import {Avatar, List} from 'antd'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import getAvatar from "../../../../helpers/getAvatar";
import {IoFlowerOutline, IoGiftOutline} from "react-icons/io5";
import {getType} from "../../../../apis/model/base";
import moment from "moment";

export const renderHomeGiving = (
  item,
  index
) => {

  if (_.isEmpty(item)) {
    return null
  }

  const { object_name, alias_name, status } = item

  return (
    <Translate key={index}>
      {(t) => (
        <div className="w-full flex flex-col justify-center gap-2">
          <List.Item className="p-0">
            <List.Item.Meta
              title={
                <div className="flex items-baseline gap-1 italic">
                  <Link to={getLinkToDetail(_.get(item, 'beneficiary')) || getLinkToDetail(item) }
                        className="text-xs font-medium text-color-000 max-lines-1">
                    {_.get(item, 'beneficiary.title') || object_name || alias_name}
                  </Link>
                  {
                    status === -1 ?
                        <span className="text-2xs font-light text-color-400 whitespace-no-wrap">
                          {t('registered to get')}
                        </span> :
                        <span className="text-2xs font-light text-color-400 whitespace-no-wrap">
                          {t('received')}
                        </span>
                  }
                  <span className="text-2xs font-light text-color-400 whitespace-no-wrap">
                      {t('part given from')}
                  </span>
                </div>
              }
              description={
                <div className="flex items-baseline justify-between">
                  <Link to={getLinkToDetail(_.get(item, 'giving_event'))}
                        className="text-sm font-semibold max-lines-1 text-secondary leading-tight flex-1">
                    {getTitle(_.get(item, 'giving_event'))}
                  </Link>
                  <span className="text-2xs font-light text-color-400 italic ml-3">
                        {moment(_.get(item, 'created')).fromNow()}
                    </span>
                  {/*{*/}
                  {/*    _.get(item, 'referral.name') ?*/}
                  {/*      <span className="text-2xs font-light text-color-400 italic whitespace-no-wrap">*/}
                  {/*        {t('introduced by')}*/}
                  {/*      </span> : undefined*/}
                  {/*}*/}
                  {/*<Link to={getLinkToDetail(_.get(item, 'referral'))}*/}
                  {/*      className="text-sm font-semibold max-lines-1 text-color-secondary leading-tight italic">*/}
                  {/*  {_.get(item, 'referral.name')}*/}
                  {/*</Link>*/}
                </div>
              }
              className="flex items-center gap-2"
              avatar={
                <Avatar
                    src={_.get(item, 'giving_event.photo')}
                    size={40}
                    icon={<IoGiftOutline className="text-color-200"/>}
                    className="flex items-center justify-center background-200"
                />
              }
            />
          </List.Item>
        </div>
      )}
    </Translate>
  )
}
