import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
} from 'react'
import {
  BoxPlaceholder,
  Null,
  renderOwnChild,
} from 'views/Shared'
import {
  ModuleContainer,
  ModuleContext,
  ModuleProvider,
} from '../configs'

const NoLayout = ({ extra }) => {
  if (_.isEmpty(extra)) {
    return (
      <Translate>
        {(t) => (
          <BoxPlaceholder
            title="Missing LayoutRenderer"
            className="h-full w-full"
          />
        )}
      </Translate>
    )
  }
  return extra
}

export const ModuleLayoutWrapper = ({
  children,
  Header = Null,
  Footer = Null,
  visibleLeftDrawer = false,
}) => (
  <LayoutContext.Consumer>
    {(current = {}) => (
      <LayoutContext.Provider
        value={{
          ...current,
          visibleLeftDrawer,
        }}>
        <Header />
        {children}
        <Footer />
      </LayoutContext.Provider>
    )}
  </LayoutContext.Consumer>
)

const ModuleItem = ({
  children,
  ...props
}) => {
  const { value } = useContext(
    ModuleContext
  )

  if (_.isEmpty(value)) {
    return children
  }

  const {
    LayoutRenderer = NoLayout,
    Provider = renderOwnChild,
    ...rest
  } = value

  return (
    <Provider
      {...props}
      {...rest}>
      <LayoutRenderer
        {...props}
        {...rest}
        extra={children}
      />
    </Provider>
  )
}

const renderModule =
  ({ Fallback = Null, ...props }) =>
  ({ name, modules }) => {
    const value = _.get(
      modules,
      name,
      {}
    )
    if (_.isEmpty(value)) {
      return <Fallback module={value} />
    }
    return (
      <ModuleProvider value={value}>
        <ModuleItem {...props}>
          <Fallback module={value} />
        </ModuleItem>
      </ModuleProvider>
    )
  }

const createModule = (
  name,
  params,
  Fallback
) => (
  <ModuleContainer name={name}>
    {renderModule({
      Fallback,
      ...params,
    })}
  </ModuleContainer>
)

export const createSettingModule = (
  name,
  params,
  Fallback
) => (
  <ModuleContainer
    name={name}
    useSettings={true}>
    {({
      name,
      settingModules: modules,
    }) =>
      renderModule({
        Fallback,
        ...params,
      })({ name, modules })
    }
  </ModuleContainer>
)

export default createModule
