import {
  Alert,
  Button,
  Input,
} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  IoCaretDown,
  IoFilter,
} from 'react-icons/io5'
import { Null } from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { GivingsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import {
  getDefaultField,
  StatusFilterTypes,
} from 'views/Wishare/wishareFieldTypes'
import GivingChart from '../components/GivingChart'
import GivingItemList from '../components/GivingItemList'
import GivingContext from '../GivingContext'

const filterItems = [
  {
    key: StatusFilterTypes.Public.key,
    label: 'giving items',
    icon: (
      <IoCaretDown className="text-color-300" />
    ),
    status:
      StatusFilterTypes.Public.value,
  },
  {
    key: StatusFilterTypes.Pending.key,
    label: 'registered list',
    icon: (
      <IoCaretDown className="text-color-300" />
    ),
    isInvisible: ({ edit }) => !!!edit,
    status:
      StatusFilterTypes.Pending.value,
  },
]

const GivingList = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { giving } = useContext(
    GivingContext
  )

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  const filterOptions = useMemo(
    () =>
      giving
        ? filterItems
            .filter(
              ({
                isInvisible = Null,
              }) => !isInvisible(giving)
            )
            .map(
              ({
                label,
                isInvisible,
                ...rest
              }) => ({
                label: t(label),
                ...rest,
              })
            )
        : [],
    [t, giving]
  )

  const [sort, setSort] = useState()

  const [filter, setFilter] = useState()

  const selectedOption = useMemo(
    () =>
      _.find(
        filterOptions,
        ({ key }) => key === filter
      ) || {},
    [filter, filterOptions]
  )

  useEffect(() => {
    setFilter(
      _.get(
        getDefaultField(filterOptions),
        'key'
      )
    )
  }, [filterOptions])

  if (_.isEmpty(giving)) return null

  return (
    <Wrapper className="flex flex-col space-y-3 pb-16">
      <GivingChart />

      {!!_.get(
        giving,
        'total.reg_giving_count'
      ) &&
        !!_.get(giving, 'edit') &&
        filter !== 'pending' && (
          <Alert
            type={'warning'}
            className="flex w-full p-1"
            message={
              <div
                className="flex items-center justify-between text-primary cursor-pointer"
                onClick={() => {
                  setFilter('pending')
                }}>
                <div className="font-semibold leading-tight text-sm md:text-base">
                  {t(
                    'giving event has the unfinished items'
                  )}
                </div>
                <div className="ml-2 font-medium italic text-sm text-color-100 hover:text-primary">
                  {t('view')}
                </div>
              </div>
            }
          />
        )}

      <div className="flex flex-1 space-x-3">
        <ItemContextMenu
          items={filterOptions}
          onMenuSelect={(key) =>
            setFilter(key)
          }
          icon={
            <Button className="rounded-lg border-none">
              <div className="flex items-center space-x-3">
                <IoFilter className="text-color-300" />
                <span className="font-medium">
                  {selectedOption.label}
                </span>
                <IoCaretDown className="text-color-300" />
              </div>
            </Button>
          }
        />
        <Input.Search
          className="input-search"
          placeholder={t(
            'search with giving code'
          )}
          onSearch={(value) =>
            onSearch(value)
          }
        />
      </div>

      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {selectedOption.label}
          </span>
          <div className="flex items-end justify-end">
            <GivingsSortBy
              value={sort}
              onSelect={(value) =>
                setSort(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <GivingItemList
            sort_by={sort}
            keyword={keyword}
            filtered_by={filter}
            isFiltered={(status) =>
              Number(status) ===
              Number(
                _.get(
                  _.find(
                    filterOptions,
                    {
                      key: filter,
                    }
                  ),
                  'status',
                  0
                )
              )
            }
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default GivingList
