import React from 'react'
import { WishareHeader } from 'views/MainPage/MainHeader'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { ObserverProvider } from 'views/Shared/components/ObservedList'
import { ModuleLayoutWrapper } from './createModule'

const createModuleLayout =
  ({
    Nav = Null,
    Sider = Null,
    Banner = Null,
    visibleLeftDrawer = false,
    Template = renderOwnChild,
    ...props
  }) =>
  (content) =>
    (
      <ObserverProvider>
        <ModuleLayoutWrapper
          // Header={withProps({
          //   renderFooter: Null,
          //   className: 'stickyTop',
          // })(MainHeader)}
          Header={WishareHeader}
          visibleLeftDrawer={false}>
          <Template
            Sider={Sider}
            Banner={Banner}
            {...props}>
            {content}
          </Template>
        </ModuleLayoutWrapper>
      </ObserverProvider>
    )

export default createModuleLayout
