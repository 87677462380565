import { Modal, Radio } from 'antd'
import { getId } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoRepeatOutline,
  IoSettingsOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { compose } from 'recompose'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { beneficiaryApi } from 'views/Wishare/apis'
import { modalWidth } from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { WishareEntities } from 'views/Wishare/enums'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import BeneficiaryTransferModalContent from '../ActionForms/BeneficiaryTransferModalContent'
import renderBeneficiaryActionRow from '../functions/renderBeneficiaryActionRow'

export const BeneficiaryManagerActions =
  Object.freeze({
    SETTING: {
      key: 'setting',
      label: 'setting',
      icon: (
        <IoSettingsOutline
          className="text-color-100"
          size={15}
        />
      ),
    },
    REMOVE_BENEFICIARY: {
      key: 'remove-beneficiary',
      label: 'remove',
      icon: (
        <IoTrashOutline
          className="text-red-500"
          size={15}
        />
      ),
    },
    APPROVE_BENEFICIARY: {
      key: 'approve-beneficiary',
      label: 'approve',
      icon: (
        <IoCheckmarkCircleOutline
          className="text-green-500"
          size={15}
        />
      ),
    },
    REJECT_BENEFICIARY: {
      key: 'reject-beneficiary',
      label: 'reject',
      icon: (
        <IoCloseCircleOutline
          className="text-red-500"
          size={15}
        />
      ),
    },
    START_TRANSFER: {
      key: 'start-transfer',
      label: 'transfer',
      icon: (
        <IoRepeatOutline
          className="text-primary"
          size={15}
        />
      ),
    },
    PRE_TRANSFER: {
      key: 'pre-transfer',
      label: 'transfer',
      icon: (
        <IoRepeatOutline
          className="text-primary"
          size={15}
        />
      ),
    },
    CANCEL_TRANSFER: {
      key: 'cancel-transfer',
      label: 'cancel transfer',
      icon: (
        <IoCloseCircleOutline
          className="text-red-500"
          size={15}
        />
      ),
    },
  })

export const BeneficiaryManagerContext =
  React.createContext({})

const BeneficiaryManagerProvider = ({
  children,
  ...props
}) => {
  const t = useTranslate()

  const history = useHistory()

  const [
    selectedItem,
    setSelectedItem,
  ] = useState()

  const onError = (arg, ...rest) => {
    notifyOnError(t)(arg, ...rest)
    Modal.destroyAll()
  }

  const confirmMe = (
    callback = Null,
    params = {}
  ) => {
    const modal =
      showDeleteConfirmDialog({
        onOk: () => {
          callback()
          modal.destroy()
        },
        onCancel: () => modal.destroy(),
        translate: t,
        ...params,
      })
  }

  const reload = () => {
    history.push({
      state: {
        now: Date.now(),
      },
    })
  }

  const {
    handleAsyncAction: transfer,
  } = useAsyncAction({
    onError,
    apiInfo:
      beneficiaryApi.beneficiary_createTransfer_api,
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      setSelectedItem(null)
    },
  })

  const {
    handleAsyncAction: removeWish,
  } = useAsyncAction({
    onError,
    apiInfo:
      beneficiaryApi.beneficiary_delete_api,
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      Modal.destroyAll()
      reload()
    },
  })

  const {
    handleAsyncAction: cancelTransfer,
  } = useAsyncAction({
    apiInfo:
      beneficiaryApi.beneficiary_cancelTransfer_api,
    onError: (arg, ...rest) => {
      notifyOnError(t)(arg, ...rest)
      Modal.destroyAll()
    },
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.INFORMATION,
        t,
        {
          message: t(
            'action completed'
          ),
        }
      )
      Modal.destroyAll()
      reload()
    },
  })

  const { handleAsyncAction: approve } =
    useAsyncAction({
      onError,
      query: bindQueryParam({
        beneficiary_id: selectedItem,
      }),
      apiInfo:
        beneficiaryApi.beneficiary_acceptTransfer_api,
      onSuccess: () => {
        successNotify(
          NotificationActionTypes.INFORMATION,
          t,
          {
            message: t(
              'action completed'
            ),
          }
        )
        Modal.destroyAll()
        reload()
      },
    })

  const { handleAsyncAction: reject } =
    useAsyncAction({
      onError,
      query: bindQueryParam({
        beneficiary_id: selectedItem,
      }),
      apiInfo:
        beneficiaryApi.beneficiary_rejectTransfer_api,
      onSuccess: () => {
        successNotify(
          NotificationActionTypes.INFORMATION,
          t,
          {
            message: t(
              'action completed'
            ),
          }
        )
        Modal.destroyAll()
        reload()
      },
    })

  const handleContextAction =
    (params) =>
    (key, { item }) => {
      const beneficiary_id = getId(item)
      switch (key) {
        case BeneficiaryManagerActions
          .REMOVE_BENEFICIARY.key:
          confirmMe(() => {
            removeWish(
              {},
              { id: beneficiary_id }
            )
          })
          break
        case BeneficiaryManagerActions
          .PRE_TRANSFER.key:
          setSelectedItem(
            beneficiary_id
          )
          break
        case BeneficiaryManagerActions
          .CANCEL_TRANSFER.key:
          confirmMe(
            () => {
              cancelTransfer(
                {},
                bindQueryParam({
                  beneficiary_id,
                })
              )
            },
            {
              title: t(
                'cancel transfer'
              ),
            }
          )
          break
        case BeneficiaryManagerActions
          .APPROVE_BENEFICIARY.key:
          confirmMe(
            () =>
              approve(
                {},
                bindQueryParam({
                  beneficiary_id,
                })
              ),
            {
              title: t(
                'accept transferring'
              ),
            }
          )
          break
        case BeneficiaryManagerActions
          .REJECT_BENEFICIARY.key:
          confirmMe(
            () =>
              reject(
                {},
                bindQueryParam({
                  beneficiary_id,
                })
              ),
            {
              title: t(
                'reject transferring'
              ),
            }
          )
          break
        case BeneficiaryManagerActions
          .START_TRANSFER.key:
          const [values, query] =
            params || [,]
          transfer(values, query)
          break
        case BeneficiaryManagerActions
          .SETTING.key:
          break
        default:
          break
      }
    }

  const renderContextMenu = useCallback(
    (item, menuOptions) => (params) =>
      (
        <ItemContextMenu
          item={item}
          items={menuOptions}
          onMenuSelect={handleContextAction(
            params
          )}
        />
      ),
    [handleContextAction]
  )

  return (
    <BeneficiaryManagerContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        renderContextMenu,
        handleContextAction,
        ...props,
      }}>
      {children}
    </BeneficiaryManagerContext.Provider>
  )
}

export const BeneficiaryManagerFilterOptions =
  {
    active: {
      label: 'active',
      value: 'active',
      createApiInfo: (__) =>
        beneficiaryApi.beneficiary_getActivityBeneficiaries_api,
    },
    onMoving: {
      label: 'on moving',
      value: 'on-moving',
      createApiInfo: (__) =>
        beneficiaryApi.beneficiary_getOnMovingBeneficiaries_api,
    },
    pending: {
      label: 'pending',
      value: 'pending',
      createApiInfo: (__) =>
        beneficiaryApi.beneficiary_getOnPendingBeneficiaries_api,
    },
  }

export const BeneficiaryList = ({
  activity_id,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const history = useHistory()

  const { isSm } = useContext(
    LayoutContext
  )

  const options = [
    BeneficiaryManagerFilterOptions.active,
    BeneficiaryManagerFilterOptions.onMoving,
    BeneficiaryManagerFilterOptions.pending,
  ]

  const [filter, setFilter] = useState(
    BeneficiaryManagerFilterOptions
      .active.value
  )

  const apiInfo = useMemo(() => {
    const _currentFilter = _.find(
      options,
      { value: filter }
    )
    const { createApiInfo = Null } =
      _currentFilter || {}
    return (
      createApiInfo({
        id: activity_id,
        type: WishareEntities.ACTIVITY,
      }) || {}
    )
  }, [filter, options])

  const lookupKey = [
    mapPathParam(apiInfo.path, {
      activity_id,
    }),
    `filter=${filter}`,
  ].join('/')

  return (
    <Wrapper className="w-full space-y-3 py-3">
      <div className="flex items-center w-full pb-2 border-b-2 border-primary">
        <Radio.Group
          size={
            isSm ? 'small' : 'middle'
          }
          defaultValue={filter}
          onChange={(event) =>
            setFilter(
              event.target.value
            )
          }
          buttonStyle="solid">
          {options.map(
            (
              { label, value },
              index
            ) => (
              <Radio.Button
                key={index}
                value={value}>
                {t(label)}
              </Radio.Button>
            )
          )}
        </Radio.Group>
      </div>
      <div className="space-y-3 py-3">
        <LazyPagination
          key={lookupKey}
          refreshKey={_.get(
            history,
            'location.state.now'
          )}
          apiInfo={apiInfo}
          renderItem={renderBeneficiaryActionRow(
            filter
          )}
          query={bindQueryParam({
            activity_id,
          })}
        />
      </div>

      <BeneficiaryManagerContext.Consumer>
        {({
          selectedItem,
          setSelectedItem = Null,
          handleContextAction = Null,
        }) => {
          const onCancel = () => {
            setSelectedItem(null)
          }
          return (
            <AntdConfigWrapper>
              <Modal
                onCancel={onCancel}
                destroyOnClose={true}
                visible={!!selectedItem}
                width={modalWidth.large}
                footer={null}>
                <BeneficiaryTransferModalContent
                  onCancel={onCancel}
                  beneficiary_id={
                    selectedItem
                  }
                  activity_id={
                    activity_id
                  }
                  onSubmit={(
                    values
                  ) => {
                    handleContextAction(
                      [
                        values,
                        bindQueryParam({
                          id: selectedItem,
                        }),
                      ]
                    )(
                      BeneficiaryManagerActions
                        .START_TRANSFER
                        .key,
                      {}
                    )
                  }}
                />
              </Modal>
            </AntdConfigWrapper>
          )
        }}
      </BeneficiaryManagerContext.Consumer>
    </Wrapper>
  )
}

const BeneficiaryManager = ({
  activity,
  Wrapper = 'div',
}) => {
  if (_.isEmpty(activity)) {
    return null
  }

  return (
    <BeneficiaryManagerProvider
      item={activity}>
      <BeneficiaryList
        Wrapper={Wrapper}
        activity_id={getId(activity)}
        type={WishareEntities.ACTIVITY}
      />
    </BeneficiaryManagerProvider>
  )
}

export default compose()(
  BeneficiaryManager
)
// branch(
//   () => !isDevelop(),
//   () => () => {
//     notification.info({
//       message: 'coming soon',
//     })
//     return (
//       <Redirect
//         to={staticPaths.home}
//       />
//     )
//   }
// )
