import { Image } from 'antd'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import DatePreview from 'views/Wishare/custom/DatePreview'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { RecruitmentVideo } from 'views/Wishare/factory/createVideoPlayer'
import HostList from 'views/Wishare/Host/components/HostList'
import TagList from 'views/Wishare/Tags/TagList'
import RecruitmentBenefits from '../components/RecruitmentBenefits'
import RecruitmentRequirement from '../components/RecruitmentRequirement'
import RecruitmentContext from '../RecruitmentContext'

const RecruitmentOverview = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()
  const { recruitment } = useContext(
    RecruitmentContext
  )

  const {
    tags,
    about,
    hosts,
    photo,
    clip_url,
    end_date,
    time_open,
    time_close,
    start_date,
  } = recruitment

  const locations = _.get(
    recruitment,
    'locations',
    []
  )

  const about_us = useMemo(
    () =>
      isRawContentEmpty(about)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(about)
            )
          ),
    [about]
  )

  if (_.isEmpty(recruitment)) {
    return null
  }

  return (
    <Wrapper className="space-y-6">
      <div className="w-full custom-image-overview">
          {clip_url && <RecruitmentVideo url_field="clip_url" />}
          {photo && (
              <Image
                  style={{ width: '100%' }}
                  preview={false}
                  src={photo}
                  fallback="/images/default/green-bg-jpg"
                  className="rounded-lg"
              />
          )}
      </div>
      <HostList
        items={hosts}
        Wrapper={withProps({
          title: 'co-hosts',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo
        title="introduce"
        hidden={!about_us}>
        <div className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={about_us}
          />
        </div>
      </HeaderItemInfo>

      <HeaderItemInfo title="requirement">
        <RecruitmentRequirement />
      </HeaderItemInfo>

      <HeaderItemInfo
        renderTitle={() =>
          `${t('benefits')} & ${t(
            'responsibilities'
          )}`
        }>
        <RecruitmentBenefits />
      </HeaderItemInfo>

      <HeaderItemInfo
        title="time location"
        className="flex flex-col">
        <DatePreview
          values={[
            {
              label: 'start date',
              value: start_date,
            },
            {
              label: 'end date',
              value: end_date,
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
        <div className="flex flex-col">
          <span className="text-xs text-color-400 font-light mt-3">
            {t('address')}
          </span>
          <div className="text-color-000 font-semibold">
            {locations.map(
              (location, i) => (
                <div key={i}>
                  {_.get(
                    location,
                    'address'
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </HeaderItemInfo>

      <TagList
        items={tags}
        Wrapper={withProps({
          title: 'keywords',
          className:
            'flex flex-wrap gap-3',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo title="application deadline">
        <DatePreview
          values={[
            {
              label: 'start date',
              value: time_open,
            },
            {
              label: 'end date',
              value: time_close,
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
      </HeaderItemInfo>
      <div className="py-16" />
    </Wrapper>
  )
}

export default RecruitmentOverview
