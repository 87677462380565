import { getId } from 'apis/model/base'
import classnames from 'classnames'
import { createValue } from 'components/form/utils'
import DebounceSelect from 'components/LookupField/DebounceSelect'
import { API_ROOT_URL } from 'envs/_current/config'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsFolder } from 'react-icons/bs'
import { Null } from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'

const renderItem = (item) => ({
  value: getId(item),
  label: (
    <span className="flex flex-col leading-tight">
      <div className="flex gap-2 items-center justify-start">
        <BsFolder />
        {!!_.get(item, 'parent') && (
          <React.Fragment>
            <div className="font-medium text-sm text-color-300">
              {getTitle(
                _.get(item, 'parent')
              )}
            </div>
            <AiOutlineRight className="text-sm text-color-300" />
          </React.Fragment>
        )}
        <div className="font-medium text-sm text-color-000">
          {getTitle(item)}
        </div>
      </div>
      {item?.owner && (
        <span className="font-light text-2xs text-secondary italic">
          {getTitle(item.owner)}
        </span>
      )}
    </span>
  ),
})

export const fetchSubCategories =
  ({ owner_id, owner_type }) =>
  async (type, keyword) => {
    const response = await fetch(
      API_ROOT_URL +
        `/subcategories/subcategory/${owner_type}/${owner_id}/${type}/get-available-subcategories?keyword=${keyword}`
    )
    const body = await response.json()
    return Array.from(
      _.get(body, 'data') || []
    ).map(renderItem)
  }

export const searchSubCategories =
  ({ owner_id, owner_type }) =>
  async (type, keyword) => {
    const response = await fetch(
      API_ROOT_URL +
        `/subcategories/${owner_type}/${owner_id}/gets?keyword=${keyword}`
    )
    const body = await response.json()
    let options = _.get(
      body,
      'data',
      []
    )
    return options.map((e) => ({
      value: getId(e),
      label: (
        <div className="flex gap-2 items-center justify-start">
          <BsFolder />
          {!!_.get(e, 'parent') && (
            <React.Fragment>
              <div className="font-medium text-sm text-color-300">
                {getTitle(
                  _.get(e, 'parent')
                )}
              </div>
              <AiOutlineRight className="text-sm text-color-300" />
            </React.Fragment>
          )}
          <div className="font-medium text-sm text-color-000">
            {getTitle(e)}
          </div>
        </div>
      ),
    }))
  }

export const SubCategoriesSingleSelect =
  ({
    className,
    name,
    type,
    owner_id,
    owner_type,
    value: id,
    onChange = Null,
    defaultValue = [],
    ...props
  }) => {
    return (
      <AntdConfigWrapper>
        <DebounceSelect
          name={name}
          type={type}
          value={id}
          className={classnames(
            'w-full',
            className
          )}
          notFoundContent={undefined}
          onChange={(value) => {
            onChange(
              createValue(name, value)
            )
          }}
          excludedOptions={
            _.isArray(defaultValue)
              ? Array.from(
                  defaultValue
                ).map(renderItem)
              : undefined
          }
          fetchOptions={fetchSubCategories(
            {
              owner_id,
              owner_type,
            }
          )}
          {...props}
        />
      </AntdConfigWrapper>
    )
  }

export const SubCategoriesSelect = ({
  className,
  name,
  type,
  owner_id,
  owner_type,
  value: ids,
  onChange = Null,
  defaultValue = [],
  ...props
}) => {
  return (
    <AntdConfigWrapper>
      <DebounceSelect
        name={name}
        type={type}
        value={ids}
        mode="multiple"
        className={classnames(
          'w-full',
          className
        )}
        notFoundContent={undefined}
        onChange={(value) => {
          onChange(
            createValue(name, value)
          )
        }}
        excludedOptions={
          _.isArray(defaultValue)
            ? Array.from(
                defaultValue
              ).map(renderItem)
            : undefined
        }
        fetchOptions={fetchSubCategories(
          {
            owner_id,
            owner_type,
          }
        )}
        {...props}
      />
    </AntdConfigWrapper>
  )
}

export default SubCategoriesSelect
