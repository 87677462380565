import { organization_fetchStatistics_Api } from 'apis'
import { getId } from 'apis/model/base'
import classNames from 'classnames'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import Pure from 'components/Pure'
import { FingerLoveOutlineSvg } from 'components/Svgs/FingerLoveOutlineSvg'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
  useState,
} from 'react'
import {
  IoChatboxEllipsesOutline,
  IoEyeOutline,
  IoLogoRss,
} from 'react-icons/io5'
import {
  nest,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import {
  getResponseItem,
  Null,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { SettingsMenu } from 'views/Wishare/factory/createSettingLayout'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import { useScrollTo } from '../useScrollTo'
import WorkspaceContext, {
  WorkspaceFilterParams,
} from '../WorkspaceContext'
import WorkspaceInsightsChart, {
  InsightsChartTypes,
} from './WorkspaceInsightsChart'
import WorkspaceInsightsMetaSummary from './WorkspaceInsightsMetaSummary'
import WorkspaceInsightsSummary, {
  InsightsSummaryTypes,
  PeriodFilterHeader,
} from './WorkspaceInsightsSummary'

function createHashId(key) {
  return [
    '__WorkspaceInsights_',
    key,
    '_group__',
  ].join('')
}

export const InsightsHashTypes =
  Object.freeze({
    STATISTIC: 'statistic',
    CAMPAIGN: 'compaign',
    CONTENT: 'content',
    DONATION: 'donation',
    GIVING: 'giving',
  })

const menuItems = [
  {
    key: InsightsHashTypes.STATISTIC,
    label: 'overview',
  },
  {
    key: InsightsHashTypes.CAMPAIGN,
    label: 'charts',
  },
  {
    key: InsightsHashTypes.CONTENT,
    label: 'campaign',
  },
]

export const InsightsBlockHeader = ({
  id,
  title,
  style,
  children,
  className,
  renderTitle = renderSelf,
}) => (
  <div
    id={id}
    style={style}
    className={classNames(
      'flex items-center justify-between mb-3',
      className
    )}>
    <Translate>
      {(t) => (
        <div className="text-color-200 uppercase font-medium text-lg">
          {renderTitle(t(title))}
        </div>
      )}
    </Translate>
    {children}
  </div>
)

const InsightsSummaries = ({
  style,
  className,
}) => (
  <div
    style={style}
    className={className}>
    <WorkspaceInsightsSummary
      type={
        InsightsSummaryTypes.DONATION
      }
      hashId={createHashId(
        InsightsHashTypes.DONATION
      )}
    />
    <WorkspaceInsightsSummary
      type={InsightsSummaryTypes.GIVING}
      hashId={createHashId(
        InsightsHashTypes.GIVING
      )}
    />
  </div>
)

const InsightsCharts = ({
  style,
  data = [],
  className,
  organization_id,
}) => {
  const [campain_id, content_id] = [
    InsightsChartTypes.CAMPAIGN,
    InsightsChartTypes.CONTENT,
  ].map((name) =>
    [
      'workspace',
      organization_id,
      name,
    ].join('/')
  )

  return (
    <div
      style={style}
      className={className}>
      <WorkspaceInsightsChart
        type={
          InsightsChartTypes.CAMPAIGN
        }
        chartInfo={{
          id: campain_id,
          idname: campain_id,
          data_values: Array.from(
            data
          ).map((e) => ({
            time: e.log_date,
            donation:
              e.total_donation_value,
            giving:
              e.total_giving_value,
          })),
          data_columns: [
            {
              id: 'time',
              dataIndex: 'time',
              type: 'date',
            },
            {
              id: 'donation',
              dataIndex: 'donation',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'giving',
              dataIndex: 'giving',
              type: 'number',
              valueType: 'value',
            },
          ],
        }}
        hashId={createHashId(
          InsightsHashTypes.CAMPAIGN
        )}
      />
      <WorkspaceInsightsChart
        type={
          InsightsChartTypes.CONTENT
        }
        chartInfo={{
          id: content_id,
          idname: content_id,
          data_values: data.map(
            (e) => ({
              time: e.log_date,
              views: e.total_views,
              votes: e.total_votes,
              follows: e.total_follows,
              comments:
                e.total_comments,
              articles:
                e.total_articles,
              posts: e.total_posts,
            })
          ),
          data_columns: [
            {
              id: 'time',
              dataIndex: 'log_date',
              type: 'date',
            },
            {
              id: 'views',
              dataIndex: 'views',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'votes',
              dataIndex: 'votes',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'follows',
              dataIndex: 'follows',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'comments',
              dataIndex: 'comments',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'articles',
              dataIndex: 'articles',
              type: 'number',
              valueType: 'value',
            },
            {
              id: 'posts',
              dataIndex: 'posts',
              type: 'number',
              valueType: 'value',
            },
          ],
        }}
        hashId={createHashId(
          InsightsHashTypes.CONTENT
        )}
      />
    </div>
  )
}

const WorkspaceInsightsContent = () => {
  const {
    organization,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  if (_.isEmpty(organization)) {
    return null
  }

  const organization_id = getId(
    organization
  )

  const period = getPeriod(
    WorkspaceFilterParams.OVERVIEW
  )

  return (
    <FlexCol className="space-y-3">
      <Async
        {...{
          apiInfo:
            organization_fetchStatistics_Api,
          query: bindQueryParam({
            id: organization_id,
          }),
          values: {
            period,
          },
        }}>
        {({
          response,
          isLoading,
          handleAsyncAction = Null,
        }) => {
          const { data, summary } =
            getResponseItem(response)
          return (
            <React.Fragment>
              <Pure
                input={[
                  period,
                  summary,
                  isLoading,
                  handleAsyncAction,
                ]}>
                <WorkspaceInsightsMetaSummary
                  summary={summary}
                  isLoading={isLoading}
                  className="grid grid-cols-2 md:grid-cols-4 gap-3"
                  Header={({
                    title,
                  }) => (
                    <PeriodFilterHeader
                      title={title}
                      value={period}
                      onSelect={(
                        period
                      ) => {
                        setPeriod(
                          WorkspaceFilterParams.OVERVIEW,
                          period
                        )
                        handleAsyncAction(
                          {
                            values: {
                              period,
                            },
                          }
                        )
                      }}
                      hashId={createHashId(
                        InsightsHashTypes.STATISTIC
                      )}
                    />
                  )}
                  field_params={[
                    {
                      name: 'total_views',
                      label:
                        'total views',
                      extra_name:
                        'total_views_prev',
                      icon: (
                        <IoEyeOutline className="text-color-500 text-xl" />
                      ),
                    },
                    {
                      name: 'total_votes',
                      label:
                        'total votes',
                      extra_name:
                        'total_votes_prev',
                      icon: (
                        <FingerLoveOutlineSvg />
                      ),
                    },
                    {
                      name: 'total_comments',
                      label:
                        'total comments',
                      extra_name:
                        'total_comments_prev',
                      icon: (
                        <IoChatboxEllipsesOutline className="text-color-500 text-xl" />
                      ),
                    },
                    {
                      name: 'total_follows',
                      label:
                        'total follows',
                      extra_name:
                        'total_follows_prev',
                      icon: (
                        <IoLogoRss className="text-color-500 text-xl" />
                      ),
                    },
                  ]}
                />
              </Pure>
              <Pure input={[data]}>
                <InsightsCharts
                  data={data}
                  className={
                    wishareClassNames.inline_2
                  }
                  organization_id={
                    organization_id
                  }
                />
              </Pure>
            </React.Fragment>
          )
        }}
      </Async>
      <Pure>
        <div className="pb-10">
          <InsightsSummaries
            className={
              wishareClassNames.inline_2
            }
          />
        </div>
      </Pure>
    </FlexCol>
  )
}

const WorkspaceInsights = () => {
  const _menuItems =
    Object.values(menuItems)

  const { key: defaultActiveKey } =
    _.first(_menuItems) || {
      key: undefined,
    }

  const [activeKey, setActiveKey] =
    useState(defaultActiveKey)

  const [scrollTo = Null] = useScrollTo(
    { offset: 60 }
  )

  return (
    <DefaultTemplate
      renderMenu={() => (
        <WishareSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <SettingsMenu
            items={_menuItems}
            render={renderSelf}
            onSelect={(key) => {
              setActiveKey(key)
              scrollTo(
                createHashId(key)
              )
            }}
            defaultSelectedKeys={[
              activeKey,
            ]}
          />
        </WishareSiderMenu>
      )}>
      <div className="space-y-3 px-3">
        <WorkspaceInsightsContent />
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({ lazy: true })(
    DelayRender
  ),
  WorkspaceInsightsContent
)
