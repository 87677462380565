import ShadowScrollbar from 'components/ShadowScrollbar'
import { getTokenKey } from 'helpers/localStorage'
import mapObject from 'helpers/mapObject'
import useOnLocationChange from 'modules/navigation/useOnLocationChange'
import React, {
  useMemo,
  useState,
} from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import UserMenu from 'views/Menu/Menu'
import { BottomBar } from './BottomBar'
import DefaultHeader from './header'
import './index.css'
import { LayoutContext } from './LayoutContext'
import { LeftDrawer } from './LeftDrawer'
import { LeftSider } from './LeftSider'
import { NormalLayout } from './NormalLayout'
import { RightDrawer } from './RightDrawer'
import { RightSider } from './RightSider'
import SiderMenu from './SiderMenu/SiderMenu'

const UserLeftMenu = () =>
  getTokenKey() && (
    <div
      style={{
        width: 240,
        flexShrink: 0,
      }}
      className="RightSider stickyTop h-screen">
      <ShadowScrollbar className="w-full h-full">
        <UserMenu />
      </ShadowScrollbar>
    </div>
  )

export const breakpoints = {
  sm: 641,
  md: 1025,
  // lg: 1024,
  // xl: 1280
}
// export const breakpoints = {
//   sm: 641,
//   md: 769,
//   lg: 1025,
//   xl: 1281,
//   "2xl": 1537
// }
const breakpointLayoutConfig = {
  xs: {
    // header: SmallHeader,
    left: LeftDrawer,
    right: RightDrawer,
    bottom: BottomBar,
    showMainPageHeader: true,
    productSize: 2,
  },
  sm: {
    // header: SmallHeader,
    left: LeftDrawer,
    right: RightDrawer,
    bottom: BottomBar,
    showMainPageHeader: true,
    productSize: 2,
  },
  md: {
    // header: SmallHeader,
    left: LeftDrawer,
    right: RightDrawer,
    bottom: BottomBar,
    showMainPageHeader: true,
    productSize: 3,
  },
  lg: {
    header: DefaultHeader,
    left: LeftSider,
    userMenu: UserLeftMenu,
    right: RightSider,
    productSize: 5,
  },
}
const getBreakpoint = (
  breakpoints,
  width
) => {
  const bp = mapObject(
    breakpoints,
    (value, key) => ({
      key,
      value,
    })
  ).find(
    ({ value }) =>
      Number(value) > Number(width)
  )

  return bp ? bp.key : 'lg'
}
const useBreakpoints = (
  breakpoints
) => {
  const { width } = useWindowSize()
  const breakpoint = getBreakpoint(
    breakpoints,
    width
  )
  return breakpoint
}

const LayoutProvider = React.memo(
  ({
    children,
    hideBottomMenu = false,
  }) => {
    const defaultLeftSider = useMemo(
      () => <SiderMenu />,
      []
    )
    const breakpoint = useBreakpoints(
      breakpoints
    )
    const {
      left: LeftLayout,
      right: RightLayout,
      header: Header,
      bottom: Bottom,
      showMainPageHeader,
    } = breakpointLayoutConfig[
      breakpoint
    ] || breakpointLayoutConfig.xs
    const [leftInner, setLeftInner] =
      useState(defaultLeftSider)
    const [
      visibleLeftDrawer,
      setVisibleLeftDrawer,
    ] = useState(false)
    const [
      visibleRightDrawer,
      setVisibleRightDrawer,
    ] = useState(false)
    const [openMenu, setOpenMenu] =
      useState(false)
    useOnLocationChange(() => {
      setVisibleLeftDrawer(false)
      setVisibleRightDrawer(false)
      setOpenMenu(false)
    })
    const isLg = ![
      'xs',
      'sm',
      'md',
    ].find(
      (value) => value === breakpoint
    )
    return (
      <LayoutContext.Provider
        value={{
          width: 480,
          showMainPageHeader,
          leftSideBarWidth:
            'var(--leftSideBar-width)',
          rightSideBarWidth:
            'var(--leftSideBar-width)',
          breakpoint,
          breakpoints: breakpoints,
          isSm: ['xs', 'sm'].find(
            (value) =>
              value === breakpoint
          ),
          isMd: ['md'].find(
            (value) =>
              value === breakpoint
          ),
          isLg: isLg,
          sampleLimit: isLg ? 3 : 1,
          smallLayout: false,
          LeftLayout,
          Header,
          RightLayout,
          leftInner:
            leftInner ||
            defaultLeftSider,
          setLeftInner: setLeftInner,
          visibleLeftDrawer,
          setVisibleLeftDrawer,
          visibleRightDrawer,
          stickyOffset:
            'var(--sticky-offset)',
          setVisibleRightDrawer,
          openMenu,
          setOpenMenu,
        }}>
        <NormalLayout
          bottom={
            Boolean(
              !hideBottomMenu && Bottom
            ) ? (
              <Bottom />
            ) : null
          }
          header={Header && <Header />}
          left={<LeftLayout />}>
          {children}
        </NormalLayout>
      </LayoutContext.Provider>
    )
  }
)

export default LayoutProvider
