import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Avatar,
  DatePicker,
  Input,
  Result,
  Switch,
} from 'antd'
import { medal_getMedals_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { donationEventSchema } from 'apis/schema'
import classNames from 'classnames'
import { renderDecoratedLabel } from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
} from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import {
  getResponseItem,
  Null,
  renderElse,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { donationApi } from 'views/Wishare/apis'
import {
  wishareConfigs,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import ContainerPicker from 'views/Wishare/custom/ContainerPicker'
import { TagCreator } from 'views/Wishare/custom/TagsPicker'
import { TargetDisplayField } from 'views/Wishare/custom/TargetsDisplayFields'
import { WishareEntities } from 'views/Wishare/enums'
import { DonationAdminDynamicFields } from 'views/Wishare/factory/createDynamicFields'
import createEditableForm, {
  ErrorSummaries,
} from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import createFormField, {
  createControlledFormField,
} from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import DonorRequiredFields from '../components/DonorRequiredFields'
import DonationContext from '../DonationContext'
import DonationAnswerView from './DonationAnswerView'
import DonorFormField from './DonorFormField'

const formFields = [
  {
    name: 'tags',
    defaultValue: [],
  },
  {
    name: 'donor',
    defaultValue: {},
  },
  {
    name: 'root_owner',
  },
  {
    name: 'alias',
  },
  {
    name: 'alias_name',
  },
  {
    name: 'anonymous',
  },
  {
    name: 'email',
  },
  {
    name: 'gender',
  },
  {
    name: 'phone_number',
  },
  {
    name: 'date_of_birth',
  },
  {
    name: 'medal',
    defaultValue: {},
  },
  {
    name: 'value_display_off',
  },
  {
    name: 'transfer_dt',
    defaultValue: moment(),
  },
  { name: 'register_note' },
  { name: 'donation_items' },
  { name: 'target_unit_name' },
  { name: 'donation_type' },
  { name: 'targets', defaultValue: [] },
  {
    name: 'information_fields',
    defaultValue: {},
  },
  {
    name: 'collection_questions',
    defaultValue: [],
  },
  {
    name: 'required_information_fields',
    defaultValue: [],
  },
]

const formSchema = [
  {
    children: [
      {
        name: 'donor_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-bold uppercase text-xl pt-3 pb-1 text-color-100">
                {t('donor')}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    ItemWrapper: ({ children }) => (
      <div className="p-3 border border-color-50 background rounded-lg">
        {children}
      </div>
    ),
    children: [
      {
        name: 'donor',
        label: 'donor',
        hideError: true,
        isRequired: true,
        renderLable:
          renderDecoratedLabel,
        component: withProps(
          ({ form }) => ({
            ..._.pick(
              _.get(form, 'values'),
              [
                'root_owner',
                'alias_name',
              ]
            ),
            dependencies: _.get(
              form,
              'values.required_information_fields'
            ),
          })
        )(DonorFormField),
      },
      {
        name: 'information_fields',
        hideError: true,
        component:
          createControlledFormField({
            isRequired: false,
            Component:
              DonorRequiredFields,
            withValues: ({
              donor,
              donor_name,
            }) => ({
              Wrapper: ({ children }) =>
                renderElse(
                  _.isEmpty(
                    getTitle(donor)
                  ) &&
                    _.isEmpty(
                      donor_name
                    ),
                  <div className="mt-2 flex flex-col space-y-2">
                    {children}
                  </div>
                ),
            }),
          }),
      },
    ],
  },
  {
    title: 'display with anonymous',
    children: [
      {
        name: 'anonymous',
        hideError: true,
        component:
          createControlledFormField({
            withProps: ({
              name,
              value,
              onChange = Null,
            }) => {
              return {
                defaultChecked:
                  Boolean(value),
                checkedChildren: (
                  <CheckOutlined />
                ),
                unCheckedChildren: (
                  <CloseOutlined />
                ),
                onChange: (checked) => {
                  onChange(
                    createValue(
                      name,
                      Number(checked)
                    )
                  )
                },
              }
            },
            Component: Switch,
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'value_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-bold uppercase text-xl pt-3 pb-1 text-color-100">
                {t('value')}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title:
      'value display is not allowed',
    children: [
      {
        name: 'value_display_off',
        hideError: true,
        component:
          createControlledFormField({
            withProps: ({
              name,
              value,
              onChange = Null,
              translate,
            }) => {
              return {
                defaultChecked:
                  Boolean(value),
                checkedChildren: (
                  <CheckOutlined />
                ),
                unCheckedChildren: (
                  <CloseOutlined />
                ),
                onChange: (checked) => {
                  onChange(
                    createValue(
                      name,
                      Number(checked)
                    )
                  )
                },
              }
            },
            Component: Switch,
          }),
      },
    ],
  },
  {
    ItemWrapper: ({ children }) => (
      <Alert
        type="warning"
        className="p-3 rounded-lg"
        message={children}
      />
    ),
    children: [
      {
        name: 'targets',
        label: 'targets',
        hideError: true,
        component: TargetDisplayField,
      },
    ],
  },
  {
    // title: 'amount',
    children: [
      {
        name: 'donation_items',
        label: 'amount',
        hideError: true,
        isRequired: true,
        component:
          DonationAdminDynamicFields,
      },
    ],
  },

  {
    children: [
      {
        name: 'detail_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-bold uppercase text-xl pt-3 pb-1 text-color-100">
                {t(
                  'additional details'
                )}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'medal',
    children: [
      {
        name: 'medal',
        component: withProps(
            ({
               name,
               value,
               form,
               onChange,
             }) => {
              const root_owner = _.get(
                  form,
                  'values.root_owner'
              )
              return {
                name,
                onChange,
                label: 'medal',
                allowClear: true,
                defaultValue: _.get(
                    value,
                    'id',
                    value
                ),
                optionParams: {
                  prefixCls: undefined,
                },
                type: WishareEntities.UNDEFINED,
                customConfigs: {
                  apiInfo:
                  medal_getMedals_api,
                  values: { active: 1 },
                  query: bindQueryParams([
                    {
                      id: getId(root_owner),
                    },
                    {
                      prop: getType(
                          root_owner
                      ),
                    },
                  ]),
                },
              }
            }
        )(ContainerPicker),
      },
    ],
  },
  {
    children: [
      {
        name: 'transfer_dt',
        label: 'donation date',
        hideError: true,
        isRequired: true,
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              onChange: (
                date,
                dateString
              ) =>
                onChange(
                  createValue(
                    name,
                    date
                      ? moment(
                          new Date(date)
                        )
                      : undefined
                  )
                ),
              defaultValue: value
                ? moment(
                    new Date(value)
                  )
                : undefined,
              className: 'w-full',
              placeholder: translate(
                'donation date'
              ),
              format:
                wishareConfigs.dateFormat,
              suffixIcon: (
                <IoCalendarOutline />
              ),
            })
          )
        )(DatePicker),
      },
    ],
  },
  {
    title: 'tags',
    children: [
      {
        name: 'tags',
        component: ({
          name,
          value,
          onChange = Null,
        }) => (
          <TagCreator
            defaultValue={value}
            onChange={(value) => {
              const tags = _.isArray(
                value
              )
                ? Array.from(
                    value || []
                  )
                    .map(
                      ({ value }) =>
                        value
                    )
                    .join(',')
                : value
              onChange(
                createValue(name, tags)
              )
            }}
          />
        ),
      },
    ],
  },
  {
    title: 'note',
    children: [
      {
        name: 'donation_note',
        hideError: true,
        component: createFormField({
          maxLength: 500,
          rows: 5,
          showCount: true,
          placeholder: 'note',
          Component: Input.TextArea,
        }),
      },
    ],
  },

  {
    children: [
      {
        name: 'collection_questions',
        component:
          createControlledFormField({
            Component:
              DonationAnswerView,
            targetName:
              'collection_answers',
          }),
      },
    ],
  },
]

const AdminFormContent = ({
  donation_event,
  Wrapper = 'div',
  onCancel = Null,
  donation_options = [],
  ...props
}) => {
  const t = useTranslate()

  const id = getId(donation_event)

  const {
    root_owner,
    target_unit_name,
    conversion_required,
  } = donation_event || {}

  const initialized = useCallback(
    ({
      tags,
      targets = [],
      collection_questions,
      required_information_fields,
      ...values
    }) => {
      const donation_items = _.isEmpty(
        donation_options
      )
        ? Array.from(targets)
            .map(
              ({
                type_cd,
                item_name,
                unit_name,
              }) => ({
                type_cd,
                unit_name,
                item_name,
                donation_value: 0,
                donation_qtty: 0,
              })
            )
            .filter((e) => !!e)
        : Array.from(donation_options)
            .map(
              ({
                type_cd,
                item_name,
                unit_name,
                option_qtty,
                option_value,
              }) => ({
                type_cd,
                item_name,
                unit_name,
                donation_qtty:
                  option_qtty,
                donation_value:
                  option_value,
              })
            )
            .filter((e) => !!e)

      return {
        ...values,
        donor: {},
        targets,
        tags: Array.from(
          tags || []
        ).map(
          ({
            idname: label,
            tags: value,
          }) => ({ label, value })
        ),
        target_unit_name,
        conversion_required,
        root_owner,
        donation_items,
        collection_questions,
        question_cleared:
          _.isEmpty(
            collection_questions
          ) ||
          _.every(
            Array.from(
              collection_questions
            ).filter(
              ({ mandatory }) =>
                !!mandatory
            ),
            ({ answer }) =>
              !_.isEmpty(answer)
          ),
        required_information_fields,
      }
    },
    [donation_options]
  )

  const validate = ({
    donor,
    donor_name,
    transfer_dt,
    question_cleared,
    target_unit_name,
    conversion_required,
    donation_items = [],
  }) => {
    let errors = {}

    const { id, owner_id } = donor || {}

    let invalidDonor = Boolean(
      _.isEmpty(id) &&
        _.isEmpty(owner_id) &&
        _.isEmpty(donor_name)
    )

    if (invalidDonor) {
      errors.donor = {
        title: t('donor'),
        description: t(
          'required field'
        ),
      }
    }

    if (!transfer_dt) {
      errors.transfer_dt = {
        title: t('transfer date'),
        description: t(
          'required field'
        ),
      }
    }

    if (!question_cleared) {
      errors.collection_answers = {
        title: t('answers'),
        description: t(
          'required field'
        ),
      }
    }

    if (
      _.isEmpty(donation_items) ||
      _.some(
        donation_items.filter(
          (e) => !!e
        ),
        ({ type_cd, donation_qtty }) =>
          !donation_qtty &&
          [
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      ) ||
      _.some(
        donation_items.filter(
          (e) => !!e
        ),
        ({
          type_cd,
          donation_qtty,
          donation_value,
        }) =>
          Boolean(
            !donation_qtty ||
              (!!conversion_required &&
                !donation_value)
          ) &&
          ![
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      ) ||
      _.some(
        donation_items.filter(
          (e) => !!e
        ),
        ({
          type_cd,
          donation_qtty,
          donation_value,
          unit_name,
        }) =>
          !_.isEqual(
            unit_name,
            target_unit_name
          ) &&
          Boolean(
            !donation_qtty ||
              (!!conversion_required &&
                !donation_value) ||
              !unit_name
          ) &&
          [
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      )
    ) {
      errors.donation_items = {
        title: t('donation value'),
        description: t(
          'required field'
        ),
      }
    }

    return errors
  }

  const validationSchema = undefined

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const apiInfo =
    donationApi.donation_events_donationManageAdd_api

  const onSuccess = ([
    __,
    { response },
  ]) => {
    onCancel()
    const newItem =
      getResponseItem(response)
    successNotify(
      NotificationActionTypes.CREATE,
      t,
      {
        description: `Code: ${_.get(
          newItem,
          'idcode'
        )}`,
      }
    )
    addPost(newItem)
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    donor,
    alias,
    medal,
    medal_type,
    targets,
    transfer_dt,
    alias_name,
    root_owner,
    donation_type,
    donation_items,
    question_cleared,
    information_fields,
    collection_answers,
    collection_questions,
    required_information_fields,
    ...values
  }) => {
    const _donation_items = Array.from(
      donation_items || []
    ).map(
      ({
        unit_name,
        donation_qtty,
        ...rest
      }) => ({
        unit_name,
        donation_qtty,
        item_name: unit_name,
        donation_value: donation_qtty,
        ...rest,
      })
    )

    const {
      owner_id,
      owner_type,
      id: donor_id,
    } = donor || {}

    return _.omitBy(
      {
        transfer_dt:
          transfer_dt || moment(),
        donation_items: JSON.stringify(
          _donation_items
        ),
        ...(collection_answers
          ? {
              collection_answers:
                JSON.stringify(
                  collection_answers
                ),
            }
          : {}),
        ...values,
        donor_id,
        owner_id,
        owner_type,
      },
      _.isUndefined
    )
  }

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    apiInfo,
    onError,
    validate,
    onSuccess,
    initialized,
    onPreSubmit,
    validationSchema,
    item: donation_event,
    disableNotify: true,
    validateOnMount: true,
    Wrapper: ({
      children,
      className,
    }) => (
      <div
        className={classNames(
          className,
          'AdminForm'
        )}>
        <ErrorSummaries />
        {children}
      </div>
    ),
    query: bindQueryParam({
      id,
    }),
    ActionBar: () => (
      <FormActionBar
        hideResetButton={true}
      />
    ),
  })

  const title = getTitle(donation_event)

  const avatar = _.get(
    donation_event,
    'owner.avatar'
  )
  const owner = _.get(
    donation_event,
    'owner.title'
  )

  return (
    <Wrapper className="space-y-3 py-3 max-w-full mx-auto">
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
        <center>
          {t(
            'enter donation information'
          )}
        </center>
      </div>
      <div className="flex flex-col mb-6 p-3 border border-primary background rounded-lg">
        <span className=" text-sm font-medium text-color-400 tracking-wide mb-1">
          {t(
            'your donation will be sent to'
          )}
        </span>
        <div className="flex items-center font-bold text-primary">
          <Avatar
            src={avatar}
            size={40}
            className="flex flex-center background-100">
            <span className="capitalize font-normal text-color-300">
              {_.first(title)}
            </span>
          </Avatar>
          <div className="flex flex-col flex-1 mx-3">
            <span className="text-base md:text-lg max-lines-1">
              {title}
            </span>
            <span className="text-xs font-light text-secondary italic">
              {owner}
            </span>
          </div>
        </div>
      </div>
      {content}
    </Wrapper>
  )
}

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const DonationRegisterAdminForm = ({
  ...props
}) => {
  const { donation: item } = useContext(
    DonationContext
  )

  const { id } =
    _.get(props, 'location.state') || {}

  const donation_event =
    useSelectEntities(
      id,
      donationEventSchema,
      item
    )

  if (_.isEmpty(donation_event)) {
    return <WishareNotFound />
  }

  const ModalForm = _.get(
    donation_event,
    'edit'
  )
    ? AdminFormContent
    : AccessDenied

  const Component = withModalLayout(
    ModalForm,
    {
      donation_event,
      ...props,
    }
  )

  return (
    <AntdConfigWrapper>
      <Pure>
        <Component />
      </Pure>
    </AntdConfigWrapper>
  )
}

export default createRequiredLogin()(
  DonationRegisterAdminForm
)
