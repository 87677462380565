import React from "react";

export const Children = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			{...props}
		>
			<path
				fill="#d77f4a"
				d="M139.683 61.543c-14.504 0-8.338-.271-28.746 1.322a82.732 82.732 0 00-55.41 27.531c-7.634 8.592-19.494 12.068-30.442 8.569L15 95.742S9.848 203.628 40.979 217.198h199.054v-55.329c.001-55.415-44.934-100.324-100.35-100.326z"
			></path>
			<path
				fill="#3c87d0"
				d="M88.425 337.699v-.031H57.969c-23.171 0-41.954 18.784-41.954 41.954v69.757h224.019v-69.757c0-23.171-18.784-41.954-41.954-41.954h-30.456v.031z"
			></path>
			<path
				fill="#ffb09e"
				d="M88.279 306.06v31.639c0 21.87 17.729 39.599 39.599 39.599s39.599-17.729 39.599-39.599V306.06z"
			></path>
			<path
				fill="#ffa78f"
				d="M88.279 337.699c0 1.831.135 3.629.375 5.395 12.013 5.4 25.358 8.41 39.415 8.41 13.899 0 27.101-2.943 39.008-8.229.257-1.823.398-3.683.398-5.576V306.06H88.279z"
			></path>
			<path
				fill="#ffb09e"
				d="M81.374 256.763H32.419C14.515 256.763 0 242.249 0 224.344s14.515-32.419 32.419-32.419h48.955z"
			></path>
			<path
				fill="#ffa78f"
				d="M174.674 191.925h48.955c17.905 0 32.419 14.515 32.419 32.419 0 17.905-14.515 32.419-32.419 32.419h-48.955z"
			></path>
			<path
				fill="#ffcebf"
				d="M32.738 206.482v35.723c0 52.053 42.681 94.251 95.332 94.251 52.65 0 95.332-42.198 95.332-94.251v-35.723c0-52.053-42.681-94.251-95.332-94.251s-95.332 42.198-95.332 94.251z"
			></path>
			<path
				fill="#fe8f83"
				d="M72.247 273.777H55.685c-4.96 0-8.98-4.02-8.98-8.98s4.02-8.98 8.98-8.98h16.563c4.96 0 8.98 4.02 8.98 8.98s-4.02 8.98-8.981 8.98z"
			></path>
			<path
				fill="#ffb09e"
				d="M131.288 112.298a96.895 96.895 0 00-10.742.884c46.235 6.502 81.789 45.793 81.789 93.3v35.723c0 47.507-35.554 86.798-81.789 93.3 3.521.495 7.108.782 10.742.884 51.159-1.679 92.114-43.197 92.114-94.184v-35.723c-.001-50.987-40.955-92.505-92.114-94.184z"
			></path>
			<path
				fill="#d77f4a"
				d="M143.666 61.643h-16.844c-2.74.152-6.503.49-15.884 1.222a82.737 82.737 0 00-53.994 25.981v41.879c0 33.8 27.4 61.199 61.199 61.199h121.89v-30.056c.001-54.08-42.796-98.144-96.367-100.225z"
			></path>
			<path
				fill="#c86d36"
				d="M143.666 61.644h-16.844c-2.308.128-5.355.389-11.861.906 48.742 6.843 86.251 48.691 86.251 99.32v30.056h38.823V161.87c-.001-54.082-42.798-98.146-96.369-100.226z"
			></path>
			<path
				fill="#0078c8"
				d="M198.079 337.669h-30.456v.031h-1.438c22.609.642 40.744 19.159 40.744 41.924v69.757h33.105v-69.757c0-23.172-18.784-41.955-41.955-41.955z"
			></path>
			<path
				fill="#fe8f83"
				d="M200.07 273.777h-16.563c-4.96 0-8.98-4.02-8.98-8.98s4.02-8.98 8.98-8.98h16.563c4.96 0 8.98 4.02 8.98 8.98s-4.02 8.98-8.98 8.98z"
			></path>
			<path
				fill="#ffa799"
				d="M127.878 298.353c-15.583 0-28.26-12.677-28.26-28.259a7.963 7.963 0 1115.926 0c0 6.8 5.533 12.334 12.335 12.334 6.8 0 12.334-5.533 12.334-12.334a7.963 7.963 0 1115.926 0c-.002 15.582-12.679 28.259-28.261 28.259z"
			></path>
			<path
				fill="#5a5a5a"
				d="M174.527 248.182a7.963 7.963 0 01-7.963-7.963V222.62a7.963 7.963 0 1115.926 0v17.599a7.962 7.962 0 01-7.963 7.963zM81.228 248.182a7.963 7.963 0 01-7.963-7.963V222.62a7.963 7.963 0 1115.926 0v17.599a7.963 7.963 0 01-7.963 7.963z"
			></path>
			<g>
				<path
					fill="#f07281"
					d="M310.319 343.64h-4.118c-22.361 0-40.488 18.127-40.488 40.488v66.347H472.2v-66.347c0-22.361-18.127-40.488-40.488-40.488z"
				></path>
				<path
					fill="#eb5569"
					d="M431.713 343.64h-33.161c22.361 0 40.488 18.127 40.488 40.488v66.347h33.161v-66.347c0-22.362-18.127-40.488-40.488-40.488z"
				></path>
				<path
					fill="#ffb09e"
					d="M368.823 386.282c-19.476 0-35.265-15.789-35.265-35.265V266.15h70.53v84.866c-.001 19.477-15.789 35.266-35.265 35.266z"
				></path>
				<path
					fill="#ffa78f"
					d="M333.558 345.995a85.52 85.52 0 0035.237 7.559 85.534 85.534 0 0035.293-7.584v-79.82h-70.53z"
				></path>
				<path
					fill="#5a5a5a"
					d="M494.376 354.457H512V305.93c0-28.341-22.975-51.316-51.316-51.316H443.06v48.527c0 28.341 22.975 51.316 51.316 51.316z"
				></path>
				<path
					fill="#444"
					d="M460.684 254.614H443.06v1.476c22.439 5.484 39.095 25.712 39.095 49.84v47.051a51.434 51.434 0 0012.221 1.476H512V305.93c0-28.342-22.975-51.316-51.316-51.316z"
				></path>
				<path
					fill="#5a5a5a"
					d="M243.008 354.457h-17.624V305.93c0-28.341 22.975-51.316 51.316-51.316h17.624v48.527c0 28.341-22.975 51.316-51.316 51.316z"
				></path>
				<path
					fill="#ffb09e"
					d="M327.282 266.15h-43.709c-15.958 0-28.894-12.936-28.894-28.894s12.936-28.894 28.894-28.894h43.709z"
				></path>
				<path
					fill="#ffa78f"
					d="M410.102 208.362h43.709c15.958 0 28.894 12.936 28.894 28.894s-12.936 28.894-28.894 28.894h-43.709z"
				></path>
				<path
					fill="#ffcebf"
					d="M368.795 137.459c-46.936 0-84.985 37.579-84.985 83.936v31.775c0 46.357 38.049 83.936 84.985 83.936s84.985-37.579 84.985-83.936v-31.775c0-46.357-38.049-83.936-84.985-83.936z"
				></path>
				<path
					fill="#ffb09e"
					d="M368.795 137.459c-4.476 0-8.87.347-13.159 1.005 40.687 6.247 71.826 40.995 71.826 82.931v31.775c0 41.936-31.14 76.684-71.826 82.931a86.73 86.73 0 0013.159 1.005c46.936 0 84.985-37.579 84.985-83.936v-31.775c0-46.357-38.049-83.936-84.985-83.936z"
				></path>
				<path
					fill="#fe8f83"
					d="M321.183 284.364h-16.177c-4.844 0-8.771-3.926-8.771-8.771s3.926-8.771 8.771-8.771h16.177c4.844 0 8.771 3.926 8.771 8.771s-3.927 8.771-8.771 8.771zM432.909 284.364h-16.177c-4.845 0-8.771-3.926-8.771-8.771s3.926-8.771 8.771-8.771h16.177c4.844 0 8.771 3.926 8.771 8.771s-3.927 8.771-8.771 8.771z"
				></path>
				<path
					fill="#5a5a5a"
					d="M377.702 88.45a9.01 9.01 0 00-9.01 9.009 9.01 9.01 0 00-9.01-9.009c-53.697 0-97.226 43.53-97.226 97.226v22.685h56.734c27.339 0 49.501-22.162 49.502-49.5.001 27.338 22.163 49.5 49.502 49.5h56.735v-22.685c-.001-53.696-43.53-97.226-97.227-97.226z"
				></path>
				<path
					fill="#444"
					d="M377.702 88.451a8.975 8.975 0 00-6.545 2.83c42.441 10.441 73.927 48.733 73.927 94.396v22.685h29.845v-22.685c-.001-53.697-43.53-97.226-97.227-97.226z"
				></path>
				<path
					fill="#ffa799"
					d="M368.957 305.935c-14.351 0-26.028-11.677-26.028-26.028 0-4.808 3.897-8.704 8.704-8.704s8.704 3.896 8.704 8.704c0 4.753 3.867 8.62 8.62 8.62s8.62-3.867 8.62-8.62c0-4.808 3.897-8.704 8.704-8.704s8.704 3.896 8.704 8.704c0 14.352-11.676 26.028-26.028 26.028z"
				></path>
				<path
					fill="#5a5a5a"
					d="M408.774 263.339a8.703 8.703 0 01-8.704-8.704v-15.473a8.703 8.703 0 018.704-8.704h6.652a8.703 8.703 0 018.704 8.704c0 4.1-2.835 7.538-6.652 8.46v7.013a8.703 8.703 0 01-8.704 8.704zM329.141 263.339a8.703 8.703 0 01-8.704-8.704v-7.013c-3.817-.923-6.652-4.36-6.652-8.46a8.703 8.703 0 018.704-8.704h6.652a8.703 8.703 0 018.704 8.704v15.473a8.703 8.703 0 01-8.704 8.704z"
				></path>
			</g>
		</svg>
	);
}

export default Children;
