import { MessageOutlined } from '@ant-design/icons'
import React, {
  useContext
} from 'react'
import Count from './Count'
import { FeedContext } from './FeedCard'
export const Message = () => {
  const { handleClick } = useContext(
    FeedContext
  )
  return (
    <div
      key="message"
      onClick={() => handleClick('to')}>
      <Count
        key="message"
        color="color-primary"
        component={MessageOutlined}
      />
    </div>
  )
}
