import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import {
  normalize,
  schema,
} from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  activitySchema,
  donationEventSchema,
  givingEventSchema,
  organizationSchema,
  recruitmentEventSchema,
  userSchema,
} from './schema'
import { eventSchema } from './schema/event'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const auth_organization_schema =
  new schema.Entity(
    'organizations',
    {
      auth_user: userSchema,
    },
    {
      idAttribute: 'idname',
    }
  )

const auth_organization_transform = (
  response,
  { query }
) => {
  const { entities, result } =
    normalize(
      response.data,
      auth_organization_schema
    )
  if (
    Number(response?.status) !== 200
  ) {
    return {
      error: true,
      errorMessages: response?.data,
    }
  } else {
    return {
      response,
      entities,
      result,
    }
  }
}

const fields = [
  'idname', // unique ~ username
  'user_id', // auto fill by login user
  'owner_id', // auto fill by login user
  'owner_type', // auto fill by login user
  'org_type', // 100: company
  'title', // Ten cong ty
  'description', // mo ta cong ty
  'avatar', // avatar
  'thumbnail', // tu gen ra boi avatar (kich thuoc nho hon)
  'cover_photo', // banner
  'status', // auto fill = 1
  'certified', // auto fill = 0
  'privacy', // 1: public, 2: private
  'public', // 1: search allow, 0: no searchable -> Wishare control
  'active', // 1: active (default), 0: inactive -> user control,
  'categories',
  'about', // rich text
]

const organization_FieldTypes = {
  user_id: baseFieldTypes.auto,
  owner_id: baseFieldTypes.auto,
  avatar: baseFieldTypes.image,
  cover_photo: baseFieldTypes.image,
  thumbnail: baseFieldTypes.auto,
  about: baseFieldTypes.string,
  // status: baseFieldTypes.status,
  // active: baseFieldTypes.active,
  // certified: baseFieldTypes.certified,
  // privacy: baseFieldTypes.privacy,
  // public: baseFieldTypes.public
}

// Route::post('organizations/add', 'OrganizationController@store');
export const organization_Create_Api = {
  backend: `Route::post('organizations/add', 'OrganizationController@store')`,
  root: API_ROOT_URL,
  path: '/organizations/add',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  schema: auth_organization_schema,
  transform:
    auth_organization_transform,
}

// Route::post('organizations/{id}/edit', 'OrganizationController@update');
export const organization_Edit_Api = {
  backend: `Route::post(''organizations/{id}/edit'', 'OrganizationController@update')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/edit',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  schema: auth_organization_schema,
  transform:
    auth_organization_transform,
}
// Route::post('organizations/{id}/delete', 'OrganizationController@deletePollQuestion');
export const organization_Delete_Api = {
  backend: `Route::post(''organizations/{id}/delete'', 'OrganizationController@deletePollQuestion')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/delete',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  schema: auth_organization_schema,
  transform:
    auth_organization_transform,
}

// Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations');
export const organization_GetTypicalOrganizations_Api =
  {
    backend: `Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations')`,
    root: API_ROOT_URL,
    path: '/organizations/get-typical-organizations',
    method: 'GET',
    fieldTypes: organization_FieldTypes,
    transform: createListTransform(
      organizationSchema
    ),
  }

// Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers');
export const organization_GetFollowingUsers_Api =
  {
    backend: `Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers')`,
    root: API_ROOT_URL,
    path: '/organizations/:id/get-following-users',
    method: 'GET',
    fieldTypes: organization_FieldTypes,
    transform: createListTransform(
      organizationSchema
    ),
  }

// Route::get('organizations/{id}/validate-sub-domain', 'OrganizationController@validateSubDomain');
export const organization_validateSubDomain_Api =
  {
    backend: `Route::get('organizations/{id}/validate-sub-domain', 'OrganizationController@validateSubDomain')`,
    root: API_ROOT_URL,
    path: '/organizations/:id/validate-sub-domain',
    method: 'GET',
    transform: createTransform(
      organizationSchema,
      'data'
    ),
  }

// Route::get('organizations/{id}/get', 'OrganizationController@getById');
export const organization_getById_Api =
  {
    backend: `Route::get('organizations/:id/get', 'OrganizationController@getById')`,
    root: API_ROOT_URL,
    path: '/organizations/:id/get',
    method: 'GET',
    schema: organizationSchema,
    fieldTypes: organization_FieldTypes,
    transform: createTransform(
      organizationSchema,
      'data'
    ),
  }

export const organization_getWidgetById_Api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/organizations/:id/get-widget',
    method: 'GET',
    schema: organizationSchema,
    fieldTypes: organization_FieldTypes,
    transform: createTransform(
      organizationSchema,
      'data'
    ),
  }

export const create_organization_getById_Api_action =

    () =>
    ({
      prefixStr = 'organization_getById_Api',
      query,
      values,
      maxAge = -1,
    }) =>
      createAsyncAction({
        prefixStr:
          prefixStr +
          '@organization_getById_Api',
        query,
        values,
        apiInfo:
          organization_getById_Api,
        maxAge,
      })
// Route::get('organizations/{id}/show', 'OrganizationController@show');
export const organization_show_Api = {
  backend: `Route::get('organizations/{id}/show', 'OrganizationController@show')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/show',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: createTransform(
    organizationSchema,
    'data'
  ),
}

// Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');
export const organization_RequestVerify_Api =
  {
    backend: `Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/request-verify',
    method: 'POST',
    fields,
    fieldTypes: organization_FieldTypes,
    transform: createTransform(
      organizationSchema,
      'data'
    ),
  }

export const organization_ExportPurchasePriceData_Api =
  {
    backend: `Route::post('exports/organization/{id}/export-purchasing-price-data', 'ProductPriceController@exportPurchasePriceData')`,
    root: API_ROOT_URL,
    path: '/exports/organization/:id/export-purchasing-price-data',
    method: 'POST',
    fields,
    fieldTypes: organization_FieldTypes,
  }

export const organization_ExportPriceData_Api =
  {
    backend: `Route::post('exports/organization/{id}/export-price-data', 'ProductPriceController@exportPriceData')`,
    root: API_ROOT_URL,
    path: '/exports/organization/:id/export-price-data',
    method: 'POST',
    fields,
    fieldTypes: organization_FieldTypes,
  }

export const organization_fetchLatestFeeds_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-latest-feeds', 'OrganizationInsightsController@fetchLatestFeeds');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-latest-feeds',
    method: 'GET',
  }

export const organization_fetchLatestFollowers_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-latest-followers', 'OrganizationInsightsController@fetchLatestFollowers');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-latest-followers',
    method: 'GET',
  }

export const organization_fetchLatestDonations_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-latest-donations', 'OrganizationInsightsController@fetchLatestDonations');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-latest-donations',
    method: 'GET',
  }

export const organization_fetchLatestGivings_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-latest-givings', 'OrganizationInsightsController@fetchLatestGivings');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-latest-givings',
    method: 'GET',
  }

export const organization_fetchLatestCandidates_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-latest-givings', 'OrganizationInsightsController@fetchLatestCandidates');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-latest-candidates',
    method: 'GET',
  }

export const organization_fetchRecentlyActivities_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-recently-activities', 'OrganizationInsightsController@fetchRecentlyActivities');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-recently-activities',
    method: 'GET',
  }

export const organization_fetchRecentlyEvents_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-recently-events', 'OrganizationInsightsController@fetchRecentlyEvents');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-recently-events',
    method: 'GET',
  }

export const organization_fetchTotalDonationInPeriod_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-total-donations-by-campaign', 'OrganizationInsightsController@fetchTotalDonationInPeriod');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-total-donations-by-campaign',
    method: 'GET',
    paramsFields: ['period'],
  }

export const organization_fetchTotalGivingInPeriod_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-total-givings-by-campaign', 'OrganizationInsightsController@fetchTotalGivingInPeriod');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-total-givings-by-campaign',
    method: 'GET',
    paramsFields: ['period'],
  }

export const organization_fetchStatistics_Api =
  {
    backend: `Route::get('organizations/{id}/insights/get-statistics', 'OrganizationInsightsController@fetchStatistics');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/insights/get-statistics',
    method: 'GET',
    paramsFields: ['period'],
  }

export const organization_getActivitiesByHost_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-activities-by-host', 'OrganizationHostsController@getActivitiesByOrgMember');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-activities-by-host',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: activitySchema,
    transform: createListTransform(
      activitySchema
    ),
  }

export const organization_getOtherActivitiesHostBy_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-other-activities-host-by', 'OrganizationHostsController@getOtherActivitiesHostBy');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-other-activities-host-by',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: activitySchema,
    transform: createListTransform(
      activitySchema
    ),
  }

export const organization_getGivingEventsByHost_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-giving-event-by-host', 'OrganizationHostsController@getGivingEventsByHost');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-giving-event-by-host',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: givingEventSchema,
    transform: createListTransform(
      givingEventSchema
    ),
  }

export const organization_getDonationEventsByHost_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-donation-event-by-host', 'OrganizationHostsController@getDonationEventsByHost');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-donation-event-by-host',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: donationEventSchema,
    transform: createListTransform(
      donationEventSchema
    ),
  }

export const organization_getRecruitmentEventsByHost_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-recruitment-event-by-host', 'OrganizationHostsController@getRecruitmentEventsByHost');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-recruitment-event-by-host',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: recruitmentEventSchema,
    transform: createListTransform(
      recruitmentEventSchema
    ),
  }

export const organization_getEventsByHost_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-event-by-host', 'OrganizationHostsController@getEventsByHost');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-event-by-host',
    method: 'GET',
    paramsFields: [
      'status',
      'sort_by',
      'keyword',
      'organization_id',
    ],
    schema: eventSchema,
    transform: createListTransform(
      eventSchema
    ),
  }

export const organization_getOrgMembersSiteMaps_Api =
  {
    backend: `API: Route::get('organization/{organization_id}/hosts/get-site-map', 'OrganizationHostsController@getOrgMembersSiteMaps');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-site-map',
    method: 'GET',
  }
