import { notification } from 'antd'
import Null from 'components/NullComponent'
import { createOnError } from 'helpers'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { compose } from 'redux'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import useDispatchAsyncAction from './useDispatchAsyncAction'

function useDispatchAsyncActionWithNotify({
  onError,
  onSuccess = Null,
  disableNotify = false,
} = {}) {
  const t = useTranslate()
  const [
    data,
    handleDispatchAsyncAction,
  ] = useDispatchAsyncAction({
    onSuccess: compose(
      onSuccess,
      (...args) => {
        if (!disableNotify) {
          successNotify(
            NotificationActionTypes.INFORMATION,
            t,
            {
              message:
                'action completed',
              description: null,
            }
          )
        }
        return args
      }
    ),
    onError: _.isFunction(onError)
      ? onError
      : createOnError(),
  })
  return [
    data,
    handleDispatchAsyncAction,
  ]
}
export function useDispatchAsyncOnlyActionWithNotify({
  onSuccess = Null,
  onError = Null,
  disableNotify = false,
} = {}) {
  const t = useTranslate()
  const [
    data,
    handleDispatchAsyncAction,
  ] = useDispatchAsyncAction({
    onSuccess: compose(
      onSuccess,
      (...args) => {
        if (!disableNotify) {
          successNotify(
            NotificationActionTypes.INFORMATION,
            t,
            {
              message:
                'action completed',
              description: null,
            }
          )
        }
        return args
      }
    ),
    onError: compose(
      onError,
      (...args) => {
        if (!disableNotify) {
          const [
            error,
            errorObj = {
              error_code:
                'System error!',
              error: '-1',
            },
          ] = args
          notification.error({
            message:
              typeof errorObj.error_code ===
              'string'
                ? errorObj.error_code
                : JSON.stringify(
                    errorObj.error_code
                  ),
            description:
              typeof errorObj.error ===
              'string'
                ? errorObj.error
                : JSON.stringify(
                    errorObj.error
                  ),
            duration: 3,
            placement: 'topRight',
          })
        }

        return args
      }
    ),
  })
  return handleDispatchAsyncAction
}
export default useDispatchAsyncActionWithNotify
