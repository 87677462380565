import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import React, {
  useContext,
} from 'react'
import FlyWrapper from 'views/Wishare/Templates/FlyWrapper'
import CategoryCategories from './CategoryCategories'
import CategoryImpacts from './CategoryImpacts'
import CategoryTags from './CategoryTags'

const CategoryMenu = ({
  Wrapper = 'div',
}) => {
  const { isLg } = useContext(
    LayoutContext
  )
  return (
    <FlyWrapper disabled={!!!isLg}>
      <Wrapper className="space-y-6 p-3">
        <CategoryCategories />
        <CategoryImpacts />
        <CategoryTags />
      </Wrapper>
    </FlyWrapper>
  )
}

export default CategoryMenu
