import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
} from 'antd'
import { givingEventSchema } from 'apis/schema'
import FieldsFactory from 'components/form/FieldsFactory'
import { LoginContext } from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  IoAddCircle,
  IoPersonCircleOutline,
} from 'react-icons/io5'
import {
  Redirect,
  useParams,
} from 'react-router-dom'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItem,
  notEmpty,
  Null,
  renderIf,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { givingApi } from 'views/Wishare/apis'
import {
  wishareConfigs,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import createStepForm, {
  StepActionTypes,
  StepFormContext,
} from 'views/Wishare/factory/createStepForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import GivingContext from '../GivingContext'
import { givingStepFormSchema } from '../givingSchemas'

const StageItem = ({
  Wrapper = 'div',
}) => {
  const {
    values,
    currentStep,
    formInstance,
  } = useContext(StepFormContext)

  if (_.isEmpty(currentStep))
    return null

  const header = _.get(
    currentStep,
    'description'
  )

  const { formSchema } = currentStep

  return (
    <Wrapper
      className="max-w-full md:px-3 lg:px-6 mx-auto"
      onClick={() =>
        formInstance.validateFields()
      }>
      {header}
      <FieldsFactory
        formSchema={formSchema}
      />
    </Wrapper>
  )
}

const renderAsBeneficiary = ({
  beneficiary_address,
  email,
  address,
  referral,
  phone_number,
  ...props
}) => {
  return (
    <Translate>
      {(t) => (
        <FlexCol>
          <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
            {t('receiver information')}
          </span>
          <div className="flex items-center mb-3">
            <Avatar
              src={getAvatar(referral)}
              icon={
                <IoPersonCircleOutline />
              }
              className="flex items-center justify-center"
            />
            <span className="text-lg flex-1 mx-3 font-bold text-primary">
              {getTitle(referral)}
            </span>
          </div>
          {address.length === 0 ? (
            <span />
          ) : (
            <div className="flex items-center">
              <span className="text-xs text-color-500 italic font-light">
                {`${t('address')} : `}
              </span>
              <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                {beneficiary_address}
                {', '}
                {address}
              </span>
            </div>
          )}
          {email && (
            <div className="flex items-center">
              <span className="text-xs text-color-500 italic font-light">
                {`${t('email')} : `}
              </span>
              <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                {email}
              </span>
            </div>
          )}
          {phone_number && (
            <div className="flex items-center">
              <span className="text-xs text-color-500 italic font-light">
                {`${t('phone')} : `}
              </span>
              <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                {phone_number}
              </span>
            </div>
          )}
        </FlexCol>
      )}
    </Translate>
  )
}

const renderAsRegister = ({
  email,
  address,
  referral,
  alias_name,
  phone_number,
  referral_email,
  beneficiary_address,
  referral_phone_number,
  ...props
}) => {
  return (
    <Translate>
      {(t) => (
        <React.Fragment>
          <FlexCol>
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t(
                'referral information'
              )}
            </span>
            <div className="flex items-center mb-3">
              <Avatar
                src={getAvatar(
                  referral
                )}
                icon={
                  <IoPersonCircleOutline />
                }
                className="flex items-center justify-center"
              />
              <span className="text-lg flex-1 mx-3 font-bold text-primary">
                {getTitle(referral)}
              </span>
            </div>
            {referral_email && (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t('email')} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {referral_email}
                </span>
              </div>
            )}
            {referral_phone_number && (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t('phone')} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {
                    referral_phone_number
                  }
                </span>
              </div>
            )}
          </FlexCol>

          <FlexCol>
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t(
                'receiver information'
              )}
            </span>
            {!_.isEmpty(alias_name) && (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t(
                    'receiver name'
                  )} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {alias_name}
                </span>
              </div>
            )}
            {email && (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t('email')} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {email}
                </span>
              </div>
            )}
            {phone_number && (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t('phone')} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {phone_number}
                </span>
              </div>
            )}
            {address.length === 0 ? (
              <span />
            ) : (
              <div className="flex items-center">
                <span className="text-xs text-color-500 italic font-light">
                  {`${t('address')} : `}
                </span>
                <span className="text-sm font-medium text-color-000 ml-3 flex-1">
                  {beneficiary_address}
                  {', '}
                  {address}
                </span>
              </div>
            )}
          </FlexCol>
        </React.Fragment>
      )}
    </Translate>
  )
}

const ConfirmStage = () => {
  const t = useTranslate()
  const { values, currentStep } =
    useContext(StepFormContext)

  const header = _.get(
    currentStep,
    'description'
  )

  const {
    as_beneficiary,
    beneficiary_address,
    beneficiary_ward_id,
    beneficiary_district_id,
    beneficiary_province_id,
    beneficiary_country_id,
  } = values

  const giving_items = _.get(
    values,
    'giving_items',
    []
  )

  const {
    wards = [],
    districts = [],
    provinces = [],
    countries = [],
  } = useAppConfig() || {}

  const address = [
    [wards, beneficiary_ward_id],
    [
      districts,
      beneficiary_district_id,
    ],
    [
      provinces,
      beneficiary_province_id,
    ],
    [countries, beneficiary_country_id],
  ]
    .map(([source, id]) => {
      return _.get(
        _.find(source, {
          id,
        }),
        'location_name'
      )
    })
    .filter(notEmpty)
    .join(', ')

  if (_.isEmpty(currentStep)) {
    return null
  }

  const params = {
    beneficiary_address,
    address,
    ..._.pick(values, [
      'email',
      'alias',
      'referral',
      'alias_name',
      'phone_number',
      'referral_email',
      'referral_phone_number',
    ]),
  }

  return (
    <div className="max-w-full md:px-3 lg:px-6 mx-auto">
      <div className="mb-6">
        {header}
        <div className="flex flex-col space-y-6">
          {!!as_beneficiary
            ? renderAsBeneficiary(
                params
              )
            : renderAsRegister(params)}

          <FlexCol>
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t(
                'you register to receive'
              )}
            </span>
            <div className="p-3 rounded-lg bg-blue-100 border border-primary">
              {giving_items.map(
                (item, i) => (
                  <Row key={i}>
                    <Col
                      span={12}
                      className="flex space-x-3 font-bold text-primary items-center">
                      <IoAddCircle />
                      {item.unit_name !==
                      item.item_name ? (
                        <div>
                          {
                            item.item_name
                          }
                        </div>
                      ) : (
                        <div>
                          {t(
                            item.type_cd
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{
                        color: '#000',
                      }}
                      className="font-bold text-right">
                      {Number(
                        item.reg_giving_qtty
                      ).toLocaleString()}{' '}
                      {item.unit_name}
                    </Col>
                  </Row>
                )
              )}
            </div>
          </FlexCol>
        </div>
      </div>
      <div className="p-3 background-200 rounded-lg text-center flex flex-col italic space-y-2 text-color-100 mb-6">
        <span>
          {t(
            'Your application will be sent to the program and it will take a few days to process, so please wait.'
          )}
        </span>
      </div>
      <div className="text-sm text-center text-color-300 italic font-light mb-6">
        {t(
          'Click the Register giving button to complete and receive the giving code'
        )}
      </div>
    </div>
  )
}

const FinalStage = React.memo(
  ({ onCancel = Null }) => {
    const t = useTranslate()

    const { item } = useContext(
      StepFormContext
    )

    return (
      <FlexCol className="items-center justify-center max-w-2xl mx-auto py-12">
        <div className="font-bold text-2xl text-green-500 uppercase">
          {t('complete registration')}
        </div>

        <span className="font-light text-center text-primary text-sm">
          {t(
            'You have just completed register receiving gifts from the program'
          )}
        </span>

        <span className="text-center text-3xl font-bold text-primary mt-2 mb-6 leading-tight">
          {_.get(
            item,
            'giving_event.title'
          )}
        </span>

        <span className="font-light text-center text-color-300 italic text-sm">
          {t('description giving code')}
        </span>

        <span className="px-3 py-1 border-2 border-secondary rounded-lg text-xl font-bold text-secondary my-3">
          {_.get(item, 'idcode')}
        </span>

        <span className="text-sm text-center text-color-300 italic font-light my-3">
          {t(
            'You can click the "Back campaign" button to return to the campaign'
          )}
        </span>

        <Button
          type="primary"
          className="no-shadow no-shadow border-none rounded-lg"
          onClick={onCancel}>
          {t('back campaign')}
        </Button>
      </FlexCol>
    )
  }
)

const stages = [
  {
    index: 0,
    name: 'giving',
    title: 'givings',
    component: StageItem,
    formSchema:
      givingStepFormSchema.register_step_1,
    actions: [
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: ['giving_items'],
      },
    ],
  },
  {
    index: 1,
    name: 'receiver',
    title: 'receiver',
    component: StageItem,
    formSchema:
      givingStepFormSchema.register_step_2,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: [
          'question_cleared',
          'beneficiary_address',
          'beneficiary_country_id',
        ],
        isDisabled: (values) =>
          Boolean(
            !!!_.get(
              values,
              'as_beneficiary'
            ) &&
              !_.get(
                values,
                'alias_name'
              )
          ) ||
          _.some(
            [
              'question_cleared',
              'beneficiary_address',
              'beneficiary_country_id',
              ..._.get(
                values,
                'required_information_fields',
                []
              ),
            ],
            (name) =>
              !_.get(values, name)
          ),
      },
    ],
  },
  {
    index: 2,
    name: 'finish',
    title: 'finish',
    component: ConfirmStage,
    formSchema:
      givingStepFormSchema.register_step_3,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        label: 'register giving',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
        requireds: [],
      },
    ],
  },
  {
    index: 3,
    name: 'final step',
    title: 'final step',
    finished: true,
    component: FinalStage,
  },
]

const GivingRegisterFormContent = ({
  Wrapper = 'div',
  onCancel = Null,
  giving_options = [],
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const params = useParams()

  const [formInstance] = Form.useForm()

  const { giving: item } = useContext(
    GivingContext
  )

  const [id] = [_.get(params, 'id')]

  const giving = useSelectEntities(
    id,
    givingEventSchema,
    item
  )

  const initialValues = useMemo(() => {
    const {
      edit,
      targets = [],
      giving_type,
      target_unit_name,
      information_fields = {},
      collection_questions = [],
      required_information_fields = [],
    } = giving || {}

    const giving_items = _.isEmpty(
      giving_options
    )
      ? Array.from(targets).map(
          ({
            target,
            type_cd,
            item_name,
            unit_name,
          }) => ({
            target,
            type_cd,
            item_name,
            unit_name,
            reg_giving_qtty: 0,
            reg_giving_value: 0,
          })
        )
      : Array.from(giving_options).map(
          ({
            type_cd,
            item_name,
            unit_name,
            option_qtty,
            option_value,
          }) => {
            const matchedTarget =
              _.first(
                Array.from(
                  targets
                ).filter(
                  (e) =>
                    e.type_cd ===
                      type_cd &&
                    e.item_name ===
                      item_name &&
                    e.unit_name ===
                      unit_name
                )
              )

            return {
              type_cd,
              item_name,
              unit_name,
              reg_giving_qtty:
                option_qtty,
              reg_giving_value:
                option_value,
              target: _.get(
                matchedTarget,
                'target',
                0
              ),
              is_read_only: true,
            }
          }
        )

    const required_fields = [
      'email',
      'phone_number',
      'gender',
      'date_of_birth',
    ]

    const basicInfo = _.pick(login, [
      'name',
      'title',
      '_type',
      'avatar',
      'user_id',
      'username',
      ...required_fields,
    ])

    const requiredParams = _.omitBy(
      {
        alias: 1,
        ..._.pick(
          basicInfo,
          required_fields
        ),
        date_of_birth:
          basicInfo.date_of_birth
            ? moment(
                basicInfo.date_of_birth
              ).format(
                wishareConfigs.dateFormat
              )
            : undefined,
      },
      _.isUndefined
    )

    return {
      edit,
      giving_type,
      giving_items,
      address: [, , ,],
      target_unit_name,
      information_fields,
      collection_questions,
      as_beneficiary: 0,
      beneficiary_address: undefined,
      required_information_fields,
      alias: 1,
      // ...requiredParams,
      referral: {
        ...basicInfo,
        owner: basicInfo,
      },
      referral_email: _.get(
        basicInfo,
        'email'
      ),
      referral_phone_number: _.get(
        basicInfo,
        'phone_number'
      ),
      question_cleared:
        _.isEmpty(
          collection_questions
        ) ||
        _.every(
          Array.from(
            collection_questions
          ).filter(
            ({ mandatory }) =>
              !!mandatory
          ),
          ({ answer }) =>
            !_.isEmpty(answer)
        ),
    }
  }, [login])

  const validationSchema = undefined

  const steps = useMemo(() => {
    const title = getTitle(giving)

    const avatar = _.get(
      giving,
      'owner.avatar'
    )
    const owner = _.get(
      giving,
      'owner.title'
    )
    return stages.map(
      ({ ...rest }) => ({
        ...rest,
        description: renderIf(
          title,
          <div className="flex flex-col mb-6 border border-secondary background rounded-lg p-3">
            <span className=" text-sm font-medium text-color-400 tracking-wide mb-1">
              {t(
                'your registration will be sent to'
              )}
            </span>
            <div className="flex items-center font-bold text-secondary">
              <Avatar
                src={avatar}
                className="flex flex-center background-100"
                size={40}>
                <span className="capitalize font-normal text-color-300">
                  {_.first(title)}
                </span>
              </Avatar>
              <div className="flex flex-col flex-1 mx-3">
                <span className="text-base md:text-lg max-lines-1">
                  {title}
                </span>
                <span className="text-xs font-light text-secondary italic max-lines-1">
                  {owner}
                </span>
              </div>
            </div>
          </div>
        ),
      })
    )
  }, [t, giving])

  const { addPost } = useContext(
    ObserverContext
  )

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo =
    givingApi.giving_events_subscribeOffer_api

  const onSuccess = (
    result,
    { response },
    forward = Null
  ) => {
    successNotify(
      NotificationActionTypes.CREATE,
      t,
      {
        description:
          'successfully subscribed',
      }
    )
    const newItem =
      getResponseItem(response)
    addPost(newItem)
    forward(newItem)
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    edit,
    address,
    referral,
    giving_type,
    giving_items,
    referral_id,
    referral_type,
    referral_name,
    question_cleared,
    target_unit_name,
    information_fields,
    collection_answers,
    collection_questions,
    required_information_fields,
    ...values
  }) => {
    const _giving_items = Array.from(
      giving_items || []
    ).map(({ ...rest }) => ({
      unit_name: target_unit_name,
      item_name: target_unit_name,
      ...rest,
    }))
    return _.omitBy(
      {
        ...values,
        giving_items: JSON.stringify(
          _giving_items
        ),
        ...(collection_answers
          ? {
              collection_answers:
                JSON.stringify(
                  collection_answers
                ),
            }
          : {}),
      },
      _.isUndefined
    )
  }

  const query = bindQueryParam({ id })

  const content = createStepForm(
    {
      query,
      apiInfo,
      onError,
      onSuccess,
    },
    {
      steps,
      onCancel,
      onPreSubmit,
      formInstance,
      initialValues,
      validationSchema,
      Header: () => (
        <div className="flex justify-center uppercase font-bold text-secondary text-xl md:text-3xl my-3">
          <center>
            {t('register giving')}
          </center>
        </div>
      ),
    }
  )

  return (
    <Wrapper className="space-y-3 py-3">
      {content}
    </Wrapper>
  )
}

const GivingRegisterForm = (props) => {
  const ModalForm = withModalLayout(
    GivingRegisterFormContent,
    props
  )
  return (
    <AntdConfigWrapper>
      <ModalForm />
    </AntdConfigWrapper>
  )
}

export const registerGivingParams = {
  action: {
    name: 'action',
    value: 'receive',
  },
  options: {
    name: 'option-id',
    getOption: (value) => value,
  },
}

export default GivingRegisterForm
