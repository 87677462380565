import { eventSchema } from 'apis/schema/event'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const EventEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={eventSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const EventEntities = ({
  items,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[eventSchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export default EventEntity
