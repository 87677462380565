import {Avatar, List} from 'antd'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import {IoPerson} from "react-icons/io5";
import getAvatar from "../../../../helpers/getAvatar";
import {getType} from "../../../../apis/model/base";
import classNames from "classnames";
import moment from "moment";

export const renderHomeFollower = (
  container,
  index
) => {
  if (_.isEmpty(container)) {
    return null
  }

  const {
    owner,
    creator: item,
  } = container

  const isOrg = getType(_.get(container, 'creator')) === 'organization';

  return (
    <Translate key={index}>
      {(t) => (
        <div className="w-full flex flex-col justify-center gap-2">
          <List.Item className="p-0">
            <List.Item.Meta
              title={
                <div className="flex items-baseline gap-1 italic">
                  <Link to={getLinkToDetail(item)}
                        className="text-xs font-medium text-color-000 max-lines-1">
                    {getTitle(item)}
                  </Link>
                  <span className="text-2xs font-light text-color-400 whitespace-no-wrap">{t('followed')}</span>
                </div>
              }
              description={
                <div className="flex items-center justify-between">
                  <Link to={getLinkToDetail(owner)}
                        className="font-semibold max-lines-1 text-color-org leading-tight flex-1">
                    {getTitle(owner)}
                  </Link>
                  <span className="text-2xs font-light text-color-400 italic ml-3">
                    {moment(_.get(container, 'created')).fromNow()}
                  </span>
                </div>
              }
              className="flex items-center gap-2"
              avatar={
                <Avatar
                  src={getAvatar(item)}
                  shape={isOrg ? 'square' : 'circle'}
                  size={40}
                  icon={<IoPerson className="text-color-200"/>}
                  className={classNames("flex items-center justify-center background-200 ", isOrg ? 'rounded-md' : 'rounded-full')}
                />
              }
            />
          </List.Item>
        </div>
      )}
    </Translate>
  )
}
