import React, { useState } from 'react'
import LoadingPage from 'views/LoadingPage'

const MainContext = React.createContext(
  {}
)

export const MainProvider = ({
  children,
  ...props
}) => {
  const [isLoading, setLoading] =
    useState(false)

  return (
    <React.Fragment>
      {!!isLoading && (
        <div
          className="flex flex-center"
          style={{
            opacity: '0.5',
            background: '#000',
            width: '100%',
            height: '100%',
            zIndex: 9999999,
            top: '50%',
            left: '50%',
            transform:
              'translate(-50%, -50%)',
            position: 'fixed',
          }}>
          <LoadingPage />
        </div>
      )}
      <MainContext.Provider
        value={{
          ...props,
          isLoading,
          setLoading,
        }}>
        {children}
      </MainContext.Provider>
    </React.Fragment>
  )
}

export default MainContext
