import {Avatar, message, Spin} from 'antd'
import {
  article_publishArticle_Api,
  channel_getFollowingUsers_Api,
  poll_add_Api,
  post_create_Api,
  post_createRepost_Api,
  post_getPosts_Api
} from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import {createTimeLineFeed} from 'components/Feed/TimeLineFeed'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import {createOnError} from 'helpers'
import getItemAltId from 'helpers/getItemAltId'
import _ from 'lodash'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import {Pure} from 'modules/asyncCache/components/Pure'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {nest} from 'recompose'
import {Welcome} from 'views/Organization/components/Welcome'
import ServerContext from 'views/Server/ServerContext'
import {Login} from '../Auth'
import CreateArticle from '../CreateArticle'
import CreateImagePost from '../CreateImagePost/CreateImagePost'
import CreatePoll from '../CreatePoll'
import QuickLink from '../QuickLink'
import EventContext from './EventContext'
import Invite from './Invite'

export const ModalLayout = ({
  name,
  children
}) => {
  const t = useTranslate()
  return (
    <ServerContext.Consumer>
      {({
        setCurrent,
        product,
        item
      }) => (
        <FullSideModalLayout
          right
          title={
            t(name) || (
              <span className="font-semibold  flex-1 j">
                <div
                  style={{
                    fontSize: '0.8em'
                  }}
                  className="text-color-300 capitalize">
                  {item.title}
                </div>
              </span>
            )
          }
          onCancel={() =>
            setCurrent(null)
          }>
          {children}
        </FullSideModalLayout>
      )}
    </ServerContext.Consumer>
  )
}
export const ModalLogin = () => {
  const translate = useTranslate()
  return (
    <ModalLayout
      name={translate(
        'you need login first'
      )}>
      <Login />
    </ModalLayout>
  )
}
const EnchanedCreateImagePost = () => {
  const {
    item,
    addNewPost,
    setCurrent
  } = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: post_create_Api,
    query: {
      ':prop': item._type,
      ':id': item.id
    },
    onSuccess: result => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: createOnError()
  })
  return (
    <div className="p-6">
      <CreateImagePost
        type={item._type}
        id={item.id}
        isLoading={isLoading}
        handleAsyncPost={
          handleAsyncPost
        }
      />
    </div>
  )
}

const EnchanedCreatePoll = () => {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent
  } = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: poll_add_Api,
    query: {
      ':prop': item._type,
      ':id': item.id
    },
    onSuccess: result => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: (err, errObject) => {
      errObject.error &&
        Object.values(
          errObject.error
        ).map(item =>
          message.error(item)
        )
    }
  })
  return (
    <div className="p-6">
      <CreatePoll
        type={item._type}
        id={item.id}
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </div>
  )
}
const EnchanedCreateArticle = () => {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent
  } = useContext(ServerContext)
  const type = item._type
  const id = item.id

  const {
    isLoading,
    handleAsyncAction: handleSubmit
  } = useAsyncAction({
    apiInfo: article_publishArticle_Api,
    query: {
      ':prop': type,
      ':id': id
    },
    onSuccess: result => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error(t('error'))
  })
  const dispatch = useDispatch()
  return (
    <Spin spinning={isLoading}>
      <CreateArticle
        isLoading={isLoading}
        onCancel={() =>
          setCurrent(null)
        }
        onSubmit={({
          id,
          ...values
        }) => {
          handleSubmit(values, {
            ':article_id': id
          })
        }}
        {...{
          type: item._type,
          id: item.id
        }}
      />
    </Spin>
  )
}

const Feed = createTimeLineFeed({
  Container: Null
})
const renderItem = item =>
  (item || null) && (
    <Pure
      key={item.id}
      input={[
        item &&
          item.content &&
          item.content.updated,
        _.get(
          item,
          'content.pin_status'
        )
      ]}>
      <Feed item={item.content}></Feed>
    </Pure>
  )
const EnchanedPinnedPost = () => {
  const { item } = useContext(
    EventContext
  )
  const containerType = _.get(
    item,
    '_type'
  )
  const containerId = getItemAltId(item)
  return (
    <div className="flex flex-col p-4 verticalList ">
      <LazyPagination
        prefixStr={`pinnedPost{created:${new Date().getTime()}}`}
        renderEmpty={() => (
          <EmptyHolder />
        )}
        apiInfo={post_getPosts_Api}
        query={{
          ':prop': containerType,
          ':id': containerId
        }}
        values={{
          pinned: 1
        }}
        renderItem={
          renderItem
        }></LazyPagination>
    </div>
  )
}
const EnchanedFollowingUsers = () => {
  const t = useTranslate()
  const { item } = useContext(
    EventContext
  )
  const containerType = _.get(
    item,
    '_type'
  )
  const containerId = getItemAltId(item)
  return (
    <div className="flex flex-col py-4 verticalList ">
      <LazyPagination
        renderEmpty={() => (
          <EmptyHolder
            subTitle={t(
              "This channel doesn't have any pinned post"
            )}
          />
        )}
        apiInfo={
          channel_getFollowingUsers_Api
        }
        renderItem={item => (
          <Link
            rel="nofollow"
            to={`/user/${item.username ||
              item.user_id}`}
            className="flex items-center hover:background-200 py-2 px-4">
            <Avatar
              shape="circle"
              src={
                item.thumbnail
              }></Avatar>
            <div className="ml-2 font-semibold">
              {item.name}
            </div>
          </Link>
        )}
        query={{
          ':id': containerId
        }}></LazyPagination>
    </div>
  )
}
const EnchanedQuickLink = () => {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent
  } = useContext(ServerContext)
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': item._type,
      ':id': item.id
    },
    onSuccess: result => {
      message.success(t('posted'))
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error(t('error'))
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.push({
        pathname: `/news/post/${_.get(
          response,
          'data.data.content.id'
        )}`
        // state: { isModal: true }
      })
    }
  }, [
    history,
    response,
    result,
    success
  ])
  return (
    <FullSideModalLayout
      title={t('quick link')}
      right
      onCancel={() => setCurrent(null)}>
      <QuickLink
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}
const EnchanedModalPosting = () => {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent
  } = useContext(ServerContext)

  return (
    <FullSideModalLayout
      title={t('quick link')}
      right
      onCancel={() => setCurrent(null)}>
      <Welcome />
    </FullSideModalLayout>
  )
}
export const routes = [
  {
    path: 'share',
    name: 'share',
    component: nest(
      ModalLayout,
      Invite
    ),
    exact: true
  }
]
