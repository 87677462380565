import Icon from '@ant-design/icons'
import Count from 'components/Feed/Count'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { MdClose } from 'react-icons/md'
import SideModal from './SideModal'

export const SideModalLayout = ({
  title = null,
  closeText = 'close',
  renderHeader,
  onCancel,
  children
}) => {
  const translate = useTranslate();
  return (
    <>
      {renderHeader ? (
        renderHeader(onCancel)
      ) : (
        <>
          <div
            className="flex  items-center w-full px-4  background  flex-shrink-0 sticky z-20 top-0 "
            style={{
              minHeight:
                'var(--header-height)'
            }}>
            <div className="flex-1 font-bold">
              {!!_.isString(title) ? translate(title) : title}
            </div>
            <Count onClick={onCancel}>
              <Icon
                style={{
                  fontWeight: 700,
                  fontSize: '1.2em'
                }}
                component={MdClose}
              />
            </Count>
          </div>
          <div
            style={{
              position: 'sticky',
              top:
                'var(--header-height)'
            }}
            className=" bg-gray-300 "></div>
        </>
      )}
      <div className=" flex-1 p-3 flex flex-col">
        {_.isFunction(children)
          ? children(onCancel)
          : children}
      </div>
    </>
  )
}
export const FullSideModalLayout = ({
  title = null,
  closeText = 'close',
  renderHeader,
  onCancel,
  children,
  ...props
}) => (
  <SideModal
    onCancel={onCancel}
    {...props}>
    {({ close, handleCancel }) => (
      <SideModalLayout
        {...{
          close,
          renderHeader,
          onCancel: handleCancel
        }}
        closeText={closeText}
        title={title}>
        {children}
      </SideModalLayout>
    )}
  </SideModal>
)
