import { Modal } from 'antd'
import UseState from 'components/UseState'
import { ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import {useHistory} from "../../../modules/navigation/useRouter";
import classNames from "classnames";

export const WillRedirect = ({
  seconds,
  children,
  redirectUrl,
}) => {
  const { handleGoBack: goBack } =
    useContext(NavigationContext)

  const [_seconds, setSeconds] =
    useState(seconds)

  let _intvl

  const onCancel = () => {
    if (_intvl) {
      clearInterval(_intvl)
    }
  }

  const onRedirect = () => {
    if (!_.isEmpty(redirectUrl)) {
      onCancel()
      document.location.href =
        redirectUrl
    }
  }

  const renderMessages = useCallback(
    () => (
      <Translate>
        {(t) => (
          <React.Fragment>
            <p>
              {t(
                'You will be redirected in'
              )}
              <span className="px-1 text-md font-semibold">
                {_seconds}
              </span>
            </p>
            <p>
              {
                'Do you want to redirect now?'
              }
            </p>
          </React.Fragment>
        )}
      </Translate>
    ),
    [_seconds]
  )

  useEffect(() => {
    _intvl = setInterval(() => {
      const _newValue = _seconds - 1
      if (_newValue == -1) {
        onRedirect()
      } else {
        setSeconds(_newValue)
      }
    }, 1000)
    return () => {
      onCancel()
    }
  }, [_seconds])

  return _.isFunction(children) ? (
    children({
      goBack,
      seconds: _seconds,
      redirectUrl,
      renderMessages,
      cancel: onCancel,
      redirect: onRedirect,
    })
  ) : (
    <UseState initialValue={true}>
      {([visible, setVisible]) => (
        <Modal
          centered={true}
          onOk={onRedirect}
          onCancel={() => {
            onCancel()
            setVisible(false)
            goBack()
          }}
          closable={false}
          visible={visible}>
          <div className="flex flex-col flex-center">
            {renderMessages()}
          </div>
        </Modal>
      )}
    </UseState>
  )
}

export const openInNewTab = (url) => {
  window.open(
    url,
    '_blank',
    'noopener,noreferrer'
  )
}

const CustomLink = ({
  to,
  children,
  withSubDomain = true,
  Component = Link,
  ...props
}) => {
  const history = useHistory()
  const { diff_root } = useContext(
    SubDomainContext
  )

  const redirect = Boolean(
    !!!withSubDomain &&
      diff_root &&
      !_.isEmpty(to)
  )

  const params = _.isString(Component)
    ? {
        ..._.pick([
          'id',
          'href',
          'style',
          'onClick',
          'className',
        ]),
      }
    : {
        to,
        ...props,
      }
  if (redirect) {
    if (_.isObject(to)) {
      return (
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            history.push(to)
          }}
          {...props}>
          {children}
        </div>
      )
    }
    return (
      <a
        target="_blank"
        href={ROOT_URL + to}
        rel="noopener noreferrer"
        {...props}>
        {children}
      </a>
    )
  }
  return (
    <Component {...params}>
      {children}
    </Component>
  )
}

export default CustomLink
