import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import {
  normalize,
  schema,
} from 'normalizr'
import Request from './middleware/request'
import {
  activitySchema,
  categorySchema,
  donationEventSchema,
  givingEventSchema,
  organizationSchema,
  recruitmentEventSchema,
} from './schema'
import { eventSchema } from './schema/event'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const tagSchema =
  new schema.Entity(
    'tags',
    {},
    {
      idAttribute: 'idname',
    }
  )

export const subCategorySchema =
  new schema.Entity(
    'subcategories',
    {},
    {
      idAttribute: 'id',
    }
  )

export function getCategoriesApi() {
  return Request.get(
    `${API_ROOT_URL}/categories/gets`
  )
}

// Route::post('categories/{id}/edit', 'TagController@updateCategory');
// Route::get('categories/gets', 'TagController@getCategories');

const transformArray = createTransform([
  categorySchema,
])
export const category_getCategories_Api =
  {
    backend: `Route::get('categories/gets', 'TagController@getCategories')`,
    root: API_ROOT_URL,
    path: '/categories/gets',
    method: 'GET',
    fields: [],
    fieldTypes: {},
    schema: [categorySchema],
    transform: transformArray,
  }
export const create_category_getCategories_Api_action =

    () =>
    ({
      prefixStr = '',
      query,
      values,
      maxAge = -1,
    }) =>
      createAsyncAction({
        prefixStr:
          prefixStr +
          '@category_getCategories_Api',
        query,
        values,
        apiInfo:
          category_getCategories_Api,
        maxAge,
      })

// Route::get('categories/get-parents', 'TagController@getParentCategories');
export const category_getparent_Api = {
  name: 'category_getparent',
  backend: ``,
  root: API_ROOT_URL,
  path: '/categories/get-parents',
  method: 'GET',
  transform: (response) => {
    const { status } = response
    const data = _.get(
      response,
      'data.data'
    )
    if (data) {
      data.products = []
      const { entities, result } =
        normalize(data, [
          categorySchema,
        ])
      response.entities = entities
      response.result = result
    }
    return {
      ...response,
      ...(status !== 200
        ? {
            error: true,
            errorMessages:
              response.data,
          }
        : {
            success: true,
          }),
      status,
      entities: response.entities,
      result: response.result,
    }
  },
}

// Route::get('categories/category/{id}/get', 'TagController@getCategoryById');
export const categories_getCategoryById_Api =
  {
    name: 'categories_getCategoryById',
    backend: `Route::get('categories/category/{id}/get', 'TagController@getCategoryById');`,
    root: API_ROOT_URL,
    path: '/categories/category/:id/get',
    method: 'GET',
    schema: categorySchema,
    transform: createTransform(
      categorySchema,
      'data'
    ),
  }

// Route::get('categories/shortcuts/charts/{id}/get', 'ChartController@getShortcutChartListByChartId');
export const categories_getShortcutChartsByChartId_Api =
  {
    name: 'categories_getShortcutChartsByChartId_Api',
    backend: `Route::get('categories/shortcuts/charts/{id}/get', 'ChartController@getShortcutChartListByChartId');`,
    root: API_ROOT_URL,
    path: '/categories/shortcuts/charts/:id/get',
    method: 'GET',
    transform: createListTransform(
      categorySchema
    ),
  }

// Route::post('categories/shortcuts/{cate_id}/charts/{id}/position/{position}/add', 'ChartController@addShortcutChartToCategory');
export const categories_addShortcutChartToCategory_Api =
  {
    name: 'categories_addShortcutChartToCategory_Api',
    backend: `Route::post('categories/shortcuts/{cate_id}/charts/{id}/position/{position}/add', 'ChartController@addShortcutChartToCategory');`,
    root: API_ROOT_URL,
    path: '/categories/shortcuts/:cate_id/charts/:id/position/:position/add',
    method: 'POST',
    fields: [
      'cate_id',
      'id',
      'position',
    ],
    transform: createTransform(
      categorySchema,
      'data'
    ),
  }

// Route::post('categories/shortcuts/{cate_id}/position/{position}/remove', 'ChartController@removeShortcutChartToCategory');
export const categories_removeShortcutChartToCategory_Api =
  {
    name: 'categories_removeShortcutChartToCategory_Api',
    backend: `Route::post('categories/shortcuts/{cate_id}/position/{position}/remove', 'ChartController@removeShortcutChartToCategory');`,
    root: API_ROOT_URL,
    path: '/categories/shortcuts/:cate_id/position/:position/remove',
    method: 'POST',
    fields: ['cate_id', 'position'],
    transform: createTransform(
      categorySchema,
      'data'
    ),
  }

export const categories_getTopTags_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/tags/get-top-tags',
    method: 'GET',
    schema: tagSchema,
    transform:
      createListTransform(tagSchema),
  }

export const categories_getRelatedTags_api =
  {
    backend: `Route::get('categories/category/{id}/related-tags', 'UserCategoriesController@getRelatedTags');`,
    root: API_ROOT_URL,
    path: '/categories/category/:id/related-tags',
    method: 'GET',
    schema: tagSchema,
    transform:
      createListTransform(tagSchema),
  }

const commonSchema = new schema.Union(
  {
    organization: organizationSchema,
    activity: activitySchema,
    event: eventSchema,
    giving_event: givingEventSchema,
    donation_event: donationEventSchema,
    recruitment_event:
      recruitmentEventSchema,
  },
  '_type'
)
// Route::get('organizations/category/{category_id}/gets', 'OrganizationController@getOrganizationsByCategory');
// Route::get('activities/category/{category_id}/gets', 'ActivityController@getActivitiesByCategory');
// Route::get('events/category/{category_id}/gets', 'EventController@getEventsByCategory');
// Route::get('donation-events/category/{category_id}/gets', 'DonationEventController@getDonationEventsByCategory');
// Route::get('giving-events/category/{category_id}/gets', 'GivingEventController@getGivingEventsByCategory');
// Route::get('recruitment-events/category/{category_id}/gets', 'RecruitmentEventController@getRecruitmentEventsByCategory');
export const categories_getEntitiesByCategory_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/:type/category/:id/gets',
    method: 'GET',
    schema: commonSchema,
    transform: createListTransform(
      commonSchema
    ),
  }

export const categories_getSubcategoryById_api =
  {
    backend: `Route::get('subcategories/{id}/get', 'SubcategoryController@getSubcategoryById');`,
    root: API_ROOT_URL,
    path: '/subcategories/subcategory/:id/get',
    method: 'GET',
    schema: subCategorySchema,
    transform: createTransform(
      subCategorySchema,
      'data'
    ),
  }

export const categories_getSubcategoriesByOwner_api =
  {
    backend: `Route::get('subcategories/subcategory/{type}/{id}/{subcategory_type}/get', 'SubcategoryController@getSubcategoriesByOwner');`,
    root: API_ROOT_URL,
    path: '/subcategories/subcategory/:type/:id/:subcategory_type/get-subcategories',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: subCategorySchema,
    transform: createListTransform(
      subCategorySchema
    ),
  }

export const categories_getAvailableSubcategories_api =
  {
    backend: `Route::get('subcategories/subcategory/{type}/{id}/{subcategory_type}/get', 'SubcategoryController@getAvailableSubcategories');`,
    root: API_ROOT_URL,
    path: '/subcategories/subcategory/:type/:id/:subcategory_type/get-available-subcategories',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: subCategorySchema,
    transform: createListTransform(
      subCategorySchema
    ),
  }

export const categories_createSubcategory_api =
  {
    backend: `Route::post('subcategories/{type}/{id}/subcategory/create', 'SubcategoryController@createSubcategory');`,
    root: API_ROOT_URL,
    path: '/subcategories/:type/:id/subcategory/create',
    method: 'POST',
    schema: subCategorySchema,
    transform: createTransform(
      subCategorySchema,
      'data'
    ),
  }

export const categories_deleteSubcategory_api =
  {
    backend: `Route::post('subcategories/subcategory/{id}/delete', 'SubcategoryController@deleteSubcategory');`,
    root: API_ROOT_URL,
    path: '/subcategories/subcategory/:id/delete',
    method: 'POST',
    schema: subCategorySchema,
    transform: createTransform(
      subCategorySchema,
      'data'
    ),
  }

export const categories_editSubcategory_api =
  {
    backend: `Route::post('subcategories/subcategory/{id}/edit', 'SubcategoryController@editSubcategory');`,
    root: API_ROOT_URL,
    path: '/subcategories/subcategory/:id/edit',
    method: 'POST',
    schema: subCategorySchema,
    transform: createTransform(
      subCategorySchema,
      'data'
    ),
  }
