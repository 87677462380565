import { Input, Modal } from 'antd'
import classnames from 'classnames'
import { updateEntity } from 'components/RichEditor/utils'
import preventDefaultEvent from 'helpers/preventDefaultEvent'
import useTranslate from 'modules/local/useTranslate'
import React, { useRef } from 'react'

export default function Image(props) {
  const t = useTranslate()
  const {
    block,
    className,
    theme = {},
    onLoad,
    ...otherProps
  } = props
  // leveraging destructuring to omit certain properties from props
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    blockStyleFn,
    preventScroll,
    contentState,
    ...elementProps
  } = otherProps
  const combinedClassName = classnames(
    'w-full shadow',
    theme.image,
    className
  )
  const {
    src,
    caption
  } = contentState
    .getEntity(block.getEntityAt(0))
    .getData()
  const inputRef = useRef()
  const {
    getEditorState,
    setEditorState,
    getReadOnly
  } = blockProps
  const handleSubmit = () => {
    setEditorState(
      updateEntity(
        getEditorState(),
        block.getEntityAt(0),
        {
          caption:
            inputRef.current.input.value
        }
      )
    )
  }
  const isReadOnly = getReadOnly()
  return (
    <div
      className="z-10 relative"
      {...elementProps}>
      <img
        style={{ margin: 0 }}
        className={combinedClassName}
        src={src}
        role="presentation"
        alt=""
        onLoad={onLoad}
      />
      {((caption && caption.length) ||
        !isReadOnly) && (
          <figcaption
            style={{
              margin: 0,
              marginTop: '-0.3em'
            }}
            className="flex justify-center w-full text-center">
            <span
              onClick={
                !isReadOnly
                  ? () => {
                    Modal.confirm({
                      icon: null,
                      title: t(
                        'write caption for image'
                      ),
                      className: 'custom-modal',
                      okText: t('add'),
                      cancelText: t('cancel'),
                      okButtonProps: {
                        type: 'primary',
                        className: 'rounded-lg no-shadow no-text-shadow no-border'
                      },
                      cancelButtonProps: {
                        className: 'rounded-lg no-shadow no-text-shadow no-border'
                      },
                      content: (
                        <form
                          onSubmit={e => {
                            preventDefaultEvent(
                              e
                            )
                            handleSubmit()
                            Modal.destroyAll()
                          }}>
                          <Input
                            key={
                              caption
                            }
                            defaultValue={
                              caption
                            }
                            ref={
                              inputRef
                            }
                            placeholder={
                              t('caption of image')
                            }/>
                          <input
                            hidden
                            type="submit"
                          />
                        </form>
                      ),
                      onOk: () => {
                        handleSubmit()
                      }
                    })
                  }
                : null
            }>
            {caption &&
            caption.length ? (
              <small>
                <span className=" leading-none">
                  {caption}
                </span>
                {!isReadOnly && (
                  <span className="underline text-primary ml-2 italic cursor-pointer">
                    {t('edit')}
                  </span>
                )}
              </small>
            ) : (
              <small className="text-color-300 italic cursor-pointer hover:text-primary">
                {t('add caption')}
              </small>
            )}
          </span>
        </figcaption>
      )}
    </div>
  )
}
