import { Avatar } from 'antd'
import { getId } from 'apis/model/base'
import { showConfirm } from 'components/Feed/TimeLineFeed'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from 'numeral'
import React, {
  useContext,
  useMemo,
  useState,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  Null,
  renderSelf,
  Visible,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { givingApi } from 'views/Wishare/apis'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import getTitle from '../../../../helpers/getTitle'
import { ItemContextMenu } from '../../factory/createContextMenu'
import GivingManageEditForm from '../ActionForms/GivingManageEditForm'
import { getGivingDetail } from '../functions/getGivingDetail'
import { getReceiverEntity } from '../functions/getReceiverEntity'
import { getReceiverName } from '../functions/getReceiverName'

export const GivingItemContextActions =
  Object.freeze({
    EDIT: 'edit',
    APPROVE: 'approve',
    REMOVE: 'remove',
  })

export const GivingRecordDetail = ({
  item,
  giving,
  giving_event,
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )

  if (_.isEmpty(item)) return null

  const {
    type_cd,
    item_name,
    unit_name,
    reg_giving_value,
    giving_value,
    reg_giving_qtty,
    giving_qtty,
    status,
    convertedCurrency,
    isConvertedCurrency,
    isCompleted,
    isConversionRequired,
    isCurrencyItem,
  } = getGivingDetail(
    item,
    giving,
    giving_event
  )

  return (
    <FlexCol>
      <div className="flex items-center">
        <span className="text-primary font-semibold space-x-1 leading-tight">
          {[
            <span>
              {Number(
                isCompleted
                  ? giving_qtty
                  : reg_giving_qtty
              ).toLocaleString()}
            </span>,

            <span>{unit_name}</span>,

            !isCurrencyItem && (
              <span className="font-light">
                ({t(item_name)})
              </span>
            ),
          ].map((string, index) => (
            <React.Fragment key={index}>
              {string}
            </React.Fragment>
          ))}
        </span>
        <span className="flex-1" />
        {!!isConversionRequired &&
          !isConvertedCurrency &&
          isCompleted && (
            <React.Fragment>
              <span className="text-xs italic text-color-400 font-light">
                {t('equivalent to')}
              </span>
              <span className="text-primary font-semibold ml-2 space-x-1 leading-tight">
                <span className="uppercase">
                  {isSm
                    ? numeral(
                        Number(
                          isCompleted
                            ? giving_value
                            : reg_giving_value
                        )
                      ).format('0a')
                    : Number(
                        isCompleted
                          ? giving_value
                          : reg_giving_value
                      ).toLocaleString()}
                </span>
                <span>
                  {convertedCurrency}
                </span>
              </span>
            </React.Fragment>
          )}
      </div>
    </FlexCol>
  )
}

export const GivingRecordItem = ({
  item,
  withoutContextMenu = false,
  showEvent = false,
  showName = true,
}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)

  const sub_domain = useContext(
    SubDomainContext
  )

  const {
    idcode,
    transfer_dt,
    giving_items = [],
    giving_event = {},
  } = item || {}

  const id = getId(giving_event)

  const giving_id = getId(item)

  const edit = Boolean(
    _.get(item, 'giving_event.edit')
  )

  const isUser =
    _.get(login, 'id') ===
    _.get(item, 'beneficiary.owner_id')

  const isPending =
    Number(_.get(item, 'status')) === -1

  const [
    contextAction,
    setContextAction,
  ] = useState({})

  const {
    removePost = Null,
    updatePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: removeItem,
  } = useAsyncAction({
    apiInfo:
      givingApi.giving_events_givingManageDelete_api,
    query: bindQueryParams([
      {
        id,
      },
      { giving_id },
    ]),
    onError: notifyOnError(t),
    onSuccess: (result, __) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      if (result) {
        removePost(result)
      }
    },
  })

  const contextMenu = useMemo(
    () =>
      !!withoutContextMenu
        ? []
        : [
            {
              key: GivingItemContextActions.EDIT,
              label: 'edit',
              invisible:
                !edit || isPending,
            },
            {
              key: GivingItemContextActions.APPROVE,
              label: 'approve',
              invisible:
                !edit || !isPending,
            },
            {
              key: GivingItemContextActions.REMOVE,
              label: 'remove',
              invisible: !edit,
            },
          ]
            .filter(Visible)
            .map(
              ({
                invisible,
                ...rest
              }) => ({
                ...rest,
              })
            ),
    [
      withoutContextMenu,
      edit,
      isPending,
    ]
  )

  if (
    Boolean(
      item.isDeleted || item.deleted
    )
  ) {
    return null
  }

  const handleContextAction = (key) => {
    switch (key) {
      case GivingItemContextActions.EDIT:
        setContextAction({
          type: key,
        })
        break
      case GivingItemContextActions.APPROVE:
        setContextAction({
          type: key,
        })
        break
      case GivingItemContextActions.REMOVE:
        showConfirm({
          title: t(
            'are you sure delete this'
          ),
          okText: t('sure'),
          cancelText: t('no'),
          okButtonProps: {
            type: 'primary',
            danger: true,
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          cancelButtonProps: {
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          onOk: () => {
            removeItem()
          },
        })
        break
      default:
    }
  }

  const isObject =
    _.get(item, 'beneficiary._type') ===
    'beneficiary'

  const receiverName =
    getReceiverName(item)

  const receiverEntity =
    getReceiverEntity(item)

  const isEditing =
    contextAction.type ===
    GivingItemContextActions.EDIT

  const isAdmin = Boolean(edit)

  const referral = _.get(
    item,
    'referral'
  )

  return (
    <FlexCol className="space-y-1 relative">
      <div className="flex flex-col p-2 md:p-3 border rounded-lg relative">
        <div className="flex flex-col">
          <div className="flex items-base space-x-2 text-xs text-color-400 italic">
            <span>{t('code')}</span>
            <span className="text-secondary font-bold text-base leading-none">
              {idcode}
            </span>
            <span className="font-bold">
              {'-'}
            </span>
            <span>
              {moment(
                transfer_dt
              ).format(
                'HH:mm - DD/MM/YYYY'
              )}
            </span>
          </div>
          <div className="flex flex-wrap items-baseline gap-1 my-2">
            {isPending ? (
              <span className="capitalize italic font-bold text-color-000 max-lines-1">{`"${receiverName}"`}</span>
            ) : isObject ? (
              <Link
                to={getLinkToDetail(
                  receiverEntity,
                  sub_domain
                )}
                className="capitalize font-bold text-primary max-lines-1">
                {receiverName}
              </Link>
            ) : (
              <span className="capitalize italic font-bold text-color-000 max-lines-1">{`"${_.get(
                item,
                'beneficiary_name'
              )}"`}</span>
            )}
            <span className="text-color-400 text-sm italic whitespace-no-wrap">
              {t(
                isPending
                  ? 'register to receive'
                  : 'received'
              )}
            </span>
            {showEvent && (
              <Link
                to={getLinkToDetail(
                  _.get(
                    item,
                    'giving_event'
                  )
                )}
                className="font-semibold text-sm text-color-000 max-lines-1">
                {getTitle(
                  _.get(
                    item,
                    'giving_event'
                  )
                )}
              </Link>
            )}
          </div>
          <div className="text-sm">
            {giving_items
              .filter(
                (e) =>
                  e.giving_qtty +
                    e.giving_value +
                    e.reg_giving_qtty +
                    e.reg_giving_value !==
                  0
              )
              .map(
                (givingItem, index) => (
                  <GivingRecordDetail
                    key={index}
                    giving_event={
                      giving_event
                    }
                    giving={item}
                    item={givingItem}
                  />
                )
              )}
          </div>
        </div>
        {isAdmin && referral ? (
          <div className="flex flex-col mt-4 mb-2">
            <span className="uppercase text-color-300 text-sm border-b mb-2 italic">
              {t(
                'referral information'
              )}
            </span>
            <div className="flex items-center gap-2 mb-2">
              <Avatar
                src={_.get(
                  referral,
                  'avatar'
                )}
                size={30}
                icon={<IoPerson />}
                className="flex items-center justify-center"
              />
              <Link
                to={getLinkToDetail(
                  referral
                )}
                className="flex-1 text-sm text-color-000 font-semibold max-lines-1 no-underline">
                {_.get(
                  referral,
                  'name'
                )}
              </Link>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              {[
                {
                  name: 'referral_email',
                  label: 'email',
                  transform: (value) =>
                    value && (
                      <a
                        href={`mailto:${value}`}>
                        {value}
                      </a>
                    ),
                },
                {
                  name: 'referral_phone_number',
                  label: 'phone',
                  transform: (value) =>
                    value && (
                      <a
                        href={`tel:${value}`}>
                        {value}
                      </a>
                    ),
                },
              ].map(
                (
                  {
                    name,
                    label,
                    transform = renderSelf,
                  },
                  index
                ) => {
                  const _value =
                    transform(
                      _.get(item, name)
                    )
                  return (
                    <React.Fragment
                      key={index}>
                      {!!_value && (
                        <div className="flex items-center">
                          <span className="text-xs text-color-500 italic font-light whitespace-no-wrap">
                            {`${t(
                              label
                            )} : `}
                          </span>
                          <span className="text-sm font-medium text-color-000 ml-2">
                            {_value}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )
                }
              )}
            </div>
          </div>
        ) : undefined}
        {isAdmin || isUser ? (
          <React.Fragment>
            {_.get(
              item,
              'giving_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'admin note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'giving_note'
                  )}
                </span>
              </div>
            ) : null}
            {_.get(
              item,
              'register_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'user note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'register_note'
                  )}
                </span>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>

      {!_.isEmpty(contextMenu) && (
        <div className="absolute right-0 top-0 px-2 py-1">
          <ItemContextMenu
            onMenuSelect={
              handleContextAction
            }
            items={contextMenu}
          />
        </div>
      )}
      {contextAction.type && (
        <GivingManageEditForm
          receiver={item}
          giving_items={giving_items}
          isEditing={isEditing}
          onCancel={() => {
            setContextAction({
              type: undefined,
            })
          }}
          actionType={
            contextAction.type
          }
          onUpdated={(id, newItem) => {
            updatePost(id, newItem)
          }}
        />
      )}
    </FlexCol>
  )
}

export const MyGivingRecordItem = ({
  item,
  showEvent = false,
  withoutContextMenu = false,
}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)

  const {
    status,
    idcode,
    transfer_dt,
    giving_items = [],
    giving_event = {},
  } = item || {}

  const details = useMemo(
    () =>
      giving_items.map(
        (givingItem, index) => (
          <GivingRecordDetail
            key={index}
            item={givingItem}
            giving={item}
            status={status}
            giving_event={giving_event}
          />
        )
      ),
    [status, giving_event, giving_items]
  )

  const isPending =
    Number(_.get(item, 'status')) === -1

  const edit =
    _.get(login, 'id') ===
    _.get(item, 'referral.id')

  const isAdmin =
    _.get(item, 'giving_event.edit') ===
    true

  const {
    handleAsyncAction: unsubscribe,
  } = useAsyncAction({
    apiInfo:
      givingApi.giving_events_unsubscribeOffer_api,
    query: bindQueryParams([
      {
        id: giving_event.id,
      },
      { giving_id: item.id },
    ]),
    onError: notifyOnError(t),
    onSuccess: () =>
      successNotify(
        NotificationActionTypes.DELETE,
        t,
        {
          description:
            'successfully subscribed',
        }
      ),
  })

  const contextMenu = useMemo(
    () =>
      withoutContextMenu
        ? []
        : [
            {
              key: GivingItemContextActions.REMOVE,
              label: 'remove',
              invisible: !(
                edit && isPending
              ),
            },
          ]
            .filter(Visible)
            .map(
              ({
                invisible,
                ...rest
              }) => ({
                ...rest,
              })
            ),
    [
      edit,
      isPending,
      withoutContextMenu,
    ]
  )

  if (
    Boolean(
      item.isDeleted || item.deleted
    )
  ) {
    return null
  }

  const handleContextAction = (key) => {
    switch (key) {
      case GivingItemContextActions.REMOVE:
        showConfirm({
          title: t(
            'Are you sure delete this?'
          ),
          onOk: () => {
            unsubscribe()
          },
        })
        break
      default:
    }
  }

  const referral = _.get(
    item,
    'referral'
  )

  return (
    <FlexCol
      key={item.id}
      className="space-y-1 relative">
      <div className="flex flex-col space-y-2 p-3 border rounded-lg">
        <div className="flex items-base space-x-2 text-xs text-color-400 italic">
          <span>{t('code')}</span>
          <span className="text-secondary font-bold text-base leading-none">
            {idcode}
          </span>
          <span className="font-bold">
            {'-'}
          </span>
          <span>
            {moment(transfer_dt).format(
              'HH:mm - DD/MM/YYYY'
            )}
          </span>
        </div>
        <div className="flex flex-wrap items-baseline my-2 gap-1">
          <span className="text-sm font-light text-color-400 italic">
            {t(
              isPending
                ? 'you have registered to receive from'
                : 'you received from'
            )}
          </span>
          {showEvent && (
            <Link
              to={getLinkToDetail(
                _.get(
                  item,
                  'giving_event'
                )
              )}
              className="font-semibold text-color-000 max-lines-2">
              {getTitle(
                _.get(
                  item,
                  'giving_event'
                )
              )}
            </Link>
          )}
        </div>
        <FlexCol className="space-y-1">
          {details}
        </FlexCol>

        {!!isPending && (
          <div className="flex flex-col mb-4">
            <span className="uppercase text-color-300 text-sm border-b mb-2 italic">
              {t(
                'referral information'
              )}
            </span>
            {_.get(
              item,
              'referral'
            ) && (
              <div className="flex items-center gap-2 mb-2">
                <Avatar
                  src={_.get(
                    referral,
                    'avatar'
                  )}
                  size={30}
                  icon={<IoPerson />}
                  className="flex items-center justify-center"
                />
                <Link
                  to={getLinkToDetail(
                    referral
                  )}
                  className="flex-1 text-sm text-color-000 font-semibold max-lines-1 no-underline">
                  {_.get(
                    referral,
                    'name'
                  )}
                </Link>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              {[
                {
                  name: 'referral_email',
                  label: 'email',
                  transform: (value) =>
                    value && (
                      <a
                        href={`mailto:${value}`}>
                        {value}
                      </a>
                    ),
                },
                {
                  name: 'referral_phone_number',
                  label: 'phone',
                  transform: (value) =>
                    value && (
                      <a
                        href={`tel:${value}`}>
                        {value}
                      </a>
                    ),
                },
              ].map(
                (
                  {
                    name,
                    label,
                    transform = renderSelf,
                  },
                  index
                ) => {
                  const _value =
                    transform(
                      _.get(item, name)
                    )
                  return (
                    <React.Fragment
                      key={index}>
                      {!!_value && (
                        <div className="flex items-center">
                          <span className="text-xs text-color-500 italic font-light">
                            {`${t(
                              label
                            )} : `}
                          </span>
                          <span className="text-sm font-medium text-color-000 ml-2">
                            {_value}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )
                }
              )}
            </div>
          </div>
        )}
        {isAdmin || edit ? (
          <React.Fragment>
            {_.get(
              item,
              'giving_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'admin note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'giving_note'
                  )}
                </span>
              </div>
            ) : null}
            {_.get(
              item,
              'register_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'user note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'register_note'
                  )}
                </span>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>

      {!_.isEmpty(contextMenu) && (
        <div className="absolute right-0 top-0 px-2 py-1">
          <ItemContextMenu
            onMenuSelect={
              handleContextAction
            }
            items={contextMenu}
          />
        </div>
      )}
    </FlexCol>
  )
}

export default GivingRecordItem
