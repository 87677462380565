import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoPerson} from "react-icons/io5";
import {Avatar} from "antd";
import _ from "lodash";

const BeneficiaryMetaHeader = ({title, locations = [], referrals = [], ...props}) => {
  const t = useTranslate()
  const ItemBeneficiaryInformation = ({label, value}) => (
    <div className="flex items-center gap-2 ml-2 mb-1">
      <span className="text-color-500 text-xs italic">{`${t(label)}:`}</span>
      <span className="flex-1 text-color-000 text-sm font-medium font-color-000">{value}</span>
    </div>
  )

  const address = locations.map((e, i) => (
    <span key={i}>
            {_.get(e, 'ward.location_name')}{', '}
      {_.get(e, 'district.location_name')}{', '}
      {_.get(e, 'province.location_name')}{', '}
      {_.get(e, 'country.location_name')}
        </span>
  ))

  return (
    <div className="border rounded-lg p-2 grid grid-cols-1 md:grid-cols-2 gap-3">
      <div className="flex flex-col border-r">
        <span className="uppercase text-xs text-color-400 font-medium mb-1">{t('receiver')}</span>
        <ItemBeneficiaryInformation label="fullname" value={title}/>
        <ItemBeneficiaryInformation label="address" value={address}/>
      </div>
      <div className="flex flex-col space-y-2">
        <span className="uppercase text-xs text-color-400 font-medium mb-1">{t('contact')}</span>
        {
          referrals.map((e, i) => (
            <div key={i} className="flex flex-col">
              <div className="flex items-center gap-2 ml-2 mb-2">
                <Avatar
                  src={_.get(e, 'referral.avatar')}
                  size="small"
                  className="flex items-center justify-center "
                  icon={
                    <IoPerson
                      size={15}
                      className="text-white"
                    />
                  }/>
                <span className="font-semibold text-color-000 flex-1">
                                {_.get(e, 'referral.name')}
                            </span>
              </div>
              <ItemBeneficiaryInformation label="phone" value={_.get(e, 'referral.phone_number')}/>
              <ItemBeneficiaryInformation label="email" value={_.get(e, 'referral.email')}/>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default BeneficiaryMetaHeader
