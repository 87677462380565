import { Avatar, Modal } from 'antd'
import { ImpactCategories } from 'components/Categories/CategoriesXS'
import { LoginContext } from 'components/LoginContext'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useState,
} from 'react'
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoEllipsisHorizontalSharp,
} from 'react-icons/io5'
import { ModalListCategories } from 'views/Dashboard/components/DashboardCategories'
import CustomMenuHeader from 'views/Wishare/custom/CustomMenuHeader'

const CategoryCategories = () => {
  const t = useTranslate()

  const { categories = [] } =
    useAppConfig()
  const appCategories =
    _.cloneDeep(categories)

  const login = useContext(LoginContext)

  const my_categories =
    _.get(login, 'categories') || []

  const [isModal, setModal] =
    useState(false)

  const merged = _.merge(
    _.keyBy(appCategories, 'id'),
    _.keyBy(my_categories, 'id')
  )
  const last_categories =
    _.values(merged)

  return (
    <div className="flex flex-col">
      <CustomMenuHeader
        onClick={() => setModal(true)}
        title="categories"
        icon={
          <IoEllipsisHorizontalSharp className="text-color-400 hover:text-primary" />
        }
      />
      <div className="flex flex-col space-y-3">
        <Toggle defaultValue={true}>
          {(isToggle, toggle) => {
            const [more, Icon] =
              isToggle
                ? [
                    'less',
                    IoChevronUpOutline,
                  ]
                : [
                    'see more',
                    IoChevronDownOutline,
                  ]
            return (
              <React.Fragment>
                <ImpactCategories
                  categories={last_categories.filter(
                    ({
                      follow_status,
                    }) =>
                      Boolean(
                        isToggle ||
                          follow_status
                      )
                  )}
                />
                <div
                  onClick={toggle}
                  className="flex items-center cursor-pointer text-color-400 hover:text-color-100 space-x-3">
                  <Avatar
                    size="small"
                    style={{
                      padding: 2,
                    }}
                    icon={<Icon />}
                    className="background-200 items-baseline justify-center"
                  />
                  <div className="text-sm font-light">
                    {t(more)}
                  </div>
                </div>
              </React.Fragment>
            )
          }}
        </Toggle>
      </div>
      <Modal
          className="custom-modal"
        footer={null}
        width="800px"
        closable={true}
        visible={isModal}
        title={
            <div className="text-center font-bold text-color-000 uppercase">
                {t('choose category')}
            </div>
        }
        onCancel={() =>
          setModal(false)
        }>
        <ModalListCategories
          categories={categories}
        />
      </Modal>
    </div>
  )
}

export default CategoryCategories
