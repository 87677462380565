import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import React from 'react'
import {
  IoBonfireOutline,
  IoFlowerOutline,
  IoGiftOutline,
  IoGridOutline,
  IoLocationOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoRibbonOutline,
  IoSettingsOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { MdWorkOutline } from 'react-icons/md'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
} from 'recompose'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import InviteManager from 'views/Organization/Settings/InviteManager'
import Locations from 'views/Organization/Settings/Locations/Locations.lazy'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { CustomKeys } from 'views/Wishare/CustomKeys'
import { WishareEntities } from 'views/Wishare/enums'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import {
  CoHosts,
  CoOwners,
} from 'views/Wishare/factory/createHostSetting'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import BeneficiaryManager from 'views/Wishare/Beneficiary/Settings/BeneficiaryManager'
import ActivityContext from '../ActivityContext'
import ActivitySettingCategories from './ActivitySettingCategories'
import ActivitySettingImpCate from './ActivitySettingImpCate'
import ActivitySettingMission from './ActivitySettingMission'
import ActivitySettingOverview from './ActivitySettingOverview'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline />,
  },
  {
    key: 'mission',
    label: 'mission',
    icon: <IoRibbonOutline />,
  },
  {
    key: 'category',
    label: 'categories',
    icon: <IoGridOutline />,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
      {
        label: 'impact categories',
        key: 'impact-categories',
      },
    ],
  },
  {
    key: 'location',
    label: 'location',
    icon: <IoLocationOutline />,
  },
  {
    key: 'member-management',
    label: 'member management',
    icon: <IoPeopleOutline />,
    children: [
      {
        label: 'members',
        key: 'members',
      },
      {
        label: 'waiting members',
        key: 'waiting-members',
      },
      {
        label: 'invites',
        key: 'invites',
      },
    ],
  },
  // {
  //   key: 'beneficiary-management',
  //   label: 'beneficiary management',
  //   icon: <IoBonfireOutline />,
  // },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <IoPersonOutline />,
    children: [
      {
        label: 'list of co-hosting',
        key: CustomKeys.co_hosts.name,
      },
      {
        label: 'list of co-owners',
        key: 'host-request',
      },
    ],
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline />,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: ActivitySettingOverview,
  },
  {
    path: 'mission',
    label: 'mission',
    component: ActivitySettingMission,
  },
  {
    path: 'categories',
    label: 'categories',
    component:
      ActivitySettingCategories,
  },
  {
    path: 'impact-categories',
    label: 'impact categories',
    component: ActivitySettingImpCate,
  },
  {
    path: 'location',
    label: 'location',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <Locations item={activity} />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'members',
    label: 'members',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <Members item={activity} />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'waiting-members',
    label: 'waiting members',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <WaitingAcceptMembers
            item={activity}
          />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'beneficiary-management',
    label: 'beneficiary management',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <BeneficiaryManager
            activity={activity}
          />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'invites',
    label: 'invites',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <InviteManager
            item={activity}
          />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <CoHosts item={activity} />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'host-request',
    label: 'host request',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) => (
          <CoOwners item={activity} />
        )}
      </ActivityContext.Consumer>
    ),
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <ActivityContext.Consumer>
        {({ activity }) =>
          createDeleteEntity(activity)
        }
      </ActivityContext.Consumer>
    ),
  },
].map(
  ({
    path,
    component: Component,
    ...rest
  }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const activitySettings = {
  actions: [
    {
      key: ContextActionTypes.CREATE_DONATION,
      label: 'create donation event',
      icon: <IoFlowerOutline />,
    },
    {
      key: ContextActionTypes.CREATE_GIVING,
      label: 'create giving event',
      icon: <IoGiftOutline />,
    },
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline />,
    },
  ],
  menuItems,
  routeEntities,
}

const ActivitySettings = ({
  id,
  ...props
}) =>
  createSettingModule(
    [WishareEntities.ACTIVITY],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(
    ActivityContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({ match }) => ({
    id: _.get(match, 'params.id'),
  }))
)(ActivitySettings)
