import {Avatar, List} from 'antd'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import {IoFlowerOutline} from "react-icons/io5";
import moment from "moment";

export const renderHomeDonation = (
  item,
  index
) => {
  const {
    deleted,
    isDeleted,
    object_name,
    alias_name,
    anonymous,
    status,
  } = item || {}

  if (
    _.some(
      [
        isDeleted,
        deleted,
        _.get(item, 'isDeleted'),
        _.get(item, 'deleted'),
      ],
      Boolean
    )
  ) {
    return null
  }

  return (
    <Translate key={index}>
      {(t) => (
        <div className="w-full flex flex-col justify-center gap-2">
          <List.Item className="p-0">
            <List.Item.Meta
              title={
                <div className="flex items-baseline flex-1 gap-1 italic leading-tight">
                  {
                    !!anonymous ? (
                      <span className="text-xs text-color-100 italic">{t('anonymous')}</span>
                    ) : (
                      !!_.get(item, 'donor') ? (
                        <Link to={getLinkToDetail(_.get(item, 'donor'))}
                              className="text-xs font-medium text-color-000 max-lines-1">
                          {getTitle(_.get(item, 'donor'))}
                        </Link>
                      ) : (
                        <span className="text-xs truncate">
                            {`"${alias_name || object_name}"`}
                        </span>
                      )
                    )
                  }
                  {
                    status === -1 ?
                      <span className="text-2xs font-light text-color-400 whitespace-no-wrap">
                          {t('registered to donate')}
                      </span> :
                      <span className="text-2xs font-light text-color-400 lowercase whitespace-no-wrap">
                          {t('donated')}
                      </span>
                  }
                  <span className="text-2xs font-light text-color-400 whitespace-no-wrap">
                          {t('to the campaign')}
                      </span>
                </div>
              }
              description={
                <div className="flex items-center justify-between">
                  <Link to={getLinkToDetail(_.get(item, 'donation_event'))}
                        className="font-semibold max-lines-1 text-primary leading-tight flex-1">
                    {getTitle(_.get(item, 'donation_event'))}
                  </Link>
                  <span className="text-2xs font-light text-color-400 italic ml-3">
                        {moment(_.get(item, 'created')).fromNow()}
                    </span>
                </div>
              }
              className="flex items-center gap-2"
              avatar={
                <Avatar
                  src={_.get(item, 'object.avatar')}
                  size={40}
                  icon={<IoFlowerOutline className="text-color-200"/>}
                  className="flex items-center justify-center background-200"
                />
              }
            />
          </List.Item>
        </div>
      )}
    </Translate>
  )
}
