import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import _ from 'lodash'
import React, {
  useCallback,
} from 'react'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import FieldInputWrapper from 'views/Wishare/custom/FieldInputWrapper'
import { renderLocationCascade } from 'views/Wishare/User/functions/renderLocationCascade'

const GivingAddressCascade = ({
  name,
  value,
  country_id,
  defaultValue,
  onChange = Null,
  title = 'address',
  fieldNames = {
    country_id:
      'beneficiary_country_id',
    province_id:
      'beneficiary_province_id',
    district_id:
      'beneficiary_district_id',
    ward_id: 'beneficiary_ward_id',
  },
  Wrapper = renderOwnChild,
}) => {
  const handleChange = (
    name,
    value
  ) => {
    onChange(createValue(name, value))
  }

  const handleAddressChanged =
    useCallback(
      (event) => {
        const [
          province_id,
          district_id,
          ward_id,
        ] = _.get(
          event,
          'target.value',
          [, , ,]
        )

        onChange(event)

        handleChange(
          fieldNames.province_id,
          province_id
        )

        handleChange(
          fieldNames.district_id,
          district_id
        )

        handleChange(
          fieldNames.ward_id,
          ward_id
        )
      },
      [fieldNames]
    )

  if (Number(country_id) !== 237) {
    return null
  }

  return (
    <Wrapper>
      <FieldInputWrapper title={title}>
        <Pure
          input={[
            name,
            value,
            country_id,
            defaultValue,
          ]}>
          {renderLocationCascade({
            name,
            value,
            country_id,
            defaultValue,
            onChange:
              handleAddressChanged,
          })}
        </Pure>
      </FieldInputWrapper>
    </Wrapper>
  )
}

export default GivingAddressCascade
