import _ from 'lodash'
import {IoBanOutline} from 'react-icons/io5'
import {notEmpty} from 'views/Shared'
import BronzeMedalSvg from '../../components/Svgs/BronzeMedalSvg'
import DiamondMedalSvg from '../../components/Svgs/DiamondMedalSvg'
import GoldMedalSvg from '../../components/Svgs/GoldMedalSvg'
import SilverMedalSvg from '../../components/Svgs/SilverMedalSvg'

const bloodUnits = [
  {
    value: 'ML',
    label: 'ML (blood unit)',
    type: 'blood',
    isDefault: true,
  },
]

const yesOrno = [
  {
    value: 1,
    label: 'yes',
  },
  {
    value: 0,
    label: 'no',
  },
]

const noOrYes = [
  {
    value: 0,
    label: 'no',
  },
  {
    value: 1,
    label: 'yes',
  },
]

const price_units = [
  {
    value: 'VND',
    label: 'VND',
    isDefault: true,
  },
  {
    value: 'USD',
    label: 'US Dollar',
  },
  {
    value: 'EUR',
    label: 'Euro',
  },
].map((unit) => ({
  ...unit,
  type: 'finance',
}))

const work_time_types = [
  {
    value: 'full-time',
    label: 'full time',
  },
  {
    value: 'part-time',
    label: 'part time',
  },
]

const type_cds = Object.freeze({
  goods: {
    value: 'goods',
    label: 'goods',
  },
  money: {
    value: 'money',
    label: 'money',
    isDefault: true,
    step: 500000,
  },
  blood: {
    value: 'blood',
    label: 'blood',
  },
  person: {
    value: 'person',
    label: 'person',
  },
})

const types = Object.freeze({
  donation: [
    {
      value: 'finance',
      label: 'finance',
      type_cds: [
        type_cds.money,
        type_cds.goods,
      ],
    },
    {
      value: 'blood',
      label: 'blood',
      type_cds: [type_cds.blood],
    },
  ],
  giving: [
    {
      value: 'finance',
      label: 'finance',
      type_cds: [
        type_cds.money,
        type_cds.goods,
      ],
    },
    {
      value: 'blood',
      label: 'blood',
      type_cds: [type_cds.blood],
    },
  ],
  recruitment: [
    {
      value: 'job',
      label: 'job',
      type_cds: [type_cds.person],
    },
    {
      value: 'volunteer',
      label: 'volunteer',
      type_cds: [type_cds.person],
    },
    {
      value: 'internship',
      label: 'internship',
      type_cds: [type_cds.person],
    },
  ],
})

const periods = [
  {
    value: 'H',
    label: 'hour',
  },
  {
    value: 'D',
    label: 'day',
  },
  {
    value: 'M',
    label: 'month',
  },
  {
    value: 'Y',
    label: 'year',
  },
].map(({ label, ...rest }) => ({
  label: `per ${label}`,
  ...rest,
}))

export const getDefaultField = (
  array = [],
  key = 'isDefault'
) => {
  const reduced = array
    .map((e) => _.get(e, key))
    .filter(notEmpty)
  if (_.isEmpty(reduced)) {
    return _.first(array)
  }
  return _.find(array, { [key]: true })
}

export const getDefaultFieldValue = (
  array = [],
  key = 'isDefault'
) => {
  const item = getDefaultField(
    array,
    key
  )
  return _.get(item, 'value')
}

export const StatusFilterTypes =
  Object.freeze({
    Pending: {
      key: 'pending',
      name: 'pending',
      label: 'pending',
      value: -1,
    },
    Public: {
      key: 'public',
      name: 'public',
      label: 'public',
      value: 1,
    },
    Rejected: {
      key: 'rejected',
      name: 'rejected',
      label: 'rejected',
      value: -2,
    },
  })

export const GenderTypes =
  Object.freeze({
    Male: {
      name: 'male',
      label: 'male',
      value: 1,
    },
    Female: {
      name: 'female',
      label: 'female',
      value: 2,
    },
    Others: {
      name: 'others',
      label: 'others',
      value: 3,
    },
  })

export const MedalTypes = Object.freeze(
  {
    None: {
      name: 'none',
      key: 'none',
      label: 'none',
      value: '',
      icon: IoBanOutline,
    },
    Diamond: {
      name: 'diamond',
      key: 'diamond',
      label: 'diamond',
      value: 'diamond',
      icon: DiamondMedalSvg,
    },
    Gold: {
      name: 'gold',
      key: 'gold',
      label: 'gold',
      value: 'gold',
      icon: GoldMedalSvg,
    },
    Silver: {
      name: 'silver',
      key: 'silver',
      label: 'silver',
      value: 'silver',
      icon: SilverMedalSvg,
    },
    Bronze: {
      name: 'bronze',
      key: 'bronze',
      label: 'bronze',
      value: 'bronze',
      icon: BronzeMedalSvg,
    },
  }
)

export const EmployeeTypes =
  Object.freeze({
    ALL: {
      key: 'all',
      label: 'all',
    },
    STAFF: {
      key: 'staff',
      label: 'staff',
      value: 'staff',
    },
    INTERN: {
      key: 'intern',
      label: 'intern',
      value: 'intern',
    },
    VOLUNTEER: {
      key: 'volunteer',
      label: 'volunteer',
      value: 'volunteer',
    },
    COLLABORATOR: {
      key: 'collaborator',
      label: 'collaborator',
      value: 'collaborator',
    },
  })

export const employeeTypeOptions =
  Object.values(EmployeeTypes).filter(
    (type) => _.has(type, 'value')
  )

export const getProp =
  (object = {}, which = 'value') =>
  (value, conditionName = 'value') => {
    const array = _.isArray(object)
      ? Array.from(object)
      : Object.values(object)
    const result = _.find(array, {
      [conditionName]: value,
    })
    return _.get(result, which)
  }

export const wishareFieldTypes =
  Object.freeze({
    periods,
    yesOrno,
    noOrYes,
    bloodUnits,
    price_units,
    required: yesOrno,
    registrable: yesOrno,
    type_cds,
    activity: {
      types: [],
      units: [],
    },
    donation: {
      types: [...types.donation],
      units: [
        ...price_units,
        ...bloodUnits,
      ],
    },
    giving: {
      types: [...types.giving],
      units: [
        ...price_units,
        ...bloodUnits,
      ],
    },
    recruitment: {
      types: [...types.recruitment],
      units: [
        {
          value: 'person',
          label: 'person',
        },
      ],
      salary_periods: periods,
      salary_units: price_units,
      work_time_types,
      onSiteOrRemote: noOrYes,
    },
  })
