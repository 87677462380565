import React, { useState } from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import { Queries } from '../enums/Queries'
import { getURL } from '../functions'

export const filterQuery =
  Queries.FILTER

export const langQuery = Queries.LANG

export const categoryQuery =
  Queries.CATEGORY

export const withFilter = (
  url,
  type
) => {
  return getURL(url, {
    [filterQuery]: type,
  })
}

const FilterContext =
  React.createContext({})

export const FilterProvider = ({
  type,
  children,
  ...props
}) => {
  const location = useLocation()
  const filterSearchParam =
    useSearchParam(filterQuery)
  const [selected, setSelected] =
    useState()
  return (
    <FilterContext.Provider
      key={location.search}
      value={{
        type,
        ...props,
        selected,
        setSelected,
        filterQuery: filterSearchParam,
      }}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
