import {Avatar} from 'antd'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoPerson} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import _ from "lodash";
import {GenderTypes, getProp} from "../../wishareFieldTypes";
import moment from "moment";

const RecruitmentCandidateItem = ({
  item,
}) => {
    const t = useTranslate()
    const { candidate } = item || {}


    const date_of_birth = !!_.get(item, 'candidate.date_of_birth')
        ? moment(_.get(item, 'candidate.date_of_birth')).format("DD/MM/YYYY")
        : null

    let gender = !!_.get(item, 'gender')
        ? getProp(
            Object.values(
                GenderTypes
            ),
            'label'
        )(Number(_.get(item, 'gender')))
        : null
    gender = !!gender ? t(gender) : null;




    const RowInformation = ({label, value, hiddenIfEmpty = true}) => !value && hiddenIfEmpty ? null : (
        <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-400 italic font-light">
              {t(label)}{' : '}
            </span>
            {
                value ?
                    <span className="text-xs text-color-000 font-medium flex-1">
                      {value}
                    </span>
                    : <span className="italic text-xs text-color-200 font-medium">
                      {t('not added yet')}
                    </span>
            }
        </div>
    );

    return (
        <div className="w-full border border-color-50 rounded-lg p-2">
            <div className="flex items-center gap-3 relative">
                <Avatar
                    size={25}
                    className="flex flex-center background-200"
                    icon={<IoPerson className="text-color-200" />}
                    src={getAvatar(candidate)}>
                        <span className="capitalize text-color-200">
                            {_.first(_.get(item, 'title'))}
                        </span>
                </Avatar>
                <div className="flex items-baseline gap-1">
                    <Link
                        to={getLinkToDetail(candidate)}
                        className="text-color-000 font-semibold leading-tight flex items-center max-lines-1">
                        {getTitle(candidate)}
                    </Link>
                    <span className="text-sm text-color-400 italic lowercase">
                        {t('applied')}
                    </span>
                </div>
            </div>
            <div className="flex flex-col mt-2 space-y-1">
                <RowInformation label="campaign" value={_.get(item, 'recruitment_event.title')} />
                <RowInformation label="position" value={_.get(item, 'recruitment_event.position_title')} />
            </div>
            <div className="flex flex-col mt-2">
                <div className="w-full uppercase text-color-300 text-sm font-medium border-b mb-2">
                    {t('profile information')}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                    {_.get(item, 'phone_number') && (
                        <RowInformation label="phone" value={_.get(item, 'phone_number')} />
                    )}
                    {_.get(item, 'email') && (
                        <RowInformation label="email" value={_.get(item, 'email')} />
                    )}
                    {_.get(item, 'candidate.date_of_birth') && (
                        <RowInformation label="date of birth" value={date_of_birth} />
                    )}
                    {_.get(item, 'gender') && _.get(item, 'gender') !== 3 && (
                        <RowInformation label="gender" value={gender} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default RecruitmentCandidateItem
