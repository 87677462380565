import { DatePicker, Radio } from 'antd'
import classNames from 'classnames'
import FieldsFactory from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, { useMemo } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import {
  compose,
  mapProps,
} from 'recompose'
import {
  Null,
  renderFalse,
  Visible,
} from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { GenderTypes } from 'views/Wishare/wishareFieldTypes'

const renderFieldWrapper =
  (title, isRequired = false) =>
  ({ children }) =>
    (
      <Translate>
        {(t) => (
          <div className="flex flex-col justify-center">
            {
              <label
                className={classNames(
                  'horizontal-form-label text-sm text-color-300',
                  !!isRequired &&
                    'required-label'
                )}>
                {_.isString(title)
                  ? `${t(title)}: `
                  : title}
              </label>
            }
            <div className="flex-1">
              {children}
            </div>
          </div>
        )}
      </Translate>
    )

const createFormSchema = ({
  isRequired = true,
  isDisabled = renderFalse,
  isInvisible = renderFalse,
}) =>
  [
    {
      invisible: isInvisible('email'),
      children: [
        {
          name: 'email',
          Wrapper: renderFieldWrapper(
            'email',
            isRequired
          ),
          isRequired,
          hideError: true,
          component:
            createControlledFormField({
              type: 'email',
              disabled:
                isDisabled('email'),
              placeholder: 'email',
            }),
        },
      ],
    },
    {
      invisible: isInvisible(
        'phone_number'
      ),
      children: [
        {
          name: 'phone_number',
          Wrapper: renderFieldWrapper(
            'phone',
            isRequired
          ),
          isRequired,
          hideError: true,
          component:
            createControlledFormField({
              type: 'tel',
              disabled: isDisabled(
                'phone_number'
              ),
              placeholder: 'phone',
            }),
        },
      ],
    },
    {
      invisible: isInvisible(
        'date_of_birth'
      ),
      children: [
        {
          name: 'date_of_birth',
          isRequired,
          hideError: true,
          Wrapper: renderFieldWrapper(
            'date of birth',
            isRequired
          ),
          component: ({
            name,
            value,
            onChange = Null,
          }) => (
            <Translate>
              {(t) => (
                <DatePicker
                  name={name}
                  defaultValue={
                    value
                      ? moment(
                          new Date(
                            value
                          )
                        )
                      : undefined
                  }
                  placeholder={t(
                    'birthday'
                  )}
                  className="w-full"
                  suffixIcon={
                    <IoCalendarOutline />
                  }
                  format={
                    wishareConfigs.dateFormat
                  }
                  disabled={isDisabled(
                    'date_of_birth'
                  )}
                  onChange={(
                    date,
                    dateString
                  ) => {
                    onChange(
                      createValue(
                        name,
                        date
                      )
                    )
                  }}
                />
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      invisible: isInvisible('gender'),
      children: [
        {
          name: 'gender',
          Wrapper: renderFieldWrapper(
            'gender',
            isRequired
          ),
          isRequired,
          hideError: true,
          component: compose(
            withTranslate,
            mapProps(
              ({
                value,
                translate,
                ...props
              }) => ({
                ...props,
                defaultValue: value,
                disabled:
                  isDisabled('gender'),
                buttonStyle: 'solid',
                className:
                  'CustomRadio',
                children: Object.values(
                  GenderTypes
                ).map(
                  ({
                    name,
                    value,
                    label,
                  }) => (
                    <Radio.Button
                      key={name}
                      value={value}>
                      {translate(label)}
                    </Radio.Button>
                  )
                ),
              })
            )
          )(Radio.Group),
        },
      ],
    },
  ].filter(Visible)

const DonorRequiredFields = ({
  Wrapper = 'div',
  isRequired = true,
}) => {
  const { values } = useFormikContext()

  const {
    donor,
    required_information_fields,
  } = values || {}

  const requiredFields = Array.from(
    required_information_fields || []
  )
  const requiredValues = _.pick(
    donor,
    requiredFields
  )

  const formSchema = useMemo(() => {
    return createFormSchema({
      isRequired,
      // isDisabled: (name) =>
      //   !!_.get(requiredValues, name),
      isInvisible: (name) =>
        !requiredFields.includes(name),
    })
  }, [isRequired, requiredFields])

  return (
    <Pure
      input={[
        donor,
        requiredValues.email,
        requiredValues.gender,
        requiredValues.phone_number,
        requiredValues.date_of_birth,
      ]}>
      <Wrapper>
        <FieldsFactory
          formSchema={formSchema}
        />
      </Wrapper>
    </Pure>
  )
}

export default DonorRequiredFields
