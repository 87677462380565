import { recruitment_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { withProps } from 'recompose'
import OwnerPicker from 'views/Wishare/custom/OwnerPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [{ name: 'owner' }]

const formSchema = [
  {
    title: 'owner',
    children: [
      {
        name: 'owner',
        component: withProps(
          ({
            name,
            form,
            value,
            onChange,
          }) => {
            const { values } =
              form || {}
            const { id, type } =
              values || {}
            return {
              id,
              type,
              name,
              onChange,
              defaultValue: value,
            }
          }
        )(OwnerPicker),
      },
    ],
  },
]

const RecruitmentSettingOwner = () => {
  const { recruitment } = useContext(
    RecruitmentContext
  )

  const validationSchema = undefined

  const recruitment_id = getId(
    recruitment
  )

  const recruitment_type = getType(
    recruitment
  )

  const onPreSubmit = ({
    id,
    type,
    owner,
    ...values
  }) => {
    const [owner_id, owner_type] = [
      getId(owner),
      getType(owner),
    ]
    return {
      ...values,
      owner_id,
      owner_type,
    }
  }

  const initialized = useCallback(
    ({ owner, ...values }) => ({
      ...values,
      owner,
      id: recruitment_id,
      type: recruitment_type,
    }),
    [recruitment_id, recruitment_type]
  )

  if (_.isEmpty(recruitment))
    return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: recruitment,
    validationSchema,
    query: bindQueryParam({
      recruitment_id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: recruitment_update_api,
  })
}

export default RecruitmentSettingOwner
