import {
  Input,
  Radio,
  Tooltip,
} from 'antd'
import { organization_Edit_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import classnames from 'classnames'
import { OrganizationBannerDefault } from 'conts/imageBase64'
import { ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
  nest,
  pure,
  withProps,
} from 'recompose'
import OwnerPicker from 'views/Wishare/custom/OwnerPicker'
import { PhotoUploadComposite } from 'views/Wishare/custom/PhotoField'
import SelectDataSource from 'views/Wishare/custom/SelectDataSource'
import { WishareEntities } from 'views/Wishare/enums'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredOneOf,
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import OrganizationContainerContext from 'views/Wishare/Organization/OrganizationContainerContext'
import * as Yup from 'yup'

const formFields = [
  { name: 'title' },
  { name: 'owner' },
  { name: 'auth_code' },
  { name: 'idname' },
  { name: 'avatar' },
  { name: 'org_type' },
  { name: 'org_types' },
  { name: 'description' },
  { name: 'cover_photo' },
  { name: 'avatar_preview' },
  { name: 'cover_photo_preview' },
  { name: 'privacy', defaultValue: 1 },
]

const MegaRadioGroup = ({
  Wrapper = 'div',
  options = [],
  ...props
}) => {
  const t = useTranslate()

  if (_.isEmpty(options)) return null

  return (
    <Wrapper className="verticalList__small">
      <Radio.Group {...props}>
        {options.map(
          ({ label, value }) => (
            <React.Fragment key={value}>
              <Radio.Button
                value={value}>
                {t(label)}
              </Radio.Button>
            </React.Fragment>
          )
        )}
      </Radio.Group>
      <div className="rounded-lg border verticalList__small">
        {options.map(
          ({
            label,
            value,
            description,
          }) => (
            <div
              className={classnames(
                'p-3',
                value === props.value
                  ? 'bg-primary-50'
                  : 'background',
                value === 1
                  ? ' rounded-t-lg'
                  : 'rounded-b-lg'
              )}
              key={value}>
              <div
                className={classnames(
                  'font-bold',
                  value === props.value
                    ? 'text-primary'
                    : 'text-color-200'
                )}>
                {t(label)}
              </div>
              <div
                className={classnames(
                  'text-sm font-light',
                  value === props.value
                    ? 'text-primary'
                    : 'text-color-300'
                )}>
                {t(description)}
              </div>
            </div>
          )
        )}
      </div>
    </Wrapper>
  )
}

const formSchema = [
  {
    title: 'organization type',
    children: [
      {
        name: 'org_type',
        component:
          createControlledFormField({
            Component: SelectDataSource,
            placeholder: 'Select...',
            withValues: (values) => ({
              dataSource: _.get(
                values,
                'org_types'
              ),
              defaultValue: _.get(
                values,
                'org_type'
              ),
            }),
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'avatar',
        component: mapProps(
          ({ form, onChange }) => {
            const values = _.get(
              form,
              'values'
            )
            return {
              values,
              onChange,
              title: _.get(
                values,
                'title'
              ),
              fallback_cover:
                OrganizationBannerDefault,
            }
          }
        )(PhotoUploadComposite),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component: compose(
          mapProps(
            ({ onChange, value }) => ({
              onChange,
              value,
              name: 'title',
              placeholder:
                'name of your company',
              type: 'text',
            })
          )
        )(Input),
      },
    ],
  },
  {
    title: 'owner',
    children: [
      {
        name: 'owner',
        component: compose(
          fromRenderProps(
            OrganizationContainerContext.Consumer,
            ({ organization }) => ({
              id: getId(organization),
              type: getType(
                organization
              ),
            })
          ),
          mapProps(
            ({
              id,
              type,
              name,
              value,
              onChange,
            }) => ({
              id,
              type,
              name,
              onChange,
              defaultValue: value,
            })
          )
        )(OwnerPicker),
      },
    ],
  },
  {
    title: 'auth code',
    children: [
      {
        name: 'auth_code',
        component:
          createControlledFormField({
            placeholder: 'auth code',
            readOnly: true,
            Component: Input,
          }),
      },
    ],
  },
  {
    title: 'description',
    children: {
      name: 'description',
      component:
        createControlledFormField({
          Component: Input.TextArea,
          rows: 5,
          placeholder:
            'Short description about your organization',
        }),
    },
  },
  {
    title: 'url',
    invisible: true,
    children: {
      name: 'idname',
      component: compose(
        mapProps(({ name, value }) => ({
          name,
          value,
          addonBefore: [
            ROOT_URL,
            WishareEntities.ORGANIZATION,
          ].join('/'),
          placeholder:
            'please input name!',
          trigger: ['focus'],
          placement: 'topLeft',
          disabled: true,
          title: [
            ROOT_URL,
            WishareEntities.ORGANIZATION,
            value,
          ].join('/'),
        })),
        pure
      )(nest(Tooltip, Input)),
    },
  },
  {
    title: 'privacy',
    children: [
      {
        name: 'privacy',
        component: compose(
          withProps({
            buttonStyle: 'solid',
            name: 'privacy',
          })
        )(
          ({
            name,
            value,
            onChange,
            defaultValue,
          }) => (
            <MegaRadioGroup
              {...{
                name,
                value,
                onChange,
                defaultValue,
              }}
              buttonStyle="solid"
              options={[
                {
                  value: 1,
                  label: 'public',
                  description:
                    'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this',
                },
                {
                  value: 2,
                  label: 'private',
                  description:
                    'Only members can view data content, post articles or comment on this',
                },
              ]}
            />
          )
        ),
      },
    ],
  },
]

const OrganizationSettingOverview =
  () => {
    const t = useTranslate()

    const {
      org_types: org_type_options,
    } = useAppConfig()

    const { organization } = useContext(
      OrganizationContainerContext
    )

    const id = getId(organization)

    const org_types = Object.keys(
      org_type_options
    ).map((name) => ({
      value: Number(name),
      label: org_type_options[name],
    }))

    const validationSchema = useMemo(
      () =>
        yupShape({
          title: requiredString(t),
          org_type: requiredOneOf(
            org_types.map(
              ({ value }) => value
            ),
            t,
            Yup.number()
          ),
        }),
      [t]
    )

    const initialized = useCallback(
      ({
        avatar,
        org_type,
        cover_photo,
        ...values
      }) => ({
        ...values,
        org_type: org_type
          ? Number(org_type)
          : undefined,
        avatar_preview: avatar,
        cover_photo_preview:
          cover_photo,
      }),
      []
    )

    const onPreSubmit = ({
      owner,
      org_types,
      avatar_preview,
      cover_photo_preview,
      ...values
    }) => ({
      ...values,
      ...(owner
        ? {
            owner_id: getId(owner),
            owner_type: getType(owner),
          }
        : {}),
    })

    if (_.isEmpty(organization))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      validationSchema,
      item: {
        ...organization,
        org_types,
      },
      query: bindQueryParam({
        id,
      }),
      onPreSubmit,
      initialized,
      apiInfo: organization_Edit_Api,
    })
  }

export default OrganizationSettingOverview
