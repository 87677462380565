import {
  Avatar,
  Image,
  Skeleton,
} from 'antd'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import { DefaultImageActivity } from 'conts/imageBase64'
import getAvatar from 'helpers/getAvatar'
import getCoverPhoto from 'helpers/getCoverPhoto'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import * as moment from 'moment'
import React from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'
import wishareModel from 'views/Wishare/wishareModel'
import {WishareRibbonSvg} from "../../../../components/Svgs/WishareRibbonSvg";

const ImageItem = ({
  className = 'h-40',
  isLoading = false,
  isPlaceholder = false,
  ...props
}) => {
  if (isLoading || isPlaceholder) {
    return (
      <Skeleton.Image
        active={isLoading}
        style={{
          width: '100%',
          height: '100%',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius:
            '0.5rem',
          borderColor: 'transparent',
        }}
        className={classNames(
          'w-full',
          className
        )}
      />
    )
  }
  return (
    <Image
      preview={false}
      className={classNames(
        'w-full',
        className
      )}
      width="100%"
      style={{
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
      }}
      {...props}
    />
  )
}

const PlaceholderItem = ({
  style,
  className,
  isLoading = false,
}) => (
  <div
    style={style}
    className={classNames(
      'flex flex-col w-full shadow rounded-lg',
      className
    )}>
    <ImageItem
      isPlaceholder={true}
      isLoading={isLoading}
    />
    <div className="flex items-center p-2 gap-2">
      <Skeleton
        avatar={{
          shape: 'circle',
          size: 40,
        }}
        title={false}
        active={isLoading}
        isLoading={isLoading}
        paragraph={{ rows: 2 }}
      />
    </div>
  </div>
)

const renderRecentEvent = (
  container,
  index
) => {
  if (_.isEmpty(container)) return null

  const {
    owner: item,
    isLoading = false,
    isPlaceholder = false,
  } = container || {}

  const { created } = item || {}

  return (
    <div
      key={index}
      className="p-2">
      {!!isPlaceholder ? (
        <PlaceholderItem
          isLoading={isLoading}
        />
      ) : (
        <div className="flex flex-col w-full rounded-lg shadow hover:shadow-out relative">
          <ImageItem
            src={
              _.get(item, 'photo') ||
              getCoverPhoto(item)
            }
            isLoading={isLoading}
            isPlaceholder={
              isPlaceholder
            }
            className="h-40 object-cover"
          />
          <OverlayItemBanner
            type={getType(item)}
          />
          <div className="flex items-center p-2 gap-2">
            <Avatar
              size={40}
              icon={<IoPerson className="text-color-200"/>}
              className="flex flex-center background-200"
              src={_.get(
                item,
                'owner.avatar'
              )}
            />
            <div className="flex flex-1 flex-col">
              <Link
                to={wishareModel.getLinkToDetail(
                  item
                )}
                className="font-medium text-sm text-color-000 no-underline max-lines-1">
                {getTitle(item)}
              </Link>
              {created && (
                <span className="font-light text-color-500 text-xs italic">
                  {moment(
                    created
                  ).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const renderRecentActivity = (
  item,
  index
) => {
  if (_.isEmpty(item)) return null

  const {
    created,
    isLoading = false,
    isPlaceholder = false,
  } = item

  return (
    <div
      key={index}
      className="p-2">
      {!!isPlaceholder ? (
        <PlaceholderItem
          isLoading={isLoading}
        />
      ) : (
        <div className="rounded-lg shadow hover:shadow-out relative">
          <ImageItem
            src={
              _.get(
                item,
                'cover_photo'
              ) || getCoverPhoto(item)
            }
            isLoading={isLoading}
            isPlaceholder={
              isPlaceholder
            }
            className="h-40 object-cover"
            fallback={
              DefaultImageActivity
            }
          />
          <div className="flex items-center p-2 gap-2">
            <Avatar
              size={40}
              icon={<WishareRibbonSvg width="1rem" height="1rem" fill="currentColor"/>}
              className="flex flex-center background-200"
              src={getAvatar(item)}
            />
            <div className="flex flex-1 flex-col">
              <Link
                to={wishareModel.getLinkToDetail(
                  item
                )}
                className="font-medium text-sm text-color-000 no-underline max-lines-1">
                {getTitle(item)}
              </Link>
              {created && (
                <span className="font-light text-color-500 text-xs italic">
                  {moment(
                    created
                  ).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {
  renderRecentEvent,
  renderRecentActivity,
}
