import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import BeneficiariesByOwner from '../Beneficiary/components/BeneficiariesByOwner'
import DonorsByOwner from '../Donor/components/DonorsByOwner'
import StaffsByOwner from '../Staff/components/StaffsByOwner'
import UserJobProfile from './components/UserJobProfile'
import UserNews from './holders/UserNews'
import UserContext from './UserContext'

const UserStaffs = React.memo(() => (
  <UserContext.Consumer>
    {({ user }) => (
      <StaffsByOwner
        owner_id={getId(user)}
        owner_type={getType(user)}
      />
    )}
  </UserContext.Consumer>
))

const UserDonors = React.memo(() => (
  <UserContext.Consumer>
    {({ user }) => (
      <DonorsByOwner
        owner_id={getId(user)}
        owner_type={getType(user)}
      />
    )}
  </UserContext.Consumer>
))

const UserBeneficiaries = React.memo(
  () => (
    <UserContext.Consumer>
      {({ user }) => (
        <BeneficiariesByOwner
          owner_id={getId(user)}
          owner_type={getType(user)}
        />
      )}
    </UserContext.Consumer>
  )
)

export const userRouteEntities = [
  {
    path: 'news',
    component: UserNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'donors',
    component: UserDonors,
    breadcrumbName: (t) => t('sponsor'),
    isInvisible: ({ item }) =>
      !_.get(
        item,
        'donation_view_privacy'
      ),
  },
  {
    path: 'beneficiaries',
    component: UserBeneficiaries,
    isInvisible: ({ item }) =>
      !_.get(
        item,
        'giving_view_privacy'
      ),
    breadcrumbName: (t) =>
      t('beneficiary'),
  },
  {
    path: 'staffs',
    component: UserStaffs,
    breadcrumbName: (t) => t('work'),
    isInvisible: ({ item }) =>
        !_.get(
            item,
            'staff_view_privacy'
        ),
  },
  {
    path: 'profile',
    component: UserJobProfile,
    breadcrumbName: (t) =>
      t('my profile'),
    isInvisible: ({ item }) =>
      !!!_.get(
        item,
        'recruitment_available'
      ),
  },
]
