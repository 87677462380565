import {
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons'
import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
} from 'recompose'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { WishareEntities } from 'views/Wishare/enums'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import DonorContext from '../DonorContext'
import DonorSettingOverview from './DonorSettingOverview'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <SettingOutlined />,
  },
  // {
  //   key: 'delete',
  //   label: 'delete',
  //   icon: <LinkOutlined />,
  // },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: DonorSettingOverview,
  },
  // {
  //   path: 'delete',
  //   label: 'delete',
  //   component: () => (
  //     <DonorContext.Consumer>
  //       {({ donor }) =>
  //         createDeleteEntity(donor)
  //       }
  //     </DonorContext.Consumer>
  //   ),
  // },
].map(
  ({
    path,
    component: Component,
    ...rest
  }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const donorSettings = {
  actions: [
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <SettingOutlined />,
    },
  ],
  menuItems,
  routeEntities,
}

const DonorSettings = ({
  id,
  ...props
}) =>
  createSettingModule(
    [WishareEntities.DONOR],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(
    DonorContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({ match }) => ({
    id: _.get(match, 'params.id'),
  }))
)(DonorSettings)
