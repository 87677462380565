import {
  Avatar,
  Card,
  List,
  Result,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { candidateSchema } from 'apis/schema/candidateSchema'
import EmptyHolder from 'components/EmptyHolder'
import { FieldHeader } from 'components/form/FieldsFactory'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import Toggle from 'components/Toggle'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import moment from 'moment'
import React from 'react'
import {
  branch,
  compose,
  fromRenderProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import NoFoundPage from 'views/NoFoundPage'
import {
  Null,
  renderSelf,
} from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import {
  GenderTypes,
  getProp,
} from 'views/Wishare/wishareFieldTypes'
import RecruitmentCandidateProfile from '../components/RecruitmentCandidateProfile'
import {IoPerson} from "react-icons/io5";

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const NotFoundPage = () => (
  <div className="flex w-full justify-center">
    <NoFoundPage />
  </div>
)

const GroupWrapper = ({
  title,
  children,
}) => (
  <Card
    size="small"
    title={title}>
    {children}
  </Card>
)

const CandidateInformation = ({
  item,
  Wrapper = 'div',
}) => {
  const t = useTranslate()
  const {
    email,
    gender,
    phone_number,
    date_of_birth,
  } = item || {}
  const information = [
    {
      name: 'gender',
      label: 'gender',
      value: gender,
      transform: (value) => {
        const gender = getProp(
          Object.values(GenderTypes),
          'label'
        )(Number(value))
        return _.isString(gender)
          ? t(gender)
          : null
      },
    },
    {
      name: 'date_of_birth',
      label: 'birthday',
      value: date_of_birth,
      transform: (value) =>
        value
          ? moment(value).format(
              wishareConfigs.dateFormat
            )
          : undefined,
    },
    {
      name: 'email',
      label: 'email',
      value: email,
      transform: (value) =>
        value && (
          <a href={`mailto:${value}`}>
            {value}
          </a>
        ),
    },
    {
      name: 'phone_number',
      label: 'phone',
      value: phone_number,
      transform: (value) =>
        value && (
          <a href={`tel:${value}`}>
            {value}
          </a>
        ),
    },
  ]
  return (
    <Wrapper className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {information.map(
        (
          {
            label,
            value,
            transform = renderSelf,
          },
          index
        ) => {
          const content =
            transform(value)
          if (_.isEmpty(content)) {
            return (
              <React.Fragment
                key={index}
              />
            )
          }
          return (
            <FieldHeader
              key={index}
              title={label}
              renderLabel={(label) => (
                <div className="text-xs font-light text-color-500 italic">
                  {[label, ': '].join('')}
                </div>
              )}
              Wrapper={({
                children,
              }) => (
                <div className="flex">
                  {children}
                </div>
              )}
              className="items-center">
              <span className="font-bold text-sm text-color-100">
                {content}
              </span>
            </FieldHeader>
          )
        }
      )}
    </Wrapper>
  )
}

const ApplicationReviewForm = ({
  item,
  profile,
  children,
  renderItem,
  Wrapper = 'div',
  renderHeader = Null,
  owner,
}) => {
  const t = useTranslate()

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const entity = useSelectEntities(
    id,
    candidateSchema,
    item
  )

  const user = _.get(
    entity,
    'candidate'
  )

  const isPending = Number(_.get(item, 'status')) === -1

  const isReject = Number(_.get(item, 'status')) === -2


  const RecruitmentAgency = () => {
    return (
      <HeaderItemInfo
        title={t('recruitment agency')}>
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row items-start md:items-center mb-2">
            <span className="flex-1 font-bold text-green-800">
              {_.get(
                item,
                'recruitment_event.title'
              )}
            </span>
          </div>
          <div className="flex flex-col md:flex-row items-start md:items-center">
            <span className="text-xs text-color-500 italic mr-2">
              {t('owner')}
              {':'}
            </span>
            <div className="flex items-center space-x-2">
              <Avatar
                src={_.get(
                  owner,
                  'avatar'
                )}
              />
              <span className="flex-1 text-color-org font-bold">
                {_.get(owner, 'title')}
              </span>
            </div>
          </div>
        </div>
      </HeaderItemInfo>
    )
  }

  const CodeInfo = () => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2 text-xs text-color-500 italic mb-2">
          <span>{t('code')}</span>
          <span className="text-secondary font-bold text-base leading-none">
            {_.get(item, 'idcode')}
          </span>
          <span className="font-bold">
            {'-'}
          </span>
          <span>
            {moment(
              _.get(item, 'created')
            ).format(
              'HH:mm - DD/MM/YYYY'
            )}
          </span>

          {isPending ? (
            <span className="text-primary font-semibold text-sm italic">
            {t('pending')}
          </span>
          ) : isReject ? (
            <span className="text-orange-600 font-semibold text-sm italic">
            {t('reject')}
          </span>
          ) : (
            <span className="text-green-500 font-semibold text-sm italic">
            {t('approved')}
          </span>
          )}
        </div>
      </div>
    )
  }

  if (
    _.some([entity, user], _.isEmpty)
  ) {
    return <EmptyHolder />
  }

  return (
    <Wrapper className="flex flex-col space-y-2 gap-2">
      {renderHeader(entity, user)}
      {renderItem ? (
        renderItem(entity)
      ) : (
        <React.Fragment>
          <RecruitmentAgency />
          <HeaderItemInfo
            title={t(
              'profile information'
            )}>
            <CodeInfo />
            <div className="flex items-center gap-2">
              <Avatar
                  size={30}
                  className="flex flex-center background-100"
                  icon={<IoPerson className="text-color-300"/>}
                  src={getAvatar(user)}
              />
              <span className="text-color-000 font-bold">
                {getTitle(user)}
              </span>
            </div>
          </HeaderItemInfo>
          <GroupWrapper
            title={t('information')}>
            <CandidateInformation
              item={entity}
              meta={user}
            />
          </GroupWrapper>
          <Toggle>
            {(isToggle, toggle) => {
              return (
                <React.Fragment>
                  {isToggle && (
                    <RecruitmentCandidateProfile
                      profile={profile}
                      candidate={entity}
                    />
                  )}
                  <div className="w-full text-center">
                    <span
                      onClick={toggle}
                      className="text-xs text-color-400 hover:text-color-000 italic capitalize cursor-pointer">
                      {isToggle
                        ? t('less')
                        : t('more')}
                    </span>
                  </div>
                </React.Fragment>
              )
            }}
          </Toggle>
        </React.Fragment>
      )}
      {children}
    </Wrapper>
  )
}

export default createRequiredLogin()(
  compose(
    fromRenderProps(
      PermissionContext.Consumer,
      (props) => ({
        edit: _.get(
          props,
          permissionCodes.canEdit
        ),
      })
    ),
    branch(
      ({ edit }) => !edit,
      () => AccessDenied
    ),
    branch(
      ({ item }) => _.isEmpty(item),
      () => NotFoundPage
    )
  )(ApplicationReviewForm)
)
