import React from 'react'
import createStickySider from 'views/Wishare/factory/createStickySider'
import UserImpactReport from './UserImpactReport'
import UserMedals from './UserMedals'

const UserSticker = () => (
  <React.Fragment>
    <UserMedals />
  </React.Fragment>
)

const UserSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(UserImpactReport, UserSticker)

export default UserSider
// branch(({ invisible }) => {
//   const { isSm } = useContext(
//     LayoutContext
//   )
//   return Boolean(!invisible && isSm)
// }, renderNothing)
