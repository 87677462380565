import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import React, {
  useCallback,
} from 'react'
import { WishareHeader } from 'views/MainPage/MainHeader'
import {
  DefaultLayout,
  Null,
  renderOwnChild,
  TemplateTypes,
  withRightSider,
} from 'views/Shared'
import { ModuleLayoutWrapper } from 'views/Wishare/factory/createModule'
import DefaultTemplate from '../Templates/DefaultTemplate'
import TopicHeader from './components/TopicHeader'
import TopicMenu from './components/TopicMenu'

const TopicLayout = ({
  Sider = Null,
  Banner = Null,
  Content = Null,
}) => {
  const renderMenu = useCallback(
    () => (
      <WishareSiderMenu
        Wrapper={renderOwnChild}>
        <TopicMenu />
      </WishareSiderMenu>
    ),
    []
  )

  const siders = withRightSider(Sider)

  return (
    <ModuleLayoutWrapper
      Header={WishareHeader}
      visibleLeftDrawer={true}>
      <DefaultTemplate
        renderMenu={renderMenu}>
        <DefaultLayout
          siders={siders}
          Content={Content}
          Header={TopicHeader}
          type={
            TemplateTypes.RIGHT_SIDER
          }
        />
      </DefaultTemplate>
    </ModuleLayoutWrapper>
  )
}

export default TopicLayout
