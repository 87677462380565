import { Modal } from 'antd'
import React from 'react'
import { renderSelf } from 'views/Shared'

const createConfirmDialog = ({
  onOk,
  title,
  message,
  translate = renderSelf,
  ...props
}) =>
  Modal.confirm({
    className: 'custom-modal',
    title: (
      <div className="font-bold text-lg text-orange-500">
        {translate(title)}
      </div>
    ),
    content: (
      <div className="text-sm font-light italic text-color-300">
        {translate(message)}
      </div>
    ),
    onOk,
    ...props,
  })

export const showDeleteConfirmDialog =
  ({
    onOk,
    title = 'are you sure',
    message = 'are you sure to delete this?',
    translate = renderSelf,
    ...props
  }) =>
    createConfirmDialog({
      ...props,
      onOk,
      title: translate(title),
      message: translate(message),
      okText: translate('sure'),
      okType: 'primary',
      cancelText: translate('not sure'),
      okButtonProps: {
        danger: true,
        className: 'rounded-lg no-shadow no-text-shadow no-border',
      },
      cancelButtonProps: {
        className:
          'rounded-lg no-shadow no-text-shadow no-border',
      },
    })

export default createConfirmDialog
