import React, {useContext} from 'react'
import { BoxPlaceholder } from 'views/Shared'
import TopicContext from "../TopicContext";
import {Avatar, Col, Row} from "antd";
import _ from "lodash";
import Icon from "@ant-design/icons";
import {IoLogoRss} from "react-icons/io";
import {IoSettingsOutline, IoShareSocialOutline} from "react-icons/io5";
import useTranslate from "../../../../modules/local/useTranslate";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const TopicHeader = ({
  Wrapper = 'div',
}) => {
    const t = useTranslate()
    const {isSm} = useContext(LayoutContext)
    const {topic} = useContext(TopicContext)

    const description = _.get(topic, 'description')


    const ItemInfo = ({label, value}) => (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 flex items-center">
            <span className="ml-2 italic text-xs text-color-400 font-light">
                {t(label)}
            </span>
            <span className="font-bold text-sm text-color-000">{value}</span>
        </div>
    )

  return (
      <Wrapper className="mb-6">
          <Row className="m-row-0">

              <Col span={24} className="pr-2 relative">
                <div className="text-lg md:text-xl uppercase font-bold text-primary tracking-wide leading-tight">
                  {_.get(topic, 'title')}
                </div>
                {
                  !!description && (
                    <div className="text-color-300 text-sm font-light italic max-lines-2 leading-5">
                      {description}
                    </div>
                  )
                }
                {/*<div className="flex flex-col mt-1 md:mt-3">*/}
                {/*  {!!_.get(topic, 'total_followers') &&(*/}
                {/*    <ItemInfo label='followers' value={_.get(topic, 'total_followers')} />*/}
                {/*  )}*/}
                {/*  {!!_.get(topic, 'total_queries') &&(*/}
                {/*    <ItemInfo label='link' value={_.get(topic, 'total_queries')} />*/}
                {/*  )}*/}
                {/*  {!!_.get(topic, 'total_views') &&(*/}
                {/*    <ItemInfo label='views' value={_.get(topic, 'total_views')} />*/}
                {/*  )}*/}


                {/*</div>*/}

              </Col>
          </Row>
      </Wrapper>
  )
}

export default TopicHeader
