import {Alert, Avatar, Button, List, message, Tooltip, Upload,} from 'antd'
import {getId, getType,} from 'apis/model/base'
import {handleconfirm} from 'components/Confirm'
import Toggle from 'components/Toggle'
import copyToClipboard from 'copy-to-clipboard'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext, useEffect, useRef, useState,} from 'react'
import {IoIosHelpCircle} from 'react-icons/io'
import {
  IoCheckmarkCircleOutline,
  IoCloudUploadOutline,
  IoCopyOutline,
  IoFlowerOutline,
  IoGiftOutline,
} from 'react-icons/io5'
import LoadingPage from 'views/LoadingPage'
import {AcceptFileTypes} from 'views/Messages/DragDropFile'
import NoFoundPage from 'views/NoFoundPage'
import {Null, renderOwnChild,} from 'views/Shared'
import {donationApi, givingApi,} from '../apis'
import DonationContext, {DonationProvider,} from '../Donation/DonationContext'
import {WishareEntities} from '../enums'
import {bindQueryParam} from '../functions/routerHelper'
import GivingContext, {GivingProvider,} from '../Giving/GivingContext'
import {notifyOnError} from './createErrorEvent'

const donation_template_url =
  'https://docs.google.com/spreadsheets/d/1ePHAUphrcUCgbhibELtivWyEB67omkreqLA5U3XLM9c/edit?usp=sharing'
const giving_template_url =
  'https://docs.google.com/spreadsheets/d/1xlvkV7Xrb15GlHXx5sgyjLLcNQApMGse60jgiPUsOT4/edit?usp=sharing'

const Downloader = ({
                      children,
                      downloadURL,
                      delay = 3000,
                      auto = false,
                      onSuccess = Null,
                    }) => {
  const t = useTranslate()

  const timer = useRef()

  useEffect(() => {
    if (!!downloadURL && auto) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        onSuccess({downloadURL})
      }, delay)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [downloadURL, auto])

  return (
    <div>
      {!!downloadURL && (
        <div className="my-6 border border-green-500 rounded-lg">
          <Alert
            type="success"
            banner={true}
            showIcon={false}
            message={
              <div
                className="text-green-600"
                role="alert">
                <div className="flex flex-row items-center py-1">
                  <IoCheckmarkCircleOutline className="text-green-500 text-3xl mr-3"/>
                  <div>
                    <strong className="font-semibold text-lg">
                      {t(
                        'data has been generate'
                      )}{' '}
                      {'!'}
                    </strong>
                    <span className="block sm:inline italic font-light text-sm">
                      {t(
                        'please click the {download} button below to download to your device',
                        {
                          download: (
                            <strong className="font-semibold">
                              {' '}
                              {t(
                                'download'
                              )}{' '}
                            </strong>
                          ),
                        }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}

      <div className="flex justify-center">
        {!!downloadURL ? (
          <Button
            className="no-shadow no-text-shadow rounded-lg"
            type="primary">
            <a
              className="no-underline"
              target="_blank"
              href={downloadURL}
              rel="noopener noreferrer">
              {t('download')}
            </a>
          </Button>
        ) : (
          children || null
        )}
      </div>
    </div>
  )
}

const renderSelectedFile = (file) => (
  <Translate>
    {(t) =>
      file ? (
        <div className="flex flex-col items-center">
          <IoCloudUploadOutline
            size={40}
            className="text-primary"
          />
          <span className="text-xs font-medium text-color-100">
            {file.name} {' - '}
            <span className="text-color-000 italic">
              {(file.size / 1024)
                .toFixed(2)
                .toLocaleString()}
              {' kB'}
            </span>
          </span>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <IoCloudUploadOutline
            size={40}
            style={{color: '#e0e0e0'}}
          />
          <p className="text-xs font-medium text-color-200">
            {t('click to upload')}
          </p>
        </div>
      )
    }
  </Translate>
)

const ItemNotes = ({
                     name,
                     label,
                     column,
                     optional,
                     HelperComponent = Null,
                   }) => (
  <Translate>
    {(t) => (
      <div className="flex items-center text-color-300 gap-1 text-xs">
        <span className="text-primary font-bold">
          {column}{' '}
        </span>
        {' - '}
        <span className="text-color-200 font-bold">
          {name}
          {': '}
        </span>
        <span className="text-color-400 italic font-light">
          {t(label)}
        </span>
        {optional && (
          <span className="text-color-400 italic font-light">
            {`(${t('optional')})`}
          </span>
        )}
        <HelperComponent/>
      </div>
    )}
  </Translate>
)

const DescriptionTemplate = ({
                               inputHelpers = [],
                             }) => {
  const t = useTranslate()
  return (
    <Toggle>
      {(isToggle, toggle) => (
        <div style={{background: '#fafafa'}} className="flex flex-col p-3 border rounded-lg border-dashed">
          <div className="space-y-1">
            <span className="text-sm italic text-color-100">
              {t('notes description')}
              {':'}
            </span>
            <div className="space-y-1 pl-3">
              {Array.from(inputHelpers)
                .filter(
                  (e) =>
                    isToggle ||
                    !!!e.optional
                )
                .map((e, i) => (
                  <ItemNotes
                    key={i}
                    {...e}
                  />
                ))}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="border-b flex-1 ml-3"/>
            <div
              onClick={toggle}
              className="text-xs font-light italic cursor-pointer text-color-400 hover:text-color-100">
              {t(
                isToggle
                  ? 'short'
                  : 'see more'
              )}
            </div>
          </div>
        </div>
      )}
    </Toggle>
  )
}

const ImportModalContent = ({
                              item,
                              query,
                              values,
                              apiInfo,
                              onCancel = Null,
                              maxSize = 2048000,
                              Description = Null,
                              templateUrlPath = '',
                              Wrapper = renderOwnChild,
                            }) => {
  const t = useTranslate()

  const {handleGoBack = Null} =
    useContext(NavigationContext)

  const [isInValid, setInvalid] =
    useState()

  const [file, setFile] = useState()

  const handleFile = (file) => {
    const {name, size, ...rest} =
    file || {}
    setInvalid(size > maxSize)
    if (size > maxSize) {
      message.error(
        <div className="flex gap-3">
          <span className="text-color-300 font-light text-sm italic">
            {t('current size')} {' : '}
            <span className="font-medium text-color-000">
              {Math.round(size / 1024)} {' MB'}
            </span>
          </span>
          <span className="text-color-300 font-light text-sm italic">
            {t('max size')} {' : '}
            <span className="font-medium text-color-000">
              {Math.round(maxSize / 1024)} {' MB'}
            </span>
          </span>
        </div>
      )
    } else {
      setFile(file)
    }
  }

  const {
    isLoading = false,
    handleAsyncAction,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onError: notifyOnError(t),
    onSuccess: (__, data) => {
      const {total = 0, success = 0} =
        _.get(data, 'response.data', {})
      handleconfirm({
        title: t('upload file'),
        okText: t('OK'),
        cancelText: t('cancel'),
        okButtonProps: {
          type: 'primary',
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          handleGoBack()
          onCancel()
        },
        content: (
          <Alert
            type="success"
            className="background border-green-600"
            message={
              <div
                className="flex flex-row items-center py-1"
                role="alert">
                <div className="flex flex-col text-sm">
                  <span className="text-color-300 font-light italic">
                    {t('total row')}{' '}
                    {' : '}
                    <span className="font-medium text-color-000">
                      {total} {t('row')}
                    </span>
                  </span>
                  <span className="text-color-300 font-light italic">
                    {t('success row')}{' '}
                    {' : '}
                    <span className="font-medium text-green-700">
                      {success}{' '}
                      {t('row')}
                    </span>
                  </span>
                </div>
              </div>
            }
          />
        ),
      })
    },
  })

  if (!item || _.isEmpty(item)) {
    return <NoFoundPage/>
  }

  return (
    <Wrapper>
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-col gap-1">
          <span className="text-color-300 uppercase font-medium">
            {t('you are importing campaign data')}{' '}{t(getType(item))}
          </span>
          <div className="flex items-center gap-2 background border p-2 rounded-lg">
            <Avatar
                className="flex flex-center background-100"
                icon={
                  getType(item) === 'donation_event' ? (
                      <IoFlowerOutline className="text-primary"/>
                  ) : (
                      <IoGiftOutline className="text-secondary"/>
                  )
                }
            />
            <div className="flex flex-col">
              <span className="text-color-000 font-bold">
                {getTitle(item)}
              </span>
              <span className="text-secondary text-xs font-light italic">
                {_.get(item, 'owner.title')}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full custom-upload-file">
          <Upload.Dragger
            name="file"
            style={
              isInValid
                ? {
                  borderColor: 'red',
                }
                : undefined
            }
            accept={[
              AcceptFileTypes.XSL,
              AcceptFileTypes.XSL_APP,
              AcceptFileTypes.XSLX_APP,
            ].join(',')}
            onDrop={(event) => {
              handleFile(
                _.get(
                  event,
                  'dataTransfer.files[0]'
                )
              )
            }}
            showUploadList={false}
            beforeUpload={(file) => {
              handleFile(file)
              return false
            }}>
            {renderSelectedFile(file)}
            <p className="text-2xs italic font-light text-color-500">
              {t('limit description')}{' '}
              {': '}
              <span className="font-semibold text-color-100 ml-1">
                {'2MB'}
              </span>
            </p>
          </Upload.Dragger>
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-color-300 uppercase font-medium">
            {t('template data')}
          </span>
          <div className="flex items-center gap-2">
            <a
              target="_blank"
              className="font-light text-xs italic text-color-300 truncate p-2 border background rounded-lg"
              rel="noopener noreferrer"
              href={templateUrlPath}>
              {templateUrlPath}
            </a>

            <Button
              onClick={() => {
                copyToClipboard(
                  templateUrlPath
                )
              }}
              className="no-shadow no-text-shadow rounded-lg"
              type="dashed">
              <IoCopyOutline/>
            </Button>
            <Button
              className="no-shadow no-text-shadow rounded-lg no-border"
              type="primary">
              <a
                target="_blank"
                className="no-underline"
                rel="noopener noreferrer"
                href={templateUrlPath}>
                {t('preview template')}
              </a>
            </Button>
          </div>
        </div>

        <Description/>

        <div className="flex justify-end gap-2">
          <NavigationContext.Consumer>
            {({
                handleGoBack = Null,
              }) => (
              <Button
                className="no-shadow no-text-shadow rounded-lg no-border"
                onClick={() => {
                  handleGoBack()
                }}>
                {t('cancel')}
              </Button>
            )}
          </NavigationContext.Consumer>
          <Button
            type="primary"
            loading={!!isLoading}
            className="no-shadow no-text-shadow rounded-lg no-border"
            disabled={
              !!isLoading || !file
            }
            onClick={() => {
              handleAsyncAction({
                file,
              })
            }}>
            {t('import')}
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

const descriptionDonation = [
  {
    column: 'A',
    name: 'donation_date',
    label: 'contributed date',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Date is required'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-primary text-sm italic">
                  YYYY-MM-DD
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'B',
    name: 'idcode',
    label: 'donation code',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'donation code requires 4 to 10 characters long, uppercase, unique'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-secondary text-sm italic">
                  M1212
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'C',
    name: 'donor_name',
    label: "donor's name",
    optional: false,
  },
  {
    column: 'D',
    name: 'anonymous',
    label: 'anonymous',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Value must be YES or NO'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'E',
    name: 'donation_type',
    label: 'donation type',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Value must be money, goods or blood'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'F',
    name: 'item_name',
    label: 'type',
    optional: false,
  },
  {
    column: 'G',
    name: 'donation_qtty',
    label: 'donation quantity',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'H',
    name: 'donation_unit',
    label: 'donation unit',
    optional: false,
  },
  {
    column: 'I',
    name: 'donation_value',
    label: 'donation value',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'J',
    name: 'email',
    label: 'email',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'For example'
                )}: `}
                <span className="font-light text-sm italic">
                  example@email.com
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'K',
    name: 'phone_number',
    label: 'phone',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'L',
    name: 'gender',
    label: 'gender',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Value must be male, female or null'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'M',
    name: 'date_of_birth',
    label: 'date_of_birth',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Date is required'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-primary text-sm italic">
                  YYYY-MM-DD
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'N',
    name: 'donation_note',
    label: 'donation note',
    optional: true,
  },
  {
    column: 'O, P, Q, R, S, T, U, V, W',
    name: 'others',
    label:
      'additional fields for information',
    optional: true,
  },
]

const descriptionGiving = [
  {
    column: 'A',
    name: 'giving_date',
    label: 'giving date',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Date is required'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-primary text-sm italic">
                  YYYY-MM-DD
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'B',
    name: 'idcode',
    label: 'giving code',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'giving code requires 4 to 10 characters long, uppercase, unique'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-secondary text-sm italic">
                  M1212
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'C',
    name: 'beneficiary_name',
    label: "beneficiary's name",
    optional: false,
  },
  {
    column: 'D',
    name: 'beneficiary_address',
    label: 'beneficiary address',
    optional: true,
  },
  {
    column: 'E',
    name: 'giving_type',
    label: 'giving type',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Value must be "MONEY", "GOODS" or "BLOOD"'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'F',
    name: 'item_name',
    label: 'type',
    optional: false,
  },
  {
    column: 'G',
    name: 'giving_qtty',
    label: 'giving quantity',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'H',
    name: 'giving_unit',
    label: 'giving unit',
    optional: false,
  },
  {
    column: 'I',
    name: 'giving_value',
    label: 'giving value',
    optional: false,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'J',
    name: 'email',
    label: 'email',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'For example'
                )}: `}
                <span className="font-light text-sm italic">
                  example@email.com
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'K',
    name: 'phone_number',
    label: 'phone',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Number is required'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'L',
    name: 'gender',
    label: 'gender',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Value must be male, female or null'
                )}.`}
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'M',
    name: 'date_of_birth',
    label: 'date_of_birth',
    optional: true,
    HelperComponent: () => (
      <Translate>
        {(t) => (
          <Tooltip
            title={
              <div>
                {`${t(
                  'Date is required'
                )}. ${t(
                  'For example'
                )}: `}
                <span className="font-semibold text-primary text-sm italic">
                  YYYY-MM-DD
                </span>
              </div>
            }>
            <IoIosHelpCircle/>
          </Tooltip>
        )}
      </Translate>
    ),
  },
  {
    column: 'N',
    name: 'giving_note',
    label: 'giving note',
    optional: true,
  },
  {
    column: 'O, P, Q, R, S, T, U, V, W',
    name: 'others',
    label:
      'additional fields for information',
    optional: true,
  },
]

export const createDonationImporter = (
  item,
  params
) => (
  <ImportModalContent
    item={item}
    templateUrlPath={
      donation_template_url
    }
    query={bindQueryParam({
      id: getId(item),
    })}
    Description={() => (
      <DescriptionTemplate
        inputHelpers={
          descriptionDonation
        }
      />
    )}
    apiInfo={
      donationApi.donation_events_importDonationTmpData_api
    }
    {...(params || {})}
  />
)

export const createGivingImporter = (
  item,
  params
) => (
  <ImportModalContent
    item={item}
    templateUrlPath={
      giving_template_url
    }
    query={bindQueryParam({
      id: getId(item),
    })}
    Description={() => (
      <DescriptionTemplate
        inputHelpers={descriptionGiving}
      />
    )}
    apiInfo={
      givingApi.giving_events_importGivingTmpData_api
    }
    {...(params || {})}
  />
)

export const ImporterLoader = ({
                                 id,
                                 type,
                                 onCancel,
                               }) => {
  if (_.isEmpty(id)) {
    return null
  }
  switch (type) {
    case WishareEntities.GIVING:
      return (
        <GivingProvider item={id}>
          <GivingContext.Consumer>
            {({giving, isLoading}) =>
              !!isLoading ? (
                <LoadingPage/>
              ) : (
                createGivingImporter(
                  giving,
                  {onCancel}
                )
              )
            }
          </GivingContext.Consumer>
        </GivingProvider>
      )
    case WishareEntities.DONATION:
      return (
        <DonationProvider item={id}>
          <DonationContext.Consumer>
            {({
                donation,
                isLoading,
              }) =>
              !!isLoading ? (
                <LoadingPage/>
              ) : (
                createDonationImporter(
                  donation,
                  {onCancel}
                )
              )
            }
          </DonationContext.Consumer>
        </DonationProvider>
      )
    default:
      return null
  }
}
