import { Input } from 'antd'
import Pure from 'components/Pure'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  IoChevronDown,
  IoChevronUp,
} from 'react-icons/io5'
import {
  useLocation,
  useSearchParam,
  useToggle,
} from 'react-use'
import {
  Null,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import {
  DefaultAdvancedToggle,
  ExpandablePanel,
} from '../custom/AdvancedFormGroup'
import EntityList, {
  EntityPaginationMeta,
} from '../EntityList'

const EmptySortBy = ({
  value,
  onSelect,
}) => null

const EmptyAdvancedFilter = ({
  params,
  value,
  onChange,
}) => null

const renderDefaultSort = (
  child,
  { title }
) => (
  <div className="flex flex-col space-y-2">
    <div className="flex items-center justify-between">
      <span className="uppercase text-sm font-semibold text-color-200">
        {title}
      </span>
      <div className="flex items-end justify-end">
        {child}
      </div>
    </div>
  </div>
)

export const createPagerKey = (
  keys = []
) => {
  const array = Array.from(
    keys || []
  ).map((e) =>
    _.isString(e)
      ? e
      : JSON.stringify(e)
  )
  return ({
    sortBy,
    keyword,
    filterBy,
  }) =>
    [
      sortBy,
      keyword,
      ...array,
      JSON.stringify(filterBy),
    ].join('/')
}

export const handleFilter =
  ({
    searchParams,
    onFilter = Null,
    filter_actions = {},
  }) =>
  (params = {}) => {
    let search_params =
      new URLSearchParams(searchParams)

    Object.keys(params).forEach(
      (name) => {
        const param_value = params[name]
        const filter_name = _.get(
          filter_actions,
          name
        )
        if (
          param_value &&
          filter_name
        ) {
          search_params.set(
            filter_name,
            param_value
          )
        } else {
          search_params.delete(
            filter_name
          )
        }
      }
    )

    onFilter({
      search:
        '?' + search_params.toString(),
    })
  }

const ListFilter = ({
  filter,
  sortBy,
  params,
  keyword,
  sortHeader = null,
  onSearch = Null,
  onSortChange = Null,
  onFilterChange = Null,
  isToggleDefault = false,
  Wrapper = renderOwnChild,
  renderSearchBar = renderSelf,
  SortByWidget = EmptySortBy,
  renderSortBy = renderDefaultSort,
  AdvancedFilter = EmptyAdvancedFilter,
  AdvancedToggle = DefaultAdvancedToggle,
  renderFilter = (
    child,
    filter_widget
  ) => child,
  renderHeader = ([
    searchBar,
    filterBar,
  ]) => (
    <React.Fragment>
      {filterBar}
      {searchBar}
    </React.Fragment>
  ),
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle(
    isToggleDefault
  )

  useEffect(() => {
    if (
      _.some(
        Object.values(filter),
        (value) => !!value
      )
    ) {
      toggle(true)
    }
  }, [filter])

  const filter_widget = (
    <AdvancedFilter
      value={filter}
      onChange={onFilterChange}
      {...(params || {})}
    />
  )

  const search_bar = renderSearchBar(
    <Input.Search
      defaultValue={keyword}
      onSearch={(value) =>
        onSearch(value)
      }
      allowClear={true}
      className="input-search"
      placeholder={t('search')}
    />
  )

  const filter_bar = renderFilter(
    <div className="border-b border-color-50 pb-2">
      <div className="py-1">
        <ExpandablePanel
          onClick={toggle}
          Header={AdvancedToggle}
          expanded={isToggle}
          icons={[
            IoChevronDown,
            IoChevronUp,
          ]}
          title="advanced filter">
          {filter_widget}
        </ExpandablePanel>
      </div>
    </div>,
    filter_widget
  )

  return (
    <Wrapper>
      <div className="flex flex-col space-y-2">
        {renderHeader([
          search_bar,
          filter_bar,
        ])}
      </div>
      {renderSortBy(
        <SortByWidget
          value={sortBy || undefined}
          onSelect={onSortChange}
        />,
        { title: sortHeader }
      )}
    </Wrapper>
  )
}

const usePaginationHelper = ({
  pagerInfo = {},
  sortHeader,
  SortByWidget,
  AdvancedFilter,
  AdvancedToggle,
  filter_params = {},
  query_params = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
  renderList,
  renderFilter,
  renderSortBy,
  renderHeader,
  compact = false,
  renderSearchBar,
  dependencies = [],
  withKey = renderSelf,
  onInit = (events) => {},
  withValues = renderSelf,
  isToggleDefault = false,
  handleFilter: handleCustomFilter,
}) => {
  const history = useHistory()

  const location = useLocation()

  const [sortBy, setSortBy] = useState()

  const [filterBy, setFilterBy] =
    useState({})

  const {
    sortBy: sort_query,
    keyword: keyword_query,
  } = query_params || {}

  const keyword = useSearchParam(
    keyword_query
  )

  const handleSearch = useCallback(
    (keyword) => {
      let search_params =
        new URLSearchParams(
          location.search
        )
      if (keyword) {
        search_params.set(
          keyword_query,
          keyword
        )
      } else {
        search_params.delete(
          keyword_query
        )
      }
      history.push({
        search:
          '?' +
          search_params.toString(),
      })
    },
    [location.search]
  )

  useEffect(() => {
    onInit({
      onSortChange: setSortBy,
      onFilterChange: true
        ? setFilterBy
        : (values) => {
            setFilterBy(
              _.omitBy(
                values,
                _.isEmpty
              )
            )
          },
    })
  }, [location.search, location.state])

  const handleSort = useCallback(
    (value) => {
      let search_params =
        new URLSearchParams(
          location.search
        )
      if (value) {
        search_params.set(
          sort_query,
          value
        )
      } else {
        search_params.delete(sort_query)
      }
      history.push({
        search:
          '?' +
          search_params.toString(),
      })
    },
    [location.search]
  )

  const refreshKey = withKey({
    sortBy,
    keyword,
    filterBy,
  })

  const [widget_header, widget] =
    useMemo(() => {
      const header = compact ? null : (
        <ListFilter
          sortBy={sortBy}
          filter={filterBy}
          keyword={keyword}
          params={filter_params}
          onSearch={handleSearch}
          onSortChange={handleSort}
          renderHeader={renderHeader}
          onFilterChange={
            _.isFunction(
              handleCustomFilter
            )
              ? handleCustomFilter
              : handleFilter({
                  searchParams:
                    location.search,
                  filter_actions:
                    query_params,
                  onFilter: ({
                    search,
                  }) =>
                    history.push({
                      search,
                    }),
                })
          }
          sortHeader={sortHeader}
          SortByWidget={SortByWidget}
          renderFilter={renderFilter}
          renderSortBy={renderSortBy}
          AdvancedFilter={
            AdvancedFilter
          }
          AdvancedToggle={
            AdvancedToggle
          }
          renderSearchBar={
            renderSearchBar
          }
          isToggleDefault={
            isToggleDefault
          }
        />
      )
      return [
        header,
        <React.Fragment>
          {header}
          <Pure
            input={[
              sortBy,
              keyword,
              filterBy,
              renderList,
              refreshKey,
              dependencies,
              pagerInfo.renderItem,
            ]}>
            {_.isFunction(
              renderList
            ) ? (
              renderList({
                sortBy,
                keyword,
                filterBy,
              })
            ) : (
              <EntityList
                key={refreshKey}
                PaginationMeta={
                  EntityPaginationMeta
                }
                Header={({ meta }) =>
                  meta
                }
                values={withValues({
                  keyword,
                  sort_by: sortBy,
                  ...(filterBy || {}),
                })}
                {...(pagerInfo || {})}
              />
            )}
          </Pure>
        </React.Fragment>,
      ]
    }, [
      compact,
      keyword,
      sortBy,
      filterBy,
      refreshKey,
      renderList,
      renderHeader,
      pagerInfo.renderItem,
    ])

  return [
    widget,
    {
      sortBy,
      keyword,
      filterBy,
      setSortBy,
      setFilterBy,
      handleSearch,
      widget_header,
    },
  ]
}

export default usePaginationHelper
