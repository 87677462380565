import { staff_getRecruitmentStats_Api } from 'apis'
import { getId } from 'apis/model/base'
import Pure from 'components/Pure'
import useAsync from 'modules/asyncCache/useAsync'
import React, {
  useContext,
} from 'react'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import WorkspaceInsightsMetaSummary from '../Insights/WorkspaceInsightsMetaSummary'
import WorkspaceInsightsSummary, {
  InsightsSummaryTypes,
  PeriodFilterHeader,
} from '../Insights/WorkspaceInsightsSummary'
import WorkspaceContext, {
  WorkspaceFilterParams,
} from '../WorkspaceContext'

const WorkspaceHRStatistic = () => {
  const {
    organization,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  const period = getPeriod(
    WorkspaceFilterParams.OVERVIEW
  )

  const {
    response,
    isLoading = false,
    handleAsyncAction = Null,
  } = useAsync({
    apiInfo:
      staff_getRecruitmentStats_Api,
    query: bindQueryParam({
      organization_id: getId(
        organization
      ),
    }),
  })

  const { summary } =
    getResponseItem(response)

  return (
    <React.Fragment>
      <Pure
        input={[
          period,
          summary,
          isLoading,
          handleAsyncAction,
        ]}>
        <WorkspaceInsightsMetaSummary
          summary={summary}
          isLoading={isLoading}
          className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-8"
          Header={({ title }) => (
            <PeriodFilterHeader
              title={title}
              value={period}
              onSelect={(period) => {
                setPeriod(
                  WorkspaceFilterParams.OVERVIEW,
                  period
                )
                handleAsyncAction({
                  values: {
                    period,
                  },
                })
              }}
            />
          )}
          field_params={[
            {
              name: 'total_recruitment_event_count',
              label: 'total recruitment event',
            },
            {
              name: 'total_candidate_count',
              label: 'total staffs',
            },
            {
              name: 'total_staff_count',
              label: 'total user register',
            },
            {
              name: 'total_location_count',
              label: 'total location',
            },
          ].map(
            ({ name, ...rest }) => ({
              ...rest,
              name,
              extra_name: [
                name,
                'prev',
              ].join('_'),
            })
          )}
        />
      </Pure>
      <Pure>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-10">
          <WorkspaceInsightsSummary
            type={
              InsightsSummaryTypes.STAFF_LEFT_LIST
            }
          />
          <WorkspaceInsightsSummary
            type={
              InsightsSummaryTypes.STAFF_RIGHT_LIST
            }
          />
        </div>
      </Pure>
    </React.Fragment>
  )
}

export default WorkspaceHRStatistic
