import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import React, {
  useCallback,
} from 'react'
import {
  Null,
  renderSelf,
} from 'views/Shared'
import useImpactCategories from 'views/Wishare/hooks/useImpactCategories'
import renderImpCate from '../functions/renderImpCate'
import {Tooltip} from "antd";

const ImCatePicker = ({
  name,
  value,
  className,
  onChange = Null,
  Wrapper = 'div',
  readOnly = false,
  getValue = renderSelf,
}) => {
  const selectedItems = Array.from(
    value || []
  )

  const isSelected = useCallback(
    (item) =>
      selectedItems.includes(item),
    [selectedItems]
  )

  const setSelectedItems = (item) => {
    const value = getValue(item)
    const result =
      selectedItems.includes(value)
        ? _.pull(selectedItems, value)
        : [...selectedItems, value]
    onChange(createValue(name, result))
  }

  const categories =
    useImpactCategories(
      (item, index) => {
        const id = _.get(item, 'id')
        const selected = !!isSelected(id)
        return renderImpCate(
            selected,
            readOnly
                ? undefined
                : setSelectedItems
        )(item, index)
      }
    )

  if (_.isEmpty(categories)) {
    return (
      <Wrapper>
        <EmptyHolder />
      </Wrapper>
    )
  }

  return (
    <Wrapper
      className={classNames(
        'w-full h-full justify-center items-center',
        className
      )}>
      {categories}
    </Wrapper>
  )
}

export default ImCatePicker
