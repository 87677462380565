import { Button } from 'antd'
import {
  staff_getStaffById_Api,
  staff_getStaffs_Api,
  staff_manageStaffDelete_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoEyeOutline,
  IoGridOutline,
  IoMedalOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { StaffsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import MedalRewardList, {
  MedalRewardModalHeader,
} from '../Medal/MedalRewardList'
import WorkspaceContext from '../WorkspaceContext'
import { renderRosterContainer } from './renderRosterContainer'
import StaffActionModal, {
  StaffModalTypes,
  ViewStaffModal,
} from './StaffActionModal'
import StaffsAdvancedFilter from './StaffsAdvancedFilter'

export const RenderRosterActionWrapper =
  ({ action, children }) => {
    switch (action.type) {
      case StaffModalTypes.ASSIGN:
      case StaffModalTypes.EDIT:
      case StaffModalTypes.VIEW:
      case StaffModalTypes.MEDAL_AWARDS:
        return (
          <Async
            {...{
              apiInfo:
                staff_getStaffById_Api,
              query: bindQueryParam({
                id: action.value,
              }),
            }}>
            {({
              response,
              isLoading,
            }) =>
              _.isFunction(children)
                ? children({
                    isLoading,
                    initialValues:
                      getResponseItem(
                        response
                      ),
                  })
                : children
            }
          </Async>
        )
      case StaffModalTypes.CREATE:
      default:
        return children({
          isLoading: false,
        })
    }
  }

const WorkspaceHRStaffRosters = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    active: 'active',
    employee_type: 'employee-type',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const active = useSearchParam(
    filter_actions.active
  )

  const employee_type = useSearchParam(
    filter_actions.employee_type
  )

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const createContextOptions = (
    staff
  ) => [
    {
      key: StaffModalTypes.VIEW,
      label: 'view detail',
      icon: (
        <IoEyeOutline
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: StaffModalTypes.EDIT,
      label: 'edit',
      icon: (
        <IoCreateOutline
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: StaffModalTypes.ASSIGN,
      label: 'staff roster',
      icon: (
        <IoGridOutline
          size={16}
          className="text-primary"
        />
      ),
    },
    {
      key: StaffModalTypes.MEDAL_AWARDS,
      label: 'medals',
      icon: (
        <IoMedalOutline
          size={16}
          className="text-primary"
        />
      ),
    },
    {
      key: StaffModalTypes.DELETE,
      label: 'delete',
      icon: (
        <IoTrashOutline
          size={16}
          className="text-red-500"
        />
      ),
    },
  ]

  const onCancel = () =>
    setAction({ type: undefined })

  const {
    handleAsyncAction: deleteStaff,
  } = useAsyncAction({
    apiInfo:
      staff_manageStaffDelete_Api,
    onError: notifyOnError(t),
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
  })

  const onMenuSelect = (key, staff) => {
    const staff_id = getId(staff)
    switch (key) {
      case StaffModalTypes.EDIT:
      case StaffModalTypes.VIEW:
      case StaffModalTypes.ASSIGN:
      case StaffModalTypes.MEDAL_AWARDS:
        setAction({
          type: key,
          value: staff_id,
        })
        break
      case StaffModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteStaff(
                {},
                bindQueryParam({
                  id: staff_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const renderContextMenu = useCallback(
    (staff) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onMenuSelect(key, staff)
          }
          items={createContextOptions(
            staff
          )}
        />
      </div>
    ),
    []
  )

  const renderModal = useCallback(
    (modal) => {
      if (
        action.type ===
        StaffModalTypes.VIEW
      ) {
        return (
          <RenderRosterActionWrapper
            action={action}>
            {({
              isLoading,
              initialValues:
                selectedStaff,
            }) => {
              return (
                <FullSideModalLayout
                  right={true}
                  onCancel={onCancel}
                  title={t(
                    'view detail'
                  )}>
                  {!!isLoading ? (
                    <LoadingPage />
                  ) : (
                    <ViewStaffModal
                      initialValues={
                        selectedStaff
                      }
                      owner_id={
                        owner_id
                      }
                      owner_type={
                        owner_type
                      }
                    />
                  )}
                </FullSideModalLayout>
              )
            }}
          </RenderRosterActionWrapper>
        )
      } else if (
        action.type ===
        StaffModalTypes.MEDAL_AWARDS
      ) {
        return (
          <RenderRosterActionWrapper
            action={action}>
            {({
              isLoading,
              initialValues:
                selectedStaff,
            }) => {
              return (
                <FullSideModalLayout
                  right={true}
                  onCancel={onCancel}
                  title={t(
                    'medal rewards'
                  )}>
                  {!!isLoading ? (
                    <LoadingPage />
                  ) : (
                    <MedalRewardList
                      receiver={
                        selectedStaff
                      }
                      owner_id={
                        owner_id
                      }
                      owner_type={
                        owner_type
                      }
                      Header={
                        MedalRewardModalHeader
                      }
                    />
                  )}
                </FullSideModalLayout>
              )
            }}
          </RenderRosterActionWrapper>
        )
      } else {
        return modal
      }
    },
    [action]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          active,
          employee_type,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo: staff_getStaffs_Api,
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        renderItem:
          renderRosterContainer(
            renderContextMenu,
            {
              setAction,
              owner_id,
              owner_type,
            }
          ),
        RenderEntity: React.Fragment,
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
      ],
      sortHeader: t('staff list'),
      SortByWidget: StaffsSortBy,
      AdvancedFilter:
        StaffsAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="staffs list"
        extra={
          <Button
            type="primary"
            className="no-border rounded-lg no-shadow no-text-shadow"
            onClick={() => {
              setAction({
                type: StaffModalTypes.CREATE,
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
      {action.type &&
        renderModal(
          <NavigationContext.Consumer>
            {({ history }) => {
              const refresh = (
                state
              ) => {
                onCancel()
                history.push({
                  ...location,
                  state: state || {
                    refreshToken:
                      Date.now(),
                  },
                })
              }
              return (
                <RenderRosterActionWrapper
                  action={action}>
                  {({
                    isLoading,
                    initialValues,
                  }) => (
                    <StaffActionModal
                      type={action.type}
                      onCancel={
                        onCancel
                      }
                      params={{
                        owner_id,
                        owner_type,
                        staff_id:
                          action.value,
                      }}
                      onUpdated={() =>
                        onCancel()
                      }
                      onCreated={() =>
                        refresh(null)
                      }
                      onAssigned={() =>
                        refresh({
                          expanded_staff_id:
                            action.value,
                        })
                      }
                      isLoading={
                        isLoading
                      }
                      initialValues={
                        initialValues
                      }
                    />
                  )}
                </RenderRosterActionWrapper>
              )
            }}
          </NavigationContext.Consumer>
        )}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(WorkspaceHRStaffRosters)
