import { API_ROOT_URL } from 'envs/_current/config'
import { timeSheetSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const time_sheet_getTimeSheets_Api =
  {
    name: 'getTimeSheets',
    backend: `Route::get('time-sheets/{member_id}/gets', 'TimesheetController@getTimeSheets');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:member_id/gets',
    method: 'GET',
    schema: timeSheetSchema,
    transform: createListTransform(
      timeSheetSchema
    ),
  }

export const time_sheet_addTimeSheets_Api =
  {
    name: 'addTimeSheets',
    backend: `Route::post('time-sheets/{member_id}/add', 'TimesheetController@add');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:member_id/add',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_editTimeSheets_Api =
  {
    name: 'editTimeSheets',
    backend: `  Route::post('time-sheets/{member_id}/{id}/edit', 'TimesheetController@update');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:member_id/:id/edit',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_deleteTimeSheets_Api =
  {
    name: 'deleteTimeSheets',
    backend: `Route::post('time-sheets/{member_id}/{id}/delete', 'TimesheetController@delete');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:member_id/:id/delete',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_staffRoster_addTimesheet_Api =
  {
    backend: `Route::post('time-sheets/staff-rosters/{staff_roster_id}/add', 'TimesheetController@add');`,
    root: API_ROOT_URL,
    path: '/time-sheets/staff-rosters/:staff_roster_id/add',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_staff_editTimesheet_Api =
  {
    backend: `Route::post('time-sheets/{id}/edit', 'TimesheetController@update');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:id/edit',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_staff_deleteTimesheet_Api =
  {
    backend: `Route::post('time-sheets/{id}/delete', 'TimesheetController@delete');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:id/delete',
    method: 'POST',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }

export const time_sheet_staff_getTimeSheets_Api =
  {
    backend: `Route::get('time-sheets/staff/{staff_id}/gets', 'TimesheetController@getTimeSheets');`,
    root: API_ROOT_URL,
    path: '/time-sheets/staff/:staff_id/gets',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: timeSheetSchema,
    transform: createListTransform(
      timeSheetSchema
    ),
  }


export const time_sheet_staffRoster_getTimeSheets_Api =
  {
      backend: `Route::get('time-sheets/staff-rosters/{staff_roster_id}/gets', 'TimesheetController@getTimeSheetsByStaffRoster');`,
      root: API_ROOT_URL,
      path: '/time-sheets/staff-rosters/:staff_roster_id/gets',
      method: 'GET',
      paramsFields: [
          'keyword',
          'sort_by',
      ],
      schema: timeSheetSchema,
      transform: createListTransform(
        timeSheetSchema
      ),
  }

export const time_sheet_staff_getById_Api =
  {
    backend: `Route::get('time-sheets/{id}/get', 'TimesheetController@getById');`,
    root: API_ROOT_URL,
    path: '/time-sheets/:id/get',
    method: 'GET',
    schema: timeSheetSchema,
    transform: createTransform(
      timeSheetSchema,
      'data'
    ),
  }
