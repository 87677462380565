import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {
  useContext,
} from 'react'
import WorkspaceContext from '../WorkspaceContext'
import WorkspaceSettingContent from './WorkspaceSettingContent'

const WorkspaceSetting = () => {
  const { organization } = useContext(
    WorkspaceContext
  )

  if (_.isEmpty(organization)) {
    return null
  }

  return (
    <WorkspaceContext.Consumer>
      {({ organization }) => (
        <PermissionProvider
          permissions={{
            [permissionCodes.canEdit]:
              _.get(
                organization,
                'edit'
              ),
            ..._.get(
              organization,
              'available_actions',
              {}
            ),
          }}>
          <WorkspaceSettingContent />
        </PermissionProvider>
      )}
    </WorkspaceContext.Consumer>
  )
}

export default WorkspaceSetting
