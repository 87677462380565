import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import React from 'react'
import {
  IoCashOutline,
  IoDiamondOutline,
  IoGridOutline,
  IoHelpCircleOutline,
  IoSettingsOutline,
  IoStatsChartOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
} from 'recompose'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { CustomKeys } from 'views/Wishare/CustomKeys'
import { WishareEntities } from 'views/Wishare/enums'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import {
  CoHosts,
  CoOwners,
} from 'views/Wishare/factory/createHostSetting'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import RecruitmentContext from '../RecruitmentContext'
import RecruitmentSettingCategories from './RecruitmentSettingCategories'
import RecruitmentSettingImpCate from './RecruitmentSettingImpCate'
import RecruitmentSettingJobBenefits from './RecruitmentSettingJobBenefits'
import RecruitmentSettingJobDescription from './RecruitmentSettingJobDescription'
import RecruitmentSettingJobRequirements from './RecruitmentSettingJobRequirements'
import RecruitmentSettingJobResponsibilities from './RecruitmentSettingJobResponsibilities'
import RecruitmentSettingJobSalary from './RecruitmentSettingJobSalary'
import RecruitmentSettingOverview from './RecruitmentSettingOverview'
import RecruitmentSettingOwner from './RecruitmentSettingOwner'
import RecruitmentSettingQuestions from './RecruitmentSettingQuestions'
import RecruitmentSettingTarget from './RecruitmentSettingTarget'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline />,
  },
  {
    key: 'owner',
    label: 'owner',
    icon: <IoDiamondOutline />,
  },
  {
    key: 'job',
    label: 'job',
    icon: <IoCashOutline />,
    children: [
      {
        label: 'job description',
        key: 'job-description',
      },
      {
        label: 'job requirements',
        key: 'job-requirements',
      },
      {
        label: 'salary',
        key: 'salary',
      },
      {
        label: 'benefits',
        key: 'benefits',
      },
      {
        label: 'responsibilities',
        key: 'responsibilities',
      },
    ],
  },
  {
    key: 'category',
    label: 'categories',
    icon: <IoGridOutline />,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
      {
        label: 'impact categories',
        key: 'impact-categories',
      },
    ],
  },
  {
    key: 'target',
    label: 'target',
    icon: <IoStatsChartOutline />,
  },
  // {
  //   key: 'hosts',
  //   label: 'hosts',
  //   icon: <IoPersonOutline />,
  //   children: [
  //     {
  //       label:
  //         CustomKeys.co_hosts.label,
  //       key: CustomKeys.co_hosts.name,
  //     }
  //   ],
  // },
  {
    key: 'question',
    label: 'information collecting',
    icon: <IoHelpCircleOutline />,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline />,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component:
      RecruitmentSettingOverview,
  },
  {
    path: 'job-description',
    label: 'job description',
    component:
      RecruitmentSettingJobDescription,
  },
  {
    path: 'job-requirements',
    label: 'job requirements',
    component:
      RecruitmentSettingJobRequirements,
  },
  {
    path: 'salary',
    label: 'salary',
    component:
      RecruitmentSettingJobSalary,
  },
  {
    path: 'benefits',
    label: 'benefits',
    component:
      RecruitmentSettingJobBenefits,
  },
  {
    path: 'responsibilities',
    label: 'responsibilities',
    component:
      RecruitmentSettingJobResponsibilities,
  },
  {
    path: 'categories',
    label: 'categories',
    component:
      RecruitmentSettingCategories,
  },
  {
    path: 'impact-categories',
    label: 'impact categories',
    component:
      RecruitmentSettingImpCate,
  },
  {
    path: 'owner',
    label: 'owner',
    component: RecruitmentSettingOwner,
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: () => (
      <RecruitmentContext.Consumer>
        {({ recruitment }) => (
          <CoHosts item={recruitment} />
        )}
      </RecruitmentContext.Consumer>
    ),
  },
  {
    path: 'host-request',
    label: 'host request',
    component: () => (
      <RecruitmentContext.Consumer>
        {({ recruitment }) => (
          <CoOwners
            item={recruitment}
          />
        )}
      </RecruitmentContext.Consumer>
    ),
  },
  {
    path: 'target',
    label: 'target',
    component: RecruitmentSettingTarget,
  },
  {
    path: 'question',
    label: 'information collecting',
    component:
      RecruitmentSettingQuestions,
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <RecruitmentContext.Consumer>
        {({ recruitment }) =>
          createDeleteEntity(
            recruitment
          )
        }
      </RecruitmentContext.Consumer>
    ),
  },
].map(
  ({
    path,
    component: Component,
    ...rest
  }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const recruitmentSettings = {
  actions: [
    // {
    //   key: ContextActionTypes.CREATE_APPLICANT,
    //   label: 'create applicant',
    //   icon: <StarOutlined />,
    // },
    // {
    //   key: ContextActionTypes.APPLICANT_MANAGEMENT,
    //   label: 'applicant management',
    //   icon: <StarOutlined />,
    // },
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline />,
    },
  ],
  menuItems,
  routeEntities,
}

const RecruitmentSettings = ({
  id,
  ...props
}) =>
  createSettingModule(
    [WishareEntities.RECRUITMENT],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(
    RecruitmentContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({ match }) => ({
    id: _.get(match, 'params.id'),
  }))
)(RecruitmentSettings)
