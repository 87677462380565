import _ from "lodash";
import moment from "moment";

export const DonationStatusTypes =
  Object.freeze({
    PENDING: {id: 'PENDING', value: -1, label: 'pending'},
    ACTIVE: {id: 'ACTIVE', value: 1, label: 'active'},
    CLOSED: {id: 'CLOSED', value: 9, label: 'closed'},
  })

export function getDonationEventStatus(donationEvent) {
  if (!donationEvent) return null;

  const _status = Number(_.get(donationEvent, 'status', 1));

  if (moment(_.get(donationEvent, 'time_close')) < moment()) return DonationStatusTypes.CLOSED.value;

  return _status;
}