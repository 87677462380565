import _ from 'lodash'
import {useMemo} from 'react'

export default function Pure({
                               input = [],
  children = null
}) {
  const result = useMemo(
    () => {
      if (_.isFunction(children)) {
        return children(input)
      }
      return children
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    input
  )
  return result
}
