import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { recruitmentApi } from 'views/Wishare/apis'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import RecruitmentEventItem from 'views/Wishare/Recruitment/components/RecruitmentEventItem'
import RecruitmentEntity from 'views/Wishare/Recruitment/RecruitmentEntity'
import ActivityContext from '../ActivityContext'

const ActivityRecruitmentList = ({
  Wrapper = 'div',
}) => {
  const { activity: item } = useContext(
    ActivityContext
  )

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const apiInfo =
    recruitmentApi.recruitment_events_getItemsByOwner_gets_api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':type', type),
      ].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        RenderEntity={RecruitmentEntity}
        RenderItem={
          RecruitmentEventItem
        }
      />
    </Wrapper>
  )
}

export default ActivityRecruitmentList
