import {
  BookOutlined,
  CameraOutlined,
  EyeOutlined,
  FullscreenOutlined,
} from '@ant-design/icons'
import PushpinOutlined from '@ant-design/icons/PushpinOutlined'
import {
  Button,
  Input,
  Spin,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import {
  bookmarks_mark_Api,
  bookmarks_unMark_Api,
} from 'apis'
import {
  baseItemSchema,
  createBaseItemSchema,
} from 'apis/schema'
import { createTransform } from 'apis/utils/createTransform'
import Count from 'components/Feed/Count'
import { Share } from 'components/Feed/Share'
import { Vote } from 'components/Feed/Vote'
import ApiInfoForm from 'components/form/ApiInfoForm'
import { LoginContext } from 'components/LoginContext'
import { SelectEntityItem } from 'components/SelectEntityItem'
import Toggle from 'components/Toggle'
import UseState from 'components/UseState'
import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import Async from 'modules/asyncCache/components/Async'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import useCheckPermissions from 'modules/permissions/useCheckPermissions'
import React, {
  useContext,
} from 'react'
import { useToggle } from 'react-use'
import { ChooseShortcutChartListModal } from 'views/Category/ChooseShortcutChartList'
import { useRequiredLogin } from 'views/Server/useRequiredLogin'
import ChartContext from '../ChartContext'

export const PinToCategory = () => {
  return (
    <Toggle>
      {(isToggle, toggle) => {
        return (
          <>
            <Count
              onClick={toggle}
              component={
                PushpinOutlined
              }
            />
            {isToggle && (
              <ChooseShortcutChartListModal
                onSuccess={toggle}
                onCancel={toggle}
              />
            )}
          </>
        )
      }}
    </Toggle>
  )
}
const BookmarkAction = ({ item }) => {
  const t = useTranslate()
  const [istoggle, toggle] = useToggle()
  const [data, dispatch] =
    useDispatchAsyncActionWithNotify()
  const handleRequiredLogin =
    useRequiredLogin()
  const login = useContext(LoginContext)
  const type = item._type
  const id = item.idname
  return (
    <>
      {!item.bookmark_status ? (
        <Count
          title="bookmark"
          onClick={() => toggle()}
          component={BookOutlined}
        />
      ) : (
        <Count
          title="unbookmark"
          active
          onClick={() => {
            handleRequiredLogin(() =>
              dispatch(
                createAsyncAction({
                  apiInfo:
                    bookmarks_unMark_Api,
                  query: {
                    ':container':
                      'user',
                    ':container_id':
                      _.get(
                        login,
                        'username'
                      ),
                    ':type': type,
                    ':id': id,
                  },
                  // onSuccess: () =>
                  //   setHide(true)
                })
              )
            )
          }}
          className="color-primary"
          component={BookOutlined}
        />
      )}
      {istoggle && (
        <LoginContext.Consumer>
          {(login) => (
            <Modal
              destroyOnClose
              title={t('favorites')}
              footer={null}
              visible={true}
              onOk={null}
              onCancel={() => toggle()}>
              <div className="col-span-12 md:col-span-3  space-y-3 ">
                <Async
                  apiInfo={{
                    root: API_ROOT_URL,
                    path: '/folders/folder/gets',
                    method: 'GET',
                    transform:
                      createTransform(
                        createBaseItemSchema(
                          {
                            idAttribute:
                              () =>
                                'bookmarkByUser',
                          }
                        ),
                        'data'
                      ),
                  }}>
                  {({ isLoading }) => {
                    return (
                      <div
                        className={
                          'space-y-3  h-1/2 '
                        }>
                        <div className="flex items-center pt-3 flex-wrap">
                          <div className="uppercase  text-sm font-semibold text-color-300 flex-1">
                            {t(
                              'folders'
                            )}
                          </div>

                          <UseState
                            initialValue={
                              0
                            }>
                            {([
                              count,
                              setCount,
                            ]) => {
                              const isToggle =
                                count %
                                2
                              const toggle =
                                () =>
                                  setCount(
                                    count +
                                      1
                                  )
                              return (
                                <>
                                  {!isToggle && (
                                    <Spin
                                      spinning={
                                        isLoading
                                      }>
                                      {' '}
                                      <Count
                                        onClick={
                                          toggle
                                        }>
                                        <span className="text-xs  uppercase text-right">
                                          +{' '}
                                          {t(
                                            'new folder'
                                          )}
                                        </span>
                                      </Count>
                                    </Spin>
                                  )}
                                  {!!isToggle && (
                                    <ApiInfoForm
                                      onSuccess={() => {
                                        toggle()
                                      }}
                                      apiInfo={{
                                        root: API_ROOT_URL,
                                        path: '/folders/folder/add',
                                        method:
                                          'POST',
                                      }}
                                      initialValues={{
                                        title:
                                          '',
                                      }}>
                                      {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                      }) => {
                                        return (
                                          <div className="w-full flex flex-wrap justify-end space-y-2 space-x-2">
                                            <Input
                                              name="title"
                                              defaultValue={
                                                values.title
                                              }
                                              className="block w-full animated zoomIn faster"
                                              autoFocus
                                              onChange={
                                                handleChange
                                              }
                                              onBlur={(
                                                e
                                              ) => {
                                                if (
                                                  !e
                                                    .target
                                                    .value
                                                    .length
                                                ) {
                                                  toggle()
                                                }
                                              }}></Input>
                                            <Button
                                              onClick={
                                                toggle
                                              }>
                                              {t(
                                                'cancel'
                                              )}
                                            </Button>
                                            <Button
                                              loading={
                                                isSubmitting
                                              }
                                              onClick={() =>
                                                handleSubmit()
                                              }
                                              type="primary">
                                              {t(
                                                'create'
                                              )}
                                            </Button>
                                          </div>
                                        )
                                      }}
                                    </ApiInfoForm>
                                  )}
                                </>
                              )
                            }}
                          </UseState>
                        </div>
                        <nav className="w-full py-2 rounded-lg background-100  flex-1  font-semibold">
                          <div
                            onClick={() => {
                              dispatch(
                                createAsyncAction(
                                  {
                                    apiInfo:
                                      bookmarks_mark_Api,
                                    query:
                                      {
                                        ':container':
                                          'user',
                                        ':container_id':
                                          _.get(
                                            login,
                                            'username'
                                          ),
                                        ':type':
                                          type,
                                        ':id':
                                          id,
                                      },
                                  }
                                )
                              )
                              toggle()
                            }}
                            className="p-3 flex-shrink-0 cursor-pointer hover:background-200">
                            {t(
                              'no categorized'
                            )}
                          </div>
                          <SelectEntityItem
                            item={
                              'bookmarkByUser'
                            }
                            schema={
                              baseItemSchema
                            }>
                            {(
                              bookmarkByUser
                            ) => {
                              return (
                                bookmarkByUser &&
                                bookmarkByUser.data &&
                                bookmarkByUser.data.map(
                                  (
                                    item,
                                    i
                                  ) => (
                                    <div
                                      key={
                                        item.id
                                      }
                                      onClick={() => {
                                        dispatch(
                                          createAsyncAction(
                                            {
                                              apiInfo:
                                                bookmarks_mark_Api,
                                              query:
                                                {
                                                  ':container':
                                                    'folder',
                                                  ':container_id':
                                                    item.id,
                                                  ':type':
                                                    type,
                                                  ':id':
                                                    id,
                                                },
                                            }
                                          )
                                        )
                                        toggle()
                                      }}
                                      style={{
                                        minWidth: 100,
                                      }}
                                      className="p-3 rounded-none  cursor-pointer hover:background-200 border-t border-gray-300 ">
                                      {
                                        item.title
                                      }
                                    </div>
                                  )
                                )
                              )
                            }}
                          </SelectEntityItem>
                        </nav>
                      </div>
                    )
                  }}
                </Async>
              </div>
            </Modal>
          )}
        </LoginContext.Consumer>
      )}
    </>
  )
}
export const ChartActions = ({
  zoomEnabled = true,
}) => {
  const {
    actions,
    shareUrl,
    handleClick,
    item,
  } = useContext(ChartContext)
  const [canCapture] =
    useCheckPermissions(['capture'])
  const history = useHistory()
  return (
    <div className="flex items-center py-3 px-3">
      {actions.vote && (
        <Vote
          id={item.id}
          context={ChartContext}
        />
      )}
      {actions.view &&
        _.has(item, 'total_views') &&
        _.get(item, 'total_views', 0) >
          0 && (
          <Count
            defaultNumber={_.get(
              item,
              'total_views'
            )}
            key="view"
            color="color-primary"
            component={EyeOutlined}
          />
        )}

      <div className="flex-1" />
      <BookmarkAction item={item} />
      {actions.share && (
        <Share
          url={shareUrl}
          id={item.id}
          context={ChartContext}
        />
      )}
      {canCapture && (
        <Count
          onClick={() =>
            handleClick('capture')
          }
          component={CameraOutlined}
        />
      )}
      <PinToCategory />
      {/* {zoomEnabled && (
        <Count
          onClick={() => {
            history.push(
              '/chart/' +
                _.get(item, 'idname')
            )
          }}
          component={FullscreenOutlined}
        />
      )} */}
      <Count
        onClick={() =>
          handleClick('extend')
        }
        component={FullscreenOutlined}
      />
    </div>
  )
}
