export function getDonationDetail(donationItem, donation, donationEvent) {
  if (!donationItem) return {};
  if (!donationEvent) return {};

  const convertedCurrency = donationEvent.target_unit_name;
  // const isConvertedCurrency = (donationItem.item_name === donationItem.unit_name && donationItem.item_name === convertedCurrency);
  const isConvertedCurrency = (
    (donationItem.item_name === donationItem.unit_name && donationItem.item_name === convertedCurrency)
    || donationItem.item_name === 'blood'
  );
  const isCompleted = (donation.status === 1);
  const isConversionRequired = (donationEvent.conversion_required === 1);
  const isCurrencyItem = (donationItem.item_name === donationItem.unit_name);

  return {
    ...donationItem,
    convertedCurrency,
    isConvertedCurrency,
    isCompleted,
    isConversionRequired,
    isCurrencyItem
  }
}