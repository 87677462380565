import React from "react";

export const Handicap = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.724 511.724"
		>
			<path
				fill="#414b82"
				d="M356.303 437.359l-30.889-105.533H212.782c-16.291 0-29.496-13.206-29.496-29.497s13.206-29.496 29.496-29.496h134.733a29.497 29.497 0 0128.309 21.211l37.097 126.744c4.576 15.634-4.388 32.018-20.023 36.594-15.583 4.561-32.003-4.336-36.595-20.023z"
			></path>
			<path
				fill="#ffb69e"
				d="M285.459 229.814l-28.509-75.25c-4.81-12.695 1.582-26.885 14.278-31.694 12.697-4.811 26.885 1.583 31.694 14.278l20.488 54.077 62.018-17.84c13.048-3.751 26.665 3.781 30.418 16.827 3.753 13.047-3.781 26.665-16.827 30.418l-83.778 24.099c-12.245 3.522-25.231-2.9-29.782-14.915z"
			></path>
			<path
				fill="#46738c"
				d="M422.59 485.232H290.65c-8.675 0-15.707-7.032-15.707-15.707s7.032-15.707 15.707-15.707h131.94c8.675 0 15.707 7.032 15.707 15.707s-7.032 15.707-15.707 15.707z"
			></path>
			<path
				fill="#64afff"
				d="M315.458 121.718l-49.304-18.011c-21.753-7.947-45.829 3.246-53.775 24.998L156.428 281.87c-4.89 13.385-1.447 30.646 14.453 36.202l27.477 9.985 106.541-55.224 35.559-97.34c7.946-21.753-3.247-45.829-25-53.775z"
			></path>
			<ellipse
				cx="334.531"
				cy="54.595"
				fill="#ffb69e"
				rx="54.593"
				ry="54.593"
				transform="rotate(-28.87 334.737 54.548)"
			></ellipse>
			<path
				fill="#3c5064"
				d="M316.394 359.471H159.695a15.707 15.707 0 01-14.908-10.76L74.23 136.075c-2.732-8.233 1.728-17.123 9.961-19.855 8.231-2.733 17.123 1.728 19.855 9.961l66.986 201.875h145.361c8.675 0 15.707 7.032 15.707 15.707.001 8.675-7.031 15.708-15.706 15.708z"
			></path>
			<path
				fill="#46738c"
				d="M135.077 177.093c-8.341 0-16.11-5.285-18.88-13.635L93.059 93.726c-3.46-10.429 2.188-21.689 12.617-25.149s21.689 2.188 25.149 12.618l23.138 69.732c3.46 10.429-2.188 21.689-12.617 25.149a19.897 19.897 0 01-6.269 1.017z"
			></path>
			<path
				fill="#46738c"
				d="M316.796 436.14l-75.437-43.553 75.437-43.554a9.425 9.425 0 00-9.424-16.324l-75.437 43.554v-87.108a9.424 9.424 0 00-18.848 0v87.108l-75.437-43.554c-4.508-2.603-10.272-1.058-12.874 3.45s-1.058 10.271 3.45 12.874l75.437 43.554-75.437 43.553a9.425 9.425 0 009.424 16.324l75.437-43.554v87.108c0 5.205 4.219 9.424 9.424 9.424s9.424-4.219 9.424-9.424V408.91l75.437 43.554a9.421 9.421 0 0012.874-3.45c2.602-4.508 1.058-10.272-3.45-12.874z"
			></path>
			<path
				fill="#87afb9"
				d="M222.511 365.701c-14.848 0-26.885 12.037-26.885 26.885s12.037 26.885 26.885 26.885 26.885-12.037 26.885-26.885-12.037-26.885-26.885-26.885z"
			></path>
			<path
				fill="#373c41"
				d="M222.511 511.724c-65.693 0-119.138-53.445-119.138-119.138s53.445-119.138 119.138-119.138 119.138 53.445 119.138 119.138-53.446 119.138-119.138 119.138zm0-206.862c-48.371 0-87.724 39.353-87.724 87.724s39.353 87.723 87.724 87.723 87.724-39.352 87.724-87.723-39.353-87.724-87.724-87.724z"
			></path>
			<path
				fill="#2b2f33"
				d="M222.511 304.879c48.362 0 87.707 39.346 87.707 87.707s-39.345 87.707-87.707 87.707-87.707-39.346-87.707-87.707 39.345-87.707 87.707-87.707m0-13.613c-55.958 0-101.32 45.362-101.32 101.32s45.363 101.32 101.32 101.32 101.32-45.362 101.32-101.32-45.363-101.32-101.32-101.32z"
			></path>
			<path
				fill="#ffb69e"
				d="M152.154 295.366c-13.155-3.351-21.102-16.733-17.751-29.888l24.388-95.728a24.581 24.581 0 0116.587-17.424l36.529-11.246 22.859 18.267-6.345 28.087-25.397 7.819-20.983 82.362c-3.344 13.132-16.71 21.108-29.887 17.751z"
			></path>
			<path
				fill="#4699f2"
				d="M303.428 138.623c-3.995-12.974-17.752-20.254-30.724-16.26l-60.795 18.717 16.514 46.353 58.746-18.086c12.974-3.994 20.253-17.75 16.259-30.724z"
			></path>
		</svg>
	);
}

export default Handicap;
