export function getDonorName(
  donationItem
) {
  if (!donationItem) return null

  return !!donationItem.anonymous
    ? 'anonymous'
    : !!donationItem.donor &&
      !!donationItem.donor.id
    ? donationItem.donor.name ||
      donationItem.donor.title
    : donationItem.donor_name ?? donationItem.alias_name
}
