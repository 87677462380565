import { getPercent } from 'helpers/getPercent'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { ProgressBar } from '../../Templates/ItemTemplate'

const _getAmount = (item) => {
  let field0 = 'total_donation_qtty'
  return Number(_.get(item, field0, 0))
}

const _getTotalValue = (arr) => {
  let total = 0
  Array.from(arr || []).map((e) => {
    total += _getValue(e)
  })
  return total
}

const _getValue = (item) => {
  let field0 = 'total_donation_value'
  return Number(_.get(item, field0, 0))
}

const _getTarget = (item) => {
  return item.target > 0
    ? Number(item.target)
    : null
}
const _getProgress = (item) => {
  if (!_getTarget(item)) return null

  let field0 = 'total_donation_qtty'

  const val1 = Number(
    _.get(item, field0, 0)
  )
  return (
    Math.round(
      getPercent(
        val1,
        _getTarget(item)
      ) * 100
    ) / 100
  )
}

export const StatisticBar = ({
  donationEvent,
  hiddenExpireDate = false,
}) => {
  const t = useTranslate()
  const totalValue = Number(
    _getTotalValue(
      _.get(
        donationEvent,
        'total_donation_items',
        []
      )
    )
  )
  const target = _.get(donationEvent, 'target')
  const no_limit_flag = _.get(donationEvent, 'no_limit_flag')
  const percent = ((totalValue / target) * 100 ).toFixed(2)


  const conversionRequired = _.get(
    donationEvent,
    'conversion_required',
    0
  )

  return (
    <div className="col-12 p-0 space-y-3">
      <div className="flex flex-col">
        <div className="flex flex-col space-y-1 border-b-2 border-primary">
          <div className="flex justify-between">
            <div className="font-bold uppercase text-primary">
              {t('total')}
            </div>
            {
              !!conversionRequired && (
                <div className="flex items-center font-bold space-x-2 text-primary">
                  {[
                    (Number(target) === 0 || Number(no_limit_flag) === 1) ? <span/>
                      : <span className="text-xs font-medium">
                        {`(${percent.toLocaleString()} %)`}
                      </span>,
                    <span>
                      {totalValue.toLocaleString()}
                    </span>,
                    <span>
                    {_.get(
                      donationEvent,
                      'target_unit_name'
                    )}
                  </span>,
                  ].map((e, i) => (
                    <React.Fragment
                      key={i}>
                      {e}
                    </React.Fragment>
                  ))}
                </div>
              )
            }
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-2xs italic text-color-400">
            {t('target')}
          </div>
          {
            (Number(target) === 0 || Number(no_limit_flag) === 1)
              ? (
                <span className="text-color-400 font-bold text-xs">{t('no limit')}</span>
              )
            : (
                <div className="text-color-400 font-bold text-xs">
                  {target.toLocaleString()}{' '}{_.get(donationEvent, 'target_unit_name')}
                </div>
              )
          }
        </div>
        {!!!hiddenExpireDate &&
          !!_.get(
            donationEvent,
            'time_close'
          ) && (
            <div className="text-xs text-color-400 space-x-1 italic">
              {[
                <span className="text-2xs text-muted">
                  {t('expired')}:
                </span>,
                <span className="text-dark">
                  {moment(
                    _.get(
                      donationEvent,
                      'time_close'
                    )
                  ).format(
                    'D MMMM, YYYY'
                  )}
                </span>,
              ].map((e, i) => (
                <React.Fragment key={i}>
                  {e}
                </React.Fragment>
              ))}
            </div>
          )}
      </div>

      {_.get(
        donationEvent,
        'total_donation_items',
        []
      ).map((e, i) => {
        return (
          <div key={i}>
            <div className="flex items-center">
              <div className="flex-1 text-sm font-bold uppercase text-color-000 space-x-1">
                {[
                  <span>
                    {Number(
                      _getAmount(e)
                    ).toLocaleString()}
                  </span>,
                  <span>
                    {t(e.unit_name)}
                  </span>,
                  e.item_name !==
                    e.unit_name && (
                    <span>
                      ({t(e.item_name)})
                    </span>
                  ),
                ].map((e, i) => (
                  <React.Fragment
                    key={i}>
                    {e}
                  </React.Fragment>
                ))}
              </div>
              <div className="flex items-center text-xs font-normal text-color-300 space-x-1">
                {[
                  !!Number(_getProgress(e))
                    ? (
                      <span className="uppercase">({Number(_getProgress(e)).toLocaleString()}%)</span>
                    )
                    : null,
                  !!conversionRequired &&
                  e.item_name !==
                    e.unit_name ? (
                    <span>{'~'}</span>
                  ) : null,
                  !!conversionRequired ? (
                    <span className="uppercase">
                      {numeral(
                        Number(
                          _getValue(e)
                        )
                      ).format('0.0a')}
                    </span>
                  ) : null,
                  !!conversionRequired ? (
                    <span>
                      {_.get(
                        donationEvent,
                        'target_unit_name'
                      )}
                    </span>
                  ) : null,
                ].map((e, i) => (
                  <React.Fragment
                    key={i}>
                    {e}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <ProgressBar
              eventItem={donationEvent}
              total={_.get(e, 'target')}
              current={_getAmount(e)}
              unit={_.get(
                e,
                'unit_name'
              )}
              strokeColor="var(--color-primary)"
              showLabel= {false}
            />
            <div className="flex flex-col leading-tight">
              {!!!_.get(
                e,
                'no_limit_flag'
              ) && (
                <div className="text-xs text-color-300 space-x-1 italic">
                  {[
                    <span>
                      {t('target')}:
                    </span>,
                    <span className="text-color-000 font-semibold">
                      {Number(
                        _getTarget(e)
                      ).toLocaleString()}
                    </span>,
                    <span className="text-color-000 font-semibold">
                      {t(e.unit_name)}
                    </span>,
                  ].map((e, i) => (
                    <React.Fragment
                      key={i}>
                      {e}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
