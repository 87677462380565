import React from 'react'
import CategoryNav from './CategoryNav'

const CategoryContent = ({
  Wrapper = 'div',
  ...props
}) => {
  return (
    <Wrapper className="space-y-3 min-h-screen h-full">
      <CategoryNav />
    </Wrapper>
  )
}

export default CategoryContent
