import {
  getId,
  getType,
} from 'apis/model/base'
import { FeedContent } from 'components/Feed/FeedCard'
import { GoogleAdsInFeedUnit } from 'components/GoogleAds/GoogleAds'
import LazyComponent from 'components/LazyComponent'
import Pure from 'components/Pure'
import _ from 'lodash'
import React from 'react'
import { FeedWidget } from 'views/Feed/components/FeedWidget'
import {
  FeedActionTypes,
  FeedContentTypes,
} from 'views/Feed/Feed'
import { FeedCard } from '../FeedCard'
import { renderHeadlineFeedItem } from './renderNewsItem'

const renderExcludedItem = (
  item,
  index
) => {
  switch (item.action) {
    case FeedActionTypes.ADS:
      return <GoogleAdsInFeedUnit />
    case FeedActionTypes.STICKY:
      return (
        <LazyComponent>
          <Pure>
            <FeedWidget item={item} />
          </Pure>
        </LazyComponent>
      )
    default:
      return null
  }
}

const renderCard = (
  item,
  customComponents = {}
) => (
  <FeedCard
    key={item.id}
    item={item.content}
    customComponents={customComponents}
  />
)

export const renderFeedItem = (
  item,
  index
) => {
  const type = getType(item)

  const feed =
    type === 'feed'
      ? item
      : renderFeedContainer(item)

  if (_.isEmpty(feed)) return null

  if (
    _.includes(
      Object.values(FeedActionTypes),
      feed.action
    )
  ) {
    return renderExcludedItem(
      feed,
      index
    )
  }

  return renderCard(feed, {
    Content: FeedContent,
  })
}

export const renderFeedByType =
  (type) => (item, index) => {
    if (!item) return null

    if (
      _.includes(
        Object.values(FeedActionTypes),
        item.action
      )
    ) {
      return renderExcludedItem(
        item,
        index
      )
    } else {
      switch (type) {
        case FeedContentTypes.ARTICLE:
          return renderCard(item, {
            Content: () => (
              <FeedContent />
            ),
          })
        case FeedContentTypes.POST:
        default:
          return renderFeedItem(
            item,
            index
          )
      }
    }
  }

export const renderFeedHeadlineByType =
  (type) => (item, index) => {
    if (!item) return null

    if (
      _.includes(
        Object.values(FeedActionTypes),
        item.action
      )
    ) {
      return renderExcludedItem(
        item,
        index
      )
    } else {
      let content =
        renderHeadlineFeedItem(item)
      switch (type) {
        case FeedContentTypes.POST:
        case FeedContentTypes.ARTICLE:
        default:
          break
      }

      return (
        <React.Fragment key={index}>
          {content}
        </React.Fragment>
      )
    }
  }

export const renderFeedContainer = (
  content
) => ({
  content,
  _type: 'feed',
  id: getId(content),
})
