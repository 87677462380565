import {Menu, Space} from 'antd'
import {getType} from 'apis/model/base'
import classNames from 'classnames'
import DrawMenu from 'components/drawer/DrawMenu'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo,} from 'react'
import {IoTrashOutline} from 'react-icons/io5'
import {Null, renderElse,} from 'views/Shared'
import CustomButton from 'views/Wishare/custom/CustomButton'
import {OverlayItemBanner} from 'views/Wishare/Templates/ItemTemplate'
import HostContext, {HostActionTypes,} from '../HostContext'
import HostContent from './HostContent'

const HostActionContainer = React.memo(
  ({params, children}) => {
    const {handleAction = Null} =
      useContext(HostContext)

    return _.isFunction(children)
      ? children((action) =>
        handleAction(action, params)
      )
      : children
  }
)

const PendingItem = ({
                       handleClick = Null,
                     }) => {
  const t = useTranslate()
  return (
    <Space direction="horizontal">
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleClick(
            HostActionTypes.APPROVE
          )
        }}
        className="GhostPrimary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('approve')}
        </span>
      </CustomButton>
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleClick(
            HostActionTypes.DECLINE
          )
        }}
        className="GhostSecondary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('reject')}
        </span>
      </CustomButton>
    </Space>
  )
}

const ApprovedItem = ({
                        canLeave,
                        handleClick = Null,
                      }) => {
  const t = useTranslate()

  const menu = useMemo(
    () =>
      [
        !!canLeave
          ? {
            key: HostActionTypes.LEAVE,
            label: t('leave'),
            icon: (
              <IoTrashOutline className="text-red-500"/>
            ),
          }
          : undefined,
      ].filter((value) => value),
    [t, canLeave]
  )

  return renderElse(
    _.isEmpty(menu),
    <div className="absolute top-0 right-0 px-3 py-2">
      <DrawMenu>
        {(__, toggle) => (
          <Menu
            items={menu}
            onClick={({key}) => {
              handleClick(key)
              toggle()
            }}
          />
        )}
      </DrawMenu>
    </div>
  )
}

const renderActions = (
  status,
  handleClick = Null,
  params
) => {
  const {canLeave} = params || {}

  let Component
  switch (Number(status)) {
    case 1:
      Component = ApprovedItem
      break
    case -1:
      Component = PendingItem
      break
    default:
      Component = Null
      break
  }
  return (
    <Component
      {...{canLeave, handleClick}}
    />
  )
}

const HostActionRow = ({
                         item,
                         params,
                         status,
                         subItem,
                         Actions,
                         canLeave = true,
                         showLabel = true,
                         ...rest
                       }) => {
  const actions = useMemo(() => {
    if (Actions) {
      return (
        <Actions
          {...{item, subItem}}
          {...rest}
        />
      )
    }
    return (
      <HostActionContainer
        {...{params}}>
        {(handleClick) =>
          renderActions(
            status,
            handleClick,
            {canLeave}
          )
        }
      </HostActionContainer>
    )
  }, [
    item,
    rest,
    params,
    status,
    Actions,
    subItem,
  ])

  const hostType = getType(item)

  if (_.isEmpty(item)) return null
  return (
    <div className="relative rounded-lg hover:shadow-out border border-color-50">
      {showLabel && (
        <React.Fragment>
          <OverlayItemBanner
            type={hostType}
            title={hostType}
          />
          <div className="h-7"/>
        </React.Fragment>
      )}
      <div className="absolute right-0 top-0 p-2">
        {actions}
      </div>
      <HostContent
        {...{
          item,
          status,
        }}
      />
    </div>
  )
}

export const HostRow = ({
    showInfo,
    showLabel = true,
    type = "host",
    item,
    style,
    className,
}) => {
  if (_.isEmpty(item)) {
    return null
  }
  const {host, owner} = item || {}
  const hostType = getType(host)
  const dataItem = type === "host" ? host : owner;
  return (
    <div
      style={style}
      className={classNames(
        'relative rounded-lg hover:shadow-items border border-color-50',
        className
      )}>
      {showLabel && (
        <React.Fragment>
          <OverlayItemBanner
            type={hostType}
            title={hostType}
          />
          <div className="h-7"/>
        </React.Fragment>
      )}
      <HostContent item={owner} showInfo={showInfo}/>
    </div>
  )
}

export default HostActionRow
