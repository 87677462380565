import {Avatar, List} from 'antd'
import {donation_events_getAvailableGivingEvents_api} from 'apis'
import {getId, getType,} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import {createValue} from 'components/form/utils'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useCallback, useContext, useEffect, useState,} from 'react'
import {Redirect} from 'react-router-dom'
import LoadingPage from 'views/LoadingPage'
import {paths} from 'views/MainPage/contains'
import {getResponseItems, Null,} from 'views/Shared'
import {wishareSettingModalRoutes} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import {useDebounceSearch} from 'views/Wishare/custom/DebounceSearch'
import {CustomKeys} from 'views/Wishare/CustomKeys'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import GivingEntity from 'views/Wishare/Giving/GivingEntity'
import useTranslate from "../../../../modules/local/useTranslate";
import {BiLinkAlt} from "react-icons/bi";

const ItemWrapper = ({
                       item,
                       children,
                       ...props
                     }) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderItem = (
  {actions, ...item},
  index
) => {
  const isEmpty =
    actions && _.isEmpty(item)

  const avatar =
    getAvatar(item) ||
    _.get(item, 'photo')

  return (
    <div key={index}>
      {isEmpty ? (
        <div className="rounded-lg hover:background-100">
          <EmptyHolder
            icon={() => (
              <div
                style={{
                  fontSize: '3rem',
                  lineHeight: '2rem',
                }}>
                <BiLinkAlt/>
              </div>
            )}
            title="click here for the link"
            subTitle="your campaign is currently not associated with any giving campaign, click here to connect"
          />
        </div>
      ) : (
        <div className="w-full px-3 py-2 rounded-lg shadow-xs hover:shadow-out background">
          <Translate>
            {(t) => (
                <div className="flex items-center gap-2">
                    <Avatar
                        src={avatar}
                        className="flex flex-center background-100">
                        <span className="font-medium text-color-200">
                          {_.first(getTitle(item))}
                        </span>
                    </Avatar>
                    <div className="flex flex-col flex-1">
                        <span className="text-color-000 font-semibold leading-tight">
                            {getTitle(item)}
                        </span>
                        <span className="text-xs text-secondary italic leading-tight">
                            {t(getType(item))}
                        </span>
                    </div>
                    {actions || [
                        <Translate>
                            {(t) => (
                                <span className="px-2 py-1 bg-primary text-xs text-white rounded-md leading-tight">
                                    {t('choose')}
                                </span>
                            )}
                        </Translate>
                    ]}
                </div>
            )}
          </Translate>
        </div>
      )}
    </div>
  )
}

const SearchGivingModalContent = ({
                                    Wrapper = 'div',
                                    ...props
                                  }) => {
  const {setCurrent} = useContext(
    NavigationContext
  )

  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const params = _.get(
    props,
    'location.state'
  )

  const [id] = [_.get(params, 'id')]

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath}/>
    )
  }

  const apiInfo =
    donation_events_getAvailableGivingEvents_api

  const onSelect = (value) => {
    const result = getId(value) || value
    setCurrent(result)
  }

  return (
    <Wrapper className="space-y-3 py-3 mt-8">
      <div className="text-center text-xl font-bold uppercase text-color-000">
        <Translate>
          {(t) =>
            t('edit campaign link')
          }
        </Translate>
      </div>
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParam({id})}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({response, isLoading}) => {
          if (Boolean(isLoading)) {
            return <LoadingPage/>
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          )
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={renderClicker(
                {
                  renderItem,
                  onClick: onSelect,
                  Wrapper: ItemWrapper,
                }
              )}
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

export const SearchGivingModal = (
  props
) => (
  <AntdConfigWrapper>
    <SearchGivingModalContent
      {...props}
    />
  </AntdConfigWrapper>
)

const DonationGivingPicker = ({
                                id,
                                type,
                                name,
                                item,
                                onChange = Null,
                              }) => {
  const {
    current,
    history,
    setCurrent = Null,
    handleGoBack = Null,
  } = useContext(NavigationContext)

  const [temp, setTemp] = useState()
  const t = useTranslate()

  useEffect(() => {
    if (current) {
      setTemp(current)
      handleGoBack()
      onChange(
        createValue(name, [current])
      )
      setCurrent(null)
    }
  }, [
    name,
    current,
    setTemp,
    onChange,
    setCurrent,
    handleGoBack,
  ])

  const edit = useCallback(() => {
    history.push(
      wishareSettingModalRoutes(
        CustomKeys.giving_picker.name,
        {
          id,
          type,
        }
      )
    )
  }, [id, type, history])

  return (
    <div
      className="cursor-pointer"
      onClick={edit}>
      <GivingEntity
        item={temp}
        defaultItem={item}>
        {(giving) =>
          renderItem(
            {
              actions: [
                <span className="px-2 py-1 border border-primary text-xs text-primary rounded-md leading-tight">
                    {t('edit')}
                </span>
              ],
              ...(giving || {}),
            },
            'current-giving'
          )
        }
      </GivingEntity>
    </div>
  )
}

export default DonationGivingPicker
