import { Avatar } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {
  IoPeople,
  IoPerson,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { renderElse } from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'

export const renderStaff =
  (params = {}) =>
  (item) => {
    if (!!_.get(item, 'deleted')) {
      return null
    }

    const { owner, employee_type } =
      item || {}

    const medal = _.get(
      item,
      'medal_awards',
      []
    )

    const account = renderElse(
      _.isEmpty(owner),
      <div className="flex items-center text-secondary gap-2">
        {' - '}
        <Link
          to={getLinkToDetail(owner)}
          className="flex items-center flex-1 max-lines-1 text-xs no-underline">
          {getType(owner) ===
          WishareEntities.USER ? (
            <IoPerson className="mr-1" />
          ) : (
            <IoPeople className="mr-1" />
          )}
          {getTitle(owner)}
        </Link>
      </div>
    )

    const { renderContextMenu } =
      params || {}
    return (
      <Translate key={getId(item)}>
        {(t) => (
          <div className="relative  w-full border border-color-50 rounded-lg background">
            <div className="flex items-center p-3 gap-3">
              <LayoutContext.Consumer>
                {({ isSm }) =>
                  !!!isSm && (
                    <Avatar
                      size={35}
                      src={_.get(
                        item,
                        'owner.avatar'
                      )}
                      className="flex flex-center background-200">
                      <span className="capitalize text-color-200">
                        {_.first(
                          _.get(
                            item,
                            'name'
                          )
                        )}
                      </span>
                    </Avatar>
                  )
                }
              </LayoutContext.Consumer>
              <div className="flex flex-col">
                <div className="font-bold leading-tight max-lines-1">
                  {getTitle(item)}
                </div>
                <span className="flex items-center gap-2 italic text-xs text-color-300">
                  <span className="whitespace-no-wrap">
                    {t(employee_type)}
                  </span>
                  {account}
                </span>
              </div>
            </div>
            {_.isFunction(
              renderContextMenu
            )
              ? renderContextMenu(item)
              : null}
            {medal.length > 0 && (
              <div
                className="absolute top-0 flex gap-1"
                style={{
                  padding: '0.3rem',
                  right: _.isFunction(
                    renderContextMenu
                  )
                    ? '2rem'
                    : 0,
                }}>
                {medal.map(
                  (e, index) => (
                    <Avatar
                      key={index}
                      size={20}
                      src={_.get(
                        e,
                        'medal.avatar'
                      )}
                    />
                  )
                )}
              </div>
            )}
          </div>
        )}
      </Translate>
    )
  }
