import { Button } from 'antd'
import { recruitment_events_deleteCandidate_api } from 'apis'
import { getId } from 'apis/model/base'
import { recruitmentEventSchema } from 'apis/schema'
import { candidateSchema } from 'apis/schema/candidateSchema'
import { LoginContext } from 'components/LoginContext'
import Pure from 'components/Pure'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  useEffectOnce,
  useSearchParam,
  useToggle,
} from 'react-use'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { RecruitmentFAB } from 'views/Wishare/factory/createFAB'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { WishareModal } from 'views/Wishare/ModalContext'
import { showConfirm } from '../../../../components/Feed/TimeLineFeed'
import ApplicationReviewForm from '../ActionForms/ApplicationReviewForm'
import RecruitmentApplyAdminForm from '../ActionForms/RecruitmentApplyAdminForm'
import RecruitmentApplyForm, {
  recruitmentApplyingParams,
} from '../ActionForms/RecruitmentApplyForm'
import RecruitmentContext from '../RecruitmentContext'

export const RecruitmentOptionTypes =
  Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
  })

const MyRecord = ({
  edit,
  record,
  event_id,
  applied = false,
  renderAppliedButton = Null,
  Wrapper = renderOwnChild,
  owner,
}) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()

  const candidate_id = getId(record)

  const candidate = useSelectEntities(
    candidate_id,
    candidateSchema,
    record
  )

  if (Boolean(!applied)) {
    return renderAppliedButton([
      candidate,
      {
        disabled: applied,
      },
    ])
  }

  return (
    <Wrapper>
      <div
        className="p-2 text-center text-green-600 font-bold border-2 border-green-600 rounded-lg cursor-pointer hover:bg-green-100"
        onClick={() => toggle(true)}>
        {t('applied')}
      </div>
      <WishareModal
        visible={isToggle}
        onCancel={() => toggle(false)}
        closable={false}
        footer={
          <React.Fragment>
            {Number(
              _.get(candidate, 'status')
            ) === -1 ? (
              <AsyncAction
                query={bindQueryParams([
                  {
                    id: event_id,
                  },
                  {
                    candidate_id,
                  },
                ])}
                onError={notifyOnError(
                  t
                )}
                apiInfo={
                  recruitment_events_deleteCandidate_api
                }>
                {({
                  handleAsyncAction,
                }) => (
                  <Button
                    className="no-shadow no-text-shadow rounded-lg no-border"
                    onClick={() => {
                      showConfirm({
                        title: t(
                          'are you sure that you want to cancel this'
                        ),
                        okText:
                          t('sure'),
                        cancelText:
                          t('no'),
                        okButtonProps: {
                          type: 'primary',
                          className:
                            'rounded-lg no-shadow no-text-shadow',
                        },
                        cancelButtonProps:
                          {
                            className:
                              'rounded-lg no-shadow no-text-shadow',
                          },
                        onOk: () => {
                          handleAsyncAction()
                        },
                      })
                    }}>
                    {t('cancel application')}
                  </Button>
                )}
              </AsyncAction>
            ) : null}

            <Button
              type={'primary'}
              onClick={() =>
                toggle(false)
              }
              className="no-shadow no-text-shadow rounded-lg no-border">
              {t('close')}
            </Button>
          </React.Fragment>
        }>
        <PermissionProvider
          permissions={{
            [permissionCodes.canEdit]:
              Boolean(edit),
          }}>
          <div className="text-center uppercase font-bold text-2xl md:text-3xl my-3">
            {t('application form')}
          </div>
          <ApplicationReviewForm
            item={record}
            owner={owner}
            profile={_.get(
              record,
              'profile'
            )}
          />
        </PermissionProvider>
      </WishareModal>
    </Wrapper>
  )
}

const RecruitmentRegister = () => {
  const t = useTranslate()

  const history = useHistory()

  const { location } = history

  const { recruitment: item } =
    useContext(RecruitmentContext)

  const recruitment = useSelectEntities(
    getId(item),
    recruitmentEventSchema,
    item
  )

  const { action } =
    recruitmentApplyingParams

  const currentAction = useSearchParam(
    action.name
  )

  const [
    currentState,
    setCurrentState,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const onApply = useCallback(() => {
    let search = new URLSearchParams(
      location.search
    )
    search.set(
      action.name,
      action.value
    )
    history.push({
      // ...location,
      search: [
        '?',
        search.toString(),
      ].join(''),
    })
    setCurrentState({
      type: RecruitmentOptionTypes.USER,
    })
  }, [
    action,
    location,
    history,
    setCurrentState,
  ])

  useEffectOnce(() => {
    if (
      _.isEqual(
        currentAction,
        action.value
      )
    ) {
      setCurrentState({
        type: RecruitmentOptionTypes.USER,
      })
    }
  })

  if (_.isEmpty(recruitment))
    return null

  const canEdit = _.get(
    recruitment,
    'edit'
  )

  const isAdminToggled = Boolean(
    canEdit &&
      currentState.type ===
        RecruitmentOptionTypes.ADMIN
  )

  const isUserToggled =
    currentState.type ===
    RecruitmentOptionTypes.USER

  const applied_status = Boolean(
    _.get(recruitment, 'applied_status')
  )

  return (
    <div className="flex flex-col space-y-3">
      <div className="text-color-300 font-bold text-xl uppercase mt-1">
        {applied_status
          ? t(
              'review the application form'
            )
          : t('register')}
      </div>
      <RecruitmentFAB>
        <div className="flex flex-col gap-2 background">
          <LoginContext.Consumer>
            {(login) => (
              <MyRecord
                key={applied_status}
                edit={!!login}
                record={_.get(
                  recruitment,
                  'my_application'
                )}
                event_id={getId(
                  recruitment
                )}
                owner={_.get(
                  recruitment,
                  'root_owner'
                )}
                applied={applied_status}
                renderAppliedButton={([
                  profile,
                  { disabled },
                ]) => (
                  <Button
                    size="large"
                    block={true}
                    type="primary"
                    disabled={disabled}
                    onClick={
                      disabled
                        ? undefined
                        : onApply
                    }
                    className="rounded-lg no-shadow no-text-shadow no-border bg-green-500 uppercase hover:bg-green-600">
                    {t('apply now')}
                  </Button>
                )}
              />
            )}
          </LoginContext.Consumer>
          {canEdit && (
            <Button
              size="large"
              block={true}
              type={"dashed"}
              onClick={() => {
                setCurrentState({
                  type: RecruitmentOptionTypes.ADMIN,
                })
              }}
              className="rounded-lg no-shadow no-text-shadow">
              {`${t('input data')} (${t(
                'for admin'
              )})`}
            </Button>
          )}
        </div>
      </RecruitmentFAB>

      {isUserToggled && (
        <Pure>
          <RecruitmentApplyForm
            onCancel={() => {
              history.push({
                pathname:
                  location.pathname,
                search: '',
              })
              setCurrentState({})
            }}
          />
        </Pure>
      )}
      {isAdminToggled && (
        <RecruitmentApplyAdminForm
          onCancel={() => {
            history.push({
              pathname:
                location.pathname,
              search: '',
            })
            setCurrentState({})
          }}
        />
      )}
    </div>
  )
}

export default RecruitmentRegister
