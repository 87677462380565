import {
  Button,
  notification,
} from 'antd'
import { getTokenKey } from 'helpers/localStorage'
import _ from 'lodash'
import SocketProvider from 'modules/socket/SocketProvider'
import React, {
  useEffect,
  useMemo,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useNetwork } from 'react-use'
import { updateApp } from 'redux/actions'
import { LoginContext } from './LoginContext'
import useTranslate from "../modules/local/useTranslate";

const selectLogin = (state) => {
  const id = _.get(
    state,
    'auth.user.username'
  )
  return _.get(
    state,
    'entities.users.' + id,
    _.get(state, 'auth.user')
  )
}

const NetworkTracking = () => {

  const dispatch = useDispatch()
  const netWorkState = useNetwork()
  useEffect(() => {
    let timeout
    if (
      !netWorkState.online &&
      !navigator.onLine
    ) {
      timeout = setTimeout(() => {
        notification.error({
          key: 'netWorkOffline',
          message: 'network',
          description: 'network offline',
          btn: (
            <Button
              type="primary"
              size="small"
              className="no-shadow no-text-shadow rounded-md font-medium text-sm"
              onClick={() =>
                window.location.reload()
              }>
              reconnect
            </Button>
          ),
        })
        dispatch(
          updateApp({
            online: netWorkState.online,
          })
        )
      }, 1000)
    } else {
      notification.close(
        'netWorkOffline'
      )
      dispatch(
        updateApp({
          online: netWorkState.online,
        })
      )
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [dispatch, netWorkState.online])
  return null
}
export const IsAppReady = ({
  children,
}) => {
  const login = useSelector(selectLogin)
  const auth = useSelector((state) => {
    return state.auth
  })
  const renderKey = useMemo(() => {
    const token = getTokenKey()
    const ready =
      !token || (auth && auth.success)
    return ready ? token : 'anonymous'
  }, [auth])
  return (
    <React.Fragment>
      <NetworkTracking />
      <LoginContext.Provider
        key={renderKey}
        value={login}>
        <SocketProvider>
          {children}
        </SocketProvider>
      </LoginContext.Provider>
    </React.Fragment>
  )
}
