import {Alert, Button, Spin} from 'antd'
import {billing_getStaticFeaturesOfClass_Api, billing_subscribeTrialClass_Api} from 'apis'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {links} from '../routes'
import {emptyObject} from "../../../helpers/emptyObjects";

function ChoosePlanTrial() {
  const { planId, amount } = useParams()
  const {
    response,
    isLoading
  } = useAsync({
    apiInfo: billing_getStaticFeaturesOfClass_Api
  })
  const plans = _.get(
    response,
    'data.data',
    []
  )
  const plan =
    plans.find(
      item => item.id == planId
    ) || emptyObject
  const history = useHistory()
  const [
    data,
    dispath
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () =>
      history.push(
        links.manageSubscriptions
      )
  })
  const t = useTranslate()
  return (
    <Spin spinning={isLoading}>
      <div
        style={{ fontWeight: 500 }}
        className="w-full text-color-000 verticalList__large max-w-md mx-auto ">
        <h2 className="text-4xl text-center block font-bold uppercase">
          {t('trial')}
        </h2>
        <div className="verticalList__small">
          <h2 className="text-2xl font-bold">
            {t(
              'get started with {name}',
              { name: t(plan.name) }
            )}
          </h2>
          <div>
            <Alert
              type="info"
              message={
                <div>
                  <div className="font-bold">
                    {t(
                      'start your no-cost {subscription} trial today',
                      {
                        subscription: t(
                          plan.name
                        )
                      }
                    )}
                  </div>
                  <div className="text-color-300">
                    {t(
                      "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends",
                      {
                        subscription: t(
                          plan.name
                        )
                      }
                    )}
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div>
          <div className="font-bold">
            {t('after your trial ends')}
          </div>
          <div className="text-color-300">
            {t(
              'add payment to keep using {subscription} or change back to free',
              {
                subscription: t(
                  plan.name
                )
              }
            )}
            .
          </div>
        </div>
        <div className="text-color-300 text-sm">
          {t(
            'By clicking Submit, you agree to the policy of Wishare {term1} and {term2}.',
            {
              term1: (
                <a
                  className="hover:underline color-primary"
                  href="#">
                  {t(
                    'terms of service'
                  )}
                </a>
              ),
              term2: (
                <a
                  className="hover:underline color-primary"
                  href="#">
                  {t('privacy policy')}
                </a>
              )
            }
          )}
        </div>
        <div className="flex justify-end">
          <Link to={links.changePlan}>
            <Button
              type="ghost"
              className="mr-3">
              {t('back')}
            </Button>
          </Link>
          <Button
            loading={data.isLoading}
            onClick={() => {
              dispath(
                createAsyncAction({
                  apiInfo: billing_subscribeTrialClass_Api,
                  query: {
                    ':code': planId
                  }
                })
              )
            }}
            type="primary">
            {t('submit')}
          </Button>
        </div>
      </div>
    </Spin>
  )
}

export default ChoosePlanTrial
