export const wishareClassNames = {
  inline_1: 'grid grid-cols-1 gap-2',
  inline_2:
    'grid grid-cols-1 md:grid-cols-2 gap-2',
  inline_3:
    'grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2',
  inline_4:
    'grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-3',
  inline_6:
    'grid grid-cols-3 md:grid-cols-6 gap-1 md:gap-2',
}
