import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  renderOwnChild,
} from 'views/Shared'
import AdminPanel from 'views/Wishare/AdminPanel'
import WishareAlert from 'views/Wishare/WishareAlert'
import UserContext from '../UserContext'
import UserNav from './UserNav'

const UserContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { user } = useContext(
    UserContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!user}>
        <WishareAlert entity={user}>
          {/*<AdminPanel />*/}
        </WishareAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <UserNav />
      </div>
    </Wrapper>
  )
}

export default UserContent
