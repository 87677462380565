import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  renderOwnChild,
} from 'views/Shared'
import AdminPanel from 'views/Wishare/AdminPanel'
import WishareAlert from 'views/Wishare/WishareAlert'
import ActivityContext from '../ActivityContext'
import ActivityNav from './ActivityNav'

const ActivityContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { activity } = useContext(
    ActivityContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!activity}>
        <WishareAlert entity={activity}>
          <AdminPanel />
        </WishareAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <ActivityNav />
      </div>
    </Wrapper>
  )
}

export default ActivityContent
