import { createValue } from 'components/form/utils'
import { isDevelop } from 'envs/ForDevelop'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import {
  deepTranslate,
  Null,
  renderIf,
} from 'views/Shared'
import renderClicker from '../functions/renderers/renderClicker'
import { OverlayBannerToggle } from './OverlayBanner'

const renderItem = ({
  label,
  value,
}) => (
  <label
    key={value}
    className="cursor-pointer">
    {label}
  </label>
)

const OverlayWrapper = ({
  item,
  children,
  onClick = Null,
  isSelected = Null,
}) => {
  const t = useTranslate()
  return (
    <OverlayBannerToggle
      onClick={onClick}
      uncontrolled={true}
      overlay={
        <span>{t('selected')}</span>
      }
      defaultValue={!!isSelected(item)}
      className="bg-primary text-white left-0 text-sm text-white pl-2 py-1 pr-5 overlay-banner-selected">
      <div
        className="max-w-2xl mx-auto text-center background-100 hover:bg-primary-50 text-color-100 hover:text-primary rounded-lg font-semibold text-xl cursor-pointer"
        style={{
          padding: '2rem 1rem',
        }}>
        {children}
      </div>
    </OverlayBannerToggle>
  )
}

const enhanceWrapper = (value) =>
  withProps({
    isSelected: ({ value: _value }) =>
      _.isEqual(value, _value),
  })(OverlayWrapper)

const ManualPicker = ({
  name,
  value,
  onChange,
  options = [],
  callback = Null,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const _options = useMemo(
    () => options.map(deepTranslate(t)),
    [t, options]
  )

  const onClick = ({ value }) => {
    onChange(createValue(name, value))
    callback(value)
  }

  const mapOption = useCallback(
    renderClicker({
      onClick,
      renderItem,
      Wrapper: enhanceWrapper(value),
    }),
    [
      value,
      onClick,
      renderClicker,
      enhanceWrapper,
    ]
  )

  if (!!isDevelop()) {
    console.log('Selected: ', value)
  }

  return renderIf(
    _options,
    <Wrapper className="space-y-3">
      {_options.map(mapOption)}
    </Wrapper>
  )
}

export default ManualPicker
