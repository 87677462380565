import React from "react";

export const Sport = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<circle cx="202.806" cy="256" r="176.766" fill="#e28086"></circle>
			<path
				fill="#dd636e"
				d="M212.013 79.47c55.416 29.839 93.082 88.391 93.082 155.74 0 97.625-79.141 176.766-176.766 176.766-3.088 0-6.158-.079-9.208-.236 24.911 13.414 53.409 21.026 83.684 21.026 97.625 0 176.766-79.141 176.766-176.766.001-94.537-74.212-171.74-167.558-176.53z"
			></path>
			<path
				fill="#dd636e"
				d="M379.406 263.726a180.077 180.077 0 000-15.452H210.532v-56.603c46.646-1.906 90.154-21.049 123.239-54.391a177.924 177.924 0 00-10.87-10.984c-30.177 30.458-69.827 48.01-112.37 49.908V79.4a180.077 180.077 0 00-15.452 0v96.803c-42.543-1.898-82.194-19.449-112.37-49.908a177.924 177.924 0 00-10.87 10.984c33.088 33.343 76.592 52.485 123.24 54.391v56.603H26.206a180.077 180.077 0 000 15.452H195.08v56.603c-46.648 1.905-90.152 21.047-123.24 54.391a178.206 178.206 0 0010.87 10.984c30.176-30.459 69.827-48.01 112.37-49.908V432.6a180.077 180.077 0 0015.452 0v-96.803c42.542 1.898 82.192 19.45 112.37 49.908a178.206 178.206 0 0010.87-10.984c-33.086-33.342-76.593-52.485-123.239-54.391v-56.603h168.873z"
			></path>
			<g fill="#da4a54">
				<path d="M195.079 432.6a180.112 180.112 0 0015.452 0v-40.86a175.938 175.938 0 01-15.453 7.199V432.6zM274.544 334.575a177.512 177.512 0 01-9.578 12.788c21.483 8.483 41.172 21.42 57.936 38.341a178.206 178.206 0 0010.87-10.984c-17.253-17.386-37.34-30.911-59.228-40.145zM333.772 137.279a177.924 177.924 0 00-10.87-10.984c-11.193 11.298-23.69 20.819-37.154 28.425a175.561 175.561 0 016.457 14.103c15.081-8.379 29.068-18.948 41.567-31.544zM379.572 256c0-2.589-.056-5.165-.166-7.726h-74.787a177.155 177.155 0 01-1.813 15.453h76.6c.11-2.562.166-5.138.166-7.727z"></path>
			</g>
			<path
				fill="#f9f6f6"
				d="M511.051 275.284a140.116 140.116 0 00-17.588-53.497l-94.113-68.235a141.637 141.637 0 00-28.384-2.857c-9.723 0-19.215.984-28.384 2.857l-94.113 68.235a140.1 140.1 0 00-17.588 53.497l36.112 111.736c12.52 13.653 27.693 24.833 44.703 32.722h118.542a141.437 141.437 0 0044.703-32.722z"
			></path>
			<g fill="#efe7e4">
				<path d="M363.238 316.3h15.453v96.54h-15.453zM288.2 405.938a140.96 140.96 0 0023.494 13.805l15.311-7.608a140.891 140.891 0 01-38.805-6.197zM511.051 275.284a140.1 140.1 0 00-17.589-53.497l-17.705-12.837-17.527 3.907c8.068 17.761 12.562 37.49 12.562 58.269 0 56.773-33.546 105.711-81.898 128.074l15.135 20.542h26.207a141.437 141.437 0 0044.703-32.722z"></path>
			</g>
			<circle cx="51.668" cy="381.097" r="51.668" fill="#ffe07d"></circle>
			<path
				fill="#ffd064"
				d="M51.75 329.43a51.44 51.44 0 0110.378 31.064c0 28.535-23.132 51.668-51.668 51.668h-.082c9.429 12.513 24.414 20.604 41.29 20.604 28.535 0 51.668-23.132 51.668-51.668-.001-28.509-23.089-51.624-51.586-51.668z"
			></path>
			<path
				fill="#efe7e4"
				d="M334.472 276.445l-83.073 50.153 2.175 16.737 76.081-45.931zM342.675 248.172l-51.352-48.025 10.456-11.378 53.73 50.249zM407.457 276.445l83.073 50.153-2.174 16.737-76.081-45.931z"
			></path>
			<path
				fill="#dad0cb"
				d="M470.758 323.685l5-6.006-10.739-6.483a139.847 139.847 0 01-5.316 14.841l11.675 7.049z"
			></path>
			<path
				fill="#efe7e4"
				d="M399.254 248.172l51.353-48.025-10.457-11.378-53.73 50.249z"
			></path>
			<g fill="#fff0af">
				<path d="M95.139 409.032c-10.469-13.243-26.548-21.084-43.471-21.084-16.922 0-33.002 7.84-43.472 21.083a51.939 51.939 0 0010.401 11.763c.254-.263.494-.546.714-.85 7.504-10.359 19.6-16.543 32.356-16.543s24.852 6.184 32.355 16.543c.221.305.46.588.714.851a51.9 51.9 0 0010.403-11.763zM8.196 353.161c10.47 13.244 26.549 21.084 43.472 21.084 16.922 0 33.001-7.84 43.471-21.084a51.943 51.943 0 00-10.402-11.763 7.878 7.878 0 00-.714.851c-7.503 10.359-19.599 16.544-32.355 16.544s-24.852-6.185-32.356-16.544a7.77 7.77 0 00-.714-.85 51.917 51.917 0 00-10.402 11.762z"></path>
			</g>
			<path
				fill="#ffe07d"
				d="M84.737 341.398a7.878 7.878 0 00-.714.851 40.132 40.132 0 01-21.986 15.162c.06 1.02.091 2.048.091 3.083 0 4.505-.577 8.876-1.661 13.042 13.59-2.206 26.038-9.452 34.671-20.374a51.898 51.898 0 00-10.401-11.764zM54.2 388.008c-9.148 14.512-25.317 24.153-43.739 24.153h-.082a52.014 52.014 0 008.219 8.635c.254-.263.494-.546.714-.85 7.504-10.359 19.6-16.543 32.356-16.543s24.852 6.184 32.355 16.543c.221.305.46.588.714.851a51.943 51.943 0 0010.402-11.763c-9.945-12.583-24.954-20.287-40.939-21.026z"
			></path>
			<path
				fill="#665e66"
				d="M406.361 228.751H335.57a3.25 3.25 0 00-3.086 2.23l-22.235 67.303a3.247 3.247 0 001.184 3.654l57.631 41.604c1.136.82 2.669.82 3.804 0l57.631-41.604a3.251 3.251 0 001.184-3.654l-22.235-67.303a3.252 3.252 0 00-3.087-2.23zM342.581 153.552c-40.285 8.231-74.317 33.637-94.113 68.235l59.564-15.097a3.253 3.253 0 001.951-1.417z"
			></path>
			<path
				fill="#544f57"
				d="M511.051 275.284l-39.463 47.402a3.252 3.252 0 00-.745 2.293l4.096 62.04C497.954 361.922 512 328.466 512 291.73c0-5.563-.322-11.051-.949-16.446z"
			></path>
			<path
				fill="#665e66"
				d="M230.879 275.284a142.406 142.406 0 00-.949 16.445c0 36.737 14.046 70.193 37.06 95.291l4.096-62.04a3.248 3.248 0 00-.745-2.293zM493.463 221.787c-19.797-34.598-53.829-60.004-94.113-68.235l32.599 51.721a3.246 3.246 0 001.951 1.417zM311.694 419.743c18.02 8.357 38.101 13.022 59.271 13.022s41.251-4.665 59.271-13.022l-57.825-28.733a3.247 3.247 0 00-2.892 0z"
			></path>
			<path
				fill="#544f57"
				d="M458.23 212.857l35.232 8.93c-17.599-30.757-46.449-54.249-80.94-64.869 19.69 14.293 35.539 33.553 45.708 55.939zM329.758 412.161c-.92 0-1.837-.009-2.753-.026l-15.311 7.608c18.02 8.357 38.101 13.022 59.271 13.022s41.251-4.665 59.271-13.022L388.895 399.2c-17.985 8.319-38.019 12.961-59.137 12.961z"
			></path>
		</svg>
	);
}

export default Sport;
