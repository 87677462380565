import { Select } from 'antd'
import classNames from 'classnames'
import Translate from 'modules/local/Translate'
import React from 'react'
import { FaCaretDown } from 'react-icons/fa'
import { Null } from 'views/Shared'

const IndicatorsContainer = ({
  children,
}) => (
  <div
    className="flex items-center"
    style={{
      padding: '0 8px',
    }}>
    {children}
  </div>
)

const DropdownIndicator = () => (
  <FaCaretDown
    style={{
      color: '#a0aec0',
      marginLeft: '8px',
    }}
  />
)

const DefaultEmptyHolder = React.memo(
  () => (
    <Translate>
      {(t) => (
        <div>
          {t(
            'no data is available now'
          )}
        </div>
      )}
    </Translate>
  )
)

export const reactSelectOnTop = {
  styles: {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  },
  menuPortalTarget: document.body,
  noOptionsMessage: () => (
    <DefaultEmptyHolder />
  ),
}

export const dropdownComponents = {
  DropdownIndicator,
  IndicatorsContainer,
  LoadingIndicator: Null,
}

export const reactSelectComponents = {
  DropdownIndicator,
  IndicatorsContainer,
  IndicatorSeparator: Null,
  LoadingIndicator: Null,
}

export const SelectWidget =
  React.forwardRef(
    ({ className, ...props }, ref) => {
      return (
        <Select
          ref={ref}
          {...{
            className: classNames(
              'SelectWidget',
              className
            ),
            suffixIcon:
              props?.loading ? (
                props?.suffixIcon
              ) : (
                <DropdownIndicator />
              ),
            ...props,
          }}
        />
      )
    }
  )

export const SelectDropdownIndicator =
  DropdownIndicator
