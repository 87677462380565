import { giving_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import TagsPicker from 'views/Wishare/custom/TagsPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { mapEntityOptions } from 'views/Wishare/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { MultiCategoryPicker } from '../../custom/TagsPicker'
import GivingContext from '../GivingContext'

const formFields = [
  {
    name: 'tags',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    title: 'tags',
    children: [
      {
        name: 'tags',
        component:
          createControlledFormField({
            Component: TagsPicker,
          }),
      },
    ],
  },
  {
    title: 'categories',
    children: [
      {
        name: 'categories',
        component:
          createControlledFormField({
            Component:
              MultiCategoryPicker,
          }),
      },
    ],
  },
]

const GivingSettingCategories = () => {
  const { giving } = useContext(
    GivingContext
  )

  const validationSchema = undefined

  const giving_id = getId(giving)

  if (_.isEmpty(giving)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: giving,
    validationSchema,
    query: bindQueryParam({
      giving_id,
    }),
    onPreSubmit: ({
      tags,
      categories,
      ...values
    }) => ({
      ...values,
      tags: Array.from(tags || []).join(
        ','
      ),
      categories: Array.from(
        categories || []
      ).join(','),
    }),
    initialized: ({
      tags,
      categories,
      ...values
    }) => ({
      ...values,
      tags: Array.from(tags || []).map(
        ({ tags }) => tags
      ),
      categories:
        mapEntityOptions(categories),
    }),
    apiInfo: giving_update_api,
  })
}

export default GivingSettingCategories
