import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Descriptions,
  Form,
  InputNumber,
  Radio,
  Switch,
} from 'antd'
import { search_lookkup_getByType_Api } from 'apis'
import { AsyncFormActionBar } from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useToggle } from 'react-use'
import {
  deepTranslate,
  Null,
} from 'views/Shared'
import '../../Shared/styles/FormList.css'
import { FlexCol } from '../Templates/ItemTemplate'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from '../wishareFieldTypes'
import { ExpandablePanel } from './AdvancedFormGroup'
import AntdConfigWrapper from './AntdConfigWrapper'
import FormListHeader from './FormListHeader'
import { AsyncCreatableDataSource } from './SelectDataSource'

const targetFields = {
  item_name: {
    name: 'item_name',
    placeholder: 'select item',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'item_name' },
    }),
  },
  target: {
    name: 'target',
    placeholder: 'target',
    getStep: (type) =>
      _.get(
        wishareFieldTypes.type_cds,
        `${type}.step`,
        1
      ),
  },
  unit_name: {
    name: 'unit_name',
    placeholder: 'select unit',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'unit_name' },
    }),
  },
}

export const TargetCreatorContext =
  React.createContext({})

export const TargetCreatorProvider = ({
  type_cd,
  type_cds,
  children,
  optionName,
  getGroup = () => [],
  ...props
}) => {
  const t = useTranslate()

  const options = useMemo(() => {
    const source = Array.from(
      type_cds || []
    ).find(
      ({ value }) => value === type_cd
    )
    const result = getGroup(source)
    return result.map(deepTranslate(t))
  }, [t, getGroup, type_cd, type_cds])

  const defaultType = useMemo(
    () => getDefaultFieldValue(options),
    [options]
  )

  const groupBy = useMemo(() => {
    return {
      options,
      defaultType,
    }
  }, [options, defaultType])

  const fromGroup = (type) => {
    const source =
      wishareFieldTypes.type_cds
    switch (type) {
      case source.person.value:
        return {
          fieldNames: [
            targetFields.target.name,
            targetFields.unit_name.name,
          ],
          defaultValues: {
            unit_name: undefined,
          },
        }
      case source.goods.value:
        return {
          fieldNames: [
            targetFields.item_name.name,
            targetFields.target.name,
            targetFields.unit_name.name,
          ],
          defaultValues: {
            unit_name: undefined,
          },
        }
      case source.money.value:
        return {
          fieldNames: [
            targetFields.target.name,
            targetFields.unit_name.name,
          ],
          defaultValues: {
            unit_name: undefined,
          },
          resourceParams: {
            creatable: false,
          },
        }
      case source.blood.value:
        return {
          fieldNames: [
            targetFields.target.name,
            targetFields.unit_name.name,
          ],
          defaultValues: {
            unit_name: undefined,
          },
          resourceParams: {
            creatable: false,
          },
        }
      default:
        return {
          fieldNames: [],
        }
    }
  }

  return (
    <TargetCreatorContext.Provider
      value={{
        ...props,
        groupBy,
        type_cd,
        type_cds,
        fromGroup,
      }}>
      <AntdConfigWrapper>
        {children}
      </AntdConfigWrapper>
    </TargetCreatorContext.Provider>
  )
}

const DefaultValueInput = ({
  disabled,
  step = 1,
  value = 0,
  placeholder,
  onChange = Null,
  ...props
}) => {
  const t = useTranslate()
  return (
    <InputNumber
      min={0}
      value={value}
      step={step}
      style={{
        padding: '3px 0',
        borderColor:
          'inherit!important',
      }}
      disabled={disabled}
      onChange={onChange}
      parser={parseNumber}
      className="w-full rounded FormList"
      formatter={formatterNumber}
      placeholder={t(placeholder)}
      {...props}
    />
  )
}

const TargetNotes = React.memo(() => {
  const t = useTranslate()
  const description =
    'Mục tiêu chi tiết là những mục tiêu nhỏ bên trong mục tiêu chính bên trên, có thể bao gồm tiền và vật phẩm, ' +
    'những mục tiêu nhỏ này giúp người dùng hiểu rõ hơn về chiến dịch và có thể lựa chọn cách đóng góp phù hợp với mình.'
  return (
    <Descriptions>
      <Descriptions.Item
        label={
          <span className="font-semibold">
            {t('note')}
          </span>
        }>
        {description}
      </Descriptions.Item>
    </Descriptions>
  )
})

const GroupWrapper = ({
  index,
  options,
  children,
  onRemove,
  defaultValue,
  onChange = Null,
  setValues = Null,
  prefixTitle = 'group',
}) => {
  const t = useTranslate()

  const defaultType = _.get(
    defaultValue,
    'type_cd'
  )

  const { no_limit_flag, target } =
    defaultValue || {}

  const title = `${t(prefixTitle)} ${
    index + 1
  }`

  const handleTypeChange = useCallback(
    (event) => {
      onChange('type_cd', event)
    },
    [onChange]
  )

  const handleLimitChange = useCallback(
    (checked) => {
      onChange('no_limit_flag', {
        target: {
          value: Number(checked),
        },
      })
    },
    [onChange]
  )

  useEffect(() => {
    setValues({
      type_cd: defaultType,
      no_limit_flag: Number(
        no_limit_flag || 0
      ),
    })
  }, [])

  return (
    <div className="p-2 border border-color-50 rounded-lg space-y-3">
      <FormListHeader
        title={title}
        onRemove={onRemove}
      />
      <div className="flex flex-1 gap-2 space-x-6 items-center">
        <Radio.Group
          options={options}
          buttonStyle="solid"
          optionType="button"
          defaultValue={defaultType}
          onChange={handleTypeChange}
        />

        <div className="space-x-3">
          <Switch
            defaultChecked={Boolean(
              no_limit_flag
            )}
            onChange={handleLimitChange}
          />
          <label>
            {t(
              Boolean(no_limit_flag)
                ? 'no limit'
                : 'limited'
            )}
          </label>
        </div>
      </div>
      {_.isFunction(children)
        ? children(defaultType)
        : children}
    </div>
  )
}

export const TargetItemField = ({
  index,
  remove,
  onChange,
  ...props
}) => {
  const t = useTranslate()

  const currentTarget =
    _.get(props, 'value') || {}

  const {
    groupBy,
    prefixTitle = 'target',
    fromGroup = Null,
  } = useContext(TargetCreatorContext)

  const { options, defaultType } =
    groupBy || {}

  const {
    item_name,
    unit_name,
    target = 0,
    no_limit_flag,
    type_cd = defaultType,
  } = currentTarget

  const triggerChanges = useCallback(
    (values = {}) => {
      onChange({
        ...currentTarget,
        ...values,
      })
    },
    [onChange, currentTarget]
  )

  const invalidField = useCallback(
    (name, group_type) => {
      const {
        fieldNames = [],
        resourceParams,
      } = fromGroup(group_type) || {}
      return [
        !_.includes(fieldNames, name),
        resourceParams,
      ]
    },
    [fromGroup]
  )

  const handleChange = useCallback(
    (name, event) => {
      const value = _.get(
        event,
        'target.value'
      )
      triggerChanges({ [name]: value })
    },
    [triggerChanges]
  )

  const renderTargetResouce =
    useCallback(
      (name, value, group_type) => {
        const [
          invalid,
          resourceParams = {},
        ] = invalidField(
          name,
          group_type
        )
        if (invalid) {
          return null
        }
        const {
          createAsyncParams = Null,
          ...rest
        } =
          _.get(targetFields, name) ||
          {}

        const params =
          createAsyncParams({
            group_type,
          }) || {}

        return (
          <FlexCol className="GridFlex RequiredInp">
            <Form.Item
              name={[index, name]}
              rules={[
                {
                  required: true,
                  message: t(
                    'required field'
                  ),
                },
              ]}>
              <AsyncCreatableDataSource
                key={JSON.stringify([
                  name,
                  group_type,
                ])}
                fieldValue="name"
                menuPlacement="top"
                {...rest}
                {...params}
                {...resourceParams}
                value={value}
                onChange={(value) =>
                  triggerChanges({
                    [name]: value,
                  })
                }
              />
            </Form.Item>
          </FlexCol>
        )
      },
      [
        t,
        index,
        invalidField,
        triggerChanges,
      ]
    )

  const renderTargetInput = useCallback(
    (
      name,
      value,
      group_type,
      no_limit_flag
    ) => {
      const [invalid] = invalidField(
        name,
        group_type
      )
      if (invalid) {
        return null
      }
      const { getStep = Null } =
        _.get(targetFields, name) || {}
      const step = getStep(group_type)

      return (
        <FlexCol className="GridFlex RequiredInp">
          <Form.Item
            name={[index, name]}
            required={true}
            requiredMark={true}
            rules={[
              {
                min: !!no_limit_flag
                  ? 0
                  : 1,
                required:
                  !no_limit_flag,
                type: 'number',
                message: t(
                  'required field'
                ),
              },
            ]}>
            <DefaultValueInput
              key={JSON.stringify([
                name,
                group_type,
              ])}
              step={step}
              value={
                !!no_limit_flag
                  ? 0
                  : value
              }
              disabled={!!no_limit_flag}
              onChange={(value) => {
                triggerChanges({
                  [name]: Number(value),
                })
              }}
            />
          </Form.Item>
        </FlexCol>
      )
    },
    [
      t,
      index,
      invalidField,
      triggerChanges,
      no_limit_flag,
    ]
  )

  return (
    <GroupWrapper
      index={index}
      options={options}
      onRemove={remove}
      defaultValue={{
        type_cd,
        no_limit_flag,
      }}
      setValues={triggerChanges}
      prefixTitle={prefixTitle}
      onChange={handleChange}>
      {(group_type) => (
        <div className="CustomGrid grid md:grid-cols-2 items-start gap-2">
          {renderTargetResouce(
            targetFields.item_name.name,
            item_name,
            group_type
          )}
          {renderTargetInput(
            targetFields.target.name,
            target,
            group_type,
            no_limit_flag
          )}
          {renderTargetResouce(
            targetFields.unit_name.name,
            unit_name,
            group_type
          )}
        </div>
      )}
    </GroupWrapper>
  )
}

const TargetContent = ({
  name,
  targets = [],
  handleChange = Null,
}) => {
  const t = useTranslate()

  const [form] = Form.useForm()

  return (
    <div className="space-y-3">
      <Form
        form={form}
        autoComplete="off"
        onValuesChange={(
          changedValues,
          values
        ) => {
          handleChange(
            createValue(
              name,
              values.targets
            )
          )
        }}>
        <Form.List
          name={name}
          initialValue={targets}>
          {(
            fields,
            { add, remove },
            { errors }
          ) => (
            <React.Fragment>
              {fields.map(
                (field, index) => (
                  <Form.Item
                    {...field}
                    key={field.key}>
                    <TargetItemField
                      index={index}
                      remove={() =>
                        remove(
                          field.name
                        )
                      }
                    />
                  </Form.Item>
                )
              )}
              <Form.Item>
                <Button
                  className="rounded-lg no-shadow no-text-shadow"
                  type="primary"
                  onClick={() => add()}
                  icon={
                    <PlusOutlined />
                  }>
                  {t('add')}
                </Button>
                <Form.ErrorList
                  errors={errors}
                />
              </Form.Item>
            </React.Fragment>
          )}
        </Form.List>
        <AsyncFormActionBar
          validate={form.validateFields}
        />
      </Form>
    </div>
  )
}

const TargetCreator = ({
  name,
  value,
  type_cd,
  type_cds,
  onChange = Null,
  Wrapper = FlexCol,
  defaultOpen = true,
}) => {
  const [isToggle, toggle] = useToggle(
    Boolean(defaultOpen)
  )

  return (
    <TargetCreatorProvider
      prefixTitle="target"
      type_cd={type_cd}
      type_cds={type_cds}
      getGroup={(source) =>
        _.get(source, 'type_cds') || []
      }>
      <ExpandablePanel
        title="add target details"
        onClick={toggle}
        expanded={isToggle}>
        <TargetNotes />
        <Wrapper className="space-y-3 py-2">
          <TargetContent
            name={name}
            targets={value}
            handleChange={onChange}
          />
        </Wrapper>
      </ExpandablePanel>
    </TargetCreatorProvider>
  )
}

export default TargetCreator
