import { Avatar } from 'antd'
import { getId } from 'apis/model/base'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
} from 'react'
import {
  IoCheckmarkCircleOutline,
  IoPerson,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { renderSelf } from 'views/Shared'
import { beneficiaryApi } from 'views/Wishare/apis'
import EntityList from 'views/Wishare/EntityList'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import wishareModel from 'views/Wishare/wishareModel'
import getLinkToDetail from '../../../../helpers/getLinkToDetail'
import {
  WishareFollowButton,
  WishareVoteButton,
} from '../../custom/ToggleActionButton'
import BeneficiaryContext from '../BeneficiaryContext'
import BeneficiaryInformation from "../holders/BeneficiaryInformation";

const ActivityLinking = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { beneficiary } = useContext(
    BeneficiaryContext
  )

  const id = getId(beneficiary)

  const apiInfo =
    beneficiaryApi.beneficiary_getActivityHistory_api

  const refreshKey = [
    mapPathParam(apiInfo.path, { id }),
  ].join('/')

  const renderActivityLinking =
    useCallback(
      (item, index) => {
        const activity = _.get(
          item,
          'activity'
        )

        const TimeJoin = () => (
          <div className="flex items-center text-2xs gap-1">
            {[
              {
                label: 'from',
                fieldName: 'eff_date',
                transform: (value) =>
                  value
                    ? moment(
                        value
                      ).format(
                        'DD/MM/YYYY'
                      )
                    : '-/-',
              },
              {
                label: 'to',
                fieldName: 'end_date',
                transform: (value) =>
                  value
                    ? moment(
                        value
                      ).format(
                        'DD/MM/YYYY'
                      )
                    : '-/-',
              },
            ].map(
              (
                {
                  label,
                  fieldName,
                  transform = renderSelf,
                },
                index
              ) => {
                const date = transform(
                  _.get(item, fieldName)
                )
                return (
                  <React.Fragment
                    key={index}>
                    <span className="font-light text-color-400 italic">
                      {t(label)}
                    </span>
                    <span className="font-medium text-color-000">
                      {date}
                    </span>
                  </React.Fragment>
                )
              }
            )}
          </div>
        )

        return (
          <div
            key={index}
            style={
              _.get(item, 'end_date')
                ? { opacity: '0.5' }
                : { opacity: '1' }
            }
            className="flex items-center gap-2">
            <Avatar
              size={50}
              icon={<IoPerson />}
              src={getAvatar(activity)}
              className="flex items-center justify-center background-100 border border-color-50"
            />
            <div className="flex flex-col flex-1">
              <Link
                to={wishareModel.getLinkToDetail(
                  activity
                )}
                className="text-color-act font-semibold no-underline leading-tight max-lines-2 text-sm italic">
                {getTitle(activity)}
              </Link>
              <TimeJoin />
            </div>
            {_.get(
              item,
              'end_date'
            ) ? undefined : (
              <IoCheckmarkCircleOutline className="text-primary" />
            )}
          </div>
        )
      },
      [t]
    )

  return (
    <Wrapper className="space-y-3">
      <div className="uppercase text-sm text-color-300">
        {t('activity link')}
      </div>
      <EntityList
        apiInfo={apiInfo}
        refreshKey={refreshKey}
        RenderEntity={React.Fragment}
        query={bindQueryParam({ id })}
        renderItem={
          renderActivityLinking
        }
      />
    </Wrapper>
  )
}

const AccountLinking = () => {
  const t = useTranslate()
  const { beneficiary } = useContext(
    BeneficiaryContext
  )

  const { owner } = beneficiary || {}

  if (_.isEmpty(owner)) {
    return null
  }

  return (
    <div>
      <div className="uppercase text-sm text-color-300">
        {t('account link')}
      </div>
      <div className="flex flex-col items-center">
        <Avatar
          size={60}
          src={_.get(
            beneficiary,
            'owner.avatar'
          )}
          className="background-100 flex flex-center mb-4">
          <span className="uppercase text-color-100">
            {_.first(
              _.get(
                beneficiary,
                'owner.title'
              )
            )}
          </span>
        </Avatar>
        <Link
          to={getLinkToDetail(
            beneficiary.owner
          )}
          className="text-lg font-bold text-color-org no-underline text-center leading-tight mb-1">
          {getTitle(owner)}
        </Link>
        <span className="text-sm text-secondary italic font-light">
          {t(
            _.get(
              beneficiary,
              'owner_type'
            )
          )}
        </span>
      </div>
    </div>
  )
}

const BeneficiaryLinking = () => (
  <FlexCol className="p-2 space-y-6">
    <BeneficiaryContext.Consumer>
      {({ beneficiary }) => (
        <div className="grid grid-cols-2 gap-3 flex-1">
          <WishareFollowButton
            item={beneficiary}
          />
          <WishareVoteButton
            item={beneficiary}
          />
        </div>
      )}
    </BeneficiaryContext.Consumer>
    {/*<UserLinking />*/}
    <AccountLinking />
    <ActivityLinking />
  </FlexCol>
)

export default BeneficiaryLinking
