import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { Null } from 'views/Shared'
import { FlexCol } from '../Templates/ItemTemplate'

const FieldInputWrapper = ({
  title,
  style,
  children,
  className,
}) => {
  return (
    <div
      style={style}
      className={classNames(
        'flex flex-col justify-center',
        className
      )}>
      {_.isString(title) ? (
        <div className="flex items-center horizontal-form-label text-sm text-color-300">
          <Translate>
            {(t) => (
              <span className="">{`${t(
                title
              )}: `}</span>
            )}
          </Translate>
        </div>
      ) : (
        title || null
      )}
      <FlexCol flex={true}>
        {children}
      </FlexCol>
    </div>
  )
}

export const renderInputField =
  ({
    title,
    Component = Null,
    Wrapper = FieldInputWrapper,
    ...rest
  }) =>
  (props) =>
    (
      <Wrapper
        {...{ title }}
        {...rest}>
        <Component {...props} />
      </Wrapper>
    )

export default FieldInputWrapper
