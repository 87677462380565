import { Button, Spin } from 'antd'
import { create_loadMore_Api_action } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import Null from 'components/NullComponent'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Selector } from 'views/Discovery/Recommendation'
import {
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import { createAsyncAction } from '..'
import useTranslate from '../../local/useTranslate'
import { getAsynCacheSelector } from '../selectors'
import useAsyncList from '../useAsyncList'
import {
  AsyncByAction,
  Error,
  Success,
} from './Async'
import { DidOnMount } from './DidOnMount'
import { LazyList } from './LazyList'

const cache = {}
export const LazyPagination = ({
  refreshKey,
  reversed,
  cacheId,
  onDone,
  limit,
  sample,
  onChange,
  renderWrap,
  renderEmpty = () => <EmptyHolder />,
  renderNoMore,
  renderLoading = () => (
    <div className="w-full flex justify-center">
      <Spin className="m-auto" />
    </div>
  ),
  debug,
  query,
  children,
  renderList,
  renderItem = Null,
  apiInfo,
  values,
  Wrapper = renderOwnChild,
  Header = Null,
  PaginationMeta = Null,
}) => {
  const [errorAction, setErrorAction] =
    useState()
  const firstAction = useMemo(
    () =>
      createAsyncAction({
        prefixStr: apiInfo.path,
        query,
        values,
        apiInfo,
      }),
    [apiInfo, query, values]
  )
  const [done, setDone] = useState()
  useEffect(() => {
    done && onDone && onDone()
  }, [done, onDone])
  const [map, { set, reset }] =
    useAsyncList(
      cache[cacheId]
        ? cache[cacheId]
        : []
    )
  useEffect(() => {
    if (cacheId) {
      cache[cacheId] = map
    }
    onChange && onChange(map)
  }, [cacheId, map, onChange])
  useEffect(() => {
    reset()
    set(0, firstAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey])
  const dispatch = useDispatch()
  const t = useTranslate()

  const action_array = useMemo(
    () =>
      Object.values(map).filter(
        (_, i) => !limit || i < limit
      ),
    [map, limit]
  )

  const current_action =
    _.last(
      Boolean(done)
        ? action_array
        : action_array.slice(0, -1)
    ) || {}

  const render = () => (
    <>
      <LazyList reversed={reversed}>
        {action_array.map(
          (action, i) => {
            const schema = _.get(
              action,
              'apiInfo.schema'
            )
            return (
              <AsyncByAction
                key={action.asyncId}
                action={action}>
                <Error>
                  {() => (
                    <DidOnMount
                      onMount={() =>
                        setErrorAction(
                          action
                        )
                      }></DidOnMount>
                  )}
                </Error>
                <Success>
                  {(data) => {
                    const {
                      success,
                      response,
                      result = [],
                    } = data
                    const nextUrl =
                      _.get(
                        response,
                        'data.meta.pagination.links.next'
                      )
                    const list = schema
                      ? result
                      : _.get(
                          response,
                          'data.data'
                        ) || []
                    if (
                      i === 0 &&
                      success &&
                      list.length === 0
                    ) {
                      return (
                        <React.Fragment>
                          <DidOnMount
                            onMount={() =>
                              setDone(
                                true
                              )
                            }>
                            <div />
                          </DidOnMount>
                          <Wrapper
                            setDone={
                              setDone
                            }
                            count={
                              list.length
                            }>
                            <Selector
                              selector={(
                                state
                              ) =>
                                getAsynCacheSelector(
                                  state,
                                  current_action.asyncId
                                )
                              }>
                              {(data) =>
                                renderIf(
                                  !!success &&
                                    list.length,
                                  <Header
                                    {...{
                                      data,
                                      meta: (
                                        <PaginationMeta
                                          {...{
                                            data,
                                          }}
                                        />
                                      ),
                                    }}
                                  />
                                )
                              }
                            </Selector>
                            {renderEmpty()}
                          </Wrapper>
                        </React.Fragment>
                      )
                    }
                    const nextid = i + 1
                    return (
                      <Wrapper
                        setDone={
                          setDone
                        }
                        count={
                          list.length
                        }>
                        {i === 0 && (
                          <Selector
                            selector={(
                              state
                            ) =>
                              getAsynCacheSelector(
                                state,
                                current_action.asyncId
                              )
                            }>
                            {(data) =>
                              renderIf(
                                !!success &&
                                  list.length,
                                <Header
                                  {...{
                                    data,
                                    meta: (
                                      <PaginationMeta
                                        {...{
                                          data,
                                        }}
                                      />
                                    ),
                                  }}
                                />
                              )
                            }
                          </Selector>
                        )}
                        {renderList
                          ? renderList(
                              list,
                              schema
                            )
                          : list
                              .filter(
                                (
                                  _,
                                  iList
                                ) =>
                                  !sample ||
                                  (i ===
                                    0 &&
                                    iList <
                                      sample)
                              )
                              .map(
                                (
                                  item,
                                  i
                                ) =>
                                  schema ? (
                                    <SelectEntityItem
                                      key={
                                        i
                                      }
                                      item={
                                        item
                                      }
                                      schema={
                                        schema
                                      }>
                                      {(
                                        item
                                      ) =>
                                        renderItem(
                                          item,
                                          i,
                                          action
                                        )
                                      }
                                    </SelectEntityItem>
                                  ) : (
                                    renderItem(
                                      item,
                                      i,
                                      action
                                    )
                                  )
                              )}
                        {i === 0 &&
                        success &&
                        list.length !==
                          0 &&
                        !!sample ? (
                          <>
                            <DidOnMount
                              onMount={() =>
                                setDone(
                                  true
                                )
                              }>
                              <div />
                            </DidOnMount>
                          </>
                        ) : null}
                        {!map[nextid] &&
                          nextUrl &&
                          !sample && (
                            <DidOnMount
                              onMount={() => {
                                set(
                                  nextid,
                                  create_loadMore_Api_action(
                                    {
                                      prefixStr:
                                        firstAction.asyncId,
                                      path: nextUrl,
                                      apiInfo:
                                        action.apiInfo,
                                    }
                                  )
                                )
                              }}>
                              <div />
                            </DidOnMount>
                          )}
                        {!(
                          i === 0 &&
                          success &&
                          list.length ===
                            0
                        ) &&
                          success &&
                          !nextUrl && (
                            <>
                              <DidOnMount
                                onMount={() =>
                                  setDone(
                                    true
                                  )
                                }>
                                <div />
                              </DidOnMount>
                              {!!renderNoMore &&
                                renderNoMore()}
                            </>
                          )}
                      </Wrapper>
                    )
                  }}
                </Success>
              </AsyncByAction>
            )
          }
        )}
      </LazyList>
      {!!errorAction && (
        <div className="flex flex-col flex-center gap-2">
          <EmptyHolder
            icon={Null}
            title={t(
              'Something went wrong. Please try again.'
            )}
            subTitle={() => (
              <Button
                onClick={() => {
                  setErrorAction(null)
                  dispatch(errorAction)
                }}
                type="primary"
                className="rounded-lg font-medium px-3 flex items-center justify-center mt-2">
                {t('retry')}
              </Button>
            )}
          />
        </div>
      )}
      {!errorAction &&
        !done &&
        renderLoading()}
    </>
  )
  if (children) {
    return children(render, map)
  }

  if (renderWrap) {
    return renderWrap(
      <React.Fragment>
        {render()}
      </React.Fragment>,
      Null
    )
  }

  return render()
}
