import { createLazyComponent } from 'helpers/createLazyComponent'
import _ from 'lodash'
import React, { useRef } from 'react'
import { GrEmoji } from 'react-icons/gr'
import {
  useClickAway,
  useToggle,
} from 'react-use'
import { Null } from 'views/Shared'

const EmojiPicker = createLazyComponent(
  () => import('components/EmojiPicker')
)

function splitAt(it, index) {
  if (it) {
    return [
      it.slice(0, index),
      it.slice(index),
    ]
  } else {
    return [,]
  }
}

function getContent(emo, cursorIndex) {
  return (currentText) => {
    const emoji =
      emo.native || emo.colons

    if (!emoji) {
      return currentText
    }

    const [left, right] = splitAt(
      currentText,
      cursorIndex
    )
    return _.join(
      [left, emoji, right],
      ''
    )
  }
}

const EmojiWrapper = ({
  content,
  onChange = Null,
  currentIndex = 0,
  Component = GrEmoji,
}) => {
  const ref = useRef()

  const [isToggle, toggle] = useToggle()

  useClickAway(ref, () => {
    toggle(false)
  })

  return (
    <div className="flex items-center">
      <Component
        onClick={toggle}
        className="text-yellow-500 text-2xl cursor-pointer"
      />
      {isToggle && (
        <div
          ref={ref}
          className="absolute z-30 top-0">
          <EmojiPicker
            onSelect={(selected) => {
              toggle(false)
              const text = getContent(
                selected,
                currentIndex
              )(content)
              onChange(text)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default EmojiWrapper
