import {getId, getType,} from 'apis/model/base'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {IoCalendarOutline, IoListOutline} from 'react-icons/io5'
import {compose, fromRenderProps, nest, withProps,} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {renderFalse, renderOwnChild,} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {createCheckPermissions, invalidPermissions,} from 'views/Wishare/factory/createCheckPermissions'
import {SettingsMenu} from 'views/Wishare/factory/createSettingLayout'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import {WishareRibbonSvg} from '../../../../components/Svgs/WishareRibbonSvg'
import WorkspaceChannelList from '../Channels/WorkspaceChannelList'
import {useMenuHandler} from '../useMenuHandler'
import WorkspaceContext from '../WorkspaceContext'
import WorkspaceActivityList from './WorkspaceActivityList'
import WorkspaceEventList from './WorkspaceEventList'
import WorkspaceOthersActivityList from './WorkspaceOthersActivityList'

const ItemKeys = Object.freeze({
  EVENTS: 'events',
  CHANNELS: 'channels',
  ACTIVITIES: 'activities',
  RELATED_ACTIVITIES:
    'related-activities',
  ACTIVITY_GROUP: 'activity-group',
})

const routeEntities = [
  {
    key: ItemKeys.ACTIVITIES,
    component: WorkspaceActivityList,
  },
  {
    key: ItemKeys.CHANNELS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageChannel,
    ])(WorkspaceChannelList),
  },
  {
    key: ItemKeys.EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageEvent,
    ])(WorkspaceEventList),
  },
  {
    key: ItemKeys.RELATED_ACTIVITIES,
    component:
    WorkspaceOthersActivityList,
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const with_menu_items = ({
                           isDisabled = renderFalse,
                         }) => [
  {
    key: ItemKeys.ACTIVITY_GROUP,
    label: 'activities',
    icon: (
      <WishareRibbonSvg
        width="1rem"
        height="1rem"
        fill={'currentColor'}
      />
    ),
    children: [
      {
        key: ItemKeys.ACTIVITIES,
        label: 'activity list',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
      {
        key: ItemKeys.RELATED_ACTIVITIES,
        label: 'activity of co-owners',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
    ],
  },
  {
    key: ItemKeys.CHANNELS,
    label: 'channels',
    icon: <ChannelIcon size={16}/>,
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageChannel,
      ],
    }),
  },
  {
    key: ItemKeys.EVENTS,
    label: 'events',
    icon: (
      <IoCalendarOutline size={16}/>
    ),
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageEvent,
      ],
    }),
  },
]

const WorkspaceActivities = ({
                               owner_id,
                               owner_type,
                             }) => {
  const {
    activeKey,
    Component,
    onMenuSelect,
  } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  return (
    <DefaultTemplate
      renderMenu={() => (
        <WishareSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled:
                    invalidPermissions(
                      permissions
                    ),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[
                  activeKey,
                ]}
              />
            )}
          </PermissionContext.Consumer>
        </WishareSiderMenu>
      )}>
      <div className="space-y-3 px-3">
        <Component/>
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({lazy: true})(
    DelayRender
  ),
  compose(
    fromRenderProps(
      WorkspaceContext.Consumer,
      ({organization}) => ({
        owner_id: getId(organization),
        owner_type: getType(
          organization
        ),
      })
    )
  )(WorkspaceActivities)
)
