import { organization_getOrgMembersSiteMaps_Api } from 'apis'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import SunburstChart from 'views/Chart/Charts/ChartComponents/SunburstChart'
import LoadingPage from 'views/LoadingPage'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'

const DefaultHeader = () => {
  const t = useTranslate()

  return (
    <div className="text-color-200 uppercase font-medium text-lg">
      {t('charts')}
    </div>
  )
}

const WorkspaceHostCharts = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  Header = DefaultHeader,
}) => {
  const { response, isLoading } =
    useAsync({
      apiInfo:
        organization_getOrgMembersSiteMaps_Api,
      query: bindQueryParam({
        organization_id: owner_id,
      }),
    })

  const data_array = _.get(
    response,
    'data.data.children',
    []
  )

  return (
    <Wrapper>
      <Header />
      <div>
        {!!isLoading ? (
          <LoadingPage />
        ) : (
          <SunburstChart
            chartInfo={{
              id: owner_id,
              idname: owner_id,
              data_values: data_array,
            }}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default WorkspaceHostCharts
