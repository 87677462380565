import { Input } from 'antd'
import { recruitment_update_api } from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {
  useContext,
  useMemo,
} from 'react'
import {
  compose,
  mapProps,
} from 'recompose'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import PhotoField from 'views/Wishare/custom/PhotoField'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { ReactSelectDataSource } from 'views/Wishare/custom/SelectDataSource'
import VideoField from 'views/Wishare/custom/VideoField'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredEmail,
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  { name: 'title' },
  {
    name: 'about',
  },
  { name: 'photo' },
  { name: 'time_open' },
  { name: 'time_close' },
  { name: 'description' },
  { name: 'recruitment_type' },
  { name: 'recruitment_email' },
  { name: 'recruitment_phone' },
  { name: 'privacy', defaultValue: 1 },
  { name: 'active', defaultValue: 1 },
  { name: 'status', defaultValue: 1 },
  {
    name: 'registrable',
    defaultValue: 1,
  },
  {
    name: 'clip_url',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    inline: true,
    className: 'text-right',
    children: [
      {
        label: 'status',
        name: 'status',
        component: withAdvancedOptions(
          baseFieldTypes.status
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            maxLength: 160,
            showCount: true,
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    title: 'recruitment type',
    children: [
      {
        name: 'recruitment_type',
        component:
          createControlledFormField({
            options:
              wishareFieldTypes
                .recruitment.types,
            Component:
              ReactSelectDataSource,
            placeholder:
              'recruitment type',
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'email',
        name: 'recruitment_email',
        component:
          createControlledFormField({
            type: 'email',
            placeholder: 'email',
          }),
      },
      {
        label: 'phone',
        name: 'recruitment_phone',
        component:
          createControlledFormField({
            type: 'tel',
            placeholder: 'phone',
          }),
      },
    ],
  },
  {
    title: 'open/close recruitment',
    children: [
      {
        name: 'time_open',
        component:
          createDateRangerPicker([
            'time_open',
            'time_close',
          ]),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
      },
      {
        label: 'active',
        name: 'active',
        component: withAdvancedOptions(
          baseFieldTypes.active
        )(RadioOptions),
      },
      {
        label: 'registrable',
        name: 'registrable',
        component: withAdvancedOptions(
          wishareFieldTypes.registrable
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'about',
    children: [
      {
        name: 'about',
        component:
          createControlledFormField({
            placeholder: 'about',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
  {
    title: 'photo',
    children: [
      {
        name: 'photo',
        component: compose(
          mapProps(
            ({
              name,
              value,
              form,
              onChange,
            }) => {
              const values = _.get(
                form,
                'values'
              )
              return {
                name,
                value,
                values,
                onChange,
              }
            }
          )
        )(PhotoField),
      },
    ],
  },
  {
    title: 'clip_url',
    children: [
      {
        name: 'clip_url',
        component:
          createControlledFormField({
            Component: VideoField,
          }),
      },
    ],
  },
]

const RecruitmentSettingOverview =
  () => {
    const t = useTranslate()
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema =
      useMemo(() => {
        return yupShape({
          title: requiredString(t),
          recruitment_email:
            requiredEmail(t),
        })
      }, [t])

    const recruitment_id = getId(
      recruitment
    )

    const onPreSubmit = ({
      photo_preview,
      ...values
    }) => {
      return values
    }

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      onPreSubmit,
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingOverview
