import Icon from '@ant-design/icons'
import { Badge } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { ThemeContext } from 'modules/theme/ThemeContext'
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react'
import {
    IoAlbums,
    IoAlbumsOutline, IoChatbubbles, IoChatbubblesOutline,
    IoNotifications,
    IoNotificationsOutline,
} from 'react-icons/io5'
import {
  TiThMenu,
  TiThMenuOutline,
} from 'react-icons/ti'
import { connect } from 'react-redux'
import {
  NavLink,
  Route,
} from 'react-router-dom'
import { withProps } from 'recompose'
import { getLogin } from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import { Null } from 'views/Shared'
import ShortAppLogoSvg from '../../Svgs/ShortAppLogoSvg'
import './BottomBar.css'

export const options = [
  // {
  //     title: 'start',
  //     key: 'start',
  //     to: staticPaths.start,
  //     Icon: withProps({
  //         component: IoCafeOutline,
  //     })(Icon),
  //     ActiveIcon: withProps({
  //         component: IoCafe,
  //     })(Icon),
  // },

    {
    title: 'discovery',
    key: 'discovery',
    to: staticPaths.discovery,
    Icon: withProps({
      component: IoAlbumsOutline,
    })(Icon),
    ActiveIcon: withProps({
      component: IoAlbums,
    })(Icon),
  },

    {
        title: 'messages',
        key: 'messages',
        to: staticPaths.messages,
        Icon: connect((state) => ({
            count: (getLogin(state) || {}).count_msg_notifications,
        }))(({ count, className }) => (
            <Badge
                size={'small'}
                className="relative custom-badge"
                count={count}>
                <Icon component={IoChatbubblesOutline} className={className}/>
            </Badge>
        )),
        ActiveIcon: connect((state) => ({
            count: (getLogin(state) || {}).count_msg_notifications,
        }))(({ count, className }) => (
            <Badge
                size={'small'}
                className="relative custom-badge"
                count={count}>
                <Icon component={IoChatbubbles} className={classNames(className, 'text-primary')}/>
            </Badge>
        )),
    },
    {
        title: 'wishare',
        key: 'wishare',
        to: staticPaths.home,
        Icon: withProps({
            component: ShortAppLogoSvg,
        })(Icon),
        ActiveIcon: withProps({
            component: ShortAppLogoSvg,
        })(Icon),
    },

    {
        title: 'notification',
        key: 'notifications',
        Icon: connect((state) => ({
            count: (getLogin(state) || {})
                .count_notifications,
        }))(({ count, className }) => (
            <Badge
                size={'small'}
                className="relative custom-badge"
                count={count}>
                <Icon
                    component={
                        IoNotificationsOutline
                    }
                    className={className}
                />
            </Badge>
        )),
        ActiveIcon: connect((state) => ({
            count: (getLogin(state) || {})
                .count_notifications,
        }))(({ count, className }) => (
            <Badge
                size={'small'}
                className="relative custom-badge"
                count={count}>
                <Icon
                    component={() => (
                        <IoNotifications
                            fill={
                                'var(--primary-color)'
                            }
                        />
                    )}
                    className={classNames(
                        className,
                        'text-primary'
                    )}
                />
            </Badge>
        )),
        to: {
            pathname: '/notifications',
        },
    },
    {
    title: 'menu',
    key: 'menu',
    to: {
      pathname: '/menu',
    },
    Icon: withProps({
      component: TiThMenuOutline,
    })(Icon),
    ActiveIcon: withProps({
      component: TiThMenu,
    })(Icon),
  },
]
export const BottomBar = React.memo(
  () => {
    const t = useTranslate()
    const { changeStickyBottom } =
      useContext(ThemeContext)
    useEffect(() => {
      changeStickyBottom('50px')
      return () =>
        changeStickyBottom('0px')
    }, [changeStickyBottom])

    const renderIcon = useCallback(
      (
        isSelected = false,
        [ActiveIcon, Icon] = [
          Null,
          Null,
        ]
      ) =>
        isSelected ? (
          <ActiveIcon className="BottomBarItem_icon" />
        ) : (
          <Icon className="BottomBarItem_icon" />
        ),
      []
    )

    return (
      <header className="BottomBar">
        <div
          style={{
            height:
              'var(--header-height)',
            zIndex: 50,
          }}
        />
        <nav
          id={'footer-menu'}
          className="w-full  background items-stretch flex  border-t border-gray-300">
          {options
            .filter(Boolean)
            .map((item, i) => {
              const {
                to,
                title,
                Icon,
                ActiveIcon,
              } = item
              return (
                <NavLink
                  to={to}
                  exact
                  className="BottomBarItem"
                  activeClassName="BottomBarItem__active"
                  isActive={(
                    match,
                    location
                  ) => {
                    const path =
                      _.get(
                        match,
                        'path.pathname',
                        _.get(
                          match,
                          'url'
                        )
                      ) || to.pathname
                    const locationPath =
                      _.get(
                        location,
                        'pathname',
                        ''
                      )

                    if (!match && !to) {
                      return false
                    }

                    if (
                      path === '/' ||
                      path === ''
                    ) {
                      return !!(
                        locationPath ===
                          path ||
                        locationPath.startsWith(
                          staticPaths
                            .home
                            .pathname
                        )
                      )
                    }

                    return !!_.get(
                      location,
                      'pathname',
                      ''
                    ).includes(path)
                  }}
                  key={i}>
                  <Route
                    path={_.get(
                      to,
                      'pathname',
                      to
                    )}
                    children={({
                      match,
                      location,
                    }) => {
                      const path =
                        _.get(
                          match,
                          'path.pathname',
                          _.get(
                            match,
                            'url',
                            _.get(
                              match,
                              'path'
                            )
                          )
                        )
                      const locationPath =
                        _.get(
                          location,
                          'pathname',
                          ''
                        )
                      if (
                        [
                          '/',
                          '',
                        ].includes(path)
                      ) {
                        return renderIcon(
                          locationPath ===
                            path ||
                            locationPath.startsWith(
                              staticPaths
                                .home
                                .pathname
                            ),
                          [
                            ActiveIcon,
                            Icon,
                          ]
                        )
                      }

                      return renderIcon(
                        _.get(
                          location,
                          'pathname',
                          ''
                        ).includes(
                          path
                        ),
                        [
                          ActiveIcon,
                          Icon,
                        ]
                      )
                    }}
                  />
                  <div className="whitespace-no-wrap text-center BottomBarItem_label font-bold mt-1 uppercase ">
                    {t(title)}
                  </div>
                </NavLink>
              )
            })}
        </nav>
      </header>
    )
  }
)
