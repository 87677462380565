import {getType} from 'apis/model/base'
import _ from 'lodash'
import {ROOT_URL} from "../envs/_current/config";

export default function getCoverPhoto(
  item = {}
) {
  const photo = _.get(item, 'photo') || _.get(item, 'cover_photo');
  if (!!photo) return photo;

  const type = getType(item)
  if (!type) {
    return null;
  }

  switch (type) {
    case 'event':
      return ROOT_URL + "/images/default/event-bg.jpg";
    case 'user':
      return ROOT_URL + "/images/default/purple-bg.jpg";
    case 'organization':
      return ROOT_URL + "/images/default/dark-blue-bg.jpg";
    case 'activity':
      return ROOT_URL + "/images/default/activity-bg.jpg";
    case 'donation_event':
      return ROOT_URL + "/images/default/blue-bg.jpg";
    case 'giving_event':
      return ROOT_URL + "/images/default/pink-bg.jpg";
    case 'recruitment_event':
      return ROOT_URL + "/images/default/green-bg.jpg";
    default:
      return ROOT_URL + "/images/default/blue-bg.jpg";
  }
}
