
import {
  Avatar,
  Button,
  List,
  Modal,
  Skeleton,
} from 'antd'
import { search_searchObjects_Api } from 'apis'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useToggle } from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {
  fromObject,
  getResponseItems,
  Null,
  renderOwnChild,
} from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { WishareEntities } from 'views/Wishare/enums'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'
import {IoCheckmarkOutline, IoPerson} from "react-icons/io5";
import VerifiedSvg from "../../../../components/Svgs/VerifiedSvg";
import EmptyHolder from "../../../../components/EmptyHolder";

const CandidateItemWrapper = ({
  item,
  children,
  ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderCandidateItem = (
  {
    isSelected,
    onClick,
    renderActions,
    ...item
  },
  index
) => {
  const isEmpty = _.isEmpty(item)
  const { owner } = item || {}

    const verified = _.get(owner, 'certified')

  return (
    <div
      key={index}
      onClick={onClick}
      className={classNames(
        'w-full px-3 py-2 rounded-lg border border-color-50 background',
        !!onClick && 'cursor-pointer'
      )}>
        <div className="flex items-center gap-1">
            {isEmpty ? (
                <Translate>
                    {(t) => (
                        <EmptyHolder
                            icon={null}
                            subTitle={t(
                                'Click to select...'
                            )}
                            style={{
                                padding: 0,
                                border: 'none',
                            }}
                        />
                    )}
                </Translate>
            ) : (
                <Translate>
                    {(t) => (
                        <div className="flex items-center gap-2 flex-1">
                            <Avatar
                                size={30}
                                className="flex flex-center bg-orange-200"
                                icon={<IoPerson className="text-orange-500"/>}
                                src={getAvatar(owner)}
                            />
                            <div className="flex flex-col flex-1">
                                {getTitle(owner) ?
                                    <span className="text-color-000 font-semibold leading-tight flex items-center">
                                        {getTitle(owner)}
                                        {verified === 1 && (
                                            <VerifiedSvg width={'1rem'} height={'1rem'} className="ml-1 mb-1 zoom-animation"/>
                                        )}
                                    </span> :
                                    <span className="font-medium text-sm text-orange-600">
                                      {t('Click here to find an account')}
                                    </span>
                                }
                                {getType(owner) ?
                                    <span className="text-xs text-secondary italic">
                                        {t(getType(owner))}
                                    </span> :
                                    <span className="font-light text-xs italic text-orange-500">
                                        {t('You can find the user\'s account and add')}
                                    </span>
                                }
                            </div>
                        </div>
                    )}
                </Translate>
            )}
            {renderActions ? renderActions(item) : onClick
                ? [
                    <Translate>
                        {(t) => (
                            <Button
                                size={"small"}
                                ghost={true}
                                className="rounded-md no-shadow no-text-shadow text-xs font-medium"
                                type="primary">
                                {t('search')}
                            </Button>
                        )}
                    </Translate>,
                ]
                : [
                    <Translate>
                        {(t) =>
                            isSelected ? (
                                <IoCheckmarkOutline size={25}  className="text-green-600" />
                            ) : (
                                <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                                    {t('choose')}
                                </span>
                            )
                        }
                    </Translate>,
                ]
            }
        </div>
    </div>
  )
}

const CandidateSelectorModal = ({
  name,
  onSelect = Null,
  Wrapper = renderOwnChild,
}) => {
  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const apiInfo =
    search_searchObjects_Api

  return (
    <Wrapper>
      {searchInput}
      <Async
        key={keyword}
        values={{
          type: WishareEntities.USER,
          keyword,
        }}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          )
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={renderClicker(
                {
                  onClick: onSelect,
                  renderItem:
                    renderCandidateItem,
                  Wrapper:
                    CandidateItemWrapper,
                }
              )}
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

const RecruitmentCandidateSelector = ({
  name,
  value,
  defaultValue,
  onChange = Null,
  readOnly = false,
  setValues = Null,
  Wrapper = renderOwnChild,
  required_information_fields,
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const handleSelect = ({
    owner,
    ...object
  }) => {
    const names = Array.from(
      required_information_fields || []
    ).map((name) => ({ name }))
    setValues({
      ...fromObject(object, names),
      information_values: fromObject(
        object,
        names
      ),
      [name]: owner,
    })
    toggle(false)
  }

  return (
    <Wrapper>
      <div className="flex w-full items-center gap-2">
        <div className="flex w-full flex-col gap-2">
          {renderCandidateItem(
            {
              owner: value
                ? value
                : defaultValue,
              isSelected: true,
              onClick: readOnly
                ? undefined
                : () => toggle(true),
            },
            '__current_candidate__'
          )}
        </div>
      </div>
      <Modal
        className="custom-modal"
        onCancel={toggle}
        visible={isToggle}
        destroyOnClose={true}
        footer={
          <Button
            className="rounded-lg border-none"
            onClick={() =>
              toggle(false)
            }>
            {t('close')}
          </Button>
        }
        title={
            <div className="font-bold text-center text-color-000">
                {t('find account')}
            </div>
        }>
        <CandidateSelectorModal
          name={name}
          onSelect={handleSelect}
        />
      </Modal>
    </Wrapper>
  )
}

export default RecruitmentCandidateSelector
