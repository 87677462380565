import DonorMedalList from './components/DonorMedalList'
import DonorMyDonationList from './components/DonorMyDonationList'
import DonorOverview from './holders/DonorOverview'

export const donorRouteEntities = [
  {
    path: 'overview',
    component: DonorOverview,
    breadcrumbName: (t) =>
      t('overview'),
  },
  {
    path: 'my-donations',
    component: DonorMyDonationList,
    breadcrumbName: (t) =>
      t('list of contributors'),
  },
  {
    path: 'list-medal',
    component: DonorMedalList,
    breadcrumbName: (t) =>
      t('list of medal'),
  },
]
