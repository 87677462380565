import { Button } from 'antd'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { UseFollow } from 'views/Server/ServerContainer'
import {
  getId,
  getType,
} from '../../apis/model/base'
import classnames from "classnames";

export const FollowButton = ({
  item,
  size,
  children,
    className,
}) => {
  const t = useTranslate()
  if (_.get(item, 'status', 1) !== 1)
    return null

  return (
    <UseFollow
      follow_status={item.follow_status}
      owner_id={getId(item)}
      owner_type={getType(item)}>
      {(handleFollow) => {
        if (children) {
          return logParams(children)(
            item.follow_status,
            handleFollow,
            item.follow_status
              ? t('following')
              : t('follow')
          )
        }
        return item.follow_status ? (
          <Button
            ghost
            size={size}
            onClick={() =>
              handleFollow()
            }
            className={classnames('flex flex-center font-medium no-shadow no-text-shadow rounded-md', className)}
            type={'primary'}>
            <span className={size === 'small' ? 'text-xs' : 'text-sm'}>
              {t('following')}
            </span>
          </Button>
        ) : (
          <Button
            size={size}
            className={classnames('flex flex-center font-medium no-shadow no-text-shadow no-border rounded-md', className)}
            onClick={() =>
              handleFollow()
            }
            type={'primary'}>
            <span className={size === 'small' ? 'text-xs' : 'text-sm'}>
              {t('follow')}
            </span>
          </Button>
        )
      }}
    </UseFollow>
  )
}
