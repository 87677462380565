import {Input} from 'antd'
import {donation_update_api} from 'apis'
import {baseFieldTypes} from 'apis/base/baseFieldTypes'
import {getId} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useContext, useMemo,} from 'react'
import {compose, mapProps,} from 'recompose'
import {withAdvancedOptions} from 'views/Wishare/custom/AdvancedFormGroup'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import PhotoField from 'views/Wishare/custom/PhotoField'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import VideoField from 'views/Wishare/custom/VideoField'
import {createDateRangerPicker} from 'views/Wishare/factory/createDateRangerPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import {createControlledFormField} from 'views/Wishare/factory/createFormField'
import {requiredString, yupShape,} from 'views/Wishare/factory/createValidationSchema'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import {wishareFieldTypes} from 'views/Wishare/wishareFieldTypes'
import DonationContext from '../DonationContext'

const formFields = [
  { name: 'title' },
  {name: 'idcode'},
  {
    name: 'about',
  },
  { name: 'photo' },
  { name: 'time_open' },
  { name: 'time_close' },
  { name: 'description' },
  { name: 'privacy', defaultValue: 1 },
  { name: 'active', defaultValue: 1 },
  { name: 'status', defaultValue: 1 },

  {
    name: 'registrable',
    defaultValue: 1,
  },
  {
    name: 'clip_url',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    inline: true,
    title: 'status',
    className:
      'flex flex-col text-right',
    children: [
      {
        name: 'status',
        align: 'right',
        component: withAdvancedOptions(
          baseFieldTypes.statusAction
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'idcode',
    children: [
      {
        name: 'idcode',
        component:
          createControlledFormField({
            placeholder: 'idcode',
            readOnly: true,
            Component: Input,
          }),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            maxLength: 160,
            showCount: true,
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    title: 'open/close',
    children: [
      {
        name: 'time_open',
        component:
          createDateRangerPicker([
            'time_open',
            'time_close',
          ]),
      },
    ],
  },
  {
    inline: true,
    className:
      'flex flex-col md:flex-row justify-between ',
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
      },
      {
        label: 'active',
        name: 'active',
        component: withAdvancedOptions(
            baseFieldTypes.active
        )(RadioOptions),
      },
    ],
  },
  {
    inline: true,
    className:
      'flex flex-col md:flex-row justify-between ',
    children: [
      {
        label: 'registrable',
        name: 'registrable',
        component: withAdvancedOptions(
          wishareFieldTypes.registrable
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'about',
    children: [
      {
        name: 'about',
        component:
          createControlledFormField({
            placeholder: 'about',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
  {
    title: 'photos',
    children: [
      {
        name: 'photo',
        component: compose(
          mapProps(
            ({
              name,
              value,
              form,
              onChange,
            }) => {
              const values = _.get(
                form,
                'values'
              )
              return {
                name,
                value,
                values,
                onChange,
              }
            }
          )
        )(PhotoField),
      },
    ],
  },
  {
    title: 'clip_url',
    children: [
      {
        name: 'clip_url',
        component:
          createControlledFormField({
            Component: VideoField,
          }),
      },
    ],
  },
]

const DonationSettingOverview = () => {
  const t = useTranslate()
  const { donation } = useContext(
    DonationContext
  )

  const validationSchema =
    useMemo(() => {
      return yupShape({
        title: requiredString(t),
      })
    }, [t])

  const donation_id = getId(donation)

  const onPreSubmit = ({
    photo_preview,
    ...values
  }) => {
    return values
  }

  if (_.isEmpty(donation)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: donation,
    validationSchema,
    query: bindQueryParam({
      donation_id,
    }),
    onPreSubmit,
    apiInfo: donation_update_api,
  })
}

export default DonationSettingOverview
