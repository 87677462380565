import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import {
  Null,
  renderTrue,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import EntityList from 'views/Wishare/EntityList'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import { givingApi } from '../../apis'
import GivingContext from '../GivingContext'
import { GivingItemEntity } from '../GivingEntity'
import GivingRecordItem from './GivingRecordItem'
import {withProps} from "recompose";
import BeneficiaryMyGivings from "../../Donor/components/BeneficiaryMyGivings";

const GivingItemList = ({
  sample,
  keyword,
  children,
  sort_by,
  filtered_by,
  Footer = Null,
  Wrapper = 'div',
  isFiltered = renderTrue,
  ...props
}) => {
  const { giving } = useContext(
    GivingContext
  )

  const { posts = [] } = useContext(
    ObserverContext
  )

  if (_.isEmpty(giving)) return null

  const id = getId(giving)

  const apiInfo =
    givingApi.giving_events_getGivingsByGivingEvent_api

  const query = bindQueryParam({ id })

  const values = {
    keyword,
    sort_by,
    filtered_by,
  }

  const lookupKey = [
    mapPathParam(apiInfo.path, { id }),
    JSON.stringify({ filtered_by }),
    JSON.stringify({ sort_by }),
    JSON.stringify({ keyword }),
  ].join('/')

  const refreshKey = JSON.stringify({
    query,
    values,
    posts,
  })

  return (
    <React.Fragment>
      <EntityList
        key={refreshKey}
        query={query}
        sample={sample}
        values={values}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        refreshKey={refreshKey}
        RenderEntity={GivingItemEntity}
        RenderItem={withProps({
          showEvent: true,
        })(GivingRecordItem)}
        renderWrap={(
          child,
          __,
          count
        ) => (
          <Wrapper className="space-y-3">
            {child}
            {count ? <Footer /> : null}
          </Wrapper>
        )}
        {...props}
      />
    </React.Fragment>
  )
}

export default GivingItemList
