import { Radio, Select } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
} from 'react'
import { Null } from 'views/Shared'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/Wishare/custom/AntdConfigWrapper'
import { reactSelectComponents } from 'views/Wishare/custom/ReactSelectComponents'

const FilterSelectField = ({
  name,
  value,
  onChange,
  options,
  isLoading,
  className,
  ...props
}) => (
  <AntdConfigWrapper
    renderEmpty={RenderEmptyMeta}>
    <Select
      value={
        _.find(options, { value })
          ? value
          : undefined
      }
      showSearch={true}
      allowClear={true}
      onChange={(value) => {
        onChange(name, value)
      }}
      loading={!!isLoading}
      optionFilterProp="label"
      className={classNames(
        'CenterSelector w-full',
        className
      )}
      options={
        !!isLoading ? [] : options
      }
      suffixIcon={
        !!isLoading ? undefined : (
          <reactSelectComponents.DropdownIndicator />
        )
      }
      {...props}
    />
  </AntdConfigWrapper>
)

const HostsOwnerAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    status: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <Radio.Group
        name="status"
        buttonStyle="solid"
        className="flex items-center flex-1 justify-end lg:w-auto"
        onChange={(event) => {
          const { name, value } =
            event.target
          handleChange(name, value)
        }}
        defaultValue={
          filter.status || undefined
        }>
        <Radio.Button value={undefined}>
          {t('all')}
        </Radio.Button>
        <Radio.Button value={'1'}>
          {t('active')}
        </Radio.Button>
        <Radio.Button value={'9'}>
          {t('closed')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default HostsOwnerAdvancedFilter
