import EmptyHolder from 'components/EmptyHolder'
import { copyText } from 'helpers'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { IoCopyOutline } from 'react-icons/io5'
import {
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import DonationContext from '../DonationContext'

const formFields = [
  {
    name: 'tx_beneficiary_account_no',
    label: 'account number',
  },
  {
    name: 'tx_beneficiary_account_name',
    label: 'account name',
  },
  {
    name: 'tx_beneficiary_bank_name',
    label: 'bank name',
  },
  {
    name: 'tx_memo',
    label: 'transaction memo',
  },
]

const DonationBenificiaryItem = ({
  label,
  value,
  translate = renderSelf,
}) => {
  return (
    <FlexCol className="text-sm">
      <span className="text-xs text-color-400 font-light">
        {translate(label)}
      </span>
      <div className="flex items-center justify-between">
        <span className="text-color-000 font-semibold">
          {value}
        </span>
        <IoCopyOutline
          onClick={() => {
            copyText(
              value,
              translate('copied')
            )
          }}
          className="text-color-300 cursor-pointer"
        />
      </div>
    </FlexCol>
  )
}

export const DonationBenificiary = ({
  Wrapper = renderOwnChild,
}) => {
  const { donation } = useContext(
    DonationContext
  )

  const values = useMemo(
    () =>
      formFields.reduce(
        (prev, { name }) => ({
          ...prev,
          [name]: _.get(donation, name),
        }),
        {}
      ),
    [donation]
  )

  if (
    _.isEmpty(donation) ||
    Object.values(values).every(
      _.isEmpty
    )
  ) {
    return (
      <EmptyHolder title="beneficiary information" />
    )
  }

  return (
    <Wrapper>
      <Translate>
        {(translate) => (
          <div className="flex flex-col space-y-2">
            {formFields.map(
              (
                { name, label },
                index
              ) => (
                <DonationBenificiaryItem
                  key={index}
                  label={label}
                  value={values[name]}
                  translate={translate}
                />
              )
            )}
          </div>
        )}
      </Translate>
    </Wrapper>
  )
}

export default DonationBenificiary
