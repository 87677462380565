import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Row,
} from 'antd'
import { channel_getChannelsByAdmin_Api } from 'apis'
import { articleModel } from 'apis/model'
import { getId } from 'apis/model/base'
import { LoginContext } from 'components/LoginContext'
import Toggle from 'components/Toggle'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  IoCreateOutline,
  IoPerson,
  IoPodiumOutline,
} from 'react-icons/io5'
import {
  branch,
  compose,
  fromRenderProps,
  nest,
  renderNothing,
} from 'recompose'
import ChannelPicker from 'views/Channel/components/ChannelPicker'
import ServerContext from 'views/Server/ServerContext'
import { Null } from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import CreatePostForm from './CreatePostForm'
import { InputBoardProvider } from './InputBoardContext'
import getTitle from "../../../helpers/getTitle";

const InputHeader = ({
  title,
  onClick,
}) => (
  <LoginContext.Consumer>
    {(login) => (
      <Row className="m-row-0 gap-3">
        <Avatar
          src={getAvatar(login)}
          icon={<IoPerson className="text-color-300" />}
          className="flex flex-center background-100" />
        <div
          onClick={onClick}
          className="flex-1 background-100 rounded-full px-2 py-1 cursor-pointer">
          <span className="font-light text-sm text-color-200 italic">
            {title}
          </span>
        </div>
      </Row>
    )}
  </LoginContext.Consumer>
)

const InputActions = () => {
  const t = useTranslate()

  const history = useHistory()

  const { setCurrent = Null } =
    useContext(ServerContext)

  const actions = useMemo(
    () => [
      {
        label: 'poll question',
        path: 'createPoll',
        icon: (
          <IoPodiumOutline className="text-secondary mr-1" />
        ),
      },
      {
        label: 'article',
        icon: (
          <IoCreateOutline className="text-blue-500 mr-1" />
        ),
      },
    ],
    []
  )
  return (
    <Row className="m-row-0 space-x-3">
      <ChannelPicker
        apiInfo={
          channel_getChannelsByAdmin_Api
        }
        linkTo={(item) => {
          const url =
            articleModel.getLinkToCreate(
              item
            )
          history.push(url)
        }}
        onFinished={(channel) => {
          history.push(
            articleModel.getLinkToCreate(
              channel
            )
          )
        }}>
        {(toggle) => (
          <>
            {actions.map(
              (
                {
                  label,
                  path,
                  ...rest
                },
                index
              ) => (
                <Button
                  {...rest}
                  key={index}
                  onClick={() => {
                    if (path) {
                      setCurrent(path)
                    } else {
                      toggle()
                    }
                  }}
                  className="flex flex-center rounded-lg font-medium text-xs md:text-sm border-color-50 leading-tight no-shadow no-text-shadow">
                  {t(label)}
                </Button>
              )
            )}
          </>
        )}
      </ChannelPicker>
    </Row>
  )
}

const InputBoardForm = ({
  container,
}) => (
  <InputBoardProvider
    container={container}>
    <Toggle>
      {(isToggle, toggle) => (
        <React.Fragment>
          <Translate>
            {(t) => (
              <Col className="p-3 border rounded-lg">
                <InputHeader
                  onClick={() =>
                    toggle(true)
                  }
                  title={t(
                    'create new post'
                  )}
                />
                <Divider className="my-3" />
                <Row className="m-row-0 space-x-3">
                  <InputActions />
                </Row>
              </Col>
            )}
          </Translate>
          <LoginContext.Consumer>
            {(login) =>
              !!login && (
                <Modal
                    className="custom-modal"
                  width={620}
                  footer={null}
                  closable={false}
                  visible={isToggle}
                  destroyOnClose={true}
                  onCancel={() =>
                    toggle(false)
                  }>
                  <CreatePostForm
                    onCancel={() =>
                      toggle(false)
                    }
                    initialValues={{
                      default_owner_id:
                        getId(login),
                    }}
                  />
                </Modal>
              )
            }
          </LoginContext.Consumer>
        </React.Fragment>
      )}
    </Toggle>
  </InputBoardProvider>
)

export default nest(
  AntdConfigWrapper,
  compose(
    fromRenderProps(
      PermissionContext.Consumer,
      (props) => props
    ),
    branch(
      (props) =>
        !Boolean(
          _.get(
            props,
            permissionCodes.canPost
          )
        ),
      renderNothing
    )
  )(InputBoardForm)
)
