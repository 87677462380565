import { Button } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { FingerLoveFillSvg } from 'components/Svgs/FingerLoveFillSvg'
import { FingerLoveOutlineSvg } from 'components/Svgs/FingerLoveOutlineSvg'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { IoLogoRss } from 'react-icons/io'
import {
  IoHeart,
  IoHeartOutline,
} from 'react-icons/io5'
import { useVote } from 'views/Server/ServerContainer'
import { useFollow } from 'views/Server/useFollow'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'

const ToggleActionButton =
  React.forwardRef(
    (
      {
        isToggle,
        active = {
          label: 'active',
          icon: <IoHeart />,
          params: {},
        },
        inactive = {
          label: 'inactive',
          icon: <IoHeartOutline />,
          params: {},
        },
        onClick = Null,
        useDebounce = true,
        ...props
      },
      ref
    ) => {
      const t = useTranslate()

      const [isLoading, setLoading] =
        useState(false)

      const debounce = _.debounce(
        () => {
          onClick()
          setLoading(false)
        },
        200
      )

      const { icon, label, params } =
        isToggle ? active : inactive

      return (
        <Button
          ref={ref}
          block={true}
          type="primary"
          icon={icon}
          disabled={isLoading}
          className="rounded-lg no-shadow no-text-shadow space-x-1 flex items-center justify-center capitalize text-sm md:text-base"
          {...params}
          {...props}
          onClick={
            useDebounce
              ? () => {
                  setLoading(true)
                  debounce()
                }
              : onClick
          }>
          {label ? t(label) : null}
        </Button>
      )
    }
  )

export const UseVoteHandler = ({
  children,
  ...props
}) => {
  const action =
    useVote({ ...props }) || Null
  return _.isFunction(children)
    ? children(action)
    : children
}

export const UseFollowHandler = ({
  children,
  ...props
}) => {
  const action =
    useFollow({ ...props }) || Null
  return _.isFunction(children)
    ? children(action)
    : children
}

export const WishareVoteButton = ({
  item,
  owner_id: id,
  owner_type: type,
  vote_status: status,
  Component = ({
    isToggle,
    active,
    inactive,
    onClick,
  }) => (
    <ToggleActionButton
      {...{
        isToggle,
        active,
        inactive,
        onClick,
      }}
    />
  ),
  Wrapper = renderOwnChild,
}) => {
  const owner_id = getId(item) || id

  const owner_type =
    getType(item) || type

  const vote_status =
    _.get(item, 'vote_status') || status

  if (
    _.isEmpty(owner_id) ||
    _.isEmpty(owner_type)
  ) {
    return null
  }
  return (
    <UseVoteHandler
      {...{
        owner_id,
        owner_type,
        vote_status,
      }}>
      {(handleVote = Null) => (
        <Wrapper>
          <Component
            isToggle={vote_status}
            active={{
              label: 'voted',
              icon: (
                <FingerLoveFillSvg />
              ),
              params: { ghost: true },
            }}
            inactive={{
              label: 'vote',
              icon: (
                <FingerLoveOutlineSvg fill="#fff" />
              ),
            }}
            onClick={() => handleVote()}
          />
        </Wrapper>
      )}
    </UseVoteHandler>
  )
}

export const WishareFollowButton = ({
  item,
  owner_id: id,
  owner_type: type,
  follow_status: status,
  Component = ({
    isToggle,
    active,
    inactive,
    onClick,
  }) => (
    <ToggleActionButton
      {...{
        isToggle,
        active,
        inactive,
        onClick,
      }}
    />
  ),
  Wrapper = renderOwnChild,
}) => {
  const owner_id = getId(item) || id

  const owner_type =
    getType(item) || type

  const follow_status =
    _.get(item, 'follow_status') ||
    status

  if (
    _.isEmpty(owner_id) ||
    _.isEmpty(owner_type)
  ) {
    return null
  }
  return (
    <Wrapper>
      <UseFollowHandler
        {...{
          owner_id,
          owner_type,
          follow_status,
        }}>
        {(handleFollow = Null) => (
          <Component
            isToggle={follow_status}
            active={{
              label: 'following',
              icon: <IoLogoRss />,
              params: { ghost: true },
            }}
            inactive={{
              label: 'follow',
              icon: <IoLogoRss />,
            }}
            onClick={() =>
              handleFollow()
            }
          />
        )}
      </UseFollowHandler>
    </Wrapper>
  )
}

export default ToggleActionButton
