import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const RecruitmentLazy =
  createLazyComponent(() =>
    import('./Recruitment')
  )

const Recruitment = (props) => (
  <RecruitmentLazy {...props} />
)

export default Recruitment
