import ActivityDonationList from './holders/ActivityDonationList'
import ActivityEventList from './holders/ActivityEventList'
import ActivityGivingList from './holders/ActivityGivingList'
import ActivityInfo from './holders/ActivityInfo'
import ActivityNews from './holders/ActivityNews'
import ActivityRecruitmentList from './holders/ActivityRecruitmentList'

export const activityRouteEntities = [
  {
    path: 'overview',
    component: ActivityInfo,
    breadcrumbName: (t) =>
      t('overview'),
  },
  {
    path: 'news',
    component: ActivityNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'donations',
    component: ActivityDonationList,
    breadcrumbName: (t) =>
      t('crowdfunding'),
  },
  {
    path: 'jobs',
    component: ActivityRecruitmentList,
    breadcrumbName: (t) => t('jobs'),
  },
  {
    path: 'events',
    component: ActivityEventList,
    breadcrumbName: (t) => t('events'),
  },
  {
    path: 'givings',
    component: ActivityGivingList,
    breadcrumbName: (t) => t('givings'),
  },
]
