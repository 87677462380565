export const staticPaths = {
  account: {
    pathname: '/account',
  },
  accountOrganization: {
    pathname: '/account/organization',
  },
  accountOrganizationMember: {
    pathname:
      '/account/organization/member',
  },
  accountOrganizationAdmin: {
    pathname: '/account/organization',
  },
  accountChannel: {
    pathname: '/account/channel',
  },
  accountChannelFollow: {
    pathname:
      '/account/channel?type=follow',
  },
  accountChannelAdmin: {
    pathname: '/account/channel',
  },
  accountSettings: {
    pathname: '/account/settings',
  },
  createChannel: {
    pathname: '/createChannel',
    state: {
      isModal: true,
    },
  },
  createEvent: {
    pathname: '/createEvent',
    state: {
      isModal: true,
    },
  },
  // Begin Wishare
  selectPrimaryOrganization: {
    pathname:
      '/select-primary-organization',
    state: {
      isModal: true,
    },
  },
  createActivity: {
    pathname: '/create-activity',
    state: {
      isModal: true,
    },
  },
  createDonation: {
    pathname: '/create-donation',
    state: {
      isModal: true,
    },
  },
  createGiving: {
    pathname: '/create-giving',
    state: {
      isModal: true,
    },
  },
  createRecruitment: {
    pathname: '/create-recruitment',
    state: {
      isModal: true,
    },
  },
  createOrganization: {
    pathname: '/create-organization',
    state: {
      isModal: true,
    },
  },
  exportDonationData: {
    pathname: '/export-donation-data',
    state: {
      isModal: true,
    },
  },
  importDonationData: {
    pathname: '/import-donation-data',
    state: {
      isModal: true,
    },
  },
  exportGivingData: {
    pathname: '/export-giving-data',
    state: {
      isModal: true,
    },
  },
  importGivingData: {
    pathname: '/import-giving-data',
    state: {
      isModal: true,
    },
  },
  messages: {
    pathname: '/messages',
  },
  myspace: {
    pathname: '/myspace',
  },
  workspace: {
    pathname: '/workspace',
  },
  // End Wishare
  register: {
    pathname: '/register',
    state: {
      isModal: true,
    },
  },
  login: {
    pathname: '/login',
    state: {
      isModal: true,
    },
  },
  search: {
    pathname: '/search',
  },
  select_category: {
    pathname: '/select-category',
  },
  category: {
    pathname: '/category',
  },
  logout: {
    pathname: '/logout',
    state: {
      isModal: true,
    },
  },
  home: {
    pathname: '/dashboard',
  },
  start: {
    pathname: '/start',
  },
  discovery: {
    pathname: '/discovery',
  },
  bookmark: {
    pathname: '/bookmark',
  },
  myBookmarks: {
    pathname: '/my-bookmarks',
  },
  myChannels: {
    pathname: '/my-channels',
  },
  myArticles: {
    pathname: '/my-articles',
  },
  welcome: {
    pathname: '/welcome',
  },
  news: {
    pathname: '/news',
  },
  data_board: {
    pathname: '/data-board',
  },
  helps: {
    pathname: '/helps',
  },
  about: {
    pathname: '/helps/about-us',
  },
  policy: {
    pathname: '/helps/privacy-policy',
  },
}
