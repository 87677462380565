import getTitle from 'helpers/getTitle'
import Translate from 'modules/local/Translate'
import React from 'react'
import {Null} from 'views/Shared'
import {AiOutlineRight} from "react-icons/ai";
import _ from "lodash"
import {BsFolder} from "react-icons/bs";

export const renderSubCategory =
  ({renderContextMenu = Null} = {}) =>
    (item, index) => {
      const {description} = item || {}
      return (
        <Translate key={index}>
          {(t) => (
            <div className="p-3 flex flex-col border rounded-lg relative">
              <div className="flex gap-2 items-center justify-start">
                <BsFolder/>
                {
                  !!_.get(item, 'parent') && (
                    <React.Fragment>
                      <div className="font-bold text-sm text-color-300">
                        {getTitle(_.get(item, 'parent'))}
                      </div>
                      <AiOutlineRight className="text-sm text-color-300"/>
                    </React.Fragment>
                  )
                }
                <div className="font-bold text-sm text-color-000">
                  {getTitle(item)}
                </div>
              </div>
              <div className="font-light text-xs text-color-300 italic">
                {description}
              </div>
              {renderContextMenu(item)}
            </div>
          )}
        </Translate>
      )
    }
