import {
  Avatar,
  Button,
  List,
  Modal,
  Skeleton,
} from 'antd'
import { search_searchObjects_Api } from 'apis'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import {IoCheckmarkOutline, IoPerson} from 'react-icons/io5'
import { useToggle } from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItems,
  Null,
  renderOwnChild,
} from 'views/Shared'
import { beneficiaryApi } from 'views/Wishare/apis'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { WishareEntities } from 'views/Wishare/enums'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'

const ReceiverItemWrapper = ({
  item,
  children,
  ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderReceiverItem = (
  item,
  index
) => {
  const {
    onClick,
    isSelected,
    renderActions,
  } = item || {}
  const isEmpty = !!!getType(item)
  const verified = _.get(
    item,
    'certified'
  )
  return (
    <div
      key={index}
      onClick={onClick}
      className={classNames(
        'w-full px-3 py-2 rounded-lg border border-color-50 background',
        !!onClick && 'cursor-pointer'
      )}>
        <div className="flex items-center gap-2">
            {isEmpty ? (
                <Skeleton
                    avatar={true}
                    round={true}
                    title={false}
                    paragraph={{
                        rows: 2,
                        width: '100%',
                    }}
                    className="px-2"
                />
            ) : (
                <Translate>
                    {(t) => (
                        <div className="flex items-center gap-2 flex-1">
                            <Avatar
                                size={30}
                                className="flex flex-center background-100"
                                icon={<IoPerson className="text-color-300"/>}
                                src={getAvatar(item)}
                            />
                            <div className="flex flex-col flex-1">
                                <span className="text-color-000 font-semibold">
                                    {getTitle(item)}
                                    {verified === 1 && (
                                        <VerifiedSvg
                                            width={'1rem'}
                                            height={'1rem'}
                                            className="ml-1 zoom-animation"
                                        />
                                    )}
                                </span>
                                <span className="text-xs text-secondary italic leading-tight">
                                    {[
                                      !!getType(item) ? t(getType(item)) : "",
                                      !!_.get(item, 'owner') ? t(getType(_.get(item, 'owner'))) : "",
                                    ].filter(e => !!e).join(" - ")}
                                </span>
                            </div>
                        </div>
                    )}
                </Translate>
            )}
            {renderActions ? renderActions(item) : onClick
                ? [
                        <Translate>
                            {(t) => (
                                <Button
                                    ghost={true}
                                    type={'primary'}
                                    size={'small'}
                                    className="rounded-md no-shadow no-text-shadow text-sm font-medium">
                                    {t('edit')}
                                </Button>
                            )}
                        </Translate>,
                    ]
                : [
                    <Translate>
                        {(t) => isSelected ? (
                            <IoCheckmarkOutline
                                size={25}
                                className="text-green-600"
                            />
                        ) : (
                            <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                                {t('select')}
                            </span>
                        )}
                    </Translate>,
                ]
            }
        </div>
    </div>
  )
}

const ReceiverSelectorModal = ({
  query,
  onSelect = Null,
  Wrapper = renderOwnChild,
  type = WishareEntities.USER,
}) => {
  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const apiInfo =
    type === WishareEntities.BENEFICIARY
      ? beneficiaryApi.beneficiary_searchBeneficiary_api
      : search_searchObjects_Api

  return (
    <Wrapper>
      {searchInput}
      <Async
        key={keyword}
        query={query}
        values={{
          type,
          keyword,
        }}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          ).map(
            ({ owner, ...rest }) => ({
              ...rest,
              ...owner,
            })
          )
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={renderClicker(
                {
                  onClick: onSelect,
                  renderItem:
                    renderReceiverItem,
                  Wrapper:
                    ReceiverItemWrapper,
                }
              )}
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

const GivingReceiverSelector = ({
  title,
  query,
  defaultValue,
  required = false,
  onChange = Null,
  readOnly = false,
  Wrapper = renderOwnChild,
  type = WishareEntities.USER,
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const [current, setCurrent] =
    useState(defaultValue)

  const handleSelect = (object) => {
    toggle(false)
    onChange(object)
    setCurrent(object)
  }

  useEffect(() => {
    if (
      required &&
      _.isEmpty(defaultValue)
    ) {
      toggle(true)
    }
  }, [])

  return (
    <Wrapper>
      <div className="flex w-full items-center my-2">
        {renderReceiverItem(
          {
            ...(current ||
              defaultValue),
            isSelected: true,
            onClick: readOnly
              ? undefined
              : () => toggle(true),
          },
          '__current_receiver__'
        )}
      </div>
      <Modal
        visible={isToggle}
        destroyOnClose={true}
        footer={
          <Button
            className="rounded-lg border-none no-shadow no-text-shadow"
            onClick={() =>
              toggle(false)
            }>
            {t('close')}
          </Button>
        }
        title={
          title ? t(title) : undefined
        }
        onCancel={() => toggle(false)}>
        <ReceiverSelectorModal
          type={type}
          query={query}
          onSelect={handleSelect}
        />
      </Modal>
    </Wrapper>
  )
}

export default GivingReceiverSelector
