import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined'
import EyeTwoTone from '@ant-design/icons/lib/icons/EyeTwoTone'
import {
  Button,
  Checkbox,
  Input,
  message,
} from 'antd'
import { auth_login_Api } from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import { createValue } from 'components/form/utils'
import CryptoJS from 'crypto-js'
import { API_ROOT_URL } from 'envs/_current/config'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { loginSuccess } from 'redux/actions'
import { Null } from 'views/Shared'
import * as Yup from 'yup'

const useCryptoJS = () => {
  const key = `__${API_ROOT_URL}_sercetkey__`

  let secSpec =
    CryptoJS.enc.Utf8.parse(key)
  let ivSpec =
    CryptoJS.enc.Utf8.parse(key)

  secSpec =
    CryptoJS.lib.WordArray.create(
      secSpec.words.slice(0, 16 / 4)
    )
  ivSpec =
    CryptoJS.lib.WordArray.create(
      ivSpec.words.slice(0, 16 / 4)
    )

  const encrypt = (text) => {
    if (_.isEmpty(text)) {
      return ''
    }

    const plaintext =
      CryptoJS.enc.Utf8.parse(text)

    return CryptoJS.AES.encrypt(
      plaintext,
      secSpec,
      { iv: ivSpec }
    ).toString()
  }

  const decrypt = (encrypted) =>
    _.isEmpty(encrypted)
      ? ''
      : CryptoJS.AES.decrypt(
          encrypted,
          secSpec,
          { iv: ivSpec }
        ).toString(CryptoJS.enc.Utf8)

  return {
    encrypt,
    decrypt,
  }
}

const LoginForm = ({
  onSuccess = Null,
}) => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const {
    isLoading,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(
        loginSuccess(
          _.get(args, '1.response.data')
        )
      )
      onSuccess()
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.error')
      ),
  })

  const { encrypt, decrypt } =
    useCryptoJS()

  const initialValues =
    String(
      window.localStorage.getItem(
        'remember'
      )
    ) === 'true'
      ? {
          remember: true,
          email: decrypt(
            window.localStorage.getItem(
              'email'
            )
          ),
          password: decrypt(
            window.localStorage.getItem(
              'password'
            )
          ),
        }
      : {
          email: '',
          password: '',
          remember: false,
        }

  const prePost = useCallback(
    (values, callback = Null) =>
      () => {
        const { remember = false } =
          values || {}
        window.localStorage.setItem(
          'remember',
          String(remember)
        )
        const store = (name, value) =>
          !!remember
            ? window.localStorage.setItem(
                name,
                encrypt(value)
              )
            : window.localStorage.removeItem(
                name
              )
        _.forEach(
          ['email', 'password'],
          (name) => {
            store(
              name,
              _.get(values, name)
            )
          }
        )
        callback()
      },
    [encrypt, initialValues.remember]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={({
        remember,
        ...values
      }) => {
        handleAsyncAction({
          ...values,
          ...(!!remember
            ? { remember }
            : {}),
        })
      }}
      validationSchema={Yup.object().shape(
        {
          email: Yup.string()
            .email(
              t('invalid email address')
            )
            .required(t('Required')),
          password: Yup.string()
            .min(
              8,
              t(
                'Password must have min 8 characters'
              )
            )
            .required(
              t('password required')
            ),
        }
      )}>
      {(props) => {
        const {
          isValid,
          values,
          isSubmitting,
          errors,
          setValues,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props
        return (
          <div>
            <Form
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter'
                ) {
                  event.preventDefault()
                  prePost(
                    values,
                    handleSubmit
                  )()
                }
              }}
              className="space-y-2"
              onSubmit={prePost(
                values,
                handleSubmit
              )}>
              <Input
                name="prevent_auto_fill_email"
                style={{
                  opacity: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                }}
              />
              <FieldDecorator
                isRequired={true}
                error={[errors.email]}
                errors={errors.email}>
                <Input
                  size="large"
                  type="email"
                  inputMode="email"
                  name="email"
                  value={values.email}
                  onChange={
                    handleChange
                  }
                  placeholder={t(
                    'email account'
                  )}
                />
              </FieldDecorator>
              <Input.Password
                name="prevent_auto_fill_password"
                style={{
                  opacity: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                }}
              />
              <FieldDecorator
                isRequired={true}
                errors={[
                  errors.password,
                ]}>
                <Input.Password
                  size="large"
                  name="password"
                  value={
                    values.password
                  }
                  onChange={
                    handleChange
                  }
                  placeholder={t(
                    'password'
                  )}
                  iconRender={(
                    visible
                  ) =>
                    visible ? (
                      <EyeTwoTone />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                />
              </FieldDecorator>
              <FieldDecorator>
                <Checkbox
                  name="remember"
                  className="capitalize italic text-sm"
                  defaultChecked={
                    values.remember
                  }
                  onChange={(event) => {
                    handleChange(
                      createValue(
                        'remember',
                        event.target
                          .checked
                      )
                    )
                  }}>
                  {t('remember me')}
                </Checkbox>
              </FieldDecorator>
              <div className="w-full text-center">
                <Button
                  loading={isLoading}
                  onClick={prePost(
                    values,
                    handleSubmit
                  )}
                  size="large"
                  type="primary"
                  className="rounded-lg no-shadow no-text-shadow">
                  {t('login')}
                </Button>
              </div>
              <div className="text-right color-primary">
                <Link
                  className="text-sm text-color-400 italic font-light no-underline"
                  to={{
                    pathname:
                      '/forgotpassword',
                    state: {
                      isModal: true,
                    },
                  }}>
                  {t('forgotpass')}
                </Link>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default LoginForm
