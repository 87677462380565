import Translate from "../../../../modules/local/Translate";
import React from "react";
import classNames from "classnames";
import {IoGiftOutline} from "react-icons/io5";

export const renderGivingDetailTotal = (givingItem, index) => {

  return (
    <Translate key={index}>
      {
        t => (
          <div key={index} className={classNames(
            "flex items-start space-x-2 rounded-lg px-3 py-1 background-100",
            givingItem.className
          )}>
            <div className="flex-1 items-start space-x-1 text-sm text-color-100">
              {
                [
                  <span className="font-medium">{t(givingItem.item_name)}</span>,
                  <span className="gap-1">(<span>{Number(givingItem.total_giving_count).toLocaleString()}</span>
                    <span className="ml-1">{<IoGiftOutline/>}</span>)</span>,
                ].map((e, i) => <React.Fragment key={i}>{e}</React.Fragment>)
              }
            </div>

            <div>
              {
                (
                    <div className="text-color-000 font-semibold">
                    {
                      [
                        Number(givingItem.total_giving_qtty).toLocaleString(),
                        t(givingItem.unit_name)
                      ].join(" ")
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </Translate>
  )
}