import { SOCKET_URL } from 'envs/_current/config'
import logParams from 'helpers/logParams'
import openSocket from 'socket.io-client'
import {
  AUTHENTICATED,
  CONNECT,
  DISCONNECT,
  JOINED_ROOM,
  SPC
} from './types'

export function createSocket(
  callback = logParams
) {
  const socket = openSocket(SOCKET_URL)
  const emit = ({ type, payload }) => {
    socket.emit(type, payload)
  };

  [
    CONNECT,
    DISCONNECT,
    AUTHENTICATED,
    JOINED_ROOM,
    SPC,
    'notifications'
  ].map(type =>
    socket.on(type, res => callback(
      type,
      res,
      emit,
      socket
    ))
  );

  return {
    socket,
    emit
  }
}
