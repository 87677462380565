import _ from 'lodash'
import {
  organizationPermissionCodes,
  permissionCodes,
} from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React from 'react'
import {
  IoAddCircleOutline,
  IoBriefcaseOutline,
  IoFolderOpenOutline,
  IoGridOutline,
  IoIdCardOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoSettingsOutline,
  IoShieldCheckmarkOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { withRouter } from 'react-router-dom'
import {
  compose,
  fromRenderProps,
  nest,
  withProps,
} from 'recompose'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import Contacts from 'views/Organization/Settings/Contacts/Contacts.lazy'
import InviteManager from 'views/Organization/Settings/InviteManager'
import Locations from 'views/Organization/Settings/Locations/Locations.lazy'
import { Visible } from 'views/Shared'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { CustomKeys } from 'views/Wishare/CustomKeys'
import { WishareEntities } from 'views/Wishare/enums'
import { createCheckPermissions } from 'views/Wishare/factory/createCheckPermissions'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import {
  CoHosts,
  CoOwners,
} from 'views/Wishare/factory/createHostSetting'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import createDeleteEntity from '../../factory/createDeleteEntity'
import OrganizationContainerContext from '../OrganizationContainerContext'
import OrganizationSettingAbout from './OrganizationSettingAbout'
import OrganizationSettingCategories from './OrganizationSettingCategories'
import OrganizationSettingOverview from './OrganizationSettingOverview'
import OrganizationSettingPartners from './OrganizationSettingPartners'
import OrganizationSettingPermission from './OrganizationSettingPermission'
import OrganizationSettingPhoto from './OrganizationSettingPhoto'
import OrganizationSettingProfile from './OrganizationSettingProfile'
import OrganizationSettingRole from './OrganizationSettingRole'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline />,
  },
  {
    key: 'profiles',
    label: 'provider profiles',
    icon: <IoFolderOpenOutline />,
    children: [
      {
        label: 'about us',
        key: 'about-us',
      },
      {
        label: 'photos',
        key: 'photos',
      },
      {
        label: 'profile',
        key: 'profile',
      },
    ],
  },
  {
    key: 'category',
    label: 'categories',
    icon: <IoGridOutline />,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
    ],
  },
  {
    key: 'location-contact',
    label: 'location & contact',
    icon: <IoIdCardOutline />,
    children: [
      {
        label: 'location',
        key: 'location',
      },
      {
        label: 'contact',
        key: 'contact',
      },
    ],
  },
  {
    key: 'member-management',
    label: 'member management',
    icon: <IoPeopleOutline />,
    children: [
      {
        label: 'members',
        key: 'members',
      },
      {
        label: 'waiting members',
        key: 'waiting-members',
      },
      {
        label: 'invites',
        key: 'invites',
      },
    ],
  },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <IoPersonOutline />,
    children: [
      {
        label: 'list of co-hosting',
        key: CustomKeys.co_hosts.name,
      },
      {
        label: 'list of co-owners',
        key: 'host-request',
      },
    ],
  },
  {
    key: 'access rights',
    label: 'access rights',
    icon: <IoShieldCheckmarkOutline />,
    children: [
      {
        label: 'roles',
        key: 'roles',
      },
      {
        label: 'permissions',
        key: 'permissions',
      },
    ],
  },
  {
    key: 'partners',
    label: 'partners',
    icon: <IoBriefcaseOutline />,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline />,
  },
]
  .filter(Visible)
  .map((item) =>
    _.omit(item, [
      'visible',
      'invisible',
    ])
  )

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingOverview),
  },
  {
    path: 'about-us',
    label: 'about us',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingAbout),
  },
  {
    path: 'photos',
    label: 'photos',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingPhoto),
  },
  {
    path: 'categories',
    label: 'categories',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingCategories),
  },
  {
    path: 'profile',
    label: 'profile',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingProfile),
  },
  {
    path: 'location',
    label: 'location',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(Locations),
  },
  {
    path: 'contact',
    label: 'contact',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(Contacts),
  },
  {
    path: 'partners',
    label: 'partners',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingPartners),
  },
  {
    path: 'members',
    label: 'members',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(Members),
  },
  {
    path: 'waiting-members',
    label: 'waiting members',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(WaitingAcceptMembers),
  },
  {
    path: 'invites',
    label: 'invites',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(InviteManager),
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(CoHosts),
  },
  {
    path: 'host-request',
    label: 'host request',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(CoOwners),
  },
  {
    path: 'roles',
    label: 'roles',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageRole,
    ])(OrganizationSettingRole),
  },
  {
    path: 'permissions',
    label: 'permissions',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePermission,
    ])(OrganizationSettingPermission),
  },
  {
    path: 'delete',
    label: 'delete',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDelete,
    ])(({ item }) =>
      createDeleteEntity(item)
    ),
  },
]
  .filter(Visible)
  .map(
    ({
      path,
      invisible = false,
      component: Component,
      ...rest
    }) => ({
      ...rest,
      path,
      key: path,
      component: (props) => (
        <OrganizationContainerContext.Consumer>
          {({ organization: item }) => (
            <Component
              {...{ item }}
              {...props}
            />
          )}
        </OrganizationContainerContext.Consumer>
      ),
    })
  )

export const organizationSettings = {
  actions: [
    {
      key: ContextActionTypes.CREATE_ACTIVITY,
      label: 'create activity',
      icon: <IoAddCircleOutline />,
    },
    {
      key: ContextActionTypes.CREATE_EVENT,
      label: 'create event',
      icon: <IoAddCircleOutline />,
    },
    {
      key: ContextActionTypes.CREATE_RECRUITMENT,
      label: 'create recruitment',
      icon: <IoAddCircleOutline />,
    },
    {
      key: ContextActionTypes.CREATE_CHANNEL,
      label: 'create channel',
      icon: <IoAddCircleOutline />,
    },
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline />,
    },
  ],
  menuItems,
  routeEntities,
}

const OrganizationSettings = nest(
  ({ children, ...props }) => (
    <OrganizationContainerContext.Consumer>
      {({ organization: item }) => (
        <PermissionProvider
          permissions={{
            edit: item.edit,
            ...item.available_actions,
          }}>
          {children}
        </PermissionProvider>
      )}
    </OrganizationContainerContext.Consumer>
  ),
  ({ id, ...props }) =>
    createSettingModule(
      [WishareEntities.ORGANIZATION],
      {
        item: id,
        ...props,
      }
    )
)

export default compose(
  notFoundOnError(
    OrganizationContainerContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  fromRenderProps(
    SubDomainContext.Consumer,
    ({ id }) => ({
      id,
    })
  ),
  withProps(({ id, match }) => ({
    id: id || _.get(match, 'params.id'),
  }))
)(OrganizationSettings)
