import {
  host_getStaffSummaryOnOrg_Api,
  host_getStaffSummary_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import React, { useMemo } from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  renderNothing,
} from 'recompose'
import { Null } from 'views/Shared'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import WorkspaceInsightsMetaSummary from '../../Insights/WorkspaceInsightsMetaSummary'
import WorkspaceContext from '../../WorkspaceContext'
import {
  renderReportTable,
  renderReportTableWrapper,
} from './renderReportTable'

const headers = [
  'organization',
  'recruitment',
  'collaborator',
  'intern',
  'staff',
  'volunteer',
]

const schemas = [
  {
    name: 'title',
    value_path: 'title',
    className: '',
  },
  {
    name: 'total.recruitment_event_count',
    value_path:
      'total.recruitment_event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_recruitment_event_count',
    className2: 'text-sm',
  },
  {
    name: 'total.collaborator_count',
    value_path:
      'total.collaborator_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_collaborator_count',
    className2: 'text-sm',
  },
  {
    name: 'total.intern_count',
    value_path: 'total.intern_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_intern_count',
    className2: 'text-sm',
  },
  {
    name: 'total.staff_count',
    value_path: 'total.staff_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_staff_count',
    className2: 'text-sm',
  },
  {
    name: 'total.volunteer_count',
    value_path: 'total.volunteer_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_volunteer_count',
    className2: 'text-sm',
  },
]

const StaffPeerOrgReport = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    some_param: 'some-param',
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        PaginationMeta: Null,
        apiInfo:
          host_getStaffSummary_Api,
        renderWrap:
          renderReportTableWrapper(
            headers
          ),
        renderEmpty: () => (
          <tr>
            <td
              className="border p-2"
              colSpan={headers.length}>
              <EmptyHolder />
            </td>
          </tr>
        ),
        renderItem: renderReportTable({
          fields: schemas,
        }),
      },
      withKey: ({ sortBy, keyword }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      sortHeader: null,
      renderFilter: Null,
      AdvancedFilter: Null,
      renderSearchBar: Null,
      SortByWidget: Null,
      isToggleDefault: true,
    })
  return (
    <Wrapper className="space-y-3">
      <Async
        {...{
          apiInfo:
            host_getStaffSummaryOnOrg_Api,
          query: bindQueryParam({
            organization_id: owner_id,
          }),
        }}>
        {({ response, isLoading }) => {
          const summary = _.get(
            response,
            'data.data'
          )
          return (
            <WorkspaceInsightsMetaSummary
              title="overview"
              summary={summary}
              isLoading={isLoading}
              className="grid grid-cols-3 md:grid-cols-3 gap-3 mb-8"
              field_params={[
                {
                  name: 'recruitment_event_count',
                  label:
                    'total recruitment event',
                  extra_name:
                    'organization_member_recruitment_event_count',
                  render: (
                    __,
                    { value }
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'collaborator_count',
                  label:
                    'total collaborator',
                  extra_name:
                    'organization_member_collaborator_count',
                  render: (
                    __,
                    { value }
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'intern_count',
                  label: 'total intern',
                  extra_name:
                    'organization_member_intern_count',
                  render: (
                    __,
                    { value }
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'staff_count',
                  label: 'total staff',
                  extra_name:
                    'organization_member_staff_count',
                  render: (
                    __,
                    { value }
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'volunteer_count',
                  label:
                    'total volunteer',
                  extra_name:
                    'organization_member_volunteer_count',
                  render: (
                    __,
                    { value }
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
              ]}
            />
          )
        }}
      </Async>

      <Translate>
        {(t) => (
          <div className="flex items-center text-lg font-semibold uppercase text-color-200">
            {t('staff summaries')}
          </div>
        )}
      </Translate>

      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  )
)(StaffPeerOrgReport)
