import { API_ROOT_URL } from 'envs/_current/config'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  baseItemSchema,
  recruitmentEventSchema,
} from './schema'
import { candidateSchema } from './schema/candidateSchema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:recruitments,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
}

export const recruitment_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/recruitment-events/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: recruitmentEventSchema,
  transform: createTransform(
    recruitmentEventSchema,
    'data'
  ),
}

export const recruitment_getWidgetById_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/get-widget',
    method: 'GET',
    fields,
    fieldTypes,
    schema: recruitmentEventSchema,
    transform: createTransform(
      recruitmentEventSchema,
      'data'
    ),
  }

export const recruitment_add_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/recruitment-events/create',
  method: 'POST',
  fields,
  fieldTypes,
  schema: recruitmentEventSchema,
  transform: createTransform(
    recruitmentEventSchema,
    'data'
  ),
}

export const recruitment_update_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/recruitment-events/:recruitment_id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  schema: recruitmentEventSchema,
  transform: createTransform(
    recruitmentEventSchema,
    'data'
  ),
}

export const recruitment_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/recruitment-events/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: recruitmentEventSchema,
  transform: createTransform(
    recruitmentEventSchema,
    'data'
  ),
}

// Route::get('recruitment-events/{prop_type}/{prop_id}/get-recruitment-events', 'RecruitmentEventController@getRecruitmentEventsByProps');
// filter: categories / country_id / province_id
export const recruitment_events_getItemsByOwner_gets_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:type/:id/get-recruitment-events',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'status',
      'categories',
      'country_id',
      'province_id',
      'keyword',
      'sort_by',
    ],
    schema: recruitmentEventSchema,
    transform: createListTransform(
      recruitmentEventSchema
    ),
  }

export const recruitment_events_getRecruitmentsByRecruitmentEvent_api =
  {
    backend: `Route::get('recruitment-events/{id}/recruitments/gets', 'RecruitmentEventController@getRecruitmentsByRecruitmentEvent');`,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/recruitments/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'filtered_by',
    ],
    schema: baseItemSchema,
    transform: createListTransform(
      baseItemSchema
    ),
  }

// Route::get('recruitment-events/{id}/candidates/gets', 'CandidateController@getCandidates');
export const recruitment_events_getCandidates_api =
  {
    backend: `Route::get('recruitment-events/{id}/candidates/gets', 'CandidateController@getCandidates');`,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'filtered_by',
      'keyword',
    ],
    schema: candidateSchema,
    transform: createListTransform(
      candidateSchema
    ),
  }

export const recruitment_events_addCandidate_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_deleteCandidate_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/:candidate_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_candidateManageApprove_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/:candidate_id/manage-approve',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_candidateManageReject_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/:candidate_id/manage-reject',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_candidateManageAdd_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/manage-add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_candidateManageDeleteAdd_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/:candidate_id/manage-delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_candidateManageEdit_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/recruitment-events/:id/candidates/:candidate_id/manage-edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: candidateSchema,
    transform: createTransform(
      candidateSchema,
      'data'
    ),
  }

export const recruitment_events_getCandidatesByType_api =
  {
    backend: `Route::get('recruitment-events/{type}/{id}/candidates/gets', 'CandidateController@getCandidatesByType');`,
    root: API_ROOT_URL,
    path: '/recruitment-events/:type/:id/candidates/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'filtered_by',
      'keyword',
      'status',
    ],
    schema: candidateSchema,
    transform: createListTransform(
      candidateSchema
    ),
  }
