import {Avatar} from 'antd'
import {getId,} from 'apis/model/base'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {Link} from 'react-router-dom'
import {formatDate, getFullAddress, Null} from 'views/Shared'
import {IoIosInformationCircle} from "react-icons/io";
import {IoLocationSharp, IoPeople, IoPerson} from "react-icons/io5";
import {BsGiftFill} from "react-icons/bs";
import {GenderTypes, getProp} from "../../../Wishare/wishareFieldTypes";
import {ImLibrary} from "react-icons/im";

export const renderBeneficiary =
  (params = {}) =>
    (item) => {
      if (!!_.get(item, 'deleted')) {
        return null
      }

      const {
        showAccount = true,
        showOrg = false,
      } = params || {}

      const {owner} = item || {}

      const linkAccount =
        <div className="flex items-center gap-2">
          <Link to={getLinkToDetail(_.get(item, 'owner'))}
                className="flex items-center text-xs gap-1 no-underline">
            {_.get(item, 'owner_type') === 'user' ? <IoPerson/> : <IoPeople/>}
            {_.get(item, 'owner.name') || _.get(item, 'owner.title')}
          </Link>
        </div>

      const isShared = _.get(item, 'role_cd', '') == '0'

      const {renderContextMenu = Null} =
      params || {}

      let gender = !!_.get(item, 'gender')
        ? getProp(
          Object.values(GenderTypes),
          'label'
        )(
          Number(_.get(item, 'gender'))
        )
        : null
      gender = !!gender ? gender : null

      const RowInformation = ({icon, label, value, hiddenIfEmpty = true}) => !value && hiddenIfEmpty ? null : (
        <div className="flex items-center gap-1 italic text-xs">
          {icon}
          {!icon && (
            <span className="text-color-400 font-light whitespace-no-wrap">
                    {_.isString(label) ? `${label}: ` : label}
                </span>
          )}
          <span className="text-color-100 font-medium flex-1">
                {value}
            </span>
        </div>
      )


      return (
        <Translate key={getId(item)}>
          {(t) => (
            <div className="relative  w-full border border-color-50 rounded-lg background">
              <div className="flex items-center p-3 gap-3">
                <LayoutContext.Consumer>
                  {({isSm}) =>
                    !!!isSm && (
                      <Avatar
                        size={40}
                        className="flex flex-center background-100"
                        icon={<IoPerson size={20} className="text-color-400"/>}
                        src={_.get(item, 'avatar')}
                      />
                    )
                  }
                </LayoutContext.Consumer>
                <div className="flex flex-col flex-1">
                    <Link
                        to={getLinkToDetail(
                            item
                        )}
                        className="cursor-pointer font-bold leading-tight max-lines-1">
                        {getTitle(item)}
                    </Link>
                    <div className="italic text-xs text-secondary font-light">
                        {_.get(item, 'owner') && showAccount && (
                            linkAccount
                        )}
                        {_.get(item, 'organization') && showOrg && (
                            <div className="flex items-center gap-2 my-1">
                                <Avatar
                                    size={20}
                                    icon={<ImLibrary className="text-secondary"/>}
                                    src={_.get(item, 'organization.avatar')}
                                    className="flex flex-center bg-secondary-50"/>
                                <span>{_.get(item, 'organization.title')}</span>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-between">
                        {!!_.get(item, 'total_givings', []) > 0 && (
                            <div className="italic text-xs flex flex-col">
                                {
                                    _.get(item, 'total_givings', []).map((e, i) => (
                                        <div key={i} className="flex gap-1 items-center">
                                            {
                                                [
                                                    <span>(</span>,
                                                    <span>{_.get(e, 'total_giving_count')}</span>,
                                                    <BsGiftFill/>,
                                                    <span>)</span>,
                                                    <span>{Number(_.get(e, 'total_giving_value')).toLocaleString()}</span>,
                                                    <span>{_.get(e, 'target_unit_name')}</span>,
                                                ].map((ee, ii) => (
                                                    <React.Fragment key={ii}>
                                                        {ee}
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                        {isShared && (
                            <span className="px-2 py-1 mr-6 italic text-2xs bg-primary-50 rounded-full text-primary">
                            {t('to shared')}
                        </span>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <RowInformation
                            icon={<IoIosInformationCircle className="text-color-500"/>}
                            value={
                                [
                                    !!_.get(item, 'date_of_birth')
                                    && `${t('year of birth')}: ${formatDate(_.get(item, 'date_of_birth'), 'YYYY')}`,
                                    `${!!gender ? t(gender) : ''}`
                                ].filter(e => !!e).join(" - ")
                            }
                        />
                        <RowInformation
                            icon={<IoLocationSharp className="text-color-500"/>}
                            value={getFullAddress(_.get(item, 'location'))}/>
                    </div>
                </div>

              </div>
              {renderContextMenu(item)}
            </div>
          )}
        </Translate>
      )
    }
