import { schema } from 'normalizr'
import { articleSchema } from './article'
import { commentSchema } from './commentSchema'
import {
  donationEventSchema,
  donationSchema,
} from './donationEvent'
import messageSchema from './messageSchema'
import { pollSchema } from './poll'
import { postSchema } from './post'

export const socketUnionSchema =
  new schema.Union(
    {
      comment: commentSchema,
      article: articleSchema,
      post: postSchema,
      poll: pollSchema,
      donation: donationSchema,
      message: messageSchema,
      donation_event:
        donationEventSchema,
    },
    '_type'
  )
