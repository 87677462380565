import React from 'react'
import Loadable from 'react-loadable'
import LoadingPage from '../../../LoadingPage'

const PhotoLazy = Loadable({
  loader: () => import('./Photo'),
  loading: LoadingPage,
})

const Photo = (props) => (
  <PhotoLazy {...props} />
)

export default Photo
