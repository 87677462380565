import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import BeneficiaryContext from './BeneficiaryContext'

const BeneficiaryLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose(
  notFoundOnError(BeneficiaryContext.Consumer)
)(BeneficiaryLayout)
