import {Button, Checkbox, Divider, Input, InputNumber, message, Segmented, Tooltip} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import FieldsFactory from 'components/form/FieldsFactory'
import {StepsForm} from 'components/form/StepsForm'
import {createValue} from 'components/form/utils'
import {Field, useFormikContext} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {formatterNumber} from "../../helpers/formatterNumber";
import {parseNumber} from "../../helpers/parseNumber";
import _ from "lodash"
import ServerContext from "../Server/ServerContext";
import useAsyncAction from "../../modules/asyncCache/useAsyncAction";
import {FullSideModalLayout} from "../../components/Modal/SideModalLayout";
import {poll_edit_Api} from "../../apis";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";


const Answers = ({
                   onChange,
                   defaultValue = []
                 }) => {
  const t = useTranslate()
  const [
    answers,
    setAnswers
  ] = useState(defaultValue)
  const [
    newAnswer,
    setNewAnswer
  ] = useState('')
  useEffect(() => {
    onChange(answers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])
  const handleAddNewAnswer = e => {
    e.preventDefault()
    setAnswers([...answers, {id: null, title: newAnswer}])
    setNewAnswer('')
  }
  const handleRemoveAnswer = value => {
    setAnswers([
      ...answers.filter(
        (e, index) => value !== e.title
      )
    ])
  };

  return (
    <>
      {answers.map((answer, i) => (
        <Input
          size="large"
          className="mb-3 animated fadeInUp faster"
          key={answer.title}
          onChange={e => {
            const newAnswers = [
              ...answers
            ]
            newAnswers[i] =
              e.target.value
            setAnswers(newAnswers)
          }}
          addonAfter={
            <Tooltip
              title={t('remove')}>
              <CloseOutlined
                onClick={() => {
                  handleRemoveAnswer(answer.title)
                }}
              />
            </Tooltip>
          }
          defaultValue={answer.title}
        />
      ))}
      <form
        onSubmit={handleAddNewAnswer}>
        <div className="flex flex-col space-y-1">
          <Input
            size="large"
            className="mt-3"
            key="create new answer"
            onChange={e => {
              setNewAnswer(e.target.value)
            }}
            placeholder={t('your answer')}
            value={newAnswer}
          />
          <div className="flex">
            <div className="flex-1" />
            <Button
                className="rounded-md text-sm"
              onClick={
                handleAddNewAnswer
              }
              size={"small"}>
              {t(
                'add new answer'
              )}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

const PollForm = ({
                    onBack,
                    onchange
                  }) => {
  const t = useTranslate();
  const {values} = useFormikContext();

  return (
    <>
      <FieldsFactory
        formSchema={[
          {
            children: {
              type: 'title',
              name: 'title',
              label: t(
                'what is your question?'
              ),
              component: Input,
              withProps: {
                type: 'text',
                size: 'large',
                placeholder: t(
                  'title or question'
                )
              }
            }
          }
        ]}
      />

      <Field
        name="is_yesno_question"
        render={({field}) => (
          <FieldDecorator
            className="w-full col-span-2"
            name="is_yesno_question"
            label={t('is yes/no question')}>
            <Segmented
              defaultValue={_.get(values, 'is_yesno_question', 0)}
              options={[
                {label: t('yes'), value: 1},
                {label: t('no'), value: 0}
              ]}
              onChange={value =>
                field.onChange(
                  createValue(
                    'is_yesno_question',
                    value
                  )
                )}
            />
          </FieldDecorator>
        )}
      />
      {
        !!!_.get(values, 'is_yesno_question', 0) && (
          <Field
            name="answers"
            render={({
                       field,
                       form: {
                         values = {},
                         touched = {},
                         errors = {}
                       } = {
                         touched: {},
                         errors: {}
                       }
                     }) => (
              <FieldDecorator
                label={t('answers')}>
                <div className="flex flex-col">
                  <Answers
                    onChange={value => {
                      field.onChange(
                        createValue(
                          'answers',
                          value
                        )
                      )
                    }}

                    defaultValue={_.get(values, 'answers', [])}
                  />
                </div>
              </FieldDecorator>
            )}
          />
        )
      }

        <Divider/>

      <Field
        name="is_single_selection"
        render={({field}) => (
          <FieldDecorator
            className="w-full col-span-2"
            name="is_single_selection"
            label={t('choose number of answers')}>
            <Segmented
              disabled={!!_.get(values, 'is_yesno_question', 0)}
              defaultValue={_.get(values, 'is_single_selection', 0)}
              value={!!_.get(values, 'is_yesno_question', 0) ? 1 : _.get(values, 'is_single_selection', 0)}
              options={[
                {label: t('one answer'), value: 1},
                {label: t('multiple answer'), value: 0}
              ]}
              onChange={value =>
                field.onChange(
                  createValue(
                    'is_single_selection',
                    value
                  )
                )
              } />
          </FieldDecorator>
        )}
      />
      {
        (!!!_.get(values, 'is_yesno_question', 0) && !!!_.get(values, 'is_single_selection')) && (
          <Field
            name="vote_on_maximum"
            render={({field, form}) => (
              <FieldDecorator
                className="w-full col-span-2"
                name="vote_on_maximum"
                label={t('number of choices')}>
                <InputNumber
                    min={2}
                    max={_.get(form, 'values.answers', 1)} name="vote_on_maximum"
                    inputMode={"numeric"}
                    className="w-full"
                    formatter={formatterNumber}
                    parser={parseNumber}
                    defaultValue={1}
                    value={!!_.get(values, 'is_yesno_question', 0) ? 1 : _.get(values, 'vote_on_maximum', 1)}
                    onChange={value => {
                        field.onChange(
                            createValue(
                                'vote_on_maximum',
                                value
                            )
                        )
                    }}
                />
              </FieldDecorator>
            )}
          />
        )
      }

      <Divider/>
      <Field
        name="customizable_answers"
        render={({field}) => (
          <Checkbox
            disabled={!!_.get(values, 'is_yesno_question', 0)}
            checked={Number(
              field.value
            )}
            onChange={e =>
              field.onChange(
                createValue(
                  'customizable_answers',
                  e.target.checked
                    ? '1'
                    : '0'
                )
              )
            }>
            {t('allow add new answers')}
          </Checkbox>
        )}
      />
    </>
  )
}

const CreatePollRoutes = [
  {
    path: '/questtionAndAnswers',
    name: 'edit a poll',
    component: PollForm,
    exact: true
  }
]

const tranformBeforeSubmit = (
  values = {}
) => {
  return ({
    ...values,
    vote_on_maximum: (!!values.is_single_selection || !!values.is_yesno_question) ? 1 : values.vote_on_maximum,
    answers: JSON.stringify(
      values.answers.map(obj => ({
        id: obj.id,
        title: obj.title
      }))
    )
  })
}


export const EditPoll = ({
                           initialValues = {
                             title: '',
                             vote_on_maximum: 1,
                             customizable_answers: 0,
                             is_single_selection: 1,
                             is_yesno_question: 0,
                             answers: []
                           },
                           onSubmit,
                           isLoading
                         }) => {
  const handleSubmit = values => {
    onSubmit(
      tranformBeforeSubmit(values)
    )
  }
  return (
    <StepsForm
      isLoading={isLoading}
      routes={CreatePollRoutes}
      initialValues={initialValues}
      onSubmit={
        handleSubmit
      }
    />
  )
}


export const EnhancedEditPoll = ({pollItem, onCancel}) => {
  const t = useTranslate()
  const {
    item,
    setCurrent
  } = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: poll_edit_Api,
    query: {
      ':id': pollItem.id
    },
    onSuccess: result => {
      setCurrent(null);
      onCancel();
    },
    onError: (err, errObject) => {
      errObject.error &&
      Object.values(
        errObject.error
      ).map(item =>
        message.error(item)
      )
    }
  });

  return (
    <FullSideModalLayout
      right
      title={
        <span className="font-bold flex-1">
                <div
                  style={{
                    fontSize: '0.8em'
                  }}
                  className="text-color-300 capitalize">
                  {item.title}
                </div>
              </span>
      }
      onCancel={() => {
        setCurrent(null);
        onCancel();
      }}>
      <div className="p-6">
        <EditPoll
          initialValues={
            {
              title: _.get(pollItem, 'title'),
              description: _.get(pollItem, 'description'),
              vote_on_maximum: _.get(pollItem, 'vote_on_maximum', 1),
              answers: _.get(pollItem, 'poll_answers', []),
              customizable_answers: _.get(pollItem, 'customizable_answers'),
              is_single_selection: (_.get(pollItem, 'vote_on_maximum', 1) === 1 || _.get(pollItem, 'is_yesno_question') === 1) ? 1 : 0,
              is_yesno_question: _.get(pollItem, 'is_yesno_question'),
            }
          }
          title={t('edit')}
          isLoading={isLoading}
          onSubmit={handleAsyncPost}
        />
      </div>
    </FullSideModalLayout>
  )
}

