import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const CategoryLazy =
  createLazyComponent(() =>
    import('./Category')
  )

const Category = (props) => (
  <CategoryLazy {...props} />
)

export default Category
