import {event_getEventsByProps_Api} from 'apis/event'
import {getId, getType,} from 'apis/model/base'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo,} from 'react'
import EntityList from 'views/Wishare/EntityList'
import EventItem from 'views/Wishare/Event/components/EventItem'
import EventEntity from 'views/Wishare/Event/EventEntity'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import classNames from "classnames";

const ItemStatistic = ({
  label,
  value,
}) => (
  <Translate>
    {(t) => (
      <div className="px-2 py-4 flex flex-col flex-center gap-1 border border-color-50 rounded-lg shadow-items cursor-pointer">
        <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
          {value.toLocaleString()}
        </span>
        <span className="leading-tight text-sm text-color-400 tracking-wide uppercase hover:text-primary">
          {t(label)}
        </span>
      </div>
    )}
  </Translate>
)

const OrganizationEventList = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const { organization: item } =
    useContext(
      OrganizationContainerContext
    )

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const apiInfo =
    event_getEventsByProps_Api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':type', type),
      ].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      {
        [
          {
            id: 'insights',
            title: 'insights',
            className: "border-2 border-purple-500",
            containerClassName: "grid grid-cols-2 gap-3",
            linkTo: getLinkToDetail(item) + '/events',
            children: [
              {
                label: "events", value: _.get(item, 'total_events', 0),

              },
              {
                label: "total incoming events", value: _.get(item, 'total_incoming_events', 0),
              }
            ]
          }
        ].filter(e => !!!e.hidden).map((e, i) => {
            return !!isSm ? (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <Link
                    to={e.linkTo}
                    className={classNames("px-6 py-4 flex flex-col gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline", e.className)}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <div key={ii} className="flex items-baseline gap-3 leading-tight">
          <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
            {ee.value.toLocaleString()}
          </span>
                          <span className="text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(ee.label)}
          </span>
                        </div>
                      ))
                    }
                  </Link>
                </HeaderItemInfo>
              )
              : (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <div className={e.containerClassName}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <ItemStatistic key={ii}
                                       className={e.className}
                                       label={ee.label}
                                       value={ee.value}
                                       to={e.linkTo}
                        />
                      ))
                    }
                  </div>
                </HeaderItemInfo>
              )
          }
        )
      }

      <div className="flex flex-col">
        <div className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
          {t('event list')}
        </div>
        <div className="space-y-3">
          <EntityList
            apiInfo={apiInfo}
            lookupKey={lookupKey}
            query={bindQueryParams([
              { id },
              { type },
            ])}
            RenderItem={EventItem}
            RenderEntity={EventEntity}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default OrganizationEventList
