import {
  EllipsisOutlined,
  EyeOutlined,
  MessageOutlined,
  PushpinFilled,
  PushpinOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Col, Image,
  Menu,
  Popconfirm,
  Row,
} from 'antd'
import classnames from 'classnames'
import Null from 'components/NullComponent'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {
  useContext,
} from 'react'
import {
  IoBookmark,
  IoBookmarksOutline,
  IoCreateOutline,
  IoSaveOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { Widget } from 'views/Feed/components/FeedWidget'
import getLinkToDetail from '../../helpers/getLinkToDetail'
import getTitle from '../../helpers/getTitle'
import { CategoriesXS } from '../Categories/CategoriesXS'
import DrawMenu from '../drawer/DrawMenu'
import ImageLoader from '../ImageLoader'
import {
  BaseEmbed,
  DetailEmbed,
} from './BaseEmbed'
import { BookmarkAction } from './BookmarkAction'
import Count from './Count'
import Description from './Description'
import './FeedCard.css'
import { GalleryEmbed } from './GalleryEmbed'
import { PollEmbed } from './PollEmbed'
import { Share } from './Share'
import Timestamp from './Timestamp'
import { VideoEmbed } from './VideoEmbed'
import { Vote } from './Vote'
import {LayoutContext} from "../layouts/Default/LayoutContext";
import {BsPinAngle, BsPinAngleFill} from "react-icons/bs";
import moment from "moment";
import {Photo} from "../../conts/imageBase64";
import {getType} from "../../apis/model/base";

export const FeedContext =
  React.createContext({
    avatar: '',
    cover: '',
    title: '',
    description: '',
    verified: false,
    id: '',
    to: {},
  })

export const FeedFooter = () => {
  const { actions, handleClick } =
    useContext(FeedContext)
  const translate = useTranslate()
  return (
    <div
      className="border-t border-color-50"
      style={{
        marginTop: '0.75rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        paddingTop: '0.5rem',
      }}>
      <div className="FeedCardFooter flex color-primary w-full cursor-pointer mr-10 font-semibold mb-3 ">
        <FeedActions />
        {actions.to && (
          <div onClick={() => handleClick('to')}
            className="flex-1 text-right truncate no-underline italic text-color-500 font-light text-xs">
            {translate('show this post')}
          </div>
        )}
      </div>
    </div>
  )
}

export const FeedContentMeta = () => {
  const {
    item,
    created,
    actions,
    creatorName,
    handleClick,
  } = useContext(FeedContext)
  const permissions = useContext(
    PermissionContext
  )

  const handleMenuClick = (e) => {
    handleClick(e.key)
  }
  const t = useTranslate()
  const availbleActions = [
    // [icon,label,key]
    item.bookmark_status
      ? [
          IoBookmarksOutline,
          'unbookmark',
          {
            hasPermission: (
              permissions
            ) => true,
          },
        ]
      : [
          IoBookmarksOutline,
          'bookmark',
        ],
    [IoBookmark, 'rebookmark'],
    item.pin_status
      ? [PushpinOutlined, 'unpin']
      : [PushpinOutlined, 'pin'],
    item.edit
      ? [IoCreateOutline, 'edit']
      : [],
    [IoTrashOutline, 'delete'],
    [IoSaveOutline, 'set_channel'],
  ].filter(
    ([
      Icon,
      key,
      {
        hasPermission = (
          permissions,
          key
        ) => permissions[key],
      } = {},
    ]) =>
      hasPermission(permissions, key)
  )

  let container = _.get(item, 'container')

  const owner_type = _.get(item, 'owner._type')

  let owner_name
  let label_action
  let container_name
  let isAdmin

  switch (owner_type) {
    case 'user':
      owner_name = _.get(item, 'owner.name');
      label_action = 'posted on channel';
      container_name = getTitle(container);
      break;
    case 'organization':
      owner_name = _.get(item, 'owner.title');
      label_action = 'posted on channel';
      container_name = getTitle(container);
      break;
    case 'activity':
      owner_name = _.get(item, 'owner.title');
      label_action = 'post at';
      container_name = null;
      isAdmin = true;
      break;
    case 'donation_event':
      owner_name = _.get(item, 'owner.title');
      label_action = 'post at';
      container_name = null;
      isAdmin = true;
      break;
    case 'giving_event':
      owner_name = _.get(item, 'owner.title');
      label_action = 'post at';
      container_name = null;
      isAdmin = true;
      break;
    case 'recruitment_event':
      owner_name = _.get(item, 'owner.title');
      label_action = 'post at';
      container_name = null;
      isAdmin = true;
      break;
    case 'event':
      owner_name = _.get(item, 'owner.title');
      label_action = 'post at';
      container_name = null;
      isAdmin = true;
      break;
    default:
  }

  return (
    <div className="w-full mt-2 px-2">
      <div className="flex items-center gap-2 mr-10">
        <Avatar
            size={30} src={_.get(item, 'owner.avatar')}
            className="flex flex-center background-100 font-bold text-sm text-color-300 uppercase">
          {_.first(getTitle(item.owner))}
        </Avatar>

        <div className="flex flex-col flex-1">
          <div className="flex items-baseline gap-1 text-xs">
            <Link
                to={getLinkToDetail(item.owner)}
                className="font-bold text-color-000 no-underline max-lines-1">
              {owner_name}
            </Link>
            {!isAdmin && (
                <span className="text-2xs text-color-300 italic whitespace-no-wrap">
                  {t(label_action)}
                </span>
            )}
          </div>
          <div className="flex items-baseline gap-1 text-xs leading-tight">
            {!!item.pin_status && (!!actions.pin && !!item.edit ? (
                <Popconfirm
                    placement="bottomLeft"
                    title={
                      <span className="font-semibold text-color-000">{t('unpin this?')}</span>
                    }
                    onConfirm={() =>
                        handleClick('unpin')
                    }
                    okText={t('yes')}
                    okButtonProps={{
                      type: 'primary',
                      className: 'rounded-md no-shadow no-text-shadow no-border font-medium text-xs',
                    }}
                    cancelButtonProps={{
                      className: 'rounded-md no-shadow no-text-shadow no-border font-medium text-xs',
                    }}
                    cancelText={t('no')}>
                  <BsPinAngleFill size={10} className="mr-1 text-primary cursor-pointer"/>
                </Popconfirm>
            ) : (
                <BsPinAngle size={10} className="text-color-300 cursor-pointer"/>
            ))}
            {isAdmin && (
                <span className="text-2xs text-color-300 italic whitespace-no-wrap">
                  {t(label_action)}
                </span>
            )}
            {container_name && (
                <>
                  <Link
                      className="font-bold text-color-000 no-underline max-lines-1"
                      to={getLinkToDetail(container)}>
                    {container_name}
                  </Link>
                  <span className="ml-1 font-normal text-2xs text-color-300 italic whitespace-no-wrap">
                    {t('at')}
                  </span>
                </>
            )}
            <div className="text-2xs text-color-500 italic whitespace-no-wrap">
              {moment(created).format('HH:mm - DD/MM/YYYY')}
            </div>
          </div>
        </div>
      </div>

      {!!availbleActions.length && (
        <DrawMenu
          component={
            <Count
              deta-effect="ripple"
              className="extraIcon mt-0 mr-1">
              <EllipsisOutlined />
            </Count>
          }>
          {(isToggle, toggle) => (
            <Menu
              style={{background: 'var(--background)'}}
              onClick={(e) => {
                handleMenuClick(e)
                toggle()
              }}>
              {availbleActions.map(
                ([
                  Icon,
                  key,
                  { label } = {},
                ]) => (
                  <Menu.Item
                    icon={<Icon />}
                    key={key || label}>
                    {t(
                      label || key || ''
                    )}
                  </Menu.Item>
                )
              )}
            </Menu>
          )}
        </DrawMenu>
      )}
    </div>
  )
}

export const FeedActions = () => {
  const {
    actions,
    shareUrl,
    handleClick,
    item,
  } = useContext(FeedContext)
  return (
    <div className="flex items-center text-sm">
      {actions.vote && (
        <Vote id={item.id} />
      )}
      {actions.message && (
        <Count
          onClick={() =>
            handleClick('to')
          }
          defaultNumber={_.get(
            item,
            'total_comments'
          )}
          key="message"
          color="color-primary"
          component={MessageOutlined}
        />
      )}

      {actions.view &&
        _.has(item, 'total_views') &&
        _.get(item, 'total_views', 0) >
          0 && (
          <Count
            defaultNumber={_.get(
              item,
              'total_views'
            )}
            key="view"
            color="color-primary"
            component={EyeOutlined}
          />
        )}
      {actions.share && (
        <Share
          url={shareUrl}
          id={item.id}
        />
      )}
      {actions.bookmark && (
        <BookmarkAction />
      )}
    </div>
  )
}

const BlockRenderer = ({
  photos,
  description,
  embed_data,
  blocks: [
    renderFirstBlock = Null,
    renderSecondBlock = Null,
    renderThirdBlock = Null,
  ],
  Wrapper = ({ children }) => children,
}) => {
  return (
    <Wrapper>
      {!_.isEmpty(photos) &&
        renderFirstBlock()}
      {description &&
        renderSecondBlock()}
      {embed_data && renderThirdBlock()}
    </Wrapper>
  )
}

const embedWithProps =
  ({ item, keywords, categories }) =>
  (Wrapper) => {
    const {
      url,
      photo,
      title,
      host_url,
      description,
    } = item

    return (
      <Wrapper
        {...{
          title,
          url,
          subTitle: host_url || url,
          description,
          image: photo,
          categories,
          keywords,
        }}
      />
    )
  }

export const FeedEmbed = ({
  renderBlocks = BlockRenderer,
}) => {
  const {
    item,
    article,
    cover,
    type,
    photos,
    handleClick,
    embed_data,
    title,
    poll,
    shareUrl,
    description,
    mentions,
  } = useContext(FeedContext)

  const translate = useTranslate()

  const {isSm} = useContext(LayoutContext)

  switch (type) {
    case 'post':
      const {
        photo,
        keywords,
        categories,
      } = item

      const withEmbedData =
        embedWithProps({
          photo,
          keywords,
          categories,
          item: embed_data,
        })

      return renderBlocks({
        photos,
        description,
        embed_data,
        Wrapper: ({ children }) => {
          return (
            <div className="flex flex-col gap-2">
              {children}
            </div>
          )
        },
        blocks: [
          () => (
            <div className="pt-2 mb-3">
              <GalleryEmbed
                images={photos.map(
                  (item) => item.path
                )}
              />
            </div>
          ),
          () => (
            <Description
              type="post"
              mentions={mentions}>
              {description}
            </Description>
          ),
          () =>
            !!ReactPlayer.canPlay(
              embed_data.url
            )
              ? withEmbedData(
                  VideoEmbed
                )
              : withEmbedData(
                  BaseEmbed
                ),
        ],
      })
    case 'poll_question':
      return (
        <div className="flex flex-col">
          {description && (
            <Description
              type="poll_question"
              mentions={mentions}>
              {description}
            </Description>
          )}
          {poll && (
            <PollEmbed poll={poll} />
          )}
        </div>
      )
    case 'article':
      const article_categories = _.get(
        article,
        'categories',
        []
      )
      const coverPhoto = _.get(
        article,
        'cover_photo',
        cover
      )
      if (!article) {
        return null
      }
      return (
          <div className="flex flex-col gap-1">
            {!_.isEmpty(title) && (
                <a
                    onClick={(e) => {
                      preventParentEvent(e)
                      handleClick('to')
                    }}
                    href={shareUrl}
                    className="font-bold text-color-000 no-underline">
                  {title}
                </a>
            )}
            <div className="w-full flex gap-2">
              {!_.isEmpty(description) && (
                  <div style={{lineHeight: 1.8}} className="flex-1 max-lines-3 text-sm italic">
                    {description}
                  </div>
              )}
              {coverPhoto && !isSm && (
                  <Image
                      preview={false}
                      src={coverPhoto}
                      fallback={Photo}
                      className="w-28 md:w-32 h-20 object-cover rounded-md"
                  />
              )}
            </div>
            {!_.isEmpty(article_categories) && (
                <CategoriesXS categories={article_categories}/>
            )}
          </div>
      )
    default:
      return (
        <React.Fragment>
          {description && (
            <Description
              mentions={mentions}>
              {description}
            </Description>
          )}
        </React.Fragment>
      )
  }
}

export const FeedContent = () => {
  return (
    <div className="verticalList__small overflow-hidden">
      <FeedEmbed />
    </div>
  )
}

export const DetailFeedContent = () => {
  const {
    item,
    type,
    photos,
    embed_data,
    poll,
    description,
    mentions,
  } = useContext(FeedContext)

  let contentArr = []
  if (
    _.has(embed_data, 'content_data')
  ) {
    contentArr = _.get(
      embed_data,
      'content_data'
    )
  } else {
    contentArr = _.get(
      embed_data,
      'content',
      ''
    )
      .split('\n')
      .map(function (item) {
        return {
          type: 'p',
          value: item,
        }
      })
  }
  return (
    <div className="verticalList__small overflow-hidden">
      {type === 'post' && (
        <>
          {photos.length > 0 && (
              <div className="mb-3">
                <GalleryEmbed
                    images={photos.map(
                        (item) => item.path
                    )}
                />
              </div>
          )}
          {description && (
            <Description
              type={'article'}
              mentions={mentions}>
              {description}
            </Description>
          )}
          {embed_data &&
            (!!ReactPlayer.canPlay(
              embed_data.url
            ) ? (
              <VideoEmbed
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
              />
            ) : (
              <DetailEmbed
                id={_.get(item, 'id')}
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_name'
                  ) ||
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                keywords={_.get(
                  item,
                  'keywords'
                )}
                content={contentArr}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
                others={_.get(
                  item,
                  'others_in_container',
                  []
                )}
              />
            ))}
        </>
      )}
      {type === 'poll_question' && poll && (
          <PollEmbed poll={poll} />
      )}
      {_.get(item, 'widgets', []).length > 0 && (
          <div className="verticalList__small">
            {_.get(item, 'widgets', []).map((e, i) => {
              return (
                  <Widget
                      key={i}
                      index={i}
                      item={e}
                  />
              )
            })}
          </div>
      )}
    </div>
  )
}

export const FeedContentLayout = ({
  style,
  children,
}) => {
  return (
    <div
      style={style}
      className="FeedCardContent verticalList__small border-color-50 p-2">
      {children}
    </div>
  )
}
export const withFeedContext = compose(
  fromRenderProps(
    FeedContext.Consumer,
    (props) => props
  )
)
export const FeedWrapper = ({
  className,
  children,
}) => {
  const { item } = useContext(
    FeedContext
  )
  return (
    <div
      onContextMenu={
        item.content_disabled
          ? preventParentEvent
          : undefined
      }
      className={classnames(
        'FeedCard',
        item.content_disabled &&
          'content_disabled',
        className
      )}>
      {children}
    </div>
  )
}
