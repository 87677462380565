import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import GivingContext from '../GivingContext'

const GivingSettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.givingSettings
  return createSettingContent(
    GivingContext,
    { routes, Wrapper, rootPath }
  )
}

export default GivingSettingsContent
