import Icon, {
  UserOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Menu,
} from 'antd'
import { userModel } from 'apis/model'
import ErrorBoundary from 'components/error/ErrorBoundary'
import { FlagIcon } from 'components/icons/FlagIcon'
import { LoginContext } from 'components/LoginContext'
import { isDevelop } from 'envs/ForDevelop'
import _ from 'lodash'
import { LocalContext } from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  IoAddCircle,
  IoAddCircleOutline,
  IoAlbums,
  IoAlbumsOutline,
  IoBookmarks,
  IoBookmarksOutline,
  IoChatbubbles,
  IoChatbubblesOutline,
  IoCreate,
  IoCreateOutline,
  IoLogOutOutline,
  IoNotifications,
  IoNotificationsOutline,
  IoRadio,
  IoRadioOutline,
  IoSearch,
  IoSearchOutline,
} from 'react-icons/io5'
import { ReactReduxContext } from 'react-redux'
import { matchPath } from 'react-router'
import { useLocation } from 'react-use'
import {
  nest,
  withProps,
} from 'recompose'
import {
  getLoginBoolean,
  getMessageNotifications,
  getNotifications,
} from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import { Selector } from 'views/Discovery/Recommendation'
import { notEmpty } from 'views/Shared'
import { ShortAppLogoSvg } from '../../../Svgs/ShortAppLogoSvg'
import {
  ExcludedMenuTypes,
  makeMenuItem,
} from './functions'
import { LanguageSwitch } from './LanguageSwitch'
import './SiderMenu.css'

const MenuItemIcon = ({
  path,
  children,
  component,
  subs = [],
  activeComponent,
  style,
}) => {
  const location = useLocation()
  const isActive = _.isEmpty(subs)
    ? matchPath(location.pathname, {
        path,
      })
    : _.some(
        subs,
        ({ to = {} }) =>
          !!matchPath(
            location.pathname,
            { path: to.pathname }
          )
      )
  if (children) {
    return children(isActive)
  }
  return (
    <div className="SiderMenu-icon cursor-pointer">
      <Icon
        className="cursor-pointer"
        style={{
          fontSize: '1.5rem',
          color: isActive
            ? 'var(--color-primary)'
            : 'var(--color-color)',
          ...style,
        }}
        component={
          isActive
            ? activeComponent ||
              component
            : component
        }
      />
    </div>
  )
}

const NotificationIcon = ({ path }) => {
  return (
    <Selector
      selector={getNotifications}>
      {(count) => (
        <MenuItemIcon path={path}>
          {(active) => (
            <div className="SiderMenu-icon">
              <Badge
                count={count}
                style={{
                  padding: '0',
                  fontSize: '10px',
                }}>
                <Icon
                  className="cursor-pointer"
                  style={{
                    fontSize: '1.5rem',
                    color: active
                      ? 'var(--color-primary)'
                      : 'var(--color-color)',
                  }}>
                  {!active ? (
                    <IoNotificationsOutline />
                  ) : (
                    <IoNotifications />
                  )}
                </Icon>
              </Badge>
            </div>
          )}
        </MenuItemIcon>
      )}
    </Selector>
  )
}

const User = () => {
  const login = useContext(LoginContext)
  const src =
    userModel.getFullAvatarUrl(login)
  return (
    <span>
      <Avatar
        src={src}
        size={50}
        className="background-100 hover:background-200 border">
        <UserOutlined className="text-color-000" />
      </Avatar>
      <span className="SiderMenu-label">
        {userModel.getTitle(login)}
      </span>
    </span>
  )
}

const Lang = withProps(() => {
  const { lang } = useContext(
    LocalContext
  )
  return {
    title: (
      <Avatar
        className="w-8 h-8 border-2 border-white background-200 rounded-full m-1 mx-2 inline-grid content-center justify-center cursor-pointer"
        icon={
          <FlagIcon
            type={lang}
            width="2rem"
            height="2rem"
            style={{
              color:
                'var(--color-primary)',
            }}
          />
        }
      />
    ),
  }
})(LanguageSwitch)

const menuEntities = {
  home: {
    key: 'home',
    label: 'home',
    to: staticPaths.home,
    icon: (
      <MenuItemIcon
        path={staticPaths.home.pathname}
        component={ShortAppLogoSvg}
      />
    ),
    valid: [],
  },
  user: {
    key: 'user',
    to: {
      pathname: '/user/home',
    },
    component: User,
    valid: ['login'],
  },
  search: {
    key: 'search',
    label: 'search',
    to: {
      pathname: '/search',
      state: {
        isModal: true,
      },
    },
    icon: (
      <MenuItemIcon
        path="/search"
        component={IoSearchOutline}
        activeComponent={IoSearch}
      />
    ),
    valid: [],
  },
  notifications: {
    key: 'notifications',
    label: 'notifications',
    to: {
      pathname: '/notifications',
    },
    icon: (
      <NotificationIcon path="/notifications" />
    ),
    valid: ['login'],
  },
  messages: {
    key: 'messages',
    label: 'messages',
    to: {
      pathname: '/messages',
    },
    icon: (
      <Selector
        selector={
          getMessageNotifications
        }>
        {(count) => (
          <MenuItemIcon
            path={'/messages'}>
            {(active) => (
              <div className="SiderMenu-icon">
                <Badge count={count}>
                  <Icon
                    {...{
                      style: {
                        fontSize:
                          '1.6rem',
                      },
                    }}
                    className={
                      active
                        ? 'text-primary cursor-pointer'
                        : 'text-color cursor-pointer'
                    }>
                    {!active ? (
                      <IoChatbubblesOutline />
                    ) : (
                      <IoChatbubbles />
                    )}
                  </Icon>
                </Badge>
              </div>
            )}
          </MenuItemIcon>
        )}
      </Selector>
    ),
    valid: ['login'],
  },
  my_channels: {
    key: 'my_channels',
    label: 'my channels',
    to: {
      pathname:
        staticPaths.myChannels.pathname,
    },
    icon: (
      <MenuItemIcon
        style={{ fontSize: '1.6em' }}
        component={IoRadioOutline}
        activeComponent={IoRadio}
        path={
          staticPaths.myChannels
            .pathname
        }
      />
    ),
    valid: ['login'],
  },
  my_articles: {
    key: 'my_articles',
    label: 'my articles',
    to: {
      pathname:
        staticPaths.myArticles.pathname,
    },
    icon: (
      <MenuItemIcon
        component={IoCreateOutline}
        activeComponent={IoCreate}
        path={
          staticPaths.myArticles
            .pathname
        }
      />
    ),
    valid: ['login'],
  },
  my_bookmarks: {
    key: 'my_bookmarks',
    label: 'my bookmarks',
    to: {
      pathname:
        staticPaths.myBookmarks
          .pathname,
    },
    icon: (
      <MenuItemIcon
        style={{ fontSize: '1.45em' }}
        component={IoBookmarksOutline}
        activeComponent={IoBookmarks}
        path={
          staticPaths.myBookmarks
            .pathname
        }
      />
    ),
    valid: ['login'],
  },
  discovery: {
    key: 'discovery',
    label: 'discovery',
    to: {
      pathname:
        staticPaths.discovery.pathname,
    },
    icon: (
      <MenuItemIcon
        component={IoAlbumsOutline}
        activeComponent={IoAlbums}
        path={
          staticPaths.discovery.pathname
        }
      />
    ),
    valid: [],
  },
  start: {
    key: 'start',
    label: 'welcome',
    to: {
      pathname: '/start',
    },
    valid: [],
  },
  about: {
    key: 'about',
    label: 'about us',
    to: {
      pathname: '/helps/about-us',
    },
    valid: [],
  },
  policy: {
    key: 'policy',
    label: 'privacy policy',
    to: {
      pathname: '/helps/privacy-policy',
    },
    valid: [],
  },
  cookies_policy: {
    key: 'cookies_policy',
    label: 'cookies policy',
    to: {
      pathname: '/helps/cookies-policy',
    },
    valid: [],
  },
  terms_of_service: {
    key: 'terms_of_service',
    label: 'terms of service',
    to: {
      pathname:
        '/helps/terms-of-service',
    },
    valid: [],
  },
  contact_info: {
    key: 'contact_info',
    label: 'contact info',
    to: {
      pathname: '/helps/contact-info',
    },
    valid: [],
  },
  trust_safe: {
    key: 'trust_safe',
    label: 'trust and safe',
    to: {
      pathname: '/helps/trust-safe',
    },
    valid: [],
  },
  logout: {
    key: 'logout',
    label: 'logout',
    to: {
      pathname: '/logout',
    },
    icon: (
      <MenuItemIcon
        style={{
          fontSize: '1.4em',
        }}
        component={IoLogOutOutline}
      />
    ),
    valid: ['login'],
  },
  language: {
    key: 'language',
    label: 'language',
    icon: (
      <MenuItemIcon
        style={{
          fontSize: '1.4em',
        }}
        component={Lang}
      />
    ),
  },

  // Begin Wishare
  activity: {
    key: 'activity',
    label: 'activity',
    to: staticPaths.createActivity,
  },
  donation: {
    key: 'donation',
    label: 'donation',
    to: staticPaths.createDonation,
  },
  giving: {
    key: 'giving',
    label: 'giving',
    to: staticPaths.createGiving,
  },
  recruitment: {
    key: 'recruitment',
    label: 'recruitment',
    to: staticPaths.createRecruitment,
  },
  event: {
    key: 'event',
    label: 'event',
    to: staticPaths.createEvent,
  },
  channel: {
    key: 'channel',
    label: 'channel',
    to: staticPaths.createChannel,
  },
  organization: {
    key: 'organization',
    label: 'organization',
    to: staticPaths.createOrganization,
  },
  // End Wishare
}

const helpSubs = [
  menuEntities.start,
  menuEntities.about,
  menuEntities.contact_info,
  menuEntities.terms_of_service,
  menuEntities.policy,
  menuEntities.cookies_policy,
  menuEntities.trust_safe,
]

const creatingSubs = [
  // menuEntities.activity,
  // menuEntities.donation,
  // menuEntities.giving,
  // menuEntities.recruitment,
  // menuEntities.channel,
  // menuEntities.event,
  menuEntities.organization,
]

const menuConfig = [
  menuEntities.home,
  menuEntities.discovery,
  menuEntities.notifications,
  menuEntities.messages,
  menuEntities.my_channels,
  menuEntities.my_articles,
  menuEntities.my_bookmarks,
  // {
  //   key: 'helps',
  //   label: 'helps',
  //   icon: (
  //     <MenuItemIcon
  //       style={{
  //         fontSize: '1.6em',
  //       }}
  //       component={IoAlertCircleOutline}
  //       activeComponent={IoAlertCircle}
  //       path="/helps"
  //       subs={helpSubs}
  //     />
  //   ),
  //   subMenu: helpSubs,
  // },
  {
    key: 'create-entity',
    label: 'create',
    icon: (
      <MenuItemIcon
        style={{fontSize: '1.6em',}}
        component={IoAddCircleOutline}
        activeComponent={IoAddCircle}
        path="/create-entity"
        subs={creatingSubs}
      />
    ),
    subMenu: creatingSubs,
    valid: ['login'],
  },
  {
    key: ExcludedMenuTypes.SPACE,
    type: ExcludedMenuTypes.SPACE,
  },
  menuEntities.language,
]

const SiderMenu = nest(
  ErrorBoundary,
  () => {
    const t = useTranslate()
    const history = useHistory()
    const push = history && history.push
    const { store } = useContext(
      ReactReduxContext
    )
    const isLogin = getLoginBoolean(
      store.getState()
    )

    const ignoredKeys = Object.values(
      ExcludedMenuTypes
    )

    const validEntities = {
      login: ({ isLogin }) => isLogin,
      dev: isDevelop,
    }

    const validateMenu = (
      valid = false,
      params = {}
    ) => {
      const isValid = valid
        ? !valid.find(
            (key) =>
              !validEntities[key]({
                isLogin,
                ...params,
              })
          )
        : true
      return isValid
    }

    return useMemo(
      () => (
        <Menu
          className="flex-1"
          prefixCls="SiderMenu"
          defaultSelectedKeys={[
            menuEntities.about.key,
          ]}
          onClick={({ key }) => {
            if (
              _.includes(
                ignoredKeys,
                key
              )
            )
              return
            if (menuEntities[key].to) {
              push(menuEntities[key].to)
            }
          }}
          items={menuConfig
            .map(
              makeMenuItem({
                renderLabel: t,
                validate: (value) =>
                  validateMenu(value),
              })
            )
            .filter(notEmpty)}
        />
      ),
      [isLogin, t, push, validateMenu]
    )
  }
)

export default SiderMenu
