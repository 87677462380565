import { LikeFilled, LikeOutlined } from '@ant-design/icons'
import React from 'react'
import {
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
} from 'react-icons/ri'
import { Null } from 'views/Shared'
import Count from './Count'
import { VoteTypes } from './Vote'
import {FingerLoveFillSvg} from "../Svgs/FingerLoveFillSvg";
import {FingerLoveOutlineSvg} from "../Svgs/FingerLoveOutlineSvg";
export const Like = React.memo(
  ({ vote_status, total_votes = 0, handleVote }) => {
    return (
      <Count
        active={!!vote_status}
        defaultNumber={total_votes}
        color="text-blue-600"
        onClick={handleVote}
        {...(!!vote_status
          ? {
            component: FingerLoveFillSvg,
          }
          : {
            component: FingerLoveOutlineSvg,
          })}
      />
    )
  }
)

export const ExtraLike = React.memo(
  ({
    vote_status,
    total_votes = 0,
    total_downvotes = 0,
    handleExtraVote = Null,
    onClick,
    action,
  }) => {
    const isVotedUp = vote_status === VoteTypes.UP

    const isVotedDown = vote_status === VoteTypes.DOWN

    switch (action) {
      case VoteTypes.UP:
        return (
          <Count
            active={!!isVotedUp}
            defaultNumber={total_votes}
            color="text-blue-600"
            onClick={onClick ? onClick : () => handleExtraVote(VoteTypes.UP)}
            {...(!!isVotedUp
              ? {
                component: RiThumbUpFill,
              }
              : {
                component: RiThumbUpLine,
              })}
          />
        )
      case VoteTypes.DOWN:
        return (
          <Count
            active={!!isVotedDown}
            defaultNumber={total_downvotes}
            color="text-blue-600"
            onClick={onClick ? onClick : () => handleExtraVote(VoteTypes.DOWN)}
            {...(!!isVotedDown
              ? {
                component: RiThumbDownFill,
              }
              : {
                component: RiThumbDownLine,
              })}
          />
        )
    }
  }
)
