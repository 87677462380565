export default {
  '00000-EN': 'TRAN',
  '(yes/no) question':
    '(Yes/No) Question',
  '{beneficiaryNbr} have beneficiaryed':
    '{beneficiaryNbr} have beneficiaryed',
  '0 result': '0 result',
  '1. no poverty': '1. No Poverty',
  '10. reduced inequalities':
    '10. Reduced Inequalities',
  '11. sustainable cities and communities':
    '11. Sustainable Cities And Communities',
  '12. responsible consumption and production':
    '12. Responsible Consumption And Production',
  '13. climate action':
    '13. Climate Action',
  '14. life below water':
    '14. Life Below Water',
  '15. life on land':
    '15. Life On Land',
  '16. peace, justice and strong institutions':
    '16. Peace, Justice And Strong Institutions',
  '17 SDGs': '17 SDGs',
  '17. partnerships for the goals':
    '17. Partnerships For The Goals',
  '2. zero hunger': '2. Zero Hunger',
  '3. good health and well-being':
    '3. Good Health And Well-Being',
  '4. quality education':
    '4. Quality Education',
  '5. gender equality':
    '5. Gender Equality',
  '6. clean water and sanitation':
    '6. Clean Water And Sanitation',
  '7. affordable and clean energy':
    '7. Affordable And Clean Energy',
  '8. decent work and economic growth':
    '8. Decent Work And Economic Growth',
  '9. industry, innovation and infrastructure':
    '9. Industry, Innovation And Infrastructure',
  A: 'A',
  'a post': 'Post',
  'a beneficiary of':
    'A beneficiary of',
  about: 'About',
  'about job': 'About job',
  'about us': 'About us',
  'about wishare': 'About wishare',
  abouthelp: 'About Us',
  abouthelptips:
    'Wishare, Company, Contact',
  accept: 'Accept',
  accepted: 'Accepted',
  'access to a huge human capital for good':
    'Access to a huge human capital for good',
  account: 'Account',
  'account name': 'Account name',
  activated: 'Activated',
  activation: 'Activation',
  active: 'Active',
  activetips:
    'Active pages, you have the right to manage, edit',
  activities: 'Activities',
  activity: 'Activity',
  'activity information':
    'Activity Information',
  'Activity is the smallest project unit with full mission and action. Your organization may have many different activities.':
    'Activity is the smallest project unit with full mission and action. Your organization may have many different activities.',
  'activity name': 'Activity name',
  activitytips:
    'Activity is a collection of work with clear goals in order to serve society. The activity can mobilize community resources to accomplish its goals.',
  add: 'Add',
  'add activity': 'Add activity',
  'add album': 'Add album',
  'add an image': 'Add image',
  'add auction item':
    'Add auction item',
  'add campaign': 'Add campaign',
  'add categories': 'Select your cares',
  'add delivery policy':
    'Add delivery policy',
  'add donation': 'Add donation',
  'add donation campaigns':
    'Add donation campaigns',
  'add location': 'Add location',
  'add member': 'Add member',
  'add more': 'Add more',
  'add new': 'Add new',
  'add new language':
    'Add new language',
  'add new map': 'Add new map',
  'add new product': 'Add new product',
  'add new schedule':
    'Add new schedule',
  'add notice': 'Add notice',
  'add photos': 'Add photos',
  'add product': 'Add product',
  'add promotion campaigns':
    'Add promotion campaigns',
  'add return policy':
    'Add return policy',
  'add tag': 'Add tag',
  'add to cart': 'Add to Cart',
  'add warranty policy':
    'Add warranty policy',
  'add your comment':
    'Add your comments',
  'add your school': 'Add your schools',
  'add your skills': 'Add your skills',
  'add your workplace':
    'Add your workplaces',
  'add yours recommendations':
    'Add yours recommendations',
  'added hosts': 'Added hosts',
  'additional message':
    'Additional message',
  address: 'Address',
  addressed: 'Addressed',
  adhoc: 'Special',
  admin: 'Admin',
  advanced: 'Advanced',
  'affordable and clean energy':
    'Affordable And Clean Energy',
  age: 'Age',
  age_from: 'Age from',
  age_from_placeholder: 'Age from',
  age_to: 'Age to',
  age_to_placeholder: 'Age to',
  agree: 'Agree',
  all: 'All',
  ambassador: 'ambassador',
  amount: 'Amount',
  An: 'An',
  and: 'and',
  'and petition': 'and petition',
  'and social impact work':
    'and social impact jobs',
  animal: 'Animal',
  anonymous: 'Anonymous Sponsor',
  answer: 'Answer',
  'applicant note': 'Applicant note',
  applicants: 'Applicants',
  'applied a job': 'Applied a job',
  'applied beneficiaries':
    'applied beneficiaries',
  applies: 'Applies',
  apply: 'Apply',
  'apply a new beneficiary':
    'Apply a new beneficiary',
  'apply an existing beneficiary':
    'Apply an existing beneficiary',
  'apply now': 'Apply Now',
  'apply now!': 'Apply now!',
  'apply technology to support':
    'Apply technology to support',
  'apply to become a volunteer':
    'Apply to become a volunteer',
  approve: 'Approve',
  approved: 'Approved',
  'are bidding': 'Are bidding',
  'are you agree to remove item from order?':
    'Are you agree to remove item from order?',
  'are you sure to cancel this order?':
    'Are you sure to cancel this order?',
  'are you sure you want to remove this item?':
    'Are you sure you want to remove this item?',
  'art & design': 'Art & Design',
  'Art, Collections & Antiques':
    'Art, Collections & Antiques',
  asc: 'Ascending',
  'ask questions': 'Ask questions',
  asking: 'Asking',
  'at least': 'At least',
  attributes: 'Attributes',
  'attributes list': 'Attributes list',
  auction: 'Auction',
  'auction donation campaign':
    'Auction donation campaign',
  'auction history': 'Auction history',
  'auction information':
    'Auction information',
  'auction items': 'Auction Items',
  'auction management':
    'Auction management',
  'auction members': 'Auction members',
  'auction name': 'Auction name',
  'auction product': 'Auction product',
  'auction products':
    'Auction products',
  'auction settings':
    'Auction settings',
  'auctioned price': 'Auctioned price',
  auctions: 'Auctions',
  automatic: 'Automatic',
  'automatic execution':
    'automatic execution',
  'Automotive Motorcycles':
    'Automotive Motorcycles',
  available: 'available',
  'available activities':
    'Available activities',
  'available for social work':
    'Available for social work',
  avatar: 'Avatar',
  'average rating point':
    'Average rating point',
  'Babies & Toys': 'Babies & Toys',
  back: 'Back',
  'back to shop': 'Back to shop',
  'basic information':
    'Basic information',
  'be a part of growing trend':
    'Be a part of growing trend',
  'be the first to bid':
    'Be the first to bid',
  'become a member of wishare':
    "Become a member of wishare (It's Free)",
  'become a volunteer ':
    'Become a volunteer ',
  "Before creating a new one, please see if your wishare already exists. In the case of Wishare's indexing, please contact us so we can re-administer it.":
    "Before creating a new one, please see if your wishare already exists. In the case of Wishare's indexing, please contact us so we can re-administer it.",
  begin: 'Begin',
  benefits: 'Benefits',
  'benefits and obligations':
    'Benefits & Obligations',
  'best sales': 'Best sales',
  bid: 'Bid',
  'bid now': 'Bid now',
  'bid price': 'Bid price',
  'bid time': 'Bid time',
  bidder: 'Bidder',
  bidding: 'Bidding',
  'bidding / asking':
    'Bidding: * The next bid will be higher than the previous bid, the highest bidder will win the bid. / Asking:* The next bid will be lower than the previous bid, the person who invites the lowest price will win the bid.',
  bill: 'Bill',
  binding_conditions:
    'binding conditions',
  binding_conditions_placeholder:
    'binding conditions',
  block: 'Block',
  blocked: 'Blocked',
  blockedtips:
    'Sorry. Your content is inappropriate, not allowed to be shown publicly',
  'Books, Stationery & Gifts':
    'Books, Stationery & Gifts',
  bought: 'Bought',
  'but let us help measure your impact and discover the social activities around you':
    'but let us help measure your impact and discover the social activities around you',
  'but they can not find aright one':
    'but they can not find aright one',
  'but they can not find the support':
    'but they can not find the support',
  'buy now': 'Buy now',
  by: 'by',
  'campaign name': 'Campaign name',
  'cancel ': 'Cancel',
  'cancel order': 'Cancel order',
  cancelled: 'Cancelled',
  career: 'Career',
  careers: 'Careers',
  cart: 'Cart',
  categories: 'Categories',
  categoriesplaceholder:
    'Select categories',
  category: 'Category',
  certificate: 'Certificate',
  change: 'Change',
  'change cover photo':
    'Change cover photo',
  'change in price and quantity tab':
    'Pls, change in price and quantity tab',
  'change status': 'Change status',
  'change your picture':
    'Change your picture',
  'charity products':
    'Charity products',
  'charity, social impact project':
    'Charity, social impact project',
  'check your email and click on the activation link to complete the registration':
    'Check your email and click on the activation link to complete the registration',
  children: 'Children',
  choices: 'Choices',
  choose: 'Choose',
  'choose 1 of the funds to receive the donation':
    'Choose 1 of the funds to receive the donation',
  'choose a photo as the image for the activity':
    'Choose a photo as the image for the activity',
  'choose a photo as the image for the event':
    'Choose a photo as the image for the event',
  'choose a photo as the image for the group':
    'Choose a photo as the image for the group',
  'choose the category that you are aiming for':
    'Choose the category that you are aiming for',
  'choose the job type to continue':
    'Choose the job type to continue',
  'choose the target you want to target':
    'Choose the target you want to target',
  'clean water and sanitation':
    'Clean Water And Sanitation',
  CleanEnergy: 'CleanEnergy',
  CleanWaterAndSanitation:
    'CleanWaterAndSanitation',
  clear: 'Clear',
  'clear filter': 'Clear filter',
  click: 'Click',
  'click here': 'Click here',
  'click here to add new donate package':
    'click here to add new donate package',
  'click here to add new photos':
    'Click here to add new photos',
  'click here to dismiss recommendation this beneficiary':
    'Click here to dismiss recommendation this beneficiary',
  'click to next': 'Click to next',
  'click to vote it':
    'Click to vote it',
  'climate action': 'Climate Action',
  close: 'Close',
  closed: 'Closed',
  'closed need': 'Closed',
  closedgroup: 'Closed Organization',
  collected: 'Collected',
  Color: 'Color',
  'coming soon': 'Coming soon',
  comment: 'comment',
  'comment history': 'Comment history',
  'comment on product':
    'Comments on product',
  'comment store': 'Comment store',
  common: 'Common',
  'community policy':
    'Community Policy',
  company: 'Company',
  companytips: 'Company',
  'complete add new product':
    'Complete add new product',
  'complete the information so people can understand your store better':
    'Complete the information so people can understand your store better',
  'Complete with sharing and spreading. We need you.':
    'Complete with sharing and spreading. We need you.',
  completed: 'Completed',
  'completed project':
    'completed project',
  'completed beneficiary':
    'completed beneficiary',
  'computer & data': 'Computer & Data',
  'confirm cart': 'Confirm cart',
  'confirm password':
    'Confirm password',
  'congratulation you become winner':
    'Congratulation you become winner',
  'congratulation your payment has been finished':
    'Congratulation your payment has been finished',
  congratulations: 'Congratulations',
  'congratulations, your order is successful':
    'Congratulations, Your order is successful',
  'connect with facebook':
    'Connect with Facebook',
  'connect with google':
    'Connect with Google',
  'connect with us': 'Connect with us',
  'connecting for good':
    'Connecting for good',
  'connecting people of kindness and needs':
    'Connecting people of kindness and needs',
  'connecting the goodness':
    'Connecting \nthe goodness',
  contact: 'Contact',
  'contact us': 'Contact Us',
  'Contact us by sending to contact@wishare.com':
    'Contact us by sending to contact@wishare.com',
  contact_info: 'Contact ',
  content: 'Content',
  'content created by wishare':
    'Indexed By Wishare',
  continue: 'Continue',
  'continue shopping':
    'Continure shopping',
  'continuous time': 'Continuous time',
  contract: 'Contract',
  contribute: 'Contribute',
  cookies: 'Cookies',
  'cookies policy': 'Cookies policy',
  copied: 'Copied',
  copy: 'Copy',
  'copy url': 'Copy Url',
  'Copyright by wishare.com':
    'Copyright by wishare.com',
  could_not_create_token:
    'Login unsuccessful!',
  'count on product':
    'Count on product',
  create: 'Create',
  'create a crowdfunding activity':
    'Create a crowdfunding activity',
  'create a new album':
    'Create a new album',
  'create a new opportunity':
    'Create a new opportunity',
  'create account & login':
    'Create account & Login',
  'create activity': 'Create Activity',
  'create auction': 'Create auction',
  'create donation': 'Add Donation',
  'create event': 'Create event',
  'create giving': 'create giving',
  'create group': 'Create Organization',
  'create need':
    'Create new crowdfunding',
  'create new account':
    'create new account',
  'create new an activity':
    'Create new an activity',
  'create new auction':
    'Create new auction',
  'create new package':
    'Create new package',
  'create opportunity':
    'Create opportunity',
  'create post': 'Create post',
  'create store': 'Create store',
  'create beneficiary':
    'Create beneficiary',
  'create your activity':
    'Create your Activity',
  'create your event':
    'Create your event',
  'create your group':
    'Create your organization',
  'create your beneficiary':
    'Create your beneficiary',
  created: 'created',
  'created date': 'Created date',
  crowdfunding: 'Crowdfunding',
  culture: 'Culture',
  current: 'Current',
  'current price': 'Current price',
  currentUser: 'You',
  'customer rated positively':
    'customer rated positively',
  date: 'Date',
  'date & time': 'Date & Time',
  date_of_birth: 'Date of birth',
  day: 'Day',
  days: 'days',
  'days of delivery':
    'Days of delivery',
  'deaf mute': 'Deaf mute',
  default: 'Default',
  delete: 'Delete',
  'delete comment': 'Delete comment',
  'delete opportunity':
    'Delete this job',
  'delete question': 'Delete question',
  'delete the selected items':
    'Delete the selected items',
  delivered: 'Delivered',
  delivery: 'Delivery',
  'delivery by': 'Delivery by',
  'delivery by shop':
    'Delivery by shop',
  'delivery details':
    'Delivery details',
  'delivery fee': 'Delivery fee',
  'delivery location':
    'Delivery location',
  'delivery options':
    'Delivery options',
  'delivery period from':
    'Delivery period from',
  'delivery policy': 'Delivery policy',
  'delivery status': 'Delivery status',
  'delivery unit': 'Delivery unit',
  'delivery-methods':
    'Delivery methods',
  'delivery-policies':
    'Delivery policies',
  desc: 'Decrease',
  description: 'Introduce',
  descriptionplaceholder:
    'Write more here.',
  designer: 'Designer',
  detail: 'Detail',
  development: 'Development',
  'digital of your social activity':
    'Digital of your social activity',
  'directly work': 'Directly work',
  disabilities: 'Disabilities',
  disablity: 'Disablity',
  discard: 'Discard',
  discount: 'Discount',
  'discount value': 'Discount',
  'Discover and contribute to community fundraising activities. We need you.':
    'Discover and contribute to community fundraising activities. We need you.',
  'discover now': 'Discover now',
  discovery: 'Discovery',
  discussion: 'Discussion',
  'dismiss recommendation':
    'Dismiss recommendation',
  'display on homepage':
    'Display on homepage',
  district: 'District',
  divorced: 'Divorced',
  divorcedtips: 'Divorced',
  'do you want to delete this?':
    'Do you want to delete this?',
  'do you want to remove this post?':
    'Do you want to remove this post?',
  'Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?':
    'Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?',
  donate: 'Donate',
  'donate now': 'Donate Now',
  'donate what you can':
    'Donate what you can',
  donated: 'Donated',
  donation: 'Donation',
  'donation campaigns':
    'Donation campaigns',
  'donation code': 'Donation Code',
  'donation info': 'Donation Info',
  'donation manager':
    'Donation Manager',
  'donation transfer':
    'Donation transfer',
  'donation value': 'Donation value',
  'donation value must be large than 0':
    'Donation Value Must Be Large Than 0',
  'donation-campaigns':
    'Donation campaigns',
  donations: 'Donations',
  done: 'Done',
  'done beneficiaries':
    'Done beneficiaries',
  donetips: 'Done',
  donor: 'Donor',
  downvote: 'Down vote',
  draft: 'Draft',
  drafttips:
    'Content is reverting to drafts, backup the contents, continue to send publication requests',
  earlier: 'Earlier',
  edit: 'Edit',
  'edit auction item':
    'Edit auction item',
  'edit comment': 'Edit comment',
  'edit delivery policy':
    'Edit delivery policy',
  'edit donate option':
    'Edit donation options',
  'edit donation': 'Edit Donation',
  'edit donation campaigns':
    'Edit donation campaigns',
  'edit giving': 'Edit giving',
  'edit image': 'Edit image',
  'edit location': 'Edit location',
  'edit margin value':
    'Edit margin value',
  'edit member': 'Edit member',
  'edit member information':
    'Edit member information',
  'edit notice': 'Edit notice',
  'edit opportunity':
    'Edit opportunity',
  'edit post': 'Edit post',
  'edit product infomation':
    'Edit product infomation',
  'edit profile': 'Edit Profile',
  'edit promotion campaign':
    'Edit promotion campaign',
  'edit question': 'Edit question',
  'edit return policy':
    'Edit return policy',
  'edit warranty policy':
    'Edit warranty policy',
  education: 'Education',
  'education/ training':
    'Education/ Training',
  educations: 'Educations',
  'effective management and reporting':
    'Effective management and reporting',
  'efficient successful and sustainable':
    'efficient, successful and sustainable',
  'Electronic & Accessories':
    'Electronic & Accessories',
  email: 'Email',
  emailplaceholder:
    'Email (example@company.com)',
  embed: 'Embed',
  'empathize and help to connect these beneficiaries to the relevent activities that you should know':
    'Empathize and help to connect these beneficiaries to the relevent activities that you should know',
  'employment type': 'Employment type',
  empty: 'Empty',
  end: 'End',
  'end date': 'End date',
  'end time': 'End time',
  'end time must be after start time':
    'End time must be after start time',
  end_at: 'End at',
  end_date: 'End date',
  end_time: 'End time',
  ended: 'Ended',
  'ended at': 'Ended at',
  'ended session': 'Ended session',
  'ending now': 'Ending now',
  english: 'English',
  englishtips: 'English',
  'enter a location':
    'Enter a location',
  'enter album name':
    'Enter album name',
  'enter amount': 'Enter amount',
  'enter keyword': 'Enter keyword',
  'enter name': 'Enter name',
  'enter order code':
    'Enter order code',
  'enter order user':
    'Enter order user',
  'enter the activity name':
    'Enter the activity name',
  'enter the reason you think is a violation':
    'Enter the reason you think is a violation',
  'enter your question here':
    'Enter your question(s) here',
  'enter your reply here':
    'Enter your reply here',
  environment: 'Environment',
  environmental: 'Environmental',
  'error message':
    'Something is wrong! Check connections and reload again!',
  event: 'Event',
  'event time': 'Event time',
  'event timeline': 'Timeline',
  'event title': 'Event title',
  events: 'Events',
  eventtips:
    'Events are activities that involve multiple people at the same time and place. Events are used to implement your ideas.',
  example: 'Example',
  'example name': 'Johny English',
  experience_requirements:
    'experience requirements',
  expired: 'Expired',
  'expired job': 'expired job',
  'explore funding need':
    'Explore funding need',
  'explore jobs in': 'Explore jobs in',
  'explore the list of activities which are suitable for your need':
    'Explore the list of activities which are suitable for your need',
  'export data': 'Export Data',
  failed: 'Failed',
  'farming & forestry':
    'Farming & Forestry',
  'Fashion & Accessories':
    'Fashion & Accessories',
  fee: 'Fee',
  feedback: 'Feedback',
  feeds: 'Feeds',
  female: 'Female',
  femaletips: 'Female',
  'field of work': 'Field of work',
  filterplaceholder: 'Filter',
  'financial capital':
    'Financial \ncapital',
  'financial capital for good':
    'financial capital for good',
  'Find volunteers or create social impact locations, Experience the free recruitment on Wishare.':
    'Find volunteers or create social impact locations, Experience the free recruitment on Wishare.',
  finish: 'Finish',
  flexible: 'Flexible',
  'focusing on good connection':
    'Focusing on good connection',
  follow: 'Follow',
  'followed you': 'followed you',
  follower: 'follower',
  'followers only': 'Followers only',
  following: 'Following',
  'following auction':
    'Following auction',
  'following events':
    'Following events',
  'following beneficiaries':
    'Following beneficiaries',
  'following-auction':
    'Following Auction',
  for: 'For',
  'for community': 'for community',
  'for recruiters, let access to our huge volunteer community':
    'For recruiters, let access to our huge volunteer community',
  'for sales': 'For sales',
  'for social and creative purpose':
    'For social and creative purpose',
  'for volunteer, please let us give you a good recommendation':
    'For volunteer, please let us give you a good recommendation',
  'for volunteers': 'For volunteers',
  'for who seek to raise money for the good thing, implementing your initiative':
    'For who seek to raise money for the good thing, implementing your initiative',
  forgotpass: 'Forgot my password?',
  foundation: 'Foundation',
  'free for friends':
    'Free for friends',
  'free ship': 'Free ship',
  'free ship with minimum amount':
    'Free ship with minimum amount',
  frequency: 'Frequency',
  frequencyplaceholder: 'Frequency',
  friend: 'Friend',
  friends: 'Friends',
  from: 'From',
  'from donors': 'Thank you',
  'full-time': 'Full-time',
  fullname: 'Fullname',
  'fundraiser for good':
    'fundraiser for good',
  gave: 'Gave',
  gender: 'Gender',
  'gender equality': 'Gender Equality',
  GenderEquality: 'GenderEquality',
  general: 'General',
  'general information':
    'General information',
  'get started': 'Get started',
  gift: 'Gift',
  'gift product': 'Gift product',
  'gift product list':
    'Gift product list',
  'gift products': 'Gift products',
  'give your job a title':
    'Give your job a title',
  'give your job position':
    'Give your job position',
  'given number': 'Given Amount',
  giving: 'Giving',
  'giving what we can?':
    'Giving what we can?',
  'go to': 'Go to',
  'go to cart': 'Go to cart',
  'go to shop': 'Go to shop',
  good: 'Good',
  'good health and well-being':
    'Good Health And Well-Being',
  graduated: 'graduated',
  "Great, you've signed - the next step is to share far and wide to make sure everyone sees this campaign.":
    'Great, you’ve signed - the next step is to share far and wide to make sure everyone sees this campaign.',
  'Groceries & Pets':
    'Groceries & Pets',
  group: 'Organization',
  groups: 'Organizations',
  grouptips:
    'Organization is exchange space, connecting many people. Groups are used to share the information of company, organizations (non-profits, associations, ...) or groups of friends.',
  grouptypeplaceholder:
    'Organization type',
  'half successful': 'Half successful',
  'has been linked': 'has been linked',
  'has been supported':
    'Has been supported',
  'has draft orders':
    'has draft orders',
  'has orders': 'has orders',
  haveaccount:
    'Already have an account?',
  header: 'Header',
  'Health & Beauty': 'Health & Beauty',
  healthcare: 'Healthcare',
  healthy: 'Healthy',
  Height: 'Height',
  help: 'Help',
  helps: 'Helps',
  'here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.':
    'Here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.',
  'hidden due to invalid content':
    'Hidden content for violation of Policy and Terms of Wishare',
  hide: 'hide',
  'high to low': 'Top down',
  history: 'History',
  home: 'Home',
  'Home & LifeStyle':
    'Home & LifeStyle',
  hometown: 'Hometown',
  'hosted by': 'Hosted by',
  'hosted items': 'Hosted items',
  hostedactivities: 'Activities',
  hostedItem: 'Hosted item',
  hosting: 'Hosting',
  hosts: 'Hosts',
  'hour(h)': 'hour(h)',
  'hourly-work': 'Hourly-work',
  'house number, street name':
    'House number, street name',
  'how can you help?':
    'How can you help?',
  'how many can you help?':
    'How much can you help?',
  'how many people do you need?':
    'How many people do you need?',
  human: 'Human',
  'human capital': 'Human \ncapital',
  'human capital for good':
    'human capital for good',
  'i have read and accepted with these':
    'I have read and accepted with these',
  'If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.':
    'If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.',
  'If you have any questions, please contact us for support':
    'If you have any questions, please contact us for support.',
  "If you want to create a crowdfunding activity for charitable, social or creative activities. Let's use and experience the crowdfunding function on our platform.":
    "If you want to create a crowdfunding activity for charitable, social or creative activities. Let's use and experience the crowdfunding function on our platform.",
  ignore: 'Ignore',
  ignored: 'Ignored',
  impact: 'Impacts',
  'impact jobs': 'Impact Jobs',
  'important note': 'important note',
  in: 'IN',
  'In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activity. Join Wishare to share and contribute to these sustainability goals.':
    'In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activities. Join Wishare to share and contribute to these sustainability goals.',
  'in order for an employer to reach you, update some of yourself':
    'In order for an employer to reach you, update some of yourself',
  'in our good connection':
    'in our good connection',
  'in the same categories':
    'In the same categories',
  'in-activated': 'In-Activated',
  inactive: 'Inactive',
  inactivetips:
    'The page has snoozed or disabled, the information is confidential internally',
  index: 'Index',
  'industry, innovation and infrastructure':
    'Industry, Innovation And Infrastructure',
  info: 'Info',
  information: 'Information',
  'information about donating':
    'Information about donating',
  'information where donations are made':
    'Information where donations are made',
  informationandmap: 'Location',
  infrastructure: 'Infrastructure',
  'initial price': 'Initial price',
  input: 'input',
  inreview: 'In Review',
  inreviewtips:
    'We are reviewing your page and we will send you an email at the completion of the review process.',
  'insert question': 'Insert question',
  'inspire your goodness':
    'Inspire your goodness',
  'instructions on how to reset your password were sent to':
    'Instructions on how to reset your password were sent to',
  'intends to delivery from':
    'intends to delivery from',
  internal: 'Internal',
  internship: 'Internship',
  internships: 'Internships',
  'interpreter & translator':
    'Interpreter & Translator',
  'introduce yourself':
    'Introduce about yourself',
  'invalid date': 'Invalid date',
  'invalid email': 'Invalid email',
  'invalid email address':
    ' Invalid email address',
  invalid_credentials:
    'Wrong login information. Please check your account and login password!',
  invite: 'Invite',
  'is merged into': 'is merged into',
  'is priority product':
    'Is priority product',
  "It's YOU": "It's YOU",
  'job description': 'Job description',
  'job details': 'Job details',
  'job filter': 'Job filter',
  'job function type':
    'Job function type',
  'job information': 'Job information',
  'job type': 'Job type',
  job_requirements: 'Job requirements',
  job_types: 'Job types',
  jobs: 'Jobs',
  join: 'Join',
  'join day': 'Join day',
  'join us': 'Join us',
  'join us now': 'Join us now',
  'join us on mobile':
    'join us on mobile',
  joined: 'Joined',
  'joined activities':
    'Joined activities',
  'joined groups':
    'Joined organizations',
  'just for you': 'Just for you',
  'keep your passion like an artist and manage your social mission like a tech CEO':
    'Keep your passion like an artist and manage your social mission like a tech CEO',
  keyword: 'Keyword',
  language: 'Language',
  'language choose':
    'Display language:',
  language_requirements:
    'Language requirements',
  languages: 'Languages',
  'large scale': 'large scale',
  'last bid price': 'Last bid price',
  'last bidder': 'Last bidder',
  latitude: 'Latitude',
  'law - policy': 'Law - Policy',
  'law / legal services':
    'Law / Legal Services',
  'learn more': 'Learn more',
  'leave comment': 'Leave comment',
  Length: 'Length',
  'let discover and join to these activities below or apply your wish to request a support':
    'Let discover and join to these activities below or apply your wish to request a support',
  'let discover the crowdfunding from the community and contribute to a change':
    'Let discover the crowdfunding from the community and contribute to a change',
  'let make a free activity':
    'Let make a free activity',
  'let sign and help to connect these beneficiaries to relevent activities or the kindness people':
    'Let sign and help to connect these beneficiaries to relevent activities or the kindness people',
  "let's us help": "Let's us help",
  level: 'Level',
  'life below water':
    'Life Below Water',
  'life on land': 'Life On Land',
  LifeBelowWater: 'LifeBelowWater',
  LifeOnLand: 'LifeOnLand',
  'link target': 'Link Target',
  'link title': 'Link Title',
  'link to': 'Link to',
  'link to schedule':
    'Link to schedule',
  linked: 'linked',
  links: 'links',
  'live session': 'Live session',
  loading: 'Loading',
  location: 'Location',
  'location list': 'Locations',
  locationplaceholder:
    'Enter location info',
  locations: 'Locations',
  login: 'Login',
  logout: 'Log Out',
  longitude: 'Longitude',
  lost: 'Lost',
  'Lot size': 'Lot size',
  'low to high': 'Bottom up',
  'make a crowdfunding':
    'Make a crowdfunding',
  'make a crowdfunding now':
    'Make a crowdfunding now',
  'make a recruitment':
    'Make a recruitment',
  'make a beneficiary':
    'Make a beneficiary',
  'make an activity':
    'Make an activity',
  'make digital conversions for social initiatives to help them work':
    'Make digital conversions for social initiatives to help them work',
  'make event': 'Make event',
  'make opportunity now':
    'Make an opportunity now',
  'make post': 'Make post',
  makeactivityplaceholder:
    'enter activity name',
  makeeventplaceholder:
    'enter event name',
  makegroupplaceholder:
    'Enter organization name',
  makebeneficiaryplaceholder:
    'enter your beneficiary',
  male: 'Male',
  maletips: 'Male',
  mall: 'Mall',
  'mall ': 'Mall ',
  'mall & auction': 'Mall & Auction',
  'manage members': 'Manage members',
  management: 'Management',
  manager: 'Manager',
  mandatory: 'Mandatory',
  manual: 'Manual',
  'manual / automatic':
    'Manual:* The auction program will be controlled and started by the auction manager. Automatic: * The auction program will automatically start at the time specified in the auction program.',
  manufacturer: 'Manufacturer',
  'many people or causes that':
    'Many people or causes that',
  'many people or organisations':
    'Many people or organisations',
  map: 'Map',
  'margin value': 'Margin value',
  marital_status: 'Marital status',
  "mark 'active' if you want social activity units to be able to reach you":
    "Mark 'Active' if you want social activity units to be able to reach you",
  'mark all as read':
    'mark all as read',
  married: 'Married',
  marriedtips: 'Married',
  Material: 'Material',
  max_age: 'Max age',
  max_salary: 'Max salary',
  'maximum with': 'maximum with',
  'may be available to close, let check it again':
    'may be available to CLOSE, let check it again',
  media: 'Media',
  'media & communication':
    'Media & Communication',
  member: 'Member',
  'member filter': 'Member filter',
  'member name': 'Member name',
  members: 'Members',
  membersadmintips:
    'Manager, edit the information page of a Activity, activity, event. Administrators have the right to approve the content and accept new members',
  membersmembertips:
    'Users of the Activity, activities, events any',
  membersownertips:
    'Senior manager of a Activity, activity, aspirations. Administrators have the right to install the administrator, approve new members',
  'merge tags': 'Merge tags',
  'message has been sent':
    'Your message has been sent. Thank you for filling out our form!',
  methods: 'Total payment',
  'min margin value':
    'Min margin value',
  min_age: 'Min age',
  min_salary: 'Min salary',
  'minimum margin value':
    'Minimum margin value',
  'minimum with': 'minimum with',
  'minute(m)': 'minute(m)',
  'missing data': 'Missing information',
  mission: 'Mission',
  mission_placeholder:
    'Mission of activity',
  money: 'Money',
  month: 'Month',
  months: 'months',
  more: 'more',
  'more topics to continue':
    'More Topics To Continue',
  'multiple choice': 'Multiple choice',
  'mutual users': 'Mutual friends',
  'my auctions': 'My auctions',
  'my orders': 'My orders',
  'my questions': 'My questions',
  'my reviews': 'My reviews',
  'my stores': 'My stores',
  myfollowers: 'Following on mine',
  name: 'Name',
  'name of consignee':
    'Name of consignee',
  'name of people you want to invite':
    'name of people you want to invite',
  nameplaceholder: 'Fullname',
  nature: 'Nature',
  need: 'Crowdfunding',
  'need category type':
    'Crowdfunding type',
  'need contact':
    'Information to contribute',
  'need from': 'Get started',
  'need help': 'need help',
  'need information':
    'Need information',
  'need manager':
    'Crowdfunding management',
  'need title': 'crowdfunding title',
  'need to': 'received to date',
  needs: 'Crowdfunding',
  'new activity': 'New activity',
  'new donate option':
    'New donate option',
  'create organization':
    'Create Organization',
  'new location': 'Add Location',
  'new orders': 'New orders',
  'new password': 'New password',
  'new products': 'New products',
  'new question': 'New question',
  news: 'News',
  next: 'Next',
  'next item': 'Next item',
  nextevents: 'Next events',
  'NGO, NPO, CSR, Social group':
    'NGO, NPO, CSR, Social group',
  'ngo/ non - profit':
    'NGO/ Non - Profit',
  no: 'No',
  'no categories': 'No Categories',
  'no data yet to display':
    'No data yet to display',
  'no item found': 'No item found',
  'no matches for': 'No matches for',
  "No matter what you're a NPO or an enterprise or just an individual who passionote to make change, you're our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.":
    "No matter what you're a NPO or an enterprise or just an individual who passionote to make change, you're our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.",
  'no milestones were created':
    'No Milestones Were Created',
  'No milestones were created. Click here to start creating a schedule':
    'No milestones were created. Click here to start creating a schedule',
  'no more item': 'No more item',
  'no poverty': 'No Poverty',
  'no products in your cart':
    'No products in your cart',
  'no profile found':
    'no profile found',
  'no return': 'No return',
  'no tags': 'No Tags',
  'no warranty': 'No warranty',
  'non-profit activity':
    'non-profit activity',
  none: '...',
  NoPoverty: 'NoPoverty',
  normal: 'Normal',
  'Not all of us can do great things. But we can do small things with great love.':
    'Not all of us can do great things. But we can do small things with great love.',
  'not good': 'Not good',
  'not updated': 'Not updated',
  'not yet comment': 'Not yet comment',
  not_activated_account_credentials:
    'Account not activated. Please check the activation email to activate it',
  not_exists_account_credentials:
    'Account does not exist. Please register before or login using google account, facebook!',
  note: 'Note',
  'notice description':
    'Notice description',
  'notice management':
    'Notice management',
  'notice name': 'Notice name',
  notices: 'Notices',
  notifications: 'Notifications',
  'numerical order': 'No',
  obligations: 'Obligations',
  of: 'of',
  old: 'Old',
  'old password': 'Old password',
  'old price': 'Old price',
  on: 'on',
  'on this page': 'ON THIS PAGE',
  on_site: 'On site',
  'one product of': 'One product of',
  'online auction channel - charity donation with auctions - sell products with auctions':
    'Online auction channel - charity donation with auctions - sell products with auctions',
  'online jobs': 'Online jobs',
  'only me': 'Only me',
  'open time': 'Open time',
  opportunities: 'Opportunities',
  opportunity: 'Volunteers',
  oppr_type: 'Oppr type',
  optional: 'optional',
  options: 'Options',
  or: 'Or',
  'or a': 'or a',
  'or helping to connect the social mission and the good beneficiaries':
    'or helping to connect the social mission and the good beneficiaries',
  'or login directly by':
    'or login directly by',
  "Or you update your personal information, leaving the mode 'available to volunteer', employers will search for you.":
    "Or you update your personal information, leaving the mode 'available to volunteer', employers will search for you.",
  order: 'Order',
  'order code': 'Order code',
  'order details': 'Order details',
  'order filter': 'Order filter',
  'order information':
    'Order information',
  'order management':
    'Order management',
  'order number': 'No',
  'order status': 'Order status',
  'order user': 'Order user',
  'ordered at': 'Ordered at',
  orders: 'Orders ',
  organization: 'Organization',
  organizationtips: 'Organization',
  other: 'Other',
  'other activities':
    'other activities',
  'other auction sessions':
    'Other auction sessions',
  'other display': 'Other Display',
  'other information':
    'Other information',
  'other people': 'the others',
  'other reasons': 'Other reasons',
  other_requirements:
    'Other requirements',
  others: 'Others',
  othertips: 'Other',
  'Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.':
    'Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.',
  out: 'OUT',
  output: 'output',
  'Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.':
    'Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.',
  owner: 'Owner',
  package: 'Package',
  packed: 'Packed',
  paragraph: 'Paragraph',
  parallel: 'Parallel',
  'parallel mode': 'Parallel mode',
  'part-time': 'Part-time',
  partnerships: 'Partnerships',
  'partnerships for the goals':
    'Partnerships For The Goals',
  password: 'Password',
  'password required':
    'Password Required',
  passwordplaceholder:
    'Password (minimum 6 characters)',
  payment: 'Payment',
  'payment completed':
    'Payment completed',
  'payment location':
    'Payment location',
  'payment management':
    'Payment management',
  'payment method': 'Payment method',
  'payment type': 'Payment type',
  'peace, justice and strong institutions':
    'Peace, Justice And Strong Institutions',
  pending: 'Pending',
  'pending orders': 'Pending orders',
  'pending payment': 'Pending payment',
  'pending beneficiaries':
    'Pending beneficiaries',
  pendingdonations: 'Pending Donations',
  pendingtips: 'Pending',
  'people with disabilities':
    'People with disabilities',
  'per day': 'Per Day',
  'per hour': 'Per Hour',
  'per month': 'Per Month',
  'per quarter': 'Per Quarter',
  'per week': 'Per Week',
  'per year': 'Per Year',
  percent: 'percent',
  'period time': 'Period time',
  person: 'Person',
  person_s: 'Prs',
  phone: 'Phone',
  phone_number: 'Phone number',
  phone_placeholder:
    'Your phone number',
  photo: 'Photo',
  'photo gallery': 'Photo gallery',
  photos: 'Photos',
  'pick tags': 'Pick tags',
  'place order': 'Place order',
  'please complete the basic information':
    'Please complete the basic information',
  'please select an organization, individual or group to be the owner':
    'Please select an organization, individual or group to be the owner',
  'please sign with us, if you agree':
    'Please sign with us, if you agree',
  'please follow these instructions to complete the donation process.':
    'Please follow these instructions to complete the donation process.',
  'policy name': 'Policy name',
  policyhelp: 'Policies & Terms',
  policyhelptips:
    'Trust and Safety, Terms of use, Privacy Policy, Cookies, Community Guidelines',
  position: 'Position',
  'position and size your photo':
    'Position and size your photo',
  'positive rating': 'Positive rating',
  post: 'post',
  posted: 'posted',
  prerequisites: 'prerequisites',
  prerequisites_placeholder:
    'prerequisites',
  'current job': 'Current job',
  prevevents: 'Previous events',
  price: 'Price',
  'price and quantity':
    'Price and Quantity',
  'price type': 'Price type',
  priority_conditions:
    'priority conditions',
  priority_conditions_placeholder:
    'priority conditions',
  privacy: 'Privacy',
  'privacy policy': 'Privacy policy',
  private: 'Private',
  privatetips:
    'Closed site with the highest security. Only page creators can view the content, post information. ',
  process: 'Process',
  'process to payment':
    'Process to payment',
  processing: 'In the process',
  processingtips: 'Processing',
  product: 'Product',
  'product album': 'Product album',
  'product attributes':
    'Product attributes',
  'product categories':
    'Product categories',
  'product category':
    'Product category',
  'product comment': 'Product comment',
  'product description':
    'Product description',
  'product faq': 'Product FAQ',
  'product filter': 'Product filter',
  'product filter by':
    'Product fitter by',
  'product group': 'Product group',
  'product in the same auction':
    'Product in the same auction',
  'product in the same categories':
    'Product in the same categories',
  'product management':
    'Product management',
  'product name': 'Product name',
  'product price': 'Product price',
  'product priority':
    'Product priority',
  'product rating': 'Product ratings',
  'product reviews': 'Product reviews',
  'product reviews and ratings':
    'Product reviews and ratings',
  'product searching':
    'Product searching',
  'product type': 'Product type',
  products: 'Products',
  'products in same category':
    'Products in the same category',
  'products in same store':
    'Products in the same store',
  profile: 'Profile',
  'profile information':
    'Profile information',
  'program description':
    'Program description',
  'program filter': 'Program filter',
  'program name': 'Program name',
  'promote sharing connecting good things to help people everywhere be happy':
    'Promote sharing connecting good things to help people, everywhere be happy',
  promotion: 'Promotion',
  'promotion campaign name':
    'Promotion campaign name',
  'promotion campaigns':
    'Promotion campaigns',
  promotions: 'Promotions',
  'propose as owner':
    'requested as host',
  'proposed applicants':
    'Proposed applicants',
  'provide the most accurate address so people can find your store faster':
    'Provide the most accurate address so people can find your store faster',
  province: 'Province',
  public: 'Public',
  'public / private':
    'Public:* The auction is open to everyone / Private:* The auction is for members of the auction only',
  'public display': 'Public display',
  'public name': 'Public name',
  publictips:
    'Page is publicly visible to everyone. Anyone can see members and what they post',
  'publish reply': 'Publish reply',
  published: 'Published',
  publishedtips:
    'Content is complete and published, everyone can view, comment, share',
  publishrequest: 'Publish Request',
  publishrequesttips:
    'Content is being authenticated, you will receive an email notification within 24 hours',
  'quality education':
    'Quality Education',
  quantity: 'Quantity',
  question: 'Question',
  'question answer':
    'Questions & Answers',
  'question type': 'Question type',
  questions: 'Questions',
  'questions and answers':
    'Questions & Answers',
  quote: 'Quote',
  ranking: 'Ranking',
  'rate store': 'Rate store',
  'rate type': 'Rate type',
  rating: 'ratings',
  'rating from customers':
    'rating from customers',
  'rating successful':
    'Rating successful',
  'ready to ship': 'Ready to ship',
  readytopublish: 'Ready To Publish',
  readytopublishtips:
    'Content suitable, willing publicly to everyone',
  reasons: 'Reasons',
  received: 'received',
  'received unit': 'Received unit',
  receiver: 'receiver',
  'receiver information':
    'Receiver information',
  'recent searches': 'Recent searches',
  recommend: 'Recommend',
  'recommend activities':
    'Suggested activities',
  'recommend events':
    'Suggestted Events',
  'recommend groups':
    'Suggested groups',
  'recommend needs by following':
    'Suggested crowdfunding',
  'recommend store': 'Recommend store',
  'recommend tags': 'Recommend tags',
  'recommend users':
    'Suggested friends',
  'recommend beneficiaries':
    'Suggested beneficiaries',
  'recruitment for good':
    'recruitment for good',
  'recruitment question':
    'Recruitment question',
  recruitment_email:
    'Recruitment email',
  recruitment_phone:
    'Recruitment phone',
  red: 'Red',
  'reduced inequalities':
    'Reduced Inequalities',
  ReducedInequalities:
    'ReducedInequalities',
  reference: 'Reference',
  regconfirmpass: 'Password confirm',
  register: 'Register',
  'register enabled':
    'Register Enabled',
  'register to donate':
    'Register to Donate',
  'register value': 'Register value',
  'registered number':
    'Registered number',
  'registered to donate':
    'registered to donate',
  regpass: 'Password',
  regular: 'Regular',
  regulartips: 'Regular activity',
  reject: 'Reject',
  rejected: 'Rejected',
  'rejected applicants':
    'Rejected applicants',
  rejectedtips:
    'Content is not appropriate. Please check again and submit publishing request',
  'related tags': 'Related tags',
  religion: 'Religion',
  remain: 'Remain',
  remember: 'Keep me signed in',
  'remotely work': 'Remotely work',
  remove: 'Remove',
  'remove comment': 'Remove comment',
  'remove from your order':
    'Remove from your order',
  removed: 'Removed',
  replies: 'Replies',
  reply: 'Reply',
  'reply customer': 'Reply customer',
  'reply from the store':
    'Reply from the store',
  replyplaceholder: 'Enter reply...',
  report: 'Report',
  'report violation shop':
    'Report violation shop',
  reported: 'Reported',
  reports: 'Reports',
  request: 'Request',
  requested: 'sent request',
  required: 'Required',
  'required information fields':
    'Required information fields',
  requirements: 'Requirements',
  reset: 'Reset',
  'Reset your password':
    'Reset your password',
  responded: 'responded',
  'responsible consumption and production':
    'Responsible Consumption And Production',
  'results with keyword':
    'Results with keyword',
  'return conditions':
    'Return conditions',
  'return period': 'Return period',
  'return period type':
    'Return period type',
  'return period value':
    'Return period value',
  'return policy': 'Return policy',
  'return to draft': 'Return to Draft',
  returned: 'Returned',
  'retype new password':
    'Retype new password',
  'Retype password': 'Retype password',
  'review and rating on store':
    'Review and rating on store',
  'review management':
    'Review management',
  'review store': 'Review store',
  reviews: 'Reviews',
  role: 'Role',
  'role description':
    'Role description',
  rotate: 'Rotate',
  'running promotion campaigns':
    'Running promotion campaigns',
  salary: 'Salary',
  'salary period': 'Salary Period',
  sale: 'Sale',
  'sanitary food': 'Sanitary food',
  save: 'Save',
  'save data': 'Save data',
  'save question': 'Save question',
  scale: 'Scale',
  'scan code on Wishare for direct access to the target screen':
    'Scan the code on Wishare to redirect to the target screen',
  'scan qr-code': 'Scan QR-Code',
  schedule: 'Schedule',
  school: 'Shool',
  'School Book': 'School Book',
  sdg: 'SDGs',
  search: 'Search',
  'search activities':
    'Search activities',
  'search category': 'Search category',
  'search events': 'search events',
  'search groups': 'search groups',
  'search groups users activities you want to be hosts':
    'Search groups, users, activities you want to be hosts',
  'search groups,users,activities you want to be hosts':
    'Search groups,users,activities you want to be hosts',
  'search in wishop':
    'Search in Wishop...',
  'search members': 'Search members...',
  'search needs': 'search needs',
  'search jobs': 'Search Jobs',
  'search product to attach':
    'Search product to attach',
  'search selected category':
    'Search selected category',
  'search user': 'Search user',
  'search beneficiaries':
    'search wish...',
  searching: 'searching',
  'searching result is empty':
    'Searching result is empty',
  'searching volunteers':
    'Searching volunteers',
  searchplaceholder: 'search..',
  'seconds(s)': 'seconds(s)',
  secret: 'Secret',
  secrettips:
    'The page shows internal, official members can view members, share, post the message',
  'security policy': 'Security Policy',
  'see all': 'see all',
  'see less': 'show less',
  'see map': 'See map',
  'see more': 'See more',
  'see more jobs': 'See more jobs',
  'seek financial capital for good initiatives':
    'Seek financial capital for good initiatives',
  select: 'Select',
  'select all': 'Select all',
  'select attribute...':
    'Select attribute...',
  'select location': 'Select Location',
  'select location on map':
    'Select location on map',
  'select option': 'Select option',
  'select the payment method':
    'Select the payment method',
  'select the product catalog for your store':
    'Select the product catalog for your store',
  'select the topic category you are interested in by clicking on the items below':
    'Select the topic category you are interested in by clicking on the items below',
  select_conditions:
    'select conditions',
  select_conditions_placeholder:
    'select conditions',
  'Select...': 'Select...',
  'Sell as lot': 'Sell as lot',
  'selling price': 'Selling price',
  'send a message to <%= title %> with questions or comments about this volunteer opportunity.':
    'Send a message to <%= title %> with questions or comments about this volunteer opportunity.',
  'send feedback': 'Send Feedback',
  'send to': 'Send to',
  'send to an activity':
    'Send to an activity',
  sender: 'Sender',
  'senior advisor': 'senior advisor',
  sent: 'sent',
  sequence: 'Sequence',
  'sequence / parallel':
    'Sequence:* The products in the program will be auctioned each product in the order set. Only 1 single session at a time / Parallel:* Products in the same program will be auctioned at the same time in different sessions.',
  'set as default': 'Set as default',
  setting: 'Setting',
  settings: 'Settings',
  share: 'Share',
  'share options': 'share options',
  'share to multipal pages':
    'Share to multipal pages',
  'share to my page':
    'Share to my page',
  'share with everyone':
    'share with everyone',
  shared: 'shared',
  shipped: 'Shipped',
  shop: 'Shop',
  'shop description':
    'Shop description',
  'shop for you': 'shop for you',
  'shop infomation': 'Shop infomation',
  'shop product': 'Shop product',
  'shop products faq':
    'Shop products FAQ',
  shopping: 'Shopping',
  'shopping management':
    'Shopping management',
  short: 'Short',
  'should change status to publishrequest to complete':
    'should change status to APPROVE REQUEST to complete',
  show: 'show',
  'show map': 'Show map',
  'show profile': 'Show profile',
  'show result for': 'show result for',
  sign: 'Sign',
  'Sign in': 'Sign in',
  'sign in to connect':
    'Sign in to connect with the community',
  'Sign up': 'Sign up',
  signed: 'Signed',
  signin: 'Log In',
  'silent mode': 'Silent mode',
  single: 'Single',
  singletips: 'Single',
  sitemap: 'Sitemap',
  Size: 'Size',
  skills: 'Skills',
  'social activities exits everywhere, form individuals, NPOs or enterprises':
    'Social activities exits everywhere, form individuals, NPOs or enterprises',
  'social capital': 'Social \ncapital',
  'social worker': 'Social Worker',
  sold: 'Sold',
  'sold by': 'Sold by',
  'sold price': 'Sold price',
  'sold quantity': 'Sold quantity',
  'specific return policy':
    'Specific return policy',
  'specific warranty policy':
    'Specific warranty policy',
  specific_activity:
    'Specific Activity',
  specific_activity_name:
    'Specific activity name',
  specifications: 'Specifications',
  'sponsors for the project':
    'Sponsors for the project',
  sport: 'Sport',
  'sports & entertainment':
    'Sports & Entertainment',
  'Sports & Travel': 'Sports & Travel',
  star: 'star',
  stars: 'stars',
  start: 'Start',
  'start at': 'Start at',
  "start creating activities, events or groups to declare organizations seeking volunteers (if you've shared organizational information, go to step 3)":
    "Start creating activities, events or groups to declare organizations seeking volunteers (If you've shared organizational information, go to step 3)",
  'start date': 'Start date',
  start_at: 'Start at',
  start_date: 'Start date',
  start_time: 'Start time',
  status: 'Status',
  statusplaceholder: 'Select status',
  step: 'Step',
  'step 1': 'Step 1',
  'step 2': 'Step 2',
  'step 3': 'Step 3',
  'step 4': 'Step 4',
  'step 5': 'Step 5',
  'step 6': 'Step 6',
  'Steps to create an activity:':
    'Steps to create an activity:',
  'stop donate': 'stop donate',
  'store address': 'Store address',
  'store description':
    'Store description',
  'store manager': 'Store manager',
  'store name': 'Store name',
  'store setting': 'Store setting',
  'strategic partner':
    'strategic partner',
  'strategic sponsor':
    'strategic sponsor',
  'strengthen successful connections based on the':
    'Strengthen successful connections based on the',
  'sub total': 'Sub total',
  submit: 'Submit',
  'subscription email':
    'Subscription Email',
  subscription_email:
    'You can subscribe to email to receive new notifications from the system',
  success: 'Success',
  successful: 'Successful',
  support: 'Support',
  'Support the UN Global Goals':
    'Support the UN Global Goals',
  supported: 'supported',
  supporthelp: 'Support Center',
  supporthelptips:
    'Guide, Q&A, Help Center',
  'sustainable cities and communities':
    'Sustainable Cities And Communities',
  SustainableCitiesAndCommunities:
    'SustainableCitiesAndCommunities',
  'system error':
    'Sorry, there was a problem with the app',
  tag: 'Tag',
  tags: 'Tags',
  'tags for you': 'Tags for you',
  tagsplaceholder: 'Select tags',
  take: 'Take',
  target: 'Target',
  'teacher, teaching assistant':
    'Teacher, Teaching assistant',
  team: 'Team',
  'temporary winner':
    'Temporary winner',
  terms: 'Terms',
  'terms of use': 'Terms of Service',
  'thank you': 'Thank you',
  'thank you for registering to donate':
    'Thank you for registering to donate',
  'Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.':
    'Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.',
  'thank you for your feed back to product and shop':
    'Thank you for your feed back to product and shop',
  'the 17 goals':
    'THE 17 GOALS OF THE UNITED NATIONS',
  'the accept action was successful':
    'The accept action was successful',
  'the activation email has been sent to':
    'The activation email has been sent to',
  'the causes that we serve':
    'The causes that we serve',
  'the community creates social impact, does the good things and lets the wind blow away':
    'The community creates social impact, \ndoes the good things and lets the wind blow away',
  'The following topics are currently merged into this topic:':
    'The following topics are currently merged into this topic:',
  'The information given within the Feedback Form will be used for service improvement only and are strictly confidential.':
    'The information given within the Feedback Form will be used for service improvement only and are strictly confidential.',
  'the information has been saved successful':
    'The information has been saved successful',
  'the item has been saved':
    'The item has been saved',
  'the items you are you had accepted to be there hosts':
    'the items you are you had accepted to be there hosts',
  'the items you had been requested to be a host':
    'the items you had been requested to be a host',
  'the option has been added successful':
    'the option has been added successful',
  'The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.':
    'The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.',
  'the recruitment in the period':
    'The recruitment in the period',
  'the registration of funding for the project':
    'The registration of funding for the project',
  'the reject action was successful':
    'The reject action was successful',
  'the social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission':
    'The social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission',
  'the warning has been sent to admin':
    'The warning has been sent to admin',
  'there are items that are not shown due to the privacy policies of those items':
    'Some items are not displayed publicly due to privacy and security policies',
  'there is no need for transfer':
    'There is no need for transfer',
  'This function is currently not open to everyone, please contact the Wishare administrator':
    'This function is currently not open to everyone, please contact the Wishare administrator',
  'This page is collected and indexed by Wishare':
    'This page is collected and indexed by Wishare',
  'This page is collected and indexed by Wishare so that the community can easily find and contact the program':
    'This page is collected and indexed by Wishare so that the community can easily find and contact the program',
  'this product will extract':
    'This product will extract',
  'this will send by admin':
    'This content will be sent by admin',
  'tick size': 'Tick size',
  time: 'Time',
  'time and location':
    'Time & Location',
  'time characteristic':
    'Time characteristic',
  'time open': 'Time open',
  time_close: 'End time',
  time_open: 'Start time',
  time_type: 'Time type',
  timeline: 'Timeline',
  title: 'Title',
  titleplaceholder: 'Write title here.',
  to: 'to',
  'to develop the community or':
    'to develop the community or',
  'to donate': 'to donate',
  'To help beneficiaries find your activity, create your activity on Wishare platform.':
    'To help beneficiaries find your activity, create your activity on Wishare platform.',
  'To help beneficiaries find your need, create your activity on Wishare platform.':
    'To help beneficiaries find your need, create your activity on Wishare platform.',
  'to help us continue serving the community':
    'to help us continue serving the community',
  'to now': 'to now',
  'to support us spread the goodness':
    'to support us spread the goodness',
  token_expired:
    'The session is invalid or has expired. Please login again',
  top: 'Top',
  total: 'Total',
  'total donation': 'Total donation',
  'total donation values':
    'Total donation values',
  'total payment': 'Total payment',
  'total product amount':
    'Total product amount',
  'total values': 'Total values',
  total_activities: 'Activities',
  total_activities_official:
    'Activities (official)',
  total_activities_official_tips:
    'Activities are created and operated by the community',
  total_activities_tips:
    'Social activities are created (or indexed by Wishare) and updated to the community',
  total_address: 'Linked to',
  total_crowdfunding_amount:
    'Crowdfunding',
  total_crowdfunding_amount_tips:
    'Raise funds for activities and social work',
  total_crowdfundings: 'Crowdfunding',
  total_crowdfundings_tips:
    'Crowdfunding for social and creative purpose',
  total_donators: 'Total of donators',
  total_events: 'Events',
  total_events_happening:
    'Ongoing events',
  total_events_happening_tips:
    'Events have been happening at the present time',
  total_events_incoming:
    'Incoming Events',
  total_events_incoming_tips:
    'Events launched in the future',
  total_events_tips:
    'All events has been and is going to happen',
  total_followers: 'Followers',
  total_following: 'Following',
  total_groups: 'Groups',
  total_links: 'links',
  total_links_tips:
    'Number of links, connecting from aspirations to support activities',
  total_members: 'Members',
  total_opportunities: 'Jobs',
  total_opportunities_tips:
    'For volunteer and social impact jobs',
  total_shares: 'Shares',
  total_support: 'Support',
  total_views: 'total views',
  total_volunteers: 'Volunteer',
  total_volunteers_tips:
    'Connect people in need and receive human resources, promote and optimize social work',
  total_votes: 'Votes',
  total_beneficiaries: 'Beneficiaries',
  total_beneficiaries_done:
    'Finished beneficiaries',
  total_beneficiaries_done_tips:
    'The beneficiaries were successful',
  total_beneficiaries_tips:
    'All beneficiaries are sent on Wishare',
  trademark: 'Trademark',
  transfer: 'Transfer',
  'transfer all': 'Transfer all',
  trends: 'Trends',
  'trust and safety':
    'Trust and Safety',
  'trustworthy efficient transparent':
    'trustworthy, efficient, transparent',
  tutorial: 'Tutorial',
  type: 'Type',
  'type new password':
    'Type new password',
  'type of group': 'Type of group',
  'type of work': 'Type of work',
  'type old password':
    'Type old password',
  'type to search': 'Type to search',
  type_association: 'Association',
  type_associationtips: 'Association',
  type_company: 'Company',
  type_companytips: 'Company',
  type_foundation: 'Foundation',
  type_foundationtips: 'Foundation',
  type_group: 'Group',
  type_grouptips: 'Group',
  type_ngo:
    'NGO - Non Goverment Organization',
  type_ngotips:
    'NGO - Non Goverment Organization',
  type_npo:
    'NPO - Non profit Organization',
  type_npotips:
    'NPO - Non profit Organization',
  type_organization: 'Organization',
  type_organizationtips: 'Organization',
  type_religion: 'Religion',
  type_religiontips: 'Religion',
  type_school: 'School',
  type_schooltips: 'School',
  'typical products':
    'Typical products',
  'typical shops': 'Typical Stores',
  typing: 'Typing',
  undefined: 'Undefined',
  undefinedtips: 'Undefined',
  unfilter: 'Unfilter',
  unfollow: 'unfollow',
  unit: 'Unit',
  unlimited: 'Unlimited',
  'unlimited age': 'all ages',
  unread: 'Unread',
  update: 'Update',
  'update your profile':
    'Update your profile',
  'upload images from your computer':
    'Upload images from your computer',
  user: 'User',
  'user product faq':
    'User product FAQ',
  'user profile': 'User Profile',
  'user settings': 'User settings',
  user_not_found:
    'The login session has expired or the account is invalid, please login again',
  username: 'Username',
  users: 'Users',
  value: 'Value',
  'value type': 'Value type',
  value_winner: 'Value winner',
  variants: 'Product variants',
  verify: 'Verify',
  verifying: 'Verifying',
  'view more': 'View more',
  'view profile': 'View profile',
  vision: 'Vision',
  'Visually impaired people':
    'Visually impaired people',
  volunteer: 'Volunteer',
  'volunteer & jobs':
    'Volunteer & jobs',
  volunteer_profile:
    'Volunteer profiles',
  volunteers: 'Volunteers',
  vote: 'Vote',
  voted: 'Voted',
  wage: 'Wage',
  'wait for reply': 'Wait for reply',
  waiting: 'Waiting',
  'waiting applicants':
    'Waiting applicants',
  'waiting need': 'Running',
  'want to help': 'want to help',
  ward: 'Ward',
  warned: 'warned',
  'warranty & return':
    'Warranty & Return',
  'warranty conditions':
    'Warranty conditions',
  'warranty period': 'Warranty period',
  'warranty period type':
    'Warranty period type',
  'warranty period value':
    'Warranty period value',
  'warranty policy': 'Warranty policy',
  'warranty-policies':
    'Warranty-policies',
  'warranty-return':
    'Warranty & Return',
  'was accepted for job at':
    'was accepted for job at',
  'we believe that sharing and connecting these beneficiaries will create a great impact':
    'We believe that sharing and connecting these beneficiaries will create a great impact',
  'We welcome any feedback, please feel free to contact us':
    'We welcome any feedback, please feel free to contact us',
  "we've": 'We have',
  "we've found <%= number %> results":
    "We've found <%= number %> results",
  website: 'Website',
  website_placeholer:
    '(www.example.com)',
  week: 'Week',
  weeks: 'Weeks',
  weight: 'Weight',
  'welcome to become wishop salesperson':
    "Welcome to become Wishop's salesperson",
  'what categories are your interests?':
    'What categories are your interests?',
  'what is your position?':
    'What is your position?',
  'what we connect': 'What we connect',
  'what you can do?':
    'What you can do?',
  'what you need the crowdfunding for':
    'What you need the crowdfunding for',
  'what you need volunteers for':
    'What you need volunteers for',
  'when you have a petition for you or for someone':
    'When you have a petition for you or for someone',
  'where did you work?':
    'Where did you work?',
  'where does the opportunity take place?':
    'Where does the Opportunity take place?',
  'which school did you study?':
    'Which school did you study?',
  'who can do for good and make great impact by sharing human capital, financial capital to realise the social mision':
    'Who can do for good and make great impact by sharing human capital, financial capital to realise the social mision',
  'why partner with wishare ?':
    'Why partner with wishare ?',
  Width: 'Width',
  'will end in': 'will end in',
  'win price': 'Win price',
  win_price: 'Win price',
  winner: 'Winner',
  beneficiary: 'Beneficiary',
  'beneficiary list': 'beneficiaries',
  'wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people':
    'Wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people',
  'Wishare is willing to adjust the content or transfer the page owner right to the program upon request.':
    'Wishare is willing to adjust the content or transfer the page owner right to the program upon request.',
  beneficiaries: 'Beneficiaries',
  'beneficiaries for good':
    'beneficiaries for good',
  'wishop regulation':
    "Wishop's regulation",
  beneficiarytips:
    'beneficiaries are the good things you desire to do. You can share your beneficiaries to your friends, to activities aimed at mobilizing support from everyone.',
  with: 'with',
  'with keyword': 'with keyword',
  'won session': 'Won session',
  work: 'Work',
  'work fit for': 'Work fit for',
  'working date': 'Working date',
  'working time': 'Working time',
  workplace: 'Workplace',
  'write comment about store':
    'Write comment about store',
  year: 'Year',
  years: 'years',
  yes: 'Yes',
  'yes / no': 'Yes / No',
  you: 'You',
  'you are the highest bidder':
    'You are the highest bidder',
  'you can add or change information in the settings':
    'You can add or change information in the settings.',
  "you don't need login to do for good in this platform":
    "You don't need login to do for good in this platform",
  'you have 0 new message':
    'You have 0 new message',
  'you have completed the registration steps please confirm the information again to finish':
    'You have completed the registration steps, please confirm the information again to finish',
  'You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.':
    'You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.',
  'you should use a photo to help people recognize your store faster':
    'You should use a photo to help people recognize your store faster',
  "Create quickly in steps if you don't have your own activities and groups yet":
    "Create quickly in steps if you don't have your own activities and groups yet",
  'you voted it': 'You voted it',
  'you want to share or to implement your good initiative to the community':
    'You want to share or to implement your good initiative to the community',
  "You're welcome to be":
    "You're welcome to be",
  'your activities': 'Your activities',
  'your auction name':
    'Your auction name',
  'your bid': 'Your bid',
  'your cart currently has <%= number %> pending payment, you want to continue shopping now.':
    'Your cart currently has <%= number %> pending payment, you want to continue shopping now.',
  'your cart currently has <%= number %> products, you want to continue shopping now.':
    'Your cart currently has <%= number %> products, you want to continue shopping now.',
  'your categories': 'Your Categories',
  'your comment': 'Your comment',
  'your comment about store':
    'Your comment about store',
  'your friend': 'Your friends',
  'your group': 'Your group',
  'your order status will be update in':
    'Your order status will be update in',
  'your order will be transfer as soon as possible':
    'Your order will be transfer as soon as possible',
  'your orders': 'Your orders',
  'your pending amount':
    'Your register value',
  'your profile': 'Your profile',
  'your profile has been successfully submitted to the employer!':
    'Your profile has been successfully submitted to the employer!',
  'your store name': 'Your store name',
  'zero hunger': 'Zero Hunger',
  zones: 'Zones',
  zones_placeholder: 'Supported zones',
  give: 'Give',
  receive: 'Receive',
  'notes on the order':
    'Notes on the order',
  'closed status': 'Closed Status',
  'close status': 'Close status',
  'blocked status': 'Blocked status',
  'block status': 'Block status',
  'This content has completed the process, closing the status marks that you are no longer working on this content.':
    'This content has completed the process, closing the status marks that you are no longer working on this content.',
  'This content is required to blockade, Sessions on this content will be blocked from now.':
    'This content is required to blockade, Sessions on this content will be blocked from now.',
  'closed statustips':
    'This content has completed the process, closing the status marks that you are no longer working on this content.',
  'Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you':
    'Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you',
  "let's start for free":
    "Let's start for free",
  'delivery method': 'Delivery method',
  'Please choose a delivery method':
    'Please choose a delivery method',
  cancel: 'Cancel',
  'Click here to create first contact':
    'Click here to create first contact',
  'Enter the email address associated with your account, and we will email you a link to reset your password.':
    'Enter the email address associated with your account, and we will email you a link to reset your password.',
  write: 'write',
  'copy link': 'Copy link',
  'share link via messenger':
    'Share link via messenger',
  'share on wishare':
    'Share on Wishare',
  'social network': 'Social network',
  "Don't have a volunteer for this activity or event yet?":
    "Don't have a volunteer for this activity or event yet?",
  'Tap the "{1}" button to complete and receive the Donation Code':
    'Tap the "{1}" button to complete and receive the Donation Code',
  'share link via QR Code':
    'Share link via QR Code',
  'User use phone cameras to quickly access this link':
    'User use phone cameras to quickly access this link',
  'Below is an incomplete or unconfirmed registration by a fundraising campaign manager.':
    'Below is an incomplete or unconfirmed registration by a fundraising campaign manager.',
  'Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.':
    'Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.',
  'delete register of donation':
    'Delete register of donation',
  'are you sure to remove this donation?':
    'Are you sure to remove this donation?',
  'approve donation':
    'Approve donation',
  'contact phone': 'Contact phone',
  less: 'Less',
  'your donation': 'Your donation',
  'This view screen is for the admin of the campaign':
    'This view screen is for the admin of the campaign',
  'On behalf of the fundraising campaign, we would like to sincerely thank our sponsors':
    'On behalf of the fundraising campaign, we would like to sincerely thank our sponsors',
  'Select and tap on the package to which you can donate':
    'Select and tap on the package to which you can donate',
  'Enter another value you want to donate':
    'Enter another value you want to donate',
  'search with': 'Search with',
  'for admins': 'for Admins',
  'your name': 'Your name',
  'contact email': 'Contact Email',
  toward: 'Toward',
  'giving code': 'Giving code',
  'delete giving': 'Delete giving',
  'This giving will be remove?':
    'This giving will be remove?',
  'beneficiary account no':
    'Beneficiary Account No',
  'beneficiary account name':
    'Beneficiary Account Name',
  'transaction memo':
    'Transaction Memo',
  'beneficiary bank name':
    'Beneficiary Bank Name',
  'beneficiary info':
    'Beneficiary Info',
  'This item will be deleted and cannot be recovered':
    'This item will be deleted and cannot be recovered',
  'Value on conversion for items: (quantity) / (Unit)':
    'Value on conversion for items: (quantity) / (Unit)',
  'donate goods equivalent to':
    'donate goods equivalent to',
  'donation type': 'Donation Type',
  item: 'Item',
  'registered to goods equivalent to':
    'registered to donate goods equivalent to',
  cash: 'Cash',
  goods: 'Goods',
  'cash donations': 'cash donations',
  'goods donations': 'goods donations',
  'It is more blessed to give than to receive.':
    'It is more blessed to give than to receive.',
  '(Acts 20:35).': '(Acts 20:35).',
  'Holy Bible': 'Holy Bible',
  'A dream you dream alone is only a dream. A dream you dream together is reality.':
    'A dream you dream alone is only a dream. A dream you dream together is reality.',
  'gave away': 'gave away',
  'giving type': 'Giving type',
  'giving value': 'Giving value',
  'with an goods equivalent of':
    'with an goods equivalent of',
  'with a cash amount of':
    'with a cash amount of',
  'Are you sure you agreed to accept this person for the recruitment?':
    'Are you sure you agreed to accept this person for the recruitment?',
  'Are you sure you will reject this application for recruitment?':
    'Are you sure you will reject this application for recruitment?',
  'Time to be able to do volunteer work':
    'Time to be able to do volunteer work',
  'add time to be able to volunteer':
    'Add volunteer time',
  'Kind of work what you like':
    'Kind of work what you like',
  'The workplace (ward/district/province/country) that is right for you':
    'The workplace (ward/district/province/country) that is right for you',
  'you want': 'You want',
  anything: 'Anything',
  anywhere: 'Anywhere',
  'learn about tools':
    'Learn about tools',
  'create an activity for free':
    'Create an activity for free',
  'how does it work':
    'How does it work',
  'petition & connect':
    'Petition & connect',
  petition: 'Petition',
  recruitment: 'Recruitment',
  sales: 'Sales',
  inputs: 'Inputs',
  impacts: 'Impacts',
  'outputs/outcome': 'Outputs/Outcome',
  'fundraising, donation activity':
    'Fundraising, Donation activity',
  'recruitment, search for volunteer jobs':
    'Recruitment, search for volunteer jobs',
  'sales, create financial resources for social activities':
    'Sales, create financial resources for social activities',
  'auction, create financial resources for social activities':
    'Auction, create financial resources for social activities',
  'petition, connect social activities':
    'Petition, connect social activities',
  community: 'Community',
  '0$ - free services':
    '0$ - Free services',
  'serve the community':
    'serve the community',
  'donate - sponsor':
    'Donate - Sponsor',
  'keep us running': 'keep us running',
  'tools toward impact':
    'Tools toward impact',
  'Support {1} campaign management tools to create impact according to the model of “{2}”.':
    'Support {1} campaign management tools to create impact according to the model of “{2}”.',
  'organization - social activities':
    'Organization - Social activities',
  'theory of change':
    'Theory of change',
  'Strategic planning':
    'Strategic planning',
  effects: 'Effects',
  '17 SDG':
    'The 17 Sustainable Development Goals',
  'create your account':
    'Create your account',
  'launch your activity':
    'Launch your activity',
  'A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.':
    'A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.',
  'launch your campaign':
    'Launch your campaign',
  'Manage your recruitment, crowdfunding, auction, selling, events and so on.':
    'Manage your recruitment, crowdfunding, auction, selling, events and so on.',
  'An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.':
    'An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.',
  commitment: 'Commitment',
  'Our solution is constantly improving, but some things will never change':
    'Our solution is constantly improving, but some things will never change',
  'Totally free, with no fees or commission':
    'Totally free, with no fees or commission',
  '0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.':
    '0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.',
  'Continuous Improvement':
    'Continuous Improvement',
  'Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.':
    'Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.',
  'Free business model':
    'Free business model',
  'Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.':
    'Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.',
  'Campaign on Wishare':
    'Campaign on Wishare',
  'Wishare and Partners':
    'Wishare and Partners',
  'SDG & Category': 'SDG & Category',
  'totally free': 'Totally free',
  'now and forever.':
    'Now and forever.',
  'toward impacts': 'Toward impacts',
  'our partners': 'Our partners',
  'Fundraising and charity management platform.':
    'Fundraising and charity management platform.',
  'Free and transparent.':
    'Free and transparent.',
  mark: 'Mark',
  givings: 'Givings',
  'cash givings': 'Cash givings',
  'goods givings': 'Goods givings',
  'impact categories':
    'Impact categories',
  'Values are summed up from the organization and its activities.':
    'Values are summed up from the organization and its activities.',
  'co-hosts': 'Co-Hosts',
  'Use for: blood donation, ... form of donation calculated on each donation':
    'Use for: blood donation, ... form of donation calculated on each donation',
  'Use for: donate money, donate goods, ... Contributions can be converted to monetary value':
    'Use for: donate money, donate goods, ... Contributions can be converted to monetary value',
  'donation by count':
    'Donation by count',
  'donation by value':
    'Donation by value',
  'blood unit': 'Blood Unit',
  blood: 'Blood',
  'You register to contribute':
    'You register to contribute',
  'The organizers will contact you in case of needed. Please add some information':
    'The organizers will contact you in case of needed. Please add some information',
  'contact information':
    'Contact information',
  'impacts report': 'Impacts report',
  'my givings': 'My Givings',
  'participate in volunteering':
    'Participate in volunteering',
  'pending donations':
    'Pending donations',
  'create crowdfunding':
    'Create crowdfunding',
  'what donation?': 'What donation?',
  'Choose the right donation type for your campaign':
    'Choose the right donation type for your campaign',
  'Money/Goods... can allow donations. (If target is unlimited, please enter value "0")':
    'Money/Goods... can allow donations. (If target is unlimited, please enter value "0")',
  '..., which and how much?':
    '..., which and how much?',
  'Choose a campaign creator/initiator for the campaign':
    'Choose a campaign creator/initiator for the campaign',
  '..., and whose?': '..., and whose?',
  'Choose the impacts that the campaign towards':
    'Choose the impacts that the campaign towards',
  'Write a more descriptive description of your campaign information, which helps the community better understand your campaign.':
    'Write a more descriptive description of your campaign information, which helps the community better understand your campaign.',
  '..., donation information and when?':
    '..., donation information and when?',
  'Donation information, Estimated start and end time of the campaign':
    'Donation information, Estimated start and end time of the campaign',
  'Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section':
    'Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section',
  'Leave the value 0 if you do not want a specific goal':
    'Leave the value 0 if you do not want a specific goal',
  finance: 'Finance',
  complete: 'Complete',
  'another donation options':
    'Another donation options',
  'pending list': 'Pending list',
  type_community: 'Community',
  'edit crowdfunding':
    'Edit crowdfunding',
  'est. value': 'Est. value',
  volume: 'Volume',
  Rice: 'Rice',
  Food: 'Food',
  Pepper: 'Pepper',
  'Fish sauce': 'Fish sauce',
  Bottle: 'Bottle',
  Box: 'Box',
  'Instant Noodles': 'Instant Noodles',
  'to beneficiary': 'To beneficiary',
  'Successfully transferred to {1} the giving':
    'Successfully transferred to {1} the giving',
  'activities management':
    'Activities Management',
  'ready for volunteering':
    'Ready for volunteering',
  'your curriculum vitae':
    'Your Curriculum Vitae',
  'link to your profile on another site':
    'Link to your profile on another site',
  'Money/Goods... can be offer. (If target is unlimited, please enter value "0")':
    'Money/Goods... can be offer. (If target is unlimited, please enter value "0")',
  targets: 'Targets',
  'another giving options':
    'Another giving options',
  'for activities that make an impact on the community':
    'for activities that make an impact on the community',
  'search crowdfunding':
    'Search crowdfunding',
  'search giving': 'Search giving',
  'what offer?': 'What giving?',
  'Choose the right offer type for your campaign':
    'Choose the right offer type for your campaign',
  '..., offer information and when?':
    '..., offer information and when?',
  'Offer information, Estimated start and end time of the campaign':
    'Offer information, Estimated start and end time of the campaign',
  '..., and impact categories?':
    '..., and impact categories?',
  'profile link': 'Profile link',
  outputs: 'Outputs',
  outcome: 'Outcome',
  'volunteer job': 'Volunteer job',
  visible: 'Visible',
  invisible: 'Invisible',
  ready: 'Ready',
  'not ready': 'Not ready',
  'link to the giving':
    'Link to the giving',
  'donation guide': 'Donation Guide',
  tank: 'tank',
  'link to the donation':
    'Link to the donation',
  SGD: 'Dollar Singapore',
  Vegetables: 'Vegetables',
  'Oxygen tank': 'Oxygen tank',
  Diapers: 'Diapers',
  Bike: 'Bike',
  Milk: 'Milk',
  Mask: 'Mask',
  USD: 'USD',
  EUR: 'EUR',
  'crowdfunding campaign':
    'Crowdfunding Campaign',
  'giving campaign': 'Giving Campaign',
  'financial balance':
    'Financial Balance',
  'recruitment campaign':
    'Recruitment Campaign',
  'for the output of impact activities in the community':
    'for the output of impact activities in the community',
  'make a giving': 'make a giving',
  'job - volunteer': 'Job - Volunteer',
  features: 'Features',
  'Recruit unlimited volunteers':
    'Recruit unlimited volunteers',
  'You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.':
    'You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.',
  'Manage your volunteers':
    'Manage your volunteers',
  "Once your volunteers start coming in, you'll be able to organize, filter and keep track of them.":
    "Once your volunteers start coming in, you'll be able to organize, filter and keep track of them.",
  'Upload and Download your list':
    'Upload and Download your list',
  "Start out by uploading your own volunteer list. Don't worry, you can download your entire list of volunteers anytime.":
    "Start out by uploading your own volunteer list. Don't worry, you can download your entire list of volunteers anytime.",
  'Volunteer Analytics':
    'Volunteer Analytics',
  'Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.':
    'Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.',
  'Show unlimited giving':
    'Show unlimited giving',
  'You can post as many giving as you like, and there is no limit on how many giving you give.':
    'You can post as many giving as you like, and there is no limit on how many giving you give.',
  'Manage your Giving (Money and Goods)':
    'Manage your Giving (Money and Goods)',
  "Once your giving start, you'll be able to organize, filter and keep track of them.":
    "Once your giving start, you'll be able to organize, filter and keep track of them.",
  "Start out by uploading your own giving list. Don't worry, you can download your entire list of giving anytime.":
    "Start out by uploading your own giving list. Don't worry, you can download your entire list of giving anytime.",
  'Giving Analytics':
    'Giving Analytics',
  'Your dashboard is your Giving. Look no further.':
    'Your dashboard is your Giving. Look no further.',
  'donation - crowdfunding':
    'Donation - Crowdfunding',
  'Register unlimited donation':
    'Register unlimited donation',
  'You can post as many donation as you like, and there is no limit on how many donation you receive.':
    'You can post as many donation as you like, and there is no limit on how many donation you receive.',
  'Manage your Donations (Money and Goods)':
    'Manage your Donations (Money and Goods)',
  "Once your donation start coming in, you'll be able to organize, filter and keep track of them.":
    "Once your donation start coming in, you'll be able to organize, filter and keep track of them.",
  "Start out by uploading your own donation list. Don't worry, you can download your entire list of donor and donation anytime.":
    "Start out by uploading your own donation list. Don't worry, you can download your entire list of donor and donation anytime.",
  'Donation Analytics':
    'Donation Analytics',
  'Your dashboard is your donation. How many people are actively donors? Look no further.':
    'Your dashboard is your donation. How many people are actively donors? Look no further.',
  'stop subscribe': 'Stop subscribe',
  subscribe: 'Subscribe',
  'giving value must be large than 0':
    'Giving value must be large than 0',
  'The object of the giving campaign':
    'The object of the giving campaign',
  'the option has been removed successful':
    'The option has been removed successful',
  'the option has been edited successful':
    'The option has been edited successful',
  'click here to add new giving package':
    'Click here to add new giving package',
  'Enter another value you want to giving':
    'Enter another value you want to giving',
  'Tap to sign up for the package you want to receive':
    'Tap to sign up for the package you want to receive',
  'Tap the "{1}" button to complete and receive the giving Code':
    'Tap the "{1}" button to complete and receive the giving Code',
  recipient: 'Recipient',
  'You are registered to get':
    'You are registered to get',
  'registered to get':
    'registered to get',
  'Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page':
    'Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page',
  'your subscription':
    'Your subscription',
  'delete register of giving':
    'Delete register of giving',
  'are you sure to remove this giving?':
    'Are you sure to remove this giving?',
  'remove this giving':
    'Remove this giving',
  'approve giving': 'Approve giving',
  '{1} registered to get':
    '{1} registered to get',
  'short answer': 'Short answer',
  'long answer': 'Long answer',
  'answer guide': 'Answer guide',
  'mandatory question':
    'Mandatory question',
  'answer type': 'Answer type',
  'answer options': 'Answer options',
  'add more question':
    'Add more question',
  'add option': 'Add option',
  'optional question':
    'Optional question',
  'additional questions':
    'Additional questions',
  'total target': 'Total target',
  'additional donor information':
    'Additional Donor Information',
  medal: 'Medal',
  'Send a thank you email to donor':
    'Send a thank you email to donor',
  'send thank you email':
    'Send thank you email',
  'Deleting this content will not allow recovery, do you want to continue?':
    'Deleting this content will not allow recovery, do you want to continue?',
  introduction: 'Introduction',
  'decent work and economic growth':
    'Decent work and Economic growth',
  'Please answer the following questions to complete registration':
    'Please answer the following questions to complete registration',
  'registration questions':
    'Registration questions',
  'change donation status to pending approval':
    'Change donation status to pending approval',
  'accept application':
    'Accept application',
  'reject application':
    'Reject application',
  'create new': 'Create new',
  hour: 'hours',
  timesheet: 'Timesheet',
  timesheets: 'Timesheets',
  task: 'Task',
  'send email notifications and reminders to complete donation':
    'Send email notifications and reminders to complete donation',
  duration: 'Duration',
  'A donation confirmation email has been sent to you via the email address you entered.':
    'A donation confirmation email has been sent to you via the email address you entered.',
  'blood volume': 'Blood volume',
  'campaign overview':
    'Campaign overview',
  'donation campaign':
    'Donation campaign',
  'targets of the campaign':
    'Targets of the campaign',
  'impacts towards': 'Impacts towards',
  'impact summary report':
    'Impact Summary Report',
  'graph of donation / giving':
    'Graph of donation / giving',
  'goals to be achieved':
    'Goals to be achieved',
  'the remaining target of the campaign':
    'The remaining target of the campaign',
  'original goal': 'Original goal',
  achieved: 'Achieved',
  'campaign needs more':
    'Campaign needs more',
  'details over time':
    'Details over time',
  'donation date': 'Donation Date',
  'donor name': 'Donor name',
  'unit name': 'Unit name',
  'giving date': 'Giving Date',
  'donation campaign report':
    'Donation campaign report',
  'this report is only for campaign admins':
    'This report is only for campaign admins',
  'list of work done by volunteers':
    'List of work done by volunteers',
  'Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.':
    'Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.',
  'application file':
    'Application File',
  'profile management':
    'Profile management',
  'email has been successfully sent!':
    'Email has been successfully sent!',
  'Are you sure?': 'Are you sure?',
  closedgrouptips: 'Closed Group',
  'donate blood': 'Donate Blood',
  'register to donate blood':
    'Register to donate blood',
  organizations: 'Organizations',
  'no limit': 'No limit',
  expiration: 'Expiration',
  'my bookmarks': 'My bookmarks',
  'suggested channels':
    'Suggested channels',
  shortcuts: 'Your shortcuts',
  contribution: 'Your contribution',
  grateful: 'Grateful',
  code: 'Code',
  'you have registered to donate':
    'You have registered to donate',
  'administrators panel':
    'Administrators panel',
  'beneficiary information':
    'Beneficiary information',
  'account number': 'Account number',
  'bank name': 'Bank name',
  'transfer contents':
    'Transfer contents',
  'Successfully transferred to':
    'Successfully transferred to',
  'a gift': 'a gift',
  'you register to receive gifts':
    'You register to receive gifts',
  'your register': 'Your register',
  requirement: 'Requirement',
  'time location': 'Time & Location',
  'the target of the activity':
    'The target of the activity',
  'validation error':
    'Validation error',
  'advanced settings':
    'Advanced settings',
  'go to setting': 'Go to setting',
  'content is filtered by':
    'Content is filtered by',
  congratulation: 'Congratulation',
  'You have completed the steps of creating activity':
    'You have completed the steps of creating activity',
  'Hope your activity will be successful and help many people':
    'Hope your activity will be successful and help many people',
  attention: 'Attention',
  'note create description':
    'You need to go to the "Settings" section to add more details. Do you want to go to the "Settings" section right here?',
  'note target description':
    'The main goal is the goal that the entire campaign needs to achieve, and you can break it down into different sub-goals. To do that go to the "Settings" section to add after you complete the rest of the steps.',
  'open/close': 'Duration',
  registrable: 'Registrable',
  'You have completed the steps to create a campaign':
    'You have completed the steps to create a campaign',
  'Hope your campaign will be successful and help many people':
    'Hope your campaign will be successful and help many people',
  'create recruitment':
    'Create recruitment',
  'recruitment type':
    'Recruitment type',
  job: 'Job',
  'work time': 'Work time',
  'full time': 'Full time',
  'part time': 'Part time',
  remote: 'Remote',
  position_title: 'Position',
  donation_event: 'Donation',
  giving_event: 'Giving',
  recruitment_event: 'Recruitment',
  '{n} categories': '{n} categories',
  '{n} languages': '{n} languages',
  '{n} provider(s)':
    '{n} organization(s)',
  '1 year': 'Yearly',
  '1D': 'Daily',
  '1M': 'Monthly',
  '1Y': '1 year',
  '2 years': '2 years',
  '2Y': '2 years',
  '3 months': '3 months',
  '3M': '3 months',
  '6 months': '6 months',
  '6M': '6 months',
  'about FeedIn': 'About Feedin',
  'about-us': 'About Us',
  'accept invitation':
    'Accept invitation',
  'accepted as member':
    'accepted as member',
  'access denied': 'Access denied',
  'account settings':
    'Account Settings',
  'active subscription':
    'Active subscription',
  'add chart': 'Add chart',
  'add image': 'Add Image',
  'add link': 'Add Link',
  'add map': 'Add map',
  'add money to wallet':
    'Add money to wallet',
  'Add new and manage members here {here}':
    'Add new and manage members here {here}',
  'add new answer': 'Add new answer',
  'add new contact': 'Add new contact',
  'add new location':
    'Add new location',
  'add payment to keep using {subscription} or change back to free':
    'Add payment to keep using {subscription} or change back to free',
  'add payment to keep using or change back to free':
    'Add payment to keep using or change back to free',
  'add photo': 'Add photo',
  'add price': 'Add price',
  'Add product photos':
    'Add product photos',
  'add subscriber': 'Add Subscriber',
  'Add Tax Number': 'Add Tax Number',
  'Add to Home Screen':
    'Add to Home Screen',
  'add video': 'Add video',
  'adding content to the channel is limited due to its policies':
    'Adding content to the channel is limited due to its policies',
  'additional details':
    'additional details',
  'admin description':
    'Administrator role',
  'advanced filter': 'Advanced Filter',
  'After select, click Save to finish':
    'After select, click Save to finish',
  'after your trial ends':
    'After your trial ends',
  'all categories': 'All Categories',
  'all dataset': 'All dataset',
  'all products': 'All products',
  'all vietnam': 'All in Vietnam',
  'allow add new answers':
    'Allow add new answers',
  'Allow channel content to be found through search or suggestion':
    'Allow channel content to be found through search or suggestion',
  'Allow users to find your channel via search function.':
    'Allow users to find your channel via search function.',
  'allowed be found':
    'Allowed be found',
  'Alternate Email': 'Alternate Email',
  'Alternate Mobile No.':
    'Alternate Mobile No.',
  'Alternate Phone Number':
    'Alternate Phone Number',
  'alternative phone':
    'Alternative Phone',
  'Annual Turnover': 'Annual Turnover',
  another: 'Another',
  answers: 'Answers',
  'Anyone can view the content of the data. Only members can post articles and comment freely on this':
    'Anyone can view the content of the data. Only members can post articles and comment freely on this',
  'Are you sure delete this article?':
    'Are you sure delete this article?',
  'Are you sure delete this chart?':
    'Are you sure delete this chart?',
  'Are you sure delete this poll?':
    'Are you sure delete this poll?',
  'Are you sure delete this task?':
    'Are you sure delete this task?',
  'Are you sure delete this template?':
    'Are you sure delete this template?',
  article: 'Article',
  articles: 'Articles',
  'ask for the best deal':
    'For the best deal',
  'automatically register with Google, Facebook... account':
    'Automatically register with Google, Facebook... account',
  'available balance':
    'Available balance',
  'Back Home': 'Back Home',
  'back to account': 'Back to account',
  ban: 'Ban',
  banned: 'Banned',
  'banned. can not post any':
    'Banned. Can not post any',
  bans: 'Bans',
  Basic: 'Basic',
  'basic info': 'Basic Info',
  'because you are looking for {keyword}':
    'Because you are looking for {keyword}',
  'Become a provider?':
    'Become an organization ?',
  'Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service':
    'Become a verified seller and get {check1} Higher Listing on Feedin {check2} More Buyer Enquiries {check3} Preferred Number Service',
  'bill estimate': 'Bill estimate',
  billing: 'Billing',
  'billing address': 'Billing address',
  'billing contact': 'Billing contact',
  'billing details': 'Billing Details',
  'billing history': 'Billing history',
  'billing information':
    'Billing information',
  'billing overview':
    'Billing overview',
  'billing period': 'Billing period',
  birthday: 'Birthday',
  bookmark: 'Bookmark',
  'bookmark all': 'Bookmark all',
  bookmarked: 'Bookmarked',
  'brief introduction to yourself':
    'Brief introduction to yourself',
  'bulk price update':
    'Bulk Price Update',
  bulletin: 'Bulletin',
  'by categories': 'By categories',
  'by clicking Submit, you agree to the policy of FeedIn':
    'By clicking Submit, you agree to the policy of FeedIn',
  'By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.':
    'By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.',
  'By clicking Subscribe, you agree to {term1} and {term2}.':
    'By clicking Subscribe, you agree to {term1} and {term2}.',
  'by making a channel private, only members will have access to this channel':
    'By making a channel private, only members will have access to this channel',
  'by using FeedIn, you agree to our':
    'By using FeedIn, you agree to our',
  Call: 'Call',
  'Call verification':
    'Call verification',
  catalog: 'Catalog',
  'categories affect how your story appears in public':
    'Categories affect how your story appears in public',
  'category groups': 'Category groups',
  'change address': 'Change address',
  'change display title / subtitle / display image / categories':
    'Change display title / subtitle / display image / categories',
  'change following categories':
    'Change following categories',
  'change password': 'Change password',
  'change preview photo':
    'Change preview photo',
  'change role': 'Change role',
  'change subscription':
    'Change subscription',
  "changes here will affect how your story appears in public places like Article's homepage - not the story itself.":
    "Changes here will affect how your story appears in public places like Article's homepage - not the story itself.",
  'Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.':
    'Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account's configuration to edit":
    "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account's configuration to edit",
  channel: 'Channel',
  'channel name': 'Channel name',
  'channel subscriptions':
    'Channel subscriptions',
  channels: 'Channels',
  'channels in category':
    'Channels in category',
  'Channels that you may be interested in':
    'Channels that you may be interested in',
  'channels to follow':
    'Channels to follow',
  'charged fee data':
    'Charged fee Data',
  'charges apply': 'Charges apply',
  'chart management':
    'Chart management',
  charts: 'Charts',
  'chat with provider':
    'Chat with organization',
  'check all': 'Check All',
  'check your email and click on the link':
    'Check your email and click on the link',
  'choose another FeedIn subscription':
    'Choose another FeedIn subscription',
  'choose file': 'Choose file',
  'Choose language': 'Choose language',
  'Choose one from your existing conversations, or start a new one.':
    'Choose one from your existing conversations, or start a new one.',
  'Choose the channel for posting':
    'Choose the channel for posting',
  'Choose to follow the information catalog to get more news on your wall':
    'Choose to follow the information catalog to get more news on your wall',
  'choose your default skin tone':
    'Choose your default skin tone',
  city: 'City',
  'clear all': 'Clear All',
  'clear role permissions':
    'Clear role permissions',
  'Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.':
    'Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.',
  'click to upload': 'Click to Upload',
  'code of conduct': 'Code of conduct',
  'color theme': 'Color theme',
  'commented your post':
    'commented your post',
  'Commodity market information':
    'Commodity market information',
  'company profile': 'Company Profile',
  'confidentiality of member information':
    'Confidentiality of member information',
  'configure content by language':
    'Configure content by language',
  'Confirmation change email address has been sent to':
    'Confirmation change email address has been sent to',
  'Confirmation letter will be sent to your email address':
    'Confirmation letter will be sent to your email address',
  'contact details': 'Contact Details',
  'contact info': 'Contact information',
  'contact messages':
    'Contact Messages',
  'contact name': 'Contact name',
  'contact now': 'Contact Now',
  'contact provider':
    'Contact Organization',
  'contact to member':
    'Contact to member',
  'Contact us about your bill':
    'Contact us about your bill',
  'contact with provider':
    'Contact with Organization',
  'contact-info': 'Contact information',
  contacts: 'Contacts',
  'content allowed to be found':
    'Content allowed to be found',
  'content displayed by language':
    'Content displayed by language',
  'content is for members only':
    'Content is for members only',
  'Content language':
    'Content language',
  'Continue to {provider}':
    'Continue to {provider}',
  'Continue to PayPal':
    'Continue to PayPal',
  conversations: 'Conversations',
  'cookies-policy': 'Cookies Policy',
  'Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel':
    'Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel',
  copyright: 'Copyright',
  country: 'Country',
  cover: 'Cover',
  'create a free channel':
    'Create a free channel',
  'Create a new chart for dataset':
    'Create a new chart for dataset',
  'Create a new provider so you can post products or manage your information channels.':
    'Create an organization so you can post products or manage your information channels.',
  'create a poll': 'Create a poll',
  'create category': 'Create category',
  'create channel': 'Create channel',
  'create chart': 'Create chart',
  'create dataset': 'Create Dataset',
  'Create new channel':
    'Create new channel',
  'create new group':
    'Create New Group',
  'create one': 'Create One',
  'create product': 'Create Product',
  'create role': 'Create Role',
  'Create provider profile':
    'Create Organization Profile',
  'credit/debit card':
    'Credit/Debit Card',
  custom: 'Custom',
  'custom url': 'Custom URL',
  daily: 'Daily',
  'Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.':
    'Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.',
  'dark mode': 'Dark mode',
  'dark skin tone': 'Dark Skin Tone',
  data: 'Data',
  'data & charts': 'Data & Charts',
  'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this':
    'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this',
  'data explorer': 'Data Explorer',
  'data frequency': 'Data frequency',
  'data in category':
    'Data in category',
  'data security': 'Data security',
  'Data set you might be interested in':
    'Data set you might be interested in',
  dataset: 'Dataset',
  'dataset list': 'Dataset List',
  'dataset management':
    'Dataset Management',
  'dataset subscriptions':
    'Dataset Subscriptions',
  deactive: 'Deactive',
  'Declare the location for your provider':
    'Declare the location for your organization',
  'default skin tone':
    'Default Skin Tone',
  'delete channel': 'Delete channel',
  'delete data': 'Delete Data',
  'delete dataset': 'Delete dataset',
  'delete provider':
    'Delete organization',
  'Deleted article are gone forever. Are you sure?':
    'Deleted article are gone forever. Are you sure?',
  'Deleted channel are gone forever. Are you sure?':
    'Deleted channel are gone forever. Are you sure?',
  'Deleted dataset are gone forever. Are you sure?':
    'Deleted dataset are gone forever. Are you sure?',
  'Deleted provider are gone forever. Are you sure?':
    'Deleted organization are gone forever. Are you sure?',
  'delivery frequency':
    'Delivery frequency',
  delivery_time: 'Delivery Time',
  'designed and provided by':
    'Designed and provided by',
  'desktop app': 'Desktop App',
  'discovery more providers':
    'Discovery more organizations',
  'do not show members information for each other members':
    'Do not show members information for each other members',
  'do you want cancel the subscription?':
    'Do you want cancel the subscription?',
  'do you want to cancel?':
    'Do you want to cancel?',
  'Do you want to deactivate this product?':
    'Do you want to deactivate this product?',
  'do you want to logout?':
    'Do you want to logout?',
  'document (PDF)': 'document (PDF)',
  documentation: 'Documentation',
  "Doesn't have any bookmarked item":
    "Doesn't have any bookmarked item",
  downgrade: 'Downgrade',
  'downgrade subscription':
    'Downgrade subscription',
  'downgrade your subscription to':
    'Downgrade your subscription to',
  download: 'Download',
  'draft products': 'Draft Products',
  'edit message': 'Edit Message',
  'edit name': 'Edit name',
  'edit your post': 'Edit your post',
  'editor choices': 'Editor choices',
  'email (example@company.com)':
    'Email (example@company.com)',
  'email account': 'Email Account',
  'Emoji categories':
    'Emoji categories',
  en: 'English',
  'Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.':
    'Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.',
  'enter an url': 'Enter an url',
  'enter channel name':
    'Enter channel name',
  'Enter Dataset Name':
    'Enter Data Name',
  'Enter message text with minimum requirement of 20, maximum of 4000 characters':
    'Enter message text with minimum requirement of 20, maximum of 4000 characters',
  'Enter products / services search':
    'Enter products / services search',
  'Enter some information so the provider knows your requirements':
    'Enter some information so the organization knows your requirements',
  'enter provider name':
    'Enter organization name',
  'enter your email':
    'Enter your email',
  'enter your keyword':
    'Enter your keyword',
  expires: 'Expires',
  'Explore related products from verified Companies':
    'Explore related products from verified Organizations',
  'Explore similar products':
    'Explore similar products',
  'extra request': 'Extra Request',
  'failure purchase!':
    'Failure Purchase!',
  FAQ: 'FAQ',
  'favorites categories':
    'Favorites categories',
  'Featured Categories':
    'Featured Categories',
  'featured charts': 'Featured charts',
  'featured topics': 'Featured topics',
  'fee management': 'Fee Management',
  'fee settings': 'Fee Settings',
  feed: 'Feed',
  'FeedIn gives you the latest information on market data.':
    'FeedIn gives you the latest information on market data.',
  'FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below':
    'FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below',
  'FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.':
    'FeedIn is a platform that connects buyers with organizations, creating a leading information sharing network about agribusiness.',
  'FeedIn Member Since':
    'Feedin Member Since',
  'FeedIn will update the latest and fastest information and data about the goods market to you':
    'FeedIn will update the latest and fastest information and data about the goods market to you',
  'file upload failed':
    'file upload failed',
  'file uploaded successfully':
    'file uploaded successfully',
  filter: 'Filter',
  'Filter results': 'Filter results',
  'first subscription on':
    'First subscription on',
  Flags: 'Flags',
  folders: 'Folder',
  'follow more categories':
    'Follow more categories',
  'Follow to get notifications from this channel!':
    'Follow to get notifications from this channel!',
  followers: 'Followers',
  'following categories':
    'Following categories',
  'following channels':
    'Following channels',
  'For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.':
    'For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.',
  'For inquiring about product quotes, sending product information, or contacting cooperation, please contact us':
    'For inquiring about product quotes, sending product information, or contacting cooperation, please contact us',
  'for you': 'For you',
  free: 'Free',
  'frequently questions':
    'Frequently questions',
  'Frequently Used': 'Frequently Used',
  'frequently-questions':
    'Frequently Questions',
  'From - To': 'From - To',
  'from the sender': 'From the sender',
  'general permission':
    'General permission',
  'general settings':
    'General settings',
  'Get a quote': 'Get a quote',
  'Get Best Price': 'Get Best Price',
  'Get in touch with us':
    'Get in touch with us',
  'Get latest price':
    'Get latest price',
  'Get Quotes': 'Get Quotes',
  'Get quotes from seller':
    'Get quotes from seller',
  'get started with {name}':
    'Get started with {name}',
  'Get your business details verified with us for higher rankings in FeedIn!':
    'Get your business details verified with us for higher rankings in FeedIn!',
  go: 'Go',
  'go modal': 'Go Modal',
  "haven't comments yet":
    "Haven't comments yet",
  'head office': 'Head office',
  hello: 'Hello',
  'Hello you': 'Hello you',
  'Help people update the latest market information, multi-dimensional, confidential and customized according to need.':
    'Help people update the latest market information, multi-dimensional, confidential and customized according to need.',
  'Here is a list of all invite links. You can revoke any one.':
    'Here is a list of all invite links. You can revoke any one.',
  'hide interaction between members':
    'Hide interaction between members',
  'Hide interactions between members. But, retaining interaction between members and administrators':
    'Hide interactions between members. But, retaining interaction between members and administrators',
  'If you need assistance, please feel free to contact us via':
    'If you need assistance, please feel free to contact us via',
  'If you want to switch to annual payment, {contactus}':
    'If you want to switch to annual payment, {contactus}',
  image: 'Image',
  'image must smaller than':
    'Image must smaller than',
  'incoming requests':
    'Incoming Requests',
  'index type': 'Index type',
  'index types': 'Index types',
  'info chart editing':
    'Info Chart Editing',
  interested: 'Interested',
  'Interface language':
    'Interface language',
  introduce: 'Introduce',
  'invalid template':
    'invalid template',
  'invite and share':
    'Invite and share',
  'invite code': 'Invite code',
  'invite members': 'Invite members',
  'invite people': 'Invite People',
  'invite people to join this channel':
    'Invite people to join this channel',
  'invite you to join':
    'invite you to join',
  'Invite your friends to this page':
    'Invite your friends to this page',
  invited: 'Invited',
  'invited to become member':
    'Invited to become member',
  inviter: 'Inviter',
  invites: 'Invites',
  'is served by clicking on a':
    'is served by clicking on a',
  'item not found': 'Item Not Found',
  'join channel': 'Join channel',
  'Join for free now':
    'Join for free now',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel's latest news to you":
    "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel's latest news to you",
  'Join this channel to start chatting':
    'Join this channel to start chatting',
  'keep subscription':
    'Keep subscription',
  keywords: 'Keywords',
  kick: 'Kick',
  kicked: 'Kicked',
  'last updated': 'Last updated',
  latest: 'Latest',
  'latest updates': 'Latest Updates',
  'Latest updates on the commodity market':
    'Latest updates on the commodity market',
  leave: 'Leave',
  'leave a message': 'Leave a Message',
  'Leave a Message, we will contact you back!':
    'Leave a Message, we will contact you back!',
  'leave channel': 'Leave channel',
  'Legal Status of Firm':
    'Legal Status of Firm',
  license: 'License',
  licenses: 'Licenses',
  'light skin tone': 'Light Skin Tone',
  link: 'Link',
  'Link original post':
    'Link original post',
  'list of data': 'List of Data',
  'listed products': 'Listed Products',
  'unlisted products':
    'Unlisted Products',
  'Location (Province/district/ward)':
    'Location (Province/district/ward)',
  'location & contact':
    'Location & Contact',
  'location name': 'Location name',
  'Login now': 'Login now',
  'Login now to select and follow the content that you interested in':
    'Login now to select and follow the content that you interested in',
  'login with account':
    'Login with account',
  'login with Facebook':
    'login with Facebook',
  'login with Google':
    'login with Google',
  'Make your channel get more exciting activities by inviting other members':
    'Make your channel get more exciting activities by inviting other members',
  'Make your provider get more exciting activities by inviting other members':
    'Make your organization get more exciting activities by inviting other members',
  manage: 'Manage',
  'manage dataset': 'Manage Dataset',
  'manage products': 'Manage Products',
  'manage services': 'Manage Services',
  ManageChannelSubscriptions:
    'Channel Subscriptions',
  'managed channels':
    'Managed channels',
  'mark as read': 'Mark as read',
  market: 'Market',
  'market summary': 'Market Summary',
  mart: 'Mart',
  'me to the channel':
    'Me to the channel',
  'medium skin tone':
    'Medium Skin Tone',
  'medium-dark skin tone':
    'Medium-Dark Skin Tone',
  'medium-light skin tone':
    'Medium-Light Skin Tone',
  'member description':
    'Member Description',
  'member management':
    'Member management',
  'members list': 'Members List',
  mention: 'mention',
  menu: 'Menu',
  Message: 'Message',
  messages: 'Messages',
  min_order_qtty:
    'Minimum Order Quantity',
  'Minimum payment required':
    'Minimum payment required',
  miss: 'Miss.',
  modal: 'Modal',
  monthly: 'Monthly',
  'More data from': 'More data from',
  'More like This': 'More like This',
  mr: 'Mr.',
  mrs: 'Mrs.',
  ms: 'Ms.',
  'my channels': 'My Channels',
  'my page': 'My page',
  'my post': 'My post',
  'name of chart': 'Name of chart',
  'name of your company':
    'Name of your company',
  'Nature of Business':
    'Nature of Business',
  'new answer': 'New answer',
  'New contact': 'New contact',
  'new folder': 'New Folder',
  'new group': 'New group',
  'New template': 'New template',
  'newest data': 'Newest Data',
  'news page': 'News page',
  'next change': 'Next change',
  'No categories have been selected':
    'No categories have been selected',
  'no categorized': 'No Categorized',
  'No channel being followed':
    'No channel being followed',
  'no channels': 'No channels',
  'No Data': 'No Data',
  'No data available at this time.':
    'No data available at this time.',
  'no data found': 'No Data Found',
  'No data set are being followed':
    'No data set are being followed',
  'no dataset': 'No dataset',
  'no description': 'No description',
  'No Emoji Found': 'No Emoji Found',
  'No filter available':
    'No filter available',
  'no invites yet': 'No invites yet',
  'No news': 'No news',
  'no office found': 'No office found',
  'No posts were found':
    'No posts were found',
  'no results found':
    'no results found',
  'not a member yet?':
    'Not a member yet?',
  'notes about chart!':
    'Notes about chart!',
  notification: 'Notification',
  'Number of Employees':
    'Number of Members',
  'Number of your channels:':
    'Number of your channels:',
  Objects: 'Objects',
  offices: 'Offices',
  OK: 'OK',
  'oldest data': 'Oldest Data',
  'One more step to become a Verified Seller':
    'One more step to become a Verified Seller',
  'Only members can view data content, post articles or comment on this':
    'Only members can view data content, post articles or comment on this',
  'oops...': 'Oops..',
  'opps, no channels available':
    'Opps, No channels available',
  'or continue with':
    'or continue with',
  'other categories':
    'Other categories',
  'other channels': 'Other channels',
  'other charts': 'Other charts',
  otherSettings: 'Other Settings',
  'Our Channels': 'Our Channels',
  'Our data': 'Our Data',
  'Our mission is to:':
    'Our mission is to:',
  'Our Videos': 'Our Videos',
  overview: 'Overview',
  'owner description': 'Owner role',
  'package in use': 'Package in use',
  packaging_details:
    'Packaging Details',
  'paid channel': 'Paid Channel',
  partner: 'Partner',
  'Password and confirm password does not match':
    'Password and confirm password does not match',
  'Password must have min 6 characters':
    'Password must have min 6 characters',
  'Passwords must match':
    'Passwords must match',
  'paste your link here':
    'Paste your link here',
  pay: 'Pay',
  'Pay and subscribe':
    'Pay and subscribe',
  'payment detail': 'Payment detail',
  'payment subscription':
    'Payment subscription',
  'Payment via {provider}':
    'Payment via {provider}',
  'Payment via Paypal':
    'Payment via Paypal',
  'Payment will be sent to continue on {provider}. Please confirm the amount to pay':
    'Payment will be sent to continue on {provider}. Please confirm the amount to pay',
  'Payment will be sent to continue on Paypal. Please confirm the amount to pay':
    'Payment will be sent to continue on Paypal. Please confirm the amount to pay',
  paypal: 'PayPal',
  'pending approval':
    'Pending Approval',
  'people to follow':
    'People to follow',
  'Permanent link': 'Permanent link',
  'permission denied':
    'Permission denied',
  permissions: 'Permissions',
  personal: 'Personal',
  'personal channels':
    'Personal channels',
  'personal profile':
    'Personal profile',
  'Phone Number': 'Phone Number',
  'Phone number how we can contact you':
    'Phone number how we can contact you',
  'Pictures on the home page':
    'Pictures on the home page',
  pin: 'Pin',
  'Pin code': 'Pin code',
  pinned: 'Pinned',
  'pinned posts': 'Pinned posts',
  pinnedPosts: 'Pinned posts',
  'Please check the information and follow the steps to subscribe.':
    'Please check the information and follow the steps to subscribe.',
  'Please choose to follow related channels for the system to filter the updates to you':
    'Please choose to follow related channels for the system to filter the updates to you',
  'Please choose to save the interest charts so you can view them easily each day':
    'Please choose to save the interest charts so you can view them easily each day',
  'please enter name of product':
    'Please enter name of Product',
  'please input your email':
    'Please input your email',
  'please input your phone number':
    'Please input your phone number',
  'Please log in to FeedIn to leave your comment in the post':
    'Please log in to FeedIn to leave your comment in the post',
  'Please login to use the message function in the system.':
    'Please login to use the message function in the system.',
  'Please register to view this content':
    'Please register to view this content',
  'please select': 'Please select',
  'Please select at least 1 topic to complete. FeedIn will help you find relevant information':
    'Please select at least 1 topic to complete. FeedIn will help you find relevant information',
  'Please select the unit you want to choose as the default':
    'Please select the unit you want to choose as the default',
  policy: 'Policy',
  poll: 'Poll',
  popular: 'Popular',
  'Post something': 'Post something',
  'post your images':
    'Post your images',
  'postal code': 'Postal Code',
  'posted by': 'Posted by',
  posts: 'Posts',
  Premium: 'Premium',
  'Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.':
    'Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.',
  'premium providers':
    'Premium organizations',
  'press enter to search':
    'Press ENTER to search',
  'preview description':
    'Preview Description',
  'preview title': 'Preview Title',
  pricing: 'Pricing',
  'Primary E-mail': 'Primary E-mail',
  'Primary Mobile No.':
    'Primary Mobile No.',
  'privacy settings':
    'Privacy settings',
  'privacy-policy': 'Privacy Policy',
  'private channel': 'Private Channel',
  pro: 'Pro',
  'product catalogs':
    'Product Catalogs',
  'product certificates':
    'Product Certificates',
  'product details': 'Product details',
  'product images': 'Product Images',
  'product must have at least five character':
    'product must have at least five character',
  'product must have at least one image':
    'product must have at least one image',
  'product settings':
    'Product settings',
  'product specifications':
    'Product Specifications',
  'Product/Service Name':
    'Product/Service Name',
  production_capacity:
    'Production Capacity',
  'products list': 'Products List',
  'products page': 'Products page',
  'Professional network in the industry':
    'Professional network in the industry',
  'profile settings':
    'Profile settings',
  'Promote people to share knowledge and experience, so that people can continuously learn and prosper together.':
    'Promote people to share knowledge and experience, so that people can continuously learn and prosper together.',
  'provided services':
    'Provided services',
  'Province/City': 'Province/City',
  'Province/district/ward':
    'Province/District/Ward',
  provinces: 'Provinces',
  'public timeline': 'Public timeline',
  publish: 'Publish',
  'published by': 'Published by',
  publisher: 'Publisher',
  'quick link': 'Quick link',
  read: 'read',
  'read more': 'Read More',
  'read news': 'Read News',
  'ready to publish?':
    'Ready to publish?',
  rebookmark: 'Rebookmark',
  'recent news': 'Recent news',
  'recently news': 'Recently news',
  Recommendations: 'Recommendations',
  refreshed: 'Refreshed',
  region: 'Region',
  regions: 'Regions',
  registered: 'Registered',
  'Registering to use advanced data, you will have to pay':
    'Registering to use advanced data, you will have to pay',
  'related articles':
    'Related articles',
  'related categories':
    'Related Categories',
  'related channels':
    'Related channels',
  'related charts': 'Related charts',
  'related keywords':
    'Related keywords',
  "Related to items you've viewed":
    "Related to items you've viewed",
  relevant: 'Relevant',
  'remove all': 'Remove All',
  'renew / expires on':
    'Renew / Expires on',
  replied: 'Replied',
  'Request a call': 'Request a call',
  'Request a quote': 'Request a quote',
  'request have been sent successfully!':
    'Request have been sent successfully!',
  'request to join': 'Request to join',
  'request-to-join': 'Request to join',
  'requested to join':
    'Request to join',
  'required field': 'Required Field',
  'Requirement Details':
    'Requirement Details',
  resend: 'Resend',
  'response rate': 'Response Rate',
  responses: 'Responses',
  restricted: 'Restricted',
  results: 'Results',
  'return home': 'Return home',
  'return owner page':
    'Return owner page',
  'return user page':
    'Return user page',
  Review: 'Review',
  'role name': 'Role name',
  'role title': 'Role Title',
  roles: 'Roles',
  'sales information':
    'Sales Information',
  'save & complete': 'Save & Complete',
  'Save and continue':
    'Save and continue',
  'schedule to cancel':
    'Schedule to cancel',
  'search anythings in FeedIn':
    'search anythings in FeedIn',
  'Search by Name': 'Search by Name',
  'Search Results': 'Search Results',
  'secret channel': 'Secret channel',
  section: 'Section',
  'See all charts in the data':
    'See all charts in the data',
  'select / create a group':
    'select / create a group',
  'Select a content category or follow the authors to get relevant information':
    'Select a content category or follow the authors to get relevant information',
  'select category': 'Select category',
  'select preview photo':
    'Select preview photo',
  'Select the display order in the category':
    'Select the display order in the category',
  'Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.':
    'Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.',
  'select your country':
    'Select your country',
  selected: 'Selected',
  seller: 'seller',
  'Seller Contact Details':
    'Seller Contact Details',
  'seller name': 'seller name',
  send: 'Send',
  'Send Email': 'Send Email',
  'Send invitation to join the channel to your friends, by sending a copy of the page link below':
    'Send invitation to join the channel to your friends, by sending a copy of the page link below',
  'Send invitation to join the provider to your friends, by sending a copy of the page link below':
    'Send invitation to join the organization to your friends, by sending a copy of the page link below',
  'send request': 'Send request',
  'Send your message to this provider':
    'Send your message to this organization',
  sending: 'Sending',
  'sent a request to become member':
    'Sent a request to become member',
  services: 'Services',
  'set main': 'set main',
  'set up content selection by language':
    'Set up content selection by language',
  'share a post': 'Share a post',
  'share button': 'share button',
  'share your ideas':
    'Share your ideas',
  'Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.':
    'Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.',
  'Sharing the information':
    'Sharing the information',
  'short description':
    'Short description',
  'Short description about chart!':
    'Short description about chart!',
  'Short description about your company!':
    'Short description about your company!',
  'Short description about your dataset!':
    'Short description about your dataset!',
  'show in category':
    'Show in category',
  'show more': 'Show more',
  'show this post': 'Show this post',
  'sign in with': 'Sign in with',
  'signin / signup': 'Signin / Signup',
  site: 'Website',
  sku: 'SKU',
  'Smileys & People':
    'Smileys & People',
  'some messages...some messages...':
    'some messages...some messages...',
  'Sorry, the page you visited does not exist.':
    'Sorry, the page you visited does not exist.',
  'Sorry, the page you visited has expired or does not exist.':
    'Sorry, the page you visited has expired or does not exist.',
  source: 'Source',
  'Specification Details':
    'Specification Details',
  'start free trial':
    'Start Free Trial',
  'start trial': 'Start trial',
  'start your no-cost {subscription} trial today':
    'Start your no-cost {subscription} trial today',
  'start your no-cost trial today':
    'Start your no-cost trial today',
  state: 'State',
  'stay connected': 'Stay connected',
  'Submit requirement':
    'Submit Requirement',
  'subscribe channel':
    'Subscribe Channel',
  "Subscribe to the categories to display the charts you're interested in":
    "Subscribe to the categories to display the charts you're interested in",
  'Subscribe to the channels for information displayed on this page':
    'Subscribe to the channels for information displayed on this page',
  'subscribed data': 'Subscribed Data',
  subscribers: 'Subscribers',
  'subscribing this channel will cost you':
    'Subscribing this channel will cost you',
  subscriptions: 'Subscriptions',
  'successfully purchase!':
    'Successfully Purchase!',
  'suggested products':
    'Suggested Products',
  summary: 'Summary',
  'Summary of the latest news today':
    'Summary of the latest news today',
  'supplied by': 'Supplied by',
  provider: 'Organization',
  'provider address':
    'Organization Address',
  'provider details':
    'Organization Details',
  'provider homepage':
    'Organization Homepage',
  'provider name': 'Organization Name',
  'provider profile':
    'Organization Profile',
  'provider profile was created successful':
    'Organization profile was created successful',
  'provider profiles':
    'Organization Profiles',
  "provider's channels":
    "Organization's channels",
  providers: 'Organizations',
  'Supporting brand promotion and market information to enhance business and networking opportunities.':
    'Supporting brand promotion and market information to enhance business and networking opportunities.',
  Symbols: 'Symbols',
  'table of contents':
    'Table of contents',
  Tap: 'Tap',
  tax: 'Tax',
  'tax code': 'Tax Code',
  'Tax ID': 'Tax ID',
  'tax inclusive': 'Tax inclusive',
  'tax number': 'Tax Number',
  'Tell us what you need':
    'Tell us what you need',
  template: 'template',
  'terms of service':
    'Terms of Service',
  'terms-of-service':
    'Terms of Service',
  'the {package} subscription will be continue effect until {date}':
    'The {package} subscription will be continue effect until {date}',
  "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.":
    "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.",
  'the channel has been deleted':
    'The channel has been deleted',
  'the channel so that the system will automatically help you update with the latest news':
    'the channel so that the system will automatically help you update with the latest news',
  'The chart is enabled and ready to be displayed to the user':
    'The chart is enabled and ready to be displayed to the user',
  'The chart marked as typical and used to represent the data set':
    'The chart marked as typical and used to represent the data set',
  'The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content':
    'The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content',
  'the information that interests you':
    'The information that interests you',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other's information.":
    "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other's information.",
  'the latest news': 'The latest news',
  'The members shown here include: Owner, administrator, member as contact person.':
    'The members shown here include: Owner, administrator, member as contact person.',
  'The page content is not yet available or being written':
    'The page content is not yet available or being written',
  'The page you are looking for does not exist. Tap the button below to return to the homepage.':
    'The page you are looking for does not exist. Tap the button below to return to the homepage.',
  'the restore email has been sent to':
    'the restore email has been sent to',
  'The state of the data set. If "On", the dataset is ready to use':
    'The state of the data set. If "On", the dataset is ready to use',
  'the provider has been deleted':
    'The organization has been deleted',
  'the providers i am involved in':
    'The organizations i am involved in',
  'The tax code value has been saved successfully':
    'The tax code value has been saved successfully',
  'The verification call is pending':
    'The verification call is pending',
  'This account has not registered an email address for authentication, please register an email address to verify the ownership of the account':
    'This account has not registered an email address for authentication, please register an email address to verify the ownership of the account',
  'This channel content is limited to members only, join now to view this channel content.':
    'This channel content is limited to members only, join now to view this channel content.',
  "This channel doesn't have any pinned post":
    "This channel doesn't have any pinned post",
  'this channel supports communication in the form of hidden interactions between members':
    'This channel supports communication in the form of hidden interactions between members',
  'this channel to your friends':
    'this channel to your friends',
  'This channel will charge you':
    'This channel will charge you',
  'This is a description.':
    'This is a description.',
  'this is a regular channel':
    'This is a regular channel',
  'This is an error message':
    'This is an error message',
  "This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?":
    "This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?",
  'Time for updating the tracking list:':
    'Time for updating the tracking list:',
  'timeline is only viewable with accounts that are members of this channel':
    'Timeline is only viewable with accounts that are members of this channel',
  'timeline is viewed internally only':
    'Timeline is viewed internally only',
  'title or question':
    'Question statement',
  'to channel': 'To channel',
  'to create new channel on your company':
    'to create new channel on your company',
  'to create your new channel':
    'to create your new channel',
  'To edit your details, please visit {profile}':
    'To edit your details, please visit {profile}',
  'to find content that interests you':
    'to find content that interests you',
  'To join the channel and follow the content regularly, you must Login / Register (free) account to become a member':
    'To join the channel and follow the content regularly, you must Login / Register (free) account to become a member',
  'To make changes to your subscriptions , go to':
    'To make changes to your subscriptions , go to',
  'To manage products visit at {manage_products} or continue with {call_verification}':
    'To manage products visit at {manage_products} or continue with {call_verification}',
  'To stay on your current plan after your trial ends, add payment now.':
    'To stay on your current plan after your trial ends, add payment now.',
  'to upgrade. This subscription cost you':
    'to upgrade. This subscription cost you',
  topic: 'Topic',
  'Total due': 'Total due',
  'Total due {date}':
    'Total due {date}',
  'Travel & Places': 'Travel & Places',
  trial: 'Trial',
  'trial days': 'Trial days',
  'trust and safe': 'Trust and Safe',
  'trust-safe': 'Trust and safe',
  tutorials: 'Tutorials',
  'type product name':
    'Type product name',
  'type your message':
    'Type your message',
  'typify chart': 'Typify chart',
  unban: 'Unban',
  unbookmark: 'UnBookmark',
  unbookmarked: 'UnBookmarked',
  'unclassified products':
    'Unclassified Products',
  unpin: 'Unpin',
  'unpin this?': 'Unpin this?',
  unsave: 'Unsave',
  unsubscribe: 'Unsubscribe',
  'update about success':
    'Update about success',
  'update dataset': 'Update dataset',
  'update fail': 'Update fail',
  'update success': 'update success',
  'Updates to you the information you have chosen to follow':
    'Updates to you the information you have chosen to follow',
  upgrade: 'Upgrade',
  'upgrade now. This subscription cost you':
    'Upgrade now. This subscription cost you',
  'upgrade subscription':
    'Upgrade subscription',
  'upgrade this channel':
    'Upgrade this channel',
  'upgrade to': 'Upgrade to',
  'upgrade to {name}':
    'Upgrade to {name}',
  upload: 'Upload',
  url: 'Url',
  'us to share the news in this channel':
    'us to share the news in this channel',
  'Usage: for direct communication purposes through messaging.':
    'Usage: for direct communication purposes through messaging.',
  'use email': 'use email',
  'use the selected system language':
    'Use the selected system language',
  'used group': 'Used group',
  'user choices': 'User choices',
  'user management': 'User Management',
  uses: 'Uses',
  'Verification request has been sent, please wait for a response':
    'Verification request has been sent, please wait for a response',
  'verified provider':
    'Verified organization',
  'Verify Account': 'Verify Account',
  'Verify on Call': 'Verify on Call',
  'Verify sales information':
    'Verify sales information',
  vi: 'Tiếng Việt',
  'video url':
    'Video URL (Youtube Link)',
  vietnamese: 'Tiếng Việt',
  view: 'View',
  'view all': 'View all',
  'View all categories':
    'View All Categories',
  'View all products in {name}':
    'View all products in {name}',
  'View complete detail':
    'View complete detail',
  'view full estimate':
    'View full estimate',
  'view more charts':
    'View more charts',
  'view product page':
    'View product page',
  views: 'Views',
  'visit page': 'Visit page',
  'Visit the page to preview':
    'Visit the page to preview',
  vnpay: 'VNPay',
  'waiting admin accept your request':
    'Waiting admin accept your request',
  'waiting members list':
    'Waiting members list',
  'wanna more features?':
    'Wanna more features ?',
  "we will upgrade you immediately, you won't have to pay anything until your trial ends":
    "We will upgrade you immediately, you won't have to pay anything until your trial ends",
  "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends":
    "We will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends",
  welcome: 'Welcome',
  'What are you looking for?':
    'What are you looking for?',
  'what is your chart name?':
    'What is your chart name?',
  'what is your company name?':
    'What is your company name?',
  'what is your name?':
    'What is your name?',
  'what is your question?':
    'What is your question?',
  'what your channel name?':
    'What your channel name?',
  'what your product name?':
    'What your product name?',
  "when you're ready":
    "When you're ready",
  'which categories are your interests?':
    'Which categories are your interests ?',
  'with account': 'With account',
  'write a response':
    'Write a response',
  'write article': 'Write article',
  'write description for this page':
    'Write description for this page',
  'write something about this page':
    'Write something about this page',
  'Write your sharing':
    'Write your sharing',
  'written by': 'Written by',
  'Year of Establishment':
    'Year Of Establishment',
  'You are not choosing to follow this chart category. Please choose follow up in advance':
    'You are not choosing to follow this chart category. Please choose follow up in advance',
  'you can not change your following categories before {time}':
    'You can not change your following categories before {time}',
  'you can not follow more {n} categories':
    'You can not follow more {n} categories',
  'You can only upload JPG/PNG file':
    'You can only upload JPG/PNG file',
  "you commented on {owner}'s post":
    "You commented on {owner}'s post",
  'you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account':
    'You currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account',
  'you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account':
    'You currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account',
  "you didn't enter the channel name correctly":
    "You didn't enter the channel name correctly",
  "you didn't enter the provider name correctly":
    "You didn't enter the provider name correctly",
  'You do not have a conversation selected':
    'You do not have a conversation selected',
  "you don't have permission to post":
    "You don't have permission to post",
  'you have not any notification!':
    'You do not have any notifications!',
  'You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.':
    'You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.',
  'You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.':
    'You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.',
  'You need {amount} to be able subscribe this channel.':
    'You need {amount} to be able subscribe this channel.',
  'you need login first':
    'You need login first',
  'Your account has been activated':
    'Your account has been activated',
  'your answer': 'Your answer',
  'Your business information has been fully updated, thank you':
    'Your business information has been fully updated, thank you',
  'your chart': 'Your chart',
  'Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you':
    'Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you',
  'Your current active subscription package will continue to hold until it expires':
    'Your current active subscription package will continue to hold until it expires',
  "your device don't support this!":
    "Your device don't support this!",
  'your invite link expires':
    'Your invite link expires',
  'your message': 'Your message',
  'Your phone number':
    'Your phone number',
  'your post': 'your post',
  'your request is pending approval':
    'Your request is pending approval',
  'your subscription will be renew on {date}':
    'Your subscription will be renew on {date}',
  'Your message have been sent':
    'Your message have been sent',
  verified: 'Verified',
  'price history': 'Price history',
  'product groups': 'Product Groups',
  representative: 'Representative',
  'representative chart':
    'Representative Chart',
  'the content will be delivered to you in the language you choose':
    'The content will be delivered to you in the language you choose',
  favorites: 'Favorites',
  'chart name': 'Chart name',
  'select categories':
    'Select categories',
  'the dataset has been deleted':
    'The dataset has been deleted',
  'create article': 'Create Article',
  'have sent you invitation':
    'have sent you invitation',
  'related dataset': 'Related dataset',
  'Send invitation to your friends, by sending a copy of the page link below':
    'Send invitation to your friends, by sending a copy of the page link below',
  'Copy and share the page link below to your friends.':
    'Copy and share the page link below to your friends.',
  'Response from provider will be sent via {email}':
    'Response from organization will be sent via {email}',
  action: 'Action',
  timeout: 'Timeout',
  limit: 'Limit',
  '5 minutes': '5 minutes',
  '30 minutes': '30 minutes',
  '1h': '1h',
  '1day': '1day',
  'Join As Member': 'Join As Member',
  'Join As Admin': 'Join As Admin',
  'others data': 'Others data',
  'channel management':
    'Channel Management',
  'channel list': 'Channel List',
  'all channels': 'All channels',
  'technical specs':
    'Technical Specifications',
  'functional specs':
    'Functional Specifications',
  'other specs': 'Other Specifications',
  'custom specs': 'Custom Specs',
  shipment: 'Shipment',
  period: 'Period',
  spot: 'Spot',
  forward: 'Forward',
  basis: 'Basis',
  'select period': 'Select Period',
  'from date': 'From Date',
  'to date': 'To Date',
  'Select the category in which the system will provide you with the most current market data':
    'Select the category in which the system will provide you with the most current market data',
  'Thanks sponsors bring freely these charts to you':
    'Thanks sponsors bring freely these charts to you',
  'Thanks sponsors for updating this news to you':
    'Thanks sponsors for updating this news to you',
  'quotes of provider':
    'Quotes of organization',
  announce: 'Announce',
  'product price announcement':
    'Product price announcement',
  'create report': 'Create report',
  "the recipient's email":
    "The recipient's email",
  'new email': 'New email',
  price_type: 'Price type',
  'Pricing Reports': 'Pricing Reports',
  'Create price alerts to send them to buyers, buying partners, or make product prices announce':
    'Create price alerts to send them to buyers, buying partners, or make product prices announce',
  'products quotation':
    'Products Quotation',
  'effected date': 'Effected Date',
  'price update': 'Price Update',
  'no price tag': 'No price tag',
  'updated at': 'Updated at',
  'reference price': 'Reference Price',
  'basis price': 'Basis Price',
  'forward price': 'Forward Price',
  'delivery type': 'Delivery Type',
  'general basic prices':
    'General basic prices',
  'leave blank to select all':
    'Leave blank to select all',
  'This information is confidential and is only for the member to be submitted':
    'This information is confidential and is only for the member to be submitted',
  'Sure to remove?': 'Sure to remove?',
  'Enter a price for the period':
    'Enter a price for the period',
  'advanced entry': 'Advanced Entry',
  'flat price': 'Flat price',
  'quoted prices': 'Quoted prices',
  'start (/end) date in term months':
    'Start (/end) date in term months',
  'select provinces':
    'Select Provinces',
  'spot price': 'Spot Price',
  confirm: 'Confirm',
  'delivery type option (delivery is default): this option affects the product price':
    'Delivery Type Option (Delivery is default): This option affects the product price',
  'delivery price': 'Delivery price',
  'shipment price': 'Shipment price',
  'add spot price': 'Add spot price',
  'add forward price':
    'Add forward price',
  'period range': 'Period range',
  'This information is adjusted within the scope of the report, the content of the original information has not been changed':
    'This information is adjusted within the scope of the report, the content of the original information has not been changed',
  'original price': 'Original Price',
  'feedin users': 'Feedin Users',
  'choose user': 'Choose users',
  'Select the term for the price input':
    'Select the term for the price input',
  'price unit': 'Price Unit',
  'enter new price': 'Enter new price',
  'Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)':
    'Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)',
  incoterm: 'Incoterm',
  'transport type': 'Transport type',
  'unit of the basis price':
    'Unit of the basic price',
  'new price record by location':
    'New price record by location',
  'This location has been declared, cannot be added':
    'This location has been declared, cannot be added',
  'choose another location':
    'Choose another location',
  'new price set': 'New price set',
  'choose another option for new price set':
    'choose another option for new price set',
  'These options are already declared, cannot be added':
    'These options are already declared, cannot be added',
  'add new forward price':
    'Add new forward price',
  'edit quotes': 'Edit quotes',
  'other unit': 'other unit',
  'if basis price is required':
    'If basis price is required',
  range: 'Range',
  "editor's choice": "Editor's choice",
  'remove the 2nd unit':
    'Remove the 2nd unit',
  'hidden price': 'Hidden price',
  wholesale: 'Wholesale',
  'contact provider for wholesale price':
    'Contact organization for wholesale price',
  'provided by': 'Provided by',
  'about product': 'About product',
  'brief description':
    'Brief description',
  manufactor: 'Manufactor',
  'add new specification':
    'Add new specification',
  'selecting...': 'Selecting...',
  'method type': 'Method type',
  operation: 'Operation',
  attribute: 'Attribute',
  'attribute value': 'Attribute Value',
  factory: 'Factory',
  'Specification/Additional Details':
    'Specification/Additional Details',
  'tap on here to start your articles':
    'Tap on here to start your articles',
  'attribute group': 'Attribute Group',
  'displayed on product information':
    'Displayed on product information',
  'page not found': 'Page not found',
  'The page you are opening does not exist, please check the link or return to the homepage':
    'The page you are opening does not exist, please check the link or return to the homepage',
  'catalog group': 'Catalog group',
  'manage page': 'Manage Page',
  'main page': 'Main Page',
  'use for': 'Use For',
  set_channel: 'Update posting channel',
  'added value': 'Added value',
  'product line': 'Product Line',
  '2nd unit': '2nd unit',
  '2nd price unit': '2nd price unit',
  'The effective date of the current price set will be adjusted to the date below':
    'The effective date of the current price set will be adjusted to the date below',
  'Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.':
    'Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.',
  'Copy the current priceset for another location with the same priceset':
    'Copy the current priceset for another location with the same priceset',
  'Please contact the administrator of FeedIn to put the data on the site':
    'Please contact the administrator of FeedIn to put the data on the site',
  'Paste a link to embed content from another site (e.g. Twitter) and click Ok':
    'Paste a link to embed content from another site (e.g. Twitter) and click Ok',
  'Paste a video link to embed content from another site (e.g. Youtube) and click Ok':
    'Paste a video link to embed content from another site (e.g. Youtube) and click Ok',
  'Announcement of information':
    'Announcement of information',
  'Sell products, supply items to the market':
    'Sell products, supply items to the market',
  'Provide published information and data for the industry':
    'Provide published information and data for the industry',
  'event name': 'Event name',
  'what is your event name?':
    'What is your event name?',
  'end date and time':
    'end date and time',
  'back to home': 'Back to home',
  meeting: 'Meeting',
  online: 'Online',
  'online event?': 'Online event?',
  'online event': 'Online event',
  'created by': 'Created by',
  milestone: 'Milestone',
  'start time': 'Start time',
  'Select event type (online / meeting)':
    'Select event type (online / meeting)',
  'Event, what?, when?':
    'Event, what?, when?',
  'Event name, start time':
    'Event name, start time',
  'Add a physical location for people to join your event. Or how to meet together in online event':
    'Add a physical location for people to join your event. Or how to meet together in online event',
  'Event, where?': 'Event, where?',
  'Provide more information about your event so guests know what to expect.':
    'Provide more information about your event so guests know what to expect.',
  'Event, how?': 'Event, how?',
  'Everyone meeting on the internet.':
    'Everyone meeting on the internet.',
  'Everyone meet together at a specific location.':
    'Everyone meet together at a specific location.',
  'If you have completely forgotten your old password. Please log out and use the "{1}" function to reset the password if necessary':
    'If you have completely forgotten your old password. Please log out and use the "{1}" function to reset the password if necessary',
  'Please select the language you want as the display language on the application':
    'Please select the language you want as the display language on the application',
  farm: 'Farm',
  'recover login password':
    'Recover login password',
  individual: 'Individual',
  'you are': 'You are',
  'organization name':
    'Organization name',
  'attention: please check your activation email in the spam area':
    'Attention: please check your activation email in the Spam Area',
  'with your email': 'With your email',
  'no products, please add products to your page':
    'No products, please add products to your page',
  'priceset history management':
    'Priceset history management',
  'product configuration':
    'Product configuration',
  'events in category':
    'Events in category',
  'no events': 'No events',
  'select / create': 'Select / Create',
  'co-admins': 'Co-Admins',
  weekly: 'Weekly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
  'the event will repeat in':
    'The event will repeat in',
  'finish time': 'Finish time',
  'updating date': 'Updating date',
  'price is valid from the date':
    'Price is valid from the date',
  'contract type': 'Contract Type',
  reminder: 'Reminder',
  'type of event?': 'Type of event?',
  speakers: 'Speakers',
  'select user': 'Select user',
  event_types: 'Type of event?',
  'something is wrong, want to delete it?':
    'Something is wrong, want to delete it?',
  'successfully deleted':
    'successfully deleted',
  'are you sure to delete this?':
    'Are you sure to delete this?',
  'deletion is ignored':
    'Deletion is ignored',
  'insert here': 'Insert here',
  'not milestone': 'Not milestone',
  'The next event is scheduled in':
    'The next event is scheduled in',
  'The next event is scheduled to take place at {1}':
    'The next event is scheduled to take place at {1}',
  'products can be searched':
    'Products can be searched',
  'your product has been declared complete, you can put it on the store':
    'Your product has been declared complete, you can put it on the store',
  'Do you want to activate this product?':
    'Do you want to activate this product?',
  'This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you':
    'This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you',
  profiles: 'Profiles',
  'incoming requests management':
    'Incoming requests management',
  'waiting members': 'Waiting members',
  purchases: 'Purchases',
  'quote prices': 'Quote Prices',
  'market prices': 'Market prices',
  'Contact information has not been declared, it is good to declare contact information so that customers can contact you directly':
    'Contact information has not been declared, it is good to declare contact information so that customers can contact you directly',
  'members management':
    'Members management',
  partners: 'Partners',
  vendor: 'Vendor',
  vendors: 'Vendors',
  customer: 'Customer',
  customers: 'Customers',
  'contact books': 'Contact Books',
  'add new contact book':
    'Add new Contact Book',
  'connect to user': 'Connect to User',
  'connect to organization':
    'Connect to Organization',
  'connect to partner':
    'Connect to Partner',
  'new contact book':
    'New contact book',
  'new partner': 'New partner',
  'partner type': 'Partner Type',
  'partner name': 'Partner Name',
  'edit contact book':
    'Edit contact book',
  'edit partner': 'Edit partner',
  'all partners': 'All Partners',
  'add new partner': 'Add new partner',
  'for sales notes': 'For sales notes',
  'for purchase notes':
    'For purchase notes',
  notes: 'Notes',
  'Note: The effective date of the price being updated is different from the price entry date':
    'Note: The effective date of the price being updated is different from the price entry date',
  'product catalog': 'Product Catalog',
  'group by': 'Group by',
  'type of product use for sales / purchases':
    'Type of product use for sales / purchases',
  'sales / purchases':
    'Sales / Purchases',
  added: 'Added',
  'feedin organizations':
    'FeedIn Organizations',
  'refresh chart': 'Refresh chart',
  'incoming quotes': 'Incoming quotes',
  'purchasing planning':
    'Purchasing planning',
  'manage outgoing quotes':
    'Manage outgoing quotes',
  'product group title':
    'Product Group Title',
  'Successfully Sign Up':
    'Successfully Sign Up',
  'redirect in {1} seconds':
    'redirect in {1} seconds',
  'Thank you for registering, Website will redirect to login page after 3 seconds':
    'Thank you for registering, Website will redirect to login page after 3 seconds',
  'add products': 'Add products',
  'price offer partners':
    'Price offer partners',
  'Select the products for which you want to send price information to your partner':
    'Select the products for which you want to send price information to your partner',
  'products for partner':
    'Products for partner',
  'Note: the list below shows only partners linked to the organizations on FeedIn':
    'The partners in this list are available on the FeedIn system',
  bookmarks: 'Bookmarks',
  'my space': 'My Space',
  'all bookmarks': 'All bookmarks',
  'quotations for partners':
    'Quotations for partners',
  favourites: 'Favourites',
  'vietnam market': 'Vietnam Market',
  workspace: 'Workspace',
  updates: 'Updates',
  'for search optimization':
    'for search optimization',
  'to optimize product display':
    'to optimize product display',
  'to group products with the same properties, compare prices':
    'to group products with the same properties, compare prices',
  'be sent from partners':
    'be sent from partners',
  owned: 'Owned',
  'Prices below are average prices with selected location or selected period is All':
    'Prices below are average prices with selected location or selected period is All',
  'Can follow the latest news and events in the industry':
    'Can follow the latest news and events in the industry',
  'Explore dataset and charts':
    'Explore dataset and charts',
  'to help you manage':
    'To help you manage',
  'or create an organization as':
    'OR Create an Organization as',
  farmer: 'Farmer',
  buyer: 'Buyer',
  'Create events to reach the commodity market':
    'Create events to reach the commodity market',
  'Create news channels to announce and share information to the commodity market':
    'Create a news channel to announce and share information to the commodity market',
  'Cooperate with FeedIn to share and provide commodity data to customers':
    'Cooperate with FeedIn to share and provide commodity data to customers',
  'Please contact the administrator of FeedIn':
    'Please contact the administrator of FeedIn',
  'name required more than {1} characters':
    'Name required more than {1} characters',
  'set subscription':
    'Set subscription',
  subscribed: 'Subscribed',
  'edit contact': 'Edit contact',
  'Information gathered from the declaration in the contact information':
    'Information gathered from the declaration in the contact information',
  'The information will be linked to the information of partner':
    'The information will be linked to the information of partner',
  'product had basic information and ready to launch':
    'product had basic information and ready to launch',
  'internal use': 'Internal Use',
  '{launch} the product to display on the store':
    '{launch} the product to display on the store',
  'OR {activate} to input the price and manage it internally':
    'OR {activate} to input the price and manage it internally',
  launch: 'Launch',
  'activate only': 'Activate only',
  '{deactivate} product':
    '{deactivate} product',
  deactivate: 'Deactivate',
  'set effect date': 'Set effect date',
  'insert at': 'Insert at',
  'expire at': 'Expire at',
  'the page does not exist or the page is for internal use, you must be a member to view the content of this page.':
    'The page does not exist OR the page is for internal use, you must be a member to view the content of this page.',
  'hybrid event': 'Hybrid event',
  'no data yet': 'No data yet',
  'Please login to view this content':
    'Please login to view this content',
  'no data is available now':
    'No data is available now',
  'latest tracking news':
    'Latest tracking news',
  activate: 'Activate',
  'Buy goods, collect quotes from sellers':
    'Buy goods, collect quotes from sellers',
  'following charts':
    'Following charts',
  'Leave your information so we can contact you again':
    'Leave your information so we can contact you again',
  purchase: 'Purchase',
  'enter spot price':
    'Enter spot price',
  present: 'Present',
  weather: 'Weather',
  'Temperature - weather, tap to see more':
    'Temperature - weather, tap to see more',
  'Rain (thunder) - weather, tap to see more':
    'Rain (thunder) - weather, tap to see more',
  'Wind - weather, tap to see more':
    'Wind - weather, tap to see more',
  temperature: 'Temperature',
  rain: 'Rain',
  wind: 'Wind',
  'edit & save': 'Edit & Save',
  'create from': 'Create from',
  'remove expired': 'Remove expired',
  'delete prices at':
    'Delete prices at',
  'copy prices at': 'Copy prices at',
  'enter forward price':
    'Enter forward price',
  'affiliate products':
    'Affiliate products',
  'at date': 'at date',
  'This pricing information will expire in':
    'This pricing information will expire in',
  'This pricing information will expire at':
    'This pricing information will expire at',
  'no expired date': 'No expired date',
  quarters: 'quarters',
  'you have an article in progress':
    'You have an article in progress',
  'Create an information channel to promote your information and products to everyone':
    'Create an information channel to promote your information and products to everyone',
  'get {free} quotes from multiple sellers':
    'Get {free} quotes from multiple sellers',
  'Tell us your Requirement':
    'Tell us your Requirement',
  'Receive free quotes from sellers':
    'Receive free quotes from sellers',
  'Seal the Deal': 'Seal the Deal',
  'Enter product/ Service name':
    'Enter product/ Service name',
  'Your email address':
    'Your email address',
  'Enter your name': 'Enter your name',
  'Your live in': 'Your live in',
  'We connect': 'We connect',
  'Buyers Sellers': 'Buyers & Sellers',
  'Trusted Platform':
    'Trusted Platform',
  'Safe Secure': 'Safe Secure',
  'Quick Assistance':
    'Quick Assistance',
  'Create events so people can participate and learn more about your organization':
    'Create events so people can participate and learn more about your organization',
  'my articles': 'My Articles',
  'Use keyboard shortcuts':
    'Use keyboard shortcuts',
  'edited by': 'Edited by',
  'Publishing to:': 'Publishing to:',
  'write caption for image':
    'Write caption for image',
  'caption of image':
    'Caption of image',
  'write caption for video':
    'Write caption for video',
  'caption of video':
    'Caption of video',
  'video url is invalid!':
    'Video url is invalid!',
  'enter video url': 'Enter video url',
  'add caption': 'Add caption',
  'related products':
    'Related Products',
  'products from the same organization':
    'Products from the same organization',
  'get direction': 'Get direction',
  'export purchasing data':
    'Export purchasing data',
  'export sales data':
    'Export sales data',
  'select page': 'Select page',
  'send requirement':
    'Send requirement',
  'detail requirement':
    'Detail requirement',
  'attach file': 'Attach file',
  'how it work': 'How it work',
  'tell us what you need by filling the form':
    'Tell us what you need by filling the form',
  'receive verified supplier details':
    'Receive verified supplier details',
  'compare quotations and seal the deal':
    'Compare quotations and seal the deal',
  'your information':
    'Your information',
  'choosing a category will help the article stay on topic and more accessible to users':
    'Choosing a category will help the article stay on topic and more accessible to users',
  'please add images to make the article more vivid and realistic to attract more viewers':
    'Please add images to make the article more vivid and realistic to attract more viewers',
  'data has been generate':
    'Data has been generate',
  'please click the {download} button below to download to your device':
    'Please click the {download} button below to download to your device',
  'article preview': 'article preview',
  'back to edit': 'Back to edit',
  'please login so the supplier can contact you':
    'Please login so the supplier can contact you',
  'product information':
    'Product information',
  'please provide a few details to get the most accurate response from the supplier':
    'Please provide a few details to get the most accurate response from the supplier',
  'article has no images':
    'Article has no images',
  'this channel belongs to':
    'This channel belongs to',
  'unit price': 'Unit price',
  'posted at': 'Posted at',
  'respond at': 'Respond at',
  'view website': 'View website',
  'send message': 'Send message',
  'resend request': 'Resend request',
  'close request': 'Close request',
  'view detail': 'View detail',
  'hide detail': 'Hide detail',
  'not sure': 'Not sure',
  sure: 'Sure',
  'you have found a supplier for this product and you want to close this request':
    'You have found a supplier for this product and you want to close this request',
  'are you sure': 'Are you sure',
  'company name (if you buy for the company)':
    'company name: (if any, if you are buying for company)',
  'Request a quote / Order':
    'Request a quote / Order',
  'avatar is selected from the post, go back and add an image to make the post more lively and attractive':
    'Avatar is selected from the post, go back and add an image to make the post more lively and attractive',
  'get the latest and most accurate quotes from suppliers for the product you are interested in':
    'Get the latest and most accurate quotes from suppliers for the product you are interested in',
  'filtered by': 'Filtered by',
  'my order request':
    'My order request',
  'keyword: request id, organization id (name)':
    'Keyword: request id, organization id (name)',
  'order requests management':
    'Order requests management',
  'press enter to create new page':
    'Press enter to create new page',
  'add new page': 'Add new page',
  'product info': 'Product info',
  'order request type':
    'Order request type',
  'request type': 'Request type',
  'received at': 'Received at',
  'min quantity': 'Min quantity',
  productinfo: 'Product info',
  deleted: 'Deleted',
  'alert drafting article description':
    'This article is subject to change and has not yet been published. You can click here to continue drafting and publishing content.',
  'delete article': 'Delete article',
  'language linking':
    'Language linking',
  'articles in other languages':
    'Articles in other languages',
  'Are you sure remove this related article?':
    'Are you sure remove this related article?',
  'Type the title of the article in other languages to search and add the article you want to link to.':
    'Type the title of the article in other languages to search and add the article you want to link to.',
  'linked articles': 'Linked articles',
  'edit article setting':
    'Edit article settings',
  'owner by': 'Owner by',
  'grateful description':
    'We would like to sincerely thank our sponsors',
  'You can click "See more" to see this full information':
    'You can click "See more" to see this full information',
  'your donation description':
    'Here is your donation. If something goes wrong, please contact the campaign admin. We thank you for your contribution.',
  'contribution chart':
    'Contribution chart',
  'list of contributors':
    'List of Contributors',
  'donated items': 'Donated items',
  'pending items': 'Pending items',
  'search with donation code':
    'Search with Donation Code',
  'equivalent to': 'equivalent to',
  'has a capacity of':
    'has a capacity of',
  'my donations': 'My donations',
  'thank you card': 'Thank you card',
  'sincerely thank you':
    'Sincerely thank you',
  'supported the donation campaign':
    'supported the donation campaign',
  'money / goods': 'Money / Goods',
  'Sincere thanks to the benefactors for accompanying us to create a picture of kindness.':
    'Sincere thanks to the benefactors for accompanying us to create a picture of kindness.',
  'Wishing you good health, peace and happiness.':
    'Wishing you good health, peace and happiness.',
  'Kind regards,': 'Kind regards,',
  'poll question': 'Poll question',
  'latest contributions':
    'Latest contributions',
  'news update': 'News update',
  'export as PNG': 'Export as PNG',
  'register now': 'Register now',
  'would you like to donate another value':
    'Would you like to donate another value ?',
  'choose the package that you can donate':
    'Choose the package that you can donate',
  'register to contribute':
    'Register to contribute',
  'copy path': 'Copy path',
  'click here to start writing':
    'Click here to start writing',
  'impact-categories':
    'Impact categories',
  'host request':
    'Request to become Organizers',
  'host-request':
    'Request to become Organizers',
  'adding an address will help people find you':
    'Adding an address will help people find you',
  'load more': 'Load more',
  'waiting-members': 'Waiting members',
  'add host': 'Add host',
  'Are you sure delete this host?':
    'Are you sure delete this host?',
  confirmation: 'Confirmation',
  'Deleted {type} are gone forever. Are you sure?':
    'Deleted {type} are gone forever. Are you sure?',
  '{type} name': '{type} name',
  "you didn't enter the {type} name correctly":
    "You didn't enter the {type} name correctly",
  'select tags': 'Select tags',
  finished: 'Finished',
  'Allow contribution information to be visible to the public':
    'Allow contribution information to be visible to the public',
  'Allow charity work information to be visible to the public':
    'Allow charity work information to be visible to the public',
  'Allows to be searched by recruitment agencies':
    'Allows to be searched by recruitment agencies',
  clip_url: 'Clip link',
  'edit owner': 'Edit owner',
  ref_ids: 'Campaign link',
  'edit campaign link':
    'Edit campaign link',
  'add target details':
    'Add target details',
  'select unit': 'Select unit',
  'select item': 'Select item',
  votes: 'Votes',
  shares: 'Shares',
  'giving items': 'Giving items',
  'search with giving code':
    'Search with giving code',
  'latest giving': 'Latest giving',
  '---Choose One---': 'Choose One',
  'Linked Pages': 'Linked pages',
  'contact information so people can reach out when they need help':
    'Contact information so people can reach out when they need help',
  'detail target': 'Detail target',
  'click to select': 'Click to select',
  'not available': 'Not available',
  'summary report': 'Summary report',
  'click here for the link':
    'Click here for the link',
  'your campaign is currently not associated with any giving campaign, click here to connect':
    'Your campaign is currently not associated with any giving campaign, click here to connect',
  'link to giving': 'Link to Giving',
  'search with registration code':
    'Search with registration code',
  'giving chart': 'Giving chart',
  'list of giving': 'List of giving',
  'your registered list':
    'Your registered list',
  'indexed by wishare':
    'Indexed by Wishare',
  'recruiting volunteers':
    'Recruiting volunteers',
  'type of recruitment':
    'Type of recruitment',
  'job requirements':
    'Job requirements',
  'time to start work':
    'Time to start work',
  'application deadline':
    'Application deadline',
  'Create an organization to share and raise money for your charity':
    'Create an organization to share and raise money for your charity',
  'Create activity to share your campaigns':
    'Create activity to share your campaigns',
  'create donation campaign':
    'Create donation campaign',
  'Create a donation campaign to call for support from donors':
    'Create a donation campaign to call for support from donors',
  'create giving campaign':
    'Create giving campaign',
  'Create a giving campaign to help those in need':
    'Create a giving campaign to help those in need',
  'create recruitment campaign':
    'Create recruitment campaign',
  'Create a recruitment campaign to find volunteers for the organization':
    'Create a recruitment campaign to find volunteers for the organization',
  'Create an event to connect with other benefactors or volunteer organizations':
    'Create an event to connect with other benefactors or volunteer organizations',
  'Create a channel to share information about your charity program':
    'Create a channel to share information about your charity program',
  'who can post': 'Who can post',
  everyone: 'Everyone',
  administrators: 'Administrators',
  'event type': 'Event type',
  npo: 'Nonprofit organization',
  ngo: 'Nongovernmental organization',
  association: 'Association',
  'edit cd type': 'Edit CD type',
  'Channel members only':
    'Channel members only',
  'Channel admins only':
    'Channel admins only',
  'Everyone who has an account at wishare':
    'Everyone who has an account at wishare',
  'most view': 'Most view',
  'donation types': 'Donation types',
  'giving types': 'Giving types',
  'occupation types':
    'Occupation types',
  'reserved job types':
    'Reserved job types',
  'recruitment types':
    'Recruitment types',
  what: 'What ?',
  when: 'When ?',
  whose: 'Whose ?',
  how: 'How ?',
  'An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.':
    'An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.',
  "Organize activities throughout, be recognized for the group's activities.":
    "Organize activities throughout, be recognized for the group's activities.",
  'Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.':
    'Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.',
  'Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.':
    'Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.',
  'Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.':
    'Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.',
  'Activities are created and maintained throughout, recorded during the operation of the project.':
    'Activities are created and maintained throughout, recorded during the operation of the project.',
  'It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...':
    'It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...',
  'Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.':
    'Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.',
  'Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.':
    'Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.',
  'Donations are made over a limited period of time according to the scope of each phase of the project.':
    'Donations are made over a limited period of time according to the scope of each phase of the project.',
  'Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.':
    'Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.',
  'Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.':
    'Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.',
  'Giving takes place over a finite period of time according to the scope of each phase of the project.':
    'Giving takes place over a finite period of time according to the scope of each phase of the project.',
  'Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.':
    'Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.',
  'Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.':
    'Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.',
  'Recruitment activities have a timeline according to the development stage of the organization.':
    'Recruitment activities have a timeline according to the development stage of the organization.',
  'Recruitment is made from Groups, Associations, Community...':
    'Recruitment is made from Groups, Associations, Community...',
  'Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.':
    'Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.',
  'The event takes place over a finite period of time.':
    'The event takes place over a finite period of time.',
  'Events are created from Groups, Guilds, Communities...':
    'Events are created from Groups, Guilds, Communities...',
  'Information channel is a function to support sharing and publishing information of social activities.':
    'Information channel is a function to support sharing and publishing information of social activities.',
  'Information channels are maintained throughout.':
    'Information channels are maintained throughout.',
  'Groups, Associations, Community... can set up 1 or more channels.':
    'Groups, Associations, Community... can set up 1 or more channels.',
  denied: 'Denied',
  'applied for the job':
    'applied for the job',
  'your donation will be sent to':
    'Your donation will be sent to',
  'register donation':
    'Register donation',
  alias: 'Alias',
  'required information':
    'Required information',
  'date of birth': 'Date of birth',
  'required fields': 'Required fields',
  'Please follow these instructions to complete the donation process':
    'Please follow these instructions to complete the donation process.',
  'Thank you benefactors, when transferring, remember to specify the structure of the transfer as':
    'Thank you benefactors, when transferring, remember to specify the structure of the transfer as:',
  'your name - donation name':
    'Your name - donation name',
  'Click the Register donate button to complete and receive the donation code':
    'Click the "Register to donate" button to complete and receive the donation code',
  text: 'Text',
  radio: 'Choose one',
  checkbox: 'Choose many',
  textarea: 'Text area',
  boolean: 'True / False',
  'conversion required':
    'Conversion required',
  limited: 'Limited',
  'complete registration':
    'Complete registration',
  'You have just completed the registration, please follow the donation steps to complete the donation process for campaign':
    'You have just completed the registration, please follow the donation steps to complete the donation process for campaign',
  'description donation code':
    'This is your donation code for the campaign if you have an account on wishare you can check it in the information section or in "Gratitude" if you have completed your donation with this code',
  'Thank you for your contributions to make this life better':
    'Thank you for your contributions to make this life better',
  'You can click the "Back campaign" button to return to the campaign':
    'You can click the "Back campaign" button to return to the campaign',
  'back campaign': 'Back campaign',
  'donation input': 'Donation input',
  'create new post': 'Create new post',
  'post an article': 'Post an article',
  'you want more': 'You want more',
  'min length 2 characters':
    'Min length 2 characters',
  container: 'Posted on the channel',
  'anyone can search for and view':
    'Anyone can search for and view',
  'only you can view':
    'Only you can view',
  'add tags': 'Add tags',
  hashtags: 'Hashtags',
  'job position': 'Job position',
  'work time type': 'Work time type',
  'is remote work': 'Is remote work',
  'experience requirements':
    'Experience requirements',
  'min age': 'Min age',
  'max age': 'Max age',
  'min salary': 'Min salary',
  'max salary': 'Max salary',
  'per quater': 'per quater',
  'latest list of candidates':
    'Latest list of candidates',
  'is yes/no question':
    'Is yes/no question ?',
  'choose number of answers':
    'Choose number of answers',
  'one answer': 'One answer',
  'multiple answer': 'Multiple answer',
  'number of choices':
    'Number of choices',
  goto: 'Go to',
  'add donation option':
    'Add donation option',
  'are you sure delete this':
    'Are you sure delete this ?',
  'would you like to go to the detailed article page':
    'Would you like to go to the detailed article page ?',
  'the article was posted':
    'The article was posted',
  'are you sure you want to delete this donation package':
    'Are you sure you want to delete this donation package ?',
  'delete donation package':
    'Delete donation package',
  'General Categories':
    'General Categories',
  'private event': 'Private event',
  'private event description':
    'Only you and invited members can participate in the event',
  'private organization':
    'Private organization',
  'private organization description':
    'Only you and members of your organization can view and receive information from your organization',
  'You have completed the steps of creating organization':
    'You have completed the steps of creating organization',
  'Hope your organization will be successful and help many people':
    'Hope your organization will be successful and help many people',
  'Short description about your organization':
    'Short description about your organization',
  'display name': 'Display name',
  'you have chosen': 'You have chosen',
  'transfer date': 'Transfer date',
  'send a thank you email':
    'Send a thank you email',
  'you donated for campaign':
    'You donated for campaign',
  'volunteer available':
    'Volunteer available',
  'action completed':
    'Action completed',
  'successfully created!':
    'Successfully created!',
  'successfully updated!':
    'Successfully updated!',
  'successfully deleted!':
    'Successfully deleted!',
  'search news channel':
    'Search news channel',
  'input data': 'Input data',
  'add receiving option':
    'Add receiving option',
  'giving input': 'Giving input',
  'your giving will be sent to':
    'Your giving will be sent to',
  'register giving': 'Register giving',
  'your registration will be sent to':
    'Your registration will be sent to',
  'Please follow these instructions to complete the giving process':
    'Please follow these instructions to complete the giving process',
  'Your application will be sent to the program and it will take a few days to process, so please wait.':
    'Your application will be sent to the program and it will take a few days to process, so please wait.',
  'Click the Register giving button to complete and receive the giving code':
    'Click the "Register giving" button to complete and receive the giving code',
  'You have just completed register receiving gifts from the program':
    'You have just completed register receiving gifts from the program',
  'description giving code':
    'This is your registration code to receive the campaign\'s gifts if you have an account on Shareshare you can check it in the information section or in "My registration" by this code',
  'successfully subscribed':
    'Successfully subscribed !',
  'register to receive':
    'register to receive',
  'you have registered to receive from':
    'You have registered to receive from',
  'you received from':
    'You received from',
  'register to received':
    'Register to received',
  'receive date': 'Receive date',
  'register received':
    'Register received',
  'donation package':
    'Donation package',
  'name package': 'Name package',
  'giving package': 'Giving package',
  'add giving option':
    'Add giving option',
  '{type} will delete will be gone forever. Are you sure?':
    '{type} will delete will be gone forever. Are you sure?',
  'successfully registered':
    'Successfully registered !',
  'you register to receive':
    'You register to receive',
  km: 'កម្ពុជា។',
  fr: 'Français',
  ko: '한국인',
  'delete receiving package':
    'Delete receiving package ?',
  'are you sure you want to delete this receiving package':
    'Are you sure you want to delete this receiving package ?',
  'select categories description':
    'Wishare has grouped the information by categories to help you better access information and avoid missing information. Please choose the categories you like below',
  'by using Wishare, you agree to our':
    'By using Wishare, you agree to our',
  'donation event has the unfinished items':
    'Donation event has the unfinished items',
  'search anythings in Wishare':
    'Search anythings in Wishare',
  'registered list': 'Registered list',
  'giving event has the unfinished items':
    'Giving event has the unfinished items',
  'recruitment event has the unfinished items':
    'Recruitment event has the unfinished items',
  responsibilities: 'Responsibilities',
  'no name entered': 'No name entered',
  'search with recruitment code':
    'Search with recruitment code',
  'for admin': 'For Admin',
  'your application will be sent to':
    'Your application will be sent to',
  'click the Apply now button to complete and receive the apply code':
    'Click the "Apply now" button to complete and receive the apply code',
  'job-description': 'Job description',
  'job-requirements':
    'Job requirements',
  recruited: 'Recruited',
  'thank you for applying for this job':
    'Thank you for applying for this job.',
  'your application has been sent to the organization, please wait for a response from the organization':
    'Your application has been sent to the organization, please wait for a response from the organization.',
  'that may take a few days, please wait':
    'That may take a few days, please wait.',
  'find account': 'Find account',
  'choose account':
    'Choose account available on Wishare',
  'select receiver': 'Select receiver',
  'description recruitment code':
    'This is your volunteer registration code, you can track your application in the Volunteer section.',
  'you have just completed your registration as a volunteer for the program':
    'You have just completed your registration as a volunteer for the program',
  applied: 'Applied',
  'Thank you for signing up to be a volunteer of the program':
    'Thank you for signing up to be a volunteer of the program',
  'candidate list': 'List of candidate',
  'are you sure you want to reject this candidate':
    'Are you sure you want to reject this candidate ?',
  candidate: 'Candidate',
  'currently an employee of the organization':
    'Currently an member of the organization/group',
  'enter candidate information':
    'Enter candidate information',
  'enter information to give':
    'Enter information to give',
  'given by the organization':
    'Given by the organization',
  'enter donation information':
    'Enter donation information',
  'edit candidate information':
    'Edit candidate information',
  'administration panel':
    'Administration panel',
  'open/close recruitment':
    'Recruitment time',
  'work type': 'Work type',
  'create donation event':
    'Create Crowdfunding Campaign',
  'create giving event':
    'Create Giving Campaign',
  'organization type':
    'Organization type',
  'mobilization of manpower':
    'Mobilization of manpower',
  communication: 'Communication',
  'are you ready for volunteer work':
    'Are you ready for volunteer work ?',
  'change-password': 'Change password',
  'configure-content-by-language':
    'Configure content by language',
  diamond: 'Diamond',
  platinum: 'Platinum',
  gold: 'Gold',
  silver: 'Silver',
  bronze: 'Bronze',
  'Display name can not be empty':
    'Display name can not be empty',
  'Number values can not be zero':
    'Number values can not be zero',
  basic: 'Basic',
  premium: 'Premium',
  'general operating model':
    'General operating model',
  'we make common rules so that everyone can understand and understand how we work':
    'We make common rules so that everyone can understand and understand how we work.',
  project: 'Project',
  'serving the mission aiming for social impact':
    'Serving the mission, aiming for social impact',
  'giving gifts to difficult circumstances':
    'Giving gifts to difficult circumstances',
  'motto and how it works':
    'Motto and how it works',
  'we aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission':
    'We aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission.',
  'connect social activities':
    'Connect social activities',
  'we create links between charities and those in need':
    'We create links between charities and those in need.',
  'support groups, organizations':
    'Support associations, groups, organizations',
  'we provide support tools for associations, groups, organizations to be able to carry out their volunteering missions':
    'We provide support tools for associations, groups, organizations, ... to be able to carry out their volunteering missions.',
  'Transparency - Sustainability - Impact':
    'Transparency - Sustainability - Impact',
  'this is our operating principle, every donation or donation is clear and transparent':
    'This is our operating principle, every donation or donation is clear and transparent.',
  beginer: 'Beginner',
  intermediate: 'Intermediate',
  proficient: 'Proficient',
  'on working': 'On working',
  'not graduated': 'Not graduated',
  'not anymore': 'Not anymore',
  'review the application form':
    'Review the application form',
  'application form':
    'Application form',
  'recruitment agency':
    'Recruitment agency',
  'cancel application':
    'Cancel application',
  'do you want to change your personal information':
    'Do you want to change your personal information ?',
  'candidate profile':
    "Candidate's profile",
  'you want to see candidate information':
    'You want to see candidate information',
  'human resources': 'Human Resources',
  'Transparency & Sustainability':
    'Transparency & Sustainability',
  'record project activities with the data of fundraising campaigns, giving':
    'Record project activities with the data of fundraising campaigns, giving',
  projects: 'Projects',
  'how it works': 'How It Works',
  'information collecting':
    'Information collecting',
  candidates: 'Candidates',
  'you can not select than 2 languages':
    'You can not select than 2 languages',
  'Sharing and connecting between members in the community of social activities.':
    'Sharing and connecting between members in the community of social activities.',
  'Support social units in the work of carrying out their mission':
    'Support social units in the work of carrying out their mission',
  'Creating transparency and sustainability in social activities':
    'Creating transparency and sustainability in social activities',
  'are you sure that you want to cancel this':
    'Are you sure that you want to cancel this',
  'edit a poll': 'Edit a poll',
  'Change folder name':
    'Change folder name',
  'Do you want delete this folder':
    'Do you want delete this folder ?',
  'This will permanently delete it, but its items will still be in your saved list.':
    'This will permanently delete it, but its items will still be in your saved list.',
  'export donation data':
    'Export donation data',
  'export as PDF': 'Export as PDF',
  'export giving data':
    'Export giving data',
  'record and display the converted value':
    'Record and display the converted value',
  'with target': 'With target',
  'approved list': 'Approved list',
  'rejected list': 'Rejected list',
  export: 'Export',
  'time sheets': 'Time sheets',
  'view timesheet': 'View timesheet',
  'other project': 'Other project',
  'select project': 'Select project',
  'For example: ABC Campaign, ABC Organization,...':
    'For example: ABC Campaign, ABC Organization,...',
  'edit timesheet': 'Edit timesheet',
  'member information':
    'Member information',
  'delete this information':
    'Delete this information ?',
  'are you sure you want to delete this working information':
    'Are you sure you want to delete this working information ?',
  'my profile': 'My profile',
  'you are exporting campaign data':
    'You are exporting campaign data',
  'package donation':
    'Package donation',
  'package giving': 'Package giving',
  'do you want to leave':
    'Do you want to leave ?',
  'you will no longer be a member of this organization or activity':
    'You will no longer be a member of this organization or activity. Are you sure ?',
  'the {type} has been deleted':
    'The {type} has been deleted',
  'features group': 'Features',
  'beneficiary management':
    'Beneficiary management',
  'beneficiary-management':
    'Beneficiary management',
  'on moving': 'On moving',
  'go to activity': 'Go to activity',
  'transfer beneficiary':
    'Transfer beneficiary ',
  'note approve beneficiary':
    'When transferring a beneficiary, you will no longer have access to manage this wish. And this action cannot be undone.',
  'cancel transfer': 'Cancel transfer',
  insights: 'Insights',
  'recent campaigns':
    'Recent Campaigns',
  'recent activity': 'Recent Activity',
  'latest apply': 'Latest apply',
  'latest register giving':
    'Latest register giving',
  'latest register donation':
    'Latest register donation',
  'latest followed user':
    'Latest followed user',
  'latest post': 'Latest post',
  campaign: 'Campaign',
  'top donation campaign':
    'Top donation campaign',
  'top giving campaign':
    'Top giving campaign',
  'total giving': 'Total giving',
  'top article': 'Top article',
  'top posts': 'Top posts',
  'total votes': 'Total Votes',
  'referral information':
    'Referral information',
  existing: 'Existing',
  'beneficiary referrals':
    'Beneficiary referrals',
  'beneficiary-referrals':
    'Beneficiary referrals',
  followed: 'followed',
  'total views': 'Total views',
  'total comments': 'Total comments',
  'total follows': 'Total follows',
  today: 'Today',
  yesterday: 'Yesterday',
  'last 7 days': 'Last 7 days',
  'this month': 'This month',
  'last month': 'Last month',
  'latest donations':
    'Latest donations',
  'to the campaign': 'to the campaign',
  'part given from': 'part given from',
  'introduced by': 'introduced by',
  'history of receiving and giving':
    'History of receiving and giving',
  'account link': 'Account link',
  'activity link': 'Activity link',
  'detail information':
    'Detail information',
  'link to donation':
    'Link to Donation',
  'donation/giving':
    'Donation / Giving',
  interactive: 'Interactive',
  'accept transferring':
    'Accept transferring',
  'reject transferring':
    'Reject transferring',
  'was a member of': 'was a member of',
  'select an existing profile':
    'Select an existing profile',
  'latest articles': 'Latest articles',
  'date desc': 'Recent days',
  'name desc': 'In order Z -> A',
  'value desc': 'Descending value',
  'date asc': 'Oldest day',
  'name asc': 'In order A -> Z',
  'value asc': 'Incremental value',
  'are you sure delete this message':
    'Are you sure delete this message ?',
  'related units': 'Related units',
  benefactor: 'Benefactor',
  'user note': "User's note",
  'admin note': "Admin's note",
  filename: 'File name',
  size: 'Size',
  responding: 'Responding',
  register_to_donate:
    'register to donate',
  'attached image': 'Attach image',
  'attached file': 'Attached file',
  file: 'File',
  'money transferred':
    'Money transferred',
  'total donated amount':
    'Total donated amount ',
  'description qr-code':
    'Please register to contribute at the table or register directly at the link (open with the QR code next to it)',
  'note (administrator)':
    'Note (administrator)',
  'note (register)': 'Note (register)',
  'value display is not allowed':
    'Hide donation value',
  'hidden value': 'Hidden value',
  'or you can copy the link of the page here to send it to your friends in other ways.':
    'or you can copy the link of the page here to send it to your friends in other ways.',
  'slide show (live)':
    'Slide show (LIVE)',
  'thanks for your donation':
    'Thanks for your donation',
  'an anonymous donor':
    'An anonymous sponsor',
  'total amount contributed up to now':
    'Total amount contributed up to now',
  'thank you benefactors':
    'Thank you benefactors',
  'donated amount': 'Donated Amount',
  'activity list': 'Activity list',
  'count desc': 'Count desc',
  'campaign list': 'Campaign list',
  'event list': 'Event list',
  'email history thank you':
    'Email history thank you',
  donors: 'Donors',
  'recent donations':
    'Recent donations',
  'top donations': 'TOP donations',
  'total donation events':
    'Total donation events',
  'total donation value':
    'Total donation value',
  'total donors': 'Total donors',
  'top donation count':
    'Top donation count',
  'top donation value':
    'Top donation value',
  'donor list': 'Donor list',
  merge: 'Merge',
  'top donors': 'Top donors',
  'the system sent a thank you letter to':
    'The system sent a thank you letter to',
  'for the campaign donation':
    'for the campaign donation',
  'email thank you': 'Email thank you',
  'create donor': 'Create donor',
  'edit donor': 'Edit donor',
  'recent givings': 'Recent givings',
  'edit beneficiary':
    'Edit beneficiary',
  'account not linked yet':
    'Account not linked yet',
  'account not linked yet des':
    'You have not yet linked to your Shareshare account, click here to link.',
  'merge donor': 'Merge donor',
  'selected donor': 'Selected donor',
  'merge with': 'Merge with',
  'beneficiary share':
    'Beneficiary introduce',
  'total giving events':
    'Total giving events',
  'total giving value':
    'Total giving value',
  'total beneficiaries':
    'Total beneficiaries',
  'merge beneficiary':
    'Merge beneficiary',
  'staffs list': 'Members',
  'recent recruitments':
    'Recent recruitments',
  'staff roster': 'Staff roster',
  staff: 'Member',
  collaborator: 'Collaborator',
  intern: 'Intern',
  'employee type': 'Member type',
  'edit staff': 'Edit member',
  'create staff': 'Create member',
  'display with anonymous':
    'Display as anonymous sponsor',
  'create staff roster':
    'Create staff roster',
  'position placeholder':
    'Example: Support staff',
  'activity/organization':
    'Activity / Organization',
  'total staffs': 'Total members',
  'total recruitment event':
    'Total recruitments',
  'total user register':
    'Total user register',
  'edit staff roster':
    'Edit staff roster',
  'staff information':
    'Member information',
  'create timesheets':
    'Create timesheets',
  'edit timesheets': 'Edit timesheets',
  'total time work': 'Total time work',
  'top staff area':
    'Top staff distribution area',
  'top staff time work':
    'Top staff time work donation',
  'total hours': 'Total hours',
  'address placeholder':
    'Example: No. 10 street 10',
  'introduce to': 'Introduce to',
  'find donor': 'Find donor',
  'alias name placeholder':
    'Example: John Mark',
  'country placeholder':
    'Example: England',
  'phone placeholder':
    'Example: 0987654321',
  'email placeholder':
    'Example: example@email.com',
  'choose date placeholder':
    'Example: 01/01/2021',
  'top giving value':
    'Top giving value',
  'top giving count':
    'Top giving count',
  'you choose channel':
    'You choose channel',
  'channel post': "Channel's posts",
  'total location': 'Total location',
  'Click here to search for benefactors':
    'Click here to search for benefactors',
  'You can find a benefactor or add another benefactor':
    'You can find a benefactor or add another benefactor',
  'Click here to create a new one':
    '(Click here to create a new one)',
  'new donor': 'New donor',
  'referral list': 'Referral list',
  'find referral': 'Find referral',
  'not added yet': 'Not added yet',
  'merge note description':
    'This action cannot be undo, you should consider carefully before proceeding with this action.',
  'find beneficiary':
    'Find beneficiary',
  'share note description':
    'This action will share management rights with another unit, are you sure of that.',
  'receiver name': 'Receiver name',
  'register for': 'Register for',
  'Click here to find referrer information':
    'Click here to find referrer information',
  'You can find a referrer or add a new one':
    'You can find a referrer or add a new one',
  'new beneficiary': 'New beneficiary',
  'Click here to find beneficiary information':
    'Click here to find beneficiary information',
  'You can find a beneficiary or add another beneficiary':
    'You can find a beneficiary or add another beneficiary',
  'Click here to find an account':
    'Click here to find an account',
  "You can find the user's account and add":
    "You can find the user's account and add",
  'recent candidates':
    'Recent candidates',
  'recruitment list':
    'Recruitment list',
  'donation count': 'Donation count',
  'donation insights':
    'Donation insights',
  'list of recipients':
    'List of recipients',
  'giving insights': 'Giving insights',
  'share beneficiary':
    'Share beneficiary',
  'to shared': 'To shared',
  'this page is for organizations only create an organization':
    'This page is for organizations only create an organization',
  'Sign in for free to experience the features':
    'Sign in for free to experience the features',
  'Keep personalized tracking in the field of volunteering':
    'Keep personalized tracking in the field of volunteering.',
  'Join and update information from the community':
    'Join and update information from the community.',
  'Keep track of contributions, participating activities':
    'Keep track of contributions, participating activities.',
  'Find suggestions for suitable volunteer work':
    'Find suggestions for suitable volunteer work.',
  'create account': 'Create account',
  'Create an account for your organization or charity ':
    'Create an account for your organization or charity. ',
  'The platform supports a free tool to help transform the number of philanthropic activities':
    'The platform supports a free tool to help transform the number of philanthropic activities.',
  'Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization':
    'Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization.',
  'Organizational management features: manage donors, beneficiaries, human resources':
    'Organizational management features: manage donors, beneficiaries, human resources.',
  'extra information':
    'Extra information',
  'value placeholder':
    'Example: abc....',
  'datetime placeholder':
    'Example: 01/01/2000',
  'number placeholder':
    'Example: 012345...',
  'field name': 'Field name',
  'year of birth': 'Year of birth',
  'remember me': 'Remember me',
  'it received': 'Received',
  'clone item': 'Duplicate',
  'clone beneficiary':
    'Clone beneficiary',
  'clone note description':
    "With this action, you can edit the beneficiary's information without affecting the other organization's information.",
  'this year': 'This year',
  'last year': 'Last year',
  'last 3 years': 'Last 3 years',
  showing: 'Showing',
  'Donate to the campaign by cash or bank transfer':
    'Donate to the campaign by cash or bank transfer',
  'Donate to the campaign with items':
    'Donate to the campaign with items',
  'Contributions in kind can be converted to the corresponding value at the time of donation':
    'Contributions in kind can be converted to the corresponding value at the time of donation',
  'Giving in kind can be converted into equivalent value at the time of giving':
    'Giving in kind can be converted into equivalent value at the time of giving',
  'Give to beneficiary by cash or bank transfer':
    'Give to beneficiary by cash or bank transfer',
  'Give the beneficiary with the items':
    'Give the beneficiary with the items',
  reconnect: 'Reconnect',
  network: 'Network',
  'network offline': 'Network offline',
  'list of co-hosting':
    'List of co-hosting',
  'list of co-owners':
    'List of co-owners',
  'co-hosting': 'Co-hosting',
  'do you want to leave the position of host of this campaign':
    'Do you want to leave the position of host of this campaign ?',
  'co-owners': 'Co-owners',
  refused: 'Refused',
  'do you want to decline the invitation':
    'Do you want to decline the invitation ?',
  'host type': 'Host type',
  'owner type': 'Owner type',
  'staff list': 'Members',
  'related activities':
    'Related activities',
  'related giving events':
    'Related giving events',
  'related donation events':
    'Related donation events',
  'related recruitments':
    'Related recruitments',
  'related events': 'Related events',
  'peer organization':
    'Peer organization',
  'peer organization list':
    'Peer organization list',
  'total peer organization':
    'Total peer organization',
  'has left': 'has left',
  'total hosts': 'Total hosts',
  'total members': 'Total members',
  'my host request': 'List of members',
  'my-host-request': 'List of members',
  'list of members': 'List of members',
  'activity of co-owners':
    'Activity of co-owners',
  medals: 'Medals',
  'medal list': 'Medal list',
  'medal manager': 'Medal manager',
  'medal summary': 'Medal summary',
  'create medal': 'Create medal',
  'edit medal': 'Edit medal',
  'total staff-rosters':
    'Total staff-rosters',
  'type desc': 'Type desc',
  'type asc': 'Type asc',
  'medal awards': 'Medal awards',
  'medal rewards': 'Medal rewards',
  'medal reward list':
    'Medal reward list',
  'give medal': 'Give medal',
  'effect date': 'Effect date',
  'medal award manager':
    'Medal award manager',
  'pending givings': 'Pending givings',
  'organizational structure':
    'Organizational structure',
  operating: 'Operating',
  'activities of member':
    'Activities of member',
  'list of medal': 'List of medal',
  'level placeholder':
    'Example: 1-Diamond, 2-Gold, 3-Silver, ...',
  staffs: 'Staffs',
  'activity summaries':
    'Activity summaries',
  'donation summaries':
    'Donation summaries',
  'givings summaries':
    'Givings summaries',
  'staffs summaries':
    'Staffs summaries',
  'recruitment events': 'Recruitments',
  'organization unit reports':
    'Organization unit reports',
  '{value1} / {value2}: Number of organizations / total number of member organizations':
    '{value1} / {value2}: Number of organizations / total number of member organizations',
  contributions: 'Contributions',
  'waiting value': 'Waiting value',
  'giving count': 'Giving count',
  'spent my precious time at work':
    'spent my precious time at work',
  'We sincerely appreciate your dedication of time and effort to making this life better':
    'We sincerely appreciate your dedication of time and effort to making this life better.',
  'We would like to award this medal to mark your contribution':
    'We would like to award this medal to mark your contribution.',
  'Wishing you always full of health, peace and enthusiasm. Sincerely thank you.':
    'Wishing you always full of health, peace and enthusiasm. \nSincerely thank you.',
  'staff received': 'Member received',
  'donor received': 'Donor received',
  'register list': 'Register list',
  'ML (blood unit)': 'ML (blood unit)',
  'choose organization':
    'Choose organization',
  'total events': 'Total events',
  'total activities':
    'Total activities',
  'total donations': 'Total donations',
  'total waiting value':
    'Total register value',
  'total givings value':
    'Total givings value',
  'total givings target':
    'Total givings target',
  'total givings': 'Total givings',
  'total collaborator':
    'Total collaborator',
  'total intern': 'Total intern',
  'total staff': 'Total members',
  'total volunteer': 'Total volunteer',
  'history medal rewards':
    'History medal rewards',
  'list of staff roster':
    'List of staff roster',
  'donation of organization':
    'Donation of organization',
  'giving of organization':
    'Giving of organization',
  certifications: 'Certifications',
  'price (VND)': 'Price (VND)',
  'access rights': 'Access rights',
  'contact list': 'Contact list',
  'what is your organization name?':
    'What is your organization name ?',
  sponsor: 'Sponsor ',
  'disable on the personal page':
    'Disable on the personal page',
  'disable donor on page description':
    'This action will hide information related to your donation on your personal page',
  'disable beneficiary on page description':
    'This action will hide information related to your beneficiary on your personal page',
  'disable work on page description':
    'This action will hide information related to your work on your personal page',
  'import donation data':
    'Import donation data',
  import: 'Import data',
  'you are importing campaign data':
    'You are importing campaign data',
  'preview template':
    'Preview template',
  'limit description':
    'File size does not exceed',
  'template data': 'Template data',
  'notes description':
    'Explanation of the columns in the sample file',
  'campaign id code':
    'Campaign id code',
  'contributed date':
    'Contributed date',
  "donor's name": "Donor's name",
  "donor's owner id":
    "Donor's owner id",
  'donation quantity':
    'Donation quantity',
  'donation unit': 'Donation unit',
  'exchange value': 'Exchange value',
  'donation note': 'Donation note',
  'additional fields for information':
    'Additional fields for information',
  "beneficiary's name":
    "Beneficiary's name",
  'beneficiary address':
    'Beneficiary address',
  "beneficiary's owner id":
    "Beneficiary's owner id",
  'giving quantity': 'Giving quantity',
  'giving unit': 'Giving unit',
  'giving note': 'Giving note',
  'import giving data':
    'Import giving data',
  'Extract via campaign url path':
    'Extract via campaign url path',
  'For example': '\n For example',
  'Date is required':
    'Date is required',
  'Including 4 characters, capitalization, unique':
    'Including 4 characters, capitalization, unique',
  'Value must be YES or NO':
    'Value must be YES or NO',
  'Value must be money, goods or blood':
    'Value must be "MONEY", "GOODS" or "BLOOD"',
  'Number is required':
    'Number is required',
  'Value must be male, female or null':
    'Value must be "male", "female" or empty',
  'current size': 'Current size',
  'max size': 'Max size',
  'total row': 'Total row',
  'success row': 'Success row',
  row: 'row',
  'upload file': 'Upload file',
  'question from the campaign':
    'Question from the campaign',
  START: 'Start',
  FINISH: 'Finish',
  node: 'Node',
  'send a confirmation letter for the donation':
    'Send a confirmation letter for the donation',
  'link page': 'Link page',
  'list of partners':
    'List of partners',
  'short name': 'Short name',
  'first name': 'First name',
  'last name': 'Last name',
  'alternative email':
    'Alternative email',
  'create partners': 'Create partner',
  'edit partners': 'Edit partners',
  'delete partner': 'Delete partner',
  'are you sure you want to delete this partner':
    'Are you sure you want to delete this partner ?',
  'total org members': 'Org members',
  'total org partners': 'Org partners',
  'total org link': 'Org link',
  Volunteer: 'Volunteer',
  Administrator: 'Administrator',
  'Office staff': 'Office staff',
  'Part-time employee':
    'Part-time employee',
  Assistant: 'Assistant',
  Director: 'Director',
  Supporter: 'Supporter',
  'total other activities':
    'Total other activities',
  'total active donation events':
    'Total active donation events',
  'total active giving events':
    'Total active giving events',
  'total recruitments':
    'Total recruitments',
  'total incoming events':
    'incoming events',
  'input partner name':
    'Input partner name',
  'select account wishare':
    'Select account Wishare',
  'total active recruitments events':
    'Total active recruitments events',
  'subcategories organization':
    'Organization subcategories',
  'create subcategories organization':
    'Create category',
  'update subcategories organization':
    'Update category',
  'subcategories activity':
    'Activity subcategories',
  'create subcategories activity':
    'Create category',
  'update subcategories activity':
    'Update category',
  'subcategories': 'Subcategories',
  "remove this":'Remove this ?',
  "list of channels":'List of channels',
  "introduce channel":'Introduce channel',
  "short description about your channel":'Short description about your channel',
  "share discussion":'Share discussion',
  "choose category":'Choose category',
  "parent of subcategory":'Parent of subcategory',
  "hours":'hours',
  "choose another Wishare subscription":'Choose another Wishare subscription',
  "filter conditions by subcategory": 'Filter conditions by subcategory',
  "value to contribute": 'Value to contribute',
  "can giving": 'Can giving',
  "got it":'Got it',
  "link url is invalid!":'Link url is invalid !',
  "Saving...":'Saving...',
  "Saved!":'Saved !',
  "description question":'Description question',
  "type the answer you want here and select create":'Type the answer you want here and select "Create answer"',
  "create answer":'Create answer',
  "ex: 01/01/2021 - 12:00:00":'Ex: 01/01/2021 - 12:00:00',
  "beneficiary_count":'Beneficiary',
  "givings count":'Givings count',
  "transfer ownership":'Transfer ownership',
  "Please log in to Wishare to leave your comment in the post":'Please log in to Wishare to leave your comment in the post',
  "sanitary-food":'Sanitary food',
  "at":'at',
  "posted on channel":'posted on channel',
  "post at":'posted at',
  "transfer of ownership":'Transfer of ownership',
  "you definitely want to transfer ownership to someone else":'You definitely want to transfer ownership to someone else. And this action cannot be undone. Are you sure ?',

}
