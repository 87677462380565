import {Avatar, Button} from 'antd'
import ImageUpload from 'components/form/fields/ImageUpload'
import {createValue} from 'components/form/utils'
import ImageLoader from 'components/ImageLoader'
import {PhotoPlaceholderSiteApp} from 'conts/imageBase64'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoCloudUploadOutline, IoTrashOutline,} from 'react-icons/io5'
import {Null, renderSelf,} from 'views/Shared'

const PhotoUploadWrapper = ({
  children,
}) => (
  <div
    style={{ minHeight: '30vw' }}
    className="flex flex-col relative space-y-3">
    {children}
  </div>
)

const PhotoField = ({
  name,
  value,
  values,
  onChange = Null,
  getPreviewName = (name) =>
    `${name}_preview`,
  Wrapper = PhotoUploadWrapper,
}) => {
  const t = useTranslate()

  const previewName =
    getPreviewName(name)

  const previewImage = _.get(
    values,
    previewName,
    value
  )

  return (
    <Wrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '30vw',
        }}
        className="border rounded-md flex flex-1 flex-center overflow-hidden custom-photo-field">
        {previewImage && (
          <ImageLoader
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              background: '#f1f1f1',
              borderRadius: '0.375rem',
            }}
            src={previewImage}
            alt="photo upload"
          />
        )}
        <div className="flex flex-col justify-center items-center space-y-2 absolute">
          <div className="flex flex-col items-center">
            <Button
              className="rounded-lg text-red-500 btn-delete-upload-img border-none"
              icon={<IoTrashOutline />}
              onClick={() => {
                onChange(
                  createValue(
                    name,
                    null
                  )
                )
                onChange(
                  createValue(
                    previewName,
                    null
                  )
                )
              }}
            />
          </div>
          <ImageUpload
            name={name}
            value={value}
            maxWidth={5120}
            maxHeight={5120}
            onChange={onChange}
            renderPreview={Null}
            showUploadList={false}
            getPreviewName={
              getPreviewName
            }>
            <Button className="leading-none flex items-center gap-1 rounded-lg btn-upload-img border-none">
              <IoCloudUploadOutline />
              {`${t('add photo')} (1280 x 720)`}
            </Button>
          </ImageUpload>
        </div>
      </div>
    </Wrapper>
  )
}

const defautltFieldNames = {
  avatar: 'avatar',
  cover_photo: 'cover_photo',
  avatar_preview: 'avatar_preview',
  cover_photo_preview:
    'cover_photo_preview',
}

export const PhotoUploadComposite = ({
  title,
  values,
  onChange,
  renderAvatar = renderSelf,
  renderCoverUploader = renderSelf,
  renderAvatarUploader = renderSelf,
  fieldNames = defautltFieldNames,
  fallback_cover = PhotoPlaceholderSiteApp,
}) => (
  <div
    style={{ zIndex: 11 }}
    className="w-full relative HomeHeader overflow-hidden">
    <div
      className="w-full relative overflow-hidden rounded-md"
      style={{
        height: '200px',
        background: 'var(--background)',
      }}>
      <ImageLoader
        style={{
          top: 0,
          left: 0,
          position: 'absolute',
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          background:
            'var(--background)',
          // borderTopLeftRadius: '0.5rem',
          // borderTopRightRadius:
          //   '0.5rem',
        }}
        holder={fallback_cover}
        prefixCls="flex"
        src={
          _.get(
            values,
            fieldNames.cover_photo_preview
          ) || 'invalid cover photo'
        }
        alt=""
      />
      <div
        className="w-full absolute top-0 left-0"
        style={{
          height: '200px',
          objectFit: 'cover',
        }}
      />
      <div
          style={{padding: '0.9em'}}
          className="w-full flex flex-col absolute bottom-0 left-0">
        {renderAvatar(
          <div className="pb-3 flex items-end">
            <Avatar
              size={80}
              className="border-2 border-color-50 background-100 rounded-lg flex flex-center font-bold"
              src={_.get(values, fieldNames.avatar_preview) || 'invalid avatar'}
              shape="square">
              <span className="text-4xl uppercase">
                {title && String(title).charAt(0)}
              </span>
            </Avatar>
          </div>
        )}
        <Translate>
          {(t) => (
            <div className="flex justify-around">
              {renderAvatarUploader(
                <ImageUpload
                  onChange={onChange}
                  renderPreview={Null}
                  showUploadList={false}
                  name={
                    fieldNames.avatar
                  }>
                  <div className="px-3 py-1 flex items-center rounded-lg space-x-2 btn-upload-img">
                      <IoCloudUploadOutline />
                      <div className="flex flex-col leading-tight">
                          <span className="text-xs">{t('avatar')}</span>
                          <span className="text-2xs font-light italic opacity-50">{'(128 x 128)'}</span>
                      </div>
                  </div>
                </ImageUpload>
              )}
              {renderCoverUploader(
                <ImageUpload
                  onChange={onChange}
                  maxWidth={1920}
                  maxHeight={384}
                  minWidth={1366}
                  minHeight={274}
                  renderPreview={Null}
                  showUploadList={false}
                  name={
                    fieldNames.cover_photo
                  }>
                  <div className="px-3 py-1 flex items-center rounded-lg space-x-2 btn-upload-img">
                      <IoCloudUploadOutline />
                      <div className="flex flex-col leading-tight">
                          <span className="text-xs">{t('cover')}</span>
                        <span className="text-2xs font-light italic opacity-50">{'(1366 x 274)'}</span>
                      </div>
                  </div>
                </ImageUpload>
              )}
            </div>
          )}
        </Translate>
      </div>
    </div>
  </div>
)

export const PhotoArrayUpload = ({
  values,
  onChange,
  slots = [],
  name: fieldName,
}) => {
  const t = useTranslate()
  return (
    <div
      id="photos"
      className="flex flex-col relative space-y-3 py-3">
        <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
        {t('Pictures on the home page')}
      </div>
        <div className="text-color-300 text-sm italic font-light">
        {t(
          "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG."
        )}
      </div>
      {Array.from(slots || []).map(
        ({ name, preview }, index) => {
          const previewPhoto = _.get(
            values,
            preview
          )
          return (
            <div
              key={index}
              className="w-full relative background-100 rounded-lg"
              style={{height: '234px'}}>
              {previewPhoto && (
                <ImageLoader
                    style={{height: '234px'}}
                    prefixCls="flex"
                    className="w-full absolute background object-cover top-0 left-0 rounded-lg"
                    src={previewPhoto}
                />
              )}
              <div className="flex flex-col h-full items-center justify-center space-y-2">
                <div className="flex flex-col items-center">
                  <Button
                    className="rounded-lg text-red-500 btn-delete-upload-img border-none"
                    onClick={() => {
                      onChange(
                        createValue(
                          name,
                          null
                        )
                      )
                      onChange(
                        createValue(
                          preview,
                          null
                        )
                      )
                    }}>
                    <IoTrashOutline />
                  </Button>
                </div>
                <ImageUpload
                  name={name}
                  showUploadList={false}
                  onChange={onChange}
                  renderPreview={Null}
                  maxWidth={1920}
                  maxHeight={384}
                  minWidth={1366}
                  minHeight={274}>
                  <Button className="flex items-center gap-1 rounded-lg btn-upload-img border-none">
                    <IoCloudUploadOutline />
                    <span>
                      {t('add photo')}{' '}
                      {index} (1366 x 274)
                    </span>
                  </Button>
                </ImageUpload>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

export default PhotoField
