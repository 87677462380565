import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import _ from "lodash"
import {ImpactCategories} from "../../../../components/Categories/CategoriesXS";
import RecruitmentContext from "../RecruitmentContext";
import {FlexCol, ProgressBar} from "../../Templates/ItemTemplate";
import moment from "moment";
import numeral from 'numeral';
import {Tag} from "antd";


const RecruitmentSummary = React.memo(
  ({Wrapper = 'div', ...props}) => {

    const {isSm} = useContext(
      LayoutContext
    )

    const t = useTranslate()

    const {recruitment: item} = useContext(
      RecruitmentContext
    )

    const min_age = _.get(item, 'min_age')

    const salary = Number(_.get(item, 'salary'))
    const min_salary = Number(_.get(item, 'min_salary'))
    const max_salary = Number(_.get(item, 'max_salary'))
    const salary_unit = _.get(item, 'salary_unit_name')

    const time_close = moment(_.get(item, 'time_close'));
    const now = moment();

    if (!item) return null;

    return (
      <Wrapper className="flex flex-col space-y-3">
        <div className="flex items-center justify-start py-2 text-gray-600 font-bold text-xl uppercase">
          {t('toward impacts')}
        </div>
        <div className="flex flex-col space-y-1">
          <ImpactCategories categories={_.get(item, 'impact_categories', [])}/>
        </div>
        <div className="p-2 border rounded-lg">
          <div className="flex justify-between mb-3">
            <FlexCol>
                    <span className="text-xs text-color-400 font-light">
                        {t('age')}
                    </span>
                    <span className="text-color-000 text-sm font-semibold leading-tight">
                        {min_age || t('no limit')}
                    </span>
            </FlexCol>
            <FlexCol className="text-right">
                <span className="text-xs text-color-400 font-light">
                    {t('salary')}
                </span>
                {
                    salary !== 0 ? <span className="text-color-000 text-sm font-semibold leading-tight uppercase">
                            {numeral(salary).format('0.0a')} {' '} {salary_unit}
                        </span>
                        : min_salary !== 0 ? <span className="text-color-000 text-sm font-semibold leading-tight uppercase">
                            {numeral(min_salary).format('0.0a')} {' '} {salary_unit}
                        </span>
                        : max_salary !== 0 ? <span className="text-color-000 text-sm font-semibold leading-tight uppercase">
                            {numeral(max_salary).format('0.0a')} {' '} {salary_unit}
                        </span>
                        : <span className="text-color-000 text-sm font-semibold leading-tight">
                            {t('not updated')}
                        </span>
                }
            </FlexCol>
          </div>
          <ProgressBar
            eventItem={item}
            total={_.get(item, 'target')}
            current={_.get(item, 'total.employees')}
            unit={t('person')}
            showLabel= {false}
          />
          <div className="flex items-center justify-between mt-3">
            <div className="flex space-x-1 text-xs italic">
              <span className="text-color-400">{`${t('expires')} :`}</span>
              <span className="text-color-000 font-semibold">
                        {moment(_.get(item, 'time_close')).format("DD-MM-YYYY")}
                    </span>
            </div>
            {
              now > time_close ?
                <Tag className="mr-0 text-xs italic" color="success">{t('finished')}</Tag> :
                <Tag className="mr-0 text-xs italic" color="processing">{t('processing')}</Tag>
            }

          </div>
        </div>
      </Wrapper>
    )
  }
)

export default RecruitmentSummary
