import {Input} from 'antd'
import {organizationModel} from 'apis/model'
import {partners_addPartner_Api, partners_editPartner_Api,} from 'apis/organizationPartner'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import {createValue} from 'components/form/utils'
import DebounceSelect, {fetchLookupByTypeList,} from 'components/LookupField/DebounceSelect'
import {Field, Form} from 'formik'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {compose, mapProps, pure,} from 'recompose'
import {SelectWidget} from 'views/Wishare/custom/ReactSelectComponents'

export const PartnerTypes =
  Object.freeze({
    VENDOR: 'vendor',
    CUSTOMER: 'customer',
  })

const formSchema = [
  {
    title: 'partner type',
    children: [
      {
        title: 'partner type',
        name: 'partner_tags',
        type: 'text',
        component: compose(
          withTranslate,
          mapProps(
            ({
              translate: t,
              onChange,
              ...props
            }) => {
              return {
                placeholder:
                  'partner type',
                options: Object.values(
                  PartnerTypes
                ).map((e) => ({
                  value: e,
                  label: t(e),
                })),
                onChange: (e) => {
                  onChange(
                    createValue(
                      'partner_tags',
                      e
                    )
                  )
                },
                className: 'w-full',
                mode: 'multiple',
                allowClear: true,
                ...props,
              }
            }
          ),
          pure
        )(SelectWidget),
      },
    ],
  },
  {
    title: 'partner name',
    children: [
      {
        title: 'partner name',
        name: 'partner_name',
        type: 'text',
        component: compose(
          mapProps((props) => {
            return {
              placeholder:
                'partner name',
              ...props,
            }
          }),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'short name',
    children: [
      {
        title: 'short name',
        name: 'partner_short_name',
        type: 'text',
        component: compose(
          mapProps((props) => {
            return {
              placeholder: 'short name',
              ...props,
            }
          }),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'contact name',
    children: [
      {
        title: 'first name',
        name: 'prefix_title',
        type: 'text',
        component: compose(
          withTranslate,
          mapProps(
            ({
              translate: t,
              onChange,
              ...props
            }) => {
              return {
                className: 'w-1/2',
                options: [
                  {
                    value: null,
                    label: 'NONE',
                  },
                  {
                    value: 'mr',
                    label: 'Mr.',
                  },
                  {
                    value: 'mrs',
                    label: 'Mrs.',
                  },
                  {
                    value: 'miss',
                    label: 'Miss.',
                  },
                  {
                    value: 'ms',
                    label: 'Ms.',
                  },
                ].map((e) => ({
                  value: e.value,
                  label: !!e.label ? t(e.label) : null,
                })),
                onChange: (e) => {
                  onChange(
                    createValue(
                      'prefix_title',
                      e
                    )
                  )
                },
                ...props,
              }
            }
          ),
          pure
        )(SelectWidget),
      },
    ],
  },
  {
    children: [
      {
        title: 'first name',
        name: 'first_name',
        type: 'text',
        component: compose(
          mapProps((props) => {
            return {
              placeholder: 'first name',
              ...props,
            }
          }),
          pure
        )(Input),
      },
      {
        title: 'last name',
        name: 'last_name',
        type: 'text',
        component: compose(
          mapProps((props) => {
            return {
              placeholder: 'last name',
              ...props,
            }
          }),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'phone',
    children: [
      {
        title: 'phone',
        name: 'phone',
        type: 'tel',
        component: compose(
          mapProps((props) => ({
            placeholder: 'phone',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'alternative phone',
    children: [
      {
        title: 'alternative phone',
        name: 'phone2',
        type: 'tel',
        component: compose(
          mapProps((props) => ({
            placeholder:
              'alternative phone',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'email',
    children: [
      {
        title: 'email',
        name: 'email',
        type: 'email',
        component: compose(
          mapProps((props) => ({
            placeholder: 'email',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'alternative email',
    children: [
      {
        title: 'alternative email',
        name: 'email2',
        type: 'email',
        component: compose(
          mapProps((props) => ({
            placeholder:
              'alternative email',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'address',
    children: [
      {
        title: 'address',
        name: 'address',
        type: 'text',
        component: compose(
          mapProps((props) => ({
            placeholder: 'address',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
  {
    title: 'tax code',
    children: [
      {
        title: 'tax code',
        name: 'tax_code',
        type: 'text',
        component: compose(
          mapProps((props) => ({
            placeholder: 'tax code',
            ...props,
          })),
          pure
        )(Input),
      },
    ],
  },
]

const formSchema2 = [
  {
    title: 'for sales notes',
    children: [
      {
        title: 'for sales notes',
        name: 'for_sales_notes',
        type: 'text',
        component: compose(
          mapProps((props) => ({
            placeholder:
              'for sales notes',
            ...props,
          })),
          pure
        )(Input.TextArea),
      },
    ],
  },
  {
    title: 'for purchase notes',
    children: [
      {
        title: 'for purchase notes',
        name: 'for_purchase_notes',
        type: 'text',
        component: compose(
          mapProps((props) => ({
            placeholder:
              'for purchase notes',
            ...props,
          })),
          pure
        )(Input.TextArea),
      },
    ],
  },
  {
    title: 'notes',
    children: [
      {
        title: 'notes',
        name: 'notes',
        type: 'text',
        component: compose(
          mapProps((props) => ({
            placeholder: 'notes',
            ...props,
          })),
          pure
        )(Input.TextArea),
      },
    ],
  },
]
const PartnerForm = ({
  t,
  item,
  errors,
  memberData,
  isLoading,
}) => {
  const SelectOrganization = compose(
    mapProps(
      ({
        onChange,
        value,
        form,
        item,
        ...rest
      }) => {
        return {
          onChange: (value, option) => {
            onChange(
              createValue(
                'partner_organization_id',
                option?.id
              )
            )
            onChange(
              createValue(
                'partner_organization_name',
                option?.value
              )
            )
          },
          loading: isLoading,
          defaultValue: value,
          fetchOptions:
            fetchLookupByTypeList,
          type: 'organization',
          name: 'partner_organization_name',
          className: 'w-full',
        }
      }
    ),
    pure
  )(DebounceSelect)

  return (
    <Form className="max-w-4xl flex flex-col p-3">
      <div className="">
        <FieldsFactory
          formSchema={formSchema}
        />
      </div>

      <Field name="partner_organization_name">
        {({ field, ...rest }) => {
          return (
            <FieldDecorator
              className="w-full col-span-2"
              name="partner_organization_name"
              errors={
                errors.partner_organization_name
              }
              label={t(
                'connect to organization'
              )}>
              <SelectOrganization
                item={item}
                {...field}
                {...rest}
              />
            </FieldDecorator>
          )
        }}
      </Field>

      <div className="">
        <FieldsFactory
          formSchema={formSchema2}
        />
      </div>

      <FormActionBar className="" />
    </Form>
  )
}
const EditPartner = ({
  item,
  partner,
  onSuccess,
}) => {
  const initialValues = {
    ...partner,
    partner_tags: !!partner.partner_tags
      ? (
          partner.partner_tags || ''
        ).split(',')
      : [],
    prefix_title: _.get(
      partner,
      'prefix_title',
      'mr'
    ),
    partner_organization_id: _.get(
      partner,
      'partner_organization.id'
    ),
    partner_organization_name: _.get(
      partner,
      'partner_organization.title'
    ),
  }
  const t = useTranslate()
  return (
    <ApiInfoForm
      onSuccess={onSuccess}
      initialValues={initialValues}
      onPreSubmit={(values) => {
        return {
          ...values,
          partner_tags: _.get(
            values,
            'partner_tags',
            []
          ).join(','),
        }
      }}
      query={{
        ':id': partner.id,
      }}
      apiInfo={
        partners_editPartner_Api
      }>
      {({
        isValid,
        values,
        errors,
        isSubmitting,
        handleReset,
        handleChange,
        handleSubmit,
      }) => (
        <PartnerForm
          {...{
            t,
            errors,
            item,
            partner,
          }}
        />
      )}
    </ApiInfoForm>
  )
}
const AddPartner = ({
  item,
  partner,
  onSuccess,
  ...props
}) => {
  const initialValues = {
    partner_tags: ['vendor'],
    prefix_title: 'mr',
  }
  const t = useTranslate()
  return (
    <ApiInfoForm
      onSuccess={onSuccess}
      initialValues={initialValues}
      onPreSubmit={(values) => {
        return {
          ...values,
          partner_tags: _.get(
            values,
            'partner_tags',
            []
          ).join(','),
        }
      }}
      query={{
        ':type': item._type,
        ':id':
          organizationModel.getId(item),
      }}
      apiInfo={partners_addPartner_Api}
      {...props}>
      {({
        isValid,
        values,
        errors,
        isSubmitting,
        handleReset,
        handleChange,
        handleSubmit,
      }) => (
        <PartnerForm
          {...{
            t,
            errors,
            item,
            partner,
          }}
        />
      )}
    </ApiInfoForm>
  )
}
export default (props) =>
  props.partner ? (
    <EditPartner {...props} />
  ) : (
    <AddPartner {...props} />
  )
