import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import BeneficiaryContext from '../BeneficiaryContext'

const BeneficiarySettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.beneficiarySettings
  return createSettingContent(
    BeneficiaryContext,
    { routes, Wrapper, rootPath }
  )
}

export default BeneficiarySettingsContent
