import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { createValue } from '../utils'

const DefaultWrapper = ({
  style,
  children,
  className,
}) => (
  <div
    style={style}
    className={classNames(
      'flex flex-col px-2 py-1 border rounded-lg background',
      className
    )}>
    {children}
  </div>
)

export const OriginalObjectSelector = ({
  label,
  style,
  onSearch,
  Wrapper = DefaultWrapper,
  ...props
}) => {
  const t = useTranslate()

  const [search, setSearch] = useState()

  return (
    <Wrapper
      {...{ style: { minHeight: 65 } }}>
      {label && (
        <div className="text-xs font-light italic text-color-400 px-2 mb-1">
          {t(label)}
        </div>
      )}
      <SelectWidget
        placeholder={t(
          'click to select'
        )}
        {...props}
        onSearch={
          _.isFunction(onSearch)
            ? (text) => {
                setSearch(text)
                onSearch(text)
              }
            : undefined
        }
        style={{
          caretColor: _.isEmpty(search)
            ? 'transparent'
            : 'auto',
          ...(style || {}),
        }}
      />
    </Wrapper>
  )
}

export const ObjectSelectField = ({
  label,
  onChange,
  invisible,
  dependencies = [
    {
      name: undefined,
      value: 'type',
      suffix: 'type',
      ignored: false,
      autoClear: true,
    },
  ],
  Wrapper = DefaultWrapper,
  ...props
}) => (
  <OriginalObjectSelector
    label={label}
    Wrapper={Wrapper}
    onChange={(value, option) => {
      setTimeout(() => {
        for (
          let index = 0;
          index < dependencies.length;
          index++
        ) {
          const element =
            dependencies[index]
          const {
            name,
            value,
            suffix,
            ignored = false,
            autoClear = true,
          } = _.isObject(element)
            ? element
            : {
                name: element,
                value: element,
              }
          if (
            ignored ||
            Boolean(
              !autoClear && !option
            )
          ) {
            continue
          }
          onChange(
            createValue(
              name ||
                `${props.name}_${suffix}`,
              _.get(option, value)
            )
          )
        }
        onChange(
          createValue(
            `${props.name}_id`,
            option?.id
          )
        )
        onChange(
          createValue(props.name, value)
        )
      }, 0)
    }}
    {...props}
  />
)
