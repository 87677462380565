import { Avatar } from 'antd'
import Count from 'components/Feed/Count'
import Timestamp from 'components/Feed/Timestamp'
import ImageLoader from 'components/ImageLoader'
import SeparatedDot from 'components/SeparatedDot'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { FaComment } from 'react-icons/fa'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom'
import { CategoriesXS } from "../../../components/Categories/CategoriesXS"
import {OverlayItemBanner} from "../../Wishare/Templates/ItemTemplate";
import {WishareEntities} from "../../Wishare/enums";
import getLinkToDetail from "../../../helpers/getLinkToDetail";
import getTitle from "../../../helpers/getTitle";


export const ArticleCompactItem = ({item}) => {
  return (
      <div className="relative w-full border border-color-50 rounded-lg background">
        <OverlayItemBanner type={WishareEntities.ARTICLE}/>
        <div className="h-7"/>
        <div className="flex items-center px-3 py-2">
          <Avatar className="background-100" src={_.get(item, 'owner.avatar')}>
            <span className="capitalize text-color-100">
              {_.first(_.get(item, 'owner.title') || _.get(item, 'owner.name'))}
            </span>
          </Avatar>
          <div className="flex-col flex-1 mx-3">
            <Link
                to={getLinkToDetail(item)}
                className="cursor-pointer font-bold leading-tight max-lines-1">
              {getTitle(item)}
            </Link>
            <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
                {_.get(item, 'owner.title') || _.get(item, 'owner.name')}
            </span>
          </div>
        </div>
      </div>
  )
}

function ArticleItem({
  title,
  subTitle = 'wishare',
  categories = [],
  keywords = [],
  image,
  created,
  description,
  url,
  auth_avatar,
  auth_name,
  total_comments
}) {
  const history = useHistory()
  const t = useTranslate()
  return (
    <div className="md:p-2 block md:border-t verticalList__small border-gray-300 hover:border-green-600">
      <div className="flex">
        <div className="relative flex-1 space-y-3 break-words pr-1 overflow-hidden">
          <div className="flex flex-col">
            <h3 className="font-medium md:font-medium text-lg leading-snug md:leading-normal">
              <Link
                to={{
                  pathname: url
                }}
                style={{
                  maxHeight: '3rem',
                  overflow: 'hidden'
                }}>
                {_.truncate(title, { length: 112, omission: '...' })}
              </Link>
            </h3>
            <div className="truncate text-color-300 flex flex-1 text-sm leading-loose">
              {[
                <Timestamp
                  timestamp={created}
                />,
                total_comments > 0 && (
                  <Count
                    defaultNumber={
                      total_comments
                    }
                    onClick={() => {
                      history.push(url)
                    }}
                    key="message"
                    className=" text-sm m-0"
                    component={() => (
                      <FaComment
                        style={{
                          fontSize:
                            '0.8rem',
                          fontWeight:
                            '200'
                        }}
                      />
                    )}
                  />
                )
              ]
                .filter(item => item)
                .map((item, i, arr) => {
                  return (
                    <React.Fragment
                      key={i}>
                      {item}
                      {i <
                        arr.length -
                        1 && (
                          <SeparatedDot />
                        )}
                    </React.Fragment>
                  )
                })}
            </div>
            <div className="flex flex-col items-start hidden lg:flex">
              <div className="flex flex-1 items-center">
                <Avatar
                  style={{
                    marginRight: '0.5rem'
                  }}
                  size="small"
                  shape="circle"
                  alt={auth_name}
                  src={auth_avatar}>
                  {_.truncate(auth_name, {
                    length: 1
                  })}
                </Avatar>
                <div className="truncate flex-1 text-sm flex flex-row space-x-2 items-center">
                  <span className="font-medium">{auth_name}</span>
                  <span className="text-color-300 lowercase">{t('send')}</span>
                </div>
              </div>
              <CategoriesXS categories={categories} />
            </div>
          </div>

        </div>
        {image && (
          <Link
            to={{
              pathname: url
            }}
            style={{
              maxWidth: '33%',
              maxHeight: '22vw'
            }}
            className="w-40 rounded background-100 overflow-hidden h-32 flex-shrink-0 mt-2">
            <ImageLoader
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              alt={title}
              src={image}
            />
          </Link>
        )}
      </div>

    </div>
  )
}

export default ArticleItem
