import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {mapProps} from 'recompose'
import {renderElse} from 'views/Shared'

export const TargetDisplayField =
  mapProps(({value}) => ({value}))(
    ({value}) =>
      renderElse(
        _.isEmpty(value),
        <Translate>
          {(t) => (
            <ul
              style={{
                paddingLeft: 20,
                listStyleType: 'disc',
              }}
              className="p-2 space-y-2 text-xs">
              {Array.from(
                value || []
              ).map(
                (
                  {
                    target,
                    type_cd,
                    unit_name,
                    item_name,
                    no_limit_flag
                  },
                  index
                ) => (
                  <li key={index}>
                    <div className="flex items-center justify-between w-full gap-2">
                      <div className="space-x-1">
                        <span className="text-color-400 italic">
                          {
                            item_name === unit_name
                              ? `${t(
                              type_cd
                              )}: `
                              : `${t(
                              item_name
                              )}: `
                          }
                        </span>
                        {
                          no_limit_flag ?  t('no limit') : (
                            <React.Fragment>
              <span className="font-semibold text-color-000">
                {Number(target).toLocaleString()}
              </span>
                              <span className="font-semibold text-color-000">
                {unit_name}
              </span>
                            </React.Fragment>
                          )
                        }
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}
        </Translate>
      )
  )
