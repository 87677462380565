import React from "react";

export const SanitaryFood = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 54.819 54.819"
			version="1.1"
			viewBox="0 0 54.819 54.819"
			xmlSpace="preserve"
		>
			<path
				fill="#436B1C"
				d="M11.217 19.913a1 1 0 01-.852-.476c-.614-.995-1.761-2.146-3.078-2.104-.56-.03-1.014-.418-1.03-.969a1 1 0 01.969-1.031c2.107-.063 3.904 1.534 4.841 3.055a1 1 0 01-.85 1.525z"
			></path>
			<path
				fill="#88C057"
				d="M31.684 6.597a3.746 3.746 0 00-3.735-3.488l-.017.001a2.319 2.319 0 00-.08-.932C27.584 1.264 26.78.562 25.834.449a2.404 2.404 0 00-1.987.679A2.656 2.656 0 0022.06.431a2.677 2.677 0 00-2.677 2.677l.001.014a3.478 3.478 0 00-3.214 3.467c0 .28.037.551.099.812a4.013 4.013 0 00-3.847 4.008c0 .732.199 1.416.541 2.007a2.94 2.94 0 00-1.077 2.276c0 .838.355 1.589.917 2.124 2.412-1.971 4.946 4.621 7.93 9.884.991 1.748 11.698-.114 12.551.972.972-.874 1.6-16.094 1.627-17.431a4.821 4.821 0 00-3.227-4.644z"
			></path>
			<path
				fill="#EEAF4B"
				d="M33.053 26.533l.713-.427a4.165 4.165 0 014.287 0l.713.427a4.165 4.165 0 004.287 0l2.015-.896c.296-.826.502-1.424.584-1.689 1.88-6.047 3.091-12.414 3.256-18.765.066-2.554-2.046-6.537-5.555-4.727-3.449 1.778-5.919 8.597-7.655 11.835-2.466 4.601-4.416 9.605-5.805 14.704a4.16 4.16 0 003.16-.462z"
			></path>
			<path
				fill="#ED3F32"
				d="M7.055 25.506l1.345.807a4.878 4.878 0 005.02 0l.346-.208a4.165 4.165 0 014.287 0l.713.427a4.158 4.158 0 002.531.568c.64-2.067.425-4.651-.567-6.4-3.336-5.885-6.111-4.686-8.781-2.131a4.374 4.374 0 01-1.82 1.044c-2.928.837-5.089 2.206-3.86 5.934a.704.704 0 01.786-.041z"
			></path>
			<path
				fill="#A46F3E"
				d="M35.592 24.356a1 1 0 01-.946-1.322 3.771 3.771 0 014.768-2.352 1.76 1.76 0 002.232-1.1 1 1 0 111.894.643 3.765 3.765 0 01-4.768 2.352 1.764 1.764 0 00-2.232 1.1 1.002 1.002 0 01-.948.679zM37.957 17.389a1 1 0 01-.946-1.322 3.738 3.738 0 011.897-2.164 3.733 3.733 0 012.871-.188 1.758 1.758 0 002.232-1.101 1 1 0 111.893.643 3.766 3.766 0 01-4.768 2.353c-.445-.152-.923-.121-1.344.087s-.736.567-.888 1.013a1 1 0 01-.947.679zM40.323 10.421a1 1 0 01-.947-1.322 3.306 3.306 0 014.185-2.064c.33.112.682.09.993-.063.311-.153.544-.419.656-.748a1 1 0 111.894.643 3.279 3.279 0 01-1.665 1.899 3.28 3.28 0 01-2.521.164 1.3 1.3 0 00-1.648.813 1.002 1.002 0 01-.947.678z"
			></path>
			<path
				fill="#DAE7EF"
				d="M23.37 14.095c-.945 1.257-2.436 2.745-1.862 5.373l1.544 7.065h.001l.713-.427a4.165 4.165 0 014.287 0l.713.427c.58.348 1.127.463 1.127.463a72.055 72.055 0 014.052-11.179c-.774-1.936-2.427-2.584-3.627-3.247l-.02-.011c-.759-.435-.806-1.474-.235-2.136.225-.261.329-.62.251-.98l-.121-.552a1.134 1.134 0 00-1.346-.863L22.77 9.354a1.134 1.134 0 00-.863 1.346l.121.552c.08.368.334.653.656.792.739.319 1.19 1.144.812 1.855a1.547 1.547 0 01-.126.196z"
			></path>
			<path
				fill="#FFF"
				d="M29.746 10.899l-6.879 1.503a1.419 1.419 0 01-1.683-1.079l-.099-.452a1.419 1.419 0 011.079-1.683l6.879-1.503a1.419 1.419 0 011.683 1.079l.099.452c.166.76-.32 1.517-1.079 1.683z"
			></path>
			<path
				fill="#FBD490"
				d="M46.816 25.332l-1.745 25.029a4.699 4.699 0 01-4.693 4.459H12.307a4.699 4.699 0 01-4.693-4.459L5.911 26.153a.712.712 0 011.077-.647l1.345.807a4.878 4.878 0 005.02 0l.346-.208a4.165 4.165 0 014.287 0l.713.428a4.165 4.165 0 004.287 0l.713-.428a4.165 4.165 0 014.287 0l.713.428a4.165 4.165 0 004.287 0l.713-.428a4.165 4.165 0 014.287 0l.713.428a4.165 4.165 0 004.287 0l3.387-1.505a.315.315 0 01.443.304z"
			></path>
			<path
				fill="#E0B877"
				d="M17.91 42h-7a1 1 0 110-2h7a1 1 0 110 2zM19.91 46h-9a1 1 0 110-2h9a1 1 0 110 2zM21.91 50h-11a1 1 0 110-2h11a1 1 0 110 2zM27.91 50h-2a1 1 0 110-2h2a1 1 0 110 2z"
			></path>
		</svg>
	);
}

export default SanitaryFood;
