import { Drawer } from 'antd'
import classnames from 'classnames'
import { LoginContext } from 'components/LoginContext'
import { RegAsModal } from 'components/Modal/Modal'
import Null from 'components/NullComponent'
import ShadowScrollbar from 'components/ShadowScrollbar'
import React, {
  useContext,
} from 'react'
import { UserMenu } from 'views/Account/UserMenu'
import { PageHeader } from 'views/Organization/components/PageHeader'
import { LayoutContext } from './LayoutContext'

export const MenuWraper = ({
  children,
}) => {
  return (
    <LayoutContext.Consumer>
      {({
        breakpoint,
        openMenu,
        setOpenMenu,
      }) =>
        ['xs', 'sm', 'md'].find(
          (value) =>
            value === breakpoint
        ) ? (
          <Drawer
            bodyStyle={{
              padding: 0,
              overflow: 'hidden',
            }}
            width={280}
            placement="left"
            closable={false}
            onClose={() =>
              setOpenMenu(false)
            }
            visible={openMenu}>
            {openMenu && <RegAsModal />}
            <ShadowScrollbar className="absolute top-0 left-0">
              {children}
            </ShadowScrollbar>
          </Drawer>
        ) : (
          <div className="stickyTop top-0 h-screen">
            <ShadowScrollbar className="RightSider">
              {children}
            </ShadowScrollbar>
          </div>
        )
      }
    </LayoutContext.Consumer>
  )
}
export default function ContentLayout({
  title,
  extra,
  renderHeader,
  renderMenu,
  renderRight,
  children,
  left,
  right,
  menu,
  style,
}) {
  const pageHeader = React.useMemo(
    () =>
      renderHeader ? (
        renderHeader()
      ) : (
        <PageHeader
          {...{ title, extra }}
        />
      ),
    [extra, renderHeader, title]
  )

  const { isLg } = useContext(
    LayoutContext
  )
  return (
    <>
      {renderMenu ? (
        renderMenu()
      ) : menu ? (
        <MenuWraper>
          <div className="w-full border-r border-gray-300 min-h-screen">
            {menu}
          </div>
        </MenuWraper>
      ) : (
        <LoginContext.Consumer>
          {(login) =>
            login ? (
              <MenuWraper>
                <div className="w-full">
                  <UserMenu />
                </div>
              </MenuWraper>
            ) : null
          }
        </LoginContext.Consumer>
      )}
      <main
        style={style}
        className={classnames(
          isLg
            ? 'flex flex-1 z-20 w-0 flex-col justify-start'
            : 'w-full container mx-auto'
        )}>
        {pageHeader}
        <div className="flex-1 flex flex-col">
          {children}
        </div>
        {/* <PageFooter /> */}
      </main>
      {renderRight ? (
        renderRight()
      ) : (
        <LayoutContext.Consumer>
          {({ RightLayout = Null }) => {
            return (
              <RightLayout>
                {right || left || null}
              </RightLayout>
            )
          }}
        </LayoutContext.Consumer>
      )}
    </>
  )
}
