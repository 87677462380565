import {
  Button,
  Input,
  InputNumber,
} from 'antd'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import { createValue } from 'components/form/utils'
import ForDevelop from 'envs/ForDevelop'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
  nest,
  pure,
  withProps,
} from 'recompose'
import {
  BoxPlaceholder,
  fromObject,
  renderSelf,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import AdvancedFormGroup, {
  withAdvancedOptions,
} from '../custom/AdvancedFormGroup'
import CascadedCheckbox from '../custom/CascadedCheckbox'
import ContainerPicker, {
  withOwnerResponses,
} from '../custom/ContainerPicker'
import ManualPicker from '../custom/ManualPicker'
import RadioOptions from '../custom/RadioOptions'
import SelectDataSource from '../custom/SelectDataSource'
import { WishareEntities } from '../enums'
import { createDateRangerPicker } from '../factory/createDateRangerPicker'
import createFormField, {
  createControlledFormField,
} from '../factory/createFormField'
import { fromInitialValues } from '../factory/createInitialValues'
import { mapObjectValues } from '../factory/createSelectEntityProps'
import { StepFormContext } from '../factory/createStepForm'
import {
  requiredNumber,
  requiredOneOf,
  requiredString,
} from '../factory/createValidationSchema'
import { MyProfileModal } from '../User/components/UserProfile'
import { wishareFieldTypes } from '../wishareFieldTypes'
import RecruitmentAnswerView from './ActionForms/RecruitmentAnswerView'
import RecruitmentCandidateSelector from './components/RecruitmentCandidateSelector'
import RecruitmentRequiredFields from './components/RecruitmentRequiredFields'

// Todo: implement translation

const createGroup = ({
  privacy,
  registrable,
  ...values
}) => [
  {
    invisible: true,
    children: [
      {
        name: 'invisible',
        component: nest(
          ForDevelop,
          BoxPlaceholder
        ),
      },
    ],
  },
  {
    inline: true,
    className: 'grid grid-cols-2 gap-2',
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
        value: privacy,
      },
    ],
  },
]

const advancedSchema = {
  invisible: true,
  children: [
    {
      name: 'advanced',
      component: mapProps(
        ({ form, onChange }) => {
          const _values = fromObject(
            _.get(form, 'values'),
            [
              'registrable',
              'privacy',
            ].map((e) => ({
              name: e,
            }))
          )
          return {
            onChange,
            groupValues:
              createGroup(_values),
          }
        }
      )(AdvancedFormGroup),
    },
  ],
}

export const recruitmentValidationSchema =
  {
    [CRUD.CREATE]: (
      translate = renderSelf
    ) => {
      return {
        target:
          requiredNumber(translate),
        title:
          requiredString(translate),
        privacy:
          requiredNumber(translate),
        // position_title:
        //   requiredString(translate),
        owner:
          requiredString(translate),
        target_unit_name:
          requiredString(translate),
        recruitment_type: requiredOneOf(
          mapObjectValues(
            wishareFieldTypes
              .recruitment.types
          ),
          translate
        ),
      }
    },
  }

export const recruitmentStepFormSchema =
  {
    typeStage: [
      {
        children: [
          {
            name: 'recruitment_type',
            component: compose(
              fromRenderProps(
                StepFormContext.Consumer,
                ({ onNext }) => ({
                  callback: onNext,
                })
              ),
              mapProps(
                ({
                  name,
                  form,
                  value,
                  callback,
                  onChange,
                }) => ({
                  name,
                  value,
                  onChange,
                  options: _.get(
                    form,
                    'values.recruitment_types',
                    []
                  ),
                  callback,
                })
              )
            )(ManualPicker),
          },
        ],
      },
    ],
    targetStage: [
      {
        title: 'total target',
        inline: true,
        className:
          'grid grid-cols-2 gap-2',
        children: [
          {
            name: 'target',
            component: mapProps(
              ({
                name,
                form,
                value,
                onChange,
              }) => {
                const disabled = _.get(
                  form,
                  'values.no_limit_flag',
                  false
                )
                return {
                  min: 0,
                  disabled,
                  className: 'w-full',
                  onChange: (value) => {
                    onChange(
                      createValue(
                        name,
                        value
                      )
                    )
                  },
                  value: Number(value),
                  defaultValue:
                    Number(value),
                  parser: parseNumber,
                  formatter:
                    formatterNumber,
                  placeholder: 'value',
                }
              }
            )(InputNumber),
          },
          {
            name: 'target_unit_name',
            component: mapProps(
              ({
                name,
                form,
                value,
                onChange,
              }) => {
                const disabled = _.get(
                  form,
                  'values.no_limit_flag',
                  false
                )
                const dataSource =
                  wishareFieldTypes
                    .recruitment.units
                return {
                  name,
                  value,
                  disabled,
                  onChange,
                  dataSource,
                  defaultValue: value,
                  defaultActiveFirstOption: true,
                }
              }
            )(SelectDataSource),
          },
        ],
      },
      {
        children: [
          {
            name: 'no_limit_flag',
            component: compose(
              withTranslate,
              mapProps(
                ({
                  name,
                  form,
                  value,
                  onChange,
                  translate,
                }) => ({
                  name,
                  onChange,
                  cascades:
                    fromInitialValues([
                      form,
                      [
                        'target',
                        'target_unit_name',
                      ],
                    ]),
                  children:
                    translate(
                      'no limit'
                    ),
                  defaultChecked: value,
                })
              )
            )(CascadedCheckbox),
          },
        ],
      },
    ],
    ownerStage: [
      {
        title: 'title',
        children: [
          {
            name: 'title',
            component: createFormField({
              placeholder: 'title',
            }),
          },
        ],
      },
      {
        children: [
          {
            name: 'owner',
            component: withProps(
              ({
                name,
                form,
                value,
                onChange,
              }) => {
                const default_owner =
                  _.get(
                    form,
                    'values.default_owner'
                  )
                return {
                  name,
                  onChange,
                  defaultValue:
                    getId(value) ||
                    value,
                  label: 'owner',
                  type: WishareEntities.RECRUITMENT,
                  getOption: (option) =>
                    _.get(
                      option,
                      'owner'
                    ),
                  withResponses:
                    withOwnerResponses(
                      default_owner
                    ),
                  optionIdProp:
                    'idname',
                  mapCustomProps: (
                    option,
                    optionIdProp
                  ) => {
                    const {
                      style = {},
                    } = option
                    return [
                      _.get(
                        default_owner,
                        optionIdProp
                      ),
                    ].includes(
                      _.get(
                        option,
                        optionIdProp
                      )
                    )
                      ? {
                          ...option,
                          style: {
                            ...style,
                            color:
                              'rgba(15, 83, 113, 1)',
                            backgroundColor:
                              'rgba(241, 250, 255, 1)',
                          },
                        }
                      : option
                  },
                }
              }
            )(ContainerPicker),
          },
        ],
      },
      {
        title: 'open/close',
        children: [
          {
            name: 'time_open',
            component:
              createDateRangerPicker([
                'time_open',
                'time_close',
              ]),
          },
        ],
      },
      {
        title: 'position',
        children: [
          {
            name: 'position_title',
            component: createFormField({
              placeholder: 'position',
            }),
          },
        ],
      },
      {
        inline: true,
        className:
          'grid grid-cols-2 gap-2',
        children: [
          {
            label: 'work time',
            name: 'work_time_type',
            component:
              withAdvancedOptions(
                wishareFieldTypes
                  .recruitment
                  .work_time_types
              )(RadioOptions),
          },
          {
            label: 'remote',
            name: 'is_remote_work',
            component:
              withAdvancedOptions(
                wishareFieldTypes
                  .recruitment
                  .onSiteOrRemote
              )(RadioOptions),
          },
        ],
      },
      advancedSchema,
    ],
    apply_step_1: [
      {
        title: 'account name',
        children: [
          {
            name: 'candidate',
            component:
              createControlledFormField(
                {
                  readOnly: true,
                  Component:
                    RecruitmentCandidateSelector,
                  withValues: (
                    values
                  ) => ({
                    defaultValue: _.get(
                      values,
                      'default_candidate'
                    ),
                  }),
                }
              ),
          },
        ],
      },
      {
        children: [
          {
            name: 'information_fields',
            component:
              createControlledFormField(
                {
                  Component:
                    RecruitmentRequiredFields,
                  withValues: (
                    values
                  ) => ({
                    values,
                    value: _.get(
                      values,
                      'information_values'
                    ),
                    readOnly: true,
                  }),
                }
              ),
          },
        ],
      },
    ],
    apply_step_2: [
      {
        children: [
          {
            name: 'user_profile',
            component: compose(
              withTranslate,
              mapProps(
                ({ translate }) => ({
                  render: (props) => (
                    <div className="p-2 bg-primary-50 border border-primary rounded-lg flex items-center justify-between">
                      <span className="text-primary italic font-medium">
                        {translate(
                          'do you want to change your personal information'
                        )}
                      </span>
                      <Button
                        className="rounded-lg no-shadow no-text-shadow"
                        type="primary"
                        {...props}>
                        {translate(
                          'update your profile'
                        )}
                      </Button>
                    </div>
                  ),
                })
              )
            )(MyProfileModal),
          },
        ],
      },
      {
        title: 'introduce yourself',
        children: [
          {
            name: 'description',
            component: compose(
              withTranslate,
              mapProps(
                ({
                  name,
                  value,
                  onChange,
                  translate,
                }) => ({
                  name,
                  value,
                  rows: 5,
                  onChange,
                  placeholder:
                    translate(
                      'description'
                    ),
                })
              ),
              pure
            )(Input.TextArea),
          },
        ],
      },
      {
        children: [
          {
            name: 'collection_questions',
            component:
              createControlledFormField(
                {
                  Component:
                    RecruitmentAnswerView,
                  targetName:
                    'collection_answers',
                }
              ),
          },
        ],
      },
    ],
    apply_step_3: [],
  }
