import { channel_fetchContainerByOwner_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  nest,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import { getResponseItems } from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import { OrgType } from 'views/Wishare/Organization/components/OrgType'
import PostContainer from '../Post/PostContainer'

export const InputBoardContext =
  React.createContext({ container: {} })

export const InputBoardProvider = nest(
  withProps({
    lazy: true,
  })(DelayRender),
  ({
    children,
    container,
    ...props
  }) => {
    const apiInfo =
      channel_fetchContainerByOwner_Api

    const { id, type } = container || {}

    const values = _.isEmpty(container)
      ? undefined
      : { id, type }

    const { response, isLoading } =
      useAsync({
        values,
        apiInfo,
      })

    const t = useTranslate()

    const renderLabel = useCallback(
      (item, owner) => {
        let title = getTitle(item)
        let target = item
        switch (type) {
          case WishareEntities.ACTIVITY:
            title = getTitle(owner)
            target = owner
            break
          default:
            break
        }
        return (
          <div style={{padding: '0.15rem 0'}} className="flex gap-2 items-center">
            <AppIcon
              item={target}
              size={'small'}
            />
            <div className="flex flex-col">
              <div className="truncate text-xs font-medium leading-tight">
                {title}
              </div>
              <div className="font-light text-2xs italic text-secondary leading-tight">
                {t(getType(item))}
              </div>
            </div>
          </div>
        )
      },
      [t, type]
    )

    const options = useMemo(() => {
      const array = Array.from(
        getResponseItems(response)
      ).filter(
        ({ owner, is_default }) =>
          [
            WishareEntities.USER,
            WishareEntities.ORGANIZATION,
          ].includes(getType(owner))
            ? is_default
            : true
      )
      return array.map(
        ({
          creators,
          owner,
          ...data
        }) => ({
          creators,
          id: getId(data),
          value: getId(data),
          type: getType(data),
          owner_id: getId(owner),
          label: renderLabel(
            data,
            owner
          ),
        })
      )
    }, [type, response, renderLabel])

    const getCreators = useCallback(
      (parent) => {
        const source =
          Array.from(options)
        const { creators: array = [] } =
          source.find(({ id }) =>
            _.isEqual(id, parent)
          ) || {}
        return array.map(
          ({ owner }) => ({
            id: getId(owner),
            value: getId(owner),
            type: getType(owner),
            label: (
              <div className="flex gap-2 items-center">
                <AppIcon
                  item={owner}
                  size={'small'}
                />
                <div className="flex flex-col">
                  <div className="truncate text-xs font-medium leading-tight">
                    {getTitle(owner)}
                  </div>
                  <div className="font-light text-2xs italic text-secondary leading-tight">
                    {getType(owner) ===
                    'organization' ? (
                      <OrgType
                        item={owner}
                      />
                    ) : (
                      t(getType(owner))
                    )}
                  </div>
                </div>
              </div>
            ),
          })
        )
      },
      [options]
    )

    return (
      <PostContainer {...{ id, type }}>
        <InputBoardContext.Provider
          value={{
            options,
            container,
            isLoading,
            getCreators,
            ...props,
          }}>
          {children}
        </InputBoardContext.Provider>
      </PostContainer>
    )
  }
)
