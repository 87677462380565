import React from 'react'
import {
  Null,
  renderSelf
} from 'views/Shared'

export const ExcludedMenuTypes = Object.freeze(
  {
    SPACE: 'space'
  }
)

const makeDefaultItem = ({
  key,
  icon,
  title,
  ...props
}) => ({
  key,
  icon,
  title,
  ...props
})

const makeExCludedItem = (
  type,
  { key }
) => {
  switch (type) {
    case ExcludedMenuTypes.SPACE:
      return {
        key,
        style: { flex: '1 1 0%' }
      }
    default:
      return null
  }
}

const makeCustomItem = ({
  key,
  title,
  Component
}) => ({
  key,
  title,
  icon: <Component />
})

const makeSubItem = ({
  renderLabel = renderSelf,
  validate = Null
}) => ({
  key,
  icon,
  children = []
}) => {
  return makeDefaultItem({
    key,
    icon,
    children: children.map(
      ({ key, icon, label, valid }) => {
        const isValid = validate(valid)
        if (!isValid) return null
        const title = renderLabel(label)
        return {
          key,
          icon,
          title,
          label: title,
          style: {
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize'
          }
        }
      }
    )
  })
}

const makeMenuItem = ({
  renderLabel = renderSelf,
  validate = Null
}) => ({
  type,
  label,
  valid,
  subMenu,
  component: Component,
  ...props
}) => {
  const isValid = validate(valid)
  if (!isValid) return null
  const customProps = {
    ...props,
    title: renderLabel(label)
  }
  if (
    Object.values(
      ExcludedMenuTypes
    ).includes(type)
  ) {
    return makeExCludedItem(
      type,
      customProps
    )
  }
  if (subMenu) {
    return makeSubItem({
      validate,
      renderLabel
    })({
      ...customProps,
      children: subMenu
    })
  }
  if (Component) {
    return makeCustomItem({
      Component,
      ...customProps
    })
  }
  return makeDefaultItem(customProps)
}

export {
  makeSubItem,
  makeMenuItem,
  makeCustomItem,
  makeDefaultItem,
  makeExCludedItem
}
