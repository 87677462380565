import {
  Avatar,
  Image,
  Tooltip,
} from 'antd'
import { getType } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import { ROOT_URL } from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import {
  compose,
  fromRenderProps,
  pure,
} from 'recompose'
import DonorContext from '../DonorContext'

export const DonorBanner = ({
  donor,
  Wrapper = 'div',
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const title = getTitle(donor)

  const verified = _.get(
    donor,
    'certified'
  )

  return (
    <Wrapper className="flex flex-col">
      <Image
        preview={false}
        src={`${ROOT_URL}/images/default/blue-bg.jpg`}
        className="fadeIn animated banner-activity object-cover bg-color-org"
      />
      <div className="absolute flex items-center w-full mx-auto">
        <div
          style={{
            background:
              'radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
          }}
          className="flex flex-col items-center justify-center w-full px-3 banner-activity">
          <Avatar
            size={50}
            className="flex flex-center background-100"
            src={getAvatar(donor)}
            alt={title}>
            <span className="text-color-200 font-medium">
              {_.first(title)}
            </span>
          </Avatar>
          <div className="px-3 text-xl md:text-3xl font-bold max-w-4xl text-center text-white my-0 md:my-3 leading-tight">
            {title}
            {verified === 1 && (
              <Tooltip
                title={t('verified')}
                placement="right"
                color="#87d068">
                <VerifiedSvg
                  width={
                    isSm
                      ? '1rem'
                      : '1.3rem'
                  }
                  height={
                    isSm
                      ? '1rem'
                      : '1.3rem'
                  }
                  className="ml-1 mb-1 lg:mb-2 zoom-animation"
                />
              </Tooltip>
            )}
          </div>
          <span className="text-white italic font-medium text-xs tracking-wide">
            {t(getType(donor))}
          </span>
        </div>
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({
      donor,
    })
  ),
  pure
)(DonorBanner)
