import {
  Avatar,
  Button,
  List,
  Modal,
} from 'antd'
import { search_searchObjects_Api } from 'apis'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  IoCheckmarkOutline, IoPerson,
  IoPersonCircleOutline,
} from 'react-icons/io5'
import { useToggle } from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItems,
  Null,
  renderFalse,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { WishareEntities } from 'views/Wishare/enums'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'

const ReferralItemWrapper = ({
  item,
  children,
  ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderReferralItem = (
  {
    onClick,
    renderActions,
    nullable = false,
    selected = false,
    renderContent = renderSelf,
    ...item
  },
  index
) => {
  const verified = _.get(
    item,
    'certified'
  )
  const { owner = {} } = item || {}

  const isEmpty =
    !!!nullable &&
    Boolean(
      _.isEmpty(owner) &&
        _.isEmpty(getTitle(item))
    )

  const type =
    _.get(item, '_type') ||
    _.get(item, 'owner_type')

  return (
    <div
      key={index}
      onClick={onClick}
      className={classNames(
          'w-full px-3 py-2 rounded-lg border border-color-50 background',
          !!onClick && 'cursor-pointer'
      )}>
      <Translate>
        {(t) => (
          <div className="flex items-center gap-2">
            {renderContent(
              isEmpty ? (
                <EmptyHolder
                  icon={null}
                  subTitle={t(
                    'Click to select...'
                  )}
                  style={{
                    padding: 0,
                    border: 'none',
                  }}
                />
              ) : (
                  <div className="flex items-center gap-2 flex-1">
                    <Avatar
                        size={30}
                        className="flex flex-center background-100"
                        icon={<IoPerson className="text-color-300"/>}
                        src={getAvatar(owner)}
                    />
                    <div className="flex flex-col flex-1">
                      <span className="text-color-000 font-semibold">
                        {getTitle(item)}
                        {verified === 1 && (
                            <VerifiedSvg
                                width={'1rem'}
                                height={'1rem'}
                                className="ml-1 zoom-animation"
                            />
                        )}
                      </span>
                      <span className="text-xs text-secondary italic leading-tight">
                        {t(type)}
                      </span>
                    </div>
                </div>
              )
            )}
            {renderActions ? renderActions(item) : [
                selected ? (
                    <IoCheckmarkOutline
                        size={25}
                        className="text-green-600"
                    />
                ) : (
                    <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                      {t('select')}
                    </span>
                ),
            ]}
          </div>
        )}
      </Translate>
    </div>
  )
}

const ReferralSelectorModal = ({
  Header = Null,
  organization_id,
  onSelect = Null,
  isSelected = renderFalse,
  Wrapper = renderOwnChild,
  type = WishareEntities.USER,
}) => {
  const [
    keyword,
    ___,
    searchInput,
    typingValue,
    isTyping,
  ] = useDebounceSearch({
    timeout: 500,
    className: 'w-full p-2',
  })

  const apiInfo =
    search_searchObjects_Api

  return (
    <Wrapper>
      {searchInput}
      {!_.isEmpty(keyword) && (
        <Header
          {...{
            onSelect,
            organization_id,
            keyword: typingValue,
          }}
        />
      )}
      <Async
        key={keyword}
        values={{
          type,
          keyword,
        }}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          )

          const loading = Boolean(
            isLoading || isTyping
          )

          return (
            <React.Fragment>
              <Translate>
                {(t) => (
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2 ">
                      <div className="uppercase text-sm font-medium text-color-400 whitespace-no-wrap">
                        {t(
                          'referral list'
                        )}
                      </div>
                      <div className="border-b w-full" />
                    </div>
                    <List
                      loading={loading}
                      dataSource={items}
                      renderItem={renderClicker(
                        {
                          onClick:
                            onSelect,
                          renderItem: (
                            item,
                            index
                          ) =>
                            renderReferralItem(
                              {
                                ...item,
                                nullable: true,
                                selected:
                                  isSelected(
                                    item
                                  ),
                              },
                              index
                            ),
                          Wrapper:
                            ReferralItemWrapper,
                        }
                      )}
                      pagination={{
                        pageSize: 8,
                        hideOnSinglePage: true,
                      }}
                    />
                  </div>
                )}
              </Translate>
            </React.Fragment>
          )
        }}
      </Async>
    </Wrapper>
  )
}

const GivingReferralSelector = ({
  name,
  Header = Null,
  organization_id,
  onChange = Null,
  readOnly = false,
  renderReferral = Null,
  defaultValue: referral = {},
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const [current, setCurrent] =
    useState(referral || {})

  const handleSelect = (object) => {
    setCurrent(object)
    onChange(object)
    toggle(false)
  }

  useEffect(() => {
    const { idname: referral_id } =
      referral || {}
    const { idname: current_id } =
      current || {}
    if (referral_id !== current_id) {
      setCurrent(referral)
    }
  }, [referral, current])

  return (
    <Wrapper>
      <div className="flex w-full items-center my-2">
        {renderReferralItem(
          {
            ...current,
            onClick: readOnly
              ? undefined
              : () => toggle(true),
            renderContent: (child) =>
              renderReferral(
                current,
                child
              ),
            renderActions: () => [
              !!readOnly ? (
                <IoCheckmarkOutline
                  size={25}
                  className="text-green-600"
                />
              ) : (
                <Button
                  ghost={true}
                  type={'primary'}
                  size={'small'}
                  className="no-shadow no-text-shadow text-xs font-medium rounded-md">
                  {t('search')}
                </Button>
              ),
            ],
          },
          '__current_referral__'
        )}
      </div>
      <Modal
        className="custom-modal"
        onCancel={toggle}
        visible={isToggle}
        destroyOnClose={true}
        footer={
          <Button
            className="rounded-lg border-none"
            onClick={() =>
              toggle(false)
            }>
            {t('close')}
          </Button>
        }
        title={
          <div className="font-bold text-center text-color-000">
            {t('find referral')}
          </div>
        }>
        <ReferralSelectorModal
          Wrapper={({ children }) => (
            <div className="flex flex-col space-y-3">
              {children}
            </div>
          )}
          onSelect={handleSelect}
          organization_id={
            organization_id
          }
          Header={({ keyword }) => (
            <Header
              {...{
                keyword,
                onCancel: () =>
                  toggle(false),
              }}
            />
          )}
          isSelected={(item) => {
            if (_.isEmpty(item)) {
              return false
            }
            return _.every(
              [
                'id',
                'owner_id',
                'owner_type',
              ],
              (name) =>
                _.get(item, name) ===
                _.get(current, name)
            )
          }}
        />
      </Modal>
    </Wrapper>
  )
}

export default GivingReferralSelector
