import {PlusOutlined} from '@ant-design/icons'
import {Avatar, Input, Modal, Tooltip, Typography} from 'antd'
import {createValue} from 'components/form/utils'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {Pure} from "../../modules/asyncCache/components/Pure";
import preventParentEvent from "../../helpers/preventParentEvent";
import {useDeBounceValue} from "../../views/Search/useDeBounceValue";
import useAsyncWithCache from "../../modules/asyncCache/useAsyncWithCache";
import {API_ROOT_URL} from "../../envs/_current/config";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import {IoAddCircleOutline, IoPerson} from "react-icons/io5";

const LookupResult = ({type, keyword, createIfNotExists, toggle, onFinish, apiPath = null}) => {
  const t = useTranslate();

  const path = !!apiPath ? (
    `${apiPath}?keyword=${keyword}`
  ) : (
    `/lookup/${type}/gets?keyword=${keyword}`
  )
  const {
    success,
    result,
    action,
    isLoading,
    response,
    handleDispatchAsyncAction
  } = useAsyncWithCache({
    cacheId: path,
    apiInfo: {
      root: API_ROOT_URL,
      path: path,
      method: 'GET',
    },
  });
  const options = _.uniq(
    _.filter(
      _.get(
        response,
        'data.data',
        []
      )
      , function (o) {
        return o.id !== '';
      }).map(
      ({name: label, id: value, ...props}) => {
        return {...props, label, value}
      }
    )
  );

  return (
    <div key={keyword} className="w-full pt-6 flex flex-col gap-3">
      {!!createIfNotExists && !!(
        keyword &&
        keyword.length
      ) && (
        <div className="flex items-center gap-2 rounded-lg border border-color-50 background px-3 py-2" key="@create new">
          <IoAddCircleOutline size={14}/>
          <div className="flex items-baseline gap-1 truncate flex-1">
            <span className="font-light text-sm text-color-300 italic">
              {t('create new')}
            </span>
            <span className="font-bold text-color-000">
              {keyword}
            </span>
          </div>
          <Typography.Link onClick={() => {
            onFinish(
              createValue(
                type,
                keyword || ''
              )
            )
            toggle()
          }} className="float-right text-xs">
            {t('select')}
          </Typography.Link>
        </div>
      )}

      {options.map(o => (
          <div className="flex items-center gap-3 border border-color-50 rounded-lg background p-2" key={o.value}>
            <Avatar
                size={30}
                src={_.get(o, 'avatar')}
                className="flex flex-center background-100 border border-color-50"
                icon={<IoPerson size={15} className="text-color-400"/>}
            />
            <div className="font-medium text-color-000 flex-1">{o.label}</div>
            <Typography.Link onClick={() => {
              onFinish(
                {
                  target: {
                    ...o,
                    name: type,
                    value: o.label || '',
                    id: o.value
                  }
                }
              )
              toggle()
            }} className="float-right text-xs">{t('select')}</Typography.Link>
          </div>
        )
      )}
    </div>
  )
}

const Lookup = ({isToggle, toggle, createIfNotExists, type, title, onFinish, apiPath = null}) => {
  const t = useTranslate();
  // State and setters for ...
  // Search term
  const [searchTerm, setSearchTerm] = useState("");
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDeBounceValue(searchTerm, 300);

  return (
    <Modal
      maskClosable={false}
      title={
        <div className="font-semibold text-center text-color-000 uppercase">{title || t('select')}</div>
      }
      destroyOnClose
      onCancel={toggle}
      visible={isToggle}
      footer={null}>
      <Input.Search
        autoClear
        onPressEnter={(e) => {
          onFinish(
            createValue(
              type,
              searchTerm || ''
            )
          )
          toggle()
        }}
        size={"large"}
        autoFocus={true}
        onChange={e => {
          preventParentEvent(e);
          if (e && e.target && e.target.value) {
            setSearchTerm(e.target.value);
          }
        }}
        placeholder={t("enter your keyword")}/>

      <Pure key={debouncedSearchTerm} input={[debouncedSearchTerm]}>
        <LookupResult type={type} createIfNotExists={createIfNotExists} keyword={debouncedSearchTerm} toggle={toggle}
                      onFinish={onFinish} apiPath={apiPath}/>
      </Pure>
    </Modal>
  )
}

const LookupField = ({name, bordered, defaultValue, disabled = false, createIfNotExists = true, handleChange, apiPath = null}) => {
  const t = useTranslate();

  return (
    <Toggle>
      {(isToggle, toggle) => (
        isToggle ? (
            <React.Fragment>
              <div className="text-sm text-color-500 italic">{t('selecting...')}</div>
              <Lookup type={name}
                      isToggle={isToggle}
                      toggle={toggle}
                      createIfNotExists={createIfNotExists}
                      onFinish={handleChange}
                      apiPath={apiPath}
              />
            </React.Fragment>
          )
          : (
            <Input.Search
              // allowClear
              value={
                defaultValue
              }
              disabled={disabled}
              onFocus={() => {
                toggle();
              }}
              className="w-full"
              placeholder={t(
                'select / create'
              )}
              enterButton={
                  <Tooltip title={t('delete')}>
                    <CloseOutlined onClick={() => handleChange(createValue(name, null))} />
                  </Tooltip>
              }
              onSearch={(value, event) => {
                handleChange(createValue(name, null))
              }}
            >
            </Input.Search>
          )
      )}
    </Toggle>
  )
}

export default LookupField
