import React from "react";

function BronzeMedalSvg({size = 30, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 510 510"
      {...props}
    >
      <linearGradient id="lgbm1">
        <stop offset="0" stopColor="#fbc56d"></stop>
        <stop offset="1" stopColor="#c5715a"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_1_BM"
        x1="431.202"
        x2="480.145"
        y1="521.137"
        y2="557.508"
        gradientTransform="rotate(20.97 888.295 -551.577)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient id="lgbm2">
        <stop offset="0" stopColor="#8c3d27" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#8c3d27"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000106113030674627527000000011675722416875708583_"
        x1="451.72"
        x2="488.839"
        y1="561.82"
        y2="561.221"
        gradientTransform="rotate(20.97 888.295 -551.577)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000018238689072769584250000005509058855753628545_"
        x1="463.761"
        x2="488.457"
        y1="449.875"
        y2="527.107"
        gradientTransform="rotate(20.97 888.295 -551.577)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000114792353373030639880000007183448900706701209_"
        x1="468.737"
        x2="500.468"
        y1="491.93"
        y2="519.47"
        gradientTransform="rotate(20.97 888.295 -551.577)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000049925939611378189560000008491307268379650466_"
        x1="720.557"
        x2="769.5"
        y1="410.379"
        y2="446.749"
        gradientTransform="scale(-1 1) rotate(20.97 478.487 -2765.753)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000050630593499627444530000006899127445111967872_"
        x1="741.075"
        x2="778.194"
        y1="451.061"
        y2="450.462"
        gradientTransform="scale(-1 1) rotate(20.97 478.487 -2765.753)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000049932793420042601990000012835787075214329746_"
        x1="753.116"
        x2="777.812"
        y1="339.117"
        y2="416.348"
        gradientTransform="scale(-1 1) rotate(20.97 478.487 -2765.753)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000042711791447387489670000014827432407210869165_"
        x1="758.092"
        x2="789.823"
        y1="381.171"
        y2="408.711"
        gradientTransform="scale(-1 1) rotate(20.97 478.487 -2765.753)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000012473994419417457300000008786649331871959168_"
        x1="156.954"
        x2="324.67"
        y1="252.928"
        y2="420.644"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000018919005500079697710000012645944846826055080_"
        x1="337.326"
        x2="170.272"
        y1="433.3"
        y2="266.246"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000138541141564077426450000011187432492525464968_"
        x1="175.891"
        x2="306.108"
        y1="271.865"
        y2="402.082"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000016787611390631994080000017525715681145371782_"
        x1="255"
        x2="255"
        y1="397.502"
        y2="501.882"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000025408745545220902860000001198669253987985054_"
        x1="350.243"
        x2="218.909"
        y1="433.62"
        y2="350.953"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000132058150211660521820000017834197133222122399_"
        x1="221.874"
        x2="281.61"
        y1="320.747"
        y2="380.482"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000111174842818728827850000003509463940455402914_"
        x1="290.793"
        x2="208.23"
        y1="337.034"
        y2="371.166"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000060028365816896364600000008661628946096492729_"
        x1="269.236"
        x2="224.034"
        y1="366.334"
        y2="346.961"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000145038406879816526030000013394853554959771309_"
        x1="287.94"
        x2="242.738"
        y1="366.334"
        y2="346.961"
        gradientTransform="matrix(-1 0 0 1 528.704 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000173149398945396086060000006062974716471016359_"
        x1="241.769"
        x2="270.827"
        y1="368.601"
        y2="321.9"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000109725189523726458680000013814100659501421243_"
        x1="266.003"
        x2="263.582"
        y1="362.703"
        y2="317.04"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000008125655761623661570000004502704631712160932_"
        x1="333.364"
        x2="272.031"
        y1="283.792"
        y2="176.459"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000000213690342275754550000015671498550082787470_"
        x1="262.112"
        x2="301.612"
        y1="56.676"
        y2="119.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f9f5f3"></stop>
        <stop offset="1" stopColor="#d8e8f0"></stop>
      </linearGradient>
      <linearGradient id="lgbm3">
        <stop offset="0" stopColor="#bbec6c"></stop>
        <stop offset="1" stopColor="#81b823"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000144326062477865677420000010933406538902621608_"
        x1="222.713"
        x2="345.38"
        y1="-20.62"
        y2="120.046"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm3"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000072274243032983417130000017782409388021135790_"
        x1="196.655"
        x2="319.321"
        y1="2.104"
        y2="142.77"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm3"
      ></linearGradient>
      <linearGradient id="lgbm4">
        <stop offset="0" stopColor="#78a91f" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#78a91f"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000080910689483036462390000003822908237317676456_"
        x1="255"
        x2="255"
        y1="66.5"
        y2="33.466"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000030453207187208717570000014330866913237466270_"
        x1="292.995"
        x2="310.065"
        y1="107.005"
        y2="124.075"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000060750215040323073290000016440185625368117692_"
        x1="-771.005"
        x2="-753.935"
        y1="107.005"
        y2="124.075"
        gradientTransform="matrix(-1 0 0 1 -554 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000085233707466301006690000011163084944089379979_"
        x1="341.686"
        x2="136.626"
        y1="177.612"
        y2="-27.448"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000029014072763344359760000010879480147838154130_"
        x1="255"
        x2="255"
        y1="81.667"
        y2="221.706"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000124870009630857698430000010796641528677958838_"
        x1="255"
        x2="255"
        y1="163"
        y2="209.044"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000085221084841102295490000002747917190395419308_"
        x1="302.267"
        x2="274.267"
        y1="136.929"
        y2="165.429"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000073705721062993959970000003822596216584107920_"
        x1="233.5"
        x2="286.53"
        y1="168.5"
        y2="221.53"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000060010955421029962100000011344034963951612288_"
        x1="270.25"
        x2="243.999"
        y1="205.25"
        y2="178.999"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000157999978755939364920000004602099427384640429_"
        x1="255"
        x2="255"
        y1="11.333"
        y2="32.171"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgbm1"
      ></linearGradient>
      <path
        fill="url(#SVGID_1_BM)"
        d="M136.992 319.5c22.557 22.078 36.705 48.673 41.596 74.299 1.024 5.367-7.211 13.781-12.599 12.872-25.725-4.339-52.618-17.913-75.175-39.99s-36.705-48.673-41.596-74.299c-1.024-5.367 7.211-13.781 12.599-12.872 25.725 4.339 52.618 17.912 75.175 39.99z"
      ></path>
      <path
        fill="url(#SVGID_00000106113030674627527000000011675722416875708583_)"
        d="M136.992 319.5c22.557 22.078 36.705 48.673 41.596 74.299 1.024 5.367-7.211 13.781-12.599 12.872-25.725-4.339-52.618-17.913-75.175-39.99s-36.705-48.673-41.596-74.299c-1.024-5.367 7.211-13.781 12.599-12.872 25.725 4.339 52.618 17.912 75.175 39.99z"
      ></path>
      <path
        fill="url(#SVGID_00000018238689072769584250000005509058855753628545_)"
        d="M167.581 267.213c18.087 35.348 24.337 72.725 19.933 105.248-.922 6.811-14.108 13.558-20.172 10.321-28.953-15.454-55.61-42.39-73.696-77.738s-24.337-72.725-19.933-105.248c.922-6.811 14.108-13.558 20.172-10.321 28.952 15.454 55.609 42.39 73.696 77.738z"
      ></path>
      <path
        fill="url(#SVGID_00000114792353373030639880000007183448900706701209_)"
        d="M73.369 238.533c2.39 21.855 9.052 44.573 20.277 66.511 18.087 35.348 44.743 62.284 73.696 77.738 6.063 3.236 19.249-3.511 20.172-10.321 4.404-32.522-1.846-69.9-19.933-105.248-10.65-20.815-24.274-38.708-39.587-53.062z"
      ></path>
      <path
        fill="url(#SVGID_00000049925939611378189560000008491307268379650466_)"
        d="M373.008 319.5c-22.557 22.078-36.705 48.673-41.596 74.299-1.024 5.367 7.211 13.781 12.599 12.872 25.725-4.339 52.618-17.913 75.175-39.99s36.705-48.673 41.596-74.299c1.024-5.367-7.211-13.781-12.599-12.872-25.725 4.339-52.618 17.912-75.175 39.99z"
      ></path>
      <path
        fill="url(#SVGID_00000050630593499627444530000006899127445111967872_)"
        d="M373.008 319.5c-22.557 22.078-36.705 48.673-41.596 74.299-1.024 5.367 7.211 13.781 12.599 12.872 25.725-4.339 52.618-17.913 75.175-39.99s36.705-48.673 41.596-74.299c1.024-5.367-7.211-13.781-12.599-12.872-25.725 4.339-52.618 17.912-75.175 39.99z"
      ></path>
      <path
        fill="url(#SVGID_00000049932793420042601990000012835787075214329746_)"
        d="M342.419 267.213c-18.087 35.348-24.337 72.725-19.933 105.248.922 6.811 14.108 13.558 20.172 10.321 28.953-15.454 55.61-42.39 73.696-77.738s24.337-72.725 19.933-105.248c-.922-6.811-14.108-13.558-20.172-10.321-28.952 15.454-55.609 42.39-73.696 77.738z"
      ></path>
      <path
        fill="url(#SVGID_00000042711791447387489670000014827432407210869165_)"
        d="M436.631 238.533c-2.39 21.855-9.052 44.573-20.277 66.511-18.087 35.348-44.743 62.284-73.696 77.738-6.063 3.236-19.249-3.511-20.172-10.321-4.404-32.522 1.846-69.9 19.933-105.248 10.65-20.815 24.274-38.708 39.587-53.062z"
      ></path>
      <g>
        <circle
          cx="255"
          cy="350.974"
          r="159.026"
          fill="url(#SVGID_00000012473994419417457300000008786649331871959168_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="126.755"
          fill="url(#SVGID_00000018919005500079697710000012645944846826055080_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="108.82"
          fill="url(#SVGID_00000138541141564077426450000011187432492525464968_)"
        ></circle>
        <path
          fill="url(#SVGID_00000016787611390631994080000017525715681145371782_)"
          d="M95.974 350.974C95.974 438.802 167.172 510 255 510s159.026-71.198 159.026-159.026c0-.617-.016-1.23-.023-1.846H95.997c-.007.616-.023 1.229-.023 1.846z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000025408745545220902860000001198669253987985054_)"
        d="M299.601 503.65c50.109-14.614 89.983-53.219 106.356-102.552l-77.453-77.453-17.461.857-47.126-47.126-57.435 133.155z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000132058150211660521820000017834197133222122399_)"
          d="M320.208 316.419l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217-3.428.278-6.76 2.003-8.934 5.217l-17.978 26.575a11.977 11.977 0 01-7.109 4.932l-31.187 7.535c-8.46 2.044-11.985 12.141-6.637 19.007l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.866 1.823-16.963-6.637-19.007z"
        ></path>
        <path
          fill="url(#SVGID_00000111174842818728827850000003509463940455402914_)"
          d="M260.912 402.263l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.349-6.865 1.823-16.963-6.637-19.007l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217V401.7a11.955 11.955 0 015.912.563z"
        ></path>
        <path
          fill="url(#SVGID_00000060028365816896364600000008661628946096492729_)"
          d="M320.208 316.419l-31.187-7.535a11.956 11.956 0 01-3.02-1.185l-79.502 102.833c3.119 3.014 7.824 4.331 12.424 2.664l30.165-10.932a11.957 11.957 0 015.912-.563 11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.867 1.823-16.964-6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000145038406879816526030000013394853554959771309_)"
          d="M189.792 316.419l31.187-7.535a11.956 11.956 0 003.02-1.185l79.502 102.833c-3.119 3.014-7.824 4.331-12.424 2.664l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989c.23-2.976-.66-5.93-2.494-8.285l-19.719-25.31c-5.348-6.867-1.823-16.964 6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000173149398945396086060000006062974716471016359_)"
          d="M181.491 323.646c-1.483 3.74-1.127 8.197 1.664 11.78l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989a11.954 11.954 0 01.633-4.831z"
        ></path>
        <path
          fill="url(#SVGID_00000109725189523726458680000013814100659501421243_)"
          d="M328.521 323.646c1.483 3.74 1.128 8.197-1.664 11.78l-19.719 25.31a11.981 11.981 0 00-2.494 8.285l2.471 31.989c.67 8.677-7.844 15.151-16.026 12.185l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989a11.954 11.954 0 00-.633-4.831z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000008125655761623661570000004502704631712160932_)"
        d="M255 191.948c-.66 0-1.315.017-1.973.025l-24.542 24.542 179.161 179.161a159.079 159.079 0 006.38-44.703c0-87.827-71.198-159.025-159.026-159.025z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000000213690342275754550000015671498550082787470_)"
          d="M159 3.333v84l96 96 96-96v-84z"
        ></path>
        <path
          fill="url(#SVGID_00000144326062477865677420000010933406538902621608_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000072274243032983417130000017782409388021135790_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000080910689483036462390000003822908237317676456_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000030453207187208717570000014330866913237466270_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000060750215040323073290000016440185625368117692_)"
          d="M255 140.28c3.958 0 7.915-1.506 10.927-4.519L351 50.688v36.646l-96 96-96-96V50.688l85.073 85.073A15.407 15.407 0 00255 140.28z"
        ></path>
        <g>
          <path
            fill="url(#SVGID_00000085233707466301006690000011163084944089379979_)"
            d="M244.473 161.527l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-6.126 6.126-6.126 16.059 0 22.185L255 216.426l114.407-114.407c6.126-6.126 6.126-16.059 0-22.185-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.815 5.814-15.241 5.814-21.055-.001z"
          ></path>
          <path
            fill="url(#SVGID_00000029014072763344359760000010879480147838154130_)"
            d="M140.593 80.019L242.954 182.38c6.653 6.653 17.44 6.653 24.093 0l102.36-102.361c.03-.03.054-.063.084-.093-.029-.03-.054-.063-.084-.093-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.814 5.814-15.241 5.814-21.055 0l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-.03.03-.054.063-.084.093.029.03.053.063.083.093z"
          ></path>
          <path
            fill="url(#SVGID_00000124870009630857698430000010796641528677958838_)"
            d="M255 216.426l80.759-80.759h-44.371l-25.861 25.861c-5.814 5.814-15.241 5.814-21.055 0l-25.861-25.861H174.24z"
          ></path>
          <path
            fill="url(#SVGID_00000085221084841102295490000002747917190395419308_)"
            d="M245 206.426l10 10L357.426 114h-44.371l-47.527 47.527c-5.645 5.645-14.684 5.792-20.528.477z"
          ></path>
        </g>
        <g>
          <circle
            cx="255"
            cy="190"
            r="37.498"
            fill="url(#SVGID_00000073705721062993959970000003822596216584107920_)"
          ></circle>
          <circle
            cx="255"
            cy="190"
            r="21.498"
            fill="url(#SVGID_00000060010955421029962100000011344034963951612288_)"
          ></circle>
        </g>
        <path
          fill="url(#SVGID_00000157999978755939364920000004602099427384640429_)"
          d="M365.502 37.333H144.498c-6.35 0-11.498-5.148-11.498-11.498V11.498C133 5.148 138.148 0 144.498 0h221.005C371.852 0 377 5.148 377 11.498v14.338c0 6.35-5.148 11.497-11.498 11.497z"
        ></path>
      </g>
    </svg>
  );
}

export default BronzeMedalSvg;
