import { event_getById_Api } from 'apis/event'
import { eventSchema } from 'apis/schema/event'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import React from 'react'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { renderIf } from 'views/Shared'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import EventContext from './EventContext'

export const EventProvider = ({
  id,
  children,
}) => {
  const item = useSelectEntities(
    id,
    eventSchema
  )
  return (
    <EventContext.Provider
      value={{
        item,
      }}>
      <React.Fragment key={id}>
        <Async
          {...{
            apiInfo: event_getById_Api,
            query: { ':id': id },
          }}>
          {({ response }) => {
            const status = _.get(
              response,
              'status'
            )
            const {
              error,
              error_code,
            } = _.get(
              response,
              'errorMessages',
              {}
            )
            return renderIf(
              response &&
                status !== 200,
              <WishareNotFound
                status={{
                  title: status,
                  description: error,
                }}
                description={error_code}
              />
            )
          }}
        </Async>
      </React.Fragment>
      {children}
    </EventContext.Provider>
  )
}
