import { List } from 'antd'
import { getType } from 'apis/model/base'
import getTitle from 'helpers/getTitle'
import Translate from 'modules/local/Translate'
import React from 'react'
import { useSearchParam } from 'react-use'
import { Null } from 'views/Shared'
import {
  async_schema,
  EnitityManager,
  useManager,
} from 'views/Wishare/factory/createEntityManager'
import usePaginationHelper, {
  createPagerKey,
} from 'views/Wishare/hooks/usePaginationHelper'

const renderDefaultItem =
  ({ renderContextMenu = Null }) =>
  (item, index) =>
    (
      <Translate key={index}>
        {(t) => (
          <div className="p-2 border rounded-lg gap-2 relative">
            <List.Item.Meta
              title={getTitle(item)}
              description={getType(
                item
              )}
            />
            {renderContextMenu(item)}
          </div>
        )}
      </Translate>
    )

const SubCategoriesContainer = ({
  title,
  configs,
  owner_id,
  owner_type,
  createFormSchema,
  fetchItems = async_schema,
  renderItem = renderDefaultItem,
  filter_actions = {
    // type: 'type',
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  // const type = useSearchParam(
  //   filter_actions.type
  // )

  const {
    refreshKey,
    modal = null,
    renderContextMenu,
    handleAction = Null,
  } = useManager({
    configs,
    params: {
      owner_id,
      owner_type,
    },
    createFormSchema,
  })

  const [pager_widget] =
    usePaginationHelper({
      sortHeader: null,
      SortByWidget: Null,
      renderFilter: Null,
      AdvancedFilter: Null,
      onInit: ({
        onSortChange = Null,
        // onFilterChange = Null,
      }) => {
        // onFilterChange({
        //   type,
        // })
        onSortChange(sortBy)
      },
      pagerInfo: {
        ...(fetchItems || {}),
        RenderEntity: React.Fragment,
        renderItem: renderItem({
          renderContextMenu,
        }),
      },
      withKey: createPagerKey([
        JSON.stringify({
          meta: fetchItems,
        }),
        refreshKey,
      ]),
      query_params: filter_actions,
    })

  return (
    <EnitityManager
      {...{
        modal,
        title,
        handleAction,
      }}>
      {pager_widget}
    </EnitityManager>
  )
}

export default SubCategoriesContainer
