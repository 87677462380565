import { API_ROOT_URL } from 'envs/_current/config'
import createPath from 'helpers/createPath'
import { schema } from "normalizr"
import { createBaseItemSchema, productSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

// Route::post('products/product/add', 'ProductController@store')
export const create_product_create_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/add',
  method: 'POST',
  fields: [],
  paramsFields: [],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::post('products/product/{id}/edit', 'ProductController@update');
// PARAMS:
// title:Gạo 504 (Bổ sung)
// description:Gạo vụ hè thu 2020
// categories:rice-and-by-products-adb84dc1-14bb-11ea-9a48-b64aaf48aa3c
// photos:
// price:25000
// price_unit: VND
// unit: kg
// trademark: VN504
// status:1
// weight: 1000
// active:1
// no_sales:0

export const create_product_update_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/edit',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

//    Route::post('products/organization/{org_id}/{product_id}/sort/{direction}', 'ProductController@sortOrder');
export const create_product_sortOrder_Api = props => ({
  backend: `Route::post('products/organization/{org_id}/{product_id}/sort/{direction}', 'ProductController@sortOrder');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/:product_id/sort/:direction',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_updatePrice_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/products/product/:id/update-price',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_updateProductPriceGroupComment_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/products/product-price-group/:id/update-comment',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_copyPriceSet_Api = props => ({
  backend: `Route::post('products/product/{id}/{product_price_set_id}/copy-price-set', 'ProductController@copyPriceSet');`,
  root: API_ROOT_URL,
  path:
    '/products/product/:id/:product_price_set_id/copy-price-set',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_editPriceSetGroup_Api = props => ({
  backend: `Route::post('products/product-price-set/edit-price-set-group/{product_price_group_id}', 'ProductController@editPriceSetGroup');`,
  root: API_ROOT_URL,
  path:
    '/products/product-price-set/edit-price-set-group/:product_price_group_id',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_deletePriceSetGroup_Api = props => ({
  backend: `Route::post('products/product-price-set/delete-price-set-group/{product_price_set_group_id}', 'ProductPriceController@deletePriceGroup');`,
  root: API_ROOT_URL,
  path:
    '/products/product-price-set/delete-price-set-group/:product_price_group_id',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_expirePriceSetGroup_Api = props => ({
  backend: `Route::post('products/product-price-set/expire-price-set-group/{product_price_set_group_id}', 'ProductPriceController@expirePriceGroup');`,
  root: API_ROOT_URL,
  path:
    '/products/product-price-set/expire-price-set-group/:product_price_group_id',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_removeExpirePriceSetGroup_Api = props => ({
  backend: `Route::post('products/product-price-set/remove-expire-price-set-group/{product_price_set_group_id}', 'ProductPriceController@removeExpirePriceGroup');`,
  root: API_ROOT_URL,
  path:
    '/products/product-price-set/remove-expire-price-set-group/:product_price_group_id',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

export const create_product_editPriceSet_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/products/product/:id/edit-price-set',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::post('products/product/{id}/delete', 'ProductController@destroy');
export const create_product_delete_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/delete',
  method: 'POST',
  fields: [],
  paramsFields: [],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/product/{id}/get', 'ProductController@getById');
export const create_product_getById_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/get',
  method: 'GET',
  fields: ['id'],
  paramsFields: [],
  fieldTypes: {},
  schema: productSchema,
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/product/{id}/get-product-price', 'ProductPriceController@getById');
export const create_product_price_getById_Api = props => ({
  backend: `Route::get('products/product/{id}/get-product-price/{effect_date}', 'ProductPriceController@getById');`,
  root: API_ROOT_URL,
  path: '/products/product/:id/get-product-price/:effect_date',
  method: 'GET',
  fields: ['id'],
  paramsFields: [
    'effect_date',
  ],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/{type}/{id}/get-products', 'ProductController@getProducts');
export const create_product_getProducts_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/:type/:id/get-products',
    query
  }),
  method: 'GET',
  fields: [],
  paramsFields: [
    'keyword',
    'title',
    'product_catalog_id',
    'categories',
    'active',
    'product_type'
  ],
  fieldTypes: {},
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
  ...props
})

// Route::get('products/{type}/{id}/get-all-products', 'ProductController@getAllProducts');
export const create_product_getAllProducts_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/:type/:id/get-all-products',
    query
  }),
  method: 'GET',
  fields: [],
  fieldTypes: {},
  paramsFields: [
    'status',
    'active'
  ],
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
  ...props
})

// Route::get('products/{type}/{id}/get-products-with-price', 'ProductController@getProductsWithPrice');
export const create_product_getProductsWithPrice_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/:type/:id/get-products-with-price',
    query
  }),
  method: 'GET',
  fields: [],
  paramsFields: [
    'title',
    'product_catalog_id',
    'categories',
    'active'
  ],
  fieldTypes: {},
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
  ...props
})

// Route::post('products/product/{id}/publish', 'ProductController@publish');
export const create_product_publish_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/publish',
    query
  }),
  method: 'POST',
  fields: [],
  fieldTypes: {},
  schema: productSchema,
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Sản phẩm liên quan theo category:
// Route::get('products/product/{id}/get-other-products-by-category', 'ProductController@getOtherProductsByCategory');
export const create_product_getOtherProductsByCategory_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/get-other-products-by-category',
    query
  }),
  method: 'GET',
  schema: productSchema,
  ...props
})

// Sản phẩm liên quan cùng nhà cung cấp
// Route::get('products/product/{id}/get-other-products-by-owner', 'ProductController@getOtherProductsByOwner');
export const create_product_getOtherProductsByOwner_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/get-other-products-by-owner',
    query
  }),
  method: 'GET',
  schema: productSchema,
  ...props
})


// Route::get('products/mart/get-related-products', 'MartController@getRelatedProducts');
export const product_getRelatedProducts_Api = {
  backend: `Route::get('products/mart/get-related-products', 'MartController@getRelatedProducts');`,
  root: API_ROOT_URL,
  path: '/products/mart/get-related-products',
  method: 'GET',
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
}


export const product_get_default_specifications_Api = {
  root: API_ROOT_URL,
  path:
    '/products/get-default-specifications',
  method: 'GET',
  transform: createListTransform(
    createBaseItemSchema({
      type: 'specifications'
    })
  )
}

// Route::post('products/product/{id}/specifications/sort/{direction}', 'ProductSpecificationController@sortOrder');
export const create_product_specs_updateSortOrder_Api = props => ({
  backend: `Route::post('products/product/{id}/specifications/sort/{direction}', 'ProductSpecificationController@sortOrder');`,
  root: API_ROOT_URL,
  path: '/products/product/:id/specifications/sort/:direction',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/mart/get-all-products', 'MartController@getAllProducts');
export const product_getAllProducts_Api = {
  backend: `// Route::get('products/mart/get-all-products', 'MartController@getAllProducts');`,
  root: API_ROOT_URL,
  path: '/products/mart/get-all-products',
  method: 'GET',
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}

// Route::get('products/category/{id}/get-products', 'MartController@getProductsByCategory');
export const product_getProductsByCategory_Api = {
  backend: `// Route::get('products/category/{id}/get-products', 'MartController@getProductsByCategory');`,
  root: API_ROOT_URL,
  path: '/products/category/:id/get-products',
  method: 'GET',
  fields: [],
  paramsFields: [
    'title',
    'product_catalog_id',
    'categories',
    'active'
  ],
  fieldTypes: {},
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}

const productClustersSchema = new schema.Entity(
  'product_clusters',
  {
    data: [productSchema]
  },
  {
    idAttribute: 'product_group_title'
  }
);
// Route::get('products/organization/{org_id}/get-clustered-product-groups', 'ProductController@getProductClusterGroups');
export const product_getClusteredProductGroups_Api = {
  backend: `// Route::get('products/organization/{org_id}/get-clustered-product-groups', 'ProductController@getProductClusterGroups');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/get-clustered-product-groups',
  method: 'GET',
  schema: productClustersSchema,
  transform: createListTransform(
    productClustersSchema
  )
}

// Route::get('products/organization/{org_id}/get-clustered-products', 'ProductController@getClusteredProducts');
export const product_getClusteredProducts_Api = {
  backend: `// Route::get('products/organization/{org_id}/get-clustered-products', 'ProductController@getClusteredProducts');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/get-clustered-products',
  method: 'GET',
  paramsFields: [
    'product_group_title',
  ],
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}

// Route::get('products/organization/{org_id}/get-clustered-products', 'ProductController@getClusteredProducts');
export const product_getUnGroupClusteredProducts_Api = {
  backend: `// Route::get('products/organization/{org_id}/get-clustered-products', 'ProductController@getClusteredProducts');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/get-ungroup-clustered-products',
  method: 'GET',
  paramsFields: [
    'group_by_type',
    'group_by_type_code',
  ],
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}

// Route::get('products/organization/{org_id}/get-ungroup-purchase-products', 'ProductController@getPurchaseProductsHaveNotGroup');
export const product_getUnGroupPurchaseProducts_Api = {
  backend: `// Route::get('products/organization/{org_id}/get-ungroup-purchase-products', 'ProductController@getPurchaseProductsHaveNotGroup');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/get-ungroup-purchase-products',
  method: 'GET',
  paramsFields: [
    'group_by_type',
    'group_by_type_code',
  ],
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}

// Route::get('products/organization/{org_id}/get-ungroup-purchase-products-group-by-label', 'ProductController@getPurchaseProductsHaveNotGroupGrpByLabel');
export const product_getUnGroupPurchaseProductsGroupByLabel_Api = {
  backend: `// Route::get('products/organization/{org_id}/get-ungroup-purchase-products-group-by-label', 'ProductController@getPurchaseProductsHaveNotGroupGrpByLabel');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/get-ungroup-purchase-products-group-by-label',
  method: 'GET',
  fields: [
    'group_by_type'
  ],
  paramsFields: [
    'group_by_type'
  ],
}

// Route::post('products/organization/{org_id}/product-clusters/{id}/update-title', 'PriceReportController@updateProductGroupTitle');
export const product_clusters_updateProductGroupTitle_Api = {
  backend: `// Route::post('products/organization/{org_id}/product-clusters/{id}/update-title', 'PriceReportController@updateProductGroupTitle');`,
  root: API_ROOT_URL,
  path: '/products/organization/:organization_id/product/:id/update-title',
  method: 'POST',
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}


export const product_renameProductCatalogTitle_Api = {
  backend: `// Route::post('products/organization/{org_id}/rename-catalog-title', 'ProductController@renameProductCatalog');`,
  root: API_ROOT_URL,
  path: '/products/organization/:id/rename-catalog-title',
  method: 'POST'
}

export const product_getProductsByChannel_Api = {
  backend: `Route::get('products/suggest/channel/{id}/get-products', 'ProductController@getProductsByChannel');`,
  root: API_ROOT_URL,
  path:
    '/products/suggest/channel/:id/get-products',
  method: 'GET',
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}


export const product_getPromotedProductsByCategory_Api = {
  backend: `Route::get('products/suggest/category/{id}/get-promoted-products', 'ProductController@getPromotedProductsByCategory');`,
  root: API_ROOT_URL,
  path:
    '/products/suggest/category/:id/get-promoted-products',
  method: 'GET',
  schema: productSchema,
  transform: createListTransform(
    productSchema
  )
}