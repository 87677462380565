import { getId } from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import { categoryApi } from '../apis'
import EntityList from '../EntityList'
import { bindQueryParams } from '../functions/routerHelper'
import createEntityListParams from './createEntityListParams'

const createEntityListByCategory = (
  category,
  entity,
  entityListParams = {}
) => {
  const id = getId(category)

  const apiInfo =
    categoryApi.categories_getEntitiesByCategory_api

  const {
    RenderItem,
    RenderEntity,
    collection: type,
  } = createEntityListParams(entity)

  const lookupKey = [
    apiInfo.path
      .replace(':id', id)
      .replace(':type', type),
  ].join('/')

  if (_.isEmpty(category)) return null

  return (
    <div className="space-y-3">
      <EntityList
        {...entityListParams}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        RenderItem={RenderItem}
        RenderEntity={RenderEntity}
      />
    </div>
  )
}

export default createEntityListByCategory
