import { getId } from 'apis/model/base'
import { displayMomentValues } from 'helpers/momentDatetime'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { TimesheetPeriodOptions } from 'views/MemberSetting/components/ManageTimeSheetModal'

export const renderTimesheetsItem = (
  item
) => {
  const {
    staff,
    notes,
    duration,
    to_date,
    from_date,
    staff_roster,
    duration_unit_name,
  } = item || {}

  const [_from, _to] =
    displayMomentValues([
      from_date,
      to_date,
    ])

  const { label: duration_unit } =
    _.find(
      Object.values(
        TimesheetPeriodOptions
      ),
      { value: duration_unit_name }
    ) || {}

  return (
    <Translate key={getId(item)}>
      {(t) => (
        <div className="relative mt-1">
          <div className="flex items-center gap-1 text-xs italic">
            <span className="text-color-400 font-light whitespace-no-wrap">
              {t('total time work')}
              {': '}
            </span>
            <span className="text-primary font-medium lowercase">
              {Number(duration)}{' '}
              {t(duration_unit)}
            </span>
          </div>
          {!_.isEmpty(notes) && (
            <div className="flex items-center gap-1 text-xs italic">
              <span className="text-color-400 font-light whitespace-no-wrap">
                {t('note')}
                {': '}
              </span>
              <span className="text-color-100 font-medium">
                {notes}
              </span>
            </div>
          )}
        </div>
      )}
    </Translate>
  )
}
