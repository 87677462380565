import { BackTop, Layout } from 'antd'
import React, {useContext} from 'react'
import {LayoutContext} from "./LayoutContext";

export const NormalLayout = ({
  children,
  left,
  bottom,
}) => {

    const {isSm} = useContext(LayoutContext)

  return (
    <Layout className="NormalLayout w-full min-h-screen background">
      <Layout
        className="background"
        style={{
          flexDirection: 'row',
        }}>
        {left}
        {children}
      </Layout>
      {bottom}
      <BackTop
          className="custom-btn-backTop"
          style={{
              bottom: isSm ? 'calc(var(--header-height) + 60px)' : '55px',
              right: 15,
              zIndex: 30,
          }}
      />
    </Layout>
  )
}
