import React from 'react'
import DonationContext from './DonationContext'
import DonationInfo from './holders/DonationInfo'
import DonationList from './holders/DonationList'
import DonationNews from './holders/DonationNews'
import DonationOverview from './holders/DonationOverview'
import MyDonationList from './holders/MyDonationList'
import DonationIOReportWrapper from './views/DonationEventReport'

export const donationRouteEntities = [
  {
    path: 'overview',
    component: DonationOverview,
    breadcrumbName: (t) =>
      t('overview'),
  },
  {
    path: 'news',
    component: DonationNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'my-donations',
    component: MyDonationList,
    breadcrumbName: (t) =>
      t('my donations'),
  },
  {
    path: 'donations',
    component: DonationList,
    breadcrumbName: (t) =>
      t('list of contributors'),
  },
  {
    path: 'information',
    component: DonationInfo,
    breadcrumbName: (t) =>
      t('donation guide'),
  },
  {
    path: 'summary-report',
    label: 'summary report',
    component: () => (
      <DonationContext.Consumer>
        {({ donation }) => (
          <DonationIOReportWrapper
            donation={donation}
          />
        )}
      </DonationContext.Consumer>
    ),
    breadcrumbName: (t) =>
      t('summary report'),
    isInvisible: ({ item }) =>
      !item.edit,
  },
]
