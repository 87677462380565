import {
  DatePicker,
  Input,
  Radio,
} from 'antd'
import { donor_updateDonor_api } from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import { wishareConfigs } from 'views/Wishare/configs'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { GenderTypes } from 'views/Wishare/wishareFieldTypes'
import { DonorAccountLinking } from '../components/DonorAccountLinking'
import DonorContext from '../DonorContext'

const formFields = [
  { name: 'owner' },
  { name: 'title' },
  { name: 'email' },
  { name: 'gender' },
  { name: 'phone_number' },
  { name: 'date_of_birth' },
  { name: 'description' },
  { name: 'privacy', defaultValue: 1 },
  { name: 'active', defaultValue: 1 },
  { name: 'status', defaultValue: 1 },
]

const formSchema = [
  {
    children: [
      {
        name: 'owner',
        component:
          createControlledFormField({
            maxLength: 160,
            showCount: true,
            placeholder: 'title',
            Component: ({ value }) => (
              <Pure input={[value]}>
                <DonorAccountLinking
                  owner={value}
                />
              </Pure>
            ),
          }),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            maxLength: 160,
            showCount: true,
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        name: 'date_of_birth',
        label: 'birthday',
        component: compose(
          withTranslate,
          mapProps(
            ({
              value,
              onChange,
              translate,
            }) => ({
              onChange: (
                date,
                dateString
              ) =>
                onChange(
                  createValue(
                    'date_of_birth',
                    date
                      ? moment(
                          new Date(date)
                        )
                      : undefined
                  )
                ),
              defaultValue: value
                ? moment(
                    new Date(value)
                  )
                : undefined,
              format:
                wishareConfigs.dateFormat,
              name: 'date_of_birth',
              type: 'text',
              placeholder:
                translate('birthday'),
              suffixIcon: (
                <IoCalendarOutline />
              ),
            })
          )
        )(DatePicker),
      },
      {
        name: 'gender',
        label: 'gender',
        component: compose(
          withProps({
            buttonStyle: 'solid',
            name: 'gender',
            children: Object.values(
              GenderTypes
            ).map(
              ({
                name,
                value,
                label,
              }) => (
                <Translate key={name}>
                  {(t) => (
                    <Radio.Button
                      value={value}>
                      {t(label)}
                    </Radio.Button>
                  )}
                </Translate>
              )
            ),
          })
        )(Radio.Group),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        name: 'phone_number',
        label: 'phone',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              value,
              onChange,
              placeholder:
                translate('phone'),
              type: 'tel',
            })
          )
        )(Input),
      },
      {
        name: 'email',
        label: 'email',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              value,
              onChange,
              placeholder:
                translate('email'),
              type: 'email',
            })
          )
        )(Input),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
      },
      {
        label: 'active',
        name: 'active',
        component: withAdvancedOptions(
          baseFieldTypes.active
        )(RadioOptions),
      },
    ],
  },
]

const DonorSettingOverview = () => {
  const t = useTranslate()
  const { donor } = useContext(
    DonorContext
  )

  const validationSchema =
    useMemo(() => {
      return yupShape({
        title: requiredString(t),
      })
    }, [t])

  const id = getId(donor)

  const initialized = useCallback(
    ({ date_of_birth, ...values }) => ({
      ...values,
      date_of_birth: !!date_of_birth
        ? moment(
            new Date(date_of_birth)
          )
        : null,
    }),
    []
  )

  const onPreSubmit = ({
    owner,
    date_of_birth,
    ...values
  }) => ({
    ...values,
    ...(!!date_of_birth
      ? {
          date_of_birth: moment(
            new Date(date_of_birth)
          ),
        }
      : {}),
  })

  if (_.isEmpty(donor)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: donor,
    validationSchema,
    query: bindQueryParam({
      id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: donor_updateDonor_api,
  })
}

export default DonorSettingOverview
