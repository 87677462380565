import { createListTransform } from 'apis/utils/createListTransform'
import { schema } from 'normalizr'
import { chartSchema } from '../chart'
import { articleSchema } from './article'
import { channelSchema } from './channel'
import { organizationSchema } from './organization'
import { pollSchema } from './poll'
import { postSchema } from './post'
import { userSchema } from './user'
import { widgetSchema } from './widget'

export const feedSchema = new schema.Entity(
  'feeds',
  {
    container: new schema.Union(
      {
        user: userSchema,
        organization: organizationSchema
      },
      '_type'
    ),
    content: new schema.Union(
      {
        post: postSchema,
        poll_question: pollSchema,
        article: articleSchema,
        widget: widgetSchema
      },
      '_type'
    )
  },
  {
    idAttribute: 'id'
  }
)

export const recomendationUsersFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [userSchema]
  },
  {
    idAttribute: 'id'
  }
)
export const recomendationChannelsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [channelSchema]
  },
  {
    idAttribute: 'id'
  }
)
export const widgetsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [widgetSchema]
  },
  {
    idAttribute: 'id'
  }
)
export const followingChartsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [chartSchema]
  },
  {
    idAttribute: 'id'
  }
)

export const homeFeed = new schema.Union(
  {
    feed: feedSchema,
    'recommendation-channels': recomendationChannelsFeedSchema,
    'recommendation-users': recomendationUsersFeedSchema,
    // 'widgets': widgetsFeedSchema,
    'following-charts': followingChartsFeedSchema
  },
  value => {
    switch (value.action) {
      case 'recommendation-channels':
        return 'recommendation-channels'
      case 'recommendation-users':
        return 'recommendation-users'
      case 'following-charts':
        return 'following-charts'
      // case 'sticky':
      //   return 'widgets'

      default:
        return 'feed'
    }
  }
)