import { Input, Modal } from 'antd'
import {
  activity_delete_api,
  beneficiary_delete_api,
  donation_delete_api,
  donor_deleteDonor_api,
  giving_delete_api,
  organization_Delete_Api,
  recruitment_delete_api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { showConfirm } from 'components/Feed/TimeLineFeed'
import FieldDecorator from 'components/form/FieldDecorator'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useState } from 'react'
import {
  mapStringParams,
  Null,
} from 'views/Shared'
import CustomButton from '../custom/CustomButton'
import {
  fromWishareEntity,
  WishareEntities,
} from '../enums'
import { bindQueryParam } from '../functions/routerHelper'
import { notifyOnError } from './createErrorEvent'

const patterns = {
  types: [
    'donor',
    'beneficiary',
    'giving',
    'donation',
    'activity',
    'recruitment',
    'organization',
  ],
  name: 'type',
  confirmLabel: '{type} name',
  successMessage:
    'the {type} has been deleted',
  errorMessage:
    "you didn't enter the {type} name correctly",
  description:
    '{type} will delete will be gone forever. Are you sure?',
}

const bindingMessage = (
  message,
  value
) =>
  mapStringParams(message, [
    { name: patterns.name, value },
  ])

const DeleteEntity = ({
  id,
  type,
  query,
  values,
  apiInfo,
  onSuccess,
  validate = Null,
  title = 'confirmation',
}) => {
  const t = useTranslate()

  const history = useHistory()

  const [error, setError] = useState()

  const { displayName } =
    fromWishareEntity(type)

  const translated = displayName
    ? t(displayName)
    : displayName

  const {
    description,
    confirmLabel,
    errorMessage,
    successMessage,
  } = {
    confirmLabel: bindingMessage(
      t(patterns.confirmLabel),
      translated
    ),
    successMessage: bindingMessage(
      t(patterns.successMessage),
      translated
    ),
    errorMessage: bindingMessage(
      t(patterns.errorMessage),
      translated
    ),
    description: bindingMessage(
      t(patterns.description),
      translated
    ),
  }

  const {
    isLoading,
    handleAsyncAction = Null,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onSuccess: (result, data) => {
      Modal.success({
        content: successMessage,
        okText: t('OK'),
        okButtonProps: {
          type: 'primary',
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
      })
      if (_.isFunction(onSuccess)) {
        onSuccess(result, data)
      } else {
        history.replace('/')
      }
    },
    onError: notifyOnError(t),
  })

  return (
    <div className="p-3 flex flex-col space-y-3 items-center text-center">
      <div>
        <h2 className="text-2xl font-bold capitalize">
          {t(title)}
        </h2>
        <div>{description}</div>
        <br />
        <div className="text-left">
          <FieldDecorator
            errors={[error]}
            label={confirmLabel}>
            <Input
              id="delete_input"
              autoComplete="off"
              placeholder={t(
                confirmLabel
              )}
            />
          </FieldDecorator>
        </div>
      </div>
      <CustomButton
        onClick={() => {
          const input =
            document.querySelector(
              '#delete_input'
            ) || {}
          const value = input.value
          if (validate(value)) {
            showConfirm({
              title: t(
                'are you sure delete this'
              ),
              okText: t('yes'),
              cancelText: t('no'),
              okButtonProps: {
                type: 'primary',
                className:
                  'rounded-lg no-shadow no-text-shadow',
              },
              cancelButtonProps: {
                className:
                  'rounded-lg no-shadow no-text-shadow',
              },
              onOk: () => {
                handleAsyncAction()
              },
            })
          } else {
            setError(errorMessage)
          }
        }}
        ghost={true}
        useDefaultStyles={false}
        loading={Boolean(isLoading)}
        className="GhostDanger font-bold rounded-full capitalize">
        {t('delete')}
      </CustomButton>
    </div>
  )
}

const createDeleteEntity = (
  item,
  params = {}
) => {
  const [id, type] = [
    getId(item),
    getType(item),
  ]

  let apiInfo = {}

  switch (type) {
    case WishareEntities.DONOR:
      apiInfo = donor_deleteDonor_api
      break
    case WishareEntities.BENEFICIARY:
      apiInfo = beneficiary_delete_api
      break
    case WishareEntities.GIVING:
      apiInfo = giving_delete_api
      break
    case WishareEntities.DONATION:
      apiInfo = donation_delete_api
      break
    case WishareEntities.ACTIVITY:
      apiInfo = activity_delete_api
      break
    case WishareEntities.RECRUITMENT:
      apiInfo = recruitment_delete_api
      break
    case WishareEntities.ORGANIZATION:
      apiInfo = organization_Delete_Api
      break
    default:
      break
  }

  return (
    <DeleteEntity
      id={id}
      type={type}
      apiInfo={apiInfo}
      validate={(value) =>
        _.isEqual(value, getTitle(item))
      }
      query={bindQueryParam({ id })}
      {...params}
    />
  )
}

export default createDeleteEntity
