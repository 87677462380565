import { Select, Table } from 'antd'
import {
  beneficiary_getTopBeneficiaries_api,
  donor_getTopDonors_api,
  organization_fetchTotalDonationInPeriod_Api,
  organization_fetchTotalGivingInPeriod_Api,
  staff_getTopLocationByStaffNumber_Api,
  staff_getTopStaffByDuration_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { IoChevronDown } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  branch,
  compose,
  fromRenderProps,
} from 'recompose'
import {
  deepTranslate,
  getResponseItems,
  Null,
} from 'views/Shared'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/Wishare/custom/AntdConfigWrapper'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import WorkspaceContext, {
  SummaryFilterOptions,
  WorkspaceFilterParams,
} from '../WorkspaceContext'
import { InsightsBlockHeader } from './WorkspaceInsights'

export const InsightsSummaryTypes =
  Object.freeze({
    DEFAULT: 'default',
    GIVING: 'giving',
    DONATION: 'donation',
    HOST_LEFT_LIST: 'host-left-list',
    HOST_RIGHT_LIST: 'host-right-list',
    DONATION_COUNT: 'donation-count',
    DONATION_VALUE: 'donation-value',
    GIVING_COUNT: 'giving-count',
    GIVING_VALUE: 'giving-value',
    STAFF_LEFT_LIST: 'staff-left-list',
    STAFF_RIGHT_LIST:
      'staff-right-list',
    MEDAL_SUMMARY_LIST:
      'medal-summary-list',
  })

export const PeriodFilterHeader = ({
  title,
  value,
  hashId,
  defaultValue,
  visible = true,
  onSelect = Null,
  options = [
    SummaryFilterOptions.THISMONTH,
    SummaryFilterOptions.YESTERDAY,
    SummaryFilterOptions.TODAY,
    SummaryFilterOptions.LAST7DAYS,
    SummaryFilterOptions.LASTMONTH,
    SummaryFilterOptions.THISYEAR,
    SummaryFilterOptions.LASTYEAR,
    SummaryFilterOptions.LAST3YEAR,
  ],
}) => {
  const t = useTranslate()

  const hasFilter =
    !_.isEmpty(options) && !!visible

  const _options = useMemo(() => {
    return Array.from(options).map(
      deepTranslate(t)
    )
  }, [t, options])

  return (
    <InsightsBlockHeader
      id={hashId}
      title={title}>
      {hasFilter && (
        <Select
          size="small"
          value={value}
          options={_options}
          onSelect={onSelect}
          style={{ width: 110 }}
          defaultValue={defaultValue}
          className="text-xs text-color-300"
          suffixIcon={
            <IoChevronDown className="text-color-300" />
          }
        />
      )}
    </InsightsBlockHeader>
  )
}

const WorkspaceInsightsSummary = ({
  title,
  query,
  values,
  period,
  apiInfo,
  columns,
  renderHeader,
  Wrapper = 'div',
  setPeriod = Null,
  withFilter = () => ({}),
}) => {
  const {
    response,
    isLoading = false,
    handleAsyncAction,
  } = useAsync({
    query,
    values: {
      ...values,
      period,
    },
    apiInfo,
  })

  const dataSource = useMemo(() => {
    const items =
      getResponseItems(response)

    return Array.from(items).map(
      (item, index) => ({
        ...item,
        key: index,
        render: (text) => (
          <span className="text-xs text-color-500">
            {text}
          </span>
        ),
      })
    )
  }, [response])

  return (
    <Wrapper className="flex flex-col">
      {_.isFunction(renderHeader) ? (
        renderHeader({
          title,
          period,
          setPeriod,
          onChange: (params = {}) => {
            handleAsyncAction({
              values: {
                ...params,
              },
            })
          },
        })
      ) : (
        <PeriodFilterHeader
          title={title}
          defaultValue={period}
          onSelect={(period) => {
            setPeriod(period)
            handleAsyncAction({
              values: {
                period,
              },
            })
          }}
          {...(withFilter() || {})}
        />
      )}
      <div
        className="border"
        style={
          !!isLoading
            ? undefined
            : {
                borderBottom: 'none',
              }
        }>
        <AntdConfigWrapper
          renderEmpty={() =>
            !!isLoading ? (
              <div className="h-8" />
            ) : (
              <div className="content_disabled">
                <RenderEmptyMeta />
              </div>
            )
          }>
          <Table
            size={'small'}
            columns={columns}
            pagination={false}
            loading={isLoading}
            dataSource={dataSource}
          />
        </AntdConfigWrapper>
      </div>
    </Wrapper>
  )
}

const renderColumnHeader = (label) => (
  <Translate>
    {(t) => (
      <div className="italic font-light text-color-400 text-sm text-center">
        {t(label)}
      </div>
    )}
  </Translate>
)

const InsightsSummaryFactory = ({
  id,
  hashId,
  type = InsightsSummaryTypes.DEFAULT,
}) => {
  const {
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)
  switch (type) {
    case InsightsSummaryTypes.DONATION:
      return (
        <WorkspaceInsightsSummary
          Wrapper={({
            className,
            children,
          }) => (
            <div
              id={hashId}
              className={className}>
              {children}
            </div>
          )}
          title="top donation campaign"
          query={bindQueryParam({
            id,
          })}
          period={getPeriod(
            WorkspaceFilterParams.DONATION
          )}
          setPeriod={(value) =>
            setPeriod(
              WorkspaceFilterParams.DONATION,
              value
            )
          }
          apiInfo={
            organization_fetchTotalDonationInPeriod_Api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title: renderColumnHeader(
                'campaign name'
              ),
            },
            {
              key: 'total',
              fixed: true,
              width: 140,
              title: renderColumnHeader(
                'total donation'
              ),
              dataIndex:
                'total_donation_value',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.GIVING:
      return (
        <WorkspaceInsightsSummary
          Wrapper={({
            className,
            children,
          }) => (
            <div
              id={hashId}
              className={className}>
              {children}
            </div>
          )}
          title="top giving campaign"
          query={bindQueryParam({
            id,
          })}
          period={getPeriod(
            WorkspaceFilterParams.GIVING
          )}
          setPeriod={(value) =>
            setPeriod(
              WorkspaceFilterParams.GIVING,
              value
            )
          }
          apiInfo={
            organization_fetchTotalGivingInPeriod_Api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title: renderColumnHeader(
                'campaign name'
              ),
            },
            {
              key: 'total',
              title: renderColumnHeader(
                'total giving'
              ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_giving_value',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.DONATION_COUNT:
      return (
        <WorkspaceInsightsSummary
          title="top donation count"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'count desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            donor_getTopDonors_api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title:
                renderColumnHeader(
                  'name'
                ),
            },
            {
              key: 'total',
              title:
                renderColumnHeader(
                  'total'
                ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_donation_count',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.DONATION_VALUE:
      return (
        <WorkspaceInsightsSummary
          title="top donation value"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'value desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            donor_getTopDonors_api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title:
                renderColumnHeader(
                  'name'
                ),
            },
            {
              key: 'total',
              title:
                renderColumnHeader(
                  'total'
                ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_donation_value',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.GIVING_COUNT:
      return (
        <WorkspaceInsightsSummary
          title="top giving count"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'count desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            beneficiary_getTopBeneficiaries_api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title:
                renderColumnHeader(
                  'name'
                ),
            },
            {
              key: 'total',
              title:
                renderColumnHeader(
                  'total'
                ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_giving_count',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.GIVING_VALUE:
      return (
        <WorkspaceInsightsSummary
          title="top giving value"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'value desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            beneficiary_getTopBeneficiaries_api
          }
          columns={[
            {
              key: 'name',
              dataIndex: 'title',
              render: (title, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}>
                    {title}
                  </Link>
                </div>
              ),
              title:
                renderColumnHeader(
                  'name'
                ),
            },
            {
              key: 'total',
              title:
                renderColumnHeader(
                  'total'
                ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_giving_value',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.STAFF_LEFT_LIST:
      return (
        <WorkspaceInsightsSummary
          title="top staff time work"
          query={bindQueryParam({
            organization_id: id,
          })}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            staff_getTopStaffByDuration_Api
          }
          columns={[
            {
              key: 'name',
              dataIndex: [
                'staff',
                'name',
              ],
              render: (value, item) => (
                <div className="font-medium text-color-100 max-lines-1">
                  {!!_.get(
                    item,
                    'staff.owner'
                  ) ? (
                    <Link
                      to={getLinkToDetail(
                        _.get(
                          item,
                          'staff.owner'
                        )
                      )}>
                      {value}
                    </Link>
                  ) : (
                    value
                  )}
                </div>
              ),
              title:
                renderColumnHeader(
                  'name'
                ),
            },
            {
              key: 'employee_type',
              dataIndex: [
                'staff',
                'employee_type',
              ],
              render: (value, item) => (
                <Translate>
                  {(t) => (
                    <span>
                      {t(value)}
                    </span>
                  )}
                </Translate>
              ),
              title: renderColumnHeader(
                'employee type'
              ),
            },
            {
              key: 'total_hours_time',
              title: renderColumnHeader(
                'total hours'
              ),
              dataIndex:
                'total_hours_time',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.STAFF_RIGHT_LIST:
      return (
        <WorkspaceInsightsSummary
          title="top staff area"
          query={bindQueryParam({
            organization_id: id,
          })}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            staff_getTopLocationByStaffNumber_Api
          }
          columns={[
            {
              key: 'province',
              dataIndex: [
                'province',
                'location_name',
              ],
              title:
                renderColumnHeader(
                  'province'
                ),
            },
            {
              key: 'total_staff_count',
              title: renderColumnHeader(
                'total staffs'
              ),
              fixed: true,
              width: 140,
              dataIndex:
                'total_staff_count',
              render: (value) => (
                <div className="font-medium uppercase text-right text-color-000">
                  {Number(
                    value
                  ).toLocaleString()}
                </div>
              ),
            },
          ]}
        />
      )
    case InsightsSummaryTypes.HOST_LEFT_LIST:
      return (
        <WorkspaceInsightsSummary
          title="host left summary"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'count desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            donor_getTopDonors_api
          }
          columns={[]}
        />
      )
    case InsightsSummaryTypes.HOST_RIGHT_LIST:
      return (
        <WorkspaceInsightsSummary
          title="host right summary"
          query={bindQueryParam({
            organization_id: id,
          })}
          values={{
            sort_by: 'value desc',
          }}
          withFilter={() => ({
            visible: false,
          })}
          apiInfo={
            donor_getTopDonors_api
          }
          columns={[]}
        />
      )
    case InsightsSummaryTypes.DEFAULT:
    default:
      return null
  }
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      item: organization,
      id: getId(organization),
    })
  ),
  branch(
    ({ item }) => _.isEmpty(item),
    () => () => <WishareNotFound />
  )
)(InsightsSummaryFactory)
