import ErrorBoundary from 'components/error/ErrorBoundary'
import Null from 'components/NullComponent'
import React from 'react'
import AmChart from '../AmChart'
import ChartWrapper from '../ChartWrapper/ChartWrapper'
import _ from 'lodash'
import AmChartStateless from "../AmChart/components/AmChartStateless";

export const IOChartStateless = ({
                                     header = Null,
                                     item,
                                     CWrapper = ChartWrapper,
                                     ...props
                                   }) => {
  return (
    <ErrorBoundary
      fallbackComponent={() => {
        return (
          <div className="w-full h-full flex justify-center items-center font-bold text-color-300 text-2xl">
            Error
          </div>
        )
      }}>
      <div
        style={
          {
            minHeight: 360,
            height: 0,
          }
        }
        className="flex-1 flex flex-col">
        <AmChartStateless item={item}
                          setChartWrap={() => null}
                          {...props} />
      </div>
    </ErrorBoundary>
  )
}

export const IOChart = ({
                          chartInfo,
                          unit,
                          ...props
                          }) => {
  let item = _.cloneDeep(chartInfo);
  item.chart_source = 'AmChart';
  item.chart_type = 'XYChart';
  item.subscribe_required = 0;
  item.data_encrypted = 0;
  item.data_empty = 0;
  item.version = Date.now();
  item._type = 'chart';

  item.params = {
    "numberFormatter": {
      "numberFormat": "#.##a"
    },
    "leftAxesContainer": {
      "layout": "vertical"
    },
    "bottomAxesContainer": {
      "reverseOrder": true
    },

    "seriesContainer":{
      "children":[
        {
          "type":"Label",
          "text":"Donations/givings generation over time",
          "textAlign":"start",
          "align":"left",
          "fontSize":"0.68rem",
          "fontWeight":"600",
          "paddingLeft":5,
          "opacity":0.5
        },
        {
          "type":"Label",
          "text":"Daily",
          "textAlign":"start",
          "align":"left",
          "fontSize":"0.68rem",
          "fontWeight":"600",
          "paddingLeft":5,
          "opacity":0.5,
          "y":"75%"
        }
      ]
    },
    "xAxes": [
      {
        "id": "x1",
        "type": "DateAxis",
        "baseInterval": {
          "timeUnit": "day",
          "count": 1
        },
        "keepSelection": true,
        "dateFormats": {
          "day": "dd-MM-yyyy"
        },
        "periodChangeDateFormats": {
          "day": "MM-yyyy"
        },
        "dataFields": {
          "date": "time"
        },
        "renderer": {
          // "cellStartLocation": 0.1,
          // "cellEndLocation": 0.9,
          "fontSize": "0.68em"
        },
        "opacity": 0.5
      }
    ],
    "yAxes": [
      {
        "type": "ValueAxis",
        "id": "y1",
        "zIndex": 3,
        "extraMax": 0.01,
        "height":"75%",
        "tooltip": {
          "disabled": true
        },
        "renderer": {
          "fontSize": "0.68em",
          "grid": {
            "location": 0,
            "strokeOpacity": 0.2,
            "stroke": "#111",
            "strokeWidth": 0.5
          },
          "line": {
            "strokeOpacity": 0.5
          },
          "ticks": {
            "disabled": false,
            "strokeOpacity": 0.5,
            "length": 2
          },
          "labels": {
            "fontSize": "0.68rem",
            "wrap": true,
            "width": 41,
            "maxWidth": 41
          }
        },
        "opacity": 0.5
      },
      {
        "type": "ValueAxis",
        "id": "y2",
        "zIndex": 3,
        "extraMax": 0.01,
        "height":"25%",
        "tooltip": {
          "disabled": true
        },
        "renderer": {
          "fontSize": "0.68em",
          "grid": {
            "location": 0,
            "strokeOpacity": 0.2,
            "stroke": "#111",
            "strokeWidth": 0.5
          },
          "line": {
            "strokeOpacity": 0.5
          },
          "ticks": {
            "disabled": false,
            "strokeOpacity": 0.5,
            "length": 2
          },
          "labels": {
            "fontSize": "0.68rem",
            "wrap": true,
            "width": 41,
            "maxWidth": 41
          }
        },
        "opacity": 0.5
      }
    ],
    "series": [
      {
        "type": "LineSeries",
        "id": "s1",
        "yAxis": "y1",
        "name": "Donation",
        "stroke": "#2bade3",
        "strokeWidth": 3,
        "tensionX": 0.9,
        "dataFields": {
          "valueY": "value1",
          "dateX": "time"
        },
        "bullets": [
          {
            "type": "LabelBullet",
            "label": {
              "text": "[font-size: 10px]{value}[/]",
              "dy": -20
            }
          },
          {
            "type": "CircleBullet",
            "fillOpacity": 1,
            "strokeOpacity": 1,
            "circle": {
              "radius": 3,
              "strokeWidth": 1,
              "stroke": "#fff"
            },
            "states": {
              "hover": {
                "properties": {
                  "scale": 1.7,
                  "fillOpacity": 1,
                  "strokeOpacity": 1
                }
              }
            }
          },
        ],
        "tooltipText": "{dateX}: " +
          "\n[#2bade3 font-size: 10px font-style: italic]Donation: [/]" +
          "\n[font-size: 12px bold]Input: {value3.formatNumber('#,###.##')} " + unit + "[/]" +
          "\n[font-size: 9px #2bade3]Total: {value1.formatNumber('#,###.##')} " + unit + "[/] " +
          "\n[#f285b5 font-size: 10px font-style: italic]Giving: [/]" +
          "\n[font-size: 12px bold]Output: {value4.formatNumber('#,###.##')} " + unit + "[/]" +
          "\n[font-size: 9px #f285b5]Total: {value2.formatNumber('#,###.##')} " + unit + "[/] ",
        "tooltip": {
          "getFillFromObject": false,
          "background": {
            "fill": "#fff",
          },
          "label": {
            "fill": "#282828",
          },
          "fill": "fff",
          "propertyFields": {
            "pointerOrientation": "orientation",
            "dy": "offset"
          }
        }
      },
      {
        "type": "LineSeries",
        "id": "s2",
        "yAxis": "y1",
        "name": "Giving",
        "stroke": "#f285b5",
        "strokeWidth": 2,
        "tensionX": 0.9,
        "dataFields": {
          "valueY": "value2",
          "dateX": "time"
        },

        "bullets": [
          {
            "type": "LabelBullet",
            "label": {
              "text": "[font-size: 10px]{value}[/]",
              "dy": -20
            }
          },
          {
            "type": "CircleBullet",
            "fillOpacity": 1,
            "strokeOpacity": 1,
            "circle": {
              "radius": 3,
              "strokeWidth": 1,
              "stroke": "#fff"
            },
            "states": {
              "hover": {
                "properties": {
                  "scale": 1.7,
                  "fillOpacity": 1,
                  "strokeOpacity": 1
                }
              }
            }
          },
        ],
      },
      {
        "type": "ColumnSeries",
        "id": "s3",
        "yAxis": "y2",
        "name": "Donation",
        "hiddenInLegend": true,
        "fill": "#2bade3",
        "strokeWidth": 0,
        "tensionX": 0.9,
        "dataFields": {
          "valueY": "value3",
          "dateX": "time"
        },
        "tooltipText": "{valueY.formatNumber('#,###.##')}",
      },
      {
        "type": "ColumnSeries",
        "id": "s4",
        "yAxis": "y2",
        "name": "Giving",
        "hiddenInLegend": true,
        "fill": "#f285b5",
        "strokeWidth": 0,
        "tensionX": 0.9,
        "dataFields": {
          "valueY": "value4",
          "dateX": "time"
        },
        "tooltipText": "{valueY.formatNumber('#,###.##')}",
      },
    ],
    "legend": {
      "fontSize": "0.68rem"
    },
    "cursor": {
      "type": "XYCursor",
      "xAxis": "x1",
      "lineX": {
        "stroke": "#2bade3",
        "fill": "#dbd3e3",
        "strokeOpacity": 0.5,
        "strokeDasharray": ""
      },
      "lineY": {
        "disabled": true
      }
    }
  };

  item.data_columns = [
    {
      id: "time",
      dataIndex: "time",
      type: "date",
    },
    {
      id: "value1",
      dataIndex: "value1",
      type: "number",
    },
    {
      id: "value2",
      dataIndex: "value2",
      type: "number",
    },
    {
      id: "value3",
      dataIndex: "value3",
      type: "number",
    },
    {
      id: "value4",
      dataIndex: "value4",
      type: "number",
    }
  ];

  return (
    <IOChartStateless
      item={item}
      CWrapper={ChartWrapper}
      {...props}
    />
  )
}

export default React.memo(IOChart)
