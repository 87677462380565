import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { API_ROOT_URL } from 'envs/_current/config'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import useToggle from 'react-use/lib/useToggle'
import { emptyObject } from '../helpers/emptyObjects'

// Route::post('referrals/{referral_id}/edit-referral', 'ReferralController@updateReferral');
export const UpdateReferalCode = ({
  referral: initreferral,
}) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  const t = useTranslate()
  const timoutoptions = [
    {
      value: 0,
      label: t('none'),
    },
    {
      value: 120,
      label: t('5 minutes'),
    },
    {
      value: 1800,
      label: t('30 minutes'),
    },
    {
      value: 3600,
      label: t('1h'),
    },
    {
      value: 86400,
      label: t('1day'),
    },
  ]
  const actionoptions = [
    {
      label: t('Join As Member'),
      value: 'joinAsMember',
    },
    {
      label: t('Join As Admin'),
      value: 'joinAsAdmin',
    },
  ]
  const [referral, setReferal] =
    useState(initreferral)
  const [isToggle1, toggle1] =
    useToggle()
  const referral_id = _.get(
    referral,
    'referral_code'
  )
  const [{ isloading }, dispath] =
    useDispatchAsyncActionWithNotify({
      onSuccess: ([__, data]) => {
        const referral = logFunc(_.get)(
          data,
          'response.data'
        )
        setReferal(referral)
        toggle1()
      },
    })
  const onFinish = (values) => {
    dispath(
      createAsyncAction({
        apiInfo: {
          root: API_ROOT_URL,
          path: `/referrals/${referral_id}/edit-referral`,
          method: 'POST',
        },
        values,
      })
    )
  }
  const [form] = useForm()
  const initialValues = {
    timeout: _.get(referral, 'timeout'),
    action:
      _.get(referral, 'action') ||
      'joinAsMember',
    limit: _.get(referral, 'limit'),
  }
  useEffect(() => {
    form.resetFields()
  }, [form, isToggle1])
  return (
    <Form
      key={JSON.stringify({
        initialValues,
        isToggle1,
      })}
      form={form}
      {...layout}
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}>
      <div className="rounded-lg p-3 border border-color-50">
        <div className="flex items-end mb-3">
          <div className="font-bold">
            {t('options')}
          </div>
          <div className="flex-1" />
          <Button
            type="primary"
            size="small"
            className="rounded-md no-shadow no-text-shadow text-xs"
            onClick={toggle1}>
            {t('change')}
          </Button>
        </div>
        <div className="space-x-3 flex  items-baseline">
          <div className="font-light text-xs text-color-300 italic">
            {t('action')}:
          </div>
          <div>
            {
              (
                actionoptions.find(
                  (item) =>
                    _.get(
                      referral,
                      'action'
                    ) === item.value
                ) || emptyObject
              ).label
            }
          </div>
        </div>
        <div className="space-x-3 flex  items-baseline">
          <div className="font-light text-xs text-color-300 italic">
            {t('timeout')}:
          </div>
          <div>
            {
              (
                timoutoptions.find(
                  (item) =>
                    _.get(
                      referral,
                      'timeout'
                    ) === item.value
                ) || emptyObject
              ).label
            }
          </div>
        </div>
        <div className="space-x-3 flex  items-baseline">
          <div className="font-light text-xs text-color-300 italic">
            {t('limit')}:
          </div>
          <div>
            {_.get(referral, 'limit')}
          </div>
        </div>
      </div>
      <Modal
        className="custom-modal"
        destroyOnClose
        visible={isToggle1}
        title={
          <div className="font-bold text-color-000">{t('options')}</div>
        }
        onCancel={toggle1}
        onOk={form.submit}
        okText={t('save')}
        cancelText={t('cancel')}
        okButtonProps={{
          loading: isloading,
          type: 'primary',
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        }}
        cancelButtonProps={{
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        }}>
        <Form.Item
          label={t('action')}
          name="action">
          <Select
            options={actionoptions}
          />
        </Form.Item>
        <Form.Item
          label={t('timeout')}
          name="timeout">
          <Select
            placeholder={t('timeout')}
            options={
              timoutoptions
            }
          />
        </Form.Item>
        <Form.Item
          name="limit"
          label={t('limit')}>
          <InputNumber
            min={0}
          />
        </Form.Item>
      </Modal>
    </Form>
  )
}
