import {
  Avatar,
  Button,
  List,
  Tooltip,
} from 'antd'
import { host_searchHostsByOwner_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import classNames from 'classnames'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import { ImLibrary } from 'react-icons/im'
import { Redirect } from 'react-router-dom'
import LoadingPage from 'views/LoadingPage'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItems,
  Null,
} from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'
import { useHostActions } from '../HostContext'
import { fromHostStatus } from '../Settings/HostContent'

const renderRow =
  ({ onClick = Null }) =>
  (item, index) => {
    const {
      certified,
      owner = {},
      excluded = false,
    } = item || {}

    const id = getId(owner)
    const prop = getType(owner)

    const host_status =
      fromHostStatus(-1)

    return (
      <div
        key={index}
        className="relative rounded-lg hover:shadow-out border border-color-50 mb-3 background">
        <OverlayItemBanner
          type={prop}
          title={prop}
        />
        <div className="h-7" />
        <Translate>
          {(t) => (
              <div className="p-3 flex items-center gap-2">
                  <Avatar
                      src={getAvatar(owner)}
                      size={30}
                      className="flex flex-center background-100 rounded-md"
                      icon={<ImLibrary size={16} className="text-color-300" />}
                  />
                  <div className="flex flex-1 items-center gap-1">
                      <span className="font-semibold flex items-center custom-title-host">
                          {getTitle(owner)}
                      </span>
                      {certified === 1 && (
                          <Tooltip
                              title={t(
                                  'verified'
                              )}
                              placement="right"
                              color="#87d068">
                              <VerifiedSvg
                                  width="1rem"
                                  height="1rem"
                                  className="ml-1 mb-1 lg:mb-2 zoom-animation"
                              />
                          </Tooltip>
                      )}
                  </div>
                  {excluded
                      ? <div
                          className={classNames(
                              host_status.className,
                              'rounded-full px-3 italic space-x-1 flex items-center'
                          )}>
                          {host_status.icon}
                          <span>{t(host_status.label)}</span>
                      </div>
                      : <Button
                          ghost={true}
                          type="primary"
                          size="small"
                          className="capitalize text-xs font-medium no-shadow notext-shadow rounded-md"
                          onClick={() => {
                              onClick(
                                  id,
                                  prop
                              )
                          }}>
                          {t(
                              'send request'
                          )}
                      </Button>
                  }
              </div>
          )}
        </Translate>
      </div>
    )
  }

const AddHostModalContent = ({
  Wrapper = 'div',
  ...props
}) => {
  const t = useTranslate()

  const [
    excluded_ids,
    set_excluded_ids,
  ] = useState([])

  const { setCurrent } = useContext(
    NavigationContext
  )

  const [keyword, __, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const apiInfo =
    host_searchHostsByOwner_api

  const [id, prop] = [
    _.get(props, 'location.state.id'),
    _.get(props, 'location.state.prop'),
  ]

  const { onCreate = Null } =
    useHostActions({
      container: {
        id,
        _type: prop,
      },
      onCreated: (result, data) => {
        const host_id = getId(
          _.get(
            data,
            'response.data.host'
          )
        )
        setCurrent(result)
        set_excluded_ids([
          ...excluded_ids,
          host_id,
        ])
      },
    })

  const renderHost = useCallback(
    (item, index) =>
      renderRow({
        onClick: (host_id, host_type) =>
          onCreate({
            host_id,
            host_type,
          }),
      })(item, index),
    [onCreate]
  )

  if (_.every([id, prop], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  return (
    <Wrapper className="space-y-3 py-3 mt-8">
      <div className="text-center text-xl font-bold uppercase text-color-000">
        {t('add host')}
      </div>
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParams([
          { id },
          { prop },
        ])}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items =
            getResponseItems(
              response
            ) || []
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={(
                item,
                index
              ) =>
                renderHost({
                  ...item,
                  excluded:
                    excluded_ids.includes(
                      getId(item)
                    ),
                })
              }
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

const AddHostModal = (props) => (
  <AntdConfigWrapper>
    <AddHostModalContent {...props} />
  </AntdConfigWrapper>
)

export default AddHostModal
