import Null from 'components/NullComponent';
import Loadable from "react-loadable";

export const ArticleVersions = Object.freeze({
  DEFAULT: 'default',
  MEDIUM: 'medium'
})

export const currentArticleVersion = ArticleVersions.MEDIUM

export const isMdmArticleEnabled = currentArticleVersion === ArticleVersions.MEDIUM

export default Loadable({
  loader: () => import('./CreateMdmArticle'),
  loading: Null,
})
