import { Menu } from 'antd'
import { create_organization_getById_Api_action } from 'apis'
import { organizationSchema } from 'apis/schema'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import { isDevelop } from 'envs/ForDevelop'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {
  useContext,
  useMemo,
} from 'react'
import { connect } from 'react-redux'
import {
  matchPath,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {
  branch,
  compose,
  lifecycle,
  mapProps,
  nest,
} from 'recompose'
import { selectEntities } from 'redux/entities/selectors'
import useRouter from '../../../modules/navigation/useRouter'
import PermissionContext from '../../../modules/permissions/PermissionContext'
import { getServiceTypes } from '../../Organization/functions/validateServiceType'
import { OrganizationSettingContext } from './OrganizationSettingContext'
import Overview from './Overview.lazy'
import {
  routeEntities,
  routes,
} from './routes'
import SettingsLayout from './SettingsLayout'

const Settings = ({
  item,
  onCancel,
}) => {
  const t = useTranslate()
  const location = useLocation()
  let { url } = useRouteMatch()
  const history = useHistory()
  const { handleGoBack, ...rest } =
    useRouter()
  const select = useMemo(() => {
    return routes.find((item) => {
      const matched = matchPath(
        location.pathname,
        {
          ...item,
          path: `${url}/${item.path}`,
        }
      )
      return matched
    })
  }, [
    logParams(location).pathname,
    logParams(url),
  ])
  const pers =
    useContext(PermissionContext) || {}

  const contextMenu = useMemo(
    () =>
      isDevelop() ? (
        <Menu
          defaultSelectedKeys={[
            select && select.name,
          ]}
          onClick={({ key, item }) => {
            switch (key) {
              default:
                history.push({
                  pathname: `${url}/${key}`,
                  search:
                    item && item.search,
                  state: {
                    isModal: true,
                  },
                })
                break
            }
          }}>
          <Menu.Item
            key={
              routeEntities.overview
                .path
            }>
            <span className="font-semibold">
              {t('overview')}
            </span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.ItemGroup
            key="g1"
            title={t(
              'provider profile'
            )}>
            <Menu.Item
              key={
                routeEntities.about.path
              }>
              <span className="font-semibold">
                {t('about us')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities.photos
                  .path
              }>
              <span className="font-semibold">
                {t('photos')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities.profiles
                  .path
              }>
              <span className="font-semibold">
                {t('profile')}
              </span>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup
            key="g2"
            title={t(
              'location & contact'
            )}>
            <Menu.Item
              key={
                routeEntities.locations
                  .path
              }>
              <span className="font-semibold">
                {t('location')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities.contacts
                  .path
              }>
              <span className="font-semibold">
                {t('contact')}
              </span>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup
            key="g3"
            title={t(
              'member management'
            )}>
            <Menu.Item
              key={
                routeEntities.members
                  .path
              }>
              <span className="font-semibold">
                {t('members list')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities
                  .waitingMembers.path
              }>
              <span className="font-semibold">
                {t(
                  'waiting members list'
                )}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities.invite
                  .path
              }>
              <span className="font-semibold">
                {t('invites')}
              </span>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          {_.get(
            pers,
            'dataset-allowable',
            false
          ) && (
            <Menu.ItemGroup
              key="g4"
              title={t(
                'dataset management'
              )}>
              <Menu.Item
                key={
                  routeEntities
                    .manageDataset.path
                }>
                <span className="font-semibold">
                  {t('dataset list')}
                </span>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {_.get(
            pers,
            'dataset-allowable',
            false
          ) && <Menu.Divider />}
          {_.get(
            pers,
            'channel-allowable',
            false
          ) && (
            <Menu.ItemGroup
              key="g5"
              title={t(
                'channel management'
              )}>
              <Menu.Item
                key={
                  routeEntities
                    .managechannel.path
                }>
                <span className="font-semibold">
                  {t('channel list')}
                </span>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {_.get(
            pers,
            'channel-allowable',
            false
          ) && <Menu.Divider />}
          {_.get(
            pers,
            'product-allowable',
            false
          ) && (
            <Menu.ItemGroup
              key="g6"
              title={t('for sales')}>
              <Menu.Item
                key={
                  routeEntities
                    .manageProducts.path
                }>
                <span className="font-semibold capitalize">
                  {t('products list')}
                </span>
              </Menu.Item>
              <Menu.Item
                key={
                  routeEntities
                    .managePrices.path
                }>
                <span className="font-semibold capitalize">
                  {t(
                    'bulk price update'
                  )}
                </span>
              </Menu.Item>
              <Menu.Item
                key={
                  routeEntities
                    .manageProductReports
                    .path
                }>
                <span className="font-semibold capitalize">
                  {t(
                    'product price announcement'
                  )}
                </span>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {_.get(
            pers,
            'product-allowable',
            false
          ) && <Menu.Divider />}
          <Menu.Item
            key={
              routeEntities.delete.path
            }>
            <span className="font-semibold text-red-600">
              {t('delete provider')}
            </span>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu
          defaultSelectedKeys={[
            select && select.name,
          ]}
          onClick={({ key }) => {
            switch (key) {
              default:
                history.push({
                  pathname: `${url}/${key}`,
                  state: {
                    isModal: true,
                  },
                })
                break
            }
          }}>
          <Menu.Item
            key={
              routeEntities.overview
                .path
            }>
            <span className="font-semibold">
              {t('overview')}
            </span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.ItemGroup
            key="g0"
            title={t(
              'location & contact'
            )}>
            <Menu.Item
              key={
                routeEntities.locations
                  .path
              }>
              <span className="font-semibold">
                {t('location')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities.contacts
                  .path
              }>
              <span className="font-semibold">
                {t('contact')}
              </span>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.Divider />
          <Menu.ItemGroup
            key="g1"
            title={t(
              'member management'
            )}>
            <Menu.Item
              key={
                routeEntities.members
                  .path
              }>
              <span className="font-semibold">
                {t('members')}
              </span>
            </Menu.Item>
            <Menu.Item
              key={
                routeEntities
                  .waitingMembers.path
              }>
              <span className="font-semibold">
                {t(
                  'waiting members list'
                )}
              </span>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item
            key={
              routeEntities.delete.path
            }>
            <span className="font-semibold text-red-600">
              {t('delete provider')}
            </span>
          </Menu.Item>
        </Menu>
      ),
    [pers]
  )

  return (
    <FullSideModalLayout
      title={item.title}
      full
      onCancel={() => {
        history.replace(`/workspace`)
      }}>
      <SettingsLayout
        select={
          (select && select.name) ||
          'setting'
        }
        contextMenu={contextMenu}>
        {select ? (
          <Route
            {...select}
            path={`${url}/${select.path}`}
          />
        ) : (
          <Overview />
        )}
      </SettingsLayout>
    </FullSideModalLayout>
  )
}

export default compose(
  connect((state, props) => ({
    item: selectEntities(
      state,
      props.id,
      organizationSchema
    ),
  })),
  lifecycle({
    componentDidMount() {
      this.props.dispatch(
        create_organization_getById_Api_action()(
          {
            query: {
              ':id': this.props.id,
            },
          }
        )
      )
    },
  }),
  branch(
    (props) => !props.item,
    () => Null
  )
)(
  nest(
    mapProps(({ item, children }) => ({
      children,
      value: { item },
    }))(
      OrganizationSettingContext.Provider
    ),
    mapProps(({ item, children }) => ({
      children,
      permissions: {
        edit: item.edit,
        ...getServiceTypes(
          item.service_type
        ),
        ...item.available_actions,
      },
    }))(PermissionProvider),
    Settings
  )
)
