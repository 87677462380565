import ActivityEntity from '../Activity/ActivityEntity'
import ActivityItem from '../Activity/components/ActivityItem'
import DonationEventItem from '../Donation/components/DonationEventItem'
import DonationEntity from '../Donation/DonationEntity'
import { WishareEntities } from '../enums'
import EventItem from '../Event/components/EventItem'
import EventEntity from '../Event/EventEntity'
import GivingEventItem from '../Giving/components/GivingEventItem'
import GivingEntity from '../Giving/GivingEntity'
import OrganizationItem from '../Organization/components/OrganizationItem'
import OrganizationEntity from '../Organization/OrganizationEntity'
import RecruitmentEventItem from '../Recruitment/components/RecruitmentEventItem'
import RecruitmentEntity from '../Recruitment/RecruitmentEntity'

const createEntityListParams = (
  entity
) => {
  let RenderItem
  let RenderEntity
  let collection = ''

  switch (entity) {
    case WishareEntities.ORGANIZATION:
      RenderItem = OrganizationItem
      RenderEntity = OrganizationEntity
      collection = 'organizations'
      break
    case WishareEntities.EVENT:
      RenderItem = EventItem
      RenderEntity = EventEntity
      collection = 'events'
      break
    case WishareEntities.GIVING:
      RenderItem = GivingEventItem
      RenderEntity = GivingEntity
      collection = 'giving-events'
      break
    case WishareEntities.DONATION:
      RenderItem = DonationEventItem
      RenderEntity = DonationEntity
      collection = 'donation-events'
      break
    case WishareEntities.ACTIVITY:
      RenderItem = ActivityItem
      RenderEntity = ActivityEntity
      collection = 'activities'
      break
    case WishareEntities.RECRUITMENT:
      RenderItem = RecruitmentEventItem
      RenderEntity = RecruitmentEntity
      collection = 'recruitment-events'
      break
    default:
      break
  }

  return {
    collection,
    RenderItem,
    RenderEntity,
  }
}

export default createEntityListParams
