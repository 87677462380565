import {
  Avatar,
  Input,
  Result,
} from 'antd'
import { getId } from 'apis/model/base'
import { donationEventSchema } from 'apis/schema'
import FormActionBar from 'components/form/FormActionBar'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
} from 'react'
import { Redirect } from 'react-router-dom'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import { paths } from 'views/MainPage/contains'
import { Null } from 'views/Shared'
import { donationApi } from 'views/Wishare/apis'
import { withModalLayout } from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import PhotoField from 'views/Wishare/custom/PhotoField'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import DonationContext from '../DonationContext'
import DynamicFields from './DyanmicFields'
import EditDonationOptionItemFields, {
  EditDonationOptionItemProvider,
} from './EditDonationOptionItemFields'

const formFields = [
  { name: 'photo' },
  { name: 'title' },
  { name: 'description' },
  { name: 'photo_preview' },
]

const formSchema = [
  {
    title: 'name package',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    title: 'photo',
    children: [
      {
        name: 'photo',
        component: compose(
          mapProps(
            ({
              name,
              value,
              form,
              onChange,
            }) => {
              const values = _.get(
                form,
                'values'
              )
              return {
                name,
                value,
                values,
                onChange,
                Wrapper: ({
                  children,
                }) => (
                  <FlexCol
                    style={{
                      minHeight: 150,
                    }}>
                    {children}
                  </FlexCol>
                ),
              }
            }
          )
        )(PhotoField),
      },
    ],
  },
  {
    title: 'content',
    children: [
      {
        name: 'donation_event_option_items',
        component:
          createControlledFormField({
            edit: true,
            Component: DynamicFields,
            Item: withProps({
              listName:
                'donation_event_option_items',
            })(
              EditDonationOptionItemFields
            ),
            withValues: ({
              donation_type,
              target_unit_name,
            }) => ({
              donation_type,
              target_unit_name,
            }),
            Wrapper: ({
              children,
              donation_type,
              target_unit_name,
            }) => (
              <EditDonationOptionItemProvider
                type_cd={donation_type}
                type_cds={
                  wishareFieldTypes
                    .donation.types
                }
                prefixTitle="item"
                getGroup={(source) =>
                  _.get(
                    source,
                    'type_cds'
                  ) || []
                }
                target_unit_name={
                  target_unit_name
                }>
                {children}
              </EditDonationOptionItemProvider>
            ),
          }),
      },
    ],
  },
]

const FormContent = ({
  donation,
  currentOption,
  Wrapper = 'div',
  onCancel = Null,
  ...props
}) => {
  const t = useTranslate()

  const id = getId(donation)

  const isSm = useContext(LayoutContext)

  const initialized = useCallback(
    ({ ...values }) => {
      const { option_items } =
        currentOption || {}

      const {
        donation_type,
        target_unit_name,
      } = donation || {}

      return {
        ...values,
        donation_type,
        target_unit_name,
        donation_event_option_items:
          option_items || [{}],
      }
    },
    [donation]
  )

  const validationSchema = yupShape({
    title: requiredString(t),
    description:
      requiredString(t).nullable(),
  })

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo = currentOption
    ? donationApi.donation_events_editDonationOption_api
    : donationApi.donation_events_addDonationOption_api

  const onSuccess = ([
    __,
    { response },
  ]) => {
    // const newItem = getResponseItem(response)
    onCancel()
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    donation_type,
    target_unit_name,
    donation_event_option_items,
    ...values
  }) => {
    const _donation_event_option_items =
      Array.from(
        donation_event_option_items ||
          []
      ).map(
        ({
          unit_name,
          option_qtty,
          ...rest
        }) => ({
          unit_name,
          option_qtty,
          item_name: unit_name,
          option_value: option_qtty,
          ...rest,
        })
      )
    return {
      ...values,
      donation_event_option_items:
        JSON.stringify(
          _donation_event_option_items
        ),
    }
  }

  const option_id = getId(currentOption)

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    apiInfo,
    onError,
    onSuccess,
    initialized,
    onPreSubmit,
    validationSchema,
    item: currentOption,
    query: bindQueryParams([
      {
        id,
      },
      { option_id },
    ]),
    ActionBar: () => <FormActionBar />,
  })

  const title = getTitle(donation)

  const avatar = _.get(
    donation,
    'owner.avatar'
  )
  const owner = _.get(
    donation,
    'owner.title'
  )

  return (
    <Wrapper className="space-y-3 py-3 max-w-full mx-auto">
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
        <center>
          {t('donation package')}
        </center>
      </div>
      <div className="flex flex-col mb-6 p-3 border rounded-lg">
        <span className=" text-sm font-semibold text-color-300 tracking-wide mb-1">
          {t('owner')}
        </span>
        <div className="flex items-center font-bold text-primary">
          <Avatar
            src={avatar}
            size={40}
            className="flex flex-center background-100">
            <span className="capitalize font-normal text-color-300">
                {_.first(title)}
            </span>
          </Avatar>
          <div className="flex flex-col flex-1 mx-3">
            <span className="text-base md:text-lg max-lines-1">
              {title}
            </span>
            <span className="text-xs font-medium text-color-300 italic">
              {owner}
            </span>
          </div>
        </div>
      </div>
      {content}
    </Wrapper>
  )
}

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const CreateDonationOptionForm = ({
  ...props
}) => {
  const { donation: item } = useContext(
    DonationContext
  )

  const { id, currentOption } =
    _.get(props, 'location.state') || {}

  const donation = useSelectEntities(
    id,
    donationEventSchema,
    item
  )

  if (_.isEmpty(donation)) {
    return <WishareNotFound />
  }

  const ModalForm = _.get(
    donation,
    'edit'
  )
    ? FormContent
    : AccessDenied

  const Component = withModalLayout(
    ModalForm,
    {
      donation,
      currentOption,
      ...props,
    }
  )

  return (
    <AntdConfigWrapper>
      <Pure>
        <Component />
      </Pure>
    </AntdConfigWrapper>
  )
}

export default createRequiredLogin()(
  CreateDonationOptionForm
)
