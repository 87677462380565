import _ from 'lodash'
import { useCategoryConfig } from 'modules/local'
import { useMemo } from 'react'
import { Null } from 'views/Shared'
import { wishareConfigs } from '../configs'

const useImpactCategories = (
  renderItem = Null
) => {
  const impactCategories =
    useCategoryConfig(
      wishareConfigs.impCatePath,
      []
    )

  const categories = useMemo(() => {
    if (_.isEmpty(impactCategories))
      return []
    return Array.from(
      impactCategories
    ).map(renderItem)
  }, [renderItem, impactCategories])

  if (_.isEmpty(categories)) return []

  return categories
}

export default useImpactCategories
