import {
  Avatar,
  Button,
  List,
} from 'antd'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { IoPerson } from 'react-icons/io5'
import {
  compose,
  mapProps,
} from 'recompose'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { LayoutContext } from '../../../../components/layouts/Default/LayoutContext'
import GivingReferralSelector from './GivingReferralSelector'

const ReferralCreator = ({
  keyword,
  onCreate = Null,
}) => {
  const t = useTranslate()

  return [keyword]
    .filter(
      (value) => !_.isEmpty(value)
    )
    .map((value, index) => (
      <div
        key={index}
        className="flex items-center border background rounded-lg p-3">
        <div className="font-medium text-color-000 italic">
          {`"${value}"`}
        </div>
        <div className="flex-1" />
        <span className="text-xs font-light text-color-500 italic mr-2">
          {t(
            'Click here to create a new one'
          )}
        </span>
        <Button
          type="primary"
          size="small"
          className="no-text-shadow no-shadow rounded-md text-xs font-medium"
          onClick={() => {
            onCreate(value)
          }}>
          {t('create')}
        </Button>
      </div>
    ))
}

const renderReferral = (
  referral_name
) => {
  if (_.isEmpty(referral_name)) {
    return (
      <div className="SearchReferralInput w-full">
        <Translate>
          {(t) => (
            <div className="flex items-center gap-2">
              <LayoutContext.Consumer>
                {({ isLg }) =>
                  isLg && (
                    <Avatar
                      icon={
                        <IoPerson
                          size={14}
                          className="text-orange-500"
                        />
                      }
                      className="flex flex-center bg-orange-200"
                    />
                  )
                }
              </LayoutContext.Consumer>
              <div className="flex flex-col">
                <span className="font-medium text-sm text-orange-600">
                  {t(
                    'Click here to find referrer information'
                  )}
                </span>
                <span className="font-light text-xs italic text-orange-500">
                  {t(
                    'You can find a referrer or add a new one'
                  )}
                </span>
              </div>
            </div>
          )}
        </Translate>
      </div>
    )
  }

  return (
    <List.Item.Meta
      title={
        <span className="text-color-000 font-semibold leading-tight flex items-center">
          {referral_name}
        </span>
      }
      className="items-center px-2"
      avatar={
        <Avatar
          className="flex items-center justify-center background-100"
          icon={
            <IoPerson className="text-color-300" />
          }
        />
      }
      description={
        <Translate>
          {(t) => (
            <span className="text-xs text-secondary italic">
              {t('new referral')}
            </span>
          )}
        </Translate>
      }
    />
  )
}

const ReferralFormField = ({
  name,
  value,
  creatable = true,
  organization_id,
  setValues = Null,
  readOnly = false,
  onChange = Null,
  MetaHeader = Null,
  Wrapper = renderOwnChild,
}) => {
  const [current, setCurrent] =
    useState()
  return (
    <Wrapper>
      <MetaHeader />
      <GivingReferralSelector
        name="referral"
        readOnly={readOnly}
        defaultValue={value}
        renderReferral={(
          referral,
          child
        ) =>
          !_.isEmpty(referral)
            ? child
            : renderReferral(current)
        }
        Header={({
          keyword,
          onCancel = Null,
        }) => (
          <ReferralCreator
            keyword={
              creatable ? keyword : null
            }
            onCreate={(
              referral_name
            ) => {
              setCurrent(referral_name)
              setValues({
                referral_name,
                referral: undefined,
                referral_id: undefined,
                referral_type:
                  undefined,
                // email: undefined,
                // gender: undefined,
                // phone_number: undefined,
                // date_of_birth:
                //   undefined,
              })
              onCancel()
            }}
          />
        )}
        onChange={(referral) => {
          setValues({
            referral,
            referral_name: undefined,
          })
        }}
        organization_id={
          organization_id
        }
      />
    </Wrapper>
  )
}

export default compose(
  mapProps(
    ({
      name,
      value,
      form,
      onChange,
      readOnly,
      creatable,
      alias_name,
      root_owner,
      withReferral = (item) => {},
    }) => {
      const {
        values,
        setValues = Null,
      } = form || {}
      return {
        name,
        value,
        onChange,
        readOnly,
        creatable,
        organization_id:
          getId(root_owner),
        setValues: ({
          referral,
          ...rest
        }) => {
          setValues({
            ...values,
            ...rest,
            ...(withReferral(
              referral || {}
            ) || {}),
            referral,
          })
        },
      }
    }
  )
)(ReferralFormField)
