import {
  allActions,
  createTimeLineFeed, defaultDetailComponents,
  postDetailComponents,
} from '../TimeLineFeed'

const DetailPost = createTimeLineFeed(defaultDetailComponents, {
  ...allActions,
  vote: false,
  voteUp: true,
  voteDown: true,
  to: false,
  message: false,
})

export default DetailPost
