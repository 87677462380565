import EmptyHolder from 'components/EmptyHolder'
import React from 'react'

export const renderEmptyHolder = (
  withBorder = true
) =>
  withBorder ? (
    <EmptyHolder />
  ) : (
    <div className="w-full justify-center items-center">
      <EmptyHolder />
    </div>
  )
