import { schema } from 'normalizr'
import { hostSchema } from './host'

export const recruitmentEventSchema =
  new schema.Entity(
    'recruitment_events',
    {
      hosts: [hostSchema],
    },
    {
      idAttribute: 'idname',
    }
  )
