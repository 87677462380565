import {Avatar, Modal} from 'antd'
import {ImpactCategories} from 'components/Categories/CategoriesXS'
import {FollowButton} from 'components/Feed/FollowButton'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {LoginContext} from 'components/LoginContext'
import Toggle from 'components/Toggle'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState,} from 'react'
import {IoChevronDownOutline, IoChevronUpOutline, IoEllipsisHorizontalSharp,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {modalWidth} from 'views/Wishare/configs'
import CustomMenuHeader from 'views/Wishare/custom/CustomMenuHeader'
import categoryIcons from "../../../conts/categoryIcons";

const CategoryRow = ({
                       item,
                       title,
                     }) => {
  const t = useTranslate()

  const {isSm} = useContext(
    LayoutContext
  )

    const Icon = _.get(categoryIcons, item.idname);

  return (
    <div className="flex items-center gap-2 cursor-pointer">
        <div className="w-7 h-7">
            <Icon/>
        </div>
        <div className="flex-1 text-color-000 hover:text-primary font-medium capitalize max-lines-1">
            {title ? t(title) : ''}
        </div>
    </div>
  )
}

const renderCategory = (
  {language, alt_idname, ...category},
  index
) => (
  <div
    key={index}
    className="flex items-center justify-between p-2 border border-color-50 rounded-lg hover:shadow-out">
      <Link
          to={[
              getLinkToDetail(category),
              new URLSearchParams({
                  ref: alt_idname,
                  lang: language,
              }).toString(),
          ].join('?')}
          className="no-underline">
          <CategoryRow
              item={category}
              title={getTitle(category)}
          />
      </Link>
      <FollowButton
          size="small"
          item={category}
      />
  </div>
)

const DashboardCategories = () => {
  const t = useTranslate()

  const {categories = []} =
    useAppConfig()

  const appCategories =
    _.cloneDeep(categories)

  const login = useContext(LoginContext)

  const my_categories =
    _.get(login, 'categories') || []

  const [isModal, setModal] =
    useState(false)

  const merged = _.merge(
    _.keyBy(appCategories, 'id'),
    _.keyBy(my_categories, 'id')
  )
  const last_categories =
    _.values(merged)
  return (
    <div className="flex flex-col">
      <CustomMenuHeader
        onClick={() => setModal(true)}
        title="categories"
        icon={
          <IoEllipsisHorizontalSharp className="text-color-400 hover:text-primary"/>
        }
      />
      <div className="flex flex-col space-y-3">
        <Toggle defaultValue={true}>
          {(isToggle, toggle) => {
            const [more, Icon] =
              isToggle
                ? [
                  'less',
                  IoChevronUpOutline,
                ]
                : [
                  'see more',
                  IoChevronDownOutline,
                ]
            return (
              <React.Fragment>
                <ImpactCategories
                  categories={last_categories.filter(
                    ({
                       follow_status,
                     }) =>
                      Boolean(
                        isToggle ||
                        follow_status
                      )
                  )}
                />
                <div
                  onClick={toggle}
                  className="flex items-center cursor-pointer text-color-400 hover:text-color-100 space-x-3">
                  <Avatar
                    size="small"
                    style={{
                      padding: 2,
                    }}
                    icon={<Icon/>}
                    className="background-200 items-baseline justify-center"
                  />
                  <div className="text-sm font-light">
                    {t(more)}
                  </div>
                </div>
              </React.Fragment>
            )
          }}
        </Toggle>
      </div>

      <Modal
        className="custom-modal"
        footer={null}
        closable={true}
        visible={isModal}
        title={
          <div className="text-center font-bold text-color-000 uppercase">
              {t('choose category')}
          </div>
        }
        onCancel={() => setModal(false)}
        width={modalWidth.large}>
        <ModalListCategories
          categories={last_categories}
        />
      </Modal>
    </div>
  )
}

export const ModalListCategories = ({
    categories,
}) => {
  const t = useTranslate()

  const appCategories =
    _.cloneDeep(categories);
  const {
    hierarchy_categories = [],
  } = useAppConfig();

  const login = useContext(LoginContext)

  const my_categories =
    _.get(login, 'categories') || []

  const merged = _.merge(
    _.keyBy(appCategories, 'id'),
    _.keyBy(my_categories, 'id')
  )
  const last_categories =
    _.values(merged);

  return (
    <div className="flex flex-col space-y-3">
      <div className="px-3 space-y-1">
        <div className="font-bold text-color-100 leading-tight italic">
          {t('which categories are your interests?')}
        </div>
        <div className="font-light text-sm text-color-400 italic">
          {t('select categories description')}
        </div>
      </div>
      {
        hierarchy_categories.filter(e => e.idname !== 'sdg')
          .map((pc, pci) => {
            const sub_categories = _.intersectionBy(last_categories, _.get(pc, 'children'), 'id');
            return (
              <div key={pci} className="flex flex-col">
                <div className="border-b border-primary font-semibold text-primary uppercase tracking-wide">
                  {pc.name}
                </div>
                <div className="py-3 grid grid-cols-1 md:grid-cols-2 gap-3">
                  {sub_categories.map(renderCategory)}
                </div>
              </div>
            )
          })
      }
    </div>
  )
}

export default DashboardCategories
