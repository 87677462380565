import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import RecruitmentContext from "../RecruitmentContext";
import _ from "lodash";
import {getTitle} from "../../../../apis/model/base";
import {Avatar, Image} from "antd";
import {Link} from "react-router-dom";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {ROOT_URL} from "../../../../envs/_current/config";
import EndCampaign from "../../custom/EndCampaign";
import moment from "moment";

const RecruitmentBanner = React.memo(
  ({Wrapper = 'div', ...props}) => {
    const t = useTranslate()
    const {recruitment} = useContext(
      RecruitmentContext
    )
    const {isSm} = useContext(LayoutContext)
    const owner_name = _.get(recruitment, 'owner.title')
      const time_close = moment(_.get(recruitment, 'time_close'));
      const now = moment();

    return (
      <Wrapper className="flex flex-col">
        <Image
          preview={false}
          src={`${ROOT_URL}/images/default/green-bg.jpg`}
          className="fadeIn animated banner-activity object-cover bg-primary"
        />
        <div className="absolute flex items-center w-full mx-auto" style={{background: 'radial-gradient(rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0))'}}>
          <div className="flex flex-col items-center justify-center w-full banner-activity relative">
              <span className="uppercase text-sm text-green-600 font-medium tracking-wide">
                    {t('recruiting volunteers')}
                </span>
              <span className="px-3 text-xl md:text-3xl text-green-800 font-bold max-w-4xl text-center my-0 md:my-3">
                    {getTitle(recruitment)}
                </span>
              <div className="flex flex-col md:flex-row items-center justify-center space-x-3">
                    <span className="font-medium italic text-white opacity-75 text-xs">
                      {t(_.get(recruitment, 'owner._type'))} {':'}
                    </span>
                  <div className="flex items-center gap-2">
                      {!!!isSm && (
                          <Avatar
                              size="small"
                              className="flex flex-center background-100"
                              src={_.get(
                                  recruitment,
                                  'owner.avatar'
                              )}>
                                <span className="capitalize font-normal text-color-300">
                                    {_.first(owner_name)}
                                </span>
                          </Avatar>
                      )}
                      <Link
                          to={getLinkToDetail(
                              _.get(recruitment, 'owner')
                          )}
                          style={{color: '#fff'}}
                          className="flex-1 text-center text-sm md:text-lg opacity-75 hover:opacity-100 font-bold max-lines-2 no-underline">
                          {owner_name}
                      </Link>
                  </div>
              </div>
            {
              ((now > time_close) || _.get(recruitment, 'status') === 9) ? <EndCampaign /> : <span/>
            }
            </div>
          </div>
      </Wrapper>
    )
  }
)

export default RecruitmentBanner
