import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import {Image} from 'antd'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState,} from 'react'
import {FaAngleDoubleRight} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {withProps} from 'recompose'
import {paths} from 'views/MainPage/contains'
import {emptyArray} from '../../helpers/emptyObjects'
import {GoogleAdsRecommendedUnitHorizontal} from '../GoogleAds/GoogleAds'
import ImageLoader from '../ImageLoader'
import Description, {mapKeywords,} from './Description'
import {FeedContext} from './FeedCard'

export function LinkfyEmbed({
  title,
  url,
  subTitle,
  description,
  categories = [],
  image,
  style = {},
  className = '',
}) {
  return (
    <div
      style={{
        // borderRadius: '0.5em',
        ...style,
      }}
      className={classNames(
        'background-100 cursor-pointer',
        className
      )}>
      <div className="flex overflow-hidden w-full flex-wrap">
        <div
          style={{ minWidth: '200px' }}
          className="break-words flex-1 mb-4 flex-col w-full">
          {subTitle && (
            <div className="inline-flex items-start w-full">
              <img
                style={{
                  verticalAlign: 'sub',
                  margin: 0,
                  marginTop: '0.2em',
                }}
                className="inline pr-1 object-contain m-0 flex-shrink-0"
                alt={title}
                src={`https://www.google.com/s2/favicons?domain=${subTitle}`}
              />
              <span className="truncate w-0 flex-1 text-color-300 hover:underline leading-tight">
                {subTitle}
              </span>
            </div>
          )}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="font-semibold hover:underline color-primary">
            {title}
          </a>
          <div className="leading-snug">
            {description}
          </div>
          <div className="text-xs text-color-200 leading-none">
            {categories.map((e) => (
              <Link
                key={e.id}
                className="leading-none"
                to={
                  paths.categoryPath
                    .replace(
                      ':category',
                      e.id
                    )
                    .replace(
                      ':refcategory',
                      e.alt_idname
                    ) +
                  '?lang=' +
                  e.language
                }>
                {' '}
                • {e.name}
              </Link>
            ))}
          </div>
        </div>
        {!!image && (
          <ImageLoader
            style={{
              minWidth: '160px',
              objectFit: 'cover',
              flex: 1,
              // height: '100%',
              margin: '0 0 6px 6px',
              float: 'right',
            }}
            src={image}
          />
        )}
      </div>
    </div>
  )
}

export function BaseEmbed({
  title,
  description,
  keywords = [],
  categories = [],
  image,
}) {
  const { url: linkToUrl } = useContext(
    FeedContext
  )

  const LinkComponent = linkToUrl
    ? withProps({ to: linkToUrl })(Link)
    : 'a'

  return (
    <div className="background-100 p-2 rounded-lg">
      <div className="flex flex-col flex-1">
        {!!image && (
            <LinkComponent className="custom-banner-account">
              <Image
                  className="rounded object-cover mb-2"
                  alt={title}
                  src={image}
                  preview={false}
              />
            </LinkComponent>
        )}
        <LinkComponent className="font-semibold hover:underline color-primary text-lg leading-6">
          {title}
        </LinkComponent>
        <Description
            className="leading-snug"
            ghostbav>
          {mapKeywords(
              description,
              keywords
          )}
        </Description>
        <div className="text-xs leading-none">
          {categories.map((e) => (
              <Link
                  key={e.id}
                  className="leading-none"
                  to={
                    paths.categoryPath
                        .replace(
                            ':category',
                            e.id
                        )
                        .replace(
                            ':refcategory',
                            e.alt_idname
                        ) +
                    '?lang=' +
                    e.language
                  }>
                {' '}
                • {e.name}
              </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export function DetailEmbed({
  id,
  title,
  url,
  subTitle,
  description,
  categories = [],
  image,
  content,
  keywords = [],
  others = [],
}) {
  const t = useTranslate()
  const [
    toggleLinkView,
    setToggleLinkView,
  ] = useState(false)
  const _renderContentItem = (
    it,
    i
  ) => {
    switch (_.get(it, 'type', 'p')) {
      case 'img':
        return !_.has(
          it,
          'url'
        ) ? null : (
          <div
            className="py-4 w-full"
            key={i}>
            <img
              className="w-full object-cover bg-no-repeat"
              src={_.get(it, 'url')}
              alt={_.get(it, 'alt', '')}
            />
            {_.has(it, 'value') && (
              <div className="flex text-color-300 text-base italic">
                {_.get(it, 'value')}
              </div>
            )}
          </div>
        )
      case 'html':
        return !_.has(
          it,
          'value'
        ) ? null : (
          <div
            className="py-2 w-full flex"
            key={i}>
            <div
              className="flex-1 fix-table w-0 overflow-auto"
              dangerouslySetInnerHTML={{
                __html: _.get(
                  it,
                  'value'
                ),
              }}
              key={i}></div>
          </div>
        )
      case 'h':
        return !_.has(
          it,
          'value'
        ) ? null : (
          <Description
            className="py-2 font-semibold"
            ghostbav
            key={i}>
            {mapKeywords(
              _.get(it, 'value', ''),
              keywords
            )}
          </Description>
        )
      default:
        return !_.has(
          it,
          'value'
        ) ? null : (
          <Description
            className="py-2"
            ghostbav
            key={i}>
            {mapKeywords(
              _.get(it, 'value', ''),
              keywords
            )}
          </Description>
        )
    }
  }

  return (
    <div>
      <div className="md:border md:background-200 md:border-gray-300 rounded-md md:p-3">
        <div className="flex overflow-hidden">
          <div className="break-words flex-1 flex-col verticalList__small">
            <h1 className="font-semibold color-primary md:text-lg text-2xl leading-snug">
              {title}
            </h1>
            <div className="leading-snug md:text-base text-xl my-3">
              {description}
            </div>
            <div className="text-xs leading-none">
              {categories.map((e) => (
                <Link
                  key={e.id}
                  className="leading-none"
                  to={
                    paths.categoryPath
                      .replace(
                        ':category',
                        e.id
                      )
                      .replace(
                        ':refcategory',
                        e.alt_idname
                      ) +
                    '?lang=' +
                    e.language
                  }>
                  {' '}
                  • {e.name}
                </Link>
              ))}
            </div>
            {!!image &&
              !!_.isEmpty(content) && (
                <div
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    margin: 'auto',
                    position:
                      'relative',
                    display:
                      'inline-block',
                  }}>
                  <ImageLoader
                    alt={
                      (
                        keywords ||
                        emptyArray
                      ).reduce(
                        function (
                          r,
                          e
                        ) {
                          r +=
                            e.keyword +
                            ', '
                          return r
                        },
                        ''
                      ) + 'by Wishare'
                    }
                    className="object-contain w-full h-full"
                    style={{
                      verticalAlign:
                        'middle',
                    }}
                    src={image}
                  />
                </div>
              )}
          </div>
        </div>
        <React.Fragment>
          {others
            .slice(0, 2)
            .map((e, i) => (
              <h3 key={i}>
                <Link
                  key={i}
                  to={{
                    pathname: `/news/${e._type}/${e.id}`,
                  }}
                  className="p-2 flex items-start justify-start text-color-300 background-100 leading-5 md:text-base text-lg">
                  <span className="px-2">
                    <FaAngleDoubleRight />
                  </span>
                  {_.truncate(
                    _.get(
                      e,
                      'overview.title'
                    ),
                    {
                      length: 160,
                    }
                  )}
                </Link>
              </h3>
            ))}
        </React.Fragment>

        {!_.every(
          Array.from(content || []),
          (item) =>
            _.isEmpty(
              _.get(item, 'value')
            )
        ) && (
          <div>
            <div className="whitespace-pre-wrap p-3 background-100 leading-7 md:text-base text-xl w-full">
              {content.map((it, i) => {
                return _renderContentItem(
                  it,
                  i
                )
              })}
            </div>
          </div>
        )}
      </div>

      <div className="flex my-3">
        <div className="flex-1" />
        {
          !!url && (
            <div className="flex px-3 background-100 border rounded-full justify-end items-center">
              <div className="italic text-xs text-color-300 px-2 whitespace-pre">
                {t('source')}:
              </div>
              <div className="font-bold text-sm max-lines-1">
                {subTitle}
              </div>
              {!toggleLinkView && (
                <div
                  onClick={() => {
                    copyToClipboard(url)
                    setToggleLinkView(true)
                  }}
                  className="px-2 cursor-pointer italic text-sm">
                  <QuestionCircleOutlined/>
                </div>
              )}
            </div>
          )
        }
      </div>

      {toggleLinkView && !!url && (
        <div className="whitespace-normal my-3 text-color-300 text-sm italic">
          {t('source')}
          {': '}
          <span className="hover:underline">
            {url}
          </span>
        </div>
      )}

      {!_.isEmpty(content) && (
        <GoogleAdsRecommendedUnitHorizontal />
      )}
    </div>
  )
}

export const Keywords = ({keywords}) => {

  const t = useTranslate()

  return (!!keywords && !!keywords.length - 1 > 0) ? (
    <div id="keywords">
      <span className="text-sm text-color-300 font-medium italic">
        {t('keywords')}{' : '}
      </span>
      {keywords.map((k, i) => (
        <React.Fragment key={i}>
          <Link
            className="font-bold color-primary hover:underline"
            to={`/t/${k.idname}`}>
            {k.keyword}
          </Link>
          {i < keywords.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </div>
  ) : <span />
}
