import {
  Avatar,
  Button,
  Card,
  Input,
  Modal,
  Select,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import {
  time_sheet_addTimeSheets_Api,
  time_sheet_deleteTimeSheets_Api,
  time_sheet_editTimeSheets_Api,
  time_sheet_getTimeSheets_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { createValue } from 'components/form/utils'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import {
  compose,
  mapProps,
  nest,
} from 'recompose'
import { renderEmptyHolder } from 'views/MainPage/functions/renderEmptyHolder'
import NoFoundPage from 'views/NoFoundPage'
import {
  getResponseItem,
  Null,
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import {
  ObserverContext,
  ObserverProvider,
} from 'views/Shared/components/ObservedList'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  requiredNumber,
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import OrganizationContainerContext from 'views/Wishare/Organization/OrganizationContainerContext'
import wishareModel from 'views/Wishare/wishareModel'
import WorkplaceSelector, {
  WorkplaceDisplayTypes,
} from './WorkplaceSelector'

export const TimesheetPeriodOptions =
  Object.freeze({
    H: {
      value: 'H',
      label: 'hour',
    },
    D: {
      value: 'D',
      label: 'day',
    },
    M: {
      value: 'M',
      label: 'month',
    },
    Y: {
      value: 'Y',
      label: 'year',
    },
  })

export const UnitInputGroup = ({
  name,
  value,
  subName,
  subValue,
  options = [],
  onChange = Null,
  inputProps = {},
  selectProps = {},
  reversed = false,
  placeholder = 'Select...',
}) => {
  const t = useTranslate()

  const filterOption = (
    input,
    { children }
  ) => {
    const left = children.toLowerCase()
    const right = input.toLowerCase()
    return left.indexOf(right) >= 0
  }
  return (
    <Input.Group
      compact={true}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {!!!reversed && (
        <Input
          name={name}
          type="number"
          value={
            value ? Number(value) : 0
          }
          // style={{ minHeight: 34 }}
          onChange={(event) => {
            onChange(
              createValue(
                name,
                event.target.value
              )
            )
          }}
          {...inputProps}
        />
      )}
      <SelectWidget
        {...{
          name,
          filterOption,
          value: subValue,
          showSearch: true,
          notFoundContent: t('empty'),
          onChange: (value) =>
            onChange(
              createValue(
                subName,
                value
              )
            ),
          className: 'w-full custom-select-modal',
          optionFilterProp: 'children',
          children: options.map(
            (
              { label, value },
              index
            ) => (
              <Select.Option
                className="capitalize"
                key={index}
                value={value}>
                {t(label)}
              </Select.Option>
            )
          ),
          placeholder: placeholder
            ? t(placeholder)
            : undefined,
        }}
        {...selectProps}
      />
      {!!reversed && (
        <Input
          name={name}
          type="number"
          value={
            value ? Number(value) : 0
          }
          // style={{ minHeight: 34 }}
          onChange={(event) => {
            onChange(
              createValue(
                name,
                event.target.value
              )
            )
          }}
          {...inputProps}
        />
      )}
    </Input.Group>
  )
}

const formFields = [
  {
    name: 'title',
  },
  {
    name: 'description',
  },
  {
    name: 'workplace',
  },
  {
    name: 'workplace_name',
  },
  {
    name: 'from_date',
  },
  {
    name: 'to_date',
  },
  {
    name: 'duration',
  },
  {
    name: 'duration_unit_name',
  },
]

const formSchema = [
  {
    title: 'date',
    children: [
      {
        name: 'from_date',
        component:
          createDateRangerPicker([
            'from_date',
            'to_date',
          ]),
      },
    ],
  },
  {
    title: 'workplace',
    children: [
      {
        name: 'default_workplace',
        component:
          createControlledFormField({
            Component:
              WorkplaceSelector,
            withProps: ({ value }) => ({
              name: 'workplace',
              defaultValue: value,
              type: !!_.get(
                value,
                'workplace_id'
              )
                ? WorkplaceDisplayTypes
                    .OBJECT.value
                : WorkplaceDisplayTypes
                    .TEXT.value,
            }),
            withForm: ({
              values = {},
              setValues = Null,
            }) => ({
              setValues: (
                changedValues
              ) => {
                setValues({
                  ...values,
                  ...changedValues,
                })
              },
              query: bindQueryParams([
                {
                  id: getId(
                    values.container
                  ),
                },
                {
                  type: getType(
                    values.container
                  ),
                },
              ]),
              workplace: {
                workplace_id:
                  values.workplace_id,
                workplace_type:
                  values.workplace_type,
                workplace_name:
                  values.workplace_name,
              },
            }),
          }),
      },
    ],
  },
  {
    title: 'position',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            Component: Input,
            placeholder: 'position',
          }),
      },
    ],
  },
  {
    title: 'job description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            Component: TextArea,
            placeholder:
              'job description',
            rows: 3,
          }),
      },
    ],
  },
  {
    title: 'duration',
    children: [
      {
        name: 'duration',
        component: compose(
          mapProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => ({
              name,
              value,
              onChange,
              subName:
                'duration_unit_name',
              subValue: _.get(
                form,
                'values.duration_unit_name'
              ),
              options: Object.values(
                TimesheetPeriodOptions
              ),
            })
          )
        )(UnitInputGroup),
      },
    ],
  },
]

const ManageTimeSheetContent = ({
  query,
  values,
  apiInfo,
  onSuccess,
  initialValues,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const t = useTranslate()

  const { organization } = useContext(
    OrganizationContainerContext
  )

  const validationSchema = yupShape({
    duration: requiredNumber(t),
    duration_unit_name:
      requiredString(t),
  })

  const validate = (values) => {
    let errors = {}

    const {
      workplace_id,
      workplace_name,
      duration_unit_name,
    } = values || {}

    if (
      _.every(
        [workplace_id, workplace_name],
        _.isEmpty
      )
    ) {
      errors.default_workplace = t(
        'required field'
      )
    }

    if (_.isEmpty(duration_unit_name)) {
      errors.duration = t(
        'required field'
      )
    }

    return errors
  }

  const body = createEditableForm(
    formFields,
    formSchema
  )({
    query,
    values,
    apiInfo,
    onSuccess,
    validate,
    validationSchema,
    item: initialValues,
    onError: notifyOnError(t),
    initialized: ({
      workplace,
      workplace_name,
      ...values
    }) => {
      const default_workplace = {
        workplace_name,
        workplace_id: getId(workplace),
        workplace_type:
          getType(workplace),
      }
      return {
        default_workplace,
        container: _.pick(
          organization,
          ['id', '_type', 'idname']
        ),
        ...values,
        ...default_workplace,
      }
    },
    onPreSubmit: ({
      container,
      default_workplace,
      ...values
    }) => ({
      ...values,
    }),
  })

  return <Wrapper>{body}</Wrapper>
}

const AddTimeSheetModal = ({
  member_id,
  onCreated,
}) => {
  if (member_id) {
    return (
      <ManageTimeSheetContent
        onSuccess={onCreated}
        apiInfo={
          time_sheet_addTimeSheets_Api
        }
        initialValues={{}}
        query={bindQueryParam({
          member_id,
        })}
      />
    )
  } else {
    return <NoFoundPage />
  }
}

const UpdateModalTrigger = ({
  timesheet,
  member_id,
}) => {
  const t = useTranslate()

  const id = getId(timesheet)

  const [isToggle, toggle] = useToggle()

  const onSuccess = () => {
    toggle(false)
  }

  if (_.some([member_id], _.isEmpty)) {
    return <NoFoundPage />
  }
  return (
    <div>
      <Button
        ghost
        size="small"
        type="primary"
        className="rounded-md no-shadow no-text-shadow text-sm"
        onClick={toggle}>
        {t('edit')}
      </Button>
      <Modal
        footer={null}
        visible={isToggle}
        destroyOnClose={true}
        title={t('edit timesheet')}
        onCancel={() => toggle(false)}>
        <ManageTimeSheetContent
          apiInfo={
            time_sheet_editTimeSheets_Api
          }
          onSuccess={onSuccess}
          initialValues={timesheet}
          query={bindQueryParams([
            { id },
            {
              member_id,
            },
          ])}
        />
      </Modal>
    </div>
  )
}

export const TimesheetMeta = ({
  title,
  duration,
  workplace,
  workplace_name,
  duration_unit_name,
}) => {
  const t = useTranslate()

  const _duration =
    Number(duration).toString()

  const { label: unit_name = '' } =
    _.get(
      TimesheetPeriodOptions,
      duration_unit_name
    ) || {}

  const displayName =
    workplace_name ||
    getTitle(workplace)

  const ItemTimeSheet = ({
    label,
    value,
  }) => (
    <div className="flex items-center space-x-2">
      <span className="text-xs text-color-500 italic">
        {[label, ':'].join('')}
      </span>
      <span className="text-sm font-medium text-color-100 flex-1">
        {value}
      </span>
    </div>
  )

  return (
    <div className="space-y-1">
      <ItemTimeSheet
        label={t('project')}
        value={
          <Link
            to={wishareModel.getLinkToDetail(
              workplace
            )}>
            {displayName}
          </Link>
        }
      />
      <ItemTimeSheet
        label={t('position')}
        value={title}
      />
      {!_.some(
        [_duration, unit_name],
        _.isEmpty
      ) && (
        <ItemTimeSheet
          label={t('work time')}
          value={[
            _duration,
            t(unit_name),
          ].join(' ')}
        />
      )}
    </div>
  )
}

const renderItem =
  ({ member_id, onDelete = Null }) =>
  (item) => {
    const isDeleted = _.some(
      ['isDeleted', 'deleted'].map(
        (e) => _.get(item, e)
      ),
      Boolean
    )

    if (isDeleted) {
      return null
    }
    return (
      <Translate>
        {(t) => (
          <Card size="small">
            <div className="flex items-center space-x-2 font-semibold text-color-000 mb-2">
              <IoCalendarOutline className="text-color-400" />
              <span className="text-xs md:text-sm">
                {t('from date')}{' '}
                {_.get(
                  item,
                  'from_date'
                )}
              </span>
              <span className="text-xs md:text-sm">
                {[
                  t('to date'),
                  ' ',
                ].join('')}
                {_.get(item, 'to_date')}
              </span>
            </div>
            <TimesheetMeta
              {..._.pick(item, [
                'title',
                'duration',
                'workplace',
                'workplace_name',
                'duration_unit_name',
              ])}
            />
            <div className="flex items-center gap-2 mt-1 justify-end">
              <Button
                ghost
                danger
                type="primary"
                size="small"
                className="rounded-md no-shadow no-text-shadow text-sm"
                onClick={() =>
                  onDelete(getId(item))
                }>
                {t('delete')}
              </Button>
              <UpdateModalTrigger
                member_id={member_id}
                timesheet={item}
              />
            </div>
          </Card>
        )}
      </Translate>
    )
  }

const UserInformation = ({
  user,
  ...props
}) => {
  return renderIf(
    !!user,
    <div className="mb-5 mt-2 ml-3">
      <div className="flex items-center gap-3">
        <Avatar
          src={getAvatar(user)}
          className="border"
        />
        <div className="flex flex-col flex-1">
          <Link
            to={getLinkToDetail(user)}
            className="text-primary font-bold">
            {_.get(user, 'name')}
          </Link>
          <span className="text-xs text-color-500 font-light italic">
            {_.get(user, 'email')}
          </span>
        </div>
      </div>
    </div>
  )
}

export const ManageTimeSheetModal = ({
  item,
  onCancel = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [member_id, type] = [
    getId(item),
    getType(item),
  ]

  const {
    addPost = Null,
    removePost = Null,
    posts,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: deleteTimesheet,
  } = useAsyncAction({
    query: bindQueryParam({
      member_id,
    }),
    apiInfo:
      time_sheet_deleteTimeSheets_Api,
    onError: notifyOnError(t),
    onSuccess: (
      result,
      { response }
    ) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      if (result) {
        removePost(result)
      }
    },
  })

  const [isToggle, toggle] = useToggle()

  const showConfirmDialog = (onOk) => {
    Modal.confirm({
      title: t(
        'delete this information'
      ),
      content: (
        <div className="text-sm font-light italic text-color-300">
          {t(
            'are you sure you want to delete this working information'
          )}
        </div>
      ),
      okText: t('sure'),
      cancelText: t('no'),
      okButtonProps: {
        type: 'primary',
        danger: true,
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      cancelButtonProps: {
        ghost: 'true',
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      onOk,
    })
  }

  const onDelete = (id) => {
    showConfirmDialog(() => {
      if (id) {
        deleteTimesheet(
          {},
          bindQueryParam({ id })
        )
      }
    })
  }

  if (_.isEmpty(item)) return null

  return (
    <Wrapper>
      <Modal
        footer={null}
        visible={isToggle}
        destroyOnClose={true}
        title={
          <span className="font-bold">
            {t('add new')}
          </span>
        }
        onCancel={() => toggle(false)}>
        <AddTimeSheetModal
          onCreated={([
            result,
            { response },
          ]) => {
            const newItem =
              getResponseItem(response)
            if (newItem) {
              addPost(newItem)
            }
            toggle(false)
          }}
          member_id={member_id}
        />
      </Modal>
      <div className="uppercase text-color-300 font-medium">
        {t('member information')}
      </div>
      <UserInformation
        user={_.get(item, 'user')}
      />
      <div className="uppercase text-color-300 font-medium">
        {t('working time')}
      </div>
      <div className="text-right">
        <Button
          type="primary"
          className="no-shadow no-text-shadow rounded-lg"
          onClick={toggle}>
          {t('add new')}
        </Button>
      </div>
      <div className="py-3 space-y-2 flex-1">
        {Array.from(posts || []).map(
          (item, index) => (
            <React.Fragment key={index}>
              {renderItem({
                member_id,
                onDelete,
              })(item)}
            </React.Fragment>
          )
        )}
        <LazyPagination
          query={bindQueryParam({
            member_id,
          })}
          apiInfo={
            time_sheet_getTimeSheets_Api
          }
          renderItem={renderItem({
            member_id,
            onDelete,
          })}
          renderEmpty={
            renderEmptyHolder
          }
        />
      </div>
      <div className="text-right p-3">
        <Button
          className="rounded-lg no-shadow no-text-shadow border-none"
          onClick={onCancel}>
          {t('close')}
        </Button>
      </div>
    </Wrapper>
  )
}

export default nest(
  ObserverProvider,
  ManageTimeSheetModal
)
