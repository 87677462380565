import {
  givingEventSchema,
  givingSchema,
} from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const GivingEntity = ({
  item,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={givingEventSchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const GivingEntities = ({
  items,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={items}
    defaultItem={defaultItem}
    schema={[givingEventSchema]}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export const GivingItemEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={givingSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const GivingItemEntities = ({
  items,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={items}
    defaultItem={defaultItem}
    schema={[givingSchema]}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export default GivingEntity
