import { API_ROOT_URL } from 'envs/_current/config'
import { baseItemSchema } from './schema'
import { createListTransform } from './utils/createListTransform'

// API lấy danh sách các conversations: Route::get('messages/conversation/gets', 'ConversationController@getConversations');

export const create_message_getConversations_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/conversation/gets',
    method: 'GET',
    fields: [],
    paramsFields: [
      'organization_id',
      'title',
    ],
    fieldTypes: {},
    schema: baseItemSchema,
    transform: createListTransform(
      baseItemSchema
    ),
    ...props,
  })

// API lấy danh sách các message gần nhất của 1 conversation: Route::get('messages/conversation/{conversation_id}/get-messages', 'MessageController@getMessages');
export const create_message_getMessages_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/conversation/:conversation_id/get-messages',
    method: 'GET',
    fields: [],
    paramsFields: [],
    fieldTypes: {},
    transform: createListTransform(
      baseItemSchema
    ),
    ...props,
  })

// API tạo 1 conversations: Route::post('messages/conversation/create', 'ConversationController@createConversation');
// Title: optional
// Receiver_id: user_id
// Organization_id: (optional) thêm để lọc theo workspace về sau.

export const create_message_createConversation_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/conversation/create',
    method: 'POST',
    fields: [
      'title',
      'receiver_id',
      'organization_id',
    ],
    paramsFields: [],
    fieldTypes: {},
    transform: createListTransform(
      baseItemSchema
    ),
    ...props,
  })

// Truyền lên: message (string)
// Route::post('messages/organization/{organization_id}/send-request', 'MessageController@sendRequestToOrg');
export const create_message_sendRequestToOrg_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/organization/:organization_id/send-request',
    method: 'POST',
    fields: ['message'],
    paramsFields: [],
    fieldTypes: {},
    transform: createListTransform(
      baseItemSchema
    ),
    ...props,
  })

// Route::post('messages/templates/create', 'MessageTemplateController@create'); // truyền lên: message, meta (optional)
export const create_message_MessageTemplateController_create_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/templates/create',
    method: 'POST',
    paramsFields: [],
    fieldTypes: { message: '' },
    ...props,
  })
// Route::post('messages/templates/{template_id}/edit', 'MessageTemplateController@update'); // truyền lên: message, meta (optional)
// Route::post('messages/templates/{template_id}/delete', 'MessageTemplateController@delete');
export const create_message_MessageTemplateController_delete_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/templates/:template_id/delete',
    method: 'POST',
    paramsFields: [],
    fieldTypes: { message: '' },
    ...props,
  })
// // message_templates = []; truyền trả về trong
// Route::get('messages/templates/gets', 'MessageTemplateController@gets');
export const create_message_MessageTemplateController_Api =
  (props) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/templates/gets',
    method: 'GET',
    paramsFields: [],
    fieldTypes: {},
    ...props,
  })

export const message_createConversation_Api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/messages/conversation/create',
    method: 'POST',
    fields: [
      'title',
      'receiver_id',
      'organization_id',
    ],
  }
