import { Avatar, Tag } from 'antd'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {
  useCallback,
} from 'react'
import { BsFolder } from 'react-icons/bs'
import { FaRegAddressCard } from 'react-icons/fa'
import {
  Null,
  renderIf,
  renderMail,
  renderPhone,
  renderSelf,
} from 'views/Shared'
import AppPlaceHolderSvg from '../../../../components/Svgs/AppPlaceHolderSvg'
import CustomLink from '../../custom/CustomLink'

const ItemInfo = ({ label, value }) =>
  renderIf(
    Boolean(value),
    <Translate>
      {(t) => (
        <div className="flex items-start gap-1 text-xs leading-tight">
          <span className="font-light italic text-color-400 ">
            {[t(label), ':'].join('')}
          </span>
          <span className="font-medium text-color-100 flex-1">
            {value}
          </span>
        </div>
      )}
    </Translate>
  )

export const renderPartner =
  ({
    showInfo = true,
    renderContextMenu = Null,
  } = {}) =>
  (item, index) => {
    const {
      last_name,
      first_name,
      partner_name,
      partner_organization,
    } = item || {}

    const categories = _.get(
      partner_organization,
      'categories',
      []
    )

    const subcategories = _.get(
      partner_organization,
      'subcategories',
      []
    )

    const renderPartnerName =
      useCallback(() => {
        if (
          _.isEmpty(
            partner_organization ||
              undefined
          )
        ) {
          return (
            <div className="flex-1 text-color-100 font-bold max-lines-2">
              {partner_name}
            </div>
          )
        }
        return (
          <CustomLink
            withSubDomain={false}
            to={getLinkToDetail(
              partner_organization
            )}
            className="flex-1 text-color-org font-bold max-lines-1 no-underline leading-tight">
            {getTitle(
              partner_organization
            )}
          </CustomLink>
        )
      }, [
        partner_name,
        partner_organization,
      ])

    const hasInfo =
      !!last_name ||
      !!first_name ||
      !!_.get(item, 'phone') ||
      !!_.get(item, 'email') ||
      !!_.get(item, 'address')

    return (
      <Translate key={index}>
        {(t) => (
          <div className="flex flex-col gap-1 p-2 border border-color-50 rounded-lg hover:shadow-items relative">
            <div className="flex items-center gap-2">
              {partner_organization ? (
                <div className="flex items-center gap-2">
                  <Avatar
                    className="flex flex-center rounded-lg background-100 border border-color-50"
                    icon={
                      <AppPlaceHolderSvg
                        size={20}
                        className="text-color-500"
                      />
                    }
                    size={40}
                    src={getAvatar(
                      partner_organization
                    )}
                  />
                  <div className="flex flex-col gap-1">
                    <React.Fragment>
                      {renderPartnerName()}
                    </React.Fragment>
                    {/*<OrgType item={partner_organization} className="font-medium text-2xs text-secondary italic"/>*/}
                    {_.get(
                      partner_organization,
                      'total_activities'
                    ) > 0 && (
                      <span className="font-light text-2xs text-color-400 italic leading-tight">
                        <span className="font-bold text-xs text-color-100 mr-1">
                          {_.get(
                            partner_organization,
                            'total_activities'
                          )}
                        </span>
                        {t(
                          'activities'
                        )}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-2 text-color-100">
                  <Avatar
                    className="flex flex-center rounded-lg background-100 border border-color-50"
                    icon={
                      <FaRegAddressCard
                        size={20}
                        className="text-color-500"
                      />
                    }
                    size={40}
                  />
                  {renderPartnerName()}
                </div>
              )}
            </div>
            {categories.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {categories
                  .slice(0, 2)
                  .map((e, i) => (
                    <Tag
                      key={i}
                      color="processing"
                      icon={
                        <Avatar
                          size={13}
                          src={e.avatar}
                        />
                      }
                      style={{
                        paddingTop:
                          '0.2rem',
                        paddingBottom:
                          '0.2rem',
                      }}
                      className="flex items-center gap-1 text-xs italic mr-0 leading-tight">
                      {e.name}
                    </Tag>
                  ))}
              </div>
            )}
            {subcategories.length >
              0 && (
              <div className="flex flex-col gap-1">
                {/*<span className="text-xs text-color-400 italic leading-tight">*/}
                {/*    {t('subcategories')}{':'}*/}
                {/*</span>*/}
                <div className="flex flex-wrap gap-1">
                  {subcategories
                    .slice(0, 2)
                    .map((e, i) => (
                      <Tag
                        key={i}
                        color="magenta"
                        icon={
                          <BsFolder />
                        }
                        className="custom-tag-subcat flex flex-center gap-1 text-2xs italic mr-0 leading-tight">
                        {e.title}
                      </Tag>
                    ))}
                </div>
              </div>
            )}
            {showInfo && (
              <div className="mt-2">
                {!!hasInfo && (
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-xs text-color-400 italic uppercase underline">
                      {t(
                        'contact information'
                      )}
                      {' : '}
                    </span>
                    <div className="grid grid-cols-1 gap-1">
                      {[
                        {
                          name: 'prefix_title',
                          label: 'name',
                          is_visible:
                            !!first_name ||
                            !!last_name,
                          transform: (
                            value
                          ) => {
                            const array =
                              [
                                _.isString(
                                  value
                                )
                                  ? t(
                                      value
                                    )
                                  : value,
                                first_name,
                                last_name,
                              ]
                            return _.every(
                              array,
                              _.isEmpty
                            )
                              ? null
                              : array.join(
                                  ' '
                                )
                          },
                        },
                        {
                          name: 'phone',
                          label:
                            'phone',
                          is_visible:
                            !!_.get(
                              item,
                              'phone'
                            ),
                          transform:
                            renderPhone,
                        },
                        {
                          name: 'email',
                          label:
                            'email',
                          is_visible:
                            !!_.get(
                              item,
                              'email'
                            ),
                          transform:
                            renderMail,
                        },
                        {
                          name: 'address',
                          is_visible:
                            !!_.get(
                              item,
                              'address'
                            ),
                          label:
                            'address',
                        },
                      ]
                        .filter(
                          (e) =>
                            !!e.is_visible
                        )
                        .map(
                          (
                            {
                              name,
                              label,
                              transform = renderSelf,
                            },
                            index
                          ) => (
                            <ItemInfo
                              key={
                                index
                              }
                              label={
                                label
                              }
                              value={transform(
                                _.get(
                                  item,
                                  name
                                )
                              )}
                            />
                          )
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {renderContextMenu(item)}
          </div>
        )}
      </Translate>
    )
  }
