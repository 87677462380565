import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import UserContext from '../UserContext'

const UserSettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.userSettings
  return createSettingContent(
    UserContext,
    { routes, Wrapper, rootPath }
  )
}

export default UserSettingsContent
