import { Modal } from 'antd'
import Translate from 'modules/local/Translate'
import React, { useState } from 'react'
import { modalWidth } from './configs'

export const ModalContext =
  React.createContext({})

export const ModalProvider = ({
  item,
  children,
  ...props
}) => {
  const [current, setCurrent] =
    useState()

  return (
    <ModalContext.Provider
      value={{
        item,
        current,
        setCurrent,
        ...props,
      }}>
      {children}
    </ModalContext.Provider>
  )
}

export const WishareModal = ({
  title,
  visible,
  children,
  footer = null,
  centered = true,
  closable = true,
  width = modalWidth.large,
  ...props
}) => (
  <Translate>
    {(t) => (
      <Modal
        className="custom-modal"
        title={title}
        width={width}
        footer={footer}
        visible={visible}
        centered={centered}
        closable={closable}
        destroyOnClose={true}
        {...props}>
        {children}
      </Modal>
    )}
  </Translate>
)

export default ModalContext
