import { RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, {
  useContext,
} from 'react'
import { VscArrowLeft } from 'react-icons/vsc'
import useToggle from 'react-use/lib/useToggle'
import ImageLoader from '../ImageLoader'
import ImagesLightBox from '../ImagesLightBox/ImagesLightBox'
import { LayoutContext } from '../layouts/Default/LayoutContext'
import { ImageEmbed } from './ImageEmbed'

export function GalleryEmbed({
  images,
}) {
  const [open, toggle] = useToggle()
  const { isSm } = useContext(
    LayoutContext
  )
  if (!images || images.length === 0)
    return null
  if (images.length === 1)
    return (
      <ImageEmbed image={images[0]} />
    )
  return (
    <>
      <div
        onClick={toggle}
        style={{
          // paddingTop: '70%'
          //maxHeight: '70vh',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
        className="relative  w-full background-200 rounded-md overflow-hidden">
        <ImageLoader
          style={{
            // position: 'absolute',
            // top: 0,
            // left: 0,
            height: isSm
              ? '7em'
              : '15em',
            // height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
          rootClassName="w-full"
          className=" w-full rounded-md"
          src={images[0]}
        />
        <Button.Group
          size="small"
          className="z-10 absolute bottom-0 right-0 p-4">
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)',
            }}
            icon={<VscArrowLeft />}
          />
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)',
            }}>
            <span
              style={{
                fontWeight: 500,
              }}>
              1-{images.length} images
            </span>
          </Button>
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)',
            }}
            icon={<RightOutlined />}
          />
        </Button.Group>
      </div>
      {open && (
        <ImagesLightBox
          images={images}
          onCloseRequest={toggle}
        />
      )}
    </>
  )
}
