import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import { datasetSchema } from './dataset'
import {
  activitySchema,
  channelSchema,
  donationEventSchema,
  givingEventSchema,
  organizationSchema,
  recruitmentEventSchema,
  userSchema,
  beneficiarySchema,
} from './schema'
import { createTransform } from './utils/createTransform'

const followSchema = new schema.Union(
  {
    beneficiary: beneficiarySchema,
    organization: organizationSchema,
    channel: channelSchema,
    user: userSchema,
    dataset: datasetSchema,
    activity: activitySchema,
    giving_event: givingEventSchema,
    donation_event: donationEventSchema,
    recruitment_event:
      recruitmentEventSchema,
  },
  '_type'
)

const fields = [
  'owner_type',
  'owner_id',
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: [
    'user',
    'organization',
    'channel',
  ],
}
// Route::post('auth/following/add', 'FollowController@add');
export const follow_add_Api = {
  backend: ` Route::post('auth/following/add', 'FollowController@add');`,
  root: API_ROOT_URL,
  path: '/auth/following/add',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    followSchema,
    'data'
  ),
}
export const create_follow_add_Api_action =

    () =>
    ({
      prefixStr = 'follow_add_Api',
      query,
      values,
      maxAge = -1,
    }) =>
      createAsyncAction({
        prefixStr:
          prefixStr + '@follow_add_Api',
        query,
        values,
        apiInfo: follow_add_Api,
        maxAge,
      })

// Route::post('auth/following/remove', 'FollowController@remove');
export const follow_remove_Api = {
  backend: `Route::post('auth/following/remove', 'FollowController@remove')`,
  root: API_ROOT_URL,
  path: '/auth/following/remove',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    followSchema,
    'data'
  ),
}
export const create_follow_remove_Api_action =

    () =>
    ({
      prefixStr = 'follow_remove_Api',
      query,
      values,
      maxAge = -1,
    }) =>
      createAsyncAction({
        prefixStr:
          prefixStr +
          '@follow_remove_Api',
        query,
        values,
        apiInfo: follow_remove_Api,
        maxAge,
      })
