import { Avatar } from 'antd'
import { getType } from 'apis/model/base'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
} from 'react'
import { ImLibrary } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { LayoutContext } from '../../../../components/layouts/Default/LayoutContext'
import getLinkToDetail from '../../../../helpers/getLinkToDetail'
import WorkspaceContext from '../WorkspaceContext'

const WorkspaceBanner = ({
  Wrapper = 'div',
}) => {
  const { organization } = useContext(
    WorkspaceContext
  )

  const { isSm } = useContext(
    LayoutContext
  )

  if (_.isEmpty(organization)) {
    return null
  }

  const avatar = _.get(
    organization,
    'avatar'
  )

  return (
    <Wrapper className="flex items-center container mx-auto mt-3 gap-3 px-4">
      {avatar ? (
        <Avatar
          shape="square"
          size={40}
          src={avatar}
          className="object-contain w-full h-full rounded-lg"
        />
      ) : (
        <ImLibrary
          size={40}
          className="p-2 border border-color-50 rounded-lg text-color-org"
        />
      )}

      <Translate>
        {(t) => (
          <div className="flex flex-col">
            <Link
              to={getLinkToDetail(
                organization
              )}
              className="font-semibold text-color-org max-lines-1 leading-tight">
              {getTitle(organization)}
            </Link>
            <span className="italic font-light text-secondary text-xs">
              {t(getType(organization))}
            </span>
          </div>
        )}
      </Translate>
    </Wrapper>
  )
}

export default WorkspaceBanner
