import {
  Avatar,
  Button,
  Input,
  List,
  Modal,
  Result,
} from 'antd'
import { auth_getUserProfile_Api } from 'apis'
import { getId } from 'apis/model/base'
import { recruitmentEventSchema } from 'apis/schema'
import { candidateSchema } from 'apis/schema/candidateSchema'
import FormActionBar from 'components/form/FormActionBar'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import Pure from 'components/Pure'
import {
  FormikConsumer,
  useField,
} from 'formik'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Redirect } from 'react-router-dom'
import { useToggle } from 'react-use'
import {
  compose,
  mapProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import LoadingPage from 'views/LoadingPage'
import { paths } from 'views/MainPage/contains'
import {
  fromObject,
  getResponseItem,
  Null,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { recruitmentApi } from 'views/Wishare/apis'
import {
  modalWidth,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { MaterialTypes } from 'views/Wishare/enums'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import WishareAlert from 'views/Wishare/WishareAlert'
import RecruitmentCandidateProfile from '../components/RecruitmentCandidateProfile'
import RecruitmentCandidateSelector from '../components/RecruitmentCandidateSelector'
import { RecruitmentItemContextActions } from '../components/RecruitmentRecordItem'
import { RecruitmentRequiredFormFields } from '../components/RecruitmentRequiredFields'
import RecruitmentContext from '../RecruitmentContext'
import RecruitmentNotFound from '../RecruitmentNotFound'
import RecruitmentAnswerView from './RecruitmentAnswerView'

const formFields = [
  {
    name: 'fields_extra',
    defaultValue: [],
  },
  {
    name: 'selected_object',
    defaultValue: undefined,
  },
  {
    name: 'email',
  },
  {
    name: 'gender',
  },
  {
    name: 'phone_number',
  },
  {
    name: 'date_of_birth',
  },
  {
    name: 'candidate',
  },
  {
    name: 'description',
    defaultValue: '',
  },
  {
    name: 'changes',
    defaultValue: {},
  },
  {
    name: 'information_fields',
    defaultValue: {},
  },
  {
    name: 'required_information_fields',
    defaultValue: [],
  },
]

const DummyField = React.memo(
  ({ name, value }) => {
    const [__, ___, helpers] =
      useField(name) || {}
    const { setValue = Null } = helpers
    useEffect(() => {
      setValue(value)
    }, [])
    return null
  }
)

const ReviewCandidate = ({
  candidate,
}) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()

  const { response, isLoading } =
    useAsync({
      query: bindQueryParam({
        username: getId(candidate),
      }),
      apiInfo: auth_getUserProfile_Api,
    })

  if (!!isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <LoadingPage />
      </div>
    )
  }

  const profile =
    getResponseItem(response)


  if (_.isEmpty(profile)) {
    return null
  }

  return (
    <React.Fragment>
      <div className="p-2 bg-primary-50 border border-primary rounded-lg flex items-center justify-between">
        <span className="text-primary italic font-medium">
          {t('you want to see candidate information')}
        </span>
        <Button
            type="primary"
            className="rounded-lg no-shadow no-text-shadow"
            onClick={toggle}>
          {t('view profile')}
        </Button>
      </div>
      <Modal
        footer={null}
        closable={true}
        centered={true}
        visible={isToggle}
        destroyOnClose={true}
        width={modalWidth.large}
        onCancel={() => toggle(false)}>
        <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
          {t('candidate profile')}
        </div>
        <div className="space-y-2">
          <List.Item>
            <List.Item.Meta
              title={
                <span className="text-color-000 font-semibold">
                  {getTitle(candidate)}
                </span>
              }
              className="items-center px-2"
              avatar={
                <Avatar
                  src={getAvatar(
                    candidate
                  )}
                />
              }
            />
          </List.Item>
          <RecruitmentCandidateProfile
            profile={profile}
          />
        </div>
      </Modal>
    </React.Fragment>
  )
}

const formSchema = [
  {
    children: [
      {
        name: 'candidate',
        component: mapProps(
          ({ value }) => ({
            candidate: value,
          })
        )(ReviewCandidate),
      },
    ],
  },
  {
    children: [
      {
        name: 'selected_candidate',
        component:
          createControlledFormField({
            Component:
              RecruitmentCandidateSelector,
            withValues: (values) => ({
              defaultValue: _.get(
                values,
                'information_values'
              ),
              readOnly: !!_.get(
                values,
                'readOnly'
              ),
            }),
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'information_fields',
        component:
          createControlledFormField({
            readOnly: true,
            Component:
              RecruitmentRequiredFormFields,
            withValues: ({
              email,
              gender,
              phone_number,
              date_of_birth,
              information_fields,
              required_information_fields,
            }) => ({
              values: {
                information_fields,
                required_information_fields,
              },
              value: {
                email,
                gender,
                phone_number,
                date_of_birth,
              },
            }),
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component: compose(
          withTranslate,
          mapProps(
            ({
              form,
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              value,
              rows: 5,
              onChange,
              readOnly: _.get(
                form,
                'values.readOnly'
              ),
              placeholder: translate(
                'description'
              ),
            })
          )
        )(Input.TextArea),
      },
    ],
  },
  {
    children: [
      {
        name: 'fields_extra',
        component:
          createControlledFormField({
            Component:
              RecruitmentAnswerView,
            targetName:
              'collection_answers',
            readOnly: true,
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'changes',
        component: mapProps(() => ({
          name: 'changed',
          value: true,
        }))(DummyField),
      },
    ],
  },
]

const ManageFormContent = ({
  actionType,
  recruitment,
  Wrapper = 'div',
  onCancel = Null,
  onUpdated = Null,
  readOnly = false,
  isEditing = false,
  candidate: currentCandidate,
  ...props
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const isSm = useContext(LayoutContext)

  const id = getId(recruitment)

  const candidate_id = getId(
    currentCandidate
  )

  const candidate_entity =
    useSelectEntities(
      candidate_id,
      candidateSchema,
      currentCandidate
    )

  const initialized = useCallback(
    ({
      email,
      gender,
      candidate,
      phone_number,
      date_of_birth,
      recruitment_event,
      ...values
    }) => {
      const {
        information_fields,
        required_information_fields,
      } = recruitment || {}

      const merged_fields = [
        ...Object.values(
          information_fields
        ).map(({ name }) => name),
        'id',
        'title',
        'name',
        '_type',
      ]

      return {
        ...values,
        ...{
          email,
          gender,
          phone_number,
          date_of_birth,
        },
        readOnly,
        candidate,
        information_fields,
        information_values: fromObject(
          {
            ...(candidate || login),
            ...{
              email,
              gender,
              phone_number,
              date_of_birth,
            },
          },
          Array.from(
            merged_fields || []
          ).map((e) => ({ name: e }))
        ),
        required_information_fields,
        selected_candidate: candidate,
      }
    },
    [login, isEditing, recruitment]
  )

  const validate = ({
    ...values
  }) => ({})

  const validationSchema = undefined

  const { removePost = Null } =
    useContext(ObserverContext)

  const { handleAsyncAction: reject } =
    useAsyncAction({
      apiInfo:
        recruitmentApi.recruitment_events_candidateManageReject_api,
      query: bindQueryParams([
        {
          id,
        },
        { candidate_id },
      ]),
      onError: notifyOnError(t),
      onSuccess: (result, __) => {
        successNotify(
          NotificationActionTypes.DELETE,
          t
        )
        if (result) {
          removePost(result)
        }
      },
    })

  if (
    _.isEmpty(id) ||
    _.isEmpty(candidate_id)
  ) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const isApproving =
    actionType ===
    RecruitmentItemContextActions.APPROVE

  const apiInfo = isApproving
    ? recruitmentApi.recruitment_events_candidateManageApprove_api
    : recruitmentApi.recruitment_events_candidateManageEdit_api

  const onSuccess = ([
    result,
    { response },
  ]) => {
    const newItem =
      getResponseItem(response)
    onUpdated(result, newItem)
    onCancel()
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    changes,
    email,
    gender,
    candidate,
    phone_number,
    date_of_birth,
    readOnly,
    fields_extra,
    selected_object,
    selected_candidate,
    information_values,
    information_fields,
    required_information_fields,
    ...values
  }) => {}

  const renderErrorMessage = (
    name,
    message
  ) => {
    switch (name) {
      default:
        return (
          <li key={name}>
            {`${name}: ${message}`}
          </li>
        )
    }
  }

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    apiInfo,
    onError,
    validate,
    onSuccess,
    initialized,
    onPreSubmit,
    item: candidate_entity,
    validationSchema,
    query: bindQueryParams([
      {
        id,
      },
      { candidate_id },
    ]),
    Header: () => (
      <FormikConsumer>
        {({ errors }) => (
          <WishareAlert
            className="my-2"
            closable={false}
            messageType={
              MaterialTypes.WARNING
            }
            message={
              <div>
                <span className="text-lg font-bold mb-3">
                  {t('note')}
                </span>
                <ul
                  style={{
                    listStyle: 'circle',
                    marginLeft:
                      '0.75rem',
                  }}>
                  {_.isString(errors)
                    ? String(errors)
                    : Object.keys(
                        errors
                      ).map((name) =>
                        renderErrorMessage(
                          name,
                          errors[name]
                        )
                      )}
                </ul>
              </div>
            }
            visible={!_.isEmpty(errors)}
          />
        )}
      </FormikConsumer>
    ),
    ActionBar: () => (
      <FormActionBar
        submitText={
          isApproving
            ? 'approve'
            : 'save'
        }
        hideResetButton={true}
        ExtraActions={() => (
          <div className="flex gap-2 px-2">
            <Button
              onClick={onCancel}
              className="font-bold rounded-lg no-shadow no-text-shadow no-border">
              {t('cancel')}
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => reject()}
              className="font-bold rounded-lg no-shadow no-text-shadow no-border">
              {t('reject')}
            </Button>
          </div>
        )}
      />
    ),
  })

  const title = getTitle(recruitment)

  const avatar = _.get(
    recruitment,
    'owner.avatar'
  )
  const owner = _.get(
    recruitment,
    'owner.title'
  )

  return (
    <Wrapper className="space-y-3 py-3">
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
        <center>
          {t(
            'candidate profile'
          )}
        </center>
      </div>
      <div className="flex flex-col mb-6 p-3 border border-green-600 background rounded-lg">
        <span className=" text-sm font-medium text-color-400 tracking-wide mb-1">
          {t(
            'currently an employee of the organization'
          )}
        </span>
        <div className="flex items-center font-bold text-green-700">
          <Avatar
            src={avatar}
            size={isSm ? 30 : 50}
          />
          <div className="flex flex-col flex-1 mx-3">
            <span className="text-base md:text-lg max-lines-1">
              {title}
            </span>
            <span className="text-xs font-light text-secondary italic">
              {owner}
            </span>
          </div>
        </div>
      </div>
      {content}
    </Wrapper>
  )
}

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const RecruitmentManageEditForm = ({
  location,
  ...props
}) => {
  const { recruitment: item } =
    useContext(RecruitmentContext)

  const { id } =
    _.get(location, 'state') || {}

  const recruitment = useSelectEntities(
    id,
    recruitmentEventSchema,
    item
  )

  const ModalForm = _.get(
    recruitment,
    'edit'
  )
    ? ManageFormContent
    : AccessDenied

  if (_.isEmpty(recruitment)) {
    return <RecruitmentNotFound />
  }

  const Component = withModalLayout(
    ModalForm,
    {
      recruitment,
      ...props,
    }
  )

  return (
    <AntdConfigWrapper>
      <Pure>
        <Component />
      </Pure>
    </AntdConfigWrapper>
  )
}

export default createRequiredLogin()(
  RecruitmentManageEditForm
)
