import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import EditOutlined from '@ant-design/icons/EditOutlined'
import {
  Alert,
  message,
  Radio,
} from 'antd'
import {
  categories_addShortcutChartToCategory_Api,
  categories_getShortcutChartsByChartId_Api,
  categories_removeShortcutChartToCategory_Api,
} from 'apis'
import Modal from 'components/Modal/Modal'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  nest,
  withProps,
} from 'recompose'
import ChartContext from 'views/Chart/ChartContext'
import SelectCategory from 'views/SelectCategory'

const ShortcutChart = ({
  activeCategory,
  chart_id,
  item,
  idx,
  ...props
}) => {
  const {
    handleAsyncAction: handleAsyncAdd,
  } = useAsyncAction({
    apiInfo:
      categories_addShortcutChartToCategory_Api,
    query: {
      ':cate_id': activeCategory.id,
      ':id': chart_id,
      ':position': idx.toString(),
    },
    onSuccess: (result) => {
      props.onSuccess()
    },
    onError: (error, errorMessages) => {
      message.error(errorMessages.error)
    },
  })

  const {
    handleAsyncAction:
      handleAsyncRemove,
  } = useAsyncAction({
    apiInfo:
      categories_removeShortcutChartToCategory_Api,
    query: {
      ':cate_id': activeCategory.id,
      ':position': idx.toString(),
    },
    onSuccess: (result) => {
      props.onSuccess()
    },
    onError: (error, errorMessages) => {
      message.error(errorMessages.error)
    },
  })

  return (
    <div
      key={idx}
      onClick={(e) => {
        handleAsyncAdd()
      }}>
      {_.isEmpty(item) ? (
        <div className="h-16 w-full flex border border-dashed rounded hover:border-green-500 items-center justify-center">
          (Empty)
        </div>
      ) : (
        <div className="h-32 w-full flex relative flex-col border-2 rounded hover:border-green-500 p-3 overflow-hidden background-200">
          <CloseCircleFilled
            className="text-red-500 absolute"
            style={{ top: 8, right: 8 }}
            onClick={(e) => {
              e.stopPropagation()
              handleAsyncRemove()
            }}
          />
          <div className="flex">
            <div className="font-semibold">
              {item.title}
            </div>
          </div>
          <div className="">
            {item.description}
          </div>
        </div>
      )}
    </div>
  )
}

function ChooseShortcutChartList({
  id,
  type,
  item,
  ...props
}) {
  const t = useTranslate()
  const [
    activeCategory,
    setActiveCategory,
  ] = useState()

  const { response, success } =
    useAsync({
      apiInfo:
        categories_getShortcutChartsByChartId_Api,
      query: {
        ':id': id,
      },
    })

  useEffect(() => {
    if (success) {
      const categories = _.get(
        response,
        'data.data',
        []
      )
      setActiveCategory(
        categories[0] || null
      )
      // setCharts(shortcut_charts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (!success) return null

  const categories = _.get(
    response,
    'data.data',
    []
  )

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  return (
    <div className="px-3 py-16 background">
      <Alert
        message={
          <div>
            {!activeCategory ? (
              <div className="flex flex-col">
                <div>
                  {t(
                    'You are not choosing to follow this chart category. Please choose follow up in advance'
                  )}
                </div>
                <Toggle>
                  {(
                    isToggle,
                    toggle
                  ) => {
                    return (
                      <>
                        <div
                          className="cursor-pointer p-3 my-3 border rounded-lg flex justify-center items-center font-bold"
                          onClick={
                            toggle
                          }>
                          <div className="px-2 flex-1">
                            {t(
                              'select category'
                            )}
                          </div>
                          <EditOutlined />
                        </div>
                        {isToggle && (
                          <FullSideModalLayout
                            onCancel={
                              toggle
                            }>
                            <SelectCategory
                              onSuccess={
                                toggle
                              }
                            />
                          </FullSideModalLayout>
                        )}
                      </>
                    )
                  }}
                </Toggle>
              </div>
            ) : (
              <div>
                {t(
                  'Select the display order in the category'
                )}
                :
              </div>
            )}

            {!!activeCategory && (
              <Radio.Group
                onChange={(e) => {
                  const selectedCate =
                    categories.filter(
                      (c, i) =>
                        c.id ===
                        e.target.value
                    )
                  if (selectedCate)
                    setActiveCategory(
                      selectedCate
                    )
                }}
                value={
                  activeCategory.id
                }>
                {categories.map((e) => {
                  return (
                    <Radio
                      key={e.id}
                      style={radioStyle}
                      value={e.id}>
                      {e.name}
                    </Radio>
                  )
                })}
              </Radio.Group>
            )}
          </div>
        }
        className="mb-6 background"
      />

      <div className="verticalList">
        {!!activeCategory &&
          !_.isEmpty(
            activeCategory.shortcut_charts
          ) &&
          activeCategory.shortcut_charts.map(
            (item, i) => {
              return (
                <ShortcutChart
                  {...props}
                  item={item}
                  idx={i.toString()}
                  activeCategory={
                    activeCategory
                  }
                  chart_id={id}
                  key={id}
                />
              )
            }
          )}
      </div>
    </div>
  )
}

export default ChooseShortcutChartList

export const ChooseShortcutChartListModal =
  nest(
    withProps({
      visible: true,
      footer: null,
    })(Modal),
    (props) => (
      <ChartContext.Consumer>
        {({ id, type, item }) => {
          return (
            <ChooseShortcutChartList
              {...props}
              item={item}
              id={id}
              type={type}
            />
          )
        }}
      </ChartContext.Consumer>
    )
  )
