import { SearchGroupIcon } from 'components/AppIcon'
import Count from 'components/Feed/Count'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { IoFilterOutline } from 'react-icons/io5'
import SearchContext from 'views/Search/SearchContext'
import {
  SearchParams,
  SearchTypes,
} from 'views/Search/SearchProvider'
import {
  Null,
  renderFalse,
  renderOwnChild,
} from 'views/Shared'
import createContextMenu from 'views/Wishare/factory/createContextMenu'
import { discoveryExplorerRouteEntities } from '../discoveryExplorerRouteEntities'

export const DiscoveryContextMenu = ({
  isSelected = renderFalse,
  Wrapper = renderOwnChild,
}) => {
  const history = useHistory()

  const onSelect = useCallback(
    (key) => {
      const {
        pathname,
        getSearch = Null,
      } = _.find(discoveryExplorerRouteEntities, { type: key }) || {}

      history.push({
        pathname,
        search:
          getSearch(history) ||
          undefined,
      })
    },
    [history]
  )

  const contextMenu = useMemo(
    () =>
      createContextMenu(
        [
          SearchTypes.ALL,
          SearchTypes.ACTIVITY,
          SearchTypes.GIVING_EVENT,
          SearchTypes.DONATION_EVENT,
          SearchTypes.RECRUITMENT_EVENT,
          SearchTypes.EVENT,
          SearchTypes.CHANNEL,
          SearchTypes.ARTICLE,
          SearchTypes.ORGANIZATION,
        ].map((key) => {
            const selected = isSelected(key)

            return {
                key,
                label: key,
                icon: (
                    <SearchGroupIcon type={key} size={15}/>
                ),
                renderLabel: (label) =>
                    <span className={selected ? 'text-color-000 font-bold' : 'text-color-100 font-medium'}>
                      {label}
                    </span>
            }
        }),
        onSelect,
        {
          icon: (
            <Count deta-effect="ripple">
              <IoFilterOutline
                size={20}
              />
            </Count>
          ),
        }
      ),
    [onSelect, isSelected]
  )

  return (
    <Wrapper>{contextMenu}</Wrapper>
  )
}

const DiscoveryExplorerHeader = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { searchParams } = useContext(
    SearchContext
  )

  const type = _.get(
    searchParams,
    SearchParams.TYPE,
    SearchTypes.ALL
  )

  return (
    <Wrapper className="p-2 background stickyTop top-0 border-b border-color-50 md:mx-0 shadow-md">
      <div className="flex gap-2 items-center">
        <span className="flex flex-1 font-semibold uppercase">{t(type)}</span>
        <DiscoveryContextMenu isSelected={(key) => key === type}/>
      </div>
    </Wrapper>
  )
}

export default DiscoveryExplorerHeader
