import _ from 'lodash'
import {permissionCodes} from 'modules/permissions/contants'
import React from 'react'
import {
  IoDiamondOutline,
  IoFlowerOutline,
  IoGridOutline,
  IoHelpCircleOutline,
  IoPersonOutline,
  IoSaveOutline,
  IoSettingsOutline,
  IoStatsChartOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {withRouter} from 'react-router-dom'
import {compose, withProps,} from 'recompose'
import {notFoundOnError, withAccessPermissions,} from 'views/Wishare/composers'
import {CustomKeys} from 'views/Wishare/CustomKeys'
import {WishareEntities} from 'views/Wishare/enums'
import {ContextActionTypes} from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import {CoHosts, CoOwners,} from 'views/Wishare/factory/createHostSetting'
import {createSettingModule} from 'views/Wishare/factory/createModule'
import GivingContext from '../GivingContext'
import GivingSettingCategories from './GivingSettingCategories'
import GivingSettingDonation from './GivingSettingDonation'
import GivingSettingImpCate from './GivingSettingImpCate'
import GivingSettingOverview from './GivingSettingOverview'
import GivingSettingOwner from './GivingSettingOwner'
import GivingSettingQuestions from './GivingSettingQuestions'
import GivingSettingTarget from './GivingSettingTarget'
import {TbFileImport} from "react-icons/tb";

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline/>,
  },
  {
    key: 'owner',
    label: 'owner',
    icon: <IoDiamondOutline/>,
  },
  {
    key: 'category',
    label: 'categories',
    icon: <IoGridOutline/>,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
      {
        label: 'impact categories',
        key: 'impact-categories',
      },
    ],
  },
  {
    key: 'donation',
    label: 'donation',
    icon: <IoFlowerOutline/>,
  },
  {
    key: 'target',
    label: 'target',
    icon: <IoStatsChartOutline/>,
  },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <IoPersonOutline/>,
    children: [
      {
        label:
        CustomKeys.co_hosts.label,
        key: CustomKeys.co_hosts.name,
      },
    ],
  },
  {
    key: 'question',
    label: 'information collecting',
    icon: <IoHelpCircleOutline/>,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline/>,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: GivingSettingOverview,
  },
  {
    path: 'categories',
    label: 'categories',
    component: GivingSettingCategories,
  },
  {
    path: 'impact-categories',
    label: 'impact categories',
    component: GivingSettingImpCate,
  },
  {
    path: 'owner',
    label: 'owner',
    component: GivingSettingOwner,
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: () => (
      <GivingContext.Consumer>
        {({giving}) => (
          <CoHosts item={giving}/>
        )}
      </GivingContext.Consumer>
    ),
  },
  {
    path: 'host-request',
    label: 'host request',
    component: () => (
      <GivingContext.Consumer>
        {({giving}) => (
          <CoOwners item={giving}/>
        )}
      </GivingContext.Consumer>
    ),
  },
  {
    path: 'donation',
    label: 'donation',
    component: GivingSettingDonation,
  },
  {
    path: 'target',
    label: 'target',
    component: GivingSettingTarget,
  },
  {
    path: 'question',
    label: 'information collecting',
    component: GivingSettingQuestions,
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <GivingContext.Consumer>
        {({giving}) =>
          createDeleteEntity(giving)
        }
      </GivingContext.Consumer>
    ),
  },
].map(
  ({
     path,
     component: Component,
     ...rest
   }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const givingSettings = {
  actions: [
    // {
    //   key: 1,
    //   label: 'chưa dịch',
    //   icon: <StarOutlined />,
    // },
    // {
    //   key: 2,
    //   label: 'chưa dịch',
    //   icon: <StarOutlined />,
    // },
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline/>,
    },
    {
      key: ContextActionTypes.IMPORT,
      label: 'import giving data',
      icon: <TbFileImport/>,
    },
    {
      key: ContextActionTypes.EXPORT,
      label: 'export giving data',
      icon: <IoSaveOutline/>,
    },
  ],
  menuItems,
  routeEntities,
}

const GivingSettings = ({
                          id,
                          ...props
                        }) =>
  createSettingModule(
    [WishareEntities.GIVING],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(
    GivingContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({match}) => ({
    id: _.get(match, 'params.id'),
  }))
)(GivingSettings)
