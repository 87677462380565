import { Carousel } from 'antd'
import classNames from 'classnames'
import React from 'react'
import {
  TiArrowLeft,
  TiArrowRight,
} from 'react-icons/ti'
import { Null } from 'views/Shared'

const NavArrow = ({
  style,
  children,
  className,
  onClick = Null,
}) => (
  <div
    className={classNames(
      'opacity-50 hover:opacity-100 cursor-pointer',
      className
    )}
    style={{
      ...style,
      zIndex: 10,
      background: 'var(--background)',
      width: '30px',
      height: '30px',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#545454',
      transform: 'trans',
    }}
    onClick={onClick}>
    {children}
  </div>
)

const NextArrow = ({
  style,
  ...props
}) => {
  return (
    <NavArrow
      style={{
        ...style,
        right: '10px',
      }}
      {...props}>
      <TiArrowRight size={24} />
    </NavArrow>
  )
}

const PrevArrow = ({
  style,
  ...props
}) => {
  return (
    <NavArrow
      style={{
        ...style,
        left: '10px',
      }}
      {...props}>
      <TiArrowLeft size={24} />
    </NavArrow>
  )
}

const CustomCarousel = ({
  items = [],
  dots = false,
  speed = 500,
  arrows = true,
  infinite = false,
  renderItem = Null,
  slidesToShow = 4,
  slidesToScroll = 4,
  ...props
}) => {
  const responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite,
        dots,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ]

  return (
    <Carousel
      className="Slider custom-carousel"
      {...{
        dots,
        speed,
        arrows,
        responsive,
        slidesToShow: Math.min(
          items.length,
          slidesToShow
        ),
        slidesToScroll,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        ...props,
      }}>
      {items.map(renderItem)}
    </Carousel>
  )
}

export default CustomCarousel
