import { Avatar } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import * as moment from 'moment'
import React from 'react'
import { BsDiagram3Fill } from 'react-icons/bs'
import { FcStatistics } from 'react-icons/fc'
import { GiCarWheel } from 'react-icons/gi'
import { ImLibrary } from 'react-icons/im'
import {
  IoApps,
  IoCalendarNumber,
  IoCreate,
  IoCreateOutline,
  IoFlower,
  IoFlowerOutline,
  IoGift,
  IoGiftOutline,
  IoPeople,
  IoPerson,
  IoPersonCircleOutline,
  IoRadio,
  IoRadioOutline,
  IoSettings,
  IoSpeedometer,
} from 'react-icons/io5'
import {
  RiUser2Fill,
  RiUserReceived2Fill,
  RiUserShared2Fill,
} from 'react-icons/ri'
import { SearchTypes } from 'views/Search/SearchProvider'
import { WishareEntities } from 'views/Wishare/enums'
import { getFullAvatarUrl } from '../apis/model/base'
import { WorkSpaceActions } from '../views/MainPage/WorkSpace/Workspace'
import { ChannelIcon } from './icons/ChannelIcon'
import { DatasetIcon } from './icons/DatasetIcon'
import { WishareHeartSvg } from './Svgs/WishareHeartSvg'
import { WishareRibbonSvg } from './Svgs/WishareRibbonSvg'

export const CalendarIcon = ({
  time,
}) => {
  const dt =
    moment(time).locale('en') ||
    moment().locale('en')
  var month = dt.format('MMM')
  var day = dt.format('DD')

  return (
    <div
      style={{
        fontSize: '0.625rem',
        width: '2rem',
      }}
      className="flex flex-col rounded bg-green-600 text-white">
      <div
        style={{ fontSize: '0.625rem' }}
        className="inline-flex justify-center items-center leading-snug">
        {month}
      </div>
      <div
        style={{ fontSize: '0.575rem' }}
        className="border border-green-600 rounded text-color-000 inline-flex justify-center items-center bg-white font-normal leading-snug">
        {day}
      </div>
    </div>
  )
}

const IconWrapper = ({ children }) => {
  const DefautComponent = ({
    children,
  }) => (
    <Avatar
      shape="square"
      className="bg-gray-300 capitalize">
      {children}
    </Avatar>
  )
  return (
    <div
      className="flex items-center justify-center"
      style={{
        // minWidth: '1rem',
        // minHeight: '1rem',
        color: 'var(--text-color-400)',
      }}>
      {_.isFunction(children)
        ? children(DefautComponent)
        : children}
    </div>
  )
}

export default function AppIcon({
  item,
  size,
}) {
  if (!!!item || !!!item._type)
    return null
  switch (item._type) {
    case WishareEntities.CHANNEL:
      return (
        <IconWrapper>
          <ChannelIcon
            fill={
              'var(--text-color-400)'
            }
            premium={_.get(
              item,
              'premium',
              0
            )}
            className="text-ld w-full h-full justify-start"
          />
        </IconWrapper>
      )
    case WishareEntities.EVENT:
      return (
        <IconWrapper>
          <CalendarIcon
            time={item.time_open}
          />
        </IconWrapper>
      )
    case 'dataset':
      return (
        <IconWrapper>
          <DatasetIcon
            fill={'var(--text-color)'}
            className="text-2xl w-8 h-8 justify-start"
            premium={_.get(
              item,
              'premium',
              0
            )}
          />
        </IconWrapper>
      )
    case 'chart':
      return (
        <IconWrapper>
          <Avatar
            shape="square"
            size={size}
            className="bg-transparent rounded-md"
            icon={
              <FcStatistics className="text-2xl w-full h-full justify-start text-color-300" />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.USER:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            className="flex flex-center background-100"
            src={getFullAvatarUrl(item)}
            icon={
              <IoPerson className="text-color-300" />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.ORGANIZATION:
      return (
        <IconWrapper>
          <Avatar
            shape="square"
            size={size}
            className="bg-transparent rounded-md"
            src={getFullAvatarUrl(item)}
            icon={
              <ImLibrary
                size={20}
                className="w-full h-full justify-start text-color-org"
              />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.BENEFICIARY:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            shape="circle"
            src={getFullAvatarUrl(item)}
            className="bg-transparent"
            icon={
              <WishareHeartSvg
                width="1rem"
                height="1rem"
              />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.ACTIVITY:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            shape="circle"
            src={getFullAvatarUrl(item)}
            className="bg-transparent"
            icon={
              <WishareRibbonSvg
                width="1rem"
                height="1rem"
              />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.DONATION:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            shape="circle"
            src={getFullAvatarUrl(item)}
            className="bg-transparent"
            icon={
              <IoFlowerOutline className="text-2xl w-full h-full justify-start text-color-300" />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.GIVING:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            shape="circle"
            src={getFullAvatarUrl(item)}
            className="bg-transparent"
            icon={
              <IoGiftOutline className="text-2xl w-full h-full justify-start text-color-300" />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.RECRUITMENT:
      return (
        <IconWrapper>
          <Avatar
            shape="circle"
            src={getFullAvatarUrl(item)}
            size="small"
            className="bg-transparent"
            icon={
              <IoPeople className="text-2xl w-full h-full justify-start text-color-300" />
            }
          />
        </IconWrapper>
      )
    case WishareEntities.ARTICLE:
      return (
        <IconWrapper>
          <Avatar
            shape="circle"
            src={getFullAvatarUrl(item)}
            className="bg-transparent"
            icon={
              <IoCreateOutline className="text-2xl w-full h-full justify-start text-color-300" />
            }
          />
        </IconWrapper>
      )
    default:
      return (
        <IconWrapper>
          <Avatar
            size={size}
            shape="square"
            className="background-200"
            src={getFullAvatarUrl(item)}
            icon={
              <IoPersonCircleOutline className="text-2xl w-full h-full justify-start" />
            }
          />
        </IconWrapper>
      )
  }
}

export const SearchGroupIcon = ({
  style,
  className,
  size = 20,
  type = SearchTypes.ALL,
  FallbackComponent = IoApps,
}) => {
  if (_.isEmpty(type)) return null
  switch (type) {
    case SearchTypes.ORGANIZATION:
      return (
        <ImLibrary
          size={size}
          style={style}
          className={classNames(
            'text-color-org',
            className
          )}
        />
      )
    case SearchTypes.ACTIVITY:
      return (
        <WishareRibbonSvg
          style={style}
          className={className}
          width={size || '1.6rem'}
          height={size || '1.6rem'}
        />
      )
    case SearchTypes.DONATION_EVENT:
      return (
        <IoFlower
          size={size}
          style={style}
          className={classNames(
            'text-primary',
            className
          )}
        />
      )
    case SearchTypes.GIVING_EVENT:
      return (
        <IoGift
          size={size}
          style={style}
          className={classNames(
            'text-secondary',
            className
          )}
        />
      )
    case SearchTypes.RECRUITMENT_EVENT:
      return (
        <IoPeople
          size={size}
          style={style}
          className={classNames(
            'text-green-600',
            className
          )}
        />
      )
    case SearchTypes.ARTICLE:
      return (
        <IoCreate
          size={size}
          style={style}
          className={classNames(
            'text-teal-500',
            className
          )}
        />
      )
    case SearchTypes.EVENT:
      return (
        <IoCalendarNumber
          size={size}
          style={style}
          className={classNames(
            'text-indigo-500',
            className
          )}
        />
      )
    case SearchTypes.CHANNEL:
      return (
        <IoRadio
          size={size}
          style={style}
          className={classNames(
            'text-orange-500',
            className
          )}
        />
      )
    case SearchTypes.USER:
      return (
        <IoPerson
          size={size}
          style={style}
          className={classNames(
            'text-gray-500',
            className
          )}
        />
      )
    //  workspace route
    case WorkSpaceActions.HOME:
      return (
        <IoSpeedometer
          size={size}
          style={style}
          className={classNames(
            'text-color-org',
            className
          )}
        />
      )
    case WorkSpaceActions.CHANNELS:
      return (
        <IoRadioOutline
          size={size}
          style={style}
          className={classNames(
            'text-orange-500',
            className
          )}
        />
      )
    case WorkSpaceActions.DONATIONS:
      return (
        <RiUserShared2Fill
          size={size}
          style={style}
          className={classNames(
            'text-blue-500',
            className
          )}
        />
      )
    case WorkSpaceActions.GIVINGS:
      return (
        <RiUserReceived2Fill
          size={size}
          style={style}
          className={classNames(
            'text-secondary',
            className
          )}
        />
      )
    case WorkSpaceActions.SETTING:
      return (
        <IoSettings
          size={size}
          style={style}
          className={classNames(
            'text-gray-500',
            className
          )}
        />
      )
    case WorkSpaceActions.ACTIVITIES:
      return (
        <WishareRibbonSvg
          style={style}
          className={className}
          width={size || '1.5rem'}
          height={size || '1.5rem'}
        />
      )
    case WorkSpaceActions.HUMAN_RESOURCE_MANAGEMENT:
      return (
        <RiUser2Fill
          size={size}
          style={style}
          className={classNames(
            'text-green-600',
            className
          )}
        />
      )
    case WorkSpaceActions.STRUCTURES:
      return (
        <BsDiagram3Fill
          size={size}
          style={style}
          className={classNames(
            'text-indigo-500',
            className
          )}
        />
      )
    case WorkSpaceActions.OPERATING:
      return (
        <GiCarWheel
          size={size}
          style={style}
          className={classNames(
            'text-orange-500',
            className
          )}
        />
      )
    default:
      return (
        <FallbackComponent
          size={size}
          style={style}
          className={classNames(
            'text-xl text-color-500',
            className
          )}
        />
      )
  }
}
