import { Avatar, Tag } from 'antd'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { IoBonfireOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { Null } from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'
import getLinkToDetail from '../../../../helpers/getLinkToDetail'
import {
  BeneficiaryManagerActions,
  BeneficiaryManagerContext,
  BeneficiaryManagerFilterOptions,
} from '../Settings/BeneficiaryManager'

const BeneficiaryContextMenuOptions =
  Object.freeze({
    APPROVE: {
      key: 'approve',
      label: 'approve',
    },
    REJECT: {
      key: 'reject',
      label: 'reject',
    },
    TRANSFER: {
      key: 'transfer',
      label: 'transfer',
    },
    REMOVE: {
      key: 'remove',
      label: 'remove',
    },
    SETTING: {
      key: 'setting',
      label: 'setting',
    },
    CANCEL_TRANSFER: {
      key: 'cancel-transfer',
      label: 'cancel transfer',
    },
  })

const BeneficiaryActionRow = ({
  filter,
  item,
  contextMenu,
}) => {
  const t = useTranslate()

  const { user, description } =
    item || {}

  const avatar =
    getAvatar(user) || undefined

  const location = _.get(
    item,
    'locations',
    []
  )

  const address = location.map(
    (e, i) => (
      <span key={i}>
        {_.get(e, 'ward.location_name')}
        {', '}
        {_.get(
          e,
          'district.location_name'
        )}
        {', '}
        {_.get(
          e,
          'province.location_name'
        )}
        {', '}
        {_.get(
          e,
          'country.location_name'
        )}
      </span>
    )
  )

  const referral = _.get(
    item,
    'referrals',
    []
  )

  const status = _.get(
    item,
    'beneficiary_activity_status'
  )

  const WishStatus = ({ status }) =>
    status === -2 ? (
      <Tag
        size="small"
        color="error"
        className="text-2xs italic mr-0">
        {t('reject')}
      </Tag>
    ) : status === -1 ? (
      <Tag
        size="small"
        color="processing"
        className="text-2xs italic mr-0">
        {t('pending')}
      </Tag>
    ) : null

  return (
    <div className="border border-color-50 rounded-lg relative">
      <OverlayItemBanner
        type={WishareEntities.BENEFICIARY}
      />
      <div className="h-7" />
      <div className="p-2">
        <div className="flex items-center gap-2">
          <Avatar
            src={avatar}
            size={30}
            icon={<IoBonfireOutline />}
          />
          <div className="flex flex-col flex-1">
            <Link
              to={getLinkToDetail(item)}
              className="text-color-000 font-semibold leading-tight max-lines-2">
              {getTitle(item)}
            </Link>
            <span className="text-sm text-secondary italic">
              {description}
            </span>
          </div>
          {filter ===
          BeneficiaryManagerFilterOptions
            .active.value ? (
            <span />
          ) : (
            <WishStatus
              status={status}
            />
          )}
        </div>
        <div className="flex items-baseline space-x-1 mt-2">
          <span className="text-xs font-light text-color-500 italic">
            {`${t('address')} : `}
          </span>
          <span className="font-semibold text-color-000 text-sm flex-1 leading-tight">
            {address}
          </span>
        </div>
        <div className="flex flex-col border-t border-color-50 mt-2 pt-2">
          <span className="text-sm uppercase text-color-400 italic mb-2">
            {t('referral information')}
          </span>
          {referral.map((e, i) => (
            <div
              key={i}
              className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <Avatar
                  src={_.get(
                    e,
                    'referral.avatar'
                  )}
                  size={30}
                  icon={
                    <IoBonfireOutline />
                  }
                />
                <Link
                  to={getLinkToDetail(
                    _.get(e, 'referral')
                  )}
                  className="flex-1 text-color-000 font-semibold no-underline leading-tight max-lines-1">
                  {getTitle(
                    _.get(e, 'referral')
                  )}
                </Link>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="flex items-baseline space-x-1">
                  <span className="text-xs font-light text-color-500 italic">
                    {`${t('phone')} : `}
                  </span>
                  <span className="font-semibold text-color-000 text-sm">
                    {_.get(
                      e,
                      'referral.phone_number'
                    )}
                  </span>
                </div>
                <div className="flex items-baseline space-x-1">
                  <span className="text-xs font-light text-color-500 italic">
                    {`${t('email')} : `}
                  </span>
                  <span className="font-semibold text-color-000 text-sm">
                    {_.get(
                      e,
                      'referral.email'
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute top-0 right-0 p-1">
        {contextMenu}
      </div>
    </div>
  )
}

const ActiveContextMenu = ({
  item,
  filter,
}) => {
  const t = useTranslate()

  const { renderContextMenu = Null } =
    useContext(BeneficiaryManagerContext)

  const options = [
    BeneficiaryManagerActions.PRE_TRANSFER,
    BeneficiaryManagerActions.SETTING,
    BeneficiaryManagerActions.REMOVE_BENEFICIARY,
  ]

  const menuOptions = options.map(
    ({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })
  )

  return renderContextMenu(
    item,
    menuOptions
  )()
}

const OnMovingContextMenu = ({
  item,
  filter,
}) => {
  const t = useTranslate()

  const { renderContextMenu = Null } =
    useContext(BeneficiaryManagerContext)

  const options = [
    BeneficiaryManagerActions.CANCEL_TRANSFER,
  ]

  const menuOptions = options.map(
    ({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })
  )

  return renderContextMenu(
    item,
    menuOptions
  )()
}

const PendingContextMenu = ({
  item,
  filter,
}) => {
  const t = useTranslate()

  const { renderContextMenu = Null } =
    useContext(BeneficiaryManagerContext)

  const options = [
    BeneficiaryManagerActions.APPROVE_BENEFICIARY,
    BeneficiaryManagerActions.REJECT_BENEFICIARY,
  ]

  const menuOptions = options.map(
    ({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })
  )

  return renderContextMenu(
    item,
    menuOptions
  )()
}

const renderBeneficiaryActionRow =
  (
    filter = BeneficiaryManagerFilterOptions
      .active.value
  ) =>
  (item, index) => {
    let ContextMenu = Null
    const params = {
      item,
      filter,
    }
    switch (filter) {
      case BeneficiaryManagerFilterOptions
        .active.value:
        ContextMenu = ActiveContextMenu
        break
      case BeneficiaryManagerFilterOptions
        .onMoving.value:
        ContextMenu =
          OnMovingContextMenu
        break
      case BeneficiaryManagerFilterOptions
        .pending.value:
        ContextMenu = PendingContextMenu
        break
      default:
        break
    }

    return (
      <React.Fragment key={index}>
        {item && (
          <BeneficiaryActionRow
            item={item}
            filter={filter}
            contextMenu={
              <ContextMenu
                {...params}
              />
            }
          />
        )}
      </React.Fragment>
    )
  }

export default renderBeneficiaryActionRow
