import _ from 'lodash'
import {
  Null,
  renderSelf,
} from 'views/Shared'
import * as Yup from 'yup'
import { activityValidationSchema } from '../Activity/activitySchemas'
import { donationValidationSchema } from '../Donation/donationSchemas'
import { WishareEntities } from '../enums'
import { givingValidationSchema } from '../Giving/givingSchemas'
import { organizationValidationSchema } from '../Organization/organizationSchemas'
import { recruitmentValidationSchema } from '../Recruitment/recruitmentSchemas'
import { beneficiaryValidationSchema } from '../Beneficiary/beneficiarySchemas'

export const requiredString = (
  t = renderSelf,
  message = 'required field'
) => Yup.string().required(t(message))

export const requiredNumber = (
  t = renderSelf,
  message = 'required field'
) =>
  Yup.number()
    .nullable()
    .required(t(message))

export const yupMoreThan = (
  t = renderSelf,
  message = 'required field',
  target = 0
) =>
  Yup.number()
    .nullable()
    .moreThan(target, t(message))

export const requiredEmail = (
  t = renderSelf,
  message = 'invalid email address'
) =>
  Yup.string()
    .nullable()
    .email(t(message))

export const requiredOneOf = (
  array = [],
  t = renderSelf,
  yup = Yup.string()
) =>
  yup
    .oneOf(array, t('invalid'))
    .required(t('required field'))

export const yupShape = (params) =>
  Yup.object().shape(params)

const createValidationSchema =
  (type, action) => (translate) => {
    let fn = () => {}
    switch (type) {
      case WishareEntities.BENEFICIARY:
        fn = _.get(
          beneficiaryValidationSchema,
          action,
          Null
        )
        break
      case WishareEntities.ACTIVITY:
        fn = _.get(
          activityValidationSchema,
          action,
          Null
        )
        break
      case WishareEntities.DONATION:
        fn = _.get(
          donationValidationSchema,
          action,
          Null
        )
        break
      case WishareEntities.GIVING:
        fn = _.get(
          givingValidationSchema,
          action,
          Null
        )
        break
      case WishareEntities.RECRUITMENT:
        fn = _.get(
          recruitmentValidationSchema,
          action,
          Null
        )
        break
      case WishareEntities.ORGANIZATION:
        fn = _.get(
          organizationValidationSchema,
          action,
          Null
        )
        break
      default:
        break
    }
    return Yup.object().shape(
      fn(translate)
    )
  }

export default createValidationSchema
