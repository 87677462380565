import {DeleteOutlined} from '@ant-design/icons'
import {Button, Input, Result, Space} from 'antd'
import {
  article_createDraftArticle_Api,
  article_deleteDraftArticle_Api,
  article_getDraftArticles_Api,
  article_updateDraftArticle_Api
} from 'apis'
import {articleSchema} from 'apis/schema'
import {createTransform} from 'apis/utils/createTransform'
import ContentContainer from 'components/ContentContainer'
import {CategoriesSelect} from 'components/form/fields/CategoriesSelect'
import FieldsFactory from 'components/form/FieldsFactory'
import {StepsForm} from 'components/form/StepsForm'
import {createValue} from 'components/form/utils'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import Pure from 'components/Pure'
import RichEditor from 'components/RichEditor/RichEditor'
import sampleContent from 'components/RichEditor/sampleContent'
import {SelectEntityItem} from 'components/SelectEntityItem'
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js'
import {Formik, useFormikContext} from 'formik'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import {schema} from 'normalizr'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useToggle from 'react-use/lib/useToggle'
import {compose, mapProps, pure, withProps} from 'recompose'
import {selectEntities} from 'redux/selectors'
import * as Yup from 'yup'
import SettingsLayout from '../Organization/Settings/SettingsLayout'

const initial = {
  id: 'new article',

  title: 'new article',
  description: '',
  editorState: EditorState.createWithContent(
    convertFromRaw(
      JSON.parse(sampleContent)
    )
  )
}
const ArticleForm = ({
  onNext,
  type,
  id
}) => {
  const dispatch = useDispatch()
  const {
    values,
    setValues,
    handleChange,
    resetForm
  } = useFormikContext()
  const t = useTranslate()

  const [unSave, setUnSave] = useState(
    initial
  )

  const contextMenu = useMemo(
    () => (
      <>
        <Space
          className="w-full py-6"
          direction="vertical"
          size="large">
          <div>
            <div className="flex px-2 leading-tight cursor-pointer opacity-75  hover:opacity-100 uppercase text-sm mb-2">
              {t('unsave')}
            </div>
            <div
              onClick={() => {
                resetForm({
                  values: unSave
                })
              }}
              className="px-3 py-2 block text-sm cursor-pointer border-t border-b border-white hover:bg-gray-300 font-bold">
              {t('new article')}
            </div>
          </div>
          <div className="w-full">
            <div className="flex px-2 leading-tight cursor-pointer opacity-75 hover:opacity-100 uppercase text-sm mb-2">
              {t('draft')}
            </div>
            <LazyPagination
              apiInfo={{
                ...article_getDraftArticles_Api,
                transform: createTransform(
                  new schema.Entity(
                    `caches`,
                    {
                      data: [
                        articleSchema
                      ]
                    },
                    {
                      idAttribute: () =>
                        `${type}_${id}__drafts`,
                      processStrategy: e => {
                        e.list = e.data.reduce(
                          (res, v) => {
                            res[v.id] =
                              v.id
                            return res
                          },
                          {}
                        )
                        return e
                      }
                    }
                  ),
                  'data'
                )
              }}
              query={{
                ':prop': type,
                ':id': id
              }}
              renderEmpty={Null}
              renderList={
                Null
              }></LazyPagination>
            <SelectEntityItem
              item={`${type}_${id}__drafts`}
              schema={
                new schema.Entity(
                  `caches`,
                  {},
                  {
                    idAttribute: 'id'
                  }
                )
              }>
              {({
                  data = [],
                  list = {}
              } = {}) => {
                return Object.keys(
                  list
                ).map(item => (
                  <SelectEntityItem
                    key={item}
                    item={item}
                    schema={
                      articleSchema
                    }>
                    {item =>
                      (item &&
                        item.status ===
                        -1 &&
                        !item.deleted && (
                          <div
                            key={item}
                            onClick={() => {
                              resetForm(
                                {
                                  values: {
                                    id:
                                      item.id,
                                    title:
                                      item.title,
                                    description:
                                      item.description,
                                    editorState: EditorState.createWithContent(
                                      convertFromRaw(
                                        JSON.parse(
                                          item.content
                                        )
                                      )
                                    )
                                  }
                                }
                              )
                            }}
                            className="px-3 py-2 truncate text-sm block cursor-pointer border-t border-b border-white hover:bg-gray-300 font-bold">
                            {item.title}
                          </div>
                        )) ||
                      null
                    }
                  </SelectEntityItem>
                ))
              }}
            </SelectEntityItem>
          </div>
        </Space>
      </>
    ),
    [id, resetForm, t, type, unSave]
  )
  const isDeleted = useSelector(state =>
    values.id === 'new article'
      ? false
      : _.get(
        selectEntities(
          state,
          values.id,
          articleSchema,
          {}
        ),
        'deleted'
      )
  )
  const [
    data,
    dispatchAsync
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      onNext()
    }
  })
  return (
    <SettingsLayout
      contextMenu={contextMenu}>
      {isDeleted ? (
        <Result
          status="warning"
          icon={<DeleteOutlined />}
          title={t(
            'Draft had been deleted!'
          )}
          extra={
            <Button
              onClick={() =>
                resetForm({
                  values: unSave
                })
              }
              type="primary">
              {t('Create new')}
            </Button>
          }
        />
      ) : (
        <ContentContainer
          className="md:max-w-2xl lg:max-w-3xl"
          key={values.id}>
          <div className="border-l border-gray-300  font-serif leading-tight">
            <Input.TextArea
              autoComplete="off"
              name="title"
              className="text-4xl focus:outline-none lg:text-5xl border-none focus:outline-none overflow-hidden"
              onChange={handleChange}
              defaultValue={
                values.title
              }
              autoSize
              placeholder="Title"></Input.TextArea>
          </div>
          {values.id && (
            <div className="p-2">
              {!values.id ||
                values.id ===
                'new article' ? (
                <Pure>
                  <RichEditor
                    editorState={
                      unSave.editorState
                    }
                    onChange={editorState => {
                      setUnSave({
                        ...unSave,
                        editorState
                      })
                      handleChange(
                        createValue(
                          'editorState',
                          editorState
                        )
                      )
                    }}
                  />
                </Pure>
              ) : (
                <Pure
                  input={[values.id]}>
                  <RichEditor
                    editorState={
                      values.editorState
                    }
                    onChange={editorState => {
                      handleChange(
                        createValue(
                          'editorState',
                          editorState
                        )
                      )
                    }}
                  />
                </Pure>
              )}
            </div>
          )}
          <div
            style={{
              justifySelf: 'end'
            }}
            className="z-10 mt-8 p-3 sticky items-center bottom-0 flex justify-between background ">
            <div className="flex-1"></div>

            {values.id !==
              'new article' && (
                <Button
                  danger
                  onClick={() =>
                    dispatch(
                      createAsyncAction({
                        apiInfo: article_deleteDraftArticle_Api,
                        query: {
                          ':prop': type,
                          ':id': id,
                          ':article_id':
                            values.id
                        }
                      })
                    )
                  }>
                  {t('delete')}
                </Button>
              )}
            {values.id ===
              'new article' ? (
              <Button
                type="primary"
                className="ml-2 rounded-lg no-shadow no-text-shadow"
                onClick={() => {
                  dispatch(
                    createAsyncAction({
                      apiInfo: {
                        ...article_createDraftArticle_Api,
                        transform: createTransform(
                          new schema.Entity(
                            `caches`,
                            {
                              data: [
                                articleSchema
                              ]
                            },
                            {
                              idAttribute: () =>
                                `${type}_${id}__drafts`,
                              processStrategy: e => {
                                setUnSave(
                                  initial
                                )
                                resetForm(
                                  {
                                    values: {
                                      id:
                                        e.id,
                                      title:
                                        e.title,
                                      description:
                                        e.description,
                                      editorState: EditorState.createWithContent(
                                        convertFromRaw(
                                          JSON.parse(
                                            e.content
                                          )
                                        )
                                      )
                                    }
                                  }
                                )

                                const result = {}
                                result.data = [
                                  e
                                ]
                                result.saved = {
                                  [e.id]:
                                    unSave.eventId
                                }
                                result.list = {
                                  [e.id]:
                                    e.id
                                }
                                return result
                              }
                            }
                          ),
                          'data'
                        )
                      },
                      query: {
                        ':prop': type,
                        ':id': id
                      },
                      values: {
                        title:
                          values.title,
                        description:
                          values.description,
                        content: JSON.stringify(
                          convertToRaw(
                            values.editorState.getCurrentContent()
                          )
                        )
                      }
                    })
                  )
                }}>
                {t('save')}
              </Button>
            ) : (
              <Button
                type="primary"
                className="ml-2 rounded-lg no-shadow no-text-shadow"
                onClick={() => {
                  dispatch(
                    createAsyncAction({
                      apiInfo: article_updateDraftArticle_Api,
                      query: {
                        ':prop': type,
                        ':id': id,
                        ':article_id':
                          values.id
                      },
                      values: {
                        id: values.id,
                        title:
                          values.title,
                        description:
                          values.description,
                        content: JSON.stringify(
                          convertToRaw(
                            values.editorState.getCurrentContent()
                          )
                        )
                      }
                    })
                  )
                }}>
                {t('save')}
              </Button>
            )}
            {values.id ===
              'new article' ? (
              <Button
                type="primary"
                className="ml-2 rounded-lg no-shadow no-text-shadow"
                onClick={() => {
                  dispatchAsync(
                    createAsyncAction({
                      apiInfo: {
                        ...article_createDraftArticle_Api,
                        transform: createTransform(
                          new schema.Entity(
                            `caches`,
                            {
                              data: [
                                articleSchema
                              ]
                            },
                            {
                              idAttribute: () =>
                                `${type}_${id}__drafts`,
                              processStrategy: e => {
                                setUnSave(
                                  initial
                                )
                                resetForm(
                                  {
                                    values: {
                                      id:
                                        e.id,
                                      title:
                                        e.title,
                                      description:
                                        e.description,
                                      editorState: EditorState.createWithContent(
                                        convertFromRaw(
                                          JSON.parse(
                                            e.content
                                          )
                                        )
                                      )
                                    }
                                  }
                                )

                                const result = {}
                                result.data = [
                                  e
                                ]
                                result.saved = {
                                  [e.id]:
                                    unSave.eventId
                                }
                                result.list = {
                                  [e.id]:
                                    e.id
                                }
                                return result
                              }
                            }
                          ),
                          'data'
                        )
                      },
                      query: {
                        ':prop': type,
                        ':id': id
                      },
                      values: {
                        title:
                          values.title,
                        description:
                          values.description,
                        content: JSON.stringify(
                          convertToRaw(
                            values.editorState.getCurrentContent()
                          )
                        )
                      }
                    })
                  )
                }}>
                {t('ready to publish?')}
              </Button>
            ) : (
              <Button
                type="primary"
                className="ml-2 rounded-lg no-shadow no-text-shadow"
                onClick={() => {
                  dispatchAsync(
                    createAsyncAction({
                      apiInfo: article_updateDraftArticle_Api,
                      query: {
                        ':prop': type,
                        ':id': id,
                        ':article_id':
                          values.id
                      },
                      values: {
                        id: values.id,
                        title:
                          values.title,
                        description:
                          values.description,
                        content: JSON.stringify(
                          convertToRaw(
                            values.editorState.getCurrentContent()
                          )
                        )
                      }
                    })
                  )
                }}>
                {t('ready to publish?')}
              </Button>
            )}
          </div>
        </ContentContainer>
      )}
    </SettingsLayout>
  )
}

const formSchema = [
  {
    children: {
      type: 'title',
      name: 'title',
      label: 'title',
      component: compose(
        withTranslate,
        withProps(
          ({ value, translate }) => ({
            name: 'title',
            type: 'text',
            size: 'large',
            placeholder: translate(
              'title'
            )
          })
        )
      )(Input)
    }
  },
  {
    children: {
      type: 'description',
      name: 'description',
      label: 'description',
      component: compose(
        withTranslate,
        mapProps(
          ({
            onChange,
            value,
            translate
          }) => ({
            autosize: {
              minRows: 4,
              maxRows: 8
            },
            type: 'text',
            placeholder: translate(
              'short description'
            ),
            value: value,
            onChange: e =>
              onChange(
                createValue(
                  'description',
                  e.target.value
                )
              )
          })
        )
      )(Input.TextArea)
    }
  },
  {
    children: {
      label: 'categories',
      name: 'categories',
      component: compose(
        mapProps(
          ({
            onChange,
             value = []
          }) => ({
            value,
            onChange: value =>
              onChange(
                createValue(
                  'categories',
                  value
                )
              )
          })
        ),
        pure
      )(CategoriesSelect)
    }
  }
]
const MessageForm = ({
  currentIndex,
  onBack,
  onChange,
  isLoading,
  onNext,
  values,
  setValues,
  ...rest
}) => {
  const t = useTranslate()
  const { editorState } = values
  const imageSrcList = Object.values(
    convertToRaw(
      editorState.getCurrentContent()
    ).entityMap
  )
    .filter(
      ({ type }) => type === 'IMAGE'
    )
    .map(item => _.get(item, 'data'))

  const [isToggle, toggle] = useToggle(
    !values.cover_photo
  )
  useEffect(() => {
    let newvalues = { ...values }
    let update = false
    if (
      !values.title ||
      values.title.length <= 0
    ) {
      const stringArray = editorState
        .getCurrentContent()
        .getPlainText()
        .split('\u000A')
        .filter(
          value => value.length > 0
        )

      newvalues['title'] = _.get(
        stringArray,
        '0',
        ''
      ).trim()
      newvalues['description'] = _.get(
        stringArray,
        '1',
        ''
      )
      update = true
    }
    if (
      !values.cover_photo &&
      imageSrcList &&
      imageSrcList.length > 0
    ) {
      newvalues['cover_photo'] =
        imageSrcList[0].src
      update = true
      toggle(false)
    }
    if (
      imageSrcList &&
      imageSrcList.length > 0
    ) {
      newvalues[
        'photos'
      ] = imageSrcList.map(
        ({ imageId }) => imageId
      )
      update = true
      toggle(false)
    }
    if (update) {
      setValues(newvalues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="p-4 max-w-md  mx-auto verticalList">
        <div className="flex flex-wrap justify-center w-full">
          {isToggle && (
            <div className="flex w-full flex-wrap justify-center background-100">
              <div className="text-center leading-relaxed font-semibold w-full background-200 p-2">
                {t(
                  'select preview photo'
                )}
              </div>
              {logParams(
                imageSrcList
              ).map(({ src }) => (
                <div
                  key={src}
                  style={{
                    padding: '0.5em',
                    width: '100px',
                    height: '100px',
                    textAlign: 'center'
                  }}>
                  {
                    <img
                      onClick={() => {
                        onChange(
                          createValue(
                            'cover_photo',
                            src
                          )
                        )
                        toggle()
                      }}
                      alt={src}
                      className="background-200 w-full h-full object-fill hover:shadow"
                      src={src}
                    />
                  }
                </div>
              ))}
            </div>
          )}
          {!isToggle && (
            <div className="flex w-full flex-wrap justify-center background-100">
              <img
                alt={values.cover_photo}
                className="background-100 w-full "
                src={values.cover_photo}
              />
              <div
                onClick={toggle}
                className="leading-relaxed text-center hover:bg-gray-400 font-bold w-full background-200 p-2">
                {t(
                  'change preview photo'
                )}
              </div>
            </div>
          )}
        </div>
        <FieldsFactory
          formSchema={formSchema}
        />
        <div className="text-color-400">
          <span className="font-bold">
            Note:
          </span>{' '}
          {t(
            "changes here will affect how your story appears in public places like Article's homepage - not the story itself."
          )}
        </div>
        <div
          style={{
            justifySelf: 'end'
          }}
          className="z-10 mt-8 p-3 sticky items-center bottom-0 flex justify-between background ">
          <div className="flex-1"></div>

          <Button
            className="ml-2 rounded-lg no-shadow no-text-shadow"
            disabled={
              currentIndex === 0
            }
            onClick={onBack}>
            {t('back')}
          </Button>
          <Button
            loading={isLoading}
            className="ml-2 rounded-lg no-shadow no-text-shadow"
            type="primary"
            onClick={onNext}>
            {t('publish')}
          </Button>
        </div>
      </div>
    </>
  )
}

const createArticleRoutes = [
  {
    path: '/questtionAndAnswers',
    component: ArticleForm,
    exact: true
  },
  {
    path: '/message',
    component: MessageForm,
    exact: true
  }
]
export default function CreateArticle({
  type,
  id,
  onSubmit = Null,
  onCancel = Null,
  onDraft = Null,
  onUpdateDraft = Null,
  isLoading
}) {
  const translate = useTranslate()
  const initialValues = useMemo(
    () => ({
      id: 'new article',
      title: '',
      description: '',
      editorState: EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(sampleContent)
        )
      )
    }),
    []
  )
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required(
          translate('required field')
        ),
        description: Yup.string().required(
          translate('required field')
        )
      }),
    [translate]
  )
  return (
    <FullSideModalLayout
      title={translate(
        'create article'
      )}
      full
      onCancel={onCancel}>
      <StepsForm
        isLoading={false}
        routes={createArticleRoutes}
        initialValues={initialValues}
        validationSchema={
          validationSchema
        }
        renderBottom={Null}
        onSubmit={({
          editorState,
          categories,
          ...rest
        }) => {
          onSubmit({
            ...rest,
            categories,
            content: JSON.stringify(
              convertToRaw(
                editorState.getCurrentContent()
              )
            )
          })
        }}
        onUpdateDraft={onUpdateDraft}
        onDraft={onDraft}
        {...{ type, id }}></StepsForm>
    </FullSideModalLayout>
  )
}
export function EditArticle({
  onSubmit = Null,
  onCancel = Null,
  editorContent,
  isLoading
}) {
  const t = useTranslate()
  return (
    <FullSideModalLayout
      title={t('edit article')}
      full
      onCancel={onCancel}>
      <Formik
        validationSchema={Yup.object().shape(
          {
            title: Yup.string().required(
              'required field'
            ),
            description: Yup.string().required(
              'required field'
            )
          }
        )}
        onSubmit={({
          editorState,
          ...rest
        }) => {
          onSubmit({
            content: JSON.stringify(
              convertToRaw(
                editorState.getCurrentContent()
              )
            )
          })
        }}
        initialValues={{
          editorState: EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(editorContent)
            )
          )
        }}>
        {({
          handleChange,
          values,
          ...rest
        }) => (
          <ArticleForm
            values={values}
            onCancel={onCancel}
            onChange={handleChange}
            {...rest}
          />
        )}
      </Formik>
    </FullSideModalLayout>
  )
}
