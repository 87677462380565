import { Avatar } from 'antd'
import { medal_getMedalRewardsOfOrganization_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, { useMemo } from 'react'
import { IoMedalOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import { Null } from 'views/Shared'
import { MedalRewardsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import { MedalRewardsHisotryAdvancedFilter } from './MedalRewardsAdvancedFilter'

const renderItemReward =
  (params) => (item) => {
    return (
      <Translate>
        {(t) => (
          <div className="flex items-center gap-2 border border-color-50 rounded-lg p-3">
            <Avatar
              src={_.get(
                item,
                'medal.avatar'
              )}
              icon={
                <IoMedalOutline className="text-color-300" />
              }
              className="flex flex-center background-100"
            />
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-color-000 leading-tight">
                {_.get(
                  item,
                  'medal.title'
                )}
              </span>
              <div className="flex items-baseline gap-1 text-xs">
                <span className="text-2xs italic font-light text-color-400">
                  {t('receiver')}
                  {':'}
                </span>
                <span className="italic font-medium text-secondary">
                  {t(
                    _.get(
                      item,
                      'owner._type'
                    )
                  )}
                </span>
                {' - '}
                <Link
                  to={getLinkToDetail(
                    _.get(item, 'owner')
                  )}
                  className="font-medium text-color-100">
                  {_.get(
                    item,
                    'owner.name'
                  ) ||
                    _.get(
                      item,
                      'owner.title'
                    )}
                </Link>
              </div>
              <div className="flex items-baseline gap-1 text-xs">
                <span className="text-2xs italic font-light text-color-400">
                  {t('effect date')}
                  {':'}
                </span>
                <span className="font-medium text-color-200">
                  {_.get(
                    item,
                    'eff_date'
                  )}
                </span>
              </div>
              {_.get(item, 'notes') && (
                <div className="flex items-baseline gap-1 text-xs">
                  <span className="text-2xs italic font-light text-color-400">
                    {t('notes')}
                    {':'}
                  </span>
                  <span className="font-medium text-color-200">
                    {_.get(
                      item,
                      'notes'
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </Translate>
    )
  }

const WorkspaceMedalRewardsHistory = ({
  owner_id,
  owner_type,
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    to_date: 'to-date',
    from_date: 'from-date',
  },
  ...props
}) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const filter_to_date = useSearchParam(
    filter_actions.to_date
  )

  const filter_from_date =
    useSearchParam(
      filter_actions.from_date
    )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          from_date: filter_from_date,
          to_date: filter_to_date,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          medal_getMedalRewardsOfOrganization_Api,
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        renderItem: renderItemReward(),
        RenderEntity: React.Fragment,
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
      ],
      SortByWidget: MedalRewardsSortBy,
      AdvancedFilter:
        MedalRewardsHisotryAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="history medal rewards" />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(WorkspaceMedalRewardsHistory)
