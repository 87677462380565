import {medal_getMedalRewardsOfOwner_api} from 'apis'
import _ from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useLocation, useSearchParam,} from 'react-use'
import renderMedalReward from 'views/MainPage/WorkSpace/Medal/renderMedalReward'
import {Null} from 'views/Shared'
import {MedalRewardsSortBy} from 'views/Wishare/factory/createEntitySortBy'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import {ItemContextMenu} from "../../../Wishare/factory/createContextMenu";
import useAsyncAction from "../../../../modules/asyncCache/useAsyncAction";
import {medal_setMedalRewardVisible_Api} from "../../../../apis";
import {notifyOnError} from "../../../Wishare/factory/createErrorEvent";
import {NotificationActionTypes, successNotify} from "../../../Wishare/factory/createNotification";
import useTranslate from "../../../../modules/local/useTranslate";
import {useHistory} from "../../../../modules/navigation/useRouter";

const ContextMenuTypes = Object.freeze({
  VISIBLE: {
    key: 'visible',
    label: 'visible',
    value: 'visible',
  },
  INVISIBLE: {
    key: 'invisible',
    label: 'invisible',
    value: 'invisible',
  },
})

const MedalsByOwner = ({
  owner_id,
  owner_type,
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
  ...props
}) => {
  const t = useTranslate();
  const history = useHistory();
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const {
    handleAsyncAction: toggleReward,
  } = useAsyncAction({
    apiInfo:
    medal_setMedalRewardVisible_Api,
    onError: notifyOnError(t),
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      history.push({
        search: location.search,
        state: {
          ...(location.state || {}),
          refreshToken: Date.now(),
        },
      })
    },
  })

  const onToggle = (
    key,
    medal_reward
  ) => {
    const medal_reward_id = _.get(
      medal_reward,
      'id'
    )
    switch (key) {
      case ContextMenuTypes.VISIBLE.key:
        toggleReward(
          {
            visible: 1,
          },
          {
            medal_reward_id,
          }
        )
        break
      case ContextMenuTypes.INVISIBLE
        .key:
        toggleReward(
          {
            visible: 0,
          },
          {
            medal_reward_id,
          }
        )
        break
      default:
        break
    }
  }

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )


  const renderContextMenu = useCallback(
    (medal_reward) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onToggle(key, medal_reward)
          }
          items={[
            !!_.get(
              medal_reward,
              'visible'
            )
              ? ContextMenuTypes.INVISIBLE
              : ContextMenuTypes.VISIBLE,
          ]}
        />
      </div>
    ),
    []
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          medal_getMedalRewardsOfOwner_api,
        query: bindQueryParams([
          {
            owner_id,
          },
          {
            owner_type,
          },
        ]),
        renderItem: renderMedalReward({
          showOwner: true,
          showStatus: true,
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({ sortBy, keyword }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
      ],
      renderFilter: Null,
      SortByWidget: MedalRewardsSortBy,
      AdvancedFilter: Null,
      ...props,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="medal list" />
      {pager_widget}
    </Wrapper>
  )
}

export default MedalsByOwner
