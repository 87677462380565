import React from 'react'

export const FlagKOSvg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="1rem"
      height="1rem"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <circle cx="256" cy="256" r="256" fill="#F0F0F0"></circle>
      <path
        fill="#D80027"
        d="M345.043 256c0 22.261-39.866 77.913-89.043 77.913S166.957 278.261 166.957 256c0-49.178 39.866-89.043 89.043-89.043s89.043 39.865 89.043 89.043z"
      ></path>
      <path
        fill="#0052B4"
        d="M345.043 256c0 49.178-39.866 89.043-89.043 89.043S166.957 305.178 166.957 256"
      ></path>
      <path
        d="M353.427 319.639H386.81800000000004V341.90000000000003H353.427z"
        transform="rotate(-45.001 370.12 330.776)"
      ></path>
      <path
        d="M314.07 358.994H347.461V381.25500000000005H314.07z"
        transform="rotate(-45.001 330.763 370.13)"
      ></path>
      <path
        d="M400.656 366.851H434.047V389.112H400.656z"
        transform="rotate(-45.001 417.35 377.989)"
      ></path>
      <path
        d="M361.299 406.213H394.69V428.47400000000005H361.299z"
        transform="rotate(-45.001 377.992 417.35)"
      ></path>
      <path
        d="M377.04 343.247H410.43100000000004V365.50800000000004H377.04z"
        transform="rotate(-45.001 393.733 354.384)"
      ></path>
      <path
        d="M337.694 382.602H371.08500000000004V404.863H337.694z"
        transform="rotate(-45.001 354.387 393.738)"
      ></path>
      <path
        d="M329.544 126.805H418.587V149.066H329.544z"
        transform="rotate(-134.999 374.067 137.936)"
      ></path>
      <path
        d="M314.081 130.749H347.47200000000004V153.009H314.081z"
        transform="rotate(-134.999 330.778 141.88)"
      ></path>
      <path
        d="M353.427 170.098H386.81800000000004V192.358H353.427z"
        transform="rotate(-134.999 370.124 181.23)"
      ></path>
      <path
        d="M361.282 83.526H394.673V105.78699999999999H361.282z"
        transform="rotate(-134.999 377.98 94.658)"
      ></path>
      <path
        d="M400.651 122.879H434.04200000000003V145.14000000000001H400.651z"
        transform="rotate(-134.999 417.348 134.01)"
      ></path>
      <path
        d="M69.81 386.542H158.853V408.803H69.81z"
        transform="rotate(45.001 114.336 397.673)"
      ></path>
      <path
        d="M140.919 382.6H174.31V404.86H140.919z"
        transform="rotate(45.001 157.62 393.73)"
      ></path>
      <path
        d="M101.569 343.252H134.96V365.51300000000003H101.569z"
        transform="rotate(45.001 118.269 354.382)"
      ></path>
      <path
        d="M117.031 339.316H206.074V361.577H117.031z"
        transform="rotate(45.001 161.557 350.446)"
      ></path>
      <path
        d="M69.81 103.201H158.853V125.46199999999999H69.81z"
        transform="rotate(134.999 114.332 114.331)"
      ></path>
      <path
        d="M93.412 126.806H182.455V149.067H93.412z"
        transform="rotate(134.999 137.934 137.937)"
      ></path>
      <path
        d="M117.038 150.428H206.08100000000002V172.688H117.038z"
        transform="rotate(134.999 161.56 161.558)"
      ></path>
    </svg>
  );
}
