import {Badge, Button, Input} from 'antd'
import {referral_getReferralCode_Api} from 'apis'
import {channelModel} from 'apis/model'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {UpdateReferalCode} from 'components/UpdateReferalCode'
import copyToClipboard from 'copy-to-clipboard'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext, useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import LoadingPage from 'views/LoadingPage'
import ServerContext from 'views/Server/ServerContext'

const Invite = ({ item: i }) => {
  const t = useTranslate()
  const { item } = useContext(
    ServerContext
  )
  const [isToggle, toggle] = useToggle(
  )
  const [
    isToggleCopy2,
    toggleCopy2
  ] = useToggle(false)
  const { response } = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: (i || item).id,
      target_type: (i || item)._type
    }
  })
  useLayoutEffect(() => {
    let timeout
    if (isToggle) {
      timeout = setTimeout(
        () => toggle(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggle, toggle])

  useLayoutEffect(() => {
    let timeout
    if (isToggleCopy2) {
      timeout = setTimeout(
        () => toggleCopy2(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggleCopy2, toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage />
  const inviteLink =
    ROOT_URL +
    '/invite/' +
    _.get(data, 'referral_code')
  const timeout = moment(
    _.get(data, 'created')
  )
    .add(
      _.get(data, 'timeout'),
      'second'
    )
    .fromNow()
  return (
    <div className="flex flex-col py-3 px-5 pb-5 verticalList__large">
        <UpdateReferalCode referral={data} />
        <div className="p-3 w-full flex items-center gap-3 rounded-lg border border-color-50">
            <div className="rounded-full background-100 flex flex-center"
                 style={{width: 70, height: 70,}}>
                <ChannelIcon
                    premium={item.premium}
                    className="text-4xl text-color-400"
                />
            </div>
            <div className="flex-1">
                <Link to={channelModel.getLinkToDetail(item)}
                      className="font-bold text-lg leading-tight no-underline">
                    {item.title}
                </Link>
                <div className="w-fit flex items-center rounded-lg px-3 py-1 background-100 mt-2">
                    <Badge
                        color="blue"
                        text={
                            <span className="italic font-light text-sm text-color-300">
                                <span className="font-semibold text-color-000">{item.total_members}</span>
                                {' '}{t('member')}
                            </span>
                        }
                    />
                </div>
            </div>
        </div>
        {item.description && (
            <div className="flex flex-col gap-1">
                <div className="border-b font-semibold text-sm text-color-300 uppercase">
                    {t('introduce channel')}
                </div>
                <div className="text-xs text-color-300 italic">
                    {item.description}
                </div>
            </div>
        )}
        <div className="pb-3">
            <div className="mb-1 font-light text-sm text-color-200 italic">
                {t('Send invitation to join the channel to your friends, by sending a copy of the page link below')}
            </div>
            <div
                onClick={() => {
                    copyToClipboard(inviteLink)
                    toggle(true)
                }}>
                <Input
                    size="large"
                    value={inviteLink}
                    suffix={
                        <Button
                            size="small"
                            className="text-xs no-shadow no-text-shadow rounded-md"
                            type="primary">
                            {t(
                                isToggle
                                    ? 'copied'
                                    : 'copy'
                            )}
                        </Button>
                    }
                />
            </div>
            <div className="mt-1 mb-2 text-xs text-color-500 italic">
                {_.get(data, 'timeout', 0) ===
                0 ||
                _.get(
                    data,
                    'no_expired',
                    0
                ) === 1
                    ? t('Permanent link')
                    : `${t(
                        'your invite link expires'
                    )} ${timeout}`}
            </div>
            <div className="flex flex-col gap-1 mt-2">
              <span className="font-light text-sm text-color-200 italic">
                  {t('Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel')}
              </span>
                <div
                    onClick={() => {
                        copyToClipboard(
                            ROOT_URL +
                            channelModel.getLinkToDetail(
                                item
                            )
                        )
                        toggleCopy2(true)
                    }}>
                    <Input
                        value={
                            ROOT_URL +
                            channelModel.getLinkToDetail(
                                item
                            )
                        }
                        suffix={
                            <Button
                                size="small"
                                className="text-xs no-shadow no-text-shadow rounded-md"
                                type="primary">
                                {t(
                                    isToggleCopy2
                                        ? 'copied'
                                        : 'copy'
                                )}
                            </Button>
                        }
                    />
                </div>
            </div>
        </div>
    </div>
  )
}
export default Invite



