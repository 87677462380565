import { Input } from 'antd'
import { VideoEmbed } from 'components/Feed/VideoEmbed'
import React from 'react'
import ReactPlayer from 'react-player'

const VideoField = ({
  data,
  name,
  value,
  onChange,
  ...props
}) => {
  const {
    title,
    subTitle,
    description,
    ...rest
  } = data || {}
  return (
    <div className="flex flex-col gap-2">
      <Input
        {...props}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={
          'https://www.youtube.com'
        }
      />
      {ReactPlayer.canPlay(value) && (
        <VideoEmbed
          {...{
            title,
            subTitle,
            description,
          }}
          url={value}
          style={{
            background:
              'var(--background)',
          }}
          isShowPlayer={true}
          {...rest}
        />
      )}
    </div>
  )
}

export default VideoField
