import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { usePrevious } from 'react-use'
import { addGlobalAmChartConfig } from '../../../funcs/addGlobalAmChartConfig'
import { addWatermarkLogoConfig } from '../../../funcs/addWatermarkLogoConfig'

export const AmChartStateless = ({
  item,
  theme,
  setChartWrap,
  viewmode = viewModes.BLOCK,
  ...props
}) => {
  const t = useTranslate()
  const [chart, setChart] = useState()
  const [data, setData] = useState()
  const [cParams, setCParams] =
    useState(null)
  const [id] = useState(
    _.uniqueId('chart_' + item.id)
  )

  const { isSm } = useContext(
    LayoutContext
  )
  const _chartSize = _.get(
    props,
    'size',
    isSm || viewmode === viewModes.BLOCK
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  useEffect(() => {
    if (chart) return () => {}
    let thisChart
    let groupData = []
    let chartParams = item.params
    if (!chartParams) return () => {}
    chartParams = JSON.parse(
      JSON.stringify(chartParams)
    )
    let data_values = []
    switch (item.chart_type) {
      case 'SortBarChart':
        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        thisChart.series.values[0].columns.template.adapter.add(
          'fill',
          function (fill, target) {
            if (
              target.dataItem &&
              target.dataItem.valueX < 0
            ) {
              return '#a55'
            } else {
              return fill
            }
          }
        )

        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'dx',
          function (defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? -10
              : 10
          }
        )
        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'horizontalCenter',
          function (defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 'right'
              : 'left'
          }
        )

        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusBottomLeft',
          function (radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 5
              : 0
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusTopLeft',
          function (radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 5
              : 0
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusBottomRight',
          function (radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 0
              : 5
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusTopRight',
          function (radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 0
              : 5
          }
        )

        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'dx',
          function (defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? -10
              : 10
          }
        )
        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'horizontalCenter',
          function (defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 'right'
              : 'left'
          }
        )

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      case 'BubbleChart':
        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        thisChart.series.values[0].bullets.values[0].events.on(
          'over',
          function (event) {
            let target = event.target
            thisChart.plotContainer.children.values[0].radius =
              target.pixelRadius + 2
            thisChart.plotContainer.children.values[0].x =
              target.pixelX
            thisChart.plotContainer.children.values[0].y =
              target.pixelY
            thisChart.plotContainer.children.values[0].show()
          }
        )

        thisChart.series.values[0].bullets.values[0].adapter.add(
          'fill',
          function (fill, target) {
            return thisChart.colors.getIndex(
              target.dataItem.index
            )
          }
        )

        thisChart.series.values[0].bullets.values[0].events.on(
          'out',
          function (event) {
            thisChart.plotContainer.children.values[0].hide()
          }
        )

        let hoverState0 =
          thisChart.series.values[0].bullets.values[0].states.create(
            'hover'
          )
        hoverState0.properties.fillOpacity = 1
        hoverState0.properties.strokeOpacity = 1

        thisChart.series.values[0].heatRules.push(
          {
            target:
              thisChart.series.values[0]
                .bullets.values[0],
            min: 2,
            max: 60,
            property: 'radius',
          }
        )

        thisChart.series.values[0].bullets.values[0].adapter.add(
          'tooltipY',
          function (tooltipY, target) {
            return -target.radius
          }
        )

        thisChart.series.values[0].bullets.values[1].adapter.add(
          'dy',
          function (dy, bullet) {
            if (
              bullet.dataItem
                .dataContext
                .showLabel == 1
            ) {
              bullet.visible = true
              bullet.label.text =
                bullet.dataItem.dataContext.title
            } else {
              bullet.label.text = ''
              bullet.visible = false
            }
            return dy
          }
        )

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      case 'BarChartRace':
        let stepDuration1 = 4000
        let dataIndex1 = 0
        let interval1
        let group_id
        let label1
        let playButton1
        const groupByKey = _.find(
          item.data_columns,
          ['group', true]
        )
        groupData = data_values.reduce(
          (r, a) => {
            r[a[groupByKey.id]] = [
              ...(r[a[groupByKey.id]] ||
                []),
              a,
            ]
            return r
          },
          {}
        )

        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        label1 =
          thisChart.plotContainer.createChild(
            am4core.Label
          )
        label1.x = am4core.percent(97)
        label1.y = am4core.percent(95)
        label1.horizontalCenter =
          'right'
        label1.verticalCenter = 'middle'
        label1.dx = -15
        label1.fontSize = 50

        playButton1 =
          thisChart.plotContainer.createChild(
            am4core.PlayButton
          )
        playButton1.x =
          am4core.percent(97)
        playButton1.y =
          am4core.percent(95)
        playButton1.dy = -2
        playButton1.verticalCenter =
          'middle'
        playButton1.events.on(
          'toggled',
          function (event) {
            if (event.target.isActive) {
              play1()
            } else {
              stop1()
            }
          }
        )

        thisChart.xAxes.values[0].rangeChangeEasing =
          am4core.ease.linear
        thisChart.xAxes.values[0].rangeChangeDuration =
          stepDuration1

        thisChart.series.values[0].interpolationDuration =
          stepDuration1
        thisChart.series.values[0].interpolationEasing =
          am4core.ease.linear

        thisChart.series.values[0].columns.template.adapter.add(
          'fill',
          function (fill, target) {
            return thisChart.colors.getIndex(
              target.dataItem.index
            )
          }
        )

        group_id =
          Object.keys(groupData)[
            dataIndex1
          ]
        label1.text = group_id

        const play1 = () => {
          interval1 = setInterval(
            function () {
              nextStep1()
            },
            stepDuration1
          )
          nextStep1()
        }

        const stop1 = () => {
          if (interval1) {
            clearInterval(interval1)
          }
        }

        const nextStep1 = () => {
          group_id =
            Object.keys(groupData)[
              dataIndex1
            ]

          let newData =
            groupData[group_id]
          let itemsWithNonZero = 0
          let _data
          _data = thisChart.data || []
          for (
            let i = 0;
            i < _data.length;
            i++
          ) {
            const newItem = _.find(
              newData,
              function (e) {
                return (
                  e.id.trim() ===
                  _data[i].id.trim()
                )
              }
            )
            if (!_.isEmpty(newItem)) {
              _data[i].value =
                newItem.value
            }

            if (_data[i].value > 0) {
              itemsWithNonZero++
            }
          }
          for (
            let i = 0;
            i < newData.length;
            i++
          ) {
            const newItem = _.find(
              _data,
              function (e) {
                return (
                  e.id.trim() ===
                  newData[i].id.trim()
                )
              }
            )
            if (!_.isEmpty(newItem)) {
              thisChart.data.push(
                newData[i]
              )

              itemsWithNonZero++
            }
          }

          if (dataIndex1 === 0) {
            thisChart.series.values[0].interpolationDuration =
              stepDuration1 / 4
            thisChart.xAxes.values[0].rangeChangeDuration =
              stepDuration1 / 4
          } else {
            thisChart.series.values[0].interpolationDuration =
              stepDuration1
            thisChart.xAxes.values[0].rangeChangeDuration =
              stepDuration1
          }

          thisChart.invalidateRawData()
          label1.text = group_id

          thisChart.xAxes.values[0].zoom(
            {
              start: 0,
              end:
                itemsWithNonZero /
                thisChart.xAxes
                  .values[0].dataItems
                  .length,
            }
          )

          dataIndex1++
          if (
            dataIndex1 >=
            Object.keys(groupData)
              .length
          ) {
            dataIndex1 = 0
            stop1()
          }
        }

        thisChart.yAxes.values[0].sortBySeries =
          thisChart.series.values[0]

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        if (!_.isEmpty(groupData)) {
          thisChart.data = JSON.parse(
            JSON.stringify(
              groupData[group_id]
            )
          )
          thisChart.xAxes.values[0].zoom(
            {
              start: 0,
              end:
                1 /
                thisChart.data.length,
            }
          )
        }

        break
      case 'XYChart':
        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              responsive: {
                enabled: true,
              },
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        if (
          thisChart.series.values
            .length === 1 &&
          thisChart.series.values[0]
            .columns &&
          thisChart.series.values[0]
            .columns.template &&
          !(
            thisChart.series.values[0]
              .columns.template
              .propertyFields &&
            thisChart.series.values[0]
              .columns.template
              .propertyFields.fill
          )
        ) {
          thisChart.series.values[0].columns.template.adapter.add(
            'fill',
            function (fill, target) {
              return thisChart.colors.getIndex(
                target.dataItem.index
              )
            }
          )
        }

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        // thisChart.data = data_values;

        break
      case 'PivotStackedColumnChart':
        let columnSeries = {
          type: 'ColumnSeries',
          id: 'id',
          name: 'name',
          dataFields: {
            valueY: 'valueY',
            categoryX: 'time',
          },
          sequencedInterpolation: true,
          stacked: true,
          columns: {
            template: {
              width: '80%',
              tooltipText:
                '[bold]{name}[/]\n[font-size:0.68rem]{categoryX}: {valueY}',
            },
          },
        }
        if (
          chartParams &&
          chartParams.series &&
          chartParams.series.length > 0
        ) {
          columnSeries =
            chartParams.series[0]
        }
        chartParams.series = []

        const createSeries = (
          chartParams,
          series,
          field
        ) => {
          if (chartParams) {
            let _s = _.cloneDeep(series)
            _s.id = field
            _s.name = field

            if (
              _.has(
                _s,
                'dataFields.valueX'
              )
            )
              _s.dataFields.valueX =
                field
            if (
              _.has(
                _s,
                'dataFields.valueY'
              )
            )
              _s.dataFields.valueY =
                field

            chartParams.series.push(_s)
          }
        }

        _.get(
          item,
          'data_columns',
          []
        ).map((c) => {
          if (c.valueType === 'value') {
            createSeries(
              chartParams,
              columnSeries,
              c.id
            )
          }
        })

        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              responsive: {
                enabled: true,
              },
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        break
      case 'PivotXYChart':
        // chartParams =
        // LOOP PIVOT COLUMN TO SERIES
        if (
          chartParams.series &&
          chartParams.series.length > 0
        ) {
          let _ind = 0
          let origSeries = _.cloneDeep(
            chartParams.series
          )
          _.forEach(
            item.data_columns,
            (e, i) => {
              if (
                typeof e === 'object' &&
                e.valueType === 'value'
              ) {
                let _origS =
                  _.cloneDeep(
                    origSeries[0]
                  )
                _origS =
                  JSON.stringify(_origS)
                _origS = (
                  _origS || ''
                ).replaceAll(
                  'pivot_column',
                  e.id
                )
                _origS =
                  JSON.parse(_origS)
                if (
                  _origS.dataFields
                    .valueX
                )
                  _origS.dataFields.valueX =
                    e.id
                if (
                  _origS.dataFields
                    .openValueX
                )
                  _origS.dataFields.openValueX =
                    e.id
                if (
                  _origS.dataFields
                    .valueY
                )
                  _origS.dataFields.valueY =
                    e.id
                if (
                  _origS.dataFields
                    .openValueY
                )
                  _origS.dataFields.openValueY =
                    e.id

                chartParams.series[
                  _ind
                ] = {
                  ..._origS,
                  name: t(e.id),
                  id: 's' + _ind,
                }
                _ind += 1
              } else if (
                typeof e === 'object' &&
                e.valueType ===
                  'valueObj'
              ) {
                let _origSeries =
                  _.cloneDeep(
                    origSeries
                  )
                _origSeries =
                  JSON.stringify(
                    _origSeries
                  )
                _origSeries =
                  _origSeries.replaceAll(
                    'pivot_column',
                    e.id
                  )
                _origSeries =
                  JSON.parse(
                    _origSeries
                  )

                _.flatMap(
                  _origSeries,
                  (origS) => {
                    let _origS =
                      _.cloneDeep(origS)
                    if (
                      _origS.dataFields
                        .valueX
                    )
                      _origS.dataFields.valueX =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .valueX
                    if (
                      _origS.dataFields
                        .openValueX
                    )
                      _origS.dataFields.openValueX =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .openValueX
                    if (
                      _origS.dataFields
                        .valueY
                    )
                      _origS.dataFields.valueY =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .valueY
                    if (
                      _origS.dataFields
                        .openValueY
                    )
                      _origS.dataFields.openValueY =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .openValueY

                    chartParams.series[
                      _ind
                    ] = {
                      ..._origS,
                      name: t(e.id),
                      id: 's' + _ind,
                    }
                    _ind += 1
                  }
                )
              }
            }
          )

          // setCParams(chartParams);
        }

        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              responsive: {
                enabled: true,
              },
              tapToActivate: true,
              tapTimeout: 3000,
              ...chartParams,
            },
            id,
            am4charts.XYChart
          )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        if (
          thisChart.series.values
            .length === 1 &&
          thisChart.series.values[0]
            .columns &&
          thisChart.series.values[0]
            .columns.template
        ) {
          thisChart.series.values[0].columns.template.adapter.add(
            'fill',
            function (fill, target) {
              return thisChart.colors.getIndex(
                target.dataItem.index
              )
            }
          )
        }

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      case 'SunburstChart':
        thisChart =
          am4core.createFromConfig(
            {
              width: '100%',
              height: '100%',
              responsive: {
                enabled: true,
              },
              ...chartParams,
              tapToActivate: true,
              tapTimeout: 3000,
            },
            id,
            am4plugins_sunburst.Sunburst
          )
        thisChart.padding(0, 0, 0, 0)
        thisChart.margin(0, 0, 0, 0)
        thisChart.radius =
          am4core.percent(80)

        thisChart.colors.step = 2
        thisChart.fontSize = 11
        thisChart.innerRadius =
          am4core.percent(10)

        thisChart.dataFields.value =
          'value'
        thisChart.dataFields.name =
          'name'
        thisChart.dataFields.children =
          'children'

        if (
          !chartParams?.seriesTemplates
        ) {
          let level0SeriesTemplate =
            new am4plugins_sunburst.SunburstSeries()
          level0SeriesTemplate.hiddenInLegend = false
          thisChart.seriesTemplates.setKey(
            '0',
            level0SeriesTemplate
          )

          // this makes labels to be hidden if they don't fit
          level0SeriesTemplate.labels.template.truncate = true
          level0SeriesTemplate.labels.template.hideOversized = true

          // level0SeriesTemplate.labels.template.adapter.add(
          //   'rotation',
          //   function (
          //     rotation,
          //     target
          //   ) {
          //     target.maxWidth =
          //       target.dataItem.slice
          //         .radius -
          //       target.dataItem.slice
          //         .innerRadius -
          //       10
          //     target.maxHeight =
          //       Math.abs(
          //         ((target.dataItem
          //           .slice.arc *
          //           (target.dataItem
          //             .slice
          //             .innerRadius +
          //             target.dataItem
          //               .slice
          //               .radius)) /
          //           2) *
          //           am4core.math.RADIANS
          //       )

          //     return rotation
          //   }
          // )

          let level1SeriesTemplate =
            level0SeriesTemplate.clone()
          level1SeriesTemplate.fillOpacity = 0.75
          level1SeriesTemplate.hiddenInLegend = true
          thisChart.seriesTemplates.setKey(
            '1',
            level1SeriesTemplate
          )

          let level2SeriesTemplate =
            level0SeriesTemplate.clone()
          level2SeriesTemplate.fillOpacity = 0.5
          level2SeriesTemplate.hiddenInLegend = true
          thisChart.seriesTemplates.setKey(
            '2',
            level2SeriesTemplate
          )
        }

        thisChart.legend =
          new am4charts.Legend()
        if (!isSm) {
          thisChart.legend.maxWidth = 240
          thisChart.legend.position =
            'right'
        } else {
          thisChart.legend.maxHeight = 50
        }
        // thisChart.legend.scrollable = true
        // thisChart.legend.valueLabels.template.disabled = true

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      default:
        break
    }
    if (thisChart) {
      if (thisChart.logo) {
        thisChart.logo.disabled = true
      }
      thisChart.svgContainer.autoResize = false
      thisChart.exporting.useWebFonts = false
      thisChart.tapToActivate = true
      thisChart.tapTimeout = 5000
      thisChart.dragGrip.disabled = false
      setChartWrap({
        getChart: () => thisChart,
        type: 'amchart',
      })
      setChart(thisChart)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _chartSize,
    chart,
    id,
    isSm,
    item,
    // setChartWrap
  ])
  const predata = usePrevious(data)
  useEffect(() => {
    if (!item) return null
    const data_values = (() => {
      let data_values = item.data_values
      const data_types = _.get(
        item,
        'data_columns',
        []
      ).map((c) => c.type || 'string')

      return data_values
    })()

    if (
      JSON.stringify(predata) !==
      JSON.stringify(data_values)
    ) {
      setData(data_values)
    }
  }, [
    item.data_columns,
    item.data_encrypted,
    item.data_passphrase,
    item.data_values,
    predata,
  ])

  useEffect(() => {
    if (chart && data) {
      chart.data = data
      if (
        chart &&
        chart.svgContainer &&
        !!!chart.svgContainer.autoResize
      )
        chart.svgContainer.autoResize = true
    }
    // console.log({
    //   count: (() => {
    //     window.count = window.count || 0
    //     window.count = window.count + 1
    //     return window.count
    //   })()
    // })
  }, [chart, data])
  useEffect(() => {
    return () => {
      if (chart) {
        setTimeout(() =>
          chart.dispose()
        )
      }
    }
  }, [chart])

  return useMemo(
    () => (
      <div
        className="flex-1"
        style={{
          width: '100%',
          height: '100%',
          minHeight: '200px',
        }}
        id={id}></div>
    ),
    [id]
  )
}

export default AmChartStateless
