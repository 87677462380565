import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import ActivityContext from '../ActivityContext'

const ActivitySettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.activitySettings
  return createSettingContent(
    ActivityContext,
    { routes, Wrapper, rootPath }
  )
}

export default ActivitySettingsContent
