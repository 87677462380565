import React from 'react';

export const ImageSvg = () => (
	<svg
		width="1em"
		height="1em"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		version="1.1"
		id="Capa_1"
		x="0px"
		y="0px"
		viewBox="0 0 58 58"
		style={{
			enableBackground: 'new 0 0 58 58'
		}}
		xmlSpace="preserve">
		<rect
			x={1}
			y={7}
			style={{
				fill: '#C3E1ED',
				stroke: '#E7ECED',
				strokeWidth: 2,
				strokeMiterlimit: 10
			}}
			width={56}
			height={44}
		/>
		<circle
			style={{ fill: '#ED8A19' }}
			cx={16}
			cy="17.569"
			r="6.569"
		/>
		<polygon
			style={{ fill: '#1A9172' }}
			points="56,36.111 55,35 43,24 32.5,35.5 37.983,40.983 42,45 56,45 "
		/>
		<polygon
			style={{ fill: '#1A9172' }}
			points="2,49 26,49 21.983,44.983 11.017,34.017 2,41.956 "
		/>
		<rect
			x={2}
			y={45}
			style={{ fill: '#6B5B4B' }}
			width={54}
			height={5}
		/>
		<polygon
			style={{ fill: '#25AE88' }}
			points="37.983,40.983 27.017,30.017 10,45 42,45 "
		/>
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
	</svg>
);
