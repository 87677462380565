import {
  Avatar,
  Divider,
  Image,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { WishareRibbonSvg } from 'components/Svgs/WishareRibbonSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import {
  WishareFollowButton,
  WishareVoteButton,
} from 'views/Wishare/custom/ToggleActionButton'
import ActivityContext from '../ActivityContext'
import {ROOT_URL} from "../../../../envs/_current/config";
import {IoEyeOutline, IoShareSocialOutline} from "react-icons/io5";
import {IoHeartOutline} from "react-icons/io5";
import {IoLogoRss} from "react-icons/io";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const totalMetaData = [
  {
    label: 'followers',
    value: 'followers',
    icon: IoLogoRss ,
  },
  {
    label: 'shares',
    value: 'shares',
    icon: IoShareSocialOutline,
  },
  {
    label: 'views',
    value: 'views',
    icon: IoEyeOutline,
  },
  {
    label: 'votes',
    value: 'votes',
    icon: IoHeartOutline,
  },
]

const BannerMetaData = ({ total }) => {
  const t = useTranslate()
  return (
    <div className="w-full flex content_disabled justify-around md:justify-start">
      {totalMetaData.map(
        ({ label, value , icon}, index) => (
          <div
            key={index}
            className="flex flex-col items-center mr-0 md:mr-6">
                <span className="text-color-act font-bold leading-tight md:leading-normal">
                  {_.get(total, value) || 0}
                </span>
                <span className="font-light text-xs text-color-300 text-center italic">
                  {t(label)}
                </span>
          </div>
        )
      )}
    </div>
  )
}

const DefaultAvatar = React.memo(() => {
    const {isSm} = useContext(LayoutContext)
    return (
        <Avatar
            shape="circle"
            icon={
                <WishareRibbonSvg
                    width={isSm ? '3rem' : '6.5rem'}
                    height={isSm ? '3rem' : '6.5rem'}
                />
            }
            className="flex flex-center background-100 border-3 md:border-5 border-white shadow-md md:shadow-lg ml-2 md:ml-0"
            size={isSm ? 80 : 140}
        />
    )
})

const ActivityBanner = React.memo(
  ({ Wrapper = 'div' }) => {
    const t = useTranslate()
    const {isSm} = useContext(LayoutContext)

    const { activity } = useContext(
      ActivityContext
    )

    const [id, type] = [
      getId(activity),
      getType(activity),
    ]

    const cover_photo =
      _.get(activity, 'cover_photo') || 'invalid cover photo'

    const avatar = getAvatar(activity)

    return (
      <Wrapper className="flex flex-col">
        <Image
          preview={false}
          src={cover_photo}
          fallback={`${ROOT_URL}/images/default/activity-bg.jpg`}
          className="fadeIn animated banner-activity object-cover"
        />
        <div className="relative flex items-start container mx-auto">
          <div className="avatar-activity content_disabled">
              {
                  avatar ? <Avatar
                      size={isSm ? 80 : 140}
                      src={avatar}
                      className="border-3 md:border-5 border-white shadow-md md:shadow-lg ml-2 md:ml-0"
                  /> : <DefaultAvatar />
              }
          </div>
          <div className="flex flex-col flex-1 p-1 md:p-3 info-activity">
              <div className="w-full">
                  <div className="text-base md:text-xl font-bold text-color-act max-lines-1 mb-1">
                      {getTitle(activity)}
                  </div>
                  <div className="flex gap-2">
                      <div style={{padding: '2px 10px'}} className="text-xs rounded-full bg-color-act font-semibold text-white w-fit">
                          {t(type)}
                      </div>
                      {!!_.get(activity, 'indexed_by_wishare') && (
                          <div style={{padding: '2px 10px'}} className="text-xs rounded-full bg-color-org italic text-white w-fit">
                              {t('indexed by wishare')}
                          </div>
                      )}
                  </div>
              </div>
              <Divider className="my-2" />
              <BannerMetaData
                  total={_.get(
                      activity,
                      'total'
                  )}
              />
          </div>
        </div>
      </Wrapper>
    )
  }
)

export default ActivityBanner
