import React, {useState} from 'react'
import useTranslate from "../../../modules/local/useTranslate";

const CollapsedWrapper = ({children, className = "", lineHeight = 16, isToggle = true}) => {
  const t = useTranslate();
  const [toggle, setToggle] = useState(isToggle);

  return !!toggle ? (
    <div className="relative overflow-hidden"
         style={{maxHeight: `calc(${lineHeight} * 1rem)`,}}>

      <div className={className}>
        {children}
      </div>

      <div
        className="z-10 absolute bottom-0 flex flex-center w-full pt-5 pb-2 bg-gradient-to-t from-text-color-500 rounded-b-lg">
        <div
            onClick={() => setToggle(!toggle)}
            className="hover:background-white px-4 rounded-full border border-white text-xs text-white hover:text-color-100 italic cursor-pointer">
          {t('see more')}
        </div>
      </div>
    </div>
  ) : (
    <div className={className}>
      {children}
    </div>
  )
}

export default CollapsedWrapper
