import { schema } from 'normalizr'

export const staffSchema =
  new schema.Entity(
    'staffs',
    {},
    {
      idAttribute: 'id',
    }
  )
