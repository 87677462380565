import classNames from 'classnames'
import FieldsFactory from 'components/form/FieldsFactory'
import Pure from 'components/Pure'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { renderElse } from 'views/Shared'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'

const renderFieldWrapper =
  (title, isRequired = false) =>
  ({ children }) =>
    (
      <Translate>
        {(t) => (
          <div className="flex flex-col justify-center">
            {
              <label
                className={classNames(
                  'horizontal-form-label text-sm text-color-300',
                  !!isRequired &&
                    'required-label'
                )}>
                {_.isString(title)
                  ? `${t(title)}: `
                  : title}
              </label>
            }
            <div className="flex-1">
              {children}
            </div>
          </div>
        )}
      </Translate>
    )

const formSchema = [
  {
    children: [
      {
        name: 'referral_email',
        Wrapper:
          renderFieldWrapper('email'),
        hideError: true,
        component:
          createControlledFormField({
            type: 'email',
            placeholder: 'email',
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'referral_phone_number',
        Wrapper:
          renderFieldWrapper('phone'),
        hideError: true,
        component:
          createControlledFormField({
            type: 'tel',
            placeholder: 'phone',
          }),
      },
    ],
  },
]

const ReferralInformationField = ({
  referral,
  referral_name,
  Wrapper = 'div',
}) => (
  <Wrapper className="mt-2 flex flex-col space-y-2">
    <Pure
      input={[referral, referral_name]}>
      {renderElse(
        _.isEmpty(referral) &&
          _.isEmpty(referral_name),
        <FieldsFactory
          formSchema={formSchema}
        />
      )}
    </Pure>
  </Wrapper>
)

export default ReferralInformationField
