import {
  Alert,
  Avatar,
  Button,
  Col,
  Form,
  Row,
} from 'antd'
import { getId } from 'apis/model/base'
import { donationEventSchema } from 'apis/schema'
import FieldsFactory from 'components/form/FieldsFactory'
import { LoginContext } from 'components/LoginContext'
import { copyText } from 'helpers'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  IoAddCircle,
  IoCopyOutline,
  IoPerson,
} from 'react-icons/io5'
import {
  Redirect,
  useParams,
} from 'react-router-dom'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItem,
  Null,
  renderIf,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { donationApi } from 'views/Wishare/apis'
import {
  wishareConfigs,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import createStepForm, {
  StepActionTypes,
  StepFormContext,
} from 'views/Wishare/factory/createStepForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import DonationContext from '../DonationContext'
import { donationStepFormSchema } from '../donationSchemas'
import { getDonorName } from '../functions/getDonorName'

const StageItem = ({
  Wrapper = 'div',
}) => {
  const {
    values,
    currentStep,
    formInstance,
  } = useContext(StepFormContext)

  if (_.isEmpty(currentStep))
    return null

  const header = _.get(
    currentStep,
    'description'
  )

  const { formSchema } = currentStep

  return (
    <Wrapper
      className="max-w-full md:px-3 lg:px-6 mx-auto space-y-3"
      onClick={() =>
        formInstance.validateFields()
      }>
      {header}
      <FieldsFactory
        formSchema={formSchema}
      />
    </Wrapper>
  )
}

const ConfirmStage = () => {
  const t = useTranslate()
  const { values, currentStep } =
    useContext(StepFormContext)

  const header = _.get(
    currentStep,
    'description'
  )

  const {
    donor,
    email,
    alias,
    anonymous,
    alias_name,
    phone_number,
  } = values

  const donation_items = _.get(
    values,
    'donation_items',
    []
  )

  const useAnonymous =
    Number(anonymous) === 1

  const displayName = getDonorName({
    donor: donor
      ? {
          ...donor,
          id: getId(donor),
        }
      : undefined,
    alias_name,
    alias: Number(alias),
    donor_name: donor
      ? getTitle(donor)
      : undefined,
    anonymous: Number(anonymous),
  })

  if (_.isEmpty(currentStep))
    return null

  return (
    <div className="max-w-full md:px-3 lg:px-6 mx-auto">
      <div className="mb-6">
        {header}
        <div className="flex flex-col space-y-6">
          {useAnonymous ? (
            <div className="flex flex-col">
              <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
                {t('you have chosen')}
              </span>
              <span className="font-bold text-lg text-primary">
                {t('anonymous')}
              </span>
            </div>
          ) : (
            <FlexCol>
              <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
                {t('you are')}
              </span>
              <div className="flex items-center mb-3">
                <Avatar
                  src={getAvatar(donor)}
                  icon={
                    <IoPerson className="text-color-200" />
                  }
                  className="flex flex-center background-200"
                />
                <span className="text-lg flex-1 mx-3 font-bold text-primary">
                  {displayName}
                </span>
              </div>
              {email && (
                <div className="flex items-center">
                  <span className="text-xs text-color-500 italic font-light">
                    {`${t('email')} : `}
                  </span>
                  <span className="text-sm font-medium text-color-000 ml-3">
                    {email}
                  </span>
                </div>
              )}
              {phone_number && (
                <div className="flex items-center">
                  <span className="text-xs text-color-500 italic font-light">
                    {`${t('phone')} : `}
                  </span>
                  <span className="text-sm font-medium text-color-000 ml-3">
                    {phone_number}
                  </span>
                </div>
              )}
            </FlexCol>
          )}
          <FlexCol>
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t('donated')}
            </span>
            <div className="p-3 rounded-lg bg-blue-100 border border-primary">
              {donation_items.map(
                (item, i) => (
                  <Row key={i}>
                    <Col
                      span={12}
                      className="flex space-x-3 font-bold text-primary items-center">
                      <IoAddCircle />
                      {item.unit_name !==
                      item.item_name ? (
                        <div>
                          {
                            item.item_name
                          }
                        </div>
                      ) : (
                        <div>
                          {t(
                            item.type_cd
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      span={12}
                      style={{
                        color: '#000',
                      }}
                      className="font-bold text-color-000 text-right">
                      {Number(
                        item.reg_donation_qtty
                      ).toLocaleString()}{' '}
                      {item.unit_name}
                    </Col>
                  </Row>
                )
              )}
            </div>
          </FlexCol>
        </div>
      </div>
      <div className="p-3 background-200 rounded-lg text-center flex flex-col italic space-y-2 text-color-100 mb-6">
        <span>
          {t(
            'Please follow these instructions to complete the donation process'
          )}
        </span>
        <span>
          {t(
            'Thank you benefactors, when transferring, remember to specify the structure of the transfer as'
          )}
        </span>
        <span>{`"${t(
          'your name - donation name'
        )}"`}</span>
      </div>
      <div className="text-sm text-center text-color-300 italic font-light mb-6">
        {t(
          'Click the Register donate button to complete and receive the donation code'
        )}
      </div>
    </div>
  )
}

const FinalStage = React.memo(
  ({ onCancel = Null }) => {
    const t = useTranslate()

    const { item } = useContext(
      StepFormContext
    )

    const { donation: donation_event } =
      useContext(DonationContext)

    const ItemCopies = ({
      label,
      value,
    }) => (
      <FlexCol className="text-sm">
        <span className="text-xs text-color-400 italic font-light">
          {t(label)}
        </span>
        <div className="flex items-center justify-between gap-1 leading-tight">
          <span className="text-sm text-color-000 font-medium">
            {value}
          </span>
          <IoCopyOutline
            onClick={() => {
              copyText(
                value,
                t('copied')
              )
            }}
            className="text-color-300 cursor-pointer"
          />
        </div>
      </FlexCol>
    )

    return (
      <FlexCol className="items-center justify-center py-12 max-w-full md:max-w-3xl lg:max-w-2xl mx-auto">
        <div className="font-bold text-2xl text-green-500 uppercase">
          {t('complete registration')}
        </div>

        <span className="font-light text-center text-primary text-sm">
          {t(
            'You have just completed the registration, please follow the donation steps to complete the donation process for campaign'
          )}
        </span>

        <span className="text-center text-3xl font-bold text-primary mt-2 mb-6 leading-tight">
          {_.get(
            item,
            'donation_event.title'
          )}
        </span>
        {_.get(
          donation_event,
          'tx_beneficiary_account_no'
        ) && (
          <Alert
            type="warning"
            className="w-full mb-3"
            message={
              <div className="flex flex-col p-3">
                <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-2">
                  {t(
                    'beneficiary info'
                  )}
                </div>
                <div className="space-y-2 ml-0 md:ml-3">
                  <ItemCopies
                    label="account number"
                    value={_.get(
                      donation_event,
                      'tx_beneficiary_account_no'
                    )}
                  />
                  {_.get(
                    donation_event,
                    'tx_beneficiary_account_name'
                  ) && (
                    <ItemCopies
                      label="account name"
                      value={_.get(
                        donation_event,
                        'tx_beneficiary_account_name'
                      )}
                    />
                  )}
                  {_.get(
                    donation_event,
                    'tx_beneficiary_bank_name'
                  ) && (
                    <ItemCopies
                      label="bank name"
                      value={_.get(
                        donation_event,
                        'tx_beneficiary_bank_name'
                      )}
                    />
                  )}
                  {_.get(
                    donation_event,
                    'tx_memo'
                  ) && (
                    <ItemCopies
                      label="transaction memo"
                      value={_.get(
                        donation_event,
                        'tx_memo'
                      )}
                    />
                  )}
                </div>
              </div>
            }
          />
        )}

        <span className="font-light text-center text-color-300 italic text-sm">
          {t(
            'description donation code'
          )}
        </span>

        <span className="px-3 py-1 border-2 border-secondary rounded-lg text-xl font-bold text-secondary my-3">
          {_.get(item, 'idcode')}
        </span>

        <span className="text-color-000 font-medium mb-6 text-center">
          {t(
            'Thank you for your contributions to make this life better'
          )}
        </span>

        <span className="text-sm text-center text-color-300 italic font-light my-3">
          {t(
            'You can click the "Back campaign" button to return to the campaign'
          )}
        </span>

        <Button
          type="primary"
          className="no-shadow no-shadow border-none rounded-lg"
          onClick={onCancel}>
          {t('back campaign')}
        </Button>
      </FlexCol>
    )
  }
)

const stages = [
  {
    index: 0,
    name: 'donation',
    title: 'donation',
    component: StageItem,
    formSchema:
      donationStepFormSchema.register_step_1,
    actions: [
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: ['donation_items'],
      },
    ],
  },
  {
    index: 1,
    name: 'donor',
    title: 'donor',
    component: StageItem,
    formSchema:
      donationStepFormSchema.register_step_2,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: ['question_cleared'],
        isDisabled: (values) =>
          !_.get(
            values,
            'question_cleared'
          ) ||
          (!!_.get(values, 'alias') &&
            _.isEmpty(
              _.get(
                values,
                'alias_name'
              )
            )) ||
          _.some(
            Array.from(
              _.get(
                values,
                'required_information_fields'
              ) || []
            ).map((name) =>
              _.get(values, name)
            ),
            (value) => !value
          ),
      },
    ],
  },
  {
    index: 2,
    name: 'finish',
    title: 'finish',
    component: ConfirmStage,
    formSchema:
      donationStepFormSchema.register_step_3,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        label: 'register donation',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
        requireds: [],
      },
    ],
  },
  {
    index: 3,
    name: 'final step',
    title: 'final step',
    finished: true,
    component: FinalStage,
  },
]

const DonationRegisterFormContent = ({
  Wrapper = 'div',
  onCancel = Null,
  donation_options = [],
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const params = useParams()

  const [formInstance] = Form.useForm()

  const { donation: item } = useContext(
    DonationContext
  )

  const [id] = [_.get(params, 'id')]

  const donation = useSelectEntities(
    id,
    donationEventSchema,
    item
  )

  const renderHeader = useCallback(
    () => (
      <div className="flex justify-center uppercase text-primary font-bold text-xl md:text-3xl my-3">
        <center>
          {t('register donation')}
        </center>
      </div>
    ),
    [t]
  )

  const initialValues = useMemo(() => {
    const {
      edit,
      targets = [],
      donation_type,
      target_unit_name,
      conversion_required,
      information_fields = {},
      collection_questions = [],
      required_information_fields = [],
    } = donation || {}

    const donation_items = _.isEmpty(
      donation_options
    )
      ? Array.from(targets).map(
          ({
            target,
            type_cd,
            item_name,
            unit_name,
          }) => ({
            target,
            type_cd,
            item_name,
            unit_name,
            reg_donation_qtty: 0,
            reg_donation_value: 0,
          })
        )
      : Array.from(
          donation_options
        ).map(
          ({
            type_cd,
            item_name,
            unit_name,
            option_qtty,
            option_value,
          }) => {
            const matchedTarget =
              _.first(
                Array.from(
                  targets
                ).filter(
                  (e) =>
                    e.type_cd ===
                      type_cd &&
                    e.item_name ===
                      item_name &&
                    e.unit_name ===
                      unit_name
                )
              )

            return {
              type_cd,
              item_name,
              unit_name,
              reg_donation_qtty:
                option_qtty,
              reg_donation_value:
                option_value,
              target: _.get(
                matchedTarget,
                'target',
                0
              ),
              is_read_only: true,
            }
          }
        )

    const required_fields = Array.from(
      required_information_fields || []
    )

    const basicInfo = _.pick(login, [
      'name',
      'title',
      '_type',
      'avatar',
      'user_id',
      'username',
      ...required_fields,
    ])

    const defaultParams = _.omitBy(
      {
        anonymous: 0,
        alias_name: null,
        alias: !!login ? 0 : 1,
        ..._.pick(
          basicInfo,
          required_fields
        ),
        date_of_birth:
          basicInfo.date_of_birth
            ? moment(
                basicInfo.date_of_birth
              ).format(
                wishareConfigs.dateFormat
              )
            : undefined,
      },
      _.isUndefined
    )

    return {
      edit,
      donation_type,
      donation_items,
      target_unit_name,
      conversion_required,
      information_fields,
      collection_questions,
      required_information_fields,
      ...defaultParams,
      donor: {
        ...basicInfo,
        owner: basicInfo,
      },
      question_cleared:
        _.isEmpty(
          collection_questions
        ) ||
        _.every(
          Array.from(
            collection_questions
          ).filter(
            ({ mandatory }) =>
              !!mandatory
          ),
          ({ answer }) =>
            !_.isEmpty(answer)
        ),
    }
  }, [login])

  const validationSchema = undefined

  const steps = useMemo(() => {
    const title = getTitle(donation)

    const avatar = _.get(
      donation,
      'owner.avatar'
    )
    const owner = _.get(
      donation,
      'owner.title'
    )
    return stages.map(
      ({ ...rest }) => ({
        ...rest,
        description: renderIf(
          title,
          <div className="flex flex-col mb-6 p-3 border border-primary background rounded-lg">
            <span className="text-sm font-medium text-color-400 tracking-wide mb-1">
              {t(
                'your donation will be sent to'
              )}
            </span>
            <div className="flex items-center font-bold text-primary">
              <Avatar
                src={avatar}
                size={40}
                className="flex flex-center background-100">
                <span className="capitalize font-normal text-color-300">
                  {_.first(title)}
                </span>
              </Avatar>
              <div className="flex flex-col flex-1 mx-3">
                <span className="text-base md:text-lg max-lines-1">
                  {title}
                </span>
                <span className="text-xs font-light text-secondary italic max-lines-1">
                  {owner}
                </span>
              </div>
            </div>
          </div>
        ),
      })
    )
  }, [t, donation])

  const { addPost } = useContext(
    ObserverContext
  )

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo =
    donationApi.donation_events_register_api

  const onSuccess = (
    result,
    { response },
    forward = Null
  ) => {
    successNotify(
      NotificationActionTypes.CREATE,
      t,
      {
        description:
          'successfully registered',
      }
    )
    const newItem =
      getResponseItem(response)
    addPost(newItem)
    forward(newItem)
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    edit,
    donor,
    donation_type,
    donation_items,
    question_cleared,
    target_unit_name,
    conversion_required,
    information_fields,
    collection_answers,
    collection_questions,
    required_information_fields,
    ...values
  }) => {
    const _donation_items = Array.from(
      donation_items || []
    ).map(({ ...rest }) => ({
      unit_name: target_unit_name,
      item_name: target_unit_name,
      ...rest,
    }))
    return _.omitBy(
      {
        ...values,
        donation_items: JSON.stringify(
          _donation_items
        ),
        ...(collection_answers
          ? {
              collection_answers:
                JSON.stringify(
                  collection_answers
                ),
            }
          : {}),
      },
      _.isUndefined
    )
  }

  const query = bindQueryParam({ id })

  const content = createStepForm(
    {
      query,
      apiInfo,
      onError,
      onSuccess,
    },
    {
      steps,
      onCancel,
      onPreSubmit,
      formInstance,
      initialValues,
      validationSchema,
      Header: renderHeader,
    }
  )

  return (
    <Wrapper className="space-y-3 py-3 max-w-full mx-auto">
      {content}
    </Wrapper>
  )
}

const DonationRegisterForm = (
  props
) => {
  const ModalForm = withModalLayout(
    DonationRegisterFormContent,
    props
  )
  return (
    <AntdConfigWrapper>
      <ModalForm />
    </AntdConfigWrapper>
  )
}

export const registerDonationParams = {
  action: {
    name: 'action',
    value: 'donate',
  },
  options: {
    name: 'option-id',
    getOption: (value) => value,
  },
}

export default DonationRegisterForm
