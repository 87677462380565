import { Button } from 'antd'
import {
  medal_delete_Api,
  medal_getById_Api,
  medal_getMedals_api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { MedalsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import MedalActionModal, {
  MedalModalTypes,
} from './MedalActionModal'
import MedalsAdvancedFilter from './MedalsAdvancedFilter'
import renderMedal from './renderMedal'

const WorkspaceMedalsList = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    active: 'active',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const filter_active = useSearchParam(
    filter_actions.active
  )

  const createContextOptions = (
    medal
  ) => [
    {
      key: MedalModalTypes.EDIT,
      label: 'edit',
      icon: (
        <IoCreateOutline
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: MedalModalTypes.DELETE,
      label: 'delete',
      icon: (
        <IoTrashOutline
          size={16}
          className="text-red-500"
        />
      ),
    },
  ]

  const onCancel = () =>
    setAction({ type: undefined })

  const refresh = () => {
    onCancel()
    history.push({
      state: {
        refreshToken: Date.now(),
      },
      search: location.search,
    })
  }

  const {
    handleAsyncAction: deleteMedal,
  } = useAsyncAction({
    apiInfo: medal_delete_Api,
    onError: notifyOnError(t),
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      refresh()
    },
  })

  const onMenuSelect = (key, medal) => {
    const medal_id = getId(medal)
    switch (key) {
      case MedalModalTypes.EDIT:
        setAction({
          type: key,
          value: medal_id,
        })
        break
      case MedalModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteMedal(
                {},
                bindQueryParam({
                  id: medal_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const renderContextMenu = useCallback(
    (medal) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onMenuSelect(key, medal)
          }
          items={createContextOptions(
            medal
          )}
        />
      </div>
    ),
    [onMenuSelect]
  )

  const RenderWrapper = useCallback(
    ({ children }) => {
      switch (action.type) {
        case MedalModalTypes.EDIT:
          return (
            <Async
              {...{
                apiInfo:
                  medal_getById_Api,
                query: bindQueryParam({
                  id: action.value,
                }),
              }}>
              {({
                response,
                isLoading,
              }) =>
                _.isFunction(children)
                  ? children({
                      isLoading,
                      initialValues:
                        getResponseItem(
                          response
                        ),
                    })
                  : children
              }
            </Async>
          )
        case MedalModalTypes.CREATE:
        default:
          return children({
            isLoading: false,
          })
      }
    },
    [action]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          active: filter_active,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo: medal_getMedals_api,
        query: bindQueryParams([
          { id: owner_id },
          {
            prop: owner_type,
          },
        ]),
        renderItem: renderMedal({
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
      ],
      SortByWidget: MedalsSortBy,
      AdvancedFilter:
        MedalsAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="medal manager"
        extra={
          <Button
            type="primary"
            className="no-border rounded-lg no-shadow no-text-shadow"
            onClick={() => {
              setAction({
                type: MedalModalTypes.CREATE,
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
      {action.type && (
        <RenderWrapper>
          {({
            isLoading,
            initialValues,
          }) => (
            <MedalActionModal
              type={action.type}
              onCancel={onCancel}
              params={{
                owner_id,
                owner_type,
              }}
              onUpdated={() =>
                onCancel()
              }
              onRefresh={refresh}
              onCreated={refresh}
              isLoading={isLoading}
              initialValues={
                initialValues
              }
            />
          )}
        </RenderWrapper>
      )}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(WorkspaceMedalsList)
