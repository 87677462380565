import { Modal } from 'antd'
import { getType } from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import { Null } from 'views/Shared'
import { WishareEntities } from '../enums'
import wishareModel from '../wishareModel'

export const willGoTo = ({
  url,
  item,
  icon,
  title,
  okText,
  content,
  cancelText,
  onOk = Null,
  okButtonProps,
  ...props
}) => {
  if (_.isEmpty(item)) return null

  const type = getType(item)

  return Modal.confirm({
    icon,
    title,
    okText,
    content,
    cancelText,
    okButtonProps,
    okType: 'primary',
    onOk: () => {
      const wishareTypes = [
        WishareEntities.BENEFICIARY,
        WishareEntities.GIVING,
        WishareEntities.DONATION,
        WishareEntities.ACTIVITY,
        WishareEntities.RECRUITMENT,
        WishareEntities.ORGANIZATION,
      ]
      const getPath =
        wishareTypes.includes(type)
          ? wishareModel.getLinkToDetail
          : getLinkToDetail
      const pathname = _.isEmpty(url)
        ? getPath(item)
        : url
      onOk(pathname)
    },
    ...props,
  })
}
