import { Tabs } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  Switch,
  useParams,
} from 'react-router-dom'
import {
  notEmpty,
  Null,
} from 'views/Shared'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import {
  wishareConfigs,
  wisharePaths,
} from 'views/Wishare/configs'
import { mapPathParam } from 'views/Wishare/functions/routerHelper'
import useNavRouteAction from 'views/Wishare/hooks/useNavRouteAction'
import { NavRoutes } from 'views/Wishare/hooks/useNavRoutes'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationProfilesNav = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const {
    isSubDomain,
    url_domain,
    url_pathname,
    ...rest
  } = useContext(SubDomainContext)

  const params = useParams()

  const history = useHistory()

  const id = _.get(params, 'id')

  const sub_id = _.get(params, 'sub_id')

  const sub_type = _.get(
    params,
    'sub_type'
  )

  const withSubDomain = Boolean(
    isSubDomain && !id
  )

  const rootPath = isSubDomain
    ? '/profiles/:sub_type/:sub_id' //wisharePaths.organization_domain
    : wisharePaths.organizationProfiles

  const {
    isValidRoute = Null,
    routeEntities = [],
    getDefaultAction = Null,
    organization = {},
  } = useContext(
    OrganizationContainerContext
  )

  const defaultAction =
    getDefaultAction(rootPath)

  const { currentAction } =
    useNavRouteAction({
      anchor: sub_id,
      defaultAction,
      isAction: isValidRoute,
      // getActionParam: withSubDomain
      //   ? (pathname) => {
      //       const pattern =
      //         rootPath == ''
      //           ? pathname
      //           : _.last(
      //               String(
      //                 pathname
      //               ).split(rootPath)
      //             )
      //       const _action =
      //         pattern.substring(
      //           pattern.startsWith('/')
      //             ? 1
      //             : 0
      //         )
      //       return _.isEmpty(
      //         _action.trim()
      //       )
      //         ? defaultAction
      //         : _action
      //     }
      //   : undefined,
    })

  const pathTransform = useCallback(
    (path, segment) =>
      [
        _.isEmpty(id)
          ? path
          : mapPathParam(path, {
              id,
            }),
        segment,
      ]
        .filter(notEmpty)
        .join('/'),
    [id]
  )

  const tabPanes = useMemo(
    () =>
      routeEntities
        .filter(
          ({ isInvisible = Null }) =>
            !isInvisible({
              id: sub_id,
              prop: sub_type,
              organization,
            })
        )
        .map(
          ({
            path,
            getPath = Null,
            breadcrumbName = Null,
          }) => (
            <Tabs.TabPane
              tab={breadcrumbName(t, {
                id: sub_id,
                prop: sub_type,
              })}
              key={
                getPath({
                  id: sub_id,
                  prop: sub_type,
                }) || path
              }>
              <NavRoutes
                rootPath={rootPath
                  .replace(
                    ':sub_id',
                    sub_id
                  )
                  .replace(
                    ':sub_type',
                    sub_type
                  )}
                routes={routeEntities}
                transform={
                  pathTransform
                }
                defaultAction={
                  defaultAction
                }
                renderRoutes={
                  withSubDomain
                    ? (
                        routes,
                        redirect
                      ) => (
                        <Switch>
                          {routes}
                          {redirect}
                        </Switch>
                      )
                    : undefined
                }
              />
            </Tabs.TabPane>
          )
        ),
    [
      t,
      params,
      rootPath,
      isSubDomain,
      organization,
      defaultAction,
      routeEntities,
      pathTransform,
      withSubDomain,
    ]
  )

  return (
    <Wrapper
      id="content"
      className="space-y-3 background">
      <Tabs
        tabBarStyle={
          wishareConfigs.styles.tabBar
        }
        destroyInactiveTabPane={true}
        onChange={(activeKey) => {
          history.push({
            pathname: pathTransform(
              rootPath
                .replace(
                  ':sub_id',
                  sub_id
                )
                .replace(
                  ':sub_type',
                  sub_type
                ),
              activeKey
            ),
            hash: '#content',
          })
        }}
        activeKey={currentAction}>
        {tabPanes}
      </Tabs>
    </Wrapper>
  )
}

export default OrganizationProfilesNav
