import { Button } from 'antd'
import { post_create_Api } from 'apis'
import FieldsFactory from 'components/form/FieldsFactory'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
} from 'react'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import { mapProps } from 'recompose'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { willGoTo } from 'views/Wishare/factory/createRedirectConfirm'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { AttachedActions } from './AttachedActions'
import MegaTextarea from './MegaTextarea'
import {
  CreatorSelect,
  OwnerSelect,
} from './ObjectSelector'

const formSchema = [
  {
    children: [
      {
        name: 'creator',
        component:
          createControlledFormField({
            label: 'container',
            placeholder: 'select',
            withValues: ({
              default_owner_id,
            }) => ({
              default_owner_id,
            }),
            Component: CreatorSelect,
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'owner',
        component:
          createControlledFormField({
            label: 'posted by',
            placeholder: 'select',
            withValues: ({
              creator_id,
              default_owner_id,
            }) => ({
              parent_id: creator_id,
              defaultValue:
                default_owner_id,
            }),
            Component: OwnerSelect,
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            rows: 6,
            bordered: true,
            fieldNames: {
              embed_data: 'embed_data',
            },
            placeholder: 'description',
            Component: MegaTextarea,
          }),
      },
    ],
  },
  {
    children: [
      {
        component: mapProps(
          ({ onChange }) => ({
            onChange,
            fieldNames: {
              tags: 'tags',
              privacy: 'privacy',
            },
          })
        )(AttachedActions),
      },
    ],
  },
]

const CreatePostForm = ({
  onCancel = Null,
  initialValues = {},
}) => {
  const t = useTranslate()

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const history = useHistory()

  const onSuccess = (
    __,
    { response }
  ) => {
    onCancel()
    successNotify(
      NotificationActionTypes.CREATE,
      t
    )
    history.push({
      state: {
        refreshToken: Date.now(),
      },
    })
    const item = getResponseItem(
      response,
      'data.data.content'
    )
    if (item) {
      addPost(item)
      willGoTo({
        item,
        title: (
          <div className="flex items-center gap-2">
            <IoCheckmarkCircleOutline size={20} className="text-green-600"/>
            <span className="font-bold text-green-600">
              {t('the article was posted')}
            </span>
          </div>
        ),
        content: (
          <div className="text-sm font-light italic text-color-300">
            {t('would you like to go to the detailed article page')}
          </div>
        ),
        className: 'custom-modal',
        okText: t('goto'),
        cancelText: t('close'),
        okButtonProps: {
          type: 'primary',
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        },
        cancelButtonProps: {
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        },
        onOk: (pathname) =>
          history.push({
            pathname,
          }),
      })
    }
  }

  const {
    handleAsyncAction: createPost,
  } = useAsyncAction({
    apiInfo: post_create_Api,
    onError: notifyOnError(t),
    onSuccess,
  })

  const onSubmit = ({
    owner,
    creator,
    creator_id: id,
    creator_type: prop,
    default_owner_id,
    ...values
  }) => {
    if (createPost) {
      createPost(
        values,
        bindQueryParams([
          { id },
          { prop },
        ])
      )
    }
  }

  const initialErrors = {
    description: t(
      'min length 2 characters'
    ),
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={yupShape({
        creator_id: requiredString(t),
        creator_type: requiredString(t),
        owner_id: requiredString(t),
        owner_type: requiredString(t),
        description: requiredString(t)
          .trim()
          .min(
            2,
            initialErrors.description
          ),
      })}
      initialErrors={initialErrors}
      initialValues={initialValues}
      className="flex flex-col gap-3 space-y-3 ">
      <div className="max-w-2xl mx-auto PostForm">
          <div className="text-center text-xl text-color-000 font-bold uppercase mb-3">
              {t('create new post')}
          </div>
          <FieldsFactory
              formSchema={formSchema}
          />
          <div className="flex justify-end space-x-6">
              <Button
                  onClick={onCancel}
                  className="rounded-lg no-shadow no-text-shadow border-none">
                  {t('cancel')}
              </Button>
              <FormikConsumer>
                  {({
                        isValid,
                        handleSubmit = Null,
                    }) => (
                      <Button
                          type="primary"
                          onClick={() =>
                              handleSubmit()
                          }
                          disabled={!isValid}
                          className="rounded-lg no-shadow no-text-shadow border-none">
                          {t('post an article')}
                      </Button>
                  )}
              </FormikConsumer>
          </div>
      </div>
    </Formik>
  )
}

export default CreatePostForm
