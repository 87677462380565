import { giving_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { withProps } from 'recompose'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import GivingDonationPicker from '../components/GivingDonationPicker'
import GivingContext from '../GivingContext'

const formFields = [
  { name: 'donation_events' },
]

const formSchema = [
  {
    title: 'ref_ids',
    children: [
      {
        name: 'ref_ids',
        component: withProps(
          ({
            name,
            value,
            form = {},
            onChange,
          }) => {
            const { id, type } =
              form.values || {}
            const ref = _.first(
              Array.from(value || [])
            )
            return {
              id,
              type,
              name,
              onChange,
              item: ref,
            }
          }
        )(GivingDonationPicker),
      },
    ],
  },
]

const GivingSettingDonation = () => {
  const { giving } = useContext(
    GivingContext
  )

  const validationSchema = undefined

  const giving_id = getId(giving)

  const giving_type = getType(giving)

  const onPreSubmit = ({
    id,
    type,
    ref_ids,
    donation_events,
    ...values
  }) => {
    return {
      ...values,
      ref_ids: Array.from(
        ref_ids || []
      ).join(','),
    }
  }

  const initialized = useCallback(
    ({
      ref_ids,
      donation_events,
      ...values
    }) => ({
      ...values,
      id: giving_id,
      type: giving_type,
      ref_ids: donation_events,
    }),
    [giving_id, giving_type]
  )

  if (_.isEmpty(giving)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: giving,
    validationSchema,
    query: bindQueryParam({
      giving_id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: giving_update_api,
  })
}

export default GivingSettingDonation
