import {
  getId,
  getType,
} from 'apis/model/base'
import { createValue } from 'components/form/utils'
import getTitle from 'helpers/getTitle'
import React from 'react'
import { Null } from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import GivingReceiverSelector from '../ActionForms/GivingReceiverSelector'

const GivingReferralSelectField = ({
  name,
  value,
  onChange = Null,
  readOnly = false,
  onSelect = Null,
  ...props
}) => {
  const setValue = (name, value) =>
    onChange(createValue(name, value))

  const handleChange = (object) => {
    onSelect(object)
    setValue(name, object)
    setValue(
      [name + '_id'],
      getId(object)
    )
    setValue(
      [name + '_type'],
      getType(object)
    )
    setValue(
      [name + '_name'],
      getTitle(object)
    )
  }

  return (
    <div className="flex w-full items-center">
      <GivingReceiverSelector
        {...props}
        name={name}
        readOnly={readOnly}
        defaultValue={value}
        onChange={handleChange}
        type={WishareEntities.USER}
      />
    </div>
  )
}

export default GivingReferralSelectField
