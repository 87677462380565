import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { staticPaths } from 'routes/staticPaths'
import createStickySider from 'views/Wishare/factory/createStickySider'
import { RecruitmentHosts } from 'views/Wishare/Host/components/HostFactory'
import { RecruitmentShareWidget } from '../../factory/createShareWidget'
import RecruitmentRegister from './RecruitmentRegister'
import RecruitmentSummary from './RecruitmentSummary'

const metaRoutes = [
  {
    name: 'help',
    label: 'about us',
    path: staticPaths.about,
  },
  {
    name: 'help',
    label: 'policy',
    path: staticPaths.policy,
  },
  {
    name: 'help',
    label: 'help',
    path: staticPaths.helps,
  },
  {
    name: 'Wishare.com',
    path: staticPaths.home,
    className:
      'text-sm font-bold text-primary',
  },
]

const RecruitmentFooter = () => {
  const t = useTranslate()
  return (
    <div
      className="flex flex-wrap justify-center space-x-6"
      style={{ marginBottom: '5rem' }}>
      {metaRoutes.map(
        (
          {
            name,
            label,
            path,
            ...rest
          },
          index
        ) => (
          <Link
            key={index}
            to={path}
            className="text-sm font-light text-color-400 hover:text-primary"
            {...rest}>
            {label ? t(label) : name}
          </Link>
        )
      )}
    </div>
  )
}

const RecruitmentSticker = React.memo(
  () => (
    <React.Fragment>
      <RecruitmentRegister />
      <RecruitmentSummary />
      <RecruitmentShareWidget />
      <RecruitmentFooter />
    </React.Fragment>
  )
)

const RecruitmentSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(
    RecruitmentSticker,
    RecruitmentHosts
  )

export default RecruitmentSider
