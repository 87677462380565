import {
  Avatar,
  Button,
  Form,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { recruitmentEventSchema } from 'apis/schema'
import FieldsFactory from 'components/form/FieldsFactory'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import {
  Redirect,
  useParams,
} from 'react-router-dom'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItem,
  Null,
  renderIf,
  renderSelf,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { recruitmentApi } from 'views/Wishare/apis'
import {
  wishareConfigs,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import createStepForm, {
  StepActionTypes,
  StepFormContext,
} from 'views/Wishare/factory/createStepForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import {
  GenderTypes,
  getProp,
} from 'views/Wishare/wishareFieldTypes'
import RecruitmentContext from '../RecruitmentContext'
import { recruitmentStepFormSchema } from '../recruitmentSchemas'

const StageItem = ({
  Wrapper = 'div',
}) => {
  const {
    values,
    currentStep,
    formInstance,
  } = useContext(StepFormContext)

  if (_.isEmpty(currentStep))
    return null

  const header = _.get(
    currentStep,
    'description'
  )

  const { formSchema } = currentStep

  return (
    <Wrapper
      className="max-w-full md:px-3 lg:px-6 mx-auto"
      onClick={() =>
        formInstance.validateFields()
      }>
      {header}
      <FieldsFactory
        formSchema={formSchema}
      />
    </Wrapper>
  )
}

const ConfirmStage = () => {
  const t = useTranslate()
  const { values, currentStep } =
    useContext(StepFormContext)

  const header = _.get(
    currentStep,
    'description'
  )

  const { default_candidate } =
    values || {}

  if (_.isEmpty(currentStep))
    return null

  return (
    <div className="max-w-full md:px-3 lg:px-6 mx-auto">
      <div className=" mb-6">
        {header}
        <div className="flex flex-col space-y-6">
          <FlexCol>
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t('your information')}
            </span>
            <div className="flex items-center mb-3">
              <Avatar
                src={getAvatar(
                  default_candidate
                )}
                icon={
                  <IoPersonCircleOutline />
                }
                className="flex items-center justify-center"
              />
              <span className="text-lg flex-1 mx-3 font-bold text-primary">
                {getTitle(
                  default_candidate
                )}
              </span>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {[
                {
                  name: 'email',
                  label: 'email',
                },
                {
                  name: 'phone_number',
                  label: 'phone',
                },
                {
                  name: 'gender',
                  label: 'gender',
                  transform: (value) =>
                    getProp(
                      Object.values(
                        GenderTypes
                      ),
                      'label'
                    )(value) || value,
                  translate: t,
                },
                {
                  name: 'date_of_birth',
                  label: 'birthday',
                  transform: (value) =>
                    value
                      ? moment(
                          value
                        )?.format(
                          wishareConfigs.dateFormat
                        )
                      : undefined,
                },
              ].map(
                (
                  {
                    name,
                    label,
                    transform = renderSelf,
                    translate = renderSelf,
                  },
                  index
                ) => {
                  const _value =
                    transform(
                      _.get(
                        default_candidate,
                        name
                      )
                    )
                  return _value ? (
                    <div
                      className="flex items-center"
                      key={index}>
                      <span className="text-xs text-color-500 italic font-light">
                        {`${t(
                          label
                        )} : `}
                      </span>
                      <span className="text-sm font-medium text-color-000 ml-3">
                        {translate(
                          _value
                        )}
                      </span>
                    </div>
                  ) : null
                }
              )}
            </div>
          </FlexCol>
        </div>
      </div>
      <div className="p-3 background-200 rounded-lg text-center flex flex-col italic space-y-2 text-color-100 mb-6">
        <span>
          {t(
            'thank you for applying for this job'
          )}
        </span>
        <span>
          {t(
            'your application has been sent to the organization, please wait for a response from the organization'
          )}
        </span>
        <span>
          {t(
            'that may take a few days, please wait'
          )}
        </span>
      </div>
      <div className="text-sm text-center text-color-300 italic font-light mb-6">
        {t(
          'click the Apply now button to complete and receive the apply code'
        )}
      </div>
    </div>
  )
}

const FinalStage = React.memo(
  ({ onCancel = Null }) => {
    const t = useTranslate()

    const { item } = useContext(
      StepFormContext
    )

    return (
      <FlexCol className="items-center justify-center py-12 max-w-full md:max-w-3xl lg:max-w-2xl mx-auto">
        <div className="font-bold text-2xl text-green-500 uppercase">
          {t('complete registration')}
        </div>
        <span className="font-light text-center text-primary text-sm">
          {t(
            'you have just completed your registration as a volunteer for the program'
          )}
        </span>

        <span className="text-center text-3xl font-bold text-primary mt-2 mb-6 leading-tight">
          {_.get(
            item,
            'recruitment_event.title'
          )}
        </span>
        <span className="font-light text-center text-color-300 italic text-sm">
          {t(
            'description recruitment code'
          )}
        </span>
        <span className="px-3 py-1 border-2 border-secondary rounded-lg text-xl font-bold text-secondary my-3">
          {_.get(item, 'idcode')}
        </span>
        <span className="text-sm text-center text-color-300 italic font-light my-3">
          {t(
            'You can click the "Back campaign" button to return to the campaign'
          )}
        </span>

        <Button
          type="primary"
          className="no-shadow no-shadow border-none rounded-lg"
          onClick={onCancel}>
          {t('back campaign')}
        </Button>
      </FlexCol>
    )
  }
)

const stages = [
  {
    index: 0,
    name: 'candidate',
    title: 'candidate',
    component: StageItem,
    formSchema:
      recruitmentStepFormSchema.apply_step_1,
    actions: [
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: [
          'default_candidate',
        ],
        isDisabled: (values) =>
          _.some(
            Array.from(
              _.get(
                values,
                'required_information_fields'
              ) || []
            ).map((name) =>
              _.get(values, name)
            ),
            (value) => !value
          ),
      },
    ],
  },
  {
    index: 1,
    name: 'information',
    title: 'information',
    component: StageItem,
    formSchema:
      recruitmentStepFormSchema.apply_step_2,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: ['question_cleared'],
        isDisabled: (values) =>
          !_.get(
            values,
            'question_cleared'
          ),
      },
    ],
  },
  {
    index: 2,
    name: 'finish',
    title: 'finish',
    component: ConfirmStage,
    formSchema:
      recruitmentStepFormSchema.apply_step_3,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        label: 'apply now',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
        requireds: [],
      },
    ],
  },
  {
    index: 3,
    name: 'final step',
    title: 'final step',
    finished: true,
    component: FinalStage,
  },
]

const RecruitmentApplyFormContent = ({
  Wrapper = 'div',
  onCancel = Null,
}) => {
  const t = useTranslate()

  const params = useParams()

  const login = useSelector((state) => {
    const id = _.get(
      state,
      'auth.user.username'
    )
    return _.get(
      state,
      'entities.users.' + id,
      _.get(state, 'auth.user')
    )
  })

  const { recruitment: item } =
    useContext(RecruitmentContext)

  const [id] = [_.get(params, 'id')]

  const recruitment = useSelectEntities(
    id,
    recruitmentEventSchema,
    item
  )

  const renderHeader = useCallback(
    () => (
      <div className="flex justify-center uppercase font-bold text-green-700 text-xl md:text-3xl my-3">
        <center>
          {t('apply now')}
        </center>
      </div>
    ),
    [t]
  )

  const [formInstance] = Form.useForm()

  const initialValues = useMemo(() => {
    const {
      information_fields = {},
      collection_questions = [],
      required_information_fields = [],
    } = recruitment || {}

    const date_of_birth = _.get(
      login,
      'date_of_birth'
    )

    const information = {
      email: _.get(login, 'email'),
      gender: _.get(login, 'gender'),
      phone_number: _.get(
        login,
        'phone_number'
      ),
      date_of_birth: date_of_birth
        ? moment(date_of_birth).format(
            wishareConfigs.dateFormat
          )
        : undefined,
    }

    return {
      ...information,
      information_fields,
      collection_questions,
      question_cleared: _.isEmpty(
        collection_questions
      ),
      default_candidate: login,
      required_information_fields,
      information_values: information,
    }
  }, [login, recruitment])

  const validationSchema = undefined

  const steps = useMemo(() => {
    const title = getTitle(recruitment)

    const avatar = _.get(
      recruitment,
      'owner.avatar'
    )

    const owner = _.get(
      recruitment,
      'owner.title'
    )
    return stages.map(
      ({ ...rest }) => ({
        ...rest,
        description: renderIf(
          title,
          <div className="flex flex-col mb-6 border border-green-600 background rounded-lg p-3">
            <span className=" text-sm font-medium text-color-400 tracking-wide mb-1">
              {t(
                'your application will be sent to'
              )}
            </span>
            <div className="flex items-center font-bold text-green-700">
              <Avatar
                  src={avatar}
                  size={40}
                  className="flex flex-center background-100">
                <span className="text-color-300 font-medium uppercase">
                    {_.first(title)}
                </span>
              </Avatar>
              <div className="flex flex-col flex-1 mx-3">
                <span className="text-base md:text-lg max-lines-1">
                  {title}
                </span>
                <span className="text-xs font-light text-secondary italic">
                  {owner}
                </span>
              </div>
            </div>
          </div>
        ),
      })
    )
  }, [t, recruitment])

  const { addPost } = useContext(
    ObserverContext
  )

  const applied = _.get(
    recruitment,
    'applied_status'
  )

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo =
    recruitmentApi.recruitment_events_addCandidate_api

  const onSuccess = (
    result,
    { response },
    forward = Null
  ) => {
    successNotify(
      NotificationActionTypes.CREATE,
      t
    )
    const newItem =
      getResponseItem(response)
    addPost(newItem)
    forward(newItem)
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    candidate,
    question_cleared,
    default_candidate,
    information_fields,
    information_values,
    collection_answers,
    collection_questions,
    required_information_fields,
    ...values
  }) => ({
    ...values,
    ...(collection_answers
      ? {
          collection_answers:
            JSON.stringify(
              collection_answers
            ),
        }
      : {}),
    candidate_id: getId(
      default_candidate
    ),
    candidate_type: getType(
      default_candidate
    ),
    candidate_name: getTitle(
      default_candidate
    ),
  })

  const query = bindQueryParam({ id })

  const content = createStepForm(
    {
      query,
      apiInfo,
      onError,
      onSuccess,
    },
    {
      steps,
      onCancel,
      onPreSubmit,
      formInstance,
      initialValues,
      validationSchema,
      Header: renderHeader,
    }
  )

  return (
    <Wrapper className="space-y-3 py-3">
      {content}
    </Wrapper>
  )
}

const RecruitmentApplyForm = (
  props
) => {
  const ModalForm = withModalLayout(
    RecruitmentApplyFormContent,
    props
  )

  return (
    <AntdConfigWrapper>
      <ModalForm />
    </AntdConfigWrapper>
  )
}

export const recruitmentApplyingParams =
  {
    action: {
      name: 'action',
      value: 'apply',
    },
  }

export default createRequiredLogin()(
  RecruitmentApplyForm
)
