import { Radio } from 'antd'
import classNames from 'classnames'
import { renderDecoratedLabel } from 'components/form/FieldDecorator'
import FieldsFactory, {
  FieldHeader,
} from 'components/form/FieldsFactory'
import Pure from 'components/Pure'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  compose,
  withProps,
} from 'recompose'
import {
  Null,
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import { renderInputField } from 'views/Wishare/custom/FieldInputWrapper'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { CountryPicker } from 'views/Wishare/User/functions/renderLocationCascade'
import GivingAddressCascade from './GivingAddressCascade'
import GivingReferralSelectField from './GivingReferralSelectField'
import ReceiverRequiredFields from './ReceiverRequiredFields'
import ReferralInformationField from './ReferralInformationFields'

const ReferralModes = Object.freeze({
  WITH_ALIAS_NAME: {
    name: 'user-not-beneficiary',
    label: 'other',
    value: {
      alias: 1,
      as_beneficiary: 0,
    },
  },
  WITH_AUTH_USER: {
    name: 'user-is-beneficiary',
    label: 'you',
    value: {
      as_beneficiary: 1,
      alias: 0,
      alias_name: undefined,
    },
  },
})

const createReferralFormSchema = (
  fieldNames = []
) => [
  {
    children: [
      {
        name: 'referral',
        hideError: true,
        component:
          createControlledFormField({
            readOnly: true,
            Component:
              GivingReferralSelectField,
            title: 'select receiver',
          }),
      },
      {
        name: 'referral_information_fields',
        hideError: true,
        component:
          createControlledFormField({
            Component:
              ReferralInformationField,
            withValues: ({
              referral,
            }) => ({ referral }),
            Wrapper: ({ children }) => (
              <div className="mb-3 flex flex-col space-y-3">
                {children}
              </div>
            ),
          }),
      },
    ].filter(({ name }) =>
      fieldNames.includes(name)
    ),
  },
]

const renderRequiredLabel =
  ({ className, isRequired = true }) =>
  (text) => {
    return renderDecoratedLabel(
      text + ':',
      {
        isRequired,
        withLabel: () => ({
          className: classNames(
            className,
            'text-sm text-color-300 horizontal-form-label whitespace-no-wrap'
          ),
        }),
      }
    )
  }

const createBeneficiaryFormSchema = ({
  fieldNames = [],
}) => [
  {
    children: [
      {
        hideError: true,
        isRequired: true,
        className:
          'flex flex-col justify-center mb-3',
        label: 'receiver name',
        renderLabel:
          renderRequiredLabel({
            className: 'required-label',
          }),
        name: 'alias_name',
        component: renderInputField({
          className: 'flex-1',
          Component:
            createControlledFormField({
              placeholder:
                'alias name placeholder',
            }),
        }),
      },
      {
        name: 'referral',
        hideError: true,
        component:
          createControlledFormField({
            readOnly: true,
            Component:
              GivingReferralSelectField,
            // title: 'select receiver',
          }),
      },
      {
        hideError: true,
        name: 'receiver_information_fields',
        component:
          createControlledFormField({
            Component:
              ReceiverRequiredFields,
            Wrapper: ({ children }) => (
              <div className="mb-3 flex flex-col space-y-3">
                {children}
              </div>
            ),
          }),
      },
      {
        name: 'beneficiary_address',
        hideError: true,
        isRequired: true,
        label: 'address',
        renderLabel:
          renderRequiredLabel({
            className: 'required-label',
          }),
        className:
          'flex flex-col justify-center mb-3',
        component:
          createControlledFormField({
            placeholder:
              'address placeholder',
            className: 'flex-1',
          }),
      },
      {
        hideError: true,
        isRequired: true,
        className:
          'flex flex-col justify-center mb-3',
        label: 'country',
        renderLabel:
          renderRequiredLabel({
            className: 'required-label',
          }),
        name: 'beneficiary_country_id',
        component: renderInputField({
          className: 'flex-1',
          Component: compose(
            withProps({
              withChangedValues: (
                values
              ) => ({
                address: [, , ,],
                beneficiary_province_id:
                  undefined,
                beneficiary_district_id:
                  undefined,
                beneficiary_ward_id:
                  undefined,
              }),
            })
          )(CountryPicker),
        }),
      },
      {
        name: 'address',
        hideError: true,
        label: 'Province/district/ward',
        className:
          'flex flex-col justify-center mb-3',
        renderLabel: (
          text,
          { getValue = Null }
        ) =>
          renderIf(
            getValue(
              'beneficiary_country_id'
            ) === 237,
            renderRequiredLabel({
              isRequired: false,
            })(text)
          ),
        component:
          createControlledFormField({
            withValues: ({
              beneficiary_address,
              beneficiary_country_id,
            }) => ({
              title: null,
              address:
                beneficiary_address,
              country_id:
                beneficiary_country_id,
            }),
            Wrapper: ({ children }) => (
              <div className="flex-1">
                {children}
              </div>
            ),
            Component:
              GivingAddressCascade,
          }),
      },
    ].filter(({ name }) =>
      fieldNames.includes(name)
    ),
  },
]

const GivingAsBeneficiary = ({
  name,
  value: as_beneficiary,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const { values, setValues = Null } =
    useFormikContext()

  const options = [
    ReferralModes.WITH_AUTH_USER,
    ReferralModes.WITH_ALIAS_NAME,
  ]

  const defaultOption =
    _.find(
      options,
      ({ value: option_value }) =>
        _.get(option_value, name) ===
        as_beneficiary
    ) || _.first(options)

  return (
    <Wrapper>
      <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
        {t('register for')}
      </div>
      <Radio.Group
        name={name}
        className="flex items-center gap-2 mb-3"
        defaultValue={_.get(
          defaultOption,
          'value'
        )}
        onChange={(event) => {
          const { value = {} } =
            event.target
          setValues({
            ...values,
            ...value,
          })
        }}>
        {options.map(
          (
            { name, label, value },
            index
          ) => (
            <Radio
              key={index}
              name={name}
              value={value}>
              <span className="font-medium">
                {t(label)}
              </span>
            </Radio>
          )
        )}
      </Radio.Group>
      <div className="mt-3">
        <Pure input={[as_beneficiary]}>
          {!!!as_beneficiary && (
            <FieldHeader
              title="referral information"
              className="mt-2 border-b">
              <FieldsFactory
                formSchema={createReferralFormSchema(
                  [
                    'referral',
                    'referral_information_fields',
                  ]
                )}
              />
            </FieldHeader>
          )}

          <FieldHeader
            title="receiver information"
            className="mt-6 mb-3 border-b">
            <FieldsFactory
              formSchema={createBeneficiaryFormSchema(
                {
                  fieldNames: [
                    !!as_beneficiary
                      ? 'referral'
                      : 'alias_name',
                    'receiver_information_fields',
                    'beneficiary_address',
                    'beneficiary_country_id',
                    'address',
                  ].filter(Boolean),
                }
              )}
            />
          </FieldHeader>
        </Pure>
      </div>
    </Wrapper>
  )
}

export default GivingAsBeneficiary
