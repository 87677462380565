import { host_getDonationSummaryOfPeerOrgs_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, { useMemo } from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  renderNothing,
  withProps,
} from 'recompose'
import { Null } from 'views/Shared'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from 'views/Wishare/wishareFieldTypes'
import WorkspaceContext from '../../WorkspaceContext'
import {
  renderReportTable,
  renderReportTableWrapper,
} from './renderReportTable'
import ReportAdvancedFilter from './ReportAdvancedFilter'

const headers = [
  'organization',
  'donation',
  'donor',
  'contributions',
  'target',
  'value',
  'waiting value',
]

const schemas = [
  {
    name: 'title',
    value_path: 'title',
    className: '',
  },
  {
    name: 'total.donation_event_count',
    value_path:
      'total.donation_event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_event_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${Number(
    //     _.get(
    //       total,
    //       'organization_member_donation_event_count'
    //     )
    //   ).toLocaleString()}`,
  },
  {
    name: 'total.donor_count',
    value_path: 'total.donor_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donor_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${Number(
    //     _.get(
    //       total,
    //       'organization_member_donor_count'
    //     )
    //   ).toLocaleString()}`,
  },
  {
    name: 'total.donation_count',
    value_path: 'total.donation_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${_.get(
    //     total,
    //     'organization_member_donation_count'
    //   )}`,
  },
  {
    name: 'total.donation_target',
    value_path: 'total.donation_target',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_target',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${Number(
    //     _.get(
    //       total,
    //       'organization_member_donation_target'
    //     )
    //   ).toLocaleString()}`,
  },
  {
    name: 'total.donation_value',
    value_path: 'total.donation_value',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_value',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${Number(
    //     _.get(
    //       total,
    //       'organization_member_donation_value'
    //     )
    //   ).toLocaleString()}`,
  },
  {
    name: 'total.donation_waiting_value',
    value_path:
      'total.donation_waiting_value',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_waiting_value',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${Number(
    //     _.get(
    //       total,
    //       'organization_member_donation_waiting_value'
    //     )
    //   ).toLocaleString()}`,
  },
]

const DonationPeerOrgReport = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    currency_code: 'unit-code',
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const filter_currency_code =
    useSearchParam(
      filter_actions.currency_code
    )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          currency_code:
            filter_currency_code,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        PaginationMeta: Null,
        apiInfo:
          host_getDonationSummaryOfPeerOrgs_Api,
        renderWrap:
          renderReportTableWrapper(
            headers
          ),
        renderEmpty: () => (
          <tr>
            <td
              className="border p-2"
              colSpan={headers.length}>
              <EmptyHolder />
            </td>
          </tr>
        ),
        renderItem: renderReportTable({
          fields: schemas,
        }),
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      sortHeader: null,
      renderSearchBar: Null,
      SortByWidget: Null,
      // renderFilter: (
      //   __,
      //   filter_widget
      // ) => filter_widget,
      AdvancedFilter: withProps({
        defaultValue: {
          currency_code:
            getDefaultFieldValue(
              wishareFieldTypes.price_units
            ),
        },
      })(ReportAdvancedFilter),
      isToggleDefault: true,
    })
  return (
    <Wrapper className="space-y-3">
      <Translate>
        {(t) => (
          <div className="flex flex-col">
            <ListHeader title="donation summaries" />
            <span className="text-xs italic text-color-400 font-light">
              {t(
                '{value1} / {value2}: Number of organizations / total number of member organizations',
                {
                  value1: (
                    <span className="text-primary font-bold text-lg">
                      XX
                    </span>
                  ),
                  value2: (
                    <span className="text-color-200 font-light text-xs">
                      XX
                    </span>
                  ),
                }
              )}
            </span>
          </div>
        )}
      </Translate>
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  )
)(DonationPeerOrgReport)
