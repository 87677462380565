import { Input } from 'antd'
import { beneficiary_update_api } from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { mapProps } from 'recompose'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import { PhotoUploadComposite } from 'views/Wishare/custom/PhotoField'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import BeneficiaryContext from '../BeneficiaryContext'

const formFields = [
  { name: 'title' },
  { name: 'about' },
  { name: 'description' },
  { name: 'avatar' },
  { name: 'cover_photo' },
  { name: 'avatar_preview' },
  { name: 'cover_photo_preview' },
  { name: 'privacy', defaultValue: 1 },
  { name: 'active', defaultValue: 1 },
  { name: 'status', defaultValue: 1 },
]

const formSchema = [
  {
    children: [
      {
        name: 'avatar',
        component: mapProps(
          ({ form, onChange }) => {
            const values = _.get(
              form,
              'values'
            )
            return {
              values,
              onChange,
              title: _.get(
                values,
                'title'
              ),
            }
          }
        )(PhotoUploadComposite),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            maxLength: 160,
            showCount: true,
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
      },
      {
        label: 'active',
        name: 'active',
        component: withAdvancedOptions(
          baseFieldTypes.active
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'about',
    children: [
      {
        name: 'about',
        component:
          createControlledFormField({
            placeholder: 'about',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
]

const BeneficiarySettingOverview =
  () => {
    const t = useTranslate()
    const { beneficiary } = useContext(
      BeneficiaryContext
    )

    const validationSchema =
      useMemo(() => {
        return yupShape({
          title: requiredString(t),
        })
      }, [t])

    const id = getId(beneficiary)

    const initialized = useCallback(
      ({
        avatar,
        cover_photo,
        ...values
      }) => ({
        ...values,
        avatar_preview: avatar,
        cover_photo_preview:
          cover_photo,
      }),
      []
    )

    const onPreSubmit = ({
      avatar_preview,
      cover_photo_preview,
      ...values
    }) => ({
      ...values,
    })

    if (_.isEmpty(beneficiary))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: beneficiary,
      validationSchema,
      query: bindQueryParam({
        id,
      }),
      onPreSubmit,
      initialized,
      apiInfo: beneficiary_update_api,
    })
  }

export default BeneficiarySettingOverview
