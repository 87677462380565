import React from "react";

export const Lost = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 512 512"
			version="1.1"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
		>
			<g fill="#FFB69E">
				<path d="M476.884 184.664v-35.569c-.132-26.146-21.844-47.417-48.401-47.417h-93.079l-44.099-5.157L315.045 68c7.115-8.548 5.954-21.246-2.595-28.362-8.552-7.115-21.247-5.954-28.363 2.595l-47.519 57.089c-10.218 12.277-2.715 31.032 13.14 32.886l71.495 8.36V294.67h106.67V141.954h.609c4.233 0 8.105 3.5 8.124 7.242V297.147c0 11.122 9.017 20.139 20.139 20.139s20.139-9.016 20.139-20.139V184.664z"></path>
				<circle cx="374.6" cy="41.738" r="41.738"></circle>
			</g>
			<g fill="#414B82">
				<path d="M379.541 286.893v200.941c0 13.347 10.819 24.166 24.166 24.166s24.166-10.82 24.166-24.166V286.893h-48.332zM321.204 286.893v200.941c0 13.347 10.819 24.166 24.166 24.166s24.166-10.82 24.166-24.166V286.893h-48.332z"></path>
			</g>
			<path
				fill="#78BEFF"
				d="M476.884 184.664v-35.569c-.132-26.146-21.844-47.417-48.401-47.417h-35.389v.003c.453 10.113-8.931 18.726-18.726 18.726-10.498 0-18.272-8.58-18.726-18.726v-.003h-20.238l-44.099-5.157-9.226 39.323 39.125 4.692V294.67h106.67V141.954h.609c4.233 0 8.105 3.5 8.124 7.242v35.468h40.277z"
			></path>
			<path
				fill="#C8A06E"
				d="M247.164 220.65a9.904 9.904 0 01-8.302 4.504H135.898V164.13h102.964a9.904 9.904 0 018.302 4.504l15.885 24.422a2.91 2.91 0 010 3.172l-15.885 24.422z"
			></path>
			<path
				fill="#A56941"
				d="M24.633 289.756a9.904 9.904 0 008.302 4.504h102.964v-61.024H32.935a9.904 9.904 0 00-8.302 4.504L8.747 262.162a2.91 2.91 0 000 3.172l15.886 24.422z"
			></path>
			<path
				fill="#733319"
				d="M148.521 498.951V143.292c0-6.971-5.652-12.623-12.623-12.623-6.971 0-12.623 5.652-12.623 12.623v355.659h25.246z"
			></path>
			<path
				fill="#592713"
				d="M178.164 512H93.632c-6.971 0-12.623-5.652-12.623-12.623s5.652-12.623 12.623-12.623h84.532c6.971 0 12.623 5.652 12.623 12.623S185.135 512 178.164 512z"
			></path>
			<path
				fill="#C8D2DC"
				d="M492.435 39.165c-5.543 4.334-5.644 7.358-5.644 12.599 0 1.915-1.008 4.132-5.946 4.132-4.132 0-5.543-1.512-5.543-6.753 0-8.668 3.83-12.8 6.753-15.319 3.326-2.822 8.97-5.946 8.97-11.388 0-4.636-4.032-6.854-9.071-6.854-10.28 0-8.063 7.76-13.506 7.76-2.721 0-6.047-1.814-6.047-5.745 0-5.442 6.249-13.505 19.855-13.505 12.901 0 21.467 7.157 21.467 16.63s-8.567 16.327-11.288 18.443zm-3.729 31.445c0 4.132-3.427 7.76-7.761 7.76s-7.66-3.628-7.66-7.76c0-4.233 3.427-7.76 7.66-7.76s7.761 3.528 7.761 7.76z"
			></path>
		</svg>
	);
}

export default Lost;
