import {
  beneficiary_getById_api,
  donor_getById_api,
  staff_getStaffById_Api,
} from 'apis'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  branch,
  compose,
  fromRenderProps,
  mapProps,
  renderComponent,
  withProps,
} from 'recompose'
import { getResponseItem } from 'views/Shared'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { wisharePaths } from 'views/Wishare/configs'
import { WishareEntities } from 'views/Wishare/enums'
import createModuleLayout from 'views/Wishare/factory/createModuleLayout'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import PageTemplate from 'views/Wishare/Templates/PageTemplate'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import { OrganizationContainerProvider } from '../OrganizationContainerContext'
import { organizationProfilesEntities } from '../organizationRouteEntities'
import OrganizationProfilesBanner from './OrganizationProfilesBanner'
import OrganizationProfilesContent from './OrganizationProfilesContent'
import OrganizationProfilesSider from './OrganizationProfilesSider'

export const OrganizationProfilesContext =
  React.createContext({})

export const OrganizationProfilesProvider =
  ({
    id,
    type,
    children,
    organization_id,
  }) => {
    let asyncParams = {}

    switch (type) {
      case WishareEntities.DONOR:
        asyncParams = {
          apiInfo: donor_getById_api,
          query: bindQueryParam({ id }),
        }
        break
      case WishareEntities.STAFF:
        asyncParams = {
          apiInfo:
            staff_getStaffById_Api,
          query: bindQueryParam({ id }),
        }
        break
      case WishareEntities.BENEFICIARY:
        asyncParams = {
          apiInfo:
            beneficiary_getById_api,
          query: bindQueryParam({ id }),
        }
        break
      default:
        break
    }

    const {
      error,
      response,
      isLoading = false,
      handleAsyncAction,
    } = useAsyncAction(asyncParams)

    useEffect(() => {
      if (id && type) {
        handleAsyncAction()
      }
    }, [id, type, organization_id])

    const item =
      getResponseItem(response)

    if (!_.isEmpty(error)) {
      return <WishareNotFound />
    }

    return (
      <OrganizationProfilesContext.Provider
        key={[
          id,
          type,
          organization_id,
        ].join('/')}
        value={{
          id,
          type,
          item,
          error,
          isLoading,
          organization_id,
        }}>
        {children}
      </OrganizationProfilesContext.Provider>
    )
  }

const OrganizationProfiles = ({
  id,
  sub_id,
  sub_type,
  ...props
}) => (
  <OrganizationContainerProvider
    item={id}
    routeEntities={
      organizationProfilesEntities
    }>
    <OrganizationProfilesProvider
      {...{
        id: sub_id,
        type: sub_type,
        organization_id: id,
      }}>
      {createModuleLayout({
        Sider:
          OrganizationProfilesSider,
        Banner:
          OrganizationProfilesBanner,
        Template: PageTemplate,
        ...props,
      })(
        <OrganizationProfilesContent />
      )}
    </OrganizationProfilesProvider>
  </OrganizationContainerProvider>
)

export default compose(
  withRouter,
  fromRenderProps(
    SubDomainContext.Consumer,
    ({ id }) => ({
      id,
    })
  ),
  mapProps(({ id, match }) => ({
    id,
    ..._.pick(_.get(match, 'params'), [
      'id',
      'sub_id',
      'sub_type',
    ]),
  })),
  branch(
    ({ id, sub_id, sub_type }) =>
      _.some(
        [id, sub_id, sub_type],
        _.isEmpty
      ),
    renderComponent(
      withProps({ redirectUrl: '/' })(
        WishareNotFound
      )
    )
  ),
  branch(
    ({ sub_id, sub_type }) =>
      _.some(
        [sub_id, sub_type],
        _.isEmpty
      ),
    ({ id }) =>
      () =>
        (
          <WishareNotFound
            redirectUrl={wisharePaths.organization.replace(
              ':id',
              id
            )}
          />
        )
  )
)(OrganizationProfiles)
