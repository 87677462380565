import React from 'react'
import NoFoundPage from 'views/NoFoundPage'

const RecruitmentNotFound = () => {
  return (
    <div className="flex w-full justify-center">
      <NoFoundPage />
    </div>
  )
}

export default RecruitmentNotFound
