import React from 'react'
import TopicNews from './holders/TopicNews'
import createEntityListByTopic from "../factory/createEntityListByTopic";
import TopicContext from "./TopicContext";
import TopicGeneral from "./holders/TopicGeneral";

export const topicRouteEntities = [
  {
    path: 'general',
    component: TopicGeneral,
    breadcrumbName: (t) => t('general'),
  },
  {
    path: 'news',
    component: TopicNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'organizations',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "organization")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('organizations'),
  },
  {
    path: 'activities',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "activity")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('activities'),
  },
  {
    path: 'donations',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "donation_event")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('crowdfunding'),
  },
  {
    path: 'givings',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "giving_event")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('givings'),
  },
  {
    path: 'jobs',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "recruitment_event")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('jobs'),
  },
  {
    path: 'events',
    component: () => (
      <TopicContext.Consumer>
        {({topic}) =>
          createEntityListByTopic(topic, "event")
        }
      </TopicContext.Consumer>
    ),
    breadcrumbName: (t) => t('events'),
  },
]
