import * as moment from 'moment'

export function convertToMoment(x) {
  let m = moment(x)
  if (!m.isValid())
    m = moment(x, 'DD/MM/YYYY')
  if (!m.isValid())
    m = moment(x, 'DD-MM-YYYY')
  if (!m.isValid())
    m = moment(x, 'DD/MM/YY')
  if (!m.isValid())
    m = moment(x, 'MM/DD/YY')

  return m
}

export const displayMomentValues = (
  array = [],
  format = 'DD/MM/YYYY',
  fallbackValue = '-/-'
) =>
  Array.from(array).map((date) => {
    if (
      !date ||
      !moment(date).isValid()
    ) {
      return fallbackValue
    }
    return moment(date).format(format)
  })
