import React, {useContext} from 'react'
import useTranslate from "../../../../modules/local/useTranslate";
import _ from "lodash"
import {mapPathParam} from "../../functions/routerHelper";
import {Spin} from "antd";
import CustomLazyPaginationWrapper from "../../../../modules/asyncCache/components/CustomLazyPaginationWrapper";
import {renderHashTag} from "../../Tags/renderHashTag";
import Translate from "../../../../modules/local/Translate";
import EmptyHolder from "../../../../components/EmptyHolder";
import CustomMenuHeader from "../../custom/CustomMenuHeader";
import TopicContext from "../TopicContext";
import {keywords_getRelatedTags_Api} from "../../../../apis/topic";

const TopicRelatedTags = ({...props}) => {
  const t = useTranslate();
  const {topic} = useContext(TopicContext);
  const id = _.get(topic, 'idname', topic);

  const apiInfo =
    keywords_getRelatedTags_Api

  const cacheId = mapPathParam(
    apiInfo.path,
    {id}
  )

  if (!topic) return null;

  return (
    <CustomLazyPaginationWrapper
      auto={false}
      sample={30}
      id={cacheId}
      key={cacheId}
      apiInfo={keywords_getRelatedTags_Api}
      query={{
        ':id': id
      }}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Spin className="m-auto"/>
        </div>
      )}
      renderItem={renderHashTag}
      renderEmpty={() => (
        <Translate>
          {(t) => (
            <EmptyHolder
              subTitle={t('empty')}
              title={null}
            />
          )}
        </Translate>
      )}
      renderWrap={(children) => (
        <div className="flex flex-col">
          <CustomMenuHeader title="related tags"/>
          <div className="flex flex-wrap gap-1">
            {children}
          </div>
        </div>
      )}
    />
  )
};

export default TopicRelatedTags
