import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import withTranslate from 'modules/local/withTranslate'
import {
  useCallback,
  useContext,
} from 'react'
import {
  compose,
  mapProps,
} from 'recompose'
import { withAppConfig } from 'views/Wishare/composers'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { MultiSelectDataSource } from 'views/Wishare/custom/SelectDataSource'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  mapEntityOptions,
  mapSingleValue,
} from 'views/Wishare/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from 'views/Wishare/wishareFieldTypes'
import CustomRichEditor from '../../custom/CustomRichEditor'
import { createDateRangerPicker } from '../../factory/createDateRangerPicker'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  { name: 'position_title' },
  { name: 'job_description' },
  {
    name: 'work_time_type',
    defaultValue: getDefaultFieldValue(
      wishareFieldTypes.recruitment
        .work_time_types
    ),
  },
  {
    name: 'is_remote_work',
    defaultValue: Number(
      getDefaultFieldValue(
        wishareFieldTypes.recruitment
          .onSiteOrRemote
      )
    ),
  },
  {
    name: 'occupation_types',
  },
  {
    name: 'reserved_job_types',
  },
]

const formSchema = [
  {
    title: 'job position',
    children: [
      {
        name: 'position_title',
        component:
          createControlledFormField({
            placeholder: 'position',
          }),
      },
    ],
  },
  {
    title: 'job description',
    children: [
      {
        name: 'job_description',
        component:
          createControlledFormField({
            placeholder: 'description',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'work type',
        name: 'work_time_type',
        component: withAdvancedOptions(
          wishareFieldTypes.recruitment
            .work_time_types
        )(RadioOptions),
      },
      {
        label: 'is remote work',
        name: 'is_remote_work',
        component: withAdvancedOptions(
          wishareFieldTypes.recruitment
            .onSiteOrRemote
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'work time',
    children: [
      {
        name: 'start_date',
        component:
          createDateRangerPicker([
            'start_date',
            'end_date',
          ]),
      },
    ],
  },
  {
    title: 'occupation types',
    children: [
      {
        name: 'occupation_types',
        component: compose(
          withAppConfig,
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              appConfig,
              translate,
            }) => ({
              name,
              value,
              onChange,
              options: mapEntityOptions(
                _.get(
                  appConfig,
                  'occupation_types'
                ) || [],
                {
                  getLabel: (item) =>
                    translate(
                      getTitle(item)
                    ),
                }
              ),
              placeholder: translate(
                'occupation types'
              ),
            })
          )
        )(MultiSelectDataSource),
      },
    ],
  },
  {
    title: 'reserved job types',
    children: [
      {
        name: 'reserved_job_types',
        component: compose(
          withAppConfig,
          withTranslate,
          mapProps(
            ({
              name,
              value,
              title,
              onChange,
              appConfig,
              translate,
            }) => ({
              name,
              value,
              onChange,
              options: mapEntityOptions(
                _.get(
                  appConfig,
                  'reserved_job_types'
                ) || [],
                {
                  getLabel: (item) =>
                    translate(
                      getTitle(item)
                    ),
                }
              ),
              placeholder: title,
            })
          )
        )(MultiSelectDataSource),
      },
    ],
  },
]

const RecruitmentSettingJobDescription =
  () => {
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema = undefined

    const recruitment_id = getId(
      recruitment
    )

    const initialized = useCallback(
      ({
        occupation_types,
        reserved_job_types,
        ...values
      }) => {
        return {
          ...values,
          occupation_types:
            mapSingleValue(
              occupation_types
            ),
          reserved_job_types:
            mapSingleValue(
              reserved_job_types
            ),
        }
      },
      []
    )

    const onPreSubmit = ({
      ...values
    }) => values

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      initialized,
      onPreSubmit,
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingJobDescription
