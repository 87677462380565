import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import React, { useState } from 'react'
import { useScrollPosition } from 'views/MainPage/useScrollPosition'
import {
  renderIf,
  renderOwnChild,
} from 'views/Shared'

const FABContainer = ({
  style,
  children,
  className,
  prevOffset = -500,
  currentOffset = -400,
  Wrapper = renderOwnChild,
}) => {
  const [hasFab, toggleFAB] =
    useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y > prevOffset) {
        if (currPos.y < currentOffset) {
          toggleFAB(true)
        } else {
          toggleFAB(false)
        }
      }
    }
  )

  return (
    <Wrapper>
      <LayoutContext.Consumer>
        {({ isSm }) => {
          const isFloating = Boolean(
            isSm && hasFab
          )
          return (
            <React.Fragment>
              {renderIf(
                isFloating,
                <div
                  style={style}
                  className={className}>
                  {children}
                </div>
              )}
              <div
                style={{
                  visibility: isFloating
                    ? 'hidden'
                    : 'visible',
                }}
                className="z-30">
                {children}
              </div>
            </React.Fragment>
          )
        }}
      </LayoutContext.Consumer>
    </Wrapper>
  )
}

export const DonationFAB = ({
  children,
}) => (
  <FABContainer
    prevOffset={-500}
    currentOffset={-400}
    className="fixed bottom-0 z-30"
    style={{
      left: '0.5rem',
      right: '0.5rem',
      bottom:
        'calc(var(--header-height) + 10px)',
    }}>
    {children}
  </FABContainer>
)

export const GivingFAB = ({
  children,
}) => (
  <FABContainer
    prevOffset={-500}
    currentOffset={-400}
    className="fixed bottom-0 z-30"
    style={{
      left: '0.5rem',
      right: '0.5rem',
      bottom:
        'calc(var(--header-height) + 10px)',
    }}>
    {children}
  </FABContainer>
)

export const RecruitmentFAB = ({
  children,
}) => (
  <FABContainer
    prevOffset={-500}
    currentOffset={-400}
    className="fixed bottom-0 z-30"
    style={{
      left: '0.5rem',
      right: '0.5rem',
      bottom:
        'calc(var(--header-height) + 10px)',
    }}>
    {children}
  </FABContainer>
)

export default FABContainer
