import _ from 'lodash'
import React, { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { createAsyncAction } from '..'
import { getAsynCacheSelector } from '../selectors'
import CustomLazyPagination, {
  transformToRecordAction,
} from './CustomLazyPagination'

export default function CustomLazyPaginationWrapper({
  renderWrap,
  alwaysVisible = false,
  isLoading = false,
  ...props
}) {
  const { id, apiInfo, query, values } =
    props
  const recordId = id || apiInfo.path
  const startAction =
    transformToRecordAction(
      recordId,
      createAsyncAction({
        asyncId: recordId,
        query,
        values,
        apiInfo,
      })
    )
  const dispatch = useDispatch()
  const { success, response } =
    useSelector((state) =>
      getAsynCacheSelector(
        state,
        startAction.asyncId
      )
    )

  useEffect(() => {
    if (!isLoading && !success)
      dispatch(startAction)
  }, [])

  if (
    (isLoading || !success) &&
    props.renderLoading
  ) {
    return props.renderLoading()
  }

  if (!success) return null
  const length = _.get(
    response,
    'data.data.length',
    0
  )
  if (!length && !alwaysVisible)
    return null
  const excluded =
    !length || !!props.auto
      ? _.omit(props, [
          'renderLoadMore',
        ])
      : props
  if (renderWrap) {
    return renderWrap(
      <CustomLazyPagination
        startAction={startAction}
        {...excluded}
      />,
      startAction,
      length
    )
  }
  return (
    <CustomLazyPagination
      startAction={startAction}
      {...excluded}
    />
  )
}
