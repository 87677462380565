import EmptyHolder from 'components/EmptyHolder'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import DonationBenificiary from '../components/DonationBenificiary'
import DonationContext from '../DonationContext'

const DonationInfo = ({
  Wrapper = FlexCol,
}) => {
  const { donation } = useContext(
    DonationContext
  )

  const { donation_guide } =
    donation || {}

  const guide_state = useMemo(
    () =>
      isRawContentEmpty(donation_guide)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(donation_guide)
            )
          ),
    [donation_guide]
  )

  if (_.isEmpty(donation)) {
    return null
  }

  return (
    <Wrapper className="items-start space-y-6">
      {guide_state ? (
        <HeaderItemInfo title="donation guide">
          <div className="px-2 border border-color-50 rounded-lg">
            <Renderer
              editorState={guide_state}
            />
          </div>
        </HeaderItemInfo>
      ) : (
        <EmptyHolder title="donation guide" />
      )}
      <DonationBenificiary
        Wrapper={withProps({
          title:
            'beneficiary information',
        })(HeaderItemInfo)}
      />
    </Wrapper>
  )
}

export default DonationInfo
