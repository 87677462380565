export default {
  "00000-KM":"TRAN",
  "(yes/no) question":'(បាទ / ចាស / ទេ) សំណួរ',
  "{beneficiaryNbr} have beneficiaryed":'ប្រាថ្នា',
  "0 result":'លទ្ធផល',
  "1. no poverty":'បំបាត់ភាពក្រីក្រ',
  "10. reduced inequalities":'កាត់បន្ថយវិសមភាព',
  "11. sustainable cities and communities":'ទីក្រុងនិងសហគមន៍ប្រកបដោយនិរន្តរភាព',
  "12. responsible consumption and production":'ទទួលខុសត្រូវលើការប្រើប្រាស់ និងផលិតកម្ម',
  "13. climate action":'សកម្មភាពអាកាសធាតុ',
  "14. life below water":'ធនធានទឹក',
  "15. life on land":'ធនធានដីធ្លី',
  "16. peace, justice and strong institutions":'សន្តិភាព និងយុត្តិធម៌',
  "17 SDGs":'គោលដៅ',
  "17. partnerships for the goals":'ភាពជាដៃគូសម្រាប់គោលដៅ',
  "2. zero hunger":'បាត់ភាពអត់ឃ្លាន',
  "3. good health and well-being":'សុខភាពល្អនិងសុខុមាលភាព',
  "4. quality education":'គុណភាពនៃការអប់រំ',
  "5. gender equality":'សមភាពភេទ',
  "6. clean water and sanitation":'6. ទឹកស្អាតនិងអនាម័យ',
  "7. affordable and clean energy":'មានតំលៃសមរម្យ និងថមពលស្អាត',
  "8. decent work and economic growth":'8. ការងារសមរម្យនិងកំណើនសេដ្ឋកិច្ច',
  "9. industry, innovation and infrastructure":'9- ឧស្សាហកម្មការច្នៃប្រឌិតនិងហេដ្ឋារចនាសម្ព័ន្ធ',
  "A":'នៃ',
  "a post":'ផុសអត្ថបទ',
  "a beneficiary of":'បំណងប្រាថ្នារបស់',
  "about":'អំពី',
  "about job":'អំពីការងារ',
  "about us":'អំពី​ពួក​យើង',
  "about wishare":'អំពីការចង់បាន',
  "abouthelp":'អំពីវីស៊ែរ',
  "abouthelptips":'អំពីវីស៊ែរ/ក្រុមហ៊ុន/ទំនាក់ទំនង',
  "accept":'ទទួលស្គាល់',
  "accepted":'ការទទួលស្គាល់',
  "access to a huge human capital for good":'ការចូលទៅកាន់ធនធានមនុស្សដ៏ធំមួយសម្រាប់ការអំពើល្អ',
  "account":'គណនី',
  "account name":'ឈ្មោះ​គណនី',
  "activated":'បានធ្វើអោយសកម្ម',
  "activation":'ធ្វើអោយសកម្ម',
  "active":'សកម្ម',
  "activetips":'ទំព័រកំពុងដំណើរការ អ្នកមានសិទ្ធិគ្រប់គ្រងកែសម្រួល',
  "activities":'សកម្មភាព',
  "activity":'សកម្មភាព',
  "activity information":'ព័ត៌មានសកម្មភាព',
  "Activity is the smallest project unit with full mission and action. Your organization may have many different activities.":'សកម្មភាពគឺជាអង្គភាពគម្រោងតូចបំផុតដែលមានបេសកកម្មនិងសកម្មភាពពេញលេញ។ អង្គការរបស់អ្នកអាចមានសកម្មភាពផ្សេងៗគ្នាជាច្រើន។',
  "activity name":'ឈ្មោះសកម្មភាព',
  "activitytips":'សកម្មភាពគឺជាការប្រមូលផ្ដុំនៃការងារដែលមានគោលដៅច្បាស់លាស់ដើម្បីបម្រើសង្គម។ សកម្មភាពនេះអាចប្រមូលធនធានសហគមន៍ដើម្បីសម្រេចគោលដៅរបស់ខ្លួន។',
  "add":'បន្ថែម',
  "add activity":'បន្ថែមសកម្មភាព',
  "add album":'បន្ថែមអាល់ប៊ុម',
  "add an image":'បន្ថែមរូបភាព',
  "add auction item":'បន្ថែមផលិតផលដេញថ្លៃ',
  "add campaign":'បន្ថែមយុទ្ធនាការ',
  "add categories":'ជ្រើសរើសការយកចិត្តទុកដាក់របស់អ្នក',
  "add delivery policy":'បន្ថែមគោលការណ៍ដឹកជញ្ជូន',
  "add donation":'បន្ថែមការបរិច្ចាគ',
  "add donation campaigns":'បន្ថែមយុទ្ធនាការបរិច្ចាគ',
  "add location":'បន្ថែមទីតាំង',
  "add member":'បន្ថែមសមាជិក',
  "add more":'បន្ថែម​ទៀត',
  "add new":'បន្ថែម​ថ្មី',
  "add new language":'បន្ថែមភាសាថ្មី',
  "add new map":'បន្ថែមផែនទីថ្មី',
  "add new product":'បន្ថែមផលិតផលថ្មី',
  "add new schedule":'បន្ថែមកាលបរិច្ចេតថ្មី',
  "add notice":'បន្ថែមការជូនដំណឹង',
  "add photos":'បន្ថែមរូបថត',
  "add product":'បន្ថែមផលិតផល',
  "add promotion campaigns":'បន្ថែមយុទ្ធនាការផ្សព្វផ្សាយ',
  "add return policy":'បន្ថែមគោលការណ៍ផ្លាស់ប្ដូរ',
  "add tag":'បន្ថែមស្លាកភ្ជាប់',
  "add to cart":'បន្ថែមចូលរទេះ',
  "add warranty policy":'បន្ថែមគោលការណ៍ធានារ៉ាប់រង',
  "add your comment":'បន្ថែមយោបល់របស់អ្នក',
  "add your school":'បន្ថែមសាលារបស់អ្នក',
  "add your skills":'បន្ថែមជំនាញរបស់អ្នក',
  "add your workplace":'បន្ថែមកន្លែងធ្វើការរបស់អ្នក',
  "add yours recommendations":'បន្ថែមអនុសាសន៍របស់អ្នក',
  "added hosts":'បន្ថែមអង្គភាពតំណាង',
  "additional message":'បន្ថែមខ្លឹមសារ',
  "address":'អាសយដ្ឋាន',
  "addressed":'បានផ្ញើររួច',
  "adhoc":'ពិសេស',
  "admin":'រដ្ឋបាល',
  "advanced":'កម្រិតខ្ពស់',
  "affordable and clean energy":'មានតំលៃសមរម្យ និងថមពលស្អាត',
  "age":'អាយុ',
  "age_from":'អាយុពី',
  "age_from_placeholder":'អាយុពី',
  "age_to":'ដល់អាយុ',
  "age_to_placeholder":'អាយុទៅ',
  "agree":'យល់ព្រម',
  "all":'ទាំងអស់',
  "ambassador":'ឯកអគ្គរដ្ឋទូត',
  "amount":'ចំនួន',
  "An":'មួយ',
  "and":'និង',
  "and petition":'និងញត្តិ',
  "and social impact work":'និងការងារឥទ្ធិពលដល់សង្គម',
  "animal":'សត្វ',
  "anonymous":'អនាមិក',
  "answer":'ចម្លើយ',
  "applicant note":'កំណត់ចំណាំអ្នកដាក់ពាក្យ',
  "applicants":'បេក្ខជន',
  "applied a job":'ដាក់ពាក្យសុំការងារ',
  "applied beneficiaries":'ដាក់បំណងប្រាថ្នា',
  "applies":'អនុវត្ត',
  "apply":'ការអនុវត្ត',
  "apply a new beneficiary":'ដាក់បំណងប្រាថ្នាថ្មី',
  "apply an existing beneficiary":'ដាក់បំណងប្រាថ្នាថ្មីពីវីស៊ែរ',
  "apply now":'ដាក់ពាក្យ​ឥឡូវនេះ',
  "apply now!":'ដាក់ពាក្យ​ឥឡូវនេះ!',
  "apply technology to support":'អនុវត្តបច្ចេកវិទ្យាដើម្បីជំនួយ',
  "apply to become a volunteer":'ដាក់ពាក្យសុំធ្វើជាអ្នកស្ម័គ្រចិត្ត',
  "approve":'យល់ព្រម',
  "approved":'បានអនុម័ត',
  "are bidding":'កំពុងដេញថ្លៃ',
  "are you agree to remove item from order?":'តើអ្នកយល់ព្រមដកធាតុចេញពីលំដាប់ទេ?',
  "are you sure to cancel this order?":'តើអ្នកប្រាកដចង់បោះបង់ការបញ្ជាទិញនេះហើយឬ?',
  "are you sure you want to remove this item?":'តើអ្នកពិតជាចង់យកផលិតផលនេះចេញឬ?',
  "art & design":'សិល្បៈនិងរចនា',
  "Art, Collections & Antiques":'សិល្បៈការប្រមូលនិងវត្ថុបុរាណ',
  "asc":'ឡើង',
  "ask questions":'សួរ​សំនួរ',
  "asking":'ការសួរ',
  "at least":'យ៉ាងហោចណាស់',
  "attributes":'គុណលក្ខណៈ',
  "attributes list":'បញ្ជីឈ្មោះ',
  "auction":'ការដេញថ្លៃ',
  "auction donation campaign":'យុទ្ធនាការផ្តល់ការដេញថ្លៃ',
  "auction history":'ប្រវត្តិដេញដោល',
  "auction information":'ព័ត៌មានដេញថ្លៃ',
  "auction items":'ផលិតផលដេញថ្លៃ',
  "auction management":'ការគ្រប់គ្រងការដេញថ្លៃ',
  "auction members":'សមាជិកដេញថ្លៃ',
  "auction name":'ឈ្មោះដេញថ្លៃ',
  "auction product":'ផលិតផលដេញថ្លៃ',
  "auction products":'ផលិតផលដេញថ្លៃ',
  "auction settings":'ការកំណត់ការដេញថ្លៃ',
  "auctioned price":'តម្លៃដាក់ដេញ',
  "auctions":'ដេញថ្លៃ',
  "automatic":'អនុវត្តដោយស្វ័យប្រវត្តិ',
  "automatic execution":'ការប្រតិបត្តិដោយស្វ័យប្រវត្តិ',
  "Automotive Motorcycles":'ម៉ូតូនិងយានយន្ត',
  "available":'មានស្រាប់',
  "available activities":'សកម្មភាពដែលមាន',
  "available for social work":'មានសម្រាប់ការងារសង្គម',
  "avatar":'រូបតំណាង',
  "average rating point":'ចំណុចវាយតម្លៃមធ្យម',
  "Babies & Toys":'កុមារនិងប្រដាប់លេង',
  "back":'ត្រឡប់',
  "back to shop":'ត្រលប់ទៅហាងវិញ',
  "basic information":'ព័ត៌មានមូលដ្ឋាន',
  "be a part of growing trend":'ធ្វើជាផ្នែកមួយនៃការកើនឡើងនៃនិន្នាការកើនឡើង',
  "be the first to bid":'ក្លាយជាអ្នកដំបូងដែលដេញថ្លៃ',
  "become a member of wishare":'ក្លាយជាសមាជិករបស់វីស៊ែរឥតគិតថ្លៃ',
  "become a volunteer ":'ក្លាយជាអ្នកស្ម័គ្រចិត្ត',
  "Before creating a new one, please see if your wishare already exists. In the case of Wishare\'s indexing, please contact us so we can re-administer it.":'មុនពេលបង្កើតថ្មីមួយសូមមើលថាតើអ្នកមានរួចហើយឬនៅ។ ក្នុងករណីបានបង្កើតវីស៊ែរហើយ សូមទាក់ទងមកយើងខ្ញុំអាចគ្រប់គ្រងវាឡើងវិញបាន។',
  "begin":'ចាប់ផ្ដើម',
  "benefits":'អត្ថប្រយោជន៍',
  "benefits and obligations":'អត្ថប្រយោជន៍និងកាតព្វកិច្ច',
  "best sales":'លក់ដាច់បំផុត',
  "bid":'ការបង្គាប់',
  "bid now":'ការដេញថ្លៃឥឡូវនេះ',
  "bid price":'តម្លៃដេញថ្លៃ',
  "bid time":'ពេលវេលាដេញថ្លៃ',
  "bidder":'អ្នកដេញថ្លៃ',
  "bidding":'ការដេញថ្លៃ',
  "bidding / asking":'ការដេញថ្លៃ: * ការដេញថ្លៃបន្ទាប់នឹងខ្ពស់ជាងការដេញថ្លៃពីមុនអ្នកដេញថ្លៃខ្ពស់បំផុតនឹងឈ្នះការដេញថ្លៃ។ ការស្នើសុំ: * ការដេញថ្លៃបន្ទាប់នឹងទាបជាងការដេញថ្លៃពីមុនអ្នកដែលអញ្ជើញតម្លៃទាបបំផុតនឹងឈ្នះការដេញថ្លៃ។',
  "bill":'វិក័យប័ត្រ',
  "binding_conditions":'លក្ខខណ្ឌចងភ្ជាប់',
  "binding_conditions_placeholder":'លក្ខខណ្ឌចងភ្ជាប់',
  "block":'បិទខ្ទប់',
  "blocked":'បានបិទខ្ទប់',
  "blockedtips":'សុំទោស។ មាតិការបស់អ្នកមិនសមរម្យទេមិនត្រូវបានអនុញ្ញាតឱ្យបង្ហាញជាសាធារណៈទេ',
  "Books, Stationery & Gifts":'សៀវភៅការិយាល័យសម្ភារៈការិយាល័យនិងអំណោយ',
  "bought":'បានទិញ',
  "but let us help measure your impact and discover the social activities around you":'ប៉ុន្តែសូមឱ្យយើងជួយវាស់វែងផលប៉ះពាល់របស់អ្នកនិងស្វែងរកសកម្មភាពសង្គមនៅជុំវិញអ្នក',
  "but they can not find aright one":'ប៉ុន្តែពួកគេមិនអាចរកឃើញយ៉ាងត្រឹមត្រូវ',
  "but they can not find the support":'ប៉ុន្តែពួកគេមិនអាចរកឃើញការគាំទ្របានទេ',
  "buy now":'ទិញ​ឥឡូវនេះ',
  "by":'ដោយ',
  "campaign name":'ឈ្មោះយុទ្ធនាការ',
  "cancel ":'លប់ចោល',
  "cancel order":'បោះបង់ការបញ្ជាទិញ',
  "cancelled":'បានលប់ចោល',
  "career":'អាជិព',
  "careers":'ជ្រើសរើស',
  "cart":'រទេះ',
  "categories":'ប្រភេទ',
  "categoriesplaceholder":'ជ្រើសរើសប្រភេទ',
  "category":'ប្រភេទ',
  "certificate":'វិញ្ញាប័នប័ត្រ',
  "change":'ការប្ដូរ',
  "change cover photo":'ផ្លាស់ប្តូររូបថត',
  "change in price and quantity tab":'ការផ្លាស់ប្តូរនៅក្នុងផ្ទាំងតម្លៃនិងបរិមាណ',
  "change status":'ផ្លាស់ប្តូរស្ថានភាព',
  "change your picture":'ផ្លាស់ប្តូររូបភាពរបស់អ្នក',
  "charity products":'ផលិតផលសប្បុរសធម៌',
  "charity, social impact project":'សប្បុរសធម៌គម្រោងផលប៉ះពាល់សង្គម',
  "check your email and click on the activation link to complete the registration":'ពិនិត្យអ៊ីមែលរបស់អ្នកហើយចុចលើតំណភ្ជាប់ការធ្វើឱ្យសកម្មដើម្បីបញ្ចប់ការចុះឈ្មោះ',
  "children":'កុមារ',
  "choices":'ជម្រើស',
  "choose":'ជម្រើស',
  "choose 1 of the funds to receive the donation":'ជ្រើសរើសយកចំនួនមួយក្នុងចំណោមមូលនិធិដើម្បីទទួលអំណោយ',
  "choose a photo as the image for the activity":'ជ្រើសរើសរូបថតជារូបភាពសម្រាប់សកម្មភាព',
  "choose a photo as the image for the event":'ជ្រើសរើសរូបថតជារូបភាពសម្រាប់ព្រឹត្តិការណ៍',
  "choose a photo as the image for the group":'ជ្រើសរើសរូបថតជារូបភាពសម្រាប់ក្រុម',
  "choose the category that you are aiming for":'ជ្រើសរើសប្រភេទដែលអ្នកមានគោលបំណង',
  "choose the job type to continue":'ជ្រើសរើសប្រភេទការងារបន្ត',
  "choose the target you want to target":'ជ្រើសរើសគោលដៅដែលអ្នកចង់កំណត់គោលដៅ',
  "clean water and sanitation":'ទឹកស្អាតនិងអនាម័យ',
  "CleanEnergy":'ថាមភលស្អាត',
  "CleanWaterAndSanitation":'ទឹកស្អាតនិងអនាម័យ',
  "clear":'លុប',
  "clear filter":'តម្រងច្បាស់',
  "click":'ចុច',
  "click here":'ចុច​ទីនេះ',
  "click here to add new donate package":'ចុចត្រង់នេះដើម្បីបន្ថែមកញ្ចប់បរិច្ចាគថ្មី',
  "click here to add new photos":'ចុចត្រង់នេះដើម្បីបន្ថែមរូបថតថ្មី',
  "click here to dismiss recommendation this beneficiary":'ចុចត្រង់នេះដើម្បីបដិសេធការផ្តល់អនុសាសន៍ដែលចង់បាន',
  "click to next":'ចុចបន្ទាប់',
  "click to vote it":'ចុចដើម្បីបោះឆ្នោតវា',
  "climate action":'សកម្មភាពអាកាសធាតុ',
  "close":'បិទ',
  "closed":'ដែលបិទ',
  "closed need":'បានបិទ',
  "closedgroup":'អង្ការដែលបានបិទ',
  "collected":'ការប្រមូល',
  "Color":'ពណ៍',
  "coming soon":'មកដល់ឆាប់ៗនេះ',
  "comment":'អត្ថាធិប្បាយ',
  "comment history":'ប្រវត្តិអត្ថាធិប្បាយ',
  "comment on product":'យោបល់លើផលិតផល',
  "comment store":'ផ្កល់យោបល់ដល់ហាង',
  "common":'សាមាញ',
  "community policy":'គោលការណ៍សហគមន៍',
  "company":'ក្រុមហ៊ុន',
  "companytips":'ក្រុមហ៊ុន',
  "complete add new product":'បំពេញបន្ថែមផលិតផលថ្មី',
  "complete the information so people can understand your store better":'បំពេញព័ត៌មានដូច្នេះមនុស្សអាចយល់ពីហាងរបស់អ្នកកាន់តែប្រសើរ',
  "Complete with sharing and spreading. We need you.":'បំពេញជាមួយនឹងការចែករំលែកនិងការរីករាលដាល។ ពួក​យើង​ត្រូវការ​អ្នក។',
  "completed":'បានបញ្ចប់',
  "completed project":'បានបញ្ចប់គម្រោង',
  "completed beneficiary":'បំពេញបំណងប្រាថ្នា',
  "computer & data":'កុំព្យូទ័រនិងទិន្នន័យ',
  "confirm cart":'បញ្ជាក់រទេះ',
  "confirm password":'បញ្ជាក់ពាក្យសំងាត់',
  "congratulation you become winner":'អបអរសាទរអ្នកក្លាយជាអ្នកឈ្នះ',
  "congratulation your payment has been finished":'អបអរសាទរការបង់ប្រាក់របស់អ្នកត្រូវបានបញ្ចប់',
  "congratulations":'សេចក្ដីអបអរ',
  "congratulations, your order is successful":'អបអរសាទរការបញ្ជាទិញរបស់អ្នកទទួលបានជោគជ័យ',
  "connect with facebook":'ភ្ជាប់ជាមួយហ្វេសប៊ុក',
  "connect with google":'ភ្ជាប់ជាមួយ Google',
  "connect with us":'ភ្ជាប់ទំនាក់ទំនងជាមួយយើង',
  "connecting for good":'ការភ្ជាប់សម្រាប់ការល្អ',
  "connecting people of kindness and needs":'ការភ្ជាប់មនុស្សនៃសេចក្តីសប្បុរសនិងតម្រូវការ',
  "connecting the goodness":'ការតភ្ជាប់សេចក្តីល្អនេះ',
  "contact":'ទាក់ទង់',
  "contact us":'ទាក់ទង​មក​ពួក​យើង',
  "Contact us by sending to contact@wishare.com":'ទាក់ទងមកយើងខ្ញុំតាមការផ្ញើទៅ contact@wishare.com',
  "contact_info":'អ្នកទាក់ទង់',
  "content":'ខ្លឹមសារ',
  "content created by wishare":'ធ្វើសន្ទស្សន៍ដោយវីស៊ែរ',
  "continue":'បន្ដ',
  "continue shopping":'បន្តការទិញ',
  "continuous time":'បន្តពេលវេលា',
  "contract":'កិច្ចសន្យា',
  "contribute":'ជួយផ្តល់',
  "cookies":'ខូឃីស៍',
  "cookies policy":'គោលការណ៍ខូឃីស៍',
  "copied":'ថតចម្លង',
  "copy":'ការចម្លង',
  "copy url":'ចម្លង URL',
  "Copyright by wishare.com":'រក្សាសិទ្ធិដោយ Wishare.com',
  "could_not_create_token":'ចូលមិនបានជោគជ័យ!',
  "count on product":'រាប់លើផលិតផល',
  "create":'បង្កើត',
  "create a crowdfunding activity":'បង្កើតសកម្មភាពការរៃអង្គាស',
  "create a new album":'បង្កើតអាល់ប៊ុមថ្មី',
  "create a new opportunity":'បង្កើតឱកាសថ្មី',
  "create account & login":'បង្កើតគណនីនិងការចូល',
  "create activity":'បង្កើតសកម្មភាព',
  "create auction":'បង្កើតការដេញថ្លៃ',
  "create donation":'បន្ថែមការបរិច្ចាគ',
  "create event":'បង្កើតព្រឹត្តិការណ៍',
  "create giving":'បង្កើតការឱ្យ',
  "create group":'បង្កើតអង្គការ',
  "create need":'បង្កើតការប្រមូលមូលនិធិថ្មី',
  "create new account":'បង្កើត​គណនី​ថ្មី',
  "create new an activity":'បង្កើតសកម្មភាពថ្មីមួយ',
  "create new auction":'បង្កើតការដេញថ្លៃថ្មី',
  "create new package":'បង្កើតកញ្ចប់ថ្មី',
  "create opportunity":'បង្កើតឱកាស',
  "create post":'បង្កើតប្រកាស',
  "create store":'បង្កើតហាង',
  "create beneficiary":'បង្កើតបំណងប្រាថ្នា',
  "create your activity":'បង្កើតសកម្មភាពរបស់អ្នក',
  "create your event":'បង្កើតព្រឹត្តិការណ៍របស់អ្នក',
  "create your group":'បង្កើតអង្គភាពរបស់អ្នក',
  "create your beneficiary":'បង្កើតបំណងប្រាថ្នារបស់អ្នក',
  "created":'បានបង្កើត',
  "created date":'កាលបរិច្ឆេទបង្កើត',
  "crowdfunding":'ការប្រមូលរៃអង្គាស',
  "culture":'វប្បធម៍',
  "current":'នា​ពេល​បច្ចុប្បន្ន',
  "current price":'តម្លៃ​បច្ចុប្បន្ន',
  "currentUser":'អ្នក',
  "customer rated positively":'អតិថិជនដែលបានវាយតម្លៃជាវិជ្ជមាន',
  "date":'ថ្ងៃ',
  "date & time":'ថ្ងៃនិងពេលវេលា',
  "date_of_birth":'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
  "day":'ថ្ងៃ',
  "days":'ថ្ងៃ',
  "days of delivery":'ថ្ងៃដឹកជញ្ជូន',
  "deaf mute":'ថ្លង់',
  "default":'កំហុស',
  "delete":'លុបចោល',
  "delete comment":'លុបមតិយោបល់',
  "delete opportunity":'លុបការងារនេះ',
  "delete question":'លុបសំណួរ',
  "delete the selected items":'លុបផលិតផលដែលបានជ្រើសរើស',
  "delivered":'ប្រគល់ជូន',
  "delivery":'ការយកអាេយ',
  "delivery by":'ការដឹកជញ្ជូនដោយ',
  "delivery by shop":'ការដឹកជញ្ជូនតាមហាង',
  "delivery details":'ព័ត៌មានលំអិតនៃការដឹកជញ្ជូន',
  "delivery fee":'ថ្លៃដឹកជញ្ជូន',
  "delivery location":'ទីតាំងដឹកជញ្ជូន',
  "delivery options":'ជម្រើសដឹកជញ្ជូន',
  "delivery period from":'រយៈពេលដឹកជញ្ជូនពី',
  "delivery policy":'គោលការណ៍នៃការដឹកជញ្ជូន',
  "delivery status":'ស្ថានភាពដឹកជញ្ជូន',
  "delivery unit":'អង្គភាពដឹកជញ្ជូន',
  "delivery-methods":'វិធីសាស្រ្តដឹកជញ្ជូន',
  "delivery-policies":'គោលការណ៍នៃការដឹកជញ្ជូន',
  "desc":'បន្ថយ',
  "description":'ណែនាំ',
  "descriptionplaceholder":'សរសេរបន្ថែមនៅទីនេះ។',
  "designer":'អ្នកធ្វើប្លង់',
  "detail":'លម្អិត',
  "development":'អភិវឌ្ឈន៍',
  "digital of your social activity":'ឌីជីថលនៃសកម្មភាពសង្គមរបស់អ្នក',
  "directly work":'ធ្វើការដោយផ្ទាល់',
  "disabilities":'ពិការភាព',
  "disablity":'ពិការ',
  "discard":'បោះបង់ចោល',
  "discount":'ការបញ្ចុះតម្លៃ',
  "discount value":'ការបញ្ចុះតម្លៃ',
  "Discover and contribute to community fundraising activities. We need you.":'ស្វែងយល់និងចូលរួមចំណែកក្នុងសកម្មភាពរៃអង្គាសប្រាក់សហគមន៍។ ពួក​យើង​ត្រូវការ​អ្នក។',
  "discover now":'ស្វែងយល់នៅពេលនេះ',
  "discovery":'ស្វែងយល់',
  "discussion":'ការពិភាក្សា',
  "dismiss recommendation":'ច្រានចោលអនុសាសន៍',
  "display on homepage":'បង្ហាញនៅលើគេហទំព័រ',
  "district":'សង្កាត់',
  "divorced":'លែងលាស់',
  "divorcedtips":'លែងលាស់',
  "do you want to delete this?":'តើអ្នកចង់លុបវាទេ?',
  "do you want to remove this post?":'តើអ្នកចង់លុបប្រកាសនេះចេញទេ?',
  "Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?":'តើអ្នកចង់ចំណាយពេលវេលាទេពកោសល្យនិងការប្រឹងប្រែងរបស់អ្នកលើសកម្មភាពសហគមន៍ឬបំណងប្រាថ្នាក្នុងការទទួលបានការងារដែលជះឥទ្ធិពលដល់សង្គមទេ?',
  "donate":'បរិច្ចាគ',
  "donate now":'បរិច្ចាគឥឡូវនេះ',
  "donate what you can":'បរិច្ចាគអ្វីដែលអ្នកអាចធ្វើបាន',
  "donated":'បានបរិច្ចាគ',
  "donation":'ការបរិច្ចាគ',
  "donation campaigns":'យុទ្ធនាការបរិច្ចាគ',
  "donation code":'លេខកូដបរិច្ចាគ',
  "donation info":'ព័ត៌មានការបរិច្ចាគ',
  "donation manager":'អ្នកគ្រប់គ្រងបរិច្ចាគ',
  "donation transfer":'ការផ្ទេរអំណោយបរិច្ចាគ',
  "donation value":'តម្លៃនៃបរិច្ចាគ',
  "donation value must be large than 0":'តម្លៃបរិច្ចាគត្រូវតែធំជាង 0',
  "donation-campaigns":'យុទ្ធនាការបរិច្ចាគ',
  "donations":'បរិច្ចាគ',
  "done":'សម្រេច',
  "done beneficiaries":'បំណងប្រាថ្នាបានសម្រេច',
  "donetips":'សម្រេច',
  "donor":'អ្នកបរិច្ចាគ',
  "downvote":'បោះចោលការបោះឆ្នោត',
  "draft":'ពង្រាក់',
  "drafttips":'មាតិកាកំពុងរអាក់រអួលចំពោះសេចក្តីព្រាងបម្រុងទុកមាតិកាបន្តផ្ញើសំណើបោះពុម្ភផ្សាយ',
  "earlier":'ព័ត៌មានមុន',
  "edit":'ពិនិត្យ',
  "edit auction item":'កែសម្រួលផលិតផលដេញថ្លៃ',
  "edit comment":'កែសម្រួលមតិយោបល់',
  "edit delivery policy":'កែសម្រួលគោលការណ៍ចែកចាយ',
  "edit donate option":'កែសម្រួលជម្រើសបរិច្ចាគ',
  "edit donation":'កែសម្រួលការបរិច្ចាគ',
  "edit donation campaigns":'កែសម្រួលយុទ្ធនាការបរិច្ចាគការបរិច្ចាគ',
  "edit giving":'កែសម្រួលការឱ្យ',
  "edit image":'កែសម្រួលរូបភាព',
  "edit location":'កែសម្រួលទីតាំង',
  "edit margin value":'កែសម្រួលតម្លៃរឹម',
  "edit member":'កែសម្រួលសមាជិក',
  "edit member information":'កែសម្រួលព័ត៌មានសមាជិក',
  "edit notice":'កែសម្រួលសេចក្តីជូនដំណឹង',
  "edit opportunity":'កែសម្រួលឱកាស',
  "edit post":'កែសម្រួលអត្ថបទ',
  "edit product infomation":'កែសម្រួលព័ត៌មានផលិតផល',
  "edit profile":'កែសម្រួលទម្រង់',
  "edit promotion campaign":'កែសម្រួលយុទ្ធនាការផ្សព្វផ្សាយ',
  "edit question":'កែសម្រួលសំណួរ',
  "edit return policy":'កែសម្រួលគោលការណ៍ត្រឡប់មកវិញ',
  "edit warranty policy":'កែសម្រួលគោលនយោបាយធានា',
  "education":'ការអប់រំ',
  "education/ training":'ការអប់រំ / ការបណ្តុះបណ្តាល',
  "educations":'ការអប់រំ',
  "effective management and reporting":'ការគ្រប់គ្រងនិងការរាយការណ៍ប្រកបដោយប្រសិទ្ធភាព',
  "efficient successful and sustainable":'មានប្រសិទ្ធិភាពជោគជ័យនិងនិរន្តភាព',
  "Electronic & Accessories":'គ្រឿងអេឡិចត្រូនិចនិងគ្រឿងបន្លាស់',
  "email":'អ៊ីមែល',
  "emailplaceholder":'អ៊ីម៉ែល (Example@company.com)',
  "embed":'បង្កប់',
  "empathize and help to connect these beneficiaries to the relevent activities that you should know":'យល់ចិត្តនិងជួយក្នុងការភ្ជាប់បំណងប្រាថ្នាទាំងនេះចំពោះសកម្មភាពដែលអ្នកគួរដឹង',
  "employment type":'ប្រភេទ​ការងារ',
  "empty":'ទំនេរ',
  "end":'បញ្ចប់',
  "end date":'កាលបរិច្ឆេទបញ្ចប់',
  "end time":'ពេលវេលាបញ្ចប់',
  "end time must be after start time":'ពេលវេលាបញ្ចប់ត្រូវតែក្រោយពីពេលវេលាចាប់ផ្តើម',
  "end_at":'បញ្ចប់នៅ',
  "end_date":'កាលបរិច្ឆេទបញ្ចប់',
  "end_time":'ពេលវេលាបញ្ចប់',
  "ended":'បញ្ចប់',
  "ended at":'បានបញ្ចប់នៅ',
  "ended session":'បញ្ចប់សម័យប្រជុំ',
  "ending now":'បញ្ចប់ឥឡូវនេះ',
  "english":'ភាសាអង់គ្លេស',
  "englishtips":'ភាសាអង់គ្លេស',
  "enter a location":'បញ្ចូលទីតាំង',
  "enter album name":'បញ្ចូលឈ្មោះអាល់ប៊ុម',
  "enter amount":'បញ្ចូលចំនួនទឹកប្រាក់',
  "enter keyword":'បញ្ចូលពាក្យគន្លឹះ',
  "enter name":'បញ្ចូលឈ្មោះ',
  "enter order code":'បញ្ចូលលេខកូដបញ្ជាទិញ',
  "enter order user":'បញ្ចូលអ្នកបញ្ជាទិញ',
  "enter the activity name":'បញ្ចូលឈ្មោះសកម្មភាព',
  "enter the reason you think is a violation":'បញ្ចូលហេតុផលដែលអ្នកគិតថាគឺជាការរំលោភ',
  "enter your question here":'បញ្ចូលសំណួររបស់អ្នកនៅទីនេះ',
  "enter your reply here":'បញ្ចូលការឆ្លើយតបរបស់អ្នកនៅទីនេះ',
  "environment":'បរិយាកាស',
  "environmental":'បរិស្ថាន',
  "error message":'មានអ្វីខុស! ពិនិត្យការភ្ជាប់និងផ្ទុកឡើងវិញម្តងទៀត!',
  "event":'ព្រឹត្តិការណ៍',
  "event time":'ពេលវេលាព្រឹត្តិការណ៍',
  "event timeline":'ការចាប់ផ្ដើម',
  "event title":'ចំណងជើងព្រឹត្តិការណ៍',
  "events":'ព្រឹត្តិការណ៍ផ្សេងៗ',
  "eventtips":'ព្រឹត្តិការណ៍នានាគឺជាសកម្មភាពដែលពាក់ព័ន្ធនឹងមនុស្សជាច្រើនក្នុងពេលតែមួយនិងកន្លែង។ ព្រឹត្តិការណ៍ត្រូវបានប្រើដើម្បីអនុវត្តគំនិតរបស់អ្នក។',
  "example":'ឧទាហរណ៍',
  "example name":'ចូនីអង់គ្លេស',
  "experience_requirements":'តម្រូវការបទពិសោធន៍',
  "expired":'ផុតកំណត់',
  "expired job":'ការងារផុតកំណត់',
  "explore funding need":'ស្វែងរកតម្រូវការផ្តល់មូលនិធិ',
  "explore jobs in":'ស្វែងរកពីការងារ',
  "explore the list of activities which are suitable for your need":'ស្វែងយល់ពីបញ្ជីសកម្មភាពដែលសមស្របសម្រាប់តម្រូវការរបស់អ្នក',
  "export data":'នាំចេញទិន្នន័យ',
  "failed":'បរាជ័យ',
  "farming & forestry":'កសិកម្មនិងព្រៃឈើ',
  "Fashion & Accessories":'ម៉ូតនិងគ្រឿងបន្លាស់',
  "fee":'ថ្លៃឈ្នួល',
  "feedback":'មតិប្រតិកម្ម',
  "feeds":'មតិព័ត៌មាន',
  "female":'ភេទស្រ្ដី',
  "femaletips":'ភេទស្រ្ដី',
  "field of work":'វិស័យការងារ',
  "filterplaceholder":'តម្រង',
  "financial capital":'ហិរញ្ញវត្ថុ',
  "financial capital for good":'ដើមទុនហិរញ្ញវត្ថុ',
  "Find volunteers or create social impact locations, Experience the free recruitment on Wishare.":'ស្វែងរកអ្នកស្ម័គ្រចិត្តឬបង្កើតទីតាំងផលប៉ះពាល់សង្គមជួបប្រទះការជ្រើសរើសសេរីដោយឥតគិតថ្លៃលើការចង់បាន។',
  "finish":'បញ្ចប់',
  "flexible":'បត់បែនបាន',
  "focusing on good connection":'ផ្តោតលើការតភ្ជាប់ល្អ',
  "follow":'តាមដាន',
  "followed you":'តាមដានអ្នក',
  "follower":'អ្នកតាមដាន',
  "followers only":'អ្នកតាមដានតែប៉ុណ្ណោះ',
  "following":'កំពុងតាមដាន',
  "following auction":'តាមដានការដេញថ្លៃ',
  "following events":'តាមដានព្រឹត្តិការណ៍',
  "following beneficiaries":'តាមដានបំណងប្រាថ្នា',
  "following-auction":'តាមដានការដេញថ្លៃ',
  "for":'អោយ',
  "for community":'សម្រាប់សហគមន៍',
  "for recruiters, let access to our huge volunteer community":'សម្រាប់អ្នកជ្រើសរើសអនុញ្ញាតឱ្យចូលទៅកាន់សហគមន៍ស្ម័គ្រចិត្តដ៏ធំរបស់យើង',
  "for sales":'សម្រាប់ការលក់',
  "for social and creative purpose":'សម្រាប់គោលបំណងសង្គមនិងច្នៃប្រឌិត',
  "for volunteer, please let us give you a good recommendation":'សម្រាប់អ្នកស្ម័គ្រចិត្តសូមឱ្យយើងផ្តល់ឱ្យអ្នកនូវអនុសាសន៍ល្អ',
  "for volunteers":'សម្រាប់អ្នកស្ម័គ្រចិត្ត',
  "for who seek to raise money for the good thing, implementing your initiative":'សម្រាប់អ្នកដែលព្យាយាមរៃអង្គាសប្រាក់សម្រាប់រឿងល្អអនុវត្តគំនិតផ្តួចផ្តើមរបស់អ្នក',
  "forgotpass":'ភ្លេចលេខសំងាត់?',
  "foundation":'មូលនិធិ',
  "free for friends":'ឥតគិតថ្លៃសម្រាប់មិត្តភក្តិ',
  "free ship":'សេវាឥត​គិត​ថ្លៃ',
  "free ship with minimum amount":'ដឹកជញ្ជូនឥត​គិត​ថ្លៃជាមួយចំនួនតូច',
  "frequency":'ផាបញឹកញាប់',
  "frequencyplaceholder":'ផាបញឹកញាប់',
  "friend":'មិត្តភក្តិ',
  "friends":'មិត្តភក្តិ',
  "from":'ពី',
  "from donors":'សូមអរគុណ',
  "full-time":'ពេញម៉ោង',
  "fullname":'ឈ្មោះ​ពេញ',
  "fundraiser for good":'ការរៃអង្គាសប្រាក់',
  "gave":'ផ្តល់អោយ',
  "gender":'ភេទ',
  "gender equality":'សមភាព​ភេទ',
  "GenderEquality":'សមភាព​ភេទ',
  "general":'ទូទៅ',
  "general information":'ព័ត៌មាន​ទូទៅ',
  "get started":'ចាប់ផ្តើម',
  "gift":'អំណោយ',
  "gift product":'អំណោយជាផលិតផល',
  "gift product list":'បញ្ជីអំណោយផលិតផល',
  "gift products":'អំណោយជាផលិតផល',
  "give your job a title":'ផ្តល់ចំណងជើងការងាររបស់អ្នក',
  "give your job position":'ផ្តល់ជំហរការងាររបស់អ្នក',
  "given number":'ចំនួនទឹកប្រាក់ដែលបានផ្តល់ឱ្យ',
  "giving":'ផ្តល់អោយ',
  "giving what we can?":'ផ្តល់នូវអ្វីដែលយើងអាចធ្វើបាន?',
  "go to":'ទៅ',
  "go to cart":'ទៅរទេះ',
  "go to shop":'ចូលទៅកាន់ហាង',
  "good":'ល្អ',
  "good health and well-being":'សុខភាពល្អនិងសុខុមាលភាព',
  "graduated":'បានបញ្ចប់ការសិក្សា',
  "Great, you\'ve signed - the next step is to share far and wide to make sure everyone sees this campaign.":'ល្អណាស់អ្នកបានចុះហត្ថលេខា - ជំហានបន្ទាប់គឺដើម្បីចែករំលែកនៅឆ្ងាយនិងឆ្ងាយដើម្បីប្រាកដថាអ្នករាល់គ្នាមើលឃើញយុទ្ធនាការនេះ។',
  "Groceries & Pets":'គ្រឿងទេសនិងសត្វចិញ្ចឹម',
  "group":'សមាគមន៍',
  "groups":'អង្គការ',
  "grouptips":'អង្គការកំពុងផ្លាស់ប្តូរកន្លែងផ្លាស់ប្តូរគ្នាការភ្ជាប់មនុស្សជាច្រើន។ ក្រុមត្រូវបានប្រើដើម្បីចែករំលែកព័ត៌មានរបស់ក្រុមហ៊ុនអង្គការ (មិនមែនប្រាក់ចំណេញសមាគម ... ​​) ឬក្រុមមិត្តភក្តិ។',
  "grouptypeplaceholder":'ប្រភេទនៃអង្គភាព',
  "half successful":'ទទួលបានពាក់កណ្តាលជោគជ័យ',
  "has been linked":'ត្រូវបានផ្សារភ្ជាប់',
  "has been supported":'ត្រូវបានគាំទ្រ',
  "has draft orders":'មានការបញ្ជាទិញសេចក្តីព្រាង',
  "has orders":'មានការបញ្ជាទិញ',
  "haveaccount":'មានគណនីរួចហើយ?',
  "header":'បឋមកថា',
  "Health & Beauty":'សុខភាព​និង​សម្រស់',
  "healthcare":'ការ​ថែទាំ​សុខភាព',
  "healthy":'ផាសុខភាព',
  "Height":'កម្ពស់',
  "help":'ការជួយ',
  "helps":'ជួយ',
  "here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.":'នេះគឺជាសំណួរមួយចំនួនដែលនិយោជិកចង់ផ្ញើទៅអ្នកឆ្លើយសំណួរនឹងជួយនិយោជកហើយអ្នកយល់ពីគ្នាទៅវិញទៅមក។',
  "hidden due to invalid content":'មាតិកាដែលលាក់សម្រាប់ការរំលោភលើគោលនយោបាយនិងល័ក្ខខ័ណ្ឌនៃការចង់បាន',
  "hide":'លាក់',
  "high to low":'ចុះក្រោម',
  "history":'ប្រវត្តិសាស្រ្ត',
  "home":'ទំព័រ',
  "Home & LifeStyle":'ផ្ទះនិងរបៀបរស់នៅ',
  "hometown":'ស្រុកកំណើត',
  "hosted by":'ធ្វើជាម្ចាស់ផ្ទះដោយ',
  "hosted items":'អង្គភាពតំណាង',
  "hostedactivities":'សកម្មភាព',
  "hostedItem":'អង្គភាពតំណាង',
  "hosting":'អ្នកគ្រប់គ្រង់',
  "hosts":'ម្ចាស់ផ្ទះ',
  "hour(h)":'ម៉ោង (H)',
  "hourly-work":'ការងារតាមម៉ោង',
  "house number, street name":'លេខផ្ទះឈ្មោះផ្លូវ',
  "how can you help?":'តើអ្នកអាចជួយយ៉ាងដូចម្តេច?',
  "how many can you help?":'តើអ្នកអាចជួយបានប៉ុន្មាន?',
  "how many people do you need?":'តើអ្នកត្រូវការមនុស្សប៉ុន្មាននាក់?',
  "human":'មនុស្សលោក',
  "human capital":'មនុស្សនិងហិរញ្ញវត្ថុ',
  "human capital for good":'ធនធានមនុស្ស',
  "i have read and accepted with these":'ខ្ញុំបានអាននិងទទួលយកជាមួយរបស់ទាំងនេះ',
  "If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.":'ប្រសិនបើប្រធានបទពីរគួរតែដូចគ្នាអ្នកអាចបញ្ចូលវានៅទីនេះបាន។ សំណួរទាំងអស់និងអ្នកកែប្រែដាក់ជាក្រុមក្រោមប្រធានបទនេះនឹងត្រូវផ្ទេរទៅប្រធានបទផ្សេងទៀត។',
  "If you have any questions, please contact us for support":'ប្រសិនបើអ្នកមានសំណួរសូមទាក់ទងមកយើងសម្រាប់ការណែនាំ',
  "If you want to create a crowdfunding activity for charitable, social or creative activities. Let\'s use and experience the crowdfunding function on our platform.":'ប្រសិនបើអ្នកចង់បង្កើតសកម្មភាពប្រមូលផ្តុំការប្រមូលផ្តុំសម្រាប់សកម្មភាពសប្បុរសសង្គមឬការច្នៃប្រឌិត។ ប្រើមុខងារនិងបទពិសោធន៍នៃការប្រមូលផ្តុំគ្នានៅលើវេទិការបស់យើង។',
  "ignore":'ទុកចោល',
  "ignored":'មិនអើពើ',
  "impact":'ឥទ្ធិពល',
  "impact jobs":'ការងារដែលមានឥទ្ធិពល',
  "important note":'កំណត់សម្គាល់សំខាន់',
  "in":'បញ្ចូល',
  "In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activity. Join Wishare to share and contribute to these sustainability goals.":'នៅឆ្នាំ 2015 មេដឹកនាំពិភពលោកបានយល់ព្រមនឹងគោលដៅចំនួន 17 សម្រាប់ពិភពលោកដែលប្រសើរជាងឆ្នាំ 2030 ។ ចូលរួមប្រាថ្នាដើម្បីចែករំលែកនិងចូលរួមចំណែកក្នុងការសម្រេចគោលដៅដែលមានចីរភាពទាំងនេះ។',
  "in order for an employer to reach you, update some of yourself":'ដើម្បីឱ្យនិយោជកមកទទួលអ្នកធ្វើបច្ចុប្បន្នភាពខ្លួនឯងខ្លះ',
  "in our good connection":'នៅក្នុងការតភ្ជាប់ល្អរបស់យើង',
  "in the same categories":'ក្នុងប្រភេទដូចគ្នា',
  "in-activated":'ធ្វើឱ្យសកម្ម',
  "inactive":'ដែលគ្មានសកម្មភាព',
  "inactivetips":'Yes',
  "index":'លេខសម្គាល់',
  "industry, innovation and infrastructure":'ឧស្សាហកម្មការច្នៃប្រឌិតនិងហេដ្ឋារចនាសម្ព័ន្ធ',
  "info":'ព័ត៌មាន',
  "information":'ព័ត៌មាន',
  "information about donating":'ព័ត៌មានអំពីការបរិច្ចាគ',
  "information where donations are made":'ព័ត៌មានដែលការបរិច្ចាគត្រូវបានធ្វើឡើង',
  "informationandmap":'កន្លែងរៀបចំឡើង',
  "infrastructure":'ហេដ្ឋារចនាសម្ព័ន្ធ',
  "initial price":'តម្លៃដំបូង',
  "input":'បហ្ចូល',
  "inreview":'កំពុងពិនិត្យឡើងវិញ',
  "inreviewtips":'យើងកំពុងពិនិត្យមើលទំព័ររបស់អ្នកហើយយើងនឹងផ្ញើអ៊ីមែលមកអ្នកនៅពេលបញ្ចប់ដំណើរការពិនិត្យឡើងវិញ។',
  "insert question":'បញ្ចូលសំណួរ',
  "inspire your goodness":'ជំរុញភាពល្អរបស់អ្នក',
  "instructions on how to reset your password were sent to":'សេចក្តីណែនាំអំពីវិធីកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញត្រូវបានផ្ញើទៅ',
  "intends to delivery from":'មានបំណងដឹកជញ្ជូនពី',
  "internal":'ខាងក្នុង',
  "internship":'កម្មសិក្សា',
  "internships":'កម្មសិក្សា',
  "interpreter & translator":'អ្នកបកប្រែផ្ទាល់មាត់និងអ្នកបកប្រែ',
  "introduce yourself":'ណែនាំអំពីខ្លួនអ្នក',
  "invalid date":'កាលបរិច្ឆេទមិនត្រឹមត្រូវ',
  "invalid email":'អ៊ីមែលមិនត្រឹមត្រូវ',
  "invalid email address":'អាសយដ្ឋានអ៊ីមែលមិនត្រឹមត្រូវ',
  "invalid_credentials":'ព័ត៌មានចូលខុស។ សូមពិនិត្យមើលគណនីរបស់អ្នកហើយពាក្យសម្ងាត់ចូល!',
  "invite":'អញ្ជើញ',
  "is merged into":'ត្រូវបានបញ្ចូលគ្នាទៅក្នុង',
  "is priority product":'គឺជាផលិតផលអាទិភាព',
  "It\'s YOU":'គឺ​អ្នក',
  "job description":'ការពិពណ៌នាការងារ',
  "job details":'ព័ត៌មានលម្អិតការងារ',
  "job filter":'តម្រងការងារ',
  "job function type":'ប្រភេទមុខងារការងារ',
  "job information":'ព័ត៌មានការងារ',
  "job type":'ប្រភេទ​ការងារ',
  "job_requirements":'តម្រូវការការងារ',
  "job_types":'ប្រភេទការងារ',
  "jobs":'ការងារ',
  "join":'ចូលរួម',
  "join day":'ចូលរួមថ្ងៃ',
  "join us":'ចូលរួម​ជាមួយ​យើង',
  "join us now":'ចូលរួមជាមួយយើងឥឡូវនេះ',
  "join us on mobile":'ចូលរួមជាមួយយើងតាមទូរស័ព្ទចល័ត',
  "joined":'បានចូលរួមក្នុង',
  "joined activities":'សកម្មភាពចូលរួម',
  "joined groups":'បានចូលរួមជាមួយអង្គការ',
  "just for you":'សំរាប់​តែ​អ្នក',
  "keep your passion like an artist and manage your social mission like a tech CEO":'រក្សាចំណង់ចំណូលចិត្តរបស់អ្នកជាសិល្បករនិងគ្រប់គ្រងបេសកកម្មសង្គមរបស់អ្នកដូចជានាយកប្រតិបត្តិតិច',
  "keyword":'លេខសម្គាល់',
  "language":'ភាសា',
  "language choose":'បង្ហាញ់ភាសា',
  "language_requirements":'តម្រូវការភាសា',
  "languages":'ភាសា',
  "large scale":'ទំហំ​ធំ',
  "last bid price":'តម្លៃដេញថ្លៃចុងក្រោយ',
  "last bidder":'អ្នកដេញថ្លៃចុងក្រោយ',
  "latitude":'វីតារន្ដរៈ',
  "law - policy":'ច្បាប់ - គោលការណ៍',
  "law / legal services":'ច្បាប់ / សេវាកម្មច្បាប់',
  "learn more":'ស្វែង​យល់​បន្ថែម',
  "leave comment":'ចាកចេញពីមតិយោបល់',
  "Length":'ប្រវែង',
  "let discover and join to these activities below or apply your wish to request a support":'សូមរកមើលនិងចូលរួមក្នុងសកម្មភាពទាំងនេះនៅខាងក្រោមឬអនុវត្តបំណងប្រាថ្នារបស់អ្នកដើម្បីស្នើសុំការគាំទ្រ',
  "let discover the crowdfunding from the community and contribute to a change":'សូមឱ្យការស្វែងយល់ពីការប្រមូលផ្តុំគ្នាពីសហគមន៍និងចូលរួមចំណែកក្នុងការផ្លាស់ប្តូរ',
  "let make a free activity":'អនុញ្ញាតឱ្យធ្វើសកម្មភាពឥតគិតថ្លៃ',
  "let sign and help to connect these beneficiaries to relevent activities or the kindness people":'អនុញ្ញាតឱ្យចុះហត្ថលេខានិងជំនួយក្នុងការភ្ជាប់បំណងប្រាថ្នាទាំងនេះក្នុងសកម្មភាពដែលមិនយកចិត្តទុកដាក់ឬមនុស្សសប្បុរស',
  "let\'s us help":'សូមអោយពួកយើងជួយ',
  "level":'កម្រិត',
  "life below water":'ធនធានទឹក',
  "life on land":'ធនធានដីធ្លី',
  "LifeBelowWater":'ធនធានទឹក',
  "LifeOnLand":'ធនធានដីធ្លី',
  "link target":'គោលដៅកំណភ្ជាប់',
  "link title":'ចំណងជើងតំណភ្ជាប់',
  "link to":'ភ្ជាប់ទៅ',
  "link to schedule":'ភ្ជាប់ទៅកាលបរិច្ចេទ',
  "linked":'ភ្ជាប់',
  "links":'តំណភ្ជាប់',
  "live session":'វគ្គបន្តផ្ទាល់',
  "loading":'កំពុងផ្ទុក',
  "location":'ទីតាំង',
  "location list":'ទីតាំង',
  "locationplaceholder":'បញ្ចូលព័ត៌មានទីតាំង',
  "locations":'ទីតាំង',
  "login":'ចូល',
  "logout":'ចាកចេញ',
  "longitude":'រយះបណ្ដោយ',
  "lost":'បាត់',
  "Lot size":'ទំហំឡុត',
  "low to high":'ក្រោមឡើងលើ',
  "make a crowdfunding":'ធ្វើការរៃអង្គាសអោយសហគមន៍',
  "make a crowdfunding now":'ធ្វើការរៃអង្គាសអោយសហគមន៍ឥឡូវនេះ',
  "make a recruitment":'ធ្វើការជ្រើសរើស',
  "make a beneficiary":'ធ្វើឱ្យមានបំណងមួយ',
  "make an activity":'ធ្វើឱ្យសកម្មភាពមួយ',
  "make digital conversions for social initiatives to help them work":'ធ្វើការបំលែងឌីជីថលសម្រាប់គំនិតផ្តួចផ្តើមសង្គមជួយឱ្យពួកគេធ្វើការ',
  "make event":'បង្កើតព្រឹត្តិការណ៍',
  "make opportunity now":'បង្កើតឱកាសមួយឥឡូវនេះ',
  "make post":'បង្កើតអត្ថបទ',
  "makeactivityplaceholder":'បញ្ចូលឈ្មោះសកម្មភាព',
  "makeeventplaceholder":'បញ្ចូលឈ្មោះព្រឹត្តិការណ៍',
  "makegroupplaceholder":'បញ្ចូលឈ្មោះរបស់អង្គការ',
  "makebeneficiaryplaceholder":'បញ្ចូលបំណងប្រាថ្នារបស់អ្នក',
  "male":'ប្រុស',
  "maletips":'ប្រុស',
  "mall":'ផ្សារ​ទំនើប',
  "mall ":'ផ្សារ​ទំនើប',
  "mall & auction":'ផ្សារទំនើបនិងការដេញថ្លៃ',
  "manage members":'គ្រប់គ្រងសមាជិក',
  "management":'គ្រប់គ្រង់',
  "manager":'អ្នកគ្រប់គ្រង់',
  "mandatory":'ចាំបាច់',
  "manual":'ហត្ថកម្ម',
  "manual / automatic":'សៀវភៅណែនាំ: * កម្មវិធីដេញថ្លៃនឹងត្រូវបានគ្រប់គ្រងហើយចាប់ផ្តើមដោយកម្មវិធីគ្រប់គ្រងការដេញថ្លៃ។ ស្វ័យប្រវត្ដិៈ * កម្មវិធីដេញថ្លៃនឹងចាប់ផ្តើមដោយស្វ័យប្រវត្តិនៅពេលដែលបានបញ្ជាក់នៅក្នុងកម្មវិធីដេញថ្លៃ។',
  "manufacturer":'សិប្បកម្ម',
  "many people or causes that":'មនុស្សជាច្រើនឬមូលហេតុនោះ',
  "many people or organisations":'មនុស្សជាច្រើនឬអង្គការ',
  "map":'ផែនទី',
  "margin value":'តម្លៃរឹម',
  "marital_status":'ស្ថានភាពអាពាហ៍ពិពាហ៍',
  "mark \'active\' if you want social activity units to be able to reach you":'សម្គាល់ \ \'សកម្ម \' ប្រសិនបើអ្នកចង់អោយអង្គភាពសកម្មភាពសង្គមអាចទៅដល់អ្នកបាន',
  "mark all as read":'សម្គាល់ទាំងអស់ដូចដែលបានអាន',
  "married":'អាពាហ៍ពិពាហ៍',
  "marriedtips":'អាពាហ៍ពិពាហ៍',
  "Material":'សម្ភារៈ',
  "max_age":'អាយុអតិបរមា',
  "max_salary":'ប្រាក់ខែអតិបរមា',
  "maximum with":'អតិបរមាជាមួយ',
  "may be available to close, let check it again":'អាចមានដើម្បីបិទសូមឱ្យពិនិត្យមើលម្តងទៀត',
  "media":'ប្រព័ន្ធផ្សព្វផ្សាយ',
  "media & communication":'ប្រព័ន្ធផ្សព្វផ្សាយនិងការទំនាក់ទំនង',
  "member":'សមាជិក',
  "member filter":'តម្រងសមាជិក',
  "member name":'ឈ្មោះ​សមាជិក',
  "members":'សមាជិក',
  "membersadmintips":'អ្នកគ្រប់គ្រង, កែសម្រួលទំព័រព័ត៌មាននៃសកម្មភាពសកម្មភាពព្រឹត្តិការណ៍។ អ្នកគ្រប់គ្រងមានសិទ្ធិក្នុងការអនុម័តមាតិកានិងទទួលយកសមាជិកថ្មី',
  "membersmembertips":'អ្នកប្រើប្រាស់សកម្មភាពសកម្មភាពព្រឹត្តិការណ៍ណាមួយ',
  "membersownertips":'ប្រធានជាន់ខ្ពស់នៃសកម្មភាពសកម្មភាពសកម្មភាពសេចក្តីប្រាថ្នា។ អ្នកគ្រប់គ្រងមានសិទ្ធិតំឡើងរដ្ឋបាលអនុម័តលើសមាជិកថ្មី',
  "merge tags":'បញ្ចូលស្លាកចូល',
  "message has been sent":'សាររបស់អ្នកត្រូវបានផ្ញើ។ សូមអរគុណចំពោះការបំពេញទម្រង់របស់យើង!',
  "methods":'ការទូទាត់សរុប',
  "min margin value":'តម្លៃរឹមទាប',
  "min_age":'អាយុអប្បបរមា',
  "min_salary":'ប្រាក់ខែអប្បបរិមា',
  "minimum margin value":'តម្លៃរឹមអប្បបរមាអប្បបរមា',
  "minimum with":'អប្បបរមាជាមួយ',
  "minute(m)":'នាទី (ម)',
  "missing data":'បាត់ព័ត៌មាន',
  "mission":'បេសកកម្ផ',
  "mission_placeholder":'បេសកកម្មនៃសកម្មភាព',
  "money":'ប្រាក់',
  "month":'ខែ',
  "months":'ខែ',
  "more":'មើលបន្ថែម',
  "more topics to continue":'ប្រធានបទជាច្រើនទៀតដែលត្រូវបន្ត',
  "multiple choice":'ពហុ​ជម្រើស',
  "mutual users":'មិត្តភក្តិទៅវិញទៅមក',
  "my auctions":'ការដេញថ្លៃរបស់ខ្ញុំ',
  "my orders":'ការបញ្ជាទិញរបស់ខ្ញុំ',
  "my questions":'សំណួររបស់ខ្ញុំ',
  "my reviews":'ការពិនិត្យឡើងវិញរបស់ខ្ញុំ',
  "my stores":'ហាងរបស់ខ្ញុំ',
  "myfollowers":'តាមដានរបស់អ្នក',
  "name":'ឈ្មោះ',
  "name of consignee":'ឈ្មោះរបស់អ្នកទទួល',
  "name of people you want to invite":'ឈ្មោះមនុស្សដែលអ្នកចង់អញ្ជើញ',
  "nameplaceholder":'ឈ្មោះ​ពេញ',
  "nature":'ធម្មជាតិ',
  "need":'ការប្រមូលមូលនិធិ',
  "need category type":'ប្រភេទប្រមូលមូលនិធិ',
  "need contact":'ព័ត៌មានដើម្បីចូលរួមវិភាគទាន',
  "need from":'ចាប់ផ្តើម',
  "need help":'ត្រូវការ​ជំនួយ',
  "need information":'ត្រូវការព័ត៌មាន',
  "need manager":'ការគ្រប់គ្រងការប្រមូលមូលនិធិ',
  "need title":'ចំណងជើងការប្រមូលមូលនិធិ',
  "need to":'ទទួលបានកាលបរិច្ឆេទ',
  "needs":'ការប្រមូលមូលនិធិ',
  "new activity":'សកម្មភាពថ្មី',
  "new donate option":'ជម្រើសបរិច្ចាគថ្មី',
  "create organization":'បង្កើតអង្គការ',
  "new location":'បន្ថែមទីតាំង',
  "new orders":'ការបញ្ជាទិញថ្មី',
  "new password":'ពាក្យសម្ងាត់​ថ្មី',
  "new products":'ផលិតផល​ថ្មី',
  "new question":'សំណួរថ្មី',
  "news":'ព័ត៌មាន',
  "next":'បន្ទាប់',
  "next item":'ផលិតផលបន្តបន្ទាប់',
  "nextevents":'ព្រឹត្តិការណ៍បន្ទាប់',
  "NGO, NPO, CSR, Social group":'អង្គការក្រៅរដ្ឋាភិបាល, NPO, CSR, ក្រុមសង្គម',
  "ngo/ non - profit":'អង្គការមិនមែនរដ្ឋាភិបាល / មិនមែន - ប្រាក់ចំណេញ',
  "no":'គ្មាន',
  "no categories":'គ្មានប្រភេទ',
  "no data yet to display":'មិនមានទិន្នន័យនៅឡើយទេដើម្បីបង្ហាញ',
  "no item found":'រកមិនឃើញទិន្នន័យ',
  "no matches for":'មិនមានការប្រកួតសម្រាប់',
  "No matter what you\'re a NPO or an enterprise or just an individual who passionote to make change, you\'re our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.":'មិនថាអ្នកធ្វើអ្វីដែលអ្នកប្រើឬសហគ្រាសឬគ្រាន់តែបុគ្គលដែលមានបំណងចង់ធ្វើការផ្លាស់ប្តូរអ្នកនឹងមិត្តរបស់យើង។ សេចក្តីប្រាថ្នាបម្រើការងារឥតគិតថ្លៃដល់អ្នកដើម្បីចាប់ផ្តើម។ យើងសូមស្វាគមន៍ចំពោះអ្នកឧបត្ថម្ភឬការចូលរួមចំណែករបស់អ្នកសម្រាប់លក្ខណៈពិសេសជាមុន។',
  "no milestones were created":'មិនត្រូវបានបង្កើតឡើងទេ',
  "No milestones were created. Click here to start creating a schedule":'មិនត្រូវបានបង្កើតឡើងទេ។ ចុចត្រង់នេះដើម្បីចាប់ផ្តើមបង្កើតកាលវិភាគ',
  "no more item":'មិនមានទិន្នន័យទៀតទេ',
  "no poverty":'បំបាត់ភាពក្រីក្រ',
  "no products in your cart":'មិនមានផលិតផលនៅក្នុងរទេះរបស់អ្នកទេ',
  "no profile found":'រកមិនឃើញទម្រង់',
  "no return":'គ្មានការសងត្រឡប់មកវិញ',
  "no tags":'គ្មានស្លាក',
  "no warranty":'គ្មានការធានា',
  "non-profit activity":'សកម្មភាពមិនរកប្រាក់ចំណេញ',
  "none":'គ្មាន​',
  "NoPoverty":'បំបាត់ភាពក្រីក្រ',
  "normal":'ធម្មតា',
  "Not all of us can do great things. But we can do small things with great love.":'យើងទាំងអស់គ្នាមិនមែនអាចធ្វើរឿងអស្ចារ្យបានទេ។ ប៉ុន្តែយើងអាចធ្វើរឿងតូចតាចដោយក្តីស្រឡាញ់ដ៏អស្ចារ្យ។',
  "not good":'មិនល្អ',
  "not updated":'មិនទាន់មានការតម្លើងថ្មី',
  "not yet comment":'មិនទាន់មានយោបល់នៅឡើយទេ',
  "not_activated_account_credentials":'គណនីមិនត្រូវបានធ្វើឱ្យសកម្ម។ សូមពិនិត្យមើលអ៊ីមែលធ្វើឱ្យសកម្មដើម្បីធ្វើឱ្យវាសកម្ម',
  "not_exists_account_credentials":'គណនីមិនមានទេ។ សូមចុះឈ្មោះមុនឬចូលដោយប្រើគណនីហ្គូហ្គល, ហ្វេសប៊ុក!',
  "note":'កត់សម្គាល់',
  "notice description":'សេចក្តីជូនដំណឹងការជូនដំណឹង',
  "notice management":'ការគ្រប់គ្រងសេចក្តីជូនដំណឹង',
  "notice name":'ឈ្មោះជូនដំណឹង',
  "notices":'ជូនដំណឹង',
  "notifications":'ការជូនដំណឹង',
  "numerical order":'ល.រ',
  "obligations":'កាតព្វកិច្ច',
  "of":'នៃ',
  "old":'មនុស្សចាស់',
  "old password":'លេខសំងាត់​ចាស់',
  "old price":'តម្លៃចាស់',
  "on":'លើ',
  "on this page":'នៅលើទំព័រនេះ',
  "on_site":'នៅ​លើ​គេហទំព័រ',
  "one product of":'ផលិតផលមួយរបស់',
  "online auction channel - charity donation with auctions - sell products with auctions":'ប៉ុស្តិ៍ដេញថ្លៃតាមអ៊ិនធរណេត - ការបរិច្ចាគសប្បុរសធម៌ជាមួយនឹងការដេញថ្លៃ - លក់ផលិតផលដោយការដេញថ្លៃ',
  "online jobs":'ការងារតាមអ៊ិនធរណេត',
  "only me":'មានតែខ្ញុំទេ',
  "open time":'ពេលវេលាបើក',
  "opportunities":'ឱកាស',
  "opportunity":'អ្នកស្ម័គ្រចិត្ត',
  "oppr_type":'ប្រភេទការស្ម័គ្រចិត្ត',
  "optional":'គារជម្រើស',
  "options":'ជម្រើស',
  "or":'រឺ',
  "or a":'ឬមួយ',
  "or helping to connect the social mission and the good beneficiaries":'ឬជួយក្នុងការភ្ជាប់បេសកកម្មសង្គមនិងបំណងប្រាថ្នាដ៏ល្អ',
  "or login directly by":'ឬចូលដោយផ្ទាល់ដោយ',
  "Or you update your personal information, leaving the mode \'available to volunteer\', employers will search for you.":'ឬអ្នកធ្វើបច្ចុប្បន្នភាពព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកដោយបន្សល់ទុករបៀប \ \'ដែលមានសម្រាប់អ្នកស្ម័គ្រចិត្ត \' និយោជិកនឹងស្វែងរកអ្នក។',
  "order":'ការកម៉្មង',
  "order code":'លេខកូដបញ្ជាទិញ',
  "order details":'ព័ត៌មានលម្អិតបញ្ជាទិញ',
  "order filter":'តម្រងបញ្ជាទិញ',
  "order information":'ព័ត៌មានបញ្ជាទិញ',
  "order management":'ការគ្រប់គ្រងការកម៉្មង',
  "order number":'ល.រ',
  "order status":'ស្ថានភាពបញ្ជាទិញ',
  "order user":'គណនីអ្នកប្រើ',
  "ordered at":'បានបញ្ជាទិញនៅ',
  "orders":'ការបញ្ជាទិញ',
  "organization":'សមាគមន៍',
  "organizationtips":'សមាគមន៍ក្រៅរដ្ខាភិបាល',
  "other":'ក្រុម',
  "other activities":'សកម្មភាពផ្សេងៗ',
  "other auction sessions":'វគ្គដេញថ្លៃផ្សេងទៀត',
  "other display":'ការបង្ហាញផ្សេងទៀត',
  "other information":'ព័ត៌មាន​ផ្សេងទៀត',
  "other people":'ផ្សេងទៀត',
  "other reasons":'ហេតុផលផ្សេងទៀត',
  "other_requirements":'តម្រូវការផ្សេងទៀត',
  "others":'ផ្សេងៗ',
  "othertips":'ផ្សេងទៀត',
  "Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.":'ឧបករណ៍របស់យើងជួយដល់ពេលវេលាសន្សំសំចៃពេលវេលាប្រសិទ្ធភាពនិងការធ្វើមាត្រដ្ឋានក្នុងការកេណ្ឌធនធានមនុស្សធនធានហិរញ្ញវត្ថុនិងការអនុវត្តសកម្មភាពនិងការចេញផ្សាយរបាយការណ៍។ យើងមានភាពប្រសើរឡើងឥតឈប់ឈរដើម្បីធ្វើឱ្យអ្នកកាន់តែងាយស្រួលនិងជួយអ្នកឱ្យមានភាពល្អប្រសើរជាងមុន។',
  "out":'ខាងក្រៅ​',
  "output":'ការចេញ',
  "Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.":'លើសពីឆ្នាំនេះយើងមានចំណង់ចំណូលចិត្តការរកចំណូលបានជួយលើកកម្ពស់សកម្មភាពសង្គមឱ្យមានប្រសិទ្ធភាព។ កម្មវិធីបច្ចេកវិទ្យានេះគាំទ្រអ្នកឱ្យអនុវត្តមូលដ្ឋានសកម្មភាពរបស់អ្នកនៅលើ។ ទ្រឹស្តីនៃការផ្លាស់ប្តូរគោលការណ៍: ផ្តោតលើផលប៉ះពាល់ការច្នៃប្រឌិត infomation ដែលអាចទុកចិត្តបាន; ការទំនាក់ទំនងប្រកបដោយតម្លាភាព; ការតភ្ជាប់ប្រកបដោយប្រសិទ្ធភាព។',
  "owner":'ម្ចាស់ហាង',
  "package":'កញ្ចប់',
  "packed":'បានវេចកញ្ចប់',
  "paragraph":'វក្គ',
  "parallel":'ស្រប់',
  "parallel mode":'របៀបស្រប់',
  "part-time":'ក្រៅ​ម៉ោង',
  "partnerships":'ភាពជាដៃគូ',
  "partnerships for the goals":'ភាពជាដៃគូសម្រាប់គោលដៅ',
  "password":'ពាក្យសម្ងាត់',
  "password required":'ទាមទារលេខសម្ងាត់',
  "passwordplaceholder":'ពាក្យសម្ងាត់ (អប្បបរមា 6 តួអក្សរ)',
  "payment":'គារទូទាត់',
  "payment completed":'ការទូទាត់បានបញ្ចប់',
  "payment location":'ទីតាំងបង់ប្រាក់',
  "payment management":'ការគ្រប់គ្រងការទូទាត់',
  "payment method":'វិធី​សា​ស្រ្ត​ទូទាត់',
  "payment type":'ប្រភេទនៃការទូទាត់',
  "peace, justice and strong institutions":'សន្តិភាពយុត្តិធម៌និងស្ថាប័នរឹងមាំ',
  "pending":'កំពុងរងចាំសម្រេច',
  "pending orders":'ការបញ្ជាទិញដែលមិនទាន់សម្រេច',
  "pending payment":'ការទូទាត់មិនទាន់សម្រេច',
  "pending beneficiaries":'កំពុងរង់ចាំឆ្លើយតប',
  "pendingdonations":'ការបរិច្ចាគដែលមិនទាន់សម្រេច',
  "pendingtips":'កំពុងរងចាំសម្រេច',
  "people with disabilities":'ជនពិការ',
  "per day":'ក្នុងមួយថ្ងៃ',
  "per hour":'ក្នុងមួយម៉ោង',
  "per month":'ក្នុង​មួយ​ខែ',
  "per quarter":'ក្នុងមួយលាន',
  "per week":'ក្នុង​មួយ​ស​ប្តា​ហ៍',
  "per year":'ក្នុង​មួយ​ឆ្នាំ',
  "percent":'ភាគរយ',
  "period time":'ពេលវេលាដេញថ្លៃ',
  "person":'មនុស្ស',
  "person_s":'មនុស្ស',
  "phone":'ទូរស័ព្ទ',
  "phone_number":'លេខទូរសព្ទ',
  "phone_placeholder":'លេខទូរស័ព្ទ​របស់​អ្នក',
  "photo":'រូបថត',
  "photo gallery":'សាលរូបភាព',
  "photos":'រូបថត',
  "pick tags":'ជ្រើសរើសស្លាក',
  "place order":'ការបញ្ជាទិញ',
  "please complete the basic information":'សូមបំពេញព័ត៌មានមូលដ្ឋាន',
  "please select an organization, individual or group to be the owner":'សូមជ្រើសរើសអង្គការបុគ្គលឬក្រុមដែលជាម្ចាស់',
  "please sign with us, if you agree":'សូមចុះឈ្មោះជាមួយយើងប្រសិនបើអ្នកយល់ព្រម',
  "please follow these instructions to complete the donation process.":'សូមអនុវត្តតាមការណែនាំទាំងនេះដើម្បីបញ្ចប់ដំណើរការបរិច្ចាគ។',
  "policy name":'ឈ្មោះគោលការណ៍',
  "policyhelp":'គោលនយោបាយនិងលក្ខខណ្ឌ',
  "policyhelptips":'ការជឿទុកចិត្តនិងសុវត្ថិភាពលក្ខខណ្ឌនៃការប្រើប្រាស់គោលការណ៍ភាពឯកជនខូឃីស៍គោលការណ៍ណែនាំសហគមន៍',
  "position":'ទីតាំង',
  "position and size your photo":'ទីតាំងនិងទំហំរូបថតរបស់អ្នក',
  "positive rating":'វាយតម្លៃវិជ្ជមាន',
  "post":'ផុសអត្ថបទ',
  "posted":'ចុះផ្សាយ',
  "prerequisites":'តម្រូវការជាមុន',
  "prerequisites_placeholder":'តម្រូវការជាមុន',
  "current job":'ការងារ​បច្ចុប្បន្ន',
  "prevevents":'ព្រឹត្តិការណ៍មុន ៗ',
  "price":'តម្លៃ',
  "price and quantity":'តម្លៃនិងបរិមាណ',
  "price type":'ប្រភេទតម្លៃ',
  "priority_conditions":'លក្ខខណ្ឌអាទិភាព',
  "priority_conditions_placeholder":'លក្ខខណ្ឌអាទិភាពជាមួយសកម្មភាព',
  "privacy":'ភាពជាឯកជន',
  "privacy policy":'គោលការណ៍​ភាព​ឯកជន',
  "private":'ឯកជន',
  "privatetips":'បិទគេហទំព័រដែលមានសុវត្ថិភាពខ្ពស់បំផុត។ មានតែអ្នកបង្កើតទំព័រប៉ុណ្ណោះដែលអាចមើលមាតិកាផ្សាយព័ត៌មានប្រកាស។',
  "process":'អនុលោម',
  "process to payment":'ដំណើរការទូទាត់ប្រាក់',
  "processing":'នៅក្នុងដំណើរការនេះ',
  "processingtips":'កំពុងដំណើរការ',
  "product":'ផលិតផល',
  "product album":'អាល់ប៊ុមផលិតផល',
  "product attributes":'គុណលក្ខណៈផលិតផល',
  "product categories":'ប្រភេទផលិតផល',
  "product category":'ប្រភេទ​ផលិតផល',
  "product comment":'មតិយោបល់ពីផលិតផល',
  "product description":'ពណ៌នា​ពី​ផលិតផល',
  "product faq":'សំណួរសួរអំពីផលិតផលផលិតផល',
  "product filter":'តម្រងផលិតផល',
  "product filter by":'តម្រងផលិតផលដោយ',
  "product group":'ក្រុមផលិតផល',
  "product in the same auction":'ផលិតផលក្នុងការដេញថ្លៃដូចគ្នា',
  "product in the same categories":'ផលិតផលក្នុងប្រភេទដូចគ្នា',
  "product management":'ការគ្រប់គ្រង​ផលិតផល',
  "product name":'ឈ្មោះ​ផលិតផល',
  "product price":'តម្លៃផលិតផល',
  "product priority":'អាទិភាពផលិតផល',
  "product rating":'ការវាយតម្លៃផលិតផល',
  "product reviews":'ការពិនិត្យផលិតផល',
  "product reviews and ratings":'ការពិនិត្យផលិតផលនិងការវាយតម្លៃផលិតផល',
  "product searching":'ការស្វែងរកផលិតផល',
  "product type":'ប្រភេទ​ផលិតផល',
  "products":'ផលិតផល',
  "products in same category":'ផលិតផលក្នុងប្រភេទដូចគ្នា',
  "products in same store":'ផលិតផលនៅក្នុងហាងតែមួយ',
  "profile":'ប្រវត្តរូប',
  "profile information":'ព័ត៌មានទម្រង់',
  "program description":'ការពិពណ៌នាកម្មវិធី',
  "program filter":'តម្រងកម្មវិធី',
  "program name":'ឈ្មោះកម្មវិធី',
  "promote sharing connecting good things to help people everywhere be happy":'លើកកម្ពស់ការចែករំលែកការភ្ជាប់រឿងល្អ ៗ ដើម្បីជួយមនុស្សគ្រប់ទីកន្លែងឱ្យសប្បាយរីករាយ',
  "promotion":'ការផ្សព្វផ្សាយ',
  "promotion campaign name":'ឈ្មោះយុទ្ធនាការផ្សព្វផ្សាយលើកកម្ពស់',
  "promotion campaigns":'យុទ្ធនាការផ្សព្វផ្សាយ',
  "promotions":'ការផ្សព្វផ្សាយ',
  "propose as owner":'ស្នើសុំធ្វើជាម្ចាស់ផ្ទះ',
  "proposed applicants":'បេក្ខជនដែលបានស្នើឡើង',
  "provide the most accurate address so people can find your store faster":'ផ្តល់នូវអាស័យដ្ឋានត្រឹមត្រូវបំផុតដូច្នេះមនុស្សអាចរកឃើញហាងរបស់អ្នកលឿនជាងមុន',
  "province":'ខេត្ដ',
  "public":'ជាសាធារណះ',
  "public / private":'សាធារណៈ: * ការដេញថ្លៃគឺបើកចំហសម្រាប់មនុស្សគ្រប់គ្នា / ឯកជន: ការដេញថ្លៃគឺសម្រាប់សមាជិកនៃការដេញថ្លៃតែប៉ុណ្ណោះ',
  "public display":'ការបង្ហាញសាធារណៈ',
  "public name":'ឈ្មោះសាធារណៈ',
  "publictips":'ទំព័រអាចមើលឃើញជាសាធារណៈសម្រាប់មនុស្សគ្រប់គ្នា។ អ្នកណាម្នាក់អាចមើលឃើញសមាជិកនិងអ្វីដែលពួកគេប្រកាស',
  "publish reply":'ផ្សព្វផ្សាយការឆ្លើយតប',
  "published":'បានផ្សព្វផ្សាយ',
  "publishedtips":'មាតិកាបានបញ្ចប់ហើយបានបោះពុម្ពផ្សាយអ្នកគ្រប់គ្នាអាចមើលមតិយោបល់ចែករំលែក',
  "publishrequest":'សុំណើរការបោះពុម្ពផ្សាយ',
  "publishrequesttips":'មាតិកាកំពុងត្រូវបានផ្ទៀងផ្ទាត់អ្នកនឹងទទួលបានការជូនដំណឹងតាមអ៊ីមែលក្នុងរយៈពេល 24 ម៉ោង',
  "quality education":'ការអប់រំប្រកបដោយគុណភាព',
  "quantity":'បរិមាណ',
  "question":'សំណួរ',
  "question answer":'សំណួរនិងចម្លើយ',
  "question type":'ប្រភេទសំណួរ',
  "questions":'សំណួរ',
  "questions and answers":'សំណួរនិងចម្លើយ',
  "quote":'សម្រង់',
  "ranking":'ចំណាត់ថ្នាក់',
  "rate store":'អត្រាហាង',
  "rate type":'ប្រភេទអត្រា',
  "rating":'ចំណាត់ថ្នាក់',
  "rating from customers":'ចំណាត់ថ្នាក់ពីអតិថិជន',
  "rating successful":'ការវាយតម្លៃជោគជ័យ',
  "ready to ship":'ត្រៀមខ្លួនជាស្រេចដើម្បីដឹកជញ្ជូន',
  "readytopublish":'ត្រៀមខ្លួនជាស្រេចដើម្បីផ្សព្វផ្សាយ',
  "readytopublishtips":'មាតិកាសមស្រប, មានឆន្ទៈជាសាធារណៈដល់អ្នករាល់គ្នា',
  "reasons":'ហេតុផល',
  "received":'បានទទួល',
  "received unit":'ទទួលបានឯកតា',
  "receiver":'អ្នកទទួល',
  "receiver information":'ព័ត៌មានអ្នកទទួល',
  "recent searches":'ការស្វែងរកថ្មីៗ',
  "recommend":'ណែនាំ',
  "recommend activities":'សកម្មភាពដែលបានស្នើ',
  "recommend events":'ព្រឹត្តិការណ៍ដែលបានស្នើ',
  "recommend groups":'ក្រុមដែលបានស្នើ',
  "recommend needs by following":'បានស្នើឱ្យប្រមូលមូលនិធី',
  "recommend store":'ណែនាំដល់ហាង',
  "recommend tags":'សូមណែនាំស្លាក',
  "recommend users":'មិត្តភក្តិដែលបានស្នើ',
  "recommend beneficiaries":'បំណងប្រាថ្នាដែលបានស្នើ',
  "recruitment for good":'ការជ្រើសរើស',
  "recruitment question":'សំណួរជ្រើសរើសបុគ្គលិក',
  "recruitment_email":'អ៊ីម៉ែលជ្រើសរើសបុគ្គលិក',
  "recruitment_phone":'ទូរស័ព្ទជ្រើសរើសពលករ',
  "red":'ក្រហម',
  "reduced inequalities":'ការកាត់បន្ថយវិសមភាព',
  "ReducedInequalities":'ការកាត់បន្ថយវិសមភាព',
  "reference":'ឯកសារយោង',
  "regconfirmpass":'បញ្ជាក់ពាក្យសម្ងាត់',
  "register":'ការចុះឈ្មោះ',
  "register enabled":'ការចុះឈ្មោះបានបើកដំណើរការបាន',
  "register to donate":'ចុះឈ្មោះដើម្បីបរិច្ចាគ',
  "register value":'ចុះឈ្មោះវាយតម្លៃ',
  "registered number":'លេខដែលបានចុះឈ្មោះ',
  "registered to donate":'ចុះឈ្មោះដើម្បីបរិច្ចាគ',
  "regpass":'ពាក្យសម្ងាត់',
  "regular":'ទៀង​ទា​ត',
  "regulartips":'សកម្មភាពទៀងទាត់',
  "reject":'បដិសេធន៍',
  "rejected":'បានបដិសេធន៍',
  "rejected applicants":'បានបដិសេធបេក្ខជន',
  "rejectedtips":'មាតិកាមិនសមរម្យទេ។ សូមពិនិត្យម្តងទៀតហើយដាក់ស្នើសំណើបោះពុម្ពផ្សាយ',
  "related tags":'ស្លាកដែលទាក់ទង',
  "religion":'សាសនា',
  "remain":'សល់',
  "remember":'ធ្វើឱ្យខ្ញុំចុះហត្ថលេខាចូល',
  "remotely work":'ការងារពីចម្ងាយ',
  "remove":'លុប',
  "remove comment":'លុបមតិយោបល់ចេញ',
  "remove from your order":'លុបចេញពីការបញ្ជាទិញរបស់អ្នក',
  "removed":'បានលុប',
  "replies":'ការឆ្លើយតប',
  "reply":'ការឆ្លើយតប',
  "reply customer":'ឆ្លើយតបអតិថិជន',
  "reply from the store":'ឆ្លើយតបពីហាង',
  "replyplaceholder":'បញ្ចូលការឆ្លើយតប ...',
  "report":'របាយការណ៍',
  "report violation shop":'របាយការណ៍រំលោភលើហាង',
  "reported":'បានធ្វើរបាយការណ៍',
  "reports":'របាយការណ៍',
  "request":'ស្នើសុំ',
  "requested":'បានផ្ញើសំណើ',
  "required":'ចាំបាច់',
  "required information fields":'រាល់ព័ត៌មានដែលត្រូវការ',
  "requirements":'តម្រូវការ',
  "reset":'កំណត់ឡើងវិញ',
  "Reset your password":'កំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញ',
  "responded":'ឆ្លើយ',
  "responsible consumption and production":'ការប្រើប្រាស់និងផលិតកម្មប្រកបដោយការទទួល',
  "results with keyword":'លទ្ធផលជាមួយនឹងពាក្យគន្លឹះ',
  "return conditions":'ល័ក្ខខ័ណ្ឌសងត្រឡប់មកវិញ',
  "return period":'រយៈពេលត្រឡប់មកវិញ',
  "return period type":'ប្រភេទរយៈពេលត្រឡប់មកវិញ',
  "return period value":'តម្លៃរយៈពេលត្រឡប់មកវិញ',
  "return policy":'គោលការណ៍សងត្រឡប់',
  "return to draft":'ត្រឡប់ទៅសេចក្តីព្រាង',
  "returned":'បានត្រឡប់មកវិញ',
  "retype new password":'វាយលេខសម្ងាត់ថ្មី',
  "Retype password":'វាយ​លេខសម្ងាត់​ម្តង​ទៀត',
  "review and rating on store":'ពិនិត្យនិងវាយតម្លៃលើហាង',
  "review management":'ការគ្រប់គ្រងពិនិត្យឡើងវិញ',
  "review store":'វាយតម្លៃហាងឡើងវិញ',
  "reviews":'ការពិនិត្យឡើងវិញ',
  "role":'តួនាទី',
  "role description":'ការពិពណ៌នាតួនាទី',
  "rotate":'បង្វិល',
  "running promotion campaigns":'ប្រាក់បៀរវត្ស',
  "salary":'ប្រាក់បៀរវត្ស',
  "salary period":'រយៈពេលប្រាក់ខែ',
  "sale":'ចុះតម្លៃ',
  "sanitary food":'អាហារអនាម័យ',
  "save":'រក្សាទុក',
  "save data":'រក្សាទុកទិន្នន័យ',
  "save question":'រក្សាទុកសំណួរ',
  "scale":'ធ្វើមាត្រដ្ឋាន',
  "scan code on Wishare for direct access to the target screen":'ស្កេនលេខកូដនៅវីស៊ែរដើម្បីប្តូរទៅអេក្រង់',
  "scan qr-code":'ស្កេន QR-Code',
  "schedule":'ដំណាក់កាល',
  "school":'សាលារៀន',
  "School Book":'សៀវភៅ',
  "sdg":'គោលដៅអភិវឌ្ឃន៍ប្រកបដោយចីរភាព',
  "search":'ស្វែងរក',
  "search activities":'សកម្មភាពស្វែងរក',
  "search category":'ប្រភេទស្វែងរក',
  "search events":'ព្រឹត្តិការណ៍ស្វែងរក',
  "search groups":'ការស្វែងរកក្រុម',
  "search groups users activities you want to be hosts":'ក្រុមស្វែងរក, អ្នកប្រើសកម្មភាពដែលអ្នកចង់ក្លាយជាម៉ាស៊ីន',
  "search groups,users,activities you want to be hosts":'ក្រុមស្វែងរក, អ្នកប្រើសកម្មភាពដែលអ្នកចង់ក្លាយជាម៉ាស៊ីន',
  "search in wishop":'ស្វែងរកក្នុងវីស៊ែរ',
  "search members":'ស្វែងរកសមាជិក ...',
  "search needs":'តម្រូវការស្វែងរក',
  "search jobs":'ស្វែងរកការងារ',
  "search product to attach":'ស្វែងរកផលិតផលភ្ជាប់',
  "search selected category":'ស្វែងរកប្រភេទដែលបានជ្រើសរើស',
  "search user":'ស្វែងរកអ្នកប្រើប្រាស់',
  "search beneficiaries":'ស្វែងរកការប្រាថ្នា',
  "searching":'ការស្វែងរក',
  "searching result is empty":'ស្វែងរកលទ្ធផលគឺទទេ',
  "searching volunteers":'ស្វែងរកអ្នកស្ម័គ្រចិត្ត',
  "searchplaceholder":'ស្វែងរក ..',
  "seconds(s)":'វិនាទី (s)',
  "secret":'ការសម្ងាត់',
  "secrettips":'ទំព័របង្ហាញថាសមាជិកផ្ទៃក្នុងផ្លូវការអាចចាត់ទុកសមាជិកចែករំលែកប្រកាសសារ',
  "security policy":'គោលការណ៍សន្តិសុខ',
  "see all":'ឃើញ​ទាំងអស់',
  "see less":'បង្ហាញតិច',
  "see map":'សូមមើលផែនទី',
  "see more":'មើល​បន្ថែម​ទៀត',
  "see more jobs":'មើលការងារបន្ថែមទៀត',
  "seek financial capital for good initiatives":'ស្វែងរកដើមទុនហិរញ្ញវត្ថុសម្រាប់គំនិតផ្តួចផ្តើមល្អ',
  "select":'ជ្រើសរើស',
  "select all":'ជ្រើសរើស​ទាំងអស់',
  "select attribute...":'ជ្រើសរើសគុណលក្ខណៈ ...',
  "select location":'ជ្រើសរើសទីតាំង',
  "select location on map":'ជ្រើសរើសទីតាំងនៅលើផែនទី',
  "select option":'ជ្រើសរើសជំរើស',
  "select the payment method":'ជ្រើសរើសវិធីសាស្ត្រទូទាត់',
  "select the product catalog for your store":'ជ្រើសរើសកាតាឡុកផលិតផលសម្រាប់ហាងរបស់អ្នក',
  "select the topic category you are interested in by clicking on the items below":'ជ្រើសរើសប្រភេទប្រធានបទដែលអ្នកចាប់អារម្មណ៍ដោយចុចលើធាតុខាងក្រោម',
  "select_conditions":'ជ្រើសរើសល័ក្ខខ័ណ្ឌ',
  "select_conditions_placeholder":'ជ្រើសរើសល័ក្ខខ័ណ្ឌ',
  "Select...":'ជ្រើសរើស ...',
  "Sell as lot":'លក់ឱ្យបានច្រើន',
  "selling price":'តំលៃលក់',
  "send a message to <%= title %> with questions or comments about this volunteer opportunity.":'ផ្ញើសារទៅ <% = tlet%> ដោយមានសំណួរឬយោបល់អំពីឱកាសអ្នកស្ម័គ្រចិត្តនេះ។',
  "send feedback":'ផ្ញើមតិប្រតិកម្ម',
  "send to":'ផ្ញើទៅ',
  "send to an activity":'ផ្ញើទៅសកម្មភាពមួយ',
  "sender":'អ្នកផ្ញើ',
  "senior advisor":'ទីប្រឹក្សាជាន់ខ្ពស់',
  "sent":'បានផ្ញើ',
  "sequence":'តាមលេខរៀង',
  "sequence / parallel":'លំដាប់លំដោយ: ផលិតផលនៅក្នុងកម្មវិធីនឹងត្រូវបានដាក់លក់ដេញថ្លៃផលិតផលនីមួយៗតាមលំដាប់ដែលបានកំណត់។ មានតែវគ្គតែមួយតែមួយប៉ុណ្ណោះក្នុងពេលតែមួយ / ប៉ារ៉ាឡែល: * ផលិតផលនៅក្នុងកម្មវិធីតែមួយនឹងត្រូវបានដាក់លក់ដេញថ្លៃក្នុងពេលតែមួយក្នុងវគ្គផ្សេងៗគ្នា។',
  "set as default":'កំណត់ជាលំនាំដើម',
  "setting":'ការកំណត់',
  "settings":'ការកំណត់',
  "share":'ការរមលេក',
  "share options":'ជម្រើសចែករំលែក',
  "share to multipal pages":'ចែករំលែកទៅពហុមុខងារ',
  "share to my page":'ចែករំលែកទៅទំព័ររបស់ខ្ញុំ',
  "share with everyone":'ចែករំលែកជាមួយអ្នករាល់គ្នា',
  "shared":'ដែលបានចែករំលែក',
  "shipped":'ដឹកជញ្ជូន',
  "shop":'ហាង',
  "shop description":'ការពិពណ៌នាអំពីហាង',
  "shop for you":'ហាងសម្រាប់អ្នក',
  "shop infomation":'ព័ត៌មានហាង',
  "shop product":'ផលិតផលពីហាង',
  "shop products faq":'ផលិតផលពីហាង FAQ',
  "shopping":'ការដើរទិញឥវ៉ាន់',
  "shopping management":'ការគ្រប់គ្រងទិញឥវ៉ាន់',
  "short":'ខ្លី / តាប',
  "should change status to publishrequest to complete":'គួរតែផ្លាស់ប្តូរស្ថានភាពដើម្បីអនុម័តសំណើដើម្បីបញ្ចប់',
  "show":'បង្ហាញ',
  "show map":'បង្ហាញផែនទី',
  "show profile":'បង្ហាញទម្រង់',
  "show result for":'បង្ហាញលទ្ធផលសម្រាប់',
  "sign":'ស៊ីញ៉េ',
  "Sign in":'ចូល',
  "sign in to connect":'ចូលដើម្បីភ្ជាប់ទំនាក់ទំនងជាមួយសហគមន៍',
  "Sign up":'ចុះ​ឈ្មោះ',
  "signed":'បាន​ចុះហត្ថលេខា​លើ',
  "signin":'ចូល',
  "silent mode":'របៀបស្ងាត់',
  "single":'នៅលីវ',
  "singletips":'នៅលីវ',
  "sitemap":'បញ្ចីផែនទី',
  "Size":'ខ្នាត់',
  "skills":'ជំនាញ',
  "social activities exits everywhere, form individuals, NPOs or enterprises":'សកម្មភាពសង្គមបានចេញនៅគ្រប់ទីកន្លែងទំរង់បុគ្គលននិងសហគ្រាស',
  "social capital":'សង្គមនិងហិរញ្ញវត្ថុ',
  "social worker":'អ្នកបំរើ​ការងារ​សង្គម',
  "sold":'បានលក់',
  "sold by":'លក់​ដោយ',
  "sold price":'តម្លៃលក់',
  "sold quantity":'បរិមាណលក់',
  "specific return policy":'គោលការណ៍ត្រឡប់មកវិញជាក់លាក់',
  "specific warranty policy":'គោលការណ៍ធានាជាក់លាក់',
  "specific_activity":'សកម្មភាពជាក់លាក់',
  "specific_activity_name":'ឈ្មោះសកម្មភាពជាក់លាក់',
  "specifications":'លក្ខណៈពិសេស',
  "sponsors for the project":'អ្នកឧបត្ថម្ភសម្រាប់គម្រោង',
  "sport":'កីឡា',
  "sports & entertainment":'កីឡានិងការកម្សាន្ត',
  "Sports & Travel":'កីឡានិងការធ្វើដំណើរ',
  "star":'ផ្កាយ',
  "stars":'ផ្កាយ',
  "start":'ចាប់ផ្តើម',
  "start at":'ចាប់ផ្តើមនៅ',
  "start creating activities, events or groups to declare organizations seeking volunteers (if you\'ve shared organizational information, go to step 3)":'ចាប់ផ្តើមបង្កើតសកម្មភាពព្រឹត្តិការណ៍ឬក្រុមដើម្បីប្រកាសពីអង្គការស្វែងរកអ្នកស្ម័គ្រចិត្ត (ប្រសិនបើអ្នកបានចែករំលែកព័ត៌មានអង្គការបានចែករំលែកទៅជំហានទី 3)',
  "start date":'ថ្ងៃ​ចាប់ផ្តើម',
  "start_at":'ចាប់ផ្តើមនៅ',
  "start_date":'ថ្ងៃ​ចាប់ផ្តើម',
  "start_time":'ពេលវេលាចាប់ផ្តើម',
  "status":'ស្ថានភាព',
  "statusplaceholder":'ជ្រើសរើសស្ថានភាព',
  "step":'ជំហាន',
  "step 1":'ជំហានទី 1',
  "step 2":'ជំហានទី 2',
  "step 3":'ជំហានទី 3',
  "step 4":'ជំហានទី 4',
  "step 5":'ជំហានទី 5',
  "step 6":'ជំហានទី 6',
  "Steps to create an activity:":'ជំហានដើម្បីបង្កើតសកម្មភាពមួយ:',
  "stop donate":'ឈប់បរិច្ចាគ',
  "store address":'អាសយដ្ឋានរក្សាទុក',
  "store description":'ការពិពណ៌នាទុកហាង',
  "store manager":'អ្នកគ្រប់គ្រង​ស្តុក',
  "store name":'ឈ្មោះហាង',
  "store setting":'ការកំណត់ហាង',
  "strategic partner":'ដៃគូយុទ្ធសាស្ត្រ',
  "strategic sponsor":'អ្នកឧបត្ថម្ភយុទ្ធសាស្ត្រ',
  "strengthen successful connections based on the":'ពង្រឹងការតភ្ជាប់ដែលទទួលបានជោគជ័យដោយផ្អែកលើឯកសារ',
  "sub total":'សរុបរង',
  "submit":'ដាក់ចូល',
  "subscription email":'អ៊ីម៉ែលនៃការជាវ',
  "subscription_email":'អ្នកអាចចុះឈ្មោះជាវអ៊ីម៉ែលដើម្បីទទួលបានការជូនដំណឹងថ្មីពីប្រព័ន្ធ',
  "success":'ជោគជ័យ',
  "successful":'ដែលបានជោគជ័យ',
  "support":'គាំទ្រ',
  "Support the UN Global Goals":'គាំទ្រដល់គោលដៅសកលរបស់អង្គការសហប្រជាជាតិ',
  "supported":'បានគាំទ្រ',
  "supporthelp":'មជ្ឈមណ្ឌលគាំទ្រ',
  "supporthelptips":'ការណែនាំសំណួរជំនួយមជ្ឈមណ្ឌលជំនួយ',
  "sustainable cities and communities":'ទីក្រុងនិងសហគមន៍ប្រកបដោយនិរន្តភាព',
  "SustainableCitiesAndCommunities":'ទីក្រុងនិងសហគមន៍ប្រកបដោយនិរន្តភាព',
  "system error":'សូមអភ័យទោសមានបញ្ហាជាមួយកម្មវិធី',
  "tag":'ស្លាក',
  "tags":'ស្លាក',
  "tags for you":'ស្លាកសម្រាប់អ្នក',
  "tagsplaceholder":'ជ្រើសរើសស្លាក',
  "take":'យក',
  "target":'គោលដៅ',
  "teacher, teaching assistant":'គ្រូជំនួយការបង្រៀន',
  "team":'ក្រុម',
  "temporary winner":'អ្នកឈ្នះបណ្តោះអាសន្ន',
  "terms":'លក្ខខណ្ឌ',
  "terms of use":'លក្ខខណ្ឌ​នៃ​សេវាកម្ម',
  "thank you":'សូមអរគុណ',
  "thank you for registering to donate":'សូមអរគុណចំពោះការចុះឈ្មោះបរិច្ចាគ',
  "Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.":'សូមអរគុណដែលបានចំណាយពេលបំពេញបែបបទមតិប្រតិកម្មតាមអ៊ីនធឺណិតរបស់យើង។ តាមរយៈការផ្តល់យោបល់របស់អ្នកឱ្យយើងអ្នកកំពុងជួយយើងឱ្យយល់ពីអ្វីដែលយើងធ្វើបានល្អហើយអ្វីដែលមានភាពប្រសើរឡើងនូវអ្វីដែលមានភាពប្រសើរឡើងដើម្បីអនុវត្ត។',
  "thank you for your feed back to product and shop":'សូមអរគុណចំពោះការមាតិរបស់អ្នកត្រឡប់ទៅផលិតផលនិងហាង',
  "the 17 goals":'គោលដៅទាំង 17 របស់អង្គការសហប្រជាជាតិ',
  "the accept action was successful":'សកម្មភាពទទួលយកបានជោគជ័យ',
  "the activation email has been sent to":'អ៊ីមែលធ្វើឱ្យសកម្មត្រូវបានផ្ញើទៅ',
  "the causes that we serve":'មូលហេតុដែលយើងបម្រើ',
  "the community creates social impact, does the good things and lets the wind blow away":'សហគមន៍បង្កើតឥទ្ធិពលដល់សង្គម \ លើរបស់ល្អ ៗ ហើយអនុញ្ញាតឱ្យបត់បែនចេញបាន',
  "The following topics are currently merged into this topic:":'ប្រធានបទខាងក្រោមនេះបច្ចុប្បន្នត្រូវបានបញ្ចូលគ្នាទៅក្នុងប្រធានបទនេះ:',
  "The information given within the Feedback Form will be used for service improvement only and are strictly confidential.":'ព័ត៌មានដែលបានផ្តល់ឱ្យនៅក្នុងទំរង់មតិប្រតិកម្មនឹងត្រូវបានប្រើសម្រាប់ការកែលម្អសេវាកម្មតែប៉ុណ្ណោះហើយជាការសម្ងាត់បំផុត។',
  "the information has been saved successful":'ព័ត៌មានត្រូវបានរក្សាទុកដែលទទួលបានជោគជ័យ',
  "the item has been saved":'ផលិតផលត្រូវបានរក្សាទុក',
  "the items you are you had accepted to be there hosts":'របស់របរដែលអ្នកបានទទួលយកនៅទីនោះ',
  "the items you had been requested to be a host":'ផលិតផលដែលអ្នកត្រូវបានស្នើសុំធ្វើជាម្ចាស់ផ្ទះ',
  "the option has been added successful":'ជម្រើសត្រូវបានបន្ថែមដោយជោគជ័យ',
  "The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.":'វេទិកានេះជួយអ្នកឱ្យទាញយកអត្ថប្រយោជន៍ពីការអភិវឌ្ឍបច្ចេកវិទ្យាផ្តោតលើសកម្មភាពសង្គមនិងទំនោរប្រុលពានខ្លួនអ្នកនៅក្នុងសហគមន៍ Lage ជាមួយនឹងបំណងប្រាថ្នាចង់ទទួលបានផលប៉ះពាល់យ៉ាងខ្លាំង។ វេទិកានេះអាចចូលដំណើរការបាននៅក្នុងពហុមុខងារនិងអនុញ្ញាតឱ្យមនុស្សធ្វើអន្តរកម្មក្នុងពេលវេលាជាក់ស្តែងប្រមូលផ្តុំការគាំទ្រយ៉ាងខ្លាំងពីសហគមន៍។',
  "the recruitment in the period":'ការជ្រើសរើសក្នុងរយៈពេលនេះ',
  "the registration of funding for the project":'ការចុះឈ្មោះថវិកាសម្រាប់គម្រោងនេះ',
  "the reject action was successful":'សកម្មភាពបដិសេធទទួលបានជោគជ័យ',
  "the social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission":'សកម្មភាពសង្គមដែលមានបំណងចង់ធ្វើឱ្យមានផលប៉ះពាល់ដោយការកៀរគរធនធានសហគមន៍និងអនុវត្តបេសកកម្មល្អរបស់ពួកគេ',
  "the warning has been sent to admin":'ការព្រមានត្រូវបានផ្ញើទៅអ្នកគ្រប់គ្រង',
  "there are items that are not shown due to the privacy policies of those items":'របស់របរខ្លះមិនត្រូវបានបង្ហាញជាសាធារណៈដោយសារគោលការណ៍ភាពឯកជននិងសុវត្ថិភាព',
  "there is no need for transfer":'នេះមិនចាំបាច់ផ្ទេរទេ',
  "This function is currently not open to everyone, please contact the Wishare administrator":'បច្ចុប្បន្នមុខងារនេះមិនបើកចំហសម្រាប់មនុស្សគ្រប់គ្នាទេសូមទាក់ទងរដ្ឋបាលវីស៊ែរ',
  "This page is collected and indexed by Wishare":'ទំព័រនេះត្រូវបានប្រមូលហើយដាក់សន្ទស្សន៍ដោយវីស៊ែរ',
  "This page is collected and indexed by Wishare so that the community can easily find and contact the program":'ទំព័រនេះត្រូវបានប្រមូលនិងធ្វើលិបិក្រមដោយចង់បានដូច្នេះសហគមន៍អាចរកបានយ៉ាងងាយស្រួលនិងទាក់ទងកម្មវិធីបានយ៉ាងងាយស្រួល',
  "this product will extract":'ផលិតផលនេះនឹងស្រង់ចេញ',
  "this will send by admin":'មាតិកានេះនឹងត្រូវបានផ្ញើដោយអ្នកគ្រប់គ្រង',
  "tick size":'ទំហំធីក',
  "time":'ពេលវេលា',
  "time and location":'ពេលវេលានិងទីតាំង',
  "time characteristic":'លក្ខណៈពេលវេលា',
  "time open":'ពេលវេលាបើក',
  "time_close":'ពេលវេលាបញ្ចប់',
  "time_open":'ពេលវេលាចាប់ផ្តើម',
  "time_type":'ប្រភេទពេលវេលា',
  "timeline":'ការចាប់ផ្តើម',
  "title":'ចំណងជើង',
  "titleplaceholder":'សរសេរចំណងជើងនៅទីនេះ។',
  "to":'ដល់',
  "to develop the community or":'ដើម្បីអភិវឌ្ឍសហគមន៍ឬ',
  "to donate":'បរិច្ចាគ',
  "To help beneficiaries find your activity, create your activity on Wishare platform.":'ដើម្បីជួយអ្នកទទួលផលរកឃើញសកម្មភាពរបស់អ្នកបង្កើតសកម្មភាពរបស់អ្នកនៅលើវេទិវីស៊ែរ',
  "To help beneficiaries find your need, create your activity on Wishare platform.":'ដើម្បីជួយអ្នកទទួលផលរកឃើញសកម្មភាពរបស់អ្នកបង្កើតសកម្មភាពរបស់អ្នកនៅលើវេទិវីស៊ែរ',
  "to help us continue serving the community":'ដើម្បីជួយឱ្យយើងបន្តបម្រើសហគមន៍',
  "to now":'ទៅឥឡូវនេះ',
  "to support us spread the goodness":'ដើម្បីគាំទ្រយើងផ្សព្វផ្សាយអំពើល្អ',
  "token_expired":'សម័យមិនត្រឹមត្រូវឬបានផុតកំណត់។ សូមចូលម្តងទៀត',
  "top":'កំពូល',
  "total":'បូកសរុប',
  "total donation":'សរុបការបរិច្ចាគ',
  "total donation values":'តម្លៃបរិច្ចាគសរុប',
  "total payment":'ការទូទាត់សរុប',
  "total product amount":'ចំនួនផលិតផលសរុប',
  "total values":'តម្លៃសរុប',
  "total_activities":'សកម្មភាព',
  "total_activities_official":'សកម្មភាព (ផ្លូវការ)',
  "total_activities_official_tips":'សកម្មភាពត្រូវបានបង្កើតនិងដំណើរការដោយសហគមន៍',
  "total_activities_tips":'សកម្មភាពសង្គមត្រូវបានបង្កើតឡើង (ឬដាក់លិបិក្រមដោយការចង់បាន) និងធ្វើបច្ចុប្បន្នភាពទៅសហគមន៍',
  "total_address":'ភ្ជាប់ជាមួយ',
  "total_crowdfunding_amount":'ការប្រមូលមូលនិធិ',
  "total_crowdfunding_amount_tips":'រៃអង្គាសប្រាក់សម្រាប់សកម្មភាពនិងការងារសង្គម',
  "total_crowdfundings":'ការប្រមូលមូលនិធិ',
  "total_crowdfundings_tips":'ការប្រមូលរៃអង្គាសថវិកាសម្រាប់គោលបំណងសង្គមនិងច្នៃប្រឌិត',
  "total_donators":'សរុបនៃអ្នកបរិច្ចាគ',
  "total_events":'ព្រឹត្តិការណ៍ផ្សេងៗ',
  "total_events_happening":'ព្រឹត្តិការណ៍កំពុងដំណើរការ',
  "total_events_happening_tips":'ព្រឹត្តិការណ៍បានកើតឡើងនៅពេលនេះ',
  "total_events_incoming":'ព្រឹត្តិការណ៍ចូល',
  "total_events_incoming_tips":'ព្រឹត្តិការណ៍ដែលបានចាប់ផ្តើមនៅពេលអនាគត',
  "total_events_tips":'ព្រឹត្តិការណ៍ទាំងអស់បានកើតឡើងហើយនឹងកើតឡើង',
  "total_followers":'អ្នកតាមដាន',
  "total_following":'កំពុងតាមដាន',
  "total_groups":'ក្រុម',
  "total_links":'តំណភ្ជាប់',
  "total_links_tips":'ចំនួនតំណភ្ជាប់ដែលភ្ជាប់ពីសេចក្តីប្រាថ្នាក្នុងការគាំទ្រសកម្មភាព',
  "total_members":'សមាជិក',
  "total_opportunities":'ការងារ',
  "total_opportunities_tips":'សម្រាប់ការងារស្ម័គ្រចិត្តនិងការងារឥទ្ធិពលសង្គម',
  "total_shares":'ចែករំលែក',
  "total_support":'គាំទ្រ',
  "total_views":'ការមើលសរុប',
  "total_volunteers":'អ្នកស្ផ័គ្រចិត្ដ',
  "total_volunteers_tips":'ភ្ជាប់មនុស្សដែលត្រូវការជំនួយនិងទទួលធនធានមនុស្សផ្សព្វផ្សាយនិងបង្កើនប្រសិទ្ធភាពការងារសង្គម',
  "total_votes":'សម្លេងឆ្នោត',
  "total_beneficiaries":'ប្រាថ្នា',
  "total_beneficiaries_done":'បានបញ្ចប់បំណងប្រាថ្នា',
  "total_beneficiaries_done_tips":'បំណងប្រាថ្នាទទួលបានជោគជ័យ',
  "total_beneficiaries_tips":'រាល់បំណងប្រាថ្នាទាំងអស់ត្រូវបានផ្ញើតាមលើវីស៊ែរ',
  "trademark":'ពាណិជ្ជសញ្ញា',
  "transfer":'ផ្ទេរ',
  "transfer all":'ផ្ទេរទាំងអស់',
  "trends":'និន្នាការ',
  "trust and safety":'ការជឿទុកចិត្តនិងសុវត្ថិភាព',
  "trustworthy efficient transparent":'គួរឱ្យទុកចិត្ត, ប្រសិទ្ធិភាព, ប្រសិទ្ធភាព, ថ្លា, មានតម្លាភាព',
  "tutorial":'ការណែនាំ',
  "type":'ប្រភេទ',
  "type new password":'វាយលេខសម្ងាត់ថ្មី',
  "type of group":'ប្រភេទក្រុម',
  "type of work":'ប្រភេទការងារ',
  "type old password":'វាយពាក្យសម្ងាត់ចាស់',
  "type to search":'ប្រភេទដើម្បីស្វែងរក',
  "type_association":'សមាគមន៍',
  "type_associationtips":'សមាគមន៍',
  "type_company":'ក្រុមហ៊ុន',
  "type_companytips":'ក្រុមហ៊ុន',
  "type_foundation":'មូលនិធិ',
  "type_foundationtips":'មូលនិធិ',
  "type_group":'ក្រុម',
  "type_grouptips":'ក្រុម',
  "type_ngo":'អង្គការក្រៅរដ្ឋាភិបាល',
  "type_ngotips":'អង្គការក្រៅរដ្ឋាភិបាល',
  "type_npo":'អង្គការមិនរកប្រាក់ចំណេញ',
  "type_npotips":'អង្គការមិនរកប្រាក់ចំណេញ',
  "type_organization":'សមាគមន៍',
  "type_organizationtips":'សមាគមន៍',
  "type_religion":'សាសនា',
  "type_religiontips":'សាសនា',
  "type_school":'សាលារ្យេន',
  "type_schooltips":'សាលារ្យេន',
  "typical products":'ផលិតផលធម្មតា',
  "typical shops":'ហាងធម្មតា',
  "typing":'វាយ',
  "undefined":'មិនបានកំណត់',
  "undefinedtips":'មិនបានកំណត់',
  "unfilter":'មិនបានតម្រង',
  "unfollow":'មិនបានតាមដាន',
  "unit":'ឯកតា',
  "unlimited":'គ្មានដែនកំណត់',
  "unlimited age":'គ្រប់​វ័យ',
  "unread":'មិនអាន',
  "update":'ការអាប់ដេត',
  "update your profile":'ធ្វើបច្ចុប្បន្នភាពប្រវត្តិរូបរបស់អ្នក',
  "upload images from your computer":'ផ្ទុកឡើងរូបភាពពីកុំព្យូទ័ររបស់អ្នក',
  "user":'អ្នក​ប្រើ',
  "user product faq":'សំណួរសួរអំពីផលិតផលអ្នកប្រើប្រាស់',
  "user profile":'ទម្រង់អ្នកប្រើប្រាស់',
  "user settings":'ការកំណត់អ្នកប្រើ',
  "user_not_found":'វេនចូលបានផុតកំណត់ឬគណនីមិនត្រឹមត្រូវសូមចូលម្តងទៀត',
  "username":'ឈ្មោះ​អ្នកប្រើប្រាស់',
  "users":'អ្នកប្រើប្រាស់',
  "value":'តម្លៃ',
  "value type":'ប្រភេទតម្លៃ',
  "value_winner":'តម្លៃអ្នកឈ្នះ',
  "variants":'វ៉ារ្យ៉ង់ផលិតផល',
  "verify":'ផ្ទៀង​ផ្ទាត់',
  "verifying":'កំពុងផ្ទៀង​ផ្ទាត់',
  "view more":'មើល​បន្ថែម​ទៀត',
  "view profile":'មើលពត៌មាន',
  "vision":'ចក្ខុវិស័យ',
  "Visually impaired people":'មនុស្សដែលមានភាពអន់ថយ',
  "volunteer":'អ្នកស្ម័គ្រចិត្ត',
  "volunteer & jobs":'អ្នកស្ម័គ្រចិត្តនិងការងារ',
  "volunteer_profile":'ទម្រង់អ្នកស្ម័គ្រចិត្ត',
  "volunteers":'អ្នកស្ម័គ្រចិត្ត',
  "vote":'ការបោះឆ្នោត',
  "voted":'បានបោះឆ្នោត',
  "wage":'ប្រាក់ឈ្នួល',
  "wait for reply":'រង់ចាំការឆ្លើយតប',
  "waiting":'រងចាំ',
  "waiting applicants":'អ្នកដាក់ពាក្យដែលកំពុងរងចាំ',
  "waiting need":'កំពុងដំណើរការ',
  "want to help":'ចង់ជួយ',
  "ward":'ភូមិ ឃុំ',
  "warned":'បានដាស់តឿន',
  "warranty & return":'ការធានានិងការសងត្រឡប់មកវិញ',
  "warranty conditions":'លក្ខខណ្ឌធានា',
  "warranty period":'រយៈ​ពេល​ធានា',
  "warranty period type":'ប្រភេទនៃការធានារយៈពេល',
  "warranty period value":'តម្លៃរយៈពេលធានា',
  "warranty policy":'គោលការណ៍នៃការធានា',
  "warranty-policies":'គោលការណ៍នៃការធានា',
  "warranty-return":'ការធានានិងការសងត្រឡប់មកវិញ',
  "was accepted for job at":'ត្រូវបានទទួលយកសម្រាប់ការងារនៅ',
  "we believe that sharing and connecting these beneficiaries will create a great impact":'យើងជឿជាក់ថាការចែករំលែកនិងការភ្ជាប់បំណងប្រាថ្នាទាំងនេះនឹងបង្កើតផលប៉ះពាល់យ៉ាងខ្លាំង',
  "We welcome any feedback, please feel free to contact us":'យើងស្វាគមន៍រាល់មតិប្រតិកម្មសូមទាក់ទងមកយើងខ្ញុំ',
  "we\'ve":'យើង​មាន',
  "we\'ve found <%= number %> results":'យើងរកឃើញ <% = លេខ%> លទ្ធផល',
  "website":'វេបសាយថ៍',
  "website_placeholer":'(www.example.com)',
  "week":'សបា្ដហ៍',
  "weeks":'សបា្ដហ៍',
  "weight":'ទម្ងន់',
  "welcome to become wishop salesperson":'សូមស្វាគមន៍មកកាន់អ្នកលក់របស់អ្នកចង់បាន',
  "what categories are your interests?":'តើចំណាប់អារម្មណ៍របស់អ្នកគឺជាអ្វី?',
  "what is your position?":'តើជំហររបស់អ្នកគឺជាអ្វី?',
  "what we connect":'អ្វីដែលយើងភ្ជាប់',
  "what you can do?":'អ្វី​ដែល​អ្នក​អាច​ធ្វើ​បាន?',
  "what you need the crowdfunding for":'អ្វីដែលអ្នកត្រូវការការប្រមូលថវិកាសម្រាប់',
  "what you need volunteers for":'អ្នកត្រូវការអ្នកស្ម័គ្រចិត្តដើម្បីអ្វីដែល',
  "when you have a petition for you or for someone":'នៅពេលអ្នកមានញត្តិសម្រាប់អ្នកឬសម្រាប់នរណាម្នាក់',
  "where did you work?":'តើអ្នកបានធ្វើការនៅឯណា?',
  "where does the opportunity take place?":'តើឱកាសនេះកើតឡើងនៅកន្លែងណា?',
  "which school did you study?":'តើអ្នកបានសិក្សាសាលាមួយណា?',
  "who can do for good and make great impact by sharing human capital, financial capital to realise the social mision":'តើអ្នកណាអាចធ្វើបានល្អនិងទទួលបានផលប៉ះពាល់យ៉ាងខ្លាំងដោយចែករំលែកមូលធនរបស់មនុស្សដើមទុនហិរញ្ញវត្ថុដើម្បីដឹងអំពីការផលិតសង្គម',
  "why partner with wishare ?":'ហេតុអ្វីបានជាដៃគូជាមួយនឹងការចង់បាន?',
  "Width":'ទទឹង',
  "will end in":'នឹងបញ្ចប់នៅក្នុង',
  "win price":'តម្លៃឈ្នះ',
  "win_price":'តម្លៃឈ្នះ',
  "winner":'អ្នកឈ្នះ',
  "beneficiary":'ការរបាថ្នា',
  "beneficiary list":'ប្រាថ្នា',
  "wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people":'វីស៊ែរគឺជាវេទិកាចែករំលែកមួយដែលភ្ជាប់សេចក្តីប្រាថ្នាដែលមានឥទ្ធិពលលើផលប៉ះពាល់ការច្នៃប្រឌិតច្នៃប្រឌិតនិងមនុស្សដែលមានចិត្តល្អ',
  "Wishare is willing to adjust the content or transfer the page owner right to the program upon request.":'វីស៊ែរមានឆន្ទៈក្នុងការកែសំរួលមាតិកាឬផ្ទេរម្ចាស់សិទ្ធិចូលក្នុងកម្មវិធីតាមការស្នើសុំ។',
  "beneficiaries":'ប្រាថ្នា',
  "beneficiaries for good":'ប្រាថ្នាចង់បានល្អ',
  "wishop regulation":'បទប្បញ្ញត្តិរបស់អ្នកចង់បាន',
  "beneficiarytips":'បំណងប្រាថ្នាគឺជារបស់ល្អដែលអ្នកចង់ធ្វើ។ អ្នកអាចចែករំលែកបំណងប្រាថ្នារបស់អ្នកចំពោះមិត្តភក្តិរបស់អ្នក, សកម្មភាពដែលមានគោលបំណងកៀរគរការគាំទ្រពីមនុស្សគ្រប់គ្នា។',
  "with":'ជាមួយ',
  "with keyword":'ជាមួយនឹងពាក្យគន្លឹះ',
  "won session":'បានឈ្នះសម័យប្រជុំ',
  "work":'ការងារ',
  "work fit for":'សមការងារសម្រាប់',
  "working date":'កាលបរិច្ឆេទធ្វើការ',
  "working time":'ម៉ោង​ធ្វើការ',
  "workplace":'កន្លែងធ្វើការ',
  "write comment about store":'សរសេរមតិយោបល់អំពីហាង',
  "year":'ឆ្នាំ',
  "years":'ឆ្នាំ',
  "yes":'បាទ',
  "yes / no":'មាន/ទេ',
  "you":'អ្នក',
  "you are the highest bidder":'អ្នកគឺជាអ្នកដេញថ្លៃខ្ពស់បំផុត',
  "you can add or change information in the settings":'អ្នកអាចបន្ថែមឬផ្លាស់ប្តូរព័ត៌មាននៅក្នុងការកំណត់។',
  "you don\'t need login to do for good in this platform":'អ្នកមិនត្រូវការចូលដើម្បីធ្វើបានល្អនៅក្នុងវេទិកានេះទេ',
  "you have 0 new message":'អ្នកមានសារថ្មី 0',
  "you have completed the registration steps please confirm the information again to finish":'អ្នកបានបញ្ចប់ជំហានចុះឈ្មោះសូមបញ្ជាក់ព័ត៌មានម្តងទៀតដើម្បីបញ្ចប់',
  "You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.":'អ្នកក៏គួរតែអាននិងយល់ពីការកៀងគរមុនពេលចូលរួមវិភាគទាន។ យើងត្រូវការអ្នកប៉ុន្តែអ្នកទទួលខុសត្រូវចំពោះសកម្មភាពរបស់អ្នក។ យើងព្យាយាមបង្កើតយន្តការមួយដែលរឹតត្បិតបញ្ហាអវិជ្ជមានប៉ុន្តែក៏បដិសេធការទទួលខុសត្រូវទាក់ទងនឹងសកម្មភាពរៃអង្គាសប្រាក់នេះផងដែរ។ យើងគ្រាន់តែជាវេទិកាគាំទ្រប៉ុណ្ណោះ។',
  "you should use a photo to help people recognize your store faster":'អ្នកគួរតែប្រើរូបថតដើម្បីជួយឱ្យមនុស្សស្គាល់ហាងរបស់អ្នកលឿនជាងមុន',
  "Create quickly in steps if you don\'t have your own activities and groups yet":'បង្កើតជាជំហាន ៗ ប្រសិនបើអ្នកមិនទាន់មានសកម្មភាពនិងក្រុមផ្ទាល់ខ្លួនរបស់អ្នកនៅឡើយទេ',
  "you voted it":'អ្នកបានបោះឆ្នោតវា',
  "you want to share or to implement your good initiative to the community":'អ្នកចង់ចែករំលែកឬអនុវត្តគំនិតផ្តួចផ្តើមល្អរបស់អ្នកទៅកាន់សហគមន៍',
  "You\'re welcome to be":'អ្នកត្រូវបានស្វាគមន៍ឱ្យមាន',
  "your activities":'សកម្មភាពរបស់អ្នក',
  "your auction name":'ឈ្មោះដេញថ្លៃរបស់អ្នក',
  "your bid":'ការដេញថ្លៃរបស់អ្នក',
  "your cart currently has <%= number %> pending payment, you want to continue shopping now.":'រទេះរបស់អ្នកបច្ចុប្បន្នមាន <%% = លេខ%> ការទូទាត់ប្រាក់ដែលមិនទាន់សម្រេចអ្នកចង់បន្តទិញទំនិញឥឡូវនេះ។',
  "your cart currently has <%= number %> products, you want to continue shopping now.":'រទេះរបស់អ្នកបច្ចុប្បន្នមាន <%% = លេខ%> ផលិតផលអ្នកចង់បន្តទិញទំនិញឥឡូវនេះ។',
  "your categories":'ប្រភេទរបស់អ្នក',
  "your comment":'យោបល់របស់អ្នក',
  "your comment about store":'យោបល់របស់អ្នកអំពីហាង',
  "your friend":'មិត្ត​របស់​អ្នក',
  "your group":'ក្រុមរបស់អ្នក',
  "your order status will be update in":'ស្ថានភាពបញ្ជាទិញរបស់អ្នកនឹងត្រូវធ្វើបច្ចុប្បន្នភាព',
  "your order will be transfer as soon as possible":'ការបញ្ជាទិញរបស់អ្នកនឹងត្រូវផ្ទេរឱ្យបានឆាប់តាមដែលអាចធ្វើទៅបាន',
  "your orders":'ការបញ្ជាទិញរបស់អ្នក',
  "your pending amount":'តម្លៃចុះឈ្មោះរបស់អ្នក',
  "your profile":'ប្រវត្តិរូបរបស់អ្នក',
  "your profile has been successfully submitted to the employer!":'ប្រវត្តិរូបរបស់អ្នកត្រូវបានបញ្ជូនទៅនិយោជកដោយជោគជ័យហើយ!',
  "your store name":'ឈ្មោះហាងរបស់អ្នក',
  "zero hunger":'បាត់ភាពអត់ឃ្លាន',
  "zones":'តំបន់',
  "zones_placeholder":'តំបន់ដែលគាំទ្រជាមួយអង្ការ',
  "give":'អោយ',
  "receive":'ទទួលយក',
  "notes on the order":'កំណត់សំគាល់លើការបញ្ជាទិញ',
  "closed status":'ស្ថានភាពបិទ',
  "close status":'ស្ថានភាពបិទ',
  "blocked status":'ស្ថានភាពដែលត្រូវបានរារាំង',
  "block status":'ស្ថានភាពប្លុក',
  "This content has completed the process, closing the status marks that you are no longer working on this content.":'ខ្លឹមសារនេះបានបញ្ចប់ដំណើរការនេះដោយបិទសញ្ញាសម្គាល់ស្ថានភាពដែលអ្នកលែងធ្វើការលើខ្លឹមសារនេះ។',
  "This content is required to blockade, Sessions on this content will be blocked from now.":'មាតិកានេះតម្រូវឱ្យមានការបិទបាំងវគ្គលើមាតិកានេះនឹងត្រូវបានរារាំងចាប់ពីពេលនេះ។',
  "closed statustips":'ខ្លឹមសារនេះបានបញ្ចប់ដំណើរការនេះដោយបិទសញ្ញាសម្គាល់ស្ថានភាពដែលអ្នកលែងធ្វើការលើខ្លឹមសារនេះ។',
  "Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you":'សូមអភ័យទោសដល់អតិថិជន។ បច្ចុប្បន្ននេះយើងមានតែផលិតផល {n} ប៉ុណ្ណោះដែលបម្រើអ្នក។ សូមមេត្តាកុំកំណត់បរិមាណ។ សូមអរគុណ',
  "let\'s start for free":'សូមចាប់ផ្តើមដោយឥតគិតថ្លៃ',
  "delivery method":'វិធី​សា​ស្រ្ត​ដឹកជញ្ជូន',
  "Please choose a delivery method":'សូមជ្រើសរើសវិធីសាស្ត្រដឹកជញ្ជូន',
  "cancel":'លុបចោល',
  "Click here to create first contact":'ចុចត្រង់នេះដើម្បីបង្កើតទំនាក់ទំនងដំបូង',
  "Enter the email address associated with your account, and we will email you a link to reset your password.":'បញ្ចូលអាសយដ្ឋានអ៊ីមែលដែលភ្ជាប់ជាមួយគណនីរបស់អ្នកហើយយើងនឹងផ្ញើអ៊ីមែលទៅអ្នកនូវតំណភ្ជាប់មកអ្នកដើម្បីកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញ។',
  "write":'សរសេរ',
  "copy link":'ចម្លងតំណ',
  "share link via messenger":'ចែករំលែកតំណតាមរយៈកម្មវិធីផ្ញើសារ',
  "share on wishare":'ចែករំលែកតាមវីស៊ែរ',
  "social network":'ប​ណ្តា​ញ​សង្គម',
  "Don\'t have a volunteer for this activity or event yet?":'អ្នកមិនទាន់មានអ្នកស្ម័គ្រចិត្តសម្រាប់សកម្មភាពឬព្រឹត្តិការណ៍នេះនៅឡើយទេ?',
  "Tap the \"{1}\" button to complete and receive the Donation Code":'ប៉ះប៊ូតុង "{1}" ដើម្បីបញ្ចប់និងទទួលបានលេខកូដអំណោយ',
  "share link via QR Code":'ចែករំលែកតំណតាមរយៈ QR កូដ',
  "User use phone cameras to quickly access this link":'អ្នកប្រើប្រើកាមេរ៉ាទូរស័ព្ទដើម្បីចូលមើលតំណភ្ជាប់នេះយ៉ាងរហ័ស',
  "Below is an incomplete or unconfirmed registration by a fundraising campaign manager.":'ខាងក្រោមនេះគឺជាការចុះឈ្មោះមិនពេញលេញឬមិនបានបញ្ជាក់ដោយកម្មវិធីធ្វើយុទ្ធនាការឃោសនាបោះឆ្នោត។',
  "Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.":'ខាងក្រោមនេះគឺជាអំណោយរបស់អ្នក។ ប្រសិនបើមានអ្វីខុសសូមទាក់ទងរដ្ឋបាលយុទ្ធនាការ។ យើងសូមអរគុណចំពោះការបរិច្ចាគរបស់អ្នក។',
  "delete register of donation":'លុបការចុះឈ្មោះការបរិច្ចាគ',
  "are you sure to remove this donation?":'តើអ្នកប្រាកដជាយកអំណោយនេះចេញ?',
  "approve donation":'អនុម័តការបរិច្ចាគ',
  "contact phone":'ទូរស័ព្ទទំនាក់ទំនង',
  "less":'អន់',
  "your donation":'ការបរិច្ចាគរបស់អ្នក',
  "This view screen is for the admin of the campaign":'អេក្រង់មើលនេះគឺសម្រាប់អ្នកគ្រប់គ្រងយុទ្ធនាការ',
  "On behalf of the fundraising campaign, we would like to sincerely thank our sponsors":'ក្នុងនាមយុទ្ធនាការរៃអង្គាសប្រាក់យើងសូមថ្លែងអំណរគុណយ៉ាងជ្រាលជ្រៅចំពោះអ្នកឧបត្ថម្ភរបស់យើង',
  "Select and tap on the package to which you can donate":'ជ្រើសរើសហើយចុចលើកញ្ចប់ដែលអ្នកអាចបរិច្ចាគ',
  "Enter another value you want to donate":'បញ្ចូលតម្លៃផ្សេងទៀតដែលអ្នកចង់បរិច្ចាគ',
  "search with":'ស្វែងរកជាមួយ',
  "for admins":'សម្រាប់អ្នកគ្រប់គ្រង',
  "your name":'ឈ្មោះ​របស់​អ្នក',
  "contact email":'អ៊ីមែលទំនាក់ទំនង',
  "toward":'ឆ្ពោហតោនិយ័ត',
  "giving code":'ការផ្តល់លេខកូដ',
  "delete giving":'លុបការផ្តល់ឱ្យ',
  "This giving will be remove?":'ការផ្តល់នេះនឹងត្រូវដកចេញ?',
  "beneficiary account no":'លេខគណនីអ្នកទទួលផល',
  "beneficiary account name":'ឈ្មោះគណនីអ្នកទទួលផល',
  "transaction memo":'អនុស្សរណៈច្បាប់',
  "beneficiary bank name":'ឈ្មោះធនាគារអ្នកទទួលផល',
  "beneficiary info":'ព័ត៌មានអ្នកទទួលផល',
  "This item will be deleted and cannot be recovered":'ផលិតផលនេះនឹងត្រូវបានលុបចោលហើយមិនអាចរកឃើញវិញបានទេ',
  "Value on conversion for items: (quantity) / (Unit)":'តម្លៃស្តីពីការប្រែចិត្តជឿសម្រាប់ផលិតផល (បរិមាណ) / (ឯកតា)',
  "donate goods equivalent to":'បរិច្ចាគទំនិញស្មើនឹង',
  "donation type":'ប្រភេទបរិច្ចាគ',
  "item":'ទំនិញ',
  "registered to goods equivalent to":'បានចុះឈ្មោះដើម្បីបរិច្ចាគទំនិញស្មើនឹង',
  "cash":'ប្រាក់',
  "goods":'ទំនិញ',
  "cash donations":'ការបរិច្ចាគសាច់ប្រាក់',
  "goods donations":'ការបរិច្ចាគទំនិញ',
  "It is more blessed to give than to receive.":'ការទទួលបានមានពរជ័យច្រើនជាងក្នុងការផ្តល់ឱ្យជាងការទទួល។',
  "(Acts 20:35).":'(កិច្ចការ 20:35) ។',
  "Holy Bible":'បានផ្តល់ឱ្យ',
  "A dream you dream alone is only a dream. A dream you dream together is reality.":'ប្រភេទការផ្តល់',
  "gave away":'បានផ្តល់ឱ្យ',
  "giving type":'ប្រភេទការផ្តល់',
  "giving value":'ផ្តល់តម្លៃ',
  "with an goods equivalent of":'ជាមួយនឹងទំនិញសមមូល',
  "with a cash amount of":'ជាមួយនឹងចំនួនសាច់ប្រាក់',
  "Are you sure you agreed to accept this person for the recruitment?":'តើអ្នកប្រាកដទេថាអ្នកបានយល់ព្រមទទួលយកបុគ្គលនេះសម្រាប់ការជ្រើសរើស?',
  "Are you sure you will reject this application for recruitment?":'តើអ្នកប្រាកដថាអ្នកនឹងបដិសេធពាក្យសុំនេះសម្រាប់ការជ្រើសរើសទេ?',
  "Time to be able to do volunteer work":'ពេលវេលាដើម្បីអាចធ្វើការងារស្ម័គ្រចិត្ត',
  "add time to be able to volunteer":'បន្ថែមពេលវេលាការស្ម័គ្រចិត្ត',
  "Kind of work what you like":'ប្រភេទនៃការងារដែលអ្នកចូលចិត្ត',
  "The workplace (ward/district/province/country) that is right for you":'កន្លែងធ្វើការ (ឃុំ/ ស្រុក / ខេត្ត / ប្រទេស) ដែលត្រឹមត្រូវសម្រាប់អ្នក',
  "you want":'អ្នក​ចង់',
  "anything":'វត្ថុនាៗ',
  "anywhere":'គ្រប់កន្លែង',
  "learn about tools":'ស្វែងយល់អំពីឧបករណ៍',
  "create an activity for free":'បង្កើតសកម្មភាពដោយឥតគិតថ្លៃ',
  "how does it work":'តើ​វា​ដំណើរការ​យ៉ាង​ដូចម្តេច',
  "petition & connect":'ញ្ញត្តិនិងការភ្ជាប់',
  "petition":'ញ្ញាតិ',
  "recruitment":'ការជ្រើសរើស',
  "sales":'ការលក់',
  "inputs":'ការចូល',
  "impacts":'ឥទ្ធិពល',
  "outputs/outcome":'ការចេញ/លទ្ធផល',
  "fundraising, donation activity":'ការរៃអង្គាសប្រាក់សកម្មភាពបរិច្ចាគ',
  "recruitment, search for volunteer jobs":'ការជ្រើសរើសបុគ្គលិកស្វែងរកការងារស្ម័គ្រចិត្ត',
  "sales, create financial resources for social activities":'ការលក់បង្កើតធនធានហិរញ្ញវត្ថុសម្រាប់សកម្មភាពសង្គម',
  "auction, create financial resources for social activities":'ការដេញថ្លៃបង្កើតធនធានហិរញ្ញវត្ថុសម្រាប់សកម្មភាពសង្គម',
  "petition, connect social activities":'ញត្តិ, ភ្ជាប់សកម្មភាពសង្គម',
  "community":'សមាគមន៍',
  "0$ - free services":'0 $ - សេវាកម្មឥតគិតថ្លៃ',
  "serve the community":'បម្រើសហគមន៍',
  "donate - sponsor":'បរិច្ចាគ - អ្នកឧបត្ថម្ភ',
  "keep us running":'ដំណើរការគ្រប់គ្រង',
  "tools toward impact":'ឧបករណ៍ឆ្ពោះទៅរកការឥទ្ធិពល',
  "Support {1} campaign management tools to create impact according to the model of “{2}”.":'គាំទ្រឧបករណ៍គ្រប់គ្រងយុទ្ធនាការក្នុងយុទ្ធនាការដើម្បីបង្កើតផលប៉ះពាល់យោងទៅតាមគំរូនៃ "{2}" ។',
  "organization - social activities":'អង្គការ - សកម្មភាពសង្គម',
  "theory of change":'ទ្រឹស្តីនៃការផ្លាស់ប្តូរ',
  "Strategic planning":'ការគំរោងយុទ្ធសាស្ត្រ',
  "effects":'ផលប៉ះពាល់',
  "17 SDG":'ចំនួន១៧ គោលដៅអភិវឌ្ឍន៍ប្រកបដោយចីរភាព',
  "create your account":'បង្កើតគណនីរបស់អ្នក',
  "launch your activity":'បើកដំណើរការសកម្មភាពរបស់អ្នក',
  "A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.":'គណនីឥតគិតថ្លៃដើម្បីចាប់ផ្តើមដំណើររបស់អ្នកហើយតាមដានផលប៉ះពាល់របស់អ្នក។ អ្នកអាចបង្កើតការបង្កើតរបស់អ្នកយ៉ាងងាយស្រួលដើម្បីរៀបចំសកម្មភាពនិងយុទ្ធនាការរបស់អ្នក។',
  "launch your campaign":'បើកដំណើរការយុទ្ធនាការរបស់អ្នក',
  "Manage your recruitment, crowdfunding, auction, selling, events and so on.":'គ្រប់គ្រងការជ្រើសរើសការជ្រើសរើសការរៃអង្គាសការដេញថ្លៃការលក់ព្រឹត្តិការណ៍និងអ្វីៗផ្សេងទៀត។',
  "An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.":'បុគ្គលឬការបង្កើតអង្ការមួយអាចបង្កើតសកម្មភាពព្រឹត្តិការណ៍ការដេញថ្លៃដេញថ្លៃបានយ៉ាងងាយស្រួលយ៉ាងងាយស្រួល។ សកម្មភាពនីមួយៗគឺជាគម្រោងនីមួយៗបេសកកម្ម',
  "commitment":'ការប្តេជ្ញា',
  "Our solution is constantly improving, but some things will never change":'ដំណោះស្រាយរបស់យើងគឺមានភាពប្រសើរឡើងឥតឈប់ឈរប៉ុន្តែរឿងខ្លះនឹងមិនផ្លាស់ប្តូរទេ',
  "Totally free, with no fees or commission":'ឥតគិតថ្លៃទាំងស្រុងដោយគ្មានថ្លៃឈ្នួលឬកម្រៃជើងសារ',
  "0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.":'សូន្យដុល្លារ។ នេះគឺជាថ្លៃដើមក្នុងការប្រើវីស៊ែរ ឥឡូវនេះ និងជារៀងរហូត។ យើងចូលរួមគំរូរនេះ ដើម្បីបង្កើតផលប៉ះពាល់យ៉ាងខ្លាំងសម្រាប់សហគមន៍។',
  "Continuous Improvement":'ការកែលម្អជាបន្តបន្ទាប់',
  "Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.":'ធ្វើឱ្យប្រសើរឡើងនូវបទពិសោធន៍អ្នកប្រើប្រាស់ជានិច្ច ដោយផ្អែកលើការស្តាប់ និងចំណេះដឹងដ៏ជ្រាលជ្រៅរបស់យើងចំពោះអង្គការ NPO, NGO, CSR និងសកម្មភាពសហគមន៍។ យើងជួយឌីជីថលនីយកម្ម និងតាមដានផលប៉ះពាល់របស់អ្នកដែលធ្វើឱ្យវាងាយស្រួលប្រើ និងងាយស្រួល។',
  "Free business model":'គំរូអាជីវកម្មឥតគិតថ្លៃ',
  "Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.":'មានបំណងផ្តល់សេវាកម្មឥតគិតថ្លៃដល់សហគមន៍គំរូប្រាក់ចំណូលរបស់យើងផ្អែកលើការឧបត្ថម្ភការបរិច្ចាគដោយស្ម័គ្រចិត្តពីសហគមន៍និងការផ្សាយពាណិជ្ជកម្ម។ វាជួយឱ្យយើងរក្សានិងអភិវឌ្ឍវេទិកាជាបន្តបន្ទាប់។',
  "Campaign on Wishare":'យុទ្ធនាការលើវីស៊ែរ',
  "Wishare and Partners":'វីស៊ែរនិងដៃគូ',
  "SDG & Category":'ចំនួន១៧ គោលដៅអភិវឌ្ឍន៍ប្រកបដោយចីរភាព',
  "totally free":'ឥតគិតថ្លៃទាំងស្រុង',
  "now and forever.":'ឥឡូវ​និង​ជា​រៀង​រហូត។',
  "toward impacts":'ឆ្ពោះទៅរកការឥទ្ធិពល',
  "our partners":'ដៃគូរបស់យើង',
  "Fundraising and charity management platform.":'វេទទិការគ្រប់គ្រង់ការរ៉ៃអង្គាសថវិកា និងសប្បុសធម៌',
  "Free and transparent.":'ឥតគិតថ្លៃនិងតម្លាភាព។',
  "mark":'កត់សម្គាល់',
  "givings":'ផ្តល់អោយ',
  "cash givings":'ការផ្តល់សាច់ប្រាក់',
  "goods givings":'ការផ្តល់ទំនិញ',
  "impact categories":'ប្រភេទឥទ្ធិពល',
  "Values are summed up from the organization and its activities.":'តម្លៃត្រូវបានសង្ខេបពីអង្គការនិងសកម្មភាពរបស់វា។',
  "co-hosts":'សហម្ចាស់អង្ការ',
  "Use for: blood donation, ... form of donation calculated on each donation":'ប្រើសម្រាប់: ការបរិច្ចាគឈាម, ... សំណុំបែបបទនៃការបរិច្ចាគដែលបានគណនាលើការបរិច្ចាគនីមួយៗ',
  "Use for: donate money, donate goods, ... Contributions can be converted to monetary value":'ប្រើសម្រាប់: បរិច្ចាគប្រាក់បរិច្ចាគទំនិញ ... ការចូលរួមវិភាគទានអាចប្តូរទៅជាតម្លៃរូបិយវត្ថុ',
  "donation by count":'បរិច្ចាគដោយរាប់',
  "donation by value":'បរិច្ចាគដោយតម្លៃ',
  "blood unit":'អង្គភាពឈាម',
  "blood":'ឈាម',
  "You register to contribute":'អ្នកចុះឈ្មោះដើម្បីចូលរួមវិភាគទាន',
  "The organizers will contact you in case of needed. Please add some information":'អ្នករៀបចំកម្មវិធីនឹងទាក់ទងអ្នកក្នុងករណីចាំបាច់។ សូមបន្ថែមព័ត៌មានមួយចំនួន',
  "contact information":'ព័ត៌មានទំនាក់ទំនង',
  "impacts report":'របាយការណ៍ផលប៉ះពាល់',
  "my givings":'ការផ្តល់អោយរបស់ខ្ញុំ',
  "participate in volunteering":'ចូលរួមក្នុងការស្ម័គ្រចិត្ត',
  "pending donations":'ការបរិច្ចាគដែលមិនទាន់សម្រេច',
  "create crowdfunding":'បង្កើតការប្រមូលផ្តុំការប្រមូលផ្តុំ',
  "what donation?":'តើអំណោយអ្វីខ្លះ?',
  "Choose the right donation type for your campaign":'ជ្រើសរើសប្រភេទអំណោយត្រឹមត្រូវសម្រាប់យុទ្ធនាការរបស់អ្នក',
  "Money/Goods... can allow donations. (If target is unlimited, please enter value \"0\")":'លុយ / ទំនិញ ... អាចអនុញ្ញាតិឱ្យអំណោយបាន។ (ប្រសិនបើគោលដៅមិនមានកំណត់សូមបញ្ចូលតម្លៃ "0")',
  "..., which and how much?":'... , ដែលនិងប៉ុន្មាន?',
  "Choose a campaign creator/initiator for the campaign":'ជ្រើសរើសអ្នកបង្កើតយុទ្ធនាការ / អ្នកផ្តួចផ្តើមសម្រាប់យុទ្ធនាការ',
  "..., and whose?":'... ហើយអ្នកណា?',
  "Choose the impacts that the campaign towards":'ជ្រើសរើសផលប៉ះពាល់ដែលយុទ្ធនាការឆ្ពោះទៅរក',
  "Write a more descriptive description of your campaign information, which helps the community better understand your campaign.":'សរសេរការពិពណ៌នាពិពណ៌នាបន្ថែមនៃព័ត៌មានយុទ្ធនាការរបស់អ្នកដែលជួយឱ្យសហគមន៍យល់កាន់តែច្បាស់ពីយុទ្ធនាការរបស់អ្នក។',
  "..., donation information and when?":'ព័ត៌មានបរិច្ចាគនិងពេលវេលា?',
  "Donation information, Estimated start and end time of the campaign":'ព័ត៌មានអំណោយអំណោយការប៉ាន់ស្មានការចាប់ផ្តើមនិងពេលវេលាបញ្ចប់នៃយុទ្ធនាការ',
  "Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section":'សូមប៉ះប៊ូតុងបង្កើតដើម្បីបញ្ចប់ការចាប់ផ្តើមព័ត៌មាន។ អ្នកអាចកែតម្រូវវាថែមទៀតនៅពេលក្រោយក្នុងផ្នែកនៃការកំណត់រចនាសម្ព័ន្ធ',
  "Leave the value 0 if you do not want a specific goal":'ទុកតម្លៃ 0 ប្រសិនបើអ្នកមិនចង់បានគោលដៅជាក់លាក់',
  "finance":'ផ្ដល់ហិរញ្ញវត្ថុ',
  "complete":'បានបំពេញ',
  "another donation options":'ជម្រើសបរិច្ចាគមួយទៀត',
  "pending list":'បញ្ជីមិនទាន់សម្រេច',
  "type_community":'សហគមន៍',
  "edit crowdfunding":'កែសម្រួលការប្រមូលរៃអង្គាស',
  "est. value":'ការប៉ានស្មានតម្លៃ',
  "volume":'ចំនួន',
  "Rice":'អង្ក',
  "Food":'ចំនីអាហារ',
  "Pepper":'ម្រេច',
  "Fish sauce":'ទឹកត្រី',
  "Bottle":'ដប',
  "Box":'ប្រអប់',
  "Instant Noodles":'មី​កញ្ចប់',
  "to beneficiary":'ដល់អ្នកទទួលផល',
  "Successfully transferred to {1} the giving":'ផ្ទេរដោយជោគជ័យទៅ {1} ការឱ្យ',
  "activities management":'ការគ្រប់គ្រងសកម្មភាព',
  "ready for volunteering":'ត្រៀមខ្លួនសម្រាប់ការងារស្ម័គ្រចិត្ត',
  "your curriculum vitae":'ជីវប្រវត្តិរបស់អ្នក',
  "link to your profile on another site":'ភ្ជាប់ទៅប្រវត្តិរបស់អ្នកនៅលើគេហទំព័រមួយផ្សេងទៀត',
  "Money/Goods... can be offer. (If target is unlimited, please enter value \"0\")":'លុយ / ទំនិញ ... អាចផ្តល់ជូន។ (ប្រសិនបើគោលដៅមិនមានកំណត់សូមបញ្ចូលតម្លៃ "0")',
  "targets":'គោលដៅ',
  "another giving options":'ជម្រើសផ្តល់ឱ្យមួយផ្សេងទៀត',
  "for activities that make an impact on the community":'សម្រាប់សកម្មភាពដែលធ្វើឱ្យប៉ះពាល់ដល់សហគមន៍',
  "search crowdfunding":'ការស្វែងរកការប្រមូលរៃអង្គាស',
  "search giving":'ការផ្តល់ឱ្យ',
  "what offer?":'អ្វីដែលបានផ្តល់ឱ្យ?',
  "Choose the right offer type for your campaign":'ជ្រើសរើសប្រភេទផ្តល់ជូនត្រឹមត្រូវសម្រាប់យុទ្ធនាការរបស់អ្នក',
  "..., offer information and when?":'... , ផ្តល់ព័ត៌មាននិងពេលណា?',
  "Offer information, Estimated start and end time of the campaign":'ផ្តល់ព័ត៌មានការប៉ាន់ស្មានការចាប់ផ្តើមនិងពេលវេលាបញ្ចប់នៃយុទ្ធនាការ',
  "..., and impact categories?":'... និងប្រភេទផលប៉ះពាល់?',
  "profile link":'តំណភ្ជាប់ប្រវត្តិរូប',
  "outputs":'ការចេញ',
  "outcome":'លត្ធផល',
  "volunteer job":'ការងារស្ម័គ្រចិត្ត',
  "visible":'ដែល​អាច​មើលឃើញ',
  "invisible":'ដែលមើលមិនឃើញ',
  "ready":'រៀបចំជាស្រេច',
  "not ready":'មិនទាន់​រួចរាល់',
  "link to the giving":'ភ្ជាប់ទៅនឹងការឱ្យ',
  "donation guide":'ការណែនាំបរិច្ចាគ',
  "tank":'ធុង',
  "link to the donation":'ភ្ជាប់ទៅនឹងការបរិច្ចាគ',
  "SGD":'ប្រាក់ដុល្លារសិង្ហបុរី',
  "Vegetables":'បន្លែ',
  "Oxygen tank":'ធុងអុកស៊ីសែន',
  "Diapers":'ក្រណាត់កន្ទប',
  "Bike":'កង់',
  "Milk":'ទឹកដោះគោ',
  "Mask":'របាំងមុខ',
  "USD":'ដុល្លារ​អាមេរិក',
  "EUR":'អឺ​រ៉ូ',
  "crowdfunding campaign":'យុទ្ធនាការរៃអង្គាសថវិកា',
  "giving campaign":'យុទ្ធនាការផ្តល់',
  "financial balance":'សមតុល្យហិរញ្ញវត្ថុ',
  "recruitment campaign":'យុទ្ធនាការជ្រើសរើសបុគ្គលិក',
  "for the output of impact activities in the community":'សម្រាប់លទ្ធផលនៃសកម្មភាពផលប៉ះពាល់នៅក្នុងសហគមន៍',
  "make a giving":'ការផ្តល់ឱ្យ',
  "job - volunteer":'ការងារ - អ្នកស្ម័គ្រចិត្ត',
  "features":'លក្ខណៈពិសេស',
  "Recruit unlimited volunteers":'ជ្រើសរើសអ្នកស្ម័គ្រចិត្តគ្មានដែនកំណត់',
  "You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.":'អ្នកអាចប្រកាសឱកាសជាច្រើនតាមដែលអ្នកចូលចិត្តហើយមិនមានកំណត់ថាតើអ្នកជ្រើសរើសអ្នកស្ម័គ្រចិត្តប៉ុន្មានដែលអ្នកជ្រើសរើសទេ។',
  "Manage your volunteers":'គ្រប់គ្រងអ្នកស្ម័គ្រចិត្តរបស់អ្នក',
  "Once your volunteers start coming in, you\'ll be able to organize, filter and keep track of them.":'នៅពេលដែលអ្នកស្ម័គ្រចិត្តរបស់អ្នកចាប់ផ្តើមចូលមកអ្នកនឹងអាចរៀបចំតម្រងនិងតាមដានពួកគេ។',
  "Upload and Download your list":'ផ្ទុកឡើងនិងទាញយកបញ្ជីរបស់អ្នក',
  "Start out by uploading your own volunteer list. Don\'t worry, you can download your entire list of volunteers anytime.":'ចាប់ផ្តើមដោយបញ្ចូលបញ្ជីអ្នកស្ម័គ្រចិត្តផ្ទាល់ខ្លួនរបស់អ្នក។ កុំបារម្ភអ្នកអាចទាញយកបញ្ជីអ្នកស្ម័គ្រចិត្តទាំងមូលរបស់អ្នកគ្រប់ពេល។',
  "Volunteer Analytics":'ការវិភាគស្ម័គ្រចិត្ត',
  "Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.":'ផ្ទាំងគ្រប់គ្រងរបស់អ្នកគឺជាមជ្ឈមណ្ឌលស្ម័គ្រចិត្តរបស់អ្នក។ តើមានមនុស្សប៉ុន្មាននាក់ដែលអ្នកស្ម័គ្រចិត្តយ៉ាងសកម្ម? មើលទៅមិនមានទៀតទេ។',
  "Show unlimited giving":'បង្ហាញការផ្តល់គ្មានដែនកំណត់',
  "You can post as many giving as you like, and there is no limit on how many giving you give.":'អ្នកអាចប្រកាសការផ្តល់ឱ្យតាមដែលអ្នកចូលចិត្តហើយមិនមានដែនកំណត់លើចំនួនប៉ុន្មានដែលអ្នកផ្តល់ឱ្យទេ។',
  "Manage your Giving (Money and Goods)":'គ្រប់គ្រងការផ្តល់ឱ្យរបស់អ្នក (លុយនិងទំនិញ)',
  "Once your giving start, you\'ll be able to organize, filter and keep track of them.":'នៅពេលដែលការផ្តល់ការផ្តល់របស់អ្នកអ្នកនឹងអាចរៀបចំតម្រងនិងតាមដានពួកគេ។',
  "Start out by uploading your own giving list. Don\'t worry, you can download your entire list of giving anytime.":'ចាប់ផ្តើមដោយបញ្ចូលបញ្ជីនៃការផ្តល់ដោយខ្លួនឯងរបស់អ្នក។ កុំបារម្ភអ្នកអាចទាញយកបញ្ជីរបស់អ្នកទាំងមូលនៃការផ្តល់គ្រប់ពេល។',
  "Giving Analytics":'ផ្តល់ការវិភាគ',
  "Your dashboard is your Giving. Look no further.":'ផ្ទាំងគ្រប់គ្រងរបស់អ្នកគឺជាការឱ្យរបស់អ្នក។ មើលទៅមិនមានទៀតទេ។',
  "donation - crowdfunding":'បរិច្ចាគ -គៀរគរ',
  "Register unlimited donation":'ចុះឈ្មោះបរិច្ចាគគ្មានដែនកំណត់',
  "You can post as many donation as you like, and there is no limit on how many donation you receive.":'អ្នកអាចប្រកាសបរិច្ចាគជាច្រើនតាមដែលអ្នកចូលចិត្តហើយមិនមានកំណត់ថាតើអ្នកទទួលបានប៉ុន្មានទេ។',
  "Manage your Donations (Money and Goods)":'គ្រប់គ្រងការបរិច្ចាគរបស់អ្នក (លុយនិងទំនិញ)',
  "Once your donation start coming in, you\'ll be able to organize, filter and keep track of them.":'នៅពេលដែលការបរិច្ចាគរបស់អ្នកចាប់ផ្តើមចូលមកអ្នកនឹងអាចរៀបចំត្រងនិងតាមដានពួកគេ។',
  "Start out by uploading your own donation list. Don\'t worry, you can download your entire list of donor and donation anytime.":'ចាប់ផ្តើមដោយផ្ទុកបញ្ជីអំណោយផ្ទាល់ខ្លួនរបស់អ្នក។ កុំបារម្ភអ្នកអាចទាញយកបញ្ជីរបស់អ្នកបរិច្ចាគនិងបរិច្ចាគគ្រប់ពេលវេលា។',
  "Donation Analytics":'វិភាគការបរិច្ចាគ',
  "Your dashboard is your donation. How many people are actively donors? Look no further.":'ផ្ទាំងរបស់អ្នកគឺជាអំណោយរបស់អ្នក។ តើមានមនុស្សប៉ុន្មាននាក់ដែលជាម្ចាស់ជំនួយសកម្ម? មើលទៅមិនមានមើលពីណាឆ្ងាយ។',
  "stop subscribe":'បញ្ឈប់ការចុះឈ្មោះ',
  "subscribe":'ចុះឈ្មោះ',
  "giving value must be large than 0":'ការផ្តល់តម្លៃត្រូវតែមានទំហំធំជាង 0',
  "The object of the giving campaign":'កម្មវត្ថុនៃយុទ្ធនាការផ្តល់ឱ្យ',
  "the option has been removed successful":'ជម្រើសត្រូវបានដកចេញដោយជោគជ័យ',
  "the option has been edited successful":'ជម្រើសនេះត្រូវបានកែសម្រួលជោគជ័យ',
  "click here to add new giving package":'ចុចត្រង់នេះដើម្បីបន្ថែមកញ្ចប់ផ្តល់ឱ្យថ្មី',
  "Enter another value you want to giving":'បញ្ចូលតម្លៃផ្សេងទៀតដែលអ្នកចង់ផ្តល់ឱ្យ',
  "Tap to sign up for the package you want to receive":'ប៉ះដើម្បីចុះឈ្មោះសម្រាប់កញ្ចប់ដែលអ្នកចង់ទទួល',
  "Tap the \"{1}\" button to complete and receive the giving Code":'ប៉ះប៊ូតុង "{1}" ដើម្បីបញ្ចប់និងទទួលបានលេខកូដដែលផ្តល់ឱ្យ',
  "recipient":'អ្នកទទួលយក',
  "You are registered to get":'អ្នកត្រូវបានចុះឈ្មោះដើម្បីទទួលបាន',
  "registered to get":'ចុះឈ្មោះដើម្បីទទួលបាន',
  "Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page":'ការជាវរបស់អ្នកត្រូវបានកត់ត្រាទុកសូមធ្វើតាមការធ្វើបច្ចុប្បន្នភាពស្ថានភាពនៃការជាវនៅក្នុងបញ្ជីក្នុងទំព័រយុទ្ធនាការ',
  "your subscription":'ការជាវរបស់អ្នក',
  "delete register of giving":'លុបការចុះឈ្មោះ',
  "are you sure to remove this giving?":'តើអ្នកប្រាកដជាយកការផ្តល់នេះចេញទេ?',
  "remove this giving":'លុបការផ្តល់នេះចេញ',
  "approve giving":'អនុម័តការឱ្យ',
  "{1} registered to get":'{1} បានចុះឈ្មោះដើម្បីទទួលបាន',
  "short answer":'ចម្លើយ​ខ្លី',
  "long answer":'ចម្លើយវែង',
  "answer guide":'ការនែនាំឆ្លើយតប',
  "mandatory question":'សំណួរចាំបាច់',
  "answer type":'ប្រភេទចម្លើយ',
  "answer options":'ជម្រើសចម្លើយ',
  "add more question":'បន្ថែមសំណួរទៀត',
  "add option":'បន្ថែមជម្រើស',
  "optional question":'សំណួរស្រេចចិត្ត',
  "additional questions":'សំណួរបន្ថែម',
  "total target":'គោលដៅសរុប',
  "additional donor information":'ព័ត៌មានជំនួយបន្ថែម',
  "medal":'មេដាយ',
  "Send a thank you email to donor":'ផ្ញើអ៊ីមែលមកអរគុណដល់ម្ចាស់ជំនួយ',
  "send thank you email":'ផ្ញើអ៊ីមែលមកអរគុណ',
  "Deleting this content will not allow recovery, do you want to continue?":'ការលុបមាតិកានេះនឹងមិនអនុញ្ញាតឱ្យមានការសារឡើងវិញទេតើអ្នកចង់បន្តទេ?',
  "introduction":'ណែនាំ',
  "decent work and economic growth":'ការងារសមរម្យនិងកំណើនសេដ្ឋកិច្ច',
  "Please answer the following questions to complete registration":'សូមឆ្លើយសំណួរខាងក្រោមដើម្បីបញ្ចប់ការចុះឈ្មោះ',
  "registration questions":'សំណួរចុះឈ្មោះ',
  "change donation status to pending approval":'ផ្លាស់ប្តូរស្ថានភាពអំណោយដើម្បីរង់ចាំការអនុម័ត',
  "accept application":'ទទួលយកពាក្យសុំ',
  "reject application":'បដិសេធការដាក់ពាក្យសុំ',
  "create new":'បង្កើត​ថ្មី',
  "hour":'ម៉ោង',
  "timesheet":'តារាង​ពេលវេលា',
  "timesheets":'តារាងពេលវេលា',
  "task":'កិច្ចការ',
  "send email notifications and reminders to complete donation":'ផ្ញើការជូនដំណឹងតាមអ៊ីម៉ែលនិងការរំលឹកដើម្បីបញ្ចប់ការបរិច្ចាគ',
  "duration":'ថិរវេលា',
  "A donation confirmation email has been sent to you via the email address you entered.":'អ៊ីមែលបញ្ជាក់ការបរិច្ចាគត្រូវបានផ្ញើទៅអ្នកតាមរយៈអាសយដ្ឋានអ៊ីមែលដែលអ្នកបានបញ្ចូល។',
  "blood volume":'បរិមាណឈាម',
  "campaign overview":'ទិដ្ឋភាពទូទៅនៃយុទ្ធនាការ',
  "donation campaign":'យុទ្ធនាការបរិច្ចាគ',
  "targets of the campaign":'គោលដៅនៃយុទ្ធនាការនេះ',
  "impacts towards":'ផលប៉ះពាល់ឆ្ពោះទៅរក',
  "impact summary report":'របាយការណ៍សង្ខេបផលប៉ះពាល់',
  "graph of donation / giving":'ក្រាហ្វនៃការបរិច្ចាគ / ការឱ្យ',
  "goals to be achieved":'គោលដៅដែលត្រូវសំរេចបាន',
  "the remaining target of the campaign":'គោលដៅដែលនៅសល់នៃយុទ្ធនាការនេះ',
  "original goal":'គោលដៅដើម',
  "achieved":'បានសម្រេច',
  "campaign needs more":'យុទ្ធនាការត្រូវការបន្ថែម',
  "details over time":'ព័ត៌មានលម្អិតតាមពេលវេលា',
  "donation date":'កាលបរិច្ឆេទបរិច្ចាគ',
  "donor name":'ឈ្មោះអ្នកបរិច្ចាគ',
  "unit name":'ឈ្មោះឯកតា',
  "giving date":'កាលបរិច្ឆេទផ្តល់',
  "donation campaign report":'របាយការណ៍យុទ្ធនាការបរិច្ចាគ',
  "this report is only for campaign admins":'របាយការណ៍នេះសម្រាប់តែយុទ្ធនាការសម្រាប់យុទ្ធនាការប៉ុណ្ណោះ',
  "list of work done by volunteers":'បញ្ជីការងារធ្វើដោយអ្នកស្ម័គ្រចិត្ត',
  "Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.":'សម្គាល់ៈពេលខ្លះអ៊ីមែលនឹងត្រូវបានផ្ញើទៅថតសារឥតបានការ (សារឥតបានការ) ដូច្នេះសូមពិនិត្យមើលថតសារឥតបានការរបស់អ្នកផងដែរ។',
  "application file":'ឯកសារពាក្យសុំ',
  "profile management":'ការគ្រប់គ្រងទម្រង់',
  "email has been successfully sent!":'អ៊ីមែលត្រូវបានផ្ញើដោយជោគជ័យ!',
  "Are you sure?":'តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "closedgrouptips":'ក្រុមបិទជិត',
  "donate blood":'បរិច្ចាគឈាម',
  "register to donate blood":'ចុះឈ្មោះដើម្បីបរិច្ចាគឈាម',
  "organizations":'អង្គការ',
  "no limit":'គ្មាន​ដែន​កំណត់',
  "expiration":'ការបិសោធ',
  "my bookmarks":'ចំណាំរបស់ខ្ញុំ',
  "suggested channels":'បណ្តាញដែលបានស្នើ',
  "shortcuts":'ផ្លូវកាត់របស់អ្នក',
  "contribution":'ការចូលរួមចំណែករបស់អ្នក',
  "grateful":'ដេលដឹងកុន',
  "code":'ច្បាប់រដ្ឋប្បវេនី',
  "you have registered to donate":'អ្នកបានចុះឈ្មោះដើម្បីបរិច្ចាគ',
  "administrators panel":'បន្ទះអ្នកគ្រប់គ្រងរបស់អ្នកគ្រប់គ្រង',
  "beneficiary information":'ព័ត៌មានអ្នកទទួលផល',
  "account number":'លេខ​គណនី',
  "bank name":'ឈ្មោះ​របស់​ធនាគារ',
  "transfer contents":'មាតិកាផ្ទេរ',
  "Successfully transferred to":'ផ្ទេរដោយជោគជ័យទៅ',
  "a gift":'កាដូ',
  "you register to receive gifts":'អ្នកចុះឈ្មោះដើម្បីទទួលអំណោយ',
  "your register":'ការចុះឈ្មោះរបស់អ្នក',
  "requirement":'ការរបតេសតាម',
  "time location":'ពេលវេលានិងទីតាំង',
  "the target of the activity":'គោលដៅនៃសកម្មភាព',
  "validation error":'កំហុសសុពលភាព',
  "advanced settings":'ការកំណត់កម្រិតខ្ពស់',
  "go to setting":'ទៅការកំណត់',
  "content is filtered by":'មាតិកាត្រូវបានត្រងដោយ',
  "congratulation":'អត្ថធរង់',
  "You have completed the steps of creating activity":'អ្នកបានបញ្ចប់ជំហាននៃការបង្កើតសកម្មភាពបង្កើត',
  "Hope your activity will be successful and help many people":'សង្ឃឹមថាសកម្មភាពរបស់អ្នកនឹងទទួលបានជោគជ័យនិងជួយមនុស្សជាច្រើន',
  "attention":'ការរបយ័ត្ន',
  "note create description":'អ្នកត្រូវចូលទៅកាន់ផ្នែក "ការកំណត់" ដើម្បីបន្ថែមព័ត៌មានលម្អិតបន្ថែមទៀត។ តើអ្នកចង់ចូលទៅកាន់ផ្នែក "ការកំណត់" នៅទីនេះទេ?',
  "note target description":'គោលដៅសំខាន់គឺជាគោលដៅដែលយុទ្ធនាការទាំងមូលចាំបាច់ត្រូវតែសម្រេចបានហើយអ្នកអាចបំបែកវាទៅជាគោលដៅរងផ្សេងៗគ្នា។ ដើម្បីធ្វើដូចនេះទៅកាន់ផ្នែក "ការកំណត់" ដើម្បីបន្ថែមបន្ទាប់ពីអ្នកបានបញ្ចប់ជំហានដែលនៅសល់។',
  "open/close":'ថិរវេលា',
  "registrable":'ដេលអាចដែលបាន',
  "You have completed the steps to create a campaign":'អ្នកបានបញ្ចប់ជំហានដើម្បីបង្កើតយុទ្ធនាការមួយ',
  "Hope your campaign will be successful and help many people":'សង្ឃឹមថាយុទ្ធនាការរបស់អ្នកនឹងទទួលបានជោគជ័យនិងជួយមនុស្សជាច្រើន',
  "create recruitment":'បង្កើតការជ្រើសរើសបុគ្គលិក',
  "recruitment type":'ប្រភេទជ្រើសរើសពលករ',
  "job":'អាចីវកម្ផ',
  "work time":'ម៉ោង​ធ្វើការ',
  "full time":'ពេញ​មោ៉​ង',
  "part time":'ក្រៅ​ម៉ោង',
  "remote":'ឆាយដាច់រសយាល',
  "position_title":'ទីតាំង',
  "donation_event":'ការបរិច្ចាក',
  "giving_event":'ការអាេមរផ្គត់ផ្គង់',
  "recruitment_event":'ការជ្រើសរើស',
  "{n} categories":'{n} ប្រភេទ',
  "{n} languages":'ភាសា {n} ភាសា',
  "{n} provider(s)":'{n} អង្គភាព',
  "1 year":'ប្រចាំឆ្នាំ',
  "1D":'របចាមថៃ',
  "1M":'របចាមខេ',
  "1Y":'1 ឆ្នាំ',
  "2 years":'2 ឆ្នាំ',
  "2Y":'2 ឆ្នាំ',
  "3 months":'3 ខែ',
  "3M":'3 ខែ',
  "6 months":'6 ខែ',
  "6M":'6 ខែ',
  "about FeedIn":'អំពីចំណី',
  "about-us":'អំពី​ពួក​យើង',
  "accept invitation":'ទទួលយកការអញ្ជើញ',
  "accepted as member":'ទទួលយកជាសមាជិក',
  "access denied":'ដំណើរ​ការ​ត្រូវ​បាន​បដិសេធ',
  "account settings":'ការកំណត់​គណនី',
  "active subscription":'ការជាវសកម្ម',
  "add chart":'បន្ថែមតារាង',
  "add image":'បន្ថែមរូបភាព',
  "add link":'បន្ថែមតំណ',
  "add map":'បន្ថែមផែនទី',
  "add money to wallet":'បន្ថែមប្រាក់ទៅកាបូប',
  "Add new and manage members here {here}":'បន្ថែមថ្មីនិងគ្រប់គ្រងសមាជិកនៅទីនេះ {នៅទីនេះ}',
  "add new answer":'បន្ថែមចម្លើយថ្មី',
  "add new contact":'បន្ថែមទំនាក់ទំនងថ្មី',
  "add new location":'បន្ថែមទីតាំងថ្មី',
  "add payment to keep using {subscription} or change back to free":'បន្ថែមការទូទាត់ដើម្បីបន្តប្រើ {ការជាវ} ឬផ្លាស់ប្តូរត្រឡប់មកវិញដើម្បីឱ្យមានសេរីភាព',
  "add payment to keep using or change back to free":'បន្ថែមការទូទាត់ដើម្បីរក្សាការប្រើប្រាស់ឬផ្លាស់ប្តូរត្រឡប់មកវិញដោយឥតគិតថ្លៃ',
  "add photo":'បន្ថែមរូបថត',
  "add price":'បន្ថែមតម្លៃ',
  "Add product photos":'បន្ថែមរូបថតផលិតផល',
  "add subscriber":'បន្ថែមអតិថិជន',
  "Add Tax Number":'បន្ថែមលេខពន្ធ',
  "Add to Home Screen":'បន្ថែមទៅអេក្រង់ដើម',
  "add video":'បន្ថែមវីដេអូ',
  "adding content to the channel is limited due to its policies":'ការបន្ថែមមាតិកាទៅប៉ុស្តិ៍ត្រូវបានកំណត់ដោយសារគោលនយោបាយរបស់វា',
  "additional details":'ព័ត៌មានលំអិតបន្ថែម',
  "admin description":'តួនាទីអ្នកគ្រប់គ្រង',
  "advanced filter":'តម្រងកម្រិតខ្ពស់',
  "After select, click Save to finish":'បន្ទាប់ពីជ្រើសរើសសូមចុចរក្សាទុកដើម្បីបញ្ចប់',
  "after your trial ends":'បន្ទាប់ពីការជំនុំជម្រះក្តីរបស់អ្នកបញ្ចប់',
  "all categories":'ប្រភេទ​ទាំងអស់',
  "all dataset":'សំណុំទិន្នន័យទាំងអស់',
  "all products":'ផលិតផលទាំងអស់',
  "all vietnam":'ទាំងអស់នៅប្រទេសវៀតណាម',
  "allow add new answers":'អនុញ្ញាតឱ្យបន្ថែមចម្លើយថ្មី',
  "Allow channel content to be found through search or suggestion":'អនុញ្ញាតឱ្យរកឃើញមាតិកាឆានែលតាមរយៈការស្វែងរកឬសំណូមពរ',
  "Allow users to find your channel via search function.":'អនុញ្ញាតឱ្យអ្នកប្រើស្វែងរកប៉ុស្តិ៍របស់អ្នកតាមរយៈមុខងារស្វែងរក។',
  "allowed be found":'រកឃើញត្រូវបានអនុញ្ញាត',
  "Alternate Email":'អ៊ី​ម៉ែ​ល​ជំនួស',
  "Alternate Mobile No.":'លេខទូរស័ព្ទជំនួសទេ។',
  "Alternate Phone Number":'លេខទូរស័ព្ទជំនួស',
  "alternative phone":'ទូរស័ព្ទជំនួស',
  "Annual Turnover":'ចំណូលប្រចាំឆ្នាំ',
  "another":'មយយផេសងត្យេត',
  "answers":'ឆ្លើយ',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this":'អ្នកណាម្នាក់អាចមើលខ្លឹមសារនៃទិន្នន័យ។ មានតែសមាជិកទេដែលអាចប្រកាសអត្ថបទនិងយោបល់ដោយសេរីលើបញ្ហានេះ',
  "Are you sure delete this article?":'តើអ្នកពិតជាលុបអត្ថបទនេះហើយឬនៅ?',
  "Are you sure delete this chart?":'តើអ្នកពិតជាលុបតារាងនេះហើយឬនៅ?',
  "Are you sure delete this poll?":'តើអ្នកពិតជាលុបការស្ទង់មតិនេះមែនទេ?',
  "Are you sure delete this task?":'តើអ្នកពិតជាលុបភារកិច្ចនេះមែនទេ?',
  "Are you sure delete this template?":'តើអ្នកពិតជាលុបគំរូនេះឬ?',
  "article":'មារតា',
  "articles":'រេសម័យ',
  "ask for the best deal":'សម្រាប់កិច្ចព្រមព្រៀងល្អបំផុត',
  "automatically register with Google, Facebook... account":'ចុះឈ្មោះដោយស្វ័យប្រវត្តិជាមួយ Google, Facebook, Facebook ...',
  "available balance":'មានសមតុល្យដែលមាន',
  "Back Home":'ត្រលប់​មក​ផ្ទះ',
  "back to account":'ត្រលប់ទៅគណនីវិញ',
  "ban":'បមរាម',
  "banned":'ហាមឃាត់',
  "banned. can not post any":'ហាមឃាត់។ មិនអាចប្រកាសបានទេ',
  "bans":'ហាមឃាត់',
  "Basic":'នៃមុលដ្ឋាន',
  "basic info":'ព័ត៌មានមូលដ្ឋាន',
  "because you are looking for {keyword}":'ដោយសារតែអ្នកកំពុងស្វែងរក {ពាក្យគន្លឹះ}',
  "Become a provider?":'ក្លាយជាអង្គការមួយ ?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service":'ក្លាយជាអ្នកលក់ដែលបានផ្ទៀងផ្ទាត់ហើយទទួលបាន {Check1} ការចុះបញ្ជីខ្ពស់នៅលើ Feedin {Check2} សាកសួរអ្នកទិញបន្ថែមទៀត {COCK3} សេវាកម្មលេខដែលពេញចិត្ត',
  "bill estimate":'វិក័យប័ត្រប៉ាន់ស្មាន',
  "billing":'វិក័យប័រត',
  "billing address":'អាសយដ្ឋានវិក័យប័ត្រ',
  "billing contact":'ទំនាក់ទំនងវិក័យប័ត្រ',
  "billing details":'ព័ត៌មានលម្អិតអំពីការចេញវិក្កយបត្រ',
  "billing history":'ប្រវត្តិវិក្កយបត្រ',
  "billing information":'ព័ត៌មានវិក័យប័ត្រ',
  "billing overview":'ទិដ្ឋភាពវិក័យប័ត្រ',
  "billing period":'រយៈពេលវិក័យប័ត្រ',
  "birthday":'ថៃខយបកមនើត',
  "bookmark":'ចំណាស់',
  "bookmark all":'កំណត់ចំណាំទាំងអស់',
  "bookmarked":'ចំណាំ',
  "brief introduction to yourself":'ការណែនាំសង្ខេបអំពីខ្លួនអ្នក',
  "bulk price update":'ការធ្វើបច្ចុប្បន្នភាពតម្លៃភាគច្រើន',
  "bulletin":'ដីកា',
  "by categories":'តាមប្រភេទ',
  "by clicking Submit, you agree to the policy of FeedIn":'ដោយចុចបញ្ជូនអ្នកយល់ព្រមនឹងគោលនយោបាយរបស់ធីម',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.":'ដោយចុចដាក់ស្នើអ្នកយល់ព្រមនឹងគោលការណ៍នៃការផ្តល់មតិព័ត៌មាន {រយៈពេល 11} និង {រយៈពេល 2} ។',
  "By clicking Subscribe, you agree to {term1} and {term2}.":'ដោយចុចការចុះឈ្មោះជាវអ្នកយល់ព្រមនឹង {រយៈពេល 11} និង {រយៈពេល 2} ។',
  "by making a channel private, only members will have access to this channel":'តាមរយៈការធ្វើឱ្យឆានែលឯកជនមួយមានតែសមាជិកប៉ុណ្ណោះដែលអាចចូលទៅកាន់ប៉ុស្តិ៍នេះ',
  "by using FeedIn, you agree to our":'ដោយប្រើចំណីអ្នកយល់ព្រមនឹងរបស់យើង',
  "Call":'ការហោតាមតុរស័ប្ទ',
  "Call verification":'ហៅការផ្ទៀងផ្ទាត់',
  "catalog":'កាតាលូក',
  "categories affect how your story appears in public":'ប្រភេទដែលមានឥទ្ធិពលលើរបៀបដែលរឿងរ៉ាវរបស់អ្នកលេចឡើងនៅទីសាធារណៈ',
  "category groups":'ប្រភេទក្រុម',
  "change address":'ផ្លាស់ប្តូរអាសយដ្ឋាន',
  "change display title / subtitle / display image / categories":'ផ្លាស់ប្តូរចំណងជើងបង្ហាញ / ចំណងជើងរង / បង្ហាញរូបភាព / ប្រភេទ',
  "change following categories":'ផ្លាស់ប្តូរប្រភេទខាងក្រោម',
  "change password":'ផ្លាស់ប្តូរលេខសម្ងាត់',
  "change preview photo":'ផ្លាស់ប្តូររូបថតមើលជាមុន',
  "change role":'ផ្លាស់ប្តូរតួនាទី',
  "change subscription":'ផ្លាស់ប្តូរការជាវ',
  "changes here will affect how your story appears in public places like Article\'s homepage - not the story itself.":'ការផ្លាស់ប្តូរនៅទីនេះនឹងមានឥទ្ធិពលលើរបៀបដែលរឿងរ៉ាវរបស់អ្នកលេចឡើងនៅតាមទីសាធារណៈដូចជាគេហទំព័ររបស់មាត្រាមិនមែនជារឿងរបស់ខ្លួនទេ។',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.":'ការផ្លាស់ប្តូរបញ្ជីតាមដានត្រូវបានអនុញ្ញាតនៅក្នុង {m} ម៉ោង។ ហើយការផ្លាស់ប្តូរបន្ទាប់ត្រូវបានធ្វើឡើងក្នុង {ឃ} ថ្ងៃ។',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account\'s configuration to edit":'ការផ្លាស់ប្តូរភាសានៅលើអេក្រង់នេះនឹងផ្លាស់ប្តូរស្លាកដែលបង្ហាញនៅលើកម្មវិធីដើម្បីឱ្យសមនឹងភាសាដែលអ្នកបានជ្រើសរើស។ ដើម្បីរៀបចំការត្រងមាតិកាតាមភាសាសូមចូលប្រើការកំណត់រចនាសម្ព័ន្ធគណនីដើម្បីកែសម្រួល',
  "channel":'របលាយតឹក',
  "channel name":'ឈ្មោះឆានែល',
  "channel subscriptions":'ការជាវឆានែល',
  "channels":'ឆានន៍',
  "channels in category":'ឆានែលក្នុងប្រភេទ',
  "Channels that you may be interested in":'ប៉ុស្តិ៍ដែលអ្នកប្រហែលជាចាប់អារម្មណ៍',
  "channels to follow":'ឆានែលឱ្យធ្វើតាម',
  "charged fee data":'ទិន្នន័យថ្លៃសេវាដែលត្រូវបានចោទប្រកាន់',
  "charges apply":'ការចោទប្រកាន់ត្រូវបានអនុវត្ត',
  "chart management":'ការគ្រប់គ្រងគំនូសតាង',
  "charts":'គំនូសតាង',
  "chat with provider":'ជជែកជាមួយអង្គការ',
  "check all":'ពិនិត្យ​ទាំងអស់',
  "check your email and click on the link":'ពិនិត្យអ៊ីមែលរបស់អ្នកហើយចុចលើតំណភ្ជាប់',
  "choose another FeedIn subscription":'ជ្រើសរើសការជាវយករបស់អ្នក',
  "choose file":'ជ្រើសរើស​ឯកសារ',
  "Choose language":'ជ្រើសរើសភាសា',
  "Choose one from your existing conversations, or start a new one.":'ជ្រើសរើសយកមួយពីការសន្ទនាដែលមានស្រាប់របស់អ្នកឬចាប់ផ្តើមថ្មី។',
  "Choose the channel for posting":'ជ្រើសរើសឆានែលសម្រាប់ប្រកាស',
  "Choose to follow the information catalog to get more news on your wall":'ជ្រើសរើសអនុវត្តតាមកាតាឡុកព័ត៌មានដើម្បីទទួលបានព័ត៌មានបន្ថែមនៅលើជញ្ជាំងរបស់អ្នក',
  "choose your default skin tone":'ជ្រើសរើសសម្លេងស្បែកលំនាំដើមរបស់អ្នក',
  "city":'តីរកុង',
  "clear all":'លុបចេញ​ទាំងអស់',
  "clear role permissions":'ច្បាស់ការអនុញ្ញាតតួនាទី',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.":'ចុចប៊ូតុង {រូបតំណាង} នៅលើទំព័រគំនូសតាងដែលអ្នកចង់តាមដានតារាងនឹងត្រូវបានធ្វើបច្ចុប្បន្នភាពជាប្រចាំនៅទីនេះ។',
  "click to upload":'ចុចដើម្បីផ្ទុកឡើង',
  "code of conduct":'កូដ​នៃ​ការ​ប្រ​ព្រឹ​ត្ដ',
  "color theme":'ស្បែកពណ៌',
  "commented your post":'ផ្តល់យោបល់របស់អ្នក',
  "Commodity market information":'ព័ត៌មានទីផ្សារទំនិញ',
  "company profile":'ប្រវត្តិ​ក្រុមហ៊ុន',
  "confidentiality of member information":'ការសម្ងាត់នៃព័ត៌មានសមាជិក',
  "configure content by language":'កំណត់រចនាសម្ព័ន្ធមាតិកាតាមភាសា',
  "Confirmation change email address has been sent to":'អាសយដ្ឋានអ៊ីមែលការបញ្ជាក់ត្រូវបានផ្ញើទៅ',
  "Confirmation letter will be sent to your email address":'លិខិតបញ្ជាក់នឹងត្រូវបានផ្ញើទៅអាសយដ្ឋានអ៊ីមែលរបស់អ្នក',
  "contact details":'ព័ត៌មានលម្អិតទំនាក់ទំនង',
  "contact info":'ព័ត៌មានទំនាក់ទំនង',
  "contact messages":'សារទំនាក់ទំនង',
  "contact name":'ឈ្មោះ​ទំនាក់ទំនង',
  "contact now":'ទំនាក់ទំនងឥឡូវនេះ',
  "contact provider":'អង្គការទំនាក់ទំនង',
  "contact to member":'ទាក់ទងសមាជិក',
  "Contact us about your bill":'ទាក់ទងមកយើងអំពីវិក័យប័ត្ររបស់អ្នក',
  "contact with provider":'ទាក់ទងជាមួយអង្គការ',
  "contact-info":'ព័ត៌មានទំនាក់ទំនង',
  "contacts":'ទំនាក់ទំនង',
  "content allowed to be found":'មាតិកាដែលត្រូវបានអនុញ្ញាតឱ្យរកឃើញ',
  "content displayed by language":'មាតិកាដែលបង្ហាញដោយភាសា',
  "content is for members only":'មាតិកាគឺសម្រាប់តែសមាជិកតែប៉ុណ្ណោះ',
  "Content language":'ភាសាមាតិកា',
  "Continue to {provider}":'បន្តទៅ {អ្នកផ្តល់សេវា}',
  "Continue to PayPal":'បន្តទៅ PayPal',
  "conversations":'ការស្ទង់មតិ',
  "cookies-policy":'គោលនយោបាយខូឃីស៍',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel":'ចម្លងនិងចែករំលែកទំព័រតំណភ្ជាប់ខាងក្រោមទៅមិត្តរបស់អ្នក។ សម្រាប់មនុស្សគ្រប់គ្នាដែលអ្នកត្រូវការចែករំលែកចូលយ៉ាងងាយស្រួលហើយធ្វើតាមឆានែលនេះ',
  "copyright":'រក្សាសិទ្ធិ',
  "country":'រសុករេស',
  "cover":'រកប',
  "create a free channel":'បង្កើតឆានែលឥតគិតថ្លៃ',
  "Create a new chart for dataset":'បង្កើតគំនូសតាងថ្មីសម្រាប់សំណុំទិន្នន័យ',
  "Create a new provider so you can post products or manage your information channels.":'បង្កើតអង្គភាពដូច្នេះអ្នកអាចបង្ហោះផលិតផលឬគ្រប់គ្រងប៉ុស្តិ៍ព័ត៌មានរបស់អ្នក។',
  "create a poll":'បង្កើតការស្ទង់មតិ',
  "create category":'បង្កើតប្រភេទ',
  "create channel":'បង្កើតប៉ុស្តិ៍',
  "create chart":'បង្កើតគំនូសតាង',
  "create dataset":'បង្កើតសំណុំទិន្នន័យ',
  "Create new channel":'បង្កើតឆានែលថ្មី',
  "create new group":'បង្កើតក្រុមថ្មី',
  "create one":'បង្កើតមួយ',
  "create product":'បង្កើតផលិតផល',
  "create role":'បង្កើតតួនាទី',
  "Create provider profile":'បង្កើតប្រវត្តិរូបរបស់អង្គការ',
  "credit/debit card":'កាតឥណទាន / ឥណពន្ធ',
  "custom":'តមន្យេមតមលាប់',
  "custom url":'URL ផ្ទាល់ខ្លួន',
  "daily":'របចាមថៃ',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.":'ការធ្វើឱ្យទាន់សម័យប្រចាំថ្ងៃទំនិញផលិតផលកសិកម្មនិងផ្តល់ជាតិផ្តល់ហិរញ្ញប្បទានជារៀងរាល់ថ្ងៃនៅចំណី។',
  "dark mode":'របៀបងងឹត',
  "dark skin tone":'សម្លេងស្បែកខ្មៅងងឹត',
  "data":'តិន្នន័យ',
  "data & charts":'ទិន្នន័យនិងគំនូសតាង',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this":'មាតិកាទិន្នន័យអាចមើលបានដោយមនុស្សគ្រប់គ្នាអ្នកណាម្នាក់អាចចូលរួមបានដោយសេរីហើយអាចប្រកាសអត្ថបទនិងយោបល់ដោយសេរីលើបញ្ហានេះ',
  "data explorer":'របុសធេវីតាបអ៊ែរ',
  "data frequency":'ប្រេកង់ទិន្នន័យ',
  "data in category":'ទិន្នន័យក្នុងចំណាត់ថ្នាក់',
  "data security":'សុវត្ថិភាពទិន្នន័យ',
  "Data set you might be interested in":'សំណុំទិន្នន័យអ្នកប្រហែលជាចាប់អារម្មណ៍',
  "dataset":'តម្បៀង',
  "dataset list":'បញ្ជីសំណុំទិន្នន័យ',
  "dataset management":'ការគ្រប់គ្រងទិន្នន័យ',
  "dataset subscriptions":'ការជាវការជាវទិន្នន័យ',
  "deactive":'DAFactive',
  "Declare the location for your provider":'ប្រកាសទីតាំងសម្រាប់អង្គការរបស់អ្នក',
  "default skin tone":'សំលេងស្បែកលំនាំដើម',
  "delete channel":'លុបឆានែល',
  "delete data":'លុបទិន្នន័យ',
  "delete dataset":'លុបសំណុំទិន្នន័យ',
  "delete provider":'លុបអង្គការ',
  "Deleted article are gone forever. Are you sure?":'ការលុបអត្ថបទត្រូវបានបាត់បង់ជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "Deleted channel are gone forever. Are you sure?":'ឆានែលដែលបានលុបត្រូវបានបាត់បង់ជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "Deleted dataset are gone forever. Are you sure?":'សំណុំទិន្នន័យដែលបានលុបត្រូវបានបាត់បង់ជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "Deleted provider are gone forever. Are you sure?":'អង្គការដែលបានលុបត្រូវបានបាត់បង់ជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "delivery frequency":'ភាពញឹកញាប់នៃការដឹកជញ្ជូន',
  "delivery_time":'ពេលវេលាដឹកជញ្ជូន',
  "designed and provided by":'រចនានិងផ្តល់ដោយ',
  "desktop app":'កម្មវិធីផ្ទៃតុ',
  "discovery more providers":'រកឃើញអង្គការជាច្រើនទៀត',
  "do not show members information for each other members":'កុំបង្ហាញព័ត៌មានអំពីសមាជិកសម្រាប់សមាជិកម្នាក់ៗ',
  "do you want cancel the subscription?":'តើអ្នកចង់បោះបង់ការជាវទេ?',
  "do you want to cancel?":'តើអ្នកចង់បោះបង់ទេ?',
  "Do you want to deactivate this product?":'តើអ្នកចង់ធ្វើឱ្យផលិតផលនេះអសកម្មទេ?',
  "do you want to logout?":'តើអ្នកចង់ចាកចេញទេ?',
  "document (PDF)":'ឯកសារ (PDF)',
  "documentation":'ឯកសារ',
  "Doesn\'t have any bookmarked item":'មិនមានធាតុដែលបានកត់ចំណាំទេ',
  "downgrade":'បន្ទាបទឹកអាេយបាត់',
  "downgrade subscription":'ការជាវបន្ទាប',
  "downgrade your subscription to":'បន្ទាបការជាវរបស់អ្នកទៅ',
  "download":'របចាបរេកា',
  "draft products":'ផលិតផលព្រាង',
  "edit message":'កែសម្រួលសារ',
  "edit name":'កែសម្រួលឈ្មោះ',
  "edit your post":'កែសម្រួលប្រកាសរបស់អ្នក',
  "editor choices":'ជម្រើសកម្មវិធីនិពន្ធ',
  "email (example@company.com)":'អ៊ីម៉ែល (judy@company.com)',
  "email account":'គណនី​អ៊ី​ម៉ែ​ល',
  "Emoji categories":'ប្រភេទអេមីជី',
  "en":'អាលេវីម',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.":'ជំរុញឱ្យគំនិតផ្តួចផ្តើមអាជីវកម្មបង្កើតផលប៉ះពាល់សង្គម។ កោងឆ្ពោះទៅរកការកសាងបរិដ្ឋានរស់នៅប្រកបដោយនិរន្តរភាពសម្រាប់មនុស្សជំនាន់ក្រោយថ្ងៃនេះនិងថ្ងៃស្អែក។',
  "enter an url":'បញ្ចូល URL មួយ',
  "enter channel name":'បញ្ចូលឈ្មោះឆានែល',
  "Enter Dataset Name":'បញ្ចូលឈ្មោះទិន្នន័យ',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters":'បញ្ចូលអត្ថបទសារដោយតម្រូវការអប្បបរមា 20, អតិបរមា 4000 តួអក្សរ',
  "Enter products / services search":'បញ្ចូលផលិតផល / សេវាកម្មស្វែងរក',
  "Enter some information so the provider knows your requirements":'បញ្ចូលព័ត៌មានមួយចំនួនដូច្នេះអង្គការដឹងពីតម្រូវការរបស់អ្នក',
  "enter provider name":'បញ្ចូលឈ្មោះរបស់អង្គការ',
  "enter your email":'បញ្ចូលអ៊ីមែលរបស់អ្នក',
  "enter your keyword":'បញ្ចូលពាក្យគន្លឹះរបស់អ្នក',
  "expires":'ផុតសុបិប់សំងឺ',
  "Explore related products from verified Companies":'ស្វែងរកផលិតផលដែលទាក់ទងពីអង្គការដែលបានផ្ទៀងផ្ទាត់',
  "Explore similar products":'ស្វែងរកផលិតផលស្រដៀងគ្នា',
  "extra request":'ការស្នើសុំបន្ថែម',
  "failure purchase!":'ការទិញបរាជ័យ!',
  "FAQ":'សំណួរគេសួរញឹកញាប់',
  "favorites categories":'ប្រភេទ',
  "Featured Categories":'ប្រភេទពិសេស',
  "featured charts":'គំនូសតាងពិសេស',
  "featured topics":'ប្រធានបទដែលមានលក្ខណៈពិសេស',
  "fee management":'ការគ្រប់គ្រងថ្លៃឈ្នួល',
  "fee settings":'ការកំណត់ថ្លៃសេវា',
  "feed":'ការចិហ្ចឹម',
  "FeedIn gives you the latest information on market data.":'ចំណីផ្តល់ឱ្យអ្នកនូវព័ត៌មានចុងក្រោយស្តីពីទិន្នន័យទីផ្សារ។',
  "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below":'ចំណីបានដាក់ជាក្រុមព័ត៌មានតាមប្រភេទទំនិញដើម្បីជួយអ្នកក្នុងការទទួលបានព័ត៌មានកាន់តែប្រសើរនិងជៀសវាងព័ត៌មានដែលបាត់។ សូមជ្រើសរើសប្រភេទដែលអ្នកចូលចិត្តនៅខាងក្រោម',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.":'ចំណីគឺជាវេទិកាមួយដែលភ្ជាប់អ្នកទិញជាមួយអង្គការបង្កើតបណ្តាញចែករំលែកព័ត៌មានឈានមុខគេអំពីកសិពាណិជ្ជកម្ម។',
  "FeedIn Member Since":'សមាជិកចំណីអាហារតាំងពីពេលនោះមក',
  "FeedIn will update the latest and fastest information and data about the goods market to you":'ចំណីអាហារនឹងធ្វើបច្ចុប្បន្នភាពព័ត៌មាននិងទិន្នន័យចុងក្រោយបំផុតនិងលឿនបំផុតអំពីទីផ្សារទំនិញដល់អ្នក',
  "file upload failed":'ការផ្ទុកឯកសារបានបរាជ័យ',
  "file uploaded successfully":'ផ្ទុកឡើងដោយជោគជ័យ',
  "filter":'តរមង',
  "Filter results":'លទ្ធផលតម្រង',
  "first subscription on":'ការជាវលើកដំបូងនៅលើ',
  "Flags":'ទង់ជាតិ',
  "folders":'រកដាសបត់ចាបីរ',
  "follow more categories":'អនុវត្តតាមប្រភេទបន្ថែមទៀត',
  "Follow to get notifications from this channel!":'តាមដានដើម្បីទទួលបានការជូនដំណឹងពីប៉ុស្តិ៍នេះ!',
  "followers":'អ្នកដមាង',
  "following categories":'ប្រភេទខាងក្រោម',
  "following channels":'បណ្តាញខាងក្រោម',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.":'សម្រាប់អ្នកទិញវិនិយោគិននិងអ្នកដែលចាប់អារម្មណ៍។ គ្រាន់តែជ្រើសរើសតំបន់ដែលចាប់អារម្មណ៍អ្នកត្រូវបានស្នើភ្ជាប់ទំនាក់ទំនងជាមួយអ្នកជំនាញនិងព័ត៌មានចុងក្រោយនៃទីផ្សារ។',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us":'សម្រាប់ការសាកសួរអំពីការដកស្រង់ផលិតផលផ្ញើព័ត៌មានផលិតផលឬទាក់ទងកិច្ចសហប្រតិបត្តិការសូមទាក់ទងមកយើងខ្ញុំ',
  "for you":'សម្រាប់​អ្នក',
  "free":'ដេលមានសេរីផាប',
  "frequently questions":'សំណួរជាញឹកញាប់',
  "Frequently Used":'ប្រើជាញឹកញាប់',
  "frequently-questions":'សំណួរជាញឹកញាប់',
  "From - To":'ពី - ទៅ',
  "from the sender":'ពីអ្នកផ្ញើ',
  "general permission":'សេចក្តីអនុញ្ញាបខ្លួនជាទូទៅ',
  "general settings":'ការកំណត់​ទូទៅ',
  "Get a quote":'ទទួលបានការដកស្រង់មួយ',
  "Get Best Price":'ទទួលបានតម្លៃល្អបំផុត',
  "Get in touch with us":'ទាក់ទងជាមួយពួកយើង',
  "Get latest price":'ទទួលបានតម្លៃចុងក្រោយ',
  "Get Quotes":'ទទួលបានសម្រង់',
  "Get quotes from seller":'ទទួលបានការដកស្រង់ពីអ្នកលក់',
  "get started with {name}":'ចាប់ផ្តើមជាមួយ {ឈ្មោះ}',
  "Get your business details verified with us for higher rankings in FeedIn!":'ទទួលបានព័ត៌មានលម្អិតអាជីវកម្មរបស់អ្នកដែលបានផ្ទៀងផ្ទាត់ជាមួយយើងសម្រាប់ចំណាត់ថ្នាក់ខ្ពស់នៅក្នុងចំណី!',
  "go":'ធេវីដមនើរ',
  "go modal":'ទៅម៉ូឌុល',
  "haven\'t comments yet":'មិនទាន់មតិយោបល់នៅឡើយទេ',
  "head office":'ការិយាល័យ​ក​ណ្តា​ល',
  "hello":'ចមរាបសយរ',
  "Hello you":'សួស្តីអ្នក',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.":'ជួយមនុស្សឱ្យធ្វើបច្ចុប្បន្នភាពព័ត៌មានទីផ្សារចុងក្រោយបំផុតពហុវិមាត្រការសម្ងាត់និងតាមបំណងតាមតម្រូវការ។',
  "Here is a list of all invite links. You can revoke any one.":'នេះគឺជាបញ្ជីតំណភ្ជាប់ដែលបានអញ្ជើញទាំងអស់។ អ្នកអាចដកហូតបានមួយ។',
  "hide interaction between members":'លាក់អន្តរកម្មរវាងសមាជិក',
  "Hide interactions between members. But, retaining interaction between members and administrators":'លាក់អន្តរកម្មរវាងសមាជិក។ ប៉ុន្តែការរក្សាអន្តរកម្មរវាងសមាជិកនិងអ្នកគ្រប់គ្រង',
  "If you need assistance, please feel free to contact us via":'ប្រសិនបើអ្នកត្រូវការជំនួយសូមទាក់ទងមកយើងខ្ញុំតាមរយៈ',
  "If you want to switch to annual payment, {contactus}":'ប្រសិនបើអ្នកចង់ប្តូរទៅការទូទាត់ប្រចាំឆ្នាំ {ទំនាក់ទំនង}',
  "image":'ធម្ផារម្ផន៍',
  "image must smaller than":'រូបភាពត្រូវតែតូចជាង',
  "incoming requests":'សំណើចូល',
  "index type":'ប្រភេទសន្ទស្សន៍',
  "index types":'ប្រភេទសន្ទស្សន៍',
  "info chart editing":'ការកែសម្រួលតារាងព័ត៌មាន',
  "interested":'មេរសន្យា',
  "Interface language":'ភាសាចំណុចប្រទាក់',
  "introduce":'នាមអាេយស្គាល់',
  "invalid template":'គំរូមិនត្រឹមត្រូវ',
  "invite and share":'អញ្ជើញនិងចែករំលែក',
  "invite code":'ការអញ្ជើញលេខកូដ',
  "invite members":'អញ្ជើញសមាជិក',
  "invite people":'អញ្ជើញមនុស្ស',
  "invite people to join this channel":'អញ្ជើញមនុស្សឱ្យចូលរួមឆានែលនេះ',
  "invite you to join":'អញ្ជើញអ្នកឱ្យចូលរួម',
  "Invite your friends to this page":'អញ្ជើញមិត្តភក្តិរបស់អ្នកមកទំព័រនេះ',
  "invited":'អវិហ្ញាវ',
  "invited to become member":'បានអញ្ជើញឱ្យក្លាយជាសមាជិក',
  "inviter":'អនាឆ្លង',
  "invites":'លើកតោការអញ្ជើញ',
  "is served by clicking on a":'ត្រូវបានបម្រើដោយចុចលើក',
  "item not found":'រកមិនឃើញធាតុ',
  "join channel":'ឆានែលចូលរួម',
  "Join for free now":'ចូលរួមដោយឥតគិតថ្លៃឥឡូវនេះ',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel\'s latest news to you":'ចូលរួមឆានែលដើម្បីចែករំលែកអត្ថបទយោបល់ឬចុចដើម្បីធ្វើតាមដូច្នេះប្រព័ន្ធនឹងធ្វើបច្ចុប្បន្នភាពព័ត៌មានថ្មីៗរបស់ឆានែលដោយស្វ័យប្រវត្តិ',
  "Join this channel to start chatting":'ចូលរួមឆានែលនេះដើម្បីចាប់ផ្តើមជជែកលេង',
  "keep subscription":'រក្សាការជាវ',
  "keywords":'ពាក្យគន្លឹះ',
  "kick":'តាត់',
  "kicked":'ទាត់',
  "last updated":'ធ្វើ​ឱ្យ​ទាន់សម័យ​ចុងក្រោយ',
  "latest":'យឺតបមផុត',
  "latest updates":'ការធ្វើបច្ចុប្បន្នភាពចុងក្រោយបំផុត',
  "Latest updates on the commodity market":'ព័ត៌មានថ្មីៗចុងក្រោយនៅលើទីផ្សារទំនិញ',
  "leave":'ចាកចេញ',
  "leave a message":'ទុកសារមួយ',
  "Leave a Message, we will contact you back!":'ទុកសារមួយ, យើងនឹងទាក់ទងអ្នកត្រឡប់មកវិញ!',
  "leave channel":'ចាកចេញឆានែល',
  "Legal Status of Firm":'ស្ថានភាពច្បាប់របស់ក្រុមហ៊ុនក្រុមហ៊ុន',
  "license":'អាេយសិត្ធិ',
  "licenses":'អាជ្ញាប័ណ្ណ',
  "light skin tone":'សម្លេងស្បែកស្រាល',
  "link":'ការតាក់តង',
  "Link original post":'ភ្ជាប់ប្រកាសដើម',
  "list of data":'បញ្ជីទិន្នន័យ',
  "listed products":'ផលិតផលដែលបានរាយ',
  "unlisted products":'ផលិតផលដែលមិនបានចុះបញ្ជី',
  "Location (Province/district/ward)":'ទីតាំង (ខេត្ត / ស្រុក / វួដ)',
  "location & contact":'ទីតាំងនិងទំនាក់ទំនង',
  "location name":'ឈ្មោះទីតាំង',
  "Login now":'ចូលឥឡូវនេះ',
  "Login now to select and follow the content that you interested in":'ចូលឥឡូវនេះដើម្បីជ្រើសរើសនិងតាមដានមាតិកាដែលអ្នកចាប់អារម្មណ៍',
  "login with account":'ចូលជាមួយគណនី',
  "login with Facebook":'ចូល​ជា​មួយ​ហ្វេ​ស​ប៊ុ​ក',
  "login with Google":'ចូលជាមួយ Google',
  "Make your channel get more exciting activities by inviting other members":'ធ្វើឱ្យឆានែលរបស់អ្នកទទួលបានសកម្មភាពគួរឱ្យរំភើបជាងនេះដោយការអញ្ជើញសមាជិកផ្សេងទៀត',
  "Make your provider get more exciting activities by inviting other members":'ធ្វើឱ្យអង្គភាពរបស់អ្នកទទួលបានសកម្មភាពគួរឱ្យរំភើបជាងនេះដោយការអញ្ជើញសមាជិកផ្សេងទៀត',
  "manage":'កាន់កាប់',
  "manage dataset":'គ្រប់គ្រងសំណុំទិន្នន័យ',
  "manage products":'គ្រប់គ្រងផលិតផល',
  "manage services":'គ្រប់គ្រងសេវាកម្ម',
  "ManageChannelSubscriptions":'ការជាវឆានែល',
  "managed channels":'ឆានែលគ្រប់គ្រង',
  "mark as read":'សម្គាល់ថាជាការអាន',
  "market":'ផសារ',
  "market summary":'សង្ខេបទីផ្សារ',
  "mart":'ម៉ាសីន',
  "me to the channel":'ខ្ញុំទៅឆានែល',
  "medium skin tone":'សម្លេងស្បែកមធ្យម',
  "medium-dark skin tone":'សម្លេងស្បែកមធ្យម',
  "medium-light skin tone":'សម្លេងស្បែកមធ្យម',
  "member description":'ការពិពណ៌នាអំពីសមាជិក',
  "member management":'ការគ្រប់គ្រងសមាជិក',
  "members list":'បញ្ជីឈ្មោះសមាជិក',
  "mention":'ការវេកញេក',
  "menu":'បហ្ជីមុខម្ហូប',
  "Message":'ប៍តមាន',
  "messages":'សារ',
  "min_order_qtty":'បរិមាណបញ្ជាទិញអប្បបរមា',
  "Minimum payment required":'ទាមទារការទូទាត់អប្បបរមា',
  "miss":'កញ្ញា',
  "modal":'ការរបកបត្ដិការ',
  "monthly":'របចាមខេ',
  "More data from":'ទិន្នន័យបន្ថែមពី',
  "More like This":'ដូចនេះ',
  "mr":'លោក។',
  "mrs":'អ្នកស្រី។',
  "ms":'លោកស្រី',
  "my channels":'ឆានែលរបស់ខ្ញុំ',
  "my page":'ទំព័រ​របស់ខ្ញុំ',
  "my post":'ប្រកាសរបស់ខ្ញុំ',
  "name of chart":'ឈ្មោះតារាង',
  "name of your company":'ឈ្មោះក្រុមហ៊ុនរបស់អ្នក',
  "Nature of Business":'ធម្មជាតិនៃអាជីវកម្ម',
  "new answer":'ចម្លើយថ្មី',
  "New contact":'ទំនាក់ទំនងថ្មី',
  "new folder":'ប្រអប់​ឯកសារ​ថ្មី',
  "new group":'ក្រុមថ្មី',
  "New template":'គំរូថ្មី',
  "newest data":'ទិន្នន័យថ្មីបំផុត',
  "news page":'ទំព័រព័ត៌មាន',
  "next change":'ការផ្លាស់ប្តូរបន្ទាប់',
  "No categories have been selected":'មិនមានប្រភេទណាមួយត្រូវបានជ្រើសរើសទេ',
  "no categorized":'មិនមានប្រភេទ',
  "No channel being followed":'គ្មានប៉ុស្តិ៍ណាដែលត្រូវបានអនុវត្តតាម',
  "no channels":'គ្មានឆានែលទេ',
  "No Data":'គ្មាន​ទិន្នន័យ',
  "No data available at this time.":'មិនមានទិន្នន័យនៅពេលនេះទេ។',
  "no data found":'រកមិនឃើញទិន្នន័យទេ',
  "No data set are being followed":'គ្មានសំណុំទិន្នន័យកំពុងត្រូវបានអនុវត្តតាមទេ',
  "no dataset":'គ្មានសំណុំទិន្នន័យ',
  "no description":'គ្មានការពិពណ៌នា',
  "No Emoji Found":'រកមិនឃើញ emoji',
  "No filter available":'មិនមានតម្រងទេ',
  "no invites yet":'មិនមានលិខិតអញ្ជើញនៅឡើយទេ',
  "No news":'គ្មានព័ត៌មាន',
  "no office found":'រកមិនឃើញការិយាល័យ',
  "No posts were found":'រកមិនឃើញការប្រកាសណាមួយទេ',
  "no results found":'រកមិនឃើញលទ្ធផល',
  "not a member yet?":'មិនមែនជាសមាជិកទេឬ?',
  "notes about chart!":'កំណត់ចំណាំអំពីតារាង!',
  "notification":'របាយការ',
  "Number of Employees":'ចំនួនសមាជិក',
  "Number of your channels:":'ចំនួនឆានែលរបស់អ្នក:',
  "Objects":'វត្ថុ',
  "offices":'ការិយាល័យ',
  "OK":'យល់ព្រម',
  "oldest data":'ទិន្នន័យចំណាស់ជាងគេបំផុត',
  "One more step to become a Verified Seller":'មួយជំហានទៀតដើម្បីក្លាយជាអ្នកលក់ដែលបានផ្ទៀងផ្ទាត់',
  "Only members can view data content, post articles or comment on this":'មានតែសមាជិកប៉ុណ្ណោះដែលអាចមើលមាតិកាទិន្នន័យបានសារព័ត៌មានឬយោបល់លើបញ្ហានេះ',
  "oops...":'អូ ..',
  "opps, no channels available":'OPPs មិនមានឆានែលទេ',
  "or continue with":'ឬបន្តជាមួយ',
  "other categories":'ប្រភេទផ្សេងទៀត',
  "other channels":'ឆានែលផ្សេងទៀត',
  "other charts":'គំនូសតាងផ្សេងទៀត',
  "otherSettings":'ការកំណត់ផ្សេងទៀត',
  "Our Channels":'ឆានែលរបស់យើង',
  "Our data":'ទិន្នន័យរបស់យើង',
  "Our mission is to:":'បេសកកម្មរបស់យើងគឺ:',
  "Our Videos":'វីដេអូរបស់យើង',
  "overview":'ការបិប៍ននា',
  "owner description":'តួនាទីរបស់ម្ចាស់',
  "package in use":'កញ្ចប់កំពុងប្រើ',
  "packaging_details":'ព័ត៌មានលំអិតវេចខ្ចប់',
  "paid channel":'ឆានែលបង់ប្រាក់',
  "partner":'ដៃកុ',
  "Password and confirm password does not match":'ពាក្យសម្ងាត់និងការបញ្ជាក់ពាក្យសម្ងាត់មិនត្រូវគ្នា',
  "Password must have min 6 characters":'លេខសម្ងាត់ត្រូវតែមាននាទី 6 តួអក្សរ',
  "Passwords must match":'ពាក្យសំងាត់​ត្រូវតែ​ដូចគ្នា',
  "paste your link here":'បិទភ្ជាប់តំណរបស់អ្នកនៅទីនេះ',
  "pay":'របាក់ឈ្នួល',
  "Pay and subscribe":'បង់និងជាវ',
  "payment detail":'ព័ត៌មានលំអិតនៃការទូទាត់',
  "payment subscription":'ការជាវបង់ប្រាក់',
  "Payment via {provider}":'ការទូទាត់តាមរយៈ {អ្នកផ្តល់សេវា}',
  "Payment via Paypal":'ការទូទាត់តាម Paypal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay":'ការទូទាត់នឹងត្រូវបានផ្ញើដើម្បីបន្តនៅលើ {អ្នកផ្តល់សេវា} ។ សូមបញ្ជាក់ចំនួនទឹកប្រាក់ដែលត្រូវបង់',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay":'ការទូទាត់នឹងត្រូវបានផ្ញើឱ្យបន្តនៅលើ PayPal ។ សូមបញ្ជាក់ចំនួនទឹកប្រាក់ដែលត្រូវបង់',
  "paypal":'ស្យេវកាស',
  "pending approval":'ការអនុម័តដែលមិនទាន់សម្រេច',
  "people to follow":'មនុស្សត្រូវធ្វើតាម',
  "Permanent link":'តំណអចិន្រ្តៃយ៍',
  "permission denied":'បានបដិសេធការអនុញ្ញាត',
  "permissions":'សិទ្ធិ',
  "personal":'ផ្ទាល់ខ្លួន',
  "personal channels":'ឆានែលផ្ទាល់ខ្លួន',
  "personal profile":'ប្រវត្តិរូបផ្ទាល់ខ្លួន',
  "Phone Number":'លេខទូរសព្ទ',
  "Phone number how we can contact you":'លេខទូរស័ព្ទតើយើងអាចទាក់ទងអ្នកបានយ៉ាងដូចម្តេច',
  "Pictures on the home page":'រូបភាពនៅលើគេហទំព័រ',
  "pin":'ម្ជុល',
  "Pin code":'លេខកូដ PIN',
  "pinned":'ខ្ទាស់',
  "pinned posts":'បានខ្ទាស់ប្រកាស',
  "pinnedPosts":'បានខ្ទាស់ប្រកាស',
  "Please check the information and follow the steps to subscribe.":'សូមពិនិត្យមើលព័ត៌មានហើយធ្វើតាមជំហានដើម្បីចុះឈ្មោះជាវ។',
  "Please choose to follow related channels for the system to filter the updates to you":'សូមជ្រើសរើសធ្វើតាមឆានែលដែលពាក់ព័ន្ធសម្រាប់ប្រព័ន្ធដើម្បីត្រងការធ្វើបច្ចុប្បន្នភាពទៅអ្នក',
  "Please choose to save the interest charts so you can view them easily each day":'សូមជ្រើសរើសរក្សាទុកតារាងការប្រាក់ដូច្នេះអ្នកអាចមើលពួកវាបានយ៉ាងងាយស្រួលក្នុងមួយថ្ងៃ',
  "please enter name of product":'សូមបញ្ចូលឈ្មោះផលិតផល',
  "please input your email":'សូមបញ្ចូលអ៊ីមែលរបស់អ្នក',
  "please input your phone number":'សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក',
  "Please log in to FeedIn to leave your comment in the post":'សូមចូលទៅក្នុងចំណីដើម្បីទុកមតិយោបល់របស់អ្នកនៅក្នុងការប្រកាស',
  "Please login to use the message function in the system.":'សូមចូលដើម្បីប្រើមុខងារសារនៅក្នុងប្រព័ន្ធ។',
  "Please register to view this content":'សូមចុះឈ្មោះដើម្បីមើលមាតិកានេះ',
  "please select":'សូមជ្រើសរើស',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information":'សូមជ្រើសរើសប្រធានបទយ៉ាងហោចណាស់ 1 ដើម្បីបញ្ចប់។ ចំណីនឹងជួយអ្នកស្វែងរកព័ត៌មានពាក់ព័ន្ធ',
  "Please select the unit you want to choose as the default":'សូមជ្រើសរើសអង្គភាពដែលអ្នកចង់ជ្រើសរើសតាមលំនាំដើម',
  "policy":'នយោបាយ',
  "poll":'ក្បាល',
  "popular":'ដេលរបចារបិយផាប',
  "Post something":'ប្រកាសអ្វីមួយ',
  "post your images":'បញ្ចូលរូបភាពរបស់អ្នក',
  "postal code":'លេខ​កូដ​ប្រៃ​ស​ណី​យ',
  "posted by":'ចុះផ្សាយដោយ',
  "posts":'ប្រកាស',
  "Premium":'បុព្វលាច',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.":'ឆានែលពិសេសនៅពេលបើកដំណើរការអនុញ្ញាតឱ្យអ្នកប្រើប្រាស់ចូលរួមឆានែលសម្រាប់ថ្លៃសេវា។ ម្ចាស់ឆានែលនឹងទទួលបានប្រាក់ចំណូលពីសមាជិក។',
  "premium providers":'អង្គការបុព្វលាភ',
  "press enter to search":'ចុច Enter ដើម្បីស្វែងរក',
  "preview description":'ការមើលការពិពណ៌នាជាមុន',
  "preview title":'ចំណងជើងមើលជាមុន',
  "pricing":'តម្លៃ',
  "Primary E-mail":'អ៊ី​ម៉ែ​ល​បឋម',
  "Primary Mobile No.":'លេខទូរស័ព្ទចម្រុះបឋម',
  "privacy settings":'ការកំណត់ភាពឯកជន',
  "privacy-policy":'គោលការណ៍​ភាព​ឯកជន',
  "private channel":'ឆានែលឯកជន',
  "pro":'ផាលស្នេហ',
  "product catalogs":'កាតាឡុកផលិតផល',
  "product certificates":'វិញ្ញាបនប័ត្រផលិតផល',
  "product details":'ព​ត៌​មាន​លម្អិត​នៃ​ផលិតផល',
  "product images":'រូបភាពផលិតផល',
  "product must have at least five character":'ផលិតផលត្រូវតែមានយ៉ាងហោចណាស់ប្រាំតួអក្សរ',
  "product must have at least one image":'ផលិតផលត្រូវតែមានយ៉ាងហោចណាស់រូបភាពមួយ',
  "product settings":'ការកំណត់ផលិតផល',
  "product specifications":'លក្ខណៈផលិតផល',
  "Product/Service Name":'ឈ្មោះផលិតផល / សេវាកម្ម',
  "production_capacity":'សមត្ថភាពផលិតកម្ម',
  "products list":'បញ្ជីផលិតផល',
  "products page":'ទំព័រផលិតផល',
  "Professional network in the industry":'បណ្តាញវិជ្ជាជីវៈនៅក្នុងឧស្សាហកម្ម',
  "profile settings":'ការកំណត់ទម្រង់',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.":'លើកកម្ពស់មនុស្សឱ្យចែករំលែកចំណេះដឹងនិងបទពិសោធន៍ដូច្នេះប្រជាជនអាចរៀនបានជាបន្តបន្ទាប់និងរីកចម្រើនជាមួយគ្នា។',
  "provided services":'សេវាកម្មផ្តល់ជូន',
  "Province/City":'ខេត្ត / ក្រុង',
  "Province/district/ward":'ខេត្ត / ស្រុក / វួដ',
  "provinces":'ខេត្ត',
  "public timeline":'ការកំណត់ពេលវេលាសាធារណៈ',
  "publish":'បោហបុម្ព',
  "published by":'ចេញផ្សាយ​ដោយ',
  "publisher":'អ្នកបោហបុម្ព',
  "quick link":'តំណរហ័ស',
  "read":'អាន',
  "read more":'អាន​បន្ថែម',
  "read news":'អានព័ត៌មាន',
  "ready to publish?":'ត្រៀមខ្លួនជាស្រេចក្នុងការបោះពុម្ពផ្សាយ?',
  "rebookmark":'ការសមនាមរេប',
  "recent news":'ព័ត៌មានថ្មីៗ',
  "recently news":'ព័ត៌មានថ្មីៗ',
  "Recommendations":'អនុសាសន៍',
  "refreshed":'ធ្វើឱ្យស្រស់ស្រាយ',
  "region":'តមបន់',
  "regions":'ខ្លឹមបឹង',
  "registered":'បានចុះឈ្មោះ',
  "Registering to use advanced data, you will have to pay":'ការចុះឈ្មោះប្រើទិន្នន័យកម្រិតខ្ពស់អ្នកនឹងត្រូវបង់ប្រាក់',
  "related articles":'អត្ថបទ​ដែល​ទាក់ទង',
  "related categories":'ប្រភេទដែលទាក់ទង',
  "related channels":'បណ្តាញពាក់ព័ន្ធ',
  "related charts":'តារាងពាក់ព័ន្ធ',
  "related keywords":'ពាក្យគន្លឹះដែលពាក់ព័ន្ធ',
  "Related to items you\'ve viewed":'ទាក់ទងនឹងធាតុដែលអ្នកបានមើល',
  "relevant":'ដេលតាក់តង',
  "remove all":'យកទាំងអស់ចេញ',
  "renew / expires on":'បន្ត / ផុតកំណត់នៅលើ',
  "replied":'ឆ្លើយ',
  "Request a call":'ស្នើសុំការហៅទូរស័ព្ទ',
  "Request a quote":'ស្នើសុំសម្រង់មួយ',
  "request have been sent successfully!":'ការស្នើសុំត្រូវបានផ្ញើដោយជោគជ័យ!',
  "request to join":'ស្នើសុំឱ្យចូលរួម',
  "request-to-join":'ស្នើសុំឱ្យចូលរួម',
  "requested to join":'ស្នើសុំឱ្យចូលរួម',
  "required field":'វាលដែលត្រូវការ',
  "Requirement Details":'ព័ត៌មានលំអិតតម្រូវការ',
  "resend":'ផ្ញើសារឡើងវិញ',
  "response rate":'អត្រាឆ្លើយតប',
  "responses":'ការឆ្លើយតប',
  "restricted":'បានដាក់កម្រិត',
  "results":'លទ្ធផល',
  "return home":'ត្រលប់​មក​ផ្ទះ',
  "return owner page":'ត្រឡប់ទំព័ររបស់ម្ចាស់',
  "return user page":'ត្រឡប់ទំព័រអ្នកប្រើប្រាស់',
  "Review":'សរសេររិហកន់',
  "role name":'ឈ្មោះតួនាទី',
  "role title":'ចំណងជើងតួនាទី',
  "roles":'សមតួនាទី',
  "sales information":'ព័ត៌មានអំពីការលក់',
  "save & complete":'រក្សាទុកនិងបញ្ចប់',
  "Save and continue":'រក្សាទុកនិងបន្ត',
  "schedule to cancel":'កាលវិភាគដើម្បីបោះបង់',
  "search anythings in FeedIn":'ស្វែងរកសំណងនៅក្នុងចំណី',
  "Search by Name":'ស្វែងរកតាមឈ្មោះ',
  "Search Results":'លទ្ធផលស្វែងរក',
  "secret channel":'ឆានែលសម្ងាត់',
  "section":'ផ្នែក',
  "See all charts in the data":'មើលតារាងទាំងអស់នៅក្នុងទិន្នន័យ',
  "select / create a group":'ជ្រើសរើស / បង្កើតក្រុម',
  "Select a content category or follow the authors to get relevant information":'ជ្រើសប្រភេទមាតិកាឬធ្វើតាមអ្នកនិពន្ធដើម្បីទទួលបានព័ត៌មានពាក់ព័ន្ធ',
  "select category":'ជ្រើសរើសប្រភេទ',
  "select preview photo":'ជ្រើសរូបថតមើលជាមុន',
  "Select the display order in the category":'ជ្រើសរើសលំដាប់បង្ហាញក្នុងប្រភេទ',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.":'ជ្រើសរើសប្រភេទមាតិកាដែលអ្នកចង់ឃើញ។ ហើយចំណីនឹងប្រមូលនិងបង្ហាញព័ត៌មានដែលសាកសមនឹងចំណាប់អារម្មណ៍របស់អ្នក។',
  "select your country":'ជ្រើសរើសប្រទេសរបស់អ្នក',
  "selected":'បានជ្រើស',
  "seller":'អ្នកលក់',
  "Seller Contact Details":'ព័ត៌មានលំអិតទំនាក់ទំនងរបស់អ្នកលក់',
  "seller name":'ឈ្មោះអ្នកលក់',
  "send":'ផ្ញើរ',
  "Send Email":'ផ្ញើអ៊ីមែល',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below":'ផ្ញើការអញ្ជើញឱ្យចូលរួមជាមួយឆានែលទៅមិត្តភក្តិរបស់អ្នកដោយផ្ញើច្បាប់ចម្លងនៃតំណទំព័រខាងក្រោម',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below":'ផ្ញើការអញ្ជើញឱ្យចូលរួមក្នុងអង្គការទៅនឹងមិត្តភក្តិរបស់អ្នកដោយផ្ញើច្បាប់ចម្លងនៃតំណទំព័រខាងក្រោម',
  "send request":'ផ្ញើសំណើរ',
  "Send your message to this provider":'ផ្ញើសាររបស់អ្នកទៅអង្គការនេះ',
  "sending":'ការផ្ញើ',
  "sent a request to become member":'បានផ្ញើសំណើដើម្បីក្លាយជាសមាជិក',
  "services":'សេវាកម្ម',
  "set main":'កំណត់មេ',
  "set up content selection by language":'រៀបចំការជ្រើសរើសមាតិកាតាមភាសា',
  "share a post":'ចែករំលែកភ្នំពេញប៉ុស្តិ៍',
  "share button":'ប៊ូតុងចែករំលែក',
  "share your ideas":'ចែករំលែកគំនិតរបស់អ្នក',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.":'ចែករំលែកចំណេះដឹងដែលមានចំណេះដឹងឬរបស់អ្នកដើម្បីបង្កើនវត្តមានរបស់អ្នកបង្កើតយីហោធ្វើឱ្យមានមិត្តភក្តិជាមួយដៃគូសក្តានុពលឬថែមទាំងផ្តល់ព័ត៌មានសម្រាប់ថ្លៃសេវាសម្រាប់ថ្លៃសេវា។',
  "Sharing the information":'ចែករំលែកព័ត៌មាន',
  "short description":'ការពិពណ៌នាខ្លី',
  "Short description about chart!":'ការពិពណ៌នាខ្លីអំពីគំនូសតាង!',
  "Short description about your company!":'ការពិពណ៌នាខ្លីអំពីក្រុមហ៊ុនរបស់អ្នក!',
  "Short description about your dataset!":'ការពិពណ៌នាខ្លីអំពីសំណុំទិន្នន័យរបស់អ្នក!',
  "show in category":'បង្ហាញក្នុងប្រភេទ',
  "show more":'បង្ហាញបន្ថែមទៀត',
  "show this post":'បង្ហាញប្រកាសនេះ',
  "sign in with":'ចូលជាមួយ',
  "signin / signup":'ចុះហត្ថលេខា / ចុះឈ្មោះ',
  "site":'វេបសាយថ៍',
  "sku":'ឪ',
  "Smileys & People":'ស្នាមញញឹមនិងប្រជាជន',
  "some messages...some messages...":'សារខ្លះ ... សារខ្លះ ...',
  "Sorry, the page you visited does not exist.":'សូមអភ័យទោសទំព័រដែលអ្នកបានទស្សនាមិនមានទេ។',
  "Sorry, the page you visited has expired or does not exist.":'សូមអភ័យទោសទំព័រដែលអ្នកបានចូលទស្សនាបានផុតកំណត់ឬមិនមាន។',
  "source":'របផប',
  "Specification Details":'ព័ត៌មានលម្អិតបញ្ជាក់',
  "start free trial":'ចាប់ផ្តើមការសាកល្បងឥតគិតថ្លៃ',
  "start trial":'ចាប់ផ្តើមការជំនុំជម្រះ',
  "start your no-cost {subscription} trial today":'ចាប់ផ្តើមការជំនុំជម្រះការជាវគ្មានតំលៃរបស់អ្នក} ថ្ងៃនេះ',
  "start your no-cost trial today":'ចាប់ផ្តើមការជំនុំជម្រះក្តីគ្មានថ្លៃដើមរបស់អ្នកនៅថ្ងៃនេះ',
  "state":'សផាប',
  "stay connected":'រក្សាទំនាក់ទំនង',
  "Submit requirement":'ដាក់ស្នើតម្រូវការ',
  "subscribe channel":'ជាវប៉ុស្តិ៍ប៉ុស្តិ៍',
  "Subscribe to the categories to display the charts you\'re interested in":'ជាវប្រភេទដែលត្រូវបង្ហាញតារាងដែលអ្នកចាប់អារម្មណ៍',
  "Subscribe to the channels for information displayed on this page":'ចុះឈ្មោះជាវឆានែលសម្រាប់ព័ត៌មានដែលបានបង្ហាញនៅលើទំព័រនេះ',
  "subscribed data":'ទិន្នន័យដែលបានចុះឈ្មោះ',
  "subscribers":'អតិថិជន',
  "subscribing this channel will cost you":'ការជាវឆានែលនេះនឹងធ្វើឱ្យអ្នកខាតបង់',
  "subscriptions":'ការជាវ',
  "successfully purchase!":'ទិញដោយជោគជ័យ!',
  "suggested products":'ផលិតផលដែលបានស្នើ',
  "summary":'សង្ខេប',
  "Summary of the latest news today":'សង្ខេបនៃព័ត៌មានថ្មីៗនៅថ្ងៃនេះ',
  "supplied by":'ផ្គត់ផ្គង់ដោយ',
  "provider":'សមាកម',
  "provider address":'អាសយដ្ឋានរបស់អង្គការ',
  "provider details":'ព័ត៌មានលំអិតរបស់អង្គការ',
  "provider homepage":'គេហទំព័ររបស់អង្គការ',
  "provider name":'ឈ្មោះ​អង្គការ',
  "provider profile":'ប្រវត្តិរូបរបស់អង្គភាព',
  "provider profile was created successful":'ប្រវត្តិរូបរបស់អង្គភាពត្រូវបានបង្កើតឡើងដោយជោគជ័យ',
  "provider profiles":'ប្រវត្តិរូបរបស់អង្គការ',
  "provider\'s channels":'ឆានែលរបស់អង្គភាព',
  "providers":'អង្គការ',
  "Supporting brand promotion and market information to enhance business and networking opportunities.":'គាំទ្រដល់ការផ្សព្វផ្សាយយីហោនិងព័ត៌មានទីផ្សារដើម្បីបង្កើនឱកាសអាជីវកម្មនិងបណ្តាញ។',
  "Symbols":'និមិត្តសញ្ញា',
  "table of contents":'តារាង​មាតិកា',
  "Tap":'ក្បាលម៉ាសីនតឹក',
  "tax":'របាក់បង់បន្ធ',
  "tax code":'លេខកូដពន្ធ',
  "Tax ID":'លេខសម្គាល់ពន្ធ',
  "tax inclusive":'ការដាក់ពន្ធបញ្ចូលពន្ធ',
  "tax number":'លេខពន្ធ',
  "Tell us what you need":'ប្រាប់យើងពីអ្វីដែលអ្នកត្រូវការ',
  "template":'ថ័តទីថបហេ',
  "terms of service":'លក្ខខណ្ឌ​នៃ​សេវាកម្ម',
  "terms-of-service":'លក្ខខណ្ឌ​នៃ​សេវាកម្ម',
  "the {package} subscription will be continue effect until {date}":'ការជាវ {កញ្ចប់} នឹងបន្តមានប្រសិទ្ធិភាពរហូតដល់ {កាលបរិច្ឆេទ}',
  "The banner image will be placed on the company\'s homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.":'រូបភាពបដានឹងត្រូវបានដាក់នៅលើគេហទំព័ររបស់ក្រុមហ៊ុន។ ទិដ្ឋភាពល្អបំផុតជាមួយនឹងដំណោះស្រាយ 256 x 1280 ភីកសែល។ ប្រភេទរូបភាព: PNG, JPG ។',
  "the channel has been deleted":'ឆានែលត្រូវបានលុបចោល',
  "the channel so that the system will automatically help you update with the latest news":'ឆានែលដូច្នេះប្រព័ន្ធនេះនឹងជួយអ្នកឱ្យធ្វើបច្ចុប្បន្នភាពកម្មវិធីព័ត៌មានថ្មីៗដោយស្វ័យប្រវត្តិ',
  "The chart is enabled and ready to be displayed to the user":'គំនូសតាងត្រូវបានបើកហើយត្រៀមបង្ហាញដល់អ្នកប្រើប្រាស់',
  "The chart marked as typical and used to represent the data set":'គំនូសតាងដែលសម្គាល់ថាធម្មតានិងប្រើដើម្បីតំណាងឱ្យសំណុំទិន្នន័យ',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content":'ខ្លឹមសារ (រូបភាពប្រកាស ... ) ត្រូវបានចែករំលែកនៅក្នុងប៉ុស្តិ៍មានតែសមាជិកនៃប៉ុស្តិ៍ប៉ុណ្ណោះដែលបានមើល។ អ្នកប្រើប្រាស់ផ្សេងទៀតមិនអាចមើលឬឆ្លើយតបចំពោះខ្លឹមសារនេះបានទេ',
  "the information that interests you":'ព័ត៌មានដែលអ្នកចាប់អារម្មណ៍',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other\'s information.":'អន្តរកម្មរវាងសមាជិកនោះហើយប្រធានាធិបតីឆានែលនៃសមាជិកនីមួយៗនឹងក្លាយជាការសម្ងាត់យ៉ាងតឹងរឹង។ មានតែអ្នកគ្រប់គ្រងឬបុគ្គលដែលមាននៅក្នុងប៉ុស្តិ៍ប៉ុណ្ណោះដែលអាចមើលឃើញព័ត៌មានរបស់គ្នាទៅវិញទៅមក។',
  "the latest news":'ព័ត៌មានចុងក្រោយ',
  "The members shown here include: Owner, administrator, member as contact person.":'សមាជិកដែលបានបង្ហាញនៅទីនេះរួមមាន: ម្ចាស់អ្នកគ្រប់គ្រងសមាជិកជាអ្នកទំនាក់ទំនង។',
  "The page content is not yet available or being written":'ខ្លឹមសារទំព័រមិនទាន់មានឬត្រូវបានសរសេរនៅឡើយទេ',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.":'ទំព័រដែលអ្នកកំពុងរកមិនមានទេ។ ប៉ះប៊ូតុងខាងក្រោមដើម្បីត្រឡប់ទៅគេហទំព័រ។',
  "the restore email has been sent to":'អ៊ីមែលស្តារត្រូវបានផ្ញើទៅ',
  "The state of the data set. If \"On\", the dataset is ready to use":'ស្ថានភាពនៃសំណុំទិន្នន័យ។ ប្រសិនបើ "នៅលើ" សំណុំទិន្នន័យត្រៀមខ្លួនរួចរាល់ដើម្បីប្រើ',
  "the provider has been deleted":'អង្គការត្រូវបានលុបចោលហើយ',
  "the providers i am involved in":'អង្គការដែលខ្ញុំចូលរួម',
  "The tax code value has been saved successfully":'តម្លៃលេខកូដពន្ធត្រូវបានរក្សាទុកដោយជោគជ័យ',
  "The verification call is pending":'ការហៅការផ្ទៀងផ្ទាត់កំពុងរង់ចាំ',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account":'គណនីនេះមិនបានចុះបញ្ជីអាស័យដ្ឋានអ៊ីម៉ែលសម្រាប់ការផ្ទៀងផ្ទាត់ភាពត្រឹមត្រូវទេសូមចុះឈ្មោះអាសយដ្ឋានអ៊ីមែលដើម្បីផ្ទៀងផ្ទាត់ភាពជាម្ចាស់នៃគណនី',
  "This channel content is limited to members only, join now to view this channel content.":'មាតិកាឆានែលនេះត្រូវបានកំណត់ចំពោះសមាជិកតែប៉ុណ្ណោះ, ចូលរួមឥឡូវនេះដើម្បីមើលមាតិកាប៉ុស្តិ៍នេះ។',
  "This channel doesn\'t have any pinned post":'ប៉ុស្តិ៍នេះមិនមានប៉ុស្តិ៍ដែលបានខ្ទាស់ណាមួយទេ',
  "this channel supports communication in the form of hidden interactions between members":'ឆានែលនេះគាំទ្រការប្រាស្រ័យទាក់ទងក្នុងទម្រង់នៃអន្តរកម្មដែលលាក់កូនរវាងសមាជិក',
  "this channel to your friends":'ឆានែលនេះដល់មិត្តភក្តិរបស់អ្នក',
  "This channel will charge you":'ឆានែលនេះនឹងគិតថ្លៃអ្នក',
  "This is a description.":'នេះគឺជាការពិពណ៌នា។',
  "this is a regular channel":'នេះគឺជាបណ្តាញធម្មតា',
  "This is an error message":'នេះគឺជាសារកំហុសមួយ',
  "This product will be removed from your Catalog and won\'t be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?":'ផលិតផលនេះនឹងត្រូវបានយកចេញពីកាតាឡុករបស់អ្នកហើយនឹងមិនមើលឃើញអ្នកទិញទេ។ អ្នកក៏នឹងឈប់ទទួលប្រាក់ក្នុងស្រុកសម្រាប់ផលិតផលនេះបន្តិចម្តង ៗ ។ តើអ្នកពិតជាចង់បន្តមែនទេ?',
  "Time for updating the tracking list:":'ពេលវេលាសម្រាប់ការធ្វើបច្ចុប្បន្នភាពបញ្ជីតាមដាន:',
  "timeline is only viewable with accounts that are members of this channel":'ការកំណត់ពេលវេលាគឺអាចមើលបានតែជាមួយគណនីដែលជាសមាជិកនៃប៉ុស្តិ៍នេះ',
  "timeline is viewed internally only":'ពេលវេលាត្រូវបានមើលនៅខាងក្នុងតែប៉ុណ្ណោះ',
  "title or question":'សេចក្តីថ្លែងការណ៍សំណួរ',
  "to channel":'ទៅឆានែល',
  "to create new channel on your company":'ដើម្បីបង្កើតឆានែលថ្មីនៅលើក្រុមហ៊ុនរបស់អ្នក',
  "to create your new channel":'ដើម្បីបង្កើតឆានែលថ្មីរបស់អ្នក',
  "To edit your details, please visit {profile}":'ដើម្បីកែសម្រួលព័ត៌មានលម្អិតរបស់អ្នកសូមទស្សនា {ប្រវត្តិរូប}',
  "to find content that interests you":'ដើម្បីស្វែងរកមាតិកាដែលអ្នកចាប់អារម្មណ៍',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member":'ដើម្បីចូលរួមឆានែលហើយធ្វើតាមខ្លឹមសារជាប្រចាំអ្នកត្រូវតែចូល / ចុះឈ្មោះ (ឥតគិតថ្លៃ) គណនីដើម្បីក្លាយជាសមាជិក',
  "To make changes to your subscriptions , go to":'ដើម្បីធ្វើការផ្លាស់ប្តូរការជាវរបស់អ្នកសូមចូលទៅកាន់',
  "To manage products visit at {manage_products} or continue with {call_verification}":'ដើម្បីគ្រប់គ្រងការចូលមើលផលិតផលនៅ {color __Products} ឬបន្តជាមួយ {Call_verific}',
  "To stay on your current plan after your trial ends, add payment now.":'ដើម្បីស្នាក់នៅលើផែនការបច្ចុប្បន្នរបស់អ្នកបន្ទាប់ពីការជំនុំជម្រះក្តីរបស់អ្នកបញ្ចប់បន្ថែមការទូទាត់ឥឡូវនេះ។',
  "to upgrade. This subscription cost you":'ដើម្បីធ្វើឱ្យប្រសើរឡើង។ ការជាវនេះចំណាយអ្នក',
  "topic":'របធានបត',
  "Total due":'សរុប​មក',
  "Total due {date}":'សរុបសេចក្តីត្រូវការ {កាលបរិច្ឆេទ}',
  "Travel & Places":'ការធ្វើដំណើរនិងទីកន្លែង',
  "trial":'ការកាត់សេចក្ដី',
  "trial days":'ថ្ងៃជំនុំជម្រះ',
  "trust and safe":'ការជឿទុកចិត្តនិងមានសុវត្ថិភាព',
  "trust-safe":'ការជឿទុកចិត្តនិងមានសុវត្ថិភាព',
  "tutorials":'ការបង្រៀន',
  "type product name":'វាយឈ្មោះផលិតផល',
  "type your message":'វាយបញ្ចូលសាររបស់អ្នក',
  "typify chart":'វាយបញ្ចូលតារាង',
  "unban":'ឥតរន្ធហាន',
  "unbookmark":'ដេលចុសាយ',
  "unbookmarked":'unberglet កំណត់',
  "unclassified products":'ផលិតផលដែលមិនបានបញ្ជាក់',
  "unpin":'អក្គោអិន',
  "unpin this?":'UNPIN នេះ?',
  "unsave":'ដេលអាជមិនសកម្ម',
  "unsubscribe":'ឈប់ជាវ',
  "update about success":'ធ្វើឱ្យទាន់សម័យអំពីភាពជោគជ័យ',
  "update dataset":'ធ្វើបច្ចុប្បន្នភាពសំណុំទិន្នន័យ',
  "update fail":'ធ្វើឱ្យទាន់សម័យបរាជ័យ',
  "update success":'ធ្វើបច្ចុប្បន្នភាពជោគជ័យ',
  "Updates to you the information you have chosen to follow":'ធ្វើឱ្យទាន់សម័យដល់អ្នកនូវព័ត៌មានដែលអ្នកបានជ្រើសរើសធ្វើតាម',
  "upgrade":'ធេវីសកម្មទឹក',
  "upgrade now. This subscription cost you":'ធ្វើឱ្យប្រសើរឡើងឥឡូវនេះ។ ការជាវនេះចំណាយអ្នក',
  "upgrade subscription":'ធ្វើឱ្យប្រសើរឡើងការជាវ',
  "upgrade this channel":'ធ្វើឱ្យប្រសើរឡើងនូវប៉ុស្តិ៍នេះ',
  "upgrade to":'ធ្វើឱ្យប្រសើរឡើងទៅ',
  "upgrade to {name}":'ធ្វើឱ្យប្រសើរឡើងទៅ {ឈ្មោះ}',
  "upload":'ផ្ទុកឡើង',
  "url":'URL',
  "us to share the news in this channel":'យើងចែករំលែកព័ត៌មាននៅក្នុងប៉ុស្តិ៍នេះ',
  "Usage: for direct communication purposes through messaging.":'ការប្រើប្រាស់: សម្រាប់គោលបំណងទំនាក់ទំនងដោយផ្ទាល់តាមរយៈការផ្ញើសារ។',
  "use email":'ប្រើអ៊ីមែល',
  "use the selected system language":'ប្រើភាសាប្រព័ន្ធដែលបានជ្រើសរើស',
  "used group":'ក្រុមដែលបានប្រើ',
  "user choices":'ជម្រើសអ្នកប្រើ',
  "user management":'ការគ្រប់គ្រងអ្នកប្រើប្រាស់',
  "uses":'ការប្រើប្រាស់',
  "Verification request has been sent, please wait for a response":'ការស្នើសុំផ្ទៀងផ្ទាត់ត្រូវបានផ្ញើសូមរង់ចាំការឆ្លើយតប',
  "verified provider":'អង្គការដែលបានផ្ទៀងផ្ទាត់',
  "Verify Account":'ផ្ទៀងផ្ទាត់គណនី',
  "Verify on Call":'ផ្ទៀងផ្ទាត់លើការហៅ',
  "Verify sales information":'ផ្ទៀងផ្ទាត់ព័ត៌មានលក់',
  "vi":'tiếngviệt',
  "video url":'URL វីដេអូ (តំណយូធូប)',
  "vietnamese":'tiếngviệt',
  "view":'កមហើញ',
  "view all":'មើលទាំងអស់',
  "View all categories":'មើលប្រភេទទាំងអស់',
  "View all products in {name}":'មើលផលិតផលទាំងអស់ក្នុង {ឈ្មោះ}',
  "View complete detail":'មើលលម្អិតពេញលេញ',
  "view full estimate":'មើលការប៉ាន់ស្មានពេញលេញ',
  "view more charts":'មើលគំនូសតាងច្រើនទៀត',
  "view product page":'មើលទំព័រផលិតផល',
  "views":'ទស្សនៈ',
  "visit page":'ការទស្សនាទំព័រ',
  "Visit the page to preview":'ចូលទៅកាន់ទំព័រនេះទៅក្នុងការមើលជាមុន',
  "vnpay":'អនាក់',
  "waiting admin accept your request":'រង់ចាំអ្នកគ្រប់គ្រងទទួលយកសំណើរបស់អ្នក',
  "waiting members list":'បញ្ជីសមាជិករង់ចាំ',
  "wanna more features?":'តើមានលក្ខណៈពិសេសបន្ថែមទៀតទេ ?',
  "we will upgrade you immediately, you won\'t have to pay anything until your trial ends":'យើងនឹងធ្វើឱ្យប្រសើរឡើងអ្នកភ្លាមៗអ្នកនឹងមិនត្រូវបង់អ្វីទាំងអស់រហូតដល់ការជំនុំជម្រះរបស់អ្នកបញ្ចប់',
  "we will upgrade you to {subscription} immediately, you won\'t have to pay anything until your trial ends":'យើងនឹងធ្វើឱ្យប្រសើរឡើងនូវ {ការជាវ} ភ្លាមៗអ្នកនឹងមិនចាំបាច់បង់ប្រាក់អ្វីទាំងអស់រហូតដល់ការជំនុំជម្រះក្តីរបស់អ្នកបញ្ចប់',
  "welcome":'ការសវាកមន៍',
  "What are you looking for?":'តើ​អ្នក​កំពុង​រក​អ្វី?',
  "what is your chart name?":'តើឈ្មោះគំនូសតាងរបស់អ្នកគឺជាអ្វី?',
  "what is your company name?":'តើឈ្មោះក្រុមហ៊ុនរបស់អ្នកគឺជាអ្វី?',
  "what is your name?":'តើ​អ្នក​មាន​ឈ្មោះ​អ្វី?',
  "what is your question?":'តើ​សំ​នូ​រ​របស់​អ្នក​គឺ​អ្វី?',
  "what your channel name?":'តើឆានែលរបស់អ្នកជាអ្វី?',
  "what your product name?":'តើផលិតផលរបស់អ្នកជាអ្វី?',
  "when you\'re ready":'នៅពេលអ្នកត្រៀមខ្លួនជាស្រេច',
  "which categories are your interests?":'តើប្រភេទណាដែលជាចំណាប់អារម្មណ៍របស់អ្នក ?',
  "with account":'ជាមួយគណនី',
  "write a response":'សរសេរការឆ្លើយតប',
  "write article":'សរសេរអត្ថបទ',
  "write description for this page":'សរសេរការពិពណ៌នាសម្រាប់ទំព័រនេះ',
  "write something about this page":'សរសេរអ្វីមួយអំពីទំព័រនេះ',
  "Write your sharing":'សរសេរការចែករំលែករបស់អ្នក',
  "written by":'សរសេរដោយ',
  "Year of Establishment":'ឆ្នាំបង្កើតឆ្នាំ',
  "You are not choosing to follow this chart category. Please choose follow up in advance":'អ្នកមិនបានជ្រើសរើសធ្វើតាមប្រភេទគំនូសតាងនេះទេ។ សូមជ្រើសរើសតាមដានជាមុន',
  "you can not change your following categories before {time}":'អ្នកមិនអាចផ្លាស់ប្តូរប្រភេទដូចខាងក្រោមរបស់អ្នកមុនពេល {ម៉ោង}',
  "you can not follow more {n} categories":'អ្នកមិនអាចតាមដានប្រភេទ {n} ច្រើនទៀតទេ',
  "You can only upload JPG/PNG file":'អ្នកអាចបញ្ចូលតែឯកសារ JPG / PNG ប៉ុណ្ណោះ',
  "you commented on {owner}\'s post":'អ្នកបានអត្ថាធិប្បាយលើ {ម្ចាស់} របស់ម្ចាស់}',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account":'បច្ចុប្បន្នអ្នកមានដែនកំណត់អតិបរមានៃ {n} ដែលត្រូវបានអនុញ្ញាតឱ្យកាន់កាប់។ អ្នកអាចផ្លាស់ប្តូរវាដោយធ្វើឱ្យប្រសើរគណនីរបស់អ្នក',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account":'បច្ចុប្បន្នអ្នកមានដែនកំណត់អតិបរមារបស់អ្នកផ្តល់សេវា {n} អនុញ្ញាតឱ្យកាន់កាប់។ អ្នកអាចផ្លាស់ប្តូរវាដោយធ្វើឱ្យប្រសើរគណនីរបស់អ្នក',
  "you didn\'t enter the channel name correctly":'អ្នកមិនបានបញ្ចូលឈ្មោះឆានែលត្រឹមត្រូវទេ',
  "you didn\'t enter the provider name correctly":'អ្នកមិនបានបញ្ចូលឈ្មោះអ្នកផ្តល់សេវាត្រឹមត្រូវទេ',
  "You do not have a conversation selected":'អ្នកមិនមានការសន្ទនាដែលបានជ្រើសរើសទេ',
  "you don\'t have permission to post":'អ្នកមិនមានការអនុញ្ញាតឱ្យប្រកាសទេ',
  "you have not any notification!":'អ្នកមិនមានការជូនដំណឹងទេ!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.":'អ្នកមានធាតុអតិបរមា {n} ធាតុដែលត្រូវធ្វើតាម។ ព័ត៌មាននឹងត្រូវបានច្រោះតាមប្រភេទដែលអ្នកជ្រើសរើស។',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.":'អ្នកមានធាតុអតិបរមា {n} ធាតុដែលត្រូវធ្វើតាម។ ព័ត៌មាននឹងត្រូវបានច្រោះតាមភាសាដែលអ្នកជ្រើសរើស។',
  "You need {amount} to be able subscribe this channel.":'អ្នកត្រូវការ {ចំនួនទឹកប្រាក់} អាចជាវប៉ុស្តិ៍នេះបាន។',
  "you need login first":'អ្នកត្រូវការចូលដំបូង',
  "Your account has been activated":'គណនីរបស់អ្នកត្រូវបានធ្វើឱ្យសកម្ម',
  "your answer":'ចម្លើយ​របស់​អ្នក',
  "Your business information has been fully updated, thank you":'ព័ត៌មានអាជីវកម្មរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាពយ៉ាងពេញលេញសូមអរគុណ',
  "your chart":'តារាងរបស់អ្នក',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you":'គណនីចរន្តរបស់អ្នកអនុញ្ញាតឱ្យធ្វើតាមភាសាមួយប្រសិនបើអ្នកចង់ដកដែនកំណត់នេះចេញសូមដំឡើងទៅកញ្ចប់ខ្ពស់។ សូមអរគុណ',
  "Your current active subscription package will continue to hold until it expires":'កញ្ចប់ការជាវសកម្មបច្ចុប្បន្នរបស់អ្នកនឹងបន្តកាន់រហូតដល់វាផុតកំណត់',
  "your device don\'t support this!":'ឧបករណ៍របស់អ្នកមិនគាំទ្រវាទេ!',
  "your invite link expires":'តំណអញ្ជើញរបស់អ្នកផុតកំណត់',
  "your message":'សារ​របស់​អ្នក',
  "Your phone number":'លេខទូរស័ព្ទ​របស់​អ្នក',
  "your post":'ភ្នំពេញប៉ុស្តិ៍របស់អ្នក',
  "your request is pending approval":'សំណើរបស់អ្នកកំពុងរង់ចាំការអនុម័ត',
  "your subscription will be renew on {date}":'ការជាវរបស់អ្នកនឹងបន្តជាថ្មីនៅលើ {កាលបរិច្ឆេទ}',
  "Your message have been sent":'សាររបស់អ្នកត្រូវបានផ្ញើ',
  "verified":'បានផ្ទៀងផ្ទាត់',
  "price history":'ប្រវត្តិតម្លៃតម្លៃ',
  "product groups":'ក្រុមផលិតផល',
  "representative":'អ្នកតមនាង',
  "representative chart":'តារាងតំណាង',
  "the content will be delivered to you in the language you choose":'មាតិកានឹងត្រូវបានបញ្ជូនទៅអ្នកក្នុងភាសាដែលអ្នកជ្រើសរើស',
  "favorites":'សេវesព្រះអង្គ',
  "chart name":'ឈ្មោះគំនូសតាង',
  "select categories":'ជ្រើសរើសប្រភេទ',
  "the dataset has been deleted":'សំណុំទិន្នន័យត្រូវបានលុបចោល',
  "create article":'បង្កើតអត្ថបទ',
  "have sent you invitation":'បានផ្ញើការអញ្ជើញអ្នក',
  "related dataset":'សំណុំទិន្នន័យពាក់ព័ន្ធ',
  "Send invitation to your friends, by sending a copy of the page link below":'ផ្ញើការអញ្ជើញជូនមិត្តភក្តិរបស់អ្នកដោយផ្ញើច្បាប់ចម្លងនៃទំព័រតំណភ្ជាប់ខាងក្រោម',
  "Copy and share the page link below to your friends.":'ចម្លងនិងចែករំលែកទំព័រតំណភ្ជាប់ខាងក្រោមទៅមិត្តរបស់អ្នក។',
  "Response from provider will be sent via {email}":'ការឆ្លើយតបពីអង្គការនឹងត្រូវបានផ្ញើតាមរយៈ {អ៊ីម៉ែល}',
  "action":'កិច្ច',
  "timeout":'ដល់​ម៉ោង',
  "limit":'របមដេន',
  "5 minutes":'5 នាទី',
  "30 minutes":'30 នាទី',
  "1h":'1 ហត 1',
  "1day":'ម៉ោងមិនរាជមុ',
  "Join As Member":'ចូលរួមជាសមាជិក',
  "Join As Admin":'ចូលរួមជាអ្នកគ្រប់គ្រង',
  "others data":'ទិន្នន័យផ្សេងទៀត',
  "channel management":'ការគ្រប់គ្រងឆានែល',
  "channel list":'បញ្ជីឆានែល',
  "all channels":'ប៉ុស្តិ៍ទាំងអស់',
  "technical specs":'លក្ខណៈបច្ចេកទេស',
  "functional specs":'លក្ខណៈបច្ចេកទេសមុខងារ',
  "other specs":'លក្ខណៈពិសេសផ្សេងទៀត',
  "custom specs":'លក្ខណៈពិសេសផ្ទាល់ខ្លួន',
  "shipment":'ការបមបង់នាវា',
  "period":'រយកាល',
  "spot":'ចមនុច',
  "forward":'ដេលតោមុខ',
  "basis":'មុហ',
  "select period":'ជ្រើសរើសរយៈពេល',
  "from date":'ចាប់ពីសម័យ',
  "to date":'មកដល់ពេលនេះ',
  "Select the category in which the system will provide you with the most current market data":'ជ្រើសរើសប្រភេទដែលប្រព័ន្ធនេះនឹងផ្តល់ឱ្យអ្នកនូវទិន្នន័យទីផ្សារបច្ចុប្បន្នបំផុត',
  "Thanks sponsors bring freely these charts to you":'អ្នកអរគុណអ្នកឧបត្ថម្ភនាំមកនូវគំនូសតាងទាំងនេះឱ្យអ្នក',
  "Thanks sponsors for updating this news to you":'សូមអរគុណអ្នកឧបត្ថម្ភសម្រាប់ការធ្វើបច្ចុប្បន្នភាពព័ត៌មាននេះដល់អ្នក',
  "quotes of provider":'សម្រង់អង្គការ',
  "announce":'របកាស',
  "product price announcement":'សេចក្តីប្រកាសតម្លៃផលិតផល',
  "create report":'បង្កើតរបាយការណ៍',
  "the recipient\'s email":'អ៊ីមែលរបស់អ្នកទទួល',
  "new email":'អ៊ី​ម៉ែ​ល​ថ្មី',
  "price_type":'ប្រភេទតម្លៃ',
  "Pricing Reports":'របាយការណ៍តម្លៃ',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce":'បង្កើតការដាស់តឿនតម្លៃនឹងបញ្ជូនពួកគេទៅអ្នកទិញទិញដៃគូឬឱ្យតម្លៃផលិតផលប្រកាស',
  "products quotation":'ការដកស្រង់ផលិតផល',
  "effected date":'កាលបរិច្ឆេទដែលមានប្រសិទ្ធិភាព',
  "price update":'ការធ្វើបច្ចុប្បន្នភាពតម្លៃ',
  "no price tag":'គ្មានស្លាកតម្លៃទេ',
  "updated at":'ធ្វើឱ្យទាន់សម័យនៅ',
  "reference price":'តម្លៃយោង',
  "basis price":'តំលៃមូលដ្ឋាន',
  "forward price":'តំលៃទៅមុខ',
  "delivery type":'ប្រភេទចែកចាយ',
  "general basic prices":'តម្លៃមូលដ្ឋានទូទៅ',
  "leave blank to select all":'ទុកឱ្យនៅទទេដើម្បីជ្រើសរើសទាំងអស់',
  "This information is confidential and is only for the member to be submitted":'ព័ត៌មាននេះគឺការសម្ងាត់ហើយសម្រាប់តែសមាជិកដែលត្រូវបានដាក់ស្នើ',
  "Sure to remove?":'ប្រាកដថាយកចេញ?',
  "Enter a price for the period":'បញ្ចូលតម្លៃសម្រាប់រយៈពេលនេះ',
  "advanced entry":'ការចូលកម្រិតខ្ពស់',
  "flat price":'តម្លៃផ្ទះល្វែង',
  "quoted prices":'តម្លៃដកស្រង់',
  "start (/end) date in term months":'ចាប់ផ្តើម (/ បញ្ចប់) កាលបរិច្ឆេទក្នុងខែរយៈពេល',
  "select provinces":'ជ្រើសរើសខេត្ត',
  "spot price":'តម្លៃចំណុច',
  "confirm":'បហ្ជាក់',
  "delivery type option (delivery is default): this option affects the product price":'ជម្រើសប្រភេទនៃការចែកចាយ (ការដឹកជញ្ជូនគឺជាលំនាំដើម): ជម្រើសនេះប៉ះពាល់ដល់តម្លៃផលិតផល',
  "delivery price":'តម្លៃដឹកជញ្ជូន',
  "shipment price":'តម្លៃដឹកជញ្ជូន',
  "add spot price":'បន្ថែមតម្លៃចំណុច',
  "add forward price":'បន្ថែមតម្លៃទៅមុខ',
  "period range":'ជួររយៈពេល',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed":'ព័ត៌មាននេះត្រូវបានកែសំរួលក្នុងវិសាលភាពនៃរបាយការណ៍ខ្លឹមសារនៃព័ត៌មានដើមមិនត្រូវបានផ្លាស់ប្តូរទេ',
  "original price":'តម្លៃ​ដើម',
  "feedin users":'អ្នកប្រើចំណី',
  "choose user":'ជ្រើសរើសអ្នកប្រើប្រាស់',
  "Select the term for the price input":'ជ្រើសរើសពាក្យសម្រាប់ការបញ្ចូលតម្លៃ',
  "price unit":'ឯកតាតម្លៃ',
  "enter new price":'បញ្ចូលតម្លៃថ្មី',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)":'បញ្ចូលតម្លៃតម្លៃ / ធ្វើបច្ចុប្បន្នភាពក្នុងអេក្រង់នេះតម្លៃនឹងត្រូវបានធ្វើបច្ចុប្បន្នភាពទៅកាលបរិច្ឆេទចុងក្រោយបំផុតនៅកាលបរិច្ឆេទបច្ចុប្បន្នសម្រាប់ប្រភេទតម្លៃនីមួយៗ (ចំណុចទៅមុខ)',
  "incoterm":'ចមមេត្ផាម',
  "transport type":'ប្រភេទដឹកជញ្ជូន',
  "unit of the basis price":'ឯកតានៃតម្លៃមូលដ្ឋាន',
  "new price record by location":'កំណត់ត្រាតម្លៃថ្មីតាមទីតាំង',
  "This location has been declared, cannot be added":'ទីតាំងនេះត្រូវបានប្រកាសមិនអាចបន្ថែមបានទេ',
  "choose another location":'ជ្រើសរើសទីតាំងមួយផ្សេងទៀត',
  "new price set":'សំណុំតម្លៃថ្មី',
  "choose another option for new price set":'ជ្រើសរើសជម្រើសមួយផ្សេងទៀតសម្រាប់សំណុំតម្លៃថ្មី',
  "These options are already declared, cannot be added":'ជម្រើសទាំងនេះត្រូវបានប្រកាសរួចហើយមិនអាចបន្ថែមបានទេ',
  "add new forward price":'បន្ថែមតម្លៃទៅមុខថ្មី',
  "edit quotes":'កែសម្រួលសម្រង់',
  "other unit":'ឯកតាផ្សេងទៀត',
  "if basis price is required":'ប្រសិនបើតម្លៃមូលដ្ឋានគឺចាំបាច់',
  "range":'ចយរ',
  "editor\'s choice":'ជម្រើសរបស់អ្នកនិពន្ធ',
  "remove the 2nd unit":'ដោះឯកតាទី 2 ចេញ',
  "hidden price":'តម្លៃដែលលាក់',
  "wholesale":'លក់ដុំ',
  "contact provider for wholesale price":'អង្គការទំនាក់ទំនងសម្រាប់តម្លៃលក់ដុំ',
  "provided by":'ផ្តល់​ដោយ',
  "about product":'អំពីផលិតផល',
  "brief description":'ការពិពណ៌នាសង្ខេប',
  "manufactor":'តុបសម្រាស់',
  "add new specification":'បន្ថែមការបញ្ជាក់ថ្មី',
  "selecting...":'ការជ្រើសរើស ...',
  "method type":'ប្រភេទវិធីសាស្រ្ត',
  "operation":'ការវហកាត់',
  "attribute":'គុណនបុស',
  "attribute value":'គុណលក្ខណៈតម្លៃ',
  "factory":'រោងចរក',
  "Specification/Additional Details":'ការបញ្ជាក់ / ព័ត៌មានលម្អិតបន្ថែម',
  "tap on here to start your articles":'ប៉ះនៅទីនេះដើម្បីចាប់ផ្តើមអត្ថបទរបស់អ្នក',
  "attribute group":'គុណលក្ខណៈក្រុម',
  "displayed on product information":'បង្ហាញលើព័ត៌មានផលិតផល',
  "page not found":'រកមិនឃើញទំព័រ',
  "The page you are opening does not exist, please check the link or return to the homepage":'ទំព័រដែលអ្នកកំពុងបើកមិនមានទេសូមពិនិត្យមើលតំណឬត្រឡប់ទៅគេហទំព័រ',
  "catalog group":'កាតាឡុកក្រុម',
  "manage page":'គ្រប់គ្រងទំព័រ',
  "main page":'ទំ​ពរ​សំខាន់',
  "use for":'ប្រើ​សម្រាប់',
  "set_channel":'ធ្វើឱ្យទាន់សម័យឆានែលប្រកាស',
  "added value":'តម្លៃបន្ថែម',
  "product line":'ខ្សែផលិតផល',
  "2nd unit":'អង្គភាពទី 2',
  "2nd price unit":'ឯកតាតម្លៃលើកទី 2',
  "The effective date of the current price set will be adjusted to the date below":'កាលបរិច្ឆេទដែលមានប្រសិទ្ធភាពនៃការកំណត់តម្លៃបច្ចុប្បន្ននឹងត្រូវបានកែតម្រូវរហូតដល់កាលបរិច្ឆេទខាងក្រោម',
  "Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.":'បង្កើតទិន្នន័យតម្លៃតម្លៃថ្មីដែលមានប្រសិទ្ធភាពចាប់ពីកាលបរិច្ឆេទខាងក្រោម។ សម្គាល់ៈទិន្នន័យនឹងត្រូវបានកត់ត្រាទុកជាបណ្តោះអាសន្នយោងទៅតាមតម្លៃនៃថ្ងៃចុងក្រោយនេះ។',
  "Copy the current priceset for another location with the same priceset":'ចម្លងតម្លៃបច្ចុប្បន្នសម្រាប់ទីតាំងមួយផ្សេងទៀតដែលមានតំលៃដូចគ្នា',
  "Please contact the administrator of FeedIn to put the data on the site":'សូមទាក់ទងអ្នកគ្រប់គ្រងចំណីអាហារដើម្បីដាក់ទិន្នន័យនៅលើគេហទំព័រ',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'បិទភ្ជាប់តំណភ្ជាប់ដើម្បីបង្កប់មាតិកាពីគេហទំព័រផ្សេងទៀត (ឧ។ Twitter) ហើយចុចយល់ព្រម',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'បិទភ្ជាប់តំណវីដេអូដើម្បីបង្កប់មាតិកាពីគេហទំព័រផ្សេងទៀត (ឧ។ យូធ្យូប) ហើយចុចយល់ព្រម',
  "Announcement of information":'សេចក្តីជូនដំណឹងអំពីព័ត៌មាន',
  "Sell products, supply items to the market":'លក់ផលិតផលផ្គត់ផ្គង់របស់របរដល់ទីផ្សារ',
  "Provide published information and data for the industry":'ផ្តល់ព័ត៌មាននិងទិន្នន័យដែលបានផ្សព្វផ្សាយសម្រាប់ឧស្សាហកម្ម',
  "event name":'ឈ្មោះព្រឹត្តិការណ៍',
  "what is your event name?":'តើឈ្មោះព្រឹត្តិការណ៍របស់អ្នកគឺជាអ្វី?',
  "end date and time":'កាលបរិច្ឆេទបញ្ចប់និងពេលវេលា',
  "back to home":'ត្រលប់​ទៅផ្ទះ',
  "meeting":'កិច្ចរបចមុ',
  "online":'រេបលើ',
  "online event?":'ព្រឹត្តិការណ៍លើបណ្តាញ?',
  "online event":'ព្រឹត្តិការណ៍តាមអ៊ីនធឺណិត',
  "created by":'បង្កើត​ឡើង​ដោយ',
  "milestone":'ចមផាកហ៊ី',
  "start time":'ពេលវេលាចាប់ផ្តើម',
  "Select event type (online / meeting)":'ជ្រើសរើសប្រភេទព្រឹត្តិការណ៍ (តាមអ៊ីនធឺណិត / ការប្រជុំ)',
  "Event, what?, when?":'ព្រឹត្តិការណ៍, អ្វី?, នៅពេល?',
  "Event name, start time":'ឈ្មោះព្រឹត្តិការណ៍ពេលវេលាចាប់ផ្តើម',
  "Add a physical location for people to join your event. Or how to meet together in online event":'បន្ថែមទីតាំងរូបវន្តសម្រាប់ប្រជាជនចូលរួមព្រឹត្តិការណ៍របស់អ្នក។ ឬវិធីជួបគ្នាក្នុងព្រឹត្តិការណ៍អនឡាញ',
  "Event, where?":'ព្រឹត្តិការណ៍, កន្លែងណា?',
  "Provide more information about your event so guests know what to expect.":'ផ្តល់ព័ត៌មានបន្ថែមអំពីព្រឹត្តិការណ៍របស់អ្នកដូច្នេះភ្ញៀវដឹងពីអ្វីដែលត្រូវរំពឹង។',
  "Event, how?":'ព្រឹត្តិការណ៍, តើធ្វើដូចម្តេច?',
  "Everyone meeting on the internet.":'អ្នករាល់គ្នាប្រជុំនៅលើអ៊ីនធឺណិត។',
  "Everyone meet together at a specific location.":'អ្នករាល់គ្នាជួបគ្នានៅទីតាំងជាក់លាក់មួយ។',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'ប្រសិនបើអ្នកបានភ្លេចលេខសំងាត់ចាស់របស់អ្នកទាំងស្រុង។ សូមចាកចេញហើយប្រើមុខងារ "{1}" ដើម្បីកំណត់ពាក្យសម្ងាត់ឡើងវិញបើចាំបាច់',
  "Please select the language you want as the display language on the application":'សូមជ្រើសរើសភាសាដែលអ្នកចង់បានជាភាសាបង្ហាញនៅលើកម្មវិធី',
  "farm":'ដីរេសចមការ',
  "recover login password":'សង្គ្រោះពាក្យសម្ងាត់ចូល',
  "individual":'ដេលដាច់បីក្នា',
  "you are":'អ្នកគឺ',
  "organization name":'ឈ្មោះ​អង្គការ',
  "attention: please check your activation email in the spam area":'យកចិត្តទុកដាក់ៈសូមពិនិត្យមើលអ៊ីម៉ែលធ្វើឱ្យសកម្មរបស់អ្នកនៅក្នុងតំបន់សារឥតបានការ',
  "with your email":'ជាមួយអ៊ីម៉ែលរបស់អ្នក',
  "no products, please add products to your page":'គ្មានផលិតផលទេសូមបន្ថែមផលិតផលទៅទំព័ររបស់អ្នក',
  "priceset history management":'ការគ្រប់គ្រងប្រវត្ដិសាស្ដ្រ',
  "product configuration":'ការកំណត់រចនាសម្ព័ន្ធផលិតផល',
  "events in category":'ព្រឹត្តិការណ៍ក្នុងប្រភេទ',
  "no events":'គ្មានព្រឹត្តិការណ៍',
  "select / create":'ជ្រើសរើស / បង្កើត',
  "co-admins":'សហការទទួលខុសត្រូវ',
  "weekly":'រាល់សបា្ដហ៍',
  "quarterly":'ក្នុងស្រុក',
  "yearly":'ប្រចាំឆ្នាំ',
  "the event will repeat in":'ព្រឹត្តិការណ៍នេះនឹងធ្វើឡើងវិញ',
  "finish time":'ពេលវេលាបញ្ចប់',
  "updating date":'កាលបរិច្ឆេទធ្វើបច្ចុប្បន្នភាព',
  "price is valid from the date":'តម្លៃមានសុពលភាពចាប់ពីថ្ងៃ',
  "contract type":'ប្រភេទកិច្ចសន្យា',
  "reminder":'ការ​រំលឹក',
  "type of event?":'ប្រភេទព្រឹត្តិការណ៍?',
  "speakers":'វាអត្ថនៈ',
  "select user":'ជ្រើសរើសអ្នកប្រើប្រាស់',
  "event_types":'ប្រភេទព្រឹត្តិការណ៍?',
  "something is wrong, want to delete it?":'មានលទ្ធភាពខុសចង់លុបវាចោល?',
  "successfully deleted":'បានលុបដោយជោគជ័យ',
  "are you sure to delete this?":'តើអ្នកប្រាកដជាលុបវាចោលឬ?',
  "deletion is ignored":'ការលុបមិនត្រូវបានអើពើ',
  "insert here":'បញ្ចូលនៅទីនេះ',
  "not milestone":'មិនសំខាន់ទេ',
  "The next event is scheduled in":'ព្រឹត្តិការណ៍បន្ទាប់ត្រូវបានកំណត់ពេលវេលា',
  "The next event is scheduled to take place at {1}":'ព្រឹត្តិការណ៍បន្ទាប់គ្រោងនឹងធ្វើឡើងនៅ {1}',
  "products can be searched":'ផលិតផលអាចត្រូវបានស្វែងរក',
  "your product has been declared complete, you can put it on the store":'ផលិតផលរបស់អ្នកត្រូវបានប្រកាសឱ្យបានពេញលេញអ្នកអាចដាក់វានៅលើហាង',
  "Do you want to activate this product?":'តើអ្នកចង់ធ្វើឱ្យផលិតផលនេះសកម្មទេ?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you":'ផលិតផលនេះនឹងបន្ថែមទៅកាតាឡុកដែលបានធ្វើឱ្យសកម្មរបស់អ្នកហើយនឹងអាចមើលឃើញអ្នកទិញបាន។ អ្នកក៏នឹងចាប់ផ្តើមទទួលប្រាក់ក្នុងស្រុកសម្រាប់ផលិតផលនេះ។ សូមអរគុណ',
  "profiles":'រុសផាត',
  "incoming requests management":'ការគ្រប់គ្រងសំណើចូល',
  "waiting members":'សមាជិករង់ចាំ',
  "purchases":'ការទិញ',
  "quote prices":'តម្លៃដកស្រង់',
  "market prices":'តម្លៃទីផ្សារ',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly":'ព័ត៌មានទំនាក់ទំនងមិនត្រូវបានប្រកាសទេវាជាការល្អក្នុងការប្រកាសព័ត៌មានទំនាក់ទំនងដូច្នេះអតិថិជនអាចទាក់ទងអ្នកដោយផ្ទាល់',
  "members management":'គណៈគ្រប់គ្រងការសមាជិក',
  "partners":'ដៃគូ',
  "vendor":'ឈ្ផួញ',
  "vendors":'ផហ',
  "customer":'ផ្ញ្យេវរសី',
  "customers":'អតិថិជន',
  "contact books":'សៀវភៅទំនាក់ទំនង',
  "add new contact book":'បន្ថែមសៀវភៅទំនាក់ទំនងថ្មី',
  "connect to user":'ភ្ជាប់ទៅអ្នកប្រើប្រាស់',
  "connect to organization":'ភ្ជាប់ទៅអង្គការ',
  "connect to partner":'ភ្ជាប់ទៅដៃគូ',
  "new contact book":'សៀវភៅទំនាក់ទំនងថ្មី',
  "new partner":'ដៃគូថ្មី',
  "partner type":'ប្រភេទដៃគូ',
  "partner name":'ឈ្មោះដៃគូ',
  "edit contact book":'កែសម្រួលសៀវភៅទំនាក់ទំនង',
  "edit partner":'កែសម្រួលដៃគូ',
  "all partners":'ដៃគូទាំងអស់',
  "add new partner":'បន្ថែមដៃគូថ្មី',
  "for sales notes":'សម្រាប់ការលក់កំណត់ត្រាការលក់',
  "for purchase notes":'សម្រាប់កំណត់ត្រាទិញ',
  "notes":'កំណត់ចំណាំ',
  "Note: The effective date of the price being updated is different from the price entry date":'សម្គាល់ៈកាលបរិច្ឆេទដែលមានប្រសិទ្ធភាពនៃតម្លៃដែលត្រូវបានធ្វើបច្ចុប្បន្នភាពគឺខុសគ្នាពីកាលបរិច្ឆេទចូលតម្លៃ',
  "product catalog":'កាតាឡុកផលិតផល',
  "group by":'ដាក់ជាក្រុមដោយ',
  "type of product use for sales / purchases":'ប្រភេទនៃការប្រើប្រាស់ផលិតផលសម្រាប់ការលក់ / ការទិញ',
  "sales / purchases":'ការលក់ / ការទិញ',
  "added":'អមតាន',
  "feedin organizations":'អង្គការចំណី',
  "refresh chart":'ធ្វើឱ្យស្រស់',
  "incoming quotes":'សម្រង់ចូល',
  "purchasing planning":'ការទិញការទិញ',
  "manage outgoing quotes":'គ្រប់គ្រងសម្រង់ចេញ',
  "product group title":'ចំណងជើងក្រុមផលិតផល',
  "Successfully Sign Up":'ចុះឈ្មោះដោយជោគជ័យ',
  "redirect in {1} seconds":'ប្តូរទិសក្នុង {1} វិនាទី',
  "Thank you for registering, Website will redirect to login page after 3 seconds":'សូមអរគុណចំពោះការចុះឈ្មោះគេហទំព័រនឹងប្តូរទិសឱ្យចូលទំព័រចូលបន្ទាប់ពី 3 វិនាទី',
  "add products":'បន្ថែមផលិតផល',
  "price offer partners":'ដៃគូផ្តល់ជូនតម្លៃ',
  "Select the products for which you want to send price information to your partner":'ជ្រើសរើសផលិតផលដែលអ្នកចង់ផ្ញើព័ត៌មានតម្លៃទៅដៃគូរបស់អ្នក',
  "products for partner":'ផលិតផលសម្រាប់ដៃគូ',
  "Note: the list below shows only partners linked to the organizations on FeedIn":'ដៃគូនៅក្នុងបញ្ជីនេះមាននៅលើប្រព័ន្ធចំណី',
  "bookmarks":'ចំណាំ',
  "my space":'ទី​កន្លែង​របស់​ខ្ញុំ',
  "all bookmarks":'កំណត់ចំណាំទាំងអស់',
  "quotations for partners":'ការដកស្រង់សម្រាប់ដៃគូ',
  "favourites":'សេវesព្រះអង្គ',
  "vietnam market":'ទីផ្សារប្រទេសវៀតណាម',
  "workspace":'កន្លែងធ្វើការ',
  "updates":'បច្ចុប្បន្នភាព',
  "for search optimization":'សម្រាប់ការស្វែងរកការស្វែងរក',
  "to optimize product display":'ដើម្បីបង្កើនប្រសិទ្ធភាពការបង្ហាញផលិតផល',
  "to group products with the same properties, compare prices":'ដើម្បីដាក់ផលិតផលជាក្រុមដែលមានលក្ខណៈសម្បត្តិដូចគ្នាប្រៀបធៀបតម្លៃ',
  "be sent from partners":'ត្រូវបានផ្ញើចេញពីដៃគូ',
  "owned":'ជាម្ចាស់',
  "Prices below are average prices with selected location or selected period is All":'តម្លៃខាងក្រោមគឺជាតម្លៃជាមធ្យមដែលមានទីតាំងដែលបានជ្រើសរើសឬរយៈពេលដែលបានជ្រើសរើសគឺទាំងអស់',
  "Can follow the latest news and events in the industry":'អាចអនុវត្តតាមព័ត៌មាននិងព្រឹត្តិការណ៍ចុងក្រោយបង្អស់នៅក្នុងឧស្សាហកម្មនេះ',
  "Explore dataset and charts":'ស្វែងយល់ពីសំណុំទិន្នន័យនិងគំនូសតាង',
  "to help you manage":'ដើម្បីជួយអ្នកក្នុងការគ្រប់គ្រង',
  "or create an organization as":'ឬបង្កើតអង្គភាពមួយ',
  "farmer":'កសិករ',
  "buyer":'អ្នកតិញរបុស',
  "Create events to reach the commodity market":'បង្កើតព្រឹត្តិការណ៍ដើម្បីឈានដល់ទីផ្សារទំនិញ',
  "Create news channels to announce and share information to the commodity market":'បង្កើតឆានែលព័ត៌មានដើម្បីប្រកាសនិងចែករំលែកព័ត៌មានទៅទីផ្សារទំនិញ',
  "Cooperate with FeedIn to share and provide commodity data to customers":'សហការជាមួយចំណីអាហារដើម្បីចែករំលែកនិងផ្តល់ទិន្នន័យទំនិញដល់អតិថិជន',
  "Please contact the administrator of FeedIn":'សូមទាក់ទងអ្នកគ្រប់គ្រងចំណី',
  "name required more than {1} characters":'ឈ្មោះត្រូវការច្រើនជាងតួអក្សរ {1}',
  "set subscription":'កំណត់ការជាវ',
  "subscribed":'បានជាវ',
  "edit contact":'កែសម្រួលទំនាក់ទំនង',
  "Information gathered from the declaration in the contact information":'ព័ត៌មានដែលប្រមូលបានពីការប្រកាសនៅក្នុងព័ត៌មានទំនាក់ទំនង',
  "The information will be linked to the information of partner":'ព័ត៌មាននឹងត្រូវបានផ្សារភ្ជាប់ជាមួយនឹងព័ត៌មានរបស់ដៃគូ',
  "product had basic information and ready to launch":'ផលិតផលមានព័ត៌មានមូលដ្ឋានហើយត្រៀមខ្លួនដំណើរការ',
  "internal use":'ការប្រើប្រាស់ផ្ទៃក្នុង',
  "{launch} the product to display on the store":'{បើកដំណើរការ} ផលិតផលដែលត្រូវបង្ហាញនៅលើហាង',
  "OR {activate} to input the price and manage it internally":'ឬ {ធ្វើឱ្យសកម្ម} ដើម្បីបញ្ចូលតម្លៃនិងគ្រប់គ្រងវានៅខាងក្នុង',
  "launch":'បាញ់រ៉ុក្កេត',
  "activate only":'ធ្វើឱ្យសកម្មតែប៉ុណ្ណោះ',
  "{deactivate} product":'{ធ្វើឱ្យផលិតផលអសកម្ម} ផលិតផល',
  "deactivate":'អសកម្ម',
  "set effect date":'កំណត់កាលបរិច្ឆេទមានប្រសិទ្ធិភាព',
  "insert at":'បញ្ចូលនៅ',
  "expire at":'ផុតកំណត់នៅ',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.":'ទំព័រនេះមិនទាន់មានឬទំព័រនេះសម្រាប់ការប្រើប្រាស់ផ្ទៃក្នុងអ្នកត្រូវតែជាសមាជិកដើម្បីមើលមាតិកានៃទំព័រនេះ។',
  "hybrid event":'ព្រឹត្តិការណ៍កូនកាត់',
  "no data yet":'មិនទាន់មានទិន្នន័យនៅឡើយទេ',
  "Please login to view this content":'សូមចូលដើម្បីមើលមាតិកានេះ',
  "no data is available now":'មិនមានទិន្នន័យទេឥឡូវនេះ',
  "latest tracking news":'ព័ត៌មានតាមដានចុងក្រោយ',
  "activate":'ធេវីអាេយក្ផានធេវី',
  "Buy goods, collect quotes from sellers":'ទិញទំនិញប្រមូលសម្រង់ពីអ្នកលក់',
  "following charts":'ខាងក្រោមតារាង',
  "Leave your information so we can contact you again":'ទុកឱ្យព័ត៌មានរបស់អ្នកដូច្នេះយើងអាចទាក់ទងអ្នកម្តងទៀត',
  "purchase":'តិញ',
  "enter spot price":'បញ្ចូលតម្លៃចំណុច',
  "present":'អមនោយ',
  "weather":'អាកាសធាតុ',
  "Temperature - weather, tap to see more":'សីតុណ្ហាភាព - អាកាសធាតុ, ប៉ះដើម្បីមើលបន្ថែមទៀត',
  "Rain (thunder) - weather, tap to see more":'ភ្លៀង (ផ្គរលាន់) - អាកាសធាតុប៉ះដើម្បីមើលច្រើនទៀត',
  "Wind - weather, tap to see more":'ខ្យល់ - អាកាសធាតុ, ប៉ះដើម្បីមើលបន្ថែមទៀត',
  "temperature":'សីតុន្ហផាប',
  "rain":'ផ្ល្យេង',
  "wind":'បក់',
  "edit & save":'កែសម្រួលនិងរក្សាទុក',
  "create from":'បង្កើតពី',
  "remove expired":'យកចេញផុតកំណត់',
  "delete prices at":'លុបតម្លៃនៅ',
  "copy prices at":'ការថតចម្លងតម្លៃនៅ',
  "enter forward price":'បញ្ចូលតម្លៃទៅមុខ',
  "affiliate products":'ផលិតផលសម្ព័ន្ធភាព',
  "at date":'កាលបរិច្ឆេទ',
  "This pricing information will expire in":'ព័ត៌មានតម្លៃនេះនឹងផុតកំណត់នៅក្នុង',
  "This pricing information will expire at":'ព័ត៌មានតម្លៃនេះនឹងផុតកំណត់នៅ',
  "no expired date":'គ្មានកាលបរិច្ឆេទផុតកំណត់',
  "quarters":'មួយភាគបួន',
  "you have an article in progress":'អ្នកមានអត្ថបទកំពុងដំណើរការ',
  "Create an information channel to promote your information and products to everyone":'បង្កើតប៉ុស្តិ៍ព័ត៌មានដើម្បីលើកកម្ពស់ព័ត៌មាននិងផលិតផលរបស់អ្នកដល់អ្នករាល់គ្នា',
  "get {free} quotes from multiple sellers":'ទទួលបាន {ឥតគិតថ្លៃ} ដកស្រង់ចេញពីអ្នកលក់ច្រើន',
  "Tell us your Requirement":'ប្រាប់យើងពីតម្រូវការរបស់អ្នក',
  "Receive free quotes from sellers":'ទទួលបានការដកស្រង់ដោយឥតគិតថ្លៃពីអ្នកលក់',
  "Seal the Deal":'បិទកិច្ចព្រមព្រៀង',
  "Enter product/ Service name":'បញ្ចូលឈ្មោះផលិតផល / សេវាកម្ម',
  "Your email address":'អាសយដ្ឋានអ៊ីមែលរបស់អ្នក',
  "Enter your name":'បញ្ចូល​ឈ្មោះ​របស់​អ្នក',
  "Your live in":'ការផ្សាយបន្តផ្ទាល់របស់អ្នក',
  "We connect":'យើងភ្ជាប់',
  "Buyers Sellers":'អ្នកទិញនិងអ្នកលក់',
  "Trusted Platform":'វេទិកាទុកចិត្ត',
  "Safe Secure":'សុវត្ថិភាពសុវត្ថិភាព',
  "Quick Assistance":'ជំនួយរហ័ស',
  "Create events so people can participate and learn more about your organization":'បង្កើតព្រឹត្តិការណ៍ដូច្នេះប្រជាជនអាចចូលរួមនិងស្វែងយល់បន្ថែមអំពីអង្គភាពរបស់អ្នក',
  "my articles":'អត្ថបទរបស់ខ្ញុំ',
  "Use keyboard shortcuts":'ប្រើផ្លូវកាត់ក្តារចុច',
  "edited by":'កែសម្រួល​ដោយ',
  "Publishing to:":'បោះពុម្ពផ្សាយទៅ:',
  "write caption for image":'សរសេរចំណងជើងសម្រាប់រូបភាព',
  "caption of image":'ចំណងជើងនៃរូបភាព',
  "write caption for video":'សរសេរចំណងជើងសម្រាប់វីដេអូ',
  "caption of video":'ចំណងជើងនៃវីដេអូ',
  "video url is invalid!":'URL វីដេអូមិនត្រឹមត្រូវ!',
  "enter video url":'បញ្ចូល URL វីដេអូ',
  "add caption":'បន្ថែមចំណងជើង',
  "related products":'ផលិតផលដែលពាក់ព័ន្ធ',
  "products from the same organization":'ផលិតផលពីអង្គការតែមួយ',
  "get direction":'ទទួលបានទិសដៅ',
  "export purchasing data":'ទិន្នន័យទិញនាំចេញ',
  "export sales data":'ទិន្នន័យនៃការលក់នាំចេញ',
  "select page":'ជ្រើសរើសទំព័រ',
  "send requirement":'បើផ្ញើរតំរូវការ',
  "detail requirement":'តម្រូវការលំអិត',
  "attach file":'ភ្ជាប់ឯកសារ',
  "how it work":'របៀបដែលវាដំណើរការ',
  "tell us what you need by filling the form":'ប្រាប់យើងពីអ្វីដែលអ្នកត្រូវការដោយបំពេញទម្រង់',
  "receive verified supplier details":'ទទួលបានព័ត៌មានលំអិតរបស់អ្នកផ្គត់ផ្គង់ដែលបានផ្ទៀងផ្ទាត់',
  "compare quotations and seal the deal":'ប្រៀបធៀបការដកស្រង់និងបិទកិច្ចព្រមព្រៀង',
  "your information":'ព័ត៌មាន​របស់​អ្នក',
  "choosing a category will help the article stay on topic and more accessible to users":'ការជ្រើសរើសប្រភេទមួយនឹងជួយឱ្យអត្ថបទនៅលើប្រធានបទនិងអាចចូលដំណើរការបានកាន់តែច្រើនដល់អ្នកប្រើប្រាស់',
  "please add images to make the article more vivid and realistic to attract more viewers":'សូមបន្ថែមរូបភាពដើម្បីធ្វើឱ្យអត្ថបទមានលក្ខណៈរស់រវើកនិងប្រាកដនិយមដើម្បីទាក់ទាញអ្នកទស្សនាកាន់តែច្រើន',
  "data has been generate":'ទិន្នន័យត្រូវបានបង្កើត',
  "please click the {download} button below to download to your device":'សូមចុចប៊ូតុង {ទាញយក} ខាងក្រោមដើម្បីទាញយកទៅឧបករណ៍របស់អ្នក',
  "article preview":'ការពិពណ៌នាអត្ថបទជាមុន',
  "back to edit":'ត្រលប់ទៅកែសម្រួលវិញ',
  "please login so the supplier can contact you":'សូមចូលដូច្នេះអ្នកផ្គត់ផ្គង់អាចទាក់ទងអ្នកបាន',
  "product information":'ព័ត៌មាន​អំពី​ផលិតផល',
  "please provide a few details to get the most accurate response from the supplier":'សូមផ្តល់ព័ត៌មានលំអិតមួយចំនួនដើម្បីទទួលបានការឆ្លើយតបត្រឹមត្រូវបំផុតពីអ្នកផ្គត់ផ្គង់',
  "article has no images":'អត្ថបទមិនមានរូបភាពទេ',
  "this channel belongs to":'ឆានែលនេះជាកម្មសិទ្ធិរបស់',
  "unit price":'តម្លៃ​ឯកតា',
  "posted at":'បានចុះផ្សាយនៅ',
  "respond at":'ឆ្លើយតបនៅ',
  "view website":'មើលគេហទំព័រ',
  "send message":'ផ្ញើ​សារ',
  "resend request":'ស្នើសុំឡើងវិញ',
  "close request":'សំណើបិទ',
  "view detail":'មើលលំអិត',
  "hide detail":'លាក់ពត៌មានលំអិត',
  "not sure":'មិន​ច្បាស់',
  "sure":'ច្បាស់លាស់',
  "you have found a supplier for this product and you want to close this request":'អ្នកបានរកឃើញអ្នកផ្គត់ផ្គង់សម្រាប់ផលិតផលនេះហើយអ្នកចង់បិទសំណើនេះ',
  "are you sure":'តើ​អ្នក​ប្រាកដ​ឬ​អត់',
  "company name (if you buy for the company)":'ឈ្មោះក្រុមហ៊ុន: (បើមានប្រសិនបើអ្នកកំពុងទិញក្រុមហ៊ុន)',
  "Request a quote / Order":'ស្នើសុំសម្រង់ / បញ្ជាទិញ',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'Avatar ត្រូវបានជ្រើសរើសពីភ្នំពេញប៉ុស្តិ៍ត្រលប់មកវិញហើយបន្ថែមរូបភាពដើម្បីធ្វើឱ្យប្រកាសកាន់តែរស់រវើកនិងទាក់ទាញ',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'ទទួលបានការដកស្រង់ចុងក្រោយនិងត្រឹមត្រូវបំផុតពីអ្នកផ្គត់ផ្គង់សម្រាប់ផលិតផលដែលអ្នកចាប់អារម្មណ៍',
  "filtered by":'ត្រងដោយ',
  "my order request":'ការបញ្ជាទិញបញ្ជាទិញរបស់ខ្ញុំ',
  "keyword: request id, organization id (name)":'ពាក្យគន្លឹះ: លេខសម្គាល់ស្នើសុំលេខសម្គាល់របស់អង្គការ (ឈ្មោះ)',
  "order requests management":'ដីកាបង្គាប់ស្នើសុំការគ្រប់គ្រង',
  "press enter to create new page":'ចុច Enter ដើម្បីបង្កើតទំព័រថ្មី',
  "add new page":'បន្ថែមទំព័រថ្មី',
  "product info":'ព័ត៌មានផលិតផល',
  "order request type":'ប្រភេទស្នើសុំបញ្ជាទិញ',
  "request type":'ប្រភេទស្នើសុំ',
  "received at":'បានទទួលនៅ',
  "min quantity":'បរិមាណអប្បបរមា',
  "productinfo":'ព័ត៌មានផលិតផល',
  "deleted":'បានលុប',
  "alert drafting article description":'អត្ថបទនេះអាចមានការផ្លាស់ប្តូរហើយមិនទាន់ត្រូវបានផ្សព្វផ្សាយនៅឡើយទេ។ អ្នកអាចចុចនៅទីនេះដើម្បីបន្តធ្វើសេចក្តីព្រាងនិងបោះពុម្ពផ្សាយមាតិកា។',
  "delete article":'លុបអត្ថបទ',
  "language linking":'ការភ្ជាប់ភាសា',
  "articles in other languages":'អត្ថបទជាភាសាផ្សេងទៀត',
  "Are you sure remove this related article?":'តើអ្នកប្រាកដថាយកអត្ថបទដែលទាក់ទងនេះចេញទេ?',
  "Type the title of the article in other languages to search and add the article you want to link to.":'វាយចំណងជើងនៃអត្ថបទជាភាសាផ្សេងទៀតដើម្បីស្វែងរកនិងបន្ថែមអត្ថបទដែលអ្នកចង់ភ្ជាប់ទៅ។',
  "linked articles":'អត្ថបទភ្ជាប់',
  "edit article setting":'កែសម្រួលការកំណត់អត្ថបទ',
  "owner by":'ម្ចាស់ដោយ',
  "grateful description":'យើងសូមថ្លែងអំណរគុណយ៉ាងជ្រាលជ្រៅចំពោះអ្នកឧបត្ថម្ភរបស់យើង',
  "You can click \"See more\" to see this full information":'អ្នកអាចចុច "មើលបន្ថែម" ដើម្បីមើលព័ត៌មានពេញលេញនេះ',
  "your donation description":'នេះគឺជាអំណោយរបស់អ្នក។ ប្រសិនបើមានអ្វីខុសសូមទាក់ទងរដ្ឋបាលយុទ្ធនាការ។ យើងសូមអរគុណចំពោះការចូលរួមចំណែករបស់អ្នក។',
  "contribution chart":'តារាងវិភាគទាន',
  "list of contributors":'បញ្ជីអ្នកចូលរួមវិភាគទាន',
  "donated items":'របស់របរដែលបានបរិច្ចាគ',
  "pending items":'ធាតុដែលមិនទាន់សម្រេច',
  "search with donation code":'ស្វែងរកលេខកូដបរិច្ចាគ',
  "equivalent to":'ស្មើនឹង',
  "has a capacity of":'មានសមត្ថភាពរបស់',
  "my donations":'អំណោយរបស់ខ្ញុំ',
  "thank you card":'សូមអរគុណកាត',
  "sincerely thank you":'សូមអរគុណដោយស្មោះ',
  "supported the donation campaign":'បានគាំទ្រយុទ្ធនាការបរិច្ចាគ',
  "money / goods":'លុយ / ទំនិញ',
  "Sincere thanks to the benefactors for accompanying us to create a picture of kindness.":'សូមថ្លែងអំណរគុណចំពោះអ្នកទទួលបានគុណប្រយោជន៍ចំពោះអ្នកផ្តល់ផលប្រយោជន៍សម្រាប់ការមកជាមួយយើងដើម្បីបង្កើតរូបភាពនៃសេចក្តីសប្បុរស។',
  "Wishing you good health, peace and happiness.":'ជូនពរឱ្យអ្នកមានសុខភាពល្អសន្តិភាពនិងសុភមង្គល។',
  "Kind regards,":'សូមគោរព,',
  "poll question":'សំណួរនៃការស្ទង់មតិ',
  "latest contributions":'ការចូលរួមវិភាគទានចុងក្រោយ',
  "news update":'ព័ត៌មានបច្ចុប្បន្នភាព',
  "export as PNG":'នាំចេញជា PNG',
  "register now":'ចុះឈ្មោះ​ឥឡូវនេះ',
  "would you like to donate another value":'តើអ្នកចង់បរិច្ចាគតម្លៃមួយផ្សេងទៀតទេ?',
  "choose the package that you can donate":'ជ្រើសរើសកញ្ចប់ដែលអ្នកអាចបរិច្ចាគ',
  "register to contribute":'ចុះឈ្មោះដើម្បីចូលរួមវិភាគទាន',
  "copy path":'ចម្លងផ្លូវ',
  "click here to start writing":'ចុចត្រង់នេះដើម្បីចាប់ផ្តើមសរសេរ',
  "impact-categories":'ប្រភេទផលប៉ះពាល់',
  "host request":'ស្នើសុំឱ្យក្លាយជាអ្នករៀបចំ',
  "host-request":'ស្នើសុំឱ្យក្លាយជាអ្នករៀបចំ',
  "adding an address will help people find you":'ការបន្ថែមអាសយដ្ឋាននឹងជួយមនុស្សរកអ្នក',
  "load more":'ផ្ទុក​បន្ថែម',
  "waiting-members":'សមាជិករង់ចាំ',
  "add host":'បន្ថែមម៉ាស៊ីន',
  "Are you sure delete this host?":'តើអ្នកពិតជាលុបម៉ាស៊ីននេះឬ?',
  "confirmation":'ការបហ្ជាក់',
  "Deleted {type} are gone forever. Are you sure?":'បានលុប {ប្រភេទ} ត្រូវបានបាត់បង់ជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "{type} name":'{វាយ} ឈ្មោះ',
  "you didn\'t enter the {type} name correctly":'អ្នកមិនបានបញ្ចូលឈ្មោះ {វាយ} ត្រឹមត្រូវទេ',
  "select tags":'ជ្រើសរើសស្លាក',
  "finished":'បញ្ចប់',
  "Allow contribution information to be visible to the public":'អនុញ្ញាតឱ្យព័ត៌មានវិភាគទានដែលអាចមើលឃើញជាសាធារណៈ',
  "Allow charity work information to be visible to the public":'អនុញ្ញាតឱ្យព័ត៌មានការងារសប្បុរសធម៌អាចមើលឃើញជាសាធារណៈ',
  "Allows to be searched by recruitment agencies":'អនុញ្ញាតឱ្យស្វែងរកដោយភ្នាក់ងារជ្រើសរើសពលកម្ម',
  "clip_url":'តំណភ្ជាប់ខ្លី',
  "edit owner":'កែសម្រួលម្ចាស់',
  "ref_ids":'តំណភ្ជាប់យុទ្ធនាការ',
  "edit campaign link":'កែសម្រួលការភ្ជាប់យុទ្ធនាការ',
  "add target details":'បន្ថែមព័ត៌មានលម្អិតគោលដៅ',
  "select unit":'ជ្រើសរើសឯកតា',
  "select item":'ជ្រើសរើសធាតុ',
  "votes":'សម្លេងឆ្នោត',
  "shares":'ចែករំលែក',
  "giving items":'ផ្តល់របស់របរ',
  "search with giving code":'ស្វែងរកជាមួយលេខកូដ',
  "latest giving":'ការផ្តល់ចុងក្រោយ',
  "---Choose One---":'ជ្រើសរើសមួយ',
  "Linked Pages":'ទំព័រភ្ជាប់',
  "contact information so people can reach out when they need help":'ព័ត៌មានទំនាក់ទំនងដូច្នេះប្រជាជនអាចឈោងចាប់នៅពេលពួកគេត្រូវការជំនួយ',
  "detail target":'គោលដៅលំអិត',
  "click to select":'ចុចដើម្បីជ្រើសរើស',
  "not available":'មិនអាច',
  "summary report":'របាយការណ៍សង្ខេប',
  "click here for the link":'ចុចត្រង់នេះសំរាប់តំណ',
  "your campaign is currently not associated with any giving campaign, click here to connect":'យុទ្ធនាការរបស់អ្នកបច្ចុប្បន្នមិនមានទំនាក់ទំនងជាមួយយុទ្ធនាការផ្តល់ទេសូមចុចត្រង់នេះដើម្បីភ្ជាប់',
  "link to giving":'ភ្ជាប់ទៅការឱ្យ',
  "search with registration code":'ស្វែងរកជាមួយលេខកូដចុះឈ្មោះ',
  "giving chart":'ការផ្តល់តារាង',
  "list of giving":'បញ្ជីនៃការឱ្យ',
  "your registered list":'បញ្ជីដែលបានចុះឈ្មោះរបស់អ្នក',
  "indexed by wishare":'បានធ្វើលិបិក្រមដោយការចង់បាន',
  "recruiting volunteers":'ជ្រើសរើសអ្នកស្ម័គ្រចិត្ត',
  "type of recruitment":'ប្រភេទនៃការជ្រើសរើសបុគ្គលិក',
  "job requirements":'តម្រូវការការងារ',
  "time to start work":'ពេលវេលាដើម្បីចាប់ផ្តើមការងារ',
  "application deadline":'ថ្ងៃ​ផុតកំណត់​កម្មវិធី',
  "Create an organization to share and raise money for your charity":'បង្កើតអង្គការមួយដើម្បីចែករំលែកនិងរៃអង្គាសប្រាក់សម្រាប់សប្បុរសធម៌របស់អ្នក',
  "Create activity to share your campaigns":'បង្កើតសកម្មភាពដើម្បីចែករំលែកយុទ្ធនាការរបស់អ្នក',
  "create donation campaign":'បង្កើតយុទ្ធនាការបរិច្ចាគ',
  "Create a donation campaign to call for support from donors":'បង្កើតយុទ្ធនាការបរិច្ចាគដើម្បីអំពាវនាវឱ្យមានការគាំទ្រពីម្ចាស់ជំនួយ',
  "create giving campaign":'បង្កើតយុទ្ធនាការផ្តល់ឱ្យ',
  "Create a giving campaign to help those in need":'បង្កើតយុទ្ធនាការផ្តល់ឱ្យដើម្បីជួយអ្នកដែលខ្វះខាត',
  "create recruitment campaign":'បង្កើតយុទ្ធនាការជ្រើសរើសពលករ',
  "Create a recruitment campaign to find volunteers for the organization":'បង្កើតយុទ្ធនាការជ្រើសរើសបុគ្គលិកដើម្បីស្វែងរកអ្នកស្ម័គ្រចិត្តសម្រាប់អង្គការ',
  "Create an event to connect with other benefactors or volunteer organizations":'បង្កើតព្រឹត្តិការណ៍មួយដើម្បីភ្ជាប់ជាមួយអ្នកផលិតគុណផ្សេងទៀតឬអង្គការស្ម័គ្រចិត្ត',
  "Create a channel to share information about your charity program":'បង្កើតឆានែលដើម្បីចែករំលែកព័ត៌មានអំពីកម្មវិធីសប្បុរសធម៌របស់អ្នក',
  "who can post":'អ្នកណាអាចប្រកាស',
  "everyone":'មនុសសអាេយអ្នករាល់គ្នា',
  "administrators":'អ្នកដ្ឋានការអ្នកគ្រប់គ្រង',
  "event type":'ប្រភេទព្រឹត្តិការណ៍',
  "npo":'អង្គការមិនស្វែងរកប្រាក់ចំណេញ',
  "ngo":'អង្គការក្រៅរដ្ឋាភិបាល',
  "association":'សមាកម',
  "edit cd type":'កែសម្រួលប្រភេទស៊ីឌី',
  "Channel members only":'សមាជិកតែប៉ុណ្ណោះ',
  "Channel admins only":'ឆានែលអ្នកគ្រប់គ្រងតែប៉ុណ្ណោះ',
  "Everyone who has an account at wishare":'អ្នកដែលមានគណនីដែលមានសេចក្តីប្រាថ្នា',
  "most view":'ទិដ្ឋភាពភាគច្រើន',
  "donation types":'ប្រភេទបរិច្ចាគ',
  "giving types":'ការផ្តល់ប្រភេទ',
  "occupation types":'ប្រភេទមុខរបរ',
  "reserved job types":'ប្រភេទការងារដែលបានបម្រុងទុក',
  "recruitment types":'ប្រភេទជ្រើសរើសបុគ្គលិក',
  "what":'អ្វី?',
  "when":'ពេលណា​ ?',
  "whose":'របស់អ្នកណា?',
  "how":'យ៉ាងម៉េច?',
  "An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.":'អង្គការគឺជាក្រុមមួយសមាគមសមាគមសហគមន៍ ... ដែលបានបង្កើតឡើងដើម្បីប្រមូលសហគមន៍និងធនធានសង្គមនិងអនុវត្តគម្រោងសង្គម។',
  "Organize activities throughout, be recognized for the group\'s activities.":'រៀបចំសកម្មភាពនៅទូទាំង, ត្រូវបានទទួលស្គាល់សម្រាប់សកម្មភាពរបស់ក្រុម។',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.":'អង្គការត្រូវបានបង្កើតឡើងដោយបុគ្គលនិងអាចត្រូវបានបង្កើតឡើងដោយ 1 ដល់បុគ្គលឬអង្គការជាច្រើនដែលអាចត្រូវបានប្រើជាសាខារបស់អង្គការមួយផ្សេងទៀត។',
  "Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.":'អង្គការការប្រើប្រាស់ឧបករណ៍: ការជ្រើសរើសព្រឹត្តិការណ៍បណ្តាញនៃប្រព័ន្ធប្រតិបត្តិការប្រតិបត្តិការអង្គការមានសកម្មភាព 1 ឬច្រើនជាងនេះដើម្បីរៀបចំគម្រោងយោងទៅតាមគោលបំណងរបស់ពួកគេ។',
  "Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.":'សកម្មភាពគឺជាគម្រោងផលប៉ះពាល់សង្គមដែលត្រូវបានបង្កើតឡើងដើម្បីបម្រើបេសកកម្មជាក់លាក់មួយក្នុងគោលបំណងធ្វើឱ្យមានផលប៉ះពាល់ដល់សង្គម។',
  "Activities are created and maintained throughout, recorded during the operation of the project.":'សកម្មភាពត្រូវបានបង្កើតឡើងនិងរក្សានៅទូទាំងបានកត់ត្រាក្នុងកំឡុងពេលប្រតិបត្តិការរបស់គម្រោង។',
  "It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...":'វាអាចត្រូវបានចាប់ផ្តើមជាគម្រោងរបស់បុគ្គលឯករាជ្យមួយប៉ុន្តែសម្រាប់ជាប្រយោជន៍នៃសង្គមការប្រើប្រាស់ធនធានសហគមន៍និងនិរន្តរភាពរយៈពេលវែងអាចត្រូវបានធ្វើឱ្យឃើញ។ សកម្មភាពគួរតែត្រូវបានបង្កើតដោយក្រុមសមាគមសហគមន៍ ...',
  "Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.":'សកម្មភាពដោយប្រើឧបករណ៍: បរិច្ចាគផ្តល់ឱ្យឱ្យបានឆ្ងាយដើម្បីធ្វើឱ្យដំណើរការប្រតិបត្តិការមានតម្លាភាពកត់ត្រាព័ត៌មានការចូលរួមចំណែកដើម្បីធ្វើឱ្យប៉ះពាល់ដល់ទ្រឹស្តីនៃការផ្លាស់ប្តូរ។',
  "Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.":'ការបរិច្ចាគគឺជាព្រឹត្តិការណ៍មួយដែលជាយុទ្ធនាការមួយដែលធ្វើឡើងក្នុងរយៈពេលកំណត់ដើម្បីកៀរគរធនធានសម្រាប់សកម្មភាពនេះ។',
  "Donations are made over a limited period of time according to the scope of each phase of the project.":'ការបរិច្ចាគត្រូវបានធ្វើឡើងក្នុងរយៈពេលកំណត់មួយស្របតាមវិសាលភាពនៃដំណាក់កាលនីមួយៗនៃដំណាក់កាលនៃគម្រោង។',
  "Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.":'ការបរិច្ចាគត្រូវបានបង្កើតឡើងពីសកម្មភាពហើយចងភ្ជាប់ទៅនឹងយុទ្ធនាការផ្តល់ជូនសម្រាប់តម្លាភាពនៃធនធានកៀរគរ។',
  "Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.":'ការផ្តល់គឺជាព្រឹត្តិការណ៍មួយដែលជាយុទ្ធនាការមួយដែលធ្វើឡើងក្នុងរយៈពេលកំណត់ក្នុងគោលបំណងដើម្បីស្វែងរកនិងផ្តល់ធនធានប្រមូលផ្តុំការបង្កើតតម្លាភាពសម្រាប់សកម្មភាព។',
  "Giving takes place over a finite period of time according to the scope of each phase of the project.":'ការផ្តល់ឱ្យកើតឡើងក្នុងរយៈពេលកំណត់យោងទៅតាមវិសាលភាពនៃដំណាក់កាលនីមួយៗនៃគម្រោង។',
  "Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.":'ការឱ្យត្រូវបានផ្តល់ឱ្យពីសកម្មភាពហើយភ្ជាប់នឹងយុទ្ធនាការបរិច្ចាគដើម្បីធ្វើឱ្យធនធានផ្តល់មូលនិធិមានតម្លាភាព។',
  "Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.":'ការជ្រើសរើសបុគ្គលិកគឺជាមុខងារបង្កើតព័ត៌មានស្នើសុំជ្រើសរើសក្នុងគោលបំណងដើម្បីកៀរគរធនធានមនុស្សសម្រាប់អង្គការនិងសកម្មភាព។',
  "Recruitment activities have a timeline according to the development stage of the organization.":'សកម្មភាពជ្រើសរើសបុគ្គលិកមានពេលវេលាកំណត់យោងទៅតាមដំណាក់កាលអភិវឌ្ឍន៍របស់អង្គការ។',
  "Recruitment is made from Groups, Associations, Community...":'ការជ្រើសរើសបុគ្គលិកត្រូវបានផលិតចេញពីក្រុមសមាគមសហគមន៍ ...',
  "Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.":'ព្រឹត្តិការណ៍គឺជាសកម្មភាពមួយសម្រាប់គោលបំណងចែករំលែកព័ត៌មានព្រឹត្តិការណ៍ការកត់ត្រាការកត់ត្រាវឌ្ឍនភាពសម្រាប់អ្នកចូលរួមនិងសហគមន៍ដែលចាប់អារម្មណ៍។',
  "The event takes place over a finite period of time.":'ព្រឹត្តិការណ៍នេះធ្វើឡើងក្នុងរយៈពេលកំណត់មួយ។',
  "Events are created from Groups, Guilds, Communities...":'ព្រឹត្តិការណ៍ត្រូវបានបង្កើតឡើងពីក្រុម, Guilds, សហគមន៍ ...',
  "Information channel is a function to support sharing and publishing information of social activities.":'ប៉ុស្តិ៍ពត៌មានគឺជាមុខងារដើម្បីគាំទ្រការចែករំលែកនិងការផ្សព្វផ្សាយព័ត៌មានអំពីសកម្មភាពសង្គម។',
  "Information channels are maintained throughout.":'ប៉ុស្តិ៍ព័ត៌មានត្រូវបានរក្សាទុកនៅទូទាំង។',
  "Groups, Associations, Community... can set up 1 or more channels.":'ក្រុមសមាគមសមាគមសហគមន៍ ... អាចរៀបចំបណ្តាញចំនួន 1 ឬច្រើន។',
  "denied":'ដេលតោយយកមិន',
  "applied for the job":'បានអនុវត្តសម្រាប់ការងារ',
  "your donation will be sent to":'ការបរិច្ចាគរបស់អ្នកនឹងត្រូវបានផ្ញើទៅ',
  "register donation":'ចុះឈ្មោះការបរិច្ចាគ',
  "alias":'ក្លេង',
  "required information":'ព័ត៌មានចាំបាច់',
  "date of birth":'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
  "required fields":'វាលដែលត្រូវការ',
  "Please follow these instructions to complete the donation process":'សូមអនុវត្តតាមការណែនាំទាំងនេះដើម្បីបញ្ចប់ដំណើរការបរិច្ចាគ។',
  "Thank you benefactors, when transferring, remember to specify the structure of the transfer as":'សូមអរគុណអ្នកដែលមានគុណអ្នកផលិតជីវិតនៅពេលដែលផ្ទេរ, ចងចាំថាត្រូវបញ្ជាក់រចនាសម្ព័ន្ធនៃការផ្ទេរប្រាក់ដូចជា:',
  "your name - donation name":'ឈ្មោះរបស់អ្នក - ឈ្មោះបរិច្ចាគ',
  "Click the Register donate button to complete and receive the donation code":'ចុចប៊ូតុង "ចុះឈ្មោះដើម្បីបរិច្ចាគ" ដើម្បីបំពេញនិងទទួលបានលេខកូដអំណោយ',
  "text":'អត្ថបត',
  "radio":'ជ្រើសរើសមួយ',
  "checkbox":'ជ្រើសរើសយកជាច្រើន',
  "textarea":'តំបន់អត្ថបទ',
  "boolean":'ត្រូវ​ខុស',
  "conversion required":'ទាមទារការប្រែចិត្តជឿ',
  "limited":'រចក',
  "complete registration":'ការចុះឈ្មោះពេញលេញ',
  "You have just completed the registration, please follow the donation steps to complete the donation process for campaign":'អ្នកទើបតែបានបញ្ចប់ការចុះឈ្មោះសូមអនុវត្តតាមជំហានផ្តល់ជំនួយដើម្បីបញ្ចប់ដំណើរការបរិច្ចាគសម្រាប់យុទ្ធនាការ',
  "description donation code":'នេះគឺជាលេខកូដអំណោយរបស់អ្នកសម្រាប់យុទ្ធនាការប្រសិនបើអ្នកមានគណនីនៅពេលមានបំណងអ្នកអាចពិនិត្យមើលវានៅក្នុងផ្នែកព័ត៌មានឬ "ការដឹងគុណ" ប្រសិនបើអ្នកបានបញ្ចប់ការបរិច្ចាគរបស់អ្នកជាមួយលេខកូដនេះ',
  "Thank you for your contributions to make this life better":'សូមអរគុណចំពោះការចូលរួមចំណែករបស់អ្នកក្នុងការធ្វើឱ្យជីវិតនេះកាន់តែប្រសើរឡើង',
  "You can click the \"Back campaign\" button to return to the campaign":'អ្នកអាចចុចប៊ូតុង "ថយក្រោយយុទ្ធនាការ" ដើម្បីត្រឡប់ទៅយុទ្ធនាការវិញ',
  "back campaign":'យុទ្ធនាការត្រឡប់មកវិញ',
  "donation input":'ការបញ្ចូលការបរិច្ចាគ',
  "create new post":'បង្កើតប្រកាសថ្មី',
  "post an article":'បញ្ចូលអត្ថបទមួយ',
  "you want more":'អ្នកចង់បានច្រើនជាងនេះ',
  "min length 2 characters":'ប្រវែងមីន 2 តួអក្សរ',
  "container":'បានចុះផ្សាយនៅលើប៉ុស្តិ៍',
  "anyone can search for and view":'អ្នកណាម្នាក់អាចស្វែងរកនិងមើល',
  "only you can view":'មានតែអ្នកទេដែលអាចមើលបាន',
  "add tags":'បន្ថែមស្លាក',
  "hashtags":'hashtags',
  "job position":'មុខតំណែងការងារ',
  "work time type":'ប្រភេទពេលវេលាធ្វើការ',
  "is remote work":'គឺជាការងារពីចម្ងាយ',
  "experience requirements":'តម្រូវការបទពិសោធន៍',
  "min age":'អាយុអប្បបរមា',
  "max age":'អាយុអតិបរមា',
  "min salary":'ប្រាក់ខែអប្បបរមា',
  "max salary":'ប្រាក់ខែអតិបរមា',
  "per quater":'ក្នុងមួយ quater មួយ',
  "latest list of candidates":'បញ្ជីឈ្មោះបេក្ខជនចុងក្រោយបង្អស់',
  "is yes/no question":'គឺបាទ / ចាស / ទេ?',
  "choose number of answers":'ជ្រើសរើសចំនួនចម្លើយ',
  "one answer":'ចម្លើយមួយ',
  "multiple answer":'ចម្លើយច្រើន',
  "number of choices":'ចំនួនជម្រើស',
  "goto":'ទៅ',
  "add donation option":'បន្ថែមជម្រើសបរិច្ចាគ',
  "are you sure delete this":'តើអ្នកពិតជាលុបវាចោលឬ?',
  "would you like to go to the detailed article page":'តើអ្នកចង់ចូលទៅកាន់ទំព័រអត្ថបទលម្អិតទេ?',
  "the article was posted":'អត្ថបទត្រូវបានចុះផ្សាយ',
  "are you sure you want to delete this donation package":'តើអ្នកពិតជាចង់លុបកញ្ចប់អំណោយនេះឬ?',
  "delete donation package":'លុបកញ្ចប់អំណោយ',
  "General Categories":'ប្រភេទទូទៅ',
  "private event":'ព្រឹត្តិការណ៍ឯកជន',
  "private event description":'មានតែអ្នកនិងសមាជិកដែលបានអញ្ជើញប៉ុណ្ណោះដែលអាចចូលរួមក្នុងព្រឹត្តិការណ៍នេះ',
  "private organization":'អង្គការឯកជន',
  "private organization description":'មានតែអ្នកនិងសមាជិកនៃអង្គការរបស់អ្នកប៉ុណ្ណោះដែលអាចមើលនិងទទួលព័ត៌មានពីអង្គការរបស់អ្នក',
  "You have completed the steps of creating organization":'អ្នកបានបញ្ចប់ជំហាននៃអង្គការបង្កើត',
  "Hope your organization will be successful and help many people":'សង្ឃឹមថាអង្គការរបស់អ្នកនឹងទទួលបានជោគជ័យនិងជួយមនុស្សជាច្រើន',
  "Short description about your organization":'ការពិពណ៌នាសង្ខេបអំពីអង្គការរបស់អ្នក',
  "display name":'ឈ្មោះបង្ហាញ',
  "you have chosen":'អ្នកបានជ្រើសរើស',
  "transfer date":'កាលបរិច្ឆេទផ្ទេរ',
  "send a thank you email":'ផ្ញើអ៊ីមែលមកអរគុណ',
  "you donated for campaign":'អ្នកបានបរិច្ចាគសម្រាប់យុទ្ធនាការ',
  "volunteer available":'ស្ម័គ្រចិត្តមាន',
  "action completed":'បានបញ្ចប់សកម្មភាព',
  "successfully created!":'បានបង្កើតឡើងដោយជោគជ័យ!',
  "successfully updated!":'ធ្វើឱ្យទាន់សម័យដោយជោគជ័យ!',
  "successfully deleted!":'បានលុបដោយជោគជ័យ!',
  "search news channel":'ស្វែងរកប៉ុស្តិ៍',
  "input data":'បញ្ចូល​ទិន្នន័យ',
  "add receiving option":'បន្ថែមជម្រើសទទួល',
  "giving input":'ការផ្តល់ការបញ្ចូល',
  "your giving will be sent to":'ការផ្តល់របស់អ្នកនឹងត្រូវបានផ្ញើទៅ',
  "register giving":'ចុះឈ្មោះការឱ្យ',
  "your registration will be sent to":'ការចុះឈ្មោះរបស់អ្នកនឹងត្រូវបានផ្ញើទៅ',
  "Please follow these instructions to complete the giving process":'សូមអនុវត្តតាមការណែនាំទាំងនេះដើម្បីបញ្ចប់ដំណើរការផ្តល់',
  "Your application will be sent to the program and it will take a few days to process, so please wait.":'ពាក្យសុំរបស់អ្នកនឹងត្រូវបានផ្ញើទៅកម្មវិធីហើយវានឹងចំណាយពេលពីរបីថ្ងៃដើម្បីដំណើរការដូច្នេះសូមរង់ចាំ។',
  "Click the Register giving button to complete and receive the giving code":'ចុចប៊ូតុង "ចុះឈ្មោះផ្តល់" ដើម្បីបញ្ចប់និងទទួលបានលេខកូដដែលផ្តល់ឱ្យ',
  "You have just completed register receiving gifts from the program":'អ្នកទើបតែបានចុះឈ្មោះចុះឈ្មោះទទួលអំណោយពីកម្មវិធី',
  "description giving code":'នេះគឺជាកូដចុះឈ្មោះរបស់អ្នកដើម្បីទទួលបានអំណោយយុទ្ធនាការប្រសិនបើអ្នកមានគណនីនៅលើអ្នកអាចពិនិត្យមើលវានៅក្នុងផ្នែកព័ត៌មានឬ "ការចុះឈ្មោះរបស់ខ្ញុំ" ដោយលេខកូដនេះ',
  "successfully subscribed":'បានជាវដោយជោគជ័យ!',
  "register to receive":'ចុះឈ្មោះដើម្បីទទួល',
  "you have registered to receive from":'អ្នកបានចុះឈ្មោះទទួលពី',
  "you received from":'អ្នកបានទទួលពី',
  "register to received":'ចុះឈ្មោះដើម្បីទទួលបាន',
  "receive date":'ទទួលបានកាលបរិច្ឆេទ',
  "register received":'ចុះឈ្មោះទទួលបានទទួល',
  "donation package":'កញ្ចប់បរិច្ចាគ',
  "name package":'ឈ្មោះកញ្ចប់',
  "giving package":'ផ្តល់កញ្ចប់',
  "add giving option":'បន្ថែមជម្រើសផ្តល់ឱ្យ',
  "{type} will delete will be gone forever. Are you sure?":'{វាយ} នឹងលុបនឹងចេញជារៀងរហូត។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  "successfully registered":'បានចុះឈ្មោះដោយជោគជ័យ!',
  "you register to receive":'អ្នកចុះឈ្មោះដើម្បីទទួល',
  "km":'ប្រទេសកម្ពុជា។',
  "fr":'បារាំង',
  "ko":'ឹម',
  "delete receiving package":'លុបកញ្ចប់ទទួល?',
  "are you sure you want to delete this receiving package":'តើអ្នកពិតជាចង់លុបកញ្ចប់ទទួលនេះឬ?',
  "select categories description":'សេចក្តីជូនពរបានដាក់ជាក្រុមព័ត៌មានតាមប្រភេទដើម្បីជួយអ្នកឱ្យទទួលបានព័ត៌មានកាន់តែប្រសើរនិងជៀសវាងការបាត់ព័ត៌មាន។ សូមជ្រើសរើសប្រភេទដែលអ្នកចូលចិត្តនៅខាងក្រោម',
  "by using Wishare, you agree to our":'ដោយប្រើការចង់បានអ្នកយល់ព្រមនឹងរបស់យើង',
  "donation event has the unfinished items":'ព្រឹត្តិការណ៍អំណោយមានរបស់របរដែលមិនទាន់បានបញ្ចប់',
  "search anythings in Wishare":'ស្វែងរកអ្វីទាំងអស់នៅក្នុងសេចក្តីប្រាថ្នា',
  "registered list":'បញ្ជីដែលបានចុះឈ្មោះ',
  "giving event has the unfinished items":'ការផ្តល់ព្រឹត្តិការណ៍មានរបស់ដែលមិនបានបញ្ចប់',
  "recruitment event has the unfinished items":'ព្រឹត្តិការណ៍នៃការជ្រើសរើសបុគ្គលិកមានរបស់របរដែលមិនទាន់បានបញ្ចប់',
  "responsibilities":'ទំនួលខុសត្រូវ',
  "no name entered":'គ្មានឈ្មោះចូល',
  "search with recruitment code":'ស្វែងរកជាមួយលេខកូដជ្រើសរើសបុគ្គលិក',
  "for admin":'សម្រាប់រដ្ឋបាល',
  "your application will be sent to":'ពាក្យសុំរបស់អ្នកនឹងត្រូវបានផ្ញើទៅ',
  "click the Apply now button to complete and receive the apply code":'ចុចប៊ូតុង "ដាក់ពាក្យឥឡូវនេះ" ដើម្បីបញ្ចប់ហើយទទួលបានលេខកូដអនុវត្ត',
  "job-description":'ការពិពណ៌នាការងារ',
  "job-requirements":'តម្រូវការការងារ',
  "recruited":'ដេលរ្យេសាល',
  "thank you for applying for this job":'សូមអរគុណដែលបានដាក់ពាក្យសុំការងារនេះ។',
  "your application has been sent to the organization, please wait for a response from the organization":'ពាក្យសុំរបស់អ្នកត្រូវបានផ្ញើទៅអង្គការសូមរង់ចាំការឆ្លើយតបពីអង្គការ។',
  "that may take a few days, please wait":'វាអាចចំណាយពេលពីរបីថ្ងៃសូមរង់ចាំ។',
  "find account":'ស្វែងរកគណនី',
  "choose account":'ជ្រើសរើសគណនីដែលមាននៅលើការចង់បាន',
  "select receiver":'ជ្រើសរើសអ្នកទទួល',
  "description recruitment code":'នេះគឺជាលេខកូដចុះឈ្មោះអ្នកស្ម័គ្រចិត្តរបស់អ្នកអ្នកអាចតាមដានកម្មវិធីរបស់អ្នកនៅក្នុងផ្នែកស្ម័គ្រចិត្ត។',
  "you have just completed your registration as a volunteer for the program":'អ្នកទើបតែបានបញ្ចប់ការចុះឈ្មោះរបស់អ្នកជាអ្នកស្ម័គ្រចិត្តសម្រាប់កម្មវិធី',
  "applied":'បានអនុវត្ត',
  "Thank you for signing up to be a volunteer of the program":'សូមអរគុណដែលបានចុះឈ្មោះធ្វើជាអ្នកស្ម័គ្រចិត្តនៃកម្មវិធី',
  "candidate list":'បញ្ជីបេក្ខជន',
  "are you sure you want to reject this candidate":'តើអ្នកពិតជាចង់បដិសេធបេក្ខជននេះមែនទេ?',
  "candidate":'បេក្ខចន',
  "currently an employee of the organization":'បច្ចុប្បន្ននេះសមាជិកនៃអង្គការ / ក្រុម',
  "enter candidate information":'បញ្ចូលព័ត៌មានបេក្ខជន',
  "enter information to give":'បញ្ចូលព័ត៌មានដើម្បីផ្តល់ឱ្យ',
  "given by the organization":'ដែលបានផ្តល់ឱ្យដោយអង្គការ',
  "enter donation information":'បញ្ចូលព័ត៌មានអំណោយ',
  "edit candidate information":'កែសម្រួលព័ត៌មានបេក្ខជន',
  "administration panel":'បន្ទះរដ្ឋបាល',
  "open/close recruitment":'ពេលវេលាជ្រើសរើសបុគ្គលិក',
  "work type":'ប្រភេទការងារ',
  "create donation event":'បង្កើតយុទ្ធនាការប្រមូលផ្តុំការប្រមូលផ្តុំ',
  "create giving event":'បង្កើតយុទ្ធនាការផ្តល់ឱ្យ',
  "organization type":'ប្រភេទនៃអង្គភាព',
  "mobilization of manpower":'ការចល័តកម្លាំងពលកម្ម',
  "communication":'ការ​ទំនាក់ទំនង',
  "are you ready for volunteer work":'តើអ្នកត្រៀមខ្លួនសម្រាប់ការងារស្ម័គ្រចិត្តមែនទេ?',
  "change-password":'ផ្លាស់ប្តូរលេខសម្ងាត់',
  "configure-content-by-language":'កំណត់រចនាសម្ព័ន្ធមាតិកាតាមភាសា',
  "diamond":'បេរច',
  "platinum":'ផ្លាទីន',
  "gold":'មាស',
  "silver":'របាក់',
  "bronze":'សមរិត្ធ',
  "Display name can not be empty":'ឈ្មោះបង្ហាញមិនអាចទទេបានទេ',
  "Number values can not be zero":'តម្លៃលេខមិនអាចសូន្យទេ',
  "basic":'នៃមុលដ្ឋាន',
  "premium":'បុព្វលាច',
  "general operating model":'គំរូប្រតិបត្តិការទូទៅ',
  "we make common rules so that everyone can understand and understand how we work":'យើងបង្កើតច្បាប់ទូទៅដូច្នេះអ្នករាល់គ្នាអាចយល់និងយល់ពីរបៀបដែលយើងធ្វើការ។',
  "project":'កមរោង',
  "serving the mission aiming for social impact":'បម្រើបេសកកម្មនេះក្នុងគោលបំណងជះឥទ្ធិពលសង្គម',
  "giving gifts to difficult circumstances":'ផ្តល់អំណោយដល់កាលៈទេសៈពិបាក',
  "motto and how it works":'បាវចនានិងរបៀបដែលវាដំណើរការ',
  "we aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission":'យើងមានគោលបំណងភ្ជាប់អ្នកផ្តល់ផលប្រយោជន៍ប្រជាជនដែលជួបការលំបាកក្នុងអង្គការសប្បុរសធម៌ដែលអនុវត្តបេសកកម្មដែលមានអត្ថន័យ។',
  "connect social activities":'ភ្ជាប់សកម្មភាពសង្គម',
  "we create links between charities and those in need":'យើងបង្កើតទំនាក់ទំនងរវាងសប្បុរសធម៌និងអ្នកដែលខ្វះខាត។',
  "support groups, organizations":'សមាគមគាំទ្រក្រុមអង្គការ',
  "we provide support tools for associations, groups, organizations to be able to carry out their volunteering missions":'យើងផ្តល់ជូននូវឧបករណ៍គាំទ្រសម្រាប់សមាគមក្រុមអង្គការ ... ដើម្បីអាចអនុវត្តបេសកកម្មស្ម័គ្រចិត្តរបស់ពួកគេ។',
  "Transparency - Sustainability - Impact":'តម្លាភាព - និរន្តរភាព - ផលប៉ះពាល់',
  "this is our operating principle, every donation or donation is clear and transparent":'នេះគឺជាគោលការណ៍ប្រតិបត្តិការរបស់យើងរាល់ការបរិច្ចាគឬការបរិច្ចាគគឺច្បាស់និងមានតម្លាភាព។',
  "beginer":'រសីអ្នកចាប់ផ្ដើម',
  "intermediate":'មធ្យម',
  "proficient":'នៃកោសល្យ',
  "on working":'កំពុងធ្វើការ',
  "not graduated":'មិនបានបញ្ចប់ការសិក្សា',
  "not anymore":'អត់មាន​អ្វី​ទៀត​ទេ',
  "review the application form":'ពិនិត្យទម្រង់ពាក្យសុំ',
  "application form":'ពាក្យសុំ',
  "recruitment agency":'ទីភ្នាក់ងារជ្រើសរើសបុគ្គលិក',
  "cancel application":'បោះបង់ការដាក់ពាក្យ',
  "do you want to change your personal information":'តើអ្នកចង់ផ្លាស់ប្តូរព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកទេ?',
  "candidate profile":'ប្រវត្តិរូបរបស់បេក្ខជន',
  "you want to see candidate information":'អ្នកចង់ឃើញព័ត៌មានរបស់បេក្ខជន',
  "human resources":'ធនធានមនុស្ស',
  "Transparency & Sustainability":'តម្លាភាពនិងនិរន្តរភាព',
  "record project activities with the data of fundraising campaigns, giving":'សកម្មភាពគំរោងកំណត់ត្រាជាមួយនឹងទិន្នន័យយុទ្ធនាការរៃបញង់ការផ្តល់ឱ្យ',
  "projects":'គម្រោង',
  "how it works":'របៀបដែលវាដំណើរការ',
  "information collecting":'ការប្រមូលព័ត៌មាន',
  "candidates":'បេក្ខជន',
  "you can not select than 2 languages":'អ្នកមិនអាចជ្រើសរើសភាសាជាង 2 ភាសាបានទេ',
  "Sharing and connecting between members in the community of social activities.":'ការចែករំលែកនិងភ្ជាប់រវាងសមាជិកក្នុងសហគមន៍សកម្មភាពសង្គម។',
  "Support social units in the work of carrying out their mission":'គាំទ្រអង្គភាពសង្គមក្នុងការងារក្នុងការបំពេញបេសកកម្មរបស់ពួកគេ',
  "Creating transparency and sustainability in social activities":'ការបង្កើតតម្លាភាពនិងនិរន្តរភាពក្នុងសកម្មភាពសង្គម',
  "are you sure that you want to cancel this":'តើអ្នកប្រាកដទេថាអ្នកចង់បោះបង់វា',
  "edit a poll":'កែសម្រួលការស្ទង់មតិមួយ',
  "Change folder name":'ប្តូរឈ្មោះថតឯកសារ',
  "Do you want delete this folder":'តើអ្នកចង់លុបថតនេះទេ?',
  "This will permanently delete it, but its items will still be in your saved list.":'នេះនឹងលុបវាចោលជាអចិន្ត្រៃយ៍ប៉ុន្តែធាតុរបស់វានៅតែមាននៅក្នុងបញ្ជីដែលអ្នកបានរក្សាទុក។',
  "export donation data":'ទិន្នន័យបរិច្ចាគការនាំចេញ',
  "export as PDF":'នាំចេញជា PDF',
  "export giving data":'ការផ្តល់ទិន្នន័យការនាំចេញទិន្នន័យ',
  "record and display the converted value":'កត់ត្រានិងបង្ហាញតម្លៃដែលបានផ្លាស់ប្តូរ',
  "with target":'ជាមួយនឹងគោលដៅ',
  "approved list":'បញ្ជីដែលបានអនុម័ត',
  "rejected list":'បញ្ជីបដិសេធ',
  "export":'នាមចេញ',
  "time sheets":'សន្លឹកពេលវេលា',
  "view timesheet":'មើលតារាងពេលវេលា',
  "other project":'គម្រោងផ្សេងទៀត',
  "select project":'ជ្រើសរើសគំរោង',
  "For example: ABC Campaign, ABC Organization,...":'ឧទាហរណ៍: យុទ្ធនាការអេប៊ីស៊ីអង្គការអេប៊ីអេ ...',
  "edit timesheet":'កែសម្រួលតារាងពេលវេលា',
  "member information":'ព័ត៌មានសមាជិក',
  "delete this information":'លុបព័ត៌មាននេះ?',
  "are you sure you want to delete this working information":'តើអ្នកពិតជាចង់លុបព័ត៌មានការងារនេះឬ?',
  "my profile":'ប្រវត្តិរូបរបស់ខ្ញុំ',
  "you are exporting campaign data":'អ្នកកំពុងនាំចេញទិន្នន័យយុទ្ធនាការឃោសនា',
  "package donation":'ការបរិច្ចាគរបស់កញ្ចប់',
  "package giving":'ការផ្តល់កញ្ចប់',
  "do you want to leave":'តើអ្នកចង់ចាកចេញទេ?',
  "you will no longer be a member of this organization or activity":'អ្នកនឹងលែងក្លាយជាសមាជិកនៃអង្គការឬសកម្មភាពនេះទៀតហើយ។ តើ​អ្នក​ប្រាកដ​ឬ​អត់ ?',
  "the {type} has been deleted":'{ប្រភេទ} ត្រូវបានលុបចោល',
  "features group":'លក្ខណៈពិសេស',
  "beneficiary management":'ការគ្រប់គ្រងអ្នកទទួលផល',
  "beneficiary-management":'ការគ្រប់គ្រងអ្នកទទួលផល',
  "on moving":'នៅលើការផ្លាស់ប្តូរ',
  "go to activity":'ទៅសកម្មភាព',
  "transfer beneficiary":'អ្នកទទួលផលផ្ទេរប្រាក់',
  "note approve beneficiary":'នៅពេលផ្ទេរអ្នកទទួលអត្ថប្រយោជន៍អ្នកនឹងមិនអាចមានសិទ្ធិចូលគ្រប់គ្រងបំណងប្រាថ្នានេះបានទេ។ ហើយសកម្មភាពនេះមិនអាចធ្វើវិញបានទេ។',
  "cancel transfer":'លុបចោលការផ្ទេរប្រាក់',
  "insights":'ការយល់ដឹង',
  "recent campaigns":'យុទ្ធនាការថ្មីៗនេះ',
  "recent activity":'សកម្មភាពថ្មីៗ',
  "latest apply":'ចុងក្រោយត្រូវបានអនុវត្ត',
  "latest register giving":'ចុះឈ្មោះចុងក្រោយការផ្តល់ឱ្យ',
  "latest register donation":'ការបរិច្ចាគចុះឈ្មោះចុងក្រោយបង្អស់',
  "latest followed user":'អ្នកប្រើចុងក្រោយបន្ទាប់ពី',
  "latest post":'អត្ថបទចុងក្រោយ',
  "campaign":'យុត្ធការ',
  "top donation campaign":'យុទ្ធនាការបរិច្ចាគកំពូល',
  "top giving campaign":'យុទ្ធនាការផ្តល់ចំណាត់ថ្នាក់កំពូល',
  "total giving":'ការឱ្យ',
  "top article":'អត្ថបទខាងលើ',
  "top posts":'ប្រកាសកំពូល',
  "total votes":'សម្លេងឆ្នោតសរុប',
  "referral information":'ព័ត៌មានបញ្ជូន',
  "existing":'ដេលអវរកស',
  "beneficiary referrals":'ការបញ្ជូនអ្នកទទួលផល',
  "beneficiary-referrals":'ការបញ្ជូនអ្នកទទួលផល',
  "followed":'តាមចាមតង់',
  "total views":'ការមើលសរុប',
  "total comments":'យោបល់សរុប',
  "total follows":'សរុបដូចខាងក្រោម',
  "today":'ថៃនេហ',
  "yesterday":'បីមសិលមិញ',
  "last 7 days":'7 ថ្ងៃចុងក្រោយ',
  "this month":'ខែ​នេះ',
  "last month":'ខែមុន',
  "latest donations":'ការបរិច្ចាគចុងក្រោយ',
  "to the campaign":'ទៅយុទ្ធនាការ',
  "part given from":'ផ្នែកដែលបានផ្តល់ឱ្យពី',
  "introduced by":'ណែនាំដោយ',
  "history of receiving and giving":'ប្រវត្តិនៃការទទួលនិងការឱ្យ',
  "account link":'តំណភ្ជាប់គណនី',
  "activity link":'តំណភ្ជាប់សកម្មភាព',
  "detail information":'ព័ត៌មានលំអិត',
  "link to donation":'ភ្ជាប់ទៅការបរិច្ចាគ',
  "donation/giving":'ការបរិច្ចាគ / ផ្តល់ឱ្យ',
  "interactive":'ដេលមានតមនាក់តមនង',
  "accept transferring":'ទទួលយកការផ្ទេរ',
  "reject transferring":'ច្រានចោលការផ្ទេរ',
  "was a member of":'គឺជាសមាជិកនៃ',
  "select an existing profile":'ជ្រើសរើសទម្រង់ដែលមានស្រាប់',
  "latest articles":'អត្ថបទថ្មីៗ',
  "date desc":'ប៉ុន្មានថ្ងៃថ្មីៗនេះ',
  "name desc":'ក្នុងលំដាប់ Z -> ក',
  "value desc":'តម្លៃចុះ',
  "date asc":'ថ្ងៃចំណាស់ជាងគេបំផុត',
  "name asc":'តាមលំដាប់លំដោយ A -> Z',
  "value asc":'តម្លៃបង្កើន',
  "are you sure delete this message":'តើអ្នកពិតជាលុបសារនេះមែនទេ?',
  "related units": 'អង្គភាពពាក់ព័ន្ធ',
  "benefactor":'រសីអ្នកឧបត្ថម្ក',
  "user note":'កំណត់សំគាល់របស់អ្នកប្រើប្រាស់',
  "admin note":'កំណត់សំគាល់របស់អ្នកគ្រប់គ្រង',
  "filename":'ឈ្មោះ​ឯកសារ',
  "size":'តមហម',
  "responding":'ការឆ្លើយតប',
  "register_to_donate":'ចុះឈ្មោះដើម្បីបរិច្ចាគ',
  "attached image":'ភ្ជាប់រូបភាព',
  "attached file":'ឯកសារភ្ជាប់',
  "file":'របអប់',
  "money transferred":'ផ្ទេរប្រាក់',
  "total donated amount":'ចំនួនបរិច្ចាគសរុប',
  "description qr-code":'សូមចុះឈ្មោះដើម្បីចូលរួមវិភាគទាននៅលើតុឬចុះឈ្មោះដោយផ្ទាល់នៅតំណ (បើកជាមួយ QR កូដនៅជាប់វា)',
  "note (administrator)":'សម្គាល់ (អ្នកគ្រប់គ្រង)',
  "note (register)":'សម្គាល់ (ចុះឈ្មោះ)',
  "value display is not allowed":'លាក់តម្លៃបរិច្ចាគ',
  "hidden value":'តម្លៃលាក់',
  "or you can copy the link of the page here to send it to your friends in other ways.":'ឬអ្នកអាចចម្លងតំណទំព័រនៅទីនេះដើម្បីផ្ញើវាទៅមិត្តរបស់អ្នកតាមវិធីផ្សេងទៀត។',
  "slide show (live)":'បញ្ចាំងស្លាយ (ផ្សាយផ្ទាល់)',
  "thanks for your donation":'សូមអរគុណសម្រាប់ការបរិច្ចាគរបស់អ្នក',
  "an anonymous donor":'អ្នកឧបត្ថម្ភអនាមិក',
  "total amount contributed up to now":'ចំនួនទឹកប្រាក់សរុបបានចូលរួមចំណែកឥឡូវនេះ',
  "thank you benefactors":'សូមអរគុណអ្នកទទួលបានផលប្រយោជន៍',
  "donated amount":'ចំនួនទឹកប្រាក់ដែលបានបរិច្ចាគ',
  "activity list":'បញ្ជីសកម្មភាព',
  "count desc":'រាប់ desc',
  "campaign list":'បញ្ជីយុទ្ធនាការឃោសនាបោះឆ្នោត',
  "event list":'បញ្ជីព្រឹត្តិការណ៍',
  "email history thank you":'ប្រវត្តិអ៊ីមែលសូមអរគុណ',
  "donors":'តុកចំណាន',
  "recent donations":'ការបរិច្ចាគថ្មីៗ',
  "top donations":'ការបរិច្ចាគកំពូល',
  "total donation events":'ព្រឹត្តិការណ៍បរិច្ចាគសរុប',
  "total donation value":'តម្លៃបរិច្ចាគសរុប',
  "total donors":'ម្ចាស់ជំនួយសរុប',
  "top donation count":'ចំនួនបរិច្ចាគខាងលើ',
  "top donation value":'តម្លៃបរិច្ចាគខាងលើ',
  "donor list":'បញ្ជីអ្នកបរិច្ចាគ',
  "merge":'រយបរយមក្នា',
  "top donors":'ម្ចាស់ជំនួយកំពូល',
  "the system sent a thank you letter to":'ប្រព័ន្ធបានផ្ញើលិខិតថ្លែងអំណរគុណចំពោះ',
  "for the campaign donation":'សម្រាប់ការបរិច្ចាគយុទ្ធនាការ',
  "email thank you":'អ៊ីមែលអរគុណ',
  "create donor":'បង្កើតម្ចាស់ជំនួយ',
  "edit donor":'កែសម្រួលអ្នកបរិច្ចាគ',
  "recent givings":'ការផ្តល់ជូនថ្មីៗនេះ',
  "edit beneficiary":'កែសម្រួលអ្នកទទួលផល',
  "account not linked yet":'គណនីមិនទាន់មានទំនាក់ទំនងនៅឡើយទេ',
  "account not linked yet des":'អ្នកមិនទាន់បានភ្ជាប់ជាមួយគណនី ShareShare របស់អ្នកទេសូមចុចនៅទីនេះដើម្បីភ្ជាប់។',
  "merge donor":'បញ្ចូលអ្នកបរិច្ចាគចូលគ្នា',
  "selected donor":'ម្ចាស់ជំនួយដែលបានជ្រើសរើស',
  "merge with":'បញ្ចូលគ្នាជាមួយ',
  "beneficiary share":'ការណែនាំអំពីអ្នកទទួលផល',
  "total giving events":'ព្រឹត្តិការណ៍ផ្តល់ជូនសរុប',
  "total giving value":'តម្លៃផ្តល់ជូនសរុប',
  "total beneficiaries":'អ្នកទទួលផលសរុប',
  "merge beneficiary":'បញ្ចូលអ្នកទទួលផល',
  "staffs list":'មនុលិនក',
  "recent recruitments":'ការជ្រើសរើសបុគ្គលិកថ្មីៗ',
  "staff roster":'បញ្ជីឈ្មោះបុគ្គលិក',
  "staff":'សមាចិក',
  "collaborator":'បុរសករក្នា',
  "intern":'និសសិតអាមងតេរនា',
  "employee type":'ប្រភេទសមាជិក',
  "edit staff":'កែសម្រួលសមាជិក',
  "create staff":'បង្កើតសមាជិក',
  "display with anonymous":'បង្ហាញជាអ្នកឧបត្ថម្ភអនាមិក',
  "create staff roster":'បង្កើតបញ្ជីឈ្មោះបុគ្គលិក',
  "position placeholder":'ឧទាហរណ៍ៈបុគ្គលិកគាំទ្រ',
  "activity/organization":'សកម្មភាព / អង្គការ',
  "total staffs":'សមាជិកសរុប',
  "total recruitment event":'ការជ្រើសរើសបុគ្គលិកសរុប',
  "total user register":'ការចុះឈ្មោះអ្នកប្រើប្រាស់សរុប',
  "edit staff roster":'កែសម្រួលបញ្ជីឈ្មោះបុគ្គលិក',
  "staff information":'ព័ត៌មានសមាជិក',
  "create timesheets":'បង្កើតពេលវេលា',
  "edit timesheets":'កែសម្រួលតារាងពេលវេលា',
  "total time work":'ការងារពេលវេលាសរុប',
  "top staff area":'តំបន់ចែកចាយបុគ្គលិកកំពូល',
  "top staff time work":'ការបរិច្ចាគការងារពេលវេលាបុគ្គលិកកំពូល',
  "total hours":'ម៉ោងសរុប',
  "address placeholder":'ឧទាហរណ៍ៈលេខ 10 ផ្លូវ 10',
  "introduce to":'ណែនាំឱ្យប្រើ',
  "find donor":'ស្វែងរកម្ចាស់ជំនួយ',
  "alias name placeholder":'ឧទាហរណ៍ៈចនសម្គាល់',
  "country placeholder":'ឧទាហរណ៍ៈប្រទេសអង់គ្លេស',
  "phone placeholder":'ឧទាហរណ៍: 0987654321',
  "email placeholder":'ឧទាហរណ៍ៈឧទាហរណ៍ @email.com',
  "choose date placeholder":'ឧទាហរណ៍ៈ 01/01/2021',
  "top giving value":'តម្លៃផ្តល់ឱ្យកំពូល',
  "top giving count":'ការរាប់ខាងលើ',
  "you choose channel":'អ្នកជ្រើសរើសឆានែល',
  "channel post":'ប្រកាសរបស់ឆានែល',
  "total location":'ទីតាំងសរុប',
  "Click here to search for benefactors":'ចុចត្រង់នេះដើម្បីស្វែងរកអ្នកទទួលបានផលប្រយោជន៍',
  "You can find a benefactor or add another benefactor":'អ្នកអាចរកឃើញអ្នកទទួលផលឬបន្ថែមអ្នកទទួលផលផ្សេងទៀត',
  "Click here to create a new one":'(ចុចត្រង់នេះដើម្បីបង្កើតថ្មី)',
  "new donor":'ម្ចាស់ជំនួយថ្មី',
  "referral list":'បញ្ជីបញ្ជូន',
  "find referral":'ស្វែងរកការបញ្ជូន',
  "not added yet":'មិនបានបន្ថែមនៅឡើយទេ',
  "merge note description":'សកម្មភាពនេះមិនអាចមិនធ្វើវិញអ្នកគួរតែពិចារណាដោយប្រុងប្រយ័ត្នមុនពេលដំណើរការសកម្មភាពនេះ។',
  "find beneficiary":'ស្វែងរកអ្នកទទួលផល',
  "share note description":'សកម្មភាពនេះនឹងចែករំលែកសិទ្ធិគ្រប់គ្រងជាមួយអង្គភាពមួយផ្សេងទៀតតើអ្នកប្រាកដទេថា។',
  "receiver name":'ឈ្មោះអ្នកទទួល',
  "register for":'ចុះឈ្មោះ',
  "Click here to find referrer information":'ចុចត្រង់នេះដើម្បីស្វែងរកព័ត៌មានបញ្ជូន',
  "You can find a referrer or add a new one":'អ្នកអាចរកឃើញអ្នកបញ្ជូនឬបន្ថែមថ្មីមួយ',
  "new beneficiary":'អ្នកទទួលផលថ្មី',
  "Click here to find beneficiary information":'ចុចត្រង់នេះដើម្បីស្វែងរកព័ត៌មានអ្នកទទួលផល',
  "You can find a beneficiary or add another beneficiary":'អ្នកអាចរកឃើញអ្នកទទួលអត្ថប្រយោជន៍ឬបន្ថែមអ្នកទទួលផលផ្សេងទៀត',
  "Click here to find an account":'ចុចត្រង់នេះដើម្បីស្វែងរកគណនី',
  "You can find the user\'s account and add":'អ្នកអាចរកឃើញគណនីរបស់អ្នកប្រើប្រាស់និងបន្ថែម',
  "recent candidates":'បេក្ខជនថ្មីៗ',
  "recruitment list":'បញ្ជីជ្រើសរើសបុគ្គលិក',
  "donation count":'ការរាប់ចំនួនបរិច្ចាគ',
  "donation insights":'ការយល់ដឹងការវិចេង',
  "list of recipients":'បញ្ជីអ្នកទទួល',
  "giving insights":'ផ្តល់ការយល់ដឹង',
  "share beneficiary":'ចែករំលែកអ្នកទទួលផល',
  "to shared":'ចែករំលែក',
  "this page is for organizations only create an organization":'ទំព័រនេះសម្រាប់អង្គការបង្កើតតែអង្គការប៉ុណ្ណោះ',
  "Sign in for free to experience the features":'ចូលដោយឥតគិតថ្លៃដើម្បីទទួលបានបទពិសោធន៍ពិសេស',
  "Keep personalized tracking in the field of volunteering":'រក្សាការតាមដានផ្ទាល់ខ្លួនក្នុងវិស័យការងារស្ម័គ្រចិត្ត។',
  "Join and update information from the community":'ចូលរួមនិងធ្វើបច្ចុប្បន្នភាពព័ត៌មានពីសហគមន៍។',
  "Keep track of contributions, participating activities":'តាមដានការចូលរួមវិភាគទានសកម្មភាពដែលចូលរួម។',
  "Find suggestions for suitable volunteer work":'ស្វែងរកការផ្តល់យោបល់សម្រាប់ការងារស្ម័គ្រចិត្តសមរម្យ។',
  "create account":'បង្កើតគណនី',
  "Create an account for your organization or charity ":'បង្កើតគណនីសម្រាប់អង្គការឬសប្បុរសធម៌របស់អ្នក។',
  "The platform supports a free tool to help transform the number of philanthropic activities":'វេទិកានេះគាំទ្រឧបករណ៍ឥតគិតថ្លៃដើម្បីជួយផ្លាស់ប្តូរចំនួនសកម្មភាពសប្បុរសធម៌។',
  "Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization":'លក្ខណៈពិសេសដើម្បីធ្វើអន្តរកម្មជាមួយដៃគូ: ការរៃអង្គាសប្រាក់ការជ្រើសរើសការផ្តល់ព្រឹត្តិការណ៍ព្រឹត្តិការណ៍ការរាយការណ៍អំពីការធ្វើរបាយការណ៍ផលប៉ះពាល់សកម្មភាពរបស់អង្គការ។',
  "Organizational management features: manage donors, beneficiaries, human resources":'លក្ខណៈបច្ចេកទេសគ្រប់គ្រងអង្គការ: គ្រប់គ្រងម្ចាស់ជំនួយការអ្នកទទួលផលធនធានមនុស្ស។',
  "extra information":'ព​ត៌​មាន​បន្ថែម',
  "value placeholder":'ឧទាហរណ៍ៈ ABC ....',
  "datetime placeholder":'ឧទាហរណ៍ៈ 01/01/2000',
  "number placeholder":'ឧទាហរណ៍: 012345 ...',
  "field name":'ឈ្មោះវាល',
  "year of birth":'ឆ្នាំ​កំណើត',
  "remember me":'ចងចាំខ្ញុំ',
  "it received":'បានទទួល',
  "clone item":'ច្បាប់ចម្លង',
  "clone beneficiary":'ក្លូនអ្នកទទួលផល',
  "clone note description":'ជាមួយនឹងសកម្មភាពនេះអ្នកអាចកែសម្រួលព័ត៌មានរបស់អ្នកទទួលផលដោយមិនប៉ះពាល់ដល់ព័ត៌មានរបស់អង្គការផ្សេងទៀតឡើយ។',
  "this year":'ឆ្នាំ​នេះ',
  "last year":'ឆ្នាំមុន',
  "last 3 years":'3 ឆ្នាំចុងក្រោយ',
  "showing":'ការបង្ហាញ',
  "Donate to the campaign by cash or bank transfer":'បរិច្ចាគដល់យុទ្ធនាការដោយការផ្ទេរប្រាក់តាមធនាគារឬធនាគារ',
  "Donate to the campaign with items":'បរិច្ចាគទៅយុទ្ធនាការដែលមានធាតុ',
  "Contributions in kind can be converted to the corresponding value at the time of donation":'ការចូលរួមវិភាគទានប្រភេទអាចត្រូវបានប្តូរទៅជាតម្លៃដែលត្រូវគ្នានៅពេលអំណោយ',
  "Giving in kind can be converted into equivalent value at the time of giving":'ការផ្តល់ឱ្យក្នុងប្រភេទអាចត្រូវបានប្តូរទៅជាតម្លៃសមមូលនៅពេលផ្តល់ឱ្យ',
  "Give to beneficiary by cash or bank transfer":'ផ្តល់ឱ្យអ្នកទទួលផលដោយការផ្ទេរប្រាក់តាមធនាគារឬធនាគារ',
  "Give the beneficiary with the items":'ផ្តល់ឱ្យអ្នកទទួលនូវអត្ថប្រយោជន៍ដែលមានធាតុ',
  "reconnect":'ភ្ជាប់ឡើងវិញ',
  "network":'បណ្តាញ',
  "network offline":'បណ្តាញក្រៅបណ្តាញ',
  "list of co-hosting":'បញ្ជីនៃសហការបង្ហោះ',
  "list of co-owners":'បញ្ជីសហកម្មសិទ្ធិករ',
  "co-hosting":'សហការបង្ហោះ',
  "do you want to leave the position of host of this campaign":'តើអ្នកចង់ចាកចេញពីជំហរនៃការធ្វើយុទ្ធនាការនេះទេ?',
  "co-owners":'សហម្ចាស់',
  "refused":'រេសុង',
  "do you want to decline the invitation":'តើអ្នកចង់បដិសេធការអញ្ជើញទេ?',
  "host type":'ប្រភេទម៉ាស៊ីន',
  "owner type":'ប្រភេទម្ចាស់',
  "staff list":'មនុលិនក',
  "related activities":'សកម្មភាពដែលទាក់ទង',
  "related giving events":'ពាក់ព័ន្ធព្រឹត្តិការណ៍នានា',
  "related donation events":'ព្រឹត្តិការណ៍បរិច្ចាគដែលទាក់ទង',
  "related recruitments": 'ព្រឹត្តិការណ៍ជ្រើសរើសពលករដែលពាក់ព័ន្ធ',
  "related events":'ព្រឹត្តិការណ៍ដែលពាក់ព័ន្ធ',
  "peer organization":'អង្គការមិត្តភក្ដិ',
  "peer organization list":'បញ្ជីអង្គការមិត្តភក្តិ',
  "total peer organization":'អង្គការមិត្តភក្តិសរុប',
  "has left":'បានចាកចេញ',
  "total hosts":'ម៉ាស៊ីនសរុប',
  "total members":'សមាជិកសរុប',
  "my host request":'បញ្ជីសមាជិក',
  "my-host-request":'បញ្ជីសមាជិក',
  "list of members":'បញ្ជីសមាជិក',
  "activity of co-owners":'សកម្មភាពរបស់សហកម្មសិទ្ធិករ',
  "medals":'ការដាក់របាំ',
  "medal list":'បញ្ជីមេដាយ',
  "medal manager":'ប្រធានផ្នែកវេជ្ជសាស្ត្រ',
  "medal summary":'សង្ខេបមេដាយ',
  "create medal":'បង្កើតមេដាយ',
  "edit medal":'កែសម្រួលមេដាយ',
  "total staff-rosters":'បុគ្គលិកសរុប - រ៉ូបូត',
  "type desc":'ប្រភេទ Desc',
  "type asc":'វាយបញ្ចូលអា',
  "medal awards":'ពានរង្វាន់មេដាយ',
  "medal rewards":'រង្វាន់មេដាយ',
  "medal reward list":'បញ្ជីរង្វាន់ឱសថ',
  "give medal":'ផ្តល់មេដាយ',
  "effect date":'កាលបរិច្ឆេទបែបផែន',
  "medal award manager":'ប្រធានគ្រប់គ្រងរង្វាន់មេដាយ',
  "pending givings":'ការរង់ចាំការរង់ចាំ',
  "organizational structure":'រចនាសម្ព័ន្ធរបស់អង្គការ',
  "operating":'បបរេប',
  "activities of member":'សកម្មភាពរបស់សមាជិក',
  "list of medal":'បញ្ជីមេដាយ',
  "level placeholder":'ឧទាហរណ៍ៈពេជ្រ 1 ពេជ្រ 2- មាស 3-ប្រាក់, ...',
  "staffs":'បុគ្គលិក',
  "activity summaries":'សេចក្តីសង្ខេបសកម្មភាព',
  "donation summaries":'សេចក្តីសង្ខេបនៃការបរិច្ចាគ',
  "givings summaries":'សេចក្តីសង្ខេបនៃការផ្តល់អំណោយ',
  "staffs summaries":'សេចក្តីសង្ខេបបុគ្គលិក',
  "recruitment events":'ការជ្រើសរើស',
  "organization unit reports":'របាយការណ៍ឯកតាអង្គភាព',
  "{value1} / {value2}: Number of organizations / total number of member organizations":'{តម្លៃ 1} / {តម្លៃ 2}: ចំនួនអង្គការ / ចំនួនអង្គការសមាជិកសរុប',
  "contributions":'ការចូលរួមវិភាគទាន',
  "waiting value":'តម្លៃរង់ចាំ',
  "giving count":'ការរាប់',
  "spent my precious time at work":'បានចំណាយពេលដ៏មានតម្លៃរបស់ខ្ញុំនៅកន្លែងធ្វើការ',
  "We sincerely appreciate your dedication of time and effort to making this life better":'យើងសូមកោតសរសើរយ៉ាងស្មោះចំពោះការប្រគល់ពេលវេលានិងការខំប្រឹងប្រែងរបស់អ្នកយ៉ាងស្មោះដើម្បីធ្វើឱ្យជីវិតនេះកាន់តែប្រសើរឡើង។',
  "We would like to award this medal to mark your contribution":'យើងចង់ប្រគល់មេដាយនេះដើម្បីសម្គាល់ការចូលរួមរបស់អ្នក។',
  "Wishing you always full of health, peace and enthusiasm. Sincerely thank you.":'ជូនពរអ្នកជានិច្ចមានសុខភាពសន្តិភាពនិងភាពរីករាយ។ សូមថ្លែងអំណរគុណយ៉ាងជ្រាលជ្រៅ។',
  "staff received":'សមាជិកបានទទួល',
  "donor received":'ទទួលបានម្ចាស់ជំនួយ',
  "register list":'ចុះឈ្មោះបញ្ជី',
  "ML (blood unit)":'ML (អង្គភាពឈាម)',
  "choose organization":'ជ្រើសរើសអង្គការ',
  "total events":'ព្រឹត្តិការណ៍សរុប',
  "total activities":'សកម្មភាពសរុប',
  "total donations":'ការបរិច្ចាគសរុប',
  "total waiting value":'តម្លៃចុះឈ្មោះសរុប',
  "total givings value":'តម្លៃនៃការផ្តល់ប្រាក់សរុប',
  "total givings target":'គោលដៅនៃការជួយសង្គ្រោះសរុប',
  "total givings":'ការផ្តល់ជូនសរុប',
  "total collaborator":'សហការសហការសរុប',
  "total intern":'អ្នកហាត់ការសរុប',
  "total staff":'សមាជិកសរុប',
  "total volunteer":'អ្នកស្ម័គ្រចិត្តសរុប',
  "history medal rewards":'រង្វាន់មេដាយប្រវត្តិសាស្រ្ត',
  "list of staff roster":'បញ្ជីឈ្មោះបុគ្គលិក',
  "donation of organization":'ការបរិច្ចាគរបស់អង្គការ',
  "giving of organization":'ការឱ្យអង្គការ',
  "certifications":'វិញ្ញាបនប័ត្រ',
  "price (VND)":'តំលៃ (ដុង)',
  "access rights":'សិទ្ធិទទួលបានសិទ្ធិទទួលបាន',
  "contact list":'បញ្ជីទំនាក់ទំនង',
  "what is your organization name?":'តើឈ្មោះអង្គភាពរបស់អ្នកគឺជាអ្វី?',
  "sponsor":'អ្នកឧបត្ថម្ក',
  "disable on the personal page":'បិទនៅលើទំព័រផ្ទាល់ខ្លួន',
  "disable donor on page description":'សកម្មភាពនេះនឹងលាក់ព័ត៌មានទាក់ទងនឹងការបរិច្ចាគរបស់អ្នកនៅលើទំព័រផ្ទាល់ខ្លួនរបស់អ្នក',
  "disable beneficiary on page description":'សកម្មភាពនេះនឹងលាក់ព័ត៌មានទាក់ទងនឹងអ្នកទទួលរបស់អ្នកនៅលើទំព័រផ្ទាល់ខ្លួនរបស់អ្នក',
  "disable work on page description":'សកម្មភាពនេះនឹងលាក់ព័ត៌មានទាក់ទងនឹងការងាររបស់អ្នកនៅលើទំព័រផ្ទាល់ខ្លួនរបស់អ្នក',
  "import donation data":'ទិន្នន័យបរិច្ចាគការបរិច្ចាគ',
  "import":'នាំចូលទិន្នន័យ',
  "you are importing campaign data":'អ្នកកំពុងនាំចូលទិន្នន័យយុទ្ធនាការ',
  "preview template":'ការមើលគំរូជាមុន',
  "limit description":'ទំហំឯកសារមិនលើសពី',
  "template data":'ទិន្នន័យគំរូ',
  "notes description":'ការពន្យល់អំពីជួរឈរក្នុងឯកសារគំរូ',
  "campaign id code":'លេខអត្តសញ្ញាណប័ណ្ណឃាតកម្ម',
  "contributed date":'កាលបរិច្ឆេទចូលរួម',
  "donor\'s name":'ឈ្មោះរបស់ម្ចាស់ជំនួយ',
  "donor\'s owner id":'លេខសម្គាល់ម្ចាស់របស់ម្ចាស់ជំនួយ',
  "donation quantity":'បរិមាណបរិច្ចាគ',
  "donation unit":'អង្គភាពបរិច្ចាគ',
  "exchange value":'តម្លៃផ្លាស់ប្តូរ',
  "donation note":'កំណត់ចំណាំការបរិច្ចាគ',
  "additional fields for information":'វាលបន្ថែមសម្រាប់ព័ត៌មាន',
  "beneficiary\'s name":'ឈ្មោះរបស់អ្នកទទួលផល',
  "beneficiary address":'អាសយដ្ឋានអ្នកទទួលផល',
  "beneficiary\'s owner id":'អត្តសញ្ញាណប័ណ្ណរបស់ម្ចាស់របស់អ្នកទទួលផល',
  "giving quantity":'ការផ្តល់បរិមាណ',
  "giving unit":'ផ្តល់ឱ្យអង្គភាព',
  "giving note":'ផ្តល់ចំណាំ',
  "import giving data":'ការនាំចូលទិន្នន័យផ្តល់ជូន',
  "Extract via campaign url path":'ស្រង់ចេញតាមមាគ៌ា URL យុទ្ធនាការ',
  "For example":'\ n ឧទាហរណ៍',
  "Date is required":'កាលបរិច្ឆេទត្រូវបានទាមទារ',
  "Including 4 characters, capitalization, unique":'ក្នុងនោះរាប់បញ្ចូលទាំង 4 តួអក្សរការធ្វើចំណាត់ថ្នាក់មូលធនដែលមានតែមួយគត់',
  "Value must be YES or NO":'តម្លៃត្រូវតែបាទ / ចាសឬទេ',
  "Value must be money, goods or blood":'តម្លៃត្រូវតែជា "លុយ", ទំនិញ "ទំនិញ" ឬ "ឈាម"',
  "Number is required":'លេខត្រូវបានទាមទារ',
  "Value must be male, female or null":'តម្លៃត្រូវតែជា "បុរស" ស្រី "ឬទទេ',
  "current size":'ទំហំបច្ចុប្បន្ន',
  "max size":'ទំហំអតិបរមា',
  "total row":'ជួរដេកសរុប',
  "success row":'ជួរជោគជ័យ',
  "row":'អុមតុក',
  "upload file":'ផ្ទុកឯកសារឡើង',
  "question from the campaign":'សំណួរពីយុទ្ធនាការ',
  "START":'បហ្ចេហ',
  "FINISH":'បហ្ចប់',
  "node":'ថ្នរ',
  "send a confirmation letter for the donation":'ផ្ញើលិខិតបញ្ជាក់សម្រាប់ការបរិច្ចាគ',
  "link page":'ទំព័រភ្ជាប់',
  "list of partners":'បញ្ជីដៃគូ',
  "short name":'ឈ្មោះ​ខ្លី',
  "first name":'ឈ្មោះដំបូង',
  "last name":'នាមត្រកូល',
  "alternative email":'អ៊ីមែលផ្សេង',
  "create partners":'បង្កើតដៃគូ',
  "edit partners":'កែសម្រួលដៃគូ',
  "delete partner":'លុបដៃគូ',
  "are you sure you want to delete this partner":'តើអ្នកពិតជាចង់លុបដៃគូនេះមែនទេ?',
  "total org members":'សមាជិក Org',
  "total org partners":'ដៃគូ',
  "total org link":'org តំណភ្ជាប់',
  "Volunteer":'អ្នកស្ផ័រកចិត្ដ',
  "Administrator":'នារីចាត់ចេងការ',
  "Office staff":'បុគ្គលិក​ការិយាល័យ',
  "Part-time employee":'និយោជិកក្រៅម៉ោង',
  "Assistant":'រសីអ្នកចមនយយការ',
  "Director":'នាយក',
  "Supporter":'អ្នកបែ',
  "total other activities":'សកម្មភាពសរុបផ្សេងទៀត',
  "total active donation events":'ព្រឹត្តិការណ៍អំណោយសកម្មសរុប',
  "total active giving events":'ព្រឹត្តិការណ៍ផ្តល់ជូនសកម្មសរុប',
  "total recruitments":'ការជ្រើសរើសបុគ្គលិកសរុប',
  "total event incoming":'ការចូលព្រឹត្តិការណ៍សរុប',
  "input partner name":'ឈ្មោះដៃគូបញ្ចូល',
  "select account wishare":'ជ្រើសរើសការរកឃើញគណនី',
  "total active recruitments events": 'ការជ្រើសរើសការងារសកម្ម',
  "subcategories organization": 'អង្គការរងប្រភេទ',
  "create subcategories organization": 'បង្កើតអង្គការរងប្រភេទ',
  "update subcategories organization": 'ធ្វើបច្ចុប្បន្នភាពអង្គគីរាប្រភេទ',
  "subcategories activity": 'សកម្មភាពរង',
  "create subcategories activity": 'បង្កើតសកម្មភាពរងប្រភេទ',
  "update subcategories activity": 'ធ្វើឱ្យទាន់សម័យសកម្មភាពរង',
  "subcategories": 'ក្រុមរង',
  "remove this": 'យកវាចេញ?',
  "list of documents": 'បញ្ជីឯកសារ',
  "list of channels": 'បញ្ជីឆានែល',
  "introduce channel": 'ណែនាំប៉ុស្តិ៍',
  "short description about your channel": 'ការពិពណ៌នាខ្លីអំពីឆានែលរបស់អ្នក',
  "share discussion": 'កិច្ចពិភាក្សាចែករំលែក',
  "choose category": 'ជ្រើសរើសប្រភេទ',
  "parent of subcategory": 'ឪពុកម្តាយនៃចំណាត់ថ្នាក់រង',
  "hours": 'ម៉ោង',
  "choose another Wishare subscription": 'ជ្រើសរើសការជាវប្រាថ្នាផ្សេងទៀត',
  "filter conditions by subcategory": 'លក្ខខណ្ឌនៃការច្រោះប្រភេទរង',
  "value to contribute": 'តម្លៃដើម្បីចូលរួមវិភាគទាន',
  "can giving": 'អាចផ្តល់ឱ្យ',
}
