import * as activityApi from '../../apis/activity'
import * as categoryApi from '../../apis/category'
import * as donationApi from '../../apis/donation'
import * as givingApi from '../../apis/giving'
import * as recruitmentApi from '../../apis/recruitment'
import * as topicApi from '../../apis/topic'
import * as userApi from '../../apis/user'
import * as beneficiaryApi from '../../apis/beneficiary'

export {
  userApi,
  beneficiaryApi,
  topicApi,
  givingApi,
  activityApi,
  donationApi,
  categoryApi,
  recruitmentApi,
}
