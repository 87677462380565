import en from './en/en_US'
import fr from './fr/fr_FR'
import vi from './vi/vi_VN'
import km from './km/km_KM'
import ko from './ko/ko_KO'

export const transToCorectCode = code => {
  switch (code) {
    case 'vi':
      return 'vi-VN'
    case 'km':
      return 'km-KM'
    case 'ko':
      return 'ko-KO'
    case 'fr':
      return 'fr-FR'
    default:
      return 'en-US'
  }
}
const translations = {
  vi: vi,
  en: en,
  fr: fr,
  km: km,
  ko: ko
}

export default translations
