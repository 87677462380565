import {Input} from 'antd'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {compose, fromRenderProps, withProps,} from 'recompose'
import {renderElse, renderIf,} from 'views/Shared'
import {useDebounceSearch} from 'views/Wishare/custom/DebounceSearch'
import {DonationRecordItem} from 'views/Wishare/Donation/components/DonationRecordItem'
import {DonationItemsSortBy} from 'views/Wishare/factory/createEntitySortBy'
import DonorContext from '../DonorContext'
import {DonorMyDonations} from './DonorMyDonations'

const InsightsContent = ({
  empty = null,
  donation_items = [],
}) => {
  if (_.isEmpty(donation_items)) {
    return empty
  }

  return Array.from(donation_items).map(
    (item, index) => {
      const {
        item_name,
        unit_name,
        target_unit_name,
        total_donation_value = 0,
        total_donation_qtty = 0,
      } = item || {}
      return (
        <Translate key={index}>
          {(t) => (
            <div className="flex flex-col">
              <span className="italic text-xs font-medium text-color-200">
                {'- '}
                {item_name === target_unit_name
                  ? t('money')
                  : t(item_name)}
                {' : '}
              </span>
              <div className="flex items-center ml-2 gap-1">
                <span className="text-sm text-primary font-semibold">
                  {Number(
                    total_donation_qtty
                  ).toLocaleString()}
                </span>
                <span className="text-sm text-primary font-semibold flex-1">
                  {unit_name}
                </span>
                {renderIf(
                  item_name !==
                    'blood' &&
                    item_name !==
                      target_unit_name,
                  <React.Fragment>
                    <span className="text-2xs italic font-light text-color-400">
                      {t(
                        'equivalent to'
                      )}
                    </span>
                    <span className="text-sm text-primary font-semibold">
                      {Number(
                        total_donation_value
                      ).toLocaleString()}{' '}
                      {target_unit_name}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Translate>
      )
    }
  )
}

export const DonorMyDonationList = ({
  donor,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [sort, setSort] = useState()

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  const { total_donation_items = [] } = donor || {}

  return (
    <Wrapper className="flex flex-col space-y-3">
      {/*<div className="p-3 uppercase text-blue-600 font-medium border border-primary rounded-lg text-center">*/}
      {/*  {t('your donation description')}*/}
      {/*</div>*/}

      {renderElse(
        _.isEmpty(total_donation_items),
        <div className="flex flex-col">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
            {t('donation insights')}
          </span>
          <div className="p-3 flex flex-col gap-1 border border-color-50 rounded-lg">
            <InsightsContent
              empty={
                <span className="text-color-300 text-sm italic">
                  {t('not added yet')}
                </span>
              }
              donation_items={
                total_donation_items
              }
            />
          </div>
        </div>
      )}

      <Input.Search
        className="input-search"
        placeholder={t(
          'search with donation code'
        )}
        onSearch={(value) =>
          onSearch(value)
        }
      />
      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {t('list of contributors')}
          </span>
          <div className="flex items-end justify-end">
            <DonationItemsSortBy
              value={sort}
              onSelect={(value) =>
                setSort(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <DonorMyDonations
            donor={donor}
            sort_by={sort}
            keyword={keyword}
            filtered_by="owner"
            RenderItem={withProps({
              showEvent: true,
              withoutContextMenu: true,
            })(DonationRecordItem)}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({ donor })
  )
)(DonorMyDonationList)
