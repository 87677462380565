import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, {
  useCallback,
} from 'react'
import { OrganizationContext } from 'views/Organization/OrganizationContext'
import OrganizationProvider from 'views/Organization/OrganizationProvider'
import { LoadingWrapper } from 'views/Shared'
import { wisharePaths } from '../configs'

const OrganizationContainerContext =
  React.createContext({
    item: {},
  })

export const OrganizationContainerProvider =
  ({
    item,
    children,
    routeEntities = [],
    routePathField = 'path',
    ...props
  }) => {
    const id = getId(item) || item

    const defaultRoute = _.first(
      routeEntities
    )

    const getDefaultAction = (
      current
    ) => {
      switch (current) {
        case wisharePaths.organization:
          return _.get(
            defaultRoute,
            routePathField
          )
        case '/profiles/:sub_type/:sub_id':
        case wisharePaths.organization_domain:
          return _.get(
            defaultRoute,
            routePathField
          )
        case wisharePaths.organizationProfiles:
          return _.get(
            defaultRoute,
            routePathField
          )
        case wisharePaths.organizationSettings_domain:
          return 'settings'
        default:
          return null
      }
    }

    const isValidRoute = useCallback(
      (route) => {
        return routeEntities
          .map((item) =>
            _.get(item, routePathField)
          )
          .includes(route)
      },
      [routeEntities, routePathField]
    )

    const renderHeader = useCallback(
      (action, isModal) => null,
      []
    )

    return (
      <LoadingWrapper isLoading={!item}>
        <OrganizationProvider
          key={id}
          id={id}>
          <OrganizationContext.Consumer>
            {({
              isSucceed,
              isLoading,
              organization,
            }) => {
              // if (!!isLoading) {
              //   return <LoadingPage />
              // }
              // if (
              //   !!!isLoading &&
              //   organization
              // ) {
              //   const { sub_domain } =
              //     organization
              //   if (
              //     !_.isEmpty(sub_domain)
              //   ) {
              //     return (
              //       <WillRedirect
              //         seconds={3}
              //         redirectUrl={[
              //           'https://',
              //           sub_domain,
              //           '.',
              //           _.last(
              //             ROOT_URL.split(
              //               '//'
              //             )
              //           ),
              //           wisharePaths.organization.replace(
              //             ':id',
              //             getId(
              //               organization
              //             )
              //           ),
              //         ].join('')}
              //       />
              //     )
              //   }
              // }
              return (
                <OrganizationContainerContext.Provider
                  key={id}
                  value={{
                    isSucceed,
                    isLoading,
                    organization,
                    renderHeader,
                    defaultRoute,
                    routeEntities,
                    routePathField,
                    isValidRoute,
                    getDefaultAction,
                    ...props,
                  }}>
                  {children}
                </OrganizationContainerContext.Provider>
              )
            }}
          </OrganizationContext.Consumer>
        </OrganizationProvider>
      </LoadingWrapper>
    )
  }

export default OrganizationContainerContext
