import { Avatar, Button } from 'antd'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import AppLogoSvg from 'components/Svgs/AppLogoSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import { toDataURL } from 'helpers/toDataUrl'
import { toSlug } from 'helpers/toSlug'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, {
  useContext,
  useRef,
  useState,
} from 'react'
import { exportComponentAsPNG } from 'react-component-export-image'
import { IoSaveOutline } from 'react-icons/io5'
import FlyWrapper from '../../../Templates/FlyWrapper'
import { getDonorName } from '../../functions/getDonorName'
import { LetterBackground } from './imageBase64'

const DonationThanksLetter =
  React.forwardRef(({ item }, ref) => {
    const t = useTranslate()
    const [
      base64RootOwnerAvatar,
      setBase64RootOwnerAvatar,
    ] = useState(null)
    const [
      base64OwnerAvatar,
      setBase64OwnerAvatar,
    ] = useState(null)

    const { isSm } = useContext(
      LayoutContext
    )
    const logoSize = isSm ? 60 : 80

    const {
      donation_event = {},
      idcode,
      transfer_dt,
      donation_items = [],
    } = item
    const name = getDonorName(item)

    const type_cd = donation_items.map(
      (e, i) => _.get(e, 'type_cd')
    )

    // const base64RootOwnerAvatar = getBase64Image(getAvatar(_.get(donation_event, 'root_owner')));
    // const base64OwnerAvatar = getBase64Image(getAvatar(_.get(donation_event, 'owner')));
    toDataURL(
      getAvatar(
        _.get(
          donation_event,
          'root_owner'
        )
      ),
      function (dataUrl) {
        setBase64RootOwnerAvatar(
          dataUrl
        )
      }
    )
    toDataURL(
      getAvatar(
        _.get(donation_event, 'owner')
      ),
      function (dataUrl) {
        setBase64OwnerAvatar(dataUrl)
      }
    )

    const total_donation_value =
      _.sumBy(
        donation_items,
        'donation_value'
      )
    const target_unit_name = _.get(
      item,
      'donation_event.target_unit_name'
    )

    const renderDonationItems = (
      className2
    ) =>
      donation_items.map((item, i) => {
        const unit = _.get(
          item,
          'unit_name'
        )
        const target_unit = _.get(
          donation_event,
          'target_unit_name'
        )
        const conversion_required =
          _.get(
            donation_event,
            'conversion_required'
          )

        if (unit === target_unit) {
          return (
            <div
              key={item.id}
              className={classNames(
                'flex justify-center text-primary font-medium space-x-1 leading-tight',
                className2 || 'text-2xl'
              )}>
              <span>
                {t(item.type_cd)}
              </span>
              <span>~</span>
              <span>
                {Number(
                  item.donation_value
                ).toLocaleString()}
              </span>
              <span>{target_unit}</span>
            </div>
          )
        } else if (
          conversion_required === 1
        ) {
          return (
            <div
              key={item.id}
              className={classNames(
                'flex justify-center text-primary font-medium space-x-1 leading-tight',
                !!className2
                  ? className2
                  : _.get(
                      item,
                      'item_name',
                      ''
                    ).length > 120
                  ? 'text-lg'
                  : _.get(
                      item,
                      'item_name',
                      ''
                    ).length > 80
                  ? 'text-xl'
                  : 'text-2xl'
              )}>
              <div className="flex-1 text-left space-x-1">
                <span>
                  {Number(
                    item.donation_qtty
                  ).toLocaleString()}
                </span>
                <span>
                  {item.unit_name}
                </span>
                <span>
                  ({item.item_name})
                </span>
              </div>
              <div className="text-right space-x-1">
                <span>~</span>
                <span>
                  {Number(
                    item.donation_value
                  ).toLocaleString()}
                </span>
                <span>
                  {target_unit}
                </span>
              </div>
            </div>
          )
        } else {
          return (
            <div
              key={item.id}
              className={classNames(
                'flex justify-center text-primary font-medium space-x-1 leading-tight text-2xl',
                !!className2
                  ? className2
                  : _.get(
                      item,
                      'item_name',
                      ''
                    ).length > 120
                  ? 'text-lg'
                  : _.get(
                      item,
                      'item_name',
                      ''
                    ).length > 80
                  ? 'text-xl'
                  : 'text-2xl'
              )}>
              <span>
                {Number(
                  item.donation_qtty
                ).toLocaleString()}
              </span>
              <span>
                {item.unit_name}
              </span>
              <span>
                ({item.item_name})
              </span>
            </div>
          )
        }
      })

    return (
      <div
        ref={ref}
        className="max-w-5xl flex flex-col space-y-6 justify-center items-center w-full p-0 lg:p-3">
        <div
          className="p-3 lg:p-6 mx-auto"
          style={{
            background: `url('${LetterBackground}') no-repeat`,
            backgroundSize: '100% 100%',
          }}>
          <div className="background p-6 rounded-lg">
            <div className="flex w-full">
              <div className="items-start justify-start h-80px w-80px">
                {!!_.get(
                  donation_event,
                  'root_owner.avatar'
                ) && (
                  <Avatar
                    className="text-3xl"
                    id="root_owner_avatar"
                    width={logoSize}
                    height={logoSize}
                    style={{
                      width: logoSize,
                      height: logoSize,
                      lineHeight:
                        logoSize,
                    }}
                    src={
                      base64RootOwnerAvatar
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="flex-1" />
              {_.get(
                donation_event,
                'root_owner.id'
              ) !==
                _.get(
                  donation_event,
                  'owner.id'
                ) && (
                <div className="flex items-end justify-end h-80px w-80px">
                  {!!_.get(
                    donation_event,
                    'owner.avatar'
                  ) && (
                    <Avatar
                      className="text-3xl"
                      style={{
                        width: logoSize,
                        height:
                          logoSize,
                        lineHeight:
                          logoSize,
                      }}
                      src={
                        base64OwnerAvatar
                      }
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
            <div className="w-full flex flex-col space-y-4 pt-6 justify-center items-center">
              <div className="flex flex-col items-center w-full">
                <div className="text-3xl lg:text-4xl font-bold text-primary uppercase leading-none text-center whitespace-no-wrap flex items-center justify-center w-full">
                  {t(
                    'sincerely thank you'
                  )}
                </div>
                <span className="text-sm lg:text-lg font-medium text-primary italic uppercase leading-tight flex items-center justify-center w-full">
                  {
                    'sincerely thank you'
                  }
                </span>
              </div>

              <div className="text-3xl text-center font-bold text-secondary uppercase leading-tight flex items-center justify-center w-full">
                {name}
              </div>

              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                <div className="text-color flex items-center justify-center w-full">
                  {t(
                    'supported the donation campaign'
                  )}
                </div>
                <span className="text-xs font-light text-color-300 italic flex items-center justify-center w-full">
                  {
                    'supported the donation campaign'
                  }
                </span>
              </div>

              <div className="text-xl lg:text-2xl font-medium text-center text-color-000 uppercase leading-tight flex items-center justify-center w-full">
                {_.get(
                  donation_event,
                  'title'
                )}
              </div>

              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                <div className="capitalize text-sm text-color flex items-center justify-center w-full">
                  {t('donation code')}
                </div>
                <span className="capitalize text-xs text-color-300 font-light italic flex items-center justify-center w-full">
                  {'Donation code'}
                </span>
                <div className="text-sm lg:text-lg font-bold text-secondary mt-2 flex items-center justify-center w-full">
                  {idcode}
                </div>
              </div>

              {type_cd === 'blood' ? (
                <span />
              ) : (
                <div className="text-sm lg:text-base w-full rounded-lg mx px-6 py-3 bg-pink-100">
                  <div className="flex flex-col items-center leading-tight mb-3 w-full">
                    <div className="text-color font-medium text-sm flex items-center justify-center w-full">
                      {t(
                        'money / goods'
                      )}
                    </div>
                    <div className="text-xs font-light italic text-color-300 flex items-center justify-center w-full">
                      {'Money / Goods'}
                    </div>
                  </div>
                  {donation_items.length <=
                  1 ? (
                    <React.Fragment>
                      {renderDonationItems(
                        null
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="flex flex-col w-full items-center justify-center">
                      <div
                        className={classNames(
                          'flex justify-center text-secondary font-bold space-x-1 leading-tight',
                          'text-2xl'
                        )}>
                        <span>
                          {Number(
                            total_donation_value
                          ).toLocaleString()}
                        </span>
                        <span>
                          {
                            target_unit_name
                          }
                        </span>
                      </div>
                      <div>
                        {renderDonationItems(
                          'text-base font-medium text-color-100'
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                <div className="text-center text-color flex items-center justify-center w-full">
                  {t(
                    'Sincere thanks to the benefactors for accompanying us to create a picture of kindness.'
                  )}
                </div>
                <div className="text-xs text-color-300 font-light italic text-center flex items-center justify-center w-full">
                  {
                    'Sincere thanks to the benefactors for accompanying us to create a picture of kindness.'
                  }
                </div>
              </div>

              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                <div className="text-center text-color flex items-center justify-center w-full">
                  {t(
                    'Wishing you good health, peace and happiness.'
                  )}
                </div>
                <div className="text-xs text-color-300 font-light italic text-center flex items-center justify-center w-full">
                  {
                    'Wishing you good health, peace and happiness.'
                  }
                </div>
              </div>

              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full mt-3">
                <div className="font-medium text-color flex items-center justify-center w-full">
                  {t('Kind regards,')}
                </div>
                <div className="text-xs text-color-300 font-light italic text-center flex items-center justify-center w-full">
                  {'Kind regards,'}
                </div>
              </div>
              <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                <div className="text-center font-bold text-color-org flex items-center justify-center w-full">
                  {getTitle(
                    _.get(
                      donation_event,
                      'root_owner'
                    )
                  )}
                </div>
                <div className="text-center text-sm text-color-300 italic flex items-center justify-center w-full">
                  {moment(
                    transfer_dt
                  ).format(
                    'DD-MM-YYYY'
                  )}
                </div>
              </div>

              <div className="w-full flex justify-between leading-tight">
                <div className="flex flex-col">
                  <div className="text-primary font-medium">
                    Wishare.com
                  </div>
                  <div className="text-xs text-color-300 italic">
                    {t(
                      'connecting the goodness'
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-end leading-tight">
                  <AppLogoSvg height="1rem" />
                  <div className="text-xs text-color-300 italic">
                    {
                      'Connecting the goodness'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })

const DonationThanksLetterWrapper = ({
  item,
}) => {
  const t = useTranslate()
  const componentRef = useRef()

  const name = getDonorName(item)
  const fileName =
    toSlug(name, '_') +
    '_ThankYou_Letter'

  return (
    <React.Fragment>
      <FlyWrapper className="z-10">
        <div className="flex p-3 space-x-3">
          <div className="flex-1" />
          <Button
            className="border-none rounded-lg space-x-3 flex items-center justify-center"
            onClick={() =>
              exportComponentAsPNG(
                componentRef,
                {
                  fileName:
                    fileName + '.png',
                  html2CanvasOptions: {
                    backgroundColor:
                      null,
                    foreignObjectRendering: true,
                    scrollY: 0,
                    x: 0,
                    y: 0,
                  },
                }
              )
            }>
            <IoSaveOutline />
            <span>
              {t('export as PNG')}
            </span>
          </Button>
        </div>
      </FlyWrapper>
      <DonationThanksLetter
        item={item}
        ref={componentRef}
      />
    </React.Fragment>
  )
}

export default DonationThanksLetterWrapper
