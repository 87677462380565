import {schema} from 'normalizr'
import {channelSchema} from './channel'
import {userSchema} from './user'
import {hostSchema} from "./host";

export const organizationSchema = new schema.Entity(
  'organizations',
  {
    channels: [channelSchema],
    hosts: [hostSchema],
    creator: userSchema
  },
  {
    idAttribute: 'idname'
  }
)
export const organizationSchemaArray = [
  organizationSchema
]
