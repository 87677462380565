import { donation_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DonationContext from '../DonationContext'

const formFields = [
  { name: 'donation_guide' },
  { name: 'tx_beneficiary_account_no' },
  {
    name: 'tx_beneficiary_account_name',
  },
  { name: 'tx_beneficiary_bank_name' },
  { name: 'tx_memo' },
]

const formSchema = [
  {
    title: 'donation guide',
    children: [
      {
        name: 'donation_guide',
        component:
          createControlledFormField({
            placeholder:
              'donation guide',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
  {
    title: 'account number',
    children: [
      {
        name: 'tx_beneficiary_account_no',
        component:
          createControlledFormField({
            maxLength: 120,
            showCount: true,
            placeholder:
              'account number',
          }),
      },
    ],
  },
  {
    title: 'account name',
    children: [
      {
        name: 'tx_beneficiary_account_name',
        component:
          createControlledFormField({
            maxLength: 120,
            showCount: true,
            placeholder: 'account name',
          }),
      },
    ],
  },
  {
    title: 'bank name',
    children: [
      {
        name: 'tx_beneficiary_bank_name',
        component:
          createControlledFormField({
            maxLength: 120,
            showCount: true,
            placeholder: 'bank name',
          }),
      },
    ],
  },
  {
    title: 'transaction memo',
    children: [
      {
        name: 'tx_memo',
        component:
          createControlledFormField({
            maxLength: 120,
            showCount: true,
            placeholder:
              'transaction memo',
          }),
      },
    ],
  },
]

const DonationSettingBeneficiary =
  () => {
    const { donation } = useContext(
      DonationContext
    )

    const validationSchema = undefined

    const donation_id = getId(donation)

    const onPreSubmit = undefined

    if (_.isEmpty(donation)) return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: donation,
      validationSchema,
      query: bindQueryParam({
        donation_id,
      }),
      onPreSubmit,
      apiInfo: donation_update_api,
    })
  }

export default DonationSettingBeneficiary
