import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import TagsPicker, {
  MultiCategoryPicker,
} from 'views/Wishare/custom/TagsPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { mapEntityOptions } from 'views/Wishare/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  {
    name: 'tags',
    defaultValue: undefined,
  },
  {
    name: 'categories',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    title: 'tags',
    children: [
      {
        name: 'tags',
        component:
          createControlledFormField({
            Component: TagsPicker,
          }),
      },
    ],
  },
  {
    title: 'categories',
    children: [
      {
        name: 'categories',
        component:
          createControlledFormField({
            Component:
              MultiCategoryPicker,
          }),
      },
    ],
  },
]

const RecruitmentSettingCategories =
  () => {
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema = undefined

    const recruitment_id = getId(
      recruitment
    )

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      onPreSubmit: ({
        tags,
        categories,
        ...values
      }) =>
        _.omitBy(
          {
            ...values,
            tags: tags
              ? Array.from(
                  tags || []
                ).join(',')
              : undefined,
            categories: categories
              ? Array.from(
                  categories || []
                ).join(',')
              : undefined,
          },
          _.isUndefined
        ),
      initialized: ({
        tags,
        categories,
        ...values
      }) => ({
        ...values,
        tags: Array.from(
          tags || []
        ).map(({ tags }) => tags),
        categories:
          mapEntityOptions(categories),
      }),
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingCategories
