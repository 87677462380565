import { Button } from 'antd'
import { getId } from 'apis/model/base'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { IoCallSharp } from 'react-icons/io5'
import {
  getFullAddress,
  renderIf,
  renderMail,
  renderOwnChild,
  renderPhone,
  renderSelf,
} from 'views/Shared'
import { ConversationWith } from 'views/Wishare/factory/createChatButton'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import OrganizationContainerContext from '../OrganizationContainerContext'

const ItemInfo = ({ label, value }) =>
  renderIf(
    !_.isEmpty(value),
    <Translate>
      {(t) => (
        <div className="flex items-start md:items-center gap-2 text-xs">
          <span className="font-light italic text-color-400">
            {[t(label), ':'].join('')}
          </span>
          <span className="font-medium flex-1">
            {value}
          </span>
        </div>
      )}
    </Translate>
  )

const ContactFooter = ({
  item,
  title,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const appConfig = useAppConfig()

  const role_titles = _.get(
    appConfig,
    'role_titles',
    []
  )

  return (
    <Wrapper className="mt-2">
      <div className="text-xs uppercase italic text-color-100 font-light border-b mb-1">
        {t('contact details')}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {[
          {
            name: 'prefix_name',
            label: 'representative',
            transform: (value) =>
              title &&
              [
                !!value
                  ? t(value)
                  : null,
                title,
              ].join(' '),
          },
          {
            name: 'role_title',
            label: 'position',
            transform: (value) =>
              _.get(role_titles, value),
          },
          {
            name: 'phone',
            label: 'phone',
            transform: renderPhone,
          },
          {
            name: 'phone2',
            label: 'alternative phone',
            transform: renderPhone,
          },
          {
            name: 'email',
            label: 'email',
            transform: renderMail,
          },
        ].map(
          (
            {
              name,
              label,
              transform = renderSelf,
            },
            index
          ) => (
            <ItemInfo
              key={index}
              label={label}
              value={transform(
                _.get(item, name)
              )}
            />
          )
        )}
      </div>
    </Wrapper>
  )
}

const ContactActions = ({
  phone,
  contact_member,
  organization_id,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const { user } = contact_member || {}

  return (
    <div className="flex flex-col gap-1">
      {contact_member && (
        <ConversationWith
          params={{
            receiver_id: getId(user),
          }}
        />
      )}
      {renderPhone(
        phone,
        (value) => (
          <Button
            type="primary"
            size="default"
            className="no-shadow no-text-shadow rounded-lg flex flex-center gap-2 w-full"
            icon={<IoCallSharp />}>
            {isSm ? null : value}
          </Button>
        ),
        ({ href, children }) => (
          <a
            href={href}
            className="no-underline">
            {children}
          </a>
        )
      )}
    </div>
  )
}

const ContactInfo = ({
  organization_id,
  ...contact
}) => {
  const { location } = contact || {}

  const address =
    getFullAddress(location)

  if (_.isEmpty(contact)) return null

  return (
    <div className="border rounded-lg p-2">
      <div className="flex items-center gap-2">
        <div className="flex flex-col flex-1">
          <span className="font-semibold text-color-org uppercase mb-2">
            {_.get(
              location,
              'location_name'
            )}
          </span>
          <ItemInfo
            label="address"
            value={address}
          />
        </div>
        <ContactActions
          {..._.pick(contact, [
            'phone',
            'contact_member',
          ])}
          organization_id={
            organization_id
          }
        />
      </div>
      <ContactFooter
        Wrapper={FlexCol}
        item={_.pick(contact, [
          'email',
          'phone',
          'phone2',
          'role_title',
          'prefix_name',
        ])}
        title={getTitle(contact)}
      />
    </div>
  )
}

const OrganizationContactInfo = ({
  Wrapper = FlexCol,
}) => {
  const { organization } = useContext(
    OrganizationContainerContext
  )

  const contacts = _.get(
    organization,
    'contacts',
    []
  )

  const organization_id = getId(
    organization
  )

  const body = useMemo(
    () =>
      contacts.map((contact, index) => (
        <ContactInfo
          key={index}
          {...contact}
          organization_id={
            organization_id
          }
        />
      )),
    [contacts, organization_id]
  )

  if (_.isEmpty(organization))
    return null

  return (
    <Wrapper className="items-start space-y-6">
      <HeaderItemInfo title="contact information">
        <div className="space-y-3">
          {body}
        </div>
      </HeaderItemInfo>
    </Wrapper>
  )
}

export default OrganizationContactInfo
