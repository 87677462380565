import {Tabs} from 'antd'
import {activities_getItemsByOwner_gets_api, organization_getActivitiesByHost_Api,} from 'apis'
import {getId, getType,} from 'apis/model/base'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useState,} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {compose, fromRenderProps,} from 'recompose'
import {Null, renderOwnChild,} from 'views/Shared'
import ActivityEntity from 'views/Wishare/Activity/ActivityEntity'
import ActivityItem from 'views/Wishare/Activity/components/ActivityItem'
import {ActivitiesSortBy} from 'views/Wishare/factory/createEntitySortBy'
import {bindQueryParam, bindQueryParams,} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import classNames from "classnames";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const TabOptions = Object.freeze({
  OTHERS: 'others',
  ACTIVITIES: 'activities',
})

const ItemStatistic = ({
                         label,
                         value,
                       }) => (
  <Translate>
    {(t) => (
      <div
        className="px-2 py-4 flex flex-col flex-center gap-1 border border-color-50 rounded-lg shadow-items cursor-pointer">
        <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
          {value.toLocaleString()}
        </span>
        <span className="leading-tight text-sm text-color-400 tracking-wide uppercase hover:text-primary">
          {t(label)}
        </span>
      </div>
    )}
  </Translate>
)

const Activities = ({
                      owner_id,
                      owner_type,
                      Wrapper = 'div',
                      filter_actions = {
                        sortBy: 'sort-by',
                        keyword: 'keyword',
                      },
                    }) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
               }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        activities_getItemsByOwner_gets_api,
        query: bindQueryParams([
          {id: owner_id},
          {type: owner_type},
        ]),
        RenderItem: ActivityItem,
        RenderEntity: ActivityEntity,
      },
      withKey: ({
                  sortBy,
                  keyword,
                  filterBy,
                }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      query_params: filter_actions,
      sortHeader: null,
      renderFilter: Null,
      AdvancedFilter: Null,
      SortByWidget: ActivitiesSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      {pager_widget}
      <div className="h-10"/>
    </Wrapper>
  )
}

const Others = ({
                  owner_id,
                  owner_type,
                  Wrapper = 'div',
                  filter_actions = {
                    sortBy: 'sort-by',
                    keyword: 'keyword',
                  },
                }) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
               }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        organization_getActivitiesByHost_Api,
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        RenderItem: ActivityItem,
        RenderEntity: ActivityEntity,
      },
      withKey: ({
                  sortBy,
                  keyword,
                  filterBy,
                }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      query_params: filter_actions,
      sortHeader: null,
      renderFilter: Null,
      SortByWidget: ActivitiesSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      {pager_widget}
      <div className="h-10"/>
    </Wrapper>
  )
}

const Widget = ({
                  owner_id,
                  owner_type,
                  Wrapper = renderOwnChild,
                }) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const [current, setCurrent] =
    useState(TabOptions.HOSTS)

  const {organization} = useContext(
    OrganizationContainerContext
  )

  const tabs = useMemo(
    () =>
      [
        {
          type: TabOptions.ACTIVITIES,
          title: 'activities',
          component: Activities,
        },
        {
          type: TabOptions.OTHERS,
          title: 'activities of member',
          component: Others,
        },
      ].map(
        ({
           type,
           title,
           component: Component,
         }) => (
          <Tabs.TabPane
            key={type}
            tab={
              <span>{t(title)}</span>
            }>
            <Component
              {...{
                owner_id,
                owner_type,
              }}
            />
          </Tabs.TabPane>
        )
      ),
    [current, owner_id, owner_type]
  )

  return (
    <Wrapper>

      {
        [
          {
            id: 'insights',
            title: 'insights',
            className: "border-2 border-color-act",
            containerClassName: "grid grid-cols-2 gap-3",
            linkTo: getLinkToDetail(organization) + '/activities',
            children: [
              {
                label: "activities", value: _.get(organization, 'total_activities', 0),

              },
              {
                label: "activity of co-owners", value: _.get(organization, 'total_other_activities', 0),
              }
            ]
          }
        ].filter(e => !!!e.hidden).map((e, i) => {
            return !!isSm ? (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <Link
                    to={e.linkTo}
                    className={classNames("px-6 py-4 flex flex-col gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline", e.className)}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <div key={ii} className="flex items-baseline gap-3 leading-tight">
          <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
            {ee.value.toLocaleString()}
          </span>
                          <span className="text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(ee.label)}
          </span>
                        </div>
                      ))
                    }
                  </Link>
                </HeaderItemInfo>
              )
              : (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <div className={e.containerClassName}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <ItemStatistic key={ii}
                                       className={e.className}
                                       label={ee.label}
                                       value={ee.value}
                                       to={e.linkTo}
                        />
                      ))
                    }
                  </div>
                </HeaderItemInfo>
              )
          }
        )
      }

      <Tabs
        defaultActiveKey={current}
        onChange={(activeKey) => {
          setCurrent(activeKey)
        }}>
        {tabs}
      </Tabs>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  )
)(Widget)
