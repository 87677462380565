import { Input } from 'antd'
import React from 'react'

const DecoratedInputField = ({
  label,
  ...props
}) => (
  <div className="flex flex-col px-3 py-1 border background rounded-lg">
    <div className="text-xs text-color-300">
      {label}
    </div>
    <Input {...props} />
  </div>
)

export default DecoratedInputField
