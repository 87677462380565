import useTranslate from "../../../modules/local/useTranslate";
import React, {useContext} from 'react';
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";


const EndCampaign = () => {
    const t = useTranslate()
    const {isSm} = useContext(LayoutContext)
    return (
        <div style={{position: 'absolute', top: isSm ? '15px' : '30px', left: isSm ? '10px' : '20px'}}>
            <span className="end-stamp-campaign">{t('finished')}</span>
        </div>
    )
}

export default EndCampaign