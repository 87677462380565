import { schema } from 'normalizr'
import { recruitmentEventSchema } from './recruitmentEvent'

export const candidateSchema =
  new schema.Entity(
    'candidate',
    {
      recruitment_event:
        recruitmentEventSchema,
    },
    {
      idAttribute: 'id',
    }
  )
