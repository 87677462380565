import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  compose,
  mapProps,
} from 'recompose'
import ImCatePicker from 'views/Wishare/Category/components/ImpCatePicker'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  {
    name: 'impact_categories',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    title: 'impact categories',
    children: [
      {
        name: 'impact_categories',
        component: compose(
          mapProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              name,
              value,
              onChange,
              className:
                wishareClassNames.inline_6,
            })
          )
        )(ImCatePicker),
      },
    ],
  },
]

const RecruitmentSettingImpCate =
  () => {
    const t = useTranslate()
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema =
      useMemo(() => {
        return yupShape({
          impact_categories:
            requiredString(t),
        })
      }, [t])

    const recruitment_event_id = getId(
      recruitment
    )

    const initialized = useCallback(
      ({
        impact_categories = [],
        ...values
      }) => {
        return {
          ...values,
          impact_categories:
            impact_categories.map(
              (category) =>
                getId(category)
            ),
        }
      },
      []
    )

    if (_.isEmpty(recruitment))
      return null

    const onPreSubmit = ({
      impact_categories = [],
      ...values
    }) => {
      return {
        ...values,
        impact_categories:
          impact_categories.join(','),
      }
    }

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id:
          recruitment_event_id,
      }),
      onPreSubmit,
      initialized,
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingImpCate
