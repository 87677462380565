import {
  MessageOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Menu,
  message,
} from 'antd'
import {
  commentContainerSchema,
  comment_addComment_Api,
  comment_addReply_Api,
  comment_editComment_Api,
  comment_getComments_Api,
  comment_getReples_Api,
  comment_removeComment_Api,
} from 'apis'
import { socketUnionSchema } from 'apis/schema'
import { commentSchema } from 'apis/schema/commentSchema'
import classnames from 'classnames'
import DrawMenu from 'components/drawer/DrawMenu'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import { Like } from 'components/Feed/Like'
import Timestamp from 'components/Feed/Timestamp'
import InputBar from 'components/InputBar'
import { LoginContext } from 'components/LoginContext'
import Null from 'components/NullComponent'
import { SelectEntityItem } from 'components/SelectEntityItem'
import { Formik } from 'formik'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import { normalize } from 'normalizr'
import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import { nest } from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { GoogleAdsRecommendedUnitHorizontal } from '../../components/GoogleAds/GoogleAds'
import { SocketContext } from '../../modules/socket/SocketContext'
import { JOIN_ROOM } from '../../modules/socket/types'
import { useVote } from '../Server/ServerContainer'
import { showDeleteConfirmDialog } from '../Wishare/factory/createConfirmDialog'

const Comment = ({
  item,
  className,
  canReply = true,
}) => {
  const {
    vote_status,
    total_votes,
    _type: type,
    id,
  } = item
  const dispatch = useDispatch()
  const t = useTranslate()
  const [openEdit, toggleOpenEdit] =
    useToggle()
  const handleVote = useVote({
    vote_status,
    total_votes,
    owner_type: type,
    owner_id: id,
  })
  const [
    openReplies,
    toggleOpenReplies,
  ] = useToggle()

  if (item.isDeleted) {
    return (
      <div
        className="background p-1">
        <div className="flex items-center gap-2">
          <Avatar
            src={
              _.get(
                item,
                'creator.avatar'
              ) ||
              _.get(
                item,
                'creator.thumbnail'
              )
            }
          />
          <div className="flex flex-col flex-1">
            <Link
              rel="nofollow"
              to={
                `/user/` +
                _.get(
                  item,
                  'creator.username'
                )
              }
              className="font-bold text-xs text-color-000">
              {_.get(
                item,
                'creator.name'
              )}
            </Link>
            <Timestamp
              className="text-color-500 text-2xs italic"
              timestamp={item.created}
            />
          </div>
        </div>
        <div className="text-red-500 text-sm italic ml-2 mt-2">
          {t('deleted')}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classnames(
        'background px-1 pt-2',
        className
      )}>
      <div className="flex items-center gap-2">
        <Avatar
          size={30}
          className="background border border-color-50"
          src={
            _.get(
              item,
              'creator.avatar'
            ) ||
            _.get(
              item,
              'creator.thumbnail'
            )
          }
        />
        <div className="flex flex-col flex-1">
          <Link
            rel="nofollow"
            to={
              `/user/` +
              _.get(
                item,
                'creator.username'
              )
            }
            className="font-bold text-xs text-color-000">
            {_.get(
              item,
              'creator.name'
            )}
          </Link>
          <Timestamp
            className="text-color-500 text-2xs italic"
            timestamp={item.created}
          />
        </div>
      </div>
      <div className="px-2 py-1 mt-1">
        {!openEdit ? (
          <Description
            type="dataset"
            mentions={_.get(
              item,
              'mentions'
            )}>
            {_.get(item, 'message')}
          </Description>
        ) : (
          <AsyncAction
            onSuccess={() => {
              message.info(t('success'))
              toggleOpenEdit()
            }}
            apiInfo={
              comment_editComment_Api
            }
            query={{
              ':prop':
                item.container_type,
              ':id': item.container_id,
              ':comment_id': item.id,
            }}>
            {({
              isLoading,
              handleAsyncAction,
            }) => (
              <Formik
                initialValues={{
                  message: _.get(
                    item,
                    'message'
                  ),
                }}
                onSubmit={
                  handleAsyncAction
                }>
                {({ values }) => (
                  <div className="relative">
                    <InputBar
                      placement="bottom"
                      defaultValue={
                        values.message
                      }
                      textOnly={true}
                      isLoading={
                        !!isLoading
                      }
                      placeholder={t(
                        'reply'
                      )}
                      handleSubmit={({
                        description,
                      }) =>
                        handleAsyncAction(
                          {
                            message:
                              description,
                          }
                        )
                      }
                    />
                    <div
                      onClick={() =>
                        toggleOpenEdit()
                      }
                      className="text-right text-xs cursor-pointer text-red-500 hover:text-red-600 italic">
                      {t('cancel')}
                    </div>
                  </div>
                )}
              </Formik>
            )}
          </AsyncAction>
        )}
      </div>
      <div
        className="flex justify-start items-center"
        style={{fontSize: '0.8rem'}}>
        <Like
          vote_status={item.vote_status}
          total_votes={item.total_votes}
          handleVote={() =>
            handleVote()
          }
        />
        {canReply && (
          <React.Fragment>
            {' ⋅ '}
            <Count
              defaultNumber={_.get(
                item,
                'total_replies'
              )}
              onClick={() => {
                toggleOpenReplies()
              }}
              component={
                MessageOutlined
              }
            />
          </React.Fragment>
        )}
        {item.edit && (
          <React.Fragment>
            {' ⋅ '}
            <DrawMenu
              component={
                <div className="p-2">
                  <Count
                    component={
                      MoreOutlined
                    }
                  />
                </div>
              }>
              {(isToggle, toggle) => (
                <Menu
                    style={{background: 'var(--background)'}}
                  onClick={(props) => {
                    switch (props.key) {
                      case 'edit':
                        toggleOpenEdit()
                        break
                      case 'delete':
                        showDeleteConfirmDialog(
                          {
                            translate: t,
                            onOk: () => {
                              dispatch(
                                createAsyncAction(
                                  {
                                    apiInfo: comment_removeComment_Api,
                                    query:
                                      {
                                        ':prop': item.container_type,
                                        ':id': item.container_id,
                                        ':comment_id': item.id,
                                      },
                                  }
                                )
                              )
                            },
                          }
                        )
                        break
                      default:
                        break
                    }
                    toggle()
                  }}>
                    <Menu.Item
                        key="edit"
                        className="px-3">
                        <div className="flex items-center gap-2">
                            <IoCreateOutline />
                            <span className="text-center font-medium">
                                {t('edit')}
                            </span>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        key="delete"
                        className="px-3">
                        <div className="flex items-center gap-2">
                            <IoTrashOutline />
                            <span className="text-center font-medium">
                                {t('delete')}
                            </span>
                        </div>
                    </Menu.Item>
                </Menu>
              )}
            </DrawMenu>
          </React.Fragment>
        )}
      </div>
      {openReplies && (
        <div className="ml-2 pl-2 border-l">
          <Replies
            type={item.container_type}
            id={item.container_id}
            comment_id={item.id}
          />
        </div>
      )}
    </div>
  )
}
const renderComment = (item) => (
  <Comment item={item} />
)
export const Responses = nest(
  ({ type, id }) => {
    const t = useTranslate()

    const dispatch = useDispatch()

    const { socket, authenticated } =
      useContext(SocketContext)
    const [refreshKey, setRefreshKey] =
      useState(Date.now())

    const {
      sortByDate: comments = [],
    } = useSelectEntities(
      `${type}_${id}`,
      commentContainerSchema,
      {}
    )

    const login = useContext(
      LoginContext
    )
    useEffect(() => {
      if (socket && type && id) {
        socket.emit(JOIN_ROOM, {
          roomId: id,
          roomType: type,
        })
      }
    }, [id, type, socket])

    useEffect(() => {
      if (!socket) return
      let timeout
      if (authenticated) {
        socket.off(
          'server-push-content'
        )
        socket.on(
          'server-push-content',
          (data) => {
            dispatch({
              type: 'entities',
              ...normalize(
                data,
                socketUnionSchema
              ),
            })
          }
        )
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }, [
      authenticated,
      dispatch,
      socket,
    ])

    return (
      <>
        {!login ? (
          <div className="flex flex-col space-y-3 mb-16">
            <div
              style={{
                height: '150px',
              }}
              className="flex flex-col flex-center gap-3 overflow-hidden border border-dashed rounded-lg mt-3">
              <div className="px-8 text-center text-sm text-color-400 italic">
                {t(
                  'Please log in to Wishare to leave your comment in the post'
                )}
              </div>
              <Link
                to={'/login'}
                style={{
                  padding: '6px 20px',
                }}
                className="bg-primary font-medium text-white hover:text-white no-underline rounded-full">
                {t('Login now')}
              </Link>
            </div>

            <GoogleAdsRecommendedUnitHorizontal />
          </div>
        ) : (
          <div className="z-10">
            <AsyncAction
              onSuccess={() => {
                message.info(
                  t('success')
                )
                setRefreshKey(
                  Date.now()
                )
              }}
              apiInfo={
                comment_addComment_Api
              }
              query={{
                ':prop': type,
                ':id': id,
              }}>
              {({
                isLoading,
                handleAsyncAction,
              }) => {
                return (
                  <InputBar
                    key={refreshKey}
                    placement="bottom"
                    textOnly={true}
                    isLoading={
                      !!isLoading
                    }
                    placeholder={t(
                      'reply'
                    )}
                    handleSubmit={logFunc(
                      ({
                        description,
                      }) =>
                        handleAsyncAction(
                          {
                            message:
                              description,
                          }
                        )
                    )}
                  />
                )
              }}
            </AsyncAction>
          </div>
        )}
        <div className="flex-1 space-y-1">
          {Object.keys(comments)
            .sort((a, b) => Number(b) - Number(a))
            .map((key) => comments[key]).map((key, index) => (
                  <SelectEntityItem
                      key={key}
                      item={key}
                      schema={commentSchema}>
                      {(item) => (
                          <Comment
                              key={item.id}
                              item={item}
                              className={index > 0 && 'border-t border-color-50'}
                          />
                      )}
                  </SelectEntityItem>
            ))}
          <LazyPagination
            key={comment_getComments_Api.path
              .replace(':prop', type)
              .replace(':id', id)}
            renderEmpty={Null}
            renderItem={(item) => {
              const ids =
                Object.values(comments)
              const hideRecentComment =
                _.find(ids, (value) =>
                  [
                    _.get(item, 'id'),
                    _.get(
                      item,
                      'idname'
                    ),
                  ].includes(value)
                )
              return hideRecentComment
                ? null
                : renderComment(item)
            }}
            apiInfo={
              comment_getComments_Api
            }
            query={{
              ':prop': type,
              ':id': id,
            }}
          />
        </div>
      </>
    )
  }
)
export const Replies = nest(
  ({ type, id, comment_id }) => {
    const t = useTranslate()
    const login = useContext(
      LoginContext
    )
    const [refreshKey, setRefreshKey] =
      useState(Date.now())
    const {
      sortByDate: comments = [],
    } = useSelectEntities(
      `${type}_${id}_${comment_id}`,
      commentContainerSchema,
      {}
    )
    return (
      <>
        {!login ? (
          <div
            style={{ height: '150px' }}
            className="flex items-center justify-center flex-col verticalList__large h-screen overflow-hidden border border-dashed rounded mb-16">
            <div className="px-8 flex items-center justify-center text-center">
              {t(
                'Please log in to Wishare to leave your comment in the post'
              )}
            </div>

            <Link
              to={'/login'}
              className="border rounded-full px-6 py-2 bg-primary text-white">
              {t('Login now')}
            </Link>
          </div>
        ) : (
          <div className="z-10">
            <AsyncAction
              onSuccess={() => {
                message.info(
                  t('success')
                )
                setRefreshKey(
                  Date.now()
                )
              }}
              apiInfo={
                comment_addReply_Api
              }
              query={{
                ':type': type,
                ':id': id,
                ':comment_id':
                  comment_id,
              }}>
              {({
                isLoading,
                handleAsyncAction,
              }) => {
                return (
                  <InputBar
                    key={refreshKey}
                    textOnly={true}
                    isLoading={
                      !!isLoading
                    }
                    placement="bottom"
                    placeholder={t(
                      'reply'
                    )}
                    handleSubmit={logFunc(
                      ({
                        description,
                      }) => {
                        handleAsyncAction(
                          {
                            message:
                              description,
                          }
                        )
                      }
                    )}
                  />
                )
              }}
            </AsyncAction>
          </div>
        )}
        <div className="flex-1 verticalList">
          {Object.keys(comments)
            .sort(
              (a, b) => Number(b) - Number(a)
            )
            .map((key) => comments[key])
            .map((key) => (
              <SelectEntityItem
                key={key}
                item={key}
                schema={commentSchema}>
                {(item) => (
                  <Comment
                    key={item.id}
                    item={item}
                    canReply={false}
                  />
                )}
              </SelectEntityItem>
            ))}
          <LazyPagination
            renderEmpty={Null}
            renderItem={(item) => {
              const ids =
                Object.values(comments)
              const hideRecentComment =
                _.find(ids, (value) =>
                  [
                    _.get(item, 'id'),
                    _.get(
                      item,
                      'idname'
                    ),
                  ].includes(value)
                )
              return hideRecentComment
                ? null
                : renderComment(item)
            }}
            query={{
              ':type': type,
              ':id': id,
              ':comment_id': comment_id,
            }}
            apiInfo={
              comment_getReples_Api
            }
          />
        </div>
      </>
    )
  }
)
