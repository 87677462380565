import React from "react";

function DefaultMedal({size = 30, ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                fill="#FDBC4B"
                d="M152.048 393.485h207.888c57.344-35.136 95.808-98.208 95.808-170.24 0-110.144-89.616-199.744-199.744-199.744s-199.744 89.6-199.744 199.744c0 72.048 38.464 135.104 95.792 170.24z"
            ></path>
            <path
                fill="#FFE28F"
                d="M256 379.853c-86.352 0-156.608-70.256-156.608-156.608S169.648 66.637 256 66.637s156.608 70.256 156.608 156.608S342.352 379.853 256 379.853z"
            ></path>
            <path
                fill="#FFF"
                d="M321.168 159.629H202.496c-10.864 0-18.688-8.912-18.688-19.776.016 0-.016-.432 0-.432 0-10.656 7.824-19.776 18.688-19.776h78.032c11.952 0 22.608-8.912 23.264-20.864.656-13.04-9.568-23.472-22.176-23.472h-76.384C143.808 96.493 99.504 154.717 99.504 223.245c0 71.104 47.664 131.168 112.672 150.224 2.512-.88 5.168-1.472 7.92-1.472h57.36c11.952-.224 21.52-10 21.52-22.176s-10-22.176-22.176-22.176h-78.656c-10.432 0-18.912-8.48-18.688-18.688 0-13.952 8.064-20.432 17.6-20.432h95.856c12.176 0 22.816-8.912 23.696-20.864.432-12.832-9.568-23.472-22.384-23.472h-88.688c-9.632 0-18.48-6.768-18.48-20.208-.224-10.864 8.688-19.792 19.568-19.792h113.248c12.176 0 22.816-8.912 23.696-21.088.416-12.816-9.584-23.472-22.4-23.472z"
                enableBackground="new"
                opacity="0.3"
            ></path>
            <path
                fill="#FFCC5B"
                d="M256 91.773L286.736 186.349 386.192 186.349 305.728 244.813 336.464 339.405 256 280.941 175.536 339.405 206.272 244.813 125.808 186.349 225.264 186.349z"
            ></path>
            <g fill="#FDBC4B">
                <path d="M256 91.773L256 226.781 286.736 186.349z"></path>
                <path d="M256 226.781L386.192 186.349 305.728 244.813z"></path>
                <path d="M256 226.781L336.464 339.405 256 280.941z"></path>
                <path d="M256 226.781L206.272 244.813 175.536 339.405z"></path>
                <path d="M125.808 186.349L225.264 186.349 256 226.781z"></path>
            </g>
            <g fill="#E95454">
                <path d="M512 452.573c-64.896-47.328-129.808-40.656-194.704-21.072v-82.08c64.896-19.6 129.808-26.256 194.704 21.072-14.848 2.864-29.68 8.544-44.528 16.56 14.848 19.328 29.68 41.008 44.528 65.52zM0 452.573c64.896 47.328 129.808 40.656 194.704 21.072v-82.08C129.808 411.149 64.896 417.805 0 370.477c14.848 24.496 29.68 46.176 44.528 65.536-14.848 8.016-29.68 13.696-44.528 16.56z"></path>
            </g>
            <path
                fill="#ED6362"
                d="M403.824 389.565c-98.56-.016-197.104 71.872-295.664 71.856v-82.08c98.56.016 197.104-71.872 295.664-71.856v82.08z"
            ></path>
            <g fill="#CD494C">
                <path d="M317.296 431.501c28.848-17.728 57.696-32.912 86.528-41.936-28.848 0-57.696 6.16-86.528 14.864v27.072zM194.704 473.645c-28.848-.32-57.696-3.2-86.544-12.224 28.848 0 57.696-6.144 86.544-14.864v27.088z"></path>
            </g>
        </svg>
    );
}

export default DefaultMedal;
