import { Checkbox } from 'antd'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import React, {
  useCallback,
} from 'react'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'

const CascadedCheckbox = ({
  children,
  cascades = [],
  onChange = Null,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const onQueue = useCallback(
    (items = []) => {
      _.forEach(
        items,
        ({ name, value }) => {
          onChange(
            createValue(name, value)
          )
        }
      )
    },
    [onChange]
  )

  const handleChange = useCallback(
    (event) => {
      onChange(event)
      const _value = _.get(
        event,
        'target.checked'
      )
      if (_value) {
        if (!_.isEmpty(cascades)) {
          onQueue(cascades)
        }
      }
    },
    [cascades, onQueue, onChange]
  )

  return (
    <Wrapper>
      <Checkbox
        {...props}
        onChange={handleChange}>
        {children}
      </Checkbox>
    </Wrapper>
  )
}

export default CascadedCheckbox
