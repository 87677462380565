import Icon, {
  FontSizeOutlined,
  MenuOutlined,
  SettingOutlined,
  TwitterOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Drawer,
  Radio,
  Result,
  Spin,
} from 'antd'
import {
  article_getById_Api,
  poll_get_Api,
  post_getPostbyId_Api,
} from 'apis'
import { channelModel } from 'apis/model'
import {
  getId,
  getType,
} from 'apis/model/base'
import {
  articleSchema,
  pollSchema,
  postSchema,
} from 'apis/schema'
import classnames from 'classnames'
import { Keywords } from 'components/Feed/BaseEmbed'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import { FeedContext } from 'components/Feed/FeedCard'
import { FeedProvider } from 'components/Feed/FeedProvider'
import { Message } from 'components/Feed/Message'
import DetailPost from 'components/Feed/Post/DetailPost'
import { Share } from 'components/Feed/Share'
import {
  allActions,
  createTimeLineFeed,
  defaultDetailComponents,
} from 'components/Feed/TimeLineFeed'
import Timestamp from 'components/Feed/Timestamp'
import { Vote } from 'components/Feed/Vote'
import { GoogleAdsRecommendedUnit } from 'components/GoogleAds/GoogleAds'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Null from 'components/NullComponent'
import coverToEditorStateFromRaw from 'components/RichEditor/coverToEditorStateFromRaw'
import RichEditor from 'components/RichEditor/RichEditor'
import TableOfContents from 'components/RichEditor/TableOfContents'
import { SelectEntityItem } from 'components/SelectEntityItem'
import SeparatedDot from 'components/SeparatedDot'
import Toggle from 'components/Toggle'
import { WithDetailPopup } from 'components/WithDetailPopup'
import { ROOT_URL } from 'envs/_current/config'
import {
  callNativeShare,
  createFacebookShareLink,
  createTwitterShareLink,
} from 'helpers'
import getTitle from 'helpers/getTitle'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import useRouter, {
  useHistory,
} from 'modules/navigation/useRouter'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  FaBookmark,
  FaFacebookF,
  FaRegBookmark,
  FaShareAlt,
} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  branch,
  compose,
  mapProps,
} from 'recompose'
import { selectEntities } from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import LoadingPage from 'views/LoadingPage'
import { paths } from 'views/MainPage/contains'
import { HeaderButton } from 'views/Organization/components/PageHeader'
import { Cate } from './Cate'
import { HorizontalList } from './components/HorizontalList'
import './Feed.css'
import { FeedMoreRecommends } from './FeedMoreRecommends'
import { Responses } from './Responses'

export const FeedContentTypes =
  Object.freeze({
    ALL: 'all',
    POST: 'post',
    REPOST: 'repost',
    ARTICLE: 'article',
    CHANNEL: 'channel',
  })

export const FeedActionTypes =
  Object.freeze({
    ADS: 'advertise',
    STICKY: 'sticky',
  })

export const isValidContentType = (
  type,
  excluded = [FeedContentTypes.ALL]
) =>
  !_.isEmpty(type) &&
  !excluded.includes(type)

export const feedContentTypes = [
  {
    label: 'all',
    value: FeedContentTypes.ALL,
  },
  {
    label: 'article',
    value: FeedContentTypes.ARTICLE,
  },
  {
    label: 'news',
    value: FeedContentTypes.REPOST,
  },
  {
    label: 'q-and-a',
    value: FeedContentTypes.POST,
  },
].map(({ value, ...item }) => ({
  ...item,
  value,
  name: value,
}))

export const fromFeedContentTypes = (
  type
) => {
  return feedContentTypes.find(
    (item) =>
      type === _.get(item, 'value')
  )
}

const DetailFeed = createTimeLineFeed(
  defaultDetailComponents,
  {
    ...allActions,
    to: false,
    message: false,
  }
)

const Article = ({ id }) => {
  const t = useTranslate()
  const { handleGoBack, history } =
    useRouter()

  const [editorState, setEditorState] =
    useState()
  const [textSize, setTextSize] =
    useState('default')
  const item = useSelector(
    (state) =>
      selectEntities(
        state,
        id,
        articleSchema
      ) ||
      _.find(
        _.values(
          _.get(
            state,
            'entities.articles'
          )
        ),
        { idname: id }
      )
  )
  useEffect(() => {
    if (item) {
      const contentObj = JSON.parse(
        item.content
      )

      let maxKey = Object.keys(
        contentObj.entityMap
      ).length // get max key
      const keywords = item.keywords
      let transformedEntityMap = _.map(
        contentObj.entityMap,
        function (e, key) {
          return e
        }
      )

      const transformedBlocks = _.map(
        contentObj.blocks,
        function (e) {
          let preStrLength = 0
          let offset = 0
          let entityRanges = [
            ...e.entityRanges,
          ]
          _.map(
            keywords || [],
            function (kw) {
              if (
                (offset = e.text
                  .toLowerCase()
                  .indexOf(
                    kw.keyword.toLowerCase(),
                    offset +
                      preStrLength
                  ))
              ) {
                if (offset >= 0) {
                  entityRanges = [
                    ...entityRanges,
                    {
                      offset,
                      key: maxKey,
                      length:
                        kw.keyword
                          .length,
                    },
                  ]

                  transformedEntityMap =
                    [
                      ...transformedEntityMap,
                      {
                        data: {
                          url:
                            '/t/' +
                            kw.idname,
                        },
                        mutability:
                          'MUTABLE',
                        type: 'LINK',
                      },
                    ]

                  maxKey++
                  preStrLength =
                    kw.keyword.length
                }
              }
            }
          )

          return {
            ...e,
            entityRanges,
          }
        }
      )
      const content = {
        entityMap: transformedEntityMap,
        blocks: transformedBlocks,
      }
      setEditorState(
        coverToEditorStateFromRaw(
          content
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item && item.update])

  const {
    isLoading,
    success,
    errorMessages,
    ...rest
  } = useAsync({
    apiInfo: article_getById_Api,
    query: {
      ':article_id': id,
    },
  })
  return (
    <ContentLayout
      title={t('article')}
      renderMenu={Null}
      extra={
        item && (
          <>
            {item.edit && (
              <HeaderButton
                onClick={() =>
                  history.push(
                    `/article/${id}/edit`
                  )
                }>
                <SettingOutlined
                  style={{
                    fontSize: '1.4em',
                  }}
                />
              </HeaderButton>
            )}
            <div className="flex mr-2">
              <HeaderButton
                component="a"
                href={createFacebookShareLink(
                  `${ROOT_URL}/news/article/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full">
                    <Icon
                      component={
                        FaFacebookF
                      }
                    />
                  </div>
                }
              />
              <HeaderButton
                component="a"
                href={createTwitterShareLink(
                  `${ROOT_URL}/news/article/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full ">
                    <Icon
                      component={
                        TwitterOutlined
                      }
                    />
                  </div>
                }
              />
              <HeaderButton
                onClick={() => {
                  callNativeShare({
                    title:
                      channelModel.getTitle(
                        item
                      ),
                    url: `${ROOT_URL}/news/article/${id}`,
                  })
                }}
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full">
                    <Icon
                      component={
                        FaShareAlt
                      }
                    />
                  </div>
                }
              />
            </div>
          </>
        )
      }
      right={
        item && (
          <>
            {!_.isEmpty(
              _.get(
                item,
                'others_in_container'
              )
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                  {t(
                    'related articles'
                  )}
                </h2>
                <FeedMoreRecommends
                  item={item.container}
                  list={
                    item.others_in_container
                  }
                />
              </>
            )}
            {!_.isEmpty(
              _.get(
                item,
                'others_in_creator'
              )
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                  {t('from the sender')}
                </h2>
                <FeedMoreRecommends
                  item={item.creator}
                  list={
                    item.others_in_creator
                  }
                />
              </>
            )}
            <GoogleAdsRecommendedUnit />
          </>
        )
      }
      onCancel={handleGoBack}>
      <Spin spinning={isLoading}>
        {success &&
          item &&
          (editorState || null) && (
            <article>
              <div
                onContextMenu={
                  item.content_disabled
                    ? preventParentEvent
                    : undefined
                }
                className={classnames(
                  'flex mx-auto w-full',
                  item.content_disabled &&
                    'content_disabled'
                )}>
                <div className="w-full">
                  <div className="px-3 relative">
                    <div className="verticalList">
                      <div className="inline-flex p-1 pt-3 border-b w-full truncate items-center justify-start text-color-300 flex">
                        <div
                          style={{
                            marginRight:
                              '1rem',
                            fontSize:
                              '2rem',
                          }}
                          className="flex-shrink-0">
                          <ChannelIcon
                            premium={_.get(
                              item,
                              'container.premium',
                              0
                            )}
                            className="mr-2 text-color-100 text-3xl w-8 h-8"
                          />
                        </div>
                        <div className="flex-1">
                          <Link
                            to={channelModel.getLinkToDetail(
                              _.get(
                                item,
                                'container'
                              )
                            )}
                            className="font-bold cursor-pointer">
                            {_.get(
                              item,
                              'container.title'
                            ) ||
                              _.get(
                                item,
                                'container.name'
                              )}
                          </Link>
                          <div className="font-normal text-xs">
                            {_.get(
                              item,
                              'container.owner.title'
                            ) ||
                              _.get(
                                item,
                                'container.owner.name'
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="flex">
                        <HorizontalList
                          className="flex-1 pr-2"
                          renderMore={
                            Null
                          }
                          list={
                            item.categories
                          }
                          renderItem={(
                            cate
                          ) => (
                            <Link
                              to={
                                paths.categoryPath
                                  .replace(
                                    ':category',
                                    cate.id
                                  )
                                  .replace(
                                    ':refcategory',
                                    cate.alt_idname
                                  ) +
                                '?lang=' +
                                cate.language
                              }>
                              {' '}
                              <Cate
                                item={
                                  cate
                                }
                              />
                            </Link>
                          )}
                        />

                        <Toggle>
                          {(
                            isToggle,
                            toggle
                          ) => (
                            <>
                              <div className="background p-1">
                                <div
                                  style={{
                                    top: 'var(--header-height)',
                                  }}
                                  className="sticky flex items-center">
                                  <div className="py-3 font-bold">
                                    <Radio.Group
                                      defaultValue={
                                        textSize
                                      }
                                      size="middle"
                                      onChange={(
                                        e
                                      ) =>
                                        setTextSize(
                                          e
                                            .target
                                            .value
                                        )
                                      }>
                                      <Radio.Button value="default">
                                        <FontSizeOutlined className="text-xs" />
                                      </Radio.Button>
                                      <Radio.Button value="large">
                                        <FontSizeOutlined className="text-lg" />
                                      </Radio.Button>
                                    </Radio.Group>
                                  </div>

                                  <HeaderButton
                                    onClick={() =>
                                      toggle()
                                    }>
                                    <MenuOutlined />
                                  </HeaderButton>
                                </div>
                              </div>
                              {isToggle && (
                                <Drawer
                                  title={t(
                                    'table of contents'
                                  )}
                                  visible="true"
                                  onClose={() =>
                                    toggle()
                                  }>
                                  <TableOfContents
                                    editorState={
                                      editorState
                                    }
                                  />
                                </Drawer>
                              )}
                            </>
                          )}
                        </Toggle>
                      </div>
                    </div>
                  </div>
                  <div
                    style={
                      textSize ===
                      'default'
                        ? {
                            fontSize:
                              '1em',
                          }
                        : {
                            fontSize:
                              '1.2em',
                          }
                    }>
                    <h1
                      style={{
                        fontSize: '2em',
                      }}
                      className="px-3 pt-3 font-bold leading-tight">
                      {item.title}
                    </h1>
                    <div className="px-3 text-color-300 text-sm">
                      <SeparatedDot />
                      <Timestamp
                        className="font-normal"
                        timestamp={_.get(
                          item,
                          'created'
                        )}
                      />
                      {!!item.total_views && (
                        <>
                          <SeparatedDot />
                          <span className="font-bold">
                            {
                              item.total_views
                            }
                          </span>{' '}
                          {t('views')}
                        </>
                      )}
                    </div>
                    <WithDetailPopup
                      item={item.owner}
                      style={{
                        textOverflow:
                          'ellipsis',
                      }}>
                      <div className="flex items-center px-3 py-1">
                        <Avatar
                          style={{
                            marginRight:
                              '1em',
                          }}
                          size="small"
                          shape="circle"
                          src={_.get(
                            item,
                            'owner.thumbnail'
                          )}>
                          {getUpperFirstChar(
                            getTitle(
                              item.owner
                            )
                          )}
                        </Avatar>
                        <div className="truncate flex-1 flex flex-col">
                          <span className="font-semibold ">
                            {_.get(
                              item,
                              'owner.name'
                            ) ||
                              _.get(
                                item,
                                'owner.title'
                              )}
                          </span>
                        </div>
                      </div>
                    </WithDetailPopup>
                    {item.description && (
                      <div className="p-3 ">
                        <Description
                          type={
                            'article'
                          }
                          mentions={
                            item &&
                            item.mentions
                          }>
                          {
                            item.description
                          }
                        </Description>
                      </div>
                    )}
                    <div className="p-3 w-full verticalList mx-auto ">
                      <RichEditor
                        readOnly
                        editorState={
                          editorState
                        }
                      />
                      <Keywords
                        keywords={_.get(
                          item,
                          'keywords',
                          []
                        )}
                      />
                    </div>
                  </div>
                  <SelectEntityItem
                    item={item.id}
                    schema={
                      articleSchema
                    }>
                    {(item) => (
                      <FeedProvider
                        item={item}
                        defaultActions={
                          allActions
                        }>
                        <FeedContext.Consumer>
                          {({
                            handleClick,
                            shareUrl,
                          }) => (
                            <div className="sticky bottom-0 z-20 p-2 flex items-center background">
                              <Vote />
                              <Message />
                              <Share
                                url={
                                  shareUrl
                                }
                              />
                              <Count
                                active={
                                  item.bookmark_status
                                }
                                onClick={
                                  !item.bookmark_status
                                    ? () =>
                                        handleClick(
                                          'bookmark'
                                        )
                                    : () =>
                                        handleClick(
                                          'unbookmark'
                                        )
                                }
                                key="bookmark"
                                color={
                                  item.bookmark_status
                                    ? 'text-yellow-500'
                                    : 'text-color-300'
                                }
                                component={
                                  item.bookmark_status
                                    ? FaBookmark
                                    : FaRegBookmark
                                }
                              />
                            </div>
                          )}
                        </FeedContext.Consumer>
                      </FeedProvider>
                    )}
                  </SelectEntityItem>
                </div>
              </div>
              <div className="background-100">
                <div className="py-4 mx-auto mt-6 w-full verticalList p-3 flex flex-col">
                  <h2 className="font-bold ">
                    {t('responses')}
                  </h2>
                  <Responses
                    id={getId(item)}
                    type={getType(item)}
                  />
                </div>
              </div>
              <LayoutContext.Consumer>
                {({ breakpoint }) =>
                  [
                    'xs',
                    'sm',
                    'md',
                  ].find(
                    (value) =>
                      value ===
                      breakpoint
                  )
                    ? item && (
                        <>
                          {!_.isEmpty(
                            item.others_in_container
                          ) && (
                            <>
                              {!_.isEmpty(
                                item.others_in_container
                              ) && (
                                <>
                                  <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                                    {t(
                                      'related articles'
                                    )}
                                  </h2>
                                  <FeedMoreRecommends
                                    item={
                                      item.container
                                    }
                                    list={
                                      item.others_in_container
                                    }
                                  />
                                </>
                              )}
                              {!_.isEmpty(
                                item.others_in_creator
                              ) && (
                                <>
                                  <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                                    {t(
                                      'from the sender'
                                    )}
                                  </h2>
                                  <FeedMoreRecommends
                                    item={
                                      item.creator
                                    }
                                    list={
                                      item.others_in_creator
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}

                          <GoogleAdsRecommendedUnit />
                        </>
                      )
                    : null
                }
              </LayoutContext.Consumer>
            </article>
          )}
        {errorMessages && (
          <Result
            status="404"
            subTitle={
              errorMessages['error']
            }
            extra={
              <Link
                to={staticPaths.home}>
                <Button type="primary">
                  {t('Back Home')}
                </Button>
              </Link>
            }
          />
        )}
      </Spin>
    </ContentLayout>
  )
}

export default compose(
  mapProps((props) => ({
    id: _.get(props, 'match.params.id'),
    key: _.get(
      props,
      'match.params.id'
    ),
    type: _.get(
      props,
      'match.params.type'
    ),
  })),

  branch(
    ({ type }) => type === 'article',
    () => Article
  )
)(({ id, type }) => {
  const history = useHistory()
  const t = useTranslate()
  const item = useSelector((state) =>
    selectEntities(
      state,
      id,
      type === 'poll_question'
        ? pollSchema
        : type === 'article'
        ? articleSchema
        : postSchema
    )
  )
  const { handleGoBack } = useRouter()
  const {
    isLoading,
    errorMessages,
    ...rest
  } = useAsync(
    type === 'poll_question'
      ? createAsyncAction({
          apiInfo: poll_get_Api,
          query: {
            ':poll_question_id': id,
          },
        })
      : type === 'article'
      ? createAsyncAction({
          apiInfo: article_getById_Api,
          query: {
            ':article_id': id,
          },
        })
      : createAsyncAction({
          apiInfo: post_getPostbyId_Api,
          query: {
            ':post_id': id,
          },
        })
  )

  const isPost =
    type === 'post' &&
    _.get(item, 'action') === 'create'

  return (
    <ContentLayout
      title={t('post')}
      renderMenu={Null}
      extra={
        item &&
        type === 'post' && (
          <>
            {/* {item.edit && (
              <HeaderButton
                onClick={() => history.push(`/news/post/${id}/edit`)}
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full">
                    <Icon component={IoMdSettings} />
                  </div>
                }
              />
            )} */}
            <div className="flex mr-2">
              <HeaderButton
                component="a"
                href={createFacebookShareLink(
                  `${ROOT_URL}/news/post/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className=" rounded-full">
                    <Icon
                      component={
                        FaFacebookF
                      }
                    />
                  </div>
                }
              />
              <HeaderButton
                component="a"
                href={createTwitterShareLink(
                  `${ROOT_URL}/news/post/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className=" rounded-full">
                    <Icon
                      component={
                        TwitterOutlined
                      }
                    />
                  </div>
                }
              />
              <HeaderButton
                onClick={() => {
                  callNativeShare({
                    title:
                      channelModel.getTitle(
                        item
                      ),
                    url: `${ROOT_URL}/news/post/${id}`,
                  })
                }}
                width={42}
                height={42}
                icon={
                  <div className=" rounded-full">
                    <Icon
                      component={
                        FaShareAlt
                      }
                    />
                  </div>
                }
              />
            </div>
          </>
        )
      }
      right={
        item && (
          <>
            {!_.isEmpty(
              item.others_in_container
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                  {t(
                    'related articles'
                  )}
                </h2>
                <FeedMoreRecommends
                  item={item.container}
                  list={
                    item.others_in_container
                  }
                />
              </>
            )}
            {!_.isEmpty(
              item.others_in_creator
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                  {t('from the sender')}
                </h2>
                <FeedMoreRecommends
                  item={item.creator}
                  list={
                    item.others_in_creator
                  }
                />
              </>
            )}

            <GoogleAdsRecommendedUnit />
          </>
        )
      }
      onCancel={handleGoBack}>
      {item && (
        <>
          <div
            key={item.id}
            className="p-3 flex-1 w-full max-w-4xl mx-auto flex flex-col verticalList">
            {item ? (
              <>
                <div className="Feed verticalList">
                  {isPost ? (
                    <DetailPost
                      detail={true}
                      item={item}
                    />
                  ) : (
                    <DetailFeed
                      detail={true}
                      item={item}
                    />
                  )}
                  <Keywords
                    keywords={_.get(
                      item,
                      'keywords',
                      []
                    )}
                  />
                </div>
                <Responses
                  id={getId(item)}
                  type={getType(item)}
                />
              </>
            ) : (
              <LoadingPage />
            )}
          </div>
          <LayoutContext.Consumer>
            {({ breakpoint }) =>
              ['xs', 'sm', 'md'].find(
                (value) =>
                  value === breakpoint
              ) ? (
                <>
                  {!_.isEmpty(
                    item.others_in_container
                  ) && (
                    <>
                      <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                        {t(
                          'related articles'
                        )}
                      </h2>
                      <FeedMoreRecommends
                        item={
                          item.container
                        }
                        list={
                          item.others_in_container
                        }
                      />
                    </>
                  )}
                  {!_.isEmpty(
                    item.others_in_creator
                  ) && (
                    <>
                      <h2 className="font-bold border-b-2 background-200 border-blue-400 p-2 pl-3 md:text-lg text-xl">
                        {t(
                          'from the sender'
                        )}
                      </h2>
                      <FeedMoreRecommends
                        item={
                          item.creator
                        }
                        list={
                          item.others_in_creator
                        }
                      />
                    </>
                  )}

                  <GoogleAdsRecommendedUnit />
                </>
              ) : null
            }
          </LayoutContext.Consumer>
        </>
      )}
      {errorMessages && (
        <Result
          status="404"
          subTitle={
            errorMessages['error']
          }
          extra={
            <Link to={staticPaths.home}>
              <Button type="primary">
                {t('Back Home')}
              </Button>
            </Link>
          }
        />
      )}
    </ContentLayout>
  )
})
