import { notification } from 'antd'
import _ from 'lodash'
import { renderSelf } from 'views/Shared'
import {
  MaterialTypes,
  WishareEntities,
  WishareMessageTypes,
} from './enums'

// Todo: implement translation

export const activityMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'activity not found',
}

export const wishMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'beneficiary not found',
}

export const donationMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'donation not found',
}

export const givingMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'giving not found',
}

export const recruitmentMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'recruitment not found',
}

export const organizationMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'organization not found',
}

export const userMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'user not found',
}

export const donorMessages = {
  [WishareMessageTypes.ERROR]: 'error',
  [WishareMessageTypes.EMPTY]:
    'donor not found',
}

export const getMessage = (
  entity,
  type = WishareMessageTypes.ERROR,
  transform = renderSelf
) => {
  const defaultMessage = 'error'

  let message = defaultMessage

  const getMessage = (from) =>
    _.get(from, type, defaultMessage)

  switch (entity) {
    case WishareEntities.GIVING:
      message = getMessage(
        givingMessages
      )
      break
    case WishareEntities.DONOR:
      message = getMessage(
        donorMessages
      )
      break
    case WishareEntities.BENEFICIARY:
      message = getMessage(wishMessages)
      break
    case WishareEntities.ACTIVITY:
      message = getMessage(
        activityMessages
      )
      break
    case WishareEntities.DONATION:
      message = getMessage(
        donationMessages
      )
      break
    case WishareEntities.RECRUITMENT:
      message = getMessage(
        recruitmentMessages
      )
      break
    case WishareEntities.ORGANIZATION:
      message = getMessage(
        organizationMessages
      )
      break
    case WishareEntities.USER:
      message = getMessage(userMessages)
      break
    default:
      break
  }
  return transform(message)
}

export const showNotification = ({
  message,
  description,
  type = MaterialTypes.INFO,
}) =>
  notification[type]({
    message,
    description,
  })

export const getValidationMessage = ({
  type,
  notValid = true,
  translate = renderSelf,
}) => {
  let message = 'required field'
  switch (type) {
    case 'email':
      message = notValid
        ? 'email is not valid'
        : 'email is required'
      break
    default:
      break
  }
  return translate(message)
}
