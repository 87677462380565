import _ from 'lodash'

export const WishareEntities =
  Object.freeze({
    MEDAL: 'medal',
    HOST: 'host',
    USER: 'user',
    STAFF: 'staff',
    STAFF_ROSTER: 'staff_roster',
    DONOR: 'donor',
    ARTICLE: 'article',
    CHANNEL: 'channel',
    BENEFICIARY: 'beneficiary',
    ACTIVITY: 'activity',
    GIVING: 'giving_event',
    DONATION: 'donation_event',
    RECRUITMENT: 'recruitment_event',
    EVENT: 'event',
    TOPIC: 'topic',
    CATEGORY: 'category',
    ORGANIZATION: 'organization',
    SUB_CATEGORIES: 'sub_categories',
    PARTNER: 'parner',
    UNDEFINED: 'undefined',
  })

export const fromWishareEntity = (
  type,
  which
) => {
  let value
  switch (type) {
    case WishareEntities.BENEFICIARY:
      value = {
        pathname: 'beneficiary',
        contextName: 'beneficiary',
        displayName: 'beneficiary',
      }
      break
    case WishareEntities.GIVING:
      value = {
        pathname: 'giving',
        contextName: 'giving',
        displayName: 'giving',
      }
      break
    case WishareEntities.DONATION:
      value = {
        pathname: 'donation',
        contextName: 'donation',
        displayName: 'donation',
      }
      break
    case WishareEntities.RECRUITMENT:
      value = {
        pathname: 'jobs',
        contextName: 'recruitment',
        displayName: 'recruitment',
      }
      break
    default:
      value = {
        pathname: type,
        contextName: type,
        displayName: type,
      }
      break
  }

  return which
    ? _.get(value, which)
    : value
}

export const MaterialTypes =
  Object.freeze({
    INFO: 'info',
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success',
  })

export const WishareMessageTypes =
  Object.freeze({
    EMPTY: 'empty',
    ERROR: 'error',
  })
