import {
  Avatar,
  DatePicker,
  Input,
  Result,
} from 'antd'
import { getId } from 'apis/model/base'
import {
  givingEventSchema,
  givingSchema,
} from 'apis/schema'
import classNames from 'classnames'
import { renderDecoratedLabel } from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import { useField } from 'formik'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react'
import {IoCalendarOutline, IoPerson} from 'react-icons/io5'
import { Redirect } from 'react-router-dom'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import { DelayRender } from 'views/Discovery/DelayRender'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItem,
  Null,
  renderElse,
} from 'views/Shared'
import { givingApi } from 'views/Wishare/apis'
import {
  wishareConfigs,
  withModalLayout,
} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { TagCreator } from 'views/Wishare/custom/TagsPicker'
import { TargetDisplayField } from 'views/Wishare/custom/TargetsDisplayFields'
import { GivingAdminDynamicFields } from 'views/Wishare/factory/createDynamicFields'
import createEditableForm, {
  ErrorSummaries,
} from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import BeneficiaryInformationFields from '../components/BeneficiaryInformationFields'
import BeneficiaryRequiredFields from '../components/BeneficiaryRequiredFields'
import { GivingItemContextActions } from '../components/GivingRecordItem'
import ReferralInformationField from '../components/ReferralInformationFields'
import GivingContext from '../GivingContext'
import BeneficiaryFormField from './BeneficiaryFormField'
import GivingAnswerView from './GivingAnswerView'
import ReferralFormField from './ReferralFormField'

const formFields = [
  { name: 'tags', defaultValue: [] },
  {
    name: 'id',
  },
  {
    name: 'referral',
    defaultValue: {},
  },
  {
    name: 'beneficiary',
    defaultValue: {},
  },
  {
    name: 'beneficiary_name',
  },
  {
    name: 'beneficiary_address',
  },
  {
    name: 'referral_name',
  },
  {
    name: 'referral_name',
  },
  {
    name: 'referral_email',
  },
  {
    name: 'referral_phone_number',
  },
  {
    name: 'email',
  },
  {
    name: 'gender',
  },
  {
    name: 'phone_number',
  },
  {
    name: 'date_of_birth',
  },
  {
    name: 'transfer_dt',
    defaultValue: moment(),
  },
  {
    name: 'defaultValues',
    defaultValue: {},
  },
  { name: 'register_note' },
  { name: 'giving_note' },
  { name: 'giving_items' },
  { name: 'target_unit_name' },
  { name: 'giving_type' },
  {
    name: 'targets',
    defaultValue: [],
  },
  {
    name: 'information_fields',
    defaultValue: {},
  },
  {
    name: 'fields_extra',
    defaultValue: [],
  },
  {
    name: 'required_information_fields',
    defaultValue: [],
  },
]

function autoFill(
  obj,
  default_values,
  field_names = []
) {
  let output = Object.assign({}, obj)
  Array.from(field_names || []).forEach(
    (name) => {
      if (!_.get(output, name)) {
        output[name] = _.get(
          default_values,
          name
        )
      }
    }
  )
  return output
}

const onBeneficiarySelected =
  (values) =>
  ({ location, ...rest }) => {
    const {
      registered_information,
      beneficiary_country,
      beneficiary_province,
      beneficiary_district,
      beneficiary_ward,
      required_information_fields,
    } = values || {}

    const required_fields = Array.from(
      required_information_fields
    )

    const information_params = _.pick(
      rest,
      required_fields
    )
    const {
      address,
      country,
      province,
      district,
      ward,
      ...params
    } = autoFill(
      {
        ...location,
        ...information_params,
      },
      {
        address: _.get(
          registered_information,
          'beneficiary_address'
        ),
        country: beneficiary_country,
        province: beneficiary_province,
        district: beneficiary_district,
        ward: beneficiary_ward,
        ..._.pick(
          values,
          required_fields
        ),
      },
      [
        'address',
        'country',
        'province',
        'district',
        'ward',
        ...required_fields,
      ]
    )

    return {
      ...params,
      location: [
        province,
        district,
        ward,
      ].map(getId),
      beneficiary_address: address,
      beneficiary_country_id:
        getId(country),
      beneficiary_province_id:
        getId(province),
      beneficiary_district_id:
        getId(district),
      beneficiary_ward_id: getId(ward),
    }
  }

const DummyField = React.memo(
  ({ name, value }) => {
    const [__, ___, helpers] =
      useField(name) || {}
    const { setValue = Null } = helpers
    useEffect(() => {
      setValue(value)
    }, [])
    return null
  }
)

const formSchema = [
  {
    ItemWrapper: ({ children }) => (
      <div className="p-3 border border-color-50 background rounded-lg">
        {children}
      </div>
    ),
    children: [
      {
        name: 'referral',
        label: 'referral information',
        hideError: true,
        // isRequired: true,
        renderLable:
          renderDecoratedLabel,
        component: withProps(
          ({ form }) => ({
            creatable: false,
            ..._.pick(
              _.get(form, 'values'),
              [
                'root_owner',
                'referral_name',
              ]
            ),
            withReferral: (
              referral
            ) => ({
              referral_email: _.get(
                referral,
                'email'
              ),
              referral_phone_number:
                _.get(
                  referral,
                  'phone_number'
                ),
            }),
          })
        )(ReferralFormField),
      },
      {
        name: 'referral_information_fields',
        hideError: true,
        component:
          createControlledFormField({
            Component:
              ReferralInformationField,
            withValues: ({
              referral,
              referral_name,
            }) => ({
              referral,
              referral_name,
            }),
          }),
      },
    ],
  },
  {
    ItemWrapper: ({ children }) => (
      <div className="p-3 border border-color-50 background rounded-lg">
        {children}
      </div>
    ),
    children: [
      {
        name: 'beneficiary',
        label: 'receiver information',
        hideError: true,
        isRequired: true,
        renderLable:
          renderDecoratedLabel,
        component: withProps(
          ({ form }) => ({
            ..._.pick(
              _.get(form, 'values'),
              [
                'root_owner',
                'alias_name',
              ]
            ),
            withBeneficiary:
              onBeneficiarySelected(
                _.get(form, 'values')
              ),
          })
        )(BeneficiaryFormField),
      },
      {
        name: 'location_information_fields',
        hideError: true,
        component:
          createControlledFormField({
            isRequired: false,
            Component:
              BeneficiaryRequiredFields,
            withValues: ({
              beneficiary,
              beneficiary_name,
            }) => ({
              Wrapper: ({ children }) =>
                renderElse(
                  _.isEmpty(
                    beneficiary
                  ) &&
                    _.isEmpty(
                      beneficiary_name
                    ),
                  <div className="mt-2 flex flex-col space-y-2">
                    {children}
                  </div>
                ),
            }),
          }),
      },
      {
        name: 'information_fields',
        hideError: true,
        component:
          createControlledFormField({
            isRequired: false,
            Component:
              BeneficiaryInformationFields,
            withValues: ({
              beneficiary,
              beneficiary_name,
            }) => ({
              Wrapper: ({ children }) =>
                renderElse(
                  _.isEmpty(
                    beneficiary
                  ) &&
                    _.isEmpty(
                      beneficiary_name
                    ),
                  <div className="mt-2 flex flex-col space-y-2">
                    {children}
                  </div>
                ),
            }),
          }),
      },
    ],
  },
  {
    ItemWrapper: ({ children }) => (
      <div className="p-3 border border-color-50 background rounded-lg">
        {children}
      </div>
    ),
    children: [
      {
        name: 'targets',
        label: 'targets',
        hideError: true,
        component: TargetDisplayField,
      },
    ],
  },
  {
    title: 'giving type',
    children: [
      {
        hideError: true,
        name: 'giving_items',
        component:
          GivingAdminDynamicFields,
      },
    ],
  },
  {
    // title: 'transfer date',
    children: [
      {
        name: 'transfer_dt',
        label: 'transfer date',
        hideError: true,
        isRequired: true,
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              onChange: (
                date,
                dateString
              ) =>
                onChange(
                  createValue(
                    name,
                    date
                      ? moment(
                          new Date(date)
                        )
                      : undefined
                  )
                ),
              defaultValue: value
                ? moment(
                    new Date(value)
                  )
                : undefined,
              className: 'w-full',
              placeholder: translate(
                'transfer date'
              ),
              format:
                wishareConfigs.dateFormat,
              suffixIcon: (
                <IoCalendarOutline />
              ),
            })
          )
        )(DatePicker),
      },
    ],
  },
  {
    title: 'tags',
    children: [
      {
        name: 'tags',
        component: ({
          name,
          value,
          onChange = Null,
        }) => (
          <TagCreator
            defaultValue={value}
            onChange={(value) => {
              const tags = _.isArray(
                value
              )
                ? Array.from(
                    value || []
                  )
                    .map(
                      ({ value }) =>
                        value
                    )
                    .join(',')
                : value
              onChange(
                createValue(name, tags)
              )
            }}
          />
        ),
      },
    ],
  },
  {
    title: 'user note',
    children: [
      {
        name: 'register_note',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 3,
            disabled: true,
            placeholder: 'note',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    title: 'admin note',
    children: [
      {
        name: 'giving_note',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'note',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'fields_extra',
        component:
          createControlledFormField({
            Component: GivingAnswerView,
            targetName:
              'collection_answers',
            withValues: (values) => ({
              readOnly: !_.get(
                values,
                'isEditing'
              ),
            }),
          }),
      },
    ],
  },
  {
    children: [
      {
        name: 'defaultValues',
        component: mapProps(
          ({ value }) => ({
            name: 'status',
            value: _.get(
              value,
              'status'
            ),
          })
        )(DummyField),
      },
    ],
  },
]

const ManageFormContent = ({
  receiver,
  actionType,
  giving_event,
  giving_items,
  Wrapper = 'div',
  onCancel = Null,
  onUpdated = Null,
  isEditing = false,
  ...props
}) => {
  const t = useTranslate()

  const isSm = useContext(LayoutContext)

  const giving_id = getId(receiver)

  const giving_item_entity =
    useSelectEntities(
      giving_id,
      givingSchema,
      receiver
    )

  const initialized = useCallback(
    (values) => {
      const {
        tags,
        transfer_dt,
        locations,
        beneficiary,
        ...params
      } = _.pick(giving_item_entity, [
        'tags',
        'alias',
        'alias_name',
        'email',
        'gender',
        'beneficiary',
        'locations',
        'referral',
        'referral_name',
        'referral_email',
        'referral_phone_number',
        'beneficiary_name',
        'register_note',
        'giving_note',
        'transfer_dt',
        'phone_number',
        'date_of_birth',
        'fields_extra',
        'beneficiary_address',
        'beneficiary_country',
        'beneficiary_province',
        'beneficiary_district',
        'beneficiary_ward',
      ])

      const location_data = _.get(
        beneficiary,
        'location'
      ) || {
        address: undefined,
        country: {},
        ward: {},
        district: {},
        province: {},
      }

      const location_params =
        beneficiary
          ? {
              beneficiary_address:
                location_data.address,
              beneficiary_country_id:
                getId(
                  location_data.country
                ),
              beneficiary_province_id:
                getId(
                  location_data.province
                ),
              beneficiary_district_id:
                getId(
                  location_data.district
                ),
              beneficiary_ward_id:
                getId(
                  location_data.ward
                ),
            }
          : {
              beneficiary_address:
                _.get(
                  params,
                  'beneficiary_address'
                ),
              beneficiary_country_id:
                getId(
                  _.get(
                    params,
                    'beneficiary_country'
                  )
                ),
              beneficiary_ward_id:
                getId(
                  _.get(
                    params,
                    'beneficiary_ward'
                  )
                ),
              beneficiary_district_id:
                getId(
                  _.get(
                    params,
                    'beneficiary_district'
                  )
                ),
              beneficiary_province_id:
                getId(
                  _.get(
                    params,
                    'beneficiary_province'
                  )
                ),
            }

      return {
        ...values,
        ...params,
        isEditing,
        tags: Array.from(
          tags || []
        ).map(
          ({
            idname: label,
            tags: value,
          }) => ({ label, value })
        ),
        locations,
        location: [
          location_data.province,
          location_data.district,
          location_data.ward,
        ].map(getId),
        registered_information: {
          beneficiary_address:
            location_params.beneficiary_address,
        },
        beneficiary,
        ...location_params,
        transfer_dt:
          transfer_dt || moment(),
        question_cleared: true,
        giving_items: isEditing
          ? giving_items
          : Array.from(
              giving_items || []
            ).map(
              ({
                reg_giving_qtty,
                reg_giving_value,
                ...rest
              }) => ({
                ...rest,
                reg_giving_qtty,
                reg_giving_value,
                giving_qtty:
                  reg_giving_qtty,
                giving_value:
                  reg_giving_value,
              })
            ),
        defaultValues: isEditing
          ? {}
          : { status: 1 },
      }
    },
    [
      isEditing,
      giving_items,
      giving_item_entity,
    ]
  )

  const validate = ({
    beneficiary,
    beneficiary_name,
    transfer_dt,
    question_cleared,
    target_unit_name,
    giving_items: items = [],
  }) => {
    let errors = {}

    const { id, owner_id } =
      beneficiary || {}

    let invalidBeneficiary = Boolean(
      _.isEmpty(id) &&
        _.isEmpty(owner_id) &&
        _.isEmpty(beneficiary_name)
    )

    if (invalidBeneficiary) {
      errors.beneficiary = {
        title: t('receiver'),
        description: t(
          'required field'
        ),
      }
    }

    if (!transfer_dt) {
      errors.transfer_dt = {
        title: t('transfer date'),
        description: t(
          'required field'
        ),
      }
    }

    if (!question_cleared) {
      errors.collection_answers = {
        title: t('answers'),
        description: t(
          'required field'
        ),
      }
    }

    const giving_items = Array.from(
      items
    ).filter((e) => !_.isUndefined(e))

    if (
      _.isEmpty(giving_items) ||
      _.some(
        giving_items || [],
        ({ type_cd, giving_qtty }) =>
          !giving_qtty &&
          [
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      ) ||
      _.some(
        giving_items,
        ({
          type_cd,
          giving_qtty,
          giving_value,
        }) =>
          Boolean(
            !giving_qtty ||
              !giving_value
          ) &&
          ![
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      ) ||
      _.some(
        giving_items,
        ({
          type_cd,
          giving_qtty,
          giving_value,
          unit_name,
        }) =>
          !_.isEqual(
            unit_name,
            target_unit_name
          ) &&
          Boolean(
            !giving_qtty ||
              !giving_value ||
              !unit_name
          ) &&
          [
            wishareFieldTypes.type_cds
              .money.value,
            wishareFieldTypes.type_cds
              .blood.value,
          ].includes(type_cd)
      )
    ) {
      errors.giving_items = {
        title: t('giving value'),
        description: t(
          'required field'
        ),
      }
    }

    return errors
  }

  const validationSchema = undefined

  if (
    _.isEmpty(giving_event) ||
    _.isEmpty(giving_id)
  ) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo =
    givingApi.giving_events_givingManageEdit_api

  const onSuccess = ([
    result,
    { response },
  ]) => {
    const newItem =
      getResponseItem(response)
    onUpdated(result, newItem)
    onCancel()
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    id,
    targets,
    location,
    referral,
    isEditing,
    beneficiary,
    fields_extra,
    defaultValues,
    giving_type,
    giving_items,
    question_cleared,
    information_fields,
    collection_answers,
    required_information_fields,
    ...values
  }) => {
    const {
      owner_id: referral_owner_id,
      owner_type: referral_type,
      id: referral_id,
    } = referral || {}

    const {
      owner_id,
      owner_type,
      id: beneficiary_id,
    } = beneficiary || {}

    return _.omitBy(
      {
        ...values,
        beneficiary_id,
        ...(giving_items
          ? {
              giving_items:
                JSON.stringify(
                  Array.from(
                    giving_items || []
                  ).map(
                    ({
                      unit_name,
                      ...rest
                    }) => ({
                      unit_name,
                      item_name:
                        unit_name,
                      ...rest,
                    })
                  )
                ),
            }
          : {}),
        ...(collection_answers
          ? {
              collection_answers:
                JSON.stringify(
                  collection_answers
                ),
            }
          : {}),
        owner_id: owner_id || undefined,
        owner_type:
          owner_type || undefined,
        referral_id:
          (referral_id
            ? referral_id
            : referral_owner_id) ||
          undefined,
        referral_type,
      },
      _.isUndefined
    )
  }

  const isApproving =
    actionType ===
    GivingItemContextActions.APPROVE

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    apiInfo,
    onError,
    validate,
    onSuccess,
    initialized,
    onPreSubmit,
    item: giving_event,
    validationSchema,
    disableNotify: true,
    validateOnMount: true,
    Wrapper: ({
      children,
      className,
    }) => (
      <div
        className={classNames(
          className,
          'AdminForm'
        )}>
        <ErrorSummaries />
        {children}
      </div>
    ),
    query: bindQueryParams([
      {
        id: getId(giving_event),
      },
      { giving_id },
    ]),
    ActionBar: () => (
      <FormActionBar
        submitText={
          isApproving
            ? 'approve'
            : 'save'
        }
        hideResetButton={true}
      />
    ),
  })

  const title = getTitle(giving_event)

  const avatar = _.get(
    giving_event,
    'owner.avatar'
  )
  const owner = _.get(
    giving_event,
    'owner.title'
  )

  return (
    <Wrapper className="space-y-3 py-3 max-w-full mx-auto">
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
        <center>
          {t('edit giving')}
        </center>
      </div>
      <div className="flex flex-col mb-6 p-3 border border-secondary background rounded-lg">
        <span className=" text-sm font-semibold text-color-300 tracking-wide mb-1">
          {t(
            'given by the organization'
          )}
        </span>
        <div className="flex items-center font-bold text-secondary">
          <Avatar
            src={avatar}
            size={isSm ? 30 : 50}
            className="flex flex-center background-100"
            icon={<IoPerson className="text-color-300"/>}
          />
          <div className="flex flex-col flex-1 mx-3">
            <span className="text-base md:text-lg max-lines-1">
              {title}
            </span>
            <span className="text-xs font-light text-secondary italic">
              {owner}
            </span>
          </div>
        </div>
      </div>
      <DelayRender lazy={true}>
        {content}
      </DelayRender>
    </Wrapper>
  )
}

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const GivingManageEditForm = ({
  location,
  ...props
}) => {
  const { giving: item } = useContext(
    GivingContext
  )

  const { id } =
    _.get(location, 'state') || {}

  const giving_event =
    useSelectEntities(
      id,
      givingEventSchema,
      item
    )

  const ModalForm = _.get(
    giving_event,
    'edit'
  )
    ? ManageFormContent
    : AccessDenied

  if (_.isEmpty(giving_event)) {
    return <WishareNotFound />
  }

  const Component = withModalLayout(
    ModalForm,
    {
      giving_event,
      ...props,
    }
  )

  return (
    <AntdConfigWrapper>
      <Pure>
        <Component />
      </Pure>
    </AntdConfigWrapper>
  )
}

export const editGivingRegisterParams =
  {
    action: {
      name: 'action',
      value: {
        edit: 'edit',
        remove: 'remove',
      },
    },
    item: {
      name: 'register-id',
      getItem: (value) => value,
    },
  }

export default createRequiredLogin()(
  GivingManageEditForm
)
