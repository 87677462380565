import {Badge, Input, Tag} from 'antd'
import {referral_getReferralCode_Api} from 'apis'
import {organizationModel} from 'apis/model'
import ImageLoader from 'components/ImageLoader'
import {UpdateReferalCode} from 'components/UpdateReferalCode'
import copyToClipboard from 'copy-to-clipboard'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import LoadingPage from 'views/LoadingPage'
import {IoCopy, IoCopyOutline} from "react-icons/io5";

const Invite = ({item}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle(
    false
  )
  const [
    isToggleCopy2,
    toggleCopy2
  ] = useToggle(false)
  const {response} = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: item.id,
      target_type: item._type
    }
  })
  useLayoutEffect(() => {
    let timeout
    if (isToggle) {
      timeout = setTimeout(
        () => toggle(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggle, toggle])

  useLayoutEffect(() => {
    let timeout
    if (isToggleCopy2) {
      timeout = setTimeout(
        () => toggleCopy2(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggleCopy2, toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage/>
  const inviteLink =
    ROOT_URL +
    '/invite/organization/' +
    _.get(data, 'referral_code')
  const timeout = moment(
    _.get(data, 'created')
  )
    .add(
      _.get(data, 'timeout'),
      'second'
    )
    .fromNow()
  return (
    <div className="flex flex-col py-3 px-5 pb-5 verticalList__large">
      <h2 className="text-xl uppercase pr-5 font-bold pt-3">
        {t(
          'Invite your friends to this page'
        )}
      </h2>
      <UpdateReferalCode referral={data}/>
      <div className="p-4 w-full flex rounded-lg border">
        <ImageLoader
          className="rounded-full"
          style={{width: '4rem', height: '4rem'}} src={organizationModel.getFullAvatarUrl(item)}/>
        <div className="ml-3 mr-1">
          <Link
            to={organizationModel.getLinkToDetail(
              item
            )}
            className="font-bold text-primary text-lg max-lines-2 leading-tight">
            {item.title}
          </Link>
          {item.premium && <div className="mt-1"><Tag color="gold">PREMIUM</Tag></div>}
          <div
            style={{
              justifySelf: 'flex-end',
              display: 'flex'
            }}>
            <div className="flex items-center mt-3 mr-3 rounded-full px-3 py-1 background-200">
              <Badge
                color="blue"
                text={
                  <span
                    style={{
                      fontWeight: '400'
                    }}>
                    {item.total_members}{' '}
                    {t('members')}
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs">
        {item.description}
      </div>
      <div className="pb-3">
        <div className="mb-1 text-sm text-color-000">
          {t(
            'Send invitation to your friends, by sending a copy of the page link below'
          )}
        </div>
        <div
          onClick={() => {
            copyToClipboard(inviteLink)
            toggle(true)
          }}>
          <Input
            size="large"
            value={inviteLink}
            suffix={
              isToggle ? <IoCopy className="ml-2 text-primary"/>
                : <IoCopyOutline className="ml-2 text-color-400 cursor-pointer"/>
            }
          />
        </div>
        <div className="mb-2 text-xs italic text-color-400">
          {_.get(data, 'timeout', 0) ===
          0 ||
          _.get(
            data,
            'no_expired',
            0
          ) === 1
            ? t('Permanent link')
            : `${t(
              'your invite link expires'
            )} ${timeout}`}
        </div>
        <div className="mt-3">
          <span className="mb-1 text-sm text-color-000">
              {t('Copy and share the page link below to your friends.')}
          </span>
          <div
            onClick={() => {
              copyToClipboard(
                ROOT_URL +
                organizationModel.getLinkToDetail(
                  item
                )
              )
              toggleCopy2(true)
            }}>
            <Input
              value={
                ROOT_URL +
                organizationModel.getLinkToDetail(
                  item
                )
              }
              suffix={
                isToggleCopy2 ? <IoCopy className="ml-2 text-primary"/>
                  : <IoCopyOutline className="ml-2 text-color-400 cursor-pointer"/>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Invite
