import classnames from 'classnames'
import { RegAsModal } from 'components/Modal/Modal'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import useClickAway from 'react-use/lib/useClickAway'
import './SideModal.css'

export default function SideModal({
  onCancel,
  children,
  right,
  left,
  large,
  full,
  center,
  className,
  bottom,
  top,
  style,
  maskClosable,
  contentStyle,
  ...props
}) {
  const ref = useRef(null)
  useClickAway(ref, () => {
    maskClosable && handleCancel()
  })
  const [close, setClose] =
    useState(false)
  const handleCancel =
    useCallback(() => {
      setClose(true)
    }, [])
  useLayoutEffect(() => {
    if (close) {
      setTimeout(onCancel, 0)
    }
  }, [close, onCancel])

  const finalClassName = classnames(
    'SideModal ScrollbarTrack',
    className,
    {
      right,
      left,
      bottom,
      large,
      center,
      full,
      top,
    }
    // !close
    //   ? 'animated fadeIn faster'
    //   : 'animated fadeOut faster'
  )
  const result = useMemo(
    () => (
      <RegAsModal>
        <div
          // onClick={preventParentEvent}
          className={finalClassName}
          style={style}>
          <div className="SideModalMark" />
          <div
            ref={ref}
            style={contentStyle}
            className="SideModalContent background  min-h-screen flex flex-col">
            {children({
              close,
              handleCancel,
            })}
          </div>
        </div>
      </RegAsModal>
    ),
    [
      children,
      close,
      finalClassName,
      handleCancel,
      style,
    ]
  )
  return ReactDOM.createPortal(
    result,
    window.document.body
  )
}
