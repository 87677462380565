import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {getId, getType,} from '../../apis/model/base'
import {FingerLoveFillSvg} from "../Svgs/FingerLoveFillSvg";
import {FingerLoveOutlineSvg} from "../Svgs/FingerLoveOutlineSvg";
import {UseVoteHandler} from "../../views/Wishare/custom/ToggleActionButton";
import {Null} from 'views/Shared'

export const VoteButton = ({
                             item,
                             children,
                           }) => {
  const t = useTranslate();
  const owner_id = getId(item);
  const owner_type = getType(item);
  const vote_status = !!_.get(item, 'vote_status');
  if (_.get(item, 'status', 1) !== 1)
    return null;

  return (
    <UseVoteHandler
      {...{
        owner_id,
        owner_type,
        vote_status,
      }}>
      {
        (handleVote = Null) => !vote_status ? (
          <span onClick={handleVote} className="my-auto cursor-pointer">
                      <FingerLoveOutlineSvg/>
                  </span>
        ) : (
          <span onClick={handleVote} className="my-auto cursor-pointer">
                        <FingerLoveFillSvg/>
                    </span>
        )
      }
    </UseVoteHandler>
  )
}
