import { schema } from 'normalizr'

export const timeSheetSchema =
  new schema.Entity(
    'timesheets',
    {},
    {
      idAttribute: 'id',
    }
  )
