import { post_dashboard_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { renderEmptyHolder } from 'views/MainPage/functions/renderEmptyHolder'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import CategoryContext from '../CategoryContext'

const CategoryNews = ({
  Wrapper = 'div',
}) => {
  const { category: item } = useContext(
    CategoryContext
  )

  const [id, prop] = [
    getId(item),
    getType(item),
  ]

  const apiInfo = post_dashboard_Api

  const lookupKey = useMemo(
    () =>
      [apiInfo.path, id, prop].join(
        '/'
      ),
    [id, prop, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { prop },
        ])}
        values={{ cate_id: id }}
        renderItem={renderFeedItem}
        RenderEntity={React.Fragment}
        renderEmpty={renderEmptyHolder}
      />
    </Wrapper>
  )
}

export default CategoryNews
