import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  Null,
  renderOwnChild,
} from 'views/Shared'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import AdminPanel from 'views/Wishare/AdminPanel'
import { wisharePaths } from 'views/Wishare/configs'
import OrganizationContainerContext from '../OrganizationContainerContext'
import OrganizationNav from './OrganizationNav'

const OrganizationContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { organization } = useContext(
    OrganizationContainerContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!organization}>
        <SubDomainContext.Consumer>
          {({
            diff_root,
            isSubDomain,
            url_domain,
          }) => (
            <AdminPanel
              goToSetting={({
                path,
                push = Null,
              }) => {
                if (!!!isSubDomain) {
                  push(path)
                }
                if (!!diff_root) {
                  window.location.replace(
                    [
                      url_domain,
                      wisharePaths.organizationSettings_domain,
                    ].join('')
                  )
                } else {
                  push(path)
                }
              }}
            />
          )}
        </SubDomainContext.Consumer>
      </LoadingWrapper>
      <div className="space-y-3">
        <OrganizationNav />
      </div>
    </Wrapper>
  )
}

export default OrganizationContent
