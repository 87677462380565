import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback,} from 'react'
import SubCategoriesSelect, {searchSubCategories,} from 'views/MainPage/WorkSpace/SubCategories/SubCategoriesSelect'
import {Null} from 'views/Shared'

const PartnerFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    subcategories: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  const value = filter.subcategories
    ? _.split(filter.subcategories, ',')
    : undefined

  return (
    <div className="flex flex-col md:flex-row justify-center gap-2">
      <div className="flex text-color-100 font-medium text-sm italic pl-2 mt-1">
        {t('filter conditions by subcategory')}
      </div>
      <SubCategoriesSelect
        name="subcategories"
        className="flex-1"
        value={value}
        placeholder={t('subcategories')}
        onChange={(event) => {
          const { name, value } =
            event.target || {}
          handleChange(name, value)
        }}
        fetchOptions={searchSubCategories(
          {
            owner_id,
            owner_type,
          }
        )}
      />
    </div>
  )
}

export default PartnerFilter
