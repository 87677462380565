import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import React, {
  useContext,
} from 'react'
import FlyWrapper from 'views/Wishare/Templates/FlyWrapper'
import TopicCategories from './TopicCategories'
import TopicImpacts from './TopicImpacts'
import TopicTags from './TopicTags'

const TopicMenu = ({
  Wrapper = 'div',
}) => {
  const { isLg } = useContext(
    LayoutContext
  )
  return (
    <FlyWrapper disabled={!!!isLg}>
      <Wrapper className="space-y-6 p-3">
        <TopicCategories />
        <TopicImpacts />
        <TopicTags />
      </Wrapper>
    </FlyWrapper>
  )
}

export default TopicMenu
