import React, {
  useContext,
} from 'react'
import {
  BsBookmark,
  BsBookmarkCheckFill,
} from 'react-icons/bs'
import { Null } from 'views/Shared'
import Count from './Count'
import { FeedContext } from './FeedCard'

export const BookmarkAction = ({
  context = FeedContext,
}) => {
  const {
    handleClick = Null,
    bookmark_status = false,
  } = useContext(context) || {}

  const isBookmarked = Boolean(
    bookmark_status
  )
  return (
    <Count
      active={isBookmarked}
      onClick={() => {
        handleClick(
          isBookmarked
            ? 'unbookmark'
            : 'bookmark'
        )
      }}
      key="bookmark"
      color={
        isBookmarked
          ? 'text-orange-500'
          : 'text-color-300'
      }
      component={
        isBookmarked
          ? BsBookmarkCheckFill
          : BsBookmark
      }
    />
  )
}
