import {
  organization_fetchRecentlyActivities_Api,
  organization_fetchRecentlyEvents_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  branch,
  compose,
  fromRenderProps,
} from 'recompose'
import CustomCarousel from 'views/MainPage/CustomCarousel'
import {
  getResponseItems,
  renderOwnChild,
} from 'views/Shared'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import WorkspaceContext from '../WorkspaceContext'
import {
  renderRecentActivity,
  renderRecentEvent,
} from './renderRecentItem'

export const WorkspaceGalleryTypes =
  Object.freeze({
    DEFAULT: 'default',
    RECENT_EVENTS: 'recent-events',
    RECENT_ACTIVITIES:
      'recent-activities',
  })

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
]

const WorkspaceCarousel = ({
  query,
  values,
  apiInfo,
  renderItem,
  minSlides = 4,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const {
    response,
    isLoading = false,
  } = useAsync({
    query,
    values,
    apiInfo,
  })

  const items = useMemo(() => {
    const data =
      getResponseItems(response)

    if (!!isLoading) {
      return new Array(minSlides).fill({
        isLoading,
        isPlaceholder: true,
      })
    }

    if (_.isEmpty(data)) {
      return new Array(minSlides).fill({
        isLoading,
        isPlaceholder: true,
      })
    } else {
      const diff =
        Number(minSlides) - data.length
      return _.union(
        data,
        diff > 0
          ? new Array(diff)
              .fill({
                isLoading,
                hidden: true,
                isPlaceholder: true,
              })
              .map((item, index) => ({
                slide: index,
                ...item,
              }))
          : []
      )
    }
  }, [response, isLoading, minSlides])

  if (_.isEmpty(items)) {
    return <EmptyHolder />
  }

  return (
    <Wrapper>
      <CustomCarousel
        dots={false}
        items={items}
        centerMode={false}
        renderItem={renderItem}
        responsive={responsive}
        slidesToShow={isSm ? 1 : 3}
        slidesToScroll={isSm ? 1 : 3}
        {...props}
      />
    </Wrapper>
  )
}

const WorkspaceHomeGallery = ({
  item,
  type = WorkspaceGalleryTypes.DEFAULT,
}) => {
  const organization_id = getId(item)
  switch (type) {
    case WorkspaceGalleryTypes.RECENT_EVENTS:
      return (
        <WorkspaceCarousel
          renderItem={renderRecentEvent}
          query={bindQueryParam({
            id: organization_id,
          })}
          apiInfo={
            organization_fetchRecentlyEvents_Api
          }
        />
      )
    case WorkspaceGalleryTypes.RECENT_ACTIVITIES:
      return (
        <WorkspaceCarousel
          renderItem={
            renderRecentActivity
          }
          query={bindQueryParam({
            id: organization_id,
          })}
          apiInfo={
            organization_fetchRecentlyActivities_Api
          }
        />
      )
    case WorkspaceGalleryTypes.DEFAULT:
    default:
      return null
  }
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      item: organization,
      query: bindQueryParam({
        id: getId(organization),
      }),
    })
  ),
  branch(
    ({ item }) => _.isEmpty(item),
    () => () => <WishareNotFound />
  )
)(WorkspaceHomeGallery)
