import { CheckOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  List,
  Spin,
} from 'antd'
import { auth_getOwnedItems_Api } from 'apis'
import { getType } from 'apis/model/base'
import classnames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import { ObjectSelectField } from 'components/form/fields/ObjectSelectField'
import { createValue } from 'components/form/utils'
import { LoginContext } from 'components/LoginContext'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import {
  branch,
  compose,
  fromRenderProps,
  renderComponent,
} from 'recompose'
import {
  getFullAddress,
  getResponseItems,
  Null,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import { UnauthenticatedAlert } from 'views/Wishare/WishareAlert'
import AntdConfigWrapper from './AntdConfigWrapper'

export const renderOwnerOption = (
  option,
  actions,
  params = {}
) => {
  const title = getTitle(option)
  const verified = _.get(
    option,
    'certified'
  )

  const type =
    _.get(option, '_type') ===
    'organization'
  const typeDonor =
    _.get(option, '_type') === 'donor'
  const typeBeneficiary =
    _.get(option, '_type') ===
    'beneficiary'

  const phone = _.get(
    option,
    'phone_number'
  )
  const email = _.get(option, 'email')
  const address = getFullAddress(
    _.get(option, 'location')
  )

  const Contact = () => {
    if (
      _.isEmpty(phone) &&
      _.isEmpty(email) &&
      _.isEmpty(address)
    ) {
      return (
        <Translate>
          {(t) =>
            t(_.get(option, '_type'))
          }
        </Translate>
      )
    } else if (
      _.isEmpty(phone) &&
      _.isEmpty(email) &&
      address
    ) {
      return <span>{address}</span>
    } else if (
      _.isEmpty(phone) ||
      _.isEmpty(email)
    ) {
      return (
        <span>{phone || email}</span>
      )
    }
    return [phone, email].join(' - ')
  }

  return (
    <Translate>
      {(t) => (
        <List.Item
          className="p-0 h-full"
          actions={actions}>
          <List.Item.Meta
            title={
              <div className="flex items-center">
                <div className="flex flex-col truncate">
                  <span style={{color: type ? '#0f5371' : '#000'}} className="font-semibold truncate text-sm leading-tight">
                    {title}
                  </span>
                  <span className="text-2xs italic text-secondary font-light leading-tight truncate">
                    {typeDonor ||
                    typeBeneficiary ? (
                      <Contact />
                    ) : (
                      t(
                        _.get(
                          option,
                          '_type'
                        )
                      )
                    )}
                  </span>
                </div>
                {verified === 1 && (
                  <VerifiedSvg
                    width={'1rem'}
                    height={'1rem'}
                    className="ml-1 mb-1 lg:mb-2 zoom-animation"
                  />
                )}
              </div>
            }
            prefixCls="OptionMetaItem"
            className="flex items-center gap-2"
            avatar={
              <Avatar
                shape={
                  type
                    ? 'square'
                    : 'circle'
                }
                src={
                  _.get(
                    option,
                    'avatar'
                  ) ||
                  _.get(
                    option,
                    'owner.avatar'
                  )
                }
                className={classnames(
                  'flex flex-col background-200',
                  type
                    ? 'rounded-md'
                    : null
                )}>
                {_.first(
                  getTitle(option)
                )}
              </Avatar>
            }
            {...params}
          />
        </List.Item>
      )}
    </Translate>
  )
}

const ContainerPicker = ({
  name,
  label,
  options,
  optionParams,
  defaultValue,
  dependencies,
  customConfigs,
  onChange = Null,
  allowClear = false,
  optionIdProp = 'id',
  getOption = renderSelf,
  Wrapper = renderOwnChild,
  optionLabelProp = 'selectedOption',
  renderActions = (actions, option) =>
    actions,
  withResponses = (data, params) =>
    data,
  mapCustomProps = (
    props,
    optionIdProp
  ) => props,
  type = WishareEntities.ORGANIZATION,
}) => {
  const asyncConfigs = useMemo(() => {
    switch (type) {
      case WishareEntities.UNDEFINED:
        return customConfigs
      case WishareEntities.ACTIVITY:
      case WishareEntities.RECRUITMENT:
        return _.omitBy(
          {
            query: {
              ':types': [
                WishareEntities.ORGANIZATION,
              ].join(','),
            },
            apiInfo:
              auth_getOwnedItems_Api,
          },
          _.isUndefined
        )
      case WishareEntities.BENEFICIARY:
      case WishareEntities.DONATION:
      case WishareEntities.GIVING:
        return {
          query: {
            ':types': [
              WishareEntities.ACTIVITY,
            ].join(','),
          },
          apiInfo:
            auth_getOwnedItems_Api,
        }
      case WishareEntities.ORGANIZATION:
      default:
        return {
          query: {
            ':types': [
              WishareEntities.USER,
            ].join(','),
          },
          apiInfo:
            auth_getOwnedItems_Api,
        }
    }
  }, [type, customConfigs])

  const defaultOptions = Array.from(
    options || []
  )

  const t = useTranslate()

  const tranform = useCallback(
    (
      options = [],
      defaultValue = []
    ) => {
      if (_.isEmpty(options)) {
        return defaultValue
      }
      return options
        .filter(
          (item) =>
            !_.isEmpty(getOption(item))
        )
        .map((option) => {
          const _option =
            getOption(option)
          const value = _.get(
            _option,
            optionIdProp
          )
          const actions = renderActions(
            [
              <Button
                type="primary"
                size="small"
                className="rounded-md no-shadow no-text-shadow text-xs">
                {t('choose')}
              </Button>,
            ],
            _option,
            { optionIdProp }
          )
          return mapCustomProps(
            {
              value,
              id: value,
              [optionIdProp]: value,
              style: { height: 50 },
              className: 'items-center',
              type: getType(_option),
              [optionLabelProp]:
                renderOwnerOption(
                  _option,
                  undefined,
                  optionParams
                ),
              label: renderOwnerOption(
                _option,
                actions,
                optionParams
              ),
              title: getTitle(_option),
            },
            optionIdProp
          )
        })
    },
    [t, renderActions, optionIdProp]
  )

  const { apiInfo, query, values } =
    asyncConfigs || {}

  const [isLoaded, setLoaded] =
    useState(false)

  const {
    isLoading = false,
    response: searchResponse,
    handleAsyncAction: search,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onSuccess: () => {
      setLoaded(true)
    },
  })

  useEffect(() => {
    if (apiInfo && !isLoaded) {
      search()
    }
  }, [apiInfo, isLoaded])

  const handleSearch = _.debounce(
    (keyword) => {
      search({ keyword })
    },
    300
  )

  const RenderEmpty = () =>
    isLoading ? (
      <Spin
        spinning={true}
        className="flex items-center justify-center"
      />
    ) : (
      <EmptyHolder />
    )

  const render = useCallback(
    (dataSource = []) => {
      const _options = Array.from(
        tranform(
          withResponses(dataSource, {
            optionIdProp,
          }),
          defaultOptions
        )
      )
      const _defaultValue = _options
        .map(({ value }) => value)
        .includes(defaultValue)
        ? defaultValue
        : undefined

      return (
        <ObjectSelectField
          name={name}
          placeholder={
            isLoading
              ? '...'
              : t('click to select')
          }
          dependencies={dependencies}
          key={[isLoaded, defaultValue]}
          onChange={(event) => {
            const { name, value } =
              event.target
            onChange(
              createValue(
                name,
                value === undefined
                  ? null
                  : value
              )
            )
          }}
          label={
            label ? t(label) : undefined
          }
          bordered={false}
          suffixIcon={
            isLoading ? undefined : (
              <IoSearchOutline />
            )
          }
          allowClear={allowClear}
          onSearch={handleSearch}
          filterOption={false}
          showSearch={true}
          options={
            isLoading ? [] : _options
          }
          optionFilterProp="title"
          defaultValue={
            isLoading
              ? undefined
              : _defaultValue
          }
          loading={!!isLoading}
          optionLabelProp={
            optionLabelProp
          }
        />
      )
    },
    [
      t,
      name,
      onChange,
      tranform,
      isLoading,
      withResponses,
      optionIdProp,
      handleSearch,
      defaultValue,
      defaultOptions,
      searchResponse,
    ]
  )

  // const cacheId = [
  //   apiInfo.path,
  //   JSON.stringify(query),
  //   JSON.stringify(values),
  // ].join('/')

  return (
    <Wrapper>
      <AntdConfigWrapper
        renderEmpty={RenderEmpty}>
        {render(
          apiInfo
            ? getResponseItems(
                searchResponse
              )
            : defaultOptions
        )}
      </AntdConfigWrapper>
    </Wrapper>
  )
}

export const renderDefaultActions =
  (value, params) =>
  (actions, option, params) => {
    const { optionIdProp } =
      params || {}
    const {
      ActiveComponent = CheckOutlined,
    } = params || {}
    return _.get(
      option,
      optionIdProp
    ) ===
      _.get(value, optionIdProp, value)
      ? [
          <ActiveComponent
            style={{ color: '#87d068' }}
            className="p-2"
          />,
        ]
      : actions
  }

export const withOwnerResponses = (
  default_object
) =>
  Boolean(
    _.isEmpty(default_object) ||
      !_.isObject(default_object)
  )
    ? undefined
    : (
        items = [],
        { optionIdProp }
      ) => [
        {
          owner: default_object,
        },
        ...Array.from(
          items || []
        ).filter(
          ({ owner }) =>
            _.get(
              owner,
              optionIdProp
            ) !==
            _.get(
              default_object,
              optionIdProp
            )
        ),
      ]

export default compose(
  fromRenderProps(
    LoginContext.Consumer,
    (login) => ({ authorized: !!login })
  ),
  branch(
    ({ authorized }) => !!!authorized,
    renderComponent(
      UnauthenticatedAlert
    )
  )
)(ContainerPicker)
