import {
  search_fetchObjects_Api,
  search_getTags_Api,
} from 'apis'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import Pure from 'components/Pure'
import {
  convertFromRaw,
  DefaultDraftBlockRenderMap,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import 'draft-js/dist/Draft.css'
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import Immutable from 'immutable'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ScrollToViewByHash from '../ScrollToViewByHash'
import plugins, {
  alignmentPlugin,
  dividerPlugin,
  mentionPlugin,
  tagPlugin,
} from './plugins'
import { InlineToolBar } from './plugins/inlineToolbar'
import EmbedButton from './plugins/side-toolbar/EmbedButton'
import ImageButton from './plugins/side-toolbar/ImageButton'
import sideToolbarPlugin from './plugins/side-toolbar/sideToolbarPlugin'
import VideoButton from './plugins/side-toolbar/VideoButton'
import './RichEditor.scss'
import sampleContent from './sampleContent'

const { AlignmentTool } =
  alignmentPlugin

const { DividerButton } = dividerPlugin
const {
  MentionSuggestions:
    TagMentionSuggestions,
} = tagPlugin

const { MentionSuggestions } =
  mentionPlugin

const { SideToolbar } =
  sideToolbarPlugin

const Header = (props) => {
  return props.children.map(
    (e, index) => {
      const text = _.get(
        e,
        'props.children.props.block'
      )
        .getText()
        .trim()
      return cloneElement(e, {
        ...e.props,
        id: text,
        key: index,
      })
    }
  )
}

const blockRenderMap = Immutable.Map({
  'header-one': {
    element: 'h2',
    wrapper: <Header />,
  },
  'header-two': {
    element: 'h3',
    wrapper: <Header />,
  },
  'header-three': {
    element: 'h4',
    wrapper: <Header />,
  },
})

// keep support for other draft default block types and add our myCustomBlock type
const extendedBlockRenderMap =
  DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  )
const Mentions = () => {
  const {
    response,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: search_fetchObjects_Api(),
  })
  const onSearchChange = ({
    value,
  }) => {
    handleAsyncAction({
      keyword: value,
    })
  }
  const suggestions = _.take(
    _.get(response, 'data.data', []),
    7
  ).map((item) => ({
    name: getTitle(item),
    link: getLinkToDetail({
      idname: _.get(
        item,
        'owner.username',
        _.get(item, 'id')
      ),
      ...item,
      _type: _.get(
        item,
        'owner_type',
        getType(item)
      ),
    }),
    avatar: _.get(item, 'avatar'),
  }))
  const onAddMention = () => {
    // get the mention object selected
  }
  return (
    <MentionSuggestions
      onSearchChange={onSearchChange}
      suggestions={suggestions}
      onAddMention={onAddMention}
    />
  )
}

const TagMentions = () => {
  const [keyword, setkeyword] =
    useState()
  const {
    response,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: search_getTags_Api,
    query: { ':keyword': ' ' },
  })
  const onSearchChange = ({
    value,
  }) => {
    setkeyword(value)
  }
  useEffect(() => {
    if (
      keyword &&
      keyword.length &&
      !_.isEmpty(keyword)
    )
      handleAsyncAction(
        {},
        keyword && keyword.length
          ? {
              ':keyword': keyword,
            }
          : {}
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])
  const suggestions = !(
    keyword &&
    keyword.length &&
    !_.isEmpty(keyword)
  )
    ? []
    : [
        {
          name: '#' + keyword,
          link: `/t/${keyword}`,
        },
        ..._.get(
          response,
          'data.data',
          []
        )
          .map((item) => ({
            item,
            name:
              '#' + _.get(item, 'tags'),
            link: `/t/${_.get(
              item,
              'tags'
            )}`,
          }))
          .filter((item, i) => i < 7),
      ]
  const onAddMention = () => {
    // get the mention object selected
  }
  return (
    <TagMentionSuggestions
      onSearchChange={onSearchChange}
      suggestions={suggestions}
      onAddMention={onAddMention}
    />
  )
}
const handleReturn = (
  event,
  editorState,
  { setEditorState }
) => {
  if (isSoftNewlineEvent(event)) {
    const selection =
      editorState.getSelection()

    if (selection.isCollapsed()) {
      setEditorState(
        RichUtils.insertSoftNewline(
          editorState
        )
      )
    } else {
      const content =
        editorState.getCurrentContent()
      let newContent =
        Modifier.removeRange(
          content,
          selection,
          'forward'
        )
      const newSelection =
        newContent.getSelectionAfter()
      const block =
        newContent.getBlockForKey(
          newSelection.getStartKey()
        )

      newContent = Modifier.insertText(
        newContent,
        newSelection,
        '\n',
        block.getInlineStyleAt(
          newSelection.getStartOffset()
        ),
        null
      )

      setEditorState(
        EditorState.push(
          editorState,
          newContent,
          'insert-fragment'
        )
      )
    }

    return 'handled'
  }

  return 'not-handled'
}
const handleKey = (
  command,
  editorState,
  __,
  { setEditorState }
) => {
  const newState =
    RichUtils.handleKeyCommand(
      editorState,
      command
    )

  if (newState) {
    setEditorState(newState)
    return 'handled'
  }

  return 'not-handled'
}
const RichEditor = React.memo(
  ({
    readOnly,
    autoFocus = false,
    editorState,
    onChange,
    onBlur,
    onFocus,
    deps = [],
    canReversed = true,
  }) => {
    const t = useTranslate()
    const [e, setE] = useState(
      editorState ||
        EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(sampleContent)
          )
        )
    )
    window.editorState = e
    const editorRef = useRef()
    const onChangeRef = useRef(onChange)
    const onWrapClick = useCallback(
      () => editorRef.current.focus(),
      []
    )
    useEffect(() => {
      onChangeRef.current &&
        onChangeRef.current(e)
    }, [e])

    useEffect(() => {
      if (canReversed) {
        setE(
          editorState ||
            EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(
                  sampleContent
                )
              )
            )
        )
      }
    }, [canReversed, ...deps])

    function renderPlaceholder(
      placeholder,
      editorState
    ) {
      const contentState =
        editorState.getCurrentContent()
      const shouldHide =
        contentState.hasText() ||
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      return shouldHide
        ? ''
        : placeholder
    }

    return (
      <div
        style={{
          minHeight: readOnly
            ? 0
            : 'var(--header-height)',
        }}
        className={classNames(
          'prose RichEditor max-w-6xl dont-break-out mx-auto mb-4',
          {
            readOnly,
          }
        )}>
        {/*{!readOnly && (*/}
        {/*  <StatusBar editorState={e} />*/}
        {/*)}*/}
        <ScrollToViewByHash />
        <div onClick={onWrapClick}>
          {readOnly ? (
            <Editor
              blockRenderMap={
                extendedBlockRenderMap
              }
              ref={editorRef}
              editorState={e}
              onChange={setE}
              readOnly={readOnly}
            />
          ) : (
            <Editor
              placeholder={
                !readOnly &&
                renderPlaceholder(
                  t(
                    'click here to start writing'
                  ),
                  e
                )
              }
              blockRenderMap={
                extendedBlockRenderMap
              }
              autoFocus={autoFocus}
              readOnly={readOnly}
              ref={editorRef}
              editorState={e}
              onChange={setE}
              plugins={plugins}
              onFocus={onFocus}
              onBlur={onBlur}
              handleKeyCommand={
                handleKey
              }
              handleReturn={
                handleReturn
              }
            />
          )}
          {!readOnly && (
            <Pure>
              <AlignmentTool />
              <InlineToolBar />
              <Mentions />
              <TagMentions />
              <SideToolbar>
                {
                  // may be use React.Fragment instead of div to improve perfomance after React 16
                  (externalProps) => [
                    <ImageButton
                      {...externalProps}
                    />,
                    <VideoButton
                      {...externalProps}
                    />,
                    <EmbedButton
                      {...externalProps}
                    />,
                    <DividerButton
                      {...externalProps}
                    />,
                  ]
                }
              </SideToolbar>
            </Pure>
          )}
          <div className="clearfix" />
        </div>
      </div>
    )
  }
)
export const defaultRawContent = {
  blocks: [
    {
      key: '51v21',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
}

export default RichEditor
