import React from 'react'
import _ from "lodash"
import classNames from "classnames";
import useTranslate from "../../../../modules/local/useTranslate";
import {getPercent} from "../../../../helpers/getPercent";
import {Progress} from "antd";

const IOReport = ({item}) => {
  const t = useTranslate();

  const _moneyStatisticIO = _.sortBy(
    [
      ..._(_.get(item, 'input_statistics', []).filter(e => e.type_cd !== "blood"))
        .groupBy('target_unit_name')
        .map((el, idx) => {
          return {
            io: 'input',
            index: idx,
            unit_name: _.max(el).target_unit_name,
            className: '',
            label_value: t('input'),
            item_name: t(idx),
            total_value: _.sumBy(el, 'total_donation_value')
          }
        }).value(),
      ..._(_.get(item, 'output_statistics', []).filter(e => e.type_cd !== "blood"))
        .groupBy('target_unit_name')
        .map((el, idx) => {
          return {
            io: 'output',
            index: idx,
            unit_name: _.max(el).target_unit_name,
            className: '',
            label_value: t('output'),
            item_name: t(idx),
            total_value: _.sumBy(el, 'total_giving_value')
          }
        }).value(),
    ],
    ['index']
  );

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex-col space-y-1 p-2 background-200 rounded-md">
        <div className="text-gray-500 font-bold text-xs uppercase">
          {
            t('financial balance')
          }
        </div>
        {
          _moneyStatisticIO
            .map((el, i) => {
              const valueField = "total_value";
              const total_input = _.sumBy(
                _moneyStatisticIO.filter(e => e.io === "input")
                , valueField);
              const total_output = _.sumBy(
                _moneyStatisticIO.filter(e => e.io === "output")
                , valueField);

              const progressI = Math.round(
                getPercent(total_input
                  , Number(Math.max(total_input, total_output))) * 100
              ) / 100;
              const progressO = Math.round(
                getPercent(total_output
                  , Number(Math.max(total_input, total_output))) * 100
              ) / 100;

              return (
                <div key={i}>
                  <div className={classNames("flex text-xs",
                    el.io === "input" ? "text-primary" : "text-secondary")}>
                    <div className="flex-1 capitalize">
                      {
                        (
                          [
                            el.label_value
                          ]
                        ).join(" ")
                      }
                    </div>
                    <div>
                      {
                        (
                          [
                            _.get(el, 'total_value', 0).toLocaleString(),
                            t(el.unit_name || '')
                          ]
                        ).join(" ")
                      }
                    </div>
                  </div>
                  {
                    el.io === "input" ? (
                      progressI === 0 ?
                          <div className="meter">
                                <span style={{backgroundColor: 'var(--color-primary)', width: '100%'}}/>
                          </div>
                          : <Progress
                                  strokeColor={"var(--color-primary)"}
                                  percent={progressI}
                                  showInfo={false}
                                  className="my-1"
                              />
                    ) : (
                        progressO === 0 ?
                            <div className="meter">
                                <span style={{backgroundColor: 'var(--color-secondary)', width: '100%'}}/>
                            </div>
                            : <Progress
                                strokeColor={"var(--color-secondary)"}
                                percent={progressO}
                                showInfo={false}
                                className="my-1"
                              />
                    )
                  }
                </div>
              )
            })
        }
      </div>

      {
        !!_.get(item, 'job_statistics.length') && (
          <div className="flex-col space-y-1 p-2 background-200 rounded-md">
            <div className="text-gray-500 font-bold text-xs uppercase">
              {
                t('volunteers')
              }
            </div>
            {
              _.get(item, 'job_statistics', [])
                .map((el2, i) => {

                  return (
                    <div key={i} className="flex flex-col space-y-1">
                      <div className="flex text-xs text-primary">
                        <div className="flex-1 capitalize">
                          {
                            [
                              _.get(el2, 'total_employees', 0).toLocaleString(),
                              t('person')
                            ].join(" ")
                          }
                        </div>
                        <div>
                          {
                            [
                              _.get(el2, 'total_values', 0).toLocaleString(),
                              t(el2.duration_unit_name)
                            ].join(" ")
                          }
                        </div>
                      </div>
                    </div>
                  )


                })
            }
          </div>
        )
      }


      {
        (_.has(item, 'total_events') && _.get(item, 'total_events', 0) > 0) && (
          <div className="flex text-xs text-gray-500 font-bold p-2 background-200 rounded-md">
            <div className="uppercase flex-1">
              {
                t('event')
              }
            </div>
            <div>
              {
                [
                  _.get(item, 'total_events', 0)
                ].join(" ")
              }
            </div>
          </div>
        )
      }
    </div>
  )

};

export default IOReport
