import { Avatar, Button } from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import { toDataURL } from 'helpers/toDataUrl'
import { toSlug } from 'helpers/toSlug'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { exportComponentAsPNG } from 'react-component-export-image'
import { IoSaveOutline } from 'react-icons/io5'
import FlyWrapper from 'views/Wishare/Templates/FlyWrapper'
import AppLogoSvg from '../../../../components/Svgs/AppLogoSvg'
import getTitle from '../../../../helpers/getTitle'
import { LetterBackground } from './imageBase64'

const StaffRosterThanksCard =
  React.forwardRef(
    ({ item: staff_roster }, ref) => {
      const t = useTranslate()
      const [
        base64WorkplaceAvatar,
        setBase64WorkplaceAvatar,
      ] = useState(null)
      const [
        base64StaffAvatar,
        setBase64StaffAvatar,
      ] = useState(null)

      const { isSm } = useContext(
        LayoutContext
      )
      const logoSize = isSm ? 60 : 80

      const { staff, workplace } =
        staff_roster || {}

      const staff_avatar =
        getAvatar(staff)

      const medal = _.get(
        staff_roster,
        'medal.avatar'
      )

      const workplace_avatar =
        getAvatar(workplace)

      useEffect(() => {
        if (workplace_avatar) {
          toDataURL(
            workplace_avatar,
            function (dataUrl) {
              setBase64WorkplaceAvatar(
                dataUrl
              )
            }
          )
        }

        if (staff_avatar) {
          toDataURL(
            staff_avatar,
            function (dataUrl) {
              setBase64StaffAvatar(
                dataUrl
              )
            }
          )
        }
      }, [])

      return (
        <div
          ref={ref}
          className="max-w-5xl flex flex-col space-y-6 justify-center items-center w-full p-0 lg:p-3">
          <div
            className="p-3 lg:p-6 mx-auto w-full"
            style={{
              background: `url('${LetterBackground}') no-repeat`,
              backgroundSize:
                '100% 100%',
            }}>
            <div className="background p-6 rounded-lg">
              <div className="flex w-full">
                <div className="items-start justify-start h-80px w-80px">
                  {!!workplace &&
                    !!base64WorkplaceAvatar && (
                      <Avatar
                        alt=""
                        id="workplace_avatar"
                        className="text-3xl"
                        width={logoSize}
                        height={
                          logoSize
                        }
                        style={{
                          width:
                            logoSize,
                          height:
                            logoSize,
                          lineHeight:
                            logoSize,
                        }}
                        src={
                          base64WorkplaceAvatar
                        }
                      />
                    )}
                </div>
                <div className="flex-1" />
                <div className="flex items-end justify-end h-80px w-80px">
                  {!!base64StaffAvatar && (
                    <Avatar
                      alt=""
                      id="staff_avatar"
                      className="text-3xl"
                      style={{
                        width: logoSize,
                        height:
                          logoSize,
                        lineHeight:
                          logoSize,
                      }}
                      src={
                        base64StaffAvatar
                      }
                    />
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col space-y-4 pt-6 justify-center items-center">
                <div className="flex flex-col items-center w-full">
                  <div className="text-3xl lg:text-4xl font-bold text-primary uppercase leading-none text-center whitespace-no-wrap w-full">
                    {t(
                      'sincerely thank you'
                    )}
                  </div>
                  <div className="text-sm lg:text-lg font-medium text-primary italic uppercase leading-tight text-center w-full mt-2">
                    {
                      'sincerely thank you'
                    }
                  </div>
                </div>
                <div className="text-3xl text-center font-bold text-secondary uppercase leading-tight text-center w-full ">
                  {_.get(staff, 'name')}
                </div>

                <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                  <div className="text-sm text-color-000 text-center w-full">
                    {t(
                      'spent my precious time at work'
                    )}
                  </div>
                  <span className="text-xs font-light text-color-300 italic text-center w-full">
                    {
                      'spent my precious time at work'
                    }
                  </span>
                </div>

                <div className="text-xl lg:text-3xl font-bold text-green-600 uppercase flex flex-col items-center gap-1 w-full">
                  <div className="leading-tight">
                    {_.get(
                      staff_roster,
                      'position_title'
                    )}
                  </div>
                  <div className="leading-tight text-center">
                    {_.get(
                      staff_roster,
                      'workplace.title'
                    )}
                  </div>
                </div>

                <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                  <div className="text-sm text-center text-color-000 text-center w-full">
                    {t(
                      'We sincerely appreciate your dedication of time and effort to making this life better'
                    )}
                  </div>
                  <div className="text-xs text-color-300 font-light italic text-center w-full">
                    {
                      'We sincerely appreciate your dedication of time and effort to making this life better'
                    }
                  </div>
                </div>

                {_.get(
                  staff_roster,
                  'medal'
                ) && (
                  <div className="flex flex-col items-center gap-2">
                    <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                      <div className="text-sm text-center text-color-000 text-center w-full">
                        {t(
                          'We would like to award this medal to mark your contribution'
                        )}
                      </div>
                      <div className="text-xs text-color-300 font-light italic text-center w-full">
                        {
                          'We would like to award this medal to mark your contribution'
                        }
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Avatar
                        size={40}
                        src={medal}
                      />
                    </div>
                  </div>
                )}

                <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full">
                  <div className="text-sm text-center text-color-000 text-center w-full">
                    {t(
                      'Wishing you always full of health, peace and enthusiasm. Sincerely thank you.'
                    )}
                  </div>
                  <div className="text-xs text-color-300 font-light italic text-center w-full">
                    {
                      'Wishing you always full of health, peace and enthusiasm. Sincerely thank you.'
                    }
                  </div>
                </div>

                <div className="text-sm lg:text-base flex flex-col items-center leading-tight w-full mt-3">
                  <div className="text-sm font-medium text-color-000 flex items-center justify-center w-full">
                    {t('Kind regards,')}
                  </div>
                  <div className="text-xs text-color-300 font-light italic text-center flex items-center justify-center w-full">
                    {'Kind regards,'}
                  </div>
                </div>

                <div className="font-bold leading-tight text-color-org text-center w-full">
                  {getTitle(
                    _.get(
                      staff_roster,
                      'workplace'
                    )
                  )}
                </div>

                <div className="w-full flex justify-between leading-tight">
                  <div className="flex flex-col">
                    <div className="text-primary font-medium">
                      Wishare.com
                    </div>
                    <div className="text-xs text-color-300 italic">
                      {t(
                        'connecting the goodness'
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-end leading-tight">
                    <AppLogoSvg height="1rem" />
                    <div className="text-xs text-color-300 italic">
                      {
                        'Connecting the goodness'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  )

const StaffRosterThanksCardWrapper = ({
  item,
}) => {
  const t = useTranslate()
  const componentRef = useRef()

  const name = [
    _.get(item, 'staff.name'),
    _.get(item, 'position_title'),
  ].join(' ')
  const fileName =
    toSlug(name, '_') +
    '_ThankYou_Letter'

  return (
    <React.Fragment>
      <FlyWrapper className="z-10">
        <div className="flex p-3 space-x-3">
          <div className="flex-1" />
          <Button
            className="border-none rounded-lg space-x-3 flex items-center justify-center"
            onClick={() =>
              exportComponentAsPNG(
                componentRef,
                {
                  fileName:
                    fileName + '.png',
                  html2CanvasOptions: {
                    backgroundColor:
                      null,
                    foreignObjectRendering: true,
                    scrollY: 0,
                    x: 0,
                    y: 0,
                  },
                }
              )
            }>
            <IoSaveOutline />
            <span>
              {t('export as PNG')}
            </span>
          </Button>
        </div>
      </FlyWrapper>
      <StaffRosterThanksCard
        item={item}
        ref={componentRef}
      />
    </React.Fragment>
  )
}

export default StaffRosterThanksCardWrapper
