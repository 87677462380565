import {getId, getType,} from 'apis/model/base'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback,} from 'react'
import {IoBarChartOutline, IoFlowerOutline, IoListOutline, IoPersonOutline,} from 'react-icons/io5'
import {SiTinyletter,} from 'react-icons/si'
import {RiUserSharedLine} from 'react-icons/ri'
import {compose, fromRenderProps, withProps,} from 'recompose'
import {renderFalse, renderOwnChild,} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {createCheckPermissions, invalidPermissions,} from 'views/Wishare/factory/createCheckPermissions'
import {SettingsMenu} from 'views/Wishare/factory/createSettingLayout'
import {DonorOwnerDonations} from 'views/Wishare/Organization/holders/OrganizationDonationList'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceDonationEventList from '../Activities/WorkspaceDonationEventList'
import {useMenuHandler} from '../useMenuHandler'
import WorkspaceContext from '../WorkspaceContext'
import WorkspaceDonorsEmailHistory from './WorkspaceDonorsEmailHistory'
import WorkspaceDonorsList from './WorkspaceDonorsList'
import WorkspaceDonorsPendingDonations from './WorkspaceDonorsPendingDonations'
import WorkspaceDonorsRecentDonations from './WorkspaceDonorsRecentDonations'
import WorkspaceDonorStatistic from './WorkspaceDonorStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  DONATION_GROUP: 'donation-group',
  DONOR_GROUP: 'donor-group',
  DONORS: 'donors',
  TOP_DONORS: 'top-donors',
  DONATION_EVENTS: 'donation-events',
  ORGANIZATION_DONATIONS:
    'organization-donations',
  RECENT_DONATIONS: 'recent-donations',
  PENDING_DONATIONS:
    'pending-donations',
  THANKS_EMAIL_HISTORY:
    'thanks-email-history',
})

const with_menu_items = ({
                           isDisabled = renderFalse,
                         }) => [
  {
    key: ItemKeys.STATISTIC,
    label: 'insights',
    icon: (
      <IoBarChartOutline size={16}/>
    ),
  },
  {
    key: ItemKeys.DONATION_GROUP,
    label: 'donations',
    icon: <IoFlowerOutline size={16}/>,
    children: [
      {
        key: ItemKeys.DONATION_EVENTS,
        label: 'campaign list',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
      {
        key: ItemKeys.RECENT_DONATIONS,
        label: 'list of contributors',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
      {
        key: ItemKeys.PENDING_DONATIONS,
        label: 'register to donate',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
      {
        key: ItemKeys.ORGANIZATION_DONATIONS,
        label:
          'donation of organization',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
      {
        key: ItemKeys.THANKS_EMAIL_HISTORY,
        label:
          'email history thank you',
        icon: (
          <SiTinyletter size={16}/>
        ),
      },
    ],
  },
  {
    key: ItemKeys.DONOR_GROUP,
    label: 'donors',
    icon: <IoPersonOutline size={16}/>,
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageDonor,
      ],
    }),
    children: [
      {
        key: ItemKeys.DONORS,
        label: 'donors',
        icon: (
          <RiUserSharedLine size={16}/>
        ),
      },
    ].map(({...rest}) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageDonor,
        ],
      }),
    })),
  },
]

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceDonorStatistic,
  },
  {
    key: ItemKeys.DONATION_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDonation,
    ])(WorkspaceDonationEventList),
  },
  {
    key: ItemKeys.DONORS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDonor,
    ])(WorkspaceDonorsList),
  },
  {
    key: ItemKeys.RECENT_DONATIONS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDonation,
    ])(WorkspaceDonorsRecentDonations),
  },
  {
    key: ItemKeys.PENDING_DONATIONS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDonation,
    ])(WorkspaceDonorsPendingDonations),
  },
  {
    key: ItemKeys.ORGANIZATION_DONATIONS,
    component: compose(
      fromRenderProps(
        WorkspaceContext.Consumer,
        ({organization}) => ({
          owner_id: getId(organization),
          owner_type: getType(
            organization
          ),
        })
      ),
      createCheckPermissions([
        organizationPermissionCodes.organizationManageDonation,
      ]),
      withProps({
        Header: () => (
          <ListHeader title="donation of organization"/>
        ),
      })
    )(DonorOwnerDonations),
  },
  {
    key: ItemKeys.THANKS_EMAIL_HISTORY,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDonation,
    ])(WorkspaceDonorsEmailHistory),
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceDonationsContent =
  () => {
    const {
      activeKey,
      Component,
      onMenuSelect,
    } = useMenuHandler({
      routes: routeEntities,
      actionName: Queries.REL,
    })

    const renderMenu = useCallback(
      () => (
        <WishareSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled:
                    invalidPermissions(
                      permissions
                    ),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[
                  activeKey,
                ]}
                defaultOpenKeys={[
                  ItemKeys.DONATION_GROUP,
                  ItemKeys.DONOR_GROUP,
                ]}
              />
            )}
          </PermissionContext.Consumer>
        </WishareSiderMenu>
      ),
      [with_menu_items, activeKey]
    )

    return (
      <DefaultTemplate
        renderMenu={renderMenu}>
        <div className="px-3">
          <Component/>
        </div>
      </DefaultTemplate>
    )
  }

export default WorkspaceDonationsContent
