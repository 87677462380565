import { Avatar } from 'antd'
import { getType } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {
  IoCall,
  IoLocationSharp,
  IoMail,
  IoMedalOutline,
  IoPeople,
  IoPerson,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  getFullAddress,
  Null,
  renderElse,
} from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import {ImLibrary} from "react-icons/im";

const renderDefaultSubtitle = (
  subTitle,
  account
) => (
  <React.Fragment>
    {subTitle}
    {account}
  </React.Fragment>
)

export const renderStaffItem =
  (params) => (item, index) => {
    if (!!_.get(item, 'deleted')) {
      return null
    }

      const {
          showAccount = true,
          showOrg = false,
      } = params || {}

    const {
      Footer = Null,
      renderContextMenu,
      renderSubtitle = renderDefaultSubtitle,
    } = params || {}

    const {
      owner,
      email,
      location,
      medal_awards,
      employee_type,
      phone_number,
    } = item || {}

    const subTitle = employee_type

    const RowInformation = ({
      icon,
      label,
      value,
      hiddenIfEmpty = true,
    }) =>
      !value && hiddenIfEmpty ? null : (
        <div className="flex items-center gap-1 italic text-xs">
          {icon}
          {!icon && (
            <span className="text-color-400 font-light whitespace-no-wrap">
              {_.isString(label)
                ? `${label}: `
                : label}
            </span>
          )}
          <span className="text-color-200">
            {value}
          </span>
        </div>
      )

    const account = renderElse(
      _.isEmpty(owner),
      <div className="flex items-center gap-2">
        {' - '}
        <Link
          to={getLinkToDetail(owner)}
          className="flex items-center text-xs gap-1 no-underline">
          {getType(owner) ===
          WishareEntities.USER ? (
            <IoPerson />
          ) : (
            <IoPeople />
          )}
          {getTitle(owner)}
        </Link>
      </div>
    )

    return (
      <Translate key={index}>
        {(t) => (
          <div className="relative  w-full border border-color-50 rounded-lg background">
            <div className="relative flex items-center p-3 gap-3">
              <LayoutContext.Consumer>
                {({ isSm }) => !!!isSm && (
                    <Avatar
                      size={40}
                      className="flex flex-center background-100"
                      icon={<IoPerson size={20} className="text-color-400"/>}
                      src={_.get(item, 'avatar')}
                    />
                  )
                }
              </LayoutContext.Consumer>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <Link
                    to={getLinkToDetail(
                      item
                    )}
                    className="font-bold leading-tight max-lines-1">
                    {getTitle(item)}
                  </Link>
                </div>
                <span className="flex items-center gap-2 italic text-xs">
                    {renderSubtitle(
                        <span className="font-light text-secondary">
                          {t(subTitle)}
                        </span>,
                        showAccount ?? account
                    )}
                    {_.get(item, 'organization') && showOrg && (
                        <div className="flex items-center gap-2 text-color-400">
                            {' - '}
                            <Avatar
                                size={20}
                                icon={<ImLibrary className="text-secondary"/>}
                                src={_.get(item, 'organization.avatar')}
                                className="flex flex-center bg-secondary-50"/>
                            <span className="text-color-org">{_.get(item, 'organization.title')}</span>
                        </div>
                    )}
                </span>
                <div className="flex flex-col">
                  <RowInformation
                    icon={
                      <IoLocationSharp className="text-color-500" />
                    }
                    value={getFullAddress(
                      location
                    )}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    {!!phone_number && (
                      <RowInformation
                        icon={
                          <IoCall className="text-color-500 mr-1" />
                        }
                        value={[
                          phone_number,
                        ]
                          .filter(
                            (e) => !!e
                          )
                          .join(' - ')}
                      />
                    )}
                    {!!email && (
                      <RowInformation
                        icon={
                          <IoMail className="text-color-500 mr-1" />
                        }
                        value={[email]
                          .filter(
                            (e) => !!e
                          )
                          .join(' - ')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  right: _.isFunction(
                    renderContextMenu
                  )
                    ? '2rem'
                    : 0,
                }}
                className="absolute right-0 top-0 p-2 flex items-center gap-2">
                {Array.from(
                  medal_awards || []
                ).map((e, index) => (
                  <Avatar
                    key={index}
                    size={25}
                    src={getAvatar(
                      _.get(e, 'medal')
                    )}
                    icon={
                      <IoMedalOutline
                        style={{
                          color:
                            '#bdbdbd',
                        }}
                      />
                    }
                    className="flex flex-center background-100"
                  />
                ))}
              </div>
            </div>
            <Footer {...{ item }} />
            {_.isFunction(
              renderContextMenu
            )
              ? renderContextMenu(item)
              : null}
          </div>
        )}
      </Translate>
    )
  }
