import { Input } from 'antd'
import { beneficiary_getGivings_api } from 'apis'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useMemo,
  useState,
} from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import { withProps } from 'recompose'
import { Null } from 'views/Shared'
import { GivingItemsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import GivingRecordItem from 'views/Wishare/Giving/components/GivingRecordItem'
import { GivingItemEntity } from 'views/Wishare/Giving/GivingEntity'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'

const BeneficiaryGivingsModalContent =
  ({
    status,
    beneficiary_id,
    Header = Null,
    Wrapper = 'div',
    filter_actions = {
      sortBy: 'sort-by',
      keyword: 'keyword',
    },
  }) => {
    const t = useTranslate()

    const [keyword, setKeyword] =
      useState()

    const location = useLocation()

    const sortBy = useSearchParam(
      filter_actions.sortBy
    )

    const refresh_deps = useMemo(
      () => [
        beneficiary_id,
        `refreshToken=${_.get(
          location.state,
          'refreshToken'
        )}`,
      ],
      [beneficiary_id, location.state]
    )

    const [pager_widget] =
      usePaginationHelper({
        onInit: ({
          onSortChange = Null,
        }) => {
          onSortChange(sortBy)
        },
        pagerInfo: {
          apiInfo:
            beneficiary_getGivings_api,
          query: bindQueryParam({
            id: beneficiary_id,
          }),
          RenderEntity:
            GivingItemEntity,
          RenderItem: withProps({
            withoutContextMenu: true,
            showEvent: true
          })(GivingRecordItem),
          PaginationMeta: Null,
        },
        withKey: ({ sortBy }) =>
          [
            ...refresh_deps,
            sortBy,
            keyword,
          ].join('/'),
        withValues: ({
          keyword: ignored,
          ...rest
        }) => ({ keyword, ...rest }),
        renderSearchBar: (_) => (
          <Input.Search
            defaultValue={keyword}
            onSearch={(value) =>
              setKeyword(value)
            }
            allowClear={true}
            className="input-search"
            placeholder={t('search')}
          />
        ),
        dependencies: [beneficiary_id],
        renderFilter: Null,
        query_params: filter_actions,
        sortHeader: null,
        SortByWidget: GivingItemsSortBy,
      })

    return (
      <Wrapper className="flex flex-col space-y-3">
        <Header />
        {pager_widget}
      </Wrapper>
    )
  }

export default BeneficiaryGivingsModalContent
