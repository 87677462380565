import { Button } from 'antd'
import { getId } from 'apis/model/base'
import { showConfirm } from 'components/Feed/TimeLineFeed'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import moment from 'moment'
import React, {
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoEyeOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  Null,
  renderSelf,
  Visible,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { recruitmentApi } from 'views/Wishare/apis'
import { wishareConfigs } from 'views/Wishare/configs'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { WishareModal } from 'views/Wishare/ModalContext'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import {
  GenderTypes,
  getProp,
} from 'views/Wishare/wishareFieldTypes'
import { ItemContextMenu } from '../../factory/createContextMenu'
import ApplicationReviewForm from '../ActionForms/ApplicationReviewForm'
import RecruitmentManageEditForm from '../ActionForms/RecruitmentManageEditForm'

export const RecruitmentItemContextActions =
  Object.freeze({
    REJECT: 'reject',
    APPROVE: 'approve',
    REMOVE: 'remove',
    VIEW: 'view',
  })

const CandidateInformation = (
  props
) => {
  const t = useTranslate()
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
      {[
        {
          name: 'gender',
          label: 'gender',
          transform: (value) => {
            const gender = getProp(
              Object.values(
                GenderTypes
              ),
              'label'
            )(Number(value))
            return _.isString(gender)
              ? t(gender)
              : null
          },
        },
        {
          name: 'date_of_birth',
          label: 'birthday',
          transform: (value) =>
            value
              ? moment(value).format(
                  wishareConfigs.dateFormat
                )
              : undefined,
        },
        {
          name: 'email',
          label: 'email',
          transform: (value) =>
            value && (
              <a
                href={`mailto:${value}`}>
                {value}
              </a>
            ),
        },
        {
          name: 'phone_number',
          label: 'phone',
          transform: (value) =>
            value && (
              <a href={`tel:${value}`}>
                {value}
              </a>
            ),
        },
      ].map(
        (
          {
            name,
            label,
            transform = renderSelf,
          },
          index
        ) => {
          const _value = transform(
            _.get(props, name)
          )
          return (
            <React.Fragment key={index}>
              {!!_value && (
                <div className="flex items-center gap-1">
                  <span className="text-xs text-color-500 italic font-light whitespace-no-wrap">
                    {`${t(label)}: `}
                  </span>
                  <span className="text-sm font-medium text-color-000 ">
                    {_value}
                  </span>
                </div>
              )}
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}

export const RecruitmentRecordItem = ({
  item,
}) => {
  const t = useTranslate()

  const {
    email,
    gender,
    idcode,
    status,
    created,
    to_date,
    from_date,
    phone_number,
    date_of_birth,
    recruitment_event = {},
  } = item || {}

  const id = getId(recruitment_event)

  const candidate_id = getId(item)

  const edit = Boolean(
    _.get(
      item,
      'recruitment_event.edit'
    )
  )

  const isPending =
    Number(status) === -1

  const isReject = Number(status) === -2

  const [
    contextAction,
    setContextAction,
  ] = useState({
    readOnly: false,
  })

  const {
    removePost = Null,
    updatePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: removeItem,
  } = useAsyncAction({
    apiInfo:
      recruitmentApi.recruitment_events_candidateManageDeleteAdd_api,
    query: bindQueryParams([
      {
        id,
      },
      { candidate_id },
    ]),
    onError: notifyOnError(t),
    onSuccess: (result, __) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      if (result) {
        removePost(result)
      }
    },
  })

  const { handleAsyncAction: reject } =
    useAsyncAction({
      apiInfo:
        recruitmentApi.recruitment_events_candidateManageReject_api,
      query: bindQueryParams([
        {
          id,
        },
        { candidate_id },
      ]),
      onError: notifyOnError(t),
      onSuccess: (result, __) => {
        successNotify(
          NotificationActionTypes.DELETE,
          t
        )
        if (result) {
          removePost(result)
        }
      },
    })

  const contextMenu = useMemo(
    () =>
      [
        {
          key: RecruitmentItemContextActions.VIEW,
          label: 'view',
          icon: (
            <IoEyeOutline
              size={15}
              className="text-primary"
            />
          ),
        },
        {
          key: RecruitmentItemContextActions.REJECT,
          label: 'reject',
          icon: (
            <IoCloseCircleOutline
              size={15}
              className="text-orange-600"
            />
          ),
          invisible:
            !edit || !isPending,
        },
        {
          key: RecruitmentItemContextActions.APPROVE,
          label: 'approve',
          icon: (
            <IoCheckmarkCircleOutline
              size={15}
              className="text-green-500"
            />
          ),
          invisible:
            !edit || !isPending,
        },
        {
          key: RecruitmentItemContextActions.REMOVE,
          label: 'remove',
          icon: (
            <IoTrashOutline
              size={15}
              className="text-red-500"
            />
          ),
          invisible: !edit,
        },
      ]
        .filter(Visible)
        .map(
          ({ invisible, ...rest }) => ({
            ...rest,
          })
        ),
    [edit, isPending]
  )

  if (
    Boolean(
      item.isDeleted || item.deleted
    )
  ) {
    return null
  }

  const handleContextAction = (key) => {
    switch (key) {
      case RecruitmentItemContextActions.VIEW:
        setContextAction({
          type: key,
        })
        break
      case RecruitmentItemContextActions.REJECT:
        showConfirm({
          title: t(
            'are you sure you want to reject this candidate'
          ),
          okText: t('sure'),
          cancelText: t('no'),
          okButtonProps: {
            type: 'primary',
            danger: true,
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          cancelButtonProps: {
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          onOk: () => {
            reject()
          },
        })
        break
      case RecruitmentItemContextActions.APPROVE:
        setContextAction({
          type: key,
          readOnly: true,
        })
        break
      case RecruitmentItemContextActions.REMOVE:
        showConfirm({
          title: t(
            'are you sure delete this'
          ),
          okText: t('sure'),
          cancelText: t('no'),
          okButtonProps: {
            type: 'primary',
            danger: true,
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          cancelButtonProps: {
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          onOk: () => {
            removeItem()
          },
        })
        break
      default:
    }
  }

  return (
    <FlexCol className="space-y-1 relative">
      <div className="flex flex-col p-2 md:p-3 border rounded-lg relative">
        <div className="flex items-base space-x-2 text-xs text-color-400 italic">
          <span>{t('code')}</span>
          <span className="text-secondary font-bold text-base leading-none">
            {idcode}
          </span>
          <span className="font-bold">
            {'-'}
          </span>
          <span>
            {moment(created).format(
              'HH:mm - DD/MM/YYYY'
            )}
          </span>

          {isPending ? (
            <span className="text-primary font-semibold text-sm italic">
              {t('pending')}
            </span>
          ) : isReject ? (
            <span className="text-orange-600 font-semibold text-sm italic">
              {t('reject')}
            </span>
          ) : (
            <span className="text-green-500 font-semibold text-sm italic">
              {t('approved')}
            </span>
          )}
        </div>

        <div className="flex items-baseline space-x-1 my-2">
          {!!_.get(
            item,
            'candidate'
          ) && (
            <Link
              to={getLinkToDetail(
                _.get(item, 'candidate')
              )}
              className="capitalize font-bold text-color-000 ">
              {_.get(
                item,
                'candidate.name'
              )}
            </Link>
          )}
        </div>
        <div className="space-y-1">
          <CandidateInformation
            {...{
              email,
              gender,
              phone_number,
              date_of_birth,
            }}
          />
        </div>
      </div>
      {!_.isEmpty(contextMenu) && (
        <div className="absolute right-0 top-0 px-2 py-1">
          <ItemContextMenu
            onMenuSelect={
              handleContextAction
            }
            items={contextMenu}
          />
        </div>
      )}
      {contextAction.type ===
        RecruitmentItemContextActions.APPROVE && (
        <RecruitmentManageEditForm
          candidate={item}
          onCancel={() => {
            setContextAction({
              type: undefined,
            })
          }}
          actionType={
            contextAction.type
          }
          readOnly={Boolean(
            contextAction.readOnly
          )}
          onUpdated={(id, newItem) => {
            updatePost(id, newItem)
          }}
        />
      )}
      {contextAction.type ===
        RecruitmentItemContextActions.VIEW && (
        <WishareModal
          closable={false}
          footer={
            <Button
              onClick={() => {
                setContextAction({
                  type: undefined,
                })
              }}
              className="no-shadow no-text-shadow rounded-lg">
              {t('close')}
            </Button>
          }
          onCancel={() => {
            setContextAction({
              type: undefined,
            })
          }}
          visible={
            contextAction.type ===
            RecruitmentItemContextActions.VIEW
          }>
          <div className="text-center uppercase font-bold text-2xl md:text-3xl my-3">
            {t('candidate profile')}
          </div>
          <PermissionProvider
            permissions={{
              [permissionCodes.canEdit]:
                edit,
            }}>
            <ApplicationReviewForm
              item={item}
              owner={_.get(
                item,
                'recruitment_event.root_owner'
              )}
              profile={_.get(
                item,
                'profile'
              )}
            />
          </PermissionProvider>
        </WishareModal>
      )}
    </FlexCol>
  )
}

export default RecruitmentRecordItem
