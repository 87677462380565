import { MenuOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import { post_getPosts_Api } from 'apis'
import { userModel } from 'apis/model'
import ContentContainer from 'components/ContentContainer'
import TimeLineFeed from 'components/Feed/TimeLineFeed'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import LazyComponent from 'components/LazyComponent'
import preventParentEvent from 'helpers/preventParentEvent'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React from 'react'
import ServerContext from 'views/Server/ServerContext'
import { HeaderButton } from '../Organization/components/PageHeader'
import { Welcome } from '../Organization/components/Welcome'
import PageDetail from './PageDetail'
const renderItem = (item = null) => (
  <TimeLineFeed
    item={item.content}></TimeLineFeed>
)
const Allposts = () => (
  <LazyComponent>
    <ServerContext.Consumer>
      {({ item }) => (
        <LazyPagination
          renderNoMore={() => (
            <Welcome />
          )}
          apiInfo={post_getPosts_Api}
          query={{
            ':prop': 'user',
            ':id': item.username
          }}
          renderItem={
            renderItem
          }></LazyPagination>
      )}
    </ServerContext.Consumer>
  </LazyComponent>
)
const UserFeed = () => {
  const t = useTranslate()
  return (
    <PermissionProvider
      permissions={{
        canPostArticle: false
      }}>
      <div className="PageHeader flex background stickyTop items-center border-b border-gray-300">
        <LayoutContext.Consumer>
          {({ setOpenMenu }) => (
            <HeaderButton
              icon={<MenuOutlined />}
              onClick={() =>
                setOpenMenu(true)
              }
            />
          )}
        </LayoutContext.Consumer>
        <span className="capitalize font-semibold ml-2 truncate flex items-center flex-1">
          <ServerContext.Consumer>
            {({ item }) => (
              <>
                <div
                  className="flex items-center  truncate flex-1"
                  onClick={
                    preventParentEvent
                  }>
                  <span className="capitalize">
                    {userModel.getTitle(
                      item
                    )}
                  </span>
                </div>
              </>
            )}
          </ServerContext.Consumer>
        </span>
      </div>
      <PageDetail />
      <ContentContainer className="px-3  w-full flex flex-col items-stretch verticalList">
        <Divider orientation="left">
          <span className="bold text-lg uppercase">
            {t('feeds')}
          </span>
        </Divider>
        {/* <NewPost /> */}
        <Allposts />
      </ContentContainer>
    </PermissionProvider>
  )
}
export default UserFeed
