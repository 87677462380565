import {
  Alert,
  Avatar,
  Button,
  List,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useRef,
} from 'react'
import {
  IoCheckmarkCircleOutline,
  IoFlowerOutline,
  IoGiftOutline,
} from 'react-icons/io5'
import LoadingPage from 'views/LoadingPage'
import NoFoundPage from 'views/NoFoundPage'
import {
  getResponseItem,
  Null,
  renderOwnChild,
} from 'views/Shared'
import {
  donationApi,
  givingApi,
} from '../apis'
import DonationContext, {
  DonationProvider,
} from '../Donation/DonationContext'
import { WishareEntities } from '../enums'
import { bindQueryParam } from '../functions/routerHelper'
import GivingContext, {
  GivingProvider,
} from '../Giving/GivingContext'
import { notifyOnError } from './createErrorEvent'

const ExportContainer = ({
  item,
  query,
  apiInfo,
  values,
  children,
}) => {
  const t = useTranslate()

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const {
    response,
    isLoading,
    handleAsyncAction: handleExport,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onError: notifyOnError(t),
  })

  if (_.isFunction(children)) {
    return children(handleExport, {
      isLoading,
      ...getResponseItem(response),
    })
  } else {
    return children || null
  }
}

const Downloader = ({
  children,
  downloadURL,
  delay = 3000,
  auto = false,
  onSuccess = Null,
}) => {
  const t = useTranslate()

  const timer = useRef()

  useEffect(() => {
    if (!!downloadURL && auto) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        onSuccess({ downloadURL })
      }, delay)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [downloadURL, auto])

  return (
    <div>
      {!!downloadURL && (
        <div className="my-6 border border-green-500 rounded-lg">
          <Alert
            type="success"
            banner={true}
            showIcon={false}
            message={
              <div
                className="text-green-600"
                role="alert">
                <div className="flex flex-row items-center py-1">
                  <IoCheckmarkCircleOutline className="text-green-500 text-3xl mr-3" />
                  <div>
                    <strong className="font-semibold text-lg">
                      {t(
                        'data has been generate'
                      )}{' '}
                      {'!'}
                    </strong>
                    <span className="block sm:inline italic font-light text-sm">
                      {t(
                        'please click the {download} button below to download to your device',
                        {
                          download: (
                            <strong className="font-semibold">
                              {' '}
                              {t(
                                'download'
                              )}{' '}
                            </strong>
                          ),
                        }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}

      <div className="flex justify-center">
        {!!downloadURL ? (
          <Button
            className="no-shadow no-text-shadow rounded-lg"
            type="primary">
            <a
              className="no-underline"
              target="_blank"
              href={downloadURL}
              rel="noopener noreferrer">
              {t('download')}
            </a>
          </Button>
        ) : (
          children || null
        )}
      </div>
    </div>
  )
}

const ExportModalContent = ({
  item,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const t = useTranslate()

  if (!item || _.isEmpty(item)) {
    return <NoFoundPage />
  }

  return (
    <Wrapper>
      <div className="flex flex-col w-full">
        <div className="flex flex-col gap-1">
          <span className="text-color-300 uppercase font-medium ">
            {t('you are exporting campaign data')}{' '}{t(getType(item))}
          </span>
          <div className="flex items-center gap-2 background border p-2 rounded-lg">
            <Avatar
                className="flex flex-center background-100"
                icon={
                  getType(item) ===
                  'donation_event' ? (
                      <IoFlowerOutline className="text-primary" />
                  ) : (
                      <IoGiftOutline className="text-secondary" />
                  )
                }
            />
            <div className="flex flex-col">
              <span className="text-primary font-medium">
                {getTitle(item)}
              </span>
              <span className="text-secondary text-xs font-light italic">
                {_.get(item, 'owner.title')}
              </span>
            </div>
          </div>
        </div>
        <div className="py-3" />
        <ExportContainer
          item={item}
          {...props}>
          {(
            handleExport,
            { path, isLoading }
          ) => (
            <Downloader
              downloadURL={path}>
              <Button
                type="primary"
                className="no-shadow no-text-shadow rounded-lg no-underline"
                disabled={!!isLoading}
                onClick={() => {
                  handleExport()
                }}>
                {t('export')}
              </Button>
            </Downloader>
          )}
        </ExportContainer>
      </div>
    </Wrapper>
  )
}

export const createDonationExporter = (
  item
) => (
  <ExportModalContent
    {...{
      item,
      query: bindQueryParam({
        id: getId(item),
      }),
      apiInfo:
        donationApi.donation_events_getDonationDataForExportFile_api,
    }}
  />
)

export const createGivingExporter = (
  item
) => (
  <ExportModalContent
    {...{
      item,
      query: bindQueryParam({
        id: getId(item),
      }),
      apiInfo:
        givingApi.giving_events_getGivingDataForExportFile_api,
    }}
  />
)

export const ExporterLoader = ({
  id,
  type,
}) => {
  if (_.isEmpty(id)) {
    return null
  }
  switch (type) {
    case WishareEntities.GIVING:
      return (
        <GivingProvider item={id}>
          <GivingContext.Consumer>
            {({ giving, isLoading }) =>
              !!isLoading ? (
                <LoadingPage />
              ) : (
                createGivingExporter(
                  giving
                )
              )
            }
          </GivingContext.Consumer>
        </GivingProvider>
      )
    case WishareEntities.DONATION:
      return (
        <DonationProvider item={id}>
          <DonationContext.Consumer>
            {({
              donation,
              isLoading,
            }) =>
              !!isLoading ? (
                <LoadingPage />
              ) : (
                createDonationExporter(
                  donation
                )
              )
            }
          </DonationContext.Consumer>
        </DonationProvider>
      )
    default:
      return null
  }
}
