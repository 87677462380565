import _ from 'lodash'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSearchParam } from 'react-use'
import { Null } from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'

export const useMenuHandler = ({
  actionName = Queries.REL,
  routes = [
    {
      key: undefined,
      component: Null,
    },
  ],
}) => {
  const currentAction =
    useSearchParam(actionName)

  const { key: defaultActiveKey } =
    _.first(routes) || {
      key: undefined,
    }

  const history = useHistory()

  const [activeKey, setActivekey] =
    useState(defaultActiveKey)

  const Component = useCallback(
    (props) => {
      const { component: C = Null } =
        _.find(routes, {
          key: activeKey,
        }) || {}
      return <C {...props} />
    },
    [activeKey]
  )

  const onMenuSelect = (key) => {
    history.push({
      search: [
        '?',
        new URLSearchParams({
          [actionName]: key,
        }).toString(),
      ].join(''),
    })
  }

  useEffect(() => {
    if (
      _.find(routes, {
        key: currentAction,
      })
    ) {
      setActivekey(currentAction)
    } else if (
      !_.isEmpty(currentAction)
    ) {
      history.push({
        search: '',
      })
    }
  }, [currentAction])

  return {
    activeKey,
    Component,
    onMenuSelect,
    currentAction,
  }
}
