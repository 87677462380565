import { Avatar } from 'antd'
import { getId } from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { renderOwnChild } from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { beneficiaryApi } from 'views/Wishare/apis'
import EntityList from 'views/Wishare/EntityList'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import { OverlayItemBanner } from '../../Templates/ItemTemplate'
import BeneficiaryContext from '../BeneficiaryContext'

const ReferralItem = ({
  item,
  actions,
}) => {
  const t = useTranslate()

  if (_.isEmpty(item)) {
    return null
  }
  const { referral } = item || {}

  return (
    <div className=" flex flex-col border border-color-50 rounded-lg relative">
      {/*<div className="absolute right-0 top-0 p-2">*/}
      {/*    {actions}*/}
      {/*</div>*/}
      <OverlayItemBanner
        type={_.get(referral, '_type')}
      />
      <div className="h-7" />
      <div className="p-3 flex items-center gap-2">
        <Avatar
          size={40}
          src={_.get(
            referral,
            'avatar'
          )}
          icon={<IoPerson />}
          className="flex items-center justify-center background-100"
        />
        <Link
          to={getLinkToDetail(
            _.get(item, 'referral')
          )}
          className="text-base md:text-lg font-bold no-underline flex-1 text-color-000">
          {_.get(referral, 'name')}
        </Link>
      </div>
    </div>
  )
}

const BeneficiarySettingReferrals = ({
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const { beneficiary } = useContext(
    BeneficiaryContext
  )

  const id = getId(beneficiary)

  const apiInfo =
    beneficiaryApi.beneficiary_getReferrals_api

  const refreshKey = [
    mapPathParam(apiInfo.path, { id }),
    'setting',
  ].join('/')

  const {
    handleAsyncAction: deleteReferral,
  } = useAsyncAction({
    query: bindQueryParam({
      beneficiary_id: id,
    }),
    apiInfo:
      beneficiaryApi.beneficiary_deleteReferral_api,
  })

  const handleAction = useCallback(
    (key, { item }) => {
      switch (key) {
        case CRUD.DELETE:
          const modal =
            showDeleteConfirmDialog({
              onOk: () => {
                deleteReferral(
                  {},
                  bindQueryParam({
                    beneficiary_referral_id:
                      getId(item),
                  })
                )
                modal.destroy()
              },
              onCancel: () =>
                modal.destroy(),
              translate: t,
            })
          break
        default:
          break
      }
    }
  )

  const renderContextMenu = useCallback(
    (referral) => {
      const menuOptions = [
        {
          key: CRUD.DELETE,
          label: 'delete',
        },
      ]
      return (
        <ItemContextMenu
          item={referral}
          items={menuOptions}
          onMenuSelect={handleAction}
        />
      )
    },
    [beneficiary, handleAction]
  )

  return (
    <Wrapper>
      <EntityList
        apiInfo={apiInfo}
        refreshKey={refreshKey}
        renderItem={(item) => (
          <ReferralItem
            item={item}
            key={getId(item)}
            actions={renderContextMenu(
              item
            )}
          />
        )}
        RenderEntity={React.Fragment}
        query={bindQueryParam({ id })}
      />
    </Wrapper>
  )
}

export default BeneficiarySettingReferrals
