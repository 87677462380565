import createStickySider from 'views/Wishare/factory/createStickySider'
import CategoryRelatedTags from './CategoryRelatedTags'

const CategorySider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(CategoryRelatedTags)

export default CategorySider
// branch(({ invisible }) => {
//   const { isSm } = useContext(
//     LayoutContext
//   )
//   return Boolean(!invisible && isSm)
// }, renderNothing)
