import { donation_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { withProps } from 'recompose'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DonationGivingPicker from '../components/DonationGivingPicker'
import DonationContext from '../DonationContext'

const formFields = [
  { name: 'giving_events' },
]

const formSchema = [
  {
    title: 'ref_ids',
    children: [
      {
        name: 'ref_ids',
        component: withProps(
          ({
            name,
            value,
            form = {},
            onChange,
          }) => {
            const { id, type } =
              form.values || {}
            const ref = _.first(
              Array.from(value || [])
            )
            return {
              id,
              type,
              name,
              onChange,
              item: ref,
            }
          }
        )(DonationGivingPicker),
      },
    ],
  },
]

const DonationSettingGiving = () => {
  const { donation } = useContext(
    DonationContext
  )

  const validationSchema = undefined

  const donation_id = getId(donation)

  const donation_type =
    getType(donation)

  const onPreSubmit = ({
    id,
    type,
    ref_ids,
    giving_events,
    ...values
  }) => {
    return {
      ...values,
      ref_ids: Array.from(
        ref_ids || []
      ).join(','),
    }
  }

  const initialized = useCallback(
    ({
      ref_ids,
      giving_events,
      ...values
    }) => ({
      ...values,
      id: donation_id,
      type: donation_type,
      ref_ids: giving_events,
    }),
    [donation_id, donation_type]
  )

  if (_.isEmpty(donation)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: donation,
    validationSchema,
    query: bindQueryParam({
      donation_id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: donation_update_api,
  })
}

export default DonationSettingGiving
