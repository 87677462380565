import { ROOT_URL } from 'envs/_current/config'
import { WishareEntities } from '../enums'

export const ImageTypes = Object.freeze(
  {
    ICON: 'icon',
    BANNER: 'banner',
    AVATAR: 'avatar',
  }
)

export const createDefaultImage = ({
  type,
}) => {
  const prefix = `${ROOT_URL}/images/default`
  let imagePath = 'blue-bg.jpg'
  switch (type) {
    case WishareEntities.USER:
      imagePath = 'pink-bg.jpg'
      break
    case WishareEntities.GIVING:
      imagePath = 'pink-bg.jpg'
      break
    case WishareEntities.BENEFICIARY:
      imagePath = 'beneficiary-bg.jpg'
      break
    case WishareEntities.ACTIVITY:
      imagePath = 'activity-bg.jpg'
      break
    case WishareEntities.DONATION:
      imagePath = 'blue-bg.jpg'
      break
    case WishareEntities.RECRUITMENT:
      imagePath = 'green-bg.jpg'
      break
    case WishareEntities.EVENT:
      imagePath = 'event-bg.jpg'
      break
    case WishareEntities.ORGANIZATION:
      imagePath = 'dark-blue-bg.jpg'
      break
    default:
      break
  }
  return [prefix, imagePath].join('/')
}
