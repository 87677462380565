import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import numeral from 'numeral'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {
  useEffectOnce,
  useSearchParam,
} from 'react-use'
import { DelayRender } from 'views/Discovery/DelayRender'
import { useRequiredLogin } from 'views/Server/useRequiredLogin'
import {
  Null,
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { givingApi } from 'views/Wishare/apis'
import CustomButton from 'views/Wishare/custom/CustomButton'
import { WishareEntities } from 'views/Wishare/enums'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { GivingFAB } from 'views/Wishare/factory/createFAB'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { OverlayItemBanner } from '../../Templates/ItemTemplate'
import CreateGivingOptionForm from '../ActionForms/CreateGivingOptionForm'
import GivingRegisterAdminForm from '../ActionForms/GivingRegisterAdminForm'
import GivingRegisterForm, {
  registerGivingParams,
} from '../ActionForms/GivingRegisterForm'
import {
  getGivingEventStatus,
  GivingStatusTypes,
} from '../functions/getGivingEventStatus'
import GivingContext from '../GivingContext'

export const GivingOptionReceiverTypes =
  Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
  })

const GivingDetailOptions = ({
  options = [],
  target_unit_name,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  if (_.isEmpty(options)) return null
  return (
    <div>
      {options.map(
        (
          {
            name,
            type_cd,
            unit_name,
            option_qtty,
            option_value,
          },
          index
        ) => (
          <div
            key={index}
            className="flex space-x-1 font-semibold text-secondary">
            <span className="uppercase">
              {isSm
                ? numeral(
                    Number(option_value)
                  ).format('0a')
                : Number(
                    option_value
                  ).toLocaleString()}
            </span>
            <span>
              {target_unit_name}
            </span>
          </div>
        )
      )}
    </div>
  )
}

const OptionItem = ({
  item,
  giving_type,
  target_unit_name,
}) => {
  const {
    title,
    photo,
    description,
    option_items,
  } = item

  return (
    <React.Fragment>
      <div className="text-xs font-light text-color-300 italic">
        {description}
      </div>
      <div className="flex items-center justify-between mt-2">
        {giving_type === 'blood' ? (
          <span />
        ) : (
          <div
            className="font-medium mr-3"
            style={{ color: '#000' }}>
            {title}
          </div>
        )}
        <GivingDetailOptions
          options={option_items}
          target_unit_name={
            target_unit_name
          }
        />
      </div>
    </React.Fragment>
  )
}

const renderItem =
  ({
    edit = false,
    onEdit = Null,
    onRemove = Null,
    onItemClick = Null,
    disabled = false,
    ...props
  }) =>
  (item, index) => {
    const t = useTranslate()

    const canEdit = Boolean(edit)

    return (
      <div
        className={classNames("relative", disabled && 'filter-gray')}
        key={index}>
        <div
          onClick={() => {
            onItemClick(item.id)
          }}
          className={classNames(
            'border rounded-lg hover:shadow-out relative',
            !!disabled
              ? ' pointer-events-none background-100 border-color-50'
              : ' cursor-pointer border-secondary bg-secondary-50'
          )}>
          <OverlayItemBanner
            className={
              disabled
                ? 'bg-gray-400 text-color-300'
                : ' '
            }
            title={`${t('register')} ${
              index + 1
            }`}
            type={
              WishareEntities.GIVING
            }
          />
          <div
            className={classNames(
              'px-2 md:px-3 pb-2 relative',
              canEdit ? 'pt-10' : 'pt-8'
            )}>
            {!!disabled && (
              <div
                className="w-full h-full flex items-center justify-center absolute"
                style={{ top: '0' }}>
                <span className="end-stamp-campaign small">
                  {t('finished')}
                </span>
              </div>
            )}
            <OptionItem
              item={item}
              {...props}
            />
          </div>
        </div>
        {canEdit && (
          <div
            className="absolute z-10 p-1 flex gap-2"
            style={{
              top: '2px',
              right: '3px',
            }}>
            <CustomButton
              size="small"
              ghost={true}
              onClick={() => {
                onRemove(item)
              }}
              disabled={disabled && true}
              className="GhostSecondary custom-btn-options flex flex-center"
              icon={<IoTrashOutline />}
            />
            <CustomButton
              size="small"
              ghost={true}
              className="GhostPrimary custom-btn-options flex flex-center"
              onClick={() => {
                onEdit(item)
              }}
              disabled={disabled && true}
              icon={<IoCreateOutline />}
            />
          </div>
        )}
      </div>
    )
  }

const GivingPackages = ({
  giving,
  onItemClick = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [
    currentOption,
    setCurrentOption,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const options = Array.from(
    _.get(giving, 'options') || []
  )

  const target_unit_name = _.get(
    giving,
    'target_unit_name'
  )

  const [id, prop] = [
    getId(giving),
    getType(giving),
  ]

  const {
    handleAsyncAction: deleteOption,
  } = useAsyncAction({
    query: bindQueryParam({ id }),
    onError: notifyOnError(t),
    onSuccess: (__, { response }) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
    apiInfo:
      givingApi.giving_events_deleteGivingOption_api,
  })

  const { edit = false, giving_type } =
    giving || {}

  const onEdit = (currentOption) => {
    setCurrentOption({
      type: CRUD.UPDATE,
      value: currentOption,
    })
  }

  const onRemove = (item) => {
    Modal.confirm({
      title: (
        <div className="font-bold text-lg text-orange-500">
          <span>
            {t(
              'delete receiving package'
            )}
          </span>
        </div>
      ),
      content: (
        <div className="text-sm font-light italic text-color-300">
          {t(
            'are you sure you want to delete this receiving package'
          )}
        </div>
      ),
      okText: t('sure'),
      cancelText: t('no'),
      okButtonProps: {
        type: 'primary',
        danger: true,
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      cancelButtonProps: {
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      onOk: () => {
        const option_id = getId(item)
        deleteOption(
          {},
          bindQueryParam({ option_id })
        )
      },
    })
  }

  const givingStatus =
    getGivingEventStatus(giving)
  const applied_status = Boolean(
    _.get(giving, 'applied_status')
  )
  const disabledGivingButton =
    givingStatus !==
      GivingStatusTypes.ACTIVE.value ||
    applied_status

  return (
    <Wrapper>
      <DelayRender>
        {options.map(
          renderItem({
            edit,
            onEdit,
            onRemove,
            onItemClick,
            giving_type,
            target_unit_name,
            disabled:
              disabledGivingButton,
          })
        )}
      </DelayRender>
      {edit && (
        <div className="flex items-center justify-center py-2">
          <Translate>
            {(t) => (
              <Button
                size="large"
                className="flex-1 rounded-lg no-shadow no-text-shadow ant-btn-secondary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentOption({
                    type: CRUD.CREATE,
                    value: undefined,
                  })
                }}>
                {t('add giving option')}
              </Button>
            )}
          </Translate>
        </div>
      )}
      {currentOption.type && (
        <CreateGivingOptionForm
          currentOption={
            currentOption.value
          }
          onCancel={() => {
            setCurrentOption({})
          }}
        />
      )}
    </Wrapper>
  )
}

const GivingOption = () => {
  const t = useTranslate()

  const history = useHistory()

  const { location } = history

  const [
    currentReceiverAction,
    setCurrentReceiverAction,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const { giving } = useContext(
    GivingContext
  )

  const edit = Boolean(
    _.get(giving, 'edit')
  )

  const canEditGiving =
    edit &&
    currentReceiverAction.type ===
      GivingOptionReceiverTypes.ADMIN

  const isNormalDonor =
    currentReceiverAction.type ===
    GivingOptionReceiverTypes.USER

  const option_name =
    registerGivingParams.options.name

  const currentOption = useSearchParam(
    option_name
  )

  const {
    name: action,
    value: actionValue,
  } = registerGivingParams.action

  const currentAction =
    useSearchParam(action)

  useEffectOnce(() => {
    if (
      _.isEqual(
        currentAction,
        actionValue
      )
    ) {
      setCurrentReceiverAction({
        type: GivingOptionReceiverTypes.USER,
      })
    }
  })

  const defaultOptions = useMemo(() => {
    const source = _.get(
      giving,
      'options',
      []
    )
    const result = _.find(source, {
      id: currentOption,
    })
    if (result) {
      return Array.from(
        _.get(
          result,
          'option_items',
          []
        )
      ).map((item) => ({
        ...item,
      }))
    } else {
      return []
    }
  }, [giving, currentOption])

  const handleRequiredLogin =
    useRequiredLogin()

  const handleSelectOption = (
    option_id
  ) => {
    let search = new URLSearchParams(
      location.search
    )
    search.set(action, actionValue)
    if (option_id) {
      search.set(option_name, option_id)
    }
    history.push({
      // ...location,
      search: [
        '?',
        search.toString(),
      ].join(''),
    })
    setCurrentReceiverAction({
      type: GivingOptionReceiverTypes.USER,
      value: option_id,
    })
  }

  const renderStatusButton =
    useCallback(
      (registrable) => {
        if (!!!registrable) return null
        const givingStatus =
          getGivingEventStatus(giving)
        const isComplete =
          givingStatus === 9
        return renderIf(
          !!_.get(
            giving,
            'applied_status'
          ),
          <div className="p-2 text-center text-secondary font-bold border-2 border-secondary rounded-lg">
            {t('registered')}
          </div>
        )
      },
      [giving]
    )

  if (_.isEmpty(giving)) return null

  const registrable = Boolean(
    _.get(giving, 'registrable')
  )

  return (
    <div className="flex flex-col space-y-3">
      {/*{registrable && (*/}
      {/*  <div className="text-color-300 font-bold text-xl uppercase mt-1">*/}
      {/*    {t('register')}*/}
      {/*  </div>*/}
      {/*)}*/}
      {!_.isEmpty(
        _.get(giving, 'options', [])
      ) && (
        <span className="uppercase text-sm font-semibold text-color-300 tracking-wide">
          {t('register to received')}
        </span>
      )}
      <GivingFAB>
        <div className="flex flex-col gap-2 background">
          {renderStatusButton(
            registrable
          )}
          {edit && (
            <Button
              size="large"
              block={true}
              type={'dashed'}
              onClick={() => {
                setCurrentReceiverAction(
                  {
                    type: GivingOptionReceiverTypes.ADMIN,
                    value: undefined,
                  }
                )
              }}
              className="rounded-lg no-shadow no-text-shadow">
              {`${t('input data')} (${t(
                'for admin'
              )})`}
            </Button>
          )}
        </div>
      </GivingFAB>
      {registrable && (
        <LoginContext.Consumer>
          {(login) => (
            <GivingPackages
              giving={giving}
              onItemClick={(e) =>
                handleRequiredLogin(
                  () => {
                    if (login) {
                      handleSelectOption(
                        e
                      )
                    }
                  }
                )
              }
            />
          )}
        </LoginContext.Consumer>
      )}
      {isNormalDonor && (
        <GivingRegisterForm
          giving_options={
            defaultOptions
          }
          onCancel={() => {
            history.push({
              pathname:
                location.pathname,
            })
            setCurrentReceiverAction({})
          }}
        />
      )}
      {canEditGiving && (
        <GivingRegisterAdminForm
          giving_options={
            defaultOptions
          }
          onCancel={() => {
            setCurrentReceiverAction({})
          }}
        />
      )}
    </div>
  )
}

export default GivingOption
