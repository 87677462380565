import {Avatar, List} from 'antd'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import moment from 'moment'
import React from 'react'
import {IoBonfireOutline, IoCreateOutline} from "react-icons/io5";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import {getType} from "../../../../apis/model/base";
import classNames from "classnames";

export const renderHomeFeed = (
  container,
  index
) => {
  if (_.isEmpty(container)) {
    return null
  }

  const {
    owner,
    creator,
    content: item,
  } = container

  const { created } = item || {}

  const cover_photo = _.get(item, 'cover_photo')

  return (
    <Translate key={index}>
      {(t) => (
        <div className="w-full flex flex-col justify-center gap-2">
          <List.Item className="p-0">
            <List.Item.Meta
              title={
                <Link to={getLinkToDetail(item)} className="font-semibold max-lines-1 text-color-100 leading-tight">
                  {getTitle(item) || _.get(item,'description')}
                </Link>
              }
              description={
                <div className="flex items-baseline justify-between">
                  <div className="flex items-baseline flex-1 gap-1 italic">
                    <span className="text-2xs font-light text-color-400">{t('by')}</span>
                    <Link to={getLinkToDetail(_.get(item, 'creator'))}
                          className="text-xs font-medium text-color-100 flex-1 max-lines-1">
                      {getTitle(_.get(item, 'creator'))}
                    </Link>
                  </div>
                  <span className="text-2xs font-light text-color-400 italic ml-3">
                    {
                      moment(_.get(item, 'created')).fromNow()
                    }
                  </span>
                </div>
              }
              className="flex items-center gap-2"
              avatar={
                <Avatar
                    src={cover_photo}
                    shape={'square'}
                    size={40}
                    icon={<IoCreateOutline className="text-color-200"/>}
                    className="flex items-center justify-center background-200 rounded-lg"
                />
              }
            />
          </List.Item>
        </div>
      )}
    </Translate>
  )
}
