import {API_ROOT_URL} from 'envs/_current/config'
import {baseFieldTypes} from './base/baseFieldTypes'
import {givingSchema} from './schema'
import {beneficiarySchema} from './schema/beneficiary'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:activities,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  title: baseFieldTypes.string,
  description: baseFieldTypes.string,
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
  privacy: baseFieldTypes.privacy,
}

export const beneficiary_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/beneficiaries/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: beneficiarySchema,
  transform: createTransform(
    beneficiarySchema,
    'data'
  ),
}

export const beneficiary_create_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/beneficiaries/create',
  method: 'POST',
  fields,
  fieldTypes,
  schema: beneficiarySchema,
  transform: createTransform(
    beneficiarySchema,
    'data'
  ),
}

export const beneficiary_update_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/beneficiaries/:id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  schema: beneficiarySchema,
  transform: createTransform(
    beneficiarySchema,
    'data'
  ),
}

export const beneficiary_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/beneficiaries/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: beneficiarySchema,
  transform: createTransform(
    beneficiarySchema,
    'data'
  ),
}

export const beneficiary_mergeBeneficiary_api =
  {
    backend: `API: Route::post('beneficiaries/{to}/merge-from/{from}', 'BeneficiaryController@mergeBeneficiary');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:to/merge-from/:from',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createTransform(
      beneficiarySchema,
      'data'
    ),
  }

export const beneficiary_lookupBeneficiaries_api =
  {
    backend: `Route::get('beneficiaries/organization/{organization_id}/lookup', 'BeneficiaryController@lookupBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/organization/:organization_id/lookup',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'exclude_ids',
    ],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_shareBeneficiary_api =
  {
    backend: `Route::post('beneficiaries/{id}/share-to', 'BeneficiaryController@shareBeneficiary'); // target_activity_id`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/share-to',
    method: 'POST',
  }

export const beneficiary_getItemsByOwner_gets_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/beneficiaries/:type/:id/get-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getActivityBeneficiaries_api =
  {
    backend: `Route::get('activities/{activity_id}/get-beneficiaries', 'ActivityController@getBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/activities/:activity_id/get-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getOnMovingBeneficiaries_api =
  {
    backend: `Route::get('activities/{activity_id}/get-on-moving-beneficiaries', 'ActivityController@getOnMovingBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/activities/:activity_id/get-on-moving-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getOnPendingBeneficiaries_api =
  {
    backend: `Route::get('activities/{activity_id}/get-on-pending-beneficiaries', 'ActivityController@getOnPendingBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/activities/:activity_id/get-on-pending-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_createTransfer_api =
  {
    backend: `Route::post('beneficiaries/{id}/create-transfer', 'BeneficiaryController@createTransfer'); // target_activity_id`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/create-transfer',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_cancelTransfer_api =
  {
    backend: `Route::post('beneficiaries/beneficiary-activities/{beneficiary_id}/cancel-transfer', 'BeneficiaryController@cancelTransfer');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/beneficiary-activities/:beneficiary_id/cancel-transfer',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createTransform(
      beneficiarySchema,
      'data'
    ),
  }

export const beneficiary_acceptTransfer_api =
  {
    backend: `Route::post('beneficiaries/beneficiary-activities/{beneficiary_id}/accept-transfer', 'BeneficiaryController@acceptTransfer');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/beneficiary-activities/:beneficiary_id/accept-transfer',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createTransform(
      beneficiarySchema,
      'data'
    ),
  }

export const beneficiary_rejectTransfer_api =
  {
    backend: `Route::post('beneficiaries/beneficiary-activities/{beneficiary_id}/reject-transfer', 'BeneficiaryController@rejectTransfer');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/beneficiary-activities/:beneficiary_id/reject-transfer',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createTransform(
      beneficiarySchema,
      'data'
    ),
  }

export const beneficiary_getReferrals_api =
  {
    backend: `Route::get('beneficiaries/{beneficiary_id}/beneficiary-referrals/gets', 'WishReferralController@getReferrals');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/beneficiary-referrals/gets',
    method: 'GET',
    fields,
    fieldTypes,
    // schema: beneficiarySchema,
    // transform:
    //   createListTransform(beneficiarySchema),
  }

export const beneficiary_deleteReferral_api =
  {
    backend: `Route::post('beneficiaries/{beneficiary_id}/beneficiary-referrals/{beneficiary_referral_id}/delete', 'WishReferralController@delete');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:beneficiary_id/beneficiary-referrals/:beneficiary_referral_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    // schema: beneficiarySchema,
    // transform: createTransform(
    //   beneficiarySchema,
    //   'data'
    // ),
  }

export const beneficiary_searchBeneficiary_api =
  {
    backend: `Route::get('giving-events/{id}/beneficiaries/get-available-beneficiaries', 'GivingEventController@getBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/beneficiaries/get-available-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getGivings_api =
  {
    backend: `Route::get('beneficiaries/{id}/get-givings', 'BeneficiaryController@getGivings');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/get-givings',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'status',
      'sort_by',
    ],
    schema: givingSchema,
    transform: createListTransform(
      givingSchema
    ),
  }

export const beneficiary_getActivityHistory_api =
  {
    backend: `Route::get('beneficiaries/{id}/get-activities', 'BeneficiaryController@getActivityHistory');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/get-activities',
    method: 'GET',
  }

export const beneficiary_getOrganizationBeneficiaries_api =
  {
    backend: `Route::get('beneficiaries/organization/{organization_id}/get-beneficiaries', 'BeneficiaryController@getBeneficiaries');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/organization/:organization_id/get-beneficiaries',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
      'filter_activity_id',
      'filter_giving_event_id',
    ],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_cloneBeneficiary_api =
  {
    backend: `Route::post('beneficiaries/{id}/clone-to', 'BeneficiaryController@cloneBeneficiary'); // target_activity_id.`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/clone-to',
    method: 'POST',
    fields,
    fieldTypes,
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }
