import {
  Avatar,
  List,
  Tooltip,
} from 'antd'
import { search_fetchAvailableOwners_Api } from 'apis'
import { getType } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import { createValue } from 'components/form/utils'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useContext,
  useEffect,
} from 'react'
import { Redirect } from 'react-router-dom'
import LoadingPage from 'views/LoadingPage'
import { paths } from 'views/MainPage/contains'
import {
  getResponseItems,
  Null,
} from 'views/Shared'
import VerifiedSvg from '../../../components/Svgs/VerifiedSvg'
import useTranslate from '../../../modules/local/useTranslate'
import { wishareSettingModalRoutes } from '../configs'
import { CustomKeys } from '../CustomKeys'
import renderClicker from '../functions/renderers/renderClicker'
import { bindQueryParams } from '../functions/routerHelper'
import AntdConfigWrapper from './AntdConfigWrapper'
import { useDebounceSearch } from './DebounceSearch'

const OwnerItemWrapper = ({
  item,
  children,
  ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderOwnerItem = (
  { actions, ...item },
  index
) => {
  const isEmpty = _.isEmpty(item)

  const verified = _.get(
    item,
    'certified'
  )

  return (
    <div
      key={index}
      className="space-y-3 px-3 py-2 rounded-lg shadow-xs hover:shadow-out background">
      <Translate>
        {(t) =>
          isEmpty ? (
            <EmptyHolder />
          ) : (
            <div className="w-full flex items-center gap-2">
              <Avatar
                className="flex flex-center background-200"
                src={getAvatar(item)}>
                <span className="text-color-200">
                  {String(
                    getTitle(item)
                  )
                    .charAt(0)
                    .toUpperCase()}
                </span>
              </Avatar>
              <div className="flex flex-col flex-1">
                <div className="flex items-center gap-1">
                  <span className="text-color-000 font-semibold ">
                    {getTitle(item)}
                  </span>
                  {verified === 1 && (
                    <Tooltip
                      title={t(
                        'verified'
                      )}
                      placement="right"
                      color="#87d068">
                      <VerifiedSvg
                        width="1rem"
                        height="1rem"
                        className="ml-1 mb-1 lg:mb-2 zoom-animation"
                      />
                    </Tooltip>
                  )}
                </div>
                <span className="text-xs text-secondary leading-tight italic">
                  {t(getType(item))}
                </span>
              </div>
              {actions || (
                <Translate>
                  {(t) => (
                    <span className="px-2 py-1 bg-primary text-xs text-white rounded-md leading-tight">
                      {t('choose')}
                    </span>
                  )}
                </Translate>
              )}
            </div>
          )
        }
      </Translate>
    </div>
  )
}

const OwnerModalContent = ({
  Wrapper = 'div',
  ...props
}) => {
  const { setCurrent } = useContext(
    NavigationContext
  )

  const params = _.get(
    props,
    'location.state'
  )

  const [id, type] = [
    _.get(params, 'id'),
    _.get(params, 'type'),
  ]

  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const apiInfo =
    search_fetchAvailableOwners_Api

  const onSelect = (value) => {
    setCurrent(value)
  }

  if (_.every([id, type], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  return (
    <Wrapper className="space-y-3 py-3 mt-8">
      <div className="text-center text-xl font-bold uppercase text-color-000">
        <Translate>
          {(t) => t('edit owner')}
        </Translate>
      </div>
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          ).map(({ owner }) => owner)
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={renderClicker(
                {
                  onClick: onSelect,
                  renderItem:
                    renderOwnerItem,
                  Wrapper:
                    OwnerItemWrapper,
                }
              )}
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

export const EditOwnerModal = (
  props
) => (
  <AntdConfigWrapper>
    <OwnerModalContent {...props} />
  </AntdConfigWrapper>
)

const OwnerPicker = ({
  id,
  type,
  name,
  defaultValue,
  onChange = Null,
}) => {
  const t = useTranslate()
  const {
    current,
    history,
    setCurrent = Null,
    handleGoBack = Null,
  } = useContext(NavigationContext)

  useEffect(() => {
    if (current) {
      handleGoBack()
      onChange(
        createValue(name, current)
      )
      setCurrent(null)
    }
  }, [
    name,
    current,
    onChange,
    setCurrent,
    handleGoBack,
  ])

  const editOwner = () => {
    history.push(
      wishareSettingModalRoutes(
        CustomKeys.edit_owner.name,
        {
          id,
          type,
        }
      )
    )
  }

  return (
    <div
      className="cursor-pointer"
      onClick={editOwner}>
      {renderOwnerItem({
        actions: (
          <span className="px-2 py-1 border border-primary text-xs text-primary rounded-md leading-tight">
            {t('edit')}
          </span>
        ),
        ...defaultValue,
      })}
    </div>
  )
}

export default OwnerPicker
