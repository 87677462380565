import Animal from "../components/Svgs/Categories/Animal";
import Children from "../components/Svgs/Categories/Children";
import Culture from "../components/Svgs/Categories/Culture";
import Education from "../components/Svgs/Categories/Education";
import SanitaryFood from "../components/Svgs/Categories/SanitaryFood";
import Environment from "../components/Svgs/Categories/Environment";
import Handicap from "../components/Svgs/Categories/Handicap";
import Healthy from "../components/Svgs/Categories/Healthy";
import Infrastructure from "../components/Svgs/Categories/Infrastructure";
import Lost from "../components/Svgs/Categories/Lost";
import Old from "../components/Svgs/Categories/Old";
import Sport from "../components/Svgs/Categories/Sport";

const categoryIcons = {
  sport: Sport,
  infrastructure: Infrastructure,
  culture: Culture,
  education: Education,
  "sanitary-food": SanitaryFood,
  children: Children,
  old: Old,
  lost: Lost,
  healthy: Healthy,
  handicap: Handicap,
  environment: Environment,
  animal: Animal,
};
export default categoryIcons;
