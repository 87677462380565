import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import DonationContext from './DonationContext'

const DonationLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose(
  notFoundOnError(
    DonationContext.Consumer
  )
)(DonationLayout)
