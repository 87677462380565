import { Button } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { PicturesWall } from 'views/CreateImagePost/CreateImagePost'
import { Null } from 'views/Shared'

const AttachedImages = ({
  Wrapper = 'div',
  onChange = Null,
}) => {
  const t = useTranslate()

  return (
    <Wrapper className="flex flex-wrap items-center w-full">
      <PicturesWall
        values={[]}
        onChange={onChange}>
        <div
          style={{
            padding: '0.5em',
            width: '100px',
            height: '100px',
            textAlign: 'center',
          }}>
          <Button className="background-200 w-full h-full flex justify-center items-center border-dashed">
            {t('upload')}
          </Button>
        </div>
      </PicturesWall>
    </Wrapper>
  )
}

export default AttachedImages
