import {Tabs} from 'antd'
import {donation_events_getDonationsByDonorOwner_api, donation_events_getItemsByOwner_gets_api,} from 'apis'
import {getId, getType,} from 'apis/model/base'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useState,} from 'react'
import {useLocation, useSearchParam,} from 'react-use'
import {compose, fromRenderProps, withProps,} from 'recompose'
import {Null, renderOwnChild,} from 'views/Shared'
import DonationEventItem from 'views/Wishare/Donation/components/DonationEventItem'
import {MyDonationRecordItem} from 'views/Wishare/Donation/components/DonationRecordItem'
import DonationEntity from 'views/Wishare/Donation/DonationEntity'
import {DonationItemsSortBy, DonationsSortBy,} from 'views/Wishare/factory/createEntitySortBy'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const FilterTypes = Object.freeze({
  DONATIONS: 'donations',
  DONATION_EVENTS: 'donation-events',
})

const ItemStatistic = ({
                         label,
                         value,
                         valueComponent,
                       }) => (
  <Translate>
    {(t) => (
      <div
        className="px-2 py-4 flex flex-col flex-center gap-1 border border-color-50 rounded-lg shadow-items cursor-pointer">
        {
          !!value ? (
            <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                {Number(value).toLocaleString()}
              </span>
          ) : valueComponent
        }
        <span className="leading-tight text-sm text-color-400 tracking-wide uppercase hover:text-primary">
          {t(label)}
        </span>
      </div>
    )}
  </Translate>
)

const DonorOwnerDonationEvents = ({
                                    owner_id,
                                    owner_type,
                                    Header = Null,
                                    Wrapper = 'div',
                                    filter_actions = {
                                      sortBy: 'sort-by',
                                      keyword: 'keyword',
                                    },
                                  }) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
               }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        donation_events_getItemsByOwner_gets_api,
        query: bindQueryParams([
          {
            id: owner_id,
          },
          {type: owner_type},
        ]),
        RenderEntity: DonationEntity,
        RenderItem: DonationEventItem,
        PaginationMeta: Null,
      },
      withKey: ({sortBy, keyword}) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      renderFilter: Null,
      query_params: filter_actions,
      sortHeader: null,
      SortByWidget: DonationsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header/>
      {pager_widget}
    </Wrapper>
  )
}

export const DonorOwnerDonations = ({
                                      owner_id,
                                      owner_type,
                                      Header = Null,
                                      Wrapper = 'div',
                                      filter_actions = {
                                        sortBy: 'sort-by',
                                        keyword: 'keyword',
                                      },
                                    }) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
               }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        donation_events_getDonationsByDonorOwner_api,
        query: bindQueryParams([
          {
            id: owner_id,
          },
          {type: owner_type},
        ]),
        RenderItem: withProps({
          showEvent: true,
        })(MyDonationRecordItem),
        RenderEntity: DonationEntity,
        PaginationMeta: Null,
      },
      withKey: ({sortBy, keyword}) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      renderFilter: Null,
      query_params: filter_actions,
      sortHeader: null,
      SortByWidget: DonationItemsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header/>
      {pager_widget}
    </Wrapper>
  )
}

const OrganizationDonationList = ({
                                    owner_id,
                                    owner_type,
                                    Wrapper = renderOwnChild,
                                  }) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const [current, setCurrent] =
    useState(
      FilterTypes.DONATION_EVENTS
    )

  const {organization: item} =
    useContext(
      OrganizationContainerContext
    )

  const tabs = useMemo(
    () =>
      [
        {
          type: FilterTypes.DONATION_EVENTS,
          tab: (
            <span>
              {t('donation campaign')}
            </span>
          ),
          component:
          DonorOwnerDonationEvents,
        },
        {
          type: FilterTypes.DONATIONS,
          tab: (
            <span>
              {t(
                'donation of organization'
              )}
            </span>
          ),
          component:
          DonorOwnerDonations,
        },
      ].map(
        ({
           type,
           tab,
           component: Component,
         }) => (
          <Tabs.TabPane
            key={type}
            tab={tab}>
            <Component
              {...{
                owner_id,
                owner_type,
              }}
            />
          </Tabs.TabPane>
        )
      ),
    [current, owner_id, owner_type]
  )

  return (
    <Wrapper>

      {
        [
          {
            id: 'insights',
            title: 'insights',
            className: "border-2 border-primary",
            containerClassName: "grid grid-cols-2 gap-3",
            linkTo: getLinkToDetail(item) + '/donations',
            children: [
              {
                label: "donation campaign", valueComponent: (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(item, 'total_active_donation_events', 0)).toLocaleString()
                    }</span>
                    {
                      ' / '
                    }
                    <span className="text-color-500">{
                      Number(_.get(item, 'total_donation_events', 0)).toLocaleString()
                    }</span>
                  </div>
                ),

              },
              {
                label: "value to contribute",
                valueComponent: _.get(item, 'input_statistics.0.donation_target', 0) - _.get(item, 'input_statistics.0.donation_value', 0) > 0 ? (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(item, 'input_statistics.0.donation_target', 0) - _.get(item, 'input_statistics.0.donation_value', 0)).toLocaleString()
                    }</span>
                    {
                      ' '
                    }
                    <span className="text-color-500">{
                      _.get(item, 'input_statistics.0.target_unit_name', 0)
                    }</span>
                  </div>
                ) : null,
              }
            ]
          }
        ].filter(e => !!!e.hidden).map((e, i) => {
            return !!isSm ? (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <Link
                    to={e.linkTo}
                    className={classNames("px-6 py-4 flex flex-col gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline", e.className)}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <div key={ii} className="flex items-baseline gap-3 leading-tight">
                          {
                            !!ee.value ? (
                              <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                                {Number(ee.value).toLocaleString()}
                              </span>
                            ) : (
                              ee.valueComponent
                            )
                          }
                          <span className="text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(ee.label)}
          </span>
                        </div>
                      ))
                    }
                  </Link>
                </HeaderItemInfo>
              )
              : (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <div className={e.containerClassName}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <ItemStatistic key={ii}
                                       className={e.className}
                                       label={ee.label}
                                       value={ee.value}
                                       valueComponent={ee.valueComponent}
                                       to={e.linkTo}
                        />
                      ))
                    }
                  </div>
                </HeaderItemInfo>
              )
          }
        )
      }

      <Tabs
        defaultActiveKey={current}
        onChange={(activeKey) => {
          setCurrent(activeKey)
        }}>
        {tabs}
      </Tabs>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  )
)(OrganizationDonationList)
