import { categorySchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const CategoryEntity = ({
  item,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={categorySchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const CategoryEntities = ({
  items,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[categorySchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export default CategoryEntity
