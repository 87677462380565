import { Avatar, Divider } from 'antd'
import {
  member_getMembers_Api,
  permission_allow_Api,
  permission_deny_Api,
  permission_revoke_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import classnames from 'classnames'
import { LoginContext } from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useState,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import { PermissionRatio } from 'views/ChannelSetting/PermissionRatio'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'

function OrganizationSettingPermission({
  item,
}) {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const default_permissions = _.get(
    item,
    'default_permissions'
  )
  const [select, setSelect] = useState()

  const containerType = item._type

  const containerId = item.id

  const [data, dispathAsyncAction] =
    useDispatchAsyncActionWithNotify({
      onError: notifyOnError(t),
    })

  const handleChange =
    ({
      value: current,
      permission_id,
    }) =>
    (value) => {
      if (current === value) {
        return
      }
      const query = {
        ':prop': containerType,
        ':id': containerId,
        ':m_id': select.id,
        ':permission_name':
          permission_id,
      }
      switch (value) {
        case '1':
          dispathAsyncAction(
            createAsyncAction({
              apiInfo:
                permission_deny_Api,
              query,
            })
          )
          break
        case '2':
          dispathAsyncAction(
            createAsyncAction({
              apiInfo:
                permission_revoke_Api,
              query,
            })
          )
          break
        case '3':
          dispathAsyncAction(
            createAsyncAction({
              apiInfo:
                permission_allow_Api,
              query,
            })
          )
          break
        default:
          break
      }
    }

  return (
    <div className="flex flex-wrap gap-3">
      <div className="w-1/3">
        <div className="text-sm uppercase font-bold text-color-300 mb-2">
          {t('members')}
        </div>
        <div className="space-y-3">
          <div
            onClick={() =>
              setSelect(null)
            }
            className={classnames(
              'px-2 py-1  flex items-center gap-2 rounded-md font-semibold',
              !select
                ? 'bg-primary-50 text-primary'
                : 'hover:background-100 cursor-pointer'
            )}>
            <Avatar
              className={classnames(
                'flex flex-center',
                !select
                  ? 'bg-primary'
                  : 'background-100'
              )}
              icon={
                <IoPerson
                  size={13}
                  className={
                    !select
                      ? 'text-white'
                      : 'text-color-300'
                  }
                />
              }
              size={25}
            />
            <div>{t('default')}</div>
          </div>
          <LazyPagination
            apiInfo={
              member_getMembers_Api
            }
            renderItem={(member) => {
              const {
                id,
                user,
                thumbnail,
              } = member || {}
              return (
                <div
                  onClick={() =>
                    setSelect(member)
                  }
                  className={classnames(
                    'px-2 py-1 flex items-center gap-2 rounded-md font-semibold',
                    select &&
                      select.id === id
                      ? 'bg-primary-50 text-primary'
                      : 'hover:background-100 cursor-pointer'
                  )}>
                  <Avatar
                    className={classnames(
                      'flex flex-center',
                      select &&
                        select.id === id
                        ? 'bg-primary'
                        : 'background-100'
                    )}
                    icon={
                      <IoPerson
                        size={13}
                        className={
                          select &&
                          select.id ===
                            id
                            ? 'text-white'
                            : 'text-color-300'
                        }
                      />
                    }
                    src={
                      thumbnail ||
                      getAvatar(user)
                    }
                    size={25}
                  />
                  <div className="flex-1 text-sm">
                    {getTitle(user)}
                  </div>
                </div>
              )
            }}
            query={{
              ':prop': containerType,
              ':id': containerId,
            }}
          />
        </div>
      </div>
      <div className="flex-1">
        <div className="verticalList">
          <div className="text-sm uppercase font-bold text-color-300 mb-2">
            {t('general permission')}
          </div>
          {select &&
            select.direct_permissions &&
            select.direct_permissions.map(
              ({
                id: permission_id,
                disabled,
                idname,
                title,
                description,
                has_direct_permission,
                permission_value,
              }) => {
                const value =
                  !has_direct_permission
                    ? '2'
                    : permission_value
                    ? '3'
                    : '1'

                const isDisabled =
                  Boolean(
                    getId(
                      _.get(
                        select,
                        'user'
                      )
                    ) === getId(login)
                  ) || disabled

                return (
                  <React.Fragment
                    key={permission_id}>
                    <div className="verticalList__small">
                      <div className="flex items-center">
                        <div className="flex-1 font-bold">
                          {title}
                        </div>
                        <PermissionRatio
                          value={value}
                          disabled={
                            isDisabled
                          }
                          onChange={
                            isDisabled
                              ? undefined
                              : handleChange(
                                  {
                                    value,
                                    permission_id,
                                  }
                                )
                          }
                        />
                      </div>
                      <div className="whitespace-pre-wrap px-2 text-sm font-light text-color-300">
                        {description}
                      </div>
                    </div>
                    <div className="px-2">
                      <Divider />
                    </div>
                  </React.Fragment>
                )
              }
            )}
          {!select &&
            default_permissions &&
            default_permissions.map(
              ({
                id: permission_id,
                disabled,
                idname,
                title,
                description,
                has_direct_permission,
                permission_value,
              }) => {
                const value =
                  !has_direct_permission
                    ? '2'
                    : permission_value
                    ? '3'
                    : '1'

                return (
                  <React.Fragment
                    key={permission_id}>
                    <div className="verticalList__small">
                      <div className="flex items-center">
                        <div className="flex-1 font-bold">
                          {title}
                        </div>
                        <PermissionRatio
                          value={value}
                          disabled={
                            disabled
                          }
                          onChange={(
                            _value
                          ) => {
                            if (
                              _value ===
                              value
                            )
                              return
                            switch (
                              _value
                            ) {
                              case '1':
                                dispathAsyncAction(
                                  createAsyncAction(
                                    {
                                      apiInfo:
                                        permission_deny_Api,
                                      query:
                                        {
                                          ':prop':
                                            containerType,
                                          ':id':
                                            containerId,
                                          ':m_id':
                                            select.id,
                                          ':permission_name':
                                            permission_id,
                                        },
                                    }
                                  )
                                )
                                break
                              case '2':
                                dispathAsyncAction(
                                  createAsyncAction(
                                    {
                                      apiInfo:
                                        permission_revoke_Api,
                                      query:
                                        {
                                          ':prop':
                                            containerType,
                                          ':id':
                                            containerId,
                                          ':m_id':
                                            select.id,
                                          ':permission_name':
                                            permission_id,
                                        },
                                    }
                                  )
                                )
                                break
                              case '3':
                                dispathAsyncAction(
                                  createAsyncAction(
                                    {
                                      apiInfo:
                                        permission_allow_Api,
                                      query:
                                        {
                                          ':prop':
                                            containerType,
                                          ':id':
                                            containerId,
                                          ':m_id':
                                            select.id,
                                          ':permission_name':
                                            permission_id,
                                        },
                                    }
                                  )
                                )
                                break
                              default:
                                break
                            }
                          }}
                        />
                      </div>
                      <div className="whitespace-pre-wrap px-2 text-sm font-light text-color-300">
                        {description}
                      </div>
                    </div>
                    <div className="px-2">
                      <Divider />
                    </div>
                  </React.Fragment>
                )
              }
            )}
        </div>
      </div>
    </div>
  )
}

export default OrganizationSettingPermission
