import BeneficiaryHistory from './holders/BeneficiaryHistory'
import BeneficiaryInformation from './holders/BeneficiaryInformation'
import BeneficiaryNews from './holders/BeneficiaryNews'

export const beneficiaryRouteEntities = [
  {
    path: 'news',
    component: BeneficiaryNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'history',
    component: BeneficiaryHistory,
    breadcrumbName: (t) => t('history'),
  },
  {
    path: 'information',
    component: BeneficiaryInformation,
    breadcrumbName: (t) =>
      t('information'),
  },
]
