import {Avatar} from 'antd'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import {IoPerson} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {compose, fromRenderProps,} from 'recompose'
import {formatDate, getFullAddress, renderIf,} from 'views/Shared'
import BeneficiaryContext from '../BeneficiaryContext'
import {OverlayItemBanner} from "../../Templates/ItemTemplate";
import {WishareEntities} from "../../enums";

const ItemInformation = ({
  label,
  value,
}) => {
  const t = useTranslate()
  return renderIf(
    value,
    <div className="flex items-baseline space-x-1">
      <span className="text-xs text-color-500 font-light italic">
        {`${t(label)} : `}
      </span>
      <span className="text-sm text-color-000 font-semibold flex-1">
        {value}
      </span>
    </div>
  )
}

export const BeneficiaryInformation = ({
  beneficiary,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const {
    email,
    notes,
    location,
    referrals,
    extra_fields,
    phone_number,
    date_of_birth,
  } = beneficiary || {}

  const address =
    getFullAddress(location)

  const extra_data = useMemo(
    () =>
      Array.from(
        extra_fields || []
      ).filter(
        (e) =>
          _.has(e, 'value') ||
          _.has(e, 'dvalue') ||
          _.has(e, 'nvalue')
      ),
    [extra_fields]
  )

  if (_.isEmpty(beneficiary))
    return null

  return (
    <Wrapper className="space-y-6">
        <div className="flex flex-col">
            <div className="uppercase text-sm font-medium text-color-300 tracking-wide mb-2">
                {t('receiver information')}
            </div>
            <div className="flex flex-col space-y-3 p-3 border border-color-50 rounded-lg">
                <div className="relative background-50 rounded-lg">
                    <OverlayItemBanner type={WishareEntities.BENEFICIARY}/>
                    <div className="h-7"/>
                    <div className="flex items-center gap-3 p-3">
                        <Avatar
                            size={40}
                            src={_.get(beneficiary, 'avatar')}
                            className="flex flex-center uppercase text-color-300 background-200">
                            {_.first(getTitle(beneficiary))}
                        </Avatar>
                        <div className="flex flex-col">
                        <span className="font-semibold text-color-000">
                            {getTitle(beneficiary)}
                        </span>
                            <span className="italic font-light text-xs text-secondary">
                            {t(_.get(beneficiary, 'owner._type'))}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="space-y-2">
                    <ItemInformation label="fullname" value={getTitle(beneficiary)}/>
                    {phone_number && (
                        <ItemInformation label="phone" value={phone_number}/>
                    )}
                    {email && (
                        <ItemInformation label="email" value={email}/>
                    )}
                    {date_of_birth && (
                        <ItemInformation
                            label="date of birth"
                            value={date_of_birth ? formatDate(date_of_birth) : undefined}
                        />
                    )}
                    {!_.isEmpty(location) && (
                        <ItemInformation
                            label="address"
                            value={address}
                        />
                    )}
                    {notes && (
                        <ItemInformation label="notes" value={notes}/>
                    )}
                </div>
                {!_.isEmpty(extra_data) && (
                    <div className="flex flex-col">
          <span className="font-medium text-color-300 uppercase mb-2">
            {t('extra information')}
          </span>
                        <div className="flex flex-col space-y-2 p-2 border border-color-50 rounded-lg">
                          {extra_data.filter(e => !!e.value || !!e.nvalue || !!e.dvalue).map(
                              (e, index) => {
                                let value = e.value;
                                if (!!e.dvalue) value = formatDate(e.dvalue)
                                if (!!e.nvalue) value = Number(e.nvalue).toLocaleString()
                                if (!!e.value) value = e.value
                                return (
                                    <ItemInformation
                                      key={index}
                                      label={e.name}
                                      value={value}
                                    />
                                )
                              }
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
        {!_.isEmpty(referrals) && (
            <div className="flex flex-col">
                <span className="font-medium text-color-300 uppercase mb-2">
                    {t('referral information')}
                </span>
                <div className="space-y-2">
                    {Array.from(referrals).map(
                        (e, index) => {
                            const referral = _.get(
                                e,
                                'referral'
                            )
                            const {
                                email,
                                phone_number,
                            } = referral || {}
                            return (
                                <div
                                    key={index}
                                    className="flex flex-col p-2 border border-color-50 background rounded-lg space-y-2">
                                    <div className="flex items-center gap-2">
                                        <Avatar
                                            size={30}
                                            src={getAvatar(
                                                referral
                                            )}
                                            icon={
                                                <IoPerson />
                                            }
                                        />
                                        <Link
                                            to={getLinkToDetail(
                                                referral
                                            )}
                                            className="flex-1 font-semibold text-color-000 max-lines-1 no-underline">
                                            {getTitle(
                                                referral
                                            )}
                                        </Link>
                                    </div>
                                    <ItemInformation
                                        label="phone"
                                        value={
                                            phone_number
                                        }
                                    />
                                    <ItemInformation
                                        label="email"
                                        value={email}
                                    />
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        )}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    BeneficiaryContext.Consumer,
    ({ beneficiary }) => ({
      beneficiary,
    })
  )
)(BeneficiaryInformation)
