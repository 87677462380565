import {
  Button,
  Input,
  Modal,
  Spin,
} from 'antd'
import {
  medal_add_Api,
  medal_update_Api,
} from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import FieldsFactory from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { IoMedalOutline } from 'react-icons/io5'
import LoadingPage from 'views/LoadingPage'
import {
  Null,
  shallowDiff,
} from 'views/Shared'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { TagCreator } from 'views/Wishare/custom/TagsPicker'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import { getDefaultFieldValue } from 'views/Wishare/wishareFieldTypes'
import AvatarUploader from '../components/AvatarUploader'

export const MedalModalTypes =
  Object.freeze({
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'share',
  })

const newMedal = {
  tags: undefined,
  avatar: undefined,
  title: undefined,
  status: undefined,
  lvl_number: undefined,
  description: undefined,
  active: getDefaultFieldValue(
    baseFieldTypes.active
  ),
}

const formSchema = [
  {
    children: [
      {
        name: 'avatar',
        component:
          createControlledFormField({
            withValues: ({
              avatar_preview,
            }) => ({
              value: avatar_preview,
              icon: (
                <IoMedalOutline
                  style={{
                    color: '#bdbdbd',
                  }}
                />
              ),
            }),
            Component: AvatarUploader,
          }),
      },
    ],
  },
  {
    title: 'title',
    children: {
      name: 'title',
      component:
        createControlledFormField({
          placeholder: 'title',
        }),
    },
  },
  {
    title: 'description',
    children: {
      name: 'description',
      component:
        createControlledFormField({
          rows: 3,
          placeholder: 'description',
          Component: Input.TextArea,
        }),
    },
  },
  {
    title: 'level',
    children: {
      name: 'lvl_number',
      component:
        createControlledFormField({
          type: 'number',
          placeholder:
            'level placeholder',
        }),
    },
  },
  {
    title: 'tags',
    children: {
      name: 'tags',
      component: ({
        name,
        value,
        onChange = Null,
      }) => (
        <TagCreator
          defaultValue={value}
          onChange={(value) => {
            const tags = _.isArray(
              value
            )
              ? Array.from(value || [])
                  .map(
                    ({ value }) => value
                  )
                  .join(',')
              : value
            onChange(
              createValue(name, tags)
            )
          }}
        />
      ),
    },
  },
  {
    title: 'active',
    children: [
      {
        name: 'active',
        hideError: true,
        component: withAdvancedOptions(
          baseFieldTypes.active
        )(RadioOptions),
      },
    ],
  },
]

const CreateMedalModal = ({
  onCancel,
  owner_id,
  owner_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'create',
  initialValues = newMedal,
}) => {
  const t = useTranslate()

  const {
    isLoading = false,
    handleAsyncAction: createMedal,
  } = useAsyncAction({
    apiInfo: medal_add_Api,
    query: bindQueryParams([
      {
        id: owner_id,
      },
      { prop: owner_type },
    ]),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const { title } = values || {}
    let errors = {}

    if (_.isEmpty(title)) {
      errors.title = t('required field')
    }
    return errors
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(createMedal)({
          ...changed,
          active: _.get(
            values,
            'active'
          ),
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('create medal')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditMedalModal = ({
  onCancel,
  owner_id,
  owner_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'update',
  initialValues = newMedal,
}) => {
  const t = useTranslate()

  const { tags, avatar, ...rest } =
    initialValues || {}

  const medal_id = getId(initialValues)

  const {
    isLoading = false,
    handleAsyncAction: updateMedal,
  } = useAsyncAction({
    apiInfo: medal_update_Api,
    query: bindQueryParam({
      id: medal_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const { title } = values || {}
    let errors = {}

    if (_.isEmpty(title)) {
      errors.title = t('required field')
    }
    return errors
  }

  const default_values = {
    ...rest,
    avatar_preview: avatar,
    tags: Array.from(tags || []).map(
      ({
        idname: label,
        tags: value,
      }) => ({ label, value })
    ),
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={default_values}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          default_values
        )
        onSubmit(updateMedal)(changed)
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit medal')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const MedalActionModal = ({
  type,
  params,
  onCancel,
  onRefresh,
  onCreated,
  onUpdated,
  initialValues,
  isLoading = false,
  ...props
}) => {
  if (!!!type) return null

  let Component = Null

  let ModalWrapper = Modal

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case MedalModalTypes.CREATE:
      Component = CreateMedalModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case MedalModalTypes.EDIT:
      Component = EditMedalModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    default:
      break
  }

  const { owner_id, owner_type } =
    params || {}

  const onSubmit =
    (handleSubmit = Null) =>
    ({ avatar_preview, ...values }) => {
      handleSubmit(
        _.omitBy(
          {
            ..._.pick(
              values,
              Object.keys(newMedal)
            ),
          },
          _.isUndefined
        )
      )
    }

  return (
    <ModalWrapper
      {...{
        onCancel,
        className: 'custom-modal',
        right: true,
        footer: null,
        closable: false,
        visible: !!type,
        destroyOnClose: true,
      }}>
      {!!isLoading ? (
        <LoadingPage />
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            initialValues,
            owner_id,
            owner_type,
          }}
          {...asyncParams}
        />
      )}
    </ModalWrapper>
  )
}

export default MedalActionModal
