import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import React from 'react'
import {
  IoHelp,
  IoLocationOutline, IoPersonOutline,
  IoSettingsOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
} from 'recompose'
import Locations from 'views/Organization/Settings/Locations/Locations.lazy'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { WishareEntities } from 'views/Wishare/enums'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import BeneficiaryContext from '../BeneficiaryContext'
import BeneficiarySettingOverview from './BeneficiarySettingOverview'
import BeneficiarySettingReferrals from './BeneficiarySettingReferrals'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline />,
  },
  // {
  //   key: 'category',
  //   label: 'categories',
  //   icon: <IoGridOutline />,
  //   children: [
  //     {
  //       label: 'categories',
  //       key: 'categories',
  //     },
  //     {
  //       label: 'impact categories',
  //       key: 'impact-categories',
  //     },
  //   ],
  // },
  {
    key: 'beneficiary-referrals',
    label: 'beneficiary referrals',
    icon: <IoPersonOutline />,
  },
  {
    key: 'location',
    label: 'location',
    icon: <IoLocationOutline />,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline />,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: BeneficiarySettingOverview,
  },
  // {
  //   path: 'categories',
  //   label: 'categories',
  //   component: BeneficiarySettingCategories,
  // },
  // {
  //   path: 'impact-categories',
  //   label: 'impact categories',
  //   component: BeneficiarySettingImpCate,
  // },
  {
    path: 'beneficiary-referrals',
    label: 'beneficiary referrals',
    component: BeneficiarySettingReferrals,
  },
  {
    path: 'location',
    label: 'location',
    component: () => (
      <BeneficiaryContext.Consumer>
        {({ beneficiary }) => (
          <Locations item={beneficiary} />
        )}
      </BeneficiaryContext.Consumer>
    ),
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <BeneficiaryContext.Consumer>
        {({ beneficiary }) =>
          createDeleteEntity(beneficiary)
        }
      </BeneficiaryContext.Consumer>
    ),
  },
].map(
  ({
    path,
    component: Component,
    ...rest
  }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const beneficiarySettings = {
  actions: [
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline />,
    },
  ],
  menuItems,
  routeEntities,
}

const WishSettings = ({
  id,
  ...props
}) =>
  createSettingModule(
    [WishareEntities.BENEFICIARY],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(BeneficiaryContext.Consumer),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({ match }) => ({
    id: _.get(match, 'params.id'),
  }))
)(WishSettings)
