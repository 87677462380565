import { Avatar } from 'antd'
import { getFullAvatarUrl } from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import { IoHome } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { LayoutContext } from '../../../../components/layouts/Default/LayoutContext'
import { WishareRibbonSvg } from '../../../../components/Svgs/WishareRibbonSvg'

const ActionButtons = ({ item }) => (
  <div className="inline-flex flex-row space-x-1">
    <Link
      to={getLinkToDetail(item)}
      className="rounded-full w-10 h-10 flex items-center justify-center background-100 hover:bg-primary hover:text-white">
      <IoHome className="Icon" />
    </Link>
  </div>
)

const ActivitySettingHeader = ({
  activity,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const item = activity || {}

  if (_.isEmpty(item)) return null

  const avatar = getFullAvatarUrl(item)

  return (
    <div className="flex py-3 w-full container mx-auto flex-col">
      <div className="flex items-center gap-3">
        <Avatar
            size={isSm ? 50 : 70}
            src={avatar}
            className="flex flex-center border border-color-50 background-100"
            icon={
              <WishareRibbonSvg
                  width={isSm ? '2rem' : '3rem'}
                  height={isSm ? '2rem' : '3rem'}
              />
            }
        />
        <Link
            className="title-font text-base md:text-xl font-semibold hover:text-primary no-underline flex-1"
            title={item.title}
            to={getLinkToDetail(item)}>
          {getTitle(item)}
        </Link>
        <ActionButtons {...{ item }} />
      </div>
    </div>
  )
}

export default ActivitySettingHeader
