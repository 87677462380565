import ContentLayout from 'components/layouts/Default/ContentLayout'
import React from 'react'
import { Null } from 'views/Shared'

const DefaultWrapper = ({
  children,
}) => (
  <div className="flex w-full container mx-auto gap-3 mt-2">
    {children}
  </div>
)

const DefaultTemplate = React.memo(
  ({
    children,
    Wrapper = DefaultWrapper,
    ...props
  }) => (
    <Wrapper>
      <ContentLayout
        style={{
          padding: 0,
        }}
        renderHeader={Null}
        renderRight={Null}
        {...props}>
        {children}
      </ContentLayout>
    </Wrapper>
  )
)

export default DefaultTemplate
