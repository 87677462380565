import { donation_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import {
  useCallback,
  useContext,
} from 'react'
import {
  compose,
  mapProps,
} from 'recompose'
import { MultiSelectDataSource } from 'views/Wishare/custom/SelectDataSource'
import createCustomQuestions from 'views/Wishare/factory/createCustomQuestions'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { answer_types } from '../../factory/createCustomQuestions'
import DonationContext from '../DonationContext'

const formFields = [
  {
    name: 'information_fields',
  },
  {
    name: 'required_information_fields',
  },
  {
    name: 'collection_questions',
  },
]

const formSchema = [
  {
    title: 'required fields',
    children: [
      {
        name: 'required_information_fields',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              form,
              onChange,
              translate,
            }) => {
              const options =
                _.get(
                  form,
                  'values.information_fields'
                ) || []
              return {
                name,
                value,
                options,
                onChange,
                placeholder: translate(
                  'required fields'
                ),
              }
            }
          )
        )(MultiSelectDataSource),
      },
    ],
  },
  {
    title: 'questions',
    children: [
      {
        name: 'collection_questions',
        component:
          createCustomQuestions(),
      },
    ],
  },
]

const DonationSettingQuestions = () => {
  const t = useTranslate()

  const { donation } = useContext(
    DonationContext
  )

  const validationSchema = undefined

  const donation_id = getId(donation)

  const type = getType(donation)

  const initialized = useCallback(
    ({
      information_fields,
      ...values
    }) => ({
      type,
      id: donation_id,
      information_fields: Object.values(
        information_fields || {}
      ).map(({ name, label }) => ({
        label: _.capitalize(t(label)),
        value: name,
      })),
      ...values,
    }),
    [type, donation_id]
  )

  const onPreSubmit = ({
    id,
    type,
    information_fields,
    collection_questions,
    ...values
  }) => ({
    ...values,
    ...(collection_questions
      ? {
          collection_questions:
            JSON.stringify(
              Array.from(
                collection_questions
              ).map(
                ({
                  answer_options,
                  answer_type,
                  ...rest
                }) => ({
                  ...rest,
                  answer_type,
                  answer_options:
                    answer_type ===
                    answer_types.boolean
                      .value
                      ? ['yes', 'no']
                      : answer_options,
                })
              )
            ),
        }
      : {}),
  })

  if (_.isEmpty(donation)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: donation,
    validationSchema,
    query: bindQueryParam({
      donation_id,
    }),
    initialized,
    onPreSubmit,
    useDefaultActionBar: false,
    apiInfo: donation_update_api,
  })
}

export default DonationSettingQuestions
