import getLinkToDetail from 'helpers/getLinkToDetail'
import React from 'react'
import CustomLink from '../custom/CustomLink'

export const renderHashTag = (
  { tags, ...item },
  index
) => (
  <CustomLink
    key={index}
    withSubDomain={false}
    to={getLinkToDetail(item)}
    style={{padding: '3px 12px'}}
    className="flex flex-center text-sm italic no-underline background-100 rounded-full hover:bg-primary text-color-300 hover:text-white">
      {`#${tags}`}
  </CustomLink>
)
