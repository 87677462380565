import React from 'react';

export const ArticleSvg = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 512 512"
		style={{
			enableBackground:
				'new 0 0 512 512'
		}}
		xmlSpace="preserve">
		<polygon
			style={{ fill: '#ECE7EA' }}
			points="512,73.956 512,494.933 0,494.933 0,73.956 256,51.2 "
		/>
		<polygon
			style={{ fill: '#F14742' }}
			points="512,17.067 512,73.956 455.111,73.956 420.978,45.511 455.111,17.067 "
		/>
		<rect
			y="17.067"
			style={{ fill: '#1B4145' }}
			width="455.111"
			height="56.889"
		/>
		<path
			style={{ fill: '#D6CED1' }}
			d="M443.733,258.844H68.267c-4.714,0-8.533-3.821-8.533-8.533c0-4.713,3.82-8.533,8.533-8.533h375.467  c4.714,0,8.533,3.821,8.533,8.533C452.267,255.024,448.447,258.844,443.733,258.844z"
		/>
		<g>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M233.244,304.356H68.267c-4.714,0-8.533-3.821-8.533-8.533s3.82-8.533,8.533-8.533h164.978   c4.714,0,8.533,3.821,8.533,8.533S237.958,304.356,233.244,304.356z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M233.244,349.867H68.267c-4.714,0-8.533-3.821-8.533-8.533s3.82-8.533,8.533-8.533h164.978   c4.714,0,8.533,3.821,8.533,8.533S237.958,349.867,233.244,349.867z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M233.244,395.378H68.267c-4.714,0-8.533-3.821-8.533-8.533s3.82-8.533,8.533-8.533h164.978   c4.714,0,8.533,3.821,8.533,8.533S237.958,395.378,233.244,395.378z"
			/>
		</g>
		<rect
			x="278.756"
			y="295.822"
			style={{ fill: '#F14742' }}
			width="164.978"
			height="136.533"
		/>
		<g>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M233.244,440.889H68.267c-4.714,0-8.533-3.821-8.533-8.533s3.82-8.533,8.533-8.533h164.978   c4.714,0,8.533,3.821,8.533,8.533S237.958,440.889,233.244,440.889z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M395.04,213.333c-15.366,0-27.344-6.725-31.263-17.55c-1.603-4.432,0.688-9.324,5.121-10.927   c4.426-1.604,9.323,0.687,10.927,5.12c1.361,3.764,7.475,6.292,15.213,6.292c5.786,0,15.576-1.799,15.576-8.54   c0-4.619-2.111-6.216-16.959-8.645c-6.599-1.079-13.421-2.195-19.169-5.219c-7.911-4.163-12.092-11.167-12.092-20.257   c0-15.077,13.423-25.607,32.643-25.607c15.359,0,27.336,6.721,31.259,17.54c1.607,4.431-0.684,9.324-5.114,10.931   c-4.434,1.611-9.324-0.685-10.929-5.114c-1.364-3.761-7.477-6.29-15.214-6.29c-5.786,0-15.576,1.799-15.576,8.54   c0,4.611,2.109,6.205,16.949,8.632c6.6,1.08,13.426,2.196,19.175,5.221c7.913,4.164,12.096,11.172,12.096,20.266   C427.684,202.803,414.26,213.333,395.04,213.333z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M319.496,213.333c-3.187,0-6.143-1.786-7.609-4.676l-9.686-19.114l-9.686,19.114   c-1.608,3.174-5.029,5.022-8.553,4.624c-3.536-0.391-6.458-2.937-7.332-6.385l-17.298-68.267c-1.158-4.568,1.608-9.21,6.177-10.367   c4.567-1.155,9.21,1.607,10.367,6.176l11.705,46.197l7.005-13.825c1.454-2.868,4.396-4.676,7.612-4.676   c3.215,0,6.158,1.808,7.612,4.676l7.005,13.825l11.705-46.197c1.159-4.569,5.802-7.333,10.367-6.176   c4.569,1.157,7.334,5.799,6.177,10.367l-17.298,68.267c-0.875,3.449-3.796,5.994-7.332,6.385   C320.123,213.316,319.809,213.333,319.496,213.333z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M233.472,213.333h-48.22c-4.714,0-8.533-3.821-8.533-8.533v-68.267c0-4.713,3.82-8.533,8.533-8.533   h48.22c4.714,0,8.533,3.821,8.533,8.533s-3.82,8.533-8.533,8.533h-39.687v51.2h39.687c4.714,0,8.533,3.821,8.533,8.533   S238.186,213.333,233.472,213.333z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M217.399,179.2h-32.147c-4.714,0-8.533-3.821-8.533-8.533s3.82-8.533,8.533-8.533h32.147   c4.714,0,8.533,3.821,8.533,8.533S222.112,179.2,217.399,179.2z"
			/>
			<path
				style={{ fill: '#8B7E7F' }}
				d="M151.121,213.333c-2.45,0-4.839-1.057-6.492-2.993l-43.246-50.665V204.8   c0,4.713-3.82,8.533-8.533,8.533c-4.714,0-8.533-3.821-8.533-8.533v-68.267c0-3.575,2.228-6.77,5.581-8.007   c3.352-1.238,7.122-0.253,9.442,2.466l43.246,50.665v-45.124c0-4.713,3.82-8.533,8.533-8.533s8.533,3.821,8.533,8.533V204.8   c0,3.575-2.228,6.77-5.581,8.007C153.107,213.162,152.108,213.333,151.121,213.333z"
			/>
		</g>
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
		<g />
	</svg>
);
