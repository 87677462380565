import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import DonorContext from '../DonorContext'

const DonorSettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.donorSettings
  return createSettingContent(
    DonorContext,
    { routes, Wrapper, rootPath }
  )
}

export default DonorSettingsContent
