import { Avatar } from 'antd'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { renderOwnChild } from 'views/Shared'

const HostList = ({
  items = [],
  Wrapper = renderOwnChild,
}) => {
  if (_.isEmpty(items)) return null
  return (
    <Wrapper>
      {items.map(({ host }, index) => {
        const title = getTitle(host)
        return (
          <Link
            key={index}
            to={getLinkToDetail(host)}
            className="flex items-center space-x-2 mb-2">
            <Avatar
              alt={title}
              size="large"
              shape="circle"
              src={getAvatar(host)}
              className="background-100 uppercase font-semibold">
              {_.first(title)}
            </Avatar>
            <span className="text-lg flex-1 text-primary font-semibold">
              {title}
            </span>
          </Link>
        )
      })}
    </Wrapper>
  )
}

export default HostList
