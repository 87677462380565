import { EllipsisOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import DrawMenu from 'components/drawer/DrawMenu'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import {
  deepTranslate,
  Null,
  renderSelf,
} from 'views/Shared'

export const ContextActionTypes =
  Object.freeze({
    VIEW: 'view',
    EDIT: 'edit',
    COPY: 'copy',
    DELETE: 'delete',
    IMPORT: 'import',
    EXPORT: 'export',
    SLIDE_SHOW: 'slide show',
    SLIDE_SHOW2: 'slide show (2)',
    SETTINGS: 'settings',
    CREATE_GIVING: 'create-giving',
    CREATE_DONATION: 'create-donation',
    CREATE_ACTIVITY: 'create-activity',
    CREATE_CHANNEL: 'create-channel',
    CREATE_EVENT: 'create-event',
    CREATE_RECRUITMENT:
      'create-recruitment',
    CREATE_ORGANIZATION:
      'create-organization',
    SUMMARY_REPORT: 'summary-report',
    CREATE_APPLICANT:
      'create-applicant',
    APPLICANT_MANAGEMENT:
      'applicant-management',
  })

const CustomContextMenu = ({
  icon,
  items = [],
  onMenuSelect = Null,
  withTranslate = (t, item) =>
    deepTranslate(t)(item),
}) => {
  const t = useTranslate()

  const menu = useMemo(
    () =>
      Array.from(items || [])
        .map((item) =>
          withTranslate(t, item)
        )
        .map(
          ({
            label,
            renderLabel = renderSelf,
            ...rest
          }) => ({
            ...rest,
            label: renderLabel(label),
          })
        ),
    [t, items]
  )

  return (
    <DrawMenu component={icon}>
      {(__, toggle) => (
        <Menu
            style={{background: 'var(--background)'}}
          onClick={({ key }) => {
            onMenuSelect(key)
            toggle()
          }}
          items={menu}
        />
      )}
    </DrawMenu>
  )
}

const DefaultIcon = ({ onClick }) => (
  <div
    onClick={onClick}
    className="p-1 cursor-pointer rounded-full background hover:background-200 flex flex-center">
    <EllipsisOutlined className="my-auto cursor-pointer text-color-300 hover:text-color-100" />
  </div>
)

const createContextMenu = (
  items,
  onMenuSelect,
  {
    icon = <DefaultIcon />,
    withTranslate,
  } = {}
) => (
  <CustomContextMenu
    icon={icon}
    items={items}
    onMenuSelect={onMenuSelect}
    withTranslate={withTranslate}
  />
)

export const ItemContextMenu = ({
  icon,
  item,
  items,
  withTranslate,
  hidden = false,
  onMenuSelect = Null,
  ...props
}) =>
  hidden
    ? null
    : createContextMenu(
        items,
        (key) =>
          onMenuSelect(key, {
            item,
          }),
        {
          icon,
          withTranslate,
          ...props,
        }
      )

export default createContextMenu
