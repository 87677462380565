import _ from 'lodash'
import React, {
  useEffect,
  useState,
} from 'react'
import { Null } from 'views/Shared'
export const DelayRender = ({
  active = true,
  lazy,
  time = 50,
  children = null,
  Loading = Null,
}) => {
  const [ready, setReady] = useState(
    lazy ? (
      <Loading />
    ) : (
      children || null
    )
  )
  useEffect(
    () => {
      let timeout
      if (active) {
        timeout = setTimeout(() => {
          requestAnimationFrame(() => {
            let ready
            if (
              _.isFunction(children)
            ) {
              ready = children()
              if (
                ready &&
                ready != null
              )
                setReady(children())
            } else {
              if (
                children &&
                children !== null
              )
                setReady(
                  children || null
                )
            }
          })
        }, time)
      }

      return () => {
        timeout && clearTimeout(timeout)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, active]
  )
  if (!active)
    return _.isFunction(children)
      ? children()
      : children
  return ready
}
