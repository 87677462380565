import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../LoadingPage";

const UserMenuLazy = Loadable({
  loader: () => import('./UserMenu'),
  loading: LoadingPage
})

const UserMenu = (props) => (
  <UserMenuLazy {...props} />
)

export default UserMenu
