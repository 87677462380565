import Icon, {LinkOutlined} from '@ant-design/icons'
import {Card, message} from 'antd'
import {post_create_Api} from 'apis'
import {articleModel} from 'apis/model'
import Modal from 'components/Modal/Modal'
import {ArticleSvg} from 'components/Svgs/ArticalSvg'
import {ImageSvg} from 'components/Svgs/ImageSvg'
import {PollSvg} from 'components/Svgs/PollSvg'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import Invite from 'views/Channel/Invite'
import {isMdmArticleEnabled} from 'views/MediumArticle'
import {UseFollow} from 'views/Server/ServerContainer'
import ServerContext from 'views/Server/ServerContext'
import {IoBulbOutline} from "react-icons/io5";

const gridStyle = {
  width: '100%',
  padding: '0.3em 1em',
  lineHeight: '2em',
  cursor: 'pointer',
}

export function Welcome({}) {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent,
    onJoin
  } = useContext(ServerContext)
  const [isToggle, toggle] = useToggle()
  const [
    listKey,
    setListKey
  ] = useToggle()
  const {
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: post_create_Api,
    query: {
      ':prop': item._type,
      ':id': item.id
    },
    onSuccess: addNewPost,
    onError: () =>
      message.error('Failure!')
  })

  const history = useHistory()

  return (
    <div
      className="p-3 "
      style={{
        borderRadius: '0.3em',
        width: '100%',
        overflowWrap: 'break-word',
        overflow: 'hidden'
      }}>
      <div style={{maxWidth: '600px'}}
           className="flex w-full flex flex-col max-w-3xl m-auto font-semibold text-color-300">
        <h2 className="text-lg text-center font-bold header mt-3 mb-6 uppercase text-blue-600">
          {t('me to the channel')}
        </h2>
        <div className="flex items-center gap-3 mb-4">
          <IoBulbOutline size={40} className="text-color-500"/>
          <div className="flex-1 font-medium text-color-300 italic leading-tight">
            <span
              className="cursor-pointer font-bold text-blue-600 cursor-pointer"
              onClick={() => {
                !!!item.joined_status &&
                onJoin()
              }}>
              {t('join')}
            </span>{' '}
            {t('us to share the news in this channel')}
          </div>
        </div>
        <div className="flex items-center gap-3 mb-4">
          <IoBulbOutline size={40} className="text-color-500"/>
          <div className="flex-1 font-medium text-color-300 italic leading-tight">
            <UseFollow
              follow_status={
                item.follow_status
              }
              owner_id={
                item.id || item.username
              }
              owner_type={item._type}>
              {handleFollow => {
                return (
                  <span
                    className="font-bold cursor-pointer text-blue-600"
                    onClick={() => {
                      !!!item.follow_status &&
                      handleFollow()
                    }}>
                    {t('follow')}
                  </span>
                )
              }}
            </UseFollow>{' '}
            {t('the channel so that the system will automatically help you update with the latest news')}
          </div>
        </div>
        <div className="flex items-center gap-3 mb-4">
          <IoBulbOutline size={40} className="text-color-500"/>
          <div className="flex-1 font-medium text-color-300 italic leading-tight">
            <span
              className="font-bold cursor-pointer text-blue-600"
              onClick={() => toggle()}>
              {t('invite and share')}
            </span>{' '}
            <span>
              {t(
                'this channel to your friends'
              )}
            </span>
          </div>
          <Modal
            width={620}
            className="custom-modal"
            destroyOnClose
            footer={null}
            title={
              <div className="text-center font-bold text-color-000 uppercase">
                {t('Invite your friends to this page')}
              </div>
            }
            maskClosable
            visible={isToggle}
            onCancel={() => {
              setListKey(
                new Date().getTime()
              )
              toggle()
            }}>
            <Invite item={item}/>
          </Modal>
        </div>
        <div className="flex items-center gap-3 mb-4">
          <IoBulbOutline size={40} className="text-color-500"/>
          <div className="flex-1 font-medium text-color-300 italic leading-tight">
            <span>
              {t('If you need assistance, please feel free to contact us via')}
            </span>{' '}
            <a
              className="font-bold cursor-pointer text-color-000 no-underline"
              href="mailto:contact@wishare.com">
              contact@wishare.com
            </a>
          </div>
        </div>
      </div>
      {_.get(
        item.available_actions,
        'channel-manage-article'
      ) && (
        <div
          style={{
            maxWidth: '600px'
          }}
          className="flex w-full flex flex-col max-w-3xl m-auto  font-semibold text-color-300">
          <h2 className="text-lg text-center font-bold header mt-3 mb-6 uppercase text-blue-600">
            {t('Post something')}
          </h2>
          <Card bordered={false} className="font-semibold">
            <Card.Grid
              onClick={() => {
                setCurrent('createImagePost')
              }}
              style={gridStyle}>
              <Icon className="mr-2" component={ImageSvg}/>
              {t('image')}
            </Card.Grid>
            <Card.Grid
              onClick={() => {
                setCurrent('quickLink')
              }}
              style={gridStyle}>
              <LinkOutlined className="mr-2"/>
              {t('link')}
            </Card.Grid>
            <Card.Grid
              onClick={() => {
                setCurrent('createPoll')
              }}
              style={gridStyle}>
              <Icon className="mr-2" component={PollSvg}/>
              {t('poll')}
            </Card.Grid>
            <PermissionContext.Consumer>
              {({canPostArticle, ...restProps}) => {
                return !!canPostArticle ? (
                  <Card.Grid
                    className={!canPostArticle && 'disable'}
                    onClick={() => {
                      if (isMdmArticleEnabled) {
                        const createArticleURL = articleModel.getLinkToCreate(item)
                        history.push({
                          pathname: createArticleURL
                        })
                      } else {
                        setCurrent('createArticle')
                      }
                    }}
                    style={gridStyle}>
                    <Icon className="mr-2" component={ArticleSvg}/>
                    {t('article')}
                  </Card.Grid>
                ) : null
              }}
            </PermissionContext.Consumer>
          </Card>
          <div className="emptyMessage-2wGhmr base-34jWEe"/>
        </div>
      )}
    </div>
  )
}
