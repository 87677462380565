import {Avatar, Button, DatePicker, Input, Modal, Radio, Spin,} from 'antd'
import {search_searchObjects_Api, staff_manageStaffAdd_Api, staff_manageStaffEdit_Api,} from 'apis'
import {baseFieldTypes} from 'apis/base/baseFieldTypes'
import {getId} from 'apis/model/base'
import {organizationSchema} from 'apis/schema'
import {renderDecoratedLabel} from 'components/form/FieldDecorator'
import FieldsFactory, {FieldHeader,} from 'components/form/FieldsFactory'
import {createValue} from 'components/form/utils'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Toggle from 'components/Toggle'
import ForDevelop, {ForDevelopZone,} from 'envs/ForDevelop'
import {Formik, FormikConsumer,} from 'formik'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {useCallback, useMemo, useState,} from 'react'
import {SiTinyletter} from 'react-icons/si'
import {IoCalendarOutline, IoPeople, IoPerson,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {compose, mapProps, pure, withProps,} from 'recompose'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import LoadingPage from 'views/LoadingPage'
import {SelectCountries} from 'views/Organization/Settings/SelectCountries'
import {formatDate, getFullAddress, Null, shallowDiff, Visible,} from 'views/Shared'
import {withAppConfig} from 'views/Wishare/composers'
import {modalWidth, wishareConfigs,} from 'views/Wishare/configs'
import {withAdvancedOptions} from 'views/Wishare/custom/AdvancedFormGroup'
import ContainerPicker, {
  renderDefaultActions,
  renderOwnerOption,
  withOwnerResponses,
} from 'views/Wishare/custom/ContainerPicker'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import {SelectWidget} from 'views/Wishare/custom/ReactSelectComponents'
import SelectDataSource from 'views/Wishare/custom/SelectDataSource'
import {wishareClassNames} from 'views/Wishare/custom/wishareClassNames'
import {MaterialTypes, WishareEntities,} from 'views/Wishare/enums'
import {ItemContextMenu} from 'views/Wishare/factory/createContextMenu'
import {notifyOnError} from 'views/Wishare/factory/createErrorEvent'
import createExtraParamsField from 'views/Wishare/factory/createExtraParamsField'
import {createControlledFormField} from 'views/Wishare/factory/createFormField'
import {NotificationActionTypes, successNotify,} from 'views/Wishare/factory/createNotification'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import {renderLocationCascade} from 'views/Wishare/User/functions/renderLocationCascade'
import WishareAlert from 'views/Wishare/WishareAlert'
import {employeeTypeOptions, GenderTypes, getDefaultFieldValue, getProp,} from 'views/Wishare/wishareFieldTypes'
import AvatarUploader from '../components/AvatarUploader'
import {StaffRosterItems} from './renderRosterContainer'
import {CreateStaffRosterModal, newStaffRoster, StaffRosterModalTypes,} from './StaffRosterActionModal'
import StaffRosterThanksCardWrapper from './StaffRosterThanksCard'

export const StaffModalTypes =
  Object.freeze({
    VIEW: 'view',
    EDIT: 'edit',
    CREATE: 'create',
    DELETE: 'delete',
    ASSIGN: 'assign',
    STAFF_ROSTERS: 'staff-roster',
    MEDAL_AWARDS: 'medal-awards',
  })

const newStaff = {
  avatar: null,
  name: undefined,
  email: null,
  gender: null,
  owner: null,
  notes: undefined,
  phone_number: null,
  date_of_birth: null,
  employee_type: null,
  location: undefined,
  address: undefined,
  ward_id: undefined,
  country_id: undefined,
  province_id: undefined,
  district_id: undefined,
  extra_fields: undefined,
  active: getDefaultFieldValue(
    baseFieldTypes.active
  ),
  privacy: getDefaultFieldValue(
    baseFieldTypes.privacy
  ),
}

const withFormSchema = ({
                          owner_id,
                          owner_type,
                          render = (name, component) =>
                            component,
                        }) =>
  [
    {
      // title: 'avatar',
      children: [
        {
          name: 'avatar',
          component:
            createControlledFormField({
              withValues: ({
                             avatar_preview,
                           }) => ({
                value: avatar_preview,
              }),
              Component: AvatarUploader,
            }),
        },
      ],
    },
    {
      children: [
        {
          name: 'information_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('information')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      title: 'name',
      children: [
        {
          name: 'name',
          component:
            createControlledFormField({
              placeholder: 'name',
              Component: Input,
            }),
        },
      ],
    },
    {
      inline: true,
      className:
      wishareClassNames.inline_2,
      children: [
        {
          name: 'gender',
          label: 'gender',
          hideError: true,
          component: compose(
            withProps({
              buttonStyle: 'solid',
              name: 'gender',
              children: Object.values(
                GenderTypes
              ).map(
                ({
                   name,
                   value,
                   label,
                 }) => (
                  <Translate key={name}>
                    {(t) => (
                      <Radio.Button
                        value={value}>
                        {t(label)}
                      </Radio.Button>
                    )}
                  </Translate>
                )
              ),
            })
          )(Radio.Group),
        },
        {
          name: 'date_of_birth',
          label: 'date of birth',
          hideError: true,
          component: compose(
            withTranslate,
            mapProps(
              ({
                 name,
                 value,
                 onChange,
                 translate,
               }) => ({
                name,
                onChange: (
                  date,
                  dateString
                ) => {
                  onChange(
                    createValue(
                      name,
                      date
                        ? moment(
                        new Date(
                          date
                        )
                        )
                        : undefined
                    )
                  )
                },
                defaultValue: value
                  ? moment(
                    new Date(value)
                  )
                  : undefined,
                style: {
                  width: '100%',
                },
                format:
                wishareConfigs.dateFormat,
                placeholder:
                  translate('birthday'),
                suffixIcon: (
                  <IoCalendarOutline/>
                ),
              })
            )
          )(DatePicker),
        },
      ],
    },
    {
      title: 'employee type',
      children: [
        {
          name: 'employee_type',
          component:
            createControlledFormField({
              Component:
              SelectDataSource,
              placeholder:
                'employee type',
              withProps: ({
                            value,
                          }) => ({
                defaultValue: value,
              }),
              dataSource:
              employeeTypeOptions,
            }),
        },
      ],
    },
    {
      children: [
        {
          name: 'contact_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('contact')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      inline: true,
      className:
      wishareClassNames.inline_2,
      children: [
        {
          name: 'phone_number',
          label: 'phone',
          hideError: true,
          component:
            createControlledFormField({
              type: 'tel',
              placeholder: 'phone',
              Component: Input,
            }),
        },
        {
          name: 'email',
          label: 'email',
          hideError: true,
          component:
            createControlledFormField({
              type: 'email',
              placeholder: 'email',
              Component: Input,
            }),
        },
      ],
    },
    {
      title: 'address',
      children: [
        {
          name: 'address',
          hideError: true,
          component:
            createControlledFormField({
              placeholder:
                'address placeholder',
              Component: Input,
            }),
        },
      ],
    },
    {
      title: 'country',
      children: [
        {
          hideError: true,
          name: 'country_id',
          component: compose(
            withTranslate,
            withAppConfig,
            mapProps(
              ({
                 name,
                 form,
                 value,
                 translate,
                 appConfig,
               }) => ({
                name,
                value,
                onChange: (value) => {
                  const {
                    values,
                    setValues = Null,
                  } = form || {}
                  const hasLocationMeta =
                    _.some(
                      _.get(
                        appConfig,
                        'provinces',
                        []
                      ),
                      {
                        country_id:
                        value,
                      }
                    )
                  if (
                    !hasLocationMeta
                  ) {
                    setValues({
                      ...values,
                      [name]: value,
                    })
                  } else {
                    setValues({
                      ...values,
                      [name]: value,
                    })
                  }
                },
                placeholder: translate(
                  'country placeholder'
                ),
                Component: SelectWidget,
              })
            ),
            pure
          )(SelectCountries),
        },
      ],
    },
    {
      children: [
        {
          hideError: true,
          name: 'location',
          component: ({
                        name,
                        value,
                        form,
                        onChange,
                      }) => (
            <div className="my-2">
              {renderLocationCascade({
                name,
                value,
                onChange,
                country_id: _.get(
                  form,
                  'values.country_id'
                ),
                Header: withProps({
                  title:
                    'Province/district/ward',
                })(FieldHeader),
              })}
            </div>
          ),
        },
      ],
    },
    {
      children: [
        {
          name: 'private_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('private')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      children: [
        {
          name: 'owner',
          component: withProps(
            ({
               name,
               value,
               form,
               onChange,
             }) => ({
              name,
              onChange,
              label: 'account link',
              allowClear: true,
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                apiInfo:
                search_searchObjects_Api,
                values: {
                  type: [
                    WishareEntities.USER,
                  ].join(','),
                },
              },
              renderActions:
                renderDefaultActions(
                  value
                ),
              getOption: (option) =>
                _.get(option, 'owner'),
              withResponses:
                withOwnerResponses(
                  value
                ),
            })
          )(ContainerPicker),
        },
      ],
    },
    {
      inline: true,
      className:
      wishareClassNames.inline_2,
      children: [
        {
          name: 'privacy',
          label: 'privacy',
          component:
            withAdvancedOptions(
              baseFieldTypes.privacy
            )(RadioOptions),
        },
        {
          name: 'active',
          label: 'active',
          component:
            withAdvancedOptions(
              baseFieldTypes.active
            )(RadioOptions),
        },
      ],
    },
    {
      children: [
        {
          name: 'notes_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('notes')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      children: [
        {
          name: 'notes',
          component: compose(
            withTranslate,
            mapProps(
              ({
                 name,
                 value,
                 onChange,
                 translate,
               }) => ({
                name,
                value,
                rows: 5,
                onChange,
                placeholder:
                  translate('note'),
              })
            ),
            pure
          )(Input.TextArea),
        },
      ],
    },
    {
      children: [
        {
          name: 'extra_fields',
          component: ({
                        name,
                        value,
                        onChange,
                      }) =>
            createExtraParamsField({
              name,
              value,
              onChange,
              params: {
                Header: ({
                           children,
                         }) => (
                  <Translate>
                    {(t) => (
                      <div className="flex items-center mb-2 gap-2">
                        <label className="flex-1 uppercase text-sm font-semibold text-color-300 tracking-wide">
                          {t(
                            'extra information'
                          )}
                        </label>
                        {children}
                      </div>
                    )}
                  </Translate>
                ),
              },
            }),
        },
      ],
    },
  ].filter(Visible)

const CreateStaffModal = ({
                            onCancel,
                            owner_id,
                            owner_type,
                            onSubmit = Null,
                            onSuccess = Null,
                            submitText = 'create',
                            initialValues = newStaff,
                          }) => {
  const t = useTranslate()

  const {privacy_settings} =
    useSelectEntities(
      owner_id,
      organizationSchema,
      {}
    )

  const {
    isLoading = false,
    handleAsyncAction: createStaff,
  } = useAsyncAction({
    apiInfo: staff_manageStaffAdd_Api,
    query: bindQueryParam({
      organization_id: owner_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const {
      name,
      privacy,
      employee_type,
    } = values || {}
    let errors = {}

    if (_.isEmpty(name)) {
      errors.name = t('required field')
    }

    if (
      !_.find(
        baseFieldTypes.privacy,
        ({value}) => value === privacy
      )
    ) {
      errors.privacy = t(
        'required field'
      )
    }

    if (_.isEmpty(employee_type)) {
      errors.employee_type = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        render: (name, component) => {
          if (name === 'owner') {
            return (props) => (
              <React.Fragment>
                {renderDecoratedLabel(
                  'account link'
                )}
                {component(props)}
              </React.Fragment>
            )
          } else {
            return component
          }
        },
      }),
    []
  )

  const default_extra_fields = _.get(
    privacy_settings,
    'staff_ext_templates'
  )

  const defaultValues = {
    ...initialValues,
    extra_fields: _.isEmpty(
      default_extra_fields
    )
      ? _.get(
        initialValues,
        'extra_fields'
      )
      : Array.from(
        default_extra_fields || []
      ),
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={defaultValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          defaultValues
        )
        onSubmit(createStaff)({
          ...changed,
          privacy: _.get(
            values,
            'privacy'
          ),
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('create staff')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                  dirty,
                  isValid,
                  handleSubmit = Null,
                }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditStaffModal = ({
                          onCancel,
                          owner_id,
                          owner_type,
                          onSubmit = Null,
                          onSuccess = Null,
                          submitText = 'save',
                          initialValues: current = newStaff,
                        }) => {
  const t = useTranslate()

  const {location, avatar, ...rest} =
  current || {}

  const {
    address,
    country,
    ward,
    district,
    province,
  } = location || {}

  const initialValues = {
    ...rest,
    address,
    avatar,
    avatar_preview: avatar,
    country_id: getId(country),
    location: [
      province,
      district,
      ward,
    ].map(getId),
  }

  const {
    isLoading = false,
    handleAsyncAction: editStaff,
  } = useAsyncAction({
    apiInfo: staff_manageStaffEdit_Api,
    query: bindQueryParam({
      id: getId(initialValues),
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const {name, employee_type} =
    values || {}
    let errors = {}

    if (_.isEmpty(name)) {
      errors.name = t('required field')
    }

    if (_.isEmpty(employee_type)) {
      errors.employee_type = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
        render: (name, component) => {
          if (name === 'owner') {
            return ({
                      value,
                      ...props
                    }) => (
              <React.Fragment>
                {renderDecoratedLabel(
                  'account link'
                )}
                {_.isEmpty(
                  initialValues.owner
                )
                  ? component({
                    value,
                    ...props,
                  })
                  : renderOwnerOption(
                    value,
                    undefined,
                    {
                      className:
                        'flex items-center',
                    }
                  )}
              </React.Fragment>
            )
          } else {
            return component
          }
        },
      }),
    [initialValues.owner]
  )

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(editStaff)(changed)
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit staff')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                  dirty,
                  isValid,
                  handleSubmit = Null,
                }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

export const ViewStaffModal = ({
                                 owner_id,
                                 owner_type,
                                 initialValues: staff,
                               }) => {
  const t = useTranslate()

  // const { response, isLoading } =
  //   useAsync({
  //     query: bindQueryParam({
  //       staff_id: getId(staff),
  //     }),
  //     apiInfo:
  //       staff_getStaffRosters_Api,
  //   })

  // const staff_rosters = Array.from(
  //   getResponseItems(response)
  // )

  const address = getFullAddress(
    _.get(staff, 'location')
  )

  let gender = !!_.get(staff, 'gender')
    ? getProp(
      Object.values(GenderTypes),
      'label'
    )(Number(_.get(staff, 'gender')))
    : null
  gender = !!gender ? t(gender) : null

  const linkAccount = (
    <div className="flex items-center text-secondary gap-2">
      {' - '}
      <Link
        to={getLinkToDetail(
          _.get(staff, 'owner')
        )}
        className="flex items-center text-xs gap-1 no-underline">
        {_.get(staff, 'owner_type') ===
        'user' ? (
          <IoPerson/>
        ) : (
          <IoPeople/>
        )}
        {_.get(staff, 'owner.name') ||
        _.get(staff, 'owner.title')}
      </Link>
    </div>
  )

  const RowInformation = ({
                            label,
                            value,
                            hiddenIfEmpty = true,
                          }) =>
    !value && hiddenIfEmpty ? null : (
      <div className="flex items-baseline gap-2">
        <span className="text-xs text-color-400 italic font-light">
          {_.isString(label)
            ? `${t(label)}: `
            : label}
        </span>
        {value ? (
          <span className="text-xs text-color-000 font-medium flex-1">
            {value}
          </span>
        ) : (
          <span className="italic text-xs text-color-200 font-medium">
            {t('not added yet')}
          </span>
        )}
      </div>
    )

  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const onCancel = () =>
    setAction({type: undefined})

  const renderContextMenu = useCallback(
    (staff_roster) => (
      <div className="absolute right-0 top-0">
        <ItemContextMenu
          onMenuSelect={(key) => {
            switch (key) {
              case StaffRosterModalTypes.THANKS_CARD:
                setAction({
                  type: key,
                  value: staff_roster,
                })
                break
              default:
                break
            }
          }}
          items={[
            {
              key: StaffRosterModalTypes.THANKS_CARD,
              label: 'thank you card',
              icon: (
                <SiTinyletter
                  size={16}
                  className="text-color-300"
                />
              ),
            },
          ]}
        />
      </div>
    ),
    []
  )

  return (
    <div className="flex flex-col space-y-3">
      <ForDevelop>
        <Toggle>
          {(isToggle, toggle) => (
            <React.Fragment>
              <Button
                type="primary"
                onClick={toggle}>
                {`Debug`}
              </Button>
              {isToggle && (
                <ForDevelopZone
                  isPretty={true}
                  className="rounded">
                  {(print) => (
                    <WishareAlert
                      visible={true}
                      messageType={
                        MaterialTypes.INFO
                      }
                      message={print(
                        _.omit(staff, [
                          'debug',
                          'location',
                          'organization',
                        ])
                      )}
                    />
                  )}
                </ForDevelopZone>
              )}
            </React.Fragment>
          )}
        </Toggle>
      </ForDevelop>
      <div className="flex flex-col">
        <div className="w-full border border-color-50 rounded-lg background">
          <div className="w-full font-bold flex items-center justify-center text-xl uppercase py-3">
            {t('staff')}
          </div>
          <div className="flex items-center p-3 gap-3 mx-3 rounded-lg border border-green-500">
            <Avatar
              size={35}
              src={getAvatar(staff) || _.get(staff, 'owner.avatar')}
              icon={<IoPerson size={23} className="text-color-300"/>}
              className="flex flex-center background-100"
            />
            <div className="flex flex-col">
              <div className="font-bold leading-tight max-lines-1">
                {getTitle(staff)}
              </div>
              <span className="flex items-center gap-2 italic text-xs text-color-300">
                <span>
                  {t(_.get(staff, 'employee_type'))}
                </span>
                {_.get(staff, 'owner') && linkAccount}
              </span>
            </div>
          </div>
          <div className="flex flex-col p-3">
            <div className="uppercase text-sm font-medium text-color-300 border-b mb-2">
              {t('detail information')}
            </div>
            <div className="space-y-1">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                <RowInformation
                  label={'phone'}
                  value={_.get(
                    staff,
                    'phone_number'
                  )}
                />
                <RowInformation
                  label={'email'}
                  value={_.get(
                    staff,
                    'email'
                  )}
                />
                <RowInformation
                  label={
                    'date of birth'
                  }
                  value={_.get(
                    staff,
                    'date_of_birth'
                  )}
                />
                <RowInformation
                  label={'gender'}
                  value={gender}
                />
              </div>
              <RowInformation
                label={'address'}
                value={address}
              />
              <RowInformation
                label={'notes'}
                value={_.get(
                  staff,
                  'notes'
                )}
              />
            </div>
            {_.get(
              staff,
              'extra_fields',
              []
            ).length > 0 && (
              <>
                <div className="uppercase text-xs font-medium text-color-400 border-b mb-2 mt-3">
                  {t(
                    'extra information'
                  )}
                </div>
                <div className="space-y-1">
                  {_.get(
                    staff,
                    'extra_fields',
                    []
                  )
                    .filter(
                      (e) =>
                        !!e.value ||
                        !!e.nvalue ||
                        !!e.dvalue
                    )
                    .map((e, index) => {
                      let value =
                        e.value
                      if (!!e.dvalue)
                        value =
                          formatDate(
                            e.dvalue
                          )
                      if (!!e.nvalue)
                        value = Number(
                          e.nvalue
                        ).toLocaleString()
                      if (!!e.value)
                        value = e.value
                      return (
                        <RowInformation
                          key={index}
                          label={e.name}
                          value={value}
                        />
                      )
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <StaffRosterItems
        Wrapper="div"
        collapsed={true}
        staff_id={getId(staff)}
        owner_id={owner_id}
        owner_type={owner_type}
        onInit={(init = Null) => {
          init()
        }}
        renderContextMenu={
          renderContextMenu
        }
        className="flex flex-col gap-2"
      />
      {action.type ===
      StaffRosterModalTypes.THANKS_CARD && (
        <FullSideModalLayout
          right={true}
          onCancel={onCancel}>
          <StaffRosterThanksCardWrapper
            item={action.value}
          />
        </FullSideModalLayout>
      )}
    </div>
  )
}

const StaffActionModal = ({
                            type,
                            params,
                            onCancel,
                            onCreated,
                            onUpdated,
                            onAssigned,
                            initialValues,
                            isLoading = false,
                            ...props
                          }) => {
  let Component = Null

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case StaffModalTypes.CREATE:
      Component = CreateStaffModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case StaffModalTypes.EDIT:
      Component = EditStaffModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    case StaffModalTypes.ASSIGN:
      Component = CreateStaffRosterModal
      asyncParams = {
        onSuccess: onAssigned,
        onSubmit:
          (handleSubmit = Null) =>
            ({
               id,
               workplace,
               workplace_id,
               workplace_type,
               location,
               ...values
             }) => {
              const [
                province_id,
                district_id,
                ward_id,
              ] = Array.from(
                location || [, , ,]
              )
              handleSubmit(
                _.omitBy(
                  {
                    ..._.pick(
                      values,
                      Object.keys(
                        newStaffRoster
                      )
                    ),
                    workplace_id,
                    workplace_type,
                    province_id,
                    district_id,
                    ward_id,
                  },
                  _.isUndefined
                )
              )
            },
      }
      break
    default:
      break
  }

  const {
    staff_id,
    owner_id,
    owner_type,
  } = params || {}

  const onSubmit =
    (handleSubmit = Null) =>
      ({
         id,
         owner,
         location,
         owner_id,
         owner_type,
         extra_fields,
         avatar_preview,
         ...values
       }) => {
        const [
          province_id,
          district_id,
          ward_id,
        ] = Array.from(
          location || [, , ,]
        )
        handleSubmit(
          _.omitBy(
            {
              ..._.pick(
                values,
                Object.keys(newStaff)
              ),
              owner_id,
              owner_type,
              ward_id,
              district_id,
              province_id,
              ...(_.isEmpty(extra_fields)
                ? {}
                : {
                  extra_fields:
                    JSON.stringify(
                      Array.from(
                        extra_fields
                      )
                    ),
                }),
            },
            _.isUndefined
          )
        )
      }
  return (
    <Modal
        className="custom-modal"
      footer={null}
      closable={false}
      visible={!!type}
      onCancel={onCancel}
      destroyOnClose={true}
      width={modalWidth.medium}>
      {!!isLoading ? (
        <LoadingPage/>
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            staff_id,
            initialValues,
            owner_id,
            owner_type,
          }}
          {...asyncParams}
        />
      )}
    </Modal>
  )
}

export default StaffActionModal
