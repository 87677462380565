import {
  Avatar,
  Button,
  List,
  Modal,
} from 'antd'
import { donor_searchDonors_api } from 'apis'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  IoCheckmarkOutline, IoPerson,
  IoPersonCircleOutline,
} from 'react-icons/io5'
import { useToggle } from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItems,
  Null,
  renderFalse,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'

const DonorItemWrapper = ({
  item,
  children,
  ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderDonorItem = (
  {
    onClick,
    renderActions,
    nullable = false,
    selected = false,
    renderContent = renderSelf,
    ...item
  },
  index
) => {
  const verified = _.get(
    item,
    'certified'
  )
  const { owner = {} } = item || {}

  const isEmpty =
    !!!nullable &&
    Boolean(
      _.isEmpty(owner) &&
        _.isEmpty(getTitle(item))
    )
  return (
    <div
      key={index}
      onClick={onClick}
      className={classNames(
        'w-full px-3 py-2 rounded-lg border border-color-50 background',
        !!onClick && 'cursor-pointer'
      )}>
      <Translate>
        {(t) => (
            <div className="flex items-center gap-2">
              {renderContent(
                  isEmpty ? (
                      <EmptyHolder
                          icon={null}
                          subTitle={t(
                              'Click to select...'
                          )}
                          style={{
                            padding: 0,
                            border: 'none',
                          }}
                      />
                  ) : (
                      <div className="flex items-center gap-2 flex-1">
                        <Avatar
                            size={30}
                            className="flex flex-center background-100"
                            icon={<IoPerson className="text-color-300"/>}
                            src={getAvatar(owner)}
                        />
                        <div className="flex flex-col flex-1">
                          <span className="text-color-000 font-semibold">
                            {getTitle(item)}
                            {verified === 1 && (
                                <VerifiedSvg
                                    width={'1rem'}
                                    height={'1rem'}
                                    className="ml-1 zoom-animation"
                                />
                            )}
                          </span>
                          <span className="text-xs text-secondary italic leading-tight">
                            {[
                                !!getType(item) ? t(getType(item)) : "",
                                !!_.get(item, 'owner') ? t(getType(_.get(item, 'owner'))) : "",
                            ].filter(e => !!e).join(" - ")}
                          </span>
                        </div>
                      </div>
                  )
              )}
              {renderActions
                  ? renderActions(item)
                  : [
                    selected ? (
                        <IoCheckmarkOutline
                            size={25}
                            className="text-green-600"
                        />
                    ) : (
                        <span className="px-2 py-1 bg-primary text-xs text-white rounded-md leading-tight">
                            {t('select')}
                          </span>
                    ),
                  ]
              }
            </div>
        )}
      </Translate>
    </div>
  )
}

const DonorSelectorModal = ({
  Header = Null,
  organization_id,
  onSelect = Null,
  isSelected = renderFalse,
  Wrapper = renderOwnChild,
}) => {
  const [
    keyword,
    ___,
    searchInput,
    typingValue,
    isTyping,
  ] = useDebounceSearch({
    timeout: 500,
    autoFocus: true,
    className: 'w-full p-2',
  })

  const apiInfo = donor_searchDonors_api

  return (
    <Wrapper>
      {searchInput}
      {!_.isEmpty(keyword) && (
        <Header
          {...{
            onSelect,
            organization_id,
            keyword: typingValue,
          }}
        />
      )}
      <Async
        key={keyword}
        values={{
          keyword,
        }}
        query={bindQueryParam({
          organization_id,
        })}
        apiInfo={apiInfo}>
        {({ response, isLoading }) => {
          if (Boolean(isLoading)) {
            return <LoadingPage />
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          )

          const loading = Boolean(
            isLoading || isTyping
          )

          return (
            <React.Fragment>
              <Translate>
                {(t) => (
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2 ">
                      <div className="uppercase text-sm font-medium text-color-400 whitespace-no-wrap">
                        {t(
                          'donor list'
                        )}
                      </div>
                      <div className="border-b w-full" />
                    </div>
                    <List
                      loading={loading}
                      dataSource={items}
                      renderItem={renderClicker(
                        {
                          onClick:
                            onSelect,
                          renderItem: (
                            item,
                            index
                          ) =>
                            renderDonorItem(
                              {
                                ...item,
                                nullable: true,
                                selected:
                                  isSelected(
                                    item
                                  ),
                              },
                              index
                            ),
                          Wrapper:
                            DonorItemWrapper,
                        }
                      )}
                      pagination={{
                        pageSize: 8,
                        hideOnSinglePage: true,
                      }}
                    />
                  </div>
                )}
              </Translate>
            </React.Fragment>
          )
        }}
      </Async>
    </Wrapper>
  )
}

const DonationDonorSelector = ({
  name,
  Header = Null,
  organization_id,
  onChange = Null,
  readOnly = false,
  renderDonor = Null,
  defaultValue: donor = {},
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const [current, setCurrent] =
    useState(donor || {})

  const handleSelect = (object) => {
    setCurrent(object)
    onChange(object)
    toggle(false)
  }

  useEffect(() => {
    const { id: donor_id } = donor || {}
    const { id: current_id } =
      current || {}
    if (donor_id !== current_id) {
      setCurrent(donor)
    }
  }, [donor, current])

  return (
    <Wrapper>
      <div className="flex w-full items-center my-2">
        {renderDonorItem(
          {
            ...current,
            onClick: readOnly
              ? undefined
              : () => toggle(true),
            renderContent: (child) =>
              renderDonor(
                current,
                child
              ),
            renderActions: () => [
              !!readOnly ? (
                <IoCheckmarkOutline
                  size={25}
                  className="text-green-600"
                />
              ) : (
                <Button
                  ghost={true}
                  type={'primary'}
                  size={'small'}
                  className="no-shadow no-text-shadow text-xs font-medium rounded-md">
                  {t('search')}
                </Button>
              ),
            ],
          },
          '__current_donor__'
        )}
      </div>
      <Modal
        className="custom-modal"
        onCancel={toggle}
        visible={isToggle}
        destroyOnClose={true}
        footer={
          <Button
            className="rounded-lg border-none"
            onClick={() =>
              toggle(false)
            }>
            {t('close')}
          </Button>
        }
        title={
          <div className="font-bold text-center text-color-000">
            {t('find donor')}
          </div>
        }>
        <DonorSelectorModal
          Wrapper={({ children }) => (
            <div className="flex flex-col space-y-3">
              {children}
            </div>
          )}
          onSelect={handleSelect}
          organization_id={
            organization_id
          }
          Header={({ keyword }) => (
            <Header
              {...{
                keyword,
                onCancel: () =>
                  toggle(false),
              }}
            />
          )}
          isSelected={(item) => {
            if (_.isEmpty(item)) {
              return false
            }
            return _.every(
              [
                'id',
                'owner_id',
                'owner_type',
              ],
              (name) =>
                _.get(item, name) ===
                _.get(current, name)
            )
          }}
        />
      </Modal>
    </Wrapper>
  )
}

export default DonationDonorSelector
