import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import ForDevelop from 'envs/ForDevelop'
import _ from 'lodash'
import {
  compose,
  mapProps,
  nest,
  withProps,
} from 'recompose'
import {
  BoxPlaceholder,
  fromObject,
  renderSelf,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import ImCatePicker from '../Category/components/ImpCatePicker'
import AdvancedFormGroup, {
  withAdvancedOptions,
} from '../custom/AdvancedFormGroup'
import ContainerPicker from '../custom/ContainerPicker'
import RadioOptions from '../custom/RadioOptions'
import { WishareEntities } from '../enums'
import createFormField from '../factory/createFormField'
import {
  requiredNumber,
  requiredString,
} from '../factory/createValidationSchema'

// Todo: implement translation

const createGroup = ({
  privacy,
  status,
  ...values
}) => [
  {
    invisible: true,
    children: [
      {
        name: 'invisible',
        component: nest(
          ForDevelop,
          BoxPlaceholder
        ),
      },
    ],
  },
  {
    // inline: true,
    // className: 'grid grid-cols-2 gap-2',
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
        value: privacy,
      },
      // {
      //   label: 'status',
      //   name: 'status',
      //   component: withAdvancedOptions(
      //     baseFieldTypes.status
      //   )(RadioOptions),
      //   value: status,
      // },
    ],
  },
]

const advancedSchema = {
  children: [
    {
      name: 'advanced',
      component: mapProps(
        ({ form, onChange }) => {
          const _values = fromObject(
            _.get(form, 'values'),
            ['status', 'privacy'].map(
              (e) => ({
                name: e,
              })
            )
          )
          return {
            onChange,
            groupValues:
              createGroup(_values),
          }
        }
      )(AdvancedFormGroup),
    },
  ],
}

export const beneficiaryValidationSchema = {
  [CRUD.CREATE]: (
    translate = renderSelf
  ) => {
    return {
      title: requiredString(translate),
      privacy:
        requiredNumber(translate),
      owner: requiredString(translate),
      impact_categories:
        requiredString(translate),
    }
  },
}

export const beneficiaryStepFormSchema = {
  impactStage: [
    {
      children: [
        {
          name: 'impact_categories',
          component: compose(
            mapProps(
              ({
                name,
                value,
                onChange,
              }) => ({
                name,
                value,
                onChange,
                className:
                  'grid grid-cols-3 md:grid-cols-6 gap-1 md:gap-2',
              })
            )
          )(ImCatePicker),
        },
      ],
    },
  ],
  ownerStage: [
    {
      title: 'title',
      children: [
        {
          name: 'title',
          component: createFormField({
            placeholder: 'title',
          }),
        },
      ],
    },
    {
      children: [
        {
          name: 'owner',
          component: withProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => {
              const defaultValue =
                _.get(
                  form,
                  'values.default_owner_id',
                  value
                )
              return {
                name,
                onChange,
                defaultValue,
                type: WishareEntities.BENEFICIARY,
                label: 'owner',
                getOption: (option) => {
                  return _.get(
                    option,
                    'owner'
                  )
                },
              }
            }
          )(ContainerPicker),
        },
      ],
    },
    advancedSchema,
  ],
}
