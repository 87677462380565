import classNames from 'classnames'
import _ from 'lodash'
import React, {
  useMemo,
  useState,
} from 'react'
import { HiViewList } from 'react-icons/hi'
import { MdViewAgenda } from 'react-icons/md'

export const ViewTypes = Object.freeze({
  HEADLINE: 'headline',
  LISTVIEW: 'list',
})

export const ViewContext =
  React.createContext({})

const ToggleView = ({
  style,
  children,
  className,
  renderListView,
  renderHeadlineView,
  defaultView = ViewTypes.HEADLINE,
}) => {
  const [view, setView] = useState(
    defaultView
  )

  const configs = [
    {
      value: ViewTypes.HEADLINE,
      component: HiViewList,
      render: renderHeadlineView,
    },
    {
      value: ViewTypes.LISTVIEW,
      component: MdViewAgenda,
      render: renderListView,
    },
  ]
  const { render: renderItem } =
    _.find(configs, {
      value: view,
    }) || {}
  const switchComponent = useMemo(
    () => (
      <div
        style={style}
        className={classNames(
          'flex space-x-1 text-xl items-baseline my-auto',
          className
        )}>
        {configs.map(
          ({
            value,
            component: Component,
          }) => (
            <Component
              key={value}
              {...{
                onClick: () => {
                  setView(value)
                },
                className: classNames(
                  view === value
                    ? 'text-primary font-bold'
                    : 'text-color-500 cursor-pointer'
                ),
              }}
            />
          )
        )}
      </div>
    ),
    [view, style]
  )
  return children({
    view,
    setView,
    renderItem,
    switchComponent,
  })
}

export const ViewProvider = ({
  children,
  ...props
}) => {
  return (
    <ToggleView {...props}>
      {({ ...rest }) => (
        <ViewContext.Provider
          value={{
            ...rest,
          }}>
          {children}
        </ViewContext.Provider>
      )}
    </ToggleView>
  )
}

export default ToggleView
