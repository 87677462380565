import { getId } from 'apis/model/base'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import {
  Null,
  renderSelf,
} from './typedefs'

const getLine = (
  input,
  fields = [],
  get = _.get
) => {
  return fields
    .map((name) =>
      get(input, name, null)
    )
    .filter((el) => !!el)
    .join(' ')
}

const getLocationLines = (
  item,
  position = 0
) => {
  const location = _.get(
    item,
    `locations.${position}`,
    {}
  )

  if (_.isEmpty(location)) {
    return ''
  }

  return [
    getLine(location, ['address']),
    getLine(location, [
      'ward.type',
      'ward.name',
    ]),
    getLine(location, [
      'district.type',
      'district.name',
    ]),
    getLine(location, [
      'province.type',
      'province.name',
    ]),
    getLine(location, [
      'country.common_name',
    ]),
  ]
    .filter((el) => !!el)
    .join(', ')
}

const getFullAddress = (item) => {
  if (
    _.isEmpty(_.get(item, 'address')) &&
    _.isEmpty(
      _.get(item, 'ward.location_name')
    ) &&
    _.isEmpty(
      _.get(
        item,
        'district.location_name'
      )
    ) &&
    _.isEmpty(
      _.get(
        item,
        'province.location_name'
      )
    )
  ) {
    return null
  }

  return (
    <span className="space-x-1">
      {[
        _.get(item, 'address'),
        _.get(
          item,
          'ward.location_name'
        ),
        _.get(
          item,
          'district.location_name'
        ),
        _.get(
          item,
          'province.location_name'
        ),
        _.get(
          item,
          'country.common_name'
        ),
      ]
        .filter((e) => !!e)
        .join(', ')}
    </span>
  )
}

const sortById = (
  collection = [],
  getFn = getId
) => _.sortBy(collection, getFn)

const getItemMoment = (
  item,
  name,
  defaultValue = moment()
) => {
  const value = _.get(item, name)
  return !!value
    ? moment(value)
    : defaultValue
}

const postMoment = (
  value,
  format = 'YYYY-MM-DD'
) => moment(value).utc().format(format)

const deepTranslate =
  (
    t = Null,
    [fields, params] = [
      {
        label: 'label',
        children: 'children',
      },
      {},
    ]
  ) =>
  (item) => {
    const translateFn = (item) => {
      const raw = _.get(
        item,
        fields.label
      )
      const label = _.isString(raw)
        ? t(_.get(item, fields.label))
        : raw
      const children = _.get(
        item,
        fields.children,
        []
      )
      if (_.isEmpty(children)) {
        return {
          ...item,
          ...params,
          [fields.label]: label,
        }
      } else {
        return {
          ...item,
          ...params,
          [fields.label]: label,
          [fields.children]:
            children.map(translateFn),
        }
      }
    }
    return translateFn(item)
  }

const searchQueryParam = (
  search,
  name,
  arrayFormat = 'comma'
) => {
  const query = queryString.parse(
    search,
    {
      arrayFormat,
    }
  )
  return _.get(query, name)
}

const equalValues = (
  [left, right],
  getValue = renderSelf
) => {
  const leftValue = getValue(left)
  const rightValue = getValue(right)
  return _.isEqual(
    leftValue,
    rightValue
  )
}

const notEmpty = (value) =>
  [
    _.isEmpty(value),
    _.isEqual(value, 'undefined'),
    _.isEqual(value, 'null'),
  ].every((e) => !e)

const notDeleted = (value) =>
  !_.get(value, 'deleted', false)

const searchWith = (
  { type, keyword, pathname = '/' },
  onSearch = Null
) => {
  const _searchParams =
    new URLSearchParams()
  if (!_.isEmpty(type)) {
    _searchParams.set('type', type)
  }
  if (!_.isEmpty(keyword)) {
    _searchParams.set(
      'keyword',
      keyword
    )
  }
  onSearch({
    pathname,
    search: _searchParams.toString(),
  })
}

const normalizeString = (str) => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(
  normalizeString
)

const compare = (left, right) => {
  return memoNormalizeString(
    left
  ).includes(memoNormalizeString(right))
}

const toQueryString = (
  param,
  options = { arrayFormat: 'comma' }
) =>
  queryString.stringify(param, options)

const getURL = (
  url,
  queryParams = {},
  options = { arrayFormat: 'comma' }
) => {
  if (_.isEmpty(queryParams)) return url
  return queryString.stringifyUrl(
    { url, query: queryParams },
    options
  )
}

const fromObject = (
  item = {},
  fieldParams = [],
  configs = {
    nameIndex: 'name',
    defaultValueIndex: 'defaultValue',
  }
) => {
  if (
    _.isEmpty(item) ||
    _.isEmpty(fieldParams)
  )
    return {}
  return fieldParams.reduce(
    (result, current = {}) => {
      const name = _.get(
        current,
        configs.nameIndex
      )
      const defaultValue = _.get(
        current,
        configs.defaultValueIndex
      )
      result[name] = _.get(
        item,
        name,
        defaultValue
      )
      return result
    },
    {}
  )
}

const shallowDiff = (a, b) => {
  return _.omitBy(a, (v, k) => {
    return _.isArray(b[k])
      ? JSON.stringify(b[k]) ===
          JSON.stringify(v)
      : b[k] === v
  })
}

export const getResponseItem = (
  response,
  which = 'data'
) => _.get(response, which) || {}

export const getResponseItems = (
  response,
  which = 'data.data'
) => _.get(response, which) || []

export const mapStringParams = (
  string = '',
  values = [],
  createPattern = (name) => `{${name}}`
) => {
  if (_.isEmpty(values)) return string
  return values.reduce(
    (__, { name, value }) =>
      string.replace(
        createPattern(name),
        value
      ),
    string
  )
}

const formatDate = (
  dt,
  dateFormat = 'DD/MM/YYYY'
) => {
  if (!dt) return null
  if (!moment(dt).isValid()) return null

  return moment(dt).format(dateFormat)
}

const formatDateTime = (dateTime) => {
  const hour = moment(dateTime).utc().hours()
  const minute = moment(dateTime).utc().minutes()

  if (hour === 0 && minute === 0){
    return moment(dateTime).format('DD/MM/YYYY')
  }
  return moment(dateTime).format('HH:mm - DD/MM/YYYY')
}

export {
  getURL,
  compare,
  notEmpty,
  sortById,
  notDeleted,
  searchWith,
  postMoment,
  fromObject,
  shallowDiff,
  equalValues,
  deepTranslate,
  getItemMoment,
  toQueryString,
  searchQueryParam,
  getLocationLines,
  getFullAddress,
  formatDate,
  formatDateTime,
}
