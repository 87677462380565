import {
  LinkOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons'
import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withProps,
} from 'recompose'
import { BoxPlaceholder } from 'views/Shared'
import {
  notFoundOnError,
  withAccessPermissions,
} from 'views/Wishare/composers'
import { WishareEntities } from 'views/Wishare/enums'
import { ContextActionTypes } from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import { createSettingModule } from 'views/Wishare/factory/createModule'
import UserContext from '../UserContext'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <SettingOutlined />,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <LinkOutlined />,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: BoxPlaceholder,
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <UserContext.Consumer>
        {({ user }) =>
          createDeleteEntity(user)
        }
      </UserContext.Consumer>
    ),
  },
].map(
  ({
    path,
    component: Component,
    ...rest
  }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const userSettings = {
  actions: [
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <StarOutlined />,
    },
  ],
  menuItems,
  routeEntities,
}

const UserSettings = ({
  id,
  ...props
}) =>
  createSettingModule(
    [WishareEntities.USER],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(UserContext.Consumer),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({ match }) => ({
    id: _.get(match, 'params.id'),
  }))
)(UserSettings)
