import {
  Input,
  List,
  Radio,
  Skeleton,
} from 'antd'
import { activities_getItemsByOwner_gets_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import AppIcon from 'components/AppIcon'
import { OriginalObjectSelector } from 'components/form/fields/ObjectSelectField'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { IoCheckboxOutline } from 'react-icons/io5'
import {
  getResponseItems,
  Null,
} from 'views/Shared'

export const WorkplaceSuffixNames =
  Object.freeze({
    ID: 'id',
    TYPE: 'type',
    NAME: 'name',
  })

export const WorkplaceDisplayTypes =
  Object.freeze({
    TEXT: {
      label: 'other project',
      value: 'text',
    },
    OBJECT: {
      label: 'select project',
      value: 'object',
    },
  })

const OtherInput = ({
  name,
  value,
  withSuffix,
  defaultValue,
  setValues = Null,
}) => {
  const handleChange = (event) => {
    const text = event.target.value
    const [newValues = {}] = withSuffix(
      [
        {
          value: undefined,
          name: WorkplaceSuffixNames.ID,
        },
        {
          value: undefined,
          name: WorkplaceSuffixNames.TYPE,
        },
        {
          value: text,
          name: WorkplaceSuffixNames.NAME,
        },
      ],
      value,
      true
    )
    setValues({ ...newValues })
  }

  return (
    <Translate>
      {(t) => (
        <Input
          name={name}
          placeholder={t(
            'For example: ABC Campaign, ABC Organization,...'
          )}
          onChange={handleChange}
          value={_.get(value, name)}
          defaultValue={defaultValue}
        />
      )}
    </Translate>
  )
}

const WorkplaceObjectSelect = ({
  name,
  value,
  query,
  withSuffix,
  defaultValue,
  setValues = Null,
}) => {
  const apiInfo =
    activities_getItemsByOwner_gets_api
  const cacheId = [
    apiInfo.path,
    name,
    JSON.stringify(query),
  ].join('/')
  const { response } =
    useAsyncWithCache({
      query,
      cacheId,
      apiInfo,
    })

  const renderActivity = useCallback(
    (item, actions) => (
      <List.Item
        className="p-0"
        actions={actions}>
        <List.Item.Meta
          title={
            <div className="flex items-center">
              <span className="font-medium">
                {getTitle(item)}
              </span>
            </div>
          }
          prefixCls="OptionMetaItem"
          className="flex items-center gap-2"
          avatar={
            <AppIcon item={item} />
          }
        />
      </List.Item>
    ),
    []
  )

  const options = useMemo(() => {
    const activities =
      getResponseItems(response)
    return Array.from(activities).map(
      (activity) => {
        const params = _.pick(
          activity,
          [
            'id',
            '_type',
            'name',
            'title',
            'idname',
            'avatar',
          ]
        )
        return {
          value: getId(activity),
          label: renderActivity(
            params,
            [<IoCheckboxOutline />]
          ),
          render_as:
            renderActivity(params),
          ...params,
        }
      }
    )
  }, [response])

  const handleChange = ({
    id,
    type,
    object,
  }) => {
    const [newValues = {}] = withSuffix(
      [
        {
          value: id,
          name: WorkplaceSuffixNames.ID,
        },
        {
          value: type,
          name: WorkplaceSuffixNames.TYPE,
        },
      ],
      value,
      true
    )
    const [_changed = {}] = withSuffix(
      {
        value: undefined,
        name: WorkplaceSuffixNames.NAME,
      },
      value,
      true
    )
    setValues({
      ...newValues,
      [_changed.name]: _changed.value,
    })
  }

  if (_.isEmpty(options))
    return (
      <Skeleton
        loading={true}
        active={true}
        avatar={true}
        className="border p-1 rounded"
        paragraph={{ rows: 0 }}
      />
    )

  return (
    <Translate>
      {(t) => (
        <OriginalObjectSelector
          name={name}
          key={options}
          bordered={false}
          showSearch={true}
          options={options}
          defaultValue={
            _.isEmpty(options)
              ? undefined
              : defaultValue
          }
          label={t('activity')}
          onChange={(value, object) => {
            return handleChange({
              value,
              id: getId(object),
              type: getType(object),
            })
          }}
          optionFilterProp="title"
          optionLabelProp="render_as"
        />
      )}
    </Translate>
  )
}

const WorkplaceSelector = ({
  name,
  query,
  setValues,
  workplace,
  defaultValue,
  onChange = Null,
  Wrapper = 'div',
  type = WorkplaceDisplayTypes.TEXT
    .value,
}) => {
  const t = useTranslate()

  const [current, setCurrent] =
    useState(type)

  const options = useMemo(() => {
    const items = Object.values(
      WorkplaceDisplayTypes
    )
    return items.map(
      ({ label, value }) => {
        return {
          value,
          label: t(label),
        }
      }
    )
  }, [t])

  const withSuffix = useCallback(
    (
      suffix,
      data,
      override = false
    ) => {
      const isMulti = _.isArray(suffix)
      const array = Array.from(
        isMulti ? suffix : [suffix]
      ).map((field) => {
        const _field = _.get(
          field,
          'name',
          field
        )
        const fieldName = [
          name,
          _field,
        ].join('_')
        const _value = _.get(
          data,
          fieldName
        )
        return {
          id: field,
          value: _value,
          name: fieldName,
          newValue: _.get(
            field,
            'value',
            _value
          ),
        }
      })
      const object = array.reduce(
        (
          prev,
          { id, name, value, newValue }
        ) => {
          const _value = override
            ? newValue
            : value
          if (isMulti) {
            return {
              ...prev,
              [name]: _value,
            }
          } else {
            return {
              id,
              name,
              value: _value,
            }
          }
        },
        {}
      )
      const getValueBySuffix = (
        suffix
      ) => {
        return _.find(array, {
          id: suffix,
        })
      }
      return [object, getValueBySuffix]
    },
    [name]
  )

  const attached = useMemo(() => {
    switch (current) {
      case WorkplaceDisplayTypes.OBJECT
        .value:
        const [
          _currentObject = {},
          getValueBySuffix = Null,
        ] = withSuffix(
          [
            WorkplaceSuffixNames.ID,
            WorkplaceSuffixNames.TYPE,
          ],
          defaultValue
        )
        return (
          <WorkplaceObjectSelect
            name={name}
            query={query}
            value={workplace}
            setValues={setValues}
            withSuffix={withSuffix}
            defaultValue={getValueBySuffix(
              WorkplaceSuffixNames.ID
            )}
          />
        )
      case WorkplaceDisplayTypes.TEXT
        .value:
        const [input_data = {}] =
          withSuffix(
            WorkplaceSuffixNames.NAME,
            defaultValue
          )
        return (
          <OtherInput
            name={name}
            value={workplace}
            setValues={setValues}
            withSuffix={withSuffix}
            defaultValue={
              input_data.value
            }
          />
        )
      default:
        return null
    }
  }, [name, current, workplace])

  const resetParams = () => {
    const [newValues = {}] = withSuffix(
      Object.values(
        WorkplaceSuffixNames
      ).map((name) => ({
        name,
        value: null,
      })),
      workplace,
      true
    )
    setValues({
      ...newValues,
    })
  }

  return (
    <Wrapper className="space-y-2">
      <Radio.Group
        name={name}
        value={current}
        className="flex items-center"
        onChange={(event) => {
          resetParams()
          setCurrent(event.target.value)
        }}>
        {options.map(
          ({ label, value }, index) => (
            <Radio
              key={index}
              value={value}>
              {t(label)}
            </Radio>
          )
        )}
      </Radio.Group>
      <div className="w-full">
        {attached}
      </div>
    </Wrapper>
  )
}

export default WorkplaceSelector
