import {Avatar, Checkbox, Modal, Radio} from 'antd'
import classnames from 'classnames'
import {FlagIcon} from 'components/icons/FlagIcon'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState} from 'react'
import {ReactReduxContext} from 'react-redux'
import useToggle from 'react-use/lib/useToggle'
import {getLogin} from 'redux/selectors'

export const LanguageSwitch = ({
                                 title
                               }) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()
  const {
    lang,
    changeLang,
    changeContentLang
  } = useContext(LocalContext)
  const [
    displayLanguage,
    setDisplayLanguage
  ] = useState(lang)
  const { store } = useContext(
    ReactReduxContext
  )
  const login = getLogin(
    store.getState()
  )
  const following_languages_maximum = _.get(
    login,
    'following_languages_maximum',
    1
  )
  const _languages = _.get(
    login,
    'following_languages',
    ['vi']
  )
  const following_languages = _.isEmpty(
    _languages
  )
    ? ['vi']
    : _languages
  const [
    contentLanguages,
    setContentLanguages
  ] = useState(following_languages)
  const [
    indeterminate,
    setIndeterminate
  ] = useState(true)
  const [
    checkAll,
    setCheckAll
  ] = useState(false)
  const langArray = ['vi', 'en', 'fr', 'km', 'ko']

  return (
    <>
      <div onClick={() => toggle()}>
        {title}
      </div>
      {isToggle && (
        <Modal
          className="custom-modal"
          title={
            <div className="text-center font-bold text-color-000 uppercase">
              {t('Choose language')}
            </div>
          }
          visible={isToggle}
          header={null}
          footer={null}
          onCancel={toggle}>
          <div className="flex-col space-y-6">
            <div className="flex-col space-y-4">
              <div className="font-medium italic">
                {t('Interface language')}{':'}
              </div>
              <Radio.Group
                  className="w-full space-y-3"
                  onChange={e => {
                    setDisplayLanguage(
                        e.target.value
                    )
                    changeLang(
                        e.target.value
                    )
                  }}
                  value={
                    displayLanguage
                  }>
                <React.Fragment>
                  {langArray.map(
                      (value, key) => (
                          <Radio
                              key={key}
                              value={value}
                              className={classnames(
                                  'flex items-center hover:background-200 px-3',
                                  lang === value ? 'text-primary font-bold' : 'font-normal'
                              )}>
                            <div className="flex justify-center items-center px-3">
                              <FlagIcon
                                  type={value}
                                  style={{width: '1.5rem', height:'1.5rem'}}
                              />
                              <div className="px-3">
                                {t(value)}
                              </div>
                            </div>
                          </Radio>
                      )
                  )}
                </React.Fragment>
              </Radio.Group>
            </div>
            <div className="flex-col">
              <div className="font-medium italic">
                {t('Content language')}{':'}
              </div>
              <div className="flex-col">
                <div className="px-3 my-3">
                  <Checkbox
                    disabled={
                      langArray.length >
                      following_languages_maximum
                    }
                    indeterminate={
                      indeterminate
                    }
                    onChange={e => {
                      setContentLanguages(
                        e.target.checked
                          ? langArray
                          : []
                      )
                      setIndeterminate(
                        false
                      )
                      setCheckAll(
                        e.target.checked
                      )

                      // Update to Server
                      changeContentLang(
                        langArray.reduce(
                          (
                            result,
                            value,
                            i
                          ) => {
                            result +=
                              i === 0
                                ? value
                                : ',' +
                                value
                            return result
                          },
                          ''
                        )
                      )
                    }}
                    checked={checkAll}>
                    <span className="ml-3 font-medium text-color-100">
                      {t('check all')}
                    </span>
                  </Checkbox>
                </div>
                <Checkbox.Group
                  className="space-y-3"
                  style={{width: '100%'}}
                  value={contentLanguages}
                  onChange={checkedValues => {
                    if (
                      checkedValues.length >
                      following_languages_maximum
                    ) {
                      Modal.warning({
                        okText: t('got it'),
                        okButtonProps: {
                          className: 'rounded-lg no-shadow no-text-shadow',
                        },
                        title: (
                            <span className="font-bold text-color-000">{t('Hello you')}</span>
                        ),
                        content: (
                            <span className="text-color-300 italic">
                              {t('Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you')}
                            </span>
                        ),
                      })
                      return
                    }
                    changeContentLang(
                      checkedValues.join(",")
                    )

                    setContentLanguages(
                      checkedValues
                    )
                    setIndeterminate(
                      !!checkedValues.length &&
                      checkedValues.length <
                      langArray.length
                    )
                    setCheckAll(
                      checkedValues.length ===
                      langArray.length
                    );
                  }}>
                  <React.Fragment>
                    {langArray.map(
                      (value, key) => (
                        <div key={key}>
                          <Checkbox
                            key={key}
                            value={value}
                            className={classnames(
                              'flex items-center hover:background-200 px-3 custom-checkbox-lang',
                              _.includes(contentLanguages, value) ? 'text-primary font-bold' : 'text-color-100'
                            )}>
                            <div className="flex justify-center items-center px-3">
                              <FlagIcon
                                  type={value}
                                  style={{width: '1.5rem', height:'1.5rem'}}
                              />
                              <div className="px-3">
                                {t(value)}
                              </div>
                            </div>
                          </Checkbox>
                        </div>
                      )
                    )}
                  </React.Fragment>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
