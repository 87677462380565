import {EllipsisOutlined,} from '@ant-design/icons'
import {Avatar, Menu, Tooltip} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext,} from 'react'
import {IoShareSocialOutline} from 'react-icons/io5'
import {renderIf, renderOwnChild,} from 'views/Shared'
import {WishareEntities} from 'views/Wishare/enums'
import ItemTemplate, {OverlayItemBanner,} from 'views/Wishare/Templates/ItemTemplate'
import DrawMenu from '../../../../components/drawer/DrawMenu'
import {useAppConfig} from '../../../../modules/local'
import getAvatar from "../../../../helpers/getAvatar";
import getTitle from "../../../../helpers/getTitle";
import {FollowButton} from "../../../../components/Feed/FollowButton";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {copyLink} from "../../../../helpers";
import {ROOT_URL} from "../../../../envs/_current/config";
import {VoteButton} from "../../../../components/Feed/VoteButton";
import VerifiedSvg from "../../../../components/Svgs/VerifiedSvg";
import {BiLinkAlt} from "react-icons/bi";


export const OrganizationCompactItem = ({item}) => {
  const t = useTranslate()

  const {isSm} = useContext(LayoutContext)

  const {org_types = {}} = useAppConfig()

  const org_type = _.get(
    org_types,
    _.get(item, 'org_type', '900')
  )

  const verified = _.get(item, 'certified')

  return (
    <div className="relative w-full border border-color-50 rounded-lg background">
      <OverlayItemBanner type={WishareEntities.ORGANIZATION}/>
      <div className="h-7"/>
      <div className="flex items-center gap-3 px-3 py-2">
        <Avatar className="background-100" src={getAvatar(item)}>
            <span className="capitalize text-color-100">
                {_.first(_.get(item, 'title'))}
            </span>
        </Avatar>
        <div className="flex flex-col md:flex-row flex-1">
            <div className="flex flex-col flex-1">
                <div className="flex items-center">
                    <Link
                        to={getLinkToDetail(item)}
                        className="cursor-pointer font-bold leading-tight max-lines-1">
                        {getTitle(item)}
                    </Link>
                    {verified === 1 && (
                        <Tooltip title={t('verified')} placement="right" color="#87d068">
                            <VerifiedSvg width={16} height={16} className="ml-1 zoom-animation"/>
                        </Tooltip>
                    )}
                </div>
                <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
                    {t(org_type)}
                </span>
            </div>
            <div className="flex mt-2 md:mt-0">
                <div className="flex-1"/>
                <FollowButton
                  size={isSm ? 'small' : 'default'}
                  item={item}
                />
            </div>
        </div>
      </div>
    </div>
  )
}

const OrganizationItem = ({item}) => {
  const t = useTranslate()

  const {org_types = {}} =
    useAppConfig()

  const org_type = _.get(
    org_types,
    _.get(item, 'org_type', '900')
  )

  const renderSubTitle = useCallback(
    (Wrapper = renderOwnChild) =>
      renderIf(
        _.get(item, 'org_type', 900),
        <Wrapper>
          <span className="text-secondary text-sm italic">
            {t(org_type)}
          </span>
        </Wrapper>
      ),
    [t, item, org_type]
  )

  const renderHeader = useCallback(
    () => (
      <div className="flex justify-end pb-2">
        <DrawMenu
          component={
            <div className="px-1 cursor-pointer rounded-full background hover:background-200 my-auto">
              <EllipsisOutlined className="my-auto cursor-pointer text-color-300 hover:text-color-100"/>
            </div>
          }>
          {(__, toggle) => (
            <Menu onClick={toggle} style={{background: 'var(--background)'}}>
              <Menu.Item>
                <div onClick={() => {
                  copyLink(`${ROOT_URL}${getLinkToDetail(item)}`, t('copied'), t('can not copy that link'));
                }}
                     className="flex items-center px-3 hover:text-primary">
                  <BiLinkAlt/>
                  <span className="text-center ml-2">
                    {t('copy path')}
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          )}
        </DrawMenu>
      </div>
    ),
    [t]
  )

  const total_votes =
    _.get(item, 'total_votes') || 0

  const renderFooter =
    useCallback(() => {
      return (
        <div className="flex items-center justify-between">
          <span className="text-xs text-color-400 leading-tight">
            <span className="text-primary font-bold mr-1">
              {String(total_votes)}
            </span>
            {t('vote')}
          </span>
          <div className="space-x-6">
            <IoShareSocialOutline
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: item.title,
                      url: `${ROOT_URL}${getLinkToDetail(item)}`,
                    })
                    .then(() =>
                      console.log(
                        'Successful share'
                      )
                    )
                    .catch((error) =>
                      console.log(
                        'Error sharing',
                        error
                      )
                    )
                } else {
                  alert(
                    `your device don't support this!`
                  )
                }
              }}
              className="my-auto cursor-pointer text-color-300 hover:text-primary"
            />

            <VoteButton item={item}/>
          </div>
        </div>
      )
    }, [t, total_votes])

  return (
    <ItemTemplate
      item={item}
      image={_.get(item, 'cover_photo')}
      renderExtra={renderSubTitle}
      overlay={
        <OverlayItemBanner
          type={
            WishareEntities.ORGANIZATION
          }
        />
      }
      Header={renderHeader}
      Footer={renderFooter}>
      <div className="max-lines-2 font-light text-sm">
        {item.description}
      </div>
    </ItemTemplate>
  )
}

export default OrganizationItem
