import { feed_fetchFeedByContainer_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import InputBoardForm from 'components/Feed/InputBoard/InputBoardForm'
import Pure from 'components/Pure'
import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {
  useContext,
} from 'react'
import { nest } from 'recompose'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import { Null } from 'views/Shared'
import EntityList from 'views/Wishare/EntityList'
import {
  bindQueryParams,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import GivingContext from '../GivingContext'

const GivingNews = ({
  sample,
  keyword,
  children,
  filtered_by,
  Footer = Null,
  Wrapper = 'div',
  ...props
}) => {
  const { giving } = useContext(
    GivingContext
  )

  const [id, prop] = [
    getId(giving),
    getType(giving),
  ]

  const apiInfo =
    feed_fetchFeedByContainer_Api

  const query = bindQueryParams([
    { id },
    { prop },
  ])

  const values = {
    keyword,
    filtered_by,
  }

  const lookupKey = [
    mapPathParam(
      mapPathParam(apiInfo.path, {
        id,
      }),
      { prop }
    ),
  ].join('/')

  const refreshKey = JSON.stringify({
    query,
    values,
  })

  if (_.isEmpty(giving)) return null

  const { available_actions } = giving

  return (
    <Wrapper className="space-y-3">
      <PermissionProvider
        permissions={{
          [permissionCodes.canPost]:
            _.get(
              available_actions,
              permissionCodes.canPost
            ),
        }}>
        <InputBoardForm
          container={{ id, type: prop }}
        />
      </PermissionProvider>
      <EntityList
        query={query}
        sample={sample}
        values={values}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        refreshKey={refreshKey}
        renderItem={renderFeedItem}
        renderWrap={(
          child,
          __,
          count
        ) => (
          <div className="space-y-3">
            {child}
            {count ? <Footer /> : null}
          </div>
        )}
        {...props}
      />
    </Wrapper>
  )
}

export default nest(Pure, GivingNews)
