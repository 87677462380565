import {
  Descriptions,
  Form,
  Input,
  InputNumber,
} from 'antd'
import { search_lookkup_getByType_Api } from 'apis'
import { createValue } from 'components/form/utils'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  deepTranslate,
  Null,
} from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import FormListHeader from 'views/Wishare/custom/FormListHeader'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from 'views/Wishare/wishareFieldTypes'
import '../../../Shared/styles/FormList.css'

const formFields = {
  item_name: {
    name: 'item_name',
    placeholder: 'select item',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'item_name' },
    }),
  },
  reg_giving_qtty: {
    name: 'reg_giving_qtty',
    placeholder: 'amount',
    getStep: (type) =>
      _.get(
        wishareFieldTypes.type_cds,
        `${type}.step`,
        1
      ),
  },
  reg_giving_value: {
    name: 'reg_giving_value',
    placeholder: 'value',
    getStep: (type) =>
      _.get(
        wishareFieldTypes.type_cds,
        `${type}.step`,
        1
      ),
  },
  unit_name: {
    name: 'unit_name',
    placeholder: 'select unit',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'unit_name' },
    }),
  },
}

export const GivingItemFieldsContext =
  React.createContext({})

export const GivingItemFieldsProvider =
  ({
    type_cd,
    type_cds,
    children,
    optionName,
    getGroup = () => [],
    ...props
  }) => {
    const t = useTranslate()

    const options = useMemo(() => {
      const source = Array.from(
        type_cds || []
      ).find(
        ({ value }) => value === type_cd
      )
      const result = getGroup(source)
      return result.map(
        deepTranslate(t)
      )
    }, [t, getGroup, type_cd, type_cds])

    const defaultType = useMemo(
      () =>
        getDefaultFieldValue(options),
      [options]
    )

    const groupBy = useMemo(() => {
      return {
        options,
        defaultType,
      }
    }, [options, defaultType])

    const fromGroup = useCallback(
      (type) => {
        const source =
          wishareFieldTypes.type_cds
        switch (type) {
          case source.person.value:
            return {
              fieldNames: [
                formFields
                  .reg_giving_qtty.name,
                formFields.unit_name
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
            }
          case source.goods.value:
            return {
              fieldNames: [
                formFields
                  .reg_giving_qtty.name,
                formFields.unit_name
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
            }
          case source.money.value:
            return {
              fieldNames: [
                formFields
                  .reg_giving_qtty.name,
                formFields.unit_name
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
              resourceParams: {
                creatable: false,
              },
            }
          case source.blood.value:
            return {
              fieldNames: [
                formFields
                  .reg_giving_qtty.name,
                formFields.unit_name
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
              resourceParams: {
                creatable: false,
              },
            }
          default:
            return {
              fieldNames: [],
            }
        }
      },
      []
    )

    return (
      <GivingItemFieldsContext.Provider
        value={{
          ...props,
          groupBy,
          type_cd,
          type_cds,
          fromGroup,
        }}>
        <AntdConfigWrapper>
          {children}
        </AntdConfigWrapper>
      </GivingItemFieldsContext.Provider>
    )
  }

const DefaultValueInput = ({
  disabled,
  step = 1,
  value = 0,
  placeholder,
  onChange = Null,
  ...props
}) => {
  const t = useTranslate()
  return (
    <InputNumber
      min={0}
      value={value}
      step={step}
      style={{
        padding: '3px 0',
        borderColor:
          'inherit!important',
      }}
      disabled={disabled}
      onChange={onChange}
      parser={parseNumber}
      className="w-full rounded FormList"
      formatter={formatterNumber}
      placeholder={t(placeholder)}
      {...props}
    />
  )
}

export const GivingNotes = React.memo(
  () => {
    const t = useTranslate()
    const description = 'giving note'
    return (
      <Descriptions>
        <Descriptions.Item
          label={
            <span className="font-semibold">
              {t('note')}
            </span>
          }>
          {description}
        </Descriptions.Item>
      </Descriptions>
    )
  }
)

const GroupWrapper = ({
  index,
  options,
  children,
  onRemove,
  defaultValue,
  onChange = Null,
  prefixTitle = 'group',
}) => {
  const t = useTranslate()

  const {
    item_name,
    unit_name,
    giving_value,
    target,
    reg_giving_qtty,
    is_read_only = false,
    no_limit_flag,
  } = defaultValue || {}

    let subTitle
    switch (_.get(defaultValue, 'type_cd')) {
        case wishareFieldTypes.type_cds.money.value:
            subTitle = 'Give to beneficiary by cash or bank transfer'
            break;
        case wishareFieldTypes.type_cds.goods.value:
            subTitle = 'Give the beneficiary with the items'
            break;
        default:
            subTitle = ''
    }

  const defaultType = _.get(
    defaultValue,
    'type_cd'
  )

  useEffect(() => {
    onChange(
      createValue(
        'type_cd',
        defaultType
      )
    )
  }, [])

  return (
    <div style={{whiteSpace: 'pre-wrap'}} className="p-3 border border-color-50 background rounded-lg space-y-2">
      <FormListHeader
        title={
          <span className="text-primary font-bold">
            {t(item_name)}
          </span>
        }
        onRemove={onRemove}
      />
        {
            defaultType !== wishareFieldTypes.type_cds.blood.value ?
                <div className="text-xs text-color-100 font-light italic leading-tight">
                  {`* ${t(subTitle)}`}
              </div> : null
        }
      {!!target && (
        <div className="flex flex-1 items-center">
          <div className="flex items-center justify-between w-full gap-2">
            <div className="space-x-1">
              <span className="text-color-400 italic">
                {t('target')} :{' '}
              </span>
              {
                no_limit_flag ?  t('no limit') : (
                  <React.Fragment>
              <span className="font-semibold text-color-000">
                {Number(target).toLocaleString()}
              </span>
                    <span className="font-semibold text-color-000">
                {unit_name}
              </span>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      )}
      {_.isFunction(children)
        ? children(defaultType)
        : children}
    </div>
  )
}

export const GivingItemField = ({
  index,
  remove,
  onChange,
  ...props
}) => {
  const t = useTranslate()

  const current =
    _.get(props, 'value') || {}

  const {
    groupBy,
    prefixTitle,
    fromGroup = Null,
  } = useContext(
    GivingItemFieldsContext
  )

  const { options, defaultType } =
    groupBy || {}

  const {
    item_name,
    unit_name,
    target,
    no_limit_flag,
    is_read_only,
    giving_qtty,
    giving_value,
    reg_giving_qtty,
    reg_giving_value,
    type_cd = defaultType,
  } = current

  const triggerChanges = useCallback(
    (values = {}) => {
      onChange({
        ...current,
        ...values,
      })
    },
    [onChange, current]
  )

  const invalidField = useCallback(
    (name, group_type) => {
      const {
        fieldNames = [],
        resourceParams,
      } = fromGroup(group_type) || {}
      return [
        !_.includes(fieldNames, name),
        resourceParams,
      ]
    },
    [fromGroup]
  )

  const handleChange = useCallback(
    (name, event) => {
      const value = _.get(
        event,
        'target.value'
      )
      triggerChanges({ [name]: value })
    },
    [triggerChanges]
  )

  const renderResouceField =
    useCallback(
      (name, value, group_type) => {
        const [
          invalid,
          resourceParams = {},
        ] = invalidField(
          name,
          group_type
        )
        if (invalid) {
          return null
        }
        const {
          createAsyncParams = Null,
          ...rest
        } =
          _.get(formFields, name) || {}

        const params =
          createAsyncParams({
            group_type,
          }) || {}

        return (
          <FlexCol className="GridFlex RequiredInp">
            <Form.Item
              name={[index, name]}
              rules={[
                {
                  required: true,
                  message: t(
                    'required field'
                  ),
                },
              ]}>
              <Input
                value={value}
                disabled={true}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                }}
              />
            </Form.Item>
          </FlexCol>
        )
      },
      [
        t,
        index,
        invalidField,
        triggerChanges,
      ]
    )

  const renderInputField = useCallback(
    ({
      name,
      value,
      group_type,
      ...rest
    }) => {
      const [invalid] = invalidField(
        name,
        group_type
      )
      if (invalid) {
        return null
      }
      const { getStep = Null } =
        _.get(formFields, name) || {}
      const step = getStep(group_type)
      return (
        <FlexCol className="GridFlex RequiredInp">
          <Form.Item
            name={[index, name]}
            required={true}
            requiredMark={true}
            rules={[
              {
                min: 1,
                required: true,
                type: 'number',
                message: t(
                  'required field'
                ),
              },
            ]}>
            <DefaultValueInput
              key={JSON.stringify([
                name,
                group_type,
              ])}
              step={step}
              value={value}
              onChange={(value) => {
                triggerChanges({
                  [name]: Number(value),
                })
              }}
              {...rest}
            />
          </Form.Item>
        </FlexCol>
      )
    },
    [
      t,
      index,
      invalidField,
      triggerChanges,
    ]
  )

  return (
    <GroupWrapper
      index={index}
      options={options}
      onRemove={remove}
      defaultValue={{
        type_cd,
        item_name,
        unit_name,
        target,
        no_limit_flag,
        giving_value,
        reg_giving_value,
        giving_qtty,
        reg_giving_qtty,
        is_read_only,
      }}
      prefixTitle={prefixTitle}
      onChange={(event) => {
        handleChange('type_cd', event)
      }}>
      {(group_type) => (
        <div className="CustomGrid grid md:grid-cols-2 items-start gap-2">
          {renderResouceField(
            formFields.item_name.name,
            item_name,
            group_type
          )}
          {renderInputField({
            name: formFields
              .reg_giving_value.name,
            value: reg_giving_value,
            group_type,
            addonBefore: t('value'),
            addonAfter: unit_name,
            style: {
              borderRadius:
                '0!important',
            },
            className: 'Inp w-full',
          })}
          {renderInputField({
            name: formFields
              .reg_giving_qtty.name,
            disabled: !!is_read_only,
            value: reg_giving_qtty,
            group_type,
            addonBefore: t('quantity'),
            addonAfter: unit_name,
            style: {
              borderRadius:
                '0!important',
            },
            className: 'Inp w-full',
          })}
        </div>
      )}
    </GroupWrapper>
  )
}

export default GivingItemField
