import {ConfigProvider, Empty, Select} from 'antd'
import {category_getCategories_Api} from 'apis'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import LoadingPage from 'views/LoadingPage'
import {useAppConfig} from "../../../modules/local";

const customizeRenderEmpty = () => (
  <Empty
    imageStyle={{
      height: 60
    }}
    description={
      <span>
        Customize{' '}
        <a href="#API">Description</a>
      </span>
    }></Empty>
)
export const CategoriesSelect = ({
  value,
                                   options = [],
  onChange = Null,
  apiInfo = category_getCategories_Api
}) => {
  const t = useTranslate()
  const {
    isLoading,
    success,
    action,
    result,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: apiInfo
  })
  const {categories = []} = useAppConfig();
  // const categories = useSelector(
  //   state =>
  //     action.apiInfo
  //       ? selectEntities(
  //           state,
  //           result,
  //           action.apiInfo.schema
  //         )
  //       : []
  // )
  const isEmpty = _.isEmpty(categories)

  return (
    <ConfigProvider
      renderEmpty={
        customizeRenderEmpty
      }>
      <div>
        <Select
          optionFilterProp="label"
          defaultOpen={false}
          value={value}
          notFoundContent={
            isEmpty ? (
              <LoadingPage />
            ) : null
          }
          loading={isEmpty}
          // onFocus={
          //   isEmpty
          //     ? () => {
          //         handleAsyncAction()
          //       }
          //     : () => null
          // }
          mode="multiple"
          placeholder={t(
            'please select'
          )}
          onChange={onChange}
          style={{ width: '100%' }}>
          {categories.map(({id, name}) => (
            <Select.Option
              key={id}
              value={id}
              label={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </ConfigProvider>
  )
}
