import { Avatar, Image } from 'antd'
import { ROOT_URL } from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const StaffBanner = ({
  staff,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { employee_type } = staff || {}

  return (
    <Wrapper className="flex flex-col">
      <Image
        preview={false}
        src={`${ROOT_URL}/images/default/green-bg.jpg`}
        className="fadeIn animated banner-activity object-cover bg-color-org"
      />
      <div className="absolute flex items-center w-full mx-auto">
        <div
          style={{
            background:
              'radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
          }}
          className="flex flex-col items-center justify-center w-full px-3 banner-activity">
          <Avatar
            className="flex flex-center background-100"
            size={50}
            src={getAvatar(staff)}
            alt={getTitle(staff)}>
            <span className="text-color-200 font-medium">
              {_.first(getTitle(staff))}
            </span>
          </Avatar>
          <div className="px-3 text-xl md:text-3xl font-bold max-w-4xl text-center text-white my-0 md:my-3 leading-tight">
            <span>
              {getTitle(staff)}
            </span>
          </div>
          {employee_type && (
            <span className="uppercase text-sm text-white opacity-75 font-medium tracking-wide">
              {t(employee_type)}
            </span>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default StaffBanner
