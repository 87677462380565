import { Input } from 'antd'
import { organization_Edit_Api } from 'apis'
import classNames from 'classnames'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { FB_ID } from 'envs/_current/config'
import { Field, Form } from 'formik'
import _ from 'lodash'
import { getCacheSelector } from 'modules/asyncCache/selectors'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import {
  compose,
  mapProps,
  pure,
} from 'recompose'
import LinkedPages from 'views/Organization/Settings/Profile/LinkedPages'
import {
  Null,
  shallowDiff,
} from 'views/Shared'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import OrganizationContainerContext from '../OrganizationContainerContext'

const withSelectWidget =
  (Component) =>
  ({
    name,
    value,
    data_object,
    onChange = Null,
    withProps = () => ({}),
  }) => {
    const t = useTranslate()
    const options = Object.keys(
      data_object
    ).map((key) => ({
      value: key,
      label: data_object[key],
    }))
    return (
      <Component
        {...{
          name,
          options,
          showSearch: true,
          allowClear: true,
          className: 'w-full',
          optionFilterProp: 'label',
          value: value || undefined,
          placeholder: t(
            '---Choose One---'
          ),
          onChange: (value) =>
            onChange(
              createValue(
                name,
                value || null
              )
            ),
          ...(withProps() || {}),
        }}
      />
    )
  }

const formEntities = {
  linked_pages: {
    title: 'Linked Pages',
    name: 'linked_pages',
    component: compose(
      mapProps(
        ({
          name,
          value,
          onChange,
        }) => ({
          data: value,
          classNames: [
            'flex items-center',
          ],
          renderWrapper: ({
            children,
          }) => (
            <div className="flex flex-col gap-3">
              {children}
            </div>
          ),
          onChange: (value) => {
            onChange(
              createValue(name, value)
            )
          },
        })
      ),
      pure
    )(LinkedPages),
  },
  number_of_employees: {
    title: 'Number of Employees',
    name: 'number_of_employees',
    component: compose(
      connect((state) => ({
        data_object: _.get(
          getCacheSelector(
            state,
            'appConfig'
          ),
          'number_of_employees',
          []
        ),
      })),
      withSelectWidget,
      pure
    )(SelectWidget),
  },
  established_year: {
    title: 'Year of Establishment',
    name: 'established_year',
    component: compose(
      mapProps(
        ({ onChange, value }) => ({
          onChange,
          value,
          name: 'established_year',
          placeholder: 'YYYY',
          maxLength: 4,
          type: 'number',
          inputMode: 'number',
        })
      ),
      pure
    )(Input),
  },
  business_type: {
    title: 'Nature of Business',
    name: 'business_type',
    component: compose(
      connect((state) => ({
        data_object: _.get(
          getCacheSelector(
            state,
            'appConfig'
          ),
          'business_types',
          []
        ),
      })),
      withSelectWidget,
      pure
    )(SelectWidget),
  },
  company_type: {
    title: 'Legal Status of Firm',
    name: 'company_type',
    component: compose(
      connect((state) => ({
        data_object: _.get(
          getCacheSelector(
            state,
            'appConfig'
          ),
          'company_types',
          []
        ),
      })),
      withSelectWidget,
      pure
    )(SelectWidget),
  },
  annual_turnover: {
    title: 'Annual Turnover',
    name: 'annual_turnover',
    component: compose(
      connect((state) => ({
        data_object: _.get(
          getCacheSelector(
            state,
            'appConfig'
          ),
          'annual_turnover',
          []
        ),
      })),
      withSelectWidget,
      pure
    )(SelectWidget),
  },
  website: {
    title: 'website',
    name: 'website',
    component: compose(
      mapProps((props) => ({
        placeholder:
          'www.yourwebsite.com...',
        inputMode: 'url',
        ...props,
      })),
      pure
    )(Input),
  },
  facebook: {
    title: 'facebook',
    name: 'facebook',
    component: compose(
      mapProps((props) => ({
        placeholder: 'FB url...',
        inputMode: 'url',
        ...props,
      })),
      pure
    )(Input),
  },
  linkedin: {
    title: 'linkedin',
    name: 'linkedin',
    component: compose(
      mapProps((props) => ({
        placeholder: 'LinkedIn url...',
        inputMode: 'url',
        ...props,
      })),
      pure
    )(Input),
  },
  twitter: {
    title: 'twitter',
    name: 'twitter',
    component: compose(
      mapProps((props) => ({
        placeholder: 'Twitter url...',
        inputMode: 'url',
        ...props,
      })),
      pure
    )(Input),
  },
}

const OrganizationSettingProfile =
  () => {
    const t = useTranslate()
    const { organization: item } =
      useContext(
        OrganizationContainerContext
      )

    const {
      number_of_employees,
      established_year,
      business_type,
      company_type,
      annual_turnover,
      website,
    } = item

    const initialValues = {
      tax_code: item.tax_code,
      country_id:
        item.country_id || 237,
      video_url: item.video_url,
      number_of_employees:
        '' + number_of_employees,
      established_year:
        '' + established_year,
      business_type: '' + business_type,
      company_type: '' + company_type,
      annual_turnover:
        '' + annual_turnover,
      website: '' + website,
      facebook: item.facebook,
      linkedin: item.linkedin,
      twitter: item.twitter,
      telegram: item.telegram,
      linked_pages: item.linked_pages,
    }
    return (
      <div className="p-3">
        <ApiInfoForm
          initialValues={initialValues}
          query={{
            ':id': item.id,
          }}
          onPreSubmit={(values) => {
            const changes = shallowDiff(
              values,
              initialValues
            )
            return _.omitBy(
              {
                ...changes,
                linked_pages: _.has(
                  changes,
                  'linked_pages'
                )
                  ? JSON.stringify(
                      changes.linked_pages
                    )
                  : undefined,
              },
              _.isUndefined
            )
          }}
          apiInfo={
            organization_Edit_Api
          }>
          {({
            values,
            errors,
            handleChange,
          }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 items-start gap-3 m-auto">
              {[
                [
                  formEntities.established_year,
                ],
                [
                  formEntities.number_of_employees,
                ],
              ].map((er, ir) => (
                <div
                  key={ir}
                  className={classNames(
                    'col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-2',
                    er.length > 1
                      ? 'col-span-2'
                      : 'col-span-1'
                  )}>
                  {er.map(
                    (entity, ic) => {
                      const renderEntity =
                        (entity) => {
                          if (!entity)
                            return null
                          const C =
                            entity.component
                          return (
                            <Field
                              key={ic}
                              name={
                                entity.name
                              }>
                              {({
                                field,
                                ...rest
                              }) => {
                                return (
                                  <FieldDecorator
                                    className="col-span-1"
                                    label={
                                      entity.title
                                    }>
                                    <C
                                      {...field}
                                      {...rest}
                                    />
                                  </FieldDecorator>
                                )
                              }}
                            </Field>
                          )
                        }
                      return (
                        <React.Fragment
                          key={ic}>
                          {renderEntity(
                            entity
                          )}
                        </React.Fragment>
                      )
                    }
                  )}
                </div>
              ))}

              <FieldDecorator
                className="w-full col-span-2"
                name="video url"
                errors={
                  errors.video_url
                }
                label={t('video url')}>
                <Input
                  rows={4}
                  inputMode="url"
                  name="video_url"
                  defaultValue={
                    values.video_url
                  }
                  onChange={
                    handleChange
                  }
                />
                {Boolean(
                  values.video_url &&
                    ReactPlayer.canPlay(
                      values.video_url
                    )
                ) && (
                  <ReactPlayer
                    className=" mt-2 background-200 border border-gray-300 shadow"
                    style={{
                      fontSize: '1em',
                    }}
                    width="100%"
                    height={400}
                    url={
                      values.video_url
                    }
                    config={{
                      youtube: {
                        playerVars: {
                          controls: 1,
                          showinfo: 1,
                        },
                      },
                      facebook: {
                        appId: FB_ID,
                      },
                    }}
                  />
                )}
              </FieldDecorator>

              {[
                [formEntities.website],
              ].map((er, ir) => (
                <div
                  key={ir}
                  className={classNames(
                    'w-full col-span-2'
                  )}>
                  {er.map(
                    (entity, ic) => {
                      const renderEntity =
                        (entity) => {
                          if (!entity)
                            return null
                          const C =
                            entity.component
                          return (
                            <Field
                              key={ic}
                              name={
                                entity.name
                              }>
                              {({
                                field,
                                ...rest
                              }) => {
                                return (
                                  <FieldDecorator
                                    label={
                                      entity.title
                                    }>
                                    <C
                                      {...field}
                                      {...rest}
                                    />
                                  </FieldDecorator>
                                )
                              }}
                            </Field>
                          )
                        }
                      return (
                        <React.Fragment
                          key={ic}>
                          {renderEntity(
                            entity
                          )}
                        </React.Fragment>
                      )
                    }
                  )}
                </div>
              ))}

              {[
                formEntities.linked_pages,
              ].map((page, pIndex) => {
                return (
                  <div
                    key={pIndex}
                    className="col-span-2 md:col-span-2 grid grid-cols-2 md:grid-cols-2 gap-2">
                    <Field
                      name={page.name}>
                      {({
                        field,
                        ...rest
                      }) => {
                        const C =
                          page.component
                        return (
                          <FieldDecorator
                            className="col-span-2"
                            label={
                              page.title
                            }>
                            <C
                              {...field}
                              {...rest}
                            />
                          </FieldDecorator>
                        )
                      }}
                    </Field>
                  </div>
                )
              })}

              <FormActionBar className="col-span-1 lg:col-span-2" />
            </Form>
          )}
        </ApiInfoForm>
      </div>
    )
  }

export default OrganizationSettingProfile
