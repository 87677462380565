import {API_ROOT_URL} from 'envs/_current/config'
import {baseFieldTypes} from './base/baseFieldTypes'
import {hostSchema} from './schema'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:givings,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  idname: baseFieldTypes.string,
}

export const host_getsbyOwner_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-by-object',
  method: 'GET',
  fields,
  fieldTypes,
  paramsFields: [
    'status',
    'request_status',
    'sort_by',
    'keyword',
    'host_type',
  ],
  schema: hostSchema,
  transform:
    createListTransform(hostSchema),
}

export const host_approve_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/approve',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_reject_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/reject',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_add_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/add',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/:host_id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_leaveHost_api = {
  backend: `Route::post('hosts/host/{host_id}/leave', 'HostController@leaveHost');`,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/leave',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_resendAddHost_api = {
  backend: `Route::post('hosts/{prop}/{id}/{host_id}/re-request', 'HostController@resendAddHost');`,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/:host_id/re-request',
  method: 'POST',
  fields,
  fieldTypes,
  schema: hostSchema,
  transform: createTransform(
    hostSchema,
    'data'
  ),
}

export const host_getsByHost_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-by-host',
  method: 'GET',
  fields,
  fieldTypes,
  paramsFields: [
    'status',
    'request_status',
    'owner_type',
    'sort_by',
    'keyword',
    'subcategories'
  ],
  schema: hostSchema,
  transform:
    createListTransform(hostSchema),
}

export const host_searchHostsByOwner_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/search/hosts-by-owner/:prop/:id',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: hostSchema,
    transform:
      createListTransform(hostSchema),
  }

export const host_getPeerMembers_Api = {
  backend: `Route::get('hosts/{prop}/{id}/gets-peer-members', 'HostController@getPeerMembers');`,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-peer-members',
  method: 'GET',
  paramsFields: [
    'status',
    'sort_by',
    'keyword',
    'owner_type',
    'subcategories'
  ],
  schema: hostSchema,
  transform:
    createListTransform(hostSchema),
}

export const host_getHostingStats_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-host-stats', 'OrganizationInsightsController@getHostingStats');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-host-stats',
    method: 'GET',
    paramsFields: ['period'],
  }

export const host_getActivitySummary_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-activity-summary', 'OrganizationHostsController@getActivitySummary');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-activity-summary',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }

export const host_getDonationSummary_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-donation-summary', 'OrganizationHostsController@getDonationSummary'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-donation-summary',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getGivingSummary_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-giving-summary', 'OrganizationHostsController@getGivingSummary'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-giving-summary',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getStaffSummary_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-staff-summary', 'OrganizationHostsController@getStaffSummary');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-staff-summary',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }


export const host_getActivitySummaryOfPeerOrgs_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-activity-summary-of-peer', 'OrganizationHostsController@getActivitySummaryByPeer');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-activity-summary-of-peer',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }

export const host_getDonationSummaryOfPeerOrgs_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-donation-summary-of-peer', 'OrganizationHostsController@getDonationSummaryByPeer'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-donation-summary-of-peer',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getGivingSummaryOfPeerOrgs_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-giving-summary-of-peer', 'OrganizationHostsController@getGivingSummaryByPeer'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-giving-summary-of-peer',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getStaffSummaryOfPeerOrgs_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-staff-summary-of-peer', 'OrganizationHostsController@getStaffSummaryByPeer');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-staff-summary-of-peer',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }

export const host_getActivitySummaryOnOrg_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-activity-summary-on-org', 'OrganizationHostsController@getActivitySummaryOnOrg');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-activity-summary-on-org',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }

export const host_getDonationSummaryOnOrg_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-donation-summary-on-org', 'OrganizationHostsController@getDonationSummaryOnOrg'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-donation-summary-on-org',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getGivingSummaryOnOrg_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-giving-summary-on-org', 'OrganizationHostsController@getGivingSummaryOnOrg'); // 'currency_code'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-giving-summary-on-org',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
      'currency_code',
    ],
  }

export const host_getStaffSummaryOnOrg_Api =
  {
    backend: `Route::get('organization/{organization_id}/hosts/get-staff-summary-on-org', 'OrganizationHostsController@getStaffSummaryOnOrg');`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/hosts/get-staff-summary-on-org',
    method: 'GET',
    paramsFields: [
      'sort_by',
      'keyword',
    ],
  }
