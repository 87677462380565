import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  { name: 'obligations' },
]

const formSchema = [
  {
    title: 'responsibilities',
    children: [
      {
        name: 'obligations',
        component:
          createControlledFormField({
            placeholder:
              'responsibilities',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
]

const RecruitmentSettingJobResponsibilities =
  () => {
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema = undefined

    const recruitment_id = getId(
      recruitment
    )

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingJobResponsibilities
