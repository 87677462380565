import {Avatar, Input} from 'antd'
import {FollowButton} from 'components/Feed/FollowButton'
import {VoteButton} from 'components/Feed/VoteButton'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {ROOT_URL} from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext,} from 'react'
import {IoFlowerOutline, IoGiftOutline, IoSettingsOutline, IoShareSocialOutline,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {nest} from 'recompose'
import {renderIf} from 'views/Shared'
import {EntityContext, EntityProvider,} from 'views/Wishare/EntityList'
import {WishareEntities} from 'views/Wishare/enums'
import {ContextActionTypes, ItemContextMenu,} from 'views/Wishare/factory/createContextMenu'
import ItemTemplate, {OverlayItemBanner,} from 'views/Wishare/Templates/ItemTemplate'
import {BiLinkAlt} from "react-icons/bi";
import numeral from 'numeral'
import classNames from "classnames";

export const ActivityCompactItem = ({
                                      item,
                                    }) => {
  const t = useTranslate()
  const {isSm} = useContext(
    LayoutContext
  )

  const indexedBy = _.get(
    item,
    'indexed_by_wishare'
  )

  return (
    <div className="relative w-full border border-color-50 rounded-lg background">
      <OverlayItemBanner
        type={WishareEntities.ACTIVITY}
      />
      <div className="h-7"/>
      <div className="flex items-center gap-3 px-3 py-2">
        <Avatar className="background-100" src={getAvatar(item)}>
          <span className="capitalize text-color-100">
              {_.first(_.get(item, 'title'))}
          </span>
        </Avatar>
        <div className="flex flex-col md:flex-row flex-1">
            <div className="flex flex-col flex-1">
                <Link
                    to={getLinkToDetail(item)}
                    className="cursor-pointer font-bold leading-tight max-lines-1">
                    {getTitle(item)}
                </Link>
                <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
                    {indexedBy ? (
                        <span className="text-color-400">
                          {t('indexed by wishare')}
                        </span>
                    ) : (
                        _.get(item, 'owner.title')
                    )}
                </span>
            </div>
            <div className="flex mt-2 md:mt-0">
                <div className="flex-1"/>
                <FollowButton
                    size={
                        isSm
                            ? 'small'
                            : 'default'
                    }
                    item={item}
                />
            </div>
        </div>
      </div>
    </div>
  )
}

const ItemHeader = ({
  withMenuOptions = (item) =>
    [
      {
        key: ContextActionTypes.COPY,
        label: 'copy path',
        icon: <BiLinkAlt/>,
      },
      !!_.get(item, 'edit', false)
        ? {
          key: ContextActionTypes.SETTINGS,
          label: 'setting',
          icon: <IoSettingsOutline/>,
        }
        : undefined,
    ].filter((e) => !_.isUndefined(e)),
}) => {
  const {item, handleAction} =
    useContext(EntityContext)

  const impact_categories = Array.from(
    _.get(item, 'impact_categories') ||
    []
  )

  return (
    <div className="flex items-center justify-between pb-2">
      <div className="space-x-2">
        {impact_categories.map(
          (category, index) => (
            <Avatar
              size={20}
              key={index}
              shape="square"
              className="rounded-md"
              src={getAvatar(category)}
            />
          )
        )}
      </div>
      <ItemContextMenu
        item={item}
        onMenuSelect={handleAction}
        items={withMenuOptions(item)}
      />
    </div>
  )
}

const ItemFooter = () => {
  const {item} = useContext(
    EntityContext
  )
  const total_votes =
    _.get(item, 'total.votes') || 0

  return (
    <Translate>
      {(t) => (
        <div className="flex items-center justify-between">
          <span className="text-xs text-color-400 leading-tight">
            <span className="text-primary font-bold mr-1">
              {String(total_votes)}
            </span>
            {t('vote')}
          </span>
          <div className="space-x-6">
            <IoShareSocialOutline
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: item.title,
                      url: `${ROOT_URL}${getLinkToDetail(
                        item
                      )}`,
                    })
                    .then(() =>
                      console.log(
                        'Successful share'
                      )
                    )
                    .catch((error) =>
                      console.log(
                        'Error sharing',
                        error
                      )
                    )
                } else {
                  alert(
                    `your device don't support this!`
                  )
                }
              }}
              className="my-auto cursor-pointer text-color-300 hover:text-primary"
            />
            <VoteButton item={item}/>
          </div>
        </div>
      )}
    </Translate>
  )
}

const ActivityItem = () => {
    const t = useTranslate()

    const {item} = useContext(EntityContext)

    const owner = _.get(item, 'owner')

    const renderSubTitle =
        useCallback(() => {
            return renderIf(
                owner,
                <div className="flex items-center">
                    <span className="text-color-400 font-light">
                        {t('by')}
                    </span>
                    {!!owner && (
                        <span className="text-color-400 font-medium ml-1 max-lines-1">
                          {getTitle(owner)}
                        </span>
                    )}
                </div>
            )
        }, [t, owner])

  const input_static = _.get(item, 'input_statistics', []).filter(e => !!e.item_name)
  const output_static = _.get(item, 'output_statistics', []).filter(e => !!e.item_name)

    const Input = () => (
        input_static.slice(0, 2).map((e, i) => {
            const type = _.get(e, 'type_cd')
            switch (type) {
                case 'money':
                    return (
                        <div key={i} className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoFlowerOutline className="text-primary"/>
                          {
                            (_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                          <span className="font-semibold text-color-300 uppercase">
                                    {numeral(_.get(e, 'total_donation_value', 0)).format('0.0a')}
                                </span>
                            <span className="font-medium text-color-200">
                                {_.get(e, 'item_name')}
                            </span>
                        </div>
                    )
                case 'blood':
                    return (
                        <div key={i} className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoFlowerOutline className="text-primary"/>
                          {
                            (_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                            <span className="font-semibold text-color-000">
                                {Number(_.get(e, 'total_donation_value')).toLocaleString()}
                            </span>
                            <span className="font-medium text-color-200">
                                {t('ML (blood unit)')}
                            </span>
                        </div>
                    )
                case 'goods':
                    return (
                        <div key={i} className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoFlowerOutline className="text-primary"/>
                          {
                            (_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'donation_target', 0) - _.get(e, 'donation_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                          <span className="font-semibold text-color-300">
                                {Number(_.get(e, 'total_donation_qtty')).toLocaleString()}
                            </span>
                            <span className="font-medium text-color-200 flex-1 truncate">
                                {_.get(e, 'unit_name')}
                                {' '}
                                {t(_.get(e, 'item_name'))}
                            </span>
                        </div>
                    )
                default:
                    return null
            }
        })
    )
    const Output = () => (
        output_static.slice(0, 2).map((e, i) => {
            const type = _.get(e, 'type_cd')
            switch (type) {
                case 'money':
                    return (
                        <div key={i} className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoGiftOutline className="text-secondary"/>
                          {
                            (_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                            <span className="font-semibold text-color-000 uppercase">
                                {numeral(_.get(e, 'total_giving_value')).format('0.0a')}
                            </span>
                            <span className="font-medium text-color-200">
                                {_.get(e, 'item_name')}
                            </span>
                        </div>
                    )
                case 'blood':
                    return (
                        <div key={i} className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoGiftOutline className="text-secondary"/>
                          {
                            (_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                            <span className="font-semibold text-color-000">
                                {Number(_.get(e, 'total_donation_value')).toLocaleString()}
                            </span>
                            <span className="font-medium text-color-200">
                                {t('ML (blood unit)')}
                            </span>
                        </div>
                    )
                case 'goods':
                    return (
                        <div key={i}  className="flex items-center gap-1 text-xs italic leading-tight">
                            <IoGiftOutline className="text-secondary"/>
                          {
                            (_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)) > 0 && (
                              <React.Fragment>
                                <span className="font-semibold text-color-000 uppercase">
                                    {numeral(_.get(e, 'giving_target', 0) - _.get(e, 'giving_qtty', 0)).format('0.0a')}
                                </span>
                                {' / '}
                              </React.Fragment>
                            )
                          }
                            <span className="font-semibold text-color-000">
                                {Number(_.get(e, 'total_giving_qtty')).toLocaleString()}
                            </span>
                            <span className="font-medium text-color-200 flex-1 truncate">
                                {_.get(e, 'unit_name')}
                                {' '}
                                {t(_.get(e, 'item_name'))}
                            </span>
                        </div>
                    )
                default:
                    return null
            }
        })
    )

    const ItemStatistics = () => {
      return (
          <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-2">
                  {input_static.length > 0 && (
                      <div className="flex flex-col">
                      <span className="font-medium text-2xs text-primary uppercase">
                          {t('donation')}
                      </span>
                          <div className="ml-2">
                              <Input />
                          </div>
                      </div>
                  )}
                  {output_static.length > 0 && (
                      <div className="flex flex-col">
                          <span className="font-medium text-2xs text-secondary uppercase">
                              {t('giving')}
                          </span>
                          <div className="ml-2">
                              <Output />
                          </div>
                      </div>
                  )}
              </div>
              {(input_static.length > 2 || output_static.length > 2) && (
                  <Link
                      to={getLinkToDetail(item)}
                      className="text-right text-2xs text-color-500 italic mx-6 no-underline">
                      {'... '}{t('see more')}
                  </Link>
              )}
          </div>
      )
    }

    return (
        <ItemTemplate
            overlay={<OverlayItemBanner type={WishareEntities.ACTIVITY}/>}
            item={item}
            Header={ItemHeader}
            Footer={ItemFooter}
            image={_.get(item, 'cover_photo')}
            renderExtra={renderSubTitle}>
            <div className={
                classNames(
                    "font-light text-xs text-color-300 italic mb-1",
                    input_static.length > 0 ? 'max-lines-1' : 'max-lines-3')
            }>
                {_.get(item, 'description')}
            </div>
            <ItemStatistics />
        </ItemTemplate>
    )
}

export default nest(
  EntityProvider,
  ActivityItem
)
