import { channelModel } from "apis/model"
import _ from "lodash"
import React from "react"
import {ChannelIcon} from "../../../components/icons/ChannelIcon";
import AppIcon from "../../../components/AppIcon";
import getTitle from "../../../helpers/getTitle";
import {getType} from "../../../apis/model/base";
import {Tag} from "antd";
import {MedalModalTypes} from "../../MainPage/WorkSpace/Medal/MedalActionModal";
import {WishareEntities} from "../../Wishare/enums";
import classnames from "classnames";

const renderChannelModalItem = (
  item, {
    t,
    index,
    onItemClick = item => null,
  }
) => {

    const type = getType(_.get(item, 'parent_owner', _.get(item, 'owner')))

    let className

    switch (type) {
        case WishareEntities.ORGANIZATION:
            className = 'color-organization';
            break;
        case WishareEntities.USER:
            className = 'color-secondary';
            break;
        case WishareEntities.ACTIVITY:
            className = 'color-secondary';
            break;
        default:
            break;
    }

  return (
    <div
      key={index}
      data-effect="ripple"
      onClick={() => {
        onItemClick(item)
      }}>
      <div className="p-3 cursor-pointer background rounded-lg border">
          <div className="flex items-center gap-3">
              {
                  ["user", "organization"].includes(_.get(item, 'owner._type'))
                      ? (
                          <React.Fragment>
                              <div className="flex flex-col gap-1 truncate">
                                  <div className="font-semibold text-color-000 hover:text-primary flex-1 leading-tight truncate">
                                      {channelModel.getTitle(item)}
                                  </div>
                                  <div className="leading-tight flex items-center">
                                      <Tag style={{padding: '2px 5px'}}
                                           className={classnames("italic text-2xs leading-tight background-200", className)}>
                                          {t(getType(_.get(item, 'owner')))}
                                      </Tag>
                                      <span className="italic text-xs text-color-300">
                                          {getTitle(_.get(item, 'owner'))}
                                      </span>
                                  </div>
                              </div>
                          </React.Fragment>
                      )
                      : (
                          <React.Fragment>
                              <div className="flex flex-col gap-1 truncate">
                                  <div className="font-semibold text-color-000 hover:text-primary flex-1 leading-tight truncate">
                                      {getTitle(_.get(item, 'owner'))}
                                  </div>
                                  <div className="leading-tight text-xs flex items-center">
                                      <Tag style={{padding: '2px 5px'}}
                                           className={classnames("italic text-2xs leading-tight background-200", className)}>
                                          {t(getType(_.get(item, 'parent_owner')))}
                                      </Tag>
                                      <span className="italic text-xs text-color-300">
                                          {getTitle(_.get(item, 'parent_owner'))}
                                      </span>
                                  </div>
                              </div>
                          </React.Fragment>
                      )
              }
          </div>
          {_.get(item, 'categories', []).length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                  {_.get(item, 'categories', []).map(
                      (e, i) => (
                          <span
                              key={i}
                              className="font-medium border border-primary px-2 background rounded-md text-xs text-primary italic">
                            {e.name}
                          </span>
                      )
                  )}
              </div>
          )}
      </div>
    </div>
  )
}

export default renderChannelModalItem