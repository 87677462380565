import {Avatar, Row, Tag} from 'antd'
import {FollowButton} from 'components/Feed/FollowButton'
import {VoteButton} from 'components/Feed/VoteButton'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {ROOT_URL} from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useCallback, useContext,} from 'react'
import {IoSettingsOutline, IoShareSocialOutline,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {nest, withProps,} from 'recompose'
import {renderIf} from 'views/Shared'
import {EntityContext, EntityProvider,} from 'views/Wishare/EntityList'
import {ContextActionTypes, ItemContextMenu,} from 'views/Wishare/factory/createContextMenu'
import {WishareEntities} from '../../enums'
import ItemTemplate, {OverlayItemBanner,} from '../../Templates/ItemTemplate'
import {BiLinkAlt} from "react-icons/bi";
import {formatDateTime} from "../../../Shared";

export const EventCompactItem = ({
                                   item,
                                 }) => {
  const t = useTranslate()
  const {isSm} = useContext(
    LayoutContext
  )

  return (
    <div className="relative w-full border border-color-50 rounded-lg background">
      <OverlayItemBanner
        type={WishareEntities.EVENT}
      />
      <div className="h-7"/>
      <div className="flex items-center px-3 py-2">
        {!isSm && (
          <Avatar className="background-100" src={_.get(item, 'owner.avatar')}>
            <span className="capitalize text-color-100">
              {_.first(_.get(item, 'owner.title') || _.get(item, 'owner.name'))}
            </span>
          </Avatar>
        )}
        <div className="flex-col flex-1 mx-3">
          <Link
            to={getLinkToDetail(item)}
            className="cursor-pointer font-bold leading-tight max-lines-1">
            {getTitle(item)}
          </Link>
          <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
            {_.get(item, 'owner.name') || _.get(item, 'owner.title')}
          </span>
          <div className="flex items-center mt-2">
            <div className="flex flex-col flex-1 mr-3">
                <div className="flex space-x-1 text-xs italic">
                    <span className="text-color-400">
                        {`${t('event type')} :`}
                    </span>
                    <span className="text-color-000 font-semibold">
                        {t(_.get(item, 'event_type'))}
                    </span>
                </div>
                {_.get(item, 'time_open') && (
                    <div className="flex space-x-1 text-xs italic">
                        <span className="text-color-400">
                            {`${t('expires')} :`}
                        </span>
                        <span className="text-color-000 font-semibold">
                            {formatDateTime(_.get(item, 'time_open'))}
                        </span>
                    </div>
                )}
            </div>
            <FollowButton
              size={
                isSm
                  ? 'small'
                  : 'default'
              }
              item={item}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemHeader = ({
                      impactCategories,
                      withMenuOptions = (item) =>
                        [
                          {
                            key: ContextActionTypes.COPY,
                            label: 'copy path',
                            icon: <BiLinkAlt/>,
                          },
                          !!_.get(item, 'edit', false)
                            ? {
                              key: ContextActionTypes.SETTINGS,
                              label: 'setting',
                              icon: <IoSettingsOutline/>,
                            }
                            : undefined,
                        ].filter((e) => !_.isUndefined(e)),
                    }) => (
  <div className="flex items-center justify-between pb-2">
    <div className="space-x-2">
      {Array.from(
        impactCategories || []
      ).map((impact, i) => (
        <Avatar
          key={i}
          size={20}
          shape="square"
          className="rounded-md"
          src={getAvatar(impact)}
        />
      ))}
    </div>
    <EntityContext.Consumer>
      {({item, handleAction}) => (
        <ItemContextMenu
          item={item}
          onMenuSelect={handleAction}
          items={withMenuOptions(item)}
        />
      )}
    </EntityContext.Consumer>
  </div>
)

const EventItem = ({item}) => {
  const t = useTranslate()

  const impactCategories = _.get(
    item,
    'impact_categories',
    []
  )

  const renderSubTitle = useCallback(
    () =>
      renderIf(
        _.get(item, 'owner.name'),
        <div className="flex items-center">
          <span className=" text-color-400 font-light">
            {t('by')}
          </span>
          <span className="text-color-400 font-medium ml-1 max-lines-1">
            {getTitle(
              _.get(item, 'owner')
            )}
          </span>
        </div>
      ),
    [t, item]
  )

  const renderFooter = useCallback(
    () => (
      <div className="flex items-center justify-between">
        <span className="text-xs text-color-400 leading-tight">
          <span className="text-primary font-bold mr-1">
            {_.get(item, 'total.votes')}
          </span>
          {t('donate')}
        </span>
        <div className="space-x-6">
          <IoShareSocialOutline
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: item.title,
                    url: `${ROOT_URL}${getLinkToDetail(
                      item
                    )}`,
                  })
                  .then(() =>
                    console.log(
                      'Successful share'
                    )
                  )
                  .catch((error) =>
                    console.log(
                      'Error sharing',
                      error
                    )
                  )
              } else {
                alert(
                  `your device don't support this!`
                )
              }
            }}
            className="my-auto cursor-pointer text-color-300 hover:text-primary"
          />

          <VoteButton item={item}/>
        </div>
      </div>
    ),
    [t, item]
  )

  const time_close = moment(
    _.get(item, 'time_close')
  )
  const now = moment()

  return (
    <ItemTemplate
      item={item}
      renderExtra={renderSubTitle}
      image={_.get(item, 'photo', _.get(item, 'cover_photo', 'no-image'))}
      overlay={
        <OverlayItemBanner
          type={WishareEntities.EVENT}
        />
      }
      Header={withProps({
        impactCategories,
      })(ItemHeader)}
      Footer={renderFooter}>
      <div className="flex flex-col space-y-2">
        <Row className="m-row-0 items-center justify-between">
          <div className="flex items-center text-xs">
            <span className=" text-color-400 font-light">
              {t('start')}:
            </span>
            <span className="font-semibold text-color ml-1">
              {formatDateTime(_.get(item, 'time_open'))}
            </span>
          </div>
          {now > time_close ? (
            <Tag
              className="mr-0"
              color="success">
              {t('finished')}
            </Tag>
          ) : (
            <Tag
              className="mr-0"
              color="processing">
              {t('processing')}
            </Tag>
          )}
        </Row>
        <div className="max-lines-2 text-color-100">
          {item.description}
        </div>
      </div>
    </ItemTemplate>
  )
}

export default nest(
  EntityProvider,
  EventItem
)
