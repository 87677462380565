import {Steps} from 'antd'
import classNames from 'classnames'
import AppIcon from 'components/AppIcon'
import {ImpactCategories} from 'components/Categories/CategoriesXS'
import UseState from 'components/UseState'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext,} from 'react'
import {FaArrowAltCircleUp} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {renderDonationDetailTotal} from '../../Donation/functions/renderDonationDetailTotal'
import UserContext from '../UserContext'

const { Step } = Steps

const Outputs = ({ item }) => {
  const t = useTranslate()

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center space-x-2 border-l-2 border-secondary mt-3 px-3 py-1 background-200 rounded-r-md">
        <div className="capitalize font-bold flex-1 text-color-000">
          {t('giving')}
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        {_(
          _.get(
            item,
            'input_statistics',
            []
          )
        )
          .groupBy('type_cd')
          .map((el, idx) => {
            return {
              index: idx,
              unit_name:
                _.max(el).unit_name,
              className: '',
              label_value: t('total'),
              type_cd: t(idx),
              total_donation_qtty:
                _.sumBy(
                  el,
                  'total_donation_qtty'
                ),
              total_donation_value:
                _.sumBy(
                  el,
                  'total_donation_value'
                ),
            }
          })
          .value()
          .filter((el) => !!el.type_cd)
          .map((el, idx) => {
            return (
              <div
                key={idx}
                className="flex flex-col space-y-1">
                <div
                  key={idx}
                  className={classNames(
                    'flex items-end space-x-2 rounded-lg px-3 py-2 bg-primary text-white text-lg font-bold',
                    el.className
                  )}>
                  <div className="flex-1">
                      {el.type_cd === 'null' ? t('no data yet') : el.type_cd}
                  </div>
                </div>
                <UseState
                  initialValue={5}>
                  {([
                    limit,
                    setLimit,
                  ]) => (
                    <React.Fragment>
                      {_.get(
                        item,
                        'input_statistics',
                        []
                      )
                        .filter(
                          (el0) =>
                            el0.type_cd ===
                            el.index
                        )
                        .map(
                          (el, idx) => {
                            return {
                              ...el,
                              className:
                                '',
                            }
                          }
                        )
                        .filter(
                          (el0) => el0
                        )
                        .slice(
                          0,
                          limit > 0
                            ? limit
                            : 1000
                        )
                        .map(
                          (el, idx) =>
                            renderDonationDetailTotal(
                              el,
                              idx
                            )
                        )}

                      {limit > 0 && (
                          <div className="bottom-0">
                              <div className="p-1 cursor-pointer text-right text-sm text-color-500 hover:text-color-100 italic">
                                <span
                                    onClick={() => {
                                        setLimit(
                                            0
                                        )
                                    }}>
                                    {t(
                                        'see more'
                                    )}
                                </span>
                              </div>
                          </div>
                      )}
                    </React.Fragment>
                  )}
                </UseState>
              </div>
            )
          })}
      </div>

      {/*<div className="flex items-center space-x-2 border-l-2 border-primary mt-3 px-3 py-1 background-200">*/}
      {/*  <div className="capitalize font-bold flex-1 text-color-000">*/}
      {/*    {t('jobs')}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

const Activities = ({ item }) => {
  const t = useTranslate()
  const { org_types = {} } =
    useAppConfig();

  if (!!!_.get(item, 'activity_view_privacy', 0)) return null;

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center space-x-2 border-l-2 border-primary mt-3 px-3 py-1 background-200 rounded-r-md">
        <div className="capitalize font-semibold flex-1 text-color-000">
          {t('management')}
        </div>
      </div>
      {_.get(
        item,
        'admin_of_organizations',
        []
      ).filter( e => e.privacy === 1 )
        .map((e, i) => {
        const org_type = _.get(
          org_types,
          _.get(e, 'org_type', 900)
        )
        return (
          <div
            key={i}
            className="flex items-center space-x-2 my-1">
            <AppIcon
              size="small"
              item={e}
            />
            <div className="flex-1 flex-col leading-tight">
              <span className="text-2xs text-color-500 italic">
                {!!org_type
                  ? t(org_type)
                  : null}
              </span>
              <Link
                className="no-underline font-medium text-color-org max-lines-1"
                to={getLinkToDetail(e)}>
                {_.get(e, 'title')}
              </Link>
            </div>
          </div>
        )
      })}
      {_.get(
        item,
        'admin_of_activities',
        []
      ).filter( e => e.privacy === 1 )
        .map((e, i) => {
        return (
          <div
            key={i}
            className="flex items-center space-x-2 ">
            <AppIcon
              size="small"
              item={e}
            />
            <div className="flex-1 flex-col leading-tight">
              <Link
                className="no-underline font-medium text-color-act max-lines-1"
                to={getLinkToDetail(e)}>
                {_.get(e, 'title')}
              </Link>
              <span className="text-2xs text-color-500 italic">
                {_.get(
                  e,
                  'owner.title'
                )}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const UserImpactReport = () => {
  const t = useTranslate()
  const { user: item } = useContext(
    UserContext
  )
  if (!item) return null

  return (
    <div className="flex flex-col space-y-3 pb-6 pt-3 w-full">
      <div className="text-xl text-center text-gray-600 font-bold uppercase items-center justify-center">
        {t('impacts report')}
      </div>
      <Steps
        direction="vertical"
        current={5}>
        {[
          {
            id: 'impact',
            icon: (
              <div
                style={{
                  minHeight: '3rem',
                  height: '3rem',
                  minWidth: '3rem',
                  width: '3rem',
                  marginLeft: '-0.4rem',
                }}
                className="flex items-center justify-center rounded-full border">
                <div
                  className="h-full w-full rounded-full border"
                  style={{
                    background:
                      '#00000094',
                    backgroundImage: `url("/images/impacts/sdg.jpg")`,
                    backgroundSize:
                      'cover',
                  }}
                />
              </div>
            ),
            renderTitle: () => {
              return (
                <div className="flex items-center font-bold text-xl text-secondary uppercase space-x-1 my-3">
                  {t('toward impacts')}
                </div>
              )
            },
            renderSubTitle: () => {
              return null
            },
            renderChildren: () => {
              return (
                <div className="flex flex-col space-y-1">
                  <ImpactCategories
                    categories={_.get(
                      item,
                      'impact_categories',
                      []
                    )}
                  />
                </div>
              )
            },
          },
          {
            id: 'outputs',
            icon: (
              <FaArrowAltCircleUp className="text-secondary text-4xl" />
            ),
            renderTitle: () => {
              return (
                <div className="flex items-center font-bold text-xl text-secondary uppercase space-x-1 my-2">
                  {t('outputs')}
                </div>
              )
            },
            renderSubTitle: () => {
              return null
            },
            renderChildren: () => {
              return (
                <Outputs item={item} />
              )
            },
          },
          {
            id: 'activities',
            icon: (
              <FaArrowAltCircleUp className="text-primary text-4xl" />
            ),
            renderTitle: () => {
              return (
                <div className="flex items-center font-bold text-xl text-primary uppercase space-x-1 my-2">
                  {t('activities')}
                </div>
              )
            },
            renderSubTitle: () => {
              return null
            },
            renderChildren: () => {
              return (
                <Activities
                  item={item}
                />
              )
            },
          },
        ].map((e, i) => {
          return (
            <Step
              key={i}
              icon={e.icon}
              title={e.renderTitle()}
              subTitle={e.renderSubTitle()}
              description={e.renderChildren()}
            />
          )
        })}
      </Steps>
    </div>
  )
}

export default UserImpactReport
