export default {
  "00000-FR":"TRAN",
  "(yes/no) question":'Des questions oui ou non)',
  "{beneficiaryNbr} have beneficiaryed":'Il y a {bénéficiarrynbr} qui souhaite envoyer',
  "0 result":'0 résultats',
  "1. no poverty":'1. Supprimer la pauvreté',
  "10. reduced inequalities":'10. Réduire les inégalités',
  "11. sustainable cities and communities":'11. Communauté urbaine et durable',
  "12. responsible consumption and production":'12. La consommation et la production sont responsables',
  "13. climate action":'13. Action climatique',
  "14. life below water":'14. Ressources et environnement marins',
  "15. life on land":'15. Ressources naturelles et environnement environnemental',
  "16. peace, justice and strong institutions":'16. Paix, justice et institutions puissantes',
  "17 SDGs":'17 buts',
  "17. partnerships for the goals":'17. Relations pour les objectifs',
  "2. zero hunger":'2. Plus de faim',
  "3. good health and well-being":'3. Santé et bonne vie',
  "4. quality education":'4. Éducation de qualité',
  "5. gender equality":'5. Égalité des sexes',
  "6. clean water and sanitation":'6. Eau propre et hygiène',
  "7. affordable and clean energy":'7. Nettoyer l\'énergie avec un prix raisonnable',
  "8. decent work and economic growth":'8. Emploi et croissance économique',
  "9. industry, innovation and infrastructure":'9. Innovation et infrastructure',
  "A":'Un',
  "a post":'Des postes',
  "a beneficiary of":'Un souhait de',
  "about":'About',
  "about job":'A propos du travail',
  "about us":'À propos de nous',
  "about wishare":'À propos de Wishare',
  "abouthelp":'À propos de nous',
  "abouthelptips":'Introduction, sur l\'entreprise, contacter',
  "accept":'Accepter',
  "accepted":'Accepté',
  "access to a huge human capital for good":'Accès aux ressources humaines de toute la société',
  "account":'Compte',
  "account name":'nom du compte',
  "activated":'Activé',
  "activation":'Activé',
  "active":'Travail',
  "activetips":'La page fonctionne, vous avez le droit de gérer et de modifier',
  "activities":'Travail',
  "activity":'Travail',
  "activity information":'Informations sur les activités',
  "Activity is the smallest project unit with full mission and action. Your organization may have many different activities.":'L\'opération est une plus petite unité de projet avec une mission complète et une action. Votre organisation peut avoir de nombreuses activités différentes.',
  "activity name":'Nom d\'activité',
  "activitytips":'L\'activité est une collection d\'emplois avec des cibles claires pour servir la société. Une activité peut mobiliser les ressources communautaires pour atteindre ses objectifs.',
  "add":'Plus',
  "add activity":'Ajouter une activité',
  "add album":'Ajouter un jeu de photos',
  "add an image":'Article',
  "add auction item":'Ajouter le produit d\'enchères',
  "add campaign":'Ajouter un programme',
  "add categories":'Sélectionnez l\'élément qui vous tient à cœur',
  "add delivery policy":'Ajouter la méthode de livraison',
  "add donation":'Ajouter un nouveau financement',
  "add donation campaigns":'Ajouter un programme de charité',
  "add location":'Ajouter un emplacement',
  "add member":'Ajouter les utilisateurs',
  "add more":'Plus',
  "add new":'Ajouter un nouveau',
  "add new language":'Ajouter une nouvelle langue',
  "add new map":'Ajouter des endroits à la carte',
  "add new product":'Ajouter de nouveaux produits',
  "add new schedule":'Ajouter un nouveau calendrier',
  "add notice":'Ajouter un avis',
  "add photos":'Plus de photos',
  "add product":'Ajouter le produit',
  "add promotion campaigns":'Ajouter des promotions',
  "add return policy":'Ajouter la stratégie de retour',
  "add tag":'Attaché aux mots clés',
  "add to cart":'Ajouter au panier',
  "add warranty policy":'Ajouter la politique de garantie',
  "add your comment":'Votre commentaire',
  "add your school":'Ajouter l\'école où vous avez appris',
  "add your skills":'Ajoutez vos compétences',
  "add your workplace":'Ajouter où vous avez travaillé',
  "add yours recommendations":'Ajoutez votre proposition',
  "added hosts":'Les unités représentatives ont ajouté',
  "additional message":'Contenu additionnel',
  "address":'Adresse',
  "addressed":'envoyé',
  "adhoc":'Spécial',
  "admin":'Administration',
  "advanced":'Avancé',
  "affordable and clean energy":'Énergie propre avec un prix raisonnable',
  "age":'Âge',
  "age_from":'Vieillir de',
  "age_from_placeholder":'Âge (de l\'âge) dans la gamme de soutien',
  "age_to":'Vieillir',
  "age_to_placeholder":'Âge (à l\'âge) dans la gamme de soutien',
  "agree":'Accepter',
  "all":'Tous',
  "ambassador":'ambassadeur',
  "amount":'Valeur',
  "An":'Un',
  "and":'et',
  "and petition":'et pétition',
  "and social impact work":'et le travail d\'impact social',
  "animal":'Animal',
  "anonymous":'Sponsor anonyme',
  "answer":'Répondre',
  "applicant note":'Notes d\'application',
  "applicants":'Candidat',
  "applied a job":'Demande de demande',
  "applied beneficiaries":'Les unités bénéficiaires ont envoyé',
  "applies":'Candidat',
  "apply":'Appliquer',
  "apply a new beneficiary":'Ajouter de nouvelles unités de bénéficiaire',
  "apply an existing beneficiary":'Ajouter des avantages de Wishare',
  "apply now":'Demande immédiatement',
  "apply now!":'Appliquer maintenant!',
  "apply technology to support":'Appliquer la technologie pour supporter',
  "apply to become a volunteer":'Envoyer des dossiers aux employeurs',
  "approve":'Navigateur',
  "approved":'Approuvé',
  "are bidding":'Enchères',
  "are you agree to remove item from order?":'Acceptez-vous de supprimer ce produit de la commande?',
  "are you sure to cancel this order?":'Êtes-vous sûr de vouloir annuler cette commande?',
  "are you sure you want to remove this item?":'Êtes-vous sûr de supprimer ce contenu?',
  "art & design":'Conception d\'art',
  "Art, Collections & Antiques":'Art, collection et antiquités',
  "asc":'Ascendant',
  "ask questions":'Question',
  "asking":'Offre',
  "at least":'Au moins',
  "attributes":'Propriétés',
  "attributes list":'Liste des attributs',
  "auction":'Enchères',
  "auction donation campaign":'Mobiliser le don',
  "auction history":'Histoire des enchères',
  "auction information":'Informations sur les enchères',
  "auction items":'Produits aux enchères',
  "auction management":'Gestion des enchères',
  "auction members":'Membre des enchères',
  "auction name":'Nom de vente aux enchères',
  "auction product":'Produits des enchères',
  "auction products":'Produits aux enchères',
  "auction settings":'Installation aux enchères',
  "auctioned price":'Le prix se battait',
  "auctions":'Enchères',
  "automatic":'Implémentation automatique',
  "automatic execution":'Exécution automatique',
  "Automotive Motorcycles":'Auto et Moto',
  "available":'disponible',
  "available activities":'Activité disponible',
  "available for social work":'Prêt pour le travail social',
  "avatar":'Avatar',
  "average rating point":'Score d\'évaluation moyen',
  "Babies & Toys":'Enfants et jouets',
  "back":'Revenir',
  "back to shop":'Retour au magasin',
  "basic information":'Informations de base',
  "be a part of growing trend":'Faire partie de la tendance du développement',
  "be the first to bid":'Être la première personne à évaluer',
  "become a member of wishare":'Inscrivez-vous pour un compte Wishare gratuit',
  "become a volunteer ":'Devenir des bénévoles',
  "Before creating a new one, please see if your wishare already exists. In the case of Wishare\'s indexing, please contact us so we can re-administer it.":'Avant de créer un nouveau, veuillez chercher à voir si Wishare existe votre activité. Dans le cas où Wishare aurait fait des index, veuillez nous contacter pour que nous transférons les droits administratifs.',
  "begin":'Commencer',
  "benefits":'Avantages',
  "benefits and obligations":'Avantages et responsabilités',
  "best sales":'Vendre des produits',
  "bid":'Ensemble',
  "bid now":'Vente aux enchères maintenant',
  "bid price":'L\'offre finale',
  "bid time":'Durée',
  "bidder":'Prix',
  "bidding":'Enchères',
  "bidding / asking":'Vente aux enchères: * Le prix suivant sera plus élevé que le prix précédent, le prix le plus élevé gagnera la vente aux enchères. / Prix Offre: * Le prochain prix sera inférieur au prix, le prix le plus bas gagnera l\'offre.',
  "bill":'Facture',
  "binding_conditions":'Conditions de liaison',
  "binding_conditions_placeholder":'Conditions accompagnées d\'objets d\'activités, engagements avec des activités',
  "block":'Confinement',
  "blocked":'Bloqué avec la main',
  "blockedtips":'Désolé. Le contenu n\'est pas approprié, pas autorisé à être affiché publiquement',
  "Books, Stationery & Gifts":'Livres, papeterie et cadeaux',
  "bought":'Acheté',
  "but let us help measure your impact and discover the social activities around you":'Mais laissez-nous aider à mesurer votre impact et explorer les activités sociales autour de vous',
  "but they can not find aright one":'Mais ils ne peuvent pas trouver le besoin',
  "but they can not find the support":'Mais ils ne peuvent pas trouver de soutien',
  "buy now":'Acheter maintenant',
  "by":'par',
  "campaign name":'Nom de la campagne',
  "cancel ":'Annuler',
  "cancel order":'Annuler la commande',
  "cancelled":'Annuler',
  "career":'Profession',
  "careers":'Recrutement',
  "cart":'Chariot',
  "categories":'Catégorie',
  "categoriesplaceholder":'Choisir le groupe',
  "category":'Catégorie',
  "certificate":'Certificat d\'apprentissage',
  "change":'Changement',
  "change cover photo":'Changer de papier peint',
  "change in price and quantity tab":'Changement de prix et de quantité',
  "change status":'Changer l\'état',
  "change your picture":'Changer l\'avatar',
  "charity products":'Produits qui soutiennent la charité',
  "charity, social impact project":'Projets de charité et d\'impact social',
  "check your email and click on the activation link to complete the registration":'Vérifiez votre e-mail et cliquez sur le lien d\'activation pour terminer l\'inscription',
  "children":'Enfants',
  "choices":'Sélectif',
  "choose":'Sélectionner',
  "choose 1 of the funds to receive the donation":'Choisissez l\'un des fonds pour recevoir des dons',
  "choose a photo as the image for the activity":'Choisissez une image comme image d\'opération',
  "choose a photo as the image for the event":'Choisissez une image comme image pour l\'événement',
  "choose a photo as the image for the group":'Choisissez des photos comme arrière-plan de l\'organisation',
  "choose the category that you are aiming for":'Sélectionnez la catégorie que vous visez',
  "choose the job type to continue":'Choisissez le type de travail pour continuer',
  "choose the target you want to target":'Choisissez l\'objectif que vous souhaitez viser',
  "clean water and sanitation":'Eau propre et hygiène',
  "CleanEnergy":'Nangluong',
  "CleanWaterAndSanitation":'Nuasachvveinh',
  "clear":'Effacer',
  "clear filter":'Filtre',
  "click":'Cliquez sur',
  "click here":'Cliquez ici',
  "click here to add new donate package":'Ajouter un nouveau package de contribution',
  "click here to add new photos":'Cliquez ici pour ajouter des photos',
  "click here to dismiss recommendation this beneficiary":'Cliquez pour supprimer les recommandations pour les bénéficiaires',
  "click to next":'Sélectionnez pour continuer',
  "click to vote it":'Cliquez pour soutenir',
  "climate action":'Action climatique',
  "close":'Fermer',
  "closed":'Fermé',
  "closed need":'Arrêtez d\'accepter',
  "closedgroup":'Organiser des activités privées',
  "collected":'Contribué',
  "Color":'Couleur',
  "coming soon":'A venir',
  "comment":'Commentaire',
  "comment history":'Commentaires de l\'histoire',
  "comment on product":'Share se sent sur les produits',
  "comment store":'Commentaire de magasin',
  "common":'informations générales',
  "community policy":'Politique publique',
  "company":'Entreprise',
  "companytips":'Entreprise, ...',
  "complete add new product":'Terminez de nouveaux produits',
  "complete the information so people can understand your store better":'Remplissez les informations pour que tout le monde puisse mieux comprendre votre magasin',
  "Complete with sharing and spreading. We need you.":'Terminer le travail avec le partage et la propagation. Nous avons besoin de toi.',
  "completed":'Fin',
  "completed project":'Le projet est terminé',
  "completed beneficiary":'Unité bénéficiaire réussie',
  "computer & data":'Programme',
  "confirm cart":'Confirmation de chariot',
  "confirm password":'Confirmez le mot de passe',
  "congratulation you become winner":'Félicitations pour avoir gagné',
  "congratulation your payment has been finished":'Félicitations pour votre paiement',
  "congratulations":'Toutes nos félicitations',
  "congratulations, your order is successful":'Félicitations pour avoir acheté des produits réussis',
  "connect with facebook":'Se connecter avec Facebook',
  "connect with google":'Connectez-vous avec Google',
  "connect with us":'Connecte-toi avec nous',
  "connecting for good":'Connectez les bonnes choses',
  "connecting people of kindness and needs":'Connectez les bonnes personnes et les besoins ci-dessus',
  "connecting the goodness":'Connecter \ bon bien',
  "contact":'Contact',
  "contact us":'Contact',
  "Contact us by sending to contact@wishare.com":'Contactez-nous en envoyant contact@wishare.com',
  "contact_info":'Contact',
  "content":'Contenu',
  "content created by wishare":'Définir indexé par Wishare',
  "continue":'Continuer',
  "continue shopping":'Continuer vos achats',
  "continuous time":'Le temps continue',
  "contract":'Contracter',
  "contribute":'des dons',
  "cookies":'Biscuits',
  "cookies policy":'Politique des cookies',
  "copied":'Copié',
  "copy":'Copie',
  "copy url":'Copier le chemin',
  "Copyright by wishare.com":'Copyright appartient à Wishare.com',
  "could_not_create_token":'Connectez-vous sans succès!',
  "count on product":'Provisoire',
  "create":'Créer un nouveau',
  "create a crowdfunding activity":'Créer des activités de collecte de fonds communautaires:',
  "create a new album":'Créer de nouveaux albums',
  "create a new opportunity":'Affichage des informations de recrutement',
  "create account & login":'Créer un nouveau et connecter',
  "create activity":'Créer des activités',
  "create auction":'Créer des enchères',
  "create donation":'Entrez les informations d\'assistance',
  "create event":'Créer un évènement',
  "create giving":'Entrez les informations fournies',
  "create group":'Créer une organisation',
  "create need":'Nouvelle collecte de fonds',
  "create new account":'Créer de nouveaux comptes',
  "create new an activity":'Créer de nouvelles activités',
  "create new auction":'Créer un programme d\'enchères',
  "create new package":'Créer un nouveau package',
  "create opportunity":'Créer des informations de recrutement',
  "create post":'Créer des articles',
  "create store":'Créer un magasin',
  "create beneficiary":'Créer des unités de bénéficiaire',
  "create your activity":'Créer des activités',
  "create your event":'Créer un évènement',
  "create your group":'Créer une organisation',
  "create your beneficiary":'Créer des unités de bénéficiaire',
  "created":'créé',
  "created date":'Date créée',
  "crowdfunding":'Collecte de fonds',
  "culture":'Culture',
  "current":'Cadeau',
  "current price":'Prix ​​actuel',
  "currentUser":'Ami',
  "customer rated positively":'Les clients ont des avis positifs',
  "date":'Jour',
  "date & time":'Date et l\'heure',
  "date_of_birth":'Date de naissance',
  "day":'Jour',
  "days":'jour',
  "days of delivery":'Délai de livraison',
  "deaf mute":'Muet sourde',
  "default":'Défaut',
  "delete":'Effacer',
  "delete comment":'Supprimer les commentaires',
  "delete opportunity":'Supprimer le travail',
  "delete question":'Supprimer les questions',
  "delete the selected items":'Supprimer l\'élément sélectionné',
  "delivered":'Livré',
  "delivery":'Livraison',
  "delivery by":'Livré par',
  "delivery by shop":'Livraison par le magasin',
  "delivery details":'Détails de l\'envoi',
  "delivery fee":'Frais de transport',
  "delivery location":'Adresse de livraison',
  "delivery options":'Option de livraison',
  "delivery period from":'Livraison de',
  "delivery policy":'Politique de livraison',
  "delivery status":'Statut de livraison',
  "delivery unit":'Unité de livraison',
  "delivery-methods":'Méthode de livraison',
  "delivery-policies":'Politique de livraison',
  "desc":'Diminuer',
  "description":'Introduire',
  "descriptionplaceholder":'Tapez plus de description',
  "designer":'Designer',
  "detail":'Détail',
  "development":'Développer',
  "digital of your social activity":'Numérisez vos activités sociales',
  "directly work":'Travailler directement',
  "disabilities":'Handicapées',
  "disablity":'Handicapées',
  "discard":'Annuler',
  "discount":'Rabais',
  "discount value":'Rabais',
  "Discover and contribute to community fundraising activities. We need you.":'Découvrez et contribuez aux activités de collecte de fonds communautaires. Nous avons besoin de toi.',
  "discover now":'Explorez maintenant',
  "discovery":'Découvrir',
  "discussion":'Discuter',
  "dismiss recommendation":'Abandonner',
  "display on homepage":'Spectacle sur la page d\'accueil',
  "district":'District',
  "divorced":'Divorcé',
  "divorcedtips":'Divorcé',
  "do you want to delete this?":'Voulez-vous supprimer ce contenu?',
  "do you want to remove this post?":'Voulez-vous supprimer cet article?',
  "Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?":'Voulez-vous passer votre temps, votre talent et vos efforts sur les activités communautaires ou souhaitez faire un travail d\'impact social?',
  "donate":'des dons',
  "donate now":'Faire un don immédiatement',
  "donate what you can":'Vous pouvez faire un don',
  "donated":'Contribué',
  "donation":'des dons',
  "donation campaigns":'Programme de charité',
  "donation code":'Code de contribution',
  "donation info":'Informations sur les dons',
  "donation manager":'Gestionnaire d\'assistance',
  "donation transfer":'Transférer de l\'argent pour le programme de charité',
  "donation value":'Valeur de don',
  "donation value must be large than 0":'La saisie de la valeur de don doit être supérieure à 0',
  "donation-campaigns":'Programme de charité',
  "donations":'des dons',
  "done":'Réalisé',
  "done beneficiaries":'Unité bénéficiaire réussie',
  "donetips":'Succès',
  "donor":'Donateurs',
  "downvote":'Abandonner',
  "draft":'Brouillon',
  "drafttips":'Le contenu du manuscrit d\'origine, Sauvegarde interne, continue d\'envoyer des demandes de publication',
  "earlier":'Vieil avis',
  "edit":'Modifier',
  "edit auction item":'Modification des produits d\'enchères',
  "edit comment":'Mettre à jour les commentaires',
  "edit delivery policy":'Modifier la politique de livraison',
  "edit donate option":'Modifier l\'option de don',
  "edit donation":'Modifier les informations de don',
  "edit donation campaigns":'Modifier le programme de vente',
  "edit giving":'Correction d\'informations données',
  "edit image":'Modifier les photos',
  "edit location":'Réparer',
  "edit margin value":'Modifier la valeur de la marge',
  "edit member":'Adhésion à l\'adhésion',
  "edit member information":'Changer les informations des membres',
  "edit notice":'Modifier l\'avis',
  "edit opportunity":'Informations de recrutement correctes',
  "edit post":'Modifier la publication',
  "edit product infomation":'Édition d\'informations sur les produits',
  "edit profile":'Correction des enregistrements',
  "edit promotion campaign":'Modifier les promotions',
  "edit question":'Questions correctes',
  "edit return policy":'Édition de politique de retour',
  "edit warranty policy":'Modifier la politique de garantie',
  "education":'Éducation',
  "education/ training":'Éduques',
  "educations":'Éducation',
  "effective management and reporting":'Gestion et rapport efficaces',
  "efficient successful and sustainable":'Efficace, réussi et durable',
  "Electronic & Accessories":'Électronique et accessoires',
  "email":'E-mail',
  "emailplaceholder":'Email (example@company.com)',
  "embed":'Contenu en trempette',
  "empathize and help to connect these beneficiaries to the relevent activities that you should know":'Empathie et aider à relier ces désirs aux activités connexes que vous connaissez',
  "employment type":'Type de travail',
  "empty":'Pas de données',
  "end":'Fin',
  "end date":'Date de fin',
  "end time":'Heure de fin',
  "end time must be after start time":'La fin de l\'heure doit être après l\'heure de début',
  "end_at":'Fin',
  "end_date":'Date de fin',
  "end_time":'Heure de fin',
  "ended":'Fin',
  "ended at":'Terminé à',
  "ended session":'Fini',
  "ending now":'A venir',
  "english":'Anglais',
  "englishtips":'Anglais',
  "enter a location":'Entrez l\'emplacement',
  "enter album name":'Entrez le nom de l\'album',
  "enter amount":'Entrez la valeur',
  "enter keyword":'Entrez les mots clés',
  "enter name":'Entrez le nom',
  "enter order code":'Entrez le code de commande',
  "enter order user":'Entrez le produit',
  "enter the activity name":'Entrez le nom de l\'activité',
  "enter the reason you think is a violation":'Entrez la raison pour laquelle vous pensez à une violation',
  "enter your question here":'Entrez votre question ici',
  "enter your reply here":'Entrez la réponse ici',
  "environment":'Environnement',
  "environmental":'Environnement',
  "error message":'Erreur! Vérifiez la connexion et cliquez sur Charger!',
  "event":'Événement',
  "event time":'Heure de l\'évènement',
  "event timeline":'Ligne d\'événement',
  "event title":'Nom de l\'événement',
  "events":'Événement',
  "eventtips":'L\'événement se compose de nombreuses activités, de nombreux participants au même moment et à l\'emplacement. L\'événement est utilisé pour mettre en œuvre vos plans.',
  "example":'Par exemple',
  "example name":'Nguyen van a',
  "experience_requirements":'Expérience',
  "expired":'Expiré',
  "expired job":'Expiration pour le recrutement',
  "explore funding need":'Rechercher des activités financières',
  "explore jobs in":'Recherche d\'emploi',
  "explore the list of activities which are suitable for your need":'Recherchez des activités qui répondent à vos besoins',
  "export data":'Exporter des données',
  "failed":'Échec',
  "farming & forestry":'Agriculture et foresterie',
  "Fashion & Accessories":'Accessoires de mode',
  "fee":'Frais',
  "feedback":'Contactez Wishare',
  "feeds":'Sujet',
  "female":'Femme',
  "femaletips":'Femme',
  "field of work":'Champ de travail',
  "filterplaceholder":'Contenu de filtre',
  "financial capital":'Ressources \ nt pour',
  "financial capital for good":'Sources financières',
  "Find volunteers or create social impact locations, Experience the free recruitment on Wishare.":'Vous trouvez des bénévoles ou des postes d\'impact social, expérimentez un recrutement gratuit sur Wishare.',
  "finish":'Complet',
  "flexible":'flexible',
  "focusing on good connection":'Concentrez-vous sur une bonne connexion',
  "follow":'Moniteur',
  "followed you":'vous a suivi',
  "follower":'moniteur',
  "followers only":'Mes abonnés',
  "following":'En train de regarder',
  "following auction":'Les enchères',
  "following events":'L\'événement a été suivi',
  "following beneficiaries":'L\'unité bénéficiaire a suivi',
  "following-auction":'La vente aux enchères regarde',
  "for":'Donner',
  "for community":'pour la communauté',
  "for recruiters, let access to our huge volunteer community":'Pour les employeurs, visitez notre grande communauté de bénévoles',
  "for sales":'Vendre',
  "for social and creative purpose":'À des fins sociales et créatives',
  "for volunteer, please let us give you a good recommendation":'Pour les bénévoles, veuillez nous donner une bonne offre',
  "for volunteers":'Pour les bénévoles',
  "for who seek to raise money for the good thing, implementing your initiative":'Pour ceux qui recherchent de l\'argent pour de bonnes choses, mettez en œuvre vos initiatives',
  "forgotpass":'Mot de passe oublié?',
  "foundation":'Fonds',
  "free for friends":'Gratuit pour amis',
  "free ship":'Livraison gratuite',
  "free ship with minimum amount":'Livraison gratuite avec commandes',
  "frequency":'Fréquence',
  "frequencyplaceholder":'Fréquence',
  "friend":'Ami',
  "friends":'Ami',
  "from":'Depuis',
  "from donors":'Reconnaissant',
  "full-time":'Tout le temps',
  "fullname":'Nom et prénom',
  "fundraiser for good":'Collecte de fonds',
  "gave":'Livré',
  "gender":'Sexe',
  "gender equality":'Égalité des sexes',
  "GenderEquality":'L\'égalité des sexes',
  "general":'informations générales',
  "general information":'informations générales',
  "get started":'Commencer immédiatement',
  "gift":'Artefacts',
  "gift product":'Don',
  "gift product list":'Liste de cadeaux',
  "gift products":'Le produit est inclus',
  "give your job a title":'Nom du contenu du recrutement',
  "give your job position":'Indiquez ce poste',
  "given number":'Émis',
  "giving":'Donné',
  "giving what we can?":'Veuillez partager avec nous',
  "go to":'Déménager à',
  "go to cart":'Aller dans le chariot',
  "go to shop":'Aller au magasin',
  "good":'Bien',
  "good health and well-being":'Santé et bonne vie',
  "graduated":'gradué',
  "Great, you\'ve signed - the next step is to share far and wide to make sure everyone sees this campaign.":'Super! Vous avez signé. Continuez à partager cette campagne pour que tout le monde puisse voir et rejoindre',
  "Groceries & Pets":'Épicerie et animal de compagnie',
  "group":'Organisation',
  "groups":'Organisation',
  "grouptips":'L\'organisation est un espace de travail, reliant de nombreuses personnes. Initialisation des groupes pour créer des ports d\'informations sur l\'entreprise, des projets (non-profil, des associations, ...) ou un groupe d\'amis.',
  "grouptypeplaceholder":'Type d\'organisation',
  "half successful":'Compléter 1 partie',
  "has been linked":'a été lié',
  "has been supported":'A été soutenu',
  "has draft orders":'Il y a des commandes qui se préparent à acheter',
  "has orders":'Il y a des ordres en cours',
  "haveaccount":'Il y a des comptes?',
  "header":'Haut',
  "Health & Beauty":'Santé & Beauté',
  "healthcare":'Soins de santé',
  "healthy":'Santé',
  "Height":'Hauteur',
  "help":'Aider',
  "helps":'Aider',
  "here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.":'Voici quelques questions que l\'employeur souhaite vous envoyer, répondre aux questions aidera l\'employeur et vous vous comprenez mieux.',
  "hidden due to invalid content":'Le contenu est caché en raison de violations des politiques et des conditions de Wishare',
  "hide":'caché',
  "high to low":'Haut en bas',
  "history":'Histoire',
  "home":'Page d\'accueil',
  "Home & LifeStyle":'Maisons et outils',
  "hometown":'Maison',
  "hosted by":'Faite par',
  "hosted items":'Unité représentative',
  "hostedactivities":'Travail',
  "hostedItem":'Unité représentative',
  "hosting":'Unités organisationnelles',
  "hosts":'Unités organisationnelles',
  "hour(h)":'Temps (h)',
  "hourly-work":'De temps',
  "house number, street name":'Numéro de maison, nom de la route',
  "how can you help?":'Que pouvez-vous donner?',
  "how many can you help?":'Combien voulez-vous faire un don?',
  "how many people do you need?":'De combien de personnes avez-vous besoin?',
  "human":'Humain',
  "human capital":'Ressources \ ncon People',
  "human capital for good":'Ressources humaines',
  "i have read and accepted with these":'J\'ai lu et accepté avec ces',
  "If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.":'Si les deux sujets sont vraiment les mêmes, vous pouvez les fusionner ici. Tous les articles regroupés par ce thème seront transférés à d\'autres thèmes.',
  "If you have any questions, please contact us for support":'Toutes les questions, veuillez nous contacter pour un soutien.',
  "If you want to create a crowdfunding activity for charitable, social or creative activities. Let\'s use and experience the crowdfunding function on our platform.":'Vous souhaitez créer des activités de collecte de fonds pour des activités caritatives, sociales ou créatives. Découvrez la collecte de fonds sur notre plateforme.',
  "ignore":'Sauter',
  "ignored":'Ignoré',
  "impact":'Impact',
  "impact jobs":'Création d\'impact',
  "important note":'Notification importante',
  "in":'IMPORTER',
  "In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activity. Join Wishare to share and contribute to these sustainability goals.":'En 2015, les dirigeants mondiaux ont convenu avec 17 objectifs pour un monde meilleur d\'ici 2030. Wishare et la communauté ont créé plus d\'indexation de fonctionnement. Rejoignez Wishare pour partager et contribuer à ces objectifs durables.',
  "in order for an employer to reach you, update some of yourself":'Afin que l\'employeur puisse vous trouver, mettre à jour plus d\'informations sur vous',
  "in our good connection":'Nous nous connectons',
  "in the same categories":'Produits du même type',
  "in-activated":'Activation désactivée',
  "inactive":'Inactif',
  "inactivetips":'La page a temporairement caché ou handicapé, les informations sont la sécurité interne',
  "index":'Page d\'accueil',
  "industry, innovation and infrastructure":'Innovation et infrastructure',
  "info":'Information',
  "information":'Information',
  "information about donating":'Informations sur les dons',
  "information where donations are made":'Informations où le don est reçu',
  "informationandmap":'Lieu',
  "infrastructure":'L\'infrastructure',
  "initial price":'Prix ​​initial',
  "input":'saisir',
  "inreview":'Considérant',
  "inreviewtips":'Nous envisageons votre site et nous vous informerons par e-mail dès que le processus d\'évaluation sera terminé.',
  "insert question":'Insérer des questions',
  "inspire your goodness":'Répandre vos bonnes choses',
  "instructions on how to reset your password were sent to":'Des instructions sur la façon de réinitialiser votre mot de passe ont été envoyées',
  "intends to delivery from":'Livraison de',
  "internal":'interne',
  "internship":'Stage',
  "internships":'Interne',
  "interpreter & translator":'Frontière, traduction',
  "introduce yourself":'Présentez-vous',
  "invalid date":'L\'heure d\'importation n\'est pas valide',
  "invalid email":'l\'adresse email n\'est pas valide',
  "invalid email address":'l\'adresse email n\'est pas valide',
  "invalid_credentials":'Mauvaise information de connexion. Veuillez vérifier votre compte et vous connecter!',
  "invite":'Inviter',
  "is merged into":'ont été greffés',
  "is priority product":'Articles prioritaires',
  "It\'s YOU":'Toi-même',
  "job description":'Description de l\'emploi',
  "job details":'Détails du poste',
  "job filter":'Filtrer le travail',
  "job function type":'Champ de travail',
  "job information":'Informations sur l\'emploi',
  "job type":'Type d\'emploi',
  "job_requirements":'Conditions de demande',
  "job_types":'Type de travail',
  "jobs":'Emploi',
  "join":'Rejoindre',
  "join day":'Date de participation',
  "join us":'Rejoindre',
  "join us now":'Adhérer maintenant',
  "join us on mobile":'Application prête ci-dessus',
  "joined":'Joint',
  "joined activities":'Le programme a participé',
  "joined groups":'L\'organisation a participé',
  "just for you":'Exclusivement pour vous',
  "keep your passion like an artist and manage your social mission like a tech CEO":'Gardez votre passion en tant qu\'artiste et gérez votre mission sociale comme un PDG de la technologie',
  "keyword":'Entrez les mots clés',
  "language":'Langue',
  "language choose":'Langue d\'affichage:',
  "language_requirements":'niveau d\'anglais',
  "languages":'Langue',
  "large scale":'grande échelle',
  "last bid price":'L\'offre finale',
  "last bidder":'Meilleur soumissionnaire',
  "latitude":'Latitude',
  "law - policy":'Droit - politique',
  "law / legal services":'Droit / juridique',
  "learn more":'En savoir plus',
  "leave comment":'Écrire des commentaires',
  "Length":'Longueur',
  "let discover and join to these activities below or apply your wish to request a support":'Découvrez et participez aux activités suivantes ou appliquez vos souhaits pour demander un soutien',
  "let discover the crowdfunding from the community and contribute to a change":'Découvrez la communauté de la communauté et contribuez au changement',
  "let make a free activity":'Créer une activité gratuite',
  "let sign and help to connect these beneficiaries to relevent activities or the kindness people":'Veuillez signer et aider à relier ces désirs avec des activités ou une gentillesse connexes',
  "let\'s us help":'Laissez-nous aider',
  "level":'Niveau',
  "life below water":'Ressources marines et environnement',
  "life on land":'Ressources naturelles et environnement terrestre',
  "LifeBelowWater":'Tauguyennuoc',
  "LifeOnLand":'Tanguyendat',
  "link target":'Adresse de liaison',
  "link title":'Titre de liaison',
  "link to":'Lié à',
  "link to schedule":'Lien vers le calendrier',
  "linked":'associé',
  "links":'lien',
  "live session":'Se produire',
  "loading":'Télécharger',
  "location":'Emplacement',
  "location list":'Liste d\'adresses',
  "locationplaceholder":'Type Emplacement',
  "locations":'Emplacement',
  "login":'Connexion',
  "logout":'Se déconnecter',
  "longitude":'Longitude',
  "lost":'Perdu',
  "Lot size":'La taille du lot',
  "low to high":'De bas en haut',
  "make a crowdfunding":'Fonds communautaires',
  "make a crowdfunding now":'Créer une nouvelle activité de collecte de fonds communautaire',
  "make a recruitment":'Employeur',
  "make a beneficiary":'Créer des rêves',
  "make an activity":'Créer des activités',
  "make digital conversions for social initiatives to help them work":'Effectuer une conversion numérique pour les initiatives sociales pour les aider à travailler',
  "make event":'Créer un évènement',
  "make opportunity now":'Créer une nouvelle recrue',
  "make post":'Des postes',
  "makeactivityplaceholder":'Entrez le nom de l\'activité',
  "makeeventplaceholder":'Entrez le nom de l\'événement',
  "makegroupplaceholder":'Entrez le nom de l\'organisation',
  "makebeneficiaryplaceholder":'Entrez le nom de l\'unité bénéficiaire',
  "male":'Homme',
  "maletips":'Homme',
  "mall":'centre commercial',
  "mall ":'centre commercial',
  "mall & auction":'Centre de magasinage et de vente aux enchères',
  "manage members":'Gérer le membre',
  "management":'Gérer',
  "manager":'Gérer',
  "mandatory":'Obligatoire',
  "manual":'Effectuer un manuel',
  "manual / automatic":'Manuel: * Le programme d\'enchères sera contrôlé et démarré par le gestionnaire des enchères. Automatique: * Le programme d\'enchères commencera automatiquement à l\'époque spécifiée dans le programme d\'enchères.',
  "manufacturer":'Fabricant',
  "many people or causes that":'Beaucoup de gens ou de nombreux cas',
  "many people or organisations":'Beaucoup de gens ou d\'organisations',
  "map":'Carte',
  "margin value":'Valeur de dépôt',
  "marital_status":'État civil',
  "mark \'active\' if you want social activity units to be able to reach you":'Le marquage \ \'Working \' permet aux employeurs de visualiser vos informations et de contacter activement l\'entretien.',
  "mark all as read":'Chaussettes vues marquées',
  "married":'Marié',
  "marriedtips":'Marié',
  "Material":'Matériel',
  "max_age":'Vieillir',
  "max_salary":'Au revenu maximal',
  "maximum with":'Maximum avec',
  "may be available to close, let check it again":'Peut se tourner vers un état fermé, vérifier à nouveau.',
  "media":'Image',
  "media & communication":'Médias',
  "member":'Membre',
  "member filter":'Filtrer le membre',
  "member name":'Nom du membre',
  "members":'Membres',
  "membersadmintips":'Le gestionnaire, modifiant la page d\'information d\'un groupe, activités, événements. Les administrateurs ont le droit d\'approuver le contenu et d\'accepter les nouveaux membres',
  "membersmembertips":'Les utilisateurs appartiennent à des groupes, à des activités, à tous les événements',
  "membersownertips":'Le cadre supérieur d\'un groupe, des activités, des souhaits. Le gestionnaire a le droit d\'installer des administrateurs, d\'approuver les nouveaux membres',
  "merge tags":'Morbine avec des mots clés',
  "message has been sent":'Votre message a été envoyé au service d\'assistance, merci pour vos commentaires à Wishare!',
  "methods":'paiement total',
  "min margin value":'Valeur de marge la plus basse',
  "min_age":'Vieillir de',
  "min_salary":'Revenu minimum de',
  "minimum margin value":'Valeur de marge minimale',
  "minimum with":'Le minimum',
  "minute(m)":'minutes (m)',
  "missing data":'Le manque d\'information',
  "mission":'Mission',
  "mission_placeholder":'But / mission du programme',
  "money":'Espèces',
  "month":'Mois',
  "months":'mois',
  "more":'Voir plus',
  "more topics to continue":'Ajouter des sujets pour continuer',
  "multiple choice":'Plusieurs choix',
  "mutual users":'Amis communs',
  "my auctions":'Ma vente aux enchères',
  "my orders":'Ma commande',
  "my questions":'Ma question',
  "my reviews":'Mon commentaire',
  "my stores":'Mon magasin',
  "myfollowers":'Suiveurs',
  "name":'Nom et prénom',
  "name of consignee":'Le nom du destinataire',
  "name of people you want to invite":'Tapez la recherche par le nom que vous souhaitez inviter à rejoindre',
  "nameplaceholder":'Nom et prénom',
  "nature":'Nature',
  "need":'Collecte de fonds',
  "need category type":'Catégorie',
  "need contact":'Informations sur les dons',
  "need from":'Commencer à recevoir',
  "need help":'Besoin d\'aide',
  "need information":'Fund Information',
  "need manager":'Gestion des fonds',
  "need title":'Le titre de campagne de collecte de fonds',
  "need to":'recevoir à jour',
  "needs":'Collecte de fonds',
  "new activity":'Créer une nouvelle activité',
  "new donate option":'Nouvelles options de dons',
  "create organization":'Créer une organisation',
  "new location":'Ajouter un nouvel emplacement',
  "new orders":'Nouvelles commandes',
  "new password":'Un nouveau mot de passe',
  "new products":'Nouveau produit',
  "new question":'Nouvelle question',
  "news":'Nouvelles',
  "next":'Suivant',
  "next item":'Prochain produit',
  "nextevents":'Le prochain événement',
  "NGO, NPO, CSR, Social group":'ONG, NPO, RSE, groupe d\'activités sociales',
  "ngo/ non - profit":'Non-Profit / Non-gouvernemental',
  "no":'Ne sont pas',
  "no categories":'Incertitude',
  "no data yet to display":'aucune donnée à afficher',
  "no item found":'Pas de données',
  "no matches for":'Il n\'y a aucun résultat approprié pour',
  "No matter what you\'re a NPO or an enterprise or just an individual who passionote to make change, you\'re our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.":'Que vous soyez NPO ou Business ou simplement une personne passionnée par le changement, vous êtes toujours nos amis. Wishare sert gratuitement pour commencer. Nous accueillons vos sponsors ou contributions aux fonctionnalités avancées.',
  "no milestones were created":'Aucune étape n\'est créée',
  "No milestones were created. Click here to start creating a schedule":'Aucune étape n\'est créée. Cliquez ici pour commencer à créer un calendrier',
  "no more item":'Il n\'y a pas d\'autres informations',
  "no poverty":'Effacer la pauvreté',
  "no products in your cart":'Il n\'y a pas de produit dans le panier',
  "no profile found":'Aucune information personnelle',
  "no return":'Inférieur',
  "no tags":'Pas de mot-clé',
  "no warranty":'Aucune garantie',
  "non-profit activity":'Activités sans but lucratif',
  "none":'...',
  "NoPoverty":'Xoadoi',
  "normal":'Normal',
  "Not all of us can do great things. But we can do small things with great love.":'Nous ne faisons pas tous de grandes choses. Mais nous pouvons faire de petites choses avec un grand amour.',
  "not good":'Pas bon',
  "not updated":'Pas mettre à jour',
  "not yet comment":'Pas encore commenté',
  "not_activated_account_credentials":'Le compte n\'a pas été activé. Veuillez vérifier votre e-mail pour activer votre compte.',
  "not_exists_account_credentials":'Le compte n\'existe pas. Veuillez vous inscrire à un nouveau compte ou vous connecter via Google, Facebook!',
  "note":'Note',
  "notice description":'Description de l\'avis',
  "notice management":'Gestion des préavis',
  "notice name":'Nom de notification',
  "notices":'Notification',
  "notifications":'Notification',
  "numerical order":'STT',
  "obligations":'Responsabilité',
  "of":'appartenir à',
  "old":'Personne âgée',
  "old password":'ancien mot de passe',
  "old price":'Ancien prix',
  "on":'au-dessus de',
  "on this page":'Table des matières',
  "on_site":'Style de travail',
  "one product of":'Produit de',
  "online auction channel - charity donation with auctions - sell products with auctions":'Chaire d\'enchères en ligne - Don de charité par vente aux enchères - Vendre des produits par enchères',
  "online jobs":'Emplois en ligne',
  "only me":'Seulement moi',
  "open time":'Heures de travail',
  "opportunities":'Recrutement',
  "opportunity":'Bénévoles',
  "oppr_type":'Bénévole',
  "optional":'facultatif',
  "options":'Option',
  "or":'Ou',
  "or a":'ou un',
  "or helping to connect the social mission and the good beneficiaries":'Ou connecter le désir aux activités sociales',
  "or login directly by":'Ou connectez-vous directement avec',
  "Or you update your personal information, leaving the mode \'available to volunteer\', employers will search for you.":'Ou vous mettez à jour vos informations personnelles, en mode «prêt», l\'employeur vous trouvera.',
  "order":'Commande',
  "order code":'Commandes de code',
  "order details":'Détails de la commande',
  "order filter":'Commande de filtre',
  "order information":'Ligne d\'information',
  "order management":'La gestion des commandes',
  "order number":'STT',
  "order status":'Statut de la commande',
  "order user":'Produits utilisateur',
  "ordered at":'Ordonnance quotidienne',
  "orders":'Commande',
  "organization":'Organisation',
  "organizationtips":'Organisation de bénévolat, non-Profit, ONG, ...',
  "other":'Autre',
  "other activities":'D\'autres activités',
  "other auction sessions":'Autres enchères',
  "other display":'Autre affichage',
  "other information":'Les autres informations',
  "other people":'les autres',
  "other reasons":'Autres raisons',
  "other_requirements":'Autres exigences',
  "others":'Autre',
  "othertips":'Communauté, amis, .. Autres groupes',
  "Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.":'Nos outils vous aident à gagner du temps, de l\'efficacité et de la capacité de se développer pour mobiliser les ressources humaines, les ressources financières et effectuer des activités et publier des rapports. Nous nous sommes constamment améliorés pour vous rendre plus pratique et vous aider à optimiser.',
  "out":'Sortir',
  "output":'sortir',
  "Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.":'À l\'étranger, ces dernières années, nous avons la passion, accumuler de l\'expérience, aider à promouvoir les activités sociales pour devenir efficaces. Cette plateforme technologique vous aide à effectuer des activités en fonction. Théorie des changements de principe: se concentrer sur l\'impact, l\'innovation; information sûre; Transmission blanche; Connexion efficace.',
  "owner":'Directeur',
  "package":'Emballer',
  "packed":'Emballé',
  "paragraph":'Petit article',
  "parallel":'Parallèle',
  "parallel mode":'Mode d\'enchères',
  "part-time":'À temps partiel',
  "partnerships":'Relations pour les objectifs',
  "partnerships for the goals":'Partenariat mondial',
  "password":'Mot de passe',
  "password required":'Entrez le mot de passe de connexion',
  "passwordplaceholder":'Mot de passe (tapez au moins 6 caractères)',
  "payment":'Payer',
  "payment completed":'Paiement terminé',
  "payment location":'Adresse de facturation',
  "payment management":'Gestion des paiements',
  "payment method":'Méthodes de payement',
  "payment type":'Type de paiement',
  "peace, justice and strong institutions":'Paix, justice et institutions puissantes',
  "pending":'Attendez d\'accepter',
  "pending orders":'Les commandes attendent la navigation',
  "pending payment":'Attendre le salaire',
  "pending beneficiaries":'Unité bénéficiaire en attente de commentaires',
  "pendingdonations":'Inscrit pour contribuer',
  "pendingtips":'Attendre les commentaires',
  "people with disabilities":'Personnes handicapées',
  "per day":'/ Jour',
  "per hour":'/ Heure',
  "per month":'/ Mois',
  "per quarter":'/ Précieux',
  "per week":'/ Semaine',
  "per year":'/ Année',
  "percent":'pour cent (%)',
  "period time":'Temps de vente aux enchères',
  "person":'Personnes',
  "person_s":'Not',
  "phone":'Numéro de téléphone',
  "phone_number":'Téléphone',
  "phone_placeholder":'Votre numéro de téléphone',
  "photo":'Image',
  "photo gallery":'galerie de photos',
  "photos":'Image',
  "pick tags":'Cliquez sur le mot-clé',
  "place order":'Commande',
  "please complete the basic information":'Veuillez remplir les informations de base',
  "please select an organization, individual or group to be the owner":'Choisissez une organisation, un individu, un groupe à maîtriser',
  "please sign with us, if you agree":'Veuillez vous connecter avec nous, si vous êtes d\'accord',
  "please follow these instructions to complete the donation process.":'Veuillez suivre les instructions suivantes pour terminer le processus de contribution.',
  "policy name":'Nom de la politique',
  "policyhelp":'Politique et termes',
  "policyhelptips":'Confiance et sécurité, conditions d\'utilisation, politiques de sécurité, cookies, principes de la communauté',
  "position":'Titre',
  "position and size your photo":'Ajustez la position et la taille',
  "positive rating":'Évaluation positive',
  "post":'Envoyer l\'écriture',
  "posted":'affiché',
  "prerequisites":'Objets d\'activité',
  "prerequisites_placeholder":'Les sujets sont actifs vers',
  "current job":'Travail actuel',
  "prevevents":'L\'événement précédent',
  "price":'Prix',
  "price and quantity":'Prix ​​et quantité',
  "price type":'Dimension',
  "priority_conditions":'Conditions de priorité',
  "priority_conditions_placeholder":'Considération prioritaire pour les activités',
  "privacy":'Confidentialité',
  "privacy policy":'politique de confidentialité',
  "private":'Privé',
  "privatetips":'Page fermée avec le droit de sécurité le plus élevé. Seuls les membres qui initialisent la page peuvent afficher le contenu, publier des informations.',
  "process":'Traitement',
  "process to payment":'Choisissez une méthode de paiement',
  "processing":'En cours',
  "processingtips":'Attendre l\'examen',
  "product":'Produit',
  "product album":'Ensemble de photos de produit',
  "product attributes":'Propriétés du produit',
  "product categories":'Portefeuille de produits',
  "product category":'Portefeuille de produits',
  "product comment":'Commentaires sur le produit',
  "product description":'Description du produit',
  "product faq":'Questions sur les produits',
  "product filter":'Filtre à produit',
  "product filter by":'Filtrez le produit par',
  "product group":'Groupes de produits',
  "product in the same auction":'Produits avec le même programme',
  "product in the same categories":'Produits dans la même catégorie',
  "product management":'Gestion des produits',
  "product name":'Nom du produit',
  "product price":'Prix ​​du produit',
  "product priority":'Priorité',
  "product rating":'Évaluation de la qualité des produits',
  "product reviews":'Commentaires sur le produit',
  "product reviews and ratings":'Examen et évaluation des produits',
  "product searching":'Rechercher le produit',
  "product type":'Type de produit',
  "products":'Produit',
  "products in same category":'Autres produits dans la même catégorie',
  "products in same store":'Autres produits dans le même magasin',
  "profile":'La gestion des dossiers',
  "profile information":'Informations sur les candidats',
  "program description":'Description du programme',
  "program filter":'Filtrez le nom du programme',
  "program name":'Nom du programme',
  "promote sharing connecting good things to help people everywhere be happy":'Promouvoir le partage de bonnes choses pour aider les gens, partout pour être heureux',
  "promotion":'Promotion',
  "promotion campaign name":'Nom du programme de promotion',
  "promotion campaigns":'Promotions',
  "promotions":'Promotion',
  "propose as owner":'Proposé d\'initier',
  "proposed applicants":'Profil de référence',
  "provide the most accurate address so people can find your store faster":'Fournissez l\'adresse la plus précise pour que tout le monde trouve votre magasin plus rapidement',
  "province":'Une ville de province',
  "public":'Public',
  "public / private":'Public: * Programme d\'enchères pour tous / confidentialité: * Le programme d\'enchères est uniquement pour les membres du programme d\'enchères',
  "public display":'Afficher',
  "public name":'Afficher un nom',
  "publictips":'La page montre publiquement avec tout le monde. Tout le monde peut voir des membres et ce qu\'ils publient',
  "publish reply":'Répondre aux commentaires',
  "published":'Publié',
  "publishedtips":'Le contenu est complet et prêt à publier, tout le monde peut voir, commenter, partager',
  "publishrequest":'Demande d\'approbation',
  "publishrequesttips":'Le contenu est authentifié, vous recevrez des notifications par e-mail dans les 24 heures',
  "quality education":'Éducation de qualité',
  "quantity":'Quantité',
  "question":'Question',
  "question answer":'Questions fréquemment posées',
  "question type":'Sorte de question',
  "questions":'Question',
  "questions and answers":'Questions et réponses',
  "quote":'Citation',
  "ranking":'Rang',
  "rate store":'Évaluation des magasins',
  "rate type":'Taper',
  "rating":'Évaluer',
  "rating from customers":'Examiner des clients',
  "rating successful":'Évaluation réussie',
  "ready to ship":'Prêt à livrer',
  "readytopublish":'Prêt à publier',
  "readytopublishtips":'Le contenu est approprié, prêt à être public avec tout le monde',
  "reasons":'Raison',
  "received":'reçu',
  "received unit":'L\'unité est acceptée',
  "receiver":'Destinataire',
  "receiver information":'Informations du récepteur',
  "recent searches":'La recherche la plus proche',
  "recommend":'Introduire',
  "recommend activities":'Travail',
  "recommend events":'L\'événement est sur le point d\'avoir lieu',
  "recommend groups":'Groupe',
  "recommend needs by following":'Collecte de fonds',
  "recommend store":'Introduction du magasin',
  "recommend tags":'Tags (carte) suggérés',
  "recommend users":'L\'ami peut s\'y habituer',
  "recommend beneficiaries":'Bénéficiaire',
  "recruitment for good":'Recrutement',
  "recruitment question":'Questions de recrutement',
  "recruitment_email":'E-mail de recrutement',
  "recruitment_phone":'Numéro de téléphone',
  "red":'rouge',
  "reduced inequalities":'Réduire les inégalités',
  "ReducedInequalities":'Imprimé',
  "reference":'référence',
  "regconfirmpass":'Entrer le mot de passe',
  "register":'Enregistrer',
  "register enabled":'Autoriser l\'inscription',
  "register to donate":'Inscrivez-vous à un don',
  "register value":'Valeur de l\'enregistrement pour le don',
  "registered number":'Numéro enregistré',
  "registered to donate":'inscrit pour faire un don',
  "regpass":'Mot de passe',
  "regular":'Fréquent',
  "regulartips":'Activité régulière',
  "reject":'Refuser',
  "rejected":'Rejeté',
  "rejected applicants":'Les dossiers ont refusé',
  "rejectedtips":'Le projet de contenu n\'est pas approprié. Veuillez vérifier à nouveau et demander à publier',
  "related tags":'Mots-clés connexes',
  "religion":'Religion',
  "remain":'Restant',
  "remember":'Gardez la connexion',
  "remotely work":'Télécommande',
  "remove":'Effacer',
  "remove comment":'Supprimer les commentaires',
  "remove from your order":'Supprimer de la commande',
  "removed":'Supprimé',
  "replies":'Répondre',
  "reply":'Réponse',
  "reply customer":'Répondre aux clients',
  "reply from the store":'Commentaires du magasin',
  "replyplaceholder":'Tapez et répondez ..',
  "report":'Rapport',
  "report violation shop":'Rapport sur le magasin de violation',
  "reported":'Signalé',
  "reports":'Mauvais préavis',
  "request":'Envoyer l\'exigence',
  "requested":'Les exigences sont envoyées',
  "required":'Obligatoire!',
  "required information fields":'Les informations requises',
  "requirements":'Exigences de l\'emploi',
  "reset":'Re -Create',
  "Reset your password":'Mot de passe oublié',
  "responded":'répondre',
  "responsible consumption and production":'La consommation et la production sont responsables',
  "results with keyword":'Résultats de recherche de mots clés',
  "return conditions":'Conditions de retour',
  "return period":'Temps de retour',
  "return period type":'Type de retour',
  "return period value":'Période de renvois',
  "return policy":'Politique de retour',
  "return to draft":'Retour à l\'état de rédaction',
  "returned":'Rendre',
  "retype new password":'entrer un nouveau mot de passe',
  "Retype password":'Entrer le mot de passe',
  "review and rating on store":'Commentaire et magasin',
  "review management":'Commentaire du gestionnaire',
  "review store":'Commentaire de magasin',
  "reviews":'Commentaire',
  "role":'Rôle',
  "role description":'Décrire le rôle',
  "rotate":'Tourner',
  "running promotion campaigns":'La promotion est en cours d\'exécution',
  "salary":'Revenu',
  "salary period":'Période de salaire',
  "sale":'Réduire',
  "sanitary food":'Nourriture fraîche',
  "save":'Sauvegarder',
  "save data":'Enregistrer des informations',
  "save question":'Sauver la question',
  "scale":'Agrandir',
  "scan code on Wishare for direct access to the target screen":'Scanner le code sur Wishare pour passer à l\'écran cible',
  "scan qr-code":'Code de numérisation QR',
  "schedule":'Calendrier',
  "school":'École',
  "School Book":'Cahier de texte',
  "sdg":'Objectifs de développement durable',
  "search":'Recherche',
  "search activities":'Rechercher des activités',
  "search category":'Trouvez la catégorie',
  "search events":'Trouver des événements',
  "search groups":'Trouver des groupes',
  "search groups users activities you want to be hosts":'Trouver des membres, des groupes, des activités peuvent devenir le manager',
  "search groups,users,activities you want to be hosts":'Recherche de groupes, activités en tant que représentants',
  "search in wishop":'Recherche dans Wishop ...',
  "search members":'Trouver des membres',
  "search needs":'Trouver des projets de collecte de fonds',
  "search jobs":'Trouver un travail',
  "search product to attach":'Rechercher l\'élément inclus',
  "search selected category":'Trouvez la catégorie sélectionnée',
  "search user":'Rechercher les utilisateurs',
  "search beneficiaries":'Rechercher des bénéficiaires ..',
  "searching":'recherche',
  "searching result is empty":'0 Résultats de la recherche',
  "searching volunteers":'Trouver les ressources humaines',
  "searchplaceholder":'Tapez les mots clés.',
  "seconds(s)":'secondes (s)',
  "secret":'Secret',
  "secrettips":'La page d\'affichage interne, le nouveau membre officiel peut voir les membres, partager, publier des notifications',
  "security policy":'politique de confidentialité',
  "see all":'Voir tout',
  "see less":'enregistrer',
  "see map":'Voir l\'emplacement',
  "see more":'Continuer',
  "see more jobs":'Voir plus de recrutement',
  "seek financial capital for good initiatives":'Rechercher des capitaux financiers pour de bonnes initiatives',
  "select":'Sélectionner',
  "select all":'Tout sélectionner',
  "select attribute...":'Choisissez des propriétés ...',
  "select location":'Sélectionnez l\'adresse',
  "select location on map":'Sélectionnez l\'emplacement sur la carte',
  "select option":'Sélectionnez l\'option',
  "select the payment method":'Choisissez une méthode de paiement',
  "select the product catalog for your store":'Sélectionnez les catégories de produits pour votre magasin',
  "select the topic category you are interested in by clicking on the items below":'Sélectionnez la liste des intérêts des intérêts qui vous intéressent en cliquant sur les éléments ci-dessous',
  "select_conditions":'Conditions d\'approbation',
  "select_conditions_placeholder":'Conditions d\'approbation, filtre au sol pour le programme',
  "Select...":'Sélectionner',
  "Sell as lot":'Vendre',
  "selling price":'Prix',
  "send a message to <%= title %> with questions or comments about this volunteer opportunity.":'Envoyez un message à <% = title%> Partagez plus d\'informations sur votre introduction à l\'employeur',
  "send feedback":'Envoyer à Wishare',
  "send to":'Envoyer à',
  "send to an activity":'Envoyer à l\'activité',
  "sender":'Expéditeur',
  "senior advisor":'conseiller principal',
  "sent":'envoyé',
  "sequence":'Séquentiellement',
  "sequence / parallel":'Séquence: * Les produits du même programme seront vendus aux enchères chaque produit dans la commande. Il n\'y a qu\'une seule session à un moment / parallèle: * Les produits du même programme seront vendus aux enchères en même temps à de nombreuses sessions différentes.',
  "set as default":'Placer comme l\'adresse par défaut',
  "setting":'Paramètre',
  "settings":'Paramètre',
  "share":'Partager',
  "share options":'Partager avec',
  "share to multipal pages":'Partagez avec de nombreuses pages dans Wishare',
  "share to my page":'Partagez ma page',
  "share with everyone":'Partager avec tout le monde',
  "shared":'partagé',
  "shipped":'Être transporté',
  "shop":'Boutique',
  "shop description":'Introduction du magasin',
  "shop for you":'Store pour vous',
  "shop infomation":'Informations sur les magasins',
  "shop product":'Produits du magasin',
  "shop products faq":'Le magasin a répondu',
  "shopping":'Achats',
  "shopping management":'Responsable des achats',
  "short":'Effondrement',
  "should change status to publishrequest to complete":'Besoin de transférer la page thaïlandaise pour demander à être terminée',
  "show":'afficher',
  "show map":'Carte d\'affichage',
  "show profile":'Voir le profil',
  "show result for":'Afficher les résultats de recherche pour',
  "sign":'Signe',
  "Sign in":'Connexion',
  "sign in to connect":'Connectez-vous pour connecter la communauté',
  "Sign up":'Enregistrer',
  "signed":'Signé',
  "signin":'Connexion',
  "silent mode":'Silencieux',
  "single":'Seul',
  "singletips":'Seul',
  "sitemap":'diagramme',
  "Size":'Taille',
  "skills":'Compétence avancée',
  "social activities exits everywhere, form individuals, NPOs or enterprises":'Les activités sociales sont présentes partout, des initiatives des individus, des organisations ou des entreprises à but non lucratif',
  "social capital":'Ressources \ Nows',
  "social worker":'Secteur du travail social',
  "sold":'Vendu',
  "sold by":'Vendu par',
  "sold price":'Le prix a été vendu',
  "sold quantity":'Quantité vendue',
  "specific return policy":'Politique de retour privée du produit',
  "specific warranty policy":'Politique de garantie du produit',
  "specific_activity":'Activités spécifiques',
  "specific_activity_name":'Envoyer à des activités spécifiques',
  "specifications":'Caractéristiques',
  "sponsors for the project":'Sponsors du projet',
  "sport":'sport',
  "sports & entertainment":'Sports récréatifs',
  "Sports & Travel":'Sports et tourisme',
  "star":'Étoile',
  "stars":'Étoile',
  "start":'Commencer',
  "start at":'Commencer à',
  "start creating activities, events or groups to declare organizations seeking volunteers (if you\'ve shared organizational information, go to step 3)":'Commencez à créer des activités, des événements ou des groupes pour déclarer l\'organisation, chercher des bénévoles (si vous avez partagé les informations de l\'organisation, passez à l\'étape 3)',
  "start date":'Commence jour',
  "start_at":'Commencer',
  "start_date":'Date de démarrage du travail?',
  "start_time":'Heure de début',
  "status":'Statut',
  "statusplaceholder":'Sélectionnez l\'état',
  "step":'Marcher',
  "step 1":'Étape 1',
  "step 2":'Étape 2',
  "step 3":'Étape 3',
  "step 4":'Étape 4',
  "step 5":'Étape 5',
  "step 6":'Étape 6',
  "Steps to create an activity:":'Étapes pour créer une activité:',
  "stop donate":'Arrêtez de vous inscrire',
  "store address":'Adresse du magasin',
  "store description":'Description du magasin',
  "store manager":'Gérant de magasin',
  "store name":'Nom du magasin',
  "store setting":'Installation de magasin',
  "strategic partner":'partenariat stratégique',
  "strategic sponsor":'Sponsor stratégique',
  "strengthen successful connections based on the":'Améliorer les connexions réussies en fonction de la',
  "sub total":'total',
  "submit":'Accepter',
  "subscription email":'Courriel (inscrivez-vous pour recevoir des nouvelles)',
  "subscription_email":'Vous pouvez vous inscrire à un e-mail pour recevoir un nouvel avis du système',
  "success":'Succès',
  "successful":'Succès',
  "support":'Donner',
  "Support the UN Global Goals":'Soutenir les objectifs mondiaux des Nations Unies',
  "supported":'prise en charge',
  "supporthelp":'Centre de soutien',
  "supporthelptips":'Instructions pour une utilisation, Q&R',
  "sustainable cities and communities":'Communauté urbaine et durable',
  "SustainableCitiesAndCommunities":'Dothivacongdong',
  "system error":'Avis de la demande',
  "tag":'Cocher',
  "tags":'Mots clés',
  "tags for you":'Mots-clés pour vous',
  "tagsplaceholder":'Sélectionner marqué',
  "take":'Recevoir',
  "target":'Cible',
  "teacher, teaching assistant":'Majorité',
  "team":'Équipe',
  "temporary winner":'Gagnant temporaire',
  "terms":'Règles',
  "terms of use":'conditions d\'utilisation',
  "thank you":'Merci',
  "thank you for registering to donate":'Merci de votre inscription',
  "Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.":'Merci d\'avoir pris le temps de remplir le formulaire de rétroaction en ligne. En envoyant des commentaires, vous nous soutenez de comprendre quelles fonctionnalités fonctionnent bien et nous suggèrent de suggérer des suggestions pour continuer à améliorer.',
  "thank you for your feed back to product and shop":'Merci d\'avoir répondu au produit et au magasin',
  "the 17 goals":'17 buts des Nations Unies',
  "the accept action was successful":'Une action acceptable a réussi',
  "the activation email has been sent to":'L\'e-mail activé a été envoyé',
  "the causes that we serve":'L\'argument que nous existons',
  "the community creates social impact, does the good things and lets the wind blow away":'La communauté crée un impact social, \ n um bien, puis laissez le vent balayer',
  "The following topics are currently merged into this topic:":'Les sujets suivants sont actuellement fusionnés sur ce sujet:',
  "The information given within the Feedback Form will be used for service improvement only and are strictly confidential.":'Les informations que vous fournissez sous le formulaire seront utilisées par nous pour améliorer la qualité du service et être absolument confidentielle.',
  "the information has been saved successful":'Les informations ont été reconnues avec succès',
  "the item has been saved":'Le contenu est terminé',
  "the items you are you had accepted to be there hosts":'Les sujets ont accepté comme une unité représentative',
  "the items you had been requested to be a host":'Les sujets qui vous ont été invités à être représentatifs',
  "the option has been added successful":'L\'option a été ajoutée au succès',
  "The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.":'La plate-forme vous aide à profiter du développement technologique en mettant l\'accent sur les activités sociales et vous immergez dans une communauté de lage avec le désir d\'apporter un grand impact. La plate-forme peut accéder à plusieurs pages et permettre aux gens d\'interagir en temps réel, mobiliser rapidement un fort soutien de la communauté.',
  "the recruitment in the period":'Date limite de soumission',
  "the registration of funding for the project":'Sanctions pour le projet',
  "the reject action was successful":'L\'acte de rejet a réussi',
  "the social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission":'Activités sociales, les amateurs créent une influence en mobilisant les ressources communautaires et en mettant en œuvre leur bonne mission',
  "the warning has been sent to admin":'Un avis de contenu inapproprié a été envoyé',
  "there are items that are not shown due to the privacy policies of those items":'Certains articles ne s\'affichent pas publiquement en raison de politiques de confidentialité et de confidentialité',
  "there is no need for transfer":'Pas d\'aspiration à aider',
  "This function is currently not open to everyone, please contact the Wishare administrator":'Cette fonction n\'est actuellement pas ouverte à tout le monde, veuillez contacter l\'administrateur Wishare',
  "This page is collected and indexed by Wishare":'Le contenu est collecté et indexé par Wishare',
  "This page is collected and indexed by Wishare so that the community can easily find and contact the program":'Cette page est collectée et indexée par Wishare pour la communauté pour trouver et contacter facilement le programme. Il y a beaucoup d\'informations qui n\'ont pas été mises à jour ou les erreurs, veuillez contacter le programme directement avec le programme pour le dernier support d\'information. Wishare n\'est pas responsable des activités du programme.',
  "this product will extract":'Ce produit extraitra',
  "this will send by admin":'Ce contenu sera publié par l\'administrateur',
  "tick size":'Pas de prix',
  "time":'Temps',
  "time and location":'Temps, lieu',
  "time characteristic":'Propriétés du temps',
  "time open":'Temps d\'ouverture',
  "time_close":'Heure de fin',
  "time_open":'Heure de début',
  "time_type":'Temps de travail',
  "timeline":'Calendrier',
  "title":'Titre',
  "titleplaceholder":'Tapez le titre.',
  "to":'arriver',
  "to develop the community or":'Pour développer une bonne communauté',
  "to donate":'donner',
  "To help beneficiaries find your activity, create your activity on Wishare platform.":'Pour aider les bénéficiaires à rechercher votre activité, créez un portail multimédia sur votre activité sur la plate-forme Wishare.',
  "To help beneficiaries find your need, create your activity on Wishare platform.":'Pour aider les bénéficiaires à trouver vos besoins, créez votre activité sur la plate-forme Wishare.',
  "to help us continue serving the community":'Pour nous aider à continuer à servir la communauté',
  "to now":'jusqu\'ici',
  "to support us spread the goodness":'Pour nous aider à répandre la gentillesse',
  "token_expired":'La session n\'est pas valide ou expirée. Veuillez vous connecter à nouveau',
  "top":'Haut',
  "total":'Total',
  "total donation":'Don total',
  "total donation values":'inscrit pour contribuer',
  "total payment":'paiement total',
  "total product amount":'Total pré-prénatal',
  "total values":'Valeur totale',
  "total_activities":'Travail',
  "total_activities_official":'Activité (officiel)',
  "total_activities_official_tips":'Les activités sont créées et exploitées par la communauté',
  "total_activities_tips":'Les activités sociales sont créées (ou indexées par Wishare)',
  "total_address":'Associé',
  "total_crowdfunding_amount":'Collecte de fonds',
  "total_crowdfunding_amount_tips":'Fonds pour les activités sociales et le travail',
  "total_crowdfundings":'Fonds communautaires',
  "total_crowdfundings_tips":'Activités de collecte de fonds communautaires à des fins de soutien social et créatives',
  "total_donators":'Donateur total',
  "total_events":'Événement',
  "total_events_happening":'L\'événement a lieu',
  "total_events_happening_tips":'Des projets ont eu lieu à l\'heure actuelle',
  "total_events_incoming":'Événements (sur le point de se produire)',
  "total_events_incoming_tips":'Les événements sont sur le point d\'avoir lieu',
  "total_events_tips":'L\'événement entier était et sur le point d\'avoir lieu',
  "total_followers":'Suiveurs',
  "total_following":'En train de regarder',
  "total_groups":'Groupe',
  "total_links":'lien',
  "total_links_tips":'Nombre de liens, connexions des souhaits aux activités de soutien',
  "total_members":'Membre',
  "total_opportunities":'Recrutement',
  "total_opportunities_tips":'Pour les bénévoles et le travail à impact social',
  "total_shares":'Partager',
  "total_support":'Donner',
  "total_views":'Heures d\'accès',
  "total_volunteers":'Bénévoles',
  "total_volunteers_tips":'Connecter les personnes dans le besoin et recevoir des ressources humaines, promouvoir et optimiser le travail social',
  "total_votes":'Donner',
  "total_beneficiaries":'Bénéficiaire',
  "total_beneficiaries_done":'Unité bénéficiaire réussie',
  "total_beneficiaries_done_tips":'Les unités bénéficiaires ont été achevées',
  "total_beneficiaries_tips":'Toutes les unités bénéficiaires sont envoyées sur Wishare',
  "trademark":'Marque',
  "transfer":'Déplacer',
  "transfer all":'Transférer tout',
  "trends":'Remarquable',
  "trust and safety":'Confiance et sécurité',
  "trustworthy efficient transparent":'Confiance, efficacité, transparence',
  "tutorial":'Manuel de l\'Utilisateur',
  "type":'Taper',
  "type new password":'Entrez votre nouveau mot de passe',
  "type of group":'Type de groupe',
  "type of work":'Type de travail',
  "type old password":'Entrez dans le vieux morceau de miel',
  "type to search":'Entrez la recherche',
  "type_association":'Les associations',
  "type_associationtips":'Les associations',
  "type_company":'Entreprise',
  "type_companytips":'Entreprise',
  "type_foundation":'Fonds',
  "type_foundationtips":'Fonds',
  "type_group":'Groupe',
  "type_grouptips":'Groupe',
  "type_ngo":'Organisations non-gouvernementales',
  "type_ngotips":'Organisations non-gouvernementales',
  "type_npo":'Organisation à but non lucratif',
  "type_npotips":'Organisation à but non lucratif',
  "type_organization":'Organisation',
  "type_organizationtips":'Organisation',
  "type_religion":'Organisation religieuse',
  "type_religiontips":'Organisation religieuse',
  "type_school":'École',
  "type_schooltips":'École',
  "typical products":'Les produits se démarquent',
  "typical shops":'Magasin de premier plan',
  "typing":'Taper',
  "undefined":'Indéfini',
  "undefinedtips":'Indéfini',
  "unfilter":'Filtre',
  "unfollow":'Ne pas suivre',
  "unit":'Unité',
  "unlimited":'Illimité',
  "unlimited age":'vieillissement',
  "unread":'Pas vu',
  "update":'Mise à jour',
  "update your profile":'Mettre à jour le profil',
  "upload images from your computer":'Téléchargez des photos depuis votre ordinateur',
  "user":'Utilisateur',
  "user product faq":'Ta question',
  "user profile":'Page personnelle',
  "user settings":'Gestion de compte',
  "user_not_found":'La session de connexion est expirée ou non valide, veuillez vous connecter à nouveau',
  "username":'Compte',
  "users":'Utilisateur',
  "value":'Valeur',
  "value type":'Taper',
  "value_winner":'Valeur de la victoire',
  "variants":'Variante de produit',
  "verify":'Précision',
  "verifying":'Authentique',
  "view more":'Télécharger plus',
  "view profile":'Voir le profil',
  "vision":'Vision',
  "Visually impaired people":'Aveugle',
  "volunteer":'Bénévoles',
  "volunteer & jobs":'Bénévoles et emplois',
  "volunteer_profile":'Informations sur les bénévoles',
  "volunteers":'Bénévoles',
  "vote":'Donner',
  "voted":'Prise en charge',
  "wage":'Salaire',
  "wait for reply":'Attendre les commentaires',
  "waiting":'En attendant',
  "waiting applicants":'Le profil n\'a pas été vu',
  "waiting need":'Recevoir des dons',
  "want to help":'Vouloir aider',
  "ward":'Quartiers',
  "warned":'averti',
  "warranty & return":'Garantie et retour',
  "warranty conditions":'Conditions de garantie',
  "warranty period":'Période de garantie',
  "warranty period type":'Type de garantie',
  "warranty period value":'Période de garantie',
  "warranty policy":'Politique de garantie',
  "warranty-policies":'Politique de garantie',
  "warranty-return":'Garantie et retour',
  "was accepted for job at":'a été accepté pour le poste à',
  "we believe that sharing and connecting these beneficiaries will create a great impact":'Nous pensons que le partage et la connexion de ces désirs créeront un grand impact',
  "We welcome any feedback, please feel free to contact us":'Nous accueillons tous les commentaires, veuillez nous contacter',
  "we\'ve":'Avait',
  "we\'ve found <%= number %> results":'Trouvé <% = nombre%> Résultats',
  "website":'Page d\'accueil',
  "website_placeholer":'(www.example.com)',
  "week":'Semaine',
  "weeks":'Semaine',
  "weight":'Masse',
  "welcome to become wishop salesperson":'Bienvenue pour devenir un vendeur de Wishop !!!',
  "what categories are your interests?":'De quel groupe de catégorie vous souciez-vous?',
  "what is your position?":'Quel est ton titre?',
  "what we connect":'Nous nous connectons?',
  "what you can do?":'Que pouvez-vous faire?',
  "what you need the crowdfunding for":'Vous créez des activités de collecte de fonds communautaires',
  "what you need volunteers for":'Vous devez rechercher des bénévoles pour:',
  "when you have a petition for you or for someone":'Lorsque vous avez une recommandation pour vous ou pour quelqu\'un',
  "where did you work?":'Où avez-vous travaillé?',
  "where does the opportunity take place?":'Où se déroule le lieu de travail?',
  "which school did you study?":'Dans quelle école avez-vous étudié?',
  "who can do for good and make great impact by sharing human capital, financial capital to realise the social mision":'Les gens peuvent faire du bien et créer une grande influence en partageant le capital humain et le capital financier',
  "why partner with wishare ?":'Pourquoi coopérer avec Wishare?',
  "Width":'Largeur',
  "will end in":'se terminera par',
  "win price":'Prix ​​de la victoire',
  "win_price":'Prix ​​de la victoire',
  "winner":'Gagnant',
  "beneficiary":'Les bénéficiaires',
  "beneficiary list":'Bénéficiaire',
  "wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people":'Wishare est un fondement de la responsabilité sociale partagée pour connecter les souhaits pour créer des impacts, des innovations et de bonnes personnes.',
  "Wishare is willing to adjust the content or transfer the page owner right to the program upon request.":'Wishare est prêt à ajuster le contenu, à fermer la page ou à transférer la propriété du programme en cas de besoin.',
  "beneficiaries":'Les bénéficiaires',
  "beneficiaries for good":'Postuler',
  "wishop regulation":'de Wishop a prescrit',
  "beneficiarytips":'Le souhait est une bonne chose que vous vouliez faire. Vous pouvez lancer un nouveau souhait de partager des circonstances difficiles et des problèmes urgents qui nécessitent un soutien, pour mobiliser le soutien de la communauté.',
  "with":'avec',
  "with keyword":'avec des mots clés',
  "won session":'Gagné',
  "work":'Emploi',
  "work fit for":'Travaille adaptée à',
  "working date":'Jour ouvrable',
  "working time":'Temps de travail',
  "workplace":'Lieu de travail',
  "write comment about store":'Partagez des sentiments sur le magasin',
  "year":'Année',
  "years":'année',
  "yes":'Ont',
  "yes / no":'Oui Non',
  "you":'Ami',
  "you are the highest bidder":'Vous êtes le plus grand payeur',
  "you can add or change information in the settings":'Vous pouvez ajouter ou modifier les informations dans les paramètres.',
  "you don\'t need login to do for good in this platform":'Vous n\'avez pas besoin de vous connecter pour bien fonctionner sur cette plateforme',
  "you have 0 new message":'Vous avez 0 nouveaux avis',
  "you have completed the registration steps please confirm the information again to finish":'Vous avez terminé les étapes d\'inscription, veuillez confirmer à nouveau les informations pour terminer',
  "You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.":'Vous devez également lire et comprendre la mobilisation avant de contribuer. Nous avons besoin de vous, mais vous êtes responsable de vos actions. Nous essayons de définir un mécanisme pour limiter les problèmes négatifs, mais refuser les responsabilités liées à cette activité de collecte de fonds. Nous ne sommes qu\'une plate-forme de support.',
  "you should use a photo to help people recognize your store faster":'Vous devez utiliser une photo pour aider les gens à reconnaître votre magasin plus rapidement',
  "Create quickly in steps if you don\'t have your own activities and groups yet":'Créez rapidement avec les étapes si vous n\'avez toujours pas vos propres activités et groupes',
  "you voted it":'Vous soutenez',
  "you want to share or to implement your good initiative to the community":'Vous souhaitez partager ou mettre en œuvre votre bonne initiative avec la communauté',
  "You\'re welcome to be":'Vous pouvez devenir',
  "your activities":'Votre activité',
  "your auction name":'Votre nom d\'enchères',
  "your bid":'Le prix que tu as joué',
  "your cart currently has <%= number %> pending payment, you want to continue shopping now.":'Le panier a <% = nombre%> le produit attend le paiement, vous souhaitez continuer à faire du shopping dès maintenant.',
  "your cart currently has <%= number %> products, you want to continue shopping now.":'Le panier a <% = nombre%> de produits, vous souhaitez continuer à faire du shopping dès maintenant.',
  "your categories":'Votre catégorie',
  "your comment":'Votre commentaire',
  "your comment about store":'Votre commentaire sur le magasin',
  "your friend":'Ami',
  "your group":'Ton équipe',
  "your order status will be update in":'Vous pouvez revoir la commande',
  "your order will be transfer as soon as possible":'Le produit sera livré par le magasin au moyen le plus rapide',
  "your orders":'Votre commande',
  "your pending amount":'Vous vous êtes inscrit',
  "your profile":'Informations sur vous',
  "your profile has been successfully submitted to the employer!":'Votre profil a été envoyé avec succès à l\'employeur!',
  "your store name":'Nom de votre magasin',
  "zero hunger":'Plus faim',
  "zones":'Zone de soutien',
  "zones_placeholder":'La gamme régionale est soutenue par des activités',
  "give":'Donné',
  "receive":'Recevoir',
  "notes on the order":'Notes sur la commande',
  "closed status":'État fermé',
  "close status":'Fermé',
  "blocked status":'Blocus',
  "block status":'Effectuer un blocus',
  "This content has completed the process, closing the status marks that you are no longer working on this content.":'Ce contenu a terminé le processus, en fermant les marques d\'état que vous ne continuez plus à travailler sur ce contenu.',
  "This content is required to blockade, Sessions on this content will be blocked from now.":'Ce contenu est obligatoire pour le blocus, sur ce contenu sera bloqué à partir de maintenant.',
  "closed statustips":'Ce contenu a terminé le processus, en fermant les marques d\'état que vous ne continuez plus à travailler sur ce contenu.',
  "Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you":'Désolé les clients. Nous n\'avons actuellement que des produits pour vous servir. Veuillez ne pas mettre plus que la quantité. Merci',
  "let\'s start for free":'Démarrage gratuit',
  "delivery method":'Méthode de transport',
  "Please choose a delivery method":'Veuillez choisir la méthode de livraison',
  "cancel":'Annuler',
  "Click here to create first contact":'Cliquez ici pour créer des informations de contact',
  "Enter the email address associated with your account, and we will email you a link to reset your password.":'Entrez l\'adresse e-mail associée à votre compte, nous vous enverrons un lien pour réinitialiser votre mot de passe par e-mail.',
  "write":'écrire',
  "copy link":'Copier le lien',
  "share link via messenger":'Partager via Messenger',
  "share on wishare":'Partager chez Wishare',
  "social network":'Réseau social',
  "Don\'t have a volunteer for this activity or event yet?":'Vous n\'avez pas de bénévoles pour les activités, cet événement?',
  "Tap the \"{1}\" button to complete and receive the Donation Code":'Cliquez sur le bouton "{1}" pour terminer et recevoir un code de don',
  "share link via QR Code":'Partager des liens via des photos QR',
  "User use phone cameras to quickly access this link":'Les utilisateurs utilisent la caméra du téléphone pour accéder rapidement à ce chemin',
  "Below is an incomplete or unconfirmed registration by a fundraising campaign manager.":'Ce qui suit est l\'inscription qui n\'a pas été achevée ou n\'a pas été confirmée par le directeur de campagne de collecte de fonds.',
  "Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.":'Voici votre don. S\'il y a quelque chose qui ne va pas, veuillez contacter l\'administrateur de la campagne. Nous vous remercions d\'avoir contribué.',
  "delete register of donation":'Supprimer l\'inscription pour le don',
  "are you sure to remove this donation?":'Êtes-vous sûr de supprimer ce don?',
  "approve donation":'Parcourir le contenu du don',
  "contact phone":'Numéro de téléphone de contact',
  "less":'Enregistrer',
  "your donation":'Votre don',
  "This view screen is for the admin of the campaign":'Cet écran de vue est pour l\'administrateur de la campagne',
  "On behalf of the fundraising campaign, we would like to sincerely thank our sponsors":'Au nom de la campagne de collecte de fonds, nous remercions sincèrement les sponsors',
  "Select and tap on the package to which you can donate":'Choisissez et cliquez sur le package que vous pouvez donner',
  "Enter another value you want to donate":'Entrez une autre valeur que vous souhaitez donner',
  "search with":'Rechercher',
  "for admins":'Pour les administrateurs',
  "your name":'Votre nom',
  "contact email":'Contact par e-mail',
  "toward":'Vers',
  "giving code":'Donner un code',
  "delete giving":'Supprimer la pièce donnée',
  "This giving will be remove?":'Cet objet va être supprimé?',
  "beneficiary account no":'Numéro de compte du bénificiare',
  "beneficiary account name":'Nom du compte bénéficiaire',
  "transaction memo":'Contenu du transfert',
  "beneficiary bank name":'Nom de banque',
  "beneficiary info":'Profiter des informations',
  "This item will be deleted and cannot be recovered":'Cette section sera supprimée et ne peut pas être restaurée',
  "Value on conversion for items: (quantity) / (Unit)":'La valeur ci-dessus correspond à l\'élément: (quantité) / (unité)',
  "donate goods equivalent to":'faire un don équivalent des articles',
  "donation type":'Forme de don',
  "item":'Articles',
  "registered to goods equivalent to":'Inscrit à des éléments correspondant à',
  "cash":'Espèces',
  "goods":'Articles',
  "cash donations":'Spectacle de Kim',
  "goods donations":'article',
  "It is more blessed to give than to receive.":'Donnez-le plus béni que de réception.',
  "(Acts 20:35).":'(Actes 20:35).',
  "Holy Bible":'Bible',
  "A dream you dream alone is only a dream. A dream you dream together is reality.":'Le rêve que vous rêvez seul n\'est qu\'un rêve. Le rêve que vous rêvez avec les autres est la réalité.',
  "gave away":'émis',
  "giving type":'Former',
  "giving value":'Donner de la valeur',
  "with an goods equivalent of":'avec des marchandises équivalentes',
  "with a cash amount of":'Avec l\'argent est',
  "Are you sure you agreed to accept this person for the recruitment?":'Êtes-vous sûr d\'avoir accepté de recevoir cette personne pour le recrutement',
  "Are you sure you will reject this application for recruitment?":'Êtes-vous sûr de refuser ce dossier pour le recrutement',
  "Time to be able to do volunteer work":'Le temps peut faire du bénévolat',
  "add time to be able to volunteer":'Ajouter du temps au bénévolat',
  "Kind of work what you like":'Le type de travail que vous aimez',
  "The workplace (ward/district/province/country) that is right for you":'Le lieu de travail (Ward / District / Provincial / Country) vous convient',
  "you want":'Tu veux',
  "anything":'Quoi que ce soit',
  "anywhere":'Partout',
  "learn about tools":'Découvrez les outils',
  "create an activity for free":'Créer des activités gratuites',
  "how does it work":'Comment opérer',
  "petition & connect":'Pétition et connexion',
  "petition":'Postuler',
  "recruitment":'Recrutement',
  "sales":'Vendre',
  "inputs":'Saisir',
  "impacts":'Impact',
  "outputs/outcome":'Sortie / résultats',
  "fundraising, donation activity":'Mobilisation des capitaux, campagne de don',
  "recruitment, search for volunteer jobs":'Recruter du personnel, chercher un emploi de bénévolat',
  "sales, create financial resources for social activities":'Vendre, créer des ressources financières pour les activités sociales',
  "auction, create financial resources for social activities":'Vente aux enchères, créer des ressources financières pour les activités sociales',
  "petition, connect social activities":'Pétition, activités sociales',
  "community":'Communauté',
  "0$ - free services":'0 $ - Service gratuit',
  "serve the community":'servir la communauté',
  "donate - sponsor":'Don - parrainage',
  "keep us running":'Aidez-nous à opérer',
  "tools toward impact":'Outils pour créer un impact',
  "Support {1} campaign management tools to create impact according to the model of “{2}”.":'Support {1} Les outils de gestion de campagne créent le modèle "{2}".',
  "organization - social activities":'Organisation - Activités sociales',
  "theory of change":'Théorie des changements',
  "Strategic planning":'Planification stratégique',
  "effects":'Efficace',
  "17 SDG":'17 Objectifs de développement durable',
  "create your account":'Créer un compte gratuit',
  "launch your activity":'Initialisez votre activité',
  "A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.":'Créez un compte pour commencer votre voyage et surveillez votre impact. Vous pouvez également créer une page d\'organisation pour gérer vos opérations et campagnes.',
  "launch your campaign":'Écartez votre campagne',
  "Manage your recruitment, crowdfunding, auction, selling, events and so on.":'Gérer et diffuser la campagne de recrutement, collecter votre fonds communautaire à la communauté.',
  "An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.":'Un individu ou une organisation peut facilement créer des activités et des événements. Chaque activité est chaque projet, chaque mission que vous contribuez à la communauté.',
  "commitment":'Commettre',
  "Our solution is constantly improving, but some things will never change":'Notre solution est constamment améliorée, mais certaines choses ne changeront jamais.',
  "Totally free, with no fees or commission":'Entièrement gratuit (sans frais ni commission)',
  "0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.":'0 $. C\'est le coût d\'utilisation de Wishare, maintenant et pour toujours. Nous poursuivons ce modèle pour créer un grand impact pour la communauté.',
  "Continuous Improvement":'Amélioration continue',
  "Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.":'Améliorez toujours notre expérience utilisateur en fonction de notre profonde écoute et de nos connaissances sur les activités de la NPO, l\'ONG, la RSE, les activités communautaires. Nous aidons à numériser et à surveiller votre impact, le rendant amical et pratique.',
  "Free business model":'Modèle commercial gratuit',
  "Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.":'Le désir de fournir des services gratuits à la communauté, notre modèle de revenus est basé sur le parrainage, les contributions volontaires de la communauté et de la publicité. Il nous aide à maintenir et à développer la plate-forme en continu.',
  "Campaign on Wishare":'Campagne sur Wishare',
  "Wishare and Partners":'Wishare et partenaires',
  "SDG & Category":'17 Objectifs de développement durable',
  "totally free":'Tout gratuit',
  "now and forever.":'Comme ça pour toujours.',
  "toward impacts":'Impact sur',
  "our partners":'Partenaire',
  "Fundraising and charity management platform.":'Plateforme de collecte de fonds et gestion de charité.',
  "Free and transparent.":'Libre et transparence.',
  "mark":'Cocher',
  "givings":'Donné',
  "cash givings":'Spectacle de Kim',
  "goods givings":'Artefact',
  "impact categories":'La catégorie de la création d\'impact',
  "Values are summed up from the organization and its activities.":'La valeur est synthétisée de l\'organisation et des activités de l\'organisation.',
  "co-hosts":'Co-organisateur',
  "Use for: blood donation, ... form of donation calculated on each donation":'Utilisation pour: don de sang, ... forme de contributions sur chaque contribution',
  "Use for: donate money, donate goods, ... Contributions can be converted to monetary value":'Habitué pour: faire un don, faire un don, ... les contributions peuvent être converties en valeur monétaire',
  "donation by count":'Don à queue',
  "donation by value":'Faire un don en fonction de la valeur',
  "blood unit":'Unité de sang',
  "blood":'Don de sang',
  "You register to contribute":'Vous vous inscrivez pour faire un don',
  "The organizers will contact you in case of needed. Please add some information":'Les organisateurs vous contacteront en cas de nécessité. Veuillez ajouter des informations',
  "contact information":'Informations de contact',
  "impacts report":'Rapport d\'impact',
  "my givings":'Émis',
  "participate in volunteering":'Participer au bénévolat',
  "pending donations":'Don en attente de terminer',
  "create crowdfunding":'Créer une campagne de mobilisation',
  "what donation?":'Que faire don?',
  "Choose the right donation type for your campaign":'Choisissez le type de don qui convient à votre campagne',
  "Money/Goods... can allow donations. (If target is unlimited, please enter value \"0\")":'L\'argent / les marchandises ... peut autoriser les dons. (Si l\'objectif est illimité, veuillez saisir la valeur "0")',
  "..., which and how much?":'... type ?, Combien?',
  "Choose a campaign creator/initiator for the campaign":'Sélectionnez la campagne / initiateur de la campagne',
  "..., and whose?":'... OMS?',
  "Choose the impacts that the campaign towards":'Choisissez les effets que la campagne vise',
  "Write a more descriptive description of your campaign information, which helps the community better understand your campaign.":'Écrivez une description plus détaillée de vos informations de campagne, ce qui aide la communauté à mieux comprendre votre campagne.',
  "..., donation information and when?":'..., informations de don, temps?',
  "Donation information, Estimated start and end time of the campaign":'Informations sur la contribution, heure de début et fin de la campagne',
  "Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section":'Veuillez cliquer sur le bouton de création pour terminer l\'initialisation des informations. Vous pouvez s\'adapter davantage dans la section de configuration',
  "Leave the value 0 if you do not want a specific goal":'Laissez 0 valeur si vous ne voulez pas d\'objectif spécifique',
  "finance":'Finance',
  "complete":'Complété',
  "another donation options":'Autres options de dons',
  "pending list":'DS en attente de traitement',
  "type_community":'Communauté',
  "edit crowdfunding":'Édition de mobilisation',
  "est. value":'Estimation de la valeur',
  "volume":'Quantité',
  "Rice":'Riz',
  "Food":'Nourriture',
  "Pepper":'Poivre',
  "Fish sauce":'Sauce poisson',
  "Bottle":'Bouteille',
  "Box":'Boîte boîte',
  "Instant Noodles":'Nouilles instantanées',
  "to beneficiary":'Au destinataire',
  "Successfully transferred to {1} the giving":'Transféré avec succès à {1} le cadeau',
  "activities management":'Gestion des activités',
  "ready for volunteering":'Prêt pour la charité',
  "your curriculum vitae":'curriculum vitae',
  "link to your profile on another site":'Lien vers votre profil sur un autre site Web',
  "Money/Goods... can be offer. (If target is unlimited, please enter value \"0\")":'Argent / marchandises ... Donnez. (Si l\'objectif est illimité, veuillez saisir la valeur "0")',
  "targets":'Cible',
  "another giving options":'Les options de différence',
  "for activities that make an impact on the community":'Pour les activités qui affectent la communauté',
  "search crowdfunding":'Trouvez la campagne de collecte de fonds',
  "search giving":'Trouver une campagne',
  "what offer?":'Donner?',
  "Choose the right offer type for your campaign":'Sélectionnez la liste de vos pièces données',
  "..., offer information and when?":'..., informations fournies, temps?',
  "Offer information, Estimated start and end time of the campaign":'Informations données, le temps estimé commence, se termine',
  "..., and impact categories?":'..., la liste de la création d\'impact?',
  "profile link":'Lien vers les enregistrements',
  "outputs":'Sortir',
  "outcome":'Résultat',
  "volunteer job":'Bénévolat',
  "visible":'Afficher',
  "invisible":'Pas affichée',
  "ready":'Prêt',
  "not ready":'Pas prêt',
  "link to the giving":'Lien vers les dons',
  "donation guide":'Guide du don',
  "tank":'pot',
  "link to the donation":'Lien vers le don',
  "SGD":'Dollar de Singapour',
  "Vegetables":'Légume',
  "Oxygen tank":'Oxygène',
  "Diapers":'Description (couche)',
  "Bike":'Vélo',
  "Milk":'Lait',
  "Mask":'Masque',
  "USD":'Dollars américain',
  "EUR":'euro',
  "crowdfunding campaign":'Campagne de mobilisation',
  "giving campaign":'Faire campagne',
  "financial balance":'Solde financier',
  "recruitment campaign":'Campagne de recrutement',
  "for the output of impact activities in the community":'Donnez la production des activités d\'acteur dans la communauté',
  "make a giving":'Créer',
  "job - volunteer":'Travail - bénévolat',
  "features":'Caractéristique',
  "Recruit unlimited volunteers":'Recruter des bénévoles illimités',
  "You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.":'Vous pouvez publier autant d\'opportunités que vous le souhaitez et ne pas avoir de limite au nombre de bénévoles que vous recrutez.',
  "Manage your volunteers":'Gérez vos bénévoles',
  "Once your volunteers start coming in, you\'ll be able to organize, filter and keep track of them.":'Lorsque vous aurez des bénévoles via Wishare, vous pourrez organiser, les filtrer et les surveiller.',
  "Upload and Download your list":'Téléchargez et téléchargez votre liste',
  "Start out by uploading your own volunteer list. Don\'t worry, you can download your entire list of volunteers anytime.":'Commencez par télécharger votre propre liste de bénévoles. Ne vous inquiétez pas, vous pouvez télécharger l\'intégralité de la liste de vos bénévoles à tout moment.',
  "Volunteer Analytics":'Analyse de l\'impact du recrutement',
  "Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.":'La page de recrutement est votre centre de recherche. Combien de personnes se portent sur le bénévolat activement? Pas besoin de ne pas chercher plus loin.',
  "Show unlimited giving":'Ouvrez le cadeau gratuit',
  "You can post as many giving as you like, and there is no limit on how many giving you give.":'Vous pouvez publier autant que vous le souhaitez et ne pas avoir de limite au nombre de cadeaux.',
  "Manage your Giving (Money and Goods)":'Gérez vos dons (argent et marchandises)',
  "Once your giving start, you\'ll be able to organize, filter and keep track of them.":'Après avoir ouvert les dons, vous pourrez organiser, les filtrer et les suivre.',
  "Start out by uploading your own giving list. Don\'t worry, you can download your entire list of giving anytime.":'Commencez par télécharger votre propre liste de dons. Ne vous inquiétez pas, vous pouvez télécharger la liste complète de vos pièces données à tout moment.',
  "Giving Analytics":'Donner une analyse',
  "Your dashboard is your Giving. Look no further.":'Vous aider à gérer votre liste de dons. Pas besoin de ne pas chercher plus loin.',
  "donation - crowdfunding":'Dons - mobilisation communautaire',
  "Register unlimited donation":'Enregistrement du don sans limite',
  "You can post as many donation as you like, and there is no limit on how many donation you receive.":'Vous pouvez publier autant que vous le souhaitez et ne pas avoir de limite au nombre de contributions que vous souhaitez.',
  "Manage your Donations (Money and Goods)":'Gérez vos contributions (argent et marchandises)',
  "Once your donation start coming in, you\'ll be able to organize, filter and keep track of them.":'Lorsque vous aurez des contributions, vous pourrez organiser, les filtrer et les suivre.',
  "Start out by uploading your own donation list. Don\'t worry, you can download your entire list of donor and donation anytime.":'Commencez par télécharger votre propre liste de dons. Ne vous inquiétez pas, vous pouvez télécharger la liste complète des sponsors et des contributions à tout moment.',
  "Donation Analytics":'Analyse des effets de don',
  "Your dashboard is your donation. How many people are actively donors? Look no further.":'La page de don est le centre de mobilisation des ressources. Combien de personnes sont des sponsors actifs? Pas besoin de ne pas chercher plus loin.',
  "stop subscribe":'Arrêtez de vous inscrire',
  "subscribe":'Enregistrer',
  "giving value must be large than 0":'La valeur donnée doit être supérieure à 0',
  "The object of the giving campaign":'Sujets de la campagne',
  "the option has been removed successful":'L\'option a été supprimée avec succès',
  "the option has been edited successful":'L\'option a été modifiée avec succès',
  "click here to add new giving package":'Cliquez ici pour ajouter de nouveaux packages-cadeaux',
  "Enter another value you want to giving":'Entrez une autre valeur que vous souhaitez',
  "Tap to sign up for the package you want to receive":'Cliquez pour enregistrer le package que vous souhaitez recevoir',
  "Tap the \"{1}\" button to complete and receive the giving Code":'Cliquez sur le bouton "{1}" pour terminer et recevoir le code cadeau',
  "recipient":'Destinataire',
  "You are registered to get":'Vous vous inscrivez pour recevoir',
  "registered to get":'inscrit pour recevoir',
  "Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page":'Votre inscription a été enregistrée, veuillez suivre pour mettre à jour le statut d\'inscription sur la liste de la page de la campagne',
  "your subscription":'Votre inscription',
  "delete register of giving":'Supprimer l\'inscription pour',
  "are you sure to remove this giving?":'Vous souhaitez vraiment supprimer cet article?',
  "remove this giving":'Supprimer l\'article',
  "approve giving":'Parcourir la pièce',
  "{1} registered to get":'{1} inscrit pour recevoir',
  "short answer":'Réponse courte',
  "long answer":'La réponse',
  "answer guide":'Instructions pour répondre',
  "mandatory question":'Question obligatoire',
  "answer type":'Type de réponse',
  "answer options":'Réponse des options',
  "add more question":'Ajouter des questions',
  "add option":'Ajouter des options',
  "optional question":'La question n\'est pas requise',
  "additional questions":'Questions supplémentaires',
  "total target":'Total des buts',
  "additional donor information":'Informations supplémentaires sur le donateur',
  "medal":'Médaille',
  "Send a thank you email to donor":'Envoyer une lettre de remerciement au donateur',
  "send thank you email":'Envoyer une lettre de remerciement',
  "Deleting this content will not allow recovery, do you want to continue?":'La suppression de ce contenu ne permettra pas la récupération, voulez-vous continuer?',
  "introduction":'Introduire',
  "decent work and economic growth":'Bon travail et croissance économique',
  "Please answer the following questions to complete registration":'Veuillez répondre aux questions suivantes pour compléter l\'inscription',
  "registration questions":'Question d\'enregistrement',
  "change donation status to pending approval":'Modifier l\'état des contributions est en attente d\'approbation',
  "accept application":'Accepter les documents',
  "reject application":'Dossiers de déchets',
  "create new":'Créer un nouveau',
  "hour":'heure',
  "timesheet":'Calendrier',
  "timesheets":'Feuilles de temps',
  "task":'Emploi',
  "send email notifications and reminders to complete donation":'Envoyer un avis et des invites par e-mail pour terminer la contribution',
  "duration":'Pendant ce temps',
  "A donation confirmation email has been sent to you via the email address you entered.":'Un e-mail de confirmation de contribution vous a envoyé via l\'adresse e-mail que vous entrez.',
  "blood volume":'Capacité sanguine',
  "campaign overview":'Aperçu de la campagne',
  "donation campaign":'Campagne de campagne',
  "targets of the campaign":'Objectifs de la campagne',
  "impacts towards":'L\'impact sur',
  "impact summary report":'Bref rapport sur l\'impact',
  "graph of donation / giving":'Les graphiques génèrent une contribution / don',
  "goals to be achieved":'Les objectifs doivent être atteints',
  "the remaining target of the campaign":'Les objectifs restants de la campagne',
  "original goal":'Objectifs initiaux',
  "achieved":'Mobilisé',
  "campaign needs more":'La campagne a besoin de plus',
  "details over time":'Détails survenant',
  "donation date":'Date de don',
  "donor name":'Nom du contributeur',
  "unit name":'Nom de l\'unité',
  "giving date":'Date de don',
  "donation campaign report":'Rapport sur la campagne de don',
  "this report is only for campaign admins":'Ce rapport est uniquement pour l\'administrateur de la campagne',
  "list of work done by volunteers":'Liste des tâches effectuées',
  "Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.":'Remarque: Parfois, l\'e-mail sera transféré à tort à l\'e-mail de spam ("spam"), veuillez donc cocher la case de spam.',
  "application file":'fichier de candidature',
  "profile management":'La gestion des dossiers',
  "email has been successfully sent!":'Le courrier électronique a été envoyé avec succès!',
  "Are you sure?":'Vous voulez continuer?',
  "closedgrouptips":'Le groupe a fermé',
  "donate blood":'Don de sang',
  "register to donate blood":'Enregistrement de don de sang',
  "organizations":'Organisation',
  "no limit":'Illimité',
  "expiration":'Expiré',
  "my bookmarks":'Cocher',
  "suggested channels":'Suggérer un canal',
  "shortcuts":'Votre raccourci',
  "contribution":'Votre contribution',
  "grateful":'Reconnaissant',
  "code":'Code',
  "you have registered to donate":'Vous vous êtes inscrit pour faire un don',
  "administrators panel":'Uniquement pour les administrateurs',
  "beneficiary information":'Profiter des informations',
  "account number":'Numéro de compte',
  "bank name":'Nom de banque',
  "transfer contents":'Contenu du transfert',
  "Successfully transferred to":'A réussi à transférer à',
  "a gift":'cadeau',
  "you register to receive gifts":'Vous vous inscrivez pour recevoir un cadeau',
  "your register":'Votre inscription',
  "requirement":'Demande',
  "time location":'Lieu et heure',
  "the target of the activity":'Objectifs de l\'activité',
  "validation error":'Erreur de confirmation',
  "advanced settings":'Réglages avancés',
  "go to setting":'Aller aux paramètres',
  "content is filtered by":'Le contenu est filtré par',
  "congratulation":'Toutes nos félicitations',
  "You have completed the steps of creating activity":'Vous avez terminé les étapes pour créer des activités',
  "Hope your activity will be successful and help many people":'Je souhaite que votre activité réussisse et aide de nombreuses personnes',
  "attention":'Attention',
  "note create description":'Vous devez accéder aux "Paramètres" pour ajouter plus de détails. Voulez-vous aller dans la section "Paramètres" ici?',
  "note target description":'L\'objectif principal est l\'objectif que toute la campagne doit atteindre et vous pouvez diviser en différents petits objectifs. Pour ce faire, accédez à la section "Paramètres" pour compléter après avoir terminé les étapes restantes.',
  "open/close":'Durée',
  "registrable":'Autoriser l\'inscription',
  "You have completed the steps to create a campaign":'Vous avez terminé les étapes de la création de la campagne',
  "Hope your campaign will be successful and help many people":'J\'espère que votre campagne réussira et aidera de nombreuses personnes',
  "create recruitment":'Créer un recrutement',
  "recruitment type":'Formulaire de recrutement',
  "job":'Emploi',
  "work time":'Temps de travail',
  "full time":'Tout le temps',
  "part time":'À temps partiel',
  "remote":'Télécommande',
  "position_title":'Titre',
  "donation_event":'des dons',
  "giving_event":'Donné',
  "recruitment_event":'Emploi',
  "{n} categories":'Catégorie {N}',
  "{n} languages":'{n} langue',
  "{n} provider(s)":'{n} Organisation',
  "1 year":'un ans',
  "1D":'quotidien',
  "1M":'Mensuel',
  "1Y":'Annuel',
  "2 years":'2 années',
  "2Y":'2 années',
  "3 months":'3 mois',
  "3M":'3 mois',
  "6 months":'6 mois',
  "6M":'6 mois',
  "about FeedIn":'À propos de nourrisson',
  "about-us":'À propos de nous',
  "accept invitation":'Accepter l\'invitation',
  "accepted as member":'accepté en tant que membre',
  "access denied":'L\'accès est refusé',
  "account settings":'Paramètres du compte',
  "active subscription":'Package d\'inscription de fonctionnement',
  "add chart":'Ajouter un graphique',
  "add image":'Plus de photos',
  "add link":'Ajouter un lien',
  "add map":'Ajouter une carte',
  "add money to wallet":'Chargez de l\'argent dans votre compte',
  "Add new and manage members here {here}":'Ajouter de nouveaux membres et gérer les membres ici {ici}',
  "add new answer":'Ajouter une nouvelle réponse',
  "add new contact":'Ajouter un nouveau contact',
  "add new location":'Ajouter un nouvel emplacement',
  "add payment to keep using {subscription} or change back to free":'Payer les frais pour continuer à utiliser {abonner} ou échanger gratuitement',
  "add payment to keep using or change back to free":'Payer les frais pour continuer à utiliser ou échanger gratuitement',
  "add photo":'Plus de photos',
  "add price":'Charger',
  "Add product photos":'Ajouter des photos du produit',
  "add subscriber":'Ajouter les abonnés',
  "Add Tax Number":'Ajouter le code des impôts',
  "Add to Home Screen":'Ajouter l\'écran',
  "add video":'Montage vidéo',
  "adding content to the channel is limited due to its policies":'L\'ajout de contenu au canal est limité en raison des politiques de canal',
  "additional details":'Détails supplémentaires',
  "admin description":'Rôle d\'administration',
  "advanced filter":'Filtre avancé',
  "After select, click Save to finish":'Après sélection, appuyez sur enregistré pour terminer',
  "after your trial ends":'Après la fin de votre essai',
  "all categories":'Tous les fichiers',
  "all dataset":'Tous les ensembles de données',
  "all products":'Tous les produits',
  "all vietnam":'Tout le Vietnam',
  "allow add new answers":'Autoriser de nouvelles options',
  "Allow channel content to be found through search or suggestion":'Le contenu du canal permet d\'être trouvé via la recherche ou la proposition',
  "Allow users to find your channel via search function.":'Permet aux utilisateurs de trouver votre canal via la fonction de recherche.',
  "allowed be found":'Autorisé à être trouvé',
  "Alternate Email":'Adresse e-mail (sous)',
  "Alternate Mobile No.":'Téléphone (Extra)',
  "Alternate Phone Number":'Téléphone (Extra)',
  "alternative phone":'Téléphone (Extra)',
  "Annual Turnover":'Revenu annuel',
  "another":'Autre',
  "answers":'Réponses',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this":'Tout le monde peut afficher le contenu des données. Seuls les membres peuvent publier et commenter ceci',
  "Are you sure delete this article?":'Voulez-vous supprimer cet article?',
  "Are you sure delete this chart?":'Vous voulez vraiment supprimer ce graphique?',
  "Are you sure delete this poll?":'Êtes-vous sûr de vouloir supprimer ce vote?',
  "Are you sure delete this task?":'Êtes-vous sûr de supprimer cette tâche?',
  "Are you sure delete this template?":'Êtes-vous sûr de supprimer ce modèle?',
  "article":'Des postes',
  "articles":'Des postes',
  "ask for the best deal":'Pour le meilleur accord',
  "automatically register with Google, Facebook... account":'Inscrivez-vous automatiquement avec Google, les comptes Facebook ...',
  "available balance":'Soldes disponibles',
  "Back Home":'Retour à la page d\'accueil',
  "back to account":'Revenez à votre compte',
  "ban":'Interdiction des activités des membres',
  "banned":'Interdit',
  "banned. can not post any":'Interdire. Limite publication',
  "bans":'Interdire',
  "Basic":'Basique',
  "basic info":'Informations de base',
  "because you are looking for {keyword}":'Cherchez-vous {mot-clé}?',
  "Become a provider?":'Devenir une organisation ?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service":'Vérifiez les informations de vente à {Check1} avec des classements plus élevés sur Feedin {Check2} Approchez les clients plus {Check3} Autres services spéciaux',
  "bill estimate":'En plus de la période suivante',
  "billing":'Payer',
  "billing address":'Adresse de facturation',
  "billing contact":'Paiement de contact',
  "billing details":'Détails des informations de paiement',
  "billing history":'Historique de paiement',
  "billing information":'détails de facturation',
  "billing overview":'Aperçu du paiement',
  "billing period":'Délai de paiement',
  "birthday":'Date de naissance',
  "bookmark":'Cocher',
  "bookmark all":'marquer tout',
  "bookmarked":'Marqué',
  "brief introduction to yourself":'Courte introduction à propos de vous-même',
  "bulk price update":'Mettre à jour le prix des marchandises en fonction de la liste',
  "bulletin":'Nouvelles',
  "by categories":'Selon la catégorie',
  "by clicking Submit, you agree to the policy of FeedIn":'En cliquant pour envoyer, vous êtes d\'accord avec la politique de Feedin',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.":'En cliquant sur «Send Away», vous êtes d\'accord avec la politique de Feedin {Term1} et {Termm2}.',
  "By clicking Subscribe, you agree to {term1} and {term2}.":'En cliquant sur l\'enregistrement, vous êtes d\'accord avec {Term1} et {Term2}.',
  "by making a channel private, only members will have access to this channel":'En plaçant des canaux privés, seuls les membres ont accès à ce canal',
  "by using FeedIn, you agree to our":'Pour utiliser Feedin, vous êtes d\'accord avec',
  "Call":'Appel',
  "Call verification":'Vérifiez par appel',
  "catalog":'Catégorie',
  "categories affect how your story appears in public":'Changer la sélection des catégories affecte comment votre article apparaît',
  "category groups":'Groupe de catégories',
  "change address":'Changer l\'adresse',
  "change display title / subtitle / display image / categories":'Modifier le titre, la catégorie d\'affichage',
  "change following categories":'Changer la catégorie de suivi',
  "change password":'Changer le mot de passe',
  "change preview photo":'Modifier les photos Aperçu',
  "change role":'Changer le rôle',
  "change subscription":'Modifier le forfait d\'inscription',
  "changes here will affect how your story appears in public places like Article\'s homepage - not the story itself.":'Les changements ici affecteront la façon dont votre histoire apparaît dans des lieux publics comme le site d\'actualités - pas le contenu de l\'article.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.":'Le changement de catégories de suivi peut être effectué en continu dans {M} maintenant. Et le changement suivant est effectué après {d} date.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account\'s configuration to edit":'Les modifications linguistiques de cet écran modifieront les étiquettes affichées sur l\'application qui correspondent à la langue que vous avez choisie. Pour définir le filtre de contenu dans la langue, veuillez visiter la configuration du compte pour ajuster',
  "channel":'Chaîne de nouvelles',
  "channel name":'Nom de la chaîne d\'information',
  "channel subscriptions":'Abonnez-vous à la chaîne d\'information',
  "channels":'Chaîne de nouvelles',
  "channels in category":'Canal dans la catégorie',
  "Channels that you may be interested in":'Canaux que vous pourriez être intéressés',
  "channels to follow":'Canal d\'introduction',
  "charged fee data":'Les données sont facturées',
  "charges apply":'En vigueur',
  "chart management":'Gestion des graphiques',
  "charts":'Graphique',
  "chat with provider":'Parler avec l\'organisation',
  "check all":'Tout sélectionner',
  "check your email and click on the link":'Vérifiez votre e-mail et cliquez sur le lien',
  "choose another FeedIn subscription":'Choisissez un autre package d\'alimentation',
  "choose file":'Sélectionnez le fichier',
  "Choose language":'Choisir la langue',
  "Choose one from your existing conversations, or start a new one.":'Choisissez une conversation dans vos conversations existantes ou démarrez une nouvelle conversation.',
  "Choose the channel for posting":'Sélectionnez le canal à publier',
  "Choose to follow the information catalog to get more news on your wall":'Choisissez de suivre le portefeuille d\'informations pour recevoir plus de nouvelles sur votre mur',
  "choose your default skin tone":'Choisissez votre ton de couleur par défaut',
  "city":'Ville',
  "clear all":'Supprimer tout',
  "clear role permissions":'Éliminer la décentralisation sur le rôle',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.":'Cliquez sur le bouton {icon} sur la page du graphique que vous souhaitez suivre, le graphique sera mis à jour régulièrement ici.',
  "click to upload":'Cliquez pour télécharger',
  "code of conduct":'Règles de comportement',
  "color theme":'Couleur d\'interface',
  "commented your post":'Commentez votre message',
  "Commodity market information":'Informations sur le marché des matières premières',
  "company profile":'Profil de l\'entreprise',
  "confidentiality of member information":'Sécurité de l\'information des membres',
  "configure content by language":'Configurer le contenu dans la langue',
  "Confirmation change email address has been sent to":'Confirmez le changement d\'adresse e-mail envoyée à',
  "Confirmation letter will be sent to your email address":'La lettre de confirmation sera envoyée à votre adresse e-mail',
  "contact details":'Coordonnées pour plus de détails',
  "contact info":'Informations de contact',
  "contact messages":'Message de contact',
  "contact name":'Nom de contacte d\'une personne',
  "contact now":'Contacter maintenant',
  "contact provider":'Contacter l\'organisation',
  "contact to member":'Contacter les membres',
  "Contact us about your bill":'Contactez-nous pour votre facture',
  "contact with provider":'Contacter l\'organisation',
  "contact-info":'Informations de contact',
  "contacts":'Contact',
  "content allowed to be found":'Contenu autorisé à être trouvé',
  "content displayed by language":'Contenu affiché dans la langue',
  "content is for members only":'Contenu uniquement pour les membres',
  "Content language":'Langage de contenu',
  "Continue to {provider}":'Continuer avec {fournisseur}',
  "Continue to PayPal":'Continuez avec PayPal',
  "conversations":'Conversation',
  "cookies-policy":'Politique des cookies',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel":'Copiez, partagez le lien de la page ci-dessous vers des amis. Pour tous ceux dont vous avez besoin pour partager facilement l\'accès et suivre ce canal',
  "copyright":'Licence',
  "country":'Nation',
  "cover":'Chambre',
  "create a free channel":'Créer un canal gratuit',
  "Create a new chart for dataset":'Créez un nouveau graphique pour le kit de données',
  "Create a new provider so you can post products or manage your information channels.":'Créez une nouvelle organisation afin que vous puissiez publier des produits ou gérer vos canaux d\'information.',
  "create a poll":'Créer un vote',
  "create category":'Créer des catégories',
  "create channel":'Canaliser',
  "create chart":'Créer des graphiques',
  "create dataset":'Créer un kit de données',
  "Create new channel":'Créer un nouveau canal',
  "create new group":'Créer de nouveaux groupes',
  "create one":'En créer un nouveau',
  "create product":'Créer des produits',
  "create role":'Créer un rôle',
  "Create provider profile":'Créer des enregistrements d\'organisation',
  "credit/debit card":'Carte de crédit / débit',
  "custom":'Coutume',
  "custom url":'Personnaliser le nom de domaine',
  "daily":'quotidien',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.":'Mettre à jour les informations, le prix des marchandises, l\'agriculture et la finance quotidiennement à Fealin.',
  "dark mode":'Mode sombre',
  "dark skin tone":'Ton foncé',
  "data":'Données',
  "data & charts":'Données et graphiques',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this":'Tout le monde peut afficher le contenu des données, tout le monde peut participer librement et peut publier et commenter ce',
  "data explorer":'Exploration des données',
  "data frequency":'Fréquence des données',
  "data in category":'Données dans la catégorie',
  "data security":'Protection des données',
  "Data set you might be interested in":'Ensemble de données Vous pourriez être intéressé',
  "dataset":'Données',
  "dataset list":'DSE DSE',
  "dataset management":'Gérer le kit de données',
  "dataset subscriptions":'Inscrivez-vous aux données',
  "deactive":'Arrête de travailler',
  "Declare the location for your provider":'Déclarer l\'emplacement de l\'organisation',
  "default skin tone":'Tons de couleur par défaut',
  "delete channel":'Supprimer le canal',
  "delete data":'Suprimmer les données',
  "delete dataset":'Suprimmer les données',
  "delete provider":'Supprimer l\'organisation',
  "Deleted article are gone forever. Are you sure?":'L\'article abandonné sera perdu en permanence. Êtes-vous sûr de continuer?',
  "Deleted channel are gone forever. Are you sure?":'Le canal supprimé perdra des données pour toujours. Etes-vous sûr que vous voulez supprimer?',
  "Deleted dataset are gone forever. Are you sure?":'L\'ensemble de données que vous souhaitez supprimer disparaîtra en permanence. Es-tu sûr?',
  "Deleted provider are gone forever. Are you sure?":'L\'organisateur supprimé disparaîtra pour toujours. Es-tu sûr?',
  "delivery frequency":'Fréquence de l\'émission de données',
  "delivery_time":'Délai de livraison',
  "designed and provided by":'Conçu et fourni par',
  "desktop app":'Application de bureau',
  "discovery more providers":'Rechercher des organisations',
  "do not show members information for each other members":'N\'affichez pas d\'informations sur les membres les uns pour les autres',
  "do you want cancel the subscription?":'Voulez-vous annuler l\'inscription?',
  "do you want to cancel?":'Voulez-vous continuer à annuler?',
  "Do you want to deactivate this product?":'Voulez-vous annuler cette activation de produit?',
  "do you want to logout?":'Voulez-vous continuer à vous connecter?',
  "document (PDF)":'Document (PDF)',
  "documentation":'Document',
  "Doesn\'t have any bookmarked item":'Il n\'y a pas d\'articles marqués',
  "downgrade":'Classe basse',
  "downgrade subscription":'Enregistrement d\'inscription inférieur',
  "downgrade your subscription to":'Abaisser votre niveau d\'inscription',
  "download":'Télécharger',
  "draft products":'Le produit déclare',
  "edit message":'Modification des messages',
  "edit name":'Corriger le nom',
  "edit your post":'Modifier l\'article',
  "editor choices":'Sélectionnez affiché par l\'éditeur',
  "email (example@company.com)":'Email (example@company.com)',
  "email account":'Connexion par e-mail',
  "Emoji categories":'Catégories emoji',
  "en":'Anglais',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.":'Encouragez les initiatives commerciales à créer des impacts sociaux. Vers la construction d\'un environnement de vie durable pour les générations d\'aujourd\'hui et futures.',
  "enter an url":'Entrez le lien Link',
  "enter channel name":'Entrez le nom du canal',
  "Enter Dataset Name":'Entrez le nom des données',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters":'Entrez le contenu requis au moins 20, les 4000 plus gros caractères',
  "Enter products / services search":'Entrez les produits / services de recherche',
  "Enter some information so the provider knows your requirements":'Entrez certaines informations pour organiser votre demande',
  "enter provider name":'Entrez le nom de l\'organisation',
  "enter your email":'Entrez l\'e-mail',
  "enter your keyword":'Entrez les mots clés de recherche',
  "expires":'Expiré',
  "Explore related products from verified Companies":'Produits connexes des organisations',
  "Explore similar products":'Produits similaires',
  "extra request":'Exigences supplémentaires',
  "failure purchase!":'Buy a échoué!',
  "FAQ":'Questions fréquemment posées',
  "favorites categories":'Catégorie préférée',
  "Featured Categories":'Éminent section',
  "featured charts":'Graphique typique',
  "featured topics":'Thème proéminent',
  "fee management":'Frais de gestion',
  "fee settings":'Configurer les frais d\'utilisation',
  "feed":'Alimentation',
  "FeedIn gives you the latest information on market data.":'Feedin vous donne les dernières informations sur les données du marché.',
  "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below":'Feedin a des groupes d\'informations de groupe pour vous aider à mieux accéder aux informations et à éviter les informations manquantes. Veuillez sélectionner les catégories que vous aimez ci-dessous',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.":'Feedin est le fondement pour connecter les acheteurs à l\'organisation, créant un réseau de partage d\'informations de premier plan sur le marché de l\'agriculture et des matières premières.',
  "FeedIn Member Since":'Membres de Feedin de',
  "FeedIn will update the latest and fastest information and data about the goods market to you":'Feedin mettra à jour les données et données les plus rapides et les plus rapides sur le marché des matières premières pour vous',
  "file upload failed":'Le fichier de téléchargement est défectueux',
  "file uploaded successfully":'Le fichier a été téléchargé avec succès',
  "filter":'Rechercher le filtre',
  "Filter results":'Filtrez les résultats',
  "first subscription on":'Inscrivez-vous pour le premier jour',
  "Flags":'Insigne',
  "folders":'Dossier',
  "follow more categories":'Suivez plus de catégories',
  "Follow to get notifications from this channel!":'Suivi pour recevoir des notifications de ce canal!',
  "followers":'Suiveurs',
  "following categories":'La catégorie regarde',
  "following channels":'Canal de surveillance',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.":'Pour les acheteurs, les investisseurs et les personnes intéressées. Choisissez simplement le domaine d\'intérêt, vous êtes proposé de vous connecter avec les derniers experts et les nouvelles du marché.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us":'Pour une demande de devis de produits, envoyer des informations sur les produits ou contacter la coopération, veuillez nous contacter',
  "for you":'Pour toi',
  "free":'Gratuit',
  "frequently questions":'Questions fréquemment posées',
  "Frequently Used":'Souvent utilisé',
  "frequently-questions":'Questions fréquemment posées',
  "From - To":'De à',
  "from the sender":'Postes avec les expéditeurs',
  "general permission":'Décentralisation générale',
  "general settings":'Réglages généraux',
  "Get a quote":'Obtenir une citation',
  "Get Best Price":'Obtenez le meilleur prix',
  "Get in touch with us":'Contactez-nous',
  "Get latest price":'Obtenez le dernier prix',
  "Get Quotes":'Obtenir une citation',
  "Get quotes from seller":'Recevoir des devis du vendeur',
  "get started with {name}":'Commencez par {nom}',
  "Get your business details verified with us for higher rankings in FeedIn!":'Vérifiez les détails de votre entreprise avec nous pour un classement plus élevé dans Feedin!',
  "go":'Commencer',
  "go modal":'Ouvrir le cadre',
  "haven\'t comments yet":'aucun commentaire pour l\'instant',
  "head office":'Bureau principal',
  "hello":'Bonjour',
  "Hello you":'Bonjour',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.":'Aidez les gens à mettre à jour les dernières informations sur les marchandises et le marché agricole multidimensionnelles et multidimensionnelles et soutiennent le package de services à la demande.',
  "Here is a list of all invite links. You can revoke any one.":'Vous trouverez ci-dessous une liste de tous les liens. Vous pouvez en récupérer.',
  "hide interaction between members":'L\'affichage caché interagit entre les membres',
  "Hide interactions between members. But, retaining interaction between members and administrators":'Masquer les interactions entre les membres. Mais, maintenez l\'interaction entre les membres et les administrateurs',
  "If you need assistance, please feel free to contact us via":'Si vous avez besoin d\'un support, veuillez nous contacter',
  "If you want to switch to annual payment, {contactus}":'Si vous souhaitez passer au paiement annuel, contactus}',
  "image":'Image',
  "image must smaller than":'La taille de l\'image doit être plus petite',
  "incoming requests":'Demande envoyée',
  "index type":'Type d\'index',
  "index types":'Type de données',
  "info chart editing":'Modifier les informations du graphique',
  "interested":'Se soucier de',
  "Interface language":'Langue de l\'interface',
  "introduce":'Introduire',
  "invalid template":'forme non valide',
  "invite and share":'Inviter et partager',
  "invite code":'Code d\'invitation utilisateur',
  "invite members":'Inviter des membres',
  "invite people":'Invitez tout le monde',
  "invite people to join this channel":'Invitez tout le monde à rejoindre la chaîne',
  "invite you to join":'Veuillez vous inviter à rejoindre',
  "Invite your friends to this page":'Veuillez rejoindre cette page',
  "invited":'Invité',
  "invited to become member":'Invité à être membre',
  "inviter":'Invitation',
  "invites":'Inviter à rejoindre',
  "is served by clicking on a":'Servi en cliquant',
  "item not found":'Aucun contenu n\'a été trouvé',
  "join channel":'Rejoindre la chaîne',
  "Join for free now":'Rejoignez gratuitement maintenant',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel\'s latest news to you":'Rejoignez la chaîne pour partager des articles, des commentaires ou cliquez pour suivre afin que le système mette automatiquement à jour les dernières nouvelles de la chaîne pour vous',
  "Join this channel to start chatting":'Rejoignez le canal pour être autorisé à publier',
  "keep subscription":'Garder l\'inscription',
  "keywords":'Mot-clé',
  "kick":'Éliminer les membres',
  "kicked":'Supprimé du membre',
  "last updated":'Dernière mise à jour',
  "latest":'Dernier',
  "latest updates":'Nouvelle mise à jour',
  "Latest updates on the commodity market":'Dernière mise à jour du marché des matières premières',
  "leave":'Partir',
  "leave a message":'Laissez un message',
  "Leave a Message, we will contact you back!":'Laissant le message, nous vous contacterons à nouveau!',
  "leave channel":'Quitter le canal',
  "Legal Status of Firm":'type de compagnie',
  "license":'Licence',
  "licenses":'Licence',
  "light skin tone":'Pouleur légère',
  "link":'Lien',
  "Link original post":'Lien d\'article original',
  "list of data":'Liste des ensembles de données',
  "listed products":'Les produits sont actifs',
  "unlisted products":'Les produits ne fonctionnent pas',
  "Location (Province/district/ward)":'Emplacement (province (ville) / district (district) / Ward (commune))',
  "location & contact":'Emplacement et contact',
  "location name":'Nom',
  "Login now":'Connecte-toi maintenant',
  "Login now to select and follow the content that you interested in":'Connectez-vous maintenant pour choisir et suivre l\'élément qui vous intéresse',
  "login with account":'Connectez-vous avec le compte',
  "login with Facebook":'Se connecter avec Facebook',
  "login with Google":'Connectez-vous avec Google',
  "Make your channel get more exciting activities by inviting other members":'Faites de votre page de canal plus d\'activités en invitant d\'autres membres',
  "Make your provider get more exciting activities by inviting other members":'Faites de votre organisation plus d\'activité en invitant d\'autres membres',
  "manage":'Gérer',
  "manage dataset":'Gérer le kit de données',
  "manage products":'Gestion des produits',
  "manage services":'La gestion des services',
  "ManageChannelSubscriptions":'Gestion d\'enregistrement des canaux',
  "managed channels":'Canal d\'administration',
  "mark as read":'Marquez qu\'il a été vu',
  "market":'Marché',
  "market summary":'Résumé du marché',
  "mart":'Boutique',
  "me to the channel":'Venez au canal d\'information',
  "medium skin tone":'Ton moyen',
  "medium-dark skin tone":'Ton moyen - sombre',
  "medium-light skin tone":'Tons moyens - brillant',
  "member description":'Description du membre',
  "member management":'Gérer le membre',
  "members list":'Membre du DS',
  "mention":'mentionné',
  "menu":'Catégorie',
  "Message":'Message',
  "messages":'Message',
  "min_order_qtty":'Commande minimum',
  "Minimum payment required":'Exigences de paiement minimum',
  "miss":'Sœur aînée.',
  "modal":'Cadres',
  "monthly":'Mensuel',
  "More data from":'Voir plus de données de',
  "More like This":'Voir plus',
  "mr":'Grand-père.',
  "mrs":'Grand-mère.',
  "ms":'Manquer.',
  "my channels":'Ma Chaîne',
  "my page":'Page personnelle',
  "my post":'Mon message',
  "name of chart":'Nom du graphique',
  "name of your company":'Le nom de votre entreprise',
  "Nature of Business":'Zone commerciale',
  "new answer":'Nouvelle réponse',
  "New contact":'Nouvelles coordonnées',
  "new folder":'Nouveau dossier',
  "new group":'Créer de nouveaux groupes',
  "New template":'Nouvelle forme',
  "newest data":'Dernières données',
  "news page":'Sites d\'information',
  "next change":'Changer la prochaine fois',
  "No categories have been selected":'Il n\'y a pas de catégorie sélectionnée',
  "no categorized":'Inexpliqué',
  "No channel being followed":'0 canaux sont surveillés',
  "no channels":'Il n\'y a pas de canal d\'information',
  "No Data":'Pas de données',
  "No data available at this time.":'Il n\'y a pas de données pour le moment.',
  "no data found":'Aucune donnée disponible',
  "No data set are being followed":'0 L\'ensemble de données est surveillé',
  "no dataset":'Il n\'y a pas de jeu de données',
  "no description":'Pas de description disponible',
  "No Emoji Found":'Aucun emoji trouvé',
  "No filter available":'Aucun filtrage de données',
  "no invites yet":'Il n\'y a pas d\'invitations',
  "No news":'Pas de nouvelles',
  "no office found":'Aucun emplacement trouvé',
  "No posts were found":'Aucun message n\'a été trouvé',
  "no results found":'Aucun resultat n\'a été trouvé',
  "not a member yet?":'N\'êtes-vous pas membre?',
  "notes about chart!":'Notes pour le graphique',
  "notification":'Notification',
  "Number of Employees":'Nombre de membres',
  "Number of your channels:":'Votre nombre actuel de canaux:',
  "Objects":'Objet',
  "offices":'Bureau',
  "OK":'D\'ACCORD',
  "oldest data":'Les données les plus éloignées',
  "One more step to become a Verified Seller":'Déclaration complète des informations sur les ventes',
  "Only members can view data content, post articles or comment on this":'Seuls les membres peuvent afficher le contenu de données, publier ou commenter',
  "oops...":'Désolé...',
  "opps, no channels available":'Désolé, il n\'y a pas de chaîne d\'information',
  "or continue with":'Ou continuer avec',
  "other categories":'Autres catégories',
  "other channels":'Autres canaux',
  "other charts":'Autres graphiques',
  "otherSettings":'Autre installation',
  "Our Channels":'Le canal de l\'infonmentation',
  "Our data":'Données',
  "Our mission is to:":'Notre mission vise à:',
  "Our Videos":'Vidéo sur nous',
  "overview":'aperçu',
  "owner description":'Le rôle de gestion',
  "package in use":'Le package est utilisé',
  "packaging_details":'Détails de l\'\' emballage',
  "paid channel":'Frais de canal',
  "partner":'Partenaire',
  "Password and confirm password does not match":'Le mot de passe de confirmation ne correspond pas',
  "Password must have min 6 characters":'Les mots de passe doivent exister au moins 6 caractères',
  "Passwords must match":'Le mot de passe d\'importation doit correspondre',
  "paste your link here":'Collez votre réservoir ici',
  "pay":'Payer',
  "Pay and subscribe":'Payer et enregistrer',
  "payment detail":'Détails de paiement',
  "payment subscription":'Pays pour le forfait d\'inscription',
  "Payment via {provider}":'Paiement via {fournisseur}',
  "Payment via Paypal":'Paiement via PayPal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay":'Le paiement se poursuivra via {fournisseur}, veuillez confirmer l\'argent pour continuer',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay":'Le paiement sera sur la poursuite de PayPal. Veuillez confirmer le montant à payer',
  "paypal":'Pay Pal',
  "pending approval":'En attendant la navigation',
  "people to follow":'Ami',
  "Permanent link":'Adresse permanente',
  "permission denied":'L\'accès est rejeté',
  "permissions":'Décentraliser',
  "personal":'Individuel',
  "personal channels":'Canal personnel',
  "personal profile":'Profil',
  "Phone Number":'Numéro de téléphone',
  "Phone number how we can contact you":'Numéro de téléphone, nous pouvons vous contacter',
  "Pictures on the home page":'Photos placées sur la page d\'accueil',
  "pin":'Broche',
  "Pin code":'Code PIN',
  "pinned":'Épineux',
  "pinned posts":'L\'article a été épinglé',
  "pinnedPosts":'L\'article a été épinglé',
  "Please check the information and follow the steps to subscribe.":'Veuillez vérifier les informations et suivre les étapes pour vous inscrire.',
  "Please choose to follow related channels for the system to filter the updates to you":'Veuillez choisir de suivre les canaux connexes afin que le système filtre les informations mises à jour',
  "Please choose to save the interest charts so you can view them easily each day":'Veuillez choisir de sauver les graphiques d\'intérêt afin que vous puissiez consulter ces graphiques facilement chaque jour',
  "please enter name of product":'Veuillez saisir le nom du produit',
  "please input your email":'Veuillez saisir votre adresse e-mail',
  "please input your phone number":'Veuillez entrer votre numéro de téléphone',
  "Please log in to FeedIn to leave your comment in the post":'Veuillez vous connecter à Feedin pour laisser vos commentaires dans l\'article',
  "Please login to use the message function in the system.":'Veuillez vous connecter pour utiliser la fonction de textos dans le système.',
  "Please register to view this content":'Veuillez vous inscrire pour voir ce contenu',
  "please select":'Choisissez s\'il vous plaît',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information":'Veuillez choisir au moins 1 sujet à compléter. Feedin vous aidera à trouver les bonnes informations',
  "Please select the unit you want to choose as the default":'Veuillez cliquer sur une entreprise que vous souhaitez choisir comme défaut',
  "policy":'Politique',
  "poll":'Sondage',
  "popular":'Populaire',
  "Post something":'Écris quelque chose',
  "post your images":'Publiez votre photo',
  "postal code":'Code postal',
  "posted by":'posté par',
  "posts":'Des postes',
  "Premium":'Haute société',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.":'Les canaux hauts, lorsqu\'ils sont activés, permet aux utilisateurs de participer à un canal chargé. Le propriétaire du canal recevra des revenus des membres.',
  "premium providers":'Organisations',
  "press enter to search":'Cliquez sur Entrer pour rechercher',
  "preview description":'Description Affichage',
  "preview title":'L\'affichage du titre',
  "pricing":'Prix',
  "Primary E-mail":'Email (principal)',
  "Primary Mobile No.":'Numéro de téléphone (principal)',
  "privacy settings":'Configuration de stratégie privée',
  "privacy-policy":'Politique privée',
  "private channel":'Canal privé',
  "pro":'Forfait avancé',
  "product catalogs":'Groupe d\'affichage de produit',
  "product certificates":'certification du produit',
  "product details":'Détails du produit',
  "product images":'Image du produit',
  "product must have at least five character":'Le nom du produit doit avoir au moins 5 caractères',
  "product must have at least one image":'Les produits doivent avoir au moins une image',
  "product settings":'Gestion des produits',
  "product specifications":'Caractéristiques du produit',
  "Product/Service Name":'Noms de produits / services',
  "production_capacity":'Capacités de fabrication',
  "products list":'Produits DS',
  "products page":'Page de produit',
  "Professional network in the industry":'Réseau spécialisé dans l\'industrie',
  "profile settings":'Gestion de compte',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.":'Promouvoir les gens pour partager les connaissances, l\'expérience, apprendre ensemble, développer des prospères.',
  "provided services":'Services fournis',
  "Province/City":'Province',
  "Province/district/ward":'Province (Ville) / District (District) / Ward (Commune)',
  "provinces":'Province',
  "public timeline":'Le calendrier est public',
  "publish":'Publier',
  "published by":'Publié par',
  "publisher":'Publier',
  "quick link":'Lien rapide',
  "read":'lire',
  "read more":'En savoir plus',
  "read news":'Voir les nouvelles',
  "ready to publish?":'Prêt à publier',
  "rebookmark":'Vérifier',
  "recent news":'Nouvelles récentes',
  "recently news":'Nouvelles',
  "Recommendations":'Recommandations',
  "refreshed":'Dernière mise à jour',
  "region":'Régions',
  "regions":'Régions',
  "registered":'Inscrit',
  "Registering to use advanced data, you will have to pay":'Inscrivez-vous pour utiliser des données avancées, vous devrez payer',
  "related articles":'Posts avec la même catégorie',
  "related categories":'Catégories connexes',
  "related channels":'Canal connexe',
  "related charts":'Graphiques connexes',
  "related keywords":'Mots-clés connexes',
  "Related to items you\'ve viewed":'Lié au produit avant de regarder',
  "relevant":'En rapport',
  "remove all":'Supprimer tout',
  "renew / expires on":'Extension / expiration de la date',
  "replied":'A répondu',
  "Request a call":'Demande d\'appel',
  "Request a quote":'Demander un devis',
  "request have been sent successfully!":'La demande a été envoyée avec succès!',
  "request to join":'Envoyez une demande pour participer',
  "request-to-join":'Envoyez une demande pour participer',
  "requested to join":'Envoyez une demande pour participer',
  "required field":'La partie obligatoire',
  "Requirement Details":'Détails des exigences',
  "resend":'Envoyer à',
  "response rate":'Taux de réponse',
  "responses":'Retour',
  "restricted":'Limite',
  "results":'Résultat',
  "return home":'Retour à la page d\'accueil',
  "return owner page":'Retour à la page d\'accueil',
  "return user page":'Retour à la page utilisateur',
  "Review":'Examen',
  "role name":'Nom du rôle',
  "role title":'Titre / rôle',
  "roles":'Rôle',
  "sales information":'Informations sur les ventes',
  "save & complete":'Sauver et complet',
  "Save and continue":'Sauvegarder et continuer',
  "schedule to cancel":'Sont dans le calendrier d\'annulation',
  "search anythings in FeedIn":'Recherche dans le dos',
  "Search by Name":'Recherche par nom',
  "Search Results":'Résultats de recherche',
  "secret channel":'Canal privé',
  "section":'Segment',
  "See all charts in the data":'Voir tous les graphiques dans les données',
  "select / create a group":'Choisissez / créez 1 groupe',
  "Select a content category or follow the authors to get relevant information":'Sélectionnez la catégorie de contenu ou suivez les auteurs pour des informations pertinentes',
  "select category":'Sélectionnez la catégorie',
  "select preview photo":'Sélectionnez l\'image pour voir le représentant',
  "Select the display order in the category":'Sélectionnez l\'ordre d\'affichage dans la catégorie',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.":'Sélectionnez le type de contenu que vous souhaitez voir. Et Feedin collectera et affichera des nouvelles qui conviennent à vos intérêts.',
  "select your country":'Choisis ton pays',
  "selected":'Choisi',
  "seller":'Vendeur',
  "Seller Contact Details":'Coordonnées du vendeur',
  "seller name":'Le nom du vendeur',
  "send":'Envoyer',
  "Send Email":'Envoi postal',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below":'Envoyez des invitations à la chaîne à des amis, en envoyant une copie du lien de la page ci-dessous',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below":'Envoyez une invitation à participer en tant que membre de l\'organisation à des amis, en envoyant une copie du lien de la page ci-dessous',
  "send request":'Envoyer l\'exigence',
  "Send your message to this provider":'Envoyez votre message à cette organisation',
  "sending":'Envoi en cours',
  "sent a request to become member":'A envoyé une demande pour être membre',
  "services":'Service',
  "set main":'Configurer comme le principal',
  "set up content selection by language":'Définir la sélection de contenu dans la langue',
  "share a post":'Partager l\'article',
  "share button":'Bouton de partage',
  "share your ideas":'Partagez vos idées',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.":'Partagez vos informations et connaissances pour améliorer votre présence, la création de marque, vous faire des amis avec des partenaires potentiels ou fournir des informations chargées.',
  "Sharing the information":'Partager l\'information',
  "short description":'Brève description',
  "Short description about chart!":'Brève description du graphique',
  "Short description about your company!":'Brève description de votre entreprise!',
  "Short description about your dataset!":'Brève description du kit de données',
  "show in category":'Afficher dans le groupe d\'articles',
  "show more":'Montre plus',
  "show this post":'Voir l\'article',
  "sign in with":'Connectez-vous avec',
  "signin / signup":'Se connecter S\'inscrire',
  "site":'Adresse du site Web',
  "sku":'Code sp (sku)',
  "Smileys & People":'Smileys et gens',
  "some messages...some messages...":'Quelques messages ... quelques messages ...',
  "Sorry, the page you visited does not exist.":'Malheureusement, votre page d\'accès n\'existe pas.',
  "Sorry, the page you visited has expired or does not exist.":'Désolé, la page que vous visitez a expiré ou n\'existe pas.',
  "source":'Source',
  "Specification Details":'Paramètre',
  "start free trial":'Commencer l\'essai gratuit',
  "start trial":'Commencer procès',
  "start your no-cost {subscription} trial today":'Commencez à essayer {abonnez-vous} gratuitement aujourd\'hui',
  "start your no-cost trial today":'Commencer un essai gratuit aujourd\'hui',
  "state":'État',
  "stay connected":'Garder la connexion',
  "Submit requirement":'Envoyer l\'exigence',
  "subscribe channel":'Abonnez-vous à la chaîne',
  "Subscribe to the categories to display the charts you\'re interested in":'Inscrivez-vous pour suivre les catégories pour afficher les graphiques qui vous intéressent',
  "Subscribe to the channels for information displayed on this page":'Abonnez-vous aux canaux pour des informations affichées sur cette page',
  "subscribed data":'Données enregistrées',
  "subscribers":'Les abonnés',
  "subscribing this channel will cost you":'Abonner à cette chaîne vous fera du gaspillage',
  "subscriptions":'Enregistrer',
  "successfully purchase!":'Acheter avec succès!',
  "suggested products":'Produits proposés',
  "summary":'En bref',
  "Summary of the latest news today":'Résumé des dernières nouvelles aujourd\'hui',
  "supplied by":'Fourni par',
  "provider":'Organisation',
  "provider address":'Adresse de l\'organisation',
  "provider details":'Détails de l\'organisation',
  "provider homepage":'Organisation à domicile',
  "provider name":'nom de l\'organisation',
  "provider profile":'Dossiers de l\'organisation',
  "provider profile was created successful":'Le dossier de l\'organisation a été créé avec succès',
  "provider profiles":'Dossiers de l\'organisation',
  "provider\'s channels":'Canal d\'organisation',
  "providers":'Organisation',
  "Supporting brand promotion and market information to enhance business and networking opportunities.":'Soutenez la promotion de la marque et les informations sur le marché pour améliorer les opportunités de connexion et de commerce.',
  "Symbols":'Symboles',
  "table of contents":'Table des matières',
  "Tap":'Cliquez sur',
  "tax":'Impôt',
  "tax code":'Code fiscal',
  "Tax ID":'Code fiscal',
  "tax inclusive":'Inclus',
  "tax number":'Code fiscal',
  "Tell us what you need":'Dites-nous ce dont vous avez besoin pour rechercher',
  "template":'former',
  "terms of service":'Conditions de service',
  "terms-of-service":'Conditions de service',
  "the {package} subscription will be continue effect until {date}":'L\'enregistrement {package} continuera à prendre effet jusqu\'à {date}',
  "The banner image will be placed on the company\'s homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.":'Des images de bannières seront placées sur la page d\'accueil de l\'entreprise. Meilleure vision avec une résolution de 256 x 1280 pixels. Type de photo: PNG, JPG.',
  "the channel has been deleted":'Le canal a été supprimé',
  "the channel so that the system will automatically help you update with the latest news":'La chaîne du système vous aidera automatiquement à mettre à jour les dernières nouvelles',
  "The chart is enabled and ready to be displayed to the user":'Le graphique est activé et prêt à afficher pour l\'utilisateur',
  "The chart marked as typical and used to represent the data set":'Le graphique est marqué comme typique et utilisé pour représenter l\'ensemble de données',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content":'Le contenu (images, articles ...) partagés dans le canal, seuls les membres de la chaîne voient et discutent. D\'autres utilisateurs ne peuvent pas voir et répondre à ce contenu',
  "the information that interests you":'Informations qui vous intéressent',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other\'s information.":'Les interactions entre ce membre et l\'administrateur de canal de chaque membre seront absolument confidentielles. Seuls les administrateurs ou les individus dans le canal peuvent voir les informations de l\'autre.',
  "the latest news":'Dernières nouvelles',
  "The members shown here include: Owner, administrator, member as contact person.":'Les membres affichés ici incluent: le propriétaire, l\'administrateur et les membres sont le contact.',
  "The page content is not yet available or being written":'Le contenu de la page n\'est pas disponible ou est écrit',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.":'La page que vous recherchez n\'existe pas. Cliquez sur le bouton ci-dessous pour revenir à la page d\'accueil.',
  "the restore email has been sent to":'Restaurer le courrier électronique a été envoyé',
  "The state of the data set. If \"On\", the dataset is ready to use":'L\'état de l\'ensemble de données. Si "allume", le kit de données est prêt à l\'emploi',
  "the provider has been deleted":'L\'organisateur a été supprimé',
  "the providers i am involved in":'L\'organisateur que j\'ai participé',
  "The tax code value has been saved successfully":'Le code des impôts a été enregistré avec succès',
  "The verification call is pending":'L\'appel de vérification attend le traitement',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account":'Ce compte n\'a pas enregistré l\'adresse e-mail pour vérifier, veuillez enregistrer l\'adresse e-mail pour authentifier la propriété du compte',
  "This channel content is limited to members only, join now to view this channel content.":'Ce contenu du canal est limité aux membres, rejoignez-vous maintenant pour voir le contenu de ce canal.',
  "This channel doesn\'t have any pinned post":'Ce canal n\'a pas de message',
  "this channel supports communication in the form of hidden interactions between members":'Ce canal soutient la communication sous forme d\'interaction cachée entre les membres',
  "this channel to your friends":'Ce canal pour vos amis',
  "This channel will charge you":'Le canal vous facturera',
  "This is a description.":'Voici la description',
  "this is a regular channel":'Ceci est un canal ordinaire',
  "This is an error message":'Il y a un message d\'erreur',
  "This product will be removed from your Catalog and won\'t be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?":'Ce produit sera supprimé de votre catégorie et ne sera pas affiché avec l\'acheteur. Vous cesserez également progressivement de prendre Buyleads pour ce produit. Es-tu sur de vouloir continuer?',
  "Time for updating the tracking list:":'Le temps permet de mettre à jour les catégories de suivi:',
  "timeline is only viewable with accounts that are members of this channel":'Le calendrier ne peut être consulté qu\'avec des comptes en tant que membres de ce canal',
  "timeline is viewed internally only":'La chronologie n\'est vue qu\'à interne',
  "title or question":'Question',
  "to channel":'À la chaîne',
  "to create new channel on your company":'Pour créer une nouvelle chaîne sur votre entreprise',
  "to create your new channel":'Pour créer de nouveaux canaux',
  "To edit your details, please visit {profile}":'Pour une modification détaillée, veuillez visiter {Profil}',
  "to find content that interests you":'Pour trouver le contenu qui vous intéresse',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member":'Pour participer à la chaîne et surveiller régulièrement le contenu, vous devez vous connecter / vous inscrire à un nouveau compte (gratuit) pour devenir membre',
  "To make changes to your subscriptions , go to":'Pour changer votre inscription, allez',
  "To manage products visit at {manage_products} or continue with {call_verification}":'Pour gérer le produit, visitez {Management_Products} ou continuez avec {call_verification}',
  "To stay on your current plan after your trial ends, add payment now.":'Pour garder votre inscription après la fin de votre essai, veuillez payer maintenant.',
  "to upgrade. This subscription cost you":'mettre à jour. Ce forfait d\'inscription est pour vous',
  "topic":'Sujet',
  "Total due":'Total à ce jour',
  "Total due {date}":'Total à ce jour {date}',
  "Travel & Places":'Voyages et lieux',
  "trial":'Procès',
  "trial days":'Nombre de jours de procès',
  "trust and safe":'Confiance et sécurité',
  "trust-safe":'Confiance et sécurité',
  "tutorials":'Instruire',
  "type product name":'Tapez le nom du produit',
  "type your message":'Tapez votre message',
  "typify chart":'Graphique typique',
  "unban":'Abandonner',
  "unbookmark":'Marquer',
  "unbookmarked":'Marqué',
  "unclassified products":'Le produit n\'a pas été classé',
  "unpin":'Broches',
  "unpin this?":'enlever cet article',
  "unsave":'La version n\'est pas enregistrée',
  "unsubscribe":'Annuler l\'inscription',
  "update about success":'Introduction des mises à jour réussies',
  "update dataset":'Mettre à jour l\'ensemble de données',
  "update fail":'Mettre à jour les erreurs',
  "update success":'Mise à jour réussie',
  "Updates to you the information you have chosen to follow":'Mettez à jour à vous les informations que vous avez suivies',
  "upgrade":'Améliorer',
  "upgrade now. This subscription cost you":'Mettre à jour maintenant. Cette inscription à vos frais',
  "upgrade subscription":'Améliorer le package d\'inscription',
  "upgrade this channel":'Mise à niveau du canal',
  "upgrade to":'Améliorer',
  "upgrade to {name}":'Mettre à niveau vers {nom}',
  "upload":'Télécharger',
  "url":'Chemin',
  "us to share the news in this channel":'Avec nous pour partager des nouvelles dans cette chaîne',
  "Usage: for direct communication purposes through messaging.":'Utilisation: à des fins de contact direct via des messages',
  "use email":'Pseudo (email',
  "use the selected system language":'Utiliser dans le langage système sélectionné',
  "used group":'Les groupes utilisés',
  "user choices":'Sélectionner affiché par les utilisateurs',
  "user management":'Gestion de compte',
  "uses":'Utilisé',
  "Verification request has been sent, please wait for a response":'La demande de vérification a été envoyée, veuillez attendre les commentaires',
  "verified provider":'L\'organisation a été confirmée',
  "Verify Account":'Vérifier le compte',
  "Verify on Call":'Vérifier les appels',
  "Verify sales information":'Vérification des informations sur les ventes',
  "vi":'vietnamien',
  "video url":'URL vidéo (lien YouTube)',
  "vietnamese":'vietnamien',
  "view":'Voir',
  "view all":'Voir tout',
  "View all categories":'Voir toutes les catégories',
  "View all products in {name}":'Voir tous les produits dans {nom}',
  "View complete detail":'Voir les détails',
  "view full estimate":'Voir la période de paiement complète',
  "view more charts":'Voir plus de graphiques',
  "view product page":'Afficher la page du produit',
  "views":'Heures d\'accès',
  "visit page":'Visiter Trang',
  "Visit the page to preview":'Visitez la page pour prévisualiser',
  "vnpay":'Vnpay',
  "waiting admin accept your request":'Attendez que l\'administrateur accepte votre demande',
  "waiting members list":'DS inscrit pour rejoindre',
  "wanna more features?":'Voulez-vous ajouter des fonctionnalités ?',
  "we will upgrade you immediately, you won\'t have to pay anything until your trial ends":'Nous vous améliorerons immédiatement, vous n\'aurez rien à payer jusqu\'à la fin de votre essai.',
  "we will upgrade you to {subscription} immediately, you won\'t have to pay anything until your trial ends":'Nous allons vous mettre à niveau vers {abonner} immédiatement, vous n\'aurez rien à payer jusqu\'à la fin de votre essai.',
  "welcome":'Bonjour',
  "What are you looking for?":'Qu\'est-ce que tu cherches?',
  "what is your chart name?":'Quel est le nom de votre graphique?',
  "what is your company name?":'Votre entreprise est appelée?',
  "what is your name?":'Quel est ton nom?',
  "what is your question?":'Quelle est votre question?',
  "what your channel name?":'Quelle est votre chaîne?',
  "what your product name?":'Quel est ton nom?',
  "when you\'re ready":'Quand tu es prêt',
  "which categories are your interests?":'De quelle catégorie vous souciez-vous ?',
  "with account":'Par compte',
  "write a response":'Écrire un commentaire',
  "write article":'Répondre',
  "write description for this page":'Écrivez une description de cette page',
  "write something about this page":'Écrivez quelque chose sur cette page',
  "Write your sharing":'Écrivez votre part',
  "written by":'Écrit par',
  "Year of Establishment":'Année fondée',
  "You are not choosing to follow this chart category. Please choose follow up in advance":'Vous ne choisissez pas de suivre la catégorie de ce graphique. Veuillez choisir de suivre à l\'avance',
  "you can not change your following categories before {time}":'Vous ne pouvez pas modifier la catégorie de suivi avant l\'heure {temps}',
  "you can not follow more {n} categories":'Tu ne peux pas trop suivre',
  "You can only upload JPG/PNG file":'Vous ne pouvez télécharger que le format JPG / PNG',
  "you commented on {owner}\'s post":'Vous avez commenté l\'article de {propriétaire}',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account":'Vous avez actuellement la limite maximale du canal {n} autorisé à posséder. Vous pouvez le changer en améliorant votre compte',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account":'Vous avez actuellement la limite maximale de l\'organisation {n} autorisée à posséder. Vous pouvez modifier la limite en améliorant votre compte',
  "you didn\'t enter the channel name correctly":'Vous n\'avez pas saisi correctement le nom de canal correct',
  "you didn\'t enter the provider name correctly":'Vous avez entré le nom de l\'entreprise qui n\'est pas correct',
  "You do not have a conversation selected":'Vous n\'avez pas encore choisi de conversations',
  "you don\'t have permission to post":'Ne publiez pas',
  "you have not any notification!":'Vous n\'avez pas encore de préavis!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.":'Vous pouvez choisir l\'élément maximum {n} à surveiller. Les nouvelles seront filtrées en fonction de la catégorie que vous choisissez.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.":'Vous pouvez choisir l\'élément maximum {n} à surveiller. Les nouvelles seront filtrées dans la langue que vous choisissez.',
  "You need {amount} to be able subscribe this channel.":'Vous avez besoin de {montant} pour pouvoir vous abonner à ce canal.',
  "you need login first":'Vous devez vous connecter avant la mise en œuvre',
  "Your account has been activated":'Votre compte a été activé',
  "your answer":'Votre Réponse',
  "Your business information has been fully updated, thank you":'Vos informations commerciales ont été entièrement mises à jour, merci',
  "your chart":'Votre graphique',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you":'Votre compte actuel permet uniquement une langue, si vous souhaitez supprimer cette limite, veuillez passer vers le package supérieur. Merci',
  "Your current active subscription package will continue to hold until it expires":'Votre forfait d\'exploitation actuel continuera de continuer jusqu\'à l\'expiration',
  "your device don\'t support this!":'L\'appareil ne prend pas en charge cette fonction',
  "your invite link expires":'Votre lien expirera',
  "your message":'Votre message',
  "Your phone number":'Votre numéro de téléphone',
  "your post":'votre écriture',
  "your request is pending approval":'Votre demande attend l\'approbation',
  "your subscription will be renew on {date}":'Votre inscription sera étendue à {date}',
  "Your message have been sent":'Votre message a été envoyé',
  "verified":'Vérifié',
  "price history":'Historique des prix',
  "product groups":'Groupes de produits',
  "representative":'Représenter',
  "representative chart":'Graphique représentatif',
  "the content will be delivered to you in the language you choose":'Le contenu vous sera livré dans la langue que vous choisissez',
  "favorites":'Favoris',
  "chart name":'Nom de graphique',
  "select categories":'Sélectionnez la catégorie',
  "the dataset has been deleted":'L\'ensemble de données a été supprimé',
  "create article":'Créer des articles',
  "have sent you invitation":'vous a envoyé une invitation',
  "related dataset":'Ensembles de données connexes',
  "Send invitation to your friends, by sending a copy of the page link below":'Envoyez une invitation à vos amis, en envoyant le lien de la page ci-dessous',
  "Copy and share the page link below to your friends.":'Copiez et partagez le lien de page ci-dessous pour vos amis.',
  "Response from provider will be sent via {email}":'Les commentaires de l\'organisation seront envoyés via {email}',
  "action":'Loi',
  "timeout":'Temps effectif',
  "limit":'Limiter le nombre d\'invitations',
  "5 minutes":'5 minutes',
  "30 minutes":'30 minutes',
  "1h":'1 heure',
  "1day":'Un jour',
  "Join As Member":'Rejoindre en tant que membre',
  "Join As Admin":'Participer en tant qu\'administrateur',
  "others data":'Autre informations',
  "channel management":'Gestion post-canal',
  "channel list":'Canal DS',
  "all channels":'Tous les canaux postaux',
  "technical specs":'Caractéristiques',
  "functional specs":'Paramètres fonctionnels',
  "other specs":'Autres paramètres',
  "custom specs":'Paramètres personnalisés',
  "shipment":'Expédition',
  "period":'Période',
  "spot":'Place',
  "forward":'Avant',
  "basis":'Base',
  "select period":'Sélectionnez le terme',
  "from date":'Depuis',
  "to date":'À ce jour',
  "Select the category in which the system will provide you with the most current market data":'Sélectionnez la catégorie que le système vous fournira les dernières données du marché',
  "Thanks sponsors bring freely these charts to you":'Merci aux sponsors d\'avoir apporté ces graphiques gratuitement',
  "Thanks sponsors for updating this news to you":'Merci aux sponsors d\'avoir mis à jour ces nouvelles à vous',
  "quotes of provider":'Citation de l\'organisation',
  "announce":'Date d\'avis',
  "product price announcement":'Avis de prix du produit',
  "create report":'Créer des rapports',
  "the recipient\'s email":'L\'e-mail du destinataire',
  "new email":'Nouveau courriel',
  "price_type":'Prix',
  "Pricing Reports":'Rapport de prix du produit',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce":'Créez un avis de prix à envoyer aux acheteurs, des partenaires pour acheter ou informer les prix du produit',
  "products quotation":'Prix ​​du produit',
  "effected date":'Date effective',
  "price update":'Mettre à jour le prix',
  "no price tag":'Pas de prix',
  "updated at":'Mettre à jour',
  "reference price":'Prix ​​de référence',
  "basis price":'Prix ​​de base',
  "forward price":'Prix ​​du terme',
  "delivery type":'Livraison',
  "general basic prices":'Prix ​​de base général',
  "leave blank to select all":'Laissez vide pour choisir tout',
  "This information is confidential and is only for the member to be submitted":'Cette information est la sécurité et est uniquement pour que les membres soient envoyés',
  "Sure to remove?":'Vous voulez sûrement supprimer?',
  "Enter a price for the period":'Entrez le prix pour la période',
  "advanced entry":'Importation avancée',
  "flat price":'Prix ​​fixe',
  "quoted prices":'Taux de rack',
  "start (/end) date in term months":'Date de début (/ fin) dans les termes du terme',
  "select provinces":'Choisissez la province / ville',
  "spot price":'Au prix ponctuel',
  "confirm":'Confirmer',
  "delivery type option (delivery is default): this option affects the product price":'Option de prix sous forme de distribution (l\'expédition est par défaut): Cette option affecte le prix du produit',
  "delivery price":'Prix ​​de livraison',
  "shipment price":'Prix ​​de l\'expédition',
  "add spot price":'Ajouter un nouveau prix de livraison',
  "add forward price":'Ajouter un nouveau prix à terme',
  "period range":'Période',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed":'Ces informations sont ajustées dans le cadre du rapport, le contenu des informations initiales n\'a pas changé',
  "original price":'Coût',
  "feedin users":'Utilisateurs sur Feedin',
  "choose user":'Choisir les utilisateurs',
  "Select the term for the price input":'Choisissez le terme pour le prix du prix',
  "price unit":'Prix ​​đ / v',
  "enter new price":'Entrez le nouveau prix',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)":'Entrez / mise à jour dans cet écran, le prix sera mis à jour à l\'heure actuelle pour chaque type de prix (prix au comptant, prix à terme)',
  "incoterm":'Inccoter',
  "transport type":'Transport',
  "unit of the basis price":'Unité pour le prix de base',
  "new price record by location":'Nouveau enregistrement de prix par emplacement',
  "This location has been declared, cannot be added":'Cet endroit a déclaré, ne peut pas ajouter de nouveau',
  "choose another location":'Sélectionnez un autre emplacement',
  "new price set":'Nouvel ensemble de données de prix',
  "choose another option for new price set":'Choisissez une autre option pour le nouvel ensemble de prix',
  "These options are already declared, cannot be added":'Ces options ont été déclarées, ne peuvent pas être ajoutées',
  "add new forward price":'Ajouter un nouveau prix à terme',
  "edit quotes":'Modifier la citation',
  "other unit":'autre unité',
  "if basis price is required":'Si le prix de base est requis',
  "range":'À propos',
  "editor\'s choice":'Choisissez l\'éditeur',
  "remove the 2nd unit":'Supprimer la 2ème unité',
  "hidden price":'Pas de prix de vente',
  "wholesale":'De gros',
  "contact provider for wholesale price":'Contactez l\'organisation à un prix de gros',
  "provided by":'Fourni par',
  "about product":'À propos du produit',
  "brief description":'Brève description',
  "manufactor":'Producteur',
  "add new specification":'Ajouter de nouveaux paramètres',
  "selecting...":'Statue de la Liberté ....',
  "method type":'Catégorie',
  "operation":'Opération',
  "attribute":'Propriétés',
  "attribute value":'Valeur des attributs',
  "factory":'Usine',
  "Specification/Additional Details":'Paramètres / propriétés supplémentaires',
  "tap on here to start your articles":'Cliquez ici pour démarrer l\'article',
  "attribute group":'Groupe d\'attributs',
  "displayed on product information":'Afficher les informations sur le produit',
  "page not found":'Trang n\'existe pas',
  "The page you are opening does not exist, please check the link or return to the homepage":'La page que vous êtes ouverte n\'existe pas, veuillez vérifier le lien ou revenir à la page d\'accueil',
  "catalog group":'Groupe de table',
  "manage page":'Page de gestion',
  "main page":'Page d\'accueil',
  "use for":'Utiliser pour',
  "set_channel":'Mettre à jour le canal postal',
  "added value":'Prix ​​plus',
  "product line":'Groupe de lignes de produit',
  "2nd unit":'Unité 2',
  "2nd price unit":'Prix ​​unitaire 2',
  "The effective date of the current price set will be adjusted to the date below":'La date valide de l\'ensemble de prix actuel ajustera la date ci-dessous',
  "Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.":'Créez de nouvelles données mises à jour des prix à prendre effet en fonction du lendemain. Remarque: les données seront temporairement enregistrées au prix du dernier jour.',
  "Copy the current priceset for another location with the same priceset":'Copiez le prix actuel fixé pour une autre position avec le même prix',
  "Please contact the administrator of FeedIn to put the data on the site":'Veuillez contacter les administrateurs de Feedin pour mettre des données sur le site',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'Collez le chemin ici pour intégrer du contenu à partir d\'un autre site Web (par exemple: Twitter) et cliquez sur OK',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'Collez le chemin ici pour plonger la vidéo à partir d\'un autre site Web (par exemple: YouTube) et cliquez sur OK',
  "Announcement of information":'Avis d\'information',
  "Sell products, supply items to the market":'Vendre des produits, fournir des articles au marché',
  "Provide published information and data for the industry":'Fournir des informations et des données publiées sur l\'industrie',
  "event name":'Nom de l\'événement',
  "what is your event name?":'Quel est le nom de l\'événement?',
  "end date and time":'Ajouter la fin du temps',
  "back to home":'Retour à la page principale',
  "meeting":'Réunion',
  "online":'En ligne',
  "online event?":'Événement en ligne?',
  "online event":'Événement en ligne',
  "created by":'Créé par',
  "milestone":'L\'événement Placter',
  "start time":'Heure de début',
  "Select event type (online / meeting)":'Sélectionnez le formulaire d\'événement (en ligne / réunion)',
  "Event, what?, when?":'Événements, sur quel sujet ?, Temps?',
  "Event name, start time":'Nom de l\'événement, le temps a lieu',
  "Add a physical location for people to join your event. Or how to meet together in online event":'Ajoutez une position réaliste pour que tout le monde puisse participer à votre événement. Ou comment se rencontrer dans l\'événement en ligne',
  "Event, where?":'Événements, où?',
  "Provide more information about your event so guests know what to expect.":'Fournissez plus d\'informations sur votre événement afin que les clients sachent ce qui se passera.',
  "Event, how?":'Événements, comment?',
  "Everyone meeting on the internet.":'Les gens se rencontrent sur Internet.',
  "Everyone meet together at a specific location.":'Tout le monde s\'est rencontré à un endroit spécifique.',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'Si vous avez complètement oublié votre ancien mot de passe. Veuillez vous déconnecter et utiliser la fonction "{1}" pour réinitialiser le mot de passe si nécessaire',
  "Please select the language you want as the display language on the application":'Veuillez sélectionner la langue que vous souhaitez afficher la langue sur l\'application',
  "farm":'Cultiver',
  "recover login password":'Restaurer le mot de passe de connexion',
  "individual":'Individuel',
  "you are":'Tu es',
  "organization name":'nom de l\'organisation',
  "attention: please check your activation email in the spam area":'Remarque: veuillez vérifier votre e-mail d\'activation dans la zone du spam',
  "with your email":'Par votre adresse e-mail',
  "no products, please add products to your page":'Sans produits, veuillez ajouter des produits à votre site',
  "priceset history management":'Historique de formation des prix',
  "product configuration":'Configuration du produit',
  "events in category":'Événements dans la catégorie',
  "no events":'Pas d\'évènement',
  "select / create":'Choisissez / créez de nouveaux',
  "co-admins":'Co-administration',
  "weekly":'Hebdomadaire',
  "quarterly":'Trimestriel',
  "yearly":'Annuel',
  "the event will repeat in":'L\'événement sera répété',
  "finish time":'Heure de fin',
  "updating date":'Jour de mise à jour',
  "price is valid from the date":'Le prix est valable à la date',
  "contract type":'Type de contrat',
  "reminder":'Rappeler',
  "type of event?":'Catégorie',
  "speakers":'Haut-parleurs',
  "select user":'Choisir les utilisateurs',
  "event_types":'Catégorie',
  "something is wrong, want to delete it?":'Quelque chose ne va pas, vous voulez le supprimer?',
  "successfully deleted":'Retardé avec succès!',
  "are you sure to delete this?":'Êtes-vous sûr de supprimer cela?',
  "deletion is ignored":'L\'action supprimée a été ignorée',
  "insert here":'Insérer ici',
  "not milestone":'Développements',
  "The next event is scheduled in":'L\'événement suivant est prévu plus tard',
  "The next event is scheduled to take place at {1}":'L\'événement suivant devrait avoir lieu à {1}',
  "products can be searched":'Peut rechercher des produits',
  "your product has been declared complete, you can put it on the store":'Votre produit a été entièrement déclaré, vous pouvez le mettre sur le stand',
  "Do you want to activate this product?":'Voulez-vous activer ce produit?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you":'Ce produit ajoutera à votre portefeuille activé et l\'affichera avec l\'acheteur. Vous commencerez également à recevoir Buyleads pour ce produit. Merci',
  "profiles":'Déposer',
  "incoming requests management":'Demandes de gestion envoyées',
  "waiting members":'Les membres s\'inscrivent',
  "purchases":'Achat',
  "quote prices":'Gestion des citations',
  "market prices":'Prix ​​du marché',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly":'Les coordonnées n\'ont pas été déclarées, il est très bien si les coordonnées sont déclarées afin que les clients puissent vous contacter directement',
  "members management":'Gérer le membre',
  "partners":'Partenaire',
  "vendor":'Fournisseur',
  "vendors":'Fournisseur',
  "customer":'Client',
  "customers":'Client',
  "contact books":'Annuaire',
  "add new contact book":'Ajouter de nouveaux contacts',
  "connect to user":'Lien vers les utilisateurs',
  "connect to organization":'Lien vers l\'organisation',
  "connect to partner":'Lien vers les partenaires',
  "new contact book":'Ajouter de nouveaux contacts',
  "new partner":'Ajouter de nouveaux partenaires',
  "partner type":'Type de partenaires',
  "partner name":'Nom de partenaire',
  "edit contact book":'Édition de contact',
  "edit partner":'Modifier les partenaires',
  "all partners":'Tous les partenaires',
  "add new partner":'Ajouter de nouveaux partenaires',
  "for sales notes":'Billets de vente',
  "for purchase notes":'Acheter des notes',
  "notes":'Note',
  "Note: The effective date of the price being updated is different from the price entry date":'Remarque: le prix de mise à jour de la date est différent de la date de prix de l\'importation',
  "product catalog":'Groupe d\'affichage de produit',
  "group by":'Groupe',
  "type of product use for sales / purchases":'Produits utilisés pour les ventes / achats',
  "sales / purchases":'Ventes / achats',
  "added":'Ajoutée',
  "feedin organizations":'Organisé sur un randonnée',
  "refresh chart":'Mise à jour',
  "incoming quotes":'Quotion envoyée',
  "purchasing planning":'Planification des achats',
  "manage outgoing quotes":'Gérer les devis envoyés',
  "product group title":'Nom du groupe de produits',
  "Successfully Sign Up":'Inscrire le succès',
  "redirect in {1} seconds":'Retour derrière {1} secondes',
  "Thank you for registering, Website will redirect to login page after 3 seconds":'Merci de votre inscription, le site Web passera à la page de connexion après 3 secondes',
  "add products":'Ajouter le produit',
  "price offer partners":'Partenaire fournissant le prix',
  "Select the products for which you want to send price information to your partner":'Choisissez le produit que vous souhaitez envoyer des informations sur les prix aux partenaires',
  "products for partner":'Produits pour les partenaires',
  "Note: the list below shows only partners linked to the organizations on FeedIn":'Remarque: La liste ci-dessous affiche uniquement des partenaires associés aux organisations sur Feedin',
  "bookmarks":'Livre livre',
  "my space":'Coin privé',
  "all bookmarks":'Tous les marqueurs',
  "quotations for partners":'Citation pour les partenaires',
  "favourites":'Favoris',
  "vietnam market":'Marché du Vietnam',
  "workspace":'Travail de coin',
  "updates":'Mise à jour',
  "for search optimization":'Pour optimiser les travaux de recherche',
  "to optimize product display":'Afin d\'optimiser l\'affichage du produit',
  "to group products with the same properties, compare prices":'Afin de rassembler les produits des mêmes propriétés, comparez les prix',
  "be sent from partners":'Envoyé des partenaires',
  "owned":'Posséder',
  "Prices below are average prices with selected location or selected period is All":'Le prix ci-dessous est le prix moyen avec la position sélectionnée ou la période de temps sélectionnée est tout',
  "Can follow the latest news and events in the industry":'Peut suivre les dernières nouvelles et canaux d\'événements dans l\'industrie',
  "Explore dataset and charts":'Découvrez les ensembles de données et les graphiques',
  "to help you manage":'Pour vous aider à administrer',
  "or create an organization as":'Ou créer une organisation avec',
  "farmer":'Cultiver',
  "buyer":'Achat',
  "Create events to reach the commodity market":'Créer des événements pour accéder au marché',
  "Create news channels to announce and share information to the commodity market":'Créer des canaux d\'information pour publier et partager des informations sur le marché des matières premières',
  "Cooperate with FeedIn to share and provide commodity data to customers":'Collaborer avec Feedin pour partager et fournir des données de marchandises aux clients',
  "Please contact the administrator of FeedIn":'Veuillez contacter l\'administrateur de Feedin',
  "name required more than {1} characters":'Le nom du contenu nécessite plus de caractères {1}',
  "set subscription":'S\'inscrire',
  "subscribed":'Inscrit',
  "edit contact":'Édition des coordonnées',
  "Information gathered from the declaration in the contact information":'Informations collectées à partir de la déclaration des coordonnées',
  "The information will be linked to the information of partner":'Les informations seront associées aux informations du partenaire',
  "product had basic information and ready to launch":'Le produit a des informations de base et est prêt à lancer',
  "internal use":'Pour la circulation interne uniquement',
  "{launch} the product to display on the store":'{Lancement} Produits à afficher sur le magasin',
  "OR {activate} to input the price and manage it internally":'Ou {activer} pour importer et gérer les prix internes',
  "launch":'Lancement',
  "activate only":'Activité ouverte',
  "{deactivate} product":'{désactiver} Produit',
  "deactivate":'Annuler l\'état de fonctionnement',
  "set effect date":'Ajuster la date valide',
  "insert at":'Ajouter le prix à la date',
  "expire at":'Arrêter la date valide',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.":'La page n\'existe pas ou la page d\'utilisation interne, vous devez être un nouveau membre pour voir le contenu de cette page.',
  "hybrid event":'Réunion de combinaison / en ligne',
  "no data yet":'Pas de données',
  "Please login to view this content":'Veuillez vous connecter pour afficher ces informations',
  "no data is available now":'Il n\'y a actuellement pas de données',
  "latest tracking news":'Dernières nouvelles de suivi',
  "activate":'Activé',
  "Buy goods, collect quotes from sellers":'Acheter, synthétiser les devis de réception du vendeur',
  "following charts":'Les graphiques regardent',
  "Leave your information so we can contact you again":'Laisser des informations pour que nous puissions contacter à nouveau',
  "purchase":'Achat',
  "enter spot price":'Entrez le prix au comptant',
  "present":'Actuellement',
  "weather":'Temps',
  "Temperature - weather, tap to see more":'Température - temps, cliquez pour en voir plus',
  "Rain (thunder) - weather, tap to see more":'Pluie (Thunder) - Météo, cliquez pour en voir plus',
  "Wind - weather, tap to see more":'Vent - temps, cliquez pour en voir plus',
  "temperature":'Température',
  "rain":'Pluie',
  "wind":'Vent',
  "edit & save":'Réparer et économiser',
  "create from":'Créer de nouveaux mots',
  "remove expired":'Quitter',
  "delete prices at":'Supprimer le prix à',
  "copy prices at":'Copier le prix à',
  "enter forward price":'Entrez le prix du terme',
  "affiliate products":'Liaison des produits',
  "at date":'à',
  "This pricing information will expire in":'Ces informations sur les prix expireront plus tard',
  "This pricing information will expire at":'Ces informations sur les prix expireront',
  "no expired date":'Il n\'y a pas de date d\'expiration',
  "quarters":'précieux',
  "you have an article in progress":'Vous avez un article mis en œuvre',
  "Create an information channel to promote your information and products to everyone":'Créez des canaux d\'information pour promouvoir vos informations et produits à tout le monde',
  "get {free} quotes from multiple sellers":'Obtenez un devis {gratuit} de nombreux vendeurs',
  "Tell us your Requirement":'Faites-nous savoir votre demande',
  "Receive free quotes from sellers":'Obtenez des devis gratuits du vendeur',
  "Seal the Deal":'Effectuer une transaction',
  "Enter product/ Service name":'Entrez le nom du produit / service',
  "Your email address":'Votre adresse e-mail',
  "Enter your name":'Entrez votre nom',
  "Your live in":'Où  habites-tu',
  "We connect":'Nous nous connectons',
  "Buyers Sellers":'Acheteur vendeur',
  "Trusted Platform":'Plate-forme fiable',
  "Safe Secure":'Sécurité sûreté',
  "Quick Assistance":'Soutien rapide',
  "Create events so people can participate and learn more about your organization":'Créez des événements pour que tout le monde puisse participer et comprendre davantage sur votre organisation',
  "my articles":'Mon article',
  "Use keyboard shortcuts":'Utiliser les raccourcis',
  "edited by":'Rédigé par',
  "Publishing to:":'Publié par:',
  "write caption for image":'Écrivez une note pour les images',
  "caption of image":'Remarque d\'images',
  "write caption for video":'Écrivez la légende de la vidéo',
  "caption of video":'Légendes vidéo',
  "video url is invalid!":'La vidéo n\'existe pas!',
  "enter video url":'Entrez le chemin vidéo',
  "add caption":'Ajouter des notes',
  "related products":'Produits connexes',
  "products from the same organization":'Produits avec la même organisation',
  "get direction":'Guide',
  "export purchasing data":'Exporter des données d\'achat',
  "export sales data":'Exportation de données de vente',
  "select page":'Sélectionner la page',
  "send requirement":'Envoyer l\'exigence',
  "detail requirement":'Détails requis',
  "attach file":'Attacher',
  "how it work":'Comment opérer',
  "tell us what you need by filling the form":'Remplissez votre formulaire de demande',
  "receive verified supplier details":'Recevoir des devis des fournisseurs',
  "compare quotations and seal the deal":'Comparez les devis et les transactions',
  "your information":'Vos informations',
  "choosing a category will help the article stay on topic and more accessible to users":'La sélection de la catégorie aidera l\'article sur son sujet et plus accessible aux utilisateurs',
  "please add images to make the article more vivid and realistic to attract more viewers":'Veuillez ajouter des images pour rendre l\'article plus vivant et réaliste pour attirer plus de téléspectateurs',
  "data has been generate":'Les données ont été créées',
  "please click the {download} button below to download to your device":'Veuillez appuyer sur le bouton {Télécharger} ci-dessous pour télécharger l\'appareil',
  "article preview":'Aperçu l\'article',
  "back to edit":'Retour à l\'édition',
  "please login so the supplier can contact you":'Veuillez vous connecter pour que le fournisseur puisse vous contacter',
  "product information":'Information produit',
  "please provide a few details to get the most accurate response from the supplier":'Veuillez fournir des informations détaillées pour obtenir les commentaires les plus précis du fournisseur',
  "article has no images":'L\'article n\'a pas d\'image',
  "this channel belongs to":'Ce canal appartient à',
  "unit price":'Prix ​​unitaire',
  "posted at":'Envoyé à l\'époque',
  "respond at":'Réponse à',
  "view website":'Voir la page principale',
  "send message":'Envoyer le message',
  "resend request":'Renvoyer la demande',
  "close request":'Fermé',
  "view detail":'Voir les détails',
  "hide detail":'Cacher les détails',
  "not sure":'Ne sont pas',
  "sure":'Je suis sûr',
  "you have found a supplier for this product and you want to close this request":'Vous avez trouvé un fournisseur pour ce produit et vous souhaitez fermer cette demande',
  "are you sure":'Es-tu sûr',
  "company name (if you buy for the company)":'Nom de l\'entreprise: (le cas échéant, si vous achetez pour l\'entreprise)',
  "Request a quote / Order":'Demandez le devis / commande',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'L\'avatar est choisi dans l\'article, reviens et ajoute l\'image à l\'article plus vif et plus attrayant',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'Recevez le nouveau devis précis du fournisseur pour le produit qui vous intéresse',
  "filtered by":'Filtrez les résultats par',
  "my order request":'Demande de commande',
  "keyword: request id, organization id (name)":'Mots-clés: ID requis, ID d\'organisation (nom)',
  "order requests management":'Gérer les exigences de commande',
  "press enter to create new page":'Appuyez sur Entrée pour créer une nouvelle page',
  "add new page":'Ajouter une nouvelle page',
  "product info":'Information produit',
  "order request type":'Type de demande',
  "request type":'Exigences pour',
  "received at":'Accepter',
  "min quantity":'Quantité la plus basse',
  "productinfo":'Information produit',
  "deleted":'Supprimé',
  "alert drafting article description":'Cet article change et n\'a pas été publié. Vous pouvez cliquer ici pour continuer à rédiger et à publier.',
  "delete article":'Supprimer l\'article',
  "language linking":'Lien de langue',
  "articles in other languages":'Autres langues',
  "Are you sure remove this related article?":'Êtes-vous sûr de supprimer cet article connexe?',
  "Type the title of the article in other languages to search and add the article you want to link to.":'Tapez le titre de l\'article dans d\'autres langues pour rechercher et ajouter l\'article auquel vous souhaitez lier.',
  "linked articles":'L\'article est lié',
  "edit article setting":'Modifier les informations de l\'article',
  "owner by":'Appartenant à',
  "grateful description":'Nous remercions sincèrement les sponsors',
  "You can click \"See more\" to see this full information":'Vous pouvez cliquer sur "Voir plus" pour voir complètement ces informations',
  "your donation description":'Voici votre don. S\'il y a quelque chose qui ne va pas, veuillez contacter l\'administrateur de la campagne. Nous vous remercions pour votre contribution.',
  "contribution chart":'Tableau des contributions',
  "list of contributors":'Liste de contributions',
  "donated items":'Contribué',
  "pending items":'N\'a pas contribué',
  "search with donation code":'Recherche avec le code de don',
  "equivalent to":'équivalent',
  "has a capacity of":'La capacité est',
  "my donations":'Ma contribution',
  "thank you card":'Carte de remerciement',
  "sincerely thank you":'Merci sincèrement',
  "supported the donation campaign":'a soutenu la campagne de don',
  "money / goods":'Montant / article',
  "Sincere thanks to the benefactors for accompanying us to create a picture of kindness.":'Merci sincèrement pour votre compagnon afin que nous puissions créer une image de gentillesse.',
  "Wishing you good health, peace and happiness.":'Nous vous souhaitons à tous la santé, la paix et le bonheur.',
  "Kind regards,":'Cordialement,',
  "poll question":'Sondage',
  "latest contributions":'Dernière contribution',
  "news update":'Mettre à jour les nouvelles',
  "export as PNG":'Sortir',
  "register now":'S\'inscrire maintenant',
  "would you like to donate another value":'Voulez-vous donner une autre valeur?',
  "choose the package that you can donate":'Choisissez le package que vous pouvez donner',
  "register to contribute":'Inscrivez-vous à la contribution',
  "copy path":'Copier le chemin',
  "click here to start writing":'Cliquez ici pour commencer à écrire',
  "impact-categories":'Impact',
  "host request":'Demande d\'être une unité d\'organisation',
  "host-request":'Demande d\'être une unité d\'organisation',
  "adding an address will help people find you":'L\'ajout de l\'adresse aidera les gens à vous trouver',
  "load more":'Télécharger plus',
  "waiting-members":'Les membres attendent',
  "add host":'Ajouter le propriétaire',
  "Are you sure delete this host?":'Êtes-vous sûr de supprimer ce propriétaire?',
  "confirmation":'Confirmer',
  "Deleted {type} are gone forever. Are you sure?":'{Type} supprimé disparaîtra en permanence. Es-tu sûr?',
  "{type} name":'Nom {type}',
  "you didn\'t enter the {type} name correctly":'Vous n\'avez pas saisi le nom correct {type}',
  "select tags":'Choisissez les balises',
  "finished":'Fini',
  "Allow contribution information to be visible to the public":'Autoriser les informations sur les contributions du public',
  "Allow charity work information to be visible to the public":'Permettez au public d\'afficher des informations sur la charité',
  "Allows to be searched by recruitment agencies":'Autoriser les agences de recrutement',
  "clip_url":'Chemin vidéo',
  "edit owner":'Modifier le propriétaire',
  "ref_ids":'Lien de campagne',
  "edit campaign link":'Édition de campagne',
  "add target details":'Ajouter des objectifs détaillés',
  "select unit":'Sélectionnez l\'unité',
  "select item":'Sélectionner les éléments',
  "votes":'Donner',
  "shares":'Partager',
  "giving items":'Émis',
  "search with giving code":'Rechercher avec le code',
  "latest giving":'Donner le dernier',
  "---Choose One---":'En sélectionner un',
  "Linked Pages":'Lien de page',
  "contact information so people can reach out when they need help":'Coordonnées afin que les gens puissent contacter en cas de besoin',
  "detail target":'Objectifs détaillés',
  "click to select":'Cliquez pour choisir',
  "not available":'Pas mettre à jour',
  "summary report":'Rapport de synthèse',
  "click here for the link":'Cliquez ici pour le lien',
  "your campaign is currently not associated with any giving campaign, click here to connect":'Actuellement, votre campagne n\'a pas été liée à la campagne, cliquez ici pour vous connecter',
  "link to giving":'Lien pour donner',
  "search with registration code":'Recherche avec le code d\'enregistrement',
  "giving chart":'Donner une carte',
  "list of giving":'Liste de donnée',
  "your registered list":'Votre liste d\'inscription',
  "indexed by wishare":'Émission indexée par Wishare',
  "recruiting volunteers":'Recruter des bénévoles',
  "type of recruitment":'Type de recrutement',
  "job requirements":'Exigences de l\'emploi',
  "time to start work":'Il est temps de commencer le travail',
  "application deadline":'Date limite de dépôt',
  "Create an organization to share and raise money for your charity":'Créez une organisation pour partager et appeler pour votre programme de charité',
  "Create activity to share your campaigns":'Créer des activités pour partager vos campagnes',
  "create donation campaign":'Créer une campagne de dons',
  "Create a donation campaign to call for support from donors":'Créer une campagne de dons pour appeler au soutien des sponsors',
  "create giving campaign":'Créer une campagne de dons',
  "Create a giving campaign to help those in need":'Créer une campagne pour aider les gens dans des circonstances difficiles',
  "create recruitment campaign":'Créer des campagnes de recrutement',
  "Create a recruitment campaign to find volunteers for the organization":'Créer une campagne de recrutement pour trouver des bénévoles pour l\'organisation',
  "Create an event to connect with other benefactors or volunteer organizations":'Créer des événements pour se connecter avec des bienfaiteurs ou d\'autres organisations bénévoles',
  "Create a channel to share information about your charity program":'Créer des canaux pour partager des informations sur votre programme de charité',
  "who can post":'Autoriser la publication',
  "everyone":'Tout le monde',
  "administrators":'Administrateurs',
  "event type":'Catégorie',
  "npo":'Non lucratif',
  "ngo":'Organisations non-gouvernementales',
  "association":'Les associations',
  "edit cd type":'Modifier le type de texte',
  "Channel members only":'Seuls les membres de la chaîne',
  "Channel admins only":'Uniquement l\'administrateur de canaux',
  "Everyone who has an account at wishare":'Toutes les personnes avec des comptes à Wishare',
  "most view":'Voir beaucoup',
  "donation types":'Type de don',
  "giving types":'Type donné',
  "occupation types":'Carrière',
  "reserved job types":'Travail dédié',
  "recruitment types":'Type de recrutement',
  "what":'Quoi ?',
  "when":'Quand ?',
  "whose":'Créé par qui?',
  "how":'Comment travailler?',
  "An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.":'Les organisations sont des groupes, des associations, une communauté ..., construite pour rassembler des ressources communautaires et sociales, mettant en œuvre des projets sociaux.',
  "Organize activities throughout, be recognized for the group\'s activities.":'Organiser par le biais d\'activités, enregistré le processus de fonctionnement du groupe.',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.":'L\'organisation est créée par des individus et peut être érigée de 1 à de nombreuses personnes ou organisations, qui peuvent être utilisées comme branche d\'une autre organisation.',
  "Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.":'Organisez l\'utilisation des outils: recrutement, événements, canaux d\'information, articles pour opérer, organiser 1 ou plusieurs activités pour organiser des projets à des fins spécifiques.',
  "Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.":'Les activités sont un projet pour créer un impact social, créé pour servir la mission spécifique, afin de créer un impact sur la société.',
  "Activities are created and maintained throughout, recorded during the operation of the project.":'L\'activité est créée et maintenue tout au long du processus de fonctionnement du projet.',
  "It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...":'Il peut s\'agir d\'un projet en tant que projet d\'une personne indépendante, mais à cause de la société, utilisant des ressources communautaires et durables, à long terme, extensibles. Les activités doivent être créées par des groupes, des associations, une communauté ...',
  "Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.":'Activités utilisant les outils: don, donnant à transparent le processus d\'opération, reconnaissant les informations de contribution pour créer un impact par la théorie du changement.',
  "Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.":'Les dons sont un événement, une campagne se déroule dans un temps limité, afin de mobiliser des ressources pour l\'exploitation.',
  "Donations are made over a limited period of time according to the scope of each phase of the project.":'Les dons ont lieu dans une période finie en fonction de la portée de chaque étape du projet.',
  "Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.":'Les dons ont été créés à partir d\'activités et associés à une campagne de dons, afin de mobiliser de manière transparente les ressources.',
  "Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.":'Giving est un événement, une campagne se déroule dans un temps limité, afin de trouver et de donner les ressources qui ont été mobilisées, créant une transparence pour le fonctionnement.',
  "Giving takes place over a finite period of time according to the scope of each phase of the project.":'Give a lieu dans une période finie en fonction de la portée de chaque étape du projet.',
  "Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.":'Donner des activités et s\'associer à une campagne de dons, afin de mobiliser de manière transparente les ressources.',
  "Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.":'Le recrutement est la fonction de la création d\'informations de recrutement, afin de mobiliser les ressources humaines pour les organisations et les activités.',
  "Recruitment activities have a timeline according to the development stage of the organization.":'Les activités de recrutement ont des délais en fonction du stade de développement de l\'organisation.',
  "Recruitment is made from Groups, Associations, Community...":'Le recrutement est fait à partir de groupes, d\'associations, de communauté ...',
  "Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.":'L\'événement est une activité de bronze visant à partager les informations sur l\'événement, reconnaissant le développement des membres et la communauté est intéressée.',
  "The event takes place over a finite period of time.":'L\'événement se déroule dans une période finie.',
  "Events are created from Groups, Guilds, Communities...":'Les événements sont créés à partir de groupes, d\'associations, de communauté ...',
  "Information channel is a function to support sharing and publishing information of social activities.":'Le canal d\'information est la fonction du partage et de la publication des informations sur les activités sociales.',
  "Information channels are maintained throughout.":'Le canal d\'information est maintenu partout.',
  "Groups, Associations, Community... can set up 1 or more channels.":'Les groupes, les associations, la communauté ... peuvent être établis 1 ou plusieurs canaux.',
  "denied":'Refuser',
  "applied for the job":'participé à la demande',
  "your donation will be sent to":'Votre contribution sera envoyée',
  "register donation":'Inscrivez-vous à la contribution',
  "alias":'Alias',
  "required information":'Information requise',
  "date of birth":'Date de naissance',
  "required fields":'Champs obligatoires',
  "Please follow these instructions to complete the donation process":'Veuillez suivre les instructions suivantes pour terminer le processus de contribution.',
  "Thank you benefactors, when transferring, remember to specify the structure of the transfer as":'Merci les donateurs, lorsque le transfert de la mémoire indique clairement le contenu de transfert en fonction de la structure est:',
  "your name - donation name":'Votre nom - Nom du programme',
  "Click the Register donate button to complete and receive the donation code":'Cliquez sur le bouton "Enregistrement de consommation" pour terminer et recevoir un code de don',
  "text":'Texte court',
  "radio":'Choisissez une réponse',
  "checkbox":'Choisissez plusieurs réponses',
  "textarea":'Texte long',
  "boolean":'Vrai faux',
  "conversion required":'Conversion obligatoire',
  "limited":'Limité',
  "complete registration":'Enregistrement complet',
  "You have just completed the registration, please follow the donation steps to complete the donation process for campaign":'Vous venez de terminer l\'inscription, veuillez effectuer les contributions pour terminer le processus de don de la campagne',
  "description donation code":'Il s\'agit de votre code de contribution pour la campagne si vous avez un compte sur Wish, vous pouvez vérifier les informations ou en "gratitude" si vous avez terminé ce code.',
  "Thank you for your contributions to make this life better":'Merci pour vos contributions d\'avoir aidé cette vie',
  "You can click the \"Back campaign\" button to return to the campaign":'Vous pouvez cliquer sur le bouton "Retour" pour revenir à la campagne',
  "back campaign":'Retour à la campagne',
  "donation input":'Entrez le don',
  "create new post":'Créer des articles',
  "post an article":'Poste',
  "you want more":'Tu veux ajouter',
  "min length 2 characters":'La longueur minimale de 2 caractères',
  "container":'Publié sur la chaîne',
  "anyone can search for and view":'Les gens peuvent rechercher et regarder',
  "only you can view":'Vous seul pouvez voir',
  "add tags":'Étiqueter',
  "hashtags":'Étiqueter',
  "job position":'Titre',
  "work time type":'Temps de travail',
  "is remote work":'Télécommande',
  "experience requirements":'Expérience des exigences',
  "min age":'Âge minimum',
  "max age":'Âge maximum',
  "min salary":'Salaire minimum',
  "max salary":'Salaire maximal',
  "per quater":'Trimestriel',
  "latest list of candidates":'Dernière liste d\'applications',
  "is yes/no question":'Des questions oui ou non ?',
  "choose number of answers":'Sélectionnez le nombre de réponses',
  "one answer":'Répondre',
  "multiple answer":'Beaucoup de réponses',
  "number of choices":'Nombre d\'options',
  "goto":'Aller',
  "add donation option":'Ajouter un package de don',
  "are you sure delete this":'Êtes-vous sûr de supprimer cela?',
  "would you like to go to the detailed article page":'Voulez-vous aller sur la page pour voir l\'article détaillé?',
  "the article was posted":'L\'article a été publié',
  "are you sure you want to delete this donation package":'Vous souhaitez vraiment supprimer ce package de contribution?',
  "delete donation package":'Supprimer le package de contribution',
  "General Categories":'Catalogue général',
  "private event":'Événement privé',
  "private event description":'Seuls vous et les membres invités pouvez participer à l\'événement',
  "private organization":'Organisation privée',
  "private organization description":'Seuls vous et les membres de l\'organisation pouvez voir et recevoir des informations de l\'organisation',
  "You have completed the steps of creating organization":'Vous avez terminé les étapes pour créer une organisation',
  "Hope your organization will be successful and help many people":'J\'espère que votre organisation réussira et aidera de nombreuses personnes',
  "Short description about your organization":'Brève description de votre organisation',
  "display name":'Afficher un nom',
  "you have chosen":'Tu as choisi',
  "transfer date":'Date de don',
  "send a thank you email":'Email Merci',
  "you donated for campaign":'Vous avez contribué à la campagne',
  "volunteer available":'Prêt à faire du bénévolat',
  "action completed":'Complété',
  "successfully created!":'Créer un succès',
  "successfully updated!":'Mise à jour réussie',
  "successfully deleted!":'Supprimer le succès',
  "search news channel":'Rechercher des chaînes d\'information',
  "input data":'Insérer',
  "add receiving option":'Ajouter un forfait de réception',
  "giving input":'Donné',
  "your giving will be sent to":'Votre don enverra à',
  "register giving":'Inscrivez-vous pour recevoir',
  "your registration will be sent to":'Votre inscription enverra',
  "Please follow these instructions to complete the giving process":'Veuillez suivre les instructions suivantes pour terminer le processus d\'inscription',
  "Your application will be sent to the program and it will take a few days to process, so please wait.":'Votre demande sera envoyée au programme et prendra quelques jours pour approbation, veuillez patienter.',
  "Click the Register giving button to complete and receive the giving code":'Cliquez sur le bouton "Gift Inscription" pour terminer et recevoir le code cadeau',
  "You have just completed register receiving gifts from the program":'Vous venez de terminer l\'enregistrement des cadeaux du programme',
  "description giving code":'Il s\'agit de votre code d\'enregistrement pour recevoir des cadeaux de la campagne si vous avez un compte sur Wishare, vous pouvez vérifier les informations ou dans "My Inscription" par ce code.',
  "successfully subscribed":'Inscrivez-vous!',
  "register to receive":'Inscrivez-vous pour recevoir',
  "you have registered to receive from":'Vous vous êtes inscrit pour recevoir des mots',
  "you received from":'Vous avez reçu le mot',
  "register to received":'Inscrivez-vous pour recevoir des cadeaux',
  "receive date":'Date de réception',
  "register received":'Inscrivez-vous pour recevoir',
  "donation package":'Package de contribution',
  "name package":'Nom du paquet',
  "giving package":'Package donné',
  "add giving option":'Ajouter un package',
  "{type} will delete will be gone forever. Are you sure?":'{Type} Supprimera disparaîtra en permanence. Es-tu sûr?',
  "successfully registered":'Inscrivez-vous!',
  "you register to receive":'Vous vous inscrivez pour recevoir',
  "km":'កម្ពុជា។',
  "fr":'Français',
  "ko":'한국인',
  "delete receiving package":'Supprimer les packages-cadeaux?',
  "are you sure you want to delete this receiving package":'Êtes-vous sûr de vouloir supprimer ce package cadeau?',
  "select categories description":'Wishare a regroupé des informations sur un catalogue pour vous aider à mieux accéder aux informations et à éviter les informations manquantes. Veuillez choisir les catégories que vous aimez ci-dessous',
  "by using Wishare, you agree to our":'En utilisant Wishare, vous êtes d\'accord avec',
  "donation event has the unfinished items":'Le don d\'événements a les inscriptions d\'enregistrement en attente d\'examen',
  "search anythings in Wishare":'Recherchez tout sur Wishare',
  "registered list":'DS s\'est inscrit',
  "giving event has the unfinished items":'Événement donné avec les montants d\'inscription en attente de révision',
  "recruitment event has the unfinished items":'Le recrutement consiste à enregistrer l\'enregistrement pour examen',
  "responsibilities":'Responsabilité',
  "no name entered":'Pas encore entré le nom',
  "search with recruitment code":'Rechercher avec le code de recrutement',
  "for admin":'Pour qtv',
  "your application will be sent to":'Votre demande sera envoyée',
  "click the Apply now button to complete and receive the apply code":'Cliquez sur le bouton "Application Now" pour terminer et recevoir le code de demande',
  "job-description":'Description de l\'emploi',
  "job-requirements":'Exigences de l\'emploi',
  "recruited":'Recruté',
  "thank you for applying for this job":'Merci d\'avoir postulé pour cet emploi.',
  "your application has been sent to the organization, please wait for a response from the organization":'Votre demande a été envoyée à l\'organisation, veuillez attendre les commentaires de l\'organisation.',
  "that may take a few days, please wait":'Cela peut prendre quelques jours, veuillez patienter.',
  "find account":'Trouver des comptes',
  "choose account":'Choisissez un compte disponible sur Wishare',
  "select receiver":'Choisissez le destinataire',
  "description recruitment code":'Il s\'agit du code d\'inscription en tant que bénévole, vous suivez souvent votre demande dans la section Volunteer.',
  "you have just completed your registration as a volunteer for the program":'Vous venez de finir de vous inscrire en tant que bénévole pour le programme',
  "applied":'S\'applique',
  "Thank you for signing up to be a volunteer of the program":'Merci de vous inscrire en tant que bénévole du programme',
  "candidate list":'Liste de candidatures',
  "are you sure you want to reject this candidate":'Vous voulez vraiment rejeter ce candidat?',
  "candidate":'Candidat',
  "currently an employee of the organization":'Actuellement membre de l\'organisation / groupe',
  "enter candidate information":'Entrez des informations sur les candidats',
  "enter information to give":'Entrez les informations fournies',
  "given by the organization":'Donné par l\'organisation',
  "enter donation information":'Entrez les informations de don',
  "edit candidate information":'Modifier les informations sur les candidats',
  "administration panel":'Lift privé pour QTV',
  "open/close recruitment":'Date limite de recrutement',
  "work type":'Former',
  "create donation event":'Créer des campagnes de collecte de fonds',
  "create giving event":'Créer une campagne de dons',
  "organization type":'Forme organisationnelle',
  "mobilization of manpower":'Mobiliser les ressources humaines',
  "communication":'Les média',
  "are you ready for volunteer work":'Êtes-vous prêt pour le bénévolat?',
  "change-password":'Changer le mot de passe',
  "configure-content-by-language":'Configurer le contenu dans la langue',
  "diamond":'diamant',
  "platinum":'Platine',
  "gold":'Jaune',
  "silver":'Argent',
  "bronze":'Cuivre',
  "basic":'Basique',
  "premium":'Haute société',
  "general operating model":'Modèle d\'exploitation général',
  "we make common rules so that everyone can understand and understand how we work":'Nous offrons une règle commune afin que tout le monde puisse clairement et comprendre comment nous travaillons.',
  "project":'Projet',
  "serving the mission aiming for social impact":'Servir la mission, visant à l\'impact social',
  "giving gifts to difficult circumstances":'Offrez des cadeaux aux circonstances défavorisées',
  "motto and how it works":'Devise et mode de fonctionnement',
  "we aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission":'Nous visons le lien entre les bienfaiteurs, les circonstances défavorisées, les organisations bénévoles, qui effectuent une mission significative.',
  "connect social activities":'Connecter les activités sociales',
  "we create links between charities and those in need":'Nous créons un lien entre les organisations bénévoles et les circonstances difficiles qui ont besoin d\'aide.',
  "support groups, organizations":'Associations de soutien, groupes et organisations',
  "we provide support tools for associations, groups, organizations to be able to carry out their volunteering missions":'Nous fournissons des outils de support pour les associations, les groupes, les organisations, etc. pour mener à bien notre mission de bénévolat.',
  "Transparency - Sustainability - Impact":'Transparence - durabilité - création d\'impact',
  "this is our operating principle, every donation or donation is clear and transparent":'Il s\'agit de notre principe d\'activité, de toutes les contributions ou étant le plus clair et le plus transparent.',
  "beginer":'Commencer',
  "intermediate":'Intermédiaire',
  "proficient":'Professionnel',
  "on working":'Fonctionnement',
  "not graduated":'Pas diplômé',
  "not anymore":'Pas plus',
  "review the application form":'Examiner la demande de demande',
  "application form":'Demande de demande',
  "recruitment agency":'Unité de recrutement',
  "cancel application":'Annuler la demande',
  "do you want to change your personal information":'Voulez-vous changer vos informations personnelles?',
  "candidate profile":'Profil du candidat',
  "you want to see candidate information":'Vous souhaitez voir des informations sur les candidats',
  "human resources":'Ressources humaines',
  "Transparency & Sustainability":'Transparence et durabilité',
  "record project activities with the data of fundraising campaigns, giving":'Enregistrez les activités du projet avec les données des campagnes de collecte de fonds',
  "projects":'Projet',
  "how it works":'Comment opérer',
  "information collecting":'Collecter des informations',
  "candidates":'Liste des candidats',
  "you can not select than 2 languages":'Vous ne pouvez pas choisir plus de 2 langues',
  "Sharing and connecting between members in the community of social activities.":'Partagez et connectez-vous entre les composants de la communauté des activités sociales.',
  "Support social units in the work of carrying out their mission":'Soutenir les unités sociales dans le travail de mise en œuvre de leur mission',
  "Creating transparency and sustainability in social activities":'Créer la transparence et la durabilité dans les activités sociales',
  "are you sure that you want to cancel this":'Es-tu sûre de vouloir annuler?',
  "edit a poll":'Édition d\'exploration',
  "Change folder name":'Changer le nom du dossier',
  "Do you want delete this folder":'Vous souhaitez supprimer ce dossier?',
  "This will permanently delete it, but its items will still be in your saved list.":'Cela l\'effacera pour toujours, mais ses articles seront toujours sur votre liste enregistrée.',
  "export donation data":'Contribution des données d\'exportation',
  "export as PDF":'Exporter le fichier PDF',
  "export giving data":'Exporter les données données',
  "record and display the converted value":'Enregistrer et afficher la valeur de conversion',
  "with target":'Avec cible',
  "approved list":'DS a été approuvé',
  "rejected list":'DS a refusé',
  "export":'Exporter des données',
  "time sheets":'Temps de travail',
  "view timesheet":'Voir le temps de travail',
  "other project":'Autres projets',
  "select project":'Sélectionner le projet',
  "For example: ABC Campaign, ABC Organization,...":'Par exemple: ABC Campaign, ABC Organisation, ...',
  "edit timesheet":'Modifier le temps de travail',
  "member information":'information des membres',
  "delete this information":'Supprimer ces informations?',
  "are you sure you want to delete this working information":'Vous souhaitez vraiment supprimer ces informations?',
  "my profile":'mon profil',
  "you are exporting campaign data":'Vous exportez les données de la campagne',
  "package donation":'Package de contribution',
  "package giving":'Boite cadeau',
  "do you want to leave":'Tu veux partir?',
  "you will no longer be a member of this organization or activity":'Vous ne serez plus membre de cette organisation ou de cette activité. Es-tu sûr?',
  "the {type} has been deleted":'{type} a été supprimé',
  "features group":'Groupe de fonctionnalités',
  "beneficiary management":'Gestion de l\'administration',
  "beneficiary-management":'Gestion de l\'administration',
  "on moving":'En mouvement',
  "go to activity":'Aller à l\'exploitation',
  "transfer beneficiary":'Transfert des unités bénéficiaires',
  "note approve beneficiary":'Lorsque vous transférez le bénéficiaire, vous n\'aurez plus accès à la gestion de ce bénéficiaire. Et cette action ne peut pas être retournée.',
  "cancel transfer":'Annuler le mouvement',
  "insights":'Statistique',
  "recent campaigns":'Campagne récente',
  "recent activity":'Activités récentes',
  "latest apply":'Dernière application',
  "latest register giving":'Inscrivez-vous au dernier',
  "latest register donation":'Enregistrement de la dernière contribution',
  "latest followed user":'Les derniers abonnés suivent',
  "latest post":'nouveau poste',
  "campaign":'Campagne',
  "top donation campaign":'Campagne de la première campagne',
  "top giving campaign":'Les meilleures campagnes données',
  "total giving":'Total donné',
  "top article":'Top Article',
  "top posts":'Poste supérieur',
  "total votes":'Likes totaux',
  "referral information":'INTRODUCTION INFORMATIONS UNIT',
  "existing":'Disponible',
  "beneficiary referrals":'Présentateur',
  "beneficiary-referrals":'Présentateur',
  "followed":'suivi',
  "total views":'Vues totales',
  "total comments":'Commentaires totaux',
  "total follows":'Suivi total -up',
  "today":'Aujourd\'hui',
  "yesterday":'Hier',
  "last 7 days":'il y a 7 jours',
  "this month":'Ce mois-ci',
  "last month":'Le mois dernier',
  "latest donations":'Dernière contribution',
  "to the campaign":'à la campagne',
  "part given from":'partie',
  "introduced by":'introduit par',
  "history of receiving and giving":'Histoire de donner',
  "account link":'Lien de compte',
  "activity link":'Lien d\'activité',
  "detail information":'Détails',
  "link to donation":'Lien vers le don',
  "donation/giving":'Contribuer / donner',
  "interactive":'Interagir',
  "accept transferring":'Accepter le transfert',
  "reject transferring":'Refuser de transférer',
  "was a member of":'Déjà membre',
  "select an existing profile":'Choisissez des informations reçues sur le système',
  "latest articles":'nouveau poste',
  "date desc":'Ces derniers jours',
  "name desc":'Dans l\'ordre z -> A',
  "value desc":'La valeur diminue',
  "date asc":'La journée la plus ancienne',
  "name asc":'Dans l\'ordre a -> z',
  "value asc":'La valeur augmente',
  "are you sure delete this message":'Vous voulez vraiment supprimer ce message?',
  "related units": 'Unité pertinente',
  "benefactor":'Donateurs',
  "user note":'Notes d\'utilisateur',
  "admin note":'Remarque de QTV',
  "filename":'Nom de fichier',
  "size":'Taille',
  "responding":'Réponse',
  "register_to_donate":'Inscrivez-vous à un don',
  "attached image":'Attacher des images',
  "attached file":'Joindre un fichier',
  "file":'Déposer',
  "money transferred":'Argent transféré',
  "total donated amount":'Contribution totale',
  "description qr-code":'Veuillez vous inscrire pour contribuer à la table ou vous inscrire directement sur le lien (ouvert par code QR suivant)',
  "note (administrator)":'Notes (pour les administrateurs)',
  "note (register)":'Notes (pour les abonnés)',
  "value display is not allowed":'Masquer la valeur du don',
  "hidden value":'Valeur de masque',
  "or you can copy the link of the page here to send it to your friends in other ways.":'Ou vous pouvez copier le lien de la page ici pour envoyer à vos amis d\'autres manières.',
  "slide show (live)":'Contribution (en direct)',
  "thanks for your donation":'Merci, bienfaiteurs',
  "an anonymous donor":'Sponsor anonyme',
  "total amount contributed up to now":'Le montant total a contribué au présent',
  "thank you benefactors":'Merci, bienfaiteurs',
  "donated amount":'Quantité de contribution',
  "activity list":'Liste des activités',
  "count desc":'Organiser',
  "campaign list":'Liste de campagnes',
  "event list":'Liste des événements',
  "email history thank you":'Histoire des lettres remerciées',
  "donors":'Donateurs',
  "recent donations":'Contribution récente',
  "top donations":'Contribution supérieure',
  "total donation events":'Le nombre total de dons',
  "total donation value":'Valeur totale du don',
  "total donors":'Nombre total de sponsors',
  "top donation count":'Nombre supérieur de contributions',
  "top donation value":'Contribution de valeur maximale',
  "donor list":'Liste des sponsors',
  "merge":'Unifier',
  "top donors":'Meilleurs sponsors',
  "the system sent a thank you letter to":'Le système a envoyé une lettre de remerciement à',
  "for the campaign donation":'Parce que le don de la campagne',
  "email thank you":'Merci note',
  "create donor":'Créer des sponsors',
  "edit donor":'Surveillance',
  "recent givings":'Donner récemment',
  "edit beneficiary":'Modifier l\'unité bénéficiaire',
  "account not linked yet":'Pas des comptes liés',
  "account not linked yet des":'Vous n\'avez toujours pas lié au compte Wishare, cliquez ici pour lier.',
  "merge donor":'Consolidation des sponsors',
  "selected donor":'Le sponsor a choisi',
  "merge with":'Se tourne et se combinera en',
  "beneficiary share":'Présentation des activités',
  "total giving events":'Campagne totale pour donner',
  "total giving value":'Valeur totale de la campagne',
  "total beneficiaries":'Nombre total d\'avantages',
  "merge beneficiary":'Consolidation des avantages',
  "staffs list":'Liste des membres',
  "recent recruitments":'Recrutement récent',
  "staff roster":'Organiser des travaux',
  "staff":'Membre',
  "collaborator":'Collaborateurs',
  "intern":'Interne',
  "employee type":'Type de membre',
  "edit staff":'Modifier les informations d\'adhésion',
  "create staff":'Créer de nouvelles informations sur les membres',
  "display with anonymous":'Show avec sponsor anonyme',
  "create staff roster":'Nouvelle mise en page du travail',
  "position placeholder":'Exemple: emplacement du support',
  "activity/organization":'Opération / organisation',
  "total staffs":'Nombre total de membres',
  "total recruitment event":'Recrutement total',
  "total user register":'Nombre total d\'utilisateurs enregistrés',
  "edit staff roster":'Modifier la disposition du travail',
  "staff information":'information des membres',
  "create timesheets":'Ajouter un nouveau chronomage',
  "edit timesheets":'Chronomètre',
  "total time work":'Temps de travail total',
  "top staff area":'Top région et membres',
  "top staff time work":'Contribution de temps supérieur',
  "total hours":'heures totales',
  "address placeholder":'Exemple: n ° 10 Street 10',
  "introduce to":'Introduction à',
  "find donor":'Rechercher des sponsors',
  "alias name placeholder":'Par exemple: nguyen van abc',
  "country placeholder":'Exemple: Vietnam',
  "phone placeholder":'Par exemple: 0987654321',
  "email placeholder":'Par exemple: thudientu@email.com',
  "choose date placeholder":'Exemple: 01/01/2021',
  "top giving value":'Valeur supérieure donnée',
  "top giving count":'Nombre supérieur de dons',
  "you choose channel":'Vous choisissez la chaîne',
  "channel post":'Postes de canaux',
  "total location":'Nombre total de zones',
  "Click here to search for benefactors":'Cliquez ici pour rechercher des sponsors',
  "You can find a benefactor or add another benefactor":'Vous pouvez trouver un sponsor ou ajouter un autre sponsor',
  "Click here to create a new one":'(Cliquez ici pour créer nouveau)',
  "new donor":'Nouveau sponsor',
  "referral list":'Liste des unités d\'introduction',
  "find referral":'Rechercher des unités d\'introduction',
  "not added yet":'N\'a pas ajouté',
  "merge note description":'Cette action ne peut pas être retournée, vous devez considérer attentivement avant de poursuivre cette action.',
  "find beneficiary":'Rechercher des avantages',
  "share note description":'Cette action partagera les droits de gestion avec d\'autres unités, vous êtes sûr.',
  "receiver name":'Nom du destinataire',
  "register for":'Inscription à',
  "Click here to find referrer information":'Cliquez ici pour trouver des informations sur l\'unité d\'introduction',
  "You can find a referrer or add a new one":'Vous pouvez trouver l\'unité d\'introduction ou ajouter une autre unité d\'introduction',
  "new beneficiary":'Nouvelle unité de bénéficiaire',
  "Click here to find beneficiary information":'Cliquez ici pour trouver les informations de l\'unité bénéficiaire',
  "You can find a beneficiary or add another beneficiary":'Vous pouvez trouver l\'unité bénéficiaire ou ajouter une autre unité bénéficiaire',
  "Click here to find an account":'Cliquez ici pour trouver des comptes',
  "You can find the user\'s account and add":'Vous pouvez trouver le compte de l\'utilisateur et l\'ajouter',
  "recent candidates":'Demande récente',
  "recruitment list":'Liste de recrutement',
  "donation count":'Nombre de contributions',
  "donation insights":'Statistiques de contribution',
  "list of recipients":'Donner la liste',
  "giving insights":'Les statistiques reçoivent des dons',
  "share beneficiary":'Partager les avantages',
  "to shared":'À partager',
  "this page is for organizations only create an organization":'Cette page est uniquement pour les organisations pour créer une organisation',
  "Sign in for free to experience the features":'Connexion gratuite pour expérimenter les fonctionnalités',
  "Keep personalized tracking in the field of volunteering":'Rétention de la personnalisation de la surveillance dans le domaine de la charité.',
  "Join and update information from the community":'Rejoignez et mettez à jour les informations de la communauté.',
  "Keep track of contributions, participating activities":'Suivre les contributions, les activités participantes.',
  "Find suggestions for suitable volunteer work":'Trouvez des suggestions pour un travail bénévole approprié.',
  "create account":'Créer un compte',
  "Create an account for your organization or charity ":'Créez un compte pour votre organisation et votre activité bénévole.',
  "The platform supports a free tool to help transform the number of philanthropic activities":'La plate-forme de support d\'outils gratuite aide à convertir les activités de bénévolat.',
  "Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization":'Interaction des fonctionnalités avec les partenaires: collecte de fonds, recrutement, don, événements, rapports sur l\'impact des activités et des organisations.',
  "Organizational management features: manage donors, beneficiaries, human resources":'Caractéristiques de la gestion organisationnelle: fils, bénéficiaires, ressources humaines.',
  "extra information":'Plus d\'information',
  "value placeholder":'Exemple: ABC ...',
  "datetime placeholder":'Par exemple: 1er janvier 2001',
  "number placeholder":'Par exemple: 012345 ...',
  "field name":'Entrez le nom',
  "year of birth":'Année de naissance',
  "remember me":'Rappelez-vous le mot de passe',
  "it received":'Reçu',
  "clone item":'Créer des copies',
  "clone beneficiary":'Bénéfice des ressources humaines',
  "clone note description":'Avec cette action, vous pouvez modifier les informations du bénéficiaire mais n\'affecte pas les informations d\'autres organisations.',
  "this year":'L\'année en cours',
  "last year":'L\'année dernière',
  "last 3 years":'3 ans jusqu\'à présent',
  "showing":'Afficher',
  "Donate to the campaign by cash or bank transfer":'Contribuer à la campagne en espèces ou en transfert bancaire',
  "Donate to the campaign with items":'Contribuer à la campagne en nature',
  "Contributions in kind can be converted to the corresponding value at the time of donation":'La contribution en nature peut être convertie en valeur correspondante au moment de la contribution',
  "Giving in kind can be converted into equivalent value at the time of giving":'Donné dans les artefacts peut être converti en valeur équivalente au moment de donner',
  "Give to beneficiary by cash or bank transfer":'Donné à l\'unité bénéficiaire en espèces ou en transfert bancaire',
  "Give the beneficiary with the items":'Donné à l\'unité bénéficiaire en nature',
  "reconnect":'Reconnecter',
  "network":'Réseau',
  "network offline":'Réseau déconnecté',
  "list of co-hosting":'Liste du gestionnaire',
  "list of co-owners":'Liste des membres',
  "co-hosting":'Co-organisation',
  "do you want to leave the position of host of this campaign":'Voulez-vous quitter la position de cette campagne?',
  "co-owners":'Membre',
  "refused":'Refusé',
  "do you want to decline the invitation":'Voulez-vous refuser l\'invitation?',
  "host type":'Type de gestionnaire',
  "owner type":'Type de membre',
  "staff list":'Liste des membres',
  "related activities":'Travail',
  "related giving events":'Faire campagne',
  "related donation events":'Campagne de campagne',
  "related recruitments": 'Campagne de recrutement',
  "related events":'Événement',
  "peer organization":'Pair',
  "peer organization list":'Liste des unités',
  "total peer organization":'Unité totale de pair -to-peer',
  "has left":'a laissé',
  "total hosts":'Nombre total de gestionnaire',
  "total members":'Nombre total de membres',
  "my host request":'Unité membre',
  "my-host-request":'Unité membre',
  "list of members":'Unité membre',
  "activity of co-owners":'Activités des membres',
  "medals":'Médaille',
  "medal list":'Liste des médailles',
  "medal manager":'Gestion des souvenirs',
  "medal summary":'Résumé des médailles',
  "create medal":'Créer une médaille',
  "edit medal":'Édition de médailles',
  "total staff-rosters":'Nombre total d\'emplois',
  "type desc":'Diminuer',
  "type asc":'Augmentation de type',
  "medal awards":'Médailles de récompense',
  "medal rewards":'La médaille a reçu',
  "medal reward list":'La liste a été reçue',
  "give medal":'Médailles de récompense',
  "effect date":'Date',
  "medal award manager":'La médaille a reçu',
  "pending givings":'Inscrivez-vous pour le recevoir',
  "organizational structure":'Structure organisationnelle',
  "operating":'Fonctionner',
  "activities of member":'Activités des membres',
  "list of medal":'Liste des médailles',
  "level placeholder":'Par exemple: 1- Kim Diamond, 2-or, 3-Bat, ...',
  "staffs":'Personnel',
  "activity summaries":'Résumé de l\'exploitation',
  "donation summaries":'Résumé du don',
  "givings summaries":'Résumé des dons',
  "staffs summaries":'Résumé du personnel',
  "recruitment events":'Recrutement',
  "organization unit reports":'Rapport de l\'organisation',
  "{value1} / {value2}: Number of organizations / total number of member organizations":'{Value1} / {Value2}: le nombre de l\'organisation / nombre total d\'organisations membres',
  "contributions":'Contribuer',
  "waiting value":'Valeur nécessaire',
  "giving count":'Tourner',
  "spent my precious time at work":'a passé son précieux temps pour le travail',
  "We sincerely appreciate your dedication of time and effort to making this life better":'Nous sommes sincères gratitude à votre dévouement et à votre effort pour améliorer cette vie.',
  "We would like to award this medal to mark your contribution":'Nous aimerions décerner cette médaille pour marquer votre contribution.',
  "Wishing you always full of health, peace and enthusiasm. Sincerely thank you.":'Nous vous souhaitons toujours plein de santé, de paix et d\'enthousiasme. \ ntran merci.',
  "staff received":'Les membres ont reçu',
  "donor received":'Le sponsor a reçu',
  "register list":'Liste d\'inscription',
  "ML (blood unit)":'ML (unité sanguine)',
  "choose organization":'Choisir l\'organisation',
  "total events":'Nombre total d\'événements',
  "total activities":'Nombre total d\'activités',
  "total donations":'Nombre total de contributions',
  "total waiting value":'Valeur d\'enregistrement total',
  "total givings value":'Valeur totale donnée',
  "total givings target":'Cible totale donnée',
  "total givings":'Nombre total de fois donné',
  "total collaborator":'Nombre total de collaborateurs',
  "total intern":'Le nombre total de stagiaires',
  "total staff":'Nombre total de membres',
  "total volunteer":'Le nombre total de bénévoles',
  "history medal rewards":'Médailles du prix d\'histoire',
  "list of staff roster":'Liste de travaux',
  "donation of organization":'Contribution de l\'organisation',
  "giving of organization":'Récompensé par l\'organisation',
  "certifications":'Certifier',
  "price (VND)":'Prix ​​(VND)',
  "access rights":'Accès',
  "contact list":'Liste de contacts',
  "what is your organization name?":'Quel nom est votre organisation?',
  "sponsor":'Parrainer',
  "disable on the personal page":'Caché sur la page personnelle',
  "disable donor on page description":'Cette action masquera les informations liées à votre financement sur votre page personnelle',
  "disable beneficiary on page description":'Cette action masquera les informations liées à votre plaisir sur votre page personnelle',
  "disable work on page description":'Cette action masquera les informations liées à votre travail sur votre page personnelle',
  "import donation data":'Entrez les données de contribution',
  "import":'Insérer',
  "you are importing campaign data":'Vous entrez des données pour la campagne',
  "preview template":'Voir l\'échantillon',
  "limit description":'La capacité de fichier ne dépasse pas',
  "template data":'Échantillons de données',
  "notes description":'Expliquez les colonnes dans l\'exemple de fichier',
  "campaign id code":'Code d\'identification de la campagne',
  "contributed date":'Date de contribution',
  "donor\'s name":'Contribution du nom de la maison',
  "donor\'s owner id":'L\'idée du sponsor',
  "donation quantity":'Nombre de contributions',
  "donation unit":'Unité de contribution',
  "exchange value":'Valeur d\'échange',
  "donation note":'Notes de contribution',
  "additional fields for information":'Écoles supplémentaires pour l\'information',
  "beneficiary\'s name":'Le nom de l\'unité bénéficiaire',
  "beneficiary address":'Adresse du bénéficiaire',
  "beneficiary\'s owner id":'Propriétaires de bénéficiaires',
  "giving quantity":'Quantité donnée',
  "giving unit":'L\'unité le donne',
  "giving note":'Donner des notes',
  "import giving data":'Entrez les données données',
  "Extract via campaign url path":'Extraire via URL de campagne',
  "For example":'\ n Exemple',
  "Date is required":'Informations sur la date obligatoire',
  "Including 4 characters, capitalization, unique":'Y compris 4 caractères, capitalisation, unique',
  "Value must be YES or NO":'La valeur doit être oui ou non',
  "Value must be money, goods or blood":'La valeur doit être "de l\'argent", des "marchandises" ou du "sang"',
  "Number is required":'Informations numériques nécessaires pour entrer',
  "Value must be male, female or null":'La valeur doit être "masculin", "féminine" ou une valeur vide',
  "current size":'La taille actuelle',
  "max size":'Taille maximum',
  "total row":'Nombre total de lignes',
  "success row":'Nombre de lignes réussies',
  "row":'actuel',
  "upload file":'Insérer',
  "question from the campaign":'Questions de la campagne',
  "START":'Commencer',
  "FINISH":'Fin',
  "node":'Nœud',
  "send a confirmation letter for the donation":'Envoyer une lettre de confirmation pour contribution',
  "link page":'Lien de page',
  "list of partners":'Liste de partenaires',
  "short name":'Nom court',
  "first name":'Nom de famille',
  "last name":'Nom',
  "alternative email":'E-mail (extra)',
  "create partners":'Créer des partenaires',
  "edit partners":'Modifier les partenaires',
  "delete partner":'Supprimer les partenaires',
  "are you sure you want to delete this partner":'Vous voulez vraiment supprimer ce partenaire?',
  "total org members":'Membre',
  "total org partners":'Partenaire',
  "total org link":'Lien',
  "Volunteer":'Bénévoles',
  "Administrator":'Administrateurs',
  "Office staff":'Le personnel de bureau',
  "Part-time employee":'À temps partiel',
  "Assistant":'Assistant',
  "Director":'Directeur',
  "Supporter":'Soutien',
  "total other activities":'Nombre total d\'activités membres',
  "total active donation events":'La campagne de collecte de fonds a lieu',
  "total active giving events":'La campagne de dons a lieu',
  "total recruitments":'Nombre total de recrutement',
  "total event incoming":'Le nombre total d\'événements est sur le point d\'avoir lieu',
  "input partner name":'Entrez le nom du partenaire',
  "select account wishare":'Sélectionnez le compte Wishare',
  "total active recruitments events": 'Le recrutement a lieu',
  "subcategories organization": 'Sous-traitant de l\'organisation',
  "create subcategories organization": 'Créer une nouvelle sous-traitance pour l\'organisation',
  "update subcategories organization": 'Mettez à jour la catégorie supplémentaire de l\'organisation',
  "subcategories activity": 'Catalogue supplémentaire d\'activité',
  "create subcategories activity": 'Créer de nouvelles listes supplémentaires pour les activités',
  "update subcategories activity": 'Mettre à jour les catégories supplémentaires pour les activités',
  "subcategories": 'Catégorie supplémentaire',
  "remove this": 'Supprimer cette section?',
  "list of documents": 'Liste des documents',
  "list of channels": 'Liste des chaînes d\'information',
  "introduce channel": 'Présentation des chaînes d\'information',
  "short description about your channel": 'Brève description de votre chaîne d\'information',
  "share discussion": 'Partage de discussion',
  "choose category": 'Sélectionnez la catégorie',
  "parent of subcategory": 'Catégorie du père',
  "hours": 'heure',
  "choose another Wishare subscription": 'Sélectionnez un autre ensemble d\'inscription à Wishare',
  "filter conditions by subcategory": 'Les conditions de filtre en fonction de la classification',
  "value to contribute": 'Valeur pour contribuer',
  "can giving": 'Peut être donné',
}