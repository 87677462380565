import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import { chartSchema } from './chart'
import {
  activitySchema,
  articleSchema,
  donationEventSchema,
  givingEventSchema,
  organizationSchema,
  pollSchema,
  postSchema,
  recruitmentEventSchema,
  beneficiarySchema,
} from './schema'
import { commentSchema } from './schema/commentSchema'
import { createTransform } from './utils/createTransform'

const voteSchema = new schema.Union(
  {
    beneficiary: beneficiarySchema,
    post: postSchema,
    poll: pollSchema,
    comment: commentSchema,
    article: articleSchema,
    chart: chartSchema,
    organization: organizationSchema,
    activity: activitySchema,
    giving_event: givingEventSchema,
    donation_event: donationEventSchema,
    recruitment_event:
      recruitmentEventSchema,
  },
  '_type'
)

const fields = [
  'owner_type',
  'owner_id',
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: 'post',
}

// Route::post('auth/vote/up', 'VoteController@add');
export const vote_add_Api = {
  backend: `Route::post('auth/vote/up', 'VoteController@add');`,
  root: API_ROOT_URL,
  path: '/auth/vote/up',
  method: 'POST',
  fields,
  fieldTypes,
  schema: voteSchema,
  transform: createTransform(
    voteSchema,
    'data'
  ),
}

// Route::post('auth/vote/down', 'VoteController@remove');
export const vote_remove_Api = {
  backend: `// Route::post('auth/vote/down', 'VoteController@remove');`,
  root: API_ROOT_URL,
  path: '/auth/vote/down',
  method: 'POST',
  fields,
  fieldTypes,
  schema: voteSchema,
  transform: createTransform(
    voteSchema,
    'data'
  ),
}

export const vote_voteUp_Api = {
  backend: `Route::post('auth/vote/count-up', 'VoteController@voteUp');`,
  root: API_ROOT_URL,
  path: '/auth/vote/count-up',
  method: 'POST',
  fields,
  fieldTypes,
  schema: voteSchema,
  transform: createTransform(
    voteSchema,
    'data'
  ),
}

export const vote_voteDown_Api = {
  backend: `Route::post('auth/vote/count-down', 'VoteController@voteDown');`,
  root: API_ROOT_URL,
  path: '/auth/vote/count-down',
  method: 'POST',
  fields,
  fieldTypes,
  schema: voteSchema,
  transform: createTransform(
    voteSchema,
    'data'
  ),
}
