import { Avatar, Image } from 'antd'
import { getType } from 'apis/model/base'
import { ROOT_URL } from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import BeneficiaryContext from '../BeneficiaryContext'

export const BeneficiaryBanner = ({
  beneficiary,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  return (
    <Wrapper className="flex flex-col">
      <Image
        preview={false}
        src={`${ROOT_URL}/images/default/pink-bg.jpg`}
        className="fadeIn animated banner-activity object-cover bg-color-org"
      />
      <div className="absolute flex items-center w-full mx-auto">
        <div
          style={{
            background:
              'radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
          }}
          className="flex flex-col items-center justify-center w-full px-3 banner-activity">
          <Avatar
              className="flex flex-center background-100"
            size={50}
            src={getAvatar(beneficiary)}
            alt={getTitle(beneficiary)}>
               <span className="text-color-200 font-medium">
                    {_.first(
                      getTitle(beneficiary)
                    )}
               </span>
          </Avatar>
          <div className="px-3 text-xl md:text-3xl font-bold max-w-4xl text-center text-white my-0 md:my-3 leading-tight">
            {getTitle(beneficiary)}
          </div>
          <span className="text-white italic font-medium text-xs tracking-wide">
            {t(getType(beneficiary))}
          </span>
        </div>
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    BeneficiaryContext.Consumer,
    ({ beneficiary }) => ({
      beneficiary,
    })
  )
)(BeneficiaryBanner)
