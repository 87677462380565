import {
  Button,
  Descriptions,
} from 'antd'
import { activity_create_api } from 'apis'
import { organizationSchema } from 'apis/schema'
import FieldsFactory from 'components/form/FieldsFactory'
import getTitle from 'helpers/getTitle'
import { useQueryString } from 'hooks'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useLocation } from 'react-use'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import {
  getResponseItem,
  notEmpty,
  Null,
  renderOwnChild,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { WishareEntities } from '../enums'
import { notifyOnError } from '../factory/createErrorEvent'
import createInitialValues from '../factory/createInitialValues'
import {
  NotificationActionTypes,
  successNotify,
} from '../factory/createNotification'
import createStepForm, {
  StepActionTypes,
  StepFormContext,
} from '../factory/createStepForm'
import createValidationSchema from '../factory/createValidationSchema'
import { FlexCol } from '../Templates/ItemTemplate'
import wishareModel from '../wishareModel'
import { activityStepFormSchema } from './activitySchemas'

const ImpCateStage = ({
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()
  const { currentStep } = useContext(
    StepFormContext
  )

  if (_.isEmpty(currentStep))
    return null

  const stage = getTitle(currentStep)

  const { formSchema } = currentStep

  return (
    <Wrapper>
      <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
        {t(stage)}{' '}
      </div>
      <FieldsFactory
        formSchema={formSchema}
      />
    </Wrapper>
  )
}

const OwnerStage = ({
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()
  const { currentStep } = useContext(
    StepFormContext
  )

  if (_.isEmpty(currentStep))
    return null

  const stage = getTitle(currentStep)

  const { formSchema } = currentStep

  return (
    <Wrapper>
      <FieldsFactory
        formSchema={formSchema}
      />
    </Wrapper>
  )
}

const SummaryStage = React.memo(() => {
  const t = useTranslate()

  const {
    history,
    handleGoBack = Null,
  } = useContext(NavigationContext)

  const { item } = useContext(
    StepFormContext
  )

  const redirect = () => {
    if (_.isEmpty(item)) {
      handleGoBack()
    } else {
      history.push(
        wishareModel.getLinkToSetting(
          item
        )
      )
    }
  }

  return (
    <FlexCol className="space-y-6 items-center justify-center px-8">
      <div className="font-bold text-3xl text-green-500 uppercase">
        {t('congratulation')}
      </div>

      <span className="font-medium text-lg text-primary">
        {t(
          'You have completed the steps of creating activity'
        )}
      </span>

      <span className="text-lg text-secondary">
        {t(
          'Hope your activity will be successful and help many people'
        )}
      </span>

      <Descriptions>
        <Descriptions.Item
          label={
            <span className="font-semibold">
              {t('note')}
            </span>
          }>
          {t('note create description')}
        </Descriptions.Item>
      </Descriptions>

      <Button
        type="primary"
        className="no-shadow no-text-shadow border-none rounded-lg"
        onClick={redirect}>
        {t('go to setting')}
      </Button>
    </FlexCol>
  )
})

const stages = [
  {
    index: 0,
    name: 'impact-categories',
    title: 'target',
    component: ImpCateStage,
    formSchema:
      activityStepFormSchema.impactStage,
    actions: [
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
        requireds: [
          'impact_categories',
        ],
      },
    ],
  },
  {
    index: 1,
    name: 'owner',
    title: 'owner',
    component: OwnerStage,
    formSchema:
      activityStepFormSchema.ownerStage,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        label: 'create',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
      },
    ],
  },
  {
    index: 2,
    name: 'summary',
    title: 'summary',
    finished: true,
    component: SummaryStage,
  },
]

const CreateActivity = () => {
  const translate = useTranslate()

  const location = useLocation()

  const [{ owner_id, owner_type }] =
    useQueryString(location.pathname)

  const default_owner =
    useSelectEntities(
      owner_id,
      organizationSchema,
      {}
    )

  const initialValues = useMemo(
    () =>
      createInitialValues(
        WishareEntities.ACTIVITY,
        CRUD.CREATE,
        {
          default_owner,
          owner: default_owner,
          owner_id,
          owner_type,
        }
      ),
    [
      owner_id,
      owner_type,
      default_owner,
    ]
  )

  const validationSchema = useMemo(
    () =>
      createValidationSchema(
        WishareEntities.ACTIVITY,
        CRUD.CREATE
      )(translate),
    [translate]
  )

  const renderHeader = useCallback(
    () => (
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl my-2">
        <center>
          {translate('create activity')}
        </center>
      </div>
    ),
    [translate]
  )

  const onPreSubmit = ({
    owner,
    default_owner,
    ...values
  }) => {
    const impact_categories =
      Array.from(
        _.get(
          values,
          'impact_categories',
          []
        )
      ).join(',')
    return {
      ..._.omit(
        {
          ...values,
          impact_categories,
        },
        [
          _.isEmpty(
            impact_categories
          ) && 'impact_categories',
        ].filter(notEmpty)
      ),
    }
  }

  const onSuccess = (
    __,
    { response },
    forward = Null
  ) => {
    successNotify(
      NotificationActionTypes.CREATE,
      translate
    )
    forward(getResponseItem(response))
  }

  const content = createStepForm(
    {
      onSuccess,
      onError: notifyOnError(translate),
      apiInfo: activity_create_api,
    },
    {
      onPreSubmit,
      steps: stages,
      initialValues,
      validationSchema,
      Header: renderHeader,
    }
  )

  return (
    <FlexCol>
      <div className="h-8" />
      {content}
    </FlexCol>
  )
}

export default CreateActivity
