import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const DiscoveryExplorerLazy =
  createLazyComponent(() =>
    import('./DiscoveryExplorer')
  )

const DiscoveryExplorer = (props) => (
  <DiscoveryExplorerLazy {...props} />
)

export default DiscoveryExplorer
