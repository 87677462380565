import { BellOutlined } from '@ant-design/icons'
import { Avatar, Button } from 'antd'
import { user_getFollowersByUser_Api } from 'apis'
import ContentContainer from 'components/ContentContainer'
import ImageLoader from 'components/ImageLoader'
import Modal from 'components/Modal/Modal'
import Toggle from 'components/Toggle'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import { createNumberFormater } from 'helpers/number'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { ReactReduxContext } from 'react-redux'
import { Link } from 'react-router-dom'
import { getLogin } from 'redux/selectors'
import {UseFollow} from '../Server/ServerContainer'
import {IoPerson} from "react-icons/io5";
import classnames from "classnames";
import ServerContext from "../Server/ServerContext";
import {LayoutContext} from "../../components/layouts/Default/LayoutContext";

export const PageDetailHeader = ({
  follow_status,
  item,
  onFollow,
  avatar,
  cover,
  title,
  subTitle,
  id,
}) => {
  const t = useTranslate()
  const { store } = useContext(
    ReactReduxContext
  )
  const login = getLogin(
    store.getState()
  )
  const isOwner =
    login &&
    login.username === item.username

    const {isSm} = useContext(LayoutContext)

  return (
    <div
      style={{
        position: 'relative',
        height: '300px',
      }}
      className="background mb-4">
      {!isOwner && (
        <Button
          icon={<BellOutlined />}
          className="font-semibold z-10 absolute mt-3 mr-3 top-0 right-0"
          shape="round"
          ghost={!!follow_status}
          onClick={onFollow}
          type="primary">
          {!!follow_status
            ? t('following')
            : t('follow')}
        </Button>
      )}
      <div className="custom-image-overview">
          <ImageLoader
              style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
              }}
              src={cover}
              className="rounded-b-lg"
          />
      </div>
      <div
        style={{
          bottom: 10,
          width: '100%',
          padding: isSm ? '0.5em' : 0,
            position: 'absolute'
        }}>
        <div className="flex flex-col gap-2 flex-center">
          <Avatar
            className="flex flex-center background-100 shadow-lg"
            size={80}
            icon={<IoPerson className="text-color-300" />}
            src={avatar}>
            {id}
          </Avatar>
          <div className="flex justify-center w-full">
            <div className="w-10 h-10 flex-shrink-0 hidden md:flex" />
            <div className="flex flex-col flex-center">
              <Link
                to={getLinkToDetail(item)}
                className="text-center no-underline font-bold text-xl text-color-000 leading-tight">
                {title}
              </Link>
              <div className="font-light text-xs text-color-400 italic">
                {subTitle}
              </div>
            </div>
            <div className="w-10 h-10 flex-shrink-0 hidden md:flex" />
          </div>
        </div>
      </div>
    </div>
  )
}
const renderItem = (item, i) => {
  if (!item) return null
  return (
    <Translate>
      {t => (
        <div className="p-3 flex items-center gap-3 w-full border border-color-50 rounded-lg background hover:shadow-items">
            <Avatar
                size={35}
                className={classnames('flex flex-center background-300', item._type === 'user' ? 'rounded-full' : 'rounded-md')}
                icon={<IoPerson className="text-color-300"/>}
                src={item.avatar}
                shape={
                    item._type === 'user'
                        ? 'circle'
                        : 'square'
                }>
                {getTitle(item)}
            </Avatar>
            <div className="flex-1 overflow-hidden">
                <div className="flex flex-col gap-1 overflow-hidden">
                    <Link
                        to={getLinkToDetail(item)}
                        className="cursor-pointer no-underline font-bold leading-tight truncate">
                        {item.title || item.name}
                    </Link>
                    <div className="font-light italic text-xs text-color-400 leading-tight truncate">
                        {item.idname || item.username}
                    </div>
                </div>
                <div className="text-xs">
                    {item.description || item.about}
                </div>
            </div>
            <UseFollow
                follow_status={
                    item.follow_status
                }
                owner_id={
                    item.id || item.username
                }
                owner_type={item._type}>
                {(handleFollow) => {
                    return (
                        <Button
                            onClick={() =>
                                handleFollow()
                            }
                            ghost={!!item.follow_status}
                            type="primary"
                            className="rounded-lg no-shadow no-text-shadow">
                            {item.follow_status
                                ? t('following')
                                : t('follow')}
                        </Button>
                    )
                }}
            </UseFollow>
        </div>
      )}
    </Translate>
  )
}
function PageDetail({}) {
  const {
    contextMenu,
    item,
    onFollow,
  } = useContext(ServerContext)
  const translate = useTranslate()

  return (
    <div>
      <ContentContainer>
        <PageDetailHeader
          item={item}
          follow_status={
            item.follow_status
          }
          onFollow={onFollow}
          cover={item.cover_photo}
          avatar={item.avatar}
          title={item.name}
          subTitle={item.email}
          contextMenu={contextMenu}
          translate={translate}
        />
      </ContentContainer>
      <ContentContainer>
        <div className="flex flex-wrap px-3 gap-3">
          {[
            [
              'channels',
              item.total_channels,
            ],
            [
              'followers',
              item.total_followers,
              (isToggle, toggle) => (
                <Modal
                  maskClosable
                  onCancel={toggle}
                  visible={isToggle}
                  footer={null}
                  title={
                    <span className="text-color-000 font-semibold">
                        {translate('followers')}
                    </span>
                  }>
                  <div className="py-6 space-y-3">
                    <LazyPagination
                      apiInfo={
                        user_getFollowersByUser_Api
                      }
                      query={{
                        ':username': item.username,
                      }}
                      renderItem={renderItem}
                    />
                  </div>
                </Modal>
              ),
            ],
          ].map(
            ([
              text,
              count,
              renderModal,
            ]) => (
              <Toggle key={text}>
                {(isToggle, toggle) => {
                  return (
                    <>
                      <div
                        onClick={toggle}
                        className="p-3 flex flex-col items-center gap-1 rounded-lg background-100 hover:background-200 cursor-pointer">
                        <span className="font-bold text-2xl leading-tight">
                          {createNumberFormater(translate)(count)}
                        </span>
                        <span className="text-sm italic">
                          {translate(text)}
                        </span>
                      </div>
                      {renderModal &&
                        renderModal(
                          isToggle,
                          toggle
                        )}
                    </>
                  )
                }}
              </Toggle>
            )
          )}
        </div>
      </ContentContainer>
      <br />
    </div>
  )
}
export default PageDetail
