import GivingInfo from './holders/GivingInfo'
import GivingList from './holders/GivingList'
import GivingNews from './holders/GivingNews'
import GivingOverview from './holders/GivingOverview'
import MyGivingList from './holders/MyGivingList'
import DonationContext from "../Donation/DonationContext";
import DonationIOReportWrapper from "../Donation/views/DonationEventReport";
import React from "react";

export const givingRouteEntities = [
  {
    path: 'overview',
    component: GivingOverview,
    breadcrumbName: (t) =>
      t('overview'),
  },
  {
    path: 'news',
    component: GivingNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'givings',
    component: GivingList,
    breadcrumbName: (t) => t('list of giving'),
  },
  {
    path: 'register',
    component: MyGivingList,
    breadcrumbName: (t) =>
      t('your register'),
  },
  {
    path: 'information',
    component: GivingInfo,
    breadcrumbName: (t) =>
      t('information'),
  },
]
