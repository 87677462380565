import _ from 'lodash'
import { fromObject } from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { WishareEntities } from '../enums'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from '../wishareFieldTypes'

const baseFieldValues = {
  privacy: 1,
  title: undefined,
  owner: undefined,
}

const entities = {
  [WishareEntities.BENEFICIARY]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
      impact_categories: [],
    },
  },
  [WishareEntities.ACTIVITY]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
      impact_categories: [],
    },
  },
  [WishareEntities.ORGANIZATION]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
    },
  },
  [WishareEntities.DONATION]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
      target: 0,
      registrable: 1,
      no_limit_flag: 0,
      conversion_required: 1,
      donation_type: undefined,
      donation_types:
        wishareFieldTypes.donation
          .types,
      target_unit_name:
        getDefaultFieldValue(
          wishareFieldTypes.donation
            .units
        ),
    },
  },
  [WishareEntities.GIVING]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
      target: 0,
      registrable: 1,
      no_limit_flag: 0,
      conversion_required: 1,
      giving_type: undefined,
      giving_types:
        wishareFieldTypes.giving.types,
      target_unit_name:
        getDefaultFieldValue(
          wishareFieldTypes.giving.units
        ),
    },
  },
  [WishareEntities.RECRUITMENT]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
      target: 0,
      no_limit_flag: 0,
      recruitment_type: undefined,
      recruitment_types:
        wishareFieldTypes.recruitment
          .types,
      work_time_type:
        getDefaultFieldValue(
          wishareFieldTypes.recruitment
            .work_time_types
        ),
      is_remote_work: Number(
        getDefaultFieldValue(
          wishareFieldTypes.recruitment
            .onSiteOrRemote
        )
      ),
      target_unit_name:
        getDefaultFieldValue(
          wishareFieldTypes.recruitment
            .units
        ),
    },
  },
}

const byAction = (source, action) =>
  _.get(source, action, {})

const createInitialValues = (
  type,
  action = CRUD.CREATE,
  params
) => {
  switch (type) {
    case WishareEntities.BENEFICIARY:
    case WishareEntities.ACTIVITY:
    case WishareEntities.DONATION:
    case WishareEntities.GIVING:
    case WishareEntities.ORGANIZATION:
    case WishareEntities.RECRUITMENT:
    default:
      return {
        ...byAction(
          _.get(entities, type),
          action
        ),
        ...params,
      }
  }
}

export const asArray = (input) =>
  input ? [...input] : []

export const asObject = (input) =>
  input ? { ...input } : {}

export const fromInitialValues = (
  [source, fields = []],
  transform = asArray
) => {
  const initialValues = _.get(
    source,
    'initialValues'
  )

  if (_.isEmpty(initialValues)) {
    transform()
  }

  const _fields = fields.map((item) =>
    _.isString(item)
      ? { name: item }
      : {
          ...item,
          name: _.get(
            item,
            'name',
            String(item)
          ),
        }
  )

  const result = fromObject(
    initialValues,
    _fields
  )

  const array = _fields.map(
    ({ name }) => ({
      name,
      value: result[name],
    })
  )

  return transform(array)
}

export default createInitialValues
