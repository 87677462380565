import Icon, {LinkOutlined, LoadingOutlined, PlusOutlined,} from '@ant-design/icons'
import {Avatar, Card} from 'antd'
import {search_getDataWithType_Api, search_getTags_Api, url_getMetaTags_Api,} from 'apis'
import {articleModel} from 'apis/model'
import classnames from 'classnames'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from 'react'
import {BsEmojiSmile} from 'react-icons/bs'
import {IoIosPaperPlane} from 'react-icons/io'
import {IoMdPaperPlane} from 'react-icons/io'
import {MdClose} from 'react-icons/md/'
import Loadable from 'react-loadable'
import ReactPlayer from 'react-player'
import {connect} from 'react-redux'
import {Link, useHistory,} from 'react-router-dom'
import useClickAway from 'react-use/lib/useClickAway'
import useThrottle from 'react-use/lib/useThrottle'
import {branch} from 'recompose'
import {getLogin} from 'redux/selectors'
import ChannelContext from 'views/Channel/ChannelContext'
import LoadingPage from 'views/LoadingPage'
import {isMdmArticleEnabled} from 'views/MediumArticle'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {SelectList} from 'views/Search/SelectList'
import {useRequiredLogin} from 'views/Server/useRequiredLogin'
// import EmojiPicker from './EmojiPicker'
import {BaseEmbed} from './Feed/BaseEmbed'
import {VideoEmbed} from './Feed/VideoEmbed'
import './InputBar.css'
import NullComponent from './NullComponent'
import {ArticleSvg} from './Svgs/ArticalSvg'
import {ImageSvg} from './Svgs/ImageSvg'
import {PollSvg} from './Svgs/PollSvg'

const EmojiPicker = Loadable({
  loader: () => import('./EmojiPicker'),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>,
})

export const gridStyle = {
  width: '100%',
  padding: '0.3em 1em',
  lineHeight: '2em',
}
const useSuggestMentions = (value) => {
  const delayValue = useThrottle(
    value,
    500
  )
  const {
    response,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: search_getDataWithType_Api,
    query: {
      ':type': ' ',
    },
  })
  useEffect(() => {
    if (
      delayValue &&
      delayValue.length > 0
    )
      handleAsyncAction({
        keyword: delayValue,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayValue])

  return [
    ..._.get(response, 'data.data', [])
      .map((item) => ({
        name: _.get(item, 'name'),
        type: _.get(
          item,
          'owner._type'
        ),
        id:
          _.get(
            item,
            'owner.username'
          ) ||
          _.get(item, 'owner.idname') ||
          _.get(item, 'owner.id'),
        link: `/${_.get(
          item,
          'owner._type'
        )}/${
          _.get(item, 'idname') ||
          _.get(item, 'owner.username')
        }`,
        avatar: _.get(item, 'avatar'),
      }))
      .filter((item, i) => i < 7),
  ]
}
const useTagSuggestMentions = (
  value
) => {
  const delayValue = useThrottle(
    value,
    500
  )
  const {
    response,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: search_getTags_Api,
    query: {
      ':keyword': ' ',
    },
  })
  useEffect(() => {
    if (
      delayValue &&
      delayValue.length > 0
    )
      handleAsyncAction({
        keyword: delayValue,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayValue])

  const suggestions = [
    {
      name: '#' + value,
      link: `/t/${value}`,
    },
    ..._.get(response, 'data.data', [])
      .map((item) => ({
        item,
        name: '#' + _.get(item, 'tags'),
        link: `/t/${_.get(
          item,
          'tags'
        )}`,
      }))
      .filter((item, i) => i < 7),
  ]
  return suggestions
}
const PopupWrapper = ({
                        children,
                        placement = 'top',
                      }) => (
  <div
    className="background-100 shadow-2xl"
    style={{
      position: 'absolute',
      ...(placement === 'top'
        ? {bottom: '100%'}
        : placement === 'bottom'
          ? {top: 55}
          : {}),
      maxWidth: '100%',
      left: 0,
      zIndex: 11,
    }}>
    {children}
  </div>
)

const Mention = ({
                   name,
                   id,
                   type,
                   avatar,
                   onClick,
                 }) => {
  return (
    <div
      onClick={onClick}
      key={id}
      className="py-2 px-3 flex items-center truncate hover:background-200 ">
      <Avatar
        size="small"
        src={avatar}>
        {getUpperFirstChar(name)}
      </Avatar>{' '}
      <span className="truncate flex-1 ml-3">
        {name}
      </span>
    </div>
  )
}
const InputBar = ({
    rows = 3,
    style = {},
    enterable = true,
    defaultHeight = 45,
    renderWrapper = PopupWrapper,
    defaultValue = '',
    textOnly,
    bordered = true,
    isLoading = false,
    placement = 'top',
    placeholder = 'share discussion',
    handleSubmit:
      onSubmit = NullComponent,
    onItemClick = NullComponent,
    className = 'background',
}) => {
  const translate = useTranslate()
  const history = useHistory()
  const {item} = useContext(
    ChannelContext
  )
  const [loading, setLoading] =
    useState(false)
  const [text, setText] = useState(
    defaultValue
  )
  const [mentionText, setMentionText] =
    useState({})
  const [tag, setTag] = useState({})
  const suggesstions =
    useSuggestMentions(mentionText.text)
  const tagsuggesstions =
    useTagSuggestMentions(tag.text)
  const [linkMeta, setLinkMeta] =
    useState()
  const textAreaRef = useRef()
  const popupRef = useRef(null)
  useClickAway(popupRef, () => {
    handleOnClicks.none()
  })
  // file,emoji
  const [select, setSelect] =
    useState(null)
  const [height, setHeight] = useState(
    defaultHeight
  )
  const handleOnClicks = useMemo(
    () => ({
      none: setSelect.bind(null, null),
      options: setSelect.bind(
        null,
        'options'
      ),
      file: setSelect.bind(
        null,
        'file'
      ),
      emoji: setSelect.bind(
        null,
        'emoji'
      ),
    }),
    []
  )
  const isReady = !_.isEmpty(text)
  useEffect(() => {
    if (!text || text.length === 0) {
      setHeight(defaultHeight)
    } else {
      setHeight(
        Math.min(
          Math.max(
            textAreaRef.current
              .scrollHeight,
            defaultHeight
          ),
          200
        )
      )
    }
    textAreaRef.current.focus()
  }, [text, defaultHeight])
  const handleOnEmojiSelect = (
    emoji
  ) => {
    setText(
      text + emoji.native ||
      emoji.colons
    )
  }
  const handleOnchange = useCallback(
    (e) => {
      setText(e.target.value)
    },
    []
  )
  const isOpenedSuggestions =
    suggesstions &&
    mentionText &&
    mentionText.text &&
    mentionText.text.length > 0 &&
    suggesstions.length > 0
  const isOpenedTagSuggestions =
    tagsuggesstions &&
    tag &&
    tag.text &&
    tag.text.length > 0 &&
    tagsuggesstions.length > 0
  const handleSubmit = useRequiredLogin(
    () => {
      if (!isReady || !!isLoading)
        return
      setLoading(true)
      onSubmit({
        description: text,
        embed_data: linkMeta
          ? JSON.stringify(linkMeta)
          : undefined,
      })
      setLoading(false)
    }
  )
  // useEffect(() => {
  //   let timeout
  //   if (loading) {
  //     timeout = setTimeout(
  //       () => setLoading(false),
  //       1000
  //     )
  //   }
  //   return () =>
  //     timeout && clearTimeout(timeout)
  // }, [loading])
  const handleOnKeyDown = (e) => {
    if (loading) return
    if (isOpenedSuggestions) {
      if (
        [40, 38, 13].includes(e.keyCode)
      ) {
        e.preventDefault()
        return
      }
    }
    if (isOpenedTagSuggestions) {
      if (
        [40, 38, 13].includes(e.keyCode)
      ) {
        e.preventDefault()
        return
      }
    }
    if (e.key === 'Enter' && e.altKey) {
      setText(text + '\n')
    }
    if (e.key === 'Enter' && !e.altKey) {
      e.preventDefault()
      e.target.value && handleSubmit()
    }
  }

  const {isLoading: loadingMeta, handleAsyncAction: getMetaFromUrl,} = useAsyncAction({
    apiInfo: url_getMetaTags_Api,
    onSuccess: (result, asyncApi) => {
      const {response} = asyncApi
      const linkMeta = _.get(response, 'data')
      if (linkMeta) {
        setLinkMeta(linkMeta)
      }
    },
  })

  return (
    <div
      className={classnames(
        'w-full flex flex-col InputBar',
        className,
        {
          ' background':
            linkMeta || loadingMeta,
        }
      )}>
      <div
        style={{
          height,
          borderRadius: '0.5rem',
          ...style,
        }}
        className={classnames(
          'w-full flex items-stretch',
          className,
          bordered && 'border'
        )}>
        {!textOnly && (
          <div
            onClick={
              handleOnClicks.options
            }
            data-effect="ripple"
            className="p-3 flex justify-center items-center">
            <PlusOutlined
              style={{
                fontSize: '1.4em',
              }}
            />
          </div>
        )}
        {!textOnly && (
          <div
            className="bg-gray-400 self-center"
            style={{
              width: '1px',
              height: '60%',
            }}
          />
        )}
        <textarea
          placeholder={translate(placeholder)}
          onSelect={(e) => {
            const str = e.target.value
            const end = Math.max(
              e.target.selectionEnd,
              0
            )
            let start = end
            let text = ''
            if (str && end > 1) {
              for (
                let i = end - 1;
                i >= 0 &&
                str[i] !== ' ';
                i--
              ) {
                start = i
                text = str[i] + text
              }

              if (
                text &&
                text.length > 1 &&
                text[0] === '@'
              ) {
                setMentionText({
                  start,
                  end,
                  text: text.substring(
                    text.length -
                    (text.length - 1)
                  ),
                })
              }
              if (
                text &&
                text.length > 1 &&
                text[0] === '#'
              ) {
                setTag({
                  start,
                  end,
                  text: text.substring(
                    text.length -
                    (text.length - 1)
                  ),
                })
              }
            }
          }}
          onPaste={(e) => {
            const text = e.clipboardData.getData('Text')
            const LINK_DETECTION_REGEX =
              /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/gi
            switch (true) {
              // case !!ReactPlayer.canPlay(
              // 	text
              // ):
              // 	setLinkMeta({
              // 		url: text,
              // 		type: 'video'
              // 	});
              // 	break;
              case !!LINK_DETECTION_REGEX.exec(text):
                getMetaFromUrl({
                  content: text,
                })
                break
              default:
                break
            }
          }}
          onKeyDown={handleOnKeyDown}
          value={text}
          ref={textAreaRef}
          onChange={handleOnchange}
          rows={rows}
          autoFocus={
            textAreaRef.current ===
            document.activeElement
          }
          className="p-3 flex-1 bg-transparent self-stretch resize-none overflow-hidden outline-none"
        />

        <div className="flex items-start">
          <div
            onClick={handleOnClicks.emoji}
            data-effect="ripple"
            style={{
              width: 43,
              height: 43,
            }}
            className="p-2 flex flex-center cursor-pointer rounded-full">
            <BsEmojiSmile style={{fontSize: '1.4em'}} className="text-color-400 hover:text-color-100"/>
          </div>
          <div
            onClick={handleSubmit}
            onKeyDown={handleOnKeyDown}
            data-effect="ripple"
            style={{
              width: 43,
              height: 43,
            }}
            className={classnames(
              'p-2 flex flex-center rounded-full',
              isReady
                ? 'cursor-pointer'
                : 'cursor-not-allow'
            )}>
            {loading || isLoading ? (
              <LoadingOutlined
                style={{
                  fontSize: '1.4em',
                }}
              />
            ) : (
              <Icon
                style={{
                  fontSize: '1.6em',
                  color: isReady
                    ? 'var(--color-primary)'
                    : 'var(--text-color-400)',
                }}
                disabled={isLoading}
                {...{
                  component: isReady ? IoIosPaperPlane : IoMdPaperPlane,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {renderWrapper({
        placement,
        children: (
          <>
            {isOpenedSuggestions && (
              <div className="m-3 background rounded border border-gray-500 ">
                <SelectList
                  onSelected={(e) => {
                    e.props.onClick()
                  }}>
                  {suggesstions.map(
                    (
                      {
                        name,
                        id,
                        type,
                        avatar,
                      },
                      i
                    ) => (
                      <Mention
                        key={id}
                        {...{
                          name,
                          id,
                          type,
                          avatar,
                        }}
                        onClick={() => {
                          setText(
                            text.substring(
                              0,
                              mentionText.start
                            ) +
                            `@${type}:${id} ` +
                            text.substring(
                              mentionText.end,
                              text.length
                            )
                          )
                          setMentionText(
                            {}
                          )
                        }}></Mention>
                    )
                  )}
                </SelectList>
              </div>
            )}
            {isOpenedTagSuggestions && (
              <div className="m-3 background rounded border border-gray-500 ">
                <SelectList
                  onSelected={(e) => {
                    e.props.onClick()
                  }}>
                  {tagsuggesstions.map(
                    (
                      {
                        name,
                        id,
                        type,
                        avatar,
                      },
                      i
                    ) => (
                      <Mention
                        key={id}
                        {...{
                          name,
                          id,
                          type,
                          avatar,
                        }}
                        onClick={() => {
                          setText(
                            text.substring(
                              0,
                              tag.start
                            ) +
                            name +
                            ' ' +
                            text.substring(
                              tag.end,
                              text.length
                            )
                          )
                          setTag({})
                        }}></Mention>
                    )
                  )}
                </SelectList>
              </div>
            )}
            {loadingMeta && (
              <div className="p-3 flex ">
                <LoadingPage/>
              </div>
            )}
            {linkMeta && (
              <div className="p-3 flex w-full">
                {!!ReactPlayer.canPlay(
                  linkMeta.url
                ) ? (
                  <div className="relative w-full">
                    <VideoEmbed
                      title={_.get(
                        linkMeta,
                        'title'
                      )}
                      url={_.get(
                        linkMeta,
                        'url'
                      )}
                      subTitle={
                        _.get(
                          linkMeta,
                          'host_url'
                        ) ||
                        _.get(
                          linkMeta,
                          'url'
                        )
                      }
                      description={_.get(
                        linkMeta,
                        'description'
                      )}
                      image={_.get(
                        linkMeta,
                        'photo'
                      )}
                    />
                    <div
                      style={{
                        position:
                          'absolute',
                        right: 0,
                        top: 0,
                        height:
                          'var(--header-height)',
                      }}
                      className="w-full stickyTop right-0 flex justify-end">
                      <HeaderButton
                        onClick={() =>
                          setLinkMeta(
                            null
                          )
                        }>
                        <Icon
                          style={{
                            fontSize:
                              '1.4em',
                          }}
                          component={
                            MdClose
                          }
                        />
                      </HeaderButton>
                    </div>
                  </div>
                ) : (
                  <div className="relative w-full">
                    <BaseEmbed
                      title={_.get(
                        linkMeta,
                        'title'
                      )}
                      subTitle={
                        _.get(
                          linkMeta,
                          'host_url'
                        ) ||
                        _.get(
                          linkMeta,
                          'url'
                        )
                      }
                      description={_.get(
                        linkMeta,
                        'description'
                      )}
                      image={_.get(
                        linkMeta,
                        'photo'
                      )}
                    />
                    <div
                      style={{
                        position:
                          'absolute',
                        right: 0,
                        top: 0,
                        height:
                          'var(--header-height)',
                      }}
                      className="w-full stickyTop right-0 flex justify-end">
                      <HeaderButton
                        onClick={() =>
                          setLinkMeta(
                            null
                          )
                        }>
                        <Icon
                          style={{
                            fontSize:
                              '1.4em',
                          }}
                          component={
                            MdClose
                          }
                        />
                      </HeaderButton>
                    </div>
                  </div>
                )}
              </div>
            )}
            {select && (
              <div
                ref={popupRef}
                className="flex">
                {select === 'emoji' && (
                  <EmojiPicker
                    onSelect={
                      handleOnEmojiSelect
                    }
                  />
                )}
                {select ===
                'options' && (
                  <Card className="font-bold  animated faster fadeInUp cursor-pointer">
                    <Card.Grid
                      onClick={() => {
                        onItemClick(
                          'createImagePost'
                        )
                      }}
                      style={gridStyle}>
                      <Icon
                        className="mr-2"
                        component={
                          ImageSvg
                        }
                      />
                      {translate(
                        'image'
                      )}
                    </Card.Grid>
                    <Card.Grid
                      onClick={() => {
                        onItemClick(
                          'quickLink'
                        )
                      }}
                      style={gridStyle}>
                      <LinkOutlined className="mr-2"/>
                      {translate(
                        'link'
                      )}
                    </Card.Grid>
                    <Card.Grid
                      onClick={() => {
                        onItemClick(
                          'createPoll'
                        )
                      }}
                      style={gridStyle}>
                      <Icon
                        className="mr-2"
                        component={
                          PollSvg
                        }
                      />
                      {translate(
                        'poll'
                      )}
                    </Card.Grid>
                    <PermissionContext.Consumer>
                      {({
                          canPostArticle,
                        }) =>
                        !!canPostArticle && (
                          <Card.Grid
                            className={
                              !canPostArticle &&
                              'disable'
                            }
                            onClick={() => {
                              if (
                                isMdmArticleEnabled
                              ) {
                                const createArticleURL =
                                  articleModel.getLinkToCreate(
                                    item
                                  )
                                history.push(
                                  {
                                    pathname:
                                    createArticleURL,
                                  }
                                )
                              } else {
                                onItemClick(
                                  'createArticle'
                                )
                              }
                            }}
                            style={
                              gridStyle
                            }>
                            <Icon
                              className="mr-2"
                              component={
                                ArticleSvg
                              }
                            />
                            {translate(
                              'article'
                            )}
                          </Card.Grid>
                        )
                      }
                    </PermissionContext.Consumer>
                  </Card>
                )}
              </div>
            )}
          </>
        ),
      })}
    </div>
  )
}
const LoginHolder = () => {
  const t = useTranslate()

  return (
    <div className="w-full flex flex-col InputBar">
      <div
        className="w-full flex items-stretch bg-gray-300 border-gray-500  border background"
        style={{
          height: 45,
          borderRadius: 4,
        }}>
        <div className="flex-1">
          {t('need login')}
        </div>
        <Link
          to={{
            pathname: '/login',
            state: {isModal: true},
          }}>
          {t('need login')}
        </Link>
      </div>
    </div>
  )
}
export default connect((state) => {
  return {
    isLogin: !!getLogin(state),
  }
})(
  branch(
    ({isLogin}) => false && !isLogin,
    () => LoginHolder
  )(InputBar)
)
