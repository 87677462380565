import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  beneficiarySchema,
  donationEventSchema,
  givingEventSchema,
  givingSchema,
} from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:givings,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
}

export const giving_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/giving-events/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: givingEventSchema,
  transform: createTransform(
    givingEventSchema,
    'data'
  ),
}

export const giving_getWidgetById_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/get-widget',
    method: 'GET',
    fields,
    fieldTypes,
    schema: givingEventSchema,
    transform: createTransform(
      givingEventSchema,
      'data'
    ),
  }

export const giving_add_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/giving-events/create',
  method: 'POST',
  fields,
  fieldTypes,
  schema: givingEventSchema,
  transform: createTransform(
    givingEventSchema,
    'data'
  ),
}

export const giving_update_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/giving-events/:giving_id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  schema: givingEventSchema,
  transform: createTransform(
    givingEventSchema,
    'data'
  ),
}

export const giving_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/giving-events/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: givingEventSchema,
  transform: createTransform(
    givingEventSchema,
    'data'
  ),
}

export const giving_events_getItemsByOwner_gets_api =
  {
    backend: `Route::get('giving-events/{prop}/{id}/get-giving-events', 'GivingEventController@getGivingEventsByProps')`,
    root: API_ROOT_URL,
    path: '/giving-events/:type/:id/get-giving-events',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'status',
      'keyword',
      'sort_by',
    ],
    schema: givingEventSchema,
    transform: createListTransform(
      givingEventSchema
    ),
  }

// Route::get('giving-events/{id}/givings/get-chart-data', 'GivingEventController@getGivingDataForChart');
export const giving_events_getGivingDataForChart_api =
  {
    backend: `Route::get('giving-events/{id}/givings/get-chart-data', 'GivingEventController@getGivingDataForChart');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/get-chart-data',
    method: 'GET',
    fields,
    fieldTypes,
  }

// Route::get('giving-events/{id}/givings/get-export-data', 'GivingEventController@getGivingDataForExport');
export const giving_events_getGivingDataForExport_api =
  {
    backend: `Route::get('giving-events/{id}/givings/get-export-data', 'GivingEventController@getGivingDataForExport');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/get-export-data',
    method: 'GET',
    fields,
    fieldTypes,
  }

export const giving_events_getAvailableDonationEvents_api =
  {
    backend: `Route::get('giving-events/{id}/donation-events/get-in-events', 'GivingEventController@getAvailableDonationEventsByGivingEvent');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/donation-events/get-in-events',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: donationEventSchema,
    transform: createListTransform(
      donationEventSchema
    ),
  }

// Route::get('giving-events/givings/user/{username}/get-givings', 'GivingController@getGivingsByUser');
export const giving_events_getGivingsByUser_api =
  {
    backend: `Route::get('giving-events/givings/user/{username}/get-givings', 'GivingController@getGivingsByUser');`,
    root: API_ROOT_URL,
    path: '/giving-events/givings/user/:username/get-givings',
    method: 'GET',
    fields,
    fieldTypes,
  }

// Route::get('giving-events/givings/user/{username}/get-pending-givings', 'GivingController@getPendingGivingsByUser');
export const giving_events_getPendingGivingsByUser_api =
  {
    backend: `Route::get('giving-events/givings/user/{username}/get-pending-givings', 'GivingController@getPendingGivingsByUser');`,
    root: API_ROOT_URL,
    path: '/giving-events/givings/user/:username/get-pending-givings',
    method: 'GET',
    fields,
    fieldTypes,
  }

const _localGivingSchema =
  new schema.Entity(
    'givings',
    {
      giving_event: givingEventSchema,
    },
    {
      idAttribute: 'id',
    }
  )

// Route::get('giving-events/{id}/givings/gets', 'GivingEventController@getGivingsByGivingEvent');
export const giving_events_getGivingsByGivingEvent_api =
  {
    backend: `Route::get('giving-events/{id}/givings/gets', 'GivingEventController@getGivingsByGivingEvent');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
      'filtered_by',
    ],
    schema: givingSchema,
    transform: createListTransform(
      givingSchema
    ),
  }

export const giving_events_givingManageAdd_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/manage-add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingSchema,
    transform: createTransform(
      givingSchema,
      'data'
    ),
  }

export const giving_events_givingManageEdit_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/:giving_id/manage-edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingSchema,
    transform: createTransform(
      givingSchema,
      'data'
    ),
  }

export const giving_events_givingManageDelete_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/:giving_id/manage-delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: _localGivingSchema,
    transform: createTransform(
      _localGivingSchema,
      'data'
    ),
  }

export const giving_events_addGivingOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/option/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingEventSchema,
    transform: createTransform(
      givingEventSchema,
      'data'
    ),
  }

export const giving_events_editGivingOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/option/:option_id/edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingEventSchema,
    transform: createTransform(
      givingEventSchema,
      'data'
    ),
  }

export const giving_events_deleteGivingOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/option/:option_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingEventSchema,
    transform: createTransform(
      givingEventSchema,
      'data'
    ),
  }

export const giving_events_subscribeOffer_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/subscribe-offer/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: givingSchema,
    transform: createTransform(
      givingSchema,
      'data'
    ),
  }

export const giving_events_unsubscribeOffer_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/giving-events/:id/subscribe-offer/:giving_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: _localGivingSchema,
    transform: createTransform(
      _localGivingSchema,
      'data'
    ),
  }

export const giving_events_getGivingDataForExportFile_api =
  {
    backend: `Route::get('giving-events/{id}/givings/get-export-file', 'GivingEventController@getGivingDataForExportFile');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/givings/get-export-file',
    method: 'GET',
    fields,
    fieldTypes,
  }

export const giving_events_importGivingTmpData_api =
  {
    backend: `API Import: Route::post('giving-events/{id}/data-import', 'DonationImportController@importGivingTmpData');`,
    root: API_ROOT_URL,
    path: '/giving-events/:id/data-import',
    method: 'POST',
    fields,
    fieldTypes,
  }

export const giving_events_getGivingsByType_api =
  {
    backend: `Route::get('giving-events/{type}/{id}/givings/gets', 'GivingEventController@getGivingsByType'); 
    type = organization, id = organization_id`,
    root: API_ROOT_URL,
    path: '/giving-events/:type/:id/givings/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'status',
      'keyword',
      'sort_by',
    ],
    schema: givingSchema,
    transform: createListTransform(
      givingSchema
    ),
  }

export const giving_events_getGivingsByBeneficiaryOwner_api =
  {
    backend: `Route::get('giving-events/{type}/{id}/givings/gets-by-beneficiary-owner', 'GivingEventController@getGivingsByBeneficiaryOwner');`,
    root: API_ROOT_URL,
    path: '/giving-events/:type/:id/givings/gets-by-beneficiary-owner',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: givingSchema,
    transform: createListTransform(
      givingSchema
    ),
  }

export const beneficiary_getGivingStats_api =
  {
    backend: `Route::get('beneficiaries/organization/{organization_id}/get-giving-stats', 'BeneficiaryController@getGivingStats');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/organization/:organization_id/get-giving-stats',
    method: 'GET',
    paramsFields: ['period'],
  }

export const beneficiary_getTopBeneficiaries_api =
  {
    backend: `Route::get('beneficiaries/organization/{organization_id}/get-top-beneficiaries', 'BeneficiaryController@getTopBeneficiaries'); // sort_by: [value desc|count desc]`,
    root: API_ROOT_URL,
    path: '/beneficiaries/organization/:organization_id/get-top-beneficiaries',
    method: 'GET',
    paramsFields: ['sort_by'],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getBeneficiariesOfTheSameOwner_Api =
  {
    backend: `Route::get('beneficiaries/organization/{organization_id}/get-beneficiaries-of-the-same-owner', 'BeneficiaryController@getBeneficiariesOfTheSameOwner');`,
    root: API_ROOT_URL,
    path: '/beneficiaries/organization/:organization_id/get-beneficiaries-of-the-same-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
      'owner_id',
      'owner_type',
      'beneficiary_id',
    ],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }

export const beneficiary_getBeneficiariesOfTheOwner_Api =
  {
    backend: `Route::get('beneficiaries/{type}/{id}/get-beneficiaries-of-the-owner', 'BeneficiaryController@getBeneficiariesOfTheOwner'); // sort_by: date, name`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:prop/:id/get-beneficiaries-of-the-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
      'filter_activity_id',
      'filter_giving_event_id',
    ],
    schema: beneficiarySchema,
    transform: createListTransform(
      beneficiarySchema
    ),
  }
