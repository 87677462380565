import {Drawer} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'

export const DrawerBottomMenu = ({
  placement = 'bottom',
  title = '',
  isToggle,
  toggle,
  children,
  ...props
}) => {
  const t = useTranslate()
  const [height, setHeight] = useState(
    'auto'
  )

  const onToggleHeight = () => {
    if (height === 'auto') {
      setHeight('70Vh')
    } else {
      setHeight('auto')
    }
  }

  const onClose = () => {
    toggle(false)
  }

  return (
    <Drawer
      className="text-xl font-medium"
      bodyStyle={{
        padding: '0px',
        marginLeft: '0px',
        marginRight: '0px'
      }}
      drawerStyle={{
        maxHeight: '70vh',
        overflowY: 'auto'
      }}
      placement={placement}
      closable={false}
      onClose={onClose}
      visible={isToggle}
      key={placement}
      height={height}
      title={
        <div className="flex items-center justify-center">
          <div
            className="h-2 w-24 border rounded-full bg-gray-700"
            onClick={onToggleHeight}
          />
        </div>
      }
      footer={
        <div
          onClick={onClose}
          className="flex items-center justify-center font-normal text-color-200 py-1 text-sm font-bold uppercase">
          {t('close')}
        </div>
      }>
      {children(isToggle, toggle)}
    </Drawer>
  )
}

export default DrawerBottomMenu
