import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const DonationLazy =
  createLazyComponent(() =>
    import('./Donation')
  )

const Donation = (props) => (
  <DonationLazy {...props} />
)

export default Donation
