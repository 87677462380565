import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Avatar} from "antd";
import getAvatar from "../../../../helpers/getAvatar";
import getTitle from "../../../../helpers/getTitle";
import {IoPerson} from "react-icons/io5";
import {formatDate, getFullAddress} from "../../../Shared";
import {GenderTypes, getProp} from "../../wishareFieldTypes";
import {useAppConfig} from "../../../../modules/local";

const DonorModalHeader = ({
  donor,
  Wrapper = 'div',
}) => {

  const t = useTranslate()

  const address = getFullAddress(_.get(donor, 'location'))

  let gender = !!_.get(donor, 'gender')
      ? getProp(
          Object.values(GenderTypes),
          'label'
      )(Number(_.get(donor, 'gender')))
      : null
  gender = !!gender ? t(gender) : null;

  const RowInformation = ({
    label,
    value,
    hiddenIfEmpty = true,
  }) =>
      !value && hiddenIfEmpty ? null : (
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-400 italic font-light">
              {_.isString(label) ? `${t(label)}: ` : label}
            </span>
            {value ? (
                <span className="text-xs text-color-000 font-medium flex-1">
                  {value}
                </span>
            ) : (
                <span className="italic text-xs text-color-200 font-medium">
                  {t('not added yet')}
                </span>
            )}
          </div>
      )
  const {org_types = {}} = useAppConfig()

  return (
      <div className="border border-color-50 rounded-lg p-3 flex flex-col space-y-3">
        <div className="flex items-center gap-2 border border-color-org rounded-lg p-2">
          <Avatar
            size={30}
            src={_.get(donor, 'organization.avatar')}
            className="flex flex-center background-100">
            <span>{_.first(_.get(donor, 'organization.title'))}</span>
          </Avatar>
          <div className="flex flex-col">
              <div className="font-bold text-color-org leading-tight">
                  {getTitle(_.get(donor, 'organization'))}
              </div>
              <div className="text-xs text-secondary italic">
                  {t(_.get(org_types, _.get(_.get(donor, 'organization'), 'org_type', '900')))}
              </div>
          </div>
        </div>
        <div className="flex flex-col w-full flex-center gap-1 p-2">
          <Avatar
              size={60}
              src={getAvatar(donor)}
              className="flex flex-center background-100">
            <span>{_.first(_.get(donor, 'title'))}</span>
          </Avatar>
          <div className="flex flex-col w-full items-center justify-center">
            <div className="text-lg font-semibold">{getTitle(donor)}</div>
            <div className="flex items-center justify-center gap-2">
              <span className="text-xs italic font-light">{t(_.get(donor, 'owner._type'))}</span>
              <span className="text-xs text-secondary italic font-light">{_.get(donor, 'owner.name')}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-3">
          <div className="uppercase text-sm font-medium text-color-300 border-b mb-2">
            {t('detail information')}
          </div>
          <div className="space-y-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              {
                [
                  {label: 'phone', value: _.get(donor, 'phone_number')},
                  {label: 'email', value: _.get(donor, 'email')},
                  {label: 'date of birth', value: _.get(donor, 'date_of_birth')},
                  {label: 'gender', value: gender},
                ].map((e,i) => !!e.value && (
                  <RowInformation key={i} label={e.label} value={e.value}/>
                ))
              }
            </div>
            {
              [
                {label: 'address', value: address},
                {label: 'notes', value: _.get(donor, 'notes')}
              ].map((e,i) => !!e.value && (
                <RowInformation key={i} label={e.label} value={e.value}/>
              ))
            }
          </div>
        </div>
      </div>
  )
}

export default DonorModalHeader
