import {
  Avatar,
  Button,
  Input,
} from 'antd'
import {
  medal_deleteMedalReward_Api,
  medal_getMedalAwardById_Api,
  medal_getMedalRewards_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  getResponseItem,
  Null,
  renderElse,
} from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { MedalRewardsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import MedalRewardActionModal, {
  MedalRewardModalTypes,
} from './MedalRewardActionModal'
import renderMedalReward from './renderMedalReward'

const ActionHeader = ({
  title,
  extra,
  children,
}) => (
  <Translate>
    {(t) => (
      <React.Fragment>
        {children}
        <div className="flex items-center uppercase font-medium text-color-300">
          {title && (
            <span>{t(title)}</span>
          )}
          {extra && (
            <div className="flex flex-1 justify-end">
              {extra}
            </div>
          )}
        </div>
      </React.Fragment>
    )}
  </Translate>
)

export const MedalRewardModalHeader = ({
  receiver,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { owner } = receiver || {}
  return renderElse(
    _.isEmpty(receiver),
    <Wrapper className="flex flex-col">
      <div className="uppercase font-medium text-color-300 mb-1">
        {t('receiver')}
      </div>
      <div className="w-full border border-color-50 rounded-lg background">
        <div className="flex items-center p-3 gap-3">
          <Avatar
            size={35}
            src={getAvatar(receiver)}
            className="flex flex-center background-200">
            <span className="capitalize text-color-200">
              {_.first(
                getTitle(receiver)
              )}
            </span>
          </Avatar>
          <div className="flex flex-col">
            {[
              WishareEntities.STAFF,
              WishareEntities.STAFF_ROSTER,
            ].includes(
              getType(receiver)
            ) ? (
              <div className="font-bold leading-tight max-lines-1">
                {getTitle(receiver)}
              </div>
            ) : (
              <Link
                to={getLinkToDetail(
                  receiver
                )}
                className="font-bold leading-tight max-lines-1">
                {getTitle(receiver)}
              </Link>
            )}
            {owner && (
              <div className="italic text-xs text-secondary font-light">
                {getTitle(owner)}
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const MedalRewardList = ({
  receiver,
  owner_id,
  owner_type,
  Header = Null,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const [keyword, setKeyword] =
    useState()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const onCancel = () =>
    setAction({ type: undefined })

  const [
    refreshToken,
    setRefreshToken,
  ] = useState()

  const refresh = () => {
    onCancel()
    history.push({
      state: {
        refreshToken: Date.now(),
      },
      search: location.search,
    })
    setRefreshToken({
      refreshToken: Date.now(),
    })
  }

  const {
    handleAsyncAction:
      deleteMedalReward,
  } = useAsyncAction({
    apiInfo:
      medal_deleteMedalReward_Api,
    onError: notifyOnError(t),
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      refresh()
    },
  })

  const onMenuSelect = (
    key,
    medal_reward
  ) => {
    const medal_reward_id = getId(
      medal_reward
    )
    switch (key) {
      case MedalRewardModalTypes.EDIT:
        setAction({
          type: key,
          value: medal_reward_id,
        })
        break
      case MedalRewardModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteMedalReward(
                {},
                bindQueryParam({
                  id: medal_reward_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const renderContextMenu = useCallback(
    (medal_reward) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onMenuSelect(
              key,
              medal_reward
            )
          }
          items={[
            {
              key: MedalRewardModalTypes.EDIT,
              label: 'edit',
              icon: (
                <IoCreateOutline
                  size={16}
                  className="text-color-300"
                />
              ),
            },
            {
              key: MedalRewardModalTypes.DELETE,
              label: 'delete',
              icon: (
                <IoTrashOutline
                  size={16}
                  className="text-red-500"
                />
              ),
            },
          ]}
        />
      </div>
    ),
    []
  )

  const RenderWrapper = useCallback(
    ({ children }) => {
      switch (action.type) {
        case MedalRewardModalTypes.EDIT:
          return (
            <Async
              {...{
                apiInfo:
                  medal_getMedalAwardById_Api,
                query: bindQueryParam({
                  id: action.value,
                }),
              }}>
              {({
                response,
                isLoading,
              }) =>
                _.isFunction(children)
                  ? children({
                      isLoading,
                      initialValues:
                        getResponseItem(
                          response
                        ),
                    })
                  : children
              }
            </Async>
          )
        case MedalRewardModalTypes.CREATE:
        default:
          return children({
            isLoading: false,
          })
      }
    },
    [action]
  )

  const [receiver_id, receiver_type] = [
    getId(receiver),
    getType(receiver),
  ]

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          medal_getMedalRewards_Api,
        query: bindQueryParams([
          { id: receiver_id },
          { prop: receiver_type },
        ]),
        renderItem: renderMedalReward({
          showOwner: false,
          showStatus: false,
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({ sortBy }) =>
        [
          sortBy,
          keyword,
          JSON.stringify(refreshToken),
        ].join('/'),
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
        receiver_id,
        receiver_type,
      ],
      renderFilter: Null,
      sortHeader: null,
      withValues: ({
        keyword: ignored,
        ...rest
      }) => ({ keyword, ...rest }),
      renderSearchBar: (_) => (
        <Input.Search
          defaultValue={keyword}
          onSearch={(value) =>
            setKeyword(value)
          }
          allowClear={true}
          className="input-search"
          placeholder={t('search')}
        />
      ),
      SortByWidget: MedalRewardsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ActionHeader
        title="medal reward list"
        extra={
          <Button
            type="primary"
            className="no-border rounded-lg no-shadow no-text-shadow"
            onClick={() => {
              setAction({
                type: MedalRewardModalTypes.CREATE,
              })
            }}>
            {t('give medal')}
          </Button>
        }>
        <Header {...{ receiver }} />
      </ActionHeader>
      {pager_widget}
      {action.type && (
        <RenderWrapper>
          {({
            isLoading,
            initialValues,
          }) => (
            <MedalRewardActionModal
              type={action.type}
              onCancel={onCancel}
              params={{
                owner_id,
                owner_type,
                receiver_id,
                receiver_type,
              }}
              onUpdated={refresh}
              onCreated={refresh}
              onRefresh={refresh}
              isLoading={isLoading}
              initialValues={
                initialValues
              }
            />
          )}
        </RenderWrapper>
      )}
    </Wrapper>
  )
}

export default MedalRewardList
