import React from 'react';
import {Block} from './Block';
import NullComponent from "../../../components/NullComponent";

export const EditAbleBlockHolder = ({
                                      icon = NullComponent,
                                      title,
                                      description,
                                      onClick
                                    }) => (
  <div className="border border-color-50 rounded-lg cursor-pointer hover:background-100">
    <div
      onClick={onClick}
      className="flex w-full flex flex-col text-color-500 py-8 px-4 items-center space-y-3">
      {!!icon && icon()}
      <div className="font-bold text-color-200 mt-3">
        {title}
      </div>
      <div className="text-xs text-color-500 leading-tight font-light italic">
        {description}
      </div>
    </div>
  </div>
);
