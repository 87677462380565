import { Button } from 'antd'
import { recruitment_events_getItemsByOwner_gets_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useMemo } from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import { staticPaths } from 'routes/staticPaths'
import { Null } from 'views/Shared'
import { RecruitmentsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import RecruitmentEventItem from 'views/Wishare/Recruitment/components/RecruitmentEventItem'
import RecruitmentEntity from 'views/Wishare/Recruitment/RecruitmentEntity'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import RecruitmentsAdvancedFilter from '../HumanResources/RecruitmentsAdvancedFilter'
import WorkspaceContext from '../WorkspaceContext'

const WorkspaceRecruitmentEventsList =
  ({
    owner_id,
    owner_type,
    Wrapper = 'div',
    filter_actions = {
      status: 'status',
      sortBy: 'sort-by',
      keyword: 'keyword',
    },
  }) => {
    const t = useTranslate()

    const location = useLocation()

    const history = useHistory()

    const sortBy = useSearchParam(
      filter_actions.sortBy
    )

    const filter_status =
      useSearchParam(
        filter_actions.status
      )

    const refresh_deps = useMemo(
      () => [
        owner_id,
        owner_type,
        `refreshToken=${_.get(
          location.state,
          'refreshToken'
        )}`,
      ],
      [
        owner_id,
        owner_type,
        location.state,
      ]
    )

    const [pager_widget] =
      usePaginationHelper({
        onInit: ({
          onSortChange = Null,
          onFilterChange = Null,
        }) => {
          onFilterChange({
            status: filter_status,
          })
          onSortChange(sortBy)
        },
        pagerInfo: {
          apiInfo:
            recruitment_events_getItemsByOwner_gets_api,
          query: bindQueryParams([
            { id: owner_id },
            { type: owner_type },
          ]),
          RenderItem:
            RecruitmentEventItem,
          RenderEntity:
            RecruitmentEntity,
        },
        withKey: ({
          sortBy,
          keyword,
          filterBy,
        }) =>
          [
            ...refresh_deps,
            sortBy,
            keyword,
            JSON.stringify(filterBy),
          ].join('/'),
        dependencies: [
          owner_id,
          owner_type,
        ],
        query_params: filter_actions,
        sortHeader: t('campaign list'),
        SortByWidget:
          RecruitmentsSortBy,
        AdvancedFilter:
          RecruitmentsAdvancedFilter,
      })

    return (
      <Wrapper className="flex flex-col space-y-3">
        <ListHeader
          title="recruitment campaign"
          extra={
            <Button
              className="rounded-md no-border no-text-shadow no-shadow"
              type="primary"
              onClick={() => {
                history.push({
                  ...staticPaths.createRecruitment,
                  search:
                    '?' +
                    new URLSearchParams(
                      {
                        owner_id,
                        owner_type,
                      }
                    ).toString(),
                })
              }}>
              {t('create')}
            </Button>
          }
        />
        {pager_widget}
      </Wrapper>
    )
  }

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id }) =>
      _.isEmpty(owner_id),
    renderNothing
  ),
  pure
)(WorkspaceRecruitmentEventsList)
