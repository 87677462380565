import { Input } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useState,
} from 'react'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { GivingsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import GivingItemList from '../components/GivingItemList'
import { MyGivingRecordItem } from '../components/GivingRecordItem'
import GivingContext from '../GivingContext'

const MyGivingList = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [sort, setSort] = useState()

  const { giving } = useContext(
    GivingContext
  )

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  if (_.isEmpty(giving)) return null

  return (
    <Wrapper className="flex flex-col space-y-3 pb-16">
      <Input.Search
        className="input-search"
        placeholder={t(
          'search with registration code'
        )}
        onSearch={(value) =>
          onSearch(value)
        }
      />

      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {t('your registered list')}
          </span>
          <div className="flex items-end justify-end">
            <GivingsSortBy
              value={sort}
              onSelect={(value) =>
                setSort(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <GivingItemList
            sort_by={sort}
            keyword={keyword}
            filtered_by="owner"
            RenderItem={
              MyGivingRecordItem
            }
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default MyGivingList
