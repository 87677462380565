import {BellOutlined, LogoutOutlined, SettingOutlined, UserAddOutlined,} from '@ant-design/icons'
import {
  Alert,
  Avatar,
  Button,
  DatePicker,
  Divider,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Steps,
  Switch,
} from 'antd'
import {eventModel} from 'apis/model'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {UseHook} from 'components/UseReducer'
import {convertToRaw} from 'draft-js'
import mapParams from 'helpers/hocs/mapParams'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import * as moment from 'moment'
import React, {useCallback, useContext, useMemo, useState,} from 'react'
import {AiFillCaretLeft, AiOutlineArrowRight, AiOutlineMinusCircle, AiOutlinePlusCircle,} from 'react-icons/ai'
import {FaCircle, FaPlayCircle, FaPlusCircle, FaRegDotCircle, FaStopCircle, FaUser,} from 'react-icons/fa'
import {FiEdit2} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {nest, withProps,} from 'recompose'
import ServerContext from 'views/Server/ServerContext'
import {Null, shallowDiff,} from 'views/Shared'
import {PhotoUploadComposite} from 'views/Wishare/custom/PhotoField'
import {event_delete_Api, event_edit_Api,} from '../../apis/event'
import {eventSchema} from '../../apis/schema/event'
import ApiInfoForm from '../../components/form/ApiInfoForm'
import FieldsFactory from '../../components/form/FieldsFactory'
import FormActionBar from '../../components/form/FormActionBar'
import {createValue} from '../../components/form/utils'
import {LoginContext} from '../../components/LoginContext'
import LookupField from '../../components/LookupField'
import {parseRawContent} from '../../components/RichEditor/parseRawContent'
import RichEditor from '../../components/RichEditor/RichEditor'
import Toggle from '../../components/Toggle'
import UseState from '../../components/UseState'
import {emptyArray} from '../../helpers/emptyObjects'
import AsyncAction from '../../modules/asyncCache/components/AsyncAction'
import {useHistory} from '../../modules/navigation/useRouter'
import ServerContainer from '../Server/ServerContainer'
import {useRequiredLogin} from '../Server/useRequiredLogin'
import EventOverviewSetting, {EventOverviewFieldSet,} from './components/EventOverviewSetting'
import EventContext from './EventContext'
import {EventProvider} from './EventProvider'
import {routes} from './routes'
import {IoCalendarOutline, IoHome, IoTrashOutline} from "react-icons/io5";

const {Step} = Steps

export const CustomInputTextAreaField =
  ({label, ...props}) => {
    const t = useTranslate()

    return (
      <div className="flex flex-col px-3 py-1 border background rounded-lg">
        <div className="text-xs text-color-300">
          {t(label)}
        </div>
        <Input.TextArea {...props} />
      </div>
    )
  }

export const CustomRichEditorField = ({
                                        label,
                                        editorState,
                                        setEditorState,
                                        onGlobalChange,
                                        ...props
                                      }) => {
  const t = useTranslate()

  const handleOnChange = useCallback(
    (editorState) => {
      onGlobalChange(editorState)
      setEditorState(editorState)
    },
    [onGlobalChange, setEditorState]
  )

  return (
    <div className="flex flex-col px-3 py-1 border border-dashed background-100 rounded">
      <div className="text-xs text-color-300">
        {t(label)}
      </div>
      <RichEditor
        key={'event_description'}
        editorState={editorState}
        onChange={handleOnChange}
        // autoFocus={true}
      />
    </div>
  )
}

export const TimelineSpeakersField = ({
                                        label,
                                        onChange,
                                        ...props
                                      }) => {
  const t = useTranslate()

  const initSpeakers = _.get(
    props,
    'defaultValues',
    []
  )

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initSpeakers.map(
          (e, remapIndex) => ({
            ...e,
            key: remapIndex,
          })
        )}>
        {([speakers, setSpeakers]) => {
          return (
            <div className="flex flex-col gap-2">
              {speakers.map(
                (e, index) => (
                  <div className="flex flex-col" key={index}>
                    <div className="flex items-center gap-3 rounded-md background p-2 border border-color-50">
                      <Avatar
                        size={25}
                        className="flex flex-center border border-color-50 background-100"
                        icon={<FaUser className="text-color-400"/>}
                        src={_.get(e, 'avatar')}
                      />

                      <div className="font-medium text-color-000 text-xs flex-1 leading-tight">
                        {_.get(e, 'speaker.name', _.get(e, 'speaker_name', _.get(e, 'value')))}
                      </div>

                      <IoTrashOutline
                        onClick={() => {
                          const _speakers =
                            speakers.filter(
                              (el) => el.key !== index
                            )
                          onChange(_speakers)
                          setSpeakers(_speakers)
                        }}
                        className="float-right text-red-500 hover:text-red-600"
                      />
                    </div>
                  </div>
                )
              )}
              <div className="flex flex-col">
                {!!label && (
                  <div className="text-xs text-color-300">
                    {t(label)}
                  </div>
                )}
                <LookupField
                  apiPath={`/lookup/user/get-by-types`}
                  {...props}
                  handleChange={(e) => {
                    onChange([
                      ...speakers,
                      {
                        ...e.target,
                        speaker_name:
                        e.target
                          .value,
                      },
                    ])
                    setSpeakers([
                      ...speakers,
                      {
                        ...e.target,
                        speaker_name:
                        e.target
                          .value,
                      },
                    ])
                  }}
                />
              </div>
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const listHolder = (
  <div className="flex flex-col">
    {new Array(2)
      .fill(1)
      .map((item, i) => (
        <div
          key={'listHolder' + i}
          className="mb-3">
          <div className="flex text-nowrap items-center py-1">
            <Avatar
              size={16}
              style={{
                marginRight: '0.2em',
                background: '#f1f1f1',
                color: '#545454',
                fontWeight: 'bold',
              }}
              src={''}
              shape="circle"
            />
            <span
              style={{
                textOverflow:
                  'ellipsis',
              }}
              className="font-bold whitespace-no-wrap text-xs text-color-500">
              ####
            </span>
          </div>
          <div
            className="background-200 border-gray-300"
            style={{
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
              height: '284px',

              borderRadius: '0.3em',
            }}
          />
        </div>
      ))}
  </div>
)

const TimetableEditor = ({
                           name,
                           item,
                           itemIndex,
                           onChange,
                           isInsert,
                           ...props
                         }) => {
  const t = useTranslate()
  const values = _.get(
    props,
    'form.values',
    {}
  )

  const [editorState, setEditorState] =
    useState(() =>
      parseRawContent(item.description)
    )
  const handleOnChange = useCallback(
    (editorState) => {
      setEditorState(editorState)
    },
    []
  )

  const imageSrcList = [
    ...Object.values(
      convertToRaw(
        editorState.getCurrentContent()
      ).entityMap
    )
      .filter(
        ({type}) => type === 'IMAGE'
      )
      .map((item) =>
        _.get(item, 'data')
      ),
  ]

  return (
    <UseState
      initialValue={{
        isUpdating: false,
        ...item,
        start_time: moment(
          item.start_time
        ),
        finish_time: !!item.finish_time
          ? moment(item.finish_time)
          : null,
      }}>
      {([
          updateItem,
          setUpdateItem,
        ]) => (
        <div className="flex flex-col gap-2 w-full">
          <Input hidden={true} name="timetables"/>
          <div className="flex flex-col">
            <div className="font-light text-xs text-color-400 italic">
              {t('start time')}:
            </div>
            <div className="flex items-center gap-3">
              <DatePicker
                size={'small'}
                showTime
                className="flex-1"
                suffixIcon={<IoCalendarOutline/>}
                placeholder={t('ex: 01/01/2021 - 12:00:00')}
                name="start_time"
                defaultValue={updateItem.start_time}
                onChange={(value) => {
                  setUpdateItem({
                    ...updateItem,
                    start_time: !!value
                      ? value
                        .utc()
                        .format()
                      : null,
                  })
                }}
              />
              <Switch
                checkedChildren={t('milestone')}
                onChange={(checked) => {
                  setUpdateItem({
                    ...updateItem,
                    milestone: checked
                      ? 1
                      : 0,
                  })
                }}
                unCheckedChildren={t('update')}
                defaultChecked={updateItem.milestone === 1}
              />
            </div>
          </div>

          <Toggle
            defaultValue={
              !!updateItem.finish_time
            }>
            {(isToggle, toggle) => (
              <React.Fragment>
                {!isToggle ? (
                  <div
                    className="flex items-center text-primary gap-2 cursor-pointer"
                    onClick={toggle}>
                    <AiOutlinePlusCircle/>
                    <span className="text-sm italic">
                      {t('finish time')}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2 cursor-pointer" onClick={toggle}>
                      <AiOutlineMinusCircle/>
                      <span className="text-sm italic">
                        {t('finish time')}
                      </span>
                    </div>
                    <DatePicker
                      size={'small'}
                      showTime
                      className="flex-1"
                      suffixIcon={<IoCalendarOutline/>}
                      defaultValue={moment(values.finish_time)}
                      onChange={(value) => {
                        setUpdateItem({
                          ...updateItem,
                          finish_time: value,
                        })
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex flex-col">
            <div className="font-light text-xs text-color-400 italic">
              {t('title')}:
            </div>
            <Input
              size={'small'}
              name="title"
              defaultValue={
                updateItem.title
              }
              onChange={(e) => {
                setUpdateItem({
                  ...updateItem,
                  title: e.target.value,
                })
              }}
            />
          </div>

          <Toggle
            defaultValue={
              !!_.get(
                updateItem,
                'speakers.length'
              )
            }>
            {(isToggle, toggle) => (
              <React.Fragment>
                {!isToggle ? (
                  <div className="flex items-center text-primary gap-3 cursor-pointer" onClick={toggle}>
                    <AiOutlinePlusCircle/>
                    <span className="text-sm italic">
                      {t('speakers')}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex items-center gap-3 cursor-pointer" onClick={toggle}>
                      <AiOutlineMinusCircle/>
                      <span className="text-sm italic">
                        {t('speakers')}
                      </span>
                    </div>
                    <FieldsFactory
                      formSchema={[
                        {
                          children: {
                            type: 'text',
                            name: 'speakers',
                            component: TimelineSpeakersField,
                            withProps: {
                              bordered: false,
                              type: 'text',
                              size: 'large',
                              placeholder: t('select user'),
                              defaultValues: updateItem.speakers,
                              onChange:
                                (values) => {
                                  setUpdateItem(
                                    {
                                      ...updateItem,
                                      speakers:
                                        values.map(
                                          (e) => ({
                                            ...e,
                                            speaker_name: e.value,
                                          })
                                        ),
                                    }
                                  )
                                },
                            },
                          },
                        },
                      ]}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <Toggle
            defaultValue={
              !!_.get(
                updateItem,
                'description'
              )
            }>
            {(isToggle0, toggle0) => (
              <React.Fragment>
                {!isToggle0 ? (
                  <div
                    className="flex items-center text-primary space-x-3 cursor-pointer"
                    onClick={toggle0}>
                    <AiOutlinePlusCircle/>
                    <span className="text-sm italic">
                      {t('description')}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div
                      className="flex items-center space-x-3 cursor-pointer"
                      onClick={toggle0}>
                      <AiOutlineMinusCircle/>
                      <span className="text-sm italic">
                        {t('description')}
                      </span>
                    </div>
                    <Toggle>
                      {(isToggle, toggle) => {
                        return (
                          <div className="flex flex-col gap-2">
                            <div className="flex items-center">
                              <div className="font-medium text-xs text-color-400 flex-1">
                                {t('about')}
                              </div>
                              <div
                                className="flex flex-center gap-2 text-primary cursor-pointer px-2 py-1 background border border-primary border-dashed rounded-md"
                                onClick={toggle}>
                                <div className="font-medium text-xs leading-tight">
                                  {t('edit')}
                                </div>
                              </div>
                            </div>
                            <div className="border border-color-50 background px-3 rounded-md">
                              <RichEditor
                                key={isToggle}
                                editorState={editorState}
                                readOnly
                              />
                            </div>
                            <Modal
                              className="custom-modal"
                              onCancel={toggle}
                              okText={t('save')}
                              cancelText={t('cancel')}
                              okButtonProps={{
                                className: 'rounded-md no-shadow no-text-shadow no-border',
                              }}
                              cancelButtonProps={{
                                className: 'rounded-md no-shadow no-text-shadow no-border',
                              }}
                              maskClosable={false}
                              title={
                                <div className="text-center font-bold text-color-000 ">
                                  {t('about')}
                                </div>
                              }
                              destroyOnClose
                              visible={isToggle}
                              onOk={() => {
                                toggle()
                              }}>
                              <RichEditor
                                editorState={editorState}
                                onChange={handleOnChange}
                                autoFocus={true}
                              />
                            </Modal>
                          </div>
                        )
                      }}
                    </Toggle>
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex gap-3">
            <div className="flex-1"/>
            {!!!isInsert && (
              <Popconfirm
                placement="bottomLeft"
                title={t('remove this')}
                onConfirm={() => {
                  let _timetableTemp =
                    _.get(
                      values,
                      'timetables',
                      []
                    ).filter(
                      (e) =>
                        e.id !==
                        updateItem.id
                    )

                  onChange(
                    createValue(
                      'timetables',
                      _timetableTemp
                    )
                  )
                }}
                okText={t('yes')}
                cancelText={t('no')}
                okButtonProps={{
                  className: 'rounded no-shadow no-text-shadow',
                }}
                cancelButtonProps={{
                  className: 'rounded no-shadow no-text-shadow',
                }}
              >
                <Button danger type="primary" className="rounded-md no-shadow no-text-shadow">
                  {t('remove')}
                </Button>
              </Popconfirm>
            )}
            <Button
              type="primary"
              className="rounded-md no-shadow no-text-shadow"
              onClick={() => {
                let _updateItem =
                  updateItem
                if (
                  !!_updateItem.start_time &&
                  moment.isMoment(
                    _updateItem.start_time
                  )
                )
                  _updateItem.start_time =
                    _updateItem.start_time
                      .utc()
                      .format()
                if (
                  !!_updateItem.finish_time &&
                  moment.isMoment(
                    _updateItem.finish_time
                  )
                )
                  _updateItem.finish_time =
                    _updateItem.finish_time
                      .utc()
                      .format()

                _updateItem.description =
                  JSON.stringify(
                    convertToRaw(
                      editorState.getCurrentContent()
                    )
                  )

                _updateItem.photo_ids_string =
                  _.join(
                    _.map(
                      imageSrcList,
                      'imageId'
                    ),
                    ','
                  )

                if (
                  !!_updateItem.isInsert
                )
                  delete _updateItem.isInsert
                if (
                  !!_updateItem.active
                )
                  delete _updateItem.active
                if (
                  !!_updateItem.isUpdating
                )
                  delete _updateItem.isUpdating

                let _timetableTemp =
                  values.timetables
                _timetableTemp[
                  itemIndex
                  ] = {
                  ..._updateItem,
                }

                // insert 1 default new
                if (!!isInsert) {
                  _timetableTemp.splice(
                    itemIndex + 1,
                    0,
                    {
                      isInsert: true,
                      active: true,
                    }
                  )
                }

                onChange(
                  createValue(
                    'timetables',
                    _timetableTemp
                  )
                )
                onChange(
                  createValue(
                    'update',
                    Date.now()
                  )
                )
              }}>
              {t('update')}
            </Button>
          </div>
        </div>
      )}
    </UseState>
  )
}

const EventSetting = ({id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {
    item = {},
    newPosts,
    handleSetViewedPosts,
    handleActionClick,
  } = useContext(ServerContext)
  const handleRequiredLogin =
    useRequiredLogin()
  const shareUrl =
    eventModel.getLinkToShare(item)
  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  const initialValues = {
    title: item.title,
    video_url: item.video_url,
    pdf_path: item.pdf_path,
    idname: item.idname,
    privacy: item.privacy,
    event_type: item.event_type,
    time_open: item.time_open,
    start_date: moment(item.time_open),
    start_time: moment(item.time_open),
    time_close: item.time_close,
    end_date: !!item.time_close
      ? moment(item.time_close)
      : null,
    end_time: !!item.time_close
      ? moment(item.time_close)
      : null,
    language: item.language,
    interval_type:
      item.interval_type || 'N',
    interval_daily:
      item.interval_daily || '0000000',
    interval_monthly:
      item.interval_monthly || 1,
    interval_yearly:
      moment(item.interval_monthly) ||
      moment(),
    location_name: item.location_name,
    external_link: item.external_link,
    categories: (
      item.categories || emptyArray
    ).map(({id}) => id),
    categoryOptions:
      item.categories || emptyArray,
    description: item.description,
    about: item.about,
    schedules: _.get(
      item,
      'schedules',
      []
    ).map((el) => {
      return moment(el.start_time)
    }),
    co_hosts: _.get(
      item,
      'hosts',
      []
    ).map((el) => {
      return {
        _type: _.get(el, 'host._type'),
        id: _.get(el, 'host.id'),
        value:
          _.get(el, 'host_name') ||
          _.get(
            el,
            'host.name',
            _.get(el, 'host.title')
          ),
        avatar: _.get(
          el,
          'host.avatar'
        ),
      }
    }),
    co_admins: _.get(
      item,
      'admins',
      []
    ).map((en) => ({
      _type: _.get(en, 'user._type'),
      id: _.get(en, 'user.id'),
      value: _.get(en, 'user.name'),
      avatar: _.get(en, 'user.avatar'),
      editable: !(
        _.get(en, 'user.id') ===
        (login && login.id) ||
        _.get(en, 'role_id') == 1
      ),
    })),
    avatar: item.avatar,
    cover_photo: item.cover_photo,
    avatar_preview: item.avatar,
    cover_photo_preview:
    item.cover_photo,
  }

  const [editorState, setEditorState] =
    useState(() =>
      parseRawContent(item.about)
    )
  const handleOnChange = useCallback(
    (editorState) => {
      setEditorState(editorState)
    },
    []
  )

  return (
    <PermissionProvider
      permissions={permissions}>
      {_.get(item, 'warnings', []).map(
        (e, i) => (
          <Alert
            key={i}
            type="warning"
            closable={true}
            banner
            message={
              <div className="flex flex-col space-y-3">
                <div className="flex items-center text-color-300">
                  <div className="mr-3 flex-1">
                    <div className="font-bold">
                      {e.title}
                    </div>
                    <div>
                      {e.description}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {_.get(e, '_type') ===
                  'login_required' && (
                    <Link
                      to={'/login'}
                      className="border rounded-full px-6 py-2 bg-primary text-white">
                      {t('Login now')}
                    </Link>
                  )}
                </div>
              </div>
            }
          />
        )
      )}

      <ApiInfoForm
        initialValues={initialValues}
        query={{
          ':id': item.id,
        }}
        onPreSubmit={({
                        avatar_preview,
                        cover_photo_preview,
                        ...values
                      }) => {
          let _values = shallowDiff(
            values,
            initialValues
          )
          if (!!values.co_hosts) {
            _values = {
              ..._values,
              co_hosts: JSON.stringify(
                values.co_hosts.map(
                  (e) => {
                    return {
                      type: e._type,
                      id: e.id,
                      host_name:
                      e.value,
                    }
                  }
                )
              ),
            }
          }
          if (!!values.co_admins) {
            _values = {
              ..._values,
              co_admins: JSON.stringify(
                values.co_admins.map(
                  (e) => {
                    return e.id
                  }
                )
              ),
            }
          }
          if (!!values.speakers) {
            _values = {
              ..._values,
              speakers: JSON.stringify(
                values.speakers.map(
                  (e) => {
                    return {
                      type: e._type,
                      id: e.id,
                      speaker_name:
                      e.value,
                    }
                  }
                )
              ),
            }
          }

          if (!!values.schedules) {
            _values = {
              ..._values,
              schedules: JSON.stringify(
                values.schedules.map(
                  (e) => {
                    return e.format(
                      'YYYY-MM-DD'
                    )
                  }
                )
              ),
            }
          }

          if (
            !!values.start_date &&
            moment.isMoment(
              values.start_date
            ) &&
            !!values.start_time &&
            moment.isMoment(
              values.start_time
            )
          ) {
            _values = {
              ..._values,
              time_open: moment(
                values.start_date.format(
                  'YYYY-MM-DD'
                ) +
                ' ' +
                values.start_time.format(
                  'HH:mm'
                )
              )
                .utc()
                .format(),
            }
          }
          if (
            !!values.end_date &&
            moment.isMoment(
              values.end_date
            ) &&
            !!values.end_time &&
            moment.isMoment(
              values.end_time
            )
          ) {
            _values = {
              ..._values,
              time_close: moment(
                values.end_date.format(
                  'YYYY-MM-DD'
                ) +
                ' ' +
                values.end_time.format(
                  'HH:mm'
                )
              )
                .utc()
                .format(),
            }
          }

          if (!!values.timetables) {
            _values = {
              ..._values,
              timetables:
                JSON.stringify(
                  values.timetables.filter(
                    (el) =>
                      !!!el.isInsert
                  )
                ),
            }
          }

          _values.about =
            JSON.stringify(
              convertToRaw(
                editorState.getCurrentContent()
              )
            )

          const imageSrcList = [
            ...Object.values(
              convertToRaw(
                editorState.getCurrentContent()
              ).entityMap
            )
              .filter(
                ({type}) =>
                  type === 'IMAGE'
              )
              .map((item) =>
                _.get(item, 'data')
              ),
          ]

          _values.photo_ids_string =
            _.join(
              _.map(
                imageSrcList,
                'imageId'
              ),
              ','
            )

          return _.omitBy(
            _values,
            _.isUndefined
          )
        }}
        apiInfo={event_edit_Api}>
        {({
            isSubmitting,
            dirty,
            isValid,
            values = {},
            validate,

            ...props
          }) => {
          return (
            <React.Fragment>
              <LayoutContext.Consumer>
                {({isSm}) =>
                  isSm && (
                    <EventOverviewFieldSet/>
                  )
                }
              </LayoutContext.Consumer>
              <div className="px-3">
                <PhotoUploadComposite
                  values={values}
                  title={_.get(values, 'title')}
                  onChange={props.handleChange}
                  fallback_cover={"/images/default/event-bg.jpg"}
                  renderAvatar={Null}
                  renderAvatarUploader={Null}
                />
              </div>
              <div className="px-3 w-full py-5 space-y-3">
                <Toggle>
                  {(isToggle, toggle) => {
                    return (
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center">
                          <div className="flex-1 font-semibold text-sm text-color-300 uppercase">
                            {t('about')}
                          </div>
                          <div
                            className="flex flex-center gap-2 text-primary cursor-pointer px-2 py-1 background border border-primary border-dashed rounded-md"
                            onClick={toggle}>
                            <div className="font-semibold text-xs leading-tight">
                              {t('edit')}
                            </div>
                          </div>
                        </div>
                        <div className="background rounded-lg border border-color-50 px-3">
                          <RichEditor
                            key={isToggle}
                            editorState={editorState}
                            readOnly
                          />
                        </div>

                        <Modal
                          className="custom-modal"
                          onCancel={toggle}
                          maskClosable={false}
                          title={
                            <div className="text-center font-bold text-color-000 uppercase">
                              {_.get(item, 'title')}
                            </div>
                          }
                          destroyOnClose
                          visible={isToggle}
                          onOk={() => {
                            props.handleChange(
                              createValue(
                                'about',
                                JSON.stringify(
                                  convertToRaw(
                                    editorState.getCurrentContent()
                                  )
                                )
                              )
                            )
                            toggle()
                          }}
                          okText={t('save')}
                          cancelText={t('cancel')}
                          okButtonProps={{
                            className: 'rounded-lg no-shadow no-text-shadow no-border',
                          }}
                          cancelButtonProps={{
                            className: 'rounded-lg no-shadow no-text-shadow no-border',
                          }}>
                          <RichEditor
                            key={'event_description'}
                            editorState={editorState}
                            onChange={handleOnChange}
                            autoFocus={true}
                          />
                        </Modal>
                      </div>
                    )
                  }}
                </Toggle>

                <div className="flex-1"/>
                <FormActionBar/>
              </div>
            </React.Fragment>
          )
        }}
      </ApiInfoForm>
    </PermissionProvider>
  )
}

const EventTimetableSetting = ({
                                 id,
                               }) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {
    item,
    newPosts,
    handleSetViewedPosts,
    handleActionClick,
  } = useContext(ServerContext)
  const handleRequiredLogin =
    useRequiredLogin()

  const shareUrl =
    eventModel.getLinkToShare(item)
  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  // let _timetables = [];
  const _timetables = useMemo(() => {
    let _timetables = []
    _.get(
      item,
      'timetables',
      []
    ).forEach(function (
      arrayItem,
      index
    ) {
      _timetables.push(arrayItem)
      if (
        _.get(item, 'timetables', [])
          .length -
        2 ===
        index
      ) {
        _timetables.push({
          isInsert: true,
          active: true,
        })
      } else if (
        _.get(item, 'timetables', [])
          .length -
        2 >
        index
      ) {
        _timetables.push({
          isInsert: true,
        })
      }
    })

    return _timetables
  }, [item])

  const initialValues = {
    invisible_timeline:
      item.invisible_timeline || 0,
    update: Date.now(),
    timetables: _timetables.map(
      (el) => {
        return {
          ...el,
          speakers: _.get(
            el,
            'speakers',
            []
          ).map((ell) => ({
            _type: _.get(
              ell,
              'speaker._type'
            ),
            id: _.get(
              ell,
              'speaker.id'
            ),
            value:
              _.get(
                ell,
                'speaker_name'
              ) ||
              _.get(
                ell,
                'speaker.name',
                _.get(
                  ell,
                  'speaker.title'
                )
              ),
            avatar: _.get(
              ell,
              'speaker.avatar'
            ),
          })),
        }
      }
    ),
  }

  return (
    <PermissionProvider
      permissions={permissions}>
      <ApiInfoForm
        initialValues={initialValues}
        query={{
          ':id': item.id,
        }}
        onPreSubmit={(values) => {
          let _values = values
          if (!!values.timetables) {
            _values = {
              ..._values,
              timetables:
                JSON.stringify(
                  values.timetables.filter(
                    (el) =>
                      !!!el.isInsert
                  )
                ),
            }
          }

          return {
            ..._values,
          }
        }}
        apiInfo={event_edit_Api}>
        {({
            isSubmitting,
            dirty,
            isValid,
            values = {},
            validate,
            ...props
          }) => {
          const pastTimetables =
            values.timetables.filter(
              (e) => {
                return (
                  (e.mandatory === 1 &&
                    e.title ===
                    'START') ||
                  moment(
                    e.start_time
                  ).isBefore(
                    moment.now()
                  )
                )
              }
            )

          return (
            <div className="px-3 w-full py-5 space-y-3">
              <div className="flex items-center">
                <div className="flex-1 font-semibold text-sm text-color-300 uppercase">
                  {t('timeline')}
                </div>

                <Switch
                  name={'invisible_timeline'}
                  checkedChildren={t('visible')}
                  unCheckedChildren={
                    <div className="text-color">
                      {t('invisible')}
                    </div>
                  }
                  defaultChecked={!!!values.invisible_timeline}
                  onChange={(
                    checked,
                    event
                  ) => {
                    props.handleChange(
                      createValue(
                        'invisible_timeline',
                        !checked ? 1 : 0
                      )
                    )
                  }}
                />
              </div>

              <div className="flex flex-col px-0 md:px-3">
                <Steps
                  direction="vertical"
                  size="small"
                  current={pastTimetables.length - 1}>
                  {values.timetables.map(
                    (e, i) => {
                      const editable =
                        !e.mandatory

                      if (e.mandatory === 1 && e.title === 'START')
                        return (
                          <Step
                            key={i}
                            title={
                              <div className="font-bold text-primary uppercase">
                                {t('start')}
                              </div>
                            }
                            icon={
                              <FaPlayCircle className="text-primary"/>
                            }
                          />
                        )
                      if (e.mandatory === 1 && e.title === 'FINISH')
                        return (
                          <Step
                            key={i}
                            title={
                              <div className="font-bold text-red-600 uppercase">
                                {t('finish')}
                              </div>
                            }
                            icon={
                              <FaStopCircle className="text-red-600"/>
                            }
                          />
                        )
                      if (e.isInsert && e.active) {
                        return (
                          <Step
                            key={i}
                            title={
                              <div className="w-full rounded-lg background-100 p-3 my-3">
                                <FieldsFactory
                                  formSchema={[
                                    {
                                      type: 'text',
                                      name: 'timetables',
                                      label: t('timetables'),
                                      children:
                                        {
                                          component: TimetableEditor,
                                          withProps:
                                            {
                                              key: JSON.stringify(e),
                                              type: 'text',
                                              name: 'timetables',
                                              item: {
                                                ...e,
                                                milestone: 1,
                                              },
                                              isInsert: true,
                                              itemIndex: i,
                                            },
                                        },
                                    },
                                  ]}
                                />
                              </div>
                            }
                            icon={
                              <FaPlusCircle
                                className="cursor-pointer"
                                onClick={() => {
                                  let _timetableTemp =
                                    values.timetables
                                  _timetableTemp[
                                    i
                                    ] = {
                                    isInsert: true,
                                  }
                                  props.handleChange(
                                    createValue(
                                      'timetables',
                                      _timetableTemp
                                    )
                                  )
                                }}
                              />
                            }
                          />
                        )
                      }
                      if (e.isInsert)
                        return (
                          <Step
                            key={i}
                            title={
                              <div
                                className="flex items-center gap-1 font-medium italic text-sm text-color-400 hover:text-primary cursor-pointer"
                                onClick={() => {
                                  let _timetableTemp = values.timetables
                                  _timetableTemp[i] = {
                                    isInsert: true,
                                    active: true,
                                  }
                                  props.handleChange(createValue('timetables', _timetableTemp))
                                }}>
                                <AiFillCaretLeft/>
                                {t('insert here')}
                              </div>
                            }
                            icon={
                              <FaPlusCircle
                                className="text-blue-500 cursor-pointer"
                                style={{padding: '0.3rem'}}
                              />
                            }
                          />
                        )
                      if (e.isUpdating)
                        return (
                          <Step
                            key={i}
                            title={
                              <div className="w-full rounded-lg background-100 p-3 my-3">
                                <FieldsFactory
                                  formSchema={[
                                    {
                                      type: 'text',
                                      name: 'timetables',
                                      label: t('timetables'),
                                      children:
                                        {
                                          component: TimetableEditor,
                                          withProps:
                                            {
                                              key: JSON.stringify(e),
                                              type: 'text',
                                              name: 'timetables',
                                              item: e,
                                              itemIndex: i,
                                            },
                                        },
                                    },
                                  ]}
                                />
                              </div>
                            }
                            icon={
                              e.milestone === 1 ? (
                                <FaRegDotCircle
                                  onClick={() => {
                                    let _timetableTemp =
                                      values.timetables
                                    _timetableTemp[
                                      i
                                      ] = {
                                      ..._timetableTemp[
                                        i
                                        ],
                                      isUpdating: false,
                                    }
                                    props.handleChange(
                                      createValue(
                                        'timetables',
                                        _timetableTemp
                                      )
                                    )
                                  }}
                                />
                              ) : (
                                <FaCircle
                                  className="cursor-pointer"
                                  onClick={() => {
                                    let _timetableTemp =
                                      values.timetables
                                    _timetableTemp[
                                      i
                                      ] = {
                                      ..._timetableTemp[
                                        i
                                        ],
                                      isUpdating: false,
                                    }
                                    props.handleChange(
                                      createValue(
                                        'timetables',
                                        _timetableTemp
                                      )
                                    )
                                  }}
                                />
                              )
                            }
                          />
                        )

                      return (
                        <Step
                          key={i}
                          title={
                            <div className="flex flex-col space-y-3">
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-2">
                                  <span className="font-bold text-color-000">
                                    {t(e.title)}
                                  </span>
                                  {editable && (
                                    <Avatar
                                      size={25}
                                      className="flex flex-center background-100 hover:background-200 cursor-pointer"
                                      icon={<FiEdit2 className="text-color-400 hover:text-color-100"/>}
                                      onClick={() => {
                                        let _timetableTemp = values.timetables
                                        _timetableTemp[i] =
                                          {
                                            ..._timetableTemp[i],
                                            isUpdating: true,
                                          }
                                        props.handleChange(
                                          createValue(
                                            'timetables',
                                            _timetableTemp
                                          )
                                        )
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="flex items-center gap-2 leading-tight">
                                  {!!e.start_time && (
                                    <div className="text-xs text-color-400 italic">
                                      {moment(e.start_time).format('HH:mm - DD/MM/YYYY')}
                                    </div>
                                  )}
                                  {!!e.finish_time && (
                                    <div className="flex items-center text-xs text-color-400 italic">
                                      <AiOutlineArrowRight className="mr-2"/>
                                      <span>
                                        {moment(e.finish_time).format('HH:mm - DD/MM/YYYY')}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {_.get(e, 'speakers', []).map((eee, index) => (
                                  <div key={index} className="flex flex-col">
                                    <div className="flex items-center space-x-3">
                                      <Avatar
                                        size={25}
                                        className="flex flex-center background-100"
                                        icon={<FaUser className="text-color-400"/>}
                                        src={_.get(eee, 'avatar')}
                                      />
                                      <div className="font-medium text-sm text-color-000 flex-1">
                                        {_.get(eee, 'speaker.name', _.get(eee, 'speaker_name', _.get(eee, 'value')))}
                                      </div>
                                    </div>
                                  </div>
                              ))}
                            </div>
                          }
                          description={
                            <RichEditor
                              key={'timetable_' + i}
                              readOnly
                              editorState={parseRawContent(
                                e.description
                              )}
                            />
                          }
                          icon={
                            e.milestone ===
                            1 ? (
                              <FaRegDotCircle/>
                            ) : (
                              <FaCircle/>
                            )
                          }
                        />
                      )
                    }
                  )}
                </Steps>
              </div>

              <div className="flex-1"/>
              <FormActionBar/>
            </div>
          )
        }}
      </ApiInfoForm>
    </PermissionProvider>
  )
}

const EventDelSetting = ({id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {item} = useContext(
    ServerContext
  )

  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  return (
    <PermissionProvider
      permissions={permissions}>
      <div className="flex flex-col px-3 w-full py-5 space-y-3 pb-16">
        <div className="flex-1">
          {t(
            'something is wrong, want to delete it?'
          )}
        </div>

        <div className="flex flex-center">
          <AsyncAction
            onSuccess={() => {
              Modal.success({
                okText: t('OK'),
                content: t(
                  'successfully deleted'
                ),
              })
              history.replace('/')
            }}
            onError={() =>
              message.error('failure')
            }
            apiInfo={event_delete_Api}
            query={{
              ':id': item.id,
            }}>
            {({
                handleAsyncAction,
                isLoading,
              }) => {
              return (
                <Popconfirm
                  title={t(
                    'are you sure to delete this?'
                  )}
                  onConfirm={() => {
                    handleAsyncAction()
                  }}
                  onCancel={(e) => {
                    notification.open({
                      message:
                        'deletion is ignored',
                    })
                  }}
                  okText={t('yes')}
                  cancelText={t('No')}
                  okButtonProps={{
                    type: 'primary',
                    className:
                      'rounded-md no-shadow no-text-shadow',
                  }}
                  cancelButtonProps={{
                    className:
                      'rounded-md no-shadow no-text-shadow',
                  }}>
                  <Button
                    danger
                    ghost={true}
                    type="primary"
                    className="px-8 rounded-md no-shadow no-text-shadow">
                    {t('delete')}
                  </Button>
                </Popconfirm>
              )
            }}
          </AsyncAction>
        </div>
      </div>
    </PermissionProvider>
  )
}

export default mapParams()(
  nest(
    EventProvider,
    withProps(({item}) => ({
      renderHeader: () => (
        <EventContext.Consumer>
          {({item}) =>
            !!item &&
            !!_.get(item, 'edit') && (
              <Translate>
                {(t) => (
                  <div className="p-2 px-3 mb-2 border-b border-color-50 font-bold flex items-center gap-2">
                    <div className="flex-1">
                      {t('admin')}
                    </div>
                    <Link
                      className="no-underline"
                      to={{
                        pathname: `/event/${item.idname}`,
                      }}>
                      <Button
                        ghost={true}
                        type={'primary'}
                        className="button-rounded-md flex flex-center text-sm font-medium gap-2"
                        icon={<IoHome/>}
                      >
                        {t('back to home')}
                      </Button>
                    </Link>
                  </div>
                )}
              </Translate>
            )
          }
        </EventContext.Consumer>
      ),
      menu: (
        <div className="relative">
          <EventOverviewSetting/>
        </div>
      ),
    }))(ContentLayout),
    () => (
      <UseHook
        hook={useSearchParam}
        deps={['initialCurrent']}>
        {(initialCurrent) => (
          <EventContext.Consumer>
            {({item}) =>
              item && (
                <NavigationContext.Consumer>
                  {({history}) => (
                    <Translate>
                      {(t) => (
                        <ServerContainer
                          key={item.id}
                          initialCurrent={
                            initialCurrent
                          }
                          {...{
                            item,
                            schema:
                            eventSchema,
                            id: item.id,
                            type: item._type,
                            routes:
                            routes,
                            createMenuContext:
                              ({
                                 setCurrent,
                                 handleActionClick,
                               }) => (
                                <Menu
                                  onClick={({
                                              key,
                                            }) => {
                                    switch (
                                      key
                                      ) {
                                      case 'setting':
                                        history.push(
                                          eventModel.getLinkToSetting(
                                            item
                                          )
                                        )
                                        break
                                      default:
                                        handleActionClick(
                                          key
                                        )
                                        break
                                    }
                                  }}>
                                  <Menu.Item
                                    key={
                                      'share'
                                    }>
                                    <UserAddOutlined/>
                                    <span className="font-semibold">
                                      {t(
                                        'invite people'
                                      )}
                                    </span>
                                  </Menu.Item>
                                  {!item.follow_status &&
                                  !item.display_follow_off && (
                                    <Menu.Item
                                      key={
                                        'follow'
                                      }>
                                      <BellOutlined/>
                                      <span className="font-semibold">
                                          {t(
                                            'follow'
                                          )}
                                        </span>
                                    </Menu.Item>
                                  )}
                                  {!!item.follow_status &&
                                  !item.display_follow_off && (
                                    <Menu.Item
                                      key={
                                        'unfollow'
                                      }>
                                      <BellOutlined/>
                                      <span className="font-semibold">
                                          {t(
                                            'unfollow'
                                          )}
                                        </span>
                                    </Menu.Item>
                                  )}
                                  {!!item.join_status && (
                                    <Menu.Item
                                      key={
                                        'leave'
                                      }>
                                      <LogoutOutlined/>
                                      <span className="font-semibold">
                                        {t(
                                          'leave event'
                                        )}
                                      </span>
                                    </Menu.Item>
                                  )}

                                  <Menu.Divider/>
                                  {!!item.edit && (
                                    <Menu.Item
                                      key={
                                        'setting'
                                      }>
                                      <SettingOutlined/>
                                      <span className="font-semibold">
                                        {t(
                                          'setting'
                                        )}
                                      </span>
                                    </Menu.Item>
                                  )}
                                </Menu>
                              ),
                          }}>
                          {/*
                          <LayoutContext.Consumer>
                            {({
                              isLg,
                            }) => {
                              return (
                                <NavigationContext.Consumer>
                                  {({
                                    handleGoBack,
                                  }) => {
                                    return (
                                      <MainPageHeader
                                        title={
                                          <>
                                            {!isLg && (
                                              <div className="pl-3">
                                                <Count
                                                  onClick={() =>
                                                    handleGoBack()
                                                  }>
                                                  <VscArrowLeft />
                                                </Count>
                                              </div>
                                            )}
                                            <div
                                              className="flex items-center truncate flex-1 pl-3"
                                              onClick={
                                                preventParentEvent
                                              }>
                                              <div className="capitalize text-lg leading-tight flex items-center gap-2">
                                                {item.icon || (
                                                    <IoIosCalendar />
                                                )}
                                                <span>{t(item.title)}</span>
                                              </div>
                                            </div>
                                          </>
                                        }
                                      />
                                    )
                                  }}
                                </NavigationContext.Consumer>
                              )
                            }}
                          </LayoutContext.Consumer>
*/}
                          <div className="flex flex-col space-y-3">
                            <EventSetting
                              id={
                                item.id
                              }
                            />
                            <Divider/>
                            <EventTimetableSetting
                              id={
                                item.id
                              }
                            />
                            <Divider/>
                            <EventDelSetting
                              id={
                                item.id
                              }
                            />
                          </div>
                        </ServerContainer>
                      )}
                    </Translate>
                  )}
                </NavigationContext.Consumer>
              )
            }
          </EventContext.Consumer>
        )}
      </UseHook>
    )
  )
)
