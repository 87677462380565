import React from "react";

export const Culture = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.981 511.981"
		>
			<path
				fill="#a4ccff"
				d="M496.087 303.762l-43.544-119.638c-8.451-23.218-34.124-35.19-57.343-26.739l-155.726 56.679v83.226H176.33l46.661 103.043c13.17 36.186 38.849 58.346 75.333 70.667l82 31.333 47.122-2.405a59.504 59.504 0 0014.968-16.56l42.824-70.321a126.76 126.76 0 0010.849-109.285zm-137.895-22.189c3.54-2.863 8.75-2.314 11.613 1.226 5.282 6.516 14.427 10.016 23.871 9.258 9.443-.815 17.847-5.879 21.935-13.21 2.218-4 7.266-5.419 11.226-3.194 3.984 2.218 5.419 7.242 3.194 11.226-6.823 12.25-19.879 20.331-34.935 21.629a49.043 49.043 0 01-4.193.185c-13.484 0-25.944-5.629-33.936-15.508-2.872-3.54-2.323-8.741 1.225-11.612zm-61.951 62.919c-8.008 5.613-17.194 8.524-26.234 8.524a40.55 40.55 0 01-14.435-2.629 8.256 8.256 0 01-4.782-10.653c1.621-4.266 6.419-6.419 10.653-4.782 7.847 2.992 17.548 1.46 25.306-3.976 7.75-5.443 12.476-14.04 12.323-22.427-.081-4.565 3.548-8.331 8.113-8.411 4.605-.032 8.323 3.548 8.403 8.113.249 14.007-6.984 27.556-19.347 36.241zm129.233 48.926c-16.25.191-34.024 3.383-51.861 9.875-17.805 6.48-33.448 15.439-46.007 25.714-5.347 4.374-13.587.483-13.41-6.423.688-26.758 17.438-51.622 44.114-61.331 26.508-9.648 55.127-1.534 72.88 18.247 4.716 5.254 1.343 13.835-5.716 13.918z"
			></path>
			<path
				fill="#739ad6"
				d="M302.441 286.692a8.228 8.228 0 01-2.484 1.431c-38.385 13.986-50.625 39.447-50.75 39.7-1.929 4.124-6.849 5.915-10.974 3.997a8.261 8.261 0 01-3.997-10.974c.601-1.304 15.316-31.969 60.072-48.238a8.247 8.247 0 0110.582 4.933 8.257 8.257 0 01-2.449 9.151zM353.184 268.218a8.256 8.256 0 01-7.758-5.435c-1.565-4.282.645-9.024 4.935-10.581 44.726-16.282 75.734-2.258 77.024-1.653a8.262 8.262 0 013.992 10.976c-1.919 4.105-6.815 5.911-10.935 4.008-1.153-.524-26.54-11.597-64.435 2.185-.928.339-1.887.5-2.823.5z"
			></path>
			<path
				fill="#8bb3ea"
				d="M302.698 457.797a99.098 99.098 0 01-61.415-59.995L204.7 297.29h-28.37l39.484 108.482a126.762 126.762 0 0078.56 76.744l78.005 26.342c19.11 6.453 39.684 2.634 55.058-8.94z"
			></path>
			<path
				fill="#ff8086"
				d="M312.973 74.118L116.781 2.71A44.584 44.584 0 0098.497.1L31.657 151c-13.171 36.185-12.695 72.444 7.333 105.333l78.494 100.915a59.536 59.536 0 0022.117-3.064l78.007-26.342a126.761 126.761 0 0078.559-76.743l43.545-119.637c8.45-23.22-3.521-48.893-26.739-57.344zM78.2 138.782a8.255 8.255 0 011.839-11.532c11.347-8.226 26.597-10.065 40.798-4.887 14.21 5.169 24.718 16.371 28.113 29.976 1.113 4.427-1.581 8.903-6.008 10.008a8.217 8.217 0 01-2.008.25 8.247 8.247 0 01-8-6.258c-2.04-8.145-8.839-15.218-17.742-18.46-8.911-3.234-18.661-2.185-25.46 2.742a8.251 8.251 0 01-11.532-1.839zm122.847 119.617c-17.752 19.781-46.372 27.896-72.879 18.247-26.677-9.71-43.427-34.574-44.115-61.331-.177-6.906 8.063-10.798 13.41-6.423 12.558 10.274 28.202 19.233 46.006 25.713 17.838 6.492 35.613 9.685 51.861 9.876 7.058.084 10.431 8.664 5.717 13.918zm54.016-55.254a8.28 8.28 0 01-2.016.25c-3.693 0-7.064-2.5-8-6.25-2.048-8.145-8.847-15.218-17.742-18.46-8.919-3.234-18.661-2.177-25.46 2.742a8.252 8.252 0 01-11.532-1.839 8.255 8.255 0 011.839-11.532c11.347-8.226 26.597-10.064 40.798-4.887 14.194 5.161 24.702 16.363 28.113 29.96 1.113 4.42-1.572 8.912-6 10.016z"
			></path>
			<path
				fill="#db4655"
				d="M158.797 113.549a8.24 8.24 0 01-2.823-.5c-38.395-13.96-64.137-2.323-64.395-2.21-4.129 1.919-9.048.129-10.976-3.992a8.262 8.262 0 013.992-10.976c1.298-.613 32.282-14.645 77.024 1.661a8.246 8.246 0 014.935 10.581 8.253 8.253 0 01-7.757 5.436zM270.273 177.92c-3.105 0-6.073-1.75-7.476-4.734-.581-1.21-12.935-25.968-50.766-39.734a8.246 8.246 0 01-4.935-10.581c1.556-4.282 6.323-6.484 10.581-4.935 44.742 16.282 59.468 46.935 60.073 48.234a8.262 8.262 0 01-7.477 11.75z"
			></path>
			<path
				fill="#e5646e"
				d="M49.01 244.787a99.093 99.093 0 01-8.482-85.435L98.497.1c-17.125 1.162-32.81 12.182-39.059 29.35L15.894 149.087a126.762 126.762 0 0010.851 109.287l42.823 70.32c10.491 17.227 28.7 27.534 47.917 28.55z"
			></path>
		</svg>
	);
}

export default Culture;
