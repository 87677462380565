import {
  Form,
  InputNumber,
  Radio,
} from 'antd'
import { search_lookkup_getByType_Api } from 'apis'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  deepTranslate,
  Null,
} from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import FormListHeader from 'views/Wishare/custom/FormListHeader'
import { AsyncCreatableDataSource } from 'views/Wishare/custom/SelectDataSource'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from 'views/Wishare/wishareFieldTypes'
import '../../../Shared/styles/FormList.css'

const formFields = {
  item_name: {
    name: 'item_name',
    placeholder: 'select item',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'item_name' },
    }),
  },
  option_qtty: {
    name: 'option_qtty',
    placeholder: 'amount',
    getStep: (type) =>
      _.get(
        wishareFieldTypes.type_cds,
        `${type}.step`,
        1
      ),
  },
  option_value: {
    name: 'option_value',
    placeholder: 'value',
    getStep: (type) =>
      _.get(
        wishareFieldTypes.type_cds,
        `${type}.step`,
        1
      ),
  },
  unit_name: {
    name: 'unit_name',
    placeholder: 'select unit',
    createAsyncParams: ({
      group_type,
    }) => ({
      apiInfo:
        search_lookkup_getByType_Api,
      values: { group_type },
      query: { ':type': 'unit_name' },
    }),
  },
}

export const EditDonationOptionItemContext =
  React.createContext({})

export const EditDonationOptionItemProvider =
  ({
    type_cd,
    type_cds,
    children,
    optionName,
    getGroup = () => [],
    ...props
  }) => {
    const t = useTranslate()

    const options = useMemo(() => {
      const source = Array.from(
        type_cds || []
      ).find(
        ({ value }) => value === type_cd
      )
      const result = getGroup(source)
      return result.map(
        deepTranslate(t)
      )
    }, [t, getGroup, type_cd, type_cds])

    const defaultType = useMemo(
      () =>
        getDefaultFieldValue(options),
      [options]
    )

    const groupBy = useMemo(() => {
      return {
        options,
        defaultType,
      }
    }, [options, defaultType])

    const fromGroup = useCallback(
      (type) => {
        const source =
          wishareFieldTypes.type_cds
        switch (type) {
          case source.person.value:
            return {
              fieldNames: [
                formFields.option_qtty
                  .name,
                formFields.unit_name
                  .name,
                formFields.option_value
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
            }
          case source.goods.value:
            return {
              fieldNames: [
                formFields.item_name
                  .name,
                formFields.option_qtty
                  .name,
                formFields.unit_name
                  .name,
                formFields.option_value
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
            }
          case source.money.value:
            return {
              fieldNames: [
                formFields.option_qtty
                  .name,
                formFields.unit_name
                  .name,
                formFields.option_value
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
              resourceParams: {
                creatable: false,
              },
            }
          case source.blood.value:
            return {
              fieldNames: [
                formFields.option_qtty
                  .name,
                formFields.unit_name
                  .name,
                formFields.option_value
                  .name,
              ],
              defaultValues: {
                unit_name: undefined,
              },
              resourceParams: {
                creatable: false,
              },
            }
          default:
            return {
              fieldNames: [],
            }
        }
      },
      []
    )

    return (
      <EditDonationOptionItemContext.Provider
        value={{
          ...props,
          groupBy,
          type_cd,
          type_cds,
          fromGroup,
        }}>
        <AntdConfigWrapper>
          {children}
        </AntdConfigWrapper>
      </EditDonationOptionItemContext.Provider>
    )
  }

const DefaultValueInput = ({
  disabled,
  step = 1,
  value = 0,
  placeholder,
  onChange = Null,
  ...props
}) => {
  const t = useTranslate()
  return (
    <InputNumber
      min={0}
      value={value}
      step={step}
      style={{
        padding: '3px 0',
        borderColor:
          'inherit!important',
      }}
      disabled={disabled}
      onChange={onChange}
      parser={parseNumber}
      className="w-full rounded FormList"
      formatter={formatterNumber}
      placeholder={t(placeholder)}
      {...props}
    />
  )
}

const GroupWrapper = ({
  index,
  options,
  children,
  onRemove,
  defaultValue,
  onInit = Null,
  onChange = Null,
  prefixTitle = 'group',
}) => {
  const {
    item_name,
    unit_name,
    target_unit_name,
  } = defaultValue || {}

  const defaultType = _.get(
    defaultValue,
    'type_cd'
  )

  useEffect(() => {
    onInit()
  }, [])

  return (
    <div className="p-2 border border-color-50 rounded-lg space-y-3 background">
      <FormListHeader
        className="items-start"
        title={
          <div className="flex flex-1 gap-2 items-center">
            <Radio.Group
              name="type_cd"
              options={options}
              buttonStyle="solid"
              optionType="button"
              onChange={onChange}
              defaultValue={defaultType}
            />
          </div>
        }
        onRemove={onRemove}
      />
      {_.isFunction(children)
        ? children(defaultType)
        : children}
    </div>
  )
}

export const EditDonationOptionItemFields =
  ({
    index,
    remove,
    onChange,
    ...props
  }) => {
    const t = useTranslate()

    const current =
      _.get(props, 'value') || {}

    const {
      groupBy,
      prefixTitle,
      fromGroup = Null,
      target_unit_name,
    } = useContext(
      EditDonationOptionItemContext
    )

    const { options, defaultType } =
      groupBy || {}

    const {
      item_name,
      unit_name,
      option_qtty,
      option_value,
      type_cd = defaultType,
    } = current

    const triggerChanges = useCallback(
      (values = {}) => {
        onChange({
          ...current,
          ...values,
        })
      },
      [onChange, current]
    )

    const invalidField = useCallback(
      (name, group_type) => {
        const {
          fieldNames = [],
          resourceParams,
        } = fromGroup(group_type) || {}
        return [
          !_.includes(fieldNames, name),
          resourceParams,
        ]
      },
      [fromGroup]
    )

    const renderResouceField =
      useCallback(
        (name, value, group_type) => {
          const [
            invalid,
            resourceParams = {},
          ] = invalidField(
            name,
            group_type
          )
          if (invalid) {
            return null
          }
          const {
            createAsyncParams = Null,
            ...rest
          } =
            _.get(formFields, name) ||
            {}

          const params =
            createAsyncParams({
              group_type,
            }) || {}

          return (
            <FlexCol className="GridFlex RequiredInp">
              <Form.Item
                name={[index, name]}
                rules={[
                  {
                    required: true,
                    message: t(
                      'required field'
                    ),
                  },
                ]}>
                <AsyncCreatableDataSource
                  key={JSON.stringify([
                    name,
                    group_type,
                  ])}
                  fieldValue="name"
                  menuPlacement="top"
                  {...rest}
                  {...params}
                  {...resourceParams}
                  value={value}
                  onChange={(value) =>
                    triggerChanges({
                      [name]: value,
                    })
                  }
                />
              </Form.Item>
            </FlexCol>
          )
        },
        [
          t,
          index,
          invalidField,
          triggerChanges,
        ]
      )

    const renderInputField =
      useCallback(
        ({
          name,
          value,
          group_type,
          ...rest
        }) => {
          const [invalid] =
            invalidField(
              name,
              group_type
            )
          if (invalid) {
            return null
          }
          const { getStep = Null } =
            _.get(formFields, name) ||
            {}
          const step =
            getStep(group_type)
          return (
            <FlexCol className="GridFlex RequiredInp">
              <Form.Item
                name={[index, name]}
                required={true}
                requiredMark={true}
                rules={[
                  {
                    min: 1,
                    required: true,
                    type: 'number',
                    message: t(
                      'required field'
                    ),
                  },
                ]}>
                <DefaultValueInput
                  key={JSON.stringify([
                    name,
                    group_type,
                  ])}
                  step={step}
                  value={value}
                  onChange={(value) => {
                    triggerChanges({
                      [name]:
                        Number(value),
                    })
                  }}
                  {...rest}
                />
              </Form.Item>
            </FlexCol>
          )
        },
        [
          t,
          index,
          invalidField,
          triggerChanges,
        ]
      )

    const resetParams = {
      item_name: undefined,
      unit_name: undefined,
      option_qtty: undefined,
      option_value: undefined,
    }

    const orderFields = useCallback(
      (hideValueField) =>
        ([
          render_value_field,
          render_quantity_field,
          render_unit_field,
        ]) => {
          if (hideValueField) {
            return (
              <React.Fragment>
                {render_quantity_field(
                  true
                )}
                {render_unit_field(
                  true
                )}
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                {render_quantity_field()}
                {render_unit_field()}
                {render_value_field()}
              </React.Fragment>
            )
          }
        },
      []
    )

    return (
      <GroupWrapper
        index={index}
        options={options}
        onRemove={remove}
        defaultValue={{
          ...current,
          type_cd,
          item_name,
          target_unit_name,
        }}
        onInit={() => {
          triggerChanges({
            type_cd,
          })
        }}
        prefixTitle={prefixTitle}
        onChange={(event) => {
          const { name, value } =
            event.target
          triggerChanges({
            ...resetParams,
            [name]: value,
          })
        }}>
        {(group_type) => (
          <div className="CustomGrid grid md:grid-cols-2 items-start gap-2">
            {renderResouceField(
              formFields.item_name.name,
              item_name,
              group_type
            )}
            {orderFields(
              Boolean(
                target_unit_name ===
                  unit_name &&
                  type_cd ===
                    wishareFieldTypes
                      .type_cds.money
                      .value
              )
            )([
              () =>
                renderInputField({
                  name: formFields
                    .option_value.name,
                  value: option_value,
                  group_type,
                  addonBefore:
                    t('value'),
                  addonAfter:
                    target_unit_name,
                  style: {
                    borderRadius:
                      '0!important',
                  },
                  className:
                    'Inp w-full',
                }),
              (hideValue) =>
                renderInputField({
                  name: formFields
                    .option_qtty.name,
                  value: option_qtty,
                  group_type,
                  addonBefore: t(
                    hideValue
                      ? 'value'
                      : 'quantity'
                  ),
                  style: {
                    borderRadius:
                      '0!important',
                  },
                  className:
                    'Inp w-full',
                }),
              () =>
                renderResouceField(
                  formFields.unit_name
                    .name,
                  unit_name,
                  group_type
                ),
            ])}
          </div>
        )}
      </GroupWrapper>
    )
  }

export default EditDonationOptionItemFields
