import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import { defaultRawContent } from './RichEditor'

export const parseRawContent = (
  string
) => {
  try {
    const raw = !!string
      ? JSON.parse(string)
      : defaultRawContent
    return EditorState.createWithContent(
      convertFromRaw(raw)
    )
  } catch (error) {
    console.error(error)
    return EditorState.createWithContent(
      convertFromRaw(defaultRawContent)
    )
  }
}

export const isRawContentEmpty = (
  string
) => {
  try {
    const raw = !!string
      ? JSON.parse(string)
      : defaultRawContent
    if (
      raw.blocks &&
      raw.blocks.length === 1
    ) {
      if (!!!raw.blocks[0].text) {
        return true
      }
    }
  } catch (error) {
    return true
  }

  return false
}
