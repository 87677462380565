import React, {useContext} from 'react';
import {
  env,
  GOOGLE_DATA_AD_CLIENT,
  GOOGLE_DATA_AD_ENABLED,
  GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT,
  GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT,
  GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT
} from "../../envs/_current/config";
import ErrorBoundary from "../error/ErrorBoundary";
import {LayoutContext} from "../layouts/Default/LayoutContext";

class GoogleAds extends React.PureComponent {

  componentDidMount() {
    if (GOOGLE_DATA_AD_ENABLED) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    if (env === 'DEV') {
      return (
        <div className="text-color-300 font-bold w-full text-2xl items-center justify-center text-center p-3 border">
          FOR ADVERTISING
        </div>
      )
    }

    return (
      <ins className='adsbygoogle'
           style={{display: 'block', ...this.props.style}}
           data-ad-client={GOOGLE_DATA_AD_CLIENT}
           data-ad-slot={this.props.slot}
           {
             ...(!!this.props.dataAdLayoutKey ? {"data-ad-layout-key": this.props.dataAdLayoutKey} : {})
           }
           {
             ...(!!this.props.dataAdLayout ? {"data-ad-layout": this.props.dataAdLayout} : {})
           }
           data-ad-format={this.props.format}
           data-full-width-responsive="true"
      >
      </ins>
    );
  }
}

export default GoogleAds


export const GoogleAdsRecommendedUnit = props => {
  return (
    <ErrorBoundary>
      <div
        className="py-1 items-center justify-center relative">
        <GoogleAds slot={GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT} format="auto" {...props} />
      </div>
    </ErrorBoundary>
  )
}

export const GoogleAdsRecommendedUnitHorizontal = props => {
  return (
    <GoogleAdsRecommendedUnit format="horizontal" {...props} />
  )
}

export const GoogleAdsRecommendedUnitHorizontalMob = props => {
  const {isSm} = useContext(LayoutContext);

  return !!isSm ? (
    <GoogleAdsRecommendedUnitHorizontal {...props} />
  ) : null
}

export const GoogleAdsInFeedUnit = props => {
  return (
    <ErrorBoundary>
      <div className="p-1 items-center justify-center relative">
        <GoogleAds slot={GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT}
                   format="fluid"
                   style={{display: 'block'}}
                   dataAdLayoutKey="-fb+5w+4e-db+86"
                   {...props} />
      </div>
    </ErrorBoundary>
  )
}

export const GoogleAdsInArticleUnit = props => {
  return (
    <ErrorBoundary>
      <div className="p-1 items-center justify-center self-center relative">
        <GoogleAds slot={GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT}
                   dataAdLayout="in-article"
                   format="fluid"
                   style={{display: 'block', textAlign: 'center'}}
                   {...props} />
      </div>
    </ErrorBoundary>
  )
}