import {
  Avatar,
  Button,
  List,
} from 'antd'
import { getId } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import {
  compose,
  mapProps,
} from 'recompose'
import {
  getLocationLines,
  Null,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import GivingBeneficiarySelector from './GivingBeneficiarySelector'

const BeneficiaryCreator = ({
  keyword,
  beneficiary_name,
  onCreate = Null,
}) => {
  const t = useTranslate()

  return [keyword, beneficiary_name]
    .filter(
      (value) => !_.isEmpty(value)
    )
    .map((value, index) => (
      <div
        key={index}
        className="flex items-center border background rounded-lg p-3">
        <div className="font-medium text-color-000 italic">
          {`"${value}"`}
        </div>
        <div className="flex-1" />
        <span className="text-xs font-light text-color-500 italic mr-2">
          {t(
            'Click here to create a new one'
          )}
        </span>
        <Button
          type="primary"
          size="small"
          className="no-text-shadow no-shadow rounded-md text-xs font-medium"
          onClick={() => {
            onCreate(value)
          }}>
          {t('create')}
        </Button>
      </div>
    ))
}

const renderBeneficiary = (
  beneficiary_name
) => {
  if (_.isEmpty(beneficiary_name)) {
    return (
      <div className="SearchBeneficiaryInput w-full">
        <Translate>
          {(t) => (
            <div className="flex items-center gap-2">
              <LayoutContext.Consumer>
                {({ isLg }) =>
                  isLg && (
                    <Avatar
                      icon={
                        <IoPerson size={14} className="text-orange-500" />
                      }
                      className="flex flex-center bg-orange-200"
                    />
                  )
                }
              </LayoutContext.Consumer>
              <div className="flex flex-col">
                <span className="font-medium text-sm text-orange-600">
                  {t(
                    'Click here to find beneficiary information'
                  )}
                </span>
                <span className="font-light text-xs italic text-orange-500">
                  {t(
                    'You can find a beneficiary or add another beneficiary'
                  )}
                </span>
              </div>
            </div>
          )}
        </Translate>
      </div>
    )
  }

  return (
    <List.Item.Meta
      title={
        <span className="text-color-000 font-semibold leading-tight flex items-center">
          {beneficiary_name}
        </span>
      }
      className="items-center px-2"
      avatar={
        <Avatar
          className="flex items-center justify-center background-100"
          icon={
            <IoPerson className="text-color-300" />
          }
        />
      }
      description={
        <Translate>
          {(t) => (
            <span className="text-xs text-secondary italic">
              {t('new beneficiary')}
            </span>
          )}
        </Translate>
      }
    />
  )
}

const BeneficiaryFormField = ({
  name,
  value,
  alias_name,
  giving_event_id,
  organization_id,
  setValues = Null,
  readOnly = false,
  onChange = Null,
  MetaHeader = Null,
  Wrapper = renderOwnChild,
}) => {
  const [current, setCurrent] =
    useState()
  return (
    <Wrapper>
      <MetaHeader />
      <GivingBeneficiarySelector
        name="beneficiary"
        readOnly={readOnly}
        defaultValue={value}
        renderBeneficiary={(
          beneficiary,
          child
        ) =>
          !_.isEmpty(beneficiary)
            ? child
            : renderBeneficiary(current)
        }
        Header={({
          keyword,
          onCancel = Null,
        }) => (
          <BeneficiaryCreator
            keyword={keyword}
            alias_name={alias_name}
            onCreate={(
              beneficiary_name
            ) => {
              setCurrent(
                beneficiary_name
              )
              setValues({
                location: [, , ,],
                beneficiary_name,
                beneficiary: undefined,
                beneficiary_id:
                  undefined,
                beneficiary_type:
                  undefined,
                // beneficiary_country_id:
                //   undefined,
                // beneficiary_adress:
                //   undefined,
                // beneficiary_province_id:
                //   undefined,
                // beneficiary_district_id:
                //   undefined,
                // beneficiary_ward_id:
                //   undefined,
              })
              onCancel()
            }}
          />
        )}
        onChange={(beneficiary) => {
          setValues({
            beneficiary,
            beneficiary_name: undefined,
          })
        }}
        organization_id={
          organization_id
        }
        giving_event_id={
          giving_event_id
        }
      />
    </Wrapper>
  )
}

const ExtraInformation = () => {
  const t = useTranslate()

  const { values } = useFormikContext()

  const {
    alias,
    alias_name,
    beneficiary,
    beneficiary_name,
    beneficiary_address,
    beneficiary_country,
    beneficiary_province,
    beneficiary_district,
    beneficiary_ward,
  } = values || {}

  const dependencies = {
    beneficiary_address,
    beneficiary_country,
    beneficiary_province,
    beneficiary_district,
    beneficiary_ward,
  }

  const renderMeta = useCallback(
    ({ label, value }, index) => (
      <React.Fragment key={index}>
        {!!value && (
          <div className="flex items-center">
            <span className="text-xs text-color-500 italic font-light">
              {`${t(label)} : `}
            </span>
            <span className="text-sm font-medium text-color-000 ml-2">
              {value}
            </span>
          </div>
        )}
      </React.Fragment>
    ),
    [t]
  )

  const showMetaInfo =
    !!alias &&
    _.isEmpty(beneficiary_name) &&
    _.isEmpty(getId(beneficiary)) &&
    !_.isEmpty(alias_name)

  const meta = useMemo(() => {
    // const getLocationName = (value) =>
    //   renderElse(
    //     _.isEmpty(value),
    //     _.get(value, 'location_name')
    //   )
    if (showMetaInfo) {
      return [
        // {
        //   name: 'beneficiary_country',
        //   label: 'country',
        //   transform: getLocationName,
        // },
        {
          name: 'beneficiary_province',
          label: 'location',
          transform: (value, values) =>
            getLocationLines({
              locations: [
                {
                  province: value,
                  district: _.get(
                    values,
                    'beneficiary_district'
                  ),
                  ward: _.get(
                    values,
                    'beneficiary_ward'
                  ),
                  country: _.get(
                    values,
                    'beneficiary_country'
                  ),
                },
              ],
            }),
        },
        {
          name: 'beneficiary_address',
          label: 'address',
        },
      ]
    } else {
      return []
    }
  }, [t, showMetaInfo])

  return (
    <Pure
      input={[
        meta,
        alias_name,
        dependencies,
      ]}>
      <div className="mb-2 space-y-1">
        {!_.isEmpty(alias_name) && (
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-500 italic font-light">
              {t('receiver name')}
              {': '}
            </span>
            <span className="text-sm font-medium text-color-000">
              {alias_name}
            </span>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <Pure input={[meta]}>
            {meta.map(
              (
                {
                  name,
                  label,
                  transform = renderSelf,
                },
                index
              ) => {
                const _value =
                  transform(
                    _.get(
                      dependencies,
                      name
                    ),
                    dependencies
                  )
                return _value
                  ? renderMeta(
                      {
                        label,
                        value: _value,
                      },
                      index
                    )
                  : null
              }
            )}
          </Pure>
        </div>
      </div>
    </Pure>
  )
}

export default compose(
  mapProps(
    ({
      name,
      value,
      form,
      onChange,
      readOnly,
      alias_name,
      root_owner,
      withBeneficiary = (item) => {},
    }) => {
      const {
        values,
        setValues = Null,
      } = form || {}
      return {
        name,
        value,
        onChange,
        readOnly,
        alias_name,
        giving_event_id: getId(values),
        organization_id:
          getId(root_owner),
        setValues: ({
          beneficiary,
          ...rest
        }) => {
          setValues({
            ...values,
            ...rest,
            ...(withBeneficiary(
              beneficiary || {}
            ) || {}),
            beneficiary,
          })
        },
        MetaHeader: ExtraInformation,
      }
    }
  )
)(BeneficiaryFormField)
