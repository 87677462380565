import { Avatar, Button } from 'antd'
import {
  beneficiary_getBeneficiariesOfTheSameOwner_Api,
  donor_getDonorsOfTheSameOwner_Api,
  staff_getStaffsOfTheSameOwner_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useCallback,
  useContext,
} from 'react'
import renderLoading from 'views/MainPage/functions/renderPagingLoading'
import { renderIf } from 'views/Shared'
import { wisharePaths } from 'views/Wishare/configs'
import EntityList from 'views/Wishare/EntityList'
import { WishareEntities } from 'views/Wishare/enums'
import createStickySider from 'views/Wishare/factory/createStickySider'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { OrganizationProfilesContext } from './OrganizationProfiles'

const renderSiderItem =
  ({
    organization_id,
    isSelected = (item) => false,
  }) =>
  (item) => {
    if (_.isEmpty(item)) return null

    const isCurrent = !!isSelected(item)

    let classSelected
    let textColor
    let bgAvatar

    switch (_.get(item, '_type')) {
      case 'donor':
        classSelected = 'bg-primary-50 border-primary'
        textColor = 'text-primary'
        bgAvatar = 'bg-primary'
        break
      case 'beneficiary':
        classSelected = 'bg-secondary-50 border-secondary'
        textColor = 'text-secondary'
        bgAvatar = 'bg-secondary'
        break
      case 'staff':
        classSelected = 'bg-green-100 border-green-600'
        textColor = 'text-green-600'
        bgAvatar = 'bg-green-600'
        break
      default:
        classSelected = 'border-color-50'
        textColor = 'text-color-100'
        bgAvatar = 'background-200'
    }

    return (
      <NavigationContext.Consumer>
        {({ history }) => (
          <div
            onClick={
              isCurrent
                ? undefined
                : () => {
                    history.push({
                      pathname:
                        wisharePaths.organizationProfiles
                          .replace(
                            ':sub_type',
                            getType(
                              item
                            )
                          )
                          .replace(
                            ':sub_id',
                            getId(item)
                          )
                          .replace(
                            ':id',
                            organization_id
                          ),
                    })
                  }
            }
            className={classNames(
              'relative w-full border rounded-lg',
              isCurrent
                ? classSelected
                : 'background border-color-50 hover:background-50 cursor-pointer'
            )}>
            <div
              key={getId(item)}
              className="flex items-center p-3 gap-3">
              <LayoutContext.Consumer>
                {({ isSm }) =>
                  !!!isSm && (
                    <Avatar
                      size={35}
                      src={getAvatar(
                        item
                      )}
                      className={classNames('flex flex-center', isCurrent ? bgAvatar : 'background-200')}>
                      <span className={classNames('uppercase font-medium', isCurrent ? 'text-white' : 'text-color-200')}>
                        {_.first(getTitle(item))}
                      </span>
                    </Avatar>
                  )
                }
              </LayoutContext.Consumer>
              <div
                className={classNames(
                  'flex flex-col flex-1',
                    isCurrent ? textColor : 'text-color-100'
                )}>
                <div className="font-semibold leading-tight max-lines-1 no-underline">
                  {getTitle(item)}
                </div>
              </div>
            </div>
          </div>
        )}
      </NavigationContext.Consumer>
    )
  }

const GroupItem = ({ type, title }) => {
  const t = useTranslate()

  const {
    item,
    isLoading,
    organization_id,
    id: profile_id,
    type: profile_type,
  } = useContext(
    OrganizationProfilesContext
  )

  const [owner_id, owner_type] = [
    _.get(item, 'owner.id'),
    _.get(item, 'owner._type'),
  ]

  let params = {
    sample: 2,
    auto: false,
    key: undefined,
    query: bindQueryParam({
      organization_id,
    }),
    RenderEntity: React.Fragment,
    renderItem: renderSiderItem({
      organization_id,
      isSelected: (e) =>
        getType(e) === profile_type &&
        getId(e) === profile_id,
    }),
  }

  const withKey = useCallback(
    (path) =>
      [
        path.replace(
          ':organization_id',
          organization_id
        ),
        getId(item),
        owner_id,
        owner_type,
      ].join('/'),
    [
      item,
      owner_id,
      owner_type,
      organization_id,
    ]
  )

  switch (type) {
    case WishareEntities.DONOR:
      params = {
        ...params,
        donor_id: getId(item),
        key: withKey(
          donor_getDonorsOfTheSameOwner_Api.path
        ),
        apiInfo:
          donor_getDonorsOfTheSameOwner_Api,
        values: {
          owner_id,
          owner_type,
          donor_id: getId(item),
        },
      }
      break
    case WishareEntities.STAFF:
      params = {
        ...params,
        staff_id: getId(item),
        key: withKey(
          staff_getStaffsOfTheSameOwner_Api.path
        ),
        apiInfo:
          staff_getStaffsOfTheSameOwner_Api,
        values: {
          owner_id,
          owner_type,
          staff_id: getId(item),
        },
      }
      break
    case WishareEntities.BENEFICIARY:
      params = {
        ...params,
        beneficiary_id: getId(item),
        key: withKey(
          beneficiary_getBeneficiariesOfTheSameOwner_Api.path
        ),
        apiInfo:
          beneficiary_getBeneficiariesOfTheSameOwner_Api,
        values: {
          owner_id,
          owner_type,
          beneficiary_id: getId(item),
        },
      }
      break
    default:
      break
  }

  if (!!isLoading) {
    return renderLoading()
  }

  if (
    _.every(
      [item, owner_id, owner_type],
      _.isEmpty
    )
  ) {
    return null
  }

  return (
    <div className="space-y-3">
      <EntityList
        {...params}
        renderWrap={(
          child,
          __,
          count
        ) =>
          renderIf(
            count > 0,
            <div className="py-2 space-y-3">
              {title && (
                <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                  {t(title)}
                </div>
              )}
              {child}
            </div>
          )
        }
        renderLoadMore={(
          action,
          __,
          { isLoading }
        ) =>
          !!isLoading ? (
            renderLoading()
          ) : (
            <Button
              type="primary"
              onClick={action}
              loading={!!isLoading}>
              {t('show more')}
            </Button>
          )
        }
      />
    </div>
  )
}

const Group = () => (
  <div className="flex flex-col">
    <GroupItem
      type={WishareEntities.DONOR}
      title="donor list"
    />
    <GroupItem
      type={WishareEntities.STAFF}
      title="staff list"
    />
    <GroupItem
      type={WishareEntities.BENEFICIARY}
      title="beneficiary list"
    />
  </div>
)

const OrganizationProfilesSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(Group)

export default OrganizationProfilesSider
