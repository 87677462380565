import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback,} from 'react'
import {IoBarChartOutline, IoListOutline, IoStopwatchOutline,} from 'react-icons/io5'
import {RiUser2Line, RiUserSearchLine,} from 'react-icons/ri'
import {renderFalse, renderOwnChild,} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {createCheckPermissions, invalidPermissions,} from 'views/Wishare/factory/createCheckPermissions'
import {SettingsMenu} from 'views/Wishare/factory/createSettingLayout'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import WorkspaceRecruitmentEventsList from '../Activities/WorkspaceRecruitmentEventsList'
import {useMenuHandler} from '../useMenuHandler'
import WorkspaceHRRecentCandidates from './WorkspaceHRRecentCandidates'
import WorkspaceHRStaffRosters from './WorkspaceHRStaffRosters'
import WorkspaceHRStatistic from './WorkspaceHRStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  STAFFS: 'staffs',
  RECRUITMENT_EVENTS:
    'recruitment-events',
  STAFF_ROSTER: 'staff-roster',
  RECENT_CANDIDATES:
    'recent-candidates',
  RECRUITMENT_GROUP:
    'recruitment-group',
  HUMAN_RESOURCE_GROUP:
    'human-resource-group',
})

const with_menu_items = ({
                           isDisabled = renderFalse,
                         }) => [
  {
    key: ItemKeys.STATISTIC,
    label: 'insights',
    icon: (
      <IoBarChartOutline size={16}/>
    ),
  },
  {
    key: ItemKeys.RECRUITMENT_GROUP,
    label: 'recruitment campaign',
    icon: (
      <RiUserSearchLine size={16}/>
    ),
    children: [
      {
        key: ItemKeys.RECRUITMENT_EVENTS,
        label: 'campaign list',
        icon: (
          <IoStopwatchOutline
            size={16}
          />
        ),
      },
      {
        key: ItemKeys.RECENT_CANDIDATES,
        label: 'recent candidates',
        icon: (
          <IoStopwatchOutline
            size={16}
          />
        ),
      },
    ],
  },
  {
    key: ItemKeys.HUMAN_RESOURCE_GROUP,
    label: 'human resources',
    icon: <RiUser2Line size={16}/>,
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageStaff,
      ],
    }),
    children: [
      {
        key: ItemKeys.STAFF_ROSTER,
        label: 'staffs list',
        icon: (
          <IoListOutline size={16}/>
        ),
      },
    ].map(({...rest}) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageStaff,
        ],
      }),
    })),
  },
]

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceHRStatistic,
  },
  {
    key: ItemKeys.RECRUITMENT_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageRecruitment,
    ])(WorkspaceRecruitmentEventsList),
  },
  {
    key: ItemKeys.STAFF_ROSTER,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStaff,
    ])(WorkspaceHRStaffRosters),
  },
  {
    key: ItemKeys.RECENT_CANDIDATES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageRecruitment,
    ])(WorkspaceHRRecentCandidates),
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceHumanResourcesContent =
  () => {
    const {
      activeKey,
      Component,
      onMenuSelect,
    } = useMenuHandler({
      routes: routeEntities,
      actionName: Queries.REL,
    })

    const renderMenu = useCallback(
      () => (
        <WishareSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled:
                    invalidPermissions(
                      permissions
                    ),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[
                  activeKey,
                ]}
                defaultOpenKeys={[
                  ItemKeys.RECRUITMENT_GROUP,
                  ItemKeys.HUMAN_RESOURCE_GROUP,
                ]}
              />
            )}
          </PermissionContext.Consumer>
        </WishareSiderMenu>
      ),
      [with_menu_items, activeKey]
    )

    return (
      <DefaultTemplate
        renderMenu={renderMenu}>
        <div className="px-3">
          <Component/>
        </div>
      </DefaultTemplate>
    )
  }

export default WorkspaceHumanResourcesContent
