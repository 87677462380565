import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import RecruitmentContext from '../RecruitmentContext'

const RecruitmentSettingsContent = ({
  Wrapper,
  routes,
}) => {
  const rootPath =
    wisharePaths.recruitmentSettings
  return createSettingContent(
    RecruitmentContext,
    { routes, Wrapper, rootPath }
  )
}

export default RecruitmentSettingsContent
