import { API_ROOT_URL } from 'envs/_current/config'
import { baseFieldTypes } from './base/baseFieldTypes'
import { activitySchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:activities,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  title: baseFieldTypes.string,
  description: baseFieldTypes.string,
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
  privacy: baseFieldTypes.privacy,
}

export const activity_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/activities/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: activitySchema,
  transform: createTransform(
    activitySchema,
    'data'
  ),
}

export const activity_getWidgetById_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/activities/:id/get-widget',
    method: 'GET',
    fields,
    fieldTypes,
    schema: activitySchema,
    transform: createTransform(
      activitySchema,
      'data'
    ),
  }

export const activity_create_api = {
  backend: `Route::post('activities/add', 'ActivityController@createNewActivity')`,
  root: API_ROOT_URL,
  path: '/activities/add',
  method: 'POST',
  fields,
  fieldTypes,
  schema: activitySchema,
  transform: createTransform(
    activitySchema,
    'data'
  ),
}

export const activity_update_api = {
  backend: `Route::post('activities/{activity_id}/update', 'ActivityController@update')`,
  root: API_ROOT_URL,
  path: '/activities/:activity_id/update',
  method: 'POST',
  fields,
  fieldTypes,
  schema: activitySchema,
  transform: createTransform(
    activitySchema,
    'data'
  ),
}

export const activity_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/activities/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: activitySchema,
  transform: createTransform(
    activitySchema,
    'data'
  ),
}

export const activities_getItemsByOwner_gets_api =
  {
    backend: `Route::get('activities/{type}/{id}/get-activities', 'ActivityController@getActivitiesByOwner')`,
    root: API_ROOT_URL,
    path: '/activities/:type/:id/get-activities',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'status',
      'keyword',
      'sort_by',
    ],
    schema: activitySchema,
    transform: createListTransform(
      activitySchema
    ),
  }

export const activities_getPublicItems_gets_api =
  {
    backend: `Route::get('activities/lookup/get-activities', 'ActivityController@getActivitiesByPublic');`,
    root: API_ROOT_URL,
    path: '/activities/lookup/get-activities',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: activitySchema,
    transform: createListTransform(
      activitySchema
    ),
  }

export const activities_fetchOtherActivities_gets_api =
  {
    backend: `Route::get('activities/{activity_id}/get-other-activities', 'ActivityController@fetchOtherActivities');`,
    root: API_ROOT_URL,
    path: '/activities/:id/get-other-activities',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
  }
