import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React from 'react'
import { wishareConfigs } from '../configs'

const DatePreview = ({
  className,
  values = [],
  defaultValue = 'not available',
  dateFormat = wishareConfigs.dateFormat,
}) => {
  const t = useTranslate()
  return (
    <div className={className}>
      {values.map(
        ({ label, value }, index) => {
          const date = value
            ? moment(value).format(
                dateFormat
              )
            : t(defaultValue)
          return (
            <div
              key={index}
              className="flex flex-col text-sm">
              <span className="text-xs text-color-400 font-light">
                {t(label)}
              </span>
              <span className="text-color-000 font-semibold">
                {date}
              </span>
            </div>
          )
        }
      )}
    </div>
  )
}

export default DatePreview
