import { medal_getMedalReceivers_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import _ from 'lodash'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useMemo,
  useState,
} from 'react'
import { IoMedalOutline } from 'react-icons/io5'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { WishareEntities } from 'views/Wishare/enums'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { NameSortBy } from 'views/Wishare/factory/createEntitySortBy'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import { DonorModalTypes } from '../components/DonorActionModal'
import { renderDonor } from '../Donors/renderDonor'
import { RenderDonorActionWrapper } from '../Donors/WorkspaceDonorsList'
import { renderStaff } from '../HumanResources/renderStaff'
import { StaffModalTypes } from '../HumanResources/StaffActionModal'
import { RenderRosterActionWrapper } from '../HumanResources/WorkspaceHRStaffRosters'
import WorkspaceContext from '../WorkspaceContext'
import MedalRewardList, {
  MedalRewardModalHeader,
} from './MedalRewardList'
import useTranslate from '../../../../modules/local/useTranslate'

const MedalRewardsByType = ({
  pagerInfo,
  owner_id,
  owner_type,
  recevier_type,
  Header = Null,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
  withPager = () => ({}),
}) => {
  const history = useHistory()

  const { location = {} } = history

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      recevier_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      recevier_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo,
      withKey: ({ sortBy, keyword }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
        recevier_type,
      ],
      ...withPager(),
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header />
      {pager_widget}
    </Wrapper>
  )
}

const WorkspaceMedalRewardsByType = ({
  type,
  owner_id,
  owner_type,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const t = useTranslate()
  const { organization } = useContext(
    WorkspaceContext
  )
  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const onCancel = () => {
    setAction({})
  }

  const onMenuSelect =
    (staff) => (key) => {
      const staff_id = getId(staff)
      switch (key) {
        case DonorModalTypes.MEDAL_AWARDS:
        case StaffModalTypes.MEDAL_AWARDS:
          setAction({
            type: key,
            value: staff_id,
          })
          break
        default:
          break
      }
    }

  const component = useMemo(() => {
    switch (type) {
      case WishareEntities.DONOR:
        return (
          <MedalRewardsByType
            {...{
              owner_id,
              owner_type,
            }}
            Header={() => (
              <ListHeader title="donor list" />
            )}
            recevier_type={type}
            pagerInfo={{
              apiInfo:
                medal_getMedalReceivers_Api,
              query: {
                id: owner_id,
                receive_type: type,
              },
              renderItem: renderDonor({
                renderContextMenu: (
                  staff
                ) => (
                  <div className="absolute right-0 top-0 px-2 py-1">
                    <ItemContextMenu
                      items={[
                        {
                          key: DonorModalTypes.MEDAL_AWARDS,
                          label:
                            'medals',
                          icon: (
                            <IoMedalOutline
                              size={16}
                              className="text-primary"
                            />
                          ),
                        },
                      ]}
                      onMenuSelect={onMenuSelect(
                        staff
                      )}
                    />
                  </div>
                ),
              }),
              RenderEntity:
                React.Fragment,
            }}
            withPager={() => ({
              renderFilter: Null,
              SortByWidget: NameSortBy,
              AdvancedFilter: Null,
            })}
          />
        )
      case WishareEntities.STAFF:
        return (
          <MedalRewardsByType
            {...{
              owner_id,
              owner_type,
            }}
            Header={() => (
              <ListHeader title="staff list" />
            )}
            recevier_type={type}
            pagerInfo={{
              apiInfo:
                medal_getMedalReceivers_Api,
              query: {
                id: owner_id,
                receive_type: type,
              },
              renderItem: renderStaff({
                renderContextMenu: (
                  staff
                ) => (
                  <div className="absolute right-0 top-0 px-2 py-1">
                    <ItemContextMenu
                      items={[
                        {
                          key: StaffModalTypes.MEDAL_AWARDS,
                          label:
                            'medals',
                          icon: (
                            <IoMedalOutline
                              size={16}
                              className="text-primary"
                            />
                          ),
                        },
                      ]}
                      onMenuSelect={onMenuSelect(
                        staff
                      )}
                    />
                  </div>
                ),
              }),
              RenderEntity:
                React.Fragment,
            }}
            withPager={() => ({
              renderFilter: Null,
              SortByWidget: NameSortBy,
              AdvancedFilter: Null,
            })}
          />
        )
      default:
        return null
    }
  }, [owner_id, owner_type])

  const RenderWrapper = useMemo(() => {
    switch (type) {
      case WishareEntities.STAFF:
        return RenderRosterActionWrapper
      case WishareEntities.DONOR:
        return RenderDonorActionWrapper
      default:
        break
    }
  }, [])

  return (
    <Wrapper>
      {component}
      {action.type && (
        <RenderWrapper action={action}>
          {({
            isLoading,
            initialValues:
              selectedStaff,
          }) => (
            <FullSideModalLayout
              right={true}
              onCancel={onCancel}
              title="medal rewards">
              {!!isLoading ? (
                <LoadingPage />
              ) : (
                <MedalRewardList
                  receiver={
                    selectedStaff
                  }
                  owner_id={owner_id}
                  owner_type={
                    owner_type
                  }
                  Header={
                    MedalRewardModalHeader
                  }
                />
              )}
            </FullSideModalLayout>
          )}
        </RenderWrapper>
      )}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(WorkspaceMedalRewardsByType)
