import {Avatar} from 'antd'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {branch, compose, fromRenderProps,} from 'recompose'
import renderLoading from 'views/MainPage/functions/renderPagingLoading'
import {formatDate, getFullAddress, Null, renderIf, renderOwnChild,} from 'views/Shared'
import {BeneficiaryInformation} from 'views/Wishare/Beneficiary/holders/BeneficiaryInformation'
import {DonorInformation} from 'views/Wishare/Donor/components/DonorInformation'
import {WishareEntities} from 'views/Wishare/enums'
import {OverlayItemBanner} from '../../Templates/ItemTemplate'
import {GenderTypes, getProp,} from '../../wishareFieldTypes'
import {OrganizationProfilesContext} from './OrganizationProfiles'

const DonorInfo = React.memo(() => (
  <OrganizationProfilesContext.Consumer>
    {({ type, item }) =>
      renderIf(
        type === WishareEntities.DONOR,
        <DonorInformation
          donor={item}
        />
      )
    }
  </OrganizationProfilesContext.Consumer>
))

const StaffInfo = React.memo(() => {
  const ItemInformation = ({
    label,
    value,
  }) => (
    <div className="flex items-center gap-1 text-xs">
      <span className="text-xs text-color-500 font-light italic">
        {label} {':'}
      </span>
      <span className="text-sm text-color-000 font-semibold flex-1">
        {value}
      </span>
    </div>
  )

  return (
    <OrganizationProfilesContext.Consumer>
      {({ type, item }) =>
        renderIf(
          type ===
            WishareEntities.STAFF,
          <Translate>
            {(t) => (
              <div className="flex flex-col">
                <div className="uppercase text-sm font-medium text-color-300 tracking-wide mb-2">
                  {t(
                    'staff information'
                  )}
                </div>
                <div className="space-y-3 p-3 border border-color-50 rounded-lg">
                  <div className="relative background-50 rounded-lg">
                    <OverlayItemBanner
                      type={
                        WishareEntities.STAFF
                      }
                    />
                    <div className="h-7" />
                    <div className="flex items-center gap-3 p-3">
                      <Avatar
                        size={40}
                        src={_.get(
                          item,
                          'avatar'
                        )}
                        className="flex flex-center uppercase text-color-300 background-200">
                        {_.first(
                          getTitle(item)
                        )}
                      </Avatar>
                      <div className="flex flex-col">
                        <span className="font-semibold text-color-000">
                          {getTitle(
                            item
                          )}
                        </span>
                        <span className="italic font-light text-xs text-secondary">
                          {t(
                            _.get(
                              item,
                              'owner._type'
                            )
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ItemInformation
                    label={t(
                      'employee type'
                    )}
                    value={t(
                      _.get(
                        item,
                        'employee_type'
                      )
                    )}
                  />
                  {_.get(
                    item,
                    'phone'
                  ) && (
                    <ItemInformation
                      label={t('phone')}
                      value={_.get(
                        item,
                        'phone'
                      )}
                    />
                  )}
                  {_.get(
                    item,
                    'email'
                  ) && (
                    <ItemInformation
                      label={t('email')}
                      value={_.get(
                        item,
                        'email'
                      )}
                    />
                  )}
                  {_.get(
                    item,
                    'date_of_birth'
                  ) && (
                    <ItemInformation
                      label={t(
                        'date of birth'
                      )}
                      value={formatDate(
                        _.get(
                          item,
                          'date_of_birth'
                        )
                      )}
                    />
                  )}
                  {_.get(
                    item,
                    'gender'
                  ) && (
                    <ItemInformation
                      label={t(
                        'gender'
                      )}
                      value={t(
                        getProp(
                          Object.values(
                            GenderTypes
                          ),
                          'label'
                        )(
                          Number(
                            _.get(
                              item,
                              'gender'
                            )
                          )
                        )
                      )}
                    />
                  )}
                  {(_.get(
                    item,
                    'location.address'
                  ) ||
                    _.get(
                      item,
                      'location.ward'
                    )) && (
                    <ItemInformation
                      label={t(
                        'address'
                      )}
                      value={getFullAddress(
                        _.get(
                          item,
                          'location'
                        )
                      )}
                    />
                  )}
                  {_.get(
                    item,
                    'extra_fields',
                    []
                  ).length > 0 && (
                    <div className="flex flex-col">
                      <div className="uppercase italic text-xs font-medium text-color-400 border-b border-color-50 mb-1">
                        {t(
                          'extra information'
                        )}
                      </div>
                      {_.get(
                        item,
                        'extra_fields',
                        []
                      ) &&
                        _.get(
                          item,
                          'extra_fields',
                          []
                        ).filter(e => !!e.value || !!e.nvalue || !!e.dvalue).map(
                          (e, index) => {
                            let value = e.value;
                            if (!!e.dvalue) value = formatDate(e.dvalue)
                            if (!!e.nvalue) value = Number(e.nvalue).toLocaleString()
                            if (!!e.value) value = e.value
                            return (
                              <ItemInformation
                                key={index}
                                label={e.name}
                                value={value}
                              />
                            )
                          }
                        )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Translate>
        )
      }
    </OrganizationProfilesContext.Consumer>
  )
})

const BeneficiaryInfo = React.memo(
  () => (
    <OrganizationProfilesContext.Consumer>
      {({ type, item }) =>
        renderIf(
          type ===
            WishareEntities.BENEFICIARY,
          <BeneficiaryInformation
            beneficiary={item}
          />
        )
      }
    </OrganizationProfilesContext.Consumer>
  )
)

const OrganizationProfilesInfo = ({
  type,
  Wrapper = renderOwnChild,
}) => {
  let Component = Null
  switch (type) {
    case WishareEntities.DONOR:
      Component = DonorInfo
      break
    case WishareEntities.STAFF:
      Component = StaffInfo
      break
    case WishareEntities.BENEFICIARY:
      Component = BeneficiaryInfo
      break
    default:
      break
  }

  return (
    <Wrapper>
      <Component />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationProfilesContext.Consumer,
    ({ type, isLoading }) => ({
      type,
      isLoading,
    })
  ),
  branch(
    ({ isLoading }) => !!isLoading,
    () => renderLoading
  )
)(OrganizationProfilesInfo)
