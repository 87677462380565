import { Input } from 'antd'
import { url_getMetaTags_Api } from 'apis'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useMemo,
  useRef,
  useState,
} from 'react'
import { GrEmoji } from 'react-icons/gr'
import {
  IoCloseCircleOutline,
  IoImagesOutline,
} from 'react-icons/io5'
import ReactPlayer from 'react-player'
import { useToggle } from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { BaseEmbed } from '../BaseEmbed'
import { VideoEmbed } from '../VideoEmbed'
import AttachedImages from './AttachedImages'
import EmojiWrapper from './EmojiWrapper'

const MetaPreview = ({
  linkMeta,
  onClosed,
  isLoading,
}) => {
  const closeButton = useMemo(
    () => (
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}>
        <div className="cursor-pointer p-1">
          <IoCloseCircleOutline
            className="text-color-500 hover:text-red-600"
            size={18}
            onClick={onClosed}
          />
        </div>
      </div>
    ),
    [onClosed]
  )

  if (isLoading) {
    return (
      <div className="p-3 flex items-center">
        <LoadingPage />
      </div>
    )
  }

  if (_.isEmpty(linkMeta)) return null

  const {
    url,
    photo,
    title,
    host_url,
    description,
  } = linkMeta

  if (
    !!ReactPlayer.canPlay(linkMeta.url)
  ) {
    return (
      <div className="relative w-full border rounded-lg pt-5">
        <VideoEmbed
          url={url}
          title={title}
          image={photo}
          subTitle={host_url || url}
          description={description}
        />
        {closeButton}
      </div>
    )
  }

  return (
    <div className="relative w-full border rounded-lg pt-5">
      <BaseEmbed
        title={title}
        image={photo}
        subTitle={host_url || url}
        description={description}
      />
      {closeButton}
    </div>
  )
}

const MegaTextarea = ({
  name,
  value,
  fieldNames = {
    embed_data: 'embed_data',
  },
  onChange = Null,
  ...props
}) => {
  const t = useTranslate()

  const ref = useRef()

  const [isUploading, toggleUploading] =
    useToggle()

  const [
    currentIndex,
    setCurrentIndex,
  ] = useState(0)

  const [linkMeta, setLinkMeta] =
    useState()

  const {
    isLoading: isMetaLoading,
    handleAsyncAction: getMetaFromUrl,
  } = useAsyncAction({
    apiInfo: url_getMetaTags_Api,
    onSuccess: (__, { response }) => {
      const linkMeta =
        getResponseItem(response)
      if (linkMeta) {
        setLinkMeta(linkMeta)
        onChange(
          createValue(
            fieldNames.embed_data,
            JSON.stringify(linkMeta)
          )
        )
      }
    },
  })

  return (
    <div className="space-y-3">
      <div className="p-2 flex items-center background rounded-lg space-x-3">
        <span className="font-medium text-sm">
          {t('you want more')}
        </span>
        <EmojiWrapper
          content={value}
          currentIndex={currentIndex}
          onChange={(text) => {
            onChange(
              createValue(name, text)
            )
          }}
          Component={GrEmoji}
        />
        <IoImagesOutline
          onClick={toggleUploading}
          className="text-green-500 text-2xl cursor-pointer"
        />
      </div>
      {isUploading && (
        <AttachedImages
          onChange={onChange}
        />
      )}
      <MetaPreview
        linkMeta={linkMeta}
        onClosed={() =>
          setLinkMeta(null)
        }
        isLoading={isMetaLoading}
      />
      <Input.TextArea
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={({ target }) => {
          setCurrentIndex(
            target.selectionStart
          )
        }}
        onPaste={(e) => {
          const text =
            e.clipboardData.getData(
              'Text'
            )
          const LINK_DETECTION_REGEX =
            /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/gi
          switch (true) {
            // case !!ReactPlayer.canPlay(
            // 	text
            // ):
            // 	setLinkMeta({
            // 		url: text,
            // 		type: 'video'
            // 	});
            // 	break;
            case !!LINK_DETECTION_REGEX.exec(
              text
            ):
              getMetaFromUrl({
                content: text,
              })
              break
            default:
              break
          }
        }}
        {...props}
      />
    </div>
  )
}

export default MegaTextarea
