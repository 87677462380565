import React from 'react'
import AppTextLogoIcon from './icons/AppTextLogoIcon'

const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <AppTextLogoIcon loading={true} />
    </div>
  )
}
export default Loading
