import {Button, DatePicker, Input, Select,} from 'antd'
import {createValue} from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useCallback, useContext, useMemo,} from 'react'
import {AiOutlineNumber} from 'react-icons/ai'
import {BsCalendarDate,} from 'react-icons/bs'
import {IoAdd, IoCalendarOutline, IoTextOutline,} from 'react-icons/io5'
import {MdOutlineRemove} from 'react-icons/md'
import {withProps} from 'recompose'
import {deepTranslate, Null,} from 'views/Shared'
import {wishareConfigs} from '../configs'
import {SelectWidget} from '../custom/ReactSelectComponents'
import {createDynamicFields, DynamicFieldsContext,} from './createDynamicFields'

const ExtraParamsFieldTypes =
  Object.freeze({
    VALUE: {
      label: 'value placeholder',
      value: 'value',
      icon: <IoTextOutline/>,
    },
    DVALUE: {
      label: 'datetime placeholder',
      value: 'dvalue',
      icon: <BsCalendarDate/>,
    },
    NVALUE: {
      label: 'number placeholder',
      value: 'nvalue',
      icon: <AiOutlineNumber/>,
    },
  })

const CompactItem = ({
                       values,
                       actions,
                       field_types = [],
                       onChange = Null,
                       renderValueField = Null,
                     }) => {
  const t = useTranslate()

  const {name, field_type} =
  values || {}

  return (
    <Input.Group
      compact={true}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <SelectWidget
        {...{
          name: 'field_type',
          value: field_type,
          notFoundContent: t('empty'),
          style: {width: 60},
          onChange: (value) =>
            onChange(
              createValue(
                'field_type',
                value
              )
            ),
          className: 'w-full',
          children: field_types.map(
            (
              {label, value, icon},
              index
            ) => (
              <Select.Option
                key={index}
                value={value}>
                <div className="flex items-center justify-center h-full w-full">
                  {icon}
                </div>
              </Select.Option>
            )
          ),
          placeholder: t('Select...'),
          dropdownMatchSelectWidth: false,
        }}
      />
      <Input
        name="name"
        type="text"
        value={name}
        onChange={onChange}
        placeholder={t('field name')}
      />
      {renderValueField({field_type})}
      {actions}
    </Input.Group>
  )
}

const ExtraParamsField = ({
                            Header = Null,
                            Footer = Null,
                            Wrapper = 'div',
                          }) => {
  const t = useTranslate()

  const options = useMemo(
    () =>
      Object.values(
        ExtraParamsFieldTypes
      ).map(deepTranslate(t)),
    [t]
  )

  const {
    rows,
    params,
    createRow,
    updateRow,
    deleteRow,
    canRemove = true,
  } = useContext(DynamicFieldsContext)

  const items = Array.from(rows || [])

  const getValueByType = (
    type,
    {value, nvalue, dvalue}
  ) => {
    let current = {
      field_type: type,
      field_value: value,
    }

    if (_.isEmpty(current.field_type)) {
      current =
        _.find(
          [
            {
              field_type:
              ExtraParamsFieldTypes
                .VALUE.value,
              field_value: value,
            },
            {
              field_type:
              ExtraParamsFieldTypes
                .NVALUE.value,
              field_value: nvalue,
            },
            {
              field_type:
              ExtraParamsFieldTypes
                .DVALUE.value,
              field_value: dvalue,
            },
          ],
          ({field_value}) =>
            field_value !== null
        ) || {}
    }
    return current
  }

  const handleUpdate = useCallback(
    (id) => (event) => {
      const {name, value} =
        event.target
      if (
        ['field_type'].includes(name)
      ) {
        updateRow(id)({
          value: undefined,
          nvalue: undefined,
          dvalue: undefined,
          field_type: value,
        })
      } else {
        updateRow(id)({
          name: value,
        })
      }
    },
    [updateRow]
  )

  const renderValueField = useCallback(
    (id, value) =>
      ({field_type}) => {
        const params = {
          name: field_type,
          onChange: (event) => {
            updateRow(id)({
              [field_type]:
              event.target.value,
            })
          },
        }
        switch (field_type) {
          case ExtraParamsFieldTypes
            .VALUE.value:
            return (
              <Input
                {...params}
                value={value}
                type="text"
                placeholder={t(
                  ExtraParamsFieldTypes
                    .VALUE.label
                )}
              />
            )
          case ExtraParamsFieldTypes
            .NVALUE.value:
            return (
              <Input
                {...params}
                value={value}
                type="number"
                inputMode="numeric"
                placeholder={t(
                  ExtraParamsFieldTypes
                    .NVALUE.label
                )}
                onChange={(event) => {
                  const {
                    name,
                    value,
                  } = event.target
                  params.onChange(
                    createValue(
                      name,
                      Number(value)
                    )
                  )
                }}
              />
            )
          case ExtraParamsFieldTypes
            .DVALUE.value:
            return (
              <DatePicker
                {...params}
                defaultValue={
                  value
                    ? moment(
                    new Date(value)
                    )
                    : undefined
                }
                suffixIcon={
                  <IoCalendarOutline/>
                }
                format={
                  wishareConfigs.dateFormat
                }
                className="w-full"
                onChange={(
                  date,
                  dateString
                ) => {
                  updateRow(id)({
                    [field_type]: date,
                  })
                }}
                placeholder={t(
                  ExtraParamsFieldTypes
                    .DVALUE.label
                )}
              />
            )
          default:
            return null
        }
      },
    [t, updateRow]
  )

  return (
    <React.Fragment>
      <Header>
        <Button
          icon={<IoAdd/>}
          type="primary"
          onClick={() => {
            createRow({
              field_type:
              ExtraParamsFieldTypes
                .VALUE.value,
            })
          }}
          className="rounded-md no-shadow no-text-shadow flex flex-center gap-2">
          {t('add')}
        </Button>
      </Header>
      <Wrapper className="flex flex-col space-y-3">
        {items.map(
          ({
             id,
             name,
             value,
             nvalue,
             dvalue,
             field_type: virtual_type,
           }) => {
            const {
              field_type,
              field_value: _value,
            } = getValueByType(
              virtual_type,
              {value, nvalue, dvalue}
            )
            return (
              <React.Fragment key={id}>
                <CompactItem
                  values={{
                    name,
                    field_type,
                  }}
                  field_types={options}
                  onChange={handleUpdate(
                    id
                  )}
                  renderValueField={renderValueField(
                    id,
                    _value
                  )}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => {
                        deleteRow(id)
                      }}
                      className="p-2">
                      <MdOutlineRemove/>
                    </Button>,
                  ].map(
                    (child, index) => (
                      <React.Fragment
                        key={index}>
                        {child}
                      </React.Fragment>
                    )
                  )}
                />
              </React.Fragment>
            )
          }
        )}
        <Footer/>
      </Wrapper>
    </React.Fragment>
  )
}

const DynamicExtraFields = ({
                              name,
                              value,
                              params = {},
                              onChange = Null,
                            }) =>
  createDynamicFields({
    name,
    value,
    notifyListener: (array = []) => {
      onChange(createValue(name, array))
    },
  })(
    withProps(params)(ExtraParamsField)
  )

export default DynamicExtraFields
