import { Select } from 'antd'
import {
  activities_getItemsByOwner_gets_api,
  giving_events_getItemsByOwner_gets_api,
} from 'apis'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useState,
} from 'react'
import {
  branch,
  compose,
  renderNothing,
} from 'recompose'
import {
  getResponseItems,
  Null,
} from 'views/Shared'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/Wishare/custom/AntdConfigWrapper'
import { reactSelectComponents } from 'views/Wishare/custom/ReactSelectComponents'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'

const FilterSelectField = ({
  name,
  value,
  onChange,
  options,
  isLoading,
  ...props
}) => (
  <AntdConfigWrapper
    renderEmpty={RenderEmptyMeta}>
    <Select
      value={
        _.find(options, { value })
          ? value
          : undefined
      }
      showSearch={true}
      allowClear={true}
      onChange={(value) => {
        onChange(name, value)
      }}
      loading={!!isLoading}
      optionFilterProp="label"
      className="CenterSelector w-full"
      options={
        !!isLoading ? [] : options
      }
      suffixIcon={
        !!isLoading ? undefined : (
          <reactSelectComponents.DropdownIndicator />
        )
      }
      {...props}
    />
  </AntdConfigWrapper>
)

const ActivitiesAsyncWrapper = compose(
  branch(
    ({ owner_id, owner_type }) =>
      _.some(
        [owner_id, owner_type],
        _.isEmpty
      ),
    renderNothing
  )
)(
  ({
    owner_id,
    owner_type,
    children,
  }) => {
    const [values, setValues] =
      useState()

    const apiInfo =
      activities_getItemsByOwner_gets_api

    const { response, isLoading } =
      useAsyncWithCache({
        cacheId: [
          apiInfo.path
            .replace(':id', owner_id)
            .replace(
              ':type',
              owner_type
            ),
          JSON.stringify(values),
        ].join('/'),
        apiInfo,
        values,
        deps: [values],
        query: bindQueryParams([
          { id: owner_id },
          { type: owner_type },
        ]),
      })

    return _.isFunction(children)
      ? children({
          isLoading,
          items:
            getResponseItems(response),
          onSearch: (keyword) => {
            setValues({
              keyword,
            })
          },
        })
      : children
  }
)

const GivingsAsyncWrapper = compose(
  branch(
    ({ owner_id, owner_type }) =>
      _.some(
        [owner_id, owner_type],
        _.isEmpty
      ),
    renderNothing
  )
)(
  ({
    owner_id,
    owner_type,
    children,
  }) => {
    const [values, setValues] =
      useState()

    const apiInfo =
      giving_events_getItemsByOwner_gets_api

    const { response, isLoading } =
      useAsyncWithCache({
        cacheId: [
          apiInfo.path
            .replace(':id', owner_id)
            .replace(
              ':type',
              owner_type
            ),
          JSON.stringify(values),
        ].join('/'),
        apiInfo,
        values,
        deps: [values],
        query: bindQueryParams([
          { id: owner_id },
          { type: owner_type },
        ]),
      })

    return _.isFunction(children)
      ? children({
          isLoading,
          items:
            getResponseItems(response),
          onSearch: (keyword) => {
            setValues({
              keyword,
            })
          },
        })
      : children
  }
)

const BeneficiariesAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    filter_activity_id: undefined,
    filter_giving_event_id: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <ActivitiesAsyncWrapper
        {...{
          owner_id,
          owner_type,
        }}>
        {({ items, isLoading }) => {
          const options = Array.from(
            items
          ).map(
            ({ id, ...activity }) => ({
              value: id,
              label: t(
                getTitle(activity)
              ),
            })
          )
          return (
            <FilterSelectField
              name="filter_activity_id"
              loading={isLoading}
              value={
                filter.filter_activity_id
              }
              placeholder={t(
                isLoading
                  ? 'loading...'
                  : 'activity'
              )}
              options={options}
              onChange={(
                name,
                value
              ) => {
                handleChange(
                  name,
                  value
                )
              }}
            />
          )
        }}
      </ActivitiesAsyncWrapper>
      <GivingsAsyncWrapper
        {...{
          owner_id,
          owner_type,
        }}>
        {({ items, isLoading }) => {
          const options = Array.from(
            items
          ).map(
            ({
              id,
              ...giving_event
            }) => ({
              value: id,
              label: t(
                getTitle(giving_event)
              ),
            })
          )
          return (
            <FilterSelectField
              name="filter_giving_event_id"
              loading={isLoading}
              value={
                filter.filter_giving_event_id
              }
              placeholder={t(
                isLoading
                  ? 'loading...'
                  : 'giving'
              )}
              options={options}
              onChange={(
                name,
                value
              ) => {
                handleChange(
                  name,
                  value
                )
              }}
            />
          )
        }}
      </GivingsAsyncWrapper>
    </div>
  )
}

export default BeneficiariesAdvancedFilter
