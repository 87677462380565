export default {
  "00000-KO":"TRAN",
  "(yes/no) question":'(예/아니오) 질문',
  "{beneficiaryNbr} have beneficiaryed":'{beneficiarynbr}은 수혜자가 있습니다',
  "0 result":'0 결과',
  "1. no poverty":'1. 빈곤이 없습니다',
  "10. reduced inequalities":'10. 불평등 감소',
  "11. sustainable cities and communities":'11. 지속 가능한 도시와 지역 사회',
  "12. responsible consumption and production":'12. 책임있는 소비 및 생산',
  "13. climate action":'13. 기후 행동',
  "14. life below water":'14. 물 아래의 삶',
  "15. life on land":'15. 육지 생활',
  "16. peace, justice and strong institutions":'16. 평화, 정의 및 강력한 기관',
  "17 SDGs":'17 SDG',
  "17. partnerships for the goals":'17. 목표를위한 파트너십',
  "2. zero hunger":'2. 제로 굶주림',
  "3. good health and well-being":'3. 건강과 복지',
  "4. quality education":'4. 양질의 교육',
  "5. gender equality":'5. 남녀 평등',
  "6. clean water and sanitation":'6. 깨끗한 물과 위생',
  "7. affordable and clean energy":'7. 저렴하고 청정 에너지',
  "8. decent work and economic growth":'8. 괜찮은 일과 경제 성장',
  "9. industry, innovation and infrastructure":'9. 산업, 혁신 및 인프라',
  "A":'ㅏ',
  "a post":'우편',
  "a beneficiary of":'수혜자',
  "about":'에 대한',
  "about job":'직업에 대해',
  "about us":'우리에 대해',
  "about wishare":'Wishare에 대해',
  "abouthelp":'우리에 대해',
  "abouthelptips":'Wishare, 회사, 연락처',
  "accept":'수용하다',
  "accepted":'받아 들여졌다',
  "access to a huge human capital for good":'좋은 인적 자본에 대한 접근',
  "account":'계정',
  "account name":'계정 이름',
  "activated":'활성화',
  "activation":'활성화',
  "active":'활동적인',
  "activetips":'활성 페이지, 당신은 관리 할 권리가 있고 편집 할 권리가 있습니다.',
  "activities":'활동',
  "activity":'활동',
  "activity information":'활동 정보',
  "Activity is the smallest project unit with full mission and action. Your organization may have many different activities.":'활동은 완전한 임무와 행동을 가진 가장 작은 프로젝트 단위입니다. 귀하의 조직에는 다양한 활동이있을 수 있습니다.',
  "activity name":'활동 이름',
  "activitytips":'활동은 사회에 봉사하기 위해 명확한 목표를 가진 작업 모음입니다. 이 활동은 목표를 달성하기 위해 지역 사회 자원을 동원 할 수 있습니다.',
  "add":'추가하다',
  "add activity":'활동 추가',
  "add album":'앨범 추가',
  "add an image":'이미지 추가',
  "add auction item":'경매 품목을 추가하십시오',
  "add campaign":'캠페인 추가',
  "add categories":'당신의 관심을 선택하십시오',
  "add delivery policy":'전달 정책을 추가하십시오',
  "add donation":'기부금 추가',
  "add donation campaigns":'기부 캠페인 추가',
  "add location":'위치를 추가하십시오',
  "add member":'회원 추가',
  "add more":'더 추가하십시오',
  "add new":'새로운 걸 더하다',
  "add new language":'새로운 언어를 추가하십시오',
  "add new map":'새 맵을 추가하십시오',
  "add new product":'신제품 추가',
  "add new schedule":'새로운 일정을 추가하십시오',
  "add notice":'통지를 추가하십시오',
  "add photos":'사진 추가',
  "add product":'제품 추가',
  "add promotion campaigns":'프로모션 캠페인을 추가하십시오',
  "add return policy":'반품 정책을 추가하십시오',
  "add tag":'태그 추가',
  "add to cart":'카트에 추가하십시오',
  "add warranty policy":'보증 정책 추가',
  "add your comment":'의견을 추가하십시오',
  "add your school":'학교를 추가하십시오',
  "add your skills":'기술을 추가하십시오',
  "add your workplace":'직장을 추가하십시오',
  "add yours recommendations":'귀하의 권장 사항을 추가하십시오',
  "added hosts":'추가 된 호스트',
  "additional message":'추가 메시지',
  "address":'주소',
  "addressed":'주소',
  "adhoc":'특별한',
  "admin":'관리자',
  "advanced":'고급의',
  "affordable and clean energy":'저렴하고 청정 에너지',
  "age":'나이',
  "age_from":'나이',
  "age_from_placeholder":'나이',
  "age_to":'나이',
  "age_to_placeholder":'나이',
  "agree":'동의하다',
  "all":'모두',
  "ambassador":'대사',
  "amount":'양',
  "An":'an',
  "and":'그리고',
  "and petition":'그리고 청원',
  "and social impact work":'그리고 사회적 영향 직업',
  "animal":'동물',
  "anonymous":'익명 후원자',
  "answer":'답변',
  "applicant note":'신청자 메모',
  "applicants":'지원자',
  "applied a job":'직업을 적용했습니다',
  "applied beneficiaries":'응용 수혜자',
  "applies":'적용됩니다',
  "apply":'적용하다',
  "apply a new beneficiary":'새로운 수혜자를 적용하십시오',
  "apply an existing beneficiary":'기존 수혜자를 적용하십시오',
  "apply now":'지금 신청하십시오',
  "apply now!":'지금 신청하십시오!',
  "apply technology to support":'지원에 기술을 적용하십시오',
  "apply to become a volunteer":'자원 봉사자가되기 위해 신청하십시오',
  "approve":'승인하다',
  "approved":'승인',
  "are bidding":'입찰 중입니다',
  "are you agree to remove item from order?":'주문에서 항목을 제거하는 데 동의하십니까?',
  "are you sure to cancel this order?":'이 주문을 취소 하시겠습니까?',
  "are you sure you want to remove this item?":'이 항목을 제거 하시겠습니까?',
  "art & design":'예술 디자인',
  "Art, Collections & Antiques":'예술, 수집 및 골동품',
  "asc":'오름차순',
  "ask questions":'질문',
  "asking":'질문',
  "at least":'적어도',
  "attributes":'속성',
  "attributes list":'속성 목록',
  "auction":'경매',
  "auction donation campaign":'경매 기부 캠페인',
  "auction history":'경매 기록',
  "auction information":'경매 정보',
  "auction items":'경매 품목',
  "auction management":'경매 관리',
  "auction members":'경매 회원',
  "auction name":'경매 이름',
  "auction product":'경매 제품',
  "auction products":'경매 제품',
  "auction settings":'경매 설정',
  "auctioned price":'경매 가격',
  "auctions":'경매',
  "automatic":'자동적 인',
  "automatic execution":'자동 실행',
  "Automotive Motorcycles":'자동차 오토바이',
  "available":'사용 가능',
  "available activities":'사용 가능한 활동',
  "available for social work":'사회 사업에 사용할 수 있습니다',
  "avatar":'화신',
  "average rating point":'평균 등급 지점',
  "Babies & Toys":'아기와 장난감',
  "back":'뒤쪽에',
  "back to shop":'가게로 돌아갑니다',
  "basic information":'기본 정보',
  "be a part of growing trend":'성장 추세의 일부가 되십시오',
  "be the first to bid":'가장 먼저 입찰하십시오',
  "become a member of wishare":'Wishare의 회원이 되십시오 (무료)',
  "become a volunteer ":'자원 봉사자가 되십시오',
  "Before creating a new one, please see if your wishare already exists. In the case of Wishare\'s indexing, please contact us so we can re-administer it.":'새로운 것을 만들기 전에 Wishare가 이미 존재하는지 확인하십시오. Wishare \'s Indexing의 경우, 당사에 연락하여 다시 관리 할 수 ​​있도록 문의하십시오.',
  "begin":'시작하다',
  "benefits":'이익',
  "benefits and obligations":'혜택 및 의무',
  "best sales":'최고의 판매',
  "bid":'매기다',
  "bid now":'지금 입찰하세요',
  "bid price":'입찰 가격',
  "bid time":'입찰 시간',
  "bidder":'명령자',
  "bidding":'입찰',
  "bidding / asking":'입찰 : * 다음 입찰은 이전 입찰보다 높을 것이며, 가장 높은 입찰자는 입찰에서 승리합니다. / ask :* 다음 입찰은 이전 입찰보다 낮으며 최저 가격을 초대하는 사람은 입찰에서 승리합니다.',
  "bill":'청구서',
  "binding_conditions":'바인딩 조건',
  "binding_conditions_placeholder":'바인딩 조건',
  "block":'차단하다',
  "blocked":'막힌',
  "blockedtips":'죄송합니다. 귀하의 콘텐츠는 부적절하며 공개적으로 표시되지 않습니다.',
  "Books, Stationery & Gifts":'책, 문구 및 선물',
  "bought":'구입했다',
  "but let us help measure your impact and discover the social activities around you":'그러나 우리가 당신의 영향을 측정하고 주변의 사회 활동을 발견하도록 돕자',
  "but they can not find aright one":'그러나 그들은 Aright One을 찾을 수 없습니다',
  "but they can not find the support":'그러나 그들은 지원을 찾을 수 없습니다',
  "buy now":'지금 구매하십시오',
  "by":'~에 의해',
  "campaign name":'캠페인 이름',
  "cancel ":'취소',
  "cancel order":'주문 취소',
  "cancelled":'취소 된',
  "career":'직업',
  "careers":'경력',
  "cart":'카트',
  "categories":'카테고리',
  "categoriesplaceholder":'카테고리를 선택하십시오',
  "category":'범주',
  "certificate":'자격증',
  "change":'변화',
  "change cover photo":'커버 사진을 변경하십시오',
  "change in price and quantity tab":'pls, 가격 및 수량 탭의 변화',
  "change status":'상태 변경',
  "change your picture":'사진을 바꾸십시오',
  "charity products":'자선 제품',
  "charity, social impact project":'자선, 사회 영향 프로젝트',
  "check your email and click on the activation link to complete the registration":'이메일을 확인하고 활성화 링크를 클릭하여 등록을 완료하십시오.',
  "children":'어린이들',
  "choices":'선택',
  "choose":'선택하다',
  "choose 1 of the funds to receive the donation":'기부금을 받으려면 1 개의 자금을 선택하십시오',
  "choose a photo as the image for the activity":'활동의 이미지로 사진을 선택하십시오',
  "choose a photo as the image for the event":'이벤트의 이미지로 사진을 선택하십시오',
  "choose a photo as the image for the group":'그룹의 이미지로 사진을 선택하십시오',
  "choose the category that you are aiming for":'목표로하는 범주를 선택하십시오',
  "choose the job type to continue":'계속할 작업 유형을 선택하십시오',
  "choose the target you want to target":'타겟팅하려는 대상을 선택하십시오',
  "clean water and sanitation":'깨끗한 물과 위생',
  "CleanEnergy":'클리닝 에너지',
  "CleanWaterAndSanitation":'Cleanwaterandsanitation',
  "clear":'분명한',
  "clear filter":'클리어 필터',
  "click":'딸깍 하는 소리',
  "click here":'여기를 클릭하십시오',
  "click here to add new donate package":'새 기부 패키지를 추가하려면 여기를 클릭하십시오',
  "click here to add new photos":'새 사진을 추가하려면 여기를 클릭하십시오',
  "click here to dismiss recommendation this beneficiary":'이 수혜자를 추천하기 위해 여기를 클릭하십시오',
  "click to next":'다음으로 클릭하십시오',
  "click to vote it":'투표하려면 클릭하십시오',
  "climate action":'기후 행동',
  "close":'닫다',
  "closed":'닫은',
  "closed need":'닫은',
  "closedgroup":'폐쇄 된 조직',
  "collected":'모은',
  "Color":'색상',
  "coming soon":'곧 올 것입니다',
  "comment":'논평',
  "comment history":'댓글 기록',
  "comment on product":'제품에 대한 의견',
  "comment store":'의견 상점',
  "common":'흔한',
  "community policy":'지역 사회 정책',
  "company":'회사',
  "companytips":'회사',
  "complete add new product":'신제품 추가를 완료하십시오',
  "complete the information so people can understand your store better":'사람들이 당신의 상점을 더 잘 이해할 수 있도록 정보를 작성하십시오.',
  "Complete with sharing and spreading. We need you.":'공유 및 확산으로 완료하십시오. 우리는 당신이 필요합니다.',
  "completed":'완전한',
  "completed project":'완료된 프로젝트',
  "completed beneficiary":'완성 된 수혜자',
  "computer & data":'컴퓨터 및 데이터',
  "confirm cart":'카트 확인',
  "confirm password":'비밀번호 확인',
  "congratulation you become winner":'축하합니다',
  "congratulation your payment has been finished":'축하 결제가 완료되었습니다',
  "congratulations":'축하해요',
  "congratulations, your order is successful":'축하합니다. 주문이 성공했습니다',
  "connect with facebook":'페이스 북과 연동하세요',
  "connect with google":'Google과 연결하십시오',
  "connect with us":'우리와 접촉',
  "connecting for good":'선을 위해 연결',
  "connecting people of kindness and needs":'친절과 필요가있는 사람들을 연결합니다',
  "connecting the goodness":'연결 \ n 선함',
  "contact":'연락하다',
  "contact us":'문의하기',
  "Contact us by sending to contact@wishare.com":'contact@wishare.com으로 보내서 저희에게 연락하십시오',
  "contact_info":'연락하다',
  "content":'콘텐츠',
  "content created by wishare":'Wishare가 색인화했습니다',
  "continue":'계속하다',
  "continue shopping":'계속 쇼핑',
  "continuous time":'연속 시간',
  "contract":'계약',
  "contribute":'기여하다',
  "cookies":'쿠키',
  "cookies policy":'쿠키 정책',
  "copied":'복사',
  "copy":'복사',
  "copy url":'URL 복사',
  "Copyright by wishare.com":'Wishare.com의 저작권',
  "could_not_create_token":'로그인 실패!',
  "count on product":'제품을 의지합니다',
  "create":'만들다',
  "create a crowdfunding activity":'크라우드 펀딩 활동을 만듭니다',
  "create a new album":'새 앨범을 만듭니다',
  "create a new opportunity":'새로운 기회를 만드십시오',
  "create account & login":'계정 작성 및 로그인',
  "create activity":'활동을 만듭니다',
  "create auction":'경매를 만듭니다',
  "create donation":'기부금 추가',
  "create event":'이벤트를 만듭니다',
  "create giving":'기부를 창조하십시오',
  "create group":'조직을 만듭니다',
  "create need":'새로운 크라우드 펀딩을 만드십시오',
  "create new account":'새 계정을 만들',
  "create new an activity":'새로운 활동을 만듭니다',
  "create new auction":'새 경매를 만듭니다',
  "create new package":'새 패키지를 만듭니다',
  "create opportunity":'기회를 창출하십시오',
  "create post":'게시물을 만듭니다',
  "create store":'상점을 만듭니다',
  "create beneficiary":'수혜자를 창조하십시오',
  "create your activity":'활동을 만듭니다',
  "create your event":'이벤트를 만듭니다',
  "create your group":'조직을 만듭니다',
  "create your beneficiary":'수혜자를 창조하십시오',
  "created":'만들어진',
  "created date":'생성 된 날짜',
  "crowdfunding":'크라우드 펀딩',
  "culture":'문화',
  "current":'현재의',
  "current price":'현재 가격',
  "currentUser":'너',
  "customer rated positively":'고객은 긍정적으로 평가되었습니다',
  "date":'날짜',
  "date & time":'날짜 시간',
  "date_of_birth":'생일',
  "day":'낮',
  "days":'날',
  "days of delivery":'배달 일',
  "deaf mute":'청각 장애인 음소거',
  "default":'기본',
  "delete":'삭제',
  "delete comment":'주석을 삭제하십시오',
  "delete opportunity":'이 작업을 삭제하십시오',
  "delete question":'질문을 삭제하십시오',
  "delete the selected items":'선택한 항목을 삭제하십시오',
  "delivered":'전달',
  "delivery":'배달',
  "delivery by":'배달',
  "delivery by shop":'상점으로 배달',
  "delivery details":'배송 세부 사항',
  "delivery fee":'배달 수수료',
  "delivery location":'배달 위치',
  "delivery options":'배송 옵션',
  "delivery period from":'배달 기간',
  "delivery policy":'배달 정책',
  "delivery status":'배송 상황',
  "delivery unit":'배달 단위',
  "delivery-methods":'배달 방법',
  "delivery-policies":'배달 정책',
  "desc":'감소하다',
  "description":'소개하다',
  "descriptionplaceholder":'여기에 더 많이 쓰십시오.',
  "designer":'디자이너',
  "detail":'세부 사항',
  "development":'개발',
  "digital of your social activity":'당신의 사회 활동의 디지털',
  "directly work":'직접 작동합니다',
  "disabilities":'장애',
  "disablity":'장애',
  "discard":'버리다',
  "discount":'할인',
  "discount value":'할인',
  "Discover and contribute to community fundraising activities. We need you.":'지역 사회 모금 활동을 발견하고 기여합니다. 우리는 당신이 필요합니다.',
  "discover now":'지금 발견하십시오',
  "discovery":'발견',
  "discussion":'논의',
  "dismiss recommendation":'권장 사항을 기각하십시오',
  "display on homepage":'홈페이지에 표시',
  "district":'구역',
  "divorced":'이혼',
  "divorcedtips":'이혼',
  "do you want to delete this?":'이것을 삭제 하시겠습니까?',
  "do you want to remove this post?":'이 게시물을 제거 하시겠습니까?',
  "Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?":'지역 사회 활동에 시간, 재능 및 노력을 보내거나 사회적 영향 작업을하고 싶습니까?',
  "donate":'기부',
  "donate now":'지금 기부하세요',
  "donate what you can":'당신이 할 수있는 것을 기부하십시오',
  "donated":'기증',
  "donation":'기부',
  "donation campaigns":'기부 캠페인',
  "donation code":'기부 코드',
  "donation info":'기부 정보',
  "donation manager":'기부 관리자',
  "donation transfer":'기부 전송',
  "donation value":'기부 가치',
  "donation value must be large than 0":'기부금 값은 0보다 크야합니다',
  "donation-campaigns":'기부 캠페인',
  "donations":'기부',
  "done":'완료',
  "done beneficiaries":'수혜자',
  "donetips":'완료',
  "donor":'기증자',
  "downvote":'다운 투표',
  "draft":'초안',
  "drafttips":'콘텐츠가 드래프트로 되돌아 가고, 내용을 백업하고, 계속 게시 요청을 보냅니다.',
  "earlier":'더 일찍',
  "edit":'편집하다',
  "edit auction item":'경매 항목 편집',
  "edit comment":'댓글 편집',
  "edit delivery policy":'배송 정책 편집',
  "edit donate option":'기부 옵션 편집',
  "edit donation":'기부 편집',
  "edit donation campaigns":'기부 캠페인 편집',
  "edit giving":'주는 편집',
  "edit image":'이미지 편집',
  "edit location":'위치 편집',
  "edit margin value":'마진 값 편집',
  "edit member":'멤버 편집',
  "edit member information":'회원 정보 편집',
  "edit notice":'통지를 편집하십시오',
  "edit opportunity":'기회 편집',
  "edit post":'게시물 편집',
  "edit product infomation":'제품 정보 편집',
  "edit profile":'프로필 수정',
  "edit promotion campaign":'프로모션 캠페인 편집',
  "edit question":'질문 편집',
  "edit return policy":'반품 정책 편집',
  "edit warranty policy":'보증 정책 편집',
  "education":'교육',
  "education/ training":'교육 훈련',
  "educations":'교육',
  "effective management and reporting":'효과적인 관리 및보고',
  "efficient successful and sustainable":'효율적이고 성공적이며 지속 가능합니다',
  "Electronic & Accessories":'전자 및 액세서리',
  "email":'이메일',
  "emailplaceholder":'이메일 (example@company.com)',
  "embed":'포함시키다',
  "empathize and help to connect these beneficiaries to the relevent activities that you should know":'이 수혜자들을 당신이 알아야 할 관련 활동에 공감하고 도움을줍니다.',
  "employment type":'고용 유형',
  "empty":'비어 있는',
  "end":'끝',
  "end date":'종료 날짜',
  "end time":'종료 시간',
  "end time must be after start time":'종료 시간은 시작 시간이 지나야합니다',
  "end_at":'끝납니다',
  "end_date":'종료 날짜',
  "end_time":'종료 시간',
  "ended":'끝났다',
  "ended at":'끝났다',
  "ended session":'종료 세션',
  "ending now":'지금 끝납니다',
  "english":'영어',
  "englishtips":'영어',
  "enter a location":'위치를 입력하십시오',
  "enter album name":'앨범 이름을 입력하십시오',
  "enter amount":'금액을 입력하세요',
  "enter keyword":'키워드를 입력하십시오',
  "enter name":'이름을 입력하시오',
  "enter order code":'주문 코드를 입력하십시오',
  "enter order user":'주문 사용자를 입력하십시오',
  "enter the activity name":'활동 이름을 입력하십시오',
  "enter the reason you think is a violation":'위반이라고 생각하는 이유를 입력하십시오',
  "enter your question here":'여기에 질문을 입력하십시오',
  "enter your reply here":'여기에 답장을 입력하십시오',
  "environment":'환경',
  "environmental":'환경',
  "error message":'뭔가 잘못되었다! 연결을 확인하고 다시 다시로드하십시오!',
  "event":'이벤트',
  "event time":'이벤트 시간',
  "event timeline":'타임 라인',
  "event title":'이벤트 제목',
  "events":'이벤트',
  "eventtips":'행사는 여러 사람이 동시에 참여하는 활동입니다. 이벤트는 아이디어를 구현하는 데 사용됩니다.',
  "example":'예',
  "example name":'Johny 영어',
  "experience_requirements":'경험 요구 사항',
  "expired":'만료되었습니다',
  "expired job":'만료 된 직업',
  "explore funding need":'자금 요구를 탐색하십시오',
  "explore jobs in":'일자리를 탐색하십시오',
  "explore the list of activities which are suitable for your need":'필요에 적합한 활동 목록을 탐색하십시오.',
  "export data":'내보내기 데이터',
  "failed":'실패한',
  "farming & forestry":'농업 및 임업',
  "Fashion & Accessories":'패션 악세사리',
  "fee":'요금',
  "feedback":'피드백',
  "feeds":'피드',
  "female":'여성',
  "femaletips":'여성',
  "field of work":'업무 분야',
  "filterplaceholder":'필터',
  "financial capital":'금융 \ ncapital',
  "financial capital for good":'선을위한 금융 자본',
  "Find volunteers or create social impact locations, Experience the free recruitment on Wishare.":'자원 봉사자를 찾거나 사회적 영향 장소를 만들고 Wishare에 대한 무료 채용을 경험하십시오.',
  "finish":'마치다',
  "flexible":'유연한',
  "focusing on good connection":'좋은 연결에 중점을 둡니다',
  "follow":'따르다',
  "followed you":'당신을 따랐습니다',
  "follower":'수행원',
  "followers only":'팔로워 만',
  "following":'수행원',
  "following auction":'경매 다음',
  "following events":'다음 행사',
  "following beneficiaries":'수혜자를 추종합니다',
  "following-auction":'경매 다음',
  "for":'을 위한',
  "for community":'커뮤니티를 위해',
  "for recruiters, let access to our huge volunteer community":'채용 담당자를 위해 거대한 자원 봉사 커뮤니티에 접근하십시오.',
  "for sales":'판매를 위해',
  "for social and creative purpose":'사회적, 창의적 목적을 위해',
  "for volunteer, please let us give you a good recommendation":'자원 봉사자를 위해서, 당신에게 좋은 추천을 드리겠습니다.',
  "for volunteers":'자원 봉사자를 위해',
  "for who seek to raise money for the good thing, implementing your initiative":'좋은 일을 위해 돈을 모 으려고하는 사람을 위해, 당신의 이니셔티브를 구현하십시오',
  "forgotpass":'내 비밀번호를 잊으 셨나요?',
  "foundation":'기반',
  "free for friends":'친구에게 무료',
  "free ship":'무료 배',
  "free ship with minimum amount":'최소 금액이있는 무료 배',
  "frequency":'빈도',
  "frequencyplaceholder":'빈도',
  "friend":'친구',
  "friends":'친구',
  "from":'에서',
  "from donors":'감사합니다',
  "full-time":'풀 타임',
  "fullname":'풀 이름',
  "fundraiser for good":'좋은 모금 행사',
  "gave":'주었다',
  "gender":'성별',
  "gender equality":'남녀 평등',
  "GenderEquality":'남녀 평등',
  "general":'일반적인',
  "general information":'일반 정보',
  "get started":'시작하다',
  "gift":'선물',
  "gift product":'선물 제품',
  "gift product list":'선물 제품 목록',
  "gift products":'선물 제품',
  "give your job a title":'당신의 직업에 제목을 줘',
  "give your job position":'직무를 제공하십시오',
  "given number":'주어진 금액',
  "giving":'기부',
  "giving what we can?":'우리가 할 수있는 것을 주?',
  "go to":'이동',
  "go to cart":'카트로 가십시오',
  "go to shop":'가게에 가다',
  "good":'좋은',
  "good health and well-being":'건강과 복지',
  "graduated":'졸업',
  "Great, you\'ve signed - the next step is to share far and wide to make sure everyone sees this campaign.":'다음 단계는 모든 사람 이이 캠페인을 볼 수 있도록 멀리 떨어져 공유하는 것입니다.',
  "Groceries & Pets":'식료품 및 애완 동물',
  "group":'조직',
  "groups":'조직',
  "grouptips":'조직은 교환 공간이며 많은 사람들을 연결합니다. 그룹은 회사, 조직 (비영리 단체, 협회, ...) 또는 친구 그룹의 정보를 공유하는 데 사용됩니다.',
  "grouptypeplaceholder":'조직 유형',
  "half successful":'반 성공',
  "has been linked":'연결되었습니다',
  "has been supported":'지원되었습니다',
  "has draft orders":'초안 주문이 있습니다',
  "has orders":'주문이 있습니다',
  "haveaccount":'이미 계정이 있습니까?',
  "header":'머리글',
  "Health & Beauty":'건강 및 아름다움',
  "healthcare":'보건 의료',
  "healthy":'건강한',
  "Height":'키',
  "help":'돕다',
  "helps":'도움이됩니다',
  "here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.":'다음은 고용주가 귀하에게 보내고 싶어하는 몇 가지 질문입니다. 질문에 답하면 고용주가 도움이되고 서로를 더 잘 이해할 수 있습니다.',
  "hidden due to invalid content":'정책 위반 및 Wishare 조건을위한 숨겨진 콘텐츠',
  "hide":'숨다',
  "high to low":'위에서 아래로',
  "history":'역사',
  "home":'집',
  "Home & LifeStyle":'가정 및 라이프 스타일',
  "hometown":'고향',
  "hosted by":'에 의해 호스팅',
  "hosted items":'호스팅 된 항목',
  "hostedactivities":'활동',
  "hostedItem":'호스팅 된 항목',
  "hosting":'호스팅',
  "hosts":'호스트',
  "hour(h)":'시간 (H)',
  "hourly-work":'시간당 작업',
  "house number, street name":'집 번호, 거리 이름',
  "how can you help?":'어떻게 도울 수 있습니까?',
  "how many can you help?":'당신은 얼마를 도울 수 있습니까?',
  "how many people do you need?":'얼마나 많은 사람들이 필요합니까?',
  "human":'인간',
  "human capital":'인간 \ ncapital',
  "human capital for good":'선을위한 인적 자본',
  "i have read and accepted with these":'나는 이것들을 읽고 받아 들였다',
  "If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.":'두 주제가 실제로 동일해야한다면 여기에서 합병 할 수 있습니다. 이 주제에 따라 그룹화 된 모든 질문과 편집자는 다른 주제로 전송됩니다.',
  "If you have any questions, please contact us for support":'궁금한 점이 있으시면 지원을 받으려면 저희에게 연락하십시오.',
  "If you want to create a crowdfunding activity for charitable, social or creative activities. Let\'s use and experience the crowdfunding function on our platform.":'자선, 사회적 또는 창의적 활동을위한 크라우드 펀딩 활동을 만들고 싶다면. 플랫폼에서 크라우드 펀딩 기능을 사용하고 경험하게하십시오.',
  "ignore":'무시하다',
  "ignored":'무시했습니다',
  "impact":'영향',
  "impact jobs":'영향 작업',
  "important note":'중요 사항',
  "in":'안에',
  "In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activity. Join Wishare to share and contribute to these sustainability goals.":'2015 년 세계 지도자들은 2030 년까지 더 나은 세계를위한 17 개의 목표에 동의했습니다. Wishare와 커뮤니티는 공동으로 {n} 활동에 대한 지수를 만들었습니다. Wishare에 가입하여 이러한 지속 가능성 목표를 공유하고 기여하십시오.',
  "in order for an employer to reach you, update some of yourself":'고용주가 당신에게 연락하기 위해서는 자신의 일부를 업데이트하십시오.',
  "in our good connection":'우리의 좋은 연결에서',
  "in the same categories":'같은 범주에서',
  "in-activated":'활성화',
  "inactive":'비활성',
  "inactivetips":'페이지가 스누즈 또는 비활성화되었으며 정보는 내부적으로 기밀입니다.',
  "index":'색인',
  "industry, innovation and infrastructure":'산업, 혁신 및 인프라',
  "info":'정보',
  "information":'정보',
  "information about donating":'기부에 관한 정보',
  "information where donations are made":'기부가 이루어지는 정보',
  "informationandmap":'위치',
  "infrastructure":'하부 구조',
  "initial price":'초기 가격',
  "input":'입력',
  "inreview":'검토에서',
  "inreviewtips":'당사는 귀하의 페이지를 검토하고 있으며 검토 프로세스가 완료되면 이메일을 보내드립니다.',
  "insert question":'질문을 삽입하십시오',
  "inspire your goodness":'당신의 선하심에 영감을주십시오',
  "instructions on how to reset your password were sent to":'비밀번호 재설정 방법에 대한 지침이 전송되었습니다.',
  "intends to delivery from":'배달을 할 예정입니다',
  "internal":'내부',
  "internship":'인턴쉽',
  "internships":'인턴쉽',
  "interpreter & translator":'통역사 및 번역가',
  "introduce yourself":'자신에 대해 소개하십시오',
  "invalid date":'잘못된 날짜',
  "invalid email":'잘못된 이메일',
  "invalid email address":' 잘못된 이메일 주소',
  "invalid_credentials":'잘못된 로그인 정보. 계정을 확인하고 비밀번호 로그인하십시오!',
  "invite":'초대하다',
  "is merged into":'병합됩니다',
  "is priority product":'우선 제품입니다',
  "It\'s YOU":'그것은 당신입니다',
  "job description":'업무 설명서',
  "job details":'작업 세부 사항',
  "job filter":'작업 필터',
  "job function type":'작업 기능 유형',
  "job information":'직무 정보',
  "job type":'직업 종류',
  "job_requirements":'작업 요구 사항',
  "job_types":'작업 유형',
  "jobs":'일자리',
  "join":'가입하다',
  "join day":'날에 가입하십시오',
  "join us":'우리와 함께하십시오',
  "join us now":'지금 가입하십시오',
  "join us on mobile":'모바일에 우리와 함께하십시오',
  "joined":'가입',
  "joined activities":'가입 활동',
  "joined groups":'가입 된 조직',
  "just for you":'너만을 위해서',
  "keep your passion like an artist and manage your social mission like a tech CEO":'예술가처럼 열정을 유지하고 기술 CEO처럼 사회적 사명을 관리하십시오.',
  "keyword":'예어',
  "language":'언어',
  "language choose":'디스플레이 언어 :',
  "language_requirements":'언어 요구 사항',
  "languages":'언어',
  "large scale":'대판',
  "last bid price":'마지막 입찰 가격',
  "last bidder":'마지막 입찰자',
  "latitude":'위도',
  "law - policy":'법 - 정책',
  "law / legal services":'법 / 법률 서비스',
  "learn more":'더 알아보기',
  "leave comment":'의견을 남겨주세요',
  "Length":'길이',
  "let discover and join to these activities below or apply your wish to request a support":'아래의 이러한 활동을 발견하고 참여하게하거나 지원을 요청하기 위해 소원을 적용하십시오.',
  "let discover the crowdfunding from the community and contribute to a change":'지역 사회의 크라우드 펀딩을 발견하고 변화에 기여하십시오.',
  "let make a free activity":'무료 활동을하자',
  "let sign and help to connect these beneficiaries to relevent activities or the kindness people":'이 수혜자들을 관련 활동이나 친절에 연결하는 데 서명하고 도움을주십시오.',
  "let\'s us help":'우리가 도와 드리겠습니다',
  "level":'수준',
  "life below water":'물 아래의 삶',
  "life on land":'육지 생활',
  "LifeBelowWater":'생명 벨로 워터',
  "LifeOnLand":'Lifeonland',
  "link target":'링크 대상',
  "link title":'링크 제목',
  "link to":'다음으로 연결',
  "link to schedule":'일정 링크',
  "linked":'연결되어 있습니다',
  "links":'연결',
  "live session":'라이브 세션',
  "loading":'로딩',
  "location":'위치',
  "location list":'위치',
  "locationplaceholder":'위치 정보를 입력하십시오',
  "locations":'위치',
  "login":'로그인',
  "logout":'로그 아웃',
  "longitude":'경도',
  "lost":'잃어버린',
  "Lot size":'로트 크기',
  "low to high":'하단 위로',
  "make a crowdfunding":'크라우드 펀딩을 만드십시오',
  "make a crowdfunding now":'지금 크라우드 펀딩을 만드십시오',
  "make a recruitment":'채용을하십시오',
  "make a beneficiary":'수혜자를 만드십시오',
  "make an activity":'활동을하십시오',
  "make digital conversions for social initiatives to help them work":'사회 이니셔티브에 대한 디지털 전환을 위해 일하는 데 도움이됩니다.',
  "make event":'이벤트를 만드십시오',
  "make opportunity now":'지금 기회를 마련하십시오',
  "make post":'게시물을 만듭니다',
  "makeactivityplaceholder":'활동 이름을 입력하십시오',
  "makeeventplaceholder":'이벤트 이름을 입력하십시오',
  "makegroupplaceholder":'조직 이름을 입력하십시오',
  "makebeneficiaryplaceholder":'수혜자를 입력하십시오',
  "male":'남성',
  "maletips":'남성',
  "mall":'쇼핑 센터',
  "mall ":'쇼핑 센터',
  "mall & auction":'쇼핑몰 및 경매',
  "manage members":'회원 관리',
  "management":'관리',
  "manager":'관리자',
  "mandatory":'필수적인',
  "manual":'수동',
  "manual / automatic":'매뉴얼 :* 경매 프로그램은 경매 관리자가 통제하고 시작합니다. 자동 : * 경매 프로그램은 경매 프로그램에 지정된 시점에 자동으로 시작됩니다.',
  "manufacturer":'제조업체',
  "many people or causes that":'많은 사람들이나 원인입니다',
  "many people or organisations":'많은 사람들이나 조직',
  "map":'지도',
  "margin value":'여백 값',
  "marital_status":'결혼 상태',
  "mark \'active\' if you want social activity units to be able to reach you":'Social Activity Units가 귀하에게 연락 할 수 있기를 원한다면 Mark \ \'Active \'',
  "mark all as read":'읽은대로 표시하십시오',
  "married":'기혼',
  "marriedtips":'기혼',
  "Material":'재료',
  "max_age":'최대 시대',
  "max_salary":'최대 급여',
  "maximum with":'최대',
  "may be available to close, let check it again":'닫을 수 있습니다. 다시 확인하십시오.',
  "media":'미디어',
  "media & communication":'미디어 및 커뮤니케이션',
  "member":'회원',
  "member filter":'멤버 필터',
  "member name":'회원 이름',
  "members":'회원',
  "membersadmintips":'관리자, 활동, 활동, 이벤트의 정보 페이지를 편집합니다. 관리자는 콘텐츠를 승인하고 신입 회원을 수락 할 권리가 있습니다.',
  "membersmembertips":'활동, 활동, 이벤트 사용자',
  "membersownertips":'활동, 활동, 포부의 선임 관리자. 관리자는 관리자를 설치하고 신입 회원을 승인 할 권리가 있습니다.',
  "merge tags":'태그를 병합하십시오',
  "message has been sent":'귀하의 메시지가 전송되었습니다. 우리 양식을 작성해 주셔서 감사합니다!',
  "methods":'총 지불',
  "min margin value":'최소 마진 값',
  "min_age":'최소 시대',
  "min_salary":'최소 급여',
  "minimum margin value":'최소 마진 값',
  "minimum with":'최소',
  "minute(m)":'분 (m)',
  "missing data":'빠진 정보',
  "mission":'사명',
  "mission_placeholder":'활동의 사명',
  "money":'돈',
  "month":'월',
  "months":'달',
  "more":'더',
  "more topics to continue":'계속할 더 많은 주제',
  "multiple choice":'다중 선택',
  "mutual users":'상호 친구',
  "my auctions":'내 경매',
  "my orders":'내 주문',
  "my questions":'내 질문',
  "my reviews":'내 리뷰',
  "my stores":'내 상점',
  "myfollowers":'내 뒤를 따라 갔다',
  "name":'이름',
  "name of consignee":'Consignee의 이름',
  "name of people you want to invite":'초대하고 싶은 사람들의 이름',
  "nameplaceholder":'풀 이름',
  "nature":'자연',
  "need":'크라우드 펀딩',
  "need category type":'크라우드 펀딩 유형',
  "need contact":'기여할 정보',
  "need from":'시작하다',
  "need help":'도움이 필요하다',
  "need information":'정보가 필요합니다',
  "need manager":'크라우드 펀딩 관리',
  "need title":'크라우드 펀딩 제목',
  "need to":'지금까지 접수되었습니다',
  "needs":'크라우드 펀딩',
  "new activity":'새로운 활동',
  "new donate option":'새로운 기부 옵션',
  "create organization":'조직을 만듭니다',
  "new location":'위치를 추가하십시오',
  "new orders":'새로운 명령',
  "new password":'새 비밀번호',
  "new products":'신제품',
  "new question":'새로운 질문',
  "news":'소식',
  "next":'다음',
  "next item":'다음 항목',
  "nextevents":'다음 이벤트',
  "NGO, NPO, CSR, Social group":'NGO, NPO, CSR, 사회 그룹',
  "ngo/ non - profit":'NGO/ 비 - 이익',
  "no":'아니요',
  "no categories":'카테고리가 없습니다',
  "no data yet to display":'아직 표시 할 데이터가 없습니다',
  "no item found":'발견 된 항목이 없습니다',
  "no matches for":'일치하지 않습니다',
  "No matter what you\'re a NPO or an enterprise or just an individual who passionote to make change, you\'re our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.":'당신이 무엇이든 NPO, 기업, 또는 변화를 일으키기 위해 열정적 인 개인이든, 당신은 우리의 친구입니다. Wishare는 무료로 시작합니다. 우리는 당신의 스폰서 나 사전 기능에 대한 기부금을 환영합니다.',
  "no milestones were created":'이정표가 만들어지지 않았습니다',
  "No milestones were created. Click here to start creating a schedule":'이정표가 만들어지지 않았습니다. 일정을 만들려면 여기를 클릭하십시오',
  "no more item":'더 이상 항목이 없습니다',
  "no poverty":'빈곤이 없습니다',
  "no products in your cart":'카트에 제품이 없습니다',
  "no profile found":'프로필이 없습니다',
  "no return":'반환 없음',
  "no tags":'태그가 없습니다',
  "no warranty":'보증이 없습니다',
  "non-profit activity":'비영리 활동',
  "none":'...',
  "NoPoverty":'유노',
  "normal":'정상',
  "Not all of us can do great things. But we can do small things with great love.":'우리 모두가 위대한 일을 할 수있는 것은 아닙니다. 그러나 우리는 큰 사랑으로 작은 일을 할 수 있습니다.',
  "not good":'안좋다',
  "not updated":'업데이트되지 않았습니다',
  "not yet comment":'아직 댓글이 없습니다',
  "not_activated_account_credentials":'계정이 활성화되지 않았습니다. 활성화 이메일을 확인하여 활성화하십시오',
  "not_exists_account_credentials":'계정이 존재하지 않습니다. Google 계정, Facebook을 사용하여 등록하거나 로그인하십시오!',
  "note":'메모',
  "notice description":'주목 설명',
  "notice management":'통지 관리',
  "notice name":'통지 이름',
  "notices":'통지',
  "notifications":'알림',
  "numerical order":'아니요',
  "obligations":'은혜',
  "of":'~의',
  "old":'오래된',
  "old password":'기존 비밀번호',
  "old price":'오래된 가격',
  "on":'~에',
  "on this page":'이 페이지에서',
  "on_site":'현장에서',
  "one product of":'하나의 제품',
  "online auction channel - charity donation with auctions - sell products with auctions":'온라인 경매 채널 - 경매와 자선 기부 - 경매와 함께 제품 판매',
  "online jobs":'온라인 직업',
  "only me":'나만',
  "open time":'오픈 타임',
  "opportunities":'기회',
  "opportunity":'자원 봉사자',
  "oppr_type":'OPPR 유형',
  "optional":'선택 과목',
  "options":'옵션',
  "or":'또는',
  "or a":'또는 a',
  "or helping to connect the social mission and the good beneficiaries":'또는 사회적 사명과 좋은 수혜자를 연결하는 데 도움',
  "or login directly by":'또는 직접 로그인하십시오',
  "Or you update your personal information, leaving the mode \'available to volunteer\', employers will search for you.":'또는 귀하는 귀하의 개인 정보를 업데이트하여 모드를 자원 봉사자가 사용할 수있는 모드를 남겨두면 고용주가 귀하를 검색합니다.',
  "order":'주문하다',
  "order code":'주문 코드',
  "order details":'주문 세부 사항',
  "order filter":'주문 필터',
  "order information":'주문 정보',
  "order management":'주문 관리',
  "order number":'아니요',
  "order status":'주문 상태',
  "order user":'주문 사용자',
  "ordered at":'주문',
  "orders":'명령',
  "organization":'조직',
  "organizationtips":'조직',
  "other":'다른',
  "other activities":'다른 활동',
  "other auction sessions":'기타 경매 세션',
  "other display":'다른 디스플레이',
  "other information":'기타 정보',
  "other people":'다른 사람',
  "other reasons":'다른 이유들',
  "other_requirements":'기타 요구 사항',
  "others":'기타',
  "othertips":'다른',
  "Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.":'당사의 도구는 인적 자원 동원, 재정 자원 및 활동의 구현 및 보고서를 방출하는 데 시간, 효율성 및 확장 성을 절약 할 수 있도록 도와줍니다. 우리는 당신을보다 편리하게 만들고 더 최적화하도록 끊임없이 개선하고 있습니다.',
  "out":'밖으로',
  "output":'산출',
  "Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.":'몇 년 동안 우리는 열정과 축적 된 경험을 가지고 있으며 사회 활동을 촉진하는 데 도움이되었습니다. 이 기술 플랫폼은 귀하의 활동 기반을 수행 할 수 있도록 지원합니다. 원칙의 변화 이론 : 영향, 혁신에 초점; 신뢰할 수있는 정보; 투명한 의사 소통; 효과적인 연결.',
  "owner":'소유자',
  "package":'패키지',
  "packed":'포장 된',
  "paragraph":'절',
  "parallel":'평행한',
  "parallel mode":'병렬 모드',
  "part-time":'파트 타임',
  "partnerships":'파트너십',
  "partnerships for the goals":'목표를위한 파트너십',
  "password":'비밀번호',
  "password required":'비밀번호 필요',
  "passwordplaceholder":'비밀번호 (최소 6 자)',
  "payment":'지불',
  "payment completed":'지불이 완료되었습니다',
  "payment location":'지불 위치',
  "payment management":'지불 관리',
  "payment method":'지불 방법',
  "payment type":'지불 유형',
  "peace, justice and strong institutions":'평화, 정의 및 강력한 기관',
  "pending":'보류 중',
  "pending orders":'주문을 보류',
  "pending payment":'보류중인 지불',
  "pending beneficiaries":'보류중인 수혜자',
  "pendingdonations":'보류중인 기부',
  "pendingtips":'보류 중',
  "people with disabilities":'장애가있는 사람들',
  "per day":'하루에',
  "per hour":'시간당',
  "per month":'달마다',
  "per quarter":'분기당',
  "per week":'한 주에',
  "per year":'연간',
  "percent":'퍼센트',
  "period time":'기간 시간',
  "person":'사람',
  "person_s":'홍보',
  "phone":'핸드폰',
  "phone_number":'전화 번호',
  "phone_placeholder":'전화 번호',
  "photo":'사진',
  "photo gallery":'사진 갤러리',
  "photos":'사진',
  "pick tags":'태그를 선택하십시오',
  "place order":'주문하기',
  "please complete the basic information":'기본 정보를 작성하십시오',
  "please select an organization, individual or group to be the owner":'소유자가 될 조직, 개인 또는 그룹을 선택하십시오.',
  "please sign with us, if you agree":'동의하면 저희와 서명하십시오',
  "please follow these instructions to complete the donation process.":'기부 과정을 완료하려면 다음 지침을 따르십시오.',
  "policy name":'정책 이름',
  "policyhelp":'정책 및 약관',
  "policyhelptips":'신뢰 및 안전, 이용 약관, 개인 정보 보호 정책, 쿠키, 커뮤니티 가이드 라인',
  "position":'위치',
  "position and size your photo":'사진 위치와 크기',
  "positive rating":'긍정적 인 등급',
  "post":'우편',
  "posted":'게시',
  "prerequisites":'전제 조건',
  "prerequisites_placeholder":'전제 조건',
  "current job":'현재 직업',
  "prevevents":'이전 이벤트',
  "price":'가격',
  "price and quantity":'가격과 수량',
  "price type":'가격 유형',
  "priority_conditions":'우선 순위 조건',
  "priority_conditions_placeholder":'우선 순위 조건',
  "privacy":'은둔',
  "privacy policy":'개인 정보 정책',
  "private":'사적인',
  "privatetips":'보안이 가장 높은 폐쇄 된 사이트. 페이지 제작자만이 컨텐츠, 게시물 정보를 볼 수 있습니다.',
  "process":'프로세스',
  "process to payment":'지불 처리',
  "processing":'진행중',
  "processingtips":'처리',
  "product":'제품',
  "product album":'제품 앨범',
  "product attributes":'제품 속성',
  "product categories":'제품 카테고리',
  "product category":'제품 카테고리',
  "product comment":'제품 의견',
  "product description":'제품 설명',
  "product faq":'제품 FAQ',
  "product filter":'제품 필터',
  "product filter by":'제품 적합한 제품',
  "product group":'제품 그룹',
  "product in the same auction":'같은 경매에서 제품',
  "product in the same categories":'같은 범주의 제품',
  "product management":'제품 관리',
  "product name":'상품명',
  "product price":'제품 가격',
  "product priority":'제품 우선 순위',
  "product rating":'제품 등급',
  "product reviews":'제품 리뷰',
  "product reviews and ratings":'제품 리뷰 및 등급',
  "product searching":'제품 검색',
  "product type":'상품 유형',
  "products":'제품',
  "products in same category":'같은 카테고리의 제품',
  "products in same store":'같은 상점에있는 제품',
  "profile":'프로필',
  "profile information":'프로필 정보',
  "program description":'프로그램 설명',
  "program filter":'프로그램 필터',
  "program name":'프로그램 이름',
  "promote sharing connecting good things to help people everywhere be happy":'사람들을 돕기 위해 좋은 것을 연결하는 것을 공유하는 것을 촉진하십시오.',
  "promotion":'홍보',
  "promotion campaign name":'프로모션 캠페인 이름',
  "promotion campaigns":'프로모션 캠페인',
  "promotions":'프로모션',
  "propose as owner":'호스트로 요청했습니다',
  "proposed applicants":'제안 된 지원자',
  "provide the most accurate address so people can find your store faster":'사람들이 상점을 더 빨리 찾을 수 있도록 가장 정확한 주소를 제공하십시오.',
  "province":'주',
  "public":'공공의',
  "public / private":'공개 :* 경매는 모든 사람에게 열려 있습니다 / 사적 :* 경매는 경매 회원만을위한 것입니다.',
  "public display":'공개 디스플레이',
  "public name":'공개 이름',
  "publictips":'페이지는 모든 사람에게 공개적으로 보입니다. 누구나 회원과 게시 한 내용을 볼 수 있습니다',
  "publish reply":'답장을 게시하십시오',
  "published":'게시',
  "publishedtips":'내용이 완료되고 게시되며 모든 사람이보고 의견을보고 공유 할 수 있습니다.',
  "publishrequest":'요청을 게시하십시오',
  "publishrequesttips":'콘텐츠가 인증되고 있으며 24 시간 이내에 이메일 알림을 받게됩니다.',
  "quality education":'품질 교육',
  "quantity":'수량',
  "question":'질문',
  "question answer":'질문과 답변',
  "question type":'질문 유형',
  "questions":'질문',
  "questions and answers":'질문과 답변',
  "quote":'인용하다',
  "ranking":'순위',
  "rate store":'요금 저장소',
  "rate type":'속도 유형',
  "rating":'등급',
  "rating from customers":'고객의 등급',
  "rating successful":'평가 성공',
  "ready to ship":'배송 준비',
  "readytopublish":'게시 준비',
  "readytopublishtips":'모든 사람에게 공개적으로 기꺼이 콘텐츠',
  "reasons":'원인',
  "received":'받았다',
  "received unit":'수신 된 단위',
  "receiver":'수화기',
  "receiver information":'수신기 정보',
  "recent searches":'최근 검색',
  "recommend":'추천하다',
  "recommend activities":'제안 된 활동',
  "recommend events":'제안 된 이벤트',
  "recommend groups":'제안 된 그룹',
  "recommend needs by following":'제안 된 크라우드 펀딩',
  "recommend store":'상점을 추천합니다',
  "recommend tags":'태그를 추천합니다',
  "recommend users":'제안 된 친구',
  "recommend beneficiaries":'제안 된 수혜자',
  "recruitment for good":'좋은 채용',
  "recruitment question":'채용 질문',
  "recruitment_email":'채용 이메일',
  "recruitment_phone":'채용 전화',
  "red":'빨간색',
  "reduced inequalities":'불평등 감소',
  "ReducedInequalities":'감소',
  "reference":'참조',
  "regconfirmpass":'비밀번호 확인',
  "register":'등록하다',
  "register enabled":'등록 활성화',
  "register to donate":'기부에 등록하십시오',
  "register value":'등록 가치',
  "registered number":'등록 번호',
  "registered to donate":'기부에 등록',
  "regpass":'비밀번호',
  "regular":'정기적인',
  "regulartips":'정기적 인 활동',
  "reject":'거부하다',
  "rejected":'거부',
  "rejected applicants":'거부 된 지원자',
  "rejectedtips":'내용은 적절하지 않습니다. 다시 확인하고 게시 요청을 제출하십시오',
  "related tags":'관련 태그',
  "religion":'종교',
  "remain":'유지하다',
  "remember":'로그인 유지',
  "remotely work":'원격으로 작동합니다',
  "remove":'제거하다',
  "remove comment":'주석을 제거하십시오',
  "remove from your order":'주문에서 제거하십시오',
  "removed":'제거됨',
  "replies":'답장',
  "reply":'회신하다',
  "reply customer":'답장 고객',
  "reply from the store":'상점에서 답장하십시오',
  "replyplaceholder":'답장을 입력 ...',
  "report":'보고서',
  "report violation shop":'위반 상점을보고합니다',
  "reported":'보고 된',
  "reports":'보고서',
  "request":'요구',
  "requested":'보낸 요청',
  "required":'필수의',
  "required information fields":'필요한 정보 필드',
  "requirements":'요구 사항',
  "reset":'초기화',
  "Reset your password":'비밀번호를 재설정',
  "responded":'응답했습니다',
  "responsible consumption and production":'책임있는 소비 및 생산',
  "results with keyword":'키워드와 함께 결과',
  "return conditions":'반환 조건',
  "return period":'반환 기간',
  "return period type":'반환 기간 유형',
  "return period value":'반환 기간 값',
  "return policy":'반품 정책',
  "return to draft":'드래프트로 돌아갑니다',
  "returned":'반환',
  "retype new password":'새 비밀번호를 다시 입력하세요',
  "Retype password":'비밀번호를 다시 입력하십시오',
  "review and rating on store":'상점에서 검토 및 등급',
  "review management":'검토 관리',
  "review store":'스토어를 검토하십시오',
  "reviews":'리뷰',
  "role":'역할',
  "role description":'역할 설명',
  "rotate":'회전',
  "running promotion campaigns":'홍보 캠페인 실행',
  "salary":'샐러리',
  "salary period":'급여 기간',
  "sale":'판매',
  "sanitary food":'위생 음식',
  "save":'구하다',
  "save data":'데이터를 저장',
  "save question":'질문을 저장하십시오',
  "scale":'규모',
  "scan code on Wishare for direct access to the target screen":'Wishare에서 코드를 스캔하여 대상 화면으로 리디렉션',
  "scan qr-code":'QR 코드를 스캔하십시오',
  "schedule":'일정',
  "school":'셜',
  "School Book":'학교 책',
  "sdg":'SDG',
  "search":'찾다',
  "search activities":'검색 활동',
  "search category":'검색 범주',
  "search events":'검색 이벤트',
  "search groups":'검색 그룹',
  "search groups users activities you want to be hosts":'검색 그룹, 사용자, 호스트가되고 싶은 활동',
  "search groups,users,activities you want to be hosts":'검색 그룹, 사용자, 호스트가되고 싶은 활동',
  "search in wishop":'Wishop에서 검색 ...',
  "search members":'검색 회원 ...',
  "search needs":'검색 요구',
  "search jobs":'검색 작업',
  "search product to attach":'첨부 할 제품을 검색하십시오',
  "search selected category":'선택한 범주를 검색합니다',
  "search user":'검색 사용자',
  "search beneficiaries":'소원 검색 ...',
  "searching":'수색',
  "searching result is empty":'검색 결과는 비어 있습니다',
  "searching volunteers":'자원 봉사자 검색',
  "searchplaceholder":'찾다..',
  "seconds(s)":'초 (s)',
  "secret":'비밀',
  "secrettips":'이 페이지는 내부의 공식 회원이 회원을보고 공유하고 메시지를 게시 할 수 있음을 보여줍니다.',
  "security policy":'보안 정책',
  "see all":'모두보기',
  "see less":'덜 보여주십시오',
  "see map":'지도를 참조하십시오',
  "see more":'더보기',
  "see more jobs":'더 많은 일자리를보십시오',
  "seek financial capital for good initiatives":'좋은 이니셔티브를 위해 금융 자본을 찾으십시오',
  "select":'선택하다',
  "select all":'모두를 선택하십시오',
  "select attribute...":'속성 선택 ...',
  "select location":'위치를 선택하십시오',
  "select location on map":'지도에서 위치를 선택하십시오',
  "select option":'옵션을 선택하십시오',
  "select the payment method":'결제 방법을 선택하십시오',
  "select the product catalog for your store":'상점의 제품 카탈로그를 선택하십시오',
  "select the topic category you are interested in by clicking on the items below":'아래 항목을 클릭하여 관심있는 주제 범주를 선택하십시오.',
  "select_conditions":'선택 조건',
  "select_conditions_placeholder":'선택 조건',
  "Select...":'선택하다...',
  "Sell as lot":'많이 팔아',
  "selling price":'판매 가격',
  "send a message to <%= title %> with questions or comments about this volunteer opportunity.":'이 자원 봉사 기회에 대한 질문이나 의견과 함께 < %= title %>로 메시지를 보내십시오.',
  "send feedback":'피드백을 보내십시오',
  "send to":'보내십시오',
  "send to an activity":'활동에 보내십시오',
  "sender":'보내는 사람',
  "senior advisor":'선임 고문',
  "sent":'전송된',
  "sequence":'순서',
  "sequence / parallel":'시퀀스 :* 프로그램의 제품은 각 제품을 주문 세트로 경매합니다. 시간 / 병렬로 단일 세션 만 :* 같은 프로그램의 제품은 다른 세션에서 동시에 경매됩니다.',
  "set as default":'기본값으로 설정',
  "setting":'환경',
  "settings":'설정',
  "share":'공유하다',
  "share options":'공유 옵션',
  "share to multipal pages":'다중 페이지에 공유하십시오',
  "share to my page":'내 페이지에 공유하십시오',
  "share with everyone":'모두와 공유하십시오',
  "shared":'공유',
  "shipped":'배송',
  "shop":'가게',
  "shop description":'상점 설명',
  "shop for you":'당신을 위해 쇼핑하십시오',
  "shop infomation":'쇼핑 infomation',
  "shop product":'상점 제품',
  "shop products faq":'쇼핑 제품 FAQ',
  "shopping":'쇼핑',
  "shopping management":'쇼핑 관리',
  "short":'짧은',
  "should change status to publishrequest to complete":'완료 요청을 승인하기 위해 상태를 변경해야합니다',
  "show":'보여주다',
  "show map":'지도 표시',
  "show profile":'프로필 표시',
  "show result for":'결과를 보여줍니다',
  "sign":'징후',
  "Sign in":'로그인',
  "sign in to connect":'커뮤니티와 연결하려면 로그인하십시오',
  "Sign up":'가입하기',
  "signed":'서명',
  "signin":'로그인',
  "silent mode":'무음 모드',
  "single":'하나의',
  "singletips":'하나의',
  "sitemap":'사이트 맵',
  "Size":'크기',
  "skills":'기술',
  "social activities exits everywhere, form individuals, NPOs or enterprises":'사회 활동은 어디서나 빠져 나가 개인, NPO 또는 기업을 형성합니다.',
  "social capital":'사회적 \ ncapital',
  "social worker":'사회 사업가',
  "sold":'판매된',
  "sold by":'판매',
  "sold price":'판매 가격',
  "sold quantity":'수량을 판매했습니다',
  "specific return policy":'특정 반환 정책',
  "specific warranty policy":'특정 보증 정책',
  "specific_activity":'특정 활동',
  "specific_activity_name":'특정 활동 이름',
  "specifications":'명세서',
  "sponsors for the project":'프로젝트 후원자',
  "sport":'스포츠',
  "sports & entertainment":'스포츠 및 엔터테인먼트',
  "Sports & Travel":'스포츠 및 여행',
  "star":'별',
  "stars":'별',
  "start":'시작',
  "start at":'시작합니다',
  "start creating activities, events or groups to declare organizations seeking volunteers (if you\'ve shared organizational information, go to step 3)":'자원 봉사자를 찾는 조직을 선언 할 활동, 이벤트 또는 그룹 만들기 시작 (조직 정보를 공유 한 경우 3 단계로 이동)',
  "start date":'시작 날짜',
  "start_at":'시작합니다',
  "start_date":'시작 날짜',
  "start_time":'시작 시간',
  "status":'상태',
  "statusplaceholder":'상태를 선택하십시오',
  "step":'단계',
  "step 1":'1 단계',
  "step 2":'2 단계',
  "step 3":'3 단계',
  "step 4":'4 단계',
  "step 5":'5 단계',
  "step 6":'6 단계',
  "Steps to create an activity:":'활동을 만드는 단계 :',
  "stop donate":'기부를 중지하십시오',
  "store address":'저장 주소',
  "store description":'상점 설명',
  "store manager":'점장',
  "store name":'가게 이름',
  "store setting":'매장 설정',
  "strategic partner":'전략적 파트너',
  "strategic sponsor":'전략 스폰서',
  "strengthen successful connections based on the":'에 따라 성공적인 연결을 강화하십시오',
  "sub total":'하위 총계',
  "submit":'제출하다',
  "subscription email":'구독 이메일',
  "subscription_email":'이메일을 구독하여 시스템에서 새로운 알림을받을 수 있습니다.',
  "success":'성공',
  "successful":'성공적인',
  "support":'지원하다',
  "Support the UN Global Goals":'유엔 글로벌 목표를 지원합니다',
  "supported":'지원',
  "supporthelp":'지원 센터',
  "supporthelptips":'가이드, Q & A, 도움말 센터',
  "sustainable cities and communities":'지속 가능한 도시와 지역 사회',
  "SustainableCitiesAndCommunities":'지속 정밀도 및 커뮤니티',
  "system error":'죄송합니다. 앱에 문제가있었습니다',
  "tag":'꼬리표',
  "tags":'태그',
  "tags for you":'당신을위한 태그',
  "tagsplaceholder":'태그를 선택하십시오',
  "take":'가져가다',
  "target":'표적',
  "teacher, teaching assistant":'교사, 조교',
  "team":'팀',
  "temporary winner":'임시 우승자',
  "terms":'자귀',
  "terms of use":'서비스 약관',
  "thank you":'감사합니다',
  "thank you for registering to donate":'기부에 등록해 주셔서 감사합니다',
  "Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.":'온라인 피드백 양식을 작성하는 데 시간을내어 주셔서 감사합니다. 귀하의 의견을 제공함으로써 귀하는 우리가 잘하는 일과 구현해야 할 개선을 이해하도록 돕고 있습니다.',
  "thank you for your feed back to product and shop":'제품 및 쇼핑에 대한 피드백에 감사드립니다.',
  "the 17 goals":'유엔의 17 가지 목표',
  "the accept action was successful":'수락 행동이 성공적이었습니다',
  "the activation email has been sent to":'활성화 이메일이 전송되었습니다',
  "the causes that we serve":'우리가 봉사하는 원인',
  "the community creates social impact, does the good things and lets the wind blow away":'커뮤니티는 사회적 영향을 야기하고, 좋은 일을하고 바람을 날려 버립니다.',
  "The following topics are currently merged into this topic:":'다음 주제는 현재이 주제로 병합되어 있습니다.',
  "The information given within the Feedback Form will be used for service improvement only and are strictly confidential.":'피드백 양식 내에 제공된 정보는 서비스 개선에만 사용되며 엄격하게 기밀입니다.',
  "the information has been saved successful":'정보가 성공적으로 저장되었습니다',
  "the item has been saved":'항목이 저장되었습니다',
  "the items you are you had accepted to be there hosts":'당신이 그곳에있는 것을 받아들이는 항목',
  "the items you had been requested to be a host":'호스트가되도록 요청받은 항목',
  "the option has been added successful":'옵션이 성공했습니다',
  "The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.":'이 플랫폼은 사회 활동에 중점을 둔 기술 개발을 활용하고 큰 영향을 미치고 자하는 욕구로 LAGE 커뮤니티에서 자신을 모험적으로 이용할 수 있도록 도와줍니다. 이 플랫폼은 MultideVice에서 액세스 할 수 있으며 사람들이 실시간으로 상호 작용하고 커뮤니티의 강력한 지원을 빠르게 동원 할 수 있습니다.',
  "the recruitment in the period":'기간의 채용',
  "the registration of funding for the project":'프로젝트에 대한 자금 등록',
  "the reject action was successful":'거부 조치가 성공적이었습니다',
  "the social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission":'지역 사회 자원을 동원하고 좋은 사명을 구현함으로써 영향을 미치기 위해 열정적 인 사회 활동',
  "the warning has been sent to admin":'경고가 관리자에게 전송되었습니다',
  "there are items that are not shown due to the privacy policies of those items":'개인 정보 및 보안 정책으로 인해 일부 항목이 공개적으로 표시되지 않습니다.',
  "there is no need for transfer":'전송이 필요하지 않습니다',
  "This function is currently not open to everyone, please contact the Wishare administrator":'이 기능은 현재 모든 사람에게 공개되지 않습니다. Wishare 관리자에게 문의하십시오.',
  "This page is collected and indexed by Wishare":'이 페이지는 Wishare에 의해 수집 및 색인화됩니다',
  "This page is collected and indexed by Wishare so that the community can easily find and contact the program":'이 페이지는 커뮤니티가 프로그램을 쉽게 찾아서 접촉 할 수 있도록 Wishare에 의해 수집 및 인덱싱됩니다.',
  "this product will extract":'이 제품은 추출됩니다',
  "this will send by admin":'이 콘텐츠는 관리자가 전송합니다',
  "tick size":'진드기 크기',
  "time":'시간',
  "time and location":'시간 및 위치',
  "time characteristic":'시간 특성',
  "time open":'시간이 열립니다',
  "time_close":'종료 시간',
  "time_open":'시작 시간',
  "time_type":'시간 유형',
  "timeline":'타임 라인',
  "title":'제목',
  "titleplaceholder":'여기에 제목을 작성하십시오.',
  "to":'에게',
  "to develop the community or":'커뮤니티를 개발하기 위해 또는',
  "to donate":'기부하다',
  "To help beneficiaries find your activity, create your activity on Wishare platform.":'수혜자가 귀하의 활동을 찾도록 돕기 위해 Wishare 플랫폼에서 활동을 만듭니다.',
  "To help beneficiaries find your need, create your activity on Wishare platform.":'수혜자가 귀하의 필요를 찾도록 돕기 위해 Wishare 플랫폼에서 활동을 만드십시오.',
  "to help us continue serving the community":'커뮤니티에 계속 봉사하는 데 도움이됩니다',
  "to now":'지금까지',
  "to support us spread the goodness":'우리를 지원하기 위해 선을 전하십시오',
  "token_expired":'세션은 유효하지 않거나 만료되었습니다. 다시 로그인하십시오',
  "top":'맨 위',
  "total":'총',
  "total donation":'총 기부',
  "total donation values":'총 기부금 가치',
  "total payment":'총 지불',
  "total product amount":'총 제품 금액',
  "total values":'총 값',
  "total_activities":'활동',
  "total_activities_official":'활동 (공식)',
  "total_activities_official_tips":'활동은 지역 사회가 만들고 운영합니다',
  "total_activities_tips":'사회 활동은 만들어 지거나 Wishare에 의해 색인화되어 커뮤니티에 업데이트됩니다.',
  "total_address":'연결',
  "total_crowdfunding_amount":'크라우드 펀딩',
  "total_crowdfunding_amount_tips":'활동과 사회 사업을위한 기금을 모으십시오',
  "total_crowdfundings":'크라우드 펀딩',
  "total_crowdfundings_tips":'사회적, 창의적 목적을위한 크라우드 펀딩',
  "total_donators":'기증자의 총',
  "total_events":'이벤트',
  "total_events_happening":'진행중인 이벤트',
  "total_events_happening_tips":'현재 사건이 일어나고 있습니다',
  "total_events_incoming":'들어오는 이벤트',
  "total_events_incoming_tips":'미래에 시작된 이벤트',
  "total_events_tips":'모든 사건은 일어날 것입니다',
  "total_followers":'추종자',
  "total_following":'수행원',
  "total_groups":'여러 떼',
  "total_links":'연결',
  "total_links_tips":'링크 수, 열망에서 활동을 지원하기 위해 연결',
  "total_members":'회원',
  "total_opportunities":'일자리',
  "total_opportunities_tips":'자원 봉사 및 사회적 영향 직업',
  "total_shares":'주식',
  "total_support":'지원하다',
  "total_views":'총 견해',
  "total_volunteers":'자원 봉사자',
  "total_volunteers_tips":'도움이 필요한 사람들을 연결하고 인적 자원을 받고 사회 사업을 홍보하고 최적화합니다.',
  "total_votes":'투표',
  "total_beneficiaries":'수혜자',
  "total_beneficiaries_done":'완성 된 수혜자',
  "total_beneficiaries_done_tips":'수혜자들은 성공적이었습니다',
  "total_beneficiaries_tips":'모든 수혜자는 Wishare로 보내집니다',
  "trademark":'등록 상표',
  "transfer":'옮기다',
  "transfer all":'모든 전송',
  "trends":'트렌드',
  "trust and safety":'신뢰와 안전',
  "trustworthy efficient transparent":'신뢰할 수 있고 효율적이며 투명합니다',
  "tutorial":'지도 시간',
  "type":'유형',
  "type new password":'새 비밀번호를 입력하십시오',
  "type of group":'그룹의 유형',
  "type of work":'일의 종류',
  "type old password":'오래된 비밀번호를 입력하십시오',
  "type to search":'검색 할 유형',
  "type_association":'협회',
  "type_associationtips":'협회',
  "type_company":'회사',
  "type_companytips":'회사',
  "type_foundation":'기반',
  "type_foundationtips":'기반',
  "type_group":'그룹',
  "type_grouptips":'그룹',
  "type_ngo":'NGO- 비 통치 단체',
  "type_ngotips":'NGO- 비 통치 단체',
  "type_npo":'NPO- 비영리 단체',
  "type_npotips":'NPO- 비영리 단체',
  "type_organization":'조직',
  "type_organizationtips":'조직',
  "type_religion":'종교',
  "type_religiontips":'종교',
  "type_school":'학교',
  "type_schooltips":'학교',
  "typical products":'일반적인 제품',
  "typical shops":'전형적인 상점',
  "typing":'타자',
  "undefined":'한정되지 않은',
  "undefinedtips":'한정되지 않은',
  "unfilter":'필터',
  "unfollow":'팔로우 해제',
  "unit":'단위',
  "unlimited":'제한 없는',
  "unlimited age":'모든 연령',
  "unread":'읽히지 않는',
  "update":'업데이트',
  "update your profile":'프로필을 업데이트하십시오',
  "upload images from your computer":'컴퓨터에서 이미지를 업로드하십시오',
  "user":'사용자',
  "user product faq":'사용자 제품 FAQ',
  "user profile":'유저 프로필',
  "user settings":'사용자 설정',
  "user_not_found":'로그인 세션이 만료되었거나 계정이 잘못되었습니다. 다시 로그인하십시오.',
  "username":'사용자 이름',
  "users":'사용자',
  "value":'값',
  "value type":'가치 유형',
  "value_winner":'가치 우승자',
  "variants":'제품 변형',
  "verify":'확인하다',
  "verifying":'확인',
  "view more":'더보기',
  "view profile":'프로필보기',
  "vision":'비전',
  "Visually impaired people":'시각 장애인',
  "volunteer":'자원 봉사자',
  "volunteer & jobs":'자원 봉사 및 직업',
  "volunteer_profile":'자원 봉사 프로필',
  "volunteers":'자원 봉사자',
  "vote":'투표',
  "voted":'투표',
  "wage":'값',
  "wait for reply":'답장을 기다리십시오',
  "waiting":'대기 중',
  "waiting applicants":'대기 지원자',
  "waiting need":'달리기',
  "want to help":'도와주고 싶어',
  "ward":'구',
  "warned":'경고',
  "warranty & return":'보증 및 반품',
  "warranty conditions":'보증 조건',
  "warranty period":'보증 기간',
  "warranty period type":'보증 기간 유형',
  "warranty period value":'보증 기간 값',
  "warranty policy":'보증 정책',
  "warranty-policies":'보증-폴리시',
  "warranty-return":'보증 및 반품',
  "was accepted for job at":'직장에서 수락되었습니다',
  "we believe that sharing and connecting these beneficiaries will create a great impact":'우리는이 수혜자들을 공유하고 연결하는 것이 큰 영향을 줄 것이라고 믿습니다.',
  "We welcome any feedback, please feel free to contact us":'우리는 모든 피드백을 환영합니다. 언제든지 저희에게 연락하십시오',
  "we\'ve":'우리는 가지고 있습니다',
  "we\'ve found <%= number %> results":'우리는 < %= 숫자 %> 결과를 ​​발견했습니다.',
  "website":'웹 사이트',
  "website_placeholer":'(www.example.com)',
  "week":'주',
  "weeks":'주',
  "weight":'무게',
  "welcome to become wishop salesperson":'Wishop의 영업 사원이 되십시오',
  "what categories are your interests?":'당신의 관심사는 어떤 범주입니까?',
  "what is your position?":'당신의 위치는 무엇입니까?',
  "what we connect":'우리가 연결하는 것',
  "what you can do?":'당신은 무엇을 할 수 있습니까?',
  "what you need the crowdfunding for":'크라우드 펀딩이 필요한 것',
  "what you need volunteers for":'자원 봉사자가 필요한 것',
  "when you have a petition for you or for someone":'당신이나 누군가를위한 탄원이있을 때',
  "where did you work?":'너는 어디에서 일 했니?',
  "where does the opportunity take place?":'기회는 어디에서 이루어 집니까?',
  "which school did you study?":'어느 학교를 공부 했습니까?',
  "who can do for good and make great impact by sharing human capital, financial capital to realise the social mision":'인적 자본을 공유함으로써 선을 위해 할 수 있고 큰 영향을 미칠 수있는 사람, 사회적 비정상을 실현하기위한 금융 자본',
  "why partner with wishare ?":'Wishare와 왜 파트너가됩니까?',
  "Width":'너비',
  "will end in":'끝납니다',
  "win price":'승리 가격',
  "win_price":'승리 가격',
  "winner":'우승자',
  "beneficiary":'수익자',
  "beneficiary list":'수혜자',
  "wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people":'Wishare는 충격, 혁신 및 친절한 사람들에 대한 포부를 연결하는 공유 플랫폼입니다.',
  "Wishare is willing to adjust the content or transfer the page owner right to the program upon request.":'Wishare는 요청시 콘텐츠를 조정하거나 페이지 소유자를 프로그램에 바로 전송하려고합니다.',
  "beneficiaries":'수혜자',
  "beneficiaries for good":'좋은 수혜자',
  "wishop regulation":'Wishop \ \'s 규정',
  "beneficiarytips":'수혜자는 당신이 원하는 좋은 일입니다. 수혜자를 친구, 모든 사람의 지원 동원을 목표로하는 활동에 공유 할 수 있습니다.',
  "with":'~와 함께',
  "with keyword":'키워드와 함께',
  "won session":'승리 세션',
  "work":'일하다',
  "work fit for":'작업에 적합합니다',
  "working date":'작업 날짜',
  "working time":'근무 시간',
  "workplace":'직장',
  "write comment about store":'상점에 대한 의견을 작성하십시오',
  "year":'년도',
  "years":'연령',
  "yes":'예',
  "yes / no":'예 아니오',
  "you":'너',
  "you are the highest bidder":'당신은 가장 높은 입찰자입니다',
  "you can add or change information in the settings":'설정에서 정보를 추가하거나 변경할 수 있습니다.',
  "you don\'t need login to do for good in this platform":'이 플랫폼에서 좋은 것을 위해 로그인이 필요하지 않습니다.',
  "you have 0 new message":'새로운 메시지가 0 개 있습니다',
  "you have completed the registration steps please confirm the information again to finish":'등록 단계를 완료했습니다. 정보를 다시 확인하십시오.',
  "You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.":'또한 기여하기 전에 동원을 읽고 이해해야합니다. 우리는 당신이 필요하지만 당신은 당신의 행동에 책임이 있습니다. 우리는 부정적인 문제를 제한하는 메커니즘을 확립하려고 노력하지만이 모금 활동에 대한 책임도 거부합니다. 우리는 단지 지원 플랫폼 일뿐입니다.',
  "you should use a photo to help people recognize your store faster":'사람들이 상점을 더 빨리 인식하도록 돕기 위해 사진을 사용해야합니다.',
  "Create quickly in steps if you don\'t have your own activities and groups yet":'아직 자신의 활동과 그룹이 없으면 단계로 빠르게 만듭니다.',
  "you voted it":'당신은 그것을 투표했습니다',
  "you want to share or to implement your good initiative to the community":'당신은 커뮤니티에 좋은 이니셔티브를 공유하거나 구현하고 싶습니다.',
  "You\'re welcome to be":'당신은 환영합니다',
  "your activities":'당신의 활동',
  "your auction name":'경매 이름',
  "your bid":'귀하의 입찰',
  "your cart currently has <%= number %> pending payment, you want to continue shopping now.":'카트는 현재 < %= number %> 보류 지불을 가지고 있으며 지금 계속 쇼핑을하고 싶습니다.',
  "your cart currently has <%= number %> products, you want to continue shopping now.":'카트에는 현재 < %= number %> 제품이 있으며 지금 계속 쇼핑을하고 싶습니다.',
  "your categories":'당신의 카테고리',
  "your comment":'귀하의 의견',
  "your comment about store":'상점에 대한 귀하의 의견',
  "your friend":'너의 친구들',
  "your group":'당신의 그룹',
  "your order status will be update in":'주문 상태가 업데이트됩니다',
  "your order will be transfer as soon as possible":'주문은 가능한 빨리 이전됩니다',
  "your orders":'당신의 주문',
  "your pending amount":'등록 가치',
  "your profile":'프로필',
  "your profile has been successfully submitted to the employer!":'귀하의 프로필이 고용주에게 성공적으로 제출되었습니다!',
  "your store name":'당신의 상점 이름',
  "zero hunger":'제로 굶주림',
  "zones":'구역',
  "zones_placeholder":'지원되는 구역',
  "give":'주다',
  "receive":'받다',
  "notes on the order":'주문에 대한 메모',
  "closed status":'폐쇄 상태',
  "close status":'가까운 상태',
  "blocked status":'차단 상태',
  "block status":'블록 상태',
  "This content has completed the process, closing the status marks that you are no longer working on this content.":'이 컨텐츠는 프로세스를 완료하여 더 이상이 컨텐츠에서 작업하지 않는 상태 표시를 종료했습니다.',
  "This content is required to blockade, Sessions on this content will be blocked from now.":'이 콘텐츠는 봉쇄하는 데 필요합니다.이 컨텐츠에 대한 세션은 지금부터 차단됩니다.',
  "closed statustips":'이 컨텐츠는 프로세스를 완료하여 더 이상이 컨텐츠에서 작업하지 않는 상태 표시를 종료했습니다.',
  "Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you":'죄송합니다. 우리는 현재 귀하에게 서비스를 제공하는 {N} 제품 만 가지고 있습니다. 수량 이상을 설정하지 마십시오. 감사합니다',
  "let\'s start for free":'무료로 시작하자',
  "delivery method":'배달 방법',
  "Please choose a delivery method":'배달 방법을 선택하십시오',
  "cancel":'취소',
  "Click here to create first contact":'첫 연락처를 만들려면 여기를 클릭하십시오',
  "Enter the email address associated with your account, and we will email you a link to reset your password.":'계정과 관련된 이메일 주소를 입력하면 비밀번호를 재설정 할 링크를 이메일로 보내드립니다.',
  "write":'쓰다',
  "copy link":'링크 복사',
  "share link via messenger":'메신저를 통해 링크를 공유하십시오',
  "share on wishare":'Wishare에 공유하십시오',
  "social network":'소셜 네트워크',
  "Don\'t have a volunteer for this activity or event yet?":'이 활동이나 이벤트에 대한 자원 봉사자가 없습니까?',
  "Tap the \"{1}\" button to complete and receive the Donation Code":'"{1}"버튼을 탭하여 기부 코드를 완료하고받습니다.',
  "share link via QR Code":'QR 코드를 통해 링크를 공유하십시오',
  "User use phone cameras to quickly access this link":'사용자는 전화 카메라를 사용 하여이 링크에 빠르게 액세스하십시오',
  "Below is an incomplete or unconfirmed registration by a fundraising campaign manager.":'아래는 모금 캠페인 관리자가 불완전하거나 확인되지 않은 등록입니다.',
  "Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.":'아래는 기부입니다. 문제가 발생하면 캠페인 관리자에게 문의하십시오. 기부 해 주셔서 감사합니다.',
  "delete register of donation":'기부 등록 삭제',
  "are you sure to remove this donation?":'이 기부금을 제거 하시겠습니까?',
  "approve donation":'기부를 승인하십시오',
  "contact phone":'연락 전화 번호',
  "less":'더 적은',
  "your donation":'기부',
  "This view screen is for the admin of the campaign":'이보기 화면은 캠페인 관리자를위한 것입니다.',
  "On behalf of the fundraising campaign, we would like to sincerely thank our sponsors":'기금 모금 캠페인을 대신하여 스폰서에게 진심으로 감사드립니다.',
  "Select and tap on the package to which you can donate":'기부 할 수있는 패키지를 선택하고 탭하십시오.',
  "Enter another value you want to donate":'기부하려는 다른 가치를 입력하십시오',
  "search with":'검색',
  "for admins":'관리자를 위해',
  "your name":'당신의 이름',
  "contact email":'이메일 연락처',
  "toward":'을 향하여',
  "giving code":'코드 제공',
  "delete giving":'기부금을 삭제하십시오',
  "This giving will be remove?":'이 기부금은 제거 될까요?',
  "beneficiary account no":'수혜자 계정 번호',
  "beneficiary account name":'수혜자 계정 이름',
  "transaction memo":'거래 메모',
  "beneficiary bank name":'수혜자 은행 이름',
  "beneficiary info":'수혜자 정보',
  "This item will be deleted and cannot be recovered":'이 항목은 삭제되며 복구 할 수 없습니다',
  "Value on conversion for items: (quantity) / (Unit)":'항목 변환 가치 : (수량) / (단위)',
  "donate goods equivalent to":'상품과 동등한 상품을 기부하십시오',
  "donation type":'기부 유형',
  "item":'안건',
  "registered to goods equivalent to":'상품을 기부하도록 등록되었습니다',
  "cash":'현금',
  "goods":'상품',
  "cash donations":'현금 기부',
  "goods donations":'상품 기부',
  "It is more blessed to give than to receive.":'받는 것보다주는 것이 더 축복받습니다.',
  "(Acts 20:35).":'(사도 행전 20:35).',
  "Holy Bible":'성경',
  "A dream you dream alone is only a dream. A dream you dream together is reality.":'당신이 꿈꾸는 꿈은 단지 꿈 일뿐입니다. 함께 꿈꾸는 꿈은 현실입니다.',
  "gave away":'포기했습니다',
  "giving type":'주는 유형',
  "giving value":'가치 제공',
  "with an goods equivalent of":'상품과 동등한',
  "with a cash amount of":'현금 금액으로',
  "Are you sure you agreed to accept this person for the recruitment?":'채용을 위해이 사람을 수락하기로 합의 했습니까?',
  "Are you sure you will reject this application for recruitment?":'이 신청서가 채용을 거부 할 것입니까?',
  "Time to be able to do volunteer work":'자원 봉사 활동을 할 수있는 시간',
  "add time to be able to volunteer":'자원 봉사 시간을 추가하십시오',
  "Kind of work what you like":'당신이 좋아하는 일의 종류',
  "The workplace (ward/district/province/country) that is right for you":'당신에게 맞는 직장 (와드/지구/지방/국가)',
  "you want":'당신은 원합니다',
  "anything":'아무것',
  "anywhere":'어딘가에',
  "learn about tools":'도구에 대해 알아보십시오',
  "create an activity for free":'무료로 활동을 만듭니다',
  "how does it work":'어떻게 작동합니까?',
  "petition & connect":'청원 및 연결',
  "petition":'청원',
  "recruitment":'신병 모집',
  "sales":'매상',
  "inputs":'입력',
  "impacts":'영향',
  "outputs/outcome":'출력/결과',
  "fundraising, donation activity":'모금, 기부 활동',
  "recruitment, search for volunteer jobs":'모집, 자원 봉사 직업 검색',
  "sales, create financial resources for social activities":'판매, 사회 활동을위한 재정 자원을 창출합니다',
  "auction, create financial resources for social activities":'경매, 사회 활동을위한 재정 자원을 만듭니다',
  "petition, connect social activities":'청원, 사회 활동을 연결하십시오',
  "community":'지역 사회',
  "0$ - free services":'0 $ - 무료 서비스',
  "serve the community":'지역 사회에 봉사하십시오',
  "donate - sponsor":'기부 - 스폰서',
  "keep us running":'우리를 계속 달리십시오',
  "tools toward impact":'충격을 향한 도구',
  "Support {1} campaign management tools to create impact according to the model of “{2}”.":'{1} 캠페인 관리 도구를 지원하여 "{2}"의 모델에 따라 영향을 미칩니다.',
  "organization - social activities":'조직 - 사회 활동',
  "theory of change":'변화 이론',
  "Strategic planning":'전략 기획',
  "effects":'효과',
  "17 SDG":'17 가지 지속 가능한 개발 목표',
  "create your account":'계정을 만듭니다',
  "launch your activity":'활동을 시작하십시오',
  "A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.":'여행을 시작하고 영향을 추적하는 무료 계정. 활동과 캠페인을 유지하기 위해 시설을 쉽게 만들 수 있습니다.',
  "launch your campaign":'캠페인을 시작하십시오',
  "Manage your recruitment, crowdfunding, auction, selling, events and so on.":'채용, 크라우드 펀딩, 경매, 판매, 이벤트 등을 관리하십시오.',
  "An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.":'개인 또는 시설은 활동, 행사, 상점, 경매를 쉽게 만들 수 있습니다. 각 활동은 각 프로젝트이며, 귀하가 커뮤니티에 기여하는 각 임무입니다.',
  "commitment":'약속',
  "Our solution is constantly improving, but some things will never change":'우리의 해결책은 끊임없이 개선되고 있지만 어떤 것들은 결코 변하지 않을 것입니다.',
  "Totally free, with no fees or commission":'수수료 나 커미션없이 완전 무료',
  "0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.":'0 $. 이것은 지금 그리고 영원히 Wishare를 사용하는 데 드는 비용입니다. 우리는이 모델을 참여하여 커뮤니티에 큰 영향을 미칩니다.',
  "Continuous Improvement":'지속적인 개선',
  "Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.":'NPO, NGO, CSR, 커뮤니티 활동에 대한 듣기 및 깊은 지식에 따라 항상 사용자 경험을 향상시킵니다. 우리는 당신의 영향을 디지털화하고 추적하여 사용자 친화적이고 편리하게 만듭니다.',
  "Free business model":'무료 비즈니스 모델',
  "Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.":'커뮤니티에 무료 서비스를 제공하고자하는 수익 모델은 후원, 커뮤니티의 자발적인 기여 및 광고를 기반으로합니다. 플랫폼을 지속적으로 유지하고 개발하는 데 도움이됩니다.',
  "Campaign on Wishare":'Wishare 캠페인',
  "Wishare and Partners":'Wishare와 파트너',
  "SDG & Category":'SDG 및 카테고리',
  "totally free":'완전 무료',
  "now and forever.":'지금 그리고 영원히.',
  "toward impacts":'충격으로',
  "our partners":'우리의 파트너들',
  "Fundraising and charity management platform.":'모금 및 자선 관리 플랫폼.',
  "Free and transparent.":'자유롭고 투명합니다.',
  "mark":'표시',
  "givings":'경련',
  "cash givings":'현금 경품',
  "goods givings":'상품 경련',
  "impact categories":'영향 범주',
  "Values are summed up from the organization and its activities.":'값은 조직과 그 활동에서 요약됩니다.',
  "co-hosts":'공동 주최자',
  "Use for: blood donation, ... form of donation calculated on each donation":'사용 : 헌혈, ... 각 기부금에 대해 계산 된 기부금 형태',
  "Use for: donate money, donate goods, ... Contributions can be converted to monetary value":'사용 : 기부금, 기부금, ... 기부금은 금전적 가치로 전환 될 수 있습니다.',
  "donation by count":'카운트별로 기부',
  "donation by value":'가치별 기부',
  "blood unit":'혈액 단위',
  "blood":'피',
  "You register to contribute":'기여하기 위해 등록합니다',
  "The organizers will contact you in case of needed. Please add some information":'주최자는 필요한 경우 귀하에게 연락 할 것입니다. 정보를 추가하십시오',
  "contact information":'연락처 정보',
  "impacts report":'영향 보고서',
  "my givings":'내 경향',
  "participate in volunteering":'자원 봉사에 참여하십시오',
  "pending donations":'보류중인 기부',
  "create crowdfunding":'크라우드 펀딩을 만듭니다',
  "what donation?":'어떤 기부?',
  "Choose the right donation type for your campaign":'캠페인에 적합한 기부 유형을 선택하십시오',
  "Money/Goods... can allow donations. (If target is unlimited, please enter value \"0\")":'돈/제품 ... 기부금을 허용 할 수 있습니다. (대상이 무제한 인 경우 "0"값을 입력하십시오)',
  "..., which and how much?":'..., 어느 것이 얼마나 되었습니까?',
  "Choose a campaign creator/initiator for the campaign":'캠페인의 캠페인 제작자/개시자를 선택하십시오',
  "..., and whose?":'... 그리고 누구?',
  "Choose the impacts that the campaign towards":'캠페인에 대한 영향을 선택하십시오',
  "Write a more descriptive description of your campaign information, which helps the community better understand your campaign.":'캠페인 정보에 대한보다 설명적인 설명을 작성하여 커뮤니티가 캠페인을 더 잘 이해할 수 있도록 도와줍니다.',
  "..., donation information and when?":'..., 기부 정보 및 언제?',
  "Donation information, Estimated start and end time of the campaign":'기부 정보, 캠페인의 예상 시작 및 종료 시간',
  "Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section":'정보 초기화를 완료하려면 Create 버튼을 누르십시오. 나중에 구성 섹션에서 더 조정할 수 있습니다.',
  "Leave the value 0 if you do not want a specific goal":'특정 목표를 원하지 않으면 값 0을 남겨 두십시오.',
  "finance":'재원',
  "complete":'완벽한',
  "another donation options":'또 다른 기부 옵션',
  "pending list":'보류중인 목록',
  "type_community":'지역 사회',
  "edit crowdfunding":'크라우드 펀딩 편집',
  "est. value":'동부 표준시. 값',
  "volume":'용량',
  "Rice":'쌀',
  "Food":'음식',
  "Pepper":'후추',
  "Fish sauce":'생선 소스',
  "Bottle":'병',
  "Box":'상자',
  "Instant Noodles":'인스턴트 국수',
  "to beneficiary":'수혜자에게',
  "Successfully transferred to {1} the giving":'기부금 {1}로 성공적으로 전송되었습니다',
  "activities management":'활동 관리',
  "ready for volunteering":'자원 봉사 준비',
  "your curriculum vitae":'이력서 커리큘럼',
  "link to your profile on another site":'다른 사이트의 프로필에 링크',
  "Money/Goods... can be offer. (If target is unlimited, please enter value \"0\")":'돈/상품 ... 제공 할 수 있습니다. (대상이 무제한 인 경우 "0"값을 입력하십시오)',
  "targets":'목표',
  "another giving options":'또 다른 기부 옵션',
  "for activities that make an impact on the community":'지역 사회에 영향을 미치는 활동',
  "search crowdfunding":'크라우드 펀딩을 검색하십시오',
  "search giving":'검색 기부',
  "what offer?":'어떤 주는가?',
  "Choose the right offer type for your campaign":'캠페인에 적합한 제안 유형을 선택하십시오',
  "..., offer information and when?":'..., 정보를 제공하고 언제?',
  "Offer information, Estimated start and end time of the campaign":'캠페인의 정보, 추정 시작 및 종료 시간 제공',
  "..., and impact categories?":'... 그리고 충격 카테고리?',
  "profile link":'프로필 링크',
  "outputs":'출력',
  "outcome":'결과',
  "volunteer job":'자원 봉사 직업',
  "visible":'보이는',
  "invisible":'보이지 않는',
  "ready":'준비가 된',
  "not ready":'준비되지 않았습니다',
  "link to the giving":'기부금에 연결됩니다',
  "donation guide":'기부 가이드',
  "tank":'탱크',
  "link to the donation":'기부금 링크',
  "SGD":'달러 싱가포르',
  "Vegetables":'채소',
  "Oxygen tank":'산소 탱크',
  "Diapers":'기저귀',
  "Bike":'자전거',
  "Milk":'우유',
  "Mask":'마스크',
  "USD":'USD',
  "EUR":'EUR',
  "crowdfunding campaign":'크라우드 펀딩 캠페인',
  "giving campaign":'캠페인 제공',
  "financial balance":'재무 균형',
  "recruitment campaign":'채용 캠페인',
  "for the output of impact activities in the community":'지역 사회의 영향 활동의 결과',
  "make a giving":'기부하십시오',
  "job - volunteer":'직업 - 자원 봉사',
  "features":'특징',
  "Recruit unlimited volunteers":'무제한 자원 봉사자를 모집하십시오',
  "You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.":'당신은 당신이 원하는만큼 많은 기회를 게시 할 수 있으며, 얼마나 많은 자원 봉사자를 모집하는지에는 제한이 없습니다.',
  "Manage your volunteers":'자원 봉사자를 관리하십시오',
  "Once your volunteers start coming in, you\'ll be able to organize, filter and keep track of them.":'자원 봉사자가 들어 오면 정리하고 필터링하고 추적 할 수 있습니다.',
  "Upload and Download your list":'목록을 업로드하고 다운로드하십시오',
  "Start out by uploading your own volunteer list. Don\'t worry, you can download your entire list of volunteers anytime.":'자신의 자원 봉사 목록을 업로드하여 시작하십시오. 걱정하지 마십시오. 언제든지 자원 봉사자 목록을 다운로드 할 수 있습니다.',
  "Volunteer Analytics":'자원 봉사 분석',
  "Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.":'대시 보드는 자원 봉사 허브입니다. 얼마나 많은 사람들이 적극적으로 자원 봉사합니까? 더 이상 보지 마십시오.',
  "Show unlimited giving":'무제한 기부를 보여줍니다',
  "You can post as many giving as you like, and there is no limit on how many giving you give.":'당신은 당신이 원하는만큼 많은 기부금을 게시 할 수 있으며, 당신이주는 수에는 제한이 없습니다.',
  "Manage your Giving (Money and Goods)":'기부금 관리 (돈과 상품)',
  "Once your giving start, you\'ll be able to organize, filter and keep track of them.":'일단 기부를 시작하면, 당신은 조직, 필터링 및 추적 할 수 있습니다.',
  "Start out by uploading your own giving list. Don\'t worry, you can download your entire list of giving anytime.":'자신의 기부 목록을 업로드하여 시작하십시오. 걱정하지 마십시오. 언제든지주는 전체 목록을 다운로드 할 수 있습니다.',
  "Giving Analytics":'분석 제공',
  "Your dashboard is your Giving. Look no further.":'당신의 대시 보드는 당신의 기부입니다. 더 이상 보지 마십시오.',
  "donation - crowdfunding":'기부 - 크라우드 펀딩',
  "Register unlimited donation":'무제한 기부를 등록하십시오',
  "You can post as many donation as you like, and there is no limit on how many donation you receive.":'원하는만큼 기부금을 게시 할 수 있으며 기부금 수에 제한이 없습니다.',
  "Manage your Donations (Money and Goods)":'기부금 관리 (돈 및 상품)',
  "Once your donation start coming in, you\'ll be able to organize, filter and keep track of them.":'기부가 시작되면 조직, 필터링 및 추적을 추적 할 수 있습니다.',
  "Start out by uploading your own donation list. Don\'t worry, you can download your entire list of donor and donation anytime.":'자신의 기부 목록을 업로드하여 시작하십시오. 걱정하지 마십시오. 언제든지 기증자 및 기부금 목록을 다운로드 할 수 있습니다.',
  "Donation Analytics":'기부 분석',
  "Your dashboard is your donation. How many people are actively donors? Look no further.":'당신의 대시 보드는 당신의 기부입니다. 얼마나 많은 사람들이 적극적으로 기부자입니까? 더 이상 보지 마십시오.',
  "stop subscribe":'구독 중지하십시오',
  "subscribe":'구독하다',
  "giving value must be large than 0":'값을 기부하는 것은 0보다 크야합니다',
  "The object of the giving campaign":'기부 캠페인의 대상',
  "the option has been removed successful":'옵션이 성공적으로 제거되었습니다',
  "the option has been edited successful":'옵션이 성공적으로 편집되었습니다',
  "click here to add new giving package":'새로운 기부 패키지를 추가하려면 여기를 클릭하십시오',
  "Enter another value you want to giving":'주고 싶은 다른 가치를 입력하십시오',
  "Tap to sign up for the package you want to receive":'받고 싶은 패키지에 가입하려면 탭하십시오.',
  "Tap the \"{1}\" button to complete and receive the giving Code":'"{1}"버튼을 눌러 완료하고주는 코드를받습니다.',
  "recipient":'받는 사람',
  "You are registered to get":'당신은 등록되어 있습니다',
  "registered to get":'얻기 위해 등록되었습니다',
  "Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page":'구독이 녹음되었습니다. 캠페인 페이지의 목록에서 구독 상태 업데이트를 따르십시오.',
  "your subscription":'구독',
  "delete register of giving":'기부 레지스터를 삭제하십시오',
  "are you sure to remove this giving?":'이 기부금을 제거 하시겠습니까?',
  "remove this giving":'이 기부금을 제거하십시오',
  "approve giving":'기부를 승인하십시오',
  "{1} registered to get":'{1}가 등록되었습니다',
  "short answer":'짧은 답변',
  "long answer":'긴 대답',
  "answer guide":'답변 가이드',
  "mandatory question":'필수 질문',
  "answer type":'답변 유형',
  "answer options":'답변 옵션',
  "add more question":'더 많은 질문을 추가하십시오',
  "add option":'옵션을 추가하십시오',
  "optional question":'선택적 질문',
  "additional questions":'추가 질문',
  "total target":'총 대상',
  "additional donor information":'추가 기증자 정보',
  "medal":'메달',
  "Send a thank you email to donor":'기부자에게 감사 이메일을 보내십시오',
  "send thank you email":'감사 이메일을 보내십시오',
  "Deleting this content will not allow recovery, do you want to continue?":'이 컨텐츠를 삭제하면 복구가 허용되지 않습니다. 계속 하시겠습니까?',
  "introduction":'소개',
  "decent work and economic growth":'괜찮은 일과 경제 성장',
  "Please answer the following questions to complete registration":'등록을 완료하려면 다음 질문에 답하십시오',
  "registration questions":'등록 질문',
  "change donation status to pending approval":'기부금 상태를 보류중인 승인으로 변경합니다',
  "accept application":'신청서를 수락하십시오',
  "reject application":'응용 프로그램을 거부하십시오',
  "create new":'새로운 창조',
  "hour":'시간',
  "timesheet":'시간 시트',
  "timesheets":'시간 시트',
  "task":'일',
  "send email notifications and reminders to complete donation":'기부금을 완료하기 위해 이메일 알림 및 알림을 보내십시오',
  "duration":'지속',
  "A donation confirmation email has been sent to you via the email address you entered.":'기부 확인 이메일이 입력 한 이메일 주소를 통해 귀하에게 전송되었습니다.',
  "blood volume":'혈액량',
  "campaign overview":'캠페인 개요',
  "donation campaign":'기부 캠페인',
  "targets of the campaign":'캠페인의 목표',
  "impacts towards":'미치는 영향',
  "impact summary report":'영향 요약 보고서',
  "graph of donation / giving":'기부 / 기부 그래프',
  "goals to be achieved":'달성해야 할 목표',
  "the remaining target of the campaign":'캠페인의 나머지 목표',
  "original goal":'원래 목표',
  "achieved":'달성',
  "campaign needs more":'캠페인에는 더 많은 것이 필요합니다',
  "details over time":'시간이 지남에 따라 세부 사항',
  "donation date":'기부일',
  "donor name":'기증자 이름',
  "unit name":'단위 이름',
  "giving date":'주는 날짜',
  "donation campaign report":'기부 캠페인 보고서',
  "this report is only for campaign admins":'이 보고서는 캠페인 관리자를위한 것입니다',
  "list of work done by volunteers":'자원 봉사자가 수행 한 작업 목록',
  "Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.":'참고 : 때때로 이메일이 스팸 폴더 (SPAM)로 실수로 전송되므로 스팸 폴더도 확인하십시오.',
  "application file":'응용 프로그램 파일',
  "profile management":'프로필 관리',
  "email has been successfully sent!":'이메일이 성공적으로 전송되었습니다!',
  "Are you sure?":'확실합니까?',
  "closedgrouptips":'폐쇄 된 그룹',
  "donate blood":'헌혈하다',
  "register to donate blood":'혈액을 기증하려면 등록하십시오',
  "organizations":'조직',
  "no limit":'제한 없음',
  "expiration":'만료',
  "my bookmarks":'내 북마크',
  "suggested channels":'제안 된 채널',
  "shortcuts":'당신의 지름길',
  "contribution":'당신의 기여',
  "grateful":'고마워하는',
  "code":'암호',
  "you have registered to donate":'기부하도록 등록했습니다',
  "administrators panel":'관리자 패널',
  "beneficiary information":'수혜자 정보',
  "account number":'계좌 번호',
  "bank name":'은행 이름',
  "transfer contents":'전송 내용',
  "Successfully transferred to":'성공적으로 전송되었습니다',
  "a gift":'선물',
  "you register to receive gifts":'당신은 선물을 받기 위해 등록합니다',
  "your register":'당신의 레지스터',
  "requirement":'요구 사항',
  "time location":'시간 및 위치',
  "the target of the activity":'활동의 목표',
  "validation error":'유효성 검사 오류',
  "advanced settings":'고급 설정',
  "go to setting":'설정으로 이동하십시오',
  "content is filtered by":'내용은 필터링됩니다',
  "congratulation":'축하합니다',
  "You have completed the steps of creating activity":'활동을 만드는 단계를 완료했습니다',
  "Hope your activity will be successful and help many people":'당신의 활동이 성공하고 많은 사람들을 돕기를 바랍니다.',
  "attention":'주목',
  "note create description":'자세한 내용을 추가하려면 "설정"섹션으로 이동해야합니다. 바로 여기 "설정"섹션으로 이동 하시겠습니까?',
  "note target description":'주요 목표는 전체 캠페인이 달성 해야하는 목표이며,이를 다른 하위 목표로 분류 할 수 있습니다. 그렇게하려면 나머지 단계를 완료 한 후 추가하려면 "설정"섹션으로 이동하십시오.',
  "open/close":'지속',
  "registrable":'등록 가능',
  "You have completed the steps to create a campaign":'캠페인을 만들기위한 단계를 완료했습니다',
  "Hope your campaign will be successful and help many people":'캠페인이 성공하고 많은 사람들을 도와주기를 바랍니다.',
  "create recruitment":'채용을 만듭니다',
  "recruitment type":'채용 유형',
  "job":'직업',
  "work time":'근무 시간',
  "full time":'풀 타임',
  "part time":'파트 타임',
  "remote":'원격',
  "position_title":'위치',
  "donation_event":'기부',
  "giving_event":'기부',
  "recruitment_event":'신병 모집',
  "{n} categories":'{n} 카테고리',
  "{n} languages":'{n} 언어',
  "{n} provider(s)":'{n} 조직 (들)',
  "1 year":'매년',
  "1D":'일일',
  "1M":'월간 간행물',
  "1Y":'일년',
  "2 years":'2 년',
  "2Y":'2 년',
  "3 months":'3 개월',
  "3M":'3 개월',
  "6 months":'6 개월',
  "6M":'6 개월',
  "about FeedIn":'Feedin에 대해',
  "about-us":'우리에 대해',
  "accept invitation":'초대를 수락',
  "accepted as member":'회원으로 받아 들여집니다',
  "access denied":'접근 불가',
  "account settings":'계정 설정',
  "active subscription":'활성 구독',
  "add chart":'차트 추가',
  "add image":'이미지 추가',
  "add link":'링크를 추가하십시오',
  "add map":'지도를 추가하십시오',
  "add money to wallet":'지갑에 돈을 추가하십시오',
  "Add new and manage members here {here}":'새로 추가하고 여기에 멤버를 관리하십시오 {here}',
  "add new answer":'새로운 답변을 추가하십시오',
  "add new contact":'새로운 연락처를 추가하십시오',
  "add new location":'새로운 위치를 추가하십시오',
  "add payment to keep using {subscription} or change back to free":'{subscription}을 계속 사용하기 위해 결제를 추가하거나 무료로 다시 변경하십시오.',
  "add payment to keep using or change back to free":'계속 사용하거나 무료로 다시 변경하기 위해 지불을 추가하십시오.',
  "add photo":'사진 추가',
  "add price":'가격을 추가하십시오',
  "Add product photos":'제품 사진 추가',
  "add subscriber":'가입자를 추가하십시오',
  "Add Tax Number":'세금 번호를 추가하십시오',
  "Add to Home Screen":'홈 화면에 추가',
  "add video":'비디오 추가',
  "adding content to the channel is limited due to its policies":'정책으로 인해 채널에 컨텐츠 추가가 제한됩니다.',
  "additional details":'추가 세부 사항',
  "admin description":'관리자 역할',
  "advanced filter":'고급 필터',
  "After select, click Save to finish":'선택 후 저장을 클릭하여 완료하십시오',
  "after your trial ends":'시험이 끝난 후',
  "all categories":'모든 카테고리',
  "all dataset":'모든 데이터 세트',
  "all products":'모든 상품',
  "all vietnam":'베트남의 모든 것',
  "allow add new answers":'새 답변을 추가 할 수 있습니다',
  "Allow channel content to be found through search or suggestion":'검색 또는 제안을 통해 채널 컨텐츠를 찾을 수 있습니다.',
  "Allow users to find your channel via search function.":'사용자가 검색 기능을 통해 채널을 찾을 수 있습니다.',
  "allowed be found":'허용됩니다',
  "Alternate Email":'다른 이메일',
  "Alternate Mobile No.":'대체 모바일 번호',
  "Alternate Phone Number":'대체 전화 번호',
  "alternative phone":'대체 전화',
  "Annual Turnover":'연간 매출',
  "another":'또 다른',
  "answers":'답변',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this":'누구나 데이터의 내용을 볼 수 있습니다. 회원만이 기사를 게시하고 이에 대해 자유롭게 의견을 제시 할 수 있습니다.',
  "Are you sure delete this article?":'이 기사를 삭제합니까?',
  "Are you sure delete this chart?":'이 차트를 삭제합니까?',
  "Are you sure delete this poll?":'이 설문 조사를 삭제합니까?',
  "Are you sure delete this task?":'이 작업을 삭제합니까?',
  "Are you sure delete this template?":'이 템플릿을 삭제합니까?',
  "article":'기사',
  "articles":'조항',
  "ask for the best deal":'최선의 거래를 위해',
  "automatically register with Google, Facebook... account":'Google, Facebook ... 계정에 자동으로 등록하십시오',
  "available balance":'사용 가능한 잔액',
  "Back Home":'집으로 돌아가',
  "back to account":'다시 계정으로 돌아갑니다',
  "ban":'반',
  "banned":'금지',
  "banned. can not post any":'금지. 게시 할 수 없습니다',
  "bans":'금지',
  "Basic":'기초적인',
  "basic info":'기본 정보',
  "because you are looking for {keyword}":'{keyword}를 찾고 있기 때문에',
  "Become a provider?":'조직이 되나요 ?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service":'검증 된 판매자가되어 Feedin {Check2}에 대한 {check1} 더 높은 목록을 얻으십시오. 더 많은 구매자 문의 {check3} 우선 숫자 서비스',
  "bill estimate":'청구서 추정',
  "billing":'청구',
  "billing address":'청구 지 주소',
  "billing contact":'청구 연락처',
  "billing details":'결제 세부 정보',
  "billing history":'청구 기록',
  "billing information":'청구 정보',
  "billing overview":'청구 개요',
  "billing period":'청구 기간',
  "birthday":'생일',
  "bookmark":'서표',
  "bookmark all":'모두 북마크',
  "bookmarked":'북마크',
  "brief introduction to yourself":'자신에 대한 간단한 소개',
  "bulk price update":'대량 가격 업데이트',
  "bulletin":'회보',
  "by categories":'카테고리 별',
  "by clicking Submit, you agree to the policy of FeedIn":'제출을 클릭하면 Feedin 정책에 동의합니다.',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.":'제출을 클릭하면 Feedin {term1} 및 {term2} 정책에 동의합니다.',
  "By clicking Subscribe, you agree to {term1} and {term2}.":'구독을 클릭하면 {term1} 및 {term2}에 동의합니다.',
  "by making a channel private, only members will have access to this channel":'채널을 비공개로 만들면 회원만이 채널에 액세스 할 수 있습니다.',
  "by using FeedIn, you agree to our":'Feedin을 사용함으로써 귀하는 우리에게 동의합니다',
  "Call":'부르다',
  "Call verification":'통화 확인',
  "catalog":'목록',
  "categories affect how your story appears in public":'카테고리는 당신의 이야기가 공개적으로 나타나는 방식에 영향을 미칩니다',
  "category groups":'카테고리 그룹',
  "change address":'주소를 바꾸다',
  "change display title / subtitle / display image / categories":'디스플레이 제목 / 자막 / 디스플레이 이미지 / 카테고리 변경',
  "change following categories":'카테고리를 다음 범주로 변경하십시오',
  "change password":'비밀번호를 변경하십시오',
  "change preview photo":'미리보기 사진을 변경하십시오',
  "change role":'변화 역할',
  "change subscription":'구독 변경',
  "changes here will affect how your story appears in public places like Article\'s homepage - not the story itself.":'여기서 변경 사항은 기사 자체가 아닌 기사의 홈페이지와 같은 공공 장소에 이야기가 나타나는 방식에 영향을 미칩니다.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.":'감시 목록 변경은 {m} 시간 내에 허용됩니다. 다음 변경은 {d} 일에 이루어집니다.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account\'s configuration to edit":'이 화면에서 언어를 변경하면 선택한 언어에 맞게 응용 프로그램에 표시된 레이블이 변경됩니다. 언어 별 컨텐츠 필터링을 설정하려면 계정 구성에 액세스하려면 편집하십시오.',
  "channel":'채널',
  "channel name":'채널 이름',
  "channel subscriptions":'채널 구독',
  "channels":'채널',
  "channels in category":'카테고리의 채널',
  "Channels that you may be interested in":'관심이있는 채널',
  "channels to follow":'따라야 할 채널',
  "charged fee data":'청구 된 수수료 데이터',
  "charges apply":'요금이 적용됩니다',
  "chart management":'차트 관리',
  "charts":'차트',
  "chat with provider":'조직과 채팅',
  "check all":'모두 확인',
  "check your email and click on the link":'이메일을 확인하고 링크를 클릭하십시오',
  "choose another FeedIn subscription":'다른 Feedin 구독을 선택하십시오',
  "choose file":'파일을 선택',
  "Choose language":'언어를 선택하십시오',
  "Choose one from your existing conversations, or start a new one.":'기존 대화에서 하나를 선택하거나 새로운 대화를 시작하십시오.',
  "Choose the channel for posting":'게시 할 채널을 선택하십시오',
  "Choose to follow the information catalog to get more news on your wall":'정보 카탈로그를 따라 벽에 더 많은 뉴스를 얻으십시오.',
  "choose your default skin tone":'기본 피부 톤을 선택하십시오',
  "city":'도시',
  "clear all":'모든 것을 지우십시오',
  "clear role permissions":'명확한 역할 권한',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.":'추적하려는 차트 페이지에서 {icon} 버튼을 클릭하면 차트가 여기에서 정기적으로 업데이트됩니다.',
  "click to upload":'업로드하려면 클릭하십시오',
  "code of conduct":'행동 강령',
  "color theme":'색상 테마',
  "commented your post":'귀하의 게시물에 댓글을 달았습니다',
  "Commodity market information":'상품 시장 정보',
  "company profile":'회사 프로필',
  "confidentiality of member information":'회원 정보의 기밀성',
  "configure content by language":'언어별로 컨텐츠를 구성합니다',
  "Confirmation change email address has been sent to":'확인 변경 이메일 주소가 전송되었습니다',
  "Confirmation letter will be sent to your email address":'확인서는 귀하의 이메일 주소로 전송됩니다',
  "contact details":'연락처 세부 사항',
  "contact info":'연락처 정보',
  "contact messages":'연락처 메시지',
  "contact name":'담당자 이름',
  "contact now":'지금 연락하세요',
  "contact provider":'연락처 조직',
  "contact to member":'회원에게 연락하십시오',
  "Contact us about your bill":'청구서에 대해 문의하십시오',
  "contact with provider":'조직과의 접촉',
  "contact-info":'연락처 정보',
  "contacts":'콘택트 렌즈',
  "content allowed to be found":'내용을 찾을 수 있습니다',
  "content displayed by language":'언어로 표시되는 콘텐츠',
  "content is for members only":'콘텐츠는 회원 전용입니다',
  "Content language":'컨텐츠 언어',
  "Continue to {provider}":'계속 {provider}',
  "Continue to PayPal":'계속해서 PayPal로',
  "conversations":'대화',
  "cookies-policy":'쿠키 정책',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel":'아래 페이지 링크를 친구에게 복사하여 공유하십시오. 모든 사람을 위해 쉽게 액세스 하고이 채널을 따라야합니다.',
  "copyright":'저작권',
  "country":'국가',
  "cover":'씌우다',
  "create a free channel":'무료 채널을 만듭니다',
  "Create a new chart for dataset":'데이터 세트에 대한 새 차트를 만듭니다',
  "Create a new provider so you can post products or manage your information channels.":'제품을 게시하거나 정보 채널을 관리 할 수 ​​있도록 조직을 만듭니다.',
  "create a poll":'설문 조사를 만드십시오',
  "create category":'카테고리 생성',
  "create channel":'채널을 만듭니다',
  "create chart":'차트 생성',
  "create dataset":'데이터 세트를 만듭니다',
  "Create new channel":'새 채널을 만듭니다',
  "create new group":'새 그룹을 만듭니다',
  "create one":'하나를 만듭니다',
  "create product":'제품을 만듭니다',
  "create role":'역할을 만듭니다',
  "Create provider profile":'조직 프로필을 만듭니다',
  "credit/debit card":'신용/직불 카드',
  "custom":'관습',
  "custom url":'사용자 정의 URL',
  "daily":'일일',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.":'Feedin에서 매일 매일 업데이트, 최신 상품, 농업 및 금융 시장 가격.',
  "dark mode":'다크 모드',
  "dark skin tone":'어두운 피부 톤',
  "data":'데이터',
  "data & charts":'데이터 및 차트',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this":'데이터 컨텐츠는 모든 사람이 볼 수 있으며 누구나 자유롭게 가입 할 수 있으며 기사를 게시하고 이에 대해 자유롭게 의견을 제시 할 수 있습니다.',
  "data explorer":'데이터 탐색기',
  "data frequency":'데이터 빈도',
  "data in category":'카테고리의 데이터',
  "data security":'데이터 보안',
  "Data set you might be interested in":'관심있는 데이터 세트',
  "dataset":'데이터 세트',
  "dataset list":'데이터 세트 목록',
  "dataset management":'데이터 세트 관리',
  "dataset subscriptions":'데이터 세트 구독',
  "deactive":'비활성',
  "Declare the location for your provider":'조직의 위치를 ​​선언하십시오',
  "default skin tone":'기본 피부 톤',
  "delete channel":'채널 삭제',
  "delete data":'데이터 삭제',
  "delete dataset":'데이터 세트 삭제',
  "delete provider":'조직 삭제',
  "Deleted article are gone forever. Are you sure?":'삭제 된 기사는 영원히 사라졌습니다. 확실합니까?',
  "Deleted channel are gone forever. Are you sure?":'삭제 된 채널은 영원히 사라졌습니다. 확실합니까?',
  "Deleted dataset are gone forever. Are you sure?":'삭제 된 데이터 세트는 영원히 사라졌습니다. 확실합니까?',
  "Deleted provider are gone forever. Are you sure?":'삭제 된 조직은 영원히 사라졌습니다. 확실합니까?',
  "delivery frequency":'배달 빈도',
  "delivery_time":'배달 시간',
  "designed and provided by":'설계 및 제공',
  "desktop app":'데스크탑 앱',
  "discovery more providers":'더 많은 조직을 발견하십시오',
  "do not show members information for each other members":'서로 회원의 정보를 보여주지 마십시오',
  "do you want cancel the subscription?":'가입 취소를 원하십니까?',
  "do you want to cancel?":'취소 하시겠습니까?',
  "Do you want to deactivate this product?":'이 제품을 비활성화하고 싶습니까?',
  "do you want to logout?":'로그 아웃 하시겠습니까?',
  "document (PDF)":'문서 (PDF)',
  "documentation":'선적 서류 비치',
  "Doesn\'t have any bookmarked item":'북마크 된 항목이 없습니다',
  "downgrade":'다운 그레이드',
  "downgrade subscription":'다운 그레이드 구독',
  "downgrade your subscription to":'구독을 다운 그레이드하십시오',
  "download":'다운로드',
  "draft products":'드래프트 제품',
  "edit message":'메시지 편집',
  "edit name":'이름 편집',
  "edit your post":'게시물을 편집하십시오',
  "editor choices":'편집자 선택',
  "email (example@company.com)":'이메일 (example@company.com)',
  "email account":'이메일 계정',
  "Emoji categories":'이모티콘 카테고리',
  "en":'영어',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.":'비즈니스 이니셔티브를 장려하는 사회적 영향. 오늘날과 내일 세대를위한 지속 가능한 생활 환경을 구축하는 데 중점을두고 있습니다.',
  "enter an url":'URL을 입력하십시오',
  "enter channel name":'채널 이름을 입력하십시오',
  "Enter Dataset Name":'데이터 이름을 입력하십시오',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters":'최소 요구 사항이 20, 최대 4000 자의 메시지 텍스트 입력',
  "Enter products / services search":'제품 / 서비스 검색을 입력하십시오',
  "Enter some information so the provider knows your requirements":'조직이 귀하의 요구 사항을 알 수 있도록 일부 정보를 입력하십시오',
  "enter provider name":'조직 이름을 입력하십시오',
  "enter your email":'이메일을 입력하십시오',
  "enter your keyword":'키워드를 입력하십시오',
  "expires":'만료',
  "Explore related products from verified Companies":'검증 된 조직의 관련 제품을 탐색하십시오',
  "Explore similar products":'비슷한 제품을 탐색하십시오',
  "extra request":'추가 요청',
  "failure purchase!":'실패 구매!',
  "FAQ":'자주하는 질문',
  "favorites categories":'즐겨 찾기 카테고리',
  "Featured Categories":'주요 카테고리',
  "featured charts":'추천 차트',
  "featured topics":'주요 주제',
  "fee management":'수수료 관리',
  "fee settings":'수수료 설정',
  "feed":'밥을 먹이다',
  "FeedIn gives you the latest information on market data.":'Feedin은 시장 데이터에 대한 최신 정보를 제공합니다.',
  "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below":'Feedin은 정보를 상품 범주별로 그룹화하여 정보를 더 잘 액세스하고 누락 된 정보를 피할 수 있도록 도와줍니다. 아래에서 좋아하는 범주를 선택하십시오',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.":'Feedin은 구매자를 조직과 연결하여 농업 사업에 대한 주요 정보 공유 네트워크를 만듭니다.',
  "FeedIn Member Since":'이후 피드 멤버',
  "FeedIn will update the latest and fastest information and data about the goods market to you":'Feedin은 상품 시장에 대한 최신 정보 및 데이터를 귀하에게 업데이트합니다.',
  "file upload failed":'파일 업로드가 실패했습니다',
  "file uploaded successfully":'파일이 성공적으로 업로드되었습니다',
  "filter":'필터',
  "Filter results":'필터 결과',
  "first subscription on":'첫 번째 구독',
  "Flags":'깃발',
  "folders":'폴더',
  "follow more categories":'더 많은 범주를 따르십시오',
  "Follow to get notifications from this channel!":'이 채널에서 알림을 받으려면 팔로우하십시오!',
  "followers":'추종자',
  "following categories":'다음 범주',
  "following channels":'다음 채널',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.":'구매자, 투자자 및 관심있는 사람들을 위해. 관심 분야를 선택하면 전문가 및 최신 시장 뉴스와 연결하도록 제안됩니다.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us":'제품 견적에 대한 문의, 제품 정보 보내기 또는 협력 연락은 당사에 문의하십시오.',
  "for you":'당신을 위한',
  "free":'무료',
  "frequently questions":'자주 질문',
  "Frequently Used":'자주 사용되는',
  "frequently-questions":'자주 질문',
  "From - To":'에서부터',
  "from the sender":'발신자로부터',
  "general permission":'일반 허가',
  "general settings":'일반 설정',
  "Get a quote":'견적',
  "Get Best Price":'최고의 가격을 얻으십시오',
  "Get in touch with us":'우리와 연락하십시오',
  "Get latest price":'최신 가격을 얻으십시오',
  "Get Quotes":'인용문보기',
  "Get quotes from seller":'판매자로부터 따옴표를 얻으십시오',
  "get started with {name}":'{name}로 시작하십시오',
  "Get your business details verified with us for higher rankings in FeedIn!":'Feedin에서 더 높은 순위에 대해 비즈니스 세부 정보를 확인하십시오!',
  "go":'가다',
  "go modal":'모달로',
  "haven\'t comments yet":'아직 댓글이 없습니다',
  "head office":'본점',
  "hello":'안녕하세요',
  "Hello you":'안녕하세요',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.":'사람들이 필요에 따라 최신 시장 정보, 다차원, 기밀 및 사용자 정의를 업데이트하도록 도와줍니다.',
  "Here is a list of all invite links. You can revoke any one.":'다음은 모든 초대 링크 목록입니다. 당신은 하나를 취소 할 수 있습니다.',
  "hide interaction between members":'멤버 간의 상호 작용을 숨기십시오',
  "Hide interactions between members. But, retaining interaction between members and administrators":'회원들 사이의 상호 작용을 숨기십시오. 그러나 회원과 관리자 간의 상호 작용을 유지합니다',
  "If you need assistance, please feel free to contact us via":'도움이 필요하면 언제든지 저희에게 연락하십시오.',
  "If you want to switch to annual payment, {contactus}":'연간 지불로 전환하려면 {contactus}',
  "image":'영상',
  "image must smaller than":'이미지는보다 작아야합니다',
  "incoming requests":'들어오는 요청',
  "index type":'인덱스 유형',
  "index types":'인덱스 유형',
  "info chart editing":'정보 차트 편집',
  "interested":'관심 있는',
  "Interface language":'인터페이스 언어',
  "introduce":'소개하다',
  "invalid template":'잘못된 템플릿',
  "invite and share":'초대하고 공유하십시오',
  "invite code":'코드를 초대하십시오',
  "invite members":'회원을 초대하십시오',
  "invite people":'사람들을 초대하십시오',
  "invite people to join this channel":'이 채널에 참여하도록 사람들을 초대하십시오',
  "invite you to join":'당신을 초대하십시오',
  "Invite your friends to this page":'이 페이지에 친구를 초대하십시오',
  "invited":'초대',
  "invited to become member":'회원이되도록 초대했습니다',
  "inviter":'초대자',
  "invites":'초대',
  "is served by clicking on a":'a를 클릭하여 제공됩니다',
  "item not found":'항목을 찾을 수 없습니다',
  "join channel":'채널에 가입하십시오',
  "Join for free now":'지금 무료로 가입하십시오',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel\'s latest news to you":'채널에 가입하여 기사를 공유하거나 댓글을 올리거나 클릭하여 시스템이 채널의 최신 뉴스를 자동으로 업데이트하도록합니다.',
  "Join this channel to start chatting":'이 채널에 가입하여 채팅을 시작하십시오',
  "keep subscription":'구독을 유지하십시오',
  "keywords":'키워드',
  "kick":'발 차기',
  "kicked":'킥',
  "last updated":'마지막으로 업데이트되었습니다',
  "latest":'최신',
  "latest updates":'최신 업데이트',
  "Latest updates on the commodity market":'상품 시장에 대한 최신 업데이트',
  "leave":'떠나다',
  "leave a message":'메시지를 남겨주세요',
  "Leave a Message, we will contact you back!":'메시지를 남겨주세요. 다시 연락 드리겠습니다!',
  "leave channel":'채널을 떠나십시오',
  "Legal Status of Firm":'회사의 법적 지위',
  "license":'특허',
  "licenses":'라이센스',
  "light skin tone":'가벼운 피부 톤',
  "link":'링크',
  "Link original post":'원본 게시물을 링크합니다',
  "list of data":'데이터 목록',
  "listed products":'나열된 제품',
  "unlisted products":'비상장 제품',
  "Location (Province/district/ward)":'위치 (지방/지구/와드)',
  "location & contact":'위치 및 연락처',
  "location name":'위치 명',
  "Login now":'지금 로그인하십시오',
  "Login now to select and follow the content that you interested in":'지금 로그인하여 관심있는 콘텐츠를 선택하고 따르십시오.',
  "login with account":'계정으로 로그인하십시오',
  "login with Facebook":'Facebook으로 로그인하십시오',
  "login with Google":'Google에 로그인하십시오',
  "Make your channel get more exciting activities by inviting other members":'다른 회원을 초대하여 채널을 더 흥미로운 활동으로 만들 수 있도록',
  "Make your provider get more exciting activities by inviting other members":'다른 회원을 초대하여 조직을 더 흥미로운 활동으로 만들 수 있도록',
  "manage":'관리하다',
  "manage dataset":'데이터 세트를 관리합니다',
  "manage products":'제품 관리',
  "manage services":'서비스 관리',
  "ManageChannelSubscriptions":'채널 구독',
  "managed channels":'관리 된 채널',
  "mark as read":'읽은 상태로 표시',
  "market":'시장',
  "market summary":'시장 요약',
  "mart":'장',
  "me to the channel":'채널에 나',
  "medium skin tone":'중간 피부 톤',
  "medium-dark skin tone":'중간 어두운 피부 톤',
  "medium-light skin tone":'중간 광석 피부 톤',
  "member description":'회원 설명',
  "member management":'회원 관리',
  "members list":'회원 목록',
  "mention":'언급하다',
  "menu":'메뉴',
  "Message":'메시지',
  "messages":'메시지',
  "min_order_qtty":'최소 주문 수량',
  "Minimum payment required":'최소 지불이 필요합니다',
  "miss":'놓치다.',
  "modal":'모달',
  "monthly":'월간 간행물',
  "More data from":'더 많은 데이터',
  "More like This":'더 이렇게',
  "mr":'씨.',
  "mrs":'부인.',
  "ms":'미스',
  "my channels":'내 채널',
  "my page":'나의 페이지',
  "my post":'내 게시물',
  "name of chart":'차트 이름',
  "name of your company":'회사의 이름',
  "Nature of Business":'사업의 성격',
  "new answer":'새로운 답변',
  "New contact":'새로운 연락처',
  "new folder":'새 폴더',
  "new group":'새로운 그룹',
  "New template":'새로운 템플릿',
  "newest data":'최신 데이터',
  "news page":'뉴스 페이지',
  "next change":'다음 변경',
  "No categories have been selected":'카테고리가 선택되지 않았습니다',
  "no categorized":'분류되지 않았습니다',
  "No channel being followed":'채널을 따르지 않았습니다',
  "no channels":'채널이 없습니다',
  "No Data":'데이터가 없습니다',
  "No data available at this time.":'현재 사용 가능한 데이터가 없습니다.',
  "no data found":'데이터가 없습니다',
  "No data set are being followed":'데이터 세트가 따르지 않습니다',
  "no dataset":'데이터 세트가 없습니다',
  "no description":'설명이 없습니다',
  "No Emoji Found":'이모티콘이 발견되지 않았습니다',
  "No filter available":'사용 가능한 필터가 없습니다',
  "no invites yet":'아직 초대가 없습니다',
  "No news":'무소식',
  "no office found":'사무실이 없습니다',
  "No posts were found":'게시물이 없습니다',
  "no results found":'검색 결과가 없습니다',
  "not a member yet?":'아직 회원이 아니세요?',
  "notes about chart!":'차트에 대한 메모!',
  "notification":'공고',
  "Number of Employees":'회원 수',
  "Number of your channels:":'채널 수 :',
  "Objects":'사물',
  "offices":'부엌',
  "OK":'좋아요',
  "oldest data":'가장 오래된 데이터',
  "One more step to become a Verified Seller":'검증 된 판매자가되기위한 한 걸음 더',
  "Only members can view data content, post articles or comment on this":'회원만이 데이터 컨텐츠를 보거나 기사를 게시하거나 이에 대한 의견을 볼 수 있습니다.',
  "oops...":'죄송합니다 ..',
  "opps, no channels available":'OPPS, 사용 가능한 채널이 없습니다',
  "or continue with":'또는 계속하십시오',
  "other categories":'다른 범주',
  "other channels":'다른 채널',
  "other charts":'다른 차트',
  "otherSettings":'다른 설정',
  "Our Channels":'우리 채널',
  "Our data":'우리의 데이터',
  "Our mission is to:":'우리의 사명은 다음과 같습니다.',
  "Our Videos":'우리 비디오',
  "overview":'개요',
  "owner description":'소유자 역할',
  "package in use":'사용중인 패키지',
  "packaging_details":'포장 세부 사항',
  "paid channel":'유료 채널',
  "partner":'파트너',
  "Password and confirm password does not match":'비밀번호 및 확인 비밀번호가 일치하지 않습니다',
  "Password must have min 6 characters":'비밀번호에는 최소 6자가 있어야합니다',
  "Passwords must match":'비밀번호가 일치해야합니다',
  "paste your link here":'여기에 링크를 붙여 넣으십시오',
  "pay":'지불하다',
  "Pay and subscribe":'지불하고 구독하십시오',
  "payment detail":'지불 세부 사항',
  "payment subscription":'결제 구독',
  "Payment via {provider}":'{provider}를 통한 결제',
  "Payment via Paypal":'PayPal을 통한 지불',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay":'{provider}에서 계속 지불하기 위해 지불이 발송됩니다. 지불 할 금액을 확인하십시오',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay":'PayPal에서 계속해서 지불이 발송됩니다. 지불 할 금액을 확인하십시오',
  "paypal":'PayPal',
  "pending approval":'승인 대기 중',
  "people to follow":'따라야 할 사람들',
  "Permanent link":'영구 링크',
  "permission denied":'허가 거부',
  "permissions":'권한',
  "personal":'개인의',
  "personal channels":'개인 채널',
  "personal profile":'개인 프로필',
  "Phone Number":'전화 번호',
  "Phone number how we can contact you":'전화 번호 우리가 연락하는 방법',
  "Pictures on the home page":'홈페이지의 사진',
  "pin":'핀',
  "Pin code":'핀 코드',
  "pinned":'고정',
  "pinned posts":'고정 된 게시물',
  "pinnedPosts":'고정 된 게시물',
  "Please check the information and follow the steps to subscribe.":'정보를 확인하고 구독하려면 단계를 따르십시오.',
  "Please choose to follow related channels for the system to filter the updates to you":'업데이트를 필터링하려면 시스템의 관련 채널을 팔로우하도록 선택하십시오.',
  "Please choose to save the interest charts so you can view them easily each day":'관심있는 차트를 저장하여 매일 쉽게 볼 수 있습니다.',
  "please enter name of product":'제품 이름을 입력하십시오',
  "please input your email":'이메일을 입력하십시오',
  "please input your phone number":'전화 번호를 입력하십시오',
  "Please log in to FeedIn to leave your comment in the post":'Feedin에 로그인하여 게시물에 의견을 남겨주세요.',
  "Please login to use the message function in the system.":'시스템에서 메시지 기능을 사용하려면 로그인하십시오.',
  "Please register to view this content":'이 콘텐츠를 보려면 등록하십시오',
  "please select":'선택 해주세요',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information":'완료하려면 하나 이상의 주제를 선택하십시오. Feedin은 관련 정보를 찾는 데 도움이됩니다',
  "Please select the unit you want to choose as the default":'선택하려는 장치를 기본값으로 선택하십시오.',
  "policy":'정책',
  "poll":'투표',
  "popular":'인기 있는',
  "Post something":'무언가를 게시하십시오',
  "post your images":'이미지를 게시하십시오',
  "postal code":'우편 번호',
  "posted by":'게시자에 의해',
  "posts":'게시물',
  "Premium":'프리미엄',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.":'Premium Channel은 활성화되면 사용자가 채널에 유료로 가입 할 수 있습니다. 채널의 소유자는 회원으로부터 수익을 받게됩니다.',
  "premium providers":'프리미엄 조직',
  "press enter to search":'검색하려면 Enter를 누릅니다',
  "preview description":'미리보기 설명',
  "preview title":'미리보기 제목',
  "pricing":'가격',
  "Primary E-mail":'기본 이메일',
  "Primary Mobile No.":'기본 모바일 번호',
  "privacy settings":'개인 정보 설정',
  "privacy-policy":'개인 정보 정책',
  "private channel":'개인 채널',
  "pro":'찬성',
  "product catalogs":'제품 카탈로그',
  "product certificates":'제품 인증서',
  "product details":'제품 세부 정보',
  "product images":'제품 이미지',
  "product must have at least five character":'제품에는 5 개 이상의 특성이 있어야합니다',
  "product must have at least one image":'제품에는 하나 이상의 이미지가 있어야합니다',
  "product settings":'제품 설정',
  "product specifications":'제품 사양',
  "Product/Service Name":'제품/서비스 이름',
  "production_capacity":'생산 능력',
  "products list":'제품 목록',
  "products page":'제품 페이지',
  "Professional network in the industry":'업계의 전문 네트워크',
  "profile settings":'프로필 설정',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.":'사람들이 지식과 ​​경험을 공유하도록 촉진하여 사람들이 지속적으로 배우고 함께 번영 할 수 있도록하십시오.',
  "provided services":'서비스 제공',
  "Province/City":'지방/도시',
  "Province/district/ward":'지방/지구/와드',
  "provinces":'지방',
  "public timeline":'공개 타임 라인',
  "publish":'게시',
  "published by":'에 의해 출판 된',
  "publisher":'발행자',
  "quick link":'빠른 링크',
  "read":'읽다',
  "read more":'더 읽으십시오',
  "read news":'뉴스를 읽으십시오',
  "ready to publish?":'게시 준비가 되셨습니까?',
  "rebookmark":'Rebookmark',
  "recent news":'최근 뉴스',
  "recently news":'최근 뉴스',
  "Recommendations":'추천',
  "refreshed":'새로 고침',
  "region":'지역',
  "regions":'지역',
  "registered":'등기',
  "Registering to use advanced data, you will have to pay":'고급 데이터를 사용하도록 등록하면 지불해야합니다.',
  "related articles":'관련 기사',
  "related categories":'관련 카테고리',
  "related channels":'관련 채널',
  "related charts":'관련 차트',
  "related keywords":'관련 키워드',
  "Related to items you\'ve viewed":'당신이 본 항목과 관련이 있습니다',
  "relevant":'관련 있는',
  "remove all":'모두 제거',
  "renew / expires on":'갱신 / 만료',
  "replied":'대답했다',
  "Request a call":'전화를 요청하십시오',
  "Request a quote":'조회를 요청하다',
  "request have been sent successfully!":'요청이 성공적으로 전송되었습니다!',
  "request to join":'가입 요청',
  "request-to-join":'가입 요청',
  "requested to join":'가입 요청',
  "required field":'필수 필드',
  "Requirement Details":'요구 사항 세부 사항',
  "resend":'재판소',
  "response rate":'응답 속도',
  "responses":'응답',
  "restricted":'제한된',
  "results":'결과',
  "return home":'귀국',
  "return owner page":'리턴 소유자 페이지',
  "return user page":'반환 사용자 페이지',
  "Review":'검토',
  "role name":'역할 이름',
  "role title":'역할 제목',
  "roles":'역할',
  "sales information":'판매 정보',
  "save & complete":'저장 및 완료',
  "Save and continue":'저장하고 계속',
  "schedule to cancel":'취소 할 일정',
  "search anythings in FeedIn":'Feedin에서 모든 것을 검색하십시오',
  "Search by Name":'이름으로 검색하십시오',
  "Search Results":'검색 결과',
  "secret channel":'비밀 채널',
  "section":'부분',
  "See all charts in the data":'데이터의 모든 차트를 참조하십시오',
  "select / create a group":'그룹을 선택 / 생성합니다',
  "Select a content category or follow the authors to get relevant information":'컨텐츠 범주를 선택하거나 저자를 따라 관련 정보를 얻으십시오.',
  "select category":'카테고리를 선택하십시오',
  "select preview photo":'사진 미리보기를 선택하십시오',
  "Select the display order in the category":'카테고리에서 디스플레이 순서를 선택하십시오',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.":'보고 싶은 콘텐츠 유형을 선택하십시오. Feedin은 귀하의 관심사에 맞는 뉴스를 수집하고 표시합니다.',
  "select your country":'당신의 나라를 선택하세요',
  "selected":'선택된',
  "seller":'파는 사람',
  "Seller Contact Details":'판매자 연락처 세부 정보',
  "seller name":'판매자 이름',
  "send":'보내다',
  "Send Email":'이메일을 보내',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below":'아래 페이지 링크 사본을 보내서 채널을 친구에게 가입하도록 초대장을 보내십시오.',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below":'아래 페이지 링크 사본을 보내서 조직에 가입하도록 초대장을 보내십시오.',
  "send request":'요청을 보내십시오',
  "Send your message to this provider":'이 조직에 메시지를 보내십시오',
  "sending":'배상',
  "sent a request to become member":'회원이되기위한 요청을 보냈습니다',
  "services":'서비스',
  "set main":'메인을 설정하십시오',
  "set up content selection by language":'언어별로 컨텐츠 선택을 설정합니다',
  "share a post":'게시물을 공유하십시오',
  "share button":'공유 버튼',
  "share your ideas":'아이디어를 공유하십시오',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.":'지식이 풍부한 정보 나 정보를 공유하여 귀하의 존재를 향상시키고, 브랜드를 구축하고, 잠재적 파트너와 친구를 사귀거나, 정보를 수수료로 제공하십시오.',
  "Sharing the information":'정보 공유',
  "short description":'간단한 설명',
  "Short description about chart!":'차트에 대한 간단한 설명!',
  "Short description about your company!":'회사에 대한 간단한 설명!',
  "Short description about your dataset!":'데이터 세트에 대한 간단한 설명!',
  "show in category":'카테고리로 표시하십시오',
  "show more":'자세히보기',
  "show this post":'이 게시물을 보여주세요',
  "sign in with":'으로 로그인',
  "signin / signup":'로그인 가입',
  "site":'웹 사이트',
  "sku":'스쿠',
  "Smileys & People":'스마일과 사람들',
  "some messages...some messages...":'일부 메시지 ... 일부 메시지 ...',
  "Sorry, the page you visited does not exist.":'죄송합니다. 방문한 페이지는 존재하지 않습니다.',
  "Sorry, the page you visited has expired or does not exist.":'죄송합니다. 방문한 페이지가 만료되었거나 존재하지 않습니다.',
  "source":'원천',
  "Specification Details":'사양 세부 사항',
  "start free trial":'무료 평가판을 시작하십시오',
  "start trial":'재판을 시작하다',
  "start your no-cost {subscription} trial today":'오늘 No-Cost {Subscription} 시험을 시작하십시오',
  "start your no-cost trial today":'오늘 무료 평가판을 시작하십시오',
  "state":'상태',
  "stay connected":'대기 중',
  "Submit requirement":'요구 사항을 제출하십시오',
  "subscribe channel":'채널 가입',
  "Subscribe to the categories to display the charts you\'re interested in":'관심있는 차트를 표시하려면 카테고리를 구독하십시오.',
  "Subscribe to the channels for information displayed on this page":'이 페이지에 표시된 정보는 채널을 구독하십시오.',
  "subscribed data":'구독 데이터',
  "subscribers":'가입자',
  "subscribing this channel will cost you":'이 채널 가입 비용은 비용이들 것입니다',
  "subscriptions":'구독',
  "successfully purchase!":'성공적으로 구매하십시오!',
  "suggested products":'제안 된 제품',
  "summary":'요약',
  "Summary of the latest news today":'오늘의 최신 뉴스 요약',
  "supplied by":'제공',
  "provider":'조직',
  "provider address":'조직 주소',
  "provider details":'조직 세부 사항',
  "provider homepage":'조직 홈페이지',
  "provider name":'조직 이름',
  "provider profile":'조직 프로필',
  "provider profile was created successful":'조직 프로필이 성공적으로 만들어졌습니다',
  "provider profiles":'조직 프로필',
  "provider\'s channels":'조직의 채널',
  "providers":'조직',
  "Supporting brand promotion and market information to enhance business and networking opportunities.":'비즈니스 및 네트워킹 기회를 향상시키기 위해 브랜드 홍보 및 시장 정보를 지원합니다.',
  "Symbols":'기호',
  "table of contents":'목차',
  "Tap":'수도꼭지',
  "tax":'세',
  "tax code":'세금 코드',
  "Tax ID":'세금 아이디',
  "tax inclusive":'세금 포함',
  "tax number":'세금 번호',
  "Tell us what you need":'필요한 것을 알려주십시오',
  "template":'주형',
  "terms of service":'서비스 약관',
  "terms-of-service":'서비스 약관',
  "the {package} subscription will be continue effect until {date}":'{package} 구독은 {date}까지 계속됩니다.',
  "The banner image will be placed on the company\'s homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.":'배너 이미지는 회사의 홈페이지에 배치됩니다. 256 x 1280 픽셀 해상도로 최상의보기. 이미지 유형 : PNG, JPG.',
  "the channel has been deleted":'채널이 삭제되었습니다',
  "the channel so that the system will automatically help you update with the latest news":'시스템이 최신 뉴스로 자동 업데이트하는 데 도움이되도록 채널',
  "The chart is enabled and ready to be displayed to the user":'차트가 활성화되어 사용자에게 표시 될 준비가되었습니다.',
  "The chart marked as typical and used to represent the data set":'차트는 일반적인 것으로 표시되고 데이터 세트를 나타내는 데 사용됩니다.',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content":'컨텐츠 (이미지, 게시물 ...)는 채널에서 공유되며 채널의 구성원 만 참석하고 토론합니다. 다른 사용자는이 콘텐츠를 보거나 응답 할 수 없습니다',
  "the information that interests you":'관심있는 정보',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other\'s information.":'해당 멤버와 각 멤버의 채널 관리자 간의 상호 작용은 엄격하게 기밀입니다. 채널의 관리자 또는 개인만이 서로의 정보를 볼 수 있습니다.',
  "the latest news":'최신 뉴스',
  "The members shown here include: Owner, administrator, member as contact person.":'여기에 표시된 회원은 다음과 같습니다. 소유자, 관리자, 연락 담당자 회원.',
  "The page content is not yet available or being written":'페이지 컨텐츠는 아직 사용할 수 없거나 작성되지 않습니다.',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.":'당신이 찾고있는 페이지는 존재하지 않습니다. 아래 버튼을 탭하여 홈페이지로 돌아갑니다.',
  "the restore email has been sent to":'복원 이메일이 전송되었습니다',
  "The state of the data set. If \"On\", the dataset is ready to use":'데이터 세트의 상태. "on"인 경우 데이터 세트가 사용할 준비가되었습니다.',
  "the provider has been deleted":'조직이 삭제되었습니다',
  "the providers i am involved in":'내가 참여하는 조직',
  "The tax code value has been saved successfully":'세금 코드 가치는 성공적으로 저장되었습니다',
  "The verification call is pending":'확인 호출이 보류 중입니다',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account":'이 계정은 인증을 위해 이메일 주소를 등록하지 않았습니다. 계정 소유권을 확인하기 위해 이메일 주소를 등록하십시오.',
  "This channel content is limited to members only, join now to view this channel content.":'이 채널 컨텐츠는 멤버로만 제한 되며이 채널 컨텐츠를 보려면 지금 가입하십시오.',
  "This channel doesn\'t have any pinned post":'이 채널에는 고정 된 게시물이 없습니다',
  "this channel supports communication in the form of hidden interactions between members":'이 채널은 회원 간의 숨겨진 상호 작용의 형태로 커뮤니케이션을 지원합니다.',
  "this channel to your friends":'이 채널은 친구들에게',
  "This channel will charge you":'이 채널은 당신을 청구합니다',
  "This is a description.":'이것은 설명입니다.',
  "this is a regular channel":'이것은 일반적인 채널입니다',
  "This is an error message":'이것은 오류 메시지입니다',
  "This product will be removed from your Catalog and won\'t be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?":'이 제품은 카탈로그에서 제거되며 구매자에게는 보이지 않습니다. 또한이 제품에 대한 구매 수신을 점차적으로받지 못할 것입니다. 너 정말 계속하고 싶니?',
  "Time for updating the tracking list:":'추적 목록 업데이트 시간 :',
  "timeline is only viewable with accounts that are members of this channel":'타임 라인은이 채널의 구성원 인 계정에서만 볼 수 있습니다.',
  "timeline is viewed internally only":'타임 라인은 내부적으로 만 볼 수 있습니다',
  "title or question":'질문 진술',
  "to channel":'채널로',
  "to create new channel on your company":'회사에 새 채널을 만들려면',
  "to create your new channel":'새 채널을 만들려면',
  "To edit your details, please visit {profile}":'세부 정보를 편집하려면 {프로필}을 방문하십시오.',
  "to find content that interests you":'관심있는 콘텐츠를 찾으려면',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member":'채널에 가입하고 컨텐츠를 정기적으로 팔로우하려면 로그인 / 등록 (무료) 계정을 회원이 되려면',
  "To make changes to your subscriptions , go to":'구독을 변경하려면 이동하십시오',
  "To manage products visit at {manage_products} or continue with {call_verification}":'제품을 관리하려면 {manage_products}를 방문하거나 {call_verification}을 계속하십시오.',
  "To stay on your current plan after your trial ends, add payment now.":'시험이 끝난 후 현재 계획을 유지하려면 지금 지불을 추가하십시오.',
  "to upgrade. This subscription cost you":'업그레이드. 이 구독료는 비용이 많이 듭니다',
  "topic":'주제',
  "Total due":'총 마감',
  "Total due {date}":'총 기한 {date}',
  "Travel & Places":'여행 및 장소',
  "trial":'재판',
  "trial days":'시험 일',
  "trust and safe":'신뢰와 안전',
  "trust-safe":'신뢰와 안전',
  "tutorials":'튜토리얼',
  "type product name":'제품 이름을 입력하십시오',
  "type your message":'메시지를 입력하십시오',
  "typify chart":'차트를 대표하십시오',
  "unban":'해제',
  "unbookmark":'Unbookmark',
  "unbookmarked":'unbookmarked',
  "unclassified products":'분류되지 않은 제품',
  "unpin":'핀',
  "unpin this?":'이거 핀?',
  "unsave":'없음',
  "unsubscribe":'구독 취소',
  "update about success":'성공에 대한 업데이트',
  "update dataset":'데이터 세트를 업데이트합니다',
  "update fail":'업데이트 실패',
  "update success":'성공을 업데이트하십시오',
  "Updates to you the information you have chosen to follow":'팔로우하기로 선택한 정보를 업데이트합니다.',
  "upgrade":'업그레이드',
  "upgrade now. This subscription cost you":'지금 업그레이드하십시오. 이 구독료는 비용이 많이 듭니다',
  "upgrade subscription":'구독 업그레이드',
  "upgrade this channel":'이 채널을 업그레이드하십시오',
  "upgrade to":'업그레이드',
  "upgrade to {name}":'{name}로 업그레이드',
  "upload":'업로드',
  "url":'URL',
  "us to share the news in this channel":'우리는이 채널에서 뉴스를 공유합니다',
  "Usage: for direct communication purposes through messaging.":'사용법 : 메시징을 통한 직접 커뮤니케이션 목적.',
  "use email":'이메일을 사용하십시오',
  "use the selected system language":'선택한 시스템 언어를 사용하십시오',
  "used group":'중고 그룹',
  "user choices":'사용자 선택',
  "user management":'사용자 관리',
  "uses":'용도',
  "Verification request has been sent, please wait for a response":'확인 요청이 발송되었습니다. 응답을 기다리십시오.',
  "verified provider":'확인 된 조직',
  "Verify Account":'계정 확인',
  "Verify on Call":'전화시 확인하십시오',
  "Verify sales information":'판매 정보를 확인하십시오',
  "vi":'tiệng viết',
  "video url":'비디오 URL (YouTube 링크)',
  "vietnamese":'tiệng viết',
  "view":'보다',
  "view all":'모두보기',
  "View all categories":'모든 카테고리를보십시오',
  "View all products in {name}":'{name}에서 모든 제품보기',
  "View complete detail":'완전한 세부 사항을보십시오',
  "view full estimate":'전체 추정치를보십시오',
  "view more charts":'더 많은 차트를보십시오',
  "view product page":'제품 페이지를 봅니다',
  "views":'견해',
  "visit page":'페이지 방문',
  "Visit the page to preview":'미리보기를하려면 페이지를 방문하십시오',
  "vnpay":'vnpay',
  "waiting admin accept your request":'대기 관리자가 귀하의 요청을 수락하십시오',
  "waiting members list":'대기 회원 목록',
  "wanna more features?":'더 많은 기능을 원하십니까 ?',
  "we will upgrade you immediately, you won\'t have to pay anything until your trial ends":'우리는 즉시 당신을 업그레이드 할 것입니다. 시험이 끝날 때까지 아무것도 지불 할 필요가 없습니다.',
  "we will upgrade you to {subscription} immediately, you won\'t have to pay anything until your trial ends":'우리는 당신을 즉시 {subscription}으로 업그레이드 할 것입니다. 당신은 당신의 시험이 끝날 때까지 아무것도 지불 할 필요가 없습니다.',
  "welcome":'환영',
  "What are you looking for?":'무엇을 찾고 계신가요?',
  "what is your chart name?":'차트 이름은 무엇입니까?',
  "what is your company name?":'회사 이름은 무엇입니까?',
  "what is your name?":'이름이 뭐에요?',
  "what is your question?":'귀하의 질문은 무엇인가?',
  "what your channel name?":'당신의 채널 이름은 무엇입니까?',
  "what your product name?":'제품 이름은 무엇입니까?',
  "when you\'re ready":'준비되면',
  "which categories are your interests?":'당신의 관심사는 어떤 범주입니까 ?',
  "with account":'계정으로',
  "write a response":'응답을 작성하십시오',
  "write article":'기사를 작성하십시오',
  "write description for this page":'이 페이지에 대한 설명을 작성하십시오',
  "write something about this page":'이 페이지에 대해 작성하십시오',
  "Write your sharing":'공유를 작성하십시오',
  "written by":'작성자가 작성했습니다',
  "Year of Establishment":'설립의 해',
  "You are not choosing to follow this chart category. Please choose follow up in advance":'이 차트 범주를 따르기로 선택하지 않습니다. 사전에 후속 조치를 선택하십시오',
  "you can not change your following categories before {time}":'{time} 전에 다음 범주를 변경할 수 없습니다.',
  "you can not follow more {n} categories":'더 많은 {n} 카테고리를 따를 수 없습니다',
  "You can only upload JPG/PNG file":'JPG/PNG 파일 만 업로드 할 수 있습니다',
  "you commented on {owner}\'s post":'당신은 {소유자}의 게시물에 댓글을 달았습니다',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account":'현재 {n} 채널의 최대 한계가 허용됩니다. 계정을 업그레이드하여 변경할 수 있습니다',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account":'현재 {n} 제공 업체의 최대 한도는 소유 할 수 있습니다. 계정을 업그레이드하여 변경할 수 있습니다',
  "you didn\'t enter the channel name correctly":'채널 이름을 올바르게 입력하지 않았습니다',
  "you didn\'t enter the provider name correctly":'제공자 이름을 올바르게 입력하지 않았습니다',
  "You do not have a conversation selected":'대화가 선택되지 않습니다',
  "you don\'t have permission to post":'게시 할 권한이 없습니다',
  "you have not any notification!":'알림이 없습니다!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.":'따라야 할 최대 {n} 항목이 있습니다. 뉴스는 선택한 범주에 따라 필터링됩니다.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.":'따라야 할 최대 {n} 항목이 있습니다. 뉴스는 선택한 언어에 따라 필터링됩니다.',
  "You need {amount} to be able subscribe this channel.":'이 채널을 구독 할 수 있으려면 {and em}이 필요합니다.',
  "you need login first":'먼저 로그인이 필요합니다',
  "Your account has been activated":'귀하의 계정이 활성화되었습니다',
  "your answer":'너의 답',
  "Your business information has been fully updated, thank you":'귀하의 비즈니스 정보가 완전히 업데이트되었습니다. 감사합니다',
  "your chart":'차트',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you":'현재 계정은 한 언어로만 팔로우 할 수 있습니다.이 제한을 제거하려면 더 높은 패키지로 업그레이드하십시오. 감사합니다',
  "Your current active subscription package will continue to hold until it expires":'현재 활성 가입 패키지가 만료 될 때까지 계속 유지됩니다.',
  "your device don\'t support this!":'귀하의 장치는 이것을 지원하지 않습니다!',
  "your invite link expires":'초대 링크가 만료됩니다',
  "your message":'당신의 메시지',
  "Your phone number":'전화 번호',
  "your post":'귀하의 게시물',
  "your request is pending approval":'귀하의 요청은 승인을 받고 있습니다',
  "your subscription will be renew on {date}":'구독은 {date}에 갱신됩니다.',
  "Your message have been sent":'귀하의 메시지가 전송되었습니다',
  "verified":'확인',
  "price history":'가격 역사',
  "product groups":'제품 그룹',
  "representative":'대표',
  "representative chart":'대표 차트',
  "the content will be delivered to you in the language you choose":'콘텐츠는 선택한 언어로 제공됩니다.',
  "favorites":'즐겨 찾기',
  "chart name":'차트 이름',
  "select categories":'카테고리를 선택하십시오',
  "the dataset has been deleted":'데이터 세트가 삭제되었습니다',
  "create article":'기사를 만듭니다',
  "have sent you invitation":'초대장을 보냈습니다',
  "related dataset":'관련 데이터 세트',
  "Send invitation to your friends, by sending a copy of the page link below":'아래 페이지 링크 사본을 보내서 친구에게 초대장을 보내십시오.',
  "Copy and share the page link below to your friends.":'아래 페이지 링크를 친구에게 복사하여 공유하십시오.',
  "Response from provider will be sent via {email}":'조직의 응답은 {email}을 통해 전송됩니다.',
  "action":'행동',
  "timeout":'시간 초과',
  "limit":'한계',
  "5 minutes":'5 분',
  "30 minutes":'30 분',
  "1h":'1h',
  "1day":'1 일',
  "Join As Member":'회원으로 가입하십시오',
  "Join As Admin":'관리자로 가입하십시오',
  "others data":'기타 데이터',
  "channel management":'채널 관리',
  "channel list":'채널 목록',
  "all channels":'모든 채널',
  "technical specs":'기술 사양',
  "functional specs":'기능 사양',
  "other specs":'다른 사양',
  "custom specs":'사용자 정의 사양',
  "shipment":'선적',
  "period":'기간',
  "spot":'점',
  "forward":'앞으로',
  "basis":'기초',
  "select period":'기간을 선택하십시오',
  "from date":'날짜부터',
  "to date":'지금까지',
  "Select the category in which the system will provide you with the most current market data":'시스템이 가장 최신 시장 데이터를 제공 할 카테고리를 선택하십시오.',
  "Thanks sponsors bring freely these charts to you":'감사 스폰서는이 차트를 자유롭게 가져옵니다',
  "Thanks sponsors for updating this news to you":'이 뉴스를 업데이트해 주신 후원자에게 감사드립니다',
  "quotes of provider":'조직의 인용문',
  "announce":'발표하다',
  "product price announcement":'제품 가격 발표',
  "create report":'보고서 작성',
  "the recipient\'s email":'수신자의 이메일',
  "new email":'새 이메일',
  "price_type":'가격 유형',
  "Pricing Reports":'가격 보고서',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce":'가격 알림을 만들어 구매자에게 보내거나 파트너를 구매하거나 제품 가격이 발표됩니다.',
  "products quotation":'제품 견적',
  "effected date":'영향을받는 날짜',
  "price update":'가격 업데이트',
  "no price tag":'가격표 없음',
  "updated at":'업데이트',
  "reference price":'참조 가격',
  "basis price":'기본 가격',
  "forward price":'전진 가격',
  "delivery type":'배송 유형',
  "general basic prices":'일반적인 기본 가격',
  "leave blank to select all":'모두를 선택하려면 공백을 남겨 둡니다',
  "This information is confidential and is only for the member to be submitted":'이 정보는 기밀이며 회원이 제출하기 만하면됩니다.',
  "Sure to remove?":'확실히 제거 하시겠습니까?',
  "Enter a price for the period":'기간의 가격을 입력하십시오',
  "advanced entry":'고급 항목',
  "flat price":'평평한 가격',
  "quoted prices":'인용 가격',
  "start (/end) date in term months":'몇 개월의 시작 (/종료) 날짜',
  "select provinces":'지방을 선택하십시오',
  "spot price":'스팟 가격',
  "confirm":'확인하다',
  "delivery type option (delivery is default): this option affects the product price":'배송 유형 옵션 (배송 기본값) :이 옵션은 제품 가격에 영향을 미칩니다.',
  "delivery price":'배달 가격',
  "shipment price":'배송 가격',
  "add spot price":'스팟 가격을 추가하십시오',
  "add forward price":'전진 가격을 추가하십시오',
  "period range":'기간 범위',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed":'이 정보는 보고서의 범위 내에서 조정되며 원본 정보의 내용이 변경되지 않았습니다.',
  "original price":'원래 가격',
  "feedin users":'피드 사용자',
  "choose user":'사용자를 선택하십시오',
  "Select the term for the price input":'가격 입력 용어를 선택하십시오',
  "price unit":'가격 단위',
  "enter new price":'새로운 가격을 입력하십시오',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)":'이 화면에서 가격 / 업데이트 가격을 입력하면 각 가격 유형의 현재 날짜에 가격이 최신 날짜로 업데이트됩니다 (Spot, Forward).',
  "incoterm":'비위',
  "transport type":'운송 유형',
  "unit of the basis price":'기본 가격의 단위',
  "new price record by location":'위치 별 새로운 가격 기록',
  "This location has been declared, cannot be added":'이 위치는 선언되었으며 추가 할 수 없습니다',
  "choose another location":'다른 위치를 선택하십시오',
  "new price set":'새로운 가격 세트',
  "choose another option for new price set":'새로운 가격 세트에 대한 다른 옵션을 선택하십시오',
  "These options are already declared, cannot be added":'이러한 옵션은 이미 선언되었으며 추가 할 수 없습니다',
  "add new forward price":'새로운 포워드 가격을 추가하십시오',
  "edit quotes":'견적 편집',
  "other unit":'다른 단위',
  "if basis price is required":'기본 가격이 필요한 경우',
  "range":'범위',
  "editor\'s choice":'편집자의 선택',
  "remove the 2nd unit":'두 번째 장치를 제거하십시오',
  "hidden price":'숨겨진 가격',
  "wholesale":'모조리',
  "contact provider for wholesale price":'도매가에 대한 연락처 조직',
  "provided by":'에 의해 제공',
  "about product":'제품 정보',
  "brief description":'간단한 설명',
  "manufactor":'제조업체',
  "add new specification":'새 사양을 추가하십시오',
  "selecting...":'선택 ...',
  "method type":'메소드 유형',
  "operation":'작업',
  "attribute":'기인하다',
  "attribute value":'속성 값',
  "factory":'공장',
  "Specification/Additional Details":'사양/추가 세부 사항',
  "tap on here to start your articles":'기사를 시작하려면 여기를 탭하십시오',
  "attribute group":'속성 그룹',
  "displayed on product information":'제품 정보에 표시됩니다',
  "page not found":'페이지를 찾을 수 없습니다',
  "The page you are opening does not exist, please check the link or return to the homepage":'열려있는 페이지가 존재하지 않습니다. 링크를 확인하거나 홈페이지로 돌아 오십시오.',
  "catalog group":'카탈로그 그룹',
  "manage page":'페이지 관리',
  "main page":'메인 페이지',
  "use for":'사용',
  "set_channel":'게시 채널 업데이트',
  "added value":'부가 가치',
  "product line":'제품 계열',
  "2nd unit":'두 번째 단위',
  "2nd price unit":'두 번째 가격 단위',
  "The effective date of the current price set will be adjusted to the date below":'현재 가격 세트의 발효 일은 아래 날짜로 조정됩니다.',
  "Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.":'아래 날짜에 유효한 새로운 업데이트 가격 데이터를 생성하십시오. 참고 : 데이터는 가장 최근의 가격에 따라 일시적으로 기록됩니다.',
  "Copy the current priceset for another location with the same priceset":'동일한 가격대로 다른 위치에 대한 현재 가격대를 복사하십시오.',
  "Please contact the administrator of FeedIn to put the data on the site":'Feedin 관리자에게 문의하여 사이트에 데이터를 넣으십시오.',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'다른 사이트 (예 : Twitter)에서 컨텐츠를 포함시키는 링크를 붙여 넣고 확인을 클릭하십시오.',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'다른 사이트 (예 : YouTube)에서 콘텐츠를 포함시키기 위해 비디오 링크를 붙여 넣고 확인을 클릭하십시오.',
  "Announcement of information":'정보 발표',
  "Sell products, supply items to the market":'제품, 공급 품목을 시장에 판매하십시오',
  "Provide published information and data for the industry":'업계에 게시 된 정보 및 데이터를 제공합니다',
  "event name":'이벤트 이름',
  "what is your event name?":'이벤트 이름은 무엇입니까?',
  "end date and time":'종료 날짜와 시간',
  "back to home":'홈으로',
  "meeting":'회의',
  "online":'온라인',
  "online event?":'온라인 이벤트?',
  "online event":'온라인 이벤트',
  "created by":'만들어졌습니다',
  "milestone":'중요한 단계',
  "start time":'시작 시간',
  "Select event type (online / meeting)":'이벤트 유형 선택 (온라인 / 회의)',
  "Event, what?, when?":'이벤트, 뭐?, 언제?',
  "Event name, start time":'이벤트 이름, 시작 시간',
  "Add a physical location for people to join your event. Or how to meet together in online event":'사람들이 이벤트에 참여할 수있는 물리적 위치를 추가하십시오. 또는 온라인 이벤트에서 함께 만나는 방법',
  "Event, where?":'이벤트, 어디?',
  "Provide more information about your event so guests know what to expect.":'이벤트에 대한 자세한 정보를 제공하여 고객이 무엇을 기대 해야하는지 알 수 있습니다.',
  "Event, how?":'이벤트, 어떻게?',
  "Everyone meeting on the internet.":'모두 인터넷에서 만나는 것.',
  "Everyone meet together at a specific location.":'모든 사람이 특정 위치에서 함께 만납니다.',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'이전 비밀번호를 완전히 잊어 버린 경우. 로그 아웃하고 "{1}"기능을 사용하여 필요한 경우 비밀번호를 재설정하십시오.',
  "Please select the language you want as the display language on the application":'응용 프로그램에서 원하는 언어로 원하는 언어를 선택하십시오.',
  "farm":'농장',
  "recover login password":'로그인 암호를 복구하십시오',
  "individual":'개인',
  "you are":'당신은',
  "organization name":'조직 이름',
  "attention: please check your activation email in the spam area":'주의 : 스팸 영역에서 활성화 이메일을 확인하십시오.',
  "with your email":'이메일로',
  "no products, please add products to your page":'제품이없고 페이지에 제품을 추가하십시오',
  "priceset history management":'가격대 역사 관리',
  "product configuration":'제품 구성',
  "events in category":'카테고리의 이벤트',
  "no events":'이벤트가 없습니다',
  "select / create":'선택 / 생성',
  "co-admins":'코-아민',
  "weekly":'주간',
  "quarterly":'계간지',
  "yearly":'매년',
  "the event will repeat in":'이벤트는 반복됩니다',
  "finish time":'마무리 시간',
  "updating date":'업데이트 날짜',
  "price is valid from the date":'가격은 날짜부터 유효합니다',
  "contract type":'계약 유형',
  "reminder":'알림',
  "type of event?":'이벤트 유형?',
  "speakers":'스피커',
  "select user":'사용자를 선택하십시오',
  "event_types":'이벤트 유형?',
  "something is wrong, want to delete it?":'뭔가 잘못되었고 삭제하고 싶습니까?',
  "successfully deleted":'성공적으로 삭제되었습니다',
  "are you sure to delete this?":'이것을 삭제 하시겠습니까?',
  "deletion is ignored":'삭제는 무시됩니다',
  "insert here":'여기에 삽입하십시오',
  "not milestone":'이정표가 아닙니다',
  "The next event is scheduled in":'다음 이벤트는 예정되어 있습니다',
  "The next event is scheduled to take place at {1}":'다음 이벤트는 {1}에서 열릴 예정입니다.',
  "products can be searched":'제품을 검색 할 수 있습니다',
  "your product has been declared complete, you can put it on the store":'귀하의 제품이 완성 된 것으로 선언되었습니다. 매장에 넣을 수 있습니다.',
  "Do you want to activate this product?":'이 제품을 활성화 하시겠습니까?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you":'이 제품은 활성화 된 카탈로그에 추가되며 구매자에게 볼 수 있습니다. 또한이 제품에 대한 구매 수신을 시작합니다. 감사합니다',
  "profiles":'프로필',
  "incoming requests management":'들어오는 요청 관리',
  "waiting members":'대기 회원',
  "purchases":'구매',
  "quote prices":'견적 가격',
  "market prices":'시장 가격',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly":'연락처 정보가 선언되지 않았으며 고객이 직접 연락 할 수 있도록 연락처 정보를 선언하는 것이 좋습니다.',
  "members management":'회원 관리',
  "partners":'파트너',
  "vendor":'공급업체',
  "vendors":'공급 업체',
  "customer":'고객',
  "customers":'고객',
  "contact books":'문의 책',
  "add new contact book":'새로운 연락처 책을 추가하십시오',
  "connect to user":'사용자에게 연결하십시오',
  "connect to organization":'조직에 연결하십시오',
  "connect to partner":'파트너에게 연결하십시오',
  "new contact book":'새로운 연락처 책',
  "new partner":'새로운 파트너',
  "partner type":'파트너 유형',
  "partner name":'파트너 이름',
  "edit contact book":'연락처 서적 편집',
  "edit partner":'파트너 편집',
  "all partners":'모든 파트너',
  "add new partner":'새로운 파트너를 추가하십시오',
  "for sales notes":'판매 노트',
  "for purchase notes":'구매 노트',
  "notes":'노트',
  "Note: The effective date of the price being updated is different from the price entry date":'참고 : 업데이트되는 가격의 유효 날짜는 가격 입력 날짜와 다릅니다.',
  "product catalog":'제품 카탈로그',
  "group by":'그룹에 의해',
  "type of product use for sales / purchases":'판매 / 구매에 제품 사용 유형',
  "sales / purchases":'판매 / 구매',
  "added":'추가',
  "feedin organizations":'피드 조직',
  "refresh chart":'차트 차트',
  "incoming quotes":'들어오는 인용문',
  "purchasing planning":'구매 계획',
  "manage outgoing quotes":'나가는 인용문을 관리합니다',
  "product group title":'제품 그룹 제목',
  "Successfully Sign Up":'성공적으로 가입하십시오',
  "redirect in {1} seconds":'{1} 초 안에 리디렉션됩니다',
  "Thank you for registering, Website will redirect to login page after 3 seconds":'등록해 주셔서 감사합니다. 웹 사이트는 3 초 후에 로그인 페이지로 리디렉션됩니다.',
  "add products":'제품 추가',
  "price offer partners":'가격 제공 파트너',
  "Select the products for which you want to send price information to your partner":'파트너에게 가격 정보를 보내려는 제품을 선택하십시오.',
  "products for partner":'파트너를위한 제품',
  "Note: the list below shows only partners linked to the organizations on FeedIn":'이 목록의 파트너는 피드 시스템에서 사용할 수 있습니다.',
  "bookmarks":'북마크',
  "my space":'내 공간',
  "all bookmarks":'모든 북마크',
  "quotations for partners":'파트너를위한 인용',
  "favourites":'즐겨 찾기',
  "vietnam market":'베트남 시장',
  "workspace":'작업 공간',
  "updates":'업데이트',
  "for search optimization":'검색 최적화를 위해',
  "to optimize product display":'제품 디스플레이를 최적화합니다',
  "to group products with the same properties, compare prices":'동일한 특성을 가진 제품을 그룹화하려면 가격을 비교하십시오.',
  "be sent from partners":'파트너로부터 보내집니다',
  "owned":'소유',
  "Prices below are average prices with selected location or selected period is All":'아래 가격은 선택된 위치 또는 선택된 기간을 가진 평균 가격입니다.',
  "Can follow the latest news and events in the industry":'업계의 최신 뉴스 및 이벤트를 따를 수 있습니다.',
  "Explore dataset and charts":'데이터 세트 및 차트를 탐색하십시오',
  "to help you manage":'관리를 돕기 위해',
  "or create an organization as":'또는 조직을 만듭니다',
  "farmer":'농장주',
  "buyer":'사는 사람',
  "Create events to reach the commodity market":'상품 시장에 도달하기위한 이벤트를 만듭니다',
  "Create news channels to announce and share information to the commodity market":'상품 시장에 정보를 발표하고 공유 할 뉴스 채널 작성',
  "Cooperate with FeedIn to share and provide commodity data to customers":'Feedin과 협력하여 고객에게 상품 데이터를 공유하고 제공합니다.',
  "Please contact the administrator of FeedIn":'Feedin 관리자에게 문의하십시오',
  "name required more than {1} characters":'이름은 {1} 문자 이상이 필요합니다',
  "set subscription":'구독을 설정하십시오',
  "subscribed":'구독',
  "edit contact":'연락처 편집',
  "Information gathered from the declaration in the contact information":'연락처 정보의 선언에서 수집 된 정보',
  "The information will be linked to the information of partner":'정보는 파트너의 정보와 연결됩니다.',
  "product had basic information and ready to launch":'제품에는 기본 정보가 있으며 출시 준비가되었습니다',
  "internal use":'내부 사용',
  "{launch} the product to display on the store":'{런치} 상점에 표시 할 제품',
  "OR {activate} to input the price and manage it internally":'또는 가격을 입력하고 내부적으로 관리하려면 {activate}',
  "launch":'시작하다',
  "activate only":'만 활성화하십시오',
  "{deactivate} product":'{deactivate} 제품',
  "deactivate":'비활성화',
  "set effect date":'효과 날짜를 설정하십시오',
  "insert at":'삽입',
  "expire at":'만료',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.":'페이지가 존재하지 않거나 페이지는 내부 사용을위한 것이므로이 페이지의 내용을 볼 수있는 멤버 여야합니다.',
  "hybrid event":'하이브리드 이벤트',
  "no data yet":'아직 데이터가 없습니다',
  "Please login to view this content":'이 콘텐츠를 보려면 로그인하십시오',
  "no data is available now":'현재 사용 가능한 데이터가 없습니다',
  "latest tracking news":'최신 추적 뉴스',
  "activate":'활성화',
  "Buy goods, collect quotes from sellers":'상품을 사고, 판매자로부터 인용문을 수집하십시오',
  "following charts":'다음 차트',
  "Leave your information so we can contact you again":'다시 연락 할 수 있도록 정보를 남겨주세요',
  "purchase":'구입',
  "enter spot price":'스팟 가격을 입력하십시오',
  "present":'현재의',
  "weather":'날씨',
  "Temperature - weather, tap to see more":'온도 - 날씨, 탭하여 더 많이보십시오',
  "Rain (thunder) - weather, tap to see more":'비 (Thunder) - 날씨, 더 많은 것을 보려면 탭합니다.',
  "Wind - weather, tap to see more":'바람 - 날씨, 더 많은 것을보기 위해 탭합니다',
  "temperature":'온도',
  "rain":'비',
  "wind":'바람',
  "edit & save":'편집 및 저장',
  "create from":'생성',
  "remove expired":'만료 된 것을 제거하십시오',
  "delete prices at":'가격을 삭제하십시오',
  "copy prices at":'가격을 복사하십시오',
  "enter forward price":'전진 가격을 입력하십시오',
  "affiliate products":'제휴 제품',
  "at date":'날짜에',
  "This pricing information will expire in":'이 가격 정보는 만료됩니다',
  "This pricing information will expire at":'이 가격 정보는 만료됩니다',
  "no expired date":'만료 된 날짜가 없습니다',
  "quarters":'병사',
  "you have an article in progress":'당신은 진행중인 기사가 있습니다',
  "Create an information channel to promote your information and products to everyone":'정보 및 제품을 모든 사람에게 홍보하기위한 정보 채널을 만듭니다.',
  "get {free} quotes from multiple sellers":'여러 판매자로부터 {free} 인용문을 얻습니다',
  "Tell us your Requirement":'귀하의 요구 사항을 알려주십시오',
  "Receive free quotes from sellers":'판매자로부터 무료 견적을받습니다',
  "Seal the Deal":'계약을 성사하다',
  "Enter product/ Service name":'제품/ 서비스 이름을 입력하십시오',
  "Your email address":'귀하의 이메일 주소',
  "Enter your name":'당신의 이름을 입력',
  "Your live in":'당신의 라이브',
  "We connect":'우리는 연결합니다',
  "Buyers Sellers":'구매자 및 판매자',
  "Trusted Platform":'신뢰할 수있는 플랫폼',
  "Safe Secure":'안전한 보안',
  "Quick Assistance":'빠른 지원',
  "Create events so people can participate and learn more about your organization":'사람들이 참여하고 조직에 대해 더 많이 배울 수 있도록 이벤트를 만듭니다.',
  "my articles":'내 기사',
  "Use keyboard shortcuts":'키보드 바로 가기를 사용하십시오',
  "edited by":'편집',
  "Publishing to:":'게시 :',
  "write caption for image":'이미지에 대한 캡션을 작성하십시오',
  "caption of image":'이미지의 캡션',
  "write caption for video":'비디오에 대한 캡션을 작성하십시오',
  "caption of video":'비디오 캡션',
  "video url is invalid!":'비디오 URL은 유효하지 않습니다!',
  "enter video url":'비디오 URL을 입력하십시오',
  "add caption":'캡션을 다세요',
  "related products":'관련 상품',
  "products from the same organization":'같은 조직의 제품',
  "get direction":'방향을 얻으십시오',
  "export purchasing data":'구매 데이터 수출',
  "export sales data":'수출 판매 데이터',
  "select page":'페이지를 선택하십시오',
  "send requirement":'요구 사항을 보냅니다',
  "detail requirement":'세부 요구 사항',
  "attach file":'파일을 첨부',
  "how it work":'그것이 어떻게 작동하는지',
  "tell us what you need by filling the form":'양식을 작성하여 필요한 것을 알려주십시오',
  "receive verified supplier details":'확인 된 공급 업체 세부 정보를받습니다',
  "compare quotations and seal the deal":'인용문을 비교하고 거래를 봉인하십시오',
  "your information":'당신의 정보',
  "choosing a category will help the article stay on topic and more accessible to users":'카테고리를 선택하면 기사가 주제를 유지하고 사용자가 더 액세스 할 수 있습니다.',
  "please add images to make the article more vivid and realistic to attract more viewers":'더 많은 시청자를 유치하기 위해 기사를보다 생생하고 현실적으로 만들기 위해 이미지를 추가하십시오.',
  "data has been generate":'데이터가 생성되었습니다',
  "please click the {download} button below to download to your device":'아래의 {download} 버튼을 클릭하여 장치로 다운로드하십시오.',
  "article preview":'기사 미리보기',
  "back to edit":'편집으로 돌아갑니다',
  "please login so the supplier can contact you":'공급 업체가 연락 할 수 있도록 로그인하십시오',
  "product information":'제품 정보',
  "please provide a few details to get the most accurate response from the supplier":'공급 업체로부터 가장 정확한 응답을 얻으려면 몇 가지 세부 정보를 제공하십시오.',
  "article has no images":'기사에는 이미지가 없습니다',
  "this channel belongs to":'이 채널은 다음과 같습니다',
  "unit price":'단가',
  "posted at":'게시',
  "respond at":'응답',
  "view website":'웹 사이트보기',
  "send message":'문자 보내',
  "resend request":'요청을 재개합니다',
  "close request":'닫기 요청',
  "view detail":'자세히보기',
  "hide detail":'세부 사항을 숨 깁니다',
  "not sure":'확실하지 않다',
  "sure":'확신하는',
  "you have found a supplier for this product and you want to close this request":'이 제품의 공급 업체를 찾았 으며이 요청을 닫으려고합니다.',
  "are you sure":'확실합니까',
  "company name (if you buy for the company)":'회사 이름 : (회사를 위해 구매하는 경우)',
  "Request a quote / Order":'견적 / 주문을 요청하십시오',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'아바타는 게시물에서 선택되어 게시물을 더 활기차고 매력적으로 만들기 위해 이미지를 추가하십시오.',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'관심있는 제품에 대한 공급 업체로부터 최신의 가장 정확한 견적을 받으십시오.',
  "filtered by":'필터링',
  "my order request":'내 주문 요청',
  "keyword: request id, organization id (name)":'키워드 : 요청 ID, 조직 ID (이름)',
  "order requests management":'주문 관리 주문',
  "press enter to create new page":'새 페이지를 만들려면 Enter를 누릅니다',
  "add new page":'새 페이지를 추가하십시오',
  "product info":'제품 정보',
  "order request type":'주문 요청 유형',
  "request type":'요청 유형',
  "received at":'수령',
  "min quantity":'최소 수량',
  "productinfo":'제품 정보',
  "deleted":'삭제',
  "alert drafting article description":'이 기사는 변경 될 수 있으며 아직 출판되지 않았습니다. 컨텐츠를 작성하고 게시하려면 여기를 클릭하십시오.',
  "delete article":'기사 삭제',
  "language linking":'언어 연결',
  "articles in other languages":'다른 언어로 된 기사',
  "Are you sure remove this related article?":'이 관련 기사를 제거합니까?',
  "Type the title of the article in other languages to search and add the article you want to link to.":'다른 언어로 기사 제목을 입력하여 링크 할 기사를 검색하고 추가하십시오.',
  "linked articles":'연결된 기사',
  "edit article setting":'기사 설정 편집',
  "owner by":'소유자',
  "grateful description":'스폰서에게 진심으로 감사드립니다',
  "You can click \"See more\" to see this full information":'"더보기"를 클릭 하여이 전체 정보를 볼 수 있습니다.',
  "your donation description":'여기에 기부금이 있습니다. 문제가 발생하면 캠페인 관리자에게 문의하십시오. 귀하의 기여에 감사드립니다.',
  "contribution chart":'기여도',
  "list of contributors":'기고자 목록',
  "donated items":'기증 된 품목',
  "pending items":'보류중인 항목',
  "search with donation code":'기부 코드로 검색하십시오',
  "equivalent to":'동등합니다',
  "has a capacity of":'용량이 있습니다',
  "my donations":'내 기부',
  "thank you card":'감사합니다',
  "sincerely thank you":'진심으로 감사합니다',
  "supported the donation campaign":'기부 캠페인을 지원했습니다',
  "money / goods":'돈 / 상품',
  "Sincere thanks to the benefactors for accompanying us to create a picture of kindness.":'친절의 그림을 만들기 위해 우리와 동행 한 후원자들에게 진심으로 감사드립니다.',
  "Wishing you good health, peace and happiness.":'건강, 평화 및 행복을 기원합니다.',
  "Kind regards,":'친절한 안부,',
  "poll question":'설문 조사 질문',
  "latest contributions":'최신 기여',
  "news update":'뉴스 업데이트',
  "export as PNG":'PNG로 수출',
  "register now":'지금 등록하세요',
  "would you like to donate another value":'다른 가치를 기부 하시겠습니까?',
  "choose the package that you can donate":'기부 할 수있는 패키지를 선택하십시오',
  "register to contribute":'기여하려면 등록하십시오',
  "copy path":'복사 경로',
  "click here to start writing":'글쓰기를 시작하려면 여기를 클릭하십시오',
  "impact-categories":'영향 범주',
  "host request":'주최자가되기를 요청합니다',
  "host-request":'주최자가되기를 요청합니다',
  "adding an address will help people find you":'주소를 추가하면 사람들이 당신을 찾는 데 도움이됩니다',
  "load more":'더로드하십시오',
  "waiting-members":'대기 회원',
  "add host":'호스트 추가',
  "Are you sure delete this host?":'이 호스트를 삭제합니까?',
  "confirmation":'확인',
  "Deleted {type} are gone forever. Are you sure?":'삭제 된 {type}은 영원히 사라졌습니다. 확실합니까?',
  "{type} name":'{type} 이름',
  "you didn\'t enter the {type} name correctly":'{type} 이름을 올바르게 입력하지 않았습니다',
  "select tags":'태그를 선택하십시오',
  "finished":'완성된',
  "Allow contribution information to be visible to the public":'기여 정보가 대중에게 보이도록 허용하십시오',
  "Allow charity work information to be visible to the public":'자선 사업 정보가 대중에게 보이도록 허용',
  "Allows to be searched by recruitment agencies":'채용 기관에서 검색 할 수 있습니다',
  "clip_url":'클립 링크',
  "edit owner":'소유자 편집',
  "ref_ids":'캠페인 링크',
  "edit campaign link":'캠페인 링크 편집',
  "add target details":'대상 세부 사항을 추가하십시오',
  "select unit":'단위를 선택하십시오',
  "select item":'물품을 고르시 오',
  "votes":'투표',
  "shares":'주식',
  "giving items":'품목 제공',
  "search with giving code":'코드 제공로 검색하십시오',
  "latest giving":'최신 기부',
  "---Choose One---":'하나를 선택하십시오',
  "Linked Pages":'링크 된 페이지',
  "contact information so people can reach out when they need help":'사람들이 도움이 필요할 때 연락 할 수 있도록 연락처 정보',
  "detail target":'세부 대상',
  "click to select":'선택하려면 클릭하십시오',
  "not available":'사용 불가',
  "summary report":'요약 보고서',
  "click here for the link":'링크를 보려면 여기를 클릭하십시오',
  "your campaign is currently not associated with any giving campaign, click here to connect":'캠페인은 현재 기부 캠페인과 관련이 없으며 여기를 클릭하여 연결하십시오.',
  "link to giving":'주는 것에 대한 링크',
  "search with registration code":'등록 코드로 검색하십시오',
  "giving chart":'차트 제공',
  "list of giving":'기부 목록',
  "your registered list":'등록 된 목록',
  "indexed by wishare":'Wishare가 색인화했습니다',
  "recruiting volunteers":'자원 봉사자 모집',
  "type of recruitment":'채용 유형',
  "job requirements":'작업 요구 사항',
  "time to start work":'일을 시작할 시간',
  "application deadline":'신청 마감일',
  "Create an organization to share and raise money for your charity":'자선 단체를 위해 공유하고 돈을 모으기위한 조직을 만드십시오.',
  "Create activity to share your campaigns":'캠페인을 공유하기 위해 활동을 만듭니다',
  "create donation campaign":'기부 캠페인을 만듭니다',
  "Create a donation campaign to call for support from donors":'기부자들의 지원을 요청하는 기부 캠페인 만들기',
  "create giving campaign":'주는 캠페인을 만듭니다',
  "Create a giving campaign to help those in need":'도움이 필요한 사람들을 돕기 위해 기부 캠페인을 만듭니다',
  "create recruitment campaign":'채용 캠페인을 만듭니다',
  "Create a recruitment campaign to find volunteers for the organization":'조직을위한 자원 봉사자를 찾기위한 채용 캠페인 만들기',
  "Create an event to connect with other benefactors or volunteer organizations":'다른 후원자 또는 자원 봉사 단체와 연결하는 이벤트 만들기',
  "Create a channel to share information about your charity program":'자선 프로그램에 대한 정보를 공유 할 수있는 채널 작성',
  "who can post":'누가 게시 할 수 있습니다',
  "everyone":'모든 사람',
  "administrators":'관리자',
  "event type":'이벤트 유형',
  "npo":'비영리 단체',
  "ngo":'비정부 단체',
  "association":'협회',
  "edit cd type":'CD 유형 편집',
  "Channel members only":'채널 멤버 만',
  "Channel admins only":'채널 관리자 만',
  "Everyone who has an account at wishare":'Wishare에 계정이있는 모든 사람',
  "most view":'대부분의 견해',
  "donation types":'기부 유형',
  "giving types":'유형 제공',
  "occupation types":'직업 유형',
  "reserved job types":'예약 된 작업 유형',
  "recruitment types":'채용 유형',
  "what":'무엇 ?',
  "when":'언제 ?',
  "whose":'누구?',
  "how":'어떻게 ?',
  "An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.":'조직은 그룹, 협회, 커뮤니티 ...로 지역 사회와 사회 자원을 모으고 사회 프로젝트를 구현하기 위해 설립되었습니다.',
  "Organize activities throughout, be recognized for the group\'s activities.":'그룹 전체의 활동을 조직하고 그룹의 활동으로 인정받습니다.',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.":'조직은 개인에 의해 만들어지며 다른 조직의 지점으로 사용될 수있는 많은 개인 또는 조직으로 구성 될 수 있습니다.',
  "Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.":'조직은 도구를 사용합니다 : 채용, 이벤트, 채널, 운영 할 기사, 조직에는 자체 목적에 따라 프로젝트를 구성하는 1 개 이상의 활동이 있습니다.',
  "Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.":'활동은 사회에 영향을 미치기 위해 특정 사명을 제공하기 위해 만들어진 사회적 영향 프로젝트입니다.',
  "Activities are created and maintained throughout, recorded during the operation of the project.":'프로젝트 운영 중에 기록 된 활동이 만들어지고 유지됩니다.',
  "It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...":'그것은 독립적 인 개인의 프로젝트로 시작될 수 있지만, 사회를 위해서는 지역 사회 자원과 지속 가능성의 사용을 장기적으로 확장 할 수 있습니다. 활동은 그룹, 협회, 지역 사회에 의해 만들어야합니다 ...',
  "Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.":'도구를 사용하는 활동 : 기부하고, 운영 프로세스를 투명하게 만들기 위해 기부하고, 기여 정보를 기록하여 변화 이론에 따라 영향을 미칩니다.',
  "Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.":'기부금은 활동에 대한 자원을 동원하기 위해 제한된 시간 동안 진행되는 캠페인 인 행사입니다.',
  "Donations are made over a limited period of time according to the scope of each phase of the project.":'기부금은 프로젝트의 각 단계의 범위에 따라 제한된 기간 동안 이루어집니다.',
  "Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.":'기부금은 활동에서 생성되고 동원 자원의 투명성을 위해 경품 캠페인과 관련이 있습니다.',
  "Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.":'기부는 동원 된 자원을 찾고 제공하여 활동에 대한 투명성을 만듭니다.',
  "Giving takes place over a finite period of time according to the scope of each phase of the project.":'프로젝트의 각 단계의 범위에 따라 유한 한 기간 동안 기부가 이루어집니다.',
  "Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.":'기부금은 활동에서 생성되며 기부 캠페인과 관련하여 자금 조달 자원을 투명하게 만듭니다.',
  "Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.":'채용은 조직 및 활동을위한 인적 자원을 동원하기 위해 채용 요청 정보를 작성하는 기능입니다.',
  "Recruitment activities have a timeline according to the development stage of the organization.":'채용 활동에는 조직의 개발 단계에 따라 타임 라인이 있습니다.',
  "Recruitment is made from Groups, Associations, Community...":'채용은 그룹, 협회, 커뮤니티에서 이루어집니다 ...',
  "Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.":'이벤트는 이벤트 정보를 공유하고 참가자 및 관심있는 커뮤니티의 진행 상황을 기록하기위한 활동입니다.',
  "The event takes place over a finite period of time.":'이 행사는 유한 한 시간에 걸쳐 진행됩니다.',
  "Events are created from Groups, Guilds, Communities...":'행사는 그룹, 길드, 커뮤니티에서 만들어집니다 ...',
  "Information channel is a function to support sharing and publishing information of social activities.":'정보 채널은 사회 활동의 정보 공유 및 게시를 지원하는 기능입니다.',
  "Information channels are maintained throughout.":'정보 채널은 전체적으로 유지됩니다.',
  "Groups, Associations, Community... can set up 1 or more channels.":'그룹, 협회, 커뮤니티 ... 1 개 이상의 채널을 설정할 수 있습니다.',
  "denied":'거부됨',
  "applied for the job":'작업에 신청했습니다',
  "your donation will be sent to":'기부금이 발송됩니다',
  "register donation":'등록 기부',
  "alias":'별명',
  "required information":'필수 정보',
  "date of birth":'생일',
  "required fields":'필수 입력 사항',
  "Please follow these instructions to complete the donation process":'기부 과정을 완료하려면 다음 지침을 따르십시오.',
  "Thank you benefactors, when transferring, remember to specify the structure of the transfer as":'양도 할 때 후원자에게 감사의 말씀을 전합니다.',
  "your name - donation name":'귀하의 이름 - 기부 이름',
  "Click the Register donate button to complete and receive the donation code":'"기부 등록"버튼을 클릭하여 기부 코드를 완료하고받습니다.',
  "text":'텍스트',
  "radio":'하나를 선택하십시오',
  "checkbox":'많은 것을 선택하십시오',
  "textarea":'텍스트 영역',
  "boolean":'허위 사실',
  "conversion required":'전환이 필요합니다',
  "limited":'제한된',
  "complete registration":'완전한 등록',
  "You have just completed the registration, please follow the donation steps to complete the donation process for campaign":'방금 등록을 완료했습니다. 기부 단계를 따라 캠페인 기부 절차를 완료하십시오.',
  "description donation code":'Wishare에 대한 계정이있는 경우 캠페인 기부 코드입니다.이 코드로 기부금을 완료 한 경우 정보 섹션 또는 "감사"에서 확인할 수 있습니다.',
  "Thank you for your contributions to make this life better":'이 삶을 더 좋게 만들기 위해 기여해 주셔서 감사합니다.',
  "You can click the \"Back campaign\" button to return to the campaign":'"백 캠페인"버튼을 클릭하여 캠페인으로 돌아갈 수 있습니다.',
  "back campaign":'백 캠페인',
  "donation input":'기부 입력',
  "create new post":'새 게시물을 만듭니다',
  "post an article":'기사 게시',
  "you want more":'당신은 더 많은 것을 원합니다',
  "min length 2 characters":'최소 길이 2 자',
  "container":'채널에 게시되었습니다',
  "anyone can search for and view":'누구나 검색하고 볼 수 있습니다',
  "only you can view":'당신만이 볼 수 있습니다',
  "add tags":'태그 추가',
  "hashtags":'해시 태그',
  "job position":'작업 위치',
  "work time type":'작업 시간 유형',
  "is remote work":'원격 작업입니다',
  "experience requirements":'경험 요구 사항',
  "min age":'최소 시대',
  "max age":'최대 시대',
  "min salary":'최소 급여',
  "max salary":'최대 급여',
  "per quater":'쿼터 당',
  "latest list of candidates":'후보자의 최신 목록',
  "is yes/no question":'예/아니오 질문입니까?',
  "choose number of answers":'답변 수를 선택하십시오',
  "one answer":'하나의 대답',
  "multiple answer":'다중 답변',
  "number of choices":'선택의 수',
  "goto":'이동',
  "add donation option":'기부 옵션 추가',
  "are you sure delete this":'이걸 삭제합니까?',
  "would you like to go to the detailed article page":'자세한 기사 페이지로 이동 하시겠습니까?',
  "the article was posted":'기사가 게시되었습니다',
  "are you sure you want to delete this donation package":'이 기부 패키지를 삭제 하시겠습니까?',
  "delete donation package":'기부 패키지 삭제',
  "General Categories":'일반 카테고리',
  "private event":'개인 이벤트',
  "private event description":'귀하와 초대 된 회원만이 이벤트에 참여할 수 있습니다.',
  "private organization":'개인 조직',
  "private organization description":'귀하와 귀하의 조직의 구성원만이 귀하의 조직으로부터 정보를보고받을 수 있습니다.',
  "You have completed the steps of creating organization":'조직을 만드는 단계를 완료했습니다',
  "Hope your organization will be successful and help many people":'조직이 성공하고 많은 사람들을 돕기를 바랍니다.',
  "Short description about your organization":'조직에 대한 간단한 설명',
  "display name":'이름 표시하기',
  "you have chosen":'당신은 선택했습니다',
  "transfer date":'전송 날짜',
  "send a thank you email":'감사 이메일을 보내십시오',
  "you donated for campaign":'당신은 캠페인을 기증했습니다',
  "volunteer available":'자원 봉사 가능',
  "action completed":'작업이 완료되었습니다',
  "successfully created!":'성공적으로 만들어졌습니다!',
  "successfully updated!":'성공적으로 업데이트되었습니다!',
  "successfully deleted!":'성공적으로 삭제되었습니다!',
  "search news channel":'검색 뉴스 채널',
  "input data":'입력 데이터',
  "add receiving option":'수신 옵션을 추가하십시오',
  "giving input":'입력 제공',
  "your giving will be sent to":'당신의 기부금은 보내질 것입니다',
  "register giving":'등록 기부',
  "your registration will be sent to":'등록이 발송됩니다',
  "Please follow these instructions to complete the giving process":'이 지침을 따르면 기부 과정을 완료하십시오',
  "Your application will be sent to the program and it will take a few days to process, so please wait.":'귀하의 신청서는 프로그램으로 전송되며 처리하는 데 며칠이 걸리므로 기다리십시오.',
  "Click the Register giving button to complete and receive the giving code":'"등록 기부"버튼을 클릭하여 제공 코드를 완료하고받습니다.',
  "You have just completed register receiving gifts from the program":'프로그램에서 선물을받는 레지스터를 방금 완료했습니다.',
  "description giving code":'ShareShare에 계정이있는 경우 캠페인의 선물을 받기위한 등록 코드입니다. 정보 섹션 또는이 코드의 "내 등록"에서 확인할 수 있습니다.',
  "successfully subscribed":'성공적으로 구독!',
  "register to receive":'받으려면 등록하십시오',
  "you have registered to receive from":'귀하는 수신하도록 등록했습니다',
  "you received from":'당신은로부터 받았습니다',
  "register to received":'수신에 등록하십시오',
  "receive date":'날짜를받습니다',
  "register received":'받은 등록',
  "donation package":'기부 패키지',
  "name package":'이름 패키지',
  "giving package":'패키지 제공',
  "add giving option":'기부 옵션을 추가하십시오',
  "{type} will delete will be gone forever. Are you sure?":'{type}은 삭제됩니다. 확실합니까?',
  "successfully registered":'성공적으로 등록되었습니다!',
  "you register to receive":'당신은 받기 위해 등록합니다',
  "km":'កម្ពុជា កម្ពុជា កម្ពុជា',
  "fr":'Français',
  "ko":'한국인',
  "delete receiving package":'수신 패키지 삭제?',
  "are you sure you want to delete this receiving package":'이 수신 패키지를 삭제 하시겠습니까?',
  "select categories description":'Wishare는 정보를 카테고리별로 그룹화하여 정보에 더 잘 액세스하고 누락 된 정보를 피할 수 있도록 도와줍니다. 아래에서 좋아하는 범주를 선택하십시오',
  "by using Wishare, you agree to our":'Wishare를 사용함으로써 귀하는 우리에게 동의합니다',
  "donation event has the unfinished items":'기부 행사에는 미완성 품목이 있습니다',
  "search anythings in Wishare":'Wishare에서 모든 것을 검색하십시오',
  "registered list":'등록 된 목록',
  "giving event has the unfinished items":'주는 이벤트에는 미완성 아이템이 있습니다',
  "recruitment event has the unfinished items":'채용 이벤트에는 미완성 품목이 있습니다',
  "responsibilities":'책임',
  "no name entered":'이름이 입력되지 않았습니다',
  "search with recruitment code":'채용 코드로 검색하십시오',
  "for admin":'관리자',
  "your application will be sent to":'귀하의 신청서가 전송됩니다',
  "click the Apply now button to complete and receive the apply code":'"지금 적용"버튼을 클릭하여 코드 적용을 완료하고받습니다.',
  "job-description":'업무 설명서',
  "job-requirements":'작업 요구 사항',
  "recruited":'모집',
  "thank you for applying for this job":'이 직업을 신청해 주셔서 감사합니다.',
  "your application has been sent to the organization, please wait for a response from the organization":'귀하의 신청서가 조직으로 전송되었습니다. 조직의 응답을 기다리십시오.',
  "that may take a few days, please wait":'며칠이 걸릴 수 있습니다. 기다려주세요.',
  "find account":'계정 찾기',
  "choose account":'Wishare에서 이용할 수있는 계정을 선택하십시오',
  "select receiver":'수신기를 선택하십시오',
  "description recruitment code":'이것은 자원 봉사 등록 코드이며 자원 봉사 섹션에서 신청서를 추적 할 수 있습니다.',
  "you have just completed your registration as a volunteer for the program":'방금 프로그램 자원 봉사자로 등록을 완료했습니다.',
  "applied":'적용된',
  "Thank you for signing up to be a volunteer of the program":'프로그램의 자원 봉사자가되기 위해 가입해 주셔서 감사합니다.',
  "candidate list":'후보자 목록',
  "are you sure you want to reject this candidate":'이 후보자를 거부 하시겠습니까?',
  "candidate":'후보자',
  "currently an employee of the organization":'현재 조직/그룹의 구성원입니다',
  "enter candidate information":'후보 정보를 입력하십시오',
  "enter information to give":'제공 할 정보를 입력하십시오',
  "given by the organization":'조직이 제공합니다',
  "enter donation information":'기부 정보를 입력하십시오',
  "edit candidate information":'후보 정보 편집',
  "administration panel":'관리 패널',
  "open/close recruitment":'채용 시간',
  "work type":'작업 유형',
  "create donation event":'크라우드 펀딩 캠페인을 만듭니다',
  "create giving event":'주는 캠페인을 만듭니다',
  "organization type":'조직 유형',
  "mobilization of manpower":'인력의 동원',
  "communication":'의사소통',
  "are you ready for volunteer work":'자원 봉사 활동 준비가 되셨습니까?',
  "change-password":'비밀번호를 변경하십시오',
  "configure-content-by-language":'언어별로 컨텐츠를 구성합니다',
  "diamond":'다이아몬드',
  "platinum":'백금',
  "gold":'금',
  "silver":'은',
  "bronze":'청동',
  "Display name can not be empty":'표시 이름은 비어있을 수 없습니다',
  "Number values can not be zero":'숫자 값은 0이 될 수 없습니다',
  "basic":'기초적인',
  "premium":'프리미엄',
  "general operating model":'일반 운영 모델',
  "we make common rules so that everyone can understand and understand how we work":'우리는 모든 사람이 우리가 일하는 방식을 이해하고 이해할 수 있도록 일반적인 규칙을 만듭니다.',
  "project":'프로젝트',
  "serving the mission aiming for social impact":'사회적 영향을 목표로 선교 사업 봉사',
  "giving gifts to difficult circumstances":'어려운 상황에 선물을 제공합니다',
  "motto and how it works":'모토와 작동 방식',
  "we aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission":'우리는 의미있는 사명을 수행하는 후원자, 불우한 사람들, 자선 단체를 연결하는 것을 목표로합니다.',
  "connect social activities":'사회 활동을 연결하십시오',
  "we create links between charities and those in need":'우리는 자선 단체와 도움이 필요한 사람들 사이의 링크를 만듭니다.',
  "support groups, organizations":'지원 협회, 그룹, 조직',
  "we provide support tools for associations, groups, organizations to be able to carry out their volunteering missions":'우리는 자원 봉사 임무를 수행 할 수 있도록 협회, 그룹, 조직을위한 지원 도구를 제공합니다.',
  "Transparency - Sustainability - Impact":'투명성 - 지속 가능성 - 영향',
  "this is our operating principle, every donation or donation is clear and transparent":'이것이 우리의 운영 원칙이며, 모든 기부 또는 기부금은 명확하고 투명합니다.',
  "beginer":'초보자',
  "intermediate":'중간',
  "proficient":'능숙합니다',
  "on working":'일에',
  "not graduated":'졸업하지 않았습니다',
  "not anymore":'더 이상',
  "review the application form":'신청서를 검토하십시오',
  "application form":'신청서',
  "recruitment agency":'모집 기관',
  "cancel application":'신청을 취소합니다',
  "do you want to change your personal information":'개인 정보를 변경 하시겠습니까?',
  "candidate profile":'후보자의 프로필',
  "you want to see candidate information":'후보자 정보를보고 싶습니다',
  "human resources":'인적 자원',
  "Transparency & Sustainability":'투명성 및 지속 가능성',
  "record project activities with the data of fundraising campaigns, giving":'기금 모금 캠페인 데이터로 프로젝트 활동 기록',
  "projects":'프로젝트',
  "how it works":'작동 방식',
  "information collecting":'정보 수집',
  "candidates":'후보자',
  "you can not select than 2 languages":'2 언어를 선택할 수 없습니다',
  "Sharing and connecting between members in the community of social activities.":'사회 활동 커뮤니티의 구성원 간의 공유 및 연결.',
  "Support social units in the work of carrying out their mission":'그들의 사명을 수행하는 일에서 사회 단위를 지원합니다.',
  "Creating transparency and sustainability in social activities":'사회 활동에서 투명성과 지속 가능성 창출',
  "are you sure that you want to cancel this":'당신은 이것을 취소하고 싶다고 확신합니까?',
  "edit a poll":'설문 조사를 편집하십시오',
  "Change folder name":'폴더 이름을 변경하십시오',
  "Do you want delete this folder":'이 폴더를 삭제 하시겠습니까?',
  "This will permanently delete it, but its items will still be in your saved list.":'이것은 영구적으로 삭제되지만 해당 항목은 여전히 ​​저장된 목록에 있습니다.',
  "export donation data":'수출 기부 데이터',
  "export as PDF":'PDF로 수출',
  "export giving data":'데이터 수출 데이터',
  "record and display the converted value":'변환 된 값을 기록하고 표시하십시오',
  "with target":'대상으로',
  "approved list":'승인 된 목록',
  "rejected list":'거부 된 목록',
  "export":'내보내다',
  "time sheets":'시간 시트',
  "view timesheet":'시간 시트보기',
  "other project":'다른 프로젝트',
  "select project":'프로젝트를 선택하십시오',
  "For example: ABC Campaign, ABC Organization,...":'예 : ABC 캠페인, ABC 조직, ...',
  "edit timesheet":'시간 시트 편집',
  "member information":'회원 정보',
  "delete this information":'이 정보를 삭제 하시겠습니까?',
  "are you sure you want to delete this working information":'이 작업 정보를 삭제 하시겠습니까?',
  "my profile":'내 프로필',
  "you are exporting campaign data":'캠페인 데이터를 내보내고 있습니다',
  "package donation":'패키지 기부',
  "package giving":'패키지 제공',
  "do you want to leave":'떠나고 싶니 ?',
  "you will no longer be a member of this organization or activity":'더 이상이 조직이나 활동의 회원이되지 않습니다. 확실합니까 ?',
  "the {type} has been deleted":'{type}이 삭제되었습니다',
  "features group":'특징',
  "beneficiary management":'수혜자 관리',
  "beneficiary-management":'수혜자 관리',
  "on moving":'움직일 때',
  "go to activity":'활동으로 이동하십시오',
  "transfer beneficiary":'양도 수혜자',
  "note approve beneficiary":'수혜자를 양도 할 때 더 이상이 소원을 관리 할 수 ​​없습니다. 그리고이 행동은 취소 할 수 없습니다.',
  "cancel transfer":'전송 취소',
  "insights":'통찰력',
  "recent campaigns":'최근 캠페인',
  "recent activity":'최근 활동',
  "latest apply":'최신 적용',
  "latest register giving":'최신 레지스터 제공',
  "latest register donation":'최신 등록 기부',
  "latest followed user":'최신 사용자는 사용자가 이어졌습니다',
  "latest post":'최신 우편물',
  "campaign":'운동',
  "top donation campaign":'최고의 기부 캠페인',
  "top giving campaign":'최고의주는 캠페인',
  "total giving":'총 기부',
  "top article":'최고 기사',
  "top posts":'상단 게시물',
  "total votes":'총 투표',
  "referral information":'추천 정보',
  "existing":'기존의',
  "beneficiary referrals":'수혜자 추천',
  "beneficiary-referrals":'수혜자 추천',
  "followed":'따라 갔다',
  "total views":'총 견해',
  "total comments":'총 의견',
  "total follows":'총 다음',
  "today":'오늘',
  "yesterday":'어제',
  "last 7 days":'지난 7 일',
  "this month":'이번 달',
  "last month":'지난 달',
  "latest donations":'최신 기부',
  "to the campaign":'캠페인에',
  "part given from":'부품에서 주어진 부분',
  "introduced by":'소개',
  "history of receiving and giving":'받고 기부하는 역사',
  "account link":'계정 링크',
  "activity link":'활동 링크',
  "detail information":'자세한 정보',
  "link to donation":'기부금 링크',
  "donation/giving":'기부 / 기부',
  "interactive":'인터렉티브',
  "accept transferring":'양도를 수락하십시오',
  "reject transferring":'양도를 거부하십시오',
  "was a member of":'회원이었다',
  "select an existing profile":'기존 프로필을 선택하십시오',
  "latest articles":'최신 기사',
  "date desc":'최근',
  "name desc":'순서 z-> a',
  "value desc":'내림차순 가치',
  "date asc":'가장 오래된 날',
  "name asc":'순서 a-> z',
  "value asc":'증분 가치',
  "are you sure delete this message":'이 메시지를 삭제합니까?',
  "related units": '관련 단위',
  "benefactor":'후원자',
  "user note":'사용자의 메모',
  "admin note":'관리자의 메모',
  "filename":'파일 이름',
  "size":'크기',
  "responding":'응답',
  "register_to_donate":'기부에 등록하십시오',
  "attached image":'이미지를 첨부하십시오',
  "attached file":'첨부 파일',
  "file":'파일',
  "money transferred":'돈을 옮겼습니다',
  "total donated amount":'총 기증 된 금액',
  "description qr-code":'테이블에 기여하거나 링크에 직접 등록하려면 등록하십시오 (옆 QR 코드로 열립니다).',
  "note (administrator)":'참고 (관리자)',
  "note (register)":'참고 (등록)',
  "value display is not allowed":'기부금을 숨기십시오',
  "hidden value":'숨겨진 가치',
  "or you can copy the link of the page here to send it to your friends in other ways.":'또는 여기에 페이지 링크를 복사하여 다른 방법으로 친구에게 보낼 수 있습니다.',
  "slide show (live)":'슬라이드 쇼 (라이브)',
  "thanks for your donation":'기부 해 주셔서 감사합니다',
  "an anonymous donor":'익명 후원자',
  "total amount contributed up to now":'지금까지 총 금액이 기여했습니다',
  "thank you benefactors":'후원자 감사합니다',
  "donated amount":'기부 금액',
  "activity list":'활동 목록',
  "count desc":'Count desc',
  "campaign list":'캠페인 목록',
  "event list":'이벤트 목록',
  "email history thank you":'이메일 히스토리 감사합니다',
  "donors":'기증자',
  "recent donations":'최근 기부',
  "top donations":'최고의 기부',
  "total donation events":'총 기부 행사',
  "total donation value":'총 기부금 가치',
  "total donors":'총 기증자',
  "top donation count":'최고 기부 수',
  "top donation value":'최고 기부 가치',
  "donor list":'기증자 목록',
  "merge":'병합',
  "top donors":'최고의 기부자',
  "the system sent a thank you letter to":'이 시스템은 감사 편지를 보냈습니다',
  "for the campaign donation":'캠페인 기부',
  "email thank you":'이메일 감사합니다',
  "create donor":'기증자를 만듭니다',
  "edit donor":'기증자 편집',
  "recent givings":'최근의 경련',
  "edit beneficiary":'수혜자 편집',
  "account not linked yet":'아직 링크되지 않은 계정',
  "account not linked yet des":'Shareshare 계정에 아직 링크되지 않았으므로 여기를 클릭하여 링크하십시오.',
  "merge donor":'병합 기증자',
  "selected donor":'선택된 기증자',
  "merge with":'와 합치다',
  "beneficiary share":'수혜자가 소개합니다',
  "total giving events":'총 기부 행사',
  "total giving value":'총 기부 가치',
  "total beneficiaries":'총 수혜자',
  "merge beneficiary":'수혜자 병합',
  "staffs list":'회원',
  "recent recruitments":'최근 채용',
  "staff roster":'직원 명단',
  "staff":'회원',
  "collaborator":'협력자',
  "intern":'인턴',
  "employee type":'회원 유형',
  "edit staff":'멤버 편집',
  "create staff":'회원을 만듭니다',
  "display with anonymous":'익명 후원자로 표시하십시오',
  "create staff roster":'직원 명단을 만듭니다',
  "position placeholder":'예 : 지원 직원',
  "activity/organization":'활동 / 조직',
  "total staffs":'총 회원',
  "total recruitment event":'총 채용',
  "total user register":'총 사용자 레지스터',
  "edit staff roster":'직원 명단 편집',
  "staff information":'회원 정보',
  "create timesheets":'시간 시트를 만듭니다',
  "edit timesheets":'시간 시트 편집',
  "total time work":'총 시간 작업',
  "top staff area":'최고 직원 유통 영역',
  "top staff time work":'최고 직원 시간 근로 기부',
  "total hours":'전체 시간',
  "address placeholder":'예 : No. 10 Street 10',
  "introduce to":'소개하십시오',
  "find donor":'기증자를 찾으십시오',
  "alias name placeholder":'예 : John Mark',
  "country placeholder":'예 : 영국',
  "phone placeholder":'예 : 0987654321',
  "email placeholder":'예 : example@email.com',
  "choose date placeholder":'예 : 01/01/2021',
  "top giving value":'최고주는 가치',
  "top giving count":'최고 기부 카운트',
  "you choose channel":'당신은 채널을 선택합니다',
  "channel post":'채널의 게시물',
  "total location":'총 위치',
  "Click here to search for benefactors":'후원자를 검색하려면 여기를 클릭하십시오',
  "You can find a benefactor or add another benefactor":'후원자를 찾거나 다른 후원자를 추가 할 수 있습니다',
  "Click here to create a new one":'(새 것을 만들려면 여기를 클릭하십시오)',
  "new donor":'새로운 기증자',
  "referral list":'추천 목록',
  "find referral":'추천을 찾으십시오',
  "not added yet":'아직 추가되지 않았습니다',
  "merge note description":'이 조치는 실행 취소 할 수 없습니다.이 조치를 진행하기 전에 신중하게 고려해야합니다.',
  "find beneficiary":'수혜자를 찾으십시오',
  "share note description":'이 조치는 다른 부서와 관리 권한을 공유 할 것입니다.',
  "receiver name":'수취인 성명',
  "register for":'등록하다',
  "Click here to find referrer information":'추천자 정보를 찾으려면 여기를 클릭하십시오',
  "You can find a referrer or add a new one":'참조자를 찾거나 새로 추가 할 수 있습니다.',
  "new beneficiary":'새로운 수혜자',
  "Click here to find beneficiary information":'수혜자 정보를 찾으려면 여기를 클릭하십시오',
  "You can find a beneficiary or add another beneficiary":'수혜자를 찾거나 다른 수혜자를 추가 할 수 있습니다',
  "Click here to find an account":'계정을 찾으려면 여기를 클릭하십시오',
  "You can find the user\'s account and add":'사용자의 계정을 찾고 추가 할 수 있습니다',
  "recent candidates":'최근 후보자',
  "recruitment list":'채용 목록',
  "donation count":'기부 카운트',
  "donation insights":'기부 통찰력',
  "list of recipients":'수신자 목록',
  "giving insights":'통찰력 제공',
  "share beneficiary":'수혜자를 공유하십시오',
  "to shared":'공유',
  "this page is for organizations only create an organization":'이 페이지는 조직 만 조직 만 생성하는 것입니다',
  "Sign in for free to experience the features":'기능을 경험하려면 무료로 로그인하십시오',
  "Keep personalized tracking in the field of volunteering":'자원 봉사 분야에서 개인화 된 추적을 계속하십시오.',
  "Join and update information from the community":'커뮤니티의 정보에 가입하고 업데이트하십시오.',
  "Keep track of contributions, participating activities":'기여, 참여 활동을 추적하십시오.',
  "Find suggestions for suitable volunteer work":'적합한 자원 봉사 활동에 대한 제안을 찾으십시오.',
  "create account":'계정을 만듭니다',
  "Create an account for your organization or charity ":'조직이나 자선 단체에 대한 계정을 만듭니다.',
  "The platform supports a free tool to help transform the number of philanthropic activities":'이 플랫폼은 자선 활동의 수를 변화시키는 데 도움이되는 무료 도구를 지원합니다.',
  "Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization":'파트너와 상호 작용할 수있는 기능 : 조직의 기금 모금, 채용, 기부, 이벤트, 활동보고, 영향보고.',
  "Organizational management features: manage donors, beneficiaries, human resources":'조직 관리 기능 : 기부자, 수혜자, 인적 자원 관리.',
  "extra information":'추가 정보',
  "value placeholder":'예 : ABC ....',
  "datetime placeholder":'예 : 01/01/2000',
  "number placeholder":'예 : 012345 ...',
  "field name":'분야 명',
  "year of birth":'출생의 해',
  "remember me":'날 기억해',
  "it received":'받았다',
  "clone item":'복제하다',
  "clone beneficiary":'복제 수혜자',
  "clone note description":'이 조치를 통해 다른 조직의 정보에 영향을 미치지 않고 수혜자의 정보를 편집 할 수 있습니다.',
  "this year":'올해',
  "last year":'작년',
  "last 3 years":'지난 3 년',
  "showing":'전시',
  "Donate to the campaign by cash or bank transfer":'현금 또는 은행 이체로 캠페인에 기부하십시오',
  "Donate to the campaign with items":'항목으로 캠페인에 기부하십시오',
  "Contributions in kind can be converted to the corresponding value at the time of donation":'기부 시점에 기부금은 해당 가치로 전환 될 수 있습니다.',
  "Giving in kind can be converted into equivalent value at the time of giving":'주는 시점에 친절하게주는 것은 동등한 가치로 변환 될 수 있습니다.',
  "Give to beneficiary by cash or bank transfer":'현금 또는 은행 송금으로 수혜자에게 제공하십시오',
  "Give the beneficiary with the items":'수혜자에게 품목을 제공하십시오',
  "reconnect":'다시 연결하십시오',
  "network":'회로망',
  "network offline":'네트워크 오프라인',
  "list of co-hosting":'공동 주최 목록',
  "list of co-owners":'공동 소유자 목록',
  "co-hosting":'공동 주최',
  "do you want to leave the position of host of this campaign":'이 캠페인의 호스트 직책을 떠나고 싶습니까?',
  "co-owners":'공동 소유자',
  "refused":'거부',
  "do you want to decline the invitation":'초대를 거절 하시겠습니까?',
  "host type":'호스트 유형',
  "owner type":'소유자 유형',
  "staff list":'회원',
  "related activities":'관련 활동',
  "related giving events":'관련주는 이벤트',
  "related donation events":'관련 기부 행사',
  "related recruitments": '관련 모집 이벤트',
  "related events":'관련 이벤트',
  "peer organization":'동료 조직',
  "peer organization list":'피어 조직 목록',
  "total peer organization":'총 피어 조직',
  "has left":'떠났다',
  "total hosts":'총 호스트',
  "total members":'총 회원',
  "my host request":'회원 목록',
  "my-host-request":'회원 목록',
  "list of members":'회원 목록',
  "activity of co-owners":'공동 소유자의 활동',
  "medals":'메달',
  "medal list":'메달 목록',
  "medal manager":'메달 관리자',
  "medal summary":'메달 요약',
  "create medal":'메달을 만듭니다',
  "edit medal":'메달 편집',
  "total staff-rosters":'총 직원 로스터',
  "type desc":'타입 DESC',
  "type asc":'ASC를 입력하십시오',
  "medal awards":'메달 상',
  "medal rewards":'메달 보상',
  "medal reward list":'메달 보상 목록',
  "give medal":'메달을 줘',
  "effect date":'효과 날짜',
  "medal award manager":'메달 상 관리자',
  "pending givings":'보류중인 경제',
  "organizational structure":'조직 구조',
  "operating":'운영',
  "activities of member":'회원의 활동',
  "list of medal":'메달 목록',
  "level placeholder":'예 : 1-diamond, 2- 골드, 3- 씨, ...',
  "staffs":'직원',
  "activity summaries":'활동 요약',
  "donation summaries":'기부 요약',
  "givings summaries":'경제 요약',
  "staffs summaries":'직원 요약',
  "recruitment events":'채용',
  "organization unit reports":'조직 단위 보고서',
  "{value1} / {value2}: Number of organizations / total number of member organizations":'{value1} / {value2} : 조직 수 / 회원 조직의 총 수',
  "contributions":'기여',
  "waiting value":'대기 가치',
  "giving count":'주는 카운트',
  "spent my precious time at work":'직장에서 소중한 시간을 보냈습니다',
  "We sincerely appreciate your dedication of time and effort to making this life better":'우리는이 삶을 개선하기위한 시간과 노력의 헌신을 진심으로 감사합니다.',
  "We would like to award this medal to mark your contribution":'우리는이 메달을 수여하여 귀하의 기여를 표시하고 싶습니다.',
  "Wishing you always full of health, peace and enthusiasm. Sincerely thank you.":'항상 건강, 평화 및 열정으로 가득 차기를 바랍니다. \ nsuperally 감사합니다.',
  "staff received":'받은 회원',
  "donor received":'기증자',
  "register list":'등록 목록',
  "ML (blood unit)":'ML (혈액 단위)',
  "choose organization":'조직을 선택하십시오',
  "total events":'총 이벤트',
  "total activities":'총 활동',
  "total donations":'총 기부',
  "total waiting value":'총 등록 가치',
  "total givings value":'총 경제 가치',
  "total givings target":'총 경제 목표',
  "total givings":'총 경제',
  "total collaborator":'총 협력자',
  "total intern":'총 인턴',
  "total staff":'총 회원',
  "total volunteer":'총 자원 봉사자',
  "history medal rewards":'역사 메달 보상',
  "list of staff roster":'직원 명단 목록',
  "donation of organization":'조직의 기부',
  "giving of organization":'조직 제공',
  "certifications":'인증',
  "price (VND)":'가격 (VND)',
  "access rights":'액세스 권한',
  "contact list":'연락처 목록',
  "what is your organization name?":'조직 이름은 무엇입니까?',
  "sponsor":'스폰서',
  "disable on the personal page":'개인 페이지에서 비활성화하십시오',
  "disable donor on page description":'이 조치는 개인 페이지에서 기부와 관련된 정보를 숨 깁니다.',
  "disable beneficiary on page description":'이 조치는 개인 페이지에서 수혜자와 관련된 정보를 숨길 것입니다.',
  "disable work on page description":'이 조치는 개인 페이지에서의 작업과 관련된 정보를 숨길 것입니다.',
  "import donation data":'기부금 수입',
  "import":'수입 데이터',
  "you are importing campaign data":'캠페인 데이터를 가져오고 있습니다',
  "preview template":'미리보기 템플릿',
  "limit description":'파일 크기가 초과되지 않습니다',
  "template data":'템플릿 데이터',
  "notes description":'샘플 파일의 열에 대한 설명',
  "campaign id code":'캠페인 ID 코드',
  "contributed date":'기부 된 날짜',
  "donor\'s name":'기증자의 이름',
  "donor\'s owner id":'기증자의 소유자 ID',
  "donation quantity":'기부 수량',
  "donation unit":'기부 단위',
  "exchange value":'교환 가치',
  "donation note":'기부 메모',
  "additional fields for information":'정보를위한 추가 필드',
  "beneficiary\'s name":'수혜자의 이름',
  "beneficiary address":'수혜자 주소',
  "beneficiary\'s owner id":'수혜자의 소유자 ID',
  "giving quantity":'수량 제공',
  "giving unit":'주는 단위',
  "giving note":'주는 메모',
  "import giving data":'가져 오기 데이터 가져 오기',
  "Extract via campaign url path":'캠페인 URL 경로를 통해 추출하십시오',
  "For example":'\ n 예를 들어',
  "Date is required":'날짜가 필요합니다',
  "Including 4 characters, capitalization, unique":'4 자, 대문자, 독특한',
  "Value must be YES or NO":'값은 예 또는 아니오 여야합니다',
  "Value must be money, goods or blood":'가치는 "돈", "상품"또는 "혈액"이어야합니다.',
  "Number is required":'번호가 필요합니다',
  "Value must be male, female or null":'값은 "남성", "여성"또는 빈 여야합니다.',
  "current size":'현재 크기',
  "max size":'최대 크기',
  "total row":'총 행',
  "success row":'성공 행',
  "row":'열',
  "upload file":'파일 업로드',
  "question from the campaign":'캠페인에서 질문',
  "START":'시작',
  "FINISH":'마치다',
  "node":'마디',
  "send a confirmation letter for the donation":'기부금에 대한 확인서를 보내십시오',
  "link page":'링크 페이지',
  "list of partners":'파트너 목록',
  "short name":'짧은 이름',
  "first name":'이름',
  "last name":'성',
  "alternative email":'대체 이메일',
  "create partners":'파트너 생성',
  "edit partners":'파트너 편집',
  "delete partner":'파트너 삭제',
  "are you sure you want to delete this partner":'이 파트너를 삭제 하시겠습니까?',
  "total org members":'조직 회원',
  "total org partners":'조직 파트너',
  "total org link":'조직 링크',
  "Volunteer":'자원 봉사자',
  "Administrator":'관리자',
  "Office staff":'사무 직원',
  "Part-time employee":'파트 타임 직원',
  "Assistant":'어시스턴트',
  "Director":'감독',
  "Supporter":'서포터',
  "total other activities":'총 기타 활동',
  "total active donation events":'총 활발한 기부 행사',
  "total active giving events":'총 활발한 기부 행사',
  "total recruitments":'총 채용',
  "total event incoming":'총 이벤트 수신',
  "input partner name":'입력 파트너 이름',
  "select account wishare":'계정 Wishare를 선택하십시오',
  "total active recruitments events": '적극적인 채용',
  "subcategories organization": '하위 범주 조직',
  "create subcategories organization": '하위 범주 조직을 만듭니다',
  "update subcategories organization": '하위 범주 조직을 업데이트하십시오',
  "subcategories activity": '하위 범주 활동',
  "create subcategories activity": '하위 범주 활동을 만듭니다',
  "update subcategories activity": '하위 범주 활동을 업데이트합니다',
  "subcategories": '하위 범주',
  "remove this": '이것을 제거 하시겠습니까?',
  "list of documents": '문서 목록',
  "list of channels": '채널 목록',
  "introduce channel": '채널을 소개합니다',
  "short description about your channel": '채널에 대한 간단한 설명',
  "share discussion": '토론을 공유하십시오',
  "choose category": '카테고리를 선택하십시오',
  "parent of subcategory": '하위 범주의 부모',
  "hours": '시간',
  "choose another Wishare subscription": '다른 Wishare 구독을 선택하십시오',
  "filter conditions by subcategory": '하위 범주 별 필터 조건',
  "value to contribute": '기여할 가치',
  "can giving": '줄 수 있습니다',
}
