import {LoadingOutlined,} from '@ant-design/icons'
import {referral_deleteReferral_Api, referral_getReferrals_Api,} from 'apis'
import classnames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import Timestamp from 'components/Feed/Timestamp'
import Modal from 'components/Modal/Modal'
import copyToClipboard from 'copy-to-clipboard'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {useContext} from 'react'
import useToggle from 'react-use/lib/useToggle'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {ROOT_URL} from '../../../envs/_current/config'
import Invite from './Invite'
import {IoTrashOutline} from "react-icons/io5";
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";

const InviteRow = ({
                     className,
                     inviter,
                     invite_code,
                     uses,
                     actions,
                     expires,
                   }) => (
  <div
    className={classnames(
      'flex items-center gap-2',
      className
    )}>
    <div
      style={{flex: '3 1 0px'}}
      className="hidden md:block text-xs font-medium">
      {inviter}
    </div>
    <div style={{flex: '3 1 0px'}}>
      {invite_code}
    </div>
    <div style={{flex: '1 1 0px'}}>
      {uses}
    </div>
    <div style={{flex: '3 1 0px'}}>
      {expires}
    </div>
    <div style={{flex: '1 1 0px'}}>
      {actions}
    </div>
  </div>
)
const InviteRowHeader = withTranslate(
  ({translate}) => (
    <InviteRow
      {...{
        inviter: (
          <div className="ml-3 text-xs uppercase text-color-300 font-semibold hidden md:block">
            {translate('inviter')}
          </div>
        ),
        invite_code: (
          <div className="ml-0 md:ml-3 text-xs uppercase text-color-300 font-semibold truncate">
            {translate('invite code')}
          </div>
        ),
        uses: (
          <div className="ml-3 md:ml-0 text-xs text-center uppercase text-color-300 font-semibold truncate">
            {translate('uses')}
          </div>
        ),
        expires: (
          <div className="text-xs text-center uppercase text-color-300 font-semibold truncate">
            {translate('expires')}
          </div>
        ),
        actions: (
          <div className="text-xs text-center uppercase opacity-0 font-semibold truncate">
            {translate('delete')}
          </div>
        ),
      }}
    />
  )
)

function InviteManager({item}) {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)
  const containerType = item._type
  const containerId = item.id
  const [isToggle, toggle] = useToggle()
  const [listKey, setListKey] =
    useToggle()
  return (
    <div className="container max-w-6xl mx-auto p-3 verticalList">
      <div className="text-xs font-semibold">
        {t(
          'Here is a list of all invite links. You can revoke any one.'
        )}{' '}
        <span
          onClick={() => toggle()}
          className="color-primary font-bold cursor-pointer">
          {t('create one')}
        </span>
        <Modal
          destroyOnClose
          footer={null}
          title={t('invite')}
          maskClosable
          visible={isToggle}
          onCancel={() => {
            setListKey(
              new Date().getTime()
            )
            toggle()
          }}>
          <Invite item={item}/>
        </Modal>
      </div>
      <div className="border-b border-gray-300">
        <div/>
      </div>
      <div>
        <InviteRowHeader/>
        <div className="mt-6">
          <LazyPagination
            key={listKey}
            query={{
              ':prop': containerType,
              ':id': containerId,
            }}
            renderEmpty={() => (
              <div className="w-full">
                <EmptyHolder
                  subTitle={t(
                    'no invites yet'
                  )}
                />
              </div>
            )}
            apiInfo={
              referral_getReferrals_Api
            }
            renderItem={(item) =>
              item && (
                <InviteRow
                  className="h-16 rounded-lg border border-transparent hover:border-gray-300 p-0 md:p-2"
                  {...{
                    inviter:
                      item.creator.name || 'not available',
                    invite_code: (
                      <div
                        onClick={() => {
                          copyToClipboard(
                            [
                              ROOT_URL,
                              'invite',
                              containerType,
                              item.referral_code,
                            ].join('/')
                          )
                        }}
                        className="flex flex-col text-xs text-secondary font-medium cursor-pointer ">
                        <span>{item.referral_code}</span>
                        <span className="text-color-500 font-light px-1 text-xs italic">
                          ({t('copy')})
                        </span>
                      </div>
                    ),
                    uses: (
                      <div className="text-center">
                        {item.total_accepts ||
                        0}
                      </div>
                    ),
                    expires: (
                      <div className="text-center text-color-300 text-xs font-light italic">
                        {_.isEmpty(
                          item.expired
                        ) ? (
                          ''
                        ) : (
                          <Timestamp
                            timestamp={
                              item.expired
                            }
                          />
                        )}
                      </div>
                    ),
                    actions: (
                      <AsyncAction
                        apiInfo={
                          referral_deleteReferral_Api
                        }
                        query={{
                          ':referral_id':
                          item.id,
                        }}>
                        {({
                            handleAsyncAction,
                            isLoading,
                            success,
                          }) =>
                          success ? (
                            <div className="text-red-600 text-right text-2xs md:text-base">
                              {t(
                                'deleted'
                              )}
                            </div>
                          ) : (
                            <HeaderButton
                              width={isSm ? 35 : 50}
                              height={isSm ? 35 : 50}
                              onClick={() =>
                                handleAsyncAction()
                              }
                              className="text-color-400 hover:text-red-600 ml-auto cursor-pointer">
                              {isLoading ? (
                                <LoadingOutlined/>
                              ) : (
                                <IoTrashOutline size={isSm ? 15 : 20}/>
                              )}
                            </HeaderButton>
                          )
                        }
                      </AsyncAction>
                    ),
                  }}
                />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

export default InviteManager
