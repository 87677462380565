import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const ActivityLazy =
  createLazyComponent(() =>
    import('./Activity')
  )

const Activity = (props) => (
  <ActivityLazy {...props} />
)

export default Activity
