import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
} from 'react'
import {IoAddOutline, IoFlowerOutline, IoGiftOutline} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import wishareModel from 'views/Wishare/wishareModel'
import IOReport from '../../Activity/components/IOReport'
import * as DonationEventView from '../../Donation/components/DonationEventView'
import * as GivingEventView from '../../Giving/components/GivingEventView'
import DonationContext from '../DonationContext'
import AppIcon from "../../../../components/AppIcon";

const DonationStatisticBar = ({
  donation,
}) => {
  const t = useTranslate()
  if (!!!donation) return null
  return (
    <div className="flex flex-col">
      <div className="flex items-center text-primary font-bold text-xl uppercase mb-1">
        {`${t('donation')}`}
      </div>
      <div className="p-3 rounded-lg border-2 border-primary">
        <DonationEventView.StatisticBar
          donationEvent={donation}
        />
      </div>
    </div>
  )
}

const GivingStatisticBar = ({
  donation,
}) => {
  const t = useTranslate()

  const history = useHistory()

  const givingEvent = _.get(
    donation,
    'giving_events.0'
  )

  if (
    !!givingEvent
  )
    return (
      <div className="flex flex-col">
        <div className="text-secondary font-bold text-xl uppercase mb-1">
          {t('giving')}
        </div>
        <Link
          to={getLinkToDetail(
            givingEvent
          )}
          className="flex items-center text-secondary leading-tight mb-3">
            <IoGiftOutline className="text-xl text-secondary" />
            <span className="flex-1 font-bold ml-2">{getTitle(givingEvent)}</span>
        </Link>
        <div className="p-3 rounded-lg border-2 border-secondary">
          <GivingEventView.StatisticBar
            givingEvent={givingEvent}
          />
        </div>
      </div>
    )

  if (!!_.get(donation, 'edit')) {
    return (
      <div
        className="flex flex-col"
        onClick={() => {
          history.push({
            pathname:
              wishareModel.getLinkToSetting(
                donation,
                '/giving'
              ),
          })
        }}>
        <div className="flex items-center text-secondary font-bold text-xl uppercase mb-1">
          {t('giving')}
        </div>
        <div className="p-2 space-x-1 flex items-center border-2 border-secondary text-secondary rounded-lg cursor-pointer">
          <IoAddOutline />
          <span>
            {t('link to giving')}
          </span>
        </div>
      </div>
    )
  }
  return null
}

const TotalStatisticBar = ({
  donation,
}) => {
  const t = useTranslate()
  if (!!!donation) return null

  if (
    !_.get(
      donation,
      'giving_events.length'
    )
  )
    return null

  const newItem = {
    input_statistics: _.get(
      donation,
      'total_donation_items',
      []
    ),
    output_statistics: _.get(
      donation,
      'total_giving_items',
      []
    ),
  }

  return (
    !!_.get(
      donation,
      'giving_events.length'
    ) && (
      <div className="flex flex-col space-y-3">
        <div className="text-gray-600 font-bold text-xl uppercase flex items-center justify-start">
          {t('outcome')}
        </div>
        <IOReport item={newItem} />
      </div>
    )
  )
}

const DonationSummary = React.memo(
  ({ Wrapper = 'div', ...props }) => {
    const { isSm } = useContext(
      LayoutContext
    )

    const t = useTranslate()

    const { donation } = useContext(
      DonationContext
    )
    if (!donation) return null

    //   options.owner.id,
    //   options.impactCategories.id,
    //   options.statisticBarResult.id, ==> TotalStatisticBar
    //   options.statisticBarRefs.id, ==> GivingStatisticBar
    //   options.statisticBar.id, ==> DonationStatisticBar
    //   options.submitBar.id,
    //   options.share.id,
    //   options.donatePackages.id
    return (
      <Wrapper className="flex flex-col space-y-3">
        <TotalStatisticBar
          donation={donation}
        />
        <GivingStatisticBar
          donation={donation}
        />
        <DonationStatisticBar
          donation={donation}
        />
      </Wrapper>
    )
  }
)

export default DonationSummary
