import _ from 'lodash'

export default function getTitle(
  item = {}
) {
  let title = _.get(item, 'title');
  if (_.get(item, '_type') === "channel") {
    if (!Boolean(["user", "organization"].includes(_.get(item, 'owner._type', "user")))) {
      title = _.get(item, 'owner.title', _.get(item, 'owner.name'));
    }
  }

  if (title) return title
  return _.get(item, 'name', '')
}
