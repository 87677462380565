import { Input } from 'antd'
import {
  categories_createSubcategory_api,
  categories_deleteSubcategory_api,
  categories_editSubcategory_api,
  categories_getSubcategoriesByOwner_api,
  categories_getSubcategoryById_api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {
  branch,
  compose,
  fromRenderProps,
  mapProps,
  pure,
  renderNothing,
} from 'recompose'
import CRUD from 'views/Shared/enums/CRUD'
import { SubCategoryTypes } from 'views/Wishare/factory/createEntityManager'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import WorkspaceContext from '../WorkspaceContext'
import { renderSubCategory } from './renderSubCategory'
import SubCategoriesContainer from './SubCategoriesContainer'

const formSchema = {
  title: {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            placeholder: 'title',
          }),
      },
    ],
  },
  description: {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            rows: 3,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
}

export const ActivitySubCategories =
  compose(
    withTranslate,
    mapProps(
      ({
        owner_id,
        owner_type,
        translate,
        ...props
      }) => {
        const subcategory_type =
          SubCategoryTypes.ACTIVITY
            .value
        const validate = (values) => {
          let errors = {}
          if (
            _.isEmpty(values?.title)
          ) {
            errors.title = translate(
              'required field'
            )
          }
          return errors
        }
        return {
          ...props,
          owner_id,
          owner_type,
          title:
            'subcategories activity',
          filter_actions: {
            sortBy: 'sort-by',
            keyword: 'keyword',
          },
          fetchItems: {
            apiInfo:
              categories_getSubcategoriesByOwner_api,
            query: bindQueryParams([
              {
                id: owner_id,
              },
              { type: owner_type },
              { subcategory_type },
            ]),
          },
          renderItem: renderSubCategory,
          createFormSchema: (
            action
          ) => {
            switch (action) {
              case CRUD.CREATE:
              case CRUD.UPDATE:
                return [
                  [
                    formSchema.title,
                    formSchema.description,
                  ],
                  validate,
                ]
              default:
                break
            }
            return []
          },
          configs: {
            fetchEntity: (id) => ({
              apiInfo:
                categories_getSubcategoryById_api,
              query: bindQueryParam({
                id,
              }),
            }),
            create: {
              apiInfo:
                categories_createSubcategory_api,
              query: bindQueryParams([
                {
                  id: owner_id,
                },
                { type: owner_type },
              ]),
              values: {
                subcategory_type,
              },
            },
            update: {
              apiInfo:
                categories_editSubcategory_api,
            },
            delete: {
              apiInfo:
                categories_deleteSubcategory_api,
            },
            modal: {
              createTitle: 'create subcategories activity',
              updateTitle: 'update subcategories activity',
              renderTitle: (title) => (
                <div className="text-center font-bold text-color-000 uppercase">
                  {translate(title)}
                </div>
              ),
            },
          },
        }
      }
    )
  )(SubCategoriesContainer)

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(ActivitySubCategories)
