import { Radio } from 'antd'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import {
  article_getArticleGroups_Api,
  auth_fetchSuggestionActivitiesByAuthCategories_Api,
  auth_fetchSuggestionChannelsByAuthCategories_Api,
  auth_fetchSuggestionDonationEventsByAuthCategories_Api,
  auth_fetchSuggestionEventsByAuthCategories_Api,
  auth_fetchSuggestionGivingEventsByAuthCategories_Api,
  auth_fetchSuggestionOrganizationsByAuthCategories_Api,
  auth_fetchSuggestionPostsByAuthCategories_Api,
  auth_fetchSuggestionRecruitmentEventsByAuthCategories_Api,
  auth_fetchSuggestionUsersByAuthCategories_Api,
  auth_getMyFollowingActivities_Api,
  auth_getMyFollowingArticle_Api,
  auth_getMyFollowingChannels_Api,
  auth_getMyFollowingDonationEvents_Api,
  auth_getMyFollowingEvents_Api,
  auth_getMyFollowingGivingEvents_Api,
  auth_getMyFollowingPost_Api,
  auth_getMyFollowingRecruitmentEvents_Api,
  auth_getMyFollowingUser_Api,
  auth_getMyJoinedOrganization_Api,
} from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import { LoginContext } from 'components/LoginContext'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import LoadingPage from 'views/LoadingPage'
import { renderLoading } from 'views/MainPage/functions/renderPagingLoading'
import SearchContext from 'views/Search/SearchContext'
import { SearchTypes } from 'views/Search/SearchProvider'
import {
  deepTranslate,
  getResponseItem,
  renderTrue,
} from 'views/Shared'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import ArticleResult from './components/ArticleResult'
import createSearchResultComponent from './functions/createSearchResultComponent'

export const RecommendationFilterTypes =
  Object.freeze({
    JOINED: 'joined',
    FOLLOWING: 'following',
    POPULAR: 'popular',
    LASTED: 'lasted',
  })

const renderItem = (item, index) => {
  if (!item) return null

  return (
    <div
      key={index}
      className="w-full">
      {createSearchResultComponent(
        item,
        index
      )}
    </div>
  )
}

const RecommendationFilter = ({
  filter,
  setFilter,
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const { searchParams } = useContext(
    SearchContext
  )

  const { type } = searchParams || {}

  const filter_options = useMemo(
    () => [
      {
        label: 'popular',
        value:
          RecommendationFilterTypes.POPULAR,
        validated: (search_type) =>
          ![
            SearchTypes.ARTICLE,
          ].includes(search_type),
      },
      {
        label: 'following',
        value:
          RecommendationFilterTypes.FOLLOWING,
        validated: (search_type) =>
          Boolean(
            login &&
              ![
                SearchTypes.ARTICLE,
                SearchTypes.ORGANIZATION,
              ].includes(search_type)
          ),
      },
      {
        label: 'joined',
        value:
          RecommendationFilterTypes.JOINED,
        validated: (search_type) =>
          Boolean(
            login &&
              search_type ===
                SearchTypes.ORGANIZATION
          ),
      },
      {
        label: 'latest articles',
        value:
          RecommendationFilterTypes.LASTED,
        validated: (search_type) =>
          search_type ===
          SearchTypes.ARTICLE,
      },
    ],
    [login]
  )

  const options = useMemo(
    () =>
      Array.from(filter_options)
        .filter(
          ({
            validated = renderTrue,
          }) => validated(type)
        )
        .map(deepTranslate(t)),
    [t, type, filter_options]
  )

  const validValue = useMemo(() => {
    const item =
      _.find(options, {
        value: filter,
      }) || _.first(options)
    return _.get(item, 'value', item)
  }, [filter, options])

  return (
    <Radio.Group
      name="filter"
      options={options}
      value={validValue}
      optionType="button"
      buttonStyle="solid"
      onChange={(event) => {
        setFilter(
          _.get(event, 'target.value')
        )
      }}
    />
  )
}

export const Selector = ({
  selector,
  children,
  equalityFn,
}) => {
  const result = useSelector(
    selector,
    equalityFn
  )
  return children(result)
}

const apis = {
  popular: {
    post: auth_fetchSuggestionPostsByAuthCategories_Api,
    channel:
      auth_fetchSuggestionChannelsByAuthCategories_Api,
    event:
      auth_fetchSuggestionEventsByAuthCategories_Api,
    donation:
      auth_fetchSuggestionDonationEventsByAuthCategories_Api,
    giving:
      auth_fetchSuggestionGivingEventsByAuthCategories_Api,
    job: auth_fetchSuggestionRecruitmentEventsByAuthCategories_Api,
    organization:
      auth_fetchSuggestionOrganizationsByAuthCategories_Api,
    activity:
      auth_fetchSuggestionActivitiesByAuthCategories_Api,
    user: auth_fetchSuggestionUsersByAuthCategories_Api,
  },
  following: {
    article:
      auth_getMyFollowingArticle_Api,
    post: auth_getMyFollowingPost_Api,
    channel:
      auth_getMyFollowingChannels_Api,
    event:
      auth_getMyFollowingEvents_Api,
    donation:
      auth_getMyFollowingDonationEvents_Api,
    giving:
      auth_getMyFollowingGivingEvents_Api,
    job: auth_getMyFollowingRecruitmentEvents_Api,
    organization:
      auth_getMyJoinedOrganization_Api,
    activity:
      auth_getMyFollowingActivities_Api,
    user: auth_getMyFollowingUser_Api,
  },
  lasted: {
    article:
      article_getArticleGroups_Api,
  },
}

export const Recommendation = ({
  type = '',
  default_filter = RecommendationFilterTypes.POPULAR,
}) => {
  const [currentFilter, setFilter] =
    useState(default_filter)

  const refreshKey = [
    type,
    currentFilter,
  ]

  const content = useMemo(() => {
    let params = {}

    switch (currentFilter) {
      case RecommendationFilterTypes.POPULAR:
        params = {
          query: bindQueryParam({
            type,
          }),
          apiInfo: _.get(
            apis.popular,
            type
          ),
        }
        break
      case RecommendationFilterTypes.FOLLOWING:
        params = {
          apiInfo: _.get(
            apis.following,
            type
          ),
          values: {
            filter: 'follow',
          },
        }
        break
      case RecommendationFilterTypes.LASTED:
        return (
          <Async
            {...{
              apiInfo: _.get(
                apis.lasted,
                type
              ),
            }}>
            {({
              response,
              isLoading,
            }) => {
              if (!!isLoading) {
                return <LoadingPage />
              }
              const data =
                getResponseItem(
                  response,
                  'data.data'
                )
              return (
                <ArticleResult
                  {...data}
                />
              )
            }}
          </Async>
        )
      case RecommendationFilterTypes.JOINED:
        params = {
          apiInfo: _.get(
            apis.following,
            type
          ),
          values: {
            filter: 'member',
          },
        }
        break
      default:
        break
    }
    return (
      <LazyPagination
        {...{
          renderItem,
          refreshKey,
          renderLoading,
        }}
        {...params}
      />
    )
  }, [type, refreshKey, currentFilter])

  if (
    !_.includes(
      Object.values(SearchTypes),
      type
    )
  ) {
    return null
  }

  if (
    _.every(
      [
        apis.popular,
        apis.following,
        apis.lasted,
      ],
      (data) => !_.has(data, type)
    )
  ) {
    return <EmptyHolder />
  }
  return (
    <div className="background w-full justify-center md:p-0">
      <div className="flex items-center w-full pb-2 mb-3 border-b-2 border-primary RadioGroup">
        <RecommendationFilter
          setFilter={setFilter}
          filter={currentFilter}
        />
      </div>
      <ErrorBoundary>
        {content}
      </ErrorBoundary>
    </div>
  )
}
