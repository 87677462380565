import { userSchema } from 'apis/schema'
import _ from 'lodash'
import { selectEntities } from '../entities/selectors'
import languageArray from "../../conts/languages";

export const getLang = state => {
  let lang = 'vi';
  if (state.i18nState) {
    if (languageArray.includes(state.i18nState.lang)) lang = state.i18nState.lang;
  }
  return lang
}
export const getLogin = state => {
  return state.auth && state.auth.login
    ? selectEntities(
      state,
      state.auth.login,
      userSchema,
      state.auth.user
    ) || state.auth.user
    : undefined
}
export const getLoginBoolean = state =>
  !!getLogin(state)

export const getAllPackages = (
  login = {}
) => {
  return login['packages']
}
export const getCurrentPackageIndex = (
  login = {}
) => {
  return login['current_package_id']
}
export const selectCurrentPackages = state => {
  const login = getLogin(state)
  const allPackages = getAllPackages(
    login
  )
  const current = getCurrentPackageIndex(
    login
  )
  return _.get(allPackages, current)
}
export const getAvailableCategories = state => {
  const login = getLogin(state)
  return _.get(
    login,
    'categories',
    []
  )
}
