import {Divider, message, Radio,} from 'antd'
import {auth_login_Api} from 'apis'
import classNames from 'classnames'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useToggle} from 'react-use'
import {loginSuccess} from 'redux/actions'
import UseState from '../../components/UseState'
import {useAppConfig} from '../../modules/local'
import AppleLoginBtn from './AppleLoginBtn'
import GoogleLoginBtn from './GoogleLoginBtn/index'
import './Login.css'
import LoginForm from './LoginForm'
import Register from './RegisterForm'
import ForDevelop from "../../envs/ForDevelop";

const Footer = () => {
  const t = useTranslate()

  return (
    <div className="text-center font-light italic text-sm text-color-400">
      {t(
        'by using Wishare, you agree to our'
      )}{' '}
      <Link
        to={'/helps/privacy-policy'}
        className="text-color-000 font-medium no-underline">
        {t('privacy policy')}
      </Link>{' '}
      {t('and')}{' '}
      <Link
        to={'/helps/terms-of-service'}
        className="text-color-000 font-medium no-underline">
        {t('terms of service')}
      </Link>
      .
    </div>
  )
}

const Login = ({
  onSuccess = Null,
  isSignUpDefault = false,
  initOrgType = undefined,
}) => {
  const [isSignUp, toggle] = useToggle(
    isSignUpDefault
  )
  const t = useTranslate()
  const dispatch = useDispatch()
  const {
    isLoading,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(
        loginSuccess(
          _.get(args, '1.response.data')
        )
      )
      onSuccess()
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.message')
      ),
  })

  const { org_types = {} } =
    useAppConfig()
  let user_types = []
  user_types.push({
    id: 0,
    label: t('individual'),
    className: 'text-primary',
  })
  Object.keys(org_types)
    .sort()
    .map((e) => {
      user_types.push({
        id: e,
        label: org_types[e],
        className: 'text-color-org',
      })
    })

  return (
    <div className="flex flex-col space-y-3 pb-6">
      <div className="w-full text-center mb-3">
        <Radio.Group
          buttonStyle="solid"
          onChange={(e) => {
            if (
              isSignUp !==
              e.target.value
            )
              toggle()
          }}
          defaultValue={isSignUp}>
          <Radio.Button value={false}>
            {t('login')}
          </Radio.Button>
          <Radio.Button value={true}>
            {t('Sign up')}
          </Radio.Button>
        </Radio.Group>
      </div>

      {!isSignUp && (
        <React.Fragment>
          <div className="px-3 space-y-3">
            {!!!process.env
              .REACT_APP_CORDOVA && (
              <React.Fragment>
                <section className="flex flex-col justify-center space-y-3 pt-6">
                  {/* <FacebookLoginBtn
                    onSuccess={
                      handleAsyncAction
                    }
                  /> */}
                  <ForDevelop>
                    <AppleLoginBtn
                      className="rounded-lg"
                      onSuccess={
                        handleAsyncAction
                      }
                    />
                  </ForDevelop>
                  <GoogleLoginBtn
                    className="rounded-lg"
                    onSuccess={
                      handleAsyncAction
                    }
                  />
                </section>
                <Divider
                  style={{
                    marginBottom: 0,
                    color:
                      'var(--text-color-500)',
                  }}>
                  {t('or')}
                </Divider>
              </React.Fragment>
            )}
            <div className="text-center font-semibold text-sm mb-2">
              {t('with account')}{' '}
              Wishare
            </div>

            <LoginForm
              onSuccess={onSuccess}
            />

            <Footer />
          </div>
        </React.Fragment>
      )}

      {/*Sign Up*/}

      {isSignUp && (
        <React.Fragment>
          <div className="px-3 space-y-3">
            <UseState
              initialValue={{
                step: !!!initOrgType
                  ? 0
                  : 1,
                org_type: Number(
                  initOrgType
                ),
              }}>
              {([
                values,
                setValues,
              ]) => {
                const { step } = values
                switch (step) {
                  case 0:
                    return (
                      <section className="flex flex-col justify-center space-y-3 py-6">
                        <div className="text-center text-sm font-bold text-color-000">
                          {t('you are')}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                          {user_types.map(
                            (e, i) => (
                              <div
                                key={i}
                                onClick={() => {
                                  if (
                                    e.id ===
                                    0
                                  ) {
                                    setValues(
                                      {
                                        ...values,
                                        step: 1,
                                      }
                                    )
                                  } else {
                                    setValues(
                                      {
                                        ...values,
                                        org_type:
                                          e.id,
                                        step: 1,
                                      }
                                    )
                                  }
                                }}
                                className={classNames(
                                  'p-4 border capitalize w-full background text-center font-semibold rounded-lg cursor-pointer hover:shadow-out',
                                  e.className
                                )}>
                                {t(
                                  e.label
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </section>
                    )
                  case 1:
                    return !!values.org_type ? (
                      <section className="flex flex-col justify-center space-y-3 py-6">
                        <Register
                          setBack={() => {
                            setValues({
                              ...values,
                              org_type:
                                undefined,
                              step: 0,
                            })
                          }}
                          {...values}
                        />
                      </section>
                    ) : (
                      <div className="flex flex-col space-y-1 pt-6">
                        <section className="flex flex-col justify-center verticalList__small">
                          <GoogleLoginBtn
                            onSuccess={
                              handleAsyncAction
                            }
                          />
                        </section>
                        <Divider
                          style={{
                            marginBottom: 0,
                          }}>
                          {t('or')}
                        </Divider>
                        <div className="text-center font-light mb-2">
                          {t(
                            'with your email'
                          )}
                        </div>

                        <Register
                          setBack={() => {
                            setValues({
                              ...values,
                              org_type:
                                undefined,
                              step: 0,
                            })
                          }}
                          {...values}
                        />
                      </div>
                    )
                  default:
                    return null
                }
              }}
            </UseState>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
export default Login
