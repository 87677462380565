import Icon from '@ant-design/icons'
import {Avatar, Col, Image, Progress, Row, Tooltip,} from 'antd'
import {getType} from 'apis/model/base'
import classNames from 'classnames'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import AppPlaceHolderSvg from 'components/Svgs/AppPlaceHolderSvg'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext, useMemo,} from 'react'
import {Link} from 'react-router-dom'
import {fullGrid, Null, oneQuater, renderOwnChild,} from 'views/Shared'
import {WishareEntities} from '../enums'
import {createDefaultImage} from '../factory/createDefaultImage'
import {getEventItemStatus} from '../functions/getEventItemStatus'
import getPredictStatus from "../../../helpers/getPredictStatus";

export const ProgressBar = ({
  eventItem,
  unit,
  total,
  current,
  noLimit = false,
  strokeColor = 'var(--color-primary)',
  showLabel = true,
}) => {
  const t = useTranslate()

  const status =
    getEventItemStatus(eventItem)
  const eventType = getType(eventItem)
  const backgroundColorBarStyle =
    eventType === 'donation_event'
      ? 'var(--color-primary-50)'
      : eventType === 'giving_event'
      ? 'var(--color-secondary-50)'
      : 'var(--color-primary-50)'
  const isCompleted = status === 9

  const totalValue = parseInt(total, 10)
  const currentValue = parseInt(
    current,
    10
  )
  const percent = (
    (currentValue / totalValue) *
    100
  ).toFixed(2)

  const _unit = unit ? t(unit) : ''

  const MeterProgress = ({ item }) => {
    const type = _.get(item, '_type')
    switch (type) {
      case 'donation_event':
        return (
          <div className="meter flex-1">
            <span
              style={{
                backgroundColor:
                  'var(--color-primary)',
                width: '100%',
              }}
            />
          </div>
        )
      case 'giving_event':
        return (
          <div className="meter flex-1">
            <span
              style={{
                backgroundColor:
                  'var(--color-secondary)',
                width: '100%',
              }}
            />
          </div>
        )
      case 'recruitment_event':
        return (
          <div className="meter flex-1">
            <span
              style={{
                backgroundColor:
                  'var(--color-green-600)',
                width: '100%',
              }}
            />
          </div>
        )
      default:
        return (
          <div className="meter flex-1">
            <span
              style={{
                backgroundColor:
                  'var(--color-primary)',
                width: '100%',
              }}
            />
          </div>
        )
    }
  }

  const LabelComplete = ({ item }) => {
    const t = useTranslate()
    const type = _.get(item, '_type')
    switch (type) {
      case 'donation_event':
        return t('achieved')
      case 'giving_event':
        return t('gave away')
      case 'recruitment_event':
        return t('recruited')
      default:
        return t('achieved')
    }
  }

  const _processBar = useMemo(() => {
    if (!!totalValue || isCompleted)
      return (
        <Progress
          percent={percent}
          showInfo={false}
          className="my-1"
          strokeColor={strokeColor}
        />
      )

    return (
      <MeterProgress item={eventItem} />
    )
  }, [eventItem])

  return (
    <div className="flex flex-col mb-1">
      {showLabel ? (
        <div className="flex justify-between text-2xs text-color-400 leading-tight">
          <span>{t('target')}</span>
          {totalValue ? (
            <span className="font-bold">
              {`${Number(
                totalValue
              ).toLocaleString()} ${_unit}`}
            </span>
          ) : (
            <span>{t('no limit')}</span>
          )}
        </div>
      ) : null}
      {isCompleted ? (
        <div className="flex items-center">
          {_processBar}
          <span
            className="pl-1 leading-none uppercase font-bold tracking-wide text-green-500"
            style={{
              fontSize: '0.5rem',
            }}>
            done
          </span>
        </div>
      ) : (
        <div className="flex items-center">
          {_processBar}
        </div>
      )}
      {showLabel ? (
        <div className="flex justify-between text-xs uppercase text-primary leading-tight">
          <div className="font-medium">
            <LabelComplete
              item={eventItem}
            />
          </div>
          {totalValue === 0 ? (
            <div className="font-bold space-x-1">
              <span>
                {Number(
                  currentValue
                ).toLocaleString()}
              </span>
              <span>{_unit}</span>
            </div>
          ) : (
            <div className="font-bold space-x-1">
              <span>{`(${percent}%)`}</span>
              <span>
                {Number(
                  currentValue
                ).toLocaleString()}
              </span>
              <span>{_unit}</span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export const FlexCol = ({
  id,
  children,
  className,
  flex = true,
  style,
}) => (
  <div
    id={id}
    style={style}
    className={classNames(
      'flex flex-col',
      !!flex ? 'flex-1' : '',
      className
    )}>
    {children}
  </div>
)

const DefaultHyperLink = ({
  title,
  pathname,
  className,
  onClick = Null,
}) => {
  const t = useTranslate()
  return (
    <Link
      to={pathname}
      onClick={onClick}
      className={classNames(
        'font-semibold text-color-000 no-underline max-lines-1 leading-tight',
        className
      )}>
      {t(title || 'no title')}
    </Link>
  )
}

const DefaultImage = ({
  src,
  type,
}) => (
  <Image
    preview={false}
    fallback={createDefaultImage({
      type,
    })}
    className="border-img-radius-custom object-cover w-full image-item-template"
    src={src || 'no-image'}
  />
)

export const OverlayItemBanner = ({
  title,
  children,
  className,
  type = WishareEntities.ACTIVITY,
}) => {
  const t = useTranslate()
  let background = 'bg-gray-600'
  switch (type) {
    case WishareEntities.DONOR:
      background = 'bg-blue-600'
      break
    case WishareEntities.BENEFICIARY:
      background = 'bg-secondary'
      break
    case WishareEntities.ACTIVITY:
      background = 'bg-color-act'
      break
    case WishareEntities.DONATION:
      background = 'bg-primary'
      break
    case WishareEntities.GIVING:
      background = 'bg-pink-500'
      break
    case WishareEntities.RECRUITMENT:
      background = 'bg-green-600'
      break
    case WishareEntities.EVENT:
      background = 'bg-indigo-600'
      break
    case WishareEntities.USER:
      background = 'bg-gray-600'
      break
    case WishareEntities.ORGANIZATION:
      background = 'bg-color-org'
      break
    case WishareEntities.STAFF:
      background = 'bg-green-700'
      break
    default:
      break
  }

  const label = title || type

  return (
    <div
      className={classNames(
        'absolute top-0 text-xs text-white font-medium pl-1 pr-5 py-1 border-radius-custom',
        background,
        className
      )}>
      {t(label)}
      {children}
    </div>
  )
}

const ItemAvatar = ({
  avatar,
  className,
  size,
  Wrapper = renderOwnChild,
  ...props
}) => {
  return (
    <Wrapper>
      <Avatar
        icon={
          avatar ? undefined : (
            <Icon
              component={
                AppPlaceHolderSvg
              }
            />
          )
        }
        size={size}
        src={avatar}
        className={classNames(
          'flex items-center justify-center border-3 border-white',
          className
        )}
        {...props}
      />
    </Wrapper>
  )
}

export const BulletConnector = ({
  header,
  children,
}) => (
  <React.Fragment>
    {header}
    <span>&nbsp;&#8226;&nbsp;</span>
    {children}
  </React.Fragment>
)

const ItemTemplate = ({
  item,
  image,
  children,
  renderTitle,
  renderContent,
  overlay = null,
  Header = Null,
  Footer = Null,
  Wrapper = Row,
  renderExtra = Null,
}) => {
  const title = getTitle(item)

  const avatar = getAvatar(item)

  const { isSm } = useContext(
    LayoutContext
  )

  const typeItem = _.get(item, '_type')
  const verified = _.get(
    item,
    'certified'
  )

  const Title = useCallback(
    () =>
      renderTitle ? (
        renderTitle(title)
      ) : (
        <Translate>
          {(t) => (
            <div className="flex items-center">
              <DefaultHyperLink
                pathname={getLinkToDetail(
                  item
                )}
                title={title}
              />
              {typeItem ===
                'organization' &&
                verified === 1 && (
                  <Tooltip
                    title={t(
                      'verified'
                    )}
                    placement="right"
                    color="#87d068">
                    <VerifiedSvg
                      width={15}
                      height={15}
                      className="ml-1 zoom-animation"
                    />
                  </Tooltip>
                )}
            </div>
          )}
        </Translate>
      ),
    [title, renderTitle, item]
  )

  const Content = useCallback(
    () => (
      <div className="my-1">
        {renderContent
          ? renderContent()
          : renderOwnChild({
              children,
            })}
      </div>
    ),
    [children, renderContent]
  )

  const Extra = useCallback(
    () => (
      <div className="italic text-2xs">
        {renderExtra(renderOwnChild)}
      </div>
    ),
    [renderExtra]
  )

  const ItemType = useCallback(
    () => (
      <div className="absolute bottom-0 right-0">
        <ItemAvatar
          avatar={avatar}
          size={isSm ? 40 : 45}
        />
      </div>
    ),
    [isSm, avatar]
  )

  const first = oneQuater

  const remain = Math.max(
    Math.min(
      fullGrid,
      fullGrid - first
    ),
    0
  )

  const _predictStatus = getPredictStatus(item);
  const _displayAsActive = ["donation_event", "giving_event", "event", "recruitment_event"].includes(_.get(item, '_type'));

  return (
    <Wrapper className={classNames("m-row-0 rounded-md background border border-color-50",
      _displayAsActive ? (_predictStatus === 1 ? "shadow-items hover:shadow-out" : "filter-gray") : "shadow-items hover:shadow-out"
    )}>
      <Col
        {...{
          xs: fullGrid,
          sm: fullGrid,
          md: first,
          lg: first,
        }}
        className="p-col-0 relative custom-image-layout">
        <DefaultImage
          src={image}
          type={getType(item)}
        />
        <ItemType />
        {overlay}
      </Col>
      <Col
        {...{
          xs: fullGrid,
          sm: fullGrid,
          md: remain,
          lg: remain,
        }}
        className="px-3 py-2 flex flex-col">
        <Header />
        <FlexCol flex={false}>
          <Title />
          <Extra />
          <Content />
        </FlexCol>
        <div className="flex-1" />
        <Footer />
      </Col>
    </Wrapper>
  )
}

export default ItemTemplate
