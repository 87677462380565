import React from "react";

function SilverMedalSvg({size = 30, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 510 510"
      {...props}
    >
      <linearGradient id="lgsm1">
        <stop offset="0" stopColor="#e6e6e6"></stop>
        <stop offset="1" stopColor="#999"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_1_SMSM"
        x1="99.436"
        x2="129.071"
        y1="359.221"
        y2="400.081"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient id="lgsm2">
        <stop offset="0" stopColor="#766e6e" stopOpacity="0"></stop>
        <stop offset="0.281" stopColor="#716b6b" stopOpacity="0.281"></stop>
        <stop offset="0.571" stopColor="#656060" stopOpacity="0.571"></stop>
        <stop offset="0.864" stopColor="#514f4f" stopOpacity="0.864"></stop>
        <stop offset="1" stopColor="#444"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000106117384099910324450000011954483193762692259_"
        x1="96.737"
        x2="143.435"
        y1="385.426"
        y2="406.979"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000093872931703599667490000004913749263473845120_"
        x1="69.038"
        x2="117.981"
        y1="324.391"
        y2="360.761"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000098923603466043249190000005850537196171782837_"
        x1="89.556"
        x2="126.675"
        y1="365.073"
        y2="364.474"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000090257570213997179380000009548169459381686933_"
        x1="101.597"
        x2="126.293"
        y1="253.129"
        y2="330.36"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000167389547998436286140000005432681601478308248_"
        x1="106.573"
        x2="138.304"
        y1="295.183"
        y2="322.723"
        gradientTransform="rotate(14.98 110.383 329.969)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000148653873839702253630000003384611716562897295_"
        x1="411.054"
        x2="440.689"
        y1="275.956"
        y2="316.817"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000035521792516443199300000002859346904319370892_"
        x1="408.354"
        x2="455.052"
        y1="302.161"
        y2="323.714"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000018923993593945811100000007195276701458229123_"
        x1="380.655"
        x2="429.599"
        y1="241.126"
        y2="277.497"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000015335375435260867450000017718706726052093332_"
        x1="401.174"
        x2="438.293"
        y1="281.808"
        y2="281.21"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000007397527401457560060000006237477112096524433_"
        x1="413.215"
        x2="437.911"
        y1="169.864"
        y2="247.096"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000116957923510894451780000012931000448620325772_"
        x1="418.191"
        x2="449.921"
        y1="211.918"
        y2="239.458"
        gradientTransform="matrix(-.966 .258 .258 .966 743.665 -17.26)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000049190632882418024940000002965078469890032541_"
        x1="156.954"
        x2="324.67"
        y1="252.928"
        y2="420.644"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000096741937596137281820000016567492876788548259_"
        x1="337.326"
        x2="170.272"
        y1="433.3"
        y2="266.246"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000071551650348487888440000007984681980809540751_"
        x1="175.891"
        x2="306.108"
        y1="271.865"
        y2="402.082"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000034791603291984282960000003585358463260497327_"
        x1="255"
        x2="255"
        y1="397.502"
        y2="501.882"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000064329440274721858160000006534610812334842804_"
        x1="350.243"
        x2="218.909"
        y1="433.62"
        y2="350.953"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient id="lgsm3">
        <stop offset="0" stopColor="#f9f5f3"></stop>
        <stop offset="1" stopColor="#d8e8f0"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000140715013176602608700000004979579777548936879_"
        x1="221.874"
        x2="281.61"
        y1="320.747"
        y2="380.482"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm3"
      ></linearGradient>
      <linearGradient id="lgsm4">
        <stop offset="0" stopColor="#678d98" stopOpacity="0"></stop>
        <stop offset="0.996" stopColor="#678d98" stopOpacity="0.996"></stop>
        <stop offset="1" stopColor="#678d98"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000045583747288102451190000007839412385901963921_"
        x1="290.793"
        x2="208.23"
        y1="337.034"
        y2="371.166"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000078731972814471500610000013323529924718958990_"
        x1="269.236"
        x2="224.034"
        y1="366.334"
        y2="346.961"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000022534742627065606280000013304457366751761032_"
        x1="-1840.06"
        x2="-1885.262"
        y1="366.334"
        y2="346.961"
        gradientTransform="matrix(-1 0 0 1 -1599.296 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000091007596641028015030000005312934507882855328_"
        x1="241.769"
        x2="270.827"
        y1="368.601"
        y2="321.9"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000101070193170969410690000011872178201643546274_"
        x1="266.003"
        x2="263.582"
        y1="362.703"
        y2="317.04"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000057866671631853403130000013663919983044946072_"
        x1="333.364"
        x2="272.031"
        y1="283.792"
        y2="176.459"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000103241337178406074570000001405838065142702744_"
        x1="262.112"
        x2="301.612"
        y1="56.676"
        y2="119.676"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm3"
      ></linearGradient>
      <linearGradient id="lgsm5">
        <stop offset="0" stopColor="#b3dafe"></stop>
        <stop offset="1" stopColor="#0182fc"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000114050721654267204640000000498034352217176961_"
        x1="222.713"
        x2="345.38"
        y1="-20.62"
        y2="120.046"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm5"
      ></linearGradient>
      <linearGradient id="lgsm6">
        <stop offset="0" stopColor="#0182fc" stopOpacity="0"></stop>
        <stop offset="0.126" stopColor="#017ff3" stopOpacity="0.126"></stop>
        <stop offset="0.331" stopColor="#0176db" stopOpacity="0.331"></stop>
        <stop offset="0.588" stopColor="#0268b4" stopOpacity="0.588"></stop>
        <stop offset="0.886" stopColor="#02547e" stopOpacity="0.886"></stop>
        <stop offset="1" stopColor="#024c67"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000086649767613330414420000018314814227278675636_"
        x1="255"
        x2="255"
        y1="66.5"
        y2="33.466"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000006700945551275403940000011155203967968805276_"
        x1="196.655"
        x2="319.321"
        y1="2.104"
        y2="142.77"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm5"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000011024021774783370180000005672621631333148559_"
        x1="292.995"
        x2="310.065"
        y1="107.005"
        y2="124.075"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000031910306967442238630000004320121023346760624_"
        x1="-2899.005"
        x2="-2881.935"
        y1="107.005"
        y2="124.075"
        gradientTransform="matrix(-1 0 0 1 -2682 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000053526874254061285040000002683979830084736426_"
        x1="341.686"
        x2="136.626"
        y1="177.612"
        y2="-27.448"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000070084115877871086110000007282957867659121596_"
        x1="255"
        x2="255"
        y1="81.667"
        y2="221.706"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000173153826565653731300000015848384411721539773_"
        x1="255"
        x2="255"
        y1="163"
        y2="209.044"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000135687221961528950190000011118706075624364723_"
        x1="302.267"
        x2="274.267"
        y1="136.929"
        y2="165.429"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000182523791960411538900000004305689208199172764_"
        x1="233.5"
        x2="286.53"
        y1="168.5"
        y2="221.53"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm3"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000111881332529554552320000005826266214458108549_"
        x1="270.25"
        x2="243.999"
        y1="205.25"
        y2="178.999"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm3"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000052080493230346043700000005339723004273544882_"
        x1="255"
        x2="255"
        y1="11.333"
        y2="32.171"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lgsm1"
      ></linearGradient>
      <path
        fill="url(#SVGID_1_SM)"
        d="M121.325 376.63c25.258 10.368 45.243 27.091 57.491 46.045 2.565 3.969-1.303 13.391-5.917 14.414-22.033 4.881-48.003 2.74-73.261-7.629s-45.243-27.091-57.491-46.045c-2.565-3.969 1.303-13.392 5.917-14.414 22.032-4.881 48.003-2.739 73.261 7.629z"
      ></path>
      <path
        fill="url(#SVGID_00000106117384099910324450000011954483193762692259_)"
        d="M121.325 376.63c25.258 10.368 45.243 27.091 57.491 46.045 2.565 3.969-1.303 13.391-5.917 14.414-22.033 4.881-48.003 2.74-73.261-7.629s-45.243-27.091-57.491-46.045c-2.565-3.969 1.303-13.392 5.917-14.414 22.032-4.881 48.003-2.739 73.261 7.629z"
      ></path>
      <path
        fill="url(#SVGID_00000093872931703599667490000004913749263473845120_)"
        d="M134.171 340.814c24.736 19.605 41.58 44.58 49.117 69.556 1.578 5.231-5.735 14.458-11.188 14.116-26.037-1.633-54.199-12.328-78.935-31.933s-41.58-44.58-49.117-69.556c-1.578-5.231 5.735-14.458 11.188-14.116 26.037 1.634 54.199 12.328 78.935 31.933z"
      ></path>
      <path
        fill="url(#SVGID_00000098923603466043249190000005850537196171782837_)"
        d="M134.171 340.814c24.736 19.605 41.58 44.58 49.117 69.556 1.578 5.231-5.735 14.458-11.188 14.116-26.037-1.633-54.199-12.328-78.935-31.933s-41.58-44.58-49.117-69.556c-1.578-5.231 5.735-14.458 11.188-14.116 26.037 1.634 54.199 12.328 78.935 31.933z"
      ></path>
      <path
        fill="url(#SVGID_00000090257570213997179380000009548169459381686933_)"
        d="M159.141 285.622c21.674 33.269 31.788 69.791 30.8 102.595-.207 6.87-12.618 14.955-18.985 12.369-30.406-12.351-59.727-36.36-81.401-69.629s-31.788-69.791-30.8-102.595c.207-6.87 12.618-14.955 18.985-12.369 30.406 12.351 59.727 36.36 81.401 69.629z"
      ></path>
      <path
        fill="url(#SVGID_00000167389547998436286140000005432681601478308248_)"
        d="M62.452 266.923c4.656 21.487 13.65 43.386 27.102 64.034 21.674 33.269 50.994 57.278 81.401 69.629 6.368 2.587 18.778-5.499 18.985-12.369.989-32.804-9.125-69.326-30.8-102.595-12.763-19.59-28.178-35.966-44.904-48.645z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000148653873839702253630000003384611716562897295_)"
          d="M388.675 376.63c-25.258 10.368-45.243 27.091-57.491 46.045-2.565 3.969 1.303 13.391 5.917 14.414 22.033 4.881 48.003 2.74 73.261-7.629s45.243-27.091 57.491-46.045c2.565-3.969-1.303-13.392-5.917-14.414-22.032-4.881-48.003-2.739-73.261 7.629z"
        ></path>
        <path
          fill="url(#SVGID_00000035521792516443199300000002859346904319370892_)"
          d="M388.675 376.63c-25.258 10.368-45.243 27.091-57.491 46.045-2.565 3.969 1.303 13.391 5.917 14.414 22.033 4.881 48.003 2.74 73.261-7.629s45.243-27.091 57.491-46.045c2.565-3.969-1.303-13.392-5.917-14.414-22.032-4.881-48.003-2.739-73.261 7.629z"
        ></path>
        <path
          fill="url(#SVGID_00000018923993593945811100000007195276701458229123_)"
          d="M375.829 340.814c-24.736 19.605-41.58 44.58-49.117 69.556-1.578 5.231 5.735 14.458 11.188 14.116 26.037-1.633 54.199-12.328 78.935-31.933s41.58-44.58 49.117-69.556c1.578-5.231-5.735-14.458-11.188-14.116-26.037 1.634-54.199 12.328-78.935 31.933z"
        ></path>
        <path
          fill="url(#SVGID_00000015335375435260867450000017718706726052093332_)"
          d="M375.829 340.814c-24.736 19.605-41.58 44.58-49.117 69.556-1.578 5.231 5.735 14.458 11.188 14.116 26.037-1.633 54.199-12.328 78.935-31.933s41.58-44.58 49.117-69.556c1.578-5.231-5.735-14.458-11.188-14.116-26.037 1.634-54.199 12.328-78.935 31.933z"
        ></path>
        <path
          fill="url(#SVGID_00000007397527401457560060000006237477112096524433_)"
          d="M350.859 285.622c-21.674 33.269-31.788 69.791-30.8 102.595.207 6.87 12.618 14.955 18.985 12.369 30.406-12.351 59.727-36.36 81.401-69.629s31.788-69.791 30.8-102.595c-.207-6.87-12.618-14.955-18.985-12.369-30.406 12.351-59.727 36.36-81.401 69.629z"
        ></path>
        <path
          fill="url(#SVGID_00000116957923510894451780000012931000448620325772_)"
          d="M447.548 266.923c-4.656 21.487-13.65 43.386-27.102 64.034-21.674 33.269-50.994 57.278-81.401 69.629-6.368 2.587-18.778-5.499-18.985-12.369-.989-32.804 9.125-69.326 30.8-102.595 12.763-19.59 28.178-35.966 44.904-48.645z"
        ></path>
      </g>
      <g>
        <circle
          cx="255"
          cy="350.974"
          r="159.026"
          fill="url(#SVGID_00000049190632882418024940000002965078469890032541_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="126.755"
          fill="url(#SVGID_00000096741937596137281820000016567492876788548259_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="108.82"
          fill="url(#SVGID_00000071551650348487888440000007984681980809540751_)"
        ></circle>
        <path
          fill="url(#SVGID_00000034791603291984282960000003585358463260497327_)"
          d="M95.974 350.974C95.974 438.802 167.172 510 255 510s159.026-71.198 159.026-159.026c0-.617-.016-1.23-.023-1.846H95.997c-.007.616-.023 1.229-.023 1.846z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000064329440274721858160000006534610812334842804_)"
        d="M299.601 503.65c50.109-14.614 89.983-53.219 106.356-102.552l-77.453-77.453-17.461.857-47.126-47.126-57.435 133.155z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000140715013176602608700000004979579777548936879_)"
          d="M320.208 316.419l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217-3.428.278-6.76 2.003-8.934 5.217l-17.978 26.575a11.977 11.977 0 01-7.109 4.932l-31.187 7.535c-8.46 2.044-11.985 12.141-6.637 19.007l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.866 1.823-16.963-6.637-19.007z"
        ></path>
        <path
          fill="url(#SVGID_00000045583747288102451190000007839412385901963921_)"
          d="M260.912 402.263l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.349-6.865 1.823-16.963-6.637-19.007l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217V401.7a11.955 11.955 0 015.912.563z"
        ></path>
        <path
          fill="url(#SVGID_00000078731972814471500610000013323529924718958990_)"
          d="M320.208 316.419l-31.187-7.535a11.956 11.956 0 01-3.02-1.185l-79.502 102.833c3.119 3.014 7.824 4.331 12.424 2.664l30.165-10.932a11.957 11.957 0 015.912-.563 11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.867 1.823-16.964-6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000022534742627065606280000013304457366751761032_)"
          d="M189.792 316.419l31.187-7.535a11.956 11.956 0 003.02-1.185l79.502 102.833c-3.119 3.014-7.824 4.331-12.424 2.664l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989c.23-2.976-.66-5.93-2.494-8.285l-19.719-25.31c-5.348-6.867-1.823-16.964 6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000091007596641028015030000005312934507882855328_)"
          d="M181.491 323.646c-1.483 3.74-1.127 8.197 1.664 11.78l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989a11.954 11.954 0 01.633-4.831z"
        ></path>
        <path
          fill="url(#SVGID_00000101070193170969410690000011872178201643546274_)"
          d="M328.521 323.646c1.483 3.74 1.128 8.197-1.664 11.78l-19.719 25.31a11.981 11.981 0 00-2.494 8.285l2.471 31.989c.67 8.677-7.844 15.151-16.026 12.185l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989a11.954 11.954 0 00-.633-4.831z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000057866671631853403130000013663919983044946072_)"
        d="M255 191.948c-.66 0-1.315.017-1.973.025l-24.542 24.542 179.161 179.161a159.079 159.079 0 006.38-44.703c0-87.827-71.198-159.025-159.026-159.025z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000103241337178406074570000001405838065142702744_)"
          d="M159 3.333v84l96 96 96-96v-84z"
        ></path>
        <path
          fill="url(#SVGID_00000114050721654267204640000000498034352217176961_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000086649767613330414420000018314814227278675636_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000006700945551275403940000011155203967968805276_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000011024021774783370180000005672621631333148559_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000031910306967442238630000004320121023346760624_)"
          d="M255 140.28c3.958 0 7.915-1.506 10.927-4.519L351 50.688v36.646l-96 96-96-96V50.688l85.073 85.073A15.407 15.407 0 00255 140.28z"
        ></path>
        <g>
          <path
            fill="url(#SVGID_00000053526874254061285040000002683979830084736426_)"
            d="M244.473 161.527l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-6.126 6.126-6.126 16.059 0 22.185L255 216.426l114.407-114.407c6.126-6.126 6.126-16.059 0-22.185-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.815 5.814-15.241 5.814-21.055-.001z"
          ></path>
          <path
            fill="url(#SVGID_00000070084115877871086110000007282957867659121596_)"
            d="M140.593 80.019L242.954 182.38c6.653 6.653 17.44 6.653 24.093 0l102.36-102.361c.03-.03.054-.063.084-.093-.029-.03-.054-.063-.084-.093-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.814 5.814-15.241 5.814-21.055 0l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-.03.03-.054.063-.084.093.029.03.053.063.083.093z"
          ></path>
          <path
            fill="url(#SVGID_00000173153826565653731300000015848384411721539773_)"
            d="M255 216.426l80.759-80.759h-44.371l-25.861 25.861c-5.814 5.814-15.241 5.814-21.055 0l-25.861-25.861H174.24z"
          ></path>
          <path
            fill="url(#SVGID_00000135687221961528950190000011118706075624364723_)"
            d="M245 206.426l10 10L357.426 114h-44.371l-47.527 47.527c-5.645 5.645-14.684 5.792-20.528.477z"
          ></path>
        </g>
        <g>
          <circle
            cx="255"
            cy="190"
            r="37.498"
            fill="url(#SVGID_00000182523791960411538900000004305689208199172764_)"
          ></circle>
          <circle
            cx="255"
            cy="190"
            r="21.498"
            fill="url(#SVGID_00000111881332529554552320000005826266214458108549_)"
          ></circle>
        </g>
        <path
          fill="url(#SVGID_00000052080493230346043700000005339723004273544882_)"
          d="M365.502 37.333H144.498c-6.35 0-11.498-5.148-11.498-11.498V11.498C133 5.148 138.148 0 144.498 0h221.005C371.852 0 377 5.148 377 11.498v14.338c0 6.35-5.148 11.497-11.498 11.497z"
        ></path>
      </g>
    </svg>
  );
}

export default SilverMedalSvg;
