import Icon from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Row,
} from 'antd'
import { FollowButton } from 'components/Feed/FollowButton'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import { ROOT_URL } from 'envs/_current/config'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { IoShareSocialOutline } from 'react-icons/io5'
import CategoryContext from '../CategoryContext'
import categoryIcons from "../../../../conts/categoryIcons";

const CategoryHeader = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const { category: _currentCategory } =
    useContext(CategoryContext)
  // bỏ field follow_status; lấy từ thông tin user.
  const { follow_status, ...rest } =
    _currentCategory || {}
  const currentCategory = { ...rest }

  const login = useContext(LoginContext)

  const my_categories =
    _.get(login, 'categories') || []

  const merged = _.merge(
    _.keyBy([currentCategory], 'id'),
    _.keyBy(my_categories, 'id')
  )
  const last_categories =
    _.values(merged)

  const category = _.first(
    last_categories.filter(
      (e) =>
        !!currentCategory &&
        e.id === currentCategory.id
    )
  )

  const description = _.get(
    category,
    'description'
  )
  const total_followers = _.get(
    category,
    'total_followers'
  )
  const total_objects = _.get(
    category,
    'total_objects'
  )

  const ItemInfo = ({
    label,
    value,
  }) => (
    <div className="flex items-center gap-2">
        <span className="font-bold text-sm text-color-000">
            {value}
        </span>
        <span className="italic text-xs text-color-400 font-light">
            {t(label)}
        </span>
    </div>
  )

  if (!category) return null

  const IconCategory = _.get(categoryIcons, category.idname)

  return (
    <Wrapper className="flex flex-col mb-6">
      <Row className="m-row-0 gap-3">
        <Col
          span={6}
          className="p-col-0">
          {
            IconCategory ?
                <div className="w-full h-full">
                  <IconCategory />
                </div> :
                <Avatar
                    shape="square"
                    className="rounded-lg w-full h-full"
                    src={_.get(category, 'avatar')}
                />
          }
        </Col>
        <Col
          span={18}
          className="relative space-y-2 flex-1">
          <div className="text-base md:text-xl uppercase font-bold text-primary tracking-wide leading-tight">
            {_.get(category, 'name')}
          </div>
          {!!description && (
            <div className="text-color-300 text-sm font-light italic max-lines-2 leading-5">
              {description}
            </div>
          )}
          <div className="flex flex-col">
            {!!total_followers && (
              <ItemInfo
                label="followers"
                value={total_followers}
              />
            )}
            {!!total_objects && (
              <ItemInfo
                label="link"
                value={total_objects}
              />
            )}
          </div>
        </Col>
      </Row>
      <div className="space-x-3 flex justify-end">
        <FollowButton
            size={'small'}
            item={category}
        />
        <Button
            className="rounded-md border-none"
            size={'small'}
            onClick={() => {
              if (navigator.share) {
                navigator
                    .share({
                      title:
                      category.name,
                      url: `${ROOT_URL}${getLinkToDetail(
                          category
                      )}`,
                    })
                    .then(() =>
                        console.log(
                            'Successful share'
                        )
                    )
                    .catch((error) =>
                        console.log(
                            'Error sharing',
                            error
                        )
                    )
              } else {
                alert(
                    `your device don't support this!`
                )
              }
            }}>
          <div className="flex items-center gap-1 hover:text-primary">
            <IoShareSocialOutline />
            <div className="font-medium text-xs text-color-200 hover:text-primary">
              {t('share')}
            </div>
          </div>
        </Button>
      </div>
    </Wrapper>
  )
}

export default CategoryHeader
