import {createBlockRendererFn, createInsertAtomicBlock} from '../../utils'
import {type} from './constains'
import VideoComponent from './Video'

export default function createVideoPlugin({
                                            entityType = type,
                                            component = VideoComponent,
                                            decorator
                                          }) {
  let Video = component
  if (decorator) {
    Video = decorator(Video)
  }
  const handleInsert = createInsertAtomicBlock(
    entityType
  )
  return {
    blockRendererFn: createBlockRendererFn(
      entityType,
      Video
    ),
    handleInsert
  }
}
