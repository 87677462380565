import React from "react";

function DiamondMedalSvg({size = 30, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <g fill="#FFD15C">
        <path d="M510.933 58.667L458.667 50.133 450.133 0 442.667 51.2 393.6 58.667 442.667 66.133 450.133 117.333 458.667 67.2z"></path>
        <path d="M88.533 0L86.4 20.267 65.067 23.467 85.333 26.667 88.533 46.933 92.8 26.667 113.067 23.467 91.733 20.267z"></path>
      </g>
      <path
        fill="#CDD6E0"
        d="M59.733 65.067L54.4 28.8 49.067 65.067 13.867 70.4 49.067 76.8 54.4 113.067 59.733 76.8 97.067 70.4z"
      ></path>
      <path
        fill="#84DBFF"
        d="M336 215.467L270.933 91.733 244.267 91.733 171.733 215.467z"
      ></path>
      <path fill="#54C0EB" d="M419.2 91.733L270.933 91.733 336 215.467z"></path>
      <path
        fill="#84DBFF"
        d="M508.8 215.467L420.267 91.733 419.2 91.733 336 215.467z"
      ></path>
      <path fill="#F2F2F2" d="M254.933 512L336 215.467 171.733 215.467z"></path>
      <path
        fill="#54C0EB"
        d="M244.267 91.733L90.667 91.733 171.733 215.467z"
      ></path>
      <path
        fill="#84DBFF"
        d="M90.667 91.733L89.6 91.733 1.067 215.467 171.733 215.467z"
      ></path>
      <path
        fill="#54C0EB"
        d="M508.8 215.467L254.933 512 1.067 215.467 171.733 215.467 254.933 512 336 215.467z"
      ></path>
      <g fill="#FFF">
        <path d="M336 182.4L331.733 210.133 302.933 214.4 331.733 218.667 336 248.533 340.267 218.667 369.067 214.4 340.267 210.133z"></path>
        <path d="M126.933 120.533L122.667 148.267 93.867 152.533 121.6 156.8 126.933 186.667 131.2 157.867 160 152.533 131.2 148.267z"></path>
      </g>
    </svg>
  );
}

export default DiamondMedalSvg;
