import {
  Avatar,
  Button,
  Input,
  Modal,
  Spin,
  Steps,
} from 'antd'
import {
  staffRosterSchema,
  time_sheet_staffRoster_addTimesheet_Api,
  time_sheet_staffRoster_getTimeSheets_Api,
  time_sheet_staff_deleteTimesheet_Api,
  time_sheet_staff_editTimesheet_Api,
  time_sheet_staff_getById_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import FieldsFactory from 'components/form/FieldsFactory'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import { displayMomentValues } from 'helpers/momentDatetime'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {
  compose,
  mapProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import LoadingPage from 'views/LoadingPage'
import {
  TimesheetPeriodOptions,
  UnitInputGroup,
} from 'views/MemberSetting/components/ManageTimeSheetModal'
import {
  getFullAddress,
  getLocationLines,
  getResponseItem,
  getResponseItems,
  Null,
  renderElse,
  renderOwnChild,
  shallowDiff,
} from 'views/Shared'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import { TimesheetsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { renderTimesheetsItem } from './renderTimesheetsItem'

export const TimesheetsModalTypes =
  Object.freeze({
    EDIT: 'edit',
    CREATE: 'create',
    DELETE: 'delete',
  })

const newTimesheets = {
  notes: undefined,
  to_date: undefined,
  from_date: undefined,
  duration: undefined,
  duration_unit_name:
    TimesheetPeriodOptions.D.value,
}

const renderDates = ([
  from_date,
  to_date,
]) => {
  const dates = displayMomentValues([
    from_date,
    to_date,
  ])
  return renderElse(
    _.every(
      [from_date, to_date],
      _.isEmpty
    ),
    <Translate>
      {(t) => (
        <div className="flex items-baseline gap-2 text-sm">
          <span className="text-xs text-color-200 italic whitespace-no-wrap">
            {`${t('from date')}:`}
          </span>
          <span className="text-color-000 font-medium">
            {to_date
              ? dates.join(
                  ` ${t('to')} `
                )
              : _.first(dates)}
          </span>
        </div>
      )}
    </Translate>
  )
}

const StaffInformation = ({ item }) => {
  const t = useTranslate()

  const ItemInformation = ({
    label,
    value,
  }) => (
    <div className="flex items-baseline gap-2 text-xs italic">
      <span className="text-color-400 font-light whitespace-no-wrap">
        {t(label)}
        {':'}
      </span>
      <span className="text-color-000 font-medium">
        {value}
      </span>
    </div>
  )

  if (_.isEmpty(item)) {
    return null
  }

  const {
    eff_date,
    end_date,
    workplace,
    staff = {},
    position_title,
  } = item || {}

  const full_address =
    getLocationLines(item)

  const dates = displayMomentValues([
    eff_date,
    end_date,
  ])

  return (
    <div className="flex flex-col">
      <div className="uppercase font-medium text-color-300 mb-1">
        {t('staff information')}
      </div>
      <div className="p-3 flex flex-col border border-color-50 rounded-lg background">
        <div className="flex items-center gap-2 mb-3">
          <Avatar
            size={40}
            src={
              getAvatar(staff.owner) ||
              getAvatar(staff)
            }
            className="background-200 text-color-200">
            {_.first(getTitle(staff))}
          </Avatar>
          <div className="flex flex-col flex-1">
            <span className="font-semibold text-primary leading-tight">
              {getTitle(staff)}
            </span>
            <span className="italic text-xs font-light text-secondary">
              {t(
                _.get(
                  staff,
                  'employee_type'
                )
              )}
            </span>
          </div>
        </div>
        <div className="space-y-1">
          <ItemInformation
            label={'workplace'}
            value={getTitle(workplace)}
          />
          <ItemInformation
            label={'position_title'}
            value={position_title}
          />
          {_.some(
            dates,
            (value) => !!value
          ) && (
            <ItemInformation
              label={'from date'}
              value={dates.join(
                ` ${t('to')} `
              )}
            />
          )}
          <ItemInformation
            label={'address'}
            value={getFullAddress(item)}
          />
        </div>
      </div>
    </div>
  )
}

const formSchema = [
  {
    title: 'from date',
    children: [
      {
        name: 'from_date',
        component:
          createDateRangerPicker([
            'from_date',
            'to_date',
          ]),
      },
    ],
  },
  {
    title: 'duration',
    children: [
      {
        name: 'duration',
        component: compose(
          mapProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => ({
              name,
              value,
              onChange,
              subName:
                'duration_unit_name',
              subValue: _.get(
                form,
                'values.duration_unit_name'
              ),
              options: Object.values(
                TimesheetPeriodOptions
              ),
            })
          )
        )(UnitInputGroup),
      },
    ],
  },
  {
    title: 'notes',
    children: {
      name: 'notes',
      component:
        createControlledFormField({
          placeholder: 'notes',
          Component: Input.TextArea,
        }),
    },
  },
]

const ListFilter = ({
  sortBy,
  onSearch = Null,
  setSortBy = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()
  return (
    <Wrapper>
      <div className="flex flex-1 space-x-3">
        <Input.Search
          className="input-search"
          placeholder={t('search')}
          onSearch={(value) =>
            onSearch(value)
          }
        />
      </div>
      <div className="flex flex-col">
        <div className="flex items-center justify-between border-b border-color-50 pb-2">
          <span className="uppercase text-sm font-semibold text-color-200">
            {t('timesheets')}
          </span>
          <div className="flex items-end justify-end">
            <TimesheetsSortBy
              value={sortBy}
              onSelect={(value) =>
                setSortBy(value)
              }
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const ActionHeader = ({
  title,
  extra,
}) => (
  <Translate>
    {(t) => (
      <div className="flex items-center uppercase">
        {title && <div>{title}</div>}
        {extra && (
          <div className="flex flex-1 justify-end">
            {extra}
          </div>
        )}
      </div>
    )}
  </Translate>
)

const CreateTimesheetsModal = ({
  staff_id,
  staff_roster_id,
  onCancel,
  Header = Null,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'create',
  initialValues = newTimesheets,
}) => {
  const t = useTranslate()

  const {
    isLoading = false,
    handleAsyncAction: createTimesheets,
  } = useAsyncAction({
    apiInfo:
      time_sheet_staffRoster_addTimesheet_Api,
    query: bindQueryParam({
      staff_roster_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    let errors = {}

    const { from_date, duration } =
      values || {}

    if (_.isEmpty(from_date)) {
      errors.from_date = t(
        'required field'
      )
    }

    if (_.isEmpty(duration)) {
      errors.duration = t(
        'required field'
      )
    }

    return errors
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={({
        duration_unit_name,
        ...values
      }) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(createTimesheets)({
          ...changed,
          duration_unit_name,
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('create timesheets')}
          </div>
          <Header />
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditTimesheetsModal = ({
  staff_id,
  onCancel,
  Header = Null,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'save',
  initialValues = newTimesheets,
}) => {
  const t = useTranslate()

  const {
    isLoading = false,
    handleAsyncAction: updateTimesheets,
  } = useAsyncAction({
    apiInfo:
      time_sheet_staff_editTimesheet_Api,
    query: bindQueryParam({
      id: getId(initialValues),
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    let errors = {}

    const { from_date, duration } =
      values || {}

    if (_.isEmpty(from_date)) {
      errors.from_date = t(
        'required field'
      )
    }

    if (_.isEmpty(duration)) {
      errors.duration = t(
        'required field'
      )
    }

    return errors
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={({
        duration_unit_name,
        ...values
      }) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(updateTimesheets)({
          ...changed,
          duration_unit_name,
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit timesheets')}
          </div>
          <Header />
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const TimesheetsActionModal = ({
  type,
  params,
  onCancel,
  onCreated,
  onUpdated,
  initialValues,
  isLoading = false,
  ...props
}) => {
  let Component = Null

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case TimesheetsModalTypes.CREATE:
      Component = CreateTimesheetsModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case TimesheetsModalTypes.EDIT:
      Component = EditTimesheetsModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    default:
      break
  }

  const { staff_id, staff_roster_id } =
    params || {}

  const onSubmit =
    (handleSubmit = Null) =>
    ({ id, ...values }) => {
      handleSubmit(
        _.omitBy(
          {
            ..._.pick(
              values,
              Object.keys(newTimesheets)
            ),
            staff_roster_id,
          },
          _.isUndefined
        )
      )
    }

  return (
    <Modal
      className="custom-modal"
      {...{
        onCancel,
        right: true,
        footer: null,
        closable: false,
        visible: !!type,
        destroyOnClose: true,
      }}>
      {!!isLoading ? (
        <LoadingPage />
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            staff_id,
            staff_roster_id,
            initialValues,
          }}
          {...asyncParams}
        />
      )}
    </Modal>
  )
}

const TimesheetsManagementModal = ({
  staff_id,
  onSuccess = Null,
  Wrapper = 'div',
  initialValues,
}) => {
  const t = useTranslate()

  const staff_roster =
    useSelectEntities(
      getId(initialValues),
      staffRosterSchema,
      initialValues
    )

  const [
    refreshToken,
    setRefreshToken,
  ] = useState()

  const [sortBy, setSortBy] = useState()

  const [keyword, setKeyword] =
    useState()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const refreshKey = useMemo(
    () =>
      [
        staff_id,
        sortBy,
        keyword,
        `refreshToken=${refreshToken}`,
      ].join('/'),
    [
      staff_id,
      sortBy,
      keyword,
      refreshToken,
    ]
  )

  const onCancel = () => {
    setAction({
      type: undefined,
    })
  }

  const refresh = () => {
    onCancel()
    setRefreshToken(Date.now())
  }

  const { id: staff_roster_id, staff } =
    staff_roster || {}

  const { avatar, employee_type } =
    staff || {}

  const {
    handleAsyncAction: deleteTimesheets,
  } = useAsyncAction({
    onError: notifyOnError(t),
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      refresh()
    },
    apiInfo:
      time_sheet_staff_deleteTimesheet_Api,
  })

  const onMenuSelect = (
    key,
    timesheetsItem
  ) => {
    const id = getId(timesheetsItem)
    switch (key) {
      case TimesheetsModalTypes.CREATE:
      case TimesheetsModalTypes.EDIT:
        setAction({
          type: key,
          value: id,
        })
        break
      case TimesheetsModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteTimesheets(
                {},
                bindQueryParam({
                  id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const renderContextMenu = useCallback(
    (timesheets) => (
      <div className="absolute right-0">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onMenuSelect(
              key,
              timesheets
            )
          }
          items={[
            {
              key: TimesheetsModalTypes.EDIT,
              label: 'edit',
              icon: (
                <IoCreateOutline
                  size={16}
                  className="text-color-300"
                />
              ),
            },
            {
              key: TimesheetsModalTypes.DELETE,
              label: 'delete',
              icon: (
                <IoTrashOutline
                  size={16}
                  className="text-red-500"
                />
              ),
            },
          ]}
        />
      </div>
    ),
    []
  )

  const RenderWrapper = useCallback(
    ({ children }) => {
      switch (action.type) {
        case TimesheetsModalTypes.EDIT:
          return (
            <Async
              {...{
                query: bindQueryParam({
                  id: action.value,
                }),
                apiInfo:
                  time_sheet_staff_getById_Api,
              }}>
              {({
                response,
                isLoading,
              }) =>
                _.isFunction(children)
                  ? children({
                      isLoading,
                      initialValues:
                        getResponseItem(
                          response
                        ),
                    })
                  : children
              }
            </Async>
          )
        case TimesheetsModalTypes.CREATE:
        default:
          return children({
            isLoading: false,
          })
      }
    },
    [action]
  )

  if (_.isEmpty(staff_id)) {
    return null
  }

  return (
    <Wrapper className="flex flex-col space-y-3">
      <StaffInformation
        item={staff_roster}
      />
      <ActionHeader
        title={
          <div className="uppercase font-medium text-color-400">
            <span>
              {t('timesheets')}
            </span>
          </div>
        }
        extra={
          <Button
            type="primary"
            className="no-border rounded-lg no-shadow no-text-shadow"
            onClick={() => {
              setAction({
                type: TimesheetsModalTypes.CREATE,
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      <TimesheetsSortBy
        value={sortBy}
        onSelect={(value) =>
          setSortBy(value)
        }
      />

      {/*<ListFilter*/}
      {/*  sortBy={sortBy}*/}
      {/*  setSortBy={setSortBy}*/}
      {/*  onSearch={setKeyword}*/}
      {/*/>*/}

      <Async
        key={refreshKey}
        {...{
          apiInfo:
            time_sheet_staffRoster_getTimeSheets_Api,
          query: bindQueryParam({
            staff_roster_id,
          }),
          values: {
            keyword,
            sort_by: sortBy,
          },
        }}>
        {({
          response,
          isLoading = false,
        }) => {
          if (!!isLoading) {
            return <LoadingPage />
          }

          const steps = Array.from(
            getResponseItems(response)
          ).map((item, index) => {
            const {
              from_date,
              to_date,
            } = item || {}
            return {
              status: 'finish',
              title: (
                <div className="flex items-center justify-between">
                  {renderDates([
                    from_date,
                    to_date,
                  ])}
                  {renderContextMenu(
                    item
                  )}
                </div>
              ),
              description:
                renderTimesheetsItem(
                  item,
                  index
                ),
            }
          })

          if (_.isEmpty(steps)) {
            return <EmptyHolder />
          }

          return (
            <Steps
              progressDot={true}
              direction="vertical">
              {steps.map(
                (step, index) => (
                  <Steps.Step
                    key={index}
                    {...step}
                  />
                )
              )}
            </Steps>
          )
        }}
      </Async>

      {/* <Pure
        input={[
          sortBy,
          keyword,
          refreshKey,
          staff_id,
        ]}>
        <EntityList
          refreshKey={refreshKey}
          query={bindQueryParam({
            staff_id,
          })}
          apiInfo={apiInfo}
          values={{
            keyword,
            sort_by: sortBy,
          }}
          renderItem={renderTimesheetsItem(
            renderContextMenu
          )}
          RenderEntity={React.Fragment}
        />
      </Pure> */}

      {action.type && (
        <RenderWrapper>
          {({
            initialValues,
            isLoading = false,
          }) => (
            <TimesheetsActionModal
              type={action.type}
              onCancel={onCancel}
              params={{
                staff_id,
                staff_roster_id,
              }}
              Header={() => (
                <StaffInformation
                  item={staff_roster}
                />
              )}
              onCreated={refresh}
              onUpdated={refresh}
              isLoading={isLoading}
              initialValues={
                initialValues
              }
            />
          )}
        </RenderWrapper>
      )}
    </Wrapper>
  )
}

export default TimesheetsManagementModal
