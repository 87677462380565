import _ from 'lodash'
import React from 'react'
import { renderSelf } from 'views/Shared'
import { MaterialTypes } from '../enums'
import { showNotification } from '../messages'

export const NotificationActionTypes =
  Object.freeze({
    CREATE: {
      name: 'create',
      label: 'create',
      message: 'action completed',
      description:
        'successfully created!',
      withParams: (values) => values,
    },
    UPDATE: {
      name: 'update',
      label: 'update',
      message: 'action completed',
      description:
        'successfully updated!',
      withParams: (values) => values,
    },
    DELETE: {
      name: 'delete',
      label: 'delete',
      message: 'action completed',
      description:
        'successfully deleted!',
      withParams: (values) => values,
    },
    ERROR: {
      name: 'error',
      label: 'error',
      message: 'errors',
      description:
        'an error has beeen occurred!',
      withParams: ({
        message,
        errorType,
        ...values
      }) => ({
        ...values,
        message: errorType
          ? `error on: ${errorType}`
          : message,
      }),
    },
    WARNING: {
      name: 'warning',
      label: 'warning',
      message: 'action completed',
      description: undefined,
      withParams: ({
        description,
        ...values
      }) => ({
        ...values,
        description,
      }),
    },
    INFORMATION: {
      name: 'information',
      label: 'information',
      message: 'action completed',
      description: undefined,
      withParams: ({
        description,
        ...values
      }) => ({
        ...values,
        description,
      }),
    },
  })

export const createNotification = ({
  params = {},
  translate = renderSelf,
  type = MaterialTypes.SUCCESS,
  action = NotificationActionTypes.CREATE,
  ...props
}) => {
  if (action) {
    const {
      withParams = renderSelf,
      ...rest
    } = action
    const { message, description } =
      withParams({
        ...rest,
        ...params,
      }) || {}
    showNotification({
      type,
      translate,
      message: message ? (
        <div className="uppercase">
          {translate(message)}
        </div>
      ) : undefined,
      description: description ? (
        <div className="capitalize">
          {_.isString(description)
            ? translate(description)
            : description}
        </div>
      ) : undefined,
      ...props,
    })
  }
}

export const successNotify = (
  action = NotificationActionTypes.CREATE,
  translate = renderSelf,
  params = {}
) =>
  createNotification({
    action,
    params,
    translate,
    type: MaterialTypes.SUCCESS,
  })

export const infomationNotify = (
  action = NotificationActionTypes.INFORMATION,
  translate = renderSelf,
  params = {}
) =>
  createNotification({
    action,
    params,
    translate,
    type: MaterialTypes.INFO,
  })

export const warningNotify = (
  action = NotificationActionTypes.WARNING,
  translate = renderSelf,
  params = {}
) =>
  createNotification({
    action,
    params,
    translate,
    type: MaterialTypes.WARNING,
  })

export const errorNotify = (
  translate = renderSelf,
  params = {
    errorType: undefined,
  }
) =>
  createNotification({
    params,
    translate,
    type: MaterialTypes.ERROR,
    action:
      NotificationActionTypes.ERROR,
  })
