import React, {useTransition} from 'react'
import _ from 'lodash'
import useTranslate from "../../../../modules/local/useTranslate";
import {getFullAddress} from "../../../Shared";
import {Avatar} from "antd";
import axios from "axios";

const ItemInformation = ({label, value}) => {
    const t = useTranslate()
    return (
        <div className="flex items-baseline gap-2 text-xs italic">
            <span className="text-color-500 font-light whitespace-no-wrap">
                {t(label)}{':'}
            </span>
            <span className="text-sm text-color-000 font-medium">
                {value}
            </span>
        </div>
    )
}

export const renderProfilesStaffRosters =
    ({
      staff_id,
      owner_id,
      owner_type,
    } = {}) =>
    (item) => {

        const t = useTranslate()
        const eff_date = _.get(item, 'eff_date')
        const end_date = _.get(item, 'end_date')
        const time_work = _.get(item, 'timesheet_total', [])

        return (
            <div className="p-3 border border-color-50 rounded-lg space-y-1 relative">
                {_.get(item, 'medal') && (
                    <div className="absolute top-0 right-0 p-2">
                        <Avatar
                            size={25}
                            src={_.get(item, 'medal.avatar')}
                        />
                    </div>
                )}
                <div className="font-semibold text-green-600 mb-1">
                    {_.get(item, 'position_title')}
                </div>
              {
                [
                  {label: 'from date', value: eff_date && end_date ? `${eff_date} - ${end_date}` : eff_date || end_date,
                    hidden: !!!(eff_date && end_date ? `${eff_date} - ${end_date}` : eff_date || end_date) },
                  {label: 'project', value: <div className="flex gap-1">
                      <span>{t(_.get(item, 'workplace._type'))}</span>
                      <span>{_.get(item, 'workplace.title')}</span>
                    </div>, hidden: !_.get(item, 'workplace')},
                  {label: 'address', value: getFullAddress(item), hidden: !getFullAddress(item)},
                  {label: 'total time work', value: time_work.map((e,i) => (
                      <span key={i}>
                                    {_.get(e, 'total_values')}{' '}{t(_.get(e, 'time_type'))}{' '}
                                </span>
                    )), hidden: !time_work.length
                  },
                ].map((e,i) => !e.hidden && (
                  <ItemInformation key={i} label={e.label} value={e.value}/>
                ))
              }
            </div>
        )
    }
