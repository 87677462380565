import { donor_getById_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import React, {
  useCallback,
} from 'react'
import {
  getResponseItem,
  LoadingWrapper,
} from 'views/Shared'
import { wisharePaths } from '../configs'
import {
  bindQueryParam,
  mapPathParam,
} from '../functions/routerHelper'
import { PermissionEntity } from '../Permissions/PermissionEntity'
import DonorEntity from './DonorEntity'

const DonorContext =
  React.createContext({})

export const DonorProvider = ({
  item,
  children,
  routeEntities = [],
  routePathField = 'path',
  ...props
}) => {
  const id = getId(item) || item

  const apiInfo = donor_getById_api

  const cacheId = mapPathParam(
    apiInfo.path,
    { id }
  )

  const { response, isLoading } =
    useAsyncWithCache({
      cacheId,
      apiInfo,
      query: bindQueryParam({
        id,
      }),
    })

  const isSucceed =
    Number(
      _.get(response, 'status')
    ) === 200

  const cache =
    getResponseItem(response)

  const error = getResponseItem(
    response,
    'error'
  )

  const errorMessage = _.get(
    response,
    'errorMessages.error'
  )

  const error_code = _.get(
    response,
    'errorMessages.error_code'
  )

  const status = {
    title: getResponseItem(
      response,
      'status'
    ),
    description: errorMessage,
  }

  const defaultRoute = _.first(
    routeEntities
  )

  const getDefaultAction = (
    current
  ) => {
    switch (current) {
      case wisharePaths.donor:
        return _.get(
          defaultRoute,
          routePathField
        )
      default:
        return null
    }
  }

  const isValidRoute = useCallback(
    (route) => {
      return routeEntities
        .map((item) =>
          _.get(item, routePathField)
        )
        .includes(route)
    },
    [routeEntities, routePathField]
  )

  const renderHeader = useCallback(
    (action, isModal) => null,
    []
  )

  return (
    <LoadingWrapper isLoading={!item}>
      <DonorEntity
        key={cacheId}
        item={id}>
        {(donor) => (
          <PermissionEntity
            item={donor}>
            <DonorContext.Provider
              value={{
                error,
                cache,
                donor,
                status,
                isSucceed,
                isLoading,
                error_code,
                renderHeader,
                errorMessage,
                defaultRoute,
                routeEntities,
                routePathField,
                isValidRoute,
                getDefaultAction,
                ...props,
              }}>
              {children}
            </DonorContext.Provider>
          </PermissionEntity>
        )}
      </DonorEntity>
    </LoadingWrapper>
  )
}

export default DonorContext
