import { Menu, Space } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import DrawMenu from 'components/drawer/DrawMenu'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import {
  Null,
  renderElse,
  renderIf,
} from 'views/Shared'
import CustomButton from 'views/Wishare/custom/CustomButton'
import HostContext, {
  HostActionTypes,
} from '../HostContext'
import renderHostItem from './renderHostItem'
import renderHostRequest from './renderHostRequest'

const RejectedItem = ({
  owner,
  host_id,
}) => {
  const t = useTranslate()
  const { handleAction } = useContext(
    HostContext
  )

  const [id, prop] = [
    getId(owner),
    getType(owner),
  ]

  return renderIf(
    _.isFunction(handleAction),
    <Space direction="horizontal">
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleAction(
            HostActionTypes.RESEND,
            { id, prop, host_id }
          )
        }}
        className="GhostPrimary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('resend')}
        </span>
      </CustomButton>
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleAction(
            HostActionTypes.CANCEL,
            {
              id,
              prop,
              host_id,
            }
          )
        }}
        className="GhostSecondary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('cancel')}
        </span>
      </CustomButton>
    </Space>
  )
}

const PendingItem = ({
  owner,
  status,
  host_id,
}) => {
  const t = useTranslate()

  const { handleAction } = useContext(
    HostContext
  )

  const [id, prop] = [
    getId(owner),
    getType(owner),
  ]

  return renderIf(
    _.isFunction(handleAction),
    <Space direction="horizontal">
      {Number(status) === -9 && (
        <CustomButton
          size={'small'}
          ghost={true}
          onClick={() => {
            handleAction(
              HostActionTypes.RESEND,
              { id, prop, host_id }
            )
          }}
          className="GhostPrimary no-text-shadow no-shadow">
          <span className="leading-tight text-xs">
            {t('resend')}
          </span>
        </CustomButton>
      )}
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleAction(
            HostActionTypes.CANCEL,
            {
              id,
              prop,
              host_id,
            }
          )
        }}
        className="GhostSecondary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('cancel')}
        </span>
      </CustomButton>
    </Space>
  )
}

const OwnerPendingItem = ({
  owner,
  status,
  host_id,
}) => {
  const t = useTranslate()

  const { handleAction } = useContext(
    HostContext
  )

  return renderIf(
    _.isFunction(handleAction),
    <Space direction="horizontal">
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleAction(
            HostActionTypes.APPROVE,
            {
              host_id,
            }
          )
        }}
        className="GhostPrimary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">
          {t('approve')}
        </span>
      </CustomButton>
      <CustomButton
        size={'small'}
        ghost={true}
        onClick={() => {
          handleAction(
            HostActionTypes.DECLINE,
            {
              host_id,
            }
          )
        }}
        className="GhostSecondary no-text-shadow no-shadow">
        <span className="leading-tight text-xs">{t('reject')}</span>
      </CustomButton>
    </Space>
  )
}

const OwnerApprovedItem = ({
  owner,
  status,
  host_id,
  canLeave,
}) => {
  const t = useTranslate()

  const [id, prop] = [
    getId(owner),
    getType(owner),
  ]

  const { handleAction } = useContext(
    HostContext
  )

  const menu = useMemo(
    () =>
      [
        !!canLeave
          ? {
              key: HostActionTypes.LEAVE,
              label: t('leave'),
              icon: (
                <IoTrashOutline className="text-red-500" />
              ),
            }
          : undefined,
      ].filter((value) => value),
    [t, canLeave]
  )

  return renderElse(
    _.isEmpty(menu),
    <div className="absolute top-0 right-0 px-3 py-2">
      <DrawMenu>
        {(__, toggle) => (
          <Menu
            items={menu}
            onClick={({ key }) => {
              handleAction(key, {
                id,
                prop,
                host_id,
              })
              toggle()
            }}
          />
        )}
      </DrawMenu>
    </div>
  )
}

const renderItem =
  ({
    status,
    request_status,
    ...item
  }) =>
  ({ params = {} }) => {
    let Component = Null
    switch (Number(status)) {
      case -1:
        Component = PendingItem
        break
      case -8:
      case -9:
        Component = RejectedItem
        break
      default:
        break
    }
    return (
      <Component
        {...item}
        {...params}
        {...{ status }}
      />
    )
  }

const renderOwnerItem =
  ({
    status,
    request_status,
    ...item
  }) =>
  ({ params = {} }) => {
    let Component = Null
    switch (Number(status)) {
      case -1:
        Component = OwnerPendingItem
        break
      case 1:
        Component = OwnerApprovedItem
        break
      default:
        break
    }
    return (
      <Component
        {...item}
        {...params}
        {...{ status }}
      />
    )
  }

export const renderHost =
  ({ request_status }) =>
  (item) =>
    (
      <HostContext.Consumer>
        {({ handleAction }) =>
          renderHostItem(
            handleAction,
            Number(request_status) === 1
              ? undefined
              : renderItem({
                  status: _.get(
                    item,
                    'status'
                  ),
                  request_status,
                  host_id: getId(item),
                  owner: _.get(
                    item,
                    'owner'
                  ),
                })
          )(item)
        }
      </HostContext.Consumer>
    )

export const renderHostOwner =
  ({
    request_status,
    withoutContextMenu = false,
  }) =>
  (item) => {
    const canLeave = true
    return (
      <HostContext.Consumer>
        {({ handleAction }) =>
          renderHostRequest(
            handleAction,
            withoutContextMenu
              ? Null
              : Number(
                  request_status
                ) === 1
              ? undefined
              : renderOwnerItem({
                  canLeave,
                  status: _.get(
                    item,
                    'status'
                  ),
                  request_status,
                  host_id: getId(item),
                  owner: _.get(
                    item,
                    'owner'
                  ),
                }),
            {
              canLeave,
            }
          )(item)
        }
      </HostContext.Consumer>
    )
  }
