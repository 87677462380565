import { Avatar } from 'antd'
import { getType } from 'apis/model/base'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { renderOwnChild } from 'views/Shared'
import DonorContext from '../DonorContext'

export const DonorAccountLinking = ({
  owner,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  if (_.isEmpty(owner)) {
    return null
  }

  return (
    <Wrapper>
      <span className="uppercase text-sm font-medium text-color-300 tracking-wide">
        {t('account link')}
      </span>
      <div className="flex flex-col items-center">
        <Avatar
          size={60}
          src={getAvatar(owner)}
          className="background-100 flex flex-center mb-4">
          <span className="uppercase text-color-100">
            {_.first(getTitle(owner))}
          </span>
        </Avatar>
        <Link
          to={getLinkToDetail(owner)}
          className="text-lg font-semibold text-color-org no-underline text-center leading-tight mb-1">
          {getTitle(owner)}
        </Link>
        <span className="text-sm text-secondary italic font-light">
          {t(getType(owner))}
        </span>
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({
      owner: _.get(donor, 'owner'),
    })
  )
)(DonorAccountLinking)
