import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import * as DonationEventView from "../../Donation/components/DonationEventView";
import * as GivingEventView from "../../Giving/components/GivingEventView";
import _ from "lodash"
import IOReport from "../../Activity/components/IOReport";
import PlusSquareOutlined from "@ant-design/icons/lib/icons/PlusSquareOutlined";
import {Space} from "antd";
import GivingContext from "../GivingContext";
import {Link} from "react-router-dom";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import getTitle from "../../../../helpers/getTitle";
import {IoAddOutline, IoFlowerOutline} from "react-icons/io5";
import wishareModel from "../../wishareModel";
import {useHistory} from "../../../../modules/navigation/useRouter";


const GivingStatisticBar = ({giving}) => {
  const t = useTranslate();
  if (!!!giving) return null;
  return (
    <div className="flex flex-col">
      <div className="flex items-center text-secondary font-bold text-xl uppercase mb-1">
        {`${t('giving')}`}</div>
      <div className="p-3 rounded-lg border-2 border-secondary">
        <GivingEventView.StatisticBar givingEvent={giving}/>
      </div>
    </div>
  )
}

const DonationStatisticBar = ({giving}) => {
  const t = useTranslate();
    const history = useHistory()
  const donationEvent = _.get(
    giving,
    'donation_events.0'
  )
  if (!!_.get(giving, 'donation_events.0')) return (
    <div className="flex flex-col">
      <div className="text-primary font-bold text-xl uppercase mb-1">
        {`${t('donation')}`}</div>
      <Link
        to={getLinkToDetail(
          donationEvent
        )}
        className="flex items-center text-primary leading-tight mb-3">
        <IoFlowerOutline className="text-xl text-primary"/>
        <span className="flex-1 font-bold ml-2">{getTitle(donationEvent)}</span>
      </Link>
      <div className="p-3 rounded-lg border-2 border-primary">
        <DonationEventView.StatisticBar donationEvent={_.get(giving, 'donation_events.0')}/>
      </div>
    </div>
  );

  if (!!_.get(giving, 'edit')) {
    return (
        <div
            className="flex flex-col"
            onClick={() => {
                history.push({
                    pathname:
                        wishareModel.getLinkToSetting(
                            giving,
                            '/donation'
                        ),
                })
            }}>
            <div className="flex items-center text-primary font-bold text-xl uppercase mb-1">
                {t('donation')}
            </div>
            <div className="p-2 space-x-1 flex items-center border-2 border-primary text-primary rounded-lg cursor-pointer">
                <IoAddOutline />
                <span>
                    {t('link to donation')}
                </span>
            </div>
        </div>
      // <div className="flex flex-col">
      //   <div className="flex items-center text-primary font-bold text-xl uppercase mb-1">
      //     {`${t('donation')}`}</div>
      //   <div className="p-3">
      //     <Space>
      //       <PlusSquareOutlined/>
      //       {t('Gắn liên kết đến Đóng góp (dịch)')}
      //     </Space>
      //   </div>
      // </div>
    )
  }
  return null;
}

const TotalStatisticBar = ({item}) => {
  const t = useTranslate();
  if (!!!item) return null;

  if (!_.get(item, 'donation_events.length')) return null;

  const newItem = {
    input_statistics: _.get(item, 'total_donation_items', []),
    output_statistics: _.get(item, 'total_giving_items', [])
  };

  return !!_.get(item, 'donation_events.length') && (
    <div className="flex flex-col space-y-3">
      <div
        className="text-gray-600 font-bold text-xl uppercase flex items-center justify-start">{t('outcome')}</div>
      <IOReport item={newItem}/>
    </div>
  )
}

const GivingSummary = React.memo(
  ({Wrapper = 'div', ...props}) => {

    const {isSm} = useContext(
      LayoutContext
    )

    const t = useTranslate()

    const {giving} = useContext(
      GivingContext
    )
    if (!giving) return null;

    //   options.owner.id,
    //   options.impactCategories.id,
    //   options.statisticBarResult.id, ==> TotalStatisticBar
    //   options.statisticBarRefs.id, ==> GivingStatisticBar
    //   options.statisticBar.id, ==> DonationStatisticBar
    //   options.submitBar.id,
    //   options.share.id,
    //   options.donatePackages.id
    return (
      <Wrapper className="flex flex-col space-y-3">
        <TotalStatisticBar giving={giving}/>
        <GivingStatisticBar giving={giving}/>
        <DonationStatisticBar giving={giving}/>
      </Wrapper>
    )
  }
)

export default GivingSummary
