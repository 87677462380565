import { getId } from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import { topicApi } from '../apis'
import EntityList from '../EntityList'
import { bindQueryParams } from '../functions/routerHelper'
import createEntityListParams from './createEntityListParams'

const createEntityListByTopic = (
  topic,
  entity,
  entityListParams = {}
) => {
  const id = getId(topic)

  let apiInfo =
    topicApi.topic_getEntitiesByTopic_api

  const {
    RenderItem,
    RenderEntity,
    collection: type,
  } = createEntityListParams(entity)

  const lookupKey = [
    apiInfo.path
      .replace(':id', id)
      .replace(':type', type),
  ].join('/')

  if (_.isEmpty(topic)) return null

  return (
    <div className="space-y-3">
      <EntityList
        {...entityListParams}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        RenderItem={RenderItem}
        RenderEntity={RenderEntity}
      />
    </div>
  )
}

export default createEntityListByTopic
