import { UserOutlined } from '@ant-design/icons'
import { Avatar, Layout } from 'antd'
import { userModel } from 'apis/model'
import Count from 'components/Feed/Count'
import { LoginContext } from 'components/LoginContext'
import ShadowScrollbar from 'components/ShadowScrollbar'
import Toggle from 'components/Toggle'
import { UseHook } from 'components/UseReducer'
import useTranslate from 'modules/local/useTranslate'
import useOnLocationChange from 'modules/navigation/useOnLocationChange'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useRef,
} from 'react'
import { MdClose } from 'react-icons/md'
import { useClickAway } from 'react-use'
import { staticPaths } from 'routes/staticPaths'
import UserCtrl from 'views/User/UserCtrl.lazy'
import { LayoutContext } from './LayoutContext'

const User = () => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  if (!login)
    return (
      <span>
        <Avatar
          onClick={() =>
            history.push(
              staticPaths.login
            )
          }
          size={50}
          className="background-200 cursor-pointer">
          <UserOutlined className="text-color-000" />
        </Avatar>
      </span>
    )

  return (
    <span>
      <Toggle title={null}>
        {(istoggle, toggle) => {
          return (
            <>
              <Avatar
                onClick={() =>
                  toggle(true)
                }
                size={50}
                className={
                  !istoggle
                    ? 'background-100 hover:background-200 cursor-pointer border'
                    : 'background-100 hover:background-200 pointer-events-none border'
                }
                src={userModel.getFullAvatarUrl(
                  login
                )}>
                <div className="text-color-000 uppercase ">
                  {
                    userModel.getTitle(
                      login
                    )[0]
                  }
                </div>
              </Avatar>
              {istoggle && (
                <UseHook hook={useRef}>
                  {(ref) => (
                    <>
                      <UseHook
                        hook={
                          useClickAway
                        }
                        deps={[
                          ref,
                          toggle,
                        ]}></UseHook>
                      <UseHook
                        hook={
                          useOnLocationChange
                        }
                        deps={[
                          toggle,
                        ]}></UseHook>
                      <div
                        style={{
                          left: 60,
                        }}
                        className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-75"></div>
                      <div
                        ref={ref}
                        style={{
                          width: 280,
                          position:
                            'absolute',
                          height:
                            '100vh',
                          bottom: 0,
                          left: `60px`,
                        }}
                        className="verticalList__small background border-r border-gray-300">
                        <ShadowScrollbar>
                          <UserCtrl />
                        </ShadowScrollbar>
                        <Count
                          onClick={() =>
                            toggle()
                          }
                          className="absolute top-0 right-0 mt-3 z-10"
                          component={
                            MdClose
                          }></Count>
                      </div>
                    </>
                  )}
                </UseHook>
              )}
            </>
          )
        }}
      </Toggle>
    </span>
  )
}
const { Sider } = Layout
export const LeftSider = () => {
  return (
    <LayoutContext.Consumer>
      {({
        leftSideBarWidth,

        leftInner,
      }) => {
        return (
          <Sider
            className="z-50 background h-full"
            theme="light"
            collapsed={true}
            collapsible
            collapsedWidth={60}
            trigger={null}
            width={leftSideBarWidth}>
            <div
              className="flex flex-col justify-between border-r border-gray-300"
              style={{
                width: 60,
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
              }}>
              <div className="flex-1 flex">
                {leftInner}
              </div>
              <div
                className="flex items-center p-1 flex justify-center cursor-pointer"
                style={{
                  background: 'inherit',
                  height: 60,
                    width: 60,
                }}>
                <User />
              </div>
            </div>
          </Sider>
        )
      }}
    </LayoutContext.Consumer>
  )
}
