import classNames from 'classnames'
import ImageLoader from 'components/ImageLoader'
import Pure from 'components/Pure'
import Translate from 'modules/local/Translate'
import React from 'react'
import { OverlayBannerToggle } from 'views/Wishare/custom/OverlayBanner'

const renderImpCate = (selected = false, setSelected) => ({ id, avatar, photo, ...rest }, index) => {
      return (
          <Translate key={index}>
              {(t) => (
                  <OverlayBannerToggle
                      defaultValue={selected}
                      uncontrolled={!setSelected}
                      className="bg-primary leading-tight text-sm text-white pl-2 pr-5 py-1 overlay-banner-selected"
                      overlay={
                          <span className="font-medium text-xs italic">{t('selected')}</span>
                      }
                      onClick={() => {
                          if (setSelected) {
                              setSelected(id)
                          }
                      }}>
                      <Pure input={[t, id]}>
                          <ImageLoader
                              src={avatar}
                              className={classNames(
                                  'w-28 h-28 rounded-lg',
                                  setSelected && 'cursor-pointer'
                              )}
                          />
                      </Pure>
                  </OverlayBannerToggle>
              )}
          </Translate>
      )
  }

export default renderImpCate
