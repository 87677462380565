import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { mapProps } from 'recompose'
import TargetCreator from 'views/Wishare/custom/TargetCreator'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import RecruitmentContext from '../RecruitmentContext'
import { recruitmentStepFormSchema } from '../recruitmentSchemas'

const formFields = [
  {
    name: 'target',
  },
  {
    name: 'target_unit_name',
  },
  {
    name: 'no_limit_flag',
    defaultValue: 0,
  },
  {
    name: 'targets',
  },
  {
    name: 'recruitment_type',
  },
]

const formSchema = [
  ...recruitmentStepFormSchema.targetStage,
  {
    title: 'detail',
    children: [
      {
        name: 'targets',
        component: mapProps(
          ({
            name,
            form,
            value,
            onChange,
          }) => {
            const type_cd = _.get(
              form,
              'values.recruitment_type'
            )
            const type_cds =
              wishareFieldTypes
                .recruitment.types
            return {
              name,
              value,
              onChange,
              type_cd,
              type_cds,
            }
          }
        )(TargetCreator),
      },
    ],
  },
]

const RecruitmentSettingTarget = () => {
  const { recruitment } = useContext(
    RecruitmentContext
  )

  const validationSchema = undefined

  const recruitment_id = getId(
    recruitment
  )

  const onPreSubmit = ({
    id,
    type,
    targets,
    target_units,
    recruitment_type,
    no_limit_flag,
    ...values
  }) => {
    const _targets = targets
      ? Array.from(targets).map(
          ({ unit_name, ...rest }) => ({
            unit_name,
            item_name: unit_name,
            ...rest,
          })
        )
      : undefined
    return {
      ...values,
      ...(_targets
        ? {
            targets:
              JSON.stringify(_targets),
          }
        : {}),
      no_limit_flag: no_limit_flag
        ? 1
        : 0,
    }
  }

  const initialized = useCallback(
    ({
      owner,
      recruitment_type,
      ...values
    }) => {
      const target_units =
        wishareFieldTypes.recruitment.units.filter(
          ({ type }) =>
            type === recruitment_type
        )
      return {
        ...values,
        target_units,
        recruitment_type,
        id: recruitment_id,
      }
    },
    [recruitment_id]
  )

  if (_.isEmpty(recruitment))
    return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: recruitment,
    validationSchema,
    query: bindQueryParam({
      recruitment_id,
    }),
    onPreSubmit,
    initialized,
    useDefaultActionBar: false,
    apiInfo: recruitment_update_api,
  })
}

export default RecruitmentSettingTarget
