import {host_getsByHost_api} from 'apis'
import {getId, getType,} from 'apis/model/base'
import classNames from 'classnames'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext, useMemo,} from 'react'
import {IoCaretDown, IoCaretForward,} from 'react-icons/io5'
import {useLocation} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {branch, compose, fromRenderProps, nest, pure, renderNothing,} from 'recompose'
import {Null, renderIf,} from 'views/Shared'
import {ExpandablePanel} from 'views/Wishare/custom/AdvancedFormGroup'
import EntityList from 'views/Wishare/EntityList'
import {HostsSortBy} from 'views/Wishare/factory/createEntitySortBy'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import {renderHostOwner} from 'views/Wishare/Host/functions/renderHost'
import HostContext, {HostProvider,} from 'views/Wishare/Host/HostContext'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import HostsOwnerAdvancedFilter from './HostsOwnerAdvancedFilter'

const HostPendingList = () => {
  const t = useTranslate()

  const location = useLocation()

  const { container } = useContext(
    HostContext
  )

  const refreshToken = _.get(
    location.state,
    'refreshToken'
  )

  return (
    <Toggle>
      {(isToggle, toggle) => (
        <EntityList
          key={refreshToken}
          renderWrap={(
            children,
            __,
            count
          ) => (
            <ExpandablePanel
              onClick={toggle}
              expanded={isToggle}
              Wrapper={
                Number(count) > 0
                  ? ({ children }) => (
                      <div className="border border-primary rounded-lg space-y-2">
                        {children}
                      </div>
                    )
                  : undefined
              }
              renderCollapsedContent={(
                child
              ) => (
                <div
                  style={{
                    visibility:
                      'hidden',
                    maxHeight: 0,
                  }}>
                  {child}
                </div>
              )}
              ContentWrapper={({
                children,
              }) => (
                <div className="p-3 space-y-3">
                  {children}
                </div>
              )}
              Header={({
                title,
                onClick,
                expanded,
              }) => {
                const IconComponent =
                  !!expanded
                    ? IoCaretDown
                    : IoCaretForward
                return renderIf(
                  Number(count) > 0,
                  <div
                    className={classNames(
                      'flex items-center gap-2 p-2 text-primary bg-primary-50 cursor-pointer',
                      !!expanded
                        ? 'rounded-t-lg'
                        : 'rounded-lg'
                    )}
                    onClick={
                      count > 0
                        ? onClick
                        : null
                    }>
                    <IconComponent
                      size={14}
                    />
                    <span className="text-sm font-medium">
                      {t(title)}
                    </span>
                    <span className="text-sm font-semibold">
                      {`(${count})`}
                    </span>
                  </div>
                )
              }}
              title="pending list">
              {children}
            </ExpandablePanel>
          )}
          apiInfo={host_getsByHost_api}
          values={{
            request_status: -1,
          }}
          query={bindQueryParams([
            {
              id: getId(container),
            },
            {
              prop: getType(container),
            },
          ])}
          renderItem={renderHostOwner({
            request_status: -1,
          })}
          RenderEntity={React.Fragment}
        />
      )}
    </Toggle>
  )
}

export const CoOwnerList = ({
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
  },
                              filterByType = null,
}) => {
  const t = useTranslate()

  const { container } = useContext(
    HostContext
  )

  const location = useLocation()

  const { current } = useContext(
    NavigationContext
  )

  const refreshToken = useMemo(
    () =>
      JSON.stringify({
        token: _.get(
          location.state,
          'refreshToken'
        ),
        dependencies: [current],
      }),
    [current, location.state]
  )

  const [id, prop] = [
    getId(container),
    getType(container),
  ]

  const status = useSearchParam(
    filter_actions.status
  )

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          status,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo: host_getsByHost_api,
        query: bindQueryParams([
          { id },
          {
            prop,
          },
        ]),
        renderItem: renderHostOwner({
          request_status: 1,
        }),
        RenderEntity: React.Fragment,
      },
      withValues: (values) => ({
        ...values,
        ...(!!filterByType ? {owner_type: filterByType} : {}),
        request_status: 1,
      }),
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          id,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
          refreshToken,
        ].join('/'),
      filter_params: {
        owner_id: id,
        owner_type: prop,
      },
      query_params: filter_actions,
      dependencies: [id, prop],
      sortHeader: t(
        'list of co-owners'
      ),
      SortByWidget: HostsSortBy,
      AdvancedFilter:
        HostsOwnerAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="co-owners" />
      <HostPendingList />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      container: organization,
    })
  ),
  branch(
    ({ container }) =>
      !!!getId(container),
    renderNothing
  ),
  pure
)(
  nest(
    ({ container, children }) => (
      <HostProvider
        container={container}
        autoRefreshKey="refreshToken">
        {children}
      </HostProvider>
    ),
    CoOwnerList
  )
)
