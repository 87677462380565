import {
  organization_fetchLatestCandidates_Api,
  organization_fetchLatestDonations_Api,
  organization_fetchLatestFeeds_Api,
  organization_fetchLatestFollowers_Api,
  organization_fetchLatestGivings_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, { useMemo } from 'react'
import {
  branch,
  compose,
  fromRenderProps,
} from 'recompose'
import { Null } from 'views/Shared'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import WorkspaceContext from '../WorkspaceContext'
import { renderHomeCandidate } from './renderHomeCandidate'
import { renderHomeDonation } from './renderHomeDonation'
import { renderHomeFeed } from './renderHomeFeed'
import { renderHomeFollower } from './renderHomeFollower'
import { renderHomeGiving } from './renderHomeGiving'

export const WorkspaceHomeGroups =
  Object.freeze({
    NOTIFICATION: 'notification',
    INSIGHTS: 'insights',
    LASTED_FEEDS: 'lasted-feeds',
    LASTED_GIVINGS: 'lasted-givings',
    LASTED_FOLLOWERS:
      'lasted-followers',
    LASTED_DONATIONS:
      'lasted-donations',
    LASTED_CANDIDATES:
      'lasted-candidates',
  })

const WorkspaceHomeGroup = ({
  id,
  item,
  query,
  values,
  apiInfo,
  renderItem,
  Wrapper = 'div',
}) => {
  const refreshKey = useMemo(
    () =>
      [
        apiInfo.path,
        id,
        JSON.stringify(query),
        JSON.stringify(values),
      ].join('/'),
    [id, query, values, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-6">
      <EntityList
        query={query}
        values={values}
        apiInfo={apiInfo}
        refreshKey={refreshKey}
        renderItem={renderItem}
        RenderEntity={React.Fragment}
      />
    </Wrapper>
  )
}

export const WorkspaceLastedFeeds = ({
  type,
  query,
  ...props
}) => (
  <WorkspaceHomeGroup
    query={query}
    apiInfo={
      organization_fetchLatestFeeds_Api
    }
    renderItem={renderHomeFeed}
    {...props}
  />
)

export const WorkspaceLastedFollowers =
  ({ type, query, ...props }) => (
    <WorkspaceHomeGroup
      query={query}
      apiInfo={
        organization_fetchLatestFollowers_Api
      }
      renderItem={renderHomeFollower}
      {...props}
    />
  )

export const WorkspaceLastedCandidates =
  ({ type, query, ...props }) => (
    <WorkspaceHomeGroup
      query={query}
      apiInfo={
        organization_fetchLatestCandidates_Api
      }
      renderItem={renderHomeCandidate}
      {...props}
    />
  )

export const WorkspaceLastedGivings = ({
  type,
  query,
  ...props
}) => (
  <WorkspaceHomeGroup
    query={query}
    apiInfo={
      organization_fetchLatestGivings_Api
    }
    renderItem={renderHomeGiving}
    {...props}
  />
)

export const WorkspaceLastedDonations =
  ({ type, query, ...props }) => (
    <WorkspaceHomeGroup
      query={query}
      apiInfo={
        organization_fetchLatestDonations_Api
      }
      renderItem={renderHomeDonation}
      {...props}
    />
  )

const WorkspaceGroupFactory = ({
  type = WorkspaceHomeGroups.LASTED_FEEDS,
  ...props
}) => {
  let Component = Null
  switch (type) {
    case WorkspaceHomeGroups.LASTED_FEEDS:
      Component = WorkspaceLastedFeeds
      break
    case WorkspaceHomeGroups.LASTED_FOLLOWERS:
      Component =
        WorkspaceLastedFollowers
      break
    case WorkspaceHomeGroups.LASTED_GIVINGS:
      Component = WorkspaceLastedGivings
      break
    case WorkspaceHomeGroups.LASTED_DONATIONS:
      Component =
        WorkspaceLastedDonations
      break
    case WorkspaceHomeGroups.LASTED_CANDIDATES:
      Component =
        WorkspaceLastedCandidates
      break
    default:
      break
  }
  return (
    <Component
      {...props}
      {...{ type }}
    />
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      id: getId(organization),
      item: organization,
      query: bindQueryParam({
        id: getId(organization),
      }),
    })
  ),
  branch(
    ({ item }) => _.isEmpty(item),
    () => () => <WishareNotFound />
  )
)(WorkspaceGroupFactory)
