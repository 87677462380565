import { user_getPropsByHost_Api } from 'apis'
import {
  channelSchema,
  organizationSchema,
} from 'apis/schema'
import { createListTransform } from 'apis/utils/createListTransform'
import ContentContainer from 'components/ContentContainer'
import { BaseItem } from 'components/Item/BaseItem'
import Null from 'components/NullComponent'
import { SelectEntityItem } from 'components/SelectEntityItem'
import getLinkToDetail from 'helpers/getLinkToDetail'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import React, {
  useContext,
  useState,
} from 'react'
import { Cate } from 'views/Feed/Cate'
import { HorizontalList } from 'views/Feed/components/HorizontalList'
import ServerContext from 'views/Server/ServerContext'

const tabStringList = [
  'organization',
  'channel',
]

const apiInfos = {
  organization: {
    ...user_getPropsByHost_Api,
    schema: organizationSchema,
    transform: createListTransform(
      organizationSchema
    ),
  },
  channel: {
    ...user_getPropsByHost_Api,
    schema: channelSchema,
    transform: createListTransform(
      channelSchema
    ),
  },
}
export default function ItemList({
  current = 'organization',
}) {
  const { item } = useContext(
    ServerContext
  )
  const [_current, setCurrent] =
    useState(current)
  return (
    <div className="flex flex-wrap justify-center">
      <ContentContainer
        style={{
          height: '70px',
          top: 'var(--header-height)',
        }}
        className="relative stickyTop w-full">
        <HorizontalList
          renderMore={Null}
          currentIndex={_current}
          className="background absolute"
          background="#fff"
          list={tabStringList}
          renderItem={(item, i) => (
            <Cate
              item={{
                id: i,
                name: item,
              }}
              active={item === _current}
              key={item.name}
              handleItemClick={() =>
                setCurrent(
                  tabStringList[i]
                )
              }
            />
          )}
        />
      </ContentContainer>
      <LazyPagination
        key={_current}
        {...{
          apiInfo: apiInfos[_current],
          query: {
            ':username': item.username,
            ':props': _current,
          },
          renderItem: (
            id,
            i,
            action
          ) => (
            <SelectEntityItem
              item={id}
              schema={
                action.apiInfo.schema
              }>
              {(item) => (
                <BaseItem
                  className="m-3"
                  key={i}
                  totals={{}}
                  {...{
                    avatar: item.avatar,
                    cover:
                      item.cover_photo,
                    title:
                      item.title ||
                      item.name,
                    description:
                      item._type,
                    premium:
                      item.premium,
                    id: item.id,
                    to: getLinkToDetail(
                      item
                    ),
                  }}
                />
              )}
            </SelectEntityItem>
          ),
        }}></LazyPagination>
    </div>
  )
}
