import { medal_getMedalRewardsOfOwner_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import renderMedalReward from 'views/MainPage/WorkSpace/Medal/renderMedalReward'
import { renderIf } from 'views/Shared'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import UserContext from '../UserContext'

const UserMedals = () => {
  const t = useTranslate()

  const { user } = useContext(
    UserContext
  )

  const [owner_id, owner_type] = [
    getId(user),
    getType(user),
  ]

  const apiInfo =
    medal_getMedalRewardsOfOwner_api

  const refreshKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(
            ':owner_id',
            owner_id
          )
          .replace(
            ':owner_type',
            owner_type
          ),
        'visible=1',
      ].join('/'),
    [owner_id, owner_type, Date.now()]
  )

  if (_.isEmpty(user)) {
    return null
  }

  return (
    <EntityList
      apiInfo={apiInfo}
      refreshKey={refreshKey}
      renderItem={renderMedalReward({
        showOwner: true,
      })}
      RenderEntity={React.Fragment}
      query={bindQueryParams([
        { owner_id },
        { owner_type },
      ])}
      values={{ visible: 1 }}
      renderWrap={(child, __, count) =>
        renderIf(
          count > 0,
          <div className="flex flex-col">
            <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
              {t('medals')}
            </div>
            <div className="flex flex-col space-y-2 my-2">
              {child}
            </div>
          </div>
        )
      }
    />
  )
}

export default UserMedals
