import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import { createValue } from 'components/form/utils'
import RichEditor from 'components/RichEditor'
import {
  isRawContentEmpty,
  parseRawContent,
} from 'components/RichEditor/parseRawContent'
import plugins from 'components/RichEditor/plugins'
import { defaultRawContent } from 'components/RichEditor/RichEditor'
import { convertToRaw } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import _ from 'lodash'
import React, { useState } from 'react'
import { Null } from 'views/Shared'
import './CustomRichEditor.css'

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const EmptyEditor = () => (
  <EmptyHolder
    style={{ border: 'none' }}
  />
)

const CustomRichEditor = ({
  name,
  value,
  className,
  Wrapper = 'div',
  onChange = Null,
  readOnly = false,
  Empty = EmptyEditor,
}) => {
  const [default_value] =
    useState(value)

  const handleOnChange = (
    editorState
  ) => {
    onChange(
      createValue(
        name,
        JSON.stringify(
          convertToRaw(
            editorState.getCurrentContent()
          )
        )
      )
    )
  }

  const showEmptyHolder = Boolean(
    readOnly && isRawContentEmpty(value)
  )

  if (readOnly && _.isEmpty(value)) {
    return null
  }

  return (
    <Wrapper
      className={classNames(
        'w-full border rounded-md',
        className
      )}>
      {showEmptyHolder ? (
        <Empty />
      ) : (
        <RichEditor
          deps={[
            value !== default_value,
          ]}
          readOnly={readOnly}
          editorState={
            value
              ? parseRawContent(
                  isJson(value)
                    ? value
                    : JSON.stringify(
                        defaultRawContent
                      )
                )
              : undefined
          }
          onChange={handleOnChange}
        />
      )}
    </Wrapper>
  )
}

export const CustomEditorRenderer =
  React.memo(
    ({
      value,
      rawValue,
      className,
      onChange,
      Wrapper = 'div',
    }) => {
      const readOnly =
        !_.isFunction(onChange)

      const [
        editorState,
        setEditorState,
      ] = useState(
        _.isString(value)
          ? parseRawContent(value)
          : value ||
              parseRawContent(rawValue)
      )
      return (
        <Wrapper
          className={classNames(
            'w-full border rounded-md',
            className
          )}>
          <Editor
            readOnly={readOnly}
            editorState={editorState}
            onChange={(editorState) => {
              if (readOnly) {
                setEditorState(
                  editorState
                )
              } else {
                onChange(editorState)
              }
            }}
            plugins={plugins}
          />
        </Wrapper>
      )
    }
  )

export default CustomRichEditor
