import { default as Null } from 'components/NullComponent'
import _ from 'lodash'
import React from 'react'

const renderSelf = (me) => me

const renderOwnChild = ({ children }) =>
  children || null

const renderIf = (
  condition = false,
  value
) => (Boolean(condition) ? value : null)

const renderIfElse = (
  condition = false,
  value,
  elseValue
) => (Boolean(condition) ? value : elseValue)

const renderElse = (
  condition = false,
  value
) => renderIf(!condition, value)

const renderWhere = (
  condition,
  render = Null
) => (!!condition ? render() : null)

const withPagerWrapper =
  (render = renderSelf) =>
  (children, __, total = 0) =>
    renderWhere(total > 0, () =>
      render(children)
    )

const Visible = (
  {
    visible = false,
    invisible = false,
  },
  __
) => !!visible || !!!invisible

const renderSource = (source) =>
  Object.keys(source).map((name) => ({
    label: name,
    value: _.get(source, name),
  }))

const renderCallback = (fn = Null) =>
  fn()

const renderTrue = () => true

const renderFalse = () => false

const renderPhone = (
  phone,
  renderBody = renderSelf,
  Wrapper = 'a'
) =>
  renderIf(
    phone,
    <Wrapper
      href={['tel:', phone].join('')}>
      {renderBody(phone)}
    </Wrapper>
  )

const renderMail = (
  mail,
  renderBody = renderSelf,
  Wrapper = 'a'
) =>
  renderIf(
    mail,
    <Wrapper
      href={['mailto:', mail].join('')}>
      {renderBody(mail)}
    </Wrapper>
  )

const IsDeleted = (item) =>
  Boolean(
    _.get(item, 'deleted') ||
      _.get(item, 'isDeleted')
  )

export {
  Null,
  Visible,
  renderIf,
  renderIfElse,
  IsDeleted,
  renderElse,
  renderTrue,
  renderSelf,
  renderMail,
  renderPhone,
  renderFalse,
  renderWhere,
  renderSource,
  renderCallback,
  renderOwnChild,
  withPagerWrapper,
}
