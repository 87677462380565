import { Input, Modal } from 'antd'
import classNames from 'classnames'
import { updateEntity } from 'components/RichEditor/utils'
import preventDefaultEvent from 'helpers/preventDefaultEvent'
import useTranslate from 'modules/local/useTranslate'
import React, { useRef } from 'react'
import ReactPlayer from "react-player"
import { VideoEmbed } from "../../../Feed/VideoEmbed"

export default function Video(props) {
  const t = useTranslate()
  const {
    block,
    className,
    theme = {},
    onLoad,
    ...otherProps
  } = props
  // leveraging destructuring to omit certain properties from props
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    blockStyleFn,
    preventScroll,
    contentState,
    ...elementProps
  } = otherProps
  const combinedClassName = classNames(
    'w-full shadow',
    theme.image,
    className
  )
  const {
    src,
    caption
  } = contentState
    .getEntity(block.getEntityAt(0))
    .getData()
  const inputRef = useRef()
  const {
    getEditorState,
    setEditorState,
    getReadOnly
  } = blockProps
  const handleSubmit = () => {
    setEditorState(
      updateEntity(
        getEditorState(),
        block.getEntityAt(0),
        {
          caption:
            inputRef.current.input.value
        }
      )
    )
  }
  const isReadOnly = getReadOnly()
  return (
    <div
      className="z-10 relative"
      {...elementProps}>
      {
        (!!ReactPlayer.canPlay(
          src
        ) && (
            <VideoEmbed
              url={src}
              isShowPlayer={true}
            />
          ))
      }

      {((caption && caption.length) ||
        !isReadOnly) && (
        <figcaption
          style={{
            margin: 0,
            marginTop: '-0.3em'
          }}
          className="flex justify-center w-full text-center">
          <span
            onClick={
              !isReadOnly
                ? () => {
                  Modal.confirm({
                    icon: null,
                    title: t('write caption for video'),
                    className: 'custom-modal',
                    okText: t('add'),
                    cancelText: t('cancel'),
                    okButtonProps: {
                      type: 'primary',
                      className: 'rounded-lg no-shadow no-text-shadow no-border'
                    },
                    cancelButtonProps: {
                      className: 'rounded-lg no-shadow no-text-shadow no-border'
                    },
                    content: (
                      <form
                        onSubmit={e => {
                          preventDefaultEvent(
                            e
                          )
                          handleSubmit()
                          Modal.destroyAll()
                        }}>
                        <Input
                          key={
                            caption
                          }
                          defaultValue={
                            caption
                          }
                          ref={
                            inputRef
                          }
                          placeholder={
                              t('caption of video')
                          }
                        />
                        <input
                          hidden
                          type="submit"
                        />
                      </form>
                    ),
                    onOk: () => {
                      handleSubmit()
                    }
                  })
                }
                : null
            }>
            {caption &&
            caption.length ? (
              <div className="mt-2">
                <small>
                <span className=" leading-none">
                  {caption}
                </span>
                  {!isReadOnly && (
                      <span className="underline text-primary ml-2 italic cursor-pointer">
                    {t('edit')}
                  </span>
                  )}
                </small>
              </div>
            ) : (
              <div className="mt-2">
                  <small className="text-color-300 italic cursor-pointer hover:text-primary">
                {t('add caption')}
              </small>
              </div>
            )}
          </span>
        </figcaption>
      )}
    </div>
  )
}
