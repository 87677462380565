import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import ChartContext from '../ChartContext'

export const Copyright = () => {
  const t = useTranslate()
  const { item } = useContext(
    ChartContext
  )

  if (!!!item) {
    return (
      <div className="flex bottom-0 right-0">
        <div className="text-sm text-color-500 italic">
          <span className="font-bold text-color-100 opacity-0">...</span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex bottom-0 right-0">
      <div className="text-sm text-color-500 italic">
        {!_.isEmpty(
          _.get(
            item,
            'dataset.copyright'
          )
        ) && (
            <React.Fragment>
              {t('source')}
              {': '}
              <span className="font-bold text-color-100">
                {_.get(
                  item,
                  'dataset.copyright'
                )}
              </span>
            </React.Fragment>
          )}
        {!_.isEmpty(
          _.get(
            item,
            'dataset.designed_by'
          )
        ) && (
            <React.Fragment>
              {!_.isEmpty(
                _.get(
                  item,
                  'dataset.copyright'
                )
              ) && ', '}
              <span className="">
                {t(
                  'designed and provided by'
                )}
              </span>{' '}
              <span className="font-bold text-color-100">
                {_.get(
                  item,
                  'dataset.designed_by'
                )}
              </span>
            </React.Fragment>
          )}
      </div>
    </div>
  )
}
