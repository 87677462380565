import {DatePicker, Form, Input, Radio,} from 'antd'
import {createValue} from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {useCallback, useContext, useEffect, useMemo,} from 'react'
import {IoCalendarOutline} from 'react-icons/io5'
import {compose, mapProps, withProps,} from 'recompose'
import {notEmpty, Null, renderSelf,} from 'views/Shared'
import {wishareConfigs} from 'views/Wishare/configs'
import {StepFormContext, StepInnerForm,} from 'views/Wishare/factory/createStepForm'
import {getValidationMessage} from 'views/Wishare/messages'
import {GenderTypes} from 'views/Wishare/wishareFieldTypes'

const includedTypes = [
  'email',
  'number',
  'text',
  'url',
  'tel',
]

const defaultFields = {
  email: {
    name: 'email',
    label: 'email',
    component: withProps({
      type: 'email',
    })(Input),
    type: 'email',
  },
  phone: {
    name: 'phone_number',
    label: 'phone',
    component: withProps({
      type: 'tel',
    })(Input),
  },
  date_of_birth: {
    name: 'date_of_birth',
    label: 'date of birth',
    component: withProps(
      ({
        name,
        value,
        onChange = Null,
      }) => ({
        className: 'w-full',
        suffixIcon: (
          <IoCalendarOutline />
        ),
        format:
          wishareConfigs.dateFormat,
        onChange: (
          date,
          dateString
        ) => {
          onChange(
            createValue(
              name,
              date
                ? moment(new Date(date))
                : undefined
            )
          )
        },
        value: value
          ? moment(new Date(value))
          : undefined,
      })
    )(DatePicker),
    type: 'object',
  },
  gender: {
    name: 'gender',
    label: 'gender',
    component: compose(
      withTranslate,
      mapProps(
        ({ translate, ...props }) => ({
          ...props,
          buttonStyle: 'solid',
          className: 'CustomRadio',
          children: Object.values(
            GenderTypes
          ).map(
            ({
              name,
              value,
              label,
            }) => (
              <Radio.Button
                key={name}
                value={value}>
                {translate(label)}
              </Radio.Button>
            )
          ),
        })
      )
    )(Radio.Group),
  },
}

const RecruitmentRequiredFields = ({
  value,
  values,
  onChange = Null,
  readOnly = false,
}) => {
  const t = useTranslate()

  const {
    information_fields = {},
    required_information_fields = [],
  } = values || {}

  const array = useMemo(
    () =>
      Object.values(
        information_fields
      ).map(
        ({
          name,
          label,
          field_type,
        }) => ({
          name,
          type: field_type,
          placeholder: t(label),
        })
      ),
    [information_fields]
  )

  const getRules = (name) => {
    const { type } =
      _.find(array, { name }) || {}
    return [
      {
        required: true,
        message: t('required field'),
      },
      {
        ...(Boolean(
          !!type &&
            includedTypes.includes(type)
        )
          ? {
              type,
              message:
                getValidationMessage({
                  type,
                  translate: t,
                  notValid: true,
                }),
            }
          : {}),
      },
    ].filter(notEmpty)
  }

  const getValue = useCallback(
    (name, transform = renderSelf) =>
      transform(_.get(values, name)),
    [values]
  )

  const fields = Object.values(
    defaultFields
  ).filter(({ name }) =>
    required_information_fields.includes(
      name
    )
  )

  const { formInstance } = useContext(
    StepFormContext
  )

  const object = _.pick(
    values,
    Array.from(
      required_information_fields
    )
  )

  useEffect(() => {
    if (formInstance) {
      formInstance.setFieldsValue(
        object
      )
    }
  }, [formInstance, object])

  if (_.isEmpty(fields)) return null

  return (
    <StepInnerForm>
      <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
        {t('information')}
      </div>
      {fields.map(
        (
          {
            name,
            label,
            component: Component,
            transform = renderSelf,
          },
          index
        ) => {
          const params =
            _.find(array, { name }) ||
            {}
          const defaultValue = getValue(
            name,
            transform
          )
          return (
            <Form.Item
              key={index}
              name={name}
              label={t(label)}
              labelAlign="left"
              initialValue={
                defaultValue
              }
              rules={getRules(name)}
              className="flex flex-col">
              <Component
                onChange={onChange}
                placeholder={t(label)}
                {...params}
                name={name}
                disabled={Boolean(
                  readOnly
                )}
              />
            </Form.Item>
          )
        }
      )}
    </StepInnerForm>
  )
}

export const RecruitmentRequiredFormFields =
  ({
    value,
    values,
    onChange = Null,
    readOnly = false,
  }) => {
    const t = useTranslate()

    const {
      information_fields = {},
      required_information_fields = [],
    } = values || {}

    const [form] = Form.useForm()

    const array = useMemo(
      () =>
        Object.values(
          information_fields
        ).map(
          ({
            name,
            label,
            field_type,
          }) => ({
            name,
            type: field_type,
            placeholder: t(label),
          })
        ),
      [t, information_fields]
    )

    const getRules = (name) => {
      const { type } =
        _.find(array, { name }) || {}
      return [
        {
          ...(Boolean(
            !!type &&
              includedTypes.includes(
                type
              )
          )
            ? {
                type,
                message:
                  getValidationMessage({
                    type,
                    translate: t,
                    notValid: true,
                  }),
              }
            : {}),
        },
      ].filter(notEmpty)
    }

    const getValue = useCallback(
      (name, transform = renderSelf) =>
        transform(_.get(values, name)),
      [values]
    )

    const fields = Object.values(
      defaultFields
    ).filter(({ name }) =>
      required_information_fields.includes(
        name
      )
    )

    useEffect(() => {
      if (value && form) {
        form.setFieldsValue(value)
      }
    }, [form, value])

    if (_.isEmpty(fields)) return null

    return (
      <Form form={form}>
        <div className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
          {t('information')}
        </div>
        {fields.map(
          (
            {
              name,
              label,
              transform,
              component: Component,
            },
            index
          ) => {
            const params =
              _.find(array, { name }) ||
              {}

            return (
              <Form.Item
                key={index}
                name={name}
                label={t(label)}
                labelAlign="left"
                initialValue={getValue(
                  name,
                  transform
                )}
                rules={getRules(name)}
                className="flex flex-col">
                <Component
                  onChange={onChange}
                  placeholder={t(label)}
                  {...params}
                  name={name}
                  disabled={Boolean(
                    readOnly
                  )}
                />
              </Form.Item>
            )
          }
        )}
      </Form>
    )
  }

export default RecruitmentRequiredFields
