import { Input } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { DonationsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import DonationItemList from '../components/DonationItemList'
import { MyDonationRecordItem } from '../components/DonationRecordItem'

const MyDonationList = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [sort, setSort] = useState()

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  return (
    <Wrapper className="flex flex-col space-y-3 pb-16">
      <div className="p-3 uppercase text-blue-600 font-medium border border-primary rounded-lg text-center">
        {t('your donation description')}
      </div>

      <Input.Search
        className="input-search"
        placeholder={t(
          'search with donation code'
        )}
        onSearch={(value) =>
          onSearch(value)
        }
      />

      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {t('list of contributors')}
          </span>
          <div className="flex items-end justify-end">
            <DonationsSortBy
              value={sort}
              onSelect={(value) =>
                setSort(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <DonationItemList
            sort_by={sort}
            keyword={keyword}
            filtered_by="owner"
            RenderItem={
              MyDonationRecordItem
            }
          />
        </div>
          <div className="block md:hidden h-16" />
      </div>
    </Wrapper>
  )
}

export default MyDonationList
