import {notificationSchema} from 'apis'
import {LoginContext} from 'components/LoginContext'
import {getTokenKey} from 'helpers/localStorage'
import {normalize, schema} from 'normalizr'
import React, {useContext, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {authenticate, startRoom} from './actions'
import {createSocket} from './socket'
import {SocketContext} from './SocketContext'
import {AUTHENTICATED, CONNECT, JOINED_ROOM} from './types'

export const useEmit = () => {
  const { emit } = useContext(
    SocketContext
  )
  return emit
}
let _socket
export default function SocketProvider({
  children
}) {
  const socket = useSelector(state => state.socket)
  const login = useContext(LoginContext)
  const loginId = login && login.id
  const dispatch = useDispatch()
  const socketRef = useRef()
  useEffect(() => {
    if (loginId) {
      _socket = createSocket(
        (
          _type,
          payload = {},
          emit
        ) => {
          // message.info(_type)
          const action = {
            type: 'socket/' + _type,
            socket: {
              type: _type,
              payload
            }
          }
          switch (_type) {
            case CONNECT:
              const token = getTokenKey()
              if (token) {
                emit(
                  authenticate({
                    token
                  })
                )
              }

              break
            case JOINED_ROOM:
              break
            case AUTHENTICATED:
              loginId &&
                emit(
                  startRoom({
                    roomType: 'user',
                    roomId: loginId
                  })
                )
              break
            case 'notifications':
              const record = {
                id:
                  '/notifications/gets',
                news: [payload]
              }
              const {
                result,
                entities
              } = normalize(
                record,
                new schema.Entity(
                  'records',
                  {
                    news: [
                      notificationSchema
                    ]
                  },
                  {
                    idAttribute: 'id'
                  }
                )
              )
              action.entities = entities
              break
            default:
              break
          }
          dispatch(action)
        }
      )
      socketRef.current = _socket
    }

    return () =>
      socketRef.current &&
      socketRef.current.socket.disconnect()
  }, [dispatch, loginId])
  return (
    <SocketContext.Provider
      value={{
        ...socket,
        ...socketRef.current
      }}>
      {children}
    </SocketContext.Provider>
  )
}
