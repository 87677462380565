import _ from 'lodash'
import {
  branch,
  compose,
  fromRenderProps,
  renderNothing,
} from 'recompose'
import { OrganizationProfilesContext } from './OrganizationProfiles'

const withProfiles = compose(
  fromRenderProps(
    OrganizationProfilesContext.Consumer,
    ({
      id,
      type,
      organization_id,
    }) => ({
      id,
      type,
      organization_id,
    })
  ),
  branch(
    ({ id, type, organization_id }) =>
      _.some(
        [id, type, organization_id],
        _.isEmpty
      ),
    renderNothing
  )
)

export default withProfiles
