import { createLazyComponent } from 'helpers/createLazyComponent'
import React, {
  useContext,
} from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import { withProps } from 'recompose'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import { ValidateComponent } from 'routes/routesValidate'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import {
  wishareConfigs,
  wisharePaths,
} from 'views/Wishare/configs'
import Organization from 'views/Wishare/Organization'
import { OrganizationContainerProvider } from 'views/Wishare/Organization/OrganizationContainerContext'
import OrganizationSettingsLazy from 'views/Wishare/Organization/Settings'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'

export const SubDomainPaths =
  Object.freeze({
    NOT_FOUND: '/page-not-found',
  })

const DomainProfileLazy =
  createRequiredLogin()(
    createLazyComponent(() =>
      import(
        'views/Wishare/Organization/Profiles/OrganizationProfiles'
      )
    )
  )

const DomainOrganization = () => {
  const {
    id,
    diff_root,
    isSubDomain = false,
  } = useContext(SubDomainContext)

  return (
    <React.Fragment key={id}>
      <div className="w-full">
        <OrganizationContainerProvider
          item={id}>
          <Switch>
            {isSubDomain && (
              <Route
                path={
                  wisharePaths.organizationProfiles_domain
                }
                render={(props) => (
                  <ValidateComponent
                    withSubDomain={true}
                    component={
                      DomainProfileLazy
                    }
                    path={
                      wisharePaths.organizationProfiles_domain
                    }
                  />
                )}
              />
            )}
            {wishareConfigs.wishareRoutes.map(
              ({
                component: Component,
                ...route
              }) => {
                const _path = route.path
                return (
                  <Route
                    key={_path}
                    {...route}
                    component={
                      diff_root &&
                      [
                        wisharePaths.user,
                        wisharePaths.topic,
                        wisharePaths.category,
                        wisharePaths.userSettings,
                      ].includes(_path)
                        ? undefined
                        : Component
                    }
                    render={(props) => (
                      <ValidateComponent
                        {...route}>
                        <Component
                          {...props}
                        />
                      </ValidateComponent>
                    )}
                  />
                )
              }
            )}
            <Route
              path={
                wisharePaths.organizationSettings_domain
              }
              component={
                OrganizationSettingsLazy
              }
            />
            <Route
              component={withProps({
                redirectUrl: '/',
              })(WishareNotFound)}
              withSubDomain={true}
              path={
                SubDomainPaths.NOT_FOUND
              }
            />
            <Route
              path="*"
              component={Organization}
            />
          </Switch>
        </OrganizationContainerProvider>
      </div>
    </React.Fragment>
  )
}

export default DomainOrganization
