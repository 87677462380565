import {Avatar, Image, Tooltip,} from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import { ROOT_URL } from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import UserContext from '../UserContext'

const UserBanner = React.memo(
  ({ Wrapper = 'div' }) => {
    const { user } = useContext(
      UserContext
    )

    const t = useTranslate()
    const { isSm } = useContext(
      LayoutContext
    )

    const title = getTitle(user)

    const verified = _.get(
      user,
      'certified'
    )

    return (
      <Wrapper className="flex flex-col">
        <Image
          preview={false}
          src={_.get(user, 'cover_photo', `${ROOT_URL}/images/default/purple-bg.jpg`)}
          className="fadeIn animated banner-activity object-cover bg-color-org"
          style={{
            backgroundColor: '#752796',
          }}
        />

        <div className="absolute flex items-center w-full mx-auto">
          <div
            style={{
              background:
                'radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
            }}
            className="flex flex-col items-center justify-center w-full px-3 banner-activity">
            <Avatar
              size={50}
              src={getAvatar(user)}
              className="flex flex-center background-200"
              alt={title}>
                <span className="text-color-200 font-medium">{_.first(title)}</span>
            </Avatar>
            <div className="px-3 text-xl md:text-3xl font-bold max-w-4xl text-center text-white my-0 md:my-3 leading-tight">
              {title}
              {verified === 1 && (
                <Tooltip
                  title={t('verified')}
                  placement="right"
                  color="#87d068">
                  <VerifiedSvg
                    width={
                      isSm
                        ? '1rem'
                        : '1.3rem'
                    }
                    height={
                      isSm
                        ? '1rem'
                        : '1.3rem'
                    }
                    className="ml-1 mb-1 lg:mb-2 zoom-animation"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
)

export default UserBanner
