import React from "react";

export const Animal = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			{...props}
		>
			<path
				fill="#91de7a"
				d="M359.914 275.708V234.5c0-11.379-9.225-20.604-20.604-20.604s-20.604 9.225-20.604 20.604v41.207c0 34.137 27.674 61.811 61.811 61.811 11.379 0 20.604-9.225 20.604-20.604s-9.225-20.604-20.604-20.604c-11.378.001-20.603-9.223-20.603-20.602z"
			></path>
			<path
				fill="#ffc377"
				d="M30.39 234.5h430.104c17.069 0 30.905 13.837 30.905 30.905 0 17.069-13.837 30.905-30.905 30.905H30.39z"
			></path>
			<path
				fill="#ffb864"
				d="M291.329 265.406c0-17.069 13.837-30.905 30.905-30.905H30.39v61.811h291.844c-17.068-.001-30.905-13.838-30.905-30.906z"
			></path>
			<path
				fill="#91de7a"
				d="M41.207 419.284V306.613c0-28.448 23.061-51.509 51.509-51.509h267.198L306.86 60.915H206.036C92.246 60.915 0 153.16 0 266.951v152.333C0 470.49 41.51 512 92.716 512c51.206 0 92.716-41.51 92.716-92.716 0-51.206-41.51-92.716-92.716-92.716-11.379 0-20.604 9.225-20.604 20.604s9.225 20.604 20.604 20.604c28.448 0 51.509 23.061 51.509 51.509s-23.061 51.509-51.509 51.509c-28.447-.001-51.509-23.063-51.509-51.51z"
			></path>
			<path
				fill="#7bce5f"
				d="M92.716 255.104h267.198l-11.258-41.207H226.517c-68.87 0-124.701-55.83-124.701-124.701C40.9 124.99 0 191.189 0 266.951v152.333C0 470.49 41.51 512 92.716 512c51.206 0 92.716-41.51 92.716-92.716 0-51.206-41.51-92.716-92.716-92.716-11.379 0-20.604 9.225-20.604 20.604s9.225 20.604 20.604 20.604c28.448 0 51.509 23.061 51.509 51.509s-23.061 51.509-51.509 51.509-51.509-23.061-51.509-51.509V306.613c0-28.448 23.062-51.509 51.509-51.509z"
			></path>
			<path
				fill="#b0ee96"
				d="M511.92 232.59C501.166 109.168 402.832 10.834 279.41.08c-12.087-1.053-22.514 8.387-22.514 20.52v131.486c0 56.895 46.123 103.018 103.018 103.018H491.4c12.133 0 21.573-10.427 20.52-22.514z"
			></path>
			<path
				fill="#91de7a"
				d="M329.009 152.086V20.6c0-3.792 1.019-7.321 2.788-10.349A254.84 254.84 0 00279.41.081c-12.087-1.053-22.514 8.387-22.514 20.52v131.486c0 56.895 46.123 103.018 103.018 103.018h72.113c-56.895-.001-103.018-46.124-103.018-103.019z"
			></path>
			<path
				fill="#b0ee96"
				d="M256 275.708V234.5c0-11.379-9.225-20.604-20.604-20.604s-20.604 9.225-20.604 20.604v41.207c0 34.137 27.674 61.811 61.811 61.811 11.379 0 20.604-9.225 20.604-20.604s-9.225-20.604-20.604-20.604c-11.378.001-20.603-9.223-20.603-20.602z"
			></path>
			<ellipse
				cx="431.362"
				cy="197.942"
				fill="#ffa5bb"
				rx="30.241"
				ry="24.082"
			></ellipse>
			<path
				fill="#423e4f"
				d="M448.986 182.26a7.499 7.499 0 01-7.5-7.5v-12.362c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5v12.362a7.5 7.5 0 01-7.5 7.5z"
			></path>
			<g>
				<path
					fill="#b0ee96"
					d="M188.011 154.753a29.993 29.993 0 01-22.615-10.3 7.5 7.5 0 0111.309-9.855c2.854 3.276 6.976 5.155 11.307 5.155s8.453-1.879 11.308-5.155a7.501 7.501 0 0111.309 9.855 30.001 30.001 0 01-22.618 10.3zM79.798 189.986a29.994 29.994 0 01-22.615-10.299 7.5 7.5 0 0111.309-9.855 15 15 0 0011.307 5.154c4.331 0 8.453-1.879 11.309-5.154a7.498 7.498 0 0110.581-.726 7.5 7.5 0 01.726 10.581 30.003 30.003 0 01-22.617 10.299zM149.918 235.562a29.994 29.994 0 01-22.615-10.299 7.5 7.5 0 0111.309-9.855 15 15 0 0011.307 5.154c4.332 0 8.453-1.879 11.308-5.154a7.501 7.501 0 0111.309 9.855 30.004 30.004 0 01-22.618 10.299z"
				></path>
			</g>
		</svg>
	);
}

export default Animal;
