import EmptyHolder from 'components/EmptyHolder'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import DatePreview from '../../custom/DatePreview'
import { wishareClassNames } from '../../custom/wishareClassNames'
import HostList from '../../Host/components/HostList'
import TagList from '../../Tags/TagList'
import GivingContext from '../GivingContext'

const GivingInfo = ({
  Wrapper = FlexCol,
}) => {
  const { giving } = useContext(
    GivingContext
  )

  const {
    tags,
    about,
    hosts,
    time_open,
    time_close,
  } = giving || {}

  const about_us = useMemo(
    () =>
      isRawContentEmpty(about)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(about)
            )
          ),
    [about]
  )

  if (_.isEmpty(giving)) return null

  return (
    <Wrapper className="items-start space-y-6">
      <HostList
        items={hosts}
        Wrapper={withProps({
          title: 'co-hosts',
        })(HeaderItemInfo)}
      />

      {about_us ? (
        <HeaderItemInfo title="introduce">
          <div className="px-2 border border-color-50 rounded-lg">
            <Renderer
              editorState={about_us}
            />
          </div>
        </HeaderItemInfo>
      ) : (
        <EmptyHolder title="introduce" />
      )}

      <TagList
        items={tags}
        Wrapper={withProps({
          title: 'keywords',
          className:
            'flex flex-wrap gap-3',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo title="open/close">
        <DatePreview
          values={[
            {
              label: 'start date',
              value: time_open,
            },
            {
              label: 'end date',
              value: time_close,
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
      </HeaderItemInfo>
    </Wrapper>
  )
}

export default GivingInfo
