import {
  getId,
  getType,
} from 'apis/model/base'
import { isDevelop } from 'envs/ForDevelop'
import _ from 'lodash'
import { wisharePaths } from 'views/Wishare/configs'

export default function getLinkToDetail(
  item = {},
  params = {}
) {
  const id = _.get(item, 'id')
  const idname = _.get(item, 'idname')
  const type = getType(item)
  if (!type) {
    if (isDevelop()) {
      console.log(
        '_____item_____',
        item
      )
    }
    return '/'
  }
  switch (type) {
    case 'user':
      return (
        '/user/' +
        _.get(item, 'username')
      )
    case 'poll_question':
      return '/news/poll_question/' + id
    case 'post':
      return '/news/post/' + id
    case 'article':
      return '/news/article/' + idname
    case 'category':
      return '/category/' + idname
    case 'topic':
      return '/t/' + idname
    case 'keyword':
      return '/t/' + idname
    case 'tag':
      return '/t/' + idname
    case 'channel':
      if (
        [
          'user',
          'organization',
        ].includes(
          _.get(item, 'owner._type', '')
        )
      ) {
        return '/channel/' + idname
      }

      return getLinkToDetail(
        _.get(item, 'owner')
      )
    case 'organization':
      return '/organization/' + idname
    case 'dataset':
      return '/data/' + idname
    // Begin Wishare
    case 'activity':
      return '/activity/' + idname
    case 'donation_event':
      return '/donation/' + idname
    case 'giving_event':
      return '/giving/' + idname
    case 'recruitment_event':
      return '/jobs/' + idname
    case 'donor':
    case 'staff':
    case 'beneficiary':
      const organization_id = getId(
        _.get(item, 'organization')
      )
      return (
        organization_id === params?.id
          ? wisharePaths.organizationProfiles_domain
          : wisharePaths.organizationProfiles
      )
        .replace(':id', organization_id)
        .replace(':sub_id', id)
        .replace(':sub_type', type)
    // End Wishare
    default:
      return '/' + type + '/' + idname
  }
}
