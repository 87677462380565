import {
  Avatar,
  Button,
  Divider,
  Input,
  Modal,
} from 'antd'
import { channel_add_Api } from 'apis'
import { channelModel } from 'apis/model'
import {
  getId,
  getType,
} from 'apis/model/base'
import { FollowButton } from 'components/Feed/FollowButton'
import ApiInfoForm from 'components/form/ApiInfoForm'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import CreateChannelForm from 'views/CreateChannelForm'
import { Null } from 'views/Shared'
import { modalWidth } from 'views/Wishare/configs'
import { WishareEntities } from 'views/Wishare/enums'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'
import EmptyHolder from '../../../components/EmptyHolder'
import { useDebounceSearch } from '../../Wishare/custom/DebounceSearch'
import renderChannelModalItem from '../functions/renderChannelModalItem'

export const ChannelCompactItem = ({
  item,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const owner = _.get(item, 'owner', {})

  return (
    <div className="relative w-full border border-color-50 rounded-lg background">
      <OverlayItemBanner
        type={WishareEntities.CHANNEL}
      />
      <div className="h-7" />
      <div className="flex items-center px-3 py-2">
        {!isSm && (
          <Avatar className="background-100" src={getAvatar(owner)}>
            <span className="capitalize text-color-100">
              {_.first(getTitle(owner))}
            </span>
          </Avatar>
        )}
        <div className="flex-col flex-1 ml-0 md:ml-3 mr-3">
          <Link
            to={getLinkToDetail(item)}
            className="cursor-pointer font-bold leading-tight max-lines-1">
            {getTitle(item)}
          </Link>
          <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
            {getTitle(owner)}
          </span>
        </div>
        <FollowButton
          size={
            isSm ? 'small' : 'default'
          }
          item={item}
        />
      </div>
    </div>
  )
}

const ChannelPicker = ({
  linkTo,
  children,
  onFinished,
  ...props
}) => {
  const t = useTranslate()

  const history = useHistory()

  const [isToggle, toggle] =
    useToggle(false)

  const goTo = (item) => {
    if (linkTo) {
      linkTo(item)
    } else {
      const detailLink =
        channelModel.getLinkToDetail(
          item
        )
      history.push(detailLink)
    }
  }

  return (
    <>
      {children(() => toggle())}
      <Modal
        width={620}
        className="custom-modal"
        closeIcon={null}
        closable={true}
        maskClosable={true}
        onCancel={toggle}
        okText={t('choose')}
        cancelText={t('cancel')}
        okButtonProps={{
          className: 'rounded-lg no-shadow no-text-shadow',
        }}
        cancelButtonProps={{
          className: 'rounded-lg no-shadow no-text-shadow border-none',
        }}
        visible={isToggle}
        title={
          <div className="text-center text-xl text-color-000 font-bold uppercase mb-3">
            {t('Choose the channel for posting')}
          </div>
        }>
        <div className="space-y-3">
          <ChannelPickerContent
            onItemClick={(item) => {
              Modal.destroyAll()
              goTo(item)
            }}
            onFinished={onFinished}
            {...props}
          />
        </div>
      </Modal>
    </>
  )
}

const ChannelPickerContent = ({
  onFinished = Null,
  onItemClick = (item) => null,
  ...props
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const login = useContext(LoginContext)

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  return (
    <React.Fragment>
      <div className="flex flex-col py-2">
        <Button
          block={true}
          type="primary"
          onClick={toggle}
          className="rounded-lg no-shadow no-text-shadow">
          {t('Create new channel')}
        </Button>

        <Divider className="my-3 ">
          <span className="text-color-500 text-xs italic">
            {t('or')}
          </span>
        </Divider>

        <Input.Search
          className="input-search"
          placeholder={t(
            'search news channel'
          )}
          onSearch={(value) =>
            onSearch(value)
          }
        />
      </div>
      <LazyPagination
        key={[
          props.apiInfo.path,
          keyword,
        ].join('-')}
        {...props}
        values={{
          keyword,
        }}
        renderEmpty={() => (
          <EmptyHolder />
        )}
        renderItem={(item, index) => {
          return renderChannelModalItem(
            item,
            {
              t,
              index,
              onItemClick,
            }
          )
        }}
      />
      <Modal
        width={modalWidth.large}
        className="custom-modal"
        onCancel={toggle}
        visible={isToggle}
        destroyOnClose
        footer={null}>
        <ApiInfoForm
          onSuccess={([
            __,
            { response },
          ]) => {
            onFinished(response.data)
          }}
          apiInfo={channel_add_Api}
          initialErrors={{
            title: t('required'),
          }}
          validate={(values) => {
            let errors = {}

            if (
              !_.get(values, 'title')
            ) {
              errors = {
                ...errors,
                title: t('required'),
              }
            }

            if (
              _.get(
                values,
                'title.length'
              ) < 6
            ) {
              errors = {
                ...errors,
                title: t(
                  'name required more than {1} characters',
                  {
                    1: 6,
                  }
                ),
              }
            }

            if (
              !_.get(values, 'owner') &&
              !_.get(values, 'owner_id')
            ) {
              errors = {
                ...errors,
                owner: t('required'),
              }
            }

            return errors
          }}
          initialValues={{
            privacy: 1,
            owner_id: getId(login),
            owner_type: getType(login),
            default_owner_id:
              getId(login),
            default_owner_type:
              getType(login),
          }}>
          {({ ...props }) => {
            return (
              <CreateChannelForm
                {...props}
              />
            )
          }}
        </ApiInfoForm>
      </Modal>
    </React.Fragment>
  )
}

export default ChannelPicker
