import classNames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

const CustomMenuHeader = ({
  icon,
  title,
  onClick,
}) => {
  const t = useTranslate()
  return (
    <div className="flex items-center justify-between mb-3">
      <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
        {t(title)}
      </span>
      <div
        onClick={onClick}
        className={classNames(
          icon
            ? 'cursor-pointer py-1 px-2'
            : 'flex-1'
        )}>
        {icon}
      </div>
    </div>
  )
}

export default CustomMenuHeader
