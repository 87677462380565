import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { staffSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const staffRosterSchema =
  new schema.Entity(
    'staff_roster',
    {
      staff: staffSchema,
    },
    {
      idAttribute: 'id',
    }
  )

export const staff_getStaffs_Api = {
  backend: `Route::get('staffs/organization/{org_id}/gets', 'StaffController@getStaffs');`,
  root: API_ROOT_URL,
  path: '/staffs/organization/:organization_id/gets',
  method: 'GET',
  paramsFields: [
    'keyword',
    'sort_by',
    'active',
    'employee_type',
  ],
  schema: staffSchema,
  transform: createListTransform(
    staffSchema
  ),
}

export const staff_getStaffsOfTheOwner_Api =
  {
    backend: `Route::get('staffs/{prop}/{id}/get-staffs-of-the-owner', 'StaffController@getStaffsOfTheOwner');`,
    root: API_ROOT_URL,
    path: '/staffs/:prop/:id/get-staffs-of-the-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
      // 'active',
      // 'employee_type',
    ],
    schema: staffSchema,
    transform: createListTransform(
      staffSchema
    ),
  }

export const staff_getStaffById_Api = {
  backend: `Route::get('staffs/{id}/get', 'StaffController@getStaffById');`,
  root: API_ROOT_URL,
  path: '/staffs/:id/get',
  method: 'GET',
  schema: staffSchema,
  transform: createTransform(
    staffSchema,
    'data'
  ),
}

export const staff_manageStaffAdd_Api =
  {
    backend: `Route::post('staffs/organization/{org_id}/create', 'StaffController@manageStaffAdd');`,
    root: API_ROOT_URL,
    path: '/staffs/organization/:organization_id/create',
    method: 'POST',
    schema: staffSchema,
    transform: createTransform(
      staffSchema,
      'data'
    ),
  }

export const staff_manageStaffEdit_Api =
  {
    backend: `Route::post('staffs/{id}/update', 'StaffController@manageStaffEdit');`,
    root: API_ROOT_URL,
    path: '/staffs/:id/update',
    method: 'POST',
    schema: staffSchema,
    transform: createTransform(
      staffSchema,
      'data'
    ),
  }

export const staff_manageStaffDelete_Api =
  {
    backend: `Route::post('staffs/{id}/delete', 'StaffController@manageStaffDelete');`,
    root: API_ROOT_URL,
    path: '/staffs/:id/delete',
    method: 'POST',
    schema: staffSchema,
    transform: createTransform(
      staffSchema,
      'data'
    ),
  }

export const staff_getStaffRosters_Api =
  {
    backend: `Route::get('staff-rosters/staffs/{staff_id}/gets', 'StaffController@getStaffRosters');`,
    root: API_ROOT_URL,
    path: '/staff-rosters/staffs/:staff_id/gets',
    method: 'GET',
    schema: staffRosterSchema,
    transform: createListTransform(
      staffRosterSchema
    ),
  }

export const staff_manageStaffRosterDelete_Api =
  {
    backend: `Route::post('staff-rosters/{id}/delete', 'StaffController@manageStaffRosterDelete');`,
    root: API_ROOT_URL,
    path: '/staff-rosters/:id/delete',
    method: 'POST',
    schema: staffRosterSchema,
    transform: createTransform(
      staffRosterSchema,
      'data'
    ),
  }

export const staff_manageStaffRosterEdit_Api =
  {
    backend: `Route::post('staff-rosters/{id}/update', 'StaffController@manageStaffRosterEdit');`,
    root: API_ROOT_URL,
    path: '/staff-rosters/:id/update',
    method: 'POST',
    schema: staffRosterSchema,
    transform: createTransform(
      staffRosterSchema,
      'data'
    ),
  }

export const staff_manageStaffRosterAdd_Api =
  {
    backend: `Route::post('staff-rosters/staffs/{staff_id}/create', 'StaffController@manageStaffRosterAdd');`,
    root: API_ROOT_URL,
    path: '/staff-rosters/staffs/:staff_id/create',
    method: 'POST',
    schema: staffRosterSchema,
    transform: createTransform(
      staffRosterSchema,
      'data'
    ),
  }

export const staff_getStaffRosterById_Api =
  {
    backend: `Route::get('staff-rosters/{id}/get', 'StaffController@getStaffRosterById');`,
    root: API_ROOT_URL,
    path: '/staff-rosters/:id/get',
    method: 'GET',
    schema: staffRosterSchema,
    transform: createTransform(
      staffRosterSchema,
      'data'
    ),
  }

export const staff_getRecruitmentStats_Api =
  {
    backend: `Route::get('staffs/organization/{org_id}/get-recruitment-stats', 'StaffController@getRecruitmentStats')`,
    root: API_ROOT_URL,
    path: '/staffs/organization/:organization_id/get-recruitment-stats',
    method: 'GET',
    paramsFields: ['period'],
  }

export const staff_getTopStaffByDuration_Api =
  {
    backend: `Route::get('staffs/organization/{org_id}/get-top-staff-by-duration', 'StaffController@getTopStaffByDuration');`,
    root: API_ROOT_URL,
    path: '/staffs/organization/:organization_id/get-top-staff-by-duration',
    method: 'GET',
    paramsFields: ['period'],
  }

export const staff_getTopLocationByStaffNumber_Api =
  {
    backend: `Route::get('staffs/organization/{org_id}/get-top-location-by-staffs', 'StaffController@getTopLocationByStaffNumber');`,
    root: API_ROOT_URL,
    path: '/staffs/organization/:organization_id/get-top-location-by-staffs',
    method: 'GET',
    paramsFields: ['period'],
  }

export const staff_getStaffsOfTheSameOwner_Api =
  {
    backend: `Route::get('staffs/organization/{organization_id}/get-staffs-of-the-same-owner', 'StaffController@getStaffsOfTheSameOwner');`,
    root: API_ROOT_URL,
    path: '/staffs/organization/:organization_id/get-staffs-of-the-same-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
      'staff_id',
      'owner_id',
      'owner_type',
    ],
    schema: staffSchema,
    transform: createListTransform(
      staffSchema
    ),
  }
