import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useToggle } from 'react-use'
import { Null } from 'views/Shared'

const styles = {
  normal: {
    zIndex: 1,
  },
  overlay: {
    zIndex: 2,
  },
}

const DefaultOverlay = ({
  children,
  className,
  Wrapper = 'div',
  ...props
}) => (
  <Wrapper
    className={classNames(
      'absolute top-0',
      className
    )}
    {...props}>
    {children}
  </Wrapper>
)

const OverlayBanner = ({
  style,
  children,
  className,
  onClick = Null,
  Wrapper = 'div',
}) => {
  return (
    <Wrapper
      style={style}
      onClick={onClick}
      className={classNames(
        'relative',
        className
      )}>
      {_.isFunction(children)
        ? children(DefaultOverlay)
        : children}
    </Wrapper>
  )
}

export const OverlayBannerToggle = ({
  onClick,
  children,
  className,
  overlay = null,
  defaultValue = false,
  uncontrolled = false,
}) => {
  const [isToggle, toggle] = useToggle(
    defaultValue
  )

  const handleClick = () => {
    onClick()
    if (!!!uncontrolled) {
      toggle()
    }
  }

  return (
    <OverlayBanner
      onClick={handleClick}
      style={styles.normal}>
      {(Overlay = Null) => (
        <>
          {isToggle && (
              <div
                  style={{zIndex: 1, background: 'rgba(0,0,0,0.5)'}}
                  className="absolute top-0 w-28 h-28 rounded-lg"
              />
          )}
          {isToggle && (
              <Overlay
                  className={classNames(
                      'CustomOverlay',
                      className
                  )}
                  style={styles.overlay}>
                {overlay}
              </Overlay>
          )}
          {_.isFunction(children)
            ? children([
                isToggle,
                toggle,
              ])
            : children}
        </>
      )}
    </OverlayBanner>
  )
}

export default OverlayBanner
