const env = 'BETA'
const ENV_LABEL = 'DEV'
const IS_CODOVA = false
const API_ROOT_URL =
  'https://test123456.wishare.com/api/v1'
const ROOT_URL =
  'https://test123456.wishare.com'
const STORAGE_URL =
  'https://test123456.wishare.com/storage'
const STORAGE_GEO_VN_DATA_URL =
  'https://wishare.com/storage/geodata/json/vietnamLow.json'
const STORAGE_GEO_US_DATA_URL =
  'https://wishare.com/storage/geodata/json/usaLow.json'
const SOCKET_URL =
  'https://socket-test123456.wishare.com'
const FB_ID = '807464506358167'
const APPLE_CLIENT_ID =
  'com.wishare.app.service'
const GG_ID =
  '593867816357-nik3k3njgim7ob1pud4pvv6g4gc0ji70.apps.googleusercontent.com'
const ANDROID_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=wishare.com'
const APPLE_ITUNES_LINK =
  'https://itunes.apple.com/us/app/wishare-app/id1401245325'
const GOOGLE_API_KEY =
  'AIzaSyAfavIsdiYGeMf_CBuMr463yhUEHEUwx_4'
const FIREBASE_MESSAGING_SENDER_ID =
  '713485216987'
const CONTACT_EMAIL =
  'contact@wishare.com'
const CONTACT_PHONE = '090 9 31 35 18'
const GOOGLE_DATA_AD_CLIENT = ''
const GOOGLE_DATA_AD_ENABLED = false
const GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT =
  ''
const GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT =
  ''
const GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT =
  ''
const APP_COPYRIGHT =
  'Copyright ©2023 Wishare'

export {
  env,
  ENV_LABEL,
  IS_CODOVA,
  ROOT_URL,
  API_ROOT_URL,
  STORAGE_URL,
  SOCKET_URL,
  STORAGE_GEO_VN_DATA_URL,
  STORAGE_GEO_US_DATA_URL,
  FB_ID,
  GG_ID,
  ANDROID_PLAY_LINK,
  APPLE_ITUNES_LINK,
  GOOGLE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  GOOGLE_DATA_AD_CLIENT,
  GOOGLE_DATA_AD_ENABLED,
  GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT,
  GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT,
  GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT,
  APP_COPYRIGHT,
  APPLE_CLIENT_ID,
}
