import Icon, {
  ShareAltOutlined,
  TwitterOutlined,
} from '@ant-design/icons'
import {
  callNativeShare,
  createFacebookShareLink,
  createTwitterShareLink,
} from 'helpers'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { FaFacebookF } from 'react-icons/fa'
import { HeaderButton } from 'views/Organization/components/PageHeader'
import { getLinkToShare } from 'views/Wishare/wishareModel'
import ActivityContext from '../Activity/ActivityContext'
import DonationContext from '../Donation/DonationContext'
import {
  fromWishareEntity,
  WishareEntities,
} from '../enums'
import GivingContext from '../Giving/GivingContext'
import RecruitmentContext from '../Recruitment/RecruitmentContext'
import BeneficiaryContext from '../Beneficiary/BeneficiaryContext'

const createShareWidget =
  (type, Context) =>
  ({ title = 'share' }) => {
    const t = useTranslate()

    const context = useContext(Context)

    const { contextName } =
      fromWishareEntity(type)

    const entity = _.get(
      context,
      contextName
    )

    const shareUrl =
      getLinkToShare(entity)

    if (_.isEmpty(shareUrl)) return null

    return (
      <div className="relative flex items-center border-b pb-1">
        <div className="uppercase text-sm font-semibold text-color-300 tracking-wide flex-1">
          {t(title)}
        </div>
        <div className="flex">
          <HeaderButton
            component="a"
            href={createFacebookShareLink(
              shareUrl
            )}
            target="_blank"
            width={34}
            height={34}
            icon={
              <Icon
                component={FaFacebookF}
              />
            }
          />
          <HeaderButton
            component="a"
            href={createTwitterShareLink(
              shareUrl
            )}
            target="_blank"
            width={34}
            height={34}
            icon={
              <Icon
                component={
                  TwitterOutlined
                }
              />
            }
          />
          <HeaderButton
            onClick={() => {
              callNativeShare({
                title: getTitle(entity),
                url: shareUrl,
              })
            }}
            width={34}
            height={34}
            icon={
              <Icon
                component={
                  ShareAltOutlined
                }
              />
            }
          />
        </div>
      </div>
    )
  }

export const WishShareWidget =
  createShareWidget(
    WishareEntities.BENEFICIARY,
    BeneficiaryContext
  )

export const ActivityShareWidget =
  createShareWidget(
    WishareEntities.ACTIVITY,
    ActivityContext
  )

export const DonationShareWidget =
  createShareWidget(
    WishareEntities.DONATION,
    DonationContext
  )

export const GivingShareWidget =
  createShareWidget(
    WishareEntities.GIVING,
    GivingContext
  )

export const RecruitmentShareWidget =
  createShareWidget(
    WishareEntities.RECRUITMENT,
    RecruitmentContext
  )

export default createShareWidget
