import { Avatar, Col, Row } from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { Link } from 'react-router-dom'
import CustomLink from 'views/Wishare/custom/CustomLink'
import categoryIcons from '../../conts/categoryIcons'
import getAvatar from '../../helpers/getAvatar'
import useTranslate from '../../modules/local/useTranslate'

export const CategoriesXS = ({
  categories,
}) => {
  return (
    <div className="flex items-center flex-wrap gap-1">
      {categories.map(
        (
          {
            language,
            alt_idname,
            ...category
          },
          index
        ) => {
          return (
            <Link
              key={index}
              to={[
                getLinkToDetail(
                  category
                ),
                new URLSearchParams({
                  ref: alt_idname,
                  lang: language,
                }).toString(),
              ].join('?')}
              style={{
                padding: '2px 8px',
                borderRadius: '999px',
              }}
              className="flex flex-center background-100 hover:background-white border border-color-50 hover:border-primary text-color-300 no-underline">
              <span className="text-xs font-medium italic">
                {getTitle(category)}
              </span>
            </Link>
          )
        }
      )}
    </div>
  )
}

const bg_color_17sdgcodes = {
  0: '#1f97d4',
  1: '#e5233d79',
  2: '#dda73a79',
  3: '#4ca14679',
  4: '#c7212f79',
  5: '#ef402d79',
  6: '#27bfe679',
  7: '#fbc41279',
  8: '#a31c4479',
  9: '#f26a2e79',
  10: '#e0148379',
  11: '#f89d2a79',
  12: '#bf8d2c79',
  13: '#407f4679',
  14: '#1f97d479',
  15: '#59ba4779',
  16: '#136a9f79',
  17: '#14496b79',
}

export const ImpactCategory = ({
  item,
}) => {
  const t = useTranslate()

  const { alt_idname, language } =
    item || {}

  const Icon = _.get(
    categoryIcons,
    item.idname
  )

  return (
    <CustomLink
      withSubDomain={false}
      to={[
        getLinkToDetail(item),
        new URLSearchParams({
          ref: alt_idname,
          lang: language,
        }).toString(),
      ].join('?')}
      className="no-underline">
      <div className="flex items-center gap-3">
        {Icon ? (
            <div
                style={{
                  width: '24px',
                  height: '24px',
                }}>
              <Icon />
            </div>
        ) : (
            <Avatar
                size="small"
                shape="square"
                className="rounded"
                src={getAvatar(item)}
            />
        )}
        <div className="text-color-200 hover:text-primary font-medium capitalize max-lines-1">
          {t(getTitle(item).toLowerCase())}
        </div>
      </div>
      {/*<Row className="flex items-center m-row-0">*/}
      {/*  <Col*/}
      {/*    className="p-col-0"*/}
      {/*    span={3}>*/}
      {/*    {Icon ? (*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          width: '24px',*/}
      {/*          height: '24px',*/}
      {/*        }}>*/}
      {/*        <Icon />*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <Avatar*/}
      {/*        size="small"*/}
      {/*        shape="square"*/}
      {/*        className="rounded"*/}
      {/*        src={getAvatar(item)}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </Col>*/}
      {/*  <Col className="p-col-0" span={21}>*/}
      {/*    <div className="text-color-200 hover:text-primary font-medium capitalize max-lines-1">*/}
      {/*      {t(getTitle(item).toLowerCase())}*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </CustomLink>
  )
}

const ImpactCategoriesWrapper = ({
  title,
  children,
}) => {
  if (_.isEmpty(title)) return children
  return (
    <div className="flex flex-col">
      <Translate>
        {(t) => (
          <span className="flex items-center justify-start py-2 text-gray-600 font-bold text-xl uppercase">
            {t(title)}
          </span>
        )}
      </Translate>
      {children}
    </div>
  )
}

export const ImpactCategories = ({
  title,
  categories,
  Wrapper = ImpactCategoriesWrapper,
}) => {
  const t = useTranslate()

  if (_.isEmpty(categories)) return null

  return (
    <Wrapper {...{ title }}>
      <div className="flex flex-col gap-2">
        {categories.map((item, index) => (
            <ImpactCategory
              key={index}
              item={item}
            />
        ))}
      </div>
    </Wrapper>
  )
}
