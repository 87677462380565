import { DatePicker } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React from 'react'
import {
  IoArrowForwardOutline,
  IoCalendarOutline,
} from 'react-icons/io5'
import { mapProps } from 'recompose'
import { Null } from 'views/Shared'
import { wishareConfigs } from '../configs'

export const createDateRangerPicker = (
  [
    startName = 'start_at',
    endName = 'end_at',
  ],
  props
) =>
  mapProps(
    ({
      form,
      mode,
      dateFormat = wishareConfigs.dateFormat,
      allowClear = true,
    }) => {
      const {
        values = {},
        setValues = Null,
      } = form || {}
      const endDate = _.get(
        values,
        endName
      )
      const startDate = _.get(
        values,
        startName
      )

      const handleChange = (dates) => {
        if (!!!dates) {
          setValues({
            ...values,
            [startName]: null,
            [endName]: null,
          })
          return
        }
        const [from, to] = dates
        const [start_at, end_at] = [
          !!from
            ? moment(new Date(from)) //from.format(dateFormat)
            : null,
          !!to
            ? moment(new Date(to)) //to.format(dateFormat)
            : null,
        ]
        setValues({
          ...values,
          [startName]: start_at,
          [endName]: end_at,
        })
      }

      const t = useTranslate()

      return {
        mode,
        allowClear,
        className: 'w-full rounded-md',
        defaultValue: [
          !!startDate
            ? moment(
                new Date(startDate)
              )
            : null,
          !!endDate
            ? moment(new Date(endDate))
            : null,
        ],
        showTime: false,
        disabled: false,
        format: dateFormat,
        onChange: handleChange,
        allowEmpty: [true, true],
        placeholder: [
          t('start date'),
          t('end date'),
        ],
        suffixIcon: (
          <IoCalendarOutline />
        ),
        nextIcon: (
          <IoArrowForwardOutline />
        ),
        ...props,
      }
    }
  )(DatePicker.RangePicker)
