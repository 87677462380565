import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import {
  normalize,
  schema,
} from 'normalizr'
import createPath from '../helpers/createPath'
import { emptyObject } from '../helpers/emptyObjects'
import { baseFieldTypes } from './base/baseFieldTypes'
import { eventSchema } from './schema/event'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const fields = [
  'user_id', // auto fill = creator
  'owner_id', // user or organization
  'owner_type', // user or organization
  'categories', // user or organization
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:events,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  user_id: baseFieldTypes.auto,
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
}

// Route::post('events/add', 'EventController@store');
export const event_add_Api = {
  backend: `Route::post('events/add', 'EventController@store');`,
  root: API_ROOT_URL,
  path: '/events/add',
  method: 'POST',
  fields: ['owner_id', 'owner_type'],
  fieldTypes,
  transform: (
    response,
    { values = {} }
  ) => {
    let error,
      errorMessages,
      entities,
      result,
      success
    const { status } =
      response || emptyObject
    const data = _.get(response, 'data')
    const { owner_id, owner_type } =
      values
    switch (status) {
      case 200:
        success = true
        const {
          entities: e,
          result: r,
        } = normalize(
          data,
          new schema.Entity(
            'organizations',
            {},
            {
              idAttribute: () =>
                owner_id,
              processStrategy: (
                entities
              ) => {
                return {
                  idname: owner_id,
                  events: [data],
                }
              },
            }
          )
        )
        entities = e
        result = r
        break

      default:
        error = true
        errorMessages = response.data
        break
    }

    return {
      ...response,
      response,
      error,
      errorMessages,
      success,
      status,
      entities,
      result,
    }
  },
}

// Route::post('events/{id}/edit', 'EventController@update');
export const event_edit_Api = {
  backend: `Route::post('events/{id}/edit', 'EventController@update');`,
  root: API_ROOT_URL,
  path: '/events/:id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    eventSchema,
    'data'
  ),
}

// Route::post('events/{id}/delete', 'EventController@delete');
export const event_delete_Api = {
  backend: ` Route::post('events/{id}/delete', 'EventController@delete');`,
  root: API_ROOT_URL,
  path: '/events/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    eventSchema,
    'data'
  ),
}

// Route::get('events/{id}/get', 'EventController@getById');
export const event_getById_Api = {
  backend: `Route::get('events/{id}/get', 'EventController@getById');`,
  root: API_ROOT_URL,
  path: '/events/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  transform: createTransform(
    eventSchema,
    'data'
  ),
}

//  Route::get('events/{type}/{id}/gets', 'EventController@getEventsByProps');
export const event_getEventsByProps_Api =
  {
    backend: `Route::get('events/{type}/{id}/gets', 'EventController@getEventsByProps');`,
    root: API_ROOT_URL,
    path: '/events/:type/:id/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    transform: createListTransform(
      eventSchema
    ),
    schema: eventSchema,
  }

export const create_event_event_getEventsByProps_Api =
  ({ query, ...props } = {}) => ({
    backend: ``,
    root: API_ROOT_URL,
    path: createPath({
      path: '/events/:type/:id/gets',
      query,
    }),
    method: 'GET',
    fields,
    fieldTypes,
    schema: eventSchema,
    transform: createListTransform(
      eventSchema
    ),
    ...props,
  })

// Route::get('events/category/{category_id}/gets', 'EventController@getEventsByCategory');
export const event_getEventsByCategory_Api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/events/category/:category_id/gets',
    method: 'GET',
    paramsFields: ['exclude_promos'],
    transform: createListTransform(
      eventSchema
    ),
    schema: eventSchema,
  }
