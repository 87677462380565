import { Radio } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
} from 'react'
import { Null } from 'views/Shared'

const RecruitmentsAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    status: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <Radio.Group
        name="status"
        buttonStyle="solid"
        className="flex items-center flex-1 justify-end lg:w-auto"
        onChange={(event) => {
          const { name, value } =
            event.target
          handleChange(name, value)
        }}
        defaultValue={
          filter.status || undefined
        }>
        <Radio.Button value={undefined}>
          {t('all')}
        </Radio.Button>
        <Radio.Button value={'1'}>
          {t('active')}
        </Radio.Button>
        <Radio.Button value={'9'}>
          {t('closed')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default RecruitmentsAdvancedFilter
