import { API_ROOT_URL } from 'envs/_current/config'

// Route::get('apps/get-meta-statistics', 'AppController@getMetaTotalSystems');
export const apps_getMetaTotalSystems_Api = {
  name: 'apps_getMetaTotalSystems',
  backend: ``,
  root: API_ROOT_URL,
  path: '/apps/get-meta-statistics',
  method: 'GET'
}

// Route::get('apps/typical-items', 'AppController@getTypicalItems');
export const apps_getTypicalItems_Api = {
  name: 'apps_getTypicalItems_Api',
  backend: ``,
  root: API_ROOT_URL,
  path: '/apps/typical-items',
  method: 'GET',
  paramsFields: ['types']
}
