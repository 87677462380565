import { VideoEmbed } from 'components/Feed/VideoEmbed'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import DonationContext from '../Donation/DonationContext'
import {
  fromWishareEntity,
  WishareEntities,
} from '../enums'
import GivingContext from '../Giving/GivingContext'
import RecruitmentContext from '../Recruitment/RecruitmentContext'

const createVideoPlayer =
  (Context, type) =>
  ({
    Wrapper = 'div',
    url_field = 'video_url',
    ...props
  }) => {
    const context = useContext(Context)

    const { contextName } =
      fromWishareEntity(type)

    const entity = _.get(
      context,
      contextName
    )

    const url = _.get(entity, url_field)

    if (_.isEmpty(url)) return null

    return (
      <Wrapper className="w-full">
        <LayoutContext.Consumer>
          {({ isSm }) => (
            <VideoEmbed
              style={{
                padding: 0,
                borderRadius: 0,
              }}
              height={
                isSm ? '15rem' : '25rem'
              }
              url={url}
              isShowPlayer={true}
              {...props}
            />
          )}
        </LayoutContext.Consumer>
      </Wrapper>
    )
  }

export const DonationVideo =
  createVideoPlayer(
    DonationContext,
    WishareEntities.DONATION
  )

export const GivingVideo =
  createVideoPlayer(
    GivingContext,
    WishareEntities.GIVING
  )

export const RecruitmentVideo =
  createVideoPlayer(
    RecruitmentContext,
    WishareEntities.RECRUITMENT
  )

export default createVideoPlayer
