import _ from 'lodash'
import React, {useContext} from 'react'
import {useLocation} from "react-use";
import useTranslate from "../../../../modules/local/useTranslate";
import {useDrawerDetector} from "../../../../components/layouts/Wishare/WishareSiderMenu";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {Null, renderIf,} from 'views/Shared'
import {IoCaretDownCircleOutline} from "react-icons/io5";

const WorkspaceSubHeader = ({
                              Wrapper = 'div',
                              routes,
                            }) => {
  const currentLocation = useLocation();
  const t = useTranslate();
  const {
    toggle = Null,
    hasDrawer = false,
  } = useDrawerDetector()

  const {visibleLeftDrawer} =
    useContext(LayoutContext)

  const showDrawer = Boolean(
    visibleLeftDrawer && hasDrawer
  )

  const currentRoute = _.first(routes.filter(e => currentLocation.pathname.startsWith("/workspace/" + e.key)))

  return !!currentRoute && (
    <Wrapper className="flex items-center gap-3 mt-6 border-b pb-1">
      <span className="flex-1 text-color-000 font-bold text-lg uppercase leading-tight">
        {t(currentRoute.label)}
      </span>
      {renderIf(
        showDrawer,
        <IoCaretDownCircleOutline
          className="text-primary"
          size={20}
          onClick={() => toggle(true)}/>,
      )}
    </Wrapper>
  )
}

export default WorkspaceSubHeader
