import { wisharePaths } from 'views/Wishare/configs'
import Main from './Main.lazy'

const paths = Object.values(
  wisharePaths
)

export default {
  path: ['/', ...paths],
  withSubDomain: true,
  component: Main,
  // exact: true,
}
