import React from 'react'
import Loadable from 'react-loadable'
import LoadingPage from "../../../../LoadingPage";

const DonationEventReportLazy = Loadable({
  loader: () => import('./DonationEventReport'),
  loading: LoadingPage
})

const DonationEventReport = (props) => (
  <DonationEventReportLazy {...props} />
)

export default DonationEventReport
