import React from 'react'
import { renderOwnChild } from 'views/Shared'
import OrganizationProfilesNav from './OrganizationProfilesNav'

const OrganizationProfilesContent = ({
  Wrapper = renderOwnChild,
}) => (
  <Wrapper>
    <div className="space-y-3">
      <OrganizationProfilesNav />
    </div>
  </Wrapper>
)

export default OrganizationProfilesContent
