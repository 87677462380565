import { Spin } from 'antd'
import { categories_getTopTags_api } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import Translate from 'modules/local/Translate'
import React from 'react'
import CustomMenuHeader from 'views/Wishare/custom/CustomMenuHeader'
import { renderHashTag } from 'views/Wishare/Tags/renderHashTag'

const CategoryTags = () => {
  const apiInfo =
    categories_getTopTags_api

  const lookupKey = [apiInfo.path].join(
    '/'
  )

  return (
    <CustomLazyPaginationWrapper
      auto={false}
      sample={30}
      id={lookupKey}
      key={lookupKey}
      apiInfo={apiInfo}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Spin className="m-auto" />
        </div>
      )}
      renderItem={renderHashTag}
      renderEmpty={() => (
        <Translate>
          {(t) => (
            <EmptyHolder
              subTitle={t('empty')}
              title={null}
            />
          )}
        </Translate>
      )}
      renderWrap={(children) => (
        <div className="flex flex-col">
          <CustomMenuHeader title="keywords" />
          <div className="flex flex-wrap gap-1">
            {children}
          </div>
        </div>
      )}
    />
  )
}

export default CategoryTags
