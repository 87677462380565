import classNames from 'classnames'
import React from 'react'

const renderDefaultTitle = (title) => (
  <div className="flex m-auto text-white">
    {title || 'empty'}
  </div>
)

const BoxPlaceholder = ({
  style,
  title,
  children,
  className,
  unstyle = false,
  renderTitle = renderDefaultTitle,
  ...props
}) => {
  const color = Math.floor(
    Math.random() * 16777215
  ).toString(16)
  return (
    <center
      style={{
        backgroundColor: `#${color}`,
        ...style,
      }}
      className={classNames(
        !!!unstyle &&
          'flex w-full h-64',
        className
      )}>
      {renderTitle(title)}
      {children}
    </center>
  )
}

export { BoxPlaceholder }
