import { schema } from 'normalizr'

export const medalSchema =
  new schema.Entity(
    'medals',
    {},
    {
      idAttribute: 'id',
    }
  )

export const medalAwardSchema =
  new schema.Entity(
    'medal_awards',
    {},
    {
      idAttribute: 'id',
    }
  )
