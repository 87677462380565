import _ from 'lodash'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { BeneficiaryHistory } from '../Beneficiary/holders/BeneficiaryHistory'
import { DonorMyDonationList } from '../Donor/components/DonorMyDonationList'
import { WishareEntities } from '../enums'
import OrganizationActivityList from './holders/OrganizationActivityList'
import OrganizationChannelList from './holders/OrganizationChannelList'
import OrganizationContactInfo from './holders/OrganizationContactInfo'
import OrganizationDonationList from './holders/OrganizationDonationList'
import OrganizationEventList from './holders/OrganizationEventList'
import OrganizationGetHosts from './holders/OrganizationGetHosts'
import OrganizationGivingList from './holders/OrganizationGivingList'
import OrganizationInfo from './holders/OrganizationInfo'
import OrganizationNews from './holders/OrganizationNews'
import OrganizationRecruitmentList from './holders/OrganizationRecruitmentList'
import { OrganizationProfilesContext } from './Profiles/OrganizationProfiles'
import {
  OrganizationProfilesDonorMedalRewards,
  OrganizationProfilesStaffRosters,
} from './Profiles/OrganizationProfilesComponents'
import OrganizationProfilesInfo from './Profiles/OrganizationProfilesInfo'

export const organizationProfilesEntities =
  [
    {
      path: 'overview',
      component:
        OrganizationProfilesInfo,
      breadcrumbName: (t) =>
        t('overview'),
    },
    {
      path: 'staff-list',
      isInvisible: ({ prop }) =>
        prop !== WishareEntities.STAFF,
      component:
        OrganizationProfilesStaffRosters,
      breadcrumbName: (t, { prop }) => {
        return t('list of staff roster')
      },
    },
    {
      path: 'donation-list',
      isInvisible: ({ prop }) =>
        prop !== WishareEntities.DONOR,
      component: compose(
        fromRenderProps(
          OrganizationProfilesContext.Consumer,
          ({ item }) => ({
            donor: item,
          })
        )
      )(DonorMyDonationList),
      breadcrumbName: (t, { prop }) => {
        return t('list of contributors')
      },
    },
    {
      path: 'donor-medal-list',
      isInvisible: ({ prop }) =>
        prop !== WishareEntities.DONOR,
      component:
        OrganizationProfilesDonorMedalRewards,
      breadcrumbName: (t, { prop }) => {
        return t('list of medal')
      },
    },
    {
      path: 'giving-list',
      isInvisible: ({ prop }) =>
        prop !==
        WishareEntities.BENEFICIARY,
      component: compose(
        fromRenderProps(
          OrganizationProfilesContext.Consumer,
          ({ item }) => ({
            beneficiary: item,
          })
        )
      )(BeneficiaryHistory),
      breadcrumbName: (t, { prop }) => {
        return t('list of giving')
      },
    },
  ]

export const organizationRouteEntities =
  [
    {
      path: 'overview',
      component: OrganizationInfo,
      breadcrumbName: (t) =>
        t('overview'),
    },
    {
      path: 'hosts',
      component: OrganizationGetHosts,
      breadcrumbName: (t) =>
        t('related units'),
    },
    {
      path: 'news',
      component: OrganizationNews,
      breadcrumbName: (t) => t('news'),
    },
    {
      path: 'channels',
      component:
        OrganizationChannelList,
      breadcrumbName: (t) =>
        t('channels'),
    },
    {
      path: 'activities',
      component:
        OrganizationActivityList,
      breadcrumbName: (t) =>
        t('activities'),
    },
    {
      path: 'donations',
      component:
        OrganizationDonationList,
      breadcrumbName: (t) =>
        t('crowdfunding'),
    },
    {
      path: 'jobs',
      component:
        OrganizationRecruitmentList,
      breadcrumbName: (t) => t('jobs'),
    },
    {
      path: 'events',
      component: OrganizationEventList,
      breadcrumbName: (t) =>
        t('events'),
    },
    {
      path: 'givings',
      component: OrganizationGivingList,
      breadcrumbName: (t) =>
        t('givings'),
    },
    {
      path: 'contacts',
      component:
        OrganizationContactInfo,
      breadcrumbName: (t) =>
        t('contact'),
      isInvisible: (organization) =>
        _.isEmpty(
          organization?.contacts
        ),
    },
  ]
