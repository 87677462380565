import { Card } from 'antd'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import moment from 'moment'

export const renderHistoryItem = (
  item,
  index
) => {

  const receiver = _.get(item, 'email_address')

  const donation_event = _.get(item, 'source.donation_event.title')

  if (!!_.get(item, 'deleted')) {
    return null
  }
  return (
    <Translate key={index}>
      {(t) => (
        <div className="flex flex-col p-2 border border-color-50 rounded-lg">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-color-000">
              {_.get(item, 'subject')}
            </span>
            <span className="text-2xs italic font-light text-color-400">
              {moment(_.get(item, 'created')).format('HH:mm - DD/MM/YYYY')}
            </span>
          </div>
          <div className="flex items-baseline gap-2">
            <span className="text-xs italic text-color-400 font-light whitespace-no-wrap">
              {t('the system sent a thank you letter to')}
            </span>
            <span className="text-sm italic font-medium text-color-000 truncate">
              {receiver}
            </span>
          </div>
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-100 font-light whitespace-no-wrap">
              {t('for the campaign donation')}
            </span>
            <span className="text-sm font-semibold text-primary truncate">
              {donation_event}
            </span>
          </div>
        </div>
      )}
    </Translate>
  )
}
