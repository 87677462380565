import { Spin } from 'antd'
import classNames from 'classnames'
import { ObjectSelectField } from 'components/form/fields/ObjectSelectField'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { withProps } from 'recompose'
import {
  Null,
  renderIf,
} from 'views/Shared'
import { InputBoardContext } from './InputBoardContext'

const DefaultFieldWrapper = ({
  className,
  children,
}) => (
  <div
    className={classNames(
      'flex flex-col px-3 py-2 border rounded-lg',
      className
    )}>
    {children}
  </div>
)

export const OwnerSelect = ({
  parent_id,
  defaultValue,
  onChange = Null,
  ...props
}) => {
  const [isReady, setReady] =
    useState(false)

  const {
    isLoading,
    getCreators = Null,
  } = useContext(InputBoardContext)

  const options = useMemo(
    () => getCreators(parent_id),
    [parent_id, getCreators]
  )

  const array = Array.from(
    options || []
  )

  const disabled = _.every(
    [parent_id, array],
    _.isEmpty
  )

  const defaultOwner = useMemo(() => {
    if (array.length === 1) {
      return _.first(array)
    }
    return _.find(array, {
      id: defaultValue,
    })
  }, [array, defaultValue])

  useEffect(() => {
    if (
      Boolean(
        parent_id &&
          defaultOwner &&
          !isReady
      )
    ) {
      onChange(
        createValue(
          'owner',
          defaultOwner.value
        )
      )
      onChange(
        createValue(
          'owner_id',
          defaultOwner.id
        )
      )
      onChange(
        createValue(
          'owner_type',
          defaultOwner.type
        )
      )
      setReady(true)
    }
  }, [isReady, parent_id, defaultOwner])

  return renderIf(
    array.length > 1,
    <Spin spinning={!!isLoading}>
      <ObjectSelectField
        options={array}
        bordered={false}
        onChange={onChange}
        disabled={disabled}
        Wrapper={withProps({
          className: disabled
            ? 'background-100'
            : 'background',
        })(DefaultFieldWrapper)}
        defaultValue={_.find(options, {
          value: defaultValue,
        })}
        {...props}
      />
    </Spin>
  )
}

export const CreatorSelect = ({
  creator_id,
  onChange = Null,
  default_owner_id,
  ...props
}) => {
  const [isReady, setReady] =
    useState(false)

  const { options, isLoading } =
    useContext(InputBoardContext)

  const array = Array.from(
    options || []
  )

  const defaultValue = useMemo(() => {
    if (array.length === 1) {
      return _.first(array)
    }
    return (
      _.find(array, {
        owner_id: default_owner_id,
      }) || {}
    )
  }, [array, default_owner_id])

  useEffect(() => {
    if (!creator_id && !isReady) {
      onChange(
        createValue(
          'creator',
          defaultValue.value
        )
      )
      onChange(
        createValue(
          'creator_id',
          defaultValue.id
        )
      )
      onChange(
        createValue(
          'creator_type',
          defaultValue.type
        )
      )
      setReady(true)
    }
  }, [])

  return renderIf(
    array.length > 1,
    <Spin spinning={!!isLoading}>
      <ObjectSelectField
        options={array}
        bordered={false}
        onChange={onChange}
        Wrapper={withProps({
          className: 'background',
        })(DefaultFieldWrapper)}
        defaultValue={defaultValue}
        {...props}
      />
    </Spin>
  )
}
