import {
  Input,
  InputNumber,
  Switch,
} from 'antd'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import { createValue } from 'components/form/utils'
import ForDevelop from 'envs/ForDevelop'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
  nest,
  withProps,
} from 'recompose'
import {
  BoxPlaceholder,
  deepTranslate,
  fromObject,
  renderSelf,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import AdvancedFormGroup, {
  withAdvancedOptions,
} from '../custom/AdvancedFormGroup'
import CascadedCheckbox from '../custom/CascadedCheckbox'
import ContainerPicker, {
  withOwnerResponses,
} from '../custom/ContainerPicker'
import ManualPicker from '../custom/ManualPicker'
import RadioOptions from '../custom/RadioOptions'
import SelectDataSource from '../custom/SelectDataSource'
import { WishareEntities } from '../enums'
import { createDateRangerPicker } from '../factory/createDateRangerPicker'
import createFormField, {
  createControlledFormField,
} from '../factory/createFormField'
import { fromInitialValues } from '../factory/createInitialValues'
import { mapObjectValues } from '../factory/createSelectEntityProps'
import { StepFormContext } from '../factory/createStepForm'
import {
  requiredNumber,
  requiredOneOf,
  requiredString,
} from '../factory/createValidationSchema'
import { wishareFieldTypes } from '../wishareFieldTypes'
import GivingAnswerView from './ActionForms/GivingAnswerView'
import GivingDyanmicFields from './ActionForms/GivingDyanmicFields'
import GivingItemField, {
  GivingItemFieldsProvider,
} from './ActionForms/GivingItemFields'
import GivingAsBeneficiary from './components/GivingAsBeneficiary'

// Todo: implement translation

const createGroup = ({
  privacy,
  registrable,
  ...values
}) => [
  {
    invisible: true,
    children: [
      {
        name: 'invisible',
        component: nest(
          ForDevelop,
          BoxPlaceholder
        ),
      },
    ],
  },
  {
    inline: true,
    className: 'grid grid-cols-2 gap-2',
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
        value: privacy,
      },
      {
        label: 'registrable',
        name: 'registrable',
        component: withAdvancedOptions(
          wishareFieldTypes.registrable
        )(RadioOptions),
        value: registrable,
      },
    ],
  },
]

const advancedSchema = {
  children: [
    {
      name: 'advanced',
      component: mapProps(
        ({ form, onChange }) => {
          const _values = fromObject(
            _.get(form, 'values'),
            [
              'registrable',
              'privacy',
            ].map((e) => ({
              name: e,
            }))
          )
          return {
            onChange,
            groupValues:
              createGroup(_values),
          }
        }
      )(AdvancedFormGroup),
    },
  ],
}

export const givingValidationSchema = {
  [CRUD.CREATE]: (
    translate = renderSelf
  ) => {
    return {
      target: requiredNumber(translate),
      title: requiredString(translate),
      privacy:
        requiredNumber(translate),
      owner: requiredString(translate),
      target_unit_name:
        requiredString(translate),
      giving_type: requiredOneOf(
        mapObjectValues(
          wishareFieldTypes.giving.types
        ),
        translate
      ),
    }
  },
}

export const givingStepFormSchema = {
  typeStage: [
    {
      children: [
        {
          name: 'giving_type',
          component: compose(
            fromRenderProps(
              StepFormContext.Consumer,
              ({ onNext }) => ({
                callback: onNext,
              })
            ),
            mapProps(
              ({
                name,
                form,
                value,
                callback,
                onChange,
              }) => ({
                name,
                value,
                onChange,
                options: _.get(
                  form,
                  'values.giving_types',
                  []
                ),
                callback,
              })
            )
          )(ManualPicker),
        },
      ],
    },
  ],
  targetStage: [
    {
      title:
        'record and display the converted value',
      inline: true,
      children: [
        {
          name: 'conversion_required',
          component: compose(
            withTranslate,
            mapProps(
              ({
                name,
                value,
                onChange,
                translate,
              }) => ({
                name,
                defaultChecked: value,
                onChange: (value) => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
                placeholder: translate(
                  'conversion is required or not'
                ),
                checkedChildren:
                  translate('yes'),
                unCheckedChildren: (
                  <span className="text-color">
                    {translate('no')}
                  </span>
                ),
                options:
                  wishareFieldTypes.yesOrno.map(
                    deepTranslate(
                      translate
                    )
                  ),
              })
            )
          )(Switch),
        },
      ],
    },
    {
      title: 'target',
      inline: true,
      className:
        'grid grid-cols-2 gap-2',
      children: [
        {
          name: 'target',
          component: mapProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => {
              const isNoLimitFlag =
                !!_.get(
                  form,
                  'values.no_limit_flag',
                  false
                )
              // const isDefaultTarget = _.get( form, 'values.targets.length' ) <= 1;

              return {
                min: 0,
                disabled: isNoLimitFlag,
                className: 'w-full',
                // style: {height: '35px'},
                onChange: (value) => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
                value: Number(
                  isNoLimitFlag
                    ? 0
                    : value
                ),
                defaultValue: Number(
                  isNoLimitFlag
                    ? 0
                    : value
                ),
                parser: parseNumber,
                formatter:
                  formatterNumber,
                placeholder: 'value',
              }
            }
          )(InputNumber),
        },
        {
          name: 'target_unit_name',
          component: mapProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => {
              const disabled = _.get(
                form,
                'values.no_limit_flag',
                false
              )
              const dataSource =
                wishareFieldTypes.giving
                  .units
              return {
                name,
                value,
                disabled,
                onChange,
                dataSource,
                defaultValue: value,
                defaultActiveFirstOption: true,
              }
            }
          )(SelectDataSource),
        },
      ],
    },
    {
      children: [
        {
          name: 'no_limit_flag',
          component: compose(
            withTranslate,
            mapProps(
              ({
                name,
                form,
                value,
                onChange,
                translate,
              }) => ({
                name,
                onChange,
                cascades:
                  fromInitialValues([
                    form,
                    [
                      'target',
                      'target_unit_name',
                    ],
                  ]),
                children:
                  translate('no limit'),
                defaultChecked: value,
              })
            )
          )(CascadedCheckbox),
        },
      ],
    },
  ],
  ownerStage: [
    {
      title: 'title',
      children: [
        {
          name: 'title',
          component: createFormField({
            placeholder: 'title',
          }),
        },
      ],
    },
    {
      children: [
        {
          name: 'owner',
          component: withProps(
            ({
              name,
              form,
              value,
              onChange,
            }) => {
              const default_owner =
                _.get(
                  form,
                  'values.default_owner'
                )
              return {
                name,
                onChange,
                defaultValue:
                  getId(value) || value,
                label: 'owner',
                type: WishareEntities.GIVING,
                getOption: (option) =>
                  _.get(
                    option,
                    'owner'
                  ),
                withResponses:
                  withOwnerResponses(
                    default_owner
                  ),
                optionIdProp: 'idname',
                mapCustomProps: (
                  option,
                  optionIdProp
                ) => {
                  const { style = {} } =
                    option
                  return [
                    _.get(
                      default_owner,
                      optionIdProp
                    ),
                  ].includes(
                    _.get(
                      option,
                      optionIdProp
                    )
                  )
                    ? {
                        ...option,
                        style: {
                          ...style,
                          color:
                            'rgba(15, 83, 113, 1)',
                          backgroundColor:
                            'rgba(241, 250, 255, 1)',
                        },
                      }
                    : option
                },
              }
            }
          )(ContainerPicker),
        },
      ],
    },
    {
      title: 'open/close',
      children: [
        {
          name: 'time_open',
          component:
            createDateRangerPicker([
              'time_open',
              'time_close',
            ]),
        },
      ],
    },
    advancedSchema,
  ],
  register_step_1: [
    {
      title: 'giving type',
      children: [
        {
          name: 'giving_items',
          component:
            createControlledFormField({
              Component:
                fromRenderProps(
                  StepFormContext.Consumer,
                  ({
                    formInstance,
                  }) => ({
                    formInstance,
                  })
                )(GivingDyanmicFields),
              Item: withProps({
                listName:
                  'giving_items',
              })(GivingItemField),
              withValues: ({
                giving_type,
              }) => ({
                giving_type,
              }),
              Wrapper: ({
                children,
                giving_type,
              }) => (
                <GivingItemFieldsProvider
                  type_cd={giving_type}
                  type_cds={
                    wishareFieldTypes
                      .giving.types
                  }
                  prefixTitle="item"
                  getGroup={(source) =>
                    _.get(
                      source,
                      'type_cds'
                    ) || []
                  }>
                  {children}
                </GivingItemFieldsProvider>
              ),
            }),
        },
      ],
    },
    {
      title: 'note',
      children: [
        {
          name: 'register_note',
          component: createFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'note',
            Component: Input.TextArea,
          }),
        },
      ],
    },
  ],
  register_step_2: [
    {
      children: [
        {
          name: 'as_beneficiary',
          component: mapProps(
            ({ name, value }) => ({
              name,
              value,
            })
          )(GivingAsBeneficiary),
        },
      ],
    },
    {
      children: [
        {
          name: 'collection_questions',
          component:
            createControlledFormField({
              Component:
                GivingAnswerView,
              targetName:
                'collection_answers',
            }),
        },
      ],
    },
  ],
  register_step_3: [],
}
