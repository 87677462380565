import { Select } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import {
  deepTranslate,
  renderOwnChild,
} from 'views/Shared'
import { WishareEntities } from '../enums'

const EntitySortBy = ({
  style,
  value,
  options,
  onSelect,
  className,
  defaultValue,
  translated = false,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const t = useTranslate()

  const _options = useMemo(() => {
    if (translated) {
      return options
    }
    return Array.from(options).map(
      deepTranslate(t)
    )
  }, [t, options, translated])

  if (_.isEmpty(options)) {
    return null
  }

  const { value: _defaultValue } =
    _.find(options, {
      value: defaultValue,
    }) || {}

  return (
    <Wrapper>
      <Select
        size="small"
        value={value}
        options={_options}
        onSelect={onSelect}
        style={{ width: 150 }}
        defaultValue={_defaultValue}
        className="text-xs text-color-300"
        suffixIcon={
          <IoChevronDown className="text-color-300" />
        }
        {...props}
      />
    </Wrapper>
  )
}

export const ActivitiesSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const DonorsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    VALUE_DESC: {
      value: 'value desc',
      label: 'value desc',
    },
    VALUE_ASC: {
      value: 'value asc',
      label: 'value asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const HostsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const TimesheetsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const PartnersSortBy = ({
  ...props
}) => {
  const sortOptions = {
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.NAME_DESC.value
      }
      {...props}
    />
  )
}

export const DonationsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
    VALUE_DESC: {
      value: 'value desc',
      label: 'value desc',
    },
    VALUE_ASC: {
      value: 'value asc',
      label: 'value asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const DonationItemsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const GivingsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
    VALUE_DESC: {
      value: 'value desc',
      label: 'value desc',
    },
    VALUE_ASC: {
      value: 'value asc',
      label: 'value asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const GivingItemsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const EventsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const ChannelsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const RecruitmentsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const CandidatesSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const BeneficiariesSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
    VALUE_DESC: {
      value: 'value desc',
      label: 'value desc',
    },
    VALUE_ASC: {
      value: 'value asc',
      label: 'value asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const StaffsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const MedalsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
    TYPE_DESC: {
      value: 'type desc',
      label: 'type desc',
    },
    TYPE_ASC: {
      value: 'type asc',
      label: 'type asc',
    },
    LEVEL_DESC: {
      value: 'level desc',
      label: 'level desc',
    },
    LEVEL_ASC: {
      value: 'level asc',
      label: 'level asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.TYPE_DESC.value
      }
      {...props}
    />
  )
}

export const MedalRewardsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const SummaryMedalsSortBy = ({
  ...props
}) => {
  const sortOptions = {
    DATE_DESC: {
      value: 'date desc',
      label: 'date desc',
    },
    DATE_ASC: {
      value: 'date asc',
      label: 'date asc',
    },
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.DATE_DESC.value
      }
      {...props}
    />
  )
}

export const NameSortBy = ({
  ...props
}) => {
  const sortOptions = {
    NAME_DESC: {
      value: 'name desc',
      label: 'name desc',
    },
    NAME_ASC: {
      value: 'name asc',
      label: 'name asc',
    },
  }
  return (
    <EntitySortBy
      options={Object.values(
        sortOptions
      )}
      defaultValue={
        sortOptions.NAME_ASC.value
      }
      {...props}
    />
  )
}

const createEntitySortBy = ({
  type,
  ...props
}) => {
  const params = {
    ...props,
  }
  switch (type) {
    case WishareEntities.ACTIVITY:
      return (
        <ActivitiesSortBy {...params} />
      )
    case WishareEntities.DONOR:
      return (
        <DonorsSortBy {...params} />
      )
    case WishareEntities.DONATION:
      return (
        <DonationsSortBy {...params} />
      )
    case WishareEntities.GIVING:
      return (
        <GivingsSortBy {...params} />
      )
    case WishareEntities.EVENT:
      return (
        <EventsSortBy {...params} />
      )
    case WishareEntities.CHANNEL:
      return (
        <ChannelsSortBy {...params} />
      )
    case WishareEntities.RECRUITMENT:
      return (
        <RecruitmentsSortBy
          {...params}
        />
      )
    case WishareEntities.HOST:
      return <HostsSortBy {...params} />
    case WishareEntities.MEDAL:
      return (
        <MedalsSortBy {...params} />
      )
    default:
      return null
  }
}

export default createEntitySortBy
