import createStickySider from 'views/Wishare/factory/createStickySider'
import TopicRelatedTags from './TopicRelatedTags'

const TopicSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(TopicRelatedTags)

export default TopicSider
// branch(({ invisible }) => {
//   const { isSm } = useContext(
//     LayoutContext
//   )
//   return Boolean(!invisible && isSm)
// }, renderNothing)
