import {API_ROOT_URL} from 'envs/_current/config'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {givingEventSchema, givingSchema,} from './schema'
import {donationEventSchema, donationSchema, donorSchema,} from './schema/donationEvent'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:donations,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  idname: baseFieldTypes.string,
}

export const donation_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/donation-events/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: donationEventSchema,
  transform: createTransform(
    donationEventSchema,
    'data'
  ),
}

export const donation_getWidgetById_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/get-widget',
    method: 'GET',
    fields,
    fieldTypes,
    schema: donationEventSchema,
    transform: createTransform(
      donationEventSchema,
      'data'
    ),
  }

export const donation_add_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/donation-events/create',
  method: 'POST',
  fields,
  fieldTypes,
  schema: donationEventSchema,
  transform: createTransform(
    donationEventSchema,
    'data'
  ),
}

export const donation_update_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/donation-events/:donation_id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  schema: donationEventSchema,
  transform: createTransform(
    donationEventSchema,
    'data'
  ),
}

export const donation_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/donation-events/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: donationEventSchema,
  transform: createTransform(
    donationEventSchema,
    'data'
  ),
}

export const donation_events_getItemsByOwner_gets_api =
  {
    backend: `Route::get('donation-events/{type}/{id}/get-donation-events', 'DonationEventController@getDonationEventsByProps')`,
    root: API_ROOT_URL,
    path: '/donation-events/:type/:id/get-donation-events',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'status',
      'keyword',
      'sort_by',
    ],
    schema: donationEventSchema,
    transform: createListTransform(
      donationEventSchema
    ),
  }

// Route::get('donation-events/{id}/donations/get-chart-data', 'DonationEventController@getDonationDataForChart');
export const donation_events_getDonationDataForChart_api =
  {
    backend: `Route::get('donation-events/{id}/donations/get-chart-data', 'DonationEventController@getDonationDataForChart');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/get-chart-data',
    method: 'GET',
    fields,
    fieldTypes,
  }

// Route::get('donation-events/{id}/donations/get-export-data', 'DonationEventController@getDonationDataForExport');
export const donation_events_getDonationDataForExport_api =
  {
    backend: `Route::get('donation-events/{id}/donations/get-export-data', 'DonationEventController@getDonationDataForExport');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/get-export-data',
    method: 'GET',
    fields,
    fieldTypes,
  }

// Route::get('donation-events/donations/user/{username}/get-donations', 'DonationController@getDonationsByUser');
export const donation_events_getDonationsByUser_api =
  {
    backend: `Route::get('donation-events/donations/user/{username}/get-donations', 'DonationController@getDonationsByUser');`,
    root: API_ROOT_URL,
    path: '/donation-events/donations/user/:username/get-donations',
    method: 'GET',
    fields,
    fieldTypes,
  }

// Route::get('donation-events/donations/user/{username}/get-pending-donations', 'DonationController@getPendingDonationsByUser');
export const donation_events_getPendingDonationsByUser_api =
  {
    backend: `Route::get('donation-events/donations/user/{username}/get-pending-donations', 'DonationController@getPendingDonationsByUser');`,
    root: API_ROOT_URL,
    path: '/donation-events/donations/user/:username/get-pending-donations',
    method: 'GET',
    fields,
    fieldTypes,
  }

const _localDonationSchema =
  new schema.Entity(
    'donations',
    {
      donation_event:
        donationEventSchema,
    },
    {
      idAttribute: 'id',
    }
  )

// Route::get('donation-events/{id}/donations/gets', 'DonationEventController@getDonationsByDonationEvent');
export const donation_events_getDonationsByDonationEvent_api =
  {
    backend: `Route::get('donation-events/{id}/donations/gets', 'DonationEventController@getDonationsByDonationEvent');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
      'filtered_by',
    ],
    schema: donationSchema,
    transform: createListTransform(
      donationSchema
    ),
  }

export const donation_events_getDonationsByType_api =
  {
    backend: `Route::get('donation-events/{type}/{id}/donations/gets', 'DonationEventController@getDonationsByType');`,
    root: API_ROOT_URL,
    path: '/donation-events/:type/:id/donations/gets',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
      'status',
    ],
    schema: donationSchema,
    transform: createListTransform(
      donationSchema
    ),
  }

export const donation_events_getDonationsByDonorOwner_api =
  {
    backend: `Route::get('donation-events/{type}/{id}/donations/gets-by-donor-owner', 'DonationEventController@getDonationsByDonorOwner');`,
    root: API_ROOT_URL,
    path: '/donation-events/:type/:id/donations/gets-by-donor-owner',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: donationSchema,
    transform: createListTransform(
      donationSchema
    ),
  }

export const donation_events_getFullDonationsByDonationEvent_api =
  {
    backend: `Route::get('donation-events/{id}/donations/get-all', 'DonationEventController@getFullDonationsByDonationEvent');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/get-all',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'keyword',
      'sort_by',
      'filtered_by',
    ],
    schema: donationSchema,
    transform: createListTransform(
      donationSchema
    ),
  }

export const donation_events_getAvailableGivingEvents_api =
  {
    backend: `Route::get('donation-events/{id}/giving-events/get-out-events', 'DonationEventController@getAvailableGivingEventsByDonationEvent');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/giving-events/get-out-events',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: ['keyword'],
    schema: givingEventSchema,
    transform: createListTransform(
      givingEventSchema
    ),
  }

export const donation_events_register_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/register-donate/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationSchema,
    transform: createTransform(
      donationSchema,
      'data'
    ),
  }

export const donation_events_unregister_api =
  {
    backend: `Route::post('donation-events/{id}/register-donate/{donationId}/delete', 'DonationController@deleteDonatedRegistration');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/register-donate/:donation_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: _localDonationSchema,
    transform: createTransform(
      _localDonationSchema,
      'data'
    ),
  }

export const donation_events_donationManageAdd_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/manage-add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationSchema,
    transform: createTransform(
      donationSchema,
      'data'
    ),
  }

export const donation_events_donationManageEdit_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/:donation_id/manage-edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationSchema,
    transform: createTransform(
      donationSchema,
      'data'
    ),
  }

export const donation_events_donationManageDelete_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/:donation_id/manage-delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: _localDonationSchema,
    transform: createTransform(
      _localDonationSchema,
      'data'
    ),
  }

export const donation_events_addDonationOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/option/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationEventSchema,
    transform: createTransform(
      donationEventSchema,
      'data'
    ),
  }

export const donation_events_editDonationOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/option/:option_id/edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationEventSchema,
    transform: createTransform(
      donationEventSchema,
      'data'
    ),
  }

export const donation_events_deleteDonationOption_api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/donation-events/:id/option/:option_id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: donationEventSchema,
    transform: createTransform(
      donationEventSchema,
      'data'
    ),
  }

export const donation_events_manageDonationSendThankYouEmail_api =
  {
    backend: `Route::post('donation-events/donations/{donationId}/send-thank-you-email', 'DonationController@manageDonationSendThankYouEmail');`,
    root: API_ROOT_URL,
    path: '/donation-events/donations/:id/send-thank-you-email',
    method: 'POST',
    fields,
    fieldTypes,
  }


export const donation_events_manageDonationSendRegisterConfirmationEmail_api =
  {
    backend: `Route::post('donation-events/donations/{donationId}/send-register-confirmation-email', 'DonationController@manageDonationSendRegisterConfirmationEmail');`,
    root: API_ROOT_URL,
    path: '/donation-events/donations/:id/send-register-confirmation-email',
    method: 'POST',
    fields,
    fieldTypes,
  }

export const donation_events_getEmailSentLogHistory_api =
  {
    backend: `Route::get('donation-events/organizations/{id}/get-email-sent-history', 'DonorController@getEmailSentLogHistory');`,
    root: API_ROOT_URL,
    path: '/donation-events/organizations/:id/get-email-sent-history',
    method: 'GET',
  }

export const donation_events_getDonationDataForExportFile_api =
  {
    backend: `Route::get('donation-events/{id}/donations/get-export-file', 'DonationEventController@getDonationDataForExportFile');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/donations/get-export-file',
    method: 'GET',
    fields,
    fieldTypes,
  }

export const donation_events_importDonationTmpData_api =
  {
    backend: `API Import: Route::post('donation-events/{id}/data-import', 'DonationImportController@importDonationTmpData');`,
    root: API_ROOT_URL,
    path: '/donation-events/:id/data-import',
    method: 'POST',
    fields,
    fieldTypes,
  }

export const donor_getDonationStats_api =
  {
    backend: `Route::get('donors/organization/{organization_id}/get-donation-stats', 'DonorController@getDonationStats');`,
    root: API_ROOT_URL,
    path: '/donors/organization/:organization_id/get-donation-stats',
    method: 'GET',
    paramsFields: ['period'],
  }

// BEGIN Donors

export const donor_searchDonors_api = {
  backend: `Route::get('donors/organization/{organization_id}/search', 'DonorController@searchDonors'); // keyword`,
  root: API_ROOT_URL,
  path: '/donors/organization/:organization_id/search',
  method: 'GET',
  paramsFields: ['keyword'],
  schema: donorSchema,
  transform: createListTransform(
    donorSchema
  ),
}

export const donor_lookupDonors_api = {
  backend: `Route::get('donors/organization/{organization_id}/lookup', 'DonorController@lookupDonors'); // keyword, exclude_ids`,
  root: API_ROOT_URL,
  path: '/donors/organization/:organization_id/lookup',
  method: 'GET',
  paramsFields: [
    'keyword',
    'exclude_ids',
  ],
  schema: donorSchema,
  transform: createListTransform(
    donorSchema
  ),
}

export const donor_createDonor_api = {
  backend: `Route::post('donors/create', 'DonorController@create');`,
  root: API_ROOT_URL,
  path: '/donors/create',
  method: 'POST',
  schema: donorSchema,
  transform: createTransform(
    donorSchema,
    'data'
  ),
}

export const donor_updateDonor_api = {
  backend: `Route::post('donors/{id}/update'', 'DonorController@update');`,
  root: API_ROOT_URL,
  path: '/donors/:id/update',
  method: 'POST',
  schema: donorSchema,
  transform: createTransform(
    donorSchema,
    'data'
  ),
}

export const donor_deleteDonor_api = {
  backend: `Route::post('donors/{id}/delete', 'DonorController@delete');`,
  root: API_ROOT_URL,
  path: '/donors/:id/delete',
  method: 'POST',
  schema: donorSchema,
  transform: createTransform(
    donorSchema,
    'data'
  ),
}

export const donor_getDonors_api = {
  backend: `Route::get('donors/organization/{organization_id}/get-donors', 'DonorController@getDonors'); // keyword, sort_by`,
  root: API_ROOT_URL,
  path: '/donors/organization/:organization_id/get-donors',
  method: 'GET',
  paramsFields: [
    'keyword',
    'sort_by',
    'filter_activity_id',
    'filter_donation_event_id',
  ],
  schema: donorSchema,
  transform: createListTransform(
    donorSchema
  ),
}

export const donor_getById_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/donors/:id/get',
  method: 'GET',
  schema: donorSchema,
  transform: createTransform(
    donorSchema,
    'data'
  ),
}

export const donor_getTopDonors_api = {
  backend: `Route::get('donors/organization/{organization_id}/get-top-donors', 'DonorController@getTopDonors'); // sort_by: [value desc|count desc]`,
  root: API_ROOT_URL,
  path: '/donors/organization/:organization_id/get-top-donors',
  method: 'GET',
  paramsFields: ['sort_by'],
  schema: donorSchema,
  transform: createListTransform(
    donorSchema
  ),
}

export const donor_getDonations_api = {
  backend: `Route::get('donors/{id}/get-donations', 'DonorController@getDonations'); // keyword, sort_by`,
  root: API_ROOT_URL,
  path: '/donors/:id/get-donations',
  method: 'GET',
  paramsFields: [
    'keyword',
    'status',
    'sort_by',
  ],
  schema: donationSchema,
  transform: createListTransform(
    donationSchema
  ),
}

export const beneficiary_getGivings_api =
  {
    backend: `Route::get('beneficiaries/{id}/get-givings', 'BeneficiaryController@getGivings'); // keyword, sort_by`,
    root: API_ROOT_URL,
    path: '/beneficiaries/:id/get-givings',
    method: 'GET',
    paramsFields: [
      'keyword',
      'status',
      'sort_by',
    ],
    schema: givingSchema,
    transform: createListTransform(
      givingSchema
    ),
  }

export const donor_getActivities_api = {
  backend: `Route::get('donors/{id}/get-activities', 'DonorController@getDonationEventOwners');`,
  root: API_ROOT_URL,
  path: '/donors/:id/get-activities',
  method: 'GET',
  schema: givingSchema,
  transform: createListTransform(
    givingSchema
  ),
}

export const donor_mergeDonor_api = {
  backend: `Route::post('donors/{to}/merge-from/{from}', 'DonorController@mergeDonor');`,
  root: API_ROOT_URL,
  path: '/donors/:to/merge-from/:from',
  method: 'POST',
  schema: donorSchema,
  transform: createTransform(
    donorSchema,
    'data'
  ),
}

export const donor_getDonorsOfTheSameOwner_Api =
  {
    backend: `Route::get('donors/organization/{organization_id}/get-donors-of-the-same-owner', 'DonorController@getDonorsOfTheSameOwner');`,
    root: API_ROOT_URL,
    path: '/donors/organization/:organization_id/get-donors-of-the-same-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
      'donor_id',
      'owner_id',
      'owner_type',
    ],
    schema: donorSchema,
    transform: createListTransform(
      donorSchema
    ),
  }

export const donor_getDonorsOfTheOwner_Api =
  {
    backend: `Route::get('donors/{prop}/{id}/get-donors-of-the-owner', 'DonorController@getDonorsOfTheOwner'); // sort_by: date,name`,
    root: API_ROOT_URL,
    path: '/donors/:prop/:id/get-donors-of-the-owner',
    method: 'GET',
    paramsFields: [
      'keyword',
      'sort_by',
    ],
    schema: donorSchema,
    transform: createListTransform(
      donorSchema
    ),
  }

// END Donors
