import { Button } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { partners_getPartners_Api } from 'apis/organizationPartner'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useSearchParam } from 'react-use'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { usePartners } from 'views/Organization/Settings/Partners/UsePartners'
import { Null } from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { PartnersSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import { renderPartner } from '../functions/renderPartner'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationSettingPartners = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    partner_type: 'type',
  },
}) => {
  const t = useTranslate()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const partner_type = useSearchParam(
    filter_actions.partner_type
  )

  const {
    refreshKey,
    modal = null,
    renderContextMenu,
    handleAction = Null,
  } = usePartners({
    organization_id: owner_id,
  })

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          partner_type,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          partners_getPartners_Api,
        query: bindQueryParam({
          id: owner_id,
        }),
        renderItem: renderPartner({
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          sortBy,
          keyword,
          owner_id,
          owner_type,
          refreshKey,
          JSON.stringify(filterBy),
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      query_params: filter_actions,
      sortHeader: t('list of partners'),
      SortByWidget: PartnersSortBy,
      renderFilter: Null,
      AdvancedFilter: Null,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="partners"
        extra={
          <Button
            className="rounded-md no-border no-text-shadow no-shadow"
            type="primary"
            onClick={() => {
              handleAction(CRUD.CREATE)
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
      {modal}
      <div className="h-10" />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  )
)(OrganizationSettingPartners)
