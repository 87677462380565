import { Button } from 'antd'
import { message_createConversation_Api } from 'apis'
import { getId } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useMemo,
} from 'react'
import { IoChatbubblesSharp } from 'react-icons/io5'
import { staticPaths } from 'routes/staticPaths'
import {
  getResponseItem,
  renderOwnChild,
} from 'views/Shared'
import { notifyOnError } from './createErrorEvent'

const createChatButton = ({
  onClick,
  ...props
}) => (
  <LayoutContext.Consumer>
    {({ isSm }) => (
      <Translate>
        {(t) => (
          <Button
            ghost={true}
            type="primary"
            size="default"
            className="no-shadow no-text-shadow rounded-lg flex flex-center gap-2"
            icon={
              <IoChatbubblesSharp />
            }
            onClick={onClick}
            {...props}>
            {t('send message')}
          </Button>
        )}
      </Translate>
    )}
  </LayoutContext.Consumer>
)

export const ConversationWith = ({
  params = {},
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const history = useHistory()

  const {
    receiver_id,
    organization_id,
  } = params

  const onSuccess = (
    __,
    { response }
  ) => {
    const conversationId = getId(
      getResponseItem(response)
    )
    history.push({
      ...staticPaths.messages,
      search: `?conversationId=${conversationId}`,
    })
  }

  const values = useMemo(() => {
    const data = {
      receiver_id,
      organization_id,
    }
    return _.reduce(
      Object.keys(data),
      function (prev, key) {
        const value = data[key]
        if (value) {
          return {
            ...prev,
            [key]: value,
          }
        }
        return prev
      },
      {}
    )
  }, [receiver_id, organization_id])

  const {
    handleAsyncAction: sendMessage,
  } = useAsyncAction({
    values,
    apiInfo:
      message_createConversation_Api,
    onSuccess,
    onError: notifyOnError(t),
  })

  if (
    _.isEqual(receiver_id, getId(login))
  ) {
    return null
  }

  const Component = (props) =>
    createChatButton({
      onClick: () => sendMessage(),
      ...props,
    })

  return (
    <Wrapper>
      <Component />
    </Wrapper>
  )
}

export default createChatButton
