import { ImpactCategories } from 'components/Categories/CategoriesXS'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { staticPaths } from 'routes/staticPaths'
import { GivingShareWidget } from 'views/Wishare/factory/createShareWidget'
import createStickySider from 'views/Wishare/factory/createStickySider'
import { GivingHosts } from 'views/Wishare/Host/components/HostFactory'
import GivingContext from '../GivingContext'
import GivingOption from './GivingOption'
import GivingSummary from './GivingSummary'

const metaRoutes = [
  {
    name: 'help',
    label: 'about us',
    path: staticPaths.about,
  },
  {
    name: 'help',
    label: 'policy',
    path: staticPaths.policy,
  },
  {
    name: 'help',
    label: 'help',
    path: staticPaths.helps,
  },
  {
    name: 'Wishare.com',
    path: staticPaths.home,
    className:
      'text-sm font-bold text-primary',
  },
]

const GivingFooter = () => {
  const t = useTranslate()
  return (
    <div className="flex flex-wrap justify-center space-x-6">
      {metaRoutes.map(
        (
          {
            name,
            label,
            path,
            ...rest
          },
          index
        ) => (
          <Link
            key={index}
            to={path}
            className="text-sm font-light text-color-400 hover:text-primary"
            {...rest}>
            {label ? t(label) : name}
          </Link>
        )
      )}
      <div className="h-16" />
    </div>
  )
}

const GivingSticker = React.memo(() => (
  <React.Fragment>
    <GivingOption />
    <GivingContext.Consumer>
      {({ giving }) => (
        <ImpactCategories
          title="toward impacts"
          categories={_.get(
            giving,
            'impact_categories',
            []
          )}
        />
      )}
    </GivingContext.Consumer>
    <GivingSummary />
    <GivingShareWidget />
    <GivingFooter />
  </React.Fragment>
))

const GivingSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(GivingSticker, GivingHosts)

export default GivingSider
