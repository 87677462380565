import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {isRawContentEmpty} from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {convertFromRaw, EditorState,} from 'draft-js'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo,} from 'react'
import {BsFacebook, BsLinkedin, BsTwitter, BsYoutube,} from 'react-icons/bs'
import {IoCalendar, IoEarth} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {withProps} from 'recompose'
import renderCategoryLink from 'views/LandingPage/functions/renderCategoryLink'
import TagList from 'views/Wishare/Tags/TagList'
import {FlexCol} from 'views/Wishare/Templates/ItemTemplate'
import createPagerFooter from '../../factory/createPagerFooter'
import OrganizationImpactReport from '../components/OrganizationImpactReport'
import OrganizationContainerContext from '../OrganizationContainerContext'
import OrganizationNews from './OrganizationNews'
import {Avatar, Tag} from "antd";
import moment from "moment";
import classNames from "classnames";
import CollapsedWrapper from "../../Templates/CollapedWrapper";

const ItemStatistic = ({
                         label,
                         value,
                         valueComponent,
                         to,
                         className = "border border-color-50"
                       }) => (
  <Translate>
    {(t) => (
      <Link
        to={to}
        className="no-underline">
        <div
          className={classNames("px-2 py-4 flex flex-col flex-center gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full", className)}>
          {
            !!value ? (
              <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                {Number(value).toLocaleString()}
              </span>
            ) : valueComponent
          }
          <span className="leading-tight text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(label)}
          </span>
        </div>
      </Link>
    )}
  </Translate>
)

const PageIcon = ({
                    page,
                    size,
                    className,
                  }) => {
  switch (page) {
    case 'facebook':
      return (
        <BsFacebook
          size={size}
          className={className}
        />
      )
    case 'linkedin':
      return (
        <BsLinkedin
          size={size}
          className={className}
        />
      )
    case 'twitter':
      return (
        <BsTwitter
          size={size}
          className={className}
        />
      )
    default:
      return (
        <IoEarth
          size={22}
          className={className}
        />
      )
  }
}

const OrganizationInfo = ({
                            Wrapper = FlexCol,
                          }) => {
  const t = useTranslate()
  const {isSm} = useContext(
    LayoutContext
  )
  const {organization} = useContext(
    OrganizationContainerContext
  )

  const {
    tags,
    about,
    website,
    description,
    categories = [],
    subcategories = [],
  } = organization || {}

  const editorState = useMemo(
    () =>
      isRawContentEmpty(about)
        ? null
        : EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(about)
        )
        ),
    [about]
  )

  if (_.isEmpty(organization)) {
    return null
  }

  const linked_pages = _.get(
    organization,
    'linked_pages',
    []
  )


  return (
    <Wrapper className="items-start space-y-6">
      <HeaderItemInfo
        title="description"
        hidden={_.isEmpty(description)}>
        <div className="p-2 font-light text-sm text-color-300 border border-color-50 rounded-lg italic">
          {description}
        </div>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="about us"
        hidden={!editorState}>
        <CollapsedWrapper className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={editorState}
          />
        </CollapsedWrapper>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="link page"
        className="w-full"
        hidden={_.every(
          [
            website,
            _.get(
              organization,
              'video_url'
            ),
            linked_pages,
          ],
          (value) => _.isEmpty(value)
        )}>
        <div className="grid grid-cols-2 gap-3 ml-2 md:ml-3">
          {website && (
            <div className="flex items-center gap-2">
              <IoEarth
                size={22}
                className="text-primary"
              />
              <a
                href={website}
                target="_blank"
                className="font-medium italic">
                {website}
              </a>
            </div>
          )}
          {_.get(
            organization,
            'video_url'
          ) && (
            <div className="flex items-center gap-2">
              <BsYoutube
                size={20}
                className="text-primary"
              />
              <a
                href={_.get(
                  organization,
                  'video_url'
                )}
                target="_blank"
                className="font-medium italic">
                {_.get(
                  organization,
                  'video_url'
                )}
              </a>
            </div>
          )}
          {linked_pages.length > 0 &&
          linked_pages.map((e, i) => (
            <div
              key={i}
              className="flex items-center gap-2">
              <PageIcon
                size={20}
                page={e.page}
                className="text-primary"
              />
              <a
                href={e.url}
                target="_blank"
                className="font-medium italic">
                {e.url}
              </a>
            </div>
          ))}
        </div>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="categories"
        className="flex flex-wrap gap-2 ml-1 md:ml-2"
        hidden={_.isEmpty(categories)}>
        {categories.map(
          renderCategoryLink
        )}
      </HeaderItemInfo>

      <TagList
        items={tags}
        Wrapper={withProps({
          title: 'keywords',
          className:
            'flex flex-wrap gap-3 px-2 md:px-3',
        })(HeaderItemInfo)}
      />

      {
        [
          {
            id: 'organization_members',
            title: 'organization',
            className: "border-2 border-color-org",
            linkTo: getLinkToDetail(organization) + '/hosts',
            hidden: !!!_.get(organization, 'total_org_members', 0) && !!!_.get(organization, 'total_org_partners', 0),
            children: [
              {
                label: "total org members", value: _.get(organization, 'total_org_members', 0),

              },
              {
                label: "total org partners", value: _.get(organization, 'total_org_partners', 0),
              }
            ]
          },
          {
            id: 'activities',
            title: 'activities',
            className: "border-2 border-color-act",
            linkTo: getLinkToDetail(organization) + '/activities',
            hidden: !!!_.get(organization, 'total_activities', 0) && !!!_.get(organization, 'total_other_activities', 0),
            children: [
              {
                label: "activities", value: _.get(organization, 'total_activities', 0),

              },
              {
                label: "activity of co-owners", value: _.get(organization, 'total_other_activities', 0),
              }
            ]
          },
          {
            id: 'crowdfunding',
            title: 'crowdfunding',
            className: "border-2 border-primary",
            linkTo: getLinkToDetail(organization) + '/donations',
            hidden: !!!_.get(organization, 'total_donation_events', 0),
            children: [
              {
                label: "donation campaign", valueComponent: (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(organization, 'total_active_donation_events', 0)).toLocaleString()
                    }</span>
                    {
                      ' / '
                    }
                    <span className="text-color-500">{
                      Number(_.get(organization, 'total_donation_events', 0)).toLocaleString()
                    }</span>
                  </div>
                ),

              },
              {
                label: "value to contribute",
                valueComponent: _.get(organization, 'input_statistics.0.donation_target', 0) - _.get(organization, 'input_statistics.0.donation_value', 0) > 0 ? (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(organization, 'input_statistics.0.donation_target', 0) - _.get(organization, 'input_statistics.0.donation_value', 0)).toLocaleString()
                    }</span>
                    {
                      ' '
                    }
                    <span className="text-color-500">{
                      _.get(organization, 'input_statistics.0.target_unit_name', 0)
                    }</span>
                  </div>
                ) : null,
              }
            ]
          },
          {
            id: 'giving',
            title: 'giving',
            className: "border-2 border-secondary",
            linkTo: getLinkToDetail(organization) + '/givings',
            hidden: !!!_.get(organization, 'total_giving_events', 0),
            children: [
              {
                label: "giving campaign", valueComponent: (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(organization, 'total_active_giving_events', 0)).toLocaleString()
                    }</span>
                    {
                      ' / '
                    }
                    <span className="text-color-500">{
                      Number(_.get(organization, 'total_giving_events', 0)).toLocaleString()
                    }</span>
                  </div>
                ),

              },
              {
                label: "can giving",
                valueComponent: _.get(organization, 'output_statistics.0.giving_target', 0) - _.get(organization, 'output_statistics.0.giving_value', 0) > 0 ? (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(organization, 'output_statistics.0.giving_target', 0) - _.get(organization, 'output_statistics.0.giving_value', 0)).toLocaleString()
                    }</span>
                    {
                      ' '
                    }
                    <span className="text-color-500">{
                      _.get(organization, 'output_statistics.0.target_unit_name', 0)
                    }</span>
                  </div>
                ) : null,
              }
            ]
          },
          {
            id: 'staffs',
            title: 'staffs',
            className: "border-2 border-green-500",
            linkTo: getLinkToDetail(organization) + '/jobs',
            hidden: !!!_.get(organization, 'total_recruitment_events', 0) && !!!_.get(organization, 'total_employees', 0),
            children: [
              {
                label: "staffs", value: _.get(organization, 'total_employees', 0),

              },
              {
                label: "total active recruitments events",
                value: _.get(organization, 'total_active_recruitment_events', 0),
              }
            ]
          },
          {
            id: 'event',
            title: 'event',
            className: "border-2 border-purple-500",
            linkTo: getLinkToDetail(organization) + '/events',
            hidden: !!!_.get(organization, 'total_events', 0),
            children: [
              {
                label: "events", value: _.get(organization, 'total_events', 0),

              },
              {
                label: "total incoming events", value: _.get(organization, 'total_incoming_events', 0),
              }
            ],
            List: () => (
              <div className="flex flex-col gap-1">
                <div className="text-sm text-color-300">
                  {t('event list')}
                </div>
                <div className="flex flex-col gap-3">
                  {_.get(
                    organization,
                    'events',
                    []
                  ).map((e, i) => (
                    <div key={i} className="p-2 flex items-center gap-2 rounded-lg border border-color-50 shadow-items">
                      <Avatar
                        size={30}
                        icon={<IoCalendar size={15} className="text-primary"/>}
                        className="flex flex-center background-100"
                      />
                      <div className="flex flex-col flex-1 gap-1">
                      <span className="font-medium text-color-000">
                          {getTitle(e)}
                      </span>
                        <div className="flex justify-between">
                          <div className="flex items-baseline flex-1 gap-1 text-xs">
                              <span className="font-light text-color-400 italic">
                                  {t('start')} {':'}
                              </span>
                            <span className="font-medium text-color-100">
                                  {moment(_.get(e, 'time_open')).format('HH:mm - DD/MM/YYYY')}
                              </span>
                          </div>
                          {moment() > (_.get(e, 'time_close')) ? (
                            <Tag
                              className="mr-0"
                              color="success">
                              {t('finished')}
                            </Tag>
                          ) : (
                            <Tag
                              className="mr-0"
                              color="processing">
                              {t('processing')}
                            </Tag>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {createPagerFooter({
                  to:
                    getLinkToDetail(
                      organization
                    ) + '/events',
                  description:
                    'You can click "See more" to see this full information',
                })}
              </div>
            )
          }
        ].filter(e => !!!e.hidden).map((e, i) => {

            const ListComponent = e.List;
            return !!isSm ? (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <Link
                    to={e.linkTo}
                    className={classNames("px-6 py-4 flex flex-col gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline", e.className)}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <div key={ii} className="flex items-baseline gap-3 leading-tight">
                          {
                            !!ee.value ? (
                              <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                                {Number(ee.value).toLocaleString()}
                              </span>
                            ) : (
                              ee.valueComponent
                            )
                          }
                          <span className="text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(ee.label)}
          </span>
                        </div>
                      ))
                    }
                  </Link>

                  {
                    !!ListComponent ? <ListComponent/> : null
                  }
                </HeaderItemInfo>
              )
              : (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <div className="grid grid-cols-2 gap-3">
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <ItemStatistic
                            key={ii}
                            className={e.className}
                            label={ee.label}
                            value={ee.value}
                            valueComponent={ee.valueComponent}
                            to={e.linkTo}
                        />
                      ))
                    }
                  </div>

                  {
                    !!ListComponent ? <ListComponent/> : null
                  }
                </HeaderItemInfo>
              )
          }
        )
      }

      <OrganizationNews
        sample={2}
        Footer={withProps({
          pathname:
            getLinkToDetail(
              organization
            ) + '/news',
        })(({pathname}) =>
          createPagerFooter({
            to: pathname,
            description:
              'You can click "See more" to see this full information',
          })
        )}
        Wrapper={withProps({
          title: 'news update',
        })(HeaderItemInfo)}
      />
      {isSm && (
        <OrganizationImpactReport/>
      )}
      <div className="h-10"/>
    </Wrapper>
  )
}

export default OrganizationInfo
