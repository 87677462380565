import { Result } from 'antd'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {
  branch,
  compose,
  fromRenderProps,
  renderComponent,
  withProps,
} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import { LoadingWrapper } from 'views/Shared'
import { WishareNotFound } from './WishareNotFound'

export const withAppConfig = (
  Component
) => {
  return function Wrapper(props) {
    const appConfig = useAppConfig()
    return (
      <Component
        {...props}
        {...{ appConfig }}
      />
    )
  }
}

export const notFoundOnError = (
  FromConsumer,
  NotFoundPage = WishareNotFound
) =>
  compose(
    fromRenderProps(
      FromConsumer,
      ({
        status,
        isSucceed,
        error_code,
        isLoading = true,
      }) => ({
        status,
        isLoading,
        error_code,
        failed: !isSucceed,
      })
    ),
    branch(
      ({ isLoading }) => !!isLoading,
      () => () =>
        (
          <div className="flex items-center justify-center w-full h-full">
            <LoadingPage />
          </div>
        )
    ),
    branch(
      ({ failed }) => !!failed,
      renderComponent(
        withProps(
          ({ status, error_code }) => ({
            status,
            description: error_code,
          })
        )(NotFoundPage)
      )
    )
  )

export const withAccessPermissions = (
  requiredPermissions = [],
  AccessDenied = () => (
    <Translate>
      {(t) => (
        <LoadingWrapper
          isLoading={true}
          timeout={1000}>
          <Result
            status="403"
            title={t(
              'permission denied'
            )}
          />
        </LoadingWrapper>
      )}
    </Translate>
  )
) =>
  compose(
    fromRenderProps(
      PermissionContext.Consumer,
      (permissions) => ({
        denied:
          requiredPermissions.find(
            (name) =>
              !permissions ||
              !permissions[name]
          ),
      })
    ),
    branch(
      ({ denied }) => denied,
      renderComponent(AccessDenied)
    )
  )
