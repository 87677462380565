export const baseFieldTypes = {
  string: '',
  object: {},
  image: 'image',
  auto: 'auto',
  description: '',
  status: [
    {
      value: -1,
      label: 'pending',
      disabled: true
    },
    {
      value: 1,
      label: 'active',
    },
    {
      value: 9,
      label: 'close',
      activeLabel: 'closed',
    },
  ],
  active: [
    {
      value: 1,
      label: 'active',
    },
    {
      value: 0,
      label: 'inactive',
    },
  ],
  statusAction: [
    {
      value: 1,
      label: 'active',
      activeLabel: 'active',
    },
    {
      value: 9,
      label: 'close',
      activeLabel: 'closed',
    },
  ],
  certified: [
    {
      value: -1,
      label: 'no certified',
    },
    {
      value: 1,
      label: 'certified',
    },
  ],
  privacy: [
    {
      value: 1,
      label: 'public',
    },
    {
      value: 2,
      label: 'private',
    },
  ],
  secret: [
    {
      value: 1,
      label: 'secret',
    },
    {
      value: 0,
      label: 'regular',
    },
  ],
  public: [
    {
      value: 1,
      label: 'search allow',
    },
    {
      value: 0,
      label: 'no searchable',
    },
  ],
}
