import { Input } from 'antd'
import { donor_getDonations_api } from 'apis'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useMemo,
  useState,
} from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import { withProps } from 'recompose'
import { Null } from 'views/Shared'
import DonationRecordItem from 'views/Wishare/Donation/components/DonationRecordItem'
import { DonationItemEntity } from 'views/Wishare/Donation/DonationEntity'
import { DonationItemsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'

const DonorDonationsModalContent = ({
  status,
  donor_id,
  Header = Null,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const t = useTranslate()

  const [keyword, setKeyword] =
    useState()

  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      donor_id,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [donor_id, location.state]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo: donor_getDonations_api,
        query: bindQueryParam({
          id: donor_id,
        }),
        RenderEntity:
          DonationItemEntity,
        RenderItem: withProps({
          withoutContextMenu: true,
          showEvent: true,
        })(DonationRecordItem),
        PaginationMeta: Null,
      },
      withKey: ({ sortBy }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      withValues: ({
        keyword: ignored,
        ...rest
      }) => ({ keyword, ...rest }),
      renderSearchBar: (_) => (
        <Input.Search
          defaultValue={keyword}
          onSearch={(value) =>
            setKeyword(value)
          }
          allowClear={true}
          className="input-search"
          placeholder={t('search')}
        />
      ),
      dependencies: [donor_id],
      renderFilter: Null,
      query_params: filter_actions,
      sortHeader: null,
      SortByWidget: DonationItemsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header />
      {pager_widget}
    </Wrapper>
  )
}

export default DonorDonationsModalContent
