import { getId } from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import {
  branch,
  compose,
  fromRenderProps,
} from 'recompose'
import LinesChart from 'views/Chart/Charts/ChartComponents/LinesChart'
import LoadingPage from 'views/LoadingPage'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import WorkspaceContext from '../WorkspaceContext'
import { InsightsBlockHeader } from './WorkspaceInsights'

export const InsightsChartTypes =
  Object.freeze({
    DEFAULT: 'default',
    CONTENT: 'content',
    CAMPAIGN: 'campaign',
  })

const WorkspaceInsightsChart = ({
  title,
  hashId,
  chartInfo,
  Wrapper = FlexCol,
}) => (
  <Wrapper>
    <InsightsBlockHeader
      id={hashId}
      title={title}
    />
    {_.isEmpty(
      _.get(chartInfo, 'data_values')
    ) ? (
      <LoadingPage />
    ) : (
      <LinesChart
        chartInfo={chartInfo}
      />
    )}
  </Wrapper>
)

const InsightsChartFactory = ({
  type = InsightsChartTypes.DEFAULT,
  ...props
}) => {
  switch (type) {
    case InsightsChartTypes.CAMPAIGN:
      return (
        <WorkspaceInsightsChart
          title="donation/giving"
          {...props}
        />
      )
    case InsightsChartTypes.CONTENT:
      return (
        <WorkspaceInsightsChart
          title="interactive"
          {...props}
        />
      )
    case InsightsChartTypes.DEFAULT:
    default:
      return null
  }
}

export default ({
  type,
  hashId,
  chartInfo,
}) =>
  compose(
    fromRenderProps(
      WorkspaceContext.Consumer,
      ({ organization }) => ({
        item: organization,
        organization_id: getId(
          organization
        ),
      })
    ),
    branch(
      ({ item }) => _.isEmpty(item),
      () => () => <WishareNotFound />
    )
  )(InsightsChartFactory)({
    type,
    hashId,
    chartInfo,
  })
