import {
  Avatar,
  Badge,
  Button,
  Input,
  message,
} from 'antd'
import {
  function_article_createArticle_Api,
  poll_add_Api,
  post_create_Api,
  referral_getReferralCode_Api,
} from 'apis'
import {
  channelModel,
  organizationModel,
} from 'apis/model'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import copyToClipboard from 'copy-to-clipboard'
import { ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import * as moment from 'moment'
import React, {
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import {
  nest,
  withProps,
} from 'recompose'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import LoadingPage from 'views/LoadingPage'
import ServerContext from 'views/Server/ServerContext'
import { Login } from '../Auth'
import CreateArticle from '../CreateArticle'
import CreateImagePost from '../CreateImagePost/CreateImagePost'
import CreatePoll from '../CreatePoll'
import { CreateEventFormModal } from '../Event/Events'
import Members from '../MemberSetting/Members.lazy'
import { CreateChannelFormModal } from './components/Channels'
import Settings from './Settings/Settings'

export const ModalLayout = ({
  name,
  children,
}) => (
  <ServerContext.Consumer>
    {({
      setCurrent,
      product,
      item,
    }) => (
      <FullSideModalLayout
        right
        title={
          name || (
            <span className="font-semibold  flex-1 j">
              <div
                style={{
                  fontSize: '0.8em',
                }}
                className="text-color-300 capitalize">
                {item.title}
              </div>
              {product && (
                <div className="flex items-center  ">
                  <span className="flex justify-center items-center">
                    {product.icon || (
                      <ChannelIcon
                        premium={
                          item.premium
                        }
                        className="mr-2 text-color-100 text-3xl w-8 h-8"
                      />
                    )}
                  </span>
                  <span className="pl-1 flex-1 capitalize">
                    {product.title}
                  </span>
                </div>
              )}
            </span>
          )
        }
        onCancel={() =>
          setCurrent(null)
        }>
        {children}
      </FullSideModalLayout>
    )}
  </ServerContext.Consumer>
)
export const ModalLogin = () => {
  const translate = useTranslate()
  return (
    <ModalLayout
      name={translate(
        'you need login first'
      )}>
      <Login />
    </ModalLayout>
  )
}
const EnchanedCreateImagePost = () => {
  const {
    item,
    addNewPost,
    setCurrent,
  } = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: post_create_Api,
    query: {
      ':prop': item._type,
      ':id': item.id,
    },
    onSuccess: (result) => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error('error'),
  })
  return (
    <div className="p-6">
      <CreateImagePost
        isLoading={isLoading}
        handleAsyncPost={
          handleAsyncPost
        }
      />
    </div>
  )
}
const EnchanedCreateArticle = () => {
  const {
    item,
    addNewPost,
    setCurrent,
  } = useContext(ServerContext)
  const {
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo:
      function_article_createArticle_Api,
    query: {
      ':prop': item._type,
      ':id': item.id,
    },
    onSuccess: (result) => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error('error'),
  })
  return (
    <div className="p-6">
      <CreateArticle
        onCancel={() =>
          setCurrent(null)
        }
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </div>
  )
}
const EnchanedSettings = () => {
  const { setCurrent } = useContext(
    ServerContext
  )
  return (
    <Settings
      onCancel={() =>
        alert(setCurrent(null))
      }
    />
  )
}
const EnchanedCreatePoll = () => {
  const {
    item,
    addNewPost,
    setCurrent,
  } = useContext(ServerContext)
  const {
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: poll_add_Api,
    query: {
      ':prop': item._type,
      ':id': item.id,
    },
    onSuccess: (result) => {
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error('error'),
  })
  return (
    <div className="p-6">
      <CreatePoll
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </div>
  )
}
const Invite = () => {
  const t = useTranslate()
  const { item } = useContext(
    ServerContext
  )
  const [toggle, setToggle] =
    useState(false)
  const [toggleCopy2, setToggleCopy2] =
    useState(false)

  const { response } = useAsync({
    apiInfo:
      referral_getReferralCode_Api,
    values: {
      target_id: item.id,
      target_type: item._type,
    },
  })

  useLayoutEffect(() => {
    let timeout
    if (toggle) {
      timeout = setTimeout(
        () => setToggle(false),
        1500
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toggle])
  useLayoutEffect(() => {
    let timeout
    if (toggleCopy2) {
      timeout = setTimeout(
        () => setToggleCopy2(false),
        1500
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage />

  const inviteLink =
    ROOT_URL +
    '/invite/' +
    _.get(data, 'referral_code')

  const timeout = moment(
    _.get(data, 'created')
  )
    .add(
      _.get(data, 'timeout'),
      'second'
    )
    .fromNow()

  return (
    <div className="flex flex-col py-3 px-5 pb-5 verticalList__large">
      <h2 className="text-xl font-bold">
        {t('Invite your friends to this page')}
      </h2>
      <div className="p-6 w-full flex flex-col rounded-lg background-100">
        <div
          className="w-full relative flex justify-center items-center"
          style={{ height: 200 }}>
          <img
            className="rounded-lg background-100"
            alt={item.title}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={
              item.cover_photo
            }/>
          <Avatar
            style={{
              width: '100px',
              height: '100px',
            }}
            src={item.avatar}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-bold text-lg mt-3">
            {item.title}
          </div>
          <div className="w-fit px-3 py-1 background rounded-full border border-color-50">
            <Badge
                color="blue"
                text={
                  <span className="text-sm font-medium text-color-100 italic">
                    {item.total_members}{' '}
                    {t('members')}
                  </span>
                }
            />
          </div>
        </div>
      </div>
      <div className="pb-3">
        <div className="mb-2 font-light text-sm text-color-200 italic leading-tight">
          {t('Send invitation to join the provider to your friends, by sending a copy of the page link below')}
        </div>
        <div
          onClick={() => {
            copyToClipboard(inviteLink)
            setToggle(true)
          }}>
          <Input
            value={inviteLink}
            suffix={
              <Button
                size="small"
                ghost={toggle}
                className="text-xs rounded-md no-shadow no-text-shadow"
                type="primary">
                {t(
                  toggle
                    ? 'copied'
                    : 'copy'
                )}
              </Button>
            }
          />
        </div>
        <div className="text-xs text-color-400 italic mt-1 mb-2">
          {_.get(data, 'timeout', 0) ===
            0 ||
          _.get(
            data,
            'no_expired',
            0
          ) === 1
            ? t('Permanent link')
            : `${t(
                'your invite link expires'
              )} ${timeout}`}
        </div>

        <div className="mt-4">
          <div className="font-light text-sm text-color-200 italic mb-2 leading-tight">
            {t('or you can copy the link of the page here to send it to your friends in other ways.')}
          </div>
          <div
            onClick={() => {
              copyToClipboard(
                ROOT_URL +
                  organizationModel.getLinkToDetail(
                    item
                  )
              )
              setToggleCopy2(true)
            }}>
            <Input
              value={
                ROOT_URL +
                organizationModel.getLinkToDetail(
                  item
                )
              }
              suffix={
                <Button
                  size="small"
                  ghost={toggleCopy2}
                  className="text-xs rounded-md no-shadow no-text-shadow"
                  type="primary">
                  {t(
                    toggleCopy2
                      ? 'copied'
                      : 'copy'
                  )}
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export const routes = [
  {
    path: 'settings',
    name: 'settings',
    component: EnchanedSettings,
    exact: true,
  },

  {
    path: 'memberList',
    name: 'members',
    component: withProps({
      name: 'members',
    })(nest(ModalLayout, Members)),
    exact: true,
  },

  {
    path: 'share',
    name: 'share',
    component: createRequiredLogin(
      ModalLogin
    )(
      withProps({})(
        nest(ModalLayout, Invite)
      )
    ),
    exact: true,
  },
  {
    path: 'createPoll',
    name: 'Create Poll',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        ModalLayout,
        EnchanedCreatePoll
      )
    ),
    exact: true,
  },
  {
    path: 'createArticle',
    name: 'Create Article',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedCreateArticle),
    exact: true,
  },
  {
    path: 'createImagePost',
    name: 'createImagePost',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        ModalLayout,
        EnchanedCreateImagePost
      )
    ),
    exact: true,
  },
  {
    path: 'createChannel',
    name: 'createChannel',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const { setCurrent } = useContext(
        ServerContext
      )
      const history = useHistory()
      return (
        <CreateChannelFormModal
          {...{
            onCancel: () =>
              setCurrent(null),
            onSuccess: ([__, data]) => {
              history.push(
                channelModel.getLinkToDetail(
                  data.response.data
                )
              )
              setCurrent(null)
            },
          }}
        />
      )
    }),
    exact: true,
  },
  {
    path: 'createEvent',
    name: 'createEvent',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const { setCurrent } = useContext(
        ServerContext
      )
      const history = useHistory()
      return (
        <CreateEventFormModal
          {...{
            onCancel: () =>
              setCurrent(null),
            onSuccess: ([__, data]) => {
              history.push(
                channelModel.getLinkToDetail(
                  data.response.data
                )
              )
              setCurrent(null)
            },
          }}
        />
      )
    }),
    exact: true,
  },
]
