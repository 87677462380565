import { Tabs } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useParams } from 'react-router-dom'
import {
  notEmpty,
  Null,
} from 'views/Shared'
import {
  wishareConfigs,
  wisharePaths,
} from 'views/Wishare/configs'
import { mapPathParam } from 'views/Wishare/functions/routerHelper'
import useNavRouteAction from 'views/Wishare/hooks/useNavRouteAction'
import { NavRoutes } from 'views/Wishare/hooks/useNavRoutes'
import ActivityContext from '../ActivityContext'

const ActivityNav = ({
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const params = useParams()

  const history = useHistory()

  const id = _.get(params, 'id')

  const rootPath = wisharePaths.activity

  const {
    isValidRoute = Null,
    routeEntities = [],
    getDefaultAction = Null,
    activity = {},
  } = useContext(ActivityContext)

  const defaultAction =
    getDefaultAction(rootPath)

  const { currentAction } =
    useNavRouteAction({
      anchor: id,
      defaultAction,
      isAction: isValidRoute,
    })

  const pathTransform = useCallback(
    (path, segment) =>
      [
        _.isEmpty(id)
          ? path
          : mapPathParam(path, {
              id,
            }),
        segment,
      ]
        .filter(notEmpty)
        .join('/'),
    [id]
  )

  const tabPanes = useMemo(
    () =>
      routeEntities
        .filter(
          ({ isInvisible = Null }) =>
            !isInvisible({
              item: activity,
            })
        )
        .map(
          ({
            path,
            breadcrumbName = Null,
          }) => (
            <Tabs.TabPane
              tab={breadcrumbName(t)}
              key={path}>
              <NavRoutes
                rootPath={rootPath}
                routes={routeEntities}
                transform={
                  pathTransform
                }
                defaultAction={
                  defaultAction
                }
              />
            </Tabs.TabPane>
          )
        ),
    [
      t,
      activity,
      rootPath,
      defaultAction,
      routeEntities,
      pathTransform,
    ]
  )

  return (
    <Wrapper
      id="content"
      className="space-y-3 background">
      <Tabs
        tabBarStyle={
          wishareConfigs.styles.tabBar
        }
        destroyInactiveTabPane={true}
        //key={currentAction}
        onChange={(activeKey) => {
          history.push({
            pathname: pathTransform(
              rootPath,
              activeKey
            ),
            hash: '#content',
          })
        }}
        activeKey={currentAction}>
        {tabPanes}
      </Tabs>
    </Wrapper>
  )
}

export default ActivityNav
