import {SelectOutlined} from '@ant-design/icons'
import {Avatar, List, Skeleton,} from 'antd'
import {donor_searchDonors_api} from 'apis'
import {getId, getType,} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import {createValue} from 'components/form/utils'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useCallback, useContext, useEffect, useState,} from 'react'
import {Redirect} from 'react-router-dom'
import LoadingPage from 'views/LoadingPage'
import {getResponseItems, Null,} from 'views/Shared'
import {wishareSettingModalRoutes} from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import {useDebounceSearch} from 'views/Wishare/custom/DebounceSearch'
import {CustomKeys} from 'views/Wishare/CustomKeys'
import {DonorEntity} from 'views/Wishare/Donation/DonationEntity'
import renderClicker from 'views/Wishare/functions/renderers/renderClicker'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'

const ItemWrapper = ({
   item,
   children,
   ...props
}) => (
  <div
    className="py-2 cursor-pointer"
    {...props}>
    {children}
  </div>
)

const renderItem =
  ({actions, renderEmpty = Null}) =>
    (item) => {
      const isEmpty = _.isEmpty(item)

      const avatar =
        getAvatar(item) ||
        _.get(item, 'photo')
      return (
        <div
          key={getId(item)}
          className="w-full px-3 py-2 rounded-lg border border-color-50 background">
          {isEmpty ? (
            renderEmpty()
          ) : (
            <Translate>
              {(t) => (
                  <div className="flex items-center gap-2">
                      {isEmpty ? <EmptyHolder/> : (
                          <div className="flex items-center gap-2 flex-1">
                              <Avatar
                                  size={30}
                                  className="flex flex-center background-100"
                                  src={avatar}>
                                  {String(getTitle(item)).charAt(0).toUpperCase()}
                              </Avatar>
                              <div className="flex flex-col flex-1">
                                  <span className="text-color-000 font-semibold">
                                      {getTitle(item)}
                                  </span>
                                  <span className="text-sm text-primary italic">
                                      {[
                                          !!getType(item) ? t(getType(item)) : "",
                                          !!_.get(item, 'owner') ? t(getType(_.get(item, 'owner'))) : ""
                                      ].filter(e => !!e).join(" - ")}
                                  </span>
                              </div>
                          </div>
                      )}
                      {actions || [
                          <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                              {t('select')}
                          </span>
                      ]}
                  </div>
              )}
            </Translate>
          )}
        </div>
      )
    }

const SearchDonorModalContent = ({
                                   Wrapper = 'div',
                                   ...props
                                 }) => {
  const {setCurrent} = useContext(
    NavigationContext
  )

  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  const params = _.get(
    props,
    'location.state'
  )

  const [organization_id] = [
    _.get(params, 'organization_id'),
  ]

  if (
    _.every(
      [organization_id],
      _.isEmpty
    )
  ) {
    return <Redirect to="/"/>
  }

  const apiInfo = donor_searchDonors_api

  const onSelect = (value) => {
    const result = getId(value) || value
    setCurrent(result)
  }

  return (
    <Wrapper className="space-y-3 py-3 mt-8">
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParam({
          organization_id,
        })}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({response, isLoading}) => {
          if (Boolean(isLoading)) {
            return <LoadingPage/>
          }
          const items = Array.from(
            getResponseItems(
              response
            ) || []
          )
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={renderClicker(
                {
                  renderItem,
                  onClick: onSelect,
                  Wrapper: ItemWrapper,
                }
              )}
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

export const SearchDonorModal = (
  props
) => (
  <AntdConfigWrapper>
    <SearchDonorModalContent
      {...props}
    />
  </AntdConfigWrapper>
)

const DonorPicker = ({
                       item,
                       name,
                       organization_id,
                       onChange = Null,
                     }) => {
  const {
    current,
    history,
    setCurrent = Null,
    handleGoBack = Null,
  } = useContext(NavigationContext)

  const [temp, setTemp] = useState()

  useEffect(() => {
    if (current) {
      setTemp(current)
      handleGoBack()
      onChange(
        createValue(name, [current])
      )
      setCurrent(null)
    }
  }, [
    name,
    current,
    setTemp,
    onChange,
    setCurrent,
    handleGoBack,
  ])

  const edit = useCallback(() => {
    history.push(
      wishareSettingModalRoutes(
        CustomKeys.donor_picker.name,
        {
          organization_id,
        }
      )
    )
  }, [organization_id, history])

  return (
    <div
      onClick={edit}
      className="cursor-pointer">
      <DonorEntity
        item={temp}
        defaultItem={item}>
        {renderItem({
          actions: [
            <SelectOutlined
              size={50}
              className="p-2 text-primary text-xl"
            />,
          ],
          renderEmpty: () => (
            <Skeleton
              avatar={true}
              round={true}
              title={false}
              paragraph={{
                rows: 2,
                width: '100%',
              }}
              className="px-2"
            />
          ),
        })}
      </DonorEntity>
    </div>
  )
}

export default DonorPicker
