import {Avatar, Image} from 'antd'
import { articleGroups_CategoriesSchema } from 'apis'
import { articleSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { renderIf } from 'views/Shared'
import moment from "moment";
import {Photo} from "../../../conts/imageBase64";
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";

const renderArticleGroup = (isSm, item, index) => {

    return (
        <Translate key={index}>
            {(t) => (
                <SelectEntityItem
                    item={item}
                    schema={articleGroups_CategoriesSchema}>
                    {(item) => {
                        if (_.some(
                            [item, _.get(item, 'articles')],
                            _.isEmpty)
                        ) {return null}
                        return (
                            <div className="flex flex-col">
                                <span className="border-b-2 border-primary mb-2 text-primary uppercase font-semibold">
                                  {t(_.get(item, 'idname'))}
                                </span>
                                <div className="flex flex-col gap-3 ml-2">
                                    {Array.from(_.get(item, 'articles', [])).map(
                                        (sub, index) => (
                                            <div key={index} className="flex flex-col p-3 background border border-color-50 hover:shadow-items rounded-lg">
                                                <Link
                                                    to={getLinkToDetail(sub)}
                                                    className="no-underline max-lines-1 font-bold text-base md:text-lg text-color-000">
                                                    {getTitle(sub)}
                                                </Link>
                                                <span className="leading-tight text-color-500 italic text-xs font-light truncate">
                                                    {t('posted at')}
                                                    <span className="text-color-100 font-medium ml-1">
                                                        {moment(_.get(sub, 'created')).format("HH:mm - DD/MM/YYYY")}
                                                    </span>
                                                    {' '}
                                                    {t('by')}
                                                    <span className="text-color-100 font-medium ml-1">
                                                        {_.get(sub, 'creator.name')}
                                                    </span>
                                                </span>
                                                <div className="flex gap-3 mt-2">
                                                    <div style={{lineHeight: 1.8}} className="flex-1 text-sm text-color-300 max-lines-3">
                                                        {_.get(sub, 'description')}
                                                    </div>
                                                    {!isSm && !!_.get(sub, 'cover_photo') && (
                                                        <Image
                                                            preview={false}
                                                            src={_.get(sub, 'cover_photo')}
                                                            fallback={Photo}
                                                            className="w-32 h-20 rounded-md object-cover"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )
                    }}
                </SelectEntityItem>
            )}
        </Translate>
    )
}

const ArticleResult = ({latest_articles = [], categories: article_groups = []}) => {

    const {isSm} = useContext(LayoutContext)

    return (
        <div className="flex flex-col w-full space-y-4">
            <Translate>
                {(t) => (
                    <div className="flex flex-col">
                        <div className="space-y-3">
                            {Array.from(latest_articles).map((transformed_id, index) => (
                                <div className="p-3 background border border-color-50 hover:shadow-items rounded-lg" key={index}>
                                    <SelectEntityItem
                                        item={transformed_id}
                                        schema={articleSchema}>
                                        {(item) => renderIf(item,
                                            <div className="flex flex-col">
                                                <Link
                                                    to={getLinkToDetail(item)}
                                                    className="no-underline max-lines-1 font-bold text-base md:text-lg text-color-000">
                                                    {getTitle(item)}
                                                </Link>
                                                <span className="leading-tight text-color-500 italic text-xs font-light truncate">
                                                    {t('posted at')}
                                                    <span className="text-color-100 font-medium ml-1">
                                                        {moment(_.get(item, 'created')).format("HH:mm - DD/MM/YYYY")}
                                                    </span>
                                                    {' '}
                                                    {t('by')}
                                                    <span className="text-color-100 font-medium ml-1">
                                                        {_.get(item, 'creator.name')}
                                                    </span>
                                                </span>
                                                <div className="flex gap-3 mt-2">
                                                    <div style={{lineHeight: 1.8}} className="flex-1 text-sm text-color-300 max-lines-3">
                                                        {_.get(item, 'description')}
                                                    </div>
                                                    {!isSm && !!_.get(item, 'cover_photo') && (
                                                        <Image
                                                            preview={false}
                                                            src={_.get(item, 'cover_photo')}
                                                            fallback={Photo}
                                                            className="w-32 h-20 rounded-md object-cover"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </SelectEntityItem>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Translate>
            {Array.from(article_groups).map((e, i) =>(
                renderArticleGroup(isSm, e, i)
            ))}
            <div className="h-10"/>
        </div>
    )
}

export default ArticleResult
