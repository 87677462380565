import {Button} from 'antd'
import Null from 'components/NullComponent'
import _ from 'lodash'
import React from 'react'
import {fromRenderProps, mapProps, nest} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import useAsync from '../useAsync'
import useAsyncWithCache from '../useAsyncWithCache'
import useDispatchAsyncAction from '../useDispatchAsyncAction'
import useDispatchAsyncActionWithNotify from '../useDispatchAsyncActionWithNotify'

const AsyncContext = React.createContext(
  {}
)
const Async = ({
  children = Null,
  ...props
}) => {
  const result = useAsync(props)
  return children && children(result)
}
export const AsyncWithCache = ({
  children = Null,
  ...props
}) => {
  const result = useAsyncWithCache(
    props
  )
  return children && children(result)
}
export const AsyncByAction = ({
  action,
  children = null
}) => {
  const [
    data,
    handleAsyncAction
  ] = useDispatchAsyncAction({
    initalAction: action
  })
  const refetch = handleAsyncAction
  return (
    <AsyncContext.Provider
      value={{ ...data, refetch }}>
      {_.isFunction(children)
        ? children(data, refetch)
        : children}
    </AsyncContext.Provider>
  )
}
export const AsyncByActionWithNotify = ({
  children = null,
  action, ...props
}) => {
  const [
    data,
    dispath
  ] = useDispatchAsyncActionWithNotify({
    initalAction: action,
    ...props
  })
  return (
    <AsyncContext.Provider
      value={{ ...data, dispath }}>
      {_.isFunction(children)
        ? children(data, dispath)
        : children}
    </AsyncContext.Provider>
  )
}
export const withAsyncValue = (
  inputs = [],
  Component
) =>
  fromRenderProps(
    AsyncContext.Consumer,
    props => ({
      splitProps: inputs.map(key => {
        return props[key]
      }, {}),
      async: props
    })
  )(
    nest(
      // mapProps(
      //   ({ splitProps, children }) => ({
      //     input: splitProps,
      //     children
      //   })
      // )(Pure),
      mapProps(
        ({ async, children }) => ({
          ...async,
          children
        })
      )(Component)
    )
  )
export const Loading = withAsyncValue(
  ['isLoading'],
  ({ isLoading, children, ...rest }) =>
    isLoading ? (
      children ? (
        children({
          isLoading,
          ...rest
        })
      ) : (
        <LoadingPage></LoadingPage>
      )
    ) : null
)
export const Error = withAsyncValue(
  ['error', 'children'],
  ({
    error,
    refetch,
    children,
    ...rest
  }) =>
    error ? (
      children ? (
        children({
          error,
          refetch,
          ...rest
        })
      ) : (
        <Button
          onClick={() => refetch()}
          className="border border-gray-300"
          type="primary"
          ghost>
          Try again
        </Button>
      )
    ) : null
)
export const Success = withAsyncValue(
  ['success', 'result'],
  ({
    success,
    result,
    children = Null,
    ...rest
  }) =>
    success
      ? children({
        success,
        result,
        children,
        ...rest
      })
      : null
)
export default Async
