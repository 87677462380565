import { LoginContext } from 'components/LoginContext'
import _ from 'lodash'
import React, {
  useContext
} from 'react'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import {
  branch,
  compose,
  renderNothing,
  withProps
} from 'recompose'
import { getLogin } from 'redux/selectors'
import LoadingPage from 'views/LoadingPage'

const NewTemplateLazy = Loadable({
  loader: () =>
    import('./TemplatePage'),
  loading: LoadingPage
})

const NewTemplate = props => (
  <NewTemplateLazy {...props} />
)

export default compose(
  connect(state => ({
    authenticated: getLogin(state)
  })),
  branch(
    ({ authenticated }) =>
      authenticated === undefined,
    renderNothing
  ),
  withProps(props => {
    const login = useContext(
      LoginContext
    )
    const edit =
      !!login &&
      _.get(login, 'edit_system', false)
    return { edit }
  })
)(NewTemplate)
