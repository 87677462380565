import {
  Button,
  Card,
  ConfigProvider,
  List,
  Modal,
  Spin,
} from 'antd'
import { getId } from 'apis/model/base'
import {
  profile_addEducation_Api,
  profile_deleteEducation_Api,
  profile_updateEducation_Api,
} from 'apis/profile'
import classNames from 'classnames'
import { handleconfirm } from 'components/Confirm'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  IoAdd, IoCalendarOutline,
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { nest } from 'recompose'
import {
  getResponseItem,
  Null,
  renderFalse,
  renderOwnChild,
  renderSelf,
  updateArray,
} from 'views/Shared'
import {
  ObserverContext,
  ObserverProvider,
} from 'views/Shared/components/ObservedList'
import CRUD from 'views/Shared/enums/CRUD'
import { wishareConfigs } from 'views/Wishare/configs'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import { RenderEmptyMeta } from 'views/Wishare/custom/AntdConfigWrapper'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'

const formSchema = [
  {
    title: 'date',
    children: [
      {
        name: 'start_at',
        component:
          createDateRangerPicker([
            'start_at',
            'end_at',
          ]),
      },
    ],
  },
  {
    title: 'school',
    children: [
      {
        name: 'school',
        component:
          createControlledFormField({
            placeholder: 'school',
          }),
      },
    ],
  },
  {
    title: 'certificate',
    children: [
      {
        name: 'certificate',
        component:
          createControlledFormField({
            placeholder: 'certificate',
          }),
      },
    ],
  },
  {
    title: 'graduated',
    children: [
      {
        name: 'graduated',
        component: withAdvancedOptions(
          wishareFieldTypes.noOrYes
        )(RadioOptions),
      },
    ],
  },
]

const renderDate = ([
  start_at,
  end_at,
]) => (
  <Translate>
    {(t) => (
      <div className="font-semibold flex items-center space-x-2">
        <IoCalendarOutline className="text-color-400"/>
        <span className="font-bold text-xs text-color-000">
          {[
            {
              value: start_at,
              emptyValue: '',
              render: (text) =>
                  [
                    t('from'),
                    String(text),
                  ].join(' '),
            },
            {
              value: end_at,
              emptyValue: '',
              render: (text) =>
                  [
                    t('to'),
                    String(text),
                  ].join(' '),
            },
          ]
              .map(
                  ({
                     value,
                     render = renderSelf,
                     emptyValue = renderFalse,
                   }) =>
                      Boolean(
                          !value ||
                          !moment(
                              value
                          ).isValid()
                      )
                          ? emptyValue
                          : render(
                              moment(
                                  value
                              ).format(
                                  wishareConfigs.dateFormat
                              )
                          )
              )
              .join(' ')}
        </span>
      </div>
    )}
  </Translate>
)

const renderItem =
  (actions) =>
  (
    {
      school,
      end_at,
      start_at,
      graduated,
      certificate,
      ...rest
    },
    index
  ) =>
    (
      <Translate key={index}>
        {(t) => (
          <List.Item
            style={{
              padding: '0.25rem 0',
            }}
            className="items-start"
            extra={actions}>
            <List.Item.Meta
              title={renderDate([start_at, end_at,])}
              description={
                <div
                  style={{
                    paddingLeft:
                      '0.5rem',
                  }}
                  className={classNames(
                    wishareClassNames.inline_2,
                    'gap-2 unordered-list'
                  )}>
                  {[
                    {
                      label: 'school',
                      value: school,
                    },
                    {
                      label: 'status',
                      value: graduated,
                      transform: (
                        value
                      ) =>
                        t(
                          Boolean(value)
                            ? 'graduated'
                            : 'not graduated'
                        ),
                    },
                    {
                      label:
                        'certificate',
                      value:
                        certificate,
                    },
                  ].map(
                    (
                      {
                        label,
                        value,
                        transform = renderSelf,
                      },
                      index
                    ) => (
                      <div
                        key={index}
                        className="unordered-item font-light italic text-xs text-color-500">
                        {`${t(
                          label
                        )}: `}
                        <span className="font-medium text-color-100 capitalize">
                          {transform(
                            value
                          )}
                        </span>
                      </div>
                    )
                  )}
                </div>
              }
            />
          </List.Item>
        )}
      </Translate>
    )

const FormContainer = ({
  action,
  values,
  onCancel = Null,
  setValue = Null,
  handleSubmit = Null,
}) => {
  const t = useTranslate()

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const onError = notifyOnError(t)

  const {
    type: action_type,
    value: current_item,
  } = action || {}

  const {
    isLoading: isCreating,
    handleAsyncAction: handleCreate,
  } = useAsyncAction({
    apiInfo: profile_addEducation_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      addPost(getResponseItem(response))
    },
    onError,
  })

  const {
    isLoading: isUpdating,
    handleAsyncAction: handleUpdate,
  } = useAsyncAction({
    query: bindQueryParam({
      id: getId(current_item),
    }),
    apiInfo:
      profile_updateEducation_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      const newItem =
        getResponseItem(response)
      setValue(getId(newItem), newItem)
    },
    onError,
  })

  const params =
    action_type === CRUD.UPDATE
      ? {
          value: current_item,
          isLoading: isUpdating,
          onSubmit: handleUpdate,
        }
      : {
          value: '',
          isLoading: isCreating,
          onSubmit: handleCreate,
        }

  return (
    <EducationForm
      {...params}
      action={action_type}
      educations={values}
      handleSubmit={handleSubmit}
    />
  )
}

const EducationForm = ({
  value,
  isLoading,
  educations = [],
  onSubmit = Null,
  handleSubmit = Null,
  action = CRUD.CREATE,
  ...props
}) => {
  const t = useTranslate()

  const defaultValues = {
    graduated: 0,
  }

  const initialValues = {
    graduated:
      _.get(value, 'graduated') ||
      defaultValues.graduated,
    school: _.get(value, 'school'),
    certificate: _.get(
      value,
      'certificate'
    ),
    end_at: _.get(value, 'end_at'),
    start_at: _.get(value, 'start_at'),
  }

  return (
    <Spin spinning={!!isLoading}>
      <Formik
        enableReinitialize={true}
        validationSchema={yupShape({
          start_at:
            requiredString(
              t
            ).nullable(),
          school: requiredString(t),
          certificate:
            requiredString(t),
        })}
        initialValues={initialValues}
        onSubmit={({
          graduated,
          ...values
        }) => {
          handleSubmit(onSubmit)({
            ...values,
            ...(graduated ||
            graduated === 0
              ? {
                  graduated:
                    Number(graduated),
                }
              : {}),
          })
        }}>
        <FlexCol className="space-y-2">
          <FieldsFactory
            formSchema={formSchema}
          />
          <FormActionBar
            submitText={
              action === CRUD.CREATE
                ? 'create'
                : 'save'
            }
            background={false}
          />
        </FlexCol>
      </Formik>
    </Spin>
  )
}

const EditProfileEducation = ({
  value,
  readOnly = false,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [currentAction, setAction] =
    useState({
      type: undefined,
      value: undefined,
    })

  const [educations, setEducations] =
    useState(Array.from(value || []))

  const {
    posts = [],
    updatePost = Null,
    removePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: deleteEducation,
  } = useAsyncAction({
    apiInfo:
      profile_deleteEducation_Api,
    onError: notifyOnError(t),
    onSuccess: (
      result,
      { response }
    ) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      const deletedId = getId(
        getResponseItem(response)
      )
      if (deletedId) {
        removePost(deletedId)
        setEducations([
          ...educations.filter(
            (education) =>
              getId(education) !==
              deletedId
          ),
        ])
      }
    },
  })

  const remove = useCallback(
    (item) => {
      handleconfirm({
        title: t(
          'are you sure delete this'
        ),
        okText: t('sure'),
        cancelText: t('no'),
        okButtonProps: {
          type: 'primary',
          danger: true,
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          const id = getId(item)
          if (id) {
            deleteEducation(
              {},
              bindQueryParam({ id })
            )
          }
        },
      })
    },
    [deleteEducation]
  )

  const onCancel = () => {
    setAction({})
  }

  return (
    <Wrapper>
      <Card
        size="small"
        title={t('education')}
        style={{
          borderRadius: '0.5rem',
          border: '1px solid rgb(218 218 218)',
        }}
        headStyle={{
          fontStyle: 'italic',
          color: 'var(--text-color-400)',
          fontWeight: '500',
          background: 'var(--background-100)',
          borderBottom: 'none',
          borderRadius: '0.45rem 0.45rem 0 0',
        }}
        extra={
          readOnly ? undefined : (
            <Button
              ghost={true}
              size="small"
              type="primary"
              icon={<IoAdd />}
              onClick={() =>
                setAction({
                  type: CRUD.CREATE,
                })
              }
              className="flex items-center space-x-1 rounded-md no-shadow no-text-shadow">
              <span className="text-xs">
                {t('add')}
              </span>
            </Button>
          )
        }>
        <ConfigProvider
          renderEmpty={RenderEmptyMeta}>
          <List
            dataSource={[
              ...educations,
              ...posts,
            ]}
            renderItem={(item, index) =>
              renderItem(
                readOnly ? undefined : (
                  <div className="flex gap-2">
                    <div
                      onClick={() =>
                        remove(item)
                      }
                      className="w-7 h-7 p-1 rounded border border-red-500 cursor-pointer flex items-center justify-center">
                      <IoTrashOutline className="text-red-500" />
                    </div>
                    <div
                      className="w-7 h-7 p-1 rounded border border-primary cursor-pointer flex items-center justify-center"
                      onClick={() => {
                        setAction({
                          type: CRUD.UPDATE,
                          value: item,
                        })
                      }}>
                      <IoCreateOutline className="text-primary" />
                    </div>
                  </div>
                )
              )(item, index)
            }
          />
        </ConfigProvider>
      </Card>
      <Modal
        className="custom-modal"
        footer={null}
        closable={false}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={currentAction.type}>
        <FormContainer
          values={educations}
          onCancel={onCancel}
          setValue={(id, value) => {
            const values = updateArray(
              educations
            )(id, value)
            if (_.isArray(values)) {
              updatePost(id, value)
              setEducations(values)
            }
          }}
          action={currentAction}
          handleSubmit={(submit) =>
            (values) => {
              submit(values)
            }}
        />
      </Modal>
    </Wrapper>
  )
}

export default nest(
  ObserverProvider,
  EditProfileEducation
)
