import {Button, Col, Row} from 'antd'
import {PageNotFoundSvg} from 'components/Svgs/PageNotFoundSvg'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'

const NoFoundPage = () => {
  const t = useTranslate()
  return (
    <main
      style={{
        minHeight: 'calc(100Vh - 100px)'
      }}
      className="flex items-center justify-center overflow-hidden">
      <Row gutter={12} className="p-3">
        <Col
          xs={24}
          lg={12}
          style={{
            minHeight:
              'calc(50Vh - 50px)',
            alignSelf: 'center'
          }}>
          <div className="justify-center items-center">
            <PageNotFoundSvg/>
          </div>
        </Col>
        <Col
          xs={24}
          lg={12}
          style={{
            minHeight:
              'calc(50Vh - 50px)'
          }}>
          <div className="justify-center items-center verticalList__medium">
            <h2
              style={{
                fontSize: '6rem'
              }}>
              404
            </h2>
            <h2 className="text-4xl">
                {t('UH OH! You\'re lost.')}
            </h2>
            <div>
              {t(
                'The page you are looking for does not exist. Tap the button below to return to the homepage.'
              )}
            </div>
            <Link
              to="/">
              <Button type="primary" className="rounded-lg no-shadow no-text-shadow mt-2">
                {t('home')}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </main>
  )
}

export default NoFoundPage
