export function getReceiverName(
  givingItem
) {
  if (!givingItem) return null

  return !!givingItem.anonymous
    ? 'anonymous'
    : !!givingItem.beneficiary &&
      !!givingItem.beneficiary.id
    ? givingItem.beneficiary.name ||
      givingItem.beneficiary.title
    : givingItem.beneficiary_name ?? givingItem.alias_name
}
