import { Select } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  deepTranslate,
  Null,
} from 'views/Shared'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/Wishare/custom/AntdConfigWrapper'
import { reactSelectComponents } from 'views/Wishare/custom/ReactSelectComponents'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'

const FilterSelectField = ({
  name,
  value,
  onChange,
  options,
  isLoading,
  className,
  ...props
}) => (
  <AntdConfigWrapper
    renderEmpty={RenderEmptyMeta}>
    <Select
      value={
        _.find(options, { value })
          ? value
          : undefined
      }
      showSearch={true}
      allowClear={true}
      onChange={(value) => {
        onChange(name, value)
      }}
      loading={!!isLoading}
      optionFilterProp="label"
      className={classNames(
        'CenterSelector w-full',
        className
      )}
      options={
        !!isLoading ? [] : options
      }
      suffixIcon={
        !!isLoading ? undefined : (
          <reactSelectComponents.DropdownIndicator />
        )
      }
      {...props}
    />
  </AntdConfigWrapper>
)

const ReportAdvancedFilter = ({
  owner_id,
  owner_type,
  defaultValue,
  value: filter = {
    currency_code: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  const options = useMemo(
    () =>
      [
        ...wishareFieldTypes.price_units,
        ...wishareFieldTypes.bloodUnits,
      ]
        .map(({ label, value }) => ({
          label,
          value,
        }))
        .filter(
          (item) =>
            !!_.get(item, 'value')
        )
        .map(deepTranslate(t)),
    [t]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <FilterSelectField
        options={options}
        className="w-full flex flex-1"
        name="currency_code"
        defaultValue={_.get(
          defaultValue,
          'currency_code'
        )}
        value={filter.currency_code}
        placeholder={t('unit')}
        onChange={(name, value) => {
          handleChange(name, value)
        }}
      />
    </div>
  )
}

export default ReportAdvancedFilter
