import { Avatar, Input } from 'antd'
import { getId } from 'apis/model/base'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useMemo,
  useState,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { renderIf } from 'views/Shared'
import { beneficiaryApi } from 'views/Wishare/apis'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import EntityList from 'views/Wishare/EntityList'
import { GivingItemsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import BeneficiaryContext from '../BeneficiaryContext'

const renderGivingHistoryItem = (
  item
) => {
  const {
    referral_email,
    referral,
    transfer_dt,
    referral_phone_number,
    giving_items,
    giving_event,
  } = item || {}

  return (
    <Translate key={getId(item)}>
      {(t) => (
        <div className="p-3 border border-color-50 rounded-lg">
          <div className="flex flex-col">
            {transfer_dt && (
              <span className="text-sm text-color-000 font-semibold leading-tight">
                {`${t('date')} ${moment(
                  transfer_dt
                ).format(
                  'DD/MM/YYYY'
                )}`}
              </span>
            )}
            <div className="flex flex-wrap items-baseline gap-1">
              <div className="flex items-center gap-1">
                <span className="text-xs text-color-400 font-light italic whitespace-no-wrap">
                  {t('it received')}
                </span>
                <span className="text-xs text-color-400 font-light italic whitespace-no-wrap">
                  {t('part given from')}
                </span>
              </div>
              <Link
                to={getLinkToDetail(
                  giving_event
                )}
                className="font-bold text-secondary max-lines-1">
                {getTitle(giving_event)}
              </Link>
            </div>
          </div>
          <div className="flex items-center gap-2 mt-1">
            {Array.from(
              giving_items || []
            ).map((e, index) => {
              const {
                type_cd,
                item_name,
                unit_name,
                giving_value,
                giving_qtty,
                reg_giving_value,
              } = e || {}
              return (
                <React.Fragment
                  key={index}>
                  {type_cd ===
                  'money' ? (
                    <div className="w-full flex items-baseline gap-1 text-sm font-semibold text-primary">
                      <span>
                        {Number(
                          reg_giving_value
                        ).toLocaleString()}
                      </span>
                      <span>
                        {unit_name}
                      </span>
                    </div>
                  ) : (
                    <div className="w-full flex items-baseline justify-between text-sm">
                      <div className="flex items-baseline gap-1 font-semibold text-primary">
                        <span>
                          {' - '}
                          {giving_qtty}
                        </span>
                        <span>
                          {unit_name}
                        </span>
                        <span className="font-light">
                          {`(${item_name})`}
                        </span>
                      </div>
                      <div className="flex items-center gap-1 font-semibold text-primary">
                        <span className="italic text-xs font-light text-color-400">
                          {t(
                            'equivalent to'
                          )}
                        </span>
                        <span>
                          {Number(
                            giving_value
                          ).toLocaleString()}
                        </span>
                        <span>
                          {_.get(
                            giving_event,
                            'target_unit_name'
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          {referral && (
            <div className="mt-5 pt-2 border-t border-color-50">
              <div className="font-light text-sm text-color-400 italic mb-2 leading-tight">
                {t(
                  'referral information'
                )}
              </div>
              <div className="flex items-center gap-2 mb-1">
                <Avatar
                  src={getAvatar(
                    referral
                  )}
                  size={30}
                  icon={<IoPerson />}
                  className="flex items-center justify-center background-100"
                />
                <Link
                  to={getLinkToDetail(
                    referral
                  )}
                  className="text-sm text-color-000 font-medium">
                  {getTitle(referral)}
                </Link>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {referral_phone_number && (
                  <span className="text-sm text-color-000 italic">
                    <span className="text-xs text-color-500 font-light mr-1">
                      {`${t(
                        'Phone Number'
                      )} : `}
                    </span>
                    {
                      referral_phone_number
                    }
                  </span>
                )}
                {referral_email && (
                  <span className="text-sm text-color-000 italic">
                    <span className="text-xs text-color-500 font-light mr-1">
                      {`${t(
                        'email'
                      )} : `}
                    </span>
                    {referral_email}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Translate>
  )
}

const InsightsContent = ({
  empty = null,
  giving_items = [],
}) => {
  if (_.isEmpty(giving_items)) {
    return empty
  }

  return Array.from(giving_items).map(
    (item, index) => {
      const {
        item_name,
        unit_name,
        target_unit_name,
        total_giving_value = 0,
        total_giving_qtty = 0,
      } = item || {}
      return (
        <Translate key={index}>
          {(t) => (
            <div className="flex flex-col">
              <span className="italic text-xs font-medium text-color-200">
                {'- '}
                {item_name ===
                target_unit_name
                  ? t('money')
                  : t(item_name)}
                {' : '}
              </span>
              <div className="flex items-center ml-2 gap-1">
                <span className="text-sm text-primary font-semibold">
                  {Number(
                    total_giving_qtty
                  ).toLocaleString()}
                </span>
                <span className="text-sm text-primary font-semibold flex-1">
                  {unit_name}
                </span>
                {renderIf(
                  item_name !==
                    'blood' &&
                    item_name !==
                      target_unit_name,
                  <React.Fragment>
                    <span className="text-2xs italic font-light text-color-400">
                      {t(
                        'equivalent to'
                      )}
                    </span>
                    <span className="text-sm text-primary font-semibold">
                      {Number(
                        total_giving_value
                      ).toLocaleString()}{' '}
                      {target_unit_name}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Translate>
      )
    }
  )
}

export const BeneficiaryHistory = ({
  beneficiary,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const beneficiary_id = getId(
    beneficiary
  )

  const [sort_by, set_sort_by] =
    useState()

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  const apiInfo =
    beneficiaryApi.beneficiary_getGivings_api

  const refreshKey = useMemo(
    () =>
      [
        mapPathParam(apiInfo.path, {
          id: beneficiary_id,
        }),
        keyword,
        sort_by,
      ].join('/'),
    [
      keyword,
      sort_by,
      beneficiary_id,
      apiInfo,
    ]
  )

  const { total_giving_items = [] } =
    beneficiary || {}

  if (_.isEmpty(beneficiary))
    return null

  return (
    <Wrapper className="flex flex-col space-y-3">
      <div className="flex flex-col">
        <span className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
          {t('giving insights')}
        </span>
        <div className="p-3 flex flex-col gap-1 border border-color-50 rounded-lg">
          <InsightsContent
            empty={
              <span className="text-color-300 text-sm italic">
                {t('not added yet')}
              </span>
            }
            giving_items={
              total_giving_items
            }
          />
        </div>
      </div>
      <Input.Search
        className="input-search"
        placeholder={t(
          'search with giving code'
        )}
        onSearch={(value) =>
          onSearch(value)
        }
      />
      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {t(
              'history of receiving and giving'
            )}
          </span>
          <div className="flex items-end justify-end">
            <GivingItemsSortBy
              value={sort_by}
              onSelect={(value) =>
                set_sort_by(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <EntityList
            apiInfo={apiInfo}
            refreshKey={refreshKey}
            renderItem={
              renderGivingHistoryItem
            }
            query={bindQueryParam({
              id: beneficiary_id,
            })}
            values={{
              keyword,
              sort_by,
            }}
            RenderEntity={
              React.Fragment
            }
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    BeneficiaryContext.Consumer,
    ({ beneficiary }) => ({
      beneficiary,
    })
  )
)(BeneficiaryHistory)
