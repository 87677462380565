import {
  Alert,
  Button,
  Input,
} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  IoCaretDown,
  IoFilter,
} from 'react-icons/io5'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import { Null } from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { DonationsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import {
  getDefaultField,
  StatusFilterTypes,
} from 'views/Wishare/wishareFieldTypes'
import DonationChart from '../components/DonationChart'
import DonationItemList from '../components/DonationItemList'
import DonationContext from '../DonationContext'

const filterItems = [
  {
    key: StatusFilterTypes.Public.key,
    label: 'donated items',
    icon: (
      <IoCaretDown className="text-color-300" />
    ),
    status:
      StatusFilterTypes.Public.value,
  },
  {
    key: StatusFilterTypes.Pending.key,
    label: 'registered list',
    icon: (
      <IoCaretDown className="text-color-300" />
    ),
    isInvisible: ({ edit }) => !!!edit,
    status:
      StatusFilterTypes.Pending.value,
  },
]

export const DonationList = ({
  donation,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  const filterOptions = useMemo(
    () =>
      donation
        ? filterItems
            .filter(
              ({
                isInvisible = Null,
              }) =>
                !isInvisible(donation)
            )
            .map(
              ({
                label,
                isInvisible,
                ...rest
              }) => ({
                label: t(label),
                ...rest,
              })
            )
        : [],
    [t, donation]
  )

  const [sort, setSort] = useState()

  const [filter, setFilter] = useState()

  const selectedOption = useMemo(
    () =>
      _.find(
        filterOptions,
        ({ key }) => key === filter
      ) || {},
    [filter, filterOptions]
  )

  useEffect(() => {
    setFilter(
      _.get(
        getDefaultField(filterOptions),
        'key'
      )
    )
  }, [filterOptions])

  if (_.isEmpty(donation)) return null

  return (
    <Wrapper className="flex flex-col space-y-3 pb-16">
      <div className="p-3 uppercase text-lg md:text-xl text-secondary border border-secondary rounded-lg text-center font-bold">
        {t('grateful description')}
      </div>

      <DonationChart />

      {!!_.get(
        donation,
        'total.reg_donation_count'
      ) &&
        !!_.get(donation, 'edit') &&
        filter !== 'pending' && (
          <Alert
            type={'warning'}
            className="flex w-full p-1"
            message={
              <div
                className="flex items-center justify-between text-primary cursor-pointer"
                onClick={() => {
                  setFilter('pending')
                }}>
                <div className="font-semibold leading-tight text-sm md:text-base text-yellow-500">
                  {t(
                    'donation event has the unfinished items'
                  )}
                </div>
                <div className="ml-2 font-medium italic text-sm text-yellow-500 hover:text-primary">
                  {t('view')}
                </div>
              </div>
            }
          />
        )}
      <div className="flex flex-1 space-x-3">
        <ItemContextMenu
          items={filterOptions.map(
            (option) =>
              _.omit(option, ['status'])
          )}
          onMenuSelect={(key) =>
            setFilter(key)
          }
          icon={
            <Button className="rounded-lg border-none">
              <div className="flex items-center space-x-3">
                <IoFilter className="text-color-300" />
                <span className="font-medium">
                  {selectedOption.label}
                </span>
                <IoCaretDown className="text-color-300" />
              </div>
            </Button>
          }
        />
        <Input.Search
          className="input-search"
          placeholder={t(
            'search with donation code'
          )}
          onSearch={(value) =>
            onSearch(value)
          }
        />
      </div>

      <div className="w-full flex flex-col">
        <div className="flex items-center justify-between pb-1 mb-3 border-b">
          <span className="uppercase text-sm font-semibold text-color-200 tracking-wide">
            {t('list of contributors')}
          </span>
          <div className="flex items-end justify-end">
            <DonationsSortBy
              value={sort}
              onSelect={(value) =>
                setSort(value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <DonationItemList
            sort_by={sort}
            keyword={keyword}
            filtered_by={filter}
            isFiltered={(status) =>
              Number(status) ===
              Number(
                _.get(
                  _.find(
                    filterOptions,
                    {
                      key: filter,
                    }
                  ),
                  'status',
                  0
                )
              )
            }
          />
        </div>
        <div className="block md:hidden h-16" />
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonationContext.Consumer,
    ({ donation }) => ({ donation })
  )
)(DonationList)
