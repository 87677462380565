import Translate from 'modules/local/Translate'
import React from 'react'

const ListHeader = ({
  title,
  extra,
}) => (
  <Translate>
    {(t) => (
      <div className="flex items-center text-lg font-semibold text-primary uppercase">
        {title && (
          <span>{t(title)}</span>
        )}
        {extra && (
          <div className="flex flex-1 justify-end">
            {extra}
          </div>
        )}
      </div>
    )}
  </Translate>
)

export default ListHeader
