import { createLazyComponent } from 'helpers/createLazyComponent'
import createStickySider from 'views/Wishare/factory/createStickySider'

const DonorSiderWidgetsLazy =
  createLazyComponent(() =>
    import('./DonorSiderWidgets')
  )

const DonorSider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(DonorSiderWidgetsLazy)

export default DonorSider
