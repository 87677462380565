import Icon from '@ant-design/icons'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import { FlexCol } from '../Templates/ItemTemplate'
import {showConfirm} from "../../../components/Feed/TimeLineFeed";

const FormListHeader = ({
  title,
    subTitle,
  extra,
  onRemove,
  className,
}) => {
  const t = useTranslate()
  return (
    <div
      className={classNames(
        'flex',
        className
      )}>
      <FlexCol className="font-semibold">
        {title}
        {subTitle}
      </FlexCol>
      <div className="flex flex-end gap-2">
        {extra}
        {_.isFunction(onRemove) && (
          <div
            onClick={onRemove}
            className="flex items-center space-x-1 text-red-500 cursor-pointer">
            <Icon
              component={IoTrashOutline}
            />
            <span className="text-xs">
              {t('remove')}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormListHeader
