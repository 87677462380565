import { Col, Spin } from 'antd'
import classNames from 'classnames'
import Pure from 'components/Pure'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { nest } from 'recompose'
import ToggleView, {
  ViewTypes,
} from 'views/MainPage/ToggleView'
import useDismiss from './handlers/useDismiss'
import './styles/Segmented.css'
import {
  Null,
  renderOwnChild,
} from './typedefs'

export const withWrapper = ({
  Wrapper = renderOwnChild,
  ...props
}) => {
  return (Component) => {
    return (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    )
  }
}

const CustomPagingHeader = ({
  title = '',
  children,
  ...props
}) => {
  const t = useTranslate()
  return (
    <div
      className="flex items-center space-x-3 py-1 border-b-2 border-primary"
      {...props}>
      <h2 className="uppercase font-bold text-primary flex-1 truncate">
        {t(title)}
      </h2>
      {children}
    </div>
  )
}

const DefaultWrapper = ({
  children,
  Component = 'span',
  ...props
}) => (
  <Component {...props}>
    {children}
  </Component>
)

const PaddingWrapper = ({
  children,
  padding = '0.5rem',
  Component = 'div',
  ...props
}) => (
  <Component
    style={{ padding }}
    {...props}>
    {children}
  </Component>
)

const LoadingWrapper = ({
  children,
  center = true,
  timeout = 3000,
  Wrapper = 'div',
  isLoading = false,
  ...props
}) => {
  const [isWaiting] = useDismiss({
    isLoading,
    timeout,
  })

  const loading = useMemo(
    () => (
      <Wrapper
        className={classNames(
          'p-3 flex w-full',
          !!center && 'justify-center'
        )}>
        <Spin
          {...props}
          spinning={Boolean(isLoading)}
        />
      </Wrapper>
    ),
    [center, isLoading, props]
  )
  return isWaiting
    ? loading
    : children || null
}

const ToggleViewHeaderWrapper = ({
  title,
  children,
  renders = [],
  Wrapper = Col,
  Actions = Null,
  defaultView = ViewTypes.HEADLINE,
}) => {
  const [renderLeft, renderRight] =
    renders
  return (
    <ToggleView
      defaultView={defaultView}
      renderListView={renderLeft}
      renderHeadlineView={renderRight}>
      {({
        switchComponent,
        renderItem,
        ...rest
      }) => (
        <Wrapper className="p-col-0">
          <CustomPagingHeader
            title={title}>
            <Actions />
            {switchComponent}
          </CustomPagingHeader>
          {children(renderItem, {
            ...rest,
          })}
        </Wrapper>
      )}
    </ToggleView>
  )
}

const HighLightHeader = ({
  title,
  style,
  children,
  className,
  extra = null,
  Spacing = 'div',
  Title = renderOwnChild,
}) => {
  const t = useTranslate()
  const _title = t(title || '')
  return (
    <div
      className={classNames(
        'flex-col space-y-3',
        className
      )}>
      <div
        style={style}
        className="flex items-center border-l-2 border-primary background-200 p-1">
        <Title title={_title}>
          <div className="px-2 text-lg font-bold capitalize">
            {_title}
          </div>
        </Title>
        <Spacing className="flex flex-1" />
        {extra}
      </div>
      {children}
    </div>
  )
}

const FullContentWrapper = ({
  children,
  style,
  ...props
}) => (
  <div
    className="p-2 md:p-3 block w-full h-full"
    style={{
      ...style,
      minHeight: '80vh',
    }}
    {...props}>
    {children}
  </div>
)

export const withComp = (Component) =>
  nest(({ children }) => {
    if (_.isFunction(children)) {
      return children()
    }
    return children
  }, Component)

export const withPureComp = (
  Component
) => nest(Pure, Component)

export const withPureChild = (
  children
) => nest(Pure, () => children)

export {
  PaddingWrapper,
  DefaultWrapper,
  LoadingWrapper,
  HighLightHeader,
  CustomPagingHeader,
  FullContentWrapper,
  ToggleViewHeaderWrapper,
}
