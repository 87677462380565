import { Avatar } from 'antd'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  compose,
  fromRenderProps,
  withProps,
} from 'recompose'
import { wisharePaths } from 'views/Wishare/configs'
import { DonorPagerFooter } from 'views/Wishare/factory/createPagerFooter'
import { mapPathParam } from 'views/Wishare/functions/routerHelper'
import DonorContext from '../../Donor/DonorContext'
import { OverlayItemBanner } from '../../Templates/ItemTemplate'
import DonorMyDonations from '../components/DonorMyDonations'
import { donorRouteEntities } from '../donorRouteEntities'

export const DonorOverview = ({
  donor,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  if (_.isEmpty(donor)) return null

  const {
    id,
    owner,
    owner_id,
    owner_type,
  } = donor

  return (
    <Wrapper className="space-y-6">
      <HeaderItemInfo
        title="account"
        hidden={_.isEmpty(owner_id)}>
        {owner_id ? (
          <div className="border border-color-50 rounded-lg relative">
            <OverlayItemBanner
              type={_.get(
                donor,
                'owner_type'
              )}
            />
            <div className="h-7" />
            <div className="p-2 flex items-center gap-2">
              <Avatar
                src={_.get(
                  donor,
                  'owner.avatar'
                )}
                className="background-100 flex flex-center">
                <span className="uppercase text-color-100">
                  {_.first(
                    _.get(
                      donor,
                      'owner.name'
                    )
                  )}
                </span>
              </Avatar>
              <Link
                to={getLinkToDetail(
                  donor.owner
                )}
                className="font-semibold text-color-org no-underline flex-1">
                {getTitle(donor.owner)}
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center border border-color-50 rounded-lg px-3 py-8 space-y-3">
            <span className="font-bold text-color-200">
              {t(
                'account not linked yet'
              )}
            </span>
          </div>
        )}
      </HeaderItemInfo>

      <HeaderItemInfo
        hidden={_.isEmpty(
          donor?.total_donation_items
        )}
        title={'donation insights'}>
        <div className="p-3 flex flex-col border border-color-50 rounded-lg">
          {_.get(
            donor,
            'total_donation_items',
            []
          ).length > 0 ? (
            _.get(
              donor,
              'total_donation_items',
              []
            ).map((e, index) => (
              <div
                className="flex flex-col"
                key={index}>
                <span className="italic text-xs font-medium text-color-200">
                  {'- '}
                  {_.get(
                    e,
                    'item_name'
                  ) ===
                  _.get(
                    e,
                    'target_unit_name'
                  )
                    ? t('money')
                    : t(
                        _.get(
                          e,
                          'item_name'
                        )
                      )}
                  {' : '}
                </span>
                <div className="flex items-center ml-2 gap-1">
                  <span className="text-sm text-primary font-semibold">
                    {Number(
                      _.get(
                        e,
                        'total_donation_qtty'
                      )
                    ).toLocaleString()}
                  </span>
                  <span className="text-sm text-primary font-semibold flex-1">
                    {_.get(
                      e,
                      'unit_name'
                    )}
                  </span>
                  {_.get(
                    e,
                    'item_name'
                  ) !== 'blood' &&
                    _.get(
                      e,
                      'item_name'
                    ) !==
                      _.get(
                        e,
                        'target_unit_name'
                      ) && (
                      <span className="text-2xs italic font-light text-color-400">
                        {t(
                          'equivalent to'
                        )}
                      </span>
                    )}
                  {_.get(
                    e,
                    'item_name'
                  ) !== 'blood' &&
                    _.get(
                      e,
                      'item_name'
                    ) !==
                      _.get(
                        e,
                        'target_unit_name'
                      ) && (
                      <span className="text-sm text-primary font-semibold">
                        {Number(
                          _.get(
                            e,
                            'total_donation_value'
                          )
                        ).toLocaleString()}{' '}
                        {_.get(
                          e,
                          'target_unit_name'
                        )}
                      </span>
                    )}
                </div>
              </div>
            ))
          ) : (
            <span className="text-color-300 text-sm italic">
              {t('not added yet')}
            </span>
          )}
        </div>
      </HeaderItemInfo>
      <DonorMyDonations
        sample={2}
        donor={donor}
        Footer={withProps({
          pathname: [
            mapPathParam(
              wisharePaths.donor,
              { id }
            ),
            donorRouteEntities[1].path,
          ].join('/'),
        })(DonorPagerFooter)}
        Wrapper={withProps({
          title: 'my donations',
        })(HeaderItemInfo)}
      />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({ donor })
  )
)(DonorOverview)
