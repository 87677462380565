import { Image } from 'antd'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import DatePreview from 'views/Wishare/custom/DatePreview'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { DonationPagerFooter } from 'views/Wishare/factory/createPagerFooter'
import { DonationVideo } from 'views/Wishare/factory/createVideoPlayer'
import HostList from 'views/Wishare/Host/components/HostList'
import TagList from 'views/Wishare/Tags/TagList'
import DonationBenificiary from '../components/DonationBenificiary'
import DonationItemList from '../components/DonationItemList'
import DonationContext from '../DonationContext'
import DonationNews from './DonationNews'

const DonationOverview = ({
  Wrapper = 'div',
}) => {
  const { donation } = useContext(
    DonationContext
  )

  const {
    tags,
    about,
    hosts,
    photo,
    time_open,
    time_close,
    donation_guide,
  } = donation || {}

  const about_us = useMemo(
    () =>
      isRawContentEmpty(about)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(about)
            )
          ),
    [about]
  )

  const guide_state = useMemo(
    () =>
      isRawContentEmpty(donation_guide)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(donation_guide)
            )
          ),
    [donation_guide]
  )

  if (_.isEmpty(donation)) return null

  return (
    <Wrapper className="space-y-6">
      <div className="w-ful custom-image-overview space-y-3">
        <DonationVideo url_field="clip_url" />
        {photo && (
          <Image
            style={{ width: '100%' }}
            preview={false}
            src={photo}
            className="rounded-lg"
          />
        )}
      </div>

      <HostList
        items={hosts}
        Wrapper={withProps({
          title: 'co-hosts',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo
        title="introduce"
        hidden={!about_us}>
        <div className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={about_us}
          />
        </div>
      </HeaderItemInfo>

      <TagList
        items={tags}
        Wrapper={withProps({
          title: 'keywords',
          className:
            'flex flex-wrap gap-3',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo title="open/close">
        <DatePreview
          values={[
            {
              label: 'start date',
              value: time_open,
            },
            {
              label: 'end date',
              value: time_close,
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
      </HeaderItemInfo>

      <HeaderItemInfo
        title="donation guide"
        hidden={!guide_state}>
        <div className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={guide_state}
          />
        </div>
      </HeaderItemInfo>

      <DonationBenificiary
        Wrapper={withProps({
          title:
            'beneficiary information',
        })(HeaderItemInfo)}
      />

      <DonationItemList
        sample={2}
        filtered_by={'public'}
        Footer={withProps({
          pathname:
            getLinkToDetail(donation) +
            '/donations',
        })(DonationPagerFooter)}
        Wrapper={withProps({
          title: 'latest contributions',
        })(HeaderItemInfo)}
      />

      <DonationNews
        sample={2}
        Footer={withProps({
          pathname:
            getLinkToDetail(donation) +
            '/news',
        })(DonationPagerFooter)}
        Wrapper={withProps({
          title: 'news update',
        })(HeaderItemInfo)}
      />

      <div className="py-16" />
    </Wrapper>
  )
}

export default DonationOverview
