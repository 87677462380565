import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import DonorContext from './DonorContext'

const DonorLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose(
  notFoundOnError(DonorContext.Consumer)
)(DonorLayout)
