import { Avatar, Divider } from 'antd'
import { getId } from 'apis/model/base'
import classNames from 'classnames'
import { showConfirm } from 'components/Feed/TimeLineFeed'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from 'numeral'
import React, {
  useContext,
  useMemo,
  useState,
} from 'react'
import { IoMedalOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import {
  Null,
  renderSelf,
  Visible,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { donationApi } from 'views/Wishare/apis'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import getAvatar from '../../../../helpers/getAvatar'
import getTitle from '../../../../helpers/getTitle'
import { ItemContextMenu } from '../../factory/createContextMenu'
import DonationManageEditForm from '../ActionForms/DonationManageEditForm'
import { getDonationDetail } from '../functions/getDonationDetail'
import { getDonorEntity } from '../functions/getDonorEntity'
import { getDonorName } from '../functions/getDonorName'
import DonationThanksLetterWrapper from '../views/DonationThanksLetter/DonationThanksLetter'

export const DonationItemContextActions =
  Object.freeze({
    EDIT: 'edit',
    APPROVE: 'approve',
    REMOVE: 'remove',
    THANKS_LETTER: 'thanks-letter',
    SEND_THANKS_LETTER_EMAIL:
      'send-thanks-letter-email',
    SEND_DONATION_REGISTER_LETTER_EMAIL:
      'send-donation-register-letter-email',
  })

export const DonationRecordDetail = ({
  item,
  donation,
  donation_event,
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )

  if (_.isEmpty(item)) return null

  const {
    type_cd,
    item_name,
    unit_name,
    reg_donation_value,
    donation_value,
    reg_donation_qtty,
    donation_qtty,
    status,
    convertedCurrency,
    isConvertedCurrency,
    isCompleted,
    isConversionRequired,
    isCurrencyItem,
  } = getDonationDetail(
    item,
    donation,
    donation_event
  )

  const isHiddenValue =
    _.get(
      donation,
      'value_display_off'
    ) === 1

  const isMoney =
    _.get(item, 'type_cd') === 'money'

  return (
    <FlexCol>
      <div className="flex items-center">
        <span className="text-primary font-semibold space-x-1 leading-tight">
          {[
            <span
              className={classNames(
                Boolean(
                  isHiddenValue &&
                    isMoney
                ) && 'blur-sm'
              )}>
              {Number(
                isCompleted
                  ? donation_qtty
                  : reg_donation_qtty
              ).toLocaleString()}
            </span>,

            <span>{unit_name}</span>,

            !isCurrencyItem && (
              <span className="font-light">
                ({t(item_name)})
              </span>
            ),
          ].map((string, index) => (
            <React.Fragment key={index}>
              {string}
            </React.Fragment>
          ))}
        </span>
        <span className="flex-1" />
        {!!isConversionRequired &&
          !isConvertedCurrency &&
          isCompleted && (
            <React.Fragment>
              <span className="text-xs italic text-color-400 font-light">
                {t('equivalent to')}
              </span>
              <span className="text-primary font-semibold ml-2 space-x-1 leading-tight">
                <span
                  className={classNames(
                    'uppercase',
                    isHiddenValue
                      ? 'blur-sm'
                      : ''
                  )}>
                  {isSm
                    ? numeral(
                        Number(
                          isCompleted
                            ? donation_value
                            : reg_donation_value
                        )
                      ).format('0a')
                    : Number(
                        isCompleted
                          ? donation_value
                          : reg_donation_value
                      ).toLocaleString()}
                </span>
                <span>
                  {convertedCurrency}
                </span>
              </span>
            </React.Fragment>
          )}
      </div>
    </FlexCol>
  )
}

export const DonationRecordItem = ({
  item,
  withoutContextMenu = false,
  showEvent = false,
  showName = true,
}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)

  const sub_domain = useContext(
    SubDomainContext
  )

  const {
    idcode,
    transfer_dt,
    donation_items = [],
    donation_event = {},
    medal_type,
  } = item || {}

  const id = getId(donation_event)

  const donation_id = getId(item)

  const edit = Boolean(
    _.get(item, 'donation_event.edit')
  )

  const isUser =
    _.get(login, 'id') ===
    _.get(item, 'donor.owner_id')

  const isPending =
    Number(_.get(item, 'status')) === -1

  const email = _.get(item, 'email')

  const [
    contextAction,
    setContextAction,
  ] = useState({})

  const [
    isToggleThanksForm,
    toggleThanksForm,
  ] = useToggle()

  const {
    removePost = Null,
    updatePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: removeItem,
  } = useAsyncAction({
    apiInfo:
      donationApi.donation_events_donationManageDelete_api,
    query: bindQueryParams([
      {
        id,
      },
      { donation_id },
    ]),
    onError: notifyOnError(t),
    onSuccess: (result, __) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      if (result) {
        removePost(result)
      }
    },
  })

  const {
    handleAsyncAction:
      manageDonationSendThankYouEmail,
  } = useAsyncAction({
    apiInfo:
      donationApi.donation_events_manageDonationSendThankYouEmail_api,
    query: {
      ':id': item.id,
    },
    onSuccess: (__, { response }) => {
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const {
    handleAsyncAction:
      manageDonationSendRegisterConfirmationEmail,
  } = useAsyncAction({
    apiInfo:
      donationApi.donation_events_manageDonationSendRegisterConfirmationEmail_api,
    query: {
      ':id': item.id,
    },
    onSuccess: (__, { response }) => {
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const contextMenu = useMemo(
    () =>
      !!withoutContextMenu
        ? []
        : [
            {
              key: DonationItemContextActions.THANKS_LETTER,
              label: 'thank you card',
              invisible:
                !edit || isPending,
            },
            {
              key: DonationItemContextActions.SEND_THANKS_LETTER_EMAIL,
              label:
                'send a thank you email',
              invisible: Boolean(
                !edit || isPending
              ),
              disabled: !email,
            },
            {
              key: DonationItemContextActions.SEND_DONATION_REGISTER_LETTER_EMAIL,
              label:
                'send a confirmation letter for the donation',
              invisible: Boolean(
                !edit || !isPending
              ),
              disabled: !email,
            },
            {
              key: DonationItemContextActions.EDIT,
              label: 'edit',
              invisible:
                !edit || isPending,
            },
            {
              key: DonationItemContextActions.APPROVE,
              label: 'approve',
              invisible:
                !edit || !isPending,
            },
            {
              key: DonationItemContextActions.REMOVE,
              label: 'remove',
              invisible: !edit,
            },
          ]
            .filter(Visible)
            .map(
              ({
                invisible,
                ...rest
              }) => ({
                ...rest,
              })
            ),
    [
      withoutContextMenu,
      edit,
      isPending,
    ]
  )

  if (
    Boolean(
      item.isDeleted || item.deleted
    )
  ) {
    return null
  }

  const handleContextAction = (key) => {
    switch (key) {
      case DonationItemContextActions.EDIT:
        setContextAction({
          type: key,
        })
        break
      case DonationItemContextActions.THANKS_LETTER:
        toggleThanksForm()
        break
      case DonationItemContextActions.SEND_THANKS_LETTER_EMAIL:
        manageDonationSendThankYouEmail()
        break
      case DonationItemContextActions.SEND_DONATION_REGISTER_LETTER_EMAIL:
        manageDonationSendRegisterConfirmationEmail()
        break
      case DonationItemContextActions.APPROVE:
        setContextAction({
          type: key,
        })
        break
      case DonationItemContextActions.REMOVE:
        showConfirm({
          title: t(
            'are you sure delete this'
          ),
          okText: t('sure'),
          cancelText: t('no'),
          okButtonProps: {
            type: 'primary',
            danger: true,
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          cancelButtonProps: {
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          onOk: () => {
            removeItem()
          },
        })
        break
      default:
    }
  }

  const anonymous =
    _.get(item, 'anonymous') === 1

  const donorName = getDonorName(item)

  const donorEntity =
    getDonorEntity(item)

  const isEditing =
    contextAction.type ===
    DonationItemContextActions.EDIT

  const isAdmin = Boolean(edit)

  return (
    <FlexCol className="space-y-1 relative">
      <div className="flex flex-col space-y-2 p-2 md:p-3 border rounded-lg relative">
        {!!_.get(item, 'medal') && (
          <div
            className="absolute top-0 p-2 flex items-center gap-2"
            style={{
              right: withoutContextMenu
                ? 0
                : '2rem',
            }}>
            <Avatar
              size={25}
              src={getAvatar(
                _.get(item, 'medal')
              )}
              icon={
                <IoMedalOutline
                  style={{
                    color: '#bdbdbd',
                  }}
                />
              }
              className="flex flex-center background-100"
            />
          </div>
        )}
        <div className="flex items-baseline space-x-2 text-xs text-color-400 italic">
          <span>{t('code')}</span>
          <span className="text-secondary font-bold text-base leading-none">
            {idcode}
          </span>
          <span className="font-bold">
            {'-'}
          </span>
          <span>
            {moment(transfer_dt).format(
              'HH:mm - DD/MM/YYYY'
            )}
          </span>
        </div>
        <div className="flex flex-wrap items-baseline gap-1 my-2">
          {anonymous ? (
            <span className="capitalize italic font-semibold text-color-200 leading-tight">{`${t(
              'anonymous'
            )}`}</span>
          ) : !!donorEntity ? (
            <Link
              to={getLinkToDetail(
                donorEntity,
                sub_domain
              )}
              className="capitalize font-semibold text-primary max-lines-1 leading-tight">
              {donorName}
            </Link>
          ) : (
            <span className="capitalize italic font-bold text-color-000 leading-tight">{`"${donorName}"`}</span>
          )}
          <div className="flex items-center gap-1">
            <span className="text-color-400 text-sm italic lowercase whitespace-no-wrap">
              {t(
                isPending
                  ? 'registered to donate'
                  : 'donated'
              )}
            </span>
            {showEvent && (
              <span className="text-color-400 text-sm italic lowercase whitespace-no-wrap">
                {t('to the campaign')}
              </span>
            )}
          </div>
          {showEvent && (
            <Link
              to={getLinkToDetail(
                _.get(
                  item,
                  'donation_event'
                )
              )}
              className="text-sm font-medium text-color-000 max-lines-1">
              {_.get(
                item,
                'donation_event.title'
              )}
            </Link>
          )}
        </div>
        <div className="text-sm flex flex-col gap-1">
          {donation_items
            .filter(
              (e) =>
                e.donation_qtty +
                  e.donation_value +
                  e.reg_donation_qtty +
                  e.reg_donation_value !==
                0
            )
            .map(
              (donationItem, index) => (
                <DonationRecordDetail
                  key={index}
                  donation_event={
                    donation_event
                  }
                  donation={item}
                  item={donationItem}
                />
              )
            )}
        </div>

        {!!isPending && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
            {[
              {
                name: 'email',
                label: 'email',
                transform: (value) =>
                  value && (
                    <a
                      href={`mailto:${value}`}>
                      {value}
                    </a>
                  ),
              },
              {
                name: 'phone_number',
                label: 'phone',
                transform: (value) =>
                  value && (
                    <a
                      href={`tel:${value}`}>
                      {value}
                    </a>
                  ),
              },
            ].map(
              (
                {
                  name,
                  label,
                  transform = renderSelf,
                },
                index
              ) => {
                const _value =
                  transform(
                    _.get(item, name)
                  )
                return (
                  <React.Fragment
                    key={index}>
                    {!!_value && (
                      <div className="flex items-center">
                        <span className="text-xs text-color-500 italic font-light">
                          {`${t(
                            label
                          )} : `}
                        </span>
                        <span className="text-sm font-medium text-color-000 ml-2">
                          {_value}
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                )
              }
            )}
          </div>
        )}
        {isAdmin || isUser ? (
          <React.Fragment>
            {!!_.get(
              item,
              'donation_note'
            ) ? (
              <div className="flex flex-col md:flex-row items-baseline italic gap-0 md:gap-2 text-xs">
                <span className="font-semibold text-color-100 whitespace-no-wrap">
                  {`${t(
                    'note (administrator)'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'donation_note'
                  )}
                </span>
              </div>
            ) : null}
            {!!_.get(
              item,
              'register_note'
            ) ? (
              <div className="flex flex-col md:flex-row items-baseline italic gap-0 md:gap-2 text-xs">
                <span className="font-semibold text-color-100 whitespace-no-wrap">
                  {`${t(
                    'note (register)'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'register_note'
                  )}
                </span>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
      {!_.isEmpty(contextMenu) && (
        <div className="absolute right-0 top-0 px-2 py-1">
          <ItemContextMenu
            onMenuSelect={
              handleContextAction
            }
            items={contextMenu}
          />
        </div>
      )}
      {!!isToggleThanksForm && (
        <FullSideModalLayout
          right={true}
          onCancel={toggleThanksForm}>
          <DonationThanksLetterWrapper
            item={item}
          />
        </FullSideModalLayout>
      )}
      {contextAction.type && (
        <DonationManageEditForm
          donation={item}
          isEditing={isEditing}
          onCancel={() => {
            setContextAction({
              type: undefined,
            })
          }}
          actionType={
            contextAction.type
          }
          onUpdated={(id, newItem) => {
            updatePost(id, newItem)
          }}
        />
      )}
    </FlexCol>
  )
}

export const MyDonationRecordItem = ({
  item,
  showEvent = false,
  withoutContextMenu = false,
}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)

  const {
    status,
    idcode,
    transfer_dt,
    donation_items = [],
    donation_event = {},
  } = item || {}

  const details = useMemo(
    () =>
      donation_items.map(
        (donationItem, index) => (
          <DonationRecordDetail
            key={index}
            item={donationItem}
            donation={item}
            status={status}
            donation_event={
              donation_event
            }
          />
        )
      ),
    [
      item,
      status,
      donation_event,
      donation_items,
    ]
  )

  const isPending =
    Number(_.get(item, 'status')) === -1
  const edit =
    _.get(login, 'id') ===
    _.get(item, 'donor.owner_id')

  const isAdmin =
    _.get(
      item,
      'donation_event.edit'
    ) === true

  const [
    contextAction,
    setContextAction,
  ] = useState({})

  const {
    handleAsyncAction: unregister,
  } = useAsyncAction({
    apiInfo:
      donationApi.donation_events_unregister_api,
    query: bindQueryParams([
      {
        id: donation_event.id,
      },
      { donation_id: item.id },
    ]),
    onError: notifyOnError(t),
    onSuccess: () =>
      successNotify(
        NotificationActionTypes.DELETE,
        t,
        {
          description:
            'unsubscribed successfully!',
        }
      ),
  })

  const contextMenu = useMemo(
    () =>
      withoutContextMenu
        ? []
        : [
            {
              key: DonationItemContextActions.REMOVE,
              label: 'remove',
              invisible: !(
                edit && isPending
              ),
            },
          ]
            .filter(Visible)
            .map(
              ({
                invisible,
                ...rest
              }) => ({
                ...rest,
              })
            ),
    [
      edit,
      isPending,
      withoutContextMenu,
    ]
  )

  const handleContextAction = (key) => {
    switch (key) {
      case DonationItemContextActions.REMOVE:
        showConfirm({
          title: t(
            'are you sure delete this'
          ),
          okText: t('sure'),
          cancelText: t('no'),
          okButtonProps: {
            type: 'primary',
            danger: true,
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          cancelButtonProps: {
            className:
              'rounded-lg no-shadow no-text-shadow',
          },
          onOk: () => {
            unregister()
          },
        })
        break
      default:
    }
  }

  return (
    <FlexCol
      key={item.id}
      className="space-y-1 relative">
      <div className="flex flex-col space-y-2 p-3 border rounded-lg">
        <div className="flex items-base space-x-2 text-xs text-color-400 italic">
          <span>{t('code')}</span>
          <span className="text-secondary font-bold text-base leading-none">
            {idcode}
          </span>
          <span className="font-bold">
            {'-'}
          </span>
          <span>
            {moment(transfer_dt).format(
              'HH:mm - DD/MM/YYYY'
            )}
          </span>
        </div>
        <div className="flex flex-wrap items-baseline my-2 gap-1">
          <span className="text-sm font-light text-color-400 italic">
            {t(
              isPending
                ? 'you have registered to donate'
                : 'you donated for campaign'
            )}
          </span>
          {showEvent && (
            <Link
              to={getLinkToDetail(
                _.get(
                  item,
                  'donation_event'
                )
              )}
              className="font-semibold text-color-000 max-lines-2">
              {getTitle(
                _.get(
                  item,
                  'donation_event'
                )
              )}
            </Link>
          )}
        </div>
        <FlexCol className="space-y-1">
          {details}
        </FlexCol>

        {!!isPending && (
          <div className="flex flex-col">
            <Divider className="my-2" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              {[
                {
                  name: 'email',
                  label: 'email',
                  transform: (value) =>
                    value && (
                      <a
                        href={`mailto:${value}`}>
                        {value}
                      </a>
                    ),
                },
                {
                  name: 'phone_number',
                  label: 'phone',
                  transform: (value) =>
                    value && (
                      <a
                        href={`tel:${value}`}>
                        {value}
                      </a>
                    ),
                },
              ].map(
                (
                  {
                    name,
                    label,
                    transform = renderSelf,
                  },
                  index
                ) => {
                  const _value =
                    transform(
                      _.get(item, name)
                    )
                  return (
                    <React.Fragment
                      key={index}>
                      {!!_value && (
                        <div className="flex items-center">
                          <span className="text-xs text-color-500 italic font-light">
                            {`${t(
                              label
                            )} : `}
                          </span>
                          <span className="text-sm font-medium text-color-000 ml-2">
                            {_value}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )
                }
              )}
            </div>
          </div>
        )}
        {isAdmin || edit ? (
          <React.Fragment>
            {_.get(
              item,
              'donation_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'admin note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'donation_note'
                  )}
                </span>
              </div>
            ) : null}
            {_.get(
              item,
              'register_note'
            ) ? (
              <div className="flex items-center italic gap-1 text-xs">
                <span className="font-semibold text-color-100">
                  {`${t(
                    'user note'
                  )} : `}
                </span>
                <span className="text-color-400 font-light">
                  {_.get(
                    item,
                    'register_note'
                  )}
                </span>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>

      {!_.isEmpty(contextMenu) && (
        <div className="absolute right-0 top-0 px-2 py-1">
          <ItemContextMenu
            onMenuSelect={
              handleContextAction
            }
            items={contextMenu}
          />
        </div>
      )}
    </FlexCol>
  )
}

export default DonationRecordItem
