import {Avatar} from 'antd'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import {isRawContentEmpty} from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {convertFromRaw, EditorState,} from 'draft-js'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React, {useContext, useMemo,} from 'react'
import {Link} from 'react-router-dom'
import {withProps} from 'recompose'
import renderCategoryLink from 'views/LandingPage/functions/renderCategoryLink'
import TagList from 'views/Wishare/Tags/TagList'
import {FlexCol} from 'views/Wishare/Templates/ItemTemplate'
import createPagerFooter from '../../factory/createPagerFooter'
import ActivityContext from '../ActivityContext'
import ActivityNews from './ActivityNews'
import CollapsedWrapper from "../../Templates/CollapedWrapper";

const renderOwnerInfo = (owner) => {
  if (_.isEmpty(owner)) return null

  const avatar = getAvatar(owner)

  const title = getTitle(owner) || ''

  return (
    <Link
      to={getLinkToDetail(owner)}
      className="flex items-center space-x-2 mb-2 text-color-org">
      <Avatar
        alt={title}
        src={avatar}
        size="large"
        shape="circle"
        className="border border-color-50 background-100">
        <span className="capitalize">
          {_.first(title)}
        </span>
      </Avatar>
      <span className="text-lg  font-semibold">
        {title}
      </span>
    </Link>
  )
}

const ActivityInfo = ({
  Wrapper = FlexCol,
}) => {
  const { activity } = useContext(
    ActivityContext
  )

  const {
    tags,
    owner,
    about,
    mission,
    description,
    categories = [],
    subcategories = [],
    impact_categories = [],
  } = activity || {}

  const about_us = useMemo(
    () =>
      isRawContentEmpty(about)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(about)
            )
          ),
    [about]
  )

  const mission_state = useMemo(
    () =>
      isRawContentEmpty(mission)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(mission)
            )
          ),
    [mission]
  )

  if (_.isEmpty(activity)) return null

  return (
    <Wrapper className="items-start space-y-6">
      <HeaderItemInfo
        title="description"
        hidden={_.isEmpty(description)}>
          <div className="p-2 font-light text-sm text-color-300 border border-color-50 rounded-lg italic">
              {description}
          </div>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="owner"
        hidden={_.isEmpty(owner)}>
        {renderOwnerInfo(owner)}
      </HeaderItemInfo>

        <HeaderItemInfo
            title="impact categories"
            className="flex flex-wrap gap-2 px-2 md:px-3"
            hidden={_.isEmpty(impact_categories)}>
            {impact_categories.map((e,i) => (
                <div key={i} className="flex items-center gap-2 border rounded-lg">
                    <Avatar size={35} src={e.avatar} className="rounded-l-md rounded-r-0"/>
                    <Link
                        to={getLinkToDetail(e)}
                        className="font-bold text-sm text-color-100 no-underline mr-2">
                        {e.name}
                    </Link>
                </div>
            ))}
        </HeaderItemInfo>

      <HeaderItemInfo
        title="categories"
        className="flex flex-wrap gap-2 px-2 md:px-3"
        hidden={_.isEmpty(categories)}>
        {categories.map(
          renderCategoryLink
        )}
      </HeaderItemInfo>

      {/*<HeaderItemInfo*/}
      {/*    title="subcategories"*/}
      {/*    className="flex flex-wrap gap-2 ml-2 md:ml-3"*/}
      {/*    hidden={_.isEmpty(subcategories)}>*/}
      {/*    {subcategories.map((e,i) => (*/}
      {/*        <div*/}
      {/*            key={i}*/}
      {/*            style={{padding: '4px 10px'}}*/}
      {/*            className="flex flex-col text-sm text-secondary border rounded-md">*/}
      {/*            <span className="font-semibold text-xs text-color-000">*/}
      {/*                {getTitle(e)}*/}
      {/*            </span>*/}
      {/*            <span className="font-light text-2xs text-secondary italic leading-tight">*/}
      {/*                {_.get(e, 'owner.title')}*/}
      {/*            </span>*/}
      {/*        </div>*/}
      {/*    ))}*/}
      {/*</HeaderItemInfo>*/}

      <TagList
        items={tags}
        Wrapper={withProps({
          title: 'keywords',
          className:
            'flex flex-wrap gap-3 px-2 md:px-3',
        })(HeaderItemInfo)}
      />

      <HeaderItemInfo
        title="about us"
        hidden={!about_us}>
        <CollapsedWrapper className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={about_us}
          />
        </CollapsedWrapper>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="mission"
        hidden={!mission_state}>
        <CollapsedWrapper className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={mission_state}
          />
        </CollapsedWrapper>
      </HeaderItemInfo>

      <ActivityNews
        sample={2}
        Footer={withProps({
          pathname:
            getLinkToDetail(activity) +
            '/news',
        })(({ pathname }) =>
          createPagerFooter({
            to: pathname,
            description:
              'You can click "See more" to see this full information',
          })
        )}
        Wrapper={withProps({
          title: 'news update',
        })(HeaderItemInfo)}
      />
    </Wrapper>
  )
}

export default ActivityInfo
