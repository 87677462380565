import { Avatar } from 'antd'
import { getId } from 'apis/model/base'
import DefaultMedal from 'components/Svgs/DefaultMedal'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {
  Null,
  renderElse,
} from 'views/Shared'
import {IoMedalOutline} from "react-icons/io5";

const renderMedal =
  (params) => (item) => {
    if (!!_.get(item, 'deleted')) {
      return null
    }

    const { owner, tags } = item || {}

    const { renderContextMenu = Null } =
      params || {}

    return (
      <Translate key={getId(item)}>
        {(t) => (
          <div className="relative w-full border border-color-50 rounded-lg background">
            <div className="flex items-center p-3 gap-3">
              <Avatar
                size={40}
                src={getAvatar(item)}
                icon={<IoMedalOutline style={{color: '#bdbdbd'}}/>}
                className="flex flex-center background-100" />
              <div className="flex flex-col flex-1">
                <span className="font-bold text-sm max-lines-1">
                  {getTitle(item)}
                </span>
                {renderElse(
                  _.isEmpty(tags),
                  <span className="flex flex-wrap text-xs text-color-400 italic leading-tight">
                    {Array.from(tags || [])
                      .map(({ tags }) => `#${tags}`)
                      .join(',  ')}
                  </span>
                )}
              </div>
              {renderContextMenu(item)}
            </div>
          </div>
        )}
      </Translate>
    )
  }

export default renderMedal
