import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  renderOwnChild,
} from 'views/Shared'
import AdminPanel from 'views/Wishare/AdminPanel'
import WishareAlert from 'views/Wishare/WishareAlert'
import DonationContext from '../DonationContext'
import DonationNav from './DonationNav'

const DonationContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { donation } = useContext(
    DonationContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!donation}>
        <WishareAlert entity={donation}>
          <AdminPanel />
        </WishareAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <DonationNav />
      </div>
    </Wrapper>
  )
}

export default DonationContent
