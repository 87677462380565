export default function getMomentLocale(
  lang
) {
  switch (lang) {
    case 'en':
      return require('moment/locale/en-sg')
    case 'vi':
      return require('moment/locale/vi')
    default:
      return require('moment/locale/en-sg')
  }
}
