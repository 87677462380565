import { Avatar } from 'antd'
import { ImpactCategories } from 'components/Categories/CategoriesXS'
import { useAppConfig } from 'modules/local'
import React from 'react'
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
} from 'react-icons/io5'
import { useToggle } from 'react-use'
import CustomMenuHeader from 'views/Wishare/custom/CustomMenuHeader'

const TopicImpacts = () => {
  const [isToggle, toggle] =
    useToggle(false)

  const { impact_categories = [] } =
    useAppConfig()

  const Icon = isToggle
    ? IoChevronUpOutline
    : IoChevronDownOutline

  return (
    <div className="flex flex-col space-y-2">
      <CustomMenuHeader
        onClick={toggle}
        title="impacts"
        icon={
          <Avatar
            icon={<Icon />}
            size="small"
            className="flex items-center justify-center background-100 text-color-400 hover:text-primary hover:background-200"
          />
        }
      />
      {isToggle && (
        <div className="space-y-2">
          <ImpactCategories
            categories={
              impact_categories
            }
          />
        </div>
      )}
    </div>
  )
}

export default TopicImpacts
