import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import {
  Button,
  Divider,
  Input,
  Switch,
} from 'antd'
import {
  role_create_Api,
  role_delete_Api,
  role_getRoles_Api,
  role_update_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import classnames from 'classnames'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { selectEntities } from 'redux/selectors'
import {
  Null,
  renderFalse,
  renderOwnChild,
} from 'views/Shared'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import * as Yup from 'yup'

const PermissionsContent = ({
  role,
  permissions = [],
  onChange = Null,
  Wrapper = renderOwnChild,
}) => {
  const role_permissions =
    _.get(role, 'permissions') || []
  return (
    <Wrapper>
      {Array.from(role_permissions).map(
        ({
          title,
          disabled,
          description,
          permission_value,
          idname: permission_id,
        }) => (
          <React.Fragment
            key={permission_id}>
            <div className="px-2 py-2 verticalList__small">
              <div className="flex items-center">
                <div className="flex-1 font-bold">
                  {title}
                </div>
                <Switch
                  disabled={disabled}
                  onChange={(
                    checked
                  ) => {
                    onChange(
                      checked
                        ? [
                            ...permissions,
                            permission_id,
                          ]
                        : permissions.filter(
                            (
                              _permission_id
                            ) =>
                              _permission_id !==
                              permission_id
                          )
                    )
                  }}
                  checkedChildren={
                    <CheckOutlined />
                  }
                  unCheckedChildren={
                    <CloseOutlined />
                  }
                  checked={Array.from(
                    permissions
                  ).find(
                    (item) =>
                      item ===
                      permission_id
                  )}
                />
              </div>
              <div className="whitespace-pre-wrap px-2 text-sm font-light text-color-300">
                {description}
              </div>
            </div>
            <div className="px-2">
              <Divider />
            </div>
          </React.Fragment>
        )
      )}
    </Wrapper>
  )
}

const RoleItems = ({
  items = [],
  onSelect = Null,
  isSelected = renderFalse,
}) =>
  Array.from(items).map(
    ({ id: role_id, ...role }) => (
      <div
        key={role_id}
        onClick={() =>
          onSelect(role_id)
        }
        className={classnames(
          'px-2 py-1 rounded-lg font-semibold ',
          !!isSelected(role_id)
            ? 'bg-primary-50 text-primary'
            : 'border border-color-50 hover:background-100 cursor-pointer'
        )}>
        <div
          className="truncate"
          style={{
            maxWidth: 260,
          }}>
          {getTitle(role)}
        </div>
      </div>
    )
  )

const RoleActions = ({
  children,
  container_id,
  container_type,
  selected_role,
  onDeleted = Null,
}) => {
  const t = useTranslate()

  const { id, is_default } =
    selected_role || {}

  return (
    <div className="flex items-center gap-3">
      {children}
      <AsyncAction
        apiInfo={role_delete_Api}
        onSuccess={(result, args) => {
          onDeleted(result, args)
          successNotify(
            NotificationActionTypes.DELETE,
            t
          )
        }}
        onError={notifyOnError(t)}>
        {({
          isLoading,
          handleAsyncAction,
        }) => (
          <Button
            ghost
            danger={true}
            type="primary"
            className="rounded-lg no-shadow no-text-shadow"
            loading={isLoading}
            disabled={!!is_default}
            onClick={() =>
              handleAsyncAction(
                {},
                {
                  ':containerType':
                    container_type,
                  ':containerId':
                    container_id,
                  ':id': id,
                }
              )
            }>
            {`${t('delete')} ${getTitle(
              selected_role
            )}`}
          </Button>
        )}
      </AsyncAction>
    </div>
  )
}

const OrganizationSettingRole = ({
  item,
}) => {
  const t = useTranslate()

  const [select, setSelect] = useState()

  const container_id = _.get(item, 'id')

  const container_type = getType(item)

  const fetchApi = role_getRoles_Api

  const { result, success } = useAsync({
    apiInfo: fetchApi,
    query: bindQueryParams([
      { id: container_id },
      { prop: container_type },
    ]),
  })

  const roleByItem = useSelector(
    (state) =>
      selectEntities(
        state,
        result ||
          `${container_type}_${container_id}`,
        fetchApi.schema
      )
  )

  const roles = _.get(
    roleByItem,
    'roles',
    {}
  )

  const selectRole = roles[select]

  const selected_id = _.get(
    selectRole,
    'id'
  )

  const is_default_role = _.get(
    selectRole,
    'is_default'
  )

  const roleArray = Object.values(
    roles
  ).filter(
    ({ isDeleted }) => !isDeleted
  )

  useEffect(() => {
    if (success) {
      setSelect('@everyone')
    }
  }, [success])

  const initialValues = selectRole
    ? {
        title: selectRole.title,
        permissions:
          selectRole.permissions
            .filter(
              ({ permission_value }) =>
                permission_value
            )
            .map(getId),
      }
    : {}

  const validationSchema =
    Yup.object().shape({
      title: Yup.string().required(
        t('Required')
      ),
    })

  return (
    <div className="flex flex-col gap-2">
      <div
        style={{ minWidth: '300px' }}
        className="pb-10">
        <div className="text-sm uppercase font-bold text-color-300 mb-2">
          {t('roles')}
        </div>
        <div
          className="overflow-y-auto space-y-3"
          style={{ maxHeight: 400 }}>
          <AsyncAction
            apiInfo={role_create_Api}
            query={{
              ':id': container_id,
              ':prop': container_type,
            }}
            onSuccess={(result) => {
              setSelect(result)
              successNotify(
                NotificationActionTypes.CREATE,
                t
              )
            }}
            onError={notifyOnError(t)}
            values={{
              title: 'new role',
              description: 'new role',
            }}>
            {({
              isLoading,
              handleAsyncAction,
            }) => (
              <Button
                block
                type="primary"
                className="no-text-shadow no-shadow rounded-lg"
                loading={isLoading}
                onClick={() =>
                  handleAsyncAction()
                }>
                {t('create role')}
              </Button>
            )}
          </AsyncAction>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <RoleItems
              items={roleArray}
              onSelect={setSelect}
              isSelected={(role_id) =>
                select === role_id
              }
            />
          </div>
        </div>
      </div>
      <div className="flex-1">
        {selectRole && (
          <ApiInfoForm
            initialValues={
              initialValues
            }
            validationSchema={
              validationSchema
            }
            query={bindQueryParam({
              id: selected_id,
            })}
            onPreSubmit={({
              permissions,
              ...values
            }) =>
              _.omitBy(
                {
                  ...values,
                  permissions:
                    _.isEmpty(
                      permissions
                    )
                      ? null
                      : permissions,
                },
                _.isUndefined
              )
            }
            apiInfo={role_update_Api}>
            {({
              values: {
                title,
                permissions = [],
              },
              errors,
              handleChange,
            }) => (
              <div>
                <div className="text-sm uppercase font-bold text-color-300 mb-2">
                  {t('role name')}
                </div>
                <FieldDecorator
                  errors={errors.title}>
                  <Input
                    name="title"
                    value={title}
                    disabled={
                      is_default_role
                    }
                    onChange={
                      handleChange
                    }
                  />
                </FieldDecorator>
                <Divider />
                <div className="verticalList">
                  <div className="text-sm uppercase font-bold text-color-300 mb-2">
                    {t(
                      'general permission'
                    )}
                  </div>
                  <PermissionsContent
                    role={selectRole}
                    onChange={(
                      permissions
                    ) => {
                      handleChange(
                        createValue(
                          'permissions',
                          permissions
                        )
                      )
                    }}
                    permissions={
                      permissions
                    }
                  />
                  <RoleActions
                    container_id={
                      container_id
                    }
                    container_type={
                      container_type
                    }
                    selected_role={
                      selectRole
                    }
                    onDeleted={() => {
                      setSelect(
                        '@everyone'
                      )
                    }}>
                    <Button
                      disabled={
                        is_default_role
                      }
                      onClick={() =>
                        handleChange(
                          createValue(
                            'permissions',
                            null
                          )
                        )
                      }
                      className="rounded-lg no-border no-shadow no-text-shadow">
                      {t(
                        'clear role permissions'
                      )}
                    </Button>
                  </RoleActions>
                  <FormActionBar submitText="save" />
                </div>
              </div>
            )}
          </ApiInfoForm>
        )}
      </div>
    </div>
  )
}

export default OrganizationSettingRole
