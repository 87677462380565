import { getLinkToDetail } from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { FeedContext } from '../../components/Feed/FeedCard'
import {
  allActions,
  createTimeLineFeed,
} from '../../components/Feed/TimeLineFeed'
import { ChannelIcon } from '../../components/icons/ChannelIcon'

export const FeedCard =
  createTimeLineFeed(
    {
      Container: () => {
        const { item } = useContext(
          FeedContext
        )
        return (
          <div className="mb-1 text-color-300 flex items-center justify-start">
            <ChannelIcon
              premium={_.get(
                item,
                'container.premium',
                0
              )}
              className="mr-2 text-color-100 text-3xl w-8 h-8"
            />
            <Link
              to={getLinkToDetail(
                item.container
              )}
              className="cursor-pointer hover:underline">
              {_.get(
                item,
                'container.title'
              ) ||
                _.get(
                  item,
                  'container.name'
                )}
            </Link>
          </div>
        )
      },
      ContentLayout: ({
        style,
        children,
      }) => {
        return (
          <div
            style={style}
            className="background-100  FeedCardContent verticalList__small">
            {children}
          </div>
        )
      },
    },
    {
      ...allActions,
      bookmark: true,
      pin: false,
      unpin: false,
    }
  )
