import { ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { articleSchema } from '../schema'
import base, { get, getLinkToDetailGenerator, getLinkToSettingGenerator, getLinkToShareGenerator, getType } from './base'

const getId = get('idname')

const getContainerId = get('idname')

const replaceEditState = id => {
  window.history.replaceState(
    null,
    'Edit Article',
    `article/${id}/edit`
  )
}

const fromSegments = (segments = [], separator = '') => {
  return _.join(segments, separator)
}

const getDefaultURL = getLinkToDetailGenerator(
  { getId, getType }
)

export const ArticleContainerTypes = Object.freeze({
  DEFAULT: 'default',
  CHANNEL: 'channel'
})

export const getLinkToDetail = item => {
  const URL = getDefaultURL(item)
  return fromSegments([
    '/news',
    URL
  ])
}

export const getLinkToSetting = getLinkToSettingGenerator(
  { getId, getType }
)

export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)

const getLinkToCreate = item => {
  const URL = getDefaultURL(item)
  return fromSegments([
    URL,
    '/new-article'
  ])
}

const getEditingURL = item => {
  return fromSegments([
    // '/news',
    '',
    getType(item),
    getId(item),
    'edit'
  ], '/')
}

export default {
  ...base,
  getLinkToDetail,
  getLinkToCreate,
  getLinkToSetting,
  getLinkToShare,
  schema: articleSchema,
  getId,
  getContainerId,
  replaceEditState,
  getEditingURL,
}
