import { Avatar, Tooltip } from 'antd'
import classNames from 'classnames'
import ImageLoader from 'components/ImageLoader'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import AppPlaceHolderSvg from 'components/Svgs/AppPlaceHolderSvg'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import { OrganizationBannerDefault } from 'conts/imageBase64'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import { loadImages } from 'helpers/loadImage'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationBanner = React.memo(
  ({ Wrapper = 'div' }) => {
    const t = useTranslate()
    const { organization } = useContext(
      OrganizationContainerContext
    )
    const { isSm } = useContext(
      LayoutContext
    )

    const {
      photos,
      org_type,
      certified,
      cover_photo,
    } = organization || {}

    const [imageIndex, setImageIndex] =
      useState(0)

    const { org_types = {} } =
      useAppConfig()

    const [all_photos, set_photos] =
      useState([])

    useEffect(() => {
      loadImages([
        cover_photo,
        ...(photos || []),
      ]).then((value) => {
        const array = Array.from(
          value || []
        )
        if (_.isEmpty(array)) {
          return
        }
        set_photos(
          _.uniq(
            array
              .filter(
                (item) =>
                  !item?.error &&
                  item?.path
              )
              .map((item) => item.path)
          )
        )
      })
    }, [photos, cover_photo])

    const organization_type = _.get(
      org_types,
      org_type || '900'
    )

    useEffect(() => {
      let interval
      if (!_.isEmpty(all_photos)) {
        interval = setInterval(() => {
          const newImageIndex =
            imageIndex ===
            all_photos.length - 1
              ? 0
              : imageIndex + 1
          setImageIndex(newImageIndex)
        }, 8000)
      }
      return () =>
        clearInterval(interval)
    }, [imageIndex, all_photos])

    return _.isEmpty(all_photos) ? (
      <Wrapper className="flex flex-col w-full bg-color-org">
        <div className="container mx-auto p-3 flex justify-start gap-2 md:gap-3">
          <Avatar
            shape="square"
            size={isSm ? 70 : 100}
            icon={<AppPlaceHolderSvg />}
            src={getAvatar(organization)}
            alt={getTitle(organization)}
            className={classNames("rounded-lg background flex flex-center", !getAvatar(organization) && 'p-3')}
          />
          <div className="flex flex-col flex-1 gap-1 py-0 md:py-2">
            <div className="text-normal md:text-3xl font-semibold md:font-bold text-white">
              {getTitle(organization)}
              {certified === 1 && (
                <Tooltip
                  title={t('verified')}
                  placement="right"
                  color="#87d068">
                  <VerifiedSvg
                    width={
                      isSm
                        ? '1rem'
                        : '1.3rem'
                    }
                    height={
                      isSm
                        ? '1rem'
                        : '1.3rem'
                    }
                    className="zoom-animation ml-1"
                  />
                </Tooltip>
              )}
            </div>
            {organization_type && (
              <div className="uppercase text-xs text-white opacity-75 font-medium tracking-wide">
                {t(organization_type)}
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    ) : (
      <Wrapper className="flex flex-col overflow-hidden">
        <div className="w-full bg-color-org">
          <div className="container mx-auto p-3 flex items-start gap-2 md:gap-3">
            <Avatar
              shape="square"
              alt={getTitle(
                organization
              )}
              src={getAvatar(
                organization
              )}
              size={isSm ? 70 : 100}
              icon={
                <AppPlaceHolderSvg />
              }
              style={{
                boxShadow:
                  '0px 6px 10px 2px rgb(0 0 0 / 15%)',
              }}
              className="rounded-lg background-100 flex flex-center z-10"
            />
            <div className="flex flex-col flex-1 gap-1 py-0 md:py-2">
              <div className="text-normal md:text-2xl font-semibold md:font-bold text-white">
                {getTitle(organization)}
                {certified === 1 && (
                  <Tooltip
                    title={t(
                      'verified'
                    )}
                    placement="right"
                    color="#87d068">
                    <VerifiedSvg
                      width={
                        isSm
                          ? '1rem'
                          : '1.3rem'
                      }
                      height={
                        isSm
                          ? '1rem'
                          : '1.3rem'
                      }
                      className="zoom-animation ml-1"
                    />
                  </Tooltip>
                )}
              </div>
              {organization_type && (
                <div className="uppercase text-xs text-white opacity-75 font-medium tracking-wide">
                  {t(organization_type)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="container mx-auto rounded-md shadow-items text-center"
          style={{
            minHeight: isSm
              ? '8rem'
              : '14rem',
            height: 'auto',
            maxHeight: isSm
              ? '12rem'
              : '24rem',
            position: 'relative',
            marginTop: isSm
              ? '-1.6rem'
              : '-2.8rem',
          }}>
          <ImageLoader
            key={imageIndex}
            holder={
              OrganizationBannerDefault
            }
            style={{
              width: isSm
                ? 'calc(100% - 1rem)'
                : '100%',
              minHeight: isSm
                ? '8rem'
                : '14rem',
              height: 'auto',
              maxHeight: isSm
                ? '12rem'
                : '24rem',
              objectFit: 'cover',
              background:
                'var(--background-100)',
            }}
            prefixCls="flex justify-center"
            className="fadeInDown animated object-cover rounded-md"
            src={all_photos[imageIndex]}
          />
          {all_photos.length > 1 && (
            <div
              style={{
                background:
                  'rgba(0,0,0,0.1)',
                borderBottomLeftRadius:
                  '0.375rem',
                borderTopRightRadius:
                  '0.5rem',
              }}
              className="absolute p-1 bottom-0 left-0">
              {all_photos.map(
                (e, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setImageIndex(
                        index
                      )
                    }}
                    className={classNames(
                      'mx-2 font-medium text-sm text-white cursor-pointer',
                      imageIndex ===
                        index
                        ? 'font-bold'
                        : 'opacity-50 hover:opacity-100'
                    )}>
                    {index + 1}
                  </span>
                )
              )}
            </div>
          )}
        </div>
      </Wrapper>
    )
  }
)

export default OrganizationBanner
