import React, {lazy, Suspense} from 'react'

const SlideShow2Lazy = lazy(() =>
  import('./SlideShow2')
)

const SlideShow2 = props => (
  <Suspense fallback={null}>
    <SlideShow2Lazy {...props} />
  </Suspense>
)

export default SlideShow2
