export const roleCodes = {
  admin: 'admin',
  guest: 'guest',
  user: 'user',
}

export const organizationPermissionCodes =
  {
    organizationManageDonor:
      'organization-manage-donor',
    organizationManageDonation:
      'organization-manage-donation',
    organizationManageBeneficiary:
      'organization-manage-beneficiary',
    organizationManageGiving:
      'organization-manage-giving',
    organizationManageStaff:
      'organization-manage-staff',
    organizationManageMedal:
      'organization-manage-medal',
    organizationManageRecruitment:
      'organization-manage-recruitment',
    organizationManageChannel:
      'organization-manage-channel',
    organizationManageEvent:
      'organization-manage-event',
    organizationManageArticle:
      'organization-manage-article',
    organizationManageMember:
      'organization-manage-member',
    organizationManageStructure:
      'organization-manage-structure',
    organizationManagePeerStructure:
      'organization-manage-peer-structure',
    organizationManageInfo:
      'organization-manage-info',
    organizationManageDelete:
      'organization-manage-delete',
    organizationManageRole:
      'organization-manage-role',
    organizationManagePermission:
      'organization-manage-permission',
  }

export const permissionCodes = {
  canInvite: 'canInvite',
  canJoin: 'canJoin',
  canSubscribe: 'canSubscribe',
  canBookmark: 'canBookmark',
  canPostArticle: 'canPostArticle',
  canCanPostArticle:
    'canCanPostArticle',
  canCopy_link: 'canCopy_link',
  canDelete: 'canDelete',
  canEdit: 'canEdit',
  canEessage: 'canEessage',
  canRemove: 'canRemove',
  canShare: 'canShare',
  canTo: 'canTo',
  canView: 'canView',
  canVote: 'canVote',
  canPost: 'can_post',
}
