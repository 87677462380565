import classnames from 'classnames'
import React from 'react'
import './ContentContainer.css'
export default ({
  className,
  ...props
}) => (
  <div
    className={classnames(
      'ContentContainer',
      className
    )}
    {...props}
  />
)
