import {Avatar, Button, Steps, Tooltip,} from 'antd'
import {staff_getStaffRosterById_Api, staff_getStaffRosters_Api, staff_manageStaffRosterDelete_Api,} from 'apis'
import {getId, getType,} from 'apis/model/base'
import {staffSchema} from 'apis/schema'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import {displayMomentValues} from 'helpers/momentDatetime'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useEffect, useMemo, useState,} from 'react'
import {AiOutlineArrowDown} from 'react-icons/ai'
import {
  IoCalendarOutline,
  IoCall,
  IoCreateOutline,
  IoLocationSharp,
  IoMail,
  IoMedalOutline,
  IoPeople,
  IoPerson,
  IoTrashOutline,
} from 'react-icons/io5'
import {Link, useLocation,} from 'react-router-dom'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import LoadingPage from 'views/LoadingPage'
import {
  getFullAddress,
  getResponseItem,
  getResponseItems,
  Null,
  renderElse,
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import {WishareEntities} from 'views/Wishare/enums'
import {showDeleteConfirmDialog} from 'views/Wishare/factory/createConfirmDialog'
import {notifyOnError} from 'views/Wishare/factory/createErrorEvent'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import {StaffModalTypes} from './StaffActionModal'
import StaffRosterActionModal, {StaffRosterModalTypes,} from './StaffRosterActionModal'

const renderDates = ([
                       eff_date,
                       end_date,
                     ]) => {
  const dates = displayMomentValues([
    eff_date,
    end_date,
  ])
  return renderElse(
    _.every(
      [eff_date, end_date],
      _.isEmpty
    ),
    <Translate>
      {(t) => (
        <div className="flex items-center gap-2 items-baseline">
          <span className="text-color-400 font-light italic whitespace-no-wrap">
            {`${t('from date')}:`}
          </span>
          <span className="text-color-200 font-medium flex-1">
            {end_date
              ? dates.join(
                ` ${t('to')} `
              )
              : _.first(dates)}
          </span>
        </div>
      )}
    </Translate>
  )
}

const renderDetailItem = (
  item,
  index,
  collapsed
) => {
  const {
    eff_date,
    end_date,
    workplace,
  } = item || {}

  return (
    <Translate key={index}>
      {(t) => (
        <div className="relative flex flex-col text-xs italic space-y-1">
          {workplace && (
            <div className="flex items-baseline gap-2">
              <span className="text-color-400 font-light">
                {`${t('project')}:`}
              </span>
              <Link
                to={getLinkToDetail(
                  workplace
                )}
                className="text-color-org font-medium flex-1">
                {getTitle(workplace)}
              </Link>
            </div>
          )}
          {renderDates([
            eff_date,
            end_date,
          ])}
          {collapsed && (
            <div className="flex items-center gap-2 items-baseline">
              <span className="text-color-400 font-light">
                {`${t('address')}:`}
              </span>
              <span className="text-color-200 font-medium flex-1">
                {getFullAddress(item)}
              </span>
            </div>
          )}
        </div>
      )}
    </Translate>
  )
}

export const StaffRosterItems = ({
                                   style,
                                   showMore,
                                   staff_id,
                                   className,
                                   owner_id,
                                   owner_type,
                                   onInit = Null,
                                   collapsed = false,
                                   renderContextMenu = Null,
                                   Wrapper = renderOwnChild,
                                 }) => {
  const t = useTranslate()

  const history = useHistory()

  const location = useLocation()

  const staff = useSelectEntities(
    staff_id,
    staffSchema
  )

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const [initialized, setInitialized] =
    useState(false)

  const onCancel = () =>
    setAction({type: undefined})

  const refresh = () => {
    onCancel()
    history.push({
      state: {
        refreshToken: Date.now(),
        expanded_staff_id: staff_id,
      },
      search: location.search,
    })
  }

  const {
    response,
    isLoading = false,
    handleAsyncAction: fetchData,
  } = useAsyncAction({
    query: bindQueryParam({
      staff_id,
    }),
    onError: notifyOnError(t),
    onSuccess: () => {
      setInitialized(true)
    },
    apiInfo: staff_getStaffRosters_Api,
  })

  const {
    handleAsyncAction:
      deleteStaffRoster,
  } = useAsyncAction({
    query: bindQueryParam({
      id: staff_id,
    }),
    onError: notifyOnError(t),
    onSuccess: () => {
      setInitialized(true)
      refresh()
    },
    apiInfo:
    staff_manageStaffRosterDelete_Api,
  })

  useEffect(() => {
    onInit(() => {
      fetchData()
    })
  }, [])

  // useEffect(() => {
  //   if (
  //     _.get(
  //       location.state,
  //       'expanded_staff_id'
  //     ) === staff_id
  //   ) {
  //     fetchData()
  //   }
  // }, [staff_id, location.state])

  const onMenuSelect = (
    key,
    staff_roster
  ) => {
    const staff_roster_id = getId(
      staff_roster
    )
    switch (key) {
      case StaffRosterModalTypes.CREATE:
      case StaffRosterModalTypes.TIMESHEETS:
      case StaffRosterModalTypes.EDIT:
        setAction({
          type: key,
          value: staff_roster_id,
        })
        break
      case StaffRosterModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteStaffRoster(
                {},
                bindQueryParam({
                  id: staff_roster_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const renderActionMenu = useCallback(
    (staff_roster) => {
      const actions = [
        {
          key: StaffRosterModalTypes.TIMESHEETS,
          label: 'timesheets',
          type: 'primary',
          icon: (
            <IoCalendarOutline
              size={12}
              className="text-primary"
            />
          ),
        },
        {
          key: StaffRosterModalTypes.EDIT,
          label: 'edit staff roster',
          type: 'primary',
          icon: (
            <IoCreateOutline
              size={12}
              className="text-color-200"
            />
          ),
        },
        {
          key: StaffRosterModalTypes.DELETE,
          label: 'delete',
          type: 'danger',
          icon: (
            <IoTrashOutline
              size={12}
              className="text-red-500"
            />
          ),
        },
      ]
      return renderIf(
        staff_roster,
        <React.Fragment>
          <div className="flex flex-col gap-1">
            {actions.map(
              ({
                 key,
                 label,
                 icon,
                 type,
               }) => (
                <Tooltip
                  key={key}
                  title={t(label)}
                  placement={'left'}>
                  <Button
                    icon={icon}
                    ghost={true}
                    size={'small'}
                    type={type}
                    style={{
                      borderRadius:
                        '0.375rem',
                    }}
                    className="flex flex-center rounded-lg no-shadow no-text-shadow"
                    onClick={() => {
                      onMenuSelect(
                        key,
                        staff_roster
                      )
                    }}
                  />
                </Tooltip>
              )
            )}
          </div>
        </React.Fragment>
      )
    },
    [t, onMenuSelect]
  )

  const RenderWrapper = useCallback(
    ({children}) => {
      switch (action.type) {
        case StaffRosterModalTypes.EDIT:
        case StaffRosterModalTypes.TIMESHEETS:
          return (
            <Async
              {...{
                apiInfo:
                staff_getStaffRosterById_Api,
                query: bindQueryParam({
                  id: action.value,
                }),
              }}>
              {({
                  response,
                  isLoading,
                }) =>
                _.isFunction(children)
                  ? children({
                    isLoading,
                    initialValues:
                      getResponseItem(
                        response
                      ),
                  })
                  : children
              }
            </Async>
          )
        case StaffRosterModalTypes.CREATE:
        default:
          return children({
            isLoading: false,
          })
      }
    },
    [action]
  )

  const defaultSteps = _.get(
    staff,
    'staff_rosters',
    []
  )

  const steps = useMemo(
    () =>
      Array.from(
        collapsed
          ? getResponseItems(response)
          : defaultSteps
      ).map((item, index) => {
        return (
          <Steps.Step
            key={index}
            status="finish"
            title={
              <div className="flex items-center gap-4 mb-2">
                <div className="text-sm font-medium text-color-000 whitespace-no-wrap ">
                  {_.get(
                    item,
                    'position_title'
                  )}
                </div>
                <div className="border-b border-color-50 w-full mr-5"/>
                {renderContextMenu(
                  item
                )}
              </div>
            }
            description={
              <div className="flex items-center justify-between gap-1 relative">
                {renderDetailItem(
                  item,
                  index,
                  collapsed
                )}
                {collapsed &&
                _.get(
                  item,
                  'medal'
                ) && (
                  <div
                    className="absolute top-0"
                    style={{
                      right: '2.2rem',
                    }}>
                    <Avatar
                      size={25}
                      src={_.get(
                        item,
                        'medal.avatar'
                      )}
                    />
                  </div>
                )}
                {collapsed
                  ? renderActionMenu(
                    item
                  )
                  : null}
              </div>
            }
          />
        )
      }),
    [response, defaultSteps]
  )

  if (!!isLoading) {
    return <LoadingPage/>
  }

  if (
    _.isEmpty(staff_id) ||
    _.isEmpty(steps)
  ) {
    return (
      <Wrapper
        {...{style, className}}>
        <div className="text-sm font-medium text-color-300 uppercase">
          {`${t('task')} :`}
        </div>
        <EmptyHolder/>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...{style, className}}>
      <div className="text-sm font-medium text-color-300 uppercase">
        {`${t('task')} :`}
      </div>
      <React.Fragment>
        <Steps
          progressDot={true}
          direction="vertical">
          {steps}
        </Steps>
        {_.isFunction(showMore) && (
          <div className="flex justify-end">
            <div
              onClick={() => {
                if (!initialized) {
                  fetchData()
                }
                showMore()
              }}
              className="text-xs font-light italic cursor-pointer text-color-400">
              <div className="flex items-center gap-3">
                <span>{t('more')}</span>
                <AiOutlineArrowDown/>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>

      {action.type && (
        <RenderWrapper>
          {({
              initialValues,
              isLoading = false,
            }) => (
            <StaffRosterActionModal
              type={action.type}
              onCancel={onCancel}
              params={{
                staff_id,
                owner_id,
                owner_type,
              }}
              onUpdated={refresh}
              onRefresh={refresh}
              onCreated={refresh}
              isLoading={isLoading}
              initialValues={
                initialValues
              }
            />
          )}
        </RenderWrapper>
      )}
    </Wrapper>
  )
}

export const renderRosterContainer =
  (
    renderContextMenu = Null,
    {
      setAction = Null,
      owner_id,
      owner_type,
    } = {}
  ) =>
    (item) => {
      if (!!_.get(item, 'deleted')) {
        return null
      }

      const {
        owner,
        email,
        location,
        employee_type,
        phone_number,
        staff_rosters = [],
      } = item || {}

      const subTitle = employee_type

      const RowInformation = ({
                                icon,
                                label,
                                value,
                                hiddenIfEmpty = true,
                              }) =>
        !value && hiddenIfEmpty ? null : (
          <div className="flex items-center gap-1 italic text-xs">
            {icon}
            {!icon && (
              <span className="text-color-400 font-light whitespace-no-wrap">
              {_.isString(label)
                ? `${label}: `
                : label}
            </span>
            )}
            <span className="text-color-200">
            {value}
          </span>
          </div>
        )

      const account = renderElse(
        _.isEmpty(owner),
        <div className="flex items-center text-secondary gap-2">
          {' - '}
          <Link
            to={getLinkToDetail(owner)}
            className="flex items-center text-xs gap-1 no-underline">
            {getType(owner) ===
            WishareEntities.USER ? (
              <IoPerson/>
            ) : (
              <IoPeople/>
            )}
            {getTitle(owner)}
          </Link>
        </div>
      )

      return (
        <Translate key={getId(item)}>
          {(t) => (
            <div className="relative  w-full border border-color-50 rounded-lg background">
              <div className="relative flex items-center p-3 gap-3">
                <LayoutContext.Consumer>
                  {({isSm}) =>
                    !!!isSm && (
                      <Avatar
                        size={40}
                        className="flex flex-center background-200"
                        src={getAvatar(
                          item
                        )}>
                      <span className="capitalize text-color-200">
                        {_.first(
                          getTitle(item)
                        )}
                      </span>
                      </Avatar>
                    )
                  }
                </LayoutContext.Consumer>
                <div className="flex flex-col">
                    <Link
                        to={getLinkToDetail(
                            item
                        )}
                        className="font-bold leading-tight max-lines-1">
                        {getTitle(item)}
                    </Link>
                    <span className="flex items-center gap-2 italic text-xs text-color-300">
                        <span>{t(subTitle)}</span>
                        {account}
                    </span>
                  <div className="flex flex-col">
                    <RowInformation
                      icon={
                        <IoLocationSharp className="text-color-500"/>
                      }
                      value={getFullAddress(
                        location
                      )}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      {!!phone_number && (
                        <RowInformation
                          icon={
                            <IoCall className="text-color-500 mr-1"/>
                          }
                          value={[
                            phone_number,
                          ]
                            .filter(
                              (e) => !!e
                            )
                            .join(' - ')}
                        />
                      )}
                      {!!email && (
                        <RowInformation
                          icon={
                            <IoMail className="text-color-500 mr-1"/>
                          }
                          value={[email]
                            .filter(
                              (e) => !!e
                            )
                            .join(' - ')}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute right-0 bottom-0 p-2 flex items-center gap-2">
                  {_.get(
                    item,
                    'medal_awards',
                    []
                  ).map((e, i) => (
                    <Avatar
                      key={i}
                      size={25}
                      src={getAvatar(
                        _.get(e, 'medal')
                      )}
                      icon={
                        <IoMedalOutline
                          style={{
                            color:
                              '#bdbdbd',
                          }}
                        />
                      }
                      className="flex flex-center background-100"
                    />
                  ))}
                </div>
              </div>
              {!_.isEmpty(
                staff_rosters
              ) && (
                <StaffRosterItems
                  Wrapper="div"
                  staff_id={getId(item)}
                  showMore={() => {
                    setAction({
                      type: StaffModalTypes.VIEW,
                      value: getId(item),
                    })
                  }}
                  owner_id={owner_id}
                  owner_type={owner_type}
                  renderContextMenu={Null}
                  className="flex flex-col p-3 gap-2"
                />
              )}
              {renderContextMenu(item)}
            </div>
          )}
        </Translate>
      )
    }
