import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import DatePreview from '../../custom/DatePreview'
import { wishareClassNames } from '../../custom/wishareClassNames'
import { FlexCol } from '../../Templates/ItemTemplate'
import RecruitmentContext from '../RecruitmentContext'

const RecruitmentTimeLocation = () => {
  const t = useTranslate()
  const { recruitment } = useContext(
    RecruitmentContext
  )
  const locations = Array.from(
    _.get(recruitment, 'locations') ||
      []
  )

  return (
    <FlexCol className="flex flex-col space-y-3">
      <FlexCol>
        <span className="text-xs text-color-400 font-light">
          {t('work time')}
        </span>
        <span className="text-color-000 font-semibold">
          {t(
            _.get(
              recruitment,
              'work_time_type'
            )
          )}
        </span>
      </FlexCol>
      {!_.every(
        locations,
        ({ address }) => !address
      ) && (
        <FlexCol>
          <span className="text-xs text-color-400 font-light">
            {t('address')}
          </span>
          <div className="text-color-000 font-semibold">
            {locations.map(
              (location, index) => (
                <div key={index}>
                  {_.get(
                    location,
                    'address'
                  )}
                </div>
              )
            )}
          </div>
        </FlexCol>
      )}
      <FlexCol>
        <span className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
          {t('application deadline')}
        </span>
        <DatePreview
          values={[
            {
              label: 'start date',
              value: _.get(
                recruitment,
                'time_open'
              ),
            },
            {
              label: 'end date',
              value: _.get(
                recruitment,
                'time_close'
              ),
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
      </FlexCol>
      <FlexCol>
        <span className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
          {t('time to start work')}
        </span>
        <DatePreview
          values={[
            {
              label: 'start date',
              value: _.get(
                recruitment,
                'start_date'
              ),
            },
            {
              label: 'end date',
              value: _.get(
                recruitment,
                'end_date'
              ),
            },
          ]}
          className={
            wishareClassNames.inline_2
          }
        />
      </FlexCol>
    </FlexCol>
  )
}

export default RecruitmentTimeLocation
