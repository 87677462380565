import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import React, {
  useCallback,
} from 'react'
import { renderOwnChild } from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import { SettingsMenu } from 'views/Wishare/factory/createSettingLayout'
import { organizationSettings } from 'views/Wishare/Organization/Settings/OrganizationSettings'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import FlyWrapper from 'views/Wishare/Templates/FlyWrapper'
import { useMenuHandler } from '../useMenuHandler'

const WorkspaceSettingContent = () => {
  const { menuItems, routeEntities } =
    organizationSettings

  const {
    activeKey,
    Component,
    onMenuSelect,
  } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <WishareSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <SettingsMenu
          items={menuItems}
          onSelect={onMenuSelect}
          defaultSelectedKeys={[
            activeKey,
          ]}
          MenuWrapper={({
            children,
            ...rest
          }) => (
            <FlyWrapper {...rest}>
              {children}
            </FlyWrapper>
          )}
        />
      </WishareSiderMenu>
    ),
    [menuItems, activeKey]
  )

  return (
    <DefaultTemplate
      renderMenu={renderMenu}>
      <div className="space-y-3 p-2">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceSettingContent
