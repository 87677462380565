import { activity_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import SubCategoriesSelect from 'views/MainPage/WorkSpace/SubCategories/SubCategoriesSelect'
import TagsPicker, {
  MultiCategoryPicker,
} from 'views/Wishare/custom/TagsPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { SubCategoryTypes } from 'views/Wishare/factory/createEntityManager'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { mapEntityOptions } from 'views/Wishare/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import ActivityContext from '../ActivityContext'

const formFields = [
  {
    name: 'tags',
  },
  {
    name: 'categories',
    defaultValue: undefined,
  },
  {
    name: 'subcategories',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    title: 'tags',
    children: [
      {
        name: 'tags',
        component:
          createControlledFormField({
            Component: TagsPicker,
          }),
      },
    ],
  },
  {
    title: 'categories',
    children: [
      {
        name: 'categories',
        component:
          createControlledFormField({
            Component:
              MultiCategoryPicker,
          }),
      },
    ],
  },
  {
    title: 'subcategories',
    children: [
      {
        name: 'subcategories',
        component: ({
          name,
          form,
          value,
          onChange,
        }) => {
          const defaultValue =
            form?.values
              ?.defaultSubCategories
          return (
            <ActivityContext.Consumer>
              {({ activity }) => (
                <SubCategoriesSelect
                  {...{
                    name,
                    value,
                    onChange,
                    defaultValue,
                  }}
                    className="custom-multi-select"
                  type={
                    SubCategoryTypes
                      .ACTIVITY.value
                  }
                  owner_id={getId(
                    activity
                  )}
                  owner_type={getType(
                    activity
                  )}
                />
              )}
            </ActivityContext.Consumer>
          )
        },
      },
    ],
  },
]

const ActivitySettingCategories =
  () => {
    const { activity } = useContext(
      ActivityContext
    )

    const validationSchema = undefined

    const activity_id = getId(activity)

    if (_.isEmpty(activity)) return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: activity,
      validationSchema,
      query: bindQueryParam({
        activity_id,
      }),
      onPreSubmit: ({
        tags,
        categories,
        subcategories,
        defaultSubCategories,
        ...values
      }) =>
        _.omitBy(
          {
            ...values,
            tags: tags
              ? Array.from(
                  tags || []
                ).join(',')
              : undefined,
            categories: categories
              ? Array.from(
                  categories || []
                ).join(',')
              : undefined,
            subcategories: subcategories
              ? Array.from(
                  subcategories || []
                ).join(',')
              : undefined,
          },
          _.isUndefined
        ),
      initialized: ({
        tags,
        categories,
        subcategories,
        ...values
      }) => ({
        ...values,
        tags: Array.from(
          tags || []
        ).map(({ tags }) => tags),
        categories:
          mapEntityOptions(categories),
        subcategories: _.isArray(
          subcategories
        )
          ? Array.from(
              subcategories
            ).map(getId)
          : undefined,
        defaultSubCategories:
          subcategories,
      }),
      apiInfo: activity_update_api,
    })
  }

export default ActivitySettingCategories
