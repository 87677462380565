import { API_ROOT_URL } from 'envs/_current/config'
import { userProfileSchema } from './schema'
import { createTransform } from './utils/createTransform'

export const profile_addSkill_Api = {
  name: 'profile_addSkill_Api',
  backend: `Route::post('auth/profiles/skills/add', 'UserProfileSkillController@addSkill');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/skills/add',
  method: 'POST',
}

export const profile_updateSkill_Api = {
  name: 'profile_updateSkill_Api',
  backend: `Route::post('auth/profiles/skills/{id}/edit', 'UserProfileSkillController@updateSkill');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/skills/:id/edit',
  method: 'POST',
}

export const profile_deleteSkill_Api = {
  name: 'profile_deleteSkill_Api',
  backend: `Route::post('auth/profiles/skills/{id}/delete', 'UserProfileSkillController@deleteSkill');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/skills/:id/delete',
  method: 'POST',
}

export const profile_updateProfileInfo_Api =
  {
    name: 'profile_updateProfileInfo_Api',
    backend: `Route::post('auth/edit-profile', 'UserController@updateProfileInfo');`,
    root: API_ROOT_URL,
    path: '/auth/edit-profile',
    method: 'POST',
    schema: userProfileSchema,
    transform: createTransform(
      userProfileSchema,
      'data'
    ),
  }

export const profile_addLang_Api = {
  name: 'profile_addLang_Api',
  backend: `Route::post('auth/profiles/languages/add', 'UserProfileLanguageController@addLang');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/languages/add',
  method: 'POST',
}

export const profile_updateLang_Api = {
  name: 'profile_updateLang_Api',
  backend: `Route::post('auth/profiles/languages/{id}/edit', 'UserProfileLanguageController@updateLang');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/languages/:id/edit',
  method: 'POST',
}

export const profile_deleteLang_Api = {
  name: 'profile_deleteLang_Api',
  backend: `Route::post('auth/profiles/languages/{id}/delete', 'UserProfileLanguageController@deleteLang');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/languages/:id/delete',
  method: 'POST',
}

export const profile_addEducation_Api =
  {
    name: 'profile_addEducation_Api',
    backend: `Route::post('auth/profiles/educations/add', 'UserProfileEducationController@addEducation');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/educations/add',
    method: 'POST',
  }

export const profile_updateEducation_Api =
  {
    name: 'profile_updateEducation_Api',
    backend: `Route::post('auth/profiles/educations/{id}/edit', 'UserProfileEducationController@updateEducation');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/educations/:id/edit',
    method: 'POST',
  }

export const profile_deleteEducation_Api =
  {
    name: 'profile_deleteEducation_Api',
    backend: `Route::post('auth/profiles/educations/{id}/delete', 'UserProfileEducationController@deleteEducation');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/educations/:id/delete',
    method: 'POST',
  }

export const profile_addWork_Api = {
  name: 'profile_addWork_Api',
  backend: `Route::post('auth/profiles/works/add', 'UserProfileWorkController@addWork');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/works/add',
  method: 'POST',
}

export const profile_updateWork_Api = {
  name: 'profile_updateWork_Api',
  backend: `Route::post('auth/profiles/works/{id}/edit', 'UserProfileWorkController@updateWork');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/works/:id/edit',
  method: 'POST',
}

export const profile_deleteWork_Api = {
  name: 'profile_deleteWork_Api',
  backend: `Route::post('auth/profiles/works/{id}/delete', 'UserProfileWorkController@deleteWork');`,
  root: API_ROOT_URL,
  path: '/auth/profiles/works/:id/delete',
  method: 'POST',
}

export const profile_addTimetable_Api =
  {
    name: 'profile_addTimetable_Api',
    backend: `Route::post('auth/profiles/timetables/add', 'UserProfileTimetableController@addRecord');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/timetables/add',
    method: 'POST',
  }

export const profile_updateTimetable_Api =
  {
    name: 'profile_updateTimetable_Api',
    backend: `Route::post('auth/profiles/timetables/{id}/edit', 'UserProfileTimetableController@updateRecord');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/timetables/:id/edit',
    method: 'POST',
  }

export const profile_deleteTimetable_Api =
  {
    name: 'profile_deleteTimetable_Api',
    backend: `Route::post('auth/profiles/timetables/{id}/delete', 'UserProfileTimetableController@deleteRecord');`,
    root: API_ROOT_URL,
    path: '/auth/profiles/timetables/:id/delete',
    method: 'POST',
  }
