import {PlusOutlined, SafetyOutlined, SettingOutlined,} from '@ant-design/icons'
import {Menu} from 'antd'
import {user_getById_Api, user_getPropsByHost_Api,} from 'apis'
import {organizationSchema, userSchema,} from 'apis/schema'
import {createListTransform} from 'apis/utils/createListTransform'
import {BaseItem} from 'components/Item/BaseItem'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import Null from 'components/NullComponent'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import useRouter from 'modules/navigation/useRouter'
import React, {useContext,} from 'react'
import {connect, useSelector,} from 'react-redux'
import {Route, Switch,} from 'react-router-dom'
import {compose, nest, pure, withProps,} from 'recompose'
import {selectEntities} from 'redux/selectors'
import ServerContext from 'views/Server/ServerContext'
import ServerContainer from '../Server/ServerContainer'
import {routes} from './routes'
import UserFeed from './UserFeed'
import UserMenu from './UserMenu.lazy'

export const UserPrefixTitles =
  Object.freeze({
    NONE: '',
    MR: 'mr',
    MS: 'ms',
    MRS: 'mrs',
    MISS: 'miss',
    None: '...',
  })

export const UserContainer = ({
  id,
  children,
}) => {
  const t = useTranslate()
  const { history } = useRouter()
  const item = useSelector((state) =>
    selectEntities(
      state,
      id,
      userSchema
    )
  )
  // useAsync({
  //   apiInfo: user_getById_Api,
  //   query: {
  //     ':username': id
  //   }
  // })
  // if (!item) return <LoadingPage />
  return (
    <ServerContainer
      key={id}
      id={id}
      type="user"
      item={item}
      routes={routes}
      schema={userSchema}
      createMenuContext={({
        setCurrent,
      }) =>
        !_.get(
          item,
          'edit'
        ) ? undefined : (
          <Menu
            onClick={({ key }) => {
              switch (key) {
                case 'settings':
                  history.push({
                    pathname:
                      '/user/settings',
                    state: {
                      isModal: true,
                    },
                  })
                  break
                default:
                  setCurrent(key)
                  break
              }
            }}>
            <Menu.Item key="createChannel">
              <PlusOutlined />
              <span className="font-semibold">
                {t('Create channel')}
              </span>
            </Menu.Item>
            <Menu.Item key="settings">
              <SettingOutlined />
              <span className="font-semibold">
                {t('general settings')}
              </span>
            </Menu.Item>
            <Menu.Item disabled>
              <SafetyOutlined />
              <span className="font-semibold">
                {t('privacy settings')}
              </span>
            </Menu.Item>
          </Menu>
        )
      }>
      <Async
        key={id}
        apiInfo={user_getById_Api}
        query={{
          ':username': id,
        }}
      />
      {item && children}
    </ServerContainer>
  )
}

const Organization = () => {
  const { item } = useContext(
    ServerContext
  )

  return (
    <div className="px-3  w-full justify-center flex flex-wrap verticalList">
      <LazyPagination
        apiInfo={{
          ...user_getPropsByHost_Api,
          schema: organizationSchema,
          transform:
            createListTransform(
              organizationSchema
            ),
        }}
        query={{
          ':username': item.username,
          ':props': 'organization',
        }}
        renderItem={(item) => (
          <BaseItem
            className="m-3"
            totals={{}}
            {...{
              avatar: item.avatar,
              cover: item.cover_photo,
              title:
                item.title || item.name,
              description: item._type,
              premium: item.premium,
              id: item.id,
              to: getLinkToDetail(item),
            }}
          />
        )}></LazyPagination>
    </div>
  )
}
const Channel = () => {
  const { item } = useContext(
    ServerContext
  )

  return (
    <div className="px-3  w-full justify-center flex flex-wrap verticalList">
      <LazyPagination
        apiInfo={{
          ...user_getPropsByHost_Api,
          schema: organizationSchema,
          transform:
            createListTransform(
              organizationSchema
            ),
        }}
        query={{
          ':username': item.username,
          ':props': 'channel',
        }}
        renderItem={(item) => (
          <BaseItem
            className="m-3"
            totals={{}}
            {...{
              avatar: item.avatar,
              cover: item.cover_photo,
              title:
                item.title || item.name,
              description: item._type,
              premium: item.premium,
              id: item.id,
              to: getLinkToDetail(item),
            }}
          />
        )}></LazyPagination>
    </div>
  )
}
const Article = () => {
  const { item } = useContext(
    ServerContext
  )
  return (
    <div className="px-3  w-full justify-center flex flex-wrap verticalList">
      <LazyPagination
        apiInfo={{
          ...user_getPropsByHost_Api,
          schema: organizationSchema,
          transform:
            createListTransform(
              organizationSchema
            ),
        }}
        query={{
          ':username': item.username,
          ':props': 'article',
        }}
        renderItem={(item) => (
          <BaseItem
            className="m-3"
            totals={{}}
            {...{
              avatar: item.avatar,
              cover: item.cover_photo,
              title:
                item.title || item.name,
              description: item._type,
              premium: item.premium,
              id: item.id,
              to: getLinkToDetail(item),
            }}
          />
        )}></LazyPagination>
    </div>
  )
}
export const UserStateless = nest(
  UserContainer,
  withProps((props) => ({
    menu: <UserMenu />,
    renderHeader: Null,
  }))(ContentLayout),
  withProps((props) => ({
    location: {
      pathname: props.sub || 'activity',
    },
    children: [
      ['activity', UserFeed],
      ['organization', Organization],
      ['channel', Channel],
      ['article', Article],
    ].map(([value, component]) => (
      <Route
        key={value}
        path={value}
        exact
        component={component}
      />
    )),
  }))(Switch)
)
export default compose(
  connect((state, { match }) => {
    const id = _.get(match, 'params.id')
    const sub = _.get(
      match,
      'params.sub',
      'activity'
    )
    return {
      id,
      sub,
    }
  }),

  pure
)(UserStateless)
