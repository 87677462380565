import {host_getActivitySummary_Api, host_getActivitySummaryOnOrg_Api,} from 'apis'
import {getId, getType,} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import React, {useMemo} from 'react'
import {useLocation, useSearchParam,} from 'react-use'
import {branch, compose, fromRenderProps, renderNothing,} from 'recompose'
import {Null} from 'views/Shared'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import WorkspaceInsightsMetaSummary from '../../Insights/WorkspaceInsightsMetaSummary'
import WorkspaceContext from '../../WorkspaceContext'
import {renderReportTable, renderReportTableWrapper,} from './renderReportTable'

const headers = [
  'organization',
  'member',
  'activity',
  'donation',
  'giving',
  'recruitment',
  'event',
]

const schemas = [
  {
    name: 'title',
    value_path: 'title',
    className: '',
  },
  {
    name: 'total.organization_member_count',
    value_path:
      'total.organization_member_count',
    className: 'text-primary',
  },
  {
    name: 'total.activity_count',
    value_path: 'total.activity_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_activity_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${
    //         Number(
    //             _.get(
    //                 total, 'organization_member_activity_count'
    //             )
    //         ).toLocaleString()
    //   }`,
  },
  {
    name: 'total.donation_event_count',
    value_path:
      'total.donation_event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_donation_event_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${
    //         Number(
    //             _.get(
    //                 total, 'organization_member_donation_event_count'
    //             )
    //         ).toLocaleString()
    //   }`,
  },
  {
    name: 'total.giving_event_count',
    value_path:
      'total.giving_event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_giving_event_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${
    //         Number(
    //             _.get(
    //                 total, 'organization_member_giving_event_count'
    //             )
    //         ).toLocaleString()
    //   }`,
  },
  {
    name: 'total.recruitment_event_count',
    value_path:
      'total.recruitment_event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_recruitment_event_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${
    //         Number(
    //             _.get(
    //                 total, 'organization_member_recruitment_event_count'
    //             )
    //         ).toLocaleString()
    //   }`,
  },
  {
    name: 'total.event_count',
    value_path: 'total.event_count',
    className: 'text-primary',
    value2_path:
      'total.organization_member_event_count',
    className2: 'text-sm',
    // render: (value, { total }) =>
    //   `${value.toLocaleString()} / ${
    //         Number(
    //             _.get(
    //                 total, 'organization_member_event_count'
    //             )
    //         ).toLocaleString()
    //   }`,
  },
]

const MetaItem = ({title, value}) => (
  <div
    style={{
      boxShadow:
        '0 0 4px 2px rgb(0 0 0 / 5%)',
    }}
    className="flex flex-col items-center justify-center px-3 py-5 rounded-lg">
    <div className="text-center text-sm text-color-400">
      {title}
    </div>
    <div className="flex flex-col flex-center leading-tight mt-2">
      <span className="text-2xl font-medium text-color-100 uppercase">
        {value}
      </span>
    </div>
  </div>
)

const ActivityHostReport = ({
                              owner_id,
                              owner_type,
                              Wrapper = 'div',
                              filter_actions = {
                                some_param: 'some-param',
                                sortBy: 'sort-by',
                                keyword: 'keyword',
                              },
                            }) => {
  // const history = useHistory()

  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  // const filter_some_param =
  //   useSearchParam(
  //     filter_actions.some_param
  //   )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
                 onFilterChange = Null,
               }) => {
        // onFilterChange({
        //   some_param: filter_some_param,
        // })
        onSortChange(sortBy)
      },
      pagerInfo: {
        query: bindQueryParam({
          organization_id: owner_id,
        }),
        PaginationMeta: Null,
        apiInfo:
        host_getActivitySummary_Api,
        renderWrap:
          renderReportTableWrapper(
            headers
          ),
        renderEmpty: () => (
          <tr>
            <td
              className="border p-2"
              colSpan={headers.length}>
              <EmptyHolder/>
            </td>
          </tr>
        ),
        renderItem: renderReportTable({
          fields: schemas,
        }),
      },
      withKey: ({
                  sortBy,
                  keyword,
                  // filterBy,
                }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          // JSON.stringify(filterBy),
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      sortHeader: null,
      renderFilter: Null,
      AdvancedFilter: Null,
      renderSearchBar: Null,
      SortByWidget: Null,
      isToggleDefault: true,
    })
  return (
    <Wrapper className="space-y-3">
      <Async
        {...{
          apiInfo:
          host_getActivitySummaryOnOrg_Api,
          query: bindQueryParam({
            organization_id: owner_id,
          }),
        }}>
        {({response, isLoading}) => {
          const summary = _.get(
            response,
            'data.data'
          )
          return (
            <WorkspaceInsightsMetaSummary
              title="overview"
              summary={summary}
              isLoading={isLoading}
              className="grid grid-cols-3 md:grid-cols-3 gap-4 mb-8"
              field_params={[
                {
                  name: 'organization_member_count',
                  label:
                    'total members',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'activity_count',
                  label:
                    'total activities',
                  extra_name:
                    'organization_member_activity_count',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'donation_event_count',
                  label:
                    'total donation events',
                  extra_name:
                    'organization_member_donation_event_count',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'event_count',
                  label: 'total events',
                  extra_name:
                    'organization_member_event_count',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'giving_event_count',
                  label:
                    'total giving events',
                  extra_name:
                    'organization_member_giving_event_count',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
                {
                  name: 'recruitment_event_count',
                  label:
                    'total recruitment event',
                  extra_name:
                    'organization_member_recruitment_event_count',
                  render: (
                    __,
                    {value}
                  ) => (
                    <span className="text-color-000 text-2xl font-semibold">
                      {value.toLocaleString()}
                    </span>
                  ),
                },
              ]}
            />
          )
        }}
      </Async>

      <Translate>
        {(t) => (
          <div className="flex flex-col">
            <div className="flex items-center text-lg font-semibold uppercase text-color-200">
              {t('activity summaries')}
            </div>
            <span className="text-xs italic text-color-400 font-light">
              {t(
                '{value1} / {value2}: Number of organizations / total number of member organizations',
                {
                  value1: <span className="text-primary font-bold text-lg">XX</span>,
                  value2: <span className="text-color-200 font-light text-xs">XX</span>
                }
              )}
            </span>
          </div>
        )}
      </Translate>
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({owner_id, owner_type}) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  )
)(ActivityHostReport)
