import { DatePicker, Radio } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
} from 'react'
import { Null } from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import {IoCalendarOutline} from "react-icons/io5";

const MedalRewardsAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    active: undefined,
    visible: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <Radio.Group
        name="visible"
        buttonStyle="solid"
        className="flex flex-1 items-center justify-end w-full lg:w-auto"
        onChange={(event) => {
          const { name, value } =
            event.target
          handleChange(name, value)
        }}
        defaultValue={
          filter.visible || undefined
        }>
        <Radio.Button value={undefined}>
          {t('all')}
        </Radio.Button>
        <Radio.Button value={'1'}>
          {t('visible')}
        </Radio.Button>
        <Radio.Button value={'0'}>
          {t('invisible')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export const MedalRewardsHisotryAdvancedFilter =
  ({
    value: filter = {
      to_date: undefined,
      from_date: undefined,
    },
    onChange: setValues = Null,
  }) => {
    const t = useTranslate()

    const handleChange = useCallback(
      (name, value) => {
        setValues({
          ...filter,
          [name]: value,
        })
      },
      [filter]
    )

    return (
      <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
        <DatePicker
          allowClear={true}
          suffixIcon={
              <IoCalendarOutline />
          }
          onChange={(
            date,
            dateString
          ) =>
            handleChange(
              'from_date',
              dateString
            )
          }
          format={
            wishareConfigs.dateFormat
          }
          defaultValue={
            filter.from_date
              ? moment(
                  new Date(
                    filter.from_date
                  )
                )
              : undefined
          }
          placeholder={t('from date')}
        />
        <DatePicker
          allowClear={true}
          suffixIcon={
              <IoCalendarOutline />
          }
          onChange={(
            date,
            dateString
          ) =>
            handleChange(
              'to_date',
              dateString
            )
          }
          format={
            wishareConfigs.dateFormat
          }
          defaultValue={
            filter.to_date
              ? moment(
                  new Date(
                    filter.to_date
                  )
                )
              : undefined
          }
          placeholder={t('to date')}
        />
      </div>
    )
  }

export default MedalRewardsAdvancedFilter
