import {
  Button,
  Menu,
  Modal,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import DrawMenu from 'components/drawer/DrawMenu'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
} from 'react'
import {
  IoCallSharp,
  IoExitOutline,
  IoPeople,
} from 'react-icons/io5'
import { useJoin } from 'views/Server/ServerContainer'
import {
  renderOwnChild,
  renderPhone,
} from 'views/Shared'
import {
  WishareFollowButton,
  WishareVoteButton,
} from '../custom/ToggleActionButton'
import { fromWishareEntity } from '../enums'
import { ConversationWith } from './createChatButton'

const UseJoinAction = ({
  id,
  type,
  children,
  join_status,
}) => {
  const handleJoin = useJoin({
    id,
    type,
    status: join_status,
  })

  return _.isFunction(children)
    ? children(handleJoin, { id, type })
    : children || null
}

const ActionMemberLeave = (props) => {
  const t = useTranslate()
  const showConfirmDialog = useCallback(
    (onOk) =>
      Modal.confirm({
        title: t(
          'do you want to leave'
        ),
        content: t(
          'you will no longer be a member of this organization or activity'
        ),
        okText: t('sure'),
        okType: 'primary',
        cancelText: t('no'),
        okButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          ghost: 'true',
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk,
      }),
    [t]
  )
  return (
    <UseJoinAction
      {...props}
      join_status={true}>
      {(handleLeave) => (
        <div
          onClick={() => {
            showConfirmDialog(
              handleLeave
            )
          }}
          className="flex items-center px-3 py-1 hover:text-primary">
          <IoExitOutline />
          <span className="text-center ml-2">
            {t('leave')}
          </span>
        </div>
      )}
    </UseJoinAction>
  )
}

const createStatusBar =
  (type, Context) =>
  ({ Wrapper = renderOwnChild }) => {
    const t = useTranslate()

    const context = useContext(Context)

    const { contextName } =
      fromWishareEntity(type)

    const item = _.get(
      context,
      contextName
    )

    const contacts = _.get(
      item,
      'contacts',
      []
    )

    let member = _.get(
      _.first(contacts),
      'contact_member'
    )
    let phone = _.get(
      _.first(contacts),
      'phone'
    )
    const join_status = Boolean(
      _.get(item, 'join_status')
    )

    const action_params = {
      id: getId(item),
      type: getType(item),
    }

    if (_.isEmpty(item)) return null

    return (
      <Wrapper>
        <div className="flex items-center gap-3 py-1">
          <div className="flex gap-3 flex-1">
            <WishareFollowButton
              item={item}
            />
            <WishareVoteButton
              item={item}
            />
          </div>
          {join_status && (
            <DrawMenu
              component={
                <div className="p-2 cursor-pointer rounded-md bg-primary text-white flex item-center my-auto">
                  <IoPeople className="mx-auto" />
                </div>
              }>
              {(__, toggle) => (
                <Menu onClick={toggle}>
                  <Menu.Item>
                    <ActionMemberLeave
                      {...action_params}
                    />
                  </Menu.Item>
                </Menu>
              )}
            </DrawMenu>
          )}
        </div>

        {type === 'organization' && (
          <div className="flex flex-col">
            {(contacts.length > 0 || _.get(member, 'user') || _.get(item,'owner')) && (
              <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                {t('contact provider')}
              </div>
            )}
            {_.get(member, 'user') ? (
              <div className="grid grid-cols-2 gap-3 flex-1">
                {!!_.get(member, 'user') ? (
                  <ConversationWith
                    params={{
                      receiver_id:
                        getId(
                          _.get(
                            member,
                            'user'
                          )
                        ),
                    }}
                  />
                ) : null}
                {!!phone &&
                  renderPhone(
                    phone,
                    (value) => (
                      <Button
                        type="primary"
                        size="default"
                        className="w-full no-shadow no-text-shadow rounded-lg flex items-center justify-center"
                        icon={
                          <IoCallSharp className="mx-2" />
                        }>
                        {value}
                      </Button>
                    ),
                    ({
                      href,
                      children,
                    }) => (
                      <a
                        href={href}
                        className="no-underline">
                        {children}
                      </a>
                    )
                  )}
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-3 flex-1">
                <ConversationWith
                  params={{
                    receiver_id: getId(
                      _.get(
                        item,
                        'owner'
                      )
                    ),
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Wrapper>
    )
  }

export default createStatusBar
