import {
  nest,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import WorkspaceHumanResourcesContent from './WorkspaceHumanResourcesContent'

export default nest(
  withProps({ lazy: true })(
    DelayRender
  ),
  WorkspaceHumanResourcesContent
)
