import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  renderOwnChild,
} from 'views/Shared'
import AdminPanel from 'views/Wishare/AdminPanel'
import WishareAlert from 'views/Wishare/WishareAlert'
import BeneficiaryContext from '../BeneficiaryContext'
import BeneficiaryNav from './BeneficiaryNav'

const BeneficiaryContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { beneficiary } = useContext(
    BeneficiaryContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!beneficiary}>
        <WishareAlert entity={beneficiary}>
          <AdminPanel />
        </WishareAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <BeneficiaryNav />
      </div>
    </Wrapper>
  )
}

export default BeneficiaryContent
