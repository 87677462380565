import { UseHook } from 'components/UseReducer'
import React from 'react'
import { useSearchParam } from 'react-use'
import { withProps } from 'recompose'
import ServerContainer from 'views/Server/ServerContainer'
import EnhancedPost, {
  PostTypes,
} from 'views/Wishare/factory/createPost'

const postRoutes = [
  {
    path: 'createPoll',
    name: 'Create Poll',
    component: EnhancedPost(
      PostTypes.POLL
    ),
    exact: true,
  },
  // {
  //   path: 'createArticle',
  //   name: 'Create Article',
  //   component: createRequiredLogin()(
  //     EnchanedCreateArticle
  //   ),
  //   exact: true,
  // },
  // {
  //   path: 'createImagePost',
  //   name: 'createImagePost',
  //   component: createRequiredLogin()(
  //     nest(
  //       ModalLayout,
  //       EnchanedCreateImagePost
  //     )
  //   ),
  //   exact: true,
  // },
]

const PostContainer = ({
  id,
  type,
  routes,
  children,
}) => (
  <UseHook
    hook={useSearchParam}
    deps={['initialCurrent']}>
    {(initialCurrent) => (
      <ServerContainer
        {...{
          id,
          type,
          routes,
          initialCurrent,
        }}>
        {children}
      </ServerContainer>
    )}
  </UseHook>
)

export default withProps({
  routes: postRoutes,
})(PostContainer)
