import classnames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const renderDecoratedLabel = (
  text,
  params
) => {
  const {
    className,
    isRequired = false,
    withLabel = () => {},
  } = params || {}
  return (
    <Translate>
      {(t) => (
        <label
          className={classnames(
            'flex uppercase text-sm font-semibold text-color-300 tracking-wide mb-1',
            className,
            isRequired &&
              'required-label'
          )}
          {...withLabel({
            className,
            isRequired,
          })}>
          {t(text)}
        </label>
      )}
    </Translate>
  )
}

const FieldDecorator = ({
  className,
  label,
  description,
  errors,
  children,
  isRequired = false,
  hasError = true,
  renderLabel = renderDecoratedLabel,
}) => {
  const t = useTranslate()
  return (
    <div
      className={classnames(
        'ErrorDescription relative',
        isRequired && 'required-field',
        { errors },
        className
      )}>
      {_.isString(label)
        ? renderLabel(label, {
            isRequired,
            className:
              'flex capitalize text-sm font-semibold pb-2 block text-color-100',
          })
        : label}
      {children}
      {_.isString(description) ? (
        <label className="text-xs pb-2 block text-color-300">
          {t(description)}
        </label>
      ) : (
        description
      )}
      {hasError && (
        <div
          style={{
            minHeight: '1em',
            lineHeight: '1em',
          }}
          className="text-xs text-red-500 mt-1 ml-1">
          {errors}
        </div>
      )}
    </div>
  )
}
export default FieldDecorator
