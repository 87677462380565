import {
  Badge,
  Card,
  Checkbox,
  Input,
  Radio,
} from 'antd'
import { getId } from 'apis/model/base'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  branch,
  renderNothing,
} from 'recompose'
import { Null } from 'views/Shared'
import { answer_types } from 'views/Wishare/factory/createCustomQuestions'

const QuestionItemWrapper = ({
  item,
  order,
  children,
}) => {
  const t = useTranslate()

  const {
    title,
    mandatory,
    description,
  } = item || {}

  const [tagLabel, tagColor] = Boolean(
    mandatory
  )
    ? [
        'required',
        'var(--color-secondary)',
      ]
    : [
        'optional',
        'var(--color-primary)',
      ]

  const header = useMemo(
    () => (
      <div className="flex gap-1 text-color-000">
        <span>{t('question')}</span>
        {order && (
          <span>{`${order}:`}</span>
        )}
      </div>
    ),
    [t, order]
  )

  if (_.isEmpty(item)) return null

  return (
    <Badge.Ribbon
      color={tagColor}
      text={
          <span className="font-medium text-xs italic">{t(tagLabel)}</span>
      }>
      <Card
        type="inner"
        size="small"
        title={header}>
        <div className="text-color-000 font-medium flex items-center">
          {`- ${title} `}
        </div>
        {!_.isEmpty(description) && (
          <div className="text-color-400 text-xs font-light italic ml-3">
            {`( ${description} )`}
          </div>
        )}
        <div className="mt-2">
          {children}
        </div>
      </Card>
    </Badge.Ribbon>
  )
}

const renderAnswerItem =
  (question) =>
  ({
    placeholder,
    onChange = Null,
    readOnly = false,
    ...rest
  }) => {
    const {
      answer,
      answer_type: type,
      mandatory: required,
      answer_options: options,
    } = question || {}

    if (readOnly) {
      return (
        <div className="ReadOnlyAnswer">
          {answer}
        </div>
      )
    }

    const handleChange = (event) => {
      const value = _.get(
        event,
        'target.value'
      )
      onChange(question)(value)
    }
    switch (type) {
      case answer_types.text.value:
        return (
          <Input
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
          />
        )
      case answer_types.textarea.value:
        return (
          <Input.TextArea
            rows={3}
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
          />
        )
      case answer_types.checkbox.value:
        return (
          <Checkbox.Group
            onChange={(value) => {
              onChange(question)(value)
            }}
            options={options}
            {...rest}
          />
        )
      case answer_types.radio.value:
      case answer_types.boolean.value:
        return (
          <Radio.Group
            onChange={handleChange}
            options={options}
            {...rest}
          />
        )
      default:
        return null
    }
  }

const RecruitmentAnswerView = ({
  value,
  Wrapper = 'div',
  onChange = Null,
  readOnly = false,
  name = 'collection_questions',
  targetName = 'collection_answers',
}) => {
  const t = useTranslate()

  const questions = Array.from(
    value || []
  )

  const [answers, setAnswers] =
    useState(questions)

  const handleChange = useCallback(
    (question) => (value) => {
      let _answers = answers
      const updateAnswers = ({
        id,
        value,
      }) => {
        if (_.find(answers, { id })) {
          _answers = _answers.map(
            (item) =>
              _.isEqual(id, getId(item))
                ? {
                    ...item,
                    id,
                    answer: value,
                  }
                : item
          )
        } else {
          _answers.push({
            id,
            answer: value,
          })
        }
        return _answers
      }

      const result = updateAnswers({
        id: getId(question),
        value,
      })

      setAnswers(_answers)

      onChange(
        createValue(name, result)
      )

      onChange(
        createValue(targetName, result)
      )

      const requiredFields =
        result.filter(
          ({ mandatory }) =>
            Number(mandatory) === 1
        )

      onChange(
        createValue(
          'question_cleared',
          _.every(
            requiredFields,
            ({ answer }) =>
              !_.isEmpty(answer)
          )
        )
      )
    },
    [
      name,
      answers,
      onChange,
      targetName,
    ]
  )

  return (
    <Wrapper className="space-y-2">
      {questions.map(
        (question, index) => (
          <QuestionItemWrapper
            key={index}
            order={index + 1}
            item={question}>
            {renderAnswerItem(question)(
              {
                name: 'answer',
                readOnly,
                placeholder:
                  t('answer'),
                value: _.get(
                  question,
                  'answer'
                ),
                onChange: handleChange,
              }
            )}
          </QuestionItemWrapper>
        )
      )}
    </Wrapper>
  )
}

export default branch(
  ({ value }) => _.isEmpty(value),
  renderNothing
)(RecruitmentAnswerView)
