import {Modal} from 'antd'
import ErrorBoundary from 'components/error/ErrorBoundary'
import {IsAppReady} from 'components/IsAppReady'
import ServiceWorker from 'envs/ServiceWorker'
import {detectDevice} from 'helpers/detectDevice'
import FireBase from 'modules/firebase/FireBase'
import LocalProvider from 'modules/local'
import ThemeProvider from 'modules/theme/ThemeProvider'
import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import Routes from 'routes'
import {createRoutes} from 'routes/createRoutes'
import {modalRoutesConfig, routesConfig} from 'routes/defaultStaticRoutes'
import {initStore} from 'store'
import {
  ANDROID_PLAY_LINK,
  API_ROOT_URL,
  APPLE_ITUNES_LINK,
  env,
  FB_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  GG_ID,
  GOOGLE_API_KEY,
  IS_CODOVA,
  ROOT_URL,
  SOCKET_URL,
  STORAGE_URL
} from './config'
import history from './history'
import SubDomainProvider from "../../views/SubDomain/SubDomainProvider";

const routes = createRoutes({
  modalRoutesConfig,
  routesConfig
})

const { store, persistor } = initStore(
  {
    config: {
      env,
      IS_CODOVA,
      ROOT_URL,
      API_ROOT_URL,
      STORAGE_URL,
      SOCKET_URL,
      FB_ID,
      GG_ID,
      ANDROID_PLAY_LINK,
      APPLE_ITUNES_LINK,
      GOOGLE_API_KEY,
      FIREBASE_MESSAGING_SENDER_ID
    }
  },
  history
)
history.listen(() => {
  Modal.destroyAll()
})


function App({
  device = detectDevice()
}) {
  return (
    <Provider store={store}>
      {/*<ErrorBoundary>*/}
      {/*  <FireBase />*/}
      {/*</ErrorBoundary>*/}
      <IsAppReady>
        <LocalProvider>
          <PersistGate
            loading={null}
            persistor={persistor}>
            <ThemeProvider
              device={device}>
              <Router>
                <SubDomainProvider>
                  <Routes Routes={routes}/>
                </SubDomainProvider>
              </Router>
            </ThemeProvider>
          </PersistGate>
        </LocalProvider>
      </IsAppReady>
      <ErrorBoundary>
        <ServiceWorker />
      </ErrorBoundary>
    </Provider>
  )
}

export default App
