import { Menu } from 'antd'
import React from 'react'
import '../custom/InlineMenu.css'

const InlineMenu = ({ ...props }) => {
  return (
    <Menu
      mode="inline"
      {...props}
    />
  )
}

export default InlineMenu
