import {donor_getDonationStats_api} from 'apis'
import {getId} from 'apis/model/base'
import Pure from 'components/Pure'
import useAsync from 'modules/asyncCache/useAsync'
import React, {useContext,} from 'react'
import {getResponseItem, Null,} from 'views/Shared'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import WorkspaceInsightsMetaSummary from '../Insights/WorkspaceInsightsMetaSummary'
import WorkspaceInsightsSummary, {InsightsSummaryTypes, PeriodFilterHeader,} from '../Insights/WorkspaceInsightsSummary'
import WorkspaceContext, {WorkspaceFilterParams,} from '../WorkspaceContext'

const WorkspaceDonorStatistic = () => {
  const {
    organization,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  const period = getPeriod(
    WorkspaceFilterParams.OVERVIEW
  )

  const {
    response,
    isLoading = false,
    handleAsyncAction = Null,
  } = useAsync({
    apiInfo: donor_getDonationStats_api,
    query: bindQueryParam({
      organization_id: getId(
        organization
      ),
    }),
  })


  const { summary } =
    getResponseItem(response)


  return (
    <React.Fragment>
      <Pure
        input={[
          period,
          summary,
          isLoading,
          handleAsyncAction,
        ]}>
        <WorkspaceInsightsMetaSummary
          summary={summary}
          isLoading={isLoading}
          className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-8"
          Header={({ title }) => (
            <PeriodFilterHeader
              title={title}
              value={period}
              onSelect={(period) => {
                setPeriod(
                  WorkspaceFilterParams.OVERVIEW,
                  period
                )
                handleAsyncAction({
                  values: {
                    period,
                  },
                })
              }}
            />
          )}
          field_params={[
            {
              name: 'total_donation_count',
              label: 'total donation',
              extra_name:
                'total_donation_count_prev',
            },
            {
              name: 'total_donation_event_count',
              label:
                'total donation events',
              extra_name:
                'total_donation_event_count_prev',
            },
            {
              name: 'total_donation_value',
              label:
                'total donation value',
              extra_name:
                'total_donation_value_prev',
            },
            {
              name: 'total_donor_count',
              label: 'total donors',
              extra_name:
                'total_donor_count_prev',
            },
          ]}
        />
      </Pure>
      <Pure>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-10">
          <WorkspaceInsightsSummary
            type={
              InsightsSummaryTypes.DONATION_COUNT
            }
          />
          <WorkspaceInsightsSummary
            type={
              InsightsSummaryTypes.DONATION_VALUE
            }
          />
        </div>
      </Pure>
    </React.Fragment>
  )
}

export default WorkspaceDonorStatistic
