import {Button, Input} from 'antd'
import {auth_register_Api} from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import React from 'react'
import * as Yup from 'yup'
import './RegisterForm.css'
import EyeTwoTone from "@ant-design/icons/lib/icons/EyeTwoTone";
import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import useTranslate from "../../modules/local/useTranslate";

export const Register = ({org_type = undefined, setBack = () => {}}) => {
  const t = useTranslate();

  return (
    <ApiInfoForm
      apiInfo={auth_register_Api}
      initialValues={{
        email: '',
        name: '',
        password: '',
        confirm_password: '',
        org_type,
        organization_name: null
      }}
      validationSchema={Yup.object().shape(
        {
          email: Yup.string()
            .email(
              t(
                'invalid email address'
              )
            )
            .required(
              t('required field')
            ),
          password: Yup.string()
            .min(
              8,
              t(
                'Password must have min 8 characters'
              )
            )
            .required(
              t(
                'password required'
              )
            ),
          name: Yup.string()
            .required(
              t(
                'required field'
              )
            ),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            t(
              'Password and confirm password does not match'
            )
          )
        }
      )}>
      {props => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          success
        } = props
        return (
          <div>
            {success ? (
              <div
                className="flex flex-col"
                style={{
                  display:
                    'inline-block',
                  padding: '16px 0',
                  color: '#161616'
                }}>
                <div className="text-center">
                  <span>
                    {t(
                      'the activation email has been sent to'
                    )}
                  </span>
                  <span className="px-1 color-primary font-bold">
                    {values.email}.
                  </span>
                </div>
                <div className="text-center">
                  {t(
                    'check your email and click on the activation link to complete the registration'
                  )}
                </div>
                <div className="py-3 text-sm italic text-center">
                  {t(
                    'attention: please check your activation email in the spam area'
                  )}
                </div>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="space-y-1">
                <FieldDecorator
                  isRequired={true}
                  errors={errors.email}>
                  <Input
                    size="large"
                    inputMode="email"
                    name="email"
                    value={values.email}
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    className={`register-control ${
                      errors.email
                        ? 'register-control'
                        : ''
                    }`}
                    type="text"
                    placeholder={t(
                      'email account'
                    )}
                  />
                </FieldDecorator>
                <FieldDecorator
                  isRequired={true}
                  errors={
                    errors.password
                  }>
                  <Input.Password
                    size="large"
                    type="password"
                    name="password"
                    value={
                      values.password
                    }
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    placeholder={t(
                      'password'
                    )}
                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                  />
                </FieldDecorator>
                <FieldDecorator
                  className="wishare-field errors"
                  errors={
                    errors.confirm_password
                  }>
                  <Input.Password
                    size="large"
                    type="password"
                    name="confirm_password"
                    value={
                      values.confirm_password
                    }
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    placeholder={t(
                      'Retype password'
                    )}
                    iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                  />
                </FieldDecorator>
                <FieldDecorator
                  className="wishare-field errors"
                  errors={errors.name}>
                  <Input
                    size="large"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    className="register-control"
                    placeholder={t(
                      !!org_type ? 'provider name' : 'fullname'
                    )}
                  />
                </FieldDecorator>
                <div className="flex flex-row space-x-3">
                  <Button
                    // disabled={!isValid}
                    onClick={
                      setBack
                    }
                    type="ghost"
                    className="rounded-lg no-shadow no-text-shadow border-none"
                    size="large">
                    {t('back')}
                  </Button>
                  <Button
                    // disabled={!isValid}
                    onClick={
                      handleSubmit
                    }
                    type="primary"
                    className="flex-1 rounded-lg no-shadow no-text-shadow border-none"
                    size="large">
                    {isSubmitting
                      ? t(
                        'waiting'
                      )
                      : t(
                        'register'
                      )}
                  </Button>
                </div>
              </form>
            )}
          </div>
        )
      }}
    </ApiInfoForm>
  )
}

export default Register
