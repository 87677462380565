import { useAppConfig } from 'modules/local'

export const ResourceTypes = Object.freeze({
  DOCUMENT_FILTER: 'document-filter',
})

const useResource = (type) => {
  const { document_types = [] } = useAppConfig()
  switch (type) {
    case ResourceTypes.DOCUMENT_FILTER:
      return [
        {
          title: 'all',
        },
        ...document_types,
      ]
    default:
      return null
  }
}

export { useResource }
