import { Button } from 'antd'
import classNames from 'classnames'
import { isDevelop } from 'envs/ForDevelop'
import { useFormikContext } from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  Null,
  renderCallback,
} from 'views/Shared'

function FormActionBar({
  style,
  actions,
  children,
  className,
  submitColor,
  disabled = false,
  ExtraActions = Null,
  submitText = 'save',
  resetText = 'reset',
  alwaysVisible = true,
  hideResetButton = false,
  background = 'background',
  callback = renderCallback,
}) {
  const t = useTranslate()
  const {
    touched,
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    resetForm,
    initialValues,
    setSubmitting,
    errors = {},
    ...formProps
  } = useFormikContext()
  const show = dirty && touched
  const submit = () =>
    callback(() => {
      handleSubmit()
      setSubmitting(false)
    })

  if (children)
    return children(
      <div
        style={{
          borderRadius: '0.3em',
          ...(style ? { style } : {}),
        }}
        className={classNames(
          background,
          'flex items-center pointer-events-auto justify-end p-2',
          !alwaysVisible && {
            'fadeInUp faster opacity-0 pointer-events-auto animated':
              show,
            fadeOutDown: !show,
          },
          className
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <span
              className="mr-3 text-color-200 font-semibold hover:text-primary cursor-pointer"
              onClick={() =>
                resetForm({
                  values: initialValues,
                })
              }>
              {t(resetText)}
            </span>
            <Button
              color={submitColor}
              disabled={
                disabled ||
                !isValid ||
                !dirty
              }
              loading={isSubmitting}
              onClick={submit}
              type="primary"
              className="font-bold rounded-lg no-shadow no-text-shadow">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    )

  return (
    <div
      style={style}
      className={classNames(
        background,
        'z-10 p-1 my-2 sticky bottom-0 pointer-events-none rounded-lg',
        className
      )}>
      <div
        className={classNames(
          background,
          'flex items-center pointer-events-auto justify-end p-2 ',
          !alwaysVisible && {
            'opacity-0 faster fadeInUp animated':
              alwaysVisible || show,
            fadeOutDown: !(
              alwaysVisible || show
            ),
          }
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <ExtraActions />
            {!hideResetButton && (
              <span
                className="mr-3 text-color-200 font-semibold hover:text-primary cursor-pointer"
                onClick={() =>
                  resetForm({
                    values:
                      initialValues,
                  })
                }>
                {t(resetText)}
              </span>
            )}
            <Button
              color={submitColor}
              disabled={
                disabled ||
                !isValid ||
                !dirty
              }
              loading={isSubmitting}
              onClick={submit}
              type="primary"
              className="font-bold rounded-lg no-shadow no-text-shadow">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export const AsyncFormActionBar = ({
  validate = Null,
}) => {
  const handleSubmit = async (
    next = Null
  ) => {
    try {
      const result = await validate()
      if (result) {
        next()
      }
    } catch (errorInfo) {
      if (isDevelop()) {
        console.log(
          'Failed:',
          errorInfo
        )
      }
    }
  }
  return (
    <FormActionBar
      callback={handleSubmit}
    />
  )
}

export default FormActionBar
