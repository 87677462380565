import { beneficiarySchema } from 'apis/schema/beneficiary'
import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import {notEmpty} from 'views/Shared'

const BeneficiaryEntity = ({
                             item,
                             children,
                             defaultItem,
                           }) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={beneficiarySchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const BeneficiaryEntities = ({
                                      items,
                                      children,
                                      defaultItem,
                                    }) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[beneficiarySchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export default BeneficiaryEntity
