export const loadImage = (path) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () =>
      resolve({ path, loaded: true })
    img.onerror = () =>
      resolve({ path, error: true })
    img.src = path
  })

export const loadImages = (
  array,
  getPath = (item) => item
) =>
  Promise.all(
    Array.from(array || []).map(
      (item) => loadImage(getPath(item))
    )
  )
