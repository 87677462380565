import { Skeleton } from 'antd'
import React from 'react'

export const renderLoading = () => (
  <div className="w-full flex justify-center">
    <Skeleton
      className="p-3"
      active
    />
  </div>
)

export default renderLoading
