import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  Modal,
  Radio,
  Spin,
} from 'antd'
import {
  activities_getItemsByOwner_gets_api,
  donor_createDonor_api,
  donor_lookupDonors_api,
  donor_mergeDonor_api,
  donor_updateDonor_api,
  search_searchObjects_Api,
} from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import { organizationSchema } from 'apis/schema'
import FieldsFactory, {
  FieldHeader,
} from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, { useMemo } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import {
  compose,
  mapProps,
  pure,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import LoadingPage from 'views/LoadingPage'
import { SelectCountries } from 'views/Organization/Settings/SelectCountries'
import {
  Null,
  renderFalse,
  shallowDiff,
  Visible,
} from 'views/Shared'
import { withAppConfig } from 'views/Wishare/composers'
import {
  modalWidth,
  wishareConfigs,
} from 'views/Wishare/configs'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import ContainerPicker, {
  renderDefaultActions,
  withOwnerResponses,
} from 'views/Wishare/custom/ContainerPicker'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { WishareEntities } from 'views/Wishare/enums'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import createExtraParamsField from 'views/Wishare/factory/createExtraParamsField'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import { renderLocationCascade } from 'views/Wishare/User/functions/renderLocationCascade'
import {
  GenderTypes,
  getDefaultFieldValue,
} from 'views/Wishare/wishareFieldTypes'
import { renderDonor } from '../Donors/renderDonor'
import AvatarUploader from './AvatarUploader'

export const DonorModalTypes =
  Object.freeze({
    EDIT: 'edit',
    CREATE: 'create',
    MERGE: 'merge',
    DELETE: 'delete',
    MEDAL_AWARDS: 'medal-awards',
    DONATION_LIST: 'donation-list',
    DONATION_REG_LIST:
      'donation-reg-list',
  })

const newDonor = {
  title: undefined,
  email: null,
  gender: null,
  owner: null,
  avatar: null,
  activity: null,
  notes: undefined,
  to_donor: undefined,
  phone_number: null,
  date_of_birth: null,
  location: undefined,
  address: undefined,
  ward_id: undefined,
  country_id: undefined,
  province_id: undefined,
  district_id: undefined,
  extra_fields: undefined,
  privacy: getDefaultFieldValue(
    baseFieldTypes.privacy
  ),
}

const withFormSchema = ({
  owner_id,
  owner_type,
  shouldHide = renderFalse,
  render = (name, component) =>
    component,
}) =>
  [
    {
      // title: 'avatar',
      children: [
        {
          name: 'avatar',
          component:
            createControlledFormField({
              withValues: ({
                avatar_preview,
              }) => ({
                value: avatar_preview,
              }),
              Component: AvatarUploader,
            }),
        },
      ],
    },
    {
      title: 'activity',
      invisible: shouldHide('activity'),
      children: [
        {
          name: 'activity',
          component: withProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              name,
              onChange,
              label: 'activity',
              allowClear: true,
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                apiInfo:
                  activities_getItemsByOwner_gets_api,
                query: bindQueryParams([
                  {
                    id: owner_id,
                  },
                  {
                    type: owner_type,
                  },
                ]),
              },
              getOption: (option) =>
                option,
            })
          )(ContainerPicker),
        },
      ],
    },
    {
      children: [
        {
          name: 'information_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('information')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      title: 'name',
      children: [
        {
          name: 'title',
          component:
            createControlledFormField({
              placeholder:
                'alias name placeholder',
              Component: Input,
            }),
        },
      ],
    },
    {
      inline: true,
      className:
        wishareClassNames.inline_2,
      children: [
        {
          name: 'gender',
          label: 'gender',
          hideError: true,
          component: compose(
            withProps({
              buttonStyle: 'solid',
              name: 'gender',
              children: Object.values(
                GenderTypes
              ).map(
                ({
                  name,
                  value,
                  label,
                }) => (
                  <Translate key={name}>
                    {(t) => (
                      <Radio.Button
                        value={value}>
                        {t(label)}
                      </Radio.Button>
                    )}
                  </Translate>
                )
              ),
            })
          )(Radio.Group),
        },
        {
          name: 'date_of_birth',
          label: 'date of birth',
          hideError: true,
          component: compose(
            withTranslate,
            mapProps(
              ({
                value,
                onChange,
                translate,
              }) => ({
                onChange: (
                  date,
                  dateString
                ) => {
                  onChange(
                    createValue(
                      'date_of_birth',
                      date
                        ? moment(
                            new Date(
                              date
                            )
                          )
                        : undefined
                    )
                  )
                },
                defaultValue: value
                  ? moment(
                      new Date(value)
                    )
                  : undefined,
                style: {
                  width: '100%',
                },
                format:
                  wishareConfigs.dateFormat,
                name: 'date_of_birth',
                placeholder: translate(
                  'choose date placeholder'
                ),
                suffixIcon: (
                  <IoCalendarOutline />
                ),
              })
            )
          )(DatePicker),
        },
      ],
    },
    {
      children: [
        {
          name: 'contact_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('contact')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      inline: true,
      className:
        wishareClassNames.inline_2,
      children: [
        {
          name: 'phone_number',
          label: 'phone',
          hideError: true,
          component:
            createControlledFormField({
              type: 'tel',
              placeholder:
                'phone placeholder',
              Component: Input,
            }),
        },
        {
          name: 'email',
          label: 'email',
          hideError: true,
          component:
            createControlledFormField({
              type: 'email',
              placeholder:
                'email placeholder',
              Component: Input,
            }),
        },
      ],
    },
    {
      title: 'address',
      children: [
        {
          name: 'address',
          hideError: true,
          component:
            createControlledFormField({
              placeholder:
                'address placeholder',
              Component: Input,
            }),
        },
      ],
    },
    {
      title: 'country',
      children: [
        {
          hideError: true,
          name: 'country_id',
          component: compose(
            withTranslate,
            withAppConfig,
            mapProps(
              ({
                name,
                form,
                value,
                translate,
                appConfig,
              }) => ({
                name,
                value,
                onChange: (value) => {
                  const {
                    values,
                    setValues = Null,
                  } = form || {}
                  const hasLocationMeta =
                    _.some(
                      _.get(
                        appConfig,
                        'provinces',
                        []
                      ),
                      {
                        country_id:
                          value,
                      }
                    )
                  if (
                    !hasLocationMeta
                  ) {
                    setValues({
                      ...values,
                      [name]: value,
                    })
                  } else {
                    setValues({
                      ...values,
                      [name]: value,
                    })
                  }
                },
                placeholder: translate(
                  'country placeholder'
                ),
                Component: SelectWidget,
              })
            ),
            pure
          )(SelectCountries),
        },
      ],
    },
    {
      children: [
        {
          hideError: true,
          name: 'location',
          component: ({
            name,
            value,
            form,
            onChange,
          }) => (
            <div className="my-2">
              {renderLocationCascade({
                name,
                value,
                onChange,
                country_id: _.get(
                  form,
                  'values.country_id'
                ),
                Header: withProps({
                  title:
                    'Province/district/ward',
                })(FieldHeader),
              })}
            </div>
          ),
        },
      ],
    },
    {
      children: [
        {
          name: 'private_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('private')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      invisible: shouldHide('owner'),
      children: [
        {
          name: 'owner',
          hideError: true,
          component: withProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              name,
              onChange,
              label: 'account link',
              allowClear: true,
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                apiInfo:
                  search_searchObjects_Api,
                values: {
                  type: [
                    WishareEntities.USER,
                    WishareEntities.ORGANIZATION,
                  ].join(','),
                },
              },
              renderActions:
                renderDefaultActions(
                  value
                ),
              getOption: (option) =>
                _.get(option, 'owner'),
              withResponses:
                withOwnerResponses(
                  value
                ),
            })
          )(ContainerPicker),
        },
      ],
    },
    {
      title: 'privacy',
      children: [
        {
          name: 'privacy',
          hideError: true,
          component:
            withAdvancedOptions(
              baseFieldTypes.privacy
            )(RadioOptions),
        },
      ],
    },
    {
      children: [
        {
          name: 'notes_group',
          hideError: true,
          component: () => (
            <Translate>
              {(t) => (
                <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                  {t('notes')}
                </div>
              )}
            </Translate>
          ),
        },
      ],
    },
    {
      children: [
        {
          name: 'notes',
          hideError: true,
          component: compose(
            withTranslate,
            mapProps(
              ({
                name,
                value,
                onChange,
                translate,
              }) => ({
                name,
                value,
                rows: 5,
                onChange,
                placeholder:
                  translate('note'),
              })
            ),
            pure
          )(Input.TextArea),
        },
      ],
    },

    {
      children: [
        {
          name: 'extra_fields',
          component: ({
            name,
            value,
            onChange,
          }) =>
            createExtraParamsField({
              name,
              value,
              onChange,
              params: {
                Header: ({
                  children,
                }) => (
                  <Translate>
                    {(t) => (
                      <div className="flex items-center mb-2 gap-2">
                        <label className="flex-1 uppercase text-sm font-semibold text-color-300 tracking-wide">
                          {t(
                            'extra information'
                          )}
                        </label>
                        {children}
                      </div>
                    )}
                  </Translate>
                ),
              },
            }),
        },
      ],
    },
  ].filter(Visible)

const CreateDonorModal = ({
  onCancel,
  owner_id,
  owner_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'create',
  initialValues = newDonor,
}) => {
  const t = useTranslate()

  const { privacy_settings } =
    useSelectEntities(
      owner_id,
      organizationSchema,
      {}
    )

  const {
    isLoading = false,
    handleAsyncAction: createDonor,
  } = useAsyncAction({
    apiInfo: donor_createDonor_api,
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const { title, privacy, activity } =
      values || {}
    let errors = {}

    if (_.isEmpty(title)) {
      errors.title = t('required field')
    }

    if (
      !_.find(
        baseFieldTypes.privacy,
        ({ value }) => value === privacy
      )
    ) {
      errors.privacy = t(
        'required field'
      )
    }

    if (!!!activity) {
      errors.activity = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
        shouldHide: renderFalse,
      }),
    []
  )

  const default_extra_fields = _.get(
    privacy_settings,
    'donor_ext_templates'
  )

  const defaultValues = {
    ...initialValues,
    extra_fields: _.isEmpty(
      default_extra_fields
    )
      ? _.get(
          initialValues,
          'extra_fields'
        )
      : Array.from(
          default_extra_fields || []
        ),
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={defaultValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          defaultValues
        )
        onSubmit(createDonor)({
          ...changed,
          privacy: _.get(
            values,
            'privacy'
          ),
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('create donor')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditDonorModal = ({
  onCancel,
  owner_id,
  owner_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'save',
  initialValues: current = newDonor,
}) => {
  const t = useTranslate()

  const { location, avatar, ...rest } =
    current || {}

  const {
    address,
    country,
    ward,
    district,
    province,
  } = location || {}

  const initialValues = {
    ...rest,
    address,
    avatar,
    avatar_preview: avatar,
    country_id: getId(country),
    location: [
      province,
      district,
      ward,
    ].map(getId),
  }

  const {
    isLoading = false,
    handleAsyncAction: editDonor,
  } = useAsyncAction({
    apiInfo: donor_updateDonor_api,
    query: bindQueryParam({
      id: getId(initialValues),
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const { title } = values || {}
    let errors = {}

    if (_.isEmpty(title)) {
      errors.title = t('required field')
    }
    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
        shouldHide: (name) =>
          ['activity'].includes(name),
      }),
    [initialValues.owner]
  )

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(editDonor)(changed)
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit donor')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const MergeDonorModal = ({
  onCancel,
  owner_id,
  owner_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'merge',
  initialValues = newDonor,
}) => {
  const t = useTranslate()

  const form_values = _.pick(
    initialValues,
    ['id', 'to_donor']
  )

  const donor_id = getId(form_values)

  const {
    isLoading = false,
    handleAsyncAction: mergeDonor,
  } = useAsyncAction({
    apiInfo: donor_mergeDonor_api,
    query: bindQueryParam({
      from: donor_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const { to_donor } = values || {}
    let errors = {}

    if (_.isEmpty(to_donor)) {
      errors.to_donor = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = [
    {
      title: 'selected donor',
      children: [
        {
          name: 'id',
          component: ({ value }) =>
            renderDonor()(
              initialValues,
              value
            ),
        },
      ],
    },
    {
      title: 'merge with',
      children: [
        {
          name: 'to_donor',
          component: withProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              name,
              onChange,
              label: 'donor',
              allowClear: true,
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                apiInfo:
                  donor_lookupDonors_api,
                query: bindQueryParam({
                  organization_id:
                    owner_id,
                }),
                values: {
                  exclude_ids: [
                    donor_id,
                  ],
                },
              },
            })
          )(ContainerPicker),
        },
      ],
    },
  ]

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={form_values}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          form_values
        )
        onSubmit(({ to_donor }) => {
          mergeDonor(
            {},
            {
              to: to_donor,
            }
          )
        })(changed)
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('merge donor')}
          </div>
          <Pure>
            <FieldsFactory
              formSchema={formSchema}
            />
          </Pure>
          <Descriptions>
            <Descriptions.Item
              label={
                <span className="font-semibold text-color-000">
                  {t('note')}
                </span>
              }>
              <span className="italic text-color-300">
                {t(
                  'merge note description'
                )}
              </span>
            </Descriptions.Item>
          </Descriptions>
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const DonorActionModal = ({
  type,
  params,
  onMerged,
  onCancel,
  onCreated,
  onUpdated,
  onRefresh,
  initialValues,
  isLoading = false,
  ...props
}) => {
  let Component = Null

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case DonorModalTypes.MERGE:
      Component = MergeDonorModal
      asyncParams = {
        onSuccess: onMerged,
      }
      break
    case DonorModalTypes.CREATE:
      Component = CreateDonorModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case DonorModalTypes.EDIT:
      Component = EditDonorModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    default:
      break
  }

  const { owner_id, owner_type } =
    params || {}

  const onSubmit =
    (handleSubmit = Null) =>
    ({
      id,
      owner,
      activity,
      location,
      owner_id,
      owner_type,
      activity_id,
      to_donor_id,
      to_donor_type,
      extra_fields,
      ...values
    }) => {
      const [
        province_id,
        district_id,
        ward_id,
      ] = Array.from(
        location || [, , ,]
      )

      handleSubmit(
        _.omitBy(
          {
            ..._.pick(
              values,
              Object.keys(newDonor)
            ),
            owner_id,
            owner_type,
            ward_id,
            district_id,
            province_id,
            activity_id,
            ...(_.isEmpty(extra_fields)
              ? {}
              : {
                  extra_fields:
                    JSON.stringify(
                      Array.from(
                        extra_fields
                      )
                    ),
                }),
          },
          _.isUndefined
        )
      )
    }
  return (
    <Modal
      className="custom-modal"
      footer={null}
      closable={false}
      visible={!!type}
      onCancel={onCancel}
      destroyOnClose={true}
      width={modalWidth.medium}>
      {!!isLoading ? (
        <LoadingPage />
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            initialValues,
            owner_id,
            owner_type,
          }}
          {...asyncParams}
        />
      )}
    </Modal>
  )
}

export default DonorActionModal
