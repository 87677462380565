import { Spin } from 'antd'
import { url_getMetaTags_Api } from 'apis'
import classnames from 'classnames'
import { LinkfyEmbed } from 'components/Feed/BaseEmbed'
import { VideoEmbed } from 'components/Feed/VideoEmbed'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'


const Linkfy = props => {
  const {
    block,
    className,
    theme = {},
    onLoad,
    ...otherProps
  } = props
  // leveraging destructuring to omit certain properties from props
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    blockStyleFn,
    preventScroll,
    contentState,
    ...elementProps
  } = otherProps
  const combinedClassName = classnames(
    'p-3 md:p-6 background-100 rounded-lg',
    theme.image,
    className
  )
  const [meta, setMeta] = useState()
  const {
    src
  } = contentState
    .getEntity(block.getEntityAt(0))
    .getData()
  const {
    response,
    isLoading: loadingMeta,
    success,
    handleAsyncAction: getMetaFromUrl
  } = useAsync({
    apiInfo: url_getMetaTags_Api,
    values: {
      content: src
    },
    onSuccess: (result, asyncApi) => {
      const { response } = asyncApi
      const linkMeta = _.get(
        response,
        'data'
      )
      if (linkMeta) {
        setMeta(linkMeta)
      }
    }
  })
  const linkMeta = _.get(
    response,
    'data'
  )

  return (
    <div
      className={combinedClassName}
      {...elementProps}>
      <Spin spinning={loadingMeta}>
        {linkMeta ? (
          !!ReactPlayer.canPlay(
            linkMeta.url
          ) ? (
            <VideoEmbed
              ref="nofollow"
              title={_.get(
                linkMeta,
                'title'
              )}
              url={_.get(
                linkMeta,
                'url'
              )}
              subTitle={
                _.get(
                  linkMeta,
                  'host_url'
                ) ||
                _.get(linkMeta, 'url')
              }
              description={_.get(
                linkMeta,
                'description'
              )}
              image={_.get(
                linkMeta,
                'photo'
              )}
            />
          ) : (
            <LinkfyEmbed
                style={{
                  borderRadius: '0.3rem'
                }}
              title={_.get(
                linkMeta,
                'title'
              )}
              subTitle={
                _.get(
                  linkMeta,
                  'host_url'
                ) ||
                _.get(linkMeta, 'url')
              }
              url={_.get(
                linkMeta,
                'url'
              )}
              description={_.get(
                linkMeta,
                'description'
              )}
              image={_.get(
                linkMeta,
                'photo'
              )}
            />
          )
        ) : (
          <LinkfyEmbed
              style={{
                borderRadius: '0.3rem'
              }}
            title={src}
            subTitle={src}
          />
        )}
      </Spin>
    </div>
  )
}
export default Linkfy
