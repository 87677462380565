import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const GivingLazy = createLazyComponent(
  () => import('./Giving')
)

const Giving = (props) => (
  <GivingLazy {...props} />
)

export default Giving
