import React from 'react';

export const AppPlaceHolderSvg = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 394 277.2"
      {...props}
    >
      <path
        fill="#e8f6fc"
        d="M188.4 123.4c7-28.2 42.7-57.1 76-9.6 22.3 31.8 32.8 70.1 40.2 98.8.1.2.1.4.2.6s.1-.4.1-.6c7.4-28.7 20.5-67.1 44.4-97.7 39.2-50 64.8-19.6 71.9 8.6s6.5 102.2-95.2 228.7c-15.2 18-34 17.1-47.2 0-97.4-131.1-97.6-200-90.4-228.8z"
        transform="translate(-29.8 -88.2)"
      ></path>
      <path
        fill="#e8f6fc"
        d="M265.2 123.4c-7-28.2-42.8-57.1-76-9.6-22.3 31.8-32.9 70.1-40.3 98.8 0 .2-.1.4-.1.6s-.1-.4-.2-.6c-7.4-28.7-20.4-67.1-44.4-97.7-39.2-50-64.8-19.6-71.8 8.6s-6.6 102.2 95.2 228.7c15.2 18 33.9 17.1 47.1 0 97.4-131.1 97.6-200 90.5-228.8z"
        transform="translate(-29.8 -88.2)"
      ></path>
      <path
        fill="#fde8f1"
        d="M245.1 94.5c-25.1-5.3-41-6-43.8 40.3-1.9 29.6 9 97.5 126.4 211l2.1 1.8h-.2l-3.6 4.5c-15.2 18-34 17.1-47.2 0-97.4-131.1-97.6-200-90.4-228.8 5.7-22.8 30.2-46.2 57.1-28.9z"
        transform="translate(-29.8 -88.2)"
      ></path>
      <path
        fill="#fde8f1"
        d="M250 98.1c-23.6-7.6-44.4-5-45 41.3-.5 34.7 11.6 90.1 123.7 204.6l2.1 1.8h-.1c-1.2 1.6-3.5 4.9-4.7 6.4-15.2 18-34 17.1-47.2 0-97.4-131.1-97.6-200-90.4-228.8 5.7-22.8 30.2-46.2 57.1-28.9z"
        transform="translate(-29.8 -88.2)"
      ></path>
      <path
        fill="#fde8f1"
        d="M203.5 98.1c23.7-7.6 44.4-5 45.1 41.3.5 34.7-11.6 90.1-123.8 204.6l-2.1 1.8h.2c1.2 1.6 3.4 4.9 4.7 6.4 15.2 18 33.9 17.1 47.1 0 97.4-131.1 97.6-200 90.5-228.8-5.7-22.8-30.3-46.2-57.1-28.9z"
        transform="translate(-29.8 -88.2)"
      ></path>
    </svg>
  );
}

export default AppPlaceHolderSvg;

