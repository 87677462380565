import {
  InputNumber,
  Radio,
} from 'antd'
import { search_lookkup_getByType_Api } from 'apis'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import { formatterNumber } from 'helpers/formatterNumber'
import { parseNumber } from 'helpers/parseNumber'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { withProps } from 'recompose'
import {
  getResponseItems,
  Null,
  renderIf,
} from 'views/Shared'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'

const RenderCreateNew = ({
  keyword,
  onClick,
}) =>
  renderIf(
    keyword,
    <Translate>
      {(t) => (
        <div onClick={onClick}>
          {`${t('create')} `}
          <span className="font-semibold">
            {`"${keyword}"`}
          </span>
        </div>
      )}
    </Translate>
  )

const AddOnWrapper = ({
  style,
  children,
  className,
  withStyle = (style, isSm) => {},
}) => {
  const { isSm } = useContext(
    LayoutContext
  )
  const _style = {
    minWidth: isSm ? 64 : 128,
    ...(style || {}),
  }

  return (
    <div
      className={classNames(
        'text-sm font-semibold',
        className
      )}
      style={{
        ..._style,
        ...(withStyle(_style, isSm) ||
          {}),
      }}>
      {children}
    </div>
  )
}

const MoneyType = ({
  onItemChange,
  giving_item,
  target_unit_name,
  unit_name_options,
}) => {
  const t = useTranslate()

  const {
    type_cd,
    unit_name,
    giving_qtty,
    giving_value,
  } = giving_item || {}

  const step = _.get(
    wishareFieldTypes.type_cds,
    `${type_cd}.step`,
    1
  )

  const isValueRequired = (unit_name) =>
    Boolean(
      target_unit_name === unit_name &&
        type_cd ===
          wishareFieldTypes.type_cds
            .money.value
    )

  const show_value_field =
    !isValueRequired(unit_name)

  return (
    <div className="flex items-center flex-wrap gap-2">
      <InputNumber
        min={0}
        step={step}
        name="giving_qtty"
        onChange={(value) => {
          onItemChange(
            _.omitBy(
              {
                giving_qtty: value,
                giving_value:
                  show_value_field
                    ? undefined
                    : value,
              },
              _.isUndefined
            )
          )
        }}
        parser={parseNumber}
        value={giving_qtty}
        className="InnerNumber w-full"
        formatter={formatterNumber}
        addonBefore={
          <AddOnWrapper
            withStyle={(style, isSm) =>
              isSm && { minWidth: 68, maxWidth: 80 }
            }>
            {t('volume')}
          </AddOnWrapper>
        }
        addonAfter={
          <AddOnWrapper style={{minWidth: 120}}>
            <SelectWidget
              name="unit_name"
              className="w-full"
              value={
                (
                  _.find(
                    unit_name_options,
                    { value: unit_name }
                  ) || {}
                ).value
              }
              onChange={(value) => {
                onItemChange(
                  _.omitBy(
                    {
                      item_name: value,
                      unit_name: value,
                      giving_value:
                        isValueRequired(
                          value
                        )
                          ? giving_qtty
                          : undefined,
                    },
                    _.isUndefined
                  )
                )
              }}
              options={
                unit_name_options
              }
              placeholder={t('unit')}
            />
          </AddOnWrapper>
        }
      />
      {show_value_field && (
        <InputNumber
          min={0}
          step={step}
          name="giving_value"
          onChange={(value) => {
            onItemChange({
              giving_value: value,
            })
          }}
          parser={parseNumber}
          value={giving_value}
          className="InnerNumber w-full"
          formatter={formatterNumber}
          addonBefore={
            <AddOnWrapper
              withStyle={(
                style,
                isSm
              ) =>
                isSm && { minWidth: 68, maxWidth: 80 }
              }>
              {t('value')}
            </AddOnWrapper>
          }
          addonAfter={
            <AddOnWrapper>
              {target_unit_name}
            </AddOnWrapper>
          }
        />
      )}
    </div>
  )
}

const BloodType = ({
  onItemChange,
  giving_item,
  target_unit_name,
  unit_name_options,
}) => {
  const t = useTranslate()

  const {
    type_cd,
    unit_name,
    giving_qtty,
    giving_value,
  } = giving_item || {}

  const step = _.get(
    wishareFieldTypes.type_cds,
    `${type_cd}.step`,
    1
  )

  const isValueRequired = (unit_name) =>
    Boolean(
      target_unit_name === unit_name &&
        type_cd ===
          wishareFieldTypes.type_cds
            .blood.value
    )

  const show_value_field =
    !isValueRequired(unit_name)

  return (
    <div className="flex items-center flex-wrap gap-2">
      <InputNumber
        min={0}
        step={step}
        name="giving_qtty"
        onChange={(value) => {
          onItemChange(
            _.omitBy(
              {
                giving_qtty: value,
                giving_value:
                  show_value_field
                    ? undefined
                    : value,
              },
              _.isUndefined
            )
          )
        }}
        parser={parseNumber}
        value={giving_qtty}
        className="InnerNumber w-full"
        formatter={formatterNumber}
        addonBefore={
          <AddOnWrapper
            withStyle={(style, isSm) =>
              isSm && { minWidth: 68, maxWidth: 80 }
            }>
            {t('volume')}
          </AddOnWrapper>
        }
        addonAfter={
          <AddOnWrapper style={{minWidth: 120}}>
            <SelectWidget
              name="unit_name"
              className="w-full"
              value={
                (
                  _.find(
                    unit_name_options,
                    { value: unit_name }
                  ) || {}
                ).value
              }
              onChange={(value) => {
                onItemChange(
                  _.omitBy(
                    {
                      unit_name: value,
                      giving_value:
                        isValueRequired(
                          value
                        )
                          ? giving_qtty
                          : undefined,
                    },
                    _.isUndefined
                  )
                )
              }}
              options={
                unit_name_options
              }
              placeholder={t('unit')}
            />
          </AddOnWrapper>
        }
      />
      {show_value_field && (
        <InputNumber
          min={0}
          step={step}
          name="giving_value"
          onChange={(value) => {
            onItemChange({
              giving_value: value,
            })
          }}
          parser={parseNumber}
          value={giving_value}
          className="InnerNumber w-full"
          formatter={formatterNumber}
          addonBefore={
            <AddOnWrapper
              withStyle={(
                style,
                isSm
              ) =>
                isSm && { minWidth: 68, maxWidth: 80 }
              }>
              {t('value')}
            </AddOnWrapper>
          }
          addonAfter={
            <AddOnWrapper>
              {target_unit_name}
            </AddOnWrapper>
          }
        />
      )}
    </div>
  )
}

const GoodsType = ({
  onItemChange,
  giving_item,
  target_unit_name,
  unit_name_options,
}) => {
  const t = useTranslate()

  const unit_ref = useRef()

  const [
    current_unit,
    set_current_unit,
  ] = useState()

  const {
    type_cd,
    unit_name,
    giving_qtty,
    giving_value,
  } = giving_item || {}

  const step = _.get(
    wishareFieldTypes.type_cds,
    `${type_cd}.step`,
    1
  )

  const onUnitSelected = (value) => {
    onItemChange({ unit_name: value })
    if (unit_ref.current) {
      unit_ref.current.blur()
    }
  }

  return (
    <div className="flex items-center flex-wrap gap-2">
      <InputNumber
        min={0}
        step={step}
        name="giving_qtty"
        onChange={(value) => {
          onItemChange({
            giving_qtty: value,
          })
        }}
        parser={parseNumber}
        value={giving_qtty}
        className="InnerNumber w-full"
        formatter={formatterNumber}
        addonBefore={
          <AddOnWrapper
            withStyle={(style, isSm) =>
              isSm && { minWidth: 68, maxWidth: 80 }
            }>
            {t('volume')}
          </AddOnWrapper>
        }
        addonAfter={
          <AddOnWrapper style={{minWidth: 120}}>
            <AntdConfigWrapper
              renderEmpty={withProps({
                keyword: current_unit,
                onClick: () => {
                  onUnitSelected(
                    current_unit
                  )
                },
              })(RenderCreateNew)}>
              <SelectWidget
                ref={unit_ref}
                name="unit_name"
                className="w-full"
                allowClear={true}
                showSearch={true}
                onSearch={(value) => {
                  set_current_unit(
                    value
                  )
                }}
                onInputKeyDown={(
                  event
                ) => {
                  if (
                    event.key ===
                    'Enter'
                  ) {
                    onUnitSelected(
                      current_unit
                    )
                  }
                }}
                value={unit_name}
                onChange={(value) => {
                  onItemChange({
                    unit_name: value,
                  })
                }}
                options={
                  unit_name_options
                }
                placeholder={t('unit')}
              />
            </AntdConfigWrapper>
          </AddOnWrapper>
        }
      />
      <InputNumber
        min={0}
        step={step}
        name="giving_value"
        onChange={(value) => {
          onItemChange({
            giving_value: value,
          })
        }}
        parser={parseNumber}
        value={giving_value}
        className="InnerNumber w-full"
        formatter={formatterNumber}
        addonBefore={
          <AddOnWrapper
            withStyle={(style, isSm) =>
              isSm && { minWidth: 68, maxWidth: 80 }
            }>
            {t('value')}
          </AddOnWrapper>
        }
        addonAfter={
          <AddOnWrapper>
            {target_unit_name}
          </AddOnWrapper>
        }
      />
      <div className="text-xs text-color-400 font-light italic ml-2">
        {`* ${t('Giving in kind can be converted into equivalent value at the time of giving')}`}
      </div>
    </div>
  )
}

const ItemNameRenderer = ({
  type_cd,
  item_name,
  handleChange,
  item_name_options = [],
}) => {
  const ref = useRef()
  const [keyword, setKeyword] =
    useState()

  const onSubmit = (value) => {
    handleChange(value)
    if (ref.current) {
      ref.current.blur()
    }
  }

  return (
    <Translate>
      {(t) =>
        renderIf(
          type_cd ===
            wishareFieldTypes.type_cds
              .goods.value,
          <AntdConfigWrapper
            renderEmpty={withProps({
              keyword,
              onClick: () => {
                onSubmit(keyword)
              },
            })(RenderCreateNew)}>
            <SelectWidget
              ref={ref}
              name="item_name"
              allowClear={true}
              showSearch={true}
              onSearch={(value) => {
                setKeyword(value)
              }}
              onInputKeyDown={(
                event
              ) => {
                if (
                  event.key === 'Enter'
                ) {
                  onSubmit(keyword)
                }
              }}
              style={{
                minWidth: 150,
              }}
              value={item_name}
              onChange={handleChange}
              options={
                item_name_options
              }
              placeholder={t(
                'select item'
              )}
            />
          </AntdConfigWrapper>
        )
      }
    </Translate>
  )
}

const GivingCustomItemType = ({
  actions = [],
  onItemChange,
  giving_item,
  giving_type,
  Wrapper = 'div',
  target_unit_name,
  ItemName = ItemNameRenderer,
  type_options: options,
}) => {
  const t = useTranslate()

  const { type_cd, item_name } =
    giving_item || {}

  const {
    response: item_name_response,
  } = useAsyncWithCache({
    cacheId: [
      search_lookkup_getByType_Api.path,
      type_cd,
      'item_name',
    ].join('/'),
    apiInfo:
      search_lookkup_getByType_Api,
    values: {
      group_type: type_cd,
    },
    query: bindQueryParam({
      type: 'item_name',
    }),
  })

  const {
    response: unit_name_response,
  } = useAsyncWithCache({
    cacheId: [
      search_lookkup_getByType_Api.path,
      type_cd,
      'unit_name',
    ].join('/'),
    apiInfo:
      search_lookkup_getByType_Api,
    values: {
      group_type: type_cd,
    },
    query: bindQueryParam({
      type: 'unit_name',
    }),
  })

  const mapFn = ({ id, name }) => ({
    id,
    name,
    label: t(name),
    value: name,
  })

  const item_name_options = useMemo(
    () =>
      Array.from(
        getResponseItems(
          item_name_response
        )
      ).map(mapFn),
    [t, item_name_response]
  )

  const unit_name_options = useMemo(
    () =>
      Array.from(
        getResponseItems(
          unit_name_response
        )
      ).map(mapFn),
    [t, unit_name_response]
  )

  const content = useMemo(() => {
    let Component = Null
    switch (type_cd) {
      case wishareFieldTypes.type_cds
        .money.value:
        Component = MoneyType
        break
      case wishareFieldTypes.type_cds
        .blood.value:
        Component = BloodType
        break
      case wishareFieldTypes.type_cds
        .goods.value:
        Component = GoodsType
        break
      default:
        break
    }
    return (
      <Component
        {...{
          type_cd,
          onItemChange,
          giving_item,
          target_unit_name,
          item_name_options,
          unit_name_options,
        }}
      />
    )
  }, [
    type_cd,
    onItemChange,
    giving_item,
    target_unit_name,
    item_name_options,
    unit_name_options,
  ])

  let subTitle
  switch (type_cd) {
    case wishareFieldTypes.type_cds.money.value:
      subTitle = 'Give to beneficiary by cash or bank transfer'
      break;
    case wishareFieldTypes.type_cds.goods.value:
      subTitle = 'Give the beneficiary with the items'
      break;
    default:
      subTitle = ''
  }

  return (
    <Wrapper className="w-full flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Radio.Group
          name="type_cd"
          options={options}
          buttonStyle="solid"
          optionType="button"
          className="w-full"
          defaultValue={type_cd}
          onChange={(event) => {
            const { name, value } =
              event.target
            onItemChange({
              item_name: undefined,
              unit_name: undefined,
              giving_qtty: undefined,
              giving_value: undefined,
              [name]: value,
            })
          }}
        />
        {actions}
      </div>
      {
        type_cd !== wishareFieldTypes.type_cds.blood.value ?
            <span className="text-xs text-color-100 font-light italic">
                {`* ${t(subTitle)}`}
            </span> : null
      }
      <Pure
        input={[
          type_cd,
          item_name,
          onItemChange,
          item_name_options,
        ]}>
        <ItemName
          {...{
            type_cd,
            item_name,
            item_name_options,
            handleChange: (value) => {
              onItemChange({
                item_name: value,
              })
            },
          }}
        />
      </Pure>
      {content}
    </Wrapper>
  )
}

export default GivingCustomItemType
