import _ from 'lodash'
import { staticPaths } from 'routes/staticPaths'
import {
  SearchParams,
  SearchTypes,
} from 'views/Search/SearchProvider'
import { Null } from 'views/Shared'
import {
  ActivityExplorer,
  ArticleExplorer,
  ChannelExplorer,
  DonationExplorer,
  EventExplorer,
  GeneralExplorer,
  GivingExplorer,
  OrganizationExplorer,
  RecruitmentExplorer,
  UserExplorer,
} from '../factory/createDiscoveryExplorer'
import { withSearchParams } from '../functions/routerHelper'

const rootPath =
  staticPaths.discovery.pathname

const getSearch =
  (type) => (location) => {
    let _searchParams =
      new URLSearchParams(
        _.get(location, 'search', {})
      )
    _searchParams.set(
      SearchParams.TYPE,
      type
    )
    return [
      '?',
      _searchParams.toString(),
    ].join('')
  }

export const discoveryExplorerRouteEntities =
  [
    {
      type: SearchTypes.ALL,
      path: rootPath,
      invisible: true,
      getSearch: Null,
      component: GeneralExplorer,
    },
    {
      type: SearchTypes.ORGANIZATION,
      component: OrganizationExplorer,
    },
    {
      type: SearchTypes.ACTIVITY,
      component: ActivityExplorer,
    },
    {
      type: SearchTypes.DONATION_EVENT,
      component: DonationExplorer,
    },
    {
      type: SearchTypes.GIVING_EVENT,
      component: GivingExplorer,
    },
    {
      type: SearchTypes.EVENT,
      component: EventExplorer,
    },
    {
      type: SearchTypes.RECRUITMENT_EVENT,
      component: RecruitmentExplorer,
    },
    {
      type: SearchTypes.CHANNEL,
      component: ChannelExplorer,
    },
    {
      type: SearchTypes.ARTICLE,
      component: ArticleExplorer,
    },
  ].map(({ type, ...props }) => {
    const search = `${SearchParams.TYPE}=${type}`
    return {
      type,
      search,
      pathname: rootPath,
      getSearch: withSearchParams({
        [SearchParams.TYPE]: type,
        [SearchParams.KEYWORD]:
          undefined,
      }),
      path: [rootPath, search].join(
        '?'
      ),
      ...props,
    }
  })
