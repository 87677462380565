import React from 'react'
import Loadable from 'react-loadable'
import LoadingPage from 'views/LoadingPage'

const UserCtrlLazy = Loadable({
  loader: () => import('./UserCtrl'),
  loading: LoadingPage,
})

const UserCtrl = (props) => (
  <UserCtrlLazy {...props} />
)

export default UserCtrl
