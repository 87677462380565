import { Spin } from 'antd'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import React, { useEffect } from 'react'
import { Null } from 'views/Shared'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { API_ROOT_URL } from '../../envs/_current/config'

function DebounceSelect({
  name,
  type,
  fetchOptions,
  onChange = Null,
  defaultOptions = [],
  excludedOptions = [],
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] =
    React.useState(false)
  const [options, setOptions] =
    React.useState(defaultOptions)
  const fetchRef = React.useRef(0)
  const debounceFetcher =
    React.useMemo(() => {
      const loadOptions = (
        value = ''
      ) => {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setFetching(true)
        fetchOptions(type, value).then(
          (newOptions) => {
            if (
              fetchId !==
              fetchRef.current
            ) {
              // for fetch callback order
              return
            }

            setOptions(newOptions)
            setFetching(false)
          }
        )
      }

      return debounce(
        loadOptions,
        debounceTimeout
      )
    }, [fetchOptions, debounceTimeout])

  let timeout
  useEffect(() => {
    timeout = setTimeout(() => {
      debounceFetcher()
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  const getOptions = (options) => {
    let result = options
      ? Array.from(options || [])
      : undefined

    const array = Array.from(
      props.value || []
    )

    const matched = _.filter(
      excludedOptions,
      ({ value }) =>
        array.includes(value)
    )

    if (!_.isEmpty(matched)) {
      result = [
        ...matched,
        ...result.filter(
          ({ value }) =>
            !_.find(matched, { value })
        ),
      ]
    }

    return result
  }

  const _options = getOptions(options)

  return (
    <SelectWidget
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : null
      }
      onFocus={() => {
        debounceFetcher()
      }}
      onChange={(value, option) => {
        onChange(value, option)
        if (_.isEmpty(value)) {
          debounceFetcher()
        }
      }}
      {...props}
      options={_options}
      loading={fetching}
      value={
        _.isEmpty(_options)
          ? undefined
          : props.value
      }
    />
  )
} // Usage of DebounceSelect

export async function fetchLookupList(
  type,
  keyword
) {
  console.log(
    'fetching ',
    type,
    keyword
  )
  return fetch(
    API_ROOT_URL +
      `/lookup/${type}/gets?keyword=${keyword}`
  )
    .then((response) => response.json())
    .then((body) => {
      let options = _.get(
        body,
        'data',
        []
      ).map((e) => ({
        label: e.name,
        value: e.name,
        id: e.id,
      }))

      const found = options.some(
        (el) => el.value === keyword
      )
      if (!found)
        options.push({
          label: keyword,
          value: keyword,
          id: null,
        })

      return [...options]
    })
}

export async function fetchLookupByTypeList(
  type,
  keyword
) {
  return fetch(
    API_ROOT_URL +
      `/lookup/${type}/get-by-types?keyword=${keyword}`
  )
    .then((response) => response.json())
    .then((body) => {
      let options = _.get(
        body,
        'data',
        []
      ).map((e) => ({
        label: e.name,
        value: e.name,
        id: e.id,
      }))

      // const found = options.some(
      //   (el) => el.value === keyword
      // )
      // if (!found)
      //   options.push({
      //     label: keyword,
      //     value: keyword,
      //     id: null,
      //   })

      return [...options]
    })
}

export default DebounceSelect
