import { Card, Radio } from 'antd'
import {
  auth_getAuthProfile_Api,
  auth_getUserProfile_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import { profile_updateProfileInfo_Api } from 'apis/profile'
import { userProfileSchema } from 'apis/schema'
import { FieldHeader } from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { defaultRawContent } from 'components/RichEditor/RichEditor'
import { SelectEntityItem } from 'components/SelectEntityItem'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import { useToggle } from 'react-use'
import {
  branch,
  compose,
  mapProps,
  pure,
  renderNothing,
  withProps,
} from 'recompose'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import LoadingPage from 'views/LoadingPage'
import LinkedPages from 'views/Organization/Settings/Profile/LinkedPages'
import { SelectCountries } from 'views/Organization/Settings/SelectCountries'
import {
  Null,
  renderIf,
  renderOwnChild,
} from 'views/Shared'
import { withAppConfig } from 'views/Wishare/composers'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { MultiSelectDataSource } from 'views/Wishare/custom/SelectDataSource'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  mapEntityOptions,
  mapSingleValue,
} from 'views/Wishare/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { WishareModal } from 'views/Wishare/ModalContext'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from 'views/Wishare/wishareFieldTypes'
import EditProfileAbout from '../ActionForms/EditProfileAbout'
import EditProfileEducation from '../ActionForms/EditProfileEducation'
import EditProfileLanguage from '../ActionForms/EditProfileLanguage'
import EditProfileSkill from '../ActionForms/EditProfileSkill'
import EditProfileWork from '../ActionForms/EditProfileWork'
import EditProfileWorkingTime from '../ActionForms/EditProfileWorkingTime'
import { renderLocationCascade } from '../functions/renderLocationCascade'

const recruitment_available_options = [
  {
    value: 1,
    name: 'yes',
    label: 'ready',
  },
  {
    value: 0,
    name: 'no',
    label: 'no',
  },
]

const formFields = [
  {
    name: 'about',
  },
  {
    name: 'recruitment_available',
    defaultValue: Number(
      getDefaultFieldValue(
        recruitment_available_options
      )
    ),
  },
  {
    name: 'prefer_ward_id',
  },
  {
    name: 'prefer_country_id',
  },
  {
    name: 'prefer_district_id',
  },
  {
    name: 'prefer_province_id',
  },
  {
    name: 'prefer_work_time_type',
  },
  {
    name: 'prefer_remote_work',
  },
  {
    name: 'occupation_types',
  },
  {
    name: 'address',
  },
  {
    name: 'linked_pages',
    defaultValue: [],
  },
  {
    name: 'skill_profiles',
  },
  {
    name: 'language_profiles',
  },
  {
    name: 'education_profiles',
  },
  {
    name: 'work_profiles',
  },
  {
    name: 'timetable_profiles',
  },
]

const formSchema = [
  {
    children: [
      {
        name: 'recruitment_available',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              size: 'small',
              children: (
                <div className="flex flex-col md:flex-row items-end md:items-center">
                  <span className="flex-1 italic font-medium text-color-200">
                    {translate(
                      'are you ready for volunteer work'
                    )}
                  </span>
                  <Radio.Group
                    {...{
                      name,
                      value,
                      onChange,
                    }}
                    buttonStyle="solid">
                    {recruitment_available_options.map(
                      ({
                        name,
                        value,
                        label,
                      }) => (
                        <Radio.Button
                          key={name}
                          value={value}>
                          {translate(
                            label
                          )}
                        </Radio.Button>
                      )
                    )}
                  </Radio.Group>
                </div>
              ),
            })
          )
        )(Card),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'work type',
        name: 'prefer_work_time_type',
        component: withAdvancedOptions(
          wishareFieldTypes.recruitment
            .work_time_types
        )(RadioOptions),
      },
      {
        label: 'is remote work',
        name: 'prefer_remote_work',
        component: withAdvancedOptions(
          wishareFieldTypes.recruitment
            .onSiteOrRemote
        )(RadioOptions),
      },
    ],
  },
  {
    title: 'occupation types',
    children: [
      {
        name: 'occupation_types',
        component: compose(
          withAppConfig,
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              appConfig,
              translate,
            }) => {
              return {
                name,
                value,
                onChange,
                options:
                  mapEntityOptions(
                    _.get(
                      appConfig,
                      'occupation_types'
                    ) || [],
                    {
                      getLabel: (
                        item
                      ) =>
                        translate(
                          getTitle(item)
                        ),
                    }
                  ),
                placeholder: translate(
                  'occupation types'
                ),
              }
            }
          )
        )(MultiSelectDataSource),
      },
    ],
  },
  {
    title: 'country',
    children: [
      {
        name: 'prefer_country_id',
        component: compose(
          withTranslate,
          withAppConfig,
          mapProps(
            ({
              name,
              form,
              value,
              onChange,
              translate,
              appConfig,
            }) => ({
              name,
              value,
              onChange: (value) => {
                const {
                  values,
                  setValues = Null,
                } = form || {}
                const hasLocationMeta =
                  _.some(
                    _.get(
                      appConfig,
                      'provinces',
                      []
                    ),
                    {
                      country_id: value,
                    }
                  )
                if (!hasLocationMeta) {
                  setValues({
                    ...values,
                    address: [],
                    [name]: value,
                  })
                } else {
                  setValues({
                    ...values,
                    [name]: value,
                  })
                }
              },
              placeholder:
                translate('country'),
              Component: withProps({
                size: 'large',
              })(SelectWidget),
            })
          ),
          pure
        )(SelectCountries),
      },
    ],
  },
  {
    children: [
      {
        name: 'address',
        component: ({
          name,
          value,
          form,
          onChange,
        }) =>
          renderLocationCascade({
            name,
            value,
            onChange,
            country_id: _.get(
              form,
              'values.prefer_country_id'
            ),
            Header: withProps({
              title: 'address',
            })(FieldHeader),
          }),
      },
    ],
  },
  {
    title: 'my page',
    children: [
      {
        name: 'linked_pages',
        component: compose(
          mapProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              data: value,
              classNames: [
                'flex items-center',
              ],
              renderWrapper: ({
                children,
              }) => (
                <div className="flex flex-col gap-2">
                  {children}
                </div>
              ),
              onChange: (value) => {
                onChange(
                  createValue(
                    name,
                    value
                  )
                )
              },
            })
          ),
          pure
        )(LinkedPages),
      },
    ],
  },
  {
    children: [
      {
        name: 'about',
        component: EditProfileAbout,
      },
    ],
  },
  {
    children: [
      {
        name: 'skill_profiles',
        component: EditProfileSkill,
      },
    ],
  },
  {
    children: [
      {
        name: 'language_profiles',
        component: EditProfileLanguage,
      },
    ],
  },
  {
    children: [
      {
        name: 'education_profiles',
        component: EditProfileEducation,
      },
    ],
  },
  {
    children: [
      {
        name: 'work_profiles',
        component: EditProfileWork,
      },
    ],
  },
  {
    invisible: true,
    children: [
      {
        name: 'timetable_profiles',
        component:
          EditProfileWorkingTime,
      },
    ],
  },
]

const UserProfile = ({
  profile,
  onCancel = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  if (_.isEmpty(profile)) return null

  const validationSchema = undefined

  const {
    about,
    prefer_ward,
    prefer_country,
    prefer_district,
    prefer_province,
    occupation_types,
    prefer_remote_work,
    prefer_work_time_type,
    ...rest
  } = profile || {}

  const onSuccess = ([
    __,
    { response },
  ]) => {
    onCancel()
  }

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    item: {
      ...rest,
      about: about || defaultRawContent,
      prefer_work_time_type:
        prefer_work_time_type ||
        getDefaultFieldValue(
          wishareFieldTypes.recruitment
            .work_time_types
        ),
      prefer_remote_work:
        prefer_remote_work ||
        Number(
          getDefaultFieldValue(
            wishareFieldTypes
              .recruitment
              .onSiteOrRemote
          )
        ),
      prefer_country_id:
        getId(prefer_country) || 237,
      prefer_ward_id: getId(
        prefer_ward
      ),
      prefer_district_id: getId(
        prefer_district
      ),
      prefer_province_id: getId(
        prefer_province
      ),
      address: [
        prefer_province,
        prefer_district,
        prefer_ward,
      ].map(getId),
      occupation_types: mapSingleValue(
        occupation_types
      ),
    },
    onSuccess,
    validationSchema,
    onError: notifyOnError(t),
    apiInfo:
      profile_updateProfileInfo_Api,
    initialized: ({ ...values }) => ({
      ...values,
    }),
    onPreSubmit: ({
      address,
      linked_pages,
      ...values
    }) => {
      const [
        prefer_province_id,
        prefer_district_id,
        prefer_ward_id,
      ] = address || []
      return {
        ...values,
        ...{
          prefer_ward_id,
          prefer_district_id,
          prefer_province_id,
        },
        ...(linked_pages
          ? {
              linked_pages:
                JSON.stringify(
                  linked_pages
                ),
            }
          : {}),
      }
    },
    ActionBar: () => (
      <FormActionBar submitText="update" />
    ),
  })

  return (
    <Wrapper>
      <div className="text-center uppercase font-bold text-xl md:text-2xl text-color-000 my-3">
        {t('update your profile')}
      </div>
      {content}
    </Wrapper>
  )
}

const DefaultToggle = ({
  onClick,
  ...props
}) => (
  <Translate>
    {(t) => (
      <div
        {...props}
        onClick={onClick}>
        {t('click me')}
      </div>
    )}
  </Translate>
)

const ProfileModal = ({
  result,
  isLoading,
  handleAsyncAction,
  render = DefaultToggle,
}) => {
  const [isToggle, toggle] = useToggle()

  return (
    <React.Fragment>
      {render({
        onClick: () => {
          handleAsyncAction()
          toggle(true)
        },
      })}
      <WishareModal
        className="custom-modal"
        visible={isToggle}
        destroyOnClose={true}
        onCancel={() => toggle(false)}>
        {!!isLoading ? (
          <div className="flex flex-col items-center justify-center h-64">
            <LoadingPage />
          </div>
        ) : (
          renderIf(
            result,
            <SelectEntityItem
              item={result}
              schema={
                userProfileSchema
              }>
              {(item = {}) => {
                if (_.isEmpty(item)) {
                  return null
                }
                return (
                  <UserProfile
                    profile={item}
                    onCancel={() =>
                      toggle(false)
                    }
                  />
                )
              }}
            </SelectEntityItem>
          )
        )}
      </WishareModal>
    </React.Fragment>
  )
}

export const ViewProfileModal =
  createRequiredLogin()(
    compose(
      branch(
        ({ username }) =>
          _.isEmpty(username),
        renderNothing
      )
    )(({ username, ...props }) => {
      const {
        result,
        isLoading,
        handleAsyncAction,
      } = useAsyncAction({
        query: bindQueryParam({
          username,
        }),
        apiInfo:
          auth_getUserProfile_Api,
      })

      return (
        <ProfileModal
          {...{
            result,
            isLoading,
            handleAsyncAction,
          }}
          {...props}
        />
      )
    })
  )

export const MyProfileModal = ({
  render = DefaultToggle,
}) => {
  const {
    result,
    isLoading,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: auth_getAuthProfile_Api,
  })

  return (
    <ProfileModal
      {...{
        render,
        result,
        isLoading,
        handleAsyncAction,
      }}
    />
  )
}

export default createRequiredLogin()(
  compose(
    branch(
      ({ invisible }) =>
        Boolean(invisible),
      renderNothing
    )
  )(UserProfile)
)
