import {Badge, Button, Input} from 'antd'
import {referral_getReferralCode_Api} from 'apis'
import {channelModel} from 'apis/model'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {UpdateReferalCode} from 'components/UpdateReferalCode'
import copyToClipboard from 'copy-to-clipboard'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext, useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import LoadingPage from 'views/LoadingPage'
import ServerContext from 'views/Server/ServerContext'

const Invite = ({ item: i }) => {
  const t = useTranslate()
  const { item } = useContext(
    ServerContext
  )
  const [isToggle, toggle] = useToggle(
  )
  const [
    isToggleCopy2,
    toggleCopy2
  ] = useToggle(false)
  const { response } = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: (i || item).id,
      target_type: (i || item)._type
    }
  })
  useLayoutEffect(() => {
    let timeout
    if (isToggle) {
      timeout = setTimeout(
        () => toggle(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggle, toggle])

  useLayoutEffect(() => {
    let timeout
    if (isToggleCopy2) {
      timeout = setTimeout(
        () => toggleCopy2(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggleCopy2, toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage />
  const inviteLink =
    ROOT_URL +
    '/invite/' +
    _.get(data, 'referral_code')
  const timeout = moment(
    _.get(data, 'created')
  )
    .add(
      _.get(data, 'timeout'),
      'second'
    )
    .fromNow()
  return (
    <div className="flex flex-col py-3 px-5 pb-5 verticalList__large">
      <h2 className="text-xl uppercase pr-5 font-bold pt-3 mb-6">
        {t(
          'Invite your friends to this page'
        )}
      </h2>
      <UpdateReferalCode referral={data} />
      <div className="p-6 w-full flex  shadow rounded-lg background-100 verticalList">

        <div
          className="text-color-400 rounded-full background-200  flex items-center justify-center"
          style={{
            width: '100px',
            height: '100px',
            fontSize: '80px'
          }}>
          <ChannelIcon
            premium={item.premium}
            className="text-6xl"
          />
        </div>
        <div className="verticalList ml-3 mr-1">
          <Link
            to={channelModel.getLinkToDetail(
              item
            )}
            className="font-bold text-lg">
            {item.title}
          </Link>
          <div
            style={{
              justifySelf: 'flex-end',
              display: 'flex'
            }}>
            <div className="flex items-center mr-3 rounded-full px-3 py-1 background-200">
              <Badge
                color="blue"
                text={
                  <span
                    style={{
                      fontWeight: '400'
                    }}>
                    {item.total_members}{' '}
                    {t('members')}
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs">
        {item.description}
      </div>
      <div className="pb-3">
        <div className="mb-2 font-semibold text-color-300">
          {t(
            'Send invitation to join the channel to your friends, by sending a copy of the page link below'
          )}
        </div>
        <div
          onClick={() => {
            copyToClipboard(inviteLink)
            toggle(true)
          }}>
          <Input
            size="large"
            value={inviteLink}
            suffix={
              <Button
                size="small"
                type="primary">
                {t(
                  isToggle
                    ? 'copied'
                    : 'copy'
                )}
              </Button>
            }
          />
        </div>
        <div className="mb-2 text-xs  text-color-300">
          {_.get(data, 'timeout', 0) ===
            0 ||
            _.get(
              data,
              'no_expired',
              0
            ) === 1
            ? t('Permanent link')
            : `${t(
              'your invite link expires'
            )} ${timeout}`}
        </div>
        <div className="pt-5">
          {t(
            'Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel'
          )}
          <div
            onClick={() => {
              copyToClipboard(
                ROOT_URL +
                channelModel.getLinkToDetail(
                  item
                )
              )
              toggleCopy2(true)
            }}>
            <Input
              value={
                ROOT_URL +
                channelModel.getLinkToDetail(
                  item
                )
              }
              suffix={
                <Button
                  size="small"
                  type="primary">
                  {t(
                    isToggleCopy2
                      ? 'copied'
                      : 'copy'
                  )}
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Invite



