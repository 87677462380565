import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import { ReactSelectDataSource } from 'views/Wishare/custom/SelectDataSource'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import {
  createControlledFormField,
  createNumberControlledInput,
} from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import RecruitmentContext from '../RecruitmentContext'

const formFields = [
  { name: 'salary' },
  { name: 'min_salary' },
  { name: 'max_salary' },
  { name: 'salary_period' },
  { name: 'salary_unit_name' },
]

const formSchema = [
  {
    title: 'salary period',
    children: [
      {
        name: 'salary_period',
        component:
          createControlledFormField({
            options:
              wishareFieldTypes
                .recruitment
                .salary_periods,
            Component:
              ReactSelectDataSource,
            placeholder:
              'salary period',
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'salary',
        name: 'salary',
        component:
          createNumberControlledInput(),
      },
      {
        label: 'unit',
        name: 'salary_unit_name',
        component:
          createControlledFormField({
            options:
              wishareFieldTypes
                .recruitment
                .salary_units,
            Component:
              ReactSelectDataSource,
            placeholder: 'unit',
          }),
      },
    ],
  },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'min salary',
        name: 'min_salary',
        component:
          createNumberControlledInput(),
      },
      {
        label: 'max salary',
        name: 'max_salary',
        component:
          createNumberControlledInput(),
      },
    ],
  },
]

const RecruitmentSettingJobSalary =
  () => {
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema = undefined

    const recruitment_id = getId(
      recruitment
    )

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingJobSalary
