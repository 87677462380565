import {GG_ID} from 'envs/_current/config'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import GoogleLogin from 'react-google-login'


export default function GoogleLoginBtn({
  onSuccess
}) {
  const t = useTranslate()
  const responseGoogle = response => {
    if (response['accessToken']) {
      onSuccess({
        token: response.accessToken,
        provider: 'google'
      })
    }
  }
  return (
    <GoogleLogin
      type=""
      clientId={GG_ID}
      scope="email profile openid"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      render={renderProps => {
        return (
          <button
            className="loginBtn loginBtn--google"
            onClick={
              renderProps.onClick
            }
            disabled={
              renderProps.disabled
            }>
            {t('login with Google')}
          </button>
        )
      }}
    />
  )
}
