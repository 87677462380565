import Null from 'components/NullComponent'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {compose} from 'recompose'

export const get = key => item => _.get(item, key)

export const getTitle = get('title')

export const getId = item =>
  get('idname')(item) ||
  get('username')(item) ||
  get('id')(item)
export const getType = get('_type')

export const getDescription = get(
  'description'
)

export const getFullImageUrl = avatar =>
  avatar

export const getAvatar = get('avatar')

export const getThumbnail = get(
  'thumbnail'
)
export const getCover = get(
  'cover_photo'
)
export const getFullAvatarUrl = getAvatar
export const getThumbnailUrl = getThumbnail
export const getFullCoverUrl = compose(
  getFullImageUrl,
  getCover
)
export const getLinkToDetailGenerator = ({
  getType = Null,
  getId = Null
}) => item =>
    `/${getType(item)}/${getId(item)}`
export const getLinkToShareGenerator = ({
  rootUrl = ROOT_URL,
  getType = Null,
  getId = Null
}) => item =>
    `${rootUrl}/${getType(item)}/${getId(
      item
    )}`
export const getLinkToSettingGenerator = ({
  getType = Null,
  getId = Null
}) => item =>
    `/${getType(item)}/${getId(
      item
    )}/setting`
export const getMetaGenerator = ({
  getTitle,
  getDescription,
  getImage,
  getUrl
}) => item => {
  return {
    title: getTitle(item),
    description: getDescription(item),
    image: getImage(item),
    url: getUrl(item)
  }
}
export const getLinkToDetail = getLinkToDetailGenerator(
  { getType, getId }
)
export const getLinkToSetting = getLinkToSettingGenerator(
  { getType, getId }
)
export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)
export default {
  get: _.get,
  getTitle,
  getId,
  getType,
  getLinkToDetail,
  getLinkToSetting,
  getFullAvatarUrl,
  getThumbnailUrl,
  getFullCoverUrl,
  getMeta: getMetaGenerator({
    getTitle,
    getDescription,
    getImage: getFullAvatarUrl,
    getUrl: getLinkToShare
  })
}
