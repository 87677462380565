import {
  Button,
  Card,
  ConfigProvider,
  List,
  Modal,
  Spin,
  TimePicker,
} from 'antd'
import { getId } from 'apis/model/base'
import {
  profile_addTimetable_Api,
  profile_deleteTimetable_Api,
  profile_updateTimetable_Api,
} from 'apis/profile'
import classNames from 'classnames'
import { handleconfirm } from 'components/Confirm'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  IoAdd,
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {
  compose,
  mapProps,
  nest,
} from 'recompose'
import {
  getResponseItem,
  Null,
  renderFalse,
  renderOwnChild,
  renderSelf,
  updateArray,
} from 'views/Shared'
import {
  ObserverContext,
  ObserverProvider,
} from 'views/Shared/components/ObservedList'
import CRUD from 'views/Shared/enums/CRUD'
import { wishareConfigs } from 'views/Wishare/configs'
import { RenderEmptyMeta } from 'views/Wishare/custom/AntdConfigWrapper'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'

const formSchema = [
  {
    title: 'date',
    children: [
      {
        name: 'from_date',
        component:
          createDateRangerPicker([
            'from_date',
            'to_date',
          ]),
      },
    ],
  },
  {
    title: 'time',
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        name: 'start_time',
        component: compose(
          mapProps(
            ({
              name,
              value,
              onChange = Null,
            }) => ({
              name,
              value,
              format:
                wishareConfigs.timeFormat,
              onChange: (time) => {
                onChange(
                  createValue(
                    name,
                    time
                  )
                )
              },
            })
          )
        )(TimePicker),
      },
      {
        name: 'end_time',
        component: compose(
          mapProps(
            ({
              name,
              value,
              onChange = Null,
            }) => ({
              name,
              value,
              format:
                wishareConfigs.timeFormat,
              onChange: (time) => {
                onChange(
                  createValue(
                    name,
                    time
                  )
                )
              },
            })
          )
        )(TimePicker),
      },
    ],
  },
]

const renderDate = ([
  from_date,
  to_date,
]) => (
  <Translate>
    {(t) => (
      <div className="font-bold">
        {[
          {
            value: from_date,
            emptyValue: '',
            render: (text) =>
              [
                t('from'),
                String(text),
              ].join(' '),
          },
          {
            value: to_date,
            emptyValue: '',
            render: (text) =>
              [
                t('to'),
                String(text),
              ].join(' '),
          },
        ]
          .map(
            ({
              value,
              render = renderSelf,
              emptyValue = renderFalse,
            }) =>
              Boolean(
                !value ||
                  !moment(
                    value
                  ).isValid()
              )
                ? emptyValue
                : render(
                    moment(
                      value
                    ).format(
                      wishareConfigs.dateFormat
                    )
                  )
          )
          .join(' ')}
      </div>
    )}
  </Translate>
)

const renderItem =
  (actions) =>
  (
    {
      to_date,
      from_date,
      start_time,
      end_time,
      ...rest
    },
    index
  ) =>
    (
      <Translate key={index}>
        {(t) => (
          <List.Item
            style={{
              padding: '0.25rem 0',
            }}
            className="items-start"
            extra={actions}>
            <List.Item.Meta
              title={renderDate([
                from_date,
                to_date,
              ])}
              description={
                <div
                  style={{
                    paddingLeft:
                      '0.5rem',
                  }}
                  className={classNames(
                    wishareClassNames.inline_2,
                    'gap-2 unordered-list'
                  )}>
                  {[
                    {
                      label: 'time',
                      value: [
                        start_time,
                        end_time,
                      ],
                      transform: (
                        value = []
                      ) => (
                        <span>
                          {value.join(
                            ' - '
                          )}
                        </span>
                      ),
                    },
                  ].map(
                    (
                      {
                        label,
                        value,
                        transform = renderSelf,
                      },
                      index
                    ) => (
                      <div
                        key={index}
                        className="unordered-item font-light italic text-xs text-color-500">
                        {`${t(
                          label
                        )}: `}
                        <span className="font-medium text-color-100 capitalize">
                          {transform(
                            value
                          )}
                        </span>
                      </div>
                    )
                  )}
                </div>
              }
            />
          </List.Item>
        )}
      </Translate>
    )

const FormContainer = ({
  action,
  values,
  onCancel = Null,
  setValue = Null,
  handleSubmit = Null,
}) => {
  const t = useTranslate()

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const onError = notifyOnError(t)

  const {
    type: action_type,
    value: current_item,
  } = action || {}

  const {
    isLoading: isCreating,
    handleAsyncAction: handleCreate,
  } = useAsyncAction({
    apiInfo: profile_addTimetable_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      addPost(getResponseItem(response))
    },
    onError,
  })

  const {
    isLoading: isUpdating,
    handleAsyncAction: handleUpdate,
  } = useAsyncAction({
    query: bindQueryParam({
      id: getId(current_item),
    }),
    apiInfo:
      profile_updateTimetable_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      const newItem =
        getResponseItem(response)
      setValue(getId(newItem), newItem)
    },
    onError,
  })

  const params =
    action_type === CRUD.UPDATE
      ? {
          value: current_item,
          isLoading: isUpdating,
          onSubmit: handleUpdate,
        }
      : {
          value: '',
          isLoading: isCreating,
          onSubmit: handleCreate,
        }

  return (
    <WorkingTimeForm
      {...params}
      records={values}
      action={action_type}
      handleSubmit={handleSubmit}
    />
  )
}

const getDateTime = (
  values,
  useTimeString = true
) => {
  return [
    {
      name: 'start_time',
      format: wishareConfigs.timeFormat,
      transform: (data, format) =>
        useTimeString
          ? moment(data, format)
          : data.format(format),
    },
    {
      name: 'end_time',
      format: wishareConfigs.timeFormat,
      transform: (data, format) =>
        useTimeString
          ? moment(data, format)
          : data.format(format),
    },
    {
      name: 'from_date',
    },
    {
      name: 'to_date',
    },
  ].reduce(
    (
      prev,
      {
        name,
        transform = (data, format) =>
          moment(data).format(format),
        format = wishareConfigs.dateFormat,
      }
    ) => {
      const value = transform(
        _.get(values, name),
        format
      )
      return Boolean(
        !useTimeString ||
          Boolean(
            value &&
              moment(value).isValid()
          )
      )
        ? {
            ...prev,
            [name]: value,
          }
        : prev
    },
    {}
  )
}

const WorkingTimeForm = ({
  value,
  isLoading,
  records = [],
  onSubmit = Null,
  handleSubmit = Null,
  action = CRUD.CREATE,
  ...props
}) => {
  const initialValues = {
    ...(getDateTime(value) || {}),
  }

  return (
    <Spin spinning={!!isLoading}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={({
          start_time,
          end_time,
          from_date,
          to_date,
          ...values
        }) => {
          const dateTime = getDateTime(
            {
              start_time,
              end_time,
              from_date,
              to_date,
            },
            false
          )
          handleSubmit(onSubmit)({
            ...values,
            ...dateTime,
          })
        }}>
        <FlexCol className="space-y-2">
          <FieldsFactory
            formSchema={formSchema}
          />
          <FormActionBar
            submitText={
              action === CRUD.CREATE
                ? 'create'
                : 'save'
            }
            background={false}
          />
        </FlexCol>
      </Formik>
    </Spin>
  )
}

const EditProfileWorkingTime = ({
  value,
  readOnly = false,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [currentAction, setAction] =
    useState({
      type: undefined,
      value: undefined,
    })

  const [records, setRecords] =
    useState(Array.from(value || []))

  const {
    posts = [],
    updatePost = Null,
    removePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: deleteWork,
  } = useAsyncAction({
    apiInfo:
      profile_deleteTimetable_Api,
    onError: notifyOnError(t),
    onSuccess: (
      result,
      { response }
    ) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      const deletedId = getId(
        getResponseItem(response)
      )
      if (deletedId) {
        removePost(deletedId)
        setRecords([
          ...records.filter(
            (record) =>
              getId(record) !==
              deletedId
          ),
        ])
      }
    },
  })

  const remove = useCallback(
    (item) => {
      handleconfirm({
        title: t(
          'Are you sure delete this?'
        ),
        onOk: () => {
          const id = getId(item)
          if (id) {
            deleteWork(
              {},
              bindQueryParam({ id })
            )
          }
        },
      })
    },
    [deleteWork]
  )

  const onCancel = () => {
    setAction({})
  }

  return (
    <Wrapper>
      <Card
        size="small"
        title={t('working time')}
        style={{
            borderRadius: '0.5rem',
            border: '1px solid rgb(218 218 218)',
        }}
        headStyle={{
            fontStyle: 'italic',
            color: 'var(--text-color-400)',
            fontWeight: '500',
            background: 'var(--background-100)',
            borderBottom: 'none',
            borderRadius: '0.45rem 0.45rem 0 0',
        }}
        extra={
          readOnly ? undefined : (
            <Button
              ghost={true}
              size="small"
              type="primary"
              icon={<IoAdd />}
              onClick={() =>
                setAction({
                  type: CRUD.CREATE,
                })
              }
              className="flex items-center space-x-1 rounded-md no-shadow no-text-shadow">
              <span className="text-xs">
                {t('add')}
              </span>
            </Button>
          )
        }>
        <ConfigProvider
          renderEmpty={RenderEmptyMeta}>
          <List
            dataSource={[
              ...records,
              ...posts,
            ]}
            renderItem={(item, index) =>
              renderItem(
                readOnly ? undefined : (
                  <div className="flex gap-2">
                    <div
                      onClick={() =>
                        remove(item)
                      }
                      className="w-7 h-7 p-1 rounded border border-red-500 cursor-pointer flex items-center justify-center">
                      <IoTrashOutline className="text-red-500" />
                    </div>
                    <div
                      className="w-7 h-7 p-1 rounded border border-primary cursor-pointer flex items-center justify-center"
                      onClick={() => {
                        setAction({
                          type: CRUD.UPDATE,
                          value: item,
                        })
                      }}>
                      <IoCreateOutline className="text-primary" />
                    </div>
                  </div>
                )
              )(item, index)
            }
          />
        </ConfigProvider>
      </Card>
      <Modal
        className="custom-modal"
        footer={null}
        closable={false}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={currentAction.type}>
        <FormContainer
          values={records}
          onCancel={onCancel}
          setValue={(id, value) => {
            const values = updateArray(
              records
            )(id, value)
            if (_.isArray(values)) {
              updatePost(id, value)
              setRecords(values)
            }
          }}
          action={currentAction}
          handleSubmit={(submit) =>
            (values) => {
              submit(values)
            }}
        />
      </Modal>
    </Wrapper>
  )
}

export default nest(
  ObserverProvider,
  EditProfileWorkingTime
)
