import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { Switch } from 'antd'
import classnames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { IoLockClosed } from 'react-icons/io5'

const FieldDescription = ({
  children,
  className,
  description,
}) => {
  const t = useTranslate()
  return (
    <div className={className}>
      <div className="flex-1">
        {t(description)}
      </div>
      {children}
    </div>
  )
}

export const ToggleField = ({
  name,
  title,
  value,
  disabled,
  className,
  onChange,
  description,
  defaultValue,
  Wrapper = FieldDescription,
}) => (
  <Wrapper
    description={description}
    className={classnames(
      'flex items-start justify-start w-full space-x-3',
      className
    )}>
    <Switch
      disabled={disabled}
      onChange={onChange}
      checkedChildren={
        <CheckOutlined />
      }
      unCheckedChildren={
        <CloseOutlined />
      }
      defaultChecked={defaultValue}
      {...(value
        ? {
            checked: value,
          }
        : {})}
    />
  </Wrapper>
)

export const CustomToggleField = ({
  title,
  className,
  description,
  Icon = IoLockClosed,
  ...props
}) => {
  const t = useTranslate()
  const Wrapper = useMemo(() => {
    return ({ children }) => (
      <div className="flex flex-col">
        <div className="flex items-start justify-start w-full">
          <div className="flex flex-1 font-bold text-lg items-center justify-center w-full">
            <Icon
              style={{
                color: '#15759e',
                fontSize: '1em',
              }}
            />
            <span className="px-1 flex-1 text-primary">
              {t(title)}
            </span>
          </div>
          {children}
        </div>
        <div className="flex items-start justify-start w-full text-sm text-color-400 font-light italic mt-3">
          {t(description)}
        </div>
      </div>
    )
  }, [t, title, description])
  return (
    <div className="p-3 border border-dashed border-primary bg-primary-50 rounded-lg">
      <ToggleField
        {...props}
        Wrapper={Wrapper}
      />
    </div>
  )
}
