import React from 'react'
import {WishareEntities} from '../enums'
import createEntityListByCategory from '../factory/createEntityListByCategory'
import CategoryContext from './CategoryContext'
import CategoryNews from './holders/CategoryNews'
import CategoryGeneral from "./holders/CategoryGeneral";

export const categoryRouteEntities = [
  {
    path: 'general',
    component: CategoryGeneral,
    breadcrumbName: (t) => t('general'),
  },
  {
    path: 'news',
    component: CategoryNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'organizations',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.ORGANIZATION
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) =>
      t('organizations'),
  },
  {
    path: 'activities',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.ACTIVITY
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) =>
      t('activities'),
  },
  {
    path: 'donations',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.DONATION
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) =>
      t('crowdfunding'),
  },
  {
    path: 'givings',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.GIVING
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) => t('givings'),
  },
  {
    path: 'jobs',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.RECRUITMENT
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) => t('jobs'),
  },
  {
    path: 'events',
    component: () => (
      <CategoryContext.Consumer>
        {({category}) =>
          createEntityListByCategory(
            category,
            WishareEntities.EVENT
          )
        }
      </CategoryContext.Consumer>
    ),
    breadcrumbName: (t) => t('events'),
  },
]
