import _ from 'lodash'
import {permissionCodes} from 'modules/permissions/contants'
import React from 'react'
import {
  IoDiamondOutline,
  IoFileTrayFullOutline,
  IoGiftOutline,
  IoGridOutline,
  IoHelpCircleOutline,
  IoPersonOutline,
  IoSaveOutline,
  IoSettingsOutline,
  IoStatsChartOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {RiSlideshowLine} from 'react-icons/ri'
import {withRouter} from 'react-router-dom'
import {compose, withProps,} from 'recompose'
import {notFoundOnError, withAccessPermissions,} from 'views/Wishare/composers'
import {CustomKeys} from 'views/Wishare/CustomKeys'
import {WishareEntities} from 'views/Wishare/enums'
import {ContextActionTypes} from 'views/Wishare/factory/createContextMenu'
import createDeleteEntity from 'views/Wishare/factory/createDeleteEntity'
import {CoHosts} from 'views/Wishare/factory/createHostSetting'
import {createSettingModule} from 'views/Wishare/factory/createModule'
import DonationContext from '../DonationContext'
import DonationSettingBeneficiary from './DonationSettingBeneficiary'
import DonationSettingCategories from './DonationSettingCategories'
import DonationSettingGiving from './DonationSettingGiving'
import DonationSettingImpCate from './DonationSettingImpCate'
import DonationSettingOverview from './DonationSettingOverview'
import DonationSettingOwner from './DonationSettingOwner'
import DonationSettingQuestions from './DonationSettingQuestions'
import DonationSettingTarget from './DonationSettingTarget'
import {TbFileImport} from "react-icons/tb";

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline/>,
  },
  {
    key: 'owner',
    label: 'owner',
    icon: <IoDiamondOutline/>,
  },
  {
    key: 'beneficiary',
    label: 'beneficiary information',
    icon: <IoFileTrayFullOutline/>,
  },
  {
    key: 'category',
    label: 'categories',
    icon: <IoGridOutline/>,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
      {
        label: 'impact categories',
        key: 'impact-categories',
      },
    ],
  },
  {
    key: 'giving',
    label: 'givings',
    icon: <IoGiftOutline/>,
  },
  {
    key: 'target',
    label: 'target',
    icon: <IoStatsChartOutline/>,
  },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <IoPersonOutline/>,
    children: [
      {
        label:
        CustomKeys.co_hosts.label,
        key: CustomKeys.co_hosts.name,
      },
    ],
  },
  {
    key: 'question',
    label: 'information collecting',
    icon: <IoHelpCircleOutline/>,
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline/>,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: DonationSettingOverview,
  },
  {
    path: 'beneficiary',
    label: 'beneficiary information',
    component:
    DonationSettingBeneficiary,
  },
  {
    path: 'categories',
    label: 'categories',
    component:
    DonationSettingCategories,
  },
  {
    path: 'impact-categories',
    label: 'impact categories',
    component: DonationSettingImpCate,
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: () => (
      <DonationContext.Consumer>
        {({donation}) => (
          <CoHosts item={donation}/>
        )}
      </DonationContext.Consumer>
    ),
  },
  {
    path: 'owner',
    label: 'owner',
    component: DonationSettingOwner,
  },
  {
    path: 'giving',
    label: 'giving',
    component: DonationSettingGiving,
  },
  {
    path: 'target',
    label: 'target',
    component: DonationSettingTarget,
  },
  {
    path: 'question',
    label: 'information collecting',
    component: DonationSettingQuestions,
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <DonationContext.Consumer>
        {({donation}) =>
          createDeleteEntity(donation)
        }
      </DonationContext.Consumer>
    ),
  },
].map(
  ({
     path,
     component: Component,
     ...rest
   }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <Component {...props} />
    ),
  })
)

export const donationSettings = {
  actions: [
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline/>,
    },
    {
      key: ContextActionTypes.IMPORT,
      label: 'import donation data',
      icon: <TbFileImport/>,
    },
    {
      key: ContextActionTypes.EXPORT,
      label: 'export donation data',
      icon: <IoSaveOutline/>,
    },
    {
      key: ContextActionTypes.SLIDE_SHOW,
      label: 'slide show (live)',
      icon: <RiSlideshowLine/>,
    },
    // {
    //   key: ContextActionTypes.SLIDE_SHOW2,
    //   label: 'slide show (live) (2)',
    //   icon: <RiSlideshowLine />,
    // },
  ],
  menuItems,
  routeEntities,
}

const DonationSettings = ({
                            id,
                            ...props
                          }) =>
  createSettingModule(
    [WishareEntities.DONATION],
    {
      item: id,
      ...props,
    }
  )

export default compose(
  notFoundOnError(
    DonationContext.Consumer
  ),
  withAccessPermissions([
    permissionCodes.canEdit,
  ]),
  withRouter,
  withProps(({match}) => ({
    id: _.get(match, 'params.id'),
  }))
)(DonationSettings)
