import {Avatar, Image} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { getTitle } from '../../../../apis/model/base'
import { LayoutContext } from '../../../../components/layouts/Default/LayoutContext'
import getLinkToDetail from '../../../../helpers/getLinkToDetail'
import GivingContext from '../GivingContext'
import {ROOT_URL} from "../../../../envs/_current/config";
import EndCampaign from "../../custom/EndCampaign";
import moment from "moment";

const GivingBanner = React.memo(
  ({ Wrapper = 'div', ...props }) => {
      const t = useTranslate()
      const { giving } = useContext(
          GivingContext
      )
      const { isSm } = useContext(
          LayoutContext
      )
      const owner_name = _.get(giving, 'owner.title')
      const time_close = moment(_.get(giving, 'time_close'));
      const now = moment();

    return (
      <Wrapper className="flex flex-col">
        <Image
          preview={false}
          src={`${ROOT_URL}/images/default/pink-bg.jpg`}
          className="fadeIn animated banner-activity object-cover bg-secondary"
        />
        <div className="absolute flex items-center w-full mx-auto" style={{background: 'radial-gradient(rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0))'}}>
          <div className="flex flex-col items-center justify-center w-full banner-activity relative">
              <span className="uppercase text-sm text-pink-600 font-medium tracking-wide">
                {t('giving campaign')}
              </span>
              <span className="px-3 text-xl md:text-3xl text-pink-800 font-bold max-w-4xl text-center my-0 md:my-3">
                {getTitle(giving)}
              </span>
              <div className="flex flex-col md:flex-row items-center justify-center space-x-3 text-secondary-50">
                <span className="font-medium italic text-white opacity-75 text-xs">
                  {t(_.get(giving, 'owner._type'))} {':'}
                </span>
                  <div className="flex items-center gap-2">
                    {!isSm && (
                      <Avatar
                        size="small"
                        className="flex flex-center background-100"
                        src={_.get(
                          giving,
                          'owner.avatar'
                        )}>
                          <span className="text-color-300 font-medium uppercase">
                              {_.first(owner_name)}
                          </span>
                      </Avatar>
                    )}
                    <Link
                      to={getLinkToDetail(
                        _.get(giving, 'owner')
                      )}
                      style={{color: '#fff'}}
                      className="flex-1 text-center text-sm md:text-lg opacity-75 hover:opacity-100 font-bold max-lines-2 no-underline">
                      {owner_name}
                    </Link>
                  </div>
                </div>
          </div>
          {
            ((now > time_close) || _.get(giving, 'status') === 9) ? <EndCampaign /> : <span/>
          }
        </div>
      </Wrapper>
    )
  }
)

export default GivingBanner
