import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import '@changey/react-leaflet-markercluster/dist/styles.min.css'
import { createControlComponent } from '@react-leaflet/core'
import { Image, List } from 'antd'
import classNames from 'classnames'
import DidOnMount from 'components/DidOnMount'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { GOOGLE_API_KEY } from 'envs/_current/config'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import L from 'leaflet'
import {
  GeoSearchControl,
  OpenStreetMapProvider,
} from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'
import 'leaflet.fullscreen'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  Component,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BiCurrentLocation } from 'react-icons/bi'
import {
  HiLocationMarker,
  HiOutlineLocationMarker,
} from 'react-icons/hi'
import { IoCheckmarkOutline } from 'react-icons/io5'
import {
  CircleMarker,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet'
import 'react-leaflet-fullscreen/dist/styles.css'
import { DelayRender } from 'views/Discovery/DelayRender'
import {
  Null,
  renderElse,
  renderIf,
} from 'views/Shared'
import ToggleActionButton from 'views/Wishare/custom/ToggleActionButton'

const FullscreenControl =
  createControlComponent((props) =>
    L.control.fullscreen(props)
  )

delete L.Icon.Default.prototype
  ._getIconUrl
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
})

const PositionClasses = Object.freeze({
  BottomLeft:
    'leaflet-bottom leaflet-left',
  BottomRight:
    'leaflet-bottom leaflet-right',
  TopLeft: 'leaflet-top leaflet-left',
  TopRight: 'leaflet-top leaflet-right',
})

export const MapModes = Object.freeze({
  Marker: 'Marker',
  CircleMarker: 'CircleMarker',
})

const BaseLayers = Object.freeze({
  Default: {
    name: 'Default',
    isDefault: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  Alidade: {
    name: 'Alidade',
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="https://www.stadiamaps.com">Stadia Maps</a> contributors',
  },
  AlidadeDark: {
    name: 'Alidade(dark)',
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="https://www.stadiamaps.com">Stadia Maps</a> contributors',
  },
})

const defaultActions = {
  locator: true,
  selector: true,
}

const LLCopyright = ({
  maxZoom,
  ...props
}) => {
  const params = {
    maxZoom,
    ...props,
  }
  return (
    <React.Fragment>
      {[
        BaseLayers.Default,
        BaseLayers.Alidade,
        BaseLayers.AlidadeDark,
      ].map(
        (
          {
            name,
            isDefault = false,
            ...props
          },
          index
        ) => (
          <LayersControl.BaseLayer
            key={index}
            name={name}
            checked={isDefault}>
            <TileLayer
              {...params}
              {...props}
            />
          </LayersControl.BaseLayer>
        )
      )}
      {/* <LayersControl.BaseLayer name="???">
        <WMSTileLayer
          maxZoom={maxZoom}
          url="???"
          params={{
            layers: '???',
          }}
        />
      </LayersControl.BaseLayer> */}
    </React.Fragment>
  )
}

const WithMap = ({
  children,
  handlerMaker = (map) => ({}),
}) => {
  const map = useMap()
  const eventHandlers =
    handlerMaker(map) || {}
  return map
    ? children({
        map,
        eventHandlers,
      })
    : null
}

export const getStaticMap = ({
  latitude,
  longitude,
  zoom = 16,
  width = 600,
  height = 300,
  rootURL = 'https://maps.googleapis.com/maps/api/staticmap',
}) => {
  const getImageURL = () => {
    let searchURL = new URLSearchParams(
      {
        size: '200x200',
        key: GOOGLE_API_KEY,
        markers: `color:red|${latitude},${longitude}`,
      }
    )

    if (zoom) {
      searchURL.set('zoom', zoom)
    }

    if (
      _.every(
        [width, height],
        (e) =>
          Number(_.defaultTo(e, 0)) > 0
      )
    ) {
      searchURL.set(
        'size',
        `${width}x${height}`
      )
    }

    return [
      rootURL,
      searchURL.toString(),
    ].join('?')
  }

  if (
    _.some(
      [rootURL, latitude, longitude],
      (e) => !e
    )
  ) {
    return {}
  }

  const imageURL = getImageURL()

  return {
    zoom,
    width,
    height,
    rootURL,
    latitude,
    imageURL,
    longitude,
  }
}

class CustomMarkerClusterGroup extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <MarkerClusterGroup
        {...this.props}
      />
    )
  }
}

const SearchComponent = ({
  mode,
  onGeoSearch,
  selectedItem,
}) => {
  const t = useTranslate()

  const provider =
    new OpenStreetMapProvider()

  const searchControl =
    new GeoSearchControl({
      provider,
      style: mode,
      showMarker: true,
      showPopup: false,
      keepResult: true,
      classNames: {
        item: 'gsc-item',
        form: 'gsc-form',
        input: 'gsc-input',
        notfound: 'gsc-notfound mb-2',
        resultlist: 'gsc-result-list',
        resetButton: 'gsc-reset reset',
      },
      searchLabel: t('Enter address'),
      popupFormat: ({
        query,
        result,
      }) => result.label,
      resultFormat: ({ result }) =>
        result.label,
      notFoundMessage: t(
        'Address could not be found.'
      ),
    })

  const map = useMap()
  if (_.isFunction(onGeoSearch)) {
    map.on(
      'geosearch/showlocation',
      (data) => {
        onGeoSearch(data)
      }
    )
  }
  useEffect(() => {
    map.addControl(searchControl)
    return () =>
      map.removeControl(searchControl)
  }, [])

  if (_.isEmpty(selectedItem)) {
    return null
  } else {
    const {
      icon,
      latLng = [,],
      display_name,
    } = selectedItem || {}

    return (
      <LayerGroup>
        <div
          className={classNames(
            PositionClasses.BottomRight
          )}>
          <div className="leaflet-control">
            <div
              style={{
                width: 300,
                marginBottom: 16,
              }}
              className="p-2 bg-white border rounded-lg">
              <List.Item
                className="items-start"
                onClick={() => {
                  if (
                    selectedItem?.latLng
                  ) {
                    map.flyTo(
                      selectedItem.latLng,
                      map.getMaxZoom()
                    )
                  }
                }}
                actions={[
                  <IoCheckmarkOutline
                    size={24}
                    className="text-green-600"
                  />,
                ]}>
                {selectedItem?.anonymous ? (
                  <div>
                    <div>
                      {`${t(
                        'latitude'
                      )}: `}
                      <span className="font-semibold">
                        {latLng[0]}
                      </span>
                    </div>
                    <div>
                      {`${t(
                        'longitude'
                      )}: `}
                      <span className="font-semibold">
                        {latLng[1]}
                      </span>
                    </div>
                  </div>
                ) : (
                  <List.Item.Meta
                    className="gap-1"
                    avatar={
                      <Image
                        preview={false}
                        src={
                          icon ||
                          getAvatar(
                            selectedItem
                          )
                        }
                      />
                    }
                    title={
                      <span className="max-lines-2 cursor-pointer">
                        {display_name ||
                          getTitle(
                            selectedItem
                          )}
                      </span>
                    }
                  />
                )}
              </List.Item>
            </div>
          </div>
        </div>
      </LayerGroup>
    )
  }
}

const ActionGroup = ({
  containerId,
  onClick = Null,
  onClear = Null,
  onLocationFound = Null,
  actions = defaultActions,
}) => {
  const map = useMap()

  const t = useTranslate()

  const [isLoading, setLoading] =
    useState()

  const group = L.layerGroup()
  useEffect(() => {
    map.addLayer(group)
    return () => {
      map.removeLayer(group)
    }
  }, [])

  const createHint = useCallback(
    (latlng) =>
      '<div>' +
      [
        {
          label: 'latitude',
          value: latlng.lat,
        },
        {
          label: 'longitude',
          value: latlng.lng,
        },
      ]
        .map(
          ({ label, value }) =>
            `<div>${t(
              label
            )}: <b>${value}</b></div>`
        )
        .join('\n') +
      '</div>',
    []
  )

  map.on('locationfound', (e) => {
    setLoading(false)
    const marker = L.marker(
      e.latlng
    ).bindPopup(createHint(e.latlng))
    group.clearLayers()
    group.addLayer(marker)
    marker.on('click', onClick)
    onLocationFound(e)
    map.flyToBounds(e.bounds, {
      duration: 0.5,
    })
  })

  const [isToggle, toggle] =
    useState(false)

  const [mark, setMark] = useState()

  const handlClick = (e) => {
    if (mark) {
      map.removeLayer(mark)
    }
    if (isToggle) {
      setMark(
        L.marker(e.latlng)
          .bindPopup(
            createHint(e.latlng)
          )
          .addTo(map)
          .on('click', onClick)
      )
      onClick(e)
    }
  }

  useEffect(() => {
    map.on('click', handlClick)
    if (isToggle) {
      document.getElementById(
        containerId
      ).style.cursor = 'crosshair'
    } else {
      document.getElementById(
        containerId
      ).style.cursor = ''
      onClear()
      if (mark) {
        map.removeLayer(mark)
      }
    }
    return () => {
      map.off('click', handlClick)
    }
  }, [isToggle, mark])

  const locator_ref = useRef(null)

  const selector_ref = useRef(null)

  useEffect(() => {
    if (locator_ref.current) {
      L.DomEvent.disableClickPropagation(
        locator_ref.current
      )
    }
    if (selector_ref.current) {
      L.DomEvent.disableClickPropagation(
        selector_ref.current
      )
    }
  })

  const size = 36

  return (
    <div
      className={classNames(
        PositionClasses.TopRight
      )}>
      {renderIf(
        actions.locator,
        <div
          ref={locator_ref}
          onClick={() => {
            toggle(false)
            onClick(null)
            map.stop()
            map.stopLocate()
            map.locate()
            setLoading(true)
          }}
          className="flex leaflet-control leaflet-bar">
          <BiCurrentLocation
            size={size}
            className={classNames(
              'p-2 border cursor-pointer',
              isLoading
                ? 'bg-gray-300'
                : 'bg-white'
            )}
          />
        </div>
      )}
      {renderIf(
        actions.selector,
        <div className="flex leaflet-control leaflet-bar">
          <ToggleActionButton
            ref={selector_ref}
            type="default"
            style={{
              width: size,
              height: size,
            }}
            className=""
            isToggle={isToggle}
            onClick={() => {
              const next = !isToggle
              toggle(next)
            }}
            active={{
              icon: (
                <HiLocationMarker
                  size={24}
                  className="text-blue-600"
                />
              ),
            }}
            inactive={{
              icon: (
                <HiOutlineLocationMarker
                  size={24}
                  className="text-blue-600"
                />
              ),
            }}
          />
        </div>
      )}
    </div>
  )
}

const AutoPlay = ({
  bounds,
  delay = 0,
}) => {
  const map = useMap()
  return renderIf(
    map,
    <DelayRender
      lazy={true}
      time={delay}>
      <DidOnMount
        handler={() => {
          map.flyToBounds(bounds, {
            duration: 0.5,
          })
        }}
      />
    </DelayRender>
  )
}

const defaultFields = {
  id: 'id',
  name: 'name',
  children: 'children',
  latitude: 'latitude',
  longitude: 'longitude',
}

const vietnamLatLng = [
  14.0583, 108.2772,
]

const GMapWidget = ({
  data,
  style,
  settings,
  className,
  id = 'g_map',
  height = 500,
  maxZoom = 16,
  onReady = Null,
  defaultZoom = 4,
  Wrapper = 'div',
  groupBy = false,
  fullscreen = true,
  spiderMode = true,
  showSearch = true,
  autoPlayDelay = 0,
  iconCreateFunction,
  onZoomChanged = Null,
  onLocationFound = Null,
  center = vietnamLatLng,
  mode = MapModes.Marker,
  onSelectLocation = Null,
  renderMarker = getTitle,
  actions = defaultActions,
  fieldNames = defaultFields,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const [ready, setReady] =
    useState(false)

  const [current, setCurrent] =
    useState()

  const fields = useMemo(
    () =>
      _.merge(
        defaultFields,
        fieldNames
      ),
    [fieldNames]
  )

  const createMarkerHandlers = (
    map
  ) => ({
    click: (event) => {
      map.stop()
      const { lat, lng } =
        event.latlng || {}
      const { data } =
        event?.target?.options || {}
      const value = {
        data,
        latLng: [lat, lng],
        display_name: _.get(
          data,
          fields.name
        ),
        anonymous: !!data?.anonymous,
      }
      setCurrent(value)
      onSelectLocation(value)
      map.flyTo(
        [lat, lng],
        map.getMaxZoom(),
        {
          duration: 0.5,
        }
      )
    },
  })

  const createMarkers = useCallback(
    (data, args) =>
      Array.from(data || []).map(
        (item, index) => {
          const params = args || {}
          const latLng = [
            _.get(
              item,
              fields.latitude
            ),
            _.get(
              item,
              fields.longitude
            ),
          ]

          if (
            _.some(latLng, (e) => !e)
          ) {
            return null
          }

          const Hint = isSm
            ? Popup
            : Tooltip

          switch (mode) {
            case MapModes.Marker:
              return (
                <Marker
                  key={index}
                  data={item}
                  position={latLng}
                  alt={getTitle(item)}
                  {...params}>
                  <Hint>
                    {renderMarker(item)}
                  </Hint>
                </Marker>
              )
            case MapModes.CircleMarker:
              return (
                <CircleMarker
                  key={index}
                  radius={20}
                  data={item}
                  fill={true}
                  color="blue"
                  stroke={false}
                  center={latLng}
                  alt={getTitle(item)}
                  {...params}>
                  <Hint>
                    {renderMarker(item)}
                  </Hint>
                </CircleMarker>
              )
            default:
              return null
          }
        }
      ),
    [isSm, fields, renderMarker]
  )

  const createClusters = (data) =>
    renderElse(
      _.isEmpty(data),
      <CustomMarkerClusterGroup
        {...(spiderMode
          ? {}
          : {
              spiderfyOnMaxZoom: false,
              disableClusteringAtZoom:
                maxZoom,
            })}
        chunkedLoading={true}
        showCoverageOnHover={false}
        {...(_.isFunction(
          iconCreateFunction
        )
          ? {
              iconCreateFunction,
            }
          : {})}>
        <WithMap
          handlerMaker={
            createMarkerHandlers
          }>
          {({ eventHandlers }) =>
            createMarkers(data, {
              eventHandlers,
            })
          }
        </WithMap>
      </CustomMarkerClusterGroup>
    )

  const renderLayers = useCallback(
    (params) => {
      const { mapFn = Null } =
        params || {}
      const markers = Array.from(
        data || []
      )
      if (groupBy) {
        return (
          <React.Fragment>
            <LayersControl position="bottomleft">
              <LLCopyright
                maxZoom={maxZoom}
              />
              {markers.map(
                (root, index) => (
                  <LayersControl.Overlay
                    key={index}
                    checked={true}
                    name={_.get(
                      root,
                      fields.name
                    )}>
                    <LayerGroup>
                      {createClusters(
                        Array.from(
                          _.get(
                            root,
                            fields.children
                          ) || []
                        )
                      )}
                    </LayerGroup>
                  </LayersControl.Overlay>
                )
              )}
            </LayersControl>
            {renderElse(
              _.isEmpty(markers),
              <AutoPlay
                delay={autoPlayDelay}
                bounds={_.flatMap(
                  markers,
                  (item) =>
                    _.get(
                      item,
                      fields.children,
                      []
                    )
                ).map(mapFn)}
              />
            )}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <LayersControl position="bottomleft">
              <LLCopyright
                maxZoom={maxZoom}
              />
              {createClusters(markers)}
            </LayersControl>
            {renderElse(
              _.isEmpty(markers),
              <AutoPlay
                delay={autoPlayDelay}
                bounds={markers.map(
                  mapFn
                )}
              />
            )}
          </React.Fragment>
        )
      }
    },
    [data, fields, groupBy]
  )

  return (
    <React.Fragment>
      <Wrapper className="w-full h-full">
        <MapContainer
          id={id}
          center={center}
          dragging={true}
          maxZoom={maxZoom}
          zoom={defaultZoom}
          zoomControl={true}
          whenReady={() => {
            setReady(true)
            onReady()
          }}
          className={className}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          attributionControl={true}
          style={_.omitBy(
            {
              height,
              ...(style || {}),
            },
            _.isUndefined
          )}
          {...(settings || {})}>
          {renderIf(
            fullscreen,
            <FullscreenControl />
          )}
          {renderIf(
            ready,
            <React.Fragment>
              <WithMap>
                {({ map }) => (
                  <DidOnMount
                    handler={() => {
                      onZoomChanged(
                        map?.getZoom() ??
                          defaultZoom
                      )
                    }}
                  />
                )}
              </WithMap>
              {showSearch && (
                <SearchComponent
                  mode="bar"
                  selectedItem={current}
                  onGeoSearch={(
                    data
                  ) => {
                    const item =
                      data?.location
                        ?.raw
                    const value = item
                      ? {
                          ...item,
                          latLng: [
                            item.lat,
                            item.lon,
                          ],
                        }
                      : undefined
                    setCurrent(value)
                    onSelectLocation(
                      value
                    )
                  }}
                />
              )}
              {renderLayers({
                mapFn: (item) => [
                  _.get(
                    item,
                    fields.latitude
                  ),
                  _.get(
                    item,
                    fields.longitude
                  ),
                ],
              })}
              {renderElse(
                _.isEmpty(actions),
                <ActionGroup
                  actions={actions}
                  containerId={id}
                  onLocationFound={
                    onLocationFound
                  }
                  onClear={() => {
                    setCurrent(
                      undefined
                    )
                  }}
                  onClick={(event) => {
                    if (event?.latlng) {
                      const {
                        lat,
                        lng,
                      } = event.latlng
                      const value = {
                        latLng: [
                          lat,
                          lng,
                        ],
                        anonymous: true,
                      }
                      setCurrent(value)
                      onSelectLocation(
                        value
                      )
                    }
                  }}
                />
              )}
            </React.Fragment>
          )}
        </MapContainer>
      </Wrapper>
    </React.Fragment>
  )
}

export default GMapWidget
