import {
  beneficiary_delete_api,
  beneficiary_getById_api,
} from 'apis'
import { getId } from 'apis/model/base'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import LoadingPage from 'views/LoadingPage'
import { BeneficiaryModalTypes } from 'views/MainPage/WorkSpace/components/BeneficiaryActionModal'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import BeneficiaryGivingsModalContent from './components/BeneficiaryGivingsModalContent'

const GetBeneficiaryById = ({
  id,
  children,
}) => (
  <Async
    {...{
      apiInfo: beneficiary_getById_api,
      query: bindQueryParam({
        id,
      }),
    }}>
    {({ response, isLoading }) =>
      _.isFunction(children)
        ? children({
            isLoading,
            beneficiary:
              getResponseItem(response),
          })
        : children
    }
  </Async>
)

const BeneficiaryContextMenu = ({
  beneficiary,
  Wrapper = 'div',
  setAction = Null,
  createContextOptions = (
    beneficiary
  ) => [],
  ...props
}) => {
  const t = useTranslate()

  const {
    handleAsyncAction:
      deleteBeneficiary,
  } = useAsyncAction({
    apiInfo: beneficiary_delete_api,
    onError: notifyOnError(t),
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
  })

  const onMenuSelect = (
    key,
    beneficiary
  ) => {
    const beneficiary_id = getId(
      beneficiary
    )
    switch (key) {
      case BeneficiaryModalTypes.GIVING_LIST:
        setAction({
          type: key,
          value: beneficiary_id,
        })
        break
      case BeneficiaryModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteBeneficiary(
                {},
                bindQueryParam({
                  id: beneficiary_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  return (
    <Wrapper className="absolute right-0 top-0 px-2 py-1">
      <ItemContextMenu
        onMenuSelect={(key) =>
          onMenuSelect(key, beneficiary)
        }
        items={createContextOptions(
          beneficiary
        )}
      />
    </Wrapper>
  )
}

const useBeneficiaryContextMenu = ({
  createContextOptions = (
    beneficiary
  ) => [],
  renderModalHeader = (action, item) =>
    null,
}) => {
  const t = useTranslate()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const onCancel = () =>
    setAction({ type: undefined })

  const createContextModal = (type) => {
    switch (type) {
      case BeneficiaryModalTypes.GIVING_LIST:
        return (
          <GetBeneficiaryById
            id={action.value}>
            {({
              beneficiary,
              isLoading = false,
            }) => (
              <FullSideModalLayout
                right={true}
                onCancel={onCancel}
                title={t(
                  'view detail'
                )}>
                {!!isLoading ? (
                  <LoadingPage />
                ) : (
                  <BeneficiaryGivingsModalContent
                    beneficiary_id={
                      action.value
                    }
                    Header={() =>
                      renderModalHeader(
                        action,
                        beneficiary
                      )
                    }
                  />
                )}
              </FullSideModalLayout>
            )}
          </GetBeneficiaryById>
        )
      default:
        break
    }
  }

  return {
    action,
    setAction,
    createContextModal,
    renderContextMenu: (
      beneficiary
    ) => (
      <BeneficiaryContextMenu
        beneficiary={beneficiary}
        setAction={setAction}
        createContextOptions={
          createContextOptions
        }
      />
    ),
  }
}

export default useBeneficiaryContextMenu
