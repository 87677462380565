import Null from 'components/NullComponent'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAsynCacheSelector} from './selectors'

function useDispatchAsyncAction({
  initalAction,
  onSuccess = Null,
  onError = Null
                                } = {}) {
  const [action, setAction] = useState(
    initalAction
  )
  const data = useSelector(
    state =>
      action
        ? getAsynCacheSelector(
          state,
          action.asyncId
        )
        : {
          idle: true
        },
    (left, right) =>
      left.updated === right.updated
  )
  const dispatch = useDispatch()
  const handleDispatchAsyncAction = useCallback(
    action => {
      setAction(action)
      dispatch(action)
      return action
    },
    [dispatch]
  )
  useEffect(() => {
    if (action && !data.success) {
      dispatch(action)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (data.error) {
      onError(
        data.error,
        data.errorMessages
      )
    }
    if (data.success) {
      onSuccess(
        data.result,
        data,
        handleDispatchAsyncAction
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error, data.success])
  return [
    data,
    handleDispatchAsyncAction
  ]
}
export default useDispatchAsyncAction
