import _ from 'lodash'
import React from 'react'
import { renderOwnChild } from 'views/Shared/typedefs'
import { renderHashTag } from './renderHashTag'

const TagList = ({
  items = [],
  Wrapper = renderOwnChild,
}) => {
  if (_.isEmpty(items)) return null
  return (
    <Wrapper>
      {items.map(renderHashTag)}
    </Wrapper>
  )
}

export default TagList
