import {Avatar, Tag, Tooltip} from 'antd'
import classNames from 'classnames'
import {AppPlaceHolderSvg} from 'components/Svgs/AppPlaceHolderSvg'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoCheckmarkCircleOutline, IoCloseCircleOutline, IoRefreshCircle, IoTimeOutline,} from 'react-icons/io5'
import CustomLink from 'views/Wishare/custom/CustomLink'
import {getFullAddress, renderIf, renderMail, renderPhone, renderSelf} from "../../../Shared";
import Translate from "../../../../modules/local/Translate";
import {BsFolder} from 'react-icons/bs'

export const fromHostStatus = (
  value
) => {
  switch (value) {
    case -1:
      return {
        label: 'pending',
        className:
          'border border-blue-500 text-blue-500',
        icon: (
          <IoTimeOutline size={12} />
        ),
      }
    case 1:
      return {
        label: undefined,
        className:
          'border border-green-500 text-green-500',
        icon: (
          <IoCheckmarkCircleOutline
            size={12}
          />
        ),
      }
    case -8:
      return {
        label: 'has left',
        className:
          'bg-orange-600 text-white',
        icon: (
          <IoRefreshCircle size={12} />
        ),
      }
    case -9:
      return {
        label: 'rejected',
        className:
          'border border-red-500 text-red-500',
        icon: (
          <IoCloseCircleOutline
            size={12}
          />
        ),
      }
    default:
      return {}
  }
}

const ItemInfo = ({ label, value }) =>
    renderIf(
        Boolean(value),
        <Translate>
          {(t) => (
              <div className="flex items-start gap-1 text-xs leading-tight">
                <span className="font-light italic text-color-400">
                  {[t(label), ':'].join('')}
                </span>
                <span className="font-medium text-color-100 flex-1">
                  {value}
                </span>
              </div>
          )}
        </Translate>
    )

const HostContent = ({
  item,
  status,
  showInfo = true,
}) => {
  const t = useTranslate()
  const { label, className, icon } =
    fromHostStatus(status)
  const verified = _.get(
    item,
    'certified'
  )
  const categories = _.get(
      item,
      'categories',
      []
  )
  const subcategories = _.get(
    item,
    'subcategories',
    []
  )

  const hasInfo = _.get(item, 'contacts', []).length > 0

  const contact = _.get(item, 'contacts.0')

  return (
    <div className="flex flex-col gap-1 p-2">
      <div className="flex items-center gap-2">
        <Avatar
          size={40}
          icon={<AppPlaceHolderSvg />}
          className="background-100 flex flex-center border border-color-50 rounded-md"
          src={getAvatar(item)}
        />
        <div className="flex flex-col gap-1">
          <div className="flex flex-1">
            <CustomLink
                withSubDomain={false}
                to={getLinkToDetail(item)}
                className="font-semibold text-color-000 max-lines-1 no-underline leading-tight">
              {getTitle(item)}
            </CustomLink>
            {verified === 1 && (
                <Tooltip
                    title={t('verified')}
                    placement="right"
                    color="#87d068">
                  <VerifiedSvg
                      width="1rem"
                      height="1rem"
                      className="ml-1 mb-1 lg:mb-2 zoom-animation"
                  />
                </Tooltip>
            )}
          </div>
          {_.get(item, 'total_activities') > 0 && (
              <span className="font-light text-2xs text-color-400 italic leading-tight">
                <span className="font-bold text-xs text-color-100 mr-1">
                  {_.get(item, 'total_activities')}
                </span>
                {t('activities')}
              </span>
          )}
        </div>
      </div>
      {categories.length > 0 && (
          <div className="flex flex-wrap gap-1">
            {categories.slice(0,2).map((e, i) => (
                <Tag
                    key={i}
                    color="processing"
                    icon={<Avatar size={13} src={e.avatar}/>}
                    style={{paddingTop: '0.2rem', paddingBottom: '0.2rem'}}
                    className="flex items-center gap-1 text-xs italic mr-0 leading-tight">
                  {e.name}
                </Tag>
            ))}
          </div>
      )}
      {subcategories.length > 0 && (
          <div className="flex flex-col gap-1">
            {/*<span className="text-xs text-color-400 italic leading-tight">*/}
            {/*  {t('subcategories')}{':'}*/}
            {/*</span>*/}
            <div className="flex flex-wrap gap-1">
              {subcategories.slice(0,2).map((e, i) => (
                  <Tag
                      key={i}
                      color="magenta"
                      icon={<BsFolder/>}
                      className="custom-tag-subcat flex flex-center gap-1 text-2xs italic mr-0 leading-tight">
                    {e.title}
                  </Tag>
              ))}
            </div>
          </div>
      )}
      {showInfo && hasInfo && (
          <div className="mt-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-xs text-color-400 italic uppercase underline">
                {t('contact information')}{' : '}
              </span>
              <div className="flex flex-col gap-1">
                {[
                  {
                    name: 'prefix_name',
                    label: 'name',
                    is_visible: !!_.get(contact, 'name'),
                    transform: (value) => {
                      const array = [!!value ? t(value) : null, _.get(contact, 'name')]
                      return _.every(array, _.isEmpty) ? null : array.join(' ')
                    },
                  },
                  {
                    name: 'phone',
                    label: 'phone',
                    is_visible: !!_.get(contact, 'phone'),
                    transform: renderPhone,
                  },
                  {
                    name: 'email',
                    label: 'email',
                    is_visible: !!_.get(contact, 'email'),
                    transform: renderMail,
                  },
                  {
                    name: 'location',
                    label: 'address',
                    is_visible: !!_.get(contact, 'location'),
                    transform: getFullAddress,
                  },
                ].filter((e) => !!e.is_visible).map(
                    ({name, label, transform = renderSelf,}, index) => (
                        <ItemInfo
                            key={index}
                            label={label}
                            value={transform(_.get(contact, name))}
                        />
                    )
                )}
              </div>
            </div>
          </div>
      )}
      {!!label && (
        <React.Fragment>
          <div className="h-5" />
          <div
            className={classNames(
              'rounded-full p-1 italic space-x-1 flex items-center absolute truncate bottom-0 right-0 m-2',
              className
            )}>
            {icon}
            <span className="text-xs leading-tight">
              {t(label)}
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default HostContent
