import {Avatar} from 'antd'
import {getId} from 'apis/model/base'
import classNames from 'classnames'
import React from 'react'
import {Null} from 'views/Shared'
import {paths} from '../../MainPage/contains'
import _ from "lodash";
import categoryIcons from "../../../conts/categoryIcons";
import CustomLink from "../../Wishare/custom/CustomLink";

const renderCategoryLink = (
  item,
  index
) => {
  const {
    name,
    avatar,
    language,
    alt_idname,
  } = item
  const id = getId(item)
  const pathname = paths.categoryPath
    .replace(':category', id)
    .replace(':refcategory', alt_idname)

  const avatarProps = {
    size: 18,
    shape: 'circle',
  }

  const IconCategory = _.get(categoryIcons, item.idname)

  return (
    <CustomLink
      key={index}
      withSubDomain={false}
      to={{
        pathname,
        search: `?lang=${language}`,
        state: {refId: alt_idname},
      }}
      style={{padding: '5px 12px'}}
      className="no-underline border rounded-full truncate hover:border-primary hover:text-primary flex flex-center gap-2">
        <Avatar
            {...avatarProps}
            src={<IconCategory/>}
            alt={name}>
            {name.charAt(0)}
        </Avatar>
        <span className="font-medium text-xs italic leading-tight">
          {name}
        </span>
    </CustomLink>
  )
}

export const renderCategoryItem =
  (onClick = Null) =>
    (item, index) => {
      const {
        name,
        avatar,
        language,
        alt_idname,
      } = item
      const id = getId(item)
      const pathname = paths.categoryPath
        .replace(':category', id)
        .replace(
          ':refcategory',
          alt_idname
        )

      const avatarProps = {
        size: 24,
        shape: 'circle',
        className: 'bg-blue-500',
      }
      return (
        <CustomLink
          key={index}
          withSubDomain={false}
          to={{
            pathname,
            search: `?lang=${language}`,
            state: {refId: alt_idname},
          }}
          onClick={onClick}
          className={classNames(
            'hover:border-primary hover:bg-primary hover:text-white',
            'no-underline m-1 border rounded-full truncate border-gray'
          )}>
          <div className="flex items-center justify-center md:p-2 p-1">
            {avatar ? (
              <Avatar
                {...avatarProps}
                avatar={avatar}
                alt={name}
              />
            ) : (
              <Avatar
                {...avatarProps}
                style={{
                  color: 'white',
                }}>
                {name.charAt(0)}
              </Avatar>
            )}
            <span className="md:px-3 px-1">
            {name}
          </span>
          </div>
        </CustomLink>
      )
    }

export default renderCategoryLink
