import { Avatar, Button } from 'antd'
import ImageUpload from 'components/form/fields/ImageUpload'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { FaUser } from 'react-icons/fa'
import {IoCloudUploadOutline, IoPeople, IoPerson, IoPersonCircleOutline} from 'react-icons/io5'

const AvatarUploader = ({
  onChange,
  name = 'avatar',
  Wrapper = 'div',
    icon = <IoPersonCircleOutline size={45} className="text-color-500"/>,
}) => {
  const t = useTranslate()
  return (
    <Wrapper>
      <ImageUpload
        name={name}
        maxWidth={256}
        maxHeight={256}
        onChange={onChange}
        className="space-y-2"
        showUploadList={false}
        renderPreview={({ src }) => (
          <Avatar
            src={src}
            size={65}
            icon={icon}
            className="background flex flex-center border"
          />
        )}>
        <Button
            size={"small"}
            className="rounded-md background-200 flex items-center gap-2 no-shadow no-text-shadow">
          <IoCloudUploadOutline />
          <span className=" text-xs font-medium">{t('avatar')}</span>
        </Button>
      </ImageUpload>
    </Wrapper>
  )
}

export default AvatarUploader
