import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const TopicLazy = createLazyComponent(
  () => import('./Topic')
)

const Topic = (props) => (
  <TopicLazy {...props} />
)

export default Topic
