import React from "react";

export const Environment = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#89c8fd"
				d="M386.102 294.555c0-93.059-75.239-168.496-168.051-168.496S50 201.496 50 294.555c0 93.058 75.238 168.496 168.05 168.496s168.052-75.438 168.052-168.496zm0 0"
			></path>
			<path
				fill="#60b6ff"
				d="M218.05 126.059c-8.624 0-17.1.652-25.374 1.91 80.77 12.265 142.672 82.176 142.672 166.586 0 84.41-61.903 154.32-142.672 166.586a168.774 168.774 0 0025.375 1.91c92.812 0 168.05-75.438 168.05-168.496 0-93.059-75.238-168.496-168.05-168.496zm0 0"
			></path>
			<g fill="#baeb6c">
				<path d="M196.207 191.543c-25.887 16.223-45.305 42.176-30.742 71.379 14.562 29.2 46.922 17.844 76.855 13.789 29.934-4.055 7.282 88.414 25.078 111.125 17.797 22.71 67.954-22.711 63.102-51.914-7.188-43.235-9.707-58.402 48.54-89.223l.155-.058c-19.632-66.48-79.062-115.809-150.62-120.243l-.009.254s-6.472 48.668-32.359 64.891zm0 0"></path>
				<path d="M323.34 163.238c.012.008.023.02.035.028-.012-.008-.023-.016-.035-.028zm0 0M343.89 182.895c0 .003.005.007.008.007l-.007-.007zm0 0M106.41 370.8c-7.773-11.855 13.754-39.741 18.606-60.831 4.855-21.09-24.27-19.469-33.168-26.77-8.899-7.3-21.035-38.933-29.934-46.234l-1.422-1.121C53.711 254.125 50 273.906 50 294.554c0 55.696 26.953 105.071 68.492 135.747l.051-3.531s6.473-27.579-12.133-55.97zm0 0M330.5 335.922l-.105-.629a167.487 167.487 0 01-13.961 36.984c9.558-11.28 15.945-25.035 14.066-36.355zm0 0"></path>
			</g>
			<path
				fill="#96d629"
				d="M228.574 126.398l-.008.254s-.539 4.063-1.93 10.223c58.93 22.305 102.075 76.86 108.012 142.203 7.426-10.156 21.008-20.008 44.391-32.379l.156-.058c-19.632-66.48-79.062-115.809-150.62-120.243zm0 0"
			></path>
			<path
				fill="#09a66d"
				d="M217.824 512a219.86 219.86 0 01-35.87-2.957c-57.27-9.457-107.43-40.648-141.24-87.828C6.907 374.035-6.503 316.512 2.958 259.246c.684-4.148 4.613-6.953 8.75-6.27a7.608 7.608 0 016.27 8.75c-8.793 53.254 3.675 106.747 35.117 150.622s78.082 72.879 131.344 81.675c109.949 18.165 214.152-56.511 232.312-166.445 8.793-53.254-3.676-106.746-35.117-150.621-31.438-43.875-78.082-72.879-131.34-81.676a7.613 7.613 0 01-6.273-8.75c.687-4.148 4.613-6.953 8.75-6.27 57.273 9.458 107.433 40.65 141.238 87.829 33.808 47.18 47.219 104.703 37.762 161.969s-40.653 107.421-87.836 141.226C306.617 498.02 262.836 512 217.824 512zm0 0"
			></path>
			<path
				fill="#49cb5c"
				d="M171.605 183.887c-81.265-13.79-131.68-116.11-131.68-116.11s81.348-79.965 162.614-66.18c81.266 13.786 131.68 116.106 131.68 116.106s-81.348 79.969-162.614 66.184zm0 0"
			></path>
			<path
				fill="#07b128"
				d="M202.54 1.598c-15.52-2.633-31.036-1.84-45.993 1.156 77.871 17.484 125.906 114.95 125.906 114.95s-53.25 52.339-116.625 65.026c1.91.43 3.832.825 5.777 1.157 81.266 13.785 162.614-66.184 162.614-66.184S283.805 15.383 202.539 1.598zm0 0"
			></path>
			<path
				fill="#09a66d"
				d="M329.625 109.207l-79.473-13.406L175.918.215c-5.922.347-11.79 1.105-17.578 2.191l69.625 89.649-59.926-10.11-51.133-66.156a244.988 244.988 0 00-13.957 6.844l42.957 55.578-99.09-16.719c-4.449 3.887-6.89 6.285-6.89 6.285s1.633 3.309 4.765 8.797L143.242 93.2l-54.656 42.262c3.52 3.625 7.21 7.207 11.07 10.684l64.016-49.497 63.441 10.704-86.281 66.753c5.356 2.5 10.89 4.665 16.598 6.407l90.113-69.711 79.523 13.414c4.61-4.02 7.153-6.512 7.153-6.512s-1.574-3.187-4.594-8.496zm0 0"
			></path>
			<path
				fill="#49cb5c"
				d="M389.492 84.566c21.801-51.586 97.336-68.953 97.336-68.953s40.192 66.27 18.39 117.856c-21.796 51.586-97.335 68.953-97.335 68.953s-40.192-66.27-18.39-117.856zm0 0"
			></path>
			<path
				fill="#07b128"
				d="M486.828 15.613s-17.176 3.953-37.512 13.68C459.75 51.395 476.29 96.25 460.56 133.469c-11.403 26.984-37.512 44.605-59.82 55.273 4.027 8.531 7.148 13.68 7.148 13.68s75.535-17.367 97.336-68.953c21.797-51.586-18.395-117.856-18.395-117.856zm0 0"
			></path>
			<path
				fill="#09a66d"
				d="M511.395 90.922l-58.086 24.367 38.136-91.176c-2.73-5.39-4.617-8.5-4.617-8.5s-3.594.828-9.441 2.657l-39.414 94.226L413.53 52.36c-4.14 3.653-8.047 7.621-11.59 11.922l27.696 68.145-26.016 62.195c2.54 4.957 4.262 7.8 4.262 7.8s3.906-.898 10.187-2.89l26.864-64.219 66.437-27.867c.442-5.52.418-11.054.024-16.523zm0 0"
			></path>
		</svg>
	);
}

export default Environment;
