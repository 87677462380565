import { donation_events_getEmailSentLogHistory_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, { useMemo } from 'react'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import { renderHistoryItem } from './renderHistoryItem'

const WorkspaceDonorsEmailHistory = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  title = 'email thank you',
}) => {
  const apiInfo =
    donation_events_getEmailSentLogHistory_api

  const refreshKey = useMemo(() => {
    return [
      apiInfo.path.replace(
        ':id',
        owner_id
      ),
    ].join('/')
  }, [apiInfo, owner_id])

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader title={title} />
      <EntityList
        refreshKey={refreshKey}
        query={bindQueryParam({
          id: owner_id,
        })}
        RenderEntity={React.Fragment}
        renderItem={renderHistoryItem}
        apiInfo={
          donation_events_getEmailSentLogHistory_api
        }
      />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id }) =>
      _.isEmpty(owner_id),
    renderNothing
  ),
  pure
)(WorkspaceDonorsEmailHistory)
