import { poll_add_Api } from 'apis'
import { getId } from 'apis/model/base'
import { InputBoardProvider } from 'components/Feed/InputBoard/InputBoardContext'
import {
  CreatorSelect,
  OwnerSelect,
} from 'components/Feed/InputBoard/ObjectSelector'
import FieldsFactory from 'components/form/FieldsFactory'
import { LoginContext } from 'components/LoginContext'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import {
    fromRenderProps,
    nest, withProps,
} from 'recompose'
import {
  createRequiredLogin,
  ModalLayout,
} from 'routes/createRequiredLogin'
import CreatePoll from 'views/CreatePoll'
import NoFoundPage from 'views/NoFoundPage'
import ServerContext from 'views/Server/ServerContext'
import {
  getResponseItem,
  Null,
  renderOwnChild,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import { bindQueryParams } from '../functions/routerHelper'
import { notifyOnError } from './createErrorEvent'
import { createControlledFormField } from './createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from './createNotification'

export const PostTypes = Object.freeze({
  POLL: 'poll',
  EMBED: 'embed',
  IMAGES: 'images',
  DEFAULT: 'default',
  ARTICLE: 'article',
})

const CascadedSelector = () => (
  <>
    <FieldsFactory
      formSchema={[
        {
          children: {
            name: 'creator',
            component:
              createControlledFormField(
                {
                  label: 'container',
                    // bordered: true,
                  placeholder: 'select',
                  withValues: ({
                    default_owner_id,
                  }) => ({
                    default_owner_id,
                  }),
                  Component:
                    CreatorSelect,
                }
              ),
          },
        },
        {
          children: {
            name: 'owner',
            component:
              createControlledFormField(
                {
                  label: 'posted by',
                  placeholder: 'select',
                  withValues: ({
                    creator_id,
                    default_owner_id,
                  }) => ({
                    parent_id:
                      creator_id,
                    defaultValue:
                      default_owner_id,
                  }),
                  Component:
                    OwnerSelect,
                }
              ),
          },
        },
      ]}
    />
  </>
)

export const PollPost = ({
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const { id, type, setCurrent } =
    useContext(ServerContext)

  const { addPost } = useContext(
    ObserverContext
  )

  const {
    isLoading,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: poll_add_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      const newItem =
        getResponseItem(response)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      addPost(newItem)
      setCurrent(null)
    },
    onError: notifyOnError(t),
  })

  return (
    <Wrapper>
        <div className="text-center text-xl text-color-000 font-bold uppercase mb-3">
            {t('create a poll')}
        </div>
      <Formik
        initialValues={{
          default_owner_id:
            getId(login),
          owner_id: id,
          owner_type: type,
          owner: undefined,
          creator: undefined,
          creator_id: undefined,
          creator_type: undefined,
        }}>
        <InputBoardProvider
          container={{
            id,
            type,
          }}>
          <CascadedSelector />
          <FormikConsumer>
            {({ values }) => {
              const {
                owner_id,
                owner_type,
                creator_id,
                creator_type,
              } = values
              return (
                <CreatePoll
                  isLoading={isLoading}
                  onSubmit={(
                    values
                  ) => {
                    handleAsyncAction(
                      {
                        owner_id,
                        owner_type,
                        ...values,
                      },
                      bindQueryParams([
                        {
                          id: creator_id,
                        },
                        {
                          prop: creator_type,
                        },
                      ])
                    )
                  }}
                />
              )
            }}
          </FormikConsumer>
        </InputBoardProvider>
      </Formik>
    </Wrapper>
  )
}

const createPost = (type) => {
  let Component = Null
  switch (type) {
      case PostTypes.POLL:
      Component = PollPost
      break
    default:
      return (
        <div className="flex w-full justify-center">
          <NoFoundPage />
        </div>
      )
  }
  return createRequiredLogin()(
    nest(
      fromRenderProps(
        ServerContext.Consumer,
        ({ setCurrent = Null }) => ({
          onCancel: () => {
            setCurrent(null)
          },
        })
      )(ModalLayout),
      Component
    )
  )
}

const EnhancedPost = createPost

export default EnhancedPost
