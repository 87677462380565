import Icon from '@ant-design/icons'
import { Input, message, Modal } from 'antd'
import { videoPlugin } from 'components/RichEditor/plugins'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useRef } from 'react'
import { AiOutlineYoutube } from "react-icons/ai"
import { string } from 'yup'


export default function VideoButton({
  onClick,
  ...props
}) {
  const t = useTranslate()
  const inputRef = useRef()
  const handleAddLink = videoUrl => {
    videoUrl &&
      props.setEditorState(
        videoPlugin.handleInsert(
          props.getEditorState(),
          { src: videoUrl }
        )
      )
  }
  const handleSubmit = async () => {

    const url = _.get(
      inputRef.current,
      'input.value'
    )

    const schema = string().url()
    const valid = await schema.isValid(
      url
    )
    if (valid) {
      handleAddLink(url)
    } else {
      message.error(t('video url is invalid!'))
    }
  }
  return (
    <div className="buttonWrapper relative">
      <button
        style={{ fontSize: '24px' }}
        onClick={e => {
          Modal.confirm({
              icon: (
                  <Icon component={AiOutlineYoutube} />
              ),
              title: t(
                  'Paste a video link to embed content from another site (e.g. Youtube) and click Ok'
              ),
              className: 'custom-modal',
              okText: t('add'),
              cancelText: t('cancel'),
              okButtonProps: {
                  type: 'primary',
                  className: 'rounded-lg no-shadow no-text-shadow no-border'
              },
              cancelButtonProps: {
                  className: 'rounded-lg no-shadow no-text-shadow no-border'
              },
              content: (
                  <form
                      onSubmit={() => {
                          handleSubmit()
                          Modal.destroyAll()
                      }}>
                      <Input
                          ref={inputRef}
                          placeholder={t('enter video url')}
                      />
                      <input
                          hidden
                          type="submit"
                      />
                  </form>
              ),
              onOk: handleSubmit
          })
        }}
        className="button font-semibold px-2">
        <Icon
          component={AiOutlineYoutube}/>
      </button>
    </div>
  )
}
