import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { IoFileTrayStacked } from 'react-icons/io5'

const EmptyHolder = ({
  icon = () => (
    <div
      style={{
        fontSize: '3rem',
        color: '#ebebeb',
      }}>
      <IoFileTrayStacked />
    </div>
  ),
  style,
  title = 'no data yet',
  subTitle = 'no data is available now',
  children,
}) => {
  const t = useTranslate()
  return (
    <div
      style={style}
      className="border border-color-50 rounded-lg pb-8 pt-6 px-4 flex flex-col space-y-2 items-center w-full">
      {icon && (
        <div className="text-color-500">
          {icon()}
        </div>
      )}

      {title && (
        <div className="font-bold text-color-200 leading-snug">
          {typeof title === 'string'
            ? t(title)
            : title()}
        </div>
      )}
      {subTitle && (
        <div className="text-xs text-color-500 leading-tight font-light italic">
          {typeof subTitle === 'string'
            ? t(subTitle)
            : subTitle()}
        </div>
      )}
      {!!children && children}
    </div>
  )
}

export default EmptyHolder
