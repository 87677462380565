import _ from 'lodash'
import RecruitmentBenefits from './components/RecruitmentBenefits'
import RecruitmentRequirement from './components/RecruitmentRequirement'
import RecruitmentTimeLocation from './components/RecruitmentTimeLocation'
import RecruitmentList from './holders/RecruitmentList'
import RecruitmentOverview from './holders/RecruitmentOverview'

export const recruitmentRouteEntities =
  [
    {
      path: 'overview',
      component: RecruitmentOverview,
      breadcrumbName: (t) =>
        t('overview'),
    },
    {
      path: 'requirement',
      component: RecruitmentRequirement,
      breadcrumbName: (t) =>
        t('requirement'),
    },
    {
      path: 'benefits-responsibilities',
      component: RecruitmentBenefits,
      breadcrumbName: (t) =>
        `${t('benefits')} & ${t(
          'responsibilities'
        )}`,
    },
    {
      path: 'time-location',
      component:
        RecruitmentTimeLocation,
      breadcrumbName: (t) =>
        t('time location'),
    },
    {
      path: 'candidates',
      component: RecruitmentList,
      breadcrumbName: (t) =>
        t('candidates'),
      isInvisible: ({ item }) =>
        !_.get(item, 'edit'),
    },
  ]
