import { activity_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import ActivityContext from '../ActivityContext'

const formFields = [{ name: 'mission' }]

const formSchema = [
  {
    title: 'mission',
    children: [
      {
        name: 'mission',
        component:
          createControlledFormField({
            placeholder: 'mission',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
]

const ActivitySettingMission = () => {
  const { activity } = useContext(
    ActivityContext
  )

  const validationSchema = undefined

  const activity_id = getId(activity)

  if (_.isEmpty(activity)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: activity,
    validationSchema,
    query: bindQueryParam({
      activity_id,
    }),
    apiInfo: activity_update_api,
  })
}

export default ActivitySettingMission
