import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import _ from 'lodash'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback,} from 'react'
import {BsUiChecksGrid} from 'react-icons/bs'
import {IoBarChartOutline, IoListOutline, IoMedalOutline} from 'react-icons/io5'
import {compose, withProps,} from 'recompose'
import {renderFalse, renderOwnChild, Visible,} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {WishareEntities} from 'views/Wishare/enums'
import {createCheckPermissions, invalidPermissions,} from 'views/Wishare/factory/createCheckPermissions'
import {SettingsMenu} from 'views/Wishare/factory/createSettingLayout'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import ActivitySubCategories from '../SubCategories/ActivitySubCategories'
import OrganizationSubCategories from '../SubCategories/OrganizationSubCategories'
import {useMenuHandler} from '../useMenuHandler'
import WorkspaceMedalRewardsByType from './WorkspaceMedalRewardsByType'
import WorkspaceMedalRewardsHistory from './WorkspaceMedalRewardsHistory'
import WorkspaceMedalsList from './WorkspaceMedalsList'
import WorkspaceMedalsStatistic from './WorkspaceMedalsStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  CATEGORY_GROUP: 'category-group',
  MEDAL_LIST: 'medal-list',
  MEDAL_GROUP: 'medal-group',
  STAFF_MEDAL_REWARDS:
    'staff-medal-rewards',
  DONOR_MEDAL_REWARDS:
    'donor-medal-rewards',
  MEDAL_REWARDS_HISTORY:
    'medal-rewards-history',
  ORGANIZATION_CATEGORIES:
    'organization-categories',
  ACTIVITY_CATEGORIES:
    'activity-categories',
})

const with_menu_items = ({
                           isDisabled = renderFalse,
                         }) =>
  [
    {
      key: ItemKeys.STATISTIC,
      label: 'insights',
      icon: (
        <IoBarChartOutline size={16}/>
      ),
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageMedal,
        ],
      }),
    },
    {
      key: ItemKeys.MEDAL_GROUP,
      label: 'medals',
      icon: (
        <IoMedalOutline size={16}/>
      ),
      children: [
        {
          key: ItemKeys.MEDAL_LIST,
          label: 'medal list',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
        {
          key: ItemKeys.STAFF_MEDAL_REWARDS,
          label: 'staff received',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
        {
          key: ItemKeys.DONOR_MEDAL_REWARDS,
          label: 'donor received',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
        {
          key: ItemKeys.MEDAL_REWARDS_HISTORY,
          label:
            'history medal rewards',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
      ].map(({...rest}) => ({
        ...rest,
        disabled: isDisabled({
          required_permissions: [
            organizationPermissionCodes.organizationManageMedal,
          ],
        }),
      })),
    },
    {
      key: ItemKeys.CATEGORY_GROUP,
      label: 'options',
      icon: <BsUiChecksGrid size={16}/>,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageInfo,
        ],
      }),
      children: [
        {
          key: ItemKeys.ORGANIZATION_CATEGORIES,
          label: 'subcategories organization',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
        {
          key: ItemKeys.ACTIVITY_CATEGORIES,
          label: 'subcategories activity',
          icon: (
            <IoListOutline size={16}/>
          ),
        },
      ],
    },
  ]
    .filter(Visible)
    .map((item) =>
      _.omit(item, [
        'visible',
        'invisible',
      ])
    )

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceMedalsStatistic,
  },
  {
    key: ItemKeys.ORGANIZATION_CATEGORIES,
    component:
    OrganizationSubCategories,
  },
  {
    key: ItemKeys.ACTIVITY_CATEGORIES,
    component: ActivitySubCategories,
  },
  {
    key: ItemKeys.MEDAL_LIST,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMedal,
    ])(WorkspaceMedalsList),
  },
  {
    key: ItemKeys.DONOR_MEDAL_REWARDS,
    component: compose(
      createCheckPermissions([
        organizationPermissionCodes.organizationManageMedal,
      ]),
      withProps({
        type: WishareEntities.DONOR,
      })
    )(WorkspaceMedalRewardsByType),
  },
  {
    key: ItemKeys.STAFF_MEDAL_REWARDS,
    component: compose(
      createCheckPermissions([
        organizationPermissionCodes.organizationManageMedal,
      ]),
      withProps({
        type: WishareEntities.STAFF,
      })
    )(WorkspaceMedalRewardsByType),
  },
  {
    key: ItemKeys.MEDAL_REWARDS_HISTORY,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMedal,
    ])(WorkspaceMedalRewardsHistory),
  },
]
  .filter(Visible)
  .map(
    ({
       key,
       invisible = false,
       ...rest
     }) => ({
      key,
      path: key,
      ...rest,
    })
  )

const WorkspaceOperatingContent =
  () => {
    const {
      activeKey,
      Component,
      onMenuSelect,
    } = useMenuHandler({
      routes: routeEntities,
      actionName: Queries.REL,
    })

    const renderMenu = useCallback(
      () => (
        <WishareSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled:
                    invalidPermissions(
                      permissions
                    ),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[
                  activeKey,
                ]}
                defaultOpenKeys={[
                  ItemKeys.MEDAL_GROUP,
                  ItemKeys.CATEGORY_GROUP,
                ]}
              />
            )}
          </PermissionContext.Consumer>
        </WishareSiderMenu>
      ),
      [with_menu_items, activeKey]
    )

    return (
      <DefaultTemplate
        renderMenu={renderMenu}>
        <div className="px-3">
          <Component/>
        </div>
      </DefaultTemplate>
    )
  }

export default WorkspaceOperatingContent
