import { Button } from 'antd'
import classNames from 'classnames'
import { createValue } from 'components/form/utils'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {
  useCallback,
} from 'react'
import { IoPricetags } from 'react-icons/io5'
import { Null } from 'views/Shared'
import { TagCreator } from 'views/Wishare/custom/TagsPicker'
import PostPrivacy from './PostPrivacy'

export const AttachedActions = ({
  fieldNames = {
    tags: 'tags',
    privacy: 'privacy',
  },
  onChange = Null,
}) => {
  const handleTagsChanged = useCallback(
    (value) => {
      const tags = _.isArray(value)
        ? Array.from(value || [])
            .map(({ value }) => value)
            .join(',')
        : value
      onChange(
        createValue(
          fieldNames.tags,
          tags
        )
      )
    },
    [onChange, fieldNames]
  )

  return (
    <Toggle>
      {(isToggle, toggle) => (
        <div className="flex flex-col gap-2 mt-3">
          <div
            id="AttachedActions"
            className="flex gap-2">
            <PostPrivacy
              onSelect={(value) => {
                onChange(
                  createValue(
                    fieldNames.privacy,
                    Number(value)
                  )
                )
              }}
            />
            <Translate>
              {(t) => (
                <div
                  className={classNames(
                    isToggle
                      ? 'text-color-300'
                      : 'text-white'
                  )}>
                  <Button
                    onClick={toggle}
                    icon={<IoPricetags />}
                    type={isToggle ? 'primary' : 'default'}
                    className="flex items-center gap-2 px-2 rounded-md no-shadow no-text-shadow no-border">
                    {t('hashtags')}
                  </Button>
                </div>
              )}
            </Translate>
          </div>
          {isToggle && (
            <TagCreator
              onChange={
                handleTagsChanged
              }
            />
          )}
        </div>
      )}
    </Toggle>
  )
}
