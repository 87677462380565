import { message } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import { permissionCodes } from 'modules/permissions/contants'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParam } from 'react-use'
import { compose } from 'recompose'
import { ModalLayout } from 'routes/createRequiredLogin'
import { staticPaths } from 'routes/staticPaths'
import {
  Null,
  renderElse,
} from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import { withAccessPermissions } from './composers'
import {
  modalWidth,
  ModuleContext,
} from './configs'
import ActionMenu from './custom/ActionMenu'
import {
  fromWishareEntity,
  WishareEntities,
} from './enums'
import { ContextActionTypes } from './factory/createContextMenu'
import { ExporterLoader } from './factory/createDataExporter'
import { ImporterLoader } from './factory/createDataImporter'
import wishareModel from './wishareModel'

const useData = ({ item }) => {
  const history = useHistory()

  const [
    current_action,
    set_current_action,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const searchParams =
    new URLSearchParams(
      _.get(history, 'location.search')
    )

  const action_query = useSearchParam(
    Queries.ACTION
  )

  const type_query = useSearchParam(
    Queries.TYPE
  )

  useEffect(() => {
    set_current_action({
      type: type_query,
      value: getId(item),
    })
  }, [history.location])

  const onCancel = () => {
    searchParams.delete(Queries.TYPE)
    searchParams.delete(Queries.ACTION)
    history.push({
      search:
        '?' + searchParams.toString(),
      hash: _.get(
        history,
        'location.hash'
      ),
    })
  }

  const onImport = () => {
    searchParams.set(
      Queries.ACTION,
      'import'
    )
    searchParams.set(Queries.TYPE, type)
    history.push({
      search:
        '?' + searchParams.toString(),
      hash: _.get(
        history,
        'location.hash'
      ),
      state: {
        id,
        type,
        isModal: true,
      },
    })
  }

  const onExport = () => {
    searchParams.set(
      Queries.ACTION,
      'export'
    )
    searchParams.set(Queries.TYPE, type)
    history.push({
      search:
        '?' + searchParams.toString(),
      hash: _.get(
        history,
        'location.hash'
      ),
      state: {
        id,
        type,
        isModal: true,
      },
    })
  }

  const modal_content = useMemo(() => {
    const { value, type } =
      current_action
    let body = null
    if (action_query === 'import') {
      body = (
        <ImporterLoader
          id={value}
          type={type}
          onCancel={onCancel}
        />
      )
    } else if (
      action_query === 'export'
    ) {
      body = (
        <ExporterLoader
          id={value}
          type={type}
        />
      )
    }
    return (
      [
        WishareEntities.DONATION,
        WishareEntities.GIVING,
      ].includes(
        current_action.type
      ) && (
        <ModalLayout
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          visible={action_query}
          width={modalWidth.small}
          title={renderElse(
            _.isEmpty(action_query),
            <Translate>
              {(t) => (
                <div className="text-center mt-4 font-bold text-lg text-color-000 uppercase">
                  {t(action_query)}
                </div>
              )}
            </Translate>
          )}>
          {body}
        </ModalLayout>
      )
    )
  }, [
    searchParams,
    action_query,
    current_action,
  ])

  return [
    modal_content,
    {
      onImport,
      onExport,
      searchParams,
      action_query,
      current_action,
    },
  ]
}

const AdminPanel = ({
  Wrapper = 'div',
  goToSetting = ({
    path,
    push = Null,
  }) => {
    push(path)
  },
}) => {
  const t = useTranslate()

  const history = useHistory()

  const { value } = useContext(
    ModuleContext
  )

  const { entity, Context } = value

  const currentContext =
    useContext(Context)

  const { actions } =
    _.get(value, 'settings') || {}

  const { contextName } =
    fromWishareEntity(entity)

  const item = _.get(
    currentContext,
    contextName
  )

  const withOwnerQueries = (
    owner,
    params = {}
  ) => {
    if (_.isEmpty(owner)) {
      return undefined
    }
    let searchParams =
      new URLSearchParams({
        owner_id: getId(owner),
        owner_type: getType(owner),
        ...params,
      })
    return `?${searchParams.toString()}`
  }

  const [
    data_modal,
    {
      onExport = Null,
      onImport = Null,
    },
  ] = useData({ item })

  const handleClick = useCallback(
    (action) => {
      const _searchQueries =
        withOwnerQueries(item)
      const [id, type] = [
        getId(item),
        getType(item),
      ]
      switch (action) {
        case ContextActionTypes.EXPORT:
          if (
            [
              WishareEntities.DONATION,
              WishareEntities.GIVING,
            ].includes(type)
          ) {
            onExport()
          } else {
            message.warn('coming soon')
          }
          break
        case ContextActionTypes.IMPORT:
          if (
            [
              WishareEntities.DONATION,
              WishareEntities.GIVING,
            ].includes(type)
          ) {
            onImport()
          } else {
            message.warn('coming soon')
          }
          break
        case ContextActionTypes.SETTINGS:
          goToSetting({
            push: history.push,
            path: wishareModel.getLinkToSetting(
              item
            ),
          })
          break
        case ContextActionTypes.SLIDE_SHOW:
          history.push(
            `/donation-shows/${id}/slide-show`
          )
          break
        case ContextActionTypes.CREATE_GIVING:
          history.push(
            _.omitBy(
              {
                pathname:
                  staticPaths
                    .createGiving
                    .pathname,
                search: _searchQueries,
              },
              _.isUndefined
            )
          )
          break
        case ContextActionTypes.CREATE_DONATION:
          history.push(
            _.omitBy(
              {
                pathname:
                  staticPaths
                    .createDonation
                    .pathname,
                search: _searchQueries,
              },
              _.isUndefined
            )
          )
          break
        case ContextActionTypes.CREATE_ACTIVITY:
          history.push(
            _.omitBy(
              {
                pathname:
                  staticPaths
                    .createActivity
                    .pathname,
                search: _searchQueries,
              },
              _.isUndefined
            )
          )
          break
        case ContextActionTypes.CREATE_CHANNEL:
          history.push({
            pathname:
              staticPaths.createChannel
                .pathname,
            search: _searchQueries,
          })
          break
        case ContextActionTypes.CREATE_EVENT:
          history.push({
            pathname:
              staticPaths.createEvent
                .pathname,
            search: _searchQueries,
          })
          break
        case ContextActionTypes.CREATE_RECRUITMENT:
          history.push(
            _.omitBy(
              {
                pathname:
                  staticPaths
                    .createRecruitment
                    .pathname,
                search: _searchQueries,
              },
              _.isUndefined
            )
          )
          break
        case ContextActionTypes.CREATE_ORGANIZATION:
          history.push(
            staticPaths.createOrganization
          )
          break
        default:
          message.warning(
            <span>
              <span className="font-bold">
                {'Admin panel'}
              </span>
              <span>
                {
                  ' missing action handler'
                }
              </span>
            </span>
          )
          break
      }
    },
    [item, history]
  )

  if (_.isEmpty(value)) return null

  return (
    <Wrapper className="flex items-center px-3 py-1 mt-1 border border-primary bg-primary-50 rounded-md">
      <span className="text-primary capitalize font-bold flex-1">
        {t('administration panel')}
      </span>
      {!_.isEmpty(actions) && (
        <div className="cursor-pointer hover:text-primary">
          <ActionMenu
            actions={actions}
            onClick={handleClick}
          />
        </div>
      )}
      {data_modal ? data_modal : null}
    </Wrapper>
  )
}

export default compose(
  withAccessPermissions(
    [permissionCodes.canEdit],
    Null
  )
)(AdminPanel)
