import createStickySider from 'views/Wishare/factory/createStickySider'
import {OrganizationHosts} from 'views/Wishare/Host/components/HostFactory'
import OrganizationImpactReport from './OrganizationImpactReport'
import React, {useContext} from "react";
import createStatusBar from "../../factory/createStatusBar";
import OrganizationContainerContext from "../OrganizationContainerContext";
import {WishareEntities} from "../../enums";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {renderIf} from "../../../Shared";

export const OrganizationStatusBar =
  createStatusBar(
    WishareEntities.ORGANIZATION,
    OrganizationContainerContext
  )

const OrganizationSticker = () => (
  <React.Fragment>
    <OrganizationStatusBar/>
      <div className="h-3"/>
    <OrganizationHosts/>
  </React.Fragment>
)

const OrgReport = () => (
    <LayoutContext.Consumer>
        {({isSm}) => (
            !isSm ? <OrganizationImpactReport /> : <span/>
        )}
    </LayoutContext.Consumer>
)

const OrganizationSider = ({
     Wrapper = 'div',
     stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(
    OrgReport,
    OrganizationSticker,
  )

export default OrganizationSider
