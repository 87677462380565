import {Button, Input} from 'antd'
import {auth_requestResetPassword_Api} from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import {Formik} from 'formik'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import {LocalContext} from 'modules/local/LocalContext'
import React, {useContext} from 'react'
import * as Yup from 'yup'

export const ForgetPassword = () => {
  const { translate } = useContext(
    LocalContext
  )

  const {
    isLoading,
    success,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: auth_requestResetPassword_Api
  })
  return (
    <Formik
      initialValues={{
        email: ''
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors }
      ) => {
        handleAsyncAction(values)
      }}
      validationSchema={Yup.object().shape(
        {
          email: Yup.string()
            .email(
              translate(
                'invalid email address'
              )
            )
            .required(
              translate('required field')
            )
        }
      )}>
      {props => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props
        return (
          <div className="w-full max-w-lg h-full flex items-center justify-center p-4 flex-col">
            <form
              onSubmit={handleSubmit}
              className="w-full">
              <br />
              <FieldDecorator
                label={translate(
                  'enter your email'
                )}
                isRequired={true}
                errors={errors.email}>
                <Input
                  size="large"
                  inputMode="email"
                  name="email"
                  value={values.email}
                  onChange={
                    handleChange
                  }
                  onBlur={handleBlur}
                  className={`ForgetPassword-control ${
                    errors.email
                      ? 'ForgetPassword-control'
                      : ''
                  }`}
                  type="text"
                  placeholder={translate(
                    'email account'
                  )}
                />
              </FieldDecorator>
              {success && (
                <>
                  <div className="text-color-300 italic">
                    {`${translate(
                      'the restore email has been sent to'
                    )} ${
                      values.email
                    }. ${translate(
                      'check your email and click on the link'
                    )}`}
                  </div>
                  <br />
                </>
              )}

              <div className="mt-2">
                <Button
                  loading={isLoading}
                  // disabled={!isValid}
                  onClick={handleSubmit}
                  type="primary"
                  className="w-full"
                  size="large">
                  {isLoading
                    ? translate(
                        'sending'
                      )
                    : success
                    ? translate(
                        'resend'
                      )
                    : translate(
                        'send request'
                      )}
                </Button>
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )
}

export default ForgetPassword
