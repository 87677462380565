import React from "react";

function GoldMedalSvg({size = 30, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 510 510"
      {...props}
    >
      <linearGradient id="lggm1">
        <stop offset="0" stopColor="#fef0ae"></stop>
        <stop offset="1" stopColor="#fbc56d"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_1_GM"
        x1="72.721"
        x2="105.721"
        y1="363.436"
        y2="408.936"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient id="lggm2">
        <stop offset="0" stopColor="#dc8758" stopOpacity="0"></stop>
        <stop offset="0.996" stopColor="#dc8758" stopOpacity="0.996"></stop>
        <stop offset="1" stopColor="#dc8758"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000096059282872428830480000011499915508092298421_"
        x1="69.716"
        x2="121.716"
        y1="392.617"
        y2="416.617"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000073683785649861447540000008250512956081955738_"
        x1="38.872"
        x2="93.372"
        y1="324.652"
        y2="365.152"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000134940357196594510090000005070891299847048597_"
        x1="61.72"
        x2="103.053"
        y1="369.953"
        y2="369.287"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000024720307053842855310000017122229594761748638_"
        x1="148.363"
        x2="135.863"
        y1="231.487"
        y2="288.987"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000056407699143955633300000011206899048651824294_"
        x1="94.349"
        x2="126.349"
        y1="237.278"
        y2="269.944"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000065793722268671470680000008096832128208309381_"
        x1="75.128"
        x2="102.628"
        y1="245.299"
        y2="331.299"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000059275438799995172830000016531008987520891032_"
        x1="80.669"
        x2="116.002"
        y1="292.128"
        y2="322.795"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000106137019791307634060000011746670599169048971_"
        x1="814.174"
        x2="847.174"
        y1="363.436"
        y2="408.936"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000108999361436644517730000018021148800200361112_"
        x1="811.168"
        x2="863.168"
        y1="392.617"
        y2="416.617"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000092453463080795751720000011023797112814379146_"
        x1="780.324"
        x2="834.824"
        y1="324.652"
        y2="365.152"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000134932119374335817800000012118356890046906786_"
        x1="803.172"
        x2="844.505"
        y1="369.953"
        y2="369.287"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000163039852097225205830000007347204393334895758_"
        x1="889.815"
        x2="877.315"
        y1="231.487"
        y2="288.987"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000146463504554978784560000013389406356789528744_"
        x1="835.801"
        x2="867.801"
        y1="237.278"
        y2="269.944"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000044859753002549876840000017467840226869347220_"
        x1="816.58"
        x2="844.08"
        y1="245.299"
        y2="331.299"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000181057043746700985070000015378480368081286274_"
        x1="822.121"
        x2="857.454"
        y1="292.128"
        y2="322.795"
        gradientTransform="matrix(-1 0 0 1 1251.452 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000088105730694926041990000001992194922937344411_"
        x1="156.954"
        x2="324.67"
        y1="252.928"
        y2="420.644"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000071561108277057066190000002073201051477929602_"
        x1="337.326"
        x2="170.272"
        y1="433.3"
        y2="266.246"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000183233500541831055180000017552257388645439122_"
        x1="175.891"
        x2="306.108"
        y1="271.865"
        y2="402.082"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000000918316877099873730000010553891599411407010_"
        x1="255"
        x2="255"
        y1="397.502"
        y2="501.882"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000157305474378843298190000010909739780281153671_"
        x1="350.243"
        x2="218.909"
        y1="433.62"
        y2="350.953"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient id="lggm3">
        <stop offset="0" stopColor="#fbc56d"></stop>
        <stop offset="1" stopColor="#c5715a"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000067202837751582379400000015304590177670947213_"
        x1="221.874"
        x2="281.61"
        y1="320.747"
        y2="380.482"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm3"
      ></linearGradient>
      <linearGradient id="lggm4">
        <stop offset="0" stopColor="#8c3d27" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#8c3d27"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000000902500109731794170000005408833589533994920_"
        x1="290.793"
        x2="208.23"
        y1="337.034"
        y2="371.166"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000042016382380429756930000006447152958300983736_"
        x1="269.236"
        x2="224.034"
        y1="366.334"
        y2="346.961"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000070079383850012534940000007842209833952310957_"
        x1="2415.94"
        x2="2370.738"
        y1="366.334"
        y2="346.961"
        gradientTransform="matrix(-1 0 0 1 2656.704 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000003810618284389568970000007269385251155011976_"
        x1="241.769"
        x2="270.827"
        y1="368.601"
        y2="321.9"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000088117070798342456960000000833263787283560852_"
        x1="266.003"
        x2="263.582"
        y1="362.703"
        y2="317.04"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm4"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000043433326417433653380000002907659520825269921_"
        x1="333.364"
        x2="272.031"
        y1="283.792"
        y2="176.459"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000041990815809659017320000013912070958971954832_"
        x1="262.112"
        x2="301.612"
        y1="56.676"
        y2="119.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f9f5f3"></stop>
        <stop offset="1" stopColor="#d8e8f0"></stop>
      </linearGradient>
      <linearGradient id="lggm5">
        <stop offset="0" stopColor="#fd4755"></stop>
        <stop offset="1" stopColor="#a72b2b"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000017480474890867574070000004639875041324681874_"
        x1="222.713"
        x2="345.38"
        y1="-20.62"
        y2="120.046"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm5"
      ></linearGradient>
      <linearGradient id="lggm6">
        <stop offset="0" stopColor="#a72b2b" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#42210b"></stop>
      </linearGradient>
      <linearGradient
        id="SVGID_00000017502666571973420770000012434187902404561582_"
        x1="255"
        x2="255"
        y1="66.5"
        y2="33.466"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000163063692234757140340000010092734682543963537_"
        x1="196.655"
        x2="319.321"
        y1="2.104"
        y2="142.77"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm5"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000101086487707588144920000003385988987088475054_"
        x1="292.995"
        x2="310.065"
        y1="107.005"
        y2="124.075"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000002375386552981520280000001346203996876177831_"
        x1="1356.995"
        x2="1374.065"
        y1="107.005"
        y2="124.075"
        gradientTransform="matrix(-1 0 0 1 1574 0)"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm6"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000152234436945935641930000001615839193518459314_"
        x1="341.686"
        x2="136.626"
        y1="177.612"
        y2="-27.448"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000151528649065452528880000003108432255969151413_"
        x1="255"
        x2="255"
        y1="81.667"
        y2="221.706"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000089576389922551553110000011323850583732677556_"
        x1="255"
        x2="255"
        y1="163"
        y2="209.044"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000034771112367295652530000015120071156190649989_"
        x1="302.267"
        x2="274.267"
        y1="136.929"
        y2="165.429"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm2"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000159436357136572823330000015128865034840501153_"
        x1="233.5"
        x2="286.53"
        y1="168.5"
        y2="221.53"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm3"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000091696865153676864420000003054716083236435892_"
        x1="276"
        x2="226.323"
        y1="211"
        y2="161.323"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm3"
      ></linearGradient>
      <linearGradient
        id="SVGID_00000155121582527183284030000010332501736471910306_"
        x1="255"
        x2="255"
        y1="11.333"
        y2="32.171"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#lggm1"
      ></linearGradient>
      <path
        fill="url(#SVGID_1_GM)"
        d="M110.119 377.851c30.153 3.894 56.459 16.139 75.085 33.009 3.901 3.533 2.448 14.781-2.222 17.207-22.3 11.585-50.854 16.746-81.007 12.852S45.516 424.78 26.89 407.91c-3.901-3.533-2.448-14.781 2.222-17.207 22.3-11.585 50.854-16.746 81.007-12.852z"
      ></path>
      <path
        fill="url(#SVGID_00000096059282872428830480000011499915508092298421_)"
        d="M110.119 377.851c30.153 3.894 56.459 16.139 75.085 33.009 3.901 3.533 2.448 14.781-2.222 17.207-22.3 11.585-50.854 16.746-81.007 12.852S45.516 424.78 26.89 407.91c-3.901-3.533-2.448-14.781 2.222-17.207 22.3-11.585 50.854-16.746 81.007-12.852z"
      ></path>
      <path
        fill="url(#SVGID_00000073683785649861447540000008250512956081955738_)"
        d="M113.644 335.627c32.247 13.98 57.546 36.007 72.834 60.709 3.201 5.173-2.014 17.202-7.978 18.402-28.48 5.728-61.851 2.317-94.097-11.663-32.247-13.98-57.546-36.006-72.834-60.709-3.201-5.173 2.014-17.202 7.978-18.402 28.48-5.727 61.85-2.317 94.097 11.663z"
      ></path>
      <path
        fill="url(#SVGID_00000134940357196594510090000005070891299847048597_)"
        d="M113.644 335.627c32.247 13.98 57.546 36.007 72.834 60.709 3.201 5.173-2.014 17.202-7.978 18.402-28.48 5.728-61.851 2.317-94.097-11.663-32.247-13.98-57.546-36.006-72.834-60.709-3.201-5.173 2.014-17.202 7.978-18.402 28.48-5.727 61.85-2.317 94.097 11.663z"
      ></path>
      <path
        fill="url(#SVGID_00000024720307053842855310000017122229594761748638_)"
        d="M171.708 267.844c12.916 32.687 15.231 66.152 8.573 94.429-1.394 5.922-13.588 10.74-18.653 7.371-24.189-16.089-45.374-42.097-58.29-74.784s-15.231-66.152-8.573-94.429c1.394-5.922 13.588-10.74 18.653-7.371 24.189 16.088 45.374 42.096 58.29 74.784z"
      ></path>
      <path
        fill="url(#SVGID_00000056407699143955633300000011206899048651824294_)"
        d="M171.708 267.844c12.916 32.687 15.231 66.152 8.573 94.429-1.394 5.922-13.588 10.74-18.653 7.371-24.189-16.089-45.374-42.097-58.29-74.784s-15.231-66.152-8.573-94.429c1.394-5.922 13.588-10.74 18.653-7.371 24.189 16.088 45.374 42.096 58.29 74.784z"
      ></path>
      <path
        fill="url(#SVGID_00000065793722268671470680000008096832128208309381_)"
        d="M124.641 269.074c32.88 29.56 54.259 65.943 62.625 101.518 1.752 7.45-9.275 19.716-16.869 18.764-36.262-4.547-74.706-21.947-107.586-51.508C29.931 308.288 8.552 271.905.186 236.33c-1.752-7.45 9.275-19.716 16.869-18.764 36.261 4.547 74.706 21.948 107.586 51.508z"
      ></path>
      <path
        fill="url(#SVGID_00000059275438799995172830000016531008987520891032_)"
        d="M15.249 276.751c11.185 21.777 27.157 42.751 47.563 61.097 32.88 29.56 71.324 46.961 107.586 51.508 7.594.952 18.621-11.313 16.869-18.764-8.366-35.575-29.745-71.958-62.625-101.518-19.361-17.407-40.653-30.592-62.291-39.424z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000106137019791307634060000011746670599169048971_)"
          d="M399.881 377.851c-30.153 3.894-56.459 16.139-75.085 33.009-3.901 3.533-2.448 14.781 2.222 17.207 22.3 11.585 50.854 16.746 81.007 12.852s56.459-16.139 75.085-33.009c3.901-3.533 2.448-14.781-2.222-17.207-22.3-11.585-50.854-16.746-81.007-12.852z"
        ></path>
        <path
          fill="url(#SVGID_00000108999361436644517730000018021148800200361112_)"
          d="M399.881 377.851c-30.153 3.894-56.459 16.139-75.085 33.009-3.901 3.533-2.448 14.781 2.222 17.207 22.3 11.585 50.854 16.746 81.007 12.852s56.459-16.139 75.085-33.009c3.901-3.533 2.448-14.781-2.222-17.207-22.3-11.585-50.854-16.746-81.007-12.852z"
        ></path>
        <path
          fill="url(#SVGID_00000092453463080795751720000011023797112814379146_)"
          d="M396.356 335.627c-32.247 13.98-57.546 36.007-72.834 60.709-3.201 5.173 2.014 17.202 7.978 18.402 28.48 5.728 61.851 2.317 94.097-11.663 32.247-13.98 57.546-36.006 72.834-60.709 3.201-5.173-2.014-17.202-7.978-18.402-28.48-5.727-61.85-2.317-94.097 11.663z"
        ></path>
        <path
          fill="url(#SVGID_00000134932119374335817800000012118356890046906786_)"
          d="M396.356 335.627c-32.247 13.98-57.546 36.007-72.834 60.709-3.201 5.173 2.014 17.202 7.978 18.402 28.48 5.728 61.851 2.317 94.097-11.663 32.247-13.98 57.546-36.006 72.834-60.709 3.201-5.173-2.014-17.202-7.978-18.402-28.48-5.727-61.85-2.317-94.097 11.663z"
        ></path>
        <path
          fill="url(#SVGID_00000163039852097225205830000007347204393334895758_)"
          d="M338.292 267.844c-12.916 32.687-15.231 66.152-8.573 94.429 1.394 5.922 13.588 10.74 18.653 7.371 24.189-16.089 45.374-42.097 58.29-74.784s15.231-66.152 8.573-94.429c-1.394-5.922-13.588-10.74-18.653-7.371-24.189 16.088-45.374 42.096-58.29 74.784z"
        ></path>
        <path
          fill="url(#SVGID_00000146463504554978784560000013389406356789528744_)"
          d="M338.292 267.844c-12.916 32.687-15.231 66.152-8.573 94.429 1.394 5.922 13.588 10.74 18.653 7.371 24.189-16.089 45.374-42.097 58.29-74.784s15.231-66.152 8.573-94.429c-1.394-5.922-13.588-10.74-18.653-7.371-24.189 16.088-45.374 42.096-58.29 74.784z"
        ></path>
        <path
          fill="url(#SVGID_00000044859753002549876840000017467840226869347220_)"
          d="M385.359 269.074c-32.88 29.56-54.259 65.943-62.625 101.518-1.752 7.45 9.275 19.716 16.869 18.764 36.262-4.547 74.706-21.947 107.586-51.508 32.88-29.56 54.259-65.943 62.625-101.518 1.752-7.45-9.275-19.716-16.869-18.764-36.261 4.547-74.706 21.948-107.586 51.508z"
        ></path>
        <path
          fill="url(#SVGID_00000181057043746700985070000015378480368081286274_)"
          d="M494.751 276.751c-11.185 21.777-27.157 42.751-47.563 61.097-32.88 29.56-71.324 46.961-107.586 51.508-7.594.952-18.621-11.313-16.869-18.764 8.366-35.575 29.745-71.958 62.625-101.518 19.361-17.407 40.653-30.592 62.291-39.424z"
        ></path>
      </g>
      <g>
        <circle
          cx="255"
          cy="350.974"
          r="159.026"
          fill="url(#SVGID_00000088105730694926041990000001992194922937344411_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="126.755"
          fill="url(#SVGID_00000071561108277057066190000002073201051477929602_)"
        ></circle>
        <circle
          cx="255"
          cy="350.974"
          r="108.82"
          fill="url(#SVGID_00000183233500541831055180000017552257388645439122_)"
        ></circle>
        <path
          fill="url(#SVGID_00000000918316877099873730000010553891599411407010_)"
          d="M95.974 350.974C95.974 438.802 167.172 510 255 510s159.026-71.198 159.026-159.026c0-.617-.016-1.23-.023-1.846H95.997c-.007.616-.023 1.229-.023 1.846z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000157305474378843298190000010909739780281153671_)"
        d="M299.601 503.65c50.109-14.614 89.983-53.219 106.356-102.552l-77.453-77.453-17.461.857-47.126-47.126-57.435 133.155z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000067202837751582379400000015304590177670947213_)"
          d="M320.208 316.419l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217-3.428.278-6.76 2.003-8.934 5.217l-17.978 26.575a11.977 11.977 0 01-7.109 4.932l-31.187 7.535c-8.46 2.044-11.985 12.141-6.637 19.007l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.866 1.823-16.963-6.637-19.007z"
        ></path>
        <path
          fill="url(#SVGID_00000000902500109731794170000005408833589533994920_)"
          d="M260.912 402.263l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.349-6.865 1.823-16.963-6.637-19.007l-31.187-7.535a11.983 11.983 0 01-7.109-4.932l-17.978-26.575c-2.175-3.214-5.506-4.939-8.934-5.217V401.7a11.955 11.955 0 015.912.563z"
        ></path>
        <path
          fill="url(#SVGID_00000042016382380429756930000006447152958300983736_)"
          d="M320.208 316.419l-31.187-7.535a11.956 11.956 0 01-3.02-1.185l-79.502 102.833c3.119 3.014 7.824 4.331 12.424 2.664l30.165-10.932a11.957 11.957 0 015.912-.563 11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989c-.23-2.976.66-5.93 2.494-8.285l19.719-25.31c5.348-6.867 1.823-16.964-6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000070079383850012534940000007842209833952310957_)"
          d="M189.792 316.419l31.187-7.535a11.956 11.956 0 003.02-1.185l79.502 102.833c-3.119 3.014-7.824 4.331-12.424 2.664l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989c.23-2.976-.66-5.93-2.494-8.285l-19.719-25.31c-5.348-6.867-1.823-16.964 6.637-19.008z"
        ></path>
        <path
          fill="url(#SVGID_00000003810618284389568970000007269385251155011976_)"
          d="M181.491 323.646c-1.483 3.74-1.127 8.197 1.664 11.78l19.719 25.31a11.981 11.981 0 012.494 8.285l-2.471 31.989c-.67 8.677 7.843 15.151 16.026 12.185l30.165-10.932A11.957 11.957 0 01255 401.7a11.979 11.979 0 015.912.563l30.165 10.932c8.182 2.965 16.696-3.508 16.026-12.185l-2.471-31.989a11.954 11.954 0 01.633-4.831z"
        ></path>
        <path
          fill="url(#SVGID_00000088117070798342456960000000833263787283560852_)"
          d="M328.521 323.646c1.483 3.74 1.128 8.197-1.664 11.78l-19.719 25.31a11.981 11.981 0 00-2.494 8.285l2.471 31.989c.67 8.677-7.844 15.151-16.026 12.185l-30.165-10.932a11.957 11.957 0 00-5.912-.563 11.979 11.979 0 00-5.912.563l-30.165 10.932c-8.182 2.965-16.696-3.508-16.026-12.185l2.471-31.989a11.954 11.954 0 00-.633-4.831z"
        ></path>
      </g>
      <path
        fill="url(#SVGID_00000043433326417433653380000002907659520825269921_)"
        d="M255 191.948c-.66 0-1.315.017-1.973.025l-24.542 24.542 179.161 179.161a159.079 159.079 0 006.38-44.703c0-87.827-71.198-159.025-159.026-159.025z"
      ></path>
      <g>
        <path
          fill="url(#SVGID_00000041990815809659017320000013912070958971954832_)"
          d="M159 3.333v84l96 96 96-96v-84z"
        ></path>
        <path
          fill="url(#SVGID_00000017480474890867574070000004639875041324681874_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000017502666571973420770000012434187902404561582_)"
          d="M159 3.333v26.142l95.679 95.679a.454.454 0 00.642 0L351 29.475V3.333z"
        ></path>
        <path
          fill="url(#SVGID_00000163063692234757140340000010092734682543963537_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000101086487707588144920000003385988987088475054_)"
          d="M255 140.28a15.401 15.401 0 01-10.927-4.519L159 50.688v36.646l96 96 96-96V50.688l-85.073 85.073A15.405 15.405 0 01255 140.28z"
        ></path>
        <path
          fill="url(#SVGID_00000002375386552981520280000001346203996876177831_)"
          d="M255 140.28c3.958 0 7.915-1.506 10.927-4.519L351 50.688v36.646l-96 96-96-96V50.688l85.073 85.073A15.407 15.407 0 00255 140.28z"
        ></path>
        <g>
          <path
            fill="url(#SVGID_00000152234436945935641930000001615839193518459314_)"
            d="M244.473 161.527l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-6.126 6.126-6.126 16.059 0 22.185L255 216.426l114.407-114.407c6.126-6.126 6.126-16.059 0-22.185-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.815 5.814-15.241 5.814-21.055-.001z"
          ></path>
          <path
            fill="url(#SVGID_00000151528649065452528880000003108432255969151413_)"
            d="M140.593 80.019L242.954 182.38c6.653 6.653 17.44 6.653 24.093 0l102.36-102.361c.03-.03.054-.063.084-.093-.029-.03-.054-.063-.084-.093-6.126-6.126-16.059-6.126-22.185 0l-81.694 81.694c-5.814 5.814-15.241 5.814-21.055 0l-81.694-81.694c-6.126-6.126-16.059-6.126-22.185 0-.03.03-.054.063-.084.093.029.03.053.063.083.093z"
          ></path>
          <path
            fill="url(#SVGID_00000089576389922551553110000011323850583732677556_)"
            d="M255 216.426l80.759-80.759h-44.371l-25.861 25.861c-5.814 5.814-15.241 5.814-21.055 0l-25.861-25.861H174.24z"
          ></path>
          <path
            fill="url(#SVGID_00000034771112367295652530000015120071156190649989_)"
            d="M245 206.426l10 10L357.426 114h-44.371l-47.527 47.527c-5.645 5.645-14.684 5.792-20.528.477z"
          ></path>
        </g>
        <g>
          <circle
            cx="255"
            cy="190"
            r="37.498"
            fill="url(#SVGID_00000159436357136572823330000015128865034840501153_)"
          ></circle>
          <circle
            cx="255"
            cy="190"
            r="21.498"
            fill="url(#SVGID_00000091696865153676864420000003054716083236435892_)"
          ></circle>
        </g>
        <path
          fill="url(#SVGID_00000155121582527183284030000010332501736471910306_)"
          d="M365.502 37.333H144.498c-6.35 0-11.498-5.148-11.498-11.498V11.498C133 5.148 138.148 0 144.498 0h221.005C371.852 0 377 5.148 377 11.498v14.338c0 6.35-5.148 11.497-11.498 11.497z"
        ></path>
      </g>
    </svg>
  );
}

export default GoldMedalSvg;
