import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import { organizationSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const organizationPartnerSchema =
  new schema.Entity(
    'organization_partner',
    {},
    {
      idAttribute: 'id',
    }
  )
const fields = [
  'name', //=> ['type' => 'text'],
  'phone', //=> ['type' => 'text'],
  'phone2', //=> ['type' => 'text'],
  'email', //=> ['type' => 'text'],
]
const fieldTypes = {
  name: baseFieldTypes.string, //=> ['type' => 'text'],
  phone: baseFieldTypes.string, //=> ['type' => 'text'],
  phone2: baseFieldTypes.string, //=> ['type' => 'text'],
  email: baseFieldTypes.string, //=> ['type' => 'text']
}
export const partners_addPartner_Api = {
  backend: `// Route::post('organizations/{org_id}/partners/add', 'OrganizationPartnerController@add');`,
  root: API_ROOT_URL,
  path: '/organizations/:id/partners/add',
  method: 'POST',
  fields,
  fieldTypes,
  schema: organizationPartnerSchema,
  transform: createTransform(
    organizationPartnerSchema,
    'data'
  ),
}

export const partners_editPartner_Api =
  {
    backend: `// Route::post('organizations/partner/{id}/edit', 'OrganizationPartnerController@edit');`,
    root: API_ROOT_URL,
    path: '/organizations/partner/:id/edit',
    method: 'POST',
    fields,
    fieldTypes,
    schema: organizationPartnerSchema,
    transform: createTransform(
      organizationPartnerSchema,
      'data'
    ),
  }

export const partners_deletePartner_Api =
  {
    backend: `// Route::post('organizations/partner/{id}/delete', 'OrganizationPartnerController@delete');`,
    root: API_ROOT_URL,
    path: '/organizations/partner/:id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: organizationPartnerSchema,
    transform: createTransform(
      organizationPartnerSchema,
      'data'
    ),
  }

export const partners_getPartners_Api =
  {
    backend: `// Route::get('organizations/{id}/partners/get-partners', 'OrganizationPartnerController@getPartners');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/partners/get-partners',
    method: 'GET',
    fieldTypes,
    paramsFields: [
      'sort_by',
      'keyword',
      'partner_type',
      'subcategories',
    ],
    schema: organizationPartnerSchema,
    transform: createListTransform(
      organizationPartnerSchema
    ),
  }

export const partners_getById_Api = {
  backend: `Route::get('organizations/partner/{id}/get', 'OrganizationPartnerController@getById');`,
  root: API_ROOT_URL,
  path: '/organizations/partner/:id/get',
  method: 'GET',
  fieldTypes,
  schema: organizationPartnerSchema,
  transform: createTransform(
    organizationPartnerSchema,
    'data'
  ),
}
export const partners_getIdentifiedPartners_Api =
  {
    backend: `// Route::get('organizations/{id}/partners/get-identified-partners', 'OrganizationPartnerController@getIdentifiedPartners');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/partners/get-identified-partners',
    method: 'GET',
    fieldTypes,
    paramsFields: [
      'partner_type',
      'keyword',
    ],
    schema: organizationPartnerSchema,
    transform: createListTransform(
      organizationPartnerSchema
    ),
  }

export const partners_getPriceProviders_Api =
  {
    backend: `// Route::get('organizations/{id}/get-price-providers', 'OrganizationPartnerController@getPriceProviders');`,
    root: API_ROOT_URL,
    path: '/organizations/:id/get-price-providers',
    method: 'GET',
    fieldTypes,
    paramsFields: ['keyword'],
    schema: organizationSchema,
    transform: createListTransform(
      organizationSchema
    ),
  }
