import {Image as Img} from 'antd'
import classNames from 'classnames'
import React, {useEffect, useState,} from 'react'
import {PhotoPlaceholderSiteApp} from '../conts/imageBase64'

const _loaded = {}
const imageSources = {}

function ImageLoader({
  alt,
  style,
  onClick,
                       rootClassName = "",
  className,
  prefixCls,
  loadingClassName = '',
  holder = PhotoPlaceholderSiteApp,
  loadedClassName = 'fadeIn animated',
  src,
}) {
  const [visible, setVisible] =
    useState(() => {
      if (!imageSources[src]) {
        const image = new Image()
        image.src = src
        imageSources[src] = image
      }
      return imageSources[src]
    })
  const _className = visible
    ? loadedClassName
    : loadingClassName
  useEffect(() => {
    if (imageSources[src]) {
      let handler = () => {
        _loaded[src] = true
        setVisible(true)
      }
      imageSources[
        src
      ].addEventListener(
        'load',
        handler
      )
      return () =>
        imageSources[
          src
        ].removeEventListener(
          'load',
          handler
        )
    }
  }, [src])

  return (
    <Img
      style={style}
      rootClassName={rootClassName}
      preview={false}
      alt={alt || ''}
      fallback={holder}
      onClick={onClick}
      prefixCls={prefixCls}
      className={classNames(
        _className,
        className
      )}
      src={visible ? src : holder}
    />
  )
}

export default React.memo(ImageLoader)
