import { AtomicBlockUtils, EditorState, Modifier } from 'draft-js';

export function createStore(
  initialState = {}
) {
  let state = initialState
  const listeners = {}

  return {
    subscribeToItem(key, callback) {
      listeners[key] =
        listeners[key] || []
      listeners[key] &&
        listeners[key].push(callback)
    },
    unsubscribeFromItem(key, callback) {
      const listener = listeners[key]
      if (listener) {
        listeners[
          key
        ] = listener.filter(
          currentListener =>
            currentListener !== callback
        )
      }
    },
    updateItem(key, item) {
      state = {
        ...state,
        [key]: item
      }
      const listener = listeners[key]
      if (listener) {
        listener.forEach(
          currentListener =>
            currentListener(state[key])
        )
      }
    },
    getItem(key) {
      return state[key]
    }
  }
}

export const updateEntity = (
  editorState,
  entityKey,
  patch
) => {
  const mergedContent = editorState
    .getCurrentContent()
    .mergeEntityData(entityKey, patch)

  const changedContentState = Modifier.applyEntity(
    mergedContent,
    editorState.getSelection(),
    entityKey
  )

  return EditorState.push(
    editorState,
    changedContentState,
    'apply-entity'
  )
}

export const createBlockTypeIsActive = blockType => editorState => {
  const type = editorState
    .getCurrentContent()
    .getBlockForKey(
      editorState
        .getSelection()
        .getStartKey()
    )
    .getType()
  return type === blockType
}
export const createInsertAtomicBlock = entityType => (
  editorState,
  data
) => {
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity(
    entityType,
    'IMMUTABLE',
    data
  )
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' '
  )
  return EditorState.forceSelection(
    newEditorState,
    newEditorState
      .getCurrentContent()
      .getSelectionAfter()
  )
}
export const createBlockRendererFn = (
  entityType,
  component
) => (
  block,
  {
    getEditorState,
    getReadOnly,
    setEditorState
  }
) => {
    if (block.getType() === 'atomic') {
      const contentState = getEditorState().getCurrentContent()
      const entity = block.getEntityAt(0)
      if (!entity) return null
      const type = contentState
        .getEntity(entity)
        .getType()
      if (type === entityType) {
        return {
          component,
          editable: false,
          props: {
            getEditorState,
            getReadOnly,
            setEditorState
          }
        }
      }
    }

    return null
  }
