import { organizationModel } from 'apis/model'
import React from 'react'
import {
  compose,
  fromRenderProps,
} from 'recompose'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import { createCheckPermissions } from 'views/Wishare/factory/createCheckPermissions'
import { About } from './About'
import ChannelSettings from './ChannelSettings.lazy'
import Contacts from './Contacts/Contacts.lazy'
import Delete from './Delete/Delete.lazy'
import InviteManager from './InviteManager'
import Locations from './Locations/Locations.lazy'
import { OrganizationSettingContext } from './OrganizationSettingContext'
import Overview from './Overview.lazy'
import Photo from './Photo/Photo.lazy'
import Profile from './Profile/Profile.lazy'

export const routeEntities = {
  overview: {
    path: '',
    name: 'overview',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Overview),
    exact: true,
  },
  about: {
    path: 'about',
    name: 'about',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(About),
    exact: true,
  },
  locations: {
    path: 'locations',
    name: 'locations',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Locations),
    exact: true,
  },
  contacts: {
    path: 'contacts',
    name: 'contacts',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Contacts),
    exact: true,
  },
  profiles: {
    path: 'profiles',
    name: 'provider profiles',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Profile),
    exact: true,
  },
  photos: {
    path: 'photos',
    name: 'photos',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Photo),
    exact: true,
  },
  members: {
    path: 'members',
    name: 'members',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Members),
    exact: true,
  },
  waitingMembers: {
    path: 'waiting-members',
    name: 'waiting members list',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(WaitingAcceptMembers),
    exact: true,
  },
  delete: {
    path: 'delete',
    name: 'delete',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(Delete),
    exact: true,
  },
  managechannel: {
    path: 'channel-manage',
    name: 'channel management',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions([
        'channel-allowable',
      ])
    )(({ item }) => {
      return (
        <ChannelSettings
          {...{
            type: organizationModel.getType(
              item
            ),
            id: organizationModel.getId(
              item
            ),
          }}
        />
      )
    }),
  },
  invite: {
    path: 'invite',
    name: 'invite',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        (props) => props
      ),
      createCheckPermissions(['edit'])
    )(InviteManager),
    exact: true,
  },
}
export const routes = [
  routeEntities.overview,
  routeEntities.about,
  routeEntities.contacts,
  routeEntities.locations,
  routeEntities.profiles,
  routeEntities.photos,
  routeEntities.members,
  routeEntities.waitingMembers,
  routeEntities.invite,
  routeEntities.delete,
  routeEntities.manageDataset,
  routeEntities.managechannel,
  routeEntities.summary,
  routeEntities.addProduct,
  routeEntities.manageProducts,
  routeEntities.managePrices,
  routeEntities.manageProductReports,
  routeEntities.product,
  routeEntities.preview,
  routeEntities.businessProfile,
  routeEntities.incomingRequests,
  routeEntities.messages,
]
