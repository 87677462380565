import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import {
  branch,
  renderNothing,
} from 'recompose'
import {
  Null,
  renderTrue,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import EntityList from 'views/Wishare/EntityList'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import { recruitmentApi } from '../../apis'
import RecruitmentContext from '../RecruitmentContext'
import { CandidateEntity } from '../RecruitmentEntity'
import RecruitmentRecordItem from './RecruitmentRecordItem'

const RecruitmentItemList = ({
  sample,
  keyword,
  children,
  filtered_by,
  Footer = Null,
  Wrapper = 'div',
  isFiltered = renderTrue,
  ...props
}) => {
  const { recruitment } = useContext(
    RecruitmentContext
  )

  const { posts = [] } = useContext(
    ObserverContext
  )

  if (_.isEmpty(recruitment))
    return null

  const id = getId(recruitment)

  const apiInfo =
    recruitmentApi.recruitment_events_getCandidates_api

  const query = bindQueryParam({ id })

  const values = {
    keyword,
    filtered_by,
  }

  const lookupKey = [
    mapPathParam(apiInfo.path, { id }),
    JSON.stringify({ filtered_by }),
    JSON.stringify({ keyword }),
  ].join('/')

  const refreshKey = JSON.stringify({
    query,
    values,
    posts,
  })

  return (
    <React.Fragment>
      <EntityList
        query={query}
        sample={sample}
        values={values}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        refreshKey={refreshKey}
        RenderEntity={CandidateEntity}
        RenderItem={branch(
          ({ item }) =>
            !isFiltered(
              _.get(item, 'status')
            ),
          renderNothing
        )(RecruitmentRecordItem)}
        renderWrap={(
          child,
          __,
          count
        ) => (
          <Wrapper className="space-y-3">
            {child}
            {count ? <Footer /> : null}
          </Wrapper>
        )}
        {...props}
      />
    </React.Fragment>
  )
}

export default RecruitmentItemList
