import { Avatar } from 'antd'
import channelModel from 'apis/model/channel'
import { FollowButton } from 'components/Feed/FollowButton'
import { LoginContext } from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import numeral from 'numeral'
import React from 'react'
import { IoLogoRss } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { WishareEntities } from '../../Wishare/enums'
import { OverlayItemBanner } from '../../Wishare/Templates/ItemTemplate'

export const renderChannelItem = (
  e
) => {
  if (!e) return null

  return (
    <Translate>
      {(t) => (
        <div key={e.id} className="w-full border border-color-50 rounded-lg relative">
          <div className="flex items-center gap-2 p-2">
            <Avatar
                size={35}
                className="flex flex-center background-100"
                src={getAvatar(e.owner)}>
              <span className="uppercase font-bold text-color-200">
                {_.first(getTitle(e.owner))}
              </span>
            </Avatar>
            <div className="flex flex-col flex-1 gap-1">
              <Link
                to={channelModel.getLinkToDetail(e)}
                className="font-bold text-sm text-color-000 max-lines-1 no-underline">
                {e.title}
              </Link>
              <div className="flex items-center justify-between">
                <div className="flex items-center truncate">
                  {!!_.get(e, 'selected_by_editor') && (
                      <span className="text-xs text-color-200 background-200 rounded-full px-2 py-0 m-0 h-5">
                        {t('editor choice')}
                      </span>
                  )}
                  <div className="flex flex-center gap-1">
                    {e.follow_status ? (
                        <IoLogoRss size={13} className="text-primary"/>
                    ) : (
                        <IoLogoRss size={13} className="text-primary"/>
                    )}
                    {!!e.total_followers && (
                        <span className="font-bold text-color-000 text-xs">
                          {numeral(e.total_followers).format()}
                        </span>
                    )}
                  </div>
                </div>
                <LoginContext.Consumer>
                  {(login) => {
                    return (
                        login &&
                        !!!_.get(
                            e,
                            'follow_status'
                        ) && (
                            <FollowButton
                                size="small"
                                className="float-right leading-tight"
                                item={e}
                            />
                        )
                    )
                  }}
                </LoginContext.Consumer>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translate>
  )
}
