import { Spin } from 'antd'
import { getId } from 'apis/model/base'
import Pure from 'components/Pure'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import IOChart from 'views/Chart/Charts/ChartComponents/IOChart'
import { getResponseItems } from 'views/Shared'
import {
  bindQueryParam,
  mapPathParam,
} from 'views/Wishare/functions/routerHelper'
import { givingApi } from '../../apis'
import GivingContext from '../GivingContext'
import {renderIf} from "../../../Shared";

const GivingChartContent = ({
  id,
  unit,
  title,
  response,
  isLoading = false,
}) => {
  if (Boolean(isLoading)) {
    return (
      <Spin
        spinning={true}
        style={{
          minHeight: 360,
        }}
        className="flex justify-center items-center"
      />
    )
  }

  const data_values =
    getResponseItems(response)

  return (
    <Pure input={[data_values]}>
      <IOChart
        chartInfo={{
          title,
          id: id,
          idname: id,
          data_values,
        }}
        unit={unit}
      />
    </Pure>
  )
}

const GivingChart = () => {
  const t = useTranslate()

  const { giving } = useContext(
    GivingContext
  )

  if (_.isEmpty(giving)) return null

  const id = getId(giving)

  const { target_unit_name } = giving

  const apiInfo =
    givingApi.giving_events_getGivingDataForChart_api

  const query = bindQueryParam({
    id,
  })

  const refreshKey = mapPathParam(
    apiInfo.path,
    { id }
  )

  const chartTitle = `(${t(
    'giving history'
  )})`

  return renderIf(
    !!giving.conversion_required,
    <div className="w-full flex flex-col">
      <div className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 mb-3 border-b">
        {t('giving chart')}
      </div>
      <div className="background-100 p-3 rounded-lg">
        <Async
          key={refreshKey}
          query={query}
          apiInfo={apiInfo}>
          {({
            response,
            isLoading,
          }) => (
            <GivingChartContent
              {...{
                id,
                response,
                isLoading,
              }}
              title={chartTitle}
              unit={target_unit_name}
            />
          )}
        </Async>
      </div>
    </div>
  )
}

export default GivingChart
