import React from 'react';

export const PollSvg = () => (
	<svg
		width="1em"
		height="1em"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="-32 0 512 512">
		<path
			d="m426.167969 264.75h-85.164063c-3.640625 0-6.59375 2.949219-6.59375 6.59375v233.042969h98.347656v-233.042969c0-3.640625-2.949218-6.59375-6.589843-6.59375zm0 0"
			fill="#ffc850"
		/>
		<path
			d="m371.550781 264.75h-30.546875c-3.640625 0-6.59375 2.953125-6.59375 6.59375v233.046875h30.550782v-233.046875c0-3.640625 2.949218-6.59375 6.589843-6.59375zm0 0"
			fill="#feb644"
		/>
		<path
			d="m266.277344 375.886719h-85.164063c-3.640625 0-6.589843 2.953125-6.589843 6.59375v121.910156h98.34375v-121.910156c0-3.640625-2.949219-6.59375-6.589844-6.59375zm0 0"
			fill="#00aaf0"
		/>
		<path
			d="m211.664062 375.886719h-30.550781c-3.640625 0-6.589843 2.953125-6.589843 6.59375v121.910156h30.546874v-121.910156c0-3.640625 2.953126-6.59375 6.59375-6.59375zm0 0"
			fill="#059adb"
		/>
		<path
			d="m63.808594 0c-16.035156 0-29.152344 13.117188-29.152344 29.148438 0 16.035156 13.117188 29.152343 29.152344 29.152343 16.03125 0 29.148437-13.117187 29.148437-29.152343 0-16.03125-13.117187-29.148438-29.148437-29.148438zm0 0"
			fill="#f7b594"
		/>
		<path
			d="m65.207031 29.148438c0-10.4375 5.566407-19.640626 13.875-24.792969-4.449219-2.757813-9.683593-4.355469-15.273437-4.355469-16.035156 0-29.152344 13.117188-29.152344 29.148438 0 16.035156 13.117188 29.152343 29.152344 29.152343 5.589844 0 10.824218-1.601562 15.273437-4.359375-8.308593-5.148437-13.875-14.351562-13.875-24.792968zm0 0"
			fill="#f0a479"
		/>
		<path
			d="m106.390625 152.335938h-85.164063c-3.640624 0-6.59375 2.953124-6.59375 6.59375v345.457031h98.347657v-345.457031c0-3.640626-2.953125-6.59375-6.589844-6.59375zm0 0"
			fill="#f25c5c"
		/>
		<path
			d="m51.773438 152.335938h-30.546876c-3.640624 0-6.59375 2.953124-6.59375 6.59375v345.457031h30.550782v-345.457031c0-3.640626 2.949218-6.59375 6.589844-6.59375zm0 0"
			fill="#e54a4a"
		/>
		<path
			d="m87.074219 58.300781h-46.535157c-14.734374 0-26.789062 12.054688-26.789062 26.789063v32.515625c0 3.640625 2.949219 6.59375 6.589844 6.59375h86.933594c3.640624 0 6.59375-2.953125 6.59375-6.59375v-32.515625c-.003907-14.734375-12.058594-26.789063-26.792969-26.789063zm0 0"
			fill="#f25c5c"
		/>
		<path
			d="m44.296875 117.605469v-32.515625c0-14.734375 12.058594-26.789063 26.792969-26.789063h-30.550782c-14.734374 0-26.789062 12.054688-26.789062 26.789063v32.515625c0 3.640625 2.949219 6.59375 6.589844 6.59375h30.550781c-3.640625 0-6.59375-2.953125-6.59375-6.59375zm0 0"
			fill="#e54a4a"
		/>
		<path
			d="m441.320312 512h-433.707031c-4.207031 0-7.613281-3.40625-7.613281-7.613281 0-4.203125 3.40625-7.609375 7.613281-7.609375h433.710938c4.203125 0 7.609375 3.40625 7.609375 7.609375 0 4.207031-3.40625 7.613281-7.613282 7.613281zm0 0"
			fill="#52586d"
		/>
		<path
			d="m48.480469 124.199219v-26.125c0-4.203125-3.40625-7.613281-7.609375-7.613281-4.207032 0-7.613282 3.410156-7.613282 7.613281v26.121093h15.222657zm0 0"
			fill="#d83f3f"
		/>
		<path
			d="m94.355469 98.074219c0-4.203125-3.40625-7.613281-7.613281-7.613281-4.203126 0-7.609376 3.410156-7.609376 7.613281v26.121093h15.222657zm0 0"
			fill="#e54a4a"
		/>
		<path
			d="m223.695312 223.550781c-16.03125 0-29.148437 13.117188-29.148437 29.148438 0 16.035156 13.117187 29.152343 29.148437 29.152343 16.035157 0 29.152344-13.117187 29.152344-29.152343 0-16.03125-13.121094-29.148438-29.152344-29.148438zm0 0"
			fill="#f7b594"
		/>
		<path
			d="m225.09375 252.699219c0-10.441407 5.566406-19.640625 13.875-24.792969-4.449219-2.757812-9.683594-4.355469-15.273438-4.355469-16.03125 0-29.148437 13.117188-29.148437 29.148438s13.117187 29.148437 29.148437 29.148437c5.589844 0 10.824219-1.597656 15.273438-4.355468-8.308594-5.148438-13.875-14.351563-13.875-24.792969zm0 0"
			fill="#f0a479"
		/>
		<path
			d="m246.960938 281.847656h-46.53125c-14.734376 0-26.792969 12.058594-26.792969 26.792969v32.515625c0 3.640625 2.953125 6.589844 6.59375 6.589844h86.933593c3.640626 0 6.589844-2.949219 6.589844-6.589844v-32.515625c0-14.734375-12.054687-26.792969-26.792968-26.792969zm0 0"
			fill="#00c3ff"
		/>
		<path
			d="m204.1875 341.15625v-32.515625c0-14.734375 12.054688-26.792969 26.789062-26.792969h-30.546874c-14.734376 0-26.792969 12.058594-26.792969 26.792969v32.515625c0 3.640625 2.953125 6.589844 6.59375 6.589844h30.546875c-3.640625 0-6.589844-2.949219-6.589844-6.589844zm0 0"
			fill="#00aaf0"
		/>
		<path
			d="m208.371094 347.746094v-26.121094c0-4.203125-3.40625-7.613281-7.613282-7.613281-4.203124 0-7.609374 3.410156-7.609374 7.613281v26.121094zm0 0"
			fill="#059adb"
		/>
		<path
			d="m254.246094 321.625c0-4.203125-3.410156-7.613281-7.613282-7.613281-4.203124 0-7.613281 3.410156-7.613281 7.613281v26.121094h15.226563zm0 0"
			fill="#00aaf0"
		/>
		<path
			d="m383.585938 112.414062c-16.035157 0-29.152344 13.117188-29.152344 29.148438 0 16.035156 13.117187 29.152344 29.152344 29.152344 16.03125 0 29.148437-13.117188 29.148437-29.152344 0-16.03125-13.117187-29.148438-29.148437-29.148438zm0 0"
			fill="#f7b594"
		/>
		<path
			d="m384.984375 141.5625c0-10.441406 5.566406-19.640625 13.875-24.792969-4.449219-2.757812-9.683594-4.355469-15.273437-4.355469-16.035157 0-29.152344 13.117188-29.152344 29.148438s13.117187 29.152344 29.152344 29.152344c5.589843 0 10.824218-1.601563 15.273437-4.359375-8.308594-5.148438-13.875-14.351563-13.875-24.792969zm0 0"
			fill="#f0a479"
		/>
		<path
			d="m406.851562 170.714844h-46.535156c-14.734375 0-26.789062 12.054687-26.789062 26.789062v32.515625c0 3.640625 2.949218 6.589844 6.589844 6.589844h86.933593c3.640625 0 6.59375-2.949219 6.59375-6.589844v-32.515625c0-14.734375-12.058593-26.789062-26.792969-26.789062zm0 0"
			fill="#ffc850"
		/>
		<path
			d="m364.074219 230.019531v-32.515625c0-14.734375 12.058593-26.789062 26.792969-26.789062h-30.550782c-14.734375 0-26.789062 12.054687-26.789062 26.789062v32.515625c0 3.640625 2.949218 6.589844 6.589844 6.589844h30.550781c-3.640625 0-6.59375-2.949219-6.59375-6.589844zm0 0"
			fill="#feb644"
		/>
		<path
			d="m368.261719 236.609375v-26.121094c0-4.203125-3.410157-7.613281-7.613281-7.613281-4.203126 0-7.613282 3.410156-7.613282 7.613281v26.121094zm0 0"
			fill="#f9972d"
		/>
		<path
			d="m414.132812 210.488281c0-4.203125-3.40625-7.613281-7.609374-7.613281-4.207032 0-7.613282 3.410156-7.613282 7.613281v26.121094h15.222656zm0 0"
			fill="#feb644"
		/>
	</svg>
);
