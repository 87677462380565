import { event_getEventsByProps_Api } from 'apis/event'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import EntityList from 'views/Wishare/EntityList'
import EventItem from 'views/Wishare/Event/components/EventItem'
import EventEntity from 'views/Wishare/Event/EventEntity'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import ActivityContext from '../ActivityContext'

const ActivityEventList = ({
  Wrapper = 'div',
}) => {
  const { activity: item } = useContext(
    ActivityContext
  )

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const apiInfo =
    event_getEventsByProps_Api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':type', type),
      ].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        RenderItem={EventItem}
        RenderEntity={EventEntity}
      />
    </Wrapper>
  )
}

export default ActivityEventList
