import classNames from 'classnames'
import React, {useContext, useEffect, useState,} from 'react'
import ReactPlayer from 'react-player'
import useToggle from 'react-use/lib/useToggle'
import {FB_ID} from '../../envs/_current/config'
import {LayoutContext} from '../layouts/Default/LayoutContext'
import Description from './Description'

let currentPlayerRefs = {
  current: {
    setPlaying: undefined,
  },
}

const MediaPlayer = ({url, isPlaying}) => {
  const [playing, setPlaying] = useState(isPlaying)

  useEffect(() => {
    if (
      currentPlayerRefs.current.setPlaying
    ) {
      currentPlayerRefs.current.setPlaying(
        false
      )
    }
    currentPlayerRefs.current.setPlaying = setPlaying
    setPlaying(true)
    return () => {
      currentPlayerRefs.current.setPlaying = undefined
    }
  }, [isPlaying, setPlaying])

  return (
    <ReactPlayer
      style={{
        fontSize: '1em',
      }}
      width="100%"
      height="100%"
      url={url}
      light={true}
      config={{
        youtube: {
          playerVars: {
            controls: 1,
            autoplay: playing ? 1 : 0,
            showinfo: 1,
          },
        },
        facebook: {
          appId: FB_ID,
        },
      }}
    />
  )
}

export function VideoEmbed({
                             url,
                             title,
                             image,
                             subTitle,
                             className,
                             style = {},
                             height,
                             description,
                             categories = [],
                             isShowPlayer = false,
                           }) {
  const {isSm} = useContext(
    LayoutContext
  )
  const [showPlayer, toggleShowPlayer] =
    useToggle(isShowPlayer)
  return (
    <div
      style={{
        borderRadius: '0.3em',
        ...style,
      }}
      className={classNames(
        ' cursor-pointer verticalList',
        className
      )}>
      <div
        className="relative overflow-hidden"
        style={{
          width: '100%',
          height: height ? height : (isSm ? '12rem' : '24rem'),
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}>
          <MediaPlayer url={url} isPlaying={showPlayer}/>
        </div>
      </div>
      <div className="break-words flex-1 mr-2 flex flex-col overflow-hidden verticalList__small">
        {!!subTitle && (
          <div className="flex inline">
            <img
              style={{
                verticalAlign: 'sub',
              }}
              className="inline pr-1 object-cover"
              alt={title}
              src={`https://www.google.com/s2/favicons?domain=${subTitle}`}
            />
            <a
              href={'//' + subTitle}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="truncate text-color-300 hover:underline leading-none">
              {subTitle}
            </a>
          </div>
        )}
        {!!title && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="font-semibold hover:underline color-primary text-lg leading-6">
            <h3>{title}</h3>
          </a>
        )}
        {!!description && (
          <Description className="leading-snug">
            {description}
          </Description>
        )}
        {!!categories &&
        !!categories.length && (
          <div className="text-xs">
            {categories.map((e) => {
              return (
                <span
                  key={e.id}
                  className="text-color-300">
                    {'•'}
                  <span className="px-2">
                      {e.name}
                    </span>
                  </span>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
