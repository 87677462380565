import { schema } from 'normalizr'
import { hostSchema } from './host'

export const donationEventSchema =
  new schema.Entity(
    'donation_events',
    {
      hosts: [hostSchema],
    },
    {
      idAttribute: 'idname',
    }
  )

export const donationSchema =
  new schema.Entity(
    'donations',
    {},
    {
      idAttribute: 'id',
    }
  )

export const donorSchema =
  new schema.Entity(
    'donors',
    {},
    {
      idAttribute: 'id',
    }
  )
