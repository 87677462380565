import { Button } from 'antd'
import { getChannelsByProps_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { channelSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useMemo } from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import { staticPaths } from 'routes/staticPaths'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'
import { Null } from 'views/Shared'
import { ChannelsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'

const ChannelEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={channelSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

const WorkspaceChannelList = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [owner_id, location.state]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo: getChannelsByProps_Api,
        query: bindQueryParams([
          { id: owner_id },
          { type: owner_type },
        ]),
        renderItem: renderChannelItem,
        RenderEntity: ChannelEntity,
      },
      withKey: ({ sortBy, keyword }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      renderFilter: Null,
      query_params: filter_actions,
      sortHeader: t('channel list'),
      SortByWidget: ChannelsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="channels"
        extra={
          <Button
            type="primary"
            className="rounded-md no-border no-text-shadow no-shadow"
            onClick={() => {
              history.push({
                ...staticPaths.createChannel,
                search:
                  '?' +
                  new URLSearchParams({
                    owner_id,
                    owner_type,
                  }).toString(),
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id }) =>
      _.isEmpty(owner_id),
    renderNothing
  ),
  pure
)(WorkspaceChannelList)
