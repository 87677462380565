import { recruitment_events_getCandidatesByType_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import { Null } from 'views/Shared'
import { CandidatesSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import RecruitmentCandidateItem from 'views/Wishare/Recruitment/components/RecruitmentCandidateItem'
import { CandidateEntity } from 'views/Wishare/Recruitment/RecruitmentEntity'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import CandidatesAdvancedFilter from './CandidatesAdvancedFilter'

const WorkspaceHRRecentCandidates = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const status = useSearchParam(
    filter_actions.status
  )

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const onCancel = () =>
    setAction({ type: undefined })

  const refresh_deps = useMemo(
    () => [
      owner_id,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [owner_id, location.state]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
        onFilterChange = Null,
      }) => {
        onFilterChange({
          status,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          recruitment_events_getCandidatesByType_api,
        query: bindQueryParams([
          {
            id: owner_id,
          },
          { type: owner_type },
        ]),
        RenderEntity: CandidateEntity,
        RenderItem:
          RecruitmentCandidateItem,
      },
      withKey: ({
        sortBy,
        keyword,
        filterBy,
      }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      filter_params: {
        owner_id,
        owner_type,
      },
      query_params: filter_actions,
      dependencies: [owner_id],
      sortHeader: t('candidate list'),
      SortByWidget: CandidatesSortBy,
      AdvancedFilter:
        CandidatesAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader title="recent candidates" />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id }) =>
      _.isEmpty(owner_id),
    renderNothing
  ),
  pure
)(WorkspaceHRRecentCandidates)
