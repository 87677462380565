import {
  Button,
  Card,
  ConfigProvider,
  Input,
  List,
  Modal,
  Spin,
} from 'antd'
import { getId } from 'apis/model/base'
import {
  profile_addSkill_Api,
  profile_deleteSkill_Api,
  profile_updateSkill_Api,
} from 'apis/profile'
import { handleconfirm } from 'components/Confirm'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  IoAdd,
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { nest } from 'recompose'
import {
  getResponseItem,
  Null,
  renderOwnChild,
  updateArray,
} from 'views/Shared'
import {
  ObserverContext,
  ObserverProvider,
} from 'views/Shared/components/ObservedList'
import CRUD from 'views/Shared/enums/CRUD'
import { RenderEmptyMeta } from 'views/Wishare/custom/AntdConfigWrapper'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'

const formSchema = [
  {
    title: 'skills',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            rows: 3,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
]

const FormContainer = ({
  action,
  onCancel = Null,
  setValue = Null,
  handleSubmit = Null,
}) => {
  const t = useTranslate()

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const onError = notifyOnError(t)

  const {
    type: action_type,
    value: current_item,
  } = action || {}

  const {
    isLoading: isCreating,
    handleAsyncAction: handleCreate,
  } = useAsyncAction({
    apiInfo: profile_addSkill_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      addPost(getResponseItem(response))
    },
    onError,
  })

  const {
    isLoading: isUpdating,
    handleAsyncAction: handleUpdate,
  } = useAsyncAction({
    query: bindQueryParam({
      id: getId(current_item),
    }),
    apiInfo: profile_updateSkill_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      const newItem =
        getResponseItem(response)
      setValue(getId(newItem), newItem)
    },
    onError,
  })

  const params =
    action_type === CRUD.UPDATE
      ? {
          value: current_item,
          isLoading: isUpdating,
          onSubmit: handleUpdate,
        }
      : {
          value: '',
          isLoading: isCreating,
          onSubmit: handleCreate,
        }

  return (
    <SkillForm
      {...params}
      action={action_type}
      handleSubmit={handleSubmit}
    />
  )
}

const SkillForm = ({
  value,
  isLoading,
  onSubmit = Null,
  handleSubmit = Null,
  action = CRUD.CREATE,
}) => {
  const t = useTranslate()

  const initialValues = {
    description: _.get(
      value,
      'description'
    ),
  }

  return (
    <Spin spinning={!!isLoading}>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit(
          onSubmit
        )}
        validationSchema={yupShape({
          description:
            requiredString(t),
        })}
        initialValues={initialValues}>
        <FlexCol className="space-y-2">
          <FieldsFactory
            formSchema={formSchema}
          />
          <FormActionBar
            submitText={
              action === CRUD.CREATE
                ? 'create'
                : 'save'
            }
            background={false}
          />
        </FlexCol>
      </Formik>
    </Spin>
  )
}

const EditProfileSkill = ({
  value,
  readOnly = false,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [currentAction, setAction] =
    useState({
      type: undefined,
      value: undefined,
    })

  const [skills, setSkills] = useState(
    Array.from(value || [])
  )

  const {
    posts = [],
    updatePost = Null,
    removePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: deleteSkill,
  } = useAsyncAction({
    apiInfo: profile_deleteSkill_Api,
    onError: notifyOnError(t),
    onSuccess: (
      result,
      { response }
    ) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      const deletedId = getId(
        getResponseItem(response)
      )
      if (deletedId) {
        removePost(deletedId)
        setSkills([
          ...skills.filter(
            (skill) =>
              getId(skill) !== deletedId
          ),
        ])
      }
    },
  })

  const remove = useCallback(
    (item) => {
      handleconfirm({
        title: t(
          'are you sure delete this'
        ),
        okText: t('sure'),
        cancelText: t('no'),
        okButtonProps: {
          type: 'primary',
          danger: true,
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          const id = getId(item)
          if (id) {
            deleteSkill(
              {},
              bindQueryParam({ id })
            )
          }
        },
      })
    },
    [deleteSkill]
  )

  const onCancel = () => {
    setAction({})
  }

  return (
    <Wrapper>
      <Card
        size="small"
        title={t('skills')}
        style={{
          borderRadius: '0.5rem',
          border:
            '1px solid rgb(218 218 218)',
        }}
        headStyle={{
          fontStyle: 'italic',
          color:
            'var(--text-color-400)',
          fontWeight: '500',
          background:
            'var(--background-100)',
          borderBottom: 'none',
          borderRadius: '0.45rem 0.45rem 0 0',
        }}
        extra={
          readOnly ? undefined : (
            <Button
              ghost={true}
              size="small"
              type="primary"
              icon={<IoAdd />}
              onClick={() =>
                setAction({
                  type: CRUD.CREATE,
                })
              }
              className="flex items-center space-x-1 rounded-md no-shadow no-text-shadow">
              <span className="text-xs">
                {t('add')}
              </span>
            </Button>
          )
        }>
        <ConfigProvider
          renderEmpty={RenderEmptyMeta}>
          <List
            dataSource={[
              ...skills,
              ...posts,
            ]}
            renderItem={(item) => (
              <List.Item
                style={{
                  padding: '0.25rem 0',
                }}
                extra={
                  readOnly ? undefined : (
                    <div className="flex gap-2 ml-2">
                      <div
                        onClick={() =>
                          remove(item)
                        }
                        className="w-7 h-7 p-1 rounded border border-red-500 cursor-pointer flex items-center justify-center">
                        <IoTrashOutline className="text-red-500" />
                      </div>
                      <div
                        className="w-7 h-7 p-1 rounded border border-primary cursor-pointer flex items-center justify-center"
                        onClick={() => {
                          setAction({
                            type: CRUD.UPDATE,
                            value: item,
                          })
                        }}>
                        <IoCreateOutline className="text-primary" />
                      </div>
                    </div>
                  )
                }>
                <List.Item.Meta
                  title={_.get(
                    item,
                    'description'
                  )}
                />
              </List.Item>
            )}
          />
        </ConfigProvider>
      </Card>
      <Modal
        className="custom-modal"
        footer={null}
        closable={false}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={currentAction.type}>
        <FormContainer
          onCancel={onCancel}
          setValue={(id, value) => {
            const values = updateArray(
              skills
            )(id, value)
            if (_.isArray(values)) {
              updatePost(id, value)
              setSkills(values)
            }
          }}
          action={currentAction}
          handleSubmit={(submit) =>
            (values) => {
              submit(values)
            }}
        />
      </Modal>
    </Wrapper>
  )
}

export default nest(
  ObserverProvider,
  EditProfileSkill
)
