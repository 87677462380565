import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  compose,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import {
  deepTranslate,
  Null,
  renderOwnChild,
} from 'views/Shared'
import InlineMenu from '../custom/InlineMenu'
import useSettingNavRoutes from '../hooks/useSettingNavRoutes'
import FlyWrapper from '../Templates/FlyWrapper'

const renderDefault = (content) => (
  <DelayRender lazy={true}>
    {content}
  </DelayRender>
)

export const SettingsMenu = ({
  items = [],
  Wrapper = 'div',
  onSelect = Null,
  defaultSelectedKeys,
  render = renderDefault,
  MenuWrapper = FlyWrapper,
  defaultOpenKeys = [],
  ...props
}) => {
  const t = useTranslate()

  const { isLg, setOpenMenu = Null } =
    useContext(LayoutContext)

  const menu = useMemo(
    () => items.map(deepTranslate(t)),
    [items, t]
  )

  const handleClick = useCallback(
    ({ key, keyPath }) => {
      switch (key) {
        default:
          onSelect(key, keyPath)
          break
      }
      setOpenMenu(false)
    },
    [onSelect]
  )

  const defaultOpenKey = useMemo(() => {
    const selectedKey = _.first(
      defaultSelectedKeys
    )
    const item = menu.find(
      ({ children = [] }) =>
        children
          .map((e) => e.key)
          .includes(selectedKey)
    )
    return _.get(item, 'key')
  }, [menu, defaultSelectedKeys])

  return (
    <Wrapper className="space-y-3 md:p-0 p-2 h-full">
      {render(
        <MenuWrapper disabled={!!!isLg}>
          <InlineMenu
            items={menu}
            onClick={handleClick}
            defaultOpenKeys={[
              ...defaultOpenKeys,
              defaultOpenKey,
            ]}
            defaultSelectedKeys={
              defaultSelectedKeys
            }
            {...props}
            className="SettingMenuInline"
          />
        </MenuWrapper>
      )}
    </Wrapper>
  )
}

const withSettingLayout =
  (Component) =>
  ({
    routes = [],
    menuItems = [],
    Template,
    MenuWrapper,
    ...props
  }) => {
    const history = useHistory()

    const { currentAction } =
      useSettingNavRoutes(routes)

    const onSelect = useCallback(
      (current) => {
        history.replace({
          pathname: current,
        })
      },
      [history]
    )

    const renderMenu = useCallback(
      () => (
        <WishareSiderMenu
          Wrapper={renderOwnChild}>
          <SettingsMenu
            items={menuItems}
            defaultSelectedKeys={[
              currentAction,
            ]}
            onSelect={onSelect}
            MenuWrapper={MenuWrapper}
          />
        </WishareSiderMenu>
      ),
      [
        menuItems,
        onSelect,
        // MenuWrapper,
        currentAction,
      ]
    )

    return (
      <Template renderMenu={renderMenu}>
        <Component
          {...props}
          {...{
            routes,
            currentAction,
          }}
        />
      </Template>
    )
  }

const createSettingLayout = (props) => {
  return (Component) => {
    const Layout = compose(
      withProps(props),
      withSettingLayout
    )(Component)
    return <Layout />
  }
}

export default createSettingLayout
