import {
  AutoComplete,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
} from 'antd'
import { search_searchObjects_Api } from 'apis'
import { getId } from 'apis/model/base'
import {
  partners_addPartner_Api,
  partners_deletePartner_Api,
  partners_editPartner_Api,
  partners_getById_Api,
} from 'apis/organizationPartner'
import { renderDecoratedLabel } from 'components/form/FieldDecorator'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import {
  createSelectValue,
  createValue,
} from 'components/form/utils'
import Pure from 'components/Pure'
import { Formik } from 'formik'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  BsPencilSquare,
  BsTrash,
} from 'react-icons/bs'
import {
  compose,
  mapProps,
  pure,
} from 'recompose'
import {
  getResponseItem,
  Null,
  renderIf,
  shallowDiff,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { UserPrefixTitles } from 'views/User/User'
import { modalWidth } from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import ContainerPicker, {
  renderDefaultActions,
  withOwnerResponses,
} from 'views/Wishare/custom/ContainerPicker'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import { WishareEntities } from 'views/Wishare/enums'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { NotificationActionTypes } from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { PartnerTypes } from './Partner'
import {successNotify} from "../../../Wishare/factory/createNotification";

const NamePicker = ({
  name,
  value,
  onChange,
  children,
  defaultValue,
  setFieldValue = Null,
  tabs = {
    text: {
      label: 'input partner name',
      value: 'text',
    },
    select: {
      label: 'select account wishare',
      value: 'select',
    },
  },
}) => {
  const t = useTranslate()

  const [tab, setTab] = useState(
    defaultValue
      ? tabs.select.value
      : tabs.text.value
  )

  useEffect(() => {
    setTab(
      defaultValue
        ? tabs.select.value
        : tabs.text.value
    )
  }, [defaultValue])

  const handleChange = (event) => {
    const { value } = event.target || {}
    setTab(value)
    switch (value) {
      case tabs.text.value:
        setFieldValue(name, null)
        setFieldValue(
          name + '_id',
          null
        )
        break
      case tabs.select.value:
        setFieldValue(
          'partner_name',
          null
        )
        break
      default:
        break
    }
  }

  return (
    <div className="flex flex-col">
      <Radio.Group
        className="mb-3"
        name="tab"
        value={tab}
        onChange={handleChange}>
        <Space direction="horizontal">
          {[tabs.text, tabs.select].map(
            (
              { label, value },
              index
            ) => (
              <Radio
                key={index}
                label={t(label)}
                className="italic font-medium text-color-000"
                value={value}>
                {t(label)}
              </Radio>
            )
          )}
        </Space>
      </Radio.Group>
      <React.Fragment>
        {tab === tabs.text.value ? (
          children
        ) : (
          <ContainerPicker
            {...{
              name,
              onChange,
              allowClear: true,
              label: 'organization',
              dependencies: [
                {
                  value: 'title',
                  name: 'partner_name',
                },
              ],
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                apiInfo:
                  search_searchObjects_Api,
                values: {
                  type: [
                    WishareEntities.ORGANIZATION,
                  ].join(','),
                },
              },
              renderActions:
                renderDefaultActions(
                  value
                ),
              getOption: (option) =>
                _.get(option, 'owner'),
              withResponses:
                withOwnerResponses(
                  value
                ),
            }}
          />
        )}
      </React.Fragment>
    </div>
  )
}

export const formSchema = {
  partner_name: {
    inline: true,
    className: 'flex flex-col',
    children: [
      {
        name: 'partner_name',
        label: 'partner name',
        component:
          createControlledFormField({
            className: 'w-full',
            placeholder: 'partner name',
            Component: AutoComplete,
            allowClear: true,
            withProps: ({
              name,
              onChange,
            }) => ({
              onChange: (value) => {
                onChange(
                  createValue(
                    name,
                    value
                  )
                )
              },
            }),
            withValues: ({
              partner_organization_name,
            }) => ({
              options: _.isEmpty(
                partner_organization_name
              )
                ? undefined
                : [
                    {
                      value:
                        partner_organization_name,
                    },
                  ],
            }),
          }),
      },
      {
        label: 'short name',
        name: 'partner_short_name',
        component:
          createControlledFormField({
            placeholder: 'short name',
          }),
      },
    ],
  },
  partner_tags: {
    title: 'tags',
    children: [
      {
        name: 'partner_tags',
        component: ({
          name,
          value,
          onChange,
        }) => {
          const array = _.split(
            value,
            ','
          )
          const tags = _.isEmpty(value)
            ? []
            : array
          return (
            <SelectWidget
              mode="tags"
              value={value}
              className="w-full"
              allowClear={true}
              onChange={(value) => {
                onChange(
                  createValue(
                    name,
                    value
                  )
                )
              }}>
              {tags.map(
                (tag, index) => (
                  <Select.Option
                    key={index}
                    value={tag}
                    label={tag}>
                    {_.startsWith(
                      tag,
                      '#'
                    )
                      ? tag
                      : '#' + tag}
                  </Select.Option>
                )
              )}
            </SelectWidget>
          )
        },
      },
    ],
  },
  partner_type: {
    title: 'partner type',
    children: [
      {
        name: 'partner_type',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              translate,
              onChange,
            }) => {
              return {
                name,
                value,
                mode: 'multiple',
                allowClear: true,
                className: 'w-full',
                placeholder: translate(
                  'partner type'
                ),
                options: Object.values(
                  PartnerTypes
                ).map((type) => ({
                  value: type,
                  label:
                    translate(type),
                })),
                onChange: (value) => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
              }
            }
          ),
          pure
        )(SelectWidget),
      },
    ],
  },
  contact_name: {
    inline: true,
    className:
      wishareClassNames.inline_2,
    title: 'contact name',
    children: [
      {
        name: 'first_name',
        component: compose(
          withTranslate,
          mapProps(
            ({
              form,
              name,
              value,
              onChange,
              translate,
            }) => ({
              name,
              value,
              onChange,
              placeholder: translate(
                'first name'
              ),
              addonBefore: (
                <SelectWidget
                  value={
                    _.find(
                      UserPrefixTitles,
                      (value) =>
                        value ===
                        form?.values
                          ?.prefix_title
                    ) ||
                    UserPrefixTitles.MR
                  }
                  onChange={(value) => {
                    onChange(
                      createSelectValue(
                        'prefix_title',
                        value
                      )
                    )
                  }}
                  className="select-before custom-auto-select">
                  {Object.values(
                    UserPrefixTitles
                  ).map((prefix) => (
                    <Select.Option
                      key={prefix}
                      value={prefix}>
                      {translate(
                        prefix
                      )}
                    </Select.Option>
                  ))}
                </SelectWidget>
              ),
            })
          ),
          pure
        )(Input),
      },
      {
        name: 'last_name',
        component:
          createControlledFormField({
            placeholder: 'last name',
          }),
      },
    ],
  },
  phone: {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'phone',
        name: 'phone',
        component:
          createControlledFormField({
            type: 'tel',
            placeholder: 'phone',
          }),
      },
      {
        label: 'alternative phone',
        name: 'phone2',
        component:
          createControlledFormField({
            type: 'tel',
            placeholder:
              'alternative phone',
          }),
      },
    ],
  },
  email: {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'email',
        name: 'email',
        component:
          createControlledFormField({
            type: 'email',
            placeholder: 'email',
          }),
      },
      {
        label: 'alternative email',
        name: 'email2',
        component:
          createControlledFormField({
            type: 'email',
            placeholder:
              'alternative email',
          }),
      },
    ],
  },
  address: {
    title: 'address',
    children: [
      {
        name: 'address',
        component:
          createControlledFormField({
            placeholder: 'address',
          }),
      },
    ],
  },
  organization: {
    children: [
      {
        name: 'partner_organization',
        component: ({
          name,
          form,
          value,
          onChange,
        }) => {
          const {
            partner_name,
            partner_organization_name,
            default_partner_organization,
          } = form?.values || {}
          return (
            <Translate>
              {(t) => (
                <NamePicker
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  defaultValue={
                    default_partner_organization
                  }
                  setFieldValue={
                    form.setFieldValue
                  }>
                  <React.Fragment>
                    {renderDecoratedLabel(
                      'partner name'
                    )}
                    <Pure
                      input={[
                        partner_name,
                        partner_organization_name,
                      ]}>
                      <AutoComplete
                        value={
                          partner_name
                        }
                        onChange={(
                          value
                        ) => {
                          onChange(
                            createValue(
                              'partner_name',
                              value
                            )
                          )
                        }}
                        {...{
                          className:
                            'w-full custom-auto-select',
                          placeholder:
                            t(
                              'partner name'
                            ),
                          allowClear: true,
                          options:
                            _.isEmpty(
                              partner_organization_name
                            )
                              ? undefined
                              : [
                                  {
                                    value:
                                      partner_organization_name,
                                  },
                                ],
                        }}
                      />
                    </Pure>
                  </React.Fragment>
                </NamePicker>
              )}
            </Translate>
          )
        },
      },
    ],
  },
}

const fetchPartner =
  ({
    id,
    deps,
    Component = Null,
    getItem = getResponseItem,
  }) =>
  (props) =>
    renderIf(
      id,
      <Async
        key={id}
        {...{
          deps,
          query: bindQueryParam({ id }),
          apiInfo: partners_getById_Api,
        }}>
        {({
          response,
          isLoading = false,
        }) => (
          <Component
            {...{
              isLoading,
              item: getItem(response),
            }}
            {...props}
          />
        )}
      </Async>
    )

const CreatePartner = ({
  item,
  onCancel = Null,
  onSubmit = Null,
  organization_id,
}) => {
  const t = useTranslate()

  const initialValues = useMemo(
    () => ({
      ...item,
      prefix_title: UserPrefixTitles.MR,
    }),
    [item]
  )

  const validate = (values) => {
    let errors = {}

    const { partner_name } = values

    if (_.isEmpty(partner_name)) {
      errors.partner_organization = t(
        'required field'
      )
    }

    return errors
  }

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          partner_tags,
          partner_organization,
          partner_organization_name,
          default_partner_organization,
          ...rest
        } = values
        onSubmit(
          _.omitBy(
            {
              ...rest,
              partner_tags: _.isEmpty(
                partner_tags
              )
                ? undefined
                : Array.from(
                    partner_tags
                  ).join(','),
            },
            _.isUndefined
          )
        )
      }}
      initialValues={initialValues}>
      <div className="flex flex-col space-y-2">
        <FieldsFactory
          formSchema={[
            formSchema.organization,
            formSchema.contact_name,
            formSchema.email,
            formSchema.phone,
            formSchema.address,
          ]}
        />
        <FormActionBar
          submitText="create"
          // background="background-100"
        />
      </div>
    </Formik>
  )
}

const EditPartner = ({
  item,
  onCancel = Null,
  onSubmit = Null,
  organization_id,
  isLoading = false,
}) => {
  const t = useTranslate()

  const initialValues = useMemo(() => {
    const { partner_organization } =
      item || {}
    return _.omitBy(
      {
        ...item,
        partner_organization_name:
          partner_organization
            ? getTitle(
                partner_organization
              )
            : undefined,
        default_partner_organization:
          partner_organization,
      },
      _.isUndefined
    )
  }, [item])

  const validate = (values) => {
    let errors = {}

    const { partner_name } = values

    if (_.isEmpty(partner_name)) {
      errors.partner_organization = t(
        'required field'
      )
    }

    return errors
  }

  return (
    <Formik
      validate={validate}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={!isLoading}
      onSubmit={(values) => {
        const {
          partner_tags,
          partner_organization,
          partner_organization_name,
          default_partner_organization,
          ...changed
        } = shallowDiff(
          values,
          initialValues
        )
        onSubmit(
          _.omitBy(
            {
              ...changed,
              partner_tags: _.isEmpty(
                partner_tags
              )
                ? undefined
                : Array.from(
                    partner_tags
                  ).join(','),
            },
            _.isUndefined
          )
        )
      }}
      initialValues={initialValues}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-2">
          <FieldsFactory
            formSchema={[
              formSchema.organization,
              formSchema.contact_name,
              formSchema.email,
              formSchema.phone,
              formSchema.address,
            ]}
          />
          <FormActionBar
            submitText="save"
            // background="background-100"
          />
        </div>
      </Spin>
    </Formik>
  )
}

export const usePartners = ({
  organization_id,
  onCreated = Null,
  onUpdated = Null,
  onDeleted = Null,
}) => {
  const t = useTranslate()

  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const [refreshKey, setRefreshKey] =
    useState()

  const onCancel = () => setAction({})

  const reload = () => {
    setRefreshKey(Date.now())
    onCancel()
  }

  const onError = notifyOnError(t)

  const {
    handleAsyncAction: onCreate,
  } = useAsyncAction({
    apiInfo: partners_addPartner_Api,
    query: bindQueryParam({
      id: organization_id,
    }),
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      onCreated(result, data)
      reload()
    },
  })

  const {
    handleAsyncAction: onUpdate,
  } = useAsyncAction({
    apiInfo: partners_editPartner_Api,
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      onUpdated(result, data)
      reload()
    },
  })

  const {
    handleAsyncAction: deletePartner,
  } = useAsyncAction({
    apiInfo: partners_deletePartner_Api,
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      onDeleted(result, data)
      reload()
    },
  })

  const handleDelete = (id) => {
    showDeleteConfirmDialog({
      onCancel,
      translate: t,
      title: 'delete partner',
      message:
        'are you sure you want to delete this partner',
      onOk: () => {
        deletePartner(
          {},
          bindQueryParam({
            id,
          })
        )
      },
    })
  }

  const handleAction = (
    type,
    params
  ) => {
    switch (type) {
      case CRUD.CREATE:
      case CRUD.UPDATE:
        setAction({
          type,
          value: params,
        })
        break
      case CRUD.DELETE:
        handleDelete(getId(params))
        break
      default:
        break
    }
  }

  const contextOptions = [
    {
      label: 'edit',
      key: CRUD.UPDATE,
      icon: <BsPencilSquare />,
    },
    {
      label: 'delete',
      key: CRUD.DELETE,
      icon: <BsTrash />,
    },
  ]

  const renderContextMenu = useCallback(
    (partner) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            handleAction(key, partner)
          }
          items={contextOptions}
        />
      </div>
    ),
    [contextOptions]
  )

  const modal = useMemo(() => {
    const { type, value } = action
    let modal_params = {}
    let params = {
      onSubmit: Null,
    }
    let Component = Null
    const partner_id = getId(value)
    switch (type) {
      case CRUD.CREATE:
        modal_params = {
          title: (
            <div className="text-center font-bold text-color-000 uppercase">
              {t('create partners')}
            </div>
          ),
        }
        params = {
          onSubmit: onCreate,
        }
        Component = CreatePartner
        break
      case CRUD.UPDATE:
        modal_params = {
          title: (
            <div className="text-center font-bold text-color-000 uppercase">
              {t('edit partners')}
            </div>
          ),
        }
        params = {
          onSubmit: (values) => {
            onUpdate(values, {
              id: partner_id,
            })
          },
        }
        Component = fetchPartner({
          id: partner_id,
          Component: EditPartner,
        })
        break
      default:
        return null
    }
    return (
      <Modal
        className="custom-modal"
        footer={null}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={action.type}
        width={modalWidth.medium}
        {...modal_params}>
        <AntdConfigWrapper>
          <Component
            {...{
              onCancel,
              organization_id,
            }}
            {...params}
          />
        </AntdConfigWrapper>
      </Modal>
    )
  }, [action, organization_id])

  return {
    modal,
    onCreate,
    onUpdate,
    refreshKey,
    handleAction,
    organization_id,
    renderContextMenu,
    onDelete: handleDelete,
  }
}

const UsePartners = ({
  children,
  ...props
}) => {
  return _.isFunction(children)
    ? children({})
    : children || null
}

export default UsePartners
