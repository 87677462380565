import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
} from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import {
  Null,
  renderSelf,
} from 'views/Shared'
import { actionBinding } from '../functions/routerHelper'

const TabContainer = ({
  children,
  headerHeight = 50,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const { hash } = useLocation()

  useLayoutEffect(() => {
    let timeout
    if (isSm && hash === '') {
      window.scrollTo(0, 0)
    } else if (isSm) {
      timeout = setTimeout(() => {
        const id = hash.replace('#', '')
        const element =
          document.getElementById(id)
        if (element) {
          // element.scrollIntoView()
          window.scroll(
            0,
            // window.scrollY -
            //   headerHeight
            element.offsetTop -
              headerHeight
          )
        }
      }, 0)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isSm, hash])

  return (
    <div
      style={{
        minHeight:
          'calc(100vh - (3 * var(--header-height) + 18px))',
      }}>
      {children}
    </div>
  )
}

const useNavRoutes = ({
  rootPath,
  defaultAction,
  transform = renderSelf,
}) => {
  const getPath =
    actionBinding(transform)

  const renderRedirect =
    useCallback(() => {
      return (
        <Redirect
          to={
            _.isEmpty(rootPath)
              ? '/'
              : getPath(
                  rootPath,
                  defaultAction
                )
          }
        />
      )
    }, [
      rootPath,
      defaultAction,
      getPath,
    ])

  const withRouteProps =
    (Component) =>
    ({ path, ...props }, index) =>
      (
        <Component
          {...{
            key: index,
            path: getPath(
              rootPath,
              path
            ),
            ...props,
          }}
        />
      )

  return [
    withRouteProps,
    renderRedirect,
  ]
}

export const NavRoutes = ({
  children,
  rootPath,
  routes = [],
  renderRoutes,
  defaultAction,
  transform = renderSelf,
  Wrapper = TabContainer,
}) => {
  const [
    withRouteProps,
    renderRedirect,
  ] = useNavRoutes({
    rootPath,
    transform,
    defaultAction,
  })

  if (_.isEmpty(routes)) return null

  if (renderRoutes) {
    return (
      <Wrapper>
        {renderRoutes(
          routes.map(
            withRouteProps(Route)
          ),
          <Route
            path="*"
            render={renderRedirect}
          />
        )}
        {children}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Switch>
        <Route
          exact={true}
          path={rootPath}
          render={() => {
            const defaultRoute = _.find(
              routes,
              ({ path }) =>
                path === defaultAction
            )
            const {
              component: Comp = Null,
            } = defaultRoute || {}
            return defaultRoute ? (
              <Comp />
            ) : (
              renderRedirect()
            )
          }}
        />
        {routes.map(
          withRouteProps(Route)
        )}
        <Route
          path="*"
          render={() => (
            <Redirect
              to={transform(rootPath)}
            />
          )}
        />
      </Switch>
      {children}
    </Wrapper>
  )
}

export default useNavRoutes
