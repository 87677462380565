import { recruitmentEventSchema } from 'apis/schema'
import { candidateSchema } from 'apis/schema/candidateSchema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const RecruitmentEntity = ({
  item,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={recruitmentEventSchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const RecruitmentEntities = ({
  items,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[recruitmentEventSchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export const CandidateEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={candidateSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const CandidateEntities = ({
  items,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={items}
    defaultItem={defaultItem}
    schema={[candidateSchema]}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export default RecruitmentEntity
