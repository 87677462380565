import React from 'react';

export const WishareHeartSvg = ({fill = "#2bade3", ...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 468.3 480"
      {...props}
    >
      <path
        fill={fill}
        d="M256 197.6C246 173.3 210 93 178.9 57.8c-67.2-75.9-131.7-35.4-148 18.7-46.9 155.8 99.4 327 178.4 401a68.1 68.1 0 0093.4 0c79-74 225.3-245.2 178.4-401-16.3-54.1-80.8-94.6-147.9-18.7C302 93 266 173.3 256 197.6"
        transform="translate(-21.8 -16)"
      ></path>
    </svg>
  );
};
