import {
  Button,
  DatePicker,
  Modal,
  Spin,
} from 'antd'
import {
  medal_addMedalReward_Api,
  medal_editMedalReward_Api,
  medal_getMedals_api,
} from 'apis'
import { getId } from 'apis/model/base'
import FieldsFactory from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, { useMemo } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {
  Null,
  shallowDiff,
} from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import ContainerPicker from 'views/Wishare/custom/ContainerPicker'
import { WishareEntities } from 'views/Wishare/enums'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'

export const MedalRewardModalTypes =
  Object.freeze({
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete',
  })

const newMedalReward = {
  notes: undefined,
  owner_id: undefined,
  owner_type: undefined,
  eff_date: moment(),
  medal_id: undefined,
}

const withFormSchema = ({
  owner_id,
  owner_type,
}) => [
  {
    title: 'effect date',
    children: [
      {
        name: 'eff_date',
        component: compose(
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
            }) => ({
              onChange: (
                date,
                dateString
              ) => {
                onChange(
                  createValue(
                    name,
                    date
                      ? moment(
                          new Date(date)
                        )
                      : undefined
                  )
                )
              },
              name,
              className: 'w-full',
              placeholder: translate(
                'effect date'
              ),
              defaultValue: value
                ? moment(
                    new Date(value)
                  )
                : undefined,
              suffixIcon: (
                <IoCalendarOutline />
              ),
              format:
                wishareConfigs.dateFormat,
            })
          )
        )(DatePicker),
      },
    ],
  },
  {
    title: 'notes',
    children: {
      name: 'notes',
      component:
        createControlledFormField({
          placeholder: 'notes',
        }),
    },
  },
  {
    children: {
      name: 'medal',
      component: withProps(
        ({
          name,
          value,
          onChange,
        }) => ({
          name,
          onChange,
          label: 'medal',
          allowClear: true,
          defaultValue: _.get(
            value,
            'id',
            value
          ),
          optionParams: {
            prefixCls: undefined,
          },
          type: WishareEntities.UNDEFINED,
          customConfigs: {
            apiInfo:
              medal_getMedals_api,
            query: bindQueryParams([
              {
                id: owner_id,
              },
              {
                prop: owner_type,
              },
            ]),
          },
        })
      )(ContainerPicker),
    },
  },
]

const validate =
  (translate) => (values) => {
    const { medal } = values || {}
    let errors = {}

    if (_.isEmpty(medal)) {
      errors.medal = translate(
        'required field'
      )
    }

    return errors
  }

const CreateMedalRewardModal = ({
  onCancel,
  owner_id,
  owner_type,
  receiver_id,
  receiver_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'give',
  initialValues = newMedalReward,
}) => {
  const t = useTranslate()

  const {
    isLoading = false,
    handleAsyncAction: createMedal,
  } = useAsyncAction({
    apiInfo: medal_addMedalReward_Api,
    query: bindQueryParams([
      {
        id: receiver_id,
      },
      { prop: receiver_type },
    ]),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
      }),
    [owner_id, owner_type]
  )

  return (
    <Formik
      validate={validate(t)}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={({
        medal_id,
        ...values
      }) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit((params) => {
          createMedal(params, {
            medal_id,
          })
        })({
          eff_date: moment(),
          ...changed,
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('give medal')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditMedalRewardModal = ({
  onCancel,
  owner_id,
  owner_type,
  receiver_id,
  receiver_type,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'update',
  initialValues = newMedalReward,
}) => {
  const t = useTranslate()

  const medal_reward_id = getId(
    initialValues
  )

  const {
    isLoading = false,
    handleAsyncAction: updateMedal,
  } = useAsyncAction({
    apiInfo: medal_editMedalReward_Api,
    query: bindQueryParam({
      id: medal_reward_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
      }),
    [owner_id, owner_type]
  )

  return (
    <Formik
      validate={validate(t)}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={({
        medal_id,
        ...values
      }) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit((params) => {
          updateMedal(params, {
            medal_id,
          })
        })({
          eff_date: moment(),
          ...changed,
        })
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit medal')}
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const MedalRewardActionModal = ({
  type,
  params,
  onCancel,
  onRefresh,
  onCreated,
  onUpdated,
  initialValues,
  isLoading = false,
  ...props
}) => {
  if (!!!type) return null

  let Component = Null

  let ModalWrapper = Modal

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case MedalRewardModalTypes.CREATE:
      Component = CreateMedalRewardModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case MedalRewardModalTypes.EDIT:
      Component = EditMedalRewardModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    default:
      break
  }

  const {
    receiver_id,
    receiver_type,
    owner_id,
    owner_type,
  } = params || {}

  const onSubmit =
    (handleSubmit = Null) =>
    ({
      medal,
      medal_id,
      medal_type,
      ...values
    }) => {
      handleSubmit(
        _.omitBy(
          {
            ..._.pick(
              values,
              Object.keys(
                newMedalReward
              )
            ),
          },
          _.isUndefined
        )
      )
    }

  return (
    <ModalWrapper
      {...{
        onCancel,
        className: 'custom-modal',
        right: true,
        footer: null,
        closable: false,
        visible: !!type,
        destroyOnClose: true,
      }}>
      {!!isLoading ? (
        <LoadingPage />
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            initialValues,
            owner_id,
            owner_type,
            receiver_id,
            receiver_type,
          }}
          {...asyncParams}
        />
      )}
    </ModalWrapper>
  )
}

export default MedalRewardActionModal
