import { Button } from 'antd'
import { create_loadMore_Api_action } from 'apis'
import { createTransform } from 'apis/utils/createTransform'
import LazyComponent from 'components/LazyComponent'
import Null from 'components/NullComponent'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { schema } from 'normalizr'
import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { BiLoaderCircle } from 'react-icons/bi'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import LoadingPage from 'views/LoadingPage'
import { createAsyncAction } from '..'
import { emptyObject } from '../../../helpers/emptyObjects'
import { getAsynCacheSelector } from '../selectors'
import { DidOnMount } from './DidOnMount'
import { LazyList } from './LazyList'

export const transformToRecordAction = (
  recordId,
  { apiInfo, ...rest }
) => {
  const recordSchema =
    new schema.Entity(
      'records',
      {
        responses: {
          [rest.asyncId]: {
            data: [apiInfo.schema],
          },
        },
      },
      {
        idAttribute: () => recordId,
        processStrategy: (e) => {
          const pagination = _.get(
            e,
            'meta.pagination',
            {}
          )
          const { links = {} } =
            pagination
          const { next: nextUrl } =
            links
          const done = !nextUrl
          return {
            id: recordId,
            ...pagination,
            update: Date.now(),
            asyncId: rest.asyncId,
            // entites,
            responses: {
              [rest.asyncId]: {
                id: rest.asyncId,
                asyncId: rest.asyncId,
                ...e,
                // result,
                success: true,
                nextUrl,
              },
            },
            nextUrl,
            done,
          }
        },
      }
    )
  return {
    ...rest,
    apiInfo: {
      ...apiInfo,
      transform: createTransform(
        recordSchema,
        'data'
      ),
    },
  }
}

export const CustomLazyPagination = ({
  startAction,
  id,
  renderEmpty = Null,
  renderNoMore,
  renderLoading = () => <LoadingPage />,
  query,
  children,
  renderLoadMore = null,
  renderItem = Null,
  apiInfo,
  auto = true,
  values,
  sample = 0,
  renderLoadMoreButton = null,
}) => {
  const t = useTranslate()
  const recordId = id || apiInfo.path
  const firstAction = useMemo(
    () =>
      startAction ||
      transformToRecordAction(
        recordId,
        createAsyncAction({
          prefixStr: recordId,
          query,
          values,
          apiInfo,
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordId]
  )
  const record = useSelector(
    (state) => {
      const records = _.get(
        state,
        `entities.records`,
        {}
      )
      return (
        records[recordId] || emptyObject
      )
    }
  )
  const {
    news = [],
    responses = [],
    nextUrl,
    asyncId,
    done,
  } = record

  const dispatch = useDispatch()
  const nextAction = useMemo(() => {
    return (
      nextUrl &&
      transformToRecordAction(
        recordId,
        create_loadMore_Api_action({
          prefixStr: recordId,
          path: nextUrl,
          apiInfo,
        })
      )
    )
  }, [apiInfo, nextUrl, recordId])
  useEffect(() => {
    if (
      !startAction &&
      (!record ||
        record === emptyObject)
    )
      dispatch(firstAction)
  }, [
    dispatch,
    firstAction,
    record,
    startAction,
  ])
  const [
    currentAsyncId,
    setCurrentAsyncId,
  ] = useState(firstAction.asyncId)
  const reachEnd =
    asyncId === currentAsyncId
  const asyncData = useSelector(
    (state) =>
      getAsynCacheSelector(
        state,
        (nextAction || firstAction)
          .asyncId
      )
  )

  const render = () => (
    <>
      {news.map((item) => (
        <SelectEntityItem
          key={item}
          item={item}
          schema={apiInfo.schema}>
          {renderItem}
        </SelectEntityItem>
      ))}
      <LazyList>
        {Object.values(responses).map(
          (item = {}, i) => {
            const {
              success,
              asyncId,
              data = [],
              nextUrl: url,
            } = item
            return (
              <Fragment key={asyncId}>
                {i === 0 &&
                  renderEmpty &&
                  _.isEmpty(data) &&
                  renderEmpty()}
                {(sample > 0
                  ? data.slice(
                      0,
                      sample
                    )
                  : data
                ).map((id, i) => (
                  <SelectEntityItem
                    key={
                      typeof id ===
                      'string'
                        ? id
                        : id.id
                    }
                    item={id}
                    schema={
                      apiInfo.schema
                    }>
                    {(item) =>
                      renderItem(item)
                    }
                  </SelectEntityItem>
                ))}
                <LazyComponent>
                  <DidOnMount
                    onMount={() => {
                      setCurrentAsyncId(
                        asyncId
                      )
                    }}>
                    <div />
                  </DidOnMount>
                </LazyComponent>
              </Fragment>
            )
          }
        )}
      </LazyList>
      {Boolean(renderLoadMore && nextUrl) &&
        renderLoadMore(
          () => {
            dispatch(nextAction)
          },
          reachEnd,
          asyncData
        )}
      {done
        ? renderNoMore && renderNoMore() : sample === 0 && (
            <div className="w-full">
              {!renderLoadMore &&
                (auto ? (
                  reachEnd && (
                    <LazyComponent>
                      <DidOnMount
                        onMount={() =>
                          dispatch(
                            nextAction
                          )
                        }>
                        <div />
                      </DidOnMount>
                    </LazyComponent>
                  )
                ) : (
                  <div className="flex justify-center pt-3 w-full">
                    {asyncData.isLoading ? (
                      <Button
                        ghost
                        className="border-none"
                        loading={
                          asyncData.isLoading
                        }
                      />
                    ) : (
                      <div className="w-full flex">
                        <div className="flex-1" />
                        {!!renderLoadMoreButton ? (
                          renderLoadMoreButton()
                        ) : (
                          <Button
                            onClick={(e) => {
                              e.preventDefault()
                              dispatch(nextAction)
                            }}
                            icon={<BiLoaderCircle />}
                            className="flex items-center gap-2 no-shadow no-text-shadow rounded-lg no-border">
                            {t('show more')}{' ...'}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              {auto
                ? !renderLoadMore &&
                  renderLoading()
                : renderNoMore &&
                  renderNoMore()}
              <div className="h-20"/>
            </div>
          )}
    </>
  )
  if (children) {
    return children(render, record)
  }
  return render()
}
export default CustomLazyPagination
