import React from "react";

export const Healthy = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 496.379 496.379"
		>
			<path
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="15"
				d="M240.451 412.977h-7.012"
			></path>
			<g>
				<path
					fill="#ffe6c4"
					d="M364.337 399.34l81.16-47.23c3.52-2.05 7.4-3.04 11.24-3.04 6.53 0 12.95 2.85 17.32 8.21 8.04 9.82 6.3 24.34-3.81 32l-120.96 91.5a72.455 72.455 0 01-43.69 14.66h-24.05a72.4 72.4 0 01-27.93-5.61l-88.92-37.18h-67.6l-55.54.17v-95.71h78.94a87.234 87.234 0 0161.68-25.55h2c23.13 0 45.31 9.19 61.67 25.55l5.14 5.13h66.12a49.43 49.43 0 0128.3 8.9 49.443 49.443 0 0118.1 23.51c.55 1.5.83 3.09.83 4.69z"
				></path>
				<path
					fill="#fed3a7"
					d="M471.561 354.685a22.8 22.8 0 01-1.314 1.085l-120.96 91.5a72.455 72.455 0 01-43.69 14.66h-24.05a72.4 72.4 0 01-27.93-5.61l-86.512-36.173a12.998 12.998 0 00-5.018-1.007h-64.99l-55.54.17v33.51l55.54-.17h67.6l88.92 37.18a72.4 72.4 0 0027.93 5.61h24.05c15.77 0 31.11-5.15 43.69-14.66l120.96-91.5c10.11-7.66 11.85-22.18 3.81-32a22.327 22.327 0 00-2.496-2.595z"
				></path>
				<path
					fill="#fed3a7"
					d="M351.764 421.737c9.612 0 18.889-6.567 24.132-12.501 5.582-6.318 8.095-12.997 7.143-18.916l-.037-1.843-18.091 10.528a25.82 25.82 0 01-.752.844c-4.03 4.301-9.454 7.192-12.913 6.864a7.437 7.437 0 00-.7-.033h-83.6a7.5 7.5 0 000 15h83.29c.51.039 1.018.057 1.528.057z"
				></path>
			</g>
			<path
				fill="#d4f2f6"
				d="M96.688 454.289v-34.69h.412v-67.695c0-8.75-7.094-15.844-15.844-15.844H24.763v137.637h56.494c8.75 0 15.844-7.093 15.844-15.844v-3.977z"
			></path>
			<path
				fill="#a7e6ef"
				d="M81.257 431.842H24.763v41.855h56.494c8.75 0 15.844-7.093 15.844-15.844v-41.855c-.001 8.751-7.094 15.844-15.844 15.844z"
			></path>
			<path
				fill="#a7e6ef"
				d="M24.763 496.379a7.5 7.5 0 01-7.5-7.5V320.981a7.5 7.5 0 0115 0v167.898a7.5 7.5 0 01-7.5 7.5z"
			></path>
			<path
				fill="#fd828a"
				d="M396.342 29.598c-39.465-39.464-103.449-39.464-142.913 0l-2.554 2.553-2.554-2.553c-39.464-39.464-103.449-39.464-142.913 0-39.464 39.464-39.464 103.449 0 142.913l127.846 127.846c9.732 9.732 25.51 9.732 35.241 0l127.846-127.846c39.465-39.464 39.465-103.448.001-142.913z"
			></path>
			<path
				fill="#fd6773"
				d="M167.386 172.512c-39.464-39.464-39.464-103.449 0-142.913 11.747-11.748 25.668-19.997 40.467-24.75-34.918-11.216-74.729-2.967-102.446 24.75-39.464 39.464-39.464 103.449 0 142.913l127.846 127.846c9.732 9.732 25.51 9.732 35.241 0l13.368-13.368z"
			></path>
			<circle cx="250.917" cy="149.752" r="89.813" fill="#f9f7f8"></circle>
			<path
				fill="#efedef"
				d="M167.386 172.512a102.255 102.255 0 01-4.483-4.791c7.119 35.055 34.655 62.693 69.655 69.963z"
			></path>
			<g fill="#fd828a">
				<path d="M295.285 135.571h-26.153v-26.153a3.844 3.844 0 00-3.844-3.844h-28.826a3.844 3.844 0 00-3.844 3.844v26.153h-26.153a3.844 3.844 0 00-3.844 3.844v28.826a3.844 3.844 0 003.844 3.844h26.153v26.153a3.844 3.844 0 003.844 3.844h28.826a3.844 3.844 0 003.844-3.844v-26.153h26.153a3.844 3.844 0 003.844-3.844v-28.826a3.845 3.845 0 00-3.844-3.844zM443.031 254.524h-16.678v-16.677a4.266 4.266 0 00-4.266-4.266H400.13a4.266 4.266 0 00-4.266 4.266v16.677h-16.678a4.266 4.266 0 00-4.266 4.266v21.957a4.266 4.266 0 004.266 4.266h16.678v16.678a4.266 4.266 0 004.266 4.266h21.957a4.266 4.266 0 004.266-4.266v-16.678h16.678a4.266 4.266 0 004.266-4.266V258.79a4.266 4.266 0 00-4.266-4.266zM59.803 254.524H76.48v-16.677a4.266 4.266 0 014.266-4.266h21.957a4.266 4.266 0 014.266 4.266v16.677h16.678a4.266 4.266 0 014.266 4.266v21.957a4.266 4.266 0 01-4.266 4.266h-16.678v16.678a4.266 4.266 0 01-4.266 4.266H80.746a4.266 4.266 0 01-4.266-4.266v-16.678H59.803a4.266 4.266 0 01-4.266-4.266V258.79a4.266 4.266 0 014.266-4.266z"></path>
			</g>
		</svg>
	);
}

export default Healthy;
