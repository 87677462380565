import {
  Button,
  Card,
  ConfigProvider,
  List,
  Modal,
  Spin,
} from 'antd'
import { getId } from 'apis/model/base'
import {
  profile_addLang_Api,
  profile_deleteLang_Api,
  profile_updateLang_Api,
} from 'apis/profile'
import { handleconfirm } from 'components/Confirm'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  IoAdd,
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { nest } from 'recompose'
import {
  getResponseItem,
  Null,
  renderOwnChild,
  updateArray,
} from 'views/Shared'
import {
  ObserverContext,
  ObserverProvider,
} from 'views/Shared/components/ObservedList'
import CRUD from 'views/Shared/enums/CRUD'
import { withAdvancedOptions } from 'views/Wishare/custom/AdvancedFormGroup'
import { RenderEmptyMeta } from 'views/Wishare/custom/AntdConfigWrapper'
import RadioOptions from 'views/Wishare/custom/RadioOptions'
import SelectDataSource from 'views/Wishare/custom/SelectDataSource'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  requiredNumber,
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'

export const LanguagesTypes =
  Object.freeze({
    VI: {
      label: 'vi',
      value: 'vi',
    },
    EN: {
      label: 'en',
      value: 'en',
    },
    FR: {
      label: 'fr',
      value: 'fr',
    },
    KM: {
      label: 'km',
      value: 'km',
    },
    KO: {
      label: 'ko',
      value: 'ko',
    },
  })

export const LanguageLevels =
  Object.freeze({
    BEGINNER: {
      label: 'beginer',
      value: 1,
    },
    INTERMEDIATE: {
      label: 'intermediate',
      value: 2,
    },
    ADVANCED: {
      label: 'advanced',
      value: 3,
    },
    PROFICIENT: {
      label: 'proficient',
      value: 9,
    },
  })

const formSchema = [
  {
    title: 'language',
    children: [
      {
        name: 'language',
        component:
          createControlledFormField({
            Component: SelectDataSource,
            placeholder: 'Select...',
            withValues: (values) => {
              const langs = Array.from(
                _.get(
                  values,
                  'languages'
                ) || []
              )
              return {
                defaultValue: _.get(
                  values,
                  'language'
                ),
                dataSource:
                  Object.values(
                    LanguagesTypes
                  ).filter(
                    ({ value }) =>
                      !langs.includes(
                        value
                      )
                  ),
              }
            },
          }),
      },
    ],
  },
  {
    title: 'level',
    children: [
      {
        name: 'level',
        component: withAdvancedOptions(
          Object.values(LanguageLevels)
        )(RadioOptions),
      },
    ],
  },
]

const renderItem =
  (actions) =>
  (
    { level, language, ...rest },
    index
  ) => {
    const { label } =
      _.find(
        Object.values(LanguageLevels),
        { value: level }
      ) || {}
    return (
      <Translate key={index}>
        {(t) => (
          <List.Item
            style={{
              padding: '0.25rem 0',
            }}
            extra={actions}>
            <List.Item.Meta
              className="custom-list-item"
              title={
                <span className="text-color-000 font-medium">
                  {t(language)}
                </span>
              }
              description={
                <span className="text-color-500 text-xs italic font-light">
                  {label
                    ? t(label)
                    : undefined}
                </span>
              }
            />
          </List.Item>
        )}
      </Translate>
    )
  }

const FormContainer = ({
  action,
  values,
  onCancel = Null,
  setValue = Null,
  handleSubmit = Null,
}) => {
  const t = useTranslate()

  const { addPost = Null } = useContext(
    ObserverContext
  )

  const onError = notifyOnError(t)

  const {
    type: action_type,
    value: current_item,
  } = action || {}

  const {
    isLoading: isCreating,
    handleAsyncAction: handleCreate,
  } = useAsyncAction({
    apiInfo: profile_addLang_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      addPost(getResponseItem(response))
    },
    onError,
  })

  const {
    isLoading: isUpdating,
    handleAsyncAction: handleUpdate,
  } = useAsyncAction({
    query: bindQueryParam({
      id: getId(current_item),
    }),
    apiInfo: profile_updateLang_Api,
    onSuccess: (
      result,
      { response }
    ) => {
      onCancel()
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      const newItem =
        getResponseItem(response)
      setValue(getId(newItem), newItem)
    },
    onError,
  })

  const params =
    action_type === CRUD.UPDATE
      ? {
          value: current_item,
          isLoading: isUpdating,
          onSubmit: handleUpdate,
        }
      : {
          value: '',
          isLoading: isCreating,
          onSubmit: handleCreate,
        }

  return (
    <LanguageForm
      {...params}
      action={action_type}
      languages={Array.from(
        values || []
      ).map(({ language }) => language)}
      handleSubmit={handleSubmit}
    />
  )
}

const LanguageForm = ({
  value,
  isLoading,
  languages,
  onSubmit = Null,
  handleSubmit = Null,
  action = CRUD.CREATE,
}) => {
  const t = useTranslate()

  const defaultValues = {
    level:
      LanguageLevels.BEGINNER.value,
  }

  const initialValues = {
    languages,
    level:
      _.get(value, 'level') ||
      defaultValues.level,
    language: _.get(value, 'language'),
  }

  return (
    <Spin spinning={!!isLoading}>
      <Formik
        enableReinitialize={true}
        validationSchema={yupShape({
          level: requiredNumber(t),
          language: requiredString(t),
        })}
        initialValues={initialValues}
        onSubmit={({
          languages,
          ...values
        }) => {
          handleSubmit(onSubmit)(values)
        }}>
        <FlexCol className="space-y-2">
          <FieldsFactory
            formSchema={formSchema}
          />
          <FormActionBar
            submitText={
              action === CRUD.CREATE
                ? 'create'
                : 'save'
            }
            background={false}
          />
        </FlexCol>
      </Formik>
    </Spin>
  )
}

const EditProfileLanguage = ({
  value,
  readOnly = false,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [currentAction, setAction] =
    useState({
      type: undefined,
      value: undefined,
    })

  const [languages, setLanguages] =
    useState(Array.from(value || []))

  const {
    posts = [],
    updatePost = Null,
    removePost = Null,
  } = useContext(ObserverContext)

  const {
    handleAsyncAction: deleteLang,
  } = useAsyncAction({
    apiInfo: profile_deleteLang_Api,
    onError: notifyOnError(t),
    onSuccess: (
      result,
      { response }
    ) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      const deletedId = getId(
        getResponseItem(response)
      )
      if (deletedId) {
        removePost(deletedId)
        setLanguages([
          ...languages.filter(
            (lang) =>
              getId(lang) !== deletedId
          ),
        ])
      }
    },
  })

  const remove = useCallback(
    (item) => {
      handleconfirm({
        title: t(
          'are you sure delete this'
        ),
        okText: t('sure'),
        cancelText: t('no'),
        okButtonProps: {
          type: 'primary',
            danger: true,
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          const id = getId(item)
          if (id) {
            deleteLang(
              {},
              bindQueryParam({ id })
            )
          }
        },
      })
    },
    [deleteLang]
  )

  const onCancel = () => {
    setAction({})
  }

  return (
    <Wrapper>
      <Card
        size="small"
        title={t('language')}
        style={{
            borderRadius: '0.5rem',
            border: '1px solid rgb(218 218 218)',
        }}
        headStyle={{
            fontStyle: 'italic',
            color: 'var(--text-color-400)',
            fontWeight: '500',
            background: 'var(--background-100)',
            borderBottom: 'none',
            borderRadius: '0.45rem 0.45rem 0 0',
        }}
        extra={
          readOnly ? undefined : (
            <Button
              ghost={true}
              size="small"
              type="primary"
              icon={<IoAdd />}
              onClick={() =>
                setAction({
                  type: CRUD.CREATE,
                })
              }
              className="flex items-center gap-1 rounded-md no-shadow no-text-shadow">
              <span className="text-xs">
                {t('add')}
              </span>
            </Button>
          )
        }>
        <ConfigProvider
          renderEmpty={RenderEmptyMeta}>
          <List
            dataSource={[
              ...languages,
              ...posts,
            ]}
            renderItem={(item, index) =>
              renderItem(
                readOnly ? undefined : (
                  <div className="flex gap-2">
                    <div
                      onClick={() =>
                        remove(item)
                      }
                      className="w-7 h-7 p-1 rounded border border-red-500 cursor-pointer flex items-center justify-center">
                      <IoTrashOutline className="text-red-500" />
                    </div>
                    <div
                      className="w-7 h-7 p-1 rounded border border-primary cursor-pointer flex items-center justify-center"
                      onClick={() => {
                        setAction({
                          type: CRUD.UPDATE,
                          value: item,
                        })
                      }}>
                      <IoCreateOutline className="text-primary" />
                    </div>
                  </div>
                )
              )(item, index)
            }
          />
        </ConfigProvider>
      </Card>
      <Modal
        className="custom-modal"
        footer={null}
        closable={false}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={currentAction.type}>
        <FormContainer
          values={languages}
          onCancel={onCancel}
          setValue={(id, value) => {
            const values = updateArray(
              languages
            )(id, value)
            if (_.isArray(values)) {
              updatePost(id, value)
              setLanguages(values)
            }
          }}
          action={currentAction}
          handleSubmit={(submit) =>
            (values) => {
              submit(values)
            }}
        />
      </Modal>
    </Wrapper>
  )
}

export default nest(
  ObserverProvider,
  EditProfileLanguage
)
