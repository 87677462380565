import {
  donationEventSchema,
  donationSchema,
  donorSchema,
} from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const DonationEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={donationEventSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const DonationEntities = ({
  items,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={items}
    defaultItem={defaultItem}
    schema={[donationEventSchema]}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export const DonationItemEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={donationSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const DonationItemEntities = ({
  items,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={items}
    defaultItem={defaultItem}
    schema={[donationSchema]}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export const DonorEntity = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    schema={donorSchema}
    defaultItem={defaultItem}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const DonorEntities = ({
  item,
  children,
  defaultItem,
}) => (
  <SelectEntityItem
    item={item}
    schema={[donorSchema]}
    defaultItem={defaultItem}>
    {(data = []) =>
      children(_.filter(data, notEmpty))
    }
  </SelectEntityItem>
)

export default DonationEntity
