import classNames from 'classnames'
import {convertToRaw} from 'draft-js'
import _ from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
import useTranslate from '../../modules/local/useTranslate'
import Toggle from '../Toggle'
import {BsCircleFill, BsDiamond, BsFillDiamondFill} from "react-icons/bs";

const headerTypes = ['header-one', 'header-two', 'header-three']
const headerIcons = [BsCircleFill, BsFillDiamondFill, BsDiamond]

const getEditorBlocks = editorState => {
  let blocks = []

  if (_.isEmpty(editorState)) {
    return blocks
  }

  try {
    const raw = convertToRaw(
      editorState.getCurrentContent()
    )
    return raw.blocks
  } catch (error) {
    return blocks
  }
}

const TableOfContents = ({
  editorState,
  className
}) => {
  const t = useTranslate()

  const blocks = getEditorBlocks(
    editorState
  )

  const result = blocks.filter(
    ({ type }) =>
      headerTypes.includes(type)
  )

  const itemsToShow = 3

  const blockSize = result.length

  const stopBy =
    blockSize < 2 * itemsToShow
      ? blockSize
      : Math.min(
          blocks.length,
          itemsToShow
        )

  const reachEnd = stopBy >= blockSize

  if (_.isEmpty(result)) {
    return null
  }

  return (
    <div
      className={classNames(
        className,
        'flex flex-col gap-1 px-3 py-4 border border-dashed rounded-lg background relative'
      )}>
      <p
        className="absolute background rounded-full border px-3 text-sm font-medium"
        style={{top: '-0.85em', left: '1.5em'}}>
        {t('table of contents')}
      </p>
      <Toggle>
        {(isToggle, toggle) => (
          <>
            {result
              .slice(0, isToggle ? blocks.length : stopBy)
              .map(({ type, text }, index) => {
                  const spaces = headerTypes.indexOf(type);
                  const BulletIcon = headerIcons[spaces];
                  return (
                    <Link
                      title={text}
                      replace
                      to={location => ({
                        ...location,
                        hash: text
                      })}
                      key={index}
                      className="flex items-start gap-1 no-underline">
                      {new Array(spaces).fill(1).map((__, index) => (
                        <span style={{paddingLeft: 2}} key={index}/>
                      ))}
                      <BulletIcon className="px-1"/>
                      <span className="flex-1 text-xs text-color-300 hover:text-primary italic leading-tight">
                        {text}
                      </span>
                    </Link>
                  )
                }
              )}
            {!reachEnd && (
              <>
                {!isToggle && (
                  <div className="block py-1 pr-3 pl-2 space-x-2 flex items-center flex-1 flex">
                    {'...'}
                  </div>
                )}
                <div className="text-center mt-2">
                  <div
                    onClick={toggle}
                    className="text-sm font-light italic capitalize px-3 cursor-pointer text-color-400">
                    {t(isToggle ? 'see less' : 'see more')}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Toggle>
    </div>
  )
}

export default TableOfContents
