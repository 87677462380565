import { giving_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { mapProps } from 'recompose'
import TargetCreator from 'views/Wishare/custom/TargetCreator'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import GivingContext from '../GivingContext'
import { givingStepFormSchema } from '../givingSchemas'

const formFields = [
  {
    name: 'conversion_required',
    defaultValue: 1,
  },
  {
    name: 'target',
  },
  {
    name: 'target_unit_name',
  },
  {
    name: 'no_limit_flag',
    defaultValue: 0,
  },
  {
    name: 'targets',
  },
  {
    name: 'giving_type',
  },
]

const formSchema = [
  ...givingStepFormSchema.targetStage,
  {
    title: 'detail',
    children: [
      {
        name: 'targets',
        component: mapProps(
          ({
            name,
            form,
            value,
            onChange,
          }) => {
            const type_cd = _.get(
              form,
              'values.giving_type'
            )
            const type_cds =
              wishareFieldTypes.giving
                .types
            return {
              name,
              value,
              onChange,
              type_cd,
              type_cds,
            }
          }
        )(TargetCreator),
      },
    ],
  },
]

const GivingSettingTarget = () => {
  const { giving } = useContext(
    GivingContext
  )

  const validationSchema = undefined

  const giving_id = getId(giving)

  const onPreSubmit = ({
    id,
    type,
    targets,
    target_units,
    giving_type,
    no_limit_flag,
    conversion_required,
    ...values
  }) => {
    const _targets = targets
      ? Array.from(targets).map(
          ({ unit_name, ...rest }) => ({
            unit_name,
            item_name: unit_name,
            ...rest,
          })
        )
      : undefined
    return {
      ...values,
      ...(_targets
        ? {
            targets:
              JSON.stringify(_targets),
          }
        : {}),
      ...(_.isNaN(Number(no_limit_flag))
        ? {}
        : {
            no_limit_flag: Number(
              no_limit_flag
            ),
          }),
      ...(_.isNaN(
        Number(conversion_required)
      )
        ? {}
        : {
            conversion_required: Number(
              conversion_required
            ),
          }),
    }
  }

  const initialized = useCallback(
    ({
      owner,
      giving_type,
      no_limit_flag,
      conversion_required,
      ...values
    }) => {
      const target_units =
        wishareFieldTypes.giving.units.filter(
          ({ type }) =>
            type === giving_type
        )
      return {
        ...values,
        no_limit_flag: !!no_limit_flag,
        conversion_required:
          !!conversion_required,
        target_units,
        giving_type,
        id: giving_id,
      }
    },
    [giving_id]
  )

  if (_.isEmpty(giving)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: giving,
    validationSchema,
    query: bindQueryParam({
      giving_id,
    }),
    onPreSubmit,
    initialized,
    useDefaultActionBar: false,
    apiInfo: giving_update_api,
  })
}

export default GivingSettingTarget
