import {Avatar, Button} from 'antd'
import {
  beneficiary_delete_api,
  beneficiary_getById_api,
  beneficiary_getGivings_api,
  beneficiary_getOrganizationBeneficiaries_api,
} from 'apis'
import {getId} from 'apis/model/base'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useCallback, useMemo, useState,} from 'react'
import {IoCreateOutline, IoDuplicateOutline, IoGitMerge, IoPeople, IoPerson, IoTrashOutline,} from 'react-icons/io5'
import {IoIosList, IoIosShareAlt,} from 'react-icons/io'
import {Link, useLocation,} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {branch, compose, fromRenderProps, pure, renderNothing, withProps,} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {formatDate, getFullAddress, getResponseItem, Null, renderElse, renderIf,} from 'views/Shared'
import {useDebounceSearch} from 'views/Wishare/custom/DebounceSearch'
import EntityList from 'views/Wishare/EntityList'
import {WishareEntities} from 'views/Wishare/enums'
import {showDeleteConfirmDialog} from 'views/Wishare/factory/createConfirmDialog'
import {ItemContextMenu} from 'views/Wishare/factory/createContextMenu'
import {BeneficiariesSortBy} from 'views/Wishare/factory/createEntitySortBy'
import {notifyOnError} from 'views/Wishare/factory/createErrorEvent'
import {NotificationActionTypes, successNotify,} from 'views/Wishare/factory/createNotification'
import {bindQueryParam} from 'views/Wishare/functions/routerHelper'
import GivingRecordItem from 'views/Wishare/Giving/components/GivingRecordItem'
import GivingEntity from 'views/Wishare/Giving/GivingEntity'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import {GenderTypes, getProp,} from 'views/Wishare/wishareFieldTypes'
import BeneficiaryActionModal, {BeneficiaryModalTypes,} from '../components/BeneficiaryActionModal'
import WorkspaceContext from '../WorkspaceContext'
import BeneficiariesAdvancedFilter from './BeneficiariesAdvancedFilter'
import {renderBeneficiary} from './renderBeneficiary'

const GivingList = ({
                      beneficiary_id,
                      status,
                      Header = Null,
                      Wrapper = 'div',
                    }) => {
  const t = useTranslate()
  const [keyword, ___, searchInput] =
    useDebounceSearch({
      timeout: 500,
      className: 'w-full p-2',
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header/>
      <div className="uppercase font-medium text-color-300 mb-2">
        {status === 1
          ? t('list of recipients')
          : t('register list')}
      </div>
      {searchInput}
      <EntityList
        key={keyword}
        query={bindQueryParam({
          id: beneficiary_id,
        })}
        values={_.omitBy(
          {
            keyword,
            status,
          },
          _.isUndefined
        )}
        apiInfo={
          beneficiary_getGivings_api
        }
        RenderItem={withProps({
          showEvent: true,
          showName: false,
          withoutContextMenu: true,
        })(GivingRecordItem)}
        RenderEntity={GivingEntity}
      />
    </Wrapper>
  )
}

const InsightsContent = ({
                           empty = null,
                           giving_items = [],
                         }) => {
  if (_.isEmpty(giving_items)) {
    return empty
  }

  return Array.from(giving_items).map(
    (item, index) => {
      const {
        item_name,
        unit_name,
        target_unit_name,
        total_giving_value = 0,
        total_giving_qtty = 0,
      } = item || {}
      return (
        <Translate key={index}>
          {(t) => (
            <div className="flex flex-col">
              <span className="italic text-xs font-medium text-color-200">
                {'- '}
                {item_name ===
                target_unit_name
                  ? t('money')
                  : t(item_name)}
                {' : '}
              </span>
              <div className="flex items-center ml-2 gap-1">
                <span className="text-sm text-primary font-semibold">
                  {Number(
                    total_giving_qtty
                  ).toLocaleString()}
                </span>
                <span className="text-sm text-primary font-semibold flex-1">
                  {unit_name}
                </span>
                {renderIf(
                  item_name !==
                  'blood' &&
                  item_name !==
                  target_unit_name,
                  <React.Fragment>
                    <span className="text-2xs italic font-light text-color-400">
                      {t(
                        'equivalent to'
                      )}
                    </span>
                    <span className="text-sm text-primary font-semibold">
                      {Number(
                        total_giving_value
                      ).toLocaleString()}{' '}
                      {target_unit_name}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Translate>
      )
    }
  )
}

const GivingModalHeader = ({
                             Wrapper = 'div',
                             beneficiary,
                           }) => {
  const t = useTranslate()
  const {owner} = beneficiary || {}
  const address = getFullAddress(
    _.get(beneficiary, 'location')
  )

  const {total_giving_items = []} =
  beneficiary || {}

  let gender = !!_.get(
    beneficiary,
    'gender'
  )
    ? getProp(
      Object.values(GenderTypes),
      'label'
    )(
      Number(
        _.get(beneficiary, 'gender')
      )
    )
    : null
  gender = !!gender ? t(gender) : null

  const subTitle = (
    <div className="flex items-center gap-2">
      <Link
        to={getLinkToDetail(
          _.get(beneficiary, 'owner')
        )}
        className="flex items-center text-xs gap-1 no-underline">
        {_.get(
          beneficiary,
          'owner_type'
        ) === 'user' ? (
          <IoPerson/>
        ) : (
          <IoPeople/>
        )}
        {_.get(
          beneficiary,
          'owner.name'
        ) ||
        _.get(
          beneficiary,
          'owner.title'
        )}
      </Link>
    </div>
  )

  const RowInformation = ({
                            label,
                            value,
                            hiddenIfEmpty = true,
                          }) =>
    !value && hiddenIfEmpty ? null : (
      <div className="flex items-baseline gap-2">
        <span className="text-xs text-color-400 italic font-light">
          {_.isString(label)
            ? `${t(label)}: `
            : label}
        </span>
        {value ? (
          <span className="text-xs text-color-000 font-medium flex-1">
            {value}
          </span>
        ) : (
          <span className="italic text-xs text-color-200 font-medium">
            {t('not added yet')}
          </span>
        )}
      </div>
    )

  return renderElse(
    _.isEmpty(beneficiary),
    <div className="flex flex-col">
      <div className="w-full border border-color-50 rounded-lg background">
        <div className="w-full font-bold flex items-center justify-center text-xl uppercase py-3">
          {t('beneficiaries')}
        </div>
        <div className="flex items-center p-3 gap-3 mx-3 rounded-lg border border-secondary">
          <Avatar
            size={35}
            src={getAvatar(beneficiary) || _.get(beneficiary, 'owner.avatar')}
            icon={<IoPerson size={23} className="text-color-300"/>}
            className="flex flex-center background-100"
          />
          <div className="flex flex-col">
            <Link
              to={getLinkToDetail(
                beneficiary
              )}
              className="cursor-pointer font-bold text-color-000 leading-tight max-lines-1">
              {getTitle(beneficiary)}
            </Link>
            <div className="italic text-xs text-secondary font-light">
              {_.get(
                beneficiary,
                'owner'
              ) ? (
                subTitle
              ) : (
                <span className="text-color-400">{`(${t(
                  'account not linked yet'
                )})`}</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col p-3">
          <div className="uppercase text-sm font-medium text-color-300 border-b mb-2">
            {t('detail information')}
          </div>
          <div className="space-y-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              <RowInformation
                label={'phone'}
                value={_.get(
                  beneficiary,
                  'phone_number'
                )}
              />
              <RowInformation
                label={'email'}
                value={_.get(
                  beneficiary,
                  'email'
                )}
              />
              <RowInformation
                label={'date of birth'}
                value={formatDate(
                  _.get(
                    beneficiary,
                    'date_of_birth'
                  )
                )}
              />
              <RowInformation
                label={'gender'}
                value={gender}
              />
            </div>
            <RowInformation
              label={'address'}
              value={address}
            />
            <RowInformation
              label={'notes'}
              value={_.get(
                beneficiary,
                'notes'
              )}
            />
          </div>
          {_.get(
            beneficiary,
            'extra_fields',
            []
          ).length > 0 && (
            <>
              <div className="uppercase text-xs font-medium text-color-400 border-b mb-2 mt-3">
                {t('extra information')}
              </div>
              <div className="space-y-1">
                {_.get(
                  beneficiary,
                  'extra_fields',
                  []
                )
                  .filter(
                    (e) =>
                      !!e.value ||
                      !!e.nvalue ||
                      !!e.dvalue
                  )
                  .map((e, index) => {
                    let value = e.value
                    if (!!e.dvalue)
                      value =
                        formatDate(
                          e.dvalue
                        )
                    if (!!e.nvalue)
                      value = Number(
                        e.nvalue
                      ).toLocaleString()
                    if (!!e.value)
                      value = e.value
                    return (
                      <RowInformation
                        key={index}
                        label={e.name}
                        value={value}
                      />
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      {_.get(
        beneficiary,
        'total_givings',
        []
      ).length > 0 && (
        <div className="mt-4">
          <div className="uppercase font-medium text-color-300 mb-2">
            {t('giving insights')}
          </div>
          <div className="p-3 flex flex-col gap-1 border border-color-50 rounded-lg">
            <InsightsContent
              empty={
                <span className="text-color-300 text-sm italic">
                  {t('not added yet')}
                </span>
              }
              giving_items={
                total_giving_items
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

const WorkspaceBeneficiariesList = ({
                                      Wrapper = 'div',
                                      organization_id,
                                      filter_actions = {
                                        sortBy: 'sort-by',
                                        keyword: 'keyword',
                                        filter_activity_id: 'activity-id',
                                        filter_giving_event_id:
                                          'giving-event-id',
                                      },
                                    }) => {
  const t = useTranslate()

  const location = useLocation()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const filter_activity_id =
    useSearchParam(
      filter_actions.filter_activity_id
    )

  const filter_giving_event_id =
    useSearchParam(
      filter_actions.filter_giving_event_id
    )

  const createContextOptions = (
    beneficiary
  ) => [
    {
      key: BeneficiaryModalTypes.GIVING_LIST,
      label: 'list of giving',
      icon: (
        <IoIosList
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.GIVING_REG_LIST,
      label: 'registered list',
      icon: (
        <IoIosList
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.EDIT,
      disabled:
        _.get(
          beneficiary,
          'role_cd'
        ) !== '1',
      label: 'edit',
      icon: (
        <IoCreateOutline
          size={16}
          className="text-color-300"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.MERGE,
      disabled: !(
        !_.get(beneficiary, 'owner') &&
        _.get(
          beneficiary,
          'role_cd'
        ) === '1'
      ),
      label: 'merge',
      icon: (
        <IoGitMerge
          size={16}
          className="text-primary"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.SHARE,
      disabled:
        _.get(
          beneficiary,
          'role_cd'
        ) !== '1',
      label: 'share',
      icon: (
        <IoIosShareAlt
          size={16}
          className="text-primary"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.CLONE,
      label: 'clone item',
      disabled:
        _.get(
          beneficiary,
          'role_cd'
        ) !== '0',
      icon: (
        <IoDuplicateOutline
          size={16}
          className="text-primary"
        />
      ),
    },
    {
      key: BeneficiaryModalTypes.DELETE,
      disabled:
        _.get(
          beneficiary,
          'role_cd'
        ) !== '1',
      label: 'delete',
      icon: (
        <IoTrashOutline
          size={16}
          className="text-red-500"
        />
      ),
    },
  ]

  const onCancel = () =>
    setAction({type: undefined})

  const {
    handleAsyncAction:
      deleteBeneficiary,
  } = useAsyncAction({
    apiInfo: beneficiary_delete_api,
    onError: notifyOnError(t),
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
  })

  const onMenuSelect = (
    key,
    beneficiary
  ) => {
    const beneficiary_id = _.get(
      beneficiary,
      'id'
    )
    switch (key) {
      case BeneficiaryModalTypes.EDIT:
      case BeneficiaryModalTypes.MERGE:
      case BeneficiaryModalTypes.SHARE:
      case BeneficiaryModalTypes.CLONE:
      case BeneficiaryModalTypes.GIVING_LIST:
      case BeneficiaryModalTypes.GIVING_REG_LIST:
        setAction({
          type: key,
          value: beneficiary_id,
        })
        break
      case BeneficiaryModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteBeneficiary(
                {},
                bindQueryParam({
                  id: beneficiary_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  const refresh_deps = useMemo(
    () => [
      organization_id,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [organization_id, location.state]
  )

  const renderContextMenu = useCallback(
    (beneficiary) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            onMenuSelect(
              key,
              beneficiary
            )
          }
          items={createContextOptions(
            beneficiary
          )}
        />
      </div>
    ),
    []
  )

  const RenderWrapper = useCallback(
    ({children}) => {
      switch (action.type) {
        case BeneficiaryModalTypes.EDIT:
        case BeneficiaryModalTypes.CLONE:
        case BeneficiaryModalTypes.SHARE:
        case BeneficiaryModalTypes.MERGE:
        case BeneficiaryModalTypes.GIVING_LIST:
        case BeneficiaryModalTypes.GIVING_REG_LIST:
          return (
            <Async
              {...{
                apiInfo:
                beneficiary_getById_api,
                query: bindQueryParam({
                  id: action.value,
                }),
              }}>
              {({
                  response,
                  isLoading,
                }) =>
                _.isFunction(children)
                  ? children({
                    isLoading,
                    initialValues:
                      getResponseItem(
                        response
                      ),
                  })
                  : children
              }
            </Async>
          )
        case BeneficiaryModalTypes.CREATE:
        default:
          return children({
            isLoading: false,
          })
      }
    },
    [action]
  )

  const renderModal = useCallback(
    (modal) => {
      if (
        action.type ===
        BeneficiaryModalTypes.GIVING_LIST ||
        action.type ===
        BeneficiaryModalTypes.GIVING_REG_LIST
      ) {
        return (
          <RenderWrapper>
            {({
                isLoading,
                initialValues:
                  selectedBeneficiary,
              }) => {
              return (
                <FullSideModalLayout
                  right={true}
                  onCancel={onCancel}
                  title={
                    <div className="text-center font-bold text-color-000">{t('view detail')}</div>
                  }>
                  {!!isLoading ? (
                    <LoadingPage/>
                  ) : (
                    <GivingList
                      beneficiary_id={
                        action.value
                      }
                      status={
                        action.type ===
                        BeneficiaryModalTypes.GIVING_LIST
                          ? 1
                          : action.type ===
                          BeneficiaryModalTypes.GIVING_REG_LIST
                          ? -1
                          : undefined
                      }
                      Header={withProps(
                        {
                          beneficiary:
                          selectedBeneficiary,
                        }
                      )(
                        GivingModalHeader
                      )}
                    />
                  )}
                </FullSideModalLayout>
              )
            }}
          </RenderWrapper>
        )
      } else {
        return modal
      }
    },
    [action]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
                 onFilterChange = Null,
               }) => {
        onFilterChange({
          filter_activity_id,
          filter_giving_event_id,
        })
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        beneficiary_getOrganizationBeneficiaries_api,
        query: bindQueryParam({
          organization_id,
        }),
        renderItem: renderBeneficiary({
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({
                  sortBy,
                  keyword,
                  filterBy,
                }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
          JSON.stringify(filterBy),
        ].join('/'),
      filter_params: {
        owner_id: organization_id,
        owner_type:
        WishareEntities.ORGANIZATION,
      },
      query_params: filter_actions,
      dependencies: [organization_id],
      sortHeader: t('beneficiary list'),
      SortByWidget: BeneficiariesSortBy,
      AdvancedFilter:
      BeneficiariesAdvancedFilter,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="beneficiaries"
        extra={
          <Button
            type="primary"
            className="no-border rounded-lg no-shadow no-text-shadow"
            onClick={() => {
              setAction({
                type: BeneficiaryModalTypes.CREATE,
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
      {action.type &&
      renderModal(
        <NavigationContext.Consumer>
          {({history}) => {
            const refresh = () => {
              onCancel()
              history.push({
                state: {
                  refreshToken:
                    Date.now(),
                },
                search:
                location.search,
              })
            }
            return (
              <RenderWrapper>
                {({
                    isLoading,
                    initialValues,
                  }) => (
                  <BeneficiaryActionModal
                    type={action.type}
                    onCancel={
                      onCancel
                    }
                    params={{
                      organization_id,
                    }}
                    onUpdated={() =>
                      onCancel()
                    }
                    onMerged={refresh}
                    onCreated={
                      refresh
                    }
                    isLoading={
                      isLoading
                    }
                    initialValues={
                      initialValues
                    }
                  />
                )}
              </RenderWrapper>
            )
          }}
        </NavigationContext.Consumer>
      )}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({organization}) => ({
      organization_id: getId(
        organization
      ),
    })
  ),
  branch(
    ({organization_id}) =>
      _.isEmpty(organization_id),
    renderNothing
  ),
  pure
)(WorkspaceBeneficiariesList)
