import Icon from '@ant-design/icons'
import {
  Button,
  Input,
  Select,
} from 'antd'
import classnames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  AiOutlineGlobal,
  AiOutlineLink,
} from 'react-icons/ai'
import {
  BsFacebook,
  BsLinkedin,
  BsTwitter,
} from 'react-icons/bs'
import {
  MdOutlineAdd,
  MdOutlineRemove,
} from 'react-icons/md'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import './LinkedPages.css'

const LinkedOptions = Object.freeze({
  FACEBOOK: 'facebook',
  LINKED_IN: 'linkedin',
  TWITTER: 'twitter',
  WEBSITE: 'website',
})

export const LinkedPagesSizes =
  Object.freeze({
    LARGE: 'large',
    DEFAULT: undefined,
  })

const availablePages = [
  LinkedOptions.FACEBOOK,
  LinkedOptions.LINKED_IN,
  LinkedOptions.TWITTER,
]

const fromPage = (page) => {
  switch (page) {
    case LinkedOptions.FACEBOOK:
      return {
        inputPlaceholder: 'FB url...',
      }
    case LinkedOptions.LINKED_IN:
      return {
        inputPlaceholder:
          'LinkedIn url...',
      }
    case LinkedOptions.TWITTER:
      return {
        inputPlaceholder:
          'Twitter url...',
      }
    default:
      return {}
  }
}

export const getPageIcon = (page) => {
  switch (page) {
    case LinkedOptions.FACEBOOK:
      return <BsFacebook />
    case LinkedOptions.LINKED_IN:
      return <BsLinkedin />
    case LinkedOptions.TWITTER:
      return <BsTwitter />
    case LinkedOptions.WEBSITE:
      return <AiOutlineGlobal />
    default:
      return <AiOutlineLink />
  }
}

function mapPages(
  pages,
  [name, value]
) {
  return pages.map((item) => ({
    [name]: item[name],
    [value]: item[value],
  }))
}

const RenderChildOnly = ({
  children,
}) => children

const WithChild =
  (props) => (Child) => {
    return <Child {...props} />
  }

const NewPageItem = ({
  className,
  filterOption,
  onClick = Null,
  options = [],
  size = LinkedPagesSizes.DEFAULT,
}) => {
  const [value, setValue] =
    useState(null)

  const t = useTranslate()

  const { isSm } = useContext(
    LayoutContext
  )

  const newLine = (value) => {
    onClick(value)
  }

  const notFoundContent = useMemo(
    () =>
      isSm ? (
        <div
          onClick={
            isSm
              ? () => {
                  if (
                    !_.isEmpty(value)
                  ) {
                    newLine(value)
                  }
                }
              : undefined
          }>
          {`${t(
            'create new'
          )} "${value}"`}
        </div>
      ) : (
        t(
          'press enter to create new page'
        )
      ),
    [t, isSm, value]
  )

  return (
    <Input.Group
      compact={true}
      className={classnames(
        'AddOn w-full',
        className
      )}>
      <SelectWidget
        {...{
          size,
          notFoundContent,
          showSearch: true,
          defaultValue: value,
          optionFilterProp: 'children',
          onChange: (val) => {
            setValue(val)
          },
          onSearch: (val) => {
            setValue(val)
          },
          onSelect: (page) => {
            newLine(page)
          },
          onInputKeyDown: (e) => {
            if (e.key === 'Enter') {
              const { value } = e.target
              newLine(value)
            }
          },
          className: 'w-full',
          filterOption: filterOption,
          children: options.map(
            (page) => {
              return (
                <Select.Option
                  key={page}
                  value={page}>
                  {page}
                </Select.Option>
              )
            }
          ),
          placeholder: t(
            'add new page'
          ),
        }}
      />
      <Button
        type="primary"
        size={size}
        disabled={_.isEmpty(value)}
        onClick={() => {
          newLine(value)
        }}>
        <Icon
          component={MdOutlineAdd}
        />
      </Button>
    </Input.Group>
  )
}

const LinkedPages = ({
  data = [],
  classNames = [],
  onChange = Null,
  onSearch = Null,
  fieldName = 'page',
  fieldValue = 'url',
  getPages = mapPages,
  renderPrimary = WithChild,
  renderSecondary = WithChild,
  renderWrapper = RenderChildOnly,
  size = LinkedPagesSizes.DEFAULT,
}) => {
  const t = useTranslate()

  const { isSm } = useContext(
    LayoutContext
  )

  const fields = [fieldName, fieldValue]

  const defaultValues = getPages(
    data,
    fields
  )

  const filterOption = (
    input,
    { children }
  ) => {
    const left = children.toLowerCase()
    const right = input.toLowerCase()
    return left.indexOf(right) >= 0
  }

  const [currentPages, setPages] =
    useState()

  const [isReady, setReady] =
    useState(false)

  const [autoFocus, setAutoFocus] =
    useState(false)

  useEffect(() => {
    if (!isReady) {
      setReady(true)
    } else if (
      !_.isEqual(
        currentPages,
        defaultValues
      )
    ) {
      setPages(defaultValues)
      setReady(false)
    }
  }, [data, isReady])

  const existingPage = (page) =>
    _.find(currentPages, { page })

  const addOther = (page) => {
    if (_.isEmpty(page)) {
      return
    }
    if (!existingPage(page)) {
      const newPages = [
        ...currentPages,
        {
          [fieldName]: page,
          [fieldValue]: '',
        },
      ]
      setPages(newPages)
      setAutoFocus(true)
    }
  }

  const remove = (selectedPage) => {
    if (existingPage(selectedPage)) {
      const newPages = Array.from(
        currentPages || []
      ).filter(
        ({ page }) =>
          !_.isEqual(page, selectedPage)
      )
      setPages(newPages)
      onChange(newPages)
    }
  }

  const handleChange = (
    target,
    item
  ) => {
    let newPages = currentPages
    const { page, value } = item

    if (target === fieldName) {
      if (existingPage(page)) {
        newPages = newPages.map((e) => {
          return _.isEqual(e.page, page)
            ? {
                [fieldName]: value,
                [fieldValue]: e.url,
              }
            : e
        })
      } else {
        newPages = [
          ...newPages,
          {
            [fieldName]: page,
            [fieldValue]: '',
          },
        ]
      }
    } else if (target === fieldValue) {
      newPages = newPages.map((e) => {
        return _.isEqual(e.page, page)
          ? {
              [fieldName]: e.page,
              [fieldValue]: value,
            }
          : e
      })
    }

    setPages(newPages)
    onChange(newPages)
  }

  const Wrapper = useCallback(
    (props) => {
      return renderWrapper({
        ...props,
      })
    },
    []
  )

  const [pageClassName, urlClassName] =
    classNames

  const isNewLine = (page) => {
    return !_.includes(
      currentPages,
      page
    )
  }

  const hasError = (page) => {
    const error = _.get(
      existingPage(page),
      'error'
    )
    return error ? 'error' : null
  }

  return (
    <Wrapper>
      {(
        currentPages || defaultValues
      ).map((item, index) => {
        const page = _.get(
          item,
          fieldName
        )
        const url = _.get(
          item,
          fieldValue
        )

        const newLine = isNewLine(page)

        const { inputPlaceholder } =
          fromPage(page)

        return (
          <div
            key={index}
            className={classnames(
              pageClassName
            )}>
            <Input.Group
              compact={true}
              className={classnames(
                'AddOn',
                urlClassName
              )}>
              {renderPrimary({
                size,
                key: currentPages,
                showSearch: true,
                defaultValue: page,
                notFoundContent: '',
                placeholder: t(
                  'select page'
                ),
                style: {
                  minWidth: isSm
                    ? 120
                    : 150,
                },
                optionFilterProp:
                  'children',
                onChange: (value) => {
                  handleChange(
                    fieldName,
                    {
                      page,
                      value,
                    }
                  )
                },
                onInputKeyDown: (e) => {
                  if (
                    e.key === 'Enter'
                  ) {
                    const { value } =
                      e.target
                    addOther(value)
                  }
                },
                onSearch: onSearch,
                filterOption:
                  filterOption,
                children: availablePages
                  .filter(
                    (e) =>
                      !existingPage(e)
                  )
                  .map((page) => {
                    return (
                      <Select.Option
                        key={page}
                        value={page}>
                        {page}
                      </Select.Option>
                    )
                  }),
              })(SelectWidget)}
              {renderSecondary({
                size,
                key: isReady,
                disabled: !isReady,
                defaultValue: url,
                allowClear: true,
                autoFocus: autoFocus,
                status: hasError(page),
                placeholder:
                  inputPlaceholder,
                onChange: (e) => {
                  handleChange(
                    fieldValue,
                    {
                      page,
                      value:
                        e.target.value,
                    }
                  )
                },
              })(Input)}
              {newLine && (
                <Button
                  type="primary"
                  size={size}
                  onClick={() => {
                    remove(page)
                  }}>
                  <Icon
                    component={
                      MdOutlineRemove
                    }
                  />
                </Button>
              )}
            </Input.Group>
          </div>
        )
      })}
      <NewPageItem
        key={
          (currentPages || []).length
        }
        size={size}
        onClick={addOther}
        options={availablePages.filter(
          (e) => !existingPage(e)
        )}
        filterOption={filterOption}
      />
    </Wrapper>
  )
}

export default LinkedPages
