import { getId } from 'apis/model/base'
import { baseItemSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React, {
  useMemo,
  useState,
} from 'react'
import { renderSelf } from '../typedefs'

export const updateArray =
  (array = []) =>
  (id, value) => {
    if (_.isEmpty(array)) return array
    return array.map((item) => {
      if (getId(item) === id) {
        return {
          ...item,
          ...value,
        }
      }
      return item
    })
  }

const IsDeleted = (e) =>
  !!_.get(e, 'deleted')

const useObservedList = ({
  isDeleted = IsDeleted,
  renderItem = renderSelf,
  schema = baseItemSchema,
}) => {
  const [newItems, setNewItems] =
    useState([])

  const visibleItems = useMemo(() => {
    if (_.isEmpty(newItems)) return []
    return newItems.filter(
      (e) => !!!isDeleted(e)
    )
  }, [newItems, isDeleted])

  const add = (result) => {
    if (!_.isEmpty(result)) {
      setNewItems([...newItems, result])
    }
  }

  const remove = (id) => {
    setNewItems(
      newItems.filter(
        (item) => getId(item) !== id
      )
    )
  }

  const update = (id, value) => {
    setNewItems(
      newItems.map((item) => {
        if (getId(item) === id) {
          return {
            ...item,
            ...value,
          }
        }
        return item
      })
    )
  }

  const clear = () => {
    setNewItems([])
  }

  const component = useMemo(
    () => (
      <SelectEntityItem
        key={visibleItems.map(getId)}
        item={visibleItems}
        defaultItem={visibleItems}
        schema={[schema]}>
        {(list = []) =>
          list.map(renderItem)
        }
      </SelectEntityItem>
    ),
    [visibleItems, schema, renderItem]
  )

  return {
    add,
    clear,
    update,
    remove,
    component,
    visibleItems,
  }
}

export default useObservedList
