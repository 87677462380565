import { List } from 'antd'
import { auth_getUserProfile_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import AppIcon from 'components/AppIcon'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import Translate from 'modules/local/Translate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {
  useContext,
} from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItem,
  Null,
  renderOwnChild,
  renderSelf,
} from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import { ConversationWith } from 'views/Wishare/factory/createChatButton'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentCandidateProfile from 'views/Wishare/Recruitment/components/RecruitmentCandidateProfile'
import { GenderTypes } from 'views/Wishare/wishareFieldTypes'
import UserContext from '../UserContext'

const JobProfiles = compose(
  withTranslate,
  mapProps(
    ({
      item,
      translate,
      Meta = Null,
    }) => ({
      profile: item,
      Wrapper: ({ children }) => (
        <div
          id="job-profiles-wrapper"
          className="p-2">
          <span className="font-medium uppercase text-color-300">
            {translate(
              'basic information'
            )}
          </span>
          <div className="px-2 md:px-4">
            {item && <Meta {...item} />}
          </div>
          <div className="font-medium uppercase text-color-300 mb-3 mt-6">
            {translate(
              'detail information'
            )}
          </div>
          <div className="space-y-3 px-2 md:px-4">
            {children}
          </div>
        </div>
      ),
    })
  )
)(RecruitmentCandidateProfile)

const renderMeta = (
  user,
  profile,
  isSm
) => {
  const renderItem = (
    {
      label,
      value,
      translate = renderSelf,
    },
    index
  ) => (
    <React.Fragment key={index}>
      {!!value && (
        <div className="flex items-center">
          <span className="text-xs text-color-500 italic font-light">
            {`${translate(label)} : `}
          </span>
          <span className="text-sm font-medium text-color-000 ml-2">
            {value}
          </span>
        </div>
      )}
    </React.Fragment>
  )

  return (
    <Translate>
      {(t) => (
        <React.Fragment>
          <div className="flex items-center mt-2">

              <div className="flex items-center flex-1 gap-2">
                  <AppIcon
                      item={user}
                      size={40}
                  />
                  <div className="flex flex-col flex-1">
                      <span className="font-bold text-color-000">
                        {getTitle(user)}
                      </span>
                      <span className="text-xs italic font-light text-secondary">
                        {t(getType(user))}
                      </span>
                  </div>
              </div>
              <ConversationWith
                  params={{
                      receiver_id:
                          getId(user),
                  }}
              />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 px-1 mt-3">
            {[
              // {
              //   name: 'email',
              //   label: 'email',
              //   transform: renderMail,
              // },
              // {
              //   name: 'phone_number',
              //   label: 'phone',
              //   transform: renderPhone,
              // },
              {
                name: 'gender',
                label: 'gender',
                transform: (value) => {
                  const { label } =
                    _.find(
                      Object.values(
                        GenderTypes
                      ),
                      {
                        value:
                          Number(value),
                      }
                    ) || {}
                  return label
                    ? t(label)
                    : null
                },
              },
              {
                name: 'date_of_birth',
                label: 'birthday',
                transform: (value) =>
                  value
                    ? moment(
                        value
                      ).format(
                        wishareConfigs.dateFormat
                      )
                    : null,
              },
            ].map(
              (
                {
                  name,
                  label,
                  transform = renderSelf,
                },
                index
              ) => {
                const _value =
                  transform(
                    _.get(profile, name)
                  )
                return _value
                  ? renderItem(
                      {
                        label,
                        value: _value,
                        translate: t,
                      },
                      index
                    )
                  : null
              }
            )}
          </div>
        </React.Fragment>
      )}
    </Translate>
  )
}

export const UserJobProfile = ({
  user,
  Header = Null,
  Footer = Null,
  Wrapper = renderOwnChild,
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const { response, isLoading } =
    useAsync({
      query: bindQueryParam({
        username: getId(user),
      }),
      apiInfo: auth_getUserProfile_Api,
    })

  if (!!isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <LoadingPage />
      </div>
    )
  }

  const profile =
    getResponseItem(response)

  return (
    <Wrapper>
      <Header />
      <JobProfiles
        item={profile}
        Meta={(item) =>
          renderMeta(user, item, isSm)
        }
      />
      <Footer />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    UserContext.Consumer,
    ({ user }) => ({ user })
  )
)(UserJobProfile)
