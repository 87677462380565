import { PlusOutlined } from '@ant-design/icons'
import { Button, List } from 'antd'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { IoTrashOutline } from 'react-icons/io5'
import {
  deepTranslate,
  Null,
} from 'views/Shared'
import DonationCustomItemType from '../Donation/ActionForms/DonationCustomItemType'
import GivingCustomItemType from '../Giving/ActionForms/GivingCustomItemType'
import {
  getDefaultFieldValue,
  wishareFieldTypes,
} from '../wishareFieldTypes'
import classnames from "classnames";

export const DynamicFieldsContext =
  React.createContext({})

const prefix = '__dynamic__'

function generateId(prefix) {
  return prefix + _.uniqueId()
}

const DynamicFieldsProvider = ({
  children,
  params = {},
  initialArray = [],
  notifyListener = Null,
  name = 'dynamic_array',
  canRemove = (array) =>
    Array.from(array).length > 1,
}) => {
  const [array, setArray] = useState(
    Array.from(initialArray || []).map(
      (item) => ({
        id: generateId(prefix),
        ...item,
      })
    )
  )

  const getByIndexKey = useCallback(
    (id) => _.find(array, { id }),
    [array]
  )

  const createRow = useCallback(
    (data = {}) => {
      const id = _.get(
        data,
        'id',
        generateId(prefix)
      )
      const newArray = [
        ...array,
        Object.assign(
          {},
          {
            id,
            ...data,
          }
        ),
      ]
      setArray(newArray)
      notifyListener(newArray)
    },
    [array]
  )

  const deleteRow = useCallback(
    (id) => {
      const itemFound =
        getByIndexKey(id)
      if (itemFound) {
        const newArray = array.filter(
          (obj) => obj.id !== id
        )
        setArray(newArray)
        notifyListener(newArray)
      }
    },
    [array]
  )

  const updateRow = useCallback(
    (id) => (data) => {
      const newArray = array.map(
        (obj) => {
          if (obj.id === id) {
            return {
              ...obj,
              ...(data || {}),
            }
          }
          return obj
        }
      )
      setArray(newArray)
      notifyListener(newArray)
    },
    [array]
  )

  return (
    <DynamicFieldsContext.Provider
      value={{
        params,
        createRow,
        updateRow,
        deleteRow,
        rows: array,
        getByIndexKey,
        canRemove: Boolean(
          canRemove(array)
        ),
      }}>
      {children}
    </DynamicFieldsContext.Provider>
  )
}

export const createDynamicFields =
  ({
    name,
    value,
    params,
    notifyListener,
  }) =>
  (Component) =>
    (
      <DynamicFieldsProvider
        name={name}
        params={params}
        initialArray={Array.from(
          value || []
        )}
        notifyListener={notifyListener}>
        <Component />
      </DynamicFieldsProvider>
    )

const DonationDynamicFields = () => {
  const t = useTranslate()

  const {
    rows,
    params,
    createRow,
    updateRow,
    deleteRow,
    canRemove = true,
  } = useContext(DynamicFieldsContext)

  const {
    donation_type,
    target_unit_name,
  } = params || {}

  const options = useMemo(() => {
    const itemFound = Array.from(
      wishareFieldTypes.donation
        .types || []
    ).find(
      ({ value }) =>
        value === donation_type
    )
    if (itemFound) {
      const result = itemFound.type_cds
      return result.map(
        deepTranslate(t)
      )
    } else {
      return []
    }
  }, [t, donation_type])

  const defaultType = useMemo(
    () => getDefaultFieldValue(options),
    [options]
  )

  const items = Array.from(rows || [])

  return (
    <div className="p-2 border border-color-50 rounded-lg space-y-3 background">
      <div className="flex flex-col space-y-3">
        {items.map(
          ({ id, ...value }) => (
            <List.Item
              key={id}
              className="relative p-0 border-b border-color-50 pb-3">
              <DonationCustomItemType
                type_options={options}
                donation_item={value}
                onItemChange={updateRow(
                  id
                )}
                donation_type={
                  donation_type
                }
                target_unit_name={
                  target_unit_name
                }
                renderItemName={
                  undefined
                }
                actions={[
                  canRemove ? (
                    <div
                      onClick={() => {
                        deleteRow(id)
                      }}
                      className="flex items-center space-x-1 text-red-500 cursor-pointer">
                      <IoTrashOutline />
                      <span className="text-xs">
                        {t('remove')}
                      </span>
                    </div>
                  ) : null,
                ].map((item, index) => (
                  <React.Fragment
                    key={index}>
                    {item}
                  </React.Fragment>
                ))}
              />
            </List.Item>
          )
        )}
      </div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          createRow({
            type_cd: defaultType,
          })
        }}
        className="rounded-lg no-shadow no-text-shadow">
        {t('add')}
      </Button>
    </div>
  )
}

const GivingDynamicFields = () => {
  const t = useTranslate()

  const {
    rows,
    params,
    createRow,
    updateRow,
    deleteRow,
    canRemove = true,
  } = useContext(DynamicFieldsContext)

  const {
    giving_type,
    target_unit_name,
  } = params || {}

  const options = useMemo(() => {
    const itemFound = Array.from(
      wishareFieldTypes.giving.types ||
        []
    ).find(
      ({ value }) =>
        value === giving_type
    )
    if (itemFound) {
      const result = itemFound.type_cds
      return result.map(
        deepTranslate(t)
      )
    } else {
      return []
    }
  }, [t, giving_type])

  const defaultType = useMemo(
    () => getDefaultFieldValue(options),
    [options]
  )

  const items = Array.from(rows || [])

  return (
    <div className="p-2 border border-color-50 rounded-lg space-y-3 background">
      <div className="flex flex-col space-y-3">
        {items.map(
          ({ id, ...value }) => (
            <List.Item
              key={id}
              className="relative p-0 border-b border-color-50 pb-3">
              <GivingCustomItemType
                type_options={options}
                giving_item={value}
                onItemChange={updateRow(
                  id
                )}
                giving_type={
                  giving_type
                }
                target_unit_name={
                  target_unit_name
                }
                renderItemName={
                  undefined
                }
                actions={[
                  canRemove ? (
                    <div
                      onClick={() => {
                        deleteRow(id)
                      }}
                      className="flex items-center space-x-1 text-red-500 cursor-pointer">
                      <IoTrashOutline />
                      <span className="text-xs">
                        {t('remove')}
                      </span>
                    </div>
                  ) : null,
                ].map((item, index) => (
                  <React.Fragment
                    key={index}>
                    {item}
                  </React.Fragment>
                ))}
              />
            </List.Item>
          )
        )}
      </div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          createRow({
            type_cd: defaultType,
          })
        }}
        className="rounded-lg no-shadow no-text-shadow">
        {t('add')}
      </Button>
    </div>
  )
}

export const DonationAdminDynamicFields =
  ({ name, form, value, onChange }) => {
    const { values } = form || {}
    const params = _.pick(values, [
      'donation_type',
      'target_unit_name',
    ])
    return createDynamicFields({
      name,
      value,
      params,
      notifyListener: (array = []) => {
        const value = array.map(
          (item) => _.omit(item, ['id'])
        )
        onChange(
          createValue(name, value)
        )
      },
    })(DonationDynamicFields)
  }

export const GivingAdminDynamicFields =
  ({ name, form, value, onChange }) => {
    const { values } = form || {}
    const params = _.pick(values, [
      'giving_type',
      'target_unit_name',
    ])
    return createDynamicFields({
      name,
      value,
      params,
      notifyListener: (array = []) => {
        const value = array.map(
          (item) => _.omit(item, ['id'])
        )
        onChange(
          createValue(name, value)
        )
      },
    })(GivingDynamicFields)
  }
