import React from "react";

export const Education = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 494.463 494.463"
		>
			<path
				fill="#f1f2f2"
				d="M423.672 242.333c-9.55 23.055-10.151 51.557-.601 74.612l8.218 16.098H76.568s-66.042-37.107 0-103.149l351.658-7.247z"
			></path>
			<path
				fill="#dee1e1"
				d="M431.29 333.046H76.57s-66.04-37.11 0-103.15l351.66-7.25-4.56 19.69a96.593 96.593 0 00-5.01 16H96.78c-12.47 0-22.61 10.14-22.61 22.6 0 12.47 10.14 22.61 22.61 22.61h322c1.08 4.61 2.51 9.1 4.29 13.4z"
			></path>
			<path
				fill="#b7e546"
				d="M425.919 348.104l-324.24-.511c-38.845 0-72.205-24.78-72.205-63.625 0-8.58-1.494-19.83 1.319-27.432 9.921-26.817 35.721-45.933 65.987-45.933h332.639c8.762 0 15.865 7.103 15.865 15.865s-7.103 15.865-15.865 15.865h-251.75l-24.586 5.771-21.382-5.771H96.779c-21.321 0-38.605 17.284-38.605 38.605 0 21.321 17.284 38.605 38.605 38.605h332.639c8.762 0 15.865 7.103 15.865 15.865 0 .632-.037 1.255-.109 1.868-.924 7.882-11.125 10.828-19.255 10.828z"
			></path>
			<path
				fill="#abd641"
				d="M445.17 337.276a15.89 15.89 0 01-4.53 9.35c-2.88 2.87-6.84 4.65-11.22 4.65l-164.337 9.849-168.303-9.849c-38.85 0-70.34-31.49-70.34-70.34 0-10.13 2.14-19.76 6-28.46v14.46c0 38.85 31.49 70.34 70.34 70.34z"
			></path>
			<path
				fill="#ef5227"
				d="M177.668 288.584v-46.251h-32.966l-11.705 1.175.703 43.076a2 2 0 002.729 1.862l8.272-2.022 9.254-3.623c.469-.184.99-.184 1.458 0l19.526 7.646a2 2 0 002.729-1.863z"
			></path>
			<path
				fill="#d83724"
				d="M144.702 242.336v44.09l-10.27 4.02c-1.31.51-2.73-.45-2.73-1.86v-46.25z"
			></path>
			<path
				fill="#fdcd08"
				d="M378.078 170.216v22.55l-1 10c0 3.87-3.14 7-7 7l-266.51-1c-3.87 0-7-3.13-7-7l-2-31.55 136.813-6.538z"
			></path>
			<path
				fill="#77777f"
				d="M460.19 171.799a7.5 7.5 0 01-7.5-7.5V86.501c0-4.142 3.357-7.5 7.5-7.5s7.5 3.358 7.5 7.5v77.797a7.5 7.5 0 01-7.5 7.501z"
			></path>
			<path
				fill="#fdcd08"
				d="M446.785 189.04c-6.26 15.431-12.559 30.656-18.82 46.088-3.742 9.224 2.98 19.332 12.857 19.332l11.576 1h28.159c9.877 0 16.599-10.108 12.857-19.333l-13.055-32.182-8.211-14.906h-25.363z"
			></path>
			<path
				fill="#faab18"
				d="M456.865 209.634c8.228.749 16.858-1.411 23.493-5.687l-7.02-17.544-11.601 3.468.001-.003-15.123-2.018c-1.91 4.71-4.687 11.398-6.597 16.108l.001.001-13.051 32.169c-3.74 9.23 2.98 19.33 12.85 19.33h16c-9.87 0-16.59-10.1-12.85-19.33 3.251-8.008 6.499-16.015 9.748-24.023.673-1.662 2.364-2.634 4.149-2.471z"
			></path>
			<path
				fill="#fdcd08"
				d="M483.274 171.471c0-10.219-6.518-18.902-15.586-22.044-2.351-.815-5.768.635-8.392.635-12.749 0-20.596 8.541-20.596 21.41s9.538 20.59 22.287 20.59c2.625 0 4.35 2.268 6.701 1.454 9.068-3.143 15.586-11.826 15.586-22.045z"
			></path>
			<path
				fill="#faab18"
				d="M467.688 193.516c-2.35.82-4.88 1.26-7.5 1.26-12.75 0-23.08-10.44-23.08-23.31 0-12.86 10.33-23.3 23.08-23.3 2.62 0 5.15.44 7.5 1.26-9.07 3.14-15.58 11.83-15.58 22.04 0 10.22 6.51 18.91 15.58 22.05z"
			></path>
			<path
				fill="#868491"
				d="M462.037 94.52l-65.959 22.834-17.999-1.846-128.029 52.398a41.96 41.96 0 01-27.456 0L94.568 117.551l-18-.196L10.605 94.52c-7.53-2.607-7.53-13.256 0-15.862l214.49-74.252a34.31 34.31 0 0122.452 0l214.49 74.252c7.529 2.606 7.529 13.255 0 15.862z"
			></path>
			<path
				fill="#77777f"
				d="M396.078 110.586v6.77l-146.03 50.55a41.959 41.959 0 01-27.46 0l-146.02-50.55v-6.77c0-14.27 10.19-26.45 24.24-28.98 44.64-8 90.23-12.05 135.51-12.05s90.88 4.05 135.52 12.05c6.8 1.23 12.97 4.81 17.39 10.1 4.4 5.25 6.85 11.97 6.85 18.88z"
			></path>
			<path
				fill="#868491"
				d="M378.078 110.586v59.63H97.568l-1-57.63c0-5.54 3.97-10.28 9.42-11.26 2.857-.512 3.717-3.007 6.579-3.486a750.28 750.28 0 01123.751-10.284c44.29 0 88.58 3.93 132.34 11.77 2.72.49 5.08 1.92 6.75 3.92 1.68 2 2.67 4.57 2.67 7.34z"
			></path>
			<path
				fill="#faab18"
				d="M378.078 192.766v11c0 3.87-3.14 7-7 7h-269.51a7 7 0 01-7-7v-33.55l9-6.538 9 6.538v17.55a5 5 0 005 5z"
			></path>
			<path
				fill="#77777f"
				d="M112.568 97.846v72.37h-18v-59.63c0-5.54 3.97-10.28 9.42-11.26 2.86-.51 5.72-1.01 8.58-1.48z"
			></path>
			<path
				fill="#f1f2f2"
				d="M450.095 457.384a94.088 94.088 0 010-72.014l3.043-13.518H44.226s-46.031 50.043 2.991 99.064l408.584 3.064z"
			></path>
			<path
				fill="#dee1e1"
				d="M455.8 473.976l-408.58-3.06c-49.03-49.02-2.99-99.06-2.99-99.06l410.874.759-5.004 12.751a93.698 93.698 0 00-4.47 13.64H70.34c-12.47 0-22.61 10.14-22.61 22.6 0 12.47 10.14 22.61 22.61 22.61h375.41a94.86 94.86 0 004.35 13.17z"
			></path>
			<path
				fill="#ef5227"
				d="M448.912 488.444l-369.702-.68c-38.845 0-73.021-21.921-73.021-60.766 0-12.328-3.015-29.304 2.557-39.379 11.985-21.67 35.075-36.344 61.591-36.344H455.86c8.762 0 15.865 7.103 15.865 15.865s-7.103 15.865-15.865 15.865h-95.52l-24.959 5.525-21.008-5.525H70.338c-21.321 0-38.605 17.284-38.605 38.605 0 21.321 17.284 38.605 38.605 38.605H455.86c8.762 0 15.865 7.103 15.865 15.865 0 .979-.089 1.936-.258 2.866-1.349 7.394-14.771 9.498-22.555 9.498z"
			></path>
			<path
				fill="#d83724"
				d="M471.47 478.946c-.59 3.24-2.16 6.12-4.39 8.35a15.826 15.826 0 01-11.22 4.65H70.34c-38.85 0-70.34-31.49-70.34-70.34 0-13.9 4.03-26.86 11-37.78v24.78c0 38.85 31.49 70.34 70.34 70.34z"
			></path>
			<path
				fill="#fdcd08"
				d="M360.34 429.615V382.99h-30.968l-13.736.836.736 43.789a2 2 0 002.729 1.862l10.27-2.804 7.256-2.841c.469-.184.99-.184 1.458 0l19.526 7.646a2 2 0 002.729-1.863z"
			></path>
			<path
				fill="#faab18"
				d="M329.372 382.986v43.69l-12.27 4.8c-1.31.51-2.73-.45-2.73-1.86v-46.63z"
			></path>
		</svg>
	);
}

export default Education;
