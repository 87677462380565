import {
  APPLE_CLIENT_ID, ROOT_URL,} from 'envs/_current/config'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
// import AppleLogin from 'react-apple-login'
import AppleLogin from 'react-apple-signin-auth'

export default function AppleLoginBtn({
                                        onSuccess,
                                      }) {
  const t = useTranslate()

  const onAppleIDSignInOnSuccess = (
    response
  ) => {
    // if (response?.accessToken) {
    onSuccess({
      ...(response?.authorization ||
        {}),
      token: response?.id_token,
      provider: 'apple',
    })
    // }
  }

  const onAppleIDSignInOnFailure = (
    response
  ) => {
    onSuccess({
      token: JSON.stringify({
        response,
      }),
      provider: 'apple',
    })
  }

  return (
    <AppleLogin
      authOptions={{
        usePopup: true,
        clientId: APPLE_CLIENT_ID,
        scope: 'email name',
        state: 'origin:web',
        nonce: 'nonce',
        redirectURI:
          ROOT_URL + '/login',
      }}
      onSuccess={
        onAppleIDSignInOnSuccess
      }
      onError={onAppleIDSignInOnFailure}
      // clientId={APPLE_CLIENT_ID}
      // scope="email name"
      // responseMode="form_post"
      // responseType="code id_token"
      // redirectURI="https://test123456.wishare.com/api/v1/auth/callback/apple-auth"
      // callback={
      //   onAppleIDSignInOnSuccess
      // }
      render={(renderProps) => (
        <button
          className="loginBtn loginBtn--apple"
          onClick={renderProps.onClick}
          disabled={
            renderProps.disabled
          }>
          {t('login with Apple')}
        </button>
      )}
    />
  )
}
