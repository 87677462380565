import { donation_update_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { withProps } from 'recompose'
import OwnerPicker from 'views/Wishare/custom/OwnerPicker'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DonationContext from '../DonationContext'

const formFields = [{ name: 'owner' }]

const formSchema = [
  {
    title: 'owner',
    children: [
      {
        name: 'owner',
        component: withProps(
          ({
            name,
            form,
            value,
            onChange,
          }) => {
            const { values } =
              form || {}
            const { id, type } =
              values || {}
            return {
              id,
              type,
              name,
              onChange,
              defaultValue: value,
            }
          }
        )(OwnerPicker),
      },
    ],
  },
]

const DonationSettingOwner = () => {
  const { donation } = useContext(
    DonationContext
  )

  const validationSchema = undefined

  const donation_id = getId(donation)

  const donation_type =
    getType(donation)

  const onPreSubmit = ({
    id,
    type,
    owner,
    ...values
  }) => {
    const [owner_id, owner_type] = [
      getId(owner),
      getType(owner),
    ]
    return {
      ...values,
      owner_id,
      owner_type,
    }
  }

  const initialized = useCallback(
    ({ owner, ...values }) => ({
      ...values,
      owner,
      id: donation_id,
      type: donation_type,
    }),
    [donation_id, donation_type]
  )

  if (_.isEmpty(donation)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: donation,
    validationSchema,
    query: bindQueryParam({
      donation_id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: donation_update_api,
  })
}

export default DonationSettingOwner
