import Icon, {
  MenuOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  SearchOutlined,
  StarFilled,
} from '@ant-design/icons'
import {
  Avatar,
  Input,
  Tooltip,
} from 'antd'
import { getId } from 'apis/model/base'
import { organizationSchema } from 'apis/schema'
import classNames from 'classnames'
import AppTextLogoIcon from 'components/icons/AppTextLogoIcon'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { useDrawerDetector } from 'components/layouts/Wishare/WishareSiderMenu'
import { LoginContext } from 'components/LoginContext'
import { ShortAppLogoSvg } from 'components/Svgs/ShortAppLogoSvg'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
} from 'react'
import { ImLibrary } from 'react-icons/im'
import { useSelector } from 'react-redux'
import {
  Link,
  NavLink,
} from 'react-router-dom'
import { useLocation } from 'react-use'
import { withProps } from 'recompose'
import {
  getLogin,
  selectEntities,
} from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import { HeaderButton } from 'views/Organization/components/PageHeader'
import {
  Null,
  renderElse,
  renderFalse,
  renderIf,
  renderSelf,
} from 'views/Shared'
import { DiscoveryContextMenu } from 'views/Wishare/DiscoveryExplorer/components/DiscoveryExplorerHeader'
import HeaderNavigation from './HeaderNavigation'
import WorkspacePicker from './WorkSpace/components/WorkspacePicker'

const HeaderToggleOptions =
  Object.freeze({
    MYSPACE: {
      label: 'Dashboard',
      path: staticPaths.home,
    },
    WORKSPACE: {
      label: 'workspace',
      path: staticPaths.workspace,
      isInvisble: (login, __) =>
        Boolean(
          !_.get(login, 'primary_org')
        ),
    },
    STARTSPACE: {
      label: 'start',
      path: staticPaths.start,
    },
  })

export const DrawerHeader = () => {
  const {
    toggle = Null,
    hasDrawer = false,
  } = useDrawerDetector()

  const { visibleLeftDrawer } =
    useContext(LayoutContext)

  const showDrawer = Boolean(
    visibleLeftDrawer && hasDrawer
  )
  return (
    <div
      className={classNames(
        !showDrawer && 'px-2'
      )}>
      {renderIf(
        showDrawer,
        <HeaderButton
          icon={<MenuOutlined />}
          onClick={() => toggle(true)}
        />
      )}
    </div>
  )
}

const HeaderWrapper = ({
  children,
  className,
  Component = 'header',
}) => (
  <Component className={className}>
    <div className="flex background-100 items-center">
      <div className="w-full flex flex-wrap items-center justify-between">
        {children}
      </div>
    </div>
  </Component>
)

const StarterNav = ({
  path,
  label,
  isInvisble = renderFalse,
}) => {
  const t = useTranslate()

  const { isLg } = useContext(
    LayoutContext
  )

  const location = useLocation()

  const isActive =
    location.pathname ===
    _.get(path, 'pathname')

  const Icon = isActive
    ? PlayCircleFilled
    : PlayCircleOutlined

  return renderElse(
    isInvisble(true),
    <nav
      style={{
        borderRadius: '0 0 0 20px',
        height: 'var(--header-height)',
        boxShadow:
          ' inset -4px -10px 9px -5px var(--border-color-base)',
      }}
      className="w-full flex-1 flex background-100 noSrollbar overflow-auto"
      id="headernav">
      <NavLink
        to={path}
        activeClassName="text-primary font-medium border-current px-3"
        tabIndex={3}
        style={{
          borderRadius: '-0.25rem',
        }}
        isActive={(match, location) =>
          location.pathname ===
          _.get(path, 'pathname')
        }
        className={classNames(
          'flex items-center px-1 space-x-2 font-normal flex-shrink-0 truncate border-transparent',
          'md:border-b-4 hover:opacity-75 focus:outline-none'
        )}>
        <div
          className={classNames(
            'border-5 rounded-full flex items-center justify-center',
            'text-color-300'
          )}>
          <Icon
            className={classNames(
              'text-3xl',
              isActive
                ? 'text-primary'
                : 'text-color-500'
            )}
          />
        </div>
        <div>
          {Boolean(isLg) && t(label)}
        </div>
      </NavLink>
      <div className="flex flex-1 justify-end">
        <LoginNow />
      </div>
    </nav>
  )
}

const LoginNow = () => {
  const t = useTranslate()
  const history = useHistory()
  const login = useContext(LoginContext)
  if (login) return null
  return (
    <div
      onClick={() => {
        history.push(staticPaths.login)
      }}
      className="flex items-center h-full text-white bg-primary px-2 cursor-pointer">
      {t('Login now')}
    </div>
  )
}

export const ToggleHeader = ({
  children,
  options = [
    HeaderToggleOptions.MYSPACE,
    HeaderToggleOptions.WORKSPACE,
  ],
}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const location = useLocation()

  return (
    <HeaderWrapper>
      {children}
      <div
        style={{
          height:
            'var(--header-height)',
          borderRadius: `0px 20px 0px 0px`,
          boxShadow:
            'var(--border-color-base) 4px 10px 9px -5px inset',
        }}
        className="flex space-x-1 flex-shrink-0 cursor-pointer items-center justify-center truncate background-100">
        {Array.from(options || []).map(
          (
            {
              label,
              isInvisble = renderFalse,
              path = staticPaths.home,
            },
            index
          ) => {
            const _currentPath =
              path.pathname
            const isActive =
              location.pathname.startsWith(
                _currentPath
              )
            return renderElse(
              isInvisble(login),
              <NavLink
                key={index}
                className="flex flex-wrap items-center"
                to={path}>
                <Avatar
                  size={40}
                  shape="circle"
                  className={classNames(
                    'background-100 m-1 text-color-500 flex-shrink-0 flex items-center justify-center border-5 rounded-full border-blue-100',
                    isActive &&
                      'border-blue-400 text-blue-500 bg-blue-500'
                  )}
                  style={
                    isActive
                      ? {
                          color:
                            '#4172d6',
                        }
                      : {
                          borderColor:
                            'var(--text-color-500)',
                        }
                  }
                  icon={<StarFilled />}
                />
                {isActive && (
                  <div className="flex pl-1 pr-2 font-bold text-blue-500 no-underline">
                    {t(label)}
                  </div>
                )}
              </NavLink>
            )
          }
        )}
      </div>
      <StarterNav
        {...HeaderToggleOptions.STARTSPACE}
      />
    </HeaderWrapper>
  )
}

const SearchWidget = () => {
  const t = useTranslate()
  const history = useHistory()
  return (
    <Input
      allowClear={true}
      prefix={<SearchOutlined />}
      onKeyDown={(event) => {
        const { value: keyword } =
          event.target
        if (_.isEmpty(keyword)) {
          return
        } else if (
          event.key === 'Enter'
        ) {
          history.push({
            pathname:
              staticPaths.discovery
                .pathname,
            search: `?keyword=${keyword}`,
          })
        }
      }}
      className="custom-search-header"
      placeholder={t('type to search')}
    />
  )
}

export const LogoHeader = ({
  style,
  className,
  LogoIcon = AppTextLogoIcon,
}) => {
  const t = useTranslate()
  const mainLocation = useLocation()
  const { isSm } = useContext(
    LayoutContext
  )
  const { primary_org } =
    useSelector(getLogin) || {}

  const primary_id = _.isString(
    primary_org
  )
    ? primary_org
    : getId(primary_org)

  const primary_organization =
    useSelector((state) =>
      selectEntities(
        state,
        primary_id,
        organizationSchema
      )
    ) || {}

  return (
    <div
      style={style}
      className={classNames(
        'container m-auto',
        className
      )}>
      <div className="w-full flex flex-wrap items-center justify-between">
        <div className="flex items-center gap-1 md:gap-3 justify-start">
          {isSm && (
            <React.Fragment>
              <DrawerHeader />
              <Link
                to={staticPaths.start}
                style={{
                  fontSize: '90px',
                  height:
                    'var(--header-height)',
                }}
                className="cursor-pointer flex items-center justify-center ml-0 md:ml-3 flex-1">
                <LogoIcon />
              </Link>
            </React.Fragment>
          )}
        </div>

        {!isSm && (
          <div
            style={{
              top: '100%',
              left: 0,
              right: 0,
              height:
                'var(--header-height)',
            }}
            className="w-full px-3 background flex justify-center gap-4 leading-relaxed relative">
            {!_.isEmpty(
              primary_organization
            ) ? (
              <div className="flex flex-center gap-4 flex-shrink-0 sticky left-0 z-30 truncate">
                <div className="flex flex-wrap flex-row items-center cursor-pointer">
                  <WorkspacePicker
                    primary_organization={
                      primary_organization
                    }
                    IconComponent={({
                      item,
                      isSm,
                    }) => (
                      <Avatar
                        size={30}
                        src={getAvatar(item)}
                        icon={<ImLibrary className="text-color-300" />}
                        shape="square"
                        className={classNames(
                          'flex flex-center rounded-md border-2 background-200 custom-avatar-workspace',
                          mainLocation.pathname.startsWith(
                            staticPaths
                              .workspace
                              .pathname
                          )
                            ? 'border-primary'
                            : 'border-color-500'
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <Link
                className="flex flex-wrap flex-row items-center no-underline"
                to={
                  staticPaths.workspace
                }>
                <Avatar
                  size={30}
                  shape="square"
                  icon={<ImLibrary />}
                  className={classNames(
                    'flex flex-center rounded-md border-2 background-200 custom-avatar-workspace',
                    mainLocation.pathname.startsWith(
                      staticPaths
                        .workspace
                        .pathname
                    )
                      ? 'border-primary'
                      : 'border-color-500'
                  )}
                />
              </Link>
            )}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Tooltip
                  placement="bottom"
                  title={t(
                    'about wishare'
                  )}>
                  <Link
                    to={
                      staticPaths.start
                        .pathname
                    }
                    className="flex items-center no-underline gap-2 leading-none">
                    <Avatar
                      size={30}
                      icon={
                        <Icon
                          component={
                            ShortAppLogoSvg
                          }
                        />
                      }
                      className={classNames(
                        'flex flex-center hover:background-200 border',
                        mainLocation.pathname.startsWith(
                          staticPaths
                            .start
                            .pathname
                        )
                          ? 'border-primary bg-primary-50'
                          : 'border-color-50 background-100'
                      )}
                    />
                    {mainLocation.pathname.startsWith(
                      staticPaths.start
                        .pathname
                    ) && (
                      <span className="text-sm font-semibold text-primary">
                        {t(
                          'about wishare'
                        )}
                      </span>
                    )}
                  </Link>
                </Tooltip>
              </div>
              <div className="flex items-center gap-3">
                <SearchWidget />
                <DiscoveryContextMenu />
              </div>
            </div>
          </div>
        )}

        {!!isSm && <HeaderNavigation />}
      </div>
    </div>
  )
}

const MainHeader = ({
  style,
  className,
  Title = Null,
  Footer = Null,
  renderFooter = renderSelf,
}) => {
  const _footerProps = {
    style: {
      zIndex: 31,
      // height:
      // wishareConfigs.styles
      //   .headerHeight,
    },
    className:
      'background PageHeader stickyTop shadow flex items-center',
  }
  return (
    <React.Fragment>
      <Title />
      {renderFooter(
        <div {..._footerProps}>
          <Footer />
        </div>,
        _footerProps
      )}
    </React.Fragment>
  )
}

export const WishareHeader = withProps({
  Footer: LogoHeader,
})(MainHeader)

export default MainHeader
