import {
  Avatar,
  Input,
  List,
} from 'antd'
import {
  auth_fetchOrganizationsWithAdminAuth_Api,
  auth_updateOrg_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ImLibrary } from 'react-icons/im'
import {
  IoCaretDownCircleOutline,
  IoCheckmarkOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { staticPaths } from 'routes/staticPaths'
import MainContext from 'views/MainPage/MainContext'
import { useDeBounceValue } from 'views/Search/useDeBounceValue'
import {
  Null,
  renderFalse,
} from 'views/Shared'
import EntityList from 'views/Wishare/EntityList'
import createContextMenu from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'

const WorkspaceAvatar = ({
  src,
  style,
  onClick,
  ...props
}) => (
  <Avatar
    src={src}
    style={style}
    shape="square"
    className="custom-avatar-workspace"
    onClick={onClick}
    {...props}
  />
)

const excludedKeys = {
  all: '__workspace_picker_more__',
}

const renderOrganizationRow =
  ({
    onItemSelected = Null,
    isSelected = renderFalse,
  }) =>
  (item) => {
    const id = getId(item)
    const selected = !!isSelected(id)
    return (
      <List.Item
        key={id}
        actions={[
          selected ? (
            <IoCheckmarkOutline
              size={25}
              className="text-green-600"
            />
          ) : (
            <Translate>
              {(t) => (
                <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                  {t('select')}
                </span>
              )}
            </Translate>
          ),
        ]}
        onClick={
          selected
            ? undefined
            : () => onItemSelected(item)
        }
        className={classNames(
          'p-2 rounded-lg hover:shadow-out border border-color-50 background',
          !selected && 'cursor-pointer'
        )}>
          <div className="flex items-center gap-2 no-underline leading-tight">
              <WorkspaceAvatar
                  size={30}
                  className="rounded-md background-100"
                  src={getAvatar(item)}
                  icon={
                      <ImLibrary className="text-color-400" />
                  }
              />
              <div className="font-semibold leading-tight">
                  {getTitle(item)}
              </div>
          </div>
      </List.Item>
    )
  }

export const AuthOrganiationsModal =
  () => {
    const t = useTranslate()

    const {
      setCurrent,
      handleGoBack = Null,
    } = useContext(NavigationContext)

    const login = useContext(
      LoginContext
    )

    const { primary_org } = login || {}

    const primary_id =
      getId(primary_org) || primary_org

    const onItemSelected = (item) => {
      const result = getId(item)
      setCurrent({
        key: excludedKeys.all,
        value: result,
      })
      handleGoBack()
    }

    const [keyword, setKeyword] =
      useState()

    const debouncedValue =
      useDeBounceValue(keyword, 500)

    return (
      <div className="space-y-4">
          <div className="h-3"/>
        <div className="flex flex-center uppercase font-bold text-2xl mb-4">
            {t('choose organization')}
        </div>
        <div className="flex flex-col gap-3">
          <Input.Search
            allowClear={true}
            placeholder={t('keyword')}
            onChange={(event) => {
              setKeyword(
                event.target.value
              )
            }}
          />
          <div className="space-y-3">
            <EntityList
              key={debouncedValue}
              apiInfo={
                auth_fetchOrganizationsWithAdminAuth_Api
              }
              RenderEntity={
                React.Fragment
              }
              values={{
                keyword: debouncedValue,
              }}
              renderItem={renderOrganizationRow(
                {
                  onItemSelected,
                  isSelected: (id) =>
                    id === primary_id,
                }
              )}
            />
          </div>
        </div>
      </div>
    )
  }

const WorkspacePicker = ({
  primary_organization,
  IconComponent = ({ item, isSm }) => (
    <WorkspaceAvatar
      src={getAvatar(item)}
      style={{
        cursor: 'pointer',
        width: isSm
          ? '1.4rem'
          : '1.6rem',
        height: isSm
          ? '1.4rem'
          : '1.6rem',
        borderRadius: '0.5rem',
        background:
          'var(--background-100)',
      }}
    />
  ),
}) => {
  const t = useTranslate()

  const history = useHistory()

  const primary_id = getId(
    primary_organization
  )

  const login = useContext(LoginContext)

  const admin_of_organizations =
    useMemo(
      () =>
        Array.from(
          _.get(
            login,
            'admin_of_organizations'
          )
        ).filter(
          (e) => getId(e) !== primary_id
        ),
      [login, primary_id]
    )

  const isActive = (path) => {
    const pathname = _.get(
      history,
      'location.pathname'
    )
    return pathname
      ? String(pathname).includes(path)
      : false
  }

  const { isSm } = useContext(
    LayoutContext
  )

  const { setLoading = Null } =
    useContext(MainContext)

  const { current, setCurrent = Null } =
    useContext(NavigationContext)

  const {
    key: nav_type,
    value: selected_organization_id,
  } = current || {}

  const {
    isLoading,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: auth_updateOrg_Api,
    onSuccess: (result) => {
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      setLoading(false)
      setCurrent(null)
    },
    onError: notifyOnError(t),
  })

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (
      nav_type === excludedKeys.all &&
      selected_organization_id &&
      selected_organization_id !==
        primary_id
    ) {
      handleAsyncAction({
        organization_id:
          selected_organization_id,
      })
    }
  }, [
    nav_type,
    primary_id,
    selected_organization_id,
  ])

  const onSelect = useCallback(
    (params = {}) =>
      (key) => {
        if (key === excludedKeys.all) {
          history.push(
            staticPaths.selectPrimaryOrganization
          )
        } else if (key !== primary_id) {
          setCurrent(null)
          handleAsyncAction({
            organization_id: key,
          })
        } else {
          setCurrent(null)
        }
      },
    [primary_id]
  )

  const renderLabel =
    (params) => (label) => {
      const {
        isSelected = false,
        className,
      } = params || {}
      return (
        <div
          className={classNames(
            'no-underline truncate',
            isSelected
              ? 'text-primary font-semibold'
              : 'text-color-200 font-medium',
            className
          )}>
          {label}
        </div>
      )
    }

  const transformMenuItems = (
    array = []
  ) => [
    ...array,
    {
      key: excludedKeys.all,
      label: (
        <span className="italic text-xs text-color-400 fontlight">
          {t('show more')}
        </span>
      ),
      renderLabel: renderLabel({
        className: 'flex flex-center flex-1',
      }),
    },
  ]

  const renderContextMenu = useCallback(
    (params) =>
      createContextMenu(
        transformMenuItems(
          [
            primary_organization,
            ...admin_of_organizations,
          ]
            .filter(
              (e) => !_.isEmpty(e)
            )
            .map((item) => {
              const current_id =
                getId(item)
              const isSelected =
                primary_id ===
                current_id
              return {
                key: current_id,
                label: getTitle(item),
                icon: (
                  <WorkspaceAvatar
                    className="rounded-md background-100 flex flex-center"
                    size={24}
                    src={getAvatar(item)}
                    icon={
                      <ImLibrary className="text-color-300" />
                    }
                  />
                ),
                // disabled: isSelected,
                renderLabel:
                  renderLabel({
                    isSelected,
                  }),
              }
            })
        ),
        (key) => onSelect(params)(key),
        {
          withTranslate: (t, item) =>
            item,
          icon: (
            <div className="flex gap-3 items-center">
              <IconComponent
                item={
                  primary_organization
                }
                isSm={isSm}
              />
              {!isSm &&
                isActive(
                  staticPaths.workspace
                    .pathname
                ) && (
                  <React.Fragment>
                    <div
                      onClick={() => {
                        if (
                          primary_organization
                        ) {
                          history.push({
                            pathname:
                              getLinkToDetail(
                                primary_organization
                              ),
                          })
                        }
                      }}
                      style={{
                        maxWidth: isSm
                          ? 'calc(100vw - (3 * var(--header-height)))'
                          : '100%',
                      }}
                      className="text-primary font-semibold truncate">
                      {getTitle(primary_organization)}
                    </div>
                    <IoCaretDownCircleOutline className="text-color-400 cursor-pointer hover:text-primary" />
                  </React.Fragment>
                )}
            </div>
          ),
        }
      ),
    [
      isSm,
      onSelect,
      primary_id,
      admin_of_organizations,
    ]
  )

  if (_.isEmpty(primary_organization)) {
    return null
  }

  return (
    <React.Fragment>
      {isActive(
        staticPaths.workspace.pathname
      ) ? (
        renderContextMenu()
      ) : (
        <Link to={`/workspace`}>
          <IconComponent
            item={primary_organization}
            isSm={isSm}
          />
        </Link>
      )}
    </React.Fragment>
  )
}

export default WorkspacePicker
