import _ from "lodash";
import moment from "moment";
import {getType} from "../../../apis/model/base";


export function getEventItemStatus(eventItem) {
  if (!eventItem) return null;

  const _status = Number(_.get(eventItem, 'status', 1));

  switch (getType(eventItem)) {
    case "donation_event":
    case "giving_event":
    case "recruitment_event":
    default:
      if (moment(_.get(eventItem, 'time_close')) < moment()) return 9;
  }


  return _status;
}