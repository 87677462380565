import { Avatar } from 'antd'
import { getId } from 'apis/model/base'
import { organizationSchema } from 'apis/schema'
import classNames from 'classnames'
import { FlagIcon } from 'components/icons/FlagIcon'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LanguageSwitch } from 'components/layouts/Default/SiderMenu/LanguageSwitch'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import { LocalContext } from 'modules/local/LocalContext'
import Translate from 'modules/local/Translate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
} from 'react'
import { ImLibrary } from 'react-icons/im'
import { IoSearchOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'
import {
  getLogin,
  selectEntities,
} from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import { Null } from 'views/Shared'
import WorkspacePicker from './WorkSpace/components/WorkspacePicker'

const defaultItems = [
  // {
  //   label: 'introduce',
  //   to: {
  //     pathname: '/start',
  //   },
  // },
  // {
  //   label: 'discovery',
  //   to: {
  //     pathname:
  //       staticPaths.discovery.pathname,
  //   },
  // },
]

const DefaultActions = () => {
  const history = useHistory()

  const { lang } = useContext(
    LocalContext
  )

  const mainLocation = useLocation()

  const { primary_org } =
    useSelector(getLogin) || {}

  const primary_id = _.isString(
    primary_org
  )
    ? primary_org
    : getId(primary_org)

  const primary_organization =
    useSelector((state) =>
      selectEntities(
        state,
        primary_id,
        organizationSchema
      )
    ) || {}

  const renderAvatar = useCallback(
    (item) => (
      <Avatar
        size={30}
        src={getAvatar(item)}
        icon={
          <ImLibrary className="text-color-300" />
        }
        shape="square"
        style={{
          padding: '0.1rem',
        }}
        className={classNames(
          'flex flex-center rounded-md border background-100 custom-avatar-workspace',
          mainLocation.pathname.startsWith(
            staticPaths.workspace
              .pathname
          )
            ? 'border-primary'
            : 'border-color-500'
        )}
      />
    ),
    [mainLocation.pathname]
  )

  return (
    <div className="flex md:hidden space-x-2 justify-center items-center mr-2 md:mr-0">
      {/*<Link
        to={staticPaths.messages}
        className="flex hover:background-100 items-center justify-center">
        <Selector
          selector={
            getMessageNotifications
          }>
          {(count) => (
            <Badge count={count}>
              <Count
                className="text-2xl"
                component={
                  IoChatbubblesOutline
                }
              />
            </Badge>
          )}
        </Selector>
      </Link>*/}

      {!_.isEmpty(
        primary_organization
      ) ? (
        <div className="flex flex-center gap-4 flex-shrink-0 ">
          <div className="flex flex-wrap flex-row items-center cursor-pointer">
            <WorkspacePicker
              primary_organization={
                primary_organization
              }
              IconComponent={({
                item,
                isSm,
              }) => renderAvatar(item)}
            />
          </div>
        </div>
      ) : (
        <Link
          to={
            staticPaths.workspace
              .pathname
          }>
          <Avatar
            size={36}
            icon={
              <ImLibrary className="text-color-300" />
            }
            shape="square"
            style={{
              padding: '0.2rem',
              fontSize: '18px',
            }}
            className="flex flex-center rounded-md border background-100 custom-avatar-workspace"
          />
        </Link>
      )}

      <div className="table w-10 h-10">
        <LanguageSwitch
          title={
            <LayoutContext.Consumer>
              {({ isSm }) => (
                <Avatar
                  style={{
                    width: isSm
                      ? '1.6rem'
                      : '2rem',
                    height: isSm
                      ? '1.6rem'
                      : '2rem',
                  }}
                  className={classNames(
                    'cursor-pointer background-200 rounded-full m-2 inline-grid content-center justify-center'
                  )}
                  icon={
                    <FlagIcon
                      type={lang}
                      width={
                        isSm
                          ? '1.5rem'
                          : '2rem'
                      }
                      height={
                        isSm
                          ? '1.5rem'
                          : '2rem'
                      }
                      {...{
                        style: {
                          color:
                            'var(--color-primary)',
                        },
                      }}
                    />
                  }
                />
              )}
            </LayoutContext.Consumer>
          }
        />
      </div>
      <div
        onClick={() => {
          history.push({
            pathname:
              staticPaths.search
                .pathname,
            state: {
              isModal: true,
            },
          })
        }}
        className="flex hover:background-200 items-center justify-center text-2xl">
        <IoSearchOutline
          className="FeedCountIcon"
          style={{ color: '#b9b9b9' }}
        />
      </div>
    </div>
  )
}

const HeaderNavigation = ({
  items = defaultItems,
  ExtraActions = Null,
}) => (
  <React.Fragment>
    <Translate>
      {(t) => (
        <nav className="hidden md:flex items-center">
          {items.map(
            ({ label, to }, index) => (
              <h2 key={index}>
                <Link
                  to={to.pathname}
                  className={classNames(
                    'px-6 font-bold uppercase',
                    index === 0
                      ? 'color-primary'
                      : 'text-color-200 hover:text-primary'
                  )}>
                  {t(label)}
                </Link>
              </h2>
            )
          )}
        </nav>
      )}
    </Translate>
    <div className="flex items-center px-3">
      <DefaultActions />
      <ExtraActions />
    </div>
  </React.Fragment>
)
export default HeaderNavigation
