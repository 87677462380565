import Icon, {
  SearchOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Spin,
} from 'antd'
import Count from 'components/Feed/Count'
import { API_ROOT_URL } from 'envs/_current/config'
import preventDefaultEvent from 'helpers/preventDefaultEvent'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MdClose } from 'react-icons/md'
import { useToggle } from 'react-use'
import classNames from 'classnames'

export const SelectCategories = ({
  type,
  onChange,
  categoryOptions = [],
  selected: externalSelected = [],
}) => {
  const [selected, setSelected] =
    useState(externalSelected)
  useEffect(() => {
    setSelected(externalSelected)
  }, [externalSelected])
  const entitesRef = useRef({
    ...selected.reduce(
      (result, value) => {
        result[value] = {
          id: value,
          name: value,
        }
        return result
      },
      {}
    ),
    ...categoryOptions.reduce(
      (result, value) => {
        result[value.id] = value
        return result
      },
      {}
    ),
  })
  const [keyword, setkeyword] =
    useState('')
  const t = useTranslate()
  const handleChange = (id) => {
    const findIndex =
      selected.findIndex(
        (item) => item === id
      )
    let newValues
    if (findIndex > -1) {
      newValues = selected.filter(
        (__, i) => findIndex !== i
      )
      setSelected(newValues)
    } else {
      newValues = [...selected, id]
      setSelected(newValues)
    }
  }
  const [isToggle, toggle] = useToggle()
  const [
    { result = [], isLoading },
    dispatch,
  ] = useDispatchAsyncAction()
  entitesRef.current = {
    ...entitesRef.current,
    ...result.reduce(
      (result, value) => {
        result[value.id] = value
        return result
      },
      {}
    ),
  }
  const options = selected
    .map((id) => {
      const item =
        entitesRef.current[id]
      if (!item) return null
      return {
        ...item,
        label: item.name,
        value: item.id,
      }
    })
    .filter(Boolean)
  const categories = keyword.length
    ? result
    : categoryOptions
  const debouncedispatch =
    useMemo(() => {
      const handler = (keyword) => {
        setkeyword(keyword)
        dispatch(
          createAsyncAction({
            apiInfo: {
              root: API_ROOT_URL,
              path:
                '/categories/search?keyword=' +
                keyword +
                (type
                  ? `&&type=${type}`
                  : ''),
              method: 'GET',
            },
          })
        )
      }
      return _.debounce(handler)
    }, [type, dispatch])
  return (
    <>
      <Select
        options={options}
        open={false}
        onClick={toggle}
        {...{
          value: selected,
        }}
        mode="multiple"
        placeholder={t('please select')}
        onChange={(value) => {
          setSelected(value)
          return onChange(value)
        }}
        style={{
          width: '100%',
        }}
      />
      <Modal
        title={
          <div className="text-center font-bold text-color-000 uppercase">
            {t('select categories')}
          </div>
        }
        width={720}
        destroyOnClose
        onOk={toggle}
        footer={null}
        visible={isToggle}
        onCancel={toggle}
      >
        <div className="flex flex-col gap-3">
          <Input
            autoFocus
            placeholder={t('please select')}
            addonAfter={<SearchOutlined />}
            onChange={(e) =>
              debouncedispatch(
                e.target.value
              )
            }
          />
          <div className="flex flex-wrap items-center gap-2">
            {options.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{padding: '2px 0 2px 10px'}}
                  className="cursor-pointer background border rounded-full flex flex-center gap-1">
                  <span className="font-medium text-color-100 italic">
                    {item.name}
                  </span>
                  <Count
                    onClick={() => {
                      handleChange(item.id)
                    }}>
                    <Icon component={MdClose} />
                  </Count>
                </div>
              )
            })}
          </div>
          {isLoading && (
            <Spin spinning>
              <div className="border background-100 rounded p-3 flex space-x-3 h-24" />
            </Spin>
          )}
          <div className="grid grid-cols-2 gap-3">
            {categories.map((item) => {
              const {
                id,
                avatar,
                name,
                alt_idname,
                description,
              } = item
              const checked = selected.includes(id)
              return (
                <div
                  key={id}
                  onClick={(e) => {
                    preventDefaultEvent(e)
                    handleChange(id)
                  }}
                  className={classNames('border background rounded-lg p-3 flex items-center gap-3 cursor-pointer', checked
                    ? 'border-primary'
                    : 'border-color-50 hover:shadow-items')}
                >
                  {avatar ? (
                    <Avatar
                      size={30}
                      src={avatar}
                      alt={alt_idname}
                    />
                  ) : (
                    <Avatar
                      size={30}
                      style={{
                        color: 'white',
                      }}
                      className="rounded-md uppercase bg-primary">
                      {alt_idname.charAt(
                        0
                      )}
                    </Avatar>
                  )}
                  <div className="flex-1">
                    <div className="font-bold">
                      {name}
                    </div>
                    {!!description && (
                      <div className="font-bold text-color-300">
                        {description}
                      </div>
                    )}
                  </div>
                  <div className="self-center">
                    <Checkbox
                      checked={checked}
                      value={id}
                      id={id}
                      name={id}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="h-3" />
          <div className="text-right">
            <Button
              type="primary"
              className="rounded-lg px-5 no-shadow no-text-shadow"
              onClick={() => {
                onChange(selected)
                toggle()
              }}>
              {t('save')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
