import React from 'react'

export const FlagVISvg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="1rem"
      height="1rem"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <circle cx="256" cy="256" r="256" fill="#D80027"></circle>
      <path
        fill="#FFDA44"
        d="M256 133.565L283.628 218.594 373.033 218.594 300.702 271.144 328.33 356.174 256 303.623 183.67 356.174 211.298 271.144 138.968 218.594 228.372 218.594z"
      ></path>
    </svg>
  )
}
