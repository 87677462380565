import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { renderOwnChild } from 'views/Shared'
import { FlexCol } from '../Templates/ItemTemplate'

const PagerLink = ({
  to,
  onClick,
  description,
  title = 'see more',
  Wrapper = renderOwnChild,
  Component = renderOwnChild,
}) => {
  const t = useTranslate()
  return (
    <Wrapper>
      {!_.isEmpty(description) && (
        <span className="font-light text-color-400 text-xs italic text-center">
          {t(description)}
        </span>
      )}
      <div
        className="text-center"
        onClick={onClick}>
        <Component
          to={to}
          className="px-5 py-1 border hover:background-100 rounded-md text-color-200 text-xs italic cursor-pointer no-underline">
          {t(title)}
        </Component>
      </div>
    </Wrapper>
  )
}

const createPagerFooter = ({
  to,
  onClick,
  description,
  Component = Link,
}) => (
  <PagerLink
    {...{
      to,
      onClick,
      description,
      Wrapper: ({
        children,
        ...rest
      }) => (
        <FlexCol
          className="space-y-3"
          {...rest}>
          {children}
        </FlexCol>
      ),
      Component,
    }}
  />
)

export const DonorPagerFooter = ({
  pathname,
}) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click "See more" to see this full information',
  })

export const DonationPagerFooter = ({
  pathname,
}) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click "See more" to see this full information',
  })

export const GivingPagerFooter = ({
  pathname,
}) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click "See more" to see this full information',
  })

export const RecruitmentPagerFooter = ({
  pathname,
}) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click "See more" to see this full information',
  })

export default createPagerFooter
