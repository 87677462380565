import { Avatar } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { ArticleCompactItem } from 'views/Articles/components/ArticleItem'
import { ChannelCompactItem } from 'views/Channel/components/ChannelPicker'
import { ActivityCompactItem } from 'views/Wishare/Activity/components/ActivityItem'
import { DonationCompactItem } from 'views/Wishare/Donation/components/DonationEventItem'
import { WishareEntities } from 'views/Wishare/enums'
import { EventCompactItem } from 'views/Wishare/Event/components/EventItem'
import { GivingCompactItem } from 'views/Wishare/Giving/components/GivingEventItem'
import { OrganizationCompactItem } from 'views/Wishare/Organization/components/OrganizationItem'
import { RecruitmentCompactItem } from 'views/Wishare/Recruitment/components/RecruitmentEventItem'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'

export const UserCompactItem = ({
  item,
  Wrapper = 'div',
}) => (
  <Wrapper className="relative w-full border border-color-50 rounded-lg CompactUser">
    <OverlayItemBanner
      type={WishareEntities.USER}
    />
    <div className="h-7" />
    <div className="flex items-center px-3 py-2">
      <Avatar
        className="mr-3"
        src={getAvatar(item)}>
        <span className="capitalize text-color-100">
          {_.first(getTitle(item))}
        </span>
      </Avatar>
      <div className="flex flex-col md:flex-row flex-1">
        <div className="flex flex-col flex-1 mx-3">
          <div className="flex items-center">
            <Link
              to={getLinkToDetail(item)}
              className="cursor-pointer font-bold leading-tight max-lines-1">
              {getTitle(item)}
            </Link>
          </div>
          <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1"></span>
        </div>
      </div>
    </div>
  </Wrapper>
)

const createSearchResultComponent = (
  item,
  index
) => {
  if (!!!getId(item)) return null

  const type = getType(item)

  let Component = () => <EmptyHolder />

  switch (type) {
    case WishareEntities.USER:
      Component = UserCompactItem
      break
    case WishareEntities.ORGANIZATION:
      Component =
        OrganizationCompactItem
      break
    case WishareEntities.ACTIVITY:
      Component = ActivityCompactItem
      break
    case WishareEntities.CHANNEL:
      Component = ChannelCompactItem
      break
    case WishareEntities.DONATION:
      Component = DonationCompactItem
      break
    case WishareEntities.GIVING:
      Component = GivingCompactItem
      break
    case WishareEntities.RECRUITMENT:
      Component = RecruitmentCompactItem
      break
    case WishareEntities.EVENT:
      Component = EventCompactItem
      break
    case WishareEntities.ARTICLE:
      Component = ArticleCompactItem
      break
    default:
      break
  }

  return (
    <div
      key={index}
      className="flex w-full mb-3">
      <Component {...{ item }} />
    </div>
  )
}

export default createSearchResultComponent
