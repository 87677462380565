import React from 'react'
import TopicNav from './TopicNav'

const TopicContent = ({
  Wrapper = 'div',
  ...props
}) => {
  return (
    <Wrapper className="space-y-3 min-h-screen h-full">
      <TopicNav />
    </Wrapper>
  )
}

export default TopicContent
