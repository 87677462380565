import { Select } from 'antd'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import React, { useMemo } from 'react'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'

export const SelectCountries = ({
  value,
  onChange,
  placeholder,
  Component = SelectWidget,
}) => {
  const { countries = [] } =
    useAppConfig()

  const options = useMemo(() => {
    return _.flatten(countries).map(
      (country, i) => (
        <Select.Option
          key={i}
          common_name={
            country.common_name
          }
          value={country.id}>
          {country.common_name}
        </Select.Option>
      )
    )
  }, [countries])
  return (
    <Component
      value={value}
      showSearch={true}
      style={{ display: 'block' }}
      loading={_.isEmpty(countries)}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.props.common_name
          .toLowerCase()
          .indexOf(
            input.toLowerCase()
          ) >= 0
      }>
      {options}
    </Component>
  )
}
