import React from 'react'

export const ChannelSvg = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 411.082 411.082"
      fill="currentColor"
      style={{strokeWidth: 3}}
      {...props}
    >
      <path d="M31.042 275.659l-2.32-6.44a8.954 8.954 0 01-4-1.12 8.842 8.842 0 01-4-4.8 7.394 7.394 0 00-.32-1.08l-5.08-17.4a8.878 8.878 0 01.76-6.76 8.97 8.97 0 012.92-3.2l-1.76-6.08a46.153 46.153 0 01-1.68-8.88C3.868 224.293-2.5 236.924.922 248.939l5.24 18.04c3.549 12.016 15.744 19.281 28 16.68a46.719 46.719 0 01-3.12-8zM194.043 356.419l-13.2-45.4h-15.6l14.48 49.8c2.141 7.546-2.011 15.444-9.44 17.96l-1.04.28c-7.602 1.838-15.314-2.589-17.56-10.08l-16.92-58.2h-15.6l18.28 62.28c4.634 15.589 20.977 24.52 36.6 20 15.609-4.633 24.545-21.005 20-36.64zM353.962 50.339c-17.52-24.72-36.52-37.28-53.28-32.48a26.71 26.71 0 00-11.6 7.04l-237.16 152a64.997 64.997 0 00-22.48 22.24 34.119 34.119 0 00-3.08 26.84l13.64 46.92a34.122 34.122 0 0017 21.04 65 65 0 0030.72 6.64l281.68 1.32a26.714 26.714 0 0013.64-.24c16.6-4.84 25.96-25.88 27.72-56 3.481-59.72-21.839-146.76-56.8-195.32zm-266.04 235.28a50.526 50.526 0 01-23.56-4.84 19.68 19.68 0 01-9.84-12l-13.64-46.92a19.679 19.679 0 011.84-15.48 50.563 50.563 0 0117.32-16.76l216-138.68a129.356 129.356 0 00-3.24 22.92c-3.36 59.76 22 146.84 56.96 195.4a129.397 129.397 0 0015 17.6l-256.84-1.24zm308.001-40.76v-.08c-1.36 23.64-7.12 39.68-16.92 42.52-9.8 2.84-23.28-7.6-37.12-26.84-15.2-21.12-29.92-52.32-40.44-88.6v-.8c-10.44-36-14.76-70.32-13.24-96.32 1.36-23.64 7.12-39.64 16.92-42.52 9.8-2.88 23.28 7.6 37.12 26.84 32.68 45.84 56.959 129.4 53.68 185.8z"></path>
    </svg>
  )
}

export const ChannelFillSvg = props => {
  return (
    <svg
      width="1em"
      height="1em"
      enableBackground="new 0 0 380.411 380.411"
      viewBox="0 0 380.411 380.411"
      style={{fill: 'var(--text-color)'}}
      {...props}
    >
      <path d="M28.556 258.593l-12.6-43.52a42.642 42.642 0 01-1.56-8C3.557 211.099-2.34 222.806.876 233.913l4.84 16.68c3.225 11.071 14.438 17.785 25.72 15.4a43.06 43.06 0 01-2.88-7.4zM327.636 49.913c-16.32-22.48-34.08-34.28-49.44-29.8a24.682 24.682 0 00-10.72 6.52l-219.16 140.6a59.999 59.999 0 00-20.68 20.44 31.522 31.522 0 00-2.84 24.84l12.6 43.36a31.56 31.56 0 0015.72 19.44 60.006 60.006 0 0028.4 6.16l260.36 1.24a24.677 24.677 0 0012.6-.24c15.32-4.44 24-24 25.64-52 3.2-55.2-20-135.68-52.48-180.56zm38.56 180c-1.28 21.84-6.6 36.64-15.64 39.28-28.72 8.36-64-81.68-70.48-102.6-9.84-32-15.32-66.28-13.68-93.92.246-4.597.74-9.177 1.48-13.72 1.32-7.56 5.04-22.92 14.16-25.56 9.12-2.64 21.52 7.04 34.28 24.8 30.56 42.36 52.96 119.6 49.88 171.72zM172.916 335.113l-12.68-44.08-42.56-.2 16 55.68c3.171 10.818 14.487 17.039 25.32 13.92 10.817-3.171 17.039-14.488 13.92-25.32z"></path>
    </svg>
  )
}
