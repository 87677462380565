import Pure from 'components/Pure'
import _ from 'lodash'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  Switch,
  useParams,
} from 'react-router-dom'
import { usePrevious } from 'react-use'
import { HighLightHeader } from 'views/Shared'
import { mapPathParam } from '../functions/routerHelper'
import { NavRoutes } from '../hooks/useNavRoutes'
import useSettingNavRoutes from '../hooks/useSettingNavRoutes'
import { FlexCol } from '../Templates/ItemTemplate'

export const DefaultSettingHeader = ({
  ...props
}) => {
  return (
    <HighLightHeader
      style={{
        border: 'none',
        background: 'transparent',
        padding: '0 0 0.25rem 0',
        borderBottom: '1px solid var(--color-primary)',
      }}
      {...props}
      Title={({ title }) => (
        <div className="text-lg font-bold text-primary uppercase">
          {title}
        </div>
      )}
    />
  )
}

export const createSettingActionHeader =
  (
    currentAction,
    customHeaderActionList,
    isModal
  ) => {
    let extra = null
    if (
      customHeaderActionList.includes(
        currentAction
      )
    ) {
      return null
    }
    return (
      <DefaultSettingHeader
        extra={extra}
        title={currentAction}
      />
    )
  }

const SettingContent = ({
  rootPath,
  routes = [],
  Context = {},
  Wrapper = FlexCol,
}) => {
  const params = useParams()
  const id = _.get(params, 'id')
  const defaultAction = _.get(
    routes,
    '[0].path'
  )

  const { isModal } = useContext(
    NavigationContext
  )

  const { currentAction } =
    useSettingNavRoutes(routes)

  const prev_action = usePrevious(
    currentAction
  )

  const { renderHeader } =
    useContext(Context) || {}

  const header = useMemo(() => {
    if (renderHeader) {
      return renderHeader(
        isModal
          ? prev_action
          : currentAction,
        isModal
      )
    } else {
      return (
        <DefaultSettingHeader
          title={currentAction}
        />
      )
    }
  }, [
    isModal,
    renderHeader,
    currentAction,
  ])

  return (
    <Wrapper className="space-y-3">
      {header}
      <NavRoutes
        routes={routes}
        rootPath={rootPath}
        transform={(path) =>
          _.isEmpty(id)
            ? path
            : mapPathParam(path, {
                id,
              })
        }
        renderRoutes={(
          routes,
          redirect
        ) => (
          <Switch>
            {routes}
            {redirect}
          </Switch>
        )}
        defaultAction={defaultAction}
      />
    </Wrapper>
  )
}

const createSettingContent = (
  Context,
  { routes, rootPath, Wrapper }
) => {
  return (
    <Pure>
      <SettingContent
        {...{
          routes,
          Context,
          rootPath,
          Wrapper,
        }}
      />
    </Pure>
  )
}

export default createSettingContent
