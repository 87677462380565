import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import RecruitmentContext from './RecruitmentContext'

const RecruitmenLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose(
  notFoundOnError(
    RecruitmentContext.Consumer
  )
)(RecruitmenLayout)
