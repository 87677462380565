import { Radio } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
} from 'react'
import { Null } from 'views/Shared'

const MedalsAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    active: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <Radio.Group
        name="active"
        buttonStyle="solid"
        className="flex flex-1 items-center justify-end w-full lg:w-auto"
        onChange={(event) => {
          const { name, value } =
            event.target
          handleChange(name, value)
        }}
        defaultValue={
          filter.active || undefined
        }>
        <Radio.Button value={undefined}>
          {t('all')}
        </Radio.Button>
        <Radio.Button value={'1'}>
          {t('active')}
        </Radio.Button>
        <Radio.Button value={'0'}>
          {t('inactive')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default MedalsAdvancedFilter
