import React from 'react'
import createStickySider from 'views/Wishare/factory/createStickySider'
import { ActivityHosts } from 'views/Wishare/Host/components/HostFactory'
import { WishareEntities } from '../../enums'
import createStatusBar from '../../factory/createStatusBar'
import ActivityContext from '../ActivityContext'
import ActivityImpactReport from './ActivityImpactReport'

export const ActivityStatusBar =
  createStatusBar(
    WishareEntities.ACTIVITY,
    ActivityContext
  )

const ActivitySticker = () => (
  <React.Fragment>
    <ActivityStatusBar />
    <ActivityHosts />
  </React.Fragment>
)

const ActivitySider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(
    ActivityImpactReport,
    ActivitySticker
  )

export default ActivitySider
