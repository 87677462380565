import { Avatar, Tag } from 'antd'
import { getId } from 'apis/model/base'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { IoMedalOutline } from 'react-icons/io5'
import {
    formatDate,
    Null,
    renderElse,
    renderIf,
} from 'views/Shared'

const renderMedalReward =
  (params) => (item) => {
    if (!!_.get(item, 'deleted')) {
      return null
    }

    const {
      showOwner = false,
      showStatus = false,
      renderContextMenu = Null,
    } = params || {}

    const {
      medal,
      owner,
      notes,
      eff_date,
      end_date,
      visible,
    } = item || {}

    const {
      tags,
      status,
      active,
      lvl_number,
    } = medal || {}

    return (
      <Translate key={getId(item)}>
        {(t) => (
          <div className="relative w-full border border-color-50 rounded-lg background">
            <div className="flex items-center p-2 gap-2">
              <Avatar
                size={40}
                src={getAvatar(medal)}
                icon={
                  <IoMedalOutline
                    style={{
                      color: '#bdbdbd',
                    }}
                  />
                }
                className="flex flex-center background-200"
              />
              <div className="flex flex-col flex-1 gap-1">
                  <div className="font-bold leading-tight max-lines-1">
                      {getTitle(medal)}
                  </div>
                  {showOwner && renderIf(_.get(medal, 'owner'),
                      <div className="flex items-center gap-1 text-2xs italic ">
                          <span className="font-light text-color-400">
                            {t('by')}
                          </span>
                          <span className="text-color-org font-semibold flex-1">
                            {_.get(medal, 'owner.title')}
                          </span>
                      </div>
                  )}
                  {/*{renderElse(_.isEmpty(tags),*/}
                  {/*  <div className="flex flex-wrap italic text-xs text-secondary font-light leading-tight">*/}
                  {/*    {Array.from(*/}
                  {/*      tags || []*/}
                  {/*    )*/}
                  {/*      .map(*/}
                  {/*        ({ tags }) =>*/}
                  {/*          `#${tags}`*/}
                  {/*      )*/}
                  {/*      .join(', ')}*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  {
                      _.get(item, 'eff_date') && (
                          <div className="flex items-baseline gap-1 text-2xs leading-tight ">
                              <span className=" italic text-color-500 font-light">{t('effect date')} {':'}</span>
                              <span className="text-color-200 font-medium">{formatDate(_.get(item, 'eff_date'))}</span>
                          </div>
                      )
                  }
                  {
                      _.get(item, 'notes') && (
                          <div className="flex items-baseline gap-1 text-2xs leading-tight">
                              <span className=" italic text-color-500 font-light">{t('notes')} {':'}</span>
                              <span className="text-color-200 font-medium">{_.get(item, 'notes')}</span>
                          </div>
                      )
                  }
                  {showStatus &&
                  renderElse(
                      !!visible,
                      <div
                          className="absolute top-0 mt-1"
                          style={{
                              right: '3rem',
                          }}>
                          <Tag
                              className="w-fit italic mr-0 "
                              color="warning">
                              {t('invisible')}
                          </Tag>
                      </div>
                  )}
              </div>
              {renderContextMenu(item)}
            </div>
          </div>
        )}
      </Translate>
    )
  }

export default renderMedalReward
