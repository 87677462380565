export function getGivingDetail(givingItem, giving, givingEvent) {
  if (!givingItem) return {};
  if (!givingEvent) return {};

  const convertedCurrency = givingEvent.target_unit_name;
  // const isConvertedCurrency = (givingItem.item_name === givingItem.unit_name && givingItem.item_name === convertedCurrency);
  const isConvertedCurrency = (
    (givingItem.item_name === givingItem.unit_name && givingItem.item_name === convertedCurrency)
    || givingItem.item_name === 'blood'
  );
  const isCompleted = (giving.status === 1);
  const isConversionRequired = (givingEvent.conversion_required === 1);
  const isCurrencyItem = (givingItem.item_name === givingItem.unit_name);

  return {
    ...givingItem,
    convertedCurrency,
    isConvertedCurrency,
    isCompleted,
    isConversionRequired,
    isCurrencyItem
  }
}