import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
} from 'react'
import {
  IoBarChartOutline,
  IoNotificationsOutline,
} from 'react-icons/io5'
import renderLoading from 'views/MainPage/functions/renderPagingLoading'
import MainContext from 'views/MainPage/MainContext'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import { SettingsMenu } from 'views/Wishare/factory/createSettingLayout'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import WorkspaceInsights from '../Insights/WorkspaceInsights'
import { useMenuHandler } from '../useMenuHandler'
import { useScrollTo } from '../useScrollTo'
import WorkspaceHomeGallery, {
  WorkspaceGalleryTypes,
} from './WorkspaceHomeGallery'
import WorkspaceHomeGroup, {
  WorkspaceHomeGroups,
} from './WorkspaceHomeGroup'

const ItemKeys = Object.freeze({
  NOTIFICATION: 'notification',
  INSIGHTS: 'insights',
})

const menuItems = [
  {
    key: WorkspaceHomeGroups.NOTIFICATION,
    label: 'latest updates',
    icon: (
      <IoNotificationsOutline
        size={16}
      />
    ),
  },
  {
    key: WorkspaceHomeGroups.INSIGHTS,
    label: 'insights',
    icon: (
      <IoBarChartOutline size={16} />
    ),
  },
]

const WorkspaceHomeContent = () => {
  const t = useTranslate()
  return (
    <div className="w-full space-y-6">
      {[
        [
          {
            label: 'latest post',
            type: WorkspaceHomeGroups.LASTED_FEEDS,
          },
          {
            label:
              'latest followed user',
            type: WorkspaceHomeGroups.LASTED_FOLLOWERS,
          },
        ],
        [
          {
            label: 'latest donations',
            type: WorkspaceHomeGroups.LASTED_DONATIONS,
          },
          {
            label: 'latest giving',
            type: WorkspaceHomeGroups.LASTED_GIVINGS,
          },
        ],
        [
          {
            label: 'latest apply',
            type: WorkspaceHomeGroups.LASTED_CANDIDATES,
          },
        ],
      ].map((array, index) => (
        <div
          key={index}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-6">
          {Array.from(array).map(
            (
              { type, label },
              index
            ) => (
              <FlexCol
                id={type}
                key={index}>
                <div className="mb-2 font-semibold text-color-000 border-b border-color-50">
                  {t(label)}
                </div>
                <WorkspaceHomeGroup
                  type={type}
                />
              </FlexCol>
            )
          )}
        </div>
      ))}
      {[
        {
          label: 'recent activity',
          type: WorkspaceGalleryTypes.RECENT_ACTIVITIES,
        },
        {
          label: 'recent campaigns',
          type: WorkspaceGalleryTypes.RECENT_EVENTS,
        },
      ].map(
        ({ label, type }, index) => (
          <div
            key={index}
            className="mb-4"
            id={type}>
            <div className="mb-1 font-medium text-color-200 uppercase">
              {t(label)}
            </div>
            <WorkspaceHomeGallery
              type={type}
            />
          </div>
        )
      )}
    </div>
  )
}

const routeEntities = [
  {
    key: ItemKeys.NOTIFICATION,
    component: WorkspaceHomeContent,
  },
  {
    key: ItemKeys.INSIGHTS,
    component: WorkspaceInsights,
  },
].map(({ key, ...rest }) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceHome = () => {
  const [scrollTo = Null] = useScrollTo(
    { offset: 60 }
  )
  const { isLoading } = useContext(
    MainContext
  )

  const {
    activeKey,
    Component,
    onMenuSelect,
  } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <WishareSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <SettingsMenu
          items={menuItems}
          onSelect={onMenuSelect}
          defaultSelectedKeys={[
            activeKey,
          ]}
        />
      </WishareSiderMenu>
    ),
    [menuItems, activeKey]
  )

  return (
    <DefaultTemplate
      renderMenu={renderMenu}>
      <div className="space-y-3 px-3">
        {!!isLoading ? (
          renderLoading()
        ) : (
          <Component />
        )}
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceHome
