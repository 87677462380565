import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Input,
  InputNumber,
  Segmented,
  Tooltip,
} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import FieldsFactory from 'components/form/FieldsFactory'
import { StepsForm } from 'components/form/StepsForm'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import {
  Field,
  useFormikContext,
} from 'formik'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useEffect,
  useState,
} from 'react'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { formatterNumber } from '../../helpers/formatterNumber'
import { parseNumber } from '../../helpers/parseNumber'

const Answers = ({
  onChange,
  defaultValue = [],
}) => {
  const t = useTranslate()
  const [answers, setAnswers] =
    useState(defaultValue)
  const [newAnswer, setNewAnswer] =
    useState('')
  useEffect(() => {
    onChange(answers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])
  const handleAddNewAnswer = (e) => {
    e.preventDefault()
    setAnswers([...answers, newAnswer])
    setNewAnswer('')
  }
  const handleRemoveAnswer = (
    value
  ) => {
    setAnswers([
      ...answers.filter(
        (e, index) => value !== e
      ),
    ])
  }
  return (
    <>
      {answers.map((value, i) => (
        <Input
          className="mb-3 animated fadeInUp faster"
          key={i}
          onChange={(e) => {
            const newAnswers = [
              ...answers,
            ]
            newAnswers[i] =
              e.target.value
            setAnswers(newAnswers)
          }}
          addonAfter={
            <Tooltip
              title={t('remove')}>
              <CloseOutlined
                onClick={() => {
                  handleRemoveAnswer(
                    value
                  )
                }}
              />
            </Tooltip>
          }
          defaultValue={value}
        />
      ))}
      <form
        onSubmit={handleAddNewAnswer}>
        <div className="flex flex-col">
          <Input
            key="create new answer"
            onChange={(e) => {
              setNewAnswer(
                e.target.value
              )
            }}
            placeholder={t(
              'your answer'
            )}
            value={newAnswer}
          />
          <div className="flex mt-2">
            <div className="flex-1" />
            <Button
              onClick={
                handleAddNewAnswer
              }
              className="rounded-md text-xs no-shadow no-text-shadow font-medium text-color-300"
              size={'small'}>
              {t('add new answer')}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

const PollForm = ({
  onBack,
  onchange,
}) => {
  const t = useTranslate()
  const { values } = useFormikContext()

  return (
    <>
      <Pure>
        <FieldsFactory
          formSchema={[
            {
              title: t(
                'what is your question?'
              ),
              children: [
                {
                  name: 'title',
                  component:
                    createControlledFormField(
                      {
                        placeholder:
                          'title or question',
                      }
                    ),
                },
              ],
            },
          ]}
        />
      </Pure>

      <Divider className="my-3" />
      <Field
        name="is_yesno_question"
        render={({ field }) => (
          <FieldDecorator
            hasError="false"
            className="w-full col-span-2"
            name="is_yesno_question"
            label={t(
              'is yes/no question'
            )}>
            <Segmented
              className="rounded no-shadow"
              defaultValue={_.get(
                values,
                'is_yesno_question',
                0
              )}
              options={[
                {
                  label: t('yes'),
                  value: 1,
                },
                {
                  label: t('no'),
                  value: 0,
                },
              ]}
              onChange={(value) =>
                field.onChange(
                  createValue(
                    'is_yesno_question',
                    value
                  )
                )
              }
            />
          </FieldDecorator>
        )}
      />
      {!!!_.get(
        values,
        'is_yesno_question',
        0
      ) && (
        <Field
          name="answers"
          render={({
            field,
            form: {
              values = {},
              touched = {},
              errors = {},
            } = {
              touched: {},
              errors: {},
            },
          }) => (
            <FieldDecorator
              hasError="false"
              label={t('answers')}>
              <div className="flex flex-col">
                <Answers
                  onChange={(value) => {
                    field.onChange(
                      createValue(
                        'answers',
                        value
                      )
                    )
                  }}
                />
              </div>
            </FieldDecorator>
          )}
        />
      )}
      <Divider className="my-3" />

      <Field
        name="is_single_selection"
        render={({ field }) => (
          <FieldDecorator
            hasError="false"
            className="w-full col-span-2"
            name="is_single_selection"
            label={t(
              'choose number of answers'
            )}>
            <Segmented
              className="rounded no-shadow"
              disabled={
                !!_.get(
                  values,
                  'is_yesno_question',
                  0
                )
              }
              defaultValue={_.get(
                values,
                'is_single_selection',
                0
              )}
              value={
                !!_.get(
                  values,
                  'is_yesno_question',
                  0
                )
                  ? 1
                  : _.get(
                      values,
                      'is_single_selection',
                      0
                    )
              }
              options={[
                {
                  label: t(
                    'one answer'
                  ),
                  value: 1,
                },
                {
                  label: t(
                    'multiple answer'
                  ),
                  value: 0,
                },
              ]}
              onChange={(value) =>
                field.onChange(
                  createValue(
                    'is_single_selection',
                    value
                  )
                )
              }
            />
          </FieldDecorator>
        )}
      />
      {!!!_.get(
        values,
        'is_yesno_question',
        0
      ) &&
        !!!_.get(
          values,
          'is_single_selection'
        ) && (
          <Field
            name="vote_on_maximum"
            render={({
              field,
              form,
            }) => (
              <FieldDecorator
                className="w-full col-span-2"
                name="vote_on_maximum"
                label={t(
                  'number of choices'
                )}>
                <InputNumber
                  min={2}
                  max={_.get(
                    form,
                    'values.answers',
                    1
                  )}
                  name="vote_on_maximum"
                  inputMode={'numeric'}
                  className="w-full"
                  formatter={
                    formatterNumber
                  }
                  parser={parseNumber}
                  defaultValue={1}
                  value={
                    !!_.get(
                      values,
                      'is_yesno_question',
                      0
                    )
                      ? 1
                      : _.get(
                          values,
                          'vote_on_maximum',
                          1
                        )
                  }
                  onChange={(value) => {
                    field.onChange(
                      createValue(
                        'vote_on_maximum',
                        value
                      )
                    )
                  }}
                />
              </FieldDecorator>
            )}
          />
        )}

      <Divider className="my-3"/>
      <Field
        name="customizable_answers"
        render={({ field }) => (
          <Checkbox
            disabled={
              !!_.get(
                values,
                'is_yesno_question',
                0
              )
            }
            checked={Number(
              field.value
            )}
            onChange={(e) =>
              field.onChange(
                createValue(
                  'customizable_answers',
                  e.target.checked
                    ? '1'
                    : '0'
                )
              )
            }>
            {t('allow add new answers')}
          </Checkbox>
        )}
      />
    </>
  )
}

const CreatePollRoutes = [
  {
    path: '/questtionAndAnswers',
    // name: 'create a poll',
    component: PollForm,
    exact: true,
  },
]
const tranformBeforeSubmit = (
  values = {}
) => ({
  ...values,
  vote_on_maximum:
    !!values.is_single_selection ||
    !!values.is_yesno_question
      ? 1
      : values.vote_on_maximum,
  answers: JSON.stringify(
    values.answers.map((str) => ({
      title: str,
    }))
  ),
})

export default function CreatePoll({
  initialValues = {
    title: '',
    vote_on_maximum: 1,
    customizable_answers: 0,
    is_single_selection: 1,
    is_yesno_question: 0,
  },
  onSubmit,
  isLoading,
}) {
  const handleSubmit = (values) => {
    onSubmit(
      tranformBeforeSubmit(values)
    )
  }
  return (
    <StepsForm
      style={{ minHeight: 'auto' }}
      isLoading={isLoading}
      routes={CreatePollRoutes}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}
