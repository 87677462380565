import React from "react";

export const Old = ({...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 3"
			viewBox="0 0 64 64"
		>
			<path fill="#9d9e9d" d="M52.939 5.605a3 3 0 10-5.887-.044z"></path>
			<path
				fill="#bcbdbc"
				d="M42 15.268V13a8 8 0 018-8 8 8 0 018 8v2.269z"
			></path>
			<path
				fill="#dfe0df"
				d="M50 5a7.957 7.957 0 00-4.888 1.676A7.953 7.953 0 0044 10.731 2.269 2.269 0 0046.268 13H58a8 8 0 00-8-8z"
			></path>
			<path fill="#747574" d="M10 32h4v11h-4z"></path>
			<path fill="#684d42" d="M2 25.999h60V32H2z"></path>
			<path fill="#745749" d="M12 26a3 3 0 003 3h47v-3z"></path>
			<path fill="#684d42" d="M2 42.999h60V49H2z"></path>
			<path fill="#745749" d="M12 43a3 3 0 003 3h47v-3z"></path>
			<path
				fill="#37c"
				d="M20 21l-3.5 1.4A10.339 10.339 0 0010 32h5v11h16V32h5v-4a4.431 4.431 0 00-2.785-4.114L26 21z"
			></path>
			<path
				fill="#3e88de"
				d="M33.215 23.886L26 21h-6l-3.5 1.4c-.159.064-.313.137-.468.209A19.977 19.977 0 0031 41.344V32h5v-4a4.431 4.431 0 00-2.785-4.114z"
			></path>
			<path
				fill="#ffe1d3"
				d="M15 32v4l-.34.5A5.607 5.607 0 0110 39a2.015 2.015 0 00-2-2H6l1.32-.66A4.853 4.853 0 0010 32z"
			></path>
			<g fill="#747574">
				<path d="M50 32h4v11h-4zM9 49v13H5V49M59 49v13h-4V49"></path>
			</g>
			<path
				fill="#eeb8ab"
				d="M17 11h-1a2 2 0 00-2 2 2 2 0 002 2h1M29 11h1a2 2 0 012 2 2 2 0 01-2 2h-1"
			></path>
			<path
				fill="#f3c350"
				d="M12 41h6v14a3 3 0 01-3 3 3 3 0 01-3-3V41z"
				transform="rotate(180 15 49.5)"
			></path>
			<path fill="#eeb8ab" d="M20 20.2v.8a3 3 0 003 3 3 3 0 003-3v-.8"></path>
			<path fill="#1a489c" d="M14 29h2v3h-2zM30 29h2v3h-2z"></path>
			<path
				fill="#ffe1d3"
				d="M36 32v5a3 3 0 01-3 3h-6.5a2.5 2.5 0 01-2.5-2.5 2.5 2.5 0 012.5-2.5H31v-3z"
			></path>
			<path
				fill="#d8a89e"
				d="M44.341 15H43a2 2 0 00-2 2 2 2 0 002 2h1M55.658 15H57a2 2 0 012 2 2 2 0 01-2 2h-1"
			></path>
			<path
				fill="#c33e37"
				d="M57 44H43a3 3 0 01-3-3V31a5 5 0 015-5h10a5 5 0 015 5v10a3 3 0 01-3 3z"
			></path>
			<path
				fill="#e15240"
				d="M55 26H45a4.959 4.959 0 00-2.624.761 18.99 18.99 0 0017.459 15.18A3 3 0 0060 41V31a5 5 0 00-5-5z"
			></path>
			<path
				fill="#ffe1d3"
				d="M48.5 39a2.5 2.5 0 012.5 2.5 2.5 2.5 0 01-2.5 2.5z"
			></path>
			<path
				fill="#684d42"
				d="M48 58a3.415 3.415 0 011 2.414A1.586 1.586 0 0147.415 62h-4.829A1.586 1.586 0 0141 60.414 3.411 3.411 0 0142 58M18 58a3.415 3.415 0 011 2.414A1.586 1.586 0 0117.415 62h-4.829A1.586 1.586 0 0111 60.414 3.411 3.411 0 0112 58z"
			></path>
			<path
				fill="#a82e25"
				d="M56 32h-2v6h-8v-6h-2v7a1 1 0 001 1h10a1 1 0 001-1z"
			></path>
			<path fill="#37c" d="M9 62H7V42a1 1 0 00-2 0H3a3 3 0 016 0z"></path>
			<rect
				width="12"
				height="16"
				x="17"
				y="5"
				fill="#ffe1d3"
				rx="6"
				transform="rotate(180 23 13)"
			></rect>
			<path fill="#fcef88" d="M15 47v11h3V44a3 3 0 00-3 3z"></path>
			<path fill="#f3c350" d="M31 41a3 3 0 013 3v14h-6V44a3 3 0 013-3z"></path>
			<path fill="#fcef88" d="M31 47v11h-3V44a3 3 0 013 3z"></path>
			<path
				fill="#745749"
				d="M18 58h-4.959a3.374 3.374 0 00-.041.414A1.586 1.586 0 0014.586 60h4.373A3.4 3.4 0 0018 58z"
			></path>
			<path
				fill="#684d42"
				d="M34 58a3.415 3.415 0 011 2.414A1.586 1.586 0 0133.415 62h-4.829A1.586 1.586 0 0127 60.414 3.411 3.411 0 0128 58z"
			></path>
			<path
				fill="#745749"
				d="M34 58h-4.959a3.374 3.374 0 00-.041.414A1.586 1.586 0 0030.586 60h4.373A3.4 3.4 0 0034 58z"
			></path>
			<path fill="#eeb8ab" d="M47 24.2v.8a3 3 0 003 3 3 3 0 003-3v-.8"></path>
			<rect
				width="12"
				height="14"
				x="44"
				y="11"
				fill="#eeb8ab"
				rx="6"
				transform="rotate(180 50 18)"
			></rect>
			<path
				fill="#ffe1d3"
				d="M50 12a6 6 0 00-6 6v1a6 6 0 0012 0v-1a6 6 0 00-6-6z"
			></path>
			<path
				fill="#745749"
				d="M48 58h-4.959a3.374 3.374 0 00-.041.414A1.586 1.586 0 0044.586 60h4.373A3.4 3.4 0 0048 58z"
			></path>
			<path
				fill="#684d42"
				d="M58 58a3.415 3.415 0 011 2.414A1.586 1.586 0 0157.415 62h-4.829A1.586 1.586 0 0151 60.414 3.411 3.411 0 0152 58"
			></path>
			<path
				fill="#745749"
				d="M58 58h-4.959a3.374 3.374 0 00-.041.414A1.586 1.586 0 0054.586 60h4.373A3.4 3.4 0 0058 58z"
			></path>
			<rect width="6" height="4" x="4" y="37" fill="#eeb8ab" rx="2"></rect>
			<path
				fill="#9d9e9d"
				d="M52 42h6v13a3 3 0 01-3 3 3 3 0 01-3-3V42z"
				transform="rotate(180 55 50)"
			></path>
			<path
				fill="#9d9e9d"
				d="M42 42h6v13a3 3 0 01-3 3 3 3 0 01-3-3V42z"
				transform="rotate(180 45 50)"
			></path>
		</svg>
	);
}

export default Old;
