import React, {
  useContext,
  useMemo,
} from 'react'
import { withProps } from 'recompose'
import LoadingPage from 'views/LoadingPage'
import { StaffBanner } from 'views/MainPage/WorkSpace/HumanResources/StaffBanner'
import { BeneficiaryBanner } from 'views/Wishare/Beneficiary/components/BeneficiaryBanner'
import { DonorBanner } from 'views/Wishare/Donor/components/DonorBanner'
import { WishareEntities } from 'views/Wishare/enums'
import OrganizationBanner from '../components/OrganizationBanner'
import { OrganizationProfilesContext } from './OrganizationProfiles'

const OrganizationProfilesBanner =
  React.memo(({ Wrapper = 'div' }) => {
    const {
      type,
      item,
      isLoading = false,
    } = useContext(
      OrganizationProfilesContext
    )

    const content = useMemo(() => {
      let Banner = OrganizationBanner

      switch (type) {
        case WishareEntities.DONOR:
          Banner = withProps({
            donor: item,
          })(DonorBanner)
          break
        case WishareEntities.BENEFICIARY:
          Banner = withProps({
            beneficiary: item,
          })(BeneficiaryBanner)
          break
        case WishareEntities.STAFF:
          Banner = withProps({
            staff: item,
          })(StaffBanner)
          break
        default:
          break
      }
      return <Banner />
    }, [item])

    if (!!isLoading) {
      return (
        <div className="flex flex-center banner-activity bg-color-org">
          <LoadingPage />
        </div>
      )
    }

    return <Wrapper>{content}</Wrapper>
  })

export default OrganizationProfilesBanner
