import {Avatar, Calendar, DatePicker, Input, Radio, Select, TimePicker,} from 'antd'
import {eventModel} from 'apis/model'
import classNames from 'classnames'
import ContentContainer from 'components/ContentContainer'
import {FormikConsumer, useFormikContext,} from 'formik'
import getCoverPhoto from 'helpers/getCoverPhoto'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, {useContext,} from 'react'
import {FaUser} from 'react-icons/fa'
import {IoIosLaptop, IoIosPeople, IoMdAlarm, IoMdCloudOutline,} from 'react-icons/io'
import {
  IoAddCircleOutline,
  IoCalendarOutline,
  IoRemoveCircleOutline,
  IoTimeOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {connect} from 'react-redux'
import {compose, mapProps, pure, withProps,} from 'recompose'
import {SelectCategories} from 'views/Category/components/SelectCategories'
import {createControlledFormField} from 'views/Wishare/factory/createFormField'
import {event_edit_Api} from '../../../apis/event'
import ApiInfoForm from '../../../components/form/ApiInfoForm'
import FieldsFactory from '../../../components/form/FieldsFactory'
import FormActionBar from '../../../components/form/FormActionBar'
import {createValue} from '../../../components/form/utils'
import {LoginContext} from '../../../components/LoginContext'
import LookupField from '../../../components/LookupField'
import Toggle from '../../../components/Toggle'
import UseState from '../../../components/UseState'
import {emptyArray} from '../../../helpers/emptyObjects'
import {getCacheSelector} from '../../../modules/asyncCache/selectors'
import EventContext from '../EventContext'
import {BsCheck} from "react-icons/bs";

const intervalTypeOptions = [
  {value: 'N', label: 'none'},
  {value: 'D', label: 'daily'},
  {value: 'W', label: 'weekly'},
  {value: 'M', label: 'monthly'},
]

const weekOptions = [
  {key: 0, value: 'M', label: 'MON'},
  {key: 1, value: 'T', label: 'TUE'},
  {key: 2, value: 'W', label: 'WED'},
  {key: 3, value: 'T', label: 'THU'},
  {key: 4, value: 'F', label: 'FRI'},
  {key: 5, value: 'S', label: 'SAT'},
  {key: 6, value: 'S', label: 'SUN'},
]

const monthOptions = [
  {value: 0, label: 'first of month'},
  ...new Array(31)
    .fill(1)
    .map((e, i) => ({
      value: i + 1,
      label: (i + 1).toString(),
    })),
  {value: 99, label: 'end of month'},
]

export const eventTypes = [
  {
    id: 'meeting',
    icon: <IoIosPeople/>,
    title: 'meeting',
  },
  {
    id: 'webinar',
    icon: <IoIosLaptop/>,
    title: 'online',
  },
  {
    id: 'hybrid',
    icon: <IoMdCloudOutline/>,
    title: 'hybrid event',
  },
  {
    id: 'reminder',
    icon: <IoMdAlarm/>,
    title: 'reminder',
  },
]

export const EventTypeIcon = ({
                                type,
                              }) => {
  const eventType = eventTypes.filter(
    (e) => e.id === type
  )
  if (eventType.length)
    return eventType[0].icon

  return null
}

export const CoHostsField = ({
                               label,
                               onChange,
                               ...props
                             }) => {
  const t = useTranslate()

  const initCohosts = _.get(
    props,
    'form.values.co_hosts',
    []
  )

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initCohosts}>
        {([cohosts, setCohosts]) => {
          return (
            <div className="flex flex-col space-y-3">
              {cohosts
                .map(
                  (e, remapIndex) => ({
                    ...e,
                    key: remapIndex,
                  })
                )
                .map((e, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex items-center space-x-3">
                      <Avatar
                        size={25}
                        className="border border-color-50 flex flex-center background-100"
                        icon={<FaUser className="text-color-400"/>}
                        src={_.get(e, 'avatar')}
                      />
                      <div className="font-medium text-color-000 text-xs flex-1 leading-tight">
                        {e.value}
                      </div>
                      <IoTrashOutline
                        onClick={() => {
                          const _cohosts =
                            cohosts
                              .map(
                                (
                                  e,
                                  remapIndex
                                ) => ({
                                  ...e,
                                  key: remapIndex,
                                })
                              )
                              .filter(
                                (el) =>
                                  el.key !==
                                  e.key
                              )

                          onChange(
                            createValue(
                              'co_hosts',
                              [
                                ..._cohosts,
                              ]
                            )
                          )
                          setCohosts([
                            ..._cohosts,
                          ])
                        }}
                        size={14}
                        className="float-right text-red-500 hover:text-red-600 cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              <div className="flex flex-col py-1">
                {!!label && (
                  <div className="text-xs text-color-300">
                    {t(label)}
                  </div>
                )}
                <LookupField
                  apiPath={`/lookup/user,organization/get-by-types`}
                  {...props}
                  handleChange={(e) => {
                    onChange(
                      createValue(
                        'co_hosts',
                        [
                          ...cohosts,
                          e.target,
                        ]
                      )
                    )
                    setCohosts([
                      ...cohosts,
                      e.target,
                    ])
                  }}
                />
              </div>
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const CoAdminsField = ({
                                label,
                                onChange,
                                ...props
                              }) => {
  const t = useTranslate()

  const initAdmins = _.get(
    props,
    'form.values.co_admins',
    []
  )

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initAdmins}>
        {([admins, setAdmins]) => {
          return (
            <div className="flex flex-col gap-2">
              {admins.map(
                (e, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <Avatar
                        size={25}
                        className="border border-color-50 flex flex-center background-100"
                        icon={<FaUser className="text-color-400"/>}
                        src={_.get(e, 'avatar')}
                      />
                      <div className="font-medium text-color-000 text-xs flex-1 leading-tight">
                        {e.value}
                      </div>
                      {!!e.editable && (
                        <IoTrashOutline
                          onClick={() => {
                            const _admins =
                              admins.filter(
                                (el) =>
                                  el.id !==
                                  e.id
                              )

                            onChange(
                              createValue(
                                'co_admins',
                                [
                                  ..._admins,
                                ]
                              )
                            )
                            setAdmins([
                              ..._admins,
                            ])
                          }}
                          size={14}
                          className="float-right text-red-500 hover:text-red-600 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                )
              )}
              <div className="flex flex-col py-1">
                {!!label && (
                  <div className="text-xs text-color-300">
                    {t(label)}
                  </div>
                )}
                <LookupField
                  apiPath={`/lookup/user/get-by-types`}
                  {...props}
                  handleChange={(e) => {
                    onChange(
                      createValue(
                        'co_admins',
                        [
                          ...admins,
                          e.target,
                        ]
                      )
                    )
                    setAdmins([
                      ...admins,
                      e.target,
                    ])
                  }}
                />
              </div>
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const SpeakersField = ({
                                label,
                                onChange,
                                ...props
                              }) => {
  const t = useTranslate()

  const initSpeakers = _.get(
    props,
    'form.values.speakers',
    []
  )

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initSpeakers}>
        {([speakers, setSpeakers]) => {
          return (
            <div className="flex flex-col space-y-3">
              {speakers
                .map(
                  (e, remapIndex) => ({
                    ...e,
                    key: remapIndex,
                  })
                )
                .map((e, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex items-center space-x-3">
                      <Avatar
                        size={25}
                        className="border border-color-50 flex flex-center background-100"
                        icon={<FaUser className="text-color-400"/>}
                        src={_.get(e, 'avatar')}
                      />
                      <div className="font-medium text-color-000 text-xs flex-1 leading-tight">
                        {e.value}
                      </div>
                      <IoTrashOutline
                        onClick={() => {
                          const _speakers =
                            speakers
                              .map(
                                (
                                  e,
                                  remapIndex
                                ) => ({
                                  ...e,
                                  key: remapIndex,
                                })
                              )
                              .filter(
                                (el) =>
                                  el.key !==
                                  e.key
                              )

                          onChange(
                            createValue(
                              'speakers',
                              [
                                ..._speakers,
                              ]
                            )
                          )
                          setSpeakers([
                            ..._speakers,
                          ])
                        }}
                        size={14}
                        className="float-right text-red-500 hover:text-red-600 cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              <div className="flex flex-col py-1">
                {!!label && (
                  <div className="text-xs text-color-300">
                    {t(label)}
                  </div>
                )}
                <LookupField
                  apiPath={`/lookup/user/get-by-types`}
                  {...props}
                  handleChange={(e) => {
                    onChange(
                      createValue(
                        'speakers',
                        [
                          ...speakers,
                          e.target,
                        ]
                      )
                    )
                    setSpeakers([
                      ...speakers,
                      e.target,
                    ])
                  }}
                />
              </div>
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const EventTypeRadioField = ({
                                      label,
                                      onChange,
                                      ...props
                                    }) => {
  const t = useTranslate()

  return (
    <div className="flex flex-col py-1">
      {!!label && (
        <div className="text-xs text-color-300">
          {t(label)}
        </div>
      )}
      <Radio.Group
        size={'small'}
        buttonStyle="solid"
        {...props}
        onChange={(e) => {
          onChange(
            createValue(
              'event_type',
              e.target.value
            )
          )
        }}>
        {[
          {
            id: 'meeting',
            icon: <IoIosPeople/>,
            title: 'meeting',
          },
          {
            id: 'webinar',
            icon: <IoIosLaptop/>,
            title: 'online',
          },
          {
            id: 'hybrid',
            icon: <IoMdCloudOutline/>,
            title: 'hybrid event',
          },
          {
            id: 'reminder',
            icon: <IoMdAlarm/>,
            title: 'reminder',
          },
        ].map((e, i) => (
          <Radio.Button
            className
            key={i}
            value={e.id}>
            <div className="space-x-1 flex items-center justify-center">
              <div className="flex items-center justify-center">
                {e.icon}
              </div>
              <div className="text-xs">
                {t(e.title)}
              </div>
            </div>
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  )
}

export const CustomDateAndTimeRangeField =
  ({onChange, ...props}) => {
    const t = useTranslate()
    return (
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-2 gap-2 px-2 py-1 border rounded-lg background">
          <div className="flex flex-col">
            <div className="text-2xs text-color-500 italic">
              {t('start date')}
            </div>
            <DatePicker
              bordered={false}
              name="start_date"
              defaultValue={_.get(
                props,
                'form.values.start_date',
                moment()
              )}
              format={'DD/MM/YYYY'}
              placeholder={t('start date')}
              suffixIcon={<IoCalendarOutline/>}
              className="custom-date-picker"
              onChange={(value) => {
                onChange(
                  createValue(
                    'start_date',
                    value
                  )
                )
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-2xs text-color-500 italic">
              {`${t('start time')} (UTC${moment().format('Z')})`}
            </div>
            <TimePicker
              bordered={false}
              name="start_time"
              defaultValue={_.get(
                props,
                'form.values.start_time',
                moment()
              )}
              placeholder={t('start time')}
              suffixIcon={<IoTimeOutline/>}
              className="custom-date-picker"
              onChange={(value) => {
                onChange(
                  createValue(
                    'start_time',
                    value
                  )
                )
              }}
              format={'HH:mm'}
            />
          </div>
        </div>

        <Toggle defaultValue={!!_.get(props, 'form.values.end_date')}>
          {(isToggle, toggle) => {
            return isToggle ? (
              <div className="flex flex-col">
                <div className="grid grid-cols-2 gap-2 px-2 py-1 border background rounded-lg">
                  <div className="flex flex-col">
                    <div className="text-2xs text-color-500 italic">
                      {t('end date')}
                    </div>
                    <DatePicker
                      bordered={false}
                      defaultValue={_.get(
                        props,
                        'form.values.end_date',
                        moment()
                      )}
                      format={'DD/MM/YYYY'}
                      placeholder={t('end date')}
                      suffixIcon={<IoCalendarOutline/>}
                      className="custom-date-picker"
                      onChange={(
                        value
                      ) => {
                        onChange(
                          createValue(
                            'end_date',
                            value
                          )
                        )
                      }}
                      {...props}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="text-2xs text-color-500 italic">
                      {t('end time')}
                    </div>
                    <TimePicker
                      bordered={false}
                      defaultValue={_.get(
                        props,
                        'form.values.end_time',
                        moment()
                      )}
                      placeholder={t('end time')}
                      suffixIcon={<IoTimeOutline/>}
                      className="custom-date-picker"
                      onChange={(
                        value
                      ) => {
                        onChange(
                          createValue(
                            'end_time',
                            value
                          )
                        )
                      }}
                      format={'HH:mm'}
                      {...props}
                    />
                  </div>
                </div>
                <div className="flex items-center text-color-300 hover:text-primary cursor-pointer mt-2"
                     onClick={() => {
                       onChange(
                         createValue(
                           'time_close',
                           null
                         )
                       )
                       onChange(
                         createValue(
                           'end_date',
                           null
                         )
                       )
                       onChange(
                         createValue(
                           'end_time',
                           null
                         )
                       )
                       toggle()
                     }}>
                  <IoRemoveCircleOutline/>
                  <span className="ml-1 font-medium text-xs italic">
                    {t('end date and time')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex items-center text-color-300 hover:text-primary cursor-pointer"
                   onClick={toggle}>
                <IoAddCircleOutline/>
                <span className="ml-1 font-medium text-xs italic">
                        {t('end date and time')}
                    </span>
              </div>
            )
          }}
        </Toggle>
      </div>
    )
  }

export const EventOverviewFieldSet =
  () => {
    const t = useTranslate()
    const {values} = useFormikContext()

    const TitleHeader = ({title, class_name}) => (
      <div
        className={classNames('font-medium text-xs text-color-400 italic mb-3 border-b tracking-wider uppercase', class_name)}>
        {t(title)}
      </div>
    )

    return (
      <React.Fragment>
        <div className="background-100 overflow-hidden p-2"
             style={{
               width: '100%',
               paddingTop: '40%',
               position: 'relative',
             }}>
          <img
            className="w-full h-full object-cover absolute top-0 left-0"
            src={getCoverPhoto(values) || "/images/default/event-bg.jpg"}
            alt={_.get(values, 'title')}
          />
        </div>

        <div className="flex flex-col p-2">
          <TitleHeader title="information"/>
          <FieldsFactory
            formSchema={[
              {
                children: [
                  {
                    name: 'title',
                    label: t('title'),
                    component:
                      createControlledFormField(
                        {
                          Component:
                          Input,
                        }
                      ),
                  },
                ],
              },
            ]}
          />
          <FieldsFactory
            formSchema={[
              {
                children: {
                  name: 'time_range',
                  label: t('time'),
                  component: withProps(
                    ({
                       values,
                       ...props
                     }) => ({
                      values,
                      ...props,
                      bordered: false,
                      name: 'time_range',
                    })
                  )(CustomDateAndTimeRangeField),
                },
              },
            ]}
          />
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'event_type',
                  label: t('type of event?'),
                  component: EventTypeRadioField,
                  withProps: {
                    type: 'text',
                    name: 'event_type',
                    defaultValue:
                    values.event_type,
                  },
                },
              },
            ]}
          />
          {['webinar', 'reminder'].includes(_.get(values, 'event_type', 'meeting')) && (
            <div className="flex flex-wrap items-center space-x-1">
              <FieldsFactory
                formSchema={[
                  {
                    children: {
                      name: 'external_link',
                      label:
                        t('link'),
                      component:
                        createControlledFormField(
                          {
                            type: 'url',
                          }
                        ),
                    },
                  },
                ]}
              />
            </div>
          )}
          {['meeting', 'hybrid',].includes(_.get(values, 'event_type', 'meeting')) && (
            <div className="flex flex-row">
              <FieldsFactory
                formSchema={[
                  {
                    children: {
                      type: 'text',
                      name: 'location_name',
                      label:
                        t('location'),
                      component:
                      Input.TextArea,
                      withProps: {
                        type: 'text',
                        name: 'location_name',
                        defaultValue:
                        values.location_name,
                      },
                    },
                  },
                ]}
              />
            </div>
          )}

          <TitleHeader title="categories" class_name="mt-3"/>
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'categories',
                  label: t(
                    'categories'
                  ),
                  component: compose(
                    connect((state) => {
                      return {
                        categories:
                        getCacheSelector(
                          state,
                          'appConfig',
                          {}
                        ).categories,
                      }
                    }),
                    mapProps(
                      ({
                         onChange,
                         categories,
                         value,
                         form,
                       }) => ({
                        type: 'channel',
                        categoryOptions:
                          [
                            ...categories,
                          ],
                        selected: value,
                        onChange: (
                          value
                        ) =>
                          onChange(
                            createValue(
                              'categories',
                              value
                            )
                          ),
                      })
                    ),
                    pure
                  )(SelectCategories),
                },
              },
            ]}
          />
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'language',
                  label: t('language'),
                  component:
                    createControlledFormField(
                      {
                        Component:
                        Select,
                        withProps: ({
                                      name,
                                      onChange,
                                    }) => ({
                          className:
                            'w-full',
                          options: [
                            'vi',
                            'en',
                            'fr',
                            'km',
                            'ko',
                          ].map(
                            (e) => ({
                              label:
                                t(e),
                              value: e,
                            })
                          ),
                          onChange: (
                            value
                          ) => {
                            onChange(
                              createValue(
                                name,
                                value
                              )
                            )
                          },
                        }),
                      }
                    ),
                },
              },
            ]}
          />

          <TitleHeader title="reminder" class_name="mt-3"/>
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'interval_type',
                  label: t('the event will repeat in'),
                  component: withProps(
                    ({
                       values,
                       onChange,
                       ...props
                     }) => ({
                      values,
                      ...props,
                      name: 'interval_type',
                      size: 'small',
                      defaultValue: 'N',
                      style: {
                        width: 180,
                      },
                      onChange: (e) => {
                        onChange(
                          createValue(
                            'interval_type',
                            e
                          )
                        )
                      },
                      options:
                        intervalTypeOptions.map(
                          (e) => ({
                            ...e,
                            label: t(
                              e.label
                            ),
                          })
                        ),
                    })
                  )(Select),
                },
              },
            ]}
          />
          {values.interval_type === 'D' && (
            <FormikConsumer>
              {({handleChange}) => (
                <Calendar
                  className="rounded-md"
                  fullscreen={false}
                  disabledDate={(
                    current
                  ) => {
                    // Can not select days before today and today
                    return (
                      current &&
                      current <
                      moment().endOf(
                        'day'
                      )
                    )
                  }}
                  dateCellRender={(
                    date
                  ) => {
                    const exists =
                      values.schedules.some(
                        (e) =>
                          date.isSame(
                            e,
                            'day'
                          )
                      )
                    return exists ? (
                      <BsCheck
                        size={12}
                        className="absolute text-primary"
                        style={{
                          bottom: '1rem',
                          left: '1.2rem',
                        }}
                      />
                    ) : null
                  }}
                  headerRender={({
                                   value,
                                   type,
                                   onChange,
                                   onTypeChange,
                                 }) => {
                    const start = 0
                    const end = 12
                    const monthOptions = []

                    const current = value.clone()
                    const localeData = value.localeData()
                    const months = []
                    for (
                      let i = 0;
                      i < 12;
                      i++
                    ) {
                      current.month(i)
                      months.push(
                        localeData.monthsShort(
                          current
                        )
                      )
                    }

                    for (
                      let index = start;
                      index < end;
                      index++
                    ) {
                      monthOptions.push(
                        <Select.Option
                          className="month-item"
                          key={`${index}`}>
                          {
                            months[
                              index
                              ]
                          }
                        </Select.Option>
                      )
                    }
                    const month = value.month()
                    const year = value.year()
                    const options = []
                    for (
                      let i = year - 10;
                      i < year + 10;
                      i += 1
                    ) {
                      options.push(
                        <Select.Option
                          key={i}
                          value={i}
                          className="year-item">
                          {i}
                        </Select.Option>
                      )
                    }
                    return (
                      <div className="flex flex-center gap-2 p-2">
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={
                            false
                          }
                          className="my-year-select"
                          onChange={(
                            newYear
                          ) => {
                            const now =
                              value
                                .clone()
                                .year(
                                  newYear
                                )
                            onChange(
                              now
                            )
                          }}
                          value={String(
                            year
                          )}>
                          {options}
                        </Select>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={
                            false
                          }
                          value={String(
                            month
                          )}
                          onChange={(
                            selectedMonth
                          ) => {
                            const newValue =
                              value.clone()
                            newValue.month(
                              parseInt(
                                selectedMonth,
                                10
                              )
                            )
                            onChange(
                              newValue
                            )
                          }}>
                          {
                            monthOptions
                          }
                        </Select>
                      </div>
                    )
                  }}
                  onSelect={(date) => {
                    const exists =
                      values.schedules.some(
                        (e) =>
                          date.isSame(
                            e,
                            'day'
                          )
                      )
                    if (exists) {
                      // remove
                      const _schedules =
                        values.schedules.filter(
                          (e) =>
                            !date.isSame(
                              e,
                              'day'
                            )
                        )
                      handleChange(
                        createValue(
                          'schedules',
                          _schedules
                        )
                      )
                    } else {
                      // add
                      const _schedules =
                        [
                          ...values.schedules,
                          date,
                        ]
                      handleChange(
                        createValue(
                          'schedules',
                          _schedules
                        )
                      )
                    }
                  }}
                />
              )}
            </FormikConsumer>
          )}
          {values.interval_type === 'D' && (
            <div className="flex flex-col gap-1 text-xs italic mt-2">
              <div className="font-light text-color-500">
                {t('The next event is scheduled in')}:
              </div>
              <div className="font-medium text-color-100">
                {values.schedules
                  .map((e, i) =>
                    e.format(
                      'DD/MM/YYYY'
                    )
                  )
                  .join(' → ')}
              </div>
            </div>
          )}
          {values.interval_type === 'W' && (
            <div className="grid grid-cols-7 gap-1 text-xs uppercase font-bold">
              {weekOptions.map(
                (e, i) => {
                  const interval_daily =
                    Object.assign(
                      [],
                      _.get(
                        values,
                        'interval_daily',
                        '0000000'
                      )
                    )

                  return (
                    <FormikConsumer>
                      {({
                          handleChange,
                        }) => (
                        <div
                          key={i}
                          onClick={() => {
                            var _interval_daily =
                              Object.assign(
                                [],
                                '0000000'
                              )
                            _interval_daily[
                              i
                              ] = 1
                            handleChange(
                              createValue(
                                'interval_daily',
                                _interval_daily.join(
                                  ''
                                )
                              )
                            )
                          }}
                          className={classNames(
                            'py-2 px-1 flex flex-center border rounded-md cursor-pointer text-2xs',
                            !!Number(
                              interval_daily[
                                i
                                ]
                            )
                              ? 'bg-primary border-primary text-white'
                              : 'background-100 border-transparent'
                          )}>
                          {e.label}
                        </div>
                      )}
                    </FormikConsumer>
                  )
                }
              )}
            </div>
          )}
          {values.interval_type === 'M' && (
            <div className="text-xs text-color-500 italic">
              {t(
                'The next event is scheduled to take place at {1}',
                {
                  1: (
                    <>
                      <br/>
                      <span className="text-sm font-medium text-color-100">
                                              {moment(values.time_open).add(1, 'M').format('HH:mm A - DD/MM/YYYY')}
                                          </span>
                    </>
                  ),
                }
              )}
            </div>
          )}

          <TitleHeader title="admin" class_name="mt-6"/>
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'co_admins',
                  label: t('co-admins'),
                  component: CoAdminsField,
                  withProps: {
                    bordered: false,
                    type: 'text',
                    name: 'co_admins',
                    size: 'large',
                    placeholder: t(
                      'select user'
                    ),
                  },
                },
              },
            ]}
          />
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'co_hosts',
                  label: t('co-hosts'),
                  component: CoHostsField,
                  withProps: {
                    bordered: false,
                    type: 'text',
                    name: 'hosts',
                    size: 'large',
                    placeholder: t(
                      'add organization'
                    ),
                  },
                },
              },
            ]}
          />
          <FieldsFactory
            formSchema={[
              {
                children: {
                  type: 'text',
                  name: 'speakers',
                  label: t('speakers'),
                  component: SpeakersField,
                  withProps: {
                    bordered: false,
                    type: 'text',
                    name: 'speakers',
                    size: 'large',
                    placeholder: t(
                      'select user'
                    ),
                  },
                },
              },
            ]}
          />
        </div>
      </React.Fragment>
    )
  }

function EventOverviewSetting() {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const {
    item,
    handleActionClick,
    contextMenu,
  } = useContext(EventContext)
  const shareUrl =
    eventModel.getLinkToShare(item)
  const cover =
    eventModel.getFullCoverUrl(item)
  if (_.isEmpty(item)) return null

  const initialValues = {
    title: item.title,
    idname: item.idname,
    privacy: item.privacy,
    event_type: item.event_type,
    time_open: item.time_open,
    start_date: moment(item.time_open),
    start_time: moment(item.time_open),
    time_close: item.time_close,
    end_date: !!item.time_close
      ? moment(item.time_close)
      : null,
    end_time: !!item.time_close
      ? moment(item.time_close)
      : null,
    language: item.language,
    interval_type:
      item.interval_type || 'N',
    interval_daily:
      item.interval_daily || '0000000',
    interval_monthly:
      item.interval_monthly || 1,
    interval_yearly:
      moment(item.interval_monthly) ||
      moment(),
    location_name: item.location_name,
    external_link: item.external_link,
    categories: (
      item.categories || emptyArray
    ).map(({id}) => id),
    categoryOptions:
      item.categories || emptyArray,
    description: item.description,
    schedules: _.get(
      item,
      'schedules',
      []
    ).map((el) => {
      return moment(el.start_time)
    }),
    co_hosts: _.get(
      item,
      'hosts',
      []
    ).map((el) => {
      return {
        _type: _.get(el, 'host._type'),
        id: _.get(el, 'host.id'),
        value:
          _.get(el, 'host_name') ||
          _.get(
            el,
            'host.name',
            _.get(el, 'host.title')
          ),
        avatar: _.get(
          el,
          'host.avatar'
        ),
        editable: true,
      }
    }),
    co_admins: _.get(
      item,
      'admins',
      []
    ).map((el) => {
      return {
        _type: _.get(el, 'user._type'),
        id: _.get(el, 'user.id'),
        value: _.get(el, 'user.name'),
        avatar: _.get(
          el,
          'user.avatar'
        ),
        editable: !(
          _.get(el, 'user.id') ===
          (login && login.id) ||
          _.get(el, 'role_id') == 1
        ),
      }
    }),
    speakers: _.get(
      item,
      'speakers',
      []
    ).map((el) => {
      return {
        _type: _.get(
          el,
          'speaker._type'
        ),
        id: _.get(el, 'speaker.id'),
        value:
          _.get(el, 'speaker_name') ||
          _.get(
            el,
            'speaker.name',
            _.get(el, 'speaker.title')
          ),
        avatar: _.get(
          el,
          'speaker.avatar'
        ),
        editable: true,
      }
    }),
    cover_photo: item.cover_photo,
  }

  return (
    <ApiInfoForm
      initialValues={initialValues}
      query={{
        ':id': item.id,
      }}
      onPreSubmit={(values) => {
        let _values = values
        if (!!values.co_hosts) {
          _values = {
            ..._values,
            co_hosts: JSON.stringify(
              values.co_hosts.map(
                (e) => {
                  return {
                    type: e._type,
                    id: e.id,
                    host_name: e.value,
                  }
                }
              )
            ),
          }
        }
        if (!!values.co_admins) {
          _values = {
            ..._values,
            co_admins: JSON.stringify(
              values.co_admins.map(
                (e) => {
                  return e.id
                }
              )
            ),
          }
        }
        if (!!values.speakers) {
          _values = {
            ..._values,
            speakers: JSON.stringify(
              values.speakers.map(
                (e) => {
                  return {
                    type: e._type,
                    id: e.id,
                    speaker_name:
                    e.value,
                  }
                }
              )
            ),
          }
        }

        if (!!values.schedules) {
          _values = {
            ..._values,
            schedules: JSON.stringify(
              values.schedules.map(
                (e) => {
                  return e.format(
                    'YYYY-MM-DD'
                  )
                }
              )
            ),
          }
        }

        if (
          !!values.start_date &&
          moment.isMoment(
            values.start_date
          ) &&
          !!values.start_time &&
          moment.isMoment(
            values.start_time
          )
        ) {
          _values = {
            ..._values,
            time_open: moment(
              values.start_date.format(
                'YYYY-MM-DD'
              ) +
              ' ' +
              values.start_time.format(
                'HH:mm'
              )
            )
              .utc()
              .format(),
          }
        }
        if (
          !!values.end_date &&
          moment.isMoment(
            values.end_date
          ) &&
          !!values.end_time &&
          moment.isMoment(
            values.end_time
          )
        ) {
          _values = {
            ..._values,
            time_close: moment(
              values.end_date.format(
                'YYYY-MM-DD'
              ) +
              ' ' +
              values.end_time.format(
                'HH:mm'
              )
            )
              .utc()
              .format(),
          }
        }

        return {
          ..._values,
        }
      }}
      apiInfo={event_edit_Api}>
      <ContentContainer className="min-h-screen w-full">
        <EventOverviewFieldSet/>

        <div className="flex-1"/>
        <FormActionBar
          submitText={t('save')}
        />
      </ContentContainer>
    </ApiInfoForm>
  )
}

export default EventOverviewSetting
