import {
  Avatar,
  Button,
  Input,
  List,
} from 'antd'
import { getId } from 'apis/model/base'
import { LoginContext } from 'components/LoginContext'
import Pure from 'components/Pure'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { IoPerson } from 'react-icons/io5'
import {
  compose,
  mapProps,
} from 'recompose'
import {
  Null,
  renderIf,
  renderMail,
  renderOwnChild,
  renderPhone,
  renderSelf,
} from 'views/Shared'
import { wishareConfigs } from 'views/Wishare/configs'
import { GenderTypes } from 'views/Wishare/wishareFieldTypes'
import { LayoutContext } from '../../../../components/layouts/Default/LayoutContext'
import DonationDonorSelector from './DonationDonorSelector'

const DonorCreator = ({
  keyword,
  alias_name,
  onCreate = Null,
}) => {
  const t = useTranslate()

  return [keyword, alias_name]
    .filter(
      (value) => !_.isEmpty(value)
    )
    .map((value, index) => (
      <div
        key={index}
        className="flex items-center border background rounded-lg p-3">
        <div className="font-medium text-color-000 italic">
          {`"${value}"`}
        </div>
        <div className="flex-1" />
        <span className="text-xs font-light text-color-500 italic mr-2">
          {t(
            'Click here to create a new one'
          )}
        </span>
        <Button
          type="primary"
          size="small"
          className="no-text-shadow no-shadow rounded-md text-xs font-medium"
          onClick={() => {
            onCreate(value)
          }}>
          {t('create')}
        </Button>
      </div>
    ))
}

const renderDonor = (donor_name) => {
  if (_.isEmpty(donor_name)) {
    return (
      <div className="SearchDonorInput w-full">
        <Translate>
          {(t) => (
            <div className="flex items-center gap-2">
              <LayoutContext.Consumer>
                {({ isLg }) =>
                  isLg && (
                    <Avatar
                      icon={
                        <IoPerson
                          size={14}
                          className="text-orange-500"
                        />
                      }
                      className="flex flex-center bg-orange-200"
                    />
                  )
                }
              </LayoutContext.Consumer>
              <div className="flex flex-col">
                <span className="font-medium text-sm text-orange-600">
                  {t(
                    'Click here to search for benefactors'
                  )}
                </span>
                <span className="font-light text-xs italic text-orange-500">
                  {t(
                    'You can find a benefactor or add another benefactor'
                  )}
                </span>
              </div>
            </div>
          )}
        </Translate>
      </div>
    )
  }

  return (
    <List.Item.Meta
      title={
        <span className="text-color-000 font-semibold leading-tight flex items-center">
          {donor_name}
        </span>
      }
      className="items-center px-2"
      avatar={
        <Avatar
          className="flex flex-center background-100"
          icon={
            <IoPerson className="text-color-300" />
          }
        />
      }
      description={
        <Translate>
          {(t) => (
            <span className="text-xs text-secondary italic">
              {t('new donor')}
            </span>
          )}
        </Translate>
      }
    />
  )
}

const DonorFormField = ({
  name,
  value,
  alias_name,
  organization_id,
  setValues = Null,
  readOnly = false,
  onChange = Null,
  MetaHeader = Null,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const login = useContext(LoginContext)
  const [current, setCurrent] =
    useState()
  return (
    <Wrapper>
      <MetaHeader />
      {login ? (
        <DonationDonorSelector
          name="donor"
          readOnly={readOnly}
          defaultValue={value}
          renderDonor={(donor, child) =>
            !_.isEmpty(donor)
              ? child
              : renderDonor(current)
          }
          Header={({
            keyword,
            onCancel = Null,
          }) => (
            <DonorCreator
              keyword={keyword}
              alias_name={alias_name}
              onCreate={(
                donor_name
              ) => {
                setCurrent(donor_name)
                setValues({
                  donor_name,
                  donor: undefined,
                  donor_id: undefined,
                  donor_type: undefined,
                })
                onCancel()
              }}
            />
          )}
          onChange={(donor) => {
            setValues({
              donor,
              donor_name: undefined,
              // alias: 0,
              // alias_name: undefined,
            })
          }}
          organization_id={
            organization_id
          }
        />
      ) : (
        <Translate>
          {(t) => (
            <Input
              value={alias_name}
              name="alias_name"
              onChange={(event) => {
                const { name, value } =
                  event.target
                setValues({
                  alias: 1,
                  [name]: value,
                  donor: undefined,
                  donor_id: undefined,
                  donor_type: undefined,
                  donor_name: undefined,
                })
              }}
              placeholder={t(
                'Enter your name'
              )}
            />
          )}
        </Translate>
      )}
    </Wrapper>
  )
}

const ExtraInformation = () => {
  const t = useTranslate()

  const { values } = useFormikContext()

  const {
    donor,
    email,
    gender,
    alias,
    donor_name,
    alias_name,
    phone_number,
    date_of_birth,
  } = values || {}

  const dependencies = {
    email,
    gender,
    phone_number,
    date_of_birth,
  }

  const renderMeta = useCallback(
    ({ label, value }, index) => (
      <React.Fragment key={index}>
        {!!value && (
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-500 italic font-light">
              {`${t(label)} : `}
            </span>
            <span className="text-sm font-medium text-color-000">
              {value}
            </span>
          </div>
        )}
      </React.Fragment>
    ),
    [t]
  )

  const showMetaInfo =
    !!alias &&
    _.isEmpty(donor_name) &&
    _.isEmpty(getId(donor)) &&
    !_.isEmpty(alias_name)

  const meta = useMemo(() => {
    if (showMetaInfo) {
      return [
        {
          name: 'email',
          label: 'email',
          transform: renderMail,
        },
        {
          name: 'phone_number',
          label: 'phone',
          transform: renderPhone,
        },
        {
          name: 'gender',
          label: 'gender',
          transform: (value) => {
            const { label } =
              _.find(
                Object.values(
                  GenderTypes
                ),
                {
                  value: Number(value),
                }
              ) || {}
            return label
              ? t(label)
              : null
          },
        },
        {
          name: 'date_of_birth',
          label: 'birthday',
          transform: (value) =>
            value
              ? moment(value).format(
                  wishareConfigs.dateFormat
                )
              : null,
        },
      ]
    } else {
      return []
    }
  }, [t, showMetaInfo])

  return (
    <Pure
      input={[
        meta,
        alias_name,
        dependencies,
      ]}>
      <div className="mb-2 space-y-1">
        {!_.isEmpty(alias_name) && (
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-500 italic font-light">
              {t('donor name')}
              {': '}
            </span>
            <span className="text-sm font-medium text-color-000">
              {alias_name}
            </span>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <Pure input={[meta]}>
            {meta.map(
              (
                {
                  name,
                  label,
                  transform = renderSelf,
                },
                index
              ) => {
                const _value =
                  transform(
                    _.get(
                      dependencies,
                      name
                    )
                  )
                return _value
                  ? renderMeta(
                      {
                        label,
                        value: _value,
                      },
                      index
                    )
                  : null
              }
            )}
          </Pure>
        </div>
      </div>
    </Pure>
  )
}

function autoFill(
  obj,
  default_values,
  field_names = []
) {
  let output = Object.assign({}, obj)
  Array.from(field_names || []).forEach(
    (name) => {
      if (!_.get(output, name)) {
        output[name] = _.get(
          default_values,
          name
        )
      }
    }
  )
  return output
}

export default compose(
  mapProps(
    ({
      name,
      value,
      form,
      onChange,
      readOnly,
      alias_name,
      root_owner,
      dependencies,
      withDonor = (item) => {},
    }) => {
      const {
        values,
        setValues = Null,
      } = form || {}
      return {
        name,
        value,
        onChange,
        readOnly,
        alias_name,
        organization_id:
          getId(root_owner),
        setValues: ({
          donor,
          ...rest
        }) => {
          const field_names =
            Array.from(
              dependencies || []
            )
          const params = _.pick(
            donor,
            field_names
          )
          autoFill(
            params,
            _.get(
              values,
              'registered_information'
            ),
            field_names
          )
          setValues({
            ...values,
            ...rest,
            ...params,
            ...(withDonor(
              donor || {}
            ) || {}),
            donor,
          })
        },
        MetaHeader: (props) => (
          <LoginContext.Consumer>
            {(login) =>
              renderIf(
                !!login,
                <ExtraInformation
                  {...props}
                />
              )
            }
          </LoginContext.Consumer>
        ),
      }
    }
  )
)(DonorFormField)
