export default {
  "00000-VI":"TRAN",
  "(yes/no) question":'Câu hỏi (Có / Không)',
  "{beneficiaryNbr} have beneficiaryed":'Đã có {beneficiaryNbr} mong ước gửi đến',
  "0 result":'0 kết quả',
  "1. no poverty":'1. Xóa Nghèo',
  "10. reduced inequalities":'10. Giảm Bất Bình Đẳng',
  "11. sustainable cities and communities":'11. Đô Thị & Cộng Đồng Bền Vững',
  "12. responsible consumption and production":'12. Tiêu Dùng & Sản Xuất Có Trách Nhiệm',
  "13. climate action":'13. Hành động về Khí Hậu',
  "14. life below water":'14. Tài Nguyên và Môi trường Biển',
  "15. life on land":'15. Tài Nguyên và Môi trường Đất liền',
  "16. peace, justice and strong institutions":'16. Hòa bình, Công lý và Các Thể Chế mạnh mẽ',
  "17 SDGs":'17 Mục tiêu',
  "17. partnerships for the goals":'17. Quan Hệ Đối Vì Các Mục Tiêu',
  "2. zero hunger":'2. Không còn nạn đói',
  "3. good health and well-being":'3. Sức Khỏe và Có Cuộc Sống Tốt',
  "4. quality education":'4. Giáo Dục Chất Lượng',
  "5. gender equality":'5. Bình Đẳng Giới',
  "6. clean water and sanitation":'6. Nước Sạch & Vệ sinh',
  "7. affordable and clean energy":'7. Năng Lượng Sạch Với Giá Thành Hợp Lý',
  "8. decent work and economic growth":'8. Việc Làm Và Tăng Trưởng Kinh Tế',
  "9. industry, innovation and infrastructure":'9. Đổi Mới Và Hạ Tầng',
  "A":'Một',
  "a post":'Bài viết',
  "a beneficiary of":'Một mong ước của',
  "about":'Giới thiệu',
  "about job":'Về công việc',
  "about us":'Về chúng tôi',
  "about wishare":'Về Wishare',
  "abouthelp":'Về chúng tôi',
  "abouthelptips":'Giới thiệu, Về Công ty, Liên hệ',
  "accept":'Chấp nhận',
  "accepted":'Đã chấp nhận',
  "access to a huge human capital for good":'Tiếp cận với nguồn nhân lực của toàn xã hội',
  "account":'Tài khoản',
  "account name":'Tên tài khoản',
  "activated":'Đã kích hoạt',
  "activation":'Kích hoạt',
  "active":'Hoạt động',
  "activetips":'Trang đang hoạt động, bạn có quyền quản lý, chỉnh sửa',
  "activities":'Hoạt động',
  "activity":'Hoạt động',
  "activity information":'Thông tin về hoạt động',
  "Activity is the smallest project unit with full mission and action. Your organization may have many different activities.":'Hoạt động là một đơn vị dự án nhỏ nhất có đầy đủ sứ mệnh và hành động. Tổ chức của bạn có thể có nhiều hoạt động khác nhau.',
  "activity name":'Tên hoạt động',
  "activitytips":'Hoạt động là tập hợp các công việc có mục tiêu rõ ràng nhằm phục vụ xã hội. Một hoạt động có thể huy động nguồn lực của cộng đồng để thực hiện mục tiêu của mình. ',
  "add":'Thêm',
  "add activity":'Thêm hoạt động',
  "add album":'Thêm tập ảnh',
  "add an image":'Ảnh bài viết',
  "add auction item":'Thêm sản phẩm đấu giá',
  "add campaign":'Thêm chương trình',
  "add categories":'Chọn mục mà bạn quan tâm',
  "add delivery policy":'Thêm phương thức giao hàng',
  "add donation":'Thêm mới tài trợ',
  "add donation campaigns":'Thêm chương trình từ thiện',
  "add location":'Thêm địa điểm',
  "add member":'Thêm người dùng',
  "add more":'Thêm tiếp nữa',
  "add new":'Thêm mới',
  "add new language":'Thêm mới ngôn ngữ',
  "add new map":'Thêm địa điểm vào bản đồ',
  "add new product":'Thêm sản phẩm mới',
  "add new schedule":'Thêm mới lịch trình',
  "add notice":'Thêm thông báo',
  "add photos":'Thêm hình ảnh',
  "add product":'Thêm sản phẩm',
  "add promotion campaigns":'Thêm chương trình khuyến mãi',
  "add return policy":'Thêm chính sách đổi trả',
  "add tag":'Gắn với từ khóa',
  "add to cart":'Thêm vào Giỏ hàng',
  "add warranty policy":'Thêm chính sách bảo hành',
  "add your comment":'Bình luận của bạn',
  "add your school":'Thêm trường nơi bạn đã học',
  "add your skills":'Thêm kỹ năng của bạn',
  "add your workplace":'Thêm nơi bạn đã làm việc',
  "add yours recommendations":'Thêm đề xuất của bạn',
  "added hosts":'Các đơn vị đại diện đã thêm',
  "additional message":'Nội dung bổ sung',
  "address":'Địa chỉ',
  "addressed":'đã gửi',
  "adhoc":'Đặc biệt',
  "admin":'Quản trị',
  "advanced":'Nâng cao',
  "affordable and clean energy":'Năng Lượng Sạch Với Giá Thành Hợp Lý',
  "age":'Độ tuổi',
  "age_from":'Tuổi từ',
  "age_from_placeholder":'Độ tuổi (từ tuổi) trong phạm vi hỗ trợ',
  "age_to":'Đến độ tuổi',
  "age_to_placeholder":'Độ tuổi (đến tuổi) trong phạm vi hỗ trợ',
  "agree":'Đồng ý',
  "all":'Tất cả',
  "ambassador":'đại sứ',
  "amount":'GIá trị',
  "An":'Một',
  "and":'và',
  "and petition":'và thỉnh nguyện thư',
  "and social impact work":'và công việc tạo tác động xã hội',
  "animal":'Động vật',
  "anonymous":'Nhà tài trợ ẩn danh',
  "answer":'Trả lời',
  "applicant note":'Ghi chú ứng tuyển',
  "applicants":'Ứng viên',
  "applied a job":'Nộp hồ sơ ứng tuyển',
  "applied beneficiaries":'Các đơn vị thụ hưởng đã gửi đến',
  "applies":'Ứng viên',
  "apply":'Áp dụng',
  "apply a new beneficiary":'Thêm đơn vị thụ hưởng mới',
  "apply an existing beneficiary":'Thêm đơn vị thụ hưởng từ Wishare',
  "apply now":'Ứng tuyển ngay',
  "apply now!":'Hãy ứng tuyển ngay!',
  "apply technology to support":'Áp dụng công nghệ để hỗ trợ',
  "apply to become a volunteer":'Gửi hồ sơ đến nhà tuyển dụng',
  "approve":'Duyệt',
  "approved":'Đã duyệt',
  "are bidding":'Đang đấu giá',
  "are you agree to remove item from order?":'Bạn có đồng ý loại bỏ sản phẩm này khỏi đơn hàng?',
  "are you sure to cancel this order?":'Bạn có chắc là muốn hủy đơn hàng này?',
  "are you sure you want to remove this item?":'Bạn có chắc là muốn xóa nội dung này?',
  "art & design":'Nghệ thuật & thiết kế',
  "Art, Collections & Antiques":'Nghệ thuật, Sưu tập & Đồ cổ',
  "asc":'Tăng dần',
  "ask questions":'Câu hỏi',
  "asking":'Thầu giá',
  "at least":'Ít nhất',
  "attributes":'Thuộc tính',
  "attributes list":'Danh sách thuộc tính',
  "auction":'Đấu giá',
  "auction donation campaign":'Vận động quyên góp',
  "auction history":'Lịch sử đấu giá',
  "auction information":'Thông tin đấu giá',
  "auction items":'Sản phẩm đấu giá',
  "auction management":'Quản lý đấu giá',
  "auction members":'Thành viên đấu giá',
  "auction name":'Tên đấu giá',
  "auction product":'Sản phẩm từ đấu giá',
  "auction products":'Sản phẩm đấu giá',
  "auction settings":'Cài đặt đấu giá',
  "auctioned price":'Giá đã đấu',
  "auctions":'Đấu giá',
  "automatic":'Thực hiện tự động',
  "automatic execution":'Tự động thực thi',
  "Automotive Motorcycles":'Ô tô & Xe máy',
  "available":'có sẵn',
  "available activities":'Hoạt động có sẵn',
  "available for social work":'Sẵn sàng cho công tác xã hội',
  "avatar":'Ảnh đại diện',
  "average rating point":'Điểm đánh giá trung bình',
  "Babies & Toys":'Trẻ em & Đồ chơi',
  "back":'Quay lại',
  "back to shop":'Quay lại cửa hàng',
  "basic information":'Thông tin cơ bản',
  "be a part of growing trend":'Hãy là một phần của xu hướng phát triển',
  "be the first to bid":'Hãy trở thành người đầu tiên ra giá',
  "become a member of wishare":'Đăng ký tài khoản Wishare miễn phí',
  "become a volunteer ":'Trở thành tình nguyện viên',
  "Before creating a new one, please see if your wishare already exists. In the case of Wishare\'s indexing, please contact us so we can re-administer it.":'Trước khi tạo mới, xin vui lòng tìm kiếm xem Wishare đã tồn tại hoạt động của bạn chưa. Trong trường hợp Wishare đã có lập chỉ mục, xin vui lòng liên hệ chúng tôi để chúng tôi chuyển giao lại quyền quản trị.',
  "begin":'Bắt đầu',
  "benefits":'Quyền lợi được hưởng',
  "benefits and obligations":'Quyền lợi & Trách nhiệm',
  "best sales":'Sản phẩm bán chạy',
  "bid":'Đặt giá',
  "bid now":'Đấu giá ngay',
  "bid price":'Giá thầu cuối cùng',
  "bid time":'Thời gian đấu',
  "bidder":'Người trả giá',
  "bidding":'Đấu giá',
  "bidding / asking":'Đấu giá:* Lần đặt giá sau sẽ cao hơn lần đặt giá trước đó, người đặt giá cao nhất sẽ thắng đấu giá. / Thầu giá:* Lần đặt giá sau sẽ  thấp hơn lần đặt giá trước, người mời giá thấp nhất sẽ thắng thầu giá.',
  "bill":'Hóa đơn',
  "binding_conditions":'Điều kiện ràng buộc',
  "binding_conditions_placeholder":'Các điều kiện đi kèm đối với các đối tượng của hoạt động, các cam kết với hoạt động',
  "block":'Phong tỏa',
  "blocked":'Đã phong tỏa',
  "blockedtips":'Rất tiếc. Nội dung không phù hợp, không được phép hiển thị công khai',
  "Books, Stationery & Gifts":'Sách, Văn phòng phẩm & Quà tặng',
  "bought":'Đã mua',
  "but let us help measure your impact and discover the social activities around you":'nhưng hãy để chúng tôi giúp đo lường tác động của bạn và khám phá các hoạt động xã hội xung quanh bạn',
  "but they can not find aright one":'nhưng họ không thể tìm thấy nơi cần',
  "but they can not find the support":'nhưng họ không thể tìm thấy được sự hỗ trợ',
  "buy now":'Mua ngay',
  "by":'bởi',
  "campaign name":'Tên chiến dịch',
  "cancel ":'Hủy bỏ',
  "cancel order":'Hủy đơn hàng',
  "cancelled":'Hủy bỏ',
  "career":'Nghề',
  "careers":'Tuyển dụng',
  "cart":'Giỏ hàng',
  "categories":'Danh mục',
  "categoriesplaceholder":'Chọn nhóm',
  "category":'Danh mục',
  "certificate":'Chứng chỉ học tập',
  "change":'Thay đổi',
  "change cover photo":'Thay đổi hình nền',
  "change in price and quantity tab":'Thay đổi ở phần giá và số lượng',
  "change status":'Thay đổi trạng thái',
  "change your picture":'Thay đổi hình đại diện',
  "charity products":'SẢN PHẨM ỦNG HỘ THIỆN NGUYỆN',
  "charity, social impact project":'Từ thiện và dự án tác động xã hội',
  "check your email and click on the activation link to complete the registration":'Kiểm tra email của bạn và nhấp vào liên kết kích hoạt để hoàn tất đăng ký',
  "children":'Trẻ em',
  "choices":'Chọn lựa',
  "choose":'Chọn',
  "choose 1 of the funds to receive the donation":'Chọn 1 trong số các quỹ để nhận quyên góp',
  "choose a photo as the image for the activity":'Chọn một tấm ảnh làm hình ảnh cho hoạt động',
  "choose a photo as the image for the event":'Chọn một tấm ảnh làm hình ảnh cho sự kiện',
  "choose a photo as the image for the group":'Chọn ảnh làm nền cho tổ chức',
  "choose the category that you are aiming for":'Chọn danh mục mà bạn hướng tới',
  "choose the job type to continue":'Chọn loại công việc để tiếp tục',
  "choose the target you want to target":'Chọn mục tiêu mà bạn muốn hướng tới',
  "clean water and sanitation":'Nước Sạch & Vệ sinh',
  "CleanEnergy":'NangLuong',
  "CleanWaterAndSanitation":'NuocSachVaVeSinh',
  "clear":'Xóa',
  "clear filter":'Bỏ lọc',
  "click":'Nhấp',
  "click here":'Nhấn vào đây',
  "click here to add new donate package":'Thêm mới gói đóng góp',
  "click here to add new photos":'Nhấp vào đây để thêm hình ảnh',
  "click here to dismiss recommendation this beneficiary":'Nhấp để bỏ khuyến nghị cho đơn vị thụ hưởng',
  "click to next":'Chọn để tiếp tục',
  "click to vote it":'Nhấn để ủng hộ',
  "climate action":'Hành động về Khí Hậu',
  "close":'Đóng',
  "closed":'Đã đóng',
  "closed need":'Ngừng nhận',
  "closedgroup":'Tổ chức hoạt động riêng tư',
  "collected":'Đã đóng góp',
  "Color":'Màu',
  "coming soon":'Sắp diễn ra',
  "comment":'Bình luận',
  "comment history":'Lịch sử nhận xét',
  "comment on product":'Chia sẻ cảm nhận về sản phẩm',
  "comment store":'Nhận xét cửa hàng',
  "common":'Thông tin chung',
  "community policy":'Chính sách Cộng đồng',
  "company":'Công ty',
  "companytips":'Công ty, ...',
  "complete add new product":'Hoàn tất thêm sản phẩm mới',
  "complete the information so people can understand your store better":'Điền đầy đủ thông tin để mọi người có thể hiểu rõ về cửa hàng của bạn hơn',
  "Complete with sharing and spreading. We need you.":'Hoàn tất việc với việc chia sẻ, lan tỏa. Chúng tôi cần bạn.',
  "completed":'Kết thúc',
  "completed project":'Dự án hoàn tất',
  "completed beneficiary":'Đơn vị thụ hưởng thành công',
  "computer & data":'Lập trình',
  "confirm cart":'Xác nhận giỏ hàng',
  "confirm password":'Xác nhận mật khẩu',
  "congratulation you become winner":'Chúc mừng bạn đã giành chiến thắng',
  "congratulation your payment has been finished":'Chúc mừng thanh toán toán của bạn đã hoàn thành',
  "congratulations":'Xin chúc mừng',
  "congratulations, your order is successful":'Chúc mừng bạn mua hàng thành công',
  "connect with facebook":'Đăng nhập bằng Facebook',
  "connect with google":'Đăng nhập bằng Google',
  "connect with us":'Kết nối với chúng tôi',
  "connecting for good":'Kết nối điều tốt lành',
  "connecting people of kindness and needs":'Kết nối những người thiện tâm và các nhu cầu trên',
  "connecting the goodness":'Kết nối \nđiều tốt lành',
  "contact":'Liên hệ',
  "contact us":'Liên hệ',
  "Contact us by sending to contact@wishare.com":'Liên hệ với chúng tôi bằng cách gửi đến contact@wishare.com',
  "contact_info":'Người liên hệ',
  "content":'Nội dung',
  "content created by wishare":'Lập chỉ mục bởi Wishare',
  "continue":'Tiếp tục',
  "continue shopping":'Tiếp tục mua sắm',
  "continuous time":'Thời gian tiếp tục',
  "contract":'Hợp đồng',
  "contribute":'Quyên góp',
  "cookies":'Cookies',
  "cookies policy":'Chính sách Cookies',
  "copied":'Đã sao chép',
  "copy":'Sao chép',
  "copy url":'Chép đường dẫn',
  "Copyright by wishare.com":'Bản quyền thuộc về wishare.com',
  "could_not_create_token":'Đăng nhập không thành công!',
  "count on product":'Tạm tính ',
  "create":'Tạo mới',
  "create a crowdfunding activity":'Tạo hoạt động gây quỹ cộng đồng:',
  "create a new album":'Tạo mới album',
  "create a new opportunity":'Đăng thông tin tuyển dụng',
  "create account & login":'Tạo mới & Đăng nhập',
  "create activity":'Tạo hoạt động',
  "create auction":'Tạo đấu giá',
  "create donation":'Nhập thông tin ủng hộ',
  "create event":'Tạo sự kiện',
  "create giving":'nhập thông tin trao đi',
  "create group":'Tạo tổ chức',
  "create need":'Tạo mới gây quỹ',
  "create new account":'tạo mới tài khoản',
  "create new an activity":'Tạo mới hoạt động',
  "create new auction":'Tạo chương trình đấu giá',
  "create new package":'Tạo gói mới',
  "create opportunity":'Tạo thông tin tuyển dụng',
  "create post":'Tạo bài viết',
  "create store":'Tạo cửa hàng',
  "create beneficiary":'Tạo đơn vị thụ hưởng',
  "create your activity":'Tạo hoạt động',
  "create your event":'Tạo sự kiện',
  "create your group":'Tạo tổ chức',
  "create your beneficiary":'Tạo đơn vị thụ hưởng',
  "created":'đã tạo',
  "created date":'Ngày tạo',
  "crowdfunding":'Gây quỹ',
  "culture":'Văn hóa',
  "current":'Hiện tại',
  "current price":'Giá hiện tại',
  "currentUser":'Bạn',
  "customer rated positively":'khách hàng đánh giá tích cực',
  "date":'Ngày',
  "date & time":'Ngày và thời gian ',
  "date_of_birth":'Ngày sinh',
  "day":'Ngày',
  "days":'ngày',
  "days of delivery":'Khoảng thời gian giao hàng',
  "deaf mute":'Người câm điếc',
  "default":'Mặc định',
  "delete":'Xóa',
  "delete comment":'Xoá bình luận',
  "delete opportunity":'Xóa đăng việc',
  "delete question":'Xóa câu hỏi',
  "delete the selected items":'Xóa mục đã chọn',
  "delivered":'Đã giao hàng',
  "delivery":'Giao hàng',
  "delivery by":'Giao bởi',
  "delivery by shop":'Giao hàng bởi cửa hàng',
  "delivery details":'Chi tiết giao hàng',
  "delivery fee":'Phí vận chuyển',
  "delivery location":'Địa chỉ giao hàng',
  "delivery options":'Tùy chọn giao hàng',
  "delivery period from":'Giao hàng từ',
  "delivery policy":'Chính sách giao hàng',
  "delivery status":'Trạng thái giao hàng',
  "delivery unit":'Đơn vị giao hàng',
  "delivery-methods":'Phương thức giao hàng',
  "delivery-policies":'Chính sách giao hàng',
  "desc":'Giảm dần',
  "description":'Giới thiệu',
  "descriptionplaceholder":'Gõ mô tả thêm',
  "designer":'Nhà thiết kế',
  "detail":'Chi tiết',
  "development":'Phát triển',
  "digital of your social activity":'Số hóa các hoạt động xã hội của bạn',
  "directly work":'Làm việc trực tiếp',
  "disabilities":'Khuyết tật',
  "disablity":'Khuyết tật',
  "discard":'Hủy bỏ',
  "discount":'Giảm giá',
  "discount value":'Giảm giá',
  "Discover and contribute to community fundraising activities. We need you.":'Hãy khám phá và cùng đóng góp cho những hoạt động gây quỹ cộng đồng. Chúng tôi cần bạn.',
  "discover now":'Khám phá ngay',
  "discovery":'Khám phá',
  "discussion":'Thảo luận',
  "dismiss recommendation":'Bỏ khuyến nghị',
  "display on homepage":'Hiển thị lên trang chủ',
  "district":'Quận, Huyện',
  "divorced":'Đã ly hôn',
  "divorcedtips":'Đã ly hôn',
  "do you want to delete this?":'Bạn có muốn xóa nội dung này?',
  "do you want to remove this post?":'Bạn có muốn xóa bài viết này?',
  "Do you want to spend your time, talent, and effort on community activities, or desire to do social impact jobs?":'Bạn có muốn dành thời gian, tài năng và nỗ lực của mình cho các hoạt động cộng đồng hoặc mong muốn làm các công việc tác động xã hội?',
  "donate":'Quyên góp',
  "donate now":'Quyên góp ngay',
  "donate what you can":'Bạn có thể quyên góp',
  "donated":'Đã đóng góp',
  "donation":'Quyên góp',
  "donation campaigns":'Chương trình từ thiện',
  "donation code":'Mã đóng góp',
  "donation info":'Thông tin quyên góp',
  "donation manager":'Quản lý ủng hộ',
  "donation transfer":'Chuyển tiền cho chương trình từ thiện',
  "donation value":'Giá trị quyên góp',
  "donation value must be large than 0":'Nhập giá trị quyên góp phải lớn hơn 0',
  "donation-campaigns":'Chương trình từ thiện',
  "donations":'Quyên góp',
  "done":'đã hoàn thành',
  "done beneficiaries":'Đơn vị thụ hưởng thành công',
  "donetips":'Thành công',
  "donor":'Nhà tài trợ',
  "downvote":'Bỏ ủng hộ',
  "draft":'Bản soạn thảo',
  "drafttips":'Nội dung hoàn nguyên bản thảo, sao lưu nội bộ, tiếp tục gửi yêu cầu xuất bản',
  "earlier":'Thông báo cũ',
  "edit":'Chỉnh sửa',
  "edit auction item":'Chỉnh sửa sản phẩm đấu giá',
  "edit comment":'Cập nhật bình luận',
  "edit delivery policy":'Chỉnh sửa chính sách giao hàng',
  "edit donate option":'Chỉnh sửa tùy chọn quyên góp',
  "edit donation":'Chỉnh sửa thông tin quyên góp',
  "edit donation campaigns":'Chỉnh sửa chương trình bán hàng',
  "edit giving":'Sửa thông tin trao đi',
  "edit image":'Sửa hình ảnh',
  "edit location":'Sửa địa điểm',
  "edit margin value":'Chỉnh sửa giá trị ký quỹ',
  "edit member":'Chỉnh sửa thành viên',
  "edit member information":'Thay đổi thông tin thành viên',
  "edit notice":'Chỉnh sửa thông báo',
  "edit opportunity":'Sửa thông tin tuyển dụng',
  "edit post":'Sửa bài viết',
  "edit product infomation":'Chỉnh sửa thông tin sản phẩm',
  "edit profile":'Sửa hồ sơ',
  "edit promotion campaign":'Chỉnh sửa chương trình khuyến mãi',
  "edit question":'Sửa câu hỏi',
  "edit return policy":'Chỉnh sửa chính sách đổi trả',
  "edit warranty policy":'Chỉnh sửa chính sách bảo hành',
  "education":'Giáo dục',
  "education/ training":'Giáo dục/Đào tạo',
  "educations":'Học vấn',
  "effective management and reporting":'Quản lý và báo cáo hiệu quả',
  "efficient successful and sustainable":'hiệu quả, thành công và bền vững',
  "Electronic & Accessories":'Điện tử & Phụ kiện',
  "email":'Thư điện tử',
  "emailplaceholder":'Thư điện tử (example@company.com)',
  "embed":'Nhúng nội dung',
  "empathize and help to connect these beneficiaries to the relevent activities that you should know":'Đồng cảm và giúp kết nối những mong muốn này với các hoạt động liên quan mà bạn biết',
  "employment type":'Loại công việc',
  "empty":'Không có dữ liệu',
  "end":'Kết thúc',
  "end date":'Ngày kết thúc',
  "end time":'Thời gian kết thúc ',
  "end time must be after start time":'Thời gian kết thúc phải sau thời gian bắt đầu',
  "end_at":'Kết thúc',
  "end_date":'Ngày kết thúc',
  "end_time":'Thời gian kết thúc',
  "ended":'Kết thúc',
  "ended at":'Kết thúc lúc',
  "ended session":'Đã kết thúc',
  "ending now":'Sắp kết thúc',
  "english":'English',
  "englishtips":'English',
  "enter a location":'Nhập địa điểm',
  "enter album name":'Nhập tên album',
  "enter amount":'Nhập giá trị',
  "enter keyword":'Nhập từ khóa',
  "enter name":'Nhập tên',
  "enter order code":'Nhập mã đơn hàng',
  "enter order user":'Nhập sản phẩm',
  "enter the activity name":'nhập tên hoạt động',
  "enter the reason you think is a violation":'Nhập lý do mà bạn cho là vi phạm',
  "enter your question here":'Điền câu hỏi của bạn vào đây',
  "enter your reply here":'Nhập câu trả lời ở đây',
  "environment":'Môi trường',
  "environmental":'Môi trường',
  "error message":'Lỗi! Kiểm tra kết nối và nhấn tải lại!',
  "event":'Sự kiện',
  "event time":'Thời gian sự kiện',
  "event timeline":'Dòng sự kiện',
  "event title":'Tên sự kiện',
  "events":'Sự kiện',
  "eventtips":'Sự kiện gồm nhiều hoạt động, nhiều người tham gia cùng thời gian, địa điểm xác định. Sự kiện được sử dụng để triển khai những kế hoạch của bạn.',
  "example":'Ví dụ',
  "example name":'Nguyễn Văn A',
  "experience_requirements":'Kinh nghiệm',
  "expired":'Hết hạn',
  "expired job":'Hết hạn tuyển dụng',
  "explore funding need":'Tìm kiếm hoạt động tài chính',
  "explore jobs in":'Tìm kiếm công việc',
  "explore the list of activities which are suitable for your need":'Tìm kiếm các hoạt động phù hợp với nhu cầu của bạn',
  "export data":'Xuất dữ liệu',
  "failed":'Thất bại',
  "farming & forestry":'Nông Lâm nghiệp',
  "Fashion & Accessories":'Thời trang & Phụ kiện',
  "fee":'Phí',
  "feedback":'Liên hệ Wishare',
  "feeds":'Chủ đề',
  "female":'Nữ',
  "femaletips":'Nữ',
  "field of work":'Lĩnh vực công tác',
  "filterplaceholder":'Lọc nội dung',
  "financial capital":'Nguồn lực \ntài chính',
  "financial capital for good":'Nguồn tài chính',
  "Find volunteers or create social impact locations, Experience the free recruitment on Wishare.":'Bạn tìm tình nguyện viên hoặc vị trí tạo tác động xã hội, Hãy trải nghiệm việc tuyển dụng miễn phí trên Wishare.',
  "finish":'Hoàn thành',
  "flexible":'Linh hoạt',
  "focusing on good connection":'Tập trung vào kết nối tốt',
  "follow":'Theo dõi',
  "followed you":'đã theo dõi bạn',
  "follower":'theo dõi',
  "followers only":'Người theo dõi tôi',
  "following":'Đang theo dõi',
  "following auction":'Phiên đấu giá theo dõi',
  "following events":'Sự kiện đã theo dõi',
  "following beneficiaries":'Đơn vị thụ hưởng đã theo dõi',
  "following-auction":'Phiên đấu giá đang theo dõi',
  "for":'Cho',
  "for community":'vì cộng đồng',
  "for recruiters, let access to our huge volunteer community":'Với nhà tuyển dụng, hãy truy cập vào cộng đồng tình nguyện lớn của chúng tôi',
  "for sales":'Hàng để bán',
  "for social and creative purpose":'cho mục đích xã hội và sáng tạo',
  "for volunteer, please let us give you a good recommendation":'Với tình nguyện viên, xin vui lòng cho chúng tôi một đề nghị tốt',
  "for volunteers":'Dành cho tình nguyện viên',
  "for who seek to raise money for the good thing, implementing your initiative":'Cho những người tìm cách kiếm tiền cho điều tốt, thực hiện sáng kiến của bạn',
  "forgotpass":'Quên mật khẩu?',
  "foundation":'Nguồn quỹ',
  "free for friends":'Miễn phí cho bạn bè',
  "free ship":'Miễn phí vận chuyển',
  "free ship with minimum amount":'Giao hàng miễn phí với đơn hàng',
  "frequency":'Tần suất',
  "frequencyplaceholder":'Tần suất',
  "friend":'Bạn bè',
  "friends":'Bạn bè',
  "from":'Từ',
  "from donors":'Tri ân',
  "full-time":'Toàn thời gian',
  "fullname":'Họ & tên',
  "fundraiser for good":'Gây quỹ',
  "gave":'Đã chuyển',
  "gender":'Giới tính',
  "gender equality":'Bình Đẳng Giới',
  "GenderEquality":'BinhDangGioi',
  "general":'Thông tin chung',
  "general information":'Thông tin chung',
  "get started":'Bắt đầu ngay',
  "gift":'Hiện vật',
  "gift product":'Hàng để tặng kèm',
  "gift product list":'Danh sách quà tặng đi kèm',
  "gift products":'Sản phẩm được tặng kèm',
  "give your job a title":'Tên của nội dung tuyển dụng',
  "give your job position":'Cho biết vị trí công việc này',
  "given number":'Đã trao đi',
  "giving":'Trao đi',
  "giving what we can?":'Hãy chia sẻ cùng chúng tôi',
  "go to":'Chuyển tới',
  "go to cart":'Đến giỏ hàng',
  "go to shop":'Đến cửa hàng',
  "good":'Tốt',
  "good health and well-being":'Sức Khỏe và Có Cuộc Sống Tốt',
  "graduated":'đã tốt nghiệp',
  "Great, you\'ve signed - the next step is to share far and wide to make sure everyone sees this campaign.":'Tuyệt vời! Bạn đã ký tên. Hãy tiếp tục chia sẻ chiến dịch này để mọi người nhìn thấy và cùng tham gia ',
  "Groceries & Pets":'Tạp hóa & Thú cưng',
  "group":'Tổ chức',
  "groups":'Tổ chức',
  "grouptips":'Tổ chức là không gian làm việc, kết nối nhiều người. Khởi tạo nhóm để tạo cổng chia sẻ thông tin công ty, các dự án (phi lợi nhuận, hội đoàn,...) hoặc một nhóm bạn bè.',
  "grouptypeplaceholder":'Loại tổ chức',
  "half successful":'Hoàn thành 1 phần',
  "has been linked":'đã được liên kết',
  "has been supported":'Đã được hỗ trợ',
  "has draft orders":'có đơn hàng chuẩn bị mua hàng',
  "has orders":'có đơn hàng đang xử lý',
  "haveaccount":'Đã có tài khoản?',
  "header":'Đầu trang',
  "Health & Beauty":'Sức khỏe & Làm đẹp',
  "healthcare":'Chăm sóc sức khỏe',
  "healthy":'Sức khỏe',
  "Height":'Chiều cao',
  "help":'Trợ giúp',
  "helps":'Trợ giúp',
  "here are some questions that employers want to send to you, answering questions will help employers and you understand each other better.":'Dưới đây là một số câu hỏi mà nhà tuyển dụng muốn gửi cho bạn, trả lời các câu hỏi sẽ giúp nhà tuyển dụng và bạn hiểu nhau hơn.',
  "hidden due to invalid content":'Nội dung bị ẩn do vi phạm Chính sách và Điều khoản của Wishare',
  "hide":'ẩn',
  "high to low":'Cao đến thấp',
  "history":'Lịch sử',
  "home":'Trang chủ',
  "Home & LifeStyle":'Nhà cửa & Công cụ',
  "hometown":'Quê nhà',
  "hosted by":'Được lập bởi',
  "hosted items":'Đơn vị đại diện',
  "hostedactivities":'Hoạt động',
  "hostedItem":'Đơn vị đại diện',
  "hosting":'Đơn vị tổ chức',
  "hosts":'Đơn vị tổ chức',
  "hour(h)":'giờ(h)',
  "hourly-work":'Việc làm theo giờ',
  "house number, street name":'Số nhà, tên đường',
  "how can you help?":'Bạn có thể quyên góp những gì?',
  "how many can you help?":'Bạn muốn quyên góp bao nhiêu?',
  "how many people do you need?":'Bạn cần bao nhiêu người?',
  "human":'Con người',
  "human capital":'Nguồn lực \ncon người',
  "human capital for good":'Nguồn nhân lực',
  "i have read and accepted with these":'Tôi đã đọc và chấp nhận với những',
  "If two topics should actually be the same, you can merge them here. All questions and editors grouped under this topic will be transferred to the other topic.":'Nếu hai chủ đề thực sự giống nhau, bạn có thể hợp nhất chúng ở đây. Tất cả các bài viết được nhóm theo chủ đề này sẽ được chuyển sang chủ đề khác.',
  "If you have any questions, please contact us for support":'Mọi thắc mắc, xin vui lòng liên hệ chúng tôi để được hổ trợ.',
  "If you want to create a crowdfunding activity for charitable, social or creative activities. Let\'s use and experience the crowdfunding function on our platform.":'Bạn muốn tạo môt hoạt động gây quỹ cho hoạt động từ thiện, xã hội hoặc sang tạo. Hãy trãi nghiệm việc gây quỹ trên nền tảng của chúng tôi.',
  "ignore":'Bỏ qua',
  "ignored":'Đã bỏ qua',
  "impact":'Tác động',
  "impact jobs":'Việc tạo tác động',
  "important note":'Thông báo quan trọng',
  "in":'NHẬP',
  "In 2015, world leaders agreed to 17 goals for a better world by 2030.  Wishare and the community jointly created the index over {n} activity. Join Wishare to share and contribute to these sustainability goals.":'Năm 2015, các nhà lãnh đạo thế giới đã đồng ý với 17 mục tiêu cho một thế giới tốt đẹp hơn vào năm 2030. Wishare và cộng đồng cùng tạo lập chỉ mục hơn {n} hoạt động. Hãy cùng Wishare tham gia chia sẻ và đóng góp cho các mục tiêu bền vững này.',
  "in order for an employer to reach you, update some of yourself":'Để nhà tuyển dụng có thể tìm thấy bạn, hãy cập nhật thêm thông tin về bạn',
  "in our good connection":'Chúng tôi kết nối',
  "in the same categories":'Sản phẩm cùng loại',
  "in-activated":'Đã tắt kích hoạt',
  "inactive":'Không hoạt động',
  "inactivetips":'Trang đã tạm ẩn hoặc vô hiệu hóa, thông tin được bảo mật nội bộ',
  "index":'Trang chủ',
  "industry, innovation and infrastructure":'Đổi Mới Và Hạ Tầng',
  "info":'Thông tin',
  "information":'Thông tin',
  "information about donating":'Thông tin quyên góp',
  "information where donations are made":'Thông tin nơi nhận quyên góp',
  "informationandmap":'Địa điểm tổ chức',
  "infrastructure":'Cơ sở hạ tầng',
  "initial price":'Giá ban đầu',
  "input":'đầu vào',
  "inreview":'Đang xét',
  "inreviewtips":'Chúng tôi đang xem xét trang của bạn và chúng tôi sẽ gửi email thông báo cho bạn ngay khi hoàn tất quy trình đánh giá.',
  "insert question":'Chèn thêm câu hỏi',
  "inspire your goodness":'Lan tỏa điều tốt lành của bạn',
  "instructions on how to reset your password were sent to":'Hướng dẫn về cách đặt lại mật khẩu của bạn đã được gửi đến',
  "intends to delivery from":'Dự định giao hàng từ',
  "internal":'Nội bộ',
  "internship":'Thực tập',
  "internships":'Thực tập sinh',
  "interpreter & translator":'Biên, Phiên dịch',
  "introduce yourself":'Giới thiệu về bạn',
  "invalid date":'Thời gian nhập chưa hợp lệ',
  "invalid email":'Địa chỉ email không hợp lệ',
  "invalid email address":'Địa chỉ e-mail không hợp lệ',
  "invalid_credentials":'Sai thông tin đăng nhập. Đề nghị kiểm tra lại tài khoản và mật mã đăng nhập!',
  "invite":'Mời',
  "is merged into":'đã được ghép vào',
  "is priority product":'Mặt hàng ưu tiên',
  "It\'s YOU":'Chính BẠN',
  "job description":'Mô tả công việc ',
  "job details":'Chi tiết công việc',
  "job filter":'Lọc công việc',
  "job function type":'Lĩnh vực công tác',
  "job information":'Thông tin công việc',
  "job type":'Loại việc làm',
  "job_requirements":'Điều kiện ứng tuyển',
  "job_types":'Loại công việc',
  "jobs":'Việc làm',
  "join":'Tham gia',
  "join day":'Ngày tham gia',
  "join us":'Tham gia',
  "join us now":'Tham gia ngay',
  "join us on mobile":'ứng dụng sẵn sàng trên',
  "joined":'Đã tham gia',
  "joined activities":'Chương trình đã tham gia',
  "joined groups":'Tổ chức đã tham gia',
  "just for you":'Dành riêng cho bạn',
  "keep your passion like an artist and manage your social mission like a tech CEO":'Giữ niềm đam mê của bạn như một nghệ sĩ và quản lý sứ mệnh xã hội của bạn như một CEO công nghệ',
  "keyword":'Nhập từ khóa',
  "language":'Ngôn ngữ',
  "language choose":'Ngôn ngữ hiển thị:',
  "language_requirements":'Trình độ ngoại ngữ',
  "languages":'Ngôn ngữ',
  "large scale":'quy mô lớn',
  "last bid price":'Giá thầu cuối cùng',
  "last bidder":'Người trả giá tốt nhất',
  "latitude":'Vĩ độ',
  "law - policy":'Luật - Chính sách',
  "law / legal services":'Luật pháp/ Pháp lý',
  "learn more":'Tìm hiểu thêm',
  "leave comment":'Viết nhận xét',
  "Length":'Chiều dài',
  "let discover and join to these activities below or apply your wish to request a support":'Hãy khám phá và tham gia các hoạt động dưới đây hoặc áp dụng mong muốn của bạn để yêu cầu hỗ trợ',
  "let discover the crowdfunding from the community and contribute to a change":'Hãy khám phá cộng đồng từ cộng đồng và góp phần thay đổi',
  "let make a free activity":'Tạo ngay một hoạt động miễn phí',
  "let sign and help to connect these beneficiaries to relevent activities or the kindness people":'Hãy ký tên và giúp đỡ để kết nối những mong muốn này với các hoạt động liên quan hoặc những người tử tế',
  "let\'s us help":'Hãy để chúng tôi giúp',
  "level":'Cấp độ',
  "life below water":'Tài Nguyên và Môi trường Biển',
  "life on land":'Tài Nguyên và Môi trường Đất liền',
  "LifeBelowWater":'TaiNguyenNuoc',
  "LifeOnLand":'TaiNguyenDat',
  "link target":'Địa chỉ liên kết ',
  "link title":'Tiêu đề liên kết',
  "link to":'Liên kết đến',
  "link to schedule":'Liên kết đến lịch trình',
  "linked":'đã liên kết',
  "links":'lượt liên kết',
  "live session":'Đang diễn ra',
  "loading":'Đang tải',
  "location":'Địa điểm',
  "location list":'Danh sách địa chỉ',
  "locationplaceholder":'Gõ địa điểm',
  "locations":'Địa điểm',
  "login":'Đăng nhập',
  "logout":'Đăng xuất',
  "longitude":'Kinh độ',
  "lost":'Thất lạc',
  "Lot size":'Kích thước lô',
  "low to high":'Thấp đến cao',
  "make a crowdfunding":'Gây quỹ cộng đồng',
  "make a crowdfunding now":'Tạo mới một hoạt động gây quỹ cộng đồng',
  "make a recruitment":'Nhà tuyển dụng',
  "make a beneficiary":'Tạo ước mơ',
  "make an activity":'Tạo hoạt động',
  "make digital conversions for social initiatives to help them work":'Thực hiện chuyển đổi kỹ thuật số cho các sáng kiến xã hội nhằm giúp họ làm việc',
  "make event":'Tạo sự kiện',
  "make opportunity now":'Tạo mới một tuyển dụng',
  "make post":'Bài viết',
  "makeactivityplaceholder":'nhập tên hoạt động',
  "makeeventplaceholder":'nhập tên sự kiện',
  "makegroupplaceholder":'nhập tên tổ chức',
  "makebeneficiaryplaceholder":'nhập tên đơn vị thụ hưởng',
  "male":'Nam',
  "maletips":'Nam',
  "mall":'Trung tâm mua sắm',
  "mall ":'Trung tâm mua sắm ',
  "mall & auction":'Trung tâm mua sắm & Đấu giá',
  "manage members":'Quản lý thành viên',
  "management":'Quản lý ',
  "manager":'Quản lý',
  "mandatory":'Bắt buộc',
  "manual":'Thực hiện thủ công',
  "manual / automatic":'Thủ công:* Chương trình đấu giá sẽ được điều khiển và bắt đầu bởi người quản lý đấu giá. Tự động:* Chương trình đấu giá sẽ tự động bắt đầu tại thời điểm đã quy định trong chương trình đấu giá.',
  "manufacturer":'Hãng sản xuất',
  "many people or causes that":'Nhiều người hoặc nhiều trường hợp',
  "many people or organisations":'Nhiều người hoặc tổ chức',
  "map":'Bản đồ',
  "margin value":'Giá trị ký quỹ',
  "marital_status":'Tình trạng hôn nhân',
  "mark \'active\' if you want social activity units to be able to reach you":'Đánh dấu \'Đang hoạt động\' cho phép nhà tuyển dụng xem thông tin của bạn và chủ động liên hệ mời phỏng vấn.',
  "mark all as read":'đánh dấu đã xem tất',
  "married":'Đã kết hôn',
  "marriedtips":'Đã kết hôn',
  "Material":'Vật liệu',
  "max_age":'Đến độ tuổi',
  "max_salary":'Đến thu nhập tối đa',
  "maximum with":'Tối đa với',
  "may be available to close, let check it again":'có thể chuyển sang trạng thái ĐÓNG, hãy kiểm tra lại.',
  "media":'Hình ảnh',
  "media & communication":'Phương tiện & truyền thông',
  "member":'Thành viên',
  "member filter":'Lọc thành viên',
  "member name":'Tên thành viên',
  "members":'Các thành viên',
  "membersadmintips":'Người quản lý, chỉnh sửa trang thông tin của một nhóm, hoạt động, sự kiện. Quản trị viên có quyền phê duyệt nội dung và chấp nhận thành viên mới',
  "membersmembertips":'Người dùng thuộc các nhóm, hoạt động, sự kiện bất kỳ',
  "membersownertips":'Người quản lý cấp cao của một nhóm, hoạt động, ước nguyện. Chủ quản có quyền cài đặt quản trị viên, phê duyệt thành viên mới',
  "merge tags":'Hợp nhất với từ khóa',
  "message has been sent":'Tin nhắn của bạn đã được gửi đến bộ phận hỗ trợ, Cám ơn bạn đã đóng góp ý kiến đến Wishare!',
  "methods":'Tổng tiền thanh toán',
  "min margin value":'Giá trị ký quỹ thấp nhất',
  "min_age":'Độ tuổi từ',
  "min_salary":'Thu nhập tối thiểu từ ',
  "minimum margin value":'Giá trị ký quỹ tối thiểu',
  "minimum with":'Tối thiểu với',
  "minute(m)":'phút(m)',
  "missing data":'Thiếu thông tin',
  "mission":'Sứ mệnh',
  "mission_placeholder":'Mục đích / Sứ mệnh hoạt động của chương trình',
  "money":'Hiện kim',
  "month":'Tháng',
  "months":'tháng',
  "more":'xem thêm',
  "more topics to continue":'Thêm Chủ đề để tiếp tục',
  "multiple choice":'Nhiều lựa chọn',
  "mutual users":'Bạn bè chung',
  "my auctions":'Đấu giá của tôi',
  "my orders":'Đơn hàng của tôi',
  "my questions":'Câu hỏi của tôi',
  "my reviews":'Nhận xét của tôi',
  "my stores":'Cửa hàng của tôi',
  "myfollowers":'Người dõi theo bạn',
  "name":'Họ tên',
  "name of consignee":'Tên người nhận hàng',
  "name of people you want to invite":'gõ tìm kiếm theo tên người bạn muốn mời tham gia',
  "nameplaceholder":'Họ & tên',
  "nature":'Tự nhiên',
  "need":'Gây quỹ',
  "need category type":'Loại gây quỹ',
  "need contact":'Thông tin quyên góp',
  "need from":'Bắt đầu nhận',
  "need help":'cần giúp đỡ',
  "need information":'Thông tin gây quỹ',
  "need manager":'Quản lý quỹ',
  "need title":'Tiêu đề chiến dịch gây quỹ',
  "need to":'nhận đến ngày',
  "needs":'Gây quỹ',
  "new activity":'Tạo hoạt đông mới',
  "new donate option":'Tùy chọn quyên góp mới',
  "create organization":'Tạo tổ chức',
  "new location":'Thêm mới địa điểm',
  "new orders":'Đơn hàng mới',
  "new password":'Mật khẩu mới',
  "new products":'Sản phẩm mới',
  "new question":'Câu hỏi mới',
  "news":'Tin mới',
  "next":'Tiếp',
  "next item":'Sản phẩm tiếp theo',
  "nextevents":'Sự kiện tiếp theo',
  "NGO, NPO, CSR, Social group":'NGO, NPO, CSR, nhóm hoạt động xã hội',
  "ngo/ non - profit":'Phi lợi nhuận/ Phi chính phủ',
  "no":'Không',
  "no categories":'Không có phân loại',
  "no data yet to display":'Chưa có dữ liệu để hiển thị',
  "no item found":'Không có dữ liệu',
  "no matches for":'Không có kết quả phù hợp cho',
  "No matter what you\'re a NPO or an enterprise or just an individual who passionote to make change, you\'re our friends. Wishare serves free to you to start. We welcome your sponsors or contributions for the advance features.":'Bất kể bạn là NPO hay doanh nghiệp hay chỉ là một cá nhân đam mê tạo ra sự thay đổi, bạn vẫn là bạn của chúng tôi. Wishare phục vụ miễn phí cho bạn để bắt đầu. Chúng tôi hoan nghênh các nhà tài trợ hoặc đóng góp của bạn cho các tính năng nâng cao.',
  "no milestones were created":'Không có cột mốc nào được tạo',
  "No milestones were created. Click here to start creating a schedule":'Không có cột mốc nào được tạo. Nhấn vào đây để bắt đầu tạo một lịch trình',
  "no more item":'Không còn thông tin nào khác',
  "no poverty":'Xóa Nghèo',
  "no products in your cart":'Không có sản phẩm nào trong giỏ hàng',
  "no profile found":'chưa có thông tin cá nhân',
  "no return":'Không đổi trả',
  "no tags":'Không có từ khóa',
  "no warranty":'Không bảo hành',
  "non-profit activity":'Hoạt động phi lợi nhuận',
  "none":'...',
  "NoPoverty":'XoaDoi',
  "normal":'Bình thường',
  "Not all of us can do great things. But we can do small things with great love.":'Không phải tất cả chúng ta đều làm được những điều vĩ đại. Nhưng chúng ta có thể làm những điều nhỏ nhặt với tình yêu vĩ đại.',
  "not good":'Không tốt',
  "not updated":'Chưa cập nhật',
  "not yet comment":'Chưa nhận xét',
  "not_activated_account_credentials":'Tài khoản chưa được kích hoạt. Vui lòng kiểm tra email để kích hoạt tài khoản.',
  "not_exists_account_credentials":'Tài khoản không tồn tại. Vui lòng đăng ký tài khoản mới hoặc đăng nhập bằng qua Google, Facebook!',
  "note":'Ghi chú',
  "notice description":'Mô tả thông báo',
  "notice management":'Quản lý thông báo',
  "notice name":'Tên thông báo',
  "notices":'Thông báo',
  "notifications":'Thông báo',
  "numerical order":'STT',
  "obligations":'Trách nhiệm',
  "of":'của',
  "old":'Người già',
  "old password":'Mật khẩu cũ',
  "old price":'Giá cũ',
  "on":'trên',
  "on this page":'Mục lục',
  "on_site":'Kiểu làm việc',
  "one product of":'Sản phẩm của',
  "online auction channel - charity donation with auctions - sell products with auctions":'Kênh đấu giá trực tuyến - quyên góp từ thiện thông qua đấu giá - bán sản phẩm thông qua đấu giá',
  "online jobs":'Việc làm trực tuyến',
  "only me":'Chỉ mình tôi',
  "open time":'Giờ mở cửa',
  "opportunities":'Tuyển dụng',
  "opportunity":'Tình nguyện viên',
  "oppr_type":'Loại tình nguyện',
  "optional":'Không bắt buộc',
  "options":'Tùy chọn',
  "or":'Hoặc',
  "or a":'hay một',
  "or helping to connect the social mission and the good beneficiaries":'hoặc là kết nối ước muốn với các hoạt động xã hội',
  "or login directly by":'hoặc đăng nhập trực tiếp với',
  "Or you update your personal information, leaving the mode \'available to volunteer\', employers will search for you.":'Hoặc bạn cập nhật thông tin cá nhân của mình, để chế độ \'Sẵn sàng\', nhà tuyển dụng sẽ tìm kiếm bạn.',
  "order":'Đơn hàng',
  "order code":'Mã đơn hàng',
  "order details":'Chi tiết đơn hàng',
  "order filter":'Lọc đơn hàng',
  "order information":'Thông tin đơn hàng',
  "order management":'Quản lý đơn hàng',
  "order number":'STT',
  "order status":'Trạng thái đơn hàng',
  "order user":'Sản phẩm người dùng',
  "ordered at":'Đã đặt hàng ngày',
  "orders":'Đơn hàng',
  "organization":'Tổ chức',
  "organizationtips":'Tổ chức thiện nguyện, phi lợi nhuận, NGO, ...',
  "other":'Khác',
  "other activities":'hoạt động khác',
  "other auction sessions":'Phiên đấu giá khác',
  "other display":'Hiển thị khác',
  "other information":'Thông tin khác',
  "other people":'những người khác',
  "other reasons":'Lý do khác',
  "other_requirements":'Yêu cầu khác',
  "others":'Khác',
  "othertips":'Cộng đồng, nhóm bạn bè, .. các nhóm khác',
  "Our tool help your save time, efficiency and scalability in mobilizing human resources, financial resources and the implementation of activities and releasing the reports. We have constantly improving to make you more convenient and help you to be more optimal.":'Công cụ của chúng tôi giúp bạn tiết kiệm thời gian, hiệu quả và khả năng mở rộng trong việc huy động nguồn nhân lực, nguồn tài chính và thực hiện các hoạt động và phát hành báo cáo. Chúng tôi đã liên tục cải tiến để làm cho bạn thuận tiện hơn và giúp bạn tối ưu hơn.',
  "out":'XUẤT',
  "output":'đầu ra',
  "Overs the years, we have passion, accumalated experience, helped promote social activities become effective. This technology platform supports you to carry out your activities base on. Theory of Change in principle: focus in impact, innovation; reliable infomation; transparent communication; effective connection.":'Ở nước ngoài những năm qua, chúng tôi có đam mê, tích lũy kinh nghiệm, giúp thúc đẩy các hoạt động xã hội trở nên hiệu quả. Nền tảng công nghệ này hỗ trợ bạn thực hiện các hoạt động dựa trên. Lý thuyết về sự thay đổi về nguyên tắc: tập trung vào tác động, đổi mới; thông tin đáng tin cậy; truyền thông minh bạch; kết nối hiệu quả.',
  "owner":'Chủ quản',
  "package":'Gói hàng',
  "packed":'Đã đóng gói',
  "paragraph":'Bài viết ngắn',
  "parallel":'Song song',
  "parallel mode":'Chế độ đấu giá',
  "part-time":'Bán thời gian',
  "partnerships":'Quan Hệ Đối Vì Các Mục Tiêu',
  "partnerships for the goals":'Quan Hệ Đối Tác Toàn Cầu',
  "password":'Mật khẩu',
  "password required":'Nhập mật khẩu đăng nhập',
  "passwordplaceholder":'Mật khẩu (gõ ít nhất 6 ký tự)',
  "payment":'Thanh toán',
  "payment completed":'Thanh toán hoàn thành',
  "payment location":'Địa chỉ thanh toán',
  "payment management":'Quản lý thanh toán',
  "payment method":'Phương thức thanh toán',
  "payment type":'Loại thanh toán',
  "peace, justice and strong institutions":'Hòa bình, Công lý và Các Thể Chế mạnh mẽ',
  "pending":'Chờ chấp nhận',
  "pending orders":'Đơn hàng đang chờ duyệt',
  "pending payment":'Chờ thanh toán',
  "pending beneficiaries":'Đơn vị thụ hưởng chờ phản hồi',
  "pendingdonations":'Đã đăng ký đóng góp',
  "pendingtips":'Chờ phản hồi',
  "people with disabilities":'Người khuyết tật',
  "per day":'/ Ngày',
  "per hour":'/ Giờ',
  "per month":'/ Tháng',
  "per quarter":'/ Quý',
  "per week":'/ Tuần',
  "per year":'/ Năm',
  "percent":'phần trăm (%)',
  "period time":'Thời gian đấu giá',
  "person":'Người',
  "person_s":'Ng',
  "phone":'Số điện thoại',
  "phone_number":'Điện thoại',
  "phone_placeholder":'Số điện thoại của bạn',
  "photo":'Hình ảnh',
  "photo gallery":'Bộ sưu tập hình ảnh',
  "photos":'Hình ảnh',
  "pick tags":'Nhấp chọn từ khóa',
  "place order":'Đặt hàng',
  "please complete the basic information":'Hãy điền đầy đủ thông tin cơ bản',
  "please select an organization, individual or group to be the owner":'Hãy chọn một tổ chức, cá nhân, nhóm để làm chủ quản ',
  "please sign with us, if you agree":'Vui lòng ký tên cùng chúng tôi, nếu bạn đồng ý',
  "please follow these instructions to complete the donation process.":'Vui lòng thực hiện các hướng dẫn sau để hoàn thành quy trình đóng góp.',
  "policy name":'Tên chính sách',
  "policyhelp":'Chính sách & Điều khoản',
  "policyhelptips":'Tin cậy và an toàn, Điều khoản sử dụng, Chính sách bảo mật, Cookies, Nguyên tắc cộng đồng',
  "position":'Chức danh',
  "position and size your photo":'Chỉnh vị trí và kích thước',
  "positive rating":'Đánh giá tích cực',
  "post":'gửi bài viết',
  "posted":'đã đăng bài',
  "prerequisites":'Đối tượng của hoạt động',
  "prerequisites_placeholder":'Đối tượng được hoạt động hướng tới',
  "current job":'Công việc hiện tại',
  "prevevents":'Sự kiện trước đây',
  "price":'Giá',
  "price and quantity":'Giá và Số lượng',
  "price type":'Chiều giá',
  "priority_conditions":'Điều kiện ưu tiên',
  "priority_conditions_placeholder":'Điều kiện xét ưu tiên đối với hoạt động',
  "privacy":'Quyền riêng tư',
  "privacy policy":'Chính sách bảo mật',
  "private":'Riêng tư',
  "privatetips":'Trang kín với quyền bảo mật cao nhất. Chỉ thành viên khởi tạo trang mới có thể xem nội dung, đăng tải thông tin. ',
  "process":'Đang thực hiện',
  "process to payment":'Chọn phương thức thanh toán',
  "processing":'Trong tiến trình',
  "processingtips":'Chờ xét duyệt',
  "product":'Sản phẩm',
  "product album":'Bộ ảnh sản phẩm',
  "product attributes":'Thuộc tính sản phẩm',
  "product categories":'Danh mục sản phẩm',
  "product category":'Danh mục sản phẩm',
  "product comment":'Nhận xét về sản phẩm',
  "product description":'Mô tả sản phẩm',
  "product faq":'Câu hỏi về sản phẩm',
  "product filter":'Lọc sản phẩm',
  "product filter by":'Lọc sản phẩm theo',
  "product group":'Nhóm sản phẩm',
  "product in the same auction":'Sản phẩm cùng chương trình',
  "product in the same categories":'Sản phẩm cùng danh mục',
  "product management":'Quản lý sản phẩm',
  "product name":'Tên sản phẩm',
  "product price":'Giá sản phẩm',
  "product priority":'Hàng ưu tiên',
  "product rating":'Đánh giá chất lượng sản phẩm',
  "product reviews":'Nhận xét về sản phẩm',
  "product reviews and ratings":'Nhận xét và đánh giá sản phẩm',
  "product searching":'Tìm kiếm sản phẩm',
  "product type":'Loại sản phẩm',
  "products":'Sản phẩm',
  "products in same category":'Sản phẩm khác cùng danh mục',
  "products in same store":'Sản phẩm khác cùng cửa hàng',
  "profile":'Quản lý hồ sơ',
  "profile information":'Thông tin ứng viên',
  "program description":'Mô tả chương trình',
  "program filter":'Lọc tên chương trình',
  "program name":'Tên chương trình',
  "promote sharing connecting good things to help people everywhere be happy":'Thúc đẩy chia sẻ kết nối các điều tốt để giúp đỡ mọi người, mọi nơi có được hạnh phúc',
  "promotion":'Khuyến mãi',
  "promotion campaign name":'Tên chương trình khuyến mãi',
  "promotion campaigns":'Chương trình khuyến mãi',
  "promotions":'Khuyến mãi',
  "propose as owner":'được đề xuất làm khởi xướng cho',
  "proposed applicants":'Hồ sơ tham khảo',
  "provide the most accurate address so people can find your store faster":'Cung cấp địa chỉ chính xác nhất để mọi người có thể tìm đến cửa hàng của bạn nhanh hơn',
  "province":'Tỉnh, Thành phố',
  "public":'Công khai',
  "public / private":'Công khai:* Chương trình đấu giá dành cho tất cả mọi người / Riêng tư:* Chương trình đấu giá chỉ dành cho thành viên của chương trình đấu giá',
  "public display":'Hiển thị',
  "public name":'Tên hiển thị',
  "publictips":'Trang hiển thị công khai với mọi người. Bất kỳ ai cũng có thể xem thành viên và những gì họ đăng',
  "publish reply":'Trả lời bình luận',
  "published":'Đã xuất bản',
  "publishedtips":'Nội dung hoàn tất và sẵn sàng xuất bản, mọi người có thể xem, nhận xét, chia sẻ',
  "publishrequest":'Yêu cầu duyệt',
  "publishrequesttips":'Nội dung đang được xác thực, bạn sẽ nhận thông báo qua email trong vòng 24 giờ ',
  "quality education":'Giáo Dục Có Chất Lượng',
  "quantity":'Số lượng',
  "question":'Câu hỏi',
  "question answer":'Các câu hỏi thường gặp',
  "question type":'Loại câu hỏi',
  "questions":'Câu hỏi',
  "questions and answers":'Câu hỏi & Câu trả lời',
  "quote":'Trích dẫn',
  "ranking":'Xếp hạng',
  "rate store":'Đánh giá cửa hàng',
  "rate type":'Loại tỷ lệ',
  "rating":'Đánh giá',
  "rating from customers":'đánh giá từ khách hàng',
  "rating successful":'Đánh giá thành công',
  "ready to ship":'Sẵn sàng giao',
  "readytopublish":'Sẵn sàng xuất bản',
  "readytopublishtips":'Nội dung phù hợp, sẵn sàng công khai với mọi người',
  "reasons":'Lý do',
  "received":'đã nhận',
  "received unit":'Đơn vị được nhận',
  "receiver":'Người nhận',
  "receiver information":'Thông tin người nhận',
  "recent searches":'Tìm kiếm gần nhất',
  "recommend":'Giới thiệu',
  "recommend activities":'Hoạt động',
  "recommend events":'Sự kiện sắp diễn ra',
  "recommend groups":'Nhóm',
  "recommend needs by following":'Gây quỹ',
  "recommend store":'Giới thiệu cửa hàng',
  "recommend tags":'Tags (Thẻ) gợi ý',
  "recommend users":'Người bạn có thể quen',
  "recommend beneficiaries":'Đơn vị thụ hưởng',
  "recruitment for good":'Tuyển dụng',
  "recruitment question":'Câu hỏi tuyển dụng',
  "recruitment_email":'Email tuyển dụng',
  "recruitment_phone":'Số điện thoại',
  "red":'đỏ',
  "reduced inequalities":'Giảm Bất Bình Đẳng',
  "ReducedInequalities":'GiamBatBinhDang',
  "reference":'tham chiếu',
  "regconfirmpass":'Nhập lại mật khẩu',
  "register":'Đăng ký',
  "register enabled":'Cho phép đăng ký',
  "register to donate":'Đăng ký quyên góp',
  "register value":'Giá trị đăng ký quyên góp',
  "registered number":'Số đã đăng ký',
  "registered to donate":'đã đăng ký quyên góp',
  "regpass":'Mật khẩu',
  "regular":'Thường xuyên',
  "regulartips":'Regular activity',
  "reject":'Từ chối',
  "rejected":'Đã từ chối',
  "rejected applicants":'Hồ sơ đã từ chối',
  "rejectedtips":'Nội dung soạn thảo chưa phù hợp. Vui lòng kiểm tra lại và gừi yêu cầu xuất bản',
  "related tags":'Các từ khóa liên quan',
  "religion":'Tôn giáo',
  "remain":'Còn lại',
  "remember":'Giữ đăng nhập',
  "remotely work":'Làm việc từ xa',
  "remove":'Xóa',
  "remove comment":'Xóa bình luận',
  "remove from your order":'Xoá khỏi đơn hàng',
  "removed":'Đã xóa',
  "replies":'Trả lời',
  "reply":'Hồi đáp',
  "reply customer":'Trả lời khách hàng',
  "reply from the store":'Phản hồi từ cửa hàng',
  "replyplaceholder":'Gõ trả lời..',
  "report":'Báo cáo',
  "report violation shop":'Báo cáo cửa hàng vi phạm',
  "reported":'Đã báo cáo',
  "reports":'Thông báo xấu',
  "request":'Gửi yêu cầu',
  "requested":'Các yêu cầu được gửi đến',
  "required":'Bắt buộc',
  "required information fields":'Các thông tin yêu cầu',
  "requirements":'Yêu cầu công việc',
  "reset":'Khởi tạo lại',
  "Reset your password":'Quên mật khẩu',
  "responded":'trả lời',
  "responsible consumption and production":'Tiêu Dùng & Sản Xuất Có Trách Nhiệm',
  "results with keyword":'Kết quả tìm kiếm từ khoá',
  "return conditions":'Điều kiện đổi trả',
  "return period":'Thời gian đổi trả',
  "return period type":'Loại đổi trả',
  "return period value":'Thời hạn đổi trả',
  "return policy":'Chính sách đổi trả',
  "return to draft":'Quay về trạng thái soạn thảo',
  "returned":'Hoàn trả',
  "retype new password":'Nhập lại mật khẩu mới',
  "Retype password":'Nhập lại mật khẩu',
  "review and rating on store":'Nhận xét và đánh cửa hàng',
  "review management":'Quản lý nhận xét',
  "review store":'Nhận xét cửa hàng',
  "reviews":'Nhận xét',
  "role":'Vai trò',
  "role description":'Mô tả vai trò',
  "rotate":'Xoay',
  "running promotion campaigns":'Chương trình khuyến mãi đang chạy',
  "salary":'Mức thu nhập',
  "salary period":'Kỳ trả lương',
  "sale":'Giảm',
  "sanitary food":'Thực phẩm sạch',
  "save":'Lưu',
  "save data":'Lưu thông tin',
  "save question":'Lưu câu hỏi',
  "scale":'Phóng to',
  "scan code on Wishare for direct access to the target screen":'Quét mã trên Wishare để chuyển hướng đến màn hình đích',
  "scan qr-code":'Quét mã QR',
  "schedule":'Lịch trình',
  "school":'Trường học',
  "School Book":'Sách giáo khoa',
  "sdg":'Mục Tiêu Phát Triển Bền Vững',
  "search":'Tìm kiếm',
  "search activities":'Tìm kiếm hoạt động',
  "search category":'Tìm danh mục',
  "search events":'tìm sự kiện',
  "search groups":'tìm nhóm',
  "search groups users activities you want to be hosts":'Tìm thành viên, nhóm, hoạt động có thể trở thành chủ quản',
  "search groups,users,activities you want to be hosts":'Tìm kiếm nhóm, hoạt động làm đại diện',
  "search in wishop":'Tìm kiếm trong Wishop...',
  "search members":'Tìm thành viên',
  "search needs":'tìm dự án gây quỹ',
  "search jobs":'Tìm việc làm ',
  "search product to attach":'Tìm kiếm mặt hàng tặng kèm',
  "search selected category":'Tìm danh mục đã chọn',
  "search user":'Tìm kiếm người dùng',
  "search beneficiaries":'tìm kiếm đơn vị thụ hưởng..',
  "searching":'tìm kiếm',
  "searching result is empty":'0 kết quả tìm kiếm',
  "searching volunteers":'Tìm nguồn nhân lực',
  "searchplaceholder":'gõ từ khóa..',
  "seconds(s)":'giây(s)',
  "secret":'Bí mật',
  "secrettips":'Trang hiển thị nội bộ, thành viên chính thức mới có thể xem thành viên, chia sẻ, đăng tải thông báo',
  "security policy":'Chính sách Bảo mật',
  "see all":'Xem tất cả',
  "see less":'thu lại',
  "see map":'Xem vị trí',
  "see more":'Xem tiếp',
  "see more jobs":'Xem thêm tuyển dụng khác',
  "seek financial capital for good initiatives":'Tìm kiếm nguồn vốn tài chính cho các sáng kiến tốt đẹp',
  "select":'Chọn',
  "select all":'Chọn tất cả',
  "select attribute...":'Chọn thuộc tính...',
  "select location":'Chọn địa chỉ',
  "select location on map":'Chọn vị trí trên bản đồ',
  "select option":'Chọn tùy chọn',
  "select the payment method":'Chọn phương thức thanh toán',
  "select the product catalog for your store":'Lựa chọn những danh mục sản phẩm dành cho cửa hàng của bạn',
  "select the topic category you are interested in by clicking on the items below":'Chọn danh mục chủ đề bạn quan tâm bằng cách nhấp vào các mục bên dưới',
  "select_conditions":'Điều kiện xét duyệt',
  "select_conditions_placeholder":'Điều kiện để xét duyệt, sàn lọc các đối tượng cho chương trình',
  "Select...":'Lựa chọn',
  "Sell as lot":'Bán nhiều',
  "selling price":'Giá bán',
  "send a message to <%= title %> with questions or comments about this volunteer opportunity.":'Gửi tin nhắn cho <%= title %> chia sẻ thêm thông tin giới thiệu của bạn đến nhà tuyển dụng',
  "send feedback":'Gửi đến Wishare',
  "send to":'Gửi đến',
  "send to an activity":'Gửi đến hoạt động',
  "sender":'Người gửi',
  "senior advisor":'cố vấn cao cấp',
  "sent":'đã gửi',
  "sequence":'Tuần tự',
  "sequence / parallel":'Tuần tự:* Các sản phẩm trong cùng chương trình sẽ được đấu giá từng sản phẩm theo thứ tự đặt ra. Chỉ có 1 phiên duy nhất tại 1 thời điểm / Song song:* Các sản phẩm trong cùng chương trình sẽ được đấu giá cùng lúc ở nhiều phiên khác nhau.',
  "set as default":'Đặt làm địa chỉ mặc định',
  "setting":'Cài đặt',
  "settings":'Cài đặt',
  "share":'Chia sẻ',
  "share options":'Chia sẻ với',
  "share to multipal pages":'Chia sẻ đến nhiều trang trong Wishare',
  "share to my page":'Chia sẻ đến trang của tôi',
  "share with everyone":'Chia sẻ với mọi người',
  "shared":'đã chia sẻ',
  "shipped":'Đang vận chuyển',
  "shop":'Cửa hàng',
  "shop description":'Giới thiệu cửa hàng',
  "shop for you":'Cửa hàng dành cho bạn',
  "shop infomation":'Thông tin cửa hàng',
  "shop product":'Sản phẩm từ cửa hàng',
  "shop products faq":'Cửa hàng hồi đáp',
  "shopping":'Mua sắm',
  "shopping management":'Quản lý mua hàng',
  "short":'Thu gọn',
  "should change status to publishrequest to complete":'cần chuyển trang thái sang YÊU CẦU DUYỆT để hoàn tất',
  "show":'hiển thị',
  "show map":'Hiển thị bản đồ',
  "show profile":'Xem hồ sơ',
  "show result for":'hiển thị kết quả tìm kiếm cho',
  "sign":'Ký tên',
  "Sign in":'Đăng nhập',
  "sign in to connect":'Đăng nhập để kết nối cộng đồng',
  "Sign up":'Đăng ký',
  "signed":'Đã ký tên',
  "signin":'Đăng nhập',
  "silent mode":'Chế độ im lặng',
  "single":'Độc thân',
  "singletips":'Độc thân',
  "sitemap":'Sơ đồ',
  "Size":'Kích thước',
  "skills":'Kỹ năng chuyên môn',
  "social activities exits everywhere, form individuals, NPOs or enterprises":'Các hoạt động xã hội có mặt ở mọi nơi, đến từ các sáng kiến của cá nhân, tổ chức phi lợi nhuận hoặc doanh nghiệp',
  "social capital":'Nguồn lực \nxã hội',
  "social worker":'Ngành công tác xã hội',
  "sold":'Đã bán',
  "sold by":'Bán bởi',
  "sold price":'Giá đã bán',
  "sold quantity":'Số lượng đã bán',
  "specific return policy":'Chính sách đổi trả riêng của sản phẩm',
  "specific warranty policy":'Chính sách bảo hành riêng của sản phẩm',
  "specific_activity":'Hoạt động cụ thể',
  "specific_activity_name":'Gửi đến hoạt động cụ thể ',
  "specifications":'Thông số kỹ thuật',
  "sponsors for the project":'Các nhà tài trợ cho dự án',
  "sport":'Thể thao',
  "sports & entertainment":'Thể thao & giải trí',
  "Sports & Travel":'Thể thao & Du lich',
  "star":'sao',
  "stars":'sao',
  "start":'Bắt đầu',
  "start at":'Bắt đầu lúc',
  "start creating activities, events or groups to declare organizations seeking volunteers (if you\'ve shared organizational information, go to step 3)":'Bắt đầu tạo hoạt động, sự kiện hoặc nhóm để khai báo tổ chức, tìm kiếm tình nguyện viên (Nếu đã chia sẻ thông tin tổ chức, hãy chuyển sang bước 3)',
  "start date":'Ngày bắt đầu',
  "start_at":'Bắt đầu',
  "start_date":'Ngày bắt đầu làm việc?',
  "start_time":'Thời gian bắt đầu',
  "status":'Trạng thái',
  "statusplaceholder":'Chọn trạng thái',
  "step":'Bước',
  "step 1":'Bước 1',
  "step 2":'Bước 2',
  "step 3":'Bước 3',
  "step 4":'Bước 4',
  "step 5":'Bước 5',
  "step 6":'Bước 6',
  "Steps to create an activity:":'Các bước tạo một hoạt động:',
  "stop donate":'ngừng đăng ký',
  "store address":'Địa chỉ cửa hàng',
  "store description":'mô tả cửa hàng',
  "store manager":'Quản lý cửa hàng',
  "store name":'Tên cửa hàng',
  "store setting":'Cài đặt cửa hàng',
  "strategic partner":'đối tác chiến lược',
  "strategic sponsor":'nhà tài trợ chiến lược',
  "strengthen successful connections based on the":'Tăng cường các kết nối thành công dựa trên sự',
  "sub total":'Tổng cộng',
  "submit":'Chấp nhận',
  "subscription email":'Thư điện tử (đăng ký nhận tin)',
  "subscription_email":'Bạn có thể đăng ký thư điện tử để nhận thông báo mới từ hệ thống',
  "success":'Thành công',
  "successful":'Thành công',
  "support":'Ủng hộ',
  "Support the UN Global Goals":'Hỗ trợ các Mục tiêu Toàn cầu của Liên Hợp Quốc',
  "supported":'đã ủng hộ',
  "supporthelp":'Trung tâm hỗ trợ',
  "supporthelptips":'Hướng dẫn sử dụng, Hỏi - đáp, Trợ giúp',
  "sustainable cities and communities":'Đô Thị & Cộng Đồng Bền Vững',
  "SustainableCitiesAndCommunities":'DoThiVaCongDong',
  "system error":'Thông báo từ ứng dụng',
  "tag":'Đánh dấu',
  "tags":'Tags',
  "tags for you":'Từ khóa cho bạn',
  "tagsplaceholder":'Chọn đánh dấu',
  "take":'Nhận',
  "target":'Mục tiêu',
  "teacher, teaching assistant":'Giáo viên, Trợ giảng',
  "team":'Đội nhóm ',
  "temporary winner":'Người chiến thắng tạm thời',
  "terms":'Điều khoản',
  "terms of use":'Điều khoản sử dụng',
  "thank you":'Cám ơn',
  "thank you for registering to donate":'Cám ơn bạn đã đăng ký quyên góp',
  "Thank you for taking the time to fill in our online feedback form. By providing us your feedback, you are helping us understand what we do well and what improvements we need to implement.":'Cảm ơn bạn dành thời gian điền thông tin biểu mẫu phản hồi trực tuyến. Bằng cách gửi thông tin phản hồi, bạn đang hỗ trợ chúng tôi hiểu tính năng nào đang hoạt động tốt và gợi ý đề xuất để chúng tôi tiếp tục cải tiến. ',
  "thank you for your feed back to product and shop":'Cám ơn bạn đã phản hồi cho sản phẩm và cửa hàng',
  "the 17 goals":'17 MỤC TIÊU CỦA LIÊN HIỆP QUỐC',
  "the accept action was successful":'Hành động chấp nhận đã thực hiện thành công',
  "the activation email has been sent to":'Email kích hoạt đã được gửi đến',
  "the causes that we serve":'Lý lẽ mà chúng tôi tồn tại',
  "the community creates social impact, does the good things and lets the wind blow away":'Cộng đồng tạo tác động xã hội, \ncùng làm điều tốt, rồi để gió cuốn đi',
  "The following topics are currently merged into this topic:":'Các chủ đề sau đây hiện đang được hợp nhất vào chủ đề này:',
  "The information given within the Feedback Form will be used for service improvement only and are strictly confidential.":'Thông tin mà bạn cung cấp trong biểu mẫu sẽ được chúng tôi tham khảo sử dụng để cải thiện chất lượng dịch vụ và được bảo mật tuyệt đối. ',
  "the information has been saved successful":'Thông tin đã được ghi nhận thành công',
  "the item has been saved":'Nội dung đã được ghi nhận hoàn tất',
  "the items you are you had accepted to be there hosts":'Các đối tượng đã chấp nhận là đơn vị đại diện',
  "the items you had been requested to be a host":'Các đối tượng bạn đã được yêu cầu làm đại diện',
  "the option has been added successful":'Tùy chọn đã được thêm thành công',
  "The platform helps you take advantage of technology development focused on social activities and immerce yourself in a lage community with a desire to bring great impact. The platform is accessible in multidevice and allows people to interact in real time, quickly mobilize strong support from the community.":'Nền tảng giúp bạn tận dụng sự phát triển công nghệ tập trung vào các hoạt động xã hội và hòa mình vào một cộng đồng lage với mong muốn mang lại tác động lớn. Nền tảng có thể truy cập trong nhiều trang và cho phép mọi người tương tác trong thời gian thực, nhanh chóng huy động sự hỗ trợ mạnh mẽ từ cộng đồng.',
  "the recruitment in the period":'Hạn nộp hồ sơ đăng ký',
  "the registration of funding for the project":'Các đăng ký tài trợ cho dự án',
  "the reject action was successful":'Hành động từ chối đã thực hiện thành công',
  "the social activities, who passionote to make an impact by mobilizing the community resources and implementing their good mission":'Các hoạt động xã hội, những người đam mê tạo ra ảnh hưởng bằng cách huy động các nguồn lực cộng đồng và thực hiện sứ mệnh tốt của họ',
  "the warning has been sent to admin":'Thông báo nội dung không phù hợp đã được gửi đi',
  "there are items that are not shown due to the privacy policies of those items":'Một số mục không hiển thị công khai do thuộc quyền riêng tư và chính sách bảo mật',
  "there is no need for transfer":'Chưa có nguyện vọng giúp đỡ',
  "This function is currently not open to everyone, please contact the Wishare administrator":'Chức năng này hiện không mở cho tất cả mọi người, vui lòng liên hệ với quản trị viên Wishare',
  "This page is collected and indexed by Wishare":'Nội dung được thu thập và chỉ mục bởi Wishare',
  "This page is collected and indexed by Wishare so that the community can easily find and contact the program":'Trang này được Wishare thu thập và lập chỉ mục để cộng đồng dễ dàng tìm kiếm và liên lạc với chương trình. Có nhiều thông tin chưa được cập nhật hoặc sai sót, xin cộng đồng liên lạc trực tiếp với chương trình để được hỗ trợ thông tin mới nhất. Wishare không chịu trách nhiệm về các hoạt động của chương trình.',
  "this product will extract":'Sản phẩm này sẽ trích ra',
  "this will send by admin":'Nội dung này sẽ được đăng bởi Quản trị viên',
  "tick size":'Bước giá',
  "time":'Thời gian',
  "time and location":'Thời gian & Địa điểm',
  "time characteristic":'Tính chất thời gian',
  "time open":'Thời gian mở cửa',
  "time_close":'Thời gian kết thúc',
  "time_open":'Thời gian bắt đầu',
  "time_type":'Thời gian làm việc',
  "timeline":'Dòng thời gian',
  "title":'Tựa đề',
  "titleplaceholder":'Gõ tiêu đề.',
  "to":'đến',
  "to develop the community or":'để phát triển cộng đồng hay',
  "to donate":'để quyên góp',
  "To help beneficiaries find your activity, create your activity on Wishare platform.":'Để giúp các đối tượng thụ hưởng tìm kiếm ra hoạt động của bạn, hãy tạo cổng truyền thông về hoạt động của bạn trên nền tảng Wishare.',
  "To help beneficiaries find your need, create your activity on Wishare platform.":'Để giúp người thụ hưởng tìm thấy nhu cầu của bạn, hãy tạo hoạt động của bạn trên nền tảng Wishare.',
  "to help us continue serving the community":'để giúp chúng tôi tiếp tục phục vụ cộng đồng',
  "to now":'đến hiện tại',
  "to support us spread the goodness":'để hỗ trợ chúng tôi lan truyền lòng tốt',
  "token_expired":'Phiên làm việc không hợp lệ hoặc đã hết hạn. Xin vui lòng đăng nhập lại',
  "top":'Đầu trang',
  "total":'Tổng',
  "total donation":'Tổng quyên góp',
  "total donation values":'đã đăng ký đóng góp',
  "total payment":'Tổng tiền thanh toán',
  "total product amount":'Tổng tiền sản phẩm',
  "total values":'Tổng giá trị',
  "total_activities":'Hoạt động',
  "total_activities_official":'Hoạt động (chính thức)',
  "total_activities_official_tips":'Các hoạt động được tạo lập và vận hành bởi cộng đồng',
  "total_activities_tips":'Các hoạt động xã hội được tạo lập (hoặc được lập chỉ mục bởi Wishare)',
  "total_address":'Đã liên kết',
  "total_crowdfunding_amount":'Gây quỹ',
  "total_crowdfunding_amount_tips":'Gây quỹ cho các hoạt động và công tác xã hội',
  "total_crowdfundings":'Gây quỹ cộng đồng',
  "total_crowdfundings_tips":'Các hoạt động gây quỹ cộng đồng nhằm mục đích hỗ trợ xã hội và sáng tạo',
  "total_donators":'Tổng người quyên góp',
  "total_events":'Sự kiện',
  "total_events_happening":'Sự kiện đang diễn ra',
  "total_events_happening_tips":'Các dự kiện đã và đang diễn ra tại thời điểm hiện tại',
  "total_events_incoming":'Sự kiện (sắp diễn ra)',
  "total_events_incoming_tips":'Các sự kiện sắp diễn ra',
  "total_events_tips":'Toàn bộ sự kiện đã, đang và sắp sửa diễn ra',
  "total_followers":'Người theo dõi',
  "total_following":'Đang theo dõi',
  "total_groups":'Nhóm',
  "total_links":'lượt liên kết',
  "total_links_tips":'Số lượt liên kết, kết nối từ ước nguyện đến các hoạt động hỗ trợ',
  "total_members":'Thành viên',
  "total_opportunities":'Tuyển dụng',
  "total_opportunities_tips":'Dành cho các tình nguyện viên và công việc tạo tác động xã hội',
  "total_shares":'Chia sẻ',
  "total_support":'Ủng hộ',
  "total_views":'Lượt truy cập',
  "total_volunteers":'Tình nguyện viên',
  "total_volunteers_tips":'Kết nối những người có nhu cầu và nhận nguồn nhân lực, thúc đẩy và tối ưu hóa công tác xã hội',
  "total_votes":'Ủng hộ',
  "total_beneficiaries":'Đơn vị thụ hưởng',
  "total_beneficiaries_done":'Đơn vị thụ hưởng thành công',
  "total_beneficiaries_done_tips":'Các đơn vị thụ hưởng đã hoàn thành',
  "total_beneficiaries_tips":'Toàn bộ các đơn vị thụ hưởng được gửi trên Wishare',
  "trademark":'Nhãn hiệu',
  "transfer":'Chuyển đi',
  "transfer all":'Chuyển tất cả',
  "trends":'Nổi bật',
  "trust and safety":'Tin cậy và An toàn',
  "trustworthy efficient transparent":'tin cậy, hiệu quả, minh bạch',
  "tutorial":'Hướng dẫn sử dụng',
  "type":'Loại',
  "type new password":'Nhập mật khẩu mới',
  "type of group":'Loại nhóm',
  "type of work":'Loại công việc',
  "type old password":'Nhập mật mẩu cũ',
  "type to search":'Nhập tìm kiếm',
  "type_association":'Hiệp hội',
  "type_associationtips":'Hiệp hội',
  "type_company":'Công ty',
  "type_companytips":'Công ty',
  "type_foundation":'Quỹ',
  "type_foundationtips":'Quỹ',
  "type_group":'Nhóm',
  "type_grouptips":'Nhóm',
  "type_ngo":'Tổ chức phi chính phủ',
  "type_ngotips":'Tổ chức phi chính phủ',
  "type_npo":'Tổ chức phi lợi nhuận',
  "type_npotips":'Tổ chức phi lợi nhuận',
  "type_organization":'Tổ chức',
  "type_organizationtips":'Tổ chức',
  "type_religion":'Tổ chức tôn giáo',
  "type_religiontips":'Tổ chức tôn giáo',
  "type_school":'Trường học',
  "type_schooltips":'Trường học',
  "typical products":'SẢN PHẨM NỔI BẬT',
  "typical shops":'Cửa hàng nổi bật',
  "typing":'Đang gõ',
  "undefined":'Chưa xác định',
  "undefinedtips":'Chưa xác định',
  "unfilter":'Bỏ lọc',
  "unfollow":'Bỏ theo dõi',
  "unit":'Đơn vị',
  "unlimited":'Không giới hạn',
  "unlimited age":'mọi lứa tuổi',
  "unread":'Chưa xem',
  "update":'Cập nhật',
  "update your profile":'Cập nhật hồ sơ',
  "upload images from your computer":'Tải ảnh từ máy tính của bạn',
  "user":'Người dùng',
  "user product faq":'Câu hỏi của bạn',
  "user profile":'Trang cá nhân',
  "user settings":'Quản lý tài khoản',
  "user_not_found":'Phiên đăng nhập đã hết hạn hoặc tài khoản không hợp lệ, vui lòng đăng nhập lại',
  "username":'Tài khoản',
  "users":'Người dùng',
  "value":'Giá trị',
  "value type":'Loại giá trị',
  "value_winner":'Giá trị chiến thắng',
  "variants":'Biến thể sản phẩm',
  "verify":'Xác thực',
  "verifying":'Đang xác thực',
  "view more":'Tải thêm',
  "view profile":'Xem hồ sơ',
  "vision":'Tầm nhìn',
  "Visually impaired people":'Người khiếm thị',
  "volunteer":'Tình nguyện viên',
  "volunteer & jobs":'Tình nguyện viên & việc làm',
  "volunteer_profile":'Thông tin tình nguyện viên',
  "volunteers":'Tình nguyện viên',
  "vote":'Ủng hộ',
  "voted":'Đã ủng hộ',
  "wage":'Lương',
  "wait for reply":'Chờ phản hồi',
  "waiting":'Đang chờ',
  "waiting applicants":'Hồ sơ chưa xem',
  "waiting need":'Đang nhận quyên góp',
  "want to help":'muốn giúp đỡ',
  "ward":'Phường, Xã',
  "warned":'đã cảnh báo',
  "warranty & return":'Bảo hành & đổi trả',
  "warranty conditions":'Điều kiện bảo hành',
  "warranty period":'Thời gian bảo hành',
  "warranty period type":'Loại bảo hành',
  "warranty period value":'Thời hạn bảo hành',
  "warranty policy":'Chính sách bảo hành',
  "warranty-policies":'Chính sách bảo hành',
  "warranty-return":'Bảo hành & Đổi trả',
  "was accepted for job at":'đã được chấp nhận cho công việc tại',
  "we believe that sharing and connecting these beneficiaries will create a great impact":'Chúng tôi tin rằng việc chia sẻ và kết nối những ước muốn này sẽ tạo ra tác động lớn',
  "We welcome any feedback, please feel free to contact us":'Chúng tôi chào đón mọi phản hồi, xin vui lòng liên hệ với chúng tôi',
  "we\'ve":'Đã có',
  "we\'ve found <%= number %> results":'Đã tìm thấy <%= number %> kết quả',
  "website":'Trang chủ',
  "website_placeholer":'(www.example.com)',
  "week":'Tuần',
  "weeks":'Tuần',
  "weight":'Khối lượng',
  "welcome to become wishop salesperson":'Chào mừng bạn trở thành người bán hàng của Wishop!!!',
  "what categories are your interests?":'Nhóm danh mục nào mà bạn quan tâm?',
  "what is your position?":'Chức danh của bạn là gì?',
  "what we connect":'Chúng tôi kết nối ?',
  "what you can do?":'Bạn có thể làm gì?',
  "what you need the crowdfunding for":'Bạn tạo hoạt động gây quỹ cộng đồng cho',
  "what you need volunteers for":'Bạn cần tìm kiếm tình nguyện viên dành cho:',
  "when you have a petition for you or for someone":'Khi bạn có một kiến nghị cho bạn hoặc cho ai đó',
  "where did you work?":'Bạn đã làm việc ở đâu?',
  "where does the opportunity take place?":'Địa điểm làm việc diễn ra ở đâu?',
  "which school did you study?":'Bạn đã học tại trường nào?',
  "who can do for good and make great impact by sharing human capital, financial capital to realise the social mision":'Người có thể làm điều tốt và tạo ra ảnh hưởng lớn bằng cách chia sẻ vốn nhân lực, vốn tài chính',
  "why partner with wishare ?":'Tại sao hợp tác với wishare ?',
  "Width":'Chiều rộng',
  "will end in":'sẽ kết thúc trong',
  "win price":'Giá chiến thắng',
  "win_price":'Giá chiến thắng',
  "winner":'Người chiến thắng',
  "beneficiary":'Thụ hưởng',
  "beneficiary list":'Đơn vị thụ hưởng',
  "wishare is a sharing platform that connects aspirations geared to impact, innovation and kind-hearted people":'Wishare là một nền tảng trách nhiệm xã hội chia sẻ kết nối những ước nguyện hướng đến tạo ra tác động, đổi mới và những người thiện tâm',
  "Wishare is willing to adjust the content or transfer the page owner right to the program upon request.":'Wishare sẵn sàng điều chỉnh nội dung, đóng trang hoặc chuyển giao quyền sở hữu trang cho chương trình khi có yêu cầu.',
  "beneficiaries":'Bên thụ hưởng',
  "beneficiaries for good":'Thỉnh nguyện',
  "wishop regulation":'của Wishop đã quy định',
  "beneficiarytips":'Ước nguyện là điều tốt đẹp bạn mong muốn thực hiện. Bạn có thể khởi tạo ước nguyện mới để chia sẻ những hoàn cảnh khó khăn, vấn đề cấp bách cần được hỗ trợ, để huy động sự ủng hộ từ cộng đồng',
  "with":'với',
  "with keyword":'với từ khóa',
  "won session":'Đã chiến thắng',
  "work":'Công việc',
  "work fit for":'Công việc phù hợp với',
  "working date":'Ngày làm việc',
  "working time":'Thời gian làm việc',
  "workplace":'Nơi công tác',
  "write comment about store":'Chia sẻ cảm nhận về cửa hàng',
  "year":'Năm',
  "years":'năm',
  "yes":'Có',
  "yes / no":'Có / Không',
  "you":'Bạn',
  "you are the highest bidder":'Bạn là người trả giá cao nhất',
  "you can add or change information in the settings":'Bạn có thể thêm hoặc thay đổi thông tin trong phần cài đặt.',
  "you don\'t need login to do for good in this platform":'Bạn không cần đăng nhập để làm việc tốt trên nền tảng này',
  "you have 0 new message":'Bạn có 0 thông báo mới',
  "you have completed the registration steps please confirm the information again to finish":'Bạn đã hoàn tất các bước đăng ký, hãy xác nhận thông tin một lần nữa để kết thúc',
  "You should also read and understand the mobilization before contributing. We need you, but you are responsible for your actions. We try to establish a mechanism that restricts negative issues, but also rejects responsibilities regarding this fundraising activity. We are just a support platform.":'Bạn cũng nên đọc và hiểu việc huy động trước khi đóng góp. Chúng tôi cần bạn, nhưng bạn chịu trách nhiệm cho hành động của mình. Chúng tôi cố gắng thiết lập một cơ chế hạn chế các vấn đề tiêu cực, nhưng cũng từ chối các trách nhiệm liên quan đến hoạt động gây quỹ này. Chúng tôi chỉ là một nền tảng hỗ trợ.',
  "you should use a photo to help people recognize your store faster":'Bạn nên sử dụng 1 tấm ảnh để giúp cho mọi người nhận ra cửa hàng của bạn nhanh hơn',
  "Create quickly in steps if you don\'t have your own activities and groups yet":'Hãy tạo nhanh ngay bằng các bước nếu bạn vẫn chưa có các hoạt động và nhóm riêng của bạn',
  "you voted it":'Bạn đang ủng hộ',
  "you want to share or to implement your good initiative to the community":'Bạn muốn chia sẻ hoặc thực hiện sáng kiến tốt của mình với cộng đồng',
  "You\'re welcome to be":'Các bạn có thể trở thành',
  "your activities":'Hoạt động của bạn',
  "your auction name":'Tên đấu giá của bạn',
  "your bid":'Giá bạn đã đấu',
  "your cart currently has <%= number %> pending payment, you want to continue shopping now.":'Giỏ hàng hiện có <%= number %> sản phẩm đang chờ thanh toán, bạn muốn tiếp tục mua sắm ngay bây giờ.',
  "your cart currently has <%= number %> products, you want to continue shopping now.":'Giỏ hàng hiện có <%= number %> sản phẩm, bạn muốn tiếp tục mua sắm ngay bây giờ.',
  "your categories":'Danh mục của bạn',
  "your comment":'Nhận xét của bạn',
  "your comment about store":'Nhận xét của bạn về cửa hàng',
  "your friend":'Bạn bè',
  "your group":'Nhóm của bạn',
  "your order status will be update in":'Bạn có thể xem lại đơn hàng',
  "your order will be transfer as soon as possible":'Sản phẩm sẽ được cửa hàng giao đến cho bạn cách nhanh nhất',
  "your orders":'Đơn hàng của bạn',
  "your pending amount":'Bạn đã đăng ký',
  "your profile":'Thông tin về bạn',
  "your profile has been successfully submitted to the employer!":'Hồ sơ của bạn đã được gửi thành công cho nhà tuyển dụng!',
  "your store name":'Tên cửa hàng của bạn',
  "zero hunger":'Không còn nạn đói',
  "zones":'Phạm vi vùng hỗ trợ',
  "zones_placeholder":'Phạm vi vùng miền được hỗ trợ với hoạt động',
  "give":'Trao đi',
  "receive":'Nhận lại',
  "notes on the order":'Ghi chú trên đơn hàng',
  "closed status":'Trạng thái đóng',
  "close status":'Đóng trạng thái',
  "blocked status":'Trạng thái phong tỏa',
  "block status":'Thực hiện phong tỏa',
  "This content has completed the process, closing the status marks that you are no longer working on this content.":'Nội dung này đã hoàn tất quá trình, việc đóng trạng thái đánh dấu việc bạn không còn tiếp tục làm việc trên nội dung này thêm nữa.',
  "This content is required to blockade, Sessions on this content will be blocked from now.":'Nội dung này là bắt buộc để phong tỏa, Phiên trên nội dung này sẽ bị chặn kể từ bây giờ.',
  "closed statustips":'Nội dung này đã hoàn tất quá trình, việc đóng trạng thái đánh dấu việc bạn không còn tiếp tục làm việc trên nội dung này thêm nữa.',
  "Sorry customers. We currently only have {n} products to serve you. Please do not set over quantity. Thank you":'Xin lỗi quý khách hàng. Chúng tôi hiện chỉ có {n} sản phẩm để phục vụ quý khách. Vui lòng không đặt quá số lượng. Cám ơn quý khách',
  "let\'s start for free":'Khởi đầu miễn phí',
  "delivery method":'Phương thức vận chuyển',
  "Please choose a delivery method":'Vui lòng chọn phương thức giao hàng',
  "cancel":'Hủy bỏ',
  "Click here to create first contact":'Nhấp ở đây để tạo thông tin liên hệ',
  "Enter the email address associated with your account, and we will email you a link to reset your password.":'Nhập địa chỉ email được liên kết với tài khoản của bạn, chúng tôi sẽ gửi cho bạn một liên kết để thiết lập lại mật khẩu của bạn qua email.',
  "write":'viết',
  "copy link":'Sao chép liên kết',
  "share link via messenger":'Chia sẻ qua Messenger',
  "share on wishare":'Chia sẻ tại Wishare',
  "social network":'Mạng xã hội',
  "Don\'t have a volunteer for this activity or event yet?":'Bạn chưa có tình nguyện viên cho hoạt động, sự kiện này ?',
  "Tap the \"{1}\" button to complete and receive the Donation Code":'Nhấn vào nút "{1}" để hoàn tất và nhận Mã quyên góp',
  "share link via QR Code":'Chia sẻ liên kết qua ảnh QR',
  "User use phone cameras to quickly access this link":'Người dùng sử dụng camera điện thoại truy cập nhanh vào đường dẫn này',
  "Below is an incomplete or unconfirmed registration by a fundraising campaign manager.":'Dưới đây là đăng ký chưa hoàn tất hoặc chưa được xác nhận bởi người quản lý chiến dịch gây quỹ.',
  "Below is your donation. If something goes wrong, please contact the campaign admin. We thank you for your donation.":'Dưới đây là quyên góp của bạn. Nếu có điều gì đó sai sót, vui lòng liên hệ với quản trị viên chiến dịch. Chúng tôi cảm ơn bạn đã đóng góp.',
  "delete register of donation":'Xóa đăng ký quyên góp',
  "are you sure to remove this donation?":'Bạn có chắc chắn xóa khoản quyên góp này không?',
  "approve donation":'Duyệt nội dung quyên góp',
  "contact phone":'Số điện thoại liên hệ',
  "less":'Thu lại',
  "your donation":'Quyên góp của bạn',
  "This view screen is for the admin of the campaign":'Màn hình xem này dành cho quản trị viên của chiến dịch',
  "On behalf of the fundraising campaign, we would like to sincerely thank our sponsors":'Thay mặt cho chiến dịch gây quỹ, chúng tôi xin chân thành cảm ơn các nhà tài trợ',
  "Select and tap on the package to which you can donate":'Chọn và nhấn vào gói mà bạn có thể quyên góp',
  "Enter another value you want to donate":'Nhập một giá trị khác mà bạn muốn quyên góp',
  "search with":'Tìm kiếm với',
  "for admins":'Dành cho quản trị viên',
  "your name":'Tên của bạn',
  "contact email":'Thư điện tử liên hệ',
  "toward":'Hướng đến',
  "giving code":'Mã trao đi',
  "delete giving":'Xóa phần trao đi',
  "This giving will be remove?":'Mục trao đi này sẽ bị xóa đi?',
  "beneficiary account no":'Số tài khoản thụ hưởng',
  "beneficiary account name":'Tên tài khoản thụ hưởng',
  "transaction memo":'Nội dung chuyển khoản',
  "beneficiary bank name":'Tên ngân hàng',
  "beneficiary info":'Thông tin thụ hưởng',
  "This item will be deleted and cannot be recovered":'Mục này sẽ bị xóa và không thể khôi phục lại được',
  "Value on conversion for items: (quantity) / (Unit)":'Giá trị trên tương ứng với vật phẩm: (số lượng) / (Đơn vị)',
  "donate goods equivalent to":'tặng vật phẩm tương đương với',
  "donation type":'Hình thức quyên góp',
  "item":'Vật phẩm',
  "registered to goods equivalent to":'đã đăng ký tặng vật phẩm tương ứng với',
  "cash":'Tiền mặt',
  "goods":'Vật phẩm',
  "cash donations":'khoản hiện kim',
  "goods donations":'khoản vật phẩm',
  "It is more blessed to give than to receive.":'Cho thì có phúc hơn là nhận.',
  "(Acts 20:35).":'(CV 20,35).',
  "Holy Bible":'Kinh Thánh',
  "A dream you dream alone is only a dream. A dream you dream together is reality.":'Giấc mơ bạn mơ một mình chỉ là một giấc mơ. Giấc mơ bạn mơ cùng người khác là hiện thực.',
  "gave away":'đã trao đi',
  "giving type":'Hình thức trao đi',
  "giving value":'Giá trị trao đi',
  "with an goods equivalent of":'với hàng hóa tương đương',
  "with a cash amount of":'với khoản tiền là',
  "Are you sure you agreed to accept this person for the recruitment?":'Bạn có chắc đã đồng ý để nhận người này cho đợt tuyển dụng',
  "Are you sure you will reject this application for recruitment?":'Bạn có chắc sẽ từ chối hồ sơ này cho đợt tuyển dụng',
  "Time to be able to do volunteer work":'Thời gian có thể làm công việc thiện nguyện',
  "add time to be able to volunteer":'Thêm thời gian có thể thiện nguyện',
  "Kind of work what you like":'Loại công việc bạn thích',
  "The workplace (ward/district/province/country) that is right for you":'Nơi làm việc (phường / quận / tỉnh / quốc gia) phù hợp với bạn',
  "you want":'Bạn muốn',
  "anything":'Bất cứ thứ gì',
  "anywhere":'Bất cứ đâu',
  "learn about tools":'Tìm hiểu về các công cụ',
  "create an activity for free":'Tạo hoạt động miễn phí',
  "how does it work":'Cách thức hoạt động',
  "petition & connect":'Thỉnh nguyện & Kết nối',
  "petition":'Thỉnh nguyện',
  "recruitment":'Tuyển dụng',
  "sales":'Bán hàng',
  "inputs":'Đầu vào',
  "impacts":'Tác động',
  "outputs/outcome":'Đầu ra/Kết quả',
  "fundraising, donation activity":'Huy động nguồn vốn, vận động quyên góp',
  "recruitment, search for volunteer jobs":'Tuyển dụng nhân sự, tìm kiếm việc thiện nguyện',
  "sales, create financial resources for social activities":'Bán hàng, tạo nguồn tài chính cho các hoạt động xã hội',
  "auction, create financial resources for social activities":'Đấu giá, tạo nguồn lực tài chính cho các hoạt động xã hội',
  "petition, connect social activities":'Thỉnh nguyện, kết nối hoạt động xã hội',
  "community":'Cộng đồng',
  "0$ - free services":'0$ - Dịch vụ miễn phí',
  "serve the community":'phục vụ cộng đồng',
  "donate - sponsor":'Quyên góp - Tài trợ',
  "keep us running":'giúp chúng tôi vận hành',
  "tools toward impact":'Các công cụ hướng đến tạo tác động',
  "Support {1} campaign management tools to create impact according to the model of “{2}”.":'Hỗ trợ {1} các công cụ quản lý chiến dịch tạo tác động theo mô hình “{2}”.',
  "organization - social activities":'Tổ chức - Hoạt động xã hội',
  "theory of change":'Lý thuyết thay đổi',
  "Strategic planning":'Lập kế hoạch chiến lược',
  "effects":'Hiệu quả',
  "17 SDG":'17 Mục tiêu Phát triển Bền vững',
  "create your account":'Tạo tài khoản miễn phí',
  "launch your activity":'Khởi tạo hoạt động của bạn',
  "A free account to start your journey and track your impact. You can easily create your establishment to hold your activity and campaign.":'Tạo một tài khoản để bắt đầu hành trình và theo dõi tác động của bạn. Bạn cũng có thể dễ dàng tạo trang Tổ chức để quản lý hoạt động và chiến dịch của mình.',
  "launch your campaign":'Lan tỏa chiến dịch của bạn',
  "Manage your recruitment, crowdfunding, auction, selling, events and so on.":'Quản lý và lan tỏa chiến dịch tuyển dụng, gây quỹ cộng đồng của bạn đến với cộng đồng.',
  "An individual or an establishment can easily create Activities, Events, Shops, Auctions. Each activity is each project, each mission that you contribute to the community.":'Một cá nhân hoặc một tổ chức có thể dễ dàng tạo Hoạt động, Sự kiện. Mỗi hoạt động là mỗi dự án, mỗi sứ mệnh mà bạn đóng góp cho cộng đồng.',
  "commitment":'Cam kết',
  "Our solution is constantly improving, but some things will never change":'Giải pháp của chúng tôi không ngừng được cải tiến, nhưng một số điều sẽ không bao giờ thay đổi.',
  "Totally free, with no fees or commission":'Hoàn toàn miễn phí (không tính phí hoặc thu hoa hồng)',
  "0 $. This is the cost to use Wishare, now and forever. We engage this model to create a big impact for the community.":'0 $. Đây là chi phí để sử dụng Wishare, bây giờ và mãi mãi. Chúng tôi theo đuổi mô hình này để tạo ra tác động lớn cho cộng đồng.',
  "Continuous Improvement":'Cải tiến liên tục',
  "Always improve user experience based on our listening and deep knowledge of NPO, NGO, CSR, community activities. We help digitize and track your impact, making it user-friendly and convenient.":'Luôn cải thiện trải nghiệm người dùng dựa trên sự lắng nghe và kiến thức sâu rộng của chúng tôi về NPO, NGO, CSR, các hoạt động cộng đồng. Chúng tôi giúp số hóa và theo dõi tác động của bạn, làm cho nó trở nên thân thiện và tiện lợi.',
  "Free business model":'Mô hình kinh doanh miễn phí',
  "Desiring to provide free services to the community, our revenue model is based on the sponsorships, voluntary contributions from the community and advertising. It helps us maintain and develop the platform continuously.":'Mong muốn cung cấp dịch vụ miễn phí cho cộng đồng, mô hình doanh thu của chúng tôi dựa trên sự tài trợ, đóng góp tự nguyện từ cộng đồng và quảng cáo. Nó giúp chúng tôi duy trì và phát triển nền tảng liên tục.',
  "Campaign on Wishare":'Chiến dịch trên Wishare',
  "Wishare and Partners":'Wishare và Đối tác',
  "SDG & Category":'17 Mục tiêu phát triển bền vững',
  "totally free":'Hoàn toàn miễn phí',
  "now and forever.":'Mãi mãi như thế.',
  "toward impacts":'Tác động hướng đến',
  "our partners":'Đối tác',
  "Fundraising and charity management platform.":'Nền tảng gây quỹ và quản trị hoạt động thiện nguyện.',
  "Free and transparent.":'Miễn phí và minh bạch.',
  "mark":'Đánh dấu',
  "givings":'Trao đi',
  "cash givings":'Khoản hiện kim',
  "goods givings":'Khoản hiện vật',
  "impact categories":'Danh mục tạo tác động',
  "Values are summed up from the organization and its activities.":'Giá trị được tổng hợp từ tổ chức và các hoạt động của tổ chức.',
  "co-hosts":'Đồng tổ chức',
  "Use for: blood donation, ... form of donation calculated on each donation":'Sử dụng cho: hiến máu, ... dạng hình thức đóng góp tính trên từng lượt đóng góp',
  "Use for: donate money, donate goods, ... Contributions can be converted to monetary value":'Dùng để: tặng tiền, tặng hàng, ... Các khoản đóng góp có thể quy đổi thành giá trị tiền tệ',
  "donation by count":'Quyên góp theo lượt',
  "donation by value":'Quyên góp theo giá trị',
  "blood unit":'Đơn vị máu',
  "blood":'Hiến máu',
  "You register to contribute":'Bạn đăng ký quyên góp',
  "The organizers will contact you in case of needed. Please add some information":'Ban tổ chức sẽ liên hệ với bạn trong trường hợp cần thiết. Vui lòng bổ sung vài thông tin bạn nhé',
  "contact information":'Thông tin liên hệ',
  "impacts report":'Báo cáo tác động',
  "my givings":'Đã trao đi',
  "participate in volunteering":'Tham gia việc thiện nguyện',
  "pending donations":'Quyên góp chờ hoàn tất',
  "create crowdfunding":'Tạo chiến dịch huy động',
  "what donation?":'Quyên góp gì?',
  "Choose the right donation type for your campaign":'Chọn loại quyên góp phù hợp với chiến dịch của bạn',
  "Money/Goods... can allow donations. (If target is unlimited, please enter value \"0\")":'Tiền / Hàng hóa ... có thể cho phép quyên góp. (Nếu mục tiêu là không giới hạn, vui lòng nhập giá trị "0")',
  "..., which and how much?":'... Loại?, Bao nhiêu?',
  "Choose a campaign creator/initiator for the campaign":'Chọn đơn vị tạo chiến dịch / khởi xướng cho chiến dịch',
  "..., and whose?":'... Ai?',
  "Choose the impacts that the campaign towards":'Chọn các tác động mà chiến dịch hướng tới',
  "Write a more descriptive description of your campaign information, which helps the community better understand your campaign.":'Viết mô tả chi tiết hơn về thông tin chiến dịch của bạn, điều này giúp cộng đồng hiểu rõ hơn về chiến dịch của bạn.',
  "..., donation information and when?":'..., thông tin quyên góp, Thời gian?',
  "Donation information, Estimated start and end time of the campaign":'Thông tin quyên góp, thời gian bắt đầu và kết thúc ước tính của chiến dịch',
  "Please tap the create button to complete the information initialization. You can further adjust it later in the configuration section":'Vui lòng nhấn vào nút tạo để hoàn tất việc khởi tạo thông tin. Bạn có thể điều chỉnh thêm sau trong phần cấu hình',
  "Leave the value 0 if you do not want a specific goal":'Để giá trị 0 nếu bạn không muốn có một mục tiêu cụ thể',
  "finance":'Tài chính',
  "complete":'Hoàn tất',
  "another donation options":'Các tùy chọn quyên góp khác',
  "pending list":'DS chờ xử lý',
  "type_community":'Cộng đồng',
  "edit crowdfunding":'Chỉnh sửa huy động',
  "est. value":'Giá trị ước tính',
  "volume":'Số lượng',
  "Rice":'Gạo',
  "Food":'Thực phẩm',
  "Pepper":'Tiêu',
  "Fish sauce":'Nước mắm',
  "Bottle":'Chai',
  "Box":'Hộp/Thùng',
  "Instant Noodles":'Mì ăn liền',
  "to beneficiary":'Đến người nhận',
  "Successfully transferred to {1} the giving":'Đã chuyển thành công cho {1} phần tặng',
  "activities management":'Quản trị hoạt động',
  "ready for volunteering":'Sẵn sàng cho thiện nguyện',
  "your curriculum vitae":'Hồ sơ ứng tuyển',
  "link to your profile on another site":'Liên kết đến hồ sơ của bạn trên một trang web khác',
  "Money/Goods... can be offer. (If target is unlimited, please enter value \"0\")":'Tiền / Hàng hóa ... cho đi. (Nếu mục tiêu là không giới hạn, vui lòng nhập giá trị "0")',
  "targets":'Mục tiêu',
  "another giving options":'Các chọn lựa cho đi khác',
  "for activities that make an impact on the community":'cho các hoạt động tạo ảnh hưởng đến cộng đồng',
  "search crowdfunding":'Tìm chiến dịch gây quỹ',
  "search giving":'Tìm chiến dịch cho đi',
  "what offer?":'Cho đi?',
  "Choose the right offer type for your campaign":'Chọn danh mục các phần cho đi của bạn',
  "..., offer information and when?":'..., thông tin cho đi, thời gian?',
  "Offer information, Estimated start and end time of the campaign":'Thông tin cho đi, Thời gian dự tính bắt đầu, kết thúc',
  "..., and impact categories?":'..., danh mục tạo tác động?',
  "profile link":'Liên kết đến hồ sơ',
  "outputs":'Đầu ra',
  "outcome":'Kết quả',
  "volunteer job":'Công việc thiện nguyện',
  "visible":'Hiển thị',
  "invisible":'Không hiển thị',
  "ready":'Sẵn sàng',
  "not ready":'Chưa sẵn sàng',
  "link to the giving":'Liên kết đến phần trao đi',
  "donation guide":'Hướng dẫn quyên góp',
  "tank":'bình',
  "link to the donation":'Liên kết đến phần quyên góp',
  "SGD":'Singapore Dollar',
  "Vegetables":'Rau củ quả',
  "Oxygen tank":'Bình Oxy',
  "Diapers":'Tả (Bỉm)',
  "Bike":'Xe đạp',
  "Milk":'Sữa',
  "Mask":'Khẩu trang',
  "USD":'US Dollar',
  "EUR":'Đồng Euro',
  "crowdfunding campaign":'Chiến dịch huy động',
  "giving campaign":'Chiến dịch trao đi',
  "recruitment campaign":'Chiến dịch tuyển dụng',
  "financial balance":'Cân đối tài chính',
  "recruitments": 'Tuyển dụng',
  "for the output of impact activities in the community":'cho đầu ra của các hoạt động tác động trong cộng đồng',
  "make a giving":'Tạo trao đi',
  "job - volunteer":'Công việc - Việc thiện nguyện',
  "features":'Đặc điểm',
  "Recruit unlimited volunteers":'Tuyển dụng thiện nguyện viên không giới hạn',
  "You can post as many opportunities as you like, and there is no limit on how many volunteers you recruit.":'Bạn có thể đăng bao nhiêu cơ hội tùy thích và không có giới hạn về số lượng tình nguyện viên mà bạn tuyển dụng.',
  "Manage your volunteers":'Quản lý tình nguyện viên của bạn',
  "Once your volunteers start coming in, you\'ll be able to organize, filter and keep track of them.":'Khi bạn có được tình nguyện viên qua Wishare, bạn sẽ có thể sắp xếp, lọc và theo dõi họ.',
  "Upload and Download your list":'Tải lên và tải xuống danh sách của bạn',
  "Start out by uploading your own volunteer list. Don\'t worry, you can download your entire list of volunteers anytime.":'Bắt đầu bằng cách tải lên danh sách tình nguyện viên của riêng bạn. Đừng lo lắng, bạn có thể tải xuống toàn bộ danh sách tình nguyện viên của mình bất cứ lúc nào.',
  "Volunteer Analytics":'Phân tích tác động tuyển dụng',
  "Your dashboard is your volunteering hub. How many people are actively volunteer? Look no further.":'Trang tuyển dụng là trung tâm tìm kiếm của bạn. Có bao nhiêu người đang tích cực tình nguyện? Không cần tìm đâu xa.',
  "Show unlimited giving":'Mở phần cho tặng không giới hạn',
  "You can post as many giving as you like, and there is no limit on how many giving you give.":'Bạn có thể đăng bao nhiêu tặng tuỳ thích và không có giới hạn về số lượng tặng.',
  "Manage your Giving (Money and Goods)":'Quản lý việc cho đi của bạn (Tiền và Hàng)',
  "Once your giving start, you\'ll be able to organize, filter and keep track of them.":'Sau khi bạn mở phần trao đi, bạn sẽ có thể sắp xếp, lọc và theo dõi chúng.',
  "Start out by uploading your own giving list. Don\'t worry, you can download your entire list of giving anytime.":'Bắt đầu bằng cách tải lên danh sách trao đi của riêng bạn. Đừng lo lắng, bạn có thể tải xuống toàn bộ danh sách các phần trao đi của mình bất cứ lúc nào.',
  "Giving Analytics":'Phân tích tác động trao đi',
  "Your dashboard is your Giving. Look no further.":'Giúp bạn quản lý danh sách cho đi của bạn. Không cần tìm đâu xa.',
  "donation - crowdfunding":'Quyên góp - Huy động cộng đồng',
  "Register unlimited donation":'Đăng ký quyên góp không giới hạn',
  "You can post as many donation as you like, and there is no limit on how many donation you receive.":'Bạn có thể đăng bao nhiêu đóng góp tùy thích và không có giới hạn về số lượng đóng góp mà bạn muốn.',
  "Manage your Donations (Money and Goods)":'Quản lý các khoản đóng góp của bạn (Tiền và Hàng)',
  "Once your donation start coming in, you\'ll be able to organize, filter and keep track of them.":'Khi bạn có các khoản đóng góp, bạn sẽ có thể sắp xếp, lọc và theo dõi chúng.',
  "Start out by uploading your own donation list. Don\'t worry, you can download your entire list of donor and donation anytime.":'Bắt đầu bằng cách tải lên danh sách quyên góp của riêng bạn. Đừng lo lắng, bạn có thể tải xuống toàn bộ danh sách các nhà tài trợ và đóng góp của mình bất cứ lúc nào.',
  "Donation Analytics":'Phân tích tác động quyên góp',
  "Your dashboard is your donation. How many people are actively donors? Look no further.":'Trang quyên góp là trung tâm cho việc huy động nguồn lực. Có bao nhiêu người là nhà tài trợ tích cực? Không cần tìm đâu xa.',
  "stop subscribe":'Ngừng đăng ký',
  "subscribe":'Đăng ký',
  "giving value must be large than 0":'Giá trị đưa ra phải lớn hơn 0',
  "The object of the giving campaign":'Đối tượng của chiến dịch trao tặng',
  "the option has been removed successful":'Tùy chọn đã được xóa thành công',
  "the option has been edited successful":'Tùy chọn đã được chỉnh sửa thành công',
  "click here to add new giving package":'Bấm vào đây để thêm gói quà tặng mới',
  "Enter another value you want to giving":'Nhập một giá trị khác mà bạn muốn cho',
  "Tap to sign up for the package you want to receive":'Nhấn để đăng ký gói bạn muốn nhận',
  "Tap the \"{1}\" button to complete and receive the giving Code":'Nhấn vào nút "{1}" để hoàn tất và nhận Mã tặng',
  "recipient":'Người nhận',
  "You are registered to get":'Bạn đang đăng ký để nhận',
  "registered to get":'đã đăng ký để nhận',
  "Your subscription has been recorded, please follow the subscription status updates on the list in the campaign page":'Đăng ký của bạn đã được ghi lại, vui lòng theo dõi cập nhật trạng thái đăng ký trên danh sách trong trang chiến dịch',
  "your subscription":'Đăng ký của bạn',
  "delete register of giving":'Xóa đăng ký cho đi',
  "are you sure to remove this giving?":'Bạn chắc chắn muốn xóa mục cho đi này?',
  "remove this giving":'Xóa mục cho đi',
  "approve giving":'Duyệt phần cho đi',
  "{1} registered to get":'{1} đã đăng ký để nhận',
  "short answer":'Câu trả lời ngắn',
  "long answer":'Câu trả lời văn bản',
  "answer guide":'Hướng dẫn trả lời',
  "mandatory question":'Câu hỏi bắt buộc',
  "answer type":'Loại trả lời',
  "answer options":'Tùy chọn trả lời',
  "add more question":'Thêm câu hỏi',
  "add option":'Thêm tùy chọn',
  "optional question":'Câu hỏi KHÔNG bắt buộc',
  "additional questions":'Các câu hỏi bổ sung',
  "total target":'Tổng mục tiêu',
  "additional donor information":'Thông tin bổ sung về người quyên góp',
  "medal":'Huy chương',
  "Send a thank you email to donor":'Gửi thư cảm ơn đến người quyên góp',
  "send thank you email":'Gửi thư cảm ơn',
  "Deleting this content will not allow recovery, do you want to continue?":'Xóa nội dung này sẽ không cho phép khôi phục, bạn có muốn tiếp tục không?',
  "introduction":'Giới thiệu',
  "decent work and economic growth":'Công Việc Tốt Và Tăng Trưởng Kinh Tế',
  "Please answer the following questions to complete registration":'Vui lòng trả lời các câu hỏi sau để hoàn tất đăng ký',
  "registration questions":'Câu hỏi đăng ký',
  "change donation status to pending approval":'Thay đổi trạng thái đóng góp thành đang chờ phê duyệt',
  "accept application":'Chấp nhận hồ sơ',
  "reject application":'Từ chối hồ sơ',
  "create new":'Tạo mới',
  "hour":'giờ',
  "timesheet":'Thời gian biểu',
  "timesheets":'Bảng chấm công',
  "task":'Công việc',
  "send email notifications and reminders to complete donation":'Gửi thông báo và lời nhắc qua email để hoàn tất việc đóng góp',
  "duration":'Trong thời gian',
  "A donation confirmation email has been sent to you via the email address you entered.":'Một email xác nhận đóng góp đã gửi đến cho bạn qua địa chỉ email bạn nhập. ',
  "blood volume":'Dung tích máu',
  "campaign overview":'Tổng quan về chiến dịch',
  "donation campaign":'Chiến dịch quyên góp',
  "targets of the campaign":'Mục tiêu của chiến dịch',
  "impacts towards":'Các tác động hướng đến',
  "impact summary report":'Báo cáo vắn tắt tác động',
  "graph of donation / giving":'Biểu đồ phát sinh Quyến góp / Trao đi',
  "goals to be achieved":'Các mục tiêu cần phải đạt được',
  "the remaining target of the campaign":'Mục tiêu còn lại của chiến dịch',
  "original goal":'Mục tiêu ban đầu',
  "achieved":'Đã huy động được',
  "campaign needs more":'Chiến dịch cần thêm',
  "details over time":'Chi tiết phát sinh',
  "donation date":'Ngày quyên góp',
  "donor name":'Tên người đóng góp',
  "unit name":'Tên đơn vị',
  "giving date":'Ngày trao đi',
  "donation campaign report":'Báo cáo chiến dịch quyên góp',
  "this report is only for campaign admins":'Báo cáo này chỉ dành riêng cho quản trị viên của chiến dịch',
  "list of work done by volunteers":'Danh sách các công việc đã thực hiện',
  "Note: Sometimes emails will be mistakenly sent to the spam folder (Spam), so please check your spam folder as well.":'Lưu ý: Đôi khi email sẽ được chuyển nhầm vào hộp thư email rác ("Spam"), vậy nên xin bạn kiểm tra cả hộp thư rác.',
  "application file":'Hồ sơ tuyển dụng',
  "profile management":'Quản lý hồ sơ',
  "email has been successfully sent!":'Email đã được gửi thành công!',
  "Are you sure?":'Bạn muốn tiếp tục?',
  "closedgrouptips":'Nhóm đã đóng trạng thái',
  "donate blood":'Quyên góp máu',
  "register to donate blood":'Đăng ký hiến máu',
  "organizations":'Tổ chức',
  "no limit":'Không giới hạn',
  "expiration":'Hết hạn',
  "my bookmarks":'Đánh dấu',
  "suggested channels":'Gợi ý kênh',
  "shortcuts":'Lối tắt của bạn',
  "contribution":'Đóng góp của bạn',
  "grateful":'Tri ân',
  "code":'Mã',
  "you have registered to donate":'Bạn đã đăng ký quyên góp',
  "administrators panel":'Chỉ dành cho quản trị viên',
  "beneficiary information":'Thông tin thụ hưởng',
  "account number":'Số tài khoản',
  "bank name":'Tên ngân hàng',
  "transfer contents":'Nội dung chuyển khoản',
  "Successfully transferred to":'Đã chuyển thành công cho',
  "a gift":'một phần quà',
  "you register to receive gifts":'Bạn đăng ký nhận một phần quà',
  "your register":'Đăng ký của bạn',
  "requirement":'Yêu cầu',
  "time location":'Thời gian và Địa điểm',
  "the target of the activity":'Mục tiêu của hoạt động',
  "validation error":'Lỗi xác nhận',
  "advanced settings":'Cài đặt nâng cao',
  "go to setting":'Đi đến cài đặt',
  "content is filtered by":'Nội dung được lọc bởi',
  "congratulation":'Chúc mừng',
  "You have completed the steps of creating activity":'Bạn đã hoàn thành các bước tạo hoạt động',
  "Hope your activity will be successful and help many people":'Chúc hoạt động của bạn sẽ thành công và giúp đỡ được nhiều người',
  "attention":'Chú ý',
  "note create description":'Bạn cần vào phần "Cài đặt" để bổ sung thêm thông tin chi tiết. Bạn có muốn đi đến phần "Cài đặt" ngay sau đây ?',
  "note target description":'Mục tiêu chính là mục tiêu mà toàn bộ chiến dịch cần đạt được và bạn có thể chia thành những mục tiêu nhỏ khác nhau. Để làm điều đó hãy đến phần "Cài đặt" để bổ sung sau khi bạn hoàn tất các bước còn lại.',
  "open/close":'Thời hạn',
  "registrable":'Cho phép đăng ký',
  "You have completed the steps to create a campaign":'Bạn đã hoàn thành các bước tạo chiến dịch',
  "Hope your campaign will be successful and help many people":'Hy vọng chiến dịch của bạn sẽ thành công và giúp ích được nhiều người',
  "create recruitment":'Tạo tuyển dụng',
  "recruitment type":'Hình thức tuyển dụng',
  "job":'Việc làm',
  "work time":'Thời gian làm việc',
  "full time":'Toàn thời gian',
  "part time":'Bán thời gian',
  "remote":'Làm việc từ xa',
  "position_title":'Chức danh',
  "donation_event":'Quyên góp',
  "giving_event":'Trao đi',
  "recruitment_event":'Việc làm',
  "{n} categories":'{n} chuyên mục',
  "{n} languages":'{n} ngôn ngữ',
  "{n} provider(s)":'{n} tổ chức',
  "1 year":'1 năm',
  "1D":'Hàng ngày',
  "1M":'Hàng tháng',
  "1Y":'Hàng năm',
  "2 years":'2 năm',
  "2Y":'2 năm',
  "3 months":'3 tháng',
  "3M":'3 tháng',
  "6 months":'6 tháng',
  "6M":'6 tháng',
  "about FeedIn":'Về FeedIn',
  "about-us":'Về chúng tôi',
  "accept invitation":'Chấp nhận lời mời',
  "accepted as member":'được chấp nhận làm thành viên',
  "access denied":'Truy cập bị từ chối',
  "account settings":'Cài đặt tài khoản',
  "active subscription":'Gói đăng ký hoạt động',
  "add chart":'Thêm biểu đồ',
  "add image":'Thêm hình ảnh',
  "add link":'Thêm liên kết',
  "add map":'Thêm bản đồ',
  "add money to wallet":'Nạp tiền vào tài khoản',
  "Add new and manage members here {here}":'Thêm mới và quản lý thành viên tại đây {here}',
  "add new answer":'Thêm mới trả lời',
  "add new contact":'Thêm mới liên hệ',
  "add new location":'Thêm mới địa điểm',
  "add payment to keep using {subscription} or change back to free":'Thanh toán khoản phí để tiếp tục sử dụng {subscription} hoặc đổi về lại miễn phí',
  "add payment to keep using or change back to free":'Thanh toán khoản phí để tiếp tục sử dụng hoặc đổi về lại miễn phí',
  "add photo":'Thêm hình ảnh',
  "add price":'Nạp giá',
  "Add product photos":'Thêm ảnh sản phẩm',
  "add subscriber":'Thêm người đăng ký',
  "Add Tax Number":'Thêm Mã số thuế',
  "Add to Home Screen":'Thêm vào màn hình',
  "add video":'Gắn video',
  "adding content to the channel is limited due to its policies":'Thêm nội dung vào kênh bị hạn chế do chính sách của kênh',
  "additional details":'Chi tiết bổ sung',
  "admin description":'Vai trò quản trị',
  "advanced filter":'Bộ lọc nâng cao',
  "After select, click Save to finish":'Sau khi chọn, nhấn Lưu để hoàn tất',
  "after your trial ends":'Sau khi đợt dùng thử của bạn kết thúc',
  "all categories":'Tất cả danh mục',
  "all dataset":'Tất cả bộ dữ liệu',
  "all products":'Tất cả sản phẩm',
  "all vietnam":'Tất cả Việt Nam',
  "allow add new answers":'Cho phép thêm mới lựa chọn',
  "Allow channel content to be found through search or suggestion":'Nội dung kênh cho phép được tìm thấy thông qua tìm kiếm hoặc đề xuất',
  "Allow users to find your channel via search function.":'Cho phép người dùng có thể tìm thấy kênh của bạn thông qua chức năng tìm kiếm.',
  "allowed be found":'Được phép tìm thấy',
  "Alternate Email":'Địa chỉ Email (phụ)',
  "Alternate Mobile No.":'Số điện thoại (phụ)',
  "Alternate Phone Number":'Số điện thoại (phụ)',
  "alternative phone":'Số điện thoại (phụ)',
  "Annual Turnover":'Doanh thu hàng năm',
  "another":'Khác',
  "answers":'Các câu trả lời',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this":'Mọi người đều có thể xem nội dung dữ liệu. Chỉ thành viên mới có thể đăng bài viết và bình luận trên này',
  "Are you sure delete this article?":'Bạn muốn xóa bài viết này chứ?',
  "Are you sure delete this chart?":'Bạn chắc chắn muốn xóa biểu đồ này?',
  "Are you sure delete this poll?":'Bạn có chắc là muốn xóa bình chọn này?',
  "Are you sure delete this task?":'Bạn có chắc chắn xóa nhiệm vụ này?',
  "Are you sure delete this template?":'Bạn có chắc chắn xóa mẫu này không?',
  "article":'Bài viết',
  "articles":'Bài viết',
  "ask for the best deal":'Cho thỏa thuận tốt nhất',
  "automatically register with Google, Facebook... account":'Tự động đăng ký với tài khoản Google, Facebook...',
  "available balance":'Số dư khả dụng',
  "Back Home":'Trở về trang chủ',
  "back to account":'Quay về tài khoản',
  "ban":'Cấm hoạt động thành viên',
  "banned":'Bị cấm hoạt động',
  "banned. can not post any":'Cấm. Hạn chế đăng bài',
  "bans":'Cấm',
  "Basic":'Cơ bản',
  "basic info":'Thông tin cơ bản',
  "because you are looking for {keyword}":'Bạn đang tìm kiếm {keyword}?',
  "Become a provider?":'Trở thành tổ chức ?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service":'Xác minh thông tin bán hàng để {check1} Có thứ hạng cao hơn trên Feedin {check2} Tiếp cận khách hàng nhiều hơn {check3} Các dịch vụ đặc biệt khác',
  "bill estimate":'Hóa đơn kỳ tới',
  "billing":'Thanh toán',
  "billing address":'Địa chỉ thanh toán',
  "billing contact":'Liên hệ thanh toán',
  "billing details":'Chi tiết thông tin thanh toán',
  "billing history":'Lịch sử thanh toán',
  "billing information":'Thông tin thanh toán',
  "billing overview":'Tổng quan thanh toán',
  "billing period":'Kỳ thanh toán',
  "birthday":'Ngày sinh',
  "bookmark":'Đánh dấu',
  "bookmark all":'Đánh dấu tất cả',
  "bookmarked":'Đã đánh dấu',
  "brief introduction to yourself":'Giới thiệu ngắn về bản thân',
  "bulk price update":'Cập nhật giá hàng theo danh sách',
  "bulletin":'Bản tin',
  "by categories":'Theo chuyên mục',
  "by clicking Submit, you agree to the policy of FeedIn":'Bằng cách nhấp vào Gửi, bạn đồng ý với chính sách của FeedIn',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.":'Bằng cách nhấp vào \'Gửi đi\', bạn đồng ý với chính sách của FeedIn {term1} và {term2}.',
  "By clicking Subscribe, you agree to {term1} and {term2}.":'Bằng cách nhấp vào Đăng ký, bạn đồng ý với {term1} và {term2}.',
  "by making a channel private, only members will have access to this channel":'Bằng cách đặt kênh riêng tư, chỉ thành viên mới có quyền truy cập vào kênh này',
  "by using FeedIn, you agree to our":'Để sử dụng Feedin, bạn đồng ý với',
  "Call":'Gọi',
  "Call verification":'Xác minh bằng cuộc gọi',
  "catalog":'Danh mục',
  "categories affect how your story appears in public":'Thay đổi lựa chọn chuyên mục ảnh hưởng đến cách xuất hiện bài viết của bạn',
  "category groups":'Nhóm chuyên mục',
  "change address":'Thay đổi địa chỉ',
  "change display title / subtitle / display image / categories":'Thay đổi tiêu đề, chuyên mục hiển thị',
  "change following categories":'Thay đổi chuyên mục theo dõi',
  "change password":'Thay đổi mật khẩu',
  "change preview photo":'Thay đổi ảnh xem trước',
  "change role":'Chuyển đổi vai trò',
  "change subscription":'Thay đổi gói đăng ký',
  "changes here will affect how your story appears in public places like Article\'s homepage - not the story itself.":'Các thay đổi ở đây sẽ ảnh hưởng đến cách câu chuyện của bạn xuất hiện ở những nơi công cộng như trang tin - không phải nội dung bài viết.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.":'Việc thay đổi chuyên mục theo dõi được phép thực hiện liên tục trong vòng {m} giờ. Và lần thay đổi tiếp theo được thực hiện sau {d} ngày.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account\'s configuration to edit":'Thay đổi ngôn ngữ trên màn hình này sẽ thay đổi các nhãn hiển thị trên ứng dụng phù  hợp với ngôn ngữ bạn đã chọn. Để thiết lập lọc nội dung theo ngôn ngữ, vui lòng truy cập vào cấu hình của tài khoản để hiệu chỉnh',
  "channel":'Kênh tin',
  "channel name":'Tên kênh tin',
  "channel subscriptions":'Đăng ký kênh tin',
  "channels":'Kênh tin',
  "channels in category":'Kênh trong chuyên mục',
  "Channels that you may be interested in":'Các kênh mà bạn có thể quan tâm',
  "channels to follow":'Kênh giới thiệu',
  "charged fee data":'Dữ liệu có tính phí',
  "charges apply":'Có áp dụng phí',
  "chart management":'Quản lý biểu đồ',
  "charts":'Biểu đồ',
  "chat with provider":'Trao đổi cùng tổ chức',
  "check all":'Chọn tất cả',
  "check your email and click on the link":'Kiểm tra email của bạn và nhấp vào liên kết',
  "choose another FeedIn subscription":'Chọn gói FeedIn khác',
  "choose file":'Chọn file',
  "Choose language":'Chọn ngôn ngữ',
  "Choose one from your existing conversations, or start a new one.":'Chọn một cuộc trò chuyện từ các cuộc trò chuyện hiện có của bạn hoặc bắt đầu cuộc trò chuyện mới.',
  "Choose the channel for posting":'Chọn kênh để đăng bài viết',
  "Choose to follow the information catalog to get more news on your wall":'Chọn theo dõi danh mục thông tin để nhận thêm tin tức trên tường của bạn',
  "choose your default skin tone":'Chọn tông màu mặc định của bạn',
  "city":'Thành phố',
  "clear all":'Xóa bỏ tất cả',
  "clear role permissions":'Xóa bỏ phân quyền trên vai trò',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.":'Nhấp vào nút {icon} trên trang biểu đồ mà bạn muốn theo dõi, biểu đồ sẽ được cập nhật thường xuyên tại đây.',
  "click to upload":'Nhấp để tải lên',
  "code of conduct":'Quy tắc ứng xử',
  "color theme":'Màu giao diện',
  "commented your post":'đã bình luận bài viết của bạn',
  "Commodity market information":'Thông tin thị trường hàng hóa',
  "company profile":'Hồ sơ công ty',
  "confidentiality of member information":'Bảo mật thông tin các thành viên',
  "configure content by language":'Cấu hình nội dung theo ngôn ngữ',
  "Confirmation change email address has been sent to":'Xác nhận thay đổi địa chỉ email đã được gửi tới',
  "Confirmation letter will be sent to your email address":'Thư xác nhận sẽ được gửi đến địa chỉ email của bạn',
  "contact details":'Thông tin liên hệ chi tiết',
  "contact info":'Thông tin liên hệ',
  "contact messages":'Tin nhắn liên hệ',
  "contact name":'Tên người liên hệ',
  "contact now":'Liên hệ ngay',
  "contact provider":'Liên hệ tổ chức',
  "contact to member":'Liên hệ đến thành viên',
  "Contact us about your bill":'Liên hệ với chúng tôi về hóa đơn của bạn',
  "contact with provider":'Liên hệ đến tổ chức',
  "contact-info":'Thông tin liên hệ',
  "contacts":'Liên hệ',
  "content allowed to be found":'Nội dung cho phép được tìm thấy',
  "content displayed by language":'Nội dung hiển thị theo ngôn ngữ',
  "content is for members only":'Nội dung chỉ dành cho thành viên',
  "Content language":'Ngôn ngữ nội dung',
  "Continue to {provider}":'Tiếp tục với {provider}',
  "Continue to PayPal":'Tiếp tục với PayPal',
  "conversations":'Hội thoại',
  "cookies-policy":'Chính sách Cookies',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel":'Sao chép, chia sẻ liên kết trang bên dưới đến bạn bè. Để mọi người bạn cần chia sẻ dễ dàng truy cập và theo dõi kênh này',
  "copyright":'Bản quyền',
  "country":'Quốc gia',
  "cover":'Phong nền',
  "create a free channel":'Tạo miễn phí kênh thông tin',
  "Create a new chart for dataset":'Tạo mới biểu đồ cho bộ dữ liệu',
  "Create a new provider so you can post products or manage your information channels.":'Tạo một tổ chức mới để bạn có thể đăng sản phẩm hoặc quản lý các kênh thông tin của mình.',
  "create a poll":'Tạo bình chọn',
  "create category":'Tạo chuyên mục',
  "create channel":'Tạo kênh',
  "create chart":'Tạo biểu đồ',
  "create dataset":'Tạo Bộ dữ liệu',
  "Create new channel":'Tạo kênh mới',
  "create new group":'Tạo nhóm mới',
  "create one":'Tạo một cái mới',
  "create product":'Tạo sản phẩm',
  "create role":'Tạo vai trò',
  "Create provider profile":'Tạo hồ sơ tổ chức',
  "credit/debit card":'Credit/Debit Card',
  "custom":'Tùy chỉnh',
  "custom url":'Tùy chỉnh tên miền',
  "daily":'Hàng ngày',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.":'Cập nhật thông tin, giá thị trường Hàng hóa, Nông nghiệp, Tài chính mới nhất hàng ngày tại FeedIn.',
  "dark mode":'Chế độ tối',
  "dark skin tone":'Tông màu tối',
  "data":'Dữ liệu',
  "data & charts":'Dữ liệu & Biểu đồ',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this":'Mọi người đều có thể xem nội dung dữ liệu, mọi người có thể tự do tham gia và có thể đăng bài viết và bình luận trên này',
  "data explorer":'Khám phá dữ liệu',
  "data frequency":'Tần xuất dữ liệu',
  "data in category":'Dữ liệu trong chuyên mục',
  "data security":'Bảo vệ dữ liệu',
  "Data set you might be interested in":'Tập dữ liệu bạn có thể quan tâm',
  "dataset":'Dữ liệu',
  "dataset list":'DS dữ liệu',
  "dataset management":'Quản lý bộ dữ liệu',
  "dataset subscriptions":'Đăng ký dữ liệu',
  "deactive":'Ngừng hoạt động',
  "Declare the location for your provider":'Khai báo địa điểm cho tổ chức',
  "default skin tone":'Tông màu mặc định',
  "delete channel":'Xóa bỏ kênh',
  "delete data":'Xóa dữ liệu',
  "delete dataset":'Xóa dữ liệu',
  "delete provider":'Xóa tổ chức',
  "Deleted article are gone forever. Are you sure?":'Bài viết đã bỏ sẽ mất vĩnh viễn. Bạn chắc chắn đi tiếp?',
  "Deleted channel are gone forever. Are you sure?":'Kênh được xóa sẽ mất dữ liệu mãi mãi. Bạn có chắc chắn muốn xóa?',
  "Deleted dataset are gone forever. Are you sure?":'Bộ dữ liệu muốn xóa sẽ biến mất vĩnh viễn. Bạn có chắc chắn?',
  "Deleted provider are gone forever. Are you sure?":'Đơn vị tổ chức bị xóa sẽ biến mất mãi mãi. Bạn có chắc không?',
  "delivery frequency":'Tần xuất cấp dữ liệu',
  "delivery_time":'Thời gian giao hàng',
  "designed and provided by":'Được thiết kế và cung cấp bởi',
  "desktop app":'Desktop App',
  "discovery more providers":'Tìm kiếm các tổ chức',
  "do not show members information for each other members":'Không hiển thị thông tin về các thành viên cho từng thành viên khác',
  "do you want cancel the subscription?":'Bạn có muốn hủy đăng ký?',
  "do you want to cancel?":'Bạn muốn tiếp tục hủy?',
  "Do you want to deactivate this product?":'Bạn có muốn hủy kích hoạt sản phẩm này không?',
  "do you want to logout?":'Bạn muốn tiếp tục đăng xuất?',
  "document (PDF)":'tài liệu (PDF)',
  "documentation":'Tài liệu',
  "Doesn\'t have any bookmarked item":'Không có bất kỳ mục nào được đánh dấu',
  "downgrade":'Hạ cấp',
  "downgrade subscription":'Hạ cấp gói đăng ký',
  "downgrade your subscription to":'Hạ cấp đăng ký của bạn xuống',
  "download":'Tải về',
  "draft products":'Sản phẩm đang khai báo',
  "edit message":'Chỉnh sửa tin nhắn',
  "edit name":'Sửa tên',
  "edit your post":'Chỉnh sửa bài viết',
  "editor choices":'Chọn hiển thị bởi biên tập',
  "email (example@company.com)":'Thư điện tử (example@company.com)',
  "email account":'Email đăng nhập',
  "Emoji categories":'Emoji categories',
  "en":'English',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.":'Khuyến khích các sáng kiến kinh doanh tạo tác động xã hội. Hướng đến xây dựng một môi trường sống bền vững cho các thế hệ hôm nay và mai sau.',
  "enter an url":'Nhập vào đường dẫn liên kết',
  "enter channel name":'Nhập vào tên của kênh',
  "Enter Dataset Name":'Nhập tên dữ liệu',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters":'Nhập nội dung tin nhắn yêu cầu tối thiểu 20, lớn nhất 4000 ký tự',
  "Enter products / services search":'Nhập sản phẩm / dịch vụ tìm kiếm',
  "Enter some information so the provider knows your requirements":'Nhập một số thông tin để tổ chức biết yêu cầu của bạn',
  "enter provider name":'Nhập tên tổ chức',
  "enter your email":'Nhập vào thư điện tử',
  "enter your keyword":'Nhập từ khóa tìm kiếm',
  "expires":'Hết hạn',
  "Explore related products from verified Companies":'Sản phẩm liên quan từ các tổ chức',
  "Explore similar products":'Các sản phẩm tương tự',
  "extra request":'Yêu cầu bổ sung',
  "failure purchase!":'Mua hàng thất bại!',
  "FAQ":'Các câu hỏi thường gặp',
  "favorites categories":'Danh mục ưa thích',
  "Featured Categories":'Mục nổi bật',
  "featured charts":'Biểu đồ tiêu biểu',
  "featured topics":'Chủ đề nổi bật',
  "fee management":'Quản lý phí',
  "fee settings":'Cấu hình sử dụng phí',
  "feed":'Feed',
  "FeedIn gives you the latest information on market data.":'FeedIn cung cấp cho bạn thông tin mới nhất về dữ liệu thị trường.',
  "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below":'FeedIn đã nhóm thông tin theo danh mục hàng hóa để giúp bạn truy cập thông tin tốt hơn và tránh bỏ sót thông tin. Vui lòng chọn các danh mục bạn thích dưới đây',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.":'FeedIn là nền tảng kết nối người mua với tổ chức, tạo mạng lưới chia sẻ thông tin hàng đầu về nông nghiệp và thị trường hàng hóa.',
  "FeedIn Member Since":'Thành viên của FeedIn từ',
  "FeedIn will update the latest and fastest information and data about the goods market to you":'FeedIn sẽ cập nhật thông tin và dữ liệu mới nhất và nhanh nhất về thị trường hàng hóa cho bạn',
  "file upload failed":'Tập tin tải lên bị lổi',
  "file uploaded successfully":'Tập tin đã tải lên thành công',
  "filter":'Lọc tìm kiếm',
  "Filter results":'Lọc kết quả',
  "first subscription on":'Đăng ký lần đầu ngày',
  "Flags":'Quốc kỳ',
  "folders":'Thư mục',
  "follow more categories":'Theo dõi thêm các chuyên mục',
  "Follow to get notifications from this channel!":'Theo dõi để nhận thông báo từ kênh này!',
  "followers":'Người theo dõi',
  "following categories":'Chuyên mục đang theo dõi',
  "following channels":'Kênh theo dõi',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.":'Đối với người mua, nhà đầu tư và những người quan tâm. Chỉ cần chọn lĩnh vực quan tâm, bạn được đề nghị kết nối với các chuyên gia và tin tức mới nhất của thị trường.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us":'Cho yêu cầu gửi báo giá sản phẩm, gửi thông tin về sản phẩm, hay liên hệ hợp tác, vui lòng liên hệ với chúng tôi',
  "for you":'Dành cho bạn',
  "free":'Miễn phí',
  "frequently questions":'Câu hỏi thường gặp',
  "Frequently Used":'Thường xuyên sử dụng',
  "frequently-questions":'Câu hỏi thường gặp',
  "From - To":'Từ - Đến',
  "from the sender":'Bài viết cùng người gửi',
  "general permission":'Phân quyền chung',
  "general settings":'Cài đặt chung',
  "Get a quote":'Nhận báo giá',
  "Get Best Price":'Nhận giá tốt nhất',
  "Get in touch with us":'Liên hệ với chúng tôi',
  "Get latest price":'Nhận giá mới nhất',
  "Get Quotes":'Nhận báo giá',
  "Get quotes from seller":'Nhận báo giá từ người bán',
  "get started with {name}":'Bắt đầu với {name}',
  "Get your business details verified with us for higher rankings in FeedIn!":'Xác minh chi tiết doanh nghiệp của bạn với chúng tôi để có thứ hạng cao hơn trong FeedIn!',
  "go":'Bắt đầu',
  "go modal":'Mở khung hình',
  "haven\'t comments yet":'Chưa có bình luận nào',
  "head office":'Văn phòng chính',
  "hello":'Xin chào',
  "Hello you":'Chào bạn',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.":'Giúp mọi người cập nhật thông tin thị trường hàng hóa, nông nghiệp mới nhất, đa chiều, bảo mật và hỗ trợ gói dịch vụ theo nhu cầu.',
  "Here is a list of all invite links. You can revoke any one.":'Dưới đây là danh sách tất cả các liên kết mời. Bạn có thể thu hồi bất kỳ.',
  "hide interaction between members":'Ẩn hiển thị tương tác giữa các thành viên',
  "Hide interactions between members. But, retaining interaction between members and administrators":'Ẩn các tương tác giữa các thành viên. Nhưng, duy trì sự tương tác giữa các thành viên và quản trị viên',
  "If you need assistance, please feel free to contact us via":'Nếu bạn cần hỗ trợ, xin vui lòng liên hệ với chúng tôi qua',
  "If you want to switch to annual payment, {contactus}":'Nếu bạn muốn chuyển sang thanh toán hàng năm, {contactus}',
  "image":'Hình ảnh',
  "image must smaller than":'Kích thước ảnh phải nhỏ hơn',
  "incoming requests":'Yêu cầu gửi đến',
  "index type":'Loại chỉ số',
  "index types":'Loại dữ liệu',
  "info chart editing":'Chỉnh sửa thông tin biểu đồ',
  "interested":'Quan tâm',
  "Interface language":'Ngôn ngữ giao diện',
  "introduce":'Giới thiệu',
  "invalid template":'biểu mẫu không hợp lệ',
  "invite and share":'Mời và chia sẻ',
  "invite code":'Mã mời người dùng',
  "invite members":'Mời thành viên',
  "invite people":'Mời mọi người',
  "invite people to join this channel":'Mời mọi người tham gia kênh',
  "invite you to join":'gửi mời bạn tham gia',
  "Invite your friends to this page":'Mời bạn tham gia trang này',
  "invited":'Đã mời',
  "invited to become member":'Được mời làm thành viên',
  "inviter":'Người mời',
  "invites":'Mời tham gia',
  "is served by clicking on a":'được phục vụ bằng cách nhấp vào',
  "item not found":'Không tìm thấy nội dung',
  "join channel":'Tham gia vào kênh',
  "Join for free now":'Tham gia miễn phí ngay',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel\'s latest news to you":'Tham gia kênh để chia sẻ bài viết, bình luận hoặc nhấp để theo dõi để hệ thống sẽ tự động cập nhật tin tức mới nhất của kênh cho bạn',
  "Join this channel to start chatting":'Tham gia kênh để được phép đăng bài',
  "keep subscription":'Giữ lại đăng ký',
  "keywords":'Từ khóa',
  "kick":'Loại bỏ thành viên',
  "kicked":'Đã bỏ khỏi thành viên',
  "last updated":'Lần cập nhật cuối',
  "latest":'Mới nhất',
  "latest updates":'Cập nhật mới',
  "Latest updates on the commodity market":'Cập nhật mới nhất về thị trường hàng hóa',
  "leave":'Rời đi',
  "leave a message":'Để lại lời nhắn',
  "Leave a Message, we will contact you back!":'Để lại tin nhắn, chúng tôi sẽ liên hệ lại với bạn!',
  "leave channel":'Rời khỏi kênh',
  "Legal Status of Firm":'Loại hình công ty',
  "license":'Cấp phép',
  "licenses":'Cấp phép',
  "light skin tone":'Tong màu sáng',
  "link":'Liên kết',
  "Link original post":'Link bài viết gốc',
  "list of data":'Danh sách bộ dữ liệu',
  "listed products":'Sản phẩm đang hoạt động',
  "unlisted products":'Sản phẩm chưa hoạt động',
  "Location (Province/district/ward)":'Địa điểm (Tỉnh (Thành) / Quận (Huyện)/ Phường (Xã))',
  "location & contact":'Địa điểm & Liên hệ',
  "location name":'Tên địa danh',
  "Login now":'Đăng nhập ngay',
  "Login now to select and follow the content that you interested in":'Đăng nhập ngay để lựa chọn và theo dõi mục bạn quan tâm',
  "login with account":'Đăng nhập với tài khoản',
  "login with Facebook":'Đăng nhập với Facebook',
  "login with Google":'Đăng nhập với Google',
  "Make your channel get more exciting activities by inviting other members":'Hãy làm cho trang kênh của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "Make your provider get more exciting activities by inviting other members":'Hãy làm cho trang tổ chức của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "manage":'Quản lý',
  "manage dataset":'Quản lý Bộ dữ liệu',
  "manage products":'Quản lý sản phẩm',
  "manage services":'Quản lý dịch vụ',
  "ManageChannelSubscriptions":'Quản lý đăng ký kênh',
  "managed channels":'Kênh quản trị',
  "mark as read":'Đánh dấu đã xem',
  "market":'Thị trường',
  "market summary":'Tóm tắt thị trường',
  "mart":'Cửa hàng',
  "me to the channel":'Đến với kênh thông tin',
  "medium skin tone":'Tông màu trung bình',
  "medium-dark skin tone":'Tông màu trung bình - tối',
  "medium-light skin tone":'Tông màu trung bình - sáng',
  "member description":'Mô tả thành viên',
  "member management":'Quản lý thành viên',
  "members list":'DS thành viên',
  "mention":'nhắc đến',
  "menu":'Danh mục',
  "Message":'Tin nhắn',
  "messages":'Tin nhắn',
  "min_order_qtty":'Đặt hàng tối thiểu',
  "Minimum payment required":'Yêu cầu thanh toán tối thiểu',
  "miss":'Chị.',
  "modal":'Khung hình',
  "monthly":'Hàng tháng',
  "More data from":'Xem thêm dữ liệu từ',
  "More like This":'Xem thêm',
  "mr":'Ông.',
  "mrs":'Bà.',
  "ms":'Cô.',
  "my channels":'Kênh của tôi',
  "my page":'Trang cá nhân',
  "my post":'Bài của tôi',
  "name of chart":'Tên của biểu đồ',
  "name of your company":'Tên công ty của bạn',
  "Nature of Business":'Lĩnh vực kinh doanh',
  "new answer":'Trả lời mới',
  "New contact":'Thông tin liên hệ mới',
  "new folder":'Thư mục mới',
  "new group":'Tạo nhóm mới',
  "New template":'Biểu mẫu mới',
  "newest data":'Dữ liệu mới nhất',
  "news page":'Trang tin tức',
  "next change":'Thay đổi lần tiếp theo',
  "No categories have been selected":'Không có chuyên mục được chọn',
  "no categorized":'Chưa phân mục',
  "No channel being followed":'0 kênh đang được theo dõi',
  "no channels":'Chưa có kênh thông tin',
  "No Data":'Không có dữ liệu',
  "No data available at this time.":'Không có dữ liệu vào lúc này.',
  "no data found":'Không tìm thấy dữ liệu',
  "No data set are being followed":'0 tập dữ liệu đang được theo dõi',
  "no dataset":'Chưa có bộ dữ liệu',
  "no description":'Không có mô tả',
  "No Emoji Found":'Không tìm thấy Emoji',
  "No filter available":'Không có lọc dữ liệu',
  "no invites yet":'Hiện chưa có lời mời nào',
  "No news":'Chưa có tin tức',
  "no office found":'Không tìm thấy địa điểm',
  "No posts were found":'Không bài viết nào được tìm thấy',
  "no results found":'Không tìm thấy kết quả',
  "not a member yet?":'Bạn chưa phải là thành viên?',
  "notes about chart!":'Ghi chú cho biểu đồ',
  "notification":'Thông báo',
  "Number of Employees":'Số lượng thành viên',
  "Number of your channels:":'Số kênh hiện tại của bạn:',
  "Objects":'Đối tượng',
  "offices":'Văn phòng',
  "OK":'OK',
  "oldest data":'Dữ liệu xa nhất',
  "One more step to become a Verified Seller":'Hoàn tất khai báo thông tin bán hàng',
  "Only members can view data content, post articles or comment on this":'Chỉ thành viên mới có thể xem nội dung dữ liệu, đăng bài viết hoặc bình luận trên này',
  "oops...":'Rất tiếc...',
  "opps, no channels available":'Rất tiếc, chưa có kênh tin tức',
  "or continue with":'hoặc tiếp tục với',
  "other categories":'Chuyên mục khác',
  "other channels":'Kênh khác',
  "other charts":'Biểu đồ khác',
  "otherSettings":'Cài đặt khác',
  "Our Channels":'Kênh thông tin',
  "Our data":'Dữ liệu',
  "Our mission is to:":'Sứ mệnh của chúng tôi hướng đến:',
  "Our Videos":'Video về chúng tôi',
  "overview":'Tổng quan',
  "owner description":'Vai trò chủ quản',
  "package in use":'Gói đang dùng',
  "packaging_details":'Chi tiết đóng gói',
  "paid channel":'Kênh có thu phí',
  "partner":'Đối tác',
  "Password and confirm password does not match":'Mật khẩu xác nhận lại không trùng khớp',
  "Password must have min 6 characters":'Mật khẩu phải tồn tại ít nhất 6 ký tự',
  "Passwords must match":'Mật khẩu nhập phải trùng khớp',
  "paste your link here":'Dán liên két của bạn vào đây',
  "pay":'Thanh toán',
  "Pay and subscribe":'Trả và đăng ký',
  "payment detail":'Chi tiết thanh toán',
  "payment subscription":'Thanh toán gói đăng ký',
  "Payment via {provider}":'Thanh toán qua {provider}',
  "Payment via Paypal":'Thanh toán qua Paypal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay":'Thanh toán sẽ được tiếp tục qua {provider}, Vui lòng xác nhận lại số tiền để tiếp tục',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay":'Thanh toán sẽ được chuyến đến tiếp tục trên Paypal. Vui lòng xác nhận số tiền cần thanh toán',
  "paypal":'PayPal',
  "pending approval":'Đang chờ duyệt',
  "people to follow":'Bạn bè giới thiệu',
  "Permanent link":'Địa chỉ vĩnh viễn',
  "permission denied":'Quyền truy cập bị từ chối',
  "permissions":'Phân quyền',
  "personal":'Cá nhân',
  "personal channels":'Kênh cá nhân',
  "personal profile":'Hồ sơ cá nhân',
  "Phone Number":'Số điện thoại',
  "Phone number how we can contact you":'Số điện thoại cách chúng tôi có thể liên hệ với bạn',
  "Pictures on the home page":'Hình ảnh đặt trên trang chủ',
  "pin":'Ghim',
  "Pin code":'Mã PIN',
  "pinned":'Đã ghim',
  "pinned posts":'Bài viết đã ghim',
  "pinnedPosts":'Bài viết đã ghim',
  "Please check the information and follow the steps to subscribe.":'Vui lòng kiểm tra thông tin và làm theo các bước để đăng ký.',
  "Please choose to follow related channels for the system to filter the updates to you":'Vui lòng chọn theo dõi các kênh liên quan để hệ thống lọc các thông tin cập nhật đến bạn',
  "Please choose to save the interest charts so you can view them easily each day":'Vui lòng chọn lưu các biểu đồ quan tâm để bạn có thể xem các biểu đồ này một cách dễ dàng mỗi ngày',
  "please enter name of product":'Vui lòng nhập tên sản phẩm',
  "please input your email":'Vui lòng nhập địa chỉ thư điện tử của bạn',
  "please input your phone number":'Vui lòng nhập số điện thoại của bạn',
  "Please log in to FeedIn to leave your comment in the post":'Vui lòng đăng nhập vào FeedIn để để lại bình luận của bạn trong bài viết',
  "Please login to use the message function in the system.":'Vui lòng đăng nhập để sử dụng chức năng nhắn tin trong hệ thống.',
  "Please register to view this content":'Vui lòng đăng ký để xem nội dung này',
  "please select":'Vui lòng chọn',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information":'Vui lòng chọn ít nhất 1 chủ đề để hoàn tất. FeedIn sẽ giúp bạn tìm thấy thông tin phù hợp',
  "Please select the unit you want to choose as the default":'Vui lòng nhấp chọn một công ty mà bạn muốn chọn làm mặc định',
  "policy":'Chính sách',
  "poll":'Thăm dò ý kiến',
  "popular":'Phổ biến',
  "Post something":'Viết điều gì đó',
  "post your images":'Đăng ảnh của bạn',
  "postal code":'Mã bưu điện',
  "posted by":'Được đăng bởi',
  "posts":'Bài viết',
  "Premium":'Cao cấp',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.":'Kênh cao cấp, khi được bật, cho phép người dùng tham gia kênh có tính phí. Chủ sở hữu của kênh sẽ nhận được doanh thu từ các thành viên.',
  "premium providers":'Các tổ chức',
  "press enter to search":'Nhấn ENTER để tìm kiếm',
  "preview description":'Mô tả hiển thị',
  "preview title":'Tựa đề hiển thị',
  "pricing":'Định giá',
  "Primary E-mail":'Email (chính)',
  "Primary Mobile No.":'Số ĐT (chính)',
  "privacy settings":'Cấu hình chính sách riêng tư',
  "privacy-policy":'Chính sách riêng tư',
  "private channel":'Kênh riêng tư',
  "pro":'Gói nâng cao',
  "product catalogs":'Nhóm hiển thị sản phẩm',
  "product certificates":'Chứng nhận sản phẩm',
  "product details":'Chi tiết sản phẩm',
  "product images":'Hình ảnh sản phẩm',
  "product must have at least five character":'tên sản phẩm phải có ít nhất 5 ký tự',
  "product must have at least one image":'sản phẩm phải có ít nhất một hình ảnh',
  "product settings":'Quản lý sản phẩm',
  "product specifications":'Đặc tính sản phẩm',
  "Product/Service Name":'Sản phẩm / Tên dịch vụ',
  "production_capacity":'Khả năng sản xuất',
  "products list":'DS sản phẩm',
  "products page":'Trang sản phẩm',
  "Professional network in the industry":'Mạng lưới chuyên môn trong ngành nghề',
  "profile settings":'Quản lý tài khoản',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.":'Thúc đẩy mọi người chia sẻ kiến thức, kinh nghiệm, cùng nhau học hỏi, phát triển thịnh vượng.',
  "provided services":'Các dịch vụ cung cấp',
  "Province/City":'Tỉnh/Thành',
  "Province/district/ward":'Tỉnh (Thành) / Quận (Huyện) / Phường (Xã)',
  "provinces":'Tỉnh / Thành',
  "public timeline":'Dòng thời gian công khai',
  "publish":'Xuất bản',
  "published by":'Xuất bản bởi',
  "publisher":'Xuất bản',
  "quick link":'Liên kết nhanh',
  "read":'đọc bài',
  "read more":'Đọc thêm',
  "read news":'Xem tin',
  "ready to publish?":'Sẵn sàng xuất bản',
  "rebookmark":'Đánh dấu lại',
  "recent news":'Tin gần đây',
  "recently news":'Tin mới',
  "Recommendations":'Khuyến nghị',
  "refreshed":'Cập nhật lần cuối',
  "region":'Vùng / Miền',
  "regions":'Vùng / Miền',
  "registered":'Đã đăng ký',
  "Registering to use advanced data, you will have to pay":'Đăng ký sử dụng dữ liệu nâng cao, bạn sẽ phải trả',
  "related articles":'Bài viết cùng thể loại',
  "related categories":'Chuyên mục liên quan',
  "related channels":'Kênh liên quan',
  "related charts":'Biểu đồ liên quan',
  "related keywords":'Từ khóa liên quan',
  "Related to items you\'ve viewed":'Liên quan đến sản phẩm trước bạn đã xem',
  "relevant":'Có liên quan',
  "remove all":'Xóa tất cả',
  "renew / expires on":'Gia hạn / Hết hạn ngày',
  "replied":'Đã phản hồi',
  "Request a call":'Yêu cầu cuộc gọi',
  "Request a quote":'Yêu cầu báo giá',
  "request have been sent successfully!":'Yêu cầu đã được gửi đi thành công!',
  "request to join":'Gửi yêu cầu tham gia',
  "request-to-join":'Gửi yêu cầu tham gia',
  "requested to join":'Gửi yêu cầu tham gia',
  "required field":'Phần bắt buộc nhập',
  "Requirement Details":'Chi tiết về yêu cầu',
  "resend":'Gửi lại',
  "response rate":'Tỷ lệ phản hồi',
  "responses":'Phản hồi',
  "restricted":'Hạn chế',
  "results":'Kết quả',
  "return home":'Quay về trang chủ',
  "return owner page":'Trờ về trang chủ quản',
  "return user page":'Trở về trang người dùng',
  "Review":'Xem lại',
  "role name":'Tên vai trò',
  "role title":'Chức danh / Vai trò',
  "roles":'Vai trò',
  "sales information":'Thông tin bán hàng',
  "save & complete":'Lưu & Hoàn tất',
  "Save and continue":'Lưu và tiếp tục',
  "schedule to cancel":'Đang trong lịch hủy bỏ',
  "search anythings in FeedIn":'Tìm kiếm trong Feedin',
  "Search by Name":'Tìm kiếm theo tên',
  "Search Results":'Kết quả tìm kiếm',
  "secret channel":'Kênh riêng tư',
  "section":'Phân khúc',
  "See all charts in the data":'Xem tất cả biểu đồ trong dữ liệu',
  "select / create a group":'Chọn / Tạo 1 nhóm',
  "Select a content category or follow the authors to get relevant information":'Chọn chuyên mục nội dung hoặc theo dõi các tác giả để có được thông tin liên quan',
  "select category":'Chọn chuyên mục',
  "select preview photo":'Chọn hình xem đại diện',
  "Select the display order in the category":'Chọn thứ tự hiển thị trong danh mục',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.":'Chọn loại nội dung bạn muốn xem. Và FeedIn sẽ thu thập và hiển thị tin tức phù hợp với sở thích của bạn.',
  "select your country":'Chọn quốc gia của bạn',
  "selected":'Đã chọn',
  "seller":'Người bán',
  "Seller Contact Details":'Thông tin liên hệ người bán',
  "seller name":'Tên người bán',
  "send":'Gửi',
  "Send Email":'Gửi thư',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below":'Gửi lời mời tham gia kênh đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below":'Gửi lời mời tham gia là thành viên tổ chức đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới',
  "send request":'Gửi yêu cầu',
  "Send your message to this provider":'Gửi tin nhắn của bạn đến tổ chức này',
  "sending":'Đang gửi',
  "sent a request to become member":'Đã gửi một yêu cầu để làm thành viên',
  "services":'Dịch vụ',
  "set main":'Thiết lập làm chính',
  "set up content selection by language":'Thiết lập chọn nội dung theo ngôn ngữ',
  "share a post":'Chia sẻ bài viết',
  "share button":'Nút Chia sẻ',
  "share your ideas":'Chia sẻ ý tưởng của bạn',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.":'Chia sẻ thông tin và kiến ​​thức của bạn để tăng cường sự hiện diện của bạn, xây dựng thương hiệu, kết bạn với các đối tác tiềm năng hoặc cung cấp thông tin có tính phí.',
  "Sharing the information":'Chia sẻ thông tin',
  "short description":'Mô tả ngắn',
  "Short description about chart!":'Mô tả ngắn về biểu đồ',
  "Short description about your company!":'Mô tả ngắn về công ty của bạn!',
  "Short description about your dataset!":'Mô tả ngắn về bộ dữ liệu',
  "show in category":'Hiển thị trong nhóm mục',
  "show more":'Hiển thị thêm',
  "show this post":'Xem bài viết',
  "sign in with":'Đăng nhập với',
  "signin / signup":'Đăng nhập / Đăng ký',
  "site":'Địa chỉ trang web',
  "sku":'Mã SP (SKU)',
  "Smileys & People":'Smileys & People',
  "some messages...some messages...":'some messages...some messages...',
  "Sorry, the page you visited does not exist.":'Rất tiếc, trang bạn truy cập không tồn tại.',
  "Sorry, the page you visited has expired or does not exist.":'Xin lỗi, trang bạn truy cập đã hết hạn sử dụng hoặc không tồn tại.',
  "source":'Nguồn',
  "Specification Details":'Thông số',
  "start free trial":'Bắt đầu dùng thử miễn phí',
  "start trial":'Bắt đầu dùng thử',
  "start your no-cost {subscription} trial today":'Bắt đầu dùng thử {subscription} miễn phí ngay hôm nay',
  "start your no-cost trial today":'Bắt đầu dùng thử miễn phí ngay hôm nay',
  "state":'Bang',
  "stay connected":'Giữ kết nối',
  "Submit requirement":'Gửi yêu cầu',
  "subscribe channel":'Đăng ký kênh',
  "Subscribe to the categories to display the charts you\'re interested in":'Đăng ký theo dõi các danh mục để hiển thị các biểu đồ mà bạn quan tâm đến',
  "Subscribe to the channels for information displayed on this page":'Đăng ký các kênh để biết thông tin hiển thị trên trang này',
  "subscribed data":'Dữ liệu đã đăng ký',
  "subscribers":'Người đăng ký',
  "subscribing this channel will cost you":'Đăng ký kênh này sẽ khiến bạn mất phí',
  "subscriptions":'Đăng ký',
  "successfully purchase!":'Mua thành công!',
  "suggested products":'Sản phẩm đề xuất',
  "summary":'Tóm lược',
  "Summary of the latest news today":'Tóm tắt những tin tức mới nhất hôm nay',
  "supplied by":'Được cung cấp bởi',
  "provider":'Tổ chức',
  "provider address":'Địa chỉ tổ chức',
  "provider details":'Chi tiết về tổ chức',
  "provider homepage":'Trang chủ tổ chức',
  "provider name":'Tên tổ chức',
  "provider profile":'Hồ sơ tổ chức',
  "provider profile was created successful":'Hồ sơ tổ chức đã được tạo thành công',
  "provider profiles":'Hồ sơ tổ chức',
  "provider\'s channels":'Kênh của tổ chức',
  "providers":'Tổ chức',
  "Supporting brand promotion and market information to enhance business and networking opportunities.":'Hỗ trợ quảng bá thương hiệu, thông tin thị trường nhằm tăng cường cơ hội kết nối, giao thương.',
  "Symbols":'Symbols',
  "table of contents":'Mục lục',
  "Tap":'Nhấp',
  "tax":'Thuế',
  "tax code":'Mã số thuế',
  "Tax ID":'Mã số thuế',
  "tax inclusive":'Đã bao gồm thuế',
  "tax number":'Mã Số Thuế',
  "Tell us what you need":'Nói với chúng tôi điều bạn cần tìm kiếm',
  "template":'biểu mẫu',
  "terms of service":'Điều khoản dịch vụ',
  "terms-of-service":'Điều khoản dịch vụ',
  "the {package} subscription will be continue effect until {date}":'Đăng ký {package} sẽ tiếp tục có hiệu lực cho đến {date}',
  "The banner image will be placed on the company\'s homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.":'Hình ảnh banner sẽ được đặt trên trang chủ của công ty. Xem tốt nhất với độ phân giải 256 x 1280 pixels. Loại ảnh: PNG, JPG.',
  "the channel has been deleted":'Kênh đã bị xóa',
  "the channel so that the system will automatically help you update with the latest news":'kênh để hệ thống sẽ tự động giúp bạn cập nhật những tin tức mới nhất',
  "The chart is enabled and ready to be displayed to the user":'Biểu đồ được kích hoạt và sẵn sàng để hiển thị cho người dùng',
  "The chart marked as typical and used to represent the data set":'Biểu đồ được đánh dấu là điển hình và được sử dụng để làm đại diện cho tập dữ liệu',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content":'Các nội dung (hình ảnh, bài viết...) được chia sẻ trong kênh, chỉ thành viên trong kênh nhìn thấy, cùng thảo luận. Người dùng khác không thể xem, phản hồi nội dung này',
  "the information that interests you":'Thông tin mà bạn quan tâm',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other\'s information.":'Các tương tác giữa chính thành viên đó với quản trị viên kênh của từng thành viên một sẽ bảo mật tuyệt đối. Chỉ quản trị viên hoặc riêng cá nhân trong kênh mới nhìn thấy thông tin của nhau.',
  "the latest news":'Tin tức mới nhất',
  "The members shown here include: Owner, administrator, member as contact person.":'Các thành viên hiển thị ở đây bao gồm: Chủ sở hữu, quản trị viên, thành viên là người liên hệ.',
  "The page content is not yet available or being written":'Nội dung trang chưa có sẵn hoặc đang được viết',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.":'Trang bạn đang tìm kiếm không tồn tại. Nhấp vào nút bên dưới để quay lại trang chủ.',
  "the restore email has been sent to":'Email khôi phục đã được gửi đến',
  "The state of the data set. If \"On\", the dataset is ready to use":'Trạng thái của bộ dữ liệu. Nếu "Bật", bộ dữ liệu sẵn sàng sử dụng',
  "the provider has been deleted":'Đơn vị tổ chức đã được xóa',
  "the providers i am involved in":'Đơn vị tổ chức mà tôi tham gia',
  "The tax code value has been saved successfully":'Mã số thuế đã được lưu thành công',
  "The verification call is pending":'Cuộc gọi xác minh đang chờ xử lý',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account":'Tài khoản này chưa đăng ký địa chỉ mail để xác thực, vui lòng đăng ký địa chỉ email để xác thực tính sở hữu của tài khoản',
  "This channel content is limited to members only, join now to view this channel content.":'Nội dung kênh này chỉ giới hạn cho thành viên, hãy tham gia ngay để xem nội dung kênh này.',
  "This channel doesn\'t have any pinned post":'Kênh này không có bài đăng nào được ghim',
  "this channel supports communication in the form of hidden interactions between members":'Kênh này hỗ trợ giao tiếp dưới dạng tương tác ẩn giữa các thành viên',
  "this channel to your friends":'kênh này cho bạn bè của bạn',
  "This channel will charge you":'Kênh sẽ thu phí từ bạn',
  "This is a description.":'Đây là đoạn mô tả',
  "this is a regular channel":'Đây là kênh thông thường',
  "This is an error message":'Có 1 một thông báo lỗi',
  "This product will be removed from your Catalog and won\'t be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?":'Sản phẩm này sẽ bị xóa khỏi Danh mục của bạn và sẽ không hiển thị với người mua. Bạn cũng sẽ dần dần ngừng nhận BuyLeads cho sản phẩm này. Bạn có chắc chắn muốn tiếp tục không?',
  "Time for updating the tracking list:":'Thời gian cho phép cập nhật chuyên mục theo dõi:',
  "timeline is only viewable with accounts that are members of this channel":'Dòng thời gian chỉ có thể xem được với các tài khoản là thành viên của kênh này',
  "timeline is viewed internally only":'Dòng thời gian chỉ được xem nội bộ',
  "title or question":'Câu hỏi',
  "to channel":'Đến kênh',
  "to create new channel on your company":'để tạo kênh mới về công ty của bạn',
  "to create your new channel":'để tạo mới kênh của bạn',
  "To edit your details, please visit {profile}":'Để chỉnh sửa chi tiết, vui lòng truy cập {profile}',
  "to find content that interests you":'để tìm nội dung mà bạn quan tâm',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member":'Để tham gia vào kênh và theo dõi nội dung thường xuyên, bạn phải Đăng nhập / Đăng ký mới (miễn phí) tài khoản để trở thành thành viên',
  "To make changes to your subscriptions , go to":'Để thay đổi đăng ký của bạn, hãy đi đến',
  "To manage products visit at {manage_products} or continue with {call_verification}":'Để quản lý sản phẩm, hãy truy cập tại {management_products} hoặc tiếp tục với {call_verification}',
  "To stay on your current plan after your trial ends, add payment now.":'Để giữ đăng ký của bạn sau khi đợt dùng thử của bạn kết thúc, hãy nạp thanh toán ngay bây giờ.',
  "to upgrade. This subscription cost you":'để nâng cấp. Gói đăng ký này lấy phí từ bạn',
  "topic":'Chủ đề',
  "Total due":'Tổng số đến ngày',
  "Total due {date}":'Tổng đến ngày {date}',
  "Travel & Places":'Travel & Places',
  "trial":'Dùng thử',
  "trial days":'Số ngày dùng thử',
  "trust and safe":'Tin cậy và An toàn',
  "trust-safe":'Tin tưởng và An toàn',
  "tutorials":'Hướng dẫn',
  "type product name":'Gõ tên sản phẩm',
  "type your message":'Gõ tin nhắn của bạn',
  "typify chart":'Biểu đồ tiêu biểu',
  "unban":'Bỏ cấm thành viên',
  "unbookmark":'Bỏ đánh dấu',
  "unbookmarked":'Đã bỏ đánh dấu',
  "unclassified products":'Sản phẩm chưa được phân loại',
  "unpin":'Bỏ ghim',
  "unpin this?":'Bỏ ghim mục này',
  "unsave":'Bản chưa lưu',
  "unsubscribe":'Hủy đăng ký',
  "update about success":'Giới thiệu cập nhật thành công',
  "update dataset":'Cập nhật bộ dữ liệu',
  "update fail":'Cập nhật bị lổi',
  "update success":'Cập nhật thành công',
  "Updates to you the information you have chosen to follow":'Cập nhật đến bạn thông tin bạn đã theo dõi',
  "upgrade":'Nâng cấp',
  "upgrade now. This subscription cost you":'Nâng cấp ngay bây giờ. Đăng ký này lấy phí của bạn',
  "upgrade subscription":'Nâng cấp gói đăng ký',
  "upgrade this channel":'Nâng cấp kênh',
  "upgrade to":'Nâng cấp lên',
  "upgrade to {name}":'Nâng cấp lên {name}',
  "upload":'Tải lên',
  "url":'Đường dẫn',
  "us to share the news in this channel":'với chúng tôi để chia sẻ tin tức trong kênh này',
  "Usage: for direct communication purposes through messaging.":'Sử dụng: cho các mục đích liên lạc trực tiếp thông qua tin nhắn',
  "use email":'Đăng nhập Email',
  "use the selected system language":'Dùng theo ngôn ngữ hệ thống đã chọn',
  "used group":'Các nhóm đã dùng',
  "user choices":'Chọn hiển thị bởi người dùng',
  "user management":'Quản lý tài khoản',
  "uses":'Đã dùng',
  "Verification request has been sent, please wait for a response":'Yêu cầu xác minh đã được gửi đi, vui lòng chờ phản hồi',
  "verified provider":'Tổ chức đã được xác nhận',
  "Verify Account":'Xác nhận tài khoản',
  "Verify on Call":'Xác minh qua cuộc gọi',
  "Verify sales information":'Xác thực thông tin bán hàng',
  "vi":'Tiếng Việt',
  "video url":'Video URL (Liên kết trang Youtube)',
  "vietnamese":'Tiếng Việt',
  "view":'Xem',
  "view all":'Xem tất cả',
  "View all categories":'Xem tất cả danh mục',
  "View all products in {name}":'Xem tất cả các sản phẩm trong {name}',
  "View complete detail":'Xem chi tiết',
  "view full estimate":'Xem đầy đủ kỳ thanh toán tiếp theo',
  "view more charts":'Xem thêm biểu đồ',
  "view product page":'Xem trang sản phẩm',
  "views":'Lượt truy cập',
  "visit page":'Ghé thăm trang',
  "Visit the page to preview":'Truy cập trang để xem trước',
  "vnpay":'VNPay',
  "waiting admin accept your request":'Chờ quản trị viên chấp nhận yêu cầu của bạn',
  "waiting members list":'DS đăng ký tham gia',
  "wanna more features?":'Bạn muốn thêm tính năng ?',
  "we will upgrade you immediately, you won\'t have to pay anything until your trial ends":'Chúng tôi sẽ nâng cấp bạn ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "we will upgrade you to {subscription} immediately, you won\'t have to pay anything until your trial ends":'Chúng tôi sẽ nâng cấp bạn lên {subscription} ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "welcome":'Chào bạn',
  "What are you looking for?":'Bạn đang cần tìm kiếm gì?',
  "what is your chart name?":'Tên của biểu đồ của bạn là gì?',
  "what is your company name?":'Công ty của bạn tên là?',
  "what is your name?":'Tên của bạn là gì?',
  "what is your question?":'Câu hỏi của bạn là gì?',
  "what your channel name?":'Kênh của bạn tên là gì?',
  "what your product name?":'Sản phẩm của bạn tên là gì?',
  "when you\'re ready":'Khi bạn thấy sẵn sàng',
  "which categories are your interests?":'Bạn quan tâm đến chuyên mục nào ?',
  "with account":'Bằng tài khoản',
  "write a response":'Viết bình luận',
  "write article":'Viết bài',
  "write description for this page":'Viết mô tả cho trang này',
  "write something about this page":'Viết điều gì đó về trang này',
  "Write your sharing":'Viết chia sẻ của bạn',
  "written by":'Được viết bởi',
  "Year of Establishment":'Năm thành lập',
  "You are not choosing to follow this chart category. Please choose follow up in advance":'Bạn không chọn theo dõi chuyên mục thuộc biểu đồ này. Vui lòng chọn theo dõi trước',
  "you can not change your following categories before {time}":'Bạn không thể thay đổi chuyên mục theo dõi trước thời điểm {time}',
  "you can not follow more {n} categories":'Bạn không thể theo dõi quá {n} chuyên mục',
  "You can only upload JPG/PNG file":'Bạn chỉ có thể tải hình định dạng JPG/PNG',
  "you commented on {owner}\'s post":'Bạn đã bình luận trên bài viết của {owner}',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account":'Bạn hiện có giới hạn tối đa là {n} kênh được phép sở hữu. Bạn có thể thay đổi nó bằng cách nâng cấp tài khoản của bạn',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account":'Bạn hiện có giới hạn tối đa là {n} tổ chức được phép sở hữu. Bạn có thể thay đổi giới hạn bằng cách nâng cấp tài khoản của bạn',
  "you didn\'t enter the channel name correctly":'Bạn đã không nhập tên kênh đúng chính xác',
  "you didn\'t enter the provider name correctly":'Bạn đã nhập tên công ty chưa đúng',
  "You do not have a conversation selected":'Bạn chưa chọn cuộc trò chuyện nào',
  "you don\'t have permission to post":'Không được phép đăng bài',
  "you have not any notification!":'Bạn chưa có thông báo nào!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.":'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo danh mục bạn chọn.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.":'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo ngôn ngữ bạn chọn.',
  "You need {amount} to be able subscribe this channel.":'Bạn cần {amount} để có thể đăng ký kênh này.',
  "you need login first":'Bạn cần đăng nhập trước khi thực hiện',
  "Your account has been activated":'Tài khoản của bạn đã được kích hoạt',
  "your answer":'Câu trả lời của bạn',
  "Your business information has been fully updated, thank you":'Thông tin doanh nghiệp của bạn đã được cập nhật đầy đủ, xin cảm ơn',
  "your chart":'Biểu đồ của bạn',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you":'Tài khoản hiện tại của bạn chỉ cho phép theo một ngôn ngữ, nếu bạn muốn xóa giới hạn này, vui lòng nâng cấp lên gói cao hơn. Cảm ơn bạn',
  "Your current active subscription package will continue to hold until it expires":'Gói đăng ký của bạn đang hoạt động hiện tại của bạn sẽ tiếp tục giữ cho đến khi hết hạn',
  "your device don\'t support this!":'Thiết bị không hỗ trợ chức năng này',
  "your invite link expires":'Liên kết của bạn sẽ hết hạn',
  "your message":'Tin nhắn của bạn',
  "Your phone number":'Số điện thoại của bạn',
  "your post":'bài viết của bạn',
  "your request is pending approval":'Yêu cầu của bạn đang chờ phê duyệt',
  "your subscription will be renew on {date}":'Đăng ký của bạn sẽ được gia hạn vào {date}',
  "Your message have been sent":'Tin nhắn của bạn đã được gửi đi',
  "verified":'Đã xác minh',
  "price history":'Lịch sử giá',
  "product groups":'Nhóm sản phẩm',
  "representative":'Đại diện',
  "representative chart":'Biểu đồ đại diện',
  "the content will be delivered to you in the language you choose":'Nội dung sẽ được chuyển đến bạn bằng ngôn ngữ bạn chọn',
  "favorites":'Mục yêu thích',
  "chart name":'Tên biểu đồ',
  "select categories":'Chọn chuyên mục',
  "the dataset has been deleted":'Tập dữ liệu đã được xóa',
  "create article":'Tạo bài viết',
  "have sent you invitation":'đã gửi đến bạn lời mời',
  "related dataset":'Các bộ dữ liệu liên quan',
  "Send invitation to your friends, by sending a copy of the page link below":'Gửi lời mời đến bạn bè của bạn, bằng cách gửi đi liên kết trang bên dưới',
  "Copy and share the page link below to your friends.":'Sao chép và chia sẻ liên kết trang dưới đây cho bạn bè của bạn.',
  "Response from provider will be sent via {email}":'Phản hồi từ tổ chức sẽ được gửi qua {email}',
  "action":'Hành động',
  "timeout":'Thời gian có hiệu lực',
  "limit":'Giới hạn số người mời',
  "5 minutes":'5 phút',
  "30 minutes":'30 phút',
  "1h":'1 giờ',
  "1day":'1 ngày',
  "Join As Member":'Tham gia với tư cách thành viên',
  "Join As Admin":'Tham gia với tư cách quản trị viên',
  "others data":'Dữ liệu khác',
  "channel management":'Quản lý kênh bài viết',
  "channel list":'DS kênh bài viết',
  "all channels":'Tất cả kênh bài viết',
  "technical specs":'Thông số kỹ thuật',
  "functional specs":'Thông số chức năng',
  "other specs":'Thông số khác',
  "custom specs":'Thông số tùy chỉnh',
  "shipment":'Shipment',
  "period":'Kỳ hạn',
  "spot":'Spot',
  "forward":'Forward',
  "basis":'Basis',
  "select period":'Chọn kỳ hạn',
  "from date":'Từ ngày',
  "to date":'Đến ngày',
  "Select the category in which the system will provide you with the most current market data":'Chọn danh mục mà hệ thống sẽ cung cấp cho bạn dữ liệu thị trường mới nhất',
  "Thanks sponsors bring freely these charts to you":'Cảm ơn những nhà tài trợ đã mang lại những biểu đồ này miễn phí cho bạn',
  "Thanks sponsors for updating this news to you":'Cảm ơn các nhà tài trợ đã cập nhật tin tức này đến bạn',
  "quotes of provider":'Báo giá của tổ chức',
  "announce":'Ngày thông báo',
  "product price announcement":'Thông báo giá sản phẩm',
  "create report":'Tạo báo cáo',
  "the recipient\'s email":'Thư điện tử của người nhận',
  "new email":'Thư điện tử mới',
  "price_type":'Loại giá',
  "Pricing Reports":'Báo cáo giá sản phẩm',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce":'Tạo thông báo giá để gửi cho người mua, đối tác mua hoặc thông báo giá sản phẩm',
  "products quotation":'Báo giá sản phẩm',
  "effected date":'Ngày hiệu lực',
  "price update":'Cập nhật giá',
  "no price tag":'Không để giá',
  "updated at":'Cập nhật lúc',
  "reference price":'Giá tham chiếu',
  "basis price":'Giá cơ sở',
  "forward price":'Giá kỳ hạn',
  "delivery type":'Loại giao hàng',
  "general basic prices":'Giá cơ bản chung',
  "leave blank to select all":'Để trống để chọn tất cả',
  "This information is confidential and is only for the member to be submitted":'Thông tin này là bảo mật và chỉ dành riêng cho thành viên được gửi',
  "Sure to remove?":'Chắc chắn rằng bạn muốn xóa?',
  "Enter a price for the period":'Nhập giá cho khoảng thời gian',
  "advanced entry":'Nhập nâng cao',
  "flat price":'Giá chốt',
  "quoted prices":'Giá công bố',
  "start (/end) date in term months":'Ngày bắt đầu ( / kết thúc) trong các tháng kỳ hạn',
  "select provinces":'Chọn Tỉnh / Thành',
  "spot price":'Giá giao ngay',
  "confirm":'Xác nhận',
  "delivery type option (delivery is default): this option affects the product price":'Tùy chọn Giá theo hình thức Phân phối (Vận chuyển là mặc định): Tùy chọn này ảnh hưởng đến giá sản phẩm',
  "delivery price":'Giá Delivery',
  "shipment price":'Giá Shipment',
  "add spot price":'Thêm mới giá giao ngay',
  "add forward price":'Thêm mới giá kỳ hạn',
  "period range":'Khoảng thời gian',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed":'Thông tin này được điều chỉnh trong phạm vi báo cáo, nội dung thông tin ban đầu không thay đổi',
  "original price":'Giá gốc',
  "feedin users":'Người dùng trên FeedIn',
  "choose user":'Chọn người dùng',
  "Select the term for the price input":'Chọn tháng kỳ hạn cho phần nhập giá',
  "price unit":'Đ/v giá',
  "enter new price":'Nhập giá mới',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)":'Nhập / cập nhật ở màn hình này, giá sẽ được cập nhật mới nhất vào thời điểm hiện tại cho từng loại giá (giá giao ngay, giá kỳ hạn)',
  "incoterm":'Incoterm',
  "transport type":'Phương tiện chuyên chở',
  "unit of the basis price":'Đơn vị cho giá basis',
  "new price record by location":'Bản ghi giá mới theo vị trí',
  "This location has been declared, cannot be added":'Địa điểm này đã khai báo, không thể thêm mới',
  "choose another location":'Chọn địa điểm khác',
  "new price set":'Tập dữ liệu giá mới',
  "choose another option for new price set":'Chọn một tùy chọn khác cho bộ giá mới',
  "These options are already declared, cannot be added":'Các tùy chọn này đã được khai báo, không thể thêm vào',
  "add new forward price":'Thêm mới giá kỳ hạn',
  "edit quotes":'Chỉnh sửa báo giá',
  "other unit":'đơn vị khác',
  "if basis price is required":'Nếu giá cơ sở là bắt buộc',
  "range":'Khoảng',
  "editor\'s choice":'Biên tập chọn',
  "remove the 2nd unit":'Xóa đi đơn vị thứ 2',
  "hidden price":'Không hiển thị giá bán',
  "wholesale":'Bán sỉ',
  "contact provider for wholesale price":'Liên hệ tổ chức để có giá bán sỉ',
  "provided by":'Cung cấp bởi',
  "about product":'Về sản phẩm',
  "brief description":'Mô tả ngắn',
  "manufactor":'Nhà sản xuất',
  "add new specification":'Thêm mới thông số',
  "selecting...":'Đang chọn ....',
  "method type":'Loại phương pháp',
  "operation":'Operation',
  "attribute":'Thuộc tính',
  "attribute value":'Giá trị thuộc tính',
  "factory":'Nhà máy',
  "Specification/Additional Details":'Thông số / Thuộc tính bổ sung',
  "tap on here to start your articles":'Nhấp vào đây để bắt đầu bài viết',
  "attribute group":'Nhóm thuộc tính',
  "displayed on product information":'Hiển thị trên thông tin sản phẩm',
  "page not found":'Trang không tồn tại',
  "The page you are opening does not exist, please check the link or return to the homepage":'Trang bạn đang mở không tồn tại, vui lòng kiểm tra liên kết hoặc quay lại trang chủ',
  "catalog group":'Nhóm mục lục',
  "manage page":'Trang quản lý',
  "main page":'Trang chính',
  "use for":'Sử dụng cho',
  "set_channel":'Cập nhật kênh đăng bài',
  "added value":'Giá cộng thêm',
  "product line":'Nhóm dòng sản phẩm',
  "2nd unit":'Đơn vị 2',
  "2nd price unit":'Đơn vị giá 2',
  "The effective date of the current price set will be adjusted to the date below":'Ngày hiệu lực của bộ giá hiện tại sẽ điều chỉnh về ngày dưới đây',
  "Generate new updated price data effective as of the date below. Note: data will be temporarily recorded according to the price of the most recent day.":'Tạo dữ liệu giá mới cập nhật có hiệu lực theo ngày dưới đây. Chú ý: dữ liệu sẽ tạm thời ghi nhận theo giá của ngày gần nhất.',
  "Copy the current priceset for another location with the same priceset":'Sao chép tập giá hiện tại cho một vị trí khác có cùng tập giá',
  "Please contact the administrator of FeedIn to put the data on the site":'Vui lòng liên hệ quản trị viên của FeedIn để đưa dữ liệu lên trang web',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'Dán đường dẫn ở đây để nhúng nội dung từ một trang web khác (ví dụ: Twitter) và nhấp Ok',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'Dán  đường dẫn ở đây để nhúng video từ một trang web khác (ví dụ: Youtube) và nhấp vào Ok',
  "Announcement of information":'Thông báo thông tin',
  "Sell products, supply items to the market":'Bán sản phẩm, cung cấp mặt hàng ra thị trường',
  "Provide published information and data for the industry":'Cung cấp thông tin và dữ liệu đã xuất bản cho ngành',
  "event name":'Tên sự kiện',
  "what is your event name?":'Tên sự kiện là gì?',
  "end date and time":'Thêm thời gian kết thúc',
  "back to home":'Quay về trang chính',
  "meeting":'Họp mặt',
  "online":'Trực tuyến',
  "online event?":'Sự kiện trực tuyến?',
  "online event":'Sự kiện trực tuyến',
  "created by":'Tạo bởi',
  "milestone":'Mốc sự kiện',
  "start time":'Giờ bắt đầu',
  "Select event type (online / meeting)":'Chọn hình thức sự kiện (trực tuyến / hội họp)',
  "Event, what?, when?":'Sự kiện, Về chủ đề gì?, Thời gian?',
  "Event name, start time":'Tên sự kiện, thời gian diễn ra',
  "Add a physical location for people to join your event. Or how to meet together in online event":'Thêm vị trí thực tế để mọi người tham gia sự kiện của bạn. Hoặc làm thế nào để gặp nhau trong sự kiện trực tuyến',
  "Event, where?":'Sự kiện, Ở đâu?',
  "Provide more information about your event so guests know what to expect.":'Cung cấp thêm thông tin về sự kiện của bạn để khách mời biết điều gì sẽ xảy ra.',
  "Event, how?":'Sự kiện, Như thế nào?',
  "Everyone meeting on the internet.":'Mọi người họp trên internet.',
  "Everyone meet together at a specific location.":'Mọi người cùng gặp nhau tại một địa điểm cụ thể.',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'Nếu bạn đã hoàn toàn quên mật khẩu cũ của mình. Vui lòng đăng xuất và sử dụng chức năng "{1}" để đặt lại mật khẩu nếu cần',
  "Please select the language you want as the display language on the application":'Vui lòng chọn ngôn ngữ bạn muốn làm ngôn ngữ hiển thị trên ứng dụng',
  "farm":'Nông trại',
  "recover login password":'Khôi phục mật mã đăng nhập',
  "individual":'Cá nhân',
  "you are":'Bạn là',
  "organization name":'Tên tổ chức',
  "attention: please check your activation email in the spam area":'Chú ý: vui lòng kiểm tra email kích hoạt của bạn trong Khu vực thư rác',
  "with your email":'Bằng địa chỉ email của bạn',
  "no products, please add products to your page":'Không có sản phẩm, bạn hãy thêm sản phẩm cho trang của bạn',
  "priceset history management":'Quản lý lịch sử tập giá',
  "product configuration":'Cấu hình sản phẩm',
  "events in category":'Sự kiện trong chuyên mục',
  "no events":'Không có sự kiện',
  "select / create":'Chọn / Tạo mới',
  "co-admins":'Đồng quản trị',
  "weekly":'Hàng tuần',
  "quarterly":'Hàng quý',
  "yearly":'Hàng năm',
  "the event will repeat in":'Sự kiện sẽ lặp lại',
  "finish time":'Thời gian kết thúc',
  "updating date":'Ngày cập nhật',
  "price is valid from the date":'Giá có hiệu lực tại ngày',
  "contract type":'Loại hợp đồng',
  "reminder":'Nhắc nhở',
  "type of event?":'Loại sự kiện',
  "speakers":'Diễn giả',
  "select user":'Chọn người dùng',
  "event_types":'Loại sự kiện',
  "something is wrong, want to delete it?":'Có gì đó không đúng, muốn xóa nó?',
  "successfully deleted":'Đã xóa thành công!',
  "are you sure to delete this?":'Bạn có chắc chắn xóa cái này không?',
  "deletion is ignored":'Hành động Xóa bị bỏ qua',
  "insert here":'Chèn ở đây',
  "not milestone":'Diễn biến',
  "The next event is scheduled in":'Sự kiện tiếp theo được lên lịch sau',
  "The next event is scheduled to take place at {1}":'Sự kiện tiếp theo dự kiến ​​diễn ra lúc {1}',
  "products can be searched":'Có thể tìm kiếm sản phẩm',
  "your product has been declared complete, you can put it on the store":'Sản phẩm của bạn đã được khai báo hoàn chỉnh, bạn có thể đưa lên gian hàng',
  "Do you want to activate this product?":'Bạn có muốn kích hoạt sản phẩm này không?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you":'Sản phẩm này sẽ thêm vào Danh mục đã kích hoạt của bạn và sẽ hiển thị với người mua. Bạn cũng sẽ bắt đầu nhận BuyLeads cho sản phẩm này. Cám ơn bạn',
  "profiles":'Hồ sơ',
  "incoming requests management":'Quản lý yêu cầu gửi đến',
  "waiting members":'Thành viên đang đăng ký',
  "purchases":'Mua hàng',
  "quote prices":'Quản lý báo giá',
  "market prices":'Giá thị trường',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly":'Thông tin liên hệ chưa được khai báo, thật tốt nếu khai báo thông tin liên hệ để khách hàng có thể liên hệ trực tiếp đến bạn',
  "members management":'Quản lý thành viên',
  "partners":'Đối tác',
  "vendor":'Nhà cung cấp',
  "vendors":'Nhà cung cấp',
  "customer":'Khách hàng',
  "customers":'Khách hàng',
  "contact books":'Sổ danh bạ',
  "add new contact book":'Thêm mới vào danh bạ',
  "connect to user":'Liên kết đến Người dùng',
  "connect to organization":'Liên kết đến Tổ chức',
  "connect to partner":'Liên kết đến Đối tác',
  "new contact book":'Thêm mới danh bạ',
  "new partner":'Thêm đối tác mới',
  "partner type":'Loại đối tác',
  "partner name":'Tên đối tác',
  "edit contact book":'Chỉnh sửa danh bạ',
  "edit partner":'Chỉnh sửa đối tác',
  "all partners":'Tất cả đối tác',
  "add new partner":'Thêm mới đối tác',
  "for sales notes":'Ghi chú bán hàng',
  "for purchase notes":'Ghi chú mua hàng',
  "notes":'Ghi chú',
  "Note: The effective date of the price being updated is different from the price entry date":'Lưu ý: Ngày cập nhật giá có hiệu lực khác với ngày nhập giá',
  "product catalog":'Nhóm hiển thị sản phẩm',
  "group by":'Nhóm theo',
  "type of product use for sales / purchases":'Loại sản phẩm sử dụng để bán hàng / mua hàng',
  "sales / purchases":'Bán hàng / Mua hàng',
  "added":'Đã thêm',
  "feedin organizations":'Tổ chức trên FeedIn',
  "refresh chart":'Cập nhật biểu đồ',
  "incoming quotes":'Báo giá gửi đến',
  "purchasing planning":'Lập kế hoạch mua hàng',
  "manage outgoing quotes":'Quản lý báo giá gửi đi',
  "product group title":'Tên nhóm sản phẩm',
  "Successfully Sign Up":'Đăng ký thành công',
  "redirect in {1} seconds":'chuyển hướng sau {1} giây',
  "Thank you for registering, Website will redirect to login page after 3 seconds":'Cảm ơn bạn đã đăng ký, Website sẽ chuyển hướng đến trang đăng nhập sau 3 giây',
  "add products":'Thêm sản phẩm',
  "price offer partners":'Đối tác cung cấp giá',
  "Select the products for which you want to send price information to your partner":'Chọn sản phẩm mà bạn muốn gửi thông tin giá cho đối tác',
  "products for partner":'Sản phẩm cho đối tác',
  "Note: the list below shows only partners linked to the organizations on FeedIn":'Lưu ý: danh sách dưới đây chỉ hiển thị các đối tác được liên kết với các tổ chức trên FeedIn',
  "bookmarks":'Sổ lưu đánh dấu',
  "my space":'Góc riêng',
  "all bookmarks":'Tất cả mục đánh dấu',
  "quotations for partners":'Báo giá cho đối tác',
  "favourites":'Mục yêu thích',
  "vietnam market":'Thị trường Việt Nam',
  "workspace":'Góc làm việc',
  "updates":'Cập nhật',
  "for search optimization":'để tối ưu công việc tìm kiếm',
  "to optimize product display":'nhằm tối ưu hiển thị sản phẩm',
  "to group products with the same properties, compare prices":'nhằm gom nhóm các sản phẩm có cùng tính chất, so sánh giá',
  "be sent from partners":'Được gửi đến từ đối tác',
  "owned":'Sở hữu',
  "Prices below are average prices with selected location or selected period is All":'Giá dưới đây là giá trung bình với vị trí đã chọn hoặc khoảng thời gian đã chọn là Tất cả',
  "Can follow the latest news and events in the industry":'Có thể theo dõi kênh tin tức và sự kiện mới nhất trong ngành hàng',
  "Explore dataset and charts":'Khám phá tập dữ liệu và biểu đồ',
  "to help you manage":'Để giúp bạn quản trị',
  "or create an organization as":'HOẶC tạo Tổ chức ứng với',
  "farmer":'Nông trại',
  "buyer":'Mua hàng',
  "Create events to reach the commodity market":'Tạo sự kiện để tiếp cận thị trường hàng hóa',
  "Create news channels to announce and share information to the commodity market":'Tạo kênh tin để công bố, chia sẻ thông tin đến thị trường ngành hàng hóa',
  "Cooperate with FeedIn to share and provide commodity data to customers":'Hợp tác với FeedIn để chia sẻ và cung cấp dữ liệu hàng hóa cho khách hàng',
  "Please contact the administrator of FeedIn":'Vui lòng liên hệ với quản trị viên của FeedIn',
  "name required more than {1} characters":'Tên nội dung yêu cầu nhiều hơn {1} ký tự',
  "set subscription":'Đặt đăng ký',
  "subscribed":'Đã đăng ký',
  "edit contact":'Chỉnh sửa thông tin liên hệ',
  "Information gathered from the declaration in the contact information":'Thông tin thu thập từ khai báo trong thông tin liên hệ',
  "The information will be linked to the information of partner":'Thông tin sẽ được liên kết với thông tin của đối tác',
  "product had basic information and ready to launch":'Sản phẩm đã có thông tin cơ bản và sẵn sàng ra mắt',
  "internal use":'Lưu hành nội bộ',
  "{launch} the product to display on the store":'{launch} sản phẩm để trưng bày trên cửa hàng',
  "OR {activate} to input the price and manage it internally":'HOẶC {activate} để nhập giá và quản lý giá nội bộ',
  "launch":'Khởi chạy',
  "activate only":'Mở hoạt động',
  "{deactivate} product":'{deactivate} sản phẩm',
  "deactivate":'Hủy trạng thái hoạt động',
  "set effect date":'Điều chỉnh ngày hiệu lực',
  "insert at":'Thêm giá tại ngày',
  "expire at":'Dừng hiệu lực ngày',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.":'Trang không tồn tại HOẶC trang sử dụng nội bộ, bạn phải là thành viên mới xem nội dung của trang này.',
  "hybrid event":'Kết hợp Hội họp / Trực tuyến',
  "no data yet":'Chưa có dữ liệu',
  "Please login to view this content":'Bạn vui lòng đăng nhập để xem nội dung thông tin này',
  "no data is available now":'Hiện không có dữ liệu',
  "latest tracking news":'Tin tức theo dõi mới nhất',
  "activate":'Kích hoạt',
  "Buy goods, collect quotes from sellers":'Mua hàng, tổng hợp nhận báo giá từ người bán',
  "following charts":'Các biểu đồ đang theo dõi',
  "Leave your information so we can contact you again":'Để lại thông tin để chúng tôi có thể liên hệ lại',
  "purchase":'Mua hàng',
  "enter spot price":'Nhập giá giao ngay',
  "present":'Hiện nay',
  "weather":'Thời tiết',
  "Temperature - weather, tap to see more":'Nhiệt độ - thời tiết, nhấn để xem thêm',
  "Rain (thunder) - weather, tap to see more":'Mưa (sấm sét) - thời tiết, nhấn để xem thêm',
  "Wind - weather, tap to see more":'Gió - thời tiết, nhấn để xem thêm',
  "temperature":'Nhiệt độ',
  "rain":'Mưa',
  "wind":'Gió',
  "edit & save":'Sửa & Lưu',
  "create from":'Tạo mới từ',
  "remove expired":'Bỏ hết hiệu lực',
  "delete prices at":'Xóa giá tại',
  "copy prices at":'Sao chép giá tại',
  "enter forward price":'Nhập giá kỳ hạn',
  "affiliate products":'Sản phẩm liên kết',
  "at date":'tại ngày',
  "This pricing information will expire in":'Thông tin giá này sẽ hết hạn sau',
  "This pricing information will expire at":'Thông tin giá này sẽ hết hạn vào',
  "no expired date":'Không có ngày hết hạn',
  "quarters":'quý',
  "you have an article in progress":'Bạn có một bài viết đang thực hiện',
  "Create an information channel to promote your information and products to everyone":'Tạo kênh thông tin để quảng bá thông tin và sản phẩm của bạn đến với mọi người',
  "get {free} quotes from multiple sellers":'Nhận báo giá {free} từ nhiều người bán',
  "Tell us your Requirement":'Cho chúng tôi biết yêu cầu của bạn',
  "Receive free quotes from sellers":'Nhận báo giá miễn phí từ người bán',
  "Seal the Deal":'Tiến hành giao dịch',
  "Enter product/ Service name":'Nhập tên sản phẩm / dịch vụ',
  "Your email address":'Địa chỉ email của bạn',
  "Enter your name":'Nhập tên của bạn',
  "Your live in":'Bạn sống ở',
  "We connect":'Chúng tôi kết nối',
  "Buyers Sellers":'Người mua & Người bán',
  "Trusted Platform":'Nền tảng đáng tin cậy',
  "Safe Secure":'An toàn & bảo mật',
  "Quick Assistance":'Hỗ trợ nhanh',
  "Create events so people can participate and learn more about your organization":'Tạo sự kiện để mọi người có thể tham gia và hiểu thêm về tổ chức của bạn',
  "my articles":'Bài viết của tôi',
  "Use keyboard shortcuts":'Sử dụng phím tắt',
  "edited by":'Được soạn thảo bởi',
  "Publishing to:":'Xuất bản bởi:',
  "write caption for image":'Viết chú thích cho hình ảnh',
  "caption of image":'Chú thích của hình ảnh',
  "write caption for video":'Viết chú thích cho video',
  "caption of video":'Chú thích của video',
  "video url is invalid!":'Video không tồn tại!',
  "enter video url":'Nhập đường dẫn video',
  "add caption":'Thêm chú thích',
  "related products":'Sản phẩm liên quan',
  "products from the same organization":'Sản phẩm cùng tổ chức',
  "get direction":'Hướng dẫn đường đi',
  "export purchasing data":'Xuất dữ liệu mua hàng',
  "export sales data":'Xuất dữ liệu bán hàng',
  "select page":'Chọn trang',
  "send requirement":'Gửi yêu cầu',
  "detail requirement":'Chi tiết yêu cầu',
  "attach file":'Đính kèm',
  "how it work":'Cách hoạt động',
  "tell us what you need by filling the form":'Điền vào biểu mẫu yêu cầu của bạn',
  "receive verified supplier details":'Nhận báo giá từ các nhà cung cấp',
  "compare quotations and seal the deal":'So sánh các báo giá và giao dịch',
  "your information":'Thông tin của bạn',
  "choosing a category will help the article stay on topic and more accessible to users":'Chọn chuyên mục sẽ giúp bài viết nằm đúng chủ đề của nó và dễ tiếp cận hơn với người dùng',
  "please add images to make the article more vivid and realistic to attract more viewers":'Vui lòng thêm hình ảnh để bài viết trở nên sinh động và chân thực hơn để thu hút nhiều người xem hơn',
  "data has been generate":'Dữ liệu đã được tạo',
  "please click the {download} button below to download to your device":'Bạn vui lòng bấm nút {download} bên dưới để tải về thiết bị',
  "article preview":'xem trước bài viết',
  "back to edit":'Quay lại để chỉnh sửa',
  "please login so the supplier can contact you":'Vui lòng đăng nhập để nhà cung cấp có thể liên hệ với bạn',
  "product information":'Thông tin sản phẩm',
  "please provide a few details to get the most accurate response from the supplier":'Vui lòng cung cấp một vài thông tin chi tiết để nhận được phản hồi chính xác nhất từ nhà cung cấp',
  "article has no images":'Bài viết không có hình ảnh',
  "this channel belongs to":'Kênh này thuộc về',
  "unit price":'Đơn giá',
  "posted at":'Gửi vào lúc',
  "respond at":'Phản hồi lúc',
  "view website":'Xem trang chính',
  "send message":'Gửi tin nhắn',
  "resend request":'Gửi lại yêu cầu',
  "close request":'Đóng yêu cầu',
  "view detail":'Xem chi tiết',
  "hide detail":'Ẩn chi tiết',
  "not sure":'Không',
  "sure":'Tôi chắc chắn',
  "you have found a supplier for this product and you want to close this request":'Bạn đã tìm được nhà cung cấp cho sản phẩm này và bạn muốn đóng yêu cầu này',
  "are you sure":'Bạn có chắc chắn',
  "company name (if you buy for the company)":'Tên công ty: (nếu có, nếu bạn mua hàng cho công ty)',
  "Request a quote / Order":'Yêu cầu báo giá / Đặt hàng',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'Ảnh đại diện được chọn từ bài viết, hãy quay lại và thêm hình ảnh để bài viết sinh động và hấp dẫn hơn',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'Nhận báo giá mới và chính xác nhất từ nhà cung cấp cho sản phẩm bạn đang quan tâm',
  "filtered by":'Lọc kết quả bởi',
  "my order request":'Yêu cầu đặt hàng',
  "keyword: request id, organization id (name)":'Từ khóa: ID yêu cầu, ID tổ chức (tên)',
  "order requests management":'Quản lý yêu cầu đặt hàng',
  "press enter to create new page":'Nhấn enter để tạo trang mới',
  "add new page":'Thêm trang mới',
  "product info":'Thông tin sản phẩm',
  "order request type":'Loại yêu cầu',
  "request type":'Yêu cầu về',
  "received at":'Nhận lúc',
  "min quantity":'Số lượng thấp nhất',
  "productinfo":'Thông tin sản phẩm',
  "deleted":'Đã xoá',
  "alert drafting article description":'Bài viết này đang có thay đổi và chưa được xuất bản. Bạn có thể nhấp vào đây để tiếp tục soạn thảo và xuất bản nội dụng.',
  "delete article":'Xoá bài viết',
  "language linking":'Liên kết ngôn ngữ',
  "articles in other languages":'Bài viết ngôn ngữ khác',
  "Are you sure remove this related article?":'Bạn có chắc chắn xóa bài viết liên quan này không?',
  "Type the title of the article in other languages to search and add the article you want to link to.":'Gõ tiêu đề của bài viết bằng các ngôn ngữ khác để tìm kiếm và thêm bài viết mà bạn muốn liên kết đến.',
  "linked articles":'Bài viết được liên kết',
  "edit article setting":'Chỉnh sửa thông tin bài viết',
  "owner by":'Sở hữu bởi',
  "grateful description":'Chúng tôi xin chân thành cảm ơn các nhà tài trợ',
  "You can click \"See more\" to see this full information":'Bạn có thể bấm vào "Xem thêm" để xem được đầy đủ thông tin này',
  "your donation description":'Dưới đây là quyên góp của bạn. Nếu có điều gì đó sai sót, vui lòng liên hệ với quản trị viên chiến dịch. Chúng tôi cảm ơn sự đóng góp của bạn.',
  "contribution chart":'Biểu đồ đóng góp',
  "list of contributors":'Danh sách đóng góp',
  "donated items":'Đã đóng góp',
  "pending items":'Chưa đóng góp',
  "search with donation code":'Tìm kiếm với Mã quyên góp',
  "equivalent to":'tương đương với',
  "has a capacity of":'có dung lượng là',
  "my donations":'Đóng góp của tôi',
  "thank you card":'Thiệp cám ơn',
  "sincerely thank you":'Trân trọng cám ơn',
  "supported the donation campaign":'đã ủng hộ cho chiến dịch quyên góp',
  "money / goods":'Số tiền / Vật Phẩm',
  "Sincere thanks to the benefactors for accompanying us to create a picture of kindness.":'Chân thành cảm ơn Quý ân nhân đã đồng hành để chúng ta cùng tạo nên bức tranh nhân ái.',
  "Wishing you good health, peace and happiness.":'Kính chúc quý ân nhân sức khỏe, bình an, hạnh phúc.',
  "Kind regards,":'Trân trọng,',
  "poll question":'Thăm dò ý kiến',
  "latest contributions":'Đóng góp mới nhất',
  "news update":'Tin tức cập nhật',
  "export as PNG":'Xuất dạng hình ảnh',
  "register now":'Đăng ký ngay',
  "would you like to donate another value":'Bạn có muốn quyên góp giá trị khác ?',
  "choose the package that you can donate":'Chọn gói mà bạn có thể quyên góp',
  "register to contribute":'Đăng ký đóng góp',
  "copy path":'Chép đường dẫn',
  "click here to start writing":'Nhấp vào đây để bắt đầu viết',
  "impact-categories":'Tác động',
  "host request":'Yêu cầu làm Đơn vị tổ chức',
  "host-request":'Yêu cầu làm Đơn vị tổ chức',
  "adding an address will help people find you":'Thêm địa chỉ sẽ giúp mọi người tìm thấy bạn',
  "load more":'Tải thêm',
  "waiting-members":'Thành viên đang chờ',
  "add host":'Thêm chủ quản',
  "Are you sure delete this host?":'Bạn có chắc chắn xóa chủ quản này không?',
  "confirmation":'Xác nhận',
  "Deleted {type} are gone forever. Are you sure?":'{type} đã xóa sẽ biến mất vĩnh viễn. Bạn có chắc không?',
  "{type} name":'Tên {type}',
  "you didn\'t enter the {type} name correctly":'Bạn đã không nhập đúng tên {type}',
  "select tags":'Chọn tags',
  "finished":'Đã hoàn tất',
  "Allow contribution information to be visible to the public":'Cho phép công khai thông tin đóng góp',
  "Allow charity work information to be visible to the public":'Cho phép công chúng hiển thị thông tin về công việc từ thiện',
  "Allows to be searched by recruitment agencies":'Cho phép được các cơ quan tuyển dụng tìm kiếm',
  "clip_url":'Đường dẫn video',
  "edit owner":'Chỉnh sửa chủ sở hữu',
  "ref_ids":'Liên kết chiến dịch',
  "edit campaign link":'Chỉnh sửa liên kết chiến dịch',
  "add target details":'Thêm mục tiêu chi tiết',
  "select unit":'Chọn đơn vị',
  "select item":'Chọn vật phẩm',
  "votes":'Ủng hộ',
  "shares":'Chia sẻ',
  "giving items":'Đã trao đi',
  "search with giving code":'Tìm kiếm với mã trao đi',
  "latest giving":'Trao đi mới nhất',
  "---Choose One---":'Chọn một',
  "Linked Pages":'Liên kết trang',
  "contact information so people can reach out when they need help":'Thông tin liên lạc để mọi người có thể liên lạc khi cần hỗ trợ',
  "detail target":'Mục tiêu chi tiết',
  "click to select":'Nhấn vào để chọn',
  "not available":'Chưa cập nhật',
  "summary report":'Báo cáo tổng hợp',
  "click here for the link":'Bấm vào đây để liên kết',
  "your campaign is currently not associated with any giving campaign, click here to connect":'Hiện tại chiến dịch của bạn vẫn chưa liên kết với chiến dịch trao đi nào, bấm vào đây để kết nối',
  "link to giving":'Liên kết đến Trao đi',
  "search with registration code":'Tìm kiếm với mã đăng ký',
  "giving chart":'Biểu đồ trao đi',
  "list of giving":'Danh sách trao đi',
  "your registered list":'Danh sách đăng ký của bạn',
  "indexed by wishare":'Được lập chỉ mục bởi Wishare',
  "recruiting volunteers":'Tuyển dụng tình nguyện viên',
  "type of recruitment":'Loại hình tuyển dụng',
  "job requirements":'Yêu cầu công việc',
  "time to start work":'Thời gian bắt đầu công việc',
  "application deadline":'Thời hạn nộp hồ sơ',
  "Create an organization to share and raise money for your charity":'Tạo tổ chức để chia sẻ và kêu gọi giúp đỡ cho chương trình thiện nguyện của bạn',
  "Create activity to share your campaigns":'Tạo hoạt động để chia sẻ những chiến dịch của bạn',
  "create donation campaign":'Tạo chiến dịch quyên góp',
  "Create a donation campaign to call for support from donors":'Tạo chiến dịch quyên góp để kêu gọi sự ủng hộ từ các mạnh thường quân',
  "create giving campaign":'Tạo chiến dịch trao đi',
  "Create a giving campaign to help those in need":'Tạo chiến dịch trao đi để giúp đỡ cho những người có hoàn cảnh khó khăn',
  "Create a recruitment campaign to find volunteers for the organization": 'Tạo tuyển dụng để tìm những tình nguyện viên cho tổ chức',
  "Create an event to connect with other benefactors or volunteer organizations":'Tạo sự kiện để kết nối với những nhà hảo tâm hoặc tổ chức thiện nguyện khác',
  "Create a channel to share information about your charity program":'Tạo kênh để chia sẻ thông tin về chương trình thiện nguyện của bạn',
  "who can post":'Cho phép đăng bài',
  "everyone":'Mọi người',
  "administrators":'Quản trị viên',
  "event type":'Loại sự kiện',
  "npo": 'Tổ chức phi lợi nhuận',
  "ngo":'Tổ chức phi chính phủ',
  "association":'Hiệp hội',
  "edit cd type":'Chỉnh sửa loại văn bản',
  "Channel members only":'Chỉ thành viên của kênh',
  "Channel admins only":'Chỉ quản trị viên kênh',
  "Everyone who has an account at wishare":'Tất cả những người có tài khoản tại Wishare',
  "most view":'Xem nhiều',
  "donation types":'Loại quyên góp',
  "giving types":'Loại trao đi',
  "occupation types":'Loại nghề nghiệp',
  "reserved job types":'Công việc dành riêng',
  "recruitment types":'Loại tuyển dụng',
  "what":'Là gì ?',
  "when":'Khi nào ?',
  "whose":'Tạo bởi ai ?',
  "how":'Hoạt động thế nào ?',
  "An organization is a Group, Association, Community... , which is set up to gather community and social resources and implement social projects.":'Tổ chức là Nhóm, Hội, Cộng đồng... , được dựng lên để tập hợp nguồn lực cộng đồng, xã hội, thực hiện các dự án xã hội.',
  "Organize activities throughout, be recognized for the group\'s activities.":'Tổ chức hoạt động xuyên suốt, được ghi nhận quá trình hoạt động của nhóm.',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, which can be used as a branch of another organization.":'Tổ chức được tạo ra bởi cá nhân, và có thể được dựng lên từ 1 đến nhiều cá nhân hoặc tổ chức, có thể dùng như 1 nhánh thuộc tổ chức khác.',
  "Organizations use tools: Recruitment, Events, Channels, Articles to operate, organizations have 1 or more Activities to organize projects according to their own purposes.":'Tổ chức sử dụng các công cụ: Tuyển dụng, Sự kiện, Kênh thông tin, Bài viết để vận hành, tổ chức có 1 hoặc nhiều Hoạt động để tổ chức các dự án  theo các mục đích riêng.',
  "Activity is a social impact project, created to serve a specific mission, aiming to make an impact on society.":'Hoạt động là 1 dự án tạo tác động xã hội, được tạo nên để phục vụ sứ mệnh cụ thể, nhằm hướng đến tạo tác động đến xã hội.',
  "Activities are created and maintained throughout, recorded during the operation of the project.":'Hoạt động được tạo và duy trì xuyên suốt, được ghi nhận quá trình hoạt động của dự án.',
  "It can be started as a project of an independent individual, but for the sake of society, the use of community resources and sustainability, long-term, can be scaled up. Activities should be created by Groups, Associations, Communities...":'Có thể xuất phát là 1 dự án của 1 cá nhân độc lập, nhưng vì tính xã hội, sử dụng nguồn lực cộng đồng và tính bền vững, lâu dài, có thể mở rộng. Các Hoạt động nên được tạo ra bởi Nhóm, Hội, Cộng đồng...',
  "Activities using tools: Donate, Give away to make the operation process transparent, record contribution information to make an impact according to Theory Of Change.":'Hoạt động sử dụng các công cụ: Quyên góp, Trao đi để minh bạch quá trình vận hành, ghi nhận thông tin đóng góp tạo tác động theo Theory Of Change.',
  "Donation is an event, a campaign taking place for a limited time, to mobilize resources for the Activity.":'Quyên góp là 1 sự kiện, 1 chiến dịch diễn ra trong 1 thời gian có hạn, nhằm huy động nguồn lực cho Hoạt động.',
  "Donations are made over a limited period of time according to the scope of each phase of the project.":'Quyên góp được diễn ra trong khoảng thời gian hữu hạn theo phạm vi từng giai đoạn của dự án.',
  "Donations are generated from Activity and tied to a Giveaway campaign, for transparency of Mobilization resources.":'Quyên góp được tạo ra từ Hoạt động và gắn đến với 1 chiến dịch Trao đi, nhằm minh bạch nguồn lực Huy động.',
  "Giving is an event, a campaign taking place in a limited time, in order to find and give away mobilized resources, creating transparency for Activities.":'Trao đi là 1 sự kiện, 1 chiến dịch diễn ra trong 1 thời gian có hạn, nhằm tìm kiếm và trao đi nguồn lực đã huy động, tạo sự minh bạch cho Hoạt động.',
  "Giving takes place over a finite period of time according to the scope of each phase of the project.":'Trao đi được diễn ra trong khoảng thời gian hữu hạn theo phạm vi từng giai đoạn của dự án.',
  "Giving is generated from Activity and tied to a Donation campaign, to make Funding resources transparent.":'Trao đi được tạo ra từ Hoạt động và gắn đến với 1 chiến dịch Quyên góp, nhằm minh bạch nguồn lực Huy động.',
  "Recruitment is the function of creating recruitment request information, in order to mobilize human resources for organizations and activities.":'Tuyển dụng là chức năng tạo thông tin yêu cầu tuyển dụng, nhằm huy động nguồn nhân lực cho tổ chức, hoạt động.',
  "Recruitment activities have a timeline according to the development stage of the organization.":'Hoạt động tuyển dụng có mốc thời gian theo giai đoạn phát triển của tổ chức.',
  "Recruitment is made from Groups, Associations, Community...":'Tuyển dụng được lập từ Nhóm, Hội, Cộng đồng...',
  "Event is an activity for the purpose of sharing event information, recording progress for participants and interested communities.":'Sự kiện là hoạt đồng nhằm mục đích chia sẽ thông tin sự kiện, ghi nhận diễn biến cho thành viên tham gia và cộng đồng có quan tâm.',
  "The event takes place over a finite period of time.":'Sự kiện được diễn ra trong khoảng thời gian hữu hạn.',
  "Events are created from Groups, Guilds, Communities...":'Sự kiện được tạo từ Nhóm, Hội, Cộng đồng...',
  "Information channel is a function to support sharing and publishing information of social activities.":'Kênh thông tin là chức năng hỗ trợ chia sẽ, công bố thông tin của hoạt động xã hội.',
  "Information channels are maintained throughout.":'Kênh thông tin được duy trì xuyên suốt.',
  "Groups, Associations, Community... can set up 1 or more channels.":'Nhóm, Hội, Cộng đồng... có thể lập 1 hoặc nhiều kênh.',
  "denied":'Từ chối',
  "applied for the job":'đã tham gia ứng tuyển',
  "your donation will be sent to":'Đóng góp của bạn sẽ được gửi đến',
  "register donation":'Đăng ký đóng góp',
  "alias":'Tên bí danh',
  "required information":'Thông tin bắt buộc',
  "date of birth":'Ngày sinh',
  "required fields":'Phần bắt buộc',
  "Please follow these instructions to complete the donation process":'Vui lòng thực hiện các hướng dẫn sau để hoàn thành quy trình đóng góp.',
  "Thank you benefactors, when transferring, remember to specify the structure of the transfer as":'Cảm ơn các nhà hảo tâm, khi chuyển khoản nhớ ghi rõ nội dung chuyển khoản theo cấu trúc là:',
  "your name - donation name":'Tên của bạn - Tên chương trình',
  "Click the Register donate button to complete and receive the donation code":'Nhấn vào nút "Đăng ký quyên góp" để hoàn tất và nhận mã quyên góp',
  "text":'Văn bản ngắn',
  "radio":'Chọn một câu trả lời',
  "checkbox":'Chọn nhiều câu trả lời',
  "textarea":'Văn bản dài',
  "boolean":'Đúng / Sai',
  "conversion required":'Bắt buộc quy đổi',
  "limited":'Có giới hạn',
  "complete registration":'Hoàn tất đăng ký',
  "You have just completed the registration, please follow the donation steps to complete the donation process for campaign":'Bạn vừa hoàn tất đăng ký, vui lòng thực hiện các bước chuyển đóng góp để hoàn tất quá trình quyên góp cho chiến dịch',
  "description donation code":'Đây là mã đóng góp của bạn cho chiến dịch nếu có tài khoản trên wishare bạn có thể kiểm tra lại ở phần thông tin hoặc ở "Tri ân" nếu đã hoàn tất đóng góp theo mã này',
  "Thank you for your contributions to make this life better":'Xin cám ơn những đóng góp của bạn đã giúp cho cuộc sống này tốt đẹp hơn',
  "You can click the \"Back campaign\" button to return to the campaign":'Bạn có thể bấm vào nút "Quay lại" để quay lại chiến dịch',
  "back campaign":'Quay lại chiến dịch',
  "donation input":'Nhập quyên góp',
  "create new post":'Tạo bài viết',
  "post an article":'Đăng bài',
  "you want more":'Bạn muốn thêm',
  "min length 2 characters":'Độ dài tối thiểu 2 ký tự',
  "container":'Đăng trên kênh',
  "anyone can search for and view":'Mọi người có thể tìm kiếm và xem',
  "only you can view":'Chỉ mình bạn có thể xem',
  "add tags":'Gắn thẻ',
  "hashtags":'Gắn thẻ',
  "job position":'Chức danh',
  "work time type":'Thời gian làm việc',
  "is remote work":'Làm việc từ xa',
  "experience requirements":'Yêu cầu kinh nghiệm',
  "min age":'Độ tuổi tối thiểu',
  "max age":'Độ tuổi tối đa',
  "min salary":'Lương tối thiểu',
  "max salary":'Lương tối đa',
  "per quater":'Theo quý',
  "latest list of candidates":'Danh sách ứng tuyển mới nhất',
  "is yes/no question":'Câu hỏi có / không ?',
  "choose number of answers":'Chọn số câu trả lời',
  "one answer":'Một đáp án',
  "multiple answer":'Nhiều đáp án',
  "number of choices":'Số lượng lựa chọn',
  "goto":'Đi đến',
  "add donation option":'Thêm gói quyên góp',
  "are you sure delete this":'Bạn có chắc chắn xóa cái này không ?',
  "would you like to go to the detailed article page":'Bạn có muốn đến trang xem bài viết chi tiết không ?',
  "the article was posted":'Bài viết đã được đăng',
  "are you sure you want to delete this donation package":'Bạn chắc chắn muốn xoá gói đóng góp này ?',
  "delete donation package":'Xoá gói đóng góp',
  "General Categories":'Danh mục chung',
  "private event":'Sự kiện riêng tư',
  "private event description":'Chỉ bạn và những thành viên được mời mới có thể tham gia vào sự kiện',
  "private organization":'Tổ chức riêng tư',
  "private organization description":'Chỉ bạn và những thành viên trong tổ chức mới có thể xem và nhận thông tin từ tổ chức',
  "You have completed the steps of creating organization":'Bạn đã hoàn thành các bước tạo tổ chức',
  "Hope your organization will be successful and help many people":'Hy vọng tổ chức của bạn sẽ thành công và giúp được nhiều người',
  "Short description about your organization":'Mô tả ngắn về tổ chức của bạn',
  "display name":'Tên hiển thị',
  "you have chosen":'Bạn đã chọn',
  "transfer date":'Ngày quyên góp',
  "send a thank you email":'Gửi email cảm ơn',
  "you donated for campaign":'Bạn đã đóng góp cho chiến dịch',
  "volunteer available":'Sẵn sàng làm việc thiện nguyện',
  "action completed":'Hoàn tất',
  "successfully created!":'Tạo thành công',
  "successfully updated!":'Cập nhật thành công',
  "successfully deleted!":'Xoá thành công',
  "search news channel":'Tìm kiếm kênh tin',
  "input data":'Nhập dữ liệu',
  "add receiving option":'Thêm gói nhận',
  "giving input":'Trao đi',
  "your giving will be sent to":'Trao đi của bạn sẽ gởi đến',
  "register giving":'Đăng ký nhận',
  "your registration will be sent to":'Đăng ký của bạn sẽ gởi đến',
  "Please follow these instructions to complete the giving process":'Vui lòng làm theo các hướng dẫn sau để hoàn tất quá trình đăng ký',
  "Your application will be sent to the program and it will take a few days to process, so please wait.":'Đơn đăng ký của bạn sẽ được gửi đến chương trình và sẽ mất vài ngày để xét duyệt, bạn hãy chờ nhé.',
  "Click the Register giving button to complete and receive the giving code":'Bấm vào nút "Đăng ký nhận quà" để hoàn tất và nhận mã nhận quà',
  "You have just completed register receiving gifts from the program":'Bạn vừa hoàn thành đăng ký nhận quà từ chương trình',
  "description giving code":'Đây là mã đăng ký của bạn để nhận quà của chiến dịch nếu có tài khoản trên Wishare bạn có thể kiểm tra lại ở phần thông tin hoặc ở "Đăng ký của tôi" theo mã này',
  "successfully subscribed":'Đăng ký thành công !',
  "register to receive":'đăng ký nhận',
  "you have registered to receive from":'Bạn đã đăng ký để nhận từ',
  "you received from":'Bạn đã nhận được từ',
  "register to received":'Đăng ký nhận quà',
  "receive date":'Ngày nhận',
  "register received":'Đăng ký nhận',
  "donation package":'Gói đóng góp',
  "name package":'Tên gói',
  "giving package":'Gói trao đi',
  "add giving option":'Thêm gói trao đi',
  "{type} will delete will be gone forever. Are you sure?":'{type} sẽ xóa sẽ biến mất vĩnh viễn. Bạn có chắc không?',
  "successfully registered":'Đăng ký thành công !',
  "you register to receive":'Bạn đăng ký để nhận',
  "km":'កម្ពុជា។',
  "fr":'Français',
  "ko":'한국인',
  "delete receiving package":'Xoá gói nhận quà ?',
  "are you sure you want to delete this receiving package":'Bạn có chắc chắn muốn xóa gói nhận quà này không ?',
  "select categories description":'Wishare đã nhóm các thông tin theo danh mục để giúp bạn tiếp cận thông tin tốt hơn và tránh bỏ sót thông tin. Hãy chọn những danh mục bạn thích bên dưới',
  "by using Wishare, you agree to our":'Bằng cách sử dụng Wishare, bạn đồng ý với',
  "donation event has the unfinished items":'Sự kiện quyên góp có các khoản đăng ký chờ xem xét',
  "search anythings in Wishare":'Tìm kiếm tất cả trên Wishare',
  "registered list":'DS đã đăng ký',
  "giving event has the unfinished items":'Sự kiện Trao đi có các khoản đăng ký chờ xem xét',
  "recruitment event has the unfinished items":'Tuyển dụng đang có các khoản đăng ký chờ xem xét',
  "responsibilities":'Trách nhiệm',
  "no name entered":'Chưa nhập tên',
  "search with recruitment code":'Tìm kiếm với mã tuyển dụng',
  "for admin":'Dành cho QTV',
  "your application will be sent to":'Đơn đăng ký của bạn sẽ được gởi đến',
  "click the Apply now button to complete and receive the apply code":'Nhấn vào nút "Ứng tuyển ngay" để hoàn tất và nhận mã ứng tuyển',
  "job-description":'Mô tả công việc',
  "job-requirements":'Yêu cầu công việc',
  "recruited":'Đã tuyển dụng được',
  "thank you for applying for this job":'Cám ơn bạn đã ứng tuyển công việc này.',
  "your application has been sent to the organization, please wait for a response from the organization":'Đơn ứng tuyển của bạn đã được gởi đến tổ chức, vui lòng chờ phản hồi từ tổ chức.',
  "that may take a few days, please wait":'Điều đó có thể mất vài ngày, bạn hãy đợi nhé.',
  "find account":'Tìm tài khoản',
  "choose account":'Chọn tài khoản có trên Wishare',
  "select receiver":'Chọn người nhận',
  "description recruitment code":'Đây là mã đăng ký làm tình nguyện viên của bạn, bạn hay theo dõi đơn đăng ký của mình ở phần Tình nguyện.',
  "you have just completed your registration as a volunteer for the program":'Bạn vừa hoàn tất đăng ký làm tình nguyện viên cho chương trình',
  "applied":'Đã ứng tuyển',
  "Thank you for signing up to be a volunteer of the program":'Xin cám ơn các bạn đã đăng ký làm tình nguyện viên của chương trình',
  "candidate list":'Danh sách ứng tuyển',
  "are you sure you want to reject this candidate":'Bạn chắc chắn muốn từ chối ứng viên này ?',
  "candidate":'Ứng viên',
  "currently an employee of the organization":'Hiện là thành viên của tổ chức/nhóm',
  "enter candidate information":'Nhập thông tin ứng viên',
  "enter information to give":'Nhập thông tin trao đi',
  "given by the organization":'Được trao đi bởi tổ chức',
  "enter donation information":'Nhập thông tin quyên góp',
  "edit candidate information":'Chỉnh sửa thông tin ứng viên',
  "administration panel":'Thanh quản trị cho QTV',
  "open/close recruitment":'Thời hạn tuyển dụng',
  "work type":'Hình thức làm việc',
  "create donation event":'Tạo chiến dịch gây quỹ',
  "create giving event":'Tạo chiến dịch trao đi',
  "organization type":'Hình thức tổ chức',
  "mobilization of manpower":'Huy động nhân lực',
  "communication":'Truyền thông',
  "are you ready for volunteer work":'Bạn đã sẵn sàng cho các công việc thiện nguyện ?',
  "change-password":'Đổi mật khẩu',
  "configure-content-by-language":'Cấu hình nội dung theo ngôn ngữ',
  "diamond":'Kim cương',
  "platinum":'Bạch kim',
  "gold":'Vàng',
  "silver":'Bạc',
  "bronze":'Đồng',
  "Display name can not be empty":'',
  "Number values can not be zero":'',
  "basic":'Cơ bản',
  "premium":'Cao cấp',
  "general operating model":'Mô hình hoạt động chung',
  "we make common rules so that everyone can understand and understand how we work":'Chúng tôi đưa ra quy tắc chung để mọi người có thể nẵm rõ và hiểu cách thức chúng tôi làm việc.',
  "project":'Dự án',
  "serving the mission aiming for social impact":'Phục vụ sứ mệnh, nhằm hướng đến tác động xã hội',
  "giving gifts to difficult circumstances":'Trao đi các phần quà cho hoàn cảnh khó khăn',
  "motto and how it works":'Phương châm và cách thức hoạt động',
  "we aim to connect benefactors, disadvantaged people, philanthropic organizations, who carry out a meaningful mission":'Chúng tôi hướng tới sự kết nối giữa những nhà hảo tâm, những hoàn cảnh khó khăn, những tổ chức thiện nguyện, những người thực hiện một sứ mệnh đầy ý nghĩa.',
  "connect social activities":'Kết nối hoạt động xã hội',
  "we create links between charities and those in need":'Chúng tôi tạo mối liên kết giữa những tổ chức thiện nguyện và những hoàn cảnh khó khăn cần sự giúp đỡ.',
  "support groups, organizations":'Hỗ trợ các hội, nhóm, tổ chức',
  "we provide support tools for associations, groups, organizations to be able to carry out their volunteering missions":'Chúng tôi cung cấp các công cụ hỗ trợ cho các hội, nhóm, tổ chức,...  có thể thực hiện sứ mệnh thiện nguyện của mình.',
  "Transparency - Sustainability - Impact":'Minh bạch - Bền vững - Tạo tác động',
  "this is our operating principle, every donation or donation is clear and transparent":'Đây là tôn chỉ hoạt động của chúng tôi, mọi đóng góp hoặc trao đi đều được rõ ràng và minh bạch nhất.',
  "beginer":'Mới bắt đầu',
  "intermediate":'Trung cấp',
  "proficient":'Chuyên nghiệp',
  "on working":'Đang làm việc',
  "not graduated":'Chưa tốt nghiệp',
  "not anymore":'Không còn nữa',
  "review the application form":'Xem lại đơn đăng ký',
  "application form":'Đơn xin ứng tuyển',
  "recruitment agency":'Đơn vị tuyển dụng',
  "cancel application":'Huỷ ứng tuyển',
  "do you want to change your personal information":'Bạn có muốn thay đổi thông tin cá nhân ?',
  "candidate profile":'Hồ sơ ứng viên',
  "you want to see candidate information":'Bạn muốn xem thông tin ứng viên',
  "human resources":'Nguồn nhân lực',
  "Transparency & Sustainability":'Minh bạch & Bền vững',
  "record project activities with the data of fundraising campaigns, giving":'Ghi lại các hoạt động của dự án với dữ liệu của các chiến dịch gây quỹ, trao đi',
  "projects":'Dự án',
  "how it works":'Cách hoạt động',
  "information collecting":'Thu thập thông tin',
  "candidates":'Danh sách Ứng viên',
  "you can not select than 2 languages":'Bạn không thể chọn nhiều hơn 2 ngôn ngữ',
  "Sharing and connecting between members in the community of social activities.":'Chia sẻ, kết nối giữa các thành phần trong cộng đồng hoạt động xã hội.',
  "Support social units in the work of carrying out their mission":'Hỗ trợ các đơn vị xã hội trong công việc thực hiện sứ mệnh của mình',
  "Creating transparency and sustainability in social activities":'Tạo tính minh bạch, bền vững trong các hoạt động xã hội',
  "are you sure that you want to cancel this":'Bạn có chắc chắn rằng bạn muốn hủy không?',
  "edit a poll":'Chỉnh sửa thăm dò ý kiến',
  "Change folder name":'Thay đổi tên thư mục',
  "Do you want delete this folder":'Bạn muốn xoá thư mục này ?',
  "This will permanently delete it, but its items will still be in your saved list.":'Thao tác này sẽ xóa nó vĩnh viễn, nhưng các mục của nó sẽ vẫn nằm trong danh sách đã lưu của bạn.',
  "export donation data":'Xuất dữ liệu đóng góp',
  "export as PDF":'Xuất file dạng PDF',
  "export giving data":'Xuất dữ liệu trao đi',
  "record and display the converted value":'Ghi nhận và hiển thị giá trị chuyển đổi',
  "with target":'Với mục tiêu',
  "approved list":'DS đã được duyệt',
  "rejected list":'DS đã từ chối',
  "export":'Xuất dữ liệu',
  "time sheets":'Thời gian làm việc',
  "view timesheet":'Xem thời gian làm việc',
  "other project":'Dự án khác',
  "select project":'Chọn dự án',
  "For example: ABC Campaign, ABC Organization,...":'Ví dụ: Chiến dịch ABC, Tổ chức ABC,...',
  "edit timesheet":'Chỉnh sửa thời gian làm việc',
  "member information":'Thông tin thành viên',
  "delete this information":'Xoá thông tin này ?',
  "are you sure you want to delete this working information":'Bạn chắc chắn muốn xoá thông tin làm việc này ?',
  "my profile":'Hồ sơ của tôi',
  "you are exporting campaign data":'Bạn đang xuất dữ liệu của chiến dịch',
  "package donation":'Gói đóng góp',
  "package giving":'Gói quà',
  "do you want to leave":'Bạn muốn rời đi ?',
  "you will no longer be a member of this organization or activity":'Bạn sẽ không còn là thành viên trong tổ chức hoặc hoạt động này nữa. Bạn chắc chắn ?',
  "the {type} has been deleted":'{type} đã bị xoá',
  "features group":'Nhóm tính năng',
  "beneficiary management":'Quản lý đơn vị thụ hưởng',
  "beneficiary-management":'Quản lý đơn vị thụ hưởng',
  "on moving":'Đang chuyển đi',
  "go to activity":'Chuyển đến hoạt động',
  "transfer beneficiary":'Chuyển đơn vị thụ hưởng',
  "note approve beneficiary":'Khi chuyển đơn vị thụ hưởng thì bạn sẽ không có quyền truy cập quản lý đơn vị thụ hưởng này nữa. Và hành động này không thể quay lại.',
  "cancel transfer":'Huỷ chuyển đi',
  "insights":'Thống kê',
  "recent campaigns":'Chiến dịch gần đây',
  "recent activity":'Hoạt động gần đây',
  "latest apply":'Ứng tuyển mới nhất',
  "latest register giving":'Đăng ký trao đi mới nhất',
  "latest register donation":'Đăng ký đóng góp mới nhất',
  "latest followed user":'Người dùng theo dõi mới nhất',
  "latest post":'Bài viết mới nhất',
  "campaign":'Chiến dịch',
  "top donation campaign":'Top chiến dịch quyên góp',
  "top giving campaign":'Top chiến dịch trao đi',
  "total giving":'Tổng trao đi',
  "top article":'Top bài viết',
  "top posts":'Top bài post',
  "total votes":'Tổng lượt thích',
  "referral information":'Thông tin đơn vị giới thiệu',
  "existing":'Đã có sẵn',
  "beneficiary referrals":'Người giới thiệu',
  "beneficiary-referrals":'Người giới thiệu',
  "followed":'đã theo dõi',
  "total views":'Tổng lượt xem',
  "total comments":'Tổng lượt bình luận',
  "total follows":'Tổng lượt theo dõi',
  "today":'Hôm nay',
  "yesterday":'Hôm qua',
  "last 7 days":'7 ngày trước',
  "this month":'Tháng này',
  "last month":'Tháng rồi',
  "latest donations":'Đóng góp mới nhất',
  "to the campaign":'đến chiến dịch',
  "part given from":'phần trao đi từ',
  "introduced by":'được giới thiệu bởi',
  "history of receiving and giving":'Lịch sử nhận trao đi',
  "account link":'Liên kết tài khoản',
  "activity link":'Liên kết hoạt động',
  "detail information":'Thông tin chi tiết',
  "link to donation":'Liên kết đến Quyên góp',
  "donation/giving":'Đóng góp / Trao đi',
  "interactive":'Tương tác',
  "accept transferring":'Chấp nhận điều chuyển',
  "reject transferring":'Từ chối điều chuyển',
  "was a member of":'đã là thành viên',
  "select an existing profile":'Chọn thông tin nhận đã có trên hệ thống',
  "latest articles":'Bài viết mới nhất',
  "date desc":'Ngày gần nhất',
  "name desc":'Theo thứ tự Z -> A',
  "value desc":'Giá trị giảm dần',
  "date asc":'Ngày cũ nhất',
  "name asc":'Theo thứ tự A -> Z',
  "value asc":'Giá trị tăng dần',
  "are you sure delete this message":'Bạn chắc chắn muốn xoá tin nhắn này ?',
  "related units": 'Đơn vị liên quan',
  "benefactor":'Nhà tài trợ',
  "user note":'Ghi chú của người dùng',
  "admin note":'Ghi chú của QTV',
  "filename":'Tên tập tin',
  "size":'Kích thước',
  "responding":'Đang hồi đáp',
  "register_to_donate":'đăng ký quyên góp',
  "attached image":'Đính kèm hình ảnh',
  "attached file":'Đính kèm tập tin',
  "file":'Tập tin',
  "money transferred":'Đã chuyển tiền',
  "total donated amount":'Tổng số tiền đóng góp',
  "description qr-code":'Xin quý ân đăng ký đóng góp tại bàn hoặc đăng ký trực tiếp tại link (mở bằng QR code bên cạnh)',
  "note (administrator)":'Ghi chú (dành cho quản trị viên)',
  "note (register)":'Ghi chú (dành cho người đăng ký)',
  "value display is not allowed":'Ẩn giá trị quyên góp',
  "hidden value":'Ẩn giá trị',
  "or you can copy the link of the page here to send it to your friends in other ways.":'hoặc bạn có thể sao chép liên kết của trang tại đây để gửi cho bạn bè của mình theo những cách khác.',
  "slide show (live)":'Trình chiếu đóng góp (LIVE)',
  "thanks for your donation":'Xin cám ơn quý ân nhân',
  "an anonymous donor":'Nhà tài trợ ẩn danh',
  "total amount contributed up to now":'Tổng số tiền đã đóng góp đến hiện tại',
  "thank you benefactors":'Xin cám ơn quý ân nhân',
  "donated amount":'Số tiền đóng góp',
  "activity list":'Danh sách hoạt động',
  "count desc":'Sắp xếp',
  "campaign list":'Danh sách chiến dịch',
  "event list":'Danh sách sự kiện',
  "email history thank you":'Lịch sử gởi thư cám ơn',
  "donors":'Nhà tài trợ',
  "recent donations":'Đóng góp gần đây',
  "top donations":'TOP đóng góp',
  "total donation events":'Tổng số chiến dịch quyên góp',
  "total donation value":'Tổng giá trị quyên góp',
  "total donors":'Tổng số nhà tài trợ',
  "top donation count":'Top số lần đóng góp',
  "top donation value":'Top giá trị đóng góp',
  "donor list":'Danh sách nhà tài trợ',
  "merge":'Hợp nhất',
  "top donors":'Top nhà tài trợ',
  "the system sent a thank you letter to":'Hệ thống đã gửi thư cảm ơn cho',
  "for the campaign donation":'Vì khoản quyên góp cho chiến dịch',
  "email thank you":'Thư cảm ơn',
  "create donor":'Tạo nhà tài trợ',
  "edit donor":'Chỉnh sửa nhà tài trợ',
  "recent givings":'Trao đi gần đây',
  "edit beneficiary":'Chỉnh sửa đơn vị thụ hưởng',
  "account not linked yet":'Chưa liên kết tài khoản',
  "account not linked yet des":'Bạn vẫn chưa liên kết đến tài khoản Wishare, bấm vào đây để liên kết.',
  "merge donor":'Hợp nhất nhà tài trợ',
  "selected donor":'Nhà tài trợ đã chọn',
  "merge with":'Sẽ chuyển và gộp thành',
  "beneficiary share":'Giới thiệu cho hoạt động',
  "total giving events":'Tổng chiến dịch trao đi',
  "total giving value":'Tổng giá trị chiến dịch',
  "total beneficiaries":'Tổng số đ/v thụ hưởng',
  "merge beneficiary":'Hợp nhất đ/v thụ hưởng',
  "staffs list":'Danh sách thành viên',
  "recent recruitments":'Tuyển dụng gần đây',
  "staff roster":'Bố trí công việc',
  "staff":'Thành viên',
  "collaborator":'Cộng tác viên',
  "intern":'Thực tập sinh',
  "employee type":'Loại thành viên',
  "edit staff":'Chỉnh sửa thông tin thành viên',
  "create staff":'Tạo thông tin thành viên mới',
  "display with anonymous":'Hiển thị với Nhà tài trợ ẩn danh',
  "create staff roster":'Bố trí công việc mới',
  "position placeholder":'Ví dụ: Vị trí hỗ trợ',
  "activity/organization":'Hoạt động / Tổ chức',
  "total staffs":'Tổng số thành viên',
  "total recruitment event":'Tổng số tuyển dụng',
  "total user register":'Tổng số người dùng đăng ký',
  "edit staff roster":'Chỉnh sửa bố trí công việc',
  "staff information":'Thông tin thành viên',
  "create timesheets":'Thêm chấm công mới',
  "edit timesheets":'Chỉnh sửa chấm công',
  "total time work":'Tổng thời gian làm việc',
  "top staff area":'Top khu vực & thành viên',
  "top staff time work":'Top thời gian đóng góp',
  "total hours":'Tổng số giờ',
  "address placeholder":'Ví dụ: Số 10 đường 10',
  "introduce to":'Giới thiệu đến',
  "find donor":'Tìm kiếm nhà tài trợ',
  "alias name placeholder":'Ví dụ: Nguyễn Văn ABC',
  "country placeholder":'Ví dụ: Việt Nam',
  "phone placeholder":'Ví dụ: 0987654321',
  "email placeholder":'Ví dụ: thudientu@email.com',
  "choose date placeholder":'Ví dụ: 01/01/2021',
  "top giving value":'Top giá trị trao đi',
  "top giving count":'Top số lần trao đi',
  "you choose channel":'Bạn chọn kênh',
  "channel post":'Bài viết của kênh',
  "total location":'Tổng số khu vực',
  "Click here to search for benefactors":'Bấm vào đây để tìm kiếm nhà tài trợ',
  "You can find a benefactor or add another benefactor":'Bạn có thể tìm thấy nhà tài trợ hoặc thêm mới một nhà tài trợ khác',
  "Click here to create a new one":'(Click vào đây để tạo mới)',
  "new donor":'Nhà tài trợ mới',
  "referral list":'Danh sách đơn vị giới thiệu',
  "find referral":'Tìm kiếm đơn vị giới thiệu',
  "not added yet":'Chưa bổ sung',
  "merge note description":'Hành động này không thể quay lại, bạn nên cân nhắc kỹ trước khi tiếp tục hành động này.',
  "find beneficiary":'Tìm kiếm đơn vị thụ hưởng',
  "share note description":'Hành động này sẽ chia sẻ quyền quản lý với đơn vị khác, bạn có chắc chắn điều đó.',
  "receiver name":'Tên người nhận',
  "register for":'Đăng ký cho',
  "Click here to find referrer information":'Bấm vào đây để tìm thông tin đơn vị giới thiệu',
  "You can find a referrer or add a new one":'Bạn có thể tìm thấy đơn vị giới thiệu hoặc thêm mới một đơn vị giới thiệu khác',
  "new beneficiary":'Đơn vị thụ hưởng mới',
  "Click here to find beneficiary information":'Bấm vào đây để tìm thông tin đơn vị thụ hưởng',
  "You can find a beneficiary or add another beneficiary":'Bạn có thể tìm thấy đơn vị thụ hưởng hoặc thêm mới một đơn vị thụ hưởng khác',
  "Click here to find an account":'Bấm vào đây để tìm tài khoản',
  "You can find the user\'s account and add":'Bạn có thể tìm thấy tài khoản của người dùng và thêm vào',
  "recent candidates":'Ứng tuyển gần đây',
  "recruitment list":'Danh sách tuyển dụng',
  "donation count":'Số lần đóng góp',
  "donation insights":'Thống kê quyên góp',
  "list of recipients":'Danh sách nhận trao đi',
  "giving insights":'Thống kê nhận trao đi',
  "share beneficiary":'Chia sẻ đơn vị thụ hưởng',
  "to shared":'Được chia sẻ',
  "this page is for organizations only create an organization":'Trang này chỉ dành cho các tổ chức hãy tạo một tổ chức',
  "Sign in for free to experience the features":'Đăng nhập miễn phí để trải nghiệm các tính năng',
  "Keep personalized tracking in the field of volunteering":'Lưu giữ cá nhân hóa các theo dõi trong lĩnh vực thiện nguyện.',
  "Join and update information from the community":'Tham gia và cập nhật thông tin từ cộng đồng.',
  "Keep track of contributions, participating activities":'Theo dõi các khoán đóng góp, các hoạt động tham gia.',
  "Find suggestions for suitable volunteer work":'Tìm gợi ý các công việc thiện nguyện phù hợp.',
  "create account":'Tạo tài khoản',
  "Create an account for your organization or charity ":'Tạo lập tài khoản cho tổ chức, hoạt động thiện nguyện của bạn. ',
  "The platform supports a free tool to help transform the number of philanthropic activities":'Nền tảng hỗ trợ công cụ miễn phí giúp chuyển đổi số hoạt động thiện nguyện.',
  "Features to interact with partners: fundraising, recruitment, giving, events, impact reporting of activities, of the organization":'Các tính năng tương tác với các đối tác: gây quỹ, tuyển dụng, trao đi, sự kiện, báo cáo tác động của hoạt động, của tổ chức.',
  "Organizational management features: manage donors, beneficiaries, human resources":'Các tính năng quản lý tổ chức: quản lý nhà tài trợ, bên thụ hưởng, nguồn nhân lực.',
  "extra information":'Thông tin thêm',
  "value placeholder":'Ví dụ: abc...',
  "datetime placeholder":'Ví dụ: 01/01/2001',
  "number placeholder":'Ví dụ: 012345...',
  "field name":'Nhập tên',
  "year of birth":'Năm sinh',
  "remember me":'Nhớ mật khẩu',
  "it received":'Đã nhận được',
  "clone item":'Tạo bản sao',
  "clone beneficiary":'Nhân bản đ/v thụ hưởng',
  "clone note description":'Với hành động này, bạn có thể chỉnh sửa các thông tin của đơn vị thụ hưởng nhưng không gây ảnh hưởng đến thông tin của tổ chức khác.',
  "this year":'Năm hiện tại',
  "last year":'Năm ngoái',
  "last 3 years":'3 năm đến hiện tại',
  "showing":'Hiển thị',
  "Donate to the campaign by cash or bank transfer":'Đóng góp cho chiến dịch bằng tiền mặt hoặc chuyển khoản',
  "Donate to the campaign with items":'Đóng góp cho chiến dịch bằng hiện vật',
  "Contributions in kind can be converted to the corresponding value at the time of donation":'Đóng góp bằng hiện vật có thể được quy đổi thành giá trị tương ứng tại thời điểm đóng góp',
  "Giving in kind can be converted into equivalent value at the time of giving":'Trao đi bằng hiện vật có thể quy đổi thành giá trị tương đương tại thời điểm trao đi',
  "Give to beneficiary by cash or bank transfer":'Trao cho đơn vị thụ hưởng bằng tiền mặt hoặc chuyển khoản',
  "Give the beneficiary with the items":'Trao cho đơn vị thụ hưởng bằng hiện vật',
  "reconnect":'Kết nối lại',
  "network":'Mạng',
  "network offline":'Mạng ngắt kết nối',
  "list of co-hosting":'Danh sách chủ quản',
  "list of co-owners":'Danh sách thành viên',
  "co-hosting":'Nhà đồng tổ chức',
  "do you want to leave the position of host of this campaign":'Bạn có muốn rời khỏi vị trí chủ quản của chiến dịch này ?',
  "co-owners":'Thành viên',
  "refused":'Bị từ chối',
  "do you want to decline the invitation":'Bạn có muốn từ chối lời mời ?',
  "host type":'Loại chủ quản',
  "owner type":'Loại thành viên',
  "staff list":'Danh sách thành viên',
  "related activities": 'Hoạt động có liên quan',
  "related giving events": 'Chiến dịch trao đi có liên quan',
  "related donation events": 'Chiến dịch quyên góp có liên quan',
  "related recruitments": 'Tuyển dụng có liên quan',
  "related events": 'Sự kiện có liên quan',
  "peer organization":'Đơn vị ngang hàng',
  "peer organization list":'Danh sách đơn vị ngang hàng',
  "total peer organization": 'Đơn vị ngang hàng',
  "has left":'đã rời khỏi',
  "total hosts":'Tổng số chủ quản',
  "total members":'Tổng số thành viên',
  "my host request":'Đơn vị thành viên',
  "my-host-request":'Đơn vị thành viên',
  "list of members":'Đơn vị thành viên',
  "activity of co-owners":'Hoạt động thành viên',
  "medals":'Kỷ niệm chương',
  "medal list":'Danh sách kỷ niệm chương',
  "medal manager":'Quản lý kỷ niệm chương',
  "medal summary":'Tổng kết kỷ niệm chương',
  "create medal":'Tạo kỷ niệm chương',
  "edit medal":'Chỉnh sửa kỷ niệm chương',
  "total staff-rosters":'Tổng số việc làm',
  "type desc":'Loại giảm dần',
  "type asc":'Loại tăng dần',
  "medal awards":'Trao kỷ niệm chương',
  "medal rewards":'Kỷ niệm chương đã nhận',
  "medal reward list":'Danh sách đã nhận',
  "give medal":'Trao kỷ niệm chương',
  "effect date":'Ngày trao',
  "medal award manager":'Kỷ niệm chương đã nhận',
  "pending givings":'Đăng ký nhận trao đi',
  "organizational structure":'Cơ cấu tổ chức',
  "operating":'Vận hành',
  "activities of member":'Hoạt động của thành viên',
  "list of medal":'Danh sách kỷ niệm chương',
  "level placeholder":'Ví dụ: 1-Kim cương, 2-Vàng, 3-bạc, ...',
  "staffs":'Nhân sự',
  "activity summaries":'Tóm tắt hoạt động',
  "donation summaries":'Tóm tắt quyên góp',
  "givings summaries":'Tóm tắt trao đi',
  "staffs summaries":'Tóm tắt nhân sự',
  "recruitment events":'Tuyển dụng',
  "organization unit reports":'Báo cáo của tổ chức',
  "{value1} / {value2}: Number of organizations / total number of member organizations":'{value1} / {value2}: Số lượng của tổ chức / tổng số lượng của các tổ chức thành viên',
  "contributions":'Lượt đóng góp',
  "waiting value":'Giá trị cần',
  "giving count":'Lượt trao đi',
  "spent my precious time at work":'đã dành thời gian quý báu của mình cho công việc',
  "We sincerely appreciate your dedication of time and effort to making this life better":'Chúng tôi chân thành biết ơn sự cống hiến thời gian và công sức của bạn để làm cuộc sống này tốt hơn.',
  "We would like to award this medal to mark your contribution":'Chúng tôi xin trao tặng kỷ niệm chương này để đánh dấu sự đóng góp của bạn.',
  "Wishing you always full of health, peace and enthusiasm. Sincerely thank you.":'Kính chúc bạn luôn tràn đầy sức khoẻ, bình an và nhiệt huyết. \nTrân trọng cám ơn.',
  "staff received":'Thành viên đã nhận',
  "donor received":'Nhà tài trợ đã nhận',
  "register list":'Danh sách đăng ký',
  "ML (blood unit)":'ML (đơn vị máu)',
  "choose organization":'Chọn tổ chức',
  "total events":'Tổng số sự kiện',
  "total activities":'Tổng số hoạt động',
  "total donations":'Tổng số lượt đóng góp',
  "total waiting value":'Tổng giá trị đăng ký',
  "total givings value":'Tổng giá trị trao đi',
  "total givings target":'Tổng mục tiêu trao đi',
  "total givings":'Tổng số lượt trao đi',
  "total collaborator":'Tổng số cộng tác viên',
  "total intern":'Tổng số thực tập sinh',
  "total staff":'Tổng số thành viên',
  "total volunteer":'Tổng số tình nguyện viên',
  "history medal rewards":'Lịch sử trao kỷ niệm chương',
  "list of staff roster":'Danh sách công việc',
  "donation of organization":'Đóng góp của tổ chức',
  "giving of organization":'Trao đi của tổ chức',
  "certifications":'Chứng nhận',
  "price (VND)":'Giá (VND)',
  "access rights":'Quyền truy cập',
  "contact list":'Danh sách liên hệ',
  "what is your organization name?":'Tổ chức của bạn tên gì ?',
  "sponsor":'Tài trợ',
  "disable on the personal page":'Ẩn trên trang cá nhân',
  "disable donor on page description":'Hành động này sẽ ẩn những thông tin liên quan đến tài trợ của bạn trên trang cá nhân',
  "disable beneficiary on page description":'Hành động này sẽ ẩn những thông tin liên quan đến thụ hưởng của bạn trên trang cá nhân',
  "disable work on page description":'Hành động này sẽ ẩn những thông tin liên quan đến công việc của bạn trên trang cá nhân',
  "import donation data":'Nhập dữ liệu đóng góp',
  "import":'Nhập dữ liệu',
  "you are importing campaign data":'Bạn đang nhập dữ liệu cho chiến dịch',
  "preview template":'Xem bản mẫu',
  "limit description":'Dung lượng tập tin không vượt quá',
  "template data":'Dữ liệu mẫu',
  "notes description":'Giải thích các cột trong tập tin mẫu',
  "campaign id code":'Mã ID của chiến dịch',
  "contributed date":'Ngày đóng góp',
  "donor\'s name":'Tên nhà đóng góp',
  "donor\'s owner id":'ID sở hữu của nhà tài trợ',
  "donation quantity":'Số lượng đóng góp',
  "donation unit":'Đơn vị đóng góp',
  "exchange value":'Giá trị quy đổi',
  "donation note":'Ghi chú đóng góp',
  "additional fields for information":'Các trường bổ sung cho thông tin',
  "beneficiary\'s name":'Tên đơn vị thụ hưởng',
  "beneficiary address":'Địa chỉ đơn vị thụ hưởng',
  "beneficiary\'s owner id":'ID sở hữu của đơn vị thụ hưởng',
  "giving quantity":'Số lượng trao đi',
  "giving unit":'Đơn vị trao đi',
  "giving note":'Ghi chú trao đi',
  "import giving data":'Nhập dữ liệu trao đi',
  "Extract via campaign url path":'Trích xuất qua đường dẫn URL chiến dịch',
  "For example":'\n Ví dụ',
  "Date is required":'Thông tin ngày bắt buộc',
  "Including 4 characters, capitalization, unique":'Bao gồm 4 ký tự, viết hoa, duy nhất',
  "Value must be YES or NO":'Giá trị phải là YES hoặc NO',
  "Value must be money, goods or blood":'Giá trị phải là "MONEY", "GOODS" hoặc "BLOOD"',
  "Number is required":'Thông tin số yêu cầu nhập',
  "Value must be male, female or null":'Giá trị phải là "male", "female" hoặc giá trị rỗng',
  "current size":'Kích thước hiện tại',
  "max size":'Kích thước tối đa',
  "total row":'Tổng số dòng',
  "success row":'Số dòng thành công',
  "row":'dòng',
  "upload file":'Nhập dữ liệu',
  "question from the campaign":'Câu hỏi từ chiến dịch',
  "START":'Bắt đầu',
  "FINISH":'Kết thúc',
  "node":'Điểm nút',
  "send a confirmation letter for the donation":'Gửi thư xác nhận cho đóng góp',
  "link page":'Liên kết trang',
  "list of partners":'Danh sách đối tác',
  "short name":'Tên ngắn gọn',
  "first name":'Họ',
  "last name":'Tên',
  "alternative email":'Thư điện tử (phụ)',
  "create partners":'Tạo đối tác',
  "edit partners":'Chỉnh sửa đối tác',
  "delete partner":'Xoá đối tác',
  "are you sure you want to delete this partner":'Bạn chắc chắn muốn xoá đối tác này ?',
  "total org members":'Thành viên',
  "total org partners":'Đối tác',
  "total org link":'Liên kết',
  "Volunteer":'Tình nguyện viên',
  "Administrator":'Quản trị viên',
  "Office staff":'Nhân viên văn phòng',
  "Part-time employee":'Nhân viên bán thời gian',
  "Assistant":'Trợ lý',
  "Director":'Giám đốc',
  "Supporter":'Hỗ trợ viên',
  "total other activities":'Tổng số hoạt động thành viên',
  "total active donation events":'Chiến dịch gây quỹ đang diễn ra',
  "total active giving events":'Chiến dịch trao đi đang diễn ra',
  "total recruitments":'Tổng số đợt tuyển dụng',
  "total incoming events": 'sự kiện sắp diễn ra',
  "input partner name":'Nhập tên đối tác',
  "select account wishare":'Chọn tài khoản Wishare',
  "total active recruitments events": 'Tuyển dụng đang diễn ra',
  "subcategories organization":'Danh mục phụ của tổ chức',
  "create subcategories organization":'Tạo mới danh mục phụ cho tổ chức',
  "update subcategories organization":'Cập nhật danh mục phụ cho tổ chức',
  "subcategories activity":'Danh mục phụ của hoạt động',
  "create subcategories activity":'Tạo mới danh mục phụ cho hoạt động',
  "update subcategories activity":'Cập nhật danh mục phụ cho hoạt động',
  "subcategories":'Danh mục phụ',
  "remove this":'Xoá mục này ?',
  "list of channels":'Danh sách kênh tin',
  "introduce channel":'Giới thiệu kênh tin',
  "short description about your channel":'Mô tả ngắn về kênh tin của bạn',
  "share discussion":'Chia sẻ thảo luận',
  "choose category":'Chọn danh mục',
  "parent of subcategory":'Danh mục cha',
  "hours":'giờ',
  "choose another Wishare subscription":'Chọn một gói đăng ký Wishare khác',
  "filter conditions by subcategory": 'Điều kiện lọc theo phân loại',
  "value to contribute": 'Giá trị cần đóng góp',
  "can giving": 'Có thể trao đi',
  "create recruitment campaign" : 'Tạo chiến dịch tuyển dụng',
  "got it":'Đã hiểu',
  "link url is invalid!":'Liên kết không tồn tại !',
  "Saving...":'Đang lưu ...',
  "Saved!":'Đã lưu !',
  "description question":'Mô tả câu hỏi',
  "type the answer you want here and select create":'Gõ câu trả lời mà bạn mong muốn vào đây và chọn "Tạo câu trả lời"',
  "create answer":'Tạo câu trả lời',
  "ex: 01/01/2021 - 12:00:00":'VD: 01/01/2021 - 12:00:00',
  "beneficiary_count":'Số đ/v thụ hưởng',
  "givings count":'Lượt trao đi',
  "transfer ownership":'Chuyển quyền sở hữu',
  "Please log in to Wishare to leave your comment in the post":'Vui lòng đăng nhập Wishare để để lại bình luận của bạn trong bài viết',
  "sanitary-food":'Vệ sinh thực phẩm',
  "at":'lúc',
  "posted on channel":'đã đăng trên kênh',
  "post at":'đã đăng lúc',
  "transfer of ownership":'Chuyển quyền sở hữu',
  "you definitely want to transfer ownership to someone else":'Bạn chắc chắn muốn chuyển quyền sở hữu cho người khác. Và hành động này không thể quay lại. Bạn có chắc chắn ?',

}
