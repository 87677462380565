import { eventModel } from 'apis/model'
import { getType } from 'apis/model/base'
import { baseItemSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import { ROOT_URL } from 'envs/_current/config'
import { copyLink } from 'helpers'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
} from 'react'
import { withProps } from 'recompose'
import { renderEmptyHolder } from 'views/MainPage/functions/renderEmptyHolder'
import { Null } from 'views/Shared'
import { WishareEntities } from './enums'
import { ContextActionTypes } from './factory/createContextMenu'
import { renderLoading } from './functions/renderers/renderLoading'
import wishareModel from './wishareModel'

export const EntityContext =
  React.createContext({})

export const EntityProvider = ({
  item,
  children,
}) => {
  const history = useHistory()

  const handleAction = useCallback(
    (action, payload) => {
      const params = payload || {}
      switch (action) {
        case ContextActionTypes.COPY:
          copyLink(
            `${ROOT_URL}${getLinkToDetail(
              item
            )}`
          )
          break
        case ContextActionTypes.SETTINGS:
          if (
            getType(item) ===
            WishareEntities.EVENT
          ) {
            history.push({
              pathname:
                eventModel.getLinkToSetting(
                  item
                ),
            })
          } else {
            history.push({
              pathname:
                wishareModel.getLinkToSetting(
                  item
                ),
            })
          }
          break
        case ContextActionTypes.CREATE_GIVING:
          break
        case ContextActionTypes.CREATE_DONATION:
          break
        default:
          break
      }
    },
    [item, history]
  )
  return (
    <EntityContext.Provider
      value={{ item, handleAction }}>
      {children}
    </EntityContext.Provider>
  )
}

const RenderDefaultItem = ({
  item,
}) => (
  <div className="flex w-full">
    {getTitle(item)}
  </div>
)

const RenderDefaultEntity = ({
  children,
  ...props
}) => (
  <SelectEntityItem
    schema={baseItemSchema}
    {...props}>
    {children}
  </SelectEntityItem>
)

export const renderDefaultMeta = ({
  count,
  links,
  total,
  action,
  per_page,
  current_page,
  total_pages,
}) => {
  const loaded_pages = Math.max(
    0,
    current_page - 1
  )
  const loaded_count =
    loaded_pages * per_page

  return (
    <Translate>
      {(t) => (
        <div
          className="PaginationMeta flex stickyTop1"
          style={{ top: '55px' }}>
          <div className="flex gap-2 text-sm rounded-lg background-200 opacity-90 px-3 py-1 truncate italic">
            <span>{t('showing')}</span>
            <span className="font-semibold text-color-000">
              {Number(
                loaded_count + count
              ).toString()}
            </span>
            <span>{t('of')}</span>
            <span className="font-semibold text-color-000">
              {total}
            </span>
            <span className="lowercase">
              {t('outcome')}
            </span>
          </div>
          {action}
        </div>
      )}
    </Translate>
  )
}

export const EntityPaginationMeta = ({
  data,
  renderMeta = renderDefaultMeta,
  ...props
}) => {
  const pagination =
    _.get(
      data,
      'response.data.meta.pagination'
    ) || {}
  return renderMeta({
    ...props,
    ...pagination,
  })
}

const EntityList = ({
  query,
  values,
  apiInfo,
  Header,
  refreshKey,
  renderItem,
  renderWrap,
  PaginationMeta,
  alwaysVisible = true,
  RenderItem = RenderDefaultItem,
  RenderEntity = RenderDefaultEntity,
  withWrapper = (child, callback) =>
    child,
  ...props
}) => {
  return (
    <LazyPagination
      query={query}
      values={values}
      apiInfo={apiInfo}
      Header={Header}
      refreshKey={refreshKey}
      alwaysVisible={alwaysVisible}
      renderLoading={renderLoading}
      PaginationMeta={PaginationMeta}
      renderItem={
        _.isFunction(renderItem)
          ? renderItem
          : (item, index) => (
              <RenderEntity
                key={index}
                item={item}
                defaultItem={item}>
                {withProps((item) => ({
                  item,
                }))(RenderItem)}
              </RenderEntity>
            )
      }
      renderEmpty={renderEmptyHolder}
      {...props}
      Wrapper={
        renderWrap
          ? ({ count, children }) =>
              renderWrap(
                children,
                Null,
                count
              )
          : undefined
      }
    />
  )
}

export default EntityList
