import _ from 'lodash'
import moment from "moment";

export default function getPredictStatus(
  item
) {
  if (_.has(item, 'status')) {
    const _status = _.get(item, 'status');

    if (_status === 1) {
      if (_.has(item, 'time_close')) {
        if (moment(_.get(item, 'time_close', moment().add(1, 'days'))).isBefore(moment())) {
          return 9;
        }
      }
    }
    return _status;
  }

  return _.get(item, 'status');
}
