import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { notEmpty } from 'views/Shared'
import { FlexCol } from '../../Templates/ItemTemplate'
import RecruitmentContext from '../RecruitmentContext'

const RecruitmentRequirement = () => {
  const t = useTranslate()
  const { recruitment } = useContext(
    RecruitmentContext
  )

  const {
    min_age,
    max_age,
    position_title,
    job_requirements,
    recruitment_type,
  } = recruitment || {}

  const requirements_state = useMemo(
    () =>
      isRawContentEmpty(
        job_requirements
      )
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(
                job_requirements
              )
            )
          ),
    [job_requirements]
  )

  if (_.isEmpty(recruitment)) {
    return null
  }

  return (
    <FlexCol className="space-y-3">
      {recruitment_type && (
        <FlexCol>
          <span className="text-xs text-color-400 font-light">
            {t('type of recruitment')}
          </span>
          <span className="text-color-000 font-semibold">
            {t(recruitment_type)}
          </span>
        </FlexCol>
      )}
      {_.every(
        [min_age, max_age],
        notEmpty
      ) && (
        <FlexCol>
          <span className="text-xs text-color-400 font-light">
            {t('age')}
          </span>
          <div className="flex space-x-2">
            <span className="text-color-000 font-semibold">
              {min_age && !max_age
                ? '>=' + min_age
                : min_age || ''}
            </span>
            {Boolean(
              max_age && min_age
            ) && <span>{'-'}</span>}
            <span className="text-color-000 font-semibold">
              {max_age && !min_age
                ? '<=' + max_age
                : max_age || ''}
            </span>
          </div>
        </FlexCol>
      )}
      {position_title && (
        <FlexCol>
          <span className="text-xs text-color-400 font-light">
            {t('position')}
          </span>
          <span className="text-color-000 font-semibold">
            {t(position_title)}
          </span>
        </FlexCol>
      )}
      {requirements_state && (
        <FlexCol>
          <span className="text-xs text-color-400 font-light">
            {t('detail requirement')}
          </span>
          <div className="px-2 border border-color-50 rounded-lg">
            <Renderer
              editorState={
                requirements_state
              }
            />
          </div>
        </FlexCol>
      )}
    </FlexCol>
  )
}

export default RecruitmentRequirement
