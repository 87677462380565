import { getType } from 'apis/model/base'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import Translate from 'modules/local/Translate'
import React from 'react'

export const renderSelectorLabel = (
  item
) => (
  <Translate>
    {(t) => (
      <div className="flex gap-2 items-center">
        <AppIcon
          size="small"
          item={item}
        />
        <div
          className="flex flex-col"
          style={{
            lineHeight: '1rem',
          }}>
          <div className="truncate text-sm font-medium">
            {getTitle(item)}
          </div>
          <div className="truncate text-xs italic text-color-500">
            {t(getType(item))}
          </div>
        </div>
      </div>
    )}
  </Translate>
)
