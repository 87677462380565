import { Input } from 'antd'
import { recruitment_update_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import { wishareClassNames } from 'views/Wishare/custom/wishareClassNames'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import {
  createControlledFormField,
  createNumberControlledInput,
} from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import RecruitmentContext from '../RecruitmentContext'
import CustomRichEditor from "../../custom/CustomRichEditor";

const formFields = [
  { name: 'job_requirements' },
  // { name: 'experience_requirements' },
  {
    name: 'min_age',
    defaultValue: 0,
  },
  {
    name: 'max_age',
    defaultValue: 0,
  },
]

const formSchema = [
  {
    title: 'job requirements',
    children: [
      {
        name: 'job_requirements',
        component:
          createControlledFormField({
            placeholder:
              'job requirements',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
  // {
  //   title: 'experience requirements',
  //   children: [
  //     {
  //       name: 'experience_requirements',
  //       component:
  //         createControlledFormField({
  //           maxLength: 500,
  //           showCount: true,
  //           placeholder:
  //             'experience requirements',
  //           Component: Input.TextArea,
  //         }),
  //     },
  //   ],
  // },
  {
    inline: true,
    className:
      wishareClassNames.inline_2,
    children: [
      {
        label: 'min age',
        name: 'min_age',
        component:
          createNumberControlledInput(),
      },
      {
        label: 'max age',
        name: 'max_age',
        component:
          createNumberControlledInput({
            max: 120,
          }),
      },
    ],
  },
]

const RecruitmentSettingJobRequirements =
  () => {
    const { recruitment } = useContext(
      RecruitmentContext
    )

    const validationSchema = undefined

    const recruitment_id = getId(
      recruitment
    )

    if (_.isEmpty(recruitment))
      return null

    return createEditableForm(
      formFields,
      formSchema
    )({
      item: recruitment,
      validationSchema,
      query: bindQueryParam({
        recruitment_id,
      }),
      apiInfo: recruitment_update_api,
    })
  }

export default RecruitmentSettingJobRequirements
