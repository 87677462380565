import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import React from 'react'

export const EventContext = React.createContext(
  {
    item: {},
    isAdmin: false,
    getProductChannelUrl: Null,
    contextMenu: null,
    setSelect: Null,
    handleSelect: {
      cancel: console.log.bind(
        null,
        null
      ),
      detail: console.log.bind(
        null,
        'detail'
      ),
      share: console.log.bind(
        null,
        'share'
      )
    }
  }
)

export const ModalLayout = ({
                              name,
                              children
                            }) => (
  <EventContext.Consumer>
    {({
        handleSelect,
        item
      }) => (
      <FullSideModalLayout
        right
        title={
          name || (
            <span className="font-semibold  flex-1 j">
              <div
                style={{
                  fontSize: '0.8em'
                }}
                className="text-color-300 capitalize">
                {item.title}
              </div>
            </span>
          )
        }
        onCancel={handleSelect.cancel}>
        {children}
      </FullSideModalLayout>
    )}
  </EventContext.Consumer>
)

export default EventContext
