import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {getFullAddress} from "../../../Shared";
import {GenderTypes, getProp} from "../../wishareFieldTypes";
import {Avatar} from "antd";
import getAvatar from "../../../../helpers/getAvatar";
import getTitle from "../../../../helpers/getTitle";
import {IoPerson} from "react-icons/io5";
import {useAppConfig} from "../../../../modules/local";

const BeneficiaryModalHeader = ({
  beneficiary,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const address = getFullAddress(_.get(beneficiary, 'location'))

  let gender = !!_.get(beneficiary, 'gender')
      ? getProp(
          Object.values(GenderTypes),
          'label'
      )(Number(_.get(beneficiary, 'gender')))
      : null
  gender = !!gender ? t(gender) : null

  const RowInformation = ({
    label,
    value,
    hiddenIfEmpty = true,
  }) =>
      !value && hiddenIfEmpty ? null : (
          <div className="flex items-baseline gap-2">
            <span className="text-xs text-color-400 italic font-light">
              {_.isString(label) ? `${t(label)}: ` : label}
            </span>
            {value ? (
                <span className="text-xs text-color-000 font-medium flex-1">
                  {value}
                </span>
            ) : (
                <span className="italic text-xs text-color-200 font-medium">
                  {t('not added yet')}
                </span>
            )}
          </div>
      )
  const {org_types = {}} = useAppConfig()

  return (
    <div className="border border-color-50 rounded-lg p-3 flex flex-col space-y-3">
      <div className="flex items-center gap-2 border border-color-org rounded-lg p-2">
        <Avatar
          size={30}
          src={_.get(beneficiary, 'organization.avatar')}
          className="flex flex-center background-100">
          <span>{_.first(_.get(beneficiary, 'organization.title'))}</span>
        </Avatar>
        <div className="flex flex-col">
            <div className="font-bold text-color-org leading-tight">
                {getTitle(_.get(beneficiary, 'organization'))}
            </div>
            <div className="text-xs text-secondary italic">
                {t(_.get(org_types, _.get(_.get(beneficiary, 'organization'), 'org_type', '900')))}
            </div>
        </div>
      </div>
      <div className="flex flex-col w-full flex-center gap-1 p-2">
        <Avatar
          size={60}
          src={getAvatar(beneficiary) || _.get(beneficiary, 'owner.avatar')}
          icon={<IoPerson size={30} className="text-color-400"/>}
          className="flex flex-center background-100"
        />
        <div className="flex flex-col flex-center">
          <div className="text-lg text-color-000 font-bold">{getTitle(beneficiary)}</div>
          <div className="flex gap-2 text-xs italic">
            <span className="text-color-300">{t(_.get(beneficiary, 'owner._type'))}</span>
            <span className="font-medium text-secondary">{_.get(beneficiary, 'owner.name')}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-3">
        <div className="uppercase text-sm font-medium text-color-300 border-b mb-2">
          {t('detail information')}
        </div>
        <div className="space-y-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
            {
              [
                {label: 'phone', value: _.get(beneficiary, 'phone_number')},
                {label: 'email', value: _.get(beneficiary, 'email')},
                {label: 'date of birth', value: _.get(beneficiary, 'date_of_birth')},
                {label: 'gender', value: gender},
              ].map((e,i) => !!e.value && (
                <RowInformation key={i} label={e.label} value={e.value}/>
              ))
            }
          </div>
          {
            [
              {label: 'address', value: address},
              {label: 'notes', value: _.get(beneficiary, 'notes')}
            ].map((e,i) => !!e.value && (
              <RowInformation key={i} label={e.label} value={e.value}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BeneficiaryModalHeader
