import { Modal } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React from 'react'
import { connect } from 'react-redux'
import { useSearchParam } from 'react-use'
import { branch } from 'recompose'
import { compose } from 'redux'
import { getLogin } from 'redux/selectors'
import Login from 'views/Auth/Login'
import { Null } from 'views/Shared'
import AppTextLogoIcon from '../components/icons/AppTextLogoIcon'
import ForgetPassword from '../views/Auth/ForgetPassword'
import { DelayRender } from '../views/Discovery/DelayRender'

export const ModalLayout = ({
  name,
  onCancel,
  children,
  width = 620,
  ...props
}) => (
  <NavigationContext.Consumer>
    {({ handleGoBack }) => (
      <Modal
        className="custom-modal"
        footer={null}
        width={width}
        title={name}
        {...props}
        visible={true}
        onCancel={
          onCancel || handleGoBack
        }
        maskClosable={false}
        destroyOnClose={true}>
        <div className="h-full md:h-auto lg:p-3">
          {_.isFunction(children)
            ? children(handleGoBack)
            : children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)
export const ModalForgetPassword = (
  props
) => {
  const translate = useTranslate()
  return (
    <ModalLayout
      name={translate(
        'recover login password'
      )}
      {...props}>
      <ForgetPassword />
    </ModalLayout>
  )
}

export const ModalLogin = ({
  onSuccess,
  ...props
}) => {
  const isSignUpDefault = _.get(
    props,
    'location.state.isSignUpDefault',
    false
  )
  return (
    <DelayRender time={1000}>
      <ModalLayout
        name={<AppTextLogoIcon />}
        {...props}>
        {(handleGoBack = Null) => (
          <Login
            isSignUpDefault={
              isSignUpDefault
            }
            onSuccess={() => {
              if (
                _.isFunction(onSuccess)
              ) {
                onSuccess()
              } else {
                handleGoBack()
              }
            }}
          />
        )}
      </ModalLayout>
    </DelayRender>
  )
}

export const ModalSignUp = (props) => {
  const type = useSearchParam('type')
  return (
    <DelayRender time={1000}>
      <ModalLayout
        name={<AppTextLogoIcon />}
        {...props}>
        <Login
          isSignUpDefault={true}
          initOrgType={type}
        />
      </ModalLayout>
    </DelayRender>
  )
}

export const createRequiredLogin = (
  FallBackComponent = ModalLogin
) =>
  compose(
    connect((state) => ({
      login: !!getLogin(state),
    })),
    branch(
      (props) => !props.login,
      (props) => (props) =>
        <FallBackComponent {...props} />
    )
  )
export const RequiredLogin =
  createRequiredLogin()(
    ({ children }) => children
  )
