import { Spin } from 'antd'
import { categories_getRelatedTags_api } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
} from 'react'
import CustomMenuHeader from '../../custom/CustomMenuHeader'
import { mapPathParam } from '../../functions/routerHelper'
import { renderHashTag } from '../../Tags/renderHashTag'
import CategoryContext from '../CategoryContext'

const CategoryRelatedTags = () => {
  const { category } = useContext(
    CategoryContext
  )

  if (_.isEmpty(category)) return null

  const id = _.get(category, 'id')

  const apiInfo =
    categories_getRelatedTags_api

  const cacheId = mapPathParam(
    apiInfo.path,
    { id }
  )

  return (
    <CustomLazyPaginationWrapper
      auto={false}
      sample={30}
      id={cacheId}
      key={cacheId}
      apiInfo={
        categories_getRelatedTags_api
      }
      query={{
        ':id': id,
      }}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Spin className="m-auto" />
        </div>
      )}
      renderItem={renderHashTag}
      renderEmpty={() => (
        <Translate>
          {(t) => (
            <EmptyHolder
              subTitle={t('empty')}
              title={null}
            />
          )}
        </Translate>
      )}
      renderWrap={(children) => (
        <div className="flex flex-col">
          <CustomMenuHeader title="related tags" />
          <div className="flex flex-wrap gap-1">
            {children}
          </div>
        </div>
      )}
    />
  )
}

export default CategoryRelatedTags
