import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import './DrawerInner.css'

export const DrawerInner = ({
  siderInner
}) => {
  const translate = useTranslate()
  return (
    <div
      className="flex flex-col DrawerInner"
      style={{
        minHeight: '100vh'
      }}>
      <div className="flex-1  py-4">
        {siderInner}
      </div>
      <div
        className="border-gray-300  background border-t sticky bottom-0 flex items-center px-4 font-semibold"
        style={{
          height: 40,
          fontSize: '0.8em'
        }}>
        {`${translate(
          'copyright'
        )} 2019 Wishare`}
      </div>
    </div>
  )
}
