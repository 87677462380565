import { getId } from 'apis/model/base'
import { createValue } from 'components/form/utils'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import {
  Null,
  renderSelf,
} from 'views/Shared'

const mapOptions = (
  source,
  getLabel = renderSelf,
  getValue = renderSelf
) =>
  source.map((item) => ({
    value: _.isString(item)
      ? item
      : getValue(item),
    label: _.isString(item)
      ? item
      : getLabel(item),
  }))

export const mapDefaultOptions = (
  source
) => {
  if (_.isEmpty(source)) return []
  return mapOptions(
    source,
    ({ label }) => label,
    ({ value }) => value
  )
}

export const mapStringOptions = (
  source
) => {
  if (_.isEmpty(source)) return []
  return mapOptions(
    source,
    renderSelf,
    renderSelf
  )
}

export const mapObjectValues = (
  source,
  getValue = (item = {}) => item.value
) => {
  if (!_.isArray(source)) return []
  return Array.from(source).map(
    (item) => getValue(item)
  )
}

export const mapEntityOptions = (
  source,
  params = {}
) => {
  if (_.isEmpty(source)) return []
  const {
    getValue = getId,
    getLabel = getTitle,
  } = params
  return mapOptions(
    source,
    getLabel,
    getValue
  )
}

export const mapSingleValue = (
  source,
  transform,
  connector = ','
) => {
  if (_.isEmpty(source))
    return undefined
  return Array.from(source || [])
    .map((item) =>
      transform
        ? transform(item)
        : getId(item)
    )
    .join(connector)
}

const createSelectEntityProps = ({
  name,
  value,
  options,
  onChange,
  selected = Null,
  mode = 'multiple',
  placeholder = 'select',
  mapFn = mapEntityOptions,
  ...rest
}) => {
  const _options = Array.from(
    mapFn(options) || []
  )

  return {
    name,
    mode,
    options: _options,
    onChange: (value) => {
      onChange(createValue(name, value))
    },
    value,
    placeholder,
    className: 'w-full',
    optionFilterProp: 'label',
    ...rest,
  }
}

export default createSelectEntityProps
