import {
  donor_deleteDonor_api,
  donor_getById_api,
} from 'apis'
import { getId } from 'apis/model/base'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import LoadingPage from 'views/LoadingPage'
import { DonorModalTypes } from 'views/MainPage/WorkSpace/components/DonorActionModal'
import {
  getResponseItem,
  Null,
} from 'views/Shared'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DonorDonationsModalContent from './components/DonorDonationsModalContent'

const GetDonorById = ({
  id,
  children,
}) => (
  <Async
    {...{
      apiInfo: donor_getById_api,
      query: bindQueryParam({
        id,
      }),
    }}>
    {({ response, isLoading }) =>
      _.isFunction(children)
        ? children({
            isLoading,
            donor:
              getResponseItem(response),
          })
        : children
    }
  </Async>
)

const DonorContextMenu = ({
  donor,
  Wrapper = 'div',
  setAction = Null,
  createContextOptions = (donor) => [],
  ...props
}) => {
  const t = useTranslate()

  const {
    handleAsyncAction: deleteDonor,
  } = useAsyncAction({
    apiInfo: donor_deleteDonor_api,
    onError: notifyOnError(t),
    onSuccess: () => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
  })

  const onMenuSelect = (key, donor) => {
    const donor_id = getId(donor)
    switch (key) {
      case DonorModalTypes.DONATION_LIST:
        setAction({
          type: key,
          value: donor_id,
        })
        break
      case DonorModalTypes.DELETE:
        const modal =
          showDeleteConfirmDialog({
            onOk: () => {
              deleteDonor(
                {},
                bindQueryParam({
                  id: donor_id,
                })
              )
              modal.destroy()
            },
            translate: t,
            onCancel: () =>
              modal.destroy(),
          })
        break
      default:
        break
    }
  }

  return (
    <Wrapper className="absolute right-0 top-0 px-2 py-1">
      <ItemContextMenu
        onMenuSelect={(key) =>
          onMenuSelect(key, donor)
        }
        items={createContextOptions(
          donor
        )}
      />
    </Wrapper>
  )
}

const useDonorContextMenu = ({
  createContextOptions = (donor) => [],
  renderModalHeader = (action, item) =>
    null,
}) => {
  const t = useTranslate()

  const [action, setAction] = useState({
    type: undefined,
    value: {},
  })

  const onCancel = () =>
    setAction({ type: undefined })

  const createContextModal = (type) => {
    switch (type) {
      case DonorModalTypes.DONATION_LIST:
        return (
          <GetDonorById
            id={action.value}>
            {({
              donor,
              isLoading = false,
            }) => (
              <FullSideModalLayout
                right={true}
                onCancel={onCancel}
                title={t(
                  'view detail'
                )}>
                {!!isLoading ? (
                  <LoadingPage />
                ) : (
                  <DonorDonationsModalContent
                    donor_id={
                      action.value
                    }
                    Header={() =>
                      renderModalHeader(
                        action,
                        donor
                      )
                    }
                  />
                )}
              </FullSideModalLayout>
            )}
          </GetDonorById>
        )
      default:
        break
    }
  }

  return {
    action,
    setAction,
    createContextModal,
    renderContextMenu: (donor) => (
      <DonorContextMenu
        donor={donor}
        setAction={setAction}
        createContextOptions={
          createContextOptions
        }
      />
    ),
  }
}

export default useDonorContextMenu
