import {
  Button,
  Card,
  Modal,
  Spin,
} from 'antd'
import { profile_updateProfileInfo_Api } from 'apis/profile'
import EmptyHolder from 'components/EmptyHolder'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import Pure from 'components/Pure'
import { Formik } from 'formik'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { IoCreateOutline } from 'react-icons/io5'
import { useToggle } from 'react-use'
import {
  compose,
  mapProps,
  nest,
} from 'recompose'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import CustomRichEditor, {
  CustomEditorRenderer,
} from 'views/Wishare/custom/CustomRichEditor'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'

const formSchema = (
  callback = Null
) => [
  {
    title: 'about',
    children: [
      {
        name: 'about',
        component: compose(
          mapProps(
            ({
              name,
              value,
              onChange,
            }) => ({
              name,
              value,
              onChange: (event) => {
                onChange(event)
                callback(event)
              },
              Wrapper: ({
                className,
                children,
              }) => (
                <div
                  style={{
                    backgroundColor:
                      'white',
                  }}
                  className={className}>
                  {children}
                </div>
              ),
            })
          )
        )(nest(Pure, CustomRichEditor)),
      },
    ],
  },
]

const AboutContent = ({
  name,
  value,
  onChange,
  onSuccess,
  handleSubmit = Null,
}) => {
  const t = useTranslate()
  const {
    isLoading,
    handleAsyncAction: onSubmit,
  } = useAsyncAction({
    apiInfo:
      profile_updateProfileInfo_Api,
    onSuccess: (result, data) => {
      onSuccess(data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const initialValues = {
    [name]: value,
  }

  return (
    <Spin spinning={!!isLoading}>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit(
          onSubmit
        )}
        initialValues={initialValues}>
        <FlexCol className="space-y-2">
          <FieldsFactory
            formSchema={formSchema(
              onChange
            )}
          />
          <FormActionBar
            background={false}
            hideResetButton={true}
          />
        </FlexCol>
      </Formik>
    </Spin>
  )
}

const ModalContent = ({
  name,
  value,
  onChange,
  readOnly = false,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  return (
    <Wrapper>
      <Card
        size="small"
        title={t('about')}
        style={{
          borderRadius: '0.5rem',
          border:
            '1px solid rgb(218 218 218)',
        }}
        headStyle={{
          fontStyle: 'italic',
          color:
            'var(--text-color-400)',
          fontWeight: '500',
          background:
            'var(--background-100)',
          borderBottom: 'none',
          borderRadius: '0.45rem 0.45rem 0 0',
        }}
        extra={
          readOnly ? undefined : (
            <Button
              size="small"
              ghost={true}
              type="primary"
              icon={<IoCreateOutline />}
              onClick={() =>
                toggle(true)
              }
              className="flex items-center space-x-1 rounded-md no-shadow no-text-shadow">
              <span className="text-xs">
                {t('edit')}
              </span>
            </Button>
          )
        }>
        {value ? (
          <CustomEditorRenderer
            key={value}
            rawValue={value}
            Wrapper={({ children }) => (
              <div className="px-2">
                {children}
              </div>
            )}
          />
        ) : (
          <EmptyHolder
            icon={null}
            style={{ border: 'none' }}
          />
        )}
      </Card>
      <Modal
        className="custom-modal"
        footer={null}
        closable={false}
        visible={isToggle}
        destroyOnClose={true}
        onCancel={() => toggle(false)}>
        <Pure
          key={value}
          input={[value]}>
          <AboutContent
            name={name}
            value={value}
            onSuccess={({
              response,
            }) => {
              toggle(false)
            }}
            handleSubmit={(submit) =>
              (values) => {
                submit(values)
              }}
          />
        </Pure>
      </Modal>
    </Wrapper>
  )
}

const EditProfileAbout = ({
  name,
  value,
  onChange,
  readOnly,
}) => (
  <ModalContent
    {...{
      name,
      value,
      onChange,
      readOnly,
    }}
  />
)

export default EditProfileAbout
