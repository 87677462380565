import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { createLazyComponent } from 'helpers/createLazyComponent'
import _ from 'lodash'
import {
  organizationPermissionCodes,
  permissionCodes,
} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {
  useContext,
} from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  useParams,
} from 'react-router-dom'
import {
  branch,
  compose,
  nest,
} from 'recompose'
import { getLogin } from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import LoadingPage from 'views/LoadingPage'
import { Null } from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import {
  createCheckPermissions,
  hasPermissions,
} from 'views/Wishare/factory/createCheckPermissions'
import { ModuleLayoutWrapper } from 'views/Wishare/factory/createModule'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import { WishareHeader } from '../MainHeader'
import WorkspaceActivities from './Activities/WorkspaceActivities'
import WorkspaceGivings from './Beneficiaries/WorkspaceGivings'
import WorkspaceBanner from './components/WorkspaceBanner'
import WorkspaceFeatures from './components/WorkspaceFeatures'
import WorkspaceDonations from './Donors/WorkspaceDonations'
import WorkspaceHome from './HomePage/WorkspaceHome'
import WorkspaceHosts from './Hosts/WorkspaceHosts'
import WorkspaceHumanResources from './HumanResources/WorkspaceHumanResources'
import WorkspaceOperating from './Medal/WorkspaceOperating'
import WorkspaceSetting from './Settings/WorkspaceSetting'
import WorkspaceContext, {
  WorkspaceProvider,
} from './WorkspaceContext'

const WorkspaceLandingPage =
  createLazyComponent(() =>
    import('./WorkspaceLandingPage')
  )

export const WorkSpaceActions =
  Object.freeze({
    HOME: 'dashboard',
    DONATIONS: 'donations',
    CHANNELS: 'channels',
    STRUCTURES: 'structures',
    SETTING: 'setting',
    ACTIVITIES: 'activities',
    GIVINGS: 'givings',
    HUMAN_RESOURCE_MANAGEMENT:
      'human-resources',
    OPERATING: 'operating',
  })

const routes = [
  {
    key: WorkSpaceActions.HOME,
    label: 'overview',
    component: WorkspaceHome,
  },
  {
    key: WorkSpaceActions.ACTIVITIES,
    label: 'activities',
    component: WorkspaceActivities,
  },
  {
    key: WorkSpaceActions.DONATIONS,
    label: 'donations',
    component: WorkspaceDonations,
    required_permissions: [
      organizationPermissionCodes.organizationManageDonation,
    ],
  },
  {
    key: WorkSpaceActions.GIVINGS,
    label: 'givings',
    component: WorkspaceGivings,
    required_permissions: [
      organizationPermissionCodes.organizationManageGiving,
    ],
  },
  {
    key: WorkSpaceActions.HUMAN_RESOURCE_MANAGEMENT,
    label: 'human resources',
    component: WorkspaceHumanResources,
    required_permissions: [
      organizationPermissionCodes.organizationManageRecruitment,
    ],
  },
  {
    key: WorkSpaceActions.STRUCTURES,
    label: 'organizational structure',
    component: WorkspaceHosts,
    required_permissions: [
      organizationPermissionCodes.organizationManageStructure,
    ],
  },
  {
    key: WorkSpaceActions.OPERATING,
    label: 'operating',
    component: WorkspaceOperating,
  },
  {
    key: WorkSpaceActions.SETTING,
    label: 'setting',
    component: WorkspaceSetting,
  },
].map(
  ({
    key,
    required_permissions = [],
    component,
    ...route
  }) => ({
    key,
    ...route,
    required_permissions,
    component: required_permissions
      ? createCheckPermissions(
          required_permissions
        )(component)
      : component,
  })
)

const Workspace = () => {
  const pathParams = useParams()
  const { isSm } = useContext(
    LayoutContext
  )

  const { organization } = useContext(
    WorkspaceContext
  )

  const isReady = _.has(
    organization,
    'available_actions'
  )

  const permissions = useContext(
    PermissionContext
  )

  return (
    <ModuleLayoutWrapper
      Header={WishareHeader}
      visibleLeftDrawer={[
        WorkSpaceActions.HOME,
        WorkSpaceActions.STRUCTURES,
        WorkSpaceActions.OPERATING,
        WorkSpaceActions.DONATIONS,
        WorkSpaceActions.SETTING,
        WorkSpaceActions.ACTIVITIES,
        WorkSpaceActions.GIVINGS,
        WorkSpaceActions.HUMAN_RESOURCE_MANAGEMENT,
      ].includes(
        _.get(
          pathParams,
          Queries.ACTION
        )
      )}>
      <DefaultTemplate
        renderMenu={Null}>
        {isSm && <WorkspaceBanner />}
        {isReady ? (
          <WorkspaceFeatures
            routes={routes}
            isDisabled={({
              required_permissions,
            }) =>
              !hasPermissions(
                permissions,
                required_permissions
              )
            }
            defaultAction={
              WorkSpaceActions.HOME
            }
          />
        ) : (
          <LoadingPage />
        )}
      </DefaultTemplate>
    </ModuleLayoutWrapper>
  )
}

export default compose(
  connect((state) => {
    const login = getLogin(state)
    const primary_org = _.get(
      login,
      'primary_org'
    )
    return {
      primary_org,
      [permissionCodes.canEdit]:
        Boolean(
          !_.isEmpty(
            _.get(login, 'primary_org')
          )
        ),
    }
  }),
  branch(
    ({ primary_org }) =>
      _.isEmpty(primary_org),
    () => () => <WorkspaceLandingPage />
  ),
  branch(
    (props) =>
      !_.get(
        props,
        permissionCodes.canEdit
      ),
    () => () =>
      (
        <Redirect
          to={staticPaths.start}
        />
      )
  )
)(nest(WorkspaceProvider, Workspace))
