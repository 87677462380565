import classNames from 'classnames'
import React from 'react'
import Translate from '../../../../modules/local/Translate'
import {IoFlowerOutline} from "react-icons/io5";

export const renderDonationDetailTotal =
  (donationItem, index) => {
    return (
      <Translate key={index}>
        {(t) => (
          <div
            className={classNames(
              'flex items-start space-x-2 rounded-lg px-3 py-1 background-100',
              donationItem.className
            )}>
            <div className="flex-1 items-start space-x-1 text-sm text-color-100">
              {[
                <span className="font-medium">
                  {
                    t(donationItem.item_name)
                  }
                </span>,
                <span className="gap-1">
                  (
                  <span>
                    {Number(
                      donationItem.total_donation_count
                    ).toLocaleString()}
                  </span>
                  <span className="ml-1">
                    {
                      <IoFlowerOutline/>
                    }
                  </span>
                  )
                </span>,
              ].map((e, index) => (
                <React.Fragment
                  key={index}>
                  {e}
                </React.Fragment>
              ))}
            </div>

            <div>
              {
                <div className="text-color-000 font-semibold">
                  {[
                    Number(
                      donationItem.total_donation_qtty
                    ).toLocaleString(),
                    t(
                      donationItem.unit_name
                    ),
                  ].join(' ')}
                </div>
              }
            </div>
          </div>
        )}
      </Translate>
    )
  }
