import React from 'react';

export const WishareRibbonSvg = ({fill = "#f184b4",...props}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			viewBox="0 0 360.8 480"
			height={30}
			width={30}
			{...props}
		>
			<path
				fill={fill}
				d="M296.3 32.7c-41-13.3-76.9-8.8-78.1 71.6-.9 60.1 20.1 156.1 214.6 354.6 1.2 1.2 2.4 2.2 3.6 3.3h-.3c-2.1 2.6-6 8.4-8.1 11.1-26.4 31.1-58.8 29.5-81.8-.2C177.4 245.8 177 126.4 189.4 76.5c9.9-39.6 52.5-80.1 99-50.1z"
				transform="translate(-75.6 -16)"
			></path>
			<path
				fill={fill}
				d="M215.7 32.7c41-13.3 76.9-8.8 78.1 71.6.9 60.1-20.1 156.1-214.6 354.6-1.2 1.2-2.3 2.2-3.6 3.3h.3c2 2.6 6 8.4 8.1 11.1 26.4 31.1 58.8 29.5 81.8-.2C334.5 245.8 335 126.4 322.6 76.5c-9.9-39.6-52.5-80.1-99-50.1z"
				transform="translate(-75.6 -16)"
			></path>
		</svg>
	);
};
