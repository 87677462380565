import {
  Avatar,
  Input,
  Result,
} from 'antd'
import { getId } from 'apis/model/base'
import { givingEventSchema } from 'apis/schema'
import FormActionBar from 'components/form/FormActionBar'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
} from 'react'
import { Redirect } from 'react-router-dom'
import {
  compose,
  mapProps,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import { paths } from 'views/MainPage/contains'
import { Null } from 'views/Shared'
import { givingApi } from 'views/Wishare/apis'
import { withModalLayout } from 'views/Wishare/configs'
import AntdConfigWrapper from 'views/Wishare/custom/AntdConfigWrapper'
import PhotoField from 'views/Wishare/custom/PhotoField'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import { wishareFieldTypes } from 'views/Wishare/wishareFieldTypes'
import { WishareNotFound } from 'views/Wishare/WishareNotFound'
import GivingContext from '../GivingContext'
import EditGivingOptionItemFields, {
  EditGivingOptionItemProvider,
} from './EditGivingOptionItemFields'
import DynamicFields from './GivingDyanmicFields'

const formFields = [
  { name: 'photo' },
  { name: 'title' },
  { name: 'description' },
  { name: 'photo_preview' },
]

const formSchema = [
  {
    title: 'name package',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            maxLength: 500,
            rows: 5,
            showCount: true,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
  {
    title: 'photo',
    children: [
      {
        name: 'photo',
        component: compose(
          mapProps(
            ({
              name,
              value,
              form,
              onChange,
            }) => {
              const values = _.get(
                form,
                'values'
              )
              return {
                name,
                value,
                values,
                onChange,
                Wrapper: ({
                  children,
                }) => (
                  <FlexCol
                    style={{
                      minHeight: 150,
                    }}>
                    {children}
                  </FlexCol>
                ),
              }
            }
          )
        )(PhotoField),
      },
    ],
  },
  {
    title: 'content',
    children: [
      {
        name: 'giving_event_option_items',
        component:
          createControlledFormField({
            edit: true,
            Component: DynamicFields,
            Item: withProps({
              listName:
                'giving_event_option_items',
            })(
              EditGivingOptionItemFields
            ),
            withValues: ({
              giving_type,
              target_unit_name,
            }) => ({
              giving_type,
              target_unit_name,
            }),
            Wrapper: ({
              children,
              giving_type,
              target_unit_name,
            }) => (
              <EditGivingOptionItemProvider
                type_cd={giving_type}
                type_cds={
                  wishareFieldTypes
                    .giving.types
                }
                prefixTitle="item"
                getGroup={(source) =>
                  _.get(
                    source,
                    'type_cds'
                  ) || []
                }
                target_unit_name={
                  target_unit_name
                }>
                {children}
              </EditGivingOptionItemProvider>
            ),
          }),
      },
    ],
  },
]

const FormContent = ({
  giving,
  currentOption,
  Wrapper = 'div',
  onCancel = Null,
  ...props
}) => {
  const t = useTranslate()

  const id = getId(giving)

  const isSm = useContext(LayoutContext)

  const initialized = useCallback(
    ({ ...values }) => {
      const { option_items } =
        currentOption || {}

      const {
        giving_type,
        target_unit_name,
      } = giving || {}

      return {
        ...values,
        giving_type,
        target_unit_name,
        giving_event_option_items:
          option_items || [{}],
      }
    },
    [giving]
  )

  const validationSchema = yupShape({
    title: requiredString(t),
    description:
      requiredString(t).nullable(),
  })

  if (_.every([id], _.isEmpty)) {
    return (
      <Redirect to={paths.homePath} />
    )
  }

  const apiInfo = currentOption
    ? givingApi.giving_events_editGivingOption_api
    : givingApi.giving_events_addGivingOption_api

  const onSuccess = ([
    __,
    { response },
  ]) => {
    // const newItem = getResponseItem(response)
    onCancel()
  }

  const onError = notifyOnError(t)

  const onPreSubmit = ({
    giving_type,
    target_unit_name,
    giving_event_option_items,
    ...values
  }) => {
    const _giving_event_option_items =
      Array.from(
        giving_event_option_items || []
      ).map(
        ({
          unit_name,
          option_qtty,
          ...rest
        }) => ({
          unit_name,
          option_qtty,
          item_name: unit_name,
          option_value: option_qtty,
          ...rest,
        })
      )
    return {
      ...values,
      giving_event_option_items:
        JSON.stringify(
          _giving_event_option_items
        ),
    }
  }

  const option_id = getId(currentOption)

  const content = createEditableForm(
    formFields,
    formSchema
  )({
    apiInfo,
    onError,
    onSuccess,
    initialized,
    onPreSubmit,
    validationSchema,
    item: currentOption,
    query: bindQueryParams([
      {
        id,
      },
      { option_id },
    ]),
    ActionBar: () => <FormActionBar />,
  })

  const title = getTitle(giving)

  const avatar = _.get(
    giving,
    'owner.avatar'
  )
  const owner = _.get(
    giving,
    'owner.title'
  )

  return (
    <Wrapper className="space-y-3 py-3 max-w-full mx-auto">
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl mb-3 mt-10">
        {t('giving package')}
      </div>
      <div className="flex flex-col mb-6 p-3 border rounded-lg">
        <span className=" text-sm font-semibold text-color-300 tracking-wide mb-1">
          {t('owner')}
        </span>
        <div className="flex items-center font-bold">
          <Avatar
            src={avatar}
            className="flex flex-center background-100"
            size={40}>
            <span className="capitalize font-normal text-color-300">
                {_.first(title)}
            </span>
          </Avatar>
          <div className="flex flex-col flex-1 mx-3">
            <span className="text-base md:text-lg text-secondary max-lines-1">
              {title}
            </span>
            <span className="text-xs font-medium text-color-300 italic">
              {owner}
            </span>
          </div>
        </div>
      </div>
      {content}
    </Wrapper>
  )
}

const AccessDenied = () => (
  <Translate>
    {(t) => (
      <Result
        status="403"
        title={t('access denied')}
      />
    )}
  </Translate>
)

const CreateGivingOptionForm = ({
  ...props
}) => {
  const { giving: item } = useContext(
    GivingContext
  )

  const { id, currentOption } =
    _.get(props, 'location.state') || {}

  const giving = useSelectEntities(
    id,
    givingEventSchema,
    item
  )

  if (_.isEmpty(giving)) {
    return <WishareNotFound />
  }

  const ModalForm = _.get(
    giving,
    'edit'
  )
    ? FormContent
    : AccessDenied

  const Component = withModalLayout(
    ModalForm,
    {
      giving,
      currentOption,
      ...props,
    }
  )

  return (
    <AntdConfigWrapper>
      <Pure>
        <Component />
      </Pure>
    </AntdConfigWrapper>
  )
}

export default createRequiredLogin()(
  CreateGivingOptionForm
)
