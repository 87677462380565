import {
  Descriptions,
  Input,
  Spin,
} from 'antd'
import { getId } from 'apis/model/base'
import classNames from 'classnames'
import { OriginalObjectSelector } from 'components/form/fields/ObjectSelectField'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { JSONViewer } from 'envs/ForDevelop'
import {
  Formik,
  FormikConsumer,
  useFormikContext,
} from 'formik'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { withProps } from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {
  getResponseItem,
  getResponseItems,
  Null,
  renderOwnChild,
} from 'views/Shared'
import {
  activityApi,
  beneficiaryApi,
} from 'views/Wishare/apis'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/Wishare/factory/createValidationSchema'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { renderSelectorLabel } from '../functions/renderSelectorLabel'
import BeneficiaryMetaHeader from './BeneficiaryMetaHeader'

const DefaultWrapper = ({
  style,
  children,
  className,
}) => (
  <div
    style={style}
    className={classNames(
      'space-y-3 py-3 mt-8',
      className
    )}>
    {children}
  </div>
)

const WishTransferSelector = ({
  name,
  value,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const { values, setValues } =
    useFormikContext()

  const { activity_id } = values || {}

  const {
    response,
    isLoading,
    handleAsyncAction: searchActivity,
  } = useAsync({
    query: bindQueryParam({
      id: activity_id,
    }),
    onError: notifyOnError(t),
    apiInfo:
      activityApi.activities_fetchOtherActivities_gets_api,
  })

  const options = useMemo(() => {
    const items = Array.from(
      getResponseItems(response)
    )
    if (_.isEmpty(items)) return []
    return items.map((item) => {
      const params = _.pick(
        item,
        'id',
        'name',
        '_type',
        'title',
        'avatar',
        'idname',
        'description'
      )
      return {
        ...params,
        label:
          renderSelectorLabel(item),
        value: getId(item),
      }
    })
  }, [response])

  const defaultOption = _.find(
    options,
    { value: getId(value) || value }
  )

  const [, onSearch] =
    useDebounceSearch({
      timeout: 500,
      callback: (keyword) =>
        searchActivity({
          values: {
            keyword,
          },
        }),
    })

  return (
    <Wrapper>
      <Spin spinning={!!isLoading}>
        <OriginalObjectSelector
          name={name}
          bordered={false}
          options={options}
          showSearch={true}
          onSearch={onSearch}
          label={t('activity')}
          onChange={(value) => {
            setValues({
              ...values,
              [name]: value,
            })
          }}
          value={_.get(
            defaultOption,
            'value'
          )}
          listHeight={400}
          filterOption={false}
        />
      </Spin>
    </Wrapper>
  )
}

const formFields = [
  'title',
  'description',
  'target_activity',
  'locations',
  'referrals',
]

const formSchema = [
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component:
          createControlledFormField({
            placeholder: 'title',
          }),
      },
    ],
  },
  {
    title: 'beneficiary information',
    children: [
      {
        name: 'meta',
        component: withProps(
          ({ form = {} }) => ({
            ..._.pick(
              form.values,
              formFields
            ),
          })
        )(BeneficiaryMetaHeader),
      },
    ],
  },
  {
    title: 'go to activity',
    children: [
      {
        name: 'target_activity_id',
        component:
          createControlledFormField({
            Component:
              WishTransferSelector,
          }),
      },
    ],
  },
  {
    title: 'description',
    children: [
      {
        name: 'description',
        component:
          createControlledFormField({
            rows: 3,
            placeholder: 'description',
            Component: Input.TextArea,
          }),
      },
    ],
  },
]

const BeneficiaryTransferModalContent =
  ({
    beneficiary_id,
    activity_id,
    onCancel = Null,
    onSubmit = Null,
    Wrapper = DefaultWrapper,
  }) => {
    const t = useTranslate()

    const handleSubmit = ({
      locations,
      referrals,
      activity_id,
      ...values
    }) => {
      onSubmit(values)
    }

    const { response, isLoading } =
      useAsync({
        apiInfo:
          beneficiaryApi.beneficiary_getById_api,
        query: bindQueryParam({
          id: beneficiary_id,
        }),
      })

    const item =
      getResponseItem(response)

    if (!!isLoading) {
      return <LoadingPage />
    }

    if (_.isEmpty(item)) {
      return null
    }

    const initialValues = {
      activity_id,
      ..._.pick(item, formFields),
    }

    return (
      <Wrapper>
        <Formik
          validationSchema={yupShape({
            title: requiredString(t),
            target_activity_id:
              requiredString(t),
          })}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          <div className="w-full space-y-3">
            <Translate>
              {(t) => (
                <div className="text-center text-xl font-bold uppercase text-color-000">
                  {t(
                    'transfer beneficiary'
                  )}
                </div>
              )}
            </Translate>
            <FormikConsumer>
              {({ values }) => (
                <JSONViewer
                  value={values}
                />
              )}
            </FormikConsumer>
            <FieldsFactory
              formSchema={formSchema}
            />
            <Descriptions>
              <Descriptions.Item
                label={
                  <span className="font-semibold">
                    {t('note')}
                  </span>
                }>
                {t(
                  'note approve beneficiary'
                )}
              </Descriptions.Item>
            </Descriptions>
            <FormActionBar
              background={false}
              submitText="transfer"
            />
          </div>
        </Formik>
      </Wrapper>
    )
  }

export default BeneficiaryTransferModalContent
