import { renderSelf } from 'views/Shared'
import { errorNotify } from './createNotification'

const onError = (
  error,
  errorObj = {
    error_code: 'System error!',
    error: '-1',
    message: 'System error!',
  },
  translate = renderSelf
) => {
  errorNotify(translate, {
    message:
      typeof errorObj.error_code ===
      'string'
        ? errorObj.error_code
        : JSON.stringify(
            errorObj.error_code ||
              errorObj.message
          ),
    description:
      typeof errorObj.error === 'string'
        ? errorObj.error
        : JSON.stringify(
            errorObj.error
          ),
  })
}

export const notifyOnError =
  (translate) => (error, errorObj) =>
    onError(error, errorObj, translate)
