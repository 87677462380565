import {feed_fetchFeedByContainer_Api} from 'apis'
import {getId, getType,} from 'apis/model/base'
import InputBoardForm from 'components/Feed/InputBoard/InputBoardForm'
import Pure from 'components/Pure'
import _ from 'lodash'
import {permissionCodes} from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {useContext, useMemo,} from 'react'
import {nest} from 'recompose'
import {renderEmptyHolder} from 'views/MainPage/functions/renderEmptyHolder'
import {renderFeedItem} from 'views/MainPage/functions/renderFeedItem'
import EntityList from 'views/Wishare/EntityList'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import ActivityContext from '../ActivityContext'
import {Null} from 'views/Shared'

const ActivityNews = ({
                        Wrapper = 'div',
                        sample,
                        Footer = Null,
                      }) => {
  const {activity} = useContext(
    ActivityContext
  )

  const [id, prop] = [
    getId(activity),
    getType(activity),
  ]

  const apiInfo =
    feed_fetchFeedByContainer_Api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':prop', prop),
      ].join('/'),
    [id, prop, apiInfo]
  )

  if (_.isEmpty(activity)) return null

  const {available_actions} = activity

  return (
    <Wrapper className="space-y-3">
      {
        !!!sample && (
          <PermissionProvider
            permissions={{
              [permissionCodes.canPost]:
                _.get(
                  available_actions,
                  permissionCodes.canPost
                ),
            }}>
            <InputBoardForm
              container={{id, type: prop}}
            />
          </PermissionProvider>
        )
      }
      <EntityList
        sample={sample}
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          {id},
          {prop},
        ])}
        renderItem={renderFeedItem}
        RenderEntity={React.Fragment}
        renderEmpty={renderEmptyHolder}
        renderWrap={(
          child,
          __,
          count
        ) => (
          <div className="space-y-3">
            {child}
            {count ? <Footer/> : null}
          </div>
        )}
      />
    </Wrapper>
  )
}

export default nest(Pure, ActivityNews)
