import { Avatar } from 'antd'
import { getType } from 'apis/model/base'
import { hostSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import { Link } from 'react-router-dom'
import {
  LoadingWrapper,
  notEmpty,
  renderOwnChild,
} from 'views/Shared'
import ActivityContext from 'views/Wishare/Activity/ActivityContext'
import DonationContext from 'views/Wishare/Donation/DonationContext'
import {
  fromWishareEntity,
  WishareEntities,
} from 'views/Wishare/enums'
import GivingContext from 'views/Wishare/Giving/GivingContext'
import OrganizationContainerContext from 'views/Wishare/Organization/OrganizationContainerContext'
import RecruitmentContext from 'views/Wishare/Recruitment/RecruitmentContext'
import { OrgType } from '../../Organization/components/OrgType'

const renderHostByItem = (
  { host, owner, host_name, ...rest },
  index
) => {
  const title =
    host_name || getTitle(host)
  const type = getType(host)
  return (
    <Translate key={index}>
      {(t) => (
        <div className="flex items-center mb-2">
          <div>
            <Avatar
              className="background-100 uppercase font-semibold"
              src={_.get(
                host,
                'avatar'
              )}>
              {_.first(title)}
            </Avatar>
          </div>
          <div className="flex flex-col">
            <Link
              className="text-color-org font-bold ml-2 max-lines-1"
              to={getLinkToDetail(
                host
              )}>
              {title}
            </Link>
            <div className="text-xs font-normal text-color-500 italic px-2">
              {type ===
              'organization' ? (
                <OrgType item={host} />
              ) : (
                t(type)
              )}
            </div>
          </div>
        </div>
      )}
    </Translate>
  )
}

const createHostList =
  (type, Context) =>
  ({
    id_field = 'id',
    host_field = 'hosts',
    Wrapper = renderOwnChild,
  }) => {
    const t = useTranslate()

    const context = useContext(Context)

    const { contextName } =
      fromWishareEntity(type)

    const entity = _.get(
      context,
      contextName
    )

    const items =
      _.get(entity, host_field) || []

    if (_.isEmpty(items)) return null

    const hostIds = items.map((item) =>
      _.get(item, id_field)
    )

    return (
      <Wrapper>
        <SelectEntityItem
          item={hostIds}
          schema={[hostSchema]}
          defaultItem={items}>
          {(list = []) => (
            <LoadingWrapper
              isLoading={_.isEmpty(
                list
              )}>
              <div className="flex flex-col">
                <span className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                  {t('hosts')}
                </span>
                {list
                  .filter(notEmpty)
                  .map(
                    renderHostByItem
                  )}
              </div>
            </LoadingWrapper>
          )}
        </SelectEntityItem>
      </Wrapper>
    )
  }

export const DonationHosts =
  createHostList(
    WishareEntities.DONATION,
    DonationContext
  )

export const GivingHosts =
  createHostList(
    WishareEntities.GIVING,
    GivingContext
  )

export const ActivityHosts =
  createHostList(
    WishareEntities.ACTIVITY,
    ActivityContext
  )

export const OrganizationHosts =
  createHostList(
    WishareEntities.ORGANIZATION,
    OrganizationContainerContext
  )
export const RecruitmentHosts =
  createHostList(
    WishareEntities.RECRUITMENT,
    RecruitmentContext
  )
