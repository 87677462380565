import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import ActivityContext from './ActivityContext'

const ActivityLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose(
  notFoundOnError(
    ActivityContext.Consumer
  )
)(ActivityLayout)
