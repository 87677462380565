import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Switch,
} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import { AsyncFormActionBar } from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import {
  Null,
  renderSelf,
} from 'views/Shared'
import { deepTranslate } from '../../Shared'
import '../../Shared/styles/FormList.css'
import FormListHeader from '../custom/FormListHeader'
import {
  createControlledCreatableReactSelect,
  createControlledReactSelect,
} from '../custom/SelectDataSource'
import { createControlledFormField } from './createFormField'
import {
  mapObjectValues,
  mapStringOptions,
} from './createSelectEntityProps'

export const answer_types = {
  text: {
    label: 'text',
    value: 'text',
  },
  radio: {
    label: 'radio',
    value: 'radio',
  },
  checkbox: {
    label: 'checkbox',
    value: 'checkbox',
  },
  textarea: {
    label: 'textarea',
    value: 'textarea',
  },
  boolean: {
    label: 'boolean',
    value: 'boolean',
  },
}

export const answer_type_options =
  Object.values(answer_types)

export const optional_answer_types = [
  answer_types.radio.value,
  answer_types.checkbox.value,
]

const FormValues = ({
  fieldName,
  children,
}) => {
  const form = Form.useFormInstance()
  const values =
    form.getFieldValue(fieldName)
  return children({
    values,
  })
}

const requiredRule = (
  translate = renderSelf
) => [
  {
    required: true,
    message: translate(
      'required field'
    ),
  },
]

const formSchema = [
  {
    name: 'answer_type',
    label: 'answer type',
    withForm: ({ translate }) => ({
      rules: requiredRule(translate),
    }),
    component:
      createControlledReactSelect({
        withProps: ({
          name,
          value,
          translate = renderSelf,
        }) => {
          return {
            name,
            options:
              answer_type_options.map(
                deepTranslate(translate)
              ),
            getOptionLabel: ({
              label,
            }) => translate(label),
            value: _.get(
              answer_types,
              value
            ),
          }
        },
        placeholder: 'answer type',
      }),
  },
  {
    name: 'title',
    label: 'title',
    withForm: ({ translate }) => ({
      rules: requiredRule(translate),
    }),
    component:
      createControlledFormField({
        className: 'FormList',
        placeholder: 'title',
      }),
  },
  {
    name: 'description',
    label: 'description',
    component:
      createControlledFormField({
        className: 'FormList',
        placeholder: 'description question',
      }),
  },
  {
    name: 'answer_options',
    label: 'answer options',
    isInvisible: ({ answer_type }) =>
      !optional_answer_types.includes(
        answer_type
      ),
    component:
      createControlledCreatableReactSelect(
        {
          withProps: ({
            name,
            label,
            value,
            onChange = Null,
            translate = renderSelf,
          }) => ({
            value: mapStringOptions(value),
            placeholder: (
                <span className="text-color-500 italic">{translate('type the answer you want here and select create')}</span>
            ),
            onChange: (array = []) => {
              onChange(
                createValue(
                  name,
                  mapObjectValues(array)
                )
              )
            },
          }),
          isMulti: true,
        }
      ),
  },
]

const QuestionGroup = ({
  index,
  children,
  onRemove,
  defaultValue,
  onChange = Null,
  prefixTitle = '',
}) => {
  const t = useTranslate()

  const title = [
    t(prefixTitle),
    String(index + 1),
  ].join(': ')

  return (
    <div className="p-2 border rounded space-y-3">
      <FormListHeader
        title={title}
        onRemove={onRemove}
      />
      <div className="flex flex-1 gap-2 items-center">
        <Switch
          onChange={onChange}
          defaultChecked={Number(
            defaultValue
          )}
        />
        <span>{t('mandatory')}</span>
      </div>
      {children}
    </div>
  )
}

const QuestionItem = ({
  index,
  remove,
  onChange = Null,
  ...props
}) => {
  const t = useTranslate()

  const currentItem =
    _.get(props, 'value') || {}

  const { mandatory = 0 } = currentItem

  const triggerChanges = useCallback(
    (values = {}) => {
      onChange({
        ...currentItem,
        ...values,
      })
    },
    [onChange, currentItem]
  )

  const handleChange = useCallback(
    (event) => {
      const { name, value } =
        event.target
      triggerChanges({ [name]: value })
    },
    [triggerChanges]
  )

  useEffect(() => {
    triggerChanges({
      mandatory,
    })
  }, [])

  const formFields = useMemo(() => {
    return (
      <FormValues fieldName="collection_questions">
        {({ values: list = [] }) => {
          const values =
            _.get(list, `[${index}]`) ||
            {}

          return formSchema.map(
            ({
              name,
              label,
              isInvisible = Null,
              withForm = () => {},
              withProps = () => {},
              component: Component,
              ...rest
            }) => {
              const onChange =
                handleChange

              const invisible =
                isInvisible(
                  values || {}
                )

              if (invisible) return null
              const value = _.get(
                values,
                name
              )
              return (
                <Form.Item
                  key={name}
                  name={[index, name]}
                  {...withForm({
                    name,
                    index,
                    translate: t,
                  })}>
                  <FieldDecorator
                    label={label}
                    hasError={false}>
                    <Component
                      {...{
                        name,
                        index,
                        label,
                        value,
                        values,
                        onChange,
                      }}
                      {...withProps({
                        name,
                        onChange,
                      })}
                      {...rest}
                    />
                  </FieldDecorator>
                </Form.Item>
              )
            }
          )
        }}
      </FormValues>
    )
  }, [t, index, handleChange])

  return (
    <QuestionGroup
      index={index}
      onRemove={remove}
      defaultValue={mandatory}
      prefixTitle="question"
      onChange={(value) => {
        triggerChanges({
          mandatory: Number(value),
        })
      }}>
      {formFields}
    </QuestionGroup>
  )
}

const CustomQuestions = ({
  name,
  value = [],
  onChange = Null,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [form] = Form.useForm()

  return (
    <Wrapper className="space-y-3">
      <Form
        form={form}
        autoComplete="off"
        onValuesChange={(
          changedValues,
          values
        ) => {
          onChange(
            createValue(
              name,
              _.get(values, name)
            )
          )
        }}>
        <Form.List
          name={name}
          initialValue={value}>
          {(
            fields,
            { add, remove },
            { errors }
          ) => (
            <React.Fragment>
              {fields.map(
                (field, index) => (
                  <Form.Item
                    {...field}
                    key={field.key}>
                    <QuestionItem
                      index={index}
                      remove={() =>
                        remove(
                          field.name
                        )
                      }
                    />
                  </Form.Item>
                )
              )}
              <Form.Item>
                <Button
                  type="primary"
                  className="rounded-lg no-shadow no-text-shadow"
                  onClick={() => add()}
                  icon={
                    <PlusOutlined />
                  }>
                  {t('add')}
                </Button>
                <Form.ErrorList
                  errors={errors}
                />
              </Form.Item>
            </React.Fragment>
          )}
        </Form.List>
        <AsyncFormActionBar
          validate={form.validateFields}
        />
      </Form>
    </Wrapper>
  )
}

const createCustomQuestions = (props) =>
  createControlledFormField({
    Component: CustomQuestions,
    ...props,
  })

export default createCustomQuestions
