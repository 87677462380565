import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { givingApi } from 'views/Wishare/apis'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import GivingEventItem from 'views/Wishare/Giving/components/GivingEventItem'
import GivingEntity from 'views/Wishare/Giving/GivingEntity'
import ActivityContext from '../ActivityContext'

const ActivityGivingList = ({
  Wrapper = 'div',
}) => {
  const { activity: item } = useContext(
    ActivityContext
  )

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const apiInfo =
    givingApi.giving_events_getItemsByOwner_gets_api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':type', type),
      ].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { type },
        ])}
        RenderEntity={GivingEntity}
        RenderItem={GivingEventItem}
      />
    </Wrapper>
  )
}

export default ActivityGivingList
