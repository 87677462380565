import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import { renderEmptyHolder } from 'views/MainPage/functions/renderEmptyHolder'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { feed_fetchFeedByContainer_Api } from '../../../../apis'
import UserContext from '../UserContext'

const UserNews = ({
  Wrapper = 'div',
}) => {
  const { user: item } = useContext(
    UserContext
  )

  const [id, prop] = [
    getId(item),
    getType(item),
  ]

  const apiInfo =
    feed_fetchFeedByContainer_Api

  const lookupKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':prop', prop),
      ].join('/'),
    [id, prop, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([
          { id },
          { prop },
        ])}
        renderItem={renderFeedItem}
        RenderEntity={React.Fragment}
        renderEmpty={renderEmptyHolder}
      />
    </Wrapper>
  )
}

export default UserNews
