import classNames from 'classnames'
import FieldsFactory from 'components/form/FieldsFactory'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import withTranslate from 'modules/local/withTranslate'
import React, { useMemo } from 'react'
import {
  compose,
  fromRenderProps,
  withProps,
} from 'recompose'
import {
  renderFalse,
  Visible,
} from 'views/Shared'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  CountryPicker,
  renderLocationCascade,
} from 'views/Wishare/User/functions/renderLocationCascade'

const renderFieldWrapper =
  (title, isRequired = false) =>
  ({ children }) =>
    (
      <Translate>
        {(t) => (
          <div className="flex flex-col justify-center ">
            {
              <label
                className={classNames(
                  'horizontal-form-label text-sm text-color-300',
                  !!isRequired &&
                    'required-label'
                )}>
                {_.isString(title)
                  ? `${t(title)}: `
                  : title}
              </label>
            }
            <div className="flex-1">
              {children}
            </div>
          </div>
        )}
      </Translate>
    )

const createFormSchema = ({
  isRequired = true,
  isDisabled = renderFalse,
  isInvisible = renderFalse,
}) =>
  [
    {
      invisible: isInvisible(
        'beneficiary_name'
      ),
      children: [
        {
          name: 'beneficiary_name',
          Wrapper:
            renderFieldWrapper('name'),
          hideError: true,
          component:
            createControlledFormField({
              placeholder: 'name',
            }),
        },
      ],
    },
    {
      children: [
        {
          name: 'beneficiary_address',
          Wrapper:
            renderFieldWrapper(
              'address'
            ),
          hideError: true,
          component:
            createControlledFormField({
              placeholder: 'address',
            }),
        },
      ],
    },
    {
      children: [
        {
          name: 'beneficiary_country_id',
          Wrapper:
            renderFieldWrapper(
              'country'
            ),
          hideError: true,
          component: compose(
            withProps({
              withChangedValues: (
                values
              ) => ({
                location: [, , ,],
                beneficiary_province_id:
                  undefined,
                beneficiary_district_id:
                  undefined,
                beneficiary_ward_id:
                  undefined,
              }),
            })
          )(CountryPicker),
        },
      ],
    },
    {
      invisible:
        isInvisible('location'),
      children: [
        {
          name: 'location',
          Wrapper:
            renderFieldWrapper(
              'location'
            ),
          hideError: true,
          component: compose(
            withTranslate,
            fromRenderProps(
              LayoutContext.Consumer,
              ({ isSm }) => ({ isSm })
            )
          )(({ name, form, value }) => {
            const {
              values,
              setValues,
            } = form || {}
            return renderLocationCascade(
              {
                name,
                defaultValue: value,
                onChange: (event) => {
                  const {
                    name,
                    value,
                  } = event.target
                  const [
                    beneficiary_province_id,
                    beneficiary_district_id,
                    beneficiary_ward_id,
                  ] = Array.from(
                    value || [, , ,]
                  )
                  setValues({
                    ...values,
                    [name]: value,
                    beneficiary_province_id,
                    beneficiary_district_id,
                    beneficiary_ward_id,
                  })
                },
                country_id: _.get(
                  form,
                  'values.beneficiary_country_id'
                ),
              }
            )
          }),
        },
      ],
    },
  ].filter(Visible)

const BeneficiaryRequiredFields = ({
  Wrapper = 'div',
  isRequired = true,
}) => {
  const { values } = useFormikContext()

  const requiredValues = _.pick(
    values,
    [
      'location',
      'beneficiary',
      'beneficiary_name',
      'beneficiary_country_id',
      'beneficiary_province_id',
      'beneficiary_district_id',
      'beneficiary_ward_id',
      'beneficiary_address',
    ]
  )

  const formSchema = useMemo(() => {
    return createFormSchema({
      isRequired,
      isInvisible: (name) =>
        _.some(
          [
            [
              'beneficiary_name',
            ].includes(name) &&
              !_.isEmpty(
                requiredValues.beneficiary
              ),
            ['location'].includes(
              name
            ) &&
              Number(
                requiredValues.beneficiary_country_id
              ) !== 237,
          ],
          (value) => !!value
        ),
    })
  }, [
    isRequired,
    requiredValues.beneficiary,
    requiredValues.beneficiary_country_id,
  ])

  return (
    <Pure
      input={[
        requiredValues.beneficiary,
        requiredValues.beneficiary_country_id,
      ]}>
      <Wrapper>
        <FieldsFactory
          formSchema={formSchema}
        />
      </Wrapper>
    </Pure>
  )
}

export default BeneficiaryRequiredFields
