import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import classNames from "classnames";

const TableWrapper = ({
                        children,
                        headers = [],
                      }) => {
  const t = useTranslate()
  const content = useMemo(
    () =>
      Array.from(headers).map(
        (title, index) => (
          <th
            key={index}
            style={{
              textAlign: 'center',
            }}
            className="p-2 border text-sm font-medium text-color-000">
            {t(title)}
          </th>
        )
      ),
    [headers]
  )
  return (
    <table className="w-full">
      <thead>
      <tr>{content}</tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  )
}

export const renderReportTableWrapper =
  (headers) => (child, __, count) =>
    (
      <TableWrapper headers={headers}>
        {child}
      </TableWrapper>
    )

export const renderReportTable =
  ({fields = []}) =>
    (item, index) =>
      (
        <tr
          key={index}
          style={{
            textAlign: 'center',
          }}>
          {Array.from(fields).map(
            (
              {
                name,
                value_path, className,
                value2_path, className2,
                render = (value, data) => value,
              },
              index
            ) => (
              <td
                key={index}
                className="px-2 py-1 border text-sm"
                style={{
                  textAlign: index === 0 ? 'left' : 'inherit',
                  fontWeight: index === 0 ? '600' : '400',
                  textTransform: index === 0 ? '' : 'uppercase',
                }}>
                <div className="space-x-1">
                    <span className={classNames("font-bold", className)}>
                        {
                            index !== 0 ?
                                Number(render(_.get(item, value_path), item)).toLocaleString()
                                : render(_.get(item, value_path), item)
                        }
                    </span>
                    {
                        _.get(item, value2_path) >= 0 && (
                            <>
                                <span className="text-color-300">|</span>
                                <span className={classNames("text-color-400 font-light", className2)}>
                                    {Number(render(_.get(item, value2_path), item)).toLocaleString()}
                                </span>
                            </>
                        )
                    }

                </div>
              </td>
            )
          )}
        </tr>
      )
