import ErrorBoundary from 'components/error/ErrorBoundary'
import Null from 'components/NullComponent'
import _ from 'lodash'
import React from 'react'
import AmChartStateless from '../AmChart/components/AmChartStateless'
import ChartWrapper from '../ChartWrapper/ChartWrapper'

export const SunburstChartStateless = ({
  header = Null,
  item,
  CWrapper = ChartWrapper,
  ...props
}) => {
  return (
    <ErrorBoundary
      fallbackComponent={() => {
        return (
          <div className="w-full h-full flex justify-center items-center font-bold text-color-300 text-2xl">
            Error
          </div>
        )
      }}>
      <div
        style={{
          minHeight: 360,
          height: 0,
        }}
        className="flex-1 flex flex-col">
        <AmChartStateless
          item={item}
          setChartWrap={() => null}
          {...props}
        />
      </div>
    </ErrorBoundary>
  )
}

export const SunburstChart = ({
  maxLevel = 5,
  chartInfo,
  ...props
}) => {
  const level = _.defaultTo(maxLevel, 0)

  let item = _.cloneDeep(chartInfo)
  item.chart_source = 'AmChart'
  item.chart_type = 'SunburstChart'
  item.subscribe_required = 0
  item.data_empty = 0
  item.version = Date.now()
  item._type = 'chart'
  item.params = {
    seriesTemplates: level
      ? new Array(level)
          .fill(true)
          .map((__, index) => ({
            [index]: _.omitBy(
              {
                hiddenInLegend:
                  index > 0,
                labels: {
                  inside: false,
                  template: {
                    inside: false,
                    // fill: '#000',
                    // text: '{name}',
                    // truncate: true,
                    // bent: true,
                  },
                },
              },
              _.isUndefined
            ),
          }))
          .reduce(
            (prev, current) => ({
              ...prev,
              ...current,
            }),
            {}
          )
      : undefined,
  }

  return (
    <SunburstChartStateless
      item={item}
      CWrapper={ChartWrapper}
      {...props}
    />
  )
}

export default React.memo(SunburstChart)
