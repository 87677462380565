import { donor_getDonorsOfTheOwner_Api } from 'apis'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { IoIosList } from 'react-icons/io'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import { DonorModalTypes } from 'views/MainPage/WorkSpace/components/DonorActionModal'
import { renderDonor } from 'views/MainPage/WorkSpace/Donors/renderDonor'
import { Null } from 'views/Shared'
import useDonorContextMenu from 'views/Wishare/Donor/useDonorContextMenu'
import { DonorsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import DonorModalHeader from './DonorModalHeader'

const DonorsByOwner = ({
  owner_id,
  owner_type,
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
  ...props
}) => {
  const t = useTranslate()

  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const {
    action = {},
    setAction = Null,
    renderContextMenu,
    createContextModal = Null,
  } = useDonorContextMenu({
    renderModalHeader: (
      action,
      item
    ) => (
      <DonorModalHeader donor={item} />
    ),
    createContextOptions: (donor) => [
      {
        key: DonorModalTypes.DONATION_LIST,
        label: 'view detail',
        icon: (
          <IoIosList
            size={16}
            className="text-color-300"
          />
        ),
      },
    ],
  })

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
          donor_getDonorsOfTheOwner_Api,
        query: bindQueryParams([
          { id: owner_id },
          { prop: owner_type },
        ]),
        renderItem: renderDonor({
          showAccount: false,
          showOrg: true,
          renderContextMenu,
        }),
        RenderEntity: React.Fragment,
      },
      withKey: ({ sortBy, keyword }) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      query_params: filter_actions,
      dependencies: [
        owner_id,
        owner_type,
      ],
      sortHeader: t('donor list'),
      renderFilter: Null,
      AdvancedFilter: Null,
      SortByWidget: DonorsSortBy,
      ...props,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="donors" />
      {pager_widget}
      {createContextModal(action.type)}
    </Wrapper>
  )
}

export default DonorsByOwner
