import { organization_Edit_Api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import {
  useCallback,
  useContext,
} from 'react'
import { mapProps } from 'recompose'
import { PhotoArrayUpload } from 'views/Wishare/custom/PhotoField'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import OrganizationContainerContext from 'views/Wishare/Organization/OrganizationContainerContext'

const imageHolders = Array.from(
  Array(5).keys()
).map((__, index) => ({
  index,
  name: `photo_${Number(index)}`,
  preview: `photo_${Number(
    index
  )}_preview`,
}))

const formFields = [{ name: 'photos' }]

const formSchema = [
  {
    children: [
      {
        name: 'photos',
        component: mapProps(
          ({
            name,
            form,
            onChange,
          }) => ({
            name,
            onChange,
            values: _.get(
              form,
              'values',
              {}
            ),
            slots: imageHolders,
          })
        )(PhotoArrayUpload),
      },
    ],
  },
]

const OrganizationSettingPhoto = () => {
  const { organization } = useContext(
    OrganizationContainerContext
  )

  const id = getId(organization)

  const validationSchema = undefined

  const initialized = useCallback(
    (values) => ({
      ...values,
      ...imageHolders.reduce(
        (prev, { preview, index }) => ({
          ...prev,
          [preview]: _.get(
            values,
            `photos[${index}]`
          ),
        }),
        {}
      ),
    }),
    []
  )

  const onPreSubmit = ({
    photos,
    ...values
  }) =>
    _.omit(
      values,
      ...imageHolders.map(
        ({ preview }) => preview
      )
    )

  if (_.isEmpty(organization))
    return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    validationSchema,
    item: organization,
    query: bindQueryParam({
      id,
    }),
    initialized,
    onPreSubmit,
    apiInfo: organization_Edit_Api,
  })
}

export default OrganizationSettingPhoto
