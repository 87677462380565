import { Spin } from 'antd'
import { post_dashboard_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import React, {
  useContext,
} from 'react'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import {
  fromWishareEntity,
  WishareEntities,
} from 'views/Wishare/enums'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import wishareModel from 'views/Wishare/wishareModel'
import createEntityListByCategory from '../../factory/createEntityListByCategory'
import createPagerFooter from '../../factory/createPagerFooter'
import CategoryContext from '../CategoryContext'

const Footer = ({ pathname }) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click "See more" to see this full information',
  })

const GroupWrapper = ({
  title,
  children,
}) => (
  <div className="flex flex-col space-y-3">
      <Translate>
          {(t) => (
              <div className="uppercase font-bold text-sm border-b-2 border-primary text-primary">
                  {title ? t(title) : null}
              </div>
          )}
      </Translate>
    <div className="space-y-3">
      {children}
    </div>
  </div>
)

const Component = ({
  item,
  type,
  pathname,
}) =>
  createEntityListByCategory(
    item,
    type,
    {
      sample: 2,
      auto: false,
      renderEmpty: () => (
        <EmptyHolder />
      ),
      renderWrap: (children) => (
        <GroupWrapper
          title={fromWishareEntity(
            type,
            'displayName'
          )}>
          {children}
          <Footer pathname={pathname} />
        </GroupWrapper>
      ),
    }
  )

const CategoryGeneral = ({
  Wrapper = 'div',
}) => {
  const { category: item } = useContext(
    CategoryContext
  )

  const [id, prop] = [
    getId(item),
    getType(item),
  ]

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-6 pb-16">
      <LazyPagination
        sample={2}
        auto={false}
        apiInfo={post_dashboard_Api}
        query={bindQueryParams([
          { id },
          { prop },
        ])}
        values={{ cate_id: id }}
        renderItem={renderFeedItem}
        renderLoading={() => (
          <div
            className="w-full flex flex-col flex-1 justify-center items-center"
            style={{
              minHeight: '70vh',
            }}>
            <Spin className="m-auto" />
          </div>
        )}
        renderWrap={(children) => (
          <GroupWrapper title="news">
            {children}
            <Footer
              pathname={[
                getLinkToDetail(item),
                'news',
              ].join('/')}
            />
          </GroupWrapper>
        )}
      />
      {[
        {type: WishareEntities.ORGANIZATION, path: 'organizations'},
        {type: WishareEntities.ACTIVITY, path: 'activities'},
        {type: WishareEntities.DONATION, path: 'donations'},
        {type: WishareEntities.GIVING, path: 'givings'},
        {type: WishareEntities.RECRUITMENT, path: 'jobs'},
        {type: WishareEntities.EVENT, path: 'events'},
      ].map((itemType, index) => (
        <Component
          key={index}
          type={itemType.type}
          item={item}
          pathname={[
            getLinkToDetail(item),
            itemType.path,
          ].join('/')}
        />
      ))}
    </Wrapper>
  )
}

export default CategoryGeneral
