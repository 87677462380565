import {
  Button,
  Dropdown,
  List,
  Menu,
} from 'antd'
import _ from 'lodash'
import React, {
  useCallback,
  useState,
} from 'react'
import {
  IoEarthSharp,
  IoPersonCircleOutline,
} from 'react-icons/io5'
import { Null } from 'views/Shared'
import './PostPrivacy.css'
import useTranslate from "../../../modules/local/useTranslate";
import Translate from "../../../modules/local/Translate";

export const PostPrivacyTypes =
  Object.freeze({
    public: {
      value: 1,
      key: 1,
      label: 'public',
      title: 'public',
      description: 'anyone can search for and view',
      icon: (
        <IoEarthSharp size={20} />
      ),
    },
    private: {
      value: 2,
      key: 2,
      label: 'private',
      title: 'private',
      description: 'only you can view',
      icon: (
        <IoPersonCircleOutline size={20} />
      ),
    },
  })

export const postPrivacy =
  Object.values(PostPrivacyTypes).map(
    ({ key, ...rest }) => ({
      ...rest,
    })
  )

const defaultPrivacyOptions =
  Object.values(PostPrivacyTypes).map(
    ({
      label,
      description,
      ...rest
    }) => ({
      ...rest,
      label: (
        <Translate>
            {(t) => (
                <div className="flex flex-col">
                    <div className="font-bold text-sm leading-tight">
                        {t(label)}
                    </div>
                    <div className="font-light text-xs italic leading-tight">
                        {t(description)}
                    </div>
                </div>
            )}
        </Translate>
      ),
    })
  )

const PostPrivacy = ({
  renderItem,
  onSelect = Null,
  items = defaultPrivacyOptions,
}) => {
    const t = useTranslate()

  const [visible, setVisible] =
    useState()

  const [privacy, setPrivacy] =
    useState(
      Number(
        PostPrivacyTypes.public.value
      )
    )

  const handleSelect = useCallback(
    ({ key }) => {
      const current = Number(key)
      setPrivacy(current)
      onSelect(current)
    },
    [onSelect, setPrivacy]
  )

  const renderPrivacy = useCallback(
    (value) => {
      const item = _.find(items, {
        value: Number(value),
      })
      if (_.isEmpty(item)) return null
      if (renderItem) {
        return renderItem(item)
      } else {
        return (
          <Button
            icon={item.icon}
            ghost={visible && false}
            type="primary"
            className="flex items-center rounded-md gap-2 px-2 no-shadow no-text-shadow no-border">
            {t(item.title)}
          </Button>
        )
      }
    },
    [items, visible, renderItem]
  )

  return (
    <Dropdown
      getPopupContainer={() =>
        document.getElementById(
          'AttachedActions'
        )
      }
      onVisibleChange={setVisible}
      overlayClassName="w-full custom-dropdown-menu"
      overlay={
        <Menu
            style={{background: 'var(--background)'}}
            items={items}
            selectable={true}
            onSelect={handleSelect}
        />
      }
      trigger={['click']}>
        {renderPrivacy(privacy)}
    </Dropdown>
  )
}

export default PostPrivacy
