import { Cascader } from 'antd'
import { createValue } from 'components/form/utils'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import withTranslate from 'modules/local/withTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import {
  compose,
  mapProps,
  pure,
} from 'recompose'
import { SelectCountries } from 'views/Organization/Settings/SelectCountries'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import { withAppConfig } from 'views/Wishare/composers'
import {
  dropdownComponents,
  SelectWidget,
} from 'views/Wishare/custom/ReactSelectComponents'

const renderDefaultLabel = ({
  name,
  type,
}) => (type ? `${type} ${name}` : name)

export const getProfileLocationOptions =
  ({
    wards,
    districts,
    provinces,
    country_id,
    renderLabel = renderDefaultLabel,
  }) => {
    const getWards = (district_id) =>
      _.filter(
        Array.from(wards || []),
        { district_id }
      ).map(({ id, name, type }) => ({
        value: id,
        label: renderLabel({
          name,
          type,
        }),
      }))

    const getDistricts = (
      province_id
    ) =>
      _.filter(
        Array.from(districts || []),
        { province_id }
      ).map(({ id, name, type }) => ({
        value: id,
        label: renderLabel({
          name,
          type,
        }),
        children: getWards(id),
      }))

    return _.filter(
      Array.from(provinces || []),
      { country_id }
    ).map(({ id, name, type }) => ({
      value: id,
      label: renderLabel({
        name,
        type,
      }),
      children: getDistricts(id),
    }))
  }

export const LocationCascade = ({
  name,
  value,
  onChange,
  country_id,
  defaultValue,
  Header = Null,
  renderLabel,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  const appConfig = useAppConfig()

  const handleChange = (value) => {
    onChange(createValue(name, value))
  }

  const options = useMemo(
    () =>
      getProfileLocationOptions({
        ..._.pick(appConfig, [
          'provinces',
          'districts',
          'wards',
        ]),
        country_id,
        renderLabel: isSm
          ? ({ name }) => name
          : renderDefaultLabel,
      }),
    [isSm, appConfig, country_id]
  )

  if (_.isEmpty(options)) return null

  return (
    <Wrapper>
      <Header />
      <Translate>
        {(translate) => (
          <Cascader
            showSearch={true}
            changeOnSelect={true}
            suffixIcon={
              <dropdownComponents.DropdownIndicator />
            }
            {...props}
            value={value}
            options={options}
            onChange={handleChange}
            // className="block w-full"
            style={{
              width: '100%',
            }}
            placeholder={translate(
              'Province/district/ward'
            )}
            defaultValue={defaultValue}
            displayRender={(
              stringArray = []
            ) =>
              stringArray.every(
                _.isEmpty
              )
                ? translate(
                    'Province/district/ward'
                  )
                : stringArray.join(
                    ' / '
                  )
            }
          />
        )}
      </Translate>
    </Wrapper>
  )
}

export const renderLocationCascade = ({
  Header,
  ...props
}) => (
  <LocationCascade
    Header={Header}
    {...props}
  />
)

export const CountryPicker = compose(
  withTranslate,
  withAppConfig,
  mapProps(
    ({
      name,
      form,
      value,
      translate,
      appConfig,
      withChangedValues = (
        values
      ) => ({}),
    }) => ({
      name,
      value,
      onChange: (value) => {
        const {
          values,
          setValues = Null,
        } = form || {}
        const hasLocationMeta = _.some(
          _.get(
            appConfig,
            'provinces',
            []
          ),
          {
            country_id: value,
          }
        )
        if (!hasLocationMeta) {
          setValues({
            ...values,
            ...withChangedValues(
              values
            ),
            [name]: value,
          })
        } else {
          setValues({
            ...values,
            [name]: value,
          })
        }
      },
      placeholder: translate('country'),
      Component: SelectWidget,
    })
  ),
  pure
)(SelectCountries)
