import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import React, {
  useContext,
} from 'react'

export const StickyWrapper = ({
  children,
  className,
  Wrapper = 'div',
  style = {},
}) => {
  const { isSm } = useContext(
    LayoutContext
  )
  return (
    <Wrapper
      style={{
        top: 'var(--header-height)',
        ...style,
      }}
      className={classNames(
        'stickyTop',
        !!!isSm && 'h-0',
        className
      )}>
      {children}
    </Wrapper>
  )
}

export const TemplateWrapper = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'w-full',
        className
      )}>
      {children}
    </div>
  )
}
