import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { isRawContentEmpty } from 'components/RichEditor/parseRawContent'
import Renderer from 'components/RichEditor/Renderer'
import {
  convertFromRaw,
  EditorState,
} from 'draft-js'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import RecruitmentContext from '../RecruitmentContext'

const RecruitmentBenefits = () => {
  const t = useTranslate()

  const { recruitment } = useContext(
    RecruitmentContext
  )

  const {
    salary,
    benefits,
    min_salary,
    max_salary,
    salary_unit_name,
  } = recruitment || {}

  const benefit_state = useMemo(
    () =>
      isRawContentEmpty(benefits)
        ? null
        : EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(benefits)
            )
          ),
    [benefits]
  )

  if (_.isEmpty(recruitment)) {
    return null
  }

  return (
    <div className="space-y-3">
      {(!!min_salary ||
        !!max_salary ||
        !!salary) && (
        <div className="flex flex-col">
          <span className="text-xs text-color-400 font-light">
            {t('salary')}
          </span>
          {!!salary ? (
            <div className="flex space-x-2">
              <span>{'~'}</span>
              <span className="text-color-000 font-semibold">
                {Number(
                  salary
                ).toLocaleString()}{' '}
                {salary_unit_name}
              </span>
            </div>
          ) : !!min_salary &&
            !!max_salary ? (
            <div className="flex space-x-2">
              <span className="text-color-000 font-semibold">
                {Number(
                  min_salary
                ).toLocaleString()}{' '}
                {salary_unit_name}
              </span>
              <span>{'-'}</span>
              <span className="text-color-000 font-semibold">
                {Number(
                  max_salary
                ).toLocaleString()}{' '}
                {salary_unit_name}
              </span>
            </div>
          ) : !!max_salary ? (
            <div className="flex space-x-2">
              <span>{'<='}</span>
              <span className="text-color-000 font-semibold">
                {Number(
                  max_salary
                ).toLocaleString()}{' '}
                {salary_unit_name}
              </span>
            </div>
          ) : (
            <div className="flex space-x-2">
              <span>{'>='}</span>
              <span className="text-color-000 font-semibold">
                {Number(
                  min_salary
                ).toLocaleString()}{' '}
                {salary_unit_name}
              </span>
            </div>
          )}
        </div>
      )}
      <HeaderItemInfo
        title="benefits"
        divider={false}
        hidden={!benefit_state}>
        <div className="px-2 border border-color-50 rounded-lg">
          <Renderer
            editorState={benefit_state}
          />
        </div>
      </HeaderItemInfo>
    </div>
  )
}

export default RecruitmentBenefits
