import _ from 'lodash'
import React from 'react'
import { renderIf } from 'views/Shared'
import EditProfileAbout from 'views/Wishare/User/ActionForms/EditProfileAbout'
import EditProfileEducation from 'views/Wishare/User/ActionForms/EditProfileEducation'
import EditProfileLanguage from 'views/Wishare/User/ActionForms/EditProfileLanguage'
import EditProfileSkill from 'views/Wishare/User/ActionForms/EditProfileSkill'
import EditProfileWork from 'views/Wishare/User/ActionForms/EditProfileWork'

const RecruitmentCandidateProfile = ({
  profile,
  candidate,
  Wrapper = 'div',
}) =>
  renderIf(
    !_.isEmpty(profile),
    <Wrapper className="space-y-2">
      {[
        {
          name: 'about',
          Component: EditProfileAbout,
        },
        {
          name: 'skill_profiles',
          Component: EditProfileSkill,
        },
        {
          name: 'language_profiles',
          Component:
            EditProfileLanguage,
        },
        {
          name: 'education_profiles',
          Component:
            EditProfileEducation,
        },
        {
          name: 'work_profiles',
          Component: EditProfileWork,
        },
      ].map(
        (
          {
            name,
            Component,
            withProps = (props) =>
              props,
          },
          index
        ) => {
          const value = _.get(
            profile,
            name
          )
          return (
            <Component
              key={index}
              name={name}
              readOnly={true}
              value={value}
              {...withProps({
                name,
                value,
                values: profile,
              })}
            />
          )
        }
      )}
    </Wrapper>
  )

export default RecruitmentCandidateProfile
