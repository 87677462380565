import {
  Button,
  Modal,
  Spin,
} from 'antd'
import { getId } from 'apis/model/base'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import { Formik } from 'formik'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  BsPencilSquare,
  BsTrash,
} from 'react-icons/bs'
import {
  getResponseItem,
  Null,
  renderElse,
  renderIf,
  renderSelf,
  shallowDiff,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { modalWidth } from 'views/Wishare/configs'
import { showDeleteConfirmDialog } from 'views/Wishare/factory/createConfirmDialog'
import { ItemContextMenu } from 'views/Wishare/factory/createContextMenu'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import AntdConfigWrapper from '../custom/AntdConfigWrapper'
import ListHeader from '../Templates/items/ListHeader'
import { createControlledFormField } from './createFormField'
import {NotificationActionTypes, successNotify} from './createNotification'

export const SubCategoryTypes =
  Object.freeze({
    ACTIVITY: {
      label: 'activity_category',
      value: 'activity_category',
    },
    ORGANIZATION: {
      label: 'organization_category',
      value: 'organization_category',
    },
  })

const fetchEntity =
  ({
    id,
    deps,
    query,
    values,
    apiInfo,
    Component = Null,
    withItem = (item) => ({ item }),
    getItem = getResponseItem,
  }) =>
  (props) =>
    renderIf(
      id,
      <Async
        key={id}
        {...{
          deps,
          query,
          values,
          apiInfo,
        }}>
        {({
          response,
          isLoading = false,
        }) => (
          <Component
            {...{
              isLoading,
              ...(withItem(
                getItem(response)
              ) || {}),
            }}
            {...props}
          />
        )}
      </Async>
    )

const CreateForm = ({
  validate,
  formSchema,
  onSubmit = Null,
  Wrapper = 'div',
  initialValues = {},
  ...props
}) => (
  <Formik
    onSubmit={(values) => {
      onSubmit(values, initialValues)
    }}
    validate={validate}
    validateOnMount={true}
    validateOnChange={true}
    enableReinitialize={true}
    initialValues={initialValues}
    {...props}>
    <Wrapper className="flex flex-col space-y-2">
      {renderElse(
        _.isEmpty(formSchema),
        <FieldsFactory
          formSchema={formSchema}
        />
      )}
      <FormActionBar submitText="create" />
    </Wrapper>
  </Formik>
)

const EditForm = ({
  validate,
  formSchema,
  initialValues,
  onSubmit = Null,
  Wrapper = 'div',
  isLoading = false,
  ...props
}) => (
  <Formik
    onSubmit={(values) => {
      onSubmit(values, initialValues)
    }}
    validate={validate}
    validateOnChange={true}
    enableReinitialize={true}
    validateOnMount={!isLoading}
    initialValues={initialValues}
    {...props}>
    <Spin spinning={!!isLoading}>
      <Wrapper className="flex flex-col space-y-2">
        {renderElse(
          _.isEmpty(formSchema),
          <FieldsFactory
            formSchema={formSchema}
          />
        )}
        <FormActionBar submitText="save" />
      </Wrapper>
    </Spin>
  </Formik>
)

export const async_schema = {
  query: undefined,
  values: undefined,
  apiInfo: undefined,
}

export const useManager = ({
  params = {},
  onCreated = Null,
  onUpdated = Null,
  onDeleted = Null,
  initialized = (item, type) => item,
  onPreSubmit = (values, type) =>
    values,
  configs = {
    create: async_schema,
    update: async_schema,
    delete: async_schema,
    fetchEntity: (id) => async_schema,
    modal: {
      createTitle: 'create',
      updateTitle: 'update',
      renderTitle: (title) => (
        <Translate>
          {(t) => (
            <div className="text-center font-semibold text-color-000 uppercase">
              {t(title)}
            </div>
          )}
        </Translate>
      ),
    },
  },
  createFormSchema = (action) => [
    [
      {
        title: 'title',
        children: [
          {
            name: 'title',
            component:
              createControlledFormField(
                {
                  placeholder: 'title',
                }
              ),
          },
        ],
      },
    ],
    undefined,
  ],
}) => {
  const t = useTranslate()

  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const [refreshKey, setRefreshKey] =
    useState()

  const onCancel = () => setAction({})

  const reload = () => {
    setRefreshKey(Date.now())
    onCancel()
  }

  const onError = notifyOnError(t)

  const {
    handleAsyncAction: onCreate,
  } = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
      onCreated(result, data)
      reload()
    },
    ...(configs?.create || {}),
  })

  const {
    handleAsyncAction: onUpdate,
  } = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
      onUpdated(result, data)
      reload()
    },
    ...(configs?.update || {}),
  })

  const {
    handleAsyncAction: deleteEntity,
  } = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
      onDeleted(result, data)
      reload()
    },
    ...(configs?.delete || {}),
  })

  const handleDelete = (id) => {
    showDeleteConfirmDialog({
      onCancel,
      translate: t,
      onOk: () => {
        deleteEntity(
          {},
          bindQueryParam({
            id,
          })
        )
      },
    })
  }

  const handleAction = (
    type,
    params
  ) => {
    switch (type) {
      case CRUD.CREATE:
      case CRUD.UPDATE:
        setAction({
          type,
          value: params,
        })
        break
      case CRUD.DELETE:
        handleDelete(getId(params))
        break
      default:
        break
    }
  }

  const contextOptions = [
    {
      label: 'edit',
      key: CRUD.UPDATE,
      icon: <BsPencilSquare />,
    },
    {
      label: 'delete',
      key: CRUD.DELETE,
      icon: <BsTrash />,
    },
  ]

  const renderContextMenu = useCallback(
    (item) => (
      <div className="absolute right-0 top-0 px-2 py-1">
        <ItemContextMenu
          onMenuSelect={(key) =>
            handleAction(key, item)
          }
          items={contextOptions}
        />
      </div>
    ),
    [contextOptions]
  )

  const handleSubmit =
    (type, id) =>
    (values, initialValues) => {
      const data =
        onPreSubmit(values, type) ||
        values

      switch (type) {
        case CRUD.CREATE:
          onCreate(
            _.omitBy(
              data,
              _.isUndefined
            )
          )
          break
        case CRUD.UPDATE:
          const changed = shallowDiff(
            data,
            initialValues
          )
          onUpdate(
            _.omitBy(
              changed,
              _.isUndefined
            ),
            {
              id,
            }
          )
          break
        default:
          break
      }
    }

  const modal = useMemo(() => {
    const { type, value } = action
    let modal_params = {}
    let content_params = {
      onSubmit: Null,
    }
    let Component = Null
    const id = getId(value)

    const {
      createTitle,
      updateTitle,
      renderTitle = renderSelf,
    } = configs?.modal || {}

    const {
      fetchEntity: withId = Null,
    } = configs || {}

    const [formSchema, validate] =
      createFormSchema(action.type)

    switch (type) {
      case CRUD.CREATE:
        modal_params = {
          title: createTitle
            ? renderTitle(
                t(createTitle)
              )
            : undefined,
        }
        content_params = {
          initialValues: initialized(
            {},
            type
          ),
          onSubmit: handleSubmit(type),
        }
        Component = CreateForm
        break
      case CRUD.UPDATE:
        modal_params = {
          title: updateTitle
            ? renderTitle(
                t(updateTitle)
              )
            : undefined,
        }
        content_params = {
          onSubmit: handleSubmit(
            type,
            id
          ),
        }
        Component = fetchEntity({
          id,
          ...(withId(id) || {}),
          Component: EditForm,
          withItem: (item) => ({
            initialValues: initialized(
              item,
              type
            ),
          }),
        })
        break
      default:
        return null
    }
    return (
      <Modal
        className="custom-modal"
        footer={null}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={action.type}
        width={modalWidth.medium}
        {...modal_params}>
        <AntdConfigWrapper>
          <Component
            {...{
              onCancel,
              validate,
              formSchema,
            }}
            {...(params || {})}
            {...content_params}
          />
        </AntdConfigWrapper>
      </Modal>
    )
  }, [
    action,
    ...Object.values(params || {}),
  ])

  return {
    modal,
    params,
    onCreate,
    onUpdate,
    refreshKey,
    handleAction,
    renderContextMenu,
    onDelete: handleDelete,
  }
}

export const EnitityManager = ({
  modal,
  title,
  children,
  Wrapper = 'div',
  handleAction = Null,
  renderExtra = renderSelf,
  renderHeader = renderSelf,
}) => {
  const t = useTranslate()
  return (
    <Wrapper className="flex flex-col space-y-3">
      {renderHeader(
        <ListHeader
          title={title}
          extra={renderExtra(
            <Button
              className="rounded-md no-border no-text-shadow no-shadow"
              type="primary"
              onClick={() => {
                handleAction(
                  CRUD.CREATE
                )
              }}>
              {t('create')}
            </Button>
          )}
        />
      )}
      {children}
      {modal}
      <div className="h-10" />
    </Wrapper>
  )
}

const createEnitityManager = (
  props
) => <EnitityManager {...props} />

export default createEnitityManager
