import { Avatar } from 'antd'
import { auth_updateFollowedCategories_Api } from 'apis'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { IoImageOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { paths } from 'views/MainPage/contains'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import Loading from '../../components/Loading'
import { useAppConfig } from '../../modules/local'
import { useRequiredLogin } from '../Server/useRequiredLogin'

export const FirstSelectCategory = ({
  onSuccess = Null,
}) => {
  const t = useTranslate()
  const history = useHistory()
  const handleRequiredLogin =
    useRequiredLogin()

  const { categories = [] } =
    useAppConfig()

  const { handleAsyncAction } =
    useAsyncAction({
      apiInfo:
        auth_updateFollowedCategories_Api,
      onSuccess: () => {
        onSuccess()
      },
      onError: notifyOnError(t),
    })

  const handleSelectItem = (id) => {
    handleAsyncAction({ cate_ids: id })
  }

  const cates = useMemo(
    () =>
      categories.filter(
        (el) => !!!el.is_sub_category
      ),
    [categories]
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-2 lg:gap-3">
      {cates.map((item, index) => {
        const categoryId = _.get(
          item,
          'id'
        )
        return (
          <div
            key={index}
            onClick={() => {
              handleRequiredLogin(() =>
                handleSelectItem(
                  categoryId
                )
              )
              setTimeout(() => {
                history.push(
                  paths.defaultCategoryPath.replace(
                    'category',
                    categoryId
                  )
                )
              }, 100)
            }}
            className="p-3 z-10 background border border-color-50 rounded-lg cursor-pointer hover:shadow-out hover:text-primary">
            <div className="flex items-center">
              <Avatar
                shape={'square'}
                className="flex items-center justify-center object-contain rounded-md"
                src={item.avatar}
                icon={
                  <IoImageOutline
                    style={{
                      fontSize: '2rem',
                      fill: 'var(--text-primary)',
                    }}
                  />
                }
                alt={_.get(
                  item,
                  'keywords'
                )}
              />
              <div className="max-lines-2 px-3">
                {_.get(item, 'name')}
              </div>
            </div>
          </div>
        )
      })}
      {!cates.length && <Loading />}
    </div>
  )
}

export default FirstSelectCategory
