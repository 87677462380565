import {Radio, Select} from 'antd'
import {host_getsByHost_api} from 'apis'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useState,} from 'react'
import {branch, compose, renderNothing,} from 'recompose'
import {getResponseItems, Null,} from 'views/Shared'
import AntdConfigWrapper, {RenderEmptyMeta,} from 'views/Wishare/custom/AntdConfigWrapper'
import {reactSelectComponents} from 'views/Wishare/custom/ReactSelectComponents'
import {WishareEntities} from 'views/Wishare/enums'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'

const FilterSelectField = ({
  name,
  value,
  onChange,
  options,
  isLoading,
  ...props
}) => (
  <AntdConfigWrapper
    renderEmpty={RenderEmptyMeta}>
    <Select
      value={
        _.find(options, { value })
          ? value
          : undefined
      }
      showSearch={true}
      allowClear={true}
      onChange={(value) => {
        onChange(name, value)
      }}
      loading={!!isLoading}
      optionFilterProp="label"
      className="CenterSelector w-full flex flex-1"
      options={
        !!isLoading ? [] : options
      }
      suffixIcon={
        !!isLoading ? undefined : (
          <reactSelectComponents.DropdownIndicator />
        )
      }
      {...props}
    />
  </AntdConfigWrapper>
)

const OrganizationsAsyncWrapper =
  compose(
    branch(
      ({ owner_id, owner_type }) =>
        _.some(
          [owner_id, owner_type],
          _.isEmpty
        ),
      renderNothing
    )
  )(
    ({
      owner_id,
      owner_type,
      children,
    }) => {
      const [values, setValues] =
        useState()

      const apiInfo =
        host_getsByHost_api

      const { response, isLoading } =
        useAsyncWithCache({
          cacheId: [
            apiInfo.path
              .replace(':id', owner_id)
              .replace(
                ':type',
                owner_type
              ),
            JSON.stringify(values),
          ].join('/'),
          apiInfo,
          values: {
            ...(values || {}),
            owner_type:
              WishareEntities.ORGANIZATION,
          },
          deps: [values],
          query: bindQueryParams([
            { id: owner_id },
            { prop: owner_type },
          ]),
        })

      return _.isFunction(children)
        ? children({
            isLoading,
            items:
              getResponseItems(
                response
              ),
            onSearch: (keyword) => {
              setValues({
                keyword,
              })
            },
          })
        : children
    }
  )

const RelatedAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    status: undefined,
    organization_id: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <OrganizationsAsyncWrapper
        {...{
          owner_id,
          owner_type,
        }}>
        {({ items, isLoading }) => {
          const options = Array.from(
            items
          ).map(({ owner }) => ({
            value: _.get(owner, 'id'),
            label: t(getTitle(owner)),
          }))
          return (
            <FilterSelectField
              name="organization_id"
              loading={isLoading}
              value={
                filter.organization_id
              }
              placeholder={t(
                isLoading
                  ? 'loading...'
                  : 'organization'
              )}
              options={options}
              onChange={(
                name,
                value
              ) => {
                handleChange(
                  name,
                  value
                )
              }}
            />
          )
        }}
      </OrganizationsAsyncWrapper>
      <Radio.Group
        name="status"
        buttonStyle="solid"
        className="flex items-center justify-end w-full lg:w-auto"
        onChange={(event) => {
          const { name, value } =
            event.target
          handleChange(name, value)
        }}
        defaultValue={
          filter.status || undefined
        }>
        <Radio.Button value={undefined}>
          {t('all')}
        </Radio.Button>
        <Radio.Button value={'1'}>
          {t('active')}
        </Radio.Button>
        <Radio.Button value={'9'}>
          {t('closed')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export const RelatedActivitiesAdvancedFilter =
  (props) => (
    <RelatedAdvancedFilter {...props} />
  )

export const RelatedDonationEventsAdvancedFilter =
  (props) => (
    <RelatedAdvancedFilter {...props} />
  )

export const RelatedGivingEventsAdvancedFilter =
  (props) => (
    <RelatedAdvancedFilter {...props} />
  )

export const RelatedRecruitmentEventsAdvancedFilter =
  (props) => (
    <RelatedAdvancedFilter {...props} />
  )

export const RelatedEventsAdvancedFilter =
  (props) => (
    <RelatedAdvancedFilter {...props} />
  )
