import React from 'react'
import {FlagENSvg} from '../Svgs/FlagENSvg'
import {FlagVISvg} from '../Svgs/FlagVISvg'
import {FlagFRSvg} from "../Svgs/FlagFRSvg";
import {FlagKOSvg} from "../Svgs/FlagKOSvg";
import {FlagKMSvg} from "../Svgs/FlagKMSvg";

export const FlagIcon = ({
                           type,
                           ...props
                         }) => {
  switch (type) {
    case 'vi':
      return <FlagVISvg {...props} />
    case 'en':
      return <FlagENSvg {...props} />
    case 'fr':
      return <FlagFRSvg {...props} />
    case 'ko':
      return <FlagKOSvg {...props} />
    case 'km':
      return <FlagKMSvg {...props} />
    default:
      return <FlagVISvg {...props} />
  }
}
