import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import numeral from 'numeral'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  IoCreateOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {
  useEffectOnce,
  useSearchParam,
} from 'react-use'
import { DelayRender } from 'views/Discovery/DelayRender'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { donationApi } from 'views/Wishare/apis'
import CustomButton from 'views/Wishare/custom/CustomButton'
import { WishareEntities } from 'views/Wishare/enums'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { DonationFAB } from 'views/Wishare/factory/createFAB'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { OverlayItemBanner } from '../../Templates/ItemTemplate'
import CreateDonationOptionForm from '../ActionForms/CreateDonationOptionForm'
import DonationRegisterAdminForm from '../ActionForms/DonationRegisterAdminForm'
import DonationRegisterForm, {
  registerDonationParams,
} from '../ActionForms/DonationRegisterForm'
import DonationContext from '../DonationContext'
import {
  DonationStatusTypes,
  getDonationEventStatus,
} from '../functions/getDonationEventStatus'

export const DonationOptionDonateTypes =
  Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
  })

const DonationDetailOptions = ({
  options = [],
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  if (_.isEmpty(options)) return null
  return (
    <div>
      {options.map(
        (
          {
            name,
            type_cd,
            unit_name,
            option_qtty,
            option_value,
          },
          index
        ) => (
          <div
            key={index}
            className="flex space-x-1 font-semibold text-primary">
            <span className="uppercase">
              {isSm
                ? numeral(
                    Number(option_value)
                  ).format('0a')
                : Number(
                    option_value
                  ).toLocaleString()}
            </span>
            <span>{unit_name}</span>
          </div>
        )
      )}
    </div>
  )
}

const OptionItem = ({
  item,
  donation_type,
}) => {
  const {
    title,
    photo,
    description,
    option_items,
  } = item

  return (
    <React.Fragment>
      <div className="text-xs font-light text-color-300 italic">
        {description}
      </div>
      <div className="flex items-center justify-between mt-2">
        {donation_type === 'blood' ? (
          <span />
        ) : (
          <div
            className="font-medium mr-3"
            style={{ color: '#000' }}>
            {title}
          </div>
        )}
        <DonationDetailOptions
          options={option_items}
        />
      </div>
    </React.Fragment>
  )
}

const renderItem =
  ({
    edit = false,
    onEdit = Null,
    onRemove = Null,
    onItemClick = Null,
    disabled = false,
    ...props
  }) =>
  (item, index) => {
    const t = useTranslate()

    const canEdit = Boolean(edit)

    return (
      <div
        className={classNames("relative", disabled && 'filter-gray')}
        style={
          !!item.photo
            ? {
                backgroundImage: `url(${item.photo})`,
                height: '100%',
                backgroundPosition:
                  'center',
                backgroundRepeat:
                  'no-repeat',
                backgroundSize: 'cover',
              }
            : {}
        }
        key={index}>
        <div
          onClick={
            !!disabled
              ? null
              : () =>
                  onItemClick(
                    getId(item)
                  )
          }
          className={classNames(
            'border rounded-lg hover:shadow-out relative',
            !!disabled
              ? ' pointer-events-none opacity-50 background-100 border-color-50'
              : ' cursor-pointer border-primary bg-primary-50'
          )}>
          <OverlayItemBanner
            className={
              disabled
                ? 'bg-gray-400 text-color-300'
                : ' '
            }
            title={`${t(
              'register'
            )} ${index + 1}`}
            type={
              WishareEntities.DONATION
            }
          />
          <div
            className={classNames(
              'px-2 md:px-3 pb-2 relative',
              canEdit ? 'pt-10' : 'pt-8'
            )}>
            {!!disabled && (
              <div
                className="w-full h-full flex items-center justify-center absolute"
                style={{ top: '0' }}>
                <span className="end-stamp-campaign small">
                  {t('finished')}
                </span>
              </div>
            )}
            <OptionItem
              item={item}
              {...props}
            />
          </div>
        </div>
        {canEdit && (
          <div
            className="absolute z-10 p-1 flex gap-2"
            style={{
              top: '2px',
              right: '3px',
            }}>
            <CustomButton
              size="small"
              ghost={true}
              onClick={() => {
                onRemove(item)
              }}
              disabled={disabled && true}
              className="GhostSecondary custom-btn-options flex flex-center"
              icon={<IoTrashOutline />}
            />
            <CustomButton
              size="small"
              ghost={true}
              className="GhostPrimary custom-btn-options flex flex-center"
              onClick={() => {
                onEdit(item)
              }}
              disabled={disabled && true}
              icon={<IoCreateOutline />}
            />
          </div>
        )}
      </div>
    )
  }

const DonationPackages = ({
  donation,
  onItemClick = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()

  const [
    currentOption,
    setCurrentOption,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const options = Array.from(
    _.get(donation, 'options') || []
  )

  const [id, prop] = [
    getId(donation),
    getType(donation),
  ]

  const {
    handleAsyncAction: deleteOption,
  } = useAsyncAction({
    query: bindQueryParam({ id }),
    onError: notifyOnError(t),
    onSuccess: (__, { response }) => {
      successNotify(
        NotificationActionTypes.DELETE,
        t
      )
    },
    apiInfo:
      donationApi.donation_events_deleteDonationOption_api,
  })

  const {
    edit = false,
    donation_type,
  } = donation || {}

  const onEdit = (currentOption) => {
    setCurrentOption({
      type: CRUD.UPDATE,
      value: currentOption,
    })
  }

  const onRemove = (item) => {
    Modal.confirm({
      title: (
        <div className="font-bold text-lg text-orange-500">
          <span>
            {t(
              'delete donation package'
            )}
          </span>
        </div>
      ),
      content: (
        <div className="text-sm font-light italic text-color-300">
          {t(
            'are you sure you want to delete this donation package'
          )}
        </div>
      ),
      okText: t('sure'),
      cancelText: t('no'),
      okButtonProps: {
        type: 'primary',
        danger: true,
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      cancelButtonProps: {
        className:
          'rounded-lg no-shadow no-text-shadow',
      },
      onOk: () => {
        const option_id = getId(item)
        deleteOption(
          {},
          bindQueryParam({ option_id })
        )
      },
    })
  }

  const donationStatus =
    getDonationEventStatus(donation)
  const disabledDonateButton =
    donationStatus !==
    DonationStatusTypes.ACTIVE.value

  return (
    <Wrapper>
      <DelayRender>
        {options.map(
          renderItem({
            edit,
            onEdit,
            onRemove,
            onItemClick,
            donation_type,
            disabled:
              disabledDonateButton,
          })
        )}
      </DelayRender>
      {edit && (
        <div className="flex items-center justify-center py-2">
          <Translate>
            {(t) => (
              <Button
                size="large"
                type="primary"
                className="flex-1 rounded-lg no-shadow no-text-shadow"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentOption({
                    type: CRUD.CREATE,
                    value: undefined,
                  })
                }}>
                {t(
                  'add donation option'
                )}
              </Button>
            )}
          </Translate>
        </div>
      )}
      {currentOption.type && (
        <CreateDonationOptionForm
          currentOption={
            currentOption.value
          }
          onCancel={() => {
            setCurrentOption({})
          }}
        />
      )}
    </Wrapper>
  )
}

const DonationOption = () => {
  const t = useTranslate()

  const history = useHistory()

  const { location } = history

  const [
    currentDonateAction,
    setCurrentDonateAction,
  ] = useState({
    type: undefined,
    value: undefined,
  })

  const { donation } = useContext(
    DonationContext
  )

  const edit = Boolean(
    _.get(donation, 'edit')
  )

  const canEditDonation =
    edit &&
    currentDonateAction.type ===
      DonationOptionDonateTypes.ADMIN

  const isNormalDonor =
    currentDonateAction.type ===
    DonationOptionDonateTypes.USER

  const option_name =
    registerDonationParams.options.name

  const currentOption = useSearchParam(
    option_name
  )

  const {
    name: action,
    value: actionValue,
  } = registerDonationParams.action

  const currentAction =
    useSearchParam(action)

  useEffectOnce(() => {
    if (
      _.isEqual(
        currentAction,
        actionValue
      )
    ) {
      setCurrentDonateAction({
        type: DonationOptionDonateTypes.USER,
      })
    }
  })

  const defaultOptions = useMemo(() => {
    const source = _.get(
      donation,
      'options',
      []
    )
    const result = _.find(source, {
      id: currentOption,
    })
    if (result) {
      return Array.from(
        _.get(
          result,
          'option_items',
          []
        )
      ).map((item) => ({
        ...item,
      }))
    } else {
      return []
    }
  }, [donation, currentOption])

  const handleSelectOption = (
    option_id
  ) => {
    let search = new URLSearchParams(
      location.search
    )
    search.set(action, actionValue)
    if (option_id) {
      search.set(option_name, option_id)
    }
    history.push({
      // ...location,
      search: [
        '?',
        search.toString(),
      ].join(''),
    })
    setCurrentDonateAction({
      type: DonationOptionDonateTypes.USER,
      value: option_id,
    })
  }

  const renderStatusButton =
    useCallback(
      (registrable) => {
        if (!!!registrable) return null
        const applied_status = Boolean(
          _.get(
            donation,
            'applied_status'
          )
        )

        const donationStatus =
          getDonationEventStatus(
            donation
          )
        const isComplete = donationStatus === 9

        if (applied_status) {
          return (
            <div className="p-2 text-center text-primary font-bold border-2 border-primary rounded-lg">
              {t('registered')}
            </div>
          )
        }

        if (
          donationStatus === DonationStatusTypes.ACTIVE.value && _.get(donation ,'donation_type') !== 'blood'
        ) {
          return (
            <Button
              disabled={isComplete}
              size="large"
              block={true}
              type="primary"
              onClick={() =>
                handleSelectOption(null)
              }
              className="rounded-lg no-shadow no-text-shadow border-none text-lg h-12 uppercase">
              {t('donate now')}
            </Button>
          )
        }
        return null
      },
      [donation]
    )

  const registrable = Boolean(
    _.get(donation, 'registrable')
  )

  if (_.isEmpty(donation)) return null

  return (
    <div className="flex flex-col space-y-3">
      {/*{registrable && (*/}
      {/*  <div className="text-color-300 font-bold text-xl uppercase mt-1">*/}
      {/*    {t('register')}*/}
      {/*  </div>*/}
      {/*)}*/}
      <DonationFAB>
        <div className="flex flex-col gap-2 background">
          {renderStatusButton(
            registrable
          )}
          {edit && (
            <Button
              size="large"
              block={true}
              type="dashed"
              onClick={() => {
                setCurrentDonateAction({
                  type: DonationOptionDonateTypes.ADMIN,
                  value: undefined,
                })
              }}
              className="rounded-lg no-shadow no-text-shadow">
              {`${t('input data')} (${t(
                'for admin'
              )})`}
            </Button>
          )}
        </div>
      </DonationFAB>
      {!!registrable && !_.isEmpty(
        _.get(donation, 'options', [])
      ) && (
        <span className="uppercase text-sm font-semibold text-color-300 tracking-wide">
          {t('register to contribute')}
        </span>
      )}
      {registrable && (
        <DonationPackages
          donation={donation}
          onItemClick={
            handleSelectOption
          }
        />
      )}
      {isNormalDonor && (
        <DonationRegisterForm
          donation_options={
            defaultOptions
          }
          onCancel={() => {
            history.push({
              pathname:
                location.pathname,
            })
            setCurrentDonateAction({})
          }}
        />
      )}
      {canEditDonation && (
        <DonationRegisterAdminForm
          donation_options={
            defaultOptions
          }
          onCancel={() => {
            setCurrentDonateAction({})
          }}
        />
      )}
    </div>
  )
}

export default DonationOption
