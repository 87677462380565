import { schema } from 'normalizr'
import { hostSchema } from './host'

export const givingEventSchema =
  new schema.Entity(
    'giving_events',
    {
      hosts: [hostSchema],
    },
    {
      idAttribute: 'idname',
    }
  )

export const givingSchema =
  new schema.Entity(
    'givings',
    {},
    {
      idAttribute: 'id',
    }
  )
