import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import MedalsByOwner from 'views/MainPage/WorkSpace/Medal/MedalsByOwner'
import DonorContext from '../DonorContext'

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({
      owner_id: getId(donor),
      owner_type: getType(donor),
    })
  ),
  branch(
    ({ owner_id, owner_type }) =>
      _.isEmpty(owner_id) ||
      _.isEmpty(owner_type),
    renderNothing
  ),
  pure
)(MedalsByOwner)
