import {API_ROOT_URL} from 'envs/_current/config'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {donorSchema, medalAwardSchema, medalSchema, staffSchema,} from './schema'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const fields = [
  'title', // required|max:100
  'description', //max:10000
  'idname', // unique:givings,idname
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
]

const fieldTypes = {
  idname: baseFieldTypes.string,
}

const medal_owner_schema =
  new schema.Union(
    {
      donors: donorSchema,
      staffs: staffSchema,
    },
    '_type'
  )

export const medal_getMedals_api = {
  backend: `Route::get('medals/{prop}/{id}/get-medals', 'MedalController@getMedals'); // search: keyword, filter: tags, sort: sort_by: date,name`,
  root: API_ROOT_URL,
  path: '/medals/:prop/:id/get-medals',
  method: 'GET',
  fields,
  fieldTypes,
  paramsFields: [
    'active',
    'tags',
    'status',
    'sort_by',
    'keyword',
  ],
  schema: medalSchema,
  transform: createListTransform(
    medalSchema
  ),
}

export const medal_getMedalRewardsOfOwner_api =
  {
    backend: `Route::get('medals/{owner_type}/{owner_id}/medal-awards/get-rewards-by-owner', 'MedalController@getMedalRewardsOfOwner'); // owner_type:staff,donor; search: keyword, filter: tags, sort: sort_by: date,name`,
    root: API_ROOT_URL,
    path: '/medals/:owner_type/:owner_id/medal-awards/get-rewards-by-owner',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'tags',
      'active',
      'sort_by',
      'keyword',
      'visible',
    ],
    schema: medalAwardSchema,
    transform: createListTransform(
      medalAwardSchema
    ),
  }

export const medal_getById_Api = {
  backend: `Route::get('medals/{id}/get', 'ChannelController@getById');`,
  root: API_ROOT_URL,
  path: '/medals/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  transform: createTransform(
    medalSchema,
    'data'
  ),
}

export const medal_add_Api = {
  backend: `Route::post('medals/{prop}/{id}/create', 'MedalController@createMedal'); // 'title', 'description', 'active:0,1', 'avatar:image', 'status:1', 'lvl_number:1..99', 'tags:tag1,tag2'`,
  root: API_ROOT_URL,
  path: '/medals/:prop/:id/create',
  method: 'POST',
  fields,
  fieldTypes,
  schema: medalSchema,
  transform: createTransform(
    medalSchema,
    'data'
  ),
}

export const medal_update_Api = {
  backend: `Route::post('medals/{id}/update', 'MedalController@updateMedal'); // 'title', 'description', 'active:0,1', 'avatar:image', 'status:1', 'lvl_number:1..99', 'tags:tag1,tag2', 'status:1', 'lvl_number:1..99', 'tags:tag1,tag2'`,
  root: API_ROOT_URL,
  path: '/medals/:id/update',
  method: 'POST',
  fields,
  fieldTypes,
  schema: medalSchema,
  transform: createTransform(
    medalSchema,
    'data'
  ),
}

export const medal_delete_Api = {
  backend: `Route::post('medals/{id}/delete', 'MedalController@deleteMedal');`,
  root: API_ROOT_URL,
  path: '/medals/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: medalSchema,
  transform: createTransform(
    medalSchema,
    'data'
  ),
}

export const medal_getMedalSummary_Api =
  {
    backend: `Route::get('organization/{organization_id}/medals/get-medal-summary', 'OrganizationMedalsController@fetchMedalSummary'); // 'filter_by:active:0,1', 'search:keyword', 'sort_by: date,name,tag,level'`,
    root: API_ROOT_URL,
    path: '/organization/:organization_id/medals/get-medal-summary',
    method: 'GET',
    paramsFields: [
      'active',
      'sort_by',
      'keyword',
    ],
  }

export const medal_getMedalStats_Api = {
  backend: `Route::get('organization/{organization_id}/medals/get-medal-stats', 'OrganizationMedalsController@getMedalStats');`,
  root: API_ROOT_URL,
  path: '/organization/:organization_id/medals/get-medal-stats',
  method: 'GET',
  paramsFields: ['period'],
}

export const medal_getMedalRewards_Api =
  {
    backend: `Route::get('medals/{prop}/{id}/medal-awards/get-rewards', 'MedalController@getMedalRewards'); // search: keyword, filter: tags, sort: sort_by: date,name`,
    root: API_ROOT_URL,
    path: '/medals/:prop/:id/medal-awards/get-rewards',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'tags',
      'sort_by',
      'keyword',
    ],
    schema: medalAwardSchema,
    transform: createListTransform(
      medalAwardSchema,
      'data.data'
    ),
  }

export const medal_getMedalAwardById_Api =
  {
    backend: ``,
    root: API_ROOT_URL,
    path: '/medals/medal-awards/:id/get',
    method: 'GET',
    fields,
    fieldTypes,
    schema: medalAwardSchema,
    transform: createTransform(
      medalAwardSchema,
      'data'
    ),
  }

export const medal_addMedalReward_Api =
  {
    backend: `Route::post('medals/{prop}/{id}/medal/{medal_id}/add', 'MedalController@addMedalReward'); // prop,id: donor,staff; eff_date, end_date, notes`,
    root: API_ROOT_URL,
    path: '/medals/:prop/:id/medal/:medal_id/add',
    method: 'POST',
    fields,
    fieldTypes,
    schema: medalAwardSchema,
    transform: createTransform(
      medalAwardSchema,
      'data'
    ),
  }

export const medal_deleteMedalReward_Api =
  {
    backend: `Route::post('medals/medal-awards/{id}/delete', 'MedalController@deleteMedalReward');`,
    root: API_ROOT_URL,
    path: '/medals/medal-awards/:id/delete',
    method: 'POST',
    fields,
    fieldTypes,
    schema: medalAwardSchema,
    transform: createTransform(
      medalAwardSchema,
      'data'
    ),
  }

export const medal_editMedalReward_Api =
  {
    backend: `Route::post('medals/medal-awards/{id}/update', 'MedalController@editMedalReward'); // id: medal_award_id; eff_date, end_date, notes`,
    root: API_ROOT_URL,
    path: '/medals/medal-awards/:id/update',
    method: 'POST',
    fields,
    fieldTypes,
    schema: medalAwardSchema,
    transform: createTransform(
      medalAwardSchema,
      'data'
    ),
  }

export const medal_setMedalRewardVisible_Api =
  {
    backend: `Route::post('medals/medal-awards/{medal_id}/set-visible-award', 'MedalController@setMedalRewardVisible'); // visible: 0,1`,
    root: API_ROOT_URL,
    path: '/medals/medal-awards/:medal_reward_id/set-visible-award',
    method: 'POST',
    fields,
    fieldTypes,
    schema: medalAwardSchema,
    transform: createTransform(
      medalAwardSchema,
      'data'
    ),
  }

export const medal_getMedalReceivers_Api =
  {
    backend: `Route::get('medals/organization/{id}/medal-awards/{receive_type}/get-rewards', 'MedalController@getMedalRewardsByReceiveType'); // search: keyword, sort: sort_by: date,name`,
    root: API_ROOT_URL,
    path: '/medals/organization/:id/medal-awards/:receive_type/get-rewards',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'sort_by',
      'keyword',
    ],
    // schema: medal_owner_schema,
    // transform: createListTransform(
    //   medal_owner_schema
    // ),
  }

export const medal_getMedalRewardsOfOrganization_Api =
  {
    backend: `Route::get('medals/organization/{id}/medal-awards/get-rewards-by-organization', 'MedalController@getMedalRewardsOfOrganization'); // search: keyword, filter: from_date, to_date, sort: sort_by: date,name`,
    root: API_ROOT_URL,
    path: '/medals/organization/:organization_id/medal-awards/get-rewards-by-organization',
    method: 'GET',
    fields,
    fieldTypes,
    paramsFields: [
      'sort_by',
      'keyword',
      'from_date',
      'to_date',
    ],
    schema: medalAwardSchema,
    transform: createListTransform(
      medalAwardSchema
    ),
  }
