import { Avatar } from 'antd'
import { getFullAvatarUrl } from 'apis/model/base'
import { WishareRibbonSvg } from 'components/Svgs/WishareRibbonSvg'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React, {useContext} from 'react'
import {IoGift, IoGiftOutline, IoHome} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { FlexCol } from 'views/Wishare/Templates/ItemTemplate'
import {WishareHeartSvg} from "../../../../components/Svgs/WishareHeartSvg";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const ActionButtons = ({ item }) => (
  <div className="inline-flex flex-row space-x-1">
    <Link
      to={getLinkToDetail(item)}
      className="rounded-full w-10 h-10 flex items-center justify-center background-100 hover:bg-primary hover:text-white">
      <IoHome className="Icon" />
    </Link>
  </div>
)

const BeneficiarySettingHeader = ({
  beneficiary,
  ...props
}) => {
  const item = beneficiary || {}

  const {isSm} = useContext(LayoutContext)

  if (_.isEmpty(item)) return null

  const avatar = getFullAvatarUrl(item)

  return (
    <div className="w-full p-8">
      <div className="flex items-center gap-3">
        {avatar ? (
          <Avatar
            shape="square"
            size={isSm ? 60 : 100}
            src={avatar}
            className="object-contain w-full h-full rounded-lg border border-color-50"
          />
        ) : (
            <IoGiftOutline size={45} className="text-orange-500"/>
        )}
        <Link
            to={getLinkToDetail(item)}
            className="text-base md:text-xl font-bold text-color-000 no-underline">
          {getTitle(item)}
        </Link>
          <div className="flex flex-1" />
        <ActionButtons {...{ item }} />
      </div>
    </div>
  )
}

export default BeneficiarySettingHeader
