import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import {compose, fromRenderProps,} from 'recompose'
import {formatDate, getFullAddress, renderElse, renderMail, renderPhone, renderSelf,} from 'views/Shared'
import {GenderTypes} from '../../wishareFieldTypes'
import DonorContext from '../DonorContext'
import {OverlayItemBanner} from "../../Templates/ItemTemplate";
import {WishareEntities} from "../../enums";
import {Avatar} from "antd";
import getTitle from "../../../../helpers/getTitle";

export const DonorInformation = ({
  donor,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { extra_fields } = donor || {}

  const extra_data = useMemo(
    () =>
      Array.from(
        extra_fields || []
      ).filter(
        (e) =>
          _.has(e, 'value') ||
          _.has(e, 'dvalue') ||
          _.has(e, 'nvalue')
      ),
    [extra_fields]
  )

  const array = [
    {
      name: 'title',
      label: 'name',
    },
    {
      name: 'email',
      label: 'email',
      render: renderMail,
    },
    {
      name: 'phone_number',
      label: 'phone',
      render: renderPhone,
    },
    {
      name: 'gender',
      label: 'gender',
      render: (value) => {
        const { label } =
          _.find(
            Object.values(GenderTypes),
            {
              value: Number(value),
            }
          ) || {}
        return label ? t(label) : null
      },
    },
    {
      name: 'date_of_birth',
      label: 'birthday',
      render: (value) => (
        <span>{formatDate(value)}</span>
      ),
    },
    {
      name: 'location',
      label: 'address',
      hiddenOnEmpty: (value) =>
        _.isEmpty(
          _.omitBy(
            value,
            (e) => !e || e === null
          )
        ),
      render: (value) => (
        <span>
          {getFullAddress(value)}
        </span>
      ),
    },
    {
      name: 'notes',
      label: 'notes',
      render: (value) => (
        <span>{value}</span>
      ),
    },
  ]


  if (_.isEmpty(donor)) {
    return null
  }

  return (
    <Wrapper className="flex flex-col">
      <div className="uppercase text-sm font-medium text-color-300 tracking-wide mb-2">
        {t('information')}
      </div>
      <div className="flex flex-col space-y-3 p-3 border border-color-50 rounded-lg">
        <div className="relative background-50 rounded-lg">
          <OverlayItemBanner type={WishareEntities.DONOR}/>
          <div className="h-7"/>
          <div className="flex items-center gap-3 p-3">
            <Avatar
                size={40}
                src={_.get(donor, 'avatar')}
                className="flex flex-center uppercase text-color-300 background-200">
              {_.first(getTitle(donor))}
            </Avatar>
            <div className="flex flex-col">
              <span className="font-semibold text-color-000">
                {getTitle(donor)}
              </span>
              <span className="italic font-light text-xs text-secondary">
                {t(_.get(donor, 'owner._type'))}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          {array
            .filter(
              ({name}) =>
                !!_.get(donor, name)
            )
            .map(
              (
                {
                  name,
                  label,
                  render = renderSelf,
                  hiddenOnEmpty = (
                    value
                  ) => false,
                },
                index
              ) => {
                const value = _.get(
                  donor,
                  name
                )
                return renderElse(
                  hiddenOnEmpty(value),
                  <div
                    key={index}
                    className="flex items-baseline space-x-1">
                  <span className="text-xs text-color-500 font-light italic">
                    {`${t(label)} : `}
                  </span>
                    <span className="text-sm text-color-000 font-semibold flex-1">
                    {render(value)}
                  </span>
                  </div>
                )
              }
            )}
        </div>

        {!_.isEmpty(extra_data) && (
          <div className="flex flex-col gap-1">
            <span className="uppercase text-xs italic text-color-300 tracking-wide border-b mt-3 ">
              {t('extra information')}
            </span>
            {extra_data.filter(e => !!e.value || !!e.nvalue || !!e.dvalue).map(
              (e, index) => {
                let value = e.value;
                if (!!e.dvalue) value = formatDate(e.dvalue)
                if (!!e.nvalue) value = Number(e.nvalue).toLocaleString()
                if (!!e.value) value = e.value

                return (
                  <div
                    key={index}
                    className="flex items-baseline space-x-1">
                    <div className="text-xs text-color-500 font-light italic">
                      {e.name}
                      {':'}
                    </div>
                    <div className="text-sm text-color-000 font-semibold flex-1">
                      {value}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({
      donor,
    })
  )
)(DonorInformation)
