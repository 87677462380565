import Icon from '@ant-design/icons/es'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback,} from 'react'
import {BsDiagram2Fill, BsDiagram3, BsDiagram3Fill,} from 'react-icons/bs'
import {
  IoBarChartOutline,
  IoCalendarOutline,
  IoFlowerOutline,
  IoGiftOutline,
  IoListOutline,
  IoNewspaperOutline,
  IoPeople,
} from 'react-icons/io5'
import {RiUser2Line, RiUserReceivedLine, RiUserSharedLine,} from 'react-icons/ri'
import {renderFalse, renderOwnChild,} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {createCheckPermissions, invalidPermissions,} from 'views/Wishare/factory/createCheckPermissions'
import {SettingsMenu} from 'views/Wishare/factory/createSettingLayout'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import {WishareRibbonSvg} from '../../../../components/Svgs/WishareRibbonSvg'
import {useMenuHandler} from '../useMenuHandler'
import HostRelatedActivities from './HostRelatedActivities'
import HostRelatedDonationEvents from './HostRelatedDonationEvents'
import HostRelatedEvents from './HostRelatedEvents'
import HostRelatedGivingEvents from './HostRelatedGivingEvents'
import HostRelatedRecruitmentEvents from './HostRelatedRecruitmentEvents'
import ActivityHostReport from './Reports/ActivityHostReport'
import ActivityPeerOrgReport from './Reports/ActivityPeerOrgReport'
import DonationHostReport from './Reports/DonationHostReport'
import DonationPeerOrgReport from './Reports/DonationPeerOrgReport'
import GivingHostReport from './Reports/GivingHostReport'
import GivingPeerOrgReport from './Reports/GivingPeerOrgReport'
import StaffHostReport from './Reports/StaffHostReport'
import StaffPeerOrgReport from './Reports/StaffPeerOrgReport'
import WorkspaceCoHostList from './WorkspaceCoHostList'
import WorkspaceCoOwnerList from './WorkspaceCoOwnerList'
import WorkspaceHostPeerMembers from './WorkspaceHostPeerMembers'
import WorkspaceHostStatistic from './WorkspaceHostStatistic'

const ItemKeys = Object.freeze({
  REPORT_GROUP: 'report_group',
  CHILDREN_ORG_REPORT:
    'children-org-report',
  PEER_ORG_REPORT: 'peer-org-report',
  STATISTIC: 'statistic',
  CO_HOST_LIST: 'co-host-list',
  CO_HOST_LIST_GROUP:
    'co-host-list-group',
  CO_OWNER_LIST: 'co-owner-list',
  CO_OWNER_LIST_GROUP:
    'co-owner-list-group',
  RELATED_EVENTS: 'related-events',
  RELATED_ACTIVITIES:
    'related-activities',
  RELATED_GIVING_EVENTS:
    'related-giving-events',
  RELATED_DONATION_EVENTS:
    'related-donation-events',
  RELATED_RECRUITMENT_EVENTS:
    'related-recruitment-events',
  PEER_MEMBERS: 'peer-members',
  PEER_MEMBERS_GROUP:
    'peer-members-group',
  ACT_GROUP: 'act-group',
  ACTIVITY_SUMMARIES:
    'activity-summaries',
  DONATION_SUMMARIES:
    'donation-summaries',
  GIVING_SUMMARIES: 'giving-summaries',
  STAFF_SUMMARIES: 'staff-summaries',
  ACTIVITY_SUMMARY_OF_PEER_ORGS:
    'activity-summary-of-peer-orgs',
  DONATION_SUMMARY_OF_PEER_ORGS:
    'donation-summary-of-peer-orgs',
  GIVING_SUMMARY_OF_PEER_ORGS:
    'giving-summary-of-peer-orgs',
  STAFF_SUMMARY_OF_PEER_ORGS:
    'staff-summary-of-peer-orgs',
})

const with_menu_items = ({
  isDisabled = renderFalse,
}) => [
  {
    key: ItemKeys.STATISTIC,
    label: 'insights',
    icon: (
      <IoBarChartOutline size={16} />
    ),
  },
  {
    key: ItemKeys.CO_HOST_LIST,
    label: 'list of co-hosting',
    icon: (
      <Icon
        component={BsDiagram2Fill}
        rotate={180}
        style={{ fontSize: 16 }}
      />
    ),
  },
  {
    key: ItemKeys.CO_OWNER_LIST_GROUP,
    label: 'list of co-owners',
    icon: (
      <Icon
        component={BsDiagram3Fill}
        style={{ fontSize: 16 }}
      />
    ),
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageMember,
      ],
    }),
    children: [
      {
        key: ItemKeys.CO_OWNER_LIST,
        label: 'list of co-owners',
        icon: (
          <Icon
            component={BsDiagram3Fill}
            style={{ fontSize: 16 }}
          />
        ),
      },
      {
        key: ItemKeys.ACT_GROUP,
        label: 'activities of member',
        icon: (
          <Icon
            component={IoListOutline}
            style={{ fontSize: 16 }}
          />
        ),
        children: [
          {
            key: ItemKeys.RELATED_ACTIVITIES,
            label: 'related activities',
            icon: (
              <Icon
                component={
                  WishareRibbonSvg
                }
                style={{ fontSize: 16 }}
              />
            ),
          },
          {
            key: ItemKeys.RELATED_DONATION_EVENTS,
            label:
              'related donation events',
            icon: (
              <Icon
                component={
                  IoFlowerOutline
                }
                style={{ fontSize: 16 }}
              />
            ),
          },
          {
            key: ItemKeys.RELATED_GIVING_EVENTS,
            label:
              'related giving events',
            icon: (
              <Icon
                component={
                  IoGiftOutline
                }
                style={{ fontSize: 16 }}
              />
            ),
          },
          {
            key: ItemKeys.RELATED_RECRUITMENT_EVENTS,
            label:
              'related recruitments',
            icon: (
              <Icon
                component={IoPeople}
                style={{ fontSize: 16 }}
              />
            ),
          },
          {
            key: ItemKeys.RELATED_EVENTS,
            label: 'related events',
            icon: (
              <Icon
                component={
                  IoCalendarOutline
                }
                style={{ fontSize: 16 }}
              />
            ),
          },
        ].map(({ ...rest }) => ({
          ...rest,
          disabled: isDisabled({
            required_permissions: [
              organizationPermissionCodes.organizationManageMember,
            ],
          }),
        })),
      },
      {
        key: ItemKeys.CHILDREN_ORG_REPORT,
        label: 'report',
        icon: (
          <IoNewspaperOutline
            size={16}
          />
        ),
        children: [
          {
            key: ItemKeys.ACTIVITY_SUMMARIES,
            label: 'activities',
            icon: (
              <WishareRibbonSvg
                width="1rem"
                height="1rem"
                fill={'currentColor'}
              />
            ),
          },
          {
            key: ItemKeys.DONATION_SUMMARIES,
            label: 'donations',
            icon: (
              <RiUserSharedLine
                size={16}
              />
            ),
          },
          {
            key: ItemKeys.GIVING_SUMMARIES,
            label: 'givings',
            icon: (
              <RiUserReceivedLine
                size={16}
              />
            ),
          },
          {
            key: ItemKeys.STAFF_SUMMARIES,
            label: 'staffs',
            icon: (
              <RiUser2Line size={16} />
            ),
          },
        ].map(({ ...rest }) => ({
          ...rest,
          disabled: isDisabled({
            required_permissions: [
              organizationPermissionCodes.organizationManageMember,
            ],
          }),
        })),
      },
    ].map(({ ...rest }) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageMember,
        ],
      }),
    })),
  },
  {
    key: ItemKeys.PEER_MEMBERS_GROUP,
    label: 'peer organization',
    icon: (
      <Icon
        component={BsDiagram3}
        rotate={180}
        style={{ fontSize: 16 }}
      />
    ),
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManagePeerStructure,
      ],
    }),
    children: [
      {
        key: ItemKeys.PEER_MEMBERS,
        label: 'peer organization',
        icon: (
          <Icon
            component={BsDiagram3}
            rotate={180}
            style={{ fontSize: 16 }}
          />
        ),
      },
      {
        key: ItemKeys.PEER_ORG_REPORT,
        label: 'report',
        icon: (
          <IoNewspaperOutline
            size={16}
          />
        ),
        children: [
          {
            key: ItemKeys.ACTIVITY_SUMMARY_OF_PEER_ORGS,
            label: 'activities',
            icon: (
              <WishareRibbonSvg
                width="1rem"
                height="1rem"
                fill={'currentColor'}
              />
            ),
          },
          {
            key: ItemKeys.DONATION_SUMMARY_OF_PEER_ORGS,
            label: 'donations',
            icon: (
              <RiUserSharedLine
                size={16}
              />
            ),
          },
          {
            key: ItemKeys.GIVING_SUMMARY_OF_PEER_ORGS,
            label: 'givings',
            icon: (
              <RiUserReceivedLine
                size={16}
              />
            ),
          },
          {
            key: ItemKeys.STAFF_SUMMARY_OF_PEER_ORGS,
            label: 'staffs',
            icon: (
              <RiUser2Line size={16} />
            ),
          },
        ].map(({ ...rest }) => ({
          ...rest,
          disabled: isDisabled({
            required_permissions: [
              organizationPermissionCodes.organizationManagePeerStructure,
            ],
          }),
        })),
      },
    ].map(({ ...rest }) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManagePeerStructure,
        ],
      }),
    })),
  },
]

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceHostStatistic,
  },
  {
    key: ItemKeys.CO_HOST_LIST,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(WorkspaceCoHostList),
  },
  {
    key: ItemKeys.CO_OWNER_LIST,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(WorkspaceCoOwnerList),
  },
  {
    key: ItemKeys.RELATED_ACTIVITIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedActivities),
  },
  {
    key: ItemKeys.RELATED_GIVING_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedGivingEvents),
  },
  {
    key: ItemKeys.RELATED_DONATION_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedDonationEvents),
  },
  {
    key: ItemKeys.RELATED_RECRUITMENT_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedRecruitmentEvents),
  },
  {
    key: ItemKeys.RELATED_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedEvents),
  },
  {
    key: ItemKeys.PEER_MEMBERS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(WorkspaceHostPeerMembers),
  },
  {
    key: ItemKeys.ACTIVITY_SUMMARIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(ActivityHostReport),
  },
  {
    key: ItemKeys.DONATION_SUMMARIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(DonationHostReport),
  },
  {
    key: ItemKeys.GIVING_SUMMARIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(GivingHostReport),
  },
  {
    key: ItemKeys.STAFF_SUMMARIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(StaffHostReport),
  },
  {
    key: ItemKeys.ACTIVITY_SUMMARY_OF_PEER_ORGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(ActivityPeerOrgReport),
  },
  {
    key: ItemKeys.DONATION_SUMMARY_OF_PEER_ORGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(DonationPeerOrgReport),
  },
  {
    key: ItemKeys.GIVING_SUMMARY_OF_PEER_ORGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(GivingPeerOrgReport),
  },
  {
    key: ItemKeys.STAFF_SUMMARY_OF_PEER_ORGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(StaffPeerOrgReport),
  },
].map(({ key, ...rest }) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceHostsContent = () => {
  const {
    activeKey,
    Component,
    onMenuSelect,
  } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <WishareSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <PermissionContext.Consumer>
          {(permissions) => (
            <SettingsMenu
              items={with_menu_items({
                isDisabled:
                  invalidPermissions(
                    permissions
                  ),
              })}
              onSelect={onMenuSelect}
              defaultSelectedKeys={[
                activeKey,
              ]}
              defaultOpenKeys={[
                ItemKeys.ACT_GROUP,
                ItemKeys.CO_OWNER_LIST_GROUP,
                ItemKeys.CHILDREN_ORG_REPORT,
                ItemKeys.PEER_MEMBERS_GROUP,
                ItemKeys.PEER_ORG_REPORT,
              ]}
            />
          )}
        </PermissionContext.Consumer>
      </WishareSiderMenu>
    ),
    [with_menu_items, activeKey]
  )

  return (
    <DefaultTemplate
      renderMenu={renderMenu}>
      <div className="px-3">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceHostsContent
