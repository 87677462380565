import {
  getId,
  getType,
} from 'apis/model/base'
import WishareSiderMenu from 'components/layouts/Wishare/WishareSiderMenu'
import { organizationPermissionCodes } from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {
  useCallback,
} from 'react'
import {
  IoBarChartOutline,
  IoGiftOutline,
  IoListOutline,
  IoPersonOutline,
} from 'react-icons/io5'
import { RiUserReceivedLine } from 'react-icons/ri'
import {
  compose,
  fromRenderProps,
  withProps,
} from 'recompose'
import {
  renderFalse,
  renderOwnChild,
} from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import {
  createCheckPermissions,
  invalidPermissions,
} from 'views/Wishare/factory/createCheckPermissions'
import { SettingsMenu } from 'views/Wishare/factory/createSettingLayout'
import { BeneficiaryOwnerGivings } from 'views/Wishare/Organization/holders/OrganizationGivingList'
import DefaultTemplate from 'views/Wishare/Templates/DefaultTemplate'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceGivingEventList from '../Activities/WorkspaceGivingEventList'
import { useMenuHandler } from '../useMenuHandler'
import WorkspaceContext from '../WorkspaceContext'
import WorkspaceBeneficiariesList from './WorkspaceBeneficiariesList'
import WorkspaceBeneficiariesPendingGivings from './WorkspaceBeneficiariesPendingGivings'
import WorkspaceBeneficiariesRecentGivings from './WorkspaceBeneficiariesRecentGivings'
import WorkspaceBeneficiaryStatistic from './WorkspaceBeneficiaryStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  GIVING_EVENTS: 'giving-events',
  BENEFICIARIES: 'beneficiaries',
  RECENT_GIVINGS: 'recent-givings',
  PENDING_GIVINGS: 'pending-givings',
  ORGANIZATION_GIVINGS:
    'organization-givings',
  GIVING_GROUP: 'giving-group',
  BENEFICIARY_GROUP:
    'beneficiary-group',
})

const with_menu_items = ({
  isDisabled = renderFalse,
}) => [
  {
    key: ItemKeys.STATISTIC,
    label: 'insights',
    icon: (
      <IoBarChartOutline size={16} />
    ),
  },
  {
    key: ItemKeys.GIVING_GROUP,
    label: 'givings',
    icon: <IoGiftOutline size={16} />,
    children: [
      {
        key: ItemKeys.GIVING_EVENTS,
        label: 'campaign list',
        icon: (
          <IoListOutline size={16} />
        ),
      },
      {
        key: ItemKeys.RECENT_GIVINGS,
        label: 'list of giving',
        icon: (
          <IoListOutline size={16} />
        ),
      },
      {
        key: ItemKeys.PENDING_GIVINGS,
        label: 'pending givings',
        icon: (
          <IoListOutline size={16} />
        ),
      },
      {
        key: ItemKeys.ORGANIZATION_GIVINGS,
        label: 'giving of organization',
        icon: (
          <IoListOutline size={16} />
        ),
      },
    ],
  },
  {
    key: ItemKeys.BENEFICIARY_GROUP,
    label: 'beneficiaries',
    icon: <IoPersonOutline size={16} />,
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageBeneficiary,
      ],
    }),
    children: [
      {
        key: ItemKeys.BENEFICIARIES,
        label: 'beneficiaries',
        icon: (
          <RiUserReceivedLine
            size={16}
          />
        ),
      },
    ].map(({ ...rest }) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageBeneficiary,
        ],
      }),
    })),
  },
]

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component:
      WorkspaceBeneficiaryStatistic,
  },
  {
    key: ItemKeys.GIVING_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageGiving,
    ])(WorkspaceGivingEventList),
  },
  {
    key: ItemKeys.BENEFICIARIES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageBeneficiary,
    ])(WorkspaceBeneficiariesList),
  },
  {
    key: ItemKeys.RECENT_GIVINGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageGiving,
    ])(
      WorkspaceBeneficiariesRecentGivings
    ),
  },
  {
    key: ItemKeys.ORGANIZATION_GIVINGS,
    component: compose(
      fromRenderProps(
        WorkspaceContext.Consumer,
        ({ organization }) => ({
          owner_id: getId(organization),
          owner_type: getType(
            organization
          ),
        })
      ),
      createCheckPermissions([
        organizationPermissionCodes.organizationManageGiving,
      ]),
      withProps({
        Header: () => (
          <ListHeader title="giving of organization" />
        ),
      })
    )(BeneficiaryOwnerGivings),
  },
  {
    key: ItemKeys.PENDING_GIVINGS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageGiving,
    ])(
      WorkspaceBeneficiariesPendingGivings
    ),
  },
].map(({ key, ...rest }) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceGivingsContent = () => {
  const {
    activeKey,
    Component,
    onMenuSelect,
  } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <WishareSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <PermissionContext.Consumer>
          {(permissions) => (
            <SettingsMenu
              items={with_menu_items({
                isDisabled:
                  invalidPermissions(
                    permissions
                  ),
              })}
              onSelect={onMenuSelect}
              defaultSelectedKeys={[
                activeKey,
              ]}
              defaultOpenKeys={[
                ItemKeys.GIVING_GROUP,
                ItemKeys.BENEFICIARY_GROUP,
              ]}
            />
          )}
        </PermissionContext.Consumer>
      </WishareSiderMenu>
    ),
    [with_menu_items, activeKey]
  )

  return (
    <DefaultTemplate
      renderMenu={renderMenu}>
      <div className="px-3">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceGivingsContent
