import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import SeparatedDot from 'components/SeparatedDot'
import getItemAltId from 'helpers/getItemAltId'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { nest } from 'recompose'

const SmallFeedView = ({
  item,
  className
}) => {
  return (
    <Link
      key={item.id}
      to={{
        pathname: `/news/${item._type}/${item.id}`
        // state: {
        //   isModal: true
        // }
      }}
      className="p-3 hover:background-100 flex items-center border-b border-1 border-gray-300">
      <div className="flex-1 overflow-hidden">
        <div className="flex overflow-hidden">
          <h5 className="font-medium text-gray-700 flex-1 leading-tight hover:underline md:text-base text-lg">
            <SeparatedDot className="px-2 text-green-600" />
            {_.truncate(
              _.get(
                item,
                'overview.title'
              ),
              {
                length: 100
              }
            )}
          </h5>
        </div>
      </div>
    </Link>
  )
}

export const FeedMoreRecommends = nest(
  ({ list, item }) => {
    const t = useTranslate()

    return (
      <React.Fragment>
        {list.map((e, i) => (
          <SmallFeedView
            key={i}
            item={e}
          />
        ))}
        {!!item && (
          <div className="bottom-0 flex w-full">
            <div className="py-3  cursor-pointer px-3 flex-wrap flex w-full justify-center color-primary">
              <div className="flex-1" />
              <Link
                to={`/${item._type
                  }/${getItemAltId(
                    item
                  )}`}>
                <span>
                  {t('see more')}
                </span>
                <DoubleRightOutlined />
              </Link>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
)
