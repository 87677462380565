import { Avatar, Badge } from 'antd'
import {
  channelModel,
  organizationModel,
} from 'apis/model'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import chartSize from 'conts/chartSize'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { BiExpand } from 'react-icons/bi'
import { FcStatistics } from 'react-icons/fc'
import { Link } from 'react-router-dom'
import { ChartStateless } from 'views/Chart/Charts'

export const Widget = ({
  item,
  index = 1,
}) => {
  const [
    isViewCompact,
    setViewCompact,
  ] = useState(
    _.get(
      item,
      'display_mode',
      'regular'
    ) === 'compact'
  )
  let widgetC = null

  switch (_.get(item, 'owner._type')) {
    case 'chart':
      widgetC = isViewCompact ? (
        <div className="w-full p-3">
          <div
            onClick={() =>
              setViewCompact(
                !isViewCompact
              )
            }
            className="flex items-start justify-center leading-tight">
            <FcStatistics className="mr-2 text-2xl w-7 h-7" />
            <div className="font-bold flex-1 text-primary text-xl">
              {_.get(item, 'title')}
            </div>

            <BiExpand className="ml-2 text-color text-xl w-6 h-6 cursor-pointer" />
          </div>
          <div className="leading-tight text-color-300">
            {_.get(item, 'description')}
          </div>
        </div>
      ) : (
        <div
          style={{ minHeight: 180 }}
          className="w-full px-3 py-2 flex-1 h-full relative">
          <ChartStateless
            item={_.get(item, 'owner')}
            size={chartSize.SMALL}
            hidedescription={true}
            hidenote={true}
            hideactionbar={true}
          />
        </div>
      )
      break
    case 'channel':
      switch (
        _.get(
          item,
          'display_mode',
          'regular'
        )
      ) {
        case 'regular':
        default:
          widgetC = (
            <div className="w-full  p-3">
              <Link
                to={channelModel.getLinkToDetail(
                  _.get(item, 'owner')
                )}
                className="flex items-center justify-center ">
                <ChannelIcon
                  className="mr-2 text-3xl w-8 h-8"
                  premium={_.get(
                    item,
                    'owner.premium'
                  )}
                />
                <div className="font-medium flex-1">
                  {_.get(item, 'title')}
                </div>
                {_.get(
                  item,
                  'owner.has_unread',
                  false
                ) && (
                  <Badge
                    dot
                    color="green"
                  />
                )}
              </Link>
              <div className="">
                {_.get(
                  item,
                  'description'
                )}
              </div>
            </div>
          )
          break
      }
      break

    case 'organization':
      switch (
        _.get(
          item,
          'display_mode',
          'regular'
        )
      ) {
        case 'regular':
        default:
          widgetC = (
            <div className="w-full">
              <Link
                to={organizationModel.getLinkToDetail(
                  _.get(item, 'owner')
                )}
                className="flex items-center justify-center">
                <Avatar
                  shape="square"
                  size={32}
                  style={{
                    background:
                      'var(--color-primary)',
                  }}
                  className="mr-2 text-color-100 text-3xl w-8 h-8 rounded object-cover border border-gray-100"
                  src={organizationModel.getFullAvatarUrl(
                    _.get(item, 'owner')
                  )}
                  alt="">
                  {getUpperFirstChar(
                    organizationModel.getTitle(
                      _.get(
                        item,
                        'owner'
                      )
                    )
                  )}
                </Avatar>

                <div className="font-bold flex-1">
                  {_.get(item, 'title')}
                </div>
              </Link>
              <div className="">
                {_.get(
                  item,
                  'description'
                )}
              </div>
            </div>
          )
          break
      }
      break
    default:
      widgetC = (
        <div className="p-3">
          {_.get(item, 'title')}
        </div>
      )
      break
  }

  return (
    <div className="flex items-center background-100 hover:background-200 w-full inline-flex items-center justify-start text-color-300">
      {widgetC}
    </div>
  )
}

export const FeedWidget = ({
  item,
}) => {
  const t = useTranslate()

  if (
    !(
      _.has(item, 'content') &&
      !_.isEmpty(item.content)
    )
  )
    return null

  return (
    <div className="rounded overflow-hidden border border-gray-300">
      <Widget
        key={item.content.id}
        item={item.content}
      />
    </div>
  )
}
