import { organization_Edit_Api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import CustomRichEditor from 'views/Wishare/custom/CustomRichEditor'
import createEditableForm from 'views/Wishare/factory/createEditableForm'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import OrganizationContainerContext from 'views/Wishare/Organization/OrganizationContainerContext'

const formFields = [{ name: 'about' }]

const formSchema = [
  {
    title: 'about',
    children: [
      {
        name: 'about',
        component:
          createControlledFormField({
            placeholder: 'about',
            Component: CustomRichEditor,
          }),
      },
    ],
  },
]

const OrganizationSettingAbout = () => {
  const { organization } = useContext(
    OrganizationContainerContext
  )

  const id = getId(organization)

  const validationSchema = undefined

  if (_.isEmpty(organization))
    return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    validationSchema,
    item: organization,
    query: bindQueryParam({
      id,
    }),
    apiInfo: organization_Edit_Api,
  })
}

export default OrganizationSettingAbout
