import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {
  useContext,
  useMemo,
} from 'react'
import { Redirect } from 'react-router-dom'
import { SubDomainPaths } from 'views/Organization/OrganizationSubDomain'
import { notEmpty } from 'views/Shared'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { WishareEntities } from '../enums'

const defaultPermission = [
  permissionCodes.canEdit,
]

const permissionContainer = {
  [permissionCodes.canEdit]: 'edit',
}

const transform =
  (type, item) => (permission) => {
    switch (type) {
      case WishareEntities.BENEFICIARY:
      case WishareEntities.GIVING:
      case WishareEntities.DONATION:
      case WishareEntities.RECRUITMENT:
      case WishareEntities.ORGANIZATION:
      case WishareEntities.ACTIVITY:
      case WishareEntities.CATEGORY:
      case WishareEntities.TOPIC:
      case WishareEntities.USER:
      case WishareEntities.DONOR:
        if (
          _.has(
            permissionContainer,
            permission
          )
        ) {
          return {
            name: permission,
            value: Boolean(
              _.get(
                item,
                permissionContainer[
                  permission
                ]
              )
            ),
          }
        }
        break
      default:
        break
    }
    return {}
  }

export const PermissionEntity = ({
  item = {},
  children,
  permissions = defaultPermission,
}) => {
  const type = getType(item)

  const { id: domain_id, diff_root } =
    useContext(SubDomainContext)

  const values = useMemo(
    () =>
      permissions
        .map(transform(type, item))
        .filter(notEmpty)
        .reduce(
          (prev, { name, value }) => {
            return {
              ...prev,
              [name]: value,
            }
          },
          {}
        ),
    [type, item, permissions]
  )

  const willRedirect = useMemo(() => {
    const condition =
      !!diff_root && !_.isEmpty(item)

    switch (type) {
      case WishareEntities.USER:
      case WishareEntities.TOPIC:
      case WishareEntities.CATEGORY:
        return false
      case WishareEntities.GIVING:
      case WishareEntities.DONATION:
      case WishareEntities.RECRUITMENT:
        return Boolean(
          condition &&
            getId(
              _.get(item, 'root_owner')
            ) !== domain_id
        )
      default:
        return Boolean(
          condition &&
            getId(
              _.get(item, 'owner')
            ) !== domain_id
        )
    }
  }, [type, item, domain_id, diff_root])

  if (!!willRedirect) {
    return (
      <Redirect
        to={SubDomainPaths.NOT_FOUND}
      />
    )
  }

  return (
    <PermissionProvider
      permissions={{
        ...values,
      }}>
      {children}
    </PermissionProvider>
  )
}
