import { Input } from 'antd'
import { staff_getStaffRosters_Api } from 'apis'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { withProps } from 'recompose'
import MedalsByOwner from 'views/MainPage/WorkSpace/Medal/MedalsByOwner'
import { Null } from 'views/Shared'
import { useDebounceSearch } from 'views/Wishare/custom/DebounceSearch'
import EntityList from 'views/Wishare/EntityList'
import { WishareEntities } from 'views/Wishare/enums'
import { StaffsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import { renderProfilesStaffRosters } from './renderProfilesStaffRosters'
import withProfiles from './withProfiles'

const StaffRosters = ({
  staff_id,
  organization_id,
  Header = Null,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const [sort_by, set_sort_by] =
    useState()

  const [keyword, onSearch] =
    useDebounceSearch({
      timeout: 300,
    })

  const apiInfo =
    staff_getStaffRosters_Api

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header />
      <div className="uppercase font-medium text-color-300 mb-2">
        {t('list of staff roster')}
      </div>
      <Input.Search
        className="input-search"
        placeholder={t(
          'type to search'
        )}
        onSearch={(value) =>
          onSearch(value)
        }
      />
      <div className="flex flex-col items-end">
        <StaffsSortBy
          onSelect={(value) => {
            set_sort_by(value)
          }}
        />
      </div>
      <EntityList
        key={[
          keyword,
          sort_by,
          apiInfo.path.replace(
            ':staff_id',
            staff_id
          ),
        ].join('/')}
        query={bindQueryParam({
          staff_id,
        })}
        values={{
          keyword,
          sort_by,
        }}
        apiInfo={apiInfo}
        renderItem={renderProfilesStaffRosters(
          {
            staff_id,
            owner_id: organization_id,
            owner_type:
              WishareEntities.ORGANIZATION,
          }
        )}
        RenderEntity={React.Fragment}
      />
    </Wrapper>
  )
}

export const OrganizationProfilesStaffRosters =
  withProfiles(
    withProps(
      ({ id, organization_id }) => ({
        staff_id: id,
        organization_id,
      })
    )(StaffRosters)
  )

export const OrganizationProfilesDonorMedalRewards =
  withProfiles(
    withProps(({ id, type }) => ({
      owner_id: id,
      owner_type: type,
    }))(MedalsByOwner)
  )
