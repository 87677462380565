import {Avatar, List} from 'antd'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import getAvatar from "../../../../helpers/getAvatar";
import {IoGiftOutline, IoPerson} from "react-icons/io5";
import moment from "moment";

export const renderHomeCandidate = (
  item,
  index
) => {
  const {
    deleted,
    isDeleted,
    object_name,
      alias_name
  } = item || {}

  if (
    _.some(
      [
        isDeleted,
        deleted,
        _.get(item, 'isDeleted'),
        _.get(item, 'deleted'),
      ],
      Boolean
    )
  ) {
    return null
  }

  return (
    <Translate key={index}>
      {(t) => (
        <div className="w-full flex flex-col justify-center gap-2">
          <List.Item className="p-0">
            <List.Item.Meta
              title={
                  <div className="flex items-baseline gap-1 italic">
                      <Link to={getLinkToDetail(_.get(item, 'candidate'))}
                            className="text-xs font-medium text-color-000 max-lines-1">
                          {_.get(item, 'candidate.name') || alias_name || t('undefined')}
                      </Link>
                      <span className="text-2xs font-light text-color-400 whitespace-no-wrap">{t('applied for the job')}</span>
                  </div>
              }
              description={
                  <div className="flex items-baseline justify-between">
                      <Link to={getLinkToDetail(_.get(item, 'recruitment_event'))}
                            className="font-semibold max-lines-1 text-green-600 leading-tight flex-1">
                          {getTitle(_.get(item, 'recruitment_event'))}
                      </Link>
                      <span className="text-2xs font-light text-color-400 italic ml-3">
                        {moment(_.get(item, 'created')).fromNow()}
                      </span>
                  </div>
              }
              className="flex items-center gap-2"
              avatar={
                  <Avatar
                      src={_.get(item, 'candidate.avatar')}
                      size={40}
                      icon={<IoPerson className="text-color-200"/>}
                      className="flex items-center justify-center background-200"
                  />
              }
            />
          </List.Item>
        </div>
      )}
    </Translate>
  )
}
