import { Table } from 'antd'
import {
  medal_getMedalStats_Api,
  medal_getMedalSummary_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import Pure from 'components/Pure'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  useLocation,
  useSearchParam,
} from 'react-use'
import {
  getResponseItem,
  getResponseItems,
  Null,
} from 'views/Shared'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/Wishare/custom/AntdConfigWrapper'
import { SummaryMedalsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import WorkspaceInsightsMetaSummary from '../Insights/WorkspaceInsightsMetaSummary'
import { PeriodFilterHeader } from '../Insights/WorkspaceInsightsSummary'
import WorkspaceContext, {
  WorkspaceFilterParams,
} from '../WorkspaceContext'

const renderColumnHeader = (label) => (
  <Translate>
    {(t) => (
      <div className="italic font-light text-color-400 text-sm text-center">
        {t(label)}
      </div>
    )}
  </Translate>
)

const MedalSummaryTable = ({
  sortBy,
  keyword,
  filterBy,
  refreshKey,
  organization_id,
}) => {
  const values = {
    keyword,
    sort_by: sortBy,
    ...(filterBy || {}),
  }

  const {
    response,
    isLoading = false,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: medal_getMedalSummary_Api,
    query: bindQueryParam({
      organization_id,
    }),
    values,
  })

  useEffect(() => {
    handleAsyncAction({
      values,
    })
  }, [refreshKey])

  const dataSource = useMemo(() => {
    const items =
      getResponseItems(response)

    return Array.from(items).map(
      (item, index) => ({
        ...item,
        key: index,
        render: (text) => (
          <span className="text-xs text-color-500">
            {text}
          </span>
        ),
      })
    )
  }, [response])

  return (
    <div
      className="border"
      style={
        !!isLoading
          ? undefined
          : {
              borderBottom: 'none',
            }
      }>
      <AntdConfigWrapper
        renderEmpty={() =>
          !!isLoading ? (
            <div className="h-8" />
          ) : (
            <div className="content_disabled">
              <RenderEmptyMeta />
            </div>
          )
        }>
        <Table
          size="small"
          columns={[
            {
              key: 'title',
              dataIndex: 'title',
              title:
                renderColumnHeader(
                  'title'
                ),
              align: 'center',
            },
            {
              key: 'active',
              dataIndex: 'active',
              title:
                renderColumnHeader(
                  'active'
                ),
              align: 'center',
            },
            {
              key: 'level',
              dataIndex: 'lvl_number',
              title:
                renderColumnHeader(
                  'level'
                ),
              align: 'center',
            },
            {
              key: 'total-donations',
              dataIndex: [
                'total',
                'donation_count',
              ],
              title: renderColumnHeader(
                'total donation'
              ),
              align: 'center',
            },
            {
              key: 'total-donors',
              dataIndex: [
                'total',
                'donor_count',
              ],
              title: renderColumnHeader(
                'total donors'
              ),
              align: 'center',
            },
            {
              key: 'total-staff',
              dataIndex: [
                'total',
                'staff_count',
              ],
              title: renderColumnHeader(
                'donations'
              ),
              align: 'center',
            },
            {
              key: 'total-staff-roster',
              dataIndex: [
                'total',
                'staff_roster_count',
              ],
              title: renderColumnHeader(
                'total staff-rosters'
              ),
              align: 'center',
            },
          ]}
          pagination={false}
          loading={isLoading}
          dataSource={dataSource}
        />
      </AntdConfigWrapper>
    </div>
  )
}

const MedalInsightsSummary = ({
  style,
  className,
  organization_id,
  filter_actions = {
    active: 'active',
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      organization_id,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [organization_id, location.state]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
        onSortChange = Null,
      }) => {
        onSortChange(sortBy)
      },
      renderList: ({
        sortBy,
        keyword,
      }) => (
        <MedalSummaryTable
          {...{
            sortBy,
            keyword,
            organization_id,
          }}
          refreshKey={[
            ...refresh_deps,
            sortBy,
            keyword,
          ].join('/')}
        />
      ),
      renderFilter: Null,
      renderSearchBar: Null,
      query_params: filter_actions,
      sortHeader: (
        <div className="text-color-200 uppercase font-medium text-lg">
          {t('medal summary')}
        </div>
      ),
      SortByWidget: SummaryMedalsSortBy,
    })

  return (
    <div
      style={style}
      className={className}>
      {pager_widget}
    </div>
  )
}

const WorkspaceMedalsStatistic = () => {
  const {
    organization,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  const period = getPeriod(
    WorkspaceFilterParams.OVERVIEW
  )

  const organization_id = getId(
    organization
  )

  const {
    response,
    isLoading = false,
    handleAsyncAction = Null,
  } = useAsync({
    apiInfo: medal_getMedalStats_Api,
    query: bindQueryParam({
      organization_id,
    }),
  })

  const { summary } =
    getResponseItem(response)

  return (
    <React.Fragment>
      <Pure
        input={[
          period,
          summary,
          isLoading,
          handleAsyncAction,
        ]}>
        <WorkspaceInsightsMetaSummary
          summary={summary}
          isLoading={isLoading}
          className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-8"
          Header={({ title }) => (
            <PeriodFilterHeader
              title={title}
              value={period}
              onSelect={(period) => {
                setPeriod(
                  WorkspaceFilterParams.OVERVIEW,
                  period
                )
                handleAsyncAction({
                  values: {
                    period,
                  },
                })
              }}
            />
          )}
          field_params={[
            {
              name: 'total_donation_count',
              label: 'total donation',
              extra_name:
                'total_donation_count_prev',
            },
            {
              name: 'total_donor_count',
              label: 'total donors',
              extra_name:
                'total_donor_count_prev',
            },
            {
              name: 'total_staff_count',
              label: 'total staffs',
              extra_name:
                'total_staff_count_prev',
            },
            {
              name: 'total_staff_roster_count',
              label:
                'total staff-rosters',
              extra_name:
                'total_staff_roster_count_prev',
            },
          ]}
        />
      </Pure>
      <Pure>
        <MedalInsightsSummary
          organization_id={
            organization_id
          }
          className="grid grid-cols-1 md:grid-cols-1 gap-2 pb-10"
        />
      </Pure>
    </React.Fragment>
  )
}

export default WorkspaceMedalsStatistic
