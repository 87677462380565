import {
  Button,
  List,
  Steps,
} from 'antd'
import {
  getId,
  getType,
} from 'apis/model/base'
import { organizationSchema } from 'apis/schema'
import AppIcon from 'components/AppIcon'
import DecoratedInputField from 'components/form/fields/DecoratedInputField'
import { ObjectSelectField } from 'components/form/fields/ObjectSelectField'
import { CustomToggleField } from 'components/form/fields/ToggleField'
import FieldsFactory from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import { FormikConsumer } from 'formik'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  compose,
  mapProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import {
  getAllPackages,
  getCurrentPackageIndex,
  getLogin,
} from 'redux/selectors'

const renderOwnerItem = ({
  id,
  type,
  label,
  avatar,
  actions,
}) => (
  <List.Item
    className="p-0"
    actions={actions}>
    <List.Item.Meta
      title={
        <div className="flex flex-col gap-1">
          <span className="font-medium text-xs leading-tight">
            {label}
          </span>
          {!!type && (
            <Translate>
              {(t) => (
                <span className="text-2xs text-secondary leading-tight italic">
                  {t(type)}
                </span>
              )}
            </Translate>
          )}
        </div>
      }
      prefixCls="OptionMetaItem"
      className="flex gap-2 items-center"
      avatar={
        <AppIcon
          size={30}
          item={{
            id,
            avatar,
            title: label,
            _type: type,
          }}
        />
      }
    />
  </List.Item>
)

const FirstScreen = () => {
  const t = useTranslate()
  const login = useSelector(getLogin)

  const allPackages =
    getAllPackages(login) || []
  const current =
    getCurrentPackageIndex(login) || 0
  const currentPackage =
    _.get(allPackages, current) || {}

  const channels_maximum = _.get(
    currentPackage,
    'channels_maximum'
  )
  const total_channels = _.get(
    login,
    'total_channels'
  )
  const organization_ids = _.get(
    login,
    'organizations',
    []
  )

  const organizations =
    useSelectEntities(
      organization_ids,
      [organizationSchema]
    )

  const owner_options = [
    {
      id: getId(login),
      type: getType(login),
      value: getId(login),
      label: login.name,
      title: getTitle(login),
      avatar: getAvatar(login),
    },
    ...organizations.map((e) => ({
      id: getId(e),
      type: getType(e),
      value: getId(e),
      label: e.title,
      title: getTitle(e),
      avatar: getAvatar(e),
    })),
  ].map(({ label, ...rest }) => ({
    label: renderOwnerItem({
      label,
      ...rest,
      actions: [
        <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
          {t('choose')}
        </span>,
      ],
    }),
    render_as: renderOwnerItem({
      label,
      ...rest,
    }),
    ...rest,
  }))

  return (
    <>
      {channels_maximum <=
        total_channels + 3 && (
        <div className="p-3 flex-col rounded border border-dashed verticalList">
          <div>
            {t(
              'Number of your channels:'
            )}
            <span className="font-bold px-1">
              {total_channels}
            </span>
          </div>
          <div>
            <Steps
              current={current}
              size="small">
              {allPackages.map((p) => (
                <Steps.Step
                  key={p.name}
                  title={t(p.name)}
                  description={
                    p.channels_maximum ===
                    9999
                      ? t('unlimited')
                      : t(
                          '{n} channels',
                          {
                            n: p.channels_maximum,
                          }
                        )
                  }
                />
              ))}
            </Steps>
            <div className="py-3 mt-3">
              {channels_maximum === 9999
                ? t('unlimited')
                : t(
                    'you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account',
                    {
                      n: channels_maximum,
                    }
                  )}
            </div>
          </div>
        </div>
      )}
      <FieldsFactory
        formSchema={[
          {
            children: {
              name: 'title',
              component: compose(
                withTranslate,
                mapProps(
                  ({
                    name,
                    value,
                    onChange,
                    translate,
                  }) => ({
                    name,
                    value,
                    onChange,
                    size: 'large',
                    bordered: false,
                    label: translate(
                      'what your channel name?'
                    ),
                    autoFocus: true,
                  })
                )
              )(DecoratedInputField),
            },
          },
          {
            children: {
              name: 'owner',
              component: mapProps(
                ({
                  name,
                  value,
                  form = {},
                  onChange,
                }) => {
                  const defaultValue =
                    _.get(
                      form.values,
                      'default_owner_id'
                    )
                  return {
                    name,
                    value,
                    onChange,
                    bordered: false,
                    size: 'large',
                    label: t('owner'),
                    defaultValue:
                      _.find(
                        owner_options,
                        {
                          value:
                            defaultValue,
                        }
                      ),
                    showSearch: true,
                    options:
                      owner_options,
                    optionLabelProp:
                      'render_as',
                    optionFilterProp:
                      'title',
                  }
                }
              )(ObjectSelectField),
            },
          },
          {
            children: {
              title: 'private channel',
              name: 'privacy',
              component: compose(
                withTranslate,
                mapProps(
                  ({
                    name,
                    value,
                    onChange,
                    translate,
                  }) => ({
                    name,
                    value:
                      Number(value) ===
                      2,
                    onChange: (
                      value
                    ) => {
                      onChange(
                        createValue(
                          name,
                          Boolean(value)
                            ? 2
                            : 1
                        )
                      )
                    },
                    title: translate(
                      'private channel'
                    ),
                    description:
                      translate(
                        'by making a channel private, only members will have access to this channel'
                      ),
                  })
                )
              )(CustomToggleField),
            },
          },
        ]}
      />
    </>
  )
}
const createChannelFormRoutes = [
  {
    path: '/first-screen',
    name: 'create channel',
    component: FirstScreen,
    exact: true,
  },
]
export default function CreateChannelForm({
  asyncData: { isLoading } = {},
  handleSubmit,
  routes = createChannelFormRoutes,
}) {
  const t = useTranslate()
  const [
    currentIndex,
    setCurrentIndex,
  ] = useState(0)

  const handleOnOk = () => {
    setCurrentIndex(
      Math.min(
        routes.length - 1,
        currentIndex + 1
      )
    )
    if (
      currentIndex ===
      routes.length - 1
    ) {
      handleSubmit()
    }
  }

  const handleOnCancel = () =>
    setCurrentIndex(
      Math.max(0, currentIndex - 1)
    )

  const C =
    createChannelFormRoutes[
      currentIndex
    ].component

  return (
    <div className="flex flex-1 flex-col verticalList">
      <div className="text-center uppercase font-bold text-3xl p-3 mt-6">
        {t(
          createChannelFormRoutes[currentIndex].name
        )}
      </div>
      <C
        {...{
          onNext: handleOnOk,
          onBack: handleOnCancel,
        }}
      />
      <div className="flex-1" />
      <div className="sticky bottom-0 flex justify-center py-6 ">
        <FormikConsumer>
          {({ errors }) => (
            <Button
              disabled={
                !_.isEmpty(errors)
              }
              loading={isLoading}
              className="capitalize no-shadow no-text-shadow rounded-lg"
              type="primary"
              onClick={handleOnOk}>
              {t('create')}
            </Button>
          )}
        </FormikConsumer>
      </div>
    </div>
  )
}
