import { PlusOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { createValue } from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  Null,
  renderOwnChild,
} from 'views/Shared'
import FormListHeader from 'views/Wishare/custom/FormListHeader'

const DefaultField = ({
  index,
  remove,
}) => (
  <FormListHeader
    title={`item ${index + 1}`}
    onRemove={remove}
  />
)

const GivingDyanmicFields = ({
  name,
  value = [],
  formInstance,
  edit = false,
  onChange = Null,
  canCreate = true,
  Item = DefaultField,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const t = useTranslate()

  const [form] = formInstance
    ? [formInstance]
    : Form.useForm()

  return (
    <Wrapper
      {...props}
      edit={edit}>
      <Form
        form={form}
        autoComplete="off"
        onValuesChange={(
          changedValues,
          values
        ) => {
          onChange(
            createValue(
              name,
              _.get(values, name)
            )
          )
        }}>
        <Form.List
          name={name}
          initialValue={value}>
          {(
            fields,
            { add, remove },
            { errors }
          ) => (
            <React.Fragment>
              {fields.map(
                (field, index) => (
                  <Form.Item
                    {...field}
                    key={field.key}>
                    <Item
                      index={index}
                      listName={name}
                      remove={() =>
                        remove(
                          field.name
                        )
                      }
                    />
                  </Form.Item>
                )
              )}
              {Boolean(
                canCreate && edit
              ) && (
                <Form.Item>
                  <Button
                    type="primary"
                    className="rounded-lg no-shadow no-text-shadow"
                    onClick={() =>
                      add()
                    }
                    icon={
                      <PlusOutlined />
                    }>
                    {t('add')}
                  </Button>
                  <Form.ErrorList
                    errors={errors}
                  />
                </Form.Item>
              )}
            </React.Fragment>
          )}
        </Form.List>
      </Form>
    </Wrapper>
  )
}

export default GivingDyanmicFields
