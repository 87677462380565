import Icon from '@ant-design/icons'
import {
  Alert,
  Avatar,
  Badge,
  Divider,
  Menu,
  message,
} from 'antd'
import {
  auth_getProps_Api,
  auth_updateOrg_Api,
  memberStatusTypes,
} from 'apis'
import {
  organizationModel,
  userModel,
} from 'apis/model'
import {
  channelSchema,
  organizationSchema,
} from 'apis/schema'
import {
  default as classnames,
  default as classNames,
} from 'classnames'
import ContentContainer from 'components/ContentContainer'
import DrawMenu from 'components/drawer/DrawMenu'
import EmptyHolder from 'components/EmptyHolder'
import AppTextLogoIcon from 'components/icons/AppTextLogoIcon'
import { FlagIcon } from 'components/icons/FlagIcon'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LanguageSwitch } from 'components/layouts/Default/SiderMenu/LanguageSwitch'
import LazyComponent from 'components/LazyComponent'
import { LoginContext } from 'components/LoginContext'
import Modal from 'components/Modal/Modal'
import Toggle from 'components/Toggle'
import { ENV_LABEL } from 'envs/_current/config'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import { LocalContext } from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
} from 'react'
import {
  AiOutlineLogin,
  AiOutlineSetting,
} from 'react-icons/ai'
import {
  FaAngleDown,
  FaSearch,
  FaSignOutAlt,
} from 'react-icons/fa'
import {
  FiHelpCircle,
  FiPlus,
  FiSettings,
} from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import { IoMdHelpCircle } from 'react-icons/io'
import { RiBookmarkLine } from 'react-icons/ri'
import {
  connect,
  ReactReduxContext,
  useSelector,
} from 'react-redux'
import { Link } from 'react-router-dom'
import { selectEntities } from 'redux/selectors'
import { staticPaths } from 'routes/staticPaths'
import ServerContext from 'views/Server/ServerContext'
import { APP_COPYRIGHT } from '../../envs/_current/config'
import {
  emptyArray,
  emptyObject,
} from '../../helpers/emptyObjects'
import { HeaderButton } from '../Organization/components/PageHeader'
import { AllChannels } from './components/UserMenu/AllChannels'
import { Helps } from './components/UserMenu/Helps'
import { MyChannels } from './components/UserMenu/MyChannels'
import { OrgChannels } from './components/UserMenu/OrgChannels'

const Lang = connect((state) => ({
  lang: state.i18nState.lang,
}))(({ lang }) => {
  const { store } = useContext(
    ReactReduxContext
  )
  const { translate: t, changeLang } =
    useContext(LocalContext)

  return (
    <div className="flex flex-wrap justify-center pb-2">
      {[
        {
          id: 'vi',
          value: 'vi',
          label: 'vietnamese',
        },
        {
          id: 'en',
          value: 'en',
          label: 'english',
        },
        {
          id: 'fr',
          value: 'fr',
          label: 'fr',
        },
        {
          id: 'km',
          value: 'km',
          label: 'km',
        },
        {
          id: 'ko',
          value: 'ko',
          label: 'ko',
        },
      ].map(
        ({ id, value, label }, key) => (
          <div
            key={key}
            onClick={() => {
              changeLang(value)
            }}
            className={classnames(
              'text-xs mr-3',
              lang === value
                ? 'font-bold'
                : 'underline cursor-pointer'
            )}>
            {t(label)}
          </div>
        )
      )}
    </div>
  )
})

const UserBlock = ({ item }) => {
  return (
    <div className="p-2 flex w-full items-center truncate">
      <Avatar
        size={42}
        className="mx-2"
        shape="circle"
        src={userModel.getFullAvatarUrl(
          item
        )}></Avatar>
      <Link
        to={staticPaths.account}
        className="px-1 flex-1 truncate font-bold text-xl">
        {userModel.getTitle(item)}
      </Link>
      <HeaderButton className="mx-1 text-red-600 flex items-center justify-center">
        <Link to={staticPaths.logout}>
          <FaSignOutAlt
            style={{
              fontSize: '1.4em',
            }}
          />
        </Link>
      </HeaderButton>
    </div>
  )
}

const OrgBlock = ({
  primaryOrg,
  organizations,
}) => {
  const t = useTranslate()
  const {
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: auth_updateOrg_Api,
    onSuccess: (result) => {
      message.info(t('success'))
    },
    onError: () =>
      message.error(t('error')),
  })

  const setPrimaryOrg = (
    organization_id
  ) => {
    handleAsyncPost({ organization_id })
  }

  return (
    <div className="flex flex-col">
      <div className="pl-2 py-2 pr-1 flex items-center">
        {primaryOrg ? (
          <div className="flex items-center truncate">
            <Avatar
              size={42}
              className="border border-gray-300 mx-2"
              shape="square"
              src={organizationModel.getFullAvatarUrl(
                primaryOrg
              )}>
              {getUpperFirstChar(
                organizationModel.getTitle(
                  primaryOrg
                )
              )}
            </Avatar>
            <Link
              to={organizationModel.getLinkToDetail(
                primaryOrg
              )}
              className="px-1 flex-1 truncate font-semibold text-xl">
              {organizationModel.getTitle(
                primaryOrg
              )}
            </Link>
          </div>
        ) : (
          <div className="flex items-center">
            <div
              style={{
                width: '32px',
                height: '32px',
              }}
              className="mx-2 items-center justify-center">
              <FiPlus
                style={{
                  fontSize: '2.5rem',
                }}
              />
            </div>
            <Link
              to={
                staticPaths.createOrganization
              }>
              <div className="px-2">
                {t('create provider')}
              </div>
            </Link>
          </div>
        )}
        <div className="flex-1" />
        <DrawMenu
          component={
            <HeaderButton>
              <FaAngleDown />
            </HeaderButton>
          }>
          {(isToggle, toggle) => (
            <Menu
              style={{
                minWidth: '280px',
              }}>
              <Menu.Item
                key="header"
                className="py-0 my-0">
                <div className="flex font-bold uppercase text-xs px-3 py-1">
                  <div className="flex-1 text-color-300">
                    {t('others')}
                  </div>
                  <Toggle>
                    {(
                      isToggle,
                      toggle
                    ) => (
                      <>
                        <div
                          className="text-blue-500"
                          onClick={() => {
                            toggle()
                          }}>
                          {t(
                            'view all'
                          )}
                        </div>
                        {isToggle && (
                          <Modal
                            header={
                              null
                            }
                            footer={
                              null
                            }
                            visible={
                              true
                            }
                            onCancel={
                              toggle
                            }>
                            <div className="px-3">
                              <ChoosePrimaryOrganization
                                toggle={
                                  toggle
                                }
                              />
                            </div>
                          </Modal>
                        )}
                      </>
                    )}
                  </Toggle>
                </div>
              </Menu.Item>

              <Menu.Divider />
              {!(
                organizations &&
                organizations.length
              ) ? (
                <EmptyHolder />
              ) : (
                organizations.map(
                  (o, i) =>
                    (i < 5 || null) && (
                      <Menu.Item
                        key={i}
                        className="flex items-center"
                        onClick={() =>
                          setPrimaryOrg(
                            o.id
                          )
                        }>
                        <Avatar
                          size={32}
                          className={classnames(
                            'border border-gray-300 mx-2',
                            Number(
                              o.join_status
                            ) !== 1
                              ? 'opacity-25'
                              : ''
                          )}
                          shape="square"
                          src={organizationModel.getThumbnailUrl(
                            o
                          )}>
                          {getUpperFirstChar(
                            organizationModel.getTitle(
                              o
                            )
                          )}
                        </Avatar>
                        <div className="flex flex-col">
                          <div className="px-2 truncate font-semibold">
                            {organizationModel.getTitle(
                              o
                            )}
                          </div>
                          {Number(
                            o.join_status
                          ) !== 1 && (
                            <div className="font-light text-xs m-0 px-2">
                              {t(
                                memberStatusTypes[
                                  String(
                                    Number(
                                      o.join_status
                                    )
                                  )
                                ]
                                  ?.label ||
                                  ''
                              )}
                            </div>
                          )}
                        </div>
                      </Menu.Item>
                    )
                )
              )}
              <Menu.Divider />
              {primaryOrg &&
                !!primaryOrg.edit && (
                  <Menu.Item
                    key="org-setting"
                    className="flex items-center">
                    <div
                      style={{
                        width: '32px',
                        // height: '32px',
                      }}
                      className="mx-2 flex items-center justify-center">
                      <FiSettings
                        style={{
                          fontSize:
                            '1rem',
                        }}
                      />
                    </div>
                    <Link
                      to={organizationModel.getLinkToSetting(
                        primaryOrg
                      )}>
                      <div className="px-2">
                        {t('settings')}
                      </div>
                    </Link>
                  </Menu.Item>
                )}
              {primaryOrg &&
                !!primaryOrg.edit && (
                  <Menu.Divider />
                )}
              <Menu.Item
                key="create-new"
                className="flex items-center">
                <div
                  style={{
                    width: '32px',
                    // height: '32px'
                  }}
                  className="mx-2 my-1 flex items-center justify-center">
                  <FiPlus
                    style={{
                      fontSize:
                        '1.5rem',
                    }}
                  />
                </div>
                <Link
                  to={
                    staticPaths.createOrganization
                  }>
                  <div className="px-2">
                    {t(
                      'Create provider profile'
                    )}
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="dicovery-new"
                className="flex items-center">
                <div
                  style={{
                    width: '32px',
                    // height: '32px'
                  }}
                  className="mx-2 my-1 flex items-center justify-center">
                  <GoSearch
                    style={{
                      fontSize:
                        '1.2rem',
                    }}
                  />
                </div>
                <Link
                  to={`${staticPaths.discovery.pathname}?type=organization`}>
                  <div className="px-2">
                    {t(
                      'discovery more providers'
                    )}
                  </div>
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </DrawMenu>
      </div>

      {_.isEmpty(primaryOrg) && (
        <Alert
          type="info"
          closable
          showIcon={false}
          banner
          className="text-center background-100"
          message={
            <div className="py-4">
              <div className="pt-2 flex justify-center items-center text-sm m-auto">
                {t(
                  'Create a new provider so you can post products or manage your information channels.'
                )}
              </div>
            </div>
          }></Alert>
      )}
    </div>
  )
}

const ExtendMenuBlock = ({}) => {
  const t = useTranslate()

  return (
    <div className="pb-3">
      <Link
        to={staticPaths.accountSettings}
        className="flex hover:bg-gray-300 items-center py-1 px-3 mx-2">
        <Avatar
          size={42}
          className="bg-transparent mr-2 text-color-300"
          shape="square">
          <Icon
            style={{ fontSize: '24px' }}
            component={AiOutlineSetting}
          />
        </Avatar>
        <div className="truncate text-xl">
          {t('user management')}
        </div>
      </Link>
      <Link
        to={staticPaths.bookmark}
        className="flex hover:bg-gray-300 items-center py-1 px-3 mx-2">
        <Avatar
          size={42}
          className="bg-transparent mr-2 text-color-300"
          shape="square">
          <Icon
            style={{ fontSize: '24px' }}
            component={RiBookmarkLine}
          />
        </Avatar>
        <div className="truncate text-xl">
          {t('favorites')}
        </div>
      </Link>
    </div>
  )
}

const FooterBlock = ({}) => {
  const t = useTranslate()

  return (
    <div className="p-3 mt-3 flex flex-col flex-wrap justify-center items-center background">
      <Lang />
      <div className="mb-3">
        {APP_COPYRIGHT}
      </div>
    </div>
  )
}

const ChoosePrimaryOrganization = ({
  toggle,
}) => {
  const t = useTranslate()
  const {
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: auth_updateOrg_Api,
    onSuccess: (result) => {
      message.info(t('success'))
    },
    onError: () =>
      message.error(t('error')),
  })

  const setPrimaryOrg = (
    organization_id
  ) => {
    handleAsyncPost({ organization_id })
  }

  const renderItem = (item) =>
    (item || null) && (
      <div
        key={item.id}
        className="flex background-100 hover:bg-green-100 p-3 border rounded border-gray-200"
        onClick={() => {
          setPrimaryOrg(item.id)
          toggle()
        }}>
        <Avatar
          size={40}
          className="border border-gray-300 mx-2"
          shape="square"
          src={organizationModel.getThumbnailUrl(
            item
          )}>
          {getUpperFirstChar(
            organizationModel.getTitle(
              item
            )
          )}
        </Avatar>
        <div className="px-2 truncate font-semibold text-lg justify-center items-center">
          {organizationModel.getTitle(
            item
          )}
        </div>
      </div>
    )

  return (
    <div className="my-12 verticalList__small">
      <Alert
        showIcon={false}
        banner
        type="info"
        message={
          <div>
            {t(
              'Please select the unit you want to choose as the default'
            )}
          </div>
        }
      />

      <LazyComponent>
        <ServerContext.Consumer>
          {({ item }) => (
            <LazyPagination
              apiInfo={
                auth_getProps_Api
              }
              query={{
                ':props':
                  'organization',
                ':params':
                  '?filter=admin',
              }}
              renderItem={
                renderItem
              }></LazyPagination>
          )}
        </ServerContext.Consumer>
      </LazyComponent>
    </div>
  )
}

export const UserMenu = () => {
  const t = useTranslate()
  const login = useContext(LoginContext)

  const { lang } = useContext(
    LocalContext
  )

  const _languages = _.get(
    login,
    'following_languages',
    ['vi']
  )
  const following_languages = _.isEmpty(
    _languages
  )
    ? ['vi']
    : _languages

  const {
    organizations: organizationIds,
    channels: channelIds,
    primary_org: primaryOrganization,
  } = login || emptyObject

  const primaryOrgId = _.get(
    primaryOrganization,
    'idname',
    primaryOrganization
  )

  const organizations = (
    useSelector((state) =>
      selectEntities(
        state,
        organizationIds,
        [organizationSchema]
      )
    ) || emptyArray
  ).filter((o) => {
    return (
      _.isEmpty(primaryOrgId) ||
      o.idname !== primaryOrgId
    )
  })

  const channels = useSelector(
    (state) =>
      selectEntities(
        state,
        channelIds,
        [channelSchema]
      )
  )
  const primaryOrg = useSelector(
    (state) =>
      selectEntities(
        state,
        primaryOrgId,
        organizationSchema
      )
  )
  if (!login)
    return (
      <ContentContainer className="min-h-screen w-full text-lg">
        <div className="px-5 py-1 flex justify-center items-center">
          <div className="pl-1">
            <div className="flex">
              <AppTextLogoIcon />
              {!_.isEmpty(
                ENV_LABEL
              ) && (
                <div className="p-1 rounded text-xs font-bold text-orange-500">
                  {ENV_LABEL}
                </div>
              )}
            </div>
          </div>
          <div className="flex-1" />

          <div className="m-1 mx-2">
            <LanguageSwitch />
          </div>

          <HeaderButton>
            <div className="background-200 rounded-full w-12 h-12 p-3 m-1 mx-2">
              <Link to="/search">
                <FaSearch
                  style={{
                    fontSize: '1.5rem',
                  }}
                />
              </Link>
            </div>
          </HeaderButton>
        </div>

        <Link
          className="p-3 mt-3 flex w-full items-center font-bold uppercase hover:text-green-600"
          to={staticPaths.login}>
          <div className="text-lg color-primary-0 py-1 px-3">
            <AiOutlineLogin
              style={{
                fontSize: '1.8rem',
              }}
            />
          </div>
          <span className="px-1">
            {t('login')}
          </span>
        </Link>
        <Divider className="my-3" />

        <Link
          className="p-3 flex w-full items-center font-bold hover:text-primary"
          to="/helps/about-us">
          <div className="text-lg color-primary-0 py-1 px-3">
            <FiHelpCircle
              style={{
                fontSize: '1.8rem',
              }}
            />
          </div>
          <span className="px-1">
            {t('about us')}
          </span>
        </Link>

        <Divider className="my-3" />

        <Link
          className="p-3 flex w-full items-center font-bold hover:text-primary"
          to="/helps/terms-of-service">
          <div className="text-lg color-primary-0 py-1 px-3">
            <IoMdHelpCircle
              style={{
                fontSize: '2rem',
              }}
            />
          </div>
          <span className="px-1">
            {t('terms of service')}
          </span>
        </Link>

        <FooterBlock />
      </ContentContainer>
    )

  return (
    (login || null) && (
      <LayoutContext.Consumer>
        {({ breakpoint }) =>
          ['xs', 'sm', 'md'].find(
            (value) =>
              value === breakpoint
          ) ? (
            <ContentContainer className="min-h-screen w-full text-lg">
              <div className="px-5 py-1 flex justify-center items-center">
                <div className="pl-1">
                  <Link
                    to="/start"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '90px',
                      height:
                        'var(--header-height)',
                    }}
                    className="flex items-center  flex-shrink-0 w-full text-center">
                    <AppTextLogoIcon />
                    {!_.isEmpty(
                      ENV_LABEL
                    ) && (
                      <div className="p-1 rounded text-xs font-bold text-orange-500">
                        <h1 className="hidden">
                          Wishare
                        </h1>
                        {ENV_LABEL}
                      </div>
                    )}
                  </Link>
                </div>
                <div className="flex-1" />

                <div className="table my-1 mx-2 w-12 h-12">
                  <LanguageSwitch
                    title={
                      <Badge
                        count={
                          <div className="flex inline-flex overflow-hidden">
                            {following_languages.map(
                              (
                                e,
                                i
                              ) => {
                                return (
                                  <Avatar
                                    key={
                                      i
                                    }
                                    className={classNames(
                                      i !==
                                        0
                                        ? '-ml-2'
                                        : '',
                                      'w-5 h-5 border-2 border-white background-200 rounded-full inline-grid content-center justify-center'
                                    )}
                                    icon={
                                      <FlagIcon
                                        type={
                                          e
                                        }
                                        {...{
                                          style:
                                            {
                                              fontSize:
                                                '2rem',
                                              color:
                                                'var(--color-primary)',
                                            },
                                        }}
                                      />
                                    }
                                  />
                                )
                              }
                            )}
                          </div>
                        }
                        offset={[
                          -10, 10,
                        ]}>
                        <Avatar
                          className={classNames(
                            'w-12 h-12 border-2 border-white background-200 rounded-full m-1 mx-2 inline-grid content-center justify-center'
                          )}
                          icon={
                            <FlagIcon
                              type={
                                lang
                              }
                              width={
                                '2.5rem'
                              }
                              height={
                                '2.5rem'
                              }
                              {...{
                                style: {
                                  // fontSize:
                                  //   '5.2rem',
                                  color:
                                    'var(--color-primary)',
                                },
                              }}
                            />
                          }
                        />
                      </Badge>
                    }
                  />
                </div>
                <HeaderButton>
                  <div className="background-200 rounded-full w-12 h-12 p-3 m-1 mx-2">
                    <Link to="/search">
                      <FaSearch
                        style={{
                          fontSize:
                            '1.5rem',
                        }}
                      />
                    </Link>
                  </div>
                </HeaderButton>
              </div>

              <UserBlock item={login} />
              <ExtendMenuBlock />

              <OrgBlock
                primaryOrg={primaryOrg}
                organizations={
                  organizations
                }
              />

              <Divider className="my-3" />
              {primaryOrgId && (
                <OrgChannels
                  key={primaryOrgId}
                  primaryOrgId={
                    primaryOrgId
                  }
                  primaryOrg={
                    primaryOrg
                  }
                />
              )}

              <MyChannels
                channels={channels}
              />

              <AllChannels
                primaryOrg={primaryOrg}
              />

              <Divider className="my-3" />
              <Helps />

              <div className="flex-1" />

              <FooterBlock />
            </ContentContainer>
          ) : (
            <ContentContainer className="min-h-screen w-full">
              <OrgBlock
                primaryOrg={primaryOrg}
                organizations={
                  organizations
                }
              />

              {primaryOrgId && (
                <OrgChannels
                  key={primaryOrgId}
                  primaryOrg={
                    primaryOrg
                  }
                />
              )}

              <MyChannels
                channels={channels}
              />

              <AllChannels
                primaryOrg={primaryOrg}
              />

              <div className="flex-1" />
              <FooterBlock />
            </ContentContainer>
          )
        }
      </LayoutContext.Consumer>
    )
  )
}
