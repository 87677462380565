import {Tabs} from 'antd'
import {giving_events_getGivingsByBeneficiaryOwner_api, giving_events_getItemsByOwner_gets_api,} from 'apis'
import {getId, getType,} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useState,} from 'react'
import {useLocation, useSearchParam,} from 'react-use'
import {compose, fromRenderProps, withProps,} from 'recompose'
import {Null, renderOwnChild,} from 'views/Shared'
import {GivingItemsSortBy, GivingsSortBy,} from 'views/Wishare/factory/createEntitySortBy'
import {bindQueryParams} from 'views/Wishare/functions/routerHelper'
import GivingEventItem from 'views/Wishare/Giving/components/GivingEventItem'
import {MyGivingRecordItem} from 'views/Wishare/Giving/components/GivingRecordItem'
import GivingEntity from 'views/Wishare/Giving/GivingEntity'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import HeaderItemInfo from '../../../../components/ItemLayout/HeaderItemInfo'
import OrganizationContainerContext from '../OrganizationContainerContext'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";

const FilterTypes = Object.freeze({
  GIVINGS: 'givings',
  GIVING_EVENTS: 'giving-events',
})

const BeneficiaryOwnerGivingEvents = ({
                                        owner_id,
                                        owner_type,
                                        Header = Null,
                                        Wrapper = 'div',
                                        filter_actions = {
                                          sortBy: 'sort-by',
                                          keyword: 'keyword',
                                        },
                                      }) => {
  const location = useLocation()

  const sortBy = useSearchParam(
    filter_actions.sortBy
  )

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(
        location.state,
        'refreshToken'
      )}`,
    ],
    [
      owner_id,
      owner_type,
      location.state,
    ]
  )

  const [pager_widget] =
    usePaginationHelper({
      onInit: ({
                 onSortChange = Null,
               }) => {
        onSortChange(sortBy)
      },
      pagerInfo: {
        apiInfo:
        giving_events_getItemsByOwner_gets_api,
        query: bindQueryParams([
          {
            id: owner_id,
          },
          {type: owner_type},
        ]),
        RenderEntity: GivingEntity,
        RenderItem: GivingEventItem,
        PaginationMeta: Null,
      },
      withKey: ({sortBy, keyword}) =>
        [
          ...refresh_deps,
          sortBy,
          keyword,
        ].join('/'),
      dependencies: [
        owner_id,
        owner_type,
      ],
      renderFilter: Null,
      query_params: filter_actions,
      sortHeader: null,
      SortByWidget: GivingsSortBy,
    })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header/>
      {pager_widget}
    </Wrapper>
  )
}

export const BeneficiaryOwnerGivings =
  ({
     owner_id,
     owner_type,
     Header = Null,
     Wrapper = 'div',
     filter_actions = {
       sortBy: 'sort-by',
       keyword: 'keyword',
     },
   }) => {
    const location = useLocation()

    const sortBy = useSearchParam(
      filter_actions.sortBy
    )

    const refresh_deps = useMemo(
      () => [
        owner_id,
        owner_type,
        `refreshToken=${_.get(
          location.state,
          'refreshToken'
        )}`,
      ],
      [
        owner_id,
        owner_type,
        location.state,
      ]
    )

    const [pager_widget] =
      usePaginationHelper({
        onInit: ({
                   onSortChange = Null,
                 }) => {
          onSortChange(sortBy)
        },
        pagerInfo: {
          apiInfo:
          giving_events_getGivingsByBeneficiaryOwner_api,
          query: bindQueryParams([
            {
              id: owner_id,
            },
            {type: owner_type},
          ]),
          RenderItem: withProps({
            showEvent: true,
          })(MyGivingRecordItem),
          RenderEntity: GivingEntity,
          PaginationMeta: Null,
        },
        withKey: ({
                    sortBy,
                    keyword,
                  }) =>
          [
            ...refresh_deps,
            sortBy,
            keyword,
          ].join('/'),
        dependencies: [
          owner_id,
          owner_type,
        ],
        renderFilter: Null,
        query_params: filter_actions,
        sortHeader: null,
        SortByWidget: GivingItemsSortBy,
      })

    return (
      <Wrapper className="flex flex-col space-y-3">
        <Header/>
        {pager_widget}
      </Wrapper>
    )
  }

const OrganizationGivingList = ({
                                  owner_id,
                                  owner_type,
                                  Wrapper = renderOwnChild,
                                }) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const [current, setCurrent] =
    useState(FilterTypes.GIVING_EVENTS)

  const {organization: item} =
    useContext(
      OrganizationContainerContext
    )

  const ItemStatistic = ({
                           label,
                           value,
                           valueComponent,
                         }) => (
    <div
      className="px-2 py-4 flex flex-col flex-center gap-1 border border-color-50 rounded-lg shadow-items cursor-pointer">
      {
        !!value ? (
          <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                {Number(value).toLocaleString()}
              </span>
        ) : valueComponent
      }
      <span className="leading-tight text-sm text-color-400 tracking-wide uppercase hover:text-primary">
        {t(label)}
      </span>
    </div>
  )

  const tabs = useMemo(
    () =>
      [
        {
          type: FilterTypes.GIVING_EVENTS,
          tab: (
            <span>
              {t('giving campaign')}
            </span>
          ),
          component:
          BeneficiaryOwnerGivingEvents,
        },
        {
          type: FilterTypes.GIVINGS,
          tab: (
            <span>
              {t(
                'giving of organization'
              )}
            </span>
          ),
          component:
          BeneficiaryOwnerGivings,
        },
      ].map(
        ({
           type,
           tab,
           component: Component,
         }) => (
          <Tabs.TabPane
            key={type}
            tab={tab}>
            <Component
              {...{
                owner_id,
                owner_type,
              }}
            />
          </Tabs.TabPane>
        )
      ),
    [current, owner_id, owner_type]
  )

  return (
    <Wrapper>

      {
        [
          {
            id: 'insights',
            title: 'insights',
            className: "border-2 border-secondary",
            containerClassName: "grid grid-cols-2 gap-3",
            linkTo: getLinkToDetail(item) + '/givings',
            children: [
              {
                label: "giving campaign", valueComponent: (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(item, 'total_active_giving_events', 0)).toLocaleString()
                    }</span>
                    {
                      ' / '
                    }
                    <span className="text-color-500">{
                      Number(_.get(item, 'total_giving_events', 0)).toLocaleString()
                    }</span>
                  </div>
                ),

              },
              {
                label: "can giving",
                valueComponent: _.get(item, 'output_statistics.0.giving_target', 0) - _.get(item, 'output_statistics.0.giving_value', 0) > 0 ? (
                  <div className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                    <span>{
                      Number(_.get(item, 'output_statistics.0.giving_target', 0) - _.get(item, 'output_statistics.0.giving_value', 0)).toLocaleString()
                    }</span>
                    {
                      ' '
                    }
                    <span className="text-color-500">{
                      _.get(item, 'output_statistics.0.target_unit_name', 0)
                    }</span>
                  </div>
                ) : null,
              }
            ]
          }
        ].filter(e => !!!e.hidden).map((e, i) => {
            return !!isSm ? (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <Link
                    to={e.linkTo}
                    className={classNames("px-6 py-4 flex flex-col gap-1 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline", e.className)}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <div key={ii} className="flex items-baseline gap-3 leading-tight">
                          {
                            !!ee.value ? (
                              <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
                                {Number(ee.value).toLocaleString()}
                              </span>
                            ) : (
                              ee.valueComponent
                            )
                          }
                          <span className="text-sm text-color-400 tracking-wide uppercase hover:text-primary">
            {t(ee.label)}
          </span>
                        </div>
                      ))
                    }
                  </Link>
                </HeaderItemInfo>
              )
              : (
                <HeaderItemInfo key={i}
                                title={e.title}
                                className="flex flex-col gap-2">
                  <div className={e.containerClassName}>
                    {
                      _.get(e, 'children', []).map((ee, ii) => (
                        <ItemStatistic key={ii}
                                       className={e.className}
                                       label={ee.label}
                                       value={ee.value}
                                       valueComponent={ee.valueComponent}
                                       to={e.linkTo}
                        />
                      ))
                    }
                  </div>
                </HeaderItemInfo>
              )
          }
        )
      }
      <Tabs
        defaultActiveKey={current}
        onChange={(activeKey) => {
          setCurrent(activeKey)
        }}>
        {tabs}
      </Tabs>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  )
)(OrganizationGivingList)
