import {
  convertFromRaw,
  DefaultDraftBlockRenderMap,
  EditorState,
} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import 'draft-js/dist/Draft.css'
import Immutable from 'immutable'
import _ from 'lodash'
import React, {
  cloneElement,
  useState,
} from 'react'
import ScrollToViewByHash from '../ScrollToViewByHash'
import plugins from './plugins'
import './RichEditor.scss'
import sampleContent from './sampleContent'

const Header = (props) => {
  return props.children.map(
    (e, index) => {
      const text = _.get(
        e,
        'props.children.props.block'
      )
        .getText()
        .trim()
      return cloneElement(e, {
        ...e.props,
        id: text,
        key: index,
      })
    }
  )
}

const blockRenderMap = Immutable.Map({
  'header-one': {
    element: 'h2',
    wrapper: <Header />,
  },
  'header-two': {
    element: 'h3',
    wrapper: <Header />,
  },
  'header-three': {
    element: 'h4',
    wrapper: <Header />,
  },
})

// keep support for other draft default block types and add our myCustomBlock type
const extendedBlockRenderMap =
  DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  )

const Renderer = React.memo(
  ({ editorState }) => {
    const [e, setE] = useState(
      editorState ||
        EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(sampleContent)
          )
        )
    )

    return (
      <div className="prose RichEditor max-w-6xl dont-break-out mx-auto mb-4 readOnly">
        <ScrollToViewByHash />
        <Editor
          blockRenderMap={
            extendedBlockRenderMap
          }
          readOnly={true}
          editorState={e}
          onChange={setE}
          plugins={plugins}
        />
      </div>
    )
  }
)
export const defaultRawContent = {
  blocks: [
    {
      key: '51v21',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
}

export default Renderer
