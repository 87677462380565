import classNames from 'classnames'
import Count from 'components/Feed/Count'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, {
  useContext,
} from 'react'
import { VscArrowLeft } from 'react-icons/vsc'
import { Null } from 'views/Shared'

const GoBackAction = ({
  title,
  className,
  extra = null,
  Wrapper = 'div',
}) => {
  const t = useTranslate()

  const { handleGoBack = Null } =
    useContext(NavigationContext)

  return (
    <Wrapper
      className={classNames(
        'flex items-center',
        className
      )}>
      <Count
        className="p-3 px-3 text-lg"
        onClick={() => handleGoBack()}>
        <VscArrowLeft />
      </Count>
      {title && (
        <span className="uppercase font-semibold truncate flex items-center flex-1">
          {t(title)}
        </span>
      )}
      {extra}
    </Wrapper>
  )
}

export default GoBackAction
