import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin'
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin'
import createFocusPlugin from 'draft-js-focus-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import createListPlugin from 'draft-js-list-plugin'
import createMentionPlugin, {
  defaultTheme,
} from 'draft-js-mention-plugin'
import 'draft-js-mention-plugin/lib/plugin.css'
import { composeDecorators } from 'draft-js-plugins-editor'
import _ from 'lodash'
import React from 'react'
import createDividerPlugin from './plugins/divider'
import './plugins/focus/index.css'
import createImagePlugin from './plugins/image'
import createAlignmentPlugin from './plugins/imageAlignment'
import inlineToolbarPlugin from './plugins/inlineToolbar'
import linkPlugin from './plugins/link'
import creatLinkfyPlugin from './plugins/linkfy'
import sideToolbarPlugin from './plugins/side-toolbar/sideToolbarPlugin'
import createVideoPlugin from './plugins/video'

export const listPlugin =
  createListPlugin()
export const blockBreakoutPlugin =
  createBlockBreakoutPlugin()
export const linkifyPlugin =
  createLinkifyPlugin({
    target: '_blank',
    rel: 'noreferrer noopener nofollow',
    component: (params) => (
      <a
        {..._.omit(params, [
          'blockKey',
        ])}
      />
    ),
  })
export const focusPlugin =
  createFocusPlugin()

export const blockDndPlugin =
  createBlockDndPlugin()
export const alignmentPlugin =
  createAlignmentPlugin()
export const dividerPlugin =
  createDividerPlugin({
    decorator: composeDecorators(
      focusPlugin.decorator
    ),
  })
export const decorator =
  composeDecorators(
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
  )

export const imagePlugin =
  createImagePlugin({
    decorator,
  })
export const videoPlugin =
  createVideoPlugin({
    decorator,
  })

export const mentionPlugin =
  createMentionPlugin({
    theme: _.merge({}, defaultTheme, {
      mention:
        'font-semibold color-primary truncate',
      mentionSuggestions:
        'z-20 border border-gray-300 absolute  max-w-full background shadow-lg cursor-pointer py-2 flex flex-col transform  scale-0',
    }),
    supportWhitespace: true,
  })
export const tagPlugin =
  createMentionPlugin({
    theme: _.merge({}, defaultTheme, {
      mention:
        'font-semibold color-primary truncate',
      mentionSuggestions:
        'z-20 border border-gray-300 absolute  max-w-full background shadow-lg cursor-pointer py-2 flex flex-col transform  scale-0',
    }),
    mentionTrigger: '#',
  })

export const linkfyPlugin =
  creatLinkfyPlugin({
    decorator,
  })

const plugins = [
  focusPlugin,
  alignmentPlugin,
  imagePlugin,
  videoPlugin,
  linkPlugin,
  dividerPlugin,
  listPlugin,
  linkifyPlugin,
  linkfyPlugin,
  blockDndPlugin,
  sideToolbarPlugin,
  inlineToolbarPlugin,
  blockBreakoutPlugin,
  mentionPlugin,
  tagPlugin,
]

export default plugins
