import {
  Button,
  Input,
  message,
} from 'antd'
import {
  channel_add_Api,
  poll_add_Api,
  post_createRepost_Api,
  post_create_Api,
} from 'apis'
import { channelModel } from 'apis/model'
import ApiInfoForm from 'components/form/ApiInfoForm'
import Modal from 'components/Modal/Modal'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import Pure from 'components/Pure'
import copy from 'copy-to-clipboard'
import {
  API_ROOT_URL,
  ROOT_URL,
} from 'envs/_current/config'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  fromRenderProps,
  nest,
  withProps,
} from 'recompose'
import { createRequiredLogin } from 'routes/createRequiredLogin'
import ServerContext from 'views/Server/ServerContext'
import CreateArticle from '../CreateArticle/CreateArticle'
import CreateChannelForm from '../CreateChannelForm/CreateChannelForm'
import CreateImagePost from '../CreateImagePost/CreateImagePost'
import CreatePoll from '../CreatePoll/CreatePoll'
import Members from '../MemberSetting/Members.lazy'
import { ModalLogin } from '../Organization/routes'
import Settings from '../Organization/Settings/Settings'
import QuickLink from '../QuickLink'
import ItemList from './ItemList'

export const ModalLayout = ({
  name,
  children,
}) => (
  <ServerContext.Consumer>
    {({
      setCurrent,
      product,
      item,
    }) => (
      <FullSideModalLayout
        right
        title={
          name || (
            <span className="font-semibold  flex-1 j">
              <div
                style={{
                  fontSize: '0.8em',
                }}
                className="text-color-300 capitalize">
                {item.name}
              </div>
            </span>
          )
        }
        onCancel={() =>
          setCurrent(null)
        }>
        {children}
      </FullSideModalLayout>
    )}
  </ServerContext.Consumer>
)
export const EnchanedCreateImagePost =
  () => {
    const {
      item,
      addNewPost,
      setCurrent,
    } = useContext(ServerContext)
    const {
      time,
      isLoading,
      handleAsyncAction:
        handleAsyncPost,
    } = useAsyncAction({
      apiInfo: post_create_Api,
      query: {
        ':prop': item._type,
        ':id': item.username,
      },
      onSuccess: (result) => {
        addNewPost(result)
        setCurrent(null)
      },
      onError: () =>
        message.error('error'),
    })
    return (
      <div className="p-6">
        <CreateImagePost
          isLoading={isLoading}
          handleAsyncPost={
            handleAsyncPost
          }
        />
      </div>
    )
  }
const EnchanedItemList = () => {
  return (
    <ModalLayout>
      <ItemList />
    </ModalLayout>
  )
}
export const EnchanedCreatePoll =
  () => {
    const {
      item,
      addNewPost,
      setCurrent,
    } = useContext(ServerContext)
    const {
      time,
      isLoading,
      handleAsyncAction:
        handleAsyncPost,
    } = useAsyncAction({
      apiInfo: poll_add_Api,
      query: {
        ':prop': item._type,
        ':id': item.username,
      },
      onSuccess: (result) => {
        addNewPost(result)
        setCurrent(null)
      },
      onError: (err, errObject) => {
        errObject.error &&
          Object.values(
            errObject.error
          ).map((item) =>
            message.error(item)
          )
      },
    })
    return (
      <div className="p-6">
        <CreatePoll
          isLoading={isLoading}
          onSubmit={handleAsyncPost}
        />
      </div>
    )
  }
export const EnchanedQuickLink = () => {
  const t = useTranslate()
  const {
    item,
    addNewPost,
    setCurrent,
  } = useContext(ServerContext)
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': item._type,
      ':id': item.username,
    },

    onSuccess: (result) => {
      message.success(t('posted'))
      addNewPost(result)
      setCurrent(null)
    },
    onError: () =>
      message.error(t('error')),
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.push({
        pathname: `/news/post/${_.get(
          response,
          'data.data.content.id'
        )}`,
        // state: { isModal: true }
      })
    }
  }, [
    history,
    response,
    result,
    success,
  ])
  return (
    <FullSideModalLayout
      title={t('quick link')}
      right
      onCancel={() => setCurrent(null)}>
      <QuickLink
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}
const CreateChannelFormModal =
  fromRenderProps(
    ServerContext.Consumer,
    (props) => ({
      item: props.item,
      onPreSubmit: ({
        default_owner_id,
        default_owner_type,
        ...values
      }) => ({
        ...values,
      }),
      onCancel: () =>
        props.setCurrent(null),
    })
  )(
    nest(
      withProps({
        footer: null,
        visible: true,
      })(Modal),
      (props) => (
        <Translate>
          {(t) => {
            return (
              <div className="px-3">
                <ApiInfoForm
                  prefixStr="CreateChannelFormModal"
                  apiInfo={
                    channel_add_Api
                  }>
                  {({ ...props }) => {
                    if (
                      props.asyncData
                        .success
                    ) {
                      const link =
                        channelModel.getLinkToDetail(
                          _.get(
                            props,
                            'asyncData.response.data'
                          )
                        )
                      return (
                        <div className="verticalList h-64 flex flex-col items-center justify-center">
                          <div className="text-blue-600 text-3xl font-bold">
                            {t(
                              'success'
                            )}
                          </div>
                          <Input
                            className="w-full"
                            value={
                              ROOT_URL +
                              link
                            }
                            size="large"
                          />
                          <div className="flex w-full">
                            <Button
                              className="flex-1"
                              onClick={() => {
                                copy(
                                  ROOT_URL +
                                    link
                                )
                                message.info(
                                  t(
                                    'copied'
                                  )
                                )
                              }}
                              size="large"
                              block>
                              {t(
                                'copy'
                              )}
                            </Button>
                            <div className="w-3 flex-shrink-0"></div>
                            <NavigationContext.Consumer>
                              {({
                                history,
                              }) => {
                                return (
                                  <Button
                                    onClick={() =>
                                      history.push(
                                        link
                                      )
                                    }
                                    className="flex-1"
                                    size="large"
                                    block
                                    type="primary">
                                    {t(
                                      'to channel'
                                    )}
                                  </Button>
                                )
                              }}
                            </NavigationContext.Consumer>
                          </div>
                        </div>
                      )
                    }
                    return (
                      <Pure>
                        <CreateChannelForm
                          {...props}
                        />
                      </Pure>
                    )
                  }}
                </ApiInfoForm>
              </div>
            )
          }}
        </Translate>
      )
    )
  )
export const routes = [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    exact: true,
  },
  {
    path: '/memberList',
    name: 'members',
    component: withProps({
      name: 'members',
    })(nest(ModalLayout, Members)),
    exact: true,
  },
  {
    path: 'share',
    name: 'share',
    component: withProps({})(
      nest(ModalLayout, () => {
        const t = useTranslate()
        const [toggle, setToggle] =
          useState(false)
        useLayoutEffect(() => {
          let timeout
          if (toggle) {
            timeout = setTimeout(
              () => setToggle(false),
              1500
            )
          }
          return () => {
            if (timeout) {
              clearTimeout(timeout)
            }
          }
        }, [toggle])
        return (
          <div className="flex flex-col py-3 px-5 pb-5">
            <h2 className="text-xl pr-5 font-bold pt-3 mb-6">
              {t(
                'Invite your friends to this page'
              )}
            </h2>
            <div className="pb-3">
              <div className="mb-2">
                {t(
                  'Send invitation to join the channel to your friends, by sending a copy of the page link below'
                )}
              </div>
              <div
                onClick={() =>
                  setToggle(true)
                }>
                <Input.Search
                  value={
                    API_ROOT_URL +
                    window.location
                      .pathname
                  }
                  enterButton={t(
                    toggle
                      ? 'copied'
                      : 'copy'
                  )}
                  size="large"
                  onSearch={(value) =>
                    console.log(value)
                  }
                />
              </div>
            </div>
          </div>
        )
      })
    ),
    exact: true,
  },
  {
    path: 'createPoll',
    name: 'Create Poll',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        ModalLayout,
        EnchanedCreatePoll
      )
    ),
    exact: true,
  },
  {
    path: 'createArticle',
    name: 'Create Article',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        ({ children }) => (
          <div className="px-6">
            {children}
          </div>
        ),
        fromRenderProps(
          ServerContext.Consumer,
          ({ setCurrent }) => ({
            onCancel: () =>
              setCurrent(null),
          })
        )(CreateArticle)
      )
    ),
    exact: true,
  },
  {
    path: 'createImagePost',
    name: 'createImagePost',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        ModalLayout,
        EnchanedCreateImagePost
      )
    ),
    exact: true,
  },
  {
    path: 'itemList',
    name: 'items',
    component: EnchanedItemList,
    exact: true,
  },
  {
    path: 'createChannel',
    name: 'create channel',
    component: CreateChannelFormModal,
    exact: true,
  },
  {
    path: 'quicklink',
    name: 'quick link',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedQuickLink),
    exact: true,
  },
]
