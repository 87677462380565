import Modal from 'components/Modal/Modal'
import {Formik} from 'formik'
import {createOnError} from 'helpers'
import React from 'react'
import {postSchema} from 'apis/schema'
import {SelectEntityItem} from 'components/SelectEntityItem'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import {feed_updateChannel_Api} from "../../apis";
import {createValue} from "../../components/form/utils";
import {Button} from "antd";
import LookupField from "../../components/LookupField";

const FeedSetChannelForm = ({
  type,
  id,
  onCancel
}) => {
  const t = useTranslate()

  return (
    <NavigationContext.Consumer>
      {({handleGoBack}) => (
        <Modal
            className="custom-modal"
            visible={true}
            title={
                <div className="text-center font-bold text-color-000">
                    {t('set_channel')}
                </div>
            }
            footer={null}
            onCancel={onCancel || handleGoBack}>
            <SelectEntityItem
                item={id}
                schema={postSchema}>
                {item => (
                    <div className="pt-3">
                        {item && (
                            <AsyncAction
                                onSuccess={
                                    onCancel ||
                                    handleGoBack
                                }
                                onError={createOnError()}
                                apiInfo={
                                    feed_updateChannel_Api
                                }
                                query={{
                                    ':type':
                                    item._type,
                                    ':id':
                                    item.id
                                }}>
                                {({handleAsyncAction, isLoading}) => (
                                    <Formik
                                        onSubmit={handleAsyncAction}
                                        initialValues={{channel_id: item.container.id}}>
                                        {({values, handleSubmit, handleChange}) => (
                                            <div className="flex flex-col">
                                                <span className="font-medium text-sm text-color-300 uppercase mb-1">
                                                    {t('Choose the channel for posting')}
                                                </span>
                                                <LookupField
                                                    createIfNotExists={false}
                                                    defaultValue={
                                                        values.channel_name
                                                    }
                                                    apiPath={`/channels/lookup`}
                                                    name={"channel_id"}
                                                    handleChange={(e) => {
                                                        handleChange(
                                                            createValue('channel_name', e.target.value)
                                                        )
                                                        handleChange(
                                                            createValue('channel_id', e.target.id)
                                                        )
                                                    }}
                                                />
                                                <div className="flex w-full justify-end float-right mt-6">
                                                    <Button
                                                        onClick={handleSubmit}
                                                        type="primary"
                                                        className="rounded-lg no-shadow no-text-shadow no-border">
                                                        {t('save')}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Formik>
                                )}
                            </AsyncAction>
                        )}
                    </div>
                )}
            </SelectEntityItem>
        </Modal>
      )}
    </NavigationContext.Consumer>
  )
}
export default FeedSetChannelForm
