import {Avatar} from 'antd'
import {organizationModel} from 'apis/model'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext,} from 'react'
import {ImLibrary} from 'react-icons/im'
import {IoCheckmarkDoneOutline, IoHome, IoLocationOutline,} from 'react-icons/io5'
import {Link, useRouteMatch,} from 'react-router-dom'
import {getLocationLines} from 'views/Shared'
import {SubDomainContext} from 'views/SubDomain/SubDomainContext'
import {wisharePaths} from 'views/Wishare/configs'
import {BsLink45Deg} from "react-icons/bs";
import getTitle from "../../../../helpers/getTitle";

const ActionButtons = ({item}) => (
  <div className="hidden md:flex flex-row space-x-1">
    <SubDomainContext.Consumer>
      {({isSubDomain}) => (
        <Link
          to={
            isSubDomain
              ? wisharePaths.organization_domain
              : organizationModel.getLinkToDetail(
              item
              )
          }
          className="rounded-full w-10 h-10 flex items-center justify-center background-100 hover:bg-primary hover:text-white">
          <IoHome className="Icon"/>
        </Link>
      )}
    </SubDomainContext.Consumer>
  </div>
)

const HeaderGroupItems = ({item}) => {
  const t = useTranslate()
  return (
    <div className="flex flex-col gap-1 text-sm">
      {!!_.get(
        item,
        'locations[0]'
      ) && (
        <div className="flex-row items-start">
          <React.Fragment>
            <IoLocationOutline className="text-primary mr-2"/>
            <span>
              {getLocationLines(item)}
            </span>
          </React.Fragment>
        </div>
      )}

      {item.tax_code && (
        <div className="flex-row items-start">
          <React.Fragment>
            <IoCheckmarkDoneOutline className="text-primary mr-2"/>
            <span>
              {`${t('tax code')} `}
              <b className="font-semibold">
                {item.tax_code}
              </b>
            </span>
          </React.Fragment>
        </div>
      )}
      {Number(item.certified) > 0 && (
        <div className="flex-row items-start">
          <React.Fragment>
            <IoCheckmarkDoneOutline className="text-primary mr-2"/>
            <span>
              <b className="font-bold">
                {`${t('verified')} `}
              </b>
              {t('provider')}
            </span>
          </React.Fragment>
        </div>
      )}
    </div>
  )
}

const SubDomainHeader = ({item}) => {
  if (!Boolean(item.sub_domain))
    return null
  return (
    <SubDomainContext.Consumer>
      {({isSubDomain}) =>
        Boolean(item.sub_domain) && (
          <a
            href={[
              window.location.protocol,
              '//',
              !!isSubDomain
                ? ''
                : item.sub_domain + '.',
              window.location.host,
            ].join('')}
            className="flex items-center gap-2 text-primary">
            <BsLink45Deg/>
            <span>
              {[
                !!isSubDomain
                  ? ''
                  : item.sub_domain +
                  '.',
                window.location
                  .host,
              ].join('')}
            </span>
          </a>
        )
      }
    </SubDomainContext.Consumer>
  )
}

const OrganizationSettingHeader = ({
                                     organization,
                                   }) => {
  const t = useTranslate()

  const {isSm} = useContext(
    LayoutContext
  )

  let {url} = useRouteMatch()

  const item = organization || {}

  const avatar = _.get(organization, 'avatar')

  const subcategories = _.get(item, 'subcategories', [])

  if (_.isEmpty(item)) return null

  return (
    <div className="py-3 w-full container mx-auto flex items-center">
      <div className="flex items-start flex-1 gap-3">
        <Avatar
          size={isSm ? 40 : 80}
          src={avatar}
          className="flex flex-center background-100 rounded-lg border border-color-50 mt-1"
          icon={<ImLibrary size={isSm ? 25 : 50} className="text-color-org"/>}
        />
        <div className="flex flex-col flex-1">
          <Link
            className="title-font text-sm md:text-xl font-bold text-color-org no-underline mb-1"
            title={item.title}
            to={organizationModel.getLinkToDetail(
              item
            )}>
            {item.title}
          </Link>
          <div className="flex flex-col gap-1">
            <SubDomainHeader
              {...{item}}
            />
            <HeaderGroupItems
              {...{item}}
            />
            {subcategories.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {subcategories.map((e, i) => (
                  <span key={i} style={{padding: '4px 8px'}}
                        className="flex flex-col border border-color-50 rounded-md leading-tight">
                        <span className="font-medium text-xs text-color-000">
                          {getTitle(e)}
                        </span>
                        <span className="font-light text-2xs text-secondary italic">
                          {_.get(e, 'owner.title')}
                        </span>
                      </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionButtons {...{item}} />
    </div>
  )
}

export default OrganizationSettingHeader
