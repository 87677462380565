import { Alert } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useMemo,
} from 'react'
import { MaterialTypes } from './enums'
import { getMessage } from './messages'

const NoContext = React.createContext(
  {}
)

const WishareAlert = ({
  type,
  entity,
  message,
  children,
  Context = NoContext,
  visible = true,
  closable = true,
  messageType = MaterialTypes.ERROR,
  Component = Alert,
  ...props
}) => {
  const translate = useTranslate()

  const context = useContext(Context)

  const withoutContext =
    _.isEmpty(entity) &&
    _.isEmpty(context)

  const _visible = withoutContext
    ? !!visible
    : !!context.error

  const _errorMessage = withoutContext
    ? message
    : context.errorMessage

  const _message = useMemo(() => {
    if (!_.isEmpty(_errorMessage))
      return _errorMessage
    return getMessage(
      entity,
      type,
      translate
    )
  }, [
    type,
    entity,
    _errorMessage,
    translate,
  ])

  if (!_visible) return children || null
  return (
    <Component
      {...props}
      message={_message}
      type={messageType}
      closable={closable}
    />
  )
}

// Todo: implement translation
export const UnauthenticatedAlert =
  () => {
    const t = useTranslate()
    return (
      <WishareAlert
        visible={true}
        closable={false}
        type={MaterialTypes.WARNING}
        message={t('unauthenticated')}
      />
    )
  }

export default WishareAlert
