import classnames from 'classnames'
import {RichUtils} from 'draft-js'
import {createBlockStyleButton, createInlineStyleButton} from 'draft-js-buttons'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import React from 'react'
import linkPlugin from '../link'
import './plugin.css'

const inlineToolbarPlugin = createInlineToolbarPlugin(
  {
    theme: {
      buttonStyles: {
        button:
          'draftJsToolbar_inline___button',
        active:
          'draftJsToolbar_inline___active'
      },
      toolbarStyles: {
        toolbar:
          'draftJsToolbar_inline___toolbar'
      }
    }
  }
)
const BoldButton = createInlineStyleButton(
  {
    style: 'BOLD',
    children: (
      <svg
        className="svgIcon-use"
        width={21}
        height={21}>
        <path
          d="M10.308 17.993h-5.92l.11-.894.783-.12c.56-.11.79-.224.79-.448V5.37c0-.225-.113-.336-.902-.448H4.5l-.114-.894h6.255c4.02 0 5.58 1.23 5.58 3.13 0 1.896-1.78 3.125-3.79 3.463v.11c2.69.34 4.25 1.56 4.25 3.57 0 2.35-2.01 3.69-6.37 3.69l.02.01h-.02zm-.335-12.96H8.967V10.5h1.23c1.788 0 2.79-1.23 2.79-2.683 0-1.685-1.004-2.803-3.006-2.803v.02zm-.223 6.36h-.783v5.588l1.225.23h.22c1.67 0 3.01-1.004 3.01-2.792 0-2.122-1.566-3.016-3.69-3.016h.018z"
          fillRule="evenodd"
        />
      </svg>
    )
  }
)
const ItalicButton = createInlineStyleButton(
  {
    style: 'ITALIC',
    children: (
      <svg
        className="svgIcon-use"
        width={21}
        height={21}>
        <path
          d="M9.847 18.04c-.533 0-2.027-.64-1.92-.853l2.027-7.68-.64-.214-1.387 1.494-.427-.427c.534-1.173 1.707-2.667 2.774-2.667.533 0 2.24.534 2.133.854l-2.133 7.786.533.214 1.6-1.067.427.427c-.64 1.066-1.92 2.133-2.987 2.133zm2.347-11.733c-.96 0-1.387-.64-1.387-1.387 0-1.067.747-1.92 1.493-1.92.854 0 1.387.64 1.387 1.493-.107 1.067-.747 1.814-1.493 1.814z"
          fillRule="evenodd"
        />
      </svg>
    )
  }
)
const HeadOneButton = createBlockStyleButton(
  {
    blockType: 'header-one',
    children: (
      <svg
        fill="currentColor"
        width={24}
        height={24}>
        <rect x="0" fill="none" width="24" height="24"/>
        <g>
          <path d="M11 7h2v10h-2v-4H7v4H5V7h2v4h4V7zm6.57 0c-.594.95-1.504 1.658-2.57 2v1h2v7h2V7h-1.43z"/>
        </g>
      </svg>
    )
  }
)
const HeadTwoButton = createBlockStyleButton(
  {
    blockType: 'header-two',
    children: (
      <svg
        fill="currentColor"
        width={24}
        height={24}>
        <rect x="0" fill="none" width="24" height="24"/>
        <g>
          <path
            d="M9 7h2v10H9v-4H5v4H3V7h2v4h4V7zm8 8c.51-.41.6-.62 1.06-1.05.437-.4.848-.828 1.23-1.28.334-.39.62-.82.85-1.28.2-.39.305-.822.31-1.26.005-.44-.087-.878-.27-1.28-.177-.385-.437-.726-.76-1-.346-.283-.743-.497-1.17-.63-.485-.153-.99-.227-1.5-.22-.36 0-.717.033-1.07.1-.343.06-.678.158-1 .29-.304.13-.593.295-.86.49-.287.21-.56.437-.82.68l1.24 1.22c.308-.268.643-.502 1-.7.35-.2.747-.304 1.15-.3.455-.03.906.106 1.27.38.31.278.477.684.45 1.1-.014.396-.14.78-.36 1.11-.285.453-.62.872-1 1.25-.44.43-.98.92-1.59 1.43-.61.51-1.41 1.06-2.16 1.65V17h8v-2h-4z"/>
        </g>

      </svg>
    )
  }
)
const HeadThreeButton = createBlockStyleButton(
  {
    blockType: 'header-three',
    children: (
      <svg
        fill="currentColor"
        width={24}
        height={24}>
        <rect x="0" fill="none" width="24" height="24"/>
        <g>
          <path
            d="M14.11 14.218c.355.287.75.523 1.17.7.434.18.9.273 1.37.27.484.017.965-.086 1.4-.3.333-.146.55-.476.55-.84.003-.203-.05-.403-.15-.58-.123-.19-.3-.34-.51-.43-.32-.137-.655-.228-1-.27-.503-.073-1.012-.106-1.52-.1v-1.57c.742.052 1.485-.07 2.17-.36.37-.164.615-.525.63-.93.026-.318-.12-.627-.38-.81-.34-.203-.734-.3-1.13-.28-.395.013-.784.108-1.14.28-.375.167-.73.375-1.06.62l-1.22-1.39c.5-.377 1.053-.68 1.64-.9.608-.224 1.252-.336 1.9-.33.525-.007 1.05.05 1.56.17.43.1.84.277 1.21.52.325.21.595.495.79.83.19.342.287.73.28 1.12.01.48-.177.943-.52 1.28-.417.39-.916.685-1.46.86v.06c.61.14 1.175.425 1.65.83.437.382.68.94.66 1.52.005.42-.113.835-.34 1.19-.23.357-.538.657-.9.88-.408.253-.853.44-1.32.55-.514.128-1.04.192-1.57.19-.786.02-1.57-.106-2.31-.37-.59-.214-1.126-.556-1.57-1l1.12-1.41zM9 11H5V7H3v10h2v-4h4v4h2V7H9v4z"/>
        </g>
      </svg>
    )
  }
)

const BlockQuoteButton = function BlockStyleButton(
  props
) {
  const blockType = 'blockquote'
  const toggleStyle = event => {
    event.preventDefault()
    props.setEditorState(
      RichUtils.toggleBlockType(
        props.getEditorState(),
        blockType
      )
    )
  }

  const preventBubblingUp = event => {
    event.preventDefault()
  }

  const blockTypeIsActive = () => {
    // if the button is rendered before the editor
    if (!props.getEditorState) {
      return false
    }

    const editorState = props.getEditorState()
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(
        editorState
          .getSelection()
          .getStartKey()
      )
      .getType()
    return type === blockType
  }

  const { theme } = props
  const className = blockTypeIsActive()
    ? classnames(
        theme.button,
        theme.active
      )
    : theme.button

  return (
    <div
      className={theme.buttonWrapper}
      onMouseDown={preventBubblingUp}>
      <button
        className={className}
        onClick={toggleStyle}
        type="button"
        children={
          <svg
            className="svgIcon-use"
            width="
21"
            height="21">
            <path d="M15.48 18.024c-2.603 0-4.45-2.172-4.45-4.778 0-3.263 2.498-6.3 6.517-8.803l1.297 1.303c-2.497 1.63-3.91 3.042-3.91 5.214 0 2.824 3.91 3.582 3.91 3.91.11 1.41-1.194 3.15-3.366 3.15h.004v.004z"></path>
            <path d="M6.578 18.024c-2.606 0-4.453-2.172-4.453-4.778 0-3.263 2.497-6.3 6.515-8.803l1.303 1.303c-2.606 1.63-3.907 3.042-3.907 5.106 0 2.823 3.91 3.58 3.91 3.91 0 1.518-1.304 3.257-3.368 3.257z"></path>
          </svg>
        }
      />
    </div>
  )
}

export const InlineToolBar = () => (
  <inlineToolbarPlugin.InlineToolbar>
    {// may be use React.Fragment instead of div to improve perfomance after React 16
    externalProps => (
      <>
        <BoldButton
          {...externalProps}
        />
        <ItalicButton
          {...externalProps}
        />
        <linkPlugin.LinkButton
          {...externalProps}
        />
        <div className="w-px shadow h-6 mx-px"></div>
        <HeadOneButton
          {...externalProps}
        />
        <HeadTwoButton
          {...externalProps}
        />
        <HeadThreeButton
          {...externalProps}
        />
        <BlockQuoteButton
          {...externalProps}
        />
      </>
    )}
  </inlineToolbarPlugin.InlineToolbar>
)
export default inlineToolbarPlugin
