import React from 'react'
import { compose } from 'recompose'
import { CoHostList } from 'views/MainPage/WorkSpace/Hosts/WorkspaceCoHostList'
import { CoOwnerList } from 'views/MainPage/WorkSpace/Hosts/WorkspaceCoOwnerList'
import { HostProvider } from '../Host/HostContext'
import { DefaultSettingHeader } from './createSettingContent'

const withHostSetting = (Component) => {
  return function Wrapper({
    item,
    ...props
  }) {
    return (
      <HostProvider container={item}>
        <Component
          {...{
            Header: ({
              title,
              extra,
            }) => (
              <DefaultSettingHeader
                title={title}
                extra={extra}
              />
            ),
          }}
          {...props}
        />
      </HostProvider>
    )
  }
}

export const CoHosts = compose(
  withHostSetting
)(CoHostList)

export const CoOwners = compose(
  withHostSetting
)(CoOwnerList)
