import createStickySider from 'views/Wishare/factory/createStickySider'
import BeneficiaryLinking from './BeneficiaryLinking'

const BeneficiarySider = ({
  Wrapper = 'div',
  stickySider = true,
}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(BeneficiaryLinking)

export default BeneficiarySider
// branch(({ invisible }) => {
//   const { isSm } = useContext(
//     LayoutContext
//   )
//   return Boolean(!invisible && isSm)
// }, renderNothing)
