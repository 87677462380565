import { useContext } from 'react'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { wisharePaths } from 'views/Wishare/configs'
import createSettingContent from 'views/Wishare/factory/createSettingContent'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationSettingsContent = ({
  Wrapper,
  routes,
}) => {
  const { isSubDomain } = useContext(
    SubDomainContext
  )

  const rootPath = isSubDomain
    ? wisharePaths.organizationSettings_domain
    : wisharePaths.organizationSettings
  return createSettingContent(
    OrganizationContainerContext,
    { routes, Wrapper, rootPath }
  )
}

export default OrganizationSettingsContent
