import React from 'react'
import Loadable from 'react-loadable'
import LoadingPage from '../../../LoadingPage'

const ProfileLazy = Loadable({
  loader: () => import('./Profile'),
  loading: LoadingPage,
})

const Profile = (props) => (
  <ProfileLazy {...props} />
)

export default Profile
