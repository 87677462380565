import {
  Avatar,
  Button,
  Modal,
  Spin,
} from 'antd'
import {
  activities_getItemsByOwner_gets_api,
  medal_getMedals_api,
  staff_manageStaffRosterAdd_Api,
  staff_manageStaffRosterEdit_Api,
} from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import { staffSchema } from 'apis/schema'
import FieldsFactory, {
  FieldHeader,
} from 'components/form/FieldsFactory'
import { FullSideModalLayout } from 'components/Modal/SideModalLayout'
import {
  Formik,
  FormikConsumer,
} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, { useMemo } from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
  pure,
  withProps,
} from 'recompose'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import LoadingPage from 'views/LoadingPage'
import { SelectCountries } from 'views/Organization/Settings/SelectCountries'
import {
  formatDate,
  getFullAddress,
  Null,
  renderSelf,
  shallowDiff,
} from 'views/Shared'
import { withAppConfig } from 'views/Wishare/composers'
import ContainerPicker, {
  renderDefaultActions,
} from 'views/Wishare/custom/ContainerPicker'
import { SelectWidget } from 'views/Wishare/custom/ReactSelectComponents'
import { WishareEntities } from 'views/Wishare/enums'
import { createDateRangerPicker } from 'views/Wishare/factory/createDateRangerPicker'
import { notifyOnError } from 'views/Wishare/factory/createErrorEvent'
import { createControlledFormField } from 'views/Wishare/factory/createFormField'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/Wishare/factory/createNotification'
import {
  bindQueryParam,
  bindQueryParams,
} from 'views/Wishare/functions/routerHelper'
import { OverlayItemBanner } from 'views/Wishare/Templates/ItemTemplate'
import { renderLocationCascade } from 'views/Wishare/User/functions/renderLocationCascade'
import {
  GenderTypes,
  getProp,
} from 'views/Wishare/wishareFieldTypes'
import WorkspaceContext from '../WorkspaceContext'
import TimesheetsManagementModal from './TimesheetsManagementModal'

export const StaffRosterModalTypes =
  Object.freeze({
    EDIT: 'edit',
    CREATE: 'create',
    DELETE: 'delete',
    TIMESHEETS: 'timesheets',
    THANKS_CARD: 'thanks-card',
  })

export const newStaffRoster = {
  address: null,
  location: null,
  eff_date: null,
  end_date: null,
  workplace: null,
  workplace_id: null,
  workplace_type: null,
  ward_id: undefined,
  medal: undefined,
  medal_id: undefined,
  country_id: undefined,
  province_id: undefined,
  district_id: undefined,
  position_title: null,
}

const withFormSchema = ({
  owner_id,
  owner_type,
}) => [
  {
    children: [
      {
        name: 'information_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                {t('information')}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'workplace',
    children: [
      {
        name: 'workplace',
        component: compose(
          fromRenderProps(
            WorkspaceContext.Consumer,
            ({ organization }) => ({
              container: _.pick(
                organization,
                [
                  'id',
                  '_type',
                  'name',
                  'title',
                  'avatar',
                ]
              ),
            })
          ),
          withProps(
            ({
              name,
              value,
              onChange,
              container,
            }) => ({
              name,
              onChange,
              label:
                'activity/organization',
              allowClear: true,
              defaultValue: _.get(
                value,
                'id',
                value
              ),
              optionParams: {
                prefixCls: undefined,
              },
              type: WishareEntities.UNDEFINED,
              customConfigs: {
                query: bindQueryParams([
                  {
                    id: getId(
                      container
                    ),
                  },
                  {
                    type: getType(
                      container
                    ),
                  },
                ]),
                apiInfo:
                  activities_getItemsByOwner_gets_api,
              },
              renderActions:
                renderDefaultActions(
                  value
                ),
              withResponses: (
                items = []
              ) => [
                container,
                ...Array.from(
                  items || []
                ).filter(
                  (item) =>
                    _.get(
                      item,
                      'id'
                    ) !==
                    _.get(
                      container,
                      'id'
                    )
                ),
              ],
              mapCustomProps: (option) => {
                const { style = {} } = option
                return _.get(container, 'id') === _.get(option, 'id') ? {
                      ...option,
                      style: {
                        ...style,
                        color: 'rgba(15, 83, 113, 1)',
                        backgroundColor: 'rgba(241, 250, 255, 1)',
                      },
                    } : option
              },
            })
          )
        )(ContainerPicker),
      },
    ],
  },
  {
    title: 'position_title',
    children: {
      name: 'position_title',
      component:
        createControlledFormField({
          placeholder:
            'position placeholder',
        }),
    },
  },

  {
    children: [
      {
        name: 'time_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                {t('time')}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'from date',
    children: [
      {
        name: 'eff_date',
        component:
          createDateRangerPicker([
            'eff_date',
            'end_date',
          ]),
      },
    ],
  },

  {
    children: [
      {
        name: 'location_group',
        hideError: true,
        component: () => (
          <Translate>
            {(t) => (
              <div className="border-b leading-tight font-semibold uppercase text-lg pt-2 pb-1 text-color-100">
                {t('location')}
              </div>
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'address',
    children: {
      name: 'address',
      hideError: true,
      component:
        createControlledFormField({
          placeholder:
            'address placeholder',
        }),
    },
  },
  {
    title: 'country',
    children: [
      {
        name: 'country_id',
        hideError: true,
        component: compose(
          withTranslate,
          withAppConfig,
          mapProps(
            ({
              name,
              form,
              value,
              onChange,
              translate,
              appConfig,
            }) => ({
              name,
              value,
              onChange: (value) => {
                const {
                  values,
                  setValues = Null,
                } = form || {}
                const isValid = _.some(
                  _.get(
                    appConfig,
                    'provinces',
                    []
                  ),
                  {
                    country_id: value,
                  }
                )
                if (!isValid) {
                  setValues({
                    ...values,
                    address: [],
                    [name]: value,
                  })
                } else {
                  setValues({
                    ...values,
                    [name]: value,
                  })
                }
              },
              placeholder:
                translate('country'),
              Component: SelectWidget,
            })
          ),
          pure
        )(SelectCountries),
      },
    ],
  },
  {
    children: [
      {
        name: 'location_data',
        component: ({
          name,
          value,
          form,
          onChange,
        }) =>
          renderLocationCascade({
            name,
            value,
            onChange,
            defaultValue: value,
            style: {
              fontSize: 14,
            },
            country_id: _.get(
              form,
              'values.country_id'
            ),
            Header: withProps({
              title:
                'Province/district/ward',
            })(FieldHeader),
          }),
      },
    ],
  },
  {
    children: {
      name: 'medal',
      component: withProps(
        ({
          name,
          value,
          onChange,
        }) => ({
          name,
          onChange,
          label: 'medal',
          allowClear: true,
          defaultValue: _.get(
            value,
            'id',
            value
          ),
          optionParams: {
            prefixCls: undefined,
          },
          type: WishareEntities.UNDEFINED,
          customConfigs: {
            apiInfo:
              medal_getMedals_api,
            values: { active: 1 },
            query: bindQueryParams([
              {
                id: owner_id,
              },
              {
                prop: owner_type,
              },
            ]),
          },
        })
      )(ContainerPicker),
    },
  },
]

export const CreateStaffRosterModal = ({
  staff_id,
  owner_id,
  owner_type,
  onCancel,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'create',
  renderTitle = renderSelf,
  initialValues = newStaffRoster,
}) => {
  const t = useTranslate()

  const staff = useSelectEntities(
    staff_id,
    staffSchema
  )

  let gender = !!_.get(staff, 'gender')
    ? getProp(
        Object.values(GenderTypes),
        'label'
      )(Number(_.get(staff, 'gender')))
    : null
  gender = !!gender ? gender : null

  const RowInformation = ({
    label,
    value,
    hiddenIfEmpty = true,
  }) =>
    !value && hiddenIfEmpty ? null : (
      <div className="flex items-baseline gap-1 italic text-xs">
        <span className="text-color-400 font-light whitespace-no-wrap">
          {_.isString(label)
            ? `${label}: `
            : label}
        </span>
        <span className="text-color-100 font-medium">
          {value}
        </span>
      </div>
    )

  const {
    isLoading = false,
    handleAsyncAction:
      createStaffRoster,
  } = useAsyncAction({
    apiInfo:
      staff_manageStaffRosterAdd_Api,
    query: bindQueryParam({
      staff_id,
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.CREATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const {
      eff_date,
      workplace,
      position_title,
    } = values || {}
    let errors = {}

    if (_.isEmpty(workplace)) {
      errors.workplace = t(
        'required field'
      )
    }

    if (_.isEmpty(position_title)) {
      errors.position_title = t(
        'required field'
      )
    }

    if (_.isEmpty(eff_date)) {
      errors.eff_date = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
      }),
    []
  )

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(createStaffRoster)(
          changed
        )
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {renderTitle(
              t('create staff roster')
            )}
          </div>
          <div className="flex flex-col">
            <div className="flex uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t('staff')}
            </div>
            <div className="border border-color-50 rounded-lg background relative">
              <OverlayItemBanner
                type={
                  WishareEntities.STAFF
                }
              />
              <div className="h-7" />
              <div className="p-2 flex items-center gap-2">
                <Avatar
                  className="background-100"
                  src={_.get(
                    staff,
                    'avatar'
                  )}>
                  {_.first(
                    _.get(staff, 'name')
                  )}
                </Avatar>
                <div className="flex flex-col">
                  <span className="font-bold text-primary">
                    {_.get(
                      staff,
                      'name'
                    )}
                  </span>
                  <span className="italic text-xs font-light text-secondary">
                    {t(
                      _.get(
                        staff,
                        'employee_type'
                      )
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {_.get(
                      staff,
                      'date_of_birth'
                  ) && (
                      <RowInformation
                          label={t(
                              'date of birth'
                          )}
                          value={formatDate(
                              _.get(
                                  staff,
                                  'date_of_birth'
                              )
                          )}
                      />
                  )}
                  {_.get(
                      staff,
                      'gender'
                  ) && (
                      <RowInformation
                          label={t(
                              'gender'
                          )}
                          value={t(gender)}
                      />
                  )}
                  {_.get(
                      staff,
                      'phone_number'
                  ) && (
                      <RowInformation
                          label={t('phone')}
                          value={_.get(
                              staff,
                              'phone_number'
                          )}
                      />
                  )}
                  {_.get(
                      staff,
                      'email'
                  ) && (
                      <RowInformation
                          label={t('email')}
                          value={_.get(
                              staff,
                              'email'
                          )}
                      />
                  )}
                </div>
                {_.get(staff, 'location') && (
                    <RowInformation
                        label={t('address')}
                        value={getFullAddress(
                            _.get(
                                staff,
                                'location'
                            )
                        )}
                    />
                )}
              </div>
            </div>
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const EditStaffRosterModal = ({
  owner_id,
  owner_type,
  staff_id,
  onCancel,
  onSubmit = Null,
  onSuccess = Null,
  submitText = 'save',
  initialValues = newStaffRoster,
}) => {
  const t = useTranslate()

  const staff = useSelectEntities(
    staff_id,
    staffSchema
  )

  const owner_staff = _.get(
    staff,
    'owner'
  )

  const {
    isLoading = false,
    handleAsyncAction: editStaffRoster,
  } = useAsyncAction({
    apiInfo:
      staff_manageStaffRosterEdit_Api,
    query: bindQueryParam({
      id: getId(initialValues),
    }),
    onSuccess: (result, data) => {
      onSuccess(result, data)
      successNotify(
        NotificationActionTypes.UPDATE,
        t
      )
    },
    onError: notifyOnError(t),
  })

  const validate = (values) => {
    const {
      eff_date,
      workplace,
      position_title,
    } = values || {}
    let errors = {}

    if (_.isEmpty(workplace)) {
      errors.workplace = t(
        'required field'
      )
    }

    if (_.isEmpty(position_title)) {
      errors.position_title = t(
        'required field'
      )
    }

    if (_.isEmpty(eff_date)) {
      errors.eff_date = t(
        'required field'
      )
    }

    return errors
  }

  const formSchema = useMemo(
    () =>
      withFormSchema({
        owner_id,
        owner_type,
      }),
    []
  )

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changed = shallowDiff(
          values,
          initialValues
        )
        onSubmit(editStaffRoster)(
          changed
        )
      }}>
      <Spin spinning={!!isLoading}>
        <div className="flex flex-col space-y-3">
          <div className="uppercase text-center text-xl font-bold text-color-000">
            {t('edit staff roster')}
          </div>
          <div className="flex flex-col">
            <div className="flex uppercase text-sm font-semibold text-color-300 tracking-wide mb-1">
              {t('staff')}
            </div>
            <div className="border border-color-50 rounded-lg background relative">
              <OverlayItemBanner
                type={
                  WishareEntities.STAFF
                }
              />
              <div className="h-7" />
              <div className="p-2 flex items-center gap-2">
                <Avatar
                  className="background-100"
                  src={
                    owner_staff
                      ? _.get(
                          owner_staff,
                          'avatar'
                        )
                      : _.get(
                          staff,
                          'avatar'
                        )
                  }>
                  {_.first(
                    _.get(staff, 'name')
                  )}
                </Avatar>
                <div className="flex flex-col">
                  <span className="font-bold text-primary">
                    {_.get(
                      staff,
                      'name'
                    )}
                  </span>
                  <span className="italic text-xs font-light text-secondary">
                    {t(
                      _.get(
                        staff,
                        'employee_type'
                      )
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <FieldsFactory
            formSchema={formSchema}
          />
          <div className="flex gap-2 justify-end">
            <Button
              className="no-border no-text-shadow no-shadow rounded-lg"
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <FormikConsumer>
              {({
                dirty,
                isValid,
                handleSubmit = Null,
              }) => {
                const disabled =
                  !Boolean(
                    dirty && isValid
                  )
                return (
                  <Button
                    type="primary"
                    className="no-border no-text-shadow no-shadow rounded-lg"
                    onClick={() => {
                      if (!disabled) {
                        handleSubmit()
                      }
                    }}
                    loading={isLoading}
                    disabled={disabled}>
                    {t(submitText)}
                  </Button>
                )
              }}
            </FormikConsumer>
          </div>
        </div>
      </Spin>
    </Formik>
  )
}

const TimesheetsRightModal = ({
  children,
  ...props
}) => {
  const t = useTranslate()
  return (
    <FullSideModalLayout
      {...props}
      right={true}
      title={t('timesheets')}>
      {children}
    </FullSideModalLayout>
  )
}

const StaffRosterActionModal = ({
  type,
  params,
  onCancel,
  onRefresh,
  onCreated,
  onUpdated,
  initialValues,
  isLoading = false,
  ...props
}) => {
  if (!!!type) return null

  let Component = Null

  let ModalWrapper = Modal

  let asyncParams = {
    onSuccess: Null,
  }

  switch (type) {
    case StaffRosterModalTypes.CREATE:
      Component = CreateStaffRosterModal
      asyncParams = {
        onSuccess: onCreated,
      }
      break
    case StaffRosterModalTypes.TIMESHEETS:
      ModalWrapper =
        TimesheetsRightModal
      Component =
        TimesheetsManagementModal
      asyncParams = {
        // onSuccess: onRefresh,
        initialValues,
      }
      break
    case StaffRosterModalTypes.EDIT:
      Component = EditStaffRosterModal
      asyncParams = {
        onSuccess: onUpdated,
      }
      break
    default:
      break
  }

  const {
    staff_id,
    owner_id,
    owner_type,
  } = params || {}

  const onSubmit =
    (handleSubmit = Null) =>
    ({
      id,
      medal,
      medal_type,
      workplace,
      location_data,
      ...values
    }) => {
      const [
        province_id,
        district_id,
        ward_id,
      ] = Array.from(
        location_data || [, , ,]
      )
      handleSubmit(
        _.omitBy(
          {
            ..._.pick(
              values,
              Object.keys(
                newStaffRoster
              )
            ),
            ward_id,
            district_id,
            province_id,
          },
          _.isUndefined
        )
      )
    }

  const {
    ward,
    country,
    province,
    district,
    workplace,
    ...values
  } = initialValues || {}

  return (
    <ModalWrapper
        className="custom-modal"
      {...{
        onCancel,
        right: true,
        footer: null,
        closable: false,
        visible: !!type,
        destroyOnClose: true,
      }}>
      {!!isLoading ? (
        <LoadingPage />
      ) : (
        <Component
          {...props}
          {...{
            onCancel,
            onSubmit,
            staff_id,
            owner_id,
            owner_type,
            initialValues: {
              workplace,
              workplace_id:
                getId(workplace),
              workplace_type:
                getType(workplace),
              country_id:
                getId(country),
              location_data: [
                province,
                district,
                ward,
              ].map(getId),
              ...values,
            },
          }}
          {...asyncParams}
        />
      )}
    </ModalWrapper>
  )
}

export default StaffRosterActionModal
