import {UploadOutlined} from '@ant-design/icons'
import {Button, message, Upload,} from 'antd'
import classNames from 'classnames'
import Null from 'components/NullComponent'
import {Field} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import Resizer from 'react-image-file-resizer'
import {createValue} from '../utils'

export const getBase64 = (
  file,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64',
      minWidth,
      minHeight
    )
  })

export function dataURItoBlob(dataURI) {
  var byteString
  if (
    dataURI
      .split(',')[0]
      .indexOf('base64') >= 0
  )
    byteString = atob(
      dataURI.split(',')[1]
    )
  else
    byteString = unescape(
      dataURI.split(',')[1]
    )

  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  var ia = new Uint8Array(
    byteString.length
  )
  for (
    var i = 0;
    i < byteString.length;
    i++
  ) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], {
    type: mimeString,
  })
}

export default function ImageUpload({
                                      value,
                                      name,
                                      style,
                                      className,
                                      onChange,
                                      children,
                                      error = Null,
                                      renderPreview,
                                      success = Null,
                                      maxWidth = 256,
                                      maxHeight = 256,
                                      minWidth = 128,
                                      minHeight = 128,
                                      showUploadList = true,
                                      getPreviewName = (name) =>
                                        `${name}_preview`,
                                    }) {
  const translate = useTranslate()

  const previewName =
    getPreviewName(name)

  const handleOnchange =
    async function (info) {
      if (
        !!info &&
        !info.file.preview
      ) {
        const file = await getBase64(
          info.file,
          maxWidth,
          maxHeight,
          minWidth,
          minHeight
        )
        const image =
          dataURItoBlob(file)
        console.log({image})
        onChange(
          createValue(previewName, file)
        )
        onChange(
          createValue(name, image)
        )
      }
      if (
        !!info &&
        info.file.status !== 'uploading'
      ) {
        console.log(
          1,
          info.file,
          info.fileList
        )
      }
      if (
        !!info &&
        info.file.status === 'done'
      ) {
        success(
          `${
            info.file.name
          } ${translate(
            'file uploaded successfully'
          )}`
        )
      } else if (
        !!info &&
        info.file.status === 'error'
      ) {
        !!info &&
        error(
          `${
            info.file.name
          } ${translate(
            'file upload failed'
          )}.`
        )
      }
    }
  const beforeUpload =
    function beforeUpload(file) {
      const isLt2M =
        file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error(
          `${translate(
            'image must smaller than'
          )} 2MB!`
        )
      }
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error(
          `${translate(
            'You can only upload JPG/PNG file'
          )}!`
        )
      }

      return false
    }
  return (
    <div
      style={style}
      className={classNames(
        'flex flex-col items-center',
        className
      )}>
      <Field
        name={previewName}
        render={({
                   field,
                   form: {
                     touched = {},
                     errors = {},
                   } = {
                     touched: {},
                     errors: {},
                   },
                 }) =>
          renderPreview ? (
            renderPreview({
              src: field.value,
            })
          ) : (
            <img
              style={{
                width: '100%',
                minHeight: '300px',
                height: 'auto',
                objectFit: 'cover',
              }}
              src={field.value}
              size={100}
              className="bg-gray-300"
              alt={field.value}
            />
          )
        }
      />
      <Upload
        {...{
          showUploadList,
          beforeUpload,
          name: 'file',
          onChange: handleOnchange,
        }}>
        {children ? (
          children
        ) : (
          <Button>
            <UploadOutlined/>{' '}
            {translate(
              'click to upload'
            )}
          </Button>
        )}
      </Upload>
    </div>
  )
}
