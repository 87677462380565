import { donor_getDonations_api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
} from 'react'
import {
  branch,
  compose,
  fromRenderProps,
  renderNothing,
  withProps,
} from 'recompose'
import {
  Null,
  renderTrue,
} from 'views/Shared'
import { ObserverContext } from 'views/Shared/components/ObservedList'
import DonationRecordItem from 'views/Wishare/Donation/components/DonationRecordItem'
import { DonationItemEntity } from 'views/Wishare/Donation/DonationEntity'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParam } from 'views/Wishare/functions/routerHelper'
import DonorContext from '../DonorContext'

export const DonorMyDonations = ({
  donor,
  sample,
  keyword,
  children,
  sort_by,
  filtered_by,
  Footer = Null,
  Wrapper = 'div',
  isFiltered = renderTrue,
  EmptyComponent = Null,
  ...props
}) => {
  const { posts = [] } = useContext(
    ObserverContext
  )

  if (_.isEmpty(donor))
    return <EmptyComponent />

  const id = getId(donor)

  const apiInfo = donor_getDonations_api

  const query = bindQueryParam({ id })

  const values = {
    keyword,
    sort_by,
    filtered_by,
  }

  const refreshKey = JSON.stringify({
    query,
    values,
    posts,
  })

  return (
    <React.Fragment>
      <EntityList
        query={query}
        sample={sample}
        values={values}
        apiInfo={apiInfo}
        refreshKey={refreshKey}
        RenderEntity={
          DonationItemEntity
        }
        RenderItem={branch(
          ({ item }) =>
            !isFiltered(
              _.get(item, 'status')
            ),
          renderNothing,
          withProps({
            showEvent: true,
          })
        )(DonationRecordItem)}
        renderWrap={(
          child,
          __,
          count
        ) => (
          <Wrapper
            count={count}
            className="space-y-3">
            {child}
            {count ? <Footer /> : null}
          </Wrapper>
        )}
        {...props}
      />
    </React.Fragment>
  )
}

export default compose(
  fromRenderProps(
    DonorContext.Consumer,
    ({ donor }) => ({ donor })
  )
)(DonorMyDonations)
