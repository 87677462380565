import { Divider } from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {
  useContext,
} from 'react'
import { withRouter } from 'react-router-dom'
import {
  compose,
  nest,
  withProps,
} from 'recompose'
import {
  createRequiredLogin,
  ModalLayout,
  ModalLogin,
} from 'routes/createRequiredLogin'
import { staticPaths } from 'routes/staticPaths'
import CustomBreadcrumb from 'views/MainPage/MySpace/components/CustomBreadcrumb'
import { AuthOrganiationsModal } from 'views/MainPage/WorkSpace/components/WorkspacePicker'
import {
  BoxPlaceholder,
  Null,
  renderIf,
  renderSelf,
} from 'views/Shared'
import MainHeader, {
  LogoHeader,
} from '../MainPage/MainHeader'
import Activity from './Activity'
import ActivityContext, {
  ActivityProvider,
} from './Activity/ActivityContext'
import ActivityLayout from './Activity/ActivityLayout'
import { activityRouteEntities } from './Activity/activityRouteEntities'
import ActivityBanner from './Activity/components/ActivityBanner'
import ActivityContent from './Activity/components/ActivityContent'
import ActivityNav from './Activity/components/ActivityNav'
import ActivitySider from './Activity/components/ActivitySider'
import CreateActivity from './Activity/CreateActivity'
import { default as ActivitySettingLazy } from './Activity/Settings'
import ActivitySettingsContent from './Activity/Settings/ActivitySettingContent'
import ActivitySettingHeader from './Activity/Settings/ActivitySettingHeader'
import { activitySettings } from './Activity/Settings/ActivitySettings'
import BeneficiaryContext, {
  BeneficiaryProvider,
} from './Beneficiary/BeneficiaryContext'
import BeneficiaryLayout from './Beneficiary/BeneficiaryLayout'
import { beneficiaryRouteEntities } from './Beneficiary/beneficiaryRouteEntities'
import BeneficiaryBanner from './Beneficiary/components/BeneficiaryBanner'
import BeneficiaryContent from './Beneficiary/components/BeneficiaryContent'
import BeneficiaryNav from './Beneficiary/components/BeneficiaryNav'
import BeneficiarySider from './Beneficiary/components/BeneficiarySider'
import BeneficiarySettingsContent from './Beneficiary/Settings/BeneficiarySettingContent'
import BeneficiarySettingHeader from './Beneficiary/Settings/BeneficiarySettingHeader'
import { beneficiarySettings } from './Beneficiary/Settings/BeneficiarySettings'
import Category from './Category'
import CategoryContext, {
  CategoryProvider,
} from './Category/CategoryContext'
import CategoryLayout from './Category/CategoryLayout'
import { categoryRouteEntities } from './Category/categoryRouteEntities'
import CategoryContent from './Category/components/CategoryContent'
import CategoryNav from './Category/components/CategoryNav'
import CategorySider from './Category/components/CategorySider'
import GoBackAction from './custom/GoBackAction'
import { EditOwnerModal } from './custom/OwnerPicker'
import { CustomKeys } from './CustomKeys'
import Donation from './Donation'
import DonationBanner from './Donation/components/DonationBanner'
import DonationContent from './Donation/components/DonationContent'
import { SearchGivingModal } from './Donation/components/DonationGivingPicker'
import DonationNav from './Donation/components/DonationNav'
import DonationSider from './Donation/components/DonationSider'
import { SearchDonorModal } from './Donation/components/DonorPicker'
import CreateDonation from './Donation/CreateDonation'
import DonationContext, {
  DonationProvider,
} from './Donation/DonationContext'
import DonationLayout from './Donation/DonationLayout'
import { donationRouteEntities } from './Donation/donationRouteEntities'
import { default as DonationSettingLazy } from './Donation/Settings'
import DonationSettingsContent from './Donation/Settings/DonationSettingContent'
import DonationSettingHeader from './Donation/Settings/DonationSettingHeader'
import { donationSettings } from './Donation/Settings/DonationSettings'
import DonorBanner from './Donor/components/DonorBanner'
import DonorContent from './Donor/components/DonorContent'
import DonorNav from './Donor/components/DonorNav'
import DonorSider from './Donor/components/DonorSider'
import DonorContext, {
  DonorProvider,
} from './Donor/DonorContext'
import DonorLayout from './Donor/DonorLayout'
import { donorRouteEntities } from './Donor/donorRouteEntities'
import DonorSettingsContent from './Donor/Settings/DonorSettingContent'
import DonorSettingHeader from './Donor/Settings/DonorSettingHeader'
import { donorSettings } from './Donor/Settings/DonorSettings'
import { WishareEntities } from './enums'
import { ModuleLayoutWrapper } from './factory/createModule'
import createModuleLayout from './factory/createModuleLayout'
import createSettingLayout from './factory/createSettingLayout'
import { mapPathParam } from './functions/routerHelper'
import Giving from './Giving'
import GivingBanner from './Giving/components/GivingBanner'
import GivingContent from './Giving/components/GivingContent'
import { SearchDonationModal } from './Giving/components/GivingDonationPicker'
import GivingNav from './Giving/components/GivingNav'
import GivingSider from './Giving/components/GivingSider'
import CreateGiving from './Giving/CreateGiving'
import GivingContext, {
  GivingProvider,
} from './Giving/GivingContext'
import GivingLayout from './Giving/GivingLayout'
import { givingRouteEntities } from './Giving/givingRouteEntities'
import GivingSettingsLazy from './Giving/Settings'
import GivingSettingsContent from './Giving/Settings/GivingSettingContent'
import GivingSettingHeader from './Giving/Settings/GivingSettingHeader'
import { givingSettings } from './Giving/Settings/GivingSettings'
import AddHostModal from './Host/components/AddHostModal'
import Organization from './Organization'
import OrganizationBanner from './Organization/components/OrganizationBanner'
import OrganizationContent from './Organization/components/OrganizationContent'
import OrganizationNav from './Organization/components/OrganizationNav'
import OrganizationSider from './Organization/components/OrganizationSider'
import CreateOrganization from './Organization/CreateOrganization'
import OrganizationContainerContext, {
  OrganizationContainerProvider,
} from './Organization/OrganizationContainerContext'
import OrganizationLayout from './Organization/OrganizationLayout'
import { organizationRouteEntities } from './Organization/organizationRouteEntities'
import { default as OrganizationSettingLazy } from './Organization/Settings'
import OrganizationSettingsContent from './Organization/Settings/OrganizationSettingContent'
import OrganizationSettingHeader from './Organization/Settings/OrganizationSettingHeader'
import { organizationSettings } from './Organization/Settings/OrganizationSettings'
import Recruitment from './Recruitment'
import RecruitmentBanner from './Recruitment/components/RecruitmentBanner'
import RecruitmentContent from './Recruitment/components/RecruitmentContent'
import RecruitmentNav from './Recruitment/components/RecruitmentNav'
import RecruitmentSider from './Recruitment/components/RecruitmentSider'
import CreateRecruitment from './Recruitment/CreateRecruitment'
import RecruitmentContext, {
  RecruitmentProvider,
} from './Recruitment/RecruitmentContext'
import RecruitmentLayout from './Recruitment/RecruitmentLayout'
import { recruitmentRouteEntities } from './Recruitment/recruitmentRouteEntities'
import RecruitmentSettingsLazy from './Recruitment/Settings'
import RecruitmentSettingsContent from './Recruitment/Settings/RecruitmentSettingContent'
import RecruitmentSettingHeader from './Recruitment/Settings/RecruitmentSettingHeader'
import { recruitmentSettings } from './Recruitment/Settings/RecruitmentSettings'
import DefaultTemplate from './Templates/DefaultTemplate'
import PageTemplate from './Templates/PageTemplate'
import Topic from './Topic'
import TopicContent from './Topic/components/TopicContent'
import TopicNav from './Topic/components/TopicNav'
import TopicSider from './Topic/components/TopicSider'
import TopicContext, {
  TopicProvider,
} from './Topic/TopicContext'
import TopicLayout from './Topic/TopicLayout'
import { topicRouteEntities } from './Topic/topicRouteEntities'
import User from './User'
import UserBanner from './User/components/UserBanner'
import UserContent from './User/components/UserContent'
import UserNav from './User/components/UserNav'
import UserSider from './User/components/UserSider'
import UserSettingsContent from './User/Settings/UserSettingContent'
import UserSettingHeader from './User/Settings/UserSettingHeader'
import { userSettings } from './User/Settings/UserSettings'
import UserContext, {
  UserProvider,
} from './User/UserContext'
import UserLayout from './User/UserLayout'
import { userRouteEntities } from './User/userRouteEntities'

export const wisharePaths =
  Object.freeze({
    user: '/user/:id',
    donor: '/donor/:id',
    beneficiary: '/beneficiary/:id',
    staff: '/staff/:id',
    giving: '/giving/:id',
    activity: '/activity/:id',
    donation: '/donation/:id',
    recruitment: '/jobs/:id',
    topic: '/t/:id',
    category: '/category/:id',
    organization_domain: '',
    organization: '/organization/:id',
    organizationProfiles:
      '/organization/:id/profiles/:sub_type/:sub_id',
    organizationProfiles_domain:
      '/profiles/:sub_type/:sub_id',
    userSettings: '/user/:id/settings',
    donorSettings:
      '/donor/:id/settings',
    beneficiarySettings:
      '/beneficiary/:id/settings',
    givingSettings:
      '/giving/:id/settings',
    activitySettings:
      '/activity/:id/settings',
    donationSettings:
      '/donation/:id/settings',
    recruitmentSettings:
      '/jobs/:id/settings',
    organizationSettings:
      '/organization/:id/settings',
    organizationSettings_domain:
      '/settings',
  })

export const {
  modalWidth,
  headerHeight,
} = {
  modalWidth: {
    small: 560,
    medium: 640,
    large: 720,
  },
  headerHeight: 'var(--header-height)',
}

export const withModalLayout = (
  Component,
  props
) =>
  nest(
    Pure,
    withProps({
      width: modalWidth.large,
      ...props,
    })(ModalLayout),
    withProps(props)(Component)
  )

const settingRoutes = [
  // {
  //   path: wisharePaths.beneficiarySettings,
  //   Provider: BeneficiaryProvider,
  //   component: BeneficiarySettingsLazy,
  // },
  {
    path: wisharePaths.activitySettings,
    Provider: ActivityProvider,
    component: ActivitySettingLazy,
  },
  {
    path: wisharePaths.donationSettings,
    Provider: DonationProvider,
    component: DonationSettingLazy,
  },
  {
    path: wisharePaths.givingSettings,
    Provider: GivingProvider,
    component: GivingSettingsLazy,
  },
  {
    path: wisharePaths.recruitmentSettings,
    Provider: RecruitmentProvider,
    component: RecruitmentSettingsLazy,
  },
  {
    path: wisharePaths.organizationSettings,
    Provider:
      OrganizationContainerProvider,
    component: OrganizationSettingLazy,
  },
  // {
  //   path: wisharePaths.donorSettings,
  //   Provider: DonorProvider,
  //   component: DonorSettingsLazy,
  // },
  // {
  //   path: wisharePaths.userSettings,
  //   Provider: UserProvider,
  //   component: UserSettingsLazy,
  // },
].map(
  ({
    component: Component,
    Provider,
    ...route
  }) => ({
    ...route,
    component: withRouter(
      ({ match }) => {
        return (
          <Provider
            item={match.params.id}>
            <Component />
          </Provider>
        )
      }
    ),
  })
)

const settingModalRoutes = [
  {
    ...CustomKeys.add_host,
    component: AddHostModal,
  },
  {
    ...CustomKeys.edit_owner,
    component: EditOwnerModal,
  },
  {
    ...CustomKeys.giving_picker,
    component: SearchGivingModal,
  },
  {
    ...CustomKeys.donation_picker,
    component: SearchDonationModal,
  },
  {
    ...CustomKeys.donor_picker,
    component: SearchDonorModal,
  },
].map(({ name, path, component }) => ({
  name,
  path,
  component,
}))

export const wishareSettingModalRoutes =
  (name, state = {}) => {
    const routes =
      settingModalRoutes.reduce(
        (prev, { name, path }) => {
          return {
            ...prev,
            [name]: {
              pathname: path,
            },
          }
        },
        {}
      )
    return {
      ..._.get(routes, name, {}),
      state: {
        isModal: true,
        ...state,
      },
    }
  }

export const wishareConfigs = {
  fixedHeader: false,
  styles: {
    headerHeight,
    tabBar: {
      zIndex: 11,
      top: `calc(${headerHeight} - 1px)`,
      position: 'sticky',
      background: 'var(--background)',
    },
  },
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
  impCatePath: 'impact_categories',
  settingRoute: {
    name: 'settings',
    path: '/settings',
    withPath:
      ({ prop, id }) =>
      (route = '/:prop/:id/settings') =>
        mapPathParam(route, {
          prop,
          id,
        }),
  },
  wishareRoutes: [
    ...settingRoutes,
    {
      path: wisharePaths.activity,
      component: Activity,
    },
    {
      path: wisharePaths.donation,
      component: Donation,
    },
    {
      path: wisharePaths.giving,
      component: Giving,
    },
    {
      path: wisharePaths.recruitment,
      component: Recruitment,
    },
    {
      path: wisharePaths.category,
      component: Category,
    },
    {
      path: wisharePaths.topic,
      component: Topic,
    },
    {
      path: wisharePaths.user,
      component: User,
    },
    {
      path: wisharePaths.organization,
      component: Organization,
    },
  ],
  wishareModalRoutes: [
    {
      name: 'select-primary-organization',
      component: AuthOrganiationsModal,
      path: staticPaths
        .selectPrimaryOrganization
        .pathname,
      params: {
        width: modalWidth.small,
      },
    },
    {
      name: 'create-activity',
      component: CreateActivity,
      path: staticPaths.createActivity
        .pathname,
    },
    {
      name: 'create-donation',
      component: CreateDonation,
      path: staticPaths.createDonation
        .pathname,
    },
    {
      name: 'create-giving',
      component: CreateGiving,
      path: staticPaths.createGiving
        .pathname,
    },
    {
      name: 'create-recruitment',
      component: CreateRecruitment,
      path: staticPaths
        .createRecruitment.pathname,
    },
    {
      name: 'create-organization',
      component: CreateOrganization,
      path: staticPaths
        .createOrganization.pathname,
    },
    ...settingModalRoutes,
  ].map(
    ({
      component,
      params,
      ...route
    }) => ({
      ...route,
      exact: true,
      withSubDomain: true,
      component: createRequiredLogin(
        ModalLogin
      )(
        withModalLayout(component, {
          ...params,
        })
      ),
    })
  ),
}

const DefaultModuleLayout = compose(
  withTranslate
)(
  ({
    translate,
    location,
    history,
    match,
    ...props
  }) =>
    createModuleLayout({
      Sider: withProps({
        title: translate('Sider'),
      })(BoxPlaceholder),
      Banner: withProps({
        title: translate('Banner'),
      })(BoxPlaceholder),
      Template: PageTemplate,
      ...props,
    })(
      <BoxPlaceholder
        title={translate('Content')}
      />
    )
)

const LayoutRenderer = ({
  extra = null,
  Content = Null,
  Layout = DefaultModuleLayout,
  location,
  history,
  match,
  ...props
}) => (
  <Pure>
    <Layout {...props}>
      <Content />
      {extra}
    </Layout>
  </Pure>
)

const CustomLayoutRenderer = ({
  extra = null,
  Content = Null,
  Layout = DefaultModuleLayout,
  location,
  history,
  match,
  ...props
}) => (
  <Pure>
    <Layout
      {...props}
      {...{ Content }}>
      {extra}
    </Layout>
  </Pure>
)

const SettingLayoutRenderer = ({
  Content = Null,
  Header = Null,
  Layout = DefaultModuleLayout,
  withBreadcrumbs = renderSelf,
  location,
  history,
  match,
  ...props
}) => {
  const translate = useTranslate()
  const { value } = useContext(
    ModuleContext
  )
  const current = _.get(
    value,
    'Context'
  )
  const context =
    useContext(current) || {}
  return (
    <div className="h-ful">
      <ModuleLayoutWrapper
        Footer={withProps({
          Title: (props) => (
            <LayoutContext.Consumer>
              {({ isSm }) =>
                renderIf(
                  isSm,
                  <LogoHeader
                    {...props}
                  />
                )
              }
            </LayoutContext.Consumer>
          ),
          renderFooter: Null,
        })(MainHeader)}
        visibleLeftDrawer={true}
      />
      <div className="h-full space-y-3">
        <div
          style={{ zIndex: 31 }}
          className="flex flex-shrink-0 p-2 shadow stickyTop background">
          <GoBackAction />
          <CustomBreadcrumb
            rootClassName="flex items-center space-x-2 text-primary uppercase font-extrabold"
            routes={withBreadcrumbs(
              context,
              translate
            )}
          />
        </div>
        <div className="h-full p-2 lg:py-2">
          <Header {...context} />
          <Divider type="horizontal" />
          <Pure>
            <Layout
              {...props}
              {...{ Content }}
            />
          </Pure>
        </div>
      </div>
    </div>
  )
}

const renderBreadcrumbs = (
  context,
  translate = renderSelf
) => [
  {
    path: '',
    breadcrumbName:
      translate('settings'),
  },
]

const SettingLayout = ({
  Template,
  menuItems,
  Content = Null,
  routeEntities,
  ...props
}) =>
  createSettingLayout({
    menuItems,
    Template,
    routes: routeEntities,
    ...props,
  })(Content)

const wishareModules = {
  [WishareEntities.BENEFICIARY]: {
    Nav: BeneficiaryNav,
    Sider: BeneficiarySider,
    Banner: BeneficiaryBanner,
    Layout: BeneficiaryLayout,
    Template: PageTemplate,
    Content: BeneficiaryContent,
    Context: BeneficiaryContext,
    Provider: BeneficiaryProvider,
    LayoutRenderer,
    routeEntities:
      beneficiaryRouteEntities,
    entity: WishareEntities.BENEFICIARY,
    settings: beneficiarySettings,
  },
  [WishareEntities.ACTIVITY]: {
    Nav: ActivityNav,
    Sider: ActivitySider,
    Banner: ActivityBanner,
    Layout: ActivityLayout,
    Template: PageTemplate,
    Content: ActivityContent,
    Context: ActivityContext,
    Provider: ActivityProvider,
    LayoutRenderer,
    routeEntities:
      activityRouteEntities,
    entity: WishareEntities.ACTIVITY,
    settings: activitySettings,
  },
  [WishareEntities.DONATION]: {
    Nav: DonationNav,
    Sider: DonationSider,
    Banner: DonationBanner,
    Layout: DonationLayout,
    Template: PageTemplate,
    Content: DonationContent,
    Context: DonationContext,
    Provider: DonationProvider,
    LayoutRenderer,
    routeEntities:
      donationRouteEntities,
    entity: WishareEntities.DONATION,
    settings: donationSettings,
  },
  [WishareEntities.GIVING]: {
    Nav: GivingNav,
    Sider: GivingSider,
    Banner: GivingBanner,
    Layout: GivingLayout,
    Template: PageTemplate,
    Content: GivingContent,
    Context: GivingContext,
    Provider: GivingProvider,
    LayoutRenderer,
    routeEntities: givingRouteEntities,
    entity: WishareEntities.GIVING,
    settings: givingSettings,
  },
  [WishareEntities.RECRUITMENT]: {
    Nav: RecruitmentNav,
    Sider: RecruitmentSider,
    Banner: RecruitmentBanner,
    Layout: RecruitmentLayout,
    Template: PageTemplate,
    Content: RecruitmentContent,
    Context: RecruitmentContext,
    Provider: RecruitmentProvider,
    LayoutRenderer,
    routeEntities:
      recruitmentRouteEntities,
    entity: WishareEntities.RECRUITMENT,
    settings: recruitmentSettings,
  },
  [WishareEntities.CATEGORY]: {
    Nav: CategoryNav,
    Sider: CategorySider,
    Banner: Null,
    Layout: CategoryLayout,
    Template: DefaultTemplate,
    Content: CategoryContent,
    Context: CategoryContext,
    Provider: CategoryProvider,
    LayoutRenderer:
      CustomLayoutRenderer,
    routeEntities:
      categoryRouteEntities,
    entity: WishareEntities.CATEGORY,
    // settings: categorySettings,
  },
  [WishareEntities.TOPIC]: {
    Nav: TopicNav,
    Sider: TopicSider,
    Banner: Null,
    Layout: TopicLayout,
    Template: DefaultTemplate,
    Content: TopicContent,
    Context: TopicContext,
    Provider: TopicProvider,
    LayoutRenderer:
      CustomLayoutRenderer,
    routeEntities: topicRouteEntities,
    entity: WishareEntities.TOPIC,
    // settings: topicSettings,
  },
  [WishareEntities.USER]: {
    Nav: UserNav,
    Sider: UserSider,
    Banner: UserBanner,
    Layout: UserLayout,
    Template: PageTemplate,
    Content: UserContent,
    Context: UserContext,
    Provider: UserProvider,
    LayoutRenderer,
    routeEntities: userRouteEntities,
    entity: WishareEntities.USER,
    settings: userSettings,
  },
  [WishareEntities.DONOR]: {
    Nav: DonorNav,
    Sider: DonorSider,
    Banner: DonorBanner,
    Layout: DonorLayout,
    Template: PageTemplate,
    Content: DonorContent,
    Context: DonorContext,
    Provider: DonorProvider,
    LayoutRenderer,
    routeEntities: donorRouteEntities,
    entity: WishareEntities.DONOR,
    settings: donorSettings,
  },
  [WishareEntities.ORGANIZATION]: {
    Nav: OrganizationNav,
    Sider: OrganizationSider,
    Banner: OrganizationBanner,
    Layout: OrganizationLayout,
    Template: PageTemplate,
    Content: OrganizationContent,
    Context:
      OrganizationContainerContext,
    Provider:
      OrganizationContainerProvider,
    LayoutRenderer,
    routeEntities:
      organizationRouteEntities,
    entity:
      WishareEntities.ORGANIZATION,
    settings: organizationSettings,
  },
}

const settingModules = {
  [WishareEntities.BENEFICIARY]: {
    bindingName: 'beneficiary-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: BeneficiarySettingsContent,
    Context: BeneficiaryContext,
    Provider: BeneficiaryProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    withBreadcrumbs: renderBreadcrumbs,
    Header: BeneficiarySettingHeader,
    menuItems:
      beneficiarySettings.menuItems,
    routeEntities:
      beneficiarySettings.routeEntities,
    entity: WishareEntities.BENEFICIARY,
  },
  [WishareEntities.ACTIVITY]: {
    bindingName: 'activity-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: ActivitySettingsContent,
    Context: ActivityContext,
    Provider: ActivityProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    withBreadcrumbs: renderBreadcrumbs,
    Header: ActivitySettingHeader,
    menuItems:
      activitySettings.menuItems,
    routeEntities:
      activitySettings.routeEntities,
    entity: WishareEntities.ACTIVITY,
  },
  [WishareEntities.DONATION]: {
    bindingName: 'donation-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: DonationSettingsContent,
    Context: DonationContext,
    Provider: DonationProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    withBreadcrumbs: renderBreadcrumbs,
    Header: DonationSettingHeader,
    menuItems:
      donationSettings.menuItems,
    routeEntities:
      donationSettings.routeEntities,
    entity: WishareEntities.DONATION,
  },
  [WishareEntities.GIVING]: {
    bindingName: 'giving-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: GivingSettingsContent,
    Context: GivingContext,
    Provider: GivingProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    withBreadcrumbs: renderBreadcrumbs,
    Header: GivingSettingHeader,
    menuItems: givingSettings.menuItems,
    routeEntities:
      givingSettings.routeEntities,
    entity: WishareEntities.GIVING,
  },
  [WishareEntities.RECRUITMENT]: {
    bindingName: 'recruitment-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: RecruitmentSettingsContent,
    Context: RecruitmentContext,
    Provider: RecruitmentProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    withBreadcrumbs: renderBreadcrumbs,
    Header: RecruitmentSettingHeader,
    menuItems:
      recruitmentSettings.menuItems,
    routeEntities:
      recruitmentSettings.routeEntities,
    entity: WishareEntities.RECRUITMENT,
  },
  [WishareEntities.ORGANIZATION]: {
    bindingName:
      'organization-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content:
      OrganizationSettingsContent,
    Context:
      OrganizationContainerContext,
    Provider:
      OrganizationContainerProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    Header: OrganizationSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems:
      organizationSettings.menuItems,
    routeEntities:
      organizationSettings.routeEntities,
    entity:
      WishareEntities.ORGANIZATION,
  },
  [WishareEntities.USER]: {
    bindingName: 'user-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: UserSettingsContent,
    Context: UserContext,
    Provider: UserProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    Header: UserSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems: userSettings.menuItems,
    routeEntities:
      userSettings.routeEntities,
    entity: WishareEntities.USER,
  },
  [WishareEntities.DONOR]: {
    bindingName: 'donor-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: DonorSettingsContent,
    Context: DonorContext,
    Provider: DonorProvider,
    LayoutRenderer:
      SettingLayoutRenderer,
    Header: DonorSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems: donorSettings.menuItems,
    routeEntities:
      donorSettings.routeEntities,
    entity: WishareEntities.DONOR,
  },
}

export const ModuleContainer = ({
  name,
  children,
  useSettings = false,
  configs = wishareConfigs,
  modules = wishareModules,
}) => {
  return children({
    name,
    configs,
    modules,
    useSettings,
    settingModules,
  })
}

export const ModuleContext =
  React.createContext({})

export const ModuleProvider = ({
  value,
  children,
}) => (
  <ModuleContext.Provider
    key={value}
    value={{ value }}>
    {children}
  </ModuleContext.Provider>
)
