import _ from 'lodash'
import { useHistory } from 'modules/navigation/useRouter'
import {
  useLayoutEffect,
  useState,
} from 'react'

export const useScrollTo = ({
  offset = 0,
  behavior = 'smooth',
  ...props
}) => {
  const history = useHistory()

  const [trigger, setTrigger] =
    useState(false)

  const current_state = _.get(
    history,
    'location.state'
  )

  const toElement = (id) => {
    const element =
      document.getElementById(id)
    if (element) {
      setTrigger(true)
      history.push({
        state: { hash: id },
      })
    }
  }

  useLayoutEffect(() => {
    const element =
      document.getElementById(
        _.get(current_state, 'hash')
      )
    if (trigger && element) {
      window.scrollTo({
        top:
          element.getBoundingClientRect()
            .top -
          document.body.getBoundingClientRect()
            .top -
          offset,
        behavior,
        ...props,
      })
    }
  }, [trigger, current_state])

  return [toElement]
}
