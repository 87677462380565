import React from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import { Null } from 'views/Shared'
export const useDeBounceValue = (
  val,
  time = 300,
  callback = Null
) => {
  const [
    debouncedValue,
    setDebouncedValue,
  ] = React.useState(val)
  useDebounce(
    () => {
      setDebouncedValue(val)
      callback(val)
    },
    time,
    [val]
  )
  return debouncedValue
}
