import { Breadcrumb } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { notEmpty } from 'views/Shared'

const renderBreadcrumbItem = (
  { path, component, breadcrumbName },
  className
) => {
  if (component) {
    return (
      <div className="pr-2">
        {component}
      </div>
    )
  }
  return (
    <div
      className={classNames(
        'px-2',
        className
      )}>
      {!!path ? (
        <Link to={path}>
          {breadcrumbName}
        </Link>
      ) : (
        breadcrumbName
      )}
    </div>
  )
}

const CustomBreadcrumb = ({
  addOnAfter,
  addOnBefore,
  routes = [],
  rootClassName,
  renderItem = renderBreadcrumbItem
}) => {
  const data = useMemo(() => {
    return [
      addOnBefore
        ? {
            path: '',
            breadcrumbName:
              'addOnBefore',
            component: addOnBefore
          }
        : {},
      ...routes,
      addOnAfter
        ? {
            path: '',
            breadcrumbName:
              'addOnAfter',
            component: addOnAfter
          }
        : {}
    ].filter(notEmpty)
  }, [routes, addOnBefore, addOnAfter])

  return (
    <Breadcrumb
      separator=""
      routes={data}
      className="flex items-center"
      itemRender={(
        route,
        params,
        routes,
        paths
      ) => {
        const index = routes.indexOf(
          route
        )
        const last =
          index === routes.length - 1
        return (
          <div
            key={index}
            className="flex items-center">
            {renderItem(
              route,
              index === 0 &&
                rootClassName
            )}
            {!last && (
              <FaChevronRight className="text-primary" />
            )}
          </div>
        )
      }}
    />
  )
}

export default CustomBreadcrumb
