import React, {
  useContext,
} from 'react'
import {
  LoadingWrapper,
  renderOwnChild,
} from 'views/Shared'
import AdminPanel from 'views/Wishare/AdminPanel'
import WishareAlert from 'views/Wishare/WishareAlert'
import GivingContext from '../GivingContext'
import GivingNav from './GivingNav'

const GivingContent = ({
  Wrapper = renderOwnChild,
}) => {
  const { giving } = useContext(
    GivingContext
  )
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!giving}>
        <WishareAlert entity={giving}>
          <AdminPanel />
        </WishareAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <GivingNav />
      </div>
    </Wrapper>
  )
}

export default GivingContent
