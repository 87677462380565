import { giving_events_getGivingsByType_api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
  withProps,
} from 'recompose'
import { Null } from 'views/Shared'
import { GivingsSortBy } from 'views/Wishare/factory/createEntitySortBy'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import GivingRecordItem from 'views/Wishare/Giving/components/GivingRecordItem'
import GivingEntity from 'views/Wishare/Giving/GivingEntity'
import usePaginationHelper from 'views/Wishare/hooks/usePaginationHelper'
import ListHeader from 'views/Wishare/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'

const WorkspaceBeneficiariesPendingGivings =
  ({
    owner_id,
    owner_type,
    Wrapper = 'div',
    filter_actions = {
      sortBy: 'sort-by',
      keyword: 'keyword',
    },
  }) => {
    const t = useTranslate()

    const location = useLocation()

    const [action, setAction] =
      useState({
        type: undefined,
        value: {},
      })

    const sortBy = useSearchParam(
      filter_actions.sortBy
    )

    const onCancel = () =>
      setAction({ type: undefined })

    const refresh_deps = useMemo(
      () => [
        owner_id,
        `refreshToken=${_.get(
          location.state,
          'refreshToken'
        )}`,
      ],
      [owner_id, location.state]
    )

    const [pager_widget] =
      usePaginationHelper({
        onInit: ({
          onSortChange = Null,
        }) => {
          onSortChange(sortBy)
        },
        pagerInfo: {
          apiInfo:
            giving_events_getGivingsByType_api,
          query: bindQueryParams([
            {
              id: owner_id,
            },
            { type: owner_type },
          ]),
          RenderItem: withProps({
            withoutContextMenu: true,
              showEvent: true
          })(GivingRecordItem),
          RenderEntity: GivingEntity,
        },
        withKey: ({
          sortBy,
          keyword,
        }) =>
          [
            ...refresh_deps,
            sortBy,
            keyword,
          ].join('/'),
        withValues: (values) => ({
          ...values,
          status: -1,
        }),
        dependencies: [
          owner_id,
          owner_type,
        ],
        renderFilter: Null,
        query_params: filter_actions,
        sortHeader: t('register list'),
        SortByWidget: GivingsSortBy,
      })

    return (
      <Wrapper className="flex flex-col space-y-3">
        <ListHeader title="pending givings" />
        {pager_widget}
      </Wrapper>
    )
  }

export default compose(
  fromRenderProps(
    WorkspaceContext.Consumer,
    ({ organization }) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  ),
  branch(
    ({ owner_id }) =>
      _.isEmpty(owner_id),
    renderNothing
  ),
  pure
)(WorkspaceBeneficiariesPendingGivings)
