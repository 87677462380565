import { schema } from 'normalizr'

export const userProfileSchema =
  new schema.Entity(
    'user_profile',
    {},
    {
      idAttribute: 'username',
    }
  )
