import { getChannelsByProps_Api } from 'apis'
import {
  getId,
  getType,
} from 'apis/model/base'
import _ from 'lodash'
import React, {
  useContext,
  useMemo,
} from 'react'
import EntityList from 'views/Wishare/EntityList'
import { bindQueryParams } from 'views/Wishare/functions/routerHelper'
import { renderChannelItem } from '../../../MainPage/functions/renderChannelItem'
import OrganizationContainerContext from '../OrganizationContainerContext'
import useTranslate from "../../../../modules/local/useTranslate";

const OrganizationChannelList = ({
  Wrapper = 'div',
}) => {
    const t = useTranslate()

  const { organization: item } =
    useContext(
      OrganizationContainerContext
    )

  const [id, type] = [
    getId(item),
    getType(item),
  ]

  const apiInfo = getChannelsByProps_Api

  const refreshKey = useMemo(
    () =>
      [
        apiInfo.path
          .replace(':id', id)
          .replace(':type', type),
      ].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
      <div className="flex flex-col gap-3">
          <div className="uppercase text-sm font-semibold text-color-200 tracking-wide pb-1 border-b">
              {t('list of channels')}
          </div>
          <EntityList
              key={refreshKey}
              apiInfo={apiInfo}
              query={bindQueryParams([
                  { id },
                  { type },
              ])}
              RenderEntity={React.Fragment}
              renderItem={renderChannelItem}
              renderWrap={(
                  child,
                  __,
                  count
              ) => (
                  <Wrapper className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2">
                      {child}
                  </Wrapper>
              )}
          />
      </div>
  )
}

export default OrganizationChannelList
