import { SearchGroupIcon } from 'components/AppIcon'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import { useHistory } from 'modules/navigation/useRouter'
import React from 'react'
import { renderOwnChild } from 'views/Shared'
import {
  fromWishareEntity,
  WishareEntities,
} from '../enums'

const renderDefaultItem = (
  type,
  { onClick }
) => {
  if (_.isEmpty(type)) return null
  return (
    <Translate>
      {(t) => (
        <div
          onClick={onClick}
          style={{ height: '8rem' }}
          className="flex flex-col items-center justify-center gap-2 rounded-lg hover:shadow-out text-color-000 hover:text-primary cursor-pointer background-100">
          <SearchGroupIcon
            type={type}
            size={30}
          />
          <span className="font-medium text-center text-sm">
            {t(type)}
          </span>
        </div>
      )}
    </Translate>
  )
}


export const renderFeatureItem = (
  type,
  { onClick }
) => {
  if (_.isEmpty(type)) return null
  return (
    <Translate>
      {(t) => (
        <div
          onClick={onClick}
          style={{ height: '6rem' }}
          className="flex flex-col items-center justify-center gap-2 border border-color-50 rounded-lg hover:shadow-out text-color-000 hover:text-primary cursor-pointer background-200">
          <SearchGroupIcon
            type={type}
            size={25}
          />
          <span className="font-medium text-center text-xs">
            {t(type)}
          </span>
        </div>
      )}
    </Translate>
  )
}

const Explorer = ({
  type,
  path,
  renderItem,
  Wrapper = renderOwnChild,
}) => {
  const history = useHistory()

  const onSelect = () => {
    if (path) {
      history.push(path)
    }
  }

  return (
    <Wrapper>
      {renderItem(type, {
        onClick: onSelect,
      })}
    </Wrapper>
  )
}

const createDiscoveryExplorer = ({
  entity,
  renderItem = renderDefaultItem,
  ...props
}) => {
  const type = fromWishareEntity(
    entity,
    'pathname'
  )
  return (
    <Explorer
      type={type}
      renderItem={renderItem}
      {...props}
    />
  )
}

export const GeneralExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.UNDEFINED,
    renderItem: renderFeatureItem,
    ...props,
  })

export const ActivityExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.ACTIVITY,
    renderItem: renderFeatureItem,
    ...props,
  })

export const GivingExplorer = (props) =>
  createDiscoveryExplorer({
    entity: WishareEntities.GIVING,
    renderItem: renderFeatureItem,
    ...props,
  })

export const DonationExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.DONATION,
    renderItem: renderFeatureItem,
    ...props,
  })

export const RecruitmentExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.RECRUITMENT,
    renderItem: renderFeatureItem,
    ...props,
  })

export const EventExplorer = (props) =>
  createDiscoveryExplorer({
    entity: WishareEntities.EVENT,
    renderItem: renderFeatureItem,
    ...props,
  })

export const ChannelExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.CHANNEL,
    renderItem: renderFeatureItem,
    ...props,
  })

export const OrganizationExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity:
      WishareEntities.ORGANIZATION,
    renderItem: renderFeatureItem,
    ...props,
  })

export const UserExplorer = (props) =>
  createDiscoveryExplorer({
    entity: WishareEntities.USER,
    renderItem: renderFeatureItem,
    ...props,
  })

export const ArticleExplorer = (
  props
) =>
  createDiscoveryExplorer({
    entity: WishareEntities.ARTICLE,
    renderItem: renderFeatureItem,
    ...props,
  })

export default createDiscoveryExplorer
